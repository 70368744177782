import React, { useMemo, useCallback, useRef, useEffect, useState } from "react";
import { Editor, Transforms, Range, createEditor, Descendant, Text } from "slate";
import { withHistory } from "slate-history";
import ReactDOM from "react-dom";
import { Slate, Editable, ReactEditor, withReact, useSelected, useFocused } from "slate-react";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import richTextEditorHelper from "../../../editor/helper/richTextEditorHelper";
import { connect } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    marginBottom: 30,
  },
  formControl: {
    marginTop: -20,
    margin: theme.spacing(1),
    minWidth: 90,
  },
  icon: {
    color: theme.palette.text.primary,
    position: "absolute !important",
    right: "0 !important",
    pointerEvents: "none !important",
    fontSize: "1.2rem",
  },
  dropDownTitle: {
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 5,
    marginTop: 5,
  },
  rowWrapper: {
    maxWidth: (props: PropTypes) => props.maxWidth || "unset",
    marginTop: (props: PropTypes) => props.marginTop || 0,

    marginLeft: (props: PropTypes) => props.marginLeft || 0,
    marginRight: (props: PropTypes) => props.marginRight || 0,
    // width: '100%',
    display: "flex",
    flexDirection: "row",
  },
  columnWrapper: {
    maxWidth: (props: PropTypes) => props.maxWidth || "unset",
    marginTop: (props: PropTypes) => props.marginTop || 0,
    marginLeft: (props: PropTypes) => props.marginLeft || 0,
    marginRight: (props: PropTypes) => props.marginRight || 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  titleAndLengthColumnWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    minWidth: 140,
    marginRight: 15,
  },
  titleAndLengthRowWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: 10,
  },
  title: {
    color: (props: PropTypes) => props.titleColor || theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 5,
  },
  titleOnTop: {
    marginRight: 10,
    marginBottom: 0,
  },
  length: {
    fontSize: theme.typography.pxToRem(14),
  },
  label: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "16.8px",
  },
  selectWrapper: {
    padding: 0,
  },
  error: {
    color: "red",
    fontSize: "0.75rem",
    marginTop: 10,
    marginLeft: 15,
  },
  menuItemRoot: {
    textTransform: "capitalize",
  },
}));

const mapStateToProps = (state) => ({
  currentWebsiteId: state.home.selectedWebsite._id,
});

const Portal = ({ children }) => (typeof document === "object" ? ReactDOM.createPortal(children, document.body) : null);

const DropDown = (props) => {
  const classes = useStyles(props);
  const { handleDropDownSelect, tags } = props;
  if (tags.length === 0) {
    return null;
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel className={classes.label} id="select-tag">
        {I18n.t("entail.select_tag")}
      </InputLabel>
      <Select
        className={classes.selectWrapper}
        disableUnderline
        onClose={(event) => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
        IconComponent={() => <DropDownIcon className={classes.icon} />}
        labelId="select-tag"
        id="select-tag"
        value={""}
        onChange={handleDropDownSelect}
      >
        {tags.map((item) => (
          <MenuItem key={item} value={item} classes={{ root: classes.menuItemRoot }}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

type MentionElement = {
  type: "mention";
  character: string;
  children: CustomText[];
};

type CustomText = {
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
  text: string;
};

const initialValue: Descendant[] = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
      },
    ],
  },
];

type PropTypes = {
  tags: string[];
  text?: string;
  title: string;
  currentWebsiteId: string;
  placeholder: string;
  maxLength?: number;
  titleAndLengthLocation?: "left" | "top";
  error?: boolean;

  onTextChanged: (text) => void;
  inputStyle?: any;
};
const TextInputMentions = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    tags = [],
    text = null,
    placeholder = null,
    title = null,
    maxLength = null,
    currentWebsiteId,
    titleAndLengthLocation = "left",
    error = false,
    inputStyle = null,
    onTextChanged,
  } = props;
  const ref = useRef<HTMLDivElement | null>();
  const [target, setTarget] = useState<Range | undefined>();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");

  const deserialize = (text) => {
    if (!text || text.length === 0) {
      return initialValue;
    }
    const des = [
      {
        type: "paragraph",
        children: [],
      },
    ];
    const tokens = text.split(/(%%[^%]*%%)/g);
    tokens.map((token) => {
      if (token.match(/(%%.*%%)/g)) {
        des[0].children.push({
          type: "mention",
          character: token.replaceAll("%%", ""),
          children: [
            {
              text: "",
            },
          ],
        });
      } else {
        des[0].children.push({ text: token });
      }
    });

    return des;
  };
  const [value, setValue] = React.useState(deserialize(text));

  const withMentions = (editor) => {
    const { isInline, isVoid } = editor;
    editor.isInline = (element) => (element.type === "mention" ? true : isInline(element));
    editor.isVoid = (element) => (element.type === "mention" ? true : isVoid(element));
    return editor;
  };

  const renderElement = useCallback((props) => <Element {...props} />, []);

  const editor = useMemo(() => withMentions(withReact(withHistory(createEditor() as ReactEditor))), []);

  const chars = tags
    .filter((c) => {
      return c.toLowerCase().startsWith(search.toLowerCase());
    })
    .slice(0, 10);

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [chars.length, editor, index, search, target]);

  const serialize = (node) => {
    if (Text.isText(node)) {
      return node.text;
    }
    const children = node.children.map((n) => serialize(n)).join("");

    switch (node.type) {
      case "mention":
        return `%%${node.character}%%`;
      case "paragraph":
        return `${children}`;
      default:
        return children;
    }
  };

  const handleChange = (value: any) => {
    setValue(value);
    const isAstChange = editor.operations.some((op) => op.type !== "set_selection");

    if (isAstChange) {
      onTextChanged(serialize(value[0]));
    }
  };

  const handleDropDownSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    try {
      if (editor.selection) {
        Transforms.select(editor, target);
      }
      insertMention(editor, event.target.value as string);
      setTarget(null);
    } catch (e) {
      console.log("tried to set but cant:::", e.message);
    }
  };

  const insertMention = (editor, character) => {
    const mention: MentionElement = {
      type: "mention",
      character,
      children: [{ text: "" }],
    };
    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
  };

  const onKeyDown = useCallback(
    (event) => {
      if (target) {
        switch (event.key) {
          case "ArrowDown": {
            event.preventDefault();
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          }
          case "ArrowUp": {
            event.preventDefault();
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          }
          case "Tab":
          case "Enter": {
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, chars[index]);
            setTarget(null);
            break;
          }
          case "Escape":
            event.preventDefault();
            setTarget(null);
            break;
          default:
            break;
        }
      }
    },
    [index, search, target]
  );

  const valueLength = richTextEditorHelper.serialize(value)?.length || 0;
  return (
    <div className={ClassNames(classes.mainWrapper)}>
      <div
        className={ClassNames(
          titleAndLengthLocation === "left" && classes.rowWrapper,
          titleAndLengthLocation === "top" && classes.columnWrapper
        )}
      >
        {(title || maxLength) && (
          <div
            className={ClassNames(
              titleAndLengthLocation === "left" && classes.titleAndLengthColumnWrapper,
              titleAndLengthLocation === "top" && classes.titleAndLengthRowWrapper
            )}
          >
            <Typography
              className={ClassNames(classes.title, titleAndLengthLocation === "top" && classes.titleOnTop)}
              variant={"subtitle2"}
            >
              {title || ""}
            </Typography>

            {maxLength && (
              <Typography
                className={ClassNames(classes.length, valueLength >= maxLength && classes.error)}
                variant={"body2"}
              >
                {I18n.t("entail.text_length", { length: valueLength, maxLength })}
              </Typography>
            )}
          </div>
        )}
        <DropDown tags={tags} handleDropDownSelect={handleDropDownSelect} />
      </div>
      <Slate
        editor={editor}
        value={value}
        onChange={(newValue) => {
          const { selection } = editor;
          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);
            const wordBefore = Editor.before(editor, start, { unit: "word" });
            const before = wordBefore && Editor.before(editor, wordBefore);
            const beforeRange = before && Editor.range(editor, before, start);
            const beforeText = beforeRange && Editor.string(editor, beforeRange);
            const beforeMatch = beforeText && beforeText.match(/^%(\w+)$/);
            const after = Editor.after(editor, start);
            const afterRange = Editor.range(editor, start, after);
            const afterText = Editor.string(editor, afterRange);
            const afterMatch = afterText.match(/^(\s|$)/);

            if (beforeMatch && afterMatch) {
              setTarget(beforeRange);
              setSearch(beforeMatch[1]);
              setIndex(0);
              return;
            }
          }

          setTarget(null);
          handleChange(newValue);
        }}
      >
        <Editable
          id={`${currentWebsiteId}.${placeholder}`}
          style={{
            outline: "none",
            border: `solid 1px ${error ? "red" : "lightgray"}`,
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0 1rem",
            background: "white",
            ...inputStyle,
          }}
          renderElement={renderElement}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
        />
        {error && (
          <Typography paragraph className={classes.error}>
            {title} is required
          </Typography>
        )}
        {target && chars.length > 0 && (
          <Portal>
            <div
              ref={ref}
              style={{
                // top: '-9999px',
                // left: '-9999px',
                position: "absolute",
                zIndex: 1,
                padding: "3px",
                background: "white",
                borderRadius: "4px",
                boxShadow: "0 1px 5px rgba(0,0,0,.2)",
              }}
              data-cy="mentions-portal"
            >
              {chars.map((char, i) => (
                <div
                  key={char}
                  style={{
                    padding: "1px 3px",
                    borderRadius: "3px",
                    background: i === index ? "#B4D5FF" : "transparent",
                  }}
                >
                  {char}
                </div>
              ))}
            </div>
          </Portal>
        )}
      </Slate>
    </div>
  );
};

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.character.replace(" ", "-")}`}
      style={{
        padding: "3px 3px 2px",
        margin: "0 1px",
        verticalAlign: "baseline",
        display: "inline-block",
        borderRadius: "4px",
        backgroundColor: "#eee",
        fontSize: "0.9em",
        boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
      }}
    >
      {element.character}
      {children}
    </span>
  );
};

const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "mention":
      return <Mention {...props} />;
    default:
      return (
        <p
          {...attributes}
          style={{
            marginTop: "-1.5px",
            marginBottom: "-1.5px",
            paddingTop: 9.5,
            paddingBottom: 9.5,
          }}
        >
          {children}
        </p>
      );
  }
};

export default connect(mapStateToProps)(TextInputMentions);
