import { STRATEGY, TARGETED_POSTS } from "./constants/actionTypes";
import _ from "lodash";

const initialState = {
  strategy: null,
  strategyLoaded: false,
  audiences: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STRATEGY.ON_STRATEGY_LOADED:
      return {
        ...state,
        strategyLoaded: true,
        strategy: action.payload.strategy,
      };
    case STRATEGY.ON_STRATEGY_CHANGED:
      return {
        ...state,
        strategy: action.strategy,
      };
    case STRATEGY.ON_NEW_AUDIENCE_LOADED:
      return {
        ...state,
        audiences: action.payload.audiences,
      };
    case STRATEGY.ON_NEW_AUDIENCE_CREATED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        audiences: _.uniqBy([...state.audiences, action.payload.audience], "_id").sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        strategy: {
          ...state.strategy,
          audiences: _.uniqBy([...state.strategy.audiences, action.payload.audience], "_id"),
        },
      };
    default:
      return state;
  }
};
