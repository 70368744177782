import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 14,
    height: 14,
  },
}));

type PropTypes = {
  className?: string;
};

const StrokeRightIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 14 14"}>
      <rect
        x="13.5"
        y="0.5"
        width="13"
        height="13"
        rx="0.5"
        transform="rotate(90 13.5 0.5)"
        fill="#F5F6F8"
        stroke="#C4C4C4"
      />
      <mask id="path-2-inside-1_3797_99" fill="white">
        <path d="M13 -4.37114e-08C13.5523 -1.95703e-08 14 0.447715 14 1L14 13C14 13.5523 13.5523 14 13 14L0.999999 14C0.447715 14 -5.92389e-07 13.5523 -5.68248e-07 13L-4.37114e-08 1C-1.95703e-08 0.447715 0.447716 -5.92389e-07 1 -5.68248e-07L13 -4.37114e-08Z" />
      </mask>
      <path
        d="M13 -4.37114e-08C14.3807 1.66414e-08 15.5 1.11929 15.5 2.5L15.5 11.5C15.5 12.8807 14.3807 14 13 14L12.5 14C12.5 14 12.5 13.5523 12.5 13L12.5 1C12.5 0.447715 12.5 -6.55671e-08 12.5 -6.55671e-08L13 -4.37114e-08ZM-6.11959e-07 14L0 -6.11959e-07L-6.11959e-07 14ZM0 -6.11959e-07L14 0L0 -6.11959e-07ZM14 14L-6.11959e-07 14L14 14Z"
        fill="#4353FF"
        mask="url(#path-2-inside-1_3797_99)"
      />
    </SvgIcon>
  );
};

export default StrokeRightIcon;
