import React from "react";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";

type PropTypes = {
  interval:
    | "today"
    | "lastWeek"
    | "last2Weeks"
    | "lastMonth"
    | "last2Months"
    | "last3Months"
    | "last6Months"
    | "lastYear";
  handleIntervalChange: (interval: string) => void;
};

const MTableToolbarSearchConsoleIntervalFilter = (props: PropTypes) => {
  const { interval, handleIntervalChange } = props;
  //   today: IGoogleSearchConsoleStatisticsResults,
  //   lastWeek: IGoogleSearchConsoleStatisticsResults,
  //   last2Weeks: IGoogleSearchConsoleStatisticsResults,
  //   lastMonth: IGoogleSearchConsoleStatisticsResults,
  //   last2Months: IGoogleSearchConsoleStatisticsResults,
  //   last3Months: IGoogleSearchConsoleStatisticsResults,
  //   last6Months: IGoogleSearchConsoleStatisticsResults,
  //   lastYear: IGoogleSearchConsoleStatisticsResults,
  const intervalOptions = [
    {
      value: "today",
      label: I18n.t("common.today"),
      interval: 0,
      intervalType: "day",
    },
    {
      value: "lastWeek",
      label: I18n.t("common.last_n_days", { days: 7 }),
      interval: 7,
      intervalType: "day",
    },
    {
      value: "last2Weeks",
      label: I18n.t("common.last_n_days", { days: 14 }),
      interval: 14,
      intervalType: "day",
    },
    {
      value: "lastMonth",
      label: I18n.t("common.last_n_months", { months: 1 }),
      interval: 1,
      intervalType: "month",
    },
    {
      value: "last2Months",
      label: I18n.t("common.last_n_months", { months: 2 }),
      interval: 2,
      intervalType: "month",
    },
    {
      value: "last3Months",
      label: I18n.t("common.last_n_months", { months: 3 }),
      interval: 3,
      intervalType: "month",
    },
    {
      value: "last6Months",
      label: I18n.t("common.last_n_months", { months: 6 }),
      interval: 6,
      intervalType: "month",
    },
    {
      value: "lastYear",
      label: I18n.t("common.last_year"),
      interval: 1,
      intervalType: "year",
    },
  ];
  return (
    <MTableToolbarBaseSelect
      options={intervalOptions}
      optionValue={"value"}
      optionLabel={"label"}
      selectedOptions={interval ? [_.find(intervalOptions, { value: interval })] : []}
      handleChange={(value) => {
        handleIntervalChange(value?.[0]?.value);
      }}
    />
  );
};

export default MTableToolbarSearchConsoleIntervalFilter;
