import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { ePostType, IAdminUser, IQuestion } from "../../../../reducers/constants/objectTypes";
import { web } from "../../../../helpers/urlHelper";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import MTableCellDate from "../../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $actions": {
        display: "flex",
      },
      "& $takenByText": {
        display: "none",
      },
      "& $takeOverButton": {
        display: "flex",
      },
    },
  },
}));

type PropTypes = {
  user: IAdminUser;

  question: IQuestion;
  communityQuestionEditor: (questionId: string) => void;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
});

const mapDispatchToProps = (dispatch) => ({
  communityQuestionEditor: (questionId) =>
    dispatch(push(web.communityQuestionEditor(questionId, "moderator"), { publicPost: true })),
});

const QuestionsModeratorItemRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { question, communityQuestionEditor } = props;
  const theme = useTheme();
  return (
    <MTableRow handleRowClick={() => communityQuestionEditor(question._id)} customClassName={classes.itemWrapper}>
      <MTableCellFirst
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(14)}
        avatarType={ePostType.COMMUNITY_ANSWER as ePostType}
        cellText={question.title || I18n.t("community.new_question")}
      />
      <MTableCellDate date={question.updatedAt} />
      <MTableCellDate date={question.createdAt} />
      <MTableCellText text={question.category?.name} />
      <MTableCellNumber value={question.answers?.length} />
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsModeratorItemRow);
