import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { ChevronRight, DoubleArrow } from "@material-ui/icons";
import { connect } from "react-redux";
import ClassNames from "classnames";
import { STRATEGY_SETTINGS } from "../../../../../reducers/constants/actionTypes";
import { AIRFLOW_DAG_IDS } from "../../../../../helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  dagWrapper: {
    display: "flex",
    flexDirection: "row",
    columnGap: "30px",
    alignItems: "center",
    padding: "40px 30px 40px 30px",
  },
  jobRow: {
    cursor: "pointer",
    transition: "all ease-in .1s",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  activeRow: {
    color: theme.palette.primary.main,
  },
  rowColor: {
    color: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  activeJob: string;
  handleJobChange: (job: string) => void;
};

const mapStateToProps = (state) => ({
  activeJob: state.strategySettings.activeJob,
});

const mapDispatchToProps = (dispatch) => ({
  handleJobChange: (job) => dispatch({ type: STRATEGY_SETTINGS.STRATEGY_SETTINGS_JOB_CHANGE, job }),
});
const JobList = (props: PropTypes) => {
  const classes = useStyles();

  const { activeJob, handleJobChange } = props;

  return (
    <div className={classes.dagWrapper}>
      {Object.entries(AIRFLOW_DAG_IDS).map(([key, value], index) => (
        <>
          <div
            key={index}
            onClick={() => handleJobChange(key)}
            className={ClassNames(classes.jobRow, activeJob === key ? classes.activeRow : classes.rowColor)}
          >
            <Typography variant={"subtitle2"}>{value}</Typography>
          </div>
          {!(index === Object.entries(AIRFLOW_DAG_IDS).length - 1) && <ChevronRight />}
        </>
      ))}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JobList);
