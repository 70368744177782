import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function NumberedListIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 38 32"}>
      <path d="M12.000,6.000 L36.000,6.000 C37.100,6.000 38.000,5.100 38.000,4.000 C38.000,2.900 37.100,2.000 36.000,2.000 L12.000,2.000 C10.900,2.000 10.000,2.900 10.000,4.000 C10.000,5.100 10.900,6.000 12.000,6.000 ZM36.000,26.000 L12.000,26.000 C10.900,26.000 10.000,26.900 10.000,28.000 C10.000,29.100 10.900,30.000 12.000,30.000 L36.000,30.000 C37.100,30.000 38.000,29.100 38.000,28.000 C38.000,26.900 37.100,26.000 36.000,26.000 ZM36.000,14.000 L12.000,14.000 C10.900,14.000 10.000,14.900 10.000,16.000 C10.000,17.100 10.900,18.000 12.000,18.000 L36.000,18.000 C37.100,18.000 38.000,17.100 38.000,16.000 C38.000,14.900 37.100,14.000 36.000,14.000 ZM5.000,24.000 L1.000,24.000 C0.440,24.000 -0.000,24.440 -0.000,25.000 C-0.000,25.560 0.440,26.000 1.000,26.000 L4.000,26.000 L4.000,27.000 L3.000,27.000 C2.440,27.000 2.000,27.440 2.000,28.000 C2.000,28.560 2.440,29.000 3.000,29.000 L4.000,29.000 L4.000,30.000 L1.000,30.000 C0.440,30.000 -0.000,30.440 -0.000,31.000 C-0.000,31.560 0.440,32.000 1.000,32.000 L5.000,32.000 C5.560,32.000 6.000,31.560 6.000,31.000 L6.000,25.000 C6.000,24.440 5.560,24.000 5.000,24.000 ZM1.000,2.000 L2.000,2.000 L2.000,7.000 C2.000,7.560 2.440,8.000 3.000,8.000 C3.560,8.000 4.000,7.560 4.000,7.000 L4.000,1.000 C4.000,0.440 3.560,-0.000 3.000,-0.000 L1.000,-0.000 C0.440,-0.000 -0.000,0.440 -0.000,1.000 C-0.000,1.560 0.440,2.000 1.000,2.000 ZM5.000,12.000 L1.000,12.000 C0.440,12.000 -0.000,12.440 -0.000,13.000 C-0.000,13.560 0.440,14.000 1.000,14.000 L3.600,14.000 L0.240,17.920 C0.080,18.100 -0.000,18.340 -0.000,18.560 L-0.000,19.000 C-0.000,19.560 0.440,20.000 1.000,20.000 L5.000,20.000 C5.560,20.000 6.000,19.560 6.000,19.000 C6.000,18.440 5.560,18.000 5.000,18.000 L2.400,18.000 L5.760,14.080 C5.920,13.900 6.000,13.660 6.000,13.440 L6.000,13.000 C6.000,12.440 5.560,12.000 5.000,12.000 Z" />
    </SvgIcon>
  );
}

export default NumberedListIcon;
