import React, { useEffect, useState } from "react";
import { Button, Theme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    marginTop: 10,
  },
  tooltip: {
    marginTop: 0,
  },
  button: {
    textTransform: "none",
    backgroundColor: "transparent",
    border: `1px dashed ${theme.palette.text.secondary}`,
    color: theme.palette.text.primary,
    borderRadius: "2px",
  },
}));

type PropTypes = {
  label?: string;
  value: string;
  className?: string;
};

const MCopyToClipboardButton = (props: PropTypes) => {
  const { label, value, className } = props;
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (showTooltip) {
      timeoutId = setTimeout(() => {
        setShowTooltip(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showTooltip]);

  const handleClick = () => {
    if (value) {
      navigator.clipboard.writeText(value);
      setShowTooltip(true);
    }
  };

  return (
    <div className={ClassNames(classes.container, className)}>
      <Tooltip open={showTooltip} classes={{ popper: classes.tooltip }} title={"Copied to clipboard!"} placement="top">
        <Button className={classes.button} onClick={handleClick}>
          {label || I18n.t("common.copy_to_clipboard")}
        </Button>
      </Tooltip>
    </div>
  );
};

export default MCopyToClipboardButton;
