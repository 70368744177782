import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core";
import TextAreaBaseComponent from "../BaseComponents/TextAreaBaseComponent";
import Classnames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  text: (props: PropTypes) => ({
    width: props.direction === "column" ? "100%" : 180,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    padding: "7px 10px",
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  }),
  fieldText: {
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: "nowrap",
  },
  row: (props: PropTypes) => ({
    display: "flex",
    flexDirection: props.direction || "row",
    alignItems: props.direction === "column" ? "start" : "center",
    justifyContent: "space-between",
    gap: props.direction === "column" ? 7 : 0,
    marginBottom: 10,
  }),
}));

type PropTypes = {
  title: string;
  placeholder?: string;
  value: string;
  direction?: "row" | "column";
  customTitleClass?: string;
  customTextClass?: string;

  handleChange: any;
};

const TextFieldElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { title, placeholder = "", value, customTitleClass = null, customTextClass = null, handleChange } = props;
  return (
    <div className={classes.row}>
      <Typography variant={"body1"} className={Classnames(classes.fieldText, customTitleClass)}>
        {title}
      </Typography>
      <TextAreaBaseComponent
        className={Classnames(classes.text, customTextClass)}
        text={value || ""}
        placeholder={placeholder}
        onChange={(value) => handleChange(value)}
      />
    </div>
  );
};

export default TextFieldElement;
