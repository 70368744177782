import { REVIEW } from "./constants/actionTypes";
import { ePostType } from "./constants/objectTypes";

const initialState = {
  postsLoaded: false,
  refreshPostList: false,
  // targeted: { },
  // targetedTranslations: { },
  // blog: { },
  // blogTranslations: { },
  // learningCenter: { },
  // learningCenterTopics: { },
  // comparison: { },
  // tags: { },
  // productPages: { },
  // productReviews: { },
  // productAlternatives: { },
  // productComparisons: { },
  // customPosts: { },

  customerPending: [],
  pending: [],
  inProgress: [],
  inProgressByOthers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REVIEW.POSTS_LOADED:
      return {
        ...state,
        postsLoaded: true,
        refreshPostList: false,

        inProgress: [].concat.apply(
          [],
          [
            action.payload.targeted.inProgress.map((p) => ({ ...p, postType: ePostType.TARGETED })),
            action.payload.targetedTranslations.inProgress.map((p) => ({ ...p, postType: "TARGETED_TRANSLATION" })),
            action.payload.blog.inProgress.map((p) => ({ ...p, postType: ePostType.BLOG })),
            action.payload.blogTranslations.inProgress.map((p) => ({ ...p, postType: "BLOG_TRANSLATION" })),
            action.payload.learningCenter.inProgress.map((p) => ({ ...p, postType: ePostType.LEARNING_CENTER })),
            action.payload.tags.inProgress.map((p) => ({ ...p, postType: ePostType.TOPIC })),
            action.payload.learningCenterTopics.inProgress.map((p) => ({ ...p, postType: "LEARNING_CENTER_TOPIC" })),
            action.payload.comparison.inProgress.map((p) => ({ ...p, postType: ePostType.COMPARISON })),
            action.payload.productPages.inProgress.map((p) => ({ ...p, postType: ePostType.PRODUCT_PAGE })),
            action.payload.productReviews.inProgress.map((p) => ({ ...p, postType: ePostType.PRODUCT_REVIEW })),
            action.payload.productAlternatives.inProgress.map((p) => ({
              ...p,
              postType: ePostType.PRODUCT_ALTERNATIVE,
            })),
            action.payload.productComparisons.inProgress.map((p) => ({ ...p, postType: ePostType.PRODUCT_COMPARISON })),
            action.payload.customPosts.inProgress.map((p) => ({ ...p, postType: ePostType.CUSTOM })),
          ]
        ),
        inProgressByOthers: [].concat.apply(
          [],
          [
            action.payload.targeted.inProgressByOthers.map((p) => ({ ...p, postType: ePostType.TARGETED })),
            action.payload.targetedTranslations.inProgressByOthers.map((p) => ({
              ...p,
              postType: "TARGETED_TRANSLATION",
            })),
            action.payload.blog.inProgressByOthers.map((p) => ({ ...p, postType: ePostType.BLOG })),
            action.payload.blogTranslations.inProgressByOthers.map((p) => ({ ...p, postType: "BLOG_TRANSLATION" })),
            action.payload.learningCenter.inProgressByOthers.map((p) => ({
              ...p,
              postType: ePostType.LEARNING_CENTER,
            })),
            action.payload.tags.inProgressByOthers.map((p) => ({ ...p, postType: ePostType.TOPIC })),
            action.payload.learningCenterTopics.inProgressByOthers.map((p) => ({
              ...p,
              postType: "LEARNING_CENTER_TOPIC",
            })),
            action.payload.comparison.inProgressByOthers.map((p) => ({ ...p, postType: ePostType.COMPARISON })),
            action.payload.productPages.inProgressByOthers.map((p) => ({ ...p, postType: ePostType.PRODUCT_PAGE })),
            action.payload.productReviews.inProgressByOthers.map((p) => ({ ...p, postType: ePostType.PRODUCT_REVIEW })),
            action.payload.productAlternatives.inProgressByOthers.map((p) => ({
              ...p,
              postType: ePostType.PRODUCT_ALTERNATIVE,
            })),
            action.payload.productComparisons.inProgressByOthers.map((p) => ({
              ...p,
              postType: ePostType.PRODUCT_COMPARISON,
            })),
            action.payload.customPosts.inProgressByOthers.map((p) => ({ ...p, postType: ePostType.CUSTOM })),
          ]
        ),
        pending: [].concat.apply(
          [],
          [
            action.payload.targeted.pending.map((p) => ({ ...p, postType: ePostType.TARGETED })),
            action.payload.targetedTranslations.pending.map((p) => ({ ...p, postType: "TARGETED_TRANSLATION" })),
            action.payload.blog.pending.map((p) => ({ ...p, postType: ePostType.BLOG })),
            action.payload.blogTranslations.pending.map((p) => ({ ...p, postType: "BLOG_TRANSLATION" })),
            action.payload.learningCenter.pending.map((p) => ({ ...p, postType: ePostType.LEARNING_CENTER })),
            action.payload.tags.pending.map((p) => ({ ...p, postType: ePostType.TOPIC })),
            action.payload.learningCenterTopics.pending.map((p) => ({ ...p, postType: "LEARNING_CENTER_TOPIC" })),
            action.payload.comparison.pending.map((p) => ({ ...p, postType: ePostType.COMPARISON })),
            action.payload.productPages.pending.map((p) => ({ ...p, postType: ePostType.PRODUCT_PAGE })),
            action.payload.productReviews.pending.map((p) => ({ ...p, postType: ePostType.PRODUCT_REVIEW })),
            action.payload.productAlternatives.pending.map((p) => ({ ...p, postType: ePostType.PRODUCT_ALTERNATIVE })),
            action.payload.productComparisons.pending.map((p) => ({ ...p, postType: ePostType.PRODUCT_COMPARISON })),
            action.payload.customPosts.pending.map((p) => ({ ...p, postType: ePostType.CUSTOM })),
          ]
        ),
        customerPending: [].concat.apply(
          [],
          [
            action.payload.targeted.customerPending.map((p) => ({ ...p, postType: ePostType.TARGETED })),
            action.payload.targetedTranslations.customerPending.map((p) => ({
              ...p,
              postType: "TARGETED_TRANSLATION",
            })),
            action.payload.blog.customerPending.map((p) => ({ ...p, postType: ePostType.BLOG })),
            action.payload.blogTranslations.customerPending.map((p) => ({ ...p, postType: "BLOG_TRANSLATION" })),
            action.payload.learningCenter.customerPending.map((p) => ({ ...p, postType: ePostType.LEARNING_CENTER })),
            action.payload.tags.customerPending.map((p) => ({ ...p, postType: ePostType.TOPIC })),
            action.payload.learningCenterTopics.customerPending.map((p) => ({
              ...p,
              postType: "LEARNING_CENTER_TOPIC",
            })),
            action.payload.comparison.customerPending.map((p) => ({ ...p, postType: ePostType.COMPARISON })),
            action.payload.productPages.customerPending.map((p) => ({ ...p, postType: ePostType.PRODUCT_PAGE })),
            action.payload.productReviews.customerPending.map((p) => ({ ...p, postType: ePostType.PRODUCT_REVIEW })),
            action.payload.productAlternatives.customerPending.map((p) => ({
              ...p,
              postType: ePostType.PRODUCT_ALTERNATIVE,
            })),
            action.payload.productComparisons.customerPending.map((p) => ({
              ...p,
              postType: ePostType.PRODUCT_COMPARISON,
            })),
            action.payload.customPosts.customerPending.map((p) => ({ ...p, postType: ePostType.CUSTOM })),
          ]
        ),

        //
        // targeted: action.payload.targeted,
        // targetedTranslations: action.payload.targetedTranslations,
        // blog: action.payload.blog,
        // blogTranslations: action.payload.blogTranslations,
        // learningCenter: action.payload.learningCenter,
        // learningCenterTopics: action.payload.learningCenterTopics,
        // comparison: action.payload.comparison,
        // tags: action.payload.tags,
        // productPages: action.payload.productPages,
        // productReviews: action.payload.productReviews,
        // productAlternatives: action.payload.productAlternatives,
        // productComparisons: action.payload.productComparisons,
        // customPosts: action.payload.customPosts,
      };
    case REVIEW.REFRESH_POST_LIST:
      return {
        ...state,
        refreshPostList: true,
      };
    default:
      return state;
  }
};
