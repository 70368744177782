import React, { useMemo } from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import _ from "lodash";
import DownChevronIcon from "../../../../icons/DownChevronIcon";
import AddIcon from "../../../../icons/AddIcon";
import CfCheckBox from "../../../CfCheckBox";
import CfButton from "../../../CfButton";
import MTableToolbarBaseActionButton from "../Actions/MTableToolbarBaseActionButton";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
  },
  popper: {
    zIndex: 3,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 20px",
    gap: 15,
    maxHeight: 250,
    width: 290,
    overflowY: "auto",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    borderTop: `solid 1px ${theme.palette.divider}`,
  },
  clearButton: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: 0,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  applyButton: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: "7px 20px",
    borderRadius: 20,
  },
  titleWrapper: {
    cursor: "pointer",
    color: theme.palette.text.disabled,
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    margin: "0 20px",
  },
  selectionResult: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    whiteSpace: "nowrap",
  },
  title: {
    padding: "10px 20px 0px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    color: theme.palette.text.primary,
    lineHeight: "normal",
  },
  checkbox: {
    margin: 0,
    "&:hover": {
      color: "blue",
    },
  },
  checkboxLabel: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  dropdown: {
    backgroundColor: "transparent",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

type PropTypes = {
  columns: string[];
  handleColumnsSelected: (columns: { _id: string; name: string }[]) => void;
  optionLabel?: string;
  optionValue?: string;
};
const MTableToolBarMappingEditColumnsFilter = (props: PropTypes) => {
  const { columns, handleColumnsSelected, optionLabel = "name", optionValue = "_id" } = props;

  const classes = useStyles(props);
  const theme = useTheme<Theme>();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const optionsHeaders = useMemo(
    () => [
      {
        _id: "suggested_title",
        name: I18n.t("workflow.mapping.columnNames.suggested_title"),
      },
      {
        _id: "feedback",
        name: I18n.t("workflow.mapping.columnNames.feedback"),
      },
      {
        _id: "status",
        name: I18n.t("workflow.mapping.columnNames.status"),
      },
      {
        _id: "conversion_potential",
        name: I18n.t("workflow.mapping.columnNames.funnelStage"),
      },
      {
        _id: "post_type",
        name: I18n.t("workflow.mapping.columnNames.post_type"),
      },
      {
        _id: "pages",
        name: I18n.t("workflow.mapping.columnNames.pages"),
      },
      {
        _id: "clicks",
        name: I18n.t("workflow.mapping.columnNames.clicks"),
      },
      {
        _id: "variations",
        name: I18n.t("workflow.mapping.columnNames.variations"),
      },
      {
        _id: "volume",
        name: I18n.t("workflow.mapping.columnNames.volume"),
      },
      {
        _id: "reviewed_at",
        name: I18n.t("workflow.mapping.columnNames.reviewed_at"),
      },
      {
        _id: "added_at",
        name: I18n.t("workflow.mapping.columnNames.added_at"),
      },
    ],
    []
  );
  const [selected, setSelected] = React.useState(columns.map((_id) => _.find(optionsHeaders, { _id })));

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    handleColumnsSelected(optionsHeaders);
    setSelected(optionsHeaders);
  }, []);
  return (
    <div className={classes.wrapper}>
      <MTableToolbarBaseActionButton
        handleClick={(e) => handleClick(e)}
        text={I18n.t("workflow.mapping.edit_columns")}
        dropdown
        className={classes.dropdown}
      />
      <Popper open={open} anchorEl={anchorEl} transition placement={"bottom"} className={classes.popper}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <div className={classes.paper}>
            <Typography className={classes.title}>{I18n.t("workflow.mapping.select_columns")}</Typography>
            <div className={classes.content}>
              {optionsHeaders &&
                optionsHeaders.length > 0 &&
                optionsHeaders.map((option, index) => (
                  <CfCheckBox
                    key={option._id}
                    customClassName={classes.checkbox}
                    labelClassName={classes.checkboxLabel}
                    iconColor={theme.palette.primary.main}
                    checked={Boolean(selected.find((opt) => opt[optionValue] === option[optionValue]))}
                    value={option[optionValue]}
                    label={option[optionLabel]}
                    handleChange={() => {
                      if (!selected.find((opt) => opt[optionValue] === option[optionValue])) {
                        setSelected([option, ...selected]);
                      } else {
                        setSelected(selected.filter((opt) => opt[optionValue] !== option[optionValue]));
                      }
                    }}
                  />
                ))}
            </div>
            <div className={classes.buttons}>
              <CfButton
                customClass={classes.clearButton}
                text={I18n.t("common.reset")}
                onClick={(e) => {
                  setSelected(optionsHeaders);
                }}
              />
              <CfButton
                height={30}
                customClass={classes.applyButton}
                text={I18n.t("common.apply")}
                onClick={() => {
                  handleColumnsSelected(selected);
                  setAnchorEl(null);
                }}
              />
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default MTableToolBarMappingEditColumnsFilter;
