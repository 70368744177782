import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { IconButton, Theme, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";

import agent from "../../../../../../../../agent";
import useDebounce from "../../../../../../../../hooks/useDebounce";
import MSelect from "../../../../../../../../components/MSelect";
import theme from "../../../../../../../../themes/theme";
import { web } from "../../../../../../../../helpers/urlHelper";
import LaunchIcon from "../../../../../../../../icons/LaunchIcon";
import TrashCanIcon from "../../../../../../../../icons/TrashCanIcon";
import { IWebsite, IWidget, eWidgetType } from "../../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  container: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
    marginBottom: 10,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 3,
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectIndicator: {
    marginRight: 5,
    width: 12,
  },
  iconButton: {
    padding: 9.5,
    borderRadius: 0,
    paddingLeft: 5,
  },
  icon: {
    fontSize: 18,
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  trashCanIcon: {
    fill: theme.palette.text.primary,
    marginBottom: 1,
    fontSize: 16,
    "&:hover": {
      fill: theme.palette.error.main,
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  widget?: IWidget;
  widgetTypes?: eWidgetType[];
  activeOnly?: boolean;
  title?: string;
  placeholder?: string;
  handleChange(widget: IWidget): void;
};

const WidgetPickerElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    widget,
    selectedWebsite,
    activeOnly,
    title = I18n.t(`widgets_builder.widget_editor.widget_picker.title`),
    placeholder = I18n.t(`widgets_builder.widget_editor.widget_picker.select_widget`),
    widgetTypes = [eWidgetType.FORM, eWidgetType.CUSTOM_WIDGET, eWidgetType.DIALOG, eWidgetType.INLINE],
  } = props;
  const { handleChange } = props;
  const [searchText, setSearchText] = React.useState("");
  const [widgetOptions, setWidgetOptions] = React.useState([]);
  const [isHover, setIsHover] = React.useState(false);

  const debouncedSearchText = useDebounce(searchText, 500);

  const searchWidgets = React.useCallback(
    (keyword: string) => {
      agent.Widgets.getAutoComplete(selectedWebsite, keyword, widgetTypes, undefined, undefined, "desc", 50, activeOnly)
        .then((res) => {
          const widgetOptionsWithSelected =
            widget && !keyword ? [widget, ...res.widgets.filter((w) => w._id !== widget?._id)] : res.widgets;
          setWidgetOptions(widgetOptionsWithSelected);
        })
        .catch((e) => console.log(e));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedWebsite, widget, activeOnly, widgetTypes]
  );

  React.useEffect(() => {
    searchWidgets(debouncedSearchText);
  }, [debouncedSearchText]);

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
        <MSelect
          searchable
          customClass={classes.selectCustomClass}
          customDropdownIndicatorClasses={classes.selectIndicator}
          valuePadding={"2px 10px 2px 6px"}
          optionLabel={"name"}
          optionValue={"_id"}
          options={widgetOptions}
          value={widget || null}
          height={50}
          borderWidth={"0px"}
          placeholder={placeholder}
          isBold={false}
          textColor={theme.palette.text.primary}
          menuPlacement={"bottom"}
          singleValueStyles={{ fontSize: theme.typography.pxToRem(14) }}
          fullWidth
          handleInputChange={(val) => {
            setSearchText(val);
          }}
          handleChange={(selectedItem) => {
            handleChange(selectedItem);
          }}
          extraDropdownIcon={
            <>
              {isHover && widget && (
                <TrashCanIcon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleChange(null);
                  }}
                  className={classes.trashCanIcon}
                />
              )}
              {widget && (
                <IconButton
                  target={"_blank"}
                  href={web.widgetsEditor(widget?._id)}
                  className={classes.iconButton}
                  disableRipple
                >
                  <LaunchIcon className={classes.icon} />
                </IconButton>
              )}
            </>
          }
        />
      </div>
    </div>
  );
};

export default WidgetPickerElement;
