import React from "react";
import { I18n } from "react-redux-i18n";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";

type PropTypes = {
  publishStatus: { _id: string; name: string } | null;
  handlePublishStatusSelected: (publishStatus: { _id: string; name: string }) => void;
};

const MTableToolbarPublishStatusFilter = (props: PropTypes) => {
  const { publishStatus, handlePublishStatusSelected } = props;
  const PUBLISH_HISTORY_STATUS = [
    { _id: "OK", name: "Ok" },
    { _id: "ERROR", name: "Error" },
    { _id: "PENDING", name: "Pending" },
  ];
  return (
    <MTableToolbarBaseSelect
      options={PUBLISH_HISTORY_STATUS}
      selectedOptions={publishStatus ? [publishStatus] : []}
      placeholder={I18n.t("exclude_keywords.type_ph")}
      handleChange={(value) => {
        handlePublishStatusSelected(value?.[0]);
      }}
    />
  );
};

export default MTableToolbarPublishStatusFilter;
