import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import TextFieldElement from "../ElementComponents/TextFieldElement";
import BorderRadiusCSS from "../CssComponents/BorderRadiusCSS";
import BorderWidthCSS from "../CssComponents/BorderWidthCSS";
import FontColorCSS from "../CssComponents/FontColorCSS";
import HorizontalAlignmentCSS from "../CssComponents/HorizontalAlignmentCSS";
import PaddingCSS from "../CssComponents/PaddingCSS";
import MarginCSS from "../CssComponents/MarginCSS";
import FontSizeCSS from "../CssComponents/FontSizeCSS";
import BorderStyleCSS from "../CssComponents/BorderStyleCSS";
import FontWeightCSS from "../CssComponents/FontWeightElement";
import HoverLabelBaseComponent from "../BaseComponents/HoverLabelBaseComponent";
import BackgroundColorCSS from "../CssComponents/BackgroundColorCSS";
import BorderColorCSS from "../CssComponents/BorderColorCSS";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: 32,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginTop: 30,
    marginBottom: 10,
  },
}));

type PropTypes = {
  alignment?: string;
  buttonText?: string;
  buttonUrl?: string;
  buttonCss?: any;
  buttonDefaultCss: any;

  handleChange: any;
};

const ButtonOldElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { buttonCss, buttonDefaultCss, alignment = null, buttonText, buttonUrl, handleChange } = props;

  return (
    <div className={classes.wrapper}>
      <TextFieldElement
        title={I18n.t("rich_text_editor.button.button_text")}
        placeholder={I18n.t("rich_text_editor.button.enter_button_text_ph")}
        value={buttonText}
        handleChange={(buttonText) => handleChange({ buttonText })}
      />
      <TextFieldElement
        title={I18n.t("rich_text_editor.button.button_url")}
        placeholder={I18n.t("rich_text_editor.button.enter_button_url_ph")}
        value={buttonUrl}
        handleChange={(buttonUrl) => handleChange({ buttonUrl })}
      />
      <Typography className={classes.title}>{I18n.t("rich_text_editor.button.button_typography")}</Typography>
      <FontSizeCSS
        value={buttonCss?.fontSize}
        defaultValue={buttonDefaultCss.fontSize}
        handleChange={(fontSize) => handleChange({ buttonCss: { ...buttonCss, fontSize } })}
      />
      <FontWeightCSS
        value={buttonCss?.fontWeight}
        defaultValue={buttonDefaultCss.fontWeight}
        handleChange={(fontWeight) => handleChange({ buttonCss: { ...buttonCss, fontWeight } })}
      />
      <Typography className={classes.title}>{I18n.t("rich_text_editor.button.button_border")}</Typography>
      <BorderStyleCSS
        value={buttonCss?.borderStyle}
        defaultValue={buttonDefaultCss.borderStyle || "solid"}
        handleChange={(borderStyle) => handleChange({ buttonCss: { ...buttonCss, borderStyle } })}
      />
      <BorderWidthCSS
        value={buttonCss?.borderWidth}
        defaultValue={buttonDefaultCss.borderWidth}
        handleChange={(borderWidth) => handleChange({ buttonCss: { ...buttonCss, borderWidth } })}
      />
      <BorderRadiusCSS
        value={buttonCss?.borderRadius}
        defaultValue={buttonDefaultCss.borderWidth}
        handleChange={(borderRadius) => handleChange({ buttonCss: { ...buttonCss, borderRadius } })}
      />
      <Typography className={classes.title}>{I18n.t("rich_text_editor.button.button_colors")}</Typography>
      <HoverLabelBaseComponent
        labels={[I18n.t("rich_text_editor.button.button_normal"), I18n.t("rich_text_editor.button.button_hover")]}
      />
      <FontColorCSS
        rowTitle={I18n.t("rich_text_editor.text_color")}
        value={buttonCss?.color || buttonDefaultCss.color}
        defaultValue={buttonDefaultCss.color}
        isValueChanged={false}
        handleChange={(color) => handleChange({ buttonCss: { ...buttonCss, color } })}
        hover
        hoverValue={buttonCss["&:hover"]?.color || buttonDefaultCss["&:hover"]?.color}
        defaultHoverValue={buttonDefaultCss["&:hover"]?.color}
        handleHoverChange={(color) =>
          handleChange({
            buttonCss: {
              ...buttonCss,
              "&:hover": { ...buttonCss["&:hover"], color },
            },
          })
        }
      />
      <BackgroundColorCSS
        value={buttonCss?.backgroundColor || buttonDefaultCss.backgroundColor}
        defaultValue={buttonDefaultCss.backgroundColor}
        isValueChanged={false}
        handleChange={(backgroundColor) => handleChange({ buttonCss: { ...buttonCss, backgroundColor } })}
        hover
        hoverValue={buttonCss["&:hover"]?.backgroundColor || buttonDefaultCss["&:hover"].backgroundColor}
        defaultHoverValue={buttonDefaultCss["&:hover"].backgroundColor}
        handleHoverChange={(backgroundColor) =>
          handleChange({
            buttonCss: {
              ...buttonCss,
              "&:hover": { ...buttonCss["&:hover"], backgroundColor },
            },
          })
        }
      />
      <BorderColorCSS
        value={buttonCss?.borderColor || buttonDefaultCss.borderColor}
        defaultValue={buttonDefaultCss.borderColor}
        isValueChanged={false}
        handleChange={(borderColor) => handleChange({ buttonCss: { ...buttonCss, borderColor } })}
        hover
        hoverValue={buttonCss["&:hover"]?.borderColor || buttonDefaultCss["&:hover"].borderColor}
        defaultHoverValue={buttonDefaultCss["&:hover"].borderColor}
        handleHoverChange={(borderColor) =>
          handleChange({
            buttonCss: {
              ...buttonCss,
              "&:hover": { ...buttonCss["&:hover"], borderColor },
            },
          })
        }
      />
      <Typography className={classes.title}>{I18n.t("rich_text_editor.button.other_button_properties")}</Typography>
      {alignment && (
        <HorizontalAlignmentCSS
          value={alignment || "center"}
          defaultValue={"center"}
          handleChange={(alignment) => handleChange({ alignment })}
        />
      )}
      <PaddingCSS
        value={buttonCss?.padding}
        defaultValue={buttonDefaultCss?.padding}
        handleChange={(padding) => handleChange({ buttonCss: { ...buttonCss, padding } })}
      />
      <MarginCSS
        value={buttonCss?.margin}
        defaultValue={buttonDefaultCss?.margin}
        handleChange={(margin) => handleChange({ buttonCss: { ...buttonCss, margin } })}
      />
    </div>
  );
};

export default ButtonOldElement;
