import { COMMUNITY_EDITOR } from "./constants/actionTypes";
import { filter, uniqBy } from "lodash";
import moment from "moment";
import RichTextEditorHelper from "../editor/helper/richTextEditorHelper";

const initialState = {
  question: null,
  questionLoaded: false,
  questionInitialState: null,
  tags: [],
  isAnswerEditor: false,
  writerAnswer: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COMMUNITY_EDITOR.COMMUNITY_EDITOR_WRITERS_LOADED:
      return {
        ...state,
        questionEditorWriters: [{ email: "All", name: "Any writer" }, ...(action.payload.writers || [])],
      };
    case COMMUNITY_EDITOR.COMMUNITY_EDITOR_DATA_LOADED: {
      const data = {
        ...state,
        question: action.payload.question,
        questionLoaded: true,
        questionInitialState: action.payload.question?.state,
        isAnswerEditor: action.isAnswerEditor,
      };
      if (action.payload.questionAnswerId) {
        data.writerAnswer = action.payload;
      }

      return data;
    }
    case COMMUNITY_EDITOR.COMMUNITY_EDITOR_CLEANUP:
      return {
        ...state,
        question: initialState.question,
        writerAnswer: initialState.writerAnswer,
        questionLoaded: initialState.questionLoaded,
        questionInitialState: initialState.questionInitialState,
      };
    case COMMUNITY_EDITOR.COMMUNITY_QUESTION_SAVED: {
      const { question } = action;
      return {
        ...state,
        question,
      };
    }
    case COMMUNITY_EDITOR.COMMUNITY_QUESTION_CHANGED:
      return {
        ...state,
        question: action.question,
      };
    case COMMUNITY_EDITOR.COMMUNITY_QUESTION_POSTED_TIME_CHANGED: {
      let lastDate = moment(action.postedTime);
      return {
        ...state,
        question: {
          ...state.question,
          postedTime: moment(action.postedTime),
          answers: state.question.answers.map((answer) => {
            lastDate = moment(lastDate).add(1 + Math.floor(Math.random() * 5), "day");
            return {
              ...answer,
              postedTime: lastDate.toDate(),
            };
          }),
        },
      };
    }
    case COMMUNITY_EDITOR.COMMUNITY_ADD_NEW_ANSWER: {
      return {
        ...state,
        question: {
          ...state.question,
          answers: [
            ...(state.question.answers || []),
            { text: "", richText: RichTextEditorHelper.getEmptyRichText(), postedTime: Date.now(), publish: false },
          ],
        },
      };

      // if (!state.question || state.question.answers.length === 0) {
      //   return {
      //     ...state,
      //     question: {
      //       ...state.question,
      //       answers: [
      //         { text: "", richText: RichTextEditorHelper.getEmptyRichText(), postedTime: Date.now(), publish: false },
      //       ],
      //     },
      //   };
      // }
      // const newAnswers = state.question.answers.slice();
      // newAnswers.splice(action.index, 0, {
      //   text: "",
      //   richText: RichTextEditorHelper.getEmptyRichText(),
      //   postedTime: moment(
      //     state.question.answers[Math.min(action.index, state.question.answers.length - 1)].postedTime
      //   ).toDate(),
      //   publish: false,
      // });
      // return {
      //   ...state,
      //   question: {
      //     ...state.question,
      //     answers: newAnswers,
      //   },
      // };
    }
    case COMMUNITY_EDITOR.COMMUNITY_ANSWER_CHANGED: {
      return {
        ...state,
        question: {
          ...state.question,
          answers: state.question.answers.map((answer, index) => (action.index === index ? action.answer : answer)),
        },
      };
    }
    case COMMUNITY_EDITOR.COMMUNITY_ANSWER_POSTED_TIME_CHANGED: {
      let lastDate = moment(action.postedTime);
      return {
        ...state,
        question: {
          ...state.question,
          answers: state.question.answers.map((answer, index) => {
            if (index === action.index) {
              return { ...answer, postedTime: action.postedTime };
            }
            if (index > action.index) {
              lastDate = moment(lastDate).add(1 + Math.floor(Math.random() * 5), "day");
              return { ...answer, postedTime: lastDate };
            }
            return answer;
          }),
        },
      };
    }
    case COMMUNITY_EDITOR.COMMUNITY_ANSWER_DELETED:
      return {
        ...state,
        question: {
          ...state.question,
          answers: state.question.answers.filter((a, index) => index !== action.index),
        },
      };

    case COMMUNITY_EDITOR.ON_TAGS_LOADED:
      return {
        ...state,
        tags: action.payload.tags,
      };
    case COMMUNITY_EDITOR.ON_TAG_SELECTED:
      return {
        ...state,
        question: {
          ...state.question,
          tags: uniqBy([...(state.question.tags || []), action.tag], "_id"),
        },
      };
    case COMMUNITY_EDITOR.ON_TAG_REMOVED:
      return {
        ...state,
        question: {
          ...state.question,
          tags: filter(state.question.tags, (tag) => tag.name !== action.tag.name),
        },
      };
    case COMMUNITY_EDITOR.ON_NEW_TAG_CREATED:
      return {
        ...state,
        tags: uniqBy([...state.tags, action.tag], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        question: {
          ...state.question,
          tags: uniqBy([...state.question.tags, action.tag], "_id"),
        },
      };
    case COMMUNITY_EDITOR.COMMUNITY_WRITER_ANSWER_CHANGED: {
      return {
        ...state,
        writerAnswer: action.writerAnswer,
      };
    }
    case COMMUNITY_EDITOR.COMMUNITY_WRITER_ANSWER_POSTED_TIME_CHANGED: {
      return {
        ...state,
        writerAnswer: {
          ...state.writerAnswer,
          postedTime: moment(action.postedTime).toISOString(),
        },
      };
    }
    default:
      return state;
  }
};
