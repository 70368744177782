import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import numeral from "numeral";
import Classnames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  metric: {
    fontSize: "14px",
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: "100%",
    width: "10%",
  },
  firstColumn: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: "14px",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  actionsWrapper: {
    gap: 20,
    alignItems: "center",
    display: "none",
    position: "absolute",
    right: 20,
    zIndex: 2,
    width: "fit-content",
    backgroundColor: theme.palette.secondary.main,
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionIcon: {
    height: 18,
    fill: theme.palette.text.secondary,
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "20px 0px",
    justifyContent: "space-between",
  },
  listItemFirstColumn: {
    width: "35%",
    paddingLeft: "15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  midMetric: {
    width: "20%",
  },
}));

type PropTypes = {
  relatedKeyword: any;
};

const RelatedPagesKeywordDialogListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { relatedKeyword } = props;

  return (
    <ListItem className={classNames(classes.listRow)}>
      <div className={classNames(classes.metric, classes.listItemFirstColumn)}>
        <Typography className={classes.firstColumn}>{relatedKeyword.keyword}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedKeyword.main_impressions || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedKeyword.main_clicks || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedKeyword.main_ctr || 0).format("0.0%")}</Typography>
      </div>
      <div className={Classnames(classes.metric, classes.midMetric)}>
        <Typography>{numeral(relatedKeyword.main_position || 0).format("0.0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedKeyword.impressions || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedKeyword.clicks || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedKeyword.ctr || 0).format("0.0%")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedKeyword.position || 0).format("0.0a")}</Typography>
      </div>
    </ListItem>
  );
};

export default RelatedPagesKeywordDialogListItem;
