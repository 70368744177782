import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { useTheme } from "@material-ui/core/styles";
import CfButton from "../../components/CfButton";
import { IKeywordType, IWebsite, IWebsiteGoal } from "../../reducers/constants/objectTypes";
import { KEYWORD_CLASS_LEVELS } from "../../helpers/constants";
import { KEYWORDCLASSIFICATION } from "../../reducers/constants/actionTypes";
import agent from "../../agent";
import MTableRow from "../../components/Table/Rows/MTableRow";
import MTableCellNumber from "../../components/Table/Cells/MTableCellNumber";
import MTableCellSelect from "../../components/Table/Cells/MTableCellSelect";
import MTableCellText from "../../components/Table/Cells/MTableCellText";
import MTableCell from "../../components/Table/Cells/MTableCell";
import MTableCellFirstWithSelect from "../../components/Table/Cells/MTableCellFirstWithSelect";
import MTableCellSelectMultiple from "../../components/Table/Cells/MTableCellSelectMultiple";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    "&:hover": {
      "& $deleteButton": {
        display: "block",
      },
    },
  },
  content: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    height: "100%",
    borderTop: (props: PropTypes) =>
      props.isFirstRow ? "1px solid transparent" : `1px solid ${theme.palette.divider}`,
    borderBottom: (props: PropTypes) =>
      props.isLastRow ? `1px solid ${theme.palette.divider}` : "1px solid transparent",
  },
  firstColumnWrapper: {
    width: "25%",
  },
  firstColumn: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  column: {
    flexGrow: 1,
    flexBasis: 0,
    fontWeight: theme.typography.fontWeightLight as any,
    width: "10%",
  },
  lastColumn: {
    flexGrow: 2,
    flexBasis: 0,
    display: "flex",
    justifyContent: "flex-end",
  },

  iconWrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: 15,
    minWidth: "auto",
  },
  icon: {
    minWidth: 20,
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  uncheckedIconWrapper: {},

  checkIconWrapper: {
    backgroundColor: theme.palette.primary.main,
  },
  checkIcon: {
    fill: theme.palette.primary.contrastText,
  },
  deleteButton: {
    display: "none",
    border: "solid 1px #F04444",
    backgroundColor: "#F04444",
    padding: "10px 30px",
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: theme.typography.pxToRem(14),
    "&:hover": {
      color: "#F04444",
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    height: 20,
    width: 20,
    minWidth: 20,
    borderRadius: "50%",
    border: `1px solid ${theme.palette.text.secondary}`,
  },
  select: {
    textTransform: "capitalize",
    cursor: "pointer",
    marginLeft: -10,
    paddingRight: 10,
  },
}));

const mapStateToProps = (state) => ({
  selectedTypeFilter: state.keywordsClassification.selectedType,
  selectedLevelFilter: state.keywordsClassification.selectedLevel,
  selectedTopicFilter: state.keywordsClassification.selectedTopic,
  selectedWordsCount: state.keywordsClassification.selectedWordsCount,
  paginationNumber: state.keywordsClassification.paginationNumber,
  page: state.keywordsClassification.page,
  searchText: state.keywordsClassification.searchText,
  selectedWebsite: state.home.selectedWebsite,
  types: state.keywordsClassification.types,
  topics: state.keywordsClassification.tags,
  levels: state.keywordsClassification.levels,
  selectedKeywordsClassificationList: state.keywordsClassification.selectedKeywordsClassificationList,
  keywordsClassificationList: state.keywordsClassification.data,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedKeywordsClassificationList: (selectedKeywordsClassificationList) =>
    dispatch({
      type: KEYWORDCLASSIFICATION.CHANGE_SELECTED_KEYWORDS_CLASSIFICATION_LIST,
      payload: selectedKeywordsClassificationList,
    }),
  changeTypes(types) {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_TYPES, payload: types });
  },
  loading: (loading) => {
    dispatch({ type: KEYWORDCLASSIFICATION.LOADING, loading });
  },
  keywordsClassificationLoaded: (payload) => {
    dispatch({ type: KEYWORDCLASSIFICATION.KEYWORDS_CLASSIFICATION_LOADED, payload });
  },
});

type PropTypes = {
  clientGoals: IWebsiteGoal[];
  searchText: string;
  selectedWebsite: IWebsite;
  selectedKeywordsClassificationList: any[];
  keywordsClassificationListItem: any;
  isLastRow: boolean;
  isFirstRow: boolean;
  types: IKeywordType[];
  topics: { _id: number; name: "Yes" | "No" }[];
  levels: { _id: number; name: keyof typeof KEYWORD_CLASS_LEVELS }[];
  selectedTypeFilter: string;
  selectedLevelFilter: string;
  selectedTopicFilter: string;
  selectedWordsCount: number;
  page: number;
  paginationNumber: string;

  loading: () => void;
  keywordsClassificationLoaded: (payload: any) => void;
  changeTypes: (types) => void;
  setSelectedKeywordsClassificationList: (selectedKeywordsClassificationList) => void;
};

const KeywordsClassificationListItem = (props: PropTypes) => {
  const {
    keywordsClassificationListItem,
    setSelectedKeywordsClassificationList,
    clientGoals,
    selectedKeywordsClassificationList,
    types,
    selectedWebsite,
    changeTypes,
    levels,
    topics,
    page,
    paginationNumber,
    selectedTypeFilter,
    selectedLevelFilter,
    selectedTopicFilter,
    selectedWordsCount,
    loading,
    keywordsClassificationLoaded,
    searchText,
  } = props;

  const theme = useTheme();
  const classes = useStyles(props);

  const [selectedType, setSelectedType] = React.useState(
    types?.find((t) => t.name === keywordsClassificationListItem?.type?.name) ?? null
  );

  const [selectedGoals, setSelectedGoals] = React.useState(
    clientGoals.filter((c) => !!keywordsClassificationListItem.goals.find((g) => g._id === c._id))
  );

  const [selectedLevel, setSectedLevel] = React.useState(
    levels?.find((l) => l.name === keywordsClassificationListItem.level) ?? null
  );
  const [selectedTopic, setSelectedTopic] = React.useState(
    topics?.find((t) => t.name === keywordsClassificationListItem.tag) ?? null
  );

  const selected = React.useMemo(
    () => selectedKeywordsClassificationList?.find((kw) => kw._id === keywordsClassificationListItem._id),
    [selectedKeywordsClassificationList]
  );

  const getKeywords = async () => {
    const res = await agent.KeywordsClassification.getKeywords(
      selectedWebsite._id,
      selectedTopicFilter,
      selectedLevelFilter,
      selectedTypeFilter,
      selectedWordsCount,
      searchText,
      paginationNumber,
      page
    );

    keywordsClassificationLoaded(res);
  };

  const deleteItem = async () => {
    loading();
    await agent.KeywordsClassification.deleteKeyword(keywordsClassificationListItem._id);
    getKeywords();
  };

  const updateType = async (type: typeof selectedType) => {
    await agent.KeywordsClassification.updateKeyword(keywordsClassificationListItem._id, {
      ...keywordsClassificationListItem,
      type: type._id,
    });
    setSelectedType(type);
  };

  const updateLevel = async (level: typeof selectedLevel) => {
    await agent.KeywordsClassification.updateKeyword(keywordsClassificationListItem._id, {
      ...keywordsClassificationListItem,
      level: level.name,
    });
    setSectedLevel(level);
  };

  const updateGoals = async (goals: IWebsiteGoal[]) => {
    const newGoals = goals.map((g) => g._id);

    await agent.KeywordsClassification.updateKeyword(keywordsClassificationListItem._id, {
      ...keywordsClassificationListItem,
      goals: newGoals,
    });

    setSelectedGoals(goals);
  };

  const updateTopic = async (topic: typeof selectedTopic) => {
    await agent.KeywordsClassification.updateKeyword(keywordsClassificationListItem._id, {
      ...keywordsClassificationListItem,
      tag: topic.name,
    });
    setSelectedTopic(topic);
  };

  const handleSearchTextChangeDebounce = React.useCallback(
    _.debounce((searchText: string) => {
      agent.KeywordsClassification.getKeywordsTypes(selectedWebsite._id, searchText, undefined, undefined, true)
        .then((res) => {
          changeTypes(_.orderBy([...res.data, { value: 0, name: "All" }], ["name"], ["asc"]));
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500),
    []
  );

  const getTypes = (search = "") => {
    handleSearchTextChangeDebounce(search);
  };
  const handleClick = (value) => {
    if (value) {
      setSelectedKeywordsClassificationList([...selectedKeywordsClassificationList, keywordsClassificationListItem]);
    } else {
      setSelectedKeywordsClassificationList(
        selectedKeywordsClassificationList.filter((s) => s._id !== keywordsClassificationListItem._id)
      );
    }
  };

  return (
    <MTableRow handleRowClick={() => {}} selected={selected} customClassName={classes.row}>
      <MTableCellFirstWithSelect
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(14)}
        selected={selected}
        onSelect={(val) => handleClick(val)}
        cellText={keywordsClassificationListItem.keyword}
      />
      <MTableCellNumber value={keywordsClassificationListItem.count} placeHolder={"-"} />
      <MTableCellSelect
        options={types.filter((t) => t.name !== "All")}
        value={selectedType}
        placeholder={I18n.t("classify_keywords.select_ph")}
        handleInputChange={getTypes}
        handleChange={updateType}
      />
      <MTableCellSelect
        options={levels}
        value={selectedLevel}
        placeholder={I18n.t("classify_keywords.select_ph")}
        handleChange={updateLevel}
      />
      <MTableCellSelectMultiple
        value={selectedGoals}
        columnWidth={"15%"}
        options={clientGoals}
        placeholder={I18n.t("classify_keywords.select_ph")}
        handleChange={(values) => {
          updateGoals(values);
        }}
      />
      <MTableCellText
        text={
          keywordsClassificationListItem.createdAt ? moment(keywordsClassificationListItem.createdAt).fromNow() : "-"
        }
      />
      <MTableCell columnWidth={"15%"}>
        <CfButton
          height={36}
          customClass={classes.deleteButton}
          text={I18n.t("classify_keywords.exclude_exact_match")}
          onClick={(e) => {
            e.stopPropagation();
            deleteItem();
          }}
        />
      </MTableCell>
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordsClassificationListItem);
