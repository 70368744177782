import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#fff",
    fontSize: 16,
  },
}));

type PropTypes = {
  className?: string;
};

const BoldIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 11 13"}>
      <path d="M2.71094 13C2.19427 13 1.7526 12.8273 1.38594 12.4819C1.01927 12.1365 0.835938 11.7204 0.835938 11.2337V1.7663C0.835938 1.27959 1.01927 0.863527 1.38594 0.518116C1.7526 0.172706 2.19427 0 2.71094 0H6.18594C7.31927 0 8.2986 0.298309 9.12394 0.894928C9.9486 1.49155 10.3609 2.32367 10.3609 3.3913C10.3609 4.14493 10.1736 4.74532 9.79894 5.19246C9.4236 5.64024 8.98594 5.97403 8.48594 6.19384V6.3587C9.2026 6.5628 9.77361 6.91606 10.1989 7.41848C10.6236 7.92089 10.8359 8.58031 10.8359 9.39674C10.8359 10.5429 10.3903 11.43 9.49894 12.058C8.60694 12.686 7.5526 13 6.33594 13H2.71094ZM3.31094 5.34601H5.98594C6.56927 5.34601 7.04427 5.18901 7.41094 4.875C7.7776 4.56099 7.96094 4.16848 7.96094 3.69746C7.96094 3.21075 7.7776 2.81415 7.41094 2.50768C7.04427 2.20184 6.56927 2.04891 5.98594 2.04891H3.31094V5.34601ZM3.31094 10.904H6.18594C6.8526 10.904 7.3816 10.7391 7.77294 10.4094C8.16494 10.0797 8.36094 9.64795 8.36094 9.11413C8.36094 8.58031 8.16494 8.14855 7.77294 7.81884C7.3816 7.48913 6.8526 7.32428 6.18594 7.32428H3.31094V10.904Z" />
    </SvgIcon>
  );
};

export default BoldIcon;
