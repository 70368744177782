import React, { useEffect, useRef, useState } from "react";
import agent from "../../../agent";
import { replace } from "connected-react-router";
import { HOME } from "../../../reducers/constants/actionTypes";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";

type PropTypes = {
  onLogin: Function;
  onAdminUserLoaded: Function;
};

const mapDispatchToProps = (dispatch) => ({
  onLogin: (redirect) => dispatch(replace(redirect)),
  onAdminUserLoaded: (payload) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: payload.user }),
});

const GoogleLogin = (props: PropTypes) => {
  const { onLogin, onAdminUserLoaded } = props;

  const [retryCounter, setCounter] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const head = document.querySelector("head");

    const script = document.createElement("script");
    script.setAttribute("id", "googleScriptTag");
    script.setAttribute("src", "https://accounts.google.com/gsi/client");
    script.setAttribute("async", "");
    script.setAttribute("defer", "");

    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    try {
      const scriptTag = document.getElementById("googleScriptTag");

      if (!scriptTag) throw new Error("Tag dosent exist");

      // @ts-ignore
      google.accounts.id.initialize({
        client_id: "759305923460-o4j43uvmf6an38a1rj8dm0ib7vm6gp1o.apps.googleusercontent.com",
        callback: handleCredentialResponse,
      });

      // @ts-ignore
      google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
      { theme: "outline", size: "large", shape: "circle", text: "signup_with", width: 320 }
      );
    } catch (e) {
      setCounter((old) => old + 1);
    }
  }, [retryCounter]);

  function handleCredentialResponse(response) {
    agent.Auth.googleLogin(response.credential)
      .then((res) => {
        if (res.user) {
          onAdminUserLoaded(res);
          onLogin("/");
        } else enqueueSnackbar(I18n.t("login.google_user_not_registered"));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return <div id={"buttonDiv"}></div>;
};

export default connect(null, mapDispatchToProps)(GoogleLogin);
