import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Button from "@material-ui/core/Button";
import { IAd, IAdPlacement } from "../../../../../../../reducers/constants/objectTypes";
import MTextField from "../../../../../../../components/MTextField";
import CfCheckBox from "../../../../../../../components/CfCheckBox";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: 50,
    display: "flex",
    flexDirection: "row",
  },
  paper: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 700,
    minHeight: 400,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F5F6F8",
  },
  topBar: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRight: `${theme.palette.divider} solid 1px`,
    padding: 40,
  },
  formWrapper: {
    flex: 1,
    padding: 40,
  },
  businessImage: {
    backgroundSize: "contain",
    borderRadius: "50%",
  },
  businessDetails: {},
  businessName: {},
  sponsored: {},
  adText: {
    paddingTop: 10,
    paddingLeft: 25,
    paddingRight: 25,
  },
  adImage: {
    marginTop: 15,
    backgroundSize: "contain",
    display: "block",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: 15,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 25,
  },
  adTitle: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  callToAction: {
    marginTop: 20,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(16),
    textTransform: "capitalize",
  },
  textFieldInput: {
    minHeight: 29,
    backgroundColor: "white",
    borderRadius: 25,
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  consentWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    marginLeft: 5,
  },
}));

type PropTypes = {
  ad: IAd;
  placement: IAdPlacement;
};

const PostFormPreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { ad, placement } = props;

  return (
    <div className={classes.wrapper}>
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.topBar}>
          <div className={classes.businessDetails}>
            <Typography className={classes.businessName} variant={"subtitle2"}>
              {ad.form.title}
            </Typography>
            <Typography className={classes.sponsored} variant={"body2"}>
              {ad.form.text}
            </Typography>
          </div>
        </div>
        <div className={classes.formWrapper}>
          {ad.form.fields.map((field, index) => (
            <div>
              {/* <Typography className={classes.fieldTitle} variant={'body1'}> */}
              {/*  {field.name} */}
              {/* </Typography> */}
              <MTextField
                fullWidth
                outlined
                inputClassName={classes.textFieldInput}
                value={""}
                onChange={() => null}
                placeholder={field.placeholder}
              />
            </div>
          ))}
          {ad.form.consentText && (
            <div className={classes.consentWrapper}>
              <CfCheckBox value={"example check box"} checked={false} />
              <Typography color={"textPrimary"} variant={"body2"}>
                {ad.form.consentText}
              </Typography>
            </div>
          )}
          <Button fullWidth className={classes.callToAction} onClick={(e) => console.log("")}>
            {ad.form.button}
            <KeyboardArrowRight />
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default PostFormPreview;
