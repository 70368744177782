import { TextField, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ClassNames from "classnames";
import { ThumbDown, ThumbUp } from "@material-ui/icons";
import theme from "../../../../../../../../../themes/theme";

type PropTypes = {
  like: boolean;
  label?: string;
  value?: string;
  placeholder?: string;
  onChange?(event: any): unknown;
  className?: string;
};

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      minHeight: 55,
      width: "100%",
      zIndex: 1,
      padding: 15,
      fontSize: 14,
    }),
    label: (props: PropTypes) => {
      const color = isDark ? palette.text.primary : palette.secondary.main;

      const colorOnHover = isDark ? palette.text.primary : palette.text.primary;

      return {
        fontSize: 14,
        "& label": {
          "&.Mui-focused": {
            color,
          },
          color: colorOnHover,
        },
      };
    },
    input: (props: PropTypes) => {
      const color = isDark ? palette.text.secondary : palette.text.primary;
      return {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightRegular as any,
        padding: 0,
        marginLeft: 10,
        color,
      };
    },
  };
});

const LikeProperty = (props: PropTypes) => {
  const { like, className, value, onChange } = props;
  const classes = useStyles(props);
  return (
    <div className={`${classes.root}`}>
      <TextField
        className={ClassNames(classes.label, className)}
        fullWidth
        margin="none"
        value={value}
        onChange={onChange}
        InputProps={{
          disableUnderline: true,
          startAdornment: like === true ? <ThumbUp /> : <ThumbDown />,
          classes: {
            input: classes.input,
          },
        }}
      />
    </div>
  );
};

export default LikeProperty;
