import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import { useMemo } from "react";
import MTableHeader from "../../components/Table/Table/MTableHeader";
import MTableHeaderLabelWithSelectAll from "../../components/Table/Table/MTableHeaderLabelWithSelectAll";
import MTableHeaderLabel from "../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../components/Table/Table/MTableHeaderSortLabel";

type PropTypes = {
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  setSortField?: (input: { field: string; direction: "asc" | "desc" }) => void;
  selectedNr: number;
  clearSelected?(): void;
  selectAll?(pending: boolean): void;
  onlyFirstColumn?: boolean;
  reviewTotal: number;
  pendingTotal: number;
  isApproveSection?: boolean;
  selectedColumns?: string[];
};

const MappingListColumns = (props: PropTypes) => {
  const {
    sortField,
    setSortField,
    selectedNr,
    clearSelected,
    selectAll,
    onlyFirstColumn = false,
    pendingTotal,
    reviewTotal,
    selectedColumns,
  } = props;

  const getHeading = () =>
    `${String(numeral(pendingTotal).format("0,0a"))} ${
      pendingTotal !== 1
        ? I18n.t("strategy.outlinePages.pending_topics")
        : I18n.t("strategy.outlinePages.pending_topic")
    }`;
  const columnSet = useMemo(() => new Set(selectedColumns), [selectedColumns]);
  const getSubHeading = () =>
    `${String(numeral(reviewTotal).format("0,0a"))} ${
      reviewTotal !== 1
        ? I18n.t("strategy.outlinePages.reviewed_topics")
        : I18n.t("strategy.outlinePages.reviewed_topic")
    }`;
  const theme = useTheme();
  if (onlyFirstColumn) {
    return (
      <MTableHeader>
        <MTableHeaderLabelWithSelectAll
          selected={selectedNr > 0}
          onSelectAll={() => selectAll(false)}
          onClearAll={clearSelected}
          columnWidth={"33%"}
          fontSize={theme.typography.pxToRem(21)}
        >
          {getSubHeading()}
        </MTableHeaderLabelWithSelectAll>
      </MTableHeader>
    );
  }
  return (
    <MTableHeader>
      <MTableHeaderLabelWithSelectAll
        selected={selectedNr > 0}
        onSelectAll={() => selectAll(true)}
        onClearAll={clearSelected}
        columnWidth={"20%"}
      >
        {getHeading()}
      </MTableHeaderLabelWithSelectAll>
      {columnSet.has("suggested_title") && (
        <MTableHeaderLabel columnWidth={"10%"}>
          {I18n.t("strategy.strategyKeywords.listColumns.suggested_title")}
        </MTableHeaderLabel>
      )}
      {columnSet.has("feedback") && (
        <MTableHeaderLabel> {I18n.t("strategy.strategyKeywords.listColumns.feedback")}</MTableHeaderLabel>
      )}
      {columnSet.has("status") && (
        <MTableHeaderLabel> {I18n.t("strategy.strategyKeywords.listColumns.status")}</MTableHeaderLabel>
      )}
      {columnSet.has("conversion_potential") && (
        <MTableHeaderLabel> {I18n.t("strategy.strategyKeywords.listColumns.funnel")}</MTableHeaderLabel>
      )}
      {columnSet.has("post_type") && (
        <MTableHeaderLabel> {I18n.t("strategy.strategyKeywords.listColumns.postType")}</MTableHeaderLabel>
      )}
      {columnSet.has("pages") && (
        <MTableHeaderLabel> {I18n.t("strategy.strategyKeywords.listColumns.pages")}</MTableHeaderLabel>
      )}
      {columnSet.has("clicks") && (
        <MTableHeaderSortLabel onClick={(result) => setSortField(result)} sortField={sortField} field={"clicks"}>
          {I18n.t("strategy.strategyKeywords.listColumns.traffic")}
        </MTableHeaderSortLabel>
      )}
      {columnSet.has("variations") && (
        <MTableHeaderLabel> {I18n.t("strategy.strategyKeywords.listColumns.kws")}</MTableHeaderLabel>
      )}
      {columnSet.has("volume") && (
        <MTableHeaderSortLabel onClick={(result) => setSortField(result)} sortField={sortField} field={"volume"}>
          {I18n.t("strategy.strategyKeywords.listColumns.vol")}
        </MTableHeaderSortLabel>
      )}
      {columnSet.has("reviewed_at") && (
        <MTableHeaderSortLabel onClick={(result) => setSortField(result)} sortField={sortField} field={"reviewed_at"}>
          {I18n.t("strategy.strategyKeywords.listColumns.reviewedAt")}
        </MTableHeaderSortLabel>
      )}
      {columnSet.has("added_at") && (
        <MTableHeaderSortLabel onClick={(result) => setSortField(result)} sortField={sortField} field={"submitted_at"}>
          {I18n.t("strategy.strategyKeywords.listColumns.added")}
        </MTableHeaderSortLabel>
      )}
      <MTableHeaderLabel>{""}</MTableHeaderLabel>
    </MTableHeader>
  );
};

export default MappingListColumns;
