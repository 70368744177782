import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Classnames from "classnames";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import {
  IKeywordHeadingCluster,
  IKeywordSerpCleaned,
  IOutlinePages,
} from "../../../../../reducers/constants/objectTypes";
import SerpDialogListItem from "./SerpDialogListItem";
import Typography from "@material-ui/core/Typography";
import CfProgressBar from "../../../../../components/CfProgressBar";
import numeral from "numeral";

const useStyles = makeStyles((theme: Theme) => ({
  dialogListSubHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
    backgroundColor: theme.palette.background.default,
    margin: "15px 0px",
    top: 2,
  },
  navDialogTabs: {
    display: "flex",
    width: "55%",
    flexDirection: "row",
  },
  dialogTabs: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    flex: "0 0 auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: theme.typography.fontWeightBold as any,
    width: "10%",
  },
  firstDialogTab: {
    width: "55%",
  },
  tab: {
    padding: "2px 40px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    cursor: "pointer",
    borderBottom: `1px solid #C4C4C4`,
  },
  activeTab: {
    color: theme.palette.text.primary,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    transition: "border .3s",
  },
  headerIcon: {
    width: 24,
    height: 24,
  },
  subDialogTitleWrapper: {
    marginTop: 30,
    marginBottom: 10,
  },
  subDialogTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },

  sortIcon: { color: `${theme.palette.text.primary} !important` },
}));

const mapStateToProps = (state) => ({
  serps: state.keywordStrategy.serps,
  serpsLoading: state.keywordStrategy.serpsLoading,
  pages: state.keywordStrategy.pages,
  broadKeywords: state.keywordStrategy.broadKeywords,
});

type PropTypes = {
  pages: IOutlinePages[];
  serps: IKeywordSerpCleaned[];
  pageTab: "outlines" | "keywords" | "broadKeywords" | "pages" | "autoVideoInterview" | "serps";
  serpsLoading: boolean;
  setPageTab: (tab: "outlines" | "keywords" | "broadKeywords" | "pages" | "autoVideoInterview" | "serps") => void;
  broadKeywords: IKeywordHeadingCluster[];
};

const SerpDialogList = (props: PropTypes) => {
  const classes = useStyles(props);

  const { pages, serps, setPageTab, pageTab, broadKeywords, serpsLoading } = props;

  return (
    <div>
      <ListSubheader className={classes.dialogListSubHeader}>
        <div className={Classnames(classes.dialogTabs, classes.firstDialogTab)}>
          <div className={classes.navDialogTabs}>
            <div
              className={Classnames(pageTab === "keywords" && classes.activeTab, classes.tab)}
              onClick={() => {
                setPageTab("keywords");
              }}
            >
              {numeral(broadKeywords?.length || 0).format("0,0a")}{" "}
              {broadKeywords?.length === 1
                ? I18n.t("strategy.outlinePages.dialog_header.keyword")
                : I18n.t("strategy.outlinePages.dialog_header.keywords")}
            </div>
            <div
              className={Classnames(pageTab === "pages" && classes.activeTab, classes.tab)}
              onClick={() => {
                setPageTab("pages");
              }}
            >
              {numeral(pages?.length || 0).format("0,0a")}{" "}
              {pages?.length === 1
                ? I18n.t("strategy.outlinePages.dialog_header.page")
                : I18n.t("strategy.outlinePages.dialog_header.pages")}
            </div>
            <div
              className={Classnames(pageTab === "serps" && classes.activeTab, classes.tab)}
              onClick={() => {
                setPageTab("serps");
              }}
            >
              {numeral(serps?.length || 0).format("0,0a")}{" "}
              {serps?.length === 1
                ? I18n.t("strategy.outlinePages.dialog_header.serp")
                : I18n.t("strategy.outlinePages.dialog_header.serps")}
            </div>
          </div>
        </div>
      </ListSubheader>

      <div className={classes.subDialogTitleWrapper}>
        <Typography className={classes.subDialogTitle}>
          {numeral(serps?.length || 0).format("0,0a")}{" "}
          {serps?.length === 1
            ? I18n.t("strategy.outlinePages.dialog_header.result")
            : I18n.t("strategy.outlinePages.dialog_header.results")}
        </Typography>
      </div>
      {serpsLoading ? <CfProgressBar /> : serps?.map((s, index) => <SerpDialogListItem key={index} serp={s} />)}
    </div>
  );
};
export default connect(mapStateToProps, null)(SerpDialogList);
