import { PAGE_EDITOR } from "./constants/actionTypes";

const initialState = {
  pageLoaded: false,
  editor: null,
  page: null,
  pageCluster: null,
  pageRichText: null,
  saveInProgress: false,
  autoSaveOn: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAGE_EDITOR.CLEANUP:
      return initialState;
    case PAGE_EDITOR.PAGE_LOADED:
      return {
        ...state,
        pageLoaded: true,
        pageCluster: action.cluster,
        page: action.page,
        pageRichText: action.page.richText,
      };
    case PAGE_EDITOR.PAGE_UPDATED:
      return {
        ...state,
        page: action.page,
        pageCluster: action.cluster,
        pageRichText: action.page.richText,
      };
    case PAGE_EDITOR.EDITOR_CHANGED:
      return {
        ...state,
        editor: action.editor,
      };
    case PAGE_EDITOR.PAGE_CHANGED:
      return {
        ...state,
        page: action.page,
      };
    case PAGE_EDITOR.CLUSTER_UPDATED:
      return {
        ...state,
        pageCluster: action.cluster,
      };
    case PAGE_EDITOR.PAGE_TEXT_CHANGED:
      return {
        ...state,
        pageRichText: action.richText,
      };
    case PAGE_EDITOR.SAVE_IN_PROGRESS:
      return {
        ...state,
        saveInProgress: action.value,
      };
    case PAGE_EDITOR.AUTO_SAVE_ON:
      return {
        ...state,
        autoSaveOn: action.value,
      };
    default:
      return state;
  }
};
