import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import agent from "../../../agent";
import { useSnackbar } from "notistack";
import EntailNavbarLogo from "../../../icons/EntailNavbarLogo";
import sideImage from "../../../img/signup_side.png";
import { eEntailProducts, eMpEvent, IAdminUser, IWebsite } from "../../../reducers/constants/objectTypes";
import Typography from "@material-ui/core/Typography";
import FlowTextField from "./components/FlowTextField";
import MButton from "../../../components/MButton";
import { ArrowForward } from "@material-ui/icons";
import ClassNames from "classnames";
import { formatUrl, isUrl, web } from "../../../helpers/urlHelper";
import { HOME } from "../../../reducers/constants/actionTypes";
import { replace } from "connected-react-router";
import { MIXPANEL_PAGE_VIEW } from "../../../reducers/middlewares/mixpanelMiddleware";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "70px 0px",
    width: 661,
  },
  topWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  imageWrapper: {
    background: "linear-gradient(90deg, #574AC1 0%, #2C2093 100%)",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  entailNavbarLogo: {},
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 30,
    lineHeight: "29px",
    margin: "30px 0",
    color: "#000",
    marginTop: 70,
  },
  inputColumn: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 15,
  },
  inputTitle: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  backIcon: {
    marginRight: 10,
  },
  nextIcon: {
    marginLeft: 10,
  },
  button: {
    padding: "10px 30px",
    borderRadius: 40,
    height: 43,
  },
  backButton: {
    visibility: "visible",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  rolesWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 35,
  },
  roles: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: 15,
    columnGap: 7,
    maxWidth: 503,
  },
}));

type PropTypes = {
  onAdminUserLoaded: (payload: any) => void;
  onWebsiteSelected: (website: IWebsite) => void;
  onBoardingDataUpdated: (payload: any) => void;
  onBoardingCompleted: () => void;
  redirectTo: (url: string) => void;
  onBoardingData: any;
  user: IAdminUser;
  website: IWebsite;
  pageLoaded: Function;
  mpEvent: (event: string, props: object) => void;
};

const mapStateToProps = (state) => ({
  onBoardingData: state.home.onBoardingData,
  user: state.home.admin,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onAdminUserLoaded: (result) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: result.user }),
  onWebsiteSelected: (selectedWebsite) => dispatch({ type: HOME.ON_WEBSITES_SELECTED, selectedWebsite }),
  onBoardingDataUpdated: (data) => dispatch({ type: HOME.ONBOARDING_DATA_UPDATED, data }),
  onBoardingCompleted: () => dispatch({ type: HOME.ONBOARDING_COMPLETED }),
  continueToNextStep: () => dispatch(replace(web.websiteInfo())),
  redirectTo: (url: string) => dispatch(replace(url)),
  pageLoaded: () =>
    dispatch({
      type: HOME.PAGE_LOADED,
      meta: {
        mixpanel: {
          event: MIXPANEL_PAGE_VIEW,
          props: { "page-name": "Onboarding preview Entail", product: eEntailProducts.cro },
        },
      },
    }),
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
});

const SelectPage = (props: PropTypes) => {
  const classes = useStyles(props);

  const {
    user,
    website,
    onBoardingData,
    onBoardingDataUpdated,
    onWebsiteSelected,
    onAdminUserLoaded,
    redirectTo,
    onBoardingCompleted,
    mpEvent,
    pageLoaded,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [invalidUrl, setInvalidUrl] = React.useState(false);
  const [pageUrl, setPageUrl] = React.useState(
    onBoardingData?.audience?.pageUrl || website?.configurations?.siteDetails?.targetAudience?.pageUrl || ""
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleContinueClick = () => {
    if (!website) {
      return;
    }
    const url = formatUrl(pageUrl);
    const eventProps: any = {
      webpage_url: pageUrl,
      website_url: website?.url,
      text: I18n.t("signup.navigation.continue"),
    };
    if (isUrl(url)) {
      const websitePageUrl = website.configurations?.siteDetails?.targetAudience?.pageUrl;
      if (websitePageUrl === url) {
        redirectTo(web.websitePagePreview());
        mpEvent(eMpEvent.onboardingPreviewButtonClick, eventProps);
        return;
      }
      setLoading(true);
      agent.Websites.croUpdateWebsite(
        {
          siteDetails: {
            ...website.configurations.siteDetails,
            targetAudience: {
              ...website.configurations.siteDetails.targetAudience,
              pageUrl: url,
            },
          },
        },
        website
      )
        .then((res) => {
          if (res.error) {
            eventProps.error_message = res.message;
            enqueueSnackbar(res.message, { variant: "error" });
            return;
          }
          onWebsiteSelected(res.website);
          onAdminUserLoaded(res);
          onBoardingCompleted();
          redirectTo(web.websitePagePreview());
        })
        .catch((e) => {
          eventProps.error_message = e.response.body.message;
          enqueueSnackbar(e.response.body.message, { variant: "error" });
        })
        .finally(() => {
          mpEvent(eMpEvent.onboardingPreviewButtonClick, eventProps);
          setLoading(false);
        });
    } else {
      mpEvent(eMpEvent.onboardingPreviewButtonClick, {
        ...eventProps,
        error_message: I18n.t("signup.personalize.website_url_error"),
      });
      setInvalidUrl(true);
    }
  };

  React.useEffect(() => {
    pageLoaded();
  }, []);

  return (
    <section className={classes.pageWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.topWrapper}>
          <EntailNavbarLogo className={classes.entailNavbarLogo} />
          <Typography className={classes.title}>{I18n.t("signup.select_page.title")}</Typography>
          <div className={classes.inputColumn}>
            <Typography className={classes.inputTitle}>{I18n.t("signup.select_page.page_url")}</Typography>
            <FlowTextField
              error={invalidUrl}
              errorMessage={I18n.t("signup.personalize.website_url_error")}
              placeholder={I18n.t("signup.select_page.page_url_ph")}
              value={pageUrl}
              onChange={(v) => {
                setInvalidUrl(false);
                onBoardingDataUpdated({
                  ...onBoardingData,
                  audience: {
                    ...onBoardingData.audience,
                    pageUrl: v,
                  },
                });
                setPageUrl(v);
              }}
            />
          </div>
        </div>
        <div className={classes.navigation}>
          {
            <MButton
              customClassNames={ClassNames(classes.button, classes.backButton)}
              onClick={() => {
                mpEvent(eMpEvent.onboardingPreviewButtonClick, {
                  text: I18n.t("signup.navigation.back"),
                  website_url: website?.url,
                });
                redirectTo(web.websiteInfo());
              }}
            >
              {I18n.t("signup.navigation.back")}
            </MButton>
          }
          <MButton loading={loading} customClassNames={classes.button} onClick={() => handleContinueClick()}>
            <span>{I18n.t("signup.navigation.continue")}</span>
            <ArrowForward className={classes.nextIcon} />
          </MButton>
        </div>
      </div>
      <div className={classes.imageWrapper}>
        <img alt={"sign up"} src={sideImage} />
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPage);
