import React from "react";
import { eModeratorAction, IAnswer } from "../../../../../../reducers/constants/objectTypes";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import agent from "../../../../../../agent";
import { useSnackbar } from "notistack";
import MButton from "../../../../../../components/MButton";
import classNames from "classnames";

type PropTypes = {
  handleQuestionChange: (question) => void;
  answer: IAnswer;
};

const mapStateToProps = (state) => ({});

const useStyles = makeStyles((theme: Theme) => ({
  moderatorWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // height: 46,
    padding: "10px 20px",
    borderTop: "1px solid #E5E5E5",
  },
  button: {
    width: "calc(50% - 10px)",
    borderRadius: 75,
    height: 40,
  },
  publishButton: {
    backgroundColor: "#4353FF",
  },
  declineButton: {
    backgroundColor: "#808080",
    "&:hover": {
      backgroundColor: "#6e6d6d",
    },
  },
}));

const mapDispatchToProps = (dispatch) => ({
  handleQuestionChange: (question) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_QUESTION_CHANGED, question }),
});

const CommunityEditorAnswerModeratorPanel = (props: PropTypes) => {
  const classes = useStyles(props);

  const { handleQuestionChange, answer } = props;
  const { enqueueSnackbar } = useSnackbar();

  const moderateAnswer = (action: eModeratorAction) => {
    agent.Community.moderateAnswer(answer, action)
      .then((res) => {
        handleQuestionChange(res.question);
        const translateKey = action === eModeratorAction.APPROVE ? "answer_is_approved" : "answer_is_declined";
        enqueueSnackbar(I18n.t(`community.moderator.${translateKey}`));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className={classes.moderatorWrapper}>
      <MButton
        customClassNames={classNames(classes.button, classes.publishButton)}
        onClick={() => moderateAnswer(eModeratorAction.APPROVE)}
      >
        <Typography>{I18n.t("community.moderator.publish")}</Typography>
      </MButton>

      <MButton
        customClassNames={classNames(classes.button, classes.declineButton)}
        onClick={() => moderateAnswer(eModeratorAction.DECLINE)}
      >
        <Typography>{I18n.t("community.moderator.delete")}</Typography>
      </MButton>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorAnswerModeratorPanel);
