import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import ClassNames from "classnames";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import DownChevronIcon from "../../../../icons/DownChevronIcon";
import { FUNNELS } from "../../../../reducers/constants/actionTypes";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import FunnelBaseSelect from "./FunnelBaseSelect";
import { isConfigValid } from "../../../../helpers/funnelHelper";

type PropTypes = {
  selectedWebsite: IWebsite;
  config: any;
  updateFunnelDetails: (funnel) => void;
  funnelsLoaded: (funnels) => void;
  clearOnError: () => void;
  options: any[];
  funnelName: string;
  funnelId: string;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  config: state.funnels.config,
  options: state.funnels.funnels,
  funnelName: state.funnels.funnelName,
  funnelId: state.funnels.funnelId,
});

const mapDispatchToProps = (dispatch) => ({
  updateConfig: (config) => dispatch({ type: FUNNELS.FUNNEL_CONFIG_UPDATED, payload: config }),
  updateFunnelName(payload) {
    dispatch({ type: FUNNELS.FUNNEL_FUNNEL_NAME_CHANGED, payload });
  },
  setEventNames(payload) {
    dispatch({ type: FUNNELS.FUNNEL_EVENT_NAMES_LOADED, payload });
  },
  updateFunnelDetails(payload) {
    dispatch({ type: FUNNELS.FUNNEL_DETAILS_LOADED, payload });
  },
  funnelsLoaded(payload) {
    dispatch({ type: FUNNELS.FUNNELS_LOADED, payload });
  },
});

const MTableToolbarFunnelSelect = (props: PropTypes) => {
  const { selectedWebsite, updateFunnelDetails, funnelsLoaded, options, funnelId, funnelName, config, clearOnError } =
    props;
  const [searchText, setSearchText] = React.useState("");
  const [selected, setSelected] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const getFunnels = () => {
    agent.Funnels.getFunnels(selectedWebsite, 1).then((res) => {
      funnelsLoaded(res.data);
      if (res.data?.length > 0) {
        updateFunnelDetails({ ...res.data[0] });
        setSelected(res.data[0]);
      }
    });
  };

  React.useEffect(() => {
    getFunnels();
  }, [selectedWebsite]);

  React.useEffect(() => {
    setSelected({ funnelName, _id: funnelId, config });
  }, [config, funnelName, funnelId]);

  const handleChange = (value) => {
    setSelected(value);
    updateFunnelDetails({ ...value });
    if (!isConfigValid(value.config)) {
      clearOnError();
      enqueueSnackbar("Funnel not valid please edit it and try again ", { variant: "error" });
    }
  };
  return (
    <FunnelBaseSelect
      optionValue={"_id"}
      optionLabel={"funnelName"}
      options={options}
      value={selected}
      placeholder={I18n.t("common.select_placeholder")}
      handleOnChange={handleChange}
    />
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(MTableToolbarFunnelSelect);
