import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import CfSearchInput from "../../../../../components/CfSearchInput";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    // flexGrow: 1,
    height: 65,
    display: "flex",
    flexDirection: "row",
    // borderBottom: "1px solid #E5E5E5",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "40px 30px 40px 30px",
  },
  content: {
    flexGrow: 1,
  },
  button: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: 5,
    marginLeft: "auto",
    fontSize: theme.typography.pxToRem(14),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold as any,
    borderRadius: 20,
    height: 36,
    minHeight: 36,
    alignSelf: "right",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#5967FF",
    },
  },
  searchField: {
    width: "270px",
    maxWidth: 457,
    height: "unset",
  },
  searchInput: {
    height: 40,
    borderRadius: 25,
    padding: 10,
    backgroundColor: "white",
  },
  select: {
    textTransform: "capitalize",
    cursor: "pointer",
    marginLeft: "15px",
  },
  refreshButton: {
    marginRight: 20,
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  refreshIcon: {
    width: 17,
    height: 17,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  searchText: string;
  handleSearchTextChange: (searchText: string) => void;
  createNewExpert: () => void;
  handleRefreshList: () => void;
};

const ExpertsHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, expertsCount, searchText, handleSearchTextChange, createNewExpert } = props;
  const { enqueueSnackbar } = useSnackbar();

  // const createNewExpert = () => {
  //   agent.Experts.new(selectedWebsite._id)
  //     .then((res) => {
  //       enqueueSnackbar(I18n.t("experts.list.create_new_expert_success"), { variant: "success" });
  //       handleRefreshList();
  //     })
  //     .catch((e) => {
  //       if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
  //     });
  // };

  return (
    <div className={classes.pageWrapper}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "start" }}>
        <CfSearchInput
          searchText={searchText}
          customClass={classes.searchField}
          customInputClass={classes.searchInput}
          placeholder={I18n.t("experts.list.search_experts")}
          handleSearchTextChange={(val) => {
            handleSearchTextChange(val);
          }}
        />
      </div>
      <div>
        <Button
          className={classes.button}
          onClick={() => {
            // createNewWidget(templateWidget);
            createNewExpert();
          }}
        >
          {I18n.t("experts.list.new_expert")}
        </Button>
      </div>
    </div>
  );
};

export default ExpertsHeader;
