import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { web } from "../../helpers/urlHelper";
import AdsListPage from "./pages/AdsList/AdsListPage";
import EditAd from "./pages/EditAd/EditAd";
import WidgetsBuilder from "./pages/WidgetsBuilder/WidgetsBuilder";
import LinksSplitTests from "./pages/LinksSplitTests/LinksSplitTests";
import { AbilityContext } from "../../casl/can";
import Dashboard from "../Analytics/Dashboard/Dashboard";
import Events from "../Analytics/Events/Events";
import ProductsEditor from "../ProductsEditor/ProductsEditor";
import Products from "./pages/Products/Products";
import Settings from "../Analytics/Settings/Settings";
import Orders from "./pages/Orders/Orders";
import { IWebsite } from "../../reducers/constants/objectTypes";
import UserEvents from "../Analytics/UserEvents/UserEvents";
import FunnelCreator from "../Analytics/FunnelCreator/FunnelCreator";
import Reports from "../Analytics/Reports/Reports";
import Campaigns from "./pages/Campaigns/Campaigns";
import WidgetPicker from "../Modals/WidgetPicker/WidgetPicker";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});
type PropTypes = {
  selectedWebsite: IWebsite;
};
const CRO = (props: PropTypes) => {
  const { selectedWebsite } = props;
  const ability = React.useContext<any>(AbilityContext);

  const routes = React.useMemo(() => {
    const allRoutes = [];
    if (ability.can("view", "cro_widgets_display_rules_tab")) {
      allRoutes.push({
        url: web.croCampaigns(),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_WIDGETS_DISPLAY_RULES`}
            exact={false}
            path={web.croCampaigns()}
            component={Campaigns}
          />
        ),
      });
    }
    if (ability.can("view", "cro_widgets_tab")) {
      allRoutes.push({
        url: web.widgets(),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_widgets`}
            exact={false}
            path={web.widgets()}
            component={WidgetsBuilder}
          />
        ),
      });
    }

    if (ability.can("view", "cro_products_tab")) {
      // allRoutes.push({
      //   url: web.croProducts(),
      //   route: (
      //     <Route
      //       key={`cro_${selectedWebsite._id}_products`}
      //       exact={true}
      //       path={web.croProducts()}
      //       component={Products}
      //     />
      //   ),
      // });
      allRoutes.push({
        url: web.croProducts(),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_products_editor`}
            exact={true}
            path={web.croProducts(":productId?")}
            component={Products}
          />
        ),
      });
    }
    if (ability.can("view", "cro_experiments_tab")) {
      allRoutes.push({
        url: web.croLinksSplitTest(),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_links_split_test`}
            exact={false}
            path={web.croLinksSplitTest()}
            component={LinksSplitTests}
          />
        ),
      });
    }
    return allRoutes;
  }, [ability, selectedWebsite]);

  return (
    <Switch>
      {routes.map((route) => route.route)}
      <Redirect exact from={"*"} to={routes[0]?.url} />
    </Switch>
  );
};

export default connect(mapStateToProps)(CRO);
