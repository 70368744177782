import superagent from "superagent";
import { IWidget } from "./reducers/constants/objectTypes";
import { DEV, STAGING } from "./reducers/constants/consts";

const API_ROOT = DEV ? "http://localhost:3003" : `https://widgets${STAGING ? '-staging' : ''}.entail.ai`;

console.log('WidgetAgent API_ROOT', API_ROOT, DEV, STAGING);

const unauthorizedRedirect = function (req) {
  req.on("response", (res) => {
    if (res.status === 401) {
      console.log("redirects", res.body.redirect);
      if (res.body.redirect) {
        window.location.replace(res.body.redirect);
      }
    }
  });
};

// const encode = encodeURIComponent;
// const responseBody = res => res.body;
const responseBody = (res) => res.body;
const responseText = (res) => res.text;

const corsPlugin = (req) => {
  // req.set('Access-Control-Allow-Origin', '*');
  req.set("Accept", "application/json");
  req.set("page-path", window.location.pathname);
};

const requests = {
  del: (url) => superagent.del(`${API_ROOT}${url}`).use(corsPlugin).withCredentials().then(responseBody),
  get: (url, headers = {}) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(corsPlugin)
      .set(headers)
      .use(unauthorizedRedirect)
      .withCredentials()
      .then(responseText),
  put: (url, body) => superagent.put(`${API_ROOT}${url}`, body).use(corsPlugin).withCredentials().then(responseBody),
  post: (url, body, headers = {}) =>
    superagent.post(`${API_ROOT}${url}`, body).use(corsPlugin).set(headers).withCredentials().then(responseBody),
};

const Widgets = {
  builderPreview: (widget: IWidget, hostName: string) =>
    requests.post(`/cms/widget/builder/preview/${widget._id}`, widget, { "preview-host-name": hostName }),
  editorPreview: (widgetId: string, hostName: string) =>
    requests.get(`/cms/widget/preview/${widgetId}`, { "preview-host-name": hostName }),
  templatePreview: (template: IWidget, hostName: string) =>
    requests.post(`/template/preview`, template, { "preview-host-name": hostName }),
};

export default {
  Widgets,
};
