import React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import {
  IWidgetFieldProperties,
  IWidgetInlineLinkFieldProps,
} from "../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "./components/GenericValueItemProperty";
import SwitchProperty from "./itemProperties/components/SwitchProperty";

type PropTypes = {
  fieldType: string;
  properties: IWidgetFieldProperties;
  onChange: (properties: IWidgetInlineLinkFieldProps) => void;
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  icon: {
    cursor: "pointer",
  },
}));

const InlineLinkWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);

  const { onChange } = props;
  const properties = props.properties as IWidgetInlineLinkFieldProps;
  const { text = "", url = "", openInNewTab, includeQueryParams } = properties;

  return (
    <div className={classes.container}>
      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, text: value })}
        value={text}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.text`)}
      />
      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, url: value })}
        value={url}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.link`)}
      />
      <SwitchProperty
        checked={openInNewTab}
        onChange={(checked) => onChange({ ...properties, openInNewTab: checked })}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.open_in_new_tab`)}
      />
      <SwitchProperty
        checked={includeQueryParams}
        onChange={(checked) => onChange({ ...properties, includeQueryParams: checked })}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.include_query_params`)}
      />
    </div>
  );
};

export default InlineLinkWidgetItem;
