import React, { useMemo } from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { replace } from "connected-react-router";
import ClassNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import MSelect from "../../../components/MSelect";
import OrganicTrafficReport from "./components/OrganicTrafficReport";
import IndexedQueriesReport from "./components/IndexedQueriesReport";
import WordsCountReport from "./components/WordsCountReport";
import CTAClicksReport from "./components/CTAClicksReport";
import { IReportsSettings, IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import IndexedQueriesEntailReport from "./components/IndexedQueriesEntailReport";
import OrganicEntailTrafficReport from "./components/OrganicEntailTrafficReports";
import CustomRedashReport from "./components/CustomRedashReport";
import CTAClicksEntailReport from "./components/CTAClicksEntailReport";
import { web } from "../../../helpers/urlHelper";
import ArticleCountReport from "./components/ArticleCountReport";
import ImpressionsReport from "./components/ImpressionsReport";
import ImpressionsEntailReport from "./components/ImpressionsEntailReport";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";
import TopQueryPageTopicReport from "./components/TopQueryPageTopicReport";
import TopQueryPageTopicEntailReport from "./components/TopQueryPageTopicEntailReport";
import CTACombinedReport from "./components/CTACombinedReport";
import CTACombinedEntailReport from "./components/CTACombinedEntailReport";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  dashboard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    rowGap: "30px",
  },
  dashboardHeader: {
    backgroundColor: theme.palette.common.white,
    alignItems: "center",
    padding: "0px 30px 0px 25px",
    zIndex: 2,
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    height: 50,
    top: 62,
    borderBottom: ` 1px solid ${theme.palette.divider}`,
  },
  reportsDashboardWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0 20px 20px",
    gap: 20,
  },
  firstRow: {
    display: "flex",
    columnGap: "10px",
    padding: "20px 0",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  select: {
    fontWeight: theme.typography.fontWeightLight as any,
  },
  chartsGrid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    columnGap: "10px",
    rowGap: "50px",
  },
  chartRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    rowGap: 12,
    width: "100%",
  },
  chartsRowGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "10px",
    width: "100%",
  },
  reportTitleWrapper: {
    display: "flex",
    columnGap: 3,
  },
  hideTitle: {
    visibility: "hidden",
  },
  moreInfo: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: 10,
    boxShadow: "-2px 0px 5px 0px #0000001A",
    borderRadius: 3,
  },
  reportTitle: {
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: 16,
    color: theme.palette.text.primary,
  },

  paper: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    gap: 30,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    border: "none",
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  infoWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  bottom: {
    display: "flex",
    height: "100%",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  text: {
    fontSize: 14,
  },
  dashboardLinks: {
    display: "flex",
    columnGap: "30px",
  },
  dashboardLink: {
    fontSize: 14,
    cursor: "pointer",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.primary,
    transition: "color 0.3s ease-in-out",
    lineHeight: "16px",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  selectedDashboardLink: {
    color: theme.palette.primary.main,
  },
}));

const options = [
  { value: 1, label: "Last month" },
  { value: 3, label: "Last 3 months" },
  { value: 6, label: "Last 6 months" },
  { value: 12, label: "Last 12 months" },
  { value: 24, label: "Last 2 years" },
];

type PropTypes = {
  selectedWebsite: IWebsite;
  goToReport: (report: string) => void;
};

const mapDispatchToProps = (dispatch) => ({
  goToReport: (report, currentUrl) => {
    dispatch(replace(web.croDashboard(report)));
  },
});

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const Dashboard = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const [selectedTimePeriod, setSelectedTimePeriod] = React.useState(options[2]);
  const [selectedSubTab, setSelectedSubTab] = React.useState("organic_traffic");
  const [reportsSettings, setReportsSettings] = React.useState<IReportsSettings>(null);
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    agent.ReportsSettings.get(selectedWebsite)
      .then((res) => {
        setReportsSettings(res.reportsSettings);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.create_error", { msg: e.message }));
      });

    // eslint-disable-next-line consistent-return
    return () => {
      setReportsSettings(null);
      setSelectedTimePeriod(options[2]);
    };
  }, [enqueueSnackbar, selectedWebsite]);

  const startDate = React.useMemo(() => {
    return moment().subtract(selectedTimePeriod.value, "month").toDate();
  }, [selectedTimePeriod]);

  const dashboardLinks = useMemo(() => {
    const links = [];

    if (!reportsSettings) return links;

    if (reportsSettings.reportsView.searchConsole || reportsSettings.reportsView.searchConsoleEntail) {
      links.push({
        label: I18n.t("reports.dashboard.dashboard_header.organic_traffic"),
        link: "organic_traffic",
      });
    }

    if (reportsSettings.reportsView.impressions || reportsSettings.reportsView.impressionsEntail) {
      links.push({
        label: I18n.t("reports.dashboard.dashboard_header.impressions"),
        link: "impressions",
      });
    }

    if (reportsSettings.reportsView.wordsCount || reportsSettings.reportsView.articleCount) {
      links.push({
        label: I18n.t("reports.dashboard.dashboard_header.delivery"),
        link: "delivery",
      });
    }

    if (reportsSettings.reportsView.indexedQueries || reportsSettings.reportsView.indexedQueriesEntail) {
      links.push({
        label: I18n.t("reports.dashboard.dashboard_header.indexed_queries"),
        link: "indexed_queries",
      });
    }

    if (reportsSettings.reportsView.ctaClicks || reportsSettings.reportsView.ctaClicksEntail) {
      links.push({
        label: I18n.t("reports.dashboard.dashboard_header.actions"),
        link: "cta_clicks",
      });
    }

    if (reportsSettings.reportsView.ctaByLandingPage || reportsSettings.reportsView.ctaByLandingPageEntail) {
      links.push({
        label: I18n.t("reports.dashboard.dashboard_header.actions_table"),
        link: "cta_landing_page",
      });
    }

    if (reportsSettings.reportsView.topQueries || reportsSettings.reportsView.topQueriesEntail) {
      links.push({
        label: I18n.t("reports.dashboard.dashboard_header.queries_pages_topics"),
        link: "queries",
      });
    }

    return links;
  }, [reportsSettings]);

  const scrollIntoView = (link: { link: string; label: any }) => {
    const node = document.getElementById(link.link);
    if (node) {
      const coordinates = node.getBoundingClientRect();

      const OFFSET = 160;
      window.scroll({
        top: window.scrollY + coordinates.top - OFFSET,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  React.useEffect(() => {
    const changeTabOnScroll = () => {
      dashboardLinks.forEach((link) => {
        const scrollPosition = window.scrollY + window.innerHeight;
        if (scrollPosition <= 20) {
          setSelectedSubTab(dashboardLinks[0].link);
          return;
        }
        const threshold = document.documentElement.scrollHeight - 50;
        if (scrollPosition >= threshold) {
          setSelectedSubTab(dashboardLinks[dashboardLinks.length - 1].link);
          return;
        }
        const section = document.getElementById(link.link);
        if (section) {
          const rect = section.getBoundingClientRect();
          const OFFSET = 200; // Adjust based on your header or any offset you might have

          // Check if section's top is within the viewport, considering any offset
          if (rect.top <= OFFSET && rect.bottom >= OFFSET) {
            setSelectedSubTab(link.link);
          }
        }
      });
    };
    window.addEventListener("scroll", changeTabOnScroll);
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("scroll", changeTabOnScroll);
  }, [dashboardLinks, selectedSubTab]);

  return (
    reportsSettings && (
      <div className={classes.dashboard}>
        <MHeaderMetaTags title={I18n.t(`meta_tags.cro.dashboard.title`)} />
        <div className={classes.dashboardHeader}>
          <div className={classes.dashboardLinks}>
            {dashboardLinks.map((link, index) => (
              <Typography
                className={ClassNames(
                  classes.dashboardLink,
                  selectedSubTab === link.link && classes.selectedDashboardLink
                )}
                key={index}
                onClick={() => {
                  scrollIntoView(link);
                }}
              >
                {link.label}
              </Typography>
            ))}
          </div>

          <MSelect
            options={options}
            value={selectedTimePeriod}
            customClass={classes.select}
            valuePadding={"2px 0px"}
            optionValue={"value"}
            optionLabel={"label"}
            borderColor={"#C4C4C4"}
            borderWidth={"0px"}
            borderRadius={0}
            height={30}
            isBold={false}
            fontWeight={theme.typography.fontWeightLight as any}
            placeholder={I18n.t("reports.dashboard.select_ph")}
            handleChange={setSelectedTimePeriod}
            textColor={"#333333"}
            selectedTextColor={"#333333"}
            textColorHover={"#333333"}
          />
        </div>
        <div className={classes.reportsDashboardWrapper}>
          <div className={classes.chartsGrid}>
            {(reportsSettings.reportsView.searchConsole || reportsSettings.reportsView.searchConsoleEntail) && (
              <div className={classes.chartRow} id={"organic_traffic"}>
                <div className={classes.reportTitleWrapper}>
                  <Typography className={classes.reportTitle}>
                    {I18n.t("reports.dashboard.organic_traffic_report")}
                  </Typography>
                  <Tooltip
                    title={I18n.t("reports.dashboard.tooltips.organic_traffic")}
                    placement={"right-end"}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Typography className={classes.moreInfo}>{"?"}</Typography>
                  </Tooltip>
                </div>
                <div className={classes.chartsRowGrid}>
                  {reportsSettings.reportsView.searchConsole && (
                    <OrganicTrafficReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                  {reportsSettings.reportsView.searchConsoleEntail && (
                    <OrganicEntailTrafficReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                </div>
              </div>
            )}
            {(reportsSettings.reportsView.impressions || reportsSettings.reportsView.impressionsEntail) && (
              <div className={classes.chartRow} id={"impressions"}>
                <div className={classes.reportTitleWrapper}>
                  <Typography className={classes.reportTitle}>
                    {I18n.t("reports.dashboard.impressions_report")}
                  </Typography>
                  <Tooltip
                    title={I18n.t("reports.dashboard.tooltips.impressions")}
                    placement={"right-end"}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Typography className={classes.moreInfo}>{"?"}</Typography>
                  </Tooltip>
                </div>
                <div className={classes.chartsRowGrid}>
                  {reportsSettings.reportsView.impressions && (
                    <ImpressionsReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                  {reportsSettings.reportsView.impressionsEntail && (
                    <ImpressionsEntailReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                </div>
              </div>
            )}
            {(reportsSettings.reportsView.wordsCount || reportsSettings.reportsView.articleCount) && (
              <div className={classes.chartRow} id={"delivery"}>
                <div className={classes.reportTitleWrapper}>
                  <Typography className={classes.reportTitle}>{I18n.t("reports.dashboard.delivrables")}</Typography>
                  <Tooltip
                    title={I18n.t("reports.dashboard.tooltips.deliverables")}
                    placement={"right-end"}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Typography className={classes.moreInfo}>{"?"}</Typography>
                  </Tooltip>
                </div>
                <div className={classes.chartsRowGrid}>
                  {reportsSettings.reportsView.wordsCount && (
                    <WordsCountReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                  {reportsSettings.reportsView.articleCount && (
                    <ArticleCountReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                </div>
              </div>
            )}
            {(reportsSettings.reportsView.indexedQueries || reportsSettings.reportsView.indexedQueriesEntail) && (
              <div className={classes.chartRow} id={"indexed_queries"}>
                <div className={classes.reportTitleWrapper}>
                  <Typography className={classes.reportTitle}>{I18n.t("reports.dashboard.indexed_queries")}</Typography>
                  <Tooltip
                    title={I18n.t("reports.dashboard.tooltips.indexed_queries")}
                    placement={"right-end"}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Typography className={classes.moreInfo}>{"?"}</Typography>
                  </Tooltip>
                </div>
                <div className={classes.chartsRowGrid}>
                  {reportsSettings.reportsView.indexedQueries && (
                    <IndexedQueriesReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                  {reportsSettings.reportsView.indexedQueriesEntail && (
                    <IndexedQueriesEntailReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                </div>
              </div>
            )}

            {(reportsSettings.reportsView.ctaClicks || reportsSettings.reportsView.ctaClicksEntail) && (
              <div className={classes.chartRow} id={"cta_clicks"}>
                <div className={classes.reportTitleWrapper}>
                  <Typography className={classes.reportTitle}>{I18n.t("reports.dashboard.actions")}</Typography>
                  <Tooltip
                    title={I18n.t("reports.dashboard.tooltips.actions")}
                    placement={"right-end"}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Typography className={classes.moreInfo}>{"?"}</Typography>
                  </Tooltip>
                </div>

                <div className={classes.chartsRowGrid}>
                  {reportsSettings.reportsView.ctaClicks && (
                    <CTAClicksReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                  {reportsSettings.reportsView.ctaClicksEntail && (
                    <CTAClicksEntailReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                </div>
              </div>
            )}

            {(reportsSettings.reportsView.ctaByLandingPage || reportsSettings.reportsView.ctaByLandingPageEntail) && (
              <div className={classes.chartRow} id={"cta_landing_page"}>
                <div className={classes.reportTitleWrapper}>
                  <Typography className={classes.reportTitle}>{I18n.t("reports.dashboard.actions_table")}</Typography>
                  <Tooltip
                    title={I18n.t("reports.dashboard.tooltips.actions")}
                    placement={"right-end"}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Typography className={classes.moreInfo}>{"?"}</Typography>
                  </Tooltip>
                </div>

                <div className={classes.chartsRowGrid}>
                  {reportsSettings.reportsView.ctaByLandingPage && (
                    <CTACombinedReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                  {reportsSettings.reportsView.ctaByLandingPageEntail && (
                    <CTACombinedEntailReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                </div>
              </div>
            )}

            {(reportsSettings.reportsView.topQueries || reportsSettings.reportsView.topQueriesEntail) && (
              <div className={classes.chartRow} id={"queries"}>
                <div className={classes.reportTitleWrapper}>
                  <Typography className={classes.reportTitle}>
                    {I18n.t("reports.dashboard.top_queries_topics_pages")}
                  </Typography>
                  <Tooltip
                    title={I18n.t("reports.dashboard.tooltips.top_queries_topics_pages")}
                    placement={"right-end"}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Typography className={classes.moreInfo}>{"?"}</Typography>
                  </Tooltip>
                </div>
                <div className={classes.chartsRowGrid}>
                  {reportsSettings.reportsView.topQueries && (
                    <TopQueryPageTopicReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                  {reportsSettings.reportsView.topQueriesEntail && (
                    <TopQueryPageTopicEntailReport selectedWebsite={selectedWebsite} startDate={startDate} />
                  )}
                </div>
              </div>
            )}

            {reportsSettings.customReports.map((report, index) => (
              <div className={classes.chartRow}>
                <div className={classes.chartsRowGrid}>
                  <CustomRedashReport
                    customReport={report}
                    selectedWebsite={selectedWebsite}
                    startDate={startDate}
                    key={index}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
