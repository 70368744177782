import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import MActionDialog from "../../../components/MActionDialog";
import {
  IEditorialPolicy,
  IWebsite,
  IWebsiteConfigurationsProps,
  IWebsiteDynamicSectionProps,
} from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import { EDITORIAL_POLICY, HOME, WEBSITE_PAGES } from "../../../reducers/constants/actionTypes";
import _, { find } from "lodash";

type PropTypes = {
  selectedWebsite: IWebsite;
  changesLostWarning: boolean;
  policyChanged: boolean;
  updatedSections: IWebsiteConfigurationsProps;
  dynamicSection: IWebsiteDynamicSectionProps;
  onWebsiteUpdated: (website) => void;
  name: string;
  url: string;
  host: string;
  policyType: "EDITORIAL_PROCESS" | "COMMUNITY_GUIDELINES" | "EDITORIAL_TEAM" | "CONTENT_PRINCIPLES" | "FACT_CHECKED";
  policy: IEditorialPolicy;
  onPolicyChanged: (policy) => void;
  onPoliciesLoaded: (policy) => void;
  onPolicyLoaded: (policy, type) => void;
};
const mapStateToProps = (state) => ({
  changesLostWarning: state.websitePages.changesLostWarning,
  policyType: state.editorial.policyType,
  policy: state.editorial.policy,
  policyChanged: state.editorial.policyChanged,
  updatedSections: state.websitePages.updatedSections,
  dynamicSection: state.websitePages.dynamicSection,
  selectedWebsite: state.home.selectedWebsite,
  name: state.websitePages.name,
  url: state.websitePages.url,
  host: state.websitePages.host,
});
const mapDispatchToProps = (dispatch) => ({
  onPolicyLoaded: (policy, type) =>
    dispatch({
      type: EDITORIAL_POLICY.ON_POLICY_LOADED,
      policy,
      policyType: type,
    }),
  onWebsiteUpdated: (website) => {
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_CONFIGURATIONS_LOADED, website });
    dispatch({ type: HOME.ON_WEBSITE_UPDATED, website });
  },
  onPoliciesLoaded: (policies) =>
    dispatch({
      type: EDITORIAL_POLICY.ON_POLICIES_LOADED,
      policies,
    }),
  onPolicyChanged: (policy) =>
    dispatch({
      type: EDITORIAL_POLICY.ON_POLICY_CHANGED,
      policy,
    }),
});
const MRouterPrompt = (props: PropTypes) => {
  const {
    changesLostWarning,
    policyChanged,
    selectedWebsite,
    updatedSections,
    dynamicSection,
    onWebsiteUpdated,
    policy,
    policyType,
    onPoliciesLoaded,
    onPolicyChanged,
    onPolicyLoaded,
    url,
    name,
    host,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const unblockRef = useRef();

  function handleShowModal() {
    setShowPrompt(true);
  }

  function onCancel() {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    if (policyChanged) {
      getPolicies();
    }
    history.push(currentPath);
  }

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (changesLostWarning || policyChanged) {
        setCurrentPath(location.pathname);
        handleShowModal();
        return false;
      }
      return true;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [changesLostWarning, policyChanged]);
  const getPolicies = () => {
    if (!selectedWebsite) {
      return;
    }
    agent.EditorialPolicy.get(selectedWebsite._id).then((res) => {
      onPoliciesLoaded(res.policies);
      const selectedPolicy = find(res.policies, { type: policyType });
      if (selectedPolicy) {
        onPolicyLoaded(selectedPolicy, policyType);
      } else {
        onPolicyLoaded(null, policyType);
      }
    });
  };

  function handleConfirm() {
    if (changesLostWarning) {
      const newUpdatedSections = { ...updatedSections };
      const dynamicSections = [...selectedWebsite.configurations.dynamicSections];
      if (dynamicSection.name) {
        const currentDynamicSection = dynamicSections.find((section) => section._id === dynamicSection._id);
        if (currentDynamicSection) {
          dynamicSections[dynamicSections.findIndex((section) => section._id === dynamicSection._id)] = {
            ...dynamicSection,
          };
        } else {
          dynamicSections.push({ ...dynamicSection, type: _.camelCase(dynamicSection.name) });
        }
        delete newUpdatedSections["dynamicSection"];
      }
      const configurations = {
        ...newUpdatedSections,
        dynamicSections,
      };
      agent.Websites.updateConfigurations({
        _id: selectedWebsite._id,
        url,
        host,
        name,
        configurations,
      })
        .then((res) => {
          onWebsiteUpdated(res.website);
          enqueueSnackbar(I18n.t("snackbar.update_success"));
        })
        .catch((e) => {
          enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
        });
    }
    if (policyChanged) {
      agent.EditorialPolicy.update(selectedWebsite._id, policyType, policy)
        .then((res) => {
          onPolicyChanged(res.policy);
          getPolicies();
          enqueueSnackbar(I18n.t("snackbar.general_update_success", { name: policyType.toLowerCase() }));
        })
        .catch((e) => {
          enqueueSnackbar(
            I18n.t("snackbar.general_update_error", {
              name: policyType.toLowerCase(),
              msg: e.message,
            })
          );
        });
    }
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  }

  return (
    <>
      {showPrompt && (
        <MActionDialog
          title={I18n.t("website.warning_title")}
          buttonDecline={"No"}
          buttonApprove={"Yes"}
          handleDecline={onCancel}
          text={I18n.t("website.warning_text")}
          handleClose={onCancel}
          handleApprove={handleConfirm}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MRouterPrompt);
