import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 12,
    height: 13,
  },
}));

type PropTypes = {
  className?: string;
};

const AccordionArrowIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 12 13"}>
      <path
        d="M1 13.5C0.733333 13.5 0.5 13.4025 0.3 13.2075C0.1 13.0125 0 12.785 0 12.525V2.45C0 2.19 0.1 1.9625 0.3 1.7675C0.5 1.5725 0.733333 1.475 1 1.475H2.08333V1.00375C2.08333 0.862917 2.13333 0.74375 2.23333 0.64625C2.33333 0.54875 2.46111 0.5 2.61667 0.5C2.77222 0.5 2.90278 0.54875 3.00833 0.64625C3.11389 0.74375 3.16667 0.868333 3.16667 1.02V1.475H8.83333V1.00375C8.83333 0.862917 8.88333 0.74375 8.98333 0.64625C9.08333 0.54875 9.21111 0.5 9.36667 0.5C9.52222 0.5 9.65278 0.54875 9.75833 0.64625C9.86389 0.74375 9.91667 0.868333 9.91667 1.02V1.475H11C11.2667 1.475 11.5 1.5725 11.7 1.7675C11.9 1.9625 12 2.19 12 2.45V12.525C12 12.785 11.9 13.0125 11.7 13.2075C11.5 13.4025 11.2667 13.5 11 13.5H1ZM1 12.525H11V5.5375H1V12.525ZM1 4.5625H11V2.45H1V4.5625ZM1 4.5625V2.45V4.5625Z"
        fill="#333333"
      />
    </SvgIcon>
  );
};

export default AccordionArrowIcon;
