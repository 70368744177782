import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: 50,
    display: "flex",
    flexDirection: "row",
  },
  paper: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
    padding: 30,
  },
  adImage: {
    backgroundSize: "contain",
  },
}));

type PropTypes = {
  html: string;
};

const AdEmbeddedHtmlPreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { html } = props;

  return (
    <div className={classes.wrapper}>
      <Paper elevation={0} className={classes.paper}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Paper>
    </div>
  );
};

export default AdEmbeddedHtmlPreview;
