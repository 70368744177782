import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 28,
    width: 140,
  },
}));

type PropTypes = {
  className?: string;
};

const EntailNavbarLogo = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 107 20"}>
      <path d="M0 20V0H13.0571V2.94286H3.65714V8.42857H12.2V11.2857H3.65714V17.0571H17.7V20H0Z" fill="black" />
      <path d="M16.5179 20V0H20.175L29.575 14.0857V0H33.2321V20H29.575L20.175 5.94286V20H16.5179Z" fill="black" />
      <path d="M41.7665 20V2.94286H32.7237V0H51.2808V2.94286H45.4237V20H41.7665Z" fill="black" />
      <path
        d="M50.5837 20L57.898 0H62.0123L69.3266 20H65.4408L63.8408 15.3714H56.0408L54.4123 20H50.5837ZM57.0408 12.5143H62.8408L59.9266 4.2L57.0408 12.5143Z"
        fill="black"
      />
      <path d="M71.9029 20V0H75.56V20H71.9029Z" fill="black" />
      <path d="M79.4364 20V0H83.0935V17.1429H106.236V20H79.4364Z" fill="black" />
      <path
        d="M102.337 11.0611V11.518H99.5427L98.8522 9.91379H95.7394L95.0489 11.518H92.2549V11.0611H93.4766L96.4937 4.09204H98.0979L101.104 11.0611H102.337ZM97.3011 6.30176L96.3662 8.45836H98.2254L97.3011 6.30176Z"
        fill="#2D6FF6"
      />
      <path d="M104.739 11.0611V11.518H101.254V11.0611H102.168V4.7841L103.825 5.6097V11.0611H104.739Z" fill="#2D6FF6" />
      <path d="M102.143 2.41089L103.813 3.17003V4.84014L102.143 4.081V2.41089Z" fill="#FFC700" />
    </SvgIcon>
  );
};

export default EntailNavbarLogo;
