import { useState } from "react";

type PropTypes = {
  initialSortFields: { [key: string]: "asc" | "desc" };
};
const useSort = (props?: PropTypes) => {
  const [sortFields, setSortFields] = useState<{ [key: string]: "asc" | "desc" }>(props?.initialSortFields || {});

  const toggleSort = (field: string) => {
    if (!sortFields[field]) {
      setSortFields({ ...sortFields, [field]: "desc" });
    } else if (sortFields[field] === "desc") {
      setSortFields({ ...sortFields, [field]: "asc" });
    } else if (sortFields[field] === "asc") {
      const { [field]: _, ...rest } = sortFields;
      setSortFields(rest);
    }
  };

  return { sortFields, toggleSort };
};

export default useSort;
