import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Theme, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { I18n } from "react-redux-i18n";
import { Editor, Transforms } from "slate";

import LiteRichTextEditor from "../../liteEditor/LiteRichTextEditor";
import { FaqElement } from "../../../types/editor.Types";
import TextFieldProperty from "../../../../containers/CRO/pages/WidgetsBuilder/FormWidgetEditor/build/items/itemProperties/components/TextFieldProperty";
import useFAQWidgetStyle from "./useFAQWidgetStyle";
import SwitchBaseComponent from "../../../elementsStyles/BaseComponents/SwitchBaseComponent";
import ArrowLeftIcon from "../../../../icons/ArrowLeftIcon";
import theme from "../../../../themes/theme";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    padding: 20,
  },
  elementNameRow: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    paddingBottom: 10,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  arrow: {
    cursor: "pointer",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  container: {
    display: "grid",
    gap: 30,
  },
  toolbarCustomClass: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    backgroundColor: palette.secondary.main,
  },
  editableCustomClass: {
    borderRadius: 5,
    minHeight: "162px !important",
    backgroundColor: palette.secondary.main,
  },
  textFieldWrapper: {
    height: 38,
    padding: 10,
    backgroundColor: palette.secondary.main,
  },
  inputLabel: {
    fontWeight: typography.fontWeightBold,
    fontSize: 14,
  },
  divider: {
    border: "0.5px solid #606163",
    marginInline: 10,
  },
  icon: {
    height: "22px",
    cursor: "pointer",
  },
  addButton: {
    justifySelf: "right",
    marginTop: -10,
    textTransform: "none",
  },
  deleteButton: {
    fontWeight: typography.fontWeightRegular,
    fontSize: 14,
    color: palette.error.main,
    textDecoration: "none",
    cursor: "pointer",
  },
  startIcon: {
    marginTop: -2,
    marginRight: 2,
  },
}));

type PropTypes = {
  editor: Editor;
  element: FaqElement;
};

const FAQWidgetStyle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor } = props;

  const { element, addNewItem, deleteItem, handleElementChange, handleItemChange } = useFAQWidgetStyle({
    editor,
    element: props.element,
  });

  if (!element.data.formatted) return null;

  const list = [...element.data.list, ...(element.data.list.length === 0 ? [{ question: "", answer: "" }] : [])];

  const close = () => {
    Transforms.deselect(editor);
  };

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.faq.name")} editor={editor}>
      <div className={classes.container}>
        <div>
          <div className={classes.row}>
            <Typography className={classes.inputLabel} variant={"subtitle2"}>
              {I18n.t("rich_text_editor.faq.faq_title")}
            </Typography>
          </div>
          <TextFieldProperty
            placeholder={I18n.t("rich_text_editor.faq.faq_title_ph")}
            value={element.data.title}
            onChange={(newValue) => handleElementChange({ ...element, data: { ...element.data, title: newValue } })}
            margin={"0 0 7px 0"}
            borderRadius={5}
            classes={{
              textFieldWrapper: classes.textFieldWrapper,
            }}
          />
        </div>
        {list.map((item, index) => (
          <div key={index}>
            <div className={classes.row}>
              <Typography className={classes.inputLabel} variant={"subtitle2"}>
                {I18n.t("rich_text_editor.faq.question", { number: index + 1 })}
              </Typography>
              {list.length > 1 && (
                <a className={classes.deleteButton} onClick={() => deleteItem(index)}>
                  {I18n.t("rich_text_editor.faq.delete")}
                </a>
              )}
            </div>

            <TextFieldProperty
              onChange={(newValue: string) => handleItemChange({ ...item, question: newValue }, index)}
              value={item.question}
              placeholder={I18n.t("rich_text_editor.faq.question_ph")}
              margin={"0 0 10px 0"}
              labelOutsideInput
              borderRadius={5}
              classes={{
                textFieldWrapper: classes.textFieldWrapper,
              }}
            />
            <LiteRichTextEditor
              editorToolbarPostion="none"
              onValueChanged={(answer) => handleItemChange({ ...item, answer }, index)}
              initialValue={item.answer}
              toolbarCustomClass={classes.toolbarCustomClass}
              editableCustomClass={classes.editableCustomClass}
              editorPlaceHolder={I18n.t("rich_text_editor.faq.answer_ph")}
              fontSize={14}
              padding={"0 10px 10px"}
            />
          </div>
        ))}

        <Button
          className={classes.addButton}
          classes={{ startIcon: classes.startIcon }}
          disableElevation
          startIcon={<AddIcon className={classes.icon} />}
          onClick={addNewItem}
        >
          {I18n.t("widgets_builder.widget_editor.fields.add")}
        </Button>
        <SwitchBaseComponent
          defaultValue={false}
          value={element.data?.googleSchemaMarkup}
          title={I18n.t("rich_text_editor.faq.google_schema_markup")}
          handleChange={(value) => {
            handleElementChange({ ...element, data: { ...element.data, googleSchemaMarkup: value } });
          }}
        />
      </div>
    </StylePanelSection>
  );
};

export default FAQWidgetStyle;
