import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { web } from "../../../helpers/urlHelper";
import LoginPopup from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/commonComponents/LoginPopup";
import EmailSubscription from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/commonComponents/EmailSubscription";
import PoweredBy from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/commonComponents/PoweredBy";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";
import TableOfContentsSettings from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/commonComponents/TableOfContentsSettings";

const Components = () => (
  <div>
    <MHeaderMetaTags title={I18n.t(`meta_tags.page_builder.components.title`)} />
    <Switch>
      <Route exact={false} path={web.components("login-popup")} component={LoginPopup} />
      <Route exact={false} path={web.components("email-subscription")} component={EmailSubscription} />
      <Route exact={false} path={web.components("powered-by")} component={PoweredBy} />

      <Route exact={false} path={web.components("table-of-contents")} component={TableOfContentsSettings} />

      <Redirect exact from={"*"} to={web.components("login-popup")} />
    </Switch>
  </div>
);

export default Components;
