import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import PageClusterItemList from "./components/PageClusterItemList";
import { ePageState, ePageType, IPageCluster, IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import { WORKFLOW_ALL_STATES } from "../../../reducers/constants/actionTypes";
import useDebounce from "../../../hooks/useDebounce";
import { api } from "../../../helpers/urlHelper";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarPostTypeFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPostTypeFilter";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import MTableToolbarPageStateFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPageStateFilter";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const useStyles = makeStyles((theme: Theme) => ({
  articles: {
    width: "100%",
    display: "flex",
  },
  articlesContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.palette.background.default,
    margin: "25px 20px 0 20px",
    padding: "20px 10px 0",
  },
}));

type PropTypes = {
  pagesLoaded: boolean;
  states: ePageState[];
  selectedWebsite: IWebsite;
  clustersLoaded: boolean;
  clusters: IPageCluster[];
  refreshClusters: boolean;
  paginationPagesCount: number;

  handleClustersLoaded: Function;

  postType: { _id: ePageType; name: string }[];
  postStatus: { _id: number; name: ePageState }[];
  searchText: string;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  onFilterChanged: (payload) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  clustersLoaded: state.workFlowAllStates.loaded,
  clusters: state.workFlowAllStates.items,
  refreshClusters: state.workFlowAllStates.refreshItemList,
  paginationPagesCount: state.workFlowAllStates.paginationPagesCount,
  pagesLoaded: state.workFlowAllStates.pagesLoaded,
  postType: state.workFlowAllStates.filter.postType,
  postStatus: state.workFlowAllStates.filter.postStatus,
  sortField: state.workFlowAllStates.filter.sortField,
  searchText: state.workFlowAllStates.filter.searchText,
});

const mapDispatchToProps = (dispatch) => ({
  handleClustersLoaded: (payload) => {
    dispatch({ type: WORKFLOW_ALL_STATES.LOADED, payload });
  },
  onFilterChanged: (payload) => dispatch({ type: WORKFLOW_ALL_STATES.WORKFLOW_ALL_STATES_ON_FILTER_CHANGED, payload }),
});

const PageClusterList = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    states,
    selectedWebsite,
    handleClustersLoaded,
    refreshClusters,
    clusters,
    clustersLoaded,
    paginationPagesCount,
    pagesLoaded,
    postType,
    postStatus,
    searchText,
    sortField,
    onFilterChanged,
  } = props;
  const [page, setPage] = useState<number>(1);
  const [paginationLimit, setPaginationLimit] = useState(100);
  const debouncedSearchText = useDebounce(searchText, 500);
  const cleanPostTypes = postType.map((p) => p._id);
  const cleanPostStates = postStatus.length > 0 ? postStatus.map((p) => p.name) : states;

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    handleClustersLoaded(
      agent.Pages.getPageClusters(
        selectedWebsite,
        page,
        paginationLimit,
        cleanPostStates,
        sortField.field,
        sortField.direction,
        cleanPostTypes,
        searchText
      )
    );
  }, [selectedWebsite, page, paginationLimit, postType, debouncedSearchText, postStatus, sortField]);

  React.useEffect(() => {
    if (!selectedWebsite || !refreshClusters) {
      return;
    }
    handleClustersLoaded(
      agent.Pages.getPageClusters(
        selectedWebsite,
        page,
        paginationLimit,
        cleanPostStates,
        sortField.field,
        sortField.direction,
        cleanPostTypes,
        searchText
      )
    );
  }, [selectedWebsite, handleClustersLoaded, refreshClusters]);

  const exportToCSV = () => {
    const API_ROOT = api.api();
    const endpoint = `/pages/${selectedWebsite._id}/all-states/csv?${
      cleanPostTypes ? `&postTypes=${cleanPostTypes.join(",")}` : ""
    }&searchText=${searchText}`;

    return `${API_ROOT}${endpoint}`;
  };
  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.article_cms.all_posts.title`)} />
      <MTableToolbar
        csvURL={exportToCSV()}
        cmsCreateButton
        searchText={searchText}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
      >
        <MTableToolbarPostTypeFilter
          postTypes={postType}
          selectedWebsite={selectedWebsite}
          handlePostTypesSelected={(value) => onFilterChanged({ postType: value })}
        />
        <MTableToolbarPageStateFilter
          pageState={postStatus}
          handlePageStateSelected={(value) => onFilterChanged({ postStatus: value })}
        />
      </MTableToolbar>
      <PageClusterItemList
        sortField={sortField}
        setSortField={(value) => onFilterChanged({ sortField: value })}
        clusters={clusters}
        clustersLoaded={clustersLoaded}
      />
      {pagesLoaded && clusters.length > 0 && (
        <MTablePagination
          paginationLimit={paginationLimit}
          page={page}
          paginationPagesCount={paginationPagesCount}
          setPaginationLimit={setPaginationLimit}
          setPage={setPage}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageClusterList);
