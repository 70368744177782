import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import CfButton from "./CfButton";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderWidth: 0,
  },
  titleWrapper: {
    height: 85,
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontSize: theme.typography.pxToRem(24),
    color: "white",
    paddingLeft: 40,
  },
  title: {
    margin: 0,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    minHeight: 150,
    padding: 20,
  },
  dialogActions: {
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 25,
    paddingBottom: 25,
    borderTop: `${theme.palette.divider} solid 1px`,
  },
  closeButton: {
    position: "absolute",
    right: 20,
    // top: theme.spacing(1),
    color: "white",
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(36),
  },
  searchTextField: {
    marginTop: 20,
  },
  searchTextFieldInput: {
    height: 34,
  },
}));

type PropTypes = {
  title: string;
  text?: string;
  buttonApprove?: string;
  buttonDecline?: string;

  handleClose: Function;
  handleApprove: Function;
  handleDecline?: Function;
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const MActionDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { title, text = null, buttonApprove = null, buttonDecline = null } = props;
  const { handleApprove, handleDecline = null, handleClose } = props;

  const close = () => {
    handleClose();
  };

  return (
    <Dialog
      open
      classes={{
        paper: classes.dialog,
      }}
      onClose={(event) => close()}
      fullWidth
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.titleWrapper}>
        <div className={classes.dialogTitle}>
          <Typography color={"inherit"} className={classes.title} variant={"h3"}>
            {title}
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {text && <Typography variant={"body1"}>{text}</Typography>}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {handleDecline && <CfButton height={40} text={buttonDecline || "Cancel"} onClick={() => handleDecline()} />}
        <CfButton height={40} text={buttonApprove || "OK"} onClick={() => handleApprove()} />
      </DialogActions>
    </Dialog>
  );
};

export default MActionDialog;
