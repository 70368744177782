import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { I18n } from "react-redux-i18n";
import { Widget } from "../widgets/WithWidgets";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
};

function TableElement(props: PropTypes) {
  const { attributes, children, element } = props;
  const editor = useSlateStatic();
  const classes = useStyles(props);
  return (
    <Widget
      title={I18n.t("rich_text_editor.tooltips.table")}
      element={element}
      contentEditable
      editable
      handleDeleteClick={() => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        Transforms.removeNodes(editor, { at: path });
      }}
    >
      <table className={classes.table} {...attributes}>
        {children}
      </table>
    </Widget>
  );
}

export default TableElement;
