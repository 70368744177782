import React from "react";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import { makeStyles, Theme } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import theme from "../../../../../themes/theme";
import green_frame from "../../../../../img/green_frame.svg";
import PageSection from "./PageSection";
import MTextField from "../../../../../components/MTextField";

const useStyles = makeStyles((theme: Theme) => ({
  headersRow: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    gap: 15,
  },
  annualDiscountRow: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    marginBottom: 10,
  },
  row: {
    display: "flex",
    alignItems: "center",
    padding: 12,
    gap: 15,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  firstColumn: {
    width: "73%",
    display: "flex",
    alignItems: "center",
    gap: 15,
  },
  annualDiscountField: {
    width: 100,
    margin: 0,
  },
  nameField: {
    margin: 0,
  },
  field: {
    margin: 0,
    width: "12%",
  },
  inputName: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.pxToRem(14),
  },
  firstHeader: {
    width: "73%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  header: {
    width: "12%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
  },
}));

type PropTypes = {
  proposal: any;
  handleChange: (value) => void;
};

const Plans = (props: PropTypes) => {
  const classes = useStyles(props);
  const { proposal, handleChange } = props;

  return (
    <PageSection title={I18n.t("account.setup.plans.title")} subtitle={I18n.t("account.setup.plans.subtitle")}>
      <div className={classes.annualDiscountRow}>
        <Typography variant={"subtitle2"} className={classes.text}>
          {I18n.t("account.setup.plans.annual_discount")}
        </Typography>
        <MTextField
          borderRadius={3}
          borderColor={theme.palette.divider}
          height={27}
          outlined
          customClass={classes.annualDiscountField}
          inputClassName={classes.input}
          value={numeral(numeral(proposal.annualDiscount).value() / 100).format("0%")}
          onChange={(value) => {
            handleChange({ ...proposal, annualDiscount: numeral(value).value() * 100 });
          }}
        />
      </div>
      <div className={classes.headersRow}>
        <Typography variant={"subtitle2"} className={classes.firstHeader}>
          {I18n.t("account.setup.plans.header1")}
        </Typography>
        <Typography variant={"subtitle2"} className={classes.header}>
          {I18n.t("account.setup.plans.header2")}
        </Typography>
        <Typography variant={"subtitle2"} className={classes.header}>
          {I18n.t("account.setup.plans.header3")}
        </Typography>
      </div>
      {proposal?.plans.map((plan, i) => (
        <div key={plan._id} className={classes.row}>
          <div className={classes.firstColumn}>
            <img src={green_frame} alt={"green-frame"} />
            <MTextField
              borderRadius={3}
              borderColor={theme.palette.divider}
              height={27}
              outlined
              customClass={classes.nameField}
              inputClassName={classes.inputName}
              fullWidth
              value={plan.name}
              onChange={(value) => {
                const newPlans = [...proposal.plans];
                newPlans[i].name = value;
                handleChange({ ...proposal, plans: newPlans });
              }}
            />
          </div>
          <MTextField
            borderRadius={3}
            borderColor={theme.palette.divider}
            height={27}
            outlined
            customClass={classes.field}
            inputClassName={classes.input}
            fullWidth
            value={numeral(plan.price).format("0,0[.]00")}
            onChange={(value) => {
              const newPlans = [...proposal.plans];
              newPlans[i].price = value;
              handleChange({ ...proposal, plans: newPlans });
            }}
            startAdornment={"$"}
          />
          <MTextField
            borderRadius={3}
            borderColor={theme.palette.divider}
            height={27}
            outlined
            customClass={classes.field}
            inputClassName={classes.input}
            fullWidth
            value={numeral(plan.discount / 100).format("0%")}
            onChange={(value) => {
              const newPlans = [...proposal.plans];
              newPlans[i].discount = numeral(value).value() * 100;
              handleChange({ ...proposal, plans: newPlans });
            }}
          />
        </div>
      ))}
    </PageSection>
  );
};

export default Plans;
