import * as React from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { IQuestion, IWebsite } from "../../../../reducers/constants/objectTypes";
import QuestionsDraftItemRow from "./QuestionsDraftItemRow";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";
import MTable from "../../../../components/Table/Table/MTable";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";

type PropTypes = {
  selectedWebsite: IWebsite;
  questionsLoaded: boolean;

  questions: IQuestion[];
  sortField: any;
  setSortField: (input: any) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  questionsLoaded: state.community.draftQuestionsLoaded,
});

const WriterCommunityItemListSubHeader = (props: {
  title: string;
  topHeader: boolean;
  sortField: any;
  setSortField: (input: any) => void;
}) => {
  const theme: any = useTheme();

  return (
    <MTableHeader>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(21)}
        sortField={props.sortField}
        field={"title"}
      >
        {props.title}
      </MTableHeaderSortLabel>
      {props.topHeader && (
        <>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"createdAt"}
          >
            {I18n.t("community.listHeader.created_at")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel>{I18n.t("community.listHeader.category")}</MTableHeaderLabel>
          <MTableHeaderLabel>{I18n.t("community.listHeader.num_answers")}</MTableHeaderLabel>
        </>
      )}
    </MTableHeader>
  );
};
const QuestionsDraftItemList = (props: PropTypes) => {
  const { questionsLoaded, questions, setSortField, sortField } = props;

  return (
    <MTable>
      {!questionsLoaded && <MTableRowProgressBar />}

      {questionsLoaded && questions.length === 0 && <MTableRowEmptyRow />}

      {questionsLoaded && questions.length > 0 && (
        <WriterCommunityItemListSubHeader
          setSortField={setSortField}
          sortField={sortField}
          title={I18n.t("community.listHeader.draft_questions")}
          topHeader
        />
      )}

      {questionsLoaded &&
        questions.length > 0 &&
        questions.map((item, index) => <QuestionsDraftItemRow key={item._id} question={item} />)}
    </MTable>
  );
};

export default connect(mapStateToProps, {})(QuestionsDraftItemList);
