import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import TextFieldElement from "../../elementsStyles/ElementComponents/TextFieldElement";
import StylePanelSection from "../../elementsStyles/components/StylePanelSection";
import NumberInputBaseComponent from "../../elementsStyles/BaseComponents/NumberInputBaseComponent";
import SwitchBaseComponent from "../../elementsStyles/BaseComponents/SwitchBaseComponent";

const useStyles = makeStyles((theme: Theme) => ({
  fieldsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  customTextClass: {
    padding: 10,
    minHeight: 38,
  },
}));

type PropTypes = {
  editor: Editor;
  websiteTheme: IWebsiteThemeProps;
  element: any;
};

const ImageStyle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, websiteTheme, element } = props;

  const handleElementDataChange = (data) => {
    console.log("PageEditorImageStyle: PageEditorComparisonTableStyle", data);
    Transforms.setNodes(
      editor,
      { data, file: { ...element.file, ...data } },
      { at: ReactEditor.findPath(editor as ReactEditor, element) }
    );
  };

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.image.name")} editor={editor}>
      <div className={classes.fieldsWrapper}>
        <TextFieldElement
          placeholder={I18n.t("rich_text_editor.image.href_ph")}
          title={I18n.t("rich_text_editor.image.href")}
          value={element.file?.href}
          customTextClass={classes.customTextClass}
          handleChange={(href) => handleElementDataChange({ ...element.file, href })}
        />
        <NumberInputBaseComponent
          rowTitle={I18n.t("rich_text_editor.image.width")}
          value={element.file?.widthPercent}
          defaultValue={100}
          postFix={"%"}
          minHeight={38}
          handleChange={(widthPercent) => handleElementDataChange({ ...element.file, widthPercent })}
        />
        {/* <HorizontalAlignmentCSS */}
        {/*  value={element.file?.alignment || "center"} */}
        {/*  handleChange={(alignment) => handleElementDataChange({ ...element.file, alignment })} */}
        {/* /> */}
        <TextFieldElement
          title={I18n.t("rich_text_editor.image.alt")}
          value={element.file?.alt}
          placeholder={I18n.t("rich_text_editor.image.alt_ph")}
          customTextClass={classes.customTextClass}
          handleChange={(alt) => handleElementDataChange({ ...element.file, alt })}
        />
        <TextFieldElement
          title={I18n.t("rich_text_editor.image.title")}
          value={element.file?.title}
          placeholder={I18n.t("rich_text_editor.image.title_ph")}
          customTextClass={classes.customTextClass}
          handleChange={(title) => handleElementDataChange({ ...element.file, title })}
        />
        <TextFieldElement
          title={I18n.t("rich_text_editor.image.caption")}
          value={element.file?.caption}
          placeholder={I18n.t("rich_text_editor.image.caption_ph")}
          customTextClass={classes.customTextClass}
          handleChange={(caption) => handleElementDataChange({ ...element.file, caption })}
        />
        <SwitchBaseComponent
          defaultValue={true}
          value={element.file?.showCaption}
          title={I18n.t("rich_text_editor.image.show_caption")}
          handleChange={(showCaption) => {
            handleElementDataChange({ ...element.file, showCaption });
          }}
        />
      </div>
    </StylePanelSection>
  );
};

export default ImageStyle;
