import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import WritersBioList from "./List/WritersBioList";
import { IAudience, IWebsite } from "../../../../reducers/constants/objectTypes";
import { AUDIENCES } from "../../../../reducers/constants/actionTypes";
import BioDetails from "./MainScreen/BioDetails";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    flexGrow: 1,
  },
  topicsWrapper: {
    overflow: "hidden",
    flexGrow: 1,
    display: "flex",
    height: "calc(100vh - 130px)",
    margin: "25px 20px 0 20px",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedAudience: IAudience;

  handleMainCategoriesLoaded: Function;
  handleCategoriesLoaded: Function;
  handleTopicsLoaded: Function;
  cleanUp: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  selectedAudience: state.audiences.selectedAudience,
});

const mapDispatchToProps = (dispatch) => ({
  handleCategoriesLoaded: (payload) => dispatch({ type: AUDIENCES.ON_CATEGORIES_LOADED, payload }),
  handleMainCategoriesLoaded: (payload) => dispatch({ type: AUDIENCES.ON_MAIN_CATEGORIES_LOADED, payload }),
  handleTopicsLoaded: (payload) => dispatch({ type: AUDIENCES.ON_TOPICS_LOADED, payload }),
  cleanUp: () => dispatch({ type: AUDIENCES.ON_AUDIENCE_CLEANUP }),
});

const WritersBio = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, handleCategoriesLoaded, handleMainCategoriesLoaded, handleTopicsLoaded, cleanUp } = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.topicsWrapper}>
        <WritersBioList />
        <BioDetails />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WritersBio);
