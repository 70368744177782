import React, { useEffect } from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { nanoid } from "nanoid";
import { round } from "lodash";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";
import classNames from "classnames";

import VideoEditorHeader from "./components/VideoEditorHeader";
import VideoEditorTabs from "./components/Tabs/VideoEditorTabs";
import { IVideoEditor, VideoEditorProvider } from "./VideoEditorContext";
import TimelineControlBar from "./components/TimelineControlBar";
import TimelinePanel from "./components/TimelinePanel";
import CfProgressBar from "../../components/CfProgressBar";
import agent from "../../agent";
import { IVideoProject, IWebsite, ITimelineRow, eVideoProjectTool } from "../../reducers/constants/objectTypes";
import { VIDEO_EDITOR } from "../../reducers/constants/actionTypes";
import VideoTools from "./components/VideoTools";
import ability from "../../casl/ability";
import VideoWithControls from "./components/VideoWithControls";
import { web } from "../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  editorWrapper: {
    backgroundColor: "#F5F6F8",
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  writeWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.palette.background.default,
    margin: "25px 20px 0 20px",
    padding: "20px 10px 0",
  },
  main: {
    minHeight: 0,
    height: "100%",
    width: "100%",
    display: "flex",
  },
  leftContainer: {
    width: 70,
    height: "100%",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
  },
  leftSideBar: {},
  mainContent: {
    flex: 1,
    backgroundColor: "#000",
  },
  rightSideBar: { width: "35%", paddingInline: 20, position: "relative" },
  top: {
    width: "100%",
    display: "flex",
    minHeight: 0,
    flex: 1,
  },
  bottom: {
    height: 200,
    flexShrink: 0,
    backgroundColor: "#20222B",
  },
  timelineWrapper: {
    height: "100%",
  },
  hideTimeline: {
    display: "none",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  categories: state.category.categories,
  videoProject: state.videoEditor.videoProject,
  selectedTool: state.videoEditor.selectedTool,
});

const mapDispatchToProps = (dispatch) => ({
  loadVideoProject: (payload: { videoProject: IVideoProject }) =>
    dispatch({ type: VIDEO_EDITOR.PROJECT_LOADED, payload }),
  editTimelineRows: (timelineRows: ITimelineRow[]) => dispatch({ type: VIDEO_EDITOR.ROWS_CHANGED, timelineRows }),
  returnToPreviousScreen: () => dispatch(goBack()),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  videoProject: IVideoProject;
  selectedTool: IVideoEditor["selectedTool"];

  loadVideoProject: (payload: { videoProject: IVideoProject }) => void;
  editTimelineRows: (timelineRows: ITimelineRow[]) => void;
  returnToPreviousScreen: () => void;
};

const VideoEditor = (props: PropTypes) => {
  const classes = useStyles();
  const { selectedWebsite, selectedTool, videoProject } = props;
  const { editTimelineRows, loadVideoProject, returnToPreviousScreen } = props;
  const { videoProjectId } = useParams<{ videoProjectId: string }>();
  const history = useHistory();

  useEffect(() => {
    if (!videoProjectId) {
      returnToPreviousScreen();
      return;
    }

    const getProject = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { videoProject } = (await agent.VideoProjects.getProject(selectedWebsite, videoProjectId)) as {
          videoProject: IVideoProject;
        };

        if (!ability.can("view", `video_workflow_${videoProject.currentState.state}_tab`)) {
          history.push(web.videoWorkflowAllStates());
          return;
        }

        loadVideoProject({ videoProject });
      } catch (error) {
        console.log("error", error);
        returnToPreviousScreen();
      }
    };

    getProject();
  }, [history, loadVideoProject, returnToPreviousScreen, selectedWebsite, videoProjectId]);

  const onDragEnd = (data: DropResult) => {
    if (!data.destination) {
      return;
    }

    if (data.destination.droppableId !== "timeline") {
      return;
    }

    const video = videoProject?.videos.find((v) => v._id === data.draggableId);

    if (!video) {
      return;
    }

    const startTime = 0;

    console.log("video", video);

    const videoItem = {
      id: `video-${nanoid(10)}`,
      src: video.cdnUrl,
      file: video,
      duration: video.duration,
      x: 0,
      y: 0,
      startTime,
      endTime: round(startTime + video.duration, 2),

      // to update
      width: 0,
      height: 0,
    };

    const newAction = {
      id: `video-0`,
      start: videoItem.startTime,
      end: videoItem.endTime,
      minStart: videoItem.startTime,
      maxEnd: videoItem.endTime,
      trimStart: 0,
      trimEnd: videoItem.endTime,
      video: videoItem,
      effectId: "video-effect",
    };

    const selectedTimeline = videoProject.timelines.find((t) => t.selected);

    const newTimelineRows = selectedTimeline.rows.map((timelineRow) => {
      if (timelineRow.type === "video") {
        return {
          ...timelineRow,
          actions: [
            newAction,
            ...timelineRow.actions.map((a, i) => ({
              ...a,
              id: `video-${i + 1}`,
              start: round(a.start + videoItem.endTime, 2),
              end: round(a.end + videoItem.endTime, 2),
              minStart: round(a.minStart + videoItem.endTime, 2),
              maxEnd: round(a.maxEnd + videoItem.endTime, 2),
            })),
          ],
        };
      }
      return timelineRow;
    });

    editTimelineRows(newTimelineRows);
  };

  if (!videoProject) {
    return (
      <div>
        <CfProgressBar />
      </div>
    );
  }

  return (
    <VideoEditorProvider>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={classes.editorWrapper}>
          <VideoEditorHeader />
          <div className={classes.main}>
            <div className={classes.leftContainer}>
              <VideoTools />
            </div>
            <div className={classes.rightContainer}>
              <div className={classes.top}>
                <div className={classes.leftSideBar}></div>
                <div className={classes.mainContent}>
                  <VideoWithControls />
                </div>
                <div className={classes.rightSideBar}>
                  <VideoEditorTabs />
                </div>
              </div>
              <div className={classes.bottom}>
                <div
                  className={classNames(
                    classes.timelineWrapper,
                    (selectedTool === eVideoProjectTool.REVIEW || !ability.can("view", "video_editor_timeline")) &&
                      classes.hideTimeline
                  )}
                >
                  <TimelineControlBar />
                  <TimelinePanel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>
    </VideoEditorProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoEditor);
