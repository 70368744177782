import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import Typography from "@material-ui/core/Typography";
import { Paper, Popover } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ClassNames from "classnames";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import edit from "../../../img/edit.png";
import SelectRow from "./SelectRow";
import {
  borderStyleOptions,
  fontOptions,
  fontStyleOptions,
  letterCaseOptions,
  textDecorationOptions,
  weightOptions,
} from "../WebsiteEditor/consts/CssConsts";
import InputRow from "./InputRow";
import DimensionsInputRow from "./DimensionsInputRow";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ColorPickerRow from "./ColorPickerRow";

const useStyles = makeStyles((theme: Theme) => ({
  elementTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 15,
  },
  toggleGroup: {
    width: "100%",
    marginBottom: 20,
  },
  toggleButton: {
    width: "100%",
  },
  designPropsRow: {
    display: "flex",
    padding: "25px 30px",
    width: "100%",
    marginBottom: 30,
    "&:hover": {
      "& $editWrapper": {
        visibility: "visible",
      },
      backgroundColor: theme.palette.secondary.main,
    },
  },
  designWrapper: {
    width: "100%",
    cursor: "pointer",
    justifyContent: "space-between",
  },
  popoverPaper: {
    borderRadius: 0,
    boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.15)",
    border: "none",
    marginLeft: 10,
  },
  wrapper: {
    height: "100%",
    width: 416,
    border: "none",
    borderRadius: 0,
  },
  innerWrapper: {
    padding: "15px 20px",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 15,
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  editImage: {
    marginLeft: 15,
    cursor: "pointer",
  },
  editWrapper: {
    width: "100%",
    display: "flex",
    visibility: "hidden",
    flexDirection: "row",
    gap: 10,
    marginTop: 15,
    alignItems: "center",
    justifyContent: "end",
  },
  designText: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  previewWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  previewTable: {
    width: "100%",

    borderCollapse: "collapse",
  },
  tableBorder: {
    border: `1px solid ${theme.palette.divider}`,
  },
  tableHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold as any,
    textAlign: "left",
    width: "100%",
    height: 70,
  },
  tableRows: {
    borderColor: theme.palette.divider,
    borderWidth: 1,
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: theme.typography.fontWeightRegular as any,
    textAlign: "left",
    width: "100%",
    height: 70,
  },
  tableRowItem: {
    paddingLeft: 20,
  },
}));

type PropTypes = {
  theme: IWebsiteThemeProps;
  elementTitle?: string;
  elementCss: any;
  defaultElementCss: any;

  handleCssChanged: (css) => void;
};

const mapStateToProps = (state) => ({
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({});

const TableDesignToolWithPreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { elementCss, defaultElementCss } = props;
  const [isHeader, setIsHeader] = React.useState(true);
  const [css, setCss] = React.useState<any>({});
  const [headerCss, setHeaderCss] = React.useState<any>({});
  const [bodyCss, setBodyCss] = React.useState<any>({});

  const [finalDefaultCss, setFinalDefaultCss] = React.useState<any>({});
  const [finalDefaultHeaderCss, setFinalDefaultHeaderCss] = React.useState<any>({});
  const [finalDefaultBodyCss, setFinalDefaultBodyCss] = React.useState<any>({});

  const { theme, elementTitle, handleCssChanged } = props;

  React.useEffect(() => {
    setCss(elementCss.css);
    setFinalDefaultCss({
      ...defaultElementCss?.css,
    });
    setHeaderCss(elementCss?.headerCss);
    setBodyCss(elementCss?.bodyCss);
    setFinalDefaultHeaderCss({
      ...defaultElementCss?.css,
      ...defaultElementCss?.headerCss,
    });
    setFinalDefaultBodyCss({
      ...defaultElementCss?.css,
      ...defaultElementCss?.bodyCss,
    });
  }, [isHeader, elementCss, defaultElementCss, theme.palette]);

  const handleRegularCssChanged = (cssName, cssValue) => {
    if (cssValue === null || cssValue === "" || cssValue === finalDefaultCss[cssName]) {
      delete css[cssName];
      const newCss = {
        css,
        headerCss: elementCss.headerCss,
        bodyCss: elementCss.bodyCss,
      };
      handleCssChanged(newCss);
      setCss(css);
      return;
    }
    const newCss = {
      css: { ...css, [cssName]: cssValue },
      headerCss: elementCss.headerCss,
      bodyCss: elementCss.bodyCss,
    };
    handleCssChanged(newCss);
    setCss(newCss.css);
  };

  const handleHeaderCssChanged = (cssName, cssValue) => {
    if (cssValue === null || cssValue === "" || cssValue === finalDefaultHeaderCss[cssName]) {
      delete headerCss[cssName];
      const newCss = {
        css: elementCss.css,
        headerCss: { ...elementCss.headerCss, ...headerCss },
        bodyCss: elementCss.bodyCss,
      };
      handleCssChanged(newCss);
      setHeaderCss(css);
      return;
    }
    const newCss = {
      css: { ...elementCss.css },
      headerCss: { ...elementCss?.headerCss, [cssName]: cssValue },
      bodyCss: elementCss.bodyCss,
    };
    handleCssChanged(newCss);
    setHeaderCss({ ...elementCss?.headerCss, [cssName]: cssValue });
  };

  const handleBodyCssChanged = (cssName, cssValue) => {
    if (cssValue === null || cssValue === "" || cssValue === finalDefaultBodyCss[cssName]) {
      delete bodyCss[cssName];
      const newCss = {
        css: elementCss.css,
        headerCss: elementCss.headerCss,
        bodyCss: { ...elementCss.bodyCss, ...bodyCss },
      };
      handleCssChanged(newCss);
      setHeaderCss(css);
      return;
    }
    const newCss = {
      css: elementCss.css,
      headerCss: elementCss.headerCss,
      bodyCss: { ...elementCss?.bodyCss, [cssName]: cssValue },
    };
    handleCssChanged(newCss);
    setBodyCss({ ...elementCss?.bodyCss, [cssName]: cssValue });
  };

  const onCssChanged = (cssName, cssValue, hoverable) => {
    if (!hoverable || !isHeader) {
      handleRegularCssChanged(cssName, cssValue);
    } else {
      handleHeaderCssChanged(cssName, cssValue);
    }
  };

  const onCssChanged1 = (cssName, cssValue, type: "" | "header" | "body") => {
    if (type === "header") {
      handleHeaderCssChanged(cssName, cssValue);
    } else if (type === "body") {
      handleBodyCssChanged(cssName, cssValue);
    } else {
      handleRegularCssChanged(cssName, cssValue);
    }
  };

  const handleHoverView = (event: React.MouseEvent<HTMLElement>, newHover: boolean | null) => {
    if (newHover !== null) {
      setIsHeader(newHover);
    }
  };
  const rows = [1, 2, 3];

  return (
    <div className={classes.designPropsRow}>
      <PopupState variant="popover" popupId="popover">
        {(popupState) => (
          <div style={{ display: "contents" }}>
            <div className={classes.designWrapper} {...bindTrigger(popupState)}>
              <div style={{ minWidth: "50%" }}>
                <Typography variant={"body2"} className={classes.elementTitle}>
                  {elementTitle}
                </Typography>
                <div className={classes.previewWrapper}>
                  <table
                    style={{ ...defaultElementCss.css, ...css }}
                    className={ClassNames(classes.previewTable, classes.tableBorder)}
                  >
                    <thead
                      style={{
                        ...defaultElementCss.css,
                        ...css,
                        ...defaultElementCss.headerCss,
                        ...headerCss,
                      }}
                      className={classes.tableHeader}
                    >
                      <tr
                        style={{
                          ...defaultElementCss.css,
                          ...css,
                          ...defaultElementCss.headerCss,
                          ...headerCss,
                        }}
                      >
                        <th className={ClassNames(classes.tableRowItem, classes.tableBorder)}>Feature 1</th>
                        <th className={ClassNames(classes.tableRowItem, classes.tableBorder)}>Feature 2</th>
                        <th className={ClassNames(classes.tableRowItem, classes.tableBorder)}>Feature 3</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        ...defaultElementCss.css,
                        ...css,
                        ...defaultElementCss.bodyCss,
                        ...bodyCss,
                      }}
                    >
                      {rows.map((row, i) => (
                        <tr
                          style={{
                            ...defaultElementCss.css,
                            ...css,
                            ...defaultElementCss.bodyCss,
                            ...bodyCss,
                          }}
                          className={classes.tableRows}
                        >
                          <td className={ClassNames(classes.tableRowItem, classes.tableBorder)}>
                            Feature {row}
                            {i + 1}
                          </td>
                          <td className={ClassNames(classes.tableRowItem, classes.tableBorder)}>
                            Feature {row}
                            {i + 1}
                          </td>
                          <td className={ClassNames(classes.tableRowItem, classes.tableBorder)}>
                            Feature {row}
                            {i + 1}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className={classes.editWrapper}>
                    <img width={12} height={12} src={edit} alt={"edit_image"} className={classes.editImage} />
                    <Typography variant={"body2"} className={classes.designText}>
                      {I18n.t("website.edit")}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              elevation={0}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <Paper className={classes.wrapper}>
                <div className={classes.titleWrapper}>
                  <Typography color={"textPrimary"} variant={"subtitle1"}>
                    {`${elementTitle} Typography`}
                  </Typography>
                  <CloseIcon className={classes.closeIcon} onClick={popupState.close} />
                </div>
                <div className={classes.innerWrapper}>
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.font")}
                    value={css?.fontFamily}
                    defaultValue={finalDefaultCss?.fontFamily || theme.typography.fontFamily}
                    isValueChanged={
                      css?.fontFamily &&
                      css?.fontFamily !== (finalDefaultCss?.fontFamily || theme.typography.fontFamily)
                    }
                    selectOptions={fontOptions}
                    handleChange={(font) => {
                      onCssChanged("fontFamily", font, false);
                    }}
                  />

                  {/*<DimensionsInputRow*/}
                  {/*	rowTitle={I18n.t('website.design_tool.padding')}*/}
                  {/*	value={css?.padding}*/}
                  {/*	defaultValue={finalDefaultCss?.padding}*/}
                  {/*	isValueChanged={css?.padding && css?.padding !== finalDefaultCss?.padding}*/}
                  {/*	handleChange={(padding) => {*/}
                  {/*		onCssChanged('padding', padding, false);*/}
                  {/*	}}*/}
                  {/*/>*/}

                  {/*<DimensionsInputRow*/}
                  {/*	rowTitle={I18n.t('website.design_tool.margin')}*/}
                  {/*	value={css?.margin}*/}
                  {/*	defaultValue={finalDefaultCss?.margin}*/}
                  {/*	isValueChanged={css?.margin && css?.margin !== finalDefaultCss?.margin}*/}
                  {/*	handleChange={(margin) => {*/}
                  {/*		onCssChanged('margin', margin, false);*/}
                  {/*	}}*/}
                  {/*/>*/}

                  <ToggleButtonGroup
                    className={classes.toggleGroup}
                    size="small"
                    exclusive
                    value={isHeader}
                    onChange={handleHoverView}
                    aria-label="device"
                  >
                    <ToggleButton className={classes.toggleButton} value={true} aria-label="regular">
                      <Typography color={"textPrimary"} variant={"body1"}>
                        {I18n.t("website.design_tool.header")}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton className={classes.toggleButton} value={false} aria-label="hover">
                      <Typography color={"textPrimary"} variant={"body1"}>
                        {I18n.t("website.design_tool.body")}
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {isHeader ? (
                    <InputRow
                      rowTitle={I18n.t("website.design_tool.size")}
                      value={headerCss?.fontSize}
                      defaultValue={finalDefaultHeaderCss?.fontSize}
                      isValueChanged={headerCss?.fontSize && headerCss?.fontSize !== finalDefaultHeaderCss?.fontSize}
                      handleChange={(size) => {
                        onCssChanged1("fontSize", size, "header");
                      }}
                    />
                  ) : (
                    <InputRow
                      rowTitle={I18n.t("website.design_tool.size")}
                      value={bodyCss?.fontSize}
                      defaultValue={finalDefaultBodyCss?.fontSize}
                      isValueChanged={bodyCss?.fontSize && bodyCss?.fontSize !== finalDefaultBodyCss?.fontSize}
                      handleChange={(size) => {
                        onCssChanged1("fontSize", size, "body");
                      }}
                    />
                  )}

                  {isHeader ? (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.text_color")}
                      value={headerCss?.color}
                      defaultValue={finalDefaultHeaderCss?.color}
                      isValueChanged={headerCss?.color && headerCss?.color !== finalDefaultHeaderCss?.color}
                      handleChange={(color) => {
                        onCssChanged1("color", color, "header");
                      }}
                    />
                  ) : (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.text_color")}
                      value={bodyCss?.color}
                      defaultValue={finalDefaultBodyCss?.color}
                      isValueChanged={bodyCss?.color && bodyCss?.color !== finalDefaultBodyCss?.color}
                      handleChange={(color) => {
                        onCssChanged1("color", color, "body");
                      }}
                    />
                  )}

                  {isHeader ? (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.background_color")}
                      value={headerCss?.backgroundColor}
                      defaultValue={finalDefaultHeaderCss?.backgroundColor}
                      isValueChanged={
                        headerCss?.backgroundColor &&
                        headerCss?.backgroundColor !== finalDefaultHeaderCss?.backgroundColor
                      }
                      handleChange={(backgroundColor) => {
                        onCssChanged1("backgroundColor", backgroundColor, "header");
                      }}
                    />
                  ) : (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.background_color")}
                      value={bodyCss?.backgroundColor}
                      defaultValue={finalDefaultBodyCss?.backgroundColor}
                      isValueChanged={
                        bodyCss?.backgroundColor && bodyCss?.backgroundColor !== finalDefaultBodyCss?.backgroundColor
                      }
                      handleChange={(backgroundColor) => {
                        onCssChanged1("backgroundColor", backgroundColor, "body");
                      }}
                    />
                  )}

                  {isHeader ? (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.weight")}
                      value={headerCss?.fontWeight}
                      defaultValue={finalDefaultHeaderCss?.fontWeight}
                      isValueChanged={
                        headerCss?.fontWeight && headerCss?.fontWeight !== finalDefaultHeaderCss?.fontWeight
                      }
                      selectOptions={weightOptions}
                      handleChange={(weight) => {
                        onCssChanged1("fontWeight", weight, "header");
                      }}
                    />
                  ) : (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.weight")}
                      value={bodyCss?.fontWeight}
                      defaultValue={finalDefaultBodyCss?.fontWeight}
                      isValueChanged={bodyCss?.fontWeight && bodyCss?.fontWeight !== finalDefaultBodyCss?.fontWeight}
                      selectOptions={weightOptions}
                      handleChange={(weight) => {
                        onCssChanged1("fontWeight", weight, "body");
                      }}
                    />
                  )}

                  {isHeader ? (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.font_style")}
                      value={headerCss?.fontStyle}
                      defaultValue={finalDefaultHeaderCss?.fontStyle}
                      isValueChanged={headerCss?.fontStyle && headerCss?.fontStyle !== finalDefaultHeaderCss?.fontStyle}
                      selectOptions={fontStyleOptions}
                      handleChange={(fontStyle) => {
                        onCssChanged1("fontStyle", fontStyle, "header");
                      }}
                    />
                  ) : (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.font_style")}
                      value={bodyCss?.fontStyle}
                      defaultValue={finalDefaultBodyCss?.fontStyle}
                      isValueChanged={bodyCss?.fontStyle && bodyCss?.fontStyle !== finalDefaultBodyCss?.fontStyle}
                      selectOptions={fontStyleOptions}
                      handleChange={(fontStyle) => {
                        onCssChanged1("fontStyle", fontStyle, "body");
                      }}
                    />
                  )}

                  {isHeader ? (
                    <SelectRow
                      id={"textTransform-hover"}
                      rowTitle={I18n.t("website.design_tool.letter_case")}
                      value={headerCss?.textTransform}
                      defaultValue={finalDefaultHeaderCss?.textTransform}
                      isValueChanged={
                        headerCss?.textTransform && headerCss?.textTransform !== finalDefaultHeaderCss?.textTransform
                      }
                      selectOptions={letterCaseOptions}
                      handleChange={(letterCase) => {
                        onCssChanged1("textTransform", letterCase, "header");
                      }}
                    />
                  ) : (
                    <SelectRow
                      id={"textTransform"}
                      rowTitle={I18n.t("website.design_tool.letter_case")}
                      value={bodyCss?.textTransform}
                      defaultValue={finalDefaultBodyCss.textTransform}
                      isValueChanged={
                        bodyCss?.textTransform && bodyCss?.textTransform !== finalDefaultBodyCss?.textTransform
                      }
                      selectOptions={letterCaseOptions}
                      handleChange={(letterCase) => {
                        onCssChanged1("textTransform", letterCase, "body");
                      }}
                    />
                  )}

                  {isHeader ? (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.text_decoration")}
                      value={headerCss?.textDecoration}
                      defaultValue={finalDefaultHeaderCss?.textDecoration}
                      isValueChanged={
                        headerCss?.textDecoration && headerCss?.textDecoration !== finalDefaultHeaderCss?.textDecoration
                      }
                      selectOptions={textDecorationOptions}
                      handleChange={(textDecoration) => {
                        onCssChanged1("textDecoration", textDecoration, "header");
                      }}
                    />
                  ) : (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.text_decoration")}
                      value={bodyCss?.textDecoration}
                      defaultValue={finalDefaultBodyCss?.textDecoration}
                      isValueChanged={
                        bodyCss?.textDecoration && bodyCss?.textDecoration !== finalDefaultBodyCss.textDecoration
                      }
                      selectOptions={textDecorationOptions}
                      handleChange={(textDecoration) => {
                        onCssChanged1("textDecoration", textDecoration, "body");
                      }}
                    />
                  )}

                  {isHeader ? (
                    <InputRow
                      rowTitle={I18n.t("website.design_tool.border_width")}
                      value={headerCss?.borderWidth}
                      defaultValue={finalDefaultHeaderCss?.borderWidth}
                      isValueChanged={
                        headerCss?.borderWidth && headerCss?.borderWidth !== finalDefaultHeaderCss?.borderWidth
                      }
                      handleChange={(borderWidth) => {
                        onCssChanged1("borderWidth", borderWidth, "header");
                      }}
                    />
                  ) : (
                    <InputRow
                      rowTitle={I18n.t("website.design_tool.border_width")}
                      value={bodyCss?.borderWidth}
                      defaultValue={finalDefaultBodyCss?.borderWidth}
                      isValueChanged={bodyCss?.borderWidth && bodyCss?.borderWidth !== finalDefaultBodyCss?.borderWidth}
                      handleChange={(borderWidth) => {
                        onCssChanged1("borderWidth", borderWidth, "body");
                      }}
                    />
                  )}

                  {isHeader ? (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.border_color")}
                      value={headerCss?.borderColor}
                      defaultValue={finalDefaultHeaderCss?.borderColor || theme.palette.divider}
                      isValueChanged={
                        headerCss?.borderColor && headerCss?.borderColor !== finalDefaultHeaderCss?.borderColor
                      }
                      handleChange={(borderColor) => {
                        onCssChanged1("borderColor", borderColor, "header");
                      }}
                    />
                  ) : (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.border_color")}
                      value={bodyCss?.borderColor}
                      defaultValue={finalDefaultCss?.borderColor || theme.palette.divider}
                      isValueChanged={bodyCss?.borderColor && bodyCss?.borderColor !== finalDefaultBodyCss?.borderColor}
                      handleChange={(borderColor) => {
                        onCssChanged1("borderColor", borderColor, "body");
                      }}
                    />
                  )}
                </div>
              </Paper>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TableDesignToolWithPreview);
