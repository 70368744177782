import { useReducer, useEffect, useMemo, useState } from "react";
import moment from "moment";
import numeral from "numeral";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";

import agent from "../../../../agent";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import { IReports } from "../../../../reducers/constants/apiAgentTypes";
import { REPORT_CACHE_KEY_NAME } from "../../../../helpers/constants";

type State = {
  loading: boolean;
  fetchResponse: any;
};

type Action = { type: "SET_LOADING"; payload: boolean } | { type: "SET_FETCH_RESPONSE"; payload: any };

const initialState: State = {
  loading: true,
  fetchResponse: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_FETCH_RESPONSE":
      return { ...state, fetchResponse: action.payload };
    default:
      return state;
  }
};

type PropTypes = {
  selectedWebsite: IWebsite;
  startDate: Date;
  reportType:
    | "indexed-queries"
    | "search-console"
    | "words-count"
    | "cta-clicks"
    | "traffic-trend"
    | "top-pages"
    | "top-queries"
    | "cta-by-url"
    | "cta-by-destination"
    | "top-topics"
    | "article-count"
    | "cta-origin"
    | "custom"
    | "internal-link-click"
    | "outbound-link-click";
  extraParams?: Record<string, any>;
};

const useReport = (props: PropTypes) => {
  const { selectedWebsite, startDate, reportType, extraParams } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const dateFormat = (tickItem) => moment(tickItem).format("MMM");
  const monthFormat = (tickItem) => moment(tickItem).format("MMM");
  const fullDateFormat = (tickItem) => moment(tickItem).format("MMM D, YYYY");
  const numberFormat = (tickItem) => numeral(tickItem).format("0a");
  const titlesFormat = (value, name) => [
    numeral(value).format("0a"),
    (name.charAt(0).toUpperCase() + name.slice(1)).replace(/_/g, " "),
  ];

  const apiAgent = useMemo(() => {
    switch (reportType) {
      case "indexed-queries":
        return agent.Reports.getIndexedQueriesData;
      case "search-console":
        return agent.Reports.getSearchConsoleData;
      case "words-count":
        return agent.Reports.getWordsCountData;
      case "cta-clicks":
        return agent.Reports.actionsQueryBuilder("cta-clicks");
      case "internal-link-click":
        return agent.Reports.actionsQueryBuilder("internal-link-click");
      case "outbound-link-click":
        return agent.Reports.actionsQueryBuilder("outbound-link-click");
      case "traffic-trend":
        return agent.Reports.getTrafficTrendData;
      case "top-pages":
        return agent.Reports.getTopPages;
      case "top-queries":
        return agent.Reports.getTopQueries;
      case "top-topics":
        return agent.Reports.getTopTopics;
      case "cta-by-url":
        return agent.Reports.getCtaByUrl;
      case "cta-by-destination":
        return agent.Reports.getCtaByDestination;
      case "article-count":
        return agent.Reports.getArticleCount;
      case "cta-origin":
        return agent.Reports.getCtaOriginData;
      case "custom":
        return agent.Reports.getCustomReport;
      default:
        return "";
    }
  }, [reportType]) as (body: IReports[typeof reportType]) => Promise<any>;

  useEffect(() => {
    if (!selectedWebsite || !reportType) {
      return;
    }
    const getData = async () => {
      if (!apiAgent) {
        return;
      }
      dispatch({ type: "SET_LOADING", payload: true });
      const reqBody = {
        website: selectedWebsite,
        refreshCache: !!sessionStorage.getItem(REPORT_CACHE_KEY_NAME),
        startDate: moment(startDate).format("YYYY-MM-DD"),
        ...extraParams,
      } as IReports[typeof reportType];

      await apiAgent(reqBody)
        .then((res) => {
          dispatch({ type: "SET_FETCH_RESPONSE", payload: res });
        })
        .catch((e) => {
          enqueueSnackbar(I18n.t("snackbar.create_error", { msg: e.message }));
        });
      dispatch({ type: "SET_LOADING", payload: false });
    };

    getData()
      .then(() => {
        sessionStorage.removeItem(REPORT_CACHE_KEY_NAME);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [apiAgent, selectedWebsite, triggerRefresh, startDate, extraParams, enqueueSnackbar]);

  return {
    loading: state.loading,
    fetchResponse: state.fetchResponse,
    dateFormat,
    monthFormat,
    fullDateFormat,
    triggerRefresh,
    setTriggerRefresh,
    numberFormat,
    titlesFormat,
  };
};

export default useReport;
