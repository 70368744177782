import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { find } from "lodash";
import { IAd, IFile, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import MTextField from "../../../../../../../components/MTextField";
import MTextButton from "../../../../../../../components/MTextButton";
import XIcon from "../../../../../../../icons/XIcon";
import CfCheckBox from "../../../../../../../components/CfCheckBox";
import MSelect from "../../../../../../../components/MSelect";
import InsertImageDialog from "../../../../../../Modals/InsertImageDialog/InsertImageDialog";

const useStyles = makeStyles((theme: Theme) => ({
  userSelectorWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  selectWrapper: {
    minWidth: 250,
    marginLeft: 10,
  },
  fieldWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  textFieldInput: {
    minHeight: 29,
  },
  textFieldMultilineInput: {
    minHeight: 134,
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  editorToolbar: {
    overflowX: "scroll",
    position: "relative",
    zIndex: 0,
  },
  formWrapper: {
    width: "100%",
    marginTop: 25,
    borderTop: `solid 1px ${theme.palette.divider}`,
    paddingTop: 25,
  },
  formFieldWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    gap: "10px",
  },
  fieldFirstRow: {
    display: "flex",
    flexDirection: "row",
  },
  fieldSecondRow: {
    display: "flex",
    flexDirection: "row",
  },
  removeIcon: {
    color: theme.palette.text.hint,
    cursor: "pointer",
    height: 10,
    width: 10,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  typesSelect: {
    width: 145,
    marginRight: 30,
    border: `${theme.palette.divider} solid 1px`,
    borderRadius: 20,
  },
  logoButton: {
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(12),
    color: "#4353ff",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  ad: IAd;

  handleAdChanged: Function;
};

const fieldTypes = [
  { label: "Text", value: "text" },
  { label: "Number", value: "number" },
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
];
const AdFormDetails = (props: PropTypes) => {
  const classes = useStyles(props);
  const [insertImageOpen, setInsertImageOpen] = React.useState(false);
  const { selectedWebsite, ad, handleAdChanged } = props;

  return (
    <div className={classes.formWrapper}>
      <Typography variant={"subtitle1"}>{I18n.t("ads.edit_ad.content.form.form")}</Typography>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.form.logo")}
        </Typography>
        {ad.form.logo && <img width={"100%"} height={"auto"} src={ad.form.logo?.cdnUrl || ad.form.logo.url} />}
        <Typography
          className={classes.logoButton}
          variant="body1"
          onClick={(e) => {
            e.stopPropagation();
            setInsertImageOpen(true);
          }}
        >
          {ad.form.logo ? I18n.t(`ads.edit_ad.placements.replace_image`) : I18n.t(`ads.edit_ad.placements.add_image`)}
        </Typography>
      </div>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.form.title")}
        </Typography>
        <MTextField
          fullWidth
          outlined
          inputClassName={classes.textFieldInput}
          value={ad.form.title}
          onChange={(title) => handleAdChanged({ ...ad, form: { ...ad.form, title } })}
          placeholder={I18n.t("ads.edit_ad.content.form.title_ph")}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.form.text")}
        </Typography>
        <MTextField
          fullWidth
          outlined
          inputClassName={classes.textFieldInput}
          value={ad.form.text}
          onChange={(text) => handleAdChanged({ ...ad, form: { ...ad.form, text } })}
          placeholder={I18n.t("ads.edit_ad.content.form.text_ph")}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.form.fields")}
        </Typography>
        {ad.form.fields.map((field, index) => (
          <div key={`form_field_${index}`} className={classes.formFieldWrapper}>
            <Typography className={classes.fieldTitle} style={{ marginTop: 20 }} variant={"body1"}>
              {I18n.t("ads.edit_ad.content.form.field", { number: index + 1 })}
            </Typography>
            <div className={classes.fieldFirstRow}>
              <div>
                <Typography className={classes.fieldTitle} variant={"body1"}>
                  {I18n.t("ads.edit_ad.content.form.field_name")}
                </Typography>
                <MTextField
                  fullWidth
                  outlined
                  inputClassName={classes.textFieldInput}
                  value={field.name}
                  onChange={(name) => {
                    const fields = [...ad.form.fields];
                    fields[index].name = name;
                    handleAdChanged({ ...ad, form: { ...ad.form, fields } });
                  }}
                  placeholder={I18n.t("ads.edit_ad.content.form.field_name_ph")}
                />
              </div>
              <div>
                <Typography className={classes.fieldTitle} variant={"body1"}>
                  {I18n.t("ads.edit_ad.content.form.field_placeholder")}
                </Typography>
                <MTextField
                  fullWidth
                  outlined
                  inputClassName={classes.textFieldInput}
                  value={field.placeholder}
                  onChange={(placeholder) => {
                    const fields = [...ad.form.fields];
                    fields[index].placeholder = placeholder;
                    handleAdChanged({ ...ad, form: { ...ad.form, fields } });
                  }}
                  placeholder={I18n.t("ads.edit_ad.content.form.field_placeholder_ph")}
                />
              </div>
              <IconButton
                onClick={() => {
                  const fields = [...ad.form.fields];
                  fields.splice(index, 1);
                  handleAdChanged({ ...ad, form: { ...ad.form, fields } });
                }}
                edge="end"
                aria-label="delete"
                disableFocusRipple
                disableRipple
                size={"small"}
                style={{ backgroundColor: "transparent" }}
              >
                <XIcon className={classes.removeIcon} />
              </IconButton>
            </div>
            <div className={classes.fieldSecondRow}>
              <MSelect
                customClass={classes.typesSelect}
                options={fieldTypes}
                borderColor={"transparent"}
                placeholder={I18n.t("ads.edit_ad.content.form.type_ph")}
                optionLabel={"label"}
                optionValue={"value"}
                value={find(fieldTypes, { value: field.type })}
                handleChange={(object) => {
                  const fields = [...ad.form.fields];
                  fields[index].type = object.value;
                  handleAdChanged({ ...ad, form: { ...ad.form, fields } });
                }}
                height={40}
              />
              <CfCheckBox
                checked={field.required}
                iconSize={24}
                handleChange={(checked) => {
                  const fields = [...ad.form.fields];
                  fields[index].required = checked;
                  handleAdChanged({ ...ad, form: { ...ad.form, fields } });
                }}
                label={I18n.t("ads.edit_ad.content.form.required")}
                value={"required"}
              />
            </div>
          </div>
        ))}
        <MTextButton
          customClass={classes.fieldTitle}
          text={I18n.t("ads.edit_ad.content.form.add_field")}
          onClick={() =>
            handleAdChanged({
              ...ad,
              form: { ...ad.form, fields: [...(ad.form.fields || []), { name: null, placeholder: null }] },
            })
          }
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.form.consent")}
        </Typography>
        <MTextField
          fullWidth
          outlined
          inputClassName={classes.textFieldInput}
          value={ad.form.consentText}
          onChange={(consentText) => handleAdChanged({ ...ad, form: { ...ad.form, consentText } })}
          placeholder={I18n.t("ads.edit_ad.content.form.consent_ph")}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.form.button")}
        </Typography>
        <MTextField
          fullWidth
          outlined
          inputClassName={classes.textFieldInput}
          value={ad.form.button}
          onChange={(button) => handleAdChanged({ ...ad, form: { ...ad.form, button } })}
          placeholder={I18n.t("ads.edit_ad.content.form.button_ph")}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.form.emails")}
        </Typography>
        <MTextField
          fullWidth
          outlined
          inputClassName={classes.textFieldInput}
          value={ad.form.emails}
          onChange={(emails) => handleAdChanged({ ...ad, form: { ...ad.form, emails } })}
          placeholder={I18n.t("ads.edit_ad.content.form.emails_ph")}
        />
      </div>
      {insertImageOpen && (
        <InsertImageDialog
          website={selectedWebsite}
          selectedImageFile={ad?.form?.logo || null}
          handleInsertClick={(file: IFile) => {
            handleAdChanged({ ...ad, form: { ...ad.form, logo: file } });
            setInsertImageOpen(false);
          }}
          handleCloseClick={(e) => setInsertImageOpen(false)}
        />
      )}
    </div>
  );
};

export default AdFormDetails;
