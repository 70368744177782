import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { IWebsiteHomepageProps } from "../../../../../reducers/constants/objectTypes";
import TextInputToolAndDesign from "../../../components/TextInputToolAndDesign";
import PageSection from "../../../components/PageSection";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
}));

type PropTypes = {
  homepage: IWebsiteHomepageProps;
  onPropertyChanged: (value) => void;
};

const ReviewsKeepshoppers = (props: PropTypes) => {
  const classes = useStyles(props);
  const { homepage, onPropertyChanged } = props;
  const [reviewsKeepshoppersError, setReviewsKeepshoppersError] = React.useState([]);

  const reviewsSectionKeepshoppersValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!homepage?.reviewsSectionKeepshoppers?.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.templates.keepshoppers.reviews_section.title`));
    }
    setReviewsKeepshoppersError(emptyFields);
    return valid;
  };

  React.useEffect(() => {
    if (reviewsKeepshoppersError.length <= 0) {
      return;
    }
    reviewsSectionKeepshoppersValidated();
  }, [homepage?.reviewsSectionKeepshoppers]);

  return (
    <PageSection
      sectionTitle={I18n.t("website.homepage.templates.keepshoppers.reviews_section.reviews_section")}
      checked={homepage?.reviewsSectionKeepshoppers?.enabled || false}
      onCheckedChange={(checked) => {
        if (!reviewsSectionKeepshoppersValidated()) {
          if (!checked && homepage?.reviewsSectionKeepshoppers?.enabled) {
            onPropertyChanged({
              ...homepage,
              reviewsSectionKeepshoppers: { ...homepage?.reviewsSectionKeepshoppers, enabled: checked },
            });
          }
          return;
        }
        onPropertyChanged({
          ...homepage,
          reviewsSectionKeepshoppers: { ...homepage?.reviewsSectionKeepshoppers, enabled: checked },
        });
      }}
    >
      {reviewsKeepshoppersError.length > 0 && (
        <div className={classes.errorWrapper}>
          <Typography variant={"body1"} className={classes.error}>
            {reviewsKeepshoppersError.length > 0 && I18n.t("website.error_message")}
          </Typography>
        </div>
      )}
      <TextInputToolAndDesign
        error={reviewsKeepshoppersError.includes(
          I18n.t(`website.homepage.templates.keepshoppers.reviews_section.title`)
        )}
        textFieldTitle={I18n.t("website.homepage.reviews_title")}
        text={homepage?.reviewsSectionKeepshoppers?.title}
        handleTextChanged={(text) =>
          onPropertyChanged({
            ...homepage,
            reviewsSectionKeepshoppers: { ...homepage?.reviewsSectionKeepshoppers, title: text },
          })
        }
        variant={"h4"}
      />
    </PageSection>
  );
};

export default ReviewsKeepshoppers;
