import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { I18n } from "react-redux-i18n";
import { TransitionProps } from "@material-ui/core/transitions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import CfButton from "../../components/CfButton";
import MTextField from "../../components/MTextField";


const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderWidth: 0,
    height: "100%",
    maxHeight: 631,
    padding: 30,
  },
  titleWrapper: {
    height: 114,
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
  },
  titleAndCloseIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  dialogContent: {
    height: "100%",
    display: "flex",
    padding: 0,
  },
  column: {
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginRight: 20,
    "&:last-child": {
      marginRight: 0,
    },
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: "30px 0 0 0",
  },
  countersAndButton: {
    display: "flex",
    alignItems: "center",
  },
  counter: {
    marginRight: 10,
  },
  counterText: {
    marginLeft: 5,
  },
  closeButton: {
    padding: 0,
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(26),
    color: theme.palette.text.secondary,
  },
  columnTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 5,
  },
  columnTitleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  columnText: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: 10,
  },
  textField: {
    height: "100%",
  },
  inputTextField: {
    padding: 10,
  },
  button: {
    marginLeft: 5,
  },
}));

type PropTypes = {
  open: boolean;

  createSeeds: () => void;
  handleCloseClick: () => void;
  handleChange: (column: string, value: string) => void;
  values: { keywords: string; domains: string; pages: string };
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const AddSeedsDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, handleCloseClick, values, handleChange, createSeeds } = props;

  const getCount = (col: string) =>
    values[col]
      .split("\n")
      .reduce((previousValue, currentValue) => (currentValue ? previousValue + 1 : previousValue), 0);

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      onClose={() => handleCloseClick}
      fullWidth
      maxWidth={"lg"}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.titleWrapper}>
        <div className={classes.titleAndCloseIcon}>
          <Typography variant={"subtitle1"}>{I18n.t("fetch_keywords.dialog.title")}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseClick}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <Typography className={classes.subtitle} variant={"body1"}>
          {I18n.t("fetch_keywords.dialog.subtitle")}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.column}>
          <div className={classes.columnTitleRow}>
            <Typography className={classes.columnTitle} variant={"subtitle2"}>
              {I18n.t("fetch_keywords.dialog.columns.column1.title")}
            </Typography>
            <Typography className={classes.columnText} variant={"body2"}>
              {"0"}
            </Typography>
          </div>
          <Typography className={classes.columnText} variant={"body2"}>
            {I18n.t("fetch_keywords.dialog.columns.column1.text")}
          </Typography>
          <MTextField
            height={"100%"}
            borderRadius={1}
            value={values.keywords || ""}
            onChange={(value) => handleChange("keywords", value)}
            customClass={classes.textField}
            inputClassName={classes.inputTextField}
            outlined
            multiline
            placeholder={I18n.t("fetch_keywords.dialog.columns.column1.placeholder")}
          />
        </div>
        <div className={classes.column}>
          <div className={classes.columnTitleRow}>
            <Typography className={classes.columnTitle} variant={"subtitle2"}>
              {I18n.t("fetch_keywords.dialog.columns.column2.title")}
            </Typography>
            <Typography className={classes.columnText} variant={"body2"}>
              {"0"}
            </Typography>
          </div>
          <Typography className={classes.columnText} variant={"body2"}>
            {I18n.t("fetch_keywords.dialog.columns.column2.text")}
          </Typography>
          <MTextField
            height={"100%"}
            borderRadius={1}
            value={values.domains || ""}
            onChange={(value) => handleChange("domains", value)}
            customClass={classes.textField}
            inputClassName={classes.inputTextField}
            outlined
            multiline
            placeholder={I18n.t("fetch_keywords.dialog.columns.column2.placeholder")}
          />
        </div>
        <div className={classes.column}>
          <div className={classes.columnTitleRow}>
            <Typography className={classes.columnTitle} variant={"subtitle2"}>
              {I18n.t("fetch_keywords.dialog.columns.column3.title")}
            </Typography>
            <Typography className={classes.columnText} variant={"body2"}>
              {"0"}
            </Typography>
          </div>
          <Typography className={classes.columnText} variant={"body2"}>
            {I18n.t("fetch_keywords.dialog.columns.column3.text")}
          </Typography>
          <MTextField
            height={"100%"}
            borderRadius={1}
            customClass={classes.textField}
            inputClassName={classes.inputTextField}
            value={values.pages || ""}
            onChange={(value) => handleChange("pages", value)}
            outlined
            multiline
            placeholder={I18n.t("fetch_keywords.dialog.columns.column3.placeholder")}
          />
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <div className={classes.countersAndButton}>
          <Typography className={classes.counter} variant={"body2"}>
            {getCount("keywords")}
            <span className={classes.counterText}>{I18n.t("fetch_keywords.dialog.keywords")}</span>
          </Typography>
          <Typography className={classes.counter} variant={"body2"}>
            {getCount("domains")}

            <span className={classes.counterText}>{I18n.t("fetch_keywords.dialog.domains")}</span>
          </Typography>
          <Typography className={classes.counter} variant={"body2"}>
            {getCount("pages")}

            <span className={classes.counterText}>{I18n.t("fetch_keywords.dialog.urls")}</span>
          </Typography>
          <CfButton
            height={40}
            text={I18n.t("fetch_keywords.dialog.add_seeds")}
            onClick={(e) => {
              e.preventDefault();
              createSeeds();
              handleCloseClick();
            }}
            customClass={classes.button}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AddSeedsDialog;
