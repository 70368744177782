import { OUTLINE } from "./constants/actionTypes";

const initialState = {
  refreshOutlines: false,
  outlinesLoaded: false,
  outlines: [],
  inProgressOutlines: [],
  inProgressByOthersOutlines: [],

  searchTextFilter: null,

  outlineLoaded: false,
  outline: null,
  outlineWebsite: null,

  outlineKeywordGroupsOptions: [],
  outlineCategoriesOptions: [],
  outlineTagsOptions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OUTLINE.ON_OUTLINE_PAGES_LOADED:
      return {
        ...state,
        outlinesLoaded: true,
        outlines: action.payload.outlines,
        inProgressOutlines: action.payload.inProgressOutlines,
        inProgressByOthersOutlines: action.payload.inProgressByOthersOutlines,
        refreshOutlines: false,
      };
    case OUTLINE.ON_OUTLINE_PAGE_REMOVED:
      return {
        ...state,
        outlines: state.outlines.filter((page) => page._id !== action.outline._id),
      };
    case OUTLINE.ON_OUTLINE_PAGE_LOADED:
      return {
        ...state,
        outlineLoaded: true,
        outline: action.outline,
        outlineWebsite: action.outline.website,
      };
    case OUTLINE.ON_OUTLINE_PAGE_CHANGED:
      return {
        ...state,
        refreshOutlines: true,
        outline: action.outline,
        outlines: state.outlines.map((outline) => {
          if (outline._id === action.outline._id) {
            return action.outline;
          }
          return outline;
        }),
      };
    case OUTLINE.ON_OUTLINE_PAGE_CREATED:
      return {
        ...state,
        outline: [action.outline, ...state.outlines],
      };
    case OUTLINE.ON_OUTLINE_TAGS_LOADED:
      return {
        ...state,
        outlineTagsOptions: action.payload?.tags,
      };
    case OUTLINE.ON_OUTLINE_CATEGORIES_LOADED:
      return {
        ...state,
        outlineCategoriesOptions: action.payload?.categories,
      };
    case OUTLINE.ON_OUTLINE_KEYWORD_GROUPS_LOADED:
      return {
        ...state,
        outlineKeywordGroupsOptions: action.payload?.data,
      };
    case OUTLINE.ON_OUTLINE_PROPERTY_CHANGED:
      return {
        ...state,
        outline: { ...state.outline, [action.name]: action.value },
      };
    case OUTLINE.OUTLINE_PAGE_CLEANUP:
      return {
        ...state,
        outlineLoaded: false,
        outline: initialState.outline,
      };
    case OUTLINE.ON_OUTLINE_PAGES_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        searchTextFilter: action.searchText,
      };
    default:
      return state;
  }
};
