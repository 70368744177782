import * as React from "react";
import _ from "lodash";
import { allCurrencies } from "../../../helpers/constants";
import MSelect, { IMSelectTypes } from "../../../componentsV2/MSelect";

type PropTypes = {
  value: { code: string; symbol: string } | null;
  height?: number;
} & Omit<IMSelectTypes, "options" | "height">;

const CurrencyPickerElement = (props: PropTypes) => {
  const { value, height = 50 } = props;

  return (
    <MSelect
      height={height}
      options={allCurrencies}
      value={_.find(allCurrencies, { code: value?.code })}
      optionValue={"code"}
      formatOptionLabel={(option: any) => `${option?.code}-${option?.symbol}`}
      {...props}
    />
  );
};

export default CurrencyPickerElement;
