import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles, useTheme } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import _, { uniqBy } from "lodash";
import CfButton from "../../../../../../../components/CfButton";
import CfProgressBar from "../../../../../../../components/CfProgressBar";
import { HighlightText } from "./FunnelSidebarStepValueSelect";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    marginRight: 10,
    marginLeft: "-20px",
    flex: 1,
    overflow: "hidden",
    alignItems: "center",
    paddingBottom: 10,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 10,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    width: 625,
  },
  popper: {
    zIndex: 9999,
  },

  searchField: {
    width: "100%",
    color: "#000",
    height: 33,
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette.divider,
    },
    "& .MuiInput-root": {
      height: "100%",
    },
    "& .MuiInputAdornment-positionStart": {
      margin: 0,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },
  searchIcon: {
    color: theme.palette.primary.dark,
  },
  searchWrap: {
    padding: "0 10px",
  },
  searchInput: {
    padding: 0,
    color: "#000",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
    gap: 3,
    maxHeight: 162,
    width: 625,
    minHeight: 100,
    overflowY: "auto",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10px 20px",
    borderTop: `solid 1px ${theme.palette.divider}`,
  },
  clearButton: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: 0,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
  applyButton: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: "7px 20px",
    borderRadius: 20,
  },
  selectionResult: {
    cursor: "pointer",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    overflowX: "hidden",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  selectedResults: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  checkbox: {
    margin: 0,
    padding: "7px 10px",
    gap: 7,
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    "&:hover": {
      fill: theme.palette.primary.main,
      backgroundColor: "#E6EEFF",
      "& $checkBoxIcon": {
        color: theme.palette.primary.main,
      },
      "& $checkboxLabel": {
        color: theme.palette.primary.main,
      },
    },
  },
  checkboxLabel: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight as any,
  },
  clearIcon: {
    fontSize: 14,
    marginLeft: "-6px",
    color: "#000",
  },
  saveText: {
    cursor: "pointer",
  },
  loading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 30,
  },
  selectedCheckboxIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  checkBoxIcon: {
    color: theme.palette.divider,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    height: 20,
    width: 20,
    "& $MuiSvgIcon-root": {
      fontSize: 20,
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
  noResults: {
    padding: "10px 10px",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(14),
  },
  addButton: {
    marginTop: 10,
    width: "100%",
    borderRadius: 0,
  },
  radioButtonRoot: {
    padding: "5px 7px 5px 0",
    color: "#dadada",
    "&$radioButtonChecked": {
      color: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  placeholder?: string;
  multipleOptions?: boolean;
  options: any[];
  value: any;
  optionLabel?: string;
  optionValue?: string;
  handleChange: (value: any) => void;
  titleClassName?: string;
  searchText?: string;
  setSearchText?: (value: string) => void;
  loading?: boolean;
  condition?: string;
};

const FunnelSidebarMultipleStepValueSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    options,
    optionLabel = "label",
    optionValue = "value",
    multipleOptions = false,
    placeholder = I18n.t("common.select_placeholder"),
    loading = false,
    setSearchText,
    searchText,
    value,
    handleChange,
  } = props;
  const theme = useTheme<Theme>();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(value || []);
  const [filteredOptions, setFilteredOptions] = React.useState(options);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    const values = value.filter((v) => v !== undefined);
    if (values.length > 0) {
      setFilteredOptions(uniqBy([...values, ...options], optionValue));
      setSelected(values);
    } else {
      setFilteredOptions(options);
    }
  }, [options, value]);
  const getSelectedResults = React.useMemo(() => {
    const values = value.filter((v) => v !== undefined);
    if (values.length === 0) return placeholder;
    const result = values?.[0]?.[optionLabel] || placeholder;
    if (values?.length === 2) {
      return `${values[0]?.[optionLabel]} or ${values[1]?.[optionLabel]}`;
    }
    if (value?.length > 2) {
      console.log("values", value);
      return `${values[0]?.[optionLabel]} | ${values[1]?.[optionLabel]}, or ${values.length - 2} more`;
    }
    return result;
  }, [value]);

  return (
    <div className={classes.wrapper}>
      <Tooltip title={anchorEl ? "" : getSelectedResults} placement="bottom">
        <Typography
          onClick={(event) => handleClick(event)}
          variant={"body1"}
          className={ClassNames(
            classes.selectionResult,
            value?.length > 0 && classes.selectedResults,
            anchorEl && classes.active
          )}
          color={"textPrimary"}
        >
          {getSelectedResults}
        </Typography>
      </Tooltip>
      <Popper open={open} anchorEl={anchorEl} transition placement={"bottom-start"} className={classes.popper}>
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
            setSelected(value);
          }}
        >
          <div className={classes.paper}>
            <div className={classes.searchWrap}>
              <TextField
                type="search"
                variant={"standard"}
                autoFocus
                value={searchText}
                className={classes.searchField}
                placeholder={"Search value..."}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                InputProps={{
                  classes: { input: classes.searchInput },
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.content}>
              {!loading && filteredOptions && filteredOptions.length > 0 && (
                <>
                  <div
                    key={`all_select`}
                    className={ClassNames(classes.checkbox)}
                    onClick={() => {
                      if (selected.length === filteredOptions.length) {
                        setSelected([]);
                      } else {
                        setSelected(filteredOptions);
                      }
                    }}
                  >
                    <Checkbox
                      classes={{
                        root: classes.checkBoxIcon,
                        checked: classes.selectedCheckboxIcon,
                      }}
                      checked={selected.length === filteredOptions.length}
                    />
                    <Typography className={classes.checkboxLabel}>{"Select all"}</Typography>
                  </div>
                  {filteredOptions.map((option, index) => (
                    <div
                      key={`${option[optionValue]}-${index}`}
                      className={ClassNames(classes.checkbox)}
                      onClick={() => {
                        if (!selected.find((opt) => opt[optionValue] === option[optionValue])) {
                          setSelected([option, ...selected]);
                        } else {
                          setSelected(selected.filter((opt) => opt[optionValue] !== option[optionValue]));
                        }
                      }}
                    >
                      <Checkbox
                        classes={{
                          root: classes.checkBoxIcon,
                          checked: classes.selectedCheckboxIcon,
                        }}
                        checked={Boolean(selected?.find((sv) => sv?.[optionValue] === option[optionValue]))}
                      />
                      <HighlightText
                        text={option[optionLabel]}
                        highlight={searchText}
                        className={classes.checkboxLabel}
                      />
                    </div>
                  ))}
                </>
              )}
              {loading && (
                <div className={classes.loading}>
                  <CfProgressBar size={20} />
                </div>
              )}
              {!loading && filteredOptions && filteredOptions.length === 0 && searchText && (
                <Typography className={classes.noResults} color={"textPrimary"}>
                  {I18n.t("common.no_results")}
                </Typography>
              )}
            </div>

            <div className={classes.buttons}>
              {/* <CfButton */}
              {/*  customClass={classes.clearButton} */}
              {/*  text={I18n.t("common.clear")} */}
              {/*  onClick={(e) => { */}
              {/*    setSelected(null); */}
              {/*  }} */}
              {/* /> */}
              <CfButton
                height={30}
                disabled={selected.length === 0}
                customClass={classes.applyButton}
                text={I18n.t("common.apply")}
                onClick={() => {
                  handleChange(selected);
                  setAnchorEl(null);
                }}
              />
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default FunnelSidebarMultipleStepValueSelect;
