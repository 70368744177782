import * as React from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import MTable from "../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import { ePageState, IPage, IWebsite } from "../../../../reducers/constants/objectTypes";
import PublishedPostItemRow from "./PublishedPostItemRow";
import FieldDateDialog from "../../components/FieldDateDialog";
import { PUBLISHED_PAGES } from "../../../../reducers/constants/actionTypes";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";
import WorkflowPostTypeDialog from "../../components/WorkflowPostTypeDialog";
import agent from "../../../../agent";
import { useSnackbar } from "notistack";

type PropTypes = {
  selectedWebsite: IWebsite;
  postsLoaded: boolean;
  pagesCount: number;
  posts: IPage[];

  sortField: { direction: "desc" | "asc" | undefined; field: string };
  setSortField: (input: { direction: "desc" | "asc" | undefined; field: string }) => void;
  title?: string;
  topHeader?: boolean;
  updatePost: (payload) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  postsLoaded: state.publishedPages.pagesLoaded,
  pagesCount: state.publishedPages.pagesCount,
});
const mapDispatchToProps = (dispatch) => ({
  updatePost: (payload) => dispatch({ type: PUBLISHED_PAGES.PUBLISHED_PAGES_UPDATE_PAGE, payload }),
});

const WriterItemListSubHeader = (props: {
  title: string;
  topHeader: boolean;
  sortField: any;
  setSortField: (input: any) => void;
}) => {
  const theme: any = useTheme();

  return (
    <MTableHeader>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(21)}
        sortField={props.sortField}
        field={"title"}
      >
        {props.title}
      </MTableHeaderSortLabel>
      {props.topHeader && (
        <>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"firstPublishDate"}
          >
            {I18n.t("workflow.published.listHeader.published_date")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"publishedDate"}
          >
            {I18n.t("workflow.published.listHeader.updated_date")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"quotaDate"}
          >
            {I18n.t("workflow.published.listHeader.quota_date")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"locale"}
          >
            {I18n.t("workflow.published.listHeader.locale")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"textWordsCount"}
          >
            {I18n.t("workflow.published.listHeader.words")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel> {I18n.t("workflow.published.listHeader.vertical")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("workflow.published.listHeader.category")}</MTableHeaderLabel>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"linksCount"}
          >
            {I18n.t("workflow.published.listHeader.links")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel>{I18n.t("workflow.published.listHeader.imported")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("workflow.published.listHeader.writer")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("workflow.published.listHeader.status")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("workflow.published.listHeader.published_by")}</MTableHeaderLabel>
        </>
      )}
    </MTableHeader>
  );
};
const PublishedItemList = (props: PropTypes) => {
  const { postsLoaded, posts, sortField, pagesCount, setSortField, updatePost } = props;
  const [post, setPost] = React.useState(null);
  const [dateField, setDateField] = React.useState(null);
  const [changePostType, setChangePostType] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const insertUpdatedPost = (page) => {
    const index = _.findIndex(posts, { _id: page?._id });
    if (index >= 0) {
      posts[index] = page;
      updatePost({ pages: [...posts] });
    }
  };

  const updatePostType = (type: string, post: IPage) => {
    if (type === post.type) return;
    agent.Pages.updatePostType(post._id, type)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: "error" });
          return;
        }
        enqueueSnackbar(res.message);
        insertUpdatedPost({ ...post, type });
      })
      .catch((e) => {
        enqueueSnackbar(e?.message, { variant: "error" });
        console.log(e);
      });
  };

  return (
    <MTable>
      {!postsLoaded && <MTableRowProgressBar />}

      {postsLoaded && posts.length === 0 && <MTableRowEmptyRow />}

      {postsLoaded && posts.length !== 0 && (
        <WriterItemListSubHeader
          sortField={sortField}
          setSortField={setSortField}
          title={I18n.t("workflow.published.published_posts", { count: pagesCount })}
          topHeader
        />
      )}

      {postsLoaded &&
        posts.length > 0 &&
        posts.map((item, index) => (
          <PublishedPostItemRow
            onPostTypeUpdate={() => {
              setChangePostType(true);
              setPost(item);
            }}
            onDueDateUpdate={(field) => {
              setDateField(field);
              setPost(item);
            }}
            key={item._id}
            post={item}
          />
        ))}
      {dateField && post && (
        <FieldDateDialog
          state={ePageState.published}
          post={post}
          field={dateField}
          onUpdate={(payload) => {
            setPost(null);
            setDateField(null);
            insertUpdatedPost(payload.page);
          }}
          onCancel={() => setPost(null)}
        />
      )}
      {changePostType && post && (
        <WorkflowPostTypeDialog
          post={post}
          open
          savePostType={(type) => {
            updatePostType(type, post);
            setChangePostType(false);
            setPost(null);
          }}
          closeDialog={() => {
            setChangePostType(false);
            setPost(null);
          }}
        />
      )}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishedItemList);
