import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { useState } from "react";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@material-ui/core/Typography";
import _, { find } from "lodash";
import { connect } from "react-redux";
import ClassNames from "classnames";
import MTextButton from "../../../components/MTextButton";
import MTextField from "../../../components/MTextField";
import CfButton from "../../../components/CfButton";
import MSelect from "../../../components/MSelect";
import LinkProductPicker from "./LinkProductPicker";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import DownChevronIcon from "../../../icons/DownChevronIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: 20,
      padding: "10px 30px",
      height: 36,
    },
    icon: {
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
    activeIcon: {
      fill: theme.palette.primary.main,
    },
    paper: {
      width: 500,
      borderRadius: 5,
      padding: 24,
      boxShadow: "3px 3px 20px 0px rgba(0, 0, 0, 0.15)",
    },
    linkDetailsWrapper: {
      display: "flex",
      flexDirection: "column",
      marginTop: 48,
      gap: 20,
    },
    textToDisplayDetailsWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    newTabCheckBox: {
      marginLeft: -5,
    },
    linkDetailsField: {
      marginTop: 5,
      height: 35,
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold as any,
    },
    fieldLabel: {
      fontWeight: theme.typography.fontWeightBold as any,
      fontSize: theme.typography.pxToRem(14),
    },
    bottomWrapper: {
      marginTop: 24,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    row: {
      display: "flex",
      width: "100%",
      gap: 10,
      marginTop: 10,
    },
    leftOptionsWrapper: {
      flex: 1,
      marginTop: 8,
    },
    rightOptionsWrapper: {
      flex: 2,
      marginTop: 8,
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    advancedWrapper: {
      display: "flex",
      gap: 10,
      flexDirection: "column",
    },
    advanced: {
      display: "flex",
      cursor: "pointer",
      justifyContent: "start",
      alignItems: "center",
      width: "100%",
      gap: 5,
    },
    advancedText: {
      cursor: "pointer",
    },
    cancelButton: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightLight as any,
      fontSize: theme.typography.pxToRem(14),
    },
    textInput: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    dropdownIcon: {
      fill: theme.palette.text.primary,
      color: theme.palette.text.primary,
    },
    rotateDropdownIcon: {
      transform: "rotate(180deg)",
    },
  })
);

type PropTypes = {
  initialUrl?: string;
  initialCssId?: string;
  // initialTextToDisplay?: string;
  initialNewTab?: boolean;
  initialReal?: string;
  initialType?: string;
  disableClickAway?: boolean;
  closePopover: () => void;
  onInsertLinkClicked: (
    url: string,
    newTab: boolean,
    rel: string | undefined,
    product,
    linkType,
    cssId: string
  ) => void;
  selectedWebsite: IWebsite;
  initialProduct: any;
};
const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});
function LinkEditor(props: PropTypes) {
  const classes = useStyles(props);
  const relOptions = [
    { name: "Follow", key: "follow" },
    { name: "No Follow", key: "nofollow" },
    { name: "Sponsored", key: "sponsored" },
    { name: "UGC", key: "ugc" },
  ];

  const linkTypes = [
    { name: "Product", key: "product" },
    { name: "Url", key: "url", value: false }, // change name
    { name: "Button", key: "button" }, // change name
  ];
  const tabOptions = [
    { name: "Open in a new tab", value: true, key: "new_tab" },
    { name: "Internal link", value: false, key: "internal_link" },
  ];
  const {
    initialUrl = "",
    initialCssId = null,
    initialNewTab = false,
    initialReal,
    initialType,
    disableClickAway = false,
    // initialTextToDisplay,
    selectedWebsite,
    initialProduct,
    closePopover,
    onInsertLinkClicked,
  } = props;
  const [url, setUrl] = useState(initialUrl);
  const [cssId, setCssId] = useState(initialCssId);
  // const [textToDisplay, setTextToDisplay] = useState(initialTextToDisplay);
  const [newTab, setNewTab] = useState(initialNewTab);
  const [rel, setRel] = useState(find(relOptions, { key: initialReal }) || relOptions[0]);
  const [linkType, setLinkType] = useState(find(linkTypes, { key: initialType }) || linkTypes[1]);
  const [product, setProduct] = useState(initialProduct);
  const [advanced, setAdvanced] = useState(false);
  const clearAndClose = () => {
    setUrl("");
    // setTextToDisplay("");
    setNewTab(true);
    setCssId(null);
    setProduct(null);
    setAdvanced(false);
    setRel(relOptions[0]);
    setLinkType(linkTypes[1]);
    closePopover();
  };

  const isDisabled = () => {
    if (linkType.key === "product" && product) return false;
    if (linkType.key === "url" && url && url.length !== 0) return false;
    if (linkType.key === "button") return false;
    return true;
  };

  return (
    <Paper className={classes.paper} contentEditable={false}>
      <ClickAwayListener onClickAway={() => (disableClickAway ? null : clearAndClose())}>
        <div>
          <Typography variant={"subtitle1"}>{I18n.t("rich_text_editor.insert_link.title")}</Typography>
          <div className={classes.linkDetailsWrapper}>
            {/* <div className={classes.column}> */}
            {/*  <Typography color={"textPrimary"} className={classes.fieldLabel} variant={"body2"}> */}
            {/*    {I18n.t("rich_text_editor.insert_link.text_to_display")} */}
            {/*  </Typography> */}
            {/*  <MTextField */}
            {/*    fullWidth */}
            {/*    outlined */}
            {/*    autoFocus */}
            {/*    borderRadius={3} */}
            {/*    customClass={classes.linkDetailsField} */}
            {/*    value={textToDisplay} */}
            {/*    onChange={(text) => setTextToDisplay(text)} */}
            {/*    placeholder={I18n.t("rich_text_editor.insert_link.text_to_display_placeholder")} */}
            {/*  /> */}
            {/* </div> */}
            <div className={classes.row}>
              <div className={classes.column} style={{ flex: 1 }}>
                <Typography color={"textPrimary"} className={classes.fieldLabel} variant={"body2"}>
                  {I18n.t("rich_text_editor.insert_link.type")}
                </Typography>
                <MSelect
                  options={linkTypes}
                  value={linkType}
                  customDropdownIndicatorClasses={classes.dropdownIcon}
                  isBold={false}
                  optionLabel={"name"}
                  optionValue={"key"}
                  customClass={classes.rightOptionsWrapper}
                  height={35}
                  borderWidth={1}
                  menuPlacement={"bottom"}
                  handleChange={(selectedType) => {
                    setLinkType(selectedType);
                    if (selectedType.key === "url") {
                      setProduct(null);
                      setUrl("");
                    }
                    if (selectedType.key === "button") {
                      setProduct(null);
                      setUrl("javascript:void(0)");
                    }
                  }}
                />
              </div>
              {linkType.key === "url" && (
                <div className={classes.column} style={{ flex: 2 }}>
                  <Typography color={"textPrimary"} className={classes.fieldLabel} variant={"body2"}>
                    {I18n.t("rich_text_editor.insert_link.url")}
                  </Typography>
                  <MTextField
                    fullWidth
                    outlined
                    autoFocus
                    height={35}
                    borderRadius={3}
                    inputClassName={classes.textInput}
                    customClass={classes.rightOptionsWrapper}
                    value={url}
                    onChange={(text) => setUrl(text)}
                    placeholder={I18n.t("rich_text_editor.insert_link.url_placeholder")}
                  />
                </div>
              )}
              {linkType.key === "product" && (
                <div className={classes.column} style={{ flex: 2 }}>
                  <Typography color={"textPrimary"} className={classes.fieldLabel} variant={"body2"}>
                    {I18n.t("rich_text_editor.insert_link.select_product")}
                  </Typography>
                  <LinkProductPicker
                    selectedWebsite={selectedWebsite}
                    product={product}
                    onProductChange={(prod) => {
                      setUrl(prod.callToActionURL || prod.link);
                      setProduct({ itemNumber: prod.itemNumber, name: prod.name });
                    }}
                  />
                </div>
              )}
            </div>
            <div className={classes.advancedWrapper}>
              <div className={classes.advanced} onClick={() => setAdvanced(!advanced)}>
                <Typography color={"textPrimary"} className={classes.fieldLabel} variant={"body2"}>
                  {I18n.t("rich_text_editor.insert_link.advanced")}
                </Typography>
                <DownChevronIcon className={ClassNames(classes.dropdownIcon, advanced && classes.rotateDropdownIcon)} />
              </div>
              {advanced && (
                <>
                  {linkType.key === "url" && (
                    <div className={classes.row}>
                      <MSelect
                        options={relOptions}
                        value={rel}
                        isBold={false}
                        optionLabel={"name"}
                        optionValue={"key"}
                        customClass={classes.leftOptionsWrapper}
                        customDropdownIndicatorClasses={classes.dropdownIcon}
                        height={35}
                        borderWidth={1}
                        menuPlacement={"bottom"}
                        handleChange={(selectedRel) => {
                          setRel(selectedRel);
                        }}
                      />
                      <MSelect
                        options={tabOptions}
                        value={_.find(tabOptions, { value: newTab })}
                        optionLabel={"name"}
                        optionValue={"key"}
                        isBold={false}
                        customClass={classes.rightOptionsWrapper}
                        height={35}
                        borderWidth={1}
                        menuPlacement={"bottom"}
                        handleChange={(tab) => {
                          setNewTab(tab.value);
                        }}
                      />
                    </div>
                  )}
                  <div className={classes.row}>
                    <div className={classes.column} style={{ flex: 2 }}>
                      <Typography color={"textPrimary"} className={classes.fieldLabel} variant={"body2"}>
                        {I18n.t("rich_text_editor.insert_link.css_id")}
                      </Typography>
                      <MTextField
                        fullWidth
                        outlined
                        height={35}
                        borderRadius={3}
                        inputClassName={classes.textInput}
                        customClass={classes.rightOptionsWrapper}
                        value={cssId}
                        onChange={(text) => setCssId(text)}
                        placeholder={I18n.t("rich_text_editor.insert_link.css_id_placeholder")}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classes.bottomWrapper}>
            <MTextButton
              customClass={classes.cancelButton}
              text={I18n.t("rich_text_editor.insert_link.cancel")}
              onClick={clearAndClose}
            />
            <CfButton
              height={32}
              customClass={classes.button}
              disabled={isDisabled()}
              text={I18n.t("rich_text_editor.insert_link.apply")}
              onClick={(e) => {
                onInsertLinkClicked(url, newTab, rel.key, product, linkType.key, cssId);
              }}
            />
          </div>
        </div>
      </ClickAwayListener>
    </Paper>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkEditor);
