import * as React from "react";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import { I18n } from "react-redux-i18n";

import StylePanelSection from "../../elementsStyles/components/StylePanelSection";
import HorizontalAlignmentCSS from "../../elementsStyles/CssComponents/HorizontalAlignmentCSS";

type PropTypes = {
  editor: Editor;
  element: any;
};

const InlineHtmlElementStyle = (props: PropTypes) => {
  const { editor, element } = props;

  const handleElementDataChange = (data) => {
    console.log("PageEditorInlineHTMLStyle: handleElementDataChange", data);
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.inline_html.name")} editor={editor}>
      <HorizontalAlignmentCSS
        moreOptions={["none"]}
        value={element.data?.style?.justifyContent || "none"}
        handleChange={(justifyContent) => {
          if (justifyContent === "none") {
            handleElementDataChange({
              ...element.data,
              style: {},
            });
            return;
          }
          handleElementDataChange({
            ...element.data,
            style: { ...element.data?.style, justifyContent, display: "flex", width: "100%" },
          });
        }}
      />
    </StylePanelSection>
  );
};

export default InlineHtmlElementStyle;
