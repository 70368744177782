import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";
import slugify from "slugify";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextareaAutosize from "react-textarea-autosize";
import PageEditorSEO from "./PageEditorSEO";
import MTextCounter from "../../../components/MTextCounter";
import { IPage, IWebsite, IWebsiteConfigurationsProps } from "../../../reducers/constants/objectTypes";
import TableOfContents from "./TableOfContents";
import MTextBox from "../../../components/MTextBox";
import { colors } from "../../../helpers/constants";
import { getWordpressAcfTabs } from "../../../helpers/websiteHelper";
import PageEditorACF from "./AcfComponents/PageEditorACF";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "20px 40px",
    paddingBottom: 0,
  },
  editorNavbar: {
    width: "100%",
    height: 55,
    display: "flex",
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: colors.pageEditorBackgroundColor,
  },
  navElement: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 10,
    cursor: "pointer",
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: 14,
    padding: "0 20px",
    minWidth: "max-content",
    borderBottom: `${theme.palette.divider} solid 1px`,
  },
  lastNavElement: {
    width: "100%",
    height: "calc(100% - 8px)",
    paddingBottom: 10,
    padding: "0 20px",
    borderBottom: `${theme.palette.divider} solid 1px`,
  },
  firstNavElement: {
    paddingLeft: 10,
  },
  navElementSelected: {
    borderBottom: "2px solid blue",
    paddingBottom: 8,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  navElementBody: {
    borderRadius: 0,
    maxHeight: 120,
    minHeight: 120,
    border: "none",
    borderBottom: `${theme.palette.divider} solid 1px`,
    overflow: "auto",
  },
  autoHeight: {
    height: "auto",
  },
  counter: {
    marginLeft: 10,
  },
  briefRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  briefWrapper: {
    padding: 0,
    maxHeight: 300,
    overflow: "auto",
    paddingBottom: 20,
  },
  inANutshellWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "18px 40px 20px 40px",
    minHeight: 120,
    borderBottom: `${theme.palette.divider} 1px solid`,
  },
  switch: {
    "& .MuiSwitch-thumb": {
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      width: 35,
      height: 12,
    },
  },
  switchDisabled: {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#B2B7C2",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#CCCFD6",
    },
  },
  switchWrapper: {
    display: "flex",
    padding: "15px 0px",
    margin: 0,
    alignItems: "center",
    gap: 20,
    "& .MuiTypography-body1": {
      lineHeight: "normal",
      fontSize: theme.typography.pxToRem(12),
      margin: 0,
    },
  },
  textField: {
    width: "100%",
    minHeight: 76,
    border: "1px solid #E5E5E5",
    padding: "10px",
    resize: "vertical",
    marginTop: 20,
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    "&:focus": {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
  },
  noMarginTop: {
    marginTop: 0,
  },
  switchLabel: {
    fontWeight: theme.typography.fontWeightBold as any,
    marginLeft: 5,
    fontSize: theme.typography.pxToRem(14),
    color: "#333333",
  },
}));

type PropTypes = {
  pageRichText: string;
  page: IPage;
  selectedWebsite?: IWebsite;
  websiteConfigurationsProps: IWebsiteConfigurationsProps;
  handlePageChanged: (value: IPage) => void;
};

const PageEditorNavbar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { page, pageRichText, websiteConfigurationsProps, selectedWebsite, handlePageChanged } = props;
  const [selectedTab, setSelectedTab] = React.useState("SEO");
  const [isTOCActive, setIsTOCActive] = React.useState(page.showTOC || false);
  const [editSlugConfirmed, setEditSlugConfirmed] = useState(false);
  const [acfTabs, setAcfTabs] = useState([] as any);

  const handleToggleTOC = (event) => {
    setIsTOCActive(event.target.checked);
    handlePageChanged({ ...page, showTOC: event.target.checked });
  };

  React.useEffect(() => {
    setAcfTabs(getWordpressAcfTabs(selectedWebsite));
  }, [page]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.editorNavbar}>
        <Typography
          variant="subtitle2"
          className={classNames(
            classes.navElement,
            selectedTab === "SEO" ? classes.navElementSelected : "",
            classes.firstNavElement
          )}
          onClick={() => setSelectedTab("SEO")}
        >
          {I18n.t("custom_post.SEO")}
        </Typography>

        {acfTabs.length === 0 && (
          <Typography
            variant="subtitle2"
            className={classNames(classes.navElement, selectedTab === "contents" ? classes.navElementSelected : "")}
            onClick={() => setSelectedTab("contents")}
          >
            {I18n.t("custom_post.table_of_contents")}
          </Typography>
        )}
        {acfTabs.map((tab: any) => (
          <Typography
            key={tab.id}
            variant="subtitle2"
            className={classNames(classes.navElement, selectedTab === tab.id ? classes.navElementSelected : "")}
            onClick={() => setSelectedTab(tab.id)}
          >
            {tab.title}
          </Typography>
        ))}

        {/*<Typography variant="subtitle2" className={classes.lastNavElement}></Typography>*/}
      </div>

      {selectedTab === "SEO" && (
        <PageEditorSEO
          page={page}
          selectedWebsite={selectedWebsite}
          websiteConfigurationsProps={websiteConfigurationsProps}
          handleMetaTitleChanged={(metaTitle) => handlePageChanged({ ...page, metaTitle })}
          handleMetaDescChanged={(metaDescription) => handlePageChanged({ ...page, metaDescription })}
          handleSlugChanged={(slug) => {
            if (!editSlugConfirmed) {
              if (
                confirm(
                  "Changing the slug o f an already published post will affect SEO. Are you sure you want to change it?"
                )
              ) {
                setEditSlugConfirmed(true);
              } else {
                return;
              }
            }
            handlePageChanged({
              ...page,
              slug: slugify(slug || page.title, {
                locale: "en",
                lower: true,
                trim: false,
                strict: true,
                remove: /[*+~.()'"!:@]/g,
              }),
            });
          }}
        />
      )}

      {selectedTab === "description" && (
        <MTextBox
          collapsable={false}
          hasTitle={false}
          hasCounter={false}
          paperCustomClass={classes.navElementBody}
          customClass={classes.autoHeight}
          text={page.metaDescription || ""}
          minHeight={30}
          descriptionPaddingBottom={1}
          desiredCharsCount={160}
          handleTextChanged={(metaDescription) => handlePageChanged({ ...page, metaDescription })}
        />
      )}
      {selectedTab?.includes("acf") && (
        <PageEditorACF
          handleACFChange={(acf) => handlePageChanged({ ...page, customFields: { ...page.customFields, wpAcf: acf } })}
          acf={page.customFields?.wpAcf || {}}
          page={page}
          selectedWebsite={selectedWebsite}
          acfTab={acfTabs.find((tab) => tab.id === selectedTab)}
        />
      )}

      <div className={classes.briefWrapper} style={{ display: selectedTab === "contents" ? "block" : "none" }}>
        <div className={classes.briefRow}>
          <FormControlLabel
            className={classes.switchWrapper}
            labelPlacement={"start"}
            control={
              <Switch
                className={classNames(classes.switch, !isTOCActive ? classes.switchDisabled : "")}
                checked={isTOCActive}
                onChange={handleToggleTOC}
                name="toc"
                size={"small"}
                inputProps={{ "aria-label": "Activate Brief" }}
                color="primary"
              />
            }
            label={
              <Typography className={classes.switchLabel} variant="body1">
                {I18n.t("custom_post.show_TOC")}
              </Typography>
            }
          />
        </div>
        <div style={{ display: "block" }} id={"page_editor_toc"} />
      </div>
    </div>
  );
};

export default PageEditorNavbar;
