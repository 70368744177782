// https://github.com/Dhaiwat10/react-link-preview/tree/master/src/components/LinkPreview
import React, { useEffect, useRef, useState } from "react";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import LinkPreviewSkeleton from "./LinkPreviewSkeleton";
import agent from "../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  Container: {
    textAlign: "left",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    borderRadius: 7,
    border: "1px solid #ccc",
    color: "black",
    transition: "0.3s all ease",
    height: "fit-content",
    "&:hover": {
      backgroundColor: "rgb(250, 250, 250) !important",
      cursor: "pointer",
    },
  },
  Secondary: {
    color: "rgb(100, 100, 100)",
  },

  LowerContainer: {
    padding: 10,
  },

  Title: {
    marginTop: 0,
    marginBottom: 10,
  },
  Description: {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  Image: {
    width: "100%",
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "30vh",
  },
  SiteDetails: {
    marginRop: 10,
  },
}));

function isValidResponse(res: APIResponse | null): boolean {
  if (!res) return false;

  return (
    res.title !== null &&
    res.description !== null &&
    res.image !== null &&
    res.siteName !== null &&
    res.hostname !== null &&
    res.title !== undefined &&
    res.description !== undefined &&
    res.image !== undefined &&
    res.siteName !== undefined &&
    res.hostname !== undefined &&
    res.image !== "null" &&
    !res.image.startsWith("/")
  );
}

export interface LinkPreviewProps {
  url: string;
  className?: string;
  width?: string | number;
  height?: string | number;
  descriptionLength?: number;
  borderRadius?: string | number;
  imageHeight?: string | number;
  textAlign?: "left" | "right" | "center";
  margin?: string | number;
  fallback?: JSX.Element[] | JSX.Element | null;
  backgroundColor?: string;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  borderColor?: string;
  showLoader?: boolean;
  customLoader?: JSX.Element[] | JSX.Element | null;
  openInNewTab?: boolean;
  fetcher?: (url: string) => Promise<APIResponse | null>;
  fallbackImageSrc?: string;
  explicitImageSrc?: string;
  /* Whether the placeholder image is displayed in case no image could be scraped */
  showPlaceholderIfNoImage?: boolean;
  onSuccess?: (metadata: APIResponse | null) => void;
  onFailure?: () => void;
}

export interface APIResponse {
  title: string | null;
  description: string | null;
  image: string | null;
  siteName: string | null;
  hostname: string | null;
}

export const LinkPreview: React.FC<LinkPreviewProps> = (props: LinkPreviewProps) => {
  const {
    url,
    className = "",
    width,
    height,
    descriptionLength,
    borderRadius,
    imageHeight,
    textAlign,
    margin,
    fallback = null,
    backgroundColor = "white",
    primaryTextColor = "black",
    secondaryTextColor = "rgb(100, 100, 100)",
    borderColor = "#ccc",
    showLoader = true,
    customLoader = null,
    openInNewTab = true,
    fetcher,
    fallbackImageSrc = null,
    explicitImageSrc = null,
    showPlaceholderIfNoImage = false,
    onSuccess = (metadata) => {},
  } = props;
  const classes = useStyles(props);
  const _isMounted = useRef(true);
  const [metadata, setMetadata] = useState<APIResponse | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    _isMounted.current = true;
    if (metadata) {
      return;
    }

    setLoading(true);

    agent.SlateEditor.linkPreview(url)
      .then((res) => {
        if (_isMounted.current) {
          setMetadata(res.metadata);
          onSuccess(res.metadata);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        console.error("No metadata could be found for the given URL.");
        if (_isMounted.current) {
          onSuccess(null);
          setMetadata(null);
          setLoading(false);
        }
      });

    return () => (_isMounted.current = false);
  }, [url, fetcher]);

  if (loading && showLoader) {
    if (customLoader) {
      return <>{customLoader}</>;
    }
    return <LinkPreviewSkeleton width={width} imageHeight={imageHeight} margin={margin} />;
  }

  if (!metadata) {
    return <>{fallback}</>;
  }

  const { image, description, title, siteName, hostname } = metadata;

  const onClick = () => {
    const browserTarget = openInNewTab ? "_blank" : "_self";
    window.open(url, browserTarget);
  };

  // console.log("image", decodeURI(explicitImageSrc || image || fallbackImageSrc));
  return (
    <div
      data-testid="container"
      onClick={onClick}
      className={ClassNames(classes.Container, className)}
      style={{ width, height, borderRadius, textAlign, margin, backgroundColor, borderColor }}
    >
      {(image || fallbackImageSrc) && (
        <div
          data-testid="image-container"
          style={{
            borderTopLeftRadius: borderRadius,
            borderTopRightRadius: borderRadius,
            backgroundImage: `url(${decodeURI(
              explicitImageSrc || image || fallbackImageSrc
            )}), url(${fallbackImageSrc})`,
            height: imageHeight,
          }}
          className={classes.Image}
        ></div>
      )}
      {title && (
        <div className={classes.LowerContainer}>
          <h3 data-testid="title" className={classes.Title} style={{ color: primaryTextColor }}>
            {title}
          </h3>
          {description && (
            <span
              data-testid="desc"
              className={ClassNames(classes.Description, classes.Secondary)}
              style={{ color: secondaryTextColor }}
            >
              {descriptionLength
                ? description.length > descriptionLength
                  ? `${description.slice(0, descriptionLength)}...`
                  : description
                : description}
            </span>
          )}
          {/* <div className={ClassNames(classes.Secondary, classes.SiteDetails)} style={{ color: secondaryTextColor }}> */}
          {/*	{siteName && <span>{siteName} • </span>} */}
          {/*	<span>{hostname}</span> */}
          {/* </div> */}
        </div>
      )}
    </div>
  );
};
