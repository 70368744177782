import { ReactEditor, useSelected, useSlateStatic } from "slate-react";
import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { Editor, Transforms, Range, Path, Node } from "slate";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import { emitEvent } from "../../../hooks/useEvents";

export const withWidgets = (editor: Editor) => {
  const { deleteBackward, isVoid, insertBreak } = editor;

  editor.isVoid = (element) =>
    [
      "comparison-table",
      "pricing-table",
      "inline-html",
      "product-embed",
      "product-cta",
      "product-cta-horizontal",
      "charticle-top-product",
      "charticle-top-products",
      "faq",
      "product-details",
      "call-to-action",
      "divider",
      "button",
    ].includes(element.type)
      ? true
      : isVoid(element);

  editor.insertBreak = () => {
    console.log("editor.insertBreak");
    if (!editor.selection || !Range.isCollapsed(editor.selection)) {
      return insertBreak();
    }

    const selectedNodePath = Path.parent(editor.selection.anchor.path);
    const selectedNode = Node.get(editor, selectedNodePath);
    if (Editor.isVoid(editor, selectedNode)) {
      Editor.insertNode(editor, {
        type: "paragraph",
        children: [{ text: "" }],
      });
      return;
    }

    insertBreak();
  };

  // if prev node is a void node, remove the current node and select the void node
  editor.deleteBackward = (unit) => {
    if (!editor.selection || !Range.isCollapsed(editor.selection) || editor.selection.anchor.offset !== 0) {
      return deleteBackward(unit);
    }

    const parentPath = Path.parent(editor.selection.anchor.path);
    const parentNode = Node.get(editor, parentPath);
    const parentIsEmpty = Node.string(parentNode).length === 0;

    if (parentIsEmpty && Path.hasPrevious(parentPath)) {
      const prevNodePath = Path.previous(parentPath);
      const prevNode = Node.get(editor, prevNodePath);
      if (Editor.isVoid(editor, prevNode)) {
        return Transforms.removeNodes(editor);
      }
    }

    deleteBackward(unit);
  };

  return editor;
};

const useStyles = makeStyles((theme: Theme) => ({
  voidWrapper: {
    position: "relative",
    outline: "1px solid transparent",
    borderRadius: 3,
    marginTop: (props: PropTypes) => (!props.element.forcedLayout ? 20 : 0),
    padding: 10,
    "&:hover": {
      outline: "1px solid #A9CAFF",
      "& > $actionsWrapper": {
        opacity: 1,
      },
    },
    "&:after": {
      position: "absolute",
      top: "-1px",
      left: "50%",
      transform: "translateX(-50%)",
      content: "' '",
      background: theme.palette.background.paper,
      height: 1,
    },
    "&:before": {
      position: "absolute",
      bottom: (props: PropTypes) => (!props.element.forcedLayout ? "-1px" : 0),
      left: "50%",
      transform: "translateX(-50%)",
      content: "' '",
      background: theme.palette.background.paper,
      width: 25,
      height: 1,
    },
  },
  readModeWrapper: {
    position: "relative",
    borderRadius: 3,
    marginTop: 20,
    padding: 10,
  },
  voidWrapperSelected: {
    // border: `1px solid ${theme.palette.divider}`,
  },
  actionsWrapper: {
    opacity: 0,
    zIndex: 2,
    // position: "relative",
  },
  actionsTopWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    backgroundColor: theme.palette.background.paper,
    height: 2,
    borderRadius: 3,
    zIndex: 10,
    padding: "0 0 0 9.5px",
    border: "none",
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
  },
  editDesign: {
    fontSize: 14,
    fill: theme.palette.primary.main,
    "&:hover": {
      fill: theme.palette.primary.light,
    },
  },
  addPAbove: {
    fontSize: 16,
    fill: theme.palette.primary.main,
    cursor: "pointer",
    marginTop: 6,
    "&:hover": {
      fill: theme.palette.primary.light,
    },
  },
  deleteIcon: {
    fontSize: 15,
    fill: theme.palette.primary.main,
    "&:hover": {
      fill: theme.palette.primary.light,
    },
  },
  dragIcon: {
    padding: 4,
    backgroundColor: "transparent",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateY(50%) translateX(-50%)",
    cursor: "pointer",
  },
  iconAddButton: {
    padding: 8.5,
    borderRadius: 0,
    // "&:hover": {
    //   backgroundColor: theme.palette.primary.main,
    // },
  },
  iconDeleteButton: {
    padding: 9.5,
    borderRadius: 0,
    "&:hover": {
      // backgroundColor: theme.palette.primary.main,
      // borderBottomRightRadius: 3,
      // borderTopRightRadius: 3,
    },
  },
}));

type PropTypes = {
  title: string;
  element: any;
  contentEditable?: boolean;
  children: any;
  editable?: boolean;
  handleDeleteClick?: () => void;
  customButtons?: any;
  readMode?: boolean;
  disableWrapper?: boolean;
};

export const Widget = React.memo(
  (props: PropTypes) => {
    const {
      title,
      element,
      children,
      editable = false,
      contentEditable = false,
      handleDeleteClick = null,
      readMode = false,
      disableWrapper = false,
      customButtons,
    } = props;
    const classes = useStyles(props);
    const editor = useSlateStatic();
    const selected = useSelected();

    React.useEffect(() => {
      emitEvent("edit-design", { element, selected, open: true });
    }, [selected]);

    if (readMode || disableWrapper) {
      return <div className={classes.readModeWrapper}>{children}</div>;
    }
    const deleteElement = () => {
      if (handleDeleteClick) {
        handleDeleteClick();
        return;
      }
      Transforms.removeNodes(editor, { at: ReactEditor.findPath(editor as ReactEditor, element) });
    };
    return (
      <div
        contentEditable={contentEditable}
        suppressContentEditableWarning={contentEditable}
        className={classes.voidWrapper}
        key={element.type}
      >
        {children}
        <div contentEditable={false} className={classes.actionsWrapper}>
          <div id={`top-actions-${element?.type}-${element.forcedLayout}`} className={classes.actionsTopWrapper}>
            <Typography className={classes.title} variant={"body1"}>
              {title}
            </Typography>
            {customButtons}
            {editable && (
              <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.tooltips.edit_design")}>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  className={classes.iconEditButton}
                  onClick={() => {
                    const elementPath = ReactEditor.findPath(editor as ReactEditor, element);
                    ReactEditor.focus(editor as ReactEditor);
                    Transforms.select(editor, Editor.start(editor, elementPath));
                    emitEvent("edit-design", { element, selected, open: true });
                  }}
                >
                  <EditOutlinedIcon className={classes.editDesign} />
                </IconButton>
              </Tooltip>
            )}
            {!element.forcedLayout && (
              <>
                <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.tooltips.add_p_above")}>
                  <div
                    className={classes.iconAddButton}
                    onClick={() => {
                      const elementPath = ReactEditor.findPath(editor as ReactEditor, element);
                      const previousPoint = Editor.before(editor, elementPath) || [0];
                      Transforms.insertNodes(editor, [{ type: "paragraph", children: [{ text: "" }] }], {
                        at: previousPoint.path ? [previousPoint.path[0] + 1] : [0],
                        select: true,
                      });
                    }}
                  >
                    <AddIcon className={classes.addPAbove} />
                  </div>
                </Tooltip>

                <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.tooltips.delete")}>
                  <IconButton className={classes.iconDeleteButton} disableRipple onClick={() => deleteElement()}>
                    <CloseIcon className={classes.deleteIcon} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
          {!element.forcedLayout && (
            <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.tooltips.add_p_below")}>
              <div
                className={classes.dragIcon}
                onClick={() => {
                  const elementPath = ReactEditor.findPath(editor as ReactEditor, element);
                  const nextPoint = Editor.after(editor, elementPath) || [elementPath[0] + 1];
                  Transforms.insertNodes(editor, [{ type: "paragraph", children: [{ text: "" }] }], {
                    at: nextPoint,
                    select: true,
                  });
                }}
              >
                <AddIcon className={classes.addPAbove} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps.children, nextProps.children)
);
