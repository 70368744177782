import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { I18n } from "react-redux-i18n";
import CheckIcon from "@material-ui/icons/Check";
import { ArrowForward } from "@material-ui/icons";
import { find } from "lodash";
import { Rating } from "@material-ui/lab";
import { IProduct } from "../../../../reducers/constants/objectTypes";
import RatingStarIcon from "../../../../icons/RatingStarIcon";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    display: "flex",
    flexDirection: "column",
    padding: 5,
  },
  productTitle: (props: PropTypes) => ({
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 2,
    textTransform: "capitalize",
    ...props.element.data.productTitleCss,
  }),
  productName: (props: PropTypes) => ({
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.45,
    ...props.element.data.productNameCss,
  }),
  ratingValue: {
    marginLeft: 4,
  },
  text: {
    marginLeft: 5,
  },
  logoAndRatingWrapper: {
    alignItems: "center",
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
  },
  ratingWrapper: {
    width: "100%",
  },
  ctaSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 30,
  },
  checklistSection: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
  },
  ctaRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    "&:first-child": {
      marginTop: 0,
    },
  },
  checkListItem: {
    fontWeight: theme.typography.fontWeightBold as any,
    marginLeft: 10,
  },
  productImage: {
    minHeight: 200,
    height: "auto",
    width: "100%",
    objectFit: "cover",
    borderRadius: 5,
    cursor: "pointer",
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(14),
  },
  productLogo: {
    height: 50,
    width: 50,
    objectFit: "cover",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: theme.palette.divider,
  },
  checkIcon: (props: PropTypes) => ({
    fill: "#35c376",
    ...props.element.data?.prosCss,
  }),
  horizontalDivider: {
    width: 60,
    height: 2,
    marginTop: 30,
    marginBottom: 30,
    backgroundColor: theme.palette.divider,
  },
  verticalDivider: {
    width: 1,
    height: "auto",
    marginLeft: 25,
    marginRight: 25,
    backgroundColor: theme.palette.divider,
    alignSelf: "stretch",
  },
  ctaButtonInput: {
    lineHeight: 1,
    textAlign: "start",
  },
  ctaButton: (props: PropTypes) => ({
    fontWeight: theme.typography.fontWeightRegular as any,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "10px 30px",
    fontSize: theme.typography.pxToRem(16),
    borderRadius: 30,
    textTransform: "capitalize",
    marginLeft: 10,
    height: "auto",
    minHeight: 40,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    ...props.element.data?.buttonCss,
  }),
  rating: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 5,
  },
  ratingStarIcon: {
    marginRight: 10,
  },
  iconFilled: {
    fill: "#FFBD06",
  },
  iconHover: {
    fill: "#FFBD06",
  },
  decimal: {
    padding: 2,
    fontSize: theme.typography.pxToRem(16),
  },
  ctaTitle: {
    width: "fit-content",
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  products: IProduct[];
};

const ProductDetailsWidgetReadOnly = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, products } = props;
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);
  return (
    <div {...attributes}>
      <div className={classes.wrap}>
        <div className={classes.horizontalDivider} />
        <Typography className={classes.productTitle}>
          {element.data.productTitle || product?.displayName || product?.name || ""}
        </Typography>
        <div className={classes.logoAndRatingWrapper}>
          <img
            className={classes.productLogo}
            alt={element.data.productLogo?.alt || product?.logoFile?.alt || "logo"}
            src={element.data.productLogo?.url || product?.logo || product?.logoFile?.url}
          />
          <div className={classes.verticalDivider} />
          <div className={classes.ratingWrapper}>
            <Typography className={classes.productName}>{element.data.productName || product?.name || ""}</Typography>
            <div className={classes.rating}>
              <Rating
                readOnly
                classes={{
                  decimal: classes.decimal,
                  iconFilled: classes.iconFilled,
                  iconHover: classes.iconHover,
                }}
                value={element.data.productRating || product?.rating || "0"}
                defaultValue={0}
                precision={0.1}
                icon={<RatingStarIcon />}
                emptyIcon={<RatingStarIcon empty />}
              />
              {/*<RatingStarIcon active className={classes.ratingStarIcon}/>*/}
              <Typography variant={"body1"} className={classes.ratingValue}>
                {element.data.productRating || product?.rating || ""}
              </Typography>
              <Typography variant={"body1"} className={classes.text}>
                {I18n.t("rich_text_editor.product_details.overall_rating")}
              </Typography>
            </div>
          </div>
        </div>
        <img
          className={classes.productImage}
          alt={element.data.productImage?.alt || product?.imageFiles?.[0]?.alt || "image"}
          src={element.data.productImage?.url || product?.imageFiles?.[0]?.url || product?.images?.[0]}
        />
        <div className={classes.ctaSection}>
          <div className={classes.ctaRow}>
            <Typography className={classes.ctaTitle}>{element.data.callToActionTitle || ""}</Typography>
            <Button
              href={element.data?.buttonUrl || element.data?.callToActionURL || product?.callToActionURL}
              endIcon={<ArrowForward />}
              className={classes.ctaButton}
            >
              <span className={classes.ctaButtonInput}>
                {element.data?.buttonText ||
                  element.data?.callToActionText ||
                  product?.callToAction ||
                  I18n.t("rich_text_editor.product_details.cta_button_text")}
              </span>
            </Button>
          </div>
        </div>
        {element.data.productBullets?.length > 0 && (
          <>
            <div className={classes.horizontalDivider} />
            <div className={classes.checklistSection}>
              {[...(element.data.productBullets || [])].map((bullet, index) => (
                <div key={`bullet_${index}`} className={classes.row}>
                  <CheckIcon className={classes.checkIcon} />
                  <Typography className={classes.checkListItem}>{bullet.name}</Typography>
                  <Typography>{" - "}</Typography>
                  <Typography>{bullet.text}</Typography>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {children}
    </div>
  );
};

export default ProductDetailsWidgetReadOnly;
