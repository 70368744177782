import React from "react";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import { ITag } from "../reducers/constants/objectTypes";
import XIcon from "../icons/XIcon";

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    minHeight: 25,
    wordBreak: "break-word",
    width: "fit-content",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "3px",
    padding: "4px 7px",
    marginRight: 5,
    display: "flex",
    alignItems: "center",
    transition: "all 1s cubic-bezier(.175,.885,.32,1)",
    webkitTransition: "all 1s cubic-bezier(.175,.885,.32,1)",
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: (props: PropTypes) => theme.typography.pxToRem(props.fontSize || 14),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  removeTagIcon: {
    color: theme.palette.text.hint,
    cursor: "pointer",
    height: 10,
    width: 10,
    marginLeft: 7,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  tag: string;
  tagCustomClass?: any;
  tagIconCustomClass?: any;
  onRemoveClicked?: Function;
  onClick?: Function;
  fontSize?: number;
};

const MTopic = (props: PropTypes) => {
  const classes = useStyles(props);
  const { tag, tagCustomClass = null, tagIconCustomClass = null, onRemoveClicked, onClick } = props;

  return (
    <div
      className={ClassNames(classes.tag, tagCustomClass)}
      key={tag}
      style={{ cursor: onClick ? "pointer" : "unset" }}
    >
      <Typography className={classes.text} variant={"body2"} onClick={(e) => onClick && onClick(e)}>
        {tag}
        {onRemoveClicked && (
          <XIcon
            className={ClassNames(classes.removeTagIcon, tagIconCustomClass)}
            onClick={(e) => onRemoveClicked(e)}
          />
        )}
      </Typography>
    </div>
  );
};

export default MTopic;
