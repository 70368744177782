import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function AlignRightIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 32 32"}>
      <path d="M1.778,32.000 L30.222,32.000 C31.200,32.000 32.000,31.200 32.000,30.222 C32.000,29.244 31.200,28.444 30.222,28.444 L1.778,28.444 C0.800,28.444 0.000,29.244 0.000,30.222 C0.000,31.200 0.800,32.000 1.778,32.000 ZM12.444,24.889 L30.222,24.889 C31.200,24.889 32.000,24.089 32.000,23.111 C32.000,22.133 31.200,21.333 30.222,21.333 L12.444,21.333 C11.467,21.333 10.667,22.133 10.667,23.111 C10.667,24.089 11.467,24.889 12.444,24.889 ZM1.778,17.778 L30.222,17.778 C31.200,17.778 32.000,16.978 32.000,16.000 C32.000,15.022 31.200,14.222 30.222,14.222 L1.778,14.222 C0.800,14.222 0.000,15.022 0.000,16.000 C0.000,16.978 0.800,17.778 1.778,17.778 ZM12.444,10.667 L30.222,10.667 C31.200,10.667 32.000,9.867 32.000,8.889 C32.000,7.911 31.200,7.111 30.222,7.111 L12.444,7.111 C11.467,7.111 10.667,7.911 10.667,8.889 C10.667,9.867 11.467,10.667 12.444,10.667 ZM0.000,1.778 C0.000,2.756 0.800,3.556 1.778,3.556 L30.222,3.556 C31.200,3.556 32.000,2.756 32.000,1.778 C32.000,0.800 31.200,-0.000 30.222,-0.000 L1.778,-0.000 C0.800,-0.000 0.000,0.800 0.000,1.778 Z" />
    </SvgIcon>
  );
}

export default AlignRightIcon;
