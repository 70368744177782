import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import _, { kebabCase } from "lodash";
import { Node } from "slate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightBold as any,
      lineHeight: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(15),
      "&:not(:first-child)": {
        marginTop: theme.typography.pxToRem(35),
      },
      // scrollMarginTop: "70px",
    },
  })
);

type PropTypes = {
  children: any;
  element: any;
};

const HeadingOneElement = React.memo(
  (props: PropTypes) => {
    const { children, element } = props;
    const classes = useStyles(props);
    return (
      <Typography
        id={element.id || kebabCase(Node.string(element))}
        className={classes.text}
        color={"inherit"}
        variant={"h2"}
      >
        {children}
      </Typography>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps?.element?.children?.length > 1) {
      return _.isEqual(prevProps.children, nextProps.children);
    }
    return _.isEqual(prevProps.element, nextProps.element);
  }
);

export default HeadingOneElement;
