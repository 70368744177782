import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 15,
    height: 17,
    fill: "none",
  },
}));

type PropTypes = {
  className?: string;
};

const PaddingAllSidesIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 15 16"}>
      <mask id="path-1-inside-1_3878_1183" fill="white">
        <path d="M2 0.899902C2 0.62376 2.22386 0.399902 2.5 0.399902H12.5C12.7761 0.399902 13 0.62376 13 0.899902C13 1.17604 12.7761 1.3999 12.5 1.3999H2.5C2.22386 1.3999 2 1.17604 2 0.899902Z" />
      </mask>
      <path
        d="M2 0.899902C2 0.0714752 2.67157 -0.600098 3.5 -0.600098H11.5C12.3284 -0.600098 13 0.0714752 13 0.899902C13 1.03251 12.9473 1.15969 12.8536 1.25346C12.7598 1.34722 12.6326 1.3999 12.5 1.3999H2.5H2H2.5C2.36739 1.3999 2.24021 1.34722 2.14645 1.25346C2.05268 1.15969 2 1.03251 2 0.899902L2 0.899902ZM13 1.3999H2H13ZM2 1.3999V0.399902V1.3999ZM13 0.399902V1.3999V0.399902Z"
        fill="#4353FF"
        mask="url(#path-1-inside-1_3878_1183)"
      />
      <mask id="path-3-inside-2_3878_1183" fill="white">
        <path d="M2 14.8999C2 14.6238 2.22386 14.3999 2.5 14.3999H12.5C12.7761 14.3999 13 14.6238 13 14.8999C13 15.176 12.7761 15.3999 12.5 15.3999H2.5C2.22386 15.3999 2 15.176 2 14.8999Z" />
      </mask>
      <path
        d="M2 14.8999C2 14.0715 2.67157 13.3999 3.5 13.3999H11.5C12.3284 13.3999 13 14.0715 13 14.8999C13 15.0325 12.9473 15.1597 12.8536 15.2535C12.7598 15.3472 12.6326 15.3999 12.5 15.3999H2.5H2H2.5C2.36739 15.3999 2.24021 15.3472 2.14645 15.2535C2.05268 15.1597 2 15.0325 2 14.8999L2 14.8999ZM13 15.3999H2H13ZM2 15.3999V14.3999V15.3999ZM13 14.3999V15.3999V14.3999Z"
        fill="#4353FF"
        mask="url(#path-3-inside-2_3878_1183)"
      />
      <mask id="path-5-inside-3_3878_1183" fill="white">
        <path d="M14.5 1.3999C14.7761 1.3999 15 1.62376 15 1.8999L15 13.8999C15 14.176 14.7761 14.3999 14.5 14.3999C14.2239 14.3999 14 14.176 14 13.8999L14 1.8999C14 1.62376 14.2239 1.3999 14.5 1.3999Z" />
      </mask>
      <path
        d="M14.5 1.3999C15.3284 1.3999 16 2.07148 16 2.8999L16 12.8999C16 13.7283 15.3284 14.3999 14.5 14.3999C14.3674 14.3999 14.2402 14.3472 14.1464 14.2535C14.0527 14.1597 14 14.0325 14 13.8999L14 1.8999L14 1.3999L14 1.8999C14 1.76729 14.0527 1.64012 14.1464 1.54635C14.2402 1.45258 14.3674 1.3999 14.5 1.3999L14.5 1.3999ZM14 14.3999L14 1.3999L14 14.3999ZM14 1.3999L15 1.3999L14 1.3999ZM15 14.3999L14 14.3999L15 14.3999Z"
        fill="#4353FF"
        mask="url(#path-5-inside-3_3878_1183)"
      />
      <mask id="path-7-inside-4_3878_1183" fill="white">
        <path d="M0.5 1.3999C0.776143 1.3999 1 1.62376 1 1.8999L0.999999 13.8999C0.999999 14.176 0.776142 14.3999 0.5 14.3999C0.223857 14.3999 3.75835e-08 14.176 4.96541e-08 13.8999L5.74191e-07 1.8999C5.86261e-07 1.62376 0.223858 1.3999 0.5 1.3999Z" />
      </mask>
      <path
        d="M0.5 1.3999C1.32843 1.3999 2 2.07148 2 2.8999L2 12.8999C2 13.7283 1.32843 14.3999 0.5 14.3999L0.5 14.3999C0.367392 14.3999 0.240215 14.3472 0.146447 14.2535C0.0526784 14.1597 4.38576e-08 14.0325 4.96541e-08 13.8999L5.74191e-07 1.8999L5.96046e-07 1.3999L5.74191e-07 1.8999C5.79987e-07 1.76729 0.052679 1.64012 0.146447 1.54635C0.240215 1.45258 0.367392 1.3999 0.5 1.3999L0.5 1.3999ZM2.77984e-08 14.3999L5.96046e-07 1.3999L2.77984e-08 14.3999ZM5.96046e-07 1.3999L1 1.3999L5.96046e-07 1.3999ZM0.999999 14.3999L2.77984e-08 14.3999L0.999999 14.3999Z"
        fill="#4353FF"
        mask="url(#path-7-inside-4_3878_1183)"
      />
    </SvgIcon>
  );
};

export default PaddingAllSidesIcon;
