import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { OutlinedInput, TextField, Tooltip } from "@material-ui/core";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
  },
  valueWrap: {
    display: "flex",
    alignItems: "center",
  },
  backToDefault: {
    color: theme.palette.text.hint,
    fontSize: 16,
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  input: {
    height: 37,
    width: 30,
    borderRadius: 4,
    fontSize: theme.typography.pxToRem(14),
    padding: "0 5px",
  },
  root: {
    borderRadius: 0,
    "&:first-child": {
      borderColor: theme.palette.divider,
      borderWidth: 1,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    "&:last-child": {
      borderColor: theme.palette.divider,
      borderWidth: 1,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  notchedOutlineLast: {
    borderColor: `${theme.palette.divider} !important`,
    borderWidth: `1px !important`,
  },
  notchedOutline: {
    borderColor: `${theme.palette.divider} !important`,
    borderWidth: `1px !important`,
    borderRight: "none",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  rowTitle: string;
  value: string;
  defaultValue?: string;
  isValueChanged?: boolean;

  handleChange: any;
};

// For Padding and margin
// 12px 10px  5px    5px
// top  right bottom left
const DimensionsInputRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { rowTitle, value = "", defaultValue = "", isValueChanged = false, handleChange } = props;

  const handleBackToDefault = () => {
    handleChange(defaultValue);
  };

  const values = value.toString().replaceAll("px", "").split(" ");

  return (
    <div className={classes.row}>
      <Typography variant={"body2"} className={ClassNames(classes.text, isValueChanged && classes.boldText)}>
        {rowTitle}
      </Typography>
      <div className={classes.valueWrap}>
        {Boolean(isValueChanged) && (
          <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
            <CloseIcon className={classes.backToDefault} onClick={handleBackToDefault} />
          </Tooltip>
        )}
        <OutlinedInput
          value={values?.[0] || ""}
          placeholder={""}
          type="number"
          classes={{
            root: classes.root,
            input: classes.input,
            notchedOutline: classes.notchedOutline,
          }}
          onChange={(event) => {
            // handleChange(parseFloat(event.target.value));
            handleChange(
              `${event.target.value || "0"}px ${values?.[1] || "0"}px ${values?.[2] || "0"}px ${values?.[3] || "0"}px`
            );
          }}
        />
        <OutlinedInput
          value={values?.[1] || ""}
          placeholder={""}
          type="number"
          classes={{
            root: classes.root,
            input: classes.input,
            notchedOutline: classes.notchedOutline,
          }}
          onChange={(event) => {
            // handleChange(parseFloat(event.target.value));

            handleChange(
              `${values?.[0] || "0"}px ${event.target.value || "0"}px ${values?.[2] || "0"}px ${values?.[3] || "0"}px`
            );
          }}
        />
        <OutlinedInput
          value={values?.[2] || ""}
          placeholder={""}
          type="number"
          classes={{
            root: classes.root,
            input: classes.input,
            notchedOutline: classes.notchedOutline,
          }}
          onChange={(event) => {
            // handleChange(parseFloat(event.target.value));
            handleChange(
              `${values?.[0] || "0"}px ${values?.[1] || "0"}px ${event.target.value || "0"}px ${values?.[3] || "0"}px`
            );
          }}
        />
        <OutlinedInput
          value={values?.[3] || ""}
          type="number"
          classes={{
            root: classes.root,
            input: classes.input,
            notchedOutline: classes.notchedOutlineLast,
          }}
          onChange={(event) => {
            handleChange(
              `${values?.[0] || "0"}px ${values?.[1] || "0"}px ${values?.[2] || "0"}px ${event.target.value || "0"}px`
            );
          }}
        />
      </div>
    </div>
  );
};
export default DimensionsInputRow;
