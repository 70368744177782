import * as React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import agent from "../../../../../../agent";
import { COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import { IQuestion, ITag, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import MPanelTags from "../../../../../../components/MPanelTags";

const useStyles = makeStyles((theme: Theme) => ({
  pageTopSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    marginBottom: 7,
  },
  select: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #E5E5E5",
    borderRadius: 4,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedQuestion: IQuestion;

  tags: ITag[];
  tagsSearchField: string;

  onTagsLoaded: Function;
  onTagSelected: Function;
  onTagRemoved: Function;
  onTagCreated: Function;
};

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,

    tags: state.communityEditor.tags,
    tagsSearchField: state.communityEditor.tagsSearchField,
    selectedQuestion: state.communityEditor.question,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onTagsLoaded: (payload) => dispatch({ type: COMMUNITY_EDITOR.ON_TAGS_LOADED, payload }),
  onTagSelected: (tag) => dispatch({ type: COMMUNITY_EDITOR.ON_TAG_SELECTED, tag }),
  onTagRemoved: (tag) => dispatch({ type: COMMUNITY_EDITOR.ON_TAG_REMOVED, tag }),
  onTagCreated: (tag) => dispatch({ type: COMMUNITY_EDITOR.ON_NEW_TAG_CREATED, tag }),
});

const CommunityEditorTags = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedQuestion, tags, selectedWebsite } = props;
  const { onTagsLoaded, onTagSelected, onTagRemoved, onTagCreated } = props;

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    onTagsLoaded(agent.Tags.all(selectedWebsite._id));
  }, [selectedWebsite]);

  return (
    <div className={classes.pageTopSection}>
      <Typography className={classes.title} variant={"body2"}>
        {I18n.t("edit_post.topics")}
      </Typography>
      <MPanelTags
        selectedWebsite={selectedWebsite}
        customClass={classes.select}
        height={37}
        placeholder={I18n.t("edit_post.select_topics_ph")}
        postTags={selectedQuestion?.tags || []}
        tags={tags}
        onTagSelected={onTagSelected}
        onTagRemoved={onTagRemoved}
        onTagCreated={onTagCreated}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorTags);
