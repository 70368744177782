import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import { useSnackbar } from "notistack";
import { IOutlineParagraphData, IOutline, IOutlineParagraphFAQData } from "../../../reducers/constants/objectTypes";
import EditorOutlineTargetGroups from "./EditorOutlineTargetGroups";
import EditorOutlineNotes from "./EditorOutlineNotes";
import EditorOutlineLinks from "./EditorOutlineLinks";
import EditorOutlineH3 from "./EditorOutlineH3";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionWrap: {
      marginTop: 30,
    },
    heading: {
      fontSize: theme.typography.pxToRem(12),
      flexBasis: "33.33%",
      flexShrink: 0,
      color: theme.palette.text.secondary,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold as any,
      marginTop: 5,
      color: theme.palette.text.primary,
    },
    faqQuestionsSecondaryHeading: {
      marginBottom: 10,
    },
    innerIdentSection: {
      marginLeft: 10,
      marginTop: 5,
      fontSize: theme.typography.pxToRem(14),
    },
    accordion: {
      marginTop: 3,
      border: "none",
      backgroundColor: theme.palette.secondary.main,
      "&:before": {
        display: "none",
      },
    },
    rounded: {
      borderRadius: 3,
      "&:first-child": {
        borderRadius: 3,
      },
      "&:last-child": {
        borderRadius: 3,
      },
    },
    expanded: {
      "&:first-child": {
        margin: 0,
      },
      "&:not(:first-child)": {
        margin: "3px 0 0",
      },
    },
    accordionSummary: {
      padding: 15,
      "& .MuiAccordionSummary-content": {
        flexDirection: "column",
        margin: 0,
      },
    },
    accordionDetails: {
      display: "flex",
      flexDirection: "column",
      padding: "0 15px",
      border: "none",
    },
    accordionDetailsInnerWrap: {
      padding: "15px 10px",
      borderTop: `solid 1px ${theme.palette.divider}`,
    },
  })
);

type PropTypes = {
  outline: IOutline;
};

const EditorOutline = (props: PropTypes) => {
  const classes = useStyles(props);
  const { outline } = props;
  const [expanded, setExpanded] = React.useState<string | false>("title");
  const { enqueueSnackbar } = useSnackbar();

  if (!outline) {
    return null;
  }

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.accordionWrap}>
      <Accordion
        classes={{ expanded: classes.expanded, rounded: classes.rounded }}
        className={classes.accordion}
        elevation={0}
        expanded={expanded === "title"}
        onChange={handleChange("title")}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="outline-title"
        >
          <Typography className={classes.heading}>{I18n.t("outline.post_title")}</Typography>
          <Typography className={classes.secondaryHeading}>{outline.postTitle.title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.accordionDetailsInnerWrap}>
            <EditorOutlineTargetGroups keywordsGroups={outline.postTitle.targetKeywordsGroups} />
            <EditorOutlineNotes notes={outline.postTitle.notes} />
          </div>
        </AccordionDetails>
      </Accordion>
      {outline.paragraphs.map((paragraph, index) => (
        <>
          {paragraph.type === "PARAGRAPH" && (
            <Accordion
              key={`paragraph_${index}`}
              classes={{ expanded: classes.expanded, rounded: classes.rounded }}
              className={classes.accordion}
              elevation={0}
              expanded={expanded === `paragraph_${index}`}
              onChange={handleChange(`paragraph_${index}`)}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                id={`paragraph_${index}`}
              >
                <Typography className={classes.heading}>{I18n.t("outline.text_section")}</Typography>
                <Typography className={classes.secondaryHeading}>{paragraph.title}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <div className={classes.accordionDetailsInnerWrap}>
                  <EditorOutlineH3 h3={(paragraph.data as IOutlineParagraphData)?.h3} />
                  <EditorOutlineTargetGroups
                    keywordsGroups={(paragraph.data as IOutlineParagraphData)?.targetKeywordsGroups}
                  />
                  <EditorOutlineLinks links={(paragraph.data as IOutlineParagraphData)?.links} />
                  <EditorOutlineNotes notes={(paragraph.data as IOutlineParagraphData).notes} />
                </div>
              </AccordionDetails>
            </Accordion>
          )}
          {paragraph.type === "FAQ" && (
            <Accordion
              key={`paragraph_${index}`}
              classes={{ expanded: classes.expanded, rounded: classes.rounded }}
              className={classes.accordion}
              elevation={0}
              expanded={expanded === `paragraph_${index}`}
              onChange={handleChange(`paragraph_${index}`)}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                id={`paragraph_${index}`}
              >
                <Typography className={classes.heading}>{I18n.t("outline.faq_section")}</Typography>
                <Typography className={classes.secondaryHeading}>{paragraph.title}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <div className={classes.accordionDetailsInnerWrap}>
                  {(paragraph.data as IOutlineParagraphFAQData)?.questions.map((questionSection) => (
                    <>
                      <Typography
                        className={ClassNames(classes.secondaryHeading, classes.faqQuestionsSecondaryHeading)}
                      >
                        {questionSection.question}
                      </Typography>
                      <div className={classes.innerIdentSection}>
                        <EditorOutlineH3 h3={(paragraph.data as IOutlineParagraphData)?.h3} />
                        <EditorOutlineTargetGroups keywordsGroups={questionSection?.targetKeywordsGroups} />
                        <EditorOutlineLinks links={questionSection.links} />
                        <EditorOutlineNotes notes={questionSection.notes} />
                      </div>
                    </>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </>
      ))}
    </div>
  );
};

export default EditorOutline;
