import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClassNames from "classnames";
import { IProduct, IWebsite } from "../../../reducers/constants/objectTypes";
import { find } from "lodash";
import ImagePlaceHolder from "../../../../icons/ImagePlaceHolder";
import { getImageCDNUrl } from "../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  featureComparisons: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid transparent",
    borderRadius: 3,
    padding: 5,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  tableTopRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 85,
    padding: "15px 20px",
    alignItems: "center",
  },
  tableTitle: (props: PropTypes) => ({
    fontSize: 21,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.3,
    marginBottom: 10,
    ...props.element.data?.tableTitleCss,
  }),
  productsWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    alignItems: "start",
  },
  productsWrapperHideLabel: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    alignItems: "center",
  },
  productWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 10,
    width: 70,
  },
  productLogoPh: {
    height: 70,
    width: 70,
    backgroundColor: "#808080",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  productLogo: {
    height: 70,
    width: 70,
    objectFit: "contain",
  },
  productTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: "16.8px",
    textAlign: "center",
  },
  productComparisonsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  comparisonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    padding: "15px 20px",
  },
  comparisonRowWrapper: (props: PropTypes) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: 85,
    ...props.element.data?.dividerCss,
  }),
  featureTitle: (props: PropTypes) => ({
    lineHeight: "19.2px",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 3,
    ...props.element.data?.itemTitleCss,
  }),
  productSelectionWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  productSelect: {
    width: 70,
  },
  productSelectCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  productSwitchWrapper: {
    width: 70,
    display: "flex",
    flexDirection: "column",
  },
  priceText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "14.4px",
    textAlign: "center",
    marginTop: 5,
  },
  closeIconWrapper: {
    width: 60,
    fontSize: "18px !important",
  },
  uncheckIcon: {
    fill: "#C4C4C4",
    fontSize: 21,
  },
  checkIcon: {
    fill: "#4353FF",
    fontSize: 21,
  },
  clearIcon: {
    fill: "#808080",
    marginLeft: 20,
    cursor: "pointer",
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  website: IWebsite;
  products: IProduct[];
};

const PricingTableWidgetReadOnly = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, products, website } = props;
  const websiteTheme = website?.configurations?.theme || {};

  const getProductByItemNumber = (itemNumber) => {
    return find(products, (p) => p.itemNumber === itemNumber);
  };
  return (
    <div {...attributes}>
      <div className={classes.featureComparisons}>
        <div
          className={classes.tableTopRow}
          style={{ backgroundColor: websiteTheme?.palette?.secondary?.main, ...element.data.headCss }}
        >
          <Typography className={classes.tableTitle}>{element.data.title || ""}</Typography>
          <div className={classes.productsWrapper}>
            {[...(element.data.products || [])].map((prod, index) => {
              const product = getProductByItemNumber(prod.itemNumber);
              return (
                <div className={classes.productWrapper} key={`${product?.name}_${index}`}>
                  {element.data.showLogo && (
                    <>
                      {product.logo || product!.logoFile?.url ? (
                        <img
                          className={classes.productLogo}
                          alt={product?.iconFile?.alt || product?.logoFile?.alt || `Product ${index}`}
                          src={getImageCDNUrl(product?.iconFile?.url || product?.logo || product?.logoFile?.url)}
                        />
                      ) : (
                        <div className={classes.productLogoPh}>
                          <ImagePlaceHolder />
                        </div>
                      )}
                    </>
                  )}
                  <Typography className={classes.productTitle}>{product.name}</Typography>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.productComparisonsWrapper}>
          {[...(element.data.comparisons || [])].map((comp, compIndex) => {
            return (
              <div
                key={`comparison_${compIndex}`}
                className={classes.comparisonRowWrapper}
                style={{
                  backgroundColor: compIndex % 2 === 0 ? "#ffffff" : websiteTheme?.palette?.secondary?.main,
                  ...(compIndex % 2 === 0 ? element.data.evenCss : element.data.oddCss),
                }}
              >
                <div className={ClassNames(classes.comparisonWrapper)}>
                  <Typography className={classes.featureTitle}>{comp.title}</Typography>
                  <div className={classes.productSelectionWrapper}>
                    {Array(element.data.products.length)
                      .fill()
                      .map((dummy, index) => {
                        const comparisonProduct = find(
                          comp.products,
                          (p) => p._id === element.data.products[index].itemNumber
                        );
                        console.log("comparisonProduct", comparisonProduct);
                        return (
                          <div
                            className={classes.productSwitchWrapper}
                            key={`${comparisonProduct?._id}_${index}_${compIndex}`}
                          >
                            {comparisonProduct?.selectType === "Text" && (
                              <Typography className={classes.priceText}>{comparisonProduct?.price || ""}</Typography>
                            )}
                            {(comparisonProduct?.selectType === "Checkbox" || !comparisonProduct?.selectType) && (
                              <div className={ClassNames(classes.productSelect, classes.productSelectCenter)}>
                                {comparisonProduct?.selected && <CheckCircleIcon className={classes.checkIcon} />}
                                {!comparisonProduct?.selected && (
                                  <RadioButtonUncheckedIcon className={classes.uncheckIcon} />
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {children}
    </div>
  );
};

export default PricingTableWidgetReadOnly;
