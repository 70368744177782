import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import TextareaAutosize from "react-textarea-autosize";

const useStyles = makeStyles((theme: Theme) => ({
  textInput: {
    fontFamily: theme.typography.fontFamily,
    resize: "none",
    border: "none",
    outline: "none",
    width: "100%",
    // height: "100%",
    margin: (props: PropTypes) => props.margin || 0,
    fontSize: (props: PropTypes) => props.fontSize || 16,
    padding: (props: PropTypes) => props.padding || 0,
    backgroundColor: "initial",
  },
}));

type PropTypes = {
  minRows?: number;
  maxRows?: number;
  text: string;
  style?: any;
  fontSize?: string | number;
  margin?: string | number;
  padding?: string | number;
  className?: string;
  placeholder?: string;
  onBlur?: () => void;
  onChange: (text: string) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
};

const TextAreaWidgetBaseElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    text,
    minRows = 1,
    maxRows = undefined,
    className = undefined,
    onChange,
    onKeyDown = null,
    onBlur = null,
    fontSize = 16,
    style,
    placeholder = I18n.t("rich_text_editor.insert_comments.comment_ph"),
  } = props;

  const [cursor, setCursor] = React.useState(null);
  const ref = React.useRef(null);
  const numbersRef = React.useRef(null);
  // https://stackoverflow.com/questions/46000544/react-controlled-input-cursor-jumps
  React.useEffect(() => {
    const input = ref.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
      /// unset height to allow minimum height to work
      /// https://github.com/Andarist/react-textarea-autosize/issues/332
      // input.style.setProperty("height", `unset`, "important");
    }
  }, [ref, cursor, text]);

  const handleChange = (e) => {
    console.log("handleChange", e.target.value);
    setCursor(e.target.selectionStart);
    onChange && onChange(e.target.value);
  };
  // return (
  //   <LiteRichTextEditor
  //     initialValue={[
  //       {
  //         type: "paragraph",
  //         children: [{ text }],
  //       },
  //     ]}
  //     onValueChanged={(value) => console.log(value)}
  //   />
  // );
  // MUI
  // return (
  //   <TextareaAutosize
  //     onBlur={onBlur}
  //     value={text}
  //     minRows={minRows}
  //     maxRows={maxRows}
  //     style={{ width: "100%", height: "fit-content" }}
  //     onChange={(e) => onChange(e.target.value)}
  //     onKeyDown={(e) => onKeyDown && onKeyDown(e)}
  //     placeholder={placeholder}
  //     className={ClassNames(classes.textInput, className)}
  //   />
  // );

  return (
    <TextareaAutosize
      ref={ref}
      onBlur={onBlur}
      value={text}
      style={style}
      minRows={minRows}
      maxRows={maxRows}
      onChange={(e) => handleChange(e)}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      placeholder={placeholder}
      className={ClassNames(classes.textInput, className)}
    />
  );
};

export default TextAreaWidgetBaseElement;
