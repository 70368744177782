import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import { FUNNELS } from "../../../../../../reducers/constants/actionTypes";
import FunnelBaseSelect from "../../FunnelBaseSelect";

const useStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    height: 17,
    alignItems: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
  },
}));

type Props = {
  title: string;
  checked: boolean;
  onChange: (value: boolean) => void;
};

const FunnelSwitch = (props: Props) => {
  const classes = useStyles();
  const { checked, title, onChange } = props;
  return (
    <div className={classes.titleWrapper}>
      <Typography className={classes.title}>{title}</Typography>
      <Switch
        checked={checked}
        onChange={() => {
          onChange(!checked);
        }}
        color="primary"
      />
    </div>
  );
};

export default FunnelSwitch;
