import * as React from "react";
import { useState } from "react";
import { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import { ReactEditor, useSelected, useSlate, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { IFile, IWebsite } from "../../../reducers/constants/objectTypes";
import { emitEvent } from "../../../hooks/useEvents";
import InsertImageDialog from "../../../containers/Modals/InsertImageDialog/InsertImageDialog";
import { Widget } from "../widgets/WithWidgets";
import { getImageCDNUrl } from "../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageAlignment: {
      display: "flex",
      justifyContent: (props: PropTypes) =>
        props.element?.alignment === "left"
          ? "flex-start"
          : props.element?.alignment === "right"
          ? "flex-end"
          : "center",
    },
    button: {
      color: "rgba(255, 255, 255, 0.54)",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
    activeIcon: {
      fill: theme.palette.primary.main,
    },
    image: {
      maxWidth: "100% !important",
      height: "auto",
    },
    focused: {
      boxShadow: "0 0 0 3px #B4D5FF",
    },
    imageDetailsWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    imageSizeWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    sizeField: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold as any,
    },
    imageWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    imageInnerWrapper: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      "&:hover": {
        "& > $uploadWrapper": {
          display: "flex",
        },
      },
    },
    uploadWrapper: {
      display: "none",
      flexDirection: "row",
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.40)",
      padding: "30px 0px",
      justifyContent: "center",
      alignItems: "center",
      gap: 8,
    },
    uploadText: {
      cursor: "pointer",
      color: "#fff",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    floatingBoard: {
      position: "absolute",
      right: 0,
      left: 0,
      background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%);",
    },
    wrapper: {
      display: "flex",
      flexDirection: "row",
    },
    replaceWrapper: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginBottom: 10,
    },
    caption: {
      fontSize: 14,
      textAlign: "center",
      marginTop: 10,
    },
  })
);

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  imageSizeLimit?: number;
  website: IWebsite;
};

const ImageElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, imageSizeLimit, website } = props;
  const editor = useSlateStatic();
  const selected = useSelected();
  const [insertImageDialogOpen, setInsertImageDialogOpen] = useState(false);

  // const onResize = (event, { element, size, handle }) => {
  //   // setWidth(size.width);
  //   // setHeight(size.height);
  //   resize(editor, size.height, size.width);
  // };

  // const resize = (editor, height, width) => {
  //   console.log("RESIZE");
  //   Transforms.setNodes(
  //     editor,
  //     {
  //       file: { ...element.file, height, width },
  //     },
  //     {
  //       match: (n) => {
  //         if (n.type === editorType.image) {
  //           const file = n.file as IFile;
  //           if (file._id === element.file._id) {
  //             return true;
  //           }
  //         }
  //         return false;
  //       },
  //     }
  //   );
  // };

  const handleChange = (file) => {
    console.log("handleChange", file, ReactEditor.findPath(editor as ReactEditor, element));
    emitEvent("edit-design-change", { element, newData: file });
    Transforms.setNodes(editor, { file }, { at: ReactEditor.findPath(editor as ReactEditor, element) });

    console.log("handleChange done");
  };

  return (
    <div {...attributes} contentEditable={false}>
      {children}
      <Widget title={I18n.t("rich_text_editor.custom_components.image")} element={element} editable>
        <div className={classes.imageAlignment}>
          <div className={classes.imageWrapper}>
            {/* In read only mode we take care on wrapping the image with href */}
            <div className={classes.imageInnerWrapper}>
              <img
                height={element.file.widthPercent ? "auto" : element.file.height || "auto"}
                width={element.file.widthPercent ? `${element.file.widthPercent}%` : element.file.width || "100%"}
                src={getImageCDNUrl(element.file.cdnUrl || element.file.url)}
                alt={element.file.alt}
                title={element.file.title}
                className={ClassNames(classes.image, selected && classes.focused)}
              />
              <div className={classes.uploadWrapper}>
                <Typography className={classes.uploadText} onClick={(e) => setInsertImageDialogOpen(true)}>
                  {I18n.t("rich_text_editor.cover_image.replace_image")}
                </Typography>
              </div>
            </div>
            {element.file?.showCaption && <span className={classes.caption}>{element.file?.caption}</span>}
          </div>
        </div>
        {insertImageDialogOpen && (
          <InsertImageDialog
            website={website}
            imageSizeLimit={imageSizeLimit}
            selectedImageFile={element.file}
            handleInsertClick={(file: IFile) => {
              handleChange(file);
              setInsertImageDialogOpen(false);
            }}
            handleCloseClick={() => {
              const path = ReactEditor.findPath(editor as ReactEditor, element);
              ReactEditor.focus(editor);
              Transforms.setSelection(editor, path);
              setInsertImageDialogOpen(false);
            }}
          />
        )}
      </Widget>
    </div>
  );
};

export default ImageElement;
