import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { useMemo, useState } from "react";
import { I18n } from "react-redux-i18n";
import { Divider } from "@material-ui/core";
import { useSnackbar } from "notistack";
import MTextField from "../../../../../components/MTextField";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import { CONVERSION_POTENTIAL_FUNNEL_STAGE } from "../../../../../helpers/constants";
import CfButton from "../../../../../components/CfButton";
import { HOME, WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import agent from "../../../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "20px 20px 20px 20px",
    background: theme.palette.background.default,
    height: "auto",
    width: "100%",
    padding: "40px 30px 40px 18px",
  },
  inputTitle: {
    fontSize: "14px",
    textTransform: "capitalize",
    color: theme.palette.text.primary,
  },
  dataWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
  },
  inputWrapper: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  dataHeading: {
    marginRight: "10px",
  },
  dataText: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: "17px",
  },
  crvWrapper: {
    display: "flex",
    gap: "20px",
    marginTop: "10px",
  },
  saveBtn: {
    marginTop: "20px",
    fontSize: "14px",
    height: 36,
  },
  helperText: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
  topRow: {
    display: "flex",
    gap: "20px",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  onWebsiteUpdated: (website) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onWebsiteUpdated: (website) => {
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_CONFIGURATIONS_LOADED, website });
    dispatch({ type: HOME.ON_WEBSITE_UPDATED, website });
  },
});
const TopicValue = (props: PropTypes) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { selectedWebsite, onWebsiteUpdated } = props;
  if (!selectedWebsite.gsc) {
    enqueueSnackbar(I18n.t("snackbar.update_error", { msg: I18n.t("strategy.settings.gsc_missing") }), {
      variant: "error",
    });

    return null;
  }

  const cvrPercentage = useMemo(
    () =>
      Object.entries(selectedWebsite.gsc.cvr).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value * 100,
        }),
        {}
      ),
    [selectedWebsite.gsc.cvr]
  );

  const [saleValue, setSaleValue] = useState(String(selectedWebsite?.gsc?.saleValue));

  const [serpCtr, setSerpCtr] = useState(String(selectedWebsite?.gsc?.serpCtr * 100));

  const [cvrValue, setCvrValue] = useState(cvrPercentage);

  const handleSave = () => {
    const cvr = Object.entries(cvrValue).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Number(value) / 100,
      }),
      {}
    );

    agent.Websites.updateGSC(selectedWebsite._id, {
      ...selectedWebsite.gsc,
      saleValue: Number(saleValue),
      serpCtr: Number(serpCtr) / 100,
      cvr,
    })
      .then((res) => {
        onWebsiteUpdated(res.website);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.dataWrapper}>
        <div className={classes.topRow}>
          <div className={classes.inputWrapper}>
            <Typography className={classes.inputTitle} variant={"subtitle1"}>
              {I18n.t("strategy.settings.sale_value")}
            </Typography>
            <MTextField
              type={"number"}
              outlined
              borderRadius={4}
              value={saleValue}
              onChange={(value) => {
                setSaleValue(value);
              }}
            />
          </div>
          <div className={classes.inputWrapper}>
            <Typography className={classes.inputTitle} variant={"subtitle1"}>
              {I18n.t("strategy.settings.serp_ctr")}
            </Typography>
            <MTextField
              type={"number"}
              outlined
              borderRadius={4}
              value={serpCtr}
              onChange={(value) => {
                setSerpCtr(value);
              }}
            />
            <Typography className={classes.helperText} variant={"subtitle1"}>
              {I18n.t("strategy.settings.serp_ctr_helper")}
            </Typography>
          </div>
        </div>

        <Divider />

        <div>
          <div className={classes.crvWrapper}>
            {Object.entries(CONVERSION_POTENTIAL_FUNNEL_STAGE).map(([key, label], index) => (
              <div className={classes.inputWrapper} key={index}>
                <Typography className={classes.inputTitle} variant={"subtitle1"}>
                  {label}
                </Typography>
                <MTextField
                  type={"number"}
                  outlined
                  value={String(cvrValue[key])}
                  borderRadius={4}
                  onChange={(value) => {
                    setCvrValue((oldValue) => ({
                      ...oldValue,
                      [key]: value,
                    }));
                  }}
                />
              </div>
            ))}
          </div>
          <Typography className={classes.helperText} variant={"subtitle1"}>
            {I18n.t("strategy.settings.cvr")}
          </Typography>
        </div>

        <CfButton text={I18n.t("strategy.settings.save")} onClick={handleSave} customClass={classes.saveBtn} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicValue);
