import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { I18n } from "react-redux-i18n";
import { Transforms } from "slate";
import { IProduct, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { connect } from "react-redux";
import { find } from "lodash";
import { Widget } from "../WithWidgets";
import TextAreaWidgetBaseElement from "../baseElements/TextAreaWidgetBaseElement";
import MPictureCarousel from "../../../../components/MPictureCarousel";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  innerWrapper: {
    width: "100%",
    maxWidth: (props) => props.element.data?.productWrapperWidth || 395,
  },
  productEmbed: (props: PropTypes) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: props.element.data?.backgroundColor || theme.palette.secondary.main,
    padding: 10,
  }),
  productTitle: (props: PropTypes) => ({
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightBold as any,
    whiteSpace: "normal",
    textAlign: "center",
    marginTop: 20,
    ...props.element.data?.titleCss,
  }),
  productPrice: (props: PropTypes) => ({
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.3,
    marginTop: 20,
    fontWeight: theme.typography.fontWeightLight as any,
    textTransform: "uppercase",
    whiteSpace: "normal",
    textAlign: "center",
    ...props.element.data?.priceCss,
  }),
  productButton: (props: PropTypes) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0 0 0",
    cursor: "pointer",
    height: 44,
    backgroundColor: "white",
    color: "black",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium as any,
    textTransform: "uppercase",
    padding: 0,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    ...props.element.data?.buttonCss,
  }),
  imageWrapper: {
    width: "100%",
    height: "auto",
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  websiteTheme: IWebsiteThemeProps;
  products: IProduct[];
};

export const getRawText = (n: Node) => {
  if (!n.data) {
    return "";
  }
  return [n.data.productTitle || "", n.data.productPrice || ""]?.join("\n") || "";
};

const mapStateToProps = (state) => ({
  websiteTheme: state.home.selectedWebsite.configurations.theme,
});

const ProductEmbedWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, websiteTheme, products } = props;
  const editor = useSlateStatic();
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  const handleChange = (data) => {
    console.log("handleChange", data, ReactEditor.findPath(editor as ReactEditor, element));
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const defaultData = {
    titleCss: {
      fontSize: websiteTheme.typography.subtitle1.css.fontSize,
    },
    priceCss: {
      fontSize: websiteTheme.typography.body2.css.fontSize,
    },
    buttonCss: {
      color: "#fff",
      backgroundColor: websiteTheme.palette.primary.main,
      textTransform: "capitalize",
      lineHeight: 1,
      ...websiteTheme.buttons.css,
      "&:hover": {
        backgroundColor: websiteTheme.palette.primary.light,
        color: "#fff",
        textTransform: "capitalize",
        ...websiteTheme.buttons.css?.["&hover"],
      },
    },
    productWrapperWidth: 395,
    backgroundColor: websiteTheme.palette.secondary.main,
    showPrice: true,
    productPrice: element.data.product?.price,
  };

  React.useEffect(() => {
    handleChange({ ...defaultData, ...element.data });
  }, []);

  return (
    <div {...attributes}>
      <Widget title={I18n.t("rich_text_editor.custom_components.product_embed")} element={element} editable>
        <div className={classes.wrapper}>
          <div className={classes.innerWrapper}>
            <div className={classes.productEmbed}>
              <div className={classes.imageWrapper}>
                <MPictureCarousel
                  editMode
                  pictures={product?.imageFiles.map((image) => ({
                    src: image.url,
                    alt: image.alt,
                    title: image.title,
                    caption: image.caption,
                    ctaUrl: product?.callToActionURL,
                  }))}
                  sponsored
                />
              </div>
              <TextAreaWidgetBaseElement
                className={classes.productTitle}
                text={element.data.productTitle || product?.name || ""}
                placeholder={I18n.t("rich_text_editor.product_embed.name_ph")}
                onChange={(productTitle) => handleChange({ ...element.data, productTitle })}
              />
              {element.data.showPrice && (
                <TextAreaWidgetBaseElement
                  className={classes.productPrice}
                  text={element.data.productPrice || product?.pricing?.price || product?.pricing?.startingPrice || ""}
                  placeholder={I18n.t("rich_text_editor.product_embed.price_ph")}
                  onChange={(productPrice) => handleChange({ ...element.data, productPrice })}
                />
              )}
            </div>
            <div className={classes.productButton}>
              <span>
                {element.data?.buttonText ||
                  element.data?.productButton ||
                  product?.callToAction ||
                  I18n.t("rich_text_editor.product_embed.shop_now")}
              </span>
            </div>
          </div>
        </div>
        {children}
      </Widget>
    </div>
  );
};

export default connect(mapStateToProps)(ProductEmbedWidget);
