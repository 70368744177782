import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: 70,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 10,
  },
  startDateSelect: {
    height: 30,
    marginRight: 20,
    borderRadius: 30,
    border: `${theme.palette.divider} solid 1px`,
    paddingLeft: 15,
  },
  toggleButtonGroup: {
    height: 30,
    marginRight: 20,
  },
  toggleButtonRoot: {
    borderRadius: 30,
    textTransform: "none",
  },
}));

type PropTypes = {
  interval: "day" | "week" | "month" | "quarter";
  handleIntervalChange: Function;

  startDate: string;
  handleStartDateChange: Function;

  total: boolean;
  handleTotalChange: Function;
};

const PositionsMetrics = (props: PropTypes) => {
  const classes = useStyles(props);
  const { interval, handleIntervalChange } = props;
  const { startDate, handleStartDateChange } = props;
  const { total, handleTotalChange } = props;

  return (
    <div className={classes.wrapper}>
      <div>
        <Select
          id="start-date-select"
          className={classes.startDateSelect}
          value={startDate}
          onChange={(e) => handleStartDateChange(e.target.value)}
          disableUnderline
          label="StartDate"
        >
          <MenuItem value={moment().subtract(7, "day").format("YYYY-MM-DD")}>Last 7 Days</MenuItem>
          <MenuItem value={moment().subtract(28, "day").format("YYYY-MM-DD")}>Last 28 Days</MenuItem>
          <MenuItem value={moment().subtract(3, "month").format("YYYY-MM-DD")}>Last 3 Months</MenuItem>
          <MenuItem value={moment().subtract(6, "month").format("YYYY-MM-DD")}>Last 6 Months</MenuItem>
          <MenuItem value={moment().subtract(12, "month").format("YYYY-MM-DD")}>Last 12 Months</MenuItem>
          <MenuItem value={moment().subtract(16, "month").format("YYYY-MM-DD")}>Last 16 Months</MenuItem>
        </Select>

        <ToggleButtonGroup
          size={"small"}
          value={interval}
          exclusive
          className={classes.toggleButtonGroup}
          onChange={(e, selectedInterval) => handleIntervalChange(selectedInterval)}
        >
          <ToggleButton
            value="day"
            classes={{
              root: classes.toggleButtonRoot,
            }}
          >
            {"Day"}
          </ToggleButton>
          <ToggleButton
            value="week"
            classes={{
              root: classes.toggleButtonRoot,
            }}
          >
            {"Week"}
          </ToggleButton>
          <ToggleButton
            value="month"
            classes={{
              root: classes.toggleButtonRoot,
            }}
          >
            {"Month"}
          </ToggleButton>
          <ToggleButton
            value="quarter"
            classes={{
              root: classes.toggleButtonRoot,
            }}
          >
            {"Quarter"}
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          size={"small"}
          value={total ? "total" : "unique"}
          exclusive
          className={classes.toggleButtonGroup}
          onChange={(e, selected) => handleTotalChange(selected === "total")}
        >
          <ToggleButton
            value="unique"
            classes={{
              root: classes.toggleButtonRoot,
            }}
          >
            {"Queries"}
          </ToggleButton>
          <ToggleButton
            value="total"
            classes={{
              root: classes.toggleButtonRoot,
            }}
          >
            {"Impressions"}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default PositionsMetrics;
