import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { Typography } from "@material-ui/core";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  title?: string;
  postsIds: string[];
  postsIdsNumber: number;

  handleChange: (postsIds) => void;
};

const PostsIds = (props: PropTypes) => {
  const classes = useStyles(props);
  const { title = "", postsIds = [], postsIdsNumber, handleChange } = props;

  return (
    <>
      <Typography variant={"body1"} className={classes.title}>
        {title}
      </Typography>
      {Array(postsIdsNumber)
        .fill(0)
        .map((x, index) => (
          <TextFieldMaxLength
            key={`id_${index}`}
            titleAndLengthLocation={"top"}
            value={postsIds?.[index]}
            placeholder={""}
            title={I18n.t("website.homepage.id", { num: index + 1 })}
            height={37}
            borderRadius={4}
            marginTop={20}
            handleValueChange={(id) => {
              /*const newPostsIds = [...postsIds];
						newPostsIds[index] = id;
						handleChange(newPostsIds);*/
              postsIds[index] = id;
              handleChange(postsIds.filter((e) => e !== "" && e !== undefined));
            }}
          />
        ))}
    </>
  );
};

export default PostsIds;
