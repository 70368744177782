import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import {
  IWidgetDialogButtonFieldProps,
  IWidgetFieldProperties,
} from "../../../../../../../reducers/constants/objectTypes";
import GenericClickActionWidgetItem from "./components/GenericClickActionWidgetItem";
import SwitchProperty from "./itemProperties/components/SwitchProperty";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

type PropTypes = {
  fieldType: string;
  properties: IWidgetFieldProperties;
  onChange: (properties: IWidgetDialogButtonFieldProps) => void;
};

const DialogButtonWidgetItem = (props: PropTypes) => {
  const properties = props.properties as IWidgetDialogButtonFieldProps;
  const { fullWidth = true } = properties;
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <GenericClickActionWidgetItem
        {...props}
        settingsLabel={I18n.t(`widgets_builder.widget_editor.fields.labels.button_link`)}
      />
      <SwitchProperty
        checked={fullWidth}
        onChange={(checked) => props.onChange({ ...properties, fullWidth: checked })}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.full_width`)}
      />
    </div>
  );
};

export default DialogButtonWidgetItem;
