import * as React from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { I18n } from "react-redux-i18n";

import { IVideoProject, eVideoProjectAction, eVideoProjectState } from "../../../reducers/constants/objectTypes";
import MPageActionsButtonGroup from "../../../components/MPageActionsButtonGroup";
import { useVideoEditorContext } from "../VideoEditorContext";
import agent from "../../../agent";
import { web } from "../../../helpers/urlHelper";
import ability from "../../../casl/ability";

const VideoEditorUpdateButton = () => {
  const { videoProject, selectedWebsite, selectedTimeline, editVideoProject, returnToPreviousScreen } =
    useVideoEditorContext();
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const updateVideoProject = async (action: eVideoProjectAction) => {
    try {
      setLoading(true);
      const data = await agent.VideoProjects.update(selectedWebsite, videoProject, action || eVideoProjectAction.save);
      const updatedVideoProject = data.videoProject as IVideoProject;
      if (!updatedVideoProject) {
        returnToPreviousScreen();
        return;
      }

      if (![eVideoProjectAction.save, eVideoProjectAction.export].includes(action)) {
        const canView = ability.can("view", `video_workflow_${updatedVideoProject.currentState.state}_tab`);
        if (canView) {
          history.push(web.videoWorkflow(updatedVideoProject.currentState.state));
          return;
        }
        returnToPreviousScreen();
        return;
      }

      editVideoProject(updatedVideoProject);

      if (action === eVideoProjectAction.export) {
        const url = data.exportedVideoUrl;

        // start file download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "video.mp4");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      enqueueSnackbar(error.response?.body?.message, {
        variant: "error",
      });
      console.log("error", error);
    }
    setLoading(false);
  };

  const actions = React.useMemo(() => {
    const actionsArr = [];

    if (ability.can("customer", "role")) {
      if (videoProject.currentState.state === eVideoProjectState.review) {
        actionsArr.push(
          { action: eVideoProjectAction.done, label: I18n.t(`video_workflow.actions.approve`) },
          { action: eVideoProjectAction.secondCheck, label: I18n.t(`video_workflow.actions.return_to_editor`) }
        );
      }
    } else {
      actionsArr.push({ action: eVideoProjectAction.save, label: I18n.t(`video_workflow.actions.save`) });

      if (videoProject.currentState.state === eVideoProjectState.deleted) {
        actionsArr.push({
          action: eVideoProjectAction.deleteForever,
          label: I18n.t(`video_workflow.actions.delete_permanently`),
        });
      } else {
        actionsArr.push({ action: eVideoProjectAction.delete, label: I18n.t(`video_workflow.actions.delete`) });
      }
    }

    const hasVideos = selectedTimeline.rows.reduce(
      (acc, row) => row.actions.reduce((acc2, action) => Math.max(acc2, action.end), acc),
      0
    );

    if (hasVideos) {
      actionsArr.push({ action: eVideoProjectAction.export, label: I18n.t(`video_workflow.actions.export`) });
    }

    return actionsArr;
  }, [selectedTimeline.rows, videoProject.currentState.state]);

  const sendToActions = React.useMemo(() => {
    if (ability.can("customer", "role")) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const actions = [
      { action: eVideoProjectAction.brief, label: I18n.t(`video_workflow.tabs.Brief`) },
      { action: eVideoProjectAction.trim, label: I18n.t(`video_workflow.tabs.Trim`) },
      { action: eVideoProjectAction.edit, label: I18n.t(`video_workflow.tabs.Edit`) },
      { action: eVideoProjectAction.firstCheck, label: I18n.t(`video_workflow.tabs.FirstCheck`) },
      { action: eVideoProjectAction.secondCheck, label: I18n.t(`video_workflow.tabs.SecondCheck`) },
    ];

    if (ability.can("review", "video_project")) {
      actions.push(
        { action: eVideoProjectAction.review, label: I18n.t(`video_workflow.tabs.Review`) },
        { action: eVideoProjectAction.done, label: I18n.t(`video_workflow.tabs.Done`) }
      );
    }

    return actions.filter(
      ({ action }) => (action as unknown as eVideoProjectState) !== videoProject.currentState.state
    );
  }, [videoProject]);

  return (
    <MPageActionsButtonGroup
      loading={loading}
      mainAction={actions[0]}
      handleActionOnClick={(option) => {
        updateVideoProject(option.action);
      }}
      actions={actions}
      sendToActions={sendToActions}
    />
  );
};

export default VideoEditorUpdateButton;
