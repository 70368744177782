import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { IAd, IAdPlacement } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: 50,
    display: "flex",
    flexDirection: "row",
  },
  leftSide: {
    width: "100%",
    marginRight: 20,
    backgroundColor: "white",
    borderRadius: 5,
    border: `1px solid ${theme.palette.divider}`,
  },
  adWrapper: {
    width: 300,
    height: 600,
  },
  adImage: {
    backgroundSize: "contain",
  },
}));

type PropTypes = {
  ad: IAd;
  placement: IAdPlacement;
};

const AdTallSidePreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { ad, placement } = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftSide} />
      <div className={classes.adWrapper}>
        {placement.imageFile && (
          <img
            width={Math.min(300, placement.imageFile.width || 300)}
            height={Math.min(600, placement.imageFile.height || 600)}
            src={placement.imageFile?.cdnUrl || placement.imageFile.url}
            className={classes.adImage}
            alt={placement.imageFile.alt}
          />
        )}
      </div>
    </div>
  );
};

export default AdTallSidePreview;
