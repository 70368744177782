import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import moment from "moment";
import ClassNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { find } from "lodash";
import { IPage, IWebsite } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusWrapper: {
      display: "flex",
      flexDirection: "column",
      whiteSpace: "pre",
      padding: "10px 15px",
    },
    statusRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 15,
      alignItems: "center",
      gap: 5,
    },
    status: {
      display: "flex",
    },
    green: {
      color: "green",
    },
    red: {
      color: "red",
    },
    flexOneTitle: {
      width: "33%",
      fontSize: theme.typography.pxToRem(12),
    },
    flexOne: {
      width: "33%",
      fontSize: theme.typography.pxToRem(12),
    },
    name: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    showWrapper: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold as any,
      display: "flex",
      cursor: "pointer",
    },
    showMore: {
      color: "inherit",
    },
    showMoreIcon: {
      fontSize: theme.typography.pxToRem(18),
    },
  })
);

type PropTypes = {
  website: IWebsite;
  page: IPage;
};

const PageEditorCustomReviewStatus = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website, page } = props;
  const [showMore, setShowMore] = React.useState(true);

  if (website.reviewProcess !== "Custom") {
    return null;
  }

  return (
    <div className={classes.statusWrapper}>
      <div className={classes.statusRow}>
        <Typography className={ClassNames(classes.flexOneTitle, classes.name)} variant={"subtitle2"}>
          {I18n.t("custom_post.review")}
        </Typography>
        <Typography className={classes.flexOneTitle} variant={"subtitle2"}>
          {I18n.t("custom_post.date")}
        </Typography>
        <Typography className={classes.flexOneTitle} variant={"subtitle2"}>
          {I18n.t("custom_post.status")}
        </Typography>
      </div>

      {website.reviews.map((regulation, index) => {
        const pageRegulation = find(page.reviews, (pr) => pr.subState === regulation.reviewStepName) || null;
        return (
          <div key={`upload_${index}`} className={classes.statusRow}>
            <Typography className={ClassNames(classes.flexOne, classes.name)} variant={"body1"} color={"textPrimary"}>
              {regulation.reviewName}
            </Typography>
            <Typography className={classes.flexOne} variant={"body1"} color={"textPrimary"}>
              {pageRegulation?.reviewDate ? moment(pageRegulation.reviewDate).format("MMM D, YY") : "-"}
            </Typography>
            {pageRegulation?.reviewDate ? (
              <Typography
                variant={"body1"}
                color={"textPrimary"}
                className={ClassNames(
                  classes.flexOne,
                  classes.status,
                  pageRegulation?.pass ? classes.green : classes.red
                )}
              >
                {pageRegulation?.pass}
                {!pageRegulation && "-"}
                {pageRegulation &&
                  (pageRegulation.pass ? (
                    <CheckCircleIcon fontSize={"small"} />
                  ) : (
                    <CancelRoundedIcon fontSize={"small"} />
                  ))}
              </Typography>
            ) : (
              <Typography className={classes.flexOne} variant={"body1"} color={"textPrimary"}>
                {"-"}
              </Typography>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PageEditorCustomReviewStatus;
