import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles";
import { Paper, Popover } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state/index";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { Variant } from "@material-ui/core/styles/createTypography";
import edit from "../../../img/edit.png";
import SelectRow from "./SelectRow";
import ColorPickerRow from "./ColorPickerRow";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import InputRow from "./InputRow";
import DimensionsInputRow from "./DimensionsInputRow";
import {
  fontOptions,
  fontStyleOptions,
  letterCaseOptions,
  textDecorationOptions,
  weightOptions,
} from "../WebsiteEditor/consts/CssConsts";

const useStyles = makeStyles((theme: Theme) => ({
  designTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 20,
    width: "100%",
  },
  editImage: {
    marginLeft: 15,
    cursor: "pointer",
  },
  designPropsRow: {
    display: "flex",
    padding: "0px 30px",
    alignItems: "center",
    minHeight: 59,
    "&:hover": {
      "& $editWrapper": {
        display: "flex",
      },
      backgroundColor: theme.palette.secondary.main,
    },
  },
  designProps: {
    display: "flex",
    color: theme.palette.text.primary,
  },
  popoverPaper: {
    borderRadius: 0,
    boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.15)",
    border: "none",
    marginLeft: 10,
  },
  wrapper: {
    height: "100%",
    width: 416,
    border: "none",
    borderRadius: 0,
  },
  innerWrapper: {
    padding: "15px 20px",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 15,
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  colorSquare: {
    width: 16,
    height: 16,
    backgroundColor: "black",
    marginLeft: 7,
  },
  designWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    cursor: "pointer",
    justifyContent: "space-between",
  },
  editWrapper: {
    display: "none",
    gap: 10,
    alignItems: "center",
  },
  designText: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
}));

type PropTypes = {
  theme: IWebsiteThemeProps;
  elementTitle?: string;
  css: any;
  defaultCss?: any;
  exampleText?: string;

  handleCssChanged: (css) => void;
};

const mapStateToProps = (state) => ({
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({});

const CodeDesignToolWithPreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { defaultCss = null, elementTitle = "", exampleText = null, css = {}, handleCssChanged } = props;

  const finalDefaultCss = defaultCss; /* || theme.typography[variant].css */

  const onCssChanged = (cssName, cssValue) => {
    if (cssValue === null || cssValue === "" || cssValue === finalDefaultCss[cssName]) {
      delete css[cssName];
      handleCssChanged(css);
      return;
    }
    handleCssChanged({ ...css, [cssName]: cssValue });
  };

  return (
    <div className={classes.designPropsRow}>
      <PopupState variant="popover" popupId="popover">
        {(popupState) => (
          <div style={{ display: "contents" }}>
            <div className={classes.designWrapper} {...bindTrigger(popupState)}>
              <div>
                {/* <Typography variant={'body2'} className={classes.designTitle}>{elementTitle}</Typography> */}
                <code style={{ ...finalDefaultCss, ...css, margin: 0, padding: 0 }}>
                  {exampleText || I18n.t("website.code_example")}
                </code>
              </div>
              <div className={classes.editWrapper}>
                <img width={12} height={12} src={edit} alt={"edit_image"} className={classes.editImage} />
                <Typography variant={"body2"} className={classes.designText}>
                  {I18n.t("website.edit")}
                </Typography>
              </div>
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              elevation={0}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <Paper className={classes.wrapper}>
                <div className={classes.titleWrapper}>
                  <Typography color={"textPrimary"} variant={"subtitle1"}>
                    {`${elementTitle} Typography`}
                  </Typography>
                  <CloseIcon className={classes.closeIcon} onClick={popupState.close} />
                </div>
                <div className={classes.innerWrapper}>
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.size")}
                    value={css?.fontSize || finalDefaultCss?.fontSize}
                    defaultValue={finalDefaultCss?.fontSize}
                    isValueChanged={css?.fontSize && css?.fontSize !== finalDefaultCss?.fontSize}
                    handleChange={(size) => {
                      onCssChanged("fontSize", size);
                    }}
                  />
                  <ColorPickerRow
                    rowTitle={I18n.t("website.design_tool.text_color")}
                    value={css?.color || finalDefaultCss?.color}
                    defaultValue={finalDefaultCss?.color}
                    isValueChanged={css?.color && css?.color !== finalDefaultCss?.color}
                    handleChange={(color) => {
                      onCssChanged("color", color);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.line_height")}
                    value={css?.lineHeight || finalDefaultCss?.lineHeight}
                    stepSize={"0.1"}
                    defaultValue={finalDefaultCss?.lineHeight}
                    isValueChanged={css?.lineHeight && css?.lineHeight !== finalDefaultCss?.lineHeight}
                    handleChange={(lineHeight) => {
                      onCssChanged("lineHeight", lineHeight);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.letter_spacing")}
                    value={css?.letterSpacing || finalDefaultCss?.letterSpacing}
                    defaultValue={finalDefaultCss?.letterSpacing}
                    isValueChanged={css?.letterSpacing && css?.letterSpacing !== finalDefaultCss?.letterSpacing}
                    handleChange={(letterSpacing) => {
                      onCssChanged("letterSpacing", letterSpacing);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.padding")}
                    value={css?.padding || finalDefaultCss?.padding}
                    defaultValue={finalDefaultCss?.padding}
                    isValueChanged={css?.padding && css?.padding !== finalDefaultCss?.padding}
                    handleChange={(padding) => {
                      onCssChanged("padding", padding);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.margin")}
                    value={css?.margin}
                    defaultValue={finalDefaultCss?.margin}
                    isValueChanged={css?.margin && css?.margin !== finalDefaultCss?.margin}
                    handleChange={(margin) => {
                      onCssChanged("margin", margin);
                    }}
                  />
                </div>
              </Paper>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeDesignToolWithPreview);
