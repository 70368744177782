import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  className?: string;
};

function CommentIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 18 18"}>
      <path d="M7.69826 10.2275H9.30127V7.71978H11.8148V6.10105H9.30127V3.59334H7.69826V6.10105H5.18521V7.71978H7.69826V10.2275ZM0 18V2.1125C0 1.53344 0.204552 1.03651 0.613657 0.621714C1.02244 0.207238 1.5172 0 2.09793 0H14.8821C15.4628 0 15.9609 0.207238 16.3764 0.621714C16.7921 1.03651 17 1.53344 17 2.1125V11.7282C17 12.294 16.7921 12.7843 16.3764 13.1991C15.9609 13.6136 15.4628 13.8208 14.8821 13.8208H4.18608L0 18ZM2.09793 12.4191L3.77048 11.7282H14.8821V2.1125H2.09793V12.4191ZM2.09793 2.1125V11.7282V12.4191V2.1125Z" />
    </SvgIcon>
  );
}

export default CommentIcon;
