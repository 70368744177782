import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { I18n } from "react-redux-i18n";
import { Button, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import { connect } from "react-redux";
import SelectProperty from "../../build/items/itemProperties/components/SelectProperty";
import ListBaseComponent from "../../../../../../../editor/elementsStyles/BaseComponents/ListBaseComponent";
import LayoutPaneSelectedIcon from "../../../../../../../icons/LayoutPaneSelectedIcon";
import LayoutPaneUnSelectedIcon from "../../../../../../../icons/LayoutPaneUnSelectedIcon";
import LayoutEmbeddedSelectedIcon from "../../../../../../../icons/LayoutEmbeddedSelectedIcon";
import LayoutEmbeddedUnSelectedIcon from "../../../../../../../icons/LayoutEmbeddedUnSelectedIcon";
import DesignLayoutModel from "../models/DesignLayoutModel";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import { eFormDesignLayout } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    padding: "30px",
  },
  wrapper: {
    backgroundColor: palette.secondary.main,
    borderRadius: "5px",
    padding: "25px 15px 15px 15px",
  },
  headerItems: {
    fontSize: "16px",
    verticalAlign: "middle",
    display: "flex",
    padding: "0px 0px 10px 0px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowBack: {
    display: "flex",
    marginLeft: "-8px",
    cursor: "pointer",
  },
  backIcon: {
    height: "22px",
    color: palette.common.white,
  },
  button: {
    background: palette.primary.light,
    justifySelf: "flex-end",
    height: "27px",
    width: "54px",
    fontSize: "14px",
    fontWeight: typography.fontWeightLight,
    textTransform: "none",
  },
  head: {
    userSelect: "none",
    color: palette.common.white,
    fontSize: "16px",
    lineHeight: "21px",
  },
  body: {
    width: "100%",
    borderRadius: "5px",
    marginTop: "10px",
  },
  selectLayoutItem: {
    borderRadius: "8px",
    padding: 15,
    width: 150,
    textAlign: "center",
    cursor: "pointer",
    border: "1px solid #AEAEAE",
  },
  selectItems: {
    display: "flex",
    justifyContent: "space-between",
  },
  selectedItem: {
    border: "1px solid #609EFF",
  },
  selectItemText: {
    color: "#AEAEAE",
    marginTop: 10,
  },
  selectedItemText: {
    color: "#609EFF",
  },
  marginTop: {
    marginTop: 10,
  },
}));

type PropTypes = {
  goBack: (any) => void;
  designLayout: DesignLayoutModel;
  editLayout: (layout: DesignLayoutModel) => void;
};

const mapStateToProps = (state) => ({
  designLayout: state.widgetBuilder.widget.layout,
});

const mapDispatchToProps = (dispatch) => ({
  editLayout: (layout) => dispatch({ type: WIDGET_BUILDER.DESIGN, key: "layout", value: layout }),
});

const LayoutDesign = (props: PropTypes) => {
  const classes = useStyles(props);
  const { goBack, designLayout, editLayout } = props;
  const [layout, setLayout] = React.useState(designLayout?.layout || eFormDesignLayout.Embedded);
  const [panePosition, setPanePosition] = React.useState(designLayout?.panePosition || "Left");
  const [formWidth, setformWidth] = React.useState(designLayout?.formWidth || "10px");

  const widthArr = ["10px", "20px", "30px", "40px", "50px", "60px", "70px", "80px", "90px", "100px"];

  function handleClick() {
    const body = {
      layout,
      panePosition,
      formWidth,
    };

    editLayout(body);
    goBack(null);
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.headerItems}>
          <div className={classes.arrowBack} onClick={() => goBack(null)}>
            <KeyboardArrowLeftRounded className={classes.backIcon} />
            <Typography variant={"h6"} className={classes.head}>
              {I18n.t("widgets_builder.design.layout")}
            </Typography>
          </div>
          <Button className={classes.button} onClick={() => handleClick()}>
            {I18n.t("widgets_builder.design.done")}
          </Button>
        </div>
        <div className={classes.body}>
          <div className={classes.selectItems}>
            <div
              className={classNames(
                classes.selectLayoutItem,
                layout === eFormDesignLayout.Embedded ? classes.selectedItem : ""
              )}
              onClick={() => setLayout(eFormDesignLayout.Embedded)}
            >
              {layout === eFormDesignLayout.Embedded ? (
                <LayoutEmbeddedSelectedIcon />
              ) : (
                <LayoutEmbeddedUnSelectedIcon />
              )}

              <Typography
                className={classNames(
                  classes.selectItemText,
                  layout === eFormDesignLayout.Embedded ? classes.selectedItemText : ""
                )}
              >
                {I18n.t("widgets_builder.design.embedded")}
              </Typography>
            </div>

            <div
              className={classNames(
                classes.selectLayoutItem,
                layout === eFormDesignLayout.Pane ? classes.selectedItem : ""
              )}
              onClick={() => setLayout(eFormDesignLayout.Pane)}
            >
              {layout === eFormDesignLayout.Pane ? <LayoutPaneSelectedIcon /> : <LayoutPaneUnSelectedIcon />}

              <Typography
                className={classNames(
                  classes.selectItemText,
                  layout === eFormDesignLayout.Pane ? classes.selectedItemText : ""
                )}
              >
                {I18n.t("widgets_builder.design.pane")}
              </Typography>
            </div>
          </div>

          <div style={{ marginTop: 15 }}>
            {layout === eFormDesignLayout.Pane && (
              <SelectProperty
                label={I18n.t("widgets_builder.design.pane_position")}
                showLabel
                value={panePosition}
                options={["Left", "Right"]}
                onChange={(option) => {
                  setPanePosition(option);
                }}
              />
            )}

            <div className={classes.marginTop}>
              <ListBaseComponent
                title={I18n.t("widgets_builder.design.form_width")}
                value={formWidth}
                selectOptions={widthArr}
                isValueChanged={false}
                borderColor="#525252"
                handleChange={(width) => {
                  setformWidth(width);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutDesign);
