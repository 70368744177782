import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import FunnelSideBarTextInput from "./inputs/FunnelSideBarTextInput";
import { FUNNELS } from "../../../../../../reducers/constants/actionTypes";
import { FUNNEL_BREAK_DOWN_OPTIONS } from "../../../../../../helpers/constants";
import FunnelBaseSelect from "../../FunnelBaseSelect";

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  sideBarTitle: {
    fontSize: 18,
    top: 0,
    position: "sticky",
    fontWeight: theme.typography.fontWeightBold as any,
    color: "#141414",
    backgroundColor: theme.palette.common.white,
    zIndex: 10,
    paddingTop: 20,
  },
}));

const mapStateToProps = (state) => ({
  config: state.funnels.config,
  funnelName: state.funnels.funnelName,
  funnelId: state.funnels.funnelId,
});

const mapDispatchToProps = (dispatch) => ({
  updateConfig(payload) {
    dispatch({ type: FUNNELS.FUNNEL_CONFIG_UPDATED, payload });
  },
  updateFunnelName(payload) {
    dispatch({ type: FUNNELS.FUNNEL_FUNNEL_NAME_CHANGED, payload });
  },
});

type PropTypes = {
  config: any;
  updateConfig: (payload) => void;
  updateFunnelName: (payload) => void;
  funnelName: string;
  funnelId: string;
};

const FunnelSidebarHeader = (props: PropTypes) => {
  const classes = useStyles();

  const { config, updateConfig, updateFunnelName, funnelName, funnelId } = props;

  const updateName = debounce((text) => {
    updateFunnelName(text);
  }, 1500);
  return (
    <div className={classes.headerWrapper}>
      <Typography className={classes.sideBarTitle}>
        {!funnelId ? I18n.t("analytics.funnels.sidebar.title") : I18n.t("analytics.funnels.sidebar.update_funnel")}
      </Typography>

      <FunnelSideBarTextInput
        defaultValue={funnelName}
        onChange={(text) => {
          updateName(text);
        }}
        textFieldId={"funnel-name"}
        label={I18n.t("analytics.funnels.sidebar.labels.name")}
      />
      <FunnelBaseSelect
        placeholder={"Select..."}
        label={I18n.t("analytics.funnels.sidebar.labels.breakdown")}
        fieldId={"funnel-breakdown"}
        optionLabel={"label"}
        optionValue={"value"}
        onClear={() => {
          updateConfig({
            ...config,
            breakdown: undefined,
          });
        }}
        value={FUNNEL_BREAK_DOWN_OPTIONS.find((c) => isEqual(c.value, config.breakdown))}
        options={FUNNEL_BREAK_DOWN_OPTIONS}
        handleOnChange={(t) => {
          updateConfig({
            ...config,
            breakdown: t ? t.value : undefined,
          });
        }}
      />
      <Divider />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FunnelSidebarHeader);
