import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import {
  IWebsiteComparisonTopAlternativeProps,
  IWebsiteSectionProps,
} from "../../../../reducers/constants/objectTypes";
import PageSection from "../../components/PageSection";
import WebsitesSectionsSwitch from "../../components/WebsitesSectionsSwitch";
import WebsiteProductPagesMainPage from "../../components/WebsiteProductPagesMainPage";
import ProductPagesPostSection from "../../components/ProductPagesPostSection";
import MRouterPrompt from "../../components/MRouterPrompt";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import CustomUrlPrefixTextField from "../../components/CustomUrlPrefixTextField";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
}));

const mapStateToProps = (state) => ({
  comparisonTopAlternative: state.websitePages.comparisonTopAlternative,
  sections: state.websitePages.sections,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (comparisonTopAlternative) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "comparisonTopAlternative",
      value: comparisonTopAlternative,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  comparisonTopAlternative: IWebsiteComparisonTopAlternativeProps;
  sections: IWebsiteSectionProps;
  onPropertyChanged: (comparisonTopAlternative) => void;
  handleToggleActive: (section, enabled) => void;
};

const ComparisonTopAlternative = (props: PropTypes) => {
  const classes = useStyles(props);
  const { comparisonTopAlternative, onPropertyChanged, sections, handleToggleActive } = props;
  const [error, setError] = React.useState(false);

  const [emptyFields, setEmptyFields] = React.useState([]);

  React.useEffect(() => {
    if (emptyFields.length <= 0 && !error) {
      return;
    }
    textFieldsValidated();
  }, [comparisonTopAlternative]);

  const textFieldsValidated = () => {
    let valid = true;
    setEmptyFields([]);
    const emptyFields = [];
    if (!comparisonTopAlternative.name) {
      valid = false;
      emptyFields.push("name");
    }
    if (!comparisonTopAlternative.mainPage.slug) {
      valid = false;
      emptyFields.push("mainPage.slug");
    }
    if (!comparisonTopAlternative.mainPage.metaTitle) {
      valid = false;
      emptyFields.push("mainPage.metaTitle");
    }
    if (!comparisonTopAlternative.mainPage.metaDescription) {
      valid = false;
      emptyFields.push("mainPage.metaDescription");
    }
    if (!comparisonTopAlternative.mainPage.title.text) {
      valid = false;
      emptyFields.push("mainPage.title");
    }
    if (!comparisonTopAlternative.mainPage.description.text) {
      valid = false;
      emptyFields.push("mainPage.description");
    }
    if (!comparisonTopAlternative.mainPage.listTitle.text) {
      valid = false;
      emptyFields.push("mainPage.listTitle");
    }
    if (!comparisonTopAlternative.postPage.metaTitle) {
      valid = false;
      emptyFields.push("postPage.metaTitle");
    }
    if (!comparisonTopAlternative.postPage.metaDescription) {
      valid = false;
      emptyFields.push("postPage.metaDescription");
    }
    if (!comparisonTopAlternative.postPage.disclaimer) {
      valid = false;
      emptyFields.push("postPage.disclaimer");
    }
    setEmptyFields(emptyFields);
    return valid;
  };

  return (
    <>
      <MRouterPrompt />
      <WebsitesSectionsSwitch
        inSection
        enabled={sections.comparisonTopAlternative}
        title={I18n.t(`website.page_types.comparisonTopAlternative`)}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.comparisonTopAlternative) {
              handleToggleActive("comparisonTopAlternative", enabled);
            }
            setError(true);
          } else {
            setError(false);
            handleToggleActive("comparisonTopAlternative", enabled);
          }
        }}
      >
        <CustomUrlPrefixTextField
          customUrlPrefix={comparisonTopAlternative.urlPrefix}
          handleValueChange={(value) => onPropertyChanged({ ...comparisonTopAlternative, urlPrefix: value })}
          previewUrls={[
            {
              key: "website.comparison_top_alternative.main_page_preview_url",
              value: comparisonTopAlternative.mainPage.slug,
              valid: sections.comparisonTopAlternative,
            },
            {
              key: "website.comparison_top_alternative.post_page_preview_url",
              value: `${comparisonTopAlternative.mainPage.slug}/post-slug`,
            },
          ]}
        />
      </WebsitesSectionsSwitch>

      {error && (
        <Typography variant={"body1"} className={classes.error}>
          {emptyFields.length > 0 && I18n.t("website.error_message")}
        </Typography>
      )}
      <PageSection sectionTitle={I18n.t("website.comparison_top_alternative.main_page")}>
        <WebsiteProductPagesMainPage
          emptyFields={emptyFields}
          page={comparisonTopAlternative}
          onPropertyChanged={onPropertyChanged}
        />
      </PageSection>

      <PageSection sectionTitle={I18n.t("website.comparison_top_alternative.post_page")}>
        <ProductPagesPostSection
          pageName={I18n.t("website.comparison_top_alternative.post_page").toLowerCase()}
          emptyFields={emptyFields}
          page={comparisonTopAlternative}
          onPropertyChanged={onPropertyChanged}
        />
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonTopAlternative);
