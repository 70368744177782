import _ from "lodash";
import { OUTLINE_PAGES } from "./constants/actionTypes";

const initialState = {
  outlines: [],
  outlinesLoaded: true,
  searchTextFilter: null,

  autoVideoInterview: null,
  outline: null,
  keywords: [],
  broadKeywords: [],
  pages: [],
  total: 0,
  limit: 100,
  page: 1,
  serps: [],
  serpsLoading: false,

  filter: {
    searchText: "",
    postState: [
      {
        _id: "APPROVED",
        name: _.capitalize("APPROVED"),
      },
    ],
    sortField: {
      direction: "desc",
      field: "createdAt",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OUTLINE_PAGES.ON_OUTLINE_PAGES_LOADED: {
      console.log("OUTLINE_PAGES.ON_OUTLINE_PAGES_LOADED", { page: action.payload.pagination?.page });
      return {
        ...state,
        outlinesLoaded: true,
        outlines: action.payload.outlines,
        page: action.payload.pagination?.page,
        total: action.payload.pagination?.total,
      };
    }
    case OUTLINE_PAGES.ON_OUTLINE_PAGES_SERPS_LOADING:
      return {
        ...state,
        serpsLoading: true,
      };
    case OUTLINE_PAGES.ON_OUTLINE_PAGES_SERPS_LOADED:
      return {
        ...state,
        serps: action.payload.data,
        serpsLoading: false,
      };
    case OUTLINE_PAGES.ON_AUTO_VIDEO_INTERVIEW_LOADED: {
      return {
        ...state,
        autoVideoInterview: action.payload.autoVideoInterview,
      };
    }
    case OUTLINE_PAGES.ON_OUTLINE_PAGES_UPDATED: {
      return {
        ...state,
        outlines: action.outlines,
      };
    }
    case OUTLINE_PAGES.ON_OUTLINE_PAGES_KEYWORDS_LOADED:
      return {
        ...state,
        broadKeywords: action.payload.broadKeywords,
        keywords: action.payload.keywords,
        pages: action.payload.pages,
      };
    case OUTLINE_PAGES.ON_OUTLINE_PAGES_KEYWORDS_EMPTY:
      return {
        ...state,
        broadKeywords: [],
        keywords: [],
      };

    case OUTLINE_PAGES.ON_OUTLINE_PAGES_START_LOADING: {
      return {
        ...state,
        outlinesLoaded: false,
      };
    }
    case OUTLINE_PAGES.ON_OUTLINE_PAGE_SELECTED:
      return {
        ...state,
        outline: action.outline,
      };
    case OUTLINE_PAGES.ON_OUTLINE_PAGINATION_CHANGE:
      return {
        ...state,
        page: action.page,
        limit: action.limit,
        total: action.total,
      };
    case OUTLINE_PAGES.ON_OUTLINE_PAGES_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        searchTextFilter: action.searchTextFilter,
      };
    case OUTLINE_PAGES.ON_OUTLINE_PAGES_OUTLINE_UPDATED:
      return {
        ...state,
        outlines: state.outlines.map((outline) => {
          if (outline._id === action.outline._id) {
            return action.outline;
          }
          return outline;
        }),
      };
    case OUTLINE_PAGES.OUTLINE_PAGES_ON_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
