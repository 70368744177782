import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { replace } from "connected-react-router";
import { HOME } from "../../reducers/constants/actionTypes";
import agent from "../../agent";
import { eUserRoles, IAdminUser, IWebsite } from "../../reducers/constants/objectTypes";
import MainRouter from "./MainRouter";
import { DragAndDropContext } from "./components/DragAndDropContext";
import cookies from "../../services/cookies";
import { web } from "../../helpers/urlHelper";
import { isOnboardingDone } from "../../helpers/websiteHelper";

type PropTypes = {
  adminLoaded: boolean;
  user?: IAdminUser;
  websites: IWebsite[];
  websitesLoaded: boolean;
  selectedWebsite: IWebsite;
  onWebsitesLoaded: Function;
  onAdminUserLoaded: (payload: any) => void;
  onOrganizationsLoaded: (payload: any) => void;
  redirectToLogin: () => void;
  adminUserLoadFailed: () => void;
  redirectToPasswordReset: () => void;
  redirectToSignupFlow: () => void;
  redirectToPagePreview: () => void;
  refreshWebsites: boolean;

  loadSubUser: () => void;
  redirectTo: (url: string) => void;
};

const mapStateToProps = (state) => ({
  adminLoaded: state.home.adminLoaded,
  user: state.home.admin,
  websitesLoaded: state.home.websitesLoaded,
  selectedWebsite: state.home.selectedWebsite,
  refreshWebsites: state.home.refreshWebsites,
  websites: state.home.websites,
});

const mapDispatchToProps = (dispatch) => ({
  onAdminUserLoaded: (result) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: result.user }),
  onWebsitesLoaded: (websites, match) => dispatch({ type: HOME.WEBSITES_LOADED, websites, match }),
  adminUserLoadFailed: () => dispatch({ type: HOME.ADMIN_USER_LOADED_FAILED }),
  onOrganizationsLoaded: (payload) => dispatch({ type: HOME.ORGANIZATIONS_LOADED, payload }),

  redirectToLogin: () => dispatch(replace("/login")),
  redirectToPasswordReset: () => dispatch(replace("/reset-initial-password")),
  redirectToSignupFlow: () => dispatch(replace(web.onBoarding())),
  redirectToPagePreview: () => dispatch(replace(web.websitePagePreview())),
  loadSubUser: () => dispatch({ type: HOME.SUB_ADMIN_USER_LOADED }),
  redirectTo: (url: string) => dispatch(replace(url)),
});

const Main = (props: PropTypes) => {
  const match = useRouteMatch("/:websiteName");
  const {
    adminLoaded,
    websitesLoaded,
    selectedWebsite,
    onWebsitesLoaded,
    websites,
    onAdminUserLoaded,
    redirectToLogin,
    adminUserLoadFailed,
    user,
    redirectToPasswordReset,
    refreshWebsites,
    loadSubUser,
    redirectToSignupFlow,
    redirectTo,
  } = props;

  const [userFetch, setUserFetch] = useState(false);

  React.useEffect(() => {
    agent.Auth.getUser()
      .then((res) => {
        onAdminUserLoaded(res);

        const subUserSession = cookies.get("subUserSession");

        if (subUserSession === "true") loadSubUser();
      })
      .catch((e) => {
        adminUserLoadFailed();
      })
      .finally(() => setUserFetch(true));
  }, [onAdminUserLoaded]);

  React.useEffect(() => {
    agent.Websites.all()
      .then((res) => {
        console.log("websites loaded", res.websites, match);
        onWebsitesLoaded(res.websites, match);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [onWebsitesLoaded, refreshWebsites]);

  if (!websitesLoaded || !adminLoaded) {
    if (!adminLoaded && userFetch) {
      console.log("redirect to login");
      redirectToLogin();
    }
    return null;
  }

  if (adminLoaded) if (user && user.initialPassword) redirectToPasswordReset();
  if (websitesLoaded && user && user.role === eUserRoles.CRO_CUSTOMER && !isOnboardingDone(selectedWebsite, user)) {
    redirectToSignupFlow();
  }

  return userFetch ? (
    <DragAndDropContext>
      <Switch>
        <Route exact={false} path={`/:websiteName`} component={MainRouter} />
        {selectedWebsite && <Redirect exact from={"*"} to={`/${selectedWebsite.name}`} />}
      </Switch>
    </DragAndDropContext>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
