import _ from "lodash";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import React from "react";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  metric: {
    fontSize: "14px",
    padding: 0,
    flexBasis: 0,
    flexGrow: 1,
    color: theme.palette.text.primary,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    height: 52,
    justifyContent: "end",
    "&:hover": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      marginBottom: "-2px",
    },
  },
}));

type PropTypes = {
  metric: { value: string; last?: boolean; col_name: string; tooltip: string };
  onClick?: () => void;
  collection?: { column: string; order: string }[];
  index: number;
  pageTab: boolean;
};

const ListMetricColumn = (props: PropTypes) => {
  const classes = useStyles(props);
  const { collection, metric, pageTab, index, onClick } = props;

  const columnOrder = _.findIndex(collection, (val) => val.column === metric.col_name);

  const renderIcon = () => {
    if (columnOrder !== -1)
      return collection[columnOrder].order === "asc" ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

    return "";
  };

  return (
    <Tooltip title={metric.tooltip} enterDelay={1000} arrow>
      <div className={classes.metric} onClick={onClick}>
        <Typography>{metric.value}</Typography>
        {renderIcon()}
      </div>
    </Tooltip>
  );
};

export default ListMetricColumn;
