import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { I18n } from "react-redux-i18n";
import addImage from "../../../../../img/image_placeholder.svg";
import { find } from "lodash";
import { Rating } from "@material-ui/lab";
import numeral from "numeral";
import { ArrowForward } from "@material-ui/icons";
import { IProduct } from "../../../../../reducers/constants/objectTypes";
import RatingStarIcon from "../../../../../icons/RatingStarIcon";
import { getImageCDNUrl } from "../../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: (props: PropTypes) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    borderRadius: 5,
    ...props.element.data.backgroundCss,
  }),
  productName: (props: PropTypes) => ({
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.45,
    ...props.element.data.productNameCss,
  }),
  productLogo: {
    height: 50,
    width: 50,
    objectFit: "cover",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: theme.palette.divider,
  },
  blueText: {
    color: theme.palette.primary.main,
  },
  logoAndRatingWrapper: {
    display: "flex",
    alignItems: "center",
  },
  ratingWrapper: {
    marginLeft: 10,
  },
  ctaButtonInput: {
    lineHeight: 1,
    textAlign: "start",
  },
  ctaButton: (props: PropTypes) => ({
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightLight as any,
    marginLeft: 10,
    height: "auto",
    minHeight: 50,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "10px 30px",
    borderRadius: 30,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    ...props.element.data?.buttonCss,
  }),
  rating: {
    display: "flex",
    alignItems: "center",
    marginLeft: 4,
  },
  ratingInnerWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 7,
  },
  ratingStarIcon: {
    marginRight: 10,
  },
  ratingInputRoot: {
    width: 50,
    marginRight: 10,
  },
  reviewsInputRoot: {
    width: 65,
    marginRight: 10,
  },
  previewImagePlaceHolder: {
    backgroundColor: theme.palette.secondary.main,
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    maxHeight: 200,
    backgroundImage: `url(${addImage})`,
  },
  iconFilled: {
    fill: "#FFBD06",
  },
  iconHover: {
    fill: "#FFBD06",
  },
  decimal: {
    padding: 2,
    fontSize: theme.typography.pxToRem(16),
  },
  ratingValue: {
    marginLeft: 4,
  },
  numOfReviewsText: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  products: IProduct[];
};

const CallToActionWidgetReadOnly = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, products } = props;
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);
  const productImage = () => {
    return (
      element.data.productLogo?.url !== undefined || product?.logo !== undefined || product?.logoFile?.url !== undefined
    );
  };
  return (
    <div {...attributes}>
      <div className={classes.wrap}>
        <div className={classes.logoAndRatingWrapper}>
          <img
            className={classes.productLogo}
            alt={element.data.productLogo?.alt || product?.logoFile?.alt || "logo"}
            src={getImageCDNUrl(element.data.productLogo?.url || product?.logo || product?.logoFile?.url)}
          />
          <div className={classes.ratingWrapper}>
            <Typography className={classes.productName}>{element.data.productName || product?.name || ""}</Typography>
            <div className={classes.ratingInnerWrapper}>
              <Rating
                readOnly
                classes={{
                  decimal: classes.decimal,
                  iconFilled: classes.iconFilled,
                  iconHover: classes.iconHover,
                }}
                value={element.data.productRating || product?.rating || "0"}
                defaultValue={0}
                precision={0.1}
                icon={<RatingStarIcon />}
                emptyIcon={<RatingStarIcon empty />}
              />
              {/*<Typography variant={'body1'} className={classes.ratingValue}>
									{element.data.productRating || product?.rating  || ''}
								</Typography>*/}

              {(element.data.numOfReviews || product?.numOfReviews) && (
                <div className={classes.rating}>
                  <Typography className={classes.numOfReviewsText} variant={"body1"}>
                    {`(${numeral(element.data.numOfReviews || product?.numOfReviews).format("0,0") || ""}`}
                  </Typography>
                  <Typography className={classes.numOfReviewsText} variant={"body1"}>
                    {` ${I18n.t("rich_text_editor.call_to_action.reviews")})`}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
        <Button
          target={"_blank"}
          href={element.data?.buttonUrl || product?.callToActionURL}
          endIcon={<ArrowForward />}
          className={classes.ctaButton}
        >
          <span className={classes.ctaButtonInput}>
            {element.data.buttonText ||
              product?.callToAction ||
              I18n.t("rich_text_editor.call_to_action.cta_button_text")}
          </span>
        </Button>
      </div>
      {children}
    </div>
  );
};

export default CallToActionWidgetReadOnly;
