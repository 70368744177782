import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  subHeader: {
    top: (props: PropTypes) => (props.top == null ? 62 : props.top),
    position: "sticky",
    display: "flex",
    backgroundColor: "white",
    padding: "30px 20px",
    marginRight: 5,
    zIndex: 2,
  },
}));

type PropTypes = {
  children: string | React.ReactNode;
  top?: number;
  className?: string;
};

const MTableHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  return <ListSubheader className={classNames(classes.subHeader, props.className)}>{props.children}</ListSubheader>;
};

export default MTableHeader;
