import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import PageSection from "../../components/PageSection";
import { IWebsiteGlossaryProps, IWebsiteSectionProps } from "../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import TextInputMentions from "../../components/TextInputMentions";
import { LENGTHS } from "../../../../reducers/constants/consts";
import WebsitesSectionsSwitch from "../../components/WebsitesSectionsSwitch";
import TextInputToolAndDesign from "../../components/TextInputToolAndDesign";
import MRouterPrompt from "../../components/MRouterPrompt";
import CustomUrlPrefixTextField from "../../components/CustomUrlPrefixTextField";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

const mapStateToProps = (state) => ({
  glossary: state.websitePages.glossary,
  sections: state.websitePages.sections,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (glossary) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "glossary",
      value: glossary,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  glossary: IWebsiteGlossaryProps;
  sections: IWebsiteSectionProps;

  onPropertyChanged: (glossary) => void;
  handleToggleActive: (section, enabled) => void;
};

const PageGlossary = (props: PropTypes) => {
  const classes = useStyles(props);
  const { glossary, sections, onPropertyChanged, handleToggleActive } = props;
  const [error, setError] = React.useState(false);
  const [emptyFields, setEmptyFields] = React.useState([]);

  React.useEffect(() => {
    if (emptyFields.length <= 0 && !error) {
      return;
    }
    textFieldsValidated();
  }, [glossary]);

  const textFieldsValidated = () => {
    setEmptyFields([]);
    const emptyFields = [];
    let valid = true;
    if (!glossary.name) {
      valid = false;
      emptyFields.push(I18n.t(`website.name`));
    }
    if (!glossary.slug) {
      valid = false;
      emptyFields.push(I18n.t(`website.slug_title`));
    }
    if (!glossary.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_title`));
    }
    if (!glossary.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_description`));
    }
    if (!glossary.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.glossary.title`));
    }
    if (!glossary.description.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.glossary.description`));
    }
    setEmptyFields(emptyFields);
    return valid;
  };

  return (
    <>
      <MRouterPrompt />
      <WebsitesSectionsSwitch
        inSection
        enabled={sections.glossary && sections.topic}
        title={I18n.t("website.glossary.glossary")}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.glossary) {
              handleToggleActive("glossary", enabled);
            }
            setError(true);
          } else {
            if (!sections.topic) {
              alert(I18n.t("website.topic_disabled"));
              return;
            }
            setError(false);
            handleToggleActive("glossary", enabled);
          }
        }}
      >
        <CustomUrlPrefixTextField
          customUrlPrefix={glossary.urlPrefix}
          handleValueChange={(value) => onPropertyChanged({ ...glossary, urlPrefix: value })}
          previewUrls={[
            {
              key: "website.glossary.main_page_preview_url",
              value: glossary.slug,
              valid: sections.glossary,
            },
          ]}
        />
      </WebsitesSectionsSwitch>

      {error && (
        <Typography variant={"body1"} className={classes.error}>
          {emptyFields.length > 0 && I18n.t("website.error_message")}
        </Typography>
      )}

      <PageSection sectionTitle={I18n.t("website.glossary.name")}>
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            error={emptyFields.includes(I18n.t(`website.name`))}
            titleAndLengthLocation={"top"}
            value={glossary.name}
            placeholder={I18n.t("website.name_ph")}
            title={I18n.t("website.name")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => onPropertyChanged({ ...glossary, name: value })}
          />

          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            error={emptyFields.includes(I18n.t(`website.slug_title`))}
            value={glossary.slug}
            placeholder={I18n.t("website.slug_title_ph")}
            title={I18n.t("website.slug_title")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => onPropertyChanged({ ...glossary, slug: value })}
          />
        </div>
        <Divider className={classes.divider} />
        <TextInputToolAndDesign
          textFieldTitle={I18n.t("website.title")}
          error={emptyFields.includes(I18n.t(`website.glossary.title`))}
          text={glossary.title}
          handleTextChanged={(text) => onPropertyChanged({ ...glossary, title: text })}
          variant={"h1"}
        />

        <TextInputMentions
          tags={[]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.meta_title`))}
          text={glossary.metaTitle}
          onTextChanged={(value) => onPropertyChanged({ ...glossary, metaTitle: value })}
        />
        <Divider className={classes.divider} />

        <TextInputToolAndDesign
          textFieldTitle={I18n.t("website.description")}
          variant={"h2"}
          error={emptyFields.includes(I18n.t(`website.glossary.description`))}
          text={glossary.description}
          handleTextChanged={(text) => onPropertyChanged({ ...glossary, description: text })}
        />

        <TextInputMentions
          tags={[]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.meta_description`))}
          text={glossary.metaDescription}
          onTextChanged={(value) => onPropertyChanged({ ...glossary, metaDescription: value })}
        />
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageGlossary);
