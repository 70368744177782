import * as React from "react";
import { colors } from "../../../../helpers/constants";

type PropTypes = {
  visible: boolean;
};

const PageEditorElementStyleWrapper = (props: PropTypes) => {
  const { visible } = props;

  return (
    <div
      style={{
        display: visible ? "block" : "none",
        position: "absolute",
        backgroundColor: colors.pageEditorBackgroundColor,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        padding: "15px 25px",
        overflow: "auto",
      }}
      id={"component_design_wrapper"}
    />
  );
};

export default PageEditorElementStyleWrapper;
