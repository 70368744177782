import React from "react";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import MDropdown, { IMDropdown } from "../../../components/MDropdown";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    padding: 15,
    height: 50,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    borderRadius: 3,
    borderWidth: 1,
    backgroundColor: "#EEF2F9",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      cursor: "pointer",
      "& $placeholder": {
        color: theme.palette.text.primary,
      },
      "& $dropDownIcon": {
        color: theme.palette.text.primary,
      },
    },
  },
  placeholder: {
    fontWeight: theme.typography.fontWeightRegular as any,
    color: theme.palette.text.primary,
    opacity: 0.5,
  },
  placeholderSelected: {
    opacity: 1,
  },
  dropDownIcon: {
    color: theme.palette.text.primary,
    marginRight: -5,
    width: "unset",
  },
  expanded: {
    "& $dropDownIcon": {
      color: theme.palette.text.primary,
    },
    "& $placeholder": {
      color: theme.palette.text.primary,
    },
  },
  paper: {
    maxHeight: 200,
  },
}));

const ModalDropdownField = (props: IMDropdown) => {
  const classes = useStyles();

  return <MDropdown {...props} classes={{ ...classes }} zIndex={9999} dynamicWidth />;
};

export default ModalDropdownField;
