import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { ListItem as MUIListItem } from "@material-ui/core";

import { web } from "../../../../../../helpers/urlHelper";
import CampaignListItem from "./CampaignListItem";
import CfProgressBar from "../../../../../../components/CfProgressBar";
import MTableHeaderLabel from "../../../../../../components/Table/Table/MTableHeaderLabel";
import { ICampaign, IWebsite, eCampaignAction, IWidget } from "../../../../../../reducers/constants/objectTypes";
import MTableHeader from "../../../../../../components/Table/Table/MTableHeader";
import MTable from "../../../../../../components/Table/Table/MTable";
import agent from "../../../../../../agent";
import MTableHeaderSortLabel from "../../../../../../components/Table/Table/MTableHeaderSortLabel";
import { WIDGETS_DISPLAY_RULES } from "../../../../../../reducers/constants/actionTypes";
import WidgetAnalyticsDialog from "../../../WidgetsBuilder/Widgets/WidgetAnalyticsDialog";
import moment from "moment/moment";
import CampaignAnalyticsDialog from "./CampaignAnalyticsDialog";
import MTableHeaderLabelWithSelectAll from "../../../../../../components/Table/Table/MTableHeaderLabelWithSelectAll";

type PropTypes = {
  selectedWebsite: IWebsite;
  rules: ICampaign[];
  loading: boolean;
  page: number;
  totalPages: number;
  pageSize: number;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  timePeriod: any;
  checkedRules: ICampaign[];

  openCampaign(id: string): void;
  campaignChanged(): void;
  handleValueChanged: (property: Record<string, any>) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  rules: state.widgetDisplayRules.rules,
  loading: state.widgetDisplayRules.loading,
  page: state.widgetDisplayRules.page,
  totalPages: state.widgetDisplayRules.totalPages,
  sortField: state.widgetDisplayRules.sortField,
  pageSize: state.widgetDisplayRules.pageSize,
  timePeriod: state.widgetDisplayRules.timePeriod,
  checkedRules: state.widgetDisplayRules.checkedRules,
});

const mapDispatchToProps = (dispatch) => ({
  openCampaign: (id) => dispatch(push(web.croCampaignEditor(id))),
  handleValueChanged: (property) => {
    dispatch({ type: WIDGETS_DISPLAY_RULES.PROPERTY_CHANGE, property });
  },
});

const WidgetDisplayRulesList = (props: PropTypes) => {
  const { loading, rules, selectedWebsite, sortField, timePeriod, checkedRules, handleValueChanged } = props;

  const handleCheckedBoxChanged = (checkedRules: ICampaign[]) => {
    handleValueChanged({ checkedRules });
  };

  const [campaignAnalytics, setCampaignAnalytics] = React.useState<ICampaign | null>(null);
  const { openCampaign, campaignChanged } = props;

  const { enqueueSnackbar } = useSnackbar();

  const deleteCampaign = (ruleId: string) => {
    agent.Campaigns.deleteCampaign(selectedWebsite._id, ruleId)
      .then(() => {
        enqueueSnackbar(I18n.t("widgets_display_rules_workflow.actions.deleted_successfully"));
        campaignChanged();
      })
      .catch((e) => {
        enqueueSnackbar(e.response.body.message ?? "Error occurred while deleting widget");
      });
  };

  const restoreCampaign = (rule: ICampaign) => {
    agent.Campaigns.updateCampaign(selectedWebsite._id, rule, eCampaignAction.RESTORE)
      .then(() => {
        enqueueSnackbar(I18n.t("widgets_display_rules_workflow.actions.restored_successfully"));
        campaignChanged();
      })
      .catch((e) => {
        enqueueSnackbar(e.response.body.message ?? "Error occurred while saving restoring rule");
      });
  };

  const publishCampaign = (rule: ICampaign) => {
    agent.Campaigns.updateCampaign(selectedWebsite._id, rule, eCampaignAction.PUBLISH)
      .then(() => {
        enqueueSnackbar(I18n.t("widgets_display_rules_workflow.actions.published_successfully"));
        campaignChanged();
      })
      .catch((e) => {
        enqueueSnackbar(
          e.response.body.message ?? I18n.t("widgets_display_rules_workflow.actions.something_went_wrong")
        );
      });
  };

  const unPublishCampaign = (rule: ICampaign) => {
    agent.Campaigns.updateCampaign(selectedWebsite._id, rule, eCampaignAction.UNPUBLISH)
      .then(() => {
        enqueueSnackbar(I18n.t("widgets_display_rules_workflow.actions.unpublished_successfully"));
        campaignChanged();
      })
      .catch((e) => {
        enqueueSnackbar(
          e.response.body.message ?? I18n.t("widgets_display_rules_workflow.actions.something_went_wrong")
        );
      });
  };

  const theme = useTheme();

  return (
    <>
      <MTable>
        <MTableHeader>
          <MTableHeaderLabelWithSelectAll
            columnWidth={"50%"}
            fontSize={theme.typography.pxToRem(20)}
            selected={checkedRules.length > 0}
            onClearAll={() => handleCheckedBoxChanged([])}
            onSelectAll={() => handleCheckedBoxChanged(rules)}
          >
            {I18n.t("widgets_display_rules_workflow.columns.campaigns")}
          </MTableHeaderLabelWithSelectAll>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged({ sortField: result })}
            sortField={props.sortField}
            field={"updatedAt"}
          >
            {I18n.t("widgets_display_rules_workflow.columns.updated")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged({ sortField: result })}
            sortField={props.sortField}
            field={"status"}
          >
            {I18n.t("widgets_display_rules_workflow.columns.status")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged({ sortField: result })}
            sortField={props.sortField}
            field={`analytics.${timePeriod.key}.totalViews`}
          >
            {I18n.t("widgets_display_rules_workflow.columns.page_views")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged({ sortField: result })}
            sortField={props.sortField}
            field={`analytics.${timePeriod.key}.totalWidgetViews`}
          >
            {I18n.t("widgets_display_rules_workflow.columns.widget_views")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged({ sortField: result })}
            sortField={props.sortField}
            field={`analytics.${timePeriod.key}.totalClicks`}
          >
            {I18n.t("widgets_display_rules_workflow.columns.clicks")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged({ sortField: result })}
            sortField={props.sortField}
            field={`analytics.${timePeriod.key}.ctr`}
          >
            {I18n.t("widgets_display_rules_workflow.columns.cpm")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel> {I18n.t("widgets_display_rules_workflow.columns.widgets")}</MTableHeaderLabel>

          <MTableHeaderLabel> {""}</MTableHeaderLabel>
        </MTableHeader>

        {loading && (
          <MUIListItem
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CfProgressBar />
          </MUIListItem>
        )}

        {!loading &&
          rules.map((item) => (
            <CampaignListItem
              key={item._id}
              campaign={item}
              selectedWebsite={selectedWebsite}
              isChecked={checkedRules.find((r) => r._id === item._id) !== undefined}
              handleClick={openCampaign}
              deleteCampaign={deleteCampaign}
              publishCampaign={publishCampaign}
              unPublishCampaign={unPublishCampaign}
              restoreCampaign={restoreCampaign}
              handleCheckChange={(checked) => {
                handleCheckedBoxChanged(
                  checked ? [...checkedRules, item] : checkedRules.filter((r) => r._id !== item._id)
                );
              }}
              timePeriod={timePeriod}
              openWidgetAnalyticsDialog={() => setCampaignAnalytics(item)}
            />
          ))}
      </MTable>
      {campaignAnalytics !== null && (
        <CampaignAnalyticsDialog
          open={true}
          campaign={campaignAnalytics}
          startDate={moment().subtract(timePeriod.value, "months").startOf("day").toDate()}
          handleClose={() => setCampaignAnalytics(null)}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetDisplayRulesList);
