import { MANAGE_CONTENT_UPLOAD } from "./constants/actionTypes";

const initialState = {
  URL: "",
  content: "",
  domains: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_CONTENT_UPLOAD.DOMAINS_LOADED:
      return {
        ...state,
        domains: action.payload.domains,
      };
    case MANAGE_CONTENT_UPLOAD.DOMAINS_CHANGED: {
      return {
        ...state,
        domains: action.domains,
      };
    }
    case MANAGE_CONTENT_UPLOAD.SET_URL:
      return {
        ...state,
        URL: action.URL,
      };
    case MANAGE_CONTENT_UPLOAD.SET_CONTENT:
      return {
        ...state,
        content: action.content,
      };
    case MANAGE_CONTENT_UPLOAD.CONTENT_SAVED:
      return {
        ...state,
        URL: "",
        content: "",
        domains: state.domains.map((d) => ({ ...d, checked: false })),
      };
    default:
      return state;
  }
};
