import * as React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { IProduct, IWebsite } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";
import agent from "../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  productWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 5,
  },
  title: {
    minWidth: 80,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 7,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  product: IProduct;

  onProductChange: (product: IProduct) => void;
};

const LinkProductPicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const { product, selectedWebsite, onProductChange } = props;
  const [items, setItems] = React.useState<IProduct[]>([]);
  const [loading, setLoading] = React.useState(false);
  const allItems = product && product.itemNumber ? _.uniqBy([product, ...items], "itemNumber") : items;

  const fetchProducts = (search = "", limit = 100) => {
    setLoading(true);
    agent.Product.autoComplete(selectedWebsite._id, search, true, limit)
      .then((results) => {
        setLoading(false);
        setItems(results.items);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (product) {
      return;
    }
    fetchProducts("", 10);
  }, []);

  return (
    <div className={classes.productWrapper}>
      <MSelect
        isClearable={false}
        loading={loading}
        fullWidth
        options={allItems}
        value={product ? _.find(allItems, { itemNumber: product?.itemNumber || null }) : null}
        searchable
        placeholder={I18n.t("edit_post.review_item_ph")}
        height={35}
        isBold={false}
        borderWidth={1}
        borderRadius={3}
        menuPlacement={"bottom"}
        handleInputChange={(search) => {
          if (search === "") {
            return;
          }
          fetchProducts(search);
        }}
        handleChange={(selectedItem) => {
          onProductChange(selectedItem);
        }}
        optionLabel={"name"}
        optionValue={"itemNumber"}
      />
    </div>
  );
};

export default LinkProductPicker;
