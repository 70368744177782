import filter from "lodash/filter";
import { MANAGE_WEBSITES } from "./constants/actionTypes";

const initialState = {
  // Categories
  categoriesSelectedLocale: null,
  selectedCategory: null,

  // Topics
  topicsFilter: "forum",
  topicsLoaded: false,
  topics: [],
  selectedTopic: null,

  // Tags
  tagsLoaded: false,
  tags: [],
  selectedTag: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_WEBSITES.ON_CATEGORY_SELECTED:
      return {
        ...state,
        selectedCategory: action.category,
      };
    case MANAGE_WEBSITES.ON_CATEGORY_CHANGED:
      return {
        ...state,
        selectedCategory: action.category,
      };
    case MANAGE_WEBSITES.ON_NEW_CATEGORY_CREATED:
      return {
        ...state,
        selectedCategory: action.category,
      };
    case MANAGE_WEBSITES.ON_CATEGORY_DELETED: {
      return {
        ...state,
        selectedCategory: null,
      };
    }
    case MANAGE_WEBSITES.ON_CATEGORY_UPDATED:
      return {
        ...state,
        selectedCategory: action.category,
      };
    case MANAGE_WEBSITES.ON_CATEGORIES_LOCALE_CHANGED:
      return {
        ...state,
        categoriesSelectedLocale: action.locale,
      };
    case MANAGE_WEBSITES.ON_TOPIC_LOADED:
      return {
        ...state,
        topicsLoaded: true,
        topics: action.payload.topics,
        selectedTopic: action.payload.topics.length > 0 ? action.payload.topics[0] : null,
      };
    case MANAGE_WEBSITES.ON_TOPIC_SELECTED:
      return {
        ...state,
        selectedTopic: action.topic,
      };
    case MANAGE_WEBSITES.ON_TOPIC_CHANGED:
      return {
        ...state,
        selectedTopic: action.topic,
      };
    case MANAGE_WEBSITES.ON_NEW_TOPIC_CREATED:
      return {
        ...state,
        selectedTopic: action.topic,
        topics: [action.topic, ...(state.topics || [])],
      };
    case MANAGE_WEBSITES.ON_TOPIC_DELETED: {
      const topics = filter(state.topics, (t) => t._id !== action.topic._id);
      return {
        ...state,
        topics,
        selectedTopic: topics.length > 0 ? topics[0] : null,
      };
    }
    case MANAGE_WEBSITES.ON_TOPIC_UPDATED:
      return {
        ...state,
        selectedTopic: action.topic,
        topics: state.topics.map((t) => {
          if (t._id === action.topic._id) {
            return action.topic;
          }
          return t;
        }),
      };
    case MANAGE_WEBSITES.FILTER_TOPIC_LIST_CHANGED:
      return {
        ...state,
        topicsFilter: action.filter,
        topicsLoaded: false,
        topics: [],
        selectedTopic: null,
      };
    case MANAGE_WEBSITES.ON_TAGS_LOADED:
      return {
        ...state,
        tagsLoaded: true,
        tags: action.payload.tags,
        selectedTag: action.payload.tags.length > 0 ? action.payload.tags[0] : null,
      };
    case MANAGE_WEBSITES.ON_TAG_SELECTED:
      return {
        ...state,
        selectedTag: action.tag,
      };
    case MANAGE_WEBSITES.ON_TAG_UPDATED:
      return {
        ...state,
        selectedTag: action.tag,
        tags: state.tags.map((tag) => {
          if (tag._id === action.tag._id) {
            return action.tag;
          }
          return tag;
        }),
      };
    default:
      return state;
  }
};
