import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { TextField, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles(({ palette, typography }: Theme) => ({
  commentField: {
    color: palette.text.secondary,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: typography.body2.fontSize,
    lineHeight: "1.5rem",
    height: "1.5rem",
    fontFamily: typography.body2.fontFamily,
    cursor: "initial",
  },
}));

type PropTypes = {
  comment: string;
  onChange: (comment: string) => void;
};

const CommentField = (props: PropTypes) => {
  const classes = useStyles(props);
  const { onChange } = props;

  const [isFocused, setIsFocused] = React.useState(false);
  const [comment, setComment] = React.useState(props.comment || "");

  return !isFocused ? (
    <Typography
      variant="body2"
      className={classes.commentField}
      onClick={(e) => {
        e.stopPropagation();
        setIsFocused(true);
      }}
    >
      {comment || I18n.t("dialogs.rephrase.add_comment")}
    </Typography>
  ) : (
    <TextField
      autoFocus
      inputProps={{ className: classes.commentField }}
      value={comment}
      onChange={(event) => setComment(event.target.value)}
      onBlur={() => {
        setIsFocused(false);
        if (comment !== props.comment) onChange(comment);
      }}
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
};

export default CommentField;
