import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { kebabCase } from "lodash";
import classNames from "classnames";
import { Node, Transforms } from "slate";
import MTextField from "../../../../../components/MTextField";
import { IPage } from "../../../../../reducers/constants/objectTypes";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  pLeft: {
    paddingLeft: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  counter: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  headingOne: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: theme.typography.pxToRem(30),
  },
  headingTwo: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: theme.typography.pxToRem(26),
  },
  wrapper: {
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  textField: {
    width: "100%",
    height: 37,
    padding: "0px 10px",
    margin: 0,
    justifyContent: "center",
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    "&:focus": {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
  },
  textInput: {
    padding: 0,
  },
  headingsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
    marginLeft: 16,
    marginRight: 28,
    marginBottom: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
    flex: 1,
  },
  clearIcon: {
    cursor: "pointer",
    fontSize: 18,
    marginLeft: 10,
  },
}));

type PropTypes = {
  customClass?: string;
  children?: React.ReactNode;
  page: IPage;
  items: any[];
  values: any[];
  handleOnChange: (value: any) => void;
};

const ACFTableOfContents = (props: PropTypes) => {
  const classes = useStyles(props);
  const { customClass, children, items = [], values = [], page, handleOnChange } = props;
  const [localValues, setLocalValues] = React.useState([]);

  const idField = React.useMemo(() => {
    return items.find((item) => item.isId)?.key || "link_anchor";
  }, [items]);

  const newValue = React.useMemo(() => {
    const value = {};
    items.forEach((item) => {
      value[item.key] = "";
    });
    return value;
  }, [items]);

  React.useEffect(() => {
    if (!page.imported && (!values || values?.length === 0)) {
      const nodes = JSON.parse(page?.richText || "[]");
      const newValues = nodes
        .map((node) => {
          if (node.type === "heading-one") {
            const headingText = Node.string(node);
            if (headingText) {
              return {
                [idField]: kebabCase(headingText.split(" ").splice(0, 3).join(" ")),
                [items[0].key]: headingText, // assuming first item is title
              };
            }
          }
        })
        .filter((value) => value);
      // all values without duplicates
      const allValues = _.unionBy(newValues, values?.length ? values : [], idField);
      setLocalValues(allValues);
      return;
    }
    setLocalValues(values?.length ? values : []);
  }, [values]);

  return (
    <div className={classNames(classes.wrapper, customClass)}>
      <div className={classes.headingsWrapper}>
        {items.map((item) => (
          <Typography className={classes.heading} key={item.key}>
            {item.label}
          </Typography>
        ))}
      </div>
      {[...localValues, newValue].map((toc, i) => (
        <div key={`acf_toc_${i + 1}`} className={classes.itemWrapper}>
          <a className={classes.link} href={`#${toc[idField] || ""}`}>
            <span className={classes.counter}>{`${i + 1}`}</span>
          </a>
          {items.map((item, j) => (
            <MTextField
              key={`${item.key}_${i + 1}${j + 1}`}
              inputClassName={classes.textInput}
              customClass={classes.textField}
              onChange={(value) => {
                if (i === localValues.length) {
                  const newValues = [...localValues, newValue];
                  newValues[i] = { ...newValues[i], [item.key]: value };
                  setLocalValues(newValues);
                  handleOnChange(newValues);
                  return;
                }
                const newValues = [...localValues];
                newValues[i] = { ...newValues[i], [item.key]: value };
                setLocalValues(newValues);
                handleOnChange(newValues);
              }}
              value={toc[item.key]}
              placeholder={""}
            />
          ))}
          <ClearIcon
            style={{ visibility: localValues.length !== i ? "visible" : "hidden" }}
            className={classes.clearIcon}
            onClick={() => {
              if (localValues.length !== i) {
                const newValues = [...localValues];
                newValues.splice(i, 1);
                handleOnChange(newValues);
              }
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default ACFTableOfContents;
