import React from "react";
import { I18n } from "react-redux-i18n";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";

type PropTypes = {
  tabType: { _id: number; name: string };
  handleTabTypeSelected: (tabType: { _id: number; name: string }) => void;
};

const MTableToolbarPublishedTabTypeFilter = (props: PropTypes) => {
  const { tabType, handleTabTypeSelected } = props;
  const tabTypes = [
    { _id: 0, name: "All" },
    { _id: 1, name: "Cluster" },
  ];
  return (
    <MTableToolbarBaseSelect
      options={tabTypes}
      selectedOptions={[tabType]}
      clearable={false}
      placeholder={I18n.t("workflow.writer.type_post")}
      handleChange={(value) => {
        handleTabTypeSelected(value?.[0] || tabTypes[0]);
      }}
    />
  );
};

export default MTableToolbarPublishedTabTypeFilter;
