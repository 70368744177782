import * as React from "react";
import numeral from "numeral";
import { Theme, withStyles } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useState } from "react";
import ClassNames from "classnames";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import VideoUploaderHelper from "../../../../helpers/VideoUploaderHelper";
import FileUpload from "./FileUpload";
import MTextField from "../../../../components/MTextField";
import theme from "../../../../themes/theme";
import CfButton from "../../../../components/CfButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
    xsColumn: {
      width: "2%",
    },
    smColumn: {
      width: "10%",
    },
    lgColumn: {
      width: "15%",
    },
    xlColumn: {
      width: "100%",
      maxWidth: 280,
      height: 32,
      display: "flex",
      alignItems: "center",
      border: `solid 1px ${theme.palette.divider}`,
      borderRadius: 3,
    },
    leftSide: {
      width: "60%",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
    },
    rightSide: {
      width: "40%",
      display: "flex",
      justifyContent: "end",
    },
    fullSize: {
      width: "100%",
    },
    fileName: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    input: {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: theme.typography.pxToRem(14),
    },
    field: {
      margin: 0,
    },
    select: {
      textTransform: "capitalize",
    },
    uploadButton: {
      width: "100%",
      height: 32,
      padding: 10,
      borderRadius: 3,
      minHeight: "unset",
      fontWeight: theme.typography.fontWeightLight as any,
      border: `solid 1px ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      "&:hover": {
        color: "white",
      },
      "&:disabled": {
        color: "white",
        backgroundColor: theme.palette.primary.dark,
        border: "none",
      },
    },
    expandIcon: {
      fontSize: 20,
      fill: "#9e9e9e",
      cursor: "pointer",
    },
    root: {
      width: "100%",
      border: `solid 1px ${theme.palette.divider}`,
      textDecoration: "none",
      borderRadius: 3,
      paddingLeft: 10,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  })
);

const iconStyles = {
  selectIcon: {
    color: "#9e9e9e",
    fontSize: 21,
    top: "unset",
    right: 4,
  },
};

const CustomExpandMore = withStyles(iconStyles)(({ className, classes, ...rest }) => {
  return <ExpandMoreIcon {...rest} className={ClassNames(className, classes.selectIcon)} />;
});

type PropTypes = {
  index: number;
  selectedWebsite: IWebsite;
};
const UploadSourceFileRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { index, selectedWebsite } = props;

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [type, setType] = useState<string>("Audio");
  const [languageCode, setLanguageCode] = useState<string>("en-US");
  const [speakers, setSpeakers] = useState<number>(2);
  const [file, setFile] = useState<any>(undefined);

  const [uploader, setUploader] = useState<any>(undefined);
  const [progress, setProgress] = useState(0);
  const [showAbort, setShowAbort] = useState<boolean>(false);

  const languageCodeList = [
    "en-US",
    "he-IL",
    "af-ZA",
    "ar-AE",
    "ar-SA",
    "da-DK",
    "de-CH",
    "de-DE",
    "en-AB",
    "en-AU",
    "en-GB",
    "en-IE",
    "en-IN",
    "en-WL",
    "es-ES",
    "es-US",
    "fa-IR",
    "fr-CA",
    "fr-FR",
    "he-IL",
    "hi-IN",
    "id-ID",
    "it-IT",
    "ja-JP",
    "ko-KR",
    "ms-MY",
    "nl-NL",
    "pt-BR",
    "pt-PT",
    "ru-RU",
    "ta-IN",
    "te-IN",
    "tr-TR",
    "zh-CN",
    "zh-TW",
    "th-TH",
    "en-ZA",
    "en-NZ",
  ];
  const types = ["Audio", "Video"];

  const handleFileSelect = (files: FileList | null) => {
    if (files) {
      setFile(files[0]);
    }
  };

  const uploadFile = () => {
    if (file) {
      let percentage: any;

      const videoUploaderOptions = {
        fileName: file.name,
        file,
        title,
        description,
        type,
        languageCode,
        speakers,
      };

      const uploader = new VideoUploaderHelper(selectedWebsite, videoUploaderOptions);
      setUploader(uploader);

      uploader
        .onProgress(({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            setProgress(percentage);
            console.log("percentage", `${percentage}%`);
          }
        })
        .onError((error: any) => {
          setFile(undefined);
          console.error(error);
        });

      uploader.start();
    }
  };

  const cancelUpload = () => {
    if (uploader) {
      uploader.abort();
      setFile(undefined);
    }
  };

  return (
    <div className={classes.row}>
      <span className={classes.xsColumn}>{index}</span>
      <div className={classes.xlColumn}>
        <div className={ClassNames(classes.leftSide, progress !== 0 && classes.fullSize)}>
          {file && <div className={classes.fileName}>{file.name}</div>}
        </div>
        {progress === 0 && (
          <div className={classes.rightSide}>
            <FileUpload handleOnSelect={handleFileSelect} />
          </div>
        )}
      </div>
      <MTextField
        disabled={progress !== 0}
        borderRadius={3}
        borderColor={theme.palette.divider}
        height={32}
        outlined
        customClass={ClassNames(classes.field, classes.lgColumn)}
        inputClassName={classes.input}
        fullWidth
        value={title}
        placeholder={I18n.t("source_file_uploader_dialog.title_ph")}
        onChange={(value) => setTitle(value)}
      />
      <MTextField
        disabled={progress !== 0}
        borderRadius={3}
        borderColor={theme.palette.divider}
        height={32}
        outlined
        customClass={ClassNames(classes.field, classes.lgColumn)}
        inputClassName={classes.input}
        fullWidth
        value={description}
        placeholder={I18n.t("source_file_uploader_dialog.description_ph")}
        onChange={(value) => setDescription(value)}
      />
      <Select
        disabled={progress !== 0}
        disableUnderline
        placeholder={I18n.t("source_file_uploader_dialog.select_ph")}
        classes={{ root: classes.root }}
        className={classes.smColumn}
        value={type}
        onChange={(event) => {
          setType(event.target.value.toString());
        }}
        IconComponent={CustomExpandMore}
      >
        {types.map((t, y) => (
          <MenuItem key={`${t}_${y}`} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
      <Select
        disabled={progress !== 0}
        disableUnderline
        placeholder={I18n.t("source_file_uploader_dialog.select_ph")}
        classes={{ root: classes.root }}
        className={classes.smColumn}
        value={languageCode}
        onChange={(event) => {
          setLanguageCode(event.target.value.toString());
        }}
        IconComponent={CustomExpandMore}
      >
        {languageCodeList.map((language, z) => (
          <MenuItem key={`${language}_${z}`} value={language}>
            {language}
          </MenuItem>
        ))}
      </Select>
      <MTextField
        disabled={progress !== 0}
        borderRadius={3}
        borderColor={theme.palette.divider}
        height={32}
        outlined
        customClass={ClassNames(classes.field, classes.smColumn)}
        inputClassName={classes.input}
        fullWidth
        value={speakers?.toString()}
        placeholder={I18n.t("source_file_uploader_dialog.speakers_ph")}
        onChange={(value) => {
          setSpeakers(numeral(value).value());
        }}
      />
      <div
        className={classes.smColumn}
        onMouseEnter={() => {
          if (progress > 0 && progress < 100) {
            setShowAbort(true);
          }
        }}
        onMouseLeave={() => {
          setShowAbort(false);
        }}
      >
        <CfButton
          disabled={progress !== 0 && showAbort === false}
          customClass={classes.uploadButton}
          text={
            progress === 0
              ? I18n.t("source_file_uploader_dialog.upload")
              : progress === 100
              ? I18n.t("source_file_uploader_dialog.uploaded")
              : showAbort
              ? I18n.t("source_file_uploader_dialog.abort")
              : `${progress}%`
          }
          onClick={() => {
            if (progress === 0) {
              uploadFile();
            } else if (showAbort) {
              cancelUpload();
            }
          }}
        />
      </div>
    </div>
  );
};
export default UploadSourceFileRow;
