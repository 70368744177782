import React, { useEffect, useMemo, useState } from "react";
import { Dialog, DialogContent, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import { STRATEGY_POST_TYPES } from "../../../helpers/constants";
import XIcon from "../../../icons/XIcon";
import MSelect from "../../../components/MSelect";
import MButton from "../../../components/MButton";
import { getAvailablePageTypes } from "../../../helpers/websiteHelper";
import _ from "lodash";
import { useSnackbar } from "notistack";
import set from "lodash/set";
import { AbilityContext } from "../../../casl/can";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    padding: 0,
    width: "50%",
    "& .MuiDialogContent-root": {
      padding: 24,
    },
    minHeight: 500,
    overflow: "hidden",
    backgroundColor: "transparent",
    boxShadow: "none",
    borderRadius: 0,
    border: "none",
  },
  dialogTitle: {
    fontSize: "25px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  firstChildWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  closeButton: {
    padding: 0,
    marginRight: 0,
    alignSelf: "flex-start",
  },
  headerIcon: {
    width: 20,
    height: 20,
    fill: theme.palette.text.primary,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    justifyContent: "space-between",
    overflow: "auto",
    maxHeight: 247,
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
  },
  charCounter: {
    display: "flex",
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.disabled,
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    width: "50%",
  },
  textField: {
    margin: 0,
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: 14,
    zIndex: 100,
  },
  buttonWrapper: {
    marginTop: "25px",
    marginLeft: "auto",
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
    borderRadius: "20px",
    padding: "10px 20px",
    height: 36,
  },
}));

type PropTypes = {
  open: boolean;
  closeDialog: () => void;
  savePostType: (type: string) => void;
  post: IPage;
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const WorkflowPostTypeDialog = (props: PropTypes) => {
  const { open, closeDialog, post, savePostType, selectedWebsite } = props;
  const classes = useStyles();
  const [snackbarKey, setSnackbarKey] = useState(null);

  const [currentPostType, setCurrentPostType] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const ability = React.useContext<any>(AbilityContext);

  const pageTypes = getAvailablePageTypes(selectedWebsite).map((value) => ({
    _id: value.key,
    name: value.label,
  }));

  React.useEffect(() => {
    setCurrentPostType(_.find(pageTypes, ["_id", post.type]));
    if (post.optimize) {
      if (snackbarKey !== null) {
        closeSnackbar(snackbarKey);
      }
      const key = enqueueSnackbar(I18n.t("workflow.dialog.optimize_post_type_error"), {
        onClose: () => setSnackbarKey(null),
      });
      setSnackbarKey(key);
    }
  }, [post]);

  if (!ability.can("editor", "role") || post.optimize) {
    return null;
  }

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={closeDialog}
      fullWidth
      maxWidth={"xl"}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.contentWrapper}>
        <div className={classes.firstChildWrapper}>
          <div className={classes.titleWrapper}>
            <Typography variant={"subtitle1"} className={classes.dialogTitle}>
              {I18n.t("workflow.dialog.edit_vertical")}
            </Typography>
            <IconButton className={classes.closeButton} onClick={closeDialog}>
              <XIcon className={classes.headerIcon} />
            </IconButton>
          </div>

          <div className={classes.column}>
            <Typography className={classes.inputLabel} variant={"subtitle2"}>
              {I18n.t("workflow.dialog.edit_vertical_description")}
            </Typography>
            <MSelect
              value={currentPostType || null}
              options={pageTypes}
              optionValue={"_id"}
              optionLabel={"name"}
              customClass={classes.textField}
              height={37}
              borderWidth={1}
              width={200}
              dropdownZIndexPriority
              borderRadius={5}
              maxMenuHeight={320}
              handleChange={(postType) => {
                setCurrentPostType(postType);
              }}
            />
          </div>
        </div>

        <div className={classes.buttonWrapper}>
          <MButton
            customClassNames={classes.saveBtn}
            onClick={() => {
              if (post && currentPostType) {
                savePostType(currentPostType._id);
              }
            }}
          >
            {I18n.t("workflow.mapping.save")}
          </MButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, {})(WorkflowPostTypeDialog);
