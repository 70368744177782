import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#fff",
    fontSize: 16,
  },
}));

type PropTypes = {
  className?: string;
};

const SmallTitleIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 18 13"}>
      <path d="M6.33089 13C6.00176 13 5.71929 12.8867 5.48346 12.6602C5.24707 12.4342 5.12887 12.1635 5.12887 11.8481V2.3038H2.03796C1.70883 2.3038 1.42636 2.19053 1.19053 1.96399C0.954136 1.738 0.835938 1.4673 0.835938 1.1519C0.835938 0.822785 0.954136 0.548523 1.19053 0.329114C1.42636 0.109705 1.70883 0 2.03796 0H10.6238C10.9529 0 11.2357 0.112996 11.4721 0.338987C11.7079 0.565528 11.8258 0.836498 11.8258 1.1519C11.8258 1.4673 11.7079 1.738 11.4721 1.96399C11.2357 2.19053 10.9529 2.3038 10.6238 2.3038H7.53291V11.8481C7.53291 12.1635 7.415 12.4342 7.17917 12.6602C6.94277 12.8867 6.66001 13 6.33089 13ZM14.0582 13C13.729 13 13.4466 12.8867 13.2107 12.6602C12.9743 12.4342 12.8561 12.1635 12.8561 11.8481V6.41772H11.4824C11.1533 6.41772 10.8708 6.30445 10.635 6.07791C10.3986 5.85192 10.2804 5.58122 10.2804 5.26582C10.2804 4.93671 10.3986 4.66245 10.635 4.44304C10.8708 4.22363 11.1533 4.11392 11.4824 4.11392H16.6339C16.963 4.11392 17.2458 4.22692 17.4822 4.45291C17.718 4.67945 17.8359 4.95042 17.8359 5.26582C17.8359 5.58122 17.718 5.85192 17.4822 6.07791C17.2458 6.30445 16.963 6.41772 16.6339 6.41772H15.2602V11.8481C15.2602 12.1635 15.1423 12.4342 14.9064 12.6602C14.67 12.8867 14.3873 13 14.0582 13Z" />
    </SvgIcon>
  );
};

export default SmallTitleIcon;
