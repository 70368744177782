import * as React from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: "22px",
    cursor: "pointer",
  },
  startIcon: {
    marginTop: -2,
    marginRight: 2,
  },
  addButton: {
    marginTop: -8,
    marginBottom: 10,
    textTransform: "none",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  onClick: () => void;
  className?: string;
  children?: React.ReactNode;
};

const AddNewButton = (props: PropTypes) => {
  const classes = useStyles();
  const { className, children } = props;
  const { onClick } = props;

  return (
    <Button
      className={ClassNames(classes.addButton, className)}
      classes={{ startIcon: classes.startIcon }}
      disableElevation
      startIcon={<AddIcon className={classes.icon} />}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default AddNewButton;
