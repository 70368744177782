import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { web } from "../../helpers/urlHelper";
import { AbilityContext } from "../../casl/can";
import Redirects from "./pages/Redirects/Redirects";
import IndexingAPI from "./pages/IndexingAPI/IndexingAPI";
import { IWebsite } from "../../reducers/constants/objectTypes";

const useStyles = makeStyles(() =>
  createStyles({
    pageWrapper: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flexGrow: 1,
      display: "flex",
    },
  })
);

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});
type PropTypes = {
  selectedWebsite: IWebsite;
};

const SEO = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const ability = React.useContext<any>(AbilityContext);
  const routes = React.useMemo(() => {
    const allRoutes = [];
    if (ability.can("view", "seo_redirects_tab")) {
      allRoutes.push({
        url: web.seoRedirects(),
        route: (
          <Route
            key={`seo_${selectedWebsite._id}_redirects`}
            exact={false}
            path={web.seoRedirects()}
            component={Redirects}
          />
        ),
      });
    }
    if (ability.can("view", "seo_indexing_api_tab")) {
      allRoutes.push({
        url: web.seoIndexingApi(),
        route: (
          <Route
            key={`seo_${selectedWebsite._id}_indexing_api`}
            exact={false}
            path={web.seoIndexingApi()}
            component={IndexingAPI}
          />
        ),
      });
    }
    return allRoutes;
  }, [ability, selectedWebsite]);
  return (
    <div className={classes.pageWrapper}>
      <div className={classes.content}>
        <Switch>
          {routes.map((route) => route.route)}
          <Redirect exact from={"*"} to={routes[0]?.url} />
        </Switch>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(SEO);
