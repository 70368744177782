import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme, Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import { I18n } from "react-redux-i18n";

import { IWebsite, IWidget, eWidgetSubtype, eWidgetType } from "../../../../../reducers/constants/objectTypes";
import { WidgetTypeAlias } from "../../../../CRO/pages/WidgetsBuilder/constants";
import { IWidgetPickerState } from "../../../../../reducers/widgetPickerReducer";
import { WIDGET_PICKER } from "../../../../../reducers/constants/actionTypes";
import agent from "../../../../../agent";
import MTextFieldV2 from "../../../../../components/MTextFieldV2";
import SearchIcon from "../../../../../icons/SearchIcon";
import CfProgressBar from "../../../../../components/CfProgressBar";
import MCategorizedOptionsFilter, { ICategoryOption } from "../../../../../components/MCategorizedOptionsFilter";

const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "15px 20px",
    gap: 20,
  },
  searchInput: {
    height: 37,
    minHeight: "unset",
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  typesWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 10,
    overflowX: "auto",
  },
  type: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 9px",
    cursor: "pointer",
    "&:hover": {
      borderRadius: 22,
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedType: {
    borderRadius: 22,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  loaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  inlineLoaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  listsContainer: {
    height: "100%",
    minHeight: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 30,
    overflowY: "auto",
  },
  listWrapper: {
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    flexShrink: 0,
    overflow: "hidden",
  },
  showMore: {
    marginTop: 5,
    fontSize: 14,
    lineHeight: "normal",
    color: theme.palette.primary.main,
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
      color: theme.palette.primary.light,
    },
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
  },
  itemsWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: 2,
    height: "100%",
    overflowY: "auto",
    marginBottom: 5,
  },
  widgetCard: {
    minWidth: 0,
    borderRadius: 3,
    padding: "20px 20px 15px 20px",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    maxHeight: 217,
    backgroundColor: "#EEF2F9",
    position: "relative",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#E4EAF5",
      "& $editIcon": {
        display: "block",
      },
    },
  },
  editIcon: {
    fontSize: 16,
    position: "absolute",
    top: 5,
    right: 5,
    display: "none",
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  selectedWidgetCard: {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: "18px 18px 13px 18px",
    "& $widgetTitle": {
      color: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: "#E4EAF5",
      "& $widgetTitle": {
        color: theme.palette.primary.main,
      },
    },
    "& $editIcon": {
      top: 3,
      right: 3,
    },
  },
  defaultWidgetCard: {},
  widgetImg: {
    width: "100%",
    height: 155,
    backgroundColor: "#0298C8",
    objectFit: "contain",
    borderRadius: 3,
    padding: 10,
  },
  templateImg: {
    width: "100%",
    height: 155,
    backgroundColor: "#6B5ED1",
    objectFit: "contain",
    borderRadius: 3,
    padding: 10,
  },
  widgetTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  searchWrapper: {
    display: "flex",
    gap: 10,
  },
  notFoundWrapper: {
    minHeight: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& $title": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightRegular as any,
    },
  },
}));

type PropTypes = {
  defaultWidget?: IWidget;
  includeDialogs: boolean;
  selectedWebsite: IWebsite;
  selectedType: IWidgetPickerState["selectedType"];
  selectedWidget: IWidget;
  handlePropertyChange: (property: Partial<IWidgetPickerState>) => void;
};

const mapStateToProps = (state) => ({
  includeDialogs: state.widgetPicker.includeDialogs,
  selectedWebsite: state.home.selectedWebsite,
  selectedType: state.widgetPicker.selectedType,
  selectedWidget: state.widgetPicker.selectedWidget,
});

const mapDispatchToProps = (dispatch) => ({
  handlePropertyChange: (property: Partial<IWidgetPickerState>) =>
    dispatch({ type: WIDGET_PICKER.PROPERTY_CHANGE, property }),
});

const WidgetPickerLeftPane = (props: PropTypes) => {
  const { defaultWidget, selectedWebsite, selectedType, selectedWidget, includeDialogs } = props;
  const { handlePropertyChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [widgets, setWidgets] = React.useState<IWidget[]>([]);
  const [templates, setTemplates] = React.useState<IWidget[]>([]);
  const [search, setSearch] = React.useState(undefined);
  const initialWidgetLimit = React.useMemo(() => (defaultWidget ? 3 : 4), [defaultWidget]);
  const widgetsLimitRef = React.useRef(initialWidgetLimit);
  const [totalWidgetsCount, setTotalWidgetsCount] = React.useState(0);
  const classes = useStyles(props);
  const [filterOptions, setFilterOptions] = React.useState<ICategoryOption[]>([
    {
      categoryKey: "sort",
      options: [
        {
          label: I18n.t("widget_picker.left_pane.filter.a_z"),
          optionKey: "a_z",
          value: {
            field: "name",
            direction: "asc",
          },
        },
        {
          label: I18n.t("widget_picker.left_pane.filter.z_a"),
          optionKey: "z_a",
          value: {
            field: "name",
            direction: "desc",
          },
        },
        {
          label: I18n.t("widget_picker.left_pane.filter.newest"),
          optionKey: "newest",
          value: {
            field: "updatedAt",
            direction: "desc",
          },
          checked: true,
        },
      ],
    },
    {
      categoryKey: "widget_type",
      options: [
        {
          label: I18n.t("widget_picker.left_pane.filter.all_widgets"),
          value: "all",
          optionKey: "all",
          checked: true,
          closeOnClick: true,
        },
        {
          label: I18n.t("widget_picker.left_pane.filter.your_widgets"),
          value: "widgets",
          optionKey: "widgets",
          closeOnClick: true,
        },
        {
          label: I18n.t("widget_picker.left_pane.filter.templates"),
          value: "templates",
          optionKey: "templates",
          closeOnClick: true,
        },
      ],
    },
  ]);

  const [showWidgets, showTemplates, showWidgetTypeFilter] = React.useMemo(() => {
    const showWidgetTypeFilter = filterOptions
      .find((f) => f.categoryKey === "widget_type")
      .options.find((o) => o.checked)?.value;
    return [
      ["all", "widgets"].includes(showWidgetTypeFilter),
      ["all", "templates"].includes(showWidgetTypeFilter),
      showWidgetTypeFilter,
    ];
  }, [filterOptions]);

  const handleFilterChange = ({ categoryKey, option: updatedOption }) => {
    setFilterOptions((prev) => {
      const newOptions = prev.map((category) => {
        if (category.categoryKey === categoryKey) {
          if (categoryKey === "widget_type") {
            return {
              ...category,
              options: category.options.map((o) =>
                o.optionKey === updatedOption.optionKey ? updatedOption : { ...o, checked: false }
              ),
            };
          }

          const uncheckOtherOptions = (
            updatedOption.checked && ["a_z", "z_a"].includes(updatedOption.optionKey) ? ["a_z", "z_a"] : []
          ).filter((o) => o !== updatedOption.optionKey);
          const sortOptions = category.options.map((o) => {
            if (o.optionKey === updatedOption.optionKey) {
              return updatedOption;
            }
            if (uncheckOtherOptions.includes(o.optionKey)) {
              return { ...o, checked: false };
            }
            return o;
          });

          return {
            ...category,
            options: sortOptions,
          };
        }
        return category;
      });
      return newOptions;
    });
  };

  const getWidgets = async ({ loadingMore }: { loadingMore?: boolean } = {}) => {
    if (loadingMore) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    const sortFilter = filterOptions.find((category) => category.categoryKey === "sort").options;
    const sortNameFilter = sortFilter.find((o) => o.value?.field === "name" && o.checked)?.value?.direction;

    const params = [
      selectedWebsite,
      search || "",
      selectedType === "All"
        ? [eWidgetType.CUSTOM_WIDGET, eWidgetType.FORM, eWidgetType.INLINE]
        : selectedType === "Texts"
        ? [eWidgetType.INLINE]
        : [selectedType],
      selectedType === "Texts"
        ? [eWidgetSubtype.TEXT_CTA, eWidgetSubtype.RELATED_CONTENT]
        : selectedType === eWidgetType.INLINE
        ? ["empty"]
        : (undefined as any),
      sortNameFilter,
      sortFilter.find((o) => o.value?.field === "updatedAt" && o.checked)?.value?.direction || "asc",
    ] as const;

    let newWidgets = null;
    let newTemplates = null;
    await Promise.all([
      agent.Widgets.getAutoComplete(...params, widgetsLimitRef?.current, true)
        .then((res) => {
          setWidgets(res.widgets);
          setTotalWidgetsCount(res.totalCount);
          newWidgets = res.widgets;
        })
        .catch((e) => {
          console.log(e);
        }),
      agent.Widgets.getTemplates(...params)
        .then((res) => {
          setTemplates(res.templates);
          newTemplates = res.templates;
        })
        .catch((e) => {
          console.log("Error", e);
        }),
    ]).then(() => {
      setLoading(false);
      setLoadingMore(false);
    });

    if (defaultWidget) {
      handlePropertyChange({ selectedWidget: defaultWidget });
    } else if (newWidgets.length > 0 && showWidgets && !loadingMore) {
      handlePropertyChange({ selectedWidget: newWidgets[0] });
    } else if (newTemplates.length > 0 && showTemplates && !loadingMore) {
      handlePropertyChange({ selectedWidget: newTemplates[0] });
    }
  };

  React.useEffect(() => {
    widgetsLimitRef.current = initialWidgetLimit;
    if (
      filterOptions.find((fo) => fo.categoryKey === "widget_type").options.find((o) => o.checked).value === "widgets" ||
      search
    ) {
      widgetsLimitRef.current = 20;
    }

    getWidgets();
  }, [selectedType, filterOptions, search]);

  React.useEffect(() => {
    if (!selectedWidget || selectedWidget?.status === "Deleted") {
      if (widgetsLimitRef.current === initialWidgetLimit) {
        getWidgets();
        return;
      }

      const newWidgets = widgets.filter((w) => w._id !== selectedWidget?._id);
      const newTemplates = templates.filter((w) => w._id !== selectedWidget?._id);
      setWidgets(newWidgets);
      setTemplates(newTemplates);
      const newSelectedWidget = newWidgets[0] || newTemplates[0];
      if (newSelectedWidget) {
        handlePropertyChange({ selectedWidget: newSelectedWidget });
      }
      return;
    }

    if (selectedWidget.isTemplate) {
      setTemplates((prev) => {
        const widgetIndex = prev.findIndex((w) => w._id === selectedWidget._id);
        if (widgetIndex !== -1) {
          prev[widgetIndex] = selectedWidget;
          return prev;
        } else {
          return [selectedWidget, ...prev];
        }
      });
    } else {
      setWidgets((prev) => {
        const widgetIndex = prev.findIndex((w) => w._id === selectedWidget._id);
        if (widgetIndex !== -1) {
          prev[widgetIndex] = selectedWidget;
          return prev;
        } else {
          return [selectedWidget, ...prev];
        }
      });
    }
  }, [selectedWidget]);

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.typesWrapper}>
        {[
          "All",
          eWidgetType.CUSTOM_WIDGET,
          eWidgetType.FORM,
          eWidgetType.INLINE,
          "Texts",
          ...(includeDialogs ? [eWidgetType.DIALOG] : []),
        ].map((type) => (
          <div
            key={type}
            className={classNames(classes.type, selectedType === type && classes.selectedType)}
            onClick={() =>
              selectedType !== type &&
              handlePropertyChange({
                selectedType: type as IWidgetPickerState["selectedType"],
              })
            }
          >
            {["All", "Texts"].includes(type) ? type : WidgetTypeAlias[type]}
          </div>
        ))}
      </div>
      <div className={classes.searchWrapper}>
        <MTextFieldV2
          className={classes.searchInput}
          onDebounceChange={setSearch}
          placeholder={I18n.t("widget_picker.left_pane.search_placeholder")}
          endAdornment={<SearchIcon />}
        />
        <MCategorizedOptionsFilter options={filterOptions} handleChange={handleFilterChange} zIndex={1300} />
      </div>
      {loading ? (
        <div className={classes.loaderWrapper}>
          <CfProgressBar />
        </div>
      ) : !defaultWidget &&
        ((widgets.length === 0 && templates.length === 0) ||
          (showWidgetTypeFilter === "widgets" && widgets.length === 0) ||
          (showWidgetTypeFilter === "templates" && templates.length === 0)) ? (
        <div className={classes.notFoundWrapper}>
          <Typography className={classes.title}>{I18n.t("widget_picker.left_pane.no_widgets")}</Typography>
        </div>
      ) : (
        <div className={classes.listsContainer}>
          {showWidgets && (defaultWidget || widgets.length > 0) && (
            <div>
              <div className={classes.listWrapper}>
                <Typography className={classes.title}>{I18n.t("widget_picker.left_pane.your_widgets")}</Typography>
                <div className={classes.itemsWrapper}>
                  {(defaultWidget
                    ? [defaultWidget, ...widgets.filter((w) => w._id !== defaultWidget._id)]
                    : widgets
                  ).map((widget) => (
                    <div
                      className={classNames(
                        classes.widgetCard,
                        selectedWidget?._id === widget._id && classes.selectedWidgetCard,
                        widget._id === defaultWidget?._id && classes.defaultWidgetCard
                      )}
                      onClick={() => handlePropertyChange({ selectedWidget: widget })}
                      key={widget._id}
                    >
                      <img className={classes.widgetImg} src={widget.thumbnail} />
                      <Tooltip enterDelay={500} title={widget.name}>
                        <Typography className={classes.widgetTitle}>{widget.name}</Typography>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
              {loadingMore ? (
                <div className={classes.inlineLoaderWrapper}>
                  <CfProgressBar />
                </div>
              ) : widgetsLimitRef?.current < totalWidgetsCount ? (
                <Typography
                  className={classes.showMore}
                  onClick={() => {
                    widgetsLimitRef.current += 10;
                    getWidgets({ loadingMore: true });
                  }}
                >
                  {I18n.t("widget_picker.left_pane.show_more")}
                </Typography>
              ) : (
                totalWidgetsCount >= initialWidgetLimit && (
                  <Typography
                    className={classes.showMore}
                    onClick={() => {
                      widgetsLimitRef.current = initialWidgetLimit;
                      const newWidgets = widgets.slice(0, initialWidgetLimit);
                      const widgetIndex = newWidgets.findIndex((w) => w._id === selectedWidget._id);
                      if (widgetIndex === -1) {
                        handlePropertyChange({ selectedWidget: newWidgets[0] });
                      }
                      setWidgets(newWidgets);
                    }}
                  >
                    {I18n.t("widget_picker.left_pane.show_less")}
                  </Typography>
                )
              )}
            </div>
          )}
          {showTemplates && templates.length > 0 && (
            <div className={classes.listWrapper}>
              <Typography className={classes.title}>{I18n.t("widget_picker.left_pane.templates")}</Typography>
              <div className={classes.itemsWrapper}>
                {templates.map((template) => (
                  <div
                    className={classNames(
                      classes.widgetCard,
                      selectedWidget?._id === template._id && classes.selectedWidgetCard
                    )}
                    onClick={() => handlePropertyChange({ selectedWidget: template })}
                    key={template._id}
                  >
                    <img className={classes.templateImg} src={template.thumbnail} />
                    <Tooltip enterDelay={500} title={template.name}>
                      <Typography className={classes.widgetTitle}>{template.name}</Typography>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetPickerLeftPane);
