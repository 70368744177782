import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import Typography from "@material-ui/core/Typography";
import { Collapse } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 0",
    borderTop: `solid 1px ${theme.palette.divider}`,
    "&:first-child": {
      borderTop: "none",
    },
    "&:last-child": {
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  },
  expandIcon: {
    marginRight: 7,
    fontSize: theme.typography.pxToRem(20),
  },
  sectionNameWrap: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  sectionName: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  subTabWrapper: {
    "& .MuiCollapse-wrapper": {
      padding: "10px 15px",
    },
  },
}));

type PropTypes = {
  openByDefault?: boolean;
  sectionTitle: string;
  children: string | React.ReactNode;
};

const PageEditorExpandableSideSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const [sectionOpen, setSectionOpen] = React.useState<boolean>(props.openByDefault || false);

  return (
    <section className={classes.section}>
      <div className={classes.sectionNameWrap} onClick={() => setSectionOpen(!sectionOpen)}>
        {sectionOpen ? (
          <ExpandMoreRoundedIcon className={classes.expandIcon} />
        ) : (
          <NavigateNextRoundedIcon className={classes.expandIcon} />
        )}
        <Typography className={classes.sectionName} variant="body1">
          {props.sectionTitle}
        </Typography>
      </div>
      <Collapse in={sectionOpen} className={classes.subTabWrapper}>
        {props.children}
      </Collapse>
    </section>
  );
};

export default PageEditorExpandableSideSection;
