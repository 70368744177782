import { Theme, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import TextFieldProperty from "./itemProperties/components/TextFieldProperty";
import { eWidgetItemsAlignment, IWidgetButtonProps } from "../../../../../../../reducers/constants/objectTypes";
import SelectProperty from "./itemProperties/components/SelectProperty";
import SwitchProperty from "./itemProperties/components/SwitchProperty";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: ({ noPadding }: PropTypes) => ({
    padding: !noPadding ? 20 : 0,
  }),
  container: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    marginBottom: 15,
  },
  properties: {},
  items: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: `1px solid ${palette.grey["100"]}`,
    width: "100%",
    borderRadius: 3,
    overflow: "hidden",
    gap: 10,
  },
  divider: {
    border: `0.5px solid ${palette.grey["100"]}`,
  },
  leftContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: 300,
    color: palette.text.primary,
    marginLeft: -8,
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: 300,
  },
  button: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    fontSize: 14,
    textTransform: "none",
    height: 30,
    padding: 10,
    borderRadius: "20px",
    fontWeight: typography.fontWeightMedium as any,
    "&:hover": {
      backgroundColor: palette.primary.light,
      color: palette.common.white,
    },
    "&:disabled": {
      borderColor: palette.primary.dark,
      color: palette.primary.dark,
      backgroundColor: palette.action.selected,
      cursor: "initial",
    },
  },
  label: {
    fontSize: 14,
    fontWeight: typography.fontWeightMedium as any,
  },
  field: {
    padding: "0px 15px 0px 15px",
    height: 55,
  },
}));

type PropTypes = {
  button?: IWidgetButtonProps;
  noPadding?: boolean;
  editButton: (submitButton: IWidgetButtonProps) => void;
  onClose(): void;
};

const ButtonWidgetItem = (props: PropTypes) => {
  const { onClose, editButton } = props;
  const { button } = props;
  const classes = useStyles(props);
  const [text, setText] = React.useState(button?.text || "Submit");
  const [href, setHref] = React.useState(button?.href || "");
  const [showArrow, setShowArrow] = React.useState<boolean>(button?.showArrow || true);
  const [alignment, setAlignment] = React.useState(button?.alignment || "Left");

  function onDone() {
    editButton({ ...button, text, showArrow, alignment, href });
    onClose();
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className={classes.leftContainer}
          >
            <KeyboardArrowLeftRounded />
            <Typography className={classes.label}>
              {I18n.t("widgets_builder.widget_editor.Form.fields.properties.submit_button")}
            </Typography>
          </div>
          <div className={classes.rightContainer}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onDone();
              }}
              className={classes.button}
            >
              {I18n.t("widgets_builder.build.item_done_button")}
            </Button>
          </div>
        </div>
        <div className={classes.properties}>
          <div className={classes.items}>
            <TextFieldProperty
              label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.text")}
              placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.text_ph")}
              onChange={(val) => setText(val)}
              value={text}
            />
            <TextFieldProperty
              label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.url")}
              placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
              onChange={(val) => setHref(val)}
              value={href}
            />

            <SwitchProperty
              label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.show_arrow")}
              checked={showArrow}
              onChange={setShowArrow}
            />
            <SelectProperty
              label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.alignment")}
              showLabel
              onChange={(val: "Left" | "Center" | "Right") => setAlignment(val)}
              options={[eWidgetItemsAlignment.LEFT, eWidgetItemsAlignment.CENTER, eWidgetItemsAlignment.RIGHT]}
              value={alignment}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonWidgetItem;
