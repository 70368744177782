import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import PageSection from "../../components/PageSection";
import {
  IWebsiteForumProps,
  IWebsiteSectionProps,
  IWebsiteThemeProps,
  SNWebsiteAutoCompleteMetaTag,
} from "../../../../reducers/constants/objectTypes";
import WebsitesSectionsSwitch from "../../components/WebsitesSectionsSwitch";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import TextInputMentions from "../../components/TextInputMentions";
import { LENGTHS } from "../../../../reducers/constants/consts";
import TextDesignToolWithPreview from "../../components/TextDesignToolWithPreview";
import ButtonTextAndUrlAndDesign from "../../components/ButtonTextAndUrlAndDesign";
import TextInputToolAndDesign from "../../components/TextInputToolAndDesign";
import MRouterPrompt from "../../components/MRouterPrompt";
import CustomUrlPrefixTextField from "../../components/CustomUrlPrefixTextField";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

const mapStateToProps = (state) => ({
  forum: state.websitePages.forum,
  theme: state.websitePages.theme,
  sections: state.websitePages.sections,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (forum) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "forum",
      value: forum,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  theme: IWebsiteThemeProps;
  forum: IWebsiteForumProps;
  sections: IWebsiteSectionProps;
  urlPrefix: string;
  onPropertyChanged: (forum) => void;
  handleToggleActive: (section, enabled) => void;
};

const PageForum = (props: PropTypes) => {
  const classes = useStyles(props);
  const { sections, forum, theme, handleToggleActive, onPropertyChanged } = props;
  const [error, setError] = React.useState(false);
  const [emptyFields, setEmptyFields] = React.useState([]);

  React.useEffect(() => {
    if (emptyFields.length <= 0 && !error) {
      return;
    }
    textFieldsValidated();
  }, [forum]);

  const textFieldsValidated = () => {
    const emptyFields = [];
    setEmptyFields([]);
    let valid = true;
    if (!forum.name) {
      valid = false;
      emptyFields.push(I18n.t(`website.name`));
    }
    if (!forum.mainPage.slug) {
      valid = false;
      emptyFields.push(I18n.t(`website.slug_title`));
    }
    if (!forum.mainPage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_title`));
    }
    if (!forum.mainPage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_description`));
    }
    if (!forum.mainPage.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.forum.main_page_title`));
    }
    if (!forum.mainPage.description.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.forum.main_page_description`));
    }
    // if (! forum.mainPage.button.text){
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.forum.main_page_button`));
    // }
    if (!forum.categoryPage.slug) {
      emptyFields.push(I18n.t(`website.forum.category_page_slug`));
    }
    if (!forum.categoryPage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.forum.category_page_meta_title`));
    }
    if (!forum.categoryPage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.forum.category_page_meta_description`));
    }
    if (!forum.postPage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.forum.post_page_meta_title`));
    }
    if (!forum.postPage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.forum.post_page_meta_description`));
    }

    setEmptyFields(emptyFields);
    return valid;
  };

  return (
    <>
      <MRouterPrompt />
      <WebsitesSectionsSwitch
        inSection
        title={I18n.t(`website.page_types.forum`)}
        enabled={sections.forum}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.forum) {
              handleToggleActive("forum", enabled);
            }
            setError(true);
          } else {
            setError(false);
            handleToggleActive("forum", enabled);
          }
        }}
      >
        <CustomUrlPrefixTextField
          handleValueChange={(value) => onPropertyChanged({ ...forum, urlPrefix: value })}
          customUrlPrefix={forum.urlPrefix}
          previewUrls={[
            {
              key: "website.forum.main_page_preview_url",
              value: forum.mainPage.slug,
              valid: sections.forum,
            },
            {
              key: "website.forum.category_page_preview_url",
              value: `${forum.categoryPage.slug}/category-slug`,
            },
            {
              key: "website.forum.post_page_preview_url",
              value: `${forum.mainPage.slug}/category-slug/post-slug`,
            },
          ]}
        />
      </WebsitesSectionsSwitch>
      {error && (
        <Typography variant={"body1"} className={classes.error}>
          {emptyFields.length > 0 && I18n.t("website.error_message")}
        </Typography>
      )}

      <PageSection sectionTitle={I18n.t("website.forum.main_page")}>
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            error={emptyFields.includes(I18n.t(`website.name`))}
            titleAndLengthLocation={"top"}
            value={forum.name}
            placeholder={I18n.t("website.name_ph")}
            title={I18n.t("website.name")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => onPropertyChanged({ ...forum, name: value })}
          />

          <TextFieldMaxLength
            error={emptyFields.includes(I18n.t(`website.slug_title`))}
            titleAndLengthLocation={"top"}
            value={forum.mainPage.slug}
            placeholder={I18n.t("website.slug_title_ph")}
            title={I18n.t("website.slug_title")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => onPropertyChanged({ ...forum, mainPage: { ...forum.mainPage, slug: value } })}
          />
        </div>
        <Divider className={classes.divider} />
        <TextInputToolAndDesign
          error={emptyFields.includes(I18n.t(`website.forum.main_page_title`))}
          textFieldTitle={I18n.t("website.title")}
          text={forum.mainPage?.title}
          handleTextChanged={(text) => onPropertyChanged({ ...forum, mainPage: { ...forum.mainPage, title: text } })}
          variant={"h1"}
        />

        <TextInputMentions
          tags={[]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          error={emptyFields.includes(I18n.t(`website.meta_title`))}
          titleAndLengthLocation={"top"}
          text={forum.mainPage.metaTitle}
          onTextChanged={(value) => onPropertyChanged({ ...forum, mainPage: { ...forum.mainPage, metaTitle: value } })}
        />
        <Divider className={classes.divider} />
        <TextInputToolAndDesign
          textFieldTitle={I18n.t("website.description")}
          variant={"h2"}
          error={emptyFields.includes(I18n.t(`website.forum.main_page_description`))}
          text={forum.mainPage?.description}
          handleTextChanged={(text) =>
            onPropertyChanged({ ...forum, mainPage: { ...forum.mainPage, description: text } })
          }
        />

        <TextInputMentions
          tags={[]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.meta_description`))}
          text={forum.mainPage.metaDescription}
          onTextChanged={(value) =>
            onPropertyChanged({ ...forum, mainPage: { ...forum.mainPage, metaDescription: value } })
          }
        />
        <Divider className={classes.divider} />
        <ButtonTextAndUrlAndDesign
          textFieldTitle={I18n.t("website.button_text")}
          urlFieldTitle={I18n.t("website.button_url")}
          button={forum.mainPage?.button}
          defaultButtonCss={theme.buttons.css}
          handleButtonChanged={(newButton) =>
            onPropertyChanged({ ...forum, mainPage: { ...forum.mainPage, button: newButton } })
          }
        />
      </PageSection>

      <PageSection sectionTitle={I18n.t("website.forum.category_page")}>
        <TextFieldMaxLength
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.forum.category_page_slug`))}
          value={forum.categoryPage.slug}
          placeholder={I18n.t("website.slug_title_ph")}
          title={I18n.t("website.slug_title")}
          height={37}
          borderRadius={4}
          marginBottom={30}
          handleValueChange={(value) =>
            onPropertyChanged({ ...forum, categoryPage: { ...forum.categoryPage, slug: value } })
          }
        />

        <TextInputMentions
          tags={[SNWebsiteAutoCompleteMetaTag.categoryName, SNWebsiteAutoCompleteMetaTag.year]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.forum.category_page_meta_title`))}
          text={forum.categoryPage.metaTitle}
          onTextChanged={(value) =>
            onPropertyChanged({
              ...forum,
              categoryPage: { ...forum.categoryPage, metaTitle: value },
            })
          }
        />

        <TextInputMentions
          tags={[
            SNWebsiteAutoCompleteMetaTag.categoryName,
            SNWebsiteAutoCompleteMetaTag.categoryDescription,
            SNWebsiteAutoCompleteMetaTag.year,
          ]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.forum.category_page_meta_description`))}
          text={forum.categoryPage.metaDescription}
          onTextChanged={(value) =>
            onPropertyChanged({
              ...forum,
              categoryPage: { ...forum.categoryPage, metaDescription: value },
            })
          }
        />
      </PageSection>

      <PageSection sectionTitle={I18n.t("website.forum.post_page")}>
        <TextDesignToolWithPreview
          topTitle
          disableHover
          marginLeft={"-30px"}
          marginRight={"-30px"}
          elementTitle={I18n.t("website.element_design", { element: I18n.t("website.title") })}
          css={forum.postPage.title?.css}
          variant={"h1"}
          exampleText={I18n.t("website.forum.example_title_text")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...forum,
              postPage: { ...forum.postPage, title: { ...forum.postPage.title, css: newCss } },
            });
          }}
        />

        <TextInputMentions
          tags={[SNWebsiteAutoCompleteMetaTag.postTitle, SNWebsiteAutoCompleteMetaTag.year]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.forum.post_page_meta_title`))}
          text={forum.postPage.metaTitle}
          onTextChanged={(value) => onPropertyChanged({ ...forum, postPage: { ...forum.postPage, metaTitle: value } })}
        />

        <TextInputMentions
          tags={[
            SNWebsiteAutoCompleteMetaTag.postTitle,
            SNWebsiteAutoCompleteMetaTag.questionText,
            SNWebsiteAutoCompleteMetaTag.answerText,
            SNWebsiteAutoCompleteMetaTag.year,
          ]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.forum.post_page_meta_description`))}
          text={forum.postPage.metaDescription}
          onTextChanged={(value) =>
            onPropertyChanged({ ...forum, postPage: { ...forum.postPage, metaDescription: value } })
          }
        />
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageForum);
