import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useLocation } from "react-router";
import { I18n } from "react-redux-i18n";

import ListHeader from "./components/ListHeader";
import agent from "../../../agent";
import { eVideoProjectState, ICategory, IVideoProject, IWebsite } from "../../../reducers/constants/objectTypes";
import ItemList from "./components/ItemList";
import { VIDEO_PROJECT } from "../../../reducers/constants/actionTypes";
import useDebounce from "../../../hooks/useDebounce";
import { web } from "../../../helpers/urlHelper";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";
import { videoProjectStates } from "../../../helpers/constants";
import ability from "../../../casl/ability";
import MTable from "../../../components/Table/Table/MTable";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";

type PropTypes = {
  state?: eVideoProjectState;
  selectedWebsite: IWebsite;
  totalItems: number;
  totalPages: number;
  page: number;
  limit: number;
  refreshVideoProjects: boolean;
  videoProjectsSortField: { direction: "desc" | "asc" | undefined; field: string };
  categories: ICategory[];

  loadVideoProjects: (payload) => void;
  updateField: (fieldName, fieldValue) => void;
  loadFailed: () => void;
  startLoading: () => void;
  openVideoEditor: (videoProject: IVideoProject, currentUrl: string) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  categories: state.category.categories,

  totalItems: state.videoWorkFlow.totalItems,
  totalPages: state.videoWorkFlow.totalPages,
  refreshVideoProjects: state.videoWorkFlow.refreshVideoProjects,
  page: state.videoWorkFlow.page,
  limit: state.videoWorkFlow.limit,
  videoProjectsSortField: state.videoWorkFlow.videoProjectsSortField,
});

const mapDispatchToProps = (dispatch) => ({
  loadVideoProjects: (payload) => dispatch({ type: VIDEO_PROJECT.PROJECTS_LOADED, payload }),
  startLoading: () => dispatch({ type: VIDEO_PROJECT.PROJECTS_LOADING }),
  loadFailed: () => dispatch({ type: VIDEO_PROJECT.PROJECTS_LOAD_FAILED }),
  updateField: (fieldName, fieldValue) => dispatch({ type: VIDEO_PROJECT.UPDATE_FIELD, fieldName, fieldValue }),
  openVideoEditor: (videoProject: IVideoProject, currentUrl: string) =>
    dispatch(push(web.videoWorkflowEditVideo(videoProject._id, currentUrl))),
});

const VideoWorkFlowState = (props: PropTypes) => {
  const {
    state,
    selectedWebsite,
    refreshVideoProjects,
    categories,
    page,
    limit,
    videoProjectsSortField,
    totalPages,
    loadVideoProjects,
    startLoading,
    updateField,
    loadFailed,
    openVideoEditor,
  } = props;

  const [searchText, setSearchText] = useState<string>("");
  const [category, setCategory] = useState<ICategory[]>([]);
  const location = useLocation();
  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    if (selectedWebsite) {
      startLoading();

      const allowedStates = [];

      videoProjectStates.forEach((s) => {
        if (ability.can("view", `video_workflow_${s}_tab`)) {
          allowedStates.push(s);
        }
      });

      const projectStates = state ? [state] : allowedStates;

      loadVideoProjects(
        agent.VideoProjects.getAll(
          selectedWebsite,
          page,
          limit,
          videoProjectsSortField.field,
          videoProjectsSortField.direction,
          projectStates,
          searchText,
          category.map((c) => c.id).join(",")
        )
          .then((res) => res)
          .catch(() => loadFailed())
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedWebsite,
    refreshVideoProjects,
    category,
    page,
    limit,
    debouncedSearchText,
    videoProjectsSortField,
    state,
  ]);

  console.log("Render VideoWorkFlowState");
  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.video_cms.${state || "AllVideos"}.title`)} />
      <MTable>
        <ListHeader
          selectedWebsite={selectedWebsite}
          category={category}
          searchText={searchText}
          setSearchText={setSearchText}
          setCategory={setCategory}
          categories={categories}
          handleVideoProjectCreated={(videoProject) => openVideoEditor(videoProject, location.pathname)}
          refreshList={() => updateField("refreshVideoProjects", true)}
          state={state}
        />
        <ItemList state={state} refreshList={() => updateField("refreshVideoProjects", true)} />
      </MTable>

      {totalPages > 0 && (
        <MTablePagination
          paginationLimit={limit}
          page={page}
          paginationPagesCount={totalPages}
          setPaginationLimit={(value) => updateField("pageSize", value)}
          setPage={(value) => updateField("page", value)}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoWorkFlowState);
