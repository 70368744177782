import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, TypographyVariant } from "@material-ui/core/styles";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import { snakeCase } from "lodash";
import { ePageState, IPageState, IWebsite, IWebsiteReviewStep } from "../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  status: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: theme.typography.pxToRem(14),
  },
  green: {
    color: "#00cb72",
  },
  red: {
    color: "#ff0576",
  },
  blue: {
    color: "#4353ff",
  },
  violet: {
    color: "darkviolet",
  },
  purple: {
    color: "purple",
  },
}));

type PropTypes = {
  currentState: IPageState | null;
  variant?: TypographyVariant;
  reviewSteps: IWebsiteReviewStep[];
};

const MPageStatusTypography = (props: PropTypes) => {
  const classes = useStyles(props);
  const { currentState, variant = "body2", reviewSteps } = props;

  const getClass = () => {
    switch (currentState?.state) {
      case ePageState.review:
      case ePageState.factCheck:
        return classes.violet;
      case ePageState.edit:
      case ePageState.secondEdit:
        return classes.purple;
      case ePageState.published:
        return classes.green;
      case ePageState.deleted:
      case ePageState.archived:
        return classes.red;
      case ePageState.writer:
        return classes.blue;
      default:
        return null;
    }
  };

  return (
    <Typography className={ClassNames(classes.status, getClass())} variant={variant}>
      {currentState.subState
        ? reviewSteps.find((r) => r.reviewStepName === currentState.subState)?.reviewName
        : I18n.t(`article.state.${snakeCase(currentState.state)}`)}
    </Typography>
  );
};

export default React.memo(MPageStatusTypography);
