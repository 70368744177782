import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { push, replace } from "connected-react-router";
import { Allotment } from "allotment";
import { Editor, Path } from "slate";
import { ReactEditor } from "slate-react";
import { ePageState, eUserRoles, IPage, IPageCluster, IWebsite } from "../../reducers/constants/objectTypes";
import { PAGE_EDITOR } from "../../reducers/constants/actionTypes";
import agent from "../../agent";
import { web } from "../../helpers/urlHelper";
import PageEditorActions from "./editorActions/PageEditorActions";
import PageEditorHeader from "./PageEditorHeader";
import PageEditorWYSIWYG from "./PageEditorWYSIWYG";
import "allotment/dist/style.css";
import PageEditorAddons from "./PageEditorAddons";
import { editorType, ForcedLayout } from "../../editor/types/editor.Types";
import { insertFAQs } from "../../editor/elements/widgets/faq/FAQWidget";
import RichTextEditorHelper from "../../editor/helper/richTextEditorHelper";
import ability from "../../casl/ability";
import PageEditorRightSideActions from "./editorActions/PageEditorRightSideActions";
import { colors } from "../../helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  // editor: {
  //   height: '100%',
  //   flexBasis: 0,
  //   flexGrow: 1,
  //   borderRight: `solid 1px ${theme.palette.divider}`,
  // },
  editorWrapper: {
    backgroundColor: "#F5F6F8",
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  leftPlaceholder: {
    height: "100%",
    flex: 1,
    maxWidth: 400,
    overflowY: "scroll",
  },
  mainWrapper: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    flex: 1,
    position: "relative",
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  wysiwygWrapper: {
    overflowY: "auto",
    height: "100%",
    backgroundColor: colors.pageEditorBackgroundColor,
  },
  componentsWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 50px)",
  },
}));

type PropTypes = {
  returnTo?: string;
  role: "WRITER" | "EDITOR" | "CUSTOMER";

  selectedWebsite: IWebsite;
  pageLoaded: boolean;
  pageCluster: IPageCluster;
  page: IPage;
  pageRichText: string;

  handleEditorChanged: (editor: ReactEditor) => void;
  handlePageChanged: (page: IPage) => void;
  returnToPreviousScreen: (url: string) => void;
  handlePageLoaded: (page: IPage, cluster: IPage) => void;
  handlePageTextChanged: (richText: string) => void;
  cleanUp: () => void;
  editPage: (id: string, currentUrl: string) => void;
  redirectToAllStates: () => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  pageLoaded: state.pageEditor.pageLoaded,
  pageCluster: state.pageEditor.pageCluster,
  page: state.pageEditor.page,
  pageRichText: state.pageEditor.pageRichText,
});

const mapDispatchToProps = (dispatch) => ({
  handlePageLoaded: (page: IPage, cluster: IPageCluster) => dispatch({ type: PAGE_EDITOR.PAGE_LOADED, page, cluster }),
  handlePageChanged: (page: IPage) => dispatch({ type: PAGE_EDITOR.PAGE_CHANGED, page }),
  handleEditorChanged: (editor: ReactEditor) => dispatch({ type: PAGE_EDITOR.EDITOR_CHANGED, editor }),
  handlePageTextChanged: (richText) => dispatch({ type: PAGE_EDITOR.PAGE_TEXT_CHANGED, richText }),
  cleanUp: () => dispatch({ type: PAGE_EDITOR.CLEANUP }),
  returnToPreviousScreen: (returnTo) => dispatch(replace(returnTo)),
  editPage: (id: string, currentUrl: string) => dispatch(push(web.editPage(id, currentUrl))),
  redirectToAllStates: () => dispatch(push(web.workflowAllStates())),
});

const PageEditor = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    role,
    selectedWebsite,
    pageLoaded,
    pageCluster,
    page,
    pageRichText,
    returnTo,
    cleanUp,
    handlePageLoaded,
    handlePageChanged,
    handlePageTextChanged,
    returnToPreviousScreen,
    editPage,
    redirectToAllStates,
    handleEditorChanged,
  } = props;
  const { pageId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const forceElements: ForcedLayout[] = React.useMemo(
    () =>
      page?.type === "niProductReview"
        ? [
            {
              type: editorType.faq,
              insert: (editor: Editor, at: Path) => insertFAQs(editor, at, true),
              name: "FAQ",
            },
            {
              type: editorType.niProsCons,
              name: "Pros/Cons",
              insert: (editor, at: Path) => RichTextEditorHelper.insertVoidElement(editor, editorType.niProsCons, at),
            },
          ]
        : [],
    [page?.type]
  );

  // console.log("forceElements", forceElements);
  const returnToPreviousPage = () => {
    console.log("returnTo", returnTo, location.resources);
    if (returnTo && returnTo !== location.resources) {
      console.log("returnTo --->", returnTo);
      returnToPreviousScreen(returnTo);
      return;
    }
    console.log("returnTo ---> write or review");
    let returnToURL = web.workflowFactCheck();
    if (role === "WRITER") {
      returnToURL = web.workflowWrite();
    }
    if (role === "EDITOR") {
      returnToURL = web.workflowEdit();
    }

    returnToPreviousScreen(returnToURL);
  };

  const exitScreen = () => {
    console.log("exitScreen", location.state?.publicPost);
    if (!location.state?.publicPost) {
      returnToPreviousPage();
      return;
    }

    returnToPreviousPage();
    // TODO: discard post to writer that hasn't been assigned by editor
    // if (post.state === ePostState.NEW && post.writer !== null) {
    // 	console.log('Remove writer from post');
    // 	agent.EditPost.discardAnswer(false, ePostType.BLOG, post, 'writer')
    // 		.then(res => {
    // 			returnToPreviousPage();
    // 		}).catch(e => {
    // 		returnToPreviousPage();
    // 	});
    // } else {
    // 	returnToPreviousPage();
    // }
  };

  React.useEffect(() => {
    console.log("download page", pageId);
    agent.Pages.getPage(selectedWebsite, pageId)
      .then((res) => {
        handlePageLoaded(res.page, res.cluster);
      })
      .catch((e) => {
        console.error("Error", e);
        enqueueSnackbar(e?.response?.body?.message || "Something went wrong");
        exitScreen();
      });
    return () => {
      cleanUp();
    };
  }, [handlePageLoaded, pageId]);

  if (page?.currentState.state === ePageState.archived) {
    const newVersionPage =
      pageCluster?.pages.find(
        (pageItem) =>
          pageItem.variant === page.variant &&
          pageItem.locale === page.locale &&
          pageItem.currentState.state !== ePageState.archived
      ) || null;

    if (newVersionPage) editPage(newVersionPage._id, location.pathname);
    else redirectToAllStates();
  }

  const withCustomElements = React.useMemo(
    () => page && !["niArticle", "wordpress", "niProductReview"].includes(page.type),
    [page]
  );
  return (
    <div className={classes.editorWrapper}>
      <PageEditorHeader
        selectedWebsite={selectedWebsite}
        pageLoaded={pageLoaded}
        pageCluster={pageCluster}
        page={page}
        exitScreen={exitScreen}
        returnToPreviousScreen={returnToPreviousPage}
      />
      {pageLoaded && (
        <div className={classes.componentsWrapper}>
          <Allotment>
            <Allotment.Pane preferredSize={390} minSize={250} maxSize={500}>
              <PageEditorActions
                selectedWebsite={selectedWebsite}
                page={page}
                withCustomElements={withCustomElements}
                disableAi={false}
              />
            </Allotment.Pane>
            <Allotment.Pane>
              <div className={classes.mainWrapper}>
                <div className={classes.wysiwygWrapper}>
                  <PageEditorWYSIWYG
                    withCustomElements={withCustomElements}
                    disableAi={!ability.can(eUserRoles.EDITOR, "role")}
                    selectedWebsite={selectedWebsite}
                    pageLoaded={pageLoaded}
                    page={page}
                    pageRichText={pageRichText}
                    handlePageChanged={handlePageChanged}
                    handlePageTextChanged={handlePageTextChanged}
                    forcedElements={forceElements}
                    handleEditorChanged={handleEditorChanged}
                  />
                  <PageEditorAddons pageLoaded={pageLoaded} pageCluster={pageCluster} page={page} />
                </div>
              </div>
            </Allotment.Pane>
            <Allotment.Pane preferredSize={295} minSize={200} maxSize={400}>
              <PageEditorRightSideActions page={page} />
            </Allotment.Pane>
          </Allotment>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditor);
