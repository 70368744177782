import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { TableSortLabel } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  icon: { color: `${theme.palette.text.primary} !important` },
}));

type PropTypes = {
  columnWidth?: number | string;
  fontSize?: number | string;
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  defaultSort?: "desc" | "asc";
  field: string;
  children: string | React.ReactNode;
  className?: string;
  onClick: (sortField: { field: string; direction: "desc" | "asc" }) => void;
};

const MTableHeaderSortLabel = (props: PropTypes) => {
  const { children, defaultSort = "desc", sortField, className, onClick, field, fontSize, columnWidth } = props;
  const classes = useStyles(props);
  return (
    <Typography
      className={ClassNames(classes.wrapper, className)}
      style={{
        fontSize,
        flexGrow: columnWidth ? undefined : 1,
        flexBasis: columnWidth ? undefined : 0,
        width: columnWidth,
      }}
      variant={"subtitle2"}
    >
      <TableSortLabel
        // key={field}
        disabled={sortField === undefined}
        classes={{ icon: classes.icon }}
        active={field === sortField?.field}
        direction={field === sortField?.field ? sortField.direction : defaultSort}
        onClick={() => {
          const direction =
            field === sortField?.field ? (sortField?.direction === "asc" ? "desc" : "asc") : defaultSort;

          onClick({
            field,
            direction,
          });
        }}
      >
        {children}
      </TableSortLabel>
    </Typography>
  );
};

export default MTableHeaderSortLabel;
