import { Typography, Slider, InputAdornment, FormControl, Input, makeStyles, Theme } from "@material-ui/core";
import ClassNames from "classnames";
import * as React from "react";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: 14,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  inputControl: {
    width: 80,
  },
  input: {
    textAlign: "right",
    padding: 0,
    fontSize: 14,
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  inputAdornment: {
    marginLeft: 1,
    "& p": {
      fontSize: 14,
    },
  },
  slider: {
    color: palette.primary.light,
  },
}));

type PropTypes = {
  min?: number;
  max: number;
  label: string;
  adornmentText?: string;
  value: number;
  customClass?: string;
  onChange: (value: number) => void;
};

export default function InputSlider(props: PropTypes) {
  const { value, min = 0, max = 100, label, adornmentText, customClass } = props;
  const { onChange } = props;

  const classes = useStyles();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value === "" ? min : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < min) {
      onChange(min);
    } else if (value > max) {
      onChange(max);
    }
  };

  return (
    <div className={ClassNames(classes.root, customClass)}>
      <div className={classes.container}>
        <Typography id="input-slider" className={classes.label}>
          {label}
        </Typography>

        <FormControl className={classes.inputControl}>
          <Input
            className={classes.input}
            classes={{ input: classes.input }}
            endAdornment={
              <InputAdornment className={classes.inputAdornment} position="end">
                {adornmentText}
              </InputAdornment>
            }
            disableUnderline={true}
            type="number"
            onChange={handleInputChange}
            onBlur={handleBlur}
            value={value}
          />
        </FormControl>
      </div>

      <Slider
        value={typeof value === "number" ? value : 0}
        onChange={(e, newValue: number) => onChange(newValue)}
        min={min}
        max={max}
        aria-labelledby="input-slider"
        className={classes.slider}
      />
    </div>
  );
}
