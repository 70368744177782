import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import OutlinePagesList from "./components/OutlinePagesList";
import { OUTLINE } from "../../reducers/constants/actionTypes";
import { web } from "../../helpers/urlHelper";
import { ePageType, IOutline, IWebsite } from "../../reducers/constants/objectTypes";
import agent from "../../agent";
import MTableWrapper from "../../components/Table/MTableWrapper";
import MTableToolbar from "../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarBaseActionButton from "../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import MHeaderMetaTags from "../../components/MHeaderMetaTags";

type PropTypes = {
  view?: "DATA_VIEW" | "EDITOR_VIEW";
  searchText: string;
  selectedWebsite: IWebsite;
  handleSearchTextChanged: (searchText) => void;
  openOutlinePage: (outline: IOutline, view: "DATA_VIEW" | "EDITOR_VIEW") => void;
  metaTitle?: string;
};

const mapStateToProps = (state) => ({
  searchText: state.outline.outlinePagesSearchText,
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  handleSearchTextChanged: (searchText) => {
    dispatch({ type: OUTLINE.ON_OUTLINE_PAGES_SEARCH_TEXT_CHANGED, searchText });
  },
  openOutlinePage: (outlinePage, view) =>
    dispatch(push(view === "DATA_VIEW" ? web.outlines(outlinePage._id) : web.workflowOutlines(outlinePage._id))),
});

const OutlineList = (props: PropTypes) => {
  const {
    searchText,
    view = "DATA_VIEW",
    selectedWebsite,
    handleSearchTextChanged,
    openOutlinePage,
    metaTitle = I18n.t("meta_tags.strategy.outline.title"),
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const toolBarActions = [];
  if (view === "DATA_VIEW") {
    toolBarActions.push(
      <MTableToolbarBaseActionButton
        handleClick={() => {
          agent.Outlines.newOutline({ website: selectedWebsite, postType: ePageType.Blog })
            .then((res) => {
              openOutlinePage(res.outline, view);
            })
            .catch((e) => {
              enqueueSnackbar(I18n.t("snackbar.save.update_error", { msg: e.message }));
            });
        }}
        text={I18n.t("outline.new_outline")}
      />
    );
  }

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={metaTitle} />
      <MTableToolbar
        cmsCreateButton
        searchText={searchText}
        handleSearchTextChanged={(text) => handleSearchTextChanged(text)}
        actions={toolBarActions}
      />
      <OutlinePagesList view={view} website={selectedWebsite} />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlineList);
