import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import ExcludeKeywordsList from "./components/ExcludeKeywordsList";
import { EXCLUDE_KEYWORDS } from "../../reducers/constants/actionTypes";
import MTableWrapper from "../../components/Table/MTableWrapper";
import MTableToolbar from "../../components/Table/Toolbar/MTableToolbar";
import { IExcludedKeywordSeed, IWebsite } from "../../reducers/constants/objectTypes";
import MTableToolbarExcludeKeywordsTypeFilter from "../../components/Table/Toolbar/Filters/MTableToolbarExcludeKeywordsTypeFilter";
import MTableToolbarBaseActionButton from "../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import AddKeywordsDialog from "./components/AddKeywordsDialog";
import agent from "../../agent";
import MTableToolbarActionDisplayText from "../../components/Table/Toolbar/Actions/MTableToolbarActionDisplayText";
import MTablePagination from "../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  keywordsListLoaded: state.excludeKeywords.keywordsListLoaded,
  keywordsList: state.excludeKeywords.keywordsList,
  searchText: state.excludeKeywords.filter.searchText,
  selectedType: state.excludeKeywords.filter.selectedType,
  checkedKeywordsList: state.excludeKeywords.checkedKeywordsList,
  selectedWebsite: state.home.selectedWebsite,
  totalKeywordsCount: state.excludeKeywords.totalKeywordsCount,
});

const mapDispatchToProps = (dispatch) => ({
  handleKeywordsListLoaded: (payload) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_KEYWORDS_LIST_LOADED, payload });
  },
  handleSearchTextChanged: (searchText) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_SEARCH_TEXT_CHANGED, searchText });
  },
  handleExcludedKeywordSeedAdded: (excludedKeywords) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_EXCLUDE_KEYWORD_ADDED, excludedKeywords });
  },
  handleCheckedBoxChanged: (checkedKeywordsList) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_CHECKED_BOX_CHANGED, checkedKeywordsList });
  },
  onFilterChanged: (payload) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_EXCLUDE_KEYWORD_FILTER_CHANGED, payload });
  },
  onKeywordsRemoved: (keywords) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_KEYWORDS_REMOVED, keywords });
  },
});

type PropTypes = {
  keywordsListLoaded: IExcludedKeywordSeed;
  keywordsList: any[];
  searchText: string;
  selectedWebsite: IWebsite;
  totalKeywordsCount: number;
  checkedKeywordsList: IExcludedKeywordSeed[];

  handleKeywordsListLoaded: (payload) => void;
  handleSearchTextChanged: (searchText) => void;
  handleCheckedBoxChanged: (checkedKeywordsList) => void;
  handleExcludedKeywordSeedAdded: (payload) => void;
  onFilterChanged: (payload) => void;
  selectedType: { type: string; name: string };
  onKeywordsRemoved: (keywords) => void;
};
const ExcludeKeywords = (props: PropTypes) => {
  const {
    onFilterChanged,
    searchText,
    selectedType,
    totalKeywordsCount,
    selectedWebsite,
    handleExcludedKeywordSeedAdded,
    handleKeywordsListLoaded,
    checkedKeywordsList,
    handleCheckedBoxChanged,
    onKeywordsRemoved,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [sources, setSources] = React.useState<{
    exact: string;
    containing: string;
    phrase: string;
  }>({ exact: "", containing: "", phrase: "" });
  const [loading, setLoading] = React.useState(false);
  const [paginationNumber, setPaginationNumber] = React.useState("20");
  const [page, setPage] = React.useState(1);
  const onSourceChange = (column: string, value: string) => {
    setSources((oldSource) => ({
      ...oldSource,
      [column]: value,
    }));
  };

  const loadKeywords = () => {
    setLoading(true);
    if (selectedWebsite) {
      handleKeywordsListLoaded(
        agent.ExcludedKeywordSeeds.getExcludedKeywordSeeds({
          website: selectedWebsite._id,
          search: searchText,
          type: selectedType.type,
          page,
          limit: paginationNumber,
        })
      );
    }
    setLoading(false);
  };

  const addExcludedKeywords = () => {
    if (!selectedWebsite) {
      enqueueSnackbar(I18n.t("snackbar.update_error", { msg: "Please choose a customer from the left panel" }));
      return;
    }

    const exact = sources.exact
      ? sources.exact.split("\n").map((seed) => ({
          seed,
          type: "exact",
        }))
      : [];
    const containing = sources.containing
      ? sources.containing.split("\n").map((seed) => ({ seed, type: "containing" }))
      : [];
    const phrase = sources.phrase
      ? sources.phrase.split("\n").map((seed) => ({
          seed,
          type: "phrase",
        }))
      : [];

    const data = exact.concat(containing).concat(phrase);

    agent.ExcludedKeywordSeeds.addExcludedKeywords(data, selectedWebsite._id)
      .then((resp) => {
        handleExcludedKeywordSeedAdded(resp);
        enqueueSnackbar(I18n.t("exclude_keywords.snackbar.saved_success"));
        setOpenDialog(false);
        setSources({
          exact: "",
          containing: "",
          phrase: "",
        });
        loadKeywords();
      })
      .catch((e) => enqueueSnackbar(I18n.t("exclude_keywords.snackbar.saved_error", { msg: e.message })));
  };

  React.useEffect(() => {
    loadKeywords();
  }, [selectedWebsite, searchText, selectedType, page, paginationNumber]);

  const handleDelete = () => {
    if (checkedKeywordsList && checkedKeywordsList?.length > 0) {
      handleCheckedBoxChanged([]);
      agent.ExcludedKeywordSeeds.cancelExclusion(checkedKeywordsList.map((obj) => obj.id))
        .then(() => {
          onKeywordsRemoved(checkedKeywordsList);
          enqueueSnackbar(I18n.t("exclude_keywords.exclusion_canceled"));
        })
        .catch(() => enqueueSnackbar(I18n.t("exclude_keywords.exclusion_error")));
    }
  };
  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t("meta_tags.strategy.exclude.title")} />
      <MTableToolbar
        searchText={searchText}
        searchPlaceHolder={I18n.t("exclude_keywords.search_keywords_ph")}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
        actions={[
          <MTableToolbarActionDisplayText
            text={I18n.t("exclude_keywords.total_excluded_kws", { count: totalKeywordsCount || 0 })}
            divider
          />,
          <MTableToolbarBaseActionButton
            handleClick={() => setOpenDialog(true)}
            text={I18n.t("exclude_keywords.add_keywords")}
          />,
        ]}
        selectedRows={checkedKeywordsList?.length}
        selectedRowActions={[
          {
            onClick: handleDelete,
            color: theme.palette.primary.main,
            text: I18n.t("exclude_keywords.cancel_exclusion"),
          },
        ]}
        onClearSelectedRows={() => {
          handleCheckedBoxChanged([]);
        }}
      >
        <MTableToolbarExcludeKeywordsTypeFilter
          type={selectedType}
          handleTypeSelected={(value) => onFilterChanged({ selectedType: value })}
        />
      </MTableToolbar>
      <ExcludeKeywordsList loading={loading} />
      {totalKeywordsCount > 0 && (
        <MTablePagination
          paginationLimit={Number(paginationNumber)}
          page={page}
          paginationPagesCount={Math.ceil(totalKeywordsCount / parseInt(paginationNumber, 10))}
          setPaginationLimit={(v) => setPaginationNumber(String(v))}
          setPage={setPage}
        />
      )}
      <AddKeywordsDialog
        values={sources}
        handleChange={onSourceChange}
        addExcludedKeywords={addExcludedKeywords}
        open={openDialog}
        handleCloseClick={() => setOpenDialog(false)}
      />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExcludeKeywords);
