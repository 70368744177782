import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useState } from "react";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import { push } from "connected-react-router";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import ExpertsHeader from "./components/ExpertsHeader";
import ExpertsList from "./components/ExpertsList";
import ExpertsPagination from "./components/ExpertsPagination";
import { web } from "../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "25px 20px 20px 10px",
    background: "white",
    height: "auto",
  },
  divider: { marginRight: 30, marginLeft: 30 },
}));

type PropTypes = {
  selectedWebsite: IWebsite;

  openExpertPage: (expertId: string) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  openExpertPage: (expertId: string) => dispatch(push(web.entailExperts(expertId))),
});

const ExpertsListPage = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, openExpertPage } = props;

  const [searchText, setSearchText] = useState(null);
  const [pagination, setPagination] = useState({ page: 1, limit: 20 });
  const [totalPages, setTotalPages] = useState(1);
  const [totalExpertsCount, setTotalExpertsCount] = useState(0);
  const [experts, setExperts] = useState([]);

  async function loadExperts() {
    try {
      const response = await agent.Experts.list(
        selectedWebsite._id,
        searchText ? 1 : pagination.page,
        pagination.limit,
        searchText
      );
      setExperts(response.items);
      setTotalPages(response.totalPages);
      setTotalExpertsCount(response.totalItems);
    } catch (e) {
      console.log("getExperts", e);
    }
  }

  React.useEffect(() => {
    loadExperts();
  }, [pagination, searchText]);

  return (
    <div className={classes.pageWrapper}>
      <ExpertsHeader
        selectedWebsite={selectedWebsite}
        searchText={searchText}
        createNewExpert={() => openExpertPage("new")}
        handleSearchTextChange={(text) => setSearchText(text)}
        handleRefreshList={() => loadExperts()}
      />
      <Divider className={classes.divider} />
      <ExpertsList
        website={selectedWebsite}
        experts={experts}
        totalExpertsCount={totalExpertsCount}
        handleExpertChanged={loadExperts}
        openExpertPage={openExpertPage}
      />
      <ExpertsPagination
        pageSize={pagination.limit}
        page={pagination.page}
        paginationPagesCount={totalPages}
        setPageSize={(limit) => setPagination({ ...pagination, limit })}
        setPage={(page) => setPagination({ ...pagination, page })}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpertsListPage);
