import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import { CONTENT_GAPS } from "../../../../reducers/constants/actionTypes";
import agent from "../../../../agent";
import ContentGapsListItem from "./ContentGapsListItem";
import ContentGapsColumns from "./ContentGapsColumns";
import MTable from "../../../../components/Table/Table/MTable";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  loading: state.contentGaps.dataLoading,
  data: state.contentGaps.dataList,
  selectedDataItemsIndexes: state.contentGaps.selectedDataItemsIndexes,
  refetch: state.contentGaps.refetch,
  total: state.contentGaps.total,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoading(loading: boolean) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_DATA_LOADING, payload: loading });
  },

  changeSort(sort: { field: string; direction: "desc" | "asc" }) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_SORT_CHANGED, payload: sort });
  },
  changeRefetch(refresh: boolean) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_REFETCH_CHANGED, payload: refresh });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
  changeSelectedItem(item: any) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_SELECTED_DATA_ITEM_CHANGED, payload: item });
  },
  changeIsDialogOpen(open: boolean) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_IS_DIALOG_OPEN_CHANGED, payload: open });
  },
  changeActiveDialogTab(tab: "keywords" | "broadKeywords" | "pages") {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_ACTIVE_DIALOG_TAB_CHANGED, payload: tab });
  },
  changeBroadKeywordsLoaded(payload: any) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_BROAD_KEYWORDS_LOADED, payload });
  },
  changeSerpsLoaded(payload: any) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_SERPS_LOADED, payload });
  },

  redirect: (url) => dispatch(push(url)),
});

type PropTypes = {
  loading: boolean;
  data: any[];
  sortField: { field: string; direction: "desc" | "asc" };
  selectedDataItemsIndexes: number[];
  changeSerpsLoaded: (serps: any) => void;
  refetch: boolean;
  selectedWebsite: IWebsite;

  changeBroadKeywordsLoaded(payload: any): void;

  changeLoading(loading: boolean): void;
  changeSelectedItem(item: any): void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  changeSort(sort: { field: string; direction: "desc" | "asc" }): void;
  changeRefetch: (refresh: boolean) => void;
  changeIsDialogOpen: (open: boolean) => void;
  changeActiveDialogTab: (tab: string) => void;

  redirect: (url: string) => void;
  total: number;
  setSortField: (sort: { field: string; direction: "desc" | "asc" }) => void;
};

const ContentGapsList = (props: PropTypes) => {
  const {
    changeLoading,
    selectedWebsite,
    changeSelectedDataItemsIndexes,
    changeSerpsLoaded,
    loading,
    selectedDataItemsIndexes,
    data,
    refetch,
    redirect,
    changeActiveDialogTab,
    changeIsDialogOpen,
    changeRefetch,
    sortField,
    total,
    changeSelectedItem,
    changeBroadKeywordsLoaded,
    setSortField,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const openDialog = async (strategyKeywords: any, tab: "keywords" | "pages") => {
    changeSelectedItem(strategyKeywords);
    changeBroadKeywordsLoaded(
      await agent.KeywordStrategy.getBroadKeywords(selectedWebsite._id, strategyKeywords.keyword)
    );

    agent.KeywordStrategy.getSerps(selectedWebsite._id, strategyKeywords.keyword)
      .then((serps) => {
        changeSerpsLoaded(serps);
      })
      .catch((e) => {
        console.log(e);
      });
    changeActiveDialogTab(tab);
    changeIsDialogOpen(true);
  };

  const sumbitForApproval = async (item, postType) => {
    try {
      if (item.conversion_potential === "None") {
        throw new Error("Submission failed. Topic has no funnel stage");
      }
      changeLoading(true);

      await agent.KeywordStrategy.updateKeywordStrategy(
        selectedWebsite._id,
        item.keyword,
        {
          ...item,
          status: "pending",
          submitted_at: new Date(),
          post_type: postType,
        },
        "true"
      );
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.pending_success"));
      changeLoading(false);
      changeRefetch(!refetch);
      changeSelectedDataItemsIndexes([]);
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return (
    <MTable>
      <ContentGapsColumns
        total={total}
        selectAll={() => {
          changeSelectedDataItemsIndexes(data.map((item, index) => index));
        }}
        clearSelected={() => {
          changeSelectedDataItemsIndexes([]);
        }}
        selectedNr={selectedDataItemsIndexes.length}
        sortField={sortField}
        setSortField={setSortField}
      />
      {!loading && data?.length < 1 && <MTableRowEmptyRow />}

      {loading && <MTableRowProgressBar />}

      {!loading &&
        data?.length > 0 &&
        data.map((item, index) => (
          <ContentGapsListItem
            isFirstRow={index === 0}
            isLastRow={index === data.length - 1}
            approveOutline={sumbitForApproval}
            selectedWebsite={selectedWebsite}
            redirect={redirect}
            selected={selectedDataItemsIndexes.includes(index)}
            setSelect={(selected) => {
              if (selected) {
                changeSelectedDataItemsIndexes([...selectedDataItemsIndexes, index]);
              } else {
                changeSelectedDataItemsIndexes(selectedDataItemsIndexes.filter((i) => i !== index));
              }
            }}
            onClick={() => {
              openDialog(item, "pages");
            }}
            item={item}
            key={index}
          />
        ))}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentGapsList);
