import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { web } from "../../helpers/urlHelper";
import Write from "./Write/Write";
import { AbilityContext } from "../../casl/can";
import New from "./New/New";
import Published from "./Published/Published";
import Draft from "./Draft/Draft";
import Deleted from "./Deleted/Deleted";
import CommunityEditor from "./CommunityEditor/CommunityEditor";
import WritePublished from "./WritePublished/WritePublished";
import Moderator from "./Moderator/Moderator";
import { IWebsite } from "../../reducers/constants/objectTypes";

const useStyles = makeStyles(() =>
  createStyles({
    pageWrapper: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flexGrow: 1,
      display: "flex",
    },
  })
);

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});
type PropTypes = {
  selectedWebsite: IWebsite;
};

const Community = (props: PropTypes) => {
  const classes = useStyles(props);
  const ability = React.useContext<any>(AbilityContext);
  const { selectedWebsite } = props;
  const routes = React.useMemo(() => {
    const allRoutes = [];
    if (ability.can("view", "community_writer_tab")) {
      allRoutes.push({
        url: web.community("writer"),
        route: (
          <Route
            key={`community_${selectedWebsite._id}_writer`}
            exact={false}
            path={web.community("writer")}
            component={() => <Write />}
          />
        ),
      });
      allRoutes.push({
        url: web.community("answers"),
        route: (
          <Route
            key={`community_${selectedWebsite._id}_answers`}
            exact={false}
            path={web.community("answers")}
            component={() => <WritePublished />}
          />
        ),
      });
    }
    if (ability.can("view", "community_editor_tab")) {
      allRoutes.push({
        url: web.community("new"),
        route: (
          <Route
            key={`community_${selectedWebsite._id}_new`}
            exact={false}
            path={web.community("new")}
            component={() => <New />}
          />
        ),
      });
      allRoutes.push({
        url: web.community("draft"),
        route: (
          <Route
            key={`community_${selectedWebsite._id}_draft`}
            exact={false}
            path={web.community("draft")}
            component={() => <Draft />}
          />
        ),
      });

      allRoutes.push({
        url: web.community("moderator"),
        route: (
          <Route
            key={`community_${selectedWebsite._id}_moderator`}
            exact={false}
            path={web.community("moderator")}
            component={() => <Moderator />}
          />
        ),
      });
      allRoutes.push({
        url: web.community("deleted"),
        route: (
          <Route
            key={`community_${selectedWebsite._id}_deleted`}
            exact={false}
            path={web.community("deleted")}
            component={() => <Deleted />}
          />
        ),
      });
    }
    if (ability.can("view", "community_published_tab")) {
      allRoutes.push({
        url: web.community("published"),
        route: (
          <Route
            key={`community_${selectedWebsite._id}_published`}
            exact={false}
            path={web.community("published")}
            component={() => <Published />}
          />
        ),
      });
    }
    return allRoutes;
  }, [selectedWebsite, ability]);

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.content}>
        <Switch>
          {routes.map((route) => route.route)}
          <Route exact={false} path={web.communityAnswerEditor(":answerId")} component={() => <CommunityEditor />} />
          <Route
            exact={false}
            path={web.communityQuestionEditor(":questionId", ":type")}
            component={() => <CommunityEditor />}
          />
          <Redirect exact from={"*"} to={routes[0]?.url} />
        </Switch>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Community);
