import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { I18n } from "react-redux-i18n";
import {
  IWidgetExpandableTextSectionFieldProps,
  IWidgetFieldProperties,
} from "../../../../../../../reducers/constants/objectTypes";
import LiteRichTextEditor from "../../../../../../../editor/elements/liteEditor/LiteRichTextEditor";
import TextFieldMaxLength from "../../../../../../../components/TextFieldMaxLength";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "grid",
    gap: 30,
  },
  editableCustomClass: {
    borderBottomLeftRadius: 3,
    borderBottomRightRadiusRadius: 3,
  },
  inputWrapper: {
    backgroundColor: palette.secondary.main,
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  input: {
    border: `1px solid ${palette.grey["100"]}`,
    width: "100%",
    overflow: "hidden",
    padding: "10px 15px",
    color: palette.text.primary,
    backgroundColor: palette.secondary.dark,
    fontWeight: typography.fontWeightLight,
    fontSize: 14,
    borderRadius: 3,
    height: 29,
  },
  inputLabel: {
    marginLeft: 5,
    marginTop: 2,
    fontWeight: typography.fontWeightLight,
    color: palette.text.secondary,
    fontSize: 16,
  },
  header: {
    display: "flex",
    justifyContent: "end",
  },
  divider: {
    border: "0.5px solid #606163",
    marginInline: 10,
  },
  icon: {
    height: "22px",
    cursor: "pointer",
  },
  addButton: {
    justifySelf: "right",
    marginTop: -20,
  },
  startIcon: {
    marginTop: -2,
    marginRight: 2,
  },
}));

type PropTypes = {
  properties?: IWidgetFieldProperties;
  onChange: (properties: IWidgetExpandableTextSectionFieldProps) => void;
};

const ExpandableTextSectionWidgetItem = (props: PropTypes) => {
  const { onChange } = props;
  const { value = [{ content: "", title: "" }] } = props.properties as IWidgetExpandableTextSectionFieldProps;

  const classes = useStyles(props);

  const handleChange = (property: { content: string } | { title: string }, index: number) => {
    const updatedValues = value.map((item, arrIndex) => (arrIndex === index ? { ...item, ...property } : item));
    onChange({ value: updatedValues });
  };

  return (
    <div className={classes.root}>
      {value.map((item, index) => (
        <div key={index}>
          {value.length > 1 && (
            <div className={classes.header}>
              <CloseIcon
                className={classes.icon}
                onClick={() => onChange({ value: value.filter((val, arrIndex) => arrIndex !== index) })}
              />
            </div>
          )}

          <TextFieldMaxLength
            handleValueChange={(newValue: string) => handleChange({ title: newValue }, index)}
            value={item.title}
            placeholder={I18n.t("widgets_builder.widget_editor.fields.placeholders.write_title")}
            inputClassName={classes.input}
            borderRadius={3}
            borderColor="0"
            marginBottom={10}
            outlined={false}
          />
          <LiteRichTextEditor
            editorToolbarPostion="top"
            onValueChanged={(content) => handleChange({ content }, index)}
            initialValue={item.content}
            editableCustomClass={classes.editableCustomClass}
          />
        </div>
      ))}

      <Button
        className={classes.addButton}
        classes={{ startIcon: classes.startIcon }}
        disableElevation
        startIcon={<AddIcon className={classes.icon} />}
        onClick={() => onChange({ value: [...value, { content: "", title: "" }] })}
      >
        {I18n.t("widgets_builder.widget_editor.fields.add")}
      </Button>
    </div>
  );
};

export default ExpandableTextSectionWidgetItem;
