import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { IWebsiteThemeProps } from "../../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import TextDesignToolWithPreview from "../../../components/TextDesignToolWithPreview";
import SelectRow from "../../../components/SelectRow";
import { fontOptions } from "../../consts/CssConsts";
import MRouterPrompt from "../../../components/MRouterPrompt";
import MainPageTypographyFontFamily from "./components/MainPageTypographyFontFamily";

const useStyles = makeStyles((theme: Theme) => ({
  innerWrapper: {
    marginRight: "-30px",
    marginLeft: "-30px",
  },
  elementWrap: {
    borderBottom: `${theme.palette.divider} solid 1px`,
    marginTop: "-10px",
    marginBottom: 0,
    padding: "0px 30px 5px 30px",
  },
}));

const mapStateToProps = (state) => ({
  article: state.websitePages.article,
  theme: state.websitePages.theme,
  initialTheme: state.websitePages.initialTheme,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (theme) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "theme",
      value: theme,
    }),
});

type PropTypes = {
  theme: IWebsiteThemeProps;
  initialTheme: IWebsiteThemeProps;

  onPropertyChanged: (article) => void;
};

const MainPageTypography = (props: PropTypes) => {
  const classes = useStyles(props);
  const { initialTheme, theme, onPropertyChanged } = props;

  return (
    <PageSection className={classes.innerWrapper} sectionTitle={I18n.t("website.main_page_design")}>
      <MRouterPrompt />
      <MainPageTypographyFontFamily
        fontFamily={theme.typography.fontFamily || ""}
        handleFontFamilyChange={(fontFamily) =>
          onPropertyChanged({ ...theme, typography: { ...theme.typography, fontFamily } })
        }
      />
      {/*<div className={classes.elementWrap}>*/}
      {/*	<SelectRow*/}
      {/*		bold={true}*/}
      {/*		width={320}*/}
      {/*		rowTitle={I18n.t('website.design_tool.font')}*/}
      {/*		value={theme.typography.fontFamily || ''}*/}
      {/*		selectOptions={fontOptions}*/}
      {/*		handleChange={(font) => {*/}
      {/*			onPropertyChanged({ ...theme, typography: { ...theme.typography, fontFamily: font } });*/}
      {/*		}}*/}
      {/*	/>*/}
      {/*</div>*/}
      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.H1_title")}
        css={theme.typography.h1?.css}
        defaultCss={initialTheme.typography.h1.css}
        variant={"h1"}
        exampleText={I18n.t("website.H1_example")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({ ...theme, typography: { ...theme.typography, h1: { css: newCss } } });
        }}
      />
      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.H2_title")}
        css={theme.typography.h2?.css}
        defaultCss={initialTheme.typography.h2.css}
        variant={"h2"}
        exampleText={I18n.t("website.H2_example")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({ ...theme, typography: { ...theme.typography, h2: { css: newCss } } });
        }}
      />

      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.H3_title")}
        css={theme.typography.h3?.css}
        defaultCss={initialTheme.typography.h3.css}
        variant={"h3"}
        exampleText={I18n.t("website.H3_example")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({ ...theme, typography: { ...theme.typography, h3: { css: newCss } } });
        }}
      />

      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.H4_title")}
        css={theme.typography.h4?.css}
        defaultCss={initialTheme.typography.h4.css}
        variant={"h4"}
        exampleText={I18n.t("website.H4_example")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({ ...theme, typography: { ...theme.typography, h4: { css: newCss } } });
        }}
      />

      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.H5_title")}
        css={theme.typography.h5?.css}
        defaultCss={initialTheme.typography.h5.css}
        variant={"h5"}
        exampleText={I18n.t("website.H5_example")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({ ...theme, typography: { ...theme.typography, h5: { css: newCss } } });
        }}
      />

      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.H6_title")}
        css={theme.typography.h6?.css}
        defaultCss={initialTheme.typography.h6.css}
        variant={"h6"}
        exampleText={I18n.t("website.H6_example")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({ ...theme, typography: { ...theme.typography, h6: { css: newCss } } });
        }}
      />

      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.subtitle1_title")}
        css={theme.typography.subtitle1?.css}
        defaultCss={initialTheme.typography.subtitle1.css}
        variant={"subtitle1"}
        exampleText={I18n.t("website.subtitle1_example")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({
            ...theme,
            typography: { ...theme.typography, subtitle1: { css: newCss } },
          });
        }}
      />
      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.subtitle2_title")}
        css={theme.typography.subtitle2?.css}
        defaultCss={initialTheme.typography.subtitle2.css}
        variant={"subtitle2"}
        exampleText={I18n.t("website.subtitle2_example")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({
            ...theme,
            typography: { ...theme.typography, subtitle2: { css: newCss } },
          });
        }}
      />
      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.body1_title")}
        css={theme.typography.body1?.css}
        defaultCss={initialTheme.typography.body1.css}
        variant={"body1"}
        exampleText={I18n.t("website.body1_example")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({
            ...theme,
            typography: { ...theme.typography, body1: { css: newCss } },
          });
        }}
      />
      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.body2_title")}
        css={theme.typography.body2?.css}
        defaultCss={initialTheme.typography.body2.css}
        variant={"body2"}
        exampleText={I18n.t("website.body2_example")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({
            ...theme,
            typography: { ...theme.typography, body2: { css: newCss } },
          });
        }}
      />
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPageTypography);
