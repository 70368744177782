import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { NavLink, useRouteMatch } from "react-router-dom";
import ClassNames from "classnames";
import { web } from "../../../helpers/urlHelper";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import { AbilityContext } from "../../../casl/can";
import PipeSeparatorIcon from "../../../icons/PipeSeparatorIcon";
import MAddToSiteButton from "../../../components/MAddToSiteButton";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 0,
    alignItems: "center",
  },
  tabRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "initial" as const,
    borderBottom: "3px solid transparent",
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    minHeight: 40,
    fontWeight: theme.typography.fontWeightLight as any,
    paddingRight: 0,
    paddingLeft: 0,
    "&:hover": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "&:focus": {
      color: theme.palette.primary.main,
    },
  },
  tabSelected: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: theme.typography.fontWeightLight as any,
    borderColor: "transparent",
  },
  pipeIcon: {
    alignSelf: "center",
    strokeWidth: 1,
    height: 24,
    marginRight: 25,
    marginLeft: 25,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};

const HeaderTabSettings = (props: PropTypes) => {
  const classes = useStyles(props);
  const match = useRouteMatch(`${web.homePage()}/:main/:page`);
  const ability = React.useContext<any>(AbilityContext);
  const tab = React.useMemo(() => {
    switch (match?.params?.main) {
      case "cms":
        return ability.can("view", "cms_settings_tab")
          ? {
              url: web.workflowSettings(),
              label: I18n.t("workflow.tabs.settings"),
            }
          : null;
      case "strategy":
        return ability.can("view", "strategy_settings_tab")
          ? {
              url: web.dataSettings(),
              label: I18n.t("strategy.tabs.settings"),
            }
          : null;
      case "analytics":
        return ability.can("view", "cro_settings_tab")
          ? {
              url: web.croSettings(),
              label: I18n.t("cro.tabs.settings"),
            }
          : null;

      default:
        return null;
    }
  }, [match?.params?.page]);

  if (match?.params?.main === "campaigns") {
    return (
      <div className={classes.wrapper}>
        <MAddToSiteButton />
        <PipeSeparatorIcon className={classes.pipeIcon} />
      </div>
    );
  }

  if (!tab) return null;
  return (
    <div className={classes.wrapper}>
      <NavLink key={tab.url} to={tab.url} activeClassName={classes.tabSelected} className={ClassNames(classes.tabRoot)}>
        {tab.label}
      </NavLink>
      <PipeSeparatorIcon className={classes.pipeIcon} />
    </div>
  );
};

export default HeaderTabSettings;
