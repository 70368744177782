import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import * as React from "react";
import { useMemo } from "react";
import { orderBy } from "lodash";
import moment from "moment/moment";
import ClassNames from "classnames";
import _ from "lodash";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import MTableCellFirst from "../../../../../components/Table/Cells/MTableCellFirst";
import MTableCellNumber from "../../../../../components/Table/Cells/MTableCellNumber";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../../../../components/Table/Table/MTableHeaderSortLabel";
import { DATE_FORMAT } from "../../../../../reducers/constants/consts";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    backgroundColor: "white",
    padding: "30px 20px 0px 0px",
    width: 80,
  },
  firstColumn: {
    backgroundColor: theme.palette.common.white,
  },
  headerFirstColumn: {
    zIndex: 100,
    backgroundColor: theme.palette.common.white,
  },
  pageEventsAvatar: {
    backgroundColor: "#B780FE !important",
  },
  table: {
    position: "relative",
    borderSpacing: "20px 0",
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: "auto",
    height: "calc(100vh - 225px)",
  },
  tableRow: {
    height: 53,
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      transition: "border-radius 500ms",
      "& $firstColumn": {
        backgroundColor: theme.palette.secondary.main,
      },
      "& $headerFirstColumn": {
        backgroundColor: theme.palette.secondary.main,
      },
      "& $thFirst": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  thFirst: {
    position: "sticky",
    left: 0,
    width: "50%",
    paddingLeft: 30,
    backgroundColor: theme.palette.common.white,
  },
  rowCell: {
    width: 80,
    paddingRight: 20,
    paddingLeft: 0,
    cursor: "pointer",
  },
  tableHeader: {
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    top: 0,
    borderBottom: `30px solid ${theme.palette.common.white}`,
  },
  tableFooter: {
    width: "100%",
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    bottom: 0,
  },
  totalTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 16,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    textAlign: "right",
    padding: "10px 30px 10px 0px",
    height: 50,
    width: "30%",
  },
  firstRow: {
    paddingTop: 15,
    paddingBottom: 15,
  },
}));

type Props = {
  data: { timeFrame: string; results: any[]; metadata: any[]; totalPages: number; total: number; totals: any[] };
  startDate: any;
  momentTimeFrame: any;
  setSelectedEvent: (event: { value: string; label: string }) => void;
};

const PageEventsReportTable = (props: Props) => {
  const classes = useStyles();
  const [sortField, setSortField] = React.useState<{ direction: "desc" | "asc" | undefined; field: string }>(null);

  const { data, startDate, momentTimeFrame, setSelectedEvent } = props;

  const sortedData = React.useMemo(() => {
    console.log("sortField", sortField);
    if (sortField) {
      console.log("sortField", "sorting");
      return orderBy(data?.results || [], sortField.field, sortField.direction);
    }
    return data?.results || [];
  }, [sortField, data]);

  const newDataRanges = useMemo(() => {
    const dates = [];

    const start = moment(startDate).startOf(momentTimeFrame);
    const end = moment().endOf(momentTimeFrame);

    while (start.isBefore(end)) {
      dates.push(start.format("YYYY-MM-DD"));
      start.add(1, momentTimeFrame as any);
    }

    return dates;
  }, [startDate]);

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell className={ClassNames(classes.thFirst, classes.headerWrapper)}>
              <MTableHeaderSortLabel
                className={classes.headerFirstColumn}
                field={"url"}
                onClick={(value) => setSortField(value)}
                sortField={sortField}
              >
                {I18n.t("reports.column_names.total_events", { count: data.total })}
              </MTableHeaderSortLabel>
            </TableCell>
            {newDataRanges.map((date, dateIndex) => (
              <TableCell key={dateIndex} className={classes.headerWrapper}>
                <MTableHeaderLabel fontSize={14}>
                  {moment(date).format(
                    data.timeFrame === "month" ? DATE_FORMAT.READABLE_MONTH_YEAR : DATE_FORMAT.READABLE
                  )}
                </MTableHeaderLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row, rowIndex) => (
            <>
              <TableRow
                key={rowIndex}
                className={classes.tableRow}
                onClick={() => {
                  setSelectedEvent({ value: row.type, label: row.name });
                }}
              >
                <TableCell className={ClassNames(classes.thFirst, classes.firstRow)}>
                  <MTableCellFirst
                    wrapperClassName={classes.firstColumn}
                    avatarClassName={classes.pageEventsAvatar}
                    cellText={row.name}
                    avatarType={"E"}
                    toolTipProps={{ enterDelay: 300, placement: "top-start" }}
                    actions={[]}
                    fontSize={14}
                  />
                </TableCell>
                {newDataRanges.map((date, dateIndex) => (
                  <TableCell key={`${rowIndex}_${dateIndex}`} className={classes.rowCell}>
                    <MTableCellNumber
                      value={row?.date_data?.[date]}
                      type={"bigNumber"}
                      toolTipProps={{ enterDelay: 300, placement: "top-start" }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </>
          ))}
          <tr></tr>
        </TableBody>
        <tfoot className={classes.tableFooter}>
          <tr>
            <th className={ClassNames(classes.thFirst, classes.totalTitle)}>{"Totals:"}</th>
            {newDataRanges.map((date, dateIndex) => (
              <td key={`total_${dateIndex}`} className={classes.rowCell}>
                <MTableCellNumber
                  value={_.find(data?.totals, { date: date })?.value || 0}
                  type={"bigNumber"}
                  toolTipProps={{ enterDelay: 300, placement: "top-start" }}
                />
              </td>
            ))}
          </tr>
        </tfoot>
      </Table>
    </TableContainer>
  );
};

export default PageEventsReportTable;
