import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { Theme } from "@material-ui/core/styles";
import { IQuestion } from "../../../../../reducers/constants/objectTypes";
import CommunityEditorUrl from "./components/CommunityEditorUrl";
import CommunityEditorLocale from "./components/CommunityEditorLocale";
import CommunityEditorCategory from "./components/CommunityEditorCategory";
import CommunityEditorTags from "./components/CommunityEditorTags";
import CommunityEditorProducts from "./components/CommunityEditorProducts";
import CommunityEditorPublishStatus from "./components/CommunityEditorPublishStatus";

const useStyles = makeStyles((theme: Theme) => ({
  groupWrapper: {
    overflow: "auto",
    padding: 20,
    backgroundColor: "#F5F6F8",
    maxWidth: 280,
    width: "100%",
    borderLeft: `${theme.palette.divider} solid 1px`,
    zIndex: 1,
  },
  divider: {
    backgroundColor: "#C4C4C4",
    width: "100%",
    height: 1,
    margin: "20px 0",
  },
}));

type PropTypes = {
  selectedQuestion: IQuestion;
  isAnswerEditor: Boolean;
};

const mapStateToProps = (state) => {
  return {
    selectedQuestion: state.communityEditor.questionLoaded,
    isAnswerEditor: state.communityEditor.isAnswerEditor,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const CommunityEditorSidePanel = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedQuestion, isAnswerEditor } = props;

  if (!selectedQuestion) {
    return <div className={classes.groupWrapper} />;
  }

  return (
    <div className={classes.groupWrapper}>
      {!isAnswerEditor && (
        <>
          <CommunityEditorPublishStatus />
          <div className={classes.divider}></div>
          <CommunityEditorUrl />
          <CommunityEditorLocale />
          <CommunityEditorCategory />
          <CommunityEditorTags />
          <CommunityEditorProducts />
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorSidePanel);
