import { CATEGORY, TAG } from "./constants/actionTypes";

const initialState = {
  tag: null,
  questions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TAG.TAG_LOADED:
      return {
        ...state,
        tag: action.payload.tag,
      };
    case TAG.TAG_QUESTIONS_LOADED:
      return {
        ...state,
        questions: action.payload.questions,
      };
    default:
      return state;
  }
};
