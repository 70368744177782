import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  className?: string;
};

function RedoIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={className} viewBox={"0 0 17 16"}>
      <path d="M12.9577 15.5C13.1785 15.5 13.3611 15.4277 13.5055 15.2832C13.6499 15.1386 13.722 14.9558 13.722 14.7347C13.722 14.5136 13.6499 14.3308 13.5055 14.1862C13.3611 14.0417 13.1785 13.9694 12.9577 13.9694H6.35898C5.17002 13.9694 4.14667 13.574 3.28892 12.7832C2.43117 11.9923 2.0023 11.0102 2.0023 9.83673C2.0023 8.66327 2.43117 7.68112 3.28892 6.89031C4.14667 6.09949 5.17002 5.70408 6.35898 5.70408H13.8749L11.5055 8.07653C11.3526 8.22959 11.2762 8.40816 11.2762 8.61224C11.2762 8.81633 11.3526 8.9949 11.5055 9.14796C11.6583 9.30102 11.8367 9.37755 12.0405 9.37755C12.2443 9.37755 12.4227 9.30102 12.5755 9.14796L16.2443 5.47449C16.3293 5.38946 16.3887 5.30442 16.4227 5.21939C16.4566 5.13435 16.4736 5.04082 16.4736 4.93878C16.4736 4.83673 16.4566 4.7432 16.4227 4.65816C16.3887 4.57313 16.3293 4.4881 16.2443 4.40306L12.5755 0.729592C12.4227 0.576531 12.2443 0.5 12.0405 0.5C11.8367 0.5 11.6583 0.576531 11.5055 0.729592C11.3526 0.882653 11.2762 1.06122 11.2762 1.26531C11.2762 1.46939 11.3526 1.64796 11.5055 1.80102L13.8749 4.17347H6.38446C4.77087 4.17347 3.38234 4.71769 2.21885 5.80612C1.05537 6.89456 0.473633 8.23809 0.473633 9.83673C0.473633 11.4354 1.05537 12.7789 2.21885 13.8673C3.38234 14.9558 4.77087 15.5 6.38446 15.5H12.9577Z" />
    </SvgIcon>
  );
}

export default RedoIcon;
