import React from "react";
import { makeStyles, Popover, Theme, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    padding: 15,
    display: "flex",
    flexDirection: "column",
    gap: 15,
    backgroundColor: palette.secondary.main,
  },
  header: {
    paddingBottom: 0,
  },
  headerText: {
    display: "flex",
    justifyContent: "center",
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
  done: {
    color: palette.primary.light,
    position: "absolute",
    right: "15px",
    cursor: "pointer",
  },
}));

type PropTypes = {
  open: boolean;
  label: string;
  anchorEl: Element | ((element: Element) => Element);
  children?: JSX.Element;
  className?: string;
  onClose: () => void;
  onDone?: () => void;
};

const CustomPopover = (props: PropTypes) => {
  const { open, label, anchorEl, children, className } = props;
  const { onClose, onDone } = props;
  const classes = useStyles();

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: { width: "300px" },
      }}
      BackdropProps={{ invisible: false }}
      disableScrollLock={true}
    >
      <div className={ClassNames(classes.root, className)}>
        <div className={classes.header}>
          <div className={classes.headerText}>
            <Typography>{label}</Typography>
            <Typography
              onClick={
                onDone
                  ? () => {
                      onDone();
                      onClose();
                    }
                  : onClose
              }
              className={classes.done}
            >
              {I18n.t("widgets_builder.build.item_done_button")}
            </Typography>
          </div>
        </div>
        {children}
      </div>
    </Popover>
  );
};

export default CustomPopover;
