import { WIDGET_PICKER } from "./constants/actionTypes";
import { IWidget, eWidgetType } from "./constants/objectTypes";

export const initialState: {
  mounted: boolean;
  selectedType: "All" | "Texts" | eWidgetType;
  selectedWidget?: IWidget;
  addWidgetMode?: boolean;
  editWidgetMode?: boolean;
  includeDialogs?: boolean;
} = {
  mounted: false,
  selectedType: "All",
  selectedWidget: null,
  addWidgetMode: false,
  editWidgetMode: false,
  includeDialogs: false,
};

export type IWidgetPickerState = typeof initialState;

export default (state = initialState, action) => {
  switch (action.type) {
    case WIDGET_PICKER.MOUNT:
      return {
        ...state,
        mounted: true,
        ...action.payload,
      };
    case WIDGET_PICKER.PROPERTY_CHANGE:
      return {
        ...state,
        ...action.property,
      };
    case WIDGET_PICKER.UNMOUNT:
      return initialState;

    default:
      return state;
  }
};
