import { makeStyles } from "@material-ui/styles";
import { find, isEqual } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { Theme, Typography } from "@material-ui/core";

import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import {
  IWidgetItem,
  IWidget,
  IWebsite,
  IWidgetFieldActionPayload,
  IWidgetFieldProperties,
  IWidgetField,
  IResetToProductDefaults,
  eWidgetProductDefaultFields,
} from "../../../../../../../reducers/constants/objectTypes";
import {
  getProductDefaultValue,
  getFieldSettings,
  excludeSameValueProperties,
  isProductField,
  getWidgetAddableFields,
  defaultWidgetFieldSettings,
} from "../../../helpers/widgets";
import SelectedWidgetField from "./SelectedWidgetField";
import SelectedWidgetItemHeader from "./SelectedWidgetItemHeader";
import WidgetItemFieldList from "./WidgetItemFieldList";
import agent from "../../../../../../../agent";
import AddButton from "../../../components/AddButton";
import AddWidgetFieldsTab from "./AddWidgetFields/AddWidgetFieldsTab";
import SelectedWidgetFieldDesign from "./SelectedWidgetFieldDesign";
import { web } from "../../../../../../../helpers/urlHelper";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 20,
    display: "flex",
    flexDirection: "column",
  },
  properties: {
    display: "flex",
    gap: 10,
    flexDirection: "column",
  },
  addItemButton: {
    marginTop: -10,
    borderTop: 0,
  },
  advancedText: {
    margin: 20,
    marginBottom: -10,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  selectedFieldHeader: {
    marginTop: -3,
    marginBottom: 17,
  },
}));

type PropTypes = {
  selectedWidgetItem: IWidgetItem;
  widget: IWidget;
  selectedWebsite: IWebsite;
  selectedFieldId: string;
  selectedItem: number;
  selectedStep?: number;
  showProductField?: boolean;
  children?: JSX.Element;
  back?: () => void;
  setSelectedFieldId: (selectedField: string) => void;
  handleItemChange: (item: IWidgetItem) => void;
  addNewField: (field: IWidgetField) => void;
  dispatchEditField: (payload: IWidgetFieldActionPayload) => void;
  deleteField: (payload: IWidgetFieldActionPayload) => void;
};

const mapStateToProps = (state) => ({
  widget: state.widgetBuilder.widget,
  selectedFieldId: state.widgetBuilder.selectedFieldId,
  selectedItem: state.widgetBuilder.selectedItem,
  selectedStep: state.widgetBuilder.selectedStep,
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedFieldId: (selectedFieldId: string) =>
    dispatch({ type: WIDGET_BUILDER.SET_SELECTED_FIELD, selectedFieldId }),
  handleItemChange: (item: IWidgetItem) => dispatch({ type: WIDGET_BUILDER.ITEM_CHANGED, item }),
  addNewField: (field: IWidgetField) => dispatch({ type: WIDGET_BUILDER.ADD_NEW_FIELD, field }),
  dispatchEditField: (payload: IWidgetFieldActionPayload) => dispatch({ type: WIDGET_BUILDER.EDIT_FIELD, ...payload }),
  deleteField: (payload: IWidgetFieldActionPayload) => dispatch({ type: WIDGET_BUILDER.DELETE_FIELD, ...payload }),
});

const SelectedWidgetItemWrapper = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

const SelectedWidgetItem = (props: PropTypes) => {
  const classes = useStyles();
  const { widget, selectedWidgetItem, selectedWebsite, selectedFieldId, selectedItem, selectedStep, children } = props;
  const { back, setSelectedFieldId, deleteField, handleItemChange, dispatchEditField, addNewField } = props;
  const { fields } = widget.items[selectedItem].steps[selectedStep || 0];
  const [initialFieldValues, setInitialFieldValues] = React.useState(null);
  const [selectedField, setSelectedField] = React.useState(null);
  const [advancedExpanded, setAdvancedExpanded] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [addNewFieldMode, setAddNewFieldMode] = React.useState(false);

  React.useEffect(() => {
    const field = find(
      widget.items[selectedItem].steps[selectedStep || 0].fields,
      (f) => f.fieldId && f.fieldId === selectedFieldId
    );

    if (!field) {
      setInitialFieldValues(null);
      setSelectedField(null);
      return;
    }

    const defaultValues = getProductDefaultValue({
      widget,
      field,
      product: selectedWidgetItem.product,
      website: selectedWebsite,
    });

    if (defaultValues) {
      setInitialFieldValues({ ...field, properties: { ...field.properties, ...defaultValues } });
      setSelectedField({ ...field, properties: { ...field.properties, ...defaultValues } });
      return;
    }

    setInitialFieldValues(field);
    setSelectedField(field);
  }, [selectedFieldId, selectedItem, selectedStep, selectedWidgetItem.product, widget.items, selectedWebsite]);

  const defaultProductFieldValues = React.useMemo(
    () =>
      getProductDefaultValue({
        widget: widget,
        field: selectedField,
        product: selectedWidgetItem.product,
        defaultsOnly: true,
        website: selectedWebsite,
      }),
    [widget, selectedField, selectedWidgetItem.product, selectedWebsite]
  );

  const editField = React.useCallback(
    (payload: IWidgetFieldActionPayload) => {
      if (!selectedField) return dispatchEditField(payload);

      return dispatchEditField({
        ...payload,
        field: {
          ...payload.field,
          properties: excludeSameValueProperties(
            selectedField.properties,
            defaultProductFieldValues as IWidgetFieldProperties
          ) as IWidgetFieldProperties,
        },
      });
    },
    [defaultProductFieldValues, dispatchEditField, selectedField]
  );

  const hasUnsavedChanges = React.useMemo(() => {
    if (!initialFieldValues || !selectedField) return false;

    return !isEqual(initialFieldValues, selectedField);
  }, [initialFieldValues, selectedField]);

  const resetToProductDefaults = React.useMemo((): IResetToProductDefaults => {
    if (!selectedField || !selectedWidgetItem.product || !eWidgetProductDefaultFields.includes(selectedField.type))
      return {
        hasChanges: false,
        onClick: null,
      };

    const updatedField = {
      ...selectedField,
      properties: { ...selectedField.properties, ...defaultProductFieldValues },
    };

    return {
      hasChanges: !isEqual(updatedField, selectedField),
      onClick: () => setSelectedField(updatedField),
    };
  }, [defaultProductFieldValues, selectedField, selectedWidgetItem.product]);

  const handlePropertiesChange = (properties?: IWidgetFieldProperties) => {
    setSelectedField((field: IWidgetField) => ({
      ...field,
      properties: !properties ? {} : { ...field.properties, ...properties },
    }));
  };

  const handleFormFieldChange = (form: IWidget) => {
    editField({ field: { ...selectedField, form } });
  };

  const handleFieldDesignChange = (properties: IWidgetFieldProperties) => {
    if (selectedField.type === "form") {
      dispatchEditField({ field: { ...selectedField, properties } });
      return;
    }
    handlePropertiesChange(properties);
  };

  const onItemFieldsReorder = (newFields: IWidgetField[]) => {
    handleItemChange({
      ...widget.items[selectedItem],
      steps: widget.items[selectedItem].steps.map((step, i) => {
        if (i === (selectedStep || 0)) {
          return {
            ...step,
            fields: newFields,
          };
        }
        return step;
      }),
    });
  };

  const createFormWidgetField = React.useCallback(() => {
    if (!selectedField || selectedField.type !== "form") return;

    agent.Widgets.newFormFieldWidget(selectedWebsite._id)
      .then((res) => editField({ field: { ...selectedField, form: res.widget, settings: defaultWidgetFieldSettings } }))
      .catch((e) => {
        setSelectedFieldId(null);
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  }, [editField, enqueueSnackbar, selectedField, selectedWebsite._id, setSelectedFieldId]);

  const fieldsWithPopulatedSettings = React.useMemo(
    () =>
      fields.map((field) => {
        const settings = getFieldSettings(field, widget);

        return {
          ...field,
          settings,
        };
      }),
    [fields, widget]
  );

  if (addNewFieldMode) {
    return (
      <AddWidgetFieldsTab
        addableFields={getWidgetAddableFields(widget)}
        onClose={() => setAddNewFieldMode(false)}
        addNewField={addNewField}
      />
    );
  }

  if (selectedField) {
    console.log("selectedField", selectedField);
    return (
      <>
        <SelectedWidgetItemWrapper>
          <SelectedWidgetItemHeader
            widget={widget}
            selectedField={selectedField}
            selectedWidgetItem={selectedWidgetItem}
            hasUnsavedChanges={hasUnsavedChanges}
            className={classes.selectedFieldHeader}
            setSelectedFieldId={setSelectedFieldId}
            back={back}
            editField={editField}
          />
          <SelectedWidgetField
            properties={selectedField.properties || {}}
            fieldType={selectedField.type}
            form={selectedField.form}
            widget={widget}
            resetToProductDefaults={resetToProductDefaults}
            selectedWebsite={selectedWebsite}
            productHref={
              selectedWidgetItem.product &&
              isProductField(selectedField) &&
              web.croProducts(selectedWidgetItem.product._id)
            }
            product={selectedWidgetItem.product}
            createFormWidgetField={createFormWidgetField}
            onChange={handlePropertiesChange}
            onFormChange={handleFormFieldChange}
          />
        </SelectedWidgetItemWrapper>
        <Typography className={classes.advancedText} onClick={() => setAdvancedExpanded((prev) => !prev)}>
          {I18n.t("widgets_builder.build.advanced_settings")}
          {advancedExpanded ? <ExpandLess /> : <ExpandMore />}
        </Typography>
        {advancedExpanded && (
          <SelectedWidgetItemWrapper>
            <SelectedWidgetFieldDesign selectedField={selectedField} onChange={handleFieldDesignChange} />
          </SelectedWidgetItemWrapper>
        )}
      </>
    );
  }

  return (
    <SelectedWidgetItemWrapper>
      {back && (
        <SelectedWidgetItemHeader
          widget={widget}
          selectedField={selectedField}
          selectedWidgetItem={selectedWidgetItem}
          hasUnsavedChanges={hasUnsavedChanges}
          setSelectedFieldId={setSelectedFieldId}
          back={back}
          editField={editField}
        />
      )}
      <div className={classes.properties}>
        {children}
        <WidgetItemFieldList
          selectedWidgetItem={selectedWidgetItem}
          widget={widget}
          fields={fieldsWithPopulatedSettings}
          selectField={setSelectedFieldId}
          editField={editField}
          deleteField={deleteField}
          onItemFieldsReorder={onItemFieldsReorder}
        />
        {getWidgetAddableFields(widget).length > 0 && (
          <AddButton
            onClick={() => setAddNewFieldMode(true)}
            text={I18n.t("widgets_builder.build.add_item_button")}
            className={classes.addItemButton}
          />
        )}
      </div>
    </SelectedWidgetItemWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedWidgetItem);
