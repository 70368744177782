import * as React from "react";
import { I18n } from "react-redux-i18n";
import filter from "lodash/filter";
import { uniqBy } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import agent from "../../../agent";
import { IProduct, IWebsite } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";
import MTag from "../../../components/MTag";
import MTopic from "../../../components/MTopic";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    minWidth: 80,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 7,
    marginTop: 5,
  },
  tagsCustomClass: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  select: {
    height: 31,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
  },
  productsWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: 7,
    gap: 5,
  },
  tagCustomClass: {
    minHeight: 24,
    backgroundColor: theme.palette.divider,
  },
  tagIconCustomClass: {
    color: theme.palette.text.primary,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  products: IProduct[];

  handleProductsChange: Function;
};

const PageEditorProducts = (props: PropTypes) => {
  const classes = useStyles(props);
  const { products = [], selectedWebsite, handleProductsChange } = props;
  const [items, setItems] = React.useState<IProduct[]>([]);

  const getProducts = (search = "") => {
    agent.Product.autoComplete(selectedWebsite._id, search, true)
      .then((results) => {
        setItems(results.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    getProducts();
  }, []);

  const allItems = [...products, ...items];

  const onProductSelected = (product) => {
    handleProductsChange(uniqBy([...products, product], "_id"));
  };

  const onProductRemoved = (product) => {
    handleProductsChange(filter(products, (pProduct) => pProduct._id !== product._id));
  };

  return (
    <div className={classes.wrapper}>
      <MSelect
        options={allItems}
        value={null}
        customClass={classes.select}
        optionValue={"_id"}
        optionLabel={"name"}
        borderWidth={"0px"}
        getDisplayLabel={(opt) => opt.name}
        height={31}
        placeholder={I18n.t("edit_post.select_products_ph")}
        searchable
        handleInputChange={(search) => {
          getProducts(search);
        }}
        handleChange={(tag) => {
          onProductSelected(tag);
        }}
      />
      {products.length > 0 && (
        <div className={classes.productsWrapper}>
          {products.map((product) => (
            <MTopic
              fontSize={12}
              key={product._id}
              tag={product.name}
              onRemoveClicked={(e) => onProductRemoved(product)}
              tagCustomClass={classes.tagCustomClass}
              tagIconCustomClass={classes.tagIconCustomClass}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PageEditorProducts;
