import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

type PropTypes = {
  className?: string;
};

const ButtonRightIcon = (props: PropTypes) => {
  const { className } = props;

  return (
    <SvgIcon className={className} viewBox={"0 0 12 12"}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.405 6.613l-4.022 3.89a.718.718 0 000 1.039.779.779 0 001.074 0l5.32-5.144a.718.718 0 000-1.039L6.458.215A.771.771 0 005.92 0a.771.771 0 00-.537.215.718.718 0 000 1.04l4.022 3.889H.76c-.42 0-.76.329-.76.734 0 .406.34.735.76.735h8.645z"
        fill="currentColor"
      ></path>
    </SvgIcon>
  );
};
export default ButtonRightIcon;
