import React from "react";
import { I18n } from "react-redux-i18n";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, DialogContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import DialogActions from "@material-ui/core/DialogActions";
import ArrowRightIcon from "../../icons/ArrowRightIcon";
import { IFile } from "../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    height: 545,
    width: 658,
    borderRadius: 20,
    border: "none",
  },
  topSide: (props: PropTypes) => ({
    width: "100%",
    height: 300,
    backgroundImage: `url(${props.image})`,
  }),
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 20px 20px 20px",
    gap: 20,
  },
  productName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  title: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.4,
    marginBottom: 9,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  bullets: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 15,
    gap: 5,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  checkIcon: {
    fontSize: 16,
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  leftButton: {
    fontSize: theme.typography.pxToRem(14),
    textTransform: "unset",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
  rightButton: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    textTransform: "unset",
    color: "white",
    borderRadius: 40,
    padding: "9px 20px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  arrow: {
    width: 14,
    marginLeft: 10,
  },
  dialogContentRoot: {
    flex: "unset",
  },
  dialogActions: {
    padding: "0 20px 15px 20px",
  },
}));

type PropTypes = {
  open: boolean;
  image: IFile;
  productName: string;
  title: string;
  subtitle: string;
  bullets: string[];
  learnMoreUrl?: string;
  handleCloseClick: () => void;
};
const BlockedProductsPopup = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, productName, title, subtitle, bullets, handleCloseClick, learnMoreUrl } = props;
  return (
    <Dialog
      open={open}
      fullWidth={false}
      maxWidth={"lg"}
      onClose={handleCloseClick}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle className={classes.topSide}></DialogTitle>
      <DialogContent
        className={classes.dialogContent}
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <div>
          <Typography variant={"subtitle1"} className={classes.productName}>
            {productName}
          </Typography>
          <Typography variant={"h1"} className={classes.title}>
            {title}
          </Typography>
          <Typography variant={"subtitle2"} className={classes.text}>
            {subtitle}
          </Typography>
        </div>
        <div className={classes.bullets}>
          {bullets.map((bullet) => (
            <div className={classes.row}>
              <CheckIcon className={classes.checkIcon} />
              <Typography variant={"body1"} className={classes.text}>
                {bullet}
              </Typography>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          disabled={!learnMoreUrl}
          disableRipple
          className={classes.leftButton}
          href={learnMoreUrl}
          target={"_blank"}
        >
          {I18n.t("blocked_products_popups.left_button")}
        </Button>
        <Button disableRipple className={classes.rightButton} href={"https://entail.ai/contact-us"} target={"_blank"}>
          {I18n.t("blocked_products_popups.right_button")}
          <ArrowRightIcon className={classes.arrow} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BlockedProductsPopup;
