import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { web } from "../../../helpers/urlHelper";
import PageBlog from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/Blog/PageBlog";
import PageTopic from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageTopic";
import PageCategory from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageCategory";
import PageGlossary from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageGlossary";
import PageProductReview from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageProductReview";
import PageProductComparison from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageProductComparison";
import ComparisonTopAlternative from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/ComparisonTopAlternative";
import PageProductPage from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageProductPage";
import { IWebsiteDynamicSectionProps } from "../../../reducers/constants/objectTypes";
import PageHomePage from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageHomePage";
import PageForum from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageForum";
import PageLearn from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageLearn";
import ComparisonTop10 from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/ComparisonTop10";
import PPCTop10Page from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PPCTop10Page";
import StaticPages from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/StaticPages";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";
import NewSectionPage from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/NewSectionPage/NewSectionPage";
import PageResourcePage from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/ResourcePage/PageResourcePage";

type PropTypes = {
  dynamicSections: IWebsiteDynamicSectionProps[];
};

const mapStateToProps = (state) => ({
  dynamicSections: state.websitePages.dynamicSections,
});
const ContentPages = (props: PropTypes) => {
  const { dynamicSections } = props;
  return (
    <div>
      <MHeaderMetaTags title={I18n.t(`meta_tags.page_builder.content_pages.title`)} />
      <Switch>
        <Route exact={false} path={web.contentPages("resource-page")} component={PageResourcePage} />
        <Route exact={false} path={web.contentPages("category")} component={PageCategory} />
        <Route exact={false} path={web.contentPages("topic")} component={PageTopic} />
        <Route exact={false} path={web.contentPages("homepage")} component={PageHomePage} />
        <Route exact={false} path={web.contentPages("blog")} component={PageBlog} />
        <Route exact={false} path={web.contentPages("forum")} component={PageForum} />
        <Route exact={false} path={web.contentPages("learn")} component={PageLearn} />
        <Route exact={false} path={web.contentPages("product-review")} component={PageProductReview} />
        <Route exact={false} path={web.contentPages("product-page")} component={PageProductPage} />
        <Route exact={false} path={web.contentPages("product-comparison")} component={PageProductComparison} />
        <Route
          exact={false}
          path={web.contentPages("comparison-top-alternative")}
          component={ComparisonTopAlternative}
        />
        <Route exact={false} path={web.contentPages("comparison-top-10")} component={ComparisonTop10} />
        <Route exact={false} path={web.contentPages("glossary")} component={PageGlossary} />
        <Route exact={false} path={web.contentPages("ppc-top-10")} component={PPCTop10Page} />
        <Route exact={false} path={web.contentPages("new-content-page")} component={NewSectionPage} />
        <Route exact={false} path={web.contentPages("static-pages")} component={StaticPages} />
        {dynamicSections.map((section) => (
          <Route
            exact={false}
            path={web.contentPages(section.type)}
            render={() => <NewSectionPage key={section.type} _id={section._id} />}
          />
        ))}
        <Redirect exact from={"*"} to={web.contentPages("resource-page")} />
      </Switch>
    </div>
  );
};

export default connect(mapStateToProps)(ContentPages);
