const isConfigValid = (config) => {
  if (
    !config ||
    !config.steps ||
    !Array.isArray(config.steps) ||
    config.steps.length === 0 ||
    !Array.isArray(config.steps[0].events) ||
    config.steps[0].events.length <= 0 ||
    !config.steps[0].events[0].value
  )
    return false;

  let stepValidator = true;

  config.steps.forEach((step) => {
    step.events.forEach((event) => {
      if (!event.value) {
        stepValidator = false;
      }

      if (event.filters && Array.isArray(event.filters) && event.filters.length > 0) {
        event.filters.forEach((filter) => {
          if (!filter.field || !filter.table || !filter.condition || !filter.value) {
            stepValidator = false;
          }
        });
      }
    });
  });

  return stepValidator;
};

const removeInvalidFilters = (event) =>
  event.filters.filter((filter) => filter.field && filter.table && filter.condition && filter.value);

export { isConfigValid, removeInvalidFilters };
