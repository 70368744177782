import * as React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import MSelect from "../../../components/MSelect";
import theme from "../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  writersWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  writerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // whiteSpace: 'pre',
    marginBottom: 10,
  },
  writerTitle: {
    maxWidth: 65,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    whiteSpace: "pre",
  },
  clearIcon: {
    fill: "#808080",
    marginLeft: 5,
    cursor: "pointer",
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
  },
  arrowIcon: {
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
}));

export const bulletedListIconList = [
  {
    key: "disc_icon",
    icon: CircleIcon,
  },
  {
    key: "v_icon",
    icon: CheckIcon,
  },
  {
    key: "x_icon",
    icon: ClearIcon,
  },
];

type PropTypes = {
  icon?: string;
  color?: string;

  onIconChange: Function;
};

const IconPickerElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { icon = "disc_icon", color = "black", onIconChange } = props;

  return (
    <div className={classes.writerWrapper}>
      <Typography variant={"body1"} className={classes.writerTitle} color={"textSecondary"}>
        {I18n.t("edit_post.select_icon")}
      </Typography>
      <div className={classes.writersWrapper}>
        <MSelect
          customClass={classes.selectCustomClass}
          customDropdownIndicatorClasses={classes.arrowIcon}
          options={bulletedListIconList}
          width={180}
          value={icon ? _.find(bulletedListIconList, { key: icon }) : null}
          searchable
          placeholder={I18n.t("edit_post.select_ph")}
          borderColor={theme.palette.divider}
          textColor={theme.palette.common.white}
          textColorHover={theme.palette.common.white}
          optionBackgroundColorHover={theme.palette.secondary.main}
          borderWidth={"0px"}
          height={32}
          menuPlacement={"bottom"}
          handleChange={(selectedItem) => {
            console.log("onChange:::", selectedItem);
            onIconChange(selectedItem);
          }}
          getDisplayLabel={(icon) => {
            const Icon = icon.icon;
            return <Icon style={{ fill: color }} />;
          }}
          optionLabel={"key"}
          optionValue={"key"}
        />
        {/*{isClearable && <CloseIcon className={classes.clearIcon} onClick={handleClear} />}*/}
      </div>
    </div>
  );
};

export default IconPickerElement;
