import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { useTheme } from "@material-ui/core/styles";
import FetchKeywordsList from "./FetchKeywordsList";
import { IWebsite } from "../../reducers/constants/objectTypes";
import { FETCH_KEYWORDS } from "../../reducers/constants/actionTypes";
import agent from "../../agent";
import AddSeedsDialog from "./AddSeedsDialog";
import MTableWrapper from "../../components/Table/MTableWrapper";
import MTableRowProgressBar from "../../components/Table/Rows/MTableRowProgressBar";
import MTableToolbar from "../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarBaseSelect from "../../components/Table/Toolbar/MTableToolbarBaseSelect";
import MTableToolbarActionDisplayText from "../../components/Table/Toolbar/Actions/MTableToolbarActionDisplayText";
import MTextField from "../../components/MTextField";
import MTableToolbarBaseActionButton from "../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import MTablePagination from "../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../components/MHeaderMetaTags";

const useStyles = makeStyles((theme: Theme) => ({
  fetchContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  fetchLimitTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: 10,
  },
  fetchLimitInput: {
    // height: 30,
    width: 100,
    borderRadius: 16,
    backgroundColor: "white",
    margin: 0,
  },
}));

const mapStateToProps = (state) => ({
  seedsListLoaded: state.fetchKeywords.seedsListLoaded,
  seedsList: state.fetchKeywords.seedsList,
  searchText: state.fetchKeywords.filter.searchText,
  selectedType: state.fetchKeywords.filter.selectedType,
  selectedWebsite: state.home.selectedWebsite,
  totalKeywordsCount: state.fetchKeywords.totalKeywordsCount,
  balance: state.fetchKeywords.balance,
  checkedSeedsList: state.fetchKeywords.checkedSeedsList,
});

const mapDispatchToProps = (dispatch) => ({
  handleSeedsListLoaded: (payload) => {
    dispatch({ type: FETCH_KEYWORDS.ON_SEEDS_LIST_LOADED, payload });
  },
  handleSeedsListAdded: (seeds) => {
    dispatch({ type: FETCH_KEYWORDS.ON_SEEDS_LIST_ADDED, seeds });
  },
  handleSearchTextChanged: (searchText) => {
    dispatch({ type: FETCH_KEYWORDS.ON_SEARCH_TEXT_CHANGED, searchText });
  },
  handleCheckedBoxChanged: (checkedSeedsList) => {
    dispatch({ type: FETCH_KEYWORDS.ON_CHECKED_BOX_CHANGED, checkedSeedsList });
  },
  clearSeeds: () => {
    dispatch({ type: FETCH_KEYWORDS.CLEAR_CHECKED_SEEDS });
  },
  setBalance: (balance) => {
    dispatch({ type: FETCH_KEYWORDS.SET_API_BALANCE, balance });
  },
  onFilterChanged: (payload) => {
    dispatch({ type: FETCH_KEYWORDS.ON_FETCH_KEYWORDS_FILTER_CHANGED, payload });
  },
  onSeedsRemoved: (seeds) => {
    dispatch({ type: FETCH_KEYWORDS.ON_SEEDS_REMOVED, seeds });
  },
  handleSeedsLoaded: (payload) => {
    dispatch({ type: FETCH_KEYWORDS.ON_SEEDS_LIST_LOADED, payload });
  },
  clearCheckedSeeds: () => {
    dispatch({ type: FETCH_KEYWORDS.CLEAR_CHECKED_SEEDS });
  },
});

type PropTypes = {
  seedsListLoaded: boolean;
  seedsList: any[];
  searchText: string;
  selectedType: string;
  selectedWebsite: IWebsite;
  totalKeywordsCount: number;
  balance: number;
  checkedSeedsList: any[];
  handleSeedsListLoaded: (seedsList) => void;
  handleSeedsListAdded: (payload) => void;
  handleSearchTextChanged: (searchText) => void;
  handleCheckedBoxChanged: (checkedSeedsList) => void;
  clearSeeds: () => void;
  onFilterChanged: (payload) => void;
  setBalance: (balance) => void;
  onSeedsRemoved: (seeds) => void;
  handleSeedsLoaded: (payload) => void;
  clearCheckedSeeds: () => void;
};

const FetchKeywords = (props: PropTypes) => {
  const classes = useStyles(props);

  const {
    seedsListLoaded = true,
    seedsList,
    selectedWebsite,
    searchText,
    handleSeedsListLoaded,
    handleSeedsListAdded,
    totalKeywordsCount = 35,
    clearSeeds,
    handleCheckedBoxChanged,
    balance,
    setBalance,
    onFilterChanged,
    selectedType,
    checkedSeedsList,
    onSeedsRemoved,
    handleSeedsLoaded,
    clearCheckedSeeds,
  } = props;

  const [loading, setLoading] = React.useState(false);
  // const [allChecked, setAllChecked] = React.useState(false);
  // const [paginationLimit, setPaginationLimit] = React.useState(100);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [fetchValue, setFetchValue] = React.useState(100);
  const [refreshBalance, setRefreshBalance] = React.useState(false);
  const [paginationNumber, setPaginationNumber] = React.useState("20");
  const [page, setPage] = React.useState<number>(1);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [sources, setSources] = React.useState<{
    keywords: string;
    domains: string;
    pages: string;
  }>({ keywords: "", domains: "", pages: "" });

  const onSourceChange = (column: string, value: string) => {
    setSources((oldSource) => ({
      ...oldSource,
      [column]: value,
    }));
  };

  const typeOptions = [
    { _id: 0, name: "All" },
    { _id: 1, name: "keyword" },
    { _id: 2, name: "domain" },
    { _id: 3, name: "page" },
  ];

  const loadSeeds = () => {
    handleSeedsListLoaded(
      agent.Seeds.getSeeds({
        website: selectedWebsite?._id,
        type: selectedType,
        limit: paginationNumber,
        page: String(page),
        search: searchText,
      })
    );
  };

  const handlePaginationNumberChanged = (number) => {
    setPaginationNumber(number);
  };

  const fetchSeed = (id) => {
    setLoading(true);
    agent.Seeds.fetchSeed(id, fetchValue)
      .then(() => {
        enqueueSnackbar(I18n.t("fetch_keywords.fetched"));
        loadSeeds();
        setRefreshBalance((oldValue) => !oldValue);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.response?.body?.message || "" }));
      });
  };

  const createSeeds = () => {
    if (!selectedWebsite) {
      enqueueSnackbar(I18n.t("snackbar.update_error", { msg: "Please choose a customer from the left panel" }));
      return;
    }

    const keywords = sources.keywords
      ? sources.keywords.split("\n").map((seed) => ({
          seed,
          type: "keyword",
          source: "semrush",
          website: selectedWebsite._id,
        }))
      : [];
    const pages = sources.pages
      ? sources.pages
          .split("\n")
          .map((seed) => ({ seed, type: "page", source: "semrush", website: selectedWebsite._id }))
      : [];
    const domains = sources.domains
      ? sources.domains.split("\n").map((seed) => ({
          seed,
          type: "domain",
          source: "semrush",
          website: selectedWebsite._id,
        }))
      : [];

    const data = keywords.concat(pages).concat(domains);

    agent.Seeds.createSeeds(data)
      .then((res) => handleSeedsListAdded(res))
      .catch((e) => enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message })));

    setSources({
      keywords: "",
      domains: "",
      pages: "",
    });
  };

  React.useEffect(() => {
    agent.Seeds.getBalance()
      .then((res) => {
        setBalance(res.data || "0");
      })
      .catch((error) => {
        console.log("Error while fetching balance", error);
      });
  }, [refreshBalance]);

  React.useEffect(() => {
    setLoading(true);
    handleCheckedBoxChanged([]);
    loadSeeds();
    setLoading(false);
  }, [seedsListLoaded, selectedWebsite, paginationNumber, page, searchText, selectedType]);

  React.useEffect(() => {
    clearSeeds();
  }, [selectedWebsite]);

  let filteredSeedsList = seedsListLoaded ? seedsList : [];
  if (searchText !== "") {
    filteredSeedsList = filteredSeedsList.filter((seed) => seed.seed.toLowerCase().includes(searchText.toLowerCase()));
  }
  const deleteSelectedSeeds = () => {
    const selectedSeeds = checkedSeedsList.map((seed) => seed._id);
    agent.Seeds.deleteSeeds(selectedSeeds);
    enqueueSnackbar(I18n.t("fetch_keywords.deleted"));
  };
  const fetchAll = () => {
    const selectedSeeds = checkedSeedsList.map((seed) => seed._id);

    agent.Seeds.fetchAll(selectedSeeds, fetchValue)
      .then(() => {
        enqueueSnackbar(I18n.t("fetch_keywords.fetched"));

        setRefreshBalance((oldValue) => !oldValue);

        handleSeedsLoaded(
          agent.Seeds.getSeeds({
            website: selectedWebsite?._id,
            type: selectedType,
            limit: paginationNumber,
            skip: (page - 1) * parseInt(paginationNumber, 10),
            search: searchText,
          })
        );

        clearCheckedSeeds();
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.response?.body?.message || "" }));
      });
  };

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t("meta_tags.strategy.fetch.title")} />
      {loading && <MTableRowProgressBar />}
      <MTableToolbar
        searchText={searchText}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
        actions={[
          <MTableToolbarActionDisplayText
            text={I18n.t("fetch_keywords.balance_total", { count: balance || 0 })}
            divider
          />,
          <MTableToolbarActionDisplayText
            text={I18n.t("fetch_keywords.total_existing_kws_total", { count: totalKeywordsCount || 0 })}
            divider
          />,
          <div className={classes.fetchContainer}>
            <Typography className={classes.fetchLimitTitle} variant={"body1"}>
              {I18n.t("fetch_keywords.fetch_limit")}
            </Typography>
            <MTextField
              outlined
              type="number"
              height={40}
              borderRadius={25}
              customClass={classes.fetchLimitInput}
              onChange={(number) => setFetchValue(number)}
              value={String(fetchValue)}
            />
          </div>,
          <MTableToolbarBaseActionButton
            handleClick={() => {
              setOpenDialog(true);
            }}
            text={I18n.t("fetch_keywords.add_sources")}
          />,
        ]}
        selectedRows={checkedSeedsList.length}
        onClearSelectedRows={() => {
          handleCheckedBoxChanged([]);
        }}
        selectedRowActions={[
          {
            text: I18n.t("fetch_keywords.delete_seeds"),
            color: theme.palette.error.main,
            onClick: () => {
              onSeedsRemoved(checkedSeedsList);
              deleteSelectedSeeds();
            },
          },
          {
            text: I18n.t("fetch_keywords.fetch_keywords"),
            color: theme.palette.primary.main,
            onClick: fetchAll,
          },
        ]}
      >
        <MTableToolbarBaseSelect
          options={typeOptions}
          selectedOptions={selectedType ? [typeOptions.find((type) => type.name === selectedType)] : []}
          handleChange={(value) => {
            if (value[0]?.name === "All") {
              onFilterChanged({ selectedType: null });
            } else {
              onFilterChanged({ selectedType: value[0]?.name });
            }
          }}
          placeholder={I18n.t("fetch_keywords.type_ph")}
        />
      </MTableToolbar>
      <FetchKeywordsList fetchSeed={fetchSeed} seedsList={filteredSeedsList} loaded={seedsListLoaded} />
      {totalKeywordsCount > 0 && (
        <MTablePagination
          paginationLimit={Number(paginationNumber)}
          page={page}
          paginationPagesCount={Math.ceil(totalKeywordsCount / parseInt(paginationNumber, 10))}
          setPaginationLimit={handlePaginationNumberChanged}
          setPage={setPage}
        />
      )}
      <AddSeedsDialog
        open={openDialog}
        handleCloseClick={() => setOpenDialog(false)}
        createSeeds={createSeeds}
        values={sources}
        handleChange={onSourceChange}
      />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FetchKeywords);
