import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Switch } from "@material-ui/core";
import KeywordTypesList from "./KeywordTypesList";
import MTableWrapper from "../../../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../../../components/Table/Toolbar/MTableToolbar";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import MTableToolbarBaseActionButton from "../../../../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import KeywordTypesAddNewKeywordDialog from "./KeywordTypesAddNewKeywordDialog";
import agent from "../../../../../agent";
import { KEYWORDTYPES } from "../../../../../reducers/constants/actionTypes";

const useStyles = makeStyles((theme: Theme) => ({
  verticalDivider: {
    border: `1px solid ${theme.palette.divider}`,
    height: "75%",
    width: 1,
    marginLeft: 5,
    marginRight: 5,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  page: number;
  pageSize: number;
  searchText: string;
  data: [];
  rowSelected: [];

  handleDataLoaded: (payload) => void;
  handleOnSearchTextChanged: (searchText: string) => void;
  handleOnRowSelectedChanged: (rowSelected: []) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  searchText: state.keywordTypes.searchText,
  page: state.keywordTypes.page,
  pageSize: state.keywordTypes.pageSize,
  rowSelected: state.keywordTypes.rowSelected,

  data: state.keywordTypes.data,
});

const mapDispatchToProps = (dispatch) => ({
  handleDataLoaded: (payload) => {
    dispatch({ type: KEYWORDTYPES.KEYWORDTYPES_LOADED, payload });
  },
  handleOnSearchTextChanged: (searchText) => {
    dispatch({ type: KEYWORDTYPES.ON_SEARCHTEXT_CHANGED, searchText });
  },
  handleOnRowSelectedChanged: (rowSelected) => {
    dispatch({ type: KEYWORDTYPES.ON_ROWSELECTED_CHANGED, rowSelected });
  },
});

const KeywordTypes = (props: PropTypes) => {
  const classes = useStyles();
  const {
    searchText,
    handleOnSearchTextChanged,
    handleDataLoaded,
    pageSize,
    page,
    selectedWebsite,
    rowSelected,
    handleOnRowSelectedChanged,
  } = props;
  const [addNewKeywordTypeDialog, setAddNewKeywordTypeDialog] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(false);
  const getKeywordsTypes = () => {
    handleDataLoaded(agent.KeywordTypes.getKeywordsTypes(null, searchText, pageSize, page));
  };

  React.useEffect(() => {
    getKeywordsTypes();
  }, [page, pageSize, searchText]);

  function addWebsites() {
    if (checkAll) {
      agent.KeywordTypes.removeWebsite(
        rowSelected.map((row) => row.original._id),
        selectedWebsite._id
      )
        .then((res) => {
          getKeywordsTypes();
        })
        .catch((e) => {
          console.log("Error", e);
        });
    } else {
      agent.KeywordTypes.addWebsite(
        rowSelected.map((row) => row.original._id),
        selectedWebsite._id
      )
        .then((res) => {
          getKeywordsTypes();
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }

    setCheckAll(!checkAll);
  }

  return (
    <MTableWrapper>
      <MTableToolbar
        searchText={searchText}
        handleSearchTextChanged={(value) => handleOnSearchTextChanged(value)}
        actions={[
          <MTableToolbarBaseActionButton
            handleClick={() => setAddNewKeywordTypeDialog(true)}
            text={I18n.t("strategy.settings.add_type")}
          />,
        ]}
        onClearSelectedRows={() => {
          handleOnRowSelectedChanged([]);
        }}
        selectedRowActions={[
          <span className={classes.verticalDivider} />,
          <Switch
            color="primary"
            checked={checkAll}
            onChange={(val) => {
              addWebsites();
            }}
          />,
        ]}
        selectedRows={rowSelected.length}
      />
      <KeywordTypesList keywordTypes={undefined} />
      {addNewKeywordTypeDialog && (
        <KeywordTypesAddNewKeywordDialog
          handleCloseClick={() => {
            setAddNewKeywordTypeDialog(false);
          }}
          handleAddedNewKeywordType={() => {
            setAddNewKeywordTypeDialog(false);
            handleDataLoaded(agent.KeywordTypes.getKeywordsTypes(null, searchText, pageSize, page));
          }}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordTypes);
