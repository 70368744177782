import React, { useMemo, useState } from "react";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { replace } from "connected-react-router";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CfSearchInput from "../../../components/CfSearchInput";
import { HOME } from "../../../reducers/constants/actionTypes";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px 20px",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  websiteWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    justifyContent: "center",
    marginLeft: 14,
    cursor: "pointer",
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  icon: {
    color: theme.palette.text.primary,
  },
  root: {
    top: 49,
    zIndex: 1000,
    left: 0,
  },
  paper: {
    borderRadius: 5,
    border: `solid 1px ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    boxShadow: "3px 2px 7px 0px rgba(0, 0, 0, 0.12)",
    width: 300,
    padding: "10px 20px",
    display: "flex",
    flexDirection: "column",
    left: "8px !important",
    top: "49px !important",
    gap: 7,
    maxHeight: 503,
  },
  searchInput: {
    backgroundColor: "transparent",
  },
  searchField: {
    width: "100%",
    height: "unset",
    marginBottom: 10,
  },
  groupList: {
    maxHeight: 158,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: "0 20px 0 0",
    margin: 0,
    overflow: "auto",
    borderLeft: `solid 1px ${theme.palette.divider}`,
    gap: 5,
  },
  itemPrimaryText: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  itemSelectedColor: {
    color: theme.palette.primary.main,
  },
  itemSelectedBorder: {
    borderLeft: `solid 2px ${theme.palette.primary.main} !important`,
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
    borderTop: "none",
    borderRight: "none",
    borderBottom: "none",
    borderLeft: `solid 2px transparent`,
    cursor: "pointer",
    "& .MuiListItemText-root": {
      margin: 0,
    },
    padding: "5px 12px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  listRightSide: {
    alignItems: "center",
    display: "flex",
    marginRight: -5,
  },
  checkIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "start",
    minWidth: 22,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)",
    borderRadius: "3px",
    padding: "12px",
  },
  logo: {
    width: 26,
    borderRadius: 0,
    height: 26,
  },
  websiteCounter: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  emptyText: {
    color: theme.palette.text.secondary,
    fontSize: `${theme.typography.pxToRem(14)}`,
  },
}));

const WebsitesDropdown = ({ handleClose }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleWebsiteChanged = (url) => {
    dispatch(replace(url));
  };
  const [searchText, setSearchText] = useState<string>("");

  const onWebsiteSelected = (selectedWebsite) => {
    dispatch({ type: HOME.ON_WEBSITES_SELECTED, selectedWebsite });
  };

  const { websites, selectedWebsite } = useSelector((state) => ({
    websites: state.home.websites,
    selectedWebsite: state.home.selectedWebsite,
  }));

  const { pathName } = useSelector((state) => ({
    pathName: state.router.location.pathname,
  }));

  const filteredWebsites = useMemo(
    () =>
      websites
        .filter((website) => (searchText ? website.name.toLowerCase().includes(searchText) : website))
        .sort((a, b) => (a._id === selectedWebsite._id ? -1 : b._id === selectedWebsite._id ? 1 : 0)),
    [websites, searchText, selectedWebsite]
  );

  return (
    <div className={classes.wrapper}>
      <Typography variant={"subtitle2"} className={classes.websiteCounter}>
        {websites.length}{" "}
        {websites.length > 1 ? I18n.t("main.user_dropdown.websites") : I18n.t("main.user_dropdown.website")}
      </Typography>
      <CfSearchInput
        customClass={classes.searchField}
        autoSearch
        autoFocus
        variant={"standard"}
        handleOnEnter={() => {
          if (filteredWebsites.length === 1) {
            onWebsiteSelected(filteredWebsites[0]);
            handleClose();
            handleWebsiteChanged(pathName.replace(selectedWebsite.name, filteredWebsites[0].name));
          }
        }}
        customInputClass={classes.searchInput}
        placeholder={I18n.t("data_customer_list.search_customers_ph")}
        handleSearchTextChange={setSearchText}
      />
      <List component="nav" className={classes.groupList}>
        {filteredWebsites?.length > 0 ? (
          filteredWebsites.map((website) => (
            <ListItem
              divider
              key={website._id}
              className={ClassNames(
                selectedWebsite.name === website.name && classes.itemSelectedBorder,
                classes.listItem
              )}
              onClick={() => {
                onWebsiteSelected(website);
                handleClose();
                handleWebsiteChanged(pathName.replace(selectedWebsite.name, website.name));
              }}
            >
              <>
                <ListItemText
                  primary={website.name}
                  primaryTypographyProps={{
                    variant: "subtitle2",
                    className: ClassNames(
                      classes.itemPrimaryText,
                      selectedWebsite.name === website.name && classes.itemSelectedColor
                    ),
                  }}
                />
                {selectedWebsite.name === website.name && (
                  <div className={classes.listRightSide}>
                    <CheckIcon className={classes.checkIcon} />
                  </div>
                )}
              </>
            </ListItem>
          ))
        ) : (
          <ListItem button>
            <ListItemText
              classes={{
                primary: classes.emptyText,
              }}
              primary={I18n.t("data_customer_list.nothing_to_show")}
            />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default WebsitesDropdown;
