import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useState } from "react";
import moment from "moment";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import SinglePageMetrics from "./components/SinglePageMetrics";
import SinglePageTable from "./components/SinglePageTable";

const useStyles = makeStyles((theme: Theme) => ({
  positions: {
    margin: 10,
    height: "100%",
    display: "flex",
    flex: 1,
    // overflow: 'hidden',
  },
  positionsContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // height: 'calc(100vh - 93px)',
    // overflow: 'auto',
  },
  graphWrapper: {
    backgroundColor: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};

const labels = {
  positions: ["0-3", "4-10", "11-20", "21-50", "51-100"],
  impressions: ["10k-1m", "1k-10k", "100-1k", "1-100"],
  clicks: ["50+", "20-50", "10-20", "5-10", "1-5", "0"],
};

const SinglePageTraffic = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalView, setTotalView] = React.useState(false);
  const [hiddenAreas, setHiddenAreas] = React.useState(new Set());
  const [interval, setInterval] = useState<"day" | "week" | "month" | "quarter">("month");
  const [startDate, setStartDate] = useState<string>(moment().subtract(6, "month").format("YYYY-MM-DD"));
  const [dataSet, setDataSet] = useState<"impressions" | "clicks" | "positions">("positions");

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    setLoading(true);
    agent.Analysis.singlePage(selectedWebsite._id, interval, moment(startDate, "YYYY-MM-DD"), moment(), totalView)
      .then((res) => {
        setPosts(res.posts);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    return () => {
      setPosts([]);
    };
  }, [selectedWebsite, interval, startDate, totalView]);

  return (
    <div className={classes.positions}>
      <div className={classes.positionsContent}>
        <SinglePageMetrics
          interval={interval}
          handleIntervalChange={(selectedInterval) => setInterval(selectedInterval)}
          startDate={startDate}
          handleStartDateChange={(selectedStartDate) => setStartDate(selectedStartDate)}
          total={totalView}
          handleTotalChange={(total) => setTotalView(total)}
          dataSet={dataSet}
          handleDataSetChange={(ds) => setDataSet(ds)}
        />
        <div className={classes.graphWrapper}>
          {/*<MetricsGraph labels={labels[dataSet]} dataSet={dataSet} metrics={metrics} hiddenAreas={hiddenAreas} loading={loading} interval={interval} />*/}
          {posts.length && (
            <SinglePageTable
              labels={labels[dataSet]}
              dataSet={dataSet}
              posts={posts}
              hiddenAreas={hiddenAreas}
              handleHiddenAreasChange={setHiddenAreas}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SinglePageTraffic;
