import * as React from "react";
import { connect } from "react-redux";
import moment from "moment/moment";
import { I18n } from "react-redux-i18n";
import { useLocation } from "react-router-dom";
import { push } from "connected-react-router";
import _ from "lodash";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import MTextField from "../../../components/MTextField";
import CfButton from "../../../components/CfButton";
import agent from "../../../agent";
import { ICategory, IWebsite } from "../../../reducers/constants/objectTypes";
import { AbilityContext } from "../../../casl/can";
import { web } from "../../../helpers/urlHelper";
import MSelect from "../../../components/MSelect";
import localeHelper from "../../../helpers/localeHelper";
import { getAvailablePageTypes } from "../../../helpers/websiteHelper";
import MTextButton from "../../../components/MTextButton";
import MDatePicker from "../../../components/MDatePicker";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderWidth: 0,
    maxWidth: 508,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    margin: 0,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
    gap: 24,
  },
  contentWrapper: {
    width: "100%",
    gap: 24,
    display: "flex",
    flexDirection: "column",
    maxHeight: 400,
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    //marginTop: 50,
  },
  textField: {
    margin: 0,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: 8,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: 10,
  },
  postButton: {
    fontWeight: theme.typography.fontWeightBold as any,
    borderRadius: 30,
    textTransform: "unset",
  },
  dialogLeftActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cancelButton: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.disabled,
  },
  dueDateField: {
    height: 37,
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 5,
    padding: "0 10px",
    margin: 0,
  },
  dueDateFieldInput: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
    color: theme.palette.text.primary,
    lineHeight: 1,
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(25),
    cursor: "pointer",
    marginRight: "-5px",
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  open: boolean;
  websitesLoaded: boolean;
  websites: IWebsite[];
  selectedWebsite: IWebsite;
  categories: ICategory[];

  handleCloseClick: Function;
  handleNewWebsiteClick: Function;
  openPost: Function;
};

const mapStateToProps = (state) => ({
  websitesLoaded: state.home.websitesLoaded,
  websites: state.home.websites,
  selectedWebsite: state.home.selectedWebsite,
  categories: state.category.categories,
});

const mapDispatchToProps = (dispatch) => ({
  openPost: (pageId, currentUrl) => dispatch(push(web.editPage(pageId, currentUrl))),
});

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const CreateNewArticleDialog = (props: PropTypes) => {
  const classes = useStyles(props);

  const { websites, selectedWebsite, openPost, handleNewWebsiteClick, categories } = props;
  const { open, handleCloseClick } = props;

  const pageTypes = getAvailablePageTypes(selectedWebsite);

  const [selectedPageType, setSelectedPageType] = React.useState(pageTypes?.[0]);
  const [locale, setLocale] = React.useState(selectedWebsite.defaultLocale);
  const [title, setTitle] = React.useState("New Article");
  const [category, setCategory] = React.useState(categories?.[0]);
  const [dueDate, setDueDate] = React.useState<Date>(moment().add(3, "weeks").toDate());
  const [showTOC, setShowToc] = React.useState(
    selectedWebsite.configurations?.commonComponents?.addons?.toc?.defaultState
  );

  const showTocOptions = [
    {
      _id: true,
      name: I18n.t("common.yes"),
    },
    {
      _id: false,
      name: I18n.t("common.no"),
    },
  ];

  const location = useLocation();
  const ability = React.useContext<any>(AbilityContext);

  const defaultLocale = {
    key: selectedWebsite.configurations.locale.defaultLocale,
    label: localeHelper.getLanguageFromLocaleCode(selectedWebsite.configurations.locale.defaultLocale),
  };

  const dynamicLocales = selectedWebsite.configurations.locale.alternateLocales.map((locale) => ({
    key: locale.locale,
    label: localeHelper.getLanguageFromLocaleCode(locale.locale),
  }));

  const localesOptions = [defaultLocale, ...dynamicLocales];

  if (pageTypes.length === 0 && !selectedWebsite.configurations.sections.forum) {
    alert("user doesn't have any page types enabled");
    return null;
  }

  const close = () => {
    handleCloseClick();
  };

  const createNewPage = () => {
    agent.Pages.createPage(
      "",
      locale,
      selectedWebsite._id,
      title,
      "default",
      selectedPageType.key,
      dueDate,
      dueDate,
      null,
      null,
      [category._id],
      showTOC
    )
      .then((res) => {
        openPost(res.page._id, location.pathname);
      })
      .catch((e) => {});
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      onClose={(event) => close()}
      fullWidth
      maxWidth={"md"}
      TransitionComponent={Transition}
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.titleWrapper}>
          <Typography color={"inherit"} className={classes.dialogTitle} variant={"h3"}>
            {I18n.t("main.new_post.new_article")}
          </Typography>
          <CloseIcon onClick={() => close()} className={classes.closeIcon} />
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.column}>
            <Typography color={"textPrimary"} variant={"body1"} className={classes.fieldTitle}>
              {I18n.t("main.new_post.title")}
            </Typography>
            <MTextField
              fullWidth
              autoFocus
              outlined
              customClass={classes.textField}
              borderRadius={5}
              height={37}
              value={title}
              placeholder={I18n.t("main.new_post.title_ph")}
              onChange={(userInput) => {
                setTitle(userInput);
              }}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.column}>
              <Typography color={"textPrimary"} variant={"body1"} className={classes.fieldTitle}>
                {I18n.t("main.new_post.page_type")}
              </Typography>
              <MSelect
                isBold={false}
                fullWidth
                value={_.find(pageTypes, { key: selectedPageType?.key }) || null}
                optionLabel={"label"}
                optionValue={"key"}
                height={37}
                customClass={classes.textField}
                options={pageTypes}
                borderWidth={1}
                borderRadius={5}
                maxMenuHeight={150}
                placeholder={I18n.t("main.new_post.page_type_ph")}
                handleChange={(value) => setSelectedPageType(value)}
              />
            </div>
            <div className={classes.column}>
              <Typography color={"textPrimary"} variant={"body1"} className={classes.fieldTitle}>
                {I18n.t("main.new_post.locale")}
              </Typography>
              <MSelect
                isBold={false}
                fullWidth
                value={_.find(localesOptions, { key: locale })}
                optionLabel={"label"}
                optionValue={"key"}
                height={37}
                customClass={classes.textField}
                options={localesOptions}
                borderWidth={1}
                borderRadius={5}
                maxMenuHeight={150}
                placeholder={I18n.t("main.new_post.locale_ph")}
                handleChange={(value) => setLocale(value.key)}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.column}>
              <Typography color={"textPrimary"} variant={"body1"} className={classes.fieldTitle}>
                {I18n.t("main.new_post.category")}
              </Typography>
              <MSelect
                isBold={false}
                maxMenuHeight={80}
                fullWidth
                value={_.find(categories, { _id: category?._id }) || null}
                optionLabel={"name"}
                optionValue={"_id"}
                height={37}
                customClass={classes.textField}
                options={categories}
                borderWidth={1}
                borderRadius={5}
                searchable
                placeholder={I18n.t("main.new_post.category_ph")}
                handleChange={(value) => setCategory(value)}
              />
            </div>
            <div className={classes.column}>
              <Typography color={"textPrimary"} variant={"body1"} className={classes.fieldTitle}>
                {I18n.t("main.new_post.set_due_date")}
              </Typography>
              <MDatePicker
                views={["date"]}
                customClass={classes.dueDateField}
                inputClassName={classes.dueDateFieldInput}
                disableDateIcon
                dateFormat={"MMM DD, YYYY"}
                minDate={moment(dueDate || Date.now()).subtract(1, "year")}
                maxDate={moment().add(1, "year")}
                placeholder={I18n.t("main.new_post.set_due_date_ph")}
                disableFuture={false}
                date={dueDate ? moment(dueDate) : null}
                onDateChanged={(date) => setDueDate(date)}
              />
            </div>
          </div>
        </div>
        <DialogActions className={classes.dialogActions}>
          {ability.can("owner", "role") ? (
            <CfButton
              height={32}
              customClass={classes.postButton}
              text={I18n.t("main.new_post.new_website")}
              onClick={() => {
                handleNewWebsiteClick();
                close();
              }}
            />
          ) : (
            <div />
          )}
          <div className={classes.dialogLeftActions}>
            <MTextButton
              onClick={() => {
                close();
              }}
              text={I18n.t("main.new_post.cancel")}
              customClass={classes.cancelButton}
            />
            <CfButton
              height={32}
              disabled={!title || !selectedPageType || !category || !dueDate}
              text={I18n.t("main.new_post.create_article")}
              customClass={classes.postButton}
              onClick={() => {
                close();
                createNewPage();
              }}
            />
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewArticleDialog);
