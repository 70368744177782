import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { useParams } from "react-router-dom";
import { push, replace } from "connected-react-router";
import LinksSplitTestsSearchBar from "./components/LinksSplitTestsSearchBar";
import LinksSplitTestsList from "./components/LinksSplitTestsList";
import { ILinksSplitTest, IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import EditLinksSplitTestDialog from "./components/EditLinksSplitTestDialog/EditLinksSplitTestDialog";
import { web } from "../../../../helpers/urlHelper";
import MTable from "../../../../components/Table/Table/MTable";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarBaseActionButton from "../../../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";

const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    // width: "100%",
    backgroundColor: theme.palette.background.default,
    margin: "25px 20px 0 10px",
    padding: "20px 10px 0",
    height: "calc(100% - 30px)",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  openTestEditor: (testId) => {
    dispatch(push(web.croLinksSplitTest(testId)));
  },
  closeTestEditor: () => {
    dispatch(replace(web.croLinksSplitTest()));
  },
});

type PropTypes = {
  selectedWebsite: IWebsite;
  openTestEditor: (testId: string) => void;
  closeTestEditor: () => void;
};

const LinksSplitTests = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, openTestEditor, closeTestEditor } = props;
  const [searchText, setSearchText] = React.useState("");
  const [loaded, setLoaded] = React.useState(false);
  const [tests, setTests] = React.useState<ILinksSplitTest[]>([]);
  const [showNewDialog, setShowNewDialog] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const { testId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    setLoaded(false);
    agent.LinksSplitTest.getTests(selectedWebsite._id)
      .then((res) => {
        setTests(res.tests);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        console.log("e", e);
      });
  }, [selectedWebsite]);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    if (!refresh) {
      return;
    }
    setLoaded(false);
    agent.LinksSplitTest.getTests(selectedWebsite._id)
      .then((res) => {
        setTests(res.tests);
        setRefresh(false);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        setRefresh(false);
        console.log("e", e);
      });
  }, [selectedWebsite, refresh]);

  const deleteTest = (test) => {
    agent.LinksSplitTest.deleteTest(selectedWebsite._id, test)
      .then((res) => {
        setRefresh(true);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
        setRefresh(true);
        console.log("e", e);
      });
  };

  console.log("testId", testId);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.cro.experiments.title`)} />
      <MTableToolbar
        searchText={searchText}
        handleSearchTextChanged={(value) => setSearchText(value)}
        actions={[
          <MTableToolbarBaseActionButton
            handleClick={() => openTestEditor("new-test")}
            text={I18n.t("links_split_test.table.new_test")}
          />,
        ]}
      />
      <LinksSplitTestsList
        loaded={loaded}
        tests={tests}
        searchText={searchText}
        openTestEditor={(test) => openTestEditor(test._id)}
        deleteTest={deleteTest}
        refreshList={() => setRefresh(true)}
      />
      {testId && (
        <EditLinksSplitTestDialog
          selectedWebsite={selectedWebsite}
          open={true}
          testId={testId}
          handleCloseClick={() => closeTestEditor()}
          handleTestChanged={(test: ILinksSplitTest, newTest) =>
            setTests((prevTests) => {
              if (newTest) {
                return [...prevTests, test];
              }

              return prevTests.map((row) => (row._id === test._id ? test : row));
            })
          }
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LinksSplitTests);
