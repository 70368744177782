import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 18,
    height: 19,
  },
}));

type PropTypes = {
  className?: string;
};

const AiIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 18 19"}>
      <rect y="0.5" width="18" height="18" rx="2" fill="#0298C8" />
      <path
        d="M11.118 14.5H10.218C10.114 14.5 10.03 14.474 9.966 14.422C9.902 14.37 9.854 14.304 9.822 14.224L9.018 12.148H5.16L4.356 14.224C4.328 14.296 4.28 14.36 4.212 14.416C4.144 14.472 4.06 14.5 3.96 14.5H3.06L6.498 5.902H7.68L11.118 14.5ZM5.484 11.308H8.694L7.344 7.81C7.256 7.594 7.17 7.324 7.086 7C7.042 7.164 6.998 7.316 6.954 7.456C6.914 7.592 6.874 7.712 6.834 7.816L5.484 11.308ZM13.5803 14.5H12.4163V5.902H13.5803V14.5Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default AiIcon;
