import * as React from "react";
import _ from "lodash";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import agent from "../../../agent";
import { ePageType, IAdminUser, IWebsite } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";
import PageEditorMissingBioTooltip from "./PageEditorMissingBioTooltip";

const useStyles = makeStyles((theme: Theme) => ({
  writersWrapper: {
    width: "100%",
  },
  writerWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 3,
  },
  writerTitle: {
    minWidth: 80,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 7,
  },
  selectCustomClass: {
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  editor: IAdminUser;
  postType: ePageType | string;
  locale?: string;
  factChecker?: boolean;

  handleEditorSelect: (writer) => void;
};

const PageEditorReviewer = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, postType, selectedWebsite, locale = "en", handleEditorSelect, factChecker = false } = props;
  const [editors, setEditors] = useState([]);
  const [selectedEditor, setSelectedEditor] = useState(null);
  const [error, setError] = useState(false);
  const theme = useTheme<Theme>();

  React.useEffect(() => {
    setSelectedEditor(editor);
  }, [editor]);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    agent.Writers.getEditorsV2(selectedWebsite, postType, locale)
      .then((res) => {
        setEditors(res.editors);
      })
      .catch((e) => {
        // show error message
      });
  }, [selectedWebsite, postType, locale]);

  const selectableWriters = editors;

  return (
    <div className={classes.writerWrapper}>
      <Typography variant={"body1"} className={classes.writerTitle}>
        {I18n.t(`edit_post.${factChecker ? "fact_checker" : "editor"}`)}
      </Typography>
      <MSelect
        customClass={classes.selectCustomClass}
        options={selectableWriters}
        value={_.find(selectableWriters, { email: editor?.email || null })}
        optionLabel={"name"}
        optionValue={"_id"}
        height={31}
        borderWidth={error ? "1px" : "0px"}
        borderColor={error ? "#F04444" : null}
        isBold={false}
        textColor={error ? "#F04444" : theme.palette.text.primary}
        menuPlacement={"bottom"}
        singleValueStyles={{
          fontSize: theme.typography.pxToRem(12),
          color: error ? "#F04444" : theme.palette.text.primary,
        }}
        fullWidth
        searchable
        getDisplayLabel={(obj: IAdminUser) => `${obj.name}`}
        handleChange={(selectedWriter: IAdminUser) => {
          setSelectedEditor(selectedWriter);
          handleEditorSelect(selectedWriter);
        }}
      />
      <PageEditorMissingBioTooltip selectedWebsite={selectedWebsite} user={selectedEditor} onBioMissing={setError} />
    </div>
  );
};

export default PageEditorReviewer;
