import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { IWidgetSettings } from "../../../../../../../../../reducers/constants/objectTypes";
import CustomSwitchOption from "../../../CustomSwitchOption";
import ColorPicker from "../../../../../../../../WebsiteConfigurations/components/ColorPicker";
import { colors } from "../../../../../../../../../helpers/constants";
import InputSlider from "../../../InputSlider";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: typography.fontWeightMedium as any,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: palette.common.white,
  },
  switch: {
    backgroundColor: palette.secondary.dark,
    paddingBlock: 0,
    height: 54,
    display: "flex",
    borderRadius: 3,
    color: palette.common.white,
    border: 0,
    marginBottom: 0,
    "&:hover": {
      "& $colorPickerRoot": {
        backgroundColor: colors.widgetOnHoverBackgroundColor,
      },
    },
  },
  colorPickerRoot: {
    border: 0,
    padding: 0,
    width: 90,
    gap: 0,
    backgroundColor: palette.secondary.dark,
    justifyContent: "flex-end",
    "& .MuiFormControl-root": {
      minWidth: "auto",
      width: 60,
    },
  },
  slider: {
    backgroundColor: palette.secondary.dark,
    padding: 15,
    display: "flex",
    borderRadius: 3,
  },
}));

type PropTypes = {
  layout: IWidgetSettings["layout"];
  onChange: (field: Partial<IWidgetSettings["layout"]>) => void;
};

const SwitchOptions = (props: PropTypes) => {
  const classes = useStyles();

  const { onChange } = props;
  const { layout } = props;
  const {
    type,
    showCloseButton,
    closeButtonColor,
    closeOnEsc,
    showBackdrop,
    closeOnBackdropClick,
    backdropOpacity,
    shadowRoot,
  } = layout;

  return (
    <div className={classes.root}>
      <CustomSwitchOption
        customClass={classes.switch}
        title={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.closeButton")}
        onChange={(value) => onChange({ showCloseButton: value })}
        checked={showCloseButton}
      />
      {showCloseButton && (
        <CustomSwitchOption
          customClass={classes.switch}
          title={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.closeButtonColor")}
          checked={showBackdrop}
          rightSide={
            <ColorPicker
              borderRadius={4}
              color={closeButtonColor || "#000000"}
              handleChange={(value) => onChange({ closeButtonColor: value })}
              top={"-100px"}
              left={"-120px"}
              className={classes.colorPickerRoot}
              hideOpacityInput
            />
          }
        />
      )}
      <CustomSwitchOption
        customClass={classes.switch}
        title={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.closeOnEsc")}
        onChange={(value) => onChange({ closeOnEsc: value })}
        checked={closeOnEsc ?? true}
      />
      {type !== "slide_in" && (
        <>
          <CustomSwitchOption
            customClass={classes.switch}
            title={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.backdrop")}
            onChange={(value) => onChange({ showBackdrop: value })}
            checked={showBackdrop}
          />
          {showBackdrop && (
            <>
              <CustomSwitchOption
                customClass={classes.switch}
                title={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.closeOnBackdropClick")}
                onChange={(value) => onChange({ closeOnBackdropClick: value })}
                checked={closeOnBackdropClick ?? true}
              />
              <InputSlider
                label={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.backdropOpacity")}
                min={1}
                max={100}
                value={backdropOpacity ?? 65}
                adornmentText="%"
                customClass={classes.slider}
                onChange={(value) => onChange({ backdropOpacity: value })}
              />
            </>
          )}
        </>
      )}
      <CustomSwitchOption
        customClass={classes.switch}
        title={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.shadowRoot")}
        onChange={(value) => onChange({ shadowRoot: value })}
        checked={shadowRoot}
      />
    </div>
  );
};

export default SwitchOptions;
