import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { I18n } from "react-redux-i18n";
import { TransitionProps } from "@material-ui/core/transitions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import { useSnackbar } from "notistack";
import filter from "lodash/filter";
import { ITag, IWebsite } from "../../../../../reducers/constants/objectTypes";
import MTextField from "../../../../../components/MTextField";
import CfButton from "../../../../../components/CfButton";
import MPanelTags from "../../../../../components/MPanelTags";
import agent from "../../../../../agent";
import { BLOG } from "../../../../../reducers/constants/actionTypes";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderWidth: 0,
  },
  titleWrapper: {
    height: 85,
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontSize: theme.typography.pxToRem(24),
    color: "white",
    paddingLeft: 40,
  },
  title: {
    margin: 0,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    minHeight: 400,
    padding: 20,
  },
  dialogActions: {
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 25,
    paddingBottom: 25,
    borderTop: `${theme.palette.divider} solid 1px`,
  },
  closeButton: {
    position: "absolute",
    right: 20,
    // top: theme.spacing(1),
    color: "white",
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(36),
  },
  searchTextField: {
    marginTop: 20,
  },
  searchTextFieldInput: {
    height: 34,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  tag: ITag;
  open: boolean;

  handleCloseClick: Function;
  onTagDeleted: Function;
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const DeleteTagDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, tag, selectedWebsite, handleCloseClick, onTagDeleted } = props;
  const [loading, setLoading] = React.useState(false);
  const [tags, setTags] = React.useState<ITag[]>([]);
  const [replaceTags, setReplaceTags] = React.useState<ITag[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const onOpen = () => {
    console.log("onOpen");
    setLoading(true);
    agent.Tags.all(selectedWebsite._id)
      .then((res) => {
        setTags(res.tags);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  const close = () => {
    setLoading(false);
    handleCloseClick();
  };

  const handleTagSelected = (tag) => {
    setReplaceTags([...replaceTags, tag]);
  };

  const handleTagRemoved = (tag) => {
    setReplaceTags(filter(replaceTags, (t) => t._id !== tag._id));
  };

  const handleDeleteClick = () => {
    setLoading(true);
    agent.Tags.deleteTag(tag, replaceTags.map((t) => t._id).join(","))
      .then((res) => {
        console.log("res", res);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
        onTagDeleted(res.tag);
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      onEntered={(e) => onOpen()}
      onClose={(event) => close()}
      fullWidth
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.titleWrapper}>
        <div className={classes.dialogTitle}>
          <Typography color={"inherit"} className={classes.title} variant={"h3"}>
            {I18n.t("manage_content.tag_pages.delete_dialog.title", { name: tag.name })}
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant={"body1"}>
          {I18n.t("manage_content.tag_pages.delete_dialog.replace_title", { name: tag.name })}
        </Typography>
        <div style={{ width: "100%", marginTop: 40 }}>
          <MPanelTags
            selectedWebsite={selectedWebsite}
            postTags={replaceTags}
            tags={tags}
            onTagSelected={handleTagSelected}
            onTagRemoved={handleTagRemoved}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {replaceTags.length === 0 && (
          <CfButton
            height={40}
            text={I18n.t("manage_content.tag_pages.delete_dialog.delete")}
            loading={loading}
            onClick={() => handleDeleteClick()}
          />
        )}
        {replaceTags.length > 0 && (
          <CfButton
            height={40}
            text={I18n.t("manage_content.tag_pages.delete_dialog.delete_and_replace")}
            loading={loading}
            onClick={() => handleDeleteClick()}
          />
        )}
        <CfButton
          height={40}
          text={I18n.t("manage_content.tag_pages.delete_dialog.cancel")}
          loading={loading}
          onClick={(e) => close()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTagDialog;
