import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useSelected, useSlate, useSlateStatic } from "slate-react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { Transforms } from "slate";
import find from "lodash/find";
import { I18n } from "react-redux-i18n";
import comment from "./comment";
import CfCheckBox from "../../../components/CfCheckBox";
import MTextButton from "../../../components/MTextButton";

const useStyles = makeStyles((theme: Theme) => ({
  comment: {
    backgroundColor: "yellow",
  },
  commentDone: {
    backgroundColor: "rgba(127, 191, 63, 0.24)",
  },
  popper: {
    zIndex: 999,
  },
  tooltip: {
    backgroundColor: "#ffffff",
    padding: 10,
  },
  commentWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tooltipComment: {},
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  editorMode?: boolean;
};

function CommentElement(props: PropTypes) {
  const classes = useStyles(props);
  const { attributes, children, element, editorMode = false } = props;
  const editor = useSlateStatic();
  const selected = useSelected();
  const alignment = element.alignment || "left";

  console.log("selected", selected);

  const handleCommentChanged = (changedComment, index) => {
    Transforms.setNodes(
      editor,
      {
        comments: element.comments.map((c, i) => (i === index ? changedComment : c)),
      },
      {
        match: (n) => n.type === "comment",
        mode: "all",
      }
    );
  };

  const done = find(element.comments, { checked: false }) === undefined;

  return (
    // <div {...attributes}>
    <Tooltip
      interactive
      arrow
      open={selected}
      classes={{
        popper: classes.popper,
        tooltip: classes.tooltip,
      }}
      placement={"top"}
      title={
        <div>
          {element.comments.map((commentObj, index) => (
            <div key={commentObj.text} className={classes.commentWrapper}>
              <CfCheckBox
                checked={commentObj.checked}
                value={"comment.checked"}
                handleChange={(checked) => handleCommentChanged({ ...commentObj, checked }, index)}
              />
              <Typography color={"textSecondary"} className={classes.tooltipComment} variant={"body2"}>
                {commentObj.text}
              </Typography>
            </div>
          ))}
          {editorMode && done && (
            <MTextButton
              text={I18n.t("rich_text_editor.insert_comments.done")}
              onClick={(e) => {
                comment.removeComment(editor);
              }}
            />
          )}
        </div>
      }
    >
      <span className={done ? classes.commentDone : classes.comment} style={{ textAlign: alignment }} {...props}>
        {children}
      </span>
    </Tooltip>
  );
}

export default CommentElement;
