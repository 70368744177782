import { EDIT_PAGES, WRITE_PAGES } from "./constants/actionTypes";

const initialState = {
  myReviews: [],
  takenByOthers: [],
  pendingReviews: [],
  rejected: [],
  paginationPagesCount: 0,
  pagesLoaded: true,
  refreshPages: false,
  myReviewsCount: 0,
  pendingCount: 0,
  rejectedCount: 0,
  takenByOthersCount: 0,
  filter: {
    searchText: "",
    postType: [],
    category: [],
    sortField: {
      direction: "desc",
      field: "publishedDate",
    },
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PAGES.PAGES_LOADED:
      return {
        ...state,
        myReviews: action.payload.pages.myReviews,
        takenByOthers: action.payload.pages.takenByOthers,
        pendingReviews: action.payload.pages.pending,
        rejected: action.payload.pages.rejectedByCustomer,
        paginationPagesCount: action.payload.paginationPagesCount,
        takenByOthersCount: action.payload.pages.takenByOthersCount,
        myReviewsCount: action.payload.pages.myReviewsCount,
        pendingCount: action.payload.pages.pendingCount,
        rejectedCount: action.payload.pages.rejectedCount,
        pagesLoaded: true,
      };
    case EDIT_PAGES.PAGES_UPDATE_PAGE:
      return {
        ...state,
        [action.payload.key]: action.payload.pages,
      };
    case EDIT_PAGES.PAGES_LOAD_FAILED:
      return {
        ...state,
        pagesLoaded: true,
        myReviews: [],
        takenByOthers: [],
        pendingReviews: [],
        rejected: [],
      };
    case EDIT_PAGES.PAGES_LOADING:
      return {
        ...state,
        pagesLoaded: false,
      };

    case EDIT_PAGES.PAGES_REFRESH:
      return {
        ...state,
        refreshPages: !state.refreshPages,
      };
    case EDIT_PAGES.EDIT_PAGES_ON_POST_TYPE_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          postType: action.payload,
        },
      };
    case EDIT_PAGES.EDIT_PAGES_ON_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
