import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 2,
    height: 30,
    strokeWidth: 0.5,
    stroke: theme.palette.divider,
  },
}));

interface PropTypes {
  className?: string;
}

function PipeSeparatorIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox="0 0 2 30">
      <path d="M1 0V30" />
    </SvgIcon>
  );
}

export default PipeSeparatorIcon;
