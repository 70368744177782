import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useState } from "react";
import moment from "moment";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import ContentGraph from "./components/ContentGraph";
import PositionsMetrics from "./components/PositionsMetrics";
import PositionsTable from "./components/PositionsTable";
import { connect } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  positions: {
    margin: 10,
    height: "100%",
    display: "flex",
    flex: 1,
    // overflow: 'hidden',
  },
  positionsContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // height: 'calc(100vh - 93px)',
    // overflow: 'auto',
  },
  graphWrapper: {
    backgroundColor: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};
const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,
  };
};
const Content = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const [content, setContent] = useState({});
  const [loading, setLoading] = React.useState(false);
  const [totalView, setTotalView] = React.useState(false);
  const [hiddenAreas, setHiddenAreas] = React.useState(new Set());
  const [interval, setInterval] = useState<"day" | "week" | "month" | "quarter">("month");
  const [startDate, setStartDate] = useState<string>(moment().subtract(6, "month").format("YYYY-MM-DD"));

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    setLoading(true);
    agent.Analysis.content(selectedWebsite._id, interval, moment(startDate, "YYYY-MM-DD"), moment(), totalView)
      .then((res) => {
        setContent(res.content);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [selectedWebsite, interval, startDate, totalView]);

  return (
    <div className={classes.positions}>
      <div className={classes.positionsContent}>
        <PositionsMetrics
          interval={interval}
          handleIntervalChange={(selectedInterval) => setInterval(selectedInterval)}
          startDate={startDate}
          handleStartDateChange={(selectedStartDate) => setStartDate(selectedStartDate)}
          total={totalView}
          handleTotalChange={(total) => setTotalView(total)}
        />
        <div className={classes.graphWrapper}>
          <ContentGraph content={content} hiddenAreas={hiddenAreas} loading={loading} />
          {/* {positions.length && <PositionsTable positions={positions} hiddenAreas={hiddenAreas} handleHiddenAreasChange={setHiddenAreas} />} */}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Content);
