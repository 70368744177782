import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview/web";
import PositionsTableRow from "./PositionsTableRow";
import PositionsTableHeader from "./PositionsTableHeader";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: 20,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 10,
  },
  table: {
    width: "100%",
    flex: 1,
    minHeight: 1,
    minWidth: 1,
    display: "flex",
    flexDirection: "row",
    border: `solid 1px ${theme.palette.divider}`,
  },

  headerWrapper: {
    width: 120,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    borderRight: `solid 1px ${theme.palette.divider}`,
    paddingTop: 20,
    "&:hover": {
      backgroundColor: "#FFFBE8",
    },
  },
  date: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    marginBottom: 20,
  },
  titles: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
    marginBottom: 20,
    visibility: "hidden",
  },
  range: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
}));

type PropTypes = {
  queriesView: boolean;
  dataSet: "impressions" | "clicks" | "positions";
  labels: any[];
  metrics: any[];
  hiddenAreas: any;
  handleHiddenAreasChange: Function;
};

const PositionsTable = (props: PropTypes) => {
  const classes = useStyles(props);
  const { queriesView, labels, dataSet, metrics, hiddenAreas, handleHiddenAreasChange } = props;
  const recyclerViewRef = React.createRef<any>();
  const tableRef = React.createRef<any>();

  const rowHeight = 88 + 40 * (labels.length + 1);

  const layoutProvider: any = new LayoutProvider(
    (index) => {
      return "ROW";
    },
    (type, dim) => {
      if (type === "ROW") {
        dim.width = 120;
        dim.height = rowHeight;
      } else {
        dim.width = 0;
        dim.height = rowHeight;
      }
    }
  );

  const tableLabels = [...labels].reverse();

  return (
    <div className={classes.wrapper}>
      <div className={classes.table} style={{ height: rowHeight }} ref={tableRef}>
        <PositionsTableHeader
          queriesView={queriesView}
          dataSet={dataSet}
          labels={tableLabels}
          hiddenAreas={hiddenAreas}
          handleHiddenAreasChange={handleHiddenAreasChange}
        />
        <RecyclerListView
          ref={recyclerViewRef}
          isHorizontal
          canChangeSize
          initialOffset={0}
          initialRenderIndex={0}
          forceNonDeterministicRendering
          dataProvider={new DataProvider((r1, r2) => {
            return r1 !== r2;
          }).cloneWithRows(metrics)}
          layoutProvider={layoutProvider}
          rowRenderer={(type, data, index) => (
            <PositionsTableRow
              index={index}
              queriesView={queriesView}
              dataSet={dataSet}
              data={data}
              labels={tableLabels}
              previousData={metrics[Math.max(0, index - 1)]}
            />
          )}
        />
      </div>
    </div>
  );
};

export default PositionsTable;
