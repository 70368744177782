import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import StylePanelSection from "../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../elementsStyles/components/StylePanelSubSection";

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    width: "100%",
  },
  toggleButton: {
    flex: 1,
  },
  toggleButtonSelected: {
    backgroundColor: "transparent !important",
    color: `${theme.palette.primary.main} !important`,
  },
}));

type PropTypes = {
  editor: Editor;
  selectedWebsite: IWebsite;
  element: any;
};

const layouts = [
  [1, 1],
  [1, 2],
  [2, 1],
  [1, 2, 1],
  [1, 1, 1],
  [1, 1, 1, 1],
];

const LayoutStyle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, selectedWebsite, element } = props;

  const changeLayout = (layout) => {
    console.log("PageEditorLayoutStyle: PageEditorComparisonTableStyle", layout);
    Transforms.setNodes(editor, { layout }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  function makeLayoutIcon(ratios: number[]) {
    const size = 16;

    return (
      <div
        role="img"
        style={{
          display: "grid",
          gridTemplateColumns: ratios.map((r) => `${r}fr`).join(" "),
          gap: 2,
          width: size,
          height: size,
        }}
      >
        {ratios.map((_, i) => {
          return <div key={i} style={{ backgroundColor: "currentColor", borderRadius: 1 }} />;
        })}
      </div>
    );
  }

  console.log("PageEditorLayoutStyle", element.layout);
  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.layouts.name")} editor={editor}>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.layouts.order")}>
        <ToggleButtonGroup
          value={element.layout.join(",")}
          exclusive
          className={classes.buttonGroup}
          onChange={(event: React.MouseEvent<HTMLElement>, newLayout: number[]) => changeLayout(newLayout.split(","))}
        >
          {layouts.map((layout) => (
            <ToggleButton
              key={layout}
              classes={{
                root: classes.toggleButton,
                selected: classes.toggleButtonSelected,
              }}
              value={layout.join(",")}
            >
              {makeLayoutIcon(layout)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </StylePanelSubSection>
    </StylePanelSection>
  );
};

export default LayoutStyle;
