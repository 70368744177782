import { FACT_CHECK_PAGES } from "./constants/actionTypes";

const initialState = {
  myPages: [],
  takenByOthers: [],
  pending: [],
  paginationPagesCount: 0,
  pagesLoaded: true,
  refreshPages: false,
  myReviewsCount: 0,
  pendingCount: 0,
  takenByOthersCount: 0,
  filter: {
    searchText: "",
    postType: [],
    category: [],
    sortField: {
      direction: "desc",
      field: "updated",
    },
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case FACT_CHECK_PAGES.FACT_CHECK_PAGES_LOADED:
      return {
        ...state,
        myPages: action.payload.pages.myReviews,
        takenByOthers: action.payload.pages.takenByOthers,
        pending: action.payload.pages.pending,
        paginationPagesCount: action.payload.paginationPagesCount,
        takenByOthersCount: action.payload.pages.takenByOthersCount,
        myReviewsCount: action.payload.pages.myReviewsCount,
        pendingCount: action.payload.pages.pendingCount,
        pagesLoaded: true,
      };
    case FACT_CHECK_PAGES.FACT_CHECK_PAGES_UPDATE_PAGE:
      return {
        ...state,
        [action.payload.key]: action.payload.pages,
      };
    case FACT_CHECK_PAGES.FACT_CHECK_PAGES_LOAD_FAILED:
      return {
        ...state,
        pagesLoaded: true,
        draftPages: [],
        takenByOthers: [],
        publicPages: [],
        rejected: [],
      };
    case FACT_CHECK_PAGES.FACT_CHECK_PAGES_LOADING:
      return {
        ...state,
        pagesLoaded: false,
      };

    case FACT_CHECK_PAGES.FACT_CHECK_PAGES_REFRESH:
      return {
        ...state,
        refreshPages: !state.refreshPages,
      };
    case FACT_CHECK_PAGES.PAGES_ON_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case FACT_CHECK_PAGES.PAGES_ON_FILTER_CLEANUP:
      return {
        ...state,
        filter: {
          ...state.filter,
          searchText: "",
          postType: [],
          category: [],
          sortField: {
            direction: "desc",
            field: "updated",
          },
        },
      };
    default:
      return state;
  }
};
