import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { replace } from "connected-react-router";
import { find } from "lodash";
import { ePageState, IAdminUser, IPage, IPageCluster, IWebsite } from "../../reducers/constants/objectTypes";
import MSelect from "../../components/MSelect";
import { web } from "../../helpers/urlHelper";
import useQuery from "../../hooks/useQuery";
import theme from "../../themes/theme";
import { useHistory } from "react-router";
import { AbilityContext } from "../../casl/can";
import Typography from "@material-ui/core/Typography";
import localeHelper from "../../helpers/localeHelper";

const useStyles = makeStyles((theme: Theme) => ({
  selector: {},
  headerType: {
    fontWeight: theme.typography.fontWeightBold as any,
    margin: "0 10px",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  cluster: IPageCluster;
  page: IPage;
  admin: IAdminUser;

  handleCreatePage: (locale: string, currentUrl: string) => void;
  openPageEditor: (pageId: string, currentUrl) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  cluster: state.pageEditor.pageCluster,
  page: state.pageEditor.page,
  admin: state.home.admin,
});

const mapDispatchToProps = (dispatch) => ({
  openPageEditor: (pageId, currentUrl) => {
    dispatch(replace(web.editPage(pageId, currentUrl)));
  },
});

const PageEditorLocaleDropDown = (props: PropTypes) => {
  const classes = useStyles(props);
  const queries = useQuery();
  const ability = React.useContext<any>(AbilityContext);
  const { selectedWebsite, page, cluster, openPageEditor, handleCreatePage, admin } = props;
  const history = useHistory();

  const locales = [
    selectedWebsite.configurations?.locale?.defaultLocale || "en",
    ...selectedWebsite.configurations.locale.alternateLocales.map((x) => x.locale),
  ];

  const localeOptions = locales.map((locale) => {
    const published = find(cluster.publishedPages?.[page.variant] || [], (p) => p.locale === locale);
    const inProgress = find(
      cluster.pages,
      (p) => p.locale === locale && ![ePageState.published, ePageState.archived].includes(p.currentState.state)
    );

    return {
      locale,
      label:
        published || inProgress
          ? localeHelper.getLanguageFromLocaleCode(locale)
          : `${localeHelper.getLanguageFromLocaleCode(locale)} - Create New`,
      default: locale === selectedWebsite.defaultLocale || "en",
      pageId: published?.page?._id || published?.page || inProgress?._id || null,
    };
  });

  const handleLocaleSelect = (locale) => {
    if (locale.pageId) {
      return history.push(web.editPage(locale.pageId, queries.get("currentUrl")));
    }
    handleCreatePage(locale.locale, queries.get("currentUrl"));
  };

  if (ability.can("admin", "role") || ability.can("owner", "role")) {
    return (
      <MSelect
        optionValue={"locale"}
        optionLabel={"label"}
        options={localeOptions}
        borderColor={"transparent"}
        textColor={theme.palette.text.primary}
        placeholder={I18n.t("pages.select_template_ph")}
        customClass={classes.selector}
        handleChange={(newLocale) => handleLocaleSelect(newLocale)}
        value={find(localeOptions, { locale: page.locale })}
        height={24}
      />
    );
  } else if (ability.can("editor", "role")) {
    const locales = localeOptions.filter((obj) => admin.locales.includes(obj.locale));
    return (
      <MSelect
        optionValue={"locale"}
        optionLabel={"label"}
        options={locales}
        borderColor={"transparent"}
        textColor={theme.palette.text.primary}
        placeholder={I18n.t("pages.select_template_ph")}
        customClass={classes.selector}
        handleChange={(newLocale) => handleLocaleSelect(newLocale)}
        value={find(locales, { locale: page.locale })}
        height={24}
      />
    );
  }
  return (
    <Typography className={classes.headerType} color={"textPrimary"} variant={"body2"}>
      {localeHelper.getLanguageFromLocaleCode(page.locale)}
    </Typography>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorLocaleDropDown);
