import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import { Input } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import StylePanelSection from "../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../elementsStyles/components/StylePanelSubSection";
import FontColorCSS from "../../elementsStyles/CssComponents/FontColorCSS";
import PaddingCSS from "../../elementsStyles/CssComponents/PaddingCSS";
import MSelect from "../../../components/MSelect";
import MarginCSS from "../../elementsStyles/CssComponents/MarginCSS";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    maxWidth: 60,
    whiteSpace: "pre",
  },
  buttonGroup: {
    width: "100%",
  },
  toggleButton: {
    flex: 1,
  },
  toggleButtonSelected: {
    backgroundColor: "transparent !important",
    color: `${theme.palette.text.primary} !important`,
  },
  select: {
    width: 180,
    textTransform: "capitalize",
  },
  inputRoot: {
    width: 80,
    border: `solid 1px ${theme.palette.divider}`,
    color: theme.palette.text.primary,
    padding: "0 5px",
  },
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    marginTop: 50,
    marginBottom: 10,
  },
}));

type PropTypes = {
  editor: Editor;
  websiteTheme: IWebsiteThemeProps;
  element: any;
};

const TextBoxElementStyle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, element, websiteTheme } = props;

  const [style, setStyle] = React.useState(null);

  const textBoxStylesOptions = [
    { _id: 1, name: "plain" },
    { _id: 2, name: "color background" },
    { _id: 3, name: "duo-color background" },
    { _id: 4, name: "side color" },
    { _id: 5, name: "color corner-border" },
    { _id: 6, name: "color border" },
    { _id: 7, name: "color shadow" },
    { _id: 8, name: "frame border" },
  ];

  React.useEffect(() => {
    setStyle(_.find(textBoxStylesOptions, (t) => t._id === element.data.styleId)?.name);
  }, [element]);

  const handleElementDataChange = (data) => {
    console.log("PageEditorTextBoxStyle: handleElementDataChange", data);
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const textBoxCss = element.data?.textBoxCss || { padding: "10px 20px 10px 20px" };
  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.text_box.name")} editor={editor}>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.text_box.settings")}>
        <FontColorCSS
          title={I18n.t("rich_text_editor.text_box.text_color")}
          value={textBoxCss?.color || websiteTheme.palette.text.primary}
          defaultValue={websiteTheme.palette.text.primary}
          isValueChanged={false}
          handleChange={(color) =>
            handleElementDataChange({
              ...element.data,
              textBoxCss: { ...textBoxCss, color },
            })
          }
        />
        <div className={classes.row}>
          <Typography variant={"body2"} className={classes.text}>
            {I18n.t("rich_text_editor.text_box.text_box_width")}
          </Typography>

          <Input
            value={textBoxCss?.width?.split("%")[0] || 100}
            type="number"
            disableUnderline
            endAdornment={
              <InputAdornment position="start" disableTypography>
                %
              </InputAdornment>
            }
            inputProps={{ min: 1, max: 100 }}
            classes={{
              root: classes.inputRoot,
            }}
            onChange={(event) =>
              handleElementDataChange({
                ...element.data,
                textBoxCss: { ...textBoxCss, width: `${event.target.value}%` },
              })
            }
          />
        </div>
        <PaddingCSS
          value={textBoxCss?.padding}
          handleChange={(padding) =>
            handleElementDataChange({ ...element.data, textBoxCss: { ...textBoxCss, padding } })
          }
        />
        <MarginCSS
          value={textBoxCss?.margin}
          handleChange={(margin) => handleElementDataChange({ ...element.data, textBoxCss: { ...textBoxCss, margin } })}
        />

        <Typography variant={"body2"} className={classes.title}>
          {I18n.t("rich_text_editor.text_box.styles")}
        </Typography>
        <div className={classes.row}>
          <Typography variant={"body2"} className={classes.text}>
            {I18n.t("rich_text_editor.text_box.text_box_style")}
          </Typography>
          <MSelect
            customClass={classes.select}
            options={textBoxStylesOptions}
            value={_.find(textBoxStylesOptions, { name: style }) || textBoxStylesOptions[0]}
            optionLabel={"name"}
            optionValue={"_id"}
            height={32}
            borderRadius={0}
            borderWidth={1}
            menuPlacement={"bottom"}
            handleChange={(value) => {
              setStyle(value.name);
              if (value.name === "frame border") {
                handleElementDataChange({
                  ...element.data,
                  styleId: value._id,
                  textBoxCss: {
                    color: element.data.textBoxCss?.color,
                    width: element.data.textBoxCss?.width,
                    background: websiteTheme.palette.secondary.main,
                    borderRadius: 15,
                    position: "relative",
                    padding: 20,
                    "&:before": {
                      content: '""',
                      bottom: "-8px",
                      left: 8,
                      width: "10%",
                      height: "75%",
                      position: "absolute",
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: "0 0 2px 2px",
                      borderRadius: "0 0 0 15px",
                    },
                    "&:after": {
                      content: '""',
                      bottom: "-2px",
                      right: -8,
                      width: "20%",
                      height: "95%",
                      position: "absolute",
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: "2px 2px 0 0",
                      borderRadius: "0 15px 12px 0",
                    },
                  },
                });
              }
              if (
                value.name === "plain" ||
                value.name === "color background" ||
                value.name === "duo-color background"
              ) {
                handleElementDataChange({
                  ...element.data,
                  styleId: value._id,
                  textBoxCss: {
                    color: element.data.textBoxCss?.color,
                    width: element.data.textBoxCss?.width,
                    background: websiteTheme.palette.secondary.main,
                    padding: "10px 20px",
                  },
                });
              }
              if (value.name === "color shadow") {
                handleElementDataChange({
                  ...element.data,
                  styleId: value._id,
                  textBoxCss: {
                    color: element.data.textBoxCss?.color,
                    width: element.data.textBoxCss?.width,
                    background: websiteTheme.palette.background.default,
                    boxShadow: `${websiteTheme.palette.divider} 6.4px 7.4px 7.2px`,
                    shadowColor: websiteTheme.palette.divider,
                    borderWidth: 0,
                    borderStyle: "solid",
                    borderColor: "transparent",
                    padding: "10px 20px",
                  },
                });
              }
              if (value.name === "color border") {
                handleElementDataChange({
                  ...element.data,
                  styleId: value._id,
                  textBoxCss: {
                    color: element.data.textBoxCss?.color,
                    width: element.data.textBoxCss?.width,
                    background: websiteTheme.palette.background.default,
                    borderColor: websiteTheme.palette.divider,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: "10px 20px",
                  },
                });
              }
              if (value.name === "side color") {
                handleElementDataChange({
                  ...element.data,
                  styleId: value._id,
                  textBoxCss: {
                    color: element.data.textBoxCss?.color,
                    width: element.data.textBoxCss?.width,
                    background: websiteTheme.palette.background.default,
                    borderWidth: "0 0 0 3px",
                    borderStyle: "solid",
                    borderColor: websiteTheme.palette.divider,
                    padding: "10px 20px",
                  },
                });
              }
              if (value.name === "color corner-border") {
                handleElementDataChange({
                  ...element.data,
                  styleId: value._id,
                  textBoxCss: {
                    color: element.data.textBoxCss?.color,
                    width: element.data.textBoxCss?.width,
                    background: websiteTheme.palette.background.default,
                    backgroundImage: `linear-gradient(to right,  ${websiteTheme.palette.divider} 2px, transparent 2px), linear-gradient(to left,  ${websiteTheme.palette.divider} 2px, transparent 2px), linear-gradient( ${websiteTheme.palette.divider} 2px, transparent 2px), linear-gradient(to top,  ${websiteTheme.palette.divider} 2px, transparent 2px)`,
                    backgroundPositionX: "0px, 100%, 0px, 100%",
                    backgroundPositionY: "0px, 100%, 0px, 100%",
                    backgroundSize: "90px 30px, 90px 30px, 90px 30px, 90px 30px",
                    backgroundRepeat: "no-repeat, no-repeat, no-repeat, no-repeat",
                    padding: "10px 20px",
                  },
                });
              }
            }}
          />
        </div>

        <div>
          {style === "side color" && (
            <FontColorCSS
              title={I18n.t("rich_text_editor.text_box.side_color")}
              value={textBoxCss?.sideColor || websiteTheme.palette.divider}
              isValueChanged={false}
              handleChange={(color) =>
                handleElementDataChange({
                  ...element.data,
                  textBoxCss: {
                    ...textBoxCss,
                    sideColor: color,
                    borderColor: color,
                    borderWidth: "0 0 0 3px",
                    borderStyle: "solid",
                  },
                })
              }
            />
          )}
          {style === "color shadow" && (
            <FontColorCSS
              title={I18n.t("rich_text_editor.text_box.shadow_color")}
              value={textBoxCss?.shadowColor || websiteTheme.palette.divider}
              isValueChanged={false}
              handleChange={(color) =>
                handleElementDataChange({
                  ...element.data,
                  textBoxCss: {
                    ...textBoxCss,
                    shadowColor: color,
                    boxShadow: `${color} 6.4px 7.4px 7.2px`,
                  },
                })
              }
            />
          )}
          {(style === "color border" || style === "color shadow") && (
            <FontColorCSS
              title={I18n.t("rich_text_editor.text_box.border_color")}
              value={textBoxCss?.borderColor || websiteTheme.palette.divider}
              isValueChanged={false}
              handleChange={(color) =>
                handleElementDataChange({
                  ...element.data,
                  textBoxCss: {
                    ...textBoxCss,
                    borderColor: color,
                  },
                })
              }
            />
          )}
          {style === "color corner-border" && (
            <FontColorCSS
              title={I18n.t("rich_text_editor.text_box.corner_border_color")}
              value={textBoxCss?.cornerBorderColor || websiteTheme.palette.divider}
              isValueChanged={false}
              handleChange={(color) =>
                handleElementDataChange({
                  ...element.data,
                  textBoxCss: {
                    ...textBoxCss,
                    cornerBorderColor: color,
                    backgroundImage: `linear-gradient(to right, ${color} 2px, transparent 2px), linear-gradient(to left, ${color} 2px, transparent 2px), linear-gradient(${color} 2px, transparent 2px), linear-gradient(to top, ${color} 2px, transparent 2px)`,
                  },
                })
              }
            />
          )}
          {(style === "color background" ||
            style === "color shadow" ||
            style === "color border" ||
            style === "side color" ||
            style === "color corner-border" ||
            style === "frame border") && (
            <FontColorCSS
              title={I18n.t("rich_text_editor.text_box.background_color")}
              value={textBoxCss?.background || websiteTheme.palette.secondary.main}
              defaultValue={websiteTheme.palette.secondary.main}
              isValueChanged={false}
              handleChange={(color) =>
                handleElementDataChange({
                  ...element.data,
                  textBoxCss: {
                    ...textBoxCss,
                    background: color,
                  },
                })
              }
            />
          )}
          {(style === "color shadow" || style === "color border") && (
            <div className={classes.row}>
              <Typography variant={"body2"} className={classes.text}>
                {I18n.t("rich_text_editor.text_box.border_width")}
              </Typography>
              <Input
                value={
                  typeof textBoxCss?.borderWidth === "number"
                    ? textBoxCss?.borderWidth
                    : textBoxCss?.borderWidth?.split("px")[0] || 0
                }
                type="number"
                disableUnderline
                endAdornment={
                  <InputAdornment position="start" disableTypography>
                    px
                  </InputAdornment>
                }
                inputProps={{ min: 1 }}
                classes={{
                  root: classes.inputRoot,
                }}
                onChange={(event) =>
                  handleElementDataChange({
                    ...element.data,
                    textBoxCss: { ...textBoxCss, borderWidth: `${event.target.value}px` },
                  })
                }
              />
            </div>
          )}
          {style === "side color" && (
            <div className={classes.row}>
              <Typography variant={"body2"} className={classes.text}>
                {I18n.t("rich_text_editor.text_box.border_width")}
              </Typography>
              <Input
                value={
                  typeof textBoxCss?.borderWidth === "number"
                    ? textBoxCss?.borderWidth
                    : textBoxCss?.borderWidth?.split(" ")?.[3]
                    ? textBoxCss?.borderWidth?.split(" ")?.[3].split("px")[0]
                    : textBoxCss?.borderWidth?.split("px")?.[0] || 1
                }
                type="number"
                disableUnderline
                endAdornment={
                  <InputAdornment position="start" disableTypography>
                    px
                  </InputAdornment>
                }
                inputProps={{ min: 1 }}
                classes={{
                  root: classes.inputRoot,
                }}
                onChange={(event) =>
                  handleElementDataChange({
                    ...element.data,
                    textBoxCss: { ...textBoxCss, borderWidth: `0 0 0 ${event.target.value}px` },
                  })
                }
              />
            </div>
          )}
          {style === "frame border" && (
            <FontColorCSS
              title={I18n.t("rich_text_editor.text_box.border_color")}
              value={textBoxCss?.["&:before"]?.borderColor || "#000"}
              defaultValue={"#000"}
              isValueChanged={false}
              handleChange={(color) =>
                handleElementDataChange({
                  ...element.data,
                  textBoxCss: {
                    ...textBoxCss,
                    "&:before": {
                      ...textBoxCss["&:before"],
                      borderColor: color,
                    },
                    "&:after": {
                      ...textBoxCss["&:after"],
                      borderColor: color,
                    },
                  },
                })
              }
            />
          )}
          {style === "duo-color background" && (
            <>
              <FontColorCSS
                title={I18n.t("rich_text_editor.text_box.background_color1")}
                value={textBoxCss?.backgroundColor1 || websiteTheme.palette.secondary.main}
                defaultValue={websiteTheme.palette.secondary.main}
                isValueChanged={false}
                handleChange={(color) =>
                  handleElementDataChange({
                    ...element.data,
                    textBoxCss: {
                      ...textBoxCss,
                      backgroundColor1: color,
                      backgroundImage: `linear-gradient(${color}, ${
                        element.data.textBoxCss?.backgroundColor2 || websiteTheme.palette.secondary.main
                      })`,
                    },
                  })
                }
              />
              <FontColorCSS
                title={I18n.t("rich_text_editor.text_box.background_color2")}
                value={textBoxCss?.backgroundColor2 || websiteTheme.palette.secondary.main}
                defaultValue={websiteTheme.palette.secondary.main}
                isValueChanged={false}
                handleChange={(color) =>
                  handleElementDataChange({
                    ...element.data,
                    textBoxCss: {
                      ...textBoxCss,
                      backgroundColor2: color,
                      backgroundImage: `linear-gradient(${
                        element.data.textBoxCss?.backgroundColor1 || websiteTheme.palette.secondary.main
                      }, ${color})`,
                    },
                  })
                }
              />
            </>
          )}
        </div>
      </StylePanelSubSection>
    </StylePanelSection>
  );
};

export default TextBoxElementStyle;
