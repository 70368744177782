import { Editor, Path, Transforms } from "slate";
import richTextEditorHelper from "../../helper/richTextEditorHelper";
import * as React from "react";

export const insertProsConsTable = (editor: Editor, at: Path, forcedLayout = false) => {
  console.log("insertProsConsTable");
  richTextEditorHelper.insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading(
    editor,
    [
      {
        forcedLayout,
        type: "layout",
        layout: [1, 1],
        layoutType: "pros-cons-table",
        uuid: richTextEditorHelper.getUuid(),
        //data: { icon: bulletedListIconList[0].key, css: { fill: "green" } },
        children: [
          {
            type: "layout-area",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    text: "Pros",
                    bold: true,
                  },
                ],
              },
              {
                type: "divider",
                forcedLayout: true,
                data: {
                  dividerCss: {
                    margin: "5px 0px",
                  },
                },
                children: [
                  {
                    text: "",
                  },
                ],
              },
              {
                type: "bulleted-list",
                forcedLayout: true,
                data: { icon: "v_icon", fill: "green" },
                children: [
                  {
                    type: "list-item",
                    children: [
                      {
                        text: "",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: "layout-area",

            children: [
              {
                type: "paragraph",
                children: [
                  {
                    text: "Cons",
                    bold: true,
                  },
                ],
              },
              {
                type: "divider",
                forcedLayout: true,
                data: {
                  dividerCss: {
                    margin: "5px 0px",
                  },
                },
                children: [
                  {
                    text: "",
                  },
                ],
              },
              {
                type: "bulleted-list",
                forcedLayout: true,
                data: { icon: "x_icon", fill: "red" },
                children: [
                  {
                    type: "list-item",
                    children: [
                      {
                        text: "",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    at
  );

  const layoutEntry = Editor.above(editor, { match: (x) => x.type === "layout" && x.layoutType === "pros-cons-table" });
  if (layoutEntry) {
    Transforms.select(editor, [...layoutEntry[1], 0]);
  } else {
    Transforms.select(editor, at);
  }
};
