import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Search } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { eActionIcon, ePostType, eUserRoles, IPage, IWebsite } from "../../../../reducers/constants/objectTypes";
import { clientPages, getSearchConsoleUrl, web } from "../../../../helpers/urlHelper";
import { WORKFLOW_ALL_STATES } from "../../../../reducers/constants/actionTypes";
import MPageStatusTypography from "../../../../components/MPageStatusTypography";
import { DATE_FORMAT, PAGE_TYPE_COLORS } from "../../../../reducers/constants/consts";
import localeHelper from "../../../../helpers/localeHelper";
import richTextEditorHelper from "../../../../editor/helper/richTextEditorHelper";
import ability from "../../../../casl/ability";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import MTableCell from "../../../../components/Table/Cells/MTableCell";
import MTableCellDate from "../../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MTableCellLink from "../../../../components/Table/Cells/MTableCellLink";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";
import { copyStringToClipboard } from "../../../../helpers/fbScraperHelper";
import CopyNameIcon from "../../../../icons/CopyNameIcon";
import CopyToClipboardIcon from "../../../../icons/CopyToClipboardIcon";
import { camelCase, startCase } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $topicWrapper": {
        paddingTop: 1,
        borderBottom: "1px solid transparent",
      },
      "& $iconColumn": {
        paddingTop: 1,
      },
      "& $actions": {
        display: "flex",
      },
    },
  },

  childPage: (props: PropTypes) => ({
    "&:before": {
      position: "absolute",
      top: 16,
      left: 51,
      width: 10,
      height: 1,
      margin: "auto",
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[props.page.type] || "#666",
    },
    "&:after": {
      position: "absolute",
      top: -9,
      bottom: 0,
      left: 51,
      width: 1,
      height: 25,
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[props.page.type] || "#666",
      zIndex: 1,
    },
  }),
  subDirectory: (props: PropTypes) => ({
    height: 34,
    "&:before": {
      position: "absolute",
      marginTop: "-1px",
      left: 32,
      width: 10,
      height: 1,
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[props.page.type] || "#666",
    },
    // '&:after': {
    // 	position: 'absolute',
    // 	top: 36,
    // 	// bottom: 0,
    // 	left: 28,
    // 	width: 1,
    // 	height: '100%',
    // 	content: "''",
    // 	backgroundColor: '#666',
    // 	zIndex: 1,
    // },
  }),

  iconColumnPh: {
    width: 40,
  },
  actions: {},
  writerName: {},
  tooltip: {
    whiteSpace: "pre-wrap",
  },
}));

type PropTypes = {
  main: boolean;
  showBorder: boolean;
  child?: boolean;
  selectedWebsite: IWebsite;
  page: IPage;

  editPost: Function;
  editTopic: Function;
  editCommunityPost: Function;
  refreshPostsList: Function;
  onDateUpdate: Function;
  onPostTypeUpdate: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  editPost: (articleId, currentUrl) => dispatch(push(web.editPage(articleId, currentUrl))),
  editCommunityPost: (postState, postId) => dispatch(push(web.editorCommunity(postState || "published", postId))),
  editTopic: (type: ePostType, topicId, currentUrl) =>
    dispatch(push(web.editTopic(type, topicId, "write", currentUrl))),
  refreshPostsList: () => dispatch({ type: WORKFLOW_ALL_STATES.REFRESH }),
});

const PageRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { main, child = false, page, selectedWebsite, editPost, onDateUpdate, onPostTypeUpdate } = props;
  const location = useLocation();
  const isEditor = ability.can(eUserRoles.EDITOR, "role");
  const theme: any = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const url = clientPages.getPageUrl(page, selectedWebsite);
  const actions = React.useMemo(() => {
    const values = [];
    if (url) {
      values.push(
        ...[
          {
            text: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
            tooltip: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
            onClick: () => window.open(url, "_blank"),
            iconName: eActionIcon.openInNew,
          },
          {
            text: I18n.t(`learning_center_content.tooltip.google_search_console`),
            tooltip: I18n.t(`learning_center_content.tooltip.google_search_console`),
            onClick: () => window.open(getSearchConsoleUrl(url), "_blank"),
            iconName: eActionIcon.search,
          },
        ]
      );
    }
    values.push({
      text: I18n.t(`learning_center_content.tooltip.copy_title`),
      tooltip: I18n.t(`learning_center_content.tooltip.copy_title`),
      onClick: () => copyStringToClipboard(page.title, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
      iconName: eActionIcon.copyName,
    });
    if (url) {
      values.push({
        text: I18n.t(`learning_center_content.tooltip.copy_url`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_url`),
        onClick: () => copyStringToClipboard(url, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
        iconName: eActionIcon.copyToClipboard,
      });
    }
    return values;
  }, [page.title]);

  return (
    <MTableRow
      handleRowClick={() => {
        editPost(page._id, location.pathname);
      }}
      customClassName={ClassNames(
        classes.itemWrapper,
        child && classes.childPage,
        !child && !main && classes.subDirectory
      )}
    >
      <MTableCellFirst
        columnWidth={"calc(34% - 40px)"}
        fontSize={theme.typography.pxToRem(!main ? 12 : 14)}
        avatarType={main ? (page.type as ePostType) : null}
        prefix={!main ? <div className={classes.iconColumnPh} /> : null}
        cellText={page.title || "Missing Title !"}
        actions={actions}
        actionClassName={classes.actions}
      />
      <MTableCell>
        <MPageStatusTypography currentState={page.currentState} reviewSteps={selectedWebsite.reviews} />
      </MTableCell>

      <MTableCellDate date={page.createdDate} />
      <MTableCellDate
        date={page.dueDate}
        dateFormat={DATE_FORMAT.MONTH_YEAR}
        underlineOnHover={isEditor}
        handleDateClick={(e) => {
          if (isEditor) {
            onDateUpdate("dueDate", page);
          }
        }}
      />
      <MTableCellDate
        date={page.quotaDate}
        dateFormat={DATE_FORMAT.MONTH_YEAR}
        underlineOnHover={isEditor}
        handleDateClick={(e) => {
          if (isEditor) {
            onDateUpdate("quotaDate", page);
          }
        }}
      />
      <MTableCell tooltip={startCase(camelCase(page.type))} onClick={() => onPostTypeUpdate(page)}>
        {startCase(camelCase(page.type))}
      </MTableCell>
      <MTableCellText text={localeHelper.getLanguageFromLocaleCode(page.locale)} />
      <MTableCellLink text={page.categories[0]?.name} href={page.categories[0]?.name} />
      <MTableCellNumber value={page.tags?.length} />
      <MTableCellNumber value={page.linksCount} />
      <MTableCellNumber value={page.metaDescription?.length} tooltipText={page.metaDescription} />
      <MTableCellNumber value={page.textWordsCount} />
      <MTableCellText text={page.writer?.name} />
      {/* <div className={classes.moreOptionsColumn}> */}
      {/*  /!* <MoreOptionsButton *!/ */}
      {/*  /!*  handleDeleteClick={() => { *!/ */}
      {/*  /!*    // if (!confirm('Are you sure? post may contain translations!')) { *!/ */}
      {/*  /!*    // 	return; *!/ */}
      {/*  /!*    // } *!/ */}
      {/*  /!*    // if (postRowData.topic) { *!/ */}
      {/*  /!*    // 	if (postRowData.type === ePostType.TOPIC) { *!/ */}
      {/*  /!*    // 		deleteTag(); *!/ */}
      {/*  /!*    // 		return; *!/ */}
      {/*  /!*    // 	} *!/ */}
      {/*  /!*    // 	if (postRowData.type === ePostType.LEARNING_CENTER) { *!/ */}
      {/*  /!*    // 		deleteLearningCenterTopic(); *!/ */}
      {/*  /!*    // 		return; *!/ */}
      {/*  /!*    // 	} *!/ */}
      {/*  /!*    // } *!/ */}
      {/*  /!*    // deletePost(); *!/ */}
      {/*  /!*  }} *!/ */}
      {/*  /!*  handleRemoveWriterClick={() => { *!/ */}
      {/*  /!*    // if (postRowData.topic) { *!/ */}
      {/*  /!*    // 	if (postRowData.type === ePostType.TOPIC) { *!/ */}
      {/*  /!*    // 		removeTagWriter(); *!/ */}
      {/*  /!*    // 		return; *!/ */}
      {/*  /!*    // 	} *!/ */}
      {/*  /!*    // 	if (postRowData.type === ePostType.LEARNING_CENTER) { *!/ */}
      {/*  /!*    // 		removeLearningCenterTopicWriter(); *!/ */}
      {/*  /!*    // 		return; *!/ */}
      {/*  /!*    // 	} *!/ */}
      {/*  /!*    // } *!/ */}
      {/*  /!*    // removeWriter(); *!/ */}
      {/*  /!*  }} *!/ */}
      {/*  /!* /> *!/ */}
      {/* </div> */}
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageRow);
