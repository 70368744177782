import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { web } from "../../helpers/urlHelper";
import EditTopic from "../EditTopic/EditTopic";
import { AbilityContext } from "../../casl/can";

type PropTypes = {};

const PostEditors = (props: PropTypes) => {
  const ability = React.useContext<any>(AbilityContext);
  return (
    <>
      <Switch>
        <Route
          exact={false}
          path={web.editTopic(":type", ":topicId", "write")}
          render={(routeProps) => (
            <EditTopic returnTo={new URLSearchParams(routeProps.location.search).get("returnTo")} />
          )}
        />
        <Redirect exact from={"*"} to={web.writer()} />
      </Switch>
    </>
  );
};

export default PostEditors;
