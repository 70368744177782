import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { isArray } from "lodash";
import { IFunnelStepFilter, IWebsite } from "../../../../reducers/constants/objectTypes";
import useDebounce from "../../../../hooks/useDebounce";
import agent from "../../../../agent";
import { FUNNEL_FILTER_COLUMNS, FUNNEL_FILTER_CONDITIONS } from "../../../../helpers/constants";
import FunnelBaseSelect from "../../FunnelCreator/components/FunnelBaseSelect";
import FunnelSidebarStepValueSelect from "../../FunnelCreator/components/FunnelSidebar/components/inputs/FunnelSidebarStepValueSelect";

const useStyles = makeStyles((theme: Theme) => ({
  filterWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    border: `1px solid ${theme.palette.divider}`,
    borderTop: "none",
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  dropDownIcon: {
    display: "none",
  },
  select: {
    borderBottom: 0,
    padding: 0,
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: 14,
  },
  conditionSelect: {
    width: "30%",
  },
  valueSelect: {
    width: "70%",
  },
  removeBtn: {
    visibility: "hidden",
  },
  removeIcon: {
    width: 11,
    height: 11,
    fill: theme.palette.text.secondary,
  },
  propertySelect: {
    height: "unset",
    padding: "10px 10px 5px 10px",
  },
  filterConditionSelect: {
    height: "unset",
    padding: "0px 10px 10px 10px",
  },
}));

type PropTypes = {
  filter: IFunnelStepFilter;
  filterIndex: number;
  eventIndex: number;
  selectedWebsite: IWebsite;
  config: any;
  updateConfig: (config: any) => void;
  dynamicColumns: any[];
};

const EventStepFilter = (props: PropTypes) => {
  const classes = useStyles();
  const { filter, selectedWebsite, updateConfig, config, dynamicColumns } = props;
  const [columnValues, setColumnValues] = useState([]);

  const [columnCount, setColumnCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [columnSearchTerm, setColumnSearchTerm] = useState("");
  const [columnLoading, setColumnLoading] = useState(false);

  const updateValue = (value: string | string[]) => {
    const newConfig = { ...config };
    newConfig.events[props.eventIndex].filters[props.filterIndex].value = value;
    updateConfig(newConfig);
  };

  const debounceColumnTerm = useDebounce(columnSearchTerm, 500);

  useEffect(() => {
    if (filter.field && filter.table) {
      setColumnLoading(true);
      agent.Funnels.getColumnValues(selectedWebsite, filter.table, filter.field, debounceColumnTerm, currentPage)
        .then((res) => {
          setColumnValues(res.data);
          setColumnCount(res.count);
          setColumnLoading(false);
        })
        .catch((err) => {
          console.log(`Error fetching col values `, err);
          setColumnLoading(false);
        });
    }
  }, [filter.field, filter.table, debounceColumnTerm]);

  const updateColumn = (value: { field: string; table: string; eventTable: string }) => {
    console.log({ value });

    const newConfig = { ...config };
    newConfig.events[props.eventIndex].filters[props.filterIndex].field = value.field;
    newConfig.events[props.eventIndex].filters[props.filterIndex].table = value.table;
    newConfig.events[props.eventIndex].filters[props.filterIndex].eventTable = value.eventTable;

    console.log({ newConfig });

    updateConfig(newConfig);
  };

  const updateCondition = (value: string) => {
    const newConfig = { ...config };
    const isMultiple = filter.condition === "is" || filter.condition === "isNot";
    const currentIsMultiple = value === "is" || value === "isNot";
    newConfig.events[props.eventIndex].filters[props.filterIndex].condition = value;
    if (isMultiple !== currentIsMultiple || value === "isSet" || value === "isNotSet") {
      newConfig.events[props.eventIndex].filters[props.filterIndex].value = null;
    }

    updateConfig(newConfig);
  };

  const removeFilter = () => {
    const newConfig = { ...config };
    newConfig.events[props.eventIndex].filters.splice(props.filterIndex, 1);
    updateConfig(newConfig);
  };

  const funnelFilterColumns = useMemo(
    () =>
      dynamicColumns.length > 0
        ? [...dynamicColumns, { label: "", value: "properties_divider" }, ...FUNNEL_FILTER_COLUMNS]
        : FUNNEL_FILTER_COLUMNS,
    [FUNNEL_FILTER_COLUMNS, dynamicColumns]
  );

  const getFilterValue = () => {
    const isMultiple = filter.condition === "is" || filter.condition === "isNot";
    if (isMultiple) {
      return isArray(filter.value) ? filter.value.map((v) => columnValues.find((f) => f.value === v)) : [];
    }
    if (isArray(filter.value)) {
      return null;
    }
    return columnValues.find((f) => f.value === filter.value) || { label: filter.value, value: filter.value };
  };
  return (
    <div className={classes.filterWrapper}>
      <FunnelBaseSelect
        showDropdownIcon={false}
        titleWrapperClassName={classes.propertySelect}
        optionValue={"value"}
        optionLabel={"label"}
        border={"none"}
        value={funnelFilterColumns.find((f) => f.value.field === filter.field && f.value.table === filter.table)}
        options={funnelFilterColumns}
        handleOnChange={(v) => {
          if (v.value) updateColumn(v.value);
        }}
        placeholder={I18n.t("analytics.funnels.sidebar.labels.select_property")}
        onClear={() => {
          removeFilter();
        }}
      />
      <FunnelBaseSelect
        titleWrapperClassName={classes.filterConditionSelect}
        showDropdownIcon={false}
        dynamicTextWidth
        search={false}
        flexDirection={"row"}
        optionValue={"value"}
        optionLabel={"label"}
        border={"none"}
        value={FUNNEL_FILTER_CONDITIONS.find((f) => f.value === filter.condition)}
        options={FUNNEL_FILTER_CONDITIONS}
        handleOnChange={(v) => {
          if (v.value) updateCondition(v.value);
        }}
        placeholder={""}
      >
        <FunnelSidebarStepValueSelect
          searchText={columnSearchTerm}
          setSearchText={setColumnSearchTerm}
          placeholder={I18n.t("analytics.funnels.sidebar.labels.select_value")}
          options={columnValues}
          condition={FUNNEL_FILTER_CONDITIONS.find((f) => f.value === filter.condition)?.value}
          loading={columnLoading}
          value={getFilterValue()}
          handleChange={(v) => {
            if (isArray(v) && v.length > 0) {
              updateValue(v?.map((item) => item.value));
              return;
            }
            updateValue(v.value);
          }}
        />
      </FunnelBaseSelect>
    </div>
  );
};

export default EventStepFilter;
