import React, { useEffect } from "react";
import { Checkbox, FormControlLabel, FormGroup, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import { IExpert, IWebsite } from "../../../../../reducers/constants/objectTypes";
import ExpertImagePicker from "./ExpertImagePicker";
import MPopover from "../../../../../components/MPopover";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import EducationYear from "./EducationYear";
import ExperienceMonthSelect from "./ExperienceMonthSelect";

const useStyles = makeStyles((theme: Theme) => ({
  textFieldTitle: {
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: `${theme.palette.divider} solid 1px`,
    marginTop: 20,
    paddingTop: 20,
    marginBottom: 20,
    "&:first-child": {
      borderTop: "none",
      paddingTop: 0,
      marginTop: 0,
    },
  },
  positionTitle: {
    fontSize: 16,
  },
  deleteText: {
    fontSize: 14,
    cursor: "pointer",
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  rowGaps: {
    gap: 10,
  },
  textFieldWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  fieldTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    minWidth: 140,
    marginRight: 15,
  },
  profilePicWrapper: {
    marginRight: 40,
  },
  divider: {
    marginLeft: -30,
    marginRight: -30,
    marginTop: 20,
    marginBottom: 20,
  },
  dropdownWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    marginTop: 10,
    alignItems: "flex-end",
  },
  wrapperClassName: {
    alignItems: "center",
    "& div:nth-child(1)": {
      minWidth: 60,
      alignItems: "flex-start",
    },
  },
  monthDropdown: () => ({
    "& div:first-of-type": {
      color: theme.palette.text.primary,
      opacity: 1,
    },
  }),
  dateWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "12px 15px",
  },
  uncheckedIcon: {
    color: theme.palette.text.primary,
    width: 22,
    height: 22,
  },
  checkedIcon: {
    fill: theme.palette.primary.main,
    width: 22,
    height: 22,
  },
  checkboxRoot: {
    fontSize: theme.typography.pxToRem(14),
    borderBottom: `${theme.palette.divider} solid 1px`,
    marginTop: -9,
  },
}));

type PropTypes = {
  item: IExpert["previousExperience"][number];
  index: number;
  selectedWebsite: IWebsite;

  handleItemChange: (item: IExpert["previousExperience"][number], index: number) => void;
  handleDeleteClick: (index: number) => void;
};

const longMonthsArr = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ExperienceItem = (props: PropTypes) => {
  const { item, index, handleItemChange, handleDeleteClick, selectedWebsite } = props;
  const classes = useStyles(props);

  const fromDateAnchorRef = React.useRef<HTMLDivElement>(null);
  const toDateAnchorRef = React.useRef<HTMLDivElement>(null);
  const [showFromDatePopover, setShowFromDatePopover] = React.useState(false);
  const [showToDatePopover, setShowToDatePopover] = React.useState(false);
  const [present, setPresent] = React.useState(!(item.endMonth || item.endYear));

  useEffect(() => {
    if (!(item.endMonth || item.endYear)) {
      setPresent(true);
    } else {
      setPresent(false);
    }
  }, [item.endMonth, item.endYear]);

  return (
    <div>
      <div className={classes.row}>
        <Typography variant="h4" className={classes.positionTitle}>
          {`${I18n.t("experts.profile.position")} #${index + 1}`}
        </Typography>
        <Typography onClick={() => handleDeleteClick(index)} variant="body1" className={classes.deleteText}>
          {I18n.t("experts.profile.delete")}
        </Typography>
      </div>
      <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
        <div className={classes.profilePicWrapper}>
          <ExpertImagePicker
            key={`profile_pic`}
            title={I18n.t("experts.profile.company_pic")}
            showSizes
            selectedWebsite={selectedWebsite}
            image={item.companyPic}
            imageWidth={500}
            imageHeight={500}
            titlePosition={"bottom"}
            onImageChanged={(file) => {
              handleItemChange({ ...item, companyPic: file }, index);
            }}
          />
        </div>
        <div className={classes.textFieldWrapper}>
          <TextFieldMaxLength
            titleClassName={classes.textFieldTitle}
            titleAndLengthLocation={"left"}
            wrapperClassName={classes.wrapperClassName}
            maxLength={50}
            value={item.positionTitle}
            placeholder={I18n.t("experts.profile.write_ph")}
            title={I18n.t("experts.profile.job_title")}
            borderRadius={4}
            handleValueChange={(positionTitle) => {
              handleItemChange({ ...item, positionTitle }, index);
            }}
          />
          <TextFieldMaxLength
            titleClassName={classes.textFieldTitle}
            key={`expert_experience_company_${index}`}
            titleAndLengthLocation={"left"}
            wrapperClassName={classes.wrapperClassName}
            maxLength={50}
            value={item.companyName}
            placeholder={I18n.t("experts.profile.write_ph")}
            title={I18n.t("experts.profile.employer")}
            borderRadius={4}
            marginTop={10}
            handleValueChange={(companyName) => {
              handleItemChange({ ...item, companyName }, index);
            }}
          />
          <div className={classes.dropdownWrapper}>
            <div ref={fromDateAnchorRef} onClick={() => setShowFromDatePopover(true)} style={{ width: "50%" }}>
              <ExperienceMonthSelect
                placeholder={`${
                  item.startMonth || item.startYear
                    ? `${item.startMonth ? longMonthsArr[item.startMonth - 1] : "Select month"}, ${
                        item.startYear || "Select year"
                      }`
                    : "Select"
                }`}
                title={I18n.t("experts.profile.start_year")}
                fullWidth
                className={ClassNames(item.startMonth && item.startYear && classes.monthDropdown)}
                disabled
                onChange={() => {}}
              />
            </div>
            <MPopover
              open={showFromDatePopover}
              onClose={() => setShowFromDatePopover(false)}
              anchorEl={fromDateAnchorRef.current}
              popoverProps={{
                PaperProps: {
                  style: {
                    width: fromDateAnchorRef?.current?.clientWidth,
                  },
                },
              }}
            >
              <div className={classes.dateWrapper}>
                <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
                  <ExperienceMonthSelect
                    value={item.startMonth}
                    onChange={(startMonth) => {
                      handleItemChange({ ...item, startMonth }, index);
                    }}
                    placeholder={I18n.t("experts.profile.month")}
                    fullWidth
                  />
                  <EducationYear
                    year={item.startYear}
                    handleYearChange={(startYear) => {
                      handleItemChange({ ...item, startYear }, index);
                    }}
                    placeholder={I18n.t("experts.profile.year")}
                    fullWidth
                  />
                </div>
              </div>
            </MPopover>
            <div ref={toDateAnchorRef} onClick={() => setShowToDatePopover(true)} style={{ width: "50%" }}>
              <ExperienceMonthSelect
                placeholder={
                  present
                    ? "Present"
                    : `${item.endMonth ? longMonthsArr[item.endMonth - 1] : "Select month"}, ${
                        item.endYear || "Select year"
                      }`
                }
                title={I18n.t("experts.profile.end_year")}
                onChange={(endMonth) => {
                  handleItemChange({ ...item, endMonth }, index);
                }}
                fullWidth
                className={classes.monthDropdown}
                disabled
              />
            </div>
            <MPopover
              open={showToDatePopover}
              onClose={() => setShowToDatePopover(false)}
              anchorEl={toDateAnchorRef.current}
              popoverProps={{
                PaperProps: {
                  style: {
                    width: toDateAnchorRef?.current?.clientWidth,
                  },
                },
              }}
            >
              <div className={classes.dateWrapper}>
                <FormGroup classes={{ root: classes.checkboxRoot }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CircleUnchecked className={classes.uncheckedIcon} />}
                        checkedIcon={<CircleChecked className={classes.checkedIcon} />}
                        checked={present}
                        onChange={() => {
                          handleItemChange({ ...item, endMonth: null, endYear: null }, index);
                        }}
                      />
                    }
                    label={I18n.t("experts.profile.current_position")}
                  />
                </FormGroup>
                <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
                  <ExperienceMonthSelect
                    value={item.endMonth}
                    onChange={(endMonth) => {
                      handleItemChange({ ...item, endMonth }, index);
                    }}
                    placeholder={I18n.t("experts.profile.month")}
                    fullWidth
                  />
                  <EducationYear
                    year={item.endYear}
                    handleYearChange={(endYear) => {
                      handleItemChange({ ...item, endYear }, index);
                    }}
                    placeholder={I18n.t("experts.profile.year")}
                    fullWidth
                  />
                </div>
              </div>
            </MPopover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceItem;
