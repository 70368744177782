import * as React from "react";
import { I18n } from "react-redux-i18n";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import { IFunnelBreakdownColumn, IWebsite } from "../../../reducers/constants/objectTypes";
import MTableToolbarTimePeriodFilter, {
  sixMonthOptions,
} from "../../../components/Table/Toolbar/Filters/MTableToolbarTimePeriodFilter";
import MTableToolbarDbBreakdownFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarDbBreakdownFilter";

type PropTypes = {
  selectedWebsite: IWebsite;
  timePeriod: any;
  handleTimePeriodChange: (timePeriod: any) => void;
  searchText: any;
  handleSearchTextChange: (searchText: any) => void;
  breakdownFilters: {
    value: any;
    filter: IFunnelBreakdownColumn;
  }[];
  handleBreakdownFiltersChange: (
    breakdownFilters: {
      value: any;
      filter: IFunnelBreakdownColumn;
    }[]
  ) => void;
};

const GoalEventsHeader = (props: PropTypes) => {
  const { selectedWebsite, timePeriod, handleTimePeriodChange, searchText, handleSearchTextChange } = props;
  const { breakdownFilters, handleBreakdownFiltersChange } = props;
  return (
    <MTableToolbar
      searchText={searchText}
      searchPlaceHolder={I18n.t("goal_events_report.header.search_goal")}
      handleSearchTextChanged={(value) => handleSearchTextChange(value)}
      actions={[
        <MTableToolbarTimePeriodFilter
          value={timePeriod}
          selectedOptions={sixMonthOptions}
          handleOnChange={(tp) => handleTimePeriodChange(tp)}
        />,
      ]}
    >
      {breakdownFilters.map((breakdownFilter) => (
        <MTableToolbarDbBreakdownFilter
          filter={breakdownFilter.filter}
          columnValue={breakdownFilter.value}
          placeholder={`${breakdownFilter.filter.label}...`}
          website={selectedWebsite}
          handleChange={(filter: IFunnelBreakdownColumn, columnValue: any) => {
            handleBreakdownFiltersChange(
              breakdownFilters.map((bf) => {
                if (bf.filter.label === filter.label) {
                  return { ...bf, value: columnValue };
                }
                return bf;
              })
            );
          }}
        />
      ))}
      {/*<MTableToolbarBaseSelect*/}
      {/*  optionLabel="name"*/}
      {/*  optionValue="value"*/}
      {/*  options={typeAndSubtypeOptions}*/}
      {/*  selectedOptions={typeAndSubtypeOptions.filter(*/}
      {/*    (type) => types.includes(type.value) || subtypes.includes(type.value)*/}
      {/*  )}*/}
      {/*  multipleOptions*/}
      {/*  title={I18n.t("workflow.writer.types")}*/}
      {/*  placeholder={I18n.t("widgets_main.header.select_types")}*/}
      {/*  handleChange={(values) => {*/}
      {/*    handleValueChanged(*/}
      {/*      "types",*/}
      {/*      (values || []).filter((v) => v.field === "type").map((value) => value.value) || []*/}
      {/*    );*/}
      {/*    handleValueChanged(*/}
      {/*      "subtypes",*/}
      {/*      (values || []).filter((v) => v.field === "subtype").map((value) => value.value) || []*/}
      {/*    );*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<MTableToolbarBaseSelect*/}
      {/*  optionLabel="name"*/}
      {/*  optionValue="value"*/}
      {/*  multipleOptions*/}
      {/*  options={statusOptions}*/}
      {/*  selectedOptions={statusOptions.filter((status) => statuses.includes(status.value))}*/}
      {/*  placeholder={I18n.t("widgets_main.header.select_status")}*/}
      {/*  title={I18n.t("workflow.writer.status")}*/}
      {/*  handleChange={(values) => handleValueChanged("statuses", values?.map((value) => value.value) || [])}*/}
      {/*/>*/}
    </MTableToolbar>
  );
};

export default GoalEventsHeader;
