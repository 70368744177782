"use strict";
import * as React from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "rgb(255, 133, 188)",
    height: 12,
    width: 12,
  },
}));

type propTypes = {
  className?: string;
};

function SlashIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={classNames(classes.icon, className)} viewBox={"0 0 10 15"} fill="none">
      <rect
        width="1.5"
        height="17"
        rx="0.75"
        transform="matrix(-0.866025 -0.5 0.510964 -0.859602 1.27002 15.1816)"
        fill="#808080"
      />
    </SvgIcon>
  );
}

export default SlashIcon;
