import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Theme, Tooltip, Typography } from "@material-ui/core";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import CropFreeIcon from "@material-ui/icons/CropFree";
import TuneIcon from "@material-ui/icons/Tune";
import ChatBubbleIcon from "@material-ui/icons/ChatBubbleOutline";
import classNames from "classnames";
import { I18n } from "react-redux-i18n";

import { IVideoEditor, useVideoEditorContext } from "../VideoEditorContext";
import CutIcon from "../../../icons/CutIcon";
import TransitionIcon from "../../../icons/TransitionIcon";
import ElementsIcon from "../../../icons/ElementsIcon";
import CaptionsIcon from "../../../icons/CaptionsIcon";
import { eVideoProjectTool } from "../../../reducers/constants/objectTypes";
import ability from "../../../casl/ability";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#F5F6F8",
  },
  iconWrapper: {
    display: "flex",
    height: 60,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 2,
  },
  onHover: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      "& $icon": {
        color: theme.palette.common.white,
      },
      "& $toolLabel": {
        opacity: 1,
        color: theme.palette.common.white,
      },
    },
  },
  selected: {
    "& $icon": {
      color: theme.palette.primary.main,
    },
    "& $toolLabel": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "&:hover": {
      backgroundColor: "#F5F6F8",
      "& $icon": {
        color: theme.palette.primary.main,
      },
      "& $toolLabel": {
        color: theme.palette.primary.main,
        opacity: 1,
      },
    },
  },
  disabled: {
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  toolLabel: {
    opacity: 0,
    fontSize: 12,
    lineHeight: "normal",
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));

type Tool = {
  name: IVideoEditor["selectedTool"];
  Icon: any;
  disabled?: boolean;
};

const VideoTools = () => {
  const classes = useStyles();
  const { selectedTool, selectedTab, editVideoEditorProperty } = useVideoEditorContext();

  const setSelectedTool = (tool: Tool["name"]) => {
    const newState = { selectedTool: tool, selectedTab };
    if (tool === eVideoProjectTool.REVIEW) {
      newState.selectedTab = "comments";
    }
    editVideoEditorProperty({ ...newState });
  };

  const tools: Tool[] = [
    {
      name: eVideoProjectTool.TRIM,
      Icon: CutIcon,
      disabled: !ability.can("view", "video_editor_tools"),
    },
    {
      name: eVideoProjectTool.FRAME,
      Icon: CropFreeIcon,
      disabled: true,
    },
    {
      name: eVideoProjectTool.ADJUST,
      Icon: TuneIcon,
      disabled: true,
    },
    {
      name: eVideoProjectTool.TRANSITION,
      Icon: TransitionIcon,
      disabled: true,
    },
    { name: eVideoProjectTool.ELEMENTS, Icon: ElementsIcon, disabled: true },
    { name: eVideoProjectTool.CAPTIONS, Icon: CaptionsIcon, disabled: true },
    { name: eVideoProjectTool.MUSIC, Icon: MusicNoteIcon, disabled: true },
    { name: eVideoProjectTool.REVIEW, Icon: ChatBubbleIcon, disabled: !ability.can("view", "video_editor_tools") },
  ];

  return (
    <div className={classes.wrapper}>
      {tools.map(({ name, Icon, disabled }, i) => (
        <Tooltip placement="right" title={disabled ? I18n.t("video_workflow.upgrade_to_access") : ""} key={i}>
          <div
            key={name}
            className={classNames(
              classes.iconWrapper,
              !disabled && classes.onHover,
              !disabled && name === selectedTool && classes.selected,
              disabled && classes.disabled
            )}
            onClick={() => {
              if (disabled) return;
              if (selectedTool !== name) {
                setSelectedTool(name);
                return;
              }
              setSelectedTool(null);
            }}
          >
            <Icon className={classes.icon} />
            <Typography className={classes.toolLabel} variant="body1">
              {name}
            </Typography>
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default VideoTools;
