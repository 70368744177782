import React from "react";
import { Theme } from "@material-ui/core";
import Select, { components } from "react-select";
import ClassNames from "classnames";
import { makeStyles, useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import DownChevronIcon from "../icons/DownChevronIcon";

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    // zIndex: 3,
    fontFamily: theme.typography.fontFamily,
  },
  extraDropdownIconWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  downChevronIcon: (props) => {
    return {
      fill: props.changeColorOnMenuOpen
        ? props?.selectProps?.menuIsOpen
          ? props?.selectProps?.textColor || theme.palette.text.primary
          : props.closedTextColor || theme.palette.text.secondary
        : props?.selectProps?.textColor || theme.palette.text.primary,
      color: props?.selectProps?.textColor || theme.palette.text.primary,
      "&:hover": {
        fill: props?.selectProps?.textColor || theme.palette.text.primary,
        color: props?.selectProps?.textColor || theme.palette.text.primary,
      },
    };
  },
  fullWidth: {
    width: "100%",
  },
  overflowHiddenOption: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  extraOptionIconWrapper: { display: "flex", alignItems: "center", justifyContent: "space-between" },
}));

const customStyles = (props, theme) => ({
  valueContainer: (provided, state) => ({
    ...provided,
    padding: props?.valuePadding || "2px 10px 2px 8px",
  }),
  option: (provided, state) => {
    const { data, isDisabled, isFocused, isSelected } = state;
    const style = data.style ? data.style : {};
    const backgroundHoverColor =
      state.selectProps.optionBackgroundColorHover || (isDisabled ? "white" : theme.palette.background.default);
    // console.log('state', state);

    return {
      ...provided,
      whiteSpace: "nowrap",
      textAlign: "left",
      height: 30,
      fontSize: theme.typography.pxToRem(14),
      cursor: isDisabled ? "initial" : "pointer",
      fontWeight: props.fontWeight || (theme.typography.fontWeightMedium as any),
      // backgroundColor: isSelected ? theme.palette.secondary.light : 'white',
      backgroundColor: isFocused ? backgroundHoverColor : theme.palette.background.paper,
      // eslint-disable-next-line no-nested-ternary
      color: isDisabled
        ? theme.palette.divider
        : // eslint-disable-next-line no-nested-ternary
        isFocused
        ? theme.palette.primary.main
        : isSelected
        ? state.selectProps.selectedTextColor || theme.palette.primary.main
        : theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: backgroundHoverColor,
        color: isDisabled ? theme.palette.divider : theme.palette.primary.main,
      },
      ...style,
    };
  },
  control: (provided, state) => ({
    ...provided,
    minHeight: 30,
    paddingLeft: 0,
    width: state.selectProps.width,
    height: state.selectProps.height ? state.selectProps.height : 35,
    borderWidth: state.selectProps.borderWidth || "0px 0px 1px 0px",
    // borderWidth: '1px',
    backgroundColor: "transparent",
    borderRadius: state.selectProps.borderRadius,
    borderColor: state.selectProps.tableRow ? "transparent" : state.selectProps.borderColor,
    cursor: state.selectProps.isSearchable ? "unset" : "pointer",
    boxShadow: "none",
    flexWrap: "nowrap",
    webkitFlexWrap: "nowrap",
    padding: state.selectProps.padding,

    "&:active": {
      borderColor: state.selectProps.borderColor,
    },
    "&:focus": {
      borderColor: state.selectProps.borderColor,
    },
    "&:hover": {
      borderColor: state.selectProps.borderColor,
      "& $singleValue": {
        color: "red",
      },
    },
  }),
  input: (provided, state) => ({
    ...provided,
    color: theme.palette.text.primary,
    padding: props?.valuePadding || "2px 10px 2px 8px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    display: "flex",
    padding: props?.valuePadding,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: props.fontWeight
      ? props.fontWeight
      : state.selectProps.isBold
      ? theme.typography.fontWeightBold
      : theme.typography.fontWeightRegular,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
    color: props.changeColorOnMenuOpen
      ? state.selectProps.menuIsOpen
        ? state.selectProps.selectedTextColor || theme.palette.text.secondary
        : props.closedTextColor || theme.palette.text.secondary
      : state.selectProps.selectedTextColor || theme.palette.text.primary,
    textTransform: state.selectProps.capitalize ? "capitalize" : "none",
    "&:hover": {
      color: state.selectProps?.textColorHover,
    },
    ...props?.singleValueStyles,
  }),
  indicatorSeparator: () => null,
  dropdownIndicator: (provided, state) => ({
    ...provided,
    paddingLeft: 2,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
    visibility: state.selectProps.showArrow ? "unset" : "hidden",
    // color: theme.palette.primary.dark,
    color: state.selectProps.arrowColor,
    opacity: state.isDisabled ? 0.5 : 1,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    display: "table",
    background: theme.palette.background.paper,
    marginTop: 0,
    zIndex: 100,
    boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  IndicatorContainer: (provided, state) => ({
    ...provided,
    cursor: "pointer",
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: 8,
    paddingBottom: 8,
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: theme.palette.text.primary,
    opacity: 0.5,
    padding: props?.valuePadding || "2px 10px 2px 8px",
  }),
});

type PropTypes = {
  id?: string;
  customClass?: any;
  options: Array<any>;
  disabled?: boolean;
  onMenuScrollToBottom?: () => void;
  optionLabel?: string;
  optionValue?: string;
  placeholder?: string;
  value: any;
  showArrow?: boolean;
  arrowColor?: string;
  error?: boolean;
  height: number;
  dynamicWidth?: boolean;
  extraDropdownIcon?: React.ReactNode;
  isClearable?: boolean;
  menuIsOpen?: boolean;
  optionBackgroundColorHover?: string;
  textColor?: string;
  textColorHover?: string;
  selectedTextColor?: string;
  fullWidth?: boolean;
  isBold?: boolean;
  searchable?: boolean;
  tableRow?: boolean;
  borderWidth?: number | string;
  borderColor?: string;
  borderRadius?: number;
  menuPlacement?: "auto" | "bottom" | "top";
  padding?: number | string;
  getDisplayLabel?: (option) => string | JSX.Element;
  handleChange?: (newValue, actionMeta) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  handleInputChange?: (input: string) => void;
  width?: string | number;
  isMulti?: boolean;
  textPaddingRight?: number;
  capitalize?: boolean;
  singleValueStyles?: React.CSSProperties;
  customDropdownIndicatorClasses?: string;
  menuPosition?: "fixed" | "absolute";
  maxMenuHeight?: number;
  loading?: boolean;
  changeColorOnMenuOpen?: boolean;
  closedTextColor?: string;
  fontWeight?: string;
  valuePadding?: number | string;
  dropdownZIndexPriority?: boolean;
  getExtraOptionIcon?: (option) => JSX.Element;
};

export type IMSelectTypes = PropTypes;

const DropdownIndicator = (props) => {
  const classes = useStyles(props);
  const { customDropdownIndicatorClasses } = props.selectProps;
  const DropDownIndicator = components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <DownChevronIcon className={ClassNames(classes.downChevronIcon, customDropdownIndicatorClasses || null)} />
    </components.DropdownIndicator>
  );

  return props.extraDropdownIcon ? (
    <div className={classes.extraDropdownIconWrapper}>
      {props.extraDropdownIcon} {DropDownIndicator}
    </div>
  ) : (
    DropDownIndicator
  );
};

// https://react-select.com/components
const MSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const theme = useTheme<Theme>();
  const {
    width,
    optionLabel,
    optionValue,
    value,
    options,
    id,
    customClass,
    capitalize = true,
    disabled = false,
    height,
    fullWidth = false,
    isBold = true,
    searchable = false,
    error = false,
    showArrow = true,
    arrowColor = theme.palette.text.primary,
    textColor = theme.palette.text.secondary,
    textColorHover = theme.palette.primary.main,
    selectedTextColor,
    optionBackgroundColorHover = null,
    borderRadius = 4,
    borderWidth = null,
    borderColor = theme.palette.divider,
    tableRow = false,
    placeholder = I18n.t("common.select_placeholder"),
    menuPlacement = "bottom",
    dynamicWidth = false,
    isClearable = false,
    handleChange,
    handleInputChange,
    getDisplayLabel = null,
    isMulti = false,
    onMenuScrollToBottom,
    textPaddingRight = 60,
    customDropdownIndicatorClasses,
    menuPosition,
    extraDropdownIcon,
    padding,
    maxMenuHeight,
    onMenuOpen,
    onMenuClose,
    changeColorOnMenuOpen = false,
    closedTextColor = theme.palette.text.secondary,
    menuIsOpen,
    loading = false,
    fontWeight,
    dropdownZIndexPriority = false,
    getExtraOptionIcon,
  } = props;

  const getTextWidth = (text) => {
    // re-use canvas object for better performance
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${
      fontWeight ? fontWeight : isBold ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular
    } 14px Montserrat`;
    const metrics = context.measureText(text);
    return metrics.width;
  };

  let customComponents: any = {
    DropdownIndicator: (selectProps) => (
      <DropdownIndicator extraDropdownIcon={extraDropdownIcon} {...props} {...selectProps} />
    ),
  };

  if (getExtraOptionIcon) {
    customComponents.Option = (props) => {
      return (
        <div className={classes.extraOptionIconWrapper}>
          <components.Option {...props} className={classes.overflowHiddenOption} />
          {getExtraOptionIcon(props.data)}
        </div>
      );
    };
  }

  return (
    <Select
      inputId={id}
      isClearable={isClearable}
      isLoading={loading}
      menuIsOpen={menuIsOpen}
      isDisabled={disabled}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onMenuScrollToBottom={onMenuScrollToBottom}
      className={ClassNames(classes.select, fullWidth && classes.fullWidth, customClass || null)}
      value={value}
      isSearchable={searchable}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      onInputChange={(input) => handleInputChange && handleInputChange(input)}
      getOptionLabel={(opt) => (getDisplayLabel && getDisplayLabel(opt)) || (optionLabel && opt[optionLabel]) || opt}
      getOptionValue={(opt) => (optionValue && opt[optionValue]) || opt}
      isOptionDisabled={(opt) => (opt.disabled ? opt.disabled : false)}
      components={customComponents}
      styles={customStyles(props, theme)}
      menuPortalTarget={dropdownZIndexPriority ? document.body : undefined}
      isMulti={isMulti}
      height={height}
      isBold={isBold}
      menuPlacement={menuPlacement}
      maxMenuHeight={maxMenuHeight}
      showArrow={showArrow}
      arrowColor={arrowColor}
      textColor={textColor}
      textColorHover={textColorHover}
      selectedTextColor={selectedTextColor}
      optionBackgroundColorHover={optionBackgroundColorHover}
      borderColor={error ? theme.palette.secondary.main : borderColor}
      borderWidth={borderWidth}
      borderRadius={borderRadius}
      tableRow={tableRow}
      customDropdownIndicatorClasses={customDropdownIndicatorClasses}
      menuPosition={menuPosition}
      padding={padding}
      width={
        dynamicWidth
          ? `${
              getTextWidth(
                value
                  ? (getDisplayLabel && getDisplayLabel(value)) || (optionLabel && value[optionLabel]) || value
                  : placeholder
              ) + textPaddingRight
            }px`
          : width || "unset"
      }
    />
  );
};

export default MSelect;
