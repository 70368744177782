import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import ColorPickerBaseComponent from "../BaseComponents/ColorPickerBaseComponent";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  valueWrap: {
    display: "flex",
    alignItems: "center",
    width: 180,
    justifyContent: "space-between",
  },
  backToDefault: {
    color: theme.palette.text.hint,
    fontSize: 16,
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    maxWidth: 60,
    whiteSpace: "pre",
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  title?: string;
  value: string;
  hoverValue?: string;
  defaultValue?: string;
  defaultHoverValue?: string;
  handleHoverChange?: any;
  isValueChanged: boolean;
  handleChange: any;
  hover?: any;
};

const BackgroundColorCSS = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    title = I18n.t("rich_text_editor.background_color"),
    value = null,
    defaultValue = "",
    isValueChanged,
    handleChange,
    hover,
    defaultHoverValue = "",
    hoverValue = null,
    handleHoverChange,
  } = props;

  return (
    <div className={classes.row}>
      <Typography variant={"body2"} className={ClassNames(classes.text, isValueChanged && classes.boldText)}>
        {title}
      </Typography>
      <div className={classes.valueWrap}>
        <ColorPickerBaseComponent
          maximize={!hover}
          defaultColor={defaultValue}
          borderRadius={2}
          color={value || defaultValue}
          handleChange={handleChange}
          top={"0px"}
          left={"-65px"}
        />
        {hover && (
          <ColorPickerBaseComponent
            defaultColor={defaultHoverValue}
            borderRadius={2}
            color={hoverValue || defaultHoverValue || defaultValue}
            handleChange={handleHoverChange}
            top={"0px"}
            left={"-65px"}
          />
        )}
      </div>
    </div>
  );
};
export default BackgroundColorCSS;
