import { GOAL_COMPLETION_REPORTS } from "./constants/actionTypes";
import { DEFAULT_FUNNEL_FILTER_COLUMN } from "../helpers/constants";

const initialState = {
  goalCompletionByPage: {
    loading: true,
    data: [],
    totalItems: 0,
    totalPages: 0,
    page: 1,
    pageSize: 20,
    sortField: null,
    timePeriod: null,
    timeFrame: null,
    countFilter: null,
    totalsRow: null,
    breakdownFilters: [
      {
        filter: {
          label: "Page Url",
          autocomplete: {
            field: "url",
            table: "events",
          },
          value: {
            field: "page_url",
            table: "page_goal_values",
          },
        },
        value: null,
      },
    ],
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case GOAL_COMPLETION_REPORTS.GOAL_COMPLETION_BY_PAGE_CHANGED:
      return {
        ...state,
        goalCompletionByPage: {
          ...state.goalCompletionByPage,
          [action.key]: action.value,
        },
      };
    case GOAL_COMPLETION_REPORTS.GOAL_COMPLETION_BY_PAGE_LOADED:
      return {
        ...state,
        goalCompletionByPage: {
          ...state.goalCompletionByPage,
          totalItems: action.payload.total,
          totalPages: action.payload.totalPages,
          data: action.payload.results,
          totalsRow: action.payload.totalQueryResults,
        },
      };
    default:
      return state;
  }
};
