import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import { Check, FilterList } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    border: (props: PropTypes) => (props.withBorder ? `1px solid ${theme.palette.divider}` : "none"),
    borderRadius: 3,
    "&:hover": {
      cursor: "pointer",
      "& svg": {
        color: theme.palette.primary.main,
        // add transform animation
        animation: "animate 2s infinite",
      },
    },
  },
  expanded: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    gap: 3,
    overflowY: "auto",
  },
  popper: {
    zIndex: 1000,
  },
  categoryWrapper: {
    display: "flex",
    padding: 10,
    flexDirection: "column",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  categoryLabel: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "normal",
    marginBottom: 5,
  },
  optionWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 5,
    padding: "7px 3px",
    "& svg": {
      visibility: "hidden",
      fontSize: 14,
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  optionLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    lineHeight: "normal",
    cursor: "pointer",
  },
  checkedOption: {
    "& $optionLabel": {
      color: theme.palette.primary.main,
    },
    "& svg": {
      visibility: "visible",
      color: theme.palette.primary.main,
    },
  },
  disabledOption: {
    "& $optionLabel": {
      color: theme.palette.text.disabled,
    },
    "& svg": {
      color: theme.palette.text.disabled,
    },
  },
}));

type IOption = {
  label: string;
  value?: any;
  optionKey?: string;
  checked?: boolean;
  disabled?: boolean;
  closeOnClick?: boolean;
};

export type ICategoryOption = { categoryLabel?: string; categoryKey: string; options: IOption[] };

type PropTypes = {
  options: ICategoryOption[];
  closeOnClick?: boolean;
  zIndex?: number;
  withBorder?: boolean;
  handleChange: ({ categoryKey, option }: { categoryKey: string; option: IOption }) => void;
};

const MCategorizedOptionsFilter = (props: PropTypes) => {
  const classes = useStyles(props);
  const { options, closeOnClick, zIndex } = props;
  const { handleChange } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <div className={ClassNames(classes.container, open && classes.expanded)} onClick={handleToggle} ref={anchorRef}>
        <FilterList />
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement={"bottom-start"}
        className={classes.popper}
        style={{
          zIndex: zIndex || 1000,
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <div className={classes.paper}>
            {options.length > 0 &&
              options.map((categoryOption, index) => (
                <div key={index} className={classes.categoryWrapper}>
                  {categoryOption.categoryLabel && (
                    <Typography className={classes.categoryLabel}>{categoryOption.categoryLabel}</Typography>
                  )}
                  {categoryOption.options.map((option, index) => (
                    <div
                      className={ClassNames(
                        classes.optionWrapper,
                        option.checked && classes.checkedOption,
                        option.disabled && classes.disabledOption
                      )}
                      key={`${option.value}-${index}`}
                    >
                      <Check />
                      <Typography
                        key={`${option.value}-${index}`}
                        className={classes.optionLabel}
                        onClick={(event) => {
                          event.stopPropagation();
                          if (option.disabled) return;
                          handleChange({
                            categoryKey: categoryOption.categoryKey,
                            option: { ...option, checked: !option.checked },
                          });
                          if (closeOnClick || option.closeOnClick) setOpen(false);
                        }}
                      >
                        {option.label}
                      </Typography>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default MCategorizedOptionsFilter;
