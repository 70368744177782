import React from "react";
import { I18n } from "react-redux-i18n";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";

type PropTypes = {
  postTypes: string[];
  handlePostTypesSelected: (postTypes: string[]) => void;
};

const MTableToolbarKeywordStrategyTypeFilter = (props: PropTypes) => {
  const { postTypes, handlePostTypesSelected } = props;

  const funnelStages = [
    {
      _id: "bottom",
      name: "Bottom",
    },
    {
      _id: "near bottom",
      name: "Near bottom",
    },
    {
      _id: "lower",
      name: "Lower",
    },
    {
      _id: "middle",
      name: "Middle",
    },
    {
      _id: "upper",
      name: "Upper",
    },
    {
      _id: "top",
      name: "Top",
    },
    {
      _id: "None",
      name: "None",
    },
  ];
  const values = funnelStages
    .map((pt) => {
      if (postTypes.includes(pt._id)) {
        return {
          _id: pt._id,
          name: pt.name,
        };
      }
    })
    .filter(Boolean);
  return (
    <MTableToolbarBaseSelect
      multipleOptions
      options={funnelStages}
      selectedOptions={values}
      placeholder={I18n.t("strategy.outlinePages.funnel_stage_filter")}
      title={I18n.t("strategy.outlinePages.funnel_stage_title")}
      handleChange={(v) => {
        if (v.find((value) => value._id === "all")) {
          handlePostTypesSelected([]);
          return;
        }
        handlePostTypesSelected(v.map((value) => value._id));
      }}
    />
  );
};

export default MTableToolbarKeywordStrategyTypeFilter;
