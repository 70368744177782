import * as React from "react";
import theme from "../../../themes/theme";

const DividerElementReadOnly = ({ attributes, children, element }) => {
  const dividerCss = element.data?.dividerCss || {};
  return (
    <div
      style={{
        width: "100%",
        height: 1,
        marginTop: 5,
        marginBottom: 5,
        backgroundColor: theme.palette.divider,
        marginLeft: element.alignment === "left" ? "unset" : "auto",
        marginRight: element.alignment === "right" ? "unset" : "auto",
        ...dividerCss,
      }}
      {...attributes}
    >
      {children}
    </div>
  );
};

export default DividerElementReadOnly;
