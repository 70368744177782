import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Classnames from "classnames";
import { I18n } from "react-redux-i18n";
import { Theme, ThemeProvider, Typography } from "@material-ui/core";
import numeral from "numeral";
import { Close } from "@material-ui/icons";

import ArrowRightRoundedIcon from "../../../../../../../../icons/ArrowRightRoundedIcon";
import DragIcon from "../../../../../../../../icons/DragIcon";
import {
  IWebsite,
  IFile,
  IWidgetImageProps,
  IWidgetVideoProps,
} from "../../../../../../../../reducers/constants/objectTypes";
import InsertImageDialog from "../../../../../../../Modals/InsertImageDialog/InsertImageDialog";
import AppTheme from "../../../../../../../../themes/theme";
import { colors } from "../../../../../../../../helpers/constants";
import InsertVideoDialog from "../../../../../../../Modals/InsertVideoDialog";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const color = palette.text.primary;
  return {
    root: {
      display: "flex",
      flexDirection: "column",
    },
    padding: {
      padding: 0,
    },
    item: {
      display: "flex",
      alignItems: "center",
      marginBottom: 2,
      justifyContent: "space-between",
      "&:hover": {
        "& $dragIcon": {
          display: "block",
        },
        "& $countText": {
          display: "none",
        },
      },
    },
    itemCount: {
      display: "flex",
      alignItems: "start",
      width: 25,
      flexShrink: 0,
    },
    countText: ({ isDragging }: PropTypes) => ({
      fontSize: 14,
      fontWeight: typography.fontWeightMedium as any,
      color,
      lineHeight: "inherit",
      display: isDragging ? "none" : "block",
    }),
    dragIcon: ({ isDragging }: PropTypes) => ({
      display: !isDragging ? "none" : "block",
      fill: color,
      width: 25,
      height: 25,
      marginLeft: -5,
      cursor: "pointer",
    }),
    media: {
      padding: 15,
      height: 55,
      minHeight: "fit-content",
      flexGrow: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "3px 3px 3px 3px",
      borderWidth: "1px 1px 1px 1px",
      cursor: "pointer",
      gap: 10,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
      "&:hover": {
        backgroundColor: colors.widgetOnHoverBackgroundColor,
      },
    },
    itemLeftContainer: {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
    },
    title: {
      width: "100%",
      fontSize: 14,
      fontWeight: typography.fontWeightMedium as any,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color,
      opacity: 0.5,
    },
    itemRightContainer: {
      display: "flex",
      justifySelf: "flex-end",
      justifyContent: "flex-end",
      alignItems: "center",
      marginRight: -12,
      minWidth: 25,
      flexShrink: 0,
      gap: 10,
      textAlign: "right",
    },
    icon: {
      fontSize: 19,
      color,
      marginTop: 2,
      opacity: 0.5,
    },
    size: {
      display: "block",
    },
    removeIcon: ({ itemNumber }: PropTypes) => ({
      height: 20,
      width: 20,
      color: palette.text.primary,
      marginLeft: 15,
      "&:hover": {
        cursor: "pointer",
      },
      ...(itemNumber && itemNumber > 1
        ? {
            visibility: "hidden",
          }
        : {
            display: "none",
          }),
    }),
    showRemoveIcon: ({ itemNumber }: PropTypes) => ({
      ...(itemNumber && itemNumber > 1
        ? {
            visibility: "visible",
          }
        : {
            display: "block",
          }),
    }),
    fullOpacity: {
      opacity: 1,
    },
  };
});

type PropTypes = {
  type: "image" | "video";
  itemNumber?: number;
  isDragging?: boolean;
  selectedWebsite: IWebsite;
  handleChange: (media: IWidgetImageProps | IWidgetVideoProps) => void;
  handleDelete?: () => void;
} & (
  | {
      type: "image";
      media: IWidgetImageProps;
    }
  | {
      type: "video";
      media: IWidgetVideoProps;
    }
);

const GenericMediaItem = (props: PropTypes) => {
  const { itemNumber, selectedWebsite, type, media } = props;
  const { handleChange, handleDelete } = props;
  const classes = useStyles(props);

  const [modalOpen, setModalOpen] = useState(false);

  const mediaTitle = type === "image" ? media?.title : media?.video?.structuredData?.name;

  return (
    <>
      <div className={classes.item}>
        {itemNumber && (
          <div className={classes.itemCount}>
            <Typography className={classes.countText}>{itemNumber}</Typography>
            <DragIcon className={classes.dragIcon} />
          </div>
        )}
        <div className={classes.media} onClick={() => setModalOpen(true)}>
          <div className={classes.itemLeftContainer}>
            <Typography className={Classnames(classes.title, mediaTitle && classes.fullOpacity)}>
              {(type === "image" ? media?.title : media?.video?.structuredData?.name) ||
                I18n.t("common.select_placeholder")}
            </Typography>
          </div>
          <div className={classes.itemRightContainer}>
            {type === "image" && media?.size && (
              <Typography className={Classnames(classes.title, classes.size, mediaTitle && classes.fullOpacity)}>
                {numeral(media.size).format("0.0 b")}
              </Typography>
            )}
            <ArrowRightRoundedIcon className={Classnames(classes.icon, mediaTitle && classes.fullOpacity)} />
          </div>
        </div>
        <Close
          className={Classnames(
            classes.removeIcon,
            handleDelete && !!(type === "image" ? media?.src : media?.video?.url) && classes.showRemoveIcon
          )}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        />
      </div>
      {modalOpen && type === "image" && (
        <ThemeProvider theme={AppTheme}>
          <InsertImageDialog
            website={selectedWebsite}
            handleInsertClick={({ cdnUrl, size, title, alt }: IFile) => {
              handleChange({ src: cdnUrl, alt, title, size });
              setModalOpen(false);
            }}
            handleCloseClick={() => setModalOpen(false)}
          />
        </ThemeProvider>
      )}
      {modalOpen && type === "video" && (
        <InsertVideoDialog
          handleCloseClick={() => setModalOpen(false)}
          website={selectedWebsite}
          defaultUrl={media?.video?.url}
          handleInsertClick={({ video, image }) => {
            handleChange({ video, thumbnail: image });
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default GenericMediaItem;
