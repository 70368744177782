import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import CfButton from "../../../components/CfButton";
import { EXCLUDE_KEYWORDS } from "../../../reducers/constants/actionTypes";
import agent from "../../../agent";
import { IExcludedKeywordSeed } from "../../../reducers/constants/objectTypes";
import KeyIcon from "../../../icons/KeyIcon";
import MTableRow from "../../../components/Table/Rows/MTableRow";
import MTableCellFirstWithSelect from "../../../components/Table/Cells/MTableCellFirstWithSelect";
import MTableCellText from "../../../components/Table/Cells/MTableCellText";
import MTableCellNumber from "../../../components/Table/Cells/MTableCellNumber";
import MTableCellDate from "../../../components/Table/Cells/MTableCellDate";
import MTableCell from "../../../components/Table/Cells/MTableCell";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    "&:hover": {
      "& $excludeButton": {
        display: "flex",
      },
    },
  },

  keyIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#84C16E",
    width: 25,
    height: 25,
    borderRadius: "50%",
  },
  excludeButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    padding: "12px 25px",
    display: "none",
    borderRadius: 75,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

type PropTypes = {
  keyword: IExcludedKeywordSeed;
  checkedKeywordsList: IExcludedKeywordSeed[];
  isFirstRow: boolean;
  isLastRow: boolean;
  formatTypeOptions: (type) => string;
  handleCheckedBoxChanged: (checkedKeywordsList) => void;
  onKeywordChanged: (keyword) => void;
  onKeywordsRemoved: (keywords) => void;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
  checkedKeywordsList: state.excludeKeywords.checkedKeywordsList,
});

const mapDispatchToProps = (dispatch) => ({
  handleCheckedBoxChanged: (checkedKeywordsList) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_CHECKED_BOX_CHANGED, checkedKeywordsList });
  },
  onKeywordChanged: (keyword) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_KEYWORD_CHANGED, keyword });
  },
  onKeywordsRemoved: (keywords) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_KEYWORDS_REMOVED, keywords });
  },
});

const ExcludeKeywordItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    keyword,
    checkedKeywordsList,
    onKeywordChanged,
    onKeywordsRemoved,
    handleCheckedBoxChanged,
    formatTypeOptions,
  } = props;
  const [displayHover, setDisplayHover] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = (e) => {
    e.stopPropagation();
    agent.ExcludedKeywordSeeds.cancelExclusion([keyword.id])
      .then(() => {
        onKeywordsRemoved([keyword]);
        enqueueSnackbar(I18n.t("exclude_keywords.exclusion_canceled"));
      })
      .catch(() => {
        enqueueSnackbar(I18n.t("exclude_keywords.exclusion_error"));
      });
  };

  const handleItemClicked = () => {
    if (!checkedKeywordsList.includes(keyword)) {
      handleCheckedBoxChanged([...checkedKeywordsList, keyword]);
    } else {
      handleCheckedBoxChanged(checkedKeywordsList.filter((checkedKeyword) => checkedKeyword !== keyword));
    }
  };

  return (
    <MTableRow
      handleRowClick={handleItemClicked}
      selected={checkedKeywordsList.includes(keyword)}
      customClassName={classes.row}
    >
      <MTableCellFirstWithSelect
        selected={checkedKeywordsList.includes(keyword)}
        onSelect={(value) => handleItemClicked()}
        columnWidth={"33%"}
        cellText={keyword.seed}
        prefix={
          <div onClick={handleItemClicked} className={classes.keyIconWrapper}>
            <KeyIcon />
          </div>
        }
      />
      <MTableCellText text={formatTypeOptions(keyword.type)} />
      <MTableCellNumber value={keyword.excluded_keywords_count} />
      <MTableCellDate date={keyword.created_at} />
      <MTableCell>
        <CfButton
          height={36}
          customClass={classes.excludeButton}
          onClick={handleDelete}
          text={I18n.t("exclude_keywords.cancel_exclusion")}
        />
      </MTableCell>
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExcludeKeywordItem);
