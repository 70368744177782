import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import SwitchProperty from "../items/itemProperties/components/SwitchProperty";
import { IWidget, IWidgetField, IWidgetItem } from "../../../../../../../reducers/constants/objectTypes";
import FormWidgetItems from "../items/FormWidgetItems";
import AddButton from "../../../components/AddButton";
import SubmitButton from "../../../components/SubmitButton";
import CustomSwitchOption from "../../components/CustomSwitchOption";
import EditWidgetName from "../components/EditWidgetName";

const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;
  const isDark = palette.type === "dark";
  return {
    buttonWrapper: {
      width: "100%",
      padding: "0px 30px 0px 30px",
      marginTop: "2px",
    },
    buttonAdd: {
      backgroundColor: palette.secondary.main,
    },
    formWidgetItemsWrapper: ({ noPadding = false }: PropTypes) => ({
      padding: !noPadding ? 20 : 0,
      display: "flex",
      flexDirection: "column",
    }),
    backgroundSwitch: {
      marginBottom: 10,
      "& .MuiTypography-root": {
        fontWeight: 600,
      },
    },
    toggleMultiStep: (props: PropTypes) => {
      const border = isDark ? `1px solid ${palette.grey["200"]}` : `1px solid ${palette.secondary.light}`;
      return {
        backgroundColor: palette.grey["100"],
        // border,
        margin: 30,
        borderRadius: 3,
      };
    },
  };
});

type PropTypes = {
  widget: IWidget;
  selectedItem: number;
  selectedStep?: number;
  noPadding?: boolean;

  removeField: (field: IWidgetField) => void;
  setSelectedStep: (selectedStep: number) => void;
  setAddNewItemMode: (open: boolean) => void;
  onSubmitButtonClick: () => void;
  setSelectedFieldId: (fieldId: string) => void;
  addNewField: (field: IWidgetField) => void;
  handleItemChange: (item: IWidgetItem) => void;
  editBackground?: (background: IWidget["background"]) => void;
};

const OnePageForm = (props: PropTypes) => {
  const classes = useStyles(props);
  const { widget, selectedStep = 0, selectedItem } = props;
  const {
    removeField,
    addNewField,
    handleItemChange,
    setSelectedStep,
    setAddNewItemMode,
    setSelectedFieldId,
    onSubmitButtonClick,
    editBackground,
  } = props;

  return (
    <>
      {/* <div className={classes.toggleMultiStep}> */}
      {/*  <SwitchProperty */}
      {/*    checked={widget.items[0].multistep} */}
      {/*    label={I18n.t("widgets_builder.build.multistep_form_label")} */}
      {/*    onChange={(e) => { */}
      {/*      setSelectedStep(null); */}
      {/*      const multiStep = e.target.checked; */}
      {/*      changeMultistep(0, multiStep); */}
      {/*    }} */}
      {/*  /> */}
      {/* </div> */}
      {!widget.isInlineField && <EditWidgetName />}
      <div className={classes.formWidgetItemsWrapper}>
        {editBackground && (
          <CustomSwitchOption
            title={I18n.t("widgets_builder.build.white_background")}
            onChange={(checked) =>
              editBackground({
                ...widget.background,
                defaultWhiteBackground: checked,
              })
            }
            checked={widget.background.defaultWhiteBackground}
            customClass={classes.backgroundSwitch}
          />
        )}

        <FormWidgetItems
          onItemsReorder={(fields) =>
            handleItemChange({
              ...widget.items[selectedItem],
              steps: widget.items[selectedItem].steps.map((step, i) => {
                if (i === (selectedStep || 0)) {
                  return {
                    ...step,
                    fields,
                  };
                }
                return step;
              }),
            })
          }
          onDeleteClick={(field, index) => {
            removeField(field);
          }}
          onClick={(field) => setSelectedFieldId(field.fieldId)}
          onCopyClick={(field) => addNewField({ ...field, fieldId: null })}
          fields={widget.items[selectedItem].steps[selectedStep || 0]?.fields}
        />
        <SubmitButton onClick={onSubmitButtonClick} />
        <AddButton
          className={classes.buttonAdd}
          onClick={() => {
            setAddNewItemMode(true);
          }}
          text={I18n.t("widgets_builder.build.add_item_button")}
        />
      </div>
    </>
  );
};
export default OnePageForm;
