import React from "react";
import { makeStyles, Popover, PopoverProps } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

type PropTypes = {
  open: boolean;
  anchorEl: Element | ((element: Element) => Element);
  children?: JSX.Element;
  onClose: () => void;
  popoverProps?: Omit<PopoverProps, "open" | "anchorEl">;
};

const MPopover = (props: PropTypes) => {
  const { open, anchorEl, children } = props;
  const { onClose } = props;
  const classes = useStyles();

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      className={classes.root}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      BackdropProps={{ invisible: true }}
      {...props.popoverProps}
    >
      {children}
    </Popover>
  );
};

export default MPopover;
