import filter from "lodash/filter";
import { INDUSTRY_EXPERTS, TARGETED_POSTS } from "./constants/actionTypes";

const initialState = {
  expertsLoaded: false,
  experts: null,
  selectedExpert: null,

  expert: null,
  posts: null,

  filter: "ALL",
  searchText: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INDUSTRY_EXPERTS.EXPERTS_LOADED:
      return {
        ...state,
        expertsLoaded: true,
        experts: action.payload.experts,
        selectedExpert: action.payload.experts.length > 0 ? action.payload.experts[0] : null,
      };
    case INDUSTRY_EXPERTS.SET_SELECTED_EXPERT:
      return {
        ...state,
        selectedExpert: action.expert,
      };
    case INDUSTRY_EXPERTS.EXPERT_CHANGED:
      return {
        ...state,
        expert: action.expert,
      };
    case INDUSTRY_EXPERTS.POSTS_LOADED:
      return {
        ...state,
        expert: action.expert,
        posts: action.posts,
      };
    case INDUSTRY_EXPERTS.EXPERT_POST_CHANGED:
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post._id === action.post._id) {
            return action.post;
          }
          return post;
        }),
      };
    case INDUSTRY_EXPERTS.EXPERT_DELETED: {
      const experts = filter(state.experts, (e) => e._id !== action.expert._id);
      return {
        ...state,
        experts,
        selectedExpert: experts.length > 0 ? experts[0] : null,
        expert: null,
        posts: null,
      };
    }
    case INDUSTRY_EXPERTS.EXPERT_CREATED:
      return {
        ...state,
        experts: [action.expert, ...state.experts],
        selectedExpert: action.expert,
        expert: null,
        posts: null,
      };
    case INDUSTRY_EXPERTS.SEARCH_TEXT_CHANGED:
      return {
        ...state,
        searchText: action.text,
      };
    case INDUSTRY_EXPERTS.FILTER_CHANGED:
      return {
        ...state,
        filter: action.filter,
        selectedExpert: null,
        expert: null,
        posts: null,
      };
    default:
      return state;
  }
};
