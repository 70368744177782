import React from "react";
import ClassNames from "classnames";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  outlinedRoot: (props: PropTypes) => ({
    borderRadius: props.borderRadius || 40,
    backgroundColor: props.backgroundColor || "transparent",

    alignItems: props.multiline ? "flex-start" : "center",
    minHeight: props.multiline && !props.height ? "fit-content" : props.height || "unset",

    "& fieldset": {
      borderColor: `${props.borderColor || theme.palette.divider} !important`,
    },
    "&:hover fieldset": {
      borderColor: `${props.borderColor || theme.palette.divider} !important`,
    },
    "&.Mui-focused fieldset": {
      borderColor: `${props.borderColor || theme.palette.divider} !important`,
    },
    "&.Mui-error fieldset": {
      borderColor: `red !important`,
    },
  }),
  focused: (props: PropTypes) => ({
    borderColor: props.borderColor || theme.palette.divider,
    $notchedOutline: {
      borderColor: props.borderColor || theme.palette.divider,
    },
  }),
  notchedOutline: (props: PropTypes) => ({
    borderColor: props.borderColor || theme.palette.divider,
    "&:hover": {
      borderColor: props.borderColor || theme.palette.divider,
    },
  }),
  input: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    marginTop: (props: PropTypes) => (props.multiline ? "-1.5px" : "auto"),
    marginBottom: (props: PropTypes) => (props.multiline ? "-1.5px" : "auto"),
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: theme.palette.text.hint,
    },
  },
}));

type PropTypes = {
  customClass?: string;
  inputClassName?: string;
  inputRootClassName?: string;

  id?: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  outlined?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  error?: boolean;
  height?: number;
  backgroundColor?: string;
  borderRadius?: number;
  disableUnderline?: boolean;
  multiline?: boolean;
  borderColor?: string;
  rows?: number;
  helperText?: string;
  margin?: "none" | "dense" | "normal";
  startAdornment?: React.ReactNode;
  label?: string;
  desiredCount?: number;
  enforceDesiredCount?: boolean;
  customStyle?: any;
  extendedInputProps?: object;
  onPaste?: Function;
  onChange?: Function;
  onFocus?: Function;
  onBlur?: Function;
  handleEnterClick?: Function;
};

const MTextField = (props: PropTypes) => {
  const classes = useStyles(props);

  const {
    customStyle = {},
    id = null,
    value = "",
    disabled = false,
    autoFocus = false,
    disableUnderline = true,
    outlined = false,
    multiline = false,
    type = "text",
    label = null,
    startAdornment = null,
    extendedInputProps = {},
    height = null,
    rows = 1,
    placeholder = null,
    fullWidth = false,
    customClass = null,
    inputRootClassName = null,
    inputClassName = null,
    desiredCount = null,
    enforceDesiredCount = fullWidth,
    margin = "normal",
    error = false,
    helperText = "",
    onPaste,
  } = props;
  const { onChange, onFocus, onBlur, handleEnterClick } = props;

  const inputProps = {} as Partial<OutlinedInputProps>;
  if (!outlined) {
    inputProps.disableUnderline = disableUnderline;
    inputProps.classes = {
      root: inputRootClassName,
      input: ClassNames(classes.input, inputClassName),
    };
  } else {
    inputProps.classes = {
      root: classes.outlinedRoot,
      notchedOutline: classes.notchedOutline,
      focused: classes.focused,
      input: ClassNames(classes.input, inputClassName),
    };
  }

  if (startAdornment) {
    inputProps.startAdornment = startAdornment;
  }

  const onKeyDown = (e) => {
    if (e.key === "Enter" && handleEnterClick) {
      e.preventDefault();
      handleEnterClick(e.target.value);
    }
  };

  return (
    <TextField
      style={customStyle}
      id={id}
      color={"primary"}
      label={label}
      autoFocus={autoFocus}
      onFocus={() => onFocus && onFocus()}
      onBlur={() => onBlur && onBlur()}
      type={type}
      error={error}
      helperText={error ? `${helperText} is required` : ""}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      fullWidth={fullWidth}
      onKeyDown={(e) => onKeyDown(e)}
      className={ClassNames(classes.root, customClass)}
      onChange={(event) => onChange && onChange(event.target.value)}
      multiline={multiline}
      onPaste={onPaste}
      inputProps={desiredCount && enforceDesiredCount ? { maxLength: desiredCount } : extendedInputProps}
      margin={margin || outlined ? "dense" : "normal"}
      minRows={multiline && height ? height / (16 * 1.3) : rows}
      // minRows={1}
      // maxRows={multiline ? height / (16 * 1.3) : null}
      variant={outlined ? "outlined" : "standard"}
      InputProps={inputProps}
    />
  );
};

export default React.memo(MTextField);
