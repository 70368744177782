import * as React from "react";
import { useEffect, useRef } from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { useFocused, useSlate } from "slate-react";
import { Paper } from "@material-ui/core";
import { Editor, Range } from "slate";
import Divider from "@material-ui/core/Divider";
import { I18n } from "react-redux-i18n";
import CommentButtonV2 from "../commentsv2/CommentButtonV2";
import LinkButton from "../link/LinkButton";
import MarkButton from "./components/MarkButton";
import BoldIcon from "./icons/hover/BoldIcon";
import ItalicIcon from "./icons/hover/ItalicIcon";
import UnderlineIcon from "./icons/hover/UnderlineIcon";
import BlockButton from "./components/BlockButton";
import BigTitleIcon from "./icons/hover/BigTitleIcon";
import SmallTitleIcon from "./icons/hover/SmallTitleIcon";
import CommentIcon from "./icons/hover/CommentIcon";
import LinkIcon from "./icons/hover/LinkIcon";
import AiAssistantButton from "../aiAssitant/AiAssistantButton";

const useStyles = makeStyles((theme: Theme) => ({
  toolbarWrapper: {
    position: "fixed",
    zIndex: 1000,
    top: -10000,
    left: -10000,
    marginTop: -6,
    opacity: 0,
    boxShadow: "none",
  },
  innerWrapper: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 4,
    backgroundColor: "#202020",
    height: 35,
    alignItems: "center",
    padding: "0px 3px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  itemsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  toolbarDivider: {
    height: 15,
    marginLeft: 8,
    marginRight: 8,
    width: 1,
  },
}));

type PropTypes = {
  disableAi: boolean;
};

function HoveringToolbar(props: PropTypes) {
  const classes = useStyles(props);
  const ref = useRef<HTMLDivElement | null>();
  const editor = useSlate();
  const inFocus = useFocused();
  const [el, setEl] = React.useState(null);
  useEffect(() => {
    setEl(ref.current);
    const { selection } = editor;
    if (!el) {
      return;
    }

    if (!selection || !inFocus || Range.isCollapsed(selection) || Editor.string(editor, selection) === "") {
      el.removeAttribute("style");
      return;
    }
    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    el.style.opacity = "1";
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
  });

  const { disableAi } = props;

  return (
    <Paper
      ref={ref}
      onClick={() => {}}
      className={classes.toolbarWrapper}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <div className={classes.innerWrapper}>
        {!disableAi && <AiAssistantButton onClick={() => el.removeAttribute("style")} />}
        {!disableAi && <Divider className={classes.toolbarDivider} orientation="vertical" />}
        <CommentButtonV2 Icon={CommentIcon} onClick={() => el.removeAttribute("style")} />
        <LinkButton Icon={LinkIcon} onClose={() => () => el.removeAttribute("style")} />
        <Divider className={classes.toolbarDivider} orientation="vertical" />
        <MarkButton format="bold" Icon={BoldIcon} tooltip={I18n.t("rich_text_editor.tooltips.bold")} />
        <MarkButton format="italic" Icon={ItalicIcon} tooltip={I18n.t("rich_text_editor.tooltips.italic")} />
        <MarkButton format="underline" Icon={UnderlineIcon} tooltip={I18n.t("rich_text_editor.tooltips.underline")} />
        <BlockButton format="heading-two" Icon={SmallTitleIcon} tooltip={I18n.t("rich_text_editor.tooltips.h3")} />
        <BlockButton format="heading-one" Icon={BigTitleIcon} tooltip={I18n.t("rich_text_editor.tooltips.h2")} />
      </div>
    </Paper>
  );
}

export default HoveringToolbar;
