import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        width: 14,
        height: 7,
    }
}));

type PropTypes = {
    className?: string;
    onClick?: (e: any) => void;
};

function AttachIcon(props: PropTypes) {
    const classes = useStyles(props);
    const { className, onClick } = props;
    return (
        <SvgIcon onClick={onClick} className={classNames(classes.icon, className)} viewBox={"0 0 14 7"}>
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75 6.5C2.82917 6.5 2.05729 6.2125 1.43437 5.6375C0.811458 5.0625 0.5 4.35 0.5 3.5C0.5 2.65 0.811458 1.9375 1.43437 1.3625C2.05729 0.7875 2.82917 0.5 3.75 0.5H6.025C6.16583 0.5 6.28229 0.5425 6.37437 0.6275C6.46646 0.7125 6.5125 0.82 6.5125 0.95C6.5125 1.08 6.46646 1.1875 6.37437 1.2725C6.28229 1.3575 6.16583 1.4 6.025 1.4H3.75C3.1 1.4 2.55833 1.6 2.125 2C1.69167 2.4 1.475 2.9 1.475 3.5C1.475 4.1 1.69167 4.6 2.125 5C2.55833 5.4 3.1 5.6 3.75 5.6H6.025C6.16583 5.6 6.28229 5.6425 6.37437 5.7275C6.46646 5.8125 6.5125 5.92 6.5125 6.05C6.5125 6.18 6.46646 6.2875 6.37437 6.3725C6.28229 6.4575 6.16583 6.5 6.025 6.5H3.75ZM4.96875 3.95C4.82792 3.95 4.71146 3.9075 4.61937 3.8225C4.52729 3.7375 4.48125 3.63 4.48125 3.5C4.48125 3.37 4.52729 3.2625 4.61937 3.1775C4.71146 3.0925 4.82792 3.05 4.96875 3.05H9.03125C9.17208 3.05 9.28854 3.0925 9.38062 3.1775C9.47271 3.2625 9.51875 3.37 9.51875 3.5C9.51875 3.63 9.47271 3.7375 9.38062 3.8225C9.28854 3.9075 9.17208 3.95 9.03125 3.95H4.96875ZM7.975 6.5C7.83417 6.5 7.71771 6.4575 7.62562 6.3725C7.53354 6.2875 7.4875 6.18 7.4875 6.05C7.4875 5.92 7.53354 5.8125 7.62562 5.7275C7.71771 5.6425 7.83417 5.6 7.975 5.6H10.25C10.9 5.6 11.4417 5.4 11.875 5C12.3083 4.6 12.525 4.1 12.525 3.5C12.525 2.9 12.3083 2.4 11.875 2C11.4417 1.6 10.9 1.4 10.25 1.4H7.975C7.83417 1.4 7.71771 1.3575 7.62562 1.2725C7.53354 1.1875 7.4875 1.08 7.4875 0.95C7.4875 0.82 7.53354 0.7125 7.62562 0.6275C7.71771 0.5425 7.83417 0.5 7.975 0.5H10.25C11.1708 0.5 11.9427 0.7875 12.5656 1.3625C13.1885 1.9375 13.5 2.65 13.5 3.5C13.5 4.35 13.1885 5.0625 12.5656 5.6375C11.9427 6.2125 11.1708 6.5 10.25 6.5H7.975Z" fill="white" />
            </svg>


        </SvgIcon>
    );
}

export default AttachIcon;
