import React from "react";
import { I18n } from "react-redux-i18n";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import slugify from "slugify";
import MTextCounter from "../../../components/MTextCounter";
import { ePageType, IPage, IWebsite, IWebsiteConfigurationsProps } from "../../../reducers/constants/objectTypes";
import MTextArea from "../../../components/MTextArea";
import MetaAssistantTextArea from "../../../editor/elements/aiAssitant/MetaAssistantTextArea";

const useStyles = makeStyles((theme: Theme) => ({
  labelCounterWrap: {
    minWidth: 80,
  },
  label: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  counter: {},
  textField: {
    width: "100%",
    resize: "vertical",
    minHeight: 39,
    padding: "10px",
    marginLeft: 10,
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    "&:focus": {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
  },
  row: {
    display: "flex",
    minHeight: 37,
    alignItems: "center",
    margin: "0px",
  },
  wrapper: {
    height: "auto",
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    padding: "20px 0",
    gap: 5,
  },
}));

type PropTypes = {
  page: IPage;
  selectedWebsite?: IWebsite;
  websiteConfigurationsProps: IWebsiteConfigurationsProps;
  handleMetaTitleChanged?: (value: string) => void;
  handleMetaDescChanged: (value: string) => void;
  handleSlugChanged: (value: string) => void;
};

const PageEditorSEO = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    page,
    websiteConfigurationsProps,
    handleMetaTitleChanged,
    handleMetaDescChanged,
    handleSlugChanged,
    selectedWebsite,
  } = props;

  const getPostPagePropsByType = (type: string, config: IWebsiteConfigurationsProps) => {
    switch (type) {
      case ePageType.Blog:
        return config.blog.postPage;
      case ePageType.Targeted:
        return config.learn.postPage;
      case ePageType.ComparisonTop10:
        return config.comparisonTop10.postPage;
      case ePageType.ComparisonTopAlternatives:
        return config.comparisonTopAlternative.postPage;
      case ePageType.ProductPage:
        return config.productPage.postPage;
      case ePageType.ProductComparison:
        return config.productComparison.postPage;
      case ePageType.ProductReview:
        return config.productReview.postPage;
      case ePageType.ProductAlternative:
        return config.productAlternative.postPage;
      default:
        return config.dynamicSections?.find((section) => section.type === type)?.postPage;
    }
  };

  const getMetaTitle = (template: string, data: { tag: string; value: string }[]) =>
    data.reduce((f, s) => `${f}`.replace(RegExp(`%%${s.tag}%%`, "gi"), s.value), template);

  const defaultMetaTitle = getMetaTitle(getPostPagePropsByType(page.type, websiteConfigurationsProps)?.metaTitle, [
    {
      tag: "Post Title",
      value: page.title,
    },
    {
      tag: "Year",
      value: moment().format("YYYY"),
    },
  ]);

  React.useEffect(() => {
    if (!page.metaTitle) {
      handleMetaTitleChanged(defaultMetaTitle);
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        <div className={classes.labelCounterWrap}>
          <div className={classes.label}>{I18n.t("custom_post.slug")}</div>
          <div className={classes.counter}></div>
        </div>
        <MTextArea
          className={classes.textField}
          maxRows={1}
          onChange={(value) => handleSlugChanged(value)}
          text={
            page.slug ||
            slugify(page.title || "", {
              locale: "en",
              strict: true,
              lower: true,
              trim: false,
              remove: /[*+~.()'"!:@?]/g,
            })
          }
          placeholder={I18n.t("targeted_posts.post.meta_description_ph")}
        />
      </div>
      <MetaAssistantTextArea
        fontSize={14}
        label={I18n.t("custom_post.meta_title")}
        desiredCount={50}
        field={"title"}
        page={page}
        selectedWebsite={selectedWebsite}
        onChange={(value) => handleMetaTitleChanged(value)}
        text={page.metaTitle || defaultMetaTitle || ""}
        placeholder={I18n.t("custom_post.meta_title_ph")}
      />
      <MetaAssistantTextArea
        fontSize={14}
        label={I18n.t("custom_post.meta_description")}
        desiredCount={160}
        field={"description"}
        page={page}
        selectedWebsite={selectedWebsite}
        onChange={(value) => handleMetaDescChanged(value)}
        text={page.metaDescription || ""}
        placeholder={I18n.t("targeted_posts.post.meta_description_ph")}
      />
    </div>
  );
};

export default PageEditorSEO;
