import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { I18n } from "react-redux-i18n";
import { Editor, Transforms } from "slate";
import { useSlate, useSlateStatic } from "slate-react";
import { useSnackbar } from "notistack";
import Tooltip from "@material-ui/core/Tooltip";
import { Selection } from "slate/dist/interfaces/editor";
import { HelpOutline } from "@material-ui/icons";
import MTextButton from "../../../../../components/MTextButton";
import { copyStringToClipboard } from "../../../../../helpers/fbScraperHelper";
import MarkDownPreview from "../../../../../editor/markDown/MarkDownPreview";
import { insertTextBox } from "../../../../../editor/elements/textBox/TextBoxElement";
import richTextEditorHelper from "../../../../../editor/helper/richTextEditorHelper";
import slateMarkdownHelper from "../../../../../helpers/slateMarkdownHelper";
import { emitEvent } from "../../../../../hooks/useEvents";

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    padding: 0,
  },
  selectedTextWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 7,
    marginBottom: 20,
  },
  selectedTextTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.secondary,
  },
  selectedText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.primary,
  },
  divider: {
    backgroundColor: theme.palette.divider,
  },
  chatsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  chatWrapper: {
    display: "flex",
    flexDirection: "column",
  },

  chat: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 15,
    gap: 15,
    alignItems: "start",
  },
  chatAssistantWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    flex: 1,
  },
  chatLoaderWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  LoaderDotWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  aiChat: {
    marginBottom: 20,
  },
  aiChatLoader: {
    marginBottom: 20,
    alignItems: "center",
  },
  userProfile: {
    width: 18,
    height: 18,
    padding: "2px 3px",
    backgroundColor: "#00BF8F",
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightLight as any,
    display: "flex",
  },
  aiProfile: {
    backgroundColor: "#0298C8",
  },
  chatText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight as any,
    flex: 1,
    color: theme.palette.text.primary,
    lineHeight: 1.25,
  },
  stopText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight as any,
    flex: 1,
    color: theme.palette.primary.main,
    lineHeight: 1.25,
  },
  chatError: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight as any,
    flex: 1,
    color: theme.palette.error.main,
    lineHeight: 1.25,
  },
  stopWrapper: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    gap: 5,
    marginLeft: 15,
  },
  icon: {
    fill: "#fff",
    fontSize: 20,
    cursor: "pointer",
  },
  chatActions: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    color: theme.palette.text.primary,
    alignItems: "center",
  },
  actionText: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  chatAction: {
    cursor: "pointer",
    color: "#609EFF",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
  },

  insertWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 3,
  },
  actionWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 3,
  },
  disabledChatAction: {
    "&:hover": {
      color: theme.palette.text.disabled,
      textDecoration: "none",
    },
  },
  insertButton: {
    padding: 0,
    paddingRight: 5,
    minHeight: 0,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    minWidth: 0,
    color: "#609EFF",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  loaderDot: {
    float: "left",
    width: 8,
    height: 8,
    margin: "0 4px",
    background: theme.palette.primary.main,
    borderRadius: "50%",
    opacity: 0,
    animation: "$loadingFade 1s infinite",
    "&:nth-child(1)": {
      animationDelay: "0s",
    },
    "&:nth-child(2)": {
      animationDelay: "0.2s",
    },
    "&:nth-child(3)": {
      animationDelay: "0.4s",
    },
  },
  "@keyframes loadingFade": {
    "0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 0.8,
    },
    "100%": {
      opacity: 0,
    },
  },
  markDownText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight as any,
    flex: 1,
    color: theme.palette.text.primary,
    lineHeight: 1.25,
  },
  toolTip: {
    backgroundColor: "#5F5F5F",
    color: "#fff",
  },
}));

type PropTypes = {
  chats: any[];
  chatLoader?: any;
  onRetry: Function;
  selectedText: { text: string; selection: Selection };
  onStop: Function;
};
const insertActions = ["insert_in_place", "insert_above", "insert_below", "insert_in_box"];

const AssistantChats = (props: PropTypes) => {
  const classes = useStyles(props);

  const editor = useSlateStatic();
  const { chats, chatLoader, onRetry, selectedText, onStop } = props;
  const { enqueueSnackbar } = useSnackbar();
  const handeInsertAction = (action, chat) => {
    const nextPoint = Editor.after(editor, editor.selection) || [0];
    const previousPoint = Editor.before(editor, editor.selection) || [0];
    switch (action) {
      case "insert_above":
        Transforms.insertNodes(editor, chat.nodes, {
          at: previousPoint?.path ? [previousPoint.path[0] + 1] : [0],
          select: true,
        });
        break;
      case "insert_below":
        Transforms.insertNodes(editor, chat.nodes, {
          at: nextPoint,
          select: true,
        });
        break;
      case "insert_in_box":
        insertTextBox(editor, previousPoint?.path ? [previousPoint.path[0] + 1] : [0], chat.nodes, false);
        break;
      case "insert_in_place":
        Transforms.insertNodes(editor, chat.nodes, {
          at: editor.selection,
          select: true,
        });
        break;
      default:
        break;
    }
  };
  const constructInitials = (name) => (name?.[0] || "a").toUpperCase();

  return (
    <List component={"div"} className={classes.list}>
      <div className={classes.selectedTextWrapper}>
        <Typography variant={"body1"} className={classes.selectedTextTitle}>
          {I18n.t("ai_assistant.selected_text")}
        </Typography>
        <Typography className={classes.selectedText}>{selectedText.text}</Typography>
      </div>
      <div className={classes.chatsWrapper}>
        {chats.map((chat, index) => (
          <div key={`ai_chat_${index + 1}`} className={classes.chatWrapper}>
            <div className={classes.chat}>
              <div className={classes.userProfile}>{constructInitials(chat.userName)}</div>
              <Typography className={classes.chatText}>{chat.question}</Typography>
              <Tooltip
                // enterDelay={2000}
                classes={{
                  tooltip: classes.toolTip,
                }}
                title={
                  <div>
                    <Typography>{`"system message": ${chat.question}`}</Typography>
                    <Typography>{`"user message": ${chat.userMessage}`}</Typography>
                  </div>
                }
                key={chat.question}
              >
                <HelpOutline className={classes.icon} />
              </Tooltip>
            </div>

            <div className={ClassNames(classes.chat, classes.aiChat)}>
              <div className={ClassNames(classes.userProfile, classes.aiProfile)}>{"A"}</div>
              <div className={classes.chatAssistantWrapper}>
                <MarkDownPreview markdown={chat.answer} customClass={classes.markDownText} />
                <div className={classes.chatActions}>
                  <MTextButton
                    customClass={classes.chatAction}
                    text={I18n.t("ai_assistant.actions.copy")}
                    onClick={() => {
                      // TODO: get text not markdown
                      copyStringToClipboard(chat.answer, () =>
                        enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"), {
                          variant: "success",
                        })
                      );
                    }}
                  />
                  <div className={classes.insertWrapper}>
                    <Typography className={classes.actionText}>{I18n.t("ai_assistant.actions.insert")}</Typography>
                    {insertActions.map((act, i) => (
                      <div key={act} className={classes.actionWrapper}>
                        <Tooltip
                          enterDelay={2000}
                          classes={{
                            tooltip: classes.toolTip,
                          }}
                          key={`${act}_${i + 1}`}
                          title={
                            richTextEditorHelper.isSelected(editor, act === "insert_in_place")
                              ? ""
                              : I18n.t(
                                  `rich_text_editor.tooltips.${
                                    act === "insert_in_place" ? "select_text" : "cursor_focus"
                                  }`
                                )
                          }
                        >
                          <MTextButton
                            customClass={ClassNames(
                              classes.chatAction,
                              !richTextEditorHelper.isSelected(editor, act === "insert_in_place") &&
                                classes.disabledChatAction
                            )}
                            text={I18n.t(`ai_assistant.actions.${act}`)}
                            onClick={() => {
                              if (richTextEditorHelper.isSelected(editor, act === "insert_in_place")) {
                                handeInsertAction(act, chat);
                              }
                            }}
                          />
                        </Tooltip>
                        {i !== insertActions.length - 1 && <Typography className={classes.actionText}>/</Typography>}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {(index !== chats.length - 1 || chatLoader) && (
              <Divider className={classes.divider} orientation="horizontal" />
            )}
          </div>
        ))}
        {chatLoader && (
          <div className={classes.chatWrapper}>
            <div className={classes.chat}>
              <div className={classes.userProfile}>{constructInitials(chatLoader.userName)}</div>
              <Typography className={classes.chatText}>{chatLoader.question}</Typography>
            </div>

            <div
              className={ClassNames(classes.chat, classes.aiChatLoader)}
              style={{ alignItems: chatLoader.error ? "start" : "center" }}
            >
              <div className={ClassNames(classes.userProfile, classes.aiProfile)}>{"A"}</div>
              {!chatLoader.error && (
                <div className={classes.chatLoaderWrapper}>
                  <div className={classes.LoaderDotWrapper}>
                    <div className={classes.loaderDot} />
                    <div className={classes.loaderDot} />
                    <div className={classes.loaderDot} />
                  </div>
                  <div className={classes.stopWrapper} onClick={() => onStop()}>
                    <Typography className={classes.stopText}>{I18n.t("ai_assistant.stop_generating")}</Typography>
                  </div>
                </div>
              )}
              {chatLoader.error && (
                <div className={classes.chatAssistantWrapper}>
                  <Typography className={classes.chatError}>{chatLoader.error}</Typography>
                  <div className={classes.chatActions}>
                    <MTextButton
                      customClass={classes.chatAction}
                      text={I18n.t("ai_assistant.actions.retry")}
                      onClick={() => onRetry(chatLoader.question)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </List>
  );
};

export default AssistantChats;
