import React, { useEffect, useMemo, useState } from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { I18n } from "react-redux-i18n";
import MButton from "../../../../components/MButton";
import MTextArea from "../../../../components/MTextArea";
import ArrowLeftIcon from "../../../../icons/ArrowLeftIcon";

const useStyles = makeStyles((theme: Theme) => ({
  dialogWrapper: {
    padding: "30px",
  },
  titleText: {
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  titleWrapper: {
    display: "flex",
    columnGap: "5px",
    alignItems: "center",
  },
  contentWrapper: {
    padding: 0,
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },
  inputText: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: "14px",
    marginBottom: "10px",
  },
  icon: {
    height: "20px",
    width: "20px",
  },
  textAreaInput: {
    border: `0.5px solid ${theme.palette.divider}`,
    padding: "15px",
  },
  saveBtn: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "end",
  },
  btnStyle: {
    borderRadius: "25px",
  },
}));

type PropTypes = {
  title: string;
  notes: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  type: string;
  save: (notesValue: string) => void;
  setNotes: (notes: string) => void;
};

const NotesDialog = (props: PropTypes) => {
  const classes = useStyles();

  const { notes, open, setOpen, title, type, save, setNotes } = props;

  const [notesValue, setNotesValue] = useState(notes);

  useEffect(() => {
    setNotesValue(notes);
  }, [notes]);

  return (
    <Dialog open={open} PaperProps={{ className: classes.dialogWrapper }} onClose={() => setOpen(false)} fullWidth>
      <DialogContent className={classes.contentWrapper}>
        <div className={classes.titleWrapper}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <ArrowLeftIcon className={classes.icon} />
          </IconButton>
          <Typography className={classes.titleText}>
            {type}: {title}
          </Typography>
        </div>
        <div className={classes.inputWrapper}>
          <Typography className={classes.inputText}>
            {I18n.t("strategy.outlinePages.dialog_outlines.notes_dialog.add_notes")}
          </Typography>

          <MTextArea
            text={notesValue}
            onChange={(val) => {
              setNotesValue(val);
            }}
            minRows={5}
            placeholder={I18n.t("strategy.outlinePages.dialog_outlines.notes_dialog.placeholder")}
            className={classes.textAreaInput}
          />
        </div>
        <div className={classes.saveBtn}>
          <MButton
            onClick={() => {
              save(notesValue);

              setNotesValue("");
            }}
            customClassNames={classes.btnStyle}
          >
            {I18n.t("strategy.outlinePages.dialog_outlines.notes_dialog.save_btn")}
          </MButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default NotesDialog;
