import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { I18n } from "react-redux-i18n";
import { nanoid } from "nanoid";

import { IWebsite } from "../../../../reducers/constants/objectTypes";
import { Widget } from "../WithWidgets";
import richTextEditorHelper from "../../../helper/richTextEditorHelper";
// eslint-disable-next-line import/no-cycle
import SNReadOnlyEditor from "../../../ReadOnlyEditor";
import useFaqWidgetElement from "./useFAQWidgetElement";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    padding: 0,
    borderRadius: 8,
    paddingBottom: 16,
  },
  questionAndAnswerRow: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  questionAndAnswer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    borderBottom: (props: PropTypes) => `solid  1px ${props.website.configurations.theme.palette.divider}`,
  },
  questionWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "16px 0px",
  },

  faqTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: "24px",
    borderBottom: (props: PropTypes) => `solid 1px ${props.website.configurations.theme.palette.divider}`,
    padding: "16px 0px",
  },
  questionText: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightLight as any,
    lineHeight: "24px",
  },
  answerText: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: "-8px",
    paddingBottom: 16,
    marginRight: 60,
  },
  icon: {
    cursor: "pointer",
    fill: (props: PropTypes) => props.website.configurations.theme.palette.text.primary,
    marginRight: 16,
  },
  hidden: {
    visibility: "hidden",
  },
  editable: () => ({
    padding: "0 !important",
  }),
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  website: IWebsite;

  readMode?: boolean;
};

const FAQWidgetElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, website, readMode } = props;

  const [expandedItems, setExpandedItems] = React.useState(new Map());

  const onExpand = (index: number) => {
    const newExpandedItems = new Map(expandedItems);
    newExpandedItems.set(index, !expandedItems.get(index));
    setExpandedItems(newExpandedItems);
  };

  useFaqWidgetElement({ element });

  const { data } = element;

  return (
    <Widget title={I18n.t("rich_text_editor.custom_components.faq")} element={element} editable readMode={readMode}>
      {data?.formatted && (readMode ? data.list.length > 0 : true) && (
        <div className={classes.wrap} {...attributes}>
          <Typography className={classes.faqTitle}>
            {data.title || (!readMode && I18n.t("rich_text_editor.faq.faq_title_ph"))}
          </Typography>
          {data.list.map((faq, faqIndex) => (
            <div className={classes.questionAndAnswerRow} key={`faq_${faqIndex}`}>
              <div onClick={() => onExpand(faqIndex)} className={classes.questionAndAnswer}>
                <div className={classes.questionWrapper}>
                  <Typography className={classes.questionText}>
                    {faq.question || (!readMode && I18n.t("rich_text_editor.faq.question_ph"))}
                  </Typography>
                  {expandedItems.get(faqIndex) ? (
                    <ExpandLessIcon className={classes.icon} />
                  ) : (
                    <ExpandMoreIcon className={classes.icon} />
                  )}
                </div>
                {expandedItems.get(faqIndex) && (
                  <div className={classes.answerText}>
                    <SNReadOnlyEditor
                      value={faq.answer || richTextEditorHelper.getEmptyRichText()}
                      website={website}
                      customEditableClass={classes.editable}
                      key={nanoid(10)}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {children}
    </Widget>
  );
};

export default FAQWidgetElement;
