import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles(() => ({
  icon: {
    width: 24,
    height: 22,
    color: "#fff",
  },
}));

type PropTypes = {
  className?: string;
  onClick?: () => void;
};

const DeleteIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className, onClick } = props;

  return (
    <SvgIcon onClick={onClick} className={ClassNames(classes.icon, className)} viewBox={"0 0 18 20"}>
      <path d="M3.89189 19.562C3.43495 19.562 3.03761 19.3848 2.69987 19.0303C2.36213 18.6759 2.19326 18.2484 2.19326 17.748V3.90069H1.65685C1.45818 3.90069 1.29428 3.83293 1.16514 3.6974C1.036 3.56187 0.971436 3.38986 0.971436 3.18135C0.971436 2.97285 1.036 2.80604 1.16514 2.68094C1.29428 2.55584 1.45818 2.49329 1.65685 2.49329H6.06734L6.66685 1.15356C6.82789 0.793676 7.18536 0.562012 7.57963 0.562012H11.1918C11.5861 0.562012 11.9436 0.793676 12.1046 1.15356L12.7041 2.49329H17.1146C17.3133 2.49329 17.4772 2.56105 17.6063 2.69658C17.7354 2.83211 17.8 3.00412 17.8 3.21263C17.8 3.40028 17.7354 3.56187 17.6063 3.6974C17.4772 3.83293 17.3133 3.90069 17.1146 3.90069H16.5782V17.748C16.5782 18.2484 16.4093 18.6759 16.0716 19.0303C15.7338 19.3848 15.3365 19.562 14.8796 19.562H3.89189ZM3.53429 3.90069V17.748C3.53429 17.8731 3.56905 17.9722 3.63859 18.0451C3.70812 18.1181 3.79256 18.1546 3.89189 18.1546H14.8796C14.9789 18.1546 15.0633 18.1181 15.1329 18.0451C15.2024 17.9722 15.2372 17.8731 15.2372 17.748V3.90069H3.53429ZM6.96136 14.7768C6.96136 14.9853 7.02592 15.1573 7.15506 15.2929C7.2842 15.4284 7.4481 15.4962 7.64677 15.4962C7.84544 15.4962 8.00934 15.4284 8.13848 15.2929C8.26762 15.1573 8.33218 14.9853 8.33218 14.7768V7.21592C8.33218 7.02827 8.26265 6.86668 8.12358 6.73115C7.98451 6.59562 7.82557 6.52786 7.64677 6.52786C7.4481 6.52786 7.2842 6.59562 7.15506 6.73115C7.02592 6.86668 6.96136 7.02827 6.96136 7.21592V14.7768ZM10.4393 14.7768C10.4393 14.9853 10.5088 15.1573 10.6479 15.2929C10.7869 15.4284 10.9459 15.4962 11.1247 15.4962C11.3233 15.4962 11.4873 15.4284 11.6164 15.2929C11.7455 15.1573 11.8101 14.9853 11.8101 14.7768V7.21592C11.8101 7.02827 11.7455 6.86668 11.6164 6.73115C11.4873 6.59562 11.3233 6.52786 11.1247 6.52786C10.926 6.52786 10.7621 6.59562 10.633 6.73115C10.5038 6.86668 10.4393 7.02827 10.4393 7.21592V14.7768ZM3.53429 3.90069V17.748C3.53429 17.8731 3.56905 17.9722 3.63859 18.0451C3.70812 18.1181 3.79256 18.1546 3.89189 18.1546H3.53429V3.90069Z" />
    </SvgIcon>
  );
};

export default DeleteIcon;
