import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import numeral from "numeral";
import { I18n } from "react-redux-i18n";
import LinksSplitTestMoreOptionsButton from "./LinksSplitTestMoreOptionsButton";
import LinksSplitTestUrlActions from "./LinksSplitTestUrlActions";
import { ILinksSplitTest } from "../../../../../reducers/constants/objectTypes";
import MTableRow from "../../../../../components/Table/Rows/MTableRow";
import MTableCell from "../../../../../components/Table/Cells/MTableCell";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";
import MTableCellNumber from "../../../../../components/Table/Cells/MTableCellNumber";
import MTableCellDate from "../../../../../components/Table/Cells/MTableCellDate";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    display: "flex",
    padding: "0 20px",
    fontSize: theme.typography.pxToRem(12),
    height: 53,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      transition: "border-radius 500ms",
      marginTop: -1,
      marginBottom: 1,
      paddingTop: 1,
      "& $topicWrapper": {
        paddingTop: 1,
        borderBottom: "1px solid transparent",
      },
      "& $actions": {
        display: "flex",
      },
      "& $takenByText": {
        display: "none",
      },
      "& $takeOverButton": {
        display: "flex",
      },
    },
  },
  topicWrapper: {
    width: "100%",
    height: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 5,
    cursor: "pointer",
  },
  topicNameWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 20,
  },
  urlWrapper: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  topicName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
    cursor: "pointer",
    width: "fit-content",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  collapse: {},
  iconColumn: {
    // padding: '19px 14px 16px 16px',
    paddingLeft: 10,
    // borderBottom: '1px solid #fff',
    marginBottom: -1,
    width: 55,
  },
  hiddenColumn: {
    width: 20,
  },
  wideColumn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    width: "20%",
  },
  column: {
    flexGrow: 1,
    flexBasis: 0,
  },
  hoverableColumn: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  moreOptionsColumn: {
    width: 30,
  },
  actions: {},
  tooltip: {
    whiteSpace: "pre-wrap",
  },
  ruleAction: {},
  countriesColumn: {
    flexGrow: 1,
    flexBasis: 0,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  countries: {
    fontSize: theme.typography.pxToRem(12),
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginRight: 10,
  },
}));

type PropTypes = {
  test: ILinksSplitTest;

  handleTestClick: () => void;
  handleDeleteClick: () => void;
};

const LinksSplitTestItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { test, handleDeleteClick, handleTestClick } = props;

  const countries = React.useMemo(() => {
    if (test.countriesSelector === "AllCountries") {
      return I18n.t("links_split_test.table.all");
    }
    if (test.countriesSelector === "IncludedCountries") {
      return I18n.t("links_split_test.table.included_countries", { countries: test.countries?.join(", ") });
    }
    return I18n.t("links_split_test.table.excluded_countries", { countries: test.countries?.join(", ") });
  }, [test]);
  return (
    <MTableRow handleRowClick={() => handleTestClick()} fullBorderWidth>
      <MTableCellText text={test.name} />
      <MTableCell columnWidth={"20%"}>{test.description}</MTableCell>
      <MTableCellText text={countries} />
      <MTableCellText
        text={(() => {
          if (test.allProducts) {
            return I18n.t("links_split_test.table.all");
          }
          if (test.products.length > 1) {
            return I18n.t("links_split_test.table.product_count", { count: test.products.length });
          }
          return test.productNames[0];
        })()}
      />
      <MTableCellText text={test.state} />
      <MTableCellNumber value={test.groups.length} />
      <MTableCellNumber value={test.analytics?.clicks} />
      <MTableCellDate date={test.analytics?.lastClickDate} />
      <MTableCellDate date={test.publishedDate} />
      <div className={classes.moreOptionsColumn}>
        <LinksSplitTestMoreOptionsButton handleDeleteClick={handleDeleteClick} />
      </div>
    </MTableRow>
  );
};

export default LinksSplitTestItem;
