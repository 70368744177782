import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import BoldIcon from "../toolbar/icons/BoldIcon";
import ItalicIcon from "../toolbar/icons/ItalicIcon";
import UnderlineIcon from "../toolbar/icons/UnderlineIcon";
import NumberedListIcon from "../toolbar/icons/NumberedListIcon";
import BulletedListIcon from "../toolbar/icons/BulletedListIcon";
import MarkButton from "../toolbar/components/MarkButton";
import BlockButton from "../toolbar/components/BlockButton";
import LinkButton from "../link/LinkButton";
import SmallTitleIcon from "../toolbar/icons/SmallTitleIcon";
import BigTitleIcon from "../toolbar/icons/BigTitleIcon";
import { editorType } from "../../types/editor.Types";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  toolbarWrapper: {
    zIndex: 3,
    position: "sticky",
    top: 0,
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
    backgroundColor: palette.secondary.dark,
  },
  toolbarDivider: {
    height: 25,
  },
}));

type PropTypes = {
  toolbarCustomClass?: string;
  elements?: editorType[];
};

function LiteEditorToolbar(props: PropTypes) {
  const classes = useStyles(props);
  const { toolbarCustomClass, elements = [editorType.numberedList, editorType.bulletedList] } = props;

  return (
    <div className={ClassNames(classes.toolbarWrapper, toolbarCustomClass)}>
      <MarkButton format="bold" Icon={BoldIcon} tooltip={I18n.t("rich_text_editor.tooltips.bold")} />
      <MarkButton format="italic" Icon={ItalicIcon} tooltip={I18n.t("rich_text_editor.tooltips.italic")} />
      <MarkButton format="underline" Icon={UnderlineIcon} tooltip={I18n.t("rich_text_editor.tooltips.underline")} />
      <LinkButton />
      {elements.includes(editorType.numberedList) && (
        <BlockButton
          format="numbered-list"
          Icon={NumberedListIcon}
          tooltip={I18n.t("rich_text_editor.tooltips.ordered_list")}
        />
      )}
      {elements.includes(editorType.bulletedList) && (
        <BlockButton
          format="bulleted-list"
          Icon={BulletedListIcon}
          tooltip={I18n.t("rich_text_editor.tooltips.bulleted_list")}
        />
      )}
      {elements.includes(editorType.headingOne) && (
        <BlockButton format="heading-two" Icon={SmallTitleIcon} tooltip={I18n.t("rich_text_editor.tooltips.h3")} />
      )}
      {elements.includes(editorType.headingTwo) && (
        <BlockButton format="heading-one" Icon={BigTitleIcon} tooltip={I18n.t("rich_text_editor.tooltips.h2")} />
      )}
    </div>
  );
}

export default LiteEditorToolbar;
