import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "../../../icons/SearchIcon";
import { eActionIcon } from "../../../reducers/constants/objectTypes";
import OpenInNewIcon from "../../../icons/OpenInNewIcon";
import CopyToClipboardIcon from "../../../icons/CopyToClipboardIcon";
import CopyNameIcon from "../../../icons/CopyNameIcon";
import AnalyticsIcon from "../../../icons/AnalyticsIcon";
import TrashCanIcon from "../../../icons/TrashCanIcon";
import OpenInEditor from "../../../icons/OpenInEditor";
import NoteIcon from "../../../icons/NoteIcon";
import InfoIcon from "../../../icons/InfoIcon";
import PhotoIcon from "@material-ui/icons/Photo";
const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    flexDirection: "row",
    alignItems: "center",
    display: "none",
    paddingRight: 10,
    gap: 13,
  },
  button: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 13,
    height: 13,
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  actionsObject?: any;
  actions?: {
    text: string;
    tooltip: string;
    onClick: Function;
    icon?: React.ReactNode;
    iconName?: string;
    tooltipDelay?: number;
    tooltipClasses?: any;
  }[];
  customClass?: string;
  className?: string;
};

const MTableCellActions = (props: PropTypes) => {
  const classes = useStyles(props);
  const { actionsObject, actions = [], customClass = undefined, className } = props;

  const getIcon = (action: any) => {
    switch (action.iconName) {
      case eActionIcon.search:
        return <SearchIcon className={classes.icon} />;
      case eActionIcon.openInNew:
        return <OpenInNewIcon className={classes.icon} />;
      case eActionIcon.copyToClipboard:
        return <CopyToClipboardIcon className={classes.icon} />;
      case eActionIcon.copyName:
        return <CopyNameIcon className={classes.icon} />;
      case eActionIcon.analytics:
        return <AnalyticsIcon className={classes.icon} />;
      case eActionIcon.trashCan:
        return <TrashCanIcon className={classes.icon} />;
      case eActionIcon.openInEditor:
        return <OpenInEditor className={classes.icon} />;
      case eActionIcon.note:
        return <NoteIcon className={classes.icon} />;
      case eActionIcon.info:
        return <InfoIcon className={classes.icon} />;
      case eActionIcon.photo:
        return <PhotoIcon className={classes.icon} />;
      default:
        return action.icon;
    }
  };

  return (
    <div className={ClassNames("row-actions-wrapper", classes.actions, className, customClass)}>
      {actions.map((action, i) => (
        <Tooltip
          enterDelay={action.tooltipDelay || 2000}
          title={action.tooltip}
          key={`cell_action${i + 1}`}
          classes={action?.tooltipClasses || {}}
        >
          <IconButton
            disableRipple
            className={classes.button}
            onClick={(event) => {
              event.stopPropagation();
              action.onClick(event, actionsObject);
            }}
          >
            {getIcon(action)}
          </IconButton>
        </Tooltip>
      ))}
    </div>
  );
};

export default MTableCellActions;
