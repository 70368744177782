import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import CfCheckBox from "../../../../../components/CfCheckBox";

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    borderRight: `solid 1px ${theme.palette.divider}`,
    paddingTop: 20,
    "&:hover": {
      backgroundColor: "#FFFBE8",
    },
  },
  date: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    marginBottom: 20,
    textTransform: "capitalize",
  },
  titles: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
    marginBottom: 20,
    visibility: "hidden",
  },
  range: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  rootCheckBox: {
    padding: 0,
  },
}));

type PropTypes = {
  hiddenAreas: any;
  handleHiddenAreasChange: Function;
};

const colors = ["#fafaff", "#F0F1FF", "#C9CCF5", "#A4AAEB", "#828AE0", "#636DD6"];

const SinglePageTableHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { hiddenAreas, handleHiddenAreasChange } = props;

  return (
    <div className={classes.headerWrapper}>
      <Typography variant={"subtitle2"} className={classes.date}>
        {"dataSet"}
      </Typography>
      <Grid container>
        <Grid item container className={classes.titles}>
          <Grid item xs={6}>
            {"Pages"}
          </Grid>
          <Grid item xs={6}>
            {"Change"}
          </Grid>
        </Grid>
        <Grid item container className={classes.range}>
          <Grid item xs={6}>
            <CfCheckBox rootClassName={classes.rootCheckBox} checked iconColor={"#131955"} value={"total"} />
          </Grid>
          <Grid item xs={6}>
            {"Total"}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SinglePageTableHeader;
