import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { EDIT_TOPIC } from "../../../../reducers/constants/actionTypes";
import { ePostType, ITargetedPost, IWebsite } from "../../../../reducers/constants/objectTypes";
import MIFrame from "../../../../components/MIFrame";
import EditTopicInfoTab from "./infoTab/EditTopicInfoTab";
import EditTopicResourcesTab from "./resourceTab/EditTopicResourcesTab";
import { AbilityContext } from "../../../../casl/can";

const useStyles = makeStyles((theme: Theme) => ({
  resources: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
  },
  tabsWrapper: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  tab: {
    height: 55,
    textTransform: "capitalize",
  },
  contentWrapper: {
    height: "calc(100vh - 55px)",
    overflowY: "auto",
  },
  resourcesTab: {},
  richText: {
    fontSize: theme.typography.pxToRem(16),
    height: "calc(100vh - 100px)",
    overflowY: "scroll",
  },
  iframe: {
    height: "calc(100vh - 100px)",
    width: "100%",
  },
  iframeHidden: {
    display: "none",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedTab: number;
  topicLoaded: boolean;
  topic: ITargetedPost;

  handleTopicChanged: Function;
  handleTabSelected: Function;
};

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,

    selectedTab: state.editTopic.selectedTab,
    topicLoaded: state.editTopic.topicLoaded,
    topic: state.editTopic.topic,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleTopicChanged: (topic) => dispatch({ type: EDIT_TOPIC.TOPIC_CHANGED, topic }),
  handleTabSelected: (tab) => dispatch({ type: EDIT_TOPIC.TAB_CHANGED, tab }),
});

const EditTopicResources = (props: PropTypes) => {
  const classes = useStyles(props);
  const { topicLoaded, topic, selectedTab, selectedWebsite } = props;
  const { handleTopicChanged, handleTabSelected } = props;
  const ability = React.useContext<any>(AbilityContext);

  if (!ability.can("view_resources", `${ePostType.LEARNING_CENTER}_topic`)) {
    return <div className={classes.resources} />;
  }

  return (
    <div className={classes.resources}>
      <div className={classes.tabsWrapper}>
        <Tabs
          value={selectedTab}
          onChange={(e, tab) => handleTabSelected(tab)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab className={classes.tab} label={I18n.t("review_answer.tabs.info")} />
          <Tab className={classes.tab} label={I18n.t("review_answer.tabs.resources")} />
          <Tab className={classes.tab} label={I18n.t("review_answer.tabs.google_search")} />
        </Tabs>
      </div>
      <div className={classes.contentWrapper}>
        {selectedTab === 0 && topicLoaded && <EditTopicInfoTab />}
        {selectedTab === 1 && topicLoaded && (
          <EditTopicResourcesTab
            topicId={topic._id}
            selectedWebsite={selectedWebsite}
            richText={topic.resourceRichText}
            text={topic.resourceText}
            handleTextChanged={(richText, text) =>
              handleTopicChanged({ ...topic, resourceRichText: richText, resourceText: text })
            }
          />
        )}
        <MIFrame
          iframeClass={ClassNames(classes.iframe, selectedTab !== 2 && classes.iframeHidden)}
          src={"https://www.google.com/search?igu=1"}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTopicResources);
