import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { I18n } from "react-redux-i18n";
import PageEditorElements from "../../../../PageEditor/editorActions/PageEditorElements";
import { useEvent } from "../../../../../hooks/useEvents";
import PageEditorElementStyleWrapper from "../../../../PageEditor/editorActions/designComponents/PageEditorElementStyleWrapper";

const useStyles = makeStyles((theme: Theme) => ({
  resourcesTab: {
    height: "100%",
    flex: 1,
    padding: "0 20px",
    overflowY: "auto",
  },
  tabs: {
    display: "block",
    top: 0,
    zIndex: 1,
    marginBottom: 20,
    "& .MuiTabs-centered": {
      display: "block",
    },
    "& .MuiTab-root": {
      paddingBottom: 0,
    },
    "& .MuiTab-wrapper": {
      marginBottom: -10,
      lineHeight: "17px",
    },
  },
  tab: {
    textTransform: "capitalize",
    minWidth: "unset",
    width: "max-content",
    padding: "0 20px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  emptyTab: {
    textTransform: "capitalize",
    minWidth: "unset",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: "default",
  },
  tabSelected: {
    fontWeight: theme.typography.fontWeightBold as any,
    borderBottom: "2px solid #4353FF",
    color: "#33333",
  },
}));

type PropTypes = {
  withCustomElements: boolean;
};

const CommunityEditorActions = (props: PropTypes) => {
  const classes = useStyles(props);
  const { withCustomElements } = props;
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [visibleDesignElement, setVisibleDesignElement] = React.useState(null);

  useEvent(
    "edit-design",
    (data) => {
      if (!withCustomElements) {
        return;
      }
      if (data.selected) {
        setVisibleDesignElement(data.element);
        if (data.openTab) {
          setSelectedTab(1);
        }
      } else {
        if (visibleDesignElement?.data?.uuid === data?.element?.data?.uuid) {
          setVisibleDesignElement(null);
        }
      }
    },
    [visibleDesignElement, withCustomElements]
  );

  const tabs = [];

  if (withCustomElements) {
    tabs.push({
      type: "elements",
      label: I18n.t("rich_text_editor.tabs.elements"),
    });

    tabs.push({
      type: "design",
      label: I18n.t("rich_text_editor.tabs.design"),
    });
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue !== tabs.length) {
      setSelectedTab(newValue);
    }
  };

  return (
    <div className={classes.resourcesTab}>
      <Tabs
        orientation="horizontal"
        centered
        variant={"fullWidth"}
        value={selectedTab}
        onChange={handleChange}
        className={classes.tabs}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab classes={{ selected: classes.tabSelected }} className={classes.tab} key={tab.type} label={tab.label} />
        ))}
        <Tab disableRipple className={classes.emptyTab} key={"empty"} label={""} />
      </Tabs>

      {tabs[selectedTab].type === "elements" && <PageEditorElements />}
      <PageEditorElementStyleWrapper visible={tabs[selectedTab].type === "design"} />
    </div>
  );
};

export default CommunityEditorActions;
