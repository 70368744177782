import * as React from "react";
import { I18n } from "react-redux-i18n";
import NumberInputBaseComponent from "../BaseComponents/NumberInputBaseComponent";

type PropTypes = {
  title?: string;
  value: number;
  handleChange: (width: number) => void;
  defaultValue?: number;
};

const HeightCSS = (props: PropTypes) => {
  const {
    title = I18n.t("rich_text_editor.feature_comparison_table.divider_height"),
    value,
    defaultValue,
    handleChange,
  } = props;
  return (
    <NumberInputBaseComponent
      postFix="px"
      defaultValue={defaultValue}
      rowTitle={title}
      value={value}
      handleChange={(width) => handleChange(parseFloat(width))}
    />
  );
};

export default HeightCSS;
