import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import filter from "lodash/filter";
import { IAd, ITag, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import agent from "../../../../../../../agent";
import MPanelTags from "../../../../../../../components/MPanelTags";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    marginBottom: 20,
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    // alignItems: 'center',
  },
  title: {
    width: 100,
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(14),
  },
  tags: {
    marginBottom: 0,
    paddingBottom: 0,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  ad: IAd;

  handleAdChanged: Function;
};

const AdTags = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, ad, handleAdChanged } = props;
  const [tags, setTags] = React.useState<ITag[]>([]);
  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    agent.Tags.all(selectedWebsite._id)
      .then((res) => {
        setTags(res.tags);
      })
      .catch((e) => {});
  }, [selectedWebsite]);

  return (
    <div className={classes.wrapper}>
      <Typography variant={"subtitle2"} className={classes.title}>
        {I18n.t("edit_post.tags")}
      </Typography>
      <MPanelTags
        customClass={classes.tags}
        selectedWebsite={selectedWebsite}
        postTags={ad.tags}
        tags={tags}
        onTagSelected={(newTag) => handleAdChanged({ ...ad, tags: [...ad.tags, newTag] })}
        onTagRemoved={(removedTag) =>
          handleAdChanged({ ...ad, tags: filter(ad.tags, (tag) => tag.name !== removedTag.name) })
        }
      />
    </div>
  );
};

export default AdTags;
