import React from "react";
import numeral from "numeral";
import { I18n } from "react-redux-i18n";
import { makeStyles, Theme } from "@material-ui/styles";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import PageSection from "./PageSection";
import { IProposal } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  plansWrap: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  planContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    border: `solid 1px #C4C4C4`,
    padding: 15,
    gap: 7,
    borderRadius: 3,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  secondRow: {
    display: "flex",
    alignItems: "baseline",
  },
  name: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  discount: {
    fontSize: theme.typography.pxToRem(12),
    color: "#00BF8F",
  },
  price: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold as any,
    marginLeft: 3,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },
  blueBorder: {
    border: `solid 1px ${theme.palette.primary.main}`,
  },
  blueColor: {
    color: theme.palette.primary.main,
  },
  discountText: {
    fontSize: 14,
    lineHeight: 1.2,
  },
  percentage: {
    fontSize: 32,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: 1.2,
    marginRight: 3,
  },
  purple: {
    color: "#A35DDC",
  },
  blue: {
    color: "#4353FF",
  },
  green: {
    color: "#00BF8F",
  },
}));

type PropTypes = {
  proposal: IProposal;
};
const SelectPlan = (props: PropTypes) => {
  const classes = useStyles(props);
  const { proposal } = props;
  const getPlan = (value) => {
    let plan = proposal?.plans[0];
    for (let i = 0; i < proposal?.plans.length; i += 1) {
      if (value >= numeral(proposal?.plans[i].price).value()) {
        plan = proposal?.plans[i];
      }
    }
    return plan;
  };

  const totalValue = proposal?.contentItems.reduce(
    (accCost, item) =>
      accCost +
      numeral(item.prices.find((p) => p.contentLevelId === proposal.selectedContentLevel?._id)?.price).value() *
        item.quantity,
    0
  );
  const selectedPlan = getPlan(totalValue);

  return (
    <PageSection title={I18n.t("account.pricing.select_plan.title")}>
      <div className={classes.plansWrap}>
        {proposal.plans.map((plan, index) => (
          <div
            key={plan._id}
            className={ClassNames(classes.planContainer, plan._id === selectedPlan._id && classes.blueBorder)}
          >
            <div className={classes.row}>
              <Typography variant={"body1"} className={classes.name}>
                {plan.name}
              </Typography>
            </div>

            <div
              className={ClassNames(
                classes.secondRow,
                index === 1 && classes.purple,
                index === 2 && classes.blue,
                index === 3 && classes.green
              )}
            >
              <Typography variant={"body1"} className={classes.percentage}>
                {numeral(numeral(plan.discount).value() / 100).format("0%")}
              </Typography>
              <Typography variant={"body1"} className={classes.discountText}>
                {I18n.t("account.pricing.select_plan.discount")}
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography color={"textSecondary"} variant={"body1"} className={classes.text}>
                {`${I18n.t("account.pricing.select_plan.from")} ${numeral(plan.price).format("$0,0[.]00")}
                ${I18n.t("account.pricing.select_plan.per_month")}`}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </PageSection>
  );
};

export default SelectPlan;
