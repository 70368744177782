import React, { useCallback, useMemo } from "react";
import { createEditor } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import ProductEmbedWidgetReadOnly from "./elements/widgets/productEmbed/ProductEmbedWidgetReadOnly";
import ComparisonTableWidgetReadOnly from "./elements/widgets/comparisonTable/ComparisonTableWidgetReadOnly";
import { LayoutAreaReadOnly, LayoutContainerReadOnly } from "./elements/layouts/layouts";
import CallToActionWidgetReadOnly from "./elements/widgets/deprecated/callToAction/CallToActionWidgetReadOnly";
import TextBoxElementReadOnly from "./elements/textBox/TextBoxElementReadOnly";
import PricingTableWidgetReadOnly from "./elements/widgets/pricingTable/PricingTableWidgetReadOnly";
import DividerElementReadOnly from "./elements/divider/DividerElementReadOnly";
import ProductDetailsWidgetReadOnly from "./elements/widgets/productDetails/ProductDetailsWidgetReadOnly";
import BulletedListElementReadOnly from "./elements/bulletedList/BulletedListElementReadOnly";

import ProductCTAWidget from "./elements/widgets/productCTAElement/ProductCTAWidget";
import ProductCTAHorizontalWidget from "./elements/widgets/productCTAHorizontal/ProductCTAHorizontalWidget";
import { IProduct, IWebsite } from "../reducers/constants/objectTypes";
import { editorType } from "./types/editor.Types";
import ButtonWidget from "./elements/widgets/button/ButtonWidget";
import CharticleTopProductsListWidget from "./elements/widgets/charticleTopProductsList/CharticleTopProductsListWidget";
import CharticleTopProductWidget from "./elements/widgets/charticleTopProduct/CharticleTopProductWidget";
import ProductReviewWidgetReadOnly from "./elements/widgets/productReview/ProductReviewWidgetReadOnly";
import NiProsConsTableReadOnly from "./elements/widgets/niProsConsTable/NiProsConsTableReadOnly";
import FAQWidgetElement from "./elements/widgets/faqWidget/FAQWidgetElement";
import EntailWidgetReadOnly from "./elements/entailWidgets/EntailWidgetReadOnly";
import BlockQuoteElement from "./elements/blockQuote/BlockQuoteElement";

const useStyles = makeStyles((theme: Theme) => ({
  editable: {
    color: theme.palette.text.primary,
    padding: 25,
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: (props: PropTypes) => theme.typography.pxToRem(props.fontSize || 16),
    lineHeight: 1.3,
  },
  title: {
    padding: 25,
    paddingBottom: 0,
    marginBottom: 0,
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.secondary,
    paddingLeft: 25,
    paddingRight: 25,
    marginTop: 16,
  },
  bulletedList: {
    "&&": {
      display: "block",
      listStyleType: "disc",
      marginBlockStart: "16px !important",
      marginBlockEnd: "16px !important",
      marginInlineStart: 0,
      marginInlineEnd: 0,
      paddingInlineStart: "25px !important",
      // fontSize: 18,
      // lineHeight: 1.66,
      marginBottom: 20,
      "& li": {
        marginBottom: 10,
        listStyle: "inherit",
      },
    },
  },
  numberedList: {
    "&&": {
      display: "block",
      listStyleType: "decimal",
      marginBlockStart: "16px !important",
      marginBlockEnd: "16px !important",
      marginInlineStart: 0,
      marginInlineEnd: 0,
      paddingInlineStart: "25px !important",
      // fontSize: 18,
      // lineHeight: 1.66,
      marginBottom: 20,
      "& li": {
        marginBottom: 10,
        listStyle: "inherit",
      },
      "& > li::marker": {
        fontWeight: theme.typography.fontWeightRegular as any,
      },
    },
  },
  tableHead: {
    "&&": {
      backgroundColor: "#F5F6F8",
      border: `1px double ${theme.palette.divider}`,
      height: "50px",
      fontSize: 14,
      padding: "25px 0 25px 20px",
    },
  },
  tableCell: {
    "&&": {
      border: `1px solid ${theme.palette.divider}`,
      height: "50px",
      fontSize: 14,
      padding: "25px 0 25px 20px",
    },
  },
  image: {
    maxWidth: "100%!important",
    height: "auto",
  },
  caption: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 10,
  },
  h2: {
    "&&": {
      fontSize: 30,
      fontWeight: theme.typography.fontWeightBold as any,
      lineHeight: "38px",
      marginBottom: 15,
      "&:not(:first-child)": {
        marginTop: 50,
        marginBottom: 15,
      },
    },
  },
  h3: {
    "&&": {
      fontSize: 26,
      fontWeight: theme.typography.fontWeightRegular as any,
      lineHeight: "34px",
      marginBottom: 10,
      "&:not(:first-child)": {
        marginTop: 40,
        marginBottom: 10,
      },
    },
  },
  paragraph: {
    "&&": {
      fontWeight: theme.typography.fontWeightLight as any,
      // fontSize: 16,
      lineHeight: 1.3,
      marginTop: 10,
      marginBottom: 0,
      wordBreak: "break-word",
    },
  },
}));

type PropTypes = {
  value: string;
  fontSize?: string | number;
  customEditableClass?: string;
  products?: IProduct[];
  website?: IWebsite;
  title?: string;
  brief?: string;
};

const SNReadOnlyEditor = (props: PropTypes) => {
  const classes = useStyles(props);
  const theme: any = useTheme();
  const { value, customEditableClass = null, products = [], website = null, title, brief } = props;
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderElement = useCallback((props) => <Element products={products} website={website} {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} theme={theme} />, []);

  console.log("SNReadOnlyEditor render", value);
  return (
    <Slate editor={editor} value={JSON.parse(value)} onChange={() => null}>
      {title && (
        <Typography className={classes.title} color={"textPrimary"} variant={"h1"}>
          {title}
        </Typography>
      )}
      {brief && (
        <Typography className={classes.subTitle} color={"textPrimary"} variant={"subtitle1"}>
          {brief}
        </Typography>
      )}
      <Editable
        className={ClassNames(classes.editable, customEditableClass)}
        readOnly
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
    </Slate>
  );
};

const Element = (props: any) => {
  const { attributes, children, element, website } = props;
  const classes = useStyles(props);
  const alignment = element.alignment || "left";
  switch (element.type) {
    case editorType.blockQuote:
      return (
        <BlockQuoteElement element={element} attributes={attributes} website={website} readOnly>
          {children}
        </BlockQuoteElement>
      );
    case editorType.headingOne:
      return (
        <h2 className={classes.h2} color={"inherit"}>
          {children}
        </h2>
      );
    case editorType.headingTwo:
      return (
        <h3 className={classes.h3} color={"inherit"}>
          {children}
        </h3>
      );
    case editorType.listItem:
      return <li {...attributes}>{children}</li>;
    case editorType.bulletedList:
      return <BulletedListElementReadOnly {...props}>{children}</BulletedListElementReadOnly>;
    case editorType.numberedList:
      return (
        <ol {...attributes} className={classes.numberedList}>
          {children}
        </ol>
      );
    case editorType.comment:
    case editorType.commentV2:
      return children;
    case editorType.image: // https://previews.123rf.com/images/victoroancea/victoroancea1201/victoroancea120100059/12055848-tv-color-test-pattern-test-card-for-pal-and-ntsc.jpg
      return (
        <div
          {...attributes}
          style={{
            overflow: "hidden",
            width: "100%",
            display: "flex",
            paddingBottom: 28,
            flexDirection: "column",
          }}
        >
          {element.file.href && (
            <a target={"_blank"} href={element.file.href}>
              <img
                height={element.file.height}
                width={element.file.width}
                src={element.file.cdnUrl || element.file.url}
                alt={element.file.title}
                data-file={element.file._id}
                className={classes.image}
              />
            </a>
          )}
          {!element.file.href && (
            <img
              height={element.file.height}
              width={element.file.width}
              src={element.file.cdnUrl || element.file.url}
              alt={element.file.title}
              data-file={element.file._id}
              className={classes.image}
            />
          )}
          {element.file.showCaption && element.file.caption && (
            <span className={classes.caption}>{element.file.caption}</span>
          )}
        </div>
      );
    case editorType.table: {
      return (
        <table className={classes.table} {...attributes}>
          {children}
        </table>
      );
    }
    case editorType.tableHead: {
      return <thead {...attributes}>{children}</thead>;
    }
    case editorType.tableBody: {
      return <tbody {...attributes}>{children}</tbody>;
    }
    case editorType.tableRow: {
      return <tr {...attributes}>{children}</tr>;
    }
    case editorType.tableHeader: {
      return (
        <th {...attributes} style={{ textAlign: alignment }} className={classes.tableHead}>
          {children}
        </th>
      );
    }
    case editorType.tableCell: {
      return (
        <td {...attributes} style={{ textAlign: alignment }} className={classes.tableCell}>
          {children}
        </td>
      );
    }
    case editorType.link:
      return (
        <a
          {...attributes}
          href={element.url}
          target={element.newTab ? "_blank" : "_blank"}
          rel={element.rel}
          data-product-id={element.product?.itemNumber}
        >
          {children}
        </a>
      );
    case editorType.embed:
      return (
        <iframe
          {...attributes}
          src={element.url}
          scrolling="no"
          frameBorder="no"
          allowtransparency={"true"}
          allowFullScreen={true}
          style={{ margin: 0, marginTop: 20, maxHeight: 1000 }}
          width={"100%"}
          height={element.subtype === "youtube" ? 385 : element.height}
        ></iframe>
      );
    case editorType.layout:
      return <LayoutContainerReadOnly {...props} />;
    case editorType.layoutArea:
      return <LayoutAreaReadOnly {...props} />;
    case editorType.inlineHtml:
      return (
        <div
          {...attributes}
          style={{ display: "flex", justifyContent: "center", ...element.data?.style }}
          dangerouslySetInnerHTML={{ __html: element.html }}
        />
      );
    case "custom-component": {
      const CustomComponent = element.component;
      return <CustomComponent />;
    }
    case editorType.productEmbed:
      return <ProductEmbedWidgetReadOnly {...props} />;
    case editorType.comparisonTable:
      return <ComparisonTableWidgetReadOnly {...props} />;
    case editorType.callToAction:
      return <CallToActionWidgetReadOnly {...props} />;
    case editorType.productCta:
      return <ProductCTAWidget {...props} readMode />;
    case editorType.productCtaHorizontal:
      return <ProductCTAHorizontalWidget readMode {...props} />;
    case editorType.faq:
      // return <FAQWidgetReadOnly {...props} />;
      return <FAQWidgetElement {...props} readMode />;
    case editorType.textBox:
      return <TextBoxElementReadOnly {...props} />;
    case editorType.pricingTable:
      return <PricingTableWidgetReadOnly {...props} />;
    case editorType.divider:
      return <DividerElementReadOnly {...props} />;
    case editorType.productDetails:
      return <ProductDetailsWidgetReadOnly {...props} />;
    case editorType.button:
      return <ButtonWidget readMode {...props} />;
    case editorType.charticleTopProduct:
      return <CharticleTopProductWidget {...props} readMode />;
    case editorType.charticleTopProducts:
      return <CharticleTopProductsListWidget {...props} readMode />;
    case editorType.productReview:
      return <ProductReviewWidgetReadOnly {...props} />;
    case editorType.niProsCons:
      return <NiProsConsTableReadOnly {...props} />;
    case editorType.entailWidget:
      return <EntailWidgetReadOnly {...props} />;
    default:
      return (
        <p {...attributes} className={classes.paragraph} style={{ textAlign: alignment }} {...props}>
          {children}
        </p>
      );
  }
};

const Leaf = (state: any) => {
  const { attributes, children, leaf, theme } = state;
  let lChildren = children;
  if (leaf.bold) {
    lChildren = <strong>{children}</strong>;
  }

  if (leaf.code) {
    lChildren = (
      <pre
        style={{
          background: theme.palette.secondary.main,
          border: "1px solid #ddd",
          borderLeft: "3px solid #f36d33",
          color: "#666",
          pageBreakInside: "avoid",
          fontFamily: "monospace",
          fontSize: 15,
          lineHeight: 1.6,
          marginBottom: "1.6em",
          maxWidth: "100%",
          overflow: "auto",
          padding: "1em 1.5em",
          display: "block",
          wordWrap: "break-word",
        }}
      >
        <code>{children}</code>
      </pre>
    );
  }

  if (leaf.italic) {
    lChildren = <em>{children}</em>;
  }

  if (leaf.underline) {
    lChildren = <u>{children}</u>;
  }
  if (leaf.color) {
    lChildren = <span style={{ color: leaf.color }}>{children}</span>;
  }
  return <span {...attributes}>{lChildren}</span>;
};

export default SNReadOnlyEditor;
