import * as React from "react";
import { I18n } from "react-redux-i18n";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import { IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { find } from "lodash";
import { CharticleTopProduct } from "../../../types/editor.Types";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../../elementsStyles/components/StylePanelSubSection";
import TypographyStyleElement from "../../../elementsStyles/ElementComponents/TypographyStyleElement";
import ButtonStyleElement from "../../../elementsStyles/ElementComponents/ButtonStyleElement";
import CardStyleElement from "../../../elementsStyles/ElementComponents/CardStyleElement";
import ListStyleElement from "../../../elementsStyles/ElementComponents/ListStyleElement";
import ProductPickerElement from "../../../elementsStyles/ElementComponents/ProductPickerElement";
import TextFieldElement from "../../../elementsStyles/ElementComponents/TextFieldElement";
import theme from "../../../../themes/theme";

type PropTypes = {
  editor: Editor;
  selectedWebsite: IWebsite;
  element: CharticleTopProduct;
  websiteTheme: IWebsiteThemeProps;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
};

const PageEditorCharticleTopProductStyle = (props: PropTypes) => {
  const { editor, selectedWebsite, element, products, handleProductsChange, websiteTheme } = props;

  const handleElementDataChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  return (
    <StylePanelSection
      sectionTitle={I18n.t("rich_text_editor.custom_components.charticle_top_product")}
      editor={editor}
    >
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.product_cta.settings")}>
        <ProductPickerElement
          isClearable={false}
          fullWidth
          selectedWebsite={selectedWebsite}
          products={products}
          handleProductsChange={handleProductsChange}
          product={product}
          onProductChange={(prod: IProduct) => {
            if (!prod) {
              handleElementDataChange({ ...element.data, product: prod });
            } else {
              handleElementDataChange({
                ...element.data,
                product: {
                  itemNumber: prod.itemNumber,
                },
              });
            }
          }}
        />
      </StylePanelSubSection>

      <CardStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.product_cta.card")}
        card={element.data.card}
        elementDefaultCss={{
          borderRadius: 4,
          padding: "24px",
        }}
        websiteTheme={websiteTheme}
        handleChange={(card) => {
          handleElementDataChange({ ...element.data, card });
        }}
      />

      <StylePanelSubSection
        divider
        sectionTitle={I18n.t("rich_text_editor.position")}
        show={element.data?.position.show}
        handleShowChange={(value) =>
          handleElementDataChange({ ...element.data, position: { ...element.data.position, show: value } })
        }
      >
        {}
      </StylePanelSubSection>

      {element.data.position.show && (
        <TextFieldElement
          title={I18n.t("rich_text_editor.position_index")}
          placeholder={I18n.t("rich_text_editor.position_index_ph")}
          value={element.data.position.index || ""}
          handleChange={(index) =>
            handleElementDataChange({ ...element.data, position: { ...element.data.position, index } })
          }
        />
      )}

      <StylePanelSubSection
        divider
        sectionTitle={I18n.t("rich_text_editor.logo")}
        show={element.data?.logo.show}
        handleShowChange={(value) =>
          handleElementDataChange({ ...element.data, logo: { ...element.data.logo, show: value } })
        }
      >
        {}
      </StylePanelSubSection>

      <StylePanelSubSection
        divider
        sectionTitle={I18n.t("rich_text_editor.rating")}
        show={element.data?.rating.show}
        handleShowChange={(value) =>
          handleElementDataChange({ ...element.data, rating: { ...element.data.rating, show: value } })
        }
      >
        {}
      </StylePanelSubSection>

      <TypographyStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.short_description")}
        typography={element.data?.shortDescription}
        elementDefaultCss={{
          fontSize: 16,
          fontWeight: theme.typography.fontWeightRegular as any,
          color: websiteTheme.palette.text.primary,
          margin: "14px 0px 0px 0px",
          padding: "0px 0px 0px 0px",
        }}
        variant={"h6"}
        handleChange={(data) => handleElementDataChange({ ...element.data, shortDescription: data })}
        theme={websiteTheme}
      />

      <ListStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.bullets")}
        list={element.data?.bullets}
        elementDefaultCss={{
          display: "flex",
          alignItems: "center",
          margin: "4px 0px 0px 0px",
          lineHeight: 1.5,
          fontSize: 16,
          fontWeight: theme.typography.fontWeightLight as any,
          listMargin: "24px 0px 0px 0px",
        }}
        variant={"subtitle1"}
        handleChange={(data) => handleElementDataChange({ ...element.data, bullets: data })}
        websiteTheme={websiteTheme}
      />

      <ButtonStyleElement
        divider
        editableText
        sectionTitle={I18n.t("rich_text_editor.product_cta.button")}
        button={element.data.button}
        defaultText={product?.callToAction || ""}
        defaultUrl={product?.callToActionURL || ""}
        theme={websiteTheme}
        handleChange={(button) => {
          handleElementDataChange({ ...element.data, button });
        }}
      />
    </StylePanelSection>
  );
};

export default PageEditorCharticleTopProductStyle;
