import { FB_GROUPS } from "./constants/actionTypes";

const initialState = {
  fbGroupsLoaded: false,
  fbGroups: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FB_GROUPS.FB_GROUPS_LOADED: {
      return {
        ...state,
        fbGroupsLoaded: true,
        fbGroups: action.payload.groups,
      };
    }
    case FB_GROUPS.FB_GROUPS_WEBSITES_UPDATED: {
      return {
        ...state,
        fbGroups: state.fbGroups.map((g) => {
          if (g._id === action.group._id) {
            return {
              ...g,
              websites: action.group.websites,
            };
          }
          return g;
        }),
      };
    }
    default:
      return state;
  }
};
