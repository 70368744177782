import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomTooltip from "./utils/CustomTooltip";
import React from "react";
import { I18n } from "react-redux-i18n";
import theme from "../../../../../themes/theme";

type Props = {
  data: any[];
  clicksLabel?: string;
  timeFormat: (item: any) => string;
  numberFormat: (item: any) => string;
  fullDateFormat: (item: any) => string;
  titlesFormat: (value: any, name: any) => any[];
  width?: string;
  height?: number;
  metric: string;
};

const TopQueryPageTopicChart = (props: Props) => {
  const { timeFormat, data, numberFormat, titlesFormat, fullDateFormat, metric } = props;

  console.log({ data });

  return (
    <ResponsiveContainer width={"98%"} height={400}>
      <ComposedChart data={data}>
        <XAxis
          dataKey={"date"}
          tickFormatter={timeFormat}
          axisLine={false}
          tickLine={false}
          tickMargin={16}
          tick={{ fontSize: 12 }}
          interval={"preserveStartEnd"}
          padding={{ right: 20 }}
        />
        <YAxis
          dataKey={"total"}
          axisLine={false}
          width={40}
          tickLine={false}
          tickMargin={10}
          tick={{ fontSize: 12 }}
          tickFormatter={numberFormat}
        />

        <Tooltip
          cursor={false}
          labelFormatter={fullDateFormat}
          content={
            <CustomTooltip
              customFormat={{
                total: metric === "ctr" ? "0,0[.]0%" : undefined,
              }}
              getTotal={false}
              translations={{
                total:
                  metric === "ctr" || metric === "position"
                    ? I18n.t("reports.dashboard.average")
                    : I18n.t("reports.dashboard.total"),
              }}
            />
          }
          formatter={titlesFormat}
          labelStyle={{ marginBottom: 5, fontWeight: theme.typography.fontWeightBold }}
        />
        <CartesianGrid stroke="#E5E5E5" vertical={false} strokeDasharray={1} />

        <Bar fill="#4353FF" maxBarSize={80} dataKey="total" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default TopQueryPageTopicChart;
