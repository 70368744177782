import { Divider, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldProperty from "./itemProperties/components/TextFieldProperty";
import TextareaProperty from "./itemProperties/components/TextareaProperty";
import RequiredFieldProperty from "./itemProperties/components/RequiredFieldProperty";
import LikeProperty from "./itemProperties/components/LikeProperty";
import { IWidgetLikeRatingFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import theme from "../../../../../../../themes/theme";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({
      display: "flex",
      flexDirection: "column",
      gap: 10,
    }),
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
    label: (props: PropTypes) => {
      const color = isDark ? palette.text.primary : palette.secondary.main;

      const colorOnHover = isDark ? palette.text.primary : palette.text.primary;

      return {
        fontSize: 14,
        "& label": {
          "&.Mui-focused": {
            color,
          },
          color: colorOnHover,
        },
      };
    },
    input: (props: PropTypes) => {
      const color = isDark ? palette.text.secondary : palette.text.primary;
      return {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightRegular as any,
        padding: 0,
        color,
      };
    },
  };
});

type PropTypes = {
  properties: IWidgetLikeRatingFieldProps;
  onPropertyChange(properties: IWidgetLikeRatingFieldProps): void;
};

const LikeWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { onPropertyChange, properties } = props;

  const onLabelChange = (event: any) => {
    const label = event.target.value;
  };

  const onThumbUpChange = (event: any) => {
    const thumbUp = event.target.value;
    onPropertyChange({ ...properties, thumbUp });
  };

  const onThumbDownChange = (event: any) => {
    const thumbDown = event.target.value;
    onPropertyChange({ ...properties, thumbDown });
  };

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.label")}
          placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.label_ph")}
          onChange={(label) => onPropertyChange({ ...properties, label })}
          value={properties?.label}
        />
        <Divider className={classes.divider} />
        <TextareaProperty onChange={(hint) => onPropertyChange({ ...properties, hint })} value={properties?.hint} />
        <Divider className={classes.divider} />
        <LikeProperty like={true} value={properties.thumbUp} onChange={onThumbUpChange} />
        <Divider className={classes.divider} />
        <LikeProperty like={false} value={properties.thumbDown} onChange={onThumbDownChange} />
      </div>

      <RequiredFieldProperty
        required={properties.required}
        onChange={(required) => onPropertyChange({ ...properties, required })}
      />
    </div>
  );
};

export default LikeWidgetItem;
