import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { IKeywordGroup } from "../../../reducers/constants/objectTypes";
import { KEYWORD_GROUPS } from "../../../reducers/constants/actionTypes";
import MTableRow from "../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../components/Table/Cells/MTableCellFirst";
import MTableCellLink from "../../../components/Table/Cells/MTableCellLink";
import MTableCellNumber from "../../../components/Table/Cells/MTableCellNumber";

type PropTypes = {
  keywordGroup: IKeywordGroup;
  checkedKeywordGroupsList: IKeywordGroup[];
  handleCheckedBoxChanged: (checkedKeywordGroupsList) => void;

  openKeywordDialog: (keywordGroup: IKeywordGroup, pageTab: boolean) => void;
};

const mapStateToProps = (state) => ({
  checkedKeywordGroupsList: state.keywordGroups.checkedKeywordGroupsList,
});

const mapDispatchToProps = (dispatch) => ({
  handleCheckedBoxChanged: (checkedKeywordGroupsList) => {
    dispatch({ type: KEYWORD_GROUPS.ON_CHECKED_BOX_CHANGED, checkedKeywordGroupsList });
  },
});

const KeywordGroupItem = (props: PropTypes) => {
  const theme = useTheme();
  const { keywordGroup, checkedKeywordGroupsList, handleCheckedBoxChanged, openKeywordDialog } = props;

  const handleCheckboxClicked = () => {
    if (!checkedKeywordGroupsList.includes(keywordGroup)) {
      handleCheckedBoxChanged([...checkedKeywordGroupsList, keywordGroup]);
    } else {
      handleCheckedBoxChanged(
        checkedKeywordGroupsList.filter((checkedOpportunity) => checkedOpportunity !== keywordGroup)
      );
    }
  };

  return (
    <MTableRow handleRowClick={handleCheckboxClicked} fullBorderWidth>
      <MTableCellFirst
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(14)}
        cellText={keywordGroup.clicks_main_keyword}
      />
      <MTableCellLink
        text={keywordGroup.variations || 0}
        handleOnClick={() => openKeywordDialog(keywordGroup, false)}
      />
      <MTableCellLink text={keywordGroup.pages || 0} handleOnClick={() => openKeywordDialog(keywordGroup, true)} />
      <MTableCellNumber value={keywordGroup.volume} type={"bigNumber"} />
      <MTableCellNumber value={keywordGroup.clicks} type={"bigNumber"} />
      <MTableCellNumber value={keywordGroup.impressions} type={"bigNumber"} />
      <MTableCellNumber value={keywordGroup.ctr} type={"percentage"} />
      <MTableCellNumber value={keywordGroup.position} type={"decimal"} placeHolder={"-"} />
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordGroupItem);
