import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem } from "@material-ui/core";
import GlobeIcon from "@material-ui/icons/Language";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { IWebsite } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    display: "flex",
    cursor: "pointer",
    padding: "20px 16px",
    "&:hover": {
      background: theme.palette.secondary.main,
      transition: "500ms",
      borderRadius: 50,
    },
  },
  websiteColumn: {
    width: "15%",
    display: "flex",
    alignItems: "center",
  },
  websiteText: {
    fontSize: "16px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.primary.main,
    marginLeft: 20,
  },
  column: {
    flexBasis: 0,
    flexGrow: 1,
  },
  columnText: {
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
}));

type PropTypes = {
  statistic: {
    website: IWebsite;
    total: {
      [key: string]: number;
    };
  };
};

const StatisticsListItem = (props: PropTypes) => {
  const classes = useStyles();

  const { statistic } = props;
  const { website, total } = statistic;

  return (
    <ListItem className={classes.itemWrapper}>
      <div className={classes.websiteColumn}>
        <GlobeIcon height={18} width={18} color={"primary"} />
        <Typography className={classes.websiteText}>{website.name}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>
          {website.startDate ? moment(website.startDate).format("YYYY-MM-DD") : "-"}
        </Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.forum || "0"}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.forumAnswers || "0"}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.Blog || "0"}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.Targeted || "0"}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.LearningCenter || "0"}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.Topic || "0"}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.Comparison || "0"}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.ProductReview || "0"}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.ProductPage || "0"}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.ProductAlternative || "0"}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{total.ProductComparison || "0"}</Typography>
      </div>
    </ListItem>
  );
};

export default StatisticsListItem;
