import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import numeral from "numeral";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import { I18n } from "react-redux-i18n";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import IconButton from "@material-ui/core/IconButton";
import { CONVERSION_POTENTIAL_FUNNEL_STAGE } from "../../../../../../helpers/constants";
import { getOpportunityPageUrl } from "../../../../../../helpers/urlHelper";
import TrashCanIcon from "../../../../../../icons/TrashCanIcon";
import CopyToClipboardIcon from "../../../../../../icons/CopyToClipboardIcon";
import OpenInEditor from "../../../../../../icons/OpenInEditor";
import MButtonGroup from "../../../../../../components/MButtonGroup";
import CopyNameIcon from "../../../../../../icons/CopyNameIcon";

const useStyles = makeStyles((theme: Theme) => ({
  metric: {
    fontSize: "14px",
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: "100%",
    width: "10%",
  },
  firstColumn: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: "14px",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "color .2s ease-in-out",
  },
  actionsWrapper: {
    gap: 20,
    alignItems: "center",
    display: "none",
    position: "absolute",
    right: 20,
    zIndex: 2,
    width: "fit-content",
    backgroundColor: theme.palette.secondary.main,
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionIcon: {
    height: 18,
    fill: theme.palette.text.secondary,
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "20px 0px",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      transition: "border-radius 500ms",
      "& $actionsWrapper": {
        display: "flex",
      },
    },
  },
  iconBtn: {
    padding: 0,
  },
  listItemFirstColumn: {
    width: "55%",
    paddingLeft: "15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  relatedPage: any;
  setSelectedRelatedPage: (relatedPage: any) => void;
  openPost: (pageId: string, currentUrl?: string) => void;
};

const RelatedPagesDialogListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { relatedPage, setSelectedRelatedPage, openPost } = props;

  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return (type) => {
      if (relatedPage.page) {
        navigator.clipboard.writeText(type === "page" ? relatedPage.page : relatedPage.metadata?.title);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();

  return (
    <ListItem
      className={classNames(classes.listRow)}
      onClick={() => {
        setSelectedRelatedPage(relatedPage);
      }}
    >
      <div className={classNames(classes.metric, classes.listItemFirstColumn)}>
        <Tooltip title={getOpportunityPageUrl(relatedPage.related_page)} placement={"bottom-start"}>
          <Typography className={classes.firstColumn}>
            {relatedPage.metadata?.title
              ? relatedPage.metadata?.title
              : getOpportunityPageUrl(relatedPage.related_page, true)}
          </Typography>
        </Tooltip>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedPage.kws || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedPage.in_common_kws || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>
          {_.capitalize(CONVERSION_POTENTIAL_FUNNEL_STAGE[relatedPage.conversion_potential] || "None")}
        </Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedPage.clicks || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedPage.position || 0).format("0.0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(relatedPage.volume || 0).format("0,0a")}</Typography>
      </div>
      <div className={classNames(classes.actionsWrapper)}>
        {relatedPage.metadata?.title && (
          <Tooltip
            arrow
            title={showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title")}
            className={classes.actionIconWrapper}
          >
            <IconButton
              className={classes.iconBtn}
              onClick={(e) => {
                e.stopPropagation();
                handleTitleCopy("title");
              }}
            >
              <CopyNameIcon className={classNames(classes.actionIcon)} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          arrow
          title={showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_url")}
          className={classes.actionIconWrapper}
        >
          <div>
            <CopyToClipboardIcon
              className={classNames(classes.actionIcon)}
              onClick={(e) => {
                e.stopPropagation();
                handleTitleCopy("page");
              }}
            />
          </div>
        </Tooltip>
        <Tooltip
          className={classes.actionIconWrapper}
          arrow
          onClick={(e) => {
            e.stopPropagation();
            window.open(relatedPage.related_page, "_blank");
          }}
          title={I18n.t("outline.icons.open_page")}
        >
          <div>
            <OpenInNewIcon className={classes.actionIcon} />
          </div>
        </Tooltip>
        {relatedPage.metadata?.entail_page_id && (
          <Tooltip arrow title={I18n.t("outline.icons.open_in_editor")} className={classes.actionIconWrapper}>
            <IconButton
              className={classes.iconBtn}
              onClick={(e) => {
                e.stopPropagation();
                openPost(relatedPage.metadata?.entail_page_id);
              }}
            >
              <OpenInEditor className={classNames(classes.actionIcon)} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </ListItem>
  );
};

export default RelatedPagesDialogListItem;
