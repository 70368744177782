import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import PlayerIcon from "@material-ui/icons/PlayArrow";
import { IFile, ICoverVideo, IWebsite } from "../../../reducers/constants/objectTypes";
import InsertImageDialog from "../../Modals/InsertImageDialog/InsertImageDialog";
import ImagePlaceHolder from "../../../icons/ImagePlaceHolder";
import EllipseIcon from "../../../icons/editor/EllipseIcon";
import InsertVideoDialog from "../../Modals/InsertVideoDialog";
import { getImageCDNUrl } from "../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    whiteSpace: "pre",
    margin: "0px 40px",
    marginBottom: 20,
    overflow: "hidden",
    position: "relative",
    "&:hover": {
      "& > $uploadWrapper": {
        display: "flex",
      },
    },
  },
  previewImage: {
    width: "100%",
    height: "auto",
    borderRadius: 5,
    cursor: "pointer",
    objectFit: "cover",
  },
  previewNoImage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 10px",
    gap: 30,
    width: "100%",
    height: 300,
    background: "#F5F6F8",
    borderRadius: 5,
  },
  imagePlaceHolder: {
    height: 33,
    width: 33,
    marginBottom: 10,
  },
  imagePlaceHolderText: {
    color: "#808080",
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 17,
    cursor: "pointer",
  },
  imagePlaceHolderTextWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
  previewImageAndText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  uploadWrapper: {
    display: "none",
    flexDirection: "row",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.40)",
    padding: "30px 0px",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },
  uploadText: {
    cursor: "pointer",
    color: "#fff",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    fill: "#808080",
  },
  playerIcon: {
    position: "absolute",
    fill: "#fff",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: 64,
    background: "radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%)",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  previewImage: IFile;
  previewVideo: ICoverVideo;
  imageSizeLimit?: number;
  onImageSelected: (file: IFile) => void;
  onVideoSelected: (value: { video: ICoverVideo; image: IFile }) => void;
};

const PageEditorCoverImage = (props: PropTypes) => {
  const classes = useStyles(props);
  const { previewImage, selectedWebsite, imageSizeLimit, previewVideo, onImageSelected, onVideoSelected } = props;
  const [open, setOpen] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);

  return (
    <div className={classes.wrapper}>
      {!previewImage && (
        <div className={classes.previewNoImage}>
          <div className={classes.previewImageAndText}>
            <ImagePlaceHolder className={classes.imagePlaceHolder} />
            <div className={classes.imagePlaceHolderTextWrapper}>
              <Typography className={classes.imagePlaceHolderText} variant={"subtitle2"} onClick={(e) => setOpen(true)}>
                {I18n.t("rich_text_editor.cover_image.upload_image")}
              </Typography>
              <EllipseIcon className={classes.icon} />
              <Typography
                className={classes.imagePlaceHolderText}
                variant={"subtitle2"}
                onClick={() => setOpenVideo(true)}
              >
                {I18n.t("rich_text_editor.cover_image.upload_video")}
              </Typography>
            </div>
          </div>
        </div>
      )}
      {previewImage && (
        <img
          height="300"
          width={previewImage?.width}
          className={classes.previewImage}
          src={getImageCDNUrl(previewImage.cdnUrl || previewImage.url)}
        />
      )}
      {previewImage && (
        <div className={classes.uploadWrapper}>
          <Typography className={classes.uploadText} onClick={(e) => setOpen(true)}>
            {I18n.t("rich_text_editor.cover_image.upload_image")}
          </Typography>
          <EllipseIcon />
          <Typography className={classes.uploadText} onClick={() => setOpenVideo(true)}>
            {I18n.t("rich_text_editor.cover_image.upload_video")}
          </Typography>
        </div>
      )}
      {previewVideo?.url && <PlayerIcon className={classes.playerIcon} />}
      {open && (
        <InsertImageDialog
          imageSizeLimit={imageSizeLimit}
          website={selectedWebsite}
          selectedImageFile={previewImage}
          handleInsertClick={(file: IFile) => {
            onImageSelected(file);
            setOpen(false);
          }}
          handleCloseClick={() => setOpen(false)}
        />
      )}
      {openVideo && (
        <InsertVideoDialog
          website={selectedWebsite}
          handleCloseClick={() => setOpenVideo(false)}
          handleInsertClick={(value) => {
            onVideoSelected(value);
            setOpenVideo(false);
          }}
        />
      )}
    </div>
  );
};

export default PageEditorCoverImage;
