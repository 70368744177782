import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { OutlinedInput } from "@material-ui/core";
import PageSection from "../../../components/PageSection";
import { IWebsiteThemeProps } from "../../../../../reducers/constants/objectTypes";
import ColorTool from "../../../components/ColorTool";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import MRouterPrompt from "../../../components/MRouterPrompt";

const useStyles = makeStyles((theme: Theme) => ({
  paletteColorWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  elementWrap: {
    width: "100%",
  },
  colorsTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 15,
    marginTop: 50,
    textDecoration: "underline",
    "&:first-child": {
      marginTop: 0,
    },
  },
  input: {
    height: 37,
    width: 100,
    fontSize: theme.typography.pxToRem(14),
    padding: "0 5px",
  },
  root: {
    borderRadius: 4,
    paddingLeft: 15,
  },
}));

const mapStateToProps = (state) => ({
  initialTheme: state.websitePages.initialTheme,
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (theme) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "theme",
      value: theme,
    }),
});

type PropTypes = {
  initialTheme: IWebsiteThemeProps;
  theme: IWebsiteThemeProps;

  onPropertyChanged: (theme) => void;
};

const ThemeBreakPoints = (props: PropTypes) => {
  const classes = useStyles(props);
  const { theme, onPropertyChanged } = props;

  return (
    <PageSection sectionTitle={I18n.t("website.breakpoints.title")}>
      <MRouterPrompt />
      <div className={classes.paletteColorWrapper}>
        {["xs", "sm", "md", "lg", "xl"].map((size) => (
          <div key={size} className={classes.elementWrap}>
            <Typography variant={"body2"} className={classes.colorsTitle}>
              {I18n.t(`website.breakpoints.${size}`)}
            </Typography>
            <OutlinedInput
              value={theme.breakpoints.values[size]}
              placeholder={""}
              type="number"
              inputProps={{ step: 1 }}
              classes={{
                root: classes.root,
                input: classes.input,
              }}
              onChange={(event) => {
                onPropertyChanged({
                  ...theme,
                  breakpoints: {
                    values: {
                      ...theme.breakpoints.values,
                      [size]: parseInt(event.target.value, 10),
                    },
                  },
                });
              }}
            />
          </div>
        ))}
      </div>
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeBreakPoints);
