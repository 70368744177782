import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";

import MTableToolbarBaseSelect from "../Toolbar/MTableToolbarBaseSelect";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingRight: 10,
  },
  select: {
    textTransform: "capitalize",
    cursor: "pointer",
    marginLeft: -10,
    paddingRight: 10,
  },
  titleWrapper: {
    margin: "0",
    justifyContent: "space-between",
    maxWidth: "150px",
  },
  selectionResultClass: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular as any,
    color: theme.palette.text.secondary,
    opacity: 0.5,
  },
  selectionResultClassSelected: {
    color: theme.palette.common.black,
    opacity: 1,
    fontWeight: theme.typography.fontWeightMedium as any,
  },
}));

type PropTypes = {
  options: any[];
  value: any;
  columnWidth?: string;
  optionLabel?: string;
  optionValue?: string;
  placeholder: string;
  handleInputChange?: (input: string) => void;
  handleChange?: (values) => void;
  searchable?: boolean;
};

const MTableCellSelectMultiple = (props: PropTypes) => {
  const classes = useStyles(props);
  const { options, value, optionLabel = "name", columnWidth, optionValue = "_id", placeholder, handleChange } = props;

  return (
    <div className={classes.column} style={{ width: columnWidth }}>
      <MTableToolbarBaseSelect
        multipleOptions
        title={I18n.t("classify_keywords.goals")}
        options={options}
        placeholder={placeholder}
        selectedOptions={value}
        titleClassName={classes.titleWrapper}
        selectionResultClass={classes.selectionResultClass}
        selectionResultSelectedClass={classes.selectionResultClassSelected}
        handleChange={(values) => {
          handleChange(values);
        }}
      />
    </div>
  );
};

export default MTableCellSelectMultiple;
