import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { sumBy } from "lodash";
import { useSnackbar } from "notistack";
import { ePostState, ePostType, ICategory, ITopic, IWebsite } from "../../../../../reducers/constants/objectTypes";
import { CATEGORY, MANAGE_WEBSITES } from "../../../../../reducers/constants/actionTypes";
import { AbilityContext } from "../../../../../casl/can";
import agent from "../../../../../agent";
import MSavedXMinutesAgo from "../../../../../components/MSavedXMinutesAgo";
import MButtonGroup from "../../../../../components/MButtonGroup";
import CfButton from "../../../../../components/CfButton";

const useStyles = makeStyles((theme: Theme) => ({
  postBottomBarWrapper: {
    zIndex: 3,
    height: 65,
    minHeight: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    borderTop: `${theme.palette.divider} solid 1px`,
    position: "sticky",
    bottom: 0,
  },
  postBottomBarInnerWrapper: {
    // width: 'calc(100% - 2*280px)',
    width: "100%",
    paddingRight: 30,
    paddingLeft: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    minHeight: 40,
  },
  discardButton: {
    marginRight: 20,
  },
  postTypesWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textCounter: {
    marginRight: 20,
  },
  lastSaved: {
    marginRight: 20,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  topicsFilter: "forum" | "blog" | "targeted_posts";
  topicsLoaded: boolean;
  selectedCategory: ICategory;

  onCategoryCreated: Function;
  onCategoryUpdated: Function;
  onCategoryDeleted: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  selectedCategory: state.manageWebsites.selectedCategory,
});

const mapDispatchToProps = (dispatch) => ({
  onCategoryUpdated: (category) => {
    dispatch({ type: MANAGE_WEBSITES.ON_CATEGORY_UPDATED, category });
    dispatch({ type: CATEGORY.CATEGORY_CHANGED, category });
  },
  onCategoryCreated: (category) => {
    dispatch({ type: MANAGE_WEBSITES.ON_NEW_CATEGORY_CREATED, category });
    dispatch({ type: CATEGORY.NEW_CATEGORY, category });
  },
  onCategoryDeleted: (category) => {
    dispatch({ type: MANAGE_WEBSITES.ON_CATEGORY_DELETED, category });
    dispatch({ type: CATEGORY.CATEGORY_DELETED, category });
  },
});

const TopicsBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, selectedCategory, onCategoryCreated, onCategoryUpdated, onCategoryDeleted } = props;
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ability = React.useContext<any>(AbilityContext);

  const actions = [];

  actions.push({
    key: "update",
    label: I18n.t("edit_post.update"),
  });

  if (
    selectedCategory &&
    selectedCategory.questionsCount === 0 &&
    selectedCategory.blogCount === 0 &&
    selectedCategory.targetedCount === 0
  ) {
    actions.push({
      key: "delete",
      label: I18n.t("edit_post.delete"),
    });
  }

  const saveCategory = (category) => {
    // switch (topicsFilter) {
    //   case 'targeted_posts':
    //     return agent.Topics.upsert(ePostType.TARGETED, topic);
    //   case 'forum':
    //     return agent.ForumTopics.upsert(topic);
    //   case 'blog':
    //     return agent.Topics.upsert(ePostType.BLOG, topic);
    //   default:
    //     return null;
    // }
  };

  const deleteCategory = (category) => {
    // switch (topicsFilter) {
    //   case 'targeted_posts':
    //     return agent.Topics.delete(ePostType.TARGETED, topic);
    //   case 'forum':
    //     return agent.ForumTopics.delete(topic);
    //   case 'blog':
    //     return agent.Topics.delete(ePostType.BLOG, topic);
    //   default:
    //     return null;
    // }
  };

  const handleSaveCategoryClick = () => {
    if (!selectedCategory.name || selectedCategory.name.length === 0) {
      alert("Name can't be empty");
      return;
    }
    setSaveInProgress(true);
    agent.Categories.upsert(selectedCategory)
      .then((res) => {
        setSaveInProgress(false);
        enqueueSnackbar(I18n.t("snackbar.topic_update_success", { name: selectedCategory.name }));
        onCategoryUpdated(res.publishResults?.category || res.category);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.topic_update_success", { name: selectedCategory.name, msg: e.message }));
        setSaveInProgress(false);
      });
  };

  const handleDeleteCategoryClick = () => {
    setSaveInProgress(true);
    agent.Categories.delete(selectedCategory)
      .then((res) => {
        setSaveInProgress(false);
        enqueueSnackbar(I18n.t("snackbar.topic_delete_success", { name: selectedCategory.name }));
        onCategoryDeleted(res.category);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.topic_delete_success", { name: selectedCategory.name, msg: e.message }));
        setSaveInProgress(false);
      });
  };

  const handleActionClick = (type) => {
    console.log("handleActionClick", type);
    switch (type) {
      case "update":
        handleSaveCategoryClick();
        break;
      case "delete":
        handleDeleteCategoryClick();
        break;
      default:
        break;
    }
  };

  const createNewTopic = () => {
    agent.Categories.upsert({
      name: `New Topic - ${Math.random().toString(36).substr(2, 5)}`,
      website: selectedWebsite,
    })
      .then((res) => {
        setSaveInProgress(true);
        onCategoryCreated(res.category);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.topic_update_success", { name: selectedCategory.name, msg: e.message }));
        setSaveInProgress(false);
      });
  };

  // const onDiscardDraftClicked = () => {
  //   setDiscardDialogOpen(true);
  // };
  //
  // const discardAnswer = () => {
  //   agent.EditPost.discardAnswer(postType, post, post.state === ePostState.INTERNAL_REVIEW ? 'editor' : 'writer')
  //     .then(res => {
  //       enqueueSnackbar(I18n.t(`snackbar.post.discard.${postType}`));
  //       returnToPreviousScreen();
  //     }).catch(e => {
  //     enqueueSnackbar(I18n.t('snackbar.post.discard.error', {msg: e.response.body.message}));
  //   });
  // }

  const onDeleteClicked = () => {
    setDeleteDialogOpen(true);
  };

  // const deleteAnswer = () => {
  //   agent.EditPost.deletePost(postType, post._id)
  //     .then(res => {
  //       enqueueSnackbar(I18n.t(`snackbar.post.delete.${postType}`));
  //       returnToPreviousScreen();
  //     }).catch(e => {
  //     enqueueSnackbar(I18n.t('snackbar.post.delete.error', {msg: e.response.body.message}));
  //   });
  // }

  return (
    <div className={classes.postBottomBarWrapper}>
      <div className={classes.postBottomBarInnerWrapper}>
        <div className={classes.flexWrapper}>
          <CfButton
            outlined
            onClick={() => createNewTopic()}
            customClass={classes.button}
            text={I18n.t("manage_topics.new_category")}
          />
        </div>
        {selectedCategory && (
          <div className={classes.flexWrapper}>
            <div className={classes.lastSaved}>
              <MSavedXMinutesAgo lastSaveTime={selectedCategory.updatedAt} />
            </div>
            {actions[0] && (
              <MButtonGroup
                text={actions[0].label}
                onClick={() => handleActionClick(actions[0].key)}
                options={actions}
                onMenuItemClick={(key: string) => handleActionClick(key)}
              />
            )}
          </div>
        )}
      </div>
      {/* {deleteDialogOpen && ( */}
      {/*  <MAlertDialog */}
      {/*    open={deleteDialogOpen} */}
      {/*    title={I18n.t('edit_post.delete_dialog.title')} */}
      {/*    description={I18n.t('edit_post.delete_dialog.description')} */}
      {/*    agreeText={I18n.t('edit_post.delete_dialog.agree')} */}
      {/*    disagreeText={I18n.t('edit_post.delete_dialog.cancel')} */}
      {/*    handleClose={(agree) => { */}
      {/*      setDeleteDialogOpen(false); */}
      {/*      if (agree) { */}
      {/*        deleteAnswer(); */}
      {/*      } */}
      {/*    }} */}
      {/*  /> */}
      {/* )} */}
      {/* {discardDialogOpen && ( */}
      {/*  <MAlertDialog */}
      {/*    open={discardDialogOpen} */}
      {/*    title={I18n.t('edit_post.discard_dialog.title')} */}
      {/*    description={I18n.t('edit_post.discard_dialog.description')} */}
      {/*    agreeText={I18n.t('edit_post.discard_dialog.agree')} */}
      {/*    disagreeText={I18n.t('edit_post.discard_dialog.cancel')} */}
      {/*    handleClose={(agree) => { */}
      {/*      setDiscardDialogOpen(false); */}
      {/*      if (agree) { */}
      {/*        discardAnswer(); */}
      {/*      } */}
      {/*    }} */}
      {/*  /> */}
      {/* )} */}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicsBottomBar);
