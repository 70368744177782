import * as React from "react";
import { I18n } from "react-redux-i18n";
import { useTheme } from "@material-ui/core/styles";
import LandingPageListItem from "./LandingPageListItem";
import { ILandingPage } from "../../../../reducers/constants/objectTypes";
import MTable from "../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";

type PropTypes = {
  landingPages: ILandingPage[];
  landingPagesLoaded: boolean;
  handleRefreshList: () => void;
};

const LandingPagesList = (props: PropTypes) => {
  const { landingPages, handleRefreshList, landingPagesLoaded } = props;
  const theme = useTheme();
  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabel columnWidth={"40%"} fontSize={theme.typography.pxToRem(16)}>
          {I18n.t("landing_pages.list.landing_page_name")}
        </MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("landing_pages.list.type")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("landing_pages.list.status")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("landing_pages.list.created")}</MTableHeaderLabel>
        <MTableHeaderLabel columnWidth={"40px"}>{""}</MTableHeaderLabel>
      </MTableHeader>
      {!landingPagesLoaded && <MTableRowProgressBar />}

      {landingPages?.length > 0 &&
        landingPages.map((item) => (
          <LandingPageListItem key={item._id} landingPage={item} handleRefreshList={handleRefreshList} />
        ))}
      {landingPagesLoaded && landingPages?.length === 0 && <MTableRowEmptyRow />}
    </MTable>
  );
};

export default LandingPagesList;
