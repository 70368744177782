import classNames from "classnames";
import React from "react";
import { IconButton, ListSubheader, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import XIcon from "../../../../icons/XIcon";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getOpportunityPageUrl } from "../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  dialogListSubHeader: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    backgroundColor: theme.palette.background.default,
    position: "sticky",
    top: 0,
  },
  dialogTitle: {
    fontSize: "20px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    marginBottom: "20px",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogTabs: {
    display: "flex",
    // marginBottom: -2,
    width: "60%",
    flexDirection: "row",
  },
  tab: {
    padding: "2px 40px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: 55,
    borderBottom: `2px solid #c4c4c4`,
  },
  activeTab: {
    color: theme.palette.text.primary,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    transition: "border .3s",
  },
  closeButton: {
    padding: 0,
    marginRight: 0,
    alignSelf: "flex-start",
  },
  headerIcon: {
    width: 20,
    height: 20,
  },
  countText: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    marginLeft: "10px",
  },
}));

const mapStateToProps = (state) => ({
  broadKeywords: state.keywordGroupPage.broadKeywords,
});

type PropTypes = {
  setPageTab: (page: "outlines" | "keywords" | "pages") => void;
  pageTab: "outlines" | "keywords" | "pages";
  close: () => void;
  broadKeywords: any[];
  title: string;
};

const KeywordGroupPageDialogHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { pageTab, setPageTab, close, title, broadKeywords } = props;

  return (
    <ListSubheader className={classes.dialogListSubHeader}>
      <div className={classes.titleWrapper}>
        <Typography className={classes.dialogTitle}>{getOpportunityPageUrl(title)}</Typography>
        <IconButton className={classes.closeButton} onClick={close}>
          <XIcon className={classes.headerIcon} />
        </IconButton>
      </div>
    </ListSubheader>
  );
};

export default connect(mapStateToProps, {})(KeywordGroupPageDialogHeader);
