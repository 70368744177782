import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "rgb(255, 133, 188)",
    height: 18,
    width: 18,
  },
}));

type PropTypes = {
  className?: string;

  exitModal: () => void;
};

const PageEditorCloseIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className, exitModal } = props;

  return (
    <SvgIcon onClick={exitModal} className={ClassNames(classes.icon, className)} viewBox={"0 0 19 19"}>
      <rect
        width="2.5"
        height="23"
        rx="1.25"
        transform="matrix(0.711585 -0.7026 -0.711585 -0.7026 16.8652 18.4585)"
        fill="#808080"
      />
      <rect
        width="2.5"
        height="23"
        rx="1.25"
        transform="matrix(-0.711585 -0.7026 0.711585 -0.7026 2.63379 18.4585)"
        fill="#808080"
      />
    </SvgIcon>
  );
};

export default PageEditorCloseIcon;
