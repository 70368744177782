import * as React from "react";
import { RenderElementProps } from "slate-react";
import { EntailWidget } from "../../types/editor.Types";
import MDangerouslySetHtmlContent from "../../../components/MDangerouslySetHtmlContent";
import widgetsAgent from "../../../widgetsAgent";
import { IWebsite } from "../../../reducers/constants/objectTypes";

const EntailWidgetReadOnly = ({
  attributes,
  children,
  element,
  website,
}: RenderElementProps & { element: EntailWidget; website: IWebsite }) => {
  const [html, setHtml] = React.useState(null);

  React.useEffect(() => {
    const loadWidget = () =>
      widgetsAgent.Widgets.editorPreview(element?.data.widget.uploadedId, website.host)
        .then((widgetHtml) => setHtml(widgetHtml))
        .catch((e) => console.log(e, e.message));

    if (!element?.data?.widget?.uploadedId) {
      return;
    }
    loadWidget();
  }, [element?.data?.widget?.uploadedId, website]);

  return (
    <div {...attributes} style={{ marginTop: element?.data?.margin?.top, marginBottom: element?.data?.margin?.bottom }}>
      {html && <MDangerouslySetHtmlContent html={html} />}
      {children}
    </div>
  );
};

export default EntailWidgetReadOnly;
