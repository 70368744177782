import React from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme, Tooltip, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import MTextFieldV2 from "../../../../../../../../components/MTextFieldV2";
import AddButton from "../../../../../WidgetsBuilder/components/AddButton";
import { IWebsite, ICampaign, eMpEvent } from "../../../../../../../../reducers/constants/objectTypes";
import { HOME, WIDGET_RULE_EDITOR } from "../../../../../../../../reducers/constants/actionTypes";
import MDynamicListItem from "../../../../../../../../components/MDynamicListItem";
import {
  WidgetRuleEditorRootState,
  WidgetRuleEditorSelectedView,
} from "../../../../../../../../reducers/widgetRuleEditorReducer";
import { loadHtml } from "../apiHelper";
import SendIcon from "../../../../../../../../icons/SendIcon";
import LaunchIcon from "../../../../../../../../icons/LaunchIcon";
import WidgetContainerListItem from "./WidgetContainerListItem";
import { getWidgetHTMLContainer, scrollToContainer } from "../../../../../../../../helpers/htmlPreviewHelper";

const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 20,
    marginTop: 6,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
  },
  itemWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  addButton: {
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  endAdornmentWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    marginLeft: 10,
  },
  textFieldIcon: {
    fill: theme.palette.text.disabled,
    "&:hover": {
      cursor: "default",
    },
    "&:first-child": {
      paddingTop: 2,
    },
    "&:last-child": {
      "& path": {
        fill: theme.palette.text.primary,
      },
    },
  },
  textFieldIconActive: {
    fill: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
    "&:last-child": {
      "& path": {
        fill: "#fff",
      },
    },
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  preview: state.widgetRuleEditor.preview,
  rule: state.widgetRuleEditor.rule,
});

const mapDispatchToProps = (dispatch) => ({
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PROPERTY_CHANGE, property });
  },
  handlePreviewPropertyChange: (property: Partial<WidgetRuleEditorRootState["preview"]>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PREVIEW_PROPERTY_CHANGE, property });
  },
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
});

type PropTypes = {
  preview: WidgetRuleEditorRootState["preview"];
  rule: ICampaign;
  selectedWebsite: IWebsite;
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => void;
  handlePreviewPropertyChange: (property: Partial<WidgetRuleEditorRootState["preview"]>) => void;
  mpEvent: (event: string, props: object) => void;
};

const RuleBuilderMain = (props: PropTypes) => {
  const classes = useStyles();
  const { rule, preview, selectedWebsite } = props;
  const { handlePropertyChange, mpEvent } = props;
  const theme = useTheme<Theme>();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const editRule = (rule: ICampaign) => {
    handlePropertyChange({ rule });
  };

  const editPreviewUrl = (previewUrl: string) => {
    const newRule = { ...rule, previewUrl };
    if (newRule.rules.length === 0) {
      newRule.rules.push({
        type: "exact",
        value: previewUrl,
        operator: "and",
      });
    }

    if (newRule.rules.length === 1 && newRule.rules[0].type === "exact" && newRule.rules[0].value === rule.previewUrl) {
      newRule.rules[0].value = previewUrl;
    }

    editRule(newRule);
  };

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.itemWrapper}>
        <Typography className={classes.title}>
          {I18n.t("widgets_display_rules_editor.builder.main_view.preview_page")}
        </Typography>
        <MTextFieldV2
          value={rule.previewUrl}
          placeholder={I18n.t("widgets_display_rules_editor.builder.main_view.paste_url")}
          onDebounceChange={editPreviewUrl}
          transformValue={(value) => {
            let previewUrl = value;
            if (previewUrl && !previewUrl.startsWith("http")) {
              previewUrl = `https://${previewUrl}`;
            }
            previewUrl = previewUrl.replace(/#.*$/, "");
            return previewUrl;
          }}
          endAdornment={
            <div className={classes.endAdornmentWrapper}>
              <LaunchIcon
                onClick={() => window.open(rule.previewUrl, "_blank")}
                className={classNames(classes.textFieldIcon, rule.previewUrl && classes.textFieldIconActive)}
              />
              <SendIcon
                className={classNames(classes.textFieldIcon, rule.previewUrl && classes.textFieldIconActive)}
                onClick={() =>
                  rule.previewUrl &&
                  loadHtml(dispatch, enqueueSnackbar, {
                    rule,
                    selectedWebsite,
                    preview,
                    noCache: true,
                  })
                }
              />
            </div>
          }
        />
      </div>
      <MDynamicListItem
        title={
          !rule.rules?.[0]?.value
            ? I18n.t("widgets_display_rules_editor.builder.main_view.select_pages")
            : `${I18n.t(`widgets_display_rules_editor.builder.page_select_view.conditions.${rule.rules?.[0].type}`)}: ${
                rule.rules?.[0].value
              }`
        }
        fontWeight={theme.typography.fontWeightRegular}
        onClick={() =>
          handlePropertyChange({
            selectedView: WidgetRuleEditorSelectedView.PAGE_SELECT,
          })
        }
      />
      <div className={classes.itemWrapper}>
        {rule.widgetContainers.length > 0 && (
          <Typography className={classes.title}>
            {I18n.t("widgets_display_rules_editor.builder.main_view.widgets")}
          </Typography>
        )}
        <div>
          {rule.widgetContainers.map((item, index) => (
            <WidgetContainerListItem
              key={item.containerId}
              id={index === 0 ? "scroll-to-widget" : undefined}
              title={item.widgetFilter.widget?.name || `Widget ${index + 1}`}
              enabled={item.enabled}
              onLocateClick={
                !preview.loading && preview.XPathCollectionsRendered && getWidgetHTMLContainer(item.containerId)
                  ? () => scrollToContainer(item.containerId)
                  : undefined
              }
              onEditClick={() =>
                handlePropertyChange({
                  selectedWidgetContainerId: item.containerId,
                  selectedView: WidgetRuleEditorSelectedView.WIDGET_CONTAINER_SETTINGS,
                  draftContainer: item,
                })
              }
              handleSwitchChange={(enabled: boolean) => {
                const newRule = { ...rule };
                newRule.widgetContainers = newRule.widgetContainers.map((container, i) =>
                  i === index ? { ...container, enabled } : container
                );
                editRule(newRule);
              }}
            />
          ))}
          {!preview.htmlWithContainers || preview.loading ? (
            <Tooltip
              title={
                preview.loading
                  ? I18n.t("widgets_display_rules_editor.builder.main_view.loading_disabled")
                  : I18n.t("widgets_display_rules_editor.builder.main_view.add_widgets_disabled")
              }
              enterDelay={500}
            >
              <div>
                <AddButton
                  className={classes.addButton}
                  text={I18n.t("widgets_display_rules_editor.builder.main_view.add_widget")}
                  disabled
                />
              </div>
            </Tooltip>
          ) : (
            <AddButton
              id={"new-widget"}
              className={classes.addButton}
              text={I18n.t("widgets_display_rules_editor.builder.main_view.add_widget")}
              onClick={() => {
                mpEvent(eMpEvent.croCampaignButtonClick, {
                  type: "add_widget",
                  text: I18n.t("widgets_display_rules_editor.builder.main_view.add_widget"),
                });
                handlePropertyChange({ addContainerMode: true });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleBuilderMain);
