import * as React from "react";
import { useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { IPageCluster } from "../../../../reducers/constants/objectTypes";
import PublishClusterRow from "./PublishClusterRow";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";
import MTable from "../../../../components/Table/Table/MTable";

type PropTypes = {
  clustersLoaded: boolean;
  clusters: IPageCluster[];
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  setSortField: (input: { direction: "desc" | "asc" | undefined; field: string }) => void;
  pagesCount: number;
};

const PublishClusterItemList = (props: PropTypes) => {
  const { clustersLoaded, clusters, sortField, pagesCount } = props;
  const theme: any = useTheme();

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          columnWidth={"calc(34% - 40px)"}
          fontSize={theme.typography.pxToRem(21)}
          sortField={props.sortField}
          field={"title"}
        >
          {I18n.t("in_progress.header.posts", { count: pagesCount })}
        </MTableHeaderSortLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"firstPublishDate"}
        >
          {I18n.t("workflow.published.listHeader.published_date")}
        </MTableHeaderSortLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"publishedDate"}
        >
          {I18n.t("workflow.published.listHeader.updated_date")}
        </MTableHeaderSortLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"quotaDate"}
        >
          {I18n.t("workflow.published.listHeader.quota_date")}
        </MTableHeaderSortLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"locale"}
        >
          {I18n.t("workflow.published.listHeader.locale")}
        </MTableHeaderSortLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"textWordsCount"}
        >
          {I18n.t("workflow.published.listHeader.words")}
        </MTableHeaderSortLabel>
        <MTableHeaderLabel> {I18n.t("workflow.published.listHeader.category")}</MTableHeaderLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"linksCount"}
        >
          {I18n.t("workflow.published.listHeader.links")}
        </MTableHeaderSortLabel>
        <MTableHeaderLabel>{I18n.t("workflow.published.listHeader.imported")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("workflow.published.listHeader.writer")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("workflow.published.listHeader.status")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("workflow.published.listHeader.published_by")}</MTableHeaderLabel>
      </MTableHeader>
      {!clustersLoaded && <MTableRowProgressBar />}
      {clustersLoaded && clusters.length === 0 && <MTableRowEmptyRow />}
      {clustersLoaded &&
        clusters.length > 0 &&
        clusters.map((cluster, index) => (
          <PublishClusterRow sortField={sortField} key={cluster._id} cluster={cluster} />
        ))}
    </MTable>
  );
};

export default PublishClusterItemList;
