import * as React from "react";
import { I18n } from "react-redux-i18n";
import { Variant } from "@material-ui/core/styles/createTypography";
import CurrencyPickerElement from "./CurrencyPickerElement";
import FontSizeCSS from "../CssComponents/FontSizeCSS";
import FontWeightCSS from "../CssComponents/FontWeightElement";
import FontColorCSS from "../CssComponents/FontColorCSS";
import PaddingCSS from "../CssComponents/PaddingCSS";
import MarginCSS from "../CssComponents/MarginCSS";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import StylePanelSubSection from "../components/StylePanelSubSection";
import { typographyDefaultCss } from "../../../containers/WebsiteConfigurations/WebsiteEditor/consts/CssConsts";

type PropTypes = {
  divider?: boolean;
  sectionTitle: string;
  currency?: boolean;
  variant: Variant;
  elementDefaultCss?: any;
  typography: {
    show: boolean | null;
    css: any;
    priceCurrency?: { code: string; symbol: string };
  };
  handleChange: any;
  theme: IWebsiteThemeProps;
};

const TypographyStyleElement = (props: PropTypes) => {
  const {
    divider = false,
    sectionTitle,
    theme,
    variant,
    currency = false,
    elementDefaultCss = {},
    typography,
    handleChange,
  } = props;

  const defaultCss = React.useMemo(
    () => (theme ? typographyDefaultCss(theme, variant, elementDefaultCss) : {}),
    [theme, variant, elementDefaultCss]
  );

  const handleShowChange = (show: boolean) => {
    handleChange({ ...typography, show });
  };

  return (
    <StylePanelSubSection
      divider={divider}
      sectionTitle={sectionTitle}
      show={typography.show}
      handleShowChange={typography.show !== null ? handleShowChange : null}
    >
      {/*{typography.show !== null && (*/}
      {/*  <SwitchBaseComponent*/}
      {/*    defaultValue={true}*/}
      {/*    value={typography.show}*/}
      {/*    rowTitle={I18n.t("rich_text_editor.show")}*/}
      {/*    handleChange={(show) => handleChange({ ...typography, show })}*/}
      {/*  />*/}
      {/*)}*/}
      {currency && (
        <CurrencyPickerElement
          height={32}
          value={typography.priceCurrency}
          onChange={(priceCurrency) => handleChange({ ...typography, priceCurrency })}
        />
      )}
      <FontSizeCSS
        value={typography.css?.fontSize || defaultCss.fontSize}
        defaultValue={defaultCss.fontSize}
        handleChange={(fontSize) => handleChange({ ...typography, css: { ...typography.css, fontSize } })}
      />
      <FontWeightCSS
        value={typography.css?.fontWeight || defaultCss.fontWeight}
        defaultValue={defaultCss.fontWeight}
        handleChange={(fontWeight) => handleChange({ ...typography, css: { ...typography.css, fontWeight } })}
      />
      <FontColorCSS
        rowTitle={I18n.t("rich_text_editor.text_color")}
        value={typography.css?.color || defaultCss.color || theme.palette.text.primary}
        defaultValue={defaultCss.color}
        isValueChanged={typography.css?.color && typography.css?.color !== defaultCss.color}
        handleChange={(color) => handleChange({ ...typography, css: { ...typography.css, color } })}
      />
      <PaddingCSS
        value={typography.css?.padding || defaultCss?.padding}
        defaultValue={defaultCss?.padding}
        handleChange={(padding) => handleChange({ ...typography, css: { ...typography.css, padding } })}
      />
      <MarginCSS
        value={typography.css?.margin || defaultCss?.margin}
        defaultValue={defaultCss?.margin}
        handleChange={(margin) => handleChange({ ...typography, css: { ...typography.css, margin } })}
      />
    </StylePanelSubSection>
  );
};

export default TypographyStyleElement;
