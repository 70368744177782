import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { ICategory } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  topicWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 3,
  },
  title: {
    minWidth: 80,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 7,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
  },
}));

type PropTypes = {
  showLabel?: boolean;
  selectedCategory: ICategory;
  categories: ICategory[];

  onCategorySelected: (category: ICategory) => void;
};

const mapStateToProps = (state) => ({
  categories: state.category.categories,
});

const mapDispatchToProps = (dispatch) => ({});

const PageEditorCategory = (props: PropTypes) => {
  const classes = useStyles(props);
  const { showLabel = true, selectedCategory } = props;
  const { categories, onCategorySelected } = props;
  const theme = useTheme<Theme>();
  return (
    <div className={classes.topicWrapper}>
      <MSelect
        customClass={classes.selectCustomClass}
        options={categories}
        value={_.find(categories, { _id: selectedCategory?._id }) || null}
        optionLabel={"name"}
        optionValue={"_id"}
        height={31}
        borderWidth={"0px"}
        isBold={false}
        textColor={theme.palette.text.primary}
        menuPlacement={"bottom"}
        singleValueStyles={{ fontSize: theme.typography.pxToRem(12) }}
        fullWidth
        searchable
        getDisplayLabel={(category) => `${category.name}`}
        handleChange={(category) => {
          onCategorySelected(category);
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorCategory);
