import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 16,
    height: 16,
  },
}));

type PropTypes = {
  className?: string;
  onClick?: (e: any) => void;
};

function TrashCanIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className, onClick } = props;
  return (
    <SvgIcon className={className} viewBox={"0 0 12 14"} onClick={onClick}>
      <path d="M1.99033 13.5C1.71664 13.5 1.47717 13.3917 1.27191 13.175C1.06664 12.9583 0.964011 12.7056 0.964011 12.4167V2.125H0.775853C0.627608 2.125 0.50502 2.07384 0.40809 1.97153C0.31116 1.86921 0.262695 1.73981 0.262695 1.58333C0.262695 1.42685 0.31116 1.29745 0.40809 1.19514C0.50502 1.09282 0.627608 1.04167 0.775853 1.04167H3.47848C3.47848 0.885185 3.52695 0.755787 3.62388 0.653472C3.72081 0.551157 3.8434 0.5 3.99164 0.5H7.48112C7.62936 0.5 7.75195 0.551157 7.84888 0.653472C7.94581 0.755787 7.99427 0.885185 7.99427 1.04167H10.6969C10.8452 1.04167 10.9677 1.09282 11.0647 1.19514C11.1616 1.29745 11.2101 1.42685 11.2101 1.58333C11.2101 1.73981 11.1616 1.86921 11.0647 1.97153C10.9677 2.07384 10.8452 2.125 10.6969 2.125H10.5087V12.4167C10.5087 12.7056 10.4061 12.9583 10.2009 13.175C9.99559 13.3917 9.75612 13.5 9.48243 13.5H1.99033ZM1.99033 2.125V12.4167H9.48243V2.125H1.99033ZM3.80348 10.3222C3.80348 10.4787 3.85195 10.6081 3.94888 10.7104C4.04581 10.8127 4.1684 10.8639 4.31664 10.8639C4.46489 10.8639 4.58748 10.8127 4.68441 10.7104C4.78134 10.6081 4.8298 10.4787 4.8298 10.3222V4.20139C4.8298 4.04491 4.78134 3.91551 4.68441 3.81319C4.58748 3.71088 4.46489 3.65972 4.31664 3.65972C4.1684 3.65972 4.04581 3.71088 3.94888 3.81319C3.85195 3.91551 3.80348 4.04491 3.80348 4.20139V10.3222ZM6.64296 10.3222C6.64296 10.4787 6.69142 10.6081 6.78835 10.7104C6.88528 10.8127 7.00787 10.8639 7.15612 10.8639C7.30436 10.8639 7.42695 10.8127 7.52388 10.7104C7.62081 10.6081 7.66927 10.4787 7.66927 10.3222V4.20139C7.66927 4.04491 7.62081 3.91551 7.52388 3.81319C7.42695 3.71088 7.30436 3.65972 7.15612 3.65972C7.00787 3.65972 6.88528 3.71088 6.78835 3.81319C6.69142 3.91551 6.64296 4.04491 6.64296 4.20139V10.3222ZM1.99033 2.125V12.4167V2.125Z" />
    </SvgIcon>
  );
}

export default TrashCanIcon;
