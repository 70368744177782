import React from "react";
import { I18n } from "react-redux-i18n";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";
import { ePageType, IWebsite } from "../../../../reducers/constants/objectTypes";
import { getAvailablePageTypes } from "../../../../helpers/websiteHelper";

type PropTypes = {
  postTypes: { _id: ePageType; name: string }[];
  selectedWebsite: IWebsite;
  handlePostTypesSelected: (postTypes: { _id: ePageType; name: string }[]) => void;
};

const MTableToolbarPostTypeFilter = (props: PropTypes) => {
  const { postTypes, handlePostTypesSelected, selectedWebsite } = props;
  const pageTypes = getAvailablePageTypes(selectedWebsite).map((value) => ({
    _id: value.key,
    name: value.label,
  }));
  return (
    <MTableToolbarBaseSelect
      multipleOptions
      options={pageTypes}
      selectedOptions={postTypes}
      placeholder={I18n.t("workflow.writer.type_post")}
      title={I18n.t("workflow.writer.types")}
      handleChange={handlePostTypesSelected}
    />
  );
};

export default MTableToolbarPostTypeFilter;
