import React, { useEffect, useMemo, useState } from "react";
import { I18n } from "react-redux-i18n";
import MDropdown from "../../../../../components/MDropdown";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  dropdownWrapper: {
    padding: "3px 10px",
  },
}));

type PropTypes = {
  value: any;
  handleOnChange: (value) => void;
};

export const options = [
  {
    value: "search-console",
    reportType: "search-console",
    label: "Organic Traffic",
  },
  {
    value: "impressions",
    reportType: "search-console",
    label: "Impressions",
  },

  {
    value: "words-count",
    reportType: "words-count",
    label: "Word Count",
  },
  {
    value: "article-count",
    reportType: "article-count",
    label: "Articles Count",
  },
  {
    value: "indexed-queries",
    reportType: "indexed-queries",
    label: "Indexed Queries",
  },
  {
    value: "cta-clicks",
    reportType: "cta-clicks",
    label: "CTA clicks",
    tabular: false,
    pivot: false,
  },
  {
    value: "cta-origin",
    reportType: "cta-origin",
    label: "CTA clicks by landing page",
    tabular: true,
    pivot: true,
  },
  {
    value: "cta-by-url",
    reportType: "cta-by-url",
    label: "CTA clicks by origin",
    tabular: true,
    pivot: true,
  },
  {
    value: "cta-by-destination",
    reportType: "cta-by-destination",
    label: "CTA clicks by destination",
    tabular: true,
    pivot: true,
  },
  {
    value: "top-topics",
    reportType: "top-topics",
    label: "Top Topics",
    tabular: true,
  },
  {
    value: "top-pages",
    reportType: "top-pages",
    label: "Top Pages",
    tabular: true,
  },

  {
    value: "top-queries",
    reportType: "top-queries",
    label: "Top Queries",
    tabular: true,
  },
  {
    value: "page-events",
    reportType: "page-events",
    label: "Page events",
    pageEvents: true,
  },
];

const ReportTypeFilter = (props: PropTypes) => {
  const classes = useStyles(props);
  const { value, handleOnChange } = props;

  return (
    <MDropdown
      classes={{ root: classes.dropdownWrapper }}
      withBorder
      options={options}
      value={value}
      handleOnChange={handleOnChange}
      placeholder={I18n.t("reports.dashboard.select_ph")}
      optionLabel="label"
      optionValue="value"
    />
  );
};

export default ReportTypeFilter;
