import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function BulletedListIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 37 30"}>
      <path d="M3.000,12.000 C1.340,12.000 -0.000,13.340 -0.000,15.000 C-0.000,16.660 1.340,18.000 3.000,18.000 C4.660,18.000 6.000,16.660 6.000,15.000 C6.000,13.340 4.660,12.000 3.000,12.000 ZM3.000,-0.000 C1.340,-0.000 -0.000,1.340 -0.000,3.000 C-0.000,4.660 1.340,6.000 3.000,6.000 C4.660,6.000 6.000,4.660 6.000,3.000 C6.000,1.340 4.660,-0.000 3.000,-0.000 ZM3.000,24.000 C1.340,24.000 -0.000,25.360 -0.000,27.000 C-0.000,28.640 1.360,30.000 3.000,30.000 C4.640,30.000 6.000,28.640 6.000,27.000 C6.000,25.360 4.660,24.000 3.000,24.000 ZM11.000,29.000 L35.000,29.000 C36.100,29.000 37.000,28.100 37.000,27.000 C37.000,25.900 36.100,25.000 35.000,25.000 L11.000,25.000 C9.900,25.000 9.000,25.900 9.000,27.000 C9.000,28.100 9.900,29.000 11.000,29.000 ZM11.000,17.000 L35.000,17.000 C36.100,17.000 37.000,16.100 37.000,15.000 C37.000,13.900 36.100,13.000 35.000,13.000 L11.000,13.000 C9.900,13.000 9.000,13.900 9.000,15.000 C9.000,16.100 9.900,17.000 11.000,17.000 ZM9.000,3.000 C9.000,4.100 9.900,5.000 11.000,5.000 L35.000,5.000 C36.100,5.000 37.000,4.100 37.000,3.000 C37.000,1.900 36.100,1.000 35.000,1.000 L11.000,1.000 C9.900,1.000 9.000,1.900 9.000,3.000 Z" />
    </SvgIcon>
  );
}

export default BulletedListIcon;
