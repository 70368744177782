import find from "lodash/find";
import orderBy from "lodash/orderBy";
import { LEARNING_CENTER_CONTENT } from "./constants/actionTypes";

const initialState = {
  refreshTopics: false,
  topicsLoaded: false,
  topics: [],
  subtopicPosts: {},
  expandedSet: new Set(),
  collapseAll: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LEARNING_CENTER_CONTENT.DOWNLOAD_TOPICS:
      return {
        ...state,
        refreshTopics: true,
      };
    case LEARNING_CENTER_CONTENT.TOPICS_LOADED:
      return {
        ...state,
        topicsLoaded: true,
        refreshTopics: false,
        topics: action.payload.topics,
      };
    case LEARNING_CENTER_CONTENT.SUBTOPIC_POSTS_LOADED: {
      return {
        ...state,
        subtopicPosts: {
          ...state.subtopicPosts,
          [action.subtopic._id]: {
            postsLoaded: true,
            posts: action.posts,
          },
        },
      };
    }
    case LEARNING_CENTER_CONTENT.EXPAND_TOPIC: {
      const expandedSet = new Set(state.expandedSet);
      expandedSet.add(action.topic._id);
      return {
        ...state,
        expandedSet,
      };
    }
    case LEARNING_CENTER_CONTENT.COLLAPSE_TOPIC: {
      const expandedSet = new Set(state.expandedSet);
      expandedSet.delete(action.topic._id);
      return {
        ...state,
        expandedSet,
      };
    }
    case LEARNING_CENTER_CONTENT.TOPICS_POSITION_UPDATED: {
      const topics = state.topics.map((topic) => {
        const t = find(action.payload.topics, { _id: topic._id });
        if (t) {
          return { ...topic, order: t.order };
        }
        return topic;
      });
      return {
        ...state,
        topics: orderBy(topics, ["order"]),
      };
    }
    case LEARNING_CENTER_CONTENT.SUBTOPICS_POSITION_UPDATED: {
      const topics = state.topics.map((topic) => {
        if (topic._id === action.payload.topics[0].parent) {
          return {
            ...topic,
            children: orderBy(
              topic.children.map((child) => {
                const c = find(action.payload.topics, { _id: child._id });
                if (c) {
                  console.log(`${child.name} from ${child.order} to ${c.order}`);
                  return { ...child, order: c.order };
                }
                return child;
              }),
              ["order"]
            ),
          };
        }
        return topic;
      });

      return {
        ...state,
        topics,
      };
    }
    case LEARNING_CENTER_CONTENT.COLLAPSE_ALL:
      return {
        ...state,
        collapseAll: true,
        expandedSet: new Set(),
      };
    case LEARNING_CENTER_CONTENT.EXPAND_ALL:
      return {
        ...state,
        collapseAll: false,
      };
    default:
      return state;
  }
};
