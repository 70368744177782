import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ReactPlayer from "react-player";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      borderWidth: 0,
      backgroundColor: "black",
    },
  })
);

type PropTypes = {
  open: boolean;
  src: string;
  subtitle?: {
    src: string;
    language: string;
  };

  handleExitClick: () => void;
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const VideoPlayerDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, src, subtitle, handleExitClick } = props;

  const close = () => {
    handleExitClick();
  };

  const handleClose = (event, reason) => {
    console.log("handleClose", reason);
    // if (reason && reason === "backdropClick") return;
    close();
  };

  console.log("subtitle", subtitle);
  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      keepMounted={false}
      onClose={handleClose}
      maxWidth={"sm"}
      TransitionComponent={Transition}
    >
      <ReactPlayer
        config={{
          file: {
            attributes: {
              crossOrigin: "anonymous",
            },
            tracks: subtitle
              ? [
                  {
                    src: subtitle.src,
                    kind: "subtitles",
                    srcLang: subtitle.language,
                    default: true,
                    label: "English",
                  },
                ]
              : [],
          },
        }}
        url={src}
        width="100%"
        height="100%"
        controls
        playing
      />
    </Dialog>
  );
};

export default VideoPlayerDialog;
