import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withHttps } from "../../../../../helpers/urlHelper";
import {
  ePostType,
  IBlogPost,
  ILearnPost,
  ILearnTopic,
  IQuestion,
  ITargetedPost,
  IWebsite,
  IWriterCommunityAnswer,
} from "../../../../../reducers/constants/objectTypes";
import MLink from "../../../../../components/MLink";

const useStyles = makeStyles((theme: Theme) => ({
  questionUrlWrapper: {
    width: "100%",
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  urlTitle: {
    marginRight: 20,
  },
}));

type PropTypes = {
  topic: ILearnTopic;
  topicType: ePostType;
  selectedWebsite: IWebsite;
};

const EditTopicInfoTabTopicUrl = (props: PropTypes) => {
  const classes = useStyles(props);
  const { topic, topicType, selectedWebsite } = props;

  const getPostUrl = () => {
    return `${selectedWebsite.url}${selectedWebsite.learningCenterUrlPrefix}${topic.uploadedUrl}`;
  };

  const url = getPostUrl();
  return (
    <div className={classes.questionUrlWrapper}>
      <Typography variant={"body1"} className={classes.urlTitle} color={"textSecondary"}>
        {I18n.t("edit_post.url")}
      </Typography>
      <MLink newTab href={withHttps(url)}>
        {url}
      </MLink>
    </div>
  );
};

export default EditTopicInfoTabTopicUrl;
