import * as React from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { I18n } from "react-redux-i18n";
import { Widget } from "../widgets/WithWidgets";

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  disableWrapperBorder?: boolean;
};

function NumberedListElement(props: PropTypes) {
  const { attributes, children, element, disableWrapperBorder = false } = props;
  const editor = useSlateStatic();

  return (
    <Widget
      title={I18n.t("rich_text_editor.tooltips.numbered_list")}
      element={element}
      contentEditable
      disableWrapper={disableWrapperBorder}
      editable
      handleDeleteClick={() => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        Transforms.removeNodes(editor, { at: path });
      }}
    >
      <ol {...attributes}>{children}</ol>
    </Widget>
  );
}

export default NumberedListElement;
