import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state/index";
import { makeStyles } from "@material-ui/styles";
import { useMemo } from "react";
import { IAdminUser, IWebsite } from "../../../../../reducers/constants/objectTypes";
import CfCheckBox from "../../../../../components/CfCheckBox";

const useStyles = makeStyles((theme: Theme) => ({
  selectorWrapper: {
    display: "flex",
  },
  itemsList: {
    overflowY: "auto",
    maxHeight: 240,
    minHeight: 40,
  },
  itemsListItem: {
    cursor: "pointer",
    height: 38,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& $itemsListItemText": {
        color: theme.palette.primary.main,
      },
      "& $listItemIcon": {
        visibility: "unset",
      },
    },
  },
  selectWebsites: {
    cursor: "pointer",
  },
  itemsListItemText: {
    fontWeight: theme.typography.fontWeightRegular as any,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
  },
  listItemIcon: {
    minWidth: "unset",
  },
  popoverPaper: {},
}));

type PropTypes = {
  user: IAdminUser;
  websites: IWebsite[];

  buttonWrapperClass?: any;
  width?: number;

  transformHorizontal?: "left" | "center" | "right" | number;

  handleUserChanged: Function;
};

const UserLocalesPicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const { user, websites, handleUserChanged } = props;
  const { buttonWrapperClass, transformHorizontal = "right", width = null } = props;

  const getLocales = () => (user.locales || ["None"]).join(", ") || "None";

  const availableLocales = useMemo(() => {
    if (!user || !user.role) {
      return [];
    }
    const locales = new Set();
    (["admin", "owner"].includes(user.role) ? websites : user.websites || []).map((website) => {
      if (website.configurations.locale.defaultLocale) {
        locales.add(website.configurations.locale.defaultLocale);
      }
      website.configurations.locale.alternateLocales.map((al) => {
        if (al.locale) {
          locales.add(al.locale);
        }
      });
    });
    return Array.from(locales);
  }, [user]);

  return (
    <div className={classes.selectorWrapper}>
      <PopupState variant="popover" popupId="posts-type-popover">
        {(popupState) => (
          <div className={buttonWrapperClass || null}>
            <div className={classes.selectWebsites} role="cell" {...bindTrigger(popupState)}>
              {getLocales()}
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: transformHorizontal,
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: transformHorizontal,
              }}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <div style={{ width: width || "unset" }}>
                <List className={classes.itemsList}>
                  {availableLocales.map((locale) => (
                    <ListItem
                      key={locale}
                      classes={{
                        container: ClassNames(classes.itemsListItem),
                      }}
                      // onClick={event => onItemSelected(popupState, item)}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <CfCheckBox
                          checked={(user.locales || []).includes(locale)}
                          value={locale}
                          handleChange={() => {
                            if ((user.locales || []).includes(locale)) {
                              handleUserChanged({ ...user, locales: user.locales.filter((l) => l !== locale) });
                              return;
                            }
                            handleUserChanged({ ...user, locales: [...(user.locales || []), locale] });
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText className={classes.itemsListItemText} primary={locale} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default UserLocalesPicker;
