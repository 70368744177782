import React, { useEffect, useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import FilesTab from "./Files/FilesTab";
import CommentsTab from "./Comments/CommentsTab";
import MediaTab from "./Media/MediaTab";
import { IVideoEditor, useVideoEditorContext } from "../../VideoEditorContext";
import ability from "../../../../casl/ability";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  resourcesTab: {
    height: "100%",
    flex: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  tabs: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    marginBottom: 25,
    backgroundColor: "#F5F6F8",
  },
  tab: {
    textTransform: "capitalize",
    minWidth: "unset",
    paddingRight: 20,
    paddingLeft: 20,
  },
  tabSelected: {
    fontWeight: typography.fontWeightBold as any,
    borderBottom: `2px solid ${palette.primary.main}`,
  },
  tabContentWrapper: {
    flex: 1,
    overflow: "auto",
  },
}));

type ITab = {
  label: string;
  value: IVideoEditor["selectedTab"];
  disabled: boolean;
};

const VideoEditorTabs = () => {
  const classes = useStyles();
  const { selectedTab, selectedTimeline, commentActions, editVideoEditorProperty } = useVideoEditorContext();

  const tabs: ITab[] = useMemo(
    () => [
      {
        label: "Comments",
        value: "comments",
        disabled: !ability.can("view", "video_editor_comments_tab"),
      },
      {
        label: "Media",
        value: "media",
        disabled: !ability.can("view", "video_editor_media_tab"),
      },
      {
        label: "Files",
        value: "files",
        disabled: !ability.can("view", "video_editor_files_tab"),
      },
    ],
    []
  );

  const setSelectedTab = useCallback(
    (tab: IVideoEditor["selectedTab"]) => {
      editVideoEditorProperty({ selectedTab: tab });
    },
    [editVideoEditorProperty]
  );

  const getLabel = (tab: ITab) => {
    if (tab.value === "comments") {
      const commentsCount = selectedTimeline ? commentActions.reduce((acc) => acc + 1, 0) : 0;
      return `${commentsCount || ""} ${commentsCount === 1 ? "Comment" : "Comments"}`;
    }
    return tab.label;
  };

  const handleChange = (_event, newValue: IVideoEditor["selectedTab"]) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(tabs.find((tab) => !tab.disabled)?.value);
    }
  }, [selectedTab, setSelectedTab, tabs]);

  return (
    <div className={classes.resourcesTab}>
      <Tabs
        orientation="horizontal"
        centered
        variant="fullWidth"
        value={selectedTab}
        onChange={handleChange}
        className={classes.tabs}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        {tabs.map((tab, i) => (
          <Tab
            classes={{ selected: classes.tabSelected }}
            className={classes.tab}
            key={i}
            label={getLabel(tab)}
            value={tab.value}
            disabled={tab.disabled}
          />
        ))}
      </Tabs>
      <div className={classes.tabContentWrapper}>
        {selectedTab === "media" && <MediaTab />}
        {selectedTab === "files" && <FilesTab />}
        {selectedTab === "comments" && <CommentsTab />}
      </div>
    </div>
  );
};

export default VideoEditorTabs;
