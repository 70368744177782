import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#F04444",
    width: 2,
    height: 15,
  },
}));

type PropTypes = {
  className?: string;
};

const ErrorIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 2 15"}>
      <path
        d="M1 9.5C0.716667 9.5 0.479167 9.40417 0.2875 9.2125C0.0958333 9.02083 0 8.78333 0 8.5V1.5C0 1.21667 0.0958333 0.979167 0.2875 0.7875C0.479167 0.595833 0.716667 0.5 1 0.5C1.28333 0.5 1.52083 0.595833 1.7125 0.7875C1.90417 0.979167 2 1.21667 2 1.5V8.5C2 8.78333 1.90417 9.02083 1.7125 9.2125C1.52083 9.40417 1.28333 9.5 1 9.5ZM1 14.5C0.716667 14.5 0.479167 14.4042 0.2875 14.2125C0.0958333 14.0208 0 13.7833 0 13.5C0 13.2167 0.0958333 12.9792 0.2875 12.7875C0.479167 12.5958 0.716667 12.5 1 12.5C1.28333 12.5 1.52083 12.5958 1.7125 12.7875C1.90417 12.9792 2 13.2167 2 13.5C2 13.7833 1.90417 14.0208 1.7125 14.2125C1.52083 14.4042 1.28333 14.5 1 14.5Z"
        fill="#F04444"
      />
    </SvgIcon>
  );
};

export default ErrorIcon;
