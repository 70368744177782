import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import { connect } from "react-redux";
import _, { findIndex } from "lodash";
import { List } from "@material-ui/core";
import ClassNames from "classnames";
import { PAGE_TYPE_COLORS } from "../../../../reducers/constants/consts";
import PublishRow from "./PublishRow";
import { IPage, IPageCluster, IWebsite } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    display: "block",

    padding: 0,
    fontSize: theme.typography.pxToRem(12),
  },
  listItem: {
    display: "block",
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
  },
  directory: (props: PropTypes) => ({
    "&:after": {
      position: "absolute",
      top: 39,
      bottom: 18,
      left: 32,
      width: 1,
      // height: '100%',
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[props.cluster.type] || "#666",
      zIndex: 1,
    },
  }),
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  cluster: IPageCluster;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const PublishClusterRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { cluster, selectedWebsite, sortField } = props;

  const pages = React.useMemo(() => {
    const publishedPages = [];
    const otherPages = cluster.pages;

    const pages1 = otherPages.reduce((pagesAcc, page) => {
      const index = findIndex(publishedPages, (p: IPage) => p.locale === page.locale && p.variant === page.variant);
      if (index > -1) {
        publishedPages[index].children = [...(publishedPages[index].children || []), page];
      } else {
        pagesAcc.push(page);
      }
      return pagesAcc;
    }, []);

    publishedPages.push(...(pages1 || []));

    return _.orderBy(publishedPages, [sortField.field], [sortField.direction]);
  }, [selectedWebsite, cluster]);
  const totalPages = pages.length;

  if (pages.length === 1) {
    return (
      <ListItem className={classes.listItem}>
        <PublishRow key={pages[0]._id} page={pages[0]} main showBorder={!pages[0].children} />
        {pages[0].children && pages[0].children.length > 0 && (
          <List className={classes.itemWrapper}>
            {pages[0].children.map((childPage, childIndex) => (
              <PublishRow
                key={childPage._id}
                page={childPage}
                main={false}
                child
                showBorder={childIndex === pages[0].children.length - 1}
              />
            ))}
          </List>
        )}
      </ListItem>
    );
  }

  return (
    <ListItem className={ClassNames(classes.listItem, classes.directory)}>
      {pages.map((page, index) => (
        <>
          {page && (
            <PublishRow
              key={page._id}
              page={page}
              main={index === 0}
              showBorder={index === totalPages - 1 && !page.children}
            />
          )}
          {page.children && page.children.length > 0 && (
            <List className={classes.itemWrapper}>
              {page.children.map((childPage, childIndex) => (
                <ListItem className={classes.listItem}>
                  <PublishRow
                    key={childPage._id}
                    page={childPage}
                    main={false}
                    child
                    showBorder={index === totalPages - 1 && childIndex === page.children.length - 1}
                  />
                </ListItem>
              ))}
            </List>
          )}
          {!page && <span>{"Error"}</span>}
        </>
      ))}
    </ListItem>
  );
};

export default connect(mapStateToProps)(PublishClusterRow);
