import React from "react";
import { Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClassNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },

    buttonOutlined: (props: propTypes) => ({
      borderRadius: props.height ? props.height / 2 : 23,
      textTransform: "capitalize",
      color: props.gray ? theme.palette.text.secondary : theme.palette.primary.main,
      fontWeight: props.gray ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
      display: "flex",
      flexDirection: "column",
      minHeight: props.height || 45,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(14),
      paddingRight: 30,
      paddingLeft: 30,
      backgroundColor: "transparent",
      borderColor: props.gray ? theme.palette.text.primary : theme.palette.primary.main,
      borderStyle: "solid",
      borderWidth: 1,

      transition: "all 1s cubic-bezier(.175,.885,.32,1)",
      webkitTransition: "all 1s cubic-bezier(.175,.885,.32,1)",

      "&:hover": {
        backgroundColor: props.gray ? theme.palette.text.primary : theme.palette.primary.main,
        color: props.gray ? "white" : "white",
      },
      "&:disabled": {
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark,
      },
    }),

    button: (props: propTypes) => ({
      borderRadius: props.height ? props.height / 2 : 23,
      textTransform: "capitalize" as const,
      color: "white",
      display: "flex",
      flexDirection: "column" as const,
      minHeight: props.height || 45,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(14),
      paddingRight: 20,
      paddingLeft: 20,
      fontWeight: theme.typography.fontWeightBold as any,
      backgroundColor: theme.palette.primary.main,

      transition: "all 1s cubic-bezier(.175,.885,.32,1)",
      webkitTransition: "all 1s cubic-bezier(.175,.885,.32,1)",

      "&:hover": {
        backgroundColor: "#5967FF",
      },
      "&:disabled": {
        color: "white",
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    fullWidth: {
      width: "100%",
    },
    progressBarWrapper: {
      minWidth: 40,
      position: "relative",
      paddingTop: 5,
      paddingBottom: 5,
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      marginTop: -15,
      marginLeft: -15,
    },
  })
);

type propTypes = {
  text: string;

  outlined?: boolean;
  gray?: boolean;
  loading?: boolean;
  onClick?: Function;
  disabled?: boolean;
  navLink?: boolean;
  href?: string;
  target?: string;
  customClass?: any;
  height?: number;
  component?: any;
  ref?: any;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};

const CfButton = (props: propTypes) => {
  const classes = useStyles(props);
  const {
    disabled,
    onClick,
    component,
    href,
    ref = null,
    target = "_self",
    text,
    loading = false,
    navLink = false,
    customClass,
    outlined = false,
    fullWidth = false,
    startIcon,
    endIcon,
  } = props;

  const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  return (
    <div className={ClassNames(classes.wrapper, fullWidth && classes.fullWidth)}>
      {!loading && (
        <Button
          disableRipple
          className={ClassNames(
            outlined ? classes.buttonOutlined : classes.button,
            fullWidth && classes.fullWidth,
            customClass || null
          )}
          disabled={disabled || loading}
          onClick={(event) => onClick(event)}
          component={navLink ? Link : "button"}
          target={target}
          href={href}
          to={href}
          endIcon={endIcon}
          startIcon={startIcon}
          ref={ref}
        >
          {text}
        </Button>
      )}
      {loading && (
        <div className={ClassNames(classes.progressBarWrapper, customClass)}>
          <CircularProgress size={30} className={classes.buttonProgress} />
        </div>
      )}
    </div>
  );
};

export default CfButton;
