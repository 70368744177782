import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import anySite from "../../../../img/any_site.svg";
import wordpress from "../../../../img/wordpress.png";
import gtm from "../../../../img/gtm.png";
import shopify from "../../../../img/Shopify.png";
import webflow from "../../../../img/Webflow.png";
import wix from "../../../../img/Wix.png";
import weebly from "../../../../img/Weebly.png";
import joomla from "../../../../img/Joomla.png";
import hubspot from "../../../../img/Hubspot.png";
import drupal from "../../../../img/Drupal.png";
import magento from "../../../../img/Magento.png";
import big_commerce from "../../../../img/Bigcommerce.png";
import reactLogo from "../../../../img/React.png";
import nextJsLogo from "../../../../img/NextJS.png";
import { HOME } from "../../../../reducers/constants/actionTypes";
import { connect } from "react-redux";
import { eEntailProducts, eMpEvent, IWebsite } from "../../../../reducers/constants/objectTypes";
import WebsiteInstruction from "./components/WebsiteInstruction";
import { MIXPANEL_PAGE_VIEW } from "../../../../reducers/middlewares/mixpanelMiddleware";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: 677,
    minHeight: 445,
    maxWidth: "70%",
    width: "100%",
    padding: 0,
    paddingBottom: 0,
    position: "absolute",
    top: 98,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 24,
  },
  title: {
    margin: 0,
    fontSize: theme.typography.pxToRem(25),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  closeButton: {
    padding: 0,
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(25),
    cursor: "pointer",
    marginRight: "-5px",
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  contentWrapper: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowX: "hidden",
  },
  siteListWrapper: {
    padding: "0px 24px 24px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  sitesWrapper: {
    display: "grid",
    gap: 10,
    gridTemplateColumns: "repeat(4, 1fr)",
    marginBottom: 10,
  },
  cardWrapper: {
    padding: "20px 15px 15px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EEF2F9",
    alignItems: "center",
    gap: 10,
    borderRadius: 3,
    border: `1px solid ${theme.palette.divider}`,
    justifyContent: "end",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E6EEFF",
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  logo: {
    width: 57.6,
    height: 50,
    objectFit: "contain",
  },
  hidden: {
    visibility: "hidden",
  },
  siteTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  selectText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
    margin: "12px 0px",
    color: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  handlePropertyChange: (property: any) => void;
  showInstructionsDialog: boolean;
  siteDialog: string | null;
  selectedWebsite: IWebsite;
  pageLoaded: Function;
  mpEvent: (event: string, props: object) => void;
};

const mapStateToProps = (state) => ({
  showInstructionsDialog: state.home.showInstructionsDialog,
  siteDialog: state.home.siteDialog,
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  handlePropertyChange: (property: any) => {
    dispatch({ type: HOME.ON_HOME_PROPERTY_CHANGED, property });
  },
  pageLoaded: () =>
    dispatch({
      type: HOME.PAGE_LOADED,
      meta: {
        mixpanel: {
          event: MIXPANEL_PAGE_VIEW,
          props: { "page-name": "Install Entail CRO", product: eEntailProducts.cro },
        },
      },
    }),
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
});

const IntegrationInstructions = (props: PropTypes) => {
  const { handlePropertyChange, showInstructionsDialog, siteDialog, selectedWebsite, pageLoaded, mpEvent } = props;

  React.useEffect(() => {
    pageLoaded();
  }, []);

  const classes = useStyles(props);

  const list = [
    {
      title: I18n.t("onboarding.integration.sites.any_site"),
      img: anySite,
      key: "any_site",
    },
    {
      title: I18n.t("onboarding.integration.sites.gtm"),
      img: gtm,
      key: "gtm",
    },
    { hide: true },
    { hide: true },
    {
      title: I18n.t("onboarding.integration.sites.wordpress"),
      img: wordpress,
      key: "wordpress",
    },
    {
      title: I18n.t("onboarding.integration.sites.shopify"),
      img: shopify,
      key: "shopify",
    },
    {
      title: I18n.t("onboarding.integration.sites.web_flow"),
      img: webflow,
      key: "web_flow",
    },
    {
      title: I18n.t("onboarding.integration.sites.wix"),
      img: wix,
      key: "wix",
    },
    {
      title: I18n.t("onboarding.integration.sites.weebly"),
      img: weebly,
      key: "weebly",
    },
    {
      title: I18n.t("onboarding.integration.sites.joomla"),
      img: joomla,
      key: "joomla",
    },
    {
      title: I18n.t("onboarding.integration.sites.hub_spot"),
      img: hubspot,
      key: "hub_spot",
    },
    {
      title: I18n.t("onboarding.integration.sites.drupal"),
      img: drupal,
      key: "drupal",
    },
    {
      title: I18n.t("onboarding.integration.sites.magento"),
      img: magento,
      key: "magento",
    },
    {
      title: I18n.t("onboarding.integration.sites.big_commerce"),
      img: big_commerce,
      key: "big_commerce",
    },
    {
      title: I18n.t("onboarding.integration.sites.react"),
      img: reactLogo,
      key: "react",
    },
    {
      title: I18n.t("onboarding.integration.sites.next_js"),
      img: nextJsLogo,
      key: "next_js",
    },
  ];

  const openSiteDialog = (key: string) => {
    handlePropertyChange({
      siteDialog: key,
    });
  };
  const renderSitesList = () => {
    return (
      <div className={classes.siteListWrapper}>
        <Typography className={classes.selectText}> {I18n.t("onboarding.integration.select_site")}</Typography>
        <div className={classes.sitesWrapper}>
          {list.map((item, index) => {
            if (item.hide) return <div className={classes.hidden}></div>;
            return (
              <div
                key={`site_card_${item.key}`}
                className={classes.cardWrapper}
                onClick={() => {
                  mpEvent(eMpEvent.croInstallButtonClick, { type: item.title, text: `Selected ${item.title}` });
                  openSiteDialog(item.key);
                }}
              >
                <img src={item.img} alt={item.title} />
                <Typography className={classes.siteTitle}>{item.title}</Typography>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={showInstructionsDialog}
      fullWidth
      classes={{
        paper: classes.wrapper,
      }}
    >
      <DialogTitle disableTypography className={classes.titleWrapper}>
        <Typography color={"inherit"} className={classes.title} variant={"h3"}>
          {I18n.t("onboarding.integration.title")}
        </Typography>
        <CloseIcon
          onClick={() =>
            handlePropertyChange({
              showInstructionsDialog: false,
              siteDialog: null,
            })
          }
          className={classes.closeIcon}
        />
      </DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        {siteDialog ? (
          <WebsiteInstruction
            handleCopyScript={() => {
              mpEvent(eMpEvent.croInstallButtonClick, { type: "installation", text: "Copy Script" });
            }}
            site={siteDialog}
            entailToken={selectedWebsite?.configurations?.analytics?.entailToken}
            handleClose={() => {
              handlePropertyChange({
                siteDialog: null,
                showInstructionsDialog: true,
              });
            }}
          />
        ) : (
          renderSitesList()
        )}
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationInstructions);
