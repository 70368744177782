import React from "react";
import { Button, Theme, Typography } from "@material-ui/core";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  header: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftContainer: (props: PropTypes) => ({
    display: "flex",
    alignItems: "center",
    maxWidth: 300,
    color: palette.text.primary,
    "&:hover": {
      cursor: props.onBackClick ? "pointer" : "initial",
    },
  }),
  rightContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    maxWidth: 300,
    gap: 10,
  },
  button: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    fontSize: 14,
    textTransform: "none",
    height: 30,
    padding: 10,
    borderRadius: "20px",
    fontWeight: typography.fontWeightMedium as any,
    "&:hover": {
      backgroundColor: palette.primary.light,
      color: palette.common.white,
    },
    "&:disabled": {
      borderColor: palette.primary.dark,
      color: palette.primary.dark,
      backgroundColor: palette.action.selected,
      cursor: "initial",
    },
  },
  leftArrow: {
    marginLeft: -8,
  },
  label: {
    fontSize: 14,
    fontWeight: typography.fontWeightMedium as any,
  },
}));

type PropTypes = {
  title: string;
  buttonProps?: {
    onClick: () => void;
    disabled?: boolean;
  };
  rightContent?: React.ReactNode;
  onBackClick?: () => void;
};

const RuleBuilderHeader = (props: PropTypes) => {
  const { title, rightContent, buttonProps } = props;
  const { onBackClick } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.header}>
      <div className={classes.leftContainer} onClick={onBackClick}>
        {onBackClick && <KeyboardArrowLeftRounded className={classes.leftArrow} />}
        <Typography className={classes.label}>{title}</Typography>
      </div>
      {buttonProps && (
        <div className={classes.rightContainer}>
          {rightContent}
          <Button onClick={buttonProps.onClick} className={classes.button} disabled={buttonProps?.disabled}>
            {I18n.t("widgets_display_rules_editor.builder.common.apply")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default RuleBuilderHeader;
