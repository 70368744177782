import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import UserEventsProfile from "./components/UserEventsProfile";
import UserEventsActivityFeed from "./components/UserEventsActivityFeed";
import {
  IAnalyticsDevice,
  IAnalyticsEvent,
  IAnalyticsSession,
  IWebsite,
} from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";

import UserEventsSessions from "./components/UserEventsSessions";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: 20,
    padding: "20px 10px 0",
    height: "calc(100% - 30px)",
  },
  leftCol: {
    width: "25vw",
  },
  rightCol: {
    width: "71vw",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

type PropTypes = {
  selectedWebsite: IWebsite;

  openUserProfile: (distinctId: string) => void;
};

const UserEvents = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, openUserProfile } = props;
  const [searchText, setSearchText] = React.useState("");
  const [loaded, setLoaded] = React.useState(false);
  const [events, setEvents] = React.useState<IAnalyticsEvent[]>([]);
  const [sessions, setSessions] = React.useState<IAnalyticsSession[]>([]);
  const [devices, setDevices] = React.useState<IAnalyticsDevice[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { distinctId } = useParams();

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    setLoaded(false);
    agent.Analytics.getUserEvents(selectedWebsite._id, distinctId)
      .then((res) => {
        setEvents(res.events);
        setSessions(res.sessions);
        setDevices(res.devices);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        console.log("e", e);
      });
  }, [selectedWebsite, distinctId]);

  return (
    <div className={classes.mainWrapper}>
      <MHeaderMetaTags title={I18n.t(`meta_tags.cro.events.title`)} />
      <div className={classes.leftCol}>
        <UserEventsProfile loaded={loaded} device={devices[0]} lastSession={sessions?.[0]} />
        <UserEventsSessions loaded={loaded} sessions={sessions} />
      </div>
      <div className={classes.rightCol}>
        <UserEventsActivityFeed loaded={loaded} events={events} sessions={sessions} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEvents);
