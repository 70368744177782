import React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";

import { IWidgetFieldProperties, IWidgetCouponFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "./components/GenericValueItemProperty";

type PropTypes = {
  properties: IWidgetFieldProperties;
  onChange: (properties: IWidgetCouponFieldProps) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

const CouponWidgetItem = (props: PropTypes) => {
  const { onChange } = props;
  const properties = props.properties as IWidgetCouponFieldProps;
  const { code, clickToCopyText, copiedText } = properties;

  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, code: value })}
        value={code}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.coupon_code`)}
      />
      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, clickToCopyText: value })}
        value={clickToCopyText}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.click_to_copy_text`)}
      />
      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, copiedText: value })}
        value={copiedText}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.copied_text`)}
      />
    </div>
  );
};

export default CouponWidgetItem;
