import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { ePostState, ITag, IWebsite } from "../../../../../reducers/constants/objectTypes";
import { MANAGE_CONTENT } from "../../../../../reducers/constants/actionTypes";
import { AbilityContext } from "../../../../../casl/can";
import agent from "../../../../../agent";
import MSavedXMinutesAgo from "../../../../../components/MSavedXMinutesAgo";
import MTextCounter from "../../../../../components/MTextCounter";
import MButtonGroup from "../../../../../components/MButtonGroup";
import DeleteTagDialog from "./DeleteTagDialog";

const useStyles = makeStyles((theme: Theme) => ({
  postBottomBarWrapper: {
    zIndex: 3,
    height: 65,
    minHeight: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    borderTop: `${theme.palette.divider} solid 1px`,
    position: "sticky",
    bottom: 0,
  },
  postBottomBarInnerWrapper: {
    // width: 'calc(100% - 2*280px)',
    width: "100%",
    paddingRight: 30,
    paddingLeft: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    minHeight: 40,
  },
  discardButton: {
    marginRight: 20,
  },
  postTypesWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textCounter: {
    marginRight: 20,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  tagsLoaded: boolean;
  tag: ITag;

  returnToPreviousScreen: Function;
  handleTagChanged: Function;
  handleTagDeleted: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  tagsLoaded: state.manageContent.tagsLoaded,
  tag: state.manageContent.selectedTag,
});

const mapDispatchToProps = (dispatch) => ({
  handleTagChanged: (tag) => dispatch({ type: MANAGE_CONTENT.ON_TAG_UPDATED, tag }),
  handleTagDeleted: (tag) => dispatch({ type: MANAGE_CONTENT.ON_TAG_DELETED, tag }),
});

const TagPageBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, tag, returnToPreviousScreen, handleTagChanged, handleTagDeleted } = props;
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ability = React.useContext<any>(AbilityContext);

  const actions = [];
  if (ability.can("publish", "topic")) {
    actions.push({
      key: "submit_for_writers",
      label: I18n.t("edit_post.submit_for_writers"),
    });
  }
  if (
    (!tag.state ||
      tag.state === ePostState.NEW ||
      tag.state === ePostState.DRAFT ||
      tag.state === ePostState.REJECTED ||
      tag.state === ePostState.PUBLISH ||
      tag.state === ePostState.UPDATE ||
      tag.state === ePostState.INTERNAL_REVIEW ||
      tag.state === ePostState.DELETED) &&
    ability.can("publish", "topic")
  ) {
    actions.push({
      key: "publish",
      label: I18n.t("edit_post.publish"),
    });
  }

  if (ability.can("delete", "topic")) {
    actions.push({
      key: "delete",
      label: I18n.t("edit_post.delete"),
    });
  }

  if (
    !tag.state ||
    tag.state === ePostState.UNINITIALIZED ||
    tag.state === ePostState.NEW ||
    tag.state === ePostState.DRAFT ||
    tag.state === ePostState.REJECTED ||
    tag.state === ePostState.DELETED
  ) {
    actions.push({
      key: "save_draft",
      label: I18n.t("edit_post.save_draft"),
    });
  }

  if (tag.state === ePostState.INTERNAL_REVIEW) {
    actions.push({
      key: "save",
      label: I18n.t("edit_post.save"),
    });
  }

  if (
    (tag.state === ePostState.PUBLISHED || tag.state === ePostState.PUBLISH || tag.state === ePostState.UPDATE) &&
    ability.can("update", "topic")
  ) {
    actions.push({
      key: "update",
      label: I18n.t("edit_post.update"),
    });
  }

  const updateTag = (tagToUpdate) => {
    setSaveInProgress(true);
    agent.Tags.update(tagToUpdate)
      .then((res) => {
        console.log("res", res);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
        handleTagChanged(res.tag);
        setSaveInProgress(false);
      })
      .catch((err) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: err.msg }));
        setSaveInProgress(false);
      });
  };

  const handleDeleteClicked = () => {
    setDeleteDialogOpen(true);
  };

  const handleActionClick = (type) => {
    console.log("handleActionClick", type);
    switch (type) {
      case "submit_for_writers":
        updateTag({ ...tag, state: ePostState.NEW });
        break;
      case "save":
        updateTag({ ...tag, state: tag.state || ePostState.NEW });
        break;
      case "update":
        updateTag({ ...tag, state: ePostState.UPDATE });
        break;
      case "publish":
        updateTag({ ...tag, state: ePostState.PUBLISH });
        break;
      case "delete":
        handleDeleteClicked();
        // updateTag({ ...tag, state: ePostState.DELETE });
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.postBottomBarWrapper}>
      <div className={classes.postBottomBarInnerWrapper}>
        <div className={classes.flexWrapper}>
          <MSavedXMinutesAgo lastSaveTime={tag.updatedAt} />
        </div>
        <div className={classes.flexWrapper}>
          <MTextCounter
            wrapperClass={classes.textCounter}
            type={"word"}
            text={tag.text || ""}
            suffix={I18n.t("writers.write_answer.words")}
          />
          {actions[0] && (
            <MButtonGroup
              text={actions[0].label}
              onClick={() => handleActionClick(actions[0].key)}
              options={actions}
              onMenuItemClick={(key: string) => handleActionClick(key)}
            />
          )}
        </div>
      </div>
      {deleteDialogOpen && (
        <DeleteTagDialog
          open={deleteDialogOpen}
          tag={tag}
          selectedWebsite={selectedWebsite}
          onTagDeleted={(tag) => {
            setDeleteDialogOpen(false);
            handleTagDeleted(tag);
          }}
          handleCloseClick={() => setDeleteDialogOpen(false)}
        />
      )}
      {/* {discardDialogOpen && ( */}
      {/*  <MAlertDialog */}
      {/*    open={discardDialogOpen} */}
      {/*    title={I18n.t('edit_post.discard_dialog.title')} */}
      {/*    description={I18n.t('edit_post.discard_dialog.description')} */}
      {/*    agreeText={I18n.t('edit_post.discard_dialog.agree')} */}
      {/*    disagreeText={I18n.t('edit_post.discard_dialog.cancel')} */}
      {/*    handleClose={(agree) => { */}
      {/*      setDiscardDialogOpen(false); */}
      {/*      if (agree) { */}
      {/*        discardAnswer(); */}
      {/*      } */}
      {/*    }} */}
      {/*  /> */}
      {/* )} */}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TagPageBottomBar);
