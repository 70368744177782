import { ePageType } from "./objectTypes";

export const PROD = (process.env.NODE_ENV || "production") === "production";
export const DEV = (process.env.NODE_ENV || "development") === "development";
export const STAGING = (process.env.TARGET_ENV || "staging") === "staging";

export const LENGTHS = {
  META_TITLE: 70,
  META_DESCRIPTION: 160,
};
export const C = {
  C: "C",
};

export const DATE_FORMAT = {
  CALENDAR: "YYYY-MM-DD",
  READABLE: "MMM D, YY",
  READABLE_MONTH_YEAR: "MMM, YY",
  READABLE_CHART: "MMM D",
  MONTH_YEAR: "MM/YYYY",
  DAY_MONTH_YEAR: "DD/MM/YYYY",
  COMPLETE: "MMMM Do, YYYY",
  TIME: "h:mm:ss a",
  DATETIME: "YYYY-MM-DD HH:mm:ss",
};

export const STATE_COLORS = {
  Writer: "green",
  Review: "green",
  FactCheck: "green",
  Deleted: "red",
  Published: "red",
  Archived: "red",
};

export const PAGE_TYPE_COLORS = {
  [ePageType.Blog]: "#808AFE",
  [ePageType.Targeted]: "#FF84BC",
  [ePageType.Topic]: "#2A9702",
  [ePageType.Comparison]: "#EC5B5B",
  [ePageType.LearningCenter]: "#9F64CE",
  [ePageType.ProductAlternative]: "#EB9546",
  [ePageType.ProductComparison]: "#ACCE64",
  [ePageType.ProductReview]: "#64CEBB",
  [ePageType.ProductPage]: "#7364CE",
};
