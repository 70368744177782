import * as React from "react";
import { useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ClassNames from "classnames";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import { findLast } from "lodash";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Search } from "@material-ui/icons";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { eActionIcon, ePostType, eUserRoles, IPage, IWebsite } from "../../../../reducers/constants/objectTypes";
import { clientPages, getSearchConsoleUrl, web } from "../../../../helpers/urlHelper";
import { PAGE_TYPE_COLORS } from "../../../../reducers/constants/consts";
import localeHelper from "../../../../helpers/localeHelper";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import { copyStringToClipboard } from "../../../../helpers/fbScraperHelper";
import CopyNameIcon from "../../../../icons/CopyNameIcon";
import CopyToClipboardIcon from "../../../../icons/CopyToClipboardIcon";
import MTableCellDate from "../../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MTableCellNumberProportion from "../../../../components/Table/Cells/MTableCellNumberProportion";
import MTableCellLink from "../../../../components/Table/Cells/MTableCellLink";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";
import MTableCellStatus from "../../../../components/Table/Cells/MTableCellStatus";
import ability from "../../../../casl/ability";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $topicWrapper": {
        paddingTop: 1,
        borderBottom: "1px solid transparent",
      },
      "& $iconColumn": {
        paddingTop: 1,
      },
      "& $actions": {
        display: "flex",
      },
    },
  },

  childPage: (props: PropTypes) => ({
    "&:before": {
      position: "absolute",
      top: 16,
      left: 51,
      width: 10,
      height: 1,
      margin: "auto",
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[props.page.type] || "#666",
    },
    "&:after": {
      position: "absolute",
      top: -9,
      bottom: 0,
      left: 51,
      width: 1,
      height: 25,
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[props.page.type] || "#666",
      zIndex: 1,
    },
  }),
  subDirectory: (props: PropTypes) => ({
    height: 34,
    "&:before": {
      position: "absolute",
      marginTop: "-1px",
      left: 32,
      width: 10,
      height: 1,
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[props.page.type] || "#666",
    },
    // '&:after': {
    // 	position: 'absolute',
    // 	top: 36,
    // 	// bottom: 0,
    // 	left: 28,
    // 	width: 1,
    // 	height: '100%',
    // 	content: "''",
    // 	backgroundColor: '#666',
    // 	zIndex: 1,
    // },
  }),

  iconColumnPh: {
    width: 40,
  },
  actions: {},
  writerName: {},
  tooltip: {
    whiteSpace: "pre-wrap",
  },
}));

type PropTypes = {
  main: boolean;
  showBorder: boolean;
  child?: boolean;
  selectedWebsite: IWebsite;
  page: IPage;
  editPost: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  editPost: (articleId, currentUrl) => dispatch(push(web.editPage(articleId, currentUrl))),
});

const PageRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { main, child = false, page, selectedWebsite, editPost } = props;
  const location = useLocation();
  const theme: any = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const status = useMemo(
    () =>
      (
        findLast(page.cluster.publishHistory, (ph) => ph.locale === page.locale && ph.variant === page.variant)
          ?.publishStatus || "UNKNOWN"
      ).toLowerCase(),
    [page]
  );

  const url = clientPages.getPageUrl(page, selectedWebsite);
  const actions = React.useMemo(() => {
    const values = [];
    if (url) {
      values.push(
        ...[
          {
            text: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
            tooltip: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
            onClick: () => window.open(url, "_blank"),
            iconName: eActionIcon.openInNewTab,
          },
          {
            text: I18n.t(`learning_center_content.tooltip.google_search_console`),
            tooltip: I18n.t(`learning_center_content.tooltip.google_search_console`),
            onClick: () => window.open(getSearchConsoleUrl(url), "_blank"),
            iconName: eActionIcon.search,
          },
        ]
      );
    }
    values.push({
      text: I18n.t(`learning_center_content.tooltip.copy_title`),
      tooltip: I18n.t(`learning_center_content.tooltip.copy_title`),
      onClick: () => copyStringToClipboard(page.title, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
      iconName: eActionIcon.copyName,
    });
    if (url) {
      values.push({
        text: I18n.t(`learning_center_content.tooltip.copy_url`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_url`),
        onClick: () => copyStringToClipboard(url, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
        iconName: eActionIcon.copyToClipboard,
      });
    }
    return values;
  }, [page.title]);

  return (
    <MTableRow
      handleRowClick={() => {
        editPost(page._id, location.pathname);
      }}
      customClassName={ClassNames(
        classes.itemWrapper,
        child && classes.childPage,
        !child && !main && classes.subDirectory
      )}
    >
      <MTableCellFirst
        columnWidth={"calc(34% - 40px)"}
        fontSize={theme.typography.pxToRem(!main ? 12 : 14)}
        avatarType={main ? (page.type as ePostType) : null}
        prefix={!main ? <div className={classes.iconColumnPh} /> : null}
        cellText={page.title || "Missing Title !"}
        actions={actions}
        actionClassName={classes.actions}
      />

      <MTableCellDate date={page.firstPublishDate} />
      <MTableCellDate date={page.publishedDate} />
      <MTableCellDate date={page.quotaDate} />

      <MTableCellText text={localeHelper.getLanguageFromLocaleCode(page.locale)} />
      <MTableCellNumberProportion value1={page.textWordsCount} value2={page.outline?.wordCount || 1000} />
      <MTableCellLink text={page.categories[0]?.name} href={page.categories[0]?.name} />
      <MTableCellNumber value={page.linksCount} />
      <MTableCellText text={page.imported ? "✓" : "✘"} />
      <MTableCellText text={page.writer?.name} />
      <MTableCellStatus status={status} />
      <MTableCellText text={page.publishedBy?.name} />
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageRow);
