import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import SwitchBaseComponent from "../BaseComponents/SwitchBaseComponent";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: 16,
    paddingTop: 16,
    borderTop: (props: PropTypes) => (props.divider ? `${theme.palette.divider} solid 1px` : "none"),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 14,
    color: theme.palette.text.primary,
    marginBottom: 7,
    lineHeight: "normal",
  },
  titleShowWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

type PropTypes = {
  className?: string;
  divider?: boolean;
  sectionTitle: string;

  show?: boolean;
  handleShowChange?: (show: boolean) => void;
  children: React.ReactElement | React.ReactElement[];
};

const StylePanelSubSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { sectionTitle, className, children } = props;
  const { show, handleShowChange } = props;

  return (
    <div className={classNames(classes.wrapper, className)}>
      <div className={classes.titleShowWrapper}>
        <Typography className={classes.title}>{sectionTitle}</Typography>
        {handleShowChange && (
          <SwitchBaseComponent defaultValue={true} value={show} handleChange={(value) => handleShowChange(value)} />
        )}
      </div>
      {children}
    </div>
  );
};

export default StylePanelSubSection;
