import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import set from "lodash/set";
import {
  eBlogTemplate,
  IWebsite,
  IWebsiteDynamicSectionProps,
  SNWebsiteAutoCompleteMetaTag,
} from "../../../../../../../reducers/constants/objectTypes";
import TextInputToolAndDesign from "../../../../../components/TextInputToolAndDesign";
import TextFieldMaxLength from "../../../../../../../components/TextFieldMaxLength";
import { WEBSITE_PAGES } from "../../../../../../../reducers/constants/actionTypes";
import TextInputMentions from "../../../../../components/TextInputMentions";
import { LENGTHS } from "../../../../../../../reducers/constants/consts";
import TextDesignToolWithPreview from "../../../../../components/TextDesignToolWithPreview";
import PageSection from "../../../../../components/PageSection";
import DefaultBlogTemplate from "./templates/DefaultBlogTemplate";
import MaypleBlogTemplate from "./templates/MaypleBlogTemplate";
import SafeBooksPageBlog from "./templates/SafeBooksPageBlog";
import JitPageBlog from "../../../Blog/templates/JitPageBlog";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (dynamicSection) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED, name: "dynamicSection", value: dynamicSection }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  dynamicSection: IWebsiteDynamicSectionProps;
  emptyFields: string[];
  onPropertyChanged: (dynamicSection) => void;
  locale: string;
  defaultLocale: string;
  website: IWebsite;
};

const BlogTemplate = (props: PropTypes) => {
  const classes = useStyles(props);
  const { dynamicSection, onPropertyChanged, emptyFields, locale, defaultLocale, website } = props;
  const [categoryPageEmptyFields, setCategoryPageEmptyFields] = React.useState([]);

  const categoryPageValidated = () => {
    let valid = true;
    const requiredFields = [];
    setCategoryPageEmptyFields(requiredFields);
    if (!dynamicSection.categoryPage.slug) {
      valid = false;
      requiredFields.push(I18n.t(`website.blog.category_page_slug`));
    }
    if (!dynamicSection.categoryPage.metaTitle) {
      valid = false;
      requiredFields.push(I18n.t(`website.blog.category_page_meta_title`));
    }
    if (!dynamicSection.categoryPage.metaDescription) {
      valid = false;
      requiredFields.push(I18n.t(`website.blog.category_page_meta_description`));
    }
    setCategoryPageEmptyFields(requiredFields);
    return valid;
  };

  return (
    <>
      {dynamicSection.blogTemplate === "default" && (
        <DefaultBlogTemplate
          onPropertyChanged={onPropertyChanged}
          dynamicSection={dynamicSection}
          emptyFields={emptyFields}
          locale={locale}
          defaultLocale={defaultLocale}
        />
      )}
      {dynamicSection.blogTemplate === "mayple" && (
        <MaypleBlogTemplate
          dynamicSection={dynamicSection}
          emptyFields={emptyFields}
          locale={locale}
          defaultLocale={defaultLocale}
          onPropertyChanged={onPropertyChanged}
          website={website}
        />
      )}
      {dynamicSection.blogTemplate === eBlogTemplate.safebooks && (
        <SafeBooksPageBlog
          dynamicSection={dynamicSection}
          emptyFields={emptyFields}
          locale={locale}
          defaultLocale={defaultLocale}
          onPropertyChanged={onPropertyChanged}
          website={website}
        />
      )}
      {dynamicSection.blogTemplate === eBlogTemplate.jit && (
        <JitPageBlog
          pageType={dynamicSection.type}
          jitTemplate={
            dynamicSection.jitTemplate ||
            ({
              mainPage: {},
            } as any)
          }
          jitTemplateTranslation={dynamicSection.translations?.[locale]?.jitTemplate || {}}
          onPropertyChanged={(value) => {
            onPropertyChanged({ ...dynamicSection, ...value });
          }}
          onTranslationChanged={(value) => {
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              jitTemplate: value,
            };
            onPropertyChanged({ ...dynamicSection });
          }}
          locale={locale}
          defaultLocale={defaultLocale}
          emptyFields={emptyFields}
          selectedWebsite={website}
        />
      )}

      {dynamicSection.blogTemplate !== eBlogTemplate.jit && (
        <PageSection
          checked={dynamicSection.categoryPage.enabled}
          onCheckedChange={(checked) => {
            if (!categoryPageValidated()) {
              if (!checked && dynamicSection.categoryPage.enabled) {
                onPropertyChanged({
                  ...dynamicSection,
                  categoryPage: { ...dynamicSection.categoryPage, enabled: checked },
                });
              }
              return;
            }
            onPropertyChanged({
              ...dynamicSection,
              categoryPage: { ...dynamicSection.categoryPage, enabled: checked },
            });
          }}
          sectionTitle={I18n.t("website.new_content_page.category_page")}
        >
          {categoryPageEmptyFields.length > 0 && (
            <div className={classes.errorWrapper}>
              <Typography variant={"body1"} className={classes.error}>
                {I18n.t("website.error_message")}
              </Typography>
            </div>
          )}
          <TextFieldMaxLength
            prefix={
              locale === defaultLocale
                ? dynamicSection.mainPage.slug
                : dynamicSection.translations[locale]?.mainPage?.slug || ""
            }
            error={categoryPageEmptyFields.includes(I18n.t(`website.blog.category_page_slug`))}
            titleAndLengthLocation={"top"}
            value={
              locale === defaultLocale
                ? dynamicSection.categoryPage.slug
                : dynamicSection.translations[locale]?.categoryPage?.slug || ""
            }
            placeholder={I18n.t("website.slug_title_ph")}
            title={I18n.t("website.slug_title")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...dynamicSection, categoryPage: { ...dynamicSection.categoryPage, slug: value } });
                return;
              }
              dynamicSection.translations[locale] = {
                ...(dynamicSection.translations[locale] || {}),
                categoryPage: { ...(dynamicSection.categoryPage || {}), slug: value },
              };
              onPropertyChanged({ ...dynamicSection });
            }}
          />

          <TextInputMentions
            tags={[SNWebsiteAutoCompleteMetaTag.categoryName]}
            key={`dynamic_section_category_page_meta_title_${locale}`}
            placeholder={I18n.t("website.meta_title_ph")}
            title={I18n.t("website.meta_title")}
            maxLength={LENGTHS.META_TITLE}
            error={categoryPageEmptyFields.includes(I18n.t(`website.blog.category_page_meta_title`))}
            titleAndLengthLocation={"top"}
            text={
              locale === defaultLocale
                ? dynamicSection.categoryPage.metaTitle
                : dynamicSection.translations[locale]?.categoryPage?.metaTitle || ""
            }
            onTextChanged={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({
                  ...dynamicSection,
                  categoryPage: { ...dynamicSection.categoryPage, metaTitle: value },
                });
                return;
              }
              dynamicSection.translations[locale] = {
                ...(dynamicSection.translations[locale] || {}),
                "categoryPage.metaTitle": value,
              };
              onPropertyChanged({ ...dynamicSection });
            }}
          />

          <TextInputMentions
            error={categoryPageEmptyFields.includes(I18n.t(`website.blog.category_page_meta_description`))}
            key={`dynamic_section_category_page_meta_description_${locale}`}
            tags={[SNWebsiteAutoCompleteMetaTag.categoryName, SNWebsiteAutoCompleteMetaTag.categoryDescription]}
            placeholder={I18n.t("website.meta_description_ph")}
            title={I18n.t("website.meta_description")}
            maxLength={LENGTHS.META_DESCRIPTION}
            titleAndLengthLocation={"top"}
            text={
              locale === defaultLocale
                ? dynamicSection.categoryPage.metaDescription
                : dynamicSection.translations[locale]?.categoryPage?.metaDescription || ""
            }
            onTextChanged={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({
                  ...dynamicSection,
                  categoryPage: { ...dynamicSection.categoryPage, metaDescription: value },
                });
                return;
              }
              dynamicSection.translations[locale] = {
                ...(dynamicSection.translations[locale] || {}),
                "categoryPage.metaDescription": value,
              };
              onPropertyChanged({ ...dynamicSection });
            }}
          />
        </PageSection>
      )}

      <PageSection sectionTitle={I18n.t("website.new_content_page.post_page")}>
        <TextDesignToolWithPreview
          topTitle
          disableHover
          marginLeft={"-30px"}
          marginRight={"-30px"}
          elementTitle={I18n.t("website.element_design", { element: I18n.t("website.title") })}
          css={dynamicSection.postPage.title?.css}
          variant={"h1"}
          // website.glossary.example_title_text
          exampleText={I18n.t(`website.new_content_page.post_page_meta_title`, { name: dynamicSection.name || "Blog" })}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...dynamicSection,
              postPage: { ...dynamicSection.postPage, title: { ...dynamicSection.postPage.title, css: newCss } },
            });
          }}
        />
        <TextInputMentions
          error={emptyFields.includes(I18n.t(`website.blog.post_page_meta_title`))}
          key={`dynamic_section_post_page_meta_title_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.postTitle, SNWebsiteAutoCompleteMetaTag.year]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? dynamicSection.postPage.metaTitle
              : dynamicSection.translations[locale]?.postPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...dynamicSection, postPage: { ...dynamicSection.postPage, metaTitle: value } });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              "postPage.metaTitle": value,
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />

        <TextInputMentions
          tags={[SNWebsiteAutoCompleteMetaTag.postTitle, SNWebsiteAutoCompleteMetaTag.postMetaDescription]}
          placeholder={I18n.t("website.meta_description_ph")}
          key={`dynamic_section_post_page_meta_description_${locale}`}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          error={emptyFields.includes(I18n.t(`website.blog.post_page_meta_description`))}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? dynamicSection.postPage.metaDescription
              : dynamicSection.translations[locale]?.postPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                postPage: { ...dynamicSection.postPage, metaDescription: value },
              });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              "postPage.metaDescription": value,
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
        {dynamicSection.blogTemplate === eBlogTemplate.safebooks && (
          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            value={
              locale === defaultLocale
                ? dynamicSection.safebooksTemplate?.bottomWidgetId
                : dynamicSection.translations[locale]?.safebooksTemplate?.bottomWidgetId || ""
            }
            placeholder={I18n.t("website.blog.bottom_widget_id_ph")}
            title={I18n.t("website.blog.bottom_widget_id")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({
                  ...dynamicSection,
                  safebooksTemplate: {
                    ...dynamicSection.safebooksTemplate,
                    bottomWidgetId: value,
                  },
                });
                return;
              }
              dynamicSection.translations[locale] = {
                ...(dynamicSection.translations[locale] || {}),
                safebooksTemplate: {
                  ...(dynamicSection.translations[locale]?.safebooksTemplate || {}),
                  bottomWidgetId: value,
                },
              };
              onPropertyChanged({ ...dynamicSection });
            }}
          />
        )}
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogTemplate);
