import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { Paper } from "@material-ui/core";
import moment from "moment";
import { sumBy } from "lodash";
import numeral from "numeral";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from "../../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  dataSet: "impressions" | "clicks" | "positions";
  metrics: any[];
  labels: any[];
  hiddenAreas: any;
  loading: boolean;
  interval: "day" | "week" | "month" | "quarter";
};

const CustomTooltip = (tooltipProps: any) => {
  const { dataSet, active, payload, label, interval } = tooltipProps;
  if (active && payload && payload.length) {
    return (
      <Paper style={{ padding: 20, width: 200, fontSize: 12, lineHeight: "14px" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: 10 }}>
          <span style={{ fontWeight: theme.typography.fontWeightBold as any, fontSize: 16 }}>
            {getXLabel(moment(label, "YYYY-MM-DD"), interval)}
          </span>
        </div>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <span style={{ fontWeight: theme.typography.fontWeightBold as any, textTransform: "capitalize" }}>
            {dataSet}
          </span>
          <span style={{ fontWeight: theme.typography.fontWeightBold as any, textTransform: "capitalize" }}>
            {"Count"}
          </span>
        </div>
        {payload
          .slice(0)
          .reverse()
          .map((p) => (
            <div
              key={`tooltip.${dataSet}.${p.name}`}
              style={{ display: "flex", marginTop: 10, alignItems: "center", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    width: 10,
                    height: 7,
                    backgroundColor: p.fill,
                    marginRight: 8,
                  }}
                />
                <span>{p.name}</span>
              </div>
              <span style={{ fontWeight: theme.typography.fontWeightBold }}>{numeral(p.value).format("0,0[.]")}</span>
            </div>
          ))}
        <div style={{ borderTop: "1px solid #000000", marginTop: 10, paddingTop: 10 }}>
          <div style={{ display: "flex", marginTop: 10, alignItems: "center", justifyContent: "space-between" }}>
            <span style={{ fontWeight: theme.typography.fontWeightBold }}>{"Total"}</span>
            <span style={{ fontWeight: theme.typography.fontWeightBold }}>
              {numeral(sumBy(payload, (p: any) => p.value || 0)).format("0,0[.]")}
            </span>
          </div>
        </div>
      </Paper>
    );
  }

  return null;
};

const colors = [
  { stroke: "#333866", fill: "#636DD6", activeStroke: "#626DD6", activeFill: "#1F2566" },
  { stroke: "#262E80", fill: "#828AE0", activeStroke: "#828AE0", activeFill: "#2D3480" },
  { stroke: "#4D5399", fill: "#A4AAEB", activeStroke: "#A4AAEB", activeFill: "#4C5399" },
  { stroke: "#7D81B3", fill: "#C9CCF5", activeStroke: "#C9CCF5", activeFill: "#7479B2" },
  { stroke: "#B8B9CC", fill: "#F0F1FF", activeStroke: "#ECECFB", activeFill: "#A3A5CC" },
  { stroke: "#ddddeb", fill: "#fafaff", activeStroke: "#f5f5fa", activeFill: "#d1d2f0" },
];

const getXLabel = (momentDate, interval) => {
  switch (interval) {
    case "day":
      return `${momentDate.format("DD/MM")}`;
    case "week":
      return `${momentDate.format("DD/MM")} - ${moment.min(momentDate.add(1, "week"), moment()).format("DD/MM")}`;
    case "month":
      return `${momentDate.format("DD/MM")} - ${moment.min(momentDate.add(1, "month"), moment()).format("DD/MM")}`;
    case "quarter":
      return `${momentDate.format("DD/MM")} - ${moment.min(momentDate.add(1, "quarter"), moment()).format("DD/MM")}`;
    default:
      return `${momentDate.format("DD/MM")}`;
  }
};

const MetricsGraph = (props: PropTypes) => {
  const classes = useStyles(props);
  const { dataSet, labels, metrics, hiddenAreas, loading, interval } = props;

  return (
    <div style={{ height: "inherit", position: "relative" }}>
      <Backdrop
        style={{
          zIndex: 10,
          color: "#fff",
          position: "absolute",
          backgroundColor: "rgba(255,255,255,0.7)",
        }}
        open={loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <ResponsiveContainer width="100%" height="100%" maxHeight={460}>
        <AreaChart
          width={400}
          height={460}
          data={metrics}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} color={"#E5E5E5"} />
          <XAxis
            tickFormatter={(label) => getXLabel(moment(label), interval)}
            dataKey="date"
            axisLine={false}
            tickLine={false}
          />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip content={(props) => <CustomTooltip dataSet={dataSet} interval={interval} {...props} />} />

          {labels.map((label, index) => {
            if (hiddenAreas.has(`${dataSet}.${label}`)) {
              return null;
            }
            return (
              <Area
                key={`${dataSet}.${label}`}
                connectNulls
                type="monotone"
                name={label}
                dataKey={`${dataSet}.${label}`}
                stackId="0"
                stroke={colors[index].stroke}
                strokeWidth={3}
                fill={colors[index].fill}
                activeDot={{
                  stroke: colors[index].activeStroke,
                  strokeWidth: 4,
                  r: 6,
                  strokeDasharray: "",
                  fill: colors[index].activeFill,
                }}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MetricsGraph;
