import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#fff",
    fontSize: 16,
  },
}));

type PropTypes = {
  className?: string;
};

const BigTitleIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 12 13"}>
      <path d="M6.33594 13C6.03038 13 5.76814 12.8867 5.5492 12.6602C5.32973 12.4342 5.22 12.1635 5.22 11.8481V2.3038H1.95188C1.64632 2.3038 1.38408 2.19053 1.16514 1.96399C0.945672 1.738 0.835938 1.4673 0.835938 1.1519C0.835938 0.822785 0.945672 0.548523 1.16514 0.329114C1.38408 0.109705 1.64632 0 1.95188 0H10.72C11.0256 0 11.2881 0.112996 11.5075 0.338987C11.7265 0.565528 11.8359 0.836498 11.8359 1.1519C11.8359 1.4673 11.7265 1.738 11.5075 1.96399C11.2881 2.19053 11.0256 2.3038 10.72 2.3038H7.45188V11.8481C7.45188 12.1635 7.34241 12.4342 7.12347 12.6602C6.90401 12.8867 6.64149 13 6.33594 13Z" />
    </SvgIcon>
  );
};

export default BigTitleIcon;
