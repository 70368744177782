import classNames from "classnames";
import numeral from "numeral";
import { ListItem, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Search from "@material-ui/icons/Search";
import { copyStringToClipboard } from "../../../../../helpers/fbScraperHelper";
import {
  IKeyword,
  IKeywordGroup,
  IKeywordGroupMember,
  IKeywordPage,
  IOpportunity,
} from "../../../../../reducers/constants/objectTypes";
import CopyNameIcon from "../../../../../icons/CopyNameIcon";
import { getQuerySearchConsoleUrl } from "../../../../../helpers/urlHelper";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  actionCenter: {
    paddingRight: "30px",
    display: "flex",
    fontSize: "14px",
    visibility: "hidden",
    padding: "0 20px",
    color: theme.palette.text.primary,
    cursor: "pointer",
    alignItems: "center",
    height: "100%",
  },
  metric: {
    fontSize: "14px",
    padding: 0,
    flexBasis: 0,
    flexGrow: 1,
    color: theme.palette.text.primary,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "end",
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 10px",
    width: "100%",
    height: 57,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      "& $actionCenter": {
        visibility: "visible",
      },
    },
  },
  listItemFirstColumn: {
    width: "calc(60% - 10px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  keyword: IKeywordGroupMember;
  group: IKeywordGroup;
};

const KeywordTabListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { keyword, group } = props;

  const { enqueueSnackbar } = useSnackbar();

  return (
    <ListItem className={classNames(classes.listRow)}>
      <div className={classes.listItemFirstColumn}>
        <span
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "pre",
          }}
        >
          {keyword.keyword}
        </span>
        <div className={classNames(classes.actionCenter)}>
          <Tooltip enterDelay={500} title={I18n.t(`learning_center_content.tooltip.google_search_console`)}>
            <IconButton
              disableRipple
              className={classes.button}
              onClick={(event) => {
                event.stopPropagation();
                window.open(getQuerySearchConsoleUrl(`sc-domain:${group?.domain || ""}`, keyword.keyword));
              }}
            >
              <Search className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip enterDelay={500} title={I18n.t(`learning_center_content.tooltip.copy_keyword`)}>
            <IconButton
              disableRipple
              className={classes.button}
              onClick={(event) => {
                event.stopPropagation();
                copyStringToClipboard(keyword.keyword, enqueueSnackbar("Title copied to clipboard"));
              }}
            >
              <CopyNameIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className={classes.metric}>
        <Typography>-</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>-</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{keyword.volume ? numeral(keyword.volume).format("0,0a") : "0"}</Typography>
      </div>

      <div className={classes.metric}>
        <Typography className={classes.clicksText}>
          {keyword.clicks ? numeral(keyword.clicks).format("0,0a") : "0"}
        </Typography>
      </div>
      <div className={classes.metric}>
        <Typography className={classes.impText}>
          {keyword.impressions ? numeral(keyword.impressions).format("0,0a") : "0"}
        </Typography>
      </div>
      <div className={classes.metric}>
        <Typography className={classes.ctrText}>{keyword.ctr ? numeral(keyword.ctr).format("0.0%") : "0%"}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography className={classes.posText}>
          {keyword.position ? numeral(keyword.position).format("0.0") : "-"}
        </Typography>
      </div>
    </ListItem>
  );
};

export default KeywordTabListItem;
