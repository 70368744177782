import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(21),
    fontWeight: theme.typography.fontWeightBold as any,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  icon: { color: `${theme.palette.text.primary} !important` },
  selectAllRadio: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
    marginRight: 10,
  },
  cancelBulkIcon: {
    fill: theme.palette.primary.main,
  },
}));

type PropTypes = {
  columnWidth?: number | string;
  fontSize?: number | string;
  selected: boolean;
  children: string | React.ReactNode;
  onSelectAll: () => void;
  onClearAll: () => void;
};

const MTableHeaderLabelWithSelectAll = (props: PropTypes) => {
  const { children, selected, onSelectAll, onClearAll, fontSize, columnWidth } = props;
  const classes = useStyles(props);
  return (
    <div
      className={classes.wrapper}
      style={{
        fontSize,
        flexGrow: columnWidth ? undefined : 1,
        flexBasis: columnWidth ? undefined : 0,
        width: columnWidth,
      }}
    >
      {!selected && (
        <RadioButtonUncheckedIcon
          className={classes.selectAllRadio}
          onClick={(e) => {
            e.stopPropagation();
            onSelectAll();
          }}
        />
      )}
      {selected && (
        <RemoveCircleIcon
          className={classNames(classes.cancelBulkIcon, classes.selectAllRadio)}
          onClick={(e) => {
            e.stopPropagation();
            onClearAll();
          }}
        />
      )}
      {children}
    </div>
  );
};

export default MTableHeaderLabelWithSelectAll;
