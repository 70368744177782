import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import { replace } from "connected-react-router";
import CfButton from "../../components/CfButton";
import AuthNavbar from "./components/AuthNavbar";
import AuthCard from "./components/AuthCard";
import AuthTextField from "./components/AuthTextField";
import { Link } from "react-router-dom";
import agent from "../../agent";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
  },
  title: {
    margin: "24px 0",
  },
  description: {
    width: "272px",
    color: "#808080",
    fontSize: 16,
    lineHeight: "19px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    marginBottom: 24,
  },
  divider: {
    color: "black",
    height: 1,
    width: 121,
    backgroundColor: "#E5E5E5",
  },
  credentialsWrapper: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    marginTop: 10,
    minWidth: 200,
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  signUpSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
  noAccountText: {
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: 16,
    lineHeight: "19px",
    color: "#808080",
  },
  routerLink: {
    textDecoration: "none",
  },
  loginText: {
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: 16,
    marginLeft: 10,
    lineHeight: "19px",
    color: "#4353FF",
    cursor: "pointer",
  },
}));

type PropTypes = {
  onSubmit: (to) => void;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (to) => dispatch(replace(to)),
});

const ForgotPassword = (props: PropTypes) => {
  const classes = useStyles(props);
  const [email, setEmail] = React.useState("");
  const { onSubmit } = props;

  const { enqueueSnackbar } = useSnackbar();

  const handleLoginClick = () => {
    if (!email) return null;

    agent.Auth.passwordResetSend(email)
      .then((res) => {
        enqueueSnackbar(res.message);
        onSubmit("/login");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <section className={classes.pageWrapper}>
      <AuthNavbar />
      <div className={classes.contentWrapper}>
        <AuthCard customClass={classes.title} title={I18n.t("login.forgot_your_password")}>
          <Typography variant={"body2"} className={classes.description}>
            {I18n.t("login.forgot_password_description")}
          </Typography>

          <div className={classes.credentialsWrapper}>
            <AuthTextField label={I18n.t("login.email_address")} value={email} onChange={setEmail} />
          </div>

          <CfButton
            fullWidth
            customClass={classes.button}
            text={I18n.t("login.send_instructions")}
            onClick={(e) => handleLoginClick()}
          />
          <div className={classes.signUpSection}>
            <Typography variant={"body2"} className={classes.noAccountText}>
              {I18n.t("login.have_account")}
            </Typography>
            <Link to="/login" className={classes.routerLink}>
              <Typography variant={"body2"} className={classes.loginText}>
                {I18n.t("login.login")}
              </Typography>
            </Link>
          </div>
        </AuthCard>
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
