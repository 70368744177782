import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { Paper } from "@material-ui/core";
import moment from "moment";
import { sumBy } from "lodash";
import numeral from "numeral";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from "../../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  content: any;
  hiddenAreas: any;
  loading: boolean;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper style={{ padding: 20, width: 200, fontSize: 12, lineHeight: "14px" }}>
        <span style={{ fontWeight: theme.typography.fontWeightBold }}>
          {moment(label, "YYYY-MM-DD").format("MMM D, YYYY")}
        </span>
        {payload
          .slice(0)
          .reverse()
          .map((p) => (
            <div style={{ display: "flex", marginTop: 10, alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    width: 10,
                    height: 7,
                    backgroundColor: p.fill,
                    marginRight: 8,
                  }}
                />
                <span>{p.dataKey}</span>
              </div>
              <span style={{ fontWeight: theme.typography.fontWeightBold }}>{numeral(p.value).format("0,0[.]")}</span>
            </div>
          ))}
        <div style={{ borderTop: "1px solid #000000", marginTop: 10, paddingTop: 10 }}>
          <div style={{ display: "flex", marginTop: 10, alignItems: "center", justifyContent: "space-between" }}>
            <span style={{ fontWeight: theme.typography.fontWeightBold }}>{"Total"}</span>
            <span style={{ fontWeight: theme.typography.fontWeightBold }}>
              {numeral(sumBy(payload, (p) => p.value || 0)).format("0,0[.]")}
            </span>
          </div>
        </div>
      </Paper>
    );
  }

  return null;
};

const ContentGraph = (props: PropTypes) => {
  const classes = useStyles(props);
  const { content, hiddenAreas, loading } = props;

  return (
    <div style={{ height: "inherit", position: "relative" }}>
      <Backdrop
        style={{
          zIndex: 10,
          color: "#fff",
          position: "absolute",
          backgroundColor: "rgba(255,255,255,0.7)",
        }}
        open={loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <ResponsiveContainer width="100%" height="100%" maxHeight={460}>
        <LineChart
          width={400}
          height={460}
          // data={positions}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} color={"#E5E5E5"} />
          <XAxis dataKey="date" axisLine={false} tickLine={false} />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip content={CustomTooltip} />
          {!hiddenAreas.has("0-3") && (
            <Line
              connectNulls
              type="monotone"
              dataKey="0-3"
              stackId="0"
              stroke="#333866"
              strokeWidth={3}
              fill="#636DD6"
              activeDot={{ stroke: "#626DD6", strokeWidth: 4, r: 6, strokeDasharray: "", fill: "#1F2566" }}
            />
          )}
          {!hiddenAreas.has("4-10") && (
            <Line
              connectNulls
              type="monotone"
              dataKey="4-10"
              stackId="0"
              stroke="#262E80"
              strokeWidth={3}
              fill="#828AE0"
              activeDot={{ stroke: "#828AE0", strokeWidth: 4, r: 6, strokeDasharray: "", fill: "#2D3480" }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ContentGraph;
