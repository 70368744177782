import * as React from "react";
import { I18n } from "react-redux-i18n";
import ListBaseComponent from "../BaseComponents/ListBaseComponent";

type PropTypes = {
  value: string;
  defaultValue?: string;
  handleChange: any;
};

const BorderStyleCSS = (props: PropTypes) => {
  const borderStyleOptions = ["solid", "dashed", "dotted", "double", "groove", "inset", "outset", "ridge", "none"];
  const { value, handleChange, defaultValue } = props;
  return (
    <ListBaseComponent
      title={I18n.t("rich_text_editor.border_style")}
      defaultValue={defaultValue}
      value={value}
      selectOptions={borderStyleOptions}
      isValueChanged={value != defaultValue}
      handleChange={(style) => handleChange(style)}
    />
  );
};

export default BorderStyleCSS;
