import * as React from "react";
import { useMemo } from "react";
import { find } from "lodash";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import { IWebsite, IWidget, IWidgetField, IWidgetItem } from "../../../../../../../reducers/constants/objectTypes";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import MultiStepForm from "../formWidget/MultiStepForm";
import OnePageForm from "../formWidget/OnePageForm";
import AddWidgetFieldsTab from "../components/AddWidgetFields/AddWidgetFieldsTab";
import ButtonWidgetItem from "./ButtonWidgetItem";
import ItemProperties from "./itemProperties/ItemProperties";
import widgetBuilderReducer, { initialState } from "../../../../../../../reducers/widgetBuilderReducer";
import CfProgressBar from "../../../../../../../components/CfProgressBar";
import AppTheme from "../../../../../../../themes/theme";
import { getWidgetAddableFields } from "../../../helpers/widgets";

const useStyles = makeStyles(() => ({
  loading: {
    display: "flex",
    justifyContent: "center",
    minHeight: 70,
  },
}));

type PropTypes = {
  fieldType: string;
  form: IWidget;
  selectedWebsite: IWebsite;
  onFormChange: (form: IWidget) => void;
  createFormWidgetField: () => void;
};

const DialogFormWidgetItem = (props: PropTypes) => {
  const [state, dispatch] = React.useReducer(widgetBuilderReducer, initialState);
  const classes = useStyles();
  const handleWidgetLoaded = (payload) => dispatch({ type: WIDGET_BUILDER.WIDGET_LOADED, payload });
  const handleItemChange = (item: IWidgetItem) => dispatch({ type: WIDGET_BUILDER.ITEM_CHANGED, item });
  const setSelectedStep = (selectedStep: number) => dispatch({ type: WIDGET_BUILDER.SET_SELECTED_STEP, selectedStep });
  const setSelectedFieldId = (selectedFieldId: string) =>
    dispatch({ type: WIDGET_BUILDER.SET_SELECTED_FIELD, selectedFieldId });
  const editButton = (button) => dispatch({ type: WIDGET_BUILDER.BUTTON_CHANGED, button });
  const addNewField = (field: IWidgetField) => dispatch({ type: WIDGET_BUILDER.ADD_NEW_FIELD, field });
  const removeField = (field: IWidgetField) => dispatch({ type: WIDGET_BUILDER.DELETE_FIELD, field });
  const addPage = () => {};

  const { widget, selectedItem, selectedStep, selectedFieldId, widgetLoaded } = state;

  const { onFormChange } = props;
  const { form, selectedWebsite, createFormWidgetField } = props;

  const [addNewItemMode, setAddNewItemMode] = React.useState(false);
  const [selectedSubmitButton, setSelectedSubmitButton] = React.useState(null);

  const item = useMemo(() => widget && widget.items[selectedItem], [widget, selectedItem]);

  React.useEffect(() => {
    if (widget) {
      onFormChange(widget);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget]);

  React.useEffect(() => {
    if (widgetLoaded) {
      return;
    }

    if (!form) {
      createFormWidgetField();
    } else {
      handleWidgetLoaded({ widget: form });
    }
  }, [createFormWidgetField, form, onFormChange, selectedWebsite._id, widgetLoaded]);

  function handleFieldEdit(field: IWidgetField) {
    handleItemChange({
      ...item,
      steps: item.steps.map((step, i) => {
        if ((selectedStep || 0) === i) {
          return {
            ...step,
            fields: step.fields.map((f) => {
              if (f.fieldId === field.fieldId) {
                return field;
              }
              return f;
            }),
          };
        }
        return step;
      }),
    });
  }

  if (!widgetLoaded)
    return (
      <div className={classes.loading}>
        <ThemeProvider theme={AppTheme}>
          <CfProgressBar />;
        </ThemeProvider>
      </div>
    );

  if (selectedFieldId) {
    return (
      <ItemProperties
        field={find(widget.items[selectedItem].steps[selectedStep || 0].fields, (f) => f.fieldId === selectedFieldId)}
        onCancel={() => setSelectedFieldId(null)}
        onDone={(field) => {
          setSelectedFieldId(null);
          handleFieldEdit(field);
        }}
        selectedWebsite={selectedWebsite}
        noPadding
      />
    );
  }

  if (selectedSubmitButton) {
    return (
      <ButtonWidgetItem
        button={widget.button}
        editButton={editButton}
        onClose={() => {
          setSelectedFieldId(null);
          setSelectedSubmitButton(null);
        }}
        noPadding
      />
    );
  }

  if (addNewItemMode) {
    return (
      <AddWidgetFieldsTab
        onClose={() => setAddNewItemMode(false)}
        addNewField={addNewField}
        addableFields={getWidgetAddableFields(widget)}
      />
    );
  }

  if (!item.multistep) {
    return (
      <OnePageForm
        setAddNewItemMode={setAddNewItemMode}
        onSubmitButtonClick={() => {
          setSelectedSubmitButton(true);
        }}
        widget={widget}
        selectedItem={selectedItem}
        selectedStep={selectedStep}
        handleItemChange={handleItemChange}
        removeField={removeField}
        setSelectedStep={setSelectedStep}
        setSelectedFieldId={setSelectedFieldId}
        addNewField={addNewField}
        noPadding
      />
    );
  }

  return null;

  return (
    <MultiStepForm
      onChange={(multistep) => {
        setSelectedStep(null);
        handleItemChange({ ...item, multistep });
      }}
      onClick={(field) => {
        setSelectedFieldId(field.fieldId);
      }}
      onAddNewItemMode={setAddNewItemMode}
      onAddPage={addPage}
    />
  );
};

export default DialogFormWidgetItem;
