import React, { useEffect } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import agent from "../../../agent";
import { IAdminUser, ICategory, IWebsite } from "../../../reducers/constants/objectTypes";
import { COMMUNITY } from "../../../reducers/constants/actionTypes";
import WriterPublishedCommunityItemList from "./components/WriterPublishedCommunityItemList";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  admin: state.home.admin,
  selectedWebsite: state.home.selectedWebsite,
  categories: state.category.categories,
  refreshPages: state.community.writeRefreshAnswers,
  sortField: state.community.writerPublishedFilter.sortField,
});

const mapDispatchToProps = (dispatch) => ({
  loadAnswers: (payload) => dispatch({ type: COMMUNITY.PUBLISHED_ANSWERS_LOADED, payload }),
  startLoading: () => dispatch({ type: COMMUNITY.PUBLISHED_ANSWERS_LOADING }),
  onFilterChanged: (payload) => dispatch({ type: COMMUNITY.PUBLISHED_ANSWERS_ON_FILTER_CHANGED, payload }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  admin: IAdminUser;
  loadAnswers: (payload) => void;
  startLoading: () => void;
  refreshPages: boolean;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  categories: ICategory[];

  onFilterChanged: (payload) => void;
};

const WritePublished = (props: PropTypes) => {
  const { selectedWebsite, admin, loadAnswers, startLoading, refreshPages, sortField, onFilterChanged } = props;

  useEffect(() => {
    startLoading();

    loadAnswers(
      agent.Community.publishedAnswers(selectedWebsite, sortField.field, sortField.direction)
        .then((res) => res)
        .catch((e) => {})
    );
  }, [selectedWebsite, admin, refreshPages, sortField]);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.community.published_answers.title`)} />
      <WriterPublishedCommunityItemList
        sortField={sortField}
        setSortField={(value) => onFilterChanged({ sortField: value })}
      />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WritePublished);
