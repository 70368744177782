import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    flexGrow: 1,
    flexBasis: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: 10,
    textTransform: "capitalize",
  },
}));

type PropTypes = {
  status: string;
  isDefaultColor?: boolean;
};

const MTableCellStatus = (props: PropTypes) => {
  const classes = useStyles(props);
  const { status = "-", isDefaultColor = false } = props;
  const theme = useTheme();

  const getStatusColor = React.useMemo(() => {
    if (isDefaultColor) {
      return theme.palette.text.primary;
    }
    switch (status.toLowerCase()) {
      case "pending":
        return "blue";
      case "ok":
      case "success":
      case "accepted":
      case "approved":
        return "green";
      case "error":
      case "failed":
      case "unknown":
      case "rejected":
        return "red";
      default:
        return theme.palette.text.primary;
    }
  }, [status]);

  return (
    <div className={classes.column}>
      <Tooltip enterDelay={2000} title={status}>
        <span style={{ color: getStatusColor }}>{status}</span>
      </Tooltip>
    </div>
  );
};

export default MTableCellStatus;
