import React from "react";
import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import DragIcon from "../../../icons/DragIcon";

const useStyles = makeStyles((theme: Theme) => ({
  dragHandle: {
    width: 10,
    height: 10,
    marginRight: 10,
    backgroundColor: "green",
    opacity: 0,
    transition: "0.2s ease-out all",
    "&:hover": {
      display: "block",
      opacity: 0.7,
    },
  },
  containerView: {
    width: "100%",
    margin: 0,
    position: "relative",
    "&:hover": {
      "& $dragIconWrapper": {
        opacity: 1,
      },
    },
  },
  dragIconWrapper: {
    position: "absolute",
    top: 0,
    height: "100%",
    opacity: 0,
    transform: "translateX(-100%)",
    "&:hover": {
      opacity: 1,
    },
  },
  dragIcon: {
    alignItems: "flex-start",
    padding: 0,
  },
}));

type PropTypes = {
  index: number;
  children: any;
  disabled?: boolean;
};

const DraggableElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { index, children, disabled = false } = props;
  if (disabled) {
    return children;
  }

  return (
    <Draggable draggableId={`${index}`} key={index} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          className={ClassNames(classes.containerView, "container")}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div contentEditable={false} className={classes.dragIconWrapper}>
            <IconButton
              disableRipple
              disableFocusRipple
              disableTouchRipple
              className={classes.dragIcon}
              {...provided.dragHandleProps}
            >
              <DragIcon />
            </IconButton>
          </div>
          {children}
        </div>
      )}
    </Draggable>
  );
};
export default DraggableElement;
