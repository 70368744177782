import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { I18n } from "react-redux-i18n";
import { Button, Theme, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import SelectProperty from "../../build/items/itemProperties/components/SelectProperty";
import FontColorCSS from "../../../../../../../editor/elementsStyles/CssComponents/FontColorCSS";
import BorderRadiusCSS from "../../../../../../../editor/elementsStyles/CssComponents/BorderRadiusCSS";
import DesignInputFieldsModel from "../models/DesignInputFieldsModel";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import { widgetDesignDefaultValues } from "../../../constants";
import { IWebsite } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    padding: "30px",
  },
  wrapper: {
    backgroundColor: palette.secondary.main,
    borderRadius: "5px",
    padding: "25px 15px 15px 15px",
  },
  headerItems: {
    fontSize: "16px",
    verticalAlign: "middle",
    display: "flex",
    padding: "0px 0px 10px 0px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowBack: {
    display: "flex",
    marginLeft: "-8px",
    cursor: "pointer",
  },
  backIcon: {
    height: "22px",
    color: palette.common.white,
  },
  button: {
    background: palette.primary.light,
    justifySelf: "flex-end",
    height: "27px",
    width: "54px",
    fontSize: "14px",
    fontWeight: typography.fontWeightLight,
    textTransform: "none",
  },
  head: {
    userSelect: "none",
    color: palette.common.white,
    fontSize: "16px",
    lineHeight: "21px",
  },
  body: {
    width: "100%",
    borderRadius: "5px",
    marginTop: "10px",
  },
  marginTop: {
    marginTop: 10,
  },
}));

type PropTypes = {
  website: IWebsite;
  goBack: (any) => void;
  inputFields: DesignInputFieldsModel;
  editinputFields: (ainputFieldsppearance: DesignInputFieldsModel) => void;
};

const mapStateToProps = (state) => ({
  inputFields: state.widgetBuilder.widget.inputFields,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  editinputFields: (inputFields) => dispatch({ type: WIDGET_BUILDER.DESIGN, key: "inputFields", value: inputFields }),
});

const InputFieldsDesign = (props: PropTypes) => {
  const classes = useStyles(props);
  const { goBack, inputFields, editinputFields, website } = props;

  const [newInputFields, setNewInputFields] = React.useState(inputFields);
  //todo: when enabling the design tab -> fix the name of the parameters
  const remoteWebsiteTheme = React.useMemo(
    () => ({
      labelPosition: "" || widgetDesignDefaultValues.inputFields.labelColor,
      labelColor: "" || widgetDesignDefaultValues.inputFields.labelPosition,
      textColor: "" || widgetDesignDefaultValues.inputFields.textColor,
      fieldColor: "" || widgetDesignDefaultValues.inputFields.fieldColor,
      buttonColor: "" || widgetDesignDefaultValues.inputFields.buttonColor,
      borderRadius: "" || widgetDesignDefaultValues.inputFields.borderRadius,
    }),
    [website]
  );

  // const [buttonColor, setButtonColor] = React.useState(inputFields?.buttonColor || "#FFFFFF");
  // const [borderRadius, setBorderRadius] = React.useState(inputFields?.borderRadius || "0px 0px 0px 0px");

  function handleClick() {
    editinputFields(newInputFields);
    goBack(null);
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.headerItems}>
          <div className={classes.arrowBack} onClick={() => goBack(null)}>
            <KeyboardArrowLeftRounded className={classes.backIcon} />
            <Typography variant={"h6"} className={classes.head}>
              {"Form Fields"}
            </Typography>
          </div>
          <Button className={classes.button} onClick={() => handleClick()}>
            {I18n.t("widgets_builder.design.done")}
          </Button>
        </div>
        <div className={classes.body}>
          <SelectProperty
            showLabel
            label={I18n.t("widgets_builder.design.label_position")}
            value={newInputFields?.labelPosition || remoteWebsiteTheme?.labelPosition}
            options={["Inside", "Outside"]}
            onChange={(labelPosition) => {
              setNewInputFields({ ...newInputFields, labelPosition });
            }}
          />

          <div className={classes.marginTop}>
            <FontColorCSS
              title={I18n.t("widgets_builder.design.label_color")}
              value={newInputFields?.labelColor || remoteWebsiteTheme?.labelColor}
              defaultValue={"#FFFFFF"}
              isValueChanged={true}
              handleChange={(labelColor) => {
                setNewInputFields({ ...newInputFields, labelColor });
              }}
            />
          </div>

          <div className={classes.marginTop}>
            <FontColorCSS
              title={I18n.t("widgets_builder.design.text_color")}
              value={newInputFields?.textColor || remoteWebsiteTheme?.textColor}
              defaultValue={"#FFFFFF"}
              isValueChanged={true}
              handleChange={(textColor) => {
                setNewInputFields({ ...newInputFields, textColor });
              }}
            />
          </div>

          <div className={classes.marginTop}>
            <FontColorCSS
              title={I18n.t("widgets_builder.design.field_color")}
              value={newInputFields?.fieldColor || remoteWebsiteTheme?.fieldColor}
              defaultValue={"#FFFFFF"}
              isValueChanged={true}
              handleChange={(fieldColor) => {
                setNewInputFields({ ...newInputFields, fieldColor });
              }}
            />
          </div>

          <div className={classes.marginTop}>
            <FontColorCSS
              title={I18n.t("widgets_builder.design.button_color")}
              value={newInputFields?.buttonColor || remoteWebsiteTheme?.buttonColor}
              defaultValue={"#FFFFFF"}
              isValueChanged={true}
              handleChange={(buttonColor) => {
                setNewInputFields({ ...newInputFields, buttonColor });
              }}
            />
          </div>

          <BorderRadiusCSS
            value={newInputFields?.borderRadius || remoteWebsiteTheme?.borderRadius}
            defaultValue={"0px 0px 0px 0px"}
            handleChange={(borderRadius) => {
              setNewInputFields({ ...newInputFields, borderRadius });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InputFieldsDesign);
