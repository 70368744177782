import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 36,
    height: 35,
  },
}));

type PropTypes = {
  className?: string;
};

const TextBoxIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 36 35"} fill="none">
      <rect x="0.333252" width="35" height="35" rx="3" fill="#00BF8F" />
      <path
        d="M23.764 11.6666L11.514 11.6666M23.764 16.0416L11.514 16.0416M5.38904 10.2083L5.38904 24.7916C5.38904 27.2078 7.44573 29.1666 9.98279 29.1666H25.2953C27.8323 29.1666 29.889 27.2078 29.889 24.7916V10.2083C29.889 7.79201 27.8323 5.83325 25.2953 5.83325L9.98279 5.83325C7.44573 5.83325 5.38904 7.79201 5.38904 10.2083Z"
        stroke="white"
        fill="none"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default TextBoxIcon;
