import React from "react";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ITag } from "../reducers/constants/objectTypes";
import XIcon from "../icons/XIcon";

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    minHeight: 23,
    wordBreak: "break-word",
    width: "fit-content",
    backgroundColor: theme.palette.divider,
    borderRadius: "2.5px",
    paddingRight: 6,
    paddingLeft: 6,
    marginRight: 5,
    marginLeft: 5,
    display: "flex",
    alignItems: "center",
    transition: "all 1s cubic-bezier(.175,.885,.32,1)",
    webkitTransition: "all 1s cubic-bezier(.175,.885,.32,1)",
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
  },
  removeTagIcon: {
    color: theme.palette.text.hint,
    cursor: "pointer",
    height: 10,
    width: 10,
    marginLeft: 10,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  tag: string;

  onRemoveClicked?: Function;
  onClick?: Function;
};

const MTag = (props: PropTypes) => {
  const classes = useStyles(props);
  const { tag } = props;
  const { onRemoveClicked, onClick } = props;

  return (
    <div className={classes.tag} key={tag} style={{ cursor: onClick ? "pointer" : "unset" }}>
      <Typography className={classes.text} variant={"body2"} onClick={(e) => onClick && onClick(e)}>
        {tag}
        {onRemoveClicked && <XIcon className={classes.removeTagIcon} onClick={(e) => onRemoveClicked(e)} />}
      </Typography>
    </div>
  );
};

export default MTag;
