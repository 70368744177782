import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardArrowUp } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import { Collapse } from "@material-ui/core";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { useSnackbar } from "notistack";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DownChevronIcon from "../../../icons/DownChevronIcon";
import MButton from "../../../components/MButton";
import agent from "../../../agent";
import slateMarkdownHelper from "../../../helpers/slateMarkdownHelper";
import { IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import MTextButton from "../../../components/MTextButton";
import MButtonGroup from "../../../components/MButtonGroup";
import { copyStringToClipboard } from "../../../helpers/fbScraperHelper";
import { editorType } from "../../types/editor.Types";

const useStyles = makeStyles((theme: Theme) => ({
  containerView: {
    width: "100%",
    margin: 0,
    position: "relative",
  },
  iconWrapper: (props: PropTypes) => ({
    position: "absolute",
    top: props.iconTop || 0,
    height: "100%",
    right: props.iconRight || 0,
  }),
  icon: {
    alignItems: "flex-start",
    padding: 0,
    cursor: "pointer",
    color: "#333333",
    width: "16px !important",
    height: "16px !important",
  },
  innerWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 15px",
    gap: 20,
    margin: (props: PropTypes) => props.margin || 0,
    borderRadius: 5,
    backgroundColor: "#fff",
    boxShadow: "2px 2px 15px 0px rgba(0, 0, 0, 0.25)",
    marginBottom: 20,
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: theme.typography.fontWeightLight as any,
    lineHeight: "21px",
  },
  actionsWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },

  actionButtonWrapper: {
    borderRadius: 3,
    border: `solid 1px ${theme.palette.divider}`,
    display: "flex",
    maxHeight: 31,
  },
  actionButton: {
    padding: "7px 10px",
    minHeight: 0,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    minWidth: 0,
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  generateButton: {
    maxWidth: 88,
    padding: "7px 15px",
    borderRadius: 3,
    maxHeight: 31,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  titleListWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingInlineStart: 0,
  },
  listTitle: {
    display: "flex",
    flexDirection: "row",
    color: theme.palette.text.primary,
    padding: 5,
    borderRadius: 3,
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
    "&:hover": {
      backgroundColor: "#EEF2F9",
      "& > $listActions": {
        display: "flex",
        alignItems: "center",
      },
    },
  },
  listActions: {
    display: "none",
    flexDirection: "row",
    margin: "0px 15px",
    gap: 10,
  },
  listAction: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  listItem: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight as any,
    lineHeight: "29px",
    flex: 1,
  },
  listActionSeparator: {
    width: 3,
    height: 3,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
  empty: {
    width: 10,
  },
}));

type PropTypes = {
  children: any;
  element?: any;
  disableAi?: boolean;
  disabled?: boolean;
  selectedWebsite: IWebsite;
  page?: IPage;
  onReplace?: Function;
  text?: string;
  iconTop?: number;
  iconRight?: number;
  margin?: number | string;
};

const styleActions = [
  { label: "Generic", key: "generic" },
  { label: "Magazine", key: "magazine" },
  { label: "Short ", key: "short" },
  { label: "Academic", key: "academic" },
  { label: "Clickbait", key: "clickbait" },
  { label: "Social", key: "social" },
  { label: "Subject", key: "subject" },
  { label: "News", key: "news" },
];

const toneActions = [
  { label: "Informal", key: "informal" },
  { label: "Formal", key: "formal" },
  { label: "Friendly", key: "friendly" },
];

const TitleAssistantDropdown = (props: PropTypes) => {
  const classes = useStyles(props);
  const { children, disableAi = false, disabled = false, element, selectedWebsite, page, onReplace, text = "" } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedStyleAction, setSelectedStyleAction] = React.useState(styleActions[0]);
  const [selectedToneAction, setSelectedToneAction] = React.useState(toneActions[0]);
  const [titles, setTitles] = React.useState([]);
  const [loadTitles, setLoadTitles] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const editor = useSlateStatic();
  if (disabled) {
    return children;
  }
  if (disableAi) {
    return children;
  }

  const getTitles = async () => {
    const currentNodes = [element || { type: editorType.headingOne, children: [{ text }] }];
    setTitles([]);
    setLoadTitles(true);
    agent.EditorAssistant.assistant(selectedWebsite, {
      message: slateMarkdownHelper.slateTopMarkdown(currentNodes),
      extraInstruction: false,
      instruction: `can you generate ten ${selectedStyleAction.label} alternative titles${
        selectedToneAction ? ` in a ${selectedToneAction.label} tone` : ""
      }. The result should be strictly a json format for example {"titles":["title1","title2","more..."]}`,
      data: { page: page?._id },
    })
      .then((res) => {
        const result = JSON.parse(res.result.text)?.titles;
        setLoadTitles(false);
        if (result) {
          setTitles(result);
        } else {
          setLoadTitles(false);
          enqueueSnackbar(I18n.t("ai_assistant.bad_formatting_error"), {
            variant: "error",
          });
          setTitles([]);
        }
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.create_error", { msg: e.response.body.message }), {
          variant: "error",
        });
        setLoadTitles(false);
        setTitles([]);
      });
  };
  const handleElementDataChange = (title) => {
    if (onReplace) {
      onReplace(title);
      return;
    }
    Transforms.insertText(editor, title, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={ClassNames(classes.containerView, "container")}>
        {children}
        <div contentEditable={false} className={classes.iconWrapper}>
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={classes.icon}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUp /> : <DownChevronIcon />}
          </IconButton>
        </div>
        <Collapse in={open}>
          <div className={classes.innerWrapper} contentEditable={false}>
            <Typography className={classes.title} variant={"subtitle2"}>
              {I18n.t("ai_assistant.alternative_titles")}
            </Typography>
            <div className={classes.actionsWrapper}>
              <MButtonGroup
                wholePress
                onMenuItemClick={(key) => setSelectedStyleAction(styleActions.find((opt) => opt.key === key))}
                onClick={() => {}}
                wrapperCustomClass={classes.actionButtonWrapper}
                customClass={classes.actionButton}
                hasDivider={false}
                options={styleActions}
                text={
                  selectedStyleAction?.label
                    ? `${selectedStyleAction.label} style`
                    : I18n.t("ai_assistant.select_style")
                }
              />
              <MButtonGroup
                wrapperCustomClass={classes.actionButtonWrapper}
                wholePress
                customClass={classes.actionButton}
                onMenuItemClick={(key) => setSelectedToneAction(toneActions.find((opt) => opt.key === key))}
                onClick={() => {}}
                hasDivider={false}
                options={toneActions}
                text={
                  selectedToneAction?.label ? `${selectedToneAction.label} tone` : I18n.t("ai_assistant.select_tone")
                }
              />
              <MButton
                barSize={20}
                disabled={!selectedStyleAction}
                loading={loadTitles}
                customClassNames={classes.generateButton}
                onClick={() => {
                  if (selectedStyleAction) {
                    getTitles();
                  }
                }}
              >
                {I18n.t("ai_assistant.generate")}
              </MButton>
            </div>

            {titles && (
              <ol className={classes.titleListWrapper}>
                {titles.map((title, i) => (
                  <div className={classes.listTitle} key={`title_${i + 1}`}>
                    <div className={classes.empty} />
                    <li className={classes.listItem}>{title}</li>
                    <div className={classes.listActions}>
                      <MTextButton
                        customClass={ClassNames(classes.listAction)}
                        text={I18n.t(`ai_assistant.insert`)}
                        onClick={() => {
                          handleElementDataChange(title);
                        }}
                      />
                      <div className={classes.listActionSeparator} />
                      <MTextButton
                        customClass={ClassNames(classes.listAction)}
                        text={I18n.t(`ai_assistant.actions.copy`)}
                        onClick={() => {
                          copyStringToClipboard(title, () =>
                            enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"), {
                              variant: "success",
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
              </ol>
            )}
          </div>
        </Collapse>
      </div>
    </ClickAwayListener>
  );
};

export default TitleAssistantDropdown;
