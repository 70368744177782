import { connect } from "react-redux";
import React, { useMemo } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Classnames from "classnames";
import { I18n } from "react-redux-i18n";
import { TableSortLabel, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import _ from "lodash";
import useSort from "../../../../../../hooks/useSort";
import RelatedPagesKeywordDialogListItem from "./RelatedPagesKeywordDialogListItem";
import { getOpportunityPageUrl } from "../../../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  dialogListSubHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    backgroundColor: theme.palette.background.default,
    margin: "0px 0px",
    top: -1,
  },
  dialogPageSubHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0,
    backgroundColor: theme.palette.background.default,
  },
  dialogTabs: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    width: "10%",
  },
  firstDialogTab: {
    width: "35%",
  },
  midDialogTab: {
    width: "20%",
  },
  tab: {
    padding: "2px 40px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  activeTab: {
    color: theme.palette.text.primary,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    transition: "border .3s",
  },
  headerIcon: {
    width: 24,
    height: 24,
  },
  sortIcon: { color: `${theme.palette.text.primary} !important` },

  metric: {
    fontSize: "14px",
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: "100%",
    width: "10%",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  stickyListRow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "20px 30px",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    backgroundColor: theme.palette.common.white,
  },
  listItemFirstColumn: {
    width: "35%",
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  pageHeaderTab: {
    width: "50%",
    paddingLeft: "30px",
    maxWidth: "40%",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      "&  $pageHeaderText": {
        color: theme.palette.primary.main,
        transition: "color .3s",
        cursor: "pointer",
      },
    },
  },
  pageHeaderText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.text.primary,
  },
  midPageHeaderTab: {
    width: "40%",
    paddingRight: "30px",
    display: "flex",
    justifyContent: "center",

    "&:hover ": {
      "& $pageHeaderText": {
        color: theme.palette.primary.main,
        transition: "color .3s",
        cursor: "pointer",
      },
    },
  },
}));

const mapStateToProps = (state) => ({
  relatedKeywords: state.internalLinking.relatedKeywords,
});

type PropTypes = {
  relatedKeywords: any[];
  relatedPage: any;
};

const RelatedPagesKeywordDialogList = (props: PropTypes) => {
  const classes = useStyles(props);

  const { relatedKeywords, relatedPage } = props;

  const { sortFields, toggleSort } = useSort({
    initialSortFields: {
      main_clicks: "desc",
    },
  });

  const columns = useMemo(
    () => [
      "keyword",
      "main_impressions",
      "main_clicks",
      "main_ctr",
      "main_position",
      "impressions",
      "clicks",
      "ctr",
      "position",
    ],
    []
  );

  const keywordColumns = React.useMemo(
    () => ({
      keyword: {
        isSortable: false,
        label: I18n.t("strategy.outlinePages.internal_linking_dialog.keywords"),
        singularLabel: I18n.t("strategy.outlinePages.internal_linking_dialog.keyword"),
      },
      main_impressions: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.internal_linking_dialog.impressions"),
      },
      main_clicks: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.internal_linking_dialog.clicks"),
      },
      main_ctr: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.internal_linking_dialog.ctr"),
      },
      main_position: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.internal_linking_dialog.position_full"),
      },
      impressions: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.internal_linking_dialog.impressions"),
      },
      clicks: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.internal_linking_dialog.clicks"),
      },
      ctr: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.internal_linking_dialog.ctr"),
      },
      position: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.internal_linking_dialog.position_full"),
      },
    }),
    []
  );

  const sortedData = useMemo(() => {
    if (relatedKeywords) {
      const fields: string[] = [];
      const directions: any[] = [];

      Object.entries(sortFields).forEach(([field, direction]) => {
        fields.push(field);
        directions.push(direction);
      });

      return _.orderBy(relatedKeywords, fields, directions);
    }
    return [];
  }, [sortFields, relatedKeywords]);

  return (
    <div>
      <ListSubheader className={classes.dialogListSubHeader}>
        <div className={classes.firstDialogTab}></div>

        <div
          className={classes.midPageHeaderTab}
          onClick={() => {
            if (relatedPage?.page) window.open(relatedPage?.page, "_blank");
          }}
        >
          <Tooltip title={relatedPage?.page || "-"}>
            <Typography className={classes.pageHeaderText}>
              {relatedPage?.page ? getOpportunityPageUrl(relatedPage.page) : "-"}
            </Typography>
          </Tooltip>
        </div>
        <div className={classes.pageHeaderTab}>
          <Tooltip
            title={relatedPage?.related_page || "-"}
            onClick={() => {
              if (relatedPage?.related_page) window.open(relatedPage?.related_page, "_blank");
            }}
          >
            <Typography className={classes.pageHeaderText}>
              {relatedPage?.related_page ? getOpportunityPageUrl(relatedPage.related_page) : "-"}
            </Typography>
          </Tooltip>
        </div>
      </ListSubheader>
      <ListSubheader className={classes.dialogListSubHeader}>
        {columns?.map((col, index) => {
          const columnLabel = keywordColumns[col].label;

          const { singularLabel } = keywordColumns[col];

          const columnText =
            index === 0
              ? `${relatedKeywords?.length} ${relatedKeywords?.length === 1 ? singularLabel : columnLabel}`
              : columnLabel;

          const { isSortable } = keywordColumns[col];
          return (
            <div
              className={
                index === 0
                  ? Classnames(classes.dialogTabs, classes.firstDialogTab)
                  : col === "main_position"
                  ? Classnames(classes.dialogTabs, classes.midDialogTab)
                  : classes.dialogTabs
              }
              key={index}
            >
              {isSortable ? (
                <TableSortLabel
                  classes={{ icon: classes.sortIcon }}
                  active={!!sortFields[col]}
                  direction={sortFields[col] || undefined}
                  onClick={() => {
                    toggleSort(col);
                  }}
                >
                  {columnText}
                </TableSortLabel>
              ) : (
                columnText
              )}
            </div>
          );
        })}
      </ListSubheader>
      {sortedData?.map((keyword, index) => (
        <RelatedPagesKeywordDialogListItem key={index} relatedKeyword={keyword} />
      ))}
    </div>
  );
};

export default connect(mapStateToProps, {})(RelatedPagesKeywordDialogList);
