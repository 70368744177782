import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import { I18n } from "react-redux-i18n";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { filter } from "lodash";
import ClassNames from "classnames";
import CfProgressBar from "../../../../../components/CfProgressBar";
import OrderItem from "./OrderItem";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  contentList: {
    paddingRight: 20,
    paddingLeft: 20,
    maxWidth: "calc(100vw - 40px)",
  },
  root: {
    paddingTop: 0,
  },
  subHeader: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 0,
    paddingLeft: 0,
    display: "flex",
    top: 0,
    zIndex: 3,
    backgroundColor: "white",
  },
  tableHiddenHead: {
    width: 20,
  },
  tableTitleHead: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(21),
    width: "10%",
  },
  tableHead: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    flexGrow: 1,
    flexBasis: 0,
  },
  tableLandingPage: {
    minWidth: 200,
  },
  tableMoreOptionsHead: {
    width: 30,
  },
}));

type PropTypes = {
  searchText: string;
  loaded: boolean;
  orders: any[];
  selectedWebsite: IWebsite;
};

const OrdersList = (props: PropTypes) => {
  const classes = useStyles(props);
  const { searchText, selectedWebsite, loaded, orders } = props;

  const filteredOrders = loaded
    ? searchText
      ? filter(
          orders,
          (order) =>
            (order.landingPage || "").toLowerCase().includes(searchText.toLowerCase()) ||
            (order.referringSite || "").toLowerCase().includes(searchText.toLowerCase()) ||
            (order.fulfillmentStatus || "").toLowerCase().includes(searchText.toLowerCase()) ||
            (order.customerId || "").toLowerCase().includes(searchText.toLowerCase()) ||
            (order.orderNumber || "").toLowerCase().includes(searchText.toLowerCase())
        )
      : orders
    : [];

  return (
    <div className={classes.contentList}>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        <ListSubheader className={classes.subHeader}>
          <Typography className={classes.tableHiddenHead} variant={"subtitle2"}>
            {}
          </Typography>
          <Typography className={classes.tableTitleHead} variant={"subtitle2"}>
            {I18n.t("orders.table.order_number")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("orders.table.created")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("orders.table.updated")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("orders.table.price")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("orders.table.items")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("orders.table.country")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("orders.table.customer_number")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("orders.table.status")}
          </Typography>
          <Typography className={ClassNames(classes.tableHead, classes.tableLandingPage)} variant={"subtitle2"}>
            {I18n.t("orders.table.landing_page")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("orders.table.referrer")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("orders.table.notes")}
          </Typography>
          <Typography className={classes.tableMoreOptionsHead} variant={"subtitle2"}>
            {""}
          </Typography>
        </ListSubheader>
        {!loaded && (
          <ListItem
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CfProgressBar />
          </ListItem>
        )}
        {loaded && filteredOrders.length === 0 && (
          <ListItem button>
            <ListItemText primary="No content to show" />
          </ListItem>
        )}
        {loaded &&
          filteredOrders.length > 0 &&
          filteredOrders.map((order, index) => (
            <OrderItem
              key={`order_${index}`}
              order={order}
              selectedWebsite={selectedWebsite}
              // handleEditClick={() => setEditRule(rule)}
              // handleDeleteClick={() => deleteRule(rule)}
            />
          ))}
      </List>
    </div>
  );
};

export default OrdersList;
