import { IReportsSettings } from "../../../../reducers/constants/objectTypes";
import React, { useMemo } from "react";
import { I18n } from "react-redux-i18n";

import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MSelect from "../../../../components/MSelect";
import MTextField from "../../../../components/MTextField";
import { CONVERSION_POTENTIAL_FUNNEL_STAGE } from "../../../../helpers/constants";
import { capitalize } from "lodash";
import MButton from "../../../../components/MButton";

type ValueTabProps = {
  reportSettings: IReportsSettings;
  setReportSettings: (reportSettings: IReportsSettings) => void;
  saveButtonClick: () => void;
};

const COMPANY_TYPES = [
  {
    value: "b2b",
    label: "B2B",
  },
  {
    value: "b2c",
    label: "B2C",
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  valueContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "25px 200px",
    gap: 10,
  },
  valueRow: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    flexDirection: "column",
    gap: 10,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.divider}`,
  },
  valueRowTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(16),
  },
  valueRowTitleWrapper: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "22px 20px",
  },
  valueRowMain: {
    padding: "30px",
    width: "100%",
  },
  businessDefinition: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    width: "100%",
  },
  converionRates: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    width: "100%",
  },
  businessInputWrapper: {
    minWidth: "250px",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    alignItems: "flex-start",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    alignItems: "flex-start",
  },
  goalInputLabel: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    width: "180px",
  },
  inputLabel: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  goalTextInput: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    padding: "10px",
    height: "40px",
    marginTop: 0,
    marginBottom: 0,
    width: "180px",
  },
  textInput: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    padding: "10px",
    height: "40px",
    marginTop: 0,
    marginBottom: 0,
  },
  textInputComponent: {
    padding: 0,
    margin: 0,
  },
  marketingGoals: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: 8,
  },
  marketingGoalRow: {
    display: "flex",
    gap: 20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  marketingGoalIndex: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: "14px",
    color: theme.palette.text.primary,
    padding: "0 5px",
  },
  saveBtnWrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    background: theme.palette.common.white,
    padding: "10px 30px 10px 20px",
    marginTop: -10,
  },
  saveBtn: {
    maxWidth: "150px",
    height: "40px",
    borderRadius: "75px",
    padding: "10px 20px",
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type ValueRowProps = {
  title: string;
  children: React.ReactNode;
};

const ValueRow = (props: ValueRowProps) => {
  const classes = useStyles(props);
  const { title, children } = props;

  return (
    <div className={classes.valueRow}>
      <div className={classes.valueRowTitleWrapper}>
        <Typography className={classes.valueRowTitle}>{title}</Typography>
      </div>
      <div className={classes.valueRowMain}>{children}</div>
    </div>
  );
};

const ValueTab = (props: ValueTabProps) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { reportSettings, setReportSettings, saveButtonClick } = props;

  const FUNNEL_STAGES = useMemo(() => {
    return Object.keys(CONVERSION_POTENTIAL_FUNNEL_STAGE);
  }, []);

  return (
    <div className={classes.valueContainer}>
      <ValueRow title={I18n.t("reports.value.businessDefinition")}>
        <div className={classes.businessDefinition}>
          <div className={classes.businessInputWrapper}>
            <Typography className={classes.inputLabel}>{I18n.t("reports.value.companyType")}</Typography>
            <MSelect
              options={COMPANY_TYPES}
              optionLabel={"label"}
              borderColor={theme.palette.divider}
              borderWidth={1}
              borderRadius={4}
              padding={"10px"}
              fullWidth
              optionValue={"value"}
              value={COMPANY_TYPES.find(
                (option) => option.value === reportSettings.value?.businessDefinition?.companyType
              )}
              height={40}
              handleChange={(newValue) => {
                if (newValue) {
                  setReportSettings({
                    ...reportSettings,
                    value: {
                      ...reportSettings.value,
                      businessDefinition: {
                        ...reportSettings.value?.businessDefinition,
                        companyType: newValue.value,
                      },
                    },
                  });
                }
              }}
            />
          </div>
          <div className={classes.businessInputWrapper}>
            <Typography className={classes.inputLabel}>{I18n.t("reports.value.businessType")}</Typography>
            <MTextField
              fullWidth
              customClass={classes.textInput}
              inputClassName={classes.textInputComponent}
              height={40}
              onChange={(value) =>
                setReportSettings({
                  ...reportSettings,
                  value: {
                    ...reportSettings.value,
                    businessDefinition: {
                      ...reportSettings.value?.businessDefinition,
                      businessType: value,
                    },
                  },
                })
              }
              value={reportSettings?.value?.businessDefinition?.businessType}
            />
          </div>
          <div className={classes.businessInputWrapper}>
            <Typography className={classes.inputLabel}>{I18n.t("reports.value.industry")}</Typography>
            <MTextField
              fullWidth
              customClass={classes.textInput}
              inputClassName={classes.textInputComponent}
              height={40}
              onChange={(value) =>
                setReportSettings({
                  ...reportSettings,
                  value: {
                    ...reportSettings.value,
                    businessDefinition: {
                      ...reportSettings.value?.businessDefinition,
                      industry: value,
                    },
                  },
                })
              }
              value={reportSettings?.value?.businessDefinition?.industry}
            />
          </div>
        </div>
      </ValueRow>

      <ValueRow title={I18n.t("reports.value.converionRates")}>
        <div className={classes.converionRates}>
          {FUNNEL_STAGES.map((funnelStage, index) => (
            <div className={classes.inputWrapper} key={index}>
              <Typography className={classes.inputLabel}>{capitalize(funnelStage)}</Typography>
              <MTextField
                fullWidth
                customClass={classes.textInput}
                inputClassName={classes.textInputComponent}
                height={40}
                type={"number"}
                value={String(
                  reportSettings?.value?.cvrFunnelStage?.[funnelStage] !== null &&
                    reportSettings?.value?.cvrFunnelStage?.[funnelStage] !== undefined
                    ? Number.isInteger(reportSettings?.value?.cvrFunnelStage?.[funnelStage] * 100)
                      ? reportSettings?.value?.cvrFunnelStage?.[funnelStage] * 100
                      : (reportSettings?.value?.cvrFunnelStage?.[funnelStage] * 100).toFixed(1)
                    : null
                )}
                onChange={(value) => {
                  const percentageValue = Number(value) / 100;

                  setReportSettings({
                    ...reportSettings,
                    value: {
                      ...reportSettings.value,
                      cvrFunnelStage: {
                        ...reportSettings.value?.cvrFunnelStage,
                        [funnelStage]: percentageValue,
                      },
                    },
                  });
                }}
              />
            </div>
          ))}
        </div>
      </ValueRow>

      <ValueRow title={I18n.t("reports.value.marketingGoals")}>
        <div className={classes.marketingGoals}>
          <div className={classes.marketingGoalRow}>
            <Typography className={classes.marketingGoalIndex}>#</Typography>
            <Typography className={classes.goalInputLabel}>{I18n.t("reports.value.goal")}</Typography>
            <Typography className={classes.goalInputLabel}>{I18n.t("reports.value.value")}</Typography>
          </div>
          {[...reportSettings.value?.goals, { name: "", value: "" }].map((row, index) => (
            <div className={classes.marketingGoalRow} key={index}>
              <Typography className={classes.marketingGoalIndex} style={index === 0 ? { marginRight: "3px" } : {}}>
                {index + 1}
              </Typography>
              <MTextField
                fullWidth
                customClass={classes.goalTextInput}
                inputClassName={classes.textInputComponent}
                height={40}
                value={row.name}
                onChange={(value) => {
                  const newGoals = [...reportSettings.value?.goals];
                  newGoals[index] = { ...newGoals[index], name: value };

                  setReportSettings({
                    ...reportSettings,
                    value: {
                      ...reportSettings.value,
                      goals: newGoals,
                    },
                  });
                }}
              />

              <MTextField
                fullWidth
                customClass={classes.goalTextInput}
                inputClassName={classes.textInputComponent}
                height={40}
                type={"number"}
                value={String(row.value)}
                onChange={(value) => {
                  const newGoals = [...reportSettings.value?.goals];
                  newGoals[index] = { ...newGoals[index], value };

                  setReportSettings({
                    ...reportSettings,
                    value: {
                      ...reportSettings.value,
                      goals: newGoals,
                    },
                  });
                }}
              />
            </div>
          ))}
        </div>
      </ValueRow>

      <div className={classes.saveBtnWrapper}>
        <MButton customClassNames={classes.saveBtn} onClick={saveButtonClick}>
          {I18n.t("reports.value.save_changes")}
        </MButton>
      </div>
    </div>
  );
};

export default ValueTab;
