import React from "react";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";

type PropTypes = {
  type: { type: string; name: string } | null;
  handleTypeSelected: (publishStatus: { type: string; name: string }) => void;
};

const MTableToolbarExcludeKeywordsTypeFilter = (props: PropTypes) => {
  const { type, handleTypeSelected } = props;
  const typeOptions = [
    { _id: 0, name: "All" },
    {
      _id: 1,
      type: "exact",
      name: "Exact match",
    },
    {
      _id: 2,
      type: "containing",
      name: "Words containing",
    },
    {
      _id: 3,
      type: "phrase",
      name: "Phrases containing",
    },
  ];
  return (
    <MTableToolbarBaseSelect
      options={[...typeOptions]}
      selectedOptions={_.find(typeOptions, { name: type?.name }) ? [_.find(typeOptions, { name: type?.name })] : []}
      placeholder={I18n.t("exclude_keywords.type_ph")}
      handleChange={(value) => {
        if (value?.[0]?._id === 0) {
          handleTypeSelected({ type: "", name: "All" });
          return;
        }
        handleTypeSelected(value?.[0] || { type: "", name: "All" });
      }}
    />
  );
};

export default MTableToolbarExcludeKeywordsTypeFilter;
