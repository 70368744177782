import { find } from "lodash";
import { EDIT_TARGETED_POST } from "./constants/actionTypes";
import { ePostState } from "./constants/objectTypes";

const initialState = {
  postLoaded: false,
  post: null,

  selectedTranslation: null,
  selectedLocale: null,

  selectedTab: "resources",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_TARGETED_POST.CLEANUP:
      return initialState;
    case EDIT_TARGETED_POST.POST_LOADED:
      return {
        ...state,
        postLoaded: true,
        post: action.post,
      };
    case EDIT_TARGETED_POST.POST_TRANSLATION_LOADED: {
      const defaultLocale = action.locale === action.post.uploadTo.defaultLocale;
      return {
        ...state,
        postLoaded: true,
        post: action.post,
        selectedLocale: action.locale,
        selectedTranslation: find(action.post.translations, (t) => t.locale === action.locale),
        selectedTab: defaultLocale ? "resources" : "original_post",
      };
    }
    case EDIT_TARGETED_POST.LOCALE_CHANGED: {
      const defaultLocale = action.locale === state.post.uploadTo.defaultLocale;
      let selectedTranslation = find(state.post.translations, (t) => t.locale === action.locale);
      if (!selectedTranslation) {
        selectedTranslation = {
          locale: action.locale,
          post: state.post._id,
          state: ePostState.UNINITIALIZED,
          title: "",
        };
      }
      return {
        ...state,
        selectedTab: defaultLocale ? "resources" : "original_post",
        selectedLocale: action.locale,
        selectedTranslation: defaultLocale ? null : selectedTranslation,
      };
    }
    case EDIT_TARGETED_POST.TRANSLATION_CHANGED:
      return {
        ...state,
        selectedTranslation: action.translation,
      };
    case EDIT_TARGETED_POST.POST_CHANGED: {
      const selectedTranslation = state.selectedTranslation
        ? find(action.post.translations, (t) => t.locale === state.selectedTranslation.locale)
        : null;
      return {
        ...state,
        post: action.post,
        selectedTranslation,
      };
    }
    case EDIT_TARGETED_POST.TAB_CHANGED:
      return {
        ...state,
        selectedTab: action.tab,
      };

    default:
      return state;
  }
};
