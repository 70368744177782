import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, useTheme } from "@material-ui/styles";
import ClassNames from "classnames";
import { ePageType, ePostType } from "../reducers/constants/objectTypes";

const getAvatarProps = (pageType: ePageType | ePostType | string, pageName: string, theme) => {
  switch (pageType) {
    case ePostType.COMMUNITY_ANSWER:
    case ePostType.COMMUNITY:
      console.log("communtiy", pageType);
      return {
        initials: "C",
        backgroundColor: "#FFBE4D",
        color: "white",
        tooltip: "Community",
      };
    case ePageType.Blog:
      return {
        initials: "B",
        backgroundColor: "#808AFE",
        color: "white",
        tooltip: "Blog",
      };
    case ePageType.Targeted:
      return {
        initials: "T",
        backgroundColor: "#FF84BC",
        color: "white",
        tooltip: "Targeted Post",
      };
    // case ePageType.Topic:
    //   return {
    //     initials: "TO",
    //     color: "#2A9702",
    //     tooltip: "Topic",
    //   };
    case ePageType.ComparisonTop10:
    case ePageType.ComparisonTopAlternatives:
      return {
        initials: "COMP",
        backgroundColor: "#EC5B5B",
        color: "white",
        tooltip: "Comparison",
      };
    case ePageType.ProductAlternative:
      return {
        initials: "PA",
        backgroundColor: "#EB9546",
        color: "white",
        tooltip: "Product Alternative",
      };
    case ePageType.ProductComparison:
      return {
        initials: "PC",
        backgroundColor: "#ACCE64",
        color: "white",
        tooltip: "Product Comparison",
      };
    case ePageType.ProductReview:
      return {
        initials: "PR",
        backgroundColor: "#64CEBB",
        color: "white",
        tooltip: "Product Review",
      };
    case ePageType.ProductPage:
      return {
        initials: "PP",
        backgroundColor: "#7364CE",
        color: "white",
        tooltip: "Product Page",
      };
    case "A":
      return {
        initials: "A",
        backgroundColor: "#808AFE",
        color: "white",
        tooltip: "",
      };

    default:
      if (pageType.includes("main_page")) {
        return {
          initials: "V",
          backgroundColor: "#E23CA0",
          color: "white",
          tooltip: `${pageType.replace("_main_page", "")} Vertical Page`,
        };
      }

      let hash = 0;
      const name = pageName || pageType;
      for (let i = 0; i < name.length; i += 1) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = "#";
      for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
      }
      return {
        initials: name[0],
        tooltip: name,
        backgroundColor: color,
        color: theme.palette.getContrastText(color),
      };
  }
};

const useStyles = makeStyles(() => ({
  wrapper: (props: PropTypes) => ({
    width: props.size || 25,
    height: props.size || 25,
    whiteSpace: "pre-wrap",
    fontSize: 12,
    lineHeight: 1.25,
  }),
}));

type PropTypes = {
  pageType: ePageType | string;
  pageName?: string;
  size?: number;
  className?: string;
};

const MPageTypeAvatar = (props: PropTypes) => {
  const { pageType, pageName, className } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const avatarProps = React.useMemo(() => getAvatarProps(pageType, pageName, theme), [pageType, pageName]);

  return (
    <Tooltip enterDelay={2000} title={avatarProps.tooltip}>
      <Avatar
        style={{ color: avatarProps.color, backgroundColor: avatarProps.backgroundColor }}
        className={ClassNames(classes.wrapper, className)}
      >
        {avatarProps.initials.toUpperCase()}
      </Avatar>
    </Tooltip>
  );
};

export default MPageTypeAvatar;
