import { Theme, Typography, makeStyles } from "@material-ui/core";
import ClassNames from "classnames";
import React from "react";
import MSwitch from "../../../../../../../../../components/MSwitch";

type PropTypes = {
  label?: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
};

const useStyles = makeStyles(({ palette, typography }: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  title: {
    color: palette.text.primary,
    flex: 1,
    fontSize: typography.pxToRem(14),
  },
  switchBase: {
    color: palette.common.white,
    "&$checked": {
      color: palette.common.white,
    },
    "&$checked + $track": {
      backgroundColor: palette.primary.light,
    },
    "&disabled + $checked": {
      color: "black",
    },
  },
  checked: {},
  track: {},
  disabled: {
    color: "#424242",
    cursor: "not-allowed",
    "&$checked": {
      color: "#424242",
      cursor: "not-allowed",
    },
    "&$checked + $track": {
      backgroundColor: palette.common.white,
      opacity: 0.1,
    },
  },
}));

const SwitchProperty = (props: PropTypes) => {
  const { checked, onChange, className, label } = props;
  const classes = useStyles(props);
  return (
    <div className={ClassNames(classes.root, className)}>
      <Typography className={classes.title} variant={"subtitle2"}>
        {label}
      </Typography>
      <MSwitch onChange={onChange} checked={checked} />
    </div>
  );
};

export default SwitchProperty;
