import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;

  return {
    root: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
    },
    rightContainer: {
      minWidth: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    label: {
      color: palette.text.primary,
      fontSize: theme.typography.pxToRem(14),
    },
    button: () => {
      const backgroundColor = palette.secondary.main;
      const colorOnHover = palette.common.white;
      const backgroundColorOnHover = palette.primary.main;

      return {
        cursor: "pointer",
        backgroundColor,
        color: palette.text.primary,
        fontSize: 12,
        textTransform: "none",
        display: "grid",
        placeItems: "center",
        flex: 1,
        height: 32,
        border: `1px solid ${palette.divider}`,
        marginLeft: -1,
        padding: 0,
        "&:hover": {
          color: colorOnHover,
          backgroundColor: backgroundColorOnHover,
        },
      };
    },
    selected: () => ({
      color: palette.common.white,
      backgroundColor: palette.primary.main,
    }),
    firstButton: {
      borderRadius: "3px 0px 0px 3px",
    },
    lastButton: {
      borderRadius: "0px 3px 3px 0px",
    },
    fullWidth: {
      flex: 1,
    },
  };
});

type PropTypes = {
  onChange: (value: string) => void;
  label?: string;
  value?: string;
  options?: string[];
  showLabel?: boolean;
  className?: string;
};

const SelectProperty = (props: PropTypes) => {
  const { showLabel, label, value, className, onChange } = props;
  const classes = useStyles(props);
  const defaultOptions = ["select_default_option_one", "select_default_option_two"];

  const options = props.options ?? defaultOptions;

  return (
    <div className={classNames(classes.root, className)}>
      {showLabel && (
        <Typography className={classes.label} variant={"subtitle2"}>
          {label ?? I18n.t("widgets_builder.widget_editor.Form.fields.properties.select_default_label")}
        </Typography>
      )}
      <div className={classNames(classes.rightContainer, !showLabel && classes.fullWidth)}>
        {options?.map((option, i) => (
          <div
            key={option}
            className={classNames(
              classes.button,
              i === 0 && classes.firstButton,
              i === options.length - 1 && classes.lastButton,
              value === option && classes.selected
            )}
            onClick={() => onChange && onChange(option)}
          >
            {I18n.t(`widgets_builder.widget_editor.selectPropertyLabels.${option}`)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectProperty;
