import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Theme,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { WIDGET_BUILDER } from "../../../../../../../../reducers/constants/actionTypes";
import { IWidgetSettings } from "../../../../../../../../reducers/constants/objectTypes";
import AppTheme from "../../../../../../../../themes/theme";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  container: {
    width: "100%",
    backgroundColor: palette.secondary.dark,
    borderRadius: 3,
    border: `1px solid ${palette.grey["100"]}`,
    padding: "10px 15px",
  },
  label: {
    fontSize: 12,
    opacity: 0.5,
  },
  formLabel: {
    fontSize: 14,
    "& span": {
      fontSize: 14,
    },
    "& svg": {
      fontSize: 18,
    },
  },
  checkbox: {
    fontSize: 18,
  },
}));

type PropTypes = {
  settings: IWidgetSettings;
  editSettings: (settings: IWidgetSettings) => void;
};

const mapStateToProps = (state) => ({
  settings: state.widgetBuilder.widget.settings,
});

const mapDispatchToProps = (dispatch) => ({
  editSettings: (payload: IWidgetSettings) => dispatch({ type: WIDGET_BUILDER.EDIT_SETTINGS, payload }),
});

const DisplayDevicesSettings = (props: PropTypes) => {
  const { settings } = props;
  const { editSettings } = props;
  const { displayDevices = [] } = settings;

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const name = event.target.name as IWidgetSettings["displayDevices"][number];
    let newDisplayDevices = [...displayDevices];
    if (checked) {
      newDisplayDevices.push(name);
    } else {
      newDisplayDevices = newDisplayDevices.filter((device) => device !== name);
    }
    editSettings({ ...settings, displayDevices: newDisplayDevices });
  };

  return (
    <div>
      <div className={classes.container}>
        <ThemeProvider theme={AppTheme}>
          <FormControl component="fieldset">
            <Typography className={classes.formLabel}>
              {I18n.t(`widgets_builder.widget_editor.Dialog.settings.devices.form_label`)}
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={displayDevices.includes("desktop")}
                    onChange={handleChange}
                    name="desktop"
                    className={classes.checkbox}
                  />
                }
                className={classes.formLabel}
                label={I18n.t(`widgets_builder.widget_editor.Dialog.settings.devices.desktop`)}
              />
              <FormControlLabel
                color="secondary"
                control={
                  <Checkbox
                    color="primary"
                    checked={displayDevices.includes("tablet")}
                    onChange={handleChange}
                    name="tablet"
                    className={classes.checkbox}
                  />
                }
                className={classes.formLabel}
                label={I18n.t(`widgets_builder.widget_editor.Dialog.settings.devices.tablet`)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={displayDevices.includes("mobile")}
                    onChange={handleChange}
                    name="mobile"
                    className={classes.checkbox}
                  />
                }
                className={classes.formLabel}
                label={I18n.t(`widgets_builder.widget_editor.Dialog.settings.devices.mobile`)}
              />
            </FormGroup>
          </FormControl>
        </ThemeProvider>
      </div>
      <Typography className={classes.label}>
        {I18n.t(`widgets_builder.widget_editor.Dialog.settings.devices.label`)}
      </Typography>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplayDevicesSettings);
