import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 17,
    height: 15,
    fill: "transparent",
  },
}));

type PropTypes = {
  className?: string;
};

const PaddingRightIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 17 15"}>
      <path
        d="M13.6001 16.2L13.6001 0.999952M3.8001 11.7999L6.4001 11.8C7.72558 11.8 8.8001 10.7254 8.8001 9.39995L8.8001 6.79995C8.8001 5.47447 7.72558 4.39995 6.4001 4.39995L3.8001 4.39995C2.47462 4.39995 1.4001 5.47447 1.4001 6.79995L1.4001 9.39995C1.4001 10.7254 2.47462 11.7999 3.8001 11.7999Z"
        stroke="#333333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default PaddingRightIcon;
