import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import Divider from "@material-ui/core/Divider";
import BoldIcon from "./icons/BoldIcon";
import ItalicIcon from "./icons/ItalicIcon";
import UnderlineIcon from "./icons/UnderlineIcon";
import BigTitleIcon from "./icons/BigTitleIcon";
import SmallTitleIcon from "./icons/SmallTitleIcon";
import CodeIcon from "./icons/CodeIcon";
import QuoteIcon from "./icons/QuoteIcon";
import NumberedListIcon from "./icons/NumberedListIcon";
import BulletedListIcon from "./icons/BulletedListIcon";
import AlignLeftIcon from "./icons/AlignLeftIcon";
import AlignCenterIcon from "./icons/AlignCenterIcon";
import AlignRightIcon from "./icons/AlignRightIcon";
import AlignJustifyIcon from "./icons/AlignJustifyIcon";
import MarkButton from "./components/MarkButton";
import BlockButton from "./components/BlockButton";
import AlignmentButton from "../alignment/AlignmentButton";
import LinkButton from "../link/LinkButton";
import InsertImageButton from "../image/InsertImageButton";
import InsertTableButton from "../table/InsertTableButton";
import InsertEmbedButton from "../embed/InsertEmbedButton";
import CommentButtonV2 from "../commentsv2/CommentButtonV2";
import InlineHtmlButton from "../inlineHTML/InlineHtmlButton";
import PreviewButton from "../preview/PreviewButton";
import UndoRedoButton from "../history/UndoRedoButton";
import TextColorButton from "../fontColor/TextColorButton";
import { IWebsite } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  toolbarWrapper: {
    zIndex: 100,
    position: "sticky",
    top: 0,
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderBottom: `${theme.palette.divider} solid 1px`,
    overflowX: "auto",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
  },
  toolbarDivider: {
    height: 25,
  },
}));

type PropTypes = {
  website: IWebsite;
  imageSizeLimit?: number;
  toolbarCustomClass?: string;
  withComments?: boolean;
  withTables?: boolean;

  previewMode: boolean;
  handlePreviewModeChange: (previewMode: boolean) => void;
};

function EditorToolbar(props: PropTypes) {
  const classes = useStyles(props);
  const {
    website,
    imageSizeLimit,
    toolbarCustomClass,
    withComments = false,
    withTables = false,
    previewMode,
    handlePreviewModeChange,
  } = props;

  return (
    <div className={ClassNames(classes.toolbarWrapper, toolbarCustomClass)}>
      <PreviewButton previewMode={previewMode} handlePreviewModeChange={handlePreviewModeChange} />
      {withComments && <CommentButtonV2 />}
      <Divider className={classes.toolbarDivider} orientation="vertical" />
      <MarkButton format="bold" Icon={BoldIcon} tooltip={I18n.t("rich_text_editor.tooltips.bold")} />
      <MarkButton format="italic" Icon={ItalicIcon} tooltip={I18n.t("rich_text_editor.tooltips.italic")} />
      <MarkButton format="underline" Icon={UnderlineIcon} tooltip={I18n.t("rich_text_editor.tooltips.underline")} />
      <BlockButton format="heading-one" Icon={BigTitleIcon} tooltip={I18n.t("rich_text_editor.tooltips.h2")} />
      <BlockButton format="heading-two" Icon={SmallTitleIcon} tooltip={I18n.t("rich_text_editor.tooltips.h3")} />
      <TextColorButton themeProps={website.configurations.theme} />
      <Divider className={classes.toolbarDivider} orientation="vertical" />
      <MarkButton format="code" Icon={CodeIcon} tooltip={I18n.t("rich_text_editor.tooltips.code")} />
      <BlockButton format="block-quote" Icon={QuoteIcon} tooltip={I18n.t("rich_text_editor.tooltips.quote")} />
      <Divider className={classes.toolbarDivider} orientation="vertical" />
      <LinkButton />
      {withTables && <InsertTableButton />}
      <InsertImageButton website={website} imageSizeLimit={imageSizeLimit} />
      <InsertEmbedButton />
      <InlineHtmlButton />
      <Divider className={classes.toolbarDivider} orientation="vertical" />
      <BlockButton
        format="numbered-list"
        Icon={NumberedListIcon}
        tooltip={I18n.t("rich_text_editor.tooltips.ordered_list")}
      />
      <BlockButton
        format="bulleted-list"
        Icon={BulletedListIcon}
        tooltip={I18n.t("rich_text_editor.tooltips.bulleted_list")}
      />
      <Divider className={classes.toolbarDivider} orientation="vertical" />
      <AlignmentButton align={"left"} Icon={AlignLeftIcon} tooltip={I18n.t("rich_text_editor.tooltips.align_left")} />
      <AlignmentButton
        align={"center"}
        Icon={AlignCenterIcon}
        tooltip={I18n.t("rich_text_editor.tooltips.align_center")}
      />
      <AlignmentButton
        align={"right"}
        Icon={AlignRightIcon}
        tooltip={I18n.t("rich_text_editor.tooltips.align_right")}
      />
      <AlignmentButton
        align={"justify"}
        Icon={AlignJustifyIcon}
        tooltip={I18n.t("rich_text_editor.tooltips.align_justify")}
      />
      <Divider className={classes.toolbarDivider} orientation="vertical" />
      {/* {enableEditableVoids && <EditableVoidsButton />} */}
      <UndoRedoButton undoButton={true} />
      <UndoRedoButton undoButton={false} />
    </div>
  );
}

export default EditorToolbar;
