import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Button, Theme, Typography } from "@material-ui/core";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";

import {
  IWebsite,
  IWidget,
  ICampaign,
  eWidgetType,
  eMpEvent,
} from "../../../../../../../../../../reducers/constants/objectTypes";
import SwitchProperty from "../../../../../../../WidgetsBuilder/FormWidgetEditor/build/items/itemProperties/components/SwitchProperty";
import MDynamicListItem from "../../../../../../../../../../components/MDynamicListItem";
import { HOME, WIDGET_RULE_EDITOR } from "../../../../../../../../../../reducers/constants/actionTypes";
import {
  WidgetRuleEditorRootState,
  WidgetRuleEditorSelectedView,
} from "../../../../../../../../../../reducers/widgetRuleEditorReducer";
import RuleBuilderHeader from "../../RuleBuilderHeader";
import MSelect from "../../../../../../../../../../components/MSelect";
import agent from "../../../../../../../../../../agent";
import FormWidgetEditor from "../../../../../../../WidgetsBuilder/FormWidgetEditor/FormWidgetEditor";
import MSwitch from "../../../../../../../../../../components/MSwitch";
import WidgetPicker from "../../../../../../../../../Modals/WidgetPicker/WidgetPicker";
import WidgetContainerDevicePosition from "./WidgetContainerDevicePosition";
import { getWidgetHTMLContainer, scrollToContainer } from "../../../../../../../../../../helpers/htmlPreviewHelper";
import LocateIcon from "../../../../../../../../../../icons/LocateIcon";

const useStyles = makeStyles((theme: Theme) => ({
  itemsWrapperWithGap: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  mainContainer: {
    flex: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
    marginBottom: 5,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 3,
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectIndicator: {
    marginRight: 5,
    width: 12,
  },
  noPaddingIndicator: {
    marginRight: -10,
  },
  groupedItems: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  bottomButtons: {
    display: "flex",
    gap: 3,
  },
  editButton: {
    width: "100%",
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular as any,
    padding: 15,
    height: 55,
    borderRadius: 3,
    borderWidth: 1,
    backgroundColor: theme.palette.secondary.dark,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#DEE1FF",
    },
  },
  deleteButton: {
    width: "100%",
    fontSize: 14,
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightRegular as any,
    padding: 15,
    height: 55,
    borderRadius: 3,
    borderWidth: 1,
    backgroundColor: theme.palette.secondary.dark,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(240, 68, 68, 0.15)",
    },
  },
  iconButton: {
    padding: 9.5,
    borderRadius: 0,
  },
  icon: {
    fontSize: 18,
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  positionRightText: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  locateIcon: {
    fill: theme.palette.text.secondary,
  },
  locateIconEnabled: {
    fill: theme.palette.text.primary,
    "&:hover": {
      cursor: "pointer",
      fill: theme.palette.primary.main,
    },
  },
}));

const mapDispatchToProps = (dispatch) => ({
  handlePropertyChange: (property: Record<string, any>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PROPERTY_CHANGE, property });
  },
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
});

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  rule: state.widgetRuleEditor.rule,
  preview: state.widgetRuleEditor.preview,
  draftContainer: state.widgetRuleEditor.draftContainer,
  selectedWidgetContainerId: state.widgetRuleEditor.selectedWidgetContainerId,
});

type PropTypes = {
  selectedWebsite: IWebsite;
  rule: ICampaign;
  preview: WidgetRuleEditorRootState["preview"];
  draftContainer: ICampaign["widgetContainers"][number];
  selectedWidgetContainerId: string;
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => void;
  mpEvent: (event: string, props: object) => void;
};

const widgetTypesOptions = [
  { value: "all", label: "Enable auto testing" },
  { value: "specific", label: "Select a specific widget" },
];

const I18nRoute = "widgets_display_rules_editor.builder.container_settings_view.main_view";

const WidgetContainerMainView = (props: PropTypes) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  const { draftContainer, selectedWidgetContainerId, rule, selectedWebsite, preview } = props;
  const { handlePropertyChange, mpEvent } = props;

  const [widgets, setWidgets] = React.useState<IWidget[]>([]);
  const [showEditWidgetPopup, setShowEditWidgetPopup] = React.useState(false);
  const [showWidgetPicker, setShowWidgetPicker] = React.useState(false);

  const storeContainer = rule.widgetContainers.find((container) => container.containerId === selectedWidgetContainerId);

  const handleDraftContainerChange = (container: ICampaign["widgetContainers"][number]) => {
    handlePropertyChange({
      draftContainer: container,
    });
  };

  const getWidgets = (search = "") => {
    if (widgets.length > 0 && !search) return;
    agent.Widgets.getAutoComplete(
      selectedWebsite,
      search,
      [eWidgetType.FORM, eWidgetType.CUSTOM_WIDGET, eWidgetType.INLINE],
      undefined,
      null,
      null,
      20,
      true
    )
      .then((res) => {
        setWidgets(res.widgets);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    getWidgets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (draftContainer.widgetFilter.value === "specific" && !draftContainer.widgetFilter.widget && widgets.length > 0) {
      handleDraftContainerChange({
        ...draftContainer,
        widgetFilter: {
          ...draftContainer.widgetFilter,
          widget: widgets[0],
        },
      });
    }
  }, [draftContainer, widgets]);

  const saveChanges = () => {
    handlePropertyChange({
      rule: {
        ...rule,
        widgetContainers: rule.widgetContainers.map((c) =>
          c.containerId === draftContainer.containerId ? draftContainer : c
        ),
      },
      selectedView: WidgetRuleEditorSelectedView.MAIN,
      selectedPositionEdit: null,
    });
  };

  const deleteContainer = () => {
    mpEvent(eMpEvent.croCampaignButtonClick, {
      type: "remove_widget",
      text: I18n.t(`${I18nRoute}.labels.remove_widget`),
    });
    handlePropertyChange({
      rule: {
        ...rule,
        widgetContainers: rule.widgetContainers.filter(
          (container) => container.containerId !== draftContainer.containerId
        ),
      },
      selectedView: null,
      selectedPositionEdit: null,
    });
  };

  const showOnDeviceOptions = [
    {
      label: I18n.t(`${I18nRoute}.labels.desktop`),
      value: "desktop",
    },
    {
      label: I18n.t(`${I18nRoute}.labels.mobile`),
      value: "mobile",
    },
    {
      label: I18n.t(`${I18nRoute}.labels.desktop_and_mobile`),
      value: "desktop_and_mobile",
    },
  ];

  const getSelectedShowOnDeviceOption = () => {
    if (draftContainer.position["mobile"].enabled && draftContainer.position["desktop"].enabled) {
      return showOnDeviceOptions[2];
    }
    if (draftContainer.position["mobile"].enabled) {
      return showOnDeviceOptions[1];
    }
    return showOnDeviceOptions[0];
  };

  const saveButtonDisabled = isEqual(draftContainer, storeContainer);
  const locateEnabled = !preview.loading && getWidgetHTMLContainer(selectedWidgetContainerId);

  return (
    <>
      <div className={classes.itemsWrapperWithGap}>
        <RuleBuilderHeader
          title={I18n.t(`${I18nRoute}.title`)}
          rightContent={
            <LocateIcon
              className={classNames(classes.locateIcon, locateEnabled && classes.locateIconEnabled)}
              onClick={locateEnabled ? () => scrollToContainer(selectedWidgetContainerId) : undefined}
            />
          }
          onBackClick={() =>
            handlePropertyChange({ selectedView: WidgetRuleEditorSelectedView.MAIN, selectedPositionEdit: null })
          }
          buttonProps={{
            disabled: saveButtonDisabled,
            onClick: saveChanges,
          }}
        />
        <div className={classNames(classes.itemsWrapperWithGap, classes.mainContainer)}>
          <div className={classes.groupedItems}>
            <Typography className={classes.title}>{I18n.t(`${I18nRoute}.labels.widget`)}</Typography>
            {/* <MSelect
              customClass={classes.selectCustomClass}
              customDropdownIndicatorClasses={classes.selectIndicator}
              valuePadding={"2px 10px 2px 6px"}
              options={widgetTypesOptions}
              value={widgetTypesOptions.find((option) => option.value === draftContainer.widgetFilter.value)}
              optionLabel={"label"}
              optionValue={"value"}
              height={50}
              borderWidth={"0px"}
              isBold={false}
              textColor={theme.palette.text.primary}
              menuPlacement={"bottom"}
              singleValueStyles={{ fontSize: theme.typography.pxToRem(14) }}
              fullWidth
              getDisplayLabel={(vertical) => `${vertical.label}`}
              handleChange={(option) =>
                handleDraftContainerChange({
                  ...draftContainer,
                  widgetFilter: {
                    ...draftContainer.widgetFilter,
                    value: option.value,
                  },
                })
              }
            /> */}
            {draftContainer.widgetFilter.value === "specific" && (
              <MDynamicListItem
                title={draftContainer.widgetFilter.widget?.name || I18n.t(`${I18nRoute}.labels.select_widget`)}
                fontWeight={theme.typography.fontWeightRegular}
                onClick={() => setShowWidgetPicker(true)}
              />
            )}
            <MDynamicListItem
              title={I18n.t(`${I18nRoute}.labels.show_on`)}
              noArrow
              fontWeight={theme.typography.fontWeightRegular}
              rightIconsComponent={
                <MSelect
                  customClass={classes.selectCustomClass}
                  customDropdownIndicatorClasses={classNames(classes.selectIndicator, classes.noPaddingIndicator)}
                  valuePadding={"0"}
                  placeholder={I18n.t(`${I18nRoute}.labels.show_on`)}
                  options={showOnDeviceOptions}
                  value={getSelectedShowOnDeviceOption()}
                  menuPosition="fixed"
                  optionLabel={"label"}
                  optionValue={"value"}
                  height={50}
                  borderWidth={"0px"}
                  isBold={false}
                  textColor={theme.palette.text.primary}
                  menuPlacement={"bottom"}
                  singleValueStyles={{ fontSize: theme.typography.pxToRem(14), marginRight: 5 }}
                  fullWidth
                  getDisplayLabel={(selected) => `${selected.label}`}
                  handleChange={(option) =>
                    handleDraftContainerChange({
                      ...draftContainer,
                      position: {
                        ...draftContainer.position,
                        ["mobile"]: {
                          ...draftContainer.position["mobile"],
                          enabled: option.value === "mobile" || option.value === "desktop_and_mobile",
                        },
                        ["desktop"]: {
                          ...draftContainer.position["desktop"],
                          enabled: option.value === "desktop" || option.value === "desktop_and_mobile",
                        },
                      },
                    })
                  }
                />
              }
            />
          </div>
          <div className={classes.groupedItems}>
            <Typography className={classes.title}>{I18n.t(`${I18nRoute}.labels.position`)}</Typography>
            <MDynamicListItem
              title={I18n.t(`${I18nRoute}.labels.same_rules`)}
              noArrow
              fontWeight={theme.typography.fontWeightRegular}
              onClick={() =>
                handleDraftContainerChange({
                  ...draftContainer,
                  position: {
                    ...draftContainer.position,
                    sameDesktopMobilePosition: !draftContainer.position.sameDesktopMobilePosition,
                  },
                })
              }
              rightIconsComponent={
                <MSwitch
                  checked={draftContainer.position.sameDesktopMobilePosition}
                  onChange={(_, e) => e.preventDefault()}
                  onClick={(e) => e.preventDefault()}
                />
              }
            />
            {draftContainer.position.sameDesktopMobilePosition ? (
              <WidgetContainerDevicePosition
                position={draftContainer.position["desktop_mobile"]}
                onChange={(updatedDevicePosition) =>
                  handleDraftContainerChange({
                    ...draftContainer,
                    position: {
                      ...draftContainer.position,
                      ["desktop_mobile"]: updatedDevicePosition,
                    },
                  })
                }
                onReselectPositionClick={() =>
                  handlePropertyChange({ reselectPositionMode: true, selectedPositionEdit: "desktop_mobile" })
                }
              />
            ) : (
              <>
                <MDynamicListItem
                  title={I18n.t(`${I18nRoute}.labels.edit_mobile_position`)}
                  fontWeight={theme.typography.fontWeightRegular}
                  onClick={() =>
                    handlePropertyChange({
                      selectedPositionEdit: "mobile",
                      selectedView: WidgetRuleEditorSelectedView.WIDGET_CONTAINER_SETTINGS_POSITION,
                    })
                  }
                />
                <MDynamicListItem
                  title={I18n.t(`${I18nRoute}.labels.edit_desktop_position`)}
                  fontWeight={theme.typography.fontWeightRegular}
                  onClick={() =>
                    handlePropertyChange({
                      selectedPositionEdit: "desktop",
                      selectedView: WidgetRuleEditorSelectedView.WIDGET_CONTAINER_SETTINGS_POSITION,
                    })
                  }
                />
              </>
            )}
          </div>
        </div>
        <div className={classes.bottomButtons}>
          <Button
            className={classes.editButton}
            disabled={!draftContainer.widgetFilter.widget._id}
            disableRipple
            onClick={() => {
              mpEvent(eMpEvent.croCampaignButtonClick, {
                type: "edit_widget",
                text: I18n.t(`${I18nRoute}.labels.edit_widget`),
              });
              setShowEditWidgetPopup(true);
            }}
          >
            {I18n.t(`${I18nRoute}.labels.edit_widget`)}
          </Button>
          <Button className={classes.deleteButton} disableRipple onClick={deleteContainer}>
            {I18n.t(`${I18nRoute}.labels.remove_widget`)}
          </Button>
        </div>
      </div>
      {showEditWidgetPopup && (
        <FormWidgetEditor
          widgetId={draftContainer.widgetFilter.widget._id}
          handleClose={(widget) => {
            if (!isEqual(widget, draftContainer.widgetFilter.widget)) {
              handlePropertyChange({
                rule: {
                  ...rule,
                  widgetContainers: rule.widgetContainers.map((c) => {
                    if (c.containerId === draftContainer.containerId) {
                      return {
                        ...c,
                        widgetFilter: {
                          ...c.widgetFilter,
                          widget,
                        },
                      };
                    }
                    return c;
                  }),
                },
                draftContainer: {
                  ...draftContainer,
                  widgetFilter: {
                    ...draftContainer.widgetFilter,
                    widget,
                  },
                },
              });
            }
            setShowEditWidgetPopup(false);
          }}
        />
      )}
      {showWidgetPicker && (
        <WidgetPicker
          defaultWidget={draftContainer.widgetFilter.widget}
          handleClose={() => setShowWidgetPicker(false)}
          handleWidgetInsert={(widget) => {
            handleDraftContainerChange({
              ...draftContainer,
              widgetFilter: {
                ...draftContainer.widgetFilter,
                widget,
              },
            });
            setShowWidgetPicker(false);
          }}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetContainerMainView);
