import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import { replace } from "connected-react-router";
import { Link } from "react-router-dom";
import CfButton from "../../components/CfButton";
import agent from "../../agent";
import Divider from "@material-ui/core/Divider";
import AuthNavbar from "./components/AuthNavbar";
import AuthCard from "./components/AuthCard";
import AuthTextField from "./components/AuthTextField";
import { HOME } from "../../reducers/constants/actionTypes";
import { useSnackbar } from "notistack";
import GoogleLogin from "./components/GoogleLogin";
import { MIXPANEL_PAGE_VIEW } from "../../reducers/middlewares/mixpanelMiddleware";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
  },
  dividerWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100%",
    margin: "30px 0",
  },
  divider: {
    color: "black",
    height: 1,
    width: 121,
    backgroundColor: "#E5E5E5",
  },
  dividerText: {
    fontSize: 14,
    lineHeight: "17px",
    color: "#808080",
  },
  credentialsWrapper: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  passwordActions: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#808080",
    marginTop: -5,
  },
  rememberMeCheckbox: {
    margin: 0,
    marginLeft: 3,
  },
  forgotPasswordText: {
    marginRight: 8,
    fontSize: 14,
    lineHeight: "17px",
    color: "#808080",
    cursor: "pointer",
  },
  button: {
    marginTop: 20,
    minWidth: 200,
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  loginWithGoogle: {
    backgroundColor: "#4688F1",
    borderRadius: 45,
    height: 45,
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  },
  googleIconWrapper: {
    backgroundColor: "white",
    padding: 5,
    borderRadius: "100%",
    height: 27,
    width: 26,
    marginLeft: 10,
  },
  googleText: {
    color: "#FFFFFF",
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: 16,
    lineHeight: "19px",
    width: "100%",
    marginLeft: 51,
  },

  signUpSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
  noAccountText: {
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: 16,
    lineHeight: "19px",
    color: "#808080",
  },
  routerLink: {
    textDecoration: "none",
  },
  signUpText: {
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: 16,
    marginLeft: 10,
    lineHeight: "19px",
    color: "#4353FF",
    cursor: "pointer",
  },
  terms: {
    width: 290,
    height: 60,
    marginTop: 20,
    textAlign: "center",
  },
  termsText: {
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: 14,
    lineHeight: "20px",
    padding: 0,
  },
  termsLink: {
    color: "black",
    cursor: "pointer",
    fontWeight: theme.typography.fontWeightRegular as any,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type PropTypes = {
  pageLoaded: Function;
  onLogin: Function;
  onAdminUserLoaded: (payload) => void;
  adminUserLoadFailed: (message: string) => void;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  pageLoaded: () =>
    dispatch({
      type: HOME.PAGE_LOADED,
      meta: { mixpanel: { event: MIXPANEL_PAGE_VIEW, props: { "page-name": "login" } } },
    }),
  onLogin: (event, user, redirect) => {
    dispatch({ type: HOME.ADMIN_USER_LOGGED_IN, meta: { mixpanel: { event, props: { user } } } }),
      dispatch(replace(redirect));
  },
  onAdminUserLoaded: (payload) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: payload.user }),
  adminUserLoadFailed: (message: string) =>
    dispatch({
      type: HOME.ADMIN_USER_LOADED_FAILED,
      meta: { mixpanel: { event: "login-failed", props: { error: message } } },
    }),
});

const Login = (props: PropTypes) => {
  const classes = useStyles(props);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { pageLoaded, onLogin, onAdminUserLoaded, adminUserLoadFailed } = props;
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    pageLoaded();
    agent.Auth.getUser()
      .then((res) => {
        onLogin("login", res.user, "/", true);
        console.log(res);
      })
      .catch((e) => {
        // adminUserLoadFailed(e.response.body.message);
        console.log(e);
      });
  }, []);

  const handleLoginClick = () => {
    if (email.length === 0 || password.length === 0) {
      return;
    }

    agent.Auth.login(email, password)
      .then((res) => {
        onAdminUserLoaded(res);
        onLogin("login", res.user, res.to);
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
        adminUserLoadFailed(e.response.body.message);
        // console.log(e.response);
      });
  };

  return (
    <section className={classes.pageWrapper}>
      <AuthNavbar />
      <div className={classes.contentWrapper}>
        <AuthCard>
          <GoogleLogin />
          <div className={classes.dividerWrapper}>
            <Divider className={classes.divider} orientation={"horizontal"} />
            <Typography variant={"body2"} className={classes.dividerText}>
              {I18n.t("login.or")}
            </Typography>
            <Divider className={classes.divider} orientation={"horizontal"} />
          </div>

          <div className={classes.credentialsWrapper}>
            <AuthTextField label={I18n.t("login.email_address")} value={email} onChange={setEmail} />
            <AuthTextField type="password" label={I18n.t("login.password")} value={password} onChange={setPassword} />
          </div>
          <div className={classes.passwordActions}>
            <div></div>
            <Link to="forgot-password" className={classes.routerLink}>
              <Typography variant={"body2"} className={classes.forgotPasswordText}>
                {I18n.t("login.forgot_password")}
              </Typography>
            </Link>
          </div>
          <CfButton
            fullWidth
            customClass={classes.button}
            text={I18n.t("login.button")}
            onClick={(e) => handleLoginClick()}
          />
        </AuthCard>
        <div className={classes.terms}>
          <Typography variant={"body2"} className={classes.termsText}>
            {I18n.t("login.terms_first_part")}

            <a href={"https://entail.ai/app-terms-of-service"} target={"_blank"} className={classes.termsLink}>
              {I18n.t("login.TOS")}
            </a>

            {I18n.t("login.terms_second_part")}

            <a href={"https://entail.ai/privacy-policy"} target={"_blank"} className={classes.termsLink}>
              {I18n.t("login.privacy_policy")}
            </a>
          </Typography>
        </div>
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
