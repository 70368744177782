import * as React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import KeywordGroupPageColumns from "./KeywordGroupPageColumns";
import { KEYWORD_GROUP_PAGES } from "../../../reducers/constants/actionTypes";
import agent from "../../../agent";
import KeywordGroupPageListItem from "./KeywordGroupPageListItem";
import MTable from "../../../components/Table/Table/MTable";
import MTableRowProgressBar from "../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../components/Table/Rows/MTableRowEmptyRow";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  loading: state.keywordGroupPage.dataLoading,
  data: state.keywordGroupPage.dataList,
  selectedDataItemsIndexes: state.keywordGroupPage.selectedDataItemsIndexes,
  refetch: state.keywordGroupPage.refetch,
  total: state.keywordGroupPage.total,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoading(loading: boolean) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_DATA_LOADING, payload: loading });
  },
  changeIsDialogOpen(open: boolean) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_IS_DIALOG_OPEN_CHANGED, payload: open });
  },
  changeActiveDialogTab(tab: "outlines" | "keywords" | "broadKeywords" | "pages") {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_ACTIVE_DIALOG_TAB_CHANGED, payload: tab });
  },
  changeRefetch(refresh: boolean) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_REFETCH_CHANGED, payload: refresh });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
  changeSelectedItem(item: any) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_SELECTED_DATA_ITEM_CHANGED, payload: item });
  },
  changeBroadKeywordsLoaded(payload: any) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_BROAD_KEYWORDS_LOADED, payload });
  },
  redirect: (url) => dispatch(push(url)),
});

type PropTypes = {
  loading: boolean;
  data: any[];
  selectedDataItemsIndexes: number[];
  refetch: boolean;
  selectedWebsite: IWebsite;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  setSortField: (input: { direction: "desc" | "asc" | undefined; field: string }) => void;
  changeLoading(loading: boolean): void;
  changeBroadKeywordsLoaded(payload: any): void;
  changeSelectedItem(item: any): void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  changeIsDialogOpen: (open: boolean) => void;
  changeActiveDialogTab: (tab: "outlines" | "keywords" | "pages") => void;
  changeRefetch: (refresh: boolean) => void;

  redirect: (url: string) => void;
  total: number;
};

const KeywordGroupPageList = (props: PropTypes) => {
  const {
    changeLoading,
    selectedWebsite,
    changeActiveDialogTab,
    changeIsDialogOpen,
    changeSelectedDataItemsIndexes,
    loading,
    selectedDataItemsIndexes,
    data,
    refetch,
    redirect,
    changeRefetch,
    sortField,
    setSortField,
    total,
    changeSelectedItem,
    changeBroadKeywordsLoaded,
  } = props;

  const openDialog = async (page: any, tab: "outlines" | "keywords" | "pages") => {
    changeSelectedItem(page);
    changeBroadKeywordsLoaded(await agent.KeywordGroupPage.getPageKeywords(selectedWebsite._id, page.page));
    changeActiveDialogTab(tab);
    changeIsDialogOpen(true);
  };
  return (
    <MTable>
      <KeywordGroupPageColumns
        total={total}
        selectAll={() => {
          changeSelectedDataItemsIndexes(data.map((item, index) => index));
        }}
        clearSelected={() => {
          changeSelectedDataItemsIndexes([]);
        }}
        selectedNr={selectedDataItemsIndexes.length}
        sortField={sortField}
        setSortField={setSortField}
      />
      {!loading && data?.length < 1 && <MTableRowEmptyRow />}

      {loading && <MTableRowProgressBar />}

      {!loading &&
        data?.length > 0 &&
        data.map((item, index) => (
          <KeywordGroupPageListItem
            selectedWebsite={selectedWebsite}
            redirect={redirect}
            selected={selectedDataItemsIndexes.includes(index)}
            setSelect={(selected) => {
              if (selected) {
                changeSelectedDataItemsIndexes([...selectedDataItemsIndexes, index]);
              } else {
                changeSelectedDataItemsIndexes(selectedDataItemsIndexes.filter((i) => i !== index));
              }
            }}
            item={item}
            key={index}
            onClick={() => {
              openDialog(item, "keywords");
            }}
          />
        ))}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordGroupPageList);
