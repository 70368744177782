import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { I18n } from "react-redux-i18n";
import { Transforms } from "slate";
import { find } from "lodash";
import { IProduct, IWebsite } from "../../../../reducers/constants/objectTypes";
import { Widget } from "../WithWidgets";
import ButtonWidgetBaseElement from "../baseElements/ButtonWidgetBaseElement";
import { Button } from "../../../types/editor.Types";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: (props: PropTypes) => ({
    width: "100%",
    display: "flex",
    justifyContent:
      props.element.data?.button?.alignment === "left"
        ? "flex-start"
        : props.element.data?.button?.alignment === "right"
        ? "flex-end"
        : "center",
  }),
  buttonDefault: {
    padding: "15px 50px",
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: Button;

  products: IProduct[];
  website: IWebsite;
  readMode?: boolean;
};

export const getRawText = (n: Node) => "";

const ButtonWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, website, products, readMode = false } = props;
  const editor = useSlateStatic();
  const websiteTheme = website.configurations.theme;
  const handleChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const defaultData = {
    button: {
      show: true,
      showArrow: false,
      css: {},
    },
  };

  React.useEffect(() => {
    console.log("element.data", element.data);
    console.log("defaultData", defaultData);
    handleChange({ ...defaultData, ...(element.data || {}) });
  }, []);

  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  return (
    <div {...attributes}>
      <Widget
        readMode={readMode}
        title={I18n.t("rich_text_editor.custom_components.button")}
        element={element}
        editable
      >
        <div className={classes.wrapper}>
          <ButtonWidgetBaseElement
            className={classes.buttonDefault}
            text={
              element.data?.button?.text ||
              product?.callToAction ||
              I18n.t("rich_text_editor.choose_product_to_add", { field: "Call To Action" })
            }
            url={
              element.data?.button?.url ||
              product?.callToActionURL ||
              I18n.t("rich_text_editor.choose_product_to_add", { field: "URL" })
            }
            css={element.data?.button?.css}
            hoverCss={element.data?.button?.css?.["&:hover"] || {}}
            showArrow={element.data?.button?.showArrow || false}
            readMode={readMode}
            websiteTheme={websiteTheme}
          />
        </div>
        {children}
      </Widget>
    </div>
  );
};

export default ButtonWidget;
