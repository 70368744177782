import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import React from "react";
import ClassNames from "classnames";

import ArrowRightRoundedIcon from "../../../../../icons/ArrowRightRoundedIcon";
import icon from "../../../../../img/widgets/icons/cons.png";
import { colors } from "../../../../../helpers/constants";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: {
      padding: "10px 15px 10px 15px",
      backgroundColor: "#EEF2F9",
      "&:hover": {
        backgroundColor: colors.widgetOnHoverBackgroundColor,
      },
    },
    header: (props) => ({
      flex: 1,
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      width: "100%",
      cursor: "pointer",
      "&:hover $leftIcon": {
        visibility: "visible",
      },
    }),
    leftContainer: (props: PropTypes) => ({
      display: "flex",
      alignItems: "center",
      maxWidth: 300,
      flex: 1,
    }),
    icon: (props: PropTypes) => ({
      width: 30,
      height: 30,
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      marginRight: 10,
      borderRadius: 3,
    }),
    title: (props: PropTypes) => {
      const color = palette.text.primary;
      return {
        fontSize: 14,
        fontWeight: typography.fontWeightMedium as any,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: 150,
        color,
        marginLeft: 10,
        userSelect: "none",
      };
    },
    rightContainer: (props: PropTypes) => ({
      display: "flex",
      flex: 1,
      justifySelf: "flex-end",
      justifyContent: "flex-end",
      alignItems: "center",
      marginRight: -20,
    }),
    leftIcon: (props: PropTypes) => {
      const color = isDark ? palette.text.secondary : palette.secondary.main;
      return {
        visibility: "hidden",
        boxSizing: "content-box",
        color,
        padding: 5,
      };
    },
    arrowIcon: () => ({
      visibility: "visible !important" as "visible",
      color: palette.text.primary,
      fontSize: 19,
    }),
    img: {
      height: 30,
      width: 30,
      userSelect: "none",
    },
  };
});

type PropTypes = {
  onClick?(event: any): unknown;
};

const SubmitButton = (props: PropTypes) => {
  const { onClick } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.header} onClick={onClick}>
        <div className={classes.leftContainer}>
          <img src={icon} className={classes.img} />
          <Typography className={`${classes?.title}`}>
            {I18n.t("widgets_builder.widget_editor.Form.fields.properties.submit_button")}
          </Typography>
        </div>

        <div className={classes.rightContainer}>
          <ArrowRightRoundedIcon className={ClassNames(classes.arrowIcon, classes.leftIcon)} />
        </div>
      </div>
    </div>
  );
};

export default SubmitButton;
