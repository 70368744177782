import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { IKeywordClassification, IWebsite, IWebsiteGoals } from "../../reducers/constants/objectTypes";
import { KEYWORDCLASSIFICATION } from "../../reducers/constants/actionTypes";
import KeywordsClassificationListItem from "./KeywordsClassificationListItem";
import MTable from "../../components/Table/Table/MTable";
import MTableHeader from "../../components/Table/Table/MTableHeader";
import MTableHeaderLabelWithSelectAll from "../../components/Table/Table/MTableHeaderLabelWithSelectAll";
import MTableHeaderSortLabel from "../../components/Table/Table/MTableHeaderSortLabel";
import MTableHeaderLabel from "../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../components/Table/Rows/MTableRowEmptyRow";
import agent from "../../agent";

type PropTypes = {
  handleCheckedBoxChanged: (checkedKeywordsClassificationList: IKeywordClassification[]) => void;
  keywordsClassificationList: IKeywordClassification[];
  keywordsClassificationLoading: boolean;
  selectedKeywordsClassificationList: IKeywordClassification[];
  sortField: { field: string; direction: "desc" | "asc" | undefined };
  selectedWebsite: IWebsite;
  setSortFiled: (result: { field: string; direction: "desc" | "asc" | undefined }) => void;
};

const mapStateToProps = (state) => ({
  keywordsClassificationList: state.keywordsClassification.data,
  keywordsClassificationLoading: state.keywordsClassification.loading,
  selectedKeywordsClassificationList: state.keywordsClassification.selectedKeywordsClassificationList,
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  handleCheckedBoxChanged: (selectedKeywordsClassificationList) => {
    dispatch({
      type: KEYWORDCLASSIFICATION.CHANGE_SELECTED_KEYWORDS_CLASSIFICATION_LIST,
      payload: selectedKeywordsClassificationList,
    });
  },
});

const KeywordsClassificationList = (props: PropTypes) => {
  const {
    keywordsClassificationList,
    keywordsClassificationLoading,
    selectedKeywordsClassificationList,
    handleCheckedBoxChanged,
    sortField,
    selectedWebsite,
    setSortFiled,
  } = props;

  const [websiteGoals, setWebsiteGoals] = useState<IWebsiteGoals[]>([]);

  useEffect(() => {
    agent.WebsiteGoals.get(selectedWebsite)
      .then((res) => {
        setWebsiteGoals(res.websiteGoals);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [selectedWebsite]);

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabelWithSelectAll
          selected={selectedKeywordsClassificationList?.length > 0}
          columnWidth={"33%"}
          onSelectAll={() => {
            handleCheckedBoxChanged(keywordsClassificationList);
          }}
          onClearAll={() => {
            handleCheckedBoxChanged([]);
          }}
        >
          {I18n.t("fetch_keywords.seed")}
        </MTableHeaderLabelWithSelectAll>

        <MTableHeaderSortLabel field={"count"} onClick={(result) => setSortFiled(result)} sortField={sortField}>
          {I18n.t("classify_keywords.count")}
        </MTableHeaderSortLabel>
        <MTableHeaderLabel> {I18n.t("classify_keywords.type")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("classify_keywords.level")}</MTableHeaderLabel>
        <MTableHeaderLabel columnWidth={"15%"}>{I18n.t("classify_keywords.goals")}</MTableHeaderLabel>

        <MTableHeaderSortLabel field={"createdAt"} onClick={(result) => setSortFiled(result)} sortField={sortField}>
          {I18n.t("classify_keywords.createdAt")}
        </MTableHeaderSortLabel>
        <MTableHeaderLabel columnWidth={"15%"}>{""}</MTableHeaderLabel>
      </MTableHeader>

      {keywordsClassificationLoading && <MTableRowProgressBar />}

      {!keywordsClassificationLoading && keywordsClassificationList?.length < 1 && <MTableRowEmptyRow />}
      {!keywordsClassificationLoading &&
        keywordsClassificationList?.map((item, index) => (
          <KeywordsClassificationListItem
            clientGoals={websiteGoals}
            isFirstRow={index === 0}
            isLastRow={index === keywordsClassificationList.length - 1}
            keywordsClassificationListItem={item}
            key={index}
          />
        ))}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordsClassificationList);
