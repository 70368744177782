// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";

import { IWebsite } from "../../../../reducers/constants/objectTypes";
import ReportCard from "./ReportCard";
import useReport from "./useReport";
import { ACTION_REPORTS_OPTIONS, REPORT_CACHE_KEY_NAME } from "../../../../helpers/constants";
import CTAClicksChart from "./charts/CTAClicksChart";
import MSelect from "../../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: 150,
    margin: 0,
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.pxToRem(14),
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  startDate: Date;
};

const CTAClicksEntailReport = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, startDate } = props;
  const [selectedTimeFrame, setSelectedTimeFrame] = React.useState({ value: "month", label: "Month" });
  const [reportType, setReportType] = useState<any>("cta-clicks");

  const extraParams = React.useMemo(
    () => ({ timeFrame: selectedTimeFrame.value, pageFilter: "true" }),
    [selectedTimeFrame.value, selectedWebsite]
  );

  const getClicksLabel = () => {
    if (reportType === "internal-link-click") {
      return I18n.t("reports.dashboard.internal_link_click");
    }
    if (reportType === "outbound-link-click") {
      return I18n.t("reports.dashboard.outbound_link_click");
    }

    return undefined;
  };

  const {
    loading,
    fetchResponse,
    dateFormat,
    monthFormat,
    triggerRefresh,
    setTriggerRefresh,
    fullDateFormat,
    numberFormat,
    titlesFormat,
  } = useReport({
    selectedWebsite,
    startDate,
    reportType,
    extraParams,
  });

  const theme = useTheme();

  const { data, cacheData } = React.useMemo(() => {
    if (fetchResponse) {
      return {
        data: fetchResponse.results,
        cacheData: {
          cache: fetchResponse.cache,
          lastFetch: fetchResponse.fetchedTime,
        },
      };
    }
    return {
      data: [],
    };
  }, [fetchResponse]);

  return (
    <ReportCard
      containsData={data.length > 0}
      cacheData={cacheData}
      subTitle={I18n.t("reports.dashboard.entail_pages")}
      reportTypeName={"cta-clicks"}
      entailTraffic={true}
      setRefreshCache={() => {
        sessionStorage.setItem(REPORT_CACHE_KEY_NAME, "true");
        setTriggerRefresh(!triggerRefresh);
      }}
      extraOptionsComponent={
        <MSelect
          options={ACTION_REPORTS_OPTIONS}
          value={ACTION_REPORTS_OPTIONS.find((option) => option.value === reportType)}
          optionValue={"value"}
          optionLabel={"label"}
          borderColor={"transparent"}
          changeColorOnMenuOpen={true}
          closedTextColor={theme.palette.text.primary}
          optionBackgroundColorHover={"#E6EEFF"}
          borderRadius={0}
          borderWidth={0}
          textColor={theme.palette.primary.main}
          valuePadding={"2px 0px 2px 0px"}
          selectedTextColor={theme.palette.primary.main}
          height={32}
          isBold={false}
          placeholder={I18n.t("reports.dashboard.select_ph")}
          handleChange={(option) => {
            if (option) {
              setReportType(option.value);
            }
          }}
        />
      }
      loading={loading}
      showTimeFrameSelect
      timeFrameProps={{ value: selectedTimeFrame, handleChange: setSelectedTimeFrame }}
    >
      <CTAClicksChart
        clicksLabel={getClicksLabel()}
        data={data}
        timeFormat={selectedTimeFrame.value === "month" ? monthFormat : dateFormat}
        numberFormat={numberFormat}
        fullDateFormat={fullDateFormat}
        titlesFormat={titlesFormat}
      />
    </ReportCard>
  );
};

export default CTAClicksEntailReport;
