import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useParams } from "react-router-dom";
import { snakeCase } from "lodash";
import agent from "../../../agent";
import { ePageType, IAdminUser, ICategory, IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import ReviewStepItemList from "./components/ReviewStepItemList";
import { FACT_CHECK_PAGES } from "../../../reducers/constants/actionTypes";
import useDebounce from "../../../hooks/useDebounce";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarPostTypeFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPostTypeFilter";
import MTableToolbarCategoriesFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarCategoriesFilter";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  admin: state.home.admin,
  refreshPages: state.factCheckPages.refreshPages,
  paginationPagesCount: state.factCheckPages.paginationPagesCount,
  pagesLoaded: state.factCheckPages.pagesLoaded,
  categories: state.category.categories,
  inProgress: state.factCheckPages.myPages,
  publicPages: state.factCheckPages.pending,
  takenByOthers: state.factCheckPages.takenByOthers,
  postType: state.factCheckPages.filter.postType,
  searchText: state.factCheckPages.filter.searchText,
  sortField: state.factCheckPages.filter.sortField,
  category: state.factCheckPages.filter.category,
});

const mapDispatchToProps = (dispatch) => ({
  loadPages: (payload) => dispatch({ type: FACT_CHECK_PAGES.FACT_CHECK_PAGES_LOADED, payload }),
  startLoading: () => dispatch({ type: FACT_CHECK_PAGES.FACT_CHECK_PAGES_LOADING }),
  loadFailed: () => dispatch({ type: FACT_CHECK_PAGES.FACT_CHECK_PAGES_LOAD_FAILED }),
  onFilterChanged: (payload) => dispatch({ type: FACT_CHECK_PAGES.PAGES_ON_FILTER_CHANGED, payload }),
  cleanUpFilters: () => dispatch({ type: FACT_CHECK_PAGES.PAGES_ON_FILTER_CLEANUP }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  admin: IAdminUser;
  loadPages: (payload) => void;
  loadFailed: () => void;
  startLoading: () => void;
  refreshPages: boolean;
  pagesLoaded: boolean;
  paginationPagesCount: number;

  categories: ICategory[];
  inProgress: IPage[];
  publicPages: IPage[];
  takenByOthers: IPage[];

  postType: { _id: ePageType; name: string }[];
  category: ICategory[];
  searchText: string;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  onFilterChanged: (payload) => void;
  cleanUpFilters: () => void;
};

const ReviewStep = (props: PropTypes) => {
  const {
    selectedWebsite,
    admin,
    loadPages,
    startLoading,
    refreshPages,
    categories,
    loadFailed,
    paginationPagesCount,
    pagesLoaded,
    publicPages,
    takenByOthers,
    inProgress,
    searchText,
    category,
    postType,
    sortField,
    onFilterChanged,
    cleanUpFilters,
  } = props;

  const [page, setPage] = useState<number>(1);
  const [paginationLimit, setPaginationLimit] = useState(100);
  const { step } = useParams();
  const debouncedSearchText = useDebounce(searchText, 500);

  React.useEffect(
    () => () => {
      cleanUpFilters();
    },
    [step]
  );

  useEffect(() => {
    if (selectedWebsite && admin) {
      const cleanPostTypes = postType.map((p) => p._id);
      const postCategories = category.map((cat) => cat._id);

      startLoading();

      loadPages(
        agent.Pages.getReviewStepItems(
          selectedWebsite,
          step,
          admin,
          page,
          paginationLimit,
          sortField.field,
          sortField.direction,
          cleanPostTypes,
          postCategories,
          searchText
        )
          .then((res) => res)
          .catch((e) => loadFailed())
      );
    }
  }, [
    selectedWebsite,
    step,
    admin,
    postType,
    refreshPages,
    category,
    page,
    paginationLimit,
    debouncedSearchText,
    sortField,
  ]);

  const reviewName = React.useMemo(
    () =>
      selectedWebsite.reviews.find((r) => r.reviewStepName === step)?.reviewName ||
      I18n.t(`article.state.${snakeCase(step)}`),
    [selectedWebsite.reviews, step]
  );
  return (
    <MTableWrapper key={`review_step_${step}`}>
      <MHeaderMetaTags
        title={I18n.t(`meta_tags.article_cms.review_step.title`, {
          name: reviewName,
        })}
      />
      <MTableToolbar
        searchText={searchText}
        cmsCreateButton
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
      >
        <MTableToolbarPostTypeFilter
          postTypes={postType}
          selectedWebsite={selectedWebsite}
          handlePostTypesSelected={(value) => onFilterChanged({ postType: value })}
        />
        <MTableToolbarCategoriesFilter
          handleCategoriesSelected={(value) => onFilterChanged({ category: value })}
          selectedCategories={category}
          key={`review_step_${step}`}
          categories={categories}
        />
      </MTableToolbar>
      <ReviewStepItemList
        sortField={sortField}
        setSortField={(value) => onFilterChanged({ sortField: value })}
        step={step}
        inProgress={inProgress}
        takenByOthers={takenByOthers}
        publicPages={publicPages}
      />
      {pagesLoaded && (takenByOthers.length > 0 || inProgress.length > 0 || publicPages.length > 0) && (
        <MTablePagination
          paginationLimit={paginationLimit}
          page={page}
          paginationPagesCount={paginationPagesCount}
          setPaginationLimit={setPaginationLimit}
          setPage={setPage}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewStep);
