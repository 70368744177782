import React from "react";
import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, Theme, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import ClassNames from "classnames";

import { isValidUrl } from "../../../../../../../../helpers/utils";
import { WIDGET_BUILDER } from "../../../../../../../../reducers/constants/actionTypes";
import { eWidgetType, IWidget, IWidgetSettings } from "../../../../../../../../reducers/constants/objectTypes";
import InputList from "../../InputList";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 25,
  },
  container: {
    width: "100%",
    backgroundColor: palette.secondary.dark,
    borderRadius: 3,
    border: `1px solid ${palette.grey["100"]}`,
    padding: "10px 15px",
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  radioLabel: {},
  radio: {
    "&$checked": {
      color: palette.primary.light,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 18,
    },
  },
  checked: {},
  label: {
    fontSize: 12,
    opacity: 0.5,
  },
  inputLabel: {
    marginBottom: 5,
  },
}));

type PropTypes = {
  widget: IWidget;
  editSettings: (settings: IWidgetSettings) => void;
};

const mapStateToProps = (state) => ({
  widget: state.widgetBuilder.widget,
});

const mapDispatchToProps = (dispatch) => ({
  editSettings: (payload: IWidgetSettings) => dispatch({ type: WIDGET_BUILDER.EDIT_SETTINGS, payload }),
});

const PagesSettings = (props: PropTypes) => {
  const { widget } = props;
  const { settings } = widget;
  const { editSettings } = props;
  const [localPages, setLocalPages] = React.useState(settings.pages);
  const { pages = { value: "all", excludedPages: [], specificPages: [] } } = settings;

  const classes = useStyles();

  const fields = React.useMemo(() => ["all", "excluded", "specific"], []);

  React.useEffect(() => {
    editSettings({
      ...settings,
      pages: {
        ...settings.pages,
        excludedPages: localPages.excludedPages.filter(isValidUrl),
        specificPages: localPages.specificPages.filter(isValidUrl),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPages]);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.container}>
          <Typography className={classes.label}>
            {I18n.t(`widgets_builder.widget_editor.settings.pages.label`, {
              type: "popup",
            })}
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={pages.value}
              onChange={(e) =>
                editSettings({
                  ...settings,
                  pages: { ...pages, value: e.target.value as IWidgetSettings["pages"]["value"] },
                })
              }
            >
              {fields.map((field) => (
                <FormControlLabel
                  value={field}
                  key={field}
                  control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                  label={I18n.t(`widgets_builder.widget_editor.settings.pages.${field}`)}
                  className={classes.radioLabel}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        {pages.value === "all" && (
          <Typography className={classes.label}>
            {I18n.t(`widgets_builder.widget_editor.settings.pages.all_selected`, {
              type: "dialog",
            })}
          </Typography>
        )}
      </div>
      {pages.value === "excluded" && (
        <div>
          <Typography className={ClassNames(classes.label, classes.inputLabel)}>
            {I18n.t(`widgets_builder.widget_editor.settings.pages.dont_display`, {
              type: "DIALOG",
            })}
          </Typography>
          <InputList
            items={localPages.excludedPages}
            placeholder={I18n.t(`widgets_builder.widget_editor.settings.pages.url_placeholder`)}
            onChange={(excludedPages) => setLocalPages({ ...localPages, excludedPages })}
            error={{
              hasError: (url) => url && !isValidUrl(url),
              message: I18n.t(`widgets_builder.widget_editor.settings.pages.error_message`),
            }}
          />
        </div>
      )}

      {pages.value === "specific" && (
        <div>
          <Typography className={ClassNames(classes.label, classes.inputLabel)}>
            {I18n.t(`widgets_builder.widget_editor.settings.pages.display`, {
              type: "POPUP",
            })}
          </Typography>
          <InputList
            items={localPages.specificPages}
            placeholder={I18n.t(`widgets_builder.widget_editor.settings.pages.url_placeholder`)}
            onChange={(specificPages) => setLocalPages({ ...localPages, specificPages })}
            error={{
              hasError: (url) => url && !isValidUrl(url),
              message: I18n.t(`widgets_builder.widget_editor.settings.pages.error_message`),
            }}
          />
        </div>
      )}

      {pages.value !== "all" && (
        <Typography className={classes.label}>
          {I18n.t(`widgets_builder.widget_editor.settings.pages.custom_selected`)}
        </Typography>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PagesSettings);
