import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { useSlateStatic, ReactEditor } from "slate-react";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { I18n } from "react-redux-i18n";

import { Transforms } from "slate";

import TextAreaWidgetBaseElement from "../baseElements/TextAreaWidgetBaseElement";
import { Widget } from "../WithWidgets";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  prosAndCons: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid transparent",
    borderRadius: 3,
    padding: 5,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  pros: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
  },
  cons: {
    marginLeft: 40,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginTop: 20,
    },
  },
  prosWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    marginRight: 20,
  },
  consWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  checkIcon: {
    fill: "#35c376",
    marginRight: 15,
  },
  clearIcon: {
    fill: "red",
    marginRight: 15,
  },
  prosConsText: {
    lineHeight: 1.5,
  },
  title: {
    flex: 1,
    lineHeight: theme.typography.pxToRem(32),
    borderBottom: `solid 1px ${theme.palette.divider}`,
    paddingBottom: 10,
    textTransform: "capitalize",
  },
}));

const NiProsConsTableWidget = ({ attributes, children, element }) => {
  const classes = useStyles();
  const editor = useSlateStatic();

  const handleChange = (data) => {
    console.log("handleChange", data, ReactEditor.findPath(editor as ReactEditor, element));
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
    console.log("handleChange done");
  };

  return (
    <div {...attributes}>
      <Widget title={I18n.t("rich_text_editor.custom_components.ni_pros_cons_table")} element={element}>
        <div className={classes.prosAndCons}>
          <div className={classes.pros}>
            <Typography className={classes.title} variant={"h5"}>
              {I18n.t("rich_text_editor.pros_cons_table.pros")}
            </Typography>
            {[...(element.data.pros || []), ""].map((pro, proIndex) => {
              return (
                <div key={`pro_${proIndex}`} className={classes.prosWrapper}>
                  <CheckIcon className={classes.checkIcon} />
                  <TextAreaWidgetBaseElement
                    className={classes.prosConsText}
                    text={pro}
                    onBlur={() => handleChange({ ...element.data, pros: element.data.pros?.filter((n) => n) })}
                    placeholder={I18n.t("rich_text_editor.pros_cons_table.pros_ph")}
                    onChange={(pro) => {
                      const pros = [...(element.data.pros || [])];
                      if (proIndex >= pros.length) {
                        pros.push(pro);
                      } else {
                        pros[proIndex] = pro;
                      }
                      handleChange({ ...element.data, pros });
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.cons}>
            <Typography className={classes.title} variant={"h5"}>
              {I18n.t("rich_text_editor.pros_cons_table.cons")}
            </Typography>
            {[...(element.data.cons || []), ""].map((con, conIndex) => {
              return (
                <div key={`con_${conIndex}`} className={classes.consWrapper}>
                  <ClearIcon className={classes.clearIcon} />
                  <TextAreaWidgetBaseElement
                    className={classes.prosConsText}
                    text={con}
                    onBlur={() => handleChange({ ...element.data, cons: element.data.cons?.filter((n) => n) })}
                    placeholder={I18n.t("rich_text_editor.pros_cons_table.cons_ph")}
                    onChange={(con) => {
                      const cons = [...(element.data.cons || [])];
                      if (conIndex >= cons.length) {
                        cons.push(con);
                      } else {
                        cons[conIndex] = con;
                      }
                      handleChange({ ...element.data, cons });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {children}
      </Widget>
    </div>
  );
};

export default NiProsConsTableWidget;
