import { nanoid } from "nanoid";
import { findIndex } from "lodash";
import { WIDGET_BUILDER } from "./constants/actionTypes";
import { IWidget, eWidgetSubtype, eWidgetType } from "./constants/objectTypes";
import {
  handleFieldEditDuringIteration,
  transformLoadedWidget,
} from "../containers/CRO/pages/WidgetsBuilder/helpers/widgets";

export const initialState: {
  widgetLoaded: boolean;
  widget: IWidget;
  latestSavedWidget: IWidget;
  selectedItem: number;
  selectedStep: number;
  selectedFieldId: string;
} = {
  widgetLoaded: false,
  widget: null,
  latestSavedWidget: null,
  selectedItem: null,
  selectedStep: null,
  selectedFieldId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WIDGET_BUILDER.WIDGET_CLEANUP:
      return initialState;
    case WIDGET_BUILDER.WIDGET_LOADED:
      const transformedWidget = transformLoadedWidget(action.payload.widget);
      return {
        ...state,
        widgetLoaded: true,
        widget: transformedWidget,
        latestSavedWidget: transformedWidget,
        selectedItem: transformedWidget?.type === eWidgetType.CUSTOM_WIDGET ? null : 0,
      };

    case WIDGET_BUILDER.SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };

    case WIDGET_BUILDER.SET_SELECTED_STEP:
      return {
        ...state,
        selectedStep: action.selectedStep,
      };

    case WIDGET_BUILDER.SET_SELECTED_FIELD:
      return {
        ...state,
        selectedFieldId: action.selectedFieldId,
      };

    case WIDGET_BUILDER.EDIT_WIDGET_PROPERTY:
      return {
        ...state,
        widget: {
          ...state.widget,
          ...action.property,
        },
      };

    case WIDGET_BUILDER.ITEM_CHANGED:
      return {
        ...state,
        widget: {
          ...state.widget,
          items: state.widget.items.map((item, index) => {
            if (index === state.selectedItem) {
              return action.item;
            }
            return item;
          }),
        },
      };

    case WIDGET_BUILDER.ADD_NEW_FIELD: {
      const field = { ...action.field };

      if (!field.fieldId) {
        field.fieldId = nanoid(10);
      }

      return {
        ...state,
        selectedFieldId: field.fieldId,
        widget: {
          ...state.widget,
          items: state.widget.items.map((item, itemIndex) => {
            if (itemIndex === state.selectedItem) {
              return {
                ...item,
                steps:
                  item.steps.length > 0
                    ? item.steps.map((step, i) => {
                        if ((state.selectedStep || 0) === i) {
                          return {
                            ...step,
                            fields: [...step.fields, { ...field, visible: true }],
                          };
                        }
                        return step;
                      })
                    : [{ fields: [field] }],
              };
            }
            return item;
          }),
        },
      };
    }

    case WIDGET_BUILDER.EDIT_FIELD: {
      const { field, applyOnAllItems, changedValues } = action;
      return {
        ...state,
        widget: {
          ...state.widget,
          items: state.widget.items.map((item, itemIndex) => {
            if (itemIndex === state.selectedItem || applyOnAllItems) {
              return {
                ...item,
                steps: item.steps.map((step, i) => {
                  if ((state.selectedStep || 0) === i) {
                    return {
                      ...step,
                      fields: step.fields.map((f) => {
                        const updatedField = handleFieldEditDuringIteration(state.widget, f, field);

                        if (field.fieldId && field.fieldId === updatedField.fieldId) {
                          if (field.fieldId === f.fieldId) return field;
                          return { ...updatedField, ...changedValues };
                        }
                        return updatedField;
                      }),
                    };
                  }
                  return step;
                }),
              };
            }
            return item;
          }),
        },
      };
    }

    case WIDGET_BUILDER.DELETE_FIELD: {
      const { field, applyOnAllItems } = action;
      return {
        ...state,
        widget: {
          ...state.widget,
          items: state.widget.items.map((item, itemIndex) => {
            if (itemIndex === state.selectedItem || applyOnAllItems) {
              return {
                ...item,
                steps: item.steps.map((step, i) => {
                  if ((state.selectedStep || 0) === i) {
                    const index = findIndex(step.fields, (f) => f.fieldId === field.fieldId);
                    if (index === -1) {
                      return step;
                    }
                    const fields = [...step.fields];
                    fields.splice(index, 1);
                    return {
                      ...step,
                      fields,
                    };
                  }
                  return step;
                }),
              };
            }
            return item;
          }),
        },
      };
    }

    case WIDGET_BUILDER.DESIGN:
      return {
        ...state,
        widget: {
          ...state.widget,
          [action.key]: action.value,
        },
      };

    case WIDGET_BUILDER.BUTTON_CHANGED:
      return {
        ...state,
        widget: {
          ...state.widget,
          button: action.button,
        },
      };

    case WIDGET_BUILDER.ADD_NEW_ITEM:
      if (!state.widget.items?.[0]) {
        return state;
      }
      return {
        ...state,
        widget: {
          ...state.widget,
          items: [
            ...state.widget.items,
            action.item ?? {
              itemId: nanoid(10),
              product: state.widget.items[0].product,
              visible: true,
              steps: state.widget.items[0].steps.map((s) => ({
                ...s,
                fields: s.fields.map((f) => ({
                  ...f,
                  fieldId: nanoid(10),
                  properties: f.properties || {},
                })),
              })),
            },
          ],
        },
      };

    case WIDGET_BUILDER.DELETE_ITEM:
      return {
        ...state,
        widget: {
          ...state.widget,
          items: state.widget.items.filter((item, index) => index !== action.index),
        },
      };

    case WIDGET_BUILDER.EDIT_SETTINGS: {
      return {
        ...state,
        widget: {
          ...state.widget,
          settings: action.payload,
        },
      };
    }

    case WIDGET_BUILDER.EDIT_BACKGROUND: {
      return {
        ...state,
        widget: {
          ...state.widget,
          background: action.background,
        },
      };
    }

    default:
      return state;
  }
};
