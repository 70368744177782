import getWindow from "get-window";
import isBackward from "selection-is-backward";
import { Editor, Node, NodeEntry, Path, PathRef, Transforms, Range } from "slate";
import { ReactEditor } from "slate-react";
import { getRawText as getComparisonTableRawText } from "../elements/widgets/comparisonTable/ComparisonTableWidget";
import { getRawText as getProductEmbedRawText } from "../elements/widgets/productEmbed/ProductEmbedWidget";
import { getRawText as getCallToActionRawText } from "../elements/widgets/deprecated/callToAction/CallToActionWidget";
import { getRawText as getFaqRawText } from "../elements/widgets/faq/FAQWidget";
import { getRawText as getProductDetailsRawText } from "../elements/widgets/productDetails/ProductDetailsWidget";
import { getRawText as getPricingTableRawText } from "../elements/widgets/pricingTable/PricingTableWidget";
import { getRawText as getButtonRawText } from "../elements/widgets/button/ButtonWidget";
import { editorType } from "../types/editor.Types";

const getEmptyRichText = (text = "") =>
  JSON.stringify([
    {
      type: "paragraph",
      children: [{ text }],
    },
  ]);

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
const isColorMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] !== null : false;
};

const isSelected = (editor: Editor, isText = true) => {
  if (editor.selection) {
    if (!isText) {
      return true;
    }
    return Editor.string(editor, editor.selection)?.length !== 0;
  }
  return false;
};

const getSelectedTextColor = (editor) => {
  const marks = Editor.marks(editor);
  return marks?.color;
};

const LIST_TYPES: string[] = [editorType.numberedList, editorType.bulletedList];
const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });
  return !!match;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type as string),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? editorType.paragraph : isList ? editorType.listItem : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
const convertToRich = (text: string) => [
  {
    type: editorType.paragraph,
    children: [{ text }],
  },
];

const serialize = (nodes) => {
  if (!nodes) {
    return "";
  }
  return nodes
    .map((n, index) => {
      switch (n.type) {
        case "comparison-table":
          return getComparisonTableRawText(n);
        case "faq":
          return getFaqRawText(n);
        case "product-details":
          return getProductDetailsRawText(n);
        case "call-to-action":
          return getCallToActionRawText(n);
        case "product-embed":
          return getProductEmbedRawText(n);
        case "pricing-table":
          return getPricingTableRawText(n);
        case "button":
          return getButtonRawText(n);
        default:
          // console.log('n', index, n.type, Node.string(n))
          return Node.string(n);
      }
    })
    .join("\n");
};

const getAllComments = (richText, comments = []) => {
  for (const item of richText) {
    if (item.type === "commentV2") {
      // filter empty comments
      if (item.comments?.length > 0) comments.push(item);
    } else if (item.children?.length > 1) {
      comments = getAllComments(item.children, comments);
    }
  }
  return comments;
};

const pageHasUnresolvedComments = (children) => {
  const comments = getAllComments(children, []);
  return comments.filter((comment) => !comment.resolved)?.length > 0;
};

const countLinks = (text) => {
  if (!text || text === "") {
    return 0;
  }
  return (text.match(/"type":"link"/g) || []).length;
};

const getLinksItems = (richText, links = []) => {
  for (const item of richText) {
    if (item.type === "link") {
      links.push(item);
    } else if (item.children?.length > 1) {
      links = getLinksItems(item.children, links);
    }
  }
  return links;
};

const getLinksURL = (richText, links = []) => {
  for (const item of richText) {
    if (item.type === "link") {
      links.push(item.url);
    } else if (item.children?.length > 1) {
      links = getLinksURL(item.children, links);
    }
  }
  return links;
};

// const getLinksURL = (text) => {
//   const matches = text.matchAll(/"url":"([\w+\\.:\\/]+)"/gim);
//   const urls = [];
//   console.log("getLinksURL", text, matches);
//   for (const match of matches) {
//     console.log("getLinksURL", match);
//     urls.push(match[1]);
//   }
//   return urls.length > 0 ? urls : null;
// };

const getUuid = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

const insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading = (
  editor: Editor,
  nodes: Node | Node[],
  at?: Path
) => {
  let pathRefForEmptyNodeAtCursor: PathRef | undefined;

  const entry = Editor.above(editor, {
    match: (node) =>
      node.type === editorType.paragraph || node.type === editorType.headingOne || node.type === editorType.headingTwo,
    at,
  });
  if (entry && Node.string(entry[0]) === "") {
    pathRefForEmptyNodeAtCursor = Editor.pathRef(editor, entry[1]);
  }
  Transforms.insertNodes(editor, nodes, { at });
  const path = pathRefForEmptyNodeAtCursor?.unref();
  if (path) {
    Transforms.removeNodes(editor, { at: path });
    // even though the selection is in the right place after the removeNodes
    // for some reason the editor blurs so we need to focus it again
    ReactEditor.focus(editor as ReactEditor);
  }
};

const moveChildren = (
  editor: Editor,
  parent: NodeEntry | Path,
  to: Path,
  shouldMoveNode: (node: Node) => boolean = () => true
) => {
  const parentPath = Path.isPath(parent) ? parent : parent[1];
  const parentNode = Path.isPath(parent) ? Node.get(editor, parentPath) : parent[0];
  if (!Editor.isBlock(editor, parentNode)) return;

  for (let i = parentNode.children.length - 1; i >= 0; i--) {
    if (shouldMoveNode(parentNode.children[i])) {
      const childPath = [...parentPath, i];
      Transforms.moveNodes(editor, { at: childPath, to });
    }
  }
};

const paragraphElement = () => ({
  type: editorType.paragraph as const,
  children: [{ text: "" }],
});

const scrollToSelection = (selection) => {
  if (!selection.anchorNode) return;

  const window = getWindow(selection.anchorNode);
  const backward = isBackward(selection);
  const range = selection.getRangeAt(0);
  const rect = range.getBoundingClientRect();
  const { innerWidth, innerHeight, pageYOffset, pageXOffset } = window;
  const top = (backward ? rect.top : rect.bottom) + pageYOffset;
  const left = (backward ? rect.left : rect.right) + pageXOffset;

  const x = left < pageXOffset || innerWidth + pageXOffset < left ? left - innerWidth / 2 : pageXOffset;

  const y = top < pageYOffset || innerHeight + pageYOffset < top ? top - innerHeight / 2 : pageYOffset;

  window.scrollTo(x, y);
};

const insertVoidElement = (editor, type, at: Path) => {
  const text = { text: "" };
  const voidNode = {
    type,
    data: { uuid: getUuid() },
    children: [text],
  };
  insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading(editor, voidNode, at);
  const widgetEntry = Editor.above(editor, { match: (x) => x.type === type });
  if (widgetEntry) {
    Transforms.select(editor, [...widgetEntry[1], 0]);
  } else {
    Transforms.select(editor, at);
  }
};

const getImmediateParentNodesOfSelection = (editor) => {
  const { selection } = editor;
  const parentNodes = new Set();
  try {
    if (!selection || Range.isCollapsed(selection)) {
      return Array.from(parentNodes);
    }
    const [start, end] = Range.edges(selection);
    const ancestorPath = Path.common(start.path, end.path);
    const startIndex = start.path[ancestorPath.length];
    const endIndex = end.path[ancestorPath.length];

    for (let i = startIndex; i <= endIndex; i++) {
      const path = ancestorPath.concat(i);
      const node = Node.get(editor, path);

      if (Node.has(editor, path) && node.children) {
        for (const [child, childPath] of Node.children(editor, path)) {
          const parentNode = Node.parent(editor, childPath);
          parentNodes.add(parentNode);
        }
      }
    }
  } catch (error) {
    console.log("finding immediateParent error:::", error);
  }

  return Array.from(parentNodes);
};

// only one FAQ's googleSchemaMarkup is allowed
const checkFaqGoogleSchemaMarkup = (richText) => {
  let googleSchemaMarkupEnabled = false;
  const nodes = JSON.parse(richText || []);
  return JSON.stringify(
    nodes?.map((node) => {
      if (node.type === editorType.faq && node.data?.googleSchemaMarkup) {
        if (googleSchemaMarkupEnabled) {
          return { ...node, data: { ...node.data, googleSchemaMarkup: false } };
        }
        googleSchemaMarkupEnabled = true;
        return node;
      }
      return node;
    })
  );
};

const isMultiNodeSelection = (editor) => {
  const { selection } = editor;

  if (selection) {
    const selectedNodes = getImmediateParentNodesOfSelection(editor);
    return selectedNodes?.length > 1;
  }
  return false;
};

const getWidgetsItems = (richText, widgets = []) => {
  for (const item of richText) {
    if (item.type === "entail-widget") {
      widgets.push(item);
    } else if (item.children?.length > 1) {
      widgets = getWidgetsItems(item.children, widgets);
    }
  }
  return widgets;
};

export default {
  toggleMark,
  isMarkActive,
  toggleBlock,
  isBlockActive,
  isColorMarkActive,
  serialize,
  convertToRich,
  countLinks,
  getLinksURL,
  getLinksItems,
  getAllComments,
  getUuid,
  insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading,
  moveChildren,
  paragraphElement,
  getSelectedTextColor,
  scrollToSelection,
  getEmptyRichText,
  insertVoidElement,
  isSelected,
  pageHasUnresolvedComments,
  isMultiNodeSelection,
  checkFaqGoogleSchemaMarkup,
  getWidgetsItems,
};
