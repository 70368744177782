import React from "react";
import { Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { I18n } from "react-redux-i18n";
import { ChevronLeft } from "@material-ui/icons";

import DownChevronIcon from "../icons/DownChevronIcon";
import CfProgressBar from "./CfProgressBar";
import { ePageAction } from "../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative" as const,
  },
  contained: {
    visibility: (props: PropTypes) => (props.loading ? "hidden" : "unset"),
  },

  progressBar: {
    // visibility: (props: PropTypes) => props.loading ? 'unset' : 'hidden',
    position: "absolute",
    display: (props: PropTypes) => (props.loading ? "flex" : "none"),
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },

  buttonOutlined: (props: PropTypes) => ({
    textTransform: "capitalize" as const,
    color: props.gray ? theme.palette.text.secondary : theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold as any,
    display: "flex",
    flexDirection: "column" as const,
    minHeight: 40,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: "transparent",
    borderColor: props.gray ? theme.palette.divider : theme.palette.primary.main,
    borderStyle: "solid" as const,
    borderWidth: 1,
    borderRadius: 5,

    // transition: 'all 1s cubic-bezier(.175,.885,.32,1)',
    // webkitTransition: 'all 1s cubic-bezier(.175,.885,.32,1)',

    "&:hover": {
      backgroundColor: props.gray ? "transparent" : theme.palette.primary.main,
      color: props.gray ? theme.palette.primary.main : "white",
    },
    "&:disabled": {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    },
  }),

  button: {
    textTransform: "capitalize" as const,
    color: "white",
    display: "flex",
    flexDirection: "column" as const,
    minHeight: 36,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    paddingRight: 20,
    paddingLeft: 20,
    fontWeight: theme.typography.fontWeightBold as any,
    backgroundColor: theme.palette.primary.main,
    transition: "none",

    // transition: 'all 1s cubic-bezier(.175,.885,.32,1)',
    // webkitTransition: 'all 1s cubic-bezier(.175,.885,.32,1)',

    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      color: "white",
      backgroundColor: theme.palette.primary.dark,
    },
  },
  fullWidth: {
    width: "100%",
  },
  progressBarWrapper: {
    minWidth: 40,
    position: "relative",
    paddingTop: 5,
    paddingBottom: 5,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    marginTop: -15,
    marginLeft: -15,
  },
  dividerWrapper: {
    minWidth: 1,
    height: "inherit",
    backgroundColor: theme.palette.primary.main,
    borderWidth: "0px !important",
  },
  divider: {
    width: 1,
    height: "calc(100% - 16px)",
    marginTop: 8,
    marginBottom: 8,
    opacity: 0.6,
    backgroundColor: "white",
  },

  textButton: {
    borderRightWidth: "0px !important",
  },
  chevronButton: {},
  buttonGroup: {
    borderRadius: 30,
    boxShadow: "none",
    display: "inline-flex",
  },
  buttonGroupText: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
  },
  buttonGroupChevron: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
  },
  disabled: {
    backgroundColor: theme.palette.primary.dark,
  },
  menuItemExtraSpace: {
    paddingLeft: 35,
  },
  chevronLeft: {
    marginLeft: -10,
    marginRight: 5,
    width: 22,
  },
}));

type PropTypes = {
  mainAction: ePageAction | { action: string; subAction?: string; label: string };
  handleActionOnClick: Function;

  outlined?: boolean;
  gray?: boolean;
  loading?: boolean;
  disabled?: boolean;
  customClass?: any;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  actions: ePageAction[] | { action: string; subAction?: string; label: string }[];
  sendToActions?: ePageAction[] | { action: string; subAction?: string; label: string }[];
  selectedOptionIndex?: number;
  id?: string;
};

const MButtonGroup = (props: PropTypes) => {
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const [sendToOpen, setSendToOpen] = React.useState(false);
  const {
    disabled,
    handleActionOnClick,
    actions,
    sendToActions,
    selectedOptionIndex,
    mainAction,
    loading = false,
    customClass,
    outlined = false,
    fullWidth = false,
    startIcon,
    id,
  } = props;
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const sendToAnchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setSendToOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    action: ePageAction | { action: string; subAction?: string; label: string }
  ) => {
    handleActionOnClick(action);
    setOpen(false);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setSendToOpen(false);
    setOpen(false);
  };

  return (
    <div id={id} className={ClassNames(classes.wrapper, fullWidth && classes.fullWidth)}>
      <div className={classes.progressBar}>
        <CfProgressBar size={30} />
      </div>
      <ButtonGroup
        disableElevation
        disableRipple
        className={classes.buttonGroup}
        variant="contained"
        color="primary"
        classes={{
          contained: classes.contained,
        }}
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          disableRipple
          disableElevation
          className={ClassNames(
            classes.textButton,
            classes.buttonGroupText,
            outlined ? classes.buttonOutlined : classes.button,
            fullWidth && classes.fullWidth,
            customClass || null
          )}
          disabled={disabled || loading}
          onClick={(event) => handleActionOnClick(mainAction)}
          startIcon={startIcon}
        >
          {mainAction?.label || I18n.t(`workflow.actions.${mainAction}`)}
        </Button>
        <div className={ClassNames(classes.dividerWrapper, disabled && classes.disabled)}>
          <div className={classes.divider} />
        </div>
        <Button
          disableRipple
          disableElevation
          disabled={disabled || loading}
          className={ClassNames(
            classes.chevronButton,
            classes.buttonGroupChevron,
            outlined ? classes.buttonOutlined : classes.button,
            fullWidth && classes.fullWidth,
            customClass || null
          )}
          onClick={handleToggle}
        >
          <DownChevronIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 2 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {sendToActions && sendToActions.length > 0 && (
                    <div>
                      <MenuItem onClick={() => setSendToOpen((prev) => !prev)} innerRef={sendToAnchorRef}>
                        <ChevronLeft className={classes.chevronLeft} /> {I18n.t(`workflow.actions.SendTo`)}
                      </MenuItem>
                      <Popper
                        open={sendToOpen}
                        placement="left-start"
                        anchorEl={sendToAnchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        style={{ zIndex: 2 }}
                      >
                        {({ TransitionProps }) => (
                          <Grow {...TransitionProps}>
                            <Paper>
                              <MenuList id="split-button-menu">
                                {sendToActions.map((action, index) => (
                                  <MenuItem
                                    key={action?.label}
                                    disabled={false}
                                    selected={index === selectedOptionIndex}
                                    onClick={(event) => handleMenuItemClick(event, action)}
                                  >
                                    {action?.label || I18n.t(`workflow.actions.${action}`)}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                  )}
                  {actions.map((action, index) => (
                    <MenuItem
                      key={action?.label}
                      disabled={false}
                      selected={index === selectedOptionIndex}
                      onClick={(event) => handleMenuItemClick(event, action)}
                      className={ClassNames(sendToActions && sendToActions.length > 0 && classes.menuItemExtraSpace)}
                    >
                      {action?.label || I18n.t(`workflow.actions.${action}`)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default MButtonGroup;
