import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Theme } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import {
  IWidgetFieldProperties,
  IWidgetProsAndConsFieldProps,
} from "../../../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../../../components/TextFieldMaxLength";
import MNumeratedItem from "../../../../../../../components/MNumeratedItem";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginBottom: 10,
  },
  input: {
    border: "none",
    width: "100%",
    overflow: "hidden",
    padding: 15,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: 14,
    borderRadius: 3,
  },
}));

type PropTypes = {
  properties?: IWidgetFieldProperties;
  onChange: (properties: IWidgetProsAndConsFieldProps) => void;
};

const ProsAndConsWidgetItem = (props: PropTypes) => {
  const classes = useStyles();
  const propsProperties = props.properties as IWidgetProsAndConsFieldProps;
  const properties = {
    ...propsProperties,
    cons: propsProperties?.cons || [],
    pros: propsProperties?.pros || [],
    title: propsProperties?.title ?? "Pros and cons",
  } as IWidgetProsAndConsFieldProps;

  const { onChange } = props;
  const { pros, cons, title } = properties;

  const handleChange = ({ value, type, index }) => {
    let updatedValues: string[];
    if (!value) {
      updatedValues = properties[type].filter((val: string, arrIndex: number) => arrIndex !== index);
    } else {
      updatedValues =
        index === properties[type].length
          ? [...properties[type], value]
          : properties[type].map((rowValue: string, arrIndex: number) => (arrIndex === index ? value : rowValue));
    }

    onChange({ ...properties, [type]: updatedValues });
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.title} variant={"subtitle2"}>
          {I18n.t("widgets_builder.widget_editor.fields.labels.title")}
        </Typography>
        <TextFieldMaxLength
          handleValueChange={(newValue) => onChange({ ...properties, title: newValue })}
          value={title}
          placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
          outlined={false}
          inputClassName={classes.input}
        />
      </div>
      <div>
        <Typography className={classes.title} variant={"subtitle2"}>
          {I18n.t("widgets_builder.widget_item_selector.pros")}
        </Typography>
        {[...pros, ""].map((value, index, array) => (
          <MNumeratedItem
            index={index}
            showRemoveIcon={index !== array.length - 1}
            onRemove={() => handleChange({ value: "", type: "pros", index })}
            key={`pro_${index}`}
          >
            <TextFieldMaxLength
              handleValueChange={(newValue) => handleChange({ value: newValue, type: "pros", index })}
              value={value}
              placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
              multiLine
              outlined={false}
              inputClassName={classes.input}
            />
          </MNumeratedItem>
        ))}
      </div>

      <div>
        <Typography className={classes.title} variant={"subtitle2"}>
          {I18n.t("widgets_builder.widget_item_selector.cons")}
        </Typography>
        {[...cons, ""].map((value, index, array) => (
          <MNumeratedItem
            key={`con_${index}`}
            index={index}
            showRemoveIcon={index !== array.length - 1}
            onRemove={() => handleChange({ value: "", type: "cons", index })}
          >
            <TextFieldMaxLength
              handleValueChange={(newValue) => handleChange({ value: newValue, type: "cons", index })}
              value={value}
              placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
              multiLine
              outlined={false}
              inputClassName={classes.input}
            />
          </MNumeratedItem>
        ))}
      </div>
    </div>
  );
};

export default ProsAndConsWidgetItem;
