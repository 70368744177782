import React from "react";
import { I18n } from "react-redux-i18n";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";
import { ICategory } from "../../../../reducers/constants/objectTypes";

type PropTypes = {
  selectedCategories: ICategory[];
  categories: ICategory[];

  handleCategoriesSelected: (categories: ICategory[]) => void;
};

const MTableToolbarCategoriesFilter = (props: PropTypes) => {
  const { selectedCategories, categories, handleCategoriesSelected } = props;

  return (
    <MTableToolbarBaseSelect
      multipleOptions
      options={categories}
      selectedOptions={selectedCategories}
      placeholder={I18n.t("workflow.writer.filter_category")}
      title={I18n.t("workflow.writer.categories")}
      handleChange={handleCategoriesSelected}
    />
  );
};

export default MTableToolbarCategoriesFilter;
