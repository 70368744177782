import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 36,
    height: 35,
  },
}));

type PropTypes = {
  className?: string;
};

const ProsConsIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0  36 35"} fill="none">
      <rect x="0.666626" width="35" height="35" rx="3" fill="#FE9A00" />
      <path
        d="M18.3334 4.2C21.8083 4.2 24.6334 7.06071 24.6334 10.5914C24.6334 14.1221 21.8083 16.9828 18.3334 16.9828C14.8584 16.9828 12.0334 14.1221 12.0334 10.5914C12.0334 7.06071 14.8584 4.2 18.3334 4.2ZM18.3334 5.68326C15.6574 5.68326 13.4905 7.88464 13.4905 10.5914C13.4905 13.2982 15.6574 15.4995 18.3334 15.4995C21.0094 15.4995 23.1762 13.2982 23.1762 10.5914C23.1762 7.88464 21.0094 5.68326 18.3334 5.68326Z"
        fill="white"
        stroke="#FE9A00"
        stroke-width="0.4"
      />
      <path
        d="M18.3334 18.0103C21.8083 18.0103 24.6334 20.871 24.6334 24.4017C24.6334 27.9324 21.8083 30.7931 18.3334 30.7931C14.8584 30.7931 12.0334 27.9324 12.0334 24.4017C12.0334 20.871 14.8584 18.0103 18.3334 18.0103ZM18.3334 19.4936C15.6574 19.4936 13.4905 21.6949 13.4905 24.4017C13.4905 27.1085 15.6574 29.3098 18.3334 29.3098C21.0094 29.3098 23.1762 27.1085 23.1762 24.4017C23.1762 21.6949 21.0094 19.4936 18.3334 19.4936Z"
        fill="white"
        stroke="#FE9A00"
        stroke-width="0.4"
      />
      <path
        d="M15.3124 11.1163L15.3122 11.1161C15.0265 10.8274 15.0266 10.3552 15.3122 10.0666L15.3126 10.0661C15.5957 9.77799 16.0567 9.778 16.3398 10.0661L16.3401 10.0664L17.3553 11.0946L17.4977 11.2388L17.64 11.0945L20.3266 8.37141L20.3269 8.37113C20.61 8.08306 21.071 8.08307 21.354 8.37113L21.3545 8.37162C21.6401 8.66025 21.6401 9.13253 21.3545 9.42113L21.3543 9.42138L18.0114 12.8112C17.727 13.0996 17.2682 13.0996 16.9838 12.8112L15.3124 11.1163Z"
        fill="white"
        stroke="#FE9A00"
        stroke-width="0.4"
      />
      <path
        d="M17.1758 26.6218L17.1756 26.622C16.8925 26.9101 16.4315 26.9101 16.1485 26.622L16.148 26.6216C15.8624 26.3329 15.8624 25.8606 16.148 25.572L16.1482 25.5718L19.4911 22.1819L19.4913 22.1817C19.7744 21.8936 20.2354 21.8936 20.5184 22.1817L20.5189 22.1822C20.8045 22.4708 20.8045 22.9431 20.5189 23.2317L20.5187 23.2319L17.1758 26.6218Z"
        fill="white"
        stroke="#FE9A00"
        stroke-width="0.4"
      />
      <path
        d="M20.5187 25.5713L20.5189 25.5715C20.8045 25.8602 20.8045 26.3325 20.5189 26.6211L20.5184 26.6216C20.2353 26.9096 19.7744 26.9096 19.4913 26.6216L19.4911 26.6213L16.1482 23.2314L16.148 23.2312C15.8624 22.9426 15.8624 22.4703 16.148 22.1817L16.1485 22.1812C16.4316 21.8931 16.8925 21.8931 17.1756 22.1812L17.1758 22.1814L20.5187 25.5713Z"
        fill="white"
        stroke="#FE9A00"
        stroke-width="0.4"
      />
    </SvgIcon>
  );
};
<svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35" fill="none"></svg>;
export default ProsConsIcon;
