import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { DATE_FORMAT } from "../../../../reducers/constants/consts";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    display: "flex",
    padding: "0 20px",
    height: 53,
  },
  date: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  date: Date;
};

const ActivityFeedDateItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { date } = props;

  return (
    <ListItem className={classes.itemWrapper}>
      <Typography className={classes.date} variant="subtitle2">
        {moment(date).format(DATE_FORMAT.COMPLETE)}
      </Typography>
    </ListItem>
  );
};

export default ActivityFeedDateItem;
