import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { find } from "lodash";
import Typography from "@material-ui/core/Typography";
import PageSection from "../../components/PageSection";
import {
  IWebsiteHomepageProps,
  IWebsiteSectionProps,
  IWebsiteThemeProps,
} from "../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import WebsitesSectionsSwitch from "../../components/WebsitesSectionsSwitch";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import MRouterPrompt from "../../components/MRouterPrompt";
import HomepageDefault from "./HomepageDefault";
import MSelect from "../../../../components/MSelect";
import HomepageKeepshoppers from "./HomepageKeepshoppers";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  idsColumns: {
    display: "flex",
    justifyContent: "space-between",
  },
  ids: {
    width: 300,
    marginBottom: 40,
  },
  selectTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 5,
  },
  select: {
    textTransform: "capitalize",
  },
}));

const mapStateToProps = (state) => ({
  homepage: state.websitePages.homepage,
  theme: state.websitePages.theme,
  sections: state.websitePages.sections,
  urlPrefix: state.websitePages.siteDetails.urlPrefix,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (homepage) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "homepage",
      value: homepage,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  theme: IWebsiteThemeProps;
  homepage: IWebsiteHomepageProps;
  sections: IWebsiteSectionProps;
  onPropertyChanged: (value) => void;
  handleToggleActive: (section, enabled) => void;
};

const PageHomePage = (props: PropTypes) => {
  const classes = useStyles(props);
  const { sections, homepage, theme, handleToggleActive, onPropertyChanged } = props;
  const [error, setError] = React.useState([]);
  const templateOptions = [
    { _id: 1, name: "default" },
    { _id: 2, name: "keepshoppers" },
    { _id: 3, name: "custom" },
  ];

  React.useEffect(() => {
    if (error.length <= 0) {
      return;
    }
    textFieldsValidated();
  }, [homepage]);

  const textFieldsValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!homepage.name) {
      valid = false;
      emptyFields.push(I18n.t(`website.name`));
    }
    if (!homepage.slug) {
      valid = false;
      emptyFields.push(I18n.t(`website.slug_title`));
    }
    // if (! homepage.topSection.button.text){
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.homepage.top_section_button`));
    // }

    setError(emptyFields);
    return valid;
  };

  return (
    <>
      <MRouterPrompt />
      <WebsitesSectionsSwitch
        inSection
        enabled={sections.homepage}
        title={I18n.t(`website.page_types.homepage`)}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.homepage) {
              handleToggleActive("homepage", enabled);
            }
            return;
          }
          setError([]);
          handleToggleActive("homepage", enabled);
        }}
      />

      {error.length > 0 && (
        <Typography variant={"body1"} className={classes.error}>
          {error.length > 0 && I18n.t("website.error_message")}
        </Typography>
      )}

      <PageSection sectionTitle={I18n.t("website.homepage.general")}>
        <div>
          <div className={classes.inputRowWrapper}>
            <TextFieldMaxLength
              titleAndLengthLocation={"top"}
              error={error.includes(I18n.t(`website.name`))}
              value={homepage.name}
              placeholder={I18n.t("website.name_ph")}
              title={I18n.t("website.name")}
              height={37}
              borderRadius={4}
              marginBottom={30}
              handleValueChange={(value) => onPropertyChanged({ ...homepage, name: value })}
            />

            <TextFieldMaxLength
              titleAndLengthLocation={"top"}
              value={homepage.slug}
              error={error.includes(I18n.t(`website.slug_title`))}
              placeholder={I18n.t("website.slug_title_ph")}
              title={I18n.t("website.slug_title")}
              height={37}
              borderRadius={4}
              marginBottom={30}
              handleValueChange={(value) => onPropertyChanged({ ...homepage, slug: value })}
            />
          </div>
          <div>
            <Typography variant={"subtitle2"} className={classes.selectTitle}>
              {I18n.t("website.homepage.template")}
            </Typography>
            <MSelect
              customClass={classes.select}
              options={templateOptions}
              value={find(templateOptions, { name: homepage.template })}
              optionValue={"_id"}
              optionLabel={"name"}
              borderRadius={0}
              borderWidth={"1px"}
              height={37}
              handleChange={(value) => onPropertyChanged({ ...homepage, template: value.name })}
            />
          </div>
        </div>
      </PageSection>

      {homepage.template === "default" && (
        <HomepageDefault theme={theme} homepage={homepage} onPropertyChanged={onPropertyChanged} />
      )}
      {homepage.template === "keepshoppers" && (
        <HomepageKeepshoppers theme={theme} homepage={homepage} onPropertyChanged={onPropertyChanged} />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHomePage);
