import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import useReport from "./useReport";
import ReportCard from "./ReportCard";
import { REPORT_CACHE_KEY_NAME } from "../../../../helpers/constants";
import ImpressionsChart from "./charts/ImpressionsChart";

const useStyles = makeStyles(() => ({
  legendWrap: {
    display: "flex",
  },
  container: {
    display: "flex",
    gap: 5,
    marginRight: 20,
    "&:last-child": {
      marginRight: 0,
    },
  },
  reportCard: {
    "& .recharts-legend-wrapper": {
      top: "-59px !important",
      left: "auto !important",
      right: "70px !important",
      width: "fit-content !important",
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  startDate: Date;
};

const ImpressionsEntailReport = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, startDate } = props;
  const [selectedTimeFrame, setSelectedTimeFrame] = React.useState({ value: "month", label: "Month" });

  const extraParams = React.useMemo(
    () => ({ timeFrame: selectedTimeFrame.value, pageFilter: selectedWebsite.configurations.siteDetails.urlPrefix }),
    [selectedTimeFrame.value, selectedWebsite]
  );

  const {
    loading,
    fetchResponse,
    triggerRefresh,
    setTriggerRefresh,
    dateFormat,
    monthFormat,
    fullDateFormat,
    numberFormat,
    titlesFormat,
  } = useReport({
    selectedWebsite,
    startDate,
    reportType: "search-console",
    extraParams,
  });

  const { data, cacheData } = React.useMemo(() => {
    if (fetchResponse) {
      return {
        data: fetchResponse.results,
        cacheData: {
          cache: fetchResponse.cache,
          lastFetch: fetchResponse.fetchedTime,
        },
      };
    }
    return {
      data: [],
    };
  }, [fetchResponse]);

  return (
    <ReportCard
      containsData={data.length > 0}
      loading={loading}
      cacheData={cacheData}
      entailTraffic={true}
      subTitle={I18n.t("reports.dashboard.entail_pages")}
      reportTypeName={"impressions"}
      setRefreshCache={() => {
        sessionStorage.setItem(REPORT_CACHE_KEY_NAME, "true");
        setTriggerRefresh(!triggerRefresh);
      }}
      showTimeFrameSelect
      timeFrameProps={{ value: selectedTimeFrame, handleChange: setSelectedTimeFrame }}
      className={classes.reportCard}
    >
      <ImpressionsChart
        data={data}
        timeFormat={selectedTimeFrame.value === "month" ? monthFormat : dateFormat}
        numberFormat={numberFormat}
        fullDateFormat={fullDateFormat}
        titlesFormat={titlesFormat}
      />
    </ReportCard>
  );
};

export default ImpressionsEntailReport;
