import { useSnackbar } from "notistack";
import { ListItem, Typography } from "@material-ui/core";
import numeral from "numeral";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Search from "@material-ui/icons/Search";
import { I18n } from "react-redux-i18n";
import CopyToClipboardIcon from "../../../../../icons/CopyToClipboardIcon";
import { copyStringToClipboard } from "../../../../../helpers/fbScraperHelper";
import { IKeywordGroupPage, IKeywordPage } from "../../../../../reducers/constants/objectTypes";
import { getSearchConsoleUrl, withHttps } from "../../../../../helpers/urlHelper";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  actionCenter: {
    paddingRight: "30px",
    display: "flex",
    fontSize: "14px",
    visibility: "hidden",
    padding: "0 20px",
    color: theme.palette.text.primary,
    cursor: "pointer",
    alignItems: "center",
    height: "100%",
  },
  metric: {
    fontSize: "14px",
    padding: 0,
    flexBasis: 0,
    flexGrow: 1,
    color: theme.palette.text.primary,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "end",
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "0 10px",
    height: 57,
    "&:hover": {
      "& $actionCenter": {
        visibility: "visible",
      },
    },
  },
  listItemFirstColumn: {
    // paddingLeft: 30,
    width: "calc(60% - 10px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  page: IKeywordGroupPage;
};

const getOpportunityPageUrl = (url) => {
  try {
    const urlObj = new URL(withHttps(url));

    const subDomain = urlObj.hostname.split(".");

    if (subDomain.length >= 3 && subDomain[0] !== "www") return url;

    return urlObj.pathname;
  } catch (e) {
    return url;
  }
};

const PageTabListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { page } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <ListItem className={classes.listRow}>
      <Tooltip title={page.page} enterDelay={500} arrow>
        <div className={classes.listItemFirstColumn}>
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "pre",
            }}
          >
            {getOpportunityPageUrl(page.page)}
          </span>
          <div className={classes.actionCenter}>
            <Tooltip enterDelay={500} title={I18n.t(`learning_center_content.tooltip.google_search_console`)}>
              <IconButton
                disableRipple
                className={classes.button}
                onClick={(event) => {
                  event.stopPropagation();

                  window.open(getSearchConsoleUrl(page.page), "_blank");
                }}
              >
                <Search className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip enterDelay={500} title={I18n.t(`learning_center_content.tooltip.open_in_new_tab`)}>
              <IconButton
                disableRipple
                className={classes.button}
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(page.page);
                }}
              >
                <OpenInNewIcon className={classes.icon} />
              </IconButton>
            </Tooltip>

            <Tooltip enterDelay={500} title={I18n.t(`learning_center_content.tooltip.copy_url`)}>
              <IconButton
                disableRipple
                className={classes.button}
                onClick={(event) => {
                  event.stopPropagation();
                  copyStringToClipboard(page.page, enqueueSnackbar("URL copied to clipboard"));
                }}
              >
                <CopyToClipboardIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Tooltip>
      <div className={classes.metric}>
        <Typography className={classes.clicksText}>{page.clicks ? numeral(page.clicks).format("0,0") : "0"}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography className={classes.impText}>
          {page.impressions ? numeral(page.impressions).format("0,0") : "0"}
        </Typography>
      </div>
      <div className={classes.metric}>
        <Typography className={classes.ctrText}>{page.ctr ? numeral(page.ctr).format("0.0%") : "0%"}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography className={classes.posText}>
          {page.position ? numeral(page.position).format("0.0") : "-"}
        </Typography>
      </div>
    </ListItem>
  );
};

export default PageTabListItem;
