import { KEYWORD_GROUPS } from "./constants/actionTypes";

const initialState = {
  // list
  keywordGroupsLoaded: false,
  keywordGroups: [],

  // single
  keywordGroup: null,
  keywordGroupLoaded: false,
  checkedKeywordGroupsList: [],

  // limit
  keywordGroupsLimit: 100,
  keywordGroupsTotal: 0,

  // filter
  keywordGroupsSearch: "",
  keywordGroupsTask: "",
  keywordGroupsVolume: 0,
  keywordGroupsIntent: "",
  keywordGroupsCompetition: "",
  filter: {
    searchText: "",
    sortField: {
      field: null,
      direction: null,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case KEYWORD_GROUPS.KEYWORD_GROUPS_LOADED:
      return {
        ...state,
        keywordGroupsLoaded: true,
        keywordGroups: action.payload.data,
        keywordGroupsTotal: action.payload.pagination.totalCount,
      };
    case KEYWORD_GROUPS.KEYWORD_GROUPS_START_LOADING:
      return {
        ...state,
        keywordGroupsLoaded: false,
      };
    case KEYWORD_GROUPS.KEYWORD_GROUP_LOADED:
      return {
        ...state,
        keywordGroup: action.keywordGroup,
        keywordGroupLoaded: true,
      };
    case KEYWORD_GROUPS.KEYWORD_GROUP_START_LOADING:
      return {
        ...state,
        keywordGroupLoaded: false,
      };
    case KEYWORD_GROUPS.ON_CHECKED_BOX_CHANGED:
      return {
        ...state,
        checkedKeywordGroupsList: action.checkedKeywordGroupsList,
      };
    case KEYWORD_GROUPS.KEYWORD_GROUP_ON_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
