import React, { useEffect, useRef, useState } from "react";
import agent from "../../../agent";
import { replace } from "connected-react-router";
import { HOME } from "../../../reducers/constants/actionTypes";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { eMpEvent } from "../../../reducers/constants/objectTypes";

type PropTypes = {
  onLogin: Function;
  onAdminUserLoaded: Function;
  mpEvent: (event: string, props: object) => void;
  isMobile?: boolean;
};

const mapDispatchToProps = (dispatch) => ({
  onLogin: (redirect) => dispatch(replace(redirect)),
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
  onAdminUserLoaded: (payload) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: payload.user }),
});

const CroGoogleSignup = (props: PropTypes) => {
  const { onLogin, onAdminUserLoaded, mpEvent, isMobile } = props;

  const [retryCounter, setCounter] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const head = document.querySelector("head");

    const script = document.createElement("script");
    script.setAttribute("id", "googleScriptTag");
    script.setAttribute("src", "https://accounts.google.com/gsi/client");
    script.setAttribute("async", "");
    script.setAttribute("defer", "");

    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    try {
      const scriptTag = document.getElementById("googleScriptTag");

      if (!scriptTag) throw new Error("Tag dosent exist");
      const width = isMobile ? window.innerWidth - 60 : 390;
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: "759305923460-o4j43uvmf6an38a1rj8dm0ib7vm6gp1o.apps.googleusercontent.com", //TODO: move to env
        callback: handleCredentialResponse,
      });

      // @ts-ignore
      google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          theme: "outline",
          size: "large",
          shape: "circle",
          text: "signup_with",
          width: width,
        } // customization attributes
      );
    } catch (e) {
      setCounter((old) => old + 1);
    }
  }, [retryCounter]);

  function handleCredentialResponse(response) {
    agent.Auth.googleSignUp(response.credential)
      .then((res) => {
        if (res.user) {
          onAdminUserLoaded(res);
          onLogin(res.to || "/");
        } else enqueueSnackbar(I18n.t("login.google_user_not_registered"));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <div
      onClick={() => {
        mpEvent(eMpEvent.websiteButtonClick, { type: "google", text: I18n.t("signup.sign_up_with_google") });
      }}
      id={"buttonDiv"}
      style={{ width: "100%" }}
    ></div>
  );
};

export default connect(null, mapDispatchToProps)(CroGoogleSignup);
