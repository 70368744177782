import React, { useState } from "react";
import Dropzone from "react-dropzone";
import cloneDeep from "lodash/cloneDeep";
import { Button, Theme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { nanoid } from "nanoid";
import { round } from "lodash";
import CfProgressBar from "../../../../../../components/CfProgressBar";
import { colors } from "../../../../../../helpers/constants";
import { useVideoEditorContext } from "../../../../VideoEditorContext";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  addButton: {
    width: "100%",
    padding: 25,
    gap: 4,
    backgroundColor: colors.widgetOnHoverBackgroundColor,
    cursor: "pointer",
    transition: "background-color 0.2s",
    textTransform: "none",
    borderRadius: 0,
    marginBottom: 20,
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: palette.primary.main,
    },
    "&:hover": {
      backgroundColor: colors.widgetOnHoverBackgroundColor,
    },
    "&:last-child": {
      borderRadius: "0 3px 3px 0",
    },
  },
  icon: {
    color: palette.primary.main,
    width: 25,
    height: 25,
  },
}));

const UploadNewMedia = () => {
  const classes = useStyles();
  const { canvas, pendingUploads, selectedTimeline, editTimelineRows, editVideoEditorProperty, setPendingUploads } =
    useVideoEditorContext();
  const timelineRows = selectedTimeline.rows;
  const [isUploading, setIsUploading] = useState(false);

  console.log("UploadNewMedia");
  const uploadImage = async (e) => {
    const image = document.createElement("img");
    image.src = URL.createObjectURL(e);
    image.onload = () => {
      const c = cloneDeep(canvas);
      if (c.width < image.width) {
        c.width = image.width;
      }
      if (c.height < image.height) {
        c.height = image.height;
      }
      editVideoEditorProperty({ canvas: c });

      const imageItem = {
        id: `image-${nanoid(10)}`,
        src: URL.createObjectURL(e),
        file: e,
        x: 0,
        y: 0,
        width: image.width,
        height: image.height,
      };

      const newTimelineRows = timelineRows.map((timelineRow) => {
        if (timelineRow.id === "image") {
          return {
            ...timelineRow,
            actions: [
              ...timelineRow.actions,
              {
                id: `${timelineRow.id}-${nanoid(10)}`,
                start: 0,
                end: 1,
                minStart: 0,
                maxEnd: 1,
                image: imageItem,
                flexible: true,
                movable: false,
                effectId: "image-effect",
              },
            ],
          };
        }
        return timelineRow;
      });
      editTimelineRows(newTimelineRows);

      setIsUploading(false); // Finish uploading
    };
  };

  const uploadAudio = async (e) => {
    const audio = document.createElement("audio");
    audio.src = URL.createObjectURL(e);
    audio.onloadedmetadata = () => {
      const duration = round(audio.duration * 1000, 2);

      const audioItem = {
        id: `audio-${nanoid(10)}`,
        src: URL.createObjectURL(e),
        file: e,
        duration,
      };

      const newTimelineRows = timelineRows.map((timelineRow) => {
        if (timelineRow.id === "audio") {
          return {
            ...timelineRow,
            actions: [
              ...timelineRow.actions,
              {
                id: `${timelineRow.id}-${nanoid(10)}`,
                start: 0,
                end: duration / 1000,
                minStart: 0,
                maxEnd: duration / 1000,
                audio: audioItem,
                flexible: true,
                movable: false,
                effectId: "audio-effect",
              },
            ],
          };
        }
        return timelineRow;
      });
      editTimelineRows(newTimelineRows);

      setIsUploading(false); // Finish uploading
    };
  };

  const uploadVideo = async (e) => {
    if (e.type === "video/quicktime" || e.type === "video/x-ms-wmv") {
      // .mov files have 'video/quicktime' MIME type
      return;
    }
    const video = document.createElement("video");
    video.src = URL.createObjectURL(e);
    function setDuration() {
      const duration = round(video.duration * 1000, 2);

      const startTime = 0;

      const c = cloneDeep(canvas);
      if (c.width < video.videoWidth) {
        c.width = video.videoWidth;
      }
      if (c.height < video.videoHeight) {
        c.height = video.videoHeight;
      }

      editVideoEditorProperty({ canvas: c });

      const videoItem = {
        id: `video-${nanoid(10)}`,
        src: URL.createObjectURL(e),
        file: e,
        duration,
        x: 0,
        y: 0,
        startTime,
        endTime: round(startTime + duration, 2),
        width: video.videoWidth,
        height: video.videoHeight,
      };

      const newTimelineRows = timelineRows.map((timelineRow) => {
        if (timelineRow.type === "video") {
          return {
            ...timelineRow,
            actions: [
              ...timelineRow.actions,
              {
                id: `${timelineRow.id}-${nanoid(10)}`,
                start: videoItem.startTime / 1000,
                end: round(videoItem.endTime / 1000, 2),
                minStart: videoItem.startTime / 1000,
                maxEnd: round(videoItem.endTime / 1000, 2),
                trimStart: 0,
                trimEnd: round(videoItem.endTime / 1000, 2),
                video: videoItem,
                flexible: true,
                movable: false,
                effectId: "video-effect",
              },
            ],
          };
        }
        return timelineRow;
      });
      editTimelineRows(newTimelineRows);

      setIsUploading(false); // Finish uploading
    }
    video.addEventListener("loadedmetadata", setDuration);
  };

  async function setFile(e) {
    console.log("onDrop", e);
    // setIsUploading(true); // Start uploading

    if (e.type.includes("video")) {
      console.log("upload new video", e);
      setPendingUploads({ ...pendingUploads, videos: [...pendingUploads.videos, e] });
      return;
      // uploadVideo(e);
    }
    if (e.type.includes("image")) {
      // uploadImage(e);
      setPendingUploads({ ...pendingUploads, images: [...pendingUploads.images, e] });
      return;
    }
    if (e.type.includes("audio")) {
      // uploadAudio(e);
      setPendingUploads({ ...pendingUploads, audios: [...pendingUploads.audios, e] });
      return;
    }

    throw new Error(`ERROR: Unsupported file type ${e.type}`);
  }

  return (
    <Dropzone
      accept={{
        "video/mp4": [".mp4"],
        "video/quicktime": [".mov"],
        "video/x-ms-wmv": [".wmv"],
        "image/*": [".jpeg", ".png"],
        "audio/mp3": [".mp3"],
        "audio/wav": [".wav"],
        "audio/mpeg": [".mpeg"],
      }}
      data-hj-allow
      onDrop={(e) => setFile(e[0])}
    >
      {({ getRootProps, getInputProps }) => (
        <section data-hj-allow style={{ height: "100%" }}>
          <div
            data-hj-allow
            {...getRootProps()}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <input data-hj-allow {...getInputProps()} />
            {isUploading ? (
              <CfProgressBar />
            ) : (
              <Button className={classes.addButton}>
                <Add className={classes.icon} />
                {I18n.t("video_workflow.video_tabs.files.upload_media_button")}
              </Button>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default UploadNewMedia;
