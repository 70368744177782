import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MTextField from "../../../../../components/MTextField";
import LiteRichTextEditor from "../../../../../editor/elements/liteEditor/LiteRichTextEditor";
import { HTMLtoJSON, jsonToHTML } from "../../../../../helpers/slateHelper";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    width: "100%",
  },
  label: {
    marginRight: 10,
    marginTop: 12,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    color: theme.palette.text.primary,
    minWidth: 80,
  },
  toolbarCustomClass: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
  editableCustomClass: {
    borderRadius: 5,
    minHeight: "162px !important",
    backgroundColor: theme.palette.secondary.main,
  },
  editorWrapper: {
    width: "100%",
  },
}));

type PropTypes = {
  label: string;
  value: string;
  handleOnChange: (val: string) => void;
};

const ACFRichTextInput = (props: PropTypes) => {
  const classes = useStyles(props);
  const { label, value, handleOnChange } = props;
  const [initialValue, setInitialValue] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleHtmlToJson = async (value: string) => {
    if (!value) return;
    setLoading(true);
    const parser = new DOMParser();
    const doc = parser.parseFromString(value.replace(/(\r\n|\n|\r|\t)/gm, " "), "text/html");
    const res = await HTMLtoJSON(doc.body);
    setInitialValue(JSON.stringify(res));
    setLoading(false);
  };

  const handleJsonToHtml = async (value: any) => {
    const newValue = await jsonToHTML(value);
    handleOnChange(newValue.join(" "));
  };

  React.useEffect(() => {
    handleHtmlToJson(value);
  }, []);

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.label}>{label}</Typography>
      <div className={classes.editorWrapper}>
        {!loading && (
          <LiteRichTextEditor
            key={label}
            editorToolbarPostion={"top"}
            toolbarCustomClass={classes.toolbarCustomClass}
            editableCustomClass={classes.editableCustomClass}
            initialValue={initialValue}
            onValueChanged={(value) => handleJsonToHtml(value)}
          />
        )}
      </div>
    </div>
  );
};

export default ACFRichTextInput;
