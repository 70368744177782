import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import useDebounce from "../../../../hooks/useDebounce";
import MTableToolbarSearchInput from "../../../../components/Table/Toolbar/MTableToolbarSearchInput";

type PropTypes = {
  funnelMap: any;
  sortField: any;
  setSortField: (sortField: any) => void;
  events: any[];
  setEvents: (events: any[]) => void;
  setLoaded: (loaded: boolean) => void;
  hasBreakdown?: boolean;
  hasStrategyValues?: boolean;
  setSearchText: (searchText: string) => void;
  totalPageCount: number;
  showValue: boolean;
  showTraffic: boolean;
  cvr: { enabled: boolean; step1: string; step2: string };
  priority: {
    enabled: boolean;
    step: string;
    column: string;
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  firstColumn: {
    width: "33%",
    paddingLeft: 0,
    display: "flex",
    alignItems: "center",
    paddingRight: 20,
  },
  column: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium as any,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  inputHeader: {
    width: "33%",
  },
  searchBarInputClassName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  selectAllRadio: {
    height: 17,
    width: 17,
    cursor: "pointer",
    marginRight: 10,
    margin: "10px 0px",
    fill: theme.palette.divider,
  },
  cancelBulkIcon: {
    fill: theme.palette.primary.main,
  },
}));

const FunnelsTableHeader = (props: PropTypes) => {
  const theme: any = useTheme();
  const {
    funnelMap,
    sortField,
    setSortField,
    events,
    hasBreakdown,
    hasStrategyValues,
    setEvents,
    setLoaded,
    setSearchText,
    totalPageCount,
    cvr,
    priority,
    showValue,
    showTraffic,
  } = props;
  const [pageFilter, setPageFilter] = useState("");
  const debouncedPageFilter = useDebounce(pageFilter, 500);
  const [search, setSearch] = useState("");

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setLoaded(false);
    if (debouncedPageFilter) {
      setEvents(events.filter((event) => event?.page?.includes(debouncedPageFilter)));
    } else {
      setEvents(events);
    }
    setLoaded(true);
  }, [debouncedPageFilter, events]);

  const classes = useStyles();
  return (
    <MTableHeader>
      {hasBreakdown && (
        <MTableHeaderLabel columnWidth={"33%"} fontSize={theme.typography.pxToRem(16)}>
          {funnelMap.breakdown_field}
        </MTableHeaderLabel>
      )}
      {hasBreakdown && hasStrategyValues && <MTableHeaderLabel>{""}</MTableHeaderLabel>}
      {hasStrategyValues && showValue && (
        <MTableHeaderLabel columnWidth={"70px"}>{I18n.t("analytics.funnels.table.header.value")}</MTableHeaderLabel>
      )}
      {hasStrategyValues && showTraffic && (
        <MTableHeaderLabel columnWidth={"70px"}>{I18n.t("analytics.funnels.table.header.traffic")}</MTableHeaderLabel>
      )}
      {hasStrategyValues && <MTableHeaderLabel>{""}</MTableHeaderLabel>}
      {/* Conversions */}
      {Object.keys(funnelMap)
        .filter(
          (key) =>
            ![
              "breakdown_field",
              "topics",
              "volume",
              "conversion_potential",
              "position",
              "impressions",
              "ctr",
              "value",
              "clicks",
            ].includes(key)
        )
        .map((key) => (
          <MTableHeaderLabel fontSize={14} key={key}>
            {funnelMap[key]}
          </MTableHeaderLabel>
        ))}
      {cvr.enabled && (
        <>
          <MTableHeaderLabel>{""}</MTableHeaderLabel>
          <MTableHeaderLabel fontSize={14}>{I18n.t("analytics.funnels.table.header.cvr")}</MTableHeaderLabel>
        </>
      )}
      {hasStrategyValues && priority.enabled && (
        <>
          <MTableHeaderLabel>{""}</MTableHeaderLabel>
          <MTableHeaderLabel fontSize={14}>{I18n.t("analytics.funnels.table.header.priority")}</MTableHeaderLabel>
        </>
      )}
    </MTableHeader>
  );
};

export default FunnelsTableHeader;
