import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import {
  IWebsite,
  IWebsiteResourcePageProps,
  IWebsiteThemeProps,
} from "../../../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../../../reducers/constants/actionTypes";
import PageSection from "../../../../components/PageSection";
import PostSearchInput from "./PostSearchInput";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
    width: "100%",
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  idsColumns: {
    display: "flex",
    justifyContent: "space-between",
  },
  ids: {
    width: 300,
    marginBottom: 40,
  },
  selectTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 5,
  },
  select: {
    textTransform: "capitalize",
  },
}));

const mapStateToProps = (state) => ({
  resourcePage: state.websitePages.resourcePage,
  theme: state.websitePages.theme,
  sections: state.websitePages.sections,
  urlPrefix: state.websitePages.siteDetails.urlPrefix,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (resourcePage) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "resourcePage",
      value: resourcePage,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  theme: IWebsiteThemeProps;
  website: IWebsite;
  resourcePage: IWebsiteResourcePageProps;
  onPropertyChanged: (value) => void;
  handleToggleActive: (section, enabled) => void;
};

const TopSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { resourcePage, theme, website, handleToggleActive, onPropertyChanged } = props;
  const [error, setError] = React.useState([]);
  const { topSection } = resourcePage;
  const textFieldsValidated = () => {
    const valid = true;
    const emptyFields = [];
    setError(emptyFields);
    return valid;
  };

  React.useEffect(() => {
    if (error.length <= 0) {
      return;
    }
    textFieldsValidated();
  }, []);

  return (
    <PageSection
      sectionTitle={I18n.t("website.resourcePage.top_section.title")}
      checked={topSection.enabled}
      onCheckedChange={(checked) => {
        if (!textFieldsValidated()) {
          if (!checked && topSection.enabled) {
            onPropertyChanged({
              ...resourcePage,
              topSection: { ...topSection, enabled: checked },
            });
          }
          return;
        }
        onPropertyChanged({
          ...resourcePage,
          topSection: { ...topSection, enabled: checked },
        });
      }}
    >
      {error.length > 0 && (
        <div className={classes.errorWrapper}>
          <Typography variant={"body1"} className={classes.error}>
            {I18n.t("website.error_message")}
          </Typography>
        </div>
      )}
      <PostSearchInput
        placeholder={I18n.t("website.resourcePage.top_section.featured_post_ph")}
        title={I18n.t("website.resourcePage.top_section.featured_post")}
        selectedWebsite={website}
        maxPages={1}
        marginBottom={30}
        pages={topSection.featuredPost?.slug ? [topSection.featuredPost] : []}
        handlePagesChange={(page) => {
          onPropertyChanged({
            ...resourcePage,
            topSection: { ...topSection, featuredPost: page },
          });
        }}
      />
      {resourcePage.template !== "safebooks" && (
        <>
          <Divider className={classes.divider} />
          <PostSearchInput
            placeholder={I18n.t("website.resourcePage.top_section.top_articles_ph", { count: 3 })}
            title={I18n.t("website.resourcePage.top_section.top_articles")}
            selectedWebsite={website}
            maxPages={3}
            marginBottom={30}
            pages={topSection.topArticles}
            handlePagesChange={(pages) => {
              onPropertyChanged({
                ...resourcePage,
                topSection: { ...topSection, topArticles: pages },
              });
            }}
          />
          <Divider className={classes.divider} />
          <PostSearchInput
            placeholder={I18n.t("website.resourcePage.top_section.editor_picks_ph", { count: 3 })}
            title={I18n.t("website.resourcePage.top_section.editor_picks")}
            selectedWebsite={website}
            maxPages={3}
            marginBottom={30}
            pages={topSection.editorPicks}
            handlePagesChange={(pages) => {
              onPropertyChanged({
                ...resourcePage,
                topSection: { ...topSection, editorPicks: pages },
              });
            }}
          />
        </>
      )}
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TopSection);
