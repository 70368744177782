import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { web } from "../../helpers/urlHelper";
import { IWebsite } from "../../reducers/constants/objectTypes";
import SourceFiles from "./Pages/SourceFiles/SourceFiles";
import { AbilityContext } from "../../casl/can";
import LoginAs from "./Pages/LoginAs";
import Account from "./Pages/Account/Account";
import Statistics from "./Pages/Statistics/Statistics";
import Insights from "../CRO/Inssights/Insights";
import EditAd from "../CRO/pages/EditAd/EditAd";
import AdsListPage from "../CRO/pages/AdsList/AdsListPage";
import Entail from "../Entail/Entail";
import Experts from "../Entail/Experts/Experts";

type PropTypes = {
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const Legacy = (props: PropTypes) => {
  const ability = React.useContext<any>(AbilityContext);
  const { selectedWebsite } = props;
  const routes = React.useMemo(() => {
    const allRoutes = [];
    if (ability.can("view", "legacy_source_files_tab")) {
      allRoutes.push({
        url: web.legacySourceFiles(),
        route: (
          <Route
            key={`legacy_${selectedWebsite._id}_source_files`}
            exact={false}
            path={web.legacySourceFiles()}
            component={SourceFiles}
          />
        ),
      });
    }
    if (ability.can("view", "legacy_login_as_tab")) {
      allRoutes.push({
        url: web.legacyLoginAs(),
        route: (
          <Route
            key={`legacy_${selectedWebsite._id}_login_as`}
            exact={false}
            path={web.legacyLoginAs()}
            component={LoginAs}
          />
        ),
      });
    }
    if (ability.can("view", "legacy_account_setup_tab")) {
      allRoutes.push({
        url: web.legacyAccountSetup(),
        route: (
          <Route
            key={`legacy_${selectedWebsite._id}_account_setup`}
            exact={false}
            path={web.legacyAccountSetup()}
            render={() => <Account />}
          />
        ),
      });
    }
    if (ability.can("view", "legacy_account_pricing_tab")) {
      allRoutes.push({
        url: web.legacyAccountPricing(),
        route: (
          <Route
            key={`legacy_${selectedWebsite._id}_account_pricing`}
            exact={false}
            path={web.legacyAccountPricing()}
            render={() => <Account />}
          />
        ),
      });
    }
    if (ability.can("view", "legacy_site_stats_tab")) {
      allRoutes.push({
        url: web.legacyWriterStats(),
        route: (
          <Route
            key={`legacy_${selectedWebsite._id}_site_stats`}
            exact={false}
            path={web.legacyWriterStats()}
            component={Statistics}
          />
        ),
      });
    }
    if (ability.can("view", "legacy_insights_tab")) {
      allRoutes.push({
        url: web.legacyInsights(":report"),
        route: (
          <Route
            key={`legacy_${selectedWebsite._id}_insights`}
            exact={false}
            path={web.legacyInsights(":report")}
            component={() => <Insights />}
          />
        ),
      });
    }

    if (ability.can("view", "cro_ads_tab")) {
      allRoutes.push({
        url: web.adsList(":adId"),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_edit_ad`}
            exact={false}
            path={web.adsList(":adId")}
            component={EditAd}
          />
        ),
      });
      allRoutes.push({
        url: web.adsList(),
        route: (
          <Route key={`cro_${selectedWebsite._id}_ads`} exact={false} path={web.adsList()} component={AdsListPage} />
        ),
      });
    }
    if (selectedWebsite.name === "Entail") {
      allRoutes.push({
        url: web.entailExperts(),
        route: <Route exact={false} path={web.entailExperts()} component={Experts} />,
      });
    }
    return allRoutes;
  }, [selectedWebsite, ability]);
  return (
    <Switch>
      {routes.map((route) => route.route)}
      <Redirect exact from={"*"} to={routes[0]?.url} />
    </Switch>
  );
};

export default connect(mapStateToProps)(Legacy);
