import { KEYWORDAGGREGATION } from "./constants/actionTypes";

const initialState = {
  data: [],
  page: 1,
  pageSize: 50,
  totalPages: null,
  totalCount: null,
  columns: [],
  searchText: "",
  sort: null,
  filter: {},
  isLoading: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case KEYWORDAGGREGATION.KEYWORDAGGREGATION_LOADED:
      return {
        ...state,
        data: action.payload.data,
        totalPages: action.payload.pagination?.totalPages,
        page: action.payload.pagination?.currentPage,
        totalCount: action.payload.pagination?.totalCount,
        isLoading: false,
      };

    case KEYWORDAGGREGATION.ON_COLUMNS_CHANGED:
      return {
        ...state,
        columns: action.columns,
      };

    case KEYWORDAGGREGATION.ON_SEARCHTEXT_CHANGED:
      return {
        ...state,
        searchText: action.searchText,
      };

    case KEYWORDAGGREGATION.ON_PAGE_CHANGED:
      return {
        ...state,
        page: action.page,
      };

    case KEYWORDAGGREGATION.ON_PAGESIZE_CHANGED:
      return {
        ...state,
        pageSize: action.pageSize,
      };

    case KEYWORDAGGREGATION.ON_SORT_CHANGED:
      return {
        ...state,
        sort: action.sort,
      };

    case KEYWORDAGGREGATION.ON_FILTER_CHANGED:
      return {
        ...state,
        filter: action.filter,
      };

    case KEYWORDAGGREGATION.IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
};
