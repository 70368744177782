import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Theme } from "@material-ui/core/styles";
import { ISourceFile, IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import SourceFilesList from "./components/SourceFilesList";
import VideoPlayerDialog from "../../../Modals/VideoPlayerDialog";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarBaseActionButton from "../../../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import UploadSourceFileDialog from "../../../Modals/UploadSourceFileDialog/UploadSourceFileDialog";
import MPagination from "../../../../components/MPagination";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";

const useStyles = makeStyles((theme: Theme) => ({
  refreshButton: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  refreshIcon: {
    width: 17,
    height: 17,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const SourceFiles = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;

  const [videoPlayerFile, setVideoPlayerFile] = useState(null);

  const [searchText, setSearchText] = useState(null);
  const [pagination, setPagination] = useState({ page: 1, limit: 20 });
  const [totalPages, setTotalPages] = useState(1);
  const [sourceFiles, setSourceFiles] = useState([]);

  async function loadSourceFiles() {
    try {
      const response = await agent.SourceFiles.list(selectedWebsite, pagination.page, pagination.limit, searchText);
      setSourceFiles(response.items);
      setTotalPages(response.totalItems);
    } catch (e) {
      console.log("getVideos", e);
    }
  }

  React.useEffect(() => {
    loadSourceFiles();
  }, [pagination, searchText]);

  const handleSourceFileChanged = (sourceFile: ISourceFile) => {
    const newSourceFiles = sourceFiles.map((sf) => {
      if (sf._id === sourceFile._id) {
        return sourceFile;
      }
      return sf;
    });
    setSourceFiles(newSourceFiles);
  };

  const [uploadFileDialogOpen, setUploadFileDialogOpen] = React.useState(false);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.legacy.source_files.title`)} />
      <MTableToolbar
        searchText={searchText}
        handleSearchTextChanged={(value) => setSearchText(value)}
        actions={[
          <Tooltip enterDelay={500} title={I18n.t(`videos.header.refresh_table`)}>
            <IconButton
              disableRipple
              className={classes.refreshButton}
              onClick={(event) => {
                event.stopPropagation();
                loadSourceFiles();
              }}
            >
              <RefreshIcon className={classes.refreshIcon} />
            </IconButton>
          </Tooltip>,
          <MTableToolbarBaseActionButton
            handleClick={() => {
              setUploadFileDialogOpen(true);
            }}
            text={I18n.t("videos.header.upload")}
          />,
        ]}
      />
      <SourceFilesList
        website={selectedWebsite}
        sourceFiles={sourceFiles}
        handleSourceFileChanged={handleSourceFileChanged}
        openSourceFilePage={(sourceFile: ISourceFile) => setVideoPlayerFile(sourceFile)}
      />
      {totalPages > 0 && (
        <MPagination
          paginationLimit={pagination.limit}
          page={pagination.page}
          paginationPagesCount={totalPages}
          setPaginationLimit={(limit) => setPagination({ ...pagination, limit })}
          setPage={(page) => setPagination({ ...pagination, page })}
        />
      )}

      {videoPlayerFile && (
        <VideoPlayerDialog
          src={videoPlayerFile.cdnUrl}
          subtitle={
            videoPlayerFile.transcriptionVttUrl
              ? {
                  src: videoPlayerFile.transcriptionVttUrl,
                  language: "en",
                }
              : null
          }
          handleExitClick={() => setVideoPlayerFile(null)}
          open
        />
      )}
      {uploadFileDialogOpen && (
        <UploadSourceFileDialog
          open
          selectedWebsite={selectedWebsite}
          handleExitClick={() => {
            setUploadFileDialogOpen(false);
            loadSourceFiles();
          }}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, null)(SourceFiles);
