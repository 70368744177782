import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, Theme, TextFieldProps } from "@material-ui/core";
import classNames from "classnames";
import { omit } from "lodash";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import useDebounce from "../hooks/useDebounce";

const useStyles = makeStyles(({ palette, typography }: Theme) => ({
  textFieldRoot: ({ disabled }) => ({
    minHeight: 50,
    padding: 15,
    backgroundColor: "#EEF2F9",
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    opacity: disabled ? 0.5 : 1,
    color: palette.text.primary,
    "& .MuiInputBase-root.Mui-disabled": {
      color: palette.text.primary,
    },
  }),
  input: {
    fontSize: 14,
    fontWeight: typography.fontWeightRegular as any,
    padding: 0,
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    // placeholder
    "&::placeholder": {
      color: palette.text.primary,
    },
    "&:disabled": {
      "&::placeholder": {
        color: palette.text.primary,
      },
    },
  },
  inputRoot: {
    backgroundColor: "inherit",
  },
  endAdornmentWrapper: {
    display: "flex",
    alignItems: "center",
  },
  numberIconsWrapper: ({ disabled }: PropTypes) => ({
    height: "100%",
    width: 20,
    position: "relative",
    "& svg": {
      cursor: !disabled ? "pointer" : "normal",
      position: "absolute",
      left: 0,
      color: palette.text.primary,
      "&:hover": {
        color: !disabled ? palette.primary.main : "unset",
      },
      "&:first-child": {
        top: -26,
        zIndex: 1,
      },
      "&:last-child": {
        top: 5,
      },
    },
  }),
}));

type PropTypes = {
  className?: string;
  endAdornment?: React.ReactNode;
  transformValue?: (value: string) => string;
  onDebounceChange?: (value: string) => void;
  onValueChange?: (value: string) => void;
} & Partial<Omit<TextFieldProps, "type" | "inputProps" | "InputProps">> &
  (
    | {
        type?: "text";
        showNumberControls?: never;
        maxLength?: number;
        noDecimals?: never;
        min?: never;
        max?: never;
        step?: never;
        onNumberChange?: never;
      }
    | {
        type?: "number";
        showNumberControls?: boolean;
        maxLength?: never;
        noDecimals?: boolean;
        min?: number;
        max?: number;
        step?: number;
        onNumberChange?: (value: number) => void;
      }
  );

const MTextFieldV2 = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    value: propValue,
    type = "text",
    noDecimals,
    endAdornment,
    maxLength,
    min = 0,
    max,
    step = 1,
    disabled,
    showNumberControls = true,
  } = props;
  const { transformValue, onChange, onDebounceChange, onValueChange, onNumberChange } = props;
  const [localValue, setLocalValue] = useState<unknown>(propValue);
  const isInitialRenderRef = React.useRef(true);
  const [hadPropValueOnInit, setHadPropValueOnInit] = useState(false);

  const debouncedValue = useDebounce(localValue, 500);

  useEffect(() => {
    if (isInitialRenderRef.current) {
      isInitialRenderRef.current = false;
      return;
    }

    if (onDebounceChange) {
      onDebounceChange(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (propValue !== undefined) {
      setHadPropValueOnInit(true);
    }
  }, [propValue]);

  useEffect(() => {
    if (
      (type === "number" && (localValue?.toString().endsWith(".") || localValue?.toString().startsWith("."))) ||
      localValue === "-"
    ) {
      return;
    }

    // todo: improve condition without onDebounceChange check
    if (hadPropValueOnInit && propValue !== localValue && !onDebounceChange) {
      setLocalValue(propValue);
    }
  }, [propValue, localValue, type, hadPropValueOnInit]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }

    let value = e.target.value as any;

    if (type === "number") {
      if (value.startsWith(".")) {
        value = "0" + value;
      }

      if (value === "-") {
        setLocalValue(value);
        return;
      }

      if (value.endsWith(".")) {
        setLocalValue(value);
        return;
      }

      value = parseFloat(e.target.value) as any;

      if (isNaN(value)) {
        value = "";
      } else {
        if (typeof min === "number") {
          value = Math.max(value, min);
        }

        if (typeof max === "number") {
          value = Math.min(value, max);
        }

        const decimalCount = noDecimals ? 0 : step.toString().split(".")[1]?.length || 1;
        const formattedValue = noDecimals ? parseInt(value, 10) : parseFloat(value.toFixed(decimalCount));
        value = formattedValue;
      }
    }

    if (transformValue) {
      value = transformValue(value);
    }

    if (onValueChange) {
      onValueChange(value);
    }

    if (onNumberChange) {
      onNumberChange(value);
    }

    setLocalValue(value);
  };

  const handleIncrementDecrement = (action: "increment" | "decrement", e: any) => {
    if (disabled) {
      return;
    }
    const parsedValue = localValue ? parseFloat(localValue as string) : 0;
    let newValue = action === "increment" ? parsedValue + step : parsedValue - step;

    if (typeof min === "number") {
      newValue = Math.max(newValue, min);
    }

    if (typeof max === "number") {
      newValue = Math.min(newValue, max);
    }

    if (!noDecimals) {
      const decimalCount = noDecimals ? 0 : step.toString().split(".")[1]?.length || 1;
      newValue = parseFloat(newValue.toFixed(decimalCount));
    }

    if (onNumberChange) {
      onNumberChange(newValue);
    }

    if (onValueChange) {
      onValueChange(newValue.toString());
    }

    if (onChange) {
      onChange({
        ...e,
        target: {
          ...e.target,
          value: newValue,
        },
      } as any);
    }

    setLocalValue(newValue);
  };

  return (
    <TextField
      {...omit(props, [
        "onDebounceChange",
        "onValueChange",
        "onNumberChange",
        "InputProps",
        "transformValue",
        "noDecimals",
        "min",
        "max",
        "step",
        "endAdornment",
        "type",
        "value",
      ])}
      type="text"
      value={localValue ?? ""}
      onChange={handleChange}
      variant="standard"
      fullWidth
      margin="none"
      className={classNames(classes.textFieldRoot, props.className)}
      inputProps={{
        min,
        max,
        step,
        maxLength,
      }}
      InputProps={{
        endAdornment: (
          <div className={classes.endAdornmentWrapper}>
            {endAdornment}
            {type === "number" && showNumberControls && (
              <div className={classes.numberIconsWrapper}>
                <KeyboardArrowUpIcon viewBox="0 -8 24 24" onClick={(e) => handleIncrementDecrement("increment", e)} />
                <KeyboardArrowDownIcon viewBox="0 8 24 24" onClick={(e) => handleIncrementDecrement("decrement", e)} />
              </div>
            )}
          </div>
        ),
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
          input: classes.input,
        },
      }}
    />
  );
};

export default MTextFieldV2;
