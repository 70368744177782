import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { orderBy } from "lodash";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import moment from "moment/moment";
import MTablePagination from "../../../../components/Table/Pagination/MTablePagination";
import { options as timePeriodOptions } from "../components/filters/TimePeriodFilter";
import { options as timeFrameOptions } from "../components/filters/TimeFrameFilter";
import { options as totalUniqueOptions } from "../components/filters/TotalUniqueFilter";
import PageEventsReportHeader from "./components/PageEventsReportHeader";
import agent from "../../../../agent";
import CfProgressBar from "../../../../components/CfProgressBar";
import PageEventsReportTable from "./components/PageEventsReportTable";
import PageEventsReportBreakdownTable from "./components/PageEventsReportBreakdownTable";
import { options as pageSourceOptions } from "../components/filters/PageSourceFilter";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
  innerWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 65px)",
    marginBottom: 20,
  },
  reportsDashboardWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    margin: 20,
    position: "relative",
    height: "100%",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableWrapper: {
    margin: 0,
    overflowX: "auto",
    height: "calc(100vh - 225px)",
    position: "relative",
  },
  headerWrapper: {
    backgroundColor: "white",
    padding: "30px 20px 0px 0px",
    width: 80,
  },
  firstColumn: {
    backgroundColor: theme.palette.common.white,
  },
  headerFirstColumn: {
    zIndex: 100,
    backgroundColor: theme.palette.common.white,
  },
  pageEventsAvatar: {
    backgroundColor: "#B780FE !important",
  },
  table: {
    position: "relative",
    borderSpacing: "20px 0",
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: "auto",
    height: "calc(100vh - 225px)",
  },
  tableRow: {
    height: 53,
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      transition: "border-radius 500ms",
      "& $firstColumn": {
        backgroundColor: theme.palette.secondary.main,
      },
      "& $headerFirstColumn": {
        backgroundColor: theme.palette.secondary.main,
      },
      "& $thFirst": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  thFirst: {
    position: "sticky",
    left: 0,
    width: "50%",
    paddingLeft: 30,
    backgroundColor: theme.palette.common.white,
  },
  rowCell: {
    width: 80,
    paddingRight: 20,
    paddingLeft: 0,
    cursor: "pointer",
  },
  tableHeader: {
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    top: 0,
    borderBottom: `30px solid ${theme.palette.common.white}`,
  },
  tableFooter: {
    width: "100%",
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    bottom: 0,
  },
  pagination: {
    display: "flex",
    zIndex: 100,
    backgroundColor: theme.palette.common.white,
    width: "calc(100vw - 40px)",
    padding: "0px 20px",
    position: "fixed",
    bottom: 0,
    margin: 0,
  },
  totalTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 16,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    textAlign: "right",
    padding: "10px 30px 10px 0px",
    height: 50,
    width: "30%",
  },
  firstRow: {
    paddingTop: 15,
    paddingBottom: 15,
  },
}));

type Props = {
  website: IWebsite;

  selectedReport: any;
  setSelectedReport: (report) => void;
};

const PageEventsReport = (props: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const [selectedTimePeriod, setSelectedTimePeriod] = React.useState(timePeriodOptions[2]);
  const [selectedTimeFrame, setSelectedTimeFrame] = React.useState(timeFrameOptions[2]);
  const [breakdownValue, setBreakdownValue] = React.useState(
    location.search
      ? {
          label: new URLSearchParams(location.search).get("traffic"),
          value: new URLSearchParams(location.search).get("traffic"),
        }
      : null
  );
  const [selectedEvent, setSelectedEvent] = React.useState(undefined);
  const [selectedBreakdown, setSelectedBreakdown] = React.useState({
    label: "URL",
    value: {
      field: "url",
      table: "events",
      eventTable: "e",
    },
  });
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(100);
  const [selectedCountFilter, setSelectedCountFilter] = React.useState(totalUniqueOptions[0]);

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<{
    timeFrame: string;
    results: any[];
    metadata: any[];
    total: number;
    totalPages: number;
    totals: any[];
  }>({
    timeFrame: "",
    results: [],
    metadata: [],
    totalPages: 0,
    total: 0,
    totals: [],
  });
  const [expandedData, setExpandedData] = React.useState<{
    timeFrame: string;
    eventName: string;
    eventProps: string[];
    results: any[];
    totals: any;
    total: number;
    totalPages: number;
  }>(undefined);
  const [sortField, setSortField] = React.useState<{ direction: "desc" | "asc" | undefined; field: string }>(null);

  const { website, selectedReport, setSelectedReport } = props;

  // useEffect(() => {
  //   try {
  //     const query = new URLSearchParams(location.search);
  //     const queryPage = query.get("traffic");
  //     if (queryPage) {
  //       console.log("setBreakdownValue queryPage", queryPage);
  //       setBreakdownValue({ value: queryPage, label: queryPage });
  //     }
  //   } catch (error) {}
  // }, [location]);

  const momentTimeFrame = useMemo(() => {
    switch (selectedTimeFrame.value) {
      case "day":
        return "day";
      case "week":
        return "isoWeek";
      case "month":
      default:
        return "month";
    }
  }, [selectedTimeFrame]);

  const startDate = React.useMemo(() => {
    return moment().subtract(selectedTimePeriod.value, "month").toDate();
  }, [selectedTimePeriod]);

  React.useEffect(() => {
    if (selectedEvent) {
      return;
    }
    setLoading(true);
    agent.Reports.getPageEvents({
      website,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      breakdownValue: breakdownValue?.value || null,
      timeFrame: selectedTimeFrame.value,
      events: "",
      column: selectedBreakdown.value.field,
      table: selectedBreakdown.value.eventTable,
      page,
      limit,
      countFilter: selectedCountFilter.value,
      refreshCache: false,
    })
      .then((res) => {
        console.log("res", res);
        setData({
          results: res.results,
          total: res.total,
          totals: res.totals,
          totalPages: res.totalPages,
          metadata: res.metadata,
          timeFrame: res.timeFrame,
        });
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [website, breakdownValue, startDate, selectedTimeFrame, selectedBreakdown, page, limit, selectedCountFilter]);

  React.useEffect(() => {
    if (!selectedEvent) {
      return;
    }
    setLoading(true);
    const startDateString = moment(startDate).format("YYYY-MM-DD");
    agent.Reports.getPageEventBreakdown({
      website,
      startDate: startDateString,
      breakdownValue: breakdownValue?.value || null,
      timeFrame: selectedTimeFrame.value,
      events: "",
      event: selectedEvent.value,
      column: selectedBreakdown.value.field,
      table: selectedBreakdown.value.eventTable,
      countFilter: selectedCountFilter.value,
    })
      .then((data) => {
        setExpandedData({
          eventProps: data.eventProps,
          results: data.results,
          eventName: data.eventName,
          totals: data.totals,
          total: data.total,
          totalPages: data.totalPages,
          timeFrame: data.timeFrame,
        });
        console.log("expandedData", expandedData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [selectedEvent, breakdownValue, startDate, selectedTimeFrame, selectedBreakdown, selectedCountFilter, website]);

  const newDataRanges = useMemo(() => {
    const dates = [];

    const start = moment(startDate).startOf(momentTimeFrame);
    const end = moment().endOf(momentTimeFrame);

    while (start.isBefore(end)) {
      dates.push(start.format("YYYY-MM-DD"));
      start.add(1, momentTimeFrame as any);
    }

    return dates;
  }, [startDate]);

  console.log("breakdownValue", breakdownValue);
  return (
    <div className={classes.innerWrapper}>
      <div className={classes.reportsDashboardWrapper}>
        <PageEventsReportHeader
          selectedWebsite={website}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
          selectedBreakdown={selectedBreakdown}
          setSelectedBreakdown={setSelectedBreakdown}
          breakdownValue={breakdownValue}
          setBreakdownValue={setBreakdownValue}
          selectedEvent={selectedEvent}
          setSelectedEvent={(event) => {
            console.log("setSelectedEvent", event);
            setSelectedEvent(event);
          }}
          selectedCountFilter={selectedCountFilter}
          setSelectedCountFilter={setSelectedCountFilter}
          selectedTimePeriod={selectedTimePeriod}
          setSelectedTimePeriod={setSelectedTimePeriod}
          selectedTimeFrame={selectedTimeFrame}
          setSelectedTimeFrame={setSelectedTimeFrame}
        />
        <div className={classes.tableWrapper}>
          {loading ? (
            <div className={classes.loader}>
              <CfProgressBar />
            </div>
          ) : (
            <>
              {selectedEvent ? (
                <PageEventsReportBreakdownTable
                  expandedData={expandedData}
                  dateRange={newDataRanges}
                  setExpanded={() => setSelectedEvent(undefined)}
                />
              ) : (
                <PageEventsReportTable
                  data={data}
                  startDate={startDate}
                  momentTimeFrame={momentTimeFrame}
                  setSelectedEvent={(event) => {
                    console.log("selectedEvent 1", event);
                    setLoading(true);
                    setSelectedEvent(event);
                  }}
                />
              )}
            </>
          )}
          {data?.totalPages > 0 && (
            <MTablePagination
              className={classes.pagination}
              paginationLimit={limit}
              page={page}
              paginationPagesCount={data.totalPages}
              setPaginationLimit={setLimit}
              setPage={setPage}
            />
          )}
          {/*{expanded && (*/}
          {/*  <PageEventsReportEventPropsDialog*/}
          {/*    closeDialog={() => setExpanded(false)}*/}
          {/*    dateRange={newDataRanges}*/}
          {/*    eventType={expanded}*/}
          {/*    pageUrl={selectedUrl.value}*/}
          {/*    website={website}*/}
          {/*    timeFrame={selectedTimeFrame.value}*/}
          {/*    startDate={startDate}*/}
          {/*  />*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  );
};

export default PageEventsReport;
