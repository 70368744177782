import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import addImage from "../../../img/image_placeholder.svg";
import InsertImageDialog from "../../Modals/InsertImageDialog/InsertImageDialog";
import { IFile, IWebsite } from "../../../reducers/constants/objectTypes";
import { getImageCDNUrl } from "../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    width: "fit-content",
  },
  imagePickerWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: (props: PropTypes) => props.marginTop || 0,
    marginBottom: (props: PropTypes) => props.marginBottom || 0,
  },
  previewImagePlaceHolder: {
    backgroundColor: theme.palette.secondary.main,
    border: (props: PropTypes) => (props.error ? "solid 1px red " : "none"),
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    minWidth: 20,
    minHeight: 20,
    backgroundImage: `url(${addImage})`,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  image: IFile;

  imageWidth?: string | number;
  imageHeight?: string | number;
  marginTop?: number;
  marginBottom?: number;
  rounded?: boolean;
  objectFit?: "cover" | "contain";
  forcesImageSize?: {
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
  };
  onImageChanged: (image) => void;
  error?: boolean;
  imageClassName?: string;
};

const ImagePicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const {
    selectedWebsite,
    image,
    objectFit = "cover",
    rounded = false,
    error = false,
    imageWidth = null,
    imageHeight = null,
    forcesImageSize = null,
    imageClassName,
  } = props;
  const { onImageChanged } = props;

  return (
    <>
      <div className={classes.wrap} onClick={(e) => setOpen(true)}>
        <img
          className={ClassNames(!image?.url && classes.previewImagePlaceHolder, imageClassName)}
          src={
            getImageCDNUrl(image?.cdnUrl || image?.url) ||
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          }
          height={rounded ? imageWidth : imageHeight || "auto"}
          width={imageWidth || "100%"}
          style={{
            maxWidth: image?.width,
            borderRadius: rounded ? "50%" : 0,
            objectFit,
            cursor: "pointer",
          }}
          alt={image?.alt || ""}
        />
      </div>

      {open && (
        <InsertImageDialog
          website={selectedWebsite}
          selectedImageFile={image || null}
          forcesImageSize={forcesImageSize}
          handleInsertClick={(file: IFile) => {
            onImageChanged(file);
            setOpen(false);
          }}
          handleCloseClick={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default React.memo(ImagePicker);
