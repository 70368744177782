import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { uniq } from "lodash";
import { useState } from "react";
import { I18n } from "react-redux-i18n";

import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import MTag from "../../../../../components/MTag";

const useStyles = makeStyles(() => ({
  expertiseContainer: {
    width: "100%",
  },
  selectedOptionsWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > *": {
      marginTop: 10,
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;

  expertise: string[];

  handleExpertiseChange: (expertise: string[]) => void;
};

const ExpertExpertise = (props: PropTypes) => {
  const classes = useStyles(props);
  const { expertise = [], handleExpertiseChange } = props;
  const [input, setInput] = useState("");

  return (
    <div className={classes.expertiseContainer}>
      <TextFieldMaxLength
        key={`expert_location`}
        titleAndLengthLocation={"top"}
        value={input}
        marginTop={0}
        placeholder={"Add expertise"}
        title={I18n.t("experts.profile.expertise")}
        borderRadius={4}
        handleValueChange={(text) => setInput(text)}
        onEnterKey={(text) => {
          handleExpertiseChange(uniq([...(expertise || []), text]));
          setInput("");
        }}
        fullWidth
      />

      <div className={classes.selectedOptionsWrapper}>
        {expertise &&
          expertise.map((option, i) => (
            <MTag
              key={`expertise_${i}`}
              tag={option}
              onRemoveClicked={() => handleExpertiseChange(expertise.filter((t) => t !== option))}
            />
          ))}
      </div>
    </div>
  );
};

export default ExpertExpertise;
