import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import {
  IWebsite,
  IWebsiteResourcePageProps,
  IWebsiteSectionProps,
  IWebsiteThemeProps,
} from "../../../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../../../reducers/constants/actionTypes";
import PageSection from "../../../../components/PageSection";
import PostSearchInput from "./PostSearchInput";
import TextInputToolAndDesign from "../../../../components/TextInputToolAndDesign";
import GradientBackgroundColorCSS from "../../../../../../editor/elementsStyles/CssComponents/GradientBackgroundColorCSS";
import ButtonTextAndUrlAndDesign from "../../../../components/ButtonTextAndUrlAndDesign";
import TextDesignToolWithPreview from "../../../../components/TextDesignToolWithPreview";
import SelectRow from "../../../../components/SelectRow";
import MTextArea from "../../../../../../components/MTextArea";
import inlineHtml from "../../../../../../editor/elements/inlineHTML/inlineHtml";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
    width: "100%",
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  idsColumns: {
    display: "flex",
    justifyContent: "space-between",
  },
  ids: {
    width: 300,
    marginBottom: 40,
  },
  selectTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 5,
  },
  select: {
    textTransform: "capitalize",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  textArea: {
    width: "100%",
    resize: "none",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    padding: 10,
    fontFamily: "monospace",
  },
}));

const mapStateToProps = (state) => ({
  resourcePage: state.websitePages.resourcePage,
  theme: state.websitePages.theme,
  sections: state.websitePages.sections,
  urlPrefix: state.websitePages.siteDetails.urlPrefix,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (resourcePage) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "resourcePage",
      value: resourcePage,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  theme: IWebsiteThemeProps;
  website: IWebsite;
  resourcePage: IWebsiteResourcePageProps;
  onPropertyChanged: (value) => void;
  handleToggleActive: (section, enabled) => void;
};

const DynamicBanner = (props: PropTypes) => {
  const classes = useStyles(props);
  const { resourcePage, theme, website, handleToggleActive, onPropertyChanged } = props;
  const [error, setError] = React.useState([]);
  const { dynamicBanner } = resourcePage;
  const textFieldsValidated = () => {
    const valid = true;
    const emptyFields = [];
    setError(emptyFields);
    return valid;
  };

  React.useEffect(() => {
    if (error.length <= 0) {
      return;
    }
    textFieldsValidated();
  }, []);

  return (
    <PageSection
      sectionTitle={I18n.t("website.resourcePage.dynamic_banner.name")}
      checked={dynamicBanner.enabled}
      onCheckedChange={(checked) => {
        if (!textFieldsValidated()) {
          if (!checked && dynamicBanner.enabled) {
            onPropertyChanged({
              ...resourcePage,
              dynamicBanner: { ...dynamicBanner, enabled: checked },
            });
          }
          return;
        }
        onPropertyChanged({
          ...resourcePage,
          dynamicBanner: { ...dynamicBanner, enabled: checked },
        });
      }}
    >
      {error.length > 0 && (
        <div className={classes.errorWrapper}>
          <Typography variant={"body1"} className={classes.error}>
            {I18n.t("website.error_message")}
          </Typography>
        </div>
      )}
      <SelectRow
        width={"50%"}
        bold
        flexDirection={"column"}
        rowTitle={I18n.t("website.resourcePage.dynamic_banner.custom_html")}
        value={dynamicBanner.customHtml.enabled ? "Yes" : "No"}
        selectOptions={["Yes", "No"]}
        handleChange={(value) => {
          onPropertyChanged({
            ...resourcePage,
            dynamicBanner: { ...dynamicBanner, customHtml: { ...dynamicBanner.customHtml, enabled: value === "Yes" } },
          });
        }}
      />
      <GradientBackgroundColorCSS
        value={dynamicBanner.background}
        title={I18n.t("website.resourcePage.featured_banner.background")}
        defaultValue={null}
        isValueChanged={dynamicBanner.background !== null}
        handleChange={(value) =>
          onPropertyChanged({
            ...resourcePage,
            dynamicBanner: { ...dynamicBanner, background: value },
          })
        }
      />
      {!dynamicBanner.customHtml.enabled && (
        <div className={classes.container}>
          <TextInputToolAndDesign
            background={dynamicBanner.background?.style}
            textFieldTitle={I18n.t("website.resourcePage.dynamic_banner.title")}
            text={dynamicBanner.title}
            handleTextChanged={(value) =>
              onPropertyChanged({
                ...resourcePage,
                dynamicBanner: { ...dynamicBanner, title: value },
              })
            }
            variant={"h4"}
          />

          <ButtonTextAndUrlAndDesign
            toggleArrow
            background={dynamicBanner.background?.style}
            textFieldTitle={I18n.t("website.resourcePage.featured_banner.button_text")}
            urlFieldTitle={I18n.t("website.resourcePage.featured_banner.button_url")}
            button={dynamicBanner.button || { text: "", url: "", css: {} }}
            defaultButtonCss={theme?.buttons?.css}
            handleButtonChanged={(value) =>
              onPropertyChanged({
                ...resourcePage,
                dynamicBanner: { ...dynamicBanner, button: value },
              })
            }
          />
        </div>
      )}
      {dynamicBanner.customHtml.enabled && (
        <MTextArea
          minRows={5}
          className={classes.textArea}
          text={dynamicBanner.customHtml.value}
          placeholder={I18n.t("website.resourcePage.dynamic_banner.custom_html_ph")}
          onChange={(value) =>
            onPropertyChanged({
              ...resourcePage,
              dynamicBanner: { ...dynamicBanner, customHtml: { ...dynamicBanner.customHtml, value } },
            })
          }
        />
      )}
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicBanner);
