import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ClassNames from "classnames";
import CfCheckBox from "../../../../../../../components/CfCheckBox";
import { IAdPlacement, IFile, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import InsertImageDialog from "../../../../../../Modals/InsertImageDialog/InsertImageDialog";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    display: "block",
    padding: 0,
    height: 95,
    cursor: "pointer",
    // fontSize: theme.typography.pxToRem(12),
  },
  itemWrapperSelected: {
    backgroundColor: "#fff0f7",
  },
  placementWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "100%",
    paddingLeft: 30,
    alignItems: "center",
  },
  placementDetails: {
    display: "flex",
    flexDirection: "column",
  },
  placementName: {
    // fontSize: theme.typography.pxToRem(14),
    // fontWeight: theme.typography.fontWeightBold as any,
  },
  placementLocation: {
    fontSize: theme.typography.pxToRem(12),
    // fontWeight: theme.typography.fontWeightBold as any,
  },
  placementAddImageButton: {
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(12),
    color: "#4353ff",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  checkBoxWrapper: {},
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedPlacement: IAdPlacement;
  placement?: IAdPlacement;
  placementType:
    | "BLOG_FEED_IMAGE"
    | "BLOG_MOBILE_FEED_IMAGE"
    | "TALL_SIDE"
    | "FORUM_POST_IMAGE"
    | "FORUM_POST_TEXT_ONLY"
    | "MOBILE_FEED_CENTER"
    | "POST_FORM";

  onPlacementSelected: Function;
  onPlacementChanged: Function;
};

const AdPlacementListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    placement = null,
    selectedPlacement,
    placementType,
    onPlacementSelected,
    onPlacementChanged,
  } = props;
  const [insertImageOpen, setInsertImageOpen] = React.useState(false);

  return (
    <ListItem
      divider
      onClick={() => onPlacementSelected(placement || { type: placementType })}
      className={ClassNames(
        classes.itemWrapper,
        selectedPlacement?.type === placementType && classes.itemWrapperSelected
      )}
    >
      <div className={classes.placementWrapper}>
        <div className={classes.placementDetails}>
          <Typography className={classes.placementName} variant="subtitle2">
            {I18n.t(`ads.edit_ad.placements.${placementType.toLowerCase()}.name`)}
          </Typography>
          <Typography className={classes.placementLocation} variant="body1">
            {I18n.t(`ads.edit_ad.placements.${placementType.toLowerCase()}.location`)}
          </Typography>
          <Typography
            className={classes.placementAddImageButton}
            variant="body1"
            onClick={(e) => {
              e.stopPropagation();
              setInsertImageOpen(true);
            }}
          >
            {placement?.imageFile
              ? I18n.t(`ads.edit_ad.placements.replace_image`)
              : I18n.t(`ads.edit_ad.placements.add_image`)}
          </Typography>
        </div>
        <div className={classes.checkBoxWrapper}>
          <CfCheckBox
            checked={placement?.active || false}
            value={placementType}
            handleChange={() =>
              onPlacementChanged({ ...(placement || { type: placementType }), active: !(placement?.active || false) })
            }
          />
        </div>
      </div>
      {insertImageOpen && (
        <InsertImageDialog
          website={selectedWebsite}
          selectedImageFile={placement?.imageFile || null}
          handleInsertClick={(file: IFile) => {
            onPlacementChanged({ ...(placement || { type: placementType }), imageFile: file });
            setInsertImageOpen(false);
          }}
          handleCloseClick={(e) => setInsertImageOpen(false)}
        />
      )}
    </ListItem>
  );
};

export default AdPlacementListItem;
