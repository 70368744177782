import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  textFields: {
    width: "100%",
    maxWidth: 661,
    border: "none",
    borderRadius: 40,
    "& > *": {
      minHeight: 42,
      borderRadius: 40,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium as any,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 20px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(25px, 14px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  errorsWrapper: {
    marginTop: 15,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  errorText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight as any,
    lineHeight: "normal",
    letterSpacing: "0.14px",
  },
  contactLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));

type PropTypes = {
  type?: string;
  value: string;
  onChange: (string) => void;
  invalidUrl?: boolean;
  existingWebsite?: boolean;
  websiteName?: string;
  className?: string;
  onContactUsClick: () => void;
};

const WebsiteUrlTextField = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    type = "text",
    value,
    onChange,
    existingWebsite,
    websiteName,
    invalidUrl,
    className,
    onContactUsClick,
  } = props;
  const getErrorMessage = () => {
    if (invalidUrl) {
      return I18n.t("signup.personalize.website_url_error");
    }
    if (existingWebsite) {
      return (
        <div className={classes.errorsWrapper}>
          <Typography
            className={classes.errorText}
            style={{
              color: "#F04444",
            }}
          >
            {I18n.t("signup.personalize.website_exist", { name: websiteName })}
          </Typography>
          <Typography className={classes.errorText}>
            {I18n.t("signup.personalize.website_exist_description")}
          </Typography>
          <Typography className={classes.errorText}>
            {I18n.t("signup.personalize.website_exist_contact_message")}
            <span>
              <a
                onClick={() => onContactUsClick()}
                className={classes.contactLink}
                href={"https://entail.ai/contact-us"}
                target={"_blank"}
              >
                {I18n.t("signup.personalize.website_exist_contact_message_link")}
              </a>
              .
            </span>
          </Typography>
        </div>
      );
    }
    return "";
  };

  return (
    <TextField
      id="outlined-required"
      error={existingWebsite || invalidUrl}
      value={value}
      type={type}
      className={ClassNames(classes.textFields, className)}
      placeholder={I18n.t("signup.personalize.website_url_ph")}
      helperText={getErrorMessage()}
      onChange={(event) => onChange(event.target.value)}
      variant="outlined"
    />
  );
};

export default WebsiteUrlTextField;
