import numeral from "numeral";
import _ from "lodash";

export const displayRangeInputLabels = (
  label: string,
  defaultLabel: string,
  min?: number | null,
  max?: number | null
) => {
  return !_.isNil(min) || !_.isNil(max)
    ? `${label} ${!_.isNil(min) ? numeral(min).format("0,0a") : ""} - ${
        !_.isNil(max) ? numeral(max).format("0,0a") : ""
      }`
    : defaultLabel;
};
