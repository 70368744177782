import React from "react";
import { Button, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import numeral from "numeral";
import Classnames from "classnames";
import { I18n } from "react-redux-i18n";
import { Add } from "@material-ui/icons";

import { useVideoEditorContext } from "../../../VideoEditorContext";
import { formatDate } from "../../../utils";
import UploadNewVideo from "./UploadNewVideo";
import { colors } from "../../../../../helpers/constants";
import { IVideoProject } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  addButton: {
    width: "100%",
    padding: 25,
    gap: 4,
    backgroundColor: colors.widgetOnHoverBackgroundColor,
    cursor: "pointer",
    transition: "background-color 0.2s",
    textTransform: "none",
    borderRadius: 0,
    marginBottom: 20,
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: palette.primary.main,
    },
    "&:hover": {
      backgroundColor: colors.widgetOnHoverBackgroundColor,
    },
    "&:last-child": {
      borderRadius: "0 3px 3px 0",
    },
  },
  icon: {
    color: palette.primary.main,
    width: 25,
    height: 25,
  },
  input: {
    display: "none",
  },
  itemsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  item: {
    display: "flex",
    padding: "0px 10px 20px 10px",
    alignItems: "center",
    gap: "20px",
    alignSelf: "stretch",
    cursor: "pointer",
    borderBottom: `1px solid ${palette.divider}`,
  },
  itemLeftSide: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    flexGrow: 1,
  },
  itemRightSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& $itemText": {
      fontSize: 14,
    },
  },
  itemTitle: {
    fontSize: 14,
    fontWeight: typography.fontWeightBold as any,
    lineHeight: "100%",
  },
  itemText: {
    fontSize: 12,
    color: palette.grey[500],
  },
  selectedItem: () => ({
    color: palette.primary.main,
  }),
}));

const FilesTab = () => {
  const classes = useStyles();
  const { videoProject, selectedTimeline, editVideoProject, setSelectedTimeline } = useVideoEditorContext();

  const createEmptyTimeline = () => {
    const emptyTimeline = {
      selected: true,
      rows: [
        {
          type: "comment",
          actions: [],
        },
        {
          type: "video",
          actions: [],
        },
      ],
    };

    const newVideoProject = {
      ...videoProject,
      timelines: [...videoProject.timelines.map((t) => ({ ...t, selected: false })), emptyTimeline],
    } as IVideoProject;

    editVideoProject(newVideoProject);
  };

  if (!selectedTimeline) {
    return null;
  }

  return (
    <div>
      {/* <UploadNewVideo /> */}
      <Button className={classes.addButton} onClick={createEmptyTimeline}>
        <Add className={classes.icon} />
        {I18n.t("video_workflow.video_tabs.files.create_new_version")}
      </Button>
      <div className={classes.itemsWrapper}>
        {[...videoProject.timelines].reverse().map((p, index, array) => (
          <div className={classes.item} onClick={() => setSelectedTimeline(array.length - index - 1)} key={index}>
            <div className={classes.itemLeftSide}>
              <Typography
                variant="body1"
                className={Classnames(classes.itemTitle, p.selected && classes.selectedItem)}
              >{`Version ${array.length - index}`}</Typography>
              <Typography variant="body1" className={classes.itemText}>
                {/* {numeral(p.file.size).format("0.0 b")} */}
              </Typography>
              {p.updatedAt ? (
                <Typography variant="body1" className={classes.itemText}>
                  {`${I18n.t("video_workflow.video_tabs.files.changed")}: ${formatDate(p.updatedAt)}`}
                </Typography>
              ) : (
                <Typography variant="body1" className={classes.itemText}>
                  {p.createdAt
                    ? `${I18n.t("video_workflow.video_tabs.files.created")}: ${formatDate(p.createdAt)}`
                    : I18n.t("video_workflow.video_tabs.files.not_saved")}
                </Typography>
              )}
            </div>
            <div className={classes.itemRightSide}>
              <Typography
                variant="body1"
                className={Classnames(classes.itemText, p._id === selectedTimeline._id && classes.selectedItem)}
              >
                {p.selected ? "Current" : "Select"}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilesTab;
