import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  className?: string;
};

function ImageIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 32 32"}>
      <path d="M32.000,28.444 L32.000,3.556 C32.000,1.600 30.400,-0.000 28.444,-0.000 L3.556,-0.000 C1.600,-0.000 -0.000,1.600 -0.000,3.556 L-0.000,28.444 C-0.000,30.400 1.600,32.000 3.556,32.000 L28.444,32.000 C30.400,32.000 32.000,30.400 32.000,28.444 ZM10.489,19.520 L14.222,24.018 L19.733,16.924 C20.089,16.462 20.800,16.462 21.155,16.942 L27.396,25.262 C27.840,25.849 27.413,26.684 26.685,26.684 L5.369,26.684 C4.622,26.684 4.213,25.831 4.675,25.244 L9.102,19.556 C9.440,19.093 10.116,19.076 10.489,19.520 Z" />
    </SvgIcon>
  );
}

export default ImageIcon;
