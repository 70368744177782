// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { isEqual, round } from "lodash";

import videoHelper from "../../../../../../helpers/videoHelper";
import { useVideoEditorContext } from "../../../../VideoEditorContext";
import VideoProjectFileUploaderHelper from "../../../../../../helpers/VideoProjectFileUploaderHelper";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  item: {
    border: `1px solid ${palette.divider}`,
    height: 80,
    width: 160,
    position: "relative",
  },
  previewImage: ({ progress }: { progress: number }) => ({
    width: "inherit",
    height: "inherit",
    objectFit: "cover",
    filter: `brightness(${progress}%)`,
  }),
  progress: {
    position: "absolute",
    bottom: 2,
    color: "white",
    fontWeight: typography.fontWeightBold as any,
    textAlign: "center",
    width: "100%",
  },
}));

type PropTypes = {
  file: File;
};

const UploadFileItem = (props: PropTypes) => {
  const { file } = props;
  const { selectedWebsite, videoProject, pendingUploads, setPendingUploads, editVideoProject } =
    useVideoEditorContext();
  const [isUploading, setIsUploading] = useState(true);
  const [blobUrl, setBlobUrl] = useState(null);

  const [uploader, setUploader] = useState<any>(undefined);
  const [progress, setProgress] = useState(0);
  const [showAbort, setShowAbort] = useState<boolean>(false);
  const [error, setError] = useState<Error>(null);

  const classes = useStyles({ progress });

  React.useEffect(() => {
    videoHelper
      .getVideoCoverAndDuration({
        type: "file",
        file,
        seekTo: 5,
      })
      .then((res: { blob: Blob; duration: number }) => {
        setBlobUrl(URL.createObjectURL(res.blob));
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        uploadFile({ duration: round(res.duration, 2) });
      })
      .catch((e) => {
        console.error(e);
        return null;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  function uploadFile(extraParams?: { duration: number }) {
    if (file) {
      let percentage = 0;

      const videoUploaderOptions = {
        fileName: file.name,
        file,
        ...extraParams,
      };

      const uploader = new VideoProjectFileUploaderHelper(selectedWebsite, videoProject, videoUploaderOptions);
      setUploader(uploader);

      uploader
        .onProgress(({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            setProgress(percentage);
            console.log("percentage", `${percentage}%`);
          }
        })
        .onError((error: any) => {
          setError(error);
          console.error(error);
        })
        .onSuccess(({ sourceFile }) => {
          console.log("sourceFile created", sourceFile);
          editVideoProject({
            ...videoProject,
            videos: [...videoProject.videos, sourceFile],
          });
          setPendingUploads({
            ...pendingUploads,
            videos: pendingUploads.videos.filter((v) => !isEqual(v, file)),
          });
        });

      uploader.start();
    }
  }

  const cancelUpload = () => {
    if (uploader) {
      uploader.abort();
      // setFile(undefined);
    }
  };

  return (
    <div className={classes.item}>
      <img className={classes.previewImage} src={blobUrl} alt={"fff"} />
      <Typography className={classes.progress} color={"textPrimary"} variant={"body2"}>
        {`${progress}%`}
      </Typography>
    </div>
  );
};

export default UploadFileItem;
