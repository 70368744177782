import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import {
  IWidgetFieldProperties,
  IWidgetButtonFieldProps,
  IProduct,
  IWebsite,
} from "../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "./components/GenericValueItemProperty";
import { Theme, Typography } from "@material-ui/core";
import MSelect from "../../../../../../../components/MSelect";
import DialogWidgetPicker from "./components/DialogWidgetPicker";
import MSwitch from "../../../../../../../components/MSwitch";
import MDynamicListItem from "../../../../../../../components/MDynamicListItem";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  groupedItems: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  switchItemsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 3,
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "normal",
    fontWeight: theme.typography.fontWeightMedium as any,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  properties: IWidgetFieldProperties;
  fieldType: string;
  product: IProduct;
  onChange: (properties: IWidgetButtonFieldProps) => void;
};

const ProductButtonWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, product, onChange } = props;
  const properties = props.properties as IWidgetButtonFieldProps;
  const {
    title = "",
    href = "",
    openAction = properties?.href || !product ? "custom" : "call_to_action",
    dialogId = "",
    showArrow = false,
    openInNewTab,
    includeQueryParams,
  } = properties;

  const theme = useTheme<Theme>();

  const buttonOpenActionOptions = [
    {
      label: I18n.t("widgets_builder.widget_editor.fields.button_open_options.primary_cta"),
      value: "call_to_action",
      disabled: !product,
    },
    {
      label: I18n.t("widgets_builder.widget_editor.fields.button_open_options.secondary_cta"),
      value: "product",
      disabled: !product,
    },
    { label: I18n.t("widgets_builder.widget_editor.fields.button_open_options.custom"), value: "custom" },
    { label: I18n.t("widgets_builder.widget_editor.fields.button_open_options.popup"), value: "dialog" },
  ];

  const handleOpenActionChange = ({ value: openAction }) => {
    const newProperties = { ...properties, openAction };
    if (openAction === "call_to_action") {
      newProperties.title = product.callToAction;
      newProperties.href = product.callToActionURL;
    }

    if (openAction === "product") {
      newProperties.title = product.linkText || product.callToAction;
      newProperties.href = product.link;
    }

    onChange(newProperties);
  };

  return (
    <div className={classes.root}>
      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, title: value })}
        value={title}
        label={I18n.t("widgets_builder.widget_editor.fields.labels.button_text")}
        labelOutsideInput
      />

      <div className={classes.groupedItems}>
        <Typography variant="body1" className={classes.label}>
          {I18n.t("widgets_builder.widget_editor.fields.labels.open_action_button")}
        </Typography>

        <MSelect
          customClass={classes.selectCustomClass}
          valuePadding={"2px 10px 2px 5px"}
          options={buttonOpenActionOptions}
          value={buttonOpenActionOptions.find((option) => option.value === openAction)}
          optionLabel={"label"}
          optionValue={"value"}
          height={50}
          borderWidth={"0px"}
          isBold={false}
          textColor={theme.palette.text.primary}
          menuPlacement={"bottom"}
          singleValueStyles={{ fontSize: theme.typography.pxToRem(14) }}
          fullWidth
          getDisplayLabel={(vertical) => `${vertical.label}`}
          handleChange={handleOpenActionChange}
        />
      </div>
      {openAction !== "dialog" ? (
        <GenericValueItemProperty
          onChange={({ value }) => onChange({ ...properties, href: value })}
          value={href}
          label={I18n.t("widgets_builder.widget_editor.fields.labels.url")}
          disabled={openAction === "product" || openAction === "call_to_action"}
          labelOutsideInput
        />
      ) : (
        <DialogWidgetPicker
          selectedWebsite={selectedWebsite}
          uploadedId={dialogId}
          handleChange={(dialogId) => onChange({ ...properties, dialogId })}
        />
      )}
      <div className={classes.groupedItems}>
        <Typography variant="body1" className={classes.label}>
          {I18n.t("widgets_builder.widget_editor.fields.labels.behavior")}
        </Typography>

        <div className={classes.switchItemsWrapper}>
          <MDynamicListItem
            title={I18n.t("widgets_builder.widget_editor.fields.labels.show_arrow")}
            noArrow
            onClick={() => onChange({ ...properties, showArrow: !showArrow })}
            rightIconsComponent={
              <MSwitch
                checked={showArrow}
                onChange={(_, e) => e.preventDefault()}
                onClick={(e) => e.preventDefault()}
              />
            }
          />

          {openAction !== "dialog" && (
            <>
              <MDynamicListItem
                title={I18n.t(`widgets_builder.widget_editor.fields.labels.open_in_new_tab`)}
                noArrow
                onClick={() => onChange({ ...properties, openInNewTab: !openInNewTab })}
                rightIconsComponent={
                  <MSwitch
                    checked={openInNewTab}
                    onChange={(_, e) => e.preventDefault()}
                    onClick={(e) => e.preventDefault()}
                  />
                }
              />

              <MDynamicListItem
                title={I18n.t(`widgets_builder.widget_editor.fields.labels.include_query_params`)}
                noArrow
                onClick={() => onChange({ ...properties, includeQueryParams: !includeQueryParams })}
                rightIconsComponent={
                  <MSwitch
                    checked={includeQueryParams}
                    onChange={(_, e) => e.preventDefault()}
                    onClick={(e) => e.preventDefault()}
                  />
                }
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductButtonWidgetItem;
