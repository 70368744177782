import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  iFrame: {
    border: "none",
  },
}));

type PropTypes = {
  src: string;
  iframeClass: string;
};

const MIFrame = (props: PropTypes) => {
  const classes = useStyles(props);
  const { src, iframeClass } = props;

  return <iframe className={ClassNames(classes.iFrame, iframeClass)} src={src} />;
};

export default MIFrame;
