import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import {
  IWidgetFieldProperties,
  IWidgetProductReviewsNumberFieldProps,
} from "../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "./components/GenericValueItemProperty";

type PropTypes = {
  properties: IWidgetFieldProperties;
  fieldType: string;
  onChange: (properties: IWidgetProductReviewsNumberFieldProps) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

const ProductReviewsNumberWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);

  const { onChange } = props;
  const { fieldType } = props;
  const properties = props.properties as IWidgetProductReviewsNumberFieldProps;
  const { value = "", labelText = "reviews" } = properties;

  return (
    <div className={classes.root}>
      <GenericValueItemProperty
        onChange={({ value: newValue }) => onChange({ ...properties, value: newValue })}
        value={value}
        label={I18n.t(`widgets_builder.widget_item_selector.${fieldType}`)}
        numbersOnly
        noDecimals
      />

      <GenericValueItemProperty
        onChange={({ value: newValue }) => onChange({ ...properties, labelText: newValue })}
        value={labelText}
        label={I18n.t(`widgets_builder.widget_item_selector.reviews_label_text`)}
        labelOutsideInput
      />
    </div>
  );
};

export default ProductReviewsNumberWidgetItem;
