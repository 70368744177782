import React from "react";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { Theme, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { cloneDeep, findIndex, round } from "lodash";

import { useVideoEditorContext } from "../VideoEditorContext";
import { eVideoProjectTool } from "../../../reducers/constants/objectTypes";
import CutIcon from "../../../icons/CutIcon";
import DeleteIcon from "../../../icons/DeleteIcon";

const useStyles = makeStyles((theme: Theme) => ({
  controlBarWrapper: {
    width: "100%",
    padding: 20,
    backgroundColor: "#20222B",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 20,
  },
  buttonsWrapper: {
    display: "flex",
    gap: 30,
    "&:first-child": {
      paddingLeft: 5,
    },
  },
  button: {
    color: "#fff",
    padding: 0,
    minWidth: 0,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  timer: {
    fontSize: 20,
    color: "#fff",
  },
  icon: {
    color: "#fff",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

function TimelineControlBar() {
  const classes = useStyles();

  const {
    selectedTimeline,
    timelineState,
    timelineRef,
    videoPlayerRef,
    selectedActionId,
    selectedTool,
    editVideoEditorTimelineProperty,
    editTimelineRows,
  } = useVideoEditorContext();
  const { time, zoom } = timelineState;
  const timelineRows = React.useMemo(() => selectedTimeline.rows, [selectedTimeline.rows]);

  const timelineTime = React.useMemo(() => round(time, 2), [time]);

  const cut = () => {
    console.log("cut", time);
    if (timelineTime > 0) {
      timelineRef.current?.pause();
      const row = cloneDeep(timelineRows.find((r) => r.type === "video"));
      const actionIndex = findIndex(row.actions, (a) => a.start <= timelineTime && timelineTime <= a.end);

      console.log("cut actionIndex vl", actionIndex, row.actions);

      if (actionIndex === -1) {
        return;
      }

      const cutPointExists = row.actions.some((a) => a.start === timelineTime || a.end === timelineTime);
      if (cutPointExists) {
        console.log("cutPoint exists", row.actions);
        return;
      }

      const currentPlayerTime = videoPlayerRef.current?.currentTime;

      const duplicatedAction = { ...row.actions[actionIndex], start: timelineTime, trimStart: currentPlayerTime };
      row.actions[actionIndex].end = timelineTime;
      row.actions[actionIndex].trimEnd = currentPlayerTime;

      row.actions.splice(actionIndex + 1, 0, duplicatedAction);
      console.log("before end vl", row.actions);

      row.actions = row.actions.map((a, index) => ({
        ...a,
        id: `video-${index}`,
        start: Math.min(row.actions?.[index - 1]?.end || 0, a.start),
        end: Math.min(row.actions?.[index + 1]?.start || a.end, a.end),
        minStart: round((row.actions?.[index - 1]?.end || 0) - a.trimStart, 2),
        maxEnd: round(a.end + (a.video.duration - a.trimEnd), 2),
      }));
      console.log("end vl", row.actions);
      const newTimelineRows = timelineRows.map((r) => (r.type === "video" ? row : r));
      editTimelineRows(newTimelineRows);
    }
  };

  const deleteAction = () => {
    if (selectedActionId === null) {
      return;
    }

    timelineRef.current?.pause();
    console.log("deleteAction", selectedActionId);
    const row = cloneDeep(timelineRows.find((r) => r.type === "video"));
    const actionIndex = findIndex(row.actions, (a) => a.id === selectedActionId);
    console.log("deleteAction vl", row.actions, actionIndex);
    if (actionIndex === -1) {
      return;
    }
    const deletedDuration = round(row.actions[actionIndex].end - row.actions[actionIndex].start, 2);
    row.actions = row.actions
      .map((a, index) => {
        if (index > actionIndex) {
          return {
            ...a,
            start: index === 1 ? 0 : Math.max(round(a.start - deletedDuration, 2), 0),
            end: Math.max(round(a.end - deletedDuration, 2), 0),
            minStart: round(a.minStart - deletedDuration, 2),
            maxEnd: a.video.duration - a.trimEnd,
          };
        }
        return a;
      })
      .filter((_, i) => i !== actionIndex);

    console.log("deleteAction vl after", row.actions, actionIndex);
    const newTimelineRows = timelineRows.map((r) => (r.type === "video" ? row : r));
    editTimelineRows(newTimelineRows);
  };

  return (
    <div className={classes.controlBarWrapper}>
      <div className={classes.buttonsWrapper}>
        {selectedTool === eVideoProjectTool.TRIM && (
          <>
            <Button
              className={classes.button}
              disableRipple
              onClick={() => {
                console.log("cut", time);
                cut();
              }}
            >
              <CutIcon className={classes.icon} />
            </Button>
            <Button
              className={classes.button}
              disableRipple
              onClick={() => {
                console.log("delete", time);
                deleteAction();
              }}
            >
              <DeleteIcon className={classes.icon} />
            </Button>
          </>
        )}
      </div>
      <div className={classes.buttonsWrapper}>
        <Button
          id="zoomOut"
          className={classes.button}
          disableRipple
          onClick={() => {
            editVideoEditorTimelineProperty({
              zoom: zoom + 1,
            });
          }}
        >
          <ZoomOutIcon />
        </Button>
        <Button
          className={classes.button}
          id="zoomIn"
          disableRipple
          onClick={() => {
            editVideoEditorTimelineProperty({
              zoom: Math.max(1, zoom - 1),
            });
          }}
        >
          <ZoomInIcon />
        </Button>
      </div>
    </div>
  );
}

export default TimelineControlBar;
