import { readUInt } from "image-size/dist/readUInt";
import { COMMUNITY } from "./constants/actionTypes";

const initialState = {
  /// new, draft, published, deleted tabs
  moderatorQuestionsLoaded: false,
  moderatorQuestions: [],
  moderatorQuestionsFilter: {
    searchText: "",
    sortField: {
      direction: "desc",
      field: "updatedAt",
    },
    category: [],
  },
  moderatorAnswers: [],
  moderatorPages: 0,

  newQuestionsLoaded: false,
  newQuestions: [],
  newTotalPages: 0,
  newQuestionsFilter: {
    searchText: "",
    sortField: {
      direction: "desc",
      field: "createdAt",
    },
    category: [],
  },

  draftQuestionsLoaded: false,
  draftQuestions: [],
  draftQuestionsFilter: {
    searchText: "",
    sortField: {
      direction: "desc",
      field: "createdAt",
    },
    category: [],
  },

  publishedQuestionsLoaded: false,
  publishedQuestions: [],
  publishedTotalPages: 0,
  publishedFilter: {
    searchText: "",
    sortField: {
      direction: "desc",
      field: "uploadedTime",
    },
    category: [],
  },

  deletedTotalPages: 0,
  deletedQuestionsLoaded: false,
  deletedQuestions: [],
  deletedQuestionsFilter: {
    searchText: "",
    sortField: {
      direction: "desc",
      field: "createdAt",
    },
    category: [],
  },
  // write tab
  writePublic: [],
  writePersonal: [],
  writeRejected: [],
  writerFilter: {
    sortField: {
      direction: "desc",
      field: "createdAt",
    },
  },

  writeAnswersLoaded: true,
  writeRefreshAnswers: false,
  // write published tab
  writePublished: [],
  writePublishedAnswersLoaded: true,
  writerPublishedFilter: {
    sortField: {
      direction: "desc",
      field: "publishedDate",
    },
  },
  focusedEditorId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COMMUNITY.MODERATOR_QUESTIONS_LOADED:
      return {
        ...state,
        moderatorAnswers: action.payload.answers,
        moderatorQuestions: action.payload.questions,
        moderatorPages: action.payload.pages,
        moderatorQuestionsLoaded: true,
      };
    case COMMUNITY.NEW_QUESTIONS_LOADED:
      return {
        ...state,
        newQuestions: action.payload.questions,
        newTotalPages: action.payload.pages,
        newQuestionsLoaded: true,
      };
    case COMMUNITY.DRAFT_QUESTIONS_LOADED:
      return {
        ...state,
        draftQuestions: action.payload.questions,
        newTotalPages: action.payload.pages,
        draftQuestionsLoaded: true,
      };
    case COMMUNITY.DRAFT_QUESTION_ON_FILTER_CHANGED:
      return {
        ...state,
        draftQuestionsFilter: {
          ...state.draftQuestionsFilter,
          ...action.payload,
        },
      };
    case COMMUNITY.PUBLISHED_QUESTIONS_LOADED:
      return {
        ...state,
        publishedQuestions: action.payload.questions,
        publishedTotalPages: action.payload.pages,
        publishedQuestionsLoaded: true,
      };
    case COMMUNITY.DELETED_QUESTIONS_LOADED:
      return {
        ...state,
        deletedQuestions: action.payload.questions,
        deletedTotalPages: action.payload.pages,
        deletedQuestionsLoaded: true,
      };
    case COMMUNITY.ANSWERS_LOADED:
      return {
        ...state,
        writePublic: action.payload.community.public,
        writePersonal: action.payload.community.personal,
        writeRejected: action.payload.community.rejected,
        writeAnswersLoaded: true,
      };
    case COMMUNITY.ANSWERS_LOADING:
      return {
        ...state,
        writeAnswersLoaded: false,
      };
    case COMMUNITY.ANSWERS_REFRESH:
      return {
        ...state,
        writeRefreshAnswers: !state.writeRefreshAnswers,
      };
    // write published
    case COMMUNITY.PUBLISHED_ANSWERS_LOADING:
      return {
        ...state,
        writePublishedAnswersLoaded: false,
      };
    case COMMUNITY.PUBLISHED_ANSWERS_LOADED:
      return {
        ...state,
        writePublished: action.payload.community,
        writePublishedAnswersLoaded: true,
      };
    case COMMUNITY.NEW_QUESTION_CREATED:
      return {
        ...state,
        newQuestions: [action.question, ...state.newQuestions],
      };
    case COMMUNITY.QUESTION_CHANGED:
      return {
        ...state,
        questionEditorQuestion: action.question,
      };
    case COMMUNITY.SET_FOCUSED_EDITOR_ID:
      return {
        ...state,
        focusedEditorId: action.focusedEditorId,
      };
    case COMMUNITY.ANSWERS_ON_FILTER_CHANGED:
      return {
        ...state,
        writerFilter: {
          ...state.writerFilter,
          ...action.payload,
        },
      };
    case COMMUNITY.PUBLISHED_ANSWERS_ON_FILTER_CHANGED:
      return {
        ...state,
        writerPublishedFilter: {
          ...state.writerPublishedFilter,
          ...action.payload,
        },
      };
    case COMMUNITY.PUBLISHED_ON_FILTER_CHANGED:
      return {
        ...state,
        publishedFilter: {
          ...state.publishedFilter,
          ...action.payload,
        },
      };
    case COMMUNITY.NEW_QUESTION_ON_FILTER_CHANGED:
      return {
        ...state,
        newQuestionsFilter: {
          ...state.newQuestionsFilter,
          ...action.payload,
        },
      };
    case COMMUNITY.MODERATOR_QUESTIONS_ON_FILTER_CHANGED:
      return {
        ...state,
        moderatorQuestionsFilter: {
          ...state.moderatorQuestionsFilter,
          ...action.payload,
        },
      };
    case COMMUNITY.DELETED_QUESTIONS_ON_FILTER_CHANGED:
      return {
        ...state,
        deletedQuestionsFilter: {
          ...state.deletedQuestionsFilter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
