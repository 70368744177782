import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import Search from "@material-ui/icons/Search";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import _ from "lodash";
import { eActionIcon, IWebsite } from "../../../reducers/constants/objectTypes";
import { getOpportunityPageUrl, getPageSearchConsoleUrl, web } from "../../../helpers/urlHelper";
import { CONVERSION_POTENTIAL_FUNNEL_STAGE } from "../../../helpers/constants";
import CopyNameIcon from "../../../icons/CopyNameIcon";
import CopyToClipboardIcon from "../../../icons/CopyToClipboardIcon";
import OpenInEditor from "../../../icons/OpenInEditor";
import MTableRow from "../../../components/Table/Rows/MTableRow";
import MTableCellNumber from "../../../components/Table/Cells/MTableCellNumber";
import MTableCellText from "../../../components/Table/Cells/MTableCellText";
import MTableCellActions from "../../../components/Table/Cells/MTableCellActions";
import MTableCellFirstWithSelect from "../../../components/Table/Cells/MTableCellFirstWithSelect";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $actionsWrapper": {
        display: "flex",
      },
      "& $hideColumn": {
        display: "none",
      },
      "& $avatar": {
        marginBottom: -1,
        display: "none",
      },
      "& $hoverIconWrapper": {
        display: "flex",
        marginBottom: -1,
      },
    },
  },
  rowIcon: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
    transition: "color 0.2s ease-in-out",
  },

  actions: {},

  actionsWrapper: {},

  selected: {
    backgroundColor: theme.palette.action.hover,
    // borderRadius: 30,
  },
  selectedIcon: {
    fill: theme.palette.primary.contrastText,
  },

  iconBtn: {
    padding: 0,
  },
  hoverIconWrapper: {
    height: 25,
    minWidth: 25,
    padding: 0,
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#B780FE",
    borderRadius: "50%",
  },
  hideColumn: {},
  createButton: {
    height: 36,
    // maxWidth: 150,
  },
  createButtonArrowIcon: {
    rotate: "180deg",
    fill: theme.palette.primary.contrastText,
  },
}));

type PropTypes = {
  item: any;
  onClick: (outline: any, pageTab: "keywords" | "broadKeywords") => void;
  redirect: (url: string) => void;
  setSelect(selected: boolean): void;
  selected: boolean;
  selectedWebsite: IWebsite;
  openPost: (pageId: string, currentUrl?: string) => void;
};

const mapDispatchToProps = (dispatch) => ({
  openPost: (pageId, currentUrl) => dispatch(push(web.editPage(pageId, currentUrl))),
});

const OutlinePageListRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { item, onClick, setSelect, selected, openPost } = props;
  const theme = useTheme();
  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return (type) => {
      if (item.page) {
        navigator.clipboard.writeText(type === "page" ? item.page : item.metadata?.title);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();

  const actions = React.useMemo(() => {
    const data = [];
    if (item.metadata?.title)
      data.push({
        text: I18n.t("outline.icons.copy_title"),
        tooltip: I18n.t("outline.icons.copy_title"),
        onClick: () => handleTitleCopy("title"),
        iconName: eActionIcon.copyName,
      });

    data.push({
      text: I18n.t("outline.icons.copy_url"),
      tooltip: I18n.t("outline.icons.copy_url"),
      onClick: () => handleTitleCopy("page"),
      iconName: eActionIcon.copyToClipboard,
    });
    data.push({
      text: I18n.t("outline.icons.open_in_new_tab"),
      tooltip: I18n.t("outline.icons.open_in_new_tab"),
      onClick: () => window.open(item.page, "_blank"),
      iconName: eActionIcon.openInNew,
    });
    if (item.metadata?.entail_page_id)
      data.push({
        text: I18n.t("outline.icons.open_in_editor"),
        tooltip: I18n.t("outline.icons.open_in_editor"),
        onClick: () => openPost(item.metadata?.entail_page_id),
        iconName: eActionIcon.openInEditor,
      });

    data.push({
      text: I18n.t("outline.icons.google_search_console"),
      tooltip: I18n.t("outline.icons.google_search_console"),
      onClick: () => window.open(getPageSearchConsoleUrl(item.domain, item.page)),
      iconName: eActionIcon.search,
    });
    return data;
  }, [item]);

  return (
    <MTableRow
      selected={selected}
      handleRowClick={() => {
        onClick(item, "keywords");
      }}
      customClassName={classNames(classes.itemWrapper, selected && classes.selected)}
    >
      <MTableCellFirstWithSelect
        columnWidth={"40%"}
        fontSize={theme.typography.pxToRem(14)}
        selected={selected}
        onSelect={setSelect}
        avatarType={"A"}
        cellText={item.metadata?.title ? item.metadata.title : getOpportunityPageUrl(item.page, true)}
      />

      <MTableCellNumber value={item.topics} type={"bigNumber"} />
      <MTableCellNumber value={item.impressions} type={"bigNumber"} />
      <MTableCellNumber value={item.clicks} type={"bigNumber"} />
      <MTableCellNumber value={item.ctr} type={"percentage"} />
      <MTableCellNumber value={item.position} type={"decimal"} />
      <MTableCellNumber value={item.volume} type={"bigNumber"} />
      <MTableCellNumber value={item.potential_value} type={"currency"} />
      <MTableCellNumber value={item.value} type={"currency"} />
      <MTableCellNumber value={item.value_delta} type={"currency"} />
      <MTableCellText text={_.capitalize(CONVERSION_POTENTIAL_FUNNEL_STAGE[item.conversion_potential] || "None")} />
      <MTableCellActions actions={actions} customClass={classes.actionsWrapper} />
    </MTableRow>
  );
};

export default connect(null, mapDispatchToProps)(OutlinePageListRow);
