import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { find, findIndex } from "lodash";
import { List } from "@material-ui/core";
import ClassNames from "classnames";
import { ePostType, IPage, IPageCluster, IWebsite } from "../../../../reducers/constants/objectTypes";
import { web } from "../../../../helpers/urlHelper";
import { WORKFLOW_ALL_STATES } from "../../../../reducers/constants/actionTypes";
import PageRow from "./PageRow";
import { PAGE_TYPE_COLORS } from "../../../../reducers/constants/consts";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    display: "block",
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
  },
  listItem: {
    display: "block",
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
  },
  directory: (props: PropTypes) => ({
    "&:after": {
      position: "absolute",
      top: 39,
      bottom: 18,
      left: 32,
      width: 1,
      // height: '100%',
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[props.cluster.type] || "#666",
      zIndex: 1,
    },
  }),
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  cluster: IPageCluster;

  editPost: Function;
  editTopic: Function;
  editCommunityPost: Function;
  refreshPostsList: Function;
  onDateUpdate: Function;
  onPostTypeUpdate: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  editPost: (articleId, currentUrl) => dispatch(push(web.editPage(articleId, currentUrl))),
  editCommunityPost: (postState, postId) => dispatch(push(web.editorCommunity(postState || "published", postId))),
  editTopic: (type: ePostType, topicId, currentUrl) =>
    dispatch(push(web.editTopic(type, topicId, "write", currentUrl))),
  refreshPostsList: () => dispatch({ type: WORKFLOW_ALL_STATES.REFRESH }),
});

const PageClusterRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { cluster, selectedWebsite, onDateUpdate, onPostTypeUpdate } = props;
  const theme: any = useTheme();
  const pages = React.useMemo(() => {
    // console.log("cluster", cluster);
    // console.log("cluster.publishedPages", cluster.publishedPages);
    const publishedPages = Object.keys(cluster.publishedPages || {})
      .map((variant) =>
        // console.log('cluster.publishedPages[variant]', variant, cluster.publishedPages[variant]);
        cluster.publishedPages[variant].map((publishedPage) => find(cluster.pages, (p) => p._id === publishedPage.page))
      )
      .flat(1);
    // console.log("cluster -> publishedPages", publishedPages);

    const otherPages = cluster.pages.filter((p) => !publishedPages.includes(p));
    // console.log("cluster -> otherPages", otherPages);

    const pages = otherPages.reduce((pagesAcc, page) => {
      const index = findIndex(publishedPages, (p: IPage) => p.locale === page.locale && p.variant === page.variant);
      if (index > -1) {
        publishedPages[index].children = [...(publishedPages[index].children || []), page];
      } else {
        pagesAcc.push(page);
      }
      return pagesAcc;
    }, []);

    // console.log("cluster -> pages", pages);

    // console.log(
    //   "pages",
    //   pages.map((p) => p.title || "missing title")
    // );
    publishedPages.push(...(pages || []));

    publishedPages.sort((a, b) => (a.locale === selectedWebsite.defaultLocale ? -1 : 1));

    // console.log(`publishedPages ${cluster._id}`, publishedPages);
    return publishedPages;
  }, [selectedWebsite, cluster]);

  // console.log("pages", pages);
  const totalPages = pages.length;

  if (pages.length === 1) {
    return (
      <ListItem className={classes.listItem}>
        <PageRow
          onDateUpdate={onDateUpdate}
          onPostTypeUpdate={onPostTypeUpdate}
          key={pages[0]._id}
          page={pages[0]}
          main
          showBorder={!pages[0].children}
        />
        {pages[0].children && pages[0].children.length > 0 && (
          <List className={classes.itemWrapper}>
            {pages[0].children.map((childPage, childIndex) => (
              <PageRow
                onDateUpdate={onDateUpdate}
                onPostTypeUpdate={onPostTypeUpdate}
                key={childPage._id}
                page={childPage}
                main={false}
                child
                showBorder={childIndex === pages[0].children.length - 1}
              />
            ))}
          </List>
        )}
      </ListItem>
    );
  }

  return (
    <ListItem className={ClassNames(classes.listItem, classes.directory)}>
      {pages.map((page, index) => (
        <>
          {page && (
            <PageRow
              onDateUpdate={onDateUpdate}
              onPostTypeUpdate={onPostTypeUpdate}
              key={page._id}
              page={page}
              main={index === 0}
              showBorder={index === totalPages - 1 && !page.children}
            />
          )}
          {page.children && page.children.length > 0 && (
            <List className={classes.itemWrapper}>
              {page.children.map((childPage, childIndex) => (
                <ListItem className={classes.listItem}>
                  <PageRow
                    onDateUpdate={onDateUpdate}
                    onPostTypeUpdate={onPostTypeUpdate}
                    key={childPage._id}
                    page={childPage}
                    main={false}
                    child
                    showBorder={index === totalPages - 1 && childIndex === page.children.length - 1}
                  />
                </ListItem>
              ))}
            </List>
          )}
          {!page && <span>{"Error"}</span>}
        </>
      ))}
    </ListItem>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageClusterRow);
