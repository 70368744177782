import * as React from "react";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { Button, TextField } from "@material-ui/core";
import ModalsCloseButton from "../../../../Modals/components/ModalsCloseButton";
import MTextArea from "../../../../../components/MTextArea";
import agent from "../../../../../agent";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    height: 80,
    fontWeight: theme.typography.fontWeightBold as any,
    paddingTop: 40,
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 30px 30px 30px",
    height: "100%",
  },
  dialog: {
    height: "474px",
    width: "572px",
  },
  footer: {
    height: 66,
    minHeight: 80,
    borderTop: `${theme.palette.divider} solid 1px`,
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonAdd: {
    borderRadius: "45px",
    padding: "10px 30px",
    background: theme.palette.primary.main,
    color: "#FFFFFF",
    fontSize: "14px",
    "&:hover": {
      background: theme.palette.primary.light,
      color: theme.palette.background.default,
    },
    textTransform: "none",
  },
  titleOne: {
    fontSize: "18px",
  },
  titleTwo: {
    marginTop: 5,
    fontSize: "14px",
  },
  modalcloseicon: {
    marginTop: 5,
  },
  input: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: 4,
    padding: "10px 15px 10px 15px",
    height: "auto",
    width: "100%",
  },
  textareainput: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: 4,
    padding: "10px 15px 10px 15px",
    height: "66px",
    width: "100%",
  },
  headInput: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 10,
  },
  inputWrapper: {
    marginTop: 30,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  handleCloseClick?: () => void;
  handleAddedNewKeywordType?: () => void;
  customClass?: string;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

const KeywordTypesAddNewKeywordDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, handleCloseClick, handleAddedNewKeywordType, customClass = "" } = props;
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  function addKeyword() {
    if (name.trim() === "" || description.trim() === "") {
      enqueueSnackbar(I18n.t("strategy.settings.complete_all_fields"));
      return;
    }

    const newKeywordType = {
      name,
      description,
      websites: [selectedWebsite],
    };

    agent.KeywordTypes.createKeywordType(newKeywordType)
      .then((res) => {
        if (res === null || res === undefined) {
          enqueueSnackbar(I18n.t("strategy.settings.something_went_wrong"));
        } else {
          enqueueSnackbar(I18n.t("strategy.settings.new_keyword_type_added"));
          handleAddedNewKeywordType();
        }
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  }

  return (
    <Dialog
      open
      fullWidth={false}
      maxWidth={"lg"}
      onClose={(event) => handleCloseClick()}
      classes={{
        paper: ClassNames(classes.dialog, customClass),
      }}
    >
      <ModalsCloseButton customClass={classes.modalcloseicon} onClick={(event) => handleCloseClick()} />
      <DialogTitle className={classes.titleWrapper}>
        <Typography className={classes.titleOne} variant={"subtitle2"}>
          {I18n.t("strategy.settings.create_classification_type")}
        </Typography>
        <Typography className={classes.titleTwo}>
          {I18n.t("strategy.settings.create_new_type_write_clear_description")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.inputWrapper}>
          <div className={classes.headInput}>
            <Typography variant={"subtitle2"}>{I18n.t("strategy.settings.name")}</Typography>
            <Typography variant={"subtitle2"}>{name.length}/60</Typography>
          </div>

          <TextField
            className={classes.input}
            placeholder={I18n.t("strategy.settings.type_name")}
            InputProps={{
              disableUnderline: true,
            }}
            onChange={(event) => {
              if (event.target.value.length <= 60) setName(event.target.value);
            }}
          />
        </div>

        <div className={classes.inputWrapper}>
          <div className={classes.headInput}>
            <Typography variant={"subtitle2"}>{I18n.t("strategy.settings.description")}</Typography>
            <Typography variant={"subtitle2"}>{description.length}/200</Typography>
          </div>

          <MTextArea
            removeHeight
            className={classes.textareainput}
            maxRows={10}
            onChange={(value) => {
              if (value.length <= 200) setDescription(value);
            }}
            placeholder={I18n.t("strategy.settings.type_description")}
            text={description}
          />
        </div>
      </DialogContent>
      <div className={classes.footer}>
        <Button className={classes.buttonAdd} onClick={(event) => addKeyword()}>
          {I18n.t("strategy.settings.create_type")}
        </Button>
      </div>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordTypesAddNewKeywordDialog);
