import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/theme";
import StatisticsList from "./components/StatisticsList";
import StatisticsFilter from "./components/StatisticsFilter";
import agent from "../../../agent";
import { IWebsite } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles(() => ({
  statsWrapper: {
    display: "flex",
    flexBasis: 0,
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    margin: "25px 20px 0 20px",
    padding: 20,
    flexDirection: "column",
  },
}));

type StatisticsType = {
  website: IWebsite;
  total: {
    [key: string]: number;
  };
};

const Statistics = () => {
  const classes = useStyles();

  const [timePeriod, setTimePeriod] = useState<{
    label: string;
    value: {
      startDate: string | null;
      endDate: string | null;
    };
  }>({
    label: "All time",
    value: {
      startDate: null,
      endDate: null,
    },
  });

  const [statistics, setStatistics] = useState<StatisticsType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const { value } = timePeriod;
    const { startDate, endDate } = value;

    setLoading(true);

    agent.Statistics.websitesV2(startDate, endDate)
      .then((res) => {
        setStatistics(res.statistics);
        setLoading(false);
      })
      .catch((e) => e);
  }, [timePeriod]);

  return (
    <div className={classes.statsWrapper}>
      <StatisticsFilter currentPeriod={timePeriod} setPeriod={setTimePeriod} />
      <StatisticsList statistics={statistics} loading={loading} />
    </div>
  );
};

export default Statistics;
