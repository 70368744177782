import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Allotment } from "allotment";
import { Dialog, Grow, Theme } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { TransitionProps } from "@material-ui/core/transitions";
import { I18n } from "react-redux-i18n";
import "allotment/dist/style.css";

import { colors } from "../../../helpers/constants";
import WidgetPickerHeader from "./components/WidgetPickerHeader";
import WidgetPickerLeftPane from "./components/WidgetPickerLeftPane/WidgetPickerLeftPane";
import { HOME, WIDGET_PICKER } from "../../../reducers/constants/actionTypes";
import WidgetPickerPreview from "./components/WidgetPickerPreview";
import { IWidgetPickerState } from "../../../reducers/widgetPickerReducer";
import { IWebsite, IWidget, eWidgetStatus, eEntailProducts } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import FormWidgetEditor from "../../CRO/pages/WidgetsBuilder/FormWidgetEditor/FormWidgetEditor";
import WidgetPickerPreviewBottom from "./components/WidgetPickerPreviewBottom";
import { MIXPANEL_PAGE_VIEW } from "../../../reducers/middlewares/mixpanelMiddleware";

const useStyles = makeStyles((theme: Theme) => ({
  editorWrapper: {
    backgroundColor: colors.pageEditorBackgroundColor,
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  componentsWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 50px)",
  },
  allotmentMain: {
    backgroundColor: "#E4EAF5",
    paddingTop: 10,
  },
  allotmentLeft: {
    backgroundColor: "#F9FBFD",
    height: "calc(100vh - 65px) !important",
  },
  allotmentRight: {
    border: 0,
    backgroundColor: "#fff",
    left: "470px !important",
    minWidth: 0,
    width: "calc(100% - 470px) !important",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
}));

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Grow ref={ref} {...props} />
  )
);

type PropTypes = {
  selectedWidget: IWidget;
  latestSavedWidget: IWidget;
  selectedWebsite: IWebsite;
  defaultWidget?: IWidget;
  mounted?: boolean;
  includeDialogs?: boolean;
  addWidgetMode?: boolean;
  editWidgetMode?: boolean;
  mount: (payload: Partial<IWidgetPickerState>) => void;
  unmount: () => void;
  handleClose: () => void;
  handleWidgetInsert?: (widget: IWidget) => void;
  handleWidgetCreate?: (widget: IWidget) => void;
  handlePropertyChange: (property: Partial<IWidgetPickerState>) => void;
  pageLoaded: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  latestSavedWidget: state.widgetBuilder.latestSavedWidget,
  selectedWidget: state.widgetPicker.selectedWidget,
  mounted: state.widgetPicker.mounted,
  editWidgetMode: state.widgetPicker.editWidgetMode,
});

const mapDispatchToProps = (dispatch) => ({
  mount: (payload: Partial<IWidgetPickerState>) => dispatch({ type: WIDGET_PICKER.MOUNT, payload }),
  unmount: () => {
    dispatch({
      type: WIDGET_PICKER.UNMOUNT,
    });
  },
  handlePropertyChange: (property: Partial<IWidgetPickerState>) =>
    dispatch({ type: WIDGET_PICKER.PROPERTY_CHANGE, property }),
  pageLoaded: () =>
    dispatch({
      type: HOME.PAGE_LOADED,
      meta: {
        mixpanel: { event: MIXPANEL_PAGE_VIEW, props: { "page-name": "Widget picker", product: eEntailProducts.cro } },
      },
    }),
});

const WidgetPicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    latestSavedWidget,
    selectedWidget,
    defaultWidget,
    mounted,
    includeDialogs,
    editWidgetMode,
    addWidgetMode,
    pageLoaded,
  } = props;
  const latestSavedWidgetRef = React.useRef<IWidget>(selectedWidget);
  const { mount, unmount, handlePropertyChange, handleWidgetInsert, handleWidgetCreate, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    pageLoaded();
    return () => {
      unmount();
    };
  }, []);

  React.useEffect(() => {
    if (mounted) return;
    mount({ includeDialogs, addWidgetMode });
  }, [mounted, includeDialogs, addWidgetMode]);

  React.useEffect(() => {
    if (!latestSavedWidget) {
      return;
    }

    latestSavedWidgetRef.current = latestSavedWidget;
    handlePropertyChange({
      selectedWidget: latestSavedWidget,
      editWidgetMode: latestSavedWidget.status !== eWidgetStatus.Deleted,
    });
  }, [latestSavedWidget]);

  const handleTemplateSelect = () =>
    agent.Widgets.newWidgetFromTemplate(selectedWebsite._id, selectedWidget)
      .then((res) => {
        if (res.remoteResponse?.error) {
          enqueueSnackbar(I18n.t("widgets_builder.actions.widget_publish_fail"));
          enqueueSnackbar(I18n.t("widgets_builder.actions.widget_created"));
        }
        if (handleWidgetCreate) {
          handleWidgetCreate(res.widget);
        }
        handlePropertyChange({ selectedWidget: res.widget, editWidgetMode: true });
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });

  if (!mounted) return null;

  return (
    <>
      {editWidgetMode && (
        <FormWidgetEditor
          extraButtonActions={
            handleWidgetInsert &&
            latestSavedWidgetRef.current?.status === eWidgetStatus.Active &&
            latestSavedWidgetRef.current?.uploadedId
              ? [
                  {
                    action: "Insert",
                    label: I18n.t("widget_picker.bottom.insert_widget"),
                    onClick: () => handleWidgetInsert(latestSavedWidgetRef.current),
                  },
                ]
              : []
          }
          widgetId={selectedWidget._id}
          handleClose={() => handlePropertyChange({ editWidgetMode: false })}
        />
      )}

      <Dialog
        className={editWidgetMode ? classes.hide : classes.show}
        fullScreen
        open={true}
        transitionDuration={0}
        disableEnforceFocus
      >
        <div className={classes.editorWrapper}>
          <WidgetPickerHeader handleBackClick={handleClose} />
          <div className={classes.componentsWrapper}>
            <Allotment className={classes.allotmentMain}>
              <Allotment.Pane preferredSize={460} minSize={460} maxSize={460} className={classes.allotmentLeft}>
                <WidgetPickerLeftPane defaultWidget={defaultWidget} />
              </Allotment.Pane>
              <Allotment.Pane className={classes.allotmentRight}>
                <WidgetPickerPreview />
                <WidgetPickerPreviewBottom
                  selectedWidget={selectedWidget}
                  handleWidgetInsertClick={handleWidgetInsert ? () => handleWidgetInsert(selectedWidget) : null}
                  handleWidgetEditClick={() => handlePropertyChange({ editWidgetMode: true })}
                  handleTemplateSelectClick={handleTemplateSelect}
                />
              </Allotment.Pane>
            </Allotment>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetPicker);
