import React, { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import { RowSelectionState } from "@tanstack/react-table";

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

function triggerTooltip(chart: ChartJS | null) {
  const tooltip = chart?.tooltip;

  if (!tooltip) {
    return;
  }

  if (tooltip.getActiveElements().length > 0) {
    tooltip.setActiveElements([], { x: 0, y: 0 });
  } else {
    const { chartArea } = chart;

    tooltip.setActiveElements(
      [
        {
          datasetIndex: 0,
          index: 2,
        },
        {
          datasetIndex: 1,
          index: 2,
        },
      ],
      {
        x: (chartArea.left + chartArea.right) / 2,
        y: (chartArea.top + chartArea.bottom) / 2,
      }
    );
  }

  chart.update();
}

type PropTypes = {
  height: number;
  reportLoaded: boolean;
  seriesHeaders: any[];
  colors: object;
  data: any;
  rowSelection: RowSelectionState;

  reportType: "website" | "page" | "allPages" | "event";
};

const mapStateToProps = (state) => ({
  reportLoaded: state.insights.reportLoaded,
  seriesHeaders: state.insights.seriesHeaders,
  data: state.insights.data,
  colors: state.insights.colors,
  rowSelection: state.insights.rowSelection,
  reportType: state.insights.reportType,
});

const mapDispatchToProps = (dispatch) => ({});

const InsightsBarChart = (props: PropTypes) => {
  const { height, reportLoaded, seriesHeaders, data, colors, rowSelection, reportType } = props;
  const chartRef = useRef<ChartJS>(null);
  const [labels, setLabels] = React.useState([]);
  const [datasets, setDatasets] = React.useState([]);

  React.useEffect(() => {
    if (!reportLoaded && reportType === "event") {
      return;
    }
    setLabels(seriesHeaders);
    setDatasets(
      data.map((element, index) => ({
        label: element.name,
        backgroundColor: colors[element.name],
        borderWidth: 2,
        fill: false,
        data: seriesHeaders.map((label) => element.series?.[label] || 0),
        hidden: !rowSelection[index],
        stack: element.name,
      }))
    );
    console.log("render graph data");
  }, [reportLoaded, seriesHeaders, data, colors, rowSelection]);

  console.log("graph data", height, datasets);
  return (
    <div style={{ height, width: "calc(100vw - 140px)", paddingBottom: 30 }}>
      <Bar
        data={{ labels, datasets }}
        ref={chartRef}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              stacked: true,

              grid: {
                display: false,
              },
            },
            y: { stacked: true },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(InsightsBarChart);
