import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import agent from "../../../agent";
import { useSnackbar } from "notistack";
import {
  eCampaignAction,
  eEntailProducts,
  eMpEvent,
  IAdminUser,
  ICampaign,
  IWebsite,
} from "../../../reducers/constants/objectTypes";
import { web } from "../../../helpers/urlHelper";
import { HOME, WIDGET_RULE_EDITOR } from "../../../reducers/constants/actionTypes";
import { replace } from "connected-react-router";
import { loadHtml } from "../../CRO/pages/Campaigns/CampaignEditor/components/CampaignBuilder/apiHelper";
import { WidgetRuleEditorRootState } from "../../../reducers/widgetRuleEditorReducer";
import CfProgressBar from "../../../components/CfProgressBar";
import CampaignPreview from "../../CRO/pages/Campaigns/CampaignEditor/components/CampaignPreview/CampaignPreview";
import Typography from "@material-ui/core/Typography";
import PreviewEmbedIcon from "../../../icons/PreviewEmbedIcon";
import MButton from "../../../components/MButton";
import { MIXPANEL_PAGE_VIEW } from "../../../reducers/middlewares/mixpanelMiddleware";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
    padding: "30px 50px 0px",
    background: "linear-gradient(90deg, #574AC1 0%, #2C2093 100%)",
    position: "relative",
  },
  campaignWrapper: {},
  emptyPageWrapper: {
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  infoWrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 50,
    left: 30,
    zIndex: 100,
    padding: 30,
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.20)",
    width: 366,
  },
  rightInfoWrapper: {
    right: 30,
    left: "unset",
  },
  infoTitle: {
    fontSize: 30,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 20,
    color: theme.palette.common.black,
  },
  infoDescription: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular as any,
    marginBottom: 30,
    textAlign: "center",
    lineHeight: "20px",
  },
  infoWarning: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  infoButton: {
    marginTop: 35,
    borderRadius: 20,
    padding: "10px 40px",
    height: 39,
    textTransform: "none",
    cursor: "pointer",
  },
}));

type PropTypes = {
  user: IAdminUser;
  selectedWebsite: IWebsite;
  onAdminUserLoaded: (payload: any) => void;
  onWebsiteSelected: (website: IWebsite) => void;
  handleRuleLoaded: (payload) => void;
  rule: ICampaign;
  preview: WidgetRuleEditorRootState["preview"];
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => void;
  handleRuleOnboarding: (fromOnboarding: boolean) => void;
  onWebsitesLoaded: Function;
  pageLoaded: Function;
  mpEvent: (event: string, props: object) => void;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
  selectedWebsite: state.home.selectedWebsite,
  rule: state.widgetRuleEditor.rule,
  preview: state.widgetRuleEditor.preview,
});

const mapDispatchToProps = (dispatch) => ({
  onAdminUserLoaded: (result) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: result.user }),
  onWebsiteSelected: (selectedWebsite) => dispatch({ type: HOME.ON_WEBSITES_SELECTED, selectedWebsite }),
  handleRuleLoaded: (payload) => dispatch({ type: WIDGET_RULE_EDITOR.RULE_LOADED, payload }),
  continueToNextStep: () => dispatch(replace(web.websiteInfo())),
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PROPERTY_CHANGE, property });
  },
  onWebsitesLoaded: (websites, match) => dispatch({ type: HOME.WEBSITES_LOADED, websites, match }),
  handleRuleOnboarding: (fromOnboarding: boolean) =>
    dispatch({ type: WIDGET_RULE_EDITOR.FROM_ON_BOARDING, fromOnboarding }),
  pageLoaded: () =>
    dispatch({
      type: HOME.PAGE_LOADED,
      meta: {
        mixpanel: {
          event: MIXPANEL_PAGE_VIEW,
          props: { "page-name": "Onboarding embed widgets", product: eEntailProducts.cro },
        },
      },
    }),
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
});

const PagePreview = (props: PropTypes) => {
  const classes = useStyles(props);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedWebsite,
    rule,
    preview,
    onWebsitesLoaded,
    pageLoaded,
    handleRuleLoaded,
    handlePropertyChange,
    handleRuleOnboarding,
    mpEvent,
  } = props;

  const [pageUrl, setPageUrl] = React.useState(selectedWebsite?.configurations?.siteDetails?.targetAudience?.pageUrl);
  const [hideEmbed, setHideEmbed] = React.useState(false);

  const createNewCampaign = () => {
    agent.Campaigns.createCampaign(selectedWebsite._id, {
      name: "My first campaign",
      previewUrl: pageUrl,
      rules: [{ type: "exact", value: pageUrl, operator: "and" }],
    })
      .then((res) => {
        if (res.error) {
          return enqueueSnackbar(I18n.t("widgets_display_rules_workflow.something_went_wrong"));
        }
        res.rule = {
          ...res.displayRule,
          previewUrl: pageUrl,
        };
        handlePropertyChange({ addContainerMode: true });
        return handleRuleLoaded(res);
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  const widgetContainer = React.useMemo(() => {
    return rule?.widgetContainers?.[0];
  }, [rule]);

  React.useEffect(() => {
    pageLoaded();
  }, []);

  // load websites if not loaded
  React.useEffect(() => {
    if (selectedWebsite) {
      if (!pageUrl) {
        setPageUrl(selectedWebsite?.configurations?.siteDetails?.targetAudience?.pageUrl);
      }
      return;
    }
    agent.Websites.all()
      .then((res) => {
        onWebsitesLoaded(res.websites, null);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [selectedWebsite]);

  /// create new campaign
  React.useEffect(() => {
    if (rule) {
      return;
    }
    if (!pageUrl) return;
    handleRuleOnboarding(true);
    createNewCampaign();
  }, [selectedWebsite, pageUrl]);

  // load html
  React.useEffect(() => {
    if (!rule) return;
    loadHtml(dispatch, enqueueSnackbar, {
      rule,
      selectedWebsite,
      preview,
      noCache: true,
    });
  }, [rule]);

  if (!rule) {
    return (
      <section className={classes.pageWrapper}>
        <div className={classes.emptyPageWrapper}>
          <CfProgressBar />
        </div>
      </section>
    );
  }

  return (
    <section className={classes.pageWrapper}>
      <CampaignPreview onBoardingPagePreview />
      {!hideEmbed && !preview.loading && !widgetContainer && (
        <div className={classes.infoWrapper}>
          <Typography className={classes.infoTitle}>{I18n.t("signup.onboarding_info.title")}</Typography>
          <Typography className={classes.infoDescription}>{I18n.t("signup.onboarding_info.description")}</Typography>
          <PreviewEmbedIcon />
          <MButton
            onClick={() => {
              mpEvent(eMpEvent.croEmbedButtonClick, {
                text: I18n.t("signup.onboarding_info.btn_text"),
              });
              setHideEmbed(!hideEmbed);
            }}
            customClassNames={classes.infoButton}
          >
            {I18n.t("signup.onboarding_info.btn_text")}
          </MButton>
          <Typography className={classes.infoWarning}>{I18n.t("signup.onboarding_info.warning")}</Typography>
        </div>
      )}
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PagePreview);
