import * as React from "react";

type PropTypes = {
  visible: boolean;
};

const PageEditorAssistantWrapper = (props: PropTypes) => {
  const { visible } = props;

  return <div style={{ display: visible ? "block" : "none", height: "100%" }} id={"editor_assistant_wrapper"} />;
};

export default PageEditorAssistantWrapper;
