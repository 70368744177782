import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function AlignCenterIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 32 32"}>
      <path d="M7.111,23.111 C7.111,24.089 7.911,24.889 8.889,24.889 L23.111,24.889 C24.089,24.889 24.889,24.089 24.889,23.111 C24.889,22.133 24.089,21.333 23.111,21.333 L8.889,21.333 C7.911,21.333 7.111,22.133 7.111,23.111 ZM1.778,32.000 L30.222,32.000 C31.200,32.000 32.000,31.200 32.000,30.222 C32.000,29.244 31.200,28.444 30.222,28.444 L1.778,28.444 C0.800,28.444 -0.000,29.244 -0.000,30.222 C-0.000,31.200 0.800,32.000 1.778,32.000 ZM1.778,17.778 L30.222,17.778 C31.200,17.778 32.000,16.978 32.000,16.000 C32.000,15.022 31.200,14.222 30.222,14.222 L1.778,14.222 C0.800,14.222 -0.000,15.022 -0.000,16.000 C-0.000,16.978 0.800,17.778 1.778,17.778 ZM7.111,8.889 C7.111,9.867 7.911,10.667 8.889,10.667 L23.111,10.667 C24.089,10.667 24.889,9.867 24.889,8.889 C24.889,7.911 24.089,7.111 23.111,7.111 L8.889,7.111 C7.911,7.111 7.111,7.911 7.111,8.889 ZM-0.000,1.778 C-0.000,2.756 0.800,3.556 1.778,3.556 L30.222,3.556 C31.200,3.556 32.000,2.756 32.000,1.778 C32.000,0.800 31.200,-0.000 30.222,-0.000 L1.778,-0.000 C0.800,-0.000 -0.000,0.800 -0.000,1.778 Z" />
    </SvgIcon>
  );
}

export default AlignCenterIcon;
