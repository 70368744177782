import React, { useEffect, useMemo, useState } from "react";
import { Dialog, DialogContent, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import { I18n } from "react-redux-i18n";
import XIcon from "../../icons/XIcon";
import { IKeywordStrategy, IWebsite } from "../../reducers/constants/objectTypes";
import { connect } from "react-redux";
import MTextField from "../../components/MTextField";
import MButton from "../../components/MButton";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    padding: 0,
    width: "50%",
    "& .MuiDialogContent-root": {
      padding: 30,
    },
  },
  dialogTitle: {
    fontSize: "25px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  closeButton: {
    padding: 0,
    marginRight: 0,
    alignSelf: "flex-start",
  },
  headerIcon: {
    width: 20,
    height: 20,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  charCounter: {
    display: "flex",
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.disabled,
  },
  charCounterError: {
    color: theme.palette.error.main,
  },
  inputLabel: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
  textInput: {
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    color: theme.palette.text.primary,
    padding: 10,
    fontSize: "16px",
    fontWeight: theme.typography.fontWeightLight as any,
  },
  buttonWrapper: {
    marginTop: "25px",
    marginLeft: "auto",
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: "25px",
    padding: "10px 30px",
  },
}));

type PropTypes = {
  open: boolean;
  closeDialog: () => void;
  saveSuggestedTitle: (item: any, title: string) => void;
  keywordStrategy: IKeywordStrategy;
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  keywordStrategy: state.keywordStrategy.selectedDataItem,
});

const MappingTitleDialog = (props: PropTypes) => {
  const { open, closeDialog, keywordStrategy, saveSuggestedTitle } = props;
  const classes = useStyles();

  const [currentSuggestedTitle, setCurrentSuggestedTitle] = useState(keywordStrategy?.suggested_title || "");

  useEffect(() => {
    setCurrentSuggestedTitle(keywordStrategy?.suggested_title || "");

    return () => {
      setCurrentSuggestedTitle("");
    };
  }, [keywordStrategy]);

  const MAX_CHAR = useMemo(() => 70, []);
  const currentLength = useMemo(() => {
    return currentSuggestedTitle.length;
  }, [currentSuggestedTitle]);

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={closeDialog}
      fullWidth
      maxWidth={"xl"}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.contentWrapper}>
        <div className={classes.titleWrapper}>
          <Typography variant={"subtitle1"} className={classes.dialogTitle}>
            {I18n.t("workflow.mapping.edit_title")}
          </Typography>
          <IconButton className={classes.closeButton} onClick={closeDialog}>
            <XIcon className={classes.headerIcon} />
          </IconButton>
        </div>
        <div className={classes.inputLabel}>
          <Typography variant={"subtitle2"}>{I18n.t("workflow.mapping.edit_title_description")}</Typography>
          <div className={ClassNames(classes.charCounter, currentLength > MAX_CHAR && classes.charCounterError)}>
            <span>{currentLength}</span>
            {"/"}
            <span>{MAX_CHAR}</span>
          </div>
        </div>
        <div>
          <MTextField
            autoFocus
            value={currentSuggestedTitle}
            // extendedInputProps={{ maxLength: MAX_CHAR }}
            customClass={classes.textInput}
            height={40}
            onChange={(value) => {
              setCurrentSuggestedTitle(value);
            }}
          />
        </div>
        <div className={classes.buttonWrapper}>
          <MButton
            customClassNames={classes.saveBtn}
            onClick={() => {
              if (keywordStrategy && currentSuggestedTitle) {
                saveSuggestedTitle(keywordStrategy, currentSuggestedTitle);
              }
            }}
          >
            <Typography variant={"subtitle2"}>{I18n.t("workflow.mapping.save")}</Typography>
          </MButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, {})(MappingTitleDialog);
