import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import _ from "lodash";
import ClassNames from "classnames";
import { Node } from "slate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraph: {
      fontWeight: theme.typography.fontWeightLight as any,
      fontSize: (props: PropTypes) => theme.typography.pxToRem(props.fontSize || 16),
      lineHeight: theme.typography.pxToRem(26),
      marginBottom: theme.typography.pxToRem(0),
      marginTop: 10,
      wordBreak: "break-word",
    },
    placeholder: {
      "&:before": {
        content: "attr(data-placeholder)",
        display: "block",
        position: "absolute",
        color: "grey",
        pointerEvents: "none",
      },
    },
  })
);

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  fontSize?: number;
  alignment: string;
};

const ParagraphElement = React.memo(
  (props: PropTypes) => {
    const { children, element, attributes, alignment } = props;
    const classes = useStyles(props);
    return (
      <p
        className={ClassNames(classes.paragraph, Node.string(element).length === 0 && classes.placeholder)}
        data-placeholder={"Type a paragraph"}
        style={{ textAlign: alignment }}
        {...attributes}
      >
        {children}
      </p>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps?.element?.children?.length > 1) {
      return _.isEqual(prevProps.children, nextProps.children);
    }
    return _.isEqual(prevProps.element, nextProps.element);
  }
);

export default ParagraphElement;
