import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Select, { StylesConfig, components } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { KeyboardArrowDown } from "@material-ui/icons";
import { omit } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  extraControlElement: {
    display: "flex",
    alignItems: "center",
  },
  downIcon: {
    color: theme.palette.text.primary,
    marginRight: -5,
  },
}));

const customStyles = (props: PropTypes, theme: Theme) =>
  ({
    menuPortal: (provided) => ({
      ...provided,
      zIndex: props.zIndex || 9999,
      ...(props.menuPortalWidth && { width: props.menuPortalWidth }),
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#EEF2F9",
      borderRadius: 3,
      border: "none",
      height: props.height || 50,
      boxShadow: "none",
      opacity: state.isDisabled ? 0.5 : 1,
      "&:hover": {
        cursor: "pointer",
      },
      "&:active": {
        border: "none",
      },
      "&:focus": {
        border: "none",
      },
    }),
    indicatorSeparator: () => null,
    valueContainer: (provided, state) => ({
      ...provided,
      color: theme.palette.text.primary,
      padding: "2px 0px 2px 15px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: theme.palette.text.primary,
      opacity: 0.5,
      padding: 0,
      margin: 0,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      "& div": {
        paddingRight: 15,
      },
    }),
    option: (provided, state) => ({
      fontSize: 14,
      fontWeight: theme.typography.fontWeightLight as any,
      padding: "3px 20px",
      width: "100%",
      cursor: !state.isDisabled ? "pointer" : "default",
      backgroundColor: state.isSelected ? "#E6EEFF" : "transparent",
      color: state.isSelected ? theme.palette.primary.main : theme.palette.text.primary,
      marginBottom: 3,
      "&:hover": {
        backgroundColor: "#E6EEFF",
        color: theme.palette.primary.main,
        borderRadius: "0px 3px 3px 0px",
      },
      "&:last-child": {
        marginBottom: 0,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      display: "table",
      background: theme.palette.background.paper,
      marginTop: 0,
      borderRadius: 5,
      boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      color: theme.palette.text.primary,
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
    }),
  } as StylesConfig<any, any>);

type PropTypes = {
  options: Record<string, any>[];
  value: any;
  optionLabel?: string;
  optionValue?: string;
  height?: number;
  extraControlElement?: React.ReactNode;
  zIndex?: number;
  menuPortalWidth?: number;
  onChange: (option: Record<string, any>) => void;
} & StateManagerProps;

export type IMSelectTypes = PropTypes;

const MSelect = (props: PropTypes) => {
  const {
    options,
    value,
    optionLabel = "label",
    optionValue = "value",
    extraControlElement,
    isSearchable = false,
  } = props;
  const { onChange } = props;
  const theme: any = useTheme();

  const DropdownIndicator = (props: any) => {
    const classes = useStyles(props);
    const DropDownIndicator = components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <KeyboardArrowDown className={classes.downIcon} />
      </components.DropdownIndicator>
    );

    return props.extraControlElement ? (
      <div className={classes.extraControlElement}>
        {props.extraControlElement} {DropDownIndicator}
      </div>
    ) : (
      DropDownIndicator
    );
  };

  const customComponents: Partial<SelectComponents<any, boolean, any>> = {
    DropdownIndicator: (selectProps) => (
      <DropdownIndicator extraControlElement={extraControlElement} {...props} {...selectProps} />
    ),
  };

  return (
    <Select
      components={customComponents}
      value={options.find((option) => option[optionValue] === value)}
      onChange={(option) => onChange(option)}
      options={options}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={(option) => option[optionValue]}
      styles={customStyles(props, theme)}
      isSearchable={isSearchable}
      menuPosition="fixed"
      {...omit(props, ["showNumberControls"])}
    />
  );
};

export default MSelect;
