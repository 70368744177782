import React, { useEffect, useState } from "react";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { ListItem } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import theme from "../../../../themes/theme";
import StatisticsListItem from "./StatisticsListItem";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import CfProgressBar from "../../../../components/CfProgressBar";

const useStyles = makeStyles(() => ({
  contentList: {
    width: "100%",
  },
  listSubHeader: {
    display: "flex",
  },
  firstColumn: {
    width: "15%",
  },
  column: {
    flexBasis: 0,
    flexGrow: 1,
  },
  firstColumnText: {
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  columnText: {
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  progressSpinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
}));

type StatisticsType = {
  website: IWebsite;
  total: {
    [key: string]: number;
  };
};

const StatisticsListHeader = () => {
  const classes = useStyles();

  return (
    <ListSubheader className={classes.listSubHeader}>
      <div className={ClassNames(classes.firstColumn)}>
        <Typography className={classes.firstColumnText}>{I18n.t("websites_statistics.website")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("websites_statistics.start_date")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("websites_statistics.forum_questions")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("websites_statistics.forum_answers")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("workflow.site_stats.blog")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("workflow.site_stats.targeted")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("workflow.site_stats.learningCenter")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("workflow.site_stats.topic")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("workflow.site_stats.comparison")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("workflow.site_stats.productReview")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("workflow.site_stats.productPage")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("workflow.site_stats.productAlternative")}</Typography>
      </div>
      <div className={classes.column}>
        <Typography className={classes.columnText}>{I18n.t("workflow.site_stats.productComparison")}</Typography>
      </div>
    </ListSubheader>
  );
};

type PropTypes = {
  statistics: StatisticsType[];
  loading: boolean;
};

const StatisticsList = (props: PropTypes) => {
  const classes = useStyles();

  const { statistics, loading } = props;

  return (
    <div className={classes.contentList}>
      <List>
        <StatisticsListHeader />

        {loading && (
          <ListItem className={classes.progressSpinner}>
            <CfProgressBar />
          </ListItem>
        )}

        {!loading && statistics.length === 0 && <ListItem>No content to show</ListItem>}

        {!loading && statistics.map((statistic, index) => <StatisticsListItem statistic={statistic} key={index} />)}
      </List>
    </div>
  );
};

export default StatisticsList;
