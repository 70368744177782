import { BaseEditor, Editor, Path } from "slate";
import { ReactEditor } from "slate-react";
import { HistoryEditor } from "slate-history";
import { IWidget } from "../../reducers/constants/objectTypes";

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;
export enum editorType {
  paragraph = "paragraph",
  blockQuote = "block-quote",
  headingOne = "heading-one",
  headingTwo = "heading-two",
  listItem = "list-item",
  numberedList = "numbered-list",
  bulletedList = "bulleted-list",
  image = "image",
  divider = "divider",
  textBox = "text-box",
  entailWidget = "entail-widget",
  layout = "layout",
  layoutArea = "layout-area",
  embed = "embed",
  link = "link",
  inlineHtml = "inline-html",
  commentV2 = "commentV2",
  comment = "comment",
  table = "table",
  tableHead = "table-head",
  tableBody = "table-body",
  tableRow = "table-row",
  tableHeader = "table-header",
  tableCell = "table-cell",
  faq = "faq",
  button = "button",
  productDetails = "product-details",
  callToAction = "call-to-action",
  productEmbed = "product-embed",
  productCta = "product-cta",
  productCtaHorizontal = "product-cta-horizontal",
  charticleTopProduct = "charticle-top-product",
  charticleTopProducts = "charticle-top-products",
  comparisonTable = "comparison-table",
  pricingTable = "pricing-table",
  productReview = "product-review",
  niProsCons = "ni-pros-cons",
}

export type ParagraphElement = {
  type: editorType.paragraph;
  children: CustomText[];
};

export type BlockQuote = {
  type: editorType.blockQuote;
  children: CustomText[];
  name?: string;
  jobTitle?: string;
  showDetails?: boolean;
};
export type ProductCTAData = {
  uuid: string;
  title: {
    show: boolean;
    css: any;
    title: string;
  };
  productName: {
    show: boolean;
    css: any;
  };
  price: {
    show: boolean;
    css: any;
    priceCurrency: any;
  };
  productNumber: {
    show: boolean;
    css: any;
    number: string;
  };
  startPrice: {
    show: boolean;
    css: any;
    priceCurrency: any;
  };
  button: {
    show: boolean;
    css: any;
    showArrow: boolean;
    text?: string;
    url?: string;
  };
  rating: {
    show: boolean;
  };
  maxImages: number;
  product: {
    itemNumber: string;
  };
  backgroundColor: string;
};
export type ProductCTA = { type: editorType.productCta; data: ProductCTAData; children: CustomText };

export type ButtonData = {
  button: {
    show: boolean;
    css: any;
    showArrow: boolean;
    text?: string;
    url?: string;
    id?: string;
    alignment: "left" | "center" | "right" | "full";
  };

  product: {
    itemNumber: string;
  };
};

export type Button = { type: editorType.button; data: ButtonData; children: CustomText };

export type ProductReviewData = {
  button: {
    show: boolean;
    css: any;
    showArrow: boolean;
    text?: string;
    url?: string;
    alignment: "left" | "center" | "right";
  };
  product: {
    itemNumber: string;
  };
  productNumber: {
    show: boolean;
    css: any;
    number: string;
  };
  maxImages: number;

  logo: {
    show: boolean;
  };
  reviews: {
    list: { title: string; description: string }[];
    show: boolean;
  };
};

export type ProductReview = {
  type: editorType.productReview;
  data: ProductReviewData;
  children: CustomText;
};

export type ProductCTAHorizontalData = {
  uuid: string;
  logo: {
    show: boolean;
  };
  productName: {
    show: boolean;
    css: any;
  };
  rating: {
    show: boolean;
  };
  shortDescription: {
    show: boolean;
    css: any;
  };
  bullets: {
    show: boolean;
    css: any;
  };
  button: {
    show: boolean;
    css: any;
    showArrow: boolean;
    text?: string;
    url?: string;
  };
  product: {
    itemNumber: string;
  };

  element: {
    css: any;
  };
};

export type FaqData = {
  uuid: string;
  title: {
    show: boolean;
    css: any;
    title: string;
  };
  question: {
    show: boolean;
    css: any;
    title: string;
  };
  answer: {
    show: boolean;
    css: any;
    title: string;
  };
  list: {
    question: string;
    answer: string;
  }[];

  card: {
    css: any;
  };
};
export type Faq = {
  type: editorType.faq;
  data: FaqData;

  children: CustomText;
};

export type FaqElement = {
  type: editorType.faq;
  data: {
    uuid: string;
    title: string;
    googleSchemaMarkup: boolean;
    list: {
      question: string;
      answer: string;
    }[];
    formatted?: boolean;
  };
  children: CustomText;
};

export type ProductCTAHorizontal = {
  type: editorType.productCtaHorizontal;
  data: ProductCTAHorizontalData;
  children: CustomText;
};

export type CharticleTopProductData = {
  uuid: string;
  position: {
    show: boolean;
    index: string;
  };
  logo: {
    show: boolean;
  };
  rating: {
    show: boolean;
  };
  productName: {
    show: boolean;
    css: object;
  };
  shortDescription: {
    show: boolean;
    css: object;
  };
  bullets: {
    show: boolean;
    css: object;
    icon: {
      key: string;
      color: string;
    };
  };
  score: {
    show: boolean;
  };
  button: {
    show: boolean;
    text: string;
    css: object;
    showArrow: boolean;
  };
  product: {
    itemNumber: string;
  };
  card: {
    css: any;
  };
};

export type EntailWidget = {
  type: editorType.entailWidget;
  data: EntailWidgetData;
  children: CustomText;
};

export type EntailWidgetData = {
  uuid: string;
  margin?: {
    top: number;
    bottom: number;
  };
  widget: {
    id: string;
    uploadedId: string;
    name: string;
  } | null;
};

export type CharticleTopProduct = {
  type: editorType.charticleTopProduct;
  data: CharticleTopProductData;
  children: CustomText;
};

export type CharticleTopProductsData = {
  uuid: string;
  position: {
    show: boolean;
  };
  logo: {
    show: boolean;
  };
  rating: {
    show: boolean;
  };
  productName: {
    show: boolean;
    css: object;
  };
  shortDescription: {
    show: boolean;
    css: object;
  };
  bullets: {
    show: boolean;
    css: object;
    icon: {
      key: string;
      color: string;
    };
  };
  score: {
    show: boolean;
  };
  button: {
    show: boolean;
    text: string;
    css: object;
    showArrow: boolean;
  };
  products: {
    itemNumber: string;
    position?: string;
  }[];
  card: {
    css: any;
  };
};
export type CharticleTopProducts = {
  type: editorType.charticleTopProducts;
  data: CharticleTopProductsData;
  children: CustomText;
};

export type ForcedLayout = {
  type: string;
  insert: (editor: Editor, at: Path) => void;
  name: string;
  layoutType?: string;
};

export type FormattedText = { text: string; bold?: true };
export type CustomText = FormattedText;

export type CustomElement =
  | ParagraphElement
  | ProductCTA
  | ProductCTAHorizontal
  | CharticleTopProduct
  | CharticleTopProducts
  | Button
  | BlockQuote
  | Faq
  | EntailWidget
  | FaqElement;

declare module "slate" {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}
