import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import CfSearchInput from "../../CfSearchInput";

const useStyles = makeStyles((theme: Theme) => ({
  searchField: {
    width: "100%",
    maxWidth: 300,
    height: "unset",
  },
  searchInput: {
    height: 40,
    borderRadius: 0,
    padding: "10px 0px",
    fontSize: 14,

    backgroundColor: "white",
  },
  notchedOutline: {
    borderWidth: "0px 0px 1px 0px !important",
    borderColor: `${theme.palette.divider} !important`,
    "&:focus": {
      borderWidth: "0px 0px 1px 0px",
      borderColor: theme.palette.divider,
    },
    "&:hover": {
      borderWidth: "0px 0px 1px 0px",
      borderColor: theme.palette.divider,
    },
  },
  input: {
    padding: 0,
    color: "#000",
  },
}));

type PropTypes = {
  searchText: string;
  handleSearchTextChanged: (text: string) => void;
  placeHolder?: string;
  inputClassName?: string;
};

const MTableToolbarSearchInput = (props: PropTypes) => {
  const classes = useStyles();
  const { searchText, handleSearchTextChanged = null, placeHolder, inputClassName } = props;

  return (
    <CfSearchInput
      searchText={searchText}
      customClass={classes.searchField}
      inputClassName={ClassNames(classes.input, inputClassName)}
      customInputClass={classes.searchInput}
      notchedOutlineCustomClass={classes.notchedOutline}
      placeholder={placeHolder || I18n.t("workflow.writer.search_posts")}
      handleSearchTextChange={handleSearchTextChanged}
    />
  );
};

export default MTableToolbarSearchInput;
