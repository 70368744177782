import filter from "lodash/filter";
import { CONTENT_SOURCE_PICKER } from "./constants/actionTypes";

const initialState = {
  insertToPost: null,
  insertToPostType: null,

  selectedPost: null,
  selectedPostType: null,

  filterFacebookPosts: true,
  filterManualPosts: true,

  tags: [],
  selectedTags: [],
  topics: [],
  selectedTopics: [],
  domains: [],
  selectedDomainsIds: [],

  contentLoaded: false,
  fbPosts: [],
  manualPosts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTENT_SOURCE_PICKER.OPEN_DIALOG:
      return {
        ...state,
        insertToPost: action.post,
        insertToPostType: action.postType,
      };
    case CONTENT_SOURCE_PICKER.SET_SELECTED_POST:
      return {
        ...state,
        selectedPost: action.post,
        selectedPostType: action.postType,
      };
    case CONTENT_SOURCE_PICKER.CONTENT_LOADED: {
      let newSelectedPost = null;
      let newSelectedPostType = null;
      if (action.payload.fbPosts.length > 0) {
        newSelectedPost = action.payload.fbPosts[0];
        newSelectedPostType = "fbPost";
      } else if (action.payload.manualPosts.length > 0) {
        newSelectedPost = action.payload.manualPosts[0];
        newSelectedPostType = "manualPost";
      }
      return {
        ...state,
        contentLoaded: true,
        fbPosts: action.payload.fbPosts || [],
        manualPosts: action.payload.manualPosts || [],
        selectedPost: newSelectedPost,
        selectedPostType: newSelectedPostType,
      };
    }
    case CONTENT_SOURCE_PICKER.FILTER_FACEBOOK_POSTS_CHANGED:
      return {
        ...state,
        contentLoaded: false,
        filterFacebookPosts: action.filterFacebookPosts,
      };
    case CONTENT_SOURCE_PICKER.FILTER_MANUAL_POSTS_CHANGED:
      return {
        ...state,
        contentLoaded: false,
        filterManualPosts: action.filterManualPosts,
      };
    case CONTENT_SOURCE_PICKER.TAGS_LOADED:
      return {
        ...state,
        tags: action.payload.tags,
      };
    case CONTENT_SOURCE_PICKER.TOPICS_LOADED:
      return {
        ...state,
        topics: action.payload.topics,
      };
    case CONTENT_SOURCE_PICKER.DOMAINS_LOADED:
      return {
        ...state,
        domains: action.payload.domains,
      };
    case CONTENT_SOURCE_PICKER.DOMAINS_SELECTED:
      return {
        ...state,
        contentLoaded: false,
        selectedDomainsIds: state.selectedDomainsIds.includes(action.domain._id)
          ? filter(state.selectedDomainsIds, (selectedDomainId) => selectedDomainId !== action.domain._id)
          : [...state.selectedDomainsIds, action.domain._id],
      };
    case CONTENT_SOURCE_PICKER.FB_POST_MARK_AS_USED:
      return {
        ...state,
        insertToPost: action.payload.learnPost,
        selectedPost: action.payload.fbPost,
        fbPosts: state.fbPosts.map((post) => {
          if (post._id === action.payload.fbPost._id) {
            return action.payload.fbPost;
          }
          return post;
        }),
      };
    case CONTENT_SOURCE_PICKER.MANUAL_POST_MARK_AS_USED:
      return {
        ...state,
        insertToPost: action.payload.learnPost,
        selectedPost: action.payload.manualPost,
        manualPosts: state.manualPosts.map((post) => {
          if (post._id === action.payload.manualPost._id) {
            return action.payload.manualPost;
          }
          return post;
        }),
      };
    default:
      return state;
  }
};
