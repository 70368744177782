import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import CfProgressBar from "../../../../../components/CfProgressBar";
import CommunityEditorAnswersList from "./components/CommunityEditorAnswersList";
import CommunityEditorQuestion from "./components/CommunityEditorQuestion";
import CommunityEditorBottomBar from "./components/CommunityEditorBottomBar";
import CommunityEditorAnswerBottomBar from "./components/CommunityEditorAnswerBottomBar";

const useStyles = makeStyles((theme: Theme) => ({
  uploadPostWrapper: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    backgroundColor: "#F5F6F8",
  },
  postWrapper: {
    height: "calc(100vh - 200px)",
    // maxWidth: 750,
    width: "100%",
    overflowY: "scroll",
    paddingRight: 30,
    paddingLeft: 30,
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  emptyPageWrapper: {
    marginTop: 100,
    width: "100%",
    justifyContent: "center",
    display: "flex",
  },
  containerTopWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    marginTop: 30,
  },
}));

type PropTypes = {
  questionLoaded: boolean;
  isAnswerEditor: boolean;
};

const mapStateToProps = (state) => ({
  questionLoaded: state.communityEditor.questionLoaded,
  isAnswerEditor: state.communityEditor.isAnswerEditor,
});

const mapDispatchToProps = (dispatch) => ({});

const CommunityEditorMainScreen = (props: PropTypes) => {
  const classes = useStyles(props);
  const { questionLoaded, isAnswerEditor } = props;

  if (!questionLoaded) {
    return (
      <div className={classes.emptyPageWrapper}>
        <CfProgressBar />
      </div>
    );
  }

  return (
    <div className={classes.uploadPostWrapper}>
      <div className={classes.postWrapper}>
        <CommunityEditorQuestion />
        <CommunityEditorAnswersList />
      </div>
      {isAnswerEditor ? <CommunityEditorAnswerBottomBar /> : <CommunityEditorBottomBar />}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorMainScreen);
