import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 14,
    height: 14,
  },
}));

type PropTypes = {
  className?: string;
};

const StrokeTopIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 14 14"}>
      <rect x="0.5" y="0.5" width="13" height="13" rx="0.5" fill="#F5F6F8" stroke="#C4C4C4" />
      <mask id="path-2-inside-1_3797_288" fill="white">
        <path d="M0 1C0 0.447716 0.447715 0 1 0L13 0C13.5523 0 14 0.447715 14 1L14 13C14 13.5523 13.5523 14 13 14L1 14C0.447716 14 0 13.5523 0 13L0 1Z" />
      </mask>
      <path
        d="M0 1C0 -0.380712 1.11929 -1.5 2.5 -1.5L11.5 -1.5C12.8807 -1.5 14 -0.380712 14 1V1.5C14 1.5 13.5523 1.5 13 1.5L1 1.5C0.447715 1.5 0 1.5 0 1.5L0 1ZM14 14L0 14L14 14ZM0 14L0 0L0 14ZM14 0L14 14L14 0Z"
        fill="#4353FF"
        mask="url(#path-2-inside-1_3797_288)"
      />
    </SvgIcon>
  );
};

export default StrokeTopIcon;
