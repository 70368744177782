import React from "react";
import { I18n } from "react-redux-i18n";

import { IProduct } from "../../../../reducers/constants/objectTypes";
import ModalInputField from "../../components/ModalInputField";
import MTextFieldV2 from "../../../../components/MTextFieldV2";
import MNumeratedItem from "../../../../components/MNumeratedItem";

type PropTypes = {
  product: IProduct;
  onChange: (product: IProduct) => void;
};

const TagsView = (props: PropTypes) => {
  const { product, onChange } = props;

  return (
    <div>
      {[...product.tags, ...(product.tags.length < 6 ? [""] : [])].map((tag, index, array) => (
        <ModalInputField title={`Tag #${index + 1}`} key={index}>
          <MNumeratedItem
            index={index}
            marginBottom={3}
            showRemoveIcon={index !== array.length - 1}
            showCounter={false}
            onRemove={() =>
              onChange({
                ...product,
                tags: product.tags.filter((_, i) => i !== index),
              })
            }
          >
            <MTextFieldV2
              value={tag}
              onValueChange={(value) => {
                const updatedTags = [...product.tags];
                if (!value) {
                  updatedTags.splice(index, 1);
                  onChange({
                    ...product,
                    tags: updatedTags,
                  });
                  return;
                }

                updatedTags[index] = value;
                onChange({
                  ...product,
                  tags: updatedTags,
                });
              }}
              placeholder={I18n.t("product_dialog.general_view.type_ph")}
            />
          </MNumeratedItem>
        </ModalInputField>
      ))}
    </div>
  );
};

export default TagsView;
