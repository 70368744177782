import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import classNames from "classnames";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { I18n } from "react-redux-i18n";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import { copyStringToClipboard } from "../../../../../helpers/fbScraperHelper";
import CopyNameIcon from "../../../../../icons/CopyNameIcon";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    display: "block",
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
  },
  topicWrapper: {
    minHeight: 60,
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      transition: "500ms",
      backgroundColor: theme.palette.secondary.main,
      borderBottom: "1px solid #fff",
      "& $ruleAction": {
        display: "flex",
      },
      "& $actions": {
        display: "flex",
      },
    },
  },
  topicNameWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 20,
  },
  urlWrapper: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  topicName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
    cursor: "pointer",
    width: "fit-content",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  collapse: {},
  iconColumn: {
    // padding: '19px 14px 16px 16px',
    paddingLeft: 10,
    // borderBottom: '1px solid #fff',
    marginBottom: -1,
    width: 55,
  },

  hiddenColumn: {
    width: 20,
  },
  firstColumn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    width: "10%",
  },
  column: {
    flexGrow: 1,
    flexBasis: 0,
  },
  landingPageColumn: {
    minWidth: 200,
    textOverflow: "ellipsis",
    overflow: "hidden",
    wordBreak: "break-word",
    whiteSpace: "nowrap",
  },
  urlColumn: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    wordBreak: "break-word",
    whiteSpace: "nowrap",
  },
  hoverableColumn: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  moreOptionsColumn: {
    width: 30,
  },
  tooltip: {
    whiteSpace: "pre-wrap",
  },
  ruleAction: {},
  landingSiteWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    padding: 10,
  },
  actions: {
    flexDirection: "row",
    alignItems: "center",
    display: "none",
    paddingRight: 10,
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    right: 0,
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  order: any;
  selectedWebsite: IWebsite;

  // handleEditClick: Function
  // handleDeleteClick: Function
};

const OrderItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, order } = props;
  const { enqueueSnackbar } = useSnackbar();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: order.currency || "USD",
  });

  return (
    <ListItem
      // onClick={() => handleEditClick()}
      className={classes.itemWrapper}
    >
      <div className={ClassNames(classes.topicWrapper)}>
        <div className={classes.hiddenColumn} />

        <div className={classes.firstColumn}>
          <div className={classes.topicNameWrapper}>
            <Tooltip enterDelay={1000} title={order.orderNumber} arrow>
              <div className={classes.urlWrapper}>
                <Typography className={classes.topicName} variant="subtitle2">
                  {order.orderNumber}
                </Typography>
                {/* <RuleUrlActions customClass={classes.ruleAction} url={`${selectedWebsite.url}${rule.from}`} /> */}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={classes.column}>{moment(order.orderCreatedAt).format("MM.DD.YY")}</div>
        <div className={classes.column}>{moment(order.orderUpdatedAt).format("MM.DD.YY")}</div>
        <div className={classes.column}>{formatter.format(order.price)}</div>
        <div className={classes.column}>{order.items.length}</div>
        <div className={classes.column}>{order.customerCountry}</div>
        <div className={classes.column}>{order.customerId}</div>
        <div className={classes.column}>{order.fulfillmentStatus}</div>
        <div className={classNames(classes.column, classes.landingPageColumn)}>
          <Tooltip enterDelay={1000} title={order.landingPage} arrow>
            <div className={classes.landingSiteWrapper}>
              {(order.landingPage || "").slice(0, 30)}
              <div className={classes.actions}>
                <Tooltip enterDelay={500} title={I18n.t(`learning_center_content.tooltip.open_in_new_tab`)}>
                  <IconButton
                    disableRipple
                    className={classes.button}
                    onClick={(event) => {
                      event.stopPropagation();
                      window.open(order.landingPage, "_blank");
                    }}
                  >
                    <OpenInNewIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
                <Tooltip enterDelay={500} title={I18n.t(`learning_center_content.tooltip.copy_url`)}>
                  <IconButton
                    disableRipple
                    className={classes.button}
                    onClick={(event) => {
                      event.stopPropagation();
                      copyStringToClipboard(order.landingPage, () =>
                        enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))
                      );
                    }}
                  >
                    <CopyNameIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </Tooltip>
        </div>
        <div className={classNames(classes.column, classes.urlColumn)}>
          {/* {rule.autoRule ? '✔' : '✘'} */}
          <Tooltip enterDelay={1000} title={order.referringSite} arrow>
            <span>{order.referringSite}</span>
          </Tooltip>
        </div>
        <div className={classNames(classes.column, classes.urlColumn)}>
          <Tooltip enterDelay={1000} title={order.notes} arrow>
            <span>{order.notes}</span>
          </Tooltip>
        </div>
        <div className={classes.moreOptionsColumn}>
          {/* <RuleMoreOptionsButton */}
          {/*	// handleDeleteClick={handleDeleteClick} */}
          {/* /> */}
        </div>
      </div>
    </ListItem>
  );
};

export default OrderItem;
