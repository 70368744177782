import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import moment from "moment";
import { COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import {
  IAdminUser,
  IQuestion,
  IWebsite,
  IWriterCommunityAnswer,
} from "../../../../../../reducers/constants/objectTypes";
import RichTextEditor from "../../../../../../editor/RichTextEditor";
import CalendarIcon from "../../../../../../icons/CalendarIcon";

// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
  answerWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    paddingBottom: 20,
  },
  answerTopBar: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 20px",
    borderBottom: "1px solid #E5E5E5",
    fontSize: theme.typography.pxToRem(12),
    padding: "0 5px",
  },
  commentWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 5,
  },
  editorToolbar: {
    overflowX: "auto",
    padding: "0 20px",
    justifyContent: "space-between",
  },
  editable: {
    fontSize: theme.typography.pxToRem(16),
    minHeight: 100,
    paddingTop: 5,
  },
  realUserWrapper: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "center",
    height: 45,
  },
  date: {
    display: "flex",
    alignItems: "center",
  },
  dateIcon: {
    marginRight: 5,
    marginBottom: 2,
  },
  dateText: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedQuestion: IQuestion;
  admin: IAdminUser;
  writerAnswer: IWriterCommunityAnswer;
  handleAnswerChange: Function;
  handleAnswerPostedTimeChange: Function;
  handleQuestionChange: (question: IQuestion) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  selectedQuestion: state.communityEditor.question,
  admin: state.home.admin,
  writerAnswer: state.communityEditor.writerAnswer,
});

const mapDispatchToProps = (dispatch) => ({
  handleQuestionChange: (question) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_QUESTION_CHANGED, question }),
  handleAnswerChange: (writerAnswer) =>
    dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_WRITER_ANSWER_CHANGED, writerAnswer }),
  handleAnswerPostedTimeChange: (postedTime) =>
    dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_WRITER_ANSWER_POSTED_TIME_CHANGED, postedTime }),
});

const CommunityEditorWriterAnswer = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, admin, writerAnswer, selectedQuestion, handleQuestionChange } = props;
  const { handleAnswerChange, handleAnswerPostedTimeChange } = props;
  const convertTextToRichText = (initialText) => {
    return JSON.stringify([
      {
        type: "paragraph",
        children: [{ text: initialText || "" }],
      },
    ]);
  };

  return (
    <div className={classes.answerWrapper}>
      <Paper elevation={0} className={classes.commentWrapper}>
        <RichTextEditor
          fontSize={16}
          droppableId={`question_editor_writer_answer`}
          withCustomElements={true}
          editableCustomClass={classes.editable}
          toolbarCustomClass={classes.editorToolbar}
          website={selectedWebsite}
          products={selectedQuestion.products}
          handleProductsChange={(products) => handleQuestionChange({ ...selectedQuestion, products })}
          initialValue={
            writerAnswer.richText || writerAnswer.resourceRichText || convertTextToRichText(writerAnswer.text)
          }
          onValueChanged={(value, raw) => handleAnswerChange({ ...writerAnswer, text: raw, richText: value })}
        >
          <div className={classes.answerTopBar}>
            <div className={classes.realUserWrapper}>
              <Typography color={"textSecondary"} variant={"inherit"}>
                {writerAnswer?.writer?.name || admin.name}
              </Typography>
            </div>
            <div className={classes.date}>
              <CalendarIcon className={classes.dateIcon} />
              <Typography className={classes.dateText} color={"textPrimary"} variant={"body2"}>
                {moment(writerAnswer.postedTime).format("MMM DD, YYYY")}
              </Typography>
            </div>
          </div>
        </RichTextEditor>
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorWriterAnswer);
