import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Paper, Popover } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import edit from "../../../img/edit.png";
import SelectRow from "./SelectRow";
import InputRow from "./InputRow";
import ColorPickerRow from "./ColorPickerRow";
import DimensionsInputRow from "./DimensionsInputRow";
import {
  fontOptions,
  fontStyleOptions,
  letterCaseOptions,
  textDecorationOptions,
  weightOptions,
} from "../WebsiteEditor/consts/CssConsts";
import { omit } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  elementDesignRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 50,
    paddingBottom: 40,

    borderBottom: `solid 1px ${theme.palette.divider}`,
    "&:last-child": {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: "none",
    },
  },
  elementDesignColumn: {
    display: "flex",
    width: 400,
  },
  elementTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 20,
  },
  designPropsRow: {
    display: "flex",
    padding: "0px 30px",
    minHeight: 59,
    alignItems: "center",
    justifyContent: "center",

    // marginBottom: 30,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& $editWrapper": {
        display: "flex",
      },
    },
  },
  designWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    cursor: "pointer",
    justifyContent: "space-between",
  },
  links: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  designTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 20,
    width: "100%",
  },
  popoverPaper: {
    borderRadius: 0,
    boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.15)",
    border: "none",
    marginLeft: 10,
  },
  wrapper: {
    height: "100%",
    width: 416,
    border: "none",
    borderRadius: 0,
  },
  innerWrapper: {
    padding: "15px 20px",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 15,
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  colorSquare: {
    width: 16,
    height: 16,
    backgroundColor: "black",
    marginLeft: 7,
  },
  editImage: {
    marginLeft: 15,
    cursor: "pointer",
  },
  toggleGroup: {
    width: "100%",
    marginBottom: 20,
  },
  toggleButton: {
    width: "100%",
  },
  editWrapper: {
    display: "none",
    gap: 10,
    alignItems: "center",
  },
  designText: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
}));

type PropTypes = {
  elementCss: any;
  defaultElementCss: any;
  theme: IWebsiteThemeProps;
  elementTitle: string;

  handleCssChanged: (css) => void;
};

const mapStateToProps = (state) => ({
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({});

const LinkDesignToolWithPreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { elementCss, defaultElementCss } = props;
  const { theme, elementTitle, handleCssChanged } = props;

  const [hover, setHover] = React.useState(false);

  const [css, setCss] = React.useState<any>(omit(elementCss || {}, "&:hover"));
  const [hoverCss, setHoverCss] = React.useState<any>(elementCss["&:hover"] || {});

  const finalDefaultCss: any = {
    color: theme.palette.primary.main,
    textDecoration: "none",
    ...css,
  };
  const finalDefaultHoverCss: any = {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    hoverCss,
  };

  const onCssChanged = (cssName, cssValue, hoverProperty) => {
    if (hoverProperty) {
      const newHoverCss = { ...hoverCss, [cssName]: cssValue };
      setHoverCss(newHoverCss);
      handleCssChanged({
        ...css,
        "&:hover": newHoverCss,
      });
    } else {
      const newCss = { ...css, [cssName]: cssValue };
      setCss(newCss);
      handleCssChanged({
        ...newCss,
        "&:hover": hoverCss,
      });
    }
  };

  const handleHoverView = (event: React.MouseEvent<HTMLElement>, newHover: boolean | null) => {
    if (newHover !== null) {
      setHover(newHover);
    }
  };

  return (
    <div className={classes.designPropsRow}>
      <PopupState variant="popover" popupId="popover">
        {(popupState) => (
          <div style={{ display: "contents" }}>
            <div className={classes.designWrapper} {...bindTrigger(popupState)}>
              <div>
                {/* <Typography variant={'body2'} className={classes.designTitle}>{elementTitle}</Typography> */}
                <Link
                  color={"primary"}
                  underline={"none"}
                  href="#"
                  onClick={null}
                  style={{ ...finalDefaultCss, ...elementCss, margin: 0, padding: 0 }}
                >
                  {I18n.t("website.design_tool.regular_link")}
                </Link>
                &nbsp;&&nbsp;
                <Link
                  color={"primary"}
                  underline={"none"}
                  href="#"
                  onClick={null}
                  style={{
                    ...finalDefaultCss,
                    ...elementCss,
                    ...finalDefaultHoverCss,
                    ...elementCss["&:hover"],
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {I18n.t("website.design_tool.link_on_hover")}
                </Link>
              </div>
              <div className={classes.editWrapper}>
                <img width={12} height={12} src={edit} alt={"edit_image"} className={classes.editImage} />
                <Typography variant={"body2"} className={classes.designText}>
                  {I18n.t("website.edit")}
                </Typography>
              </div>
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              elevation={0}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <Paper className={classes.wrapper}>
                <div className={classes.titleWrapper}>
                  <Typography color={"textPrimary"} variant={"subtitle1"}>
                    {`${elementTitle} Typography`}
                  </Typography>
                  <CloseIcon className={classes.closeIcon} onClick={popupState.close} />
                </div>
                <div className={classes.innerWrapper}>
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.font")}
                    value={css?.fontFamily}
                    defaultValue={finalDefaultCss?.fontFamily || theme.typography.fontFamily}
                    isValueChanged={
                      css?.fontFamily &&
                      css?.fontFamily !== (finalDefaultCss?.fontFamily || theme.typography.fontFamily)
                    }
                    selectOptions={fontOptions}
                    handleChange={(font) => {
                      onCssChanged("fontFamily", font, false);
                    }}
                  />

                  <InputRow
                    rowTitle={I18n.t("website.design_tool.size")}
                    value={css?.fontSize}
                    defaultValue={finalDefaultCss?.fontSize}
                    isValueChanged={css?.fontSize && css?.fontSize !== finalDefaultCss?.fontSize}
                    handleChange={(size) => {
                      onCssChanged("fontSize", size, false);
                    }}
                  />

                  <InputRow
                    rowTitle={I18n.t("website.design_tool.line_height")}
                    value={css?.lineHeight}
                    stepSize={"0.1"}
                    defaultValue={finalDefaultCss?.lineHeight}
                    isValueChanged={css?.lineHeight && css?.lineHeight !== finalDefaultCss?.lineHeight}
                    handleChange={(lineHeight) => {
                      onCssChanged("lineHeight", lineHeight, false);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.letter_spacing")}
                    value={css?.letterSpacing}
                    defaultValue={finalDefaultCss?.letterSpacing}
                    isValueChanged={css?.letterSpacing && css?.letterSpacing !== finalDefaultCss?.letterSpacing}
                    handleChange={(letterSpacing) => {
                      onCssChanged("letterSpacing", letterSpacing, false);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.padding")}
                    value={css?.padding}
                    defaultValue={finalDefaultCss?.padding}
                    isValueChanged={css?.padding && css?.padding !== finalDefaultCss?.padding}
                    handleChange={(padding) => {
                      onCssChanged("padding", padding, false);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.margin")}
                    value={css?.margin}
                    defaultValue={finalDefaultCss?.margin}
                    isValueChanged={css?.margin && css?.margin !== finalDefaultCss?.margin}
                    handleChange={(margin) => {
                      onCssChanged("margin", margin, false);
                    }}
                  />

                  <ToggleButtonGroup
                    className={classes.toggleGroup}
                    size="small"
                    exclusive
                    value={hover}
                    onChange={handleHoverView}
                    aria-label="device"
                  >
                    <ToggleButton className={classes.toggleButton} value={false} aria-label="regular">
                      <Typography color={"textPrimary"} variant={"body1"}>
                        {I18n.t("website.design_tool.regular")}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton className={classes.toggleButton} value={true} aria-label="hover">
                      <Typography color={"textPrimary"} variant={"body1"}>
                        {I18n.t("website.design_tool.hover")}
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {hover && (
                    <>
                      <ColorPickerRow
                        rowTitle={I18n.t("website.design_tool.text_color")}
                        value={hoverCss?.color || css?.color}
                        defaultValue={finalDefaultHoverCss?.color}
                        isValueChanged={hoverCss?.color && hoverCss?.color !== finalDefaultHoverCss?.color}
                        handleChange={(color) => {
                          onCssChanged("color", color, hover);
                        }}
                      />
                      <SelectRow
                        rowTitle={I18n.t("website.design_tool.weight")}
                        value={hoverCss?.fontWeight || css?.fontWeight}
                        defaultValue={finalDefaultHoverCss?.fontWeight}
                        isValueChanged={
                          hoverCss?.fontWeight && hoverCss?.fontWeight !== finalDefaultHoverCss?.fontWeight
                        }
                        selectOptions={weightOptions}
                        handleChange={(weight) => {
                          onCssChanged("fontWeight", weight, hover);
                        }}
                      />
                      <SelectRow
                        rowTitle={I18n.t("website.design_tool.font_style")}
                        value={hoverCss?.fontStyle || css?.fontStyle}
                        defaultValue={finalDefaultHoverCss?.fontStyle}
                        isValueChanged={hoverCss?.fontStyle && hoverCss?.fontStyle !== finalDefaultHoverCss?.fontStyle}
                        selectOptions={fontStyleOptions}
                        handleChange={(fontStyle) => {
                          onCssChanged("fontStyle", fontStyle, hover);
                        }}
                      />
                      <SelectRow
                        id={"textTransform-hover"}
                        rowTitle={I18n.t("website.design_tool.letter_case")}
                        value={hoverCss?.textTransform || css?.textTransform}
                        defaultValue={finalDefaultHoverCss?.textTransform}
                        isValueChanged={
                          hoverCss?.textTransform && hoverCss?.textTransform !== finalDefaultHoverCss?.textTransform
                        }
                        selectOptions={letterCaseOptions}
                        handleChange={(letterCase) => {
                          onCssChanged("textTransform", letterCase, hover);
                        }}
                      />
                      <SelectRow
                        rowTitle={I18n.t("website.design_tool.text_decoration")}
                        value={hoverCss?.textDecoration || css?.textDecoration}
                        defaultValue={finalDefaultHoverCss?.textDecoration}
                        isValueChanged={
                          hoverCss?.textDecoration && hoverCss?.textDecoration !== finalDefaultHoverCss?.textDecoration
                        }
                        selectOptions={textDecorationOptions}
                        handleChange={(textDecoration) => {
                          onCssChanged("textDecoration", textDecoration, hover);
                        }}
                      />
                    </>
                  )}

                  {!hover && (
                    <>
                      <ColorPickerRow
                        rowTitle={I18n.t("website.design_tool.text_color")}
                        value={css?.color}
                        defaultValue={finalDefaultCss?.color}
                        isValueChanged={css?.color && css?.color !== finalDefaultCss?.color}
                        handleChange={(color) => {
                          onCssChanged("color", color, hover);
                        }}
                      />
                      <SelectRow
                        rowTitle={I18n.t("website.design_tool.weight")}
                        value={css?.fontWeight}
                        defaultValue={finalDefaultCss?.fontWeight}
                        isValueChanged={css?.fontWeight && css?.fontWeight !== finalDefaultCss?.fontWeight}
                        selectOptions={weightOptions}
                        handleChange={(weight) => {
                          onCssChanged("fontWeight", weight, hover);
                        }}
                      />
                      <SelectRow
                        rowTitle={I18n.t("website.design_tool.font_style")}
                        value={css?.fontStyle}
                        defaultValue={finalDefaultCss?.fontStyle}
                        isValueChanged={css?.fontStyle && css?.fontStyle !== finalDefaultCss?.fontStyle}
                        selectOptions={fontStyleOptions}
                        handleChange={(fontStyle) => {
                          onCssChanged("fontStyle", fontStyle, hover);
                        }}
                      />
                      <SelectRow
                        id={"textTransform"}
                        rowTitle={I18n.t("website.design_tool.letter_case")}
                        value={css?.textTransform}
                        defaultValue={finalDefaultCss?.textTransform}
                        isValueChanged={css?.textTransform && css?.textTransform !== finalDefaultCss?.textTransform}
                        selectOptions={letterCaseOptions}
                        handleChange={(letterCase) => {
                          onCssChanged("textTransform", letterCase, hover);
                        }}
                      />
                      <SelectRow
                        rowTitle={I18n.t("website.design_tool.text_decoration")}
                        value={css?.textDecoration}
                        defaultValue={finalDefaultCss?.textDecoration}
                        isValueChanged={css?.textDecoration && css?.textDecoration !== finalDefaultCss?.textDecoration}
                        selectOptions={textDecorationOptions}
                        handleChange={(textDecoration) => {
                          onCssChanged("textDecoration", textDecoration, hover);
                        }}
                      />
                    </>
                  )}
                </div>
              </Paper>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkDesignToolWithPreview);
