import * as React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import RedirectsList from "./components/RedirectsList";
import agent from "../../../../agent";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../../components/Table/Toolbar/MTableToolbar";
import NewRedirectRuleDialog from "./components/NewRedirectRuleDialog";
import MTableToolbarBaseActionButton from "../../../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

type PropTypes = {
  selectedWebsite: IWebsite;
};

const Redirects = (props: PropTypes) => {
  const { selectedWebsite } = props;
  const [searchText, setSearchText] = React.useState("");
  const [redirectsRules, setRedirectsRules] = React.useState<any[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [refresh, setRefresh] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [newQueryGroupDialogOpen, setNewQueryGroupDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    if (!refresh) {
      return;
    }
    setLoaded(false);
    agent.Redirects.getRules(selectedWebsite._id)
      .then((res) => {
        setRedirectsRules(res.rules);
        setRefresh(false);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        setRefresh(false);
        console.log("e", e);
      });
  }, [selectedWebsite, refresh]);

  const deleteRule = (rule) => {
    agent.Redirects.deleteRule(selectedWebsite._id, rule)
      .then((res) => {
        setRefresh(true);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
        setRefresh(true);
        console.log("e", e);
      });
  };

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.seo.redirects.title`)} />
      <MTableToolbar
        searchText={searchText}
        handleSearchTextChanged={(text) => setSearchText(text)}
        actions={[
          <MTableToolbarBaseActionButton
            handleClick={() => {
              setNewQueryGroupDialogOpen(true);
            }}
            text={I18n.t("redirect_rules.new_rule_dialog.title")}
          />,
        ]}
      />
      <RedirectsList
        loaded={loaded}
        rules={redirectsRules}
        searchText={searchText}
        selectedWebsite={selectedWebsite}
        deleteRule={deleteRule}
        refreshList={() => setRefresh(true)}
      />
      {newQueryGroupDialogOpen && (
        <NewRedirectRuleDialog
          selectedWebsite={selectedWebsite}
          open={newQueryGroupDialogOpen}
          handleCloseClick={() => setNewQueryGroupDialogOpen(false)}
          handleRuleCreated={(rule) => {
            setRefresh(true);
          }}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Redirects);
