import { isInteger } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";

import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import { IWidget, IWebsite, IWidgetItem } from "../../../../../../../reducers/constants/objectTypes";
import SelectedWidgetItem from "../components/SelectedWidgetItem";
import WidgetItemList from "../components/WidgetItemList";
import WidgetItemProductPicker from "../components/WidgetItemProductPicker";
import EditWidgetName from "../components/EditWidgetName";

type PropTypes = {
  selectedWebsite: IWebsite;

  widget: IWidget;
  selectedItem: number;
  setSelectedWidgetItem: (index?: number) => void;
  addWidgetItem: () => void;
  editWidgetItems: (items: IWidget["items"]) => void;
  deleteWidgetItem: (index: number) => void;
  editBackground: (payload: IWidget["background"]) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  widget: state.widgetBuilder.widget,
  selectedItem: state.widgetBuilder.selectedItem,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedWidgetItem: (index: number) => {
    dispatch({ type: WIDGET_BUILDER.SET_SELECTED_ITEM, selectedItem: index });
  },
  deleteWidgetItem: (index: number) => dispatch({ type: WIDGET_BUILDER.DELETE_ITEM, index }),
  addWidgetItem: () => dispatch({ type: WIDGET_BUILDER.ADD_NEW_ITEM }),
  editWidgetItems: (items: IWidget["items"]) =>
    dispatch({ type: WIDGET_BUILDER.EDIT_WIDGET_PROPERTY, property: { items } }),
  editBackground: (payload: IWidget["background"]) => dispatch({ type: WIDGET_BUILDER.EDIT_BACKGROUND, payload }),
});

const CustomWidgetBuildTab = (props: PropTypes) => {
  const { widget, selectedWebsite, selectedItem } = props;
  const { addWidgetItem, editWidgetItems, setSelectedWidgetItem, deleteWidgetItem } = props;

  const selectedWidgetItem = React.useMemo(() => {
    if (!isInteger(selectedItem)) return null;
    return {
      ...widget.items[selectedItem],
      tempTitle:
        widget.items[selectedItem]?.product?.name ||
        widget.items[selectedItem]?.product?.displayName ||
        `${I18n.t(`widgets_builder.widget_editor.${widget.subtype}.item_title`)} ${selectedItem + 1}`,
    };
  }, [selectedItem, widget.items, widget.subtype]);

  if (selectedWidgetItem) {
    return (
      <SelectedWidgetItem
        back={() => {
          setSelectedWidgetItem();
        }}
        selectedWidgetItem={selectedWidgetItem}
      >
        <WidgetItemProductPicker selectedWidgetItem={selectedWidgetItem} />
      </SelectedWidgetItem>
    );
  }

  return (
    <div>
      <EditWidgetName />
      <WidgetItemList
        widget={widget}
        addWidgetItem={addWidgetItem}
        editWidgetItems={editWidgetItems}
        onItemSelect={setSelectedWidgetItem}
        deleteWidgetItem={deleteWidgetItem}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomWidgetBuildTab);
