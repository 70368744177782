import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles, useTheme } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import { isArray } from "lodash";
import CfCheckBox from "../../../../../../../components/CfCheckBox";
import CfButton from "../../../../../../../components/CfButton";
import CfProgressBar from "../../../../../../../components/CfProgressBar";
import FunnelSidebarMultipleStepValueSelect from "./FunnelSidebarMultipleStepValueSelect";
import theme from "../../../../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    marginRight: 10,
    marginLeft: "-20px",
    flex: 1,
    overflow: "hidden",
    alignItems: "center",
    paddingBottom: 10,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 10,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    width: 625,
  },
  popper: {
    zIndex: 9999,
  },

  searchField: {
    width: "100%",
    color: "#000",
    height: 33,
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette.divider,
    },
    "& .MuiInput-root": {
      height: "100%",
    },
    "& .MuiInputAdornment-positionStart": {
      margin: 0,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },
  searchIcon: {
    color: theme.palette.primary.dark,
  },
  searchWrap: {
    padding: "0 10px",
  },
  searchInput: {
    padding: 0,
    color: "#000",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
    gap: 3,
    maxHeight: 162,
    width: 625,
    minHeight: 100,
    overflowY: "auto",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10px 20px",
    borderTop: `solid 1px ${theme.palette.divider}`,
  },
  clearButton: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: 0,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
  applyButton: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: "7px 20px",
    borderRadius: 20,
    alignSelf: "flex-end",
  },
  activeButton: {
    color: theme.palette.common.white,
  },
  selectionResult: {
    cursor: "pointer",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    // textTransform: "lowercase",
    overflowX: "hidden",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // "&:first-letter": {
    //   textTransform: "uppercase",
    // },
  },
  selectedResults: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  checkbox: {
    margin: 0,
    cursor: "pointer",
    padding: "7px 10px",
    "&:hover": {
      fill: theme.palette.primary.main,
      backgroundColor: "#E6EEFF",
      "& $checkBoxIcon": {
        fill: theme.palette.primary.main,
      },
    },
  },
  checkboxLabel: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight as any,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    // textTransform: "lowercase",
    // "&:first-letter": {
    //   textTransform: "uppercase",
    // },
  },
  selectedLabel: {
    fill: theme.palette.primary.main,
    backgroundColor: "#E6EEFF",
    "& $checkboxLabel": {
      color: theme.palette.primary.main,
    },
  },
  clearIcon: {
    fontSize: 14,
    marginLeft: "-6px",
    color: "#000",
  },
  saveText: {
    cursor: "pointer",
  },
  loading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 30,
  },
  selectedCheckboxIcon: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  },
  checkBoxIcon: {},
  active: {
    color: theme.palette.primary.main,
  },
  noResults: {
    padding: "10px 10px",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(14),
  },
  addButton: {
    marginTop: 10,
    width: "100%",
    borderRadius: 0,
  },
}));

type PropTypes = {
  placeholder?: string;
  multipleOptions?: boolean;
  options: any[];
  value: any;
  optionLabel?: string;
  optionValue?: string;
  handleChange: (value: any) => void;
  titleClassName?: string;
  searchText?: string;
  setSearchText?: (value: string) => void;
  loading?: boolean;
  condition?: string;
};

export const HighlightText = ({ text, highlight, className }) => {
  if (!highlight)
    return (
      <Tooltip title={text} placement="bottom" style={{ zIndex: 1000 }}>
        <Typography className={className}>{text}</Typography>
      </Tooltip>
    );
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  return (
    <Tooltip title={text} placement="bottom" style={{ zIndex: 1000 }}>
      <Typography className={className}>
        {parts.map((part, index) =>
          highlight.includes(part.toLowerCase()) ? (
            <span key={index} style={{ color: "#4353FF", fontWeight: theme.typography.fontWeightBold }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </Typography>
    </Tooltip>
  );
};

const FunnelSidebarStepValueSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    options,
    optionLabel = "label",
    optionValue = "value",
    multipleOptions = false,
    placeholder = I18n.t("common.select_placeholder"),
    loading = false,
    setSearchText,
    searchText,
    value,
    handleChange,
    condition,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(value);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const filteredOptions = options;
  if (condition === "isSet" || condition === "isNotSet") {
    return null;
  }
  const isMultiple = condition === "is" || condition === "isNot";

  if (isMultiple) {
    return (
      <FunnelSidebarMultipleStepValueSelect
        options={options}
        value={value && isArray(value) ? value : []}
        handleChange={handleChange}
        placeholder={placeholder}
        searchText={searchText}
        setSearchText={setSearchText}
      />
    );
  }

  return (
    <div className={classes.wrapper}>
      <Tooltip title={anchorEl ? "" : value?.[optionLabel] || selected?.[optionLabel]} placement="bottom">
        <Typography
          onClick={(event) => handleClick(event)}
          variant={"body1"}
          className={ClassNames(
            classes.selectionResult,
            value?.[optionValue] && classes.selectedResults,
            anchorEl && classes.active
          )}
          color={"textPrimary"}
        >
          {value?.[optionLabel] || selected?.[optionLabel] || placeholder}
        </Typography>
      </Tooltip>
      <Popper open={open} anchorEl={anchorEl} transition placement={"bottom-start"} className={classes.popper}>
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
            setSelected(value);
          }}
        >
          <div className={classes.paper}>
            <div className={classes.searchWrap}>
              <TextField
                type="search"
                variant={"standard"}
                autoFocus
                value={searchText}
                className={classes.searchField}
                placeholder={"Search value.."}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && searchText) {
                    handleChange({ [optionValue]: searchText, [optionLabel]: searchText });
                    setAnchorEl(null);
                  }
                }}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                InputProps={{
                  classes: { input: classes.searchInput },
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.content}>
              {!loading &&
                filteredOptions &&
                filteredOptions.length > 0 &&
                filteredOptions.map((option, index) => (
                  <div
                    key={`${option[optionValue]}-${index}`}
                    className={ClassNames(
                      classes.checkbox,
                      value?.[optionValue] === option[optionValue] && classes.selectedLabel
                    )}
                    onClick={() => {
                      handleChange(option);
                      setAnchorEl(null);
                    }}
                  >
                    <HighlightText
                      text={option[optionLabel]}
                      highlight={searchText}
                      className={classes.checkboxLabel}
                    />
                  </div>
                ))}
              {loading && (
                <div className={classes.loading}>
                  <CfProgressBar size={20} />
                </div>
              )}
              {!loading && filteredOptions && filteredOptions.length === 0 && searchText && (
                <Typography className={classes.noResults} color={"textPrimary"}>
                  {I18n.t("common.no_results")}
                </Typography>
              )}
            </div>
            <div className={classes.buttons}>
              <CfButton
                height={30}
                disabled={!searchText}
                customClass={ClassNames(classes.applyButton)}
                text={I18n.t("common.apply")}
                onClick={() => {
                  if (!loading && searchText) {
                    handleChange({ [optionValue]: searchText, [optionLabel]: searchText });
                    setAnchorEl(null);
                  }
                }}
              />
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default FunnelSidebarStepValueSelect;
