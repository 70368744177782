import * as React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { Typography } from "@material-ui/core";
import { replace } from "connected-react-router";
import { useState } from "react";
import { IAdminUser, IOutline, IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import { OUTLINE } from "../../../reducers/constants/actionTypes";
import MButtonGroup from "../../../components/MButtonGroup";
import { web } from "../../../helpers/urlHelper";
import MSelect from "../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  bottomBar: {
    minHeight: 60,
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderTop: `${theme.palette.divider} solid 1px`,
    display: "flex",
    flexDirection: "row",
    bottom: 0,
    position: "sticky",
    zIndex: 1,
    padding: "0 20px",
  },
  leftContainer: {
    maxWidth: 350,
    width: "100%",
    height: "100%",
  },
  rightContainer: {
    maxWidth: 1050,
    width: "100%",
    height: "100%",
    paddingRight: 60,
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
  },
  button: {
    height: 42,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    padding: "12px 25px",
    borderRadius: 75,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  select: {
    marginRight: 30,
  },
  writersWrapper: {
    display: "flex",
    alignItems: "center",
  },
  writersText: {
    marginRight: 15,
    fontSize: theme.typography.pxToRem(16),
  },
}));

type PropTypes = {
  outlineId: string;
  view: "DATA_VIEW" | "EDITOR_VIEW";
  outline: IOutline;

  onOutlinePageUpdated: (outlinePage) => void;
  returnToOutlinePagesList: (view) => void;
  cleanUp: () => void;
};

const mapStateToProps = (state) => ({
  outline: state.outline.outline,
});

const mapDispatchToProps = (dispatch) => ({
  onOutlinePageUpdated: (outline) => {
    dispatch({ type: OUTLINE.ON_OUTLINE_PAGE_CHANGED, outline });
  },
  cleanUp: () => dispatch({ type: OUTLINE.OUTLINE_PAGE_CLEANUP }),
  returnToOutlinePagesList: (view) => {
    if (view === "DATA_VIEW") {
      dispatch(replace(web.outlines()));
    } else if (view === "EDITOR_VIEW") {
      dispatch(replace(web.workflowOutlines()));
    }
  },
});

const OutlineBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const { outlineId, view, outline } = props;
  const { onOutlinePageUpdated, returnToOutlinePagesList, cleanUp } = props;
  const [writers, setWriters] = useState([]);
  const [selectedWriter, setSelectedWriter] = useState<IAdminUser>(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingPost, setLoadingPost] = React.useState(false);

  React.useEffect(() => {
    if (!outline) {
      return;
    }
    console.log("getWriters", outline.website, outline.postType);
    agent.Writers.getWritersV2(outline.website, outline.postType, outline.locale)
      .then((res) => {
        setWriters(res.writers);
      })
      .catch((e) => {});
  }, [outline.postType, outline.locale]);

  const updateOutline = (newState, createPost = false, exitOnSave = false) => {
    console.log("updateOutline", exitOnSave);
    if (!outline.dueDate) {
      enqueueSnackbar(I18n.t("snackbar.outline_field_required", { field: "Due Date" }));

      return;
    }
    setLoading(true);
    console.log("OutlineBottomBar updateOutline", { ...outline, state: newState });
    agent.Outlines.update({ ...outline, state: newState })
      .then((res) => {
        if (createPost) {
          setLoadingPost(true);
          agent.Outlines.createPage(outlineId, selectedWriter?._id, outline.postType)
            .then((response) => {
              enqueueSnackbar(I18n.t("snackbar.post.save.post_saved_success"));
              console.log("exitOnSave", exitOnSave);
              if (exitOnSave) {
                returnToOutlinePagesList(view);
              }
              setLoadingPost(false);
            })
            .catch((e) => {
              enqueueSnackbar(I18n.t("snackbar.save.update_error", { msg: e.message }));
              setLoadingPost(false);
            });
          return;
        }
        onOutlinePageUpdated(res.outline);
        enqueueSnackbar(I18n.t("snackbar.outline_saved_success"));
        setLoading(false);
        if (exitOnSave) {
          returnToOutlinePagesList(view);
        }
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
        setLoading(false);
      });
  };

  const saveOutlineDraft = () => {
    if (view === "DATA_VIEW" && (outline.state === "NEW" || outline.state === "DRAFT")) {
      updateOutline("DRAFT", false, false);
    } else if (view === "EDITOR_VIEW" && outline.state === "REVIEW") {
      updateOutline("REVIEW", false, false);
    }
  };

  const saveAndSubmitOutline = () => {
    if (!outline.category) {
      enqueueSnackbar(I18n.t("snackbar.outline_field_required", { field: "Category" }));
      return;
    }
    if (view === "DATA_VIEW" && (outline.state === "NEW" || outline.state === "DRAFT")) {
      updateOutline("REVIEW", false, true);
    } else if (view === "EDITOR_VIEW" && outline.state === "REVIEW") {
      updateOutline("PUBLISHED", true, true);
    }
  };

  return (
    <div className={classes.bottomBar}>
      <div className={classes.leftContainer} />
      <div className={classes.rightContainer}>
        {view === "EDITOR_VIEW" && (
          <div className={classes.writersWrapper}>
            <Typography variant={"body2"} className={classes.writersText}>
              {I18n.t("outline.writers_text")}
            </Typography>
            <MSelect
              customClass={classes.select}
              options={[{ _id: 0, name: "Any Writer" }, ...writers]}
              value={selectedWriter || { _id: 0, name: "All Writers" }}
              optionLabel={"name"}
              optionValue={"_id"}
              height={42}
              borderRadius={0}
              placeholder={I18n.t("outline.writer_ph")}
              menuPlacement={"top"}
              handleChange={(value) => {
                if (value.name === "All Writers") {
                  setSelectedWriter(null);
                  return;
                }
                setSelectedWriter(value);
              }}
            />
          </div>
        )}
        {outlineId && (
          <MButtonGroup
            loading={loading || loadingPost}
            text={view === "EDITOR_VIEW" ? I18n.t("outline.submit_to_writers") : I18n.t("outline.submit_for_review")}
            onClick={(e) => {
              saveAndSubmitOutline();
            }}
            options={[
              {
                key: "saveAndSubmitOutline",
                label:
                  view === "EDITOR_VIEW" ? I18n.t("outline.submit_to_writers") : I18n.t("outline.submit_for_review"),
              },
              {
                key: "saveOutlineDraft",
                label: I18n.t("outline.save_draft"),
              },
            ]}
            onMenuItemClick={(key: string) => {
              switch (key) {
                case "saveOutlineDraft":
                  return saveOutlineDraft();
                case "saveAndSubmitOutline":
                  return saveAndSubmitOutline();
                default:
                  return null;
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlineBottomBar);
