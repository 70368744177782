import React from "react";
import ClassNames from "classnames";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  tabsWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  tabRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "initial" as const,
    minHeight: 50,
    borderBottom: "3px solid transparent",
    margin: "0 15px",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
    paddingRight: 10,
    paddingLeft: 10,
    "&:hover": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "&:focus": {
      color: theme.palette.primary.main,
    },
  },
  tabSelected: (props: PropTypes) => ({
    color: `${props.activeColor ? props.activeColor : theme.palette.primary.main} !important`,
    fontWeight: theme.typography.fontWeightBold as any,
    borderColor: props.activeBorderColor ? props.activeBorderColor : "transparent",
  }),
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

type PropTypes = {
  tabs: {
    url: string;
    label: string;
  }[];
  activeBorderColor?: string;
  activeColor?: string;
  tabsWrapperClass?: string;
  tabsRootClass?: string;
};

const MTabs = (props: PropTypes) => {
  const classes = useStyles(props);
  const { tabs, tabsWrapperClass = null, tabsRootClass = null } = props;

  return (
    <div className={ClassNames(classes.tabsWrapper, tabsWrapperClass)}>
      {tabs.map((tab) => (
        <NavLink
          key={tab.url}
          to={tab.url}
          activeClassName={classes.tabSelected}
          className={ClassNames(classes.tabRoot, tabsRootClass)}
        >
          <p className={classes.text}>{tab.label}</p>
        </NavLink>
      ))}
    </div>
  );
};

export default MTabs;
