import React from "react";
import ClassNames from "classnames";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";

const useStyles = makeStyles((theme: Theme) => ({
  rowWrapper: {
    maxWidth: "unset",
    marginTop: 0,
    marginBottom: (props: PropTypes) => props.marginBottom || 0,
    marginLeft: 0,
    marginRight: 0,
    display: "flex",
    flexDirection: "row",
  },
  columnWrapper: {
    maxWidth: "unset",
    marginTop: 0,
    marginBottom: (props: PropTypes) => props.marginBottom || 0,
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  titleAndLengthColumnWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    minWidth: 140,
    marginRight: 15,
  },
  titleAndLengthRowWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: 10,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 5,
  },
  titleOnTop: {
    marginRight: 10,
    marginBottom: 0,
  },
  jsonWrapper: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
  },
}));

type PropTypes = {
  title?: string;
  height?: string | number;
  width?: string | number;
  titleColor?: string;
  value: object;
  wrapperClassName?: string;
  marginBottom?: string | number;

  titleAndLengthLocation?: "left" | "top";

  handleValueChange: (json: object) => void;
};

const TextFieldJSON = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    value = {},
    title = null,
    wrapperClassName = null,
    width = "100%",
    height = "200px",
    titleAndLengthLocation = "top",
    handleValueChange,
  } = props;

  return (
    <div
      className={ClassNames(
        titleAndLengthLocation === "left" && classes.rowWrapper,
        titleAndLengthLocation === "top" && classes.columnWrapper,
        wrapperClassName
      )}
    >
      {title && (
        <div
          className={ClassNames(
            titleAndLengthLocation === "left" && classes.titleAndLengthColumnWrapper,
            titleAndLengthLocation === "top" && classes.titleAndLengthRowWrapper
          )}
        >
          <Typography
            className={ClassNames(classes.title, titleAndLengthLocation === "top" && classes.titleOnTop)}
            variant={"subtitle2"}
          >
            {title || ""}
          </Typography>
        </div>
      )}
      <div className={classes.jsonWrapper}>
        <JSONInput
          style={{
            container: {
              borderRadius: 4,
            },
          }}
          waitAfterKeyPress={3000}
          placeholder={value} // data to display
          theme="light_mitsuketa_tribute"
          locale={locale}
          onChange={(obj) => {
            if (obj.error) {
              return;
            }
            console.log("onChange", obj);
            handleValueChange(obj.jsObject);
          }}
          colors={
            {
              // background: "white"
            }
          }
          width={width}
          height={height}
        />
      </div>
    </div>
  );
};

export default TextFieldJSON;
