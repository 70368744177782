import React, { useEffect, useMemo } from "react";
import { I18n } from "react-redux-i18n";
import { Button, Theme, Typography } from "@material-ui/core";
import { FormatOptionLabelMeta } from "react-select";
import { cloneDeep, isEqual, omit } from "lodash";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { nanoid } from "nanoid";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { useSnackbar } from "notistack";
import { InfoOutlined } from "@material-ui/icons";

import { IGoal, IGoalType, IProduct, IWebsite } from "../../../../reducers/constants/objectTypes";
import ModalInputField from "../../components/ModalInputField";
import MTextFieldV2 from "../../../../components/MTextFieldV2";
import MSelect from "../../../../componentsV2/MSelect";
import agent from "../../../../agent";
import CurrencyPickerElement from "../../../../editor/elementsStyles/ElementComponents/CurrencyPickerElement";
import useDebounce from "../../../../hooks/useDebounce";
import CfProgressBar from "../../../../components/CfProgressBar";
import MEvent from "../../../../components/events/MEvent";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: 620,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  descriptionWrapper: {
    display: "flex",
    alignItems: "flex-start",
    gap: 7,
    marginBottom: 25,
    "& svg": {
      fontSize: 17,
    },
    "& p": {
      fontSize: 14,
      lineHeight: "normal",
      fontWeight: theme.typography.fontWeightRegular as any,
    },
  },
  innerWrapper: {
    height: "100%",
    padding: 24,
    display: "flex",
    flexDirection: "column",
    gap: 2,
    overflowY: "auto",
  },
  optionWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    paddingBlock: 7,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#E6EEFF",
      "& $optionTitle": {
        color: theme.palette.primary.main,
      },
    },
  },
  optionSelected: {
    backgroundColor: "#E6EEFF",
    "& $optionTitle": {
      color: theme.palette.primary.main,
    },
  },
  optionTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
  },
  optionTitleAddNew: {
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  optionDescription: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    lineHeight: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  optionDescriptionTwoLineLimit: {
    lineClamp: 2,
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  optionDescriptionAddNew: {
    fontSize: 14,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  divider: {
    width: "100%",
    height: 25,
  },
  valueWrapper: {
    display: "flex",
    gap: 3,
    "& > div:first-child": {
      width: "60%",
    },
    "& > div:nth-child(2), & > div:nth-child(3)": {
      width: "20%",
    },
  },
  valueWrapperFullWidth: {
    "& > div:first-child": {
      width: "100%",
    },
  },
  editGoalWrapper: {
    display: "flex",
    gap: 5,
    alignItems: "center",
  },
  editGoal: {
    fontSize: 14,
    lineHeight: "normal",
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightLight as any,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.light,
    },
  },
  editGoalPh: {
    fontSize: 14,
    lineHeight: "normal",
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  description: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: theme.typography.fontWeightLight as any,
  },
  buttonWrapper: {
    padding: "12px 24px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: `${theme.palette.divider} solid 1px`,
  },
  button: {
    width: "fit-content",
    padding: "0px 20px",
    height: 36,
    borderRadius: 20,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    transition: "none",
    textTransform: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      color: "white",
      backgroundColor: theme.palette.primary.dark,
    },
  },
  loadingWrapper: {
    width: 620,
    height: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type IGoalOption = {
  label: string;
  value?: IGoal;
  addNew?: boolean;
  rename?: boolean;
};

type IGoalTypeOption = {
  label: string;
  value?: IGoalType;
};

type PropTypes = {
  website: IWebsite;
  product: IProduct;
  updateProduct: (product: IProduct) => void;
};

const MarketingGoalView = (props: PropTypes) => {
  const classes = useStyles();
  const { website, product } = props;
  const [draftGoal, setDraftGoal] = React.useState<IGoal | null>(
    product.goal
      ? {
          ...cloneDeep(product.goal),
          goalId: product.goal?._id,
        }
      : ({} as IGoal)
  );
  const [loading, setLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [goals, setGoals] = React.useState([]);
  const [goalOptions, setGoalOptions] = React.useState([]);
  const [goalTypes, setGoalTypes] = React.useState([]);
  const [hasExistingGoals, setHasExistingGoals] = React.useState(false);
  const [editProductMode, setEditProductMode] = React.useState(false);
  const [eventNames, setEventNames] = React.useState([]);

  const isInitialRender = React.useRef(true);
  const { enqueueSnackbar } = useSnackbar();

  const hasChanges = useMemo(
    () => !isEqual(omit(product.goal, ["goalId"]), omit(draftGoal, ["goalId"])),
    [product.goal, draftGoal]
  );

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);

        const [goalTypes, goals] = await Promise.all([
          agent.Goal.getGoalTypes(),
          agent.Goal.autoComplete(website?._id, ""),
        ]);

        setGoalTypes(goalTypes.goalTypes);
        setGoals(goals.goals);

        if (goals.goals.length > 0) {
          setHasExistingGoals(true);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    loadInitialData();
  }, []);

  useEffect(() => {
    agent.Funnels.getColumnValues(website, "events", "name", null, 1, 500)
      .then((res) => {
        setEventNames(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    agent.Goal.autoComplete(website?._id, debouncedSearch)
      .then((response) => {
        setGoals(response.goals);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [debouncedSearch, website]);

  useEffect(() => {
    setGoalOptions((prev) => [
      // insert draftGoal if its not in the list
      ...(!search && !draftGoal?._id && draftGoal?.goalId && !prev.find((o) => o.value?.goalId === draftGoal?.goalId)
        ? [
            {
              label: draftGoal.name,
              value: draftGoal,
            },
          ]
        : []),

      // insert remote draftGoal if its not in the list
      ...(!search && draftGoal?._id && !goals.find((g) => g._id === draftGoal._id)
        ? [
            {
              label: draftGoal.name,
              value: draftGoal,
            },
          ]
        : []),

      // make sure to always have the latest draftGoal value in the list
      ...goals.map((goal: IGoal) =>
        draftGoal?._id && draftGoal?._id === goal._id
          ? {
              label: draftGoal.name,
              value: draftGoal,
            }
          : {
              label: goal.name,
              value: {
                ...goal,
                goalId: nanoid(8),
              },
            }
      ),

      // add the unsaved goals
      ...prev
        .filter((option) => !option.value?._id && !option.addNew && !option.rename)
        .map((option) =>
          draftGoal?.goalId && option.value?.goalId === draftGoal?.goalId
            ? {
                label: draftGoal.name,
                value: draftGoal,
              }
            : option
        ),

      // add the options to add new goal and rename goal
      ...(search
        ? [
            {
              label: "",
              value: {},
              addNew: true,
            },
          ]
        : []),
      ...(search && draftGoal?.goalId
        ? [
            {
              label: "",
              value: {},
              rename: true,
            },
          ]
        : []),
    ]);
  }, [goals, search, draftGoal]);

  const handleGoalAction = async (action: "update" | "create") => {
    setIsSubmitting(true);

    try {
      const { goal } = await agent.Goal[action](draftGoal, website._id);
      const res = await agent.Product.update({ ...product, goal });
      props.updateProduct(res.product);
      const newDraftGoal = {
        ...draftGoal,
        ...res.product.goal,
      };

      setDraftGoal(newDraftGoal);
      setGoals((prev) => {
        if (action === "create") {
          return [...prev, newDraftGoal];
        }

        return prev.map((g) => (g._id === newDraftGoal._id ? newDraftGoal : g));
      });
      enqueueSnackbar(I18n.t("snackbar.update_success"));
    } catch (err) {
      enqueueSnackbar(I18n.t("snackbar.update_error", { msg: err.msg || err.message }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoalChange = (option: IGoalOption) => {
    const goal = option.value;

    if (goal._id) {
      setEditProductMode(false);
    } else {
      setEditProductMode(true);
    }

    if (!option.addNew && !option.rename) {
      setDraftGoal(goal);
      return;
    }

    if (option.rename) {
      setDraftGoal({
        ...draftGoal,
        name: search,
      });
      setSearch("");
      return;
    }

    const newGoal = {
      name: search,
      goalId: nanoid(8),
      goalTracking: "rule",
      events: [],
    } as IGoal;

    setDraftGoal(newGoal);
    setSearch("");
  };

  const formatGoalOptionLabel = (option: IGoalOption, meta: FormatOptionLabelMeta<IGoalOption>) => {
    if (meta.context === "value") {
      return option.label;
    }

    if (option.addNew) {
      return (
        <div className={classes.optionWrapper}>
          <div className={classNames(classes.optionTitle, classes.optionTitleAddNew)}>
            {I18n.t(`product_dialog.marketing_goal_view.${hasExistingGoals ? "create_new_goal" : "create_first_goal"}`)}
          </div>
          <div className={classNames(classes.optionTitle, classes.optionDescriptionAddNew)}>"{meta.inputValue}"</div>
        </div>
      );
    }

    if (option.rename) {
      return (
        <div className={classes.optionWrapper}>
          <div className={classNames(classes.optionTitle, classes.optionTitleAddNew)}>
            {I18n.t("product_dialog.marketing_goal_view.rename_goal")}
          </div>
          <div className={classNames(classes.optionTitle, classes.optionDescriptionAddNew)}>"{meta.inputValue}"</div>
        </div>
      );
    }

    return (
      <div className={classes.optionWrapper}>
        <div className={classes.optionTitle}>{option.label}</div>
        <div className={classNames(classes.optionDescription, classes.optionDescriptionTwoLineLimit)}>
          {option.value?.description}
        </div>
      </div>
    );
  };

  const formatGoalTypeOptionLabel = (option: IGoalTypeOption, meta: FormatOptionLabelMeta<IGoalOption>) => {
    if (meta.context === "value") {
      return option.label;
    }

    return (
      <div className={classes.optionWrapper}>
        <div className={classes.optionTitle}>{option.label}</div>
        <div className={classNames(classes.optionDescription, classes.optionDescriptionTwoLineLimit)}>
          {option.value?.description}
        </div>
      </div>
    );
  };

  const formatValueTypeOptionLabel = (option: IValueTypeOption, meta: FormatOptionLabelMeta<IGoalOption>) => {
    if (meta.context === "value") {
      return option.label;
    }

    return (
      <div className={classes.optionWrapper}>
        <div className={classes.optionTitle}>{option.label}</div>
        <div className={classes.optionDescription}>{option.description}</div>
      </div>
    );
  };

  const goalTypesOptions = goalTypes.map((goalType: IGoalType) => {
    return {
      label: goalType.name,
      value: goalType,
    };
  });

  const valueTypeOptions = [
    {
      label: "Estimated value",
      value: "estimated",
      description:
        "Set a custom value for the event when the exact value cannot be determined from analytics data (e.g., demo bookings, lead generation).",
    },
    {
      label: "Transaction value",
      value: "transaction",
      description:
        "Use this option when the exact value is captured from your analytics data (e.g. online purchases where the transaction amount is defined)",
    },
  ] as const;

  type IValueTypeOption = (typeof valueTypeOptions)[number];

  const goalTrackingOptions = [
    {
      value: "rule",
      label: "Event rules",
    },
    {
      value: "code",
      label: "Code",
    },
  ] as const;

  type IGoalTrackingOption = (typeof goalTrackingOptions)[number];

  console.log("goalOptions", goalOptions);

  useEffect(() => {
    console.log("draftGoal updated", draftGoal, product.goal);
  }, [draftGoal, product.goal]);

  if (loading && !draftGoal?._id) {
    return (
      <div className={classes.loadingWrapper}>
        <CfProgressBar />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <div className={classes.descriptionWrapper}>
          <InfoOutlined />
          <Typography variant={"body1"}>{I18n.t(`product_dialog.marketing_goal_view.view_description`)}</Typography>
        </div>
        <ModalInputField title={I18n.t("product_dialog.marketing_goal_view.select_create_goal")}>
          <MSelect
            placeholder={I18n.t("product_dialog.marketing_goal_view.select_create_ph")}
            options={goalOptions}
            isSearchable
            formatOptionLabel={formatGoalOptionLabel}
            onInputChange={(inputValue) => setSearch(inputValue)}
            filterOption={(option: FilterOptionOption<IGoalOption>, input: string) => {
              if (!input || !option.data.value?.goalId) {
                return true;
              }

              return option.data.label.toLowerCase().includes(input.toLowerCase());
            }}
            value={goalOptions.find(
              (option) =>
                (draftGoal?.goalId && option.value?.goalId === draftGoal?.goalId) ||
                (option.value?._id && option.value?._id === draftGoal?._id)
            )}
            isOptionDisabled={(option: IGoalOption) => !option.value?.goalId && !search}
            onChange={(option: IGoalOption) => handleGoalChange(option)}
          />
        </ModalInputField>
        {draftGoal?._id && (
          <ModalInputField title={""}>
            <div className={classes.editGoalWrapper}>
              {!editProductMode && (
                <Typography variant={"body1"} className={classes.editGoal} onClick={() => setEditProductMode(true)}>
                  {I18n.t("product_dialog.marketing_goal_view.edit_goal")}
                </Typography>
              )}
              <Typography variant={"body1"} className={classes.editGoalPh}>
                {I18n.t("product_dialog.marketing_goal_view.edit_goal_ph")}
              </Typography>
            </div>
          </ModalInputField>
        )}
        <ModalInputField title="">
          <div className={classes.divider}></div>
        </ModalInputField>
        <ModalInputField title={I18n.t("product_dialog.marketing_goal_view.description")} disabled={!editProductMode}>
          <MTextFieldV2
            disabled={!editProductMode}
            value={draftGoal?.description}
            placeholder={I18n.t("product_dialog.marketing_goal_view.description_ph")}
            onValueChange={(description) => setDraftGoal({ ...draftGoal, description })}
          />
        </ModalInputField>
        <ModalInputField title={I18n.t("product_dialog.marketing_goal_view.goal_type")} disabled={!editProductMode}>
          <MSelect
            isDisabled={!editProductMode}
            placeholder={I18n.t("product_dialog.marketing_goal_view.select_goal_type_ph")}
            options={goalTypesOptions}
            formatOptionLabel={formatGoalTypeOptionLabel}
            value={goalTypesOptions.find((option: IGoalTypeOption) => option.value?._id === draftGoal?.goalType?._id)}
            onChange={(option: IGoalTypeOption) => setDraftGoal({ ...draftGoal, goalType: option.value })}
          />
        </ModalInputField>
        <div
          className={classNames(
            classes.valueWrapper,
            draftGoal?.value?.type === "transaction" && classes.valueWrapperFullWidth
          )}
        >
          <ModalInputField title={I18n.t("product_dialog.marketing_goal_view.value")} disabled={!editProductMode}>
            <MSelect
              menuPortalWidth={417}
              isDisabled={!editProductMode}
              placeholder={I18n.t("product_dialog.marketing_goal_view.select_value_type_ph")}
              options={valueTypeOptions as unknown as Record<string, any>[]}
              formatOptionLabel={formatValueTypeOptionLabel}
              value={valueTypeOptions.find((option: IValueTypeOption) => option.value === draftGoal?.value?.type)}
              onChange={(option: IValueTypeOption) =>
                setDraftGoal({ ...draftGoal, value: { ...draftGoal.value, type: option.value } })
              }
              defaultValue={valueTypeOptions[0]}
            />
          </ModalInputField>
          {draftGoal?.value?.type !== "transaction" && (
            <>
              <CurrencyPickerElement
                isDisabled={!editProductMode}
                isSearchable
                value={draftGoal?.value?.currency}
                placeholder={I18n.t("product_dialog.marketing_goal_view.select_currency_ph")}
                onChange={(currency) => setDraftGoal({ ...draftGoal, value: { ...draftGoal.value, currency } })}
                defaultValue={{ code: "USD", symbol: "$", name: "Dollar" }}
              />
              <MTextFieldV2
                disabled={!editProductMode}
                type="number"
                value={draftGoal?.value?.value}
                showNumberControls={false}
                placeholder={I18n.t("product_dialog.marketing_goal_view.set_price_ph")}
                onValueChange={(value) => setDraftGoal({ ...draftGoal, value: { ...draftGoal.value, value: value } })}
              />
            </>
          )}
        </div>
        <ModalInputField title="">
          <div className={classes.divider}></div>
        </ModalInputField>
        <ModalInputField title={I18n.t("product_dialog.marketing_goal_view.goal_tracking")} disabled={!editProductMode}>
          <MSelect
            isDisabled={!editProductMode}
            placeholder={I18n.t("product_dialog.marketing_goal_view.select_goal_tracking_ph")}
            options={goalTrackingOptions as unknown as Record<string, any>[]}
            value={goalTrackingOptions.find((option) => option.value === draftGoal?.goalTracking)}
            defaultValue={goalTrackingOptions[0]}
            onChange={(option: IGoalTrackingOption) => setDraftGoal({ ...draftGoal, goalTracking: option.value })}
          />
        </ModalInputField>
        {draftGoal?.goalTracking !== "code" && (
          <ModalInputField title={I18n.t("product_dialog.marketing_goal_view.event_rules")} disabled={!editProductMode}>
            {(draftGoal?.events?.length > 0
              ? cloneDeep(draftGoal?.events)
              : [
                  {
                    condition: "and",
                    value: "",
                    filters: [
                      {
                        column: "",
                        condition: "is",
                        value: [],
                        field: "",
                        table: "",
                        eventTable: "",
                      },
                    ],
                  },
                ]
            ).map((event, index) => (
              <MEvent
                key={index}
                selectedWebsite={website}
                event={event}
                eventNames={eventNames}
                disabled={!editProductMode}
                handleEventChange={(e) => {
                  const newEvents = [...draftGoal.events];
                  newEvents[index] = e;
                  setDraftGoal({
                    ...draftGoal,
                    events: newEvents,
                  });
                }}
                onClearEvent={() => {
                  const newEvents = [...draftGoal.events];
                  newEvents[index] = {
                    ...newEvents[index],
                    value: null,
                  };
                  setDraftGoal({
                    ...draftGoal,
                    events: newEvents,
                  });
                }}
              />
            ))}
          </ModalInputField>
        )}
      </div>
      <div className={classes.buttonWrapper}>
        <Typography variant={"body1"} className={classes.description}>
          {I18n.t("product_dialog.description")}
        </Typography>
        <Button
          disableRipple
          disableElevation
          disabled={!hasChanges || !draftGoal.name || isSubmitting}
          className={classes.button}
          onClick={() => {
            handleGoalAction(draftGoal?._id ? "update" : "create");
          }}
        >
          {I18n.t(
            `product_dialog.marketing_goal_view.${
              hasChanges && draftGoal?.name
                ? draftGoal?._id && draftGoal?._id === product.goal?._id
                  ? "update_goal"
                  : "save_goal"
                : "save_goal"
            }`
          )}
        </Button>
      </div>
    </div>
  );
};

export default MarketingGoalView;
