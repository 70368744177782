import moment from "moment";
import { findIndex, slice } from "lodash";
import { GSC_QUERIES, QUERY_GROUPS_CONTAINERS } from "./constants/actionTypes";

const initialState = {
  searchConsoleInterval: "last3Months",
  startDate: moment().subtract(3, "month"),
  endDate: moment(),

  refreshItemList: true,

  containersLoaded: false,
  containers: [],

  selectedContainer: null,

  queryGroupsLoaded: false,
  queriesGroups: [],
  queriesGroupsSearchText: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case QUERY_GROUPS_CONTAINERS.ROOT_CONTAINER_LOADED:
      return {
        ...state,
        containersLoaded: true,
        containers: action.payload.container ? [action.payload.container] : [],
      };
    case QUERY_GROUPS_CONTAINERS.CHILD_CONTAINER_LOADED: {
      const index = findIndex(state.containers, (c) => c.id === action.pushAfterId);
      const containers = slice([...state.containers], 0, index + 1);
      containers.push(action.container);
      return {
        ...state,
        containersLoaded: true,
        containers,
        // selectedContainer: action.container
      };
    }
    case QUERY_GROUPS_CONTAINERS.HANDLE_CONTAINER_UPDATED:
      return {
        ...state,
        containers: state.containers.map((c) => {
          if (c.id === action.payload.container.id) {
            return action.payload.container;
          }
          return c;
        }),
        selectedContainer:
          state.selectedContainer?.id === action.payload.container.id
            ? action.payload.container
            : state.selectedContainer,
      };
    case QUERY_GROUPS_CONTAINERS.HANDLE_CONTAINER_LIST_BACK_CLICK: {
      if (state.containers.length <= 1) {
        return state;
      }
      const containers = [...state.containers];
      containers.pop();
      return {
        ...state,
        containers,
        selectedContainer: containers[containers.length - 1],
      };
    }
    case QUERY_GROUPS_CONTAINERS.SELECT_CONTAINER:
      return {
        ...state,
        selectedContainer: action.container,
      };
    case QUERY_GROUPS_CONTAINERS.START_LOADING_QUERY_GROUPS:
      return {
        ...state,
        queryGroupsLoaded: false,
      };
    case QUERY_GROUPS_CONTAINERS.QUERY_GROUPS_START_LOADING:
      return {
        ...state,
        queryGroupsLoaded: false,
      };
    case QUERY_GROUPS_CONTAINERS.QUERY_GROUPS_LOADED:
      return {
        ...state,
        queryGroupsLoaded: true,
        queryGroups: action.payload.queryGroups,
      };
    case QUERY_GROUPS_CONTAINERS.QUERY_GROUPS_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        queriesGroupsSearchText: action.text,
      };
    default:
      return state;
  }
};
