import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { WRITER_BIO } from "../../../../../../reducers/constants/actionTypes";
import TextFieldMaxLength from "../../../../../../components/TextFieldMaxLength";

const useStyles = makeStyles((theme: Theme) => ({
  sectionWrapper: {
    borderRadius: 0,
    border: "none",
    marginTop: 10,
  },
  innerWrapper: {
    padding: 30,
  },
  sectionNameAndSwitchWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "13px 20px",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    textTransform: "capitalize",
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    "&:first-child": {
      marginTop: 0,
    },
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  titleAndHintWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    minWidth: 140,
    marginRight: 15,
  },
  hint: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: 5,
  },
}));

type PropTypes = {
  highlights: {
    active: boolean;
    option1: string;
    option2: string;
    option3: string;
    option4: string;
  };

  onPropertyChanged: (value) => void;
};

const mapStateToProps = (state) => ({
  highlights: state.writerBio.highlights,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (highlights) =>
    dispatch({ type: WRITER_BIO.ON_PROPERTY_CHANGED, name: "highlights", value: highlights }),
});

const HighlightsSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { highlights } = props;
  const { onPropertyChanged } = props;

  return (
    <Paper elevation={0} className={classes.sectionWrapper}>
      <div className={classes.sectionNameAndSwitchWrapper}>
        <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
          {I18n.t("manager.writers_bio.highlights")}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={highlights.active}
                onChange={() => {
                  onPropertyChanged({ ...highlights, active: !highlights.active });
                }}
                color="primary"
              />
            }
            labelPlacement="start"
            label={I18n.t(`manager.writers_bio.${highlights.active ? "active" : "inactive"}`)}
          />
        </FormGroup>
      </div>
      <div className={classes.innerWrapper}>
        <TextFieldMaxLength
          marginLeft={"-70px"}
          value={highlights.option1}
          placeholder={I18n.t("manager.writers_bio.option_ph")}
          title={I18n.t("manager.writers_bio.option_1")}
          maxLength={70}
          height={37}
          borderRadius={4}
          marginTop={20}
          handleValueChange={(option1) => {
            onPropertyChanged({ ...highlights, active: true, option1 });
          }}
        />
        <TextFieldMaxLength
          marginLeft={"-70px"}
          value={highlights.option2}
          placeholder={I18n.t("manager.writers_bio.option_ph")}
          title={I18n.t("manager.writers_bio.option_2")}
          maxLength={70}
          height={37}
          borderRadius={4}
          marginTop={20}
          handleValueChange={(option2) => {
            onPropertyChanged({ ...highlights, active: true, option2 });
          }}
        />
        <TextFieldMaxLength
          marginLeft={"-70px"}
          value={highlights.option3}
          placeholder={I18n.t("manager.writers_bio.option_ph")}
          title={I18n.t("manager.writers_bio.option_3")}
          maxLength={70}
          height={37}
          borderRadius={4}
          marginTop={20}
          handleValueChange={(option3) => {
            onPropertyChanged({ ...highlights, active: true, option3 });
          }}
        />
        <TextFieldMaxLength
          marginLeft={"-70px"}
          value={highlights.option4}
          placeholder={I18n.t("manager.writers_bio.option_ph")}
          title={I18n.t("manager.writers_bio.option_4")}
          maxLength={70}
          height={37}
          borderRadius={4}
          marginTop={20}
          handleValueChange={(option4) => {
            onPropertyChanged({ ...highlights, active: true, option4 });
          }}
        />
      </div>
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HighlightsSection);
