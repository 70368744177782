import React from "react";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import moment from "moment/moment";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { Theme } from "@material-ui/core/styles";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import MTextField from "../../../../components/MTextField";
import CfProgressBar from "../../../../components/CfProgressBar";
import { IFile } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import MTextButton from "../../../../components/MTextButton";
import { getImageCDNUrl } from "../../../../helpers/urlHelper";
import profile_image_ph from "../../../../img/profile_image_ph.svg";

const useStyles = makeStyles((theme: Theme) => ({
  imageDetailsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  url: {
    fontSize: 14,
    marginTop: 20,
  },
  fileName: {
    fontSize: 14,
    wordWrap: "break-word",
  },
  fileDetails: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    marginTop: 13,
  },
  deleteButton: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    color: "#F04444",
    padding: 0,
    minHeight: "unset",
    marginTop: 13,
  },
  textFieldsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: 40,
  },
  editDetailsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tooltip: {
    maxWidth: "none",
    color: theme.palette.text.primary,
    backgroundColor: "white",
    borderRadius: 3,
    boxShadow: "-2px 0px 5px 0px rgba(0, 0, 0, 0.10), 0px 0px 10px 0px rgba(0, 0, 0, 0.05)",
    padding: 10,
    lineHeight: 1,
    marginTop: 10,
  },
  fieldLabel: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    position: "relative",
  },
  questionMark: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginTop: -5,
    position: "absolute",
    top: 1,
    right: -8,
    cursor: "pointer",
  },
  customClass: {
    width: 361,
    margin: 0,
  },
  inputClass: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "8px 15px",
  },
  footerErrorText: {
    fontSize: 12,
    margin: "auto",
    color: "red",
    textAlign: "center",
    marginBottom: 5,
  },
  aiGenerationLoader: {
    marginRight: 10,
  },
  aiGenerationText: {
    fontSize: 12,
    margin: "auto",
    color: theme.palette.primary.main,
    textAlign: "center",
    marginBottom: 5,
    display: "flex",
    justifyContent: "center",
  },
  errorMessage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
    fontSize: 12,
    marginTop: 15,
    color: "#F04444",
  },
}));

type PropTypes = {
  selectedFile: IFile;
  files: IFile[];
  AIAltGeneration: boolean;
  error: boolean;
  title: string;
  alt: string;
  caption: string;
  forcesImageSize: {
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
    ratio?: number;
  };
  validImage: boolean;
  setSelectedFile: (selectedFile) => void;
  setFiles: (files) => void;
  setTitle: (title) => void;
  setAlt: (alt) => void;
  setCaption: (caption) => void;
};
const ImageDetails = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedFile, files, AIAltGeneration, error, title, alt, caption, validImage, forcesImageSize } = props;
  const { setSelectedFile, setFiles, setTitle, setAlt, setCaption } = props;
  const theme: any = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const save = () => {
    agent.Files.updateDetails({
      ...selectedFile,
      alt: alt !== null ? alt : selectedFile.alt,
      caption: caption !== null ? caption : selectedFile.caption,
      title: title !== null ? title : selectedFile.title,
    })
      .then((res) => {
        const { file } = res;
        setSelectedFile(file);
        setFiles(files.map((f) => (f._id === file._id ? file : f)));
        setAlt(null);
        setCaption(null);
        setTitle(null);
        enqueueSnackbar(I18n.t(`rich_text_editor.insert_image_dialog.snackbar.save`));
      })
      .catch((err) => {
        console.log("Error:", err);
        enqueueSnackbar(I18n.t(`rich_text_editor.insert_image_dialog.snackbar.error`));
      });
  };

  return (
    <div className={classes.imageDetailsWrapper}>
      {!selectedFile && forcesImageSize && !validImage && (
        <>
          <img src={profile_image_ph} alt={"Image placeholder"} />
          <Typography variant={"body1"} className={classes.errorMessage}>
            <ErrorOutlineRoundedIcon />
            {I18n.t("rich_text_editor.insert_image_dialog.image_range_info", {
              minWidth: forcesImageSize.minWidth,
              minHeight: forcesImageSize.minHeight,
              maxWidth: forcesImageSize.maxWidth,
              maxHeight: forcesImageSize.maxWidth,
            })}
          </Typography>
        </>
      )}
      {selectedFile && (
        <>
          <img src={getImageCDNUrl(selectedFile.cdnUrl)} style={{ width: "100%" }} />
          <Typography className={classes.url} variant={"body1"}>
            {I18n.t("rich_text_editor.insert_image_dialog.image_url")}:
            <a
              target={"_blank"}
              href={selectedFile.cdnUrl}
              style={{
                marginLeft: 5,
                textDecoration: "none",
                wordBreak: "break-all",
              }}
            >
              {`${selectedFile.fileName}.${selectedFile.extType}`}
            </a>
          </Typography>
          <div className={classes.fileDetails}>
            <Typography className={classes.fileName} variant={"body1"}>
              {I18n.t("rich_text_editor.insert_image_dialog.image_filename")}: &nbsp;
              {selectedFile.fileName}.{selectedFile.extType}
            </Typography>
            <Typography variant={"body2"}>{moment(selectedFile.createdAt).format("LL")}</Typography>
            <Typography variant={"body2"}>{numeral(selectedFile.size).format("0.0 b")}</Typography>
            <Typography variant={"body2"}>
              {`${selectedFile.width} x ${selectedFile.height} ${I18n.t(
                "rich_text_editor.insert_image_dialog.px_text"
              )}`}
            </Typography>
          </div>
          <MTextButton text={"Delete Image"} customClass={classes.deleteButton} onClick={() => setSelectedFile(null)} />
          <div className={classes.textFieldsWrapper}>
            <div className={classes.editDetailsWrapper}>
              <Tooltip
                enterDelay={500}
                title={I18n.t(`rich_text_editor.tooltips.image_title`)}
                classes={{ tooltip: classes.tooltip }}
                placement="top-start"
              >
                <Typography className={classes.fieldLabel} variant={"body2"}>
                  {I18n.t("rich_text_editor.insert_image_dialog.image_title")}
                  <span className={classes.questionMark}>?</span>
                </Typography>
              </Tooltip>
              <MTextField
                outlined
                borderColor={error && !(title || selectedFile.title) ? "red" : theme.palette.divider}
                customClass={classes.customClass}
                inputClassName={classes.inputClass}
                borderRadius={4}
                onChange={(text) => setTitle(text)}
                onBlur={() => save()}
                value={title !== null ? title : selectedFile.title}
                placeholder={I18n.t("rich_text_editor.insert_image_dialog.image_title_placeholder")}
              />
            </div>

            {error && !(title || selectedFile.title) && (
              <Typography className={classes.footerErrorText}>
                {I18n.t("rich_text_editor.insert_image_dialog.insert_error_title")}
              </Typography>
            )}
            <div className={classes.editDetailsWrapper}>
              <Tooltip
                enterDelay={500}
                title={I18n.t(`rich_text_editor.tooltips.image_alt`)}
                classes={{ tooltip: classes.tooltip }}
                placement="top-start"
              >
                <Typography className={classes.fieldLabel} variant={"body2"}>
                  {I18n.t("rich_text_editor.insert_image_dialog.image_alt")}
                  <span className={classes.questionMark}>?</span>
                </Typography>
              </Tooltip>
              <MTextField
                outlined
                disabled={AIAltGeneration}
                customClass={classes.customClass}
                inputClassName={classes.inputClass}
                borderColor={error && !(alt || selectedFile.alt) ? "red" : theme.palette.divider}
                borderRadius={4}
                onChange={(text) => setAlt(text)}
                value={alt !== null ? alt : selectedFile.alt}
                onBlur={() => save()}
                placeholder={I18n.t("rich_text_editor.insert_image_dialog.image_alt_placeholder")}
              />
            </div>
            {AIAltGeneration && (
              <Typography className={classes.aiGenerationText}>
                <CfProgressBar customClass={classes.aiGenerationLoader} size={20} />
                {I18n.t("rich_text_editor.insert_image_dialog.ai_alt_generation")}
              </Typography>
            )}
            {error && !(alt || selectedFile.alt) && (
              <Typography className={classes.footerErrorText}>
                {I18n.t("rich_text_editor.insert_image_dialog.insert_error_alt")}
              </Typography>
            )}
            <div className={classes.editDetailsWrapper}>
              <Tooltip
                enterDelay={500}
                title={I18n.t(`rich_text_editor.tooltips.image_caption`)}
                classes={{ tooltip: classes.tooltip }}
                placement="top-start"
              >
                <Typography className={classes.fieldLabel} variant={"body2"}>
                  {I18n.t("rich_text_editor.insert_image_dialog.image_caption")}
                  <span className={classes.questionMark}>?</span>
                </Typography>
              </Tooltip>
              <MTextField
                outlined
                customClass={classes.customClass}
                inputClassName={classes.inputClass}
                borderColor={error && !(caption || selectedFile.caption) ? "red" : theme.palette.divider}
                borderRadius={4}
                onChange={(text) => setCaption(text)}
                onBlur={() => save()}
                value={caption !== null ? caption : selectedFile.caption}
                placeholder={I18n.t("rich_text_editor.insert_image_dialog.image_caption_placeholder")}
              />
            </div>
            {error && !(caption || selectedFile.caption) && (
              <Typography className={classes.footerErrorText}>
                {I18n.t("rich_text_editor.insert_image_dialog.insert_error_caption")}
              </Typography>
            )}
          </div>
          {/* <div className={classes.editDetailsWrapper}> */}
          {/*	<Typography align={'right'} color={'textSecondary'} className={classes.fieldLabel} variant={'body2'}> */}
          {/*		{I18n.t('rich_text_editor.insert_image_dialog.image_width')} */}
          {/*	</Typography> */}
          {/*	<MTextField */}
          {/*		fullWidth */}
          {/*		outlined */}
          {/*		type={'number'} */}
          {/*		customClass={classes.editDetailsField} */}
          {/*		onChange={text => setDesiredWidth(text)} */}
          {/*		value={desiredWidth || selectedFile.desiredWidth} */}
          {/*		placeholder={I18n.t('rich_text_editor.insert_image_dialog.image_width_placeholder')} */}
          {/*	/> */}
          {/* </div> */}
          {/* <div className={classes.editDetailsWrapper}> */}
          {/*	<Typography align={'right'} color={'textSecondary'} className={classes.fieldLabel} variant={'body2'}> */}
          {/*		{I18n.t('rich_text_editor.insert_image_dialog.image_url')} */}
          {/*	</Typography> */}
          {/*	<MTextField */}
          {/*		fullWidth */}
          {/*		outlined */}
          {/*		customClass={classes.editDetailsField} */}
          {/*		value={selectedFile.cdnUrl || selectedFile.url} */}
          {/*		placeholder={I18n.t('rich_text_editor.insert_image_dialog.image_url_placeholder')} */}
          {/*	/> */}
          {/* </div> */}
          {/* {(alt !== null || caption !== null || title !== null || desiredWidth !== null) && ( */}
          {/*	<div className={classes.detailsButtons}> */}
          {/*		<MTextButton */}
          {/*			text={I18n.t('rich_text_editor.insert_image_dialog.save')} */}
          {/*			onClick={e => { */}
          {/*				e.stopPropagation(); */}
          {/*				updateImageFile() */}
          {/*			}} */}
          {/*		/> */}
          {/*		<MTextButton */}
          {/*			text={I18n.t('rich_text_editor.insert_image_dialog.revert')} */}
          {/*			onClick={e => { */}
          {/*				e.stopPropagation(); */}
          {/*				setAlt(null); */}
          {/*				setCaption(null); */}
          {/*				setTitle(null); */}
          {/*			}} */}
          {/*		/> */}
          {/*	</div> */}
          {/* )} */}
        </>
      )}
    </div>
  );
};

export default ImageDetails;
