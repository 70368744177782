import * as React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  image: {
    height: 80,
    width: 60,
  },
}));

type PropTypes = {
  className?: string;
};

function LayoutSlideInSelectedIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 82 63"}>
      <svg width="82" height="63" viewBox="0 0 82 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="81" height="63" rx="8" fill="#609EFF" />
        <path
          d="M4.5 11H77.5V54C77.5 55.6569 76.1569 57 74.5 57H7.5C5.84315 57 4.5 55.6569 4.5 54V11Z"
          fill="#404142"
        />
        <circle cx="10.5" cy="6" r="2" fill="#404142" />
        <circle cx="16.5" cy="6" r="2" fill="#404142" />
        <circle cx="22.5" cy="6" r="2" fill="#404142" />
        <path
          d="M54 30C54 28.3431 55.3431 27 57 27H72C73.6569 27 75 28.3431 75 30V51C75 52.6569 73.6569 54 72 54H57C55.3431 54 54 52.6569 54 51V30Z"
          fill="#609EFF"
        />
      </svg>
    </SvgIcon>
  );
}

export default LayoutSlideInSelectedIcon;
