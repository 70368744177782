import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import ClassNames from "classnames";
import { useSlate } from "slate-react";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import inlineHtml from "./inlineHtml";
import InLineHTMLIcon from "../../../icons/InLineHTMLIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      fontSize: 16,
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
    activeIcon: {
      fill: theme.palette.primary.main,
    },
    image: {},
    focused: {
      boxShadow: "0 0 0 3px #B4D5FF",
    },
    imageDetailsWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    imageSizeWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    sizeField: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold as any,
    },
    imageWrapper: {
      display: "flex",
      position: "relative",
    },
    floatingBoard: {
      position: "absolute",
      right: 0,
      left: 0,
      background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%);",
    },
  })
);

type propTypes = {};

const InlineHtmlButton = (props: propTypes) => {
  const classes = useStyles(props);
  const editor = useSlate();

  return (
    <div style={{ display: "contents" }}>
      <Tooltip enterDelay={2000} title={I18n.t("rich_text_editor.tooltips.inline_html")}>
        <IconButton
          id={"insert-inline-html"}
          className={classes.button}
          disableRipple
          onClick={(event) => {
            event.preventDefault();
            inlineHtml.insertInlineHtml(editor);
          }}
        >
          <InLineHTMLIcon
            className={ClassNames(classes.icon, inlineHtml.isInlineHtmlActive(editor) && classes.activeIcon)}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default InlineHtmlButton;
