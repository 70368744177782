import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { IProduct } from "../../../../reducers/constants/objectTypes";
import ModalInputField from "../../components/ModalInputField";
import MTextFieldV2 from "../../../../components/MTextFieldV2";
import ModalDropdownField from "../../components/ModalDropdownField";
import MSelect from "../../../../componentsV2/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    width: "100%",
    height: 25,
  },
}));

type PropTypes = {
  product: IProduct;
  onChange: (product: IProduct) => void;
};

const GeneralView = (props: PropTypes) => {
  const classes = useStyles();
  const { product, onChange } = props;

  const productStatusOptions = [
    {
      label: "Active",
      value: true,
    },
    {
      label: "Inactive",
      value: false,
    },
  ];

  const productTypeOptions = [
    {
      label: "Product",
      value: "product",
    },
    {
      label: "App",
      value: "app",
    },
    {
      label: "Shopify App",
      value: "shopify-apps",
    },
  ];

  return (
    <>
      <ModalInputField title={I18n.t("product_dialog.general_view.product_status")}>
        <ModalDropdownField
          options={productStatusOptions}
          value={productStatusOptions.find((option) => option.value === product.active)}
          handleOnChange={(option) => onChange({ ...product, active: option.value })}
          placeholder={I18n.t("product_dialog.general_view.select_ph")}
          optionLabel="label"
          optionValue="value"
        />
      </ModalInputField>
      <div className={classes.divider} />
      <ModalInputField title={I18n.t("product_dialog.general_view.product_type")}>
        <MSelect
          options={productTypeOptions}
          value={productTypeOptions.find((option) => option.value === product.type)}
          onChange={(option) => onChange({ ...product, type: option.value })}
          placeholder={I18n.t("product_dialog.general_view.select_ph")}
          optionLabel="label"
          optionValue="value"
          defaultValue={productTypeOptions[0]}
        />
      </ModalInputField>
      <ModalInputField title={I18n.t("product_dialog.general_view.product_name")}>
        <MTextFieldV2
          value={product.name}
          placeholder={I18n.t("product_dialog.general_view.type_ph")}
          onValueChange={(name) => onChange({ ...product, name })}
        />
      </ModalInputField>
      <div className={classes.divider} />
      <ModalInputField title={I18n.t("product_dialog.general_view.display_name")}>
        <MTextFieldV2
          value={product.displayName}
          placeholder={I18n.t("product_dialog.general_view.type_ph")}
          onValueChange={(displayName) => onChange({ ...product, displayName })}
        />
      </ModalInputField>
      <ModalInputField title={I18n.t("product_dialog.general_view.short_description")}>
        <MTextFieldV2
          value={product.shortDescription}
          placeholder={I18n.t("product_dialog.general_view.type_ph")}
          onValueChange={(shortDescription) => onChange({ ...product, shortDescription })}
          maxLength={250}
        />
      </ModalInputField>
    </>
  );
};

export default GeneralView;
