import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { IAd, IAdPlacement } from "../../../../../../../reducers/constants/objectTypes";
import MTextField from "../../../../../../../components/MTextField";
import { ADS } from "../../../../../../../reducers/constants/actionTypes";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    // height: 100,
    padding: 20,
    position: "sticky",
    top: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
  },
  iframeWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  iframeURL: {
    flex: 4,
  },
  iframeSize: {
    flex: 1,
    marginLeft: 20,
  },
  textFieldInput: {
    minHeight: 29,
  },
  embeddedHtmlWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
}));

type PropTypes = {
  selectedPlacement: IAdPlacement;

  handleAdPlacementChanged: Function;
};

const mapStateToProps = (state) => ({
  selectedPlacement: state.ads.selectedPlacement,
});

const mapDispatchToProps = (dispatch) => ({
  handleAdPlacementChanged: (placement) => {
    dispatch({ type: ADS.EDIT_AD_PLACEMENT_CHANGED, placement });
  },
});

const AdIframe = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedPlacement, handleAdPlacementChanged } = props;

  return (
    <div className={classes.wrapper}>
      <div className={classes.iframeWrapper}>
        <MTextField
          fullWidth
          outlined
          height={40}
          label={"Iframe URL"}
          customClass={classes.iframeURL}
          inputClassName={classes.textFieldInput}
          value={selectedPlacement?.iframeURL}
          onChange={(url) => handleAdPlacementChanged({ ...selectedPlacement, iframeURL: url })}
          placeholder={"Iframe URL..."}
        />
        <MTextField
          fullWidth
          outlined
          height={40}
          label={"Iframe Width"}
          type={"number"}
          customClass={classes.iframeSize}
          inputClassName={classes.textFieldInput}
          value={(selectedPlacement?.iframeWidth || 0).toString()}
          onChange={(width) => handleAdPlacementChanged({ ...selectedPlacement, iframeWidth: parseInt(width, 10) })}
          placeholder={"Iframe Width..."}
        />
        <MTextField
          fullWidth
          outlined
          height={40}
          label={"Iframe Height"}
          type={"number"}
          customClass={classes.iframeSize}
          inputClassName={classes.textFieldInput}
          value={(selectedPlacement?.iframeHeight || 0).toString()}
          onChange={(height) => handleAdPlacementChanged({ ...selectedPlacement, iframeHeight: parseInt(height, 10) })}
          placeholder={"Iframe Height..."}
        />
      </div>
      <div className={classes.embeddedHtmlWrapper}>
        <MTextField
          fullWidth
          outlined
          multiline
          height={90}
          label={"Embedded HTML"}
          customClass={classes.iframeURL}
          inputClassName={classes.textFieldInput}
          value={selectedPlacement?.embedHtml}
          onChange={(url) => handleAdPlacementChanged({ ...selectedPlacement, embedHtml: url })}
          placeholder={"Html..."}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdIframe);
