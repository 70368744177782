import { AUDIENCES } from "./constants/actionTypes";

const initialState = {
  audiencesLoaded: false,
  audiences: [],

  selectedAudienceId: null,
  selectedAudience: null,

  // START audience object
  active: false,
  displayName: null,
  name: null,
  metaDescription: null,

  similarAudiences: [],
  audienceMainCategory: null,
  audienceCategories: [],
  audienceTopics: [],

  pageTop: null,
  appearance: null,
  searchQueries: null,
  convert: null,
  growth: null,
  cost: null,
  callToAction: null,

  // END audience object

  mainCategoriesLoaded: false,
  mainCategories: [],

  categoriesLoaded: false,
  categories: [],
  topicsLoaded: false,
  topics: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUDIENCES.ON_AUDIENCE_SELECTED:
      return {
        ...state,
        selectedAudienceId: action.audience._id,
      };
    case AUDIENCES.ON_AUDIENCE_CREATED:
      return {
        ...state,
        audiences: [...state.audiences, action.payload.audience].sort((a, b) => a.name.localeCompare(b.name)),
        selectedAudienceId: action.payload.audience._id,
      };
    case AUDIENCES.ON_AUDIENCES_LOADED:
      return {
        ...state,
        audiencesLoaded: true,
        audiences: action.payload.audiences,
        selectedAudienceId: state.selectedAudienceId
          ? state.selectedAudienceId
          : action.payload.audiences.length > 0
          ? action.payload.audiences[0]._id
          : null,
      };
    case AUDIENCES.ON_AUDIENCE_CLEANUP:
      return {
        ...initialState,
      };
    case AUDIENCES.ON_AUDIENCE_CHANGED:
      return {
        ...state,
        selectedAudience: action.audience,
        active: action.audience.active,
        displayName: action.audience.displayName,
        name: action.audience.name,
        similarAudiences: action.audience.similarAudiences,
        audienceMainCategory: action.audience.mainCategory,
        audienceCategories: action.audience.categories,
        metaDescription: action.audience.metaDescription,
        audienceTopics: action.audience.topics,
        pageTop: action.audience.pageTop,
        appearance: action.audience.appearance,
        searchQueries: action.audience.searchQueries,
        convert: action.audience.convert,
        growth: action.audience.growth,
        cost: action.audience.cost,
        callToAction: action.audience.callToAction,
      };
    case AUDIENCES.ON_AUDIENCE_UPDATED:
      return {
        ...state,
        selectedAudience: action.audience,
        active: action.audience.active,
        displayName: action.audience.displayName,
        name: action.audience.name,
        similarAudiences: action.audience.similarAudiences,
        audienceMainCategory: action.audience.mainCategory,
        audienceCategories: action.audience.categories,
        metaDescription: action.audience.metaDescription,
        audienceTopics: action.audience.topics,
        pageTop: action.audience.pageTop,
        appearance: action.audience.appearance,
        searchQueries: action.audience.searchQueries,
        convert: action.audience.convert,
        growth: action.audience.growth,
        cost: action.audience.cost,
        callToAction: action.audience.callToAction,

        audiences: state.audiences.map((a) => {
          if (a._id === action.audience._id) {
            return action.audience;
          }
          return a;
        }),
      };
    case AUDIENCES.ON_AUDIENCE_PROPERTY_CHANGED:
      return {
        ...state,
        [action.name]: action.value,
      };
    case AUDIENCES.ON_MAIN_CATEGORIES_LOADED:
      return {
        ...state,
        mainCategoriesLoaded: true,
        mainCategories: action.payload.categories,
      };
    case AUDIENCES.ON_MAIN_CATEGORY_CREATED:
      return {
        ...state,
        mainCategories: [...state.mainCategories, action.payload.category].sort((a, b) => a.name.localeCompare(b.name)),
        audienceMainCategory: action.payload.category,
      };
    case AUDIENCES.ON_CATEGORIES_LOADED:
      return {
        ...state,
        categoriesLoaded: true,
        categories: action.payload.categories,
      };
    case AUDIENCES.ON_CATEGORY_CREATED:
      return {
        ...state,
        categories: [...state.categories, action.payload.category].sort((a, b) => a.name.localeCompare(b.name)),
        audienceCategories: [...state.audienceCategories, action.payload.category],
      };
    case AUDIENCES.ON_TOPICS_LOADED:
      return {
        ...state,
        topicsLoaded: true,
        topics: action.payload.topics,
      };
    case AUDIENCES.ON_TOPIC_CREATED:
      return {
        ...state,
        topics: [...state.topics, action.payload.topic].sort((a, b) => a.name.localeCompare(b.name)),
        audienceTopics: [...state.audienceTopics, action.payload.topic],
      };
    default:
      return state;
  }
};
