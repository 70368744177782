import React from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ImageElement from "./image/ImageElement";
import CommentElement from "./comments/CommentElement";
import LinkElement from "./link/LinkElement";
import EmbedElement from "./embed/EmbedElement";
import InlineHtmlElement from "./inlineHTML/InlineHtmlElement";
import CommentElementV2 from "./commentsv2/CommentElementV2";
import DraggableElement from "./dragAndDrop/DraggableElement";
import ProductEmbedWidget from "./widgets/productEmbed/ProductEmbedWidget";
import ProductCTAWidget from "./widgets/productCTAElement/ProductCTAWidget";
import { LayoutArea, LayoutContainer } from "./layouts/layouts";
import BulletedListElement from "./bulletedList/BulletedListElement";
import { TextBoxElement } from "./textBox/TextBoxElement";
import ProductDetailsWidget from "./widgets/productDetails/ProductDetailsWidget";
import CallToActionWidget from "./widgets/deprecated/callToAction/CallToActionWidget";
import PricingTableWidget from "./widgets/pricingTable/PricingTableWidget";
import { DividerElement } from "./divider/DividerElement";
import { IPage, IProduct, IWebsite } from "../../reducers/constants/objectTypes";
import ComparisonTableWidget from "./widgets/comparisonTable/ComparisonTableWidget";
import ButtonWidget from "./widgets/button/ButtonWidget";
import ProductCTAHorizontalWidget from "./widgets/productCTAHorizontal/ProductCTAHorizontalWidget";
import CharticleTopProductsListWidget from "./widgets/charticleTopProductsList/CharticleTopProductsListWidget";
import { editorType } from "../types/editor.Types";
import NumberedListElement from "./numberedList/NumberedListElement";
import TableElement from "./table/TableElement";
import CharticleTopProductWidget from "./widgets/charticleTopProduct/CharticleTopProductWidget";
import ProductReviewWidget from "./widgets/productReview/ProductReviewWidget";
import { EntailWidgetPreview } from "./entailWidgets/entailWidgets";
import NiProsConsTableWidget from "./widgets/niProsConsTable/NiProsConsTableWidget";
import FAQWidgetElement from "./widgets/faqWidget/FAQWidgetElement";
import ParagraphElement from "./paragraph/ParagraphElement";
import TitleAssistantDropdown from "./aiAssitant/TitleAssistantDropdown";
import BlockQuoteElement from "./blockQuote/BlockQuoteElement";
import HeadingOneElement from "./headingOne/HeadingOneElement";
import HeadingTwoElement from "./headingTwo/HeadingTwoElement";
import { TypographyProps } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editable: {
      fontSize: (props: PropTypes) => theme.typography.pxToRem(props.fontSize || 16),
      color: theme.palette.text.primary,
      lineHeight: 1.55,
      paddingRight: 25,
      paddingLeft: 25,
      paddingTop: 20,
      paddingBottom: 20,
      minHeight: "200px !important",
      fontWeight: theme.typography.fontWeightLight as any,
      overflowY: "hidden",
    },
    button: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    image: {},
    titleTextField: {
      paddingLeft: 25,
      paddingRight: 25,
      margin: "10px 0px 0px 0px",
    },
    titleTextFieldInput: {
      fontSize: theme.typography.pxToRem(30),
      lineHeight: theme.typography.pxToRem(36),
      fontWeight: theme.typography.fontWeightBold as any,
    },
    titleTypography: {
      fontSize: theme.typography.pxToRem(30),
      lineHeight: theme.typography.pxToRem(36),
      fontWeight: theme.typography.fontWeightBold as any,
      paddingLeft: 25,
      paddingTop: 25,
      paddingRight: 25,
    },
    imageDetailsWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    imageSizeWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    sizeField: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold as any,
    },

    tableHead: {
      backgroundColor: "#F5F6F8",
      border: "1px double black",
      height: "50px",
      fontSize: 14,
      padding: "25px 0 25px 20px",
    },
    tableCell: {
      border: "1px solid black",
      height: "50px",
      fontSize: 14,
      padding: "25px 0 25px 20px",
    },
    dragIcon: {
      cursor: "pointer",
      position: "absolute",
      top: 0,
      transform: "translateX(-100%)",
      padding: "4px 0 0",
    },
  })
);

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  editorMode: boolean;
  fontSize?: number;
  products?: IProduct[];
  website: IWebsite;
  disableAi?: boolean;
  isDragging?: boolean;
  page?: IPage;
  onCommentUpdate?: () => void;
};

const RenderElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element } = props;
  const { editorMode, website, products = [], disableAi = false, page, onCommentUpdate, fontSize, isDragging } = props;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor as ReactEditor, element);
  const notDraggable = path.length > 1;
  const alignment = element.alignment || "left";

  switch (element.type) {
    case editorType.blockQuote:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <BlockQuoteElement element={element} attributes={attributes} website={website}>
            {children}
          </BlockQuoteElement>
        </DraggableElement>
      );
    case editorType.headingOne:
      return (
        <TitleAssistantDropdown
          disableAi={disableAi}
          disabled={notDraggable}
          element={element}
          selectedWebsite={website}
          page={page}
        >
          <DraggableElement index={path[0]} disabled={notDraggable}>
            <HeadingOneElement element={element}>{children}</HeadingOneElement>
          </DraggableElement>
        </TitleAssistantDropdown>
      );
    case editorType.headingTwo:
      return (
        <TitleAssistantDropdown
          disableAi={disableAi}
          disabled={notDraggable}
          element={element}
          selectedWebsite={website}
          page={page}
        >
          <DraggableElement index={path[0]} disabled={notDraggable}>
            <HeadingTwoElement element={element}>{children}</HeadingTwoElement>
          </DraggableElement>
        </TitleAssistantDropdown>
      );
    case editorType.listItem:
      return <li {...attributes}>{children}</li>;
    case editorType.numberedList:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <NumberedListElement attributes={attributes} element={element}>
            {children}
          </NumberedListElement>
        </DraggableElement>
      );
    case editorType.bulletedList:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <BulletedListElement attributes={attributes} element={element}>
            {children}
          </BulletedListElement>
        </DraggableElement>
      );

    case editorType.image: // https://previews.123rf.com/images/victoroancea/victoroancea1201/victoroancea120100059/12055848-tv-color-test-pattern-test-card-for-pal-and-ntsc.jpg
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <ImageElement {...props}>{children}</ImageElement>
        </DraggableElement>
      );
    case editorType.divider:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <DividerElement {...props}>{children}</DividerElement>
        </DraggableElement>
      );
    case editorType.textBox:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <TextBoxElement {...props}>{children}</TextBoxElement>
        </DraggableElement>
      );
    case editorType.entailWidget:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <EntailWidgetPreview {...props}>{children}</EntailWidgetPreview>
        </DraggableElement>
      );
    case editorType.layout:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <LayoutContainer {...props}>{children}</LayoutContainer>
        </DraggableElement>
      );
    case editorType.layoutArea:
      return <LayoutArea {...props}>{children}</LayoutArea>;
    case editorType.embed:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <EmbedElement {...props} />
        </DraggableElement>
      );
    case editorType.commentV2:
      return <CommentElementV2 editorMode={editorMode} {...props} onCommentUpdate={onCommentUpdate} />;
    case editorType.comment:
      return <CommentElement {...props} />;
    case editorType.faq:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          {/* <FAQWidget {...props} /> */}
          <FAQWidgetElement {...props} />
        </DraggableElement>
      );
    case editorType.button:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <ButtonWidget products={products} {...props} />
        </DraggableElement>
      );
    case editorType.productDetails:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <ProductDetailsWidget products={products} {...props} />
        </DraggableElement>
      );
    case editorType.callToAction: // old - backward compatability
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <CallToActionWidget products={products} {...props} />
        </DraggableElement>
      );
    case editorType.productReview:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <ProductReviewWidget products={products} {...props} />
        </DraggableElement>
      );
    case editorType.productEmbed:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <ProductEmbedWidget products={products} {...props} />
        </DraggableElement>
      );
    case editorType.productCta:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <ProductCTAWidget products={products} {...props}>
            {children}
          </ProductCTAWidget>
        </DraggableElement>
      );
    case editorType.productCtaHorizontal:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <ProductCTAHorizontalWidget products={products} {...props}>
            {children}
          </ProductCTAHorizontalWidget>
        </DraggableElement>
      );
    case editorType.charticleTopProduct:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <CharticleTopProductWidget products={products} {...props}>
            {children}
          </CharticleTopProductWidget>
        </DraggableElement>
      );
    case editorType.charticleTopProducts:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <CharticleTopProductsListWidget products={products} {...props}>
            {children}
          </CharticleTopProductsListWidget>
        </DraggableElement>
      );
    case editorType.comparisonTable:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <ComparisonTableWidget products={products} {...props} />
        </DraggableElement>
      );
    case editorType.pricingTable:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <PricingTableWidget products={products} {...props} />
        </DraggableElement>
      );
    case editorType.table: {
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <TableElement attributes={attributes} element={element}>
            {children}
          </TableElement>
        </DraggableElement>
      );
    }
    case editorType.tableHead: {
      return <thead {...attributes}>{children}</thead>;
    }
    case editorType.tableBody: {
      return <tbody {...attributes}>{children}</tbody>;
    }
    case editorType.tableRow: {
      return <tr {...attributes}>{children}</tr>;
    }
    case editorType.tableHeader: {
      return (
        <th {...attributes} style={{ textAlign: alignment }} className={classes.tableHead}>
          {children}
        </th>
      );
    }
    case editorType.tableCell: {
      return (
        <td {...attributes} style={{ textAlign: alignment }} className={classes.tableCell}>
          {children}
        </td>
      );
    }
    case editorType.link:
      return <LinkElement {...props} />;
    case editorType.inlineHtml:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <InlineHtmlElement {...props} />
        </DraggableElement>
      );
    case editorType.niProsCons:
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <NiProsConsTableWidget {...props} />
        </DraggableElement>
      );
    default: {
      return (
        <DraggableElement index={path[0]} disabled={notDraggable}>
          <ParagraphElement attributes={attributes} element={element} alignment={alignment} fontSize={fontSize}>
            {children}
          </ParagraphElement>
        </DraggableElement>
      );
    }
  }
};

export default RenderElement;
