import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ePageState, IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import MLink from "../../../components/MLink";
import { clientPages, withHttps } from "../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  questionUrlWrapper: {
    width: "100%",
    marginBottom: 15,
    paddingLeft: 7,
    display: "flex",
    flexDirection: "column",
  },
  link: {
    color: "#0298C8",
    wordBreak: "break-word",
  },
  urlTitle: {
    minWidth: 130,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 10,
  },
  linkTypography: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: theme.typography.pxToRem(12),
  },
}));

type PropTypes = {
  page: IPage;
  selectedWebsite: IWebsite;
};

const PageEditorPublishedPostUrl = (props: PropTypes) => {
  const classes = useStyles(props);
  const { page, selectedWebsite } = props;

  if (![ePageState.published, ePageState.factCheck].includes(page?.currentState.state)) {
    return null;
  }

  if (page.currentState.state === ePageState.factCheck && (page.publishedDate === null || page.slug === null)) {
    return null;
  }

  // igonreState - hack for imported articles (Webflow/WordPress)
  const url = clientPages.getPageUrl(page, selectedWebsite, page.publishedDate !== null && page.slug !== null);

  if (!url) {
    return null;
  }

  return (
    <div className={classes.questionUrlWrapper}>
      <Typography variant={"body1"} className={classes.urlTitle} color={"textSecondary"}>
        {I18n.t("edit_post.url")}
      </Typography>
      <MLink linkClassName={classes.link} typographyClassName={classes.linkTypography} newTab href={withHttps(url)}>
        {url}
      </MLink>
    </div>
  );
};

export default PageEditorPublishedPostUrl;
