import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import List from "@material-ui/core/List";

const useStyles = makeStyles((theme: Theme) => ({
  tasksWrapper: {
    padding: 0,
  },
  taskWrapper: {
    backgroundColor: "#404142",
    marginBottom: 2,
    padding: "18px 15px",
    borderRadius: 3,
    cursor: "pointer",
  },

  taskText: {
    color: "#fff",
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  newTask: {
    color: "#609EFF",
    fontWeight: theme.typography.fontWeightMedium as any,
    textAlign: "center",
  },
  expandIcon: {
    fontSize: 16,
    color: "#fff",
  },
}));

type PropTypes = {
  data?: any;
};

const tasks = [
  {
    name: "Generate H1 title",
  },
  {
    name: "Generate H2 titles",
  },
  {
    name: "Summarize article",
  },
  {
    name: "Generate block quotes",
  },
  {
    name: "Generate table",
  },
  {
    name: "Show relevant statistics",
  },
  {
    name: "Find relevant quotes",
  },
];
const AssistantList = (props: PropTypes) => {
  const classes = useStyles(props);
  const { data } = props;

  return (
    <List className={classes.tasksWrapper} component={"div"}>
      {/*{tasks.map((task, index) => (*/}
      {/*  <ListItem key={`ai_task_${index + 1}`} className={classes.taskWrapper}>*/}
      {/*    <ListItemText*/}
      {/*      primary={task.name}*/}
      {/*      primaryTypographyProps={{ variant: "subtitle2", className: classes.taskText }}*/}
      {/*    />*/}
      {/*    <ArrowForwardIos className={classes.expandIcon} />*/}
      {/*  </ListItem>*/}
      {/*))}*/}
      {/*<ListItem key={"add_new_task"} className={classes.taskWrapper}>*/}
      {/*  <ListItemText*/}
      {/*    primary={I18n.t("ai_assistant.new_task")}*/}
      {/*    primaryTypographyProps={{ variant: "subtitle2", className: classes.newTask }}*/}
      {/*  />*/}
      {/*</ListItem>*/}
    </List>
  );
};

export default AssistantList;
