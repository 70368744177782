import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { uniqBy } from "lodash";
import MSelectMultiple from "../../../components/MSelectMultiple";
import { IQueryGroup } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    width: "100%",
    display: "flex",
    marginBottom: 10,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  selectMultipleWrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  keywordsFieldTitle: {
    minWidth: 170,
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    marginRight: 15,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
  },
  hidden: {
    visibility: "hidden",
  },
}));

type PropTypes = {
  keywordGroups: IQueryGroup[];
  keywordGroupsOptions: IQueryGroup[];
  handleKeywordGroupsChange: (keywordGroups: IQueryGroup[]) => void;
};

const FAQTargetKeywordGroups = (props: PropTypes) => {
  const classes = useStyles(props);
  const { keywordGroups = [], keywordGroupsOptions, handleKeywordGroupsChange } = props;

  return (
    <div className={classes.row}>
      <Typography className={classes.keywordsFieldTitle} variant={"subtitle2"}>
        {I18n.t("outline.target_keyword_groups")}
      </Typography>

      <MSelectMultiple
        creatable={false}
        wrapperClassName={classes.selectMultipleWrapper}
        options={keywordGroupsOptions}
        selectedOptions={keywordGroups}
        optionLabel={"name"}
        optionValue={"id"}
        borderWidth={1}
        height={37}
        placeholder={I18n.t("outline.target_keyword_groups_ph")}
        handleOptionSelected={(value) => {
          handleKeywordGroupsChange(uniqBy([...keywordGroups, value], "id"));
        }}
        handleOptionRemoved={(value) => {
          handleKeywordGroupsChange([...keywordGroups].filter((t) => t.id !== value._id));
        }}
      />
    </div>
  );
};

export default FAQTargetKeywordGroups;
