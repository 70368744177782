import { EXCLUDE_KEYWORDS } from "./constants/actionTypes";

const initialState = {
  keywordsListLoaded: false,
  keywordsList: [],
  searchText: "",
  selectedSite: null,
  checkedKeywordsList: [],
  totalKeywordsCount: 0,
  keyword: null,
  filter: {
    searchText: "",
    selectedType: { type: "", name: "All" },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXCLUDE_KEYWORDS.ON_EXCLUDE_KEYWORD_ADDED:
      return {
        ...state,
        seedsList: [...state.keywordsList, ...action.excludedKeywords],
      };
    case EXCLUDE_KEYWORDS.ON_KEYWORDS_LIST_LOADED:
      return {
        ...state,
        keywordsListLoaded: true,
        keywordsList: action.payload.keywordsList,
        totalKeywordsCount: action.payload.totalKeywordsCount,
      };
    case EXCLUDE_KEYWORDS.ON_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        searchText: action.searchText,
      };
    case EXCLUDE_KEYWORDS.ON_SELECTED_SITE_CHANGED:
      return {
        ...state,
        selectedSite: action.selectedSite,
      };
    case EXCLUDE_KEYWORDS.ON_CHECKED_BOX_CHANGED:
      return {
        ...state,
        checkedKeywordsList: action.checkedKeywordsList,
      };
    case EXCLUDE_KEYWORDS.ON_KEYWORD_CHANGED:
      return {
        ...state,
        keyword: action.keyword,
        keywordsList: state.keywordsList.map((keyword) => {
          if (keyword._id === action.keyword._id) {
            return action.keyword;
          }
          return keyword;
        }),
      };
    case EXCLUDE_KEYWORDS.ON_KEYWORDS_REMOVED:
      return {
        ...state,
        keywordsList: state.keywordsList.filter((keyword) => !action.keywords.includes(keyword)),
      };
    case EXCLUDE_KEYWORDS.ON_EXCLUDE_KEYWORD_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
