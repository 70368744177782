import * as React from "react";
import { makeStyles, styled } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Collapse, FormControlLabel, FormGroup, IconButton, IconButtonProps, Switch } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  sectionWrapper: {
    marginBottom: 10,
  },
  innerWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 8,
  },
  titleWrapper: {
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: "5px 30px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  expandContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  ratingWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  scorePercentage: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 5,
    padding: "5px 10px",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  scoreLabel: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[500],
  },
  switchWrapper: {
    "& .MuiFormGroup-root": {
      marginRight: -25,
    },
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    "&:first-child": {
      marginTop: 0,
    },
  },
  rowGaps: {
    gap: 20,
  },
}));

type PropTypes = {
  title: string;
  showSwitch?: boolean;
  checked?: boolean;
  scorePercentage?: number;
  className?: string;
  children: React.ReactNode;
  bottomComponent?: React.ReactNode;

  onSwitch?: (value: boolean) => void;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: { theme: Theme; expand: boolean }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "-15px",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpertCollapsibleCard = (props: PropTypes) => {
  const classes = useStyles(props);

  const { title, showSwitch, checked, scorePercentage, children, bottomComponent, className } = props;
  const { onSwitch } = props;

  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Paper elevation={0} className={classes.sectionWrapper}>
        <div className={classes.titleWrapper}>
          <div className={classes.expandContainer}>
            <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
            <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
              {title}
            </Typography>
          </div>
          <div>
            {scorePercentage !== undefined && (
              <div className={classes.ratingWrapper}>
                <Typography variant={"subtitle1"} className={classes.scoreLabel}>
                  {I18n.t("experts.profile.overall_score")}
                </Typography>
                <Typography variant={"subtitle1"} className={classes.scorePercentage}>
                  {scorePercentage}%
                </Typography>
              </div>
            )}

            {showSwitch && onSwitch && (
              <div className={classes.switchWrapper}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={checked} onChange={() => onSwitch(!checked)} color="primary" />}
                    label=""
                  />
                </FormGroup>
              </div>
            )}
          </div>
        </div>
        <Collapse in={expanded} timeout="auto">
          <div className={ClassNames(classes.innerWrapper, className)}>{children}</div>
        </Collapse>
      </Paper>
      {expanded && bottomComponent}
    </>
  );
};

export default ExpertCollapsibleCard;
