import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { Typography } from "@material-ui/core";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 30,
  },
  bulletWrap: {
    display: "flex",
    marginBottom: 30,
  },
  bulletName: {
    fontSize: theme.typography.pxToRem(14),
    textDecoration: "underline",
    whiteSpace: "nowrap",
    marginRight: 20,
  },
}));

type PropTypes = {
  title?: string;
  bullets: {
    name: string;
    text: any;
  }[];
  bulletsNumber: number;

  handleChange: (postsIds) => void;
};

const Bullets = (props: PropTypes) => {
  const classes = useStyles(props);
  const { title = "", bullets, bulletsNumber, handleChange } = props;

  return (
    <>
      <Typography variant={"body1"} className={classes.title}>
        {title}
      </Typography>
      {Array(bulletsNumber)
        .fill(0)
        .map((x, index) => (
          <div key={`bullet_${index}`} className={classes.bulletWrap}>
            <Typography variant={"body1"} className={classes.bulletName}>
              {I18n.t("website.homepage.bullet", { num: index + 1 })}
            </Typography>
            <TextFieldMaxLength
              titleAndLengthLocation={"top"}
              value={bullets?.[index]?.name}
              placeholder={""}
              title={I18n.t("website.homepage.bullet_name")}
              maxLength={100}
              height={37}
              borderRadius={4}
              marginRight={30}
              handleValueChange={(bulletName) => {
                if (!bullets[index]) {
                  bullets.push({ name: "", text: "" });
                }
                bullets[index].name = bulletName;
                handleChange(bullets);
              }}
            />
            <TextFieldMaxLength
              titleAndLengthLocation={"top"}
              value={bullets?.[index]?.text}
              placeholder={""}
              title={I18n.t("website.homepage.bullet_text")}
              height={80}
              multiLine
              maxLength={300}
              borderRadius={4}
              handleValueChange={(bulletText) => {
                if (!bullets[index]) {
                  bullets.push({ name: "", text: "" });
                }
                bullets[index].text = bulletText;
                handleChange(bullets);
              }}
            />
          </div>
        ))}
    </>
  );
};

export default Bullets;
