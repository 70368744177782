import React, { useEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { findLast, round } from "lodash";
import _debounce from "lodash/debounce";
import { Theme, makeStyles } from "@material-ui/core";

import { useVideoEditorContext } from "../VideoEditorContext";

(videojs as any).Vhs.GOAL_BUFFER_LENGTH = 60 * 30;
(videojs as any).Vhs.MAX_GOAL_BUFFER_LENGTH = 60 * 60;

const useStyles = makeStyles((theme: Theme) => ({
  playerWrapper: {
    "& .vjs-control-text": {
      display: "none",
    },
  },
}));

const VideoPlayer = () => {
  const { selectedTimeline, timelineState, videoPlayerRef, timelineRef, editVideoEditorTimelineProperty } =
    useVideoEditorContext();
  const [isBuffering, setIsBuffering] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!timelineState) return;

    const videoRow = selectedTimeline.rows.find((row) => row.type === "video");

    const timelineTime = round(timelineState.time, 2);

    const currentAction = findLast(
      videoRow.actions,
      (action) => action.start <= timelineTime && timelineTime <= action.end
    );
    const player = videojs(videoPlayerRef.current);
    player.preload("auto");

    if (timelineState.isBuffering) {
      return;
    }

    if (!currentAction) {
      player.pause();
      return;
    }

    if (player.currentSrc() !== currentAction.video.src) {
      player.src({ src: currentAction.video.src, type: "video/mp4" });
    }

    if (player.volume() !== timelineState.volume) {
      player.volume(timelineState.volume);
    }

    if (!currentAction) {
      if (!player.paused()) {
        player.pause();
      }
      return;
    }

    const currentPlayerTime = round(player.currentTime(), 2);
    const timeThreshold = 0.3;

    const adjustedTime = timelineTime - currentAction.start + (currentAction.trimStart || 0);
    const trimTimeDifference = Math.abs(currentPlayerTime - adjustedTime);

    // Update the player's time only if the difference exceeds the threshold
    if (trimTimeDifference > timeThreshold) {
      console.log("jumping on time", currentPlayerTime, adjustedTime);
      player.currentTime(adjustedTime);
    }

    if (!player.paused() && !timelineState.isPlaying) {
      console.log("player.paused() && !timelineState.isPlaying");
      player.pause();
    } else if (player.paused() && timelineState.isPlaying) {
      console.log("player.paused() && timelineState.isPlaying)");
      player.play();
    }
  }, [timelineState, selectedTimeline.rows, videoPlayerRef]);

  useEffect(() => {
    const videoRow = selectedTimeline.rows.find((row) => row.type === "video");

    const timelineTime = round(timelineState.time, 2);

    const currentAction = findLast(
      videoRow.actions,
      (action) => action.start <= timelineTime && timelineTime <= action.end
    );

    if (videoPlayerRef.current && currentAction) {
      const player = videojs(videoPlayerRef.current);
      const currentVideo = currentAction.video;

      // Update source if different
      if (player.currentSrc() !== currentVideo.src) {
        console.log("useEffect: update source");
        player.src({ src: currentVideo.src, type: "video/mp4" });
      }
    }
  }, [selectedTimeline.rows, timelineState.time, videoPlayerRef]);

  useEffect(() => {
    if (!videoPlayerRef.current) return;
    const video = videoPlayerRef.current;
    let bufferTimeout = null;

    const onBufferStart = () => {
      if (timelineState.isBuffering) return;
      bufferTimeout = setTimeout(() => {
        editVideoEditorTimelineProperty({ isBuffering: true, playAfterBuffering: true });
        timelineRef.current?.pause();
        bufferTimeout = null;
      }, 200);
    };

    const onBufferEnd = () => {
      clearTimeout(bufferTimeout);
      if (timelineState.isBuffering && timelineState.playAfterBuffering) {
        editVideoEditorTimelineProperty({ isBuffering: false, playAfterBuffering: false });
        video.play();
        timelineRef.current?.setTime(round(video.currentTime, 2));
        timelineRef.current?.play({ autoEnd: true });
      }
    };

    video.addEventListener("waiting", onBufferStart);
    video.addEventListener("canplay", onBufferEnd);

    return () => {
      video.removeEventListener("waiting", onBufferStart);
      video.removeEventListener("canplay", onBufferEnd);
    };
  }, [
    videoPlayerRef.current,
    timelineState.isBuffering,
    timelineState.playAfterBuffering,
    editVideoEditorTimelineProperty,
  ]);

  return (
    <div className={classes.playerWrapper}>
      <div style={{ width: "100%", height: "100%" }}>
        <video
          ref={videoPlayerRef}
          preload="auto"
          crossOrigin="anonymous"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
