import { Divider, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextareaProperty from "./itemProperties/components/TextareaProperty";
import { IWidgetParagraphFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import ShowParagraphProperty from "./itemProperties/components/ShowParagraph";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({}),
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
    switch: {
      paddingInline: 15,
      height: 55,
    },
  };
});

type PropTypes = {
  properties: IWidgetParagraphFieldProps;
  onPropertyChange(properties: IWidgetParagraphFieldProps): void;
};

const ParagraphWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;
  const classes = useStyles(props);

  const onShowParagraphChange = (showText: boolean) => {
    onPropertyChange({ ...properties, showText });
  };

  const onCaptionChange = (text: string) => {
    onPropertyChange({ ...properties, text });
  };

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <ShowParagraphProperty
          checked={properties.showText}
          onChange={onShowParagraphChange}
          className={classes.switch}
        />
        <Divider className={classes.divider} />
        <TextareaProperty
          placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.caption")}
          onChange={onCaptionChange}
          value={properties?.text}
        />
      </div>
    </div>
  );
};

export default ParagraphWidgetItem;
