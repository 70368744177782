import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";

import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import agent from "../../../../agent";
import { CONTENT_GAPS } from "../../../../reducers/constants/actionTypes";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import ContentGapsList from "./ContentGapsList";
import ContentGapsDialog from "./ContentGapsDialog/ContentGapsDialog";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import MTablePagination from "../../../../components/Table/Pagination/MTablePagination";
import MTableToolbar from "../../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarKeywordStrategyTypeFilter from "../../../../components/Table/Toolbar/Filters/MTableToolbarKeywordStrategyTypeFilter";
import MTableToolbarBaseRangeSelect from "../../../../components/Table/Toolbar/MTableToolbarBaseRangeSelect";
import MTableToolbarMultipleTextFieldInput from "../../../../components/Table/Toolbar/MTableToolbarMultipleTextFieldInput";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  searchTextFilter: state.contentGaps.filter.search,
  sortField: state.contentGaps.filter.sortField,
  total: state.contentGaps.total,
  limit: state.contentGaps.limit,
  page: state.contentGaps.page,
  refetch: state.contentGaps.refetch,
  exclude: state.contentGaps?.filter?.exclude,
  include: state.contentGaps?.filter?.include,
  funnelStageFilter: state.contentGaps?.filter?.funnelStage,
  pagesFilter: state.contentGaps?.filter?.pages,
  statusFilter: state.contentGaps?.filter?.status,
  keywordsFilter: state.contentGaps?.filter?.keywords,
  impressionsFilter: state.contentGaps?.filter?.impressions,
  clicksFilter: state.contentGaps?.filter?.clicks,
  positionFilter: state.contentGaps?.filter?.position,
  volumeFilter: state.contentGaps?.filter?.volume,
  isDialogOpen: state.contentGaps.isDialogOpen,
  activeDialogTab: state.contentGaps.activeDialogTab,
  selectedDataItemsIndexes: state.contentGaps.selectedDataItemsIndexes,
});

const mapDispatchToProps = (dispatch) => ({
  loading() {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_DATA_LOADING, payload: true });
  },
  changeExcludeFilter: (exclude) =>
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_EXCLUDE_FILTER_CHANGED, payload: exclude }),
  changeIncludeFilter: (include) =>
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_INCLUDE_FILTER_CHANGED, payload: include }),
  changeLoadedData(payload) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_DATA_LOADED, payload });
  },
  changePage(page: number) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_PAGE_CHANGED, payload: page });
  },
  changeLimit(limit: number) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_LIMIT_CHANGED, payload: limit });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
  changeSort(sort: { field: string; direction: "desc" | "asc" }) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_SORT_CHANGED, payload: sort });
  },

  changeIsDialogOpen(open: boolean) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_IS_DIALOG_OPEN_CHANGED, payload: open });
  },
  changeActiveDialogTab(tab: "outlines" | "keywords" | "broadKeywords" | "pages") {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_ACTIVE_DIALOG_TAB_CHANGED, payload: tab });
  },
  changeSearchTextFilter: (searchText) =>
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_SEARCH_TEXT_FILTER_CHANGED, payload: searchText }),
  changeFunnelStageFilter: (funnelStage) =>
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_FUNNEL_STAGE_FILTER_CHANGE, payload: funnelStage }),
  changeImpressionFilter: (impressions) =>
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_IMPRESSIONS_FILTER_CHANGED, payload: impressions }),
  changeVolumeFilter: (volume) =>
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_VOLUME_FILTER_CHANGED, payload: volume }),
  changePageFilter: (page) => dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_PAGE_FILTER_CHANGED, payload: page }),
  changeKeywordsFilter: (keywords) =>
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_KEYWORDS_FILTER_CHANGE, payload: keywords }),
  changeClicksFilter: (clicks) =>
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_CLICKS_FILTER_CHANGE, payload: clicks }),
  changePositionFilter: (position) =>
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_POSITION_FILTER_CHANGE, payload: position }),
  onFilterChanged: (payload) => dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_FILTER_CHANGED, payload }),
});

type PropTypes = {
  changeSearchTextFilter: (searchText: string) => void;
  selectedWebsite: IWebsite;
  searchTextFilter: string | null;
  funnelStageFilter: string[];
  pagesFilter: number[];
  statusFilter: string[];
  keywordsFilter: number[];
  clicksFilter: number[];
  positionFilter: number[];
  impressionsFilter: number[];
  isDialogOpen: boolean;
  exclude: string[];
  include: string[];
  changeExcludeFilter: (exclude: string[]) => void;
  changeIncludeFilter: (include: string[]) => void;
  activeDialogTab: "keywords" | "pages";
  volumeFilter: number[];
  changeFunnelStageFilter: (funnelStage: string[]) => void;
  changeImpressionFilter: (impressions: number[]) => void;
  changeVolumeFilter: (volume: number[]) => void;
  changePageFilter: (page: number[]) => void;
  changeKeywordsFilter: (keywords: number[]) => void;
  changeClicksFilter: (clicks: number[]) => void;
  changePositionFilter: (position: number[]) => void;

  total: number;
  limit: number;
  page: number;
  sortField: { field: string; direction: "desc" | "asc" };

  refetch: boolean;

  loading: () => void;
  changeLoadedData: (payload: any) => void;
  changePage: (page: number) => void;
  changeLimit: (limit: number) => void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  changeIsDialogOpen: (open: boolean) => void;
  changeActiveDialogTab: (tab: "keywords" | "pages") => void;
  selectedDataItemsIndexes: number[];
  onFilterChanged: (payload: any) => void;
};

const ContentGaps = (props: PropTypes) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    changeLoadedData,
    changeLimit,
    changeFunnelStageFilter,
    funnelStageFilter,
    changePage,
    loading,
    changeSearchTextFilter,
    changeSelectedDataItemsIndexes,
    searchTextFilter,
    limit,
    total,
    page,
    sortField,
    refetch,
    selectedWebsite,
    changeImpressionFilter,
    changeKeywordsFilter,
    impressionsFilter,
    changeClicksFilter,
    changePositionFilter,
    clicksFilter,
    positionFilter,
    changePageFilter,
    changeVolumeFilter,
    keywordsFilter,
    pagesFilter,
    statusFilter,
    volumeFilter,
    isDialogOpen,
    activeDialogTab,
    changeActiveDialogTab,
    changeIsDialogOpen,
    exclude,
    include,
    changeIncludeFilter,
    changeExcludeFilter,
    selectedDataItemsIndexes,
    onFilterChanged,
  } = props;

  useEffect(() => {
    changeLimit(100);
    changePage(1);
    onFilterChanged({
      sortField: {
        field: "value_difference",
        direction: "desc",
      },
    });
    changeSearchTextFilter("");
    changeFunnelStageFilter([]);
    changeSelectedDataItemsIndexes([]);
    changeImpressionFilter([]);
    changeKeywordsFilter([]);
    changePageFilter([]);
    changeVolumeFilter([]);
    changePositionFilter([]);
    changeClicksFilter([]);
    changeExcludeFilter([]);
    changeIncludeFilter([]);
  }, [selectedWebsite]);

  useEffect(
    () => () => {
      changeLimit(100);
      changePage(1);
      onFilterChanged({
        sortField: {
          field: "value_difference",
          direction: "desc",
        },
      });
      changeSearchTextFilter("");
      changeFunnelStageFilter([]);
      changeSelectedDataItemsIndexes([]);
      changeImpressionFilter([]);
      changeKeywordsFilter([]);
      changePageFilter([]);
      changeVolumeFilter([]);
      changePositionFilter([]);
      changeClicksFilter([]);
      changeExcludeFilter([]);
      changeIncludeFilter([]);
    },
    []
  );

  useEffect(() => {
    loading();

    const sort = JSON.stringify({
      [sortField.field]: sortField.direction === "asc" ? "1" : "-1",
    });
    const filter =
      funnelStageFilter.length > 0 ? JSON.stringify({ conversion_potential: funnelStageFilter }) : undefined;

    const excludeFilters = exclude.length > 0 ? exclude.join("|") : undefined;
    const includeFilters = include.length > 0 ? include.join("|") : undefined;

    agent.KeywordStrategy.getKeywordStrategies(
      selectedWebsite._id,
      filter,
      limit,
      page,
      sort,
      searchTextFilter,
      JSON.stringify(["new", "draft", "review", "published"]),
      undefined,
      undefined,
      excludeFilters,
      includeFilters,
      pagesFilter?.[0],
      pagesFilter?.[1],
      clicksFilter?.[0],
      clicksFilter?.[1],
      volumeFilter?.[0],
      volumeFilter?.[1],
      true,
      impressionsFilter?.[0],
      impressionsFilter?.[1],
      keywordsFilter?.[0],
      keywordsFilter?.[1],
      positionFilter?.[0],
      positionFilter?.[1]
    )
      .then((res) => {
        changeLoadedData(res);
      })
      .catch((e) => {
        enqueueSnackbar(e?.message, { variant: "error" });

        changeLoadedData([]);
      });
  }, [
    selectedWebsite,
    limit,
    page,
    refetch,
    funnelStageFilter,
    searchTextFilter,
    sortField,
    volumeFilter,
    impressionsFilter,
    keywordsFilter,
    pagesFilter,
    clicksFilter,
    positionFilter,
  ]);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t("meta_tags.strategy.gaps.title")} />
      <MTableToolbar
        selectedRows={selectedDataItemsIndexes?.length}
        selectedRowActions={[]}
        onClearSelectedRows={() => changeSelectedDataItemsIndexes([])}
        searchText={searchTextFilter}
        handleSearchTextChanged={(value) => onFilterChanged({ search: value })}
      >
        <MTableToolbarKeywordStrategyTypeFilter
          postTypes={funnelStageFilter}
          handlePostTypesSelected={(value) => onFilterChanged({ funnelStage: value })}
        />
        <MTableToolbarBaseRangeSelect
          title={I18n.t("strategy.outlinePages.pages_title")}
          placeholder={I18n.t("strategy.outlinePages.page_title")}
          handleChange={(value) => onFilterChanged({ pages: value })}
          rangValues={pagesFilter}
        />
        <MTableToolbarBaseRangeSelect
          title={I18n.t("strategy.outlinePages.volume_title")}
          placeholder={I18n.t("strategy.outlinePages.volume_title")}
          handleChange={(value) => onFilterChanged({ volume: value })}
          rangValues={volumeFilter}
        />

        <MTableToolbarBaseRangeSelect
          title={I18n.t("strategy.outlinePages.impressions_title")}
          placeholder={I18n.t("strategy.outlinePages.impressions_title")}
          handleChange={(value) => onFilterChanged({ impressions: value })}
          rangValues={impressionsFilter}
        />
        <MTableToolbarBaseRangeSelect
          handleChange={(value) => onFilterChanged({ clicks: value })}
          rangValues={clicksFilter}
          title={I18n.t("strategy.outlinePages.traffic_title")}
          placeholder={I18n.t("strategy.outlinePages.traffic_filter")}
        />
        <MTableToolbarBaseRangeSelect
          handleChange={(value) => onFilterChanged({ position: value })}
          rangValues={positionFilter}
          title={I18n.t("strategy.outlinePages.position_filter")}
          placeholder={I18n.t("strategy.outlinePages.position_filter")}
        />
        <MTableToolbarBaseRangeSelect
          handleChange={(value) => onFilterChanged({ keywords: value })}
          rangValues={keywordsFilter}
          title={I18n.t("strategy.outlinePages.keywords_title")}
          placeholder={I18n.t("strategy.outlinePages.keywords_title")}
        />
        <MTableToolbarMultipleTextFieldInput
          title={I18n.t("strategy.outlinePages.exclude")}
          placeholder={I18n.t("strategy.outlinePages.exclude")}
          selectedLabel={I18n.t("strategy.outlinePages.excluded")}
          handleChange={(value) => onFilterChanged({ exclude: value })}
          selectedOptions={exclude}
        />
        <MTableToolbarMultipleTextFieldInput
          title={I18n.t("strategy.outlinePages.include")}
          placeholder={I18n.t("strategy.outlinePages.include")}
          selectedLabel={I18n.t("strategy.outlinePages.included")}
          handleChange={(value) => onFilterChanged({ include: value })}
          selectedOptions={include}
        />
      </MTableToolbar>
      <ContentGapsList
        setSortField={(value) => {
          onFilterChanged({ sortField: value });
        }}
        sortField={sortField}
      />
      {total > 0 && (
        <MTablePagination
          paginationLimit={limit}
          page={page}
          paginationPagesCount={Math.ceil(total / limit)}
          setPaginationLimit={changeLimit}
          setPage={changePage}
        />
      )}
      <ContentGapsDialog
        isStrategy
        open={isDialogOpen}
        close={() => {
          changeIsDialogOpen(false);
          changeActiveDialogTab("keywords");
        }}
        pageTab={activeDialogTab}
        setPageTab={changeActiveDialogTab}
      />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentGaps);
