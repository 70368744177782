import React, { useMemo } from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import CustomPopover from "../../components/CustomPopover";
import GenericOptionContainer from "../../components/GenericOptionContainer";
import { IWidget } from "../../../../../../../reducers/constants/objectTypes";
import InputSlider from "../../components/InputSlider";
import ColorPickerRow from "../../../../../../WebsiteConfigurations/components/ColorPickerRow";
import { colors } from "../../../../../../../helpers/constants";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {},
  title: {
    fontSize: 14,
    fontWeight: typography.fontWeightMedium as any,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginLeft: 10,
  },
  backgroundPicker: {
    display: "flex",
    flexDirection: "column",
    "& .circle-picker": {
      marginBottom: "0px !important",
      width: "auto !important",
    },
  },
  popoverRoot: {
    minHeight: 360,
  },
  colorPickerRoot: {
    backgroundColor: colors.widgetOnHoverBackgroundColor,
    border: 0,
  },
  colorPreview: ({ background, defaultColor }: PropTypes) => ({
    width: 25,
    height: 25,
    borderRadius: background.borderRadius || "50%",
    border: `1.5px solid ${background.borderColor || palette.common.black}`,
    backgroundColor: background.value || defaultColor,
    marginRight: 8,
  }),
  radiusOptions: {
    display: "flex",
    width: "fit-content",
    borderRadius: 5,
    minWidth: 160,
    "& > div:last-child": {
      borderRadius: "0px 5px 5px 0px",
      flex: 1,
      textAlign: "center",
    },
    "& > div:first-child": {
      borderRadius: "5px 0px 0px 5px",
    },
  },
  radiusOption: {
    padding: 10,
    fontSize: 12,
    color: palette.text.primary,
    backgroundColor: colors.widgetOnHoverBackgroundColor,
    "&:hover": {
      color: palette.common.white,
      backgroundColor: palette.primary.main,
      cursor: "pointer",
    },
  },
  selectedRadiusOption: {
    color: palette.common.white,
    backgroundColor: palette.primary.main,
    "&:hover": {
      backgroundColor: palette.primary.main,
    },
  },
  radiusContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  radiusText: {
    fontSize: 14,
  },
  inputSlider: {
    marginTop: 15,
    "& #input-slider": {
      fontSize: 14,
    },
  },
}));

type PropTypes = {
  background: IWidget["background"];
  defaultColor?: string;
  showCornerRadiusSettings?: boolean;
  onChange: (background: IWidget["background"]) => void;
};

const BackgroundPickerWidgetItem = (props: PropTypes) => {
  const { background, defaultColor, showCornerRadiusSettings } = props;
  const { onChange } = props;

  const classes = useStyles(props);

  const [localBackground, setLocalBackground] = React.useState<IWidget["background"]>({
    ...background,
    value: background.value || defaultColor,
  });

  const [showBackgroundPicker, setShowBackgroundPicker] = React.useState(false);
  const [showRadiusSlider, setShowRadiusSlider] = React.useState(!background.borderRadius);

  const itemRef = React.useRef(null);

  const radiusOptions: (number | "custom")[] = useMemo(() => [0, 8, 24, "custom"], []);

  return (
    <>
      <div ref={itemRef}>
        <GenericOptionContainer onClick={() => setShowBackgroundPicker(true)}>
          <>
            <Typography className={classes.title}>
              {I18n.t("widgets_builder.widget_editor.background_picker.title")}
            </Typography>
            <div className={classes.colorPreview}></div>
          </>
        </GenericOptionContainer>
      </div>
      <CustomPopover
        open={showBackgroundPicker}
        onClose={() => setShowBackgroundPicker(false)}
        anchorEl={itemRef.current}
        onDone={() => onChange(localBackground)}
        label={I18n.t("widgets_builder.widget_editor.background_picker.title")}
        className={classes.popoverRoot}
      >
        <div className={classes.backgroundPicker}>
          <ColorPickerRow
            value={localBackground.value}
            handleChange={(value) => setLocalBackground({ ...localBackground, value })}
            rowTitle={I18n.t("widgets_builder.widget_editor.background_picker.title")}
            isValueChanged={false}
            hideOpacityInput
            classes={{
              colorPickerRoot: classes.colorPickerRoot,
            }}
          />

          <ColorPickerRow
            value={localBackground.borderColor}
            defaultValue={"#000000"}
            handleChange={(value) => setLocalBackground({ ...localBackground, borderColor: value })}
            rowTitle={I18n.t("widgets_builder.widget_editor.background_picker.border_color")}
            isValueChanged={false}
            hideOpacityInput
            classes={{
              colorPickerRoot: classes.colorPickerRoot,
            }}
          />

          {showCornerRadiusSettings && (
            <>
              <div className={classes.radiusContainer}>
                <Typography variant="body1" className={classes.radiusText}>
                  {I18n.t("widgets_builder.widget_editor.background_picker.border_radius")}
                </Typography>
                <div className={classes.radiusOptions}>
                  {radiusOptions.map((borderStyle, index) => (
                    <div
                      onClick={() => {
                        if (borderStyle === "custom") {
                          setShowRadiusSlider(true);
                        } else {
                          setLocalBackground({ ...localBackground, borderRadius: borderStyle });
                          if (showRadiusSlider) setShowRadiusSlider(false);
                        }
                      }}
                      className={ClassNames(
                        classes.radiusOption,
                        (localBackground.borderRadius === borderStyle && !showRadiusSlider) ||
                          (borderStyle === "custom" && showRadiusSlider)
                          ? classes.selectedRadiusOption
                          : ""
                      )}
                      key={index}
                    >
                      {borderStyle === "custom"
                        ? I18n.t("widgets_builder.widget_editor.background_picker.custom")
                        : borderStyle}
                    </div>
                  ))}
                </div>
              </div>
              {showRadiusSlider && (
                <InputSlider
                  value={localBackground.borderRadius || 5}
                  min={0}
                  max={48}
                  adornmentText="px"
                  label={I18n.t("widgets_builder.widget_editor.background_picker.radius")}
                  onChange={(value) => setLocalBackground({ ...localBackground, borderRadius: value })}
                  customClass={classes.inputSlider}
                />
              )}
            </>
          )}
        </div>
      </CustomPopover>
    </>
  );
};

export default BackgroundPickerWidgetItem;
