import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import debounce from "lodash/debounce";
import { useSnackbar } from "notistack";
import { FUNNELS } from "../../../../../../reducers/constants/actionTypes";
import FunnelStepFilter from "./inputs/FunnelStepFilter";
import { IWebsite } from "../../../../../../reducers/constants/objectTypes";
import FunnelBaseSelect from "../../FunnelBaseSelect";
import FunnelSideBarTextInput from "./inputs/FunnelSideBarTextInput";
import agent from "../../../../../../agent";
import { removeInvalidFilters } from "../../../../../../helpers/funnelHelper";

const useStyles = makeStyles((theme: Theme) => ({
  stepWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  stepEventWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
  },
  stepTitleWrapper: {
    display: "flex",
    justifyContent: "start",
    gap: 10,
    alignItems: "center",
  },
  stepTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: "#141414",
    whiteSpace: "nowrap",
  },
}));

const mapStateToProps = (state) => ({
  config: state.funnels.config,
  eventNames: state.funnels.eventNames,
  selectedWebsite: state.home.selectedWebsite,
  dynamicColumns: state.funnels.dynamicColumns,
});

const mapDispatchToProps = (dispatch) => ({
  updateConfig(payload) {
    dispatch({ type: FUNNELS.FUNNEL_CONFIG_UPDATED, payload });
  },
  setDynamicColumns(payload) {
    dispatch({ type: FUNNELS.FUNNELS_DYNAMIC_COLUMNS_LOADED, payload });
  },
});

type Props = {
  index: number;
  config: any;
  eventNames: any[];
  updateConfig: (payload) => void;
  selectedWebsite: IWebsite;
  setDynamicColumns: (payload) => void;
  dynamicColumns: any[];
  step: any;
  removeStep: (index: number) => void;
};

const FunnelStep = (props: Props) => {
  const classes = useStyles();

  const {
    index,
    config,
    selectedWebsite,
    removeStep,
    eventNames,
    updateConfig,
    dynamicColumns,
    setDynamicColumns,
    step,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const currentStep = useMemo(
    () =>
      config.steps?.[index] || {
        events: [
          {
            condition: "",
            value: "",
            filters: [],
          },
        ],
      },
    [config]
  );

  console.log({ step });

  const [stepDynamicColumns, setStepDynamicColumns] = useState([]);

  useEffect(() => {
    if (Array.isArray(step?.events) && step.events.length > 0) {
      const eventName = step.events[0].value;

      if (dynamicColumns[eventName]) {
        setStepDynamicColumns(dynamicColumns[eventName]);
      } else if (eventName) {
        agent.Funnels.getDynamicColumns(selectedWebsite, eventName)
          .then((res) => {
            setStepDynamicColumns(res.data);
            setDynamicColumns({ eventName, data: res.data });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [step]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const currentStepEvents = useMemo(() => currentStep?.events || [], [currentStep]);

  const updateStepName = debounce((text) => {
    const newConfig = { ...config };
    if (!newConfig.steps?.[index]) {
      const steps = newConfig.steps || [];
      steps.push({
        title: text,
        events: [
          {
            condition: "",
            value: "",
            filters: [],
          },
        ],
      });
      newConfig.steps = [...steps];
    } else {
      newConfig.steps[index] = { ...newConfig.steps[index], title: text };
    }

    updateConfig(newConfig);
  }, 1500);
  return (
    <div className={classes.stepWrapper}>
      <div className={classes.stepTitleWrapper}>
        <Typography className={classes.stepTitle}>{`Step ${index + 1}`}</Typography>
        <FunnelSideBarTextInput
          borderRadius={3}
          defaultValue={currentStep?.title || ""}
          placeholder={I18n.t("analytics.funnels.sidebar.labels.step_ph")}
          onChange={(text) => {
            updateStepName(text);
          }}
          textFieldId={"funnel-step-name"}
        />
      </div>
      {currentStepEvents.map((event, eventIndex) => (
        <div className={classes.stepEventWrapper}>
          <FunnelBaseSelect
            optionLabel={"label"}
            borderRadius={event.filters?.length > 0 ? "5px 5px 0px 0px" : 3}
            optionValue={"value"}
            placeholder={"Select event..."}
            onClear={() => {
              removeStep(index);
            }}
            onFilterClick={() => {
              setMenuIsOpen(false);
              const newConfig = { ...config };
              if (
                newConfig.steps[index].events[eventIndex].filters.length === 0 ||
                newConfig.steps[index].events[eventIndex].filters[
                  newConfig.steps[index].events[eventIndex].filters.length - 1
                ]?.field != null
              ) {
                newConfig.steps[index].events[eventIndex].filters.push({
                  column: "",
                  condition: "is",
                  value: "",
                });
                updateConfig(newConfig);
              }
            }}
            options={eventNames}
            value={eventNames.find((e) => e.value === event.value)}
            handleOnChange={(t) => {
              const newConfig = { ...config };
              if (!newConfig.steps?.[index]) {
                const steps = newConfig.steps || [];
                steps.push({
                  title: t.label,
                  events: [
                    {
                      condition: "",
                      value: t.value,
                      filters: [],
                    },
                  ],
                });
                newConfig.steps = [...steps];
              } else {
                newConfig.steps[index].events[eventIndex].value = t.value;
                const validFilters = removeInvalidFilters(newConfig.steps[index].events[eventIndex]);
                if (validFilters.length !== newConfig.steps[index].events[eventIndex].filters.length) {
                  newConfig.steps[index].events[eventIndex].filters = validFilters;
                  enqueueSnackbar("removed invalid filters ", { variant: "warning" });
                }
                if (!newConfig.steps[index].title) {
                  newConfig.steps[index].title = t.label;
                }
              }

              updateConfig(newConfig);
            }}
          />
          {event.filters.map((filter, filterIndex) => (
            <FunnelStepFilter
              dynamicColumns={stepDynamicColumns}
              updateConfig={updateConfig}
              stepIndex={index}
              eventIndex={eventIndex}
              config={config}
              selectedWebsite={selectedWebsite}
              filter={filter}
              filterIndex={filterIndex}
              key={filterIndex}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FunnelStep);
