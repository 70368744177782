import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

type PropTypes = {
  message?: string;
};
const MTableRowEmptyRow = (props: PropTypes) => {
  const { message } = props;
  return (
    <ListItem>
      <ListItemText primary={message || "No content to show"} />
    </ListItem>
  );
};

export default MTableRowEmptyRow;
