import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import find from "lodash/find";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItem from "@material-ui/core/ListItem";
import moment from "moment/moment";
import CfButton from "../../../../../components/CfButton";
import MTextField from "../../../../../components/MTextField";
import MSelect from "../../../../../components/MSelect";
import { IAdminUser, IWebsite } from "../../../../../reducers/constants/objectTypes";
import UserWebsitesPicker from "./UserWebsitesPicker";
import UserPostTypesPicker from "./UserPostsTypePicker";
import UserPPWPicker from "./UserPPWPicker";
import UserLocalesPicker from "./UserLocalesPicker";
import { AbilityContext } from "../../../../../casl/can";

const tableBorder = "transparent";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    display: "block",
    padding: 20,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: "white",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      background: theme.palette.secondary.main,
      transition: "500ms",
      "& $editIcon": {
        visibility: "unset",
        width: "unset",
        marginRight: 28,
      },
    },
  },
  rowWrapper: {
    display: "flex",
    flexFlow: "row wrap",
    borderLeft: `solid 1px ${tableBorder}`,
    transition: "0.5s",
    "&:hover": {
      background: theme.palette.secondary.main,
      transition: "500ms",
      borderRadius: 50,
    },
  },
  firstColumn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    width: "20%",
  },
  column: {
    flexGrow: 1,
    flexBasis: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  nameWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },

  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    marginRight: 5,
    marginLeft: 5,
    paddingRight: 10,
    paddingLeft: 10,
    minWidth: 0,
    minHeight: 30,
  },
  textField: {
    margin: 0,
  },
  textFieldInput: {},
  deleteIcon: {
    fontSize: theme.typography.pxToRem(24),
    cursor: "pointer",
    marginLeft: 10,
    // width: 0,
    "&:hover": {
      fill: theme.palette.primary.main,
      transition: "500ms",
    },
  },
}));

type PropTypes = {
  user: IAdminUser;
  websites: IWebsite[];

  handleUserChanged: Function;
  handleSaveClick: Function;
  handleCancelClick: Function;
  handleDeleteUserClick?: Function;
};

const EditUserRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { user, websites, handleUserChanged, handleSaveClick, handleCancelClick, handleDeleteUserClick } = props;
  const ability = React.useContext<any>(AbilityContext);

  const roles = React.useMemo(
    () => [
      { role: "owner", disabled: true },
      { role: "admin", disabled: true },
      { role: "content_manager", disabled: ability.can("hr", "role") },
      // { role: "analyst", disabled: ability.can("hr", "role") },
      { role: "editor", disabled: ability.can("hr", "role") },
      { role: "hr", disabled: ability.can("hr", "role") },
      { role: "expert" },
      { role: "writer" },
      { role: "limited_writer" },
      { role: "credited_writer" },
      { role: "customer", disabled: ability.can("hr", "role") },
    ],
    [ability]
  );

  return (
    <ListItem className={classes.itemWrapper}>
      <div className={classes.rowWrapper}>
        <div className={classes.firstColumn} role="cell">
          <div className={classes.nameWrapper}>
            <MTextField
              customClass={classes.textField}
              inputClassName={classes.textFieldInput}
              type={"text"}
              onChange={(text) => handleUserChanged({ ...user, name: text })}
              value={user.name}
              placeholder={I18n.t("manage_content.users.placeholders.name")}
            />
            <div className={classes.buttonsWrapper}>
              <CfButton
                customClass={classes.button}
                onClick={(e) => handleSaveClick()}
                text={I18n.t("manage_content.users.headers.save")}
              />
              <CfButton
                customClass={classes.button}
                outlined
                onClick={(e) => handleCancelClick()}
                text={I18n.t("manage_content.users.headers.cancel")}
              />
              {handleDeleteUserClick && (
                <DeleteIcon
                  className={classes.deleteIcon}
                  onClick={(e) => {
                    if (confirm(`Are you sure you want to remove ${user.name}?`)) {
                      handleDeleteUserClick(user);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className={classes.column} role="cell">
          {user.lastActiveDate ? moment(user.lastActiveDate).format("DD-MM-YYYY") : "-"}
        </div>
        <div className={classes.column} role="cell">
          <MTextField
            customClass={classes.textField}
            inputClassName={classes.textFieldInput}
            type={"text"}
            onChange={(text) => handleUserChanged({ ...user, username: text })}
            value={user.username}
            placeholder={I18n.t("manage_content.users.placeholders.username")}
          />
        </div>
        <div className={classes.column} role="cell">
          <MSelect
            options={roles}
            value={find(roles, { role: user.role })}
            fullWidth
            optionLabel={"role"}
            getDisplayLabel={(item) => I18n.t(`manage_content.users.roles.${item.role}`)}
            height={30}
            handleChange={(role) => {
              handleUserChanged({ ...user, role: role.role });
            }}
          />
        </div>
        <div className={classes.column} role="cell">
          {user.role === "admin" || user.role === "owner" ? (
            "All"
          ) : (
            <UserWebsitesPicker websites={websites} user={user} handleUserChanged={handleUserChanged} />
          )}
        </div>
        <div className={classes.column} role="cell">
          <UserLocalesPicker websites={websites} user={user} handleUserChanged={handleUserChanged} />
        </div>
        {/* <div className={classes.column} role="cell"> */}
        {/*  <UserPostTypesPicker user={user} handleUserChanged={handleUserChanged} /> */}
        {/* </div> */}
        <div className={classes.column} role="cell">
          <UserPPWPicker user={user} handleUserChanged={handleUserChanged} />
        </div>
        <div className={classes.column} role="cell">
          <UserPPWPicker ppw={false} user={user} handleUserChanged={handleUserChanged} />
        </div>
        <div className={classes.column} role="cell">
          <MTextField
            customClass={classes.textField}
            inputClassName={classes.textFieldInput}
            type={"email"}
            onChange={(text) => handleUserChanged({ ...user, email: text?.toLowerCase() || "" })}
            value={user.email}
            placeholder={I18n.t("manage_content.users.placeholders.email")}
          />
        </div>
      </div>
    </ListItem>
  );
};

export default EditUserRow;
