import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { clients, withHttps } from "../../../../../../helpers/urlHelper";
import { IQuestion, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import MLink from "../../../../../../components/MLink";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(14),
    borderBottom: 10,
  },
  questionUrlWrapper: {
    width: "100%",
    marginBottom: 20,
  },
  url: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "17px",
    color: "#4353FF",
    overflow: "hidden",
    wordBreak: "break-word",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedQuestion: IQuestion;
};

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,
    selectedQuestion: state.communityEditor.question,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const CommunityEditorUrl = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, selectedQuestion } = props;

  if (!selectedQuestion.uploaded) {
    return null;
  }

  const url = clients.questionsPage(selectedWebsite, selectedQuestion);
  return (
    <div className={classes.questionUrlWrapper}>
      <Typography className={classes.title} variant={"subtitle2"}>
        {I18n.t("edit_post.url")}
      </Typography>
      {selectedQuestion && (
        <MLink typographyClassName={classes.url} newTab href={withHttps(url)}>
          {url}
        </MLink>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorUrl);
