import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useRouteMatch } from "react-router";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { web } from "../../helpers/urlHelper";
import {
  IWebsite,
  IWebsiteCommonComponents,
  IWebsiteDynamicSectionProps,
  IWebsiteSectionProps,
} from "../../reducers/constants/objectTypes";
import theme from "../../themes/theme";
import { WEBSITE_PAGES } from "../../reducers/constants/actionTypes";
import agent from "../../agent";
import CfProgressBar from "../../components/CfProgressBar";
import DesignTheme from "./components/DesignTheme";
import ContentPages from "./components/ContentPages";
import EditorialPages from "./components/EditorialPages";
import Components from "./components/Components";
import DeveloperTools from "./components/DeveloperTools";
import LandingPages from "../LandingPages/LandingPages";
import EditorialPolicyBottomBar from "../Manager/Pages/EditorialPolicy/components/EditorialPolicyBottomBar";
import WebsiteBottomBar from "../WebsiteConfigurations/WebsiteEditor/WebsiteBottomBar";
import { AbilityContext } from "../../casl/can";
import SEO from "../SEO/SEO";

const useStyles = makeStyles(() => ({
  pageWrapper: {
    height: "calc(100vh - 60px)",
    width: "100%",
    display: "flex",
  },
  leftSide: {
    width: "100%",
    maxWidth: 317,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRight: `solid 1px ${theme.palette.divider}`,
    padding: "20px 0",
    overflow: "auto",
  },
  rightSide: {
    width: "100%",
    overflow: "auto",
  },
  rightSideContent: {
    width: "100%",
    maxWidth: 840,
    margin: "20px 40px 0 40px",
  },
  sideMenuTabSelected: {
    color: `${theme.palette.primary.main} !important`,
    "&:focus": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  sideMenuTab: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.text.primary,
    padding: "7px 0px 7px 30px",
    "&:hover": {
      transition: "500ms",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  type: {
    fontSize: 14,
    marginBottom: 15,
    padding: "0 20px",
  },
  disabledSection: {
    color: theme.palette.text.disabled,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  currentWebsiteId: string;
  websiteConfigurationsLoaded: boolean;
  isEditorial: boolean;
  sections: IWebsiteSectionProps;
  commonComponents: IWebsiteCommonComponents;
  dynamicSections: IWebsiteDynamicSectionProps[];
  handleWebsiteConfigurationsLoaded: Function;
  setConfigurationLoading: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  currentWebsiteId: state.websitePages.id,
  isEditorial: state.editorial.isEditorial,
  dynamicSections: state.websitePages.dynamicSections,
  sections: state.websitePages.sections,
  commonComponents: state.websitePages.commonComponents,
  websiteConfigurationsLoaded: state.websitePages.websiteConfigurationsLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  setConfigurationLoading: (value) => dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_CONFIGURATIONS_LOADING, value }),
  handleWebsiteConfigurationsLoaded: (payload) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_CONFIGURATIONS_LOADED, payload }),
});

const PageBuilder = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    websiteConfigurationsLoaded,
    handleWebsiteConfigurationsLoaded,
    setConfigurationLoading,
    currentWebsiteId,
    isEditorial,
    sections,
    commonComponents,
    dynamicSections,
  } = props;
  const location = useLocation();
  const ability = React.useContext<any>(AbilityContext);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    if (selectedWebsite._id !== currentWebsiteId) {
      setConfigurationLoading(true);
    }
    handleWebsiteConfigurationsLoaded(agent.Websites.getByID(selectedWebsite._id));
  }, [selectedWebsite, handleWebsiteConfigurationsLoaded, location]);

  const match = useRouteMatch(`${web.homePage()}/page-builder/:page`);

  const sideMenuLinksSet = React.useMemo(() => {
    if (!match?.params?.page) {
      return [];
    }
    console.log("match.params.page", match.params.page);
    switch (match.params.page) {
      case "theme":
        return {
          type: "designTheme",
          canBeDisabled: false,
          children: [
            {
              url: web.theme("color-theme"),
              label: I18n.t("website.page_types.colorTheme"),
              canBeDisabled: false,
              type: "colorTheme",
            },
            {
              url: web.theme("article"),
              label: I18n.t("website.page_types.article"),
              canBeDisabled: false,
              type: "article",
            },
            {
              url: web.theme("main-page-typography"),
              label: I18n.t("website.page_types.mainPageTypography"),
              canBeDisabled: false,
              type: "mainPageTypography",
            },
            {
              url: web.theme("content-elements"),
              label: I18n.t("website.page_types.contentElements"),
              canBeDisabled: false,
              type: "contentElements",
            },
            // {
            //   url: web.theme("tables"),
            //   label: I18n.t("website.page_types.tables"),
            //   canBeDisabled: false,
            //   type: "tables",
            // },
            {
              url: web.theme("images"),
              label: I18n.t("website.page_types.images"),
              canBeDisabled: false,
              type: "images",
            },
          ],
        };
      case "content-pages":
        return {
          type: "contentPages",
          canBeDisabled: false,
          children: [
            {
              url: web.contentPages("resource-page"),
              label: I18n.t("website.page_types.resourcePage"),
              canBeDisabled: true,
              type: "resourcePage",
            },
            {
              url: web.contentPages("category"),
              label: I18n.t("website.page_types.category"),
              canBeDisabled: false,
              type: "category",
            },
            {
              url: web.contentPages("topic"),
              label: I18n.t("website.page_types.topic"),
              canBeDisabled: false,
              type: "topic",
            },
            {
              url: web.contentPages("homepage"),
              label: I18n.t("website.page_types.homepage"),
              canBeDisabled: true,
              type: "homepage",
            },
            {
              url: web.contentPages("blog"),
              label: I18n.t("website.page_types.blog"),
              canBeDisabled: true,
              type: "blog",
            },
            {
              url: web.contentPages("forum"),
              label: I18n.t("website.page_types.community"),
              canBeDisabled: true,
              type: "forum",
            },
            {
              url: web.contentPages("learn"),
              label: I18n.t("website.page_types.targeted"),
              canBeDisabled: true,
              type: "learn",
            },
            {
              url: web.contentPages("product-review"),
              label: I18n.t("website.page_types.productReview"),
              canBeDisabled: true,
              type: "productReview",
            },
            {
              url: web.contentPages("product-page"),
              label: I18n.t("website.page_types.productPage"),
              canBeDisabled: true,
              type: "productPage",
            },
            {
              url: web.contentPages("product-comparison"),
              label: I18n.t("website.page_types.productComparison"),
              canBeDisabled: true,
              type: "productComparison",
            },
            {
              url: web.contentPages("comparison-top-alternative"),
              label: I18n.t("website.page_types.comparisonTopAlternative"),
              canBeDisabled: true,
              type: "comparisonTopAlternative",
            },
            {
              url: web.contentPages("comparison-top-10"),
              label: I18n.t("website.page_types.comparisonTop10"),
              canBeDisabled: true,
              type: "comparisonTop10",
            },
            {
              url: web.contentPages("glossary"),
              label: I18n.t("website.page_types.glossary"),
              canBeDisabled: true,
              type: "glossary",
            },
            {
              url: web.contentPages("ppc-top-10"),
              label: I18n.t("website.page_types.ppcTop10"),
              canBeDisabled: true,
              type: "ppcTop10",
            },
            ...dynamicSections.map((section) => ({
              type: section.type,
              canBeDisabled: true,
              name: section.name,
              label: section.name,
              enabled: section.enabled,
              url: web.contentPages(section.type),
            })),
            {
              url: web.contentPages("new-content-page"),
              label: I18n.t("website.page_types.newContentPage"),
              canBeDisabled: false,
              add: true,
              type: "newContentPage",
            },
            {
              url: web.contentPages("static-pages"),
              label: I18n.t("website.page_types.staticPages"),
              canBeDisabled: false,
              type: "staticPages",
            },
          ],
        };
      case "editorial-pages":
        return {
          type: "editorialPages",
          canBeDisabled: false,
          children: [
            {
              url: web.editorialPages("editorial-policy"),
              label: I18n.t("website.page_types.editorialPolicy"),
              canBeDisabled: true,
              type: "editorialPolicy",
            },
            {
              url: web.editorialPages("community-guidelines"),
              label: I18n.t("website.page_types.communityGuidelines"),
              canBeDisabled: true,
              type: "communityGuidelines",
            },
            {
              url: web.editorialPages("content-principles"),
              label: I18n.t("website.page_types.contentPrinciples"),
              canBeDisabled: true,
              type: "contentPrinciples",
            },
            {
              url: web.editorialPages("editorial-team"),
              label: I18n.t("website.page_types.editorialTeam"),
              canBeDisabled: true,
              type: "editorialTeam",
            },
            {
              url: web.editorialPages("fact-checked"),
              label: I18n.t("website.page_types.factChecked"),
              canBeDisabled: true,
              type: "factChecked",
            },
          ],
        };
      case "landing-pages":
        return {
          type: "landingPages",
          canBeDisabled: false,
          children: [],
        };
      case "components":
        return {
          type: "components",
          canBeDisabled: false,
          children: [
            {
              url: web.components("login-popup"),
              label: I18n.t("website.page_types.loginPopup"),
              canBeDisabled: false,
              type: "loginPopup",
            },
            {
              url: web.components("email-subscription"),
              label: I18n.t("website.page_types.emailSubscription"),
              canBeDisabled: true,
              enabled: commonComponents?.sideSubscribe?.enabled,
              type: "emailSubscription",
            },
            {
              url: web.components("table-of-contents"),
              label: I18n.t("website.page_types.toc"),
              canBeDisabled: false,
              type: "toc",
            },
            {
              url: web.components("powered-by"),
              label: I18n.t("website.page_types.poweredBy"),
              canBeDisabled: true,
              enabled: commonComponents?.poweredBy?.enabled,
              type: "poweredBy",
            },
          ],
        };
      case "developer-tools":
        return {
          type: "developerTools",
          canBeDisabled: false,
          children: [
            {
              url: web.developerTools("cms"),
              label: I18n.t("website.page_types.cms"),
              canBeDisabled: false,
              type: "cms",
            },
            {
              url: web.developerTools("site-details"),
              label: I18n.t("website.page_types.siteDetails"),
              canBeDisabled: false,
              type: "siteDetails",
            },
            {
              url: web.developerTools("locale"),
              label: I18n.t("website.page_types.locale"),
              canBeDisabled: false,
              type: "locale",
            },
            {
              url: web.developerTools("links"),
              label: I18n.t("website.page_types.links"),
              canBeDisabled: false,
              type: "links",
            },
            {
              url: web.developerTools("break-points"),
              label: I18n.t("website.page_types.breakPoints"),
              canBeDisabled: false,
              type: "breakPoints",
            },
            {
              url: web.developerTools("analytics"),
              label: I18n.t("website.page_types.analytics"),
              canBeDisabled: false,
              type: "analytics",
            },
            {
              url: web.developerTools("custom-code"),
              label: I18n.t("website.page_types.customCode"),
              canBeDisabled: false,
              type: "customCode",
            },
            {
              url: web.developerTools("gsc"),
              label: I18n.t("website.page_types.gsc"),
              canBeDisabled: false,
              type: "gsc",
            },
            {
              url: web.developerTools("cache-invalidation"),
              label: I18n.t("website.page_types.cache-invalidation"),
              canBeDisabled: false,
              type: "cache-invalidation",
            },
          ],
        };
      // case "header-and-footer":
      //   return {
      //     type: "headerAndFooter",
      //     canBeDisabled: false,
      //     children: [],
      //   };
      case "post":
      case "seo":
        return [];
      default:
        throw new Error(`Unhandled inner page ${match.params.page}`);
    }
  }, [match]);

  const routes = React.useMemo(() => {
    const allRoutes = [];
    if (ability.can("view", "page_builder_theme_tab")) {
      allRoutes.push({
        url: web.theme(),
        route: (
          <Route
            key={`page_builder_${selectedWebsite._id}_theme`}
            exact={false}
            path={web.theme()}
            component={DesignTheme}
          />
        ),
      });
    }
    if (ability.can("view", "page_builder_content_pages_tab")) {
      allRoutes.push({
        url: web.contentPages(),
        route: (
          <Route
            key={`page_builder_${selectedWebsite._id}_content_pages`}
            exact={false}
            path={web.contentPages()}
            render={() => <ContentPages />}
          />
        ),
      });
    }
    if (ability.can("view", "page_builder_editorial_pages_tab")) {
      allRoutes.push({
        url: web.editorialPages(),
        route: (
          <Route
            key={`page_builder_${selectedWebsite._id}_editorial_pages`}
            exact={false}
            path={web.editorialPages()}
            component={EditorialPages}
          />
        ),
      });
    }
    if (ability.can("view", "page_builder_components_tab")) {
      allRoutes.push({
        url: web.components(),
        route: (
          <Route
            key={`page_builder_${selectedWebsite._id}_components`}
            exact={false}
            path={web.components()}
            component={Components}
          />
        ),
      });
    }
    if (ability.can("view", "page_builder_developer_tools_tab")) {
      allRoutes.push({
        url: web.developerTools(),
        route: (
          <Route
            key={`page_builder_${selectedWebsite._id}_developer_tools`}
            exact={false}
            path={web.developerTools()}
            component={DeveloperTools}
          />
        ),
      });
    }
    if (ability.can("view", "page_builder_landing_pages_tab")) {
      allRoutes.push({
        url: web.landingPages(),
        route: (
          <Route
            key={`page_builder_${selectedWebsite._id}_landing_pages`}
            exact={false}
            path={web.landingPages()}
            component={LandingPages}
          />
        ),
      });
    }
    if (ability.can("view", "seo_tab")) {
      allRoutes.push({
        url: web.seo(),
        route: <Route exact={false} key={selectedWebsite._id} path={web.seo()} render={() => <SEO />} />,
      });
    }
    return allRoutes;
  }, [ability, selectedWebsite]);

  const getSectionEnabled = (child) => {
    if (child.enabled === undefined) {
      return !sections?.[child.type];
    }
    return !child.enabled;
  };
  if (sideMenuLinksSet.type === "landingPages") {
    return <LandingPages />;
  }
  if (sideMenuLinksSet?.length === 0) {
    return (
      <Switch>
        {routes.map((route) => route.route)}
        <Redirect exact from={"*"} to={routes[0]?.url} />
      </Switch>
    );
  }

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.leftSide}>
        <Typography variant={"subtitle2"} className={classes.type}>
          {sideMenuLinksSet?.type && I18n.t(`website.page_types.${sideMenuLinksSet.type}`)}
        </Typography>
        {sideMenuLinksSet &&
          sideMenuLinksSet?.children?.map((child) => (
            <NavLink
              key={child.url}
              to={child.url}
              activeClassName={classes.sideMenuTabSelected}
              className={ClassNames(
                classes.sideMenuTab,
                child.canBeDisabled && getSectionEnabled(child) && classes.disabledSection,
                child.add && classes.sideMenuTabSelected
              )}
            >
              {child.label}
            </NavLink>
          ))}
      </div>
      <div className={classes.rightSide}>
        {websiteConfigurationsLoaded && (
          <div className={classes.rightSideContent}>
            <Switch>
              {routes.map((route) => route.route)}
              <Redirect exact from={"*"} to={routes[0]?.url} />
            </Switch>
            {isEditorial ? <EditorialPolicyBottomBar /> : <WebsiteBottomBar />}
          </div>
        )}
        {!websiteConfigurationsLoaded && (
          <div>
            <CfProgressBar />
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageBuilder);
