import { useMemo, useState } from "react";
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import { Avatar } from "@material-ui/core";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import theme from "../../../../themes/theme";
import { getImageCDNUrl } from "../../../../helpers/urlHelper";
import EntailLogo from "../../../../icons/EntailLogo";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 22,
    height: 22,
    // color: "white",
    // backgroundColor: "#787CAE",
    fontSize: 12,
  },
  blackIcon: {
    filter: "grayscale(1)",
  },
  avatarImage: {
    objectFit: "contain",
  },
  logo: {
    fill: (props: PropTypes) => props.color || theme.palette.primary.main,
  },
}));

type PropTypes = {
  website: IWebsite;
  logo?: boolean;
  selected: boolean;
  className?: string;
  defaultLogo?: boolean;
  color?: string;
};

const WebsitesAvatar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website, selected, className, logo = false, defaultLogo = false } = props;

  const avatarStyle = useMemo(() => {
    if (website.configurations?.siteDetails?.favicon?.cdnUrl) {
      return {};
    }
    let hash = 0;
    for (let i = 0; i < website.name.length; i += 1) {
      hash = website.name.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    return {
      backgroundColor: color,
      color: theme.palette.getContrastText(color),
    };
  }, [website]);
  if (defaultLogo) {
    return <EntailLogo className={classes.logo} />;
  }
  return (
    <Avatar
      src={
        logo
          ? getImageCDNUrl(website.configurations?.siteDetails?.logo?.cdnUrl)
          : getImageCDNUrl(website.configurations?.siteDetails?.favicon?.cdnUrl)
      }
      alt={website.name}
      className={ClassNames(classes.avatar, !selected && classes.blackIcon, className)}
      style={avatarStyle}
      classes={{
        img: classes.avatarImage,
      }}
    >
      {website.name?.[0].toUpperCase()}
    </Avatar>
  );
};

export default WebsitesAvatar;
