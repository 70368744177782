import * as React from "react";
import { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { useLocation } from "react-router-dom";
import { IWebsite, IWidget, eWidgetAction, eActionIcon, IPage } from "../../../../../reducers/constants/objectTypes";
import numeral from "numeral";
import MTableRow from "../../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../../components/Table/Cells/MTableCellFirst";
import MTableCellDate from "../../../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";
import MTableCell from "../../../../../components/Table/Cells/MTableCell";
import MActionsDropdown from "../../../../../components/MActionsDropdown";
import { clientPages, web } from "../../../../../helpers/urlHelper";
import MTableCellWithElementListTooltip from "../../../../../components/Table/Cells/MTableCellWithElementListTooltip";
import WidgetAvatar from "../../../../../icons/WidgetAvatar";
import MTableCellNumber from "../../../../../components/Table/Cells/MTableCellNumber";
import { getWidgetType } from "../constants";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
  },
  avatarWrapper: {
    backgroundColor: theme.palette.primary.main,
    height: 25,
    width: 25,
    borderRadius: 100,
    marginRight: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pagesTooltip: {
    maxHeight: 200,
    maxWidth: 450,
    padding: 10,
    background: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    margin: 0,
    marginLeft: "-10px",
    marginTop: 3,
    overflow: "auto",
  },
  imageTooltip: {
    // maxHeight: 200,
    maxWidth: 600,
    width: "100%",
    padding: 10,
    background: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    margin: 0,
    marginLeft: "-10px",
    marginTop: 3,
    overflow: "auto",
  },
  analyticsTooltip: {
    maxHeight: 300,
    maxWidth: 800,
    padding: 10,
    fontWeight: theme.typography.fontWeightRegular as any,
    background: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    margin: 0,
    marginLeft: "-10px",
    marginTop: 3,
    overflow: "auto",
  },
  pageItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 3,
  },
  editPageContainer: {
    display: "flex",
  },
  divider: {
    color: theme.palette.text.primary,
    padding: "2px 5px",
  },
  pageTitle: {
    fontSize: 14,
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  editPageWrapper: {
    flexGrow: 1,
    flexShrink: 0,
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      "& $editIcon": {
        fill: theme.palette.primary.main,
      },
      "& $pageTitle": {
        color: theme.palette.primary.main,
      },
    },
  },
  editIcon: {
    fontSize: 15,
    fill: theme.palette.text.primary,
  },
  linkIcon: {
    fontSize: 14,
    marginLeft: 5,
  },
  widgetImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    // background: "cyan",
  },
  hoveredCell: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type PropTypes = {
  widget: IWidget;
  selectedWebsite: IWebsite;

  timePeriod: any;
  handleClick: (id: string) => void;
  duplicateWidget?: (widget: IWidget) => void;
  deleteWidget: (id: string) => void;
  restoreWidget: (widget: IWidget) => void;
  openWidgetAnalyticsDialog: (widget: IWidget) => void;
};

const WidgetsListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { widget, timePeriod, selectedWebsite } = props;
  const { duplicateWidget, handleClick, deleteWidget, restoreWidget, openWidgetAnalyticsDialog } = props;
  const theme = useTheme();
  const location = useLocation();

  const options = useMemo(() => {
    const actionsArr = [];

    if (widget.status !== "Deleted") {
      actionsArr.push({
        value: eWidgetAction.delete,
        label: I18n.t("widgets_builder.actions.delete"),
        onClick: () => deleteWidget(widget._id),
      });
    }

    if (widget.status === "Deleted") {
      actionsArr.push({
        value: eWidgetAction.restore,
        label: I18n.t("widgets_builder.actions.restore"),
        onClick: () => restoreWidget(widget),
      });
      actionsArr.push({
        value: eWidgetAction.delete,
        label: I18n.t("widgets_builder.actions.delete_permanently"),
        onClick: () => deleteWidget(widget._id),
      });
    }

    if (duplicateWidget) {
      actionsArr.push({
        value: eWidgetAction.duplicate,
        label: I18n.t("widgets_builder.actions.duplicate"),
        onClick: () => duplicateWidget(widget),
      });
    }

    return actionsArr;
  }, [deleteWidget, duplicateWidget, restoreWidget, widget]);

  return (
    <MTableRow
      handleRowClick={() => {
        handleClick(widget._id);
      }}
    >
      <MTableCellFirst
        fontSize={theme.typography.pxToRem(14)}
        columnWidth={"30%"}
        cellText={widget.name}
        prefix={
          <div className={classes.avatarWrapper}>
            <WidgetAvatar />
          </div>
        }
        actions={[
          {
            text: "See photo",
            iconName: eActionIcon.photo,
            onClick: () => null,
            tooltipDelay: 800,
            tooltipClasses: { tooltip: classes.imageTooltip },
            tooltip: (
              <div>
                <img className={classes.widgetImage} src={widget.thumbnail} alt={"widget screenshot"} />
              </div>
            ),
          },
        ]}
      />
      <MTableCellDate date={widget.updatedAt} />
      <MTableCellText text={I18n.t(`widgets_main.list.${widget.status?.toLowerCase()}`)} />
      <MTableCellText text={getWidgetType(widget)} />

      <MTableCellWithElementListTooltip
        columnTypes={["text", "hidden"]}
        tooltipMinWidth={400}
        firstColumnWidth={"100%"}
        titles={[I18n.t("widgets_main.list.campaigns")]}
        getActionValue={(type, row) => {
          console.log("row", row, type, row[1]._id);
          switch (type) {
            case eActionIcon.openInEditor:
              return web.croCampaignEditor(row[1]._id);
            case eActionIcon.copyName:
              return row[0];
            default:
              return null;
          }
        }}
        rows={widget?.campaigns?.map((campaign) => [campaign.name, campaign]) || []}
        actions={[eActionIcon.openInEditor, eActionIcon.copyName]}
      >
        {numeral(widget?.campaigns?.length || 0).format("0,0[.]")}
      </MTableCellWithElementListTooltip>
      <MTableCellWithElementListTooltip
        columnTypes={["text", "hidden"]}
        tooltipMinWidth={400}
        firstColumnWidth={"100%"}
        titles={[I18n.t("widgets_main.list.articles")]}
        getActionValue={(type, row) => {
          switch (type) {
            case eActionIcon.openInNew:
              return clientPages.getPageUrl(row[1] as IPage, selectedWebsite);
            case eActionIcon.openInEditor:
              return web.editPage(row[1]._id);
            case eActionIcon.copyName:
              return row[0];
            default:
              return null;
          }
        }}
        rows={widget?.pages?.map((page) => [page.title, page]) || []}
        actions={[eActionIcon.openInNew, eActionIcon.copyName, eActionIcon.openInEditor]}
      >
        {numeral(widget?.pages?.length || 0).format("0,0[.]")}
      </MTableCellWithElementListTooltip>

      <MTableCellWithElementListTooltip
        columnTypes={["text", "hidden"]}
        tooltipMinWidth={400}
        firstColumnWidth={"100%"}
        titles={[I18n.t("widgets_main.list.products")]}
        getActionValue={(type, product) => {
          switch (type) {
            case eActionIcon.openInEditor:
              return web.croProducts(product[1]._id);
            case eActionIcon.copyName:
              return product[0];
            default:
              return null;
          }
        }}
        rows={widget.items.filter((i) => i.product)?.map((item) => [item.product?.name, item.product]) || []}
        actions={[eActionIcon.copyName, eActionIcon.openInEditor]}
      >
        {numeral(widget.items.filter((i) => i.product)?.length || 0).format("0,0[.]")}
      </MTableCellWithElementListTooltip>

      <MTableCellNumber
        className={classes.hoveredCell}
        onClick={(e) => {
          e.stopPropagation();
          openWidgetAnalyticsDialog(widget);
        }}
        value={widget.analytics?.[timePeriod.key]?.totalLoads || 0}
      />

      <MTableCellNumber
        className={classes.hoveredCell}
        onClick={(e) => {
          e.stopPropagation();
          openWidgetAnalyticsDialog(widget);
        }}
        value={widget.analytics?.[timePeriod.key]?.totalWidgetViews || 0}
      />

      <MTableCellNumber
        className={classes.hoveredCell}
        onClick={(e) => {
          e.stopPropagation();
          openWidgetAnalyticsDialog(widget);
        }}
        value={widget.analytics?.[timePeriod.key]?.totalClicks || 0}
      />
      <MTableCellNumber
        className={classes.hoveredCell}
        onClick={(e) => {
          e.stopPropagation();
          openWidgetAnalyticsDialog(widget);
        }}
        value={(widget.analytics?.[timePeriod.key]?.ctr || 0) * 1000}
      />
      <MTableCell>
        <MActionsDropdown options={options} />
      </MTableCell>
    </MTableRow>
  );
};

export default WidgetsListItem;
