import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import TagDetails from "./components/TagDetails";
import TagRightSidebar from "./components/TagRightSidebar";
import TagsList from "./components/TagsList";

const useStyles = makeStyles((theme: Theme) => ({
  topicsWrapper: {
    overflow: "hidden",
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    height: "calc(100vh - 130px)",
    margin: "25px 20px 0 20px",
  },
}));

type PropTypes = {};

const TagPages = (props: PropTypes) => {
  const classes = useStyles(props);

  return (
    <div className={classes.topicsWrapper}>
      <TagsList />
      <TagDetails />
      <TagRightSidebar />
    </div>
  );
};

export default TagPages;
