import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Draggable } from "react-beautiful-dnd";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";
import { colors } from "../../../helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  widgetsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2px",
    marginBottom: 10,
    width: "100%",
    overflow: "hidden",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  widgetDragWrapper: {
    cursor: "grab",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
    backgroundColor: theme.palette.secondary.main,
    textAlign: "center",
    "&:hover": {
      backgroundColor: colors.widgetOnHoverBackgroundColor,
      "& $widgetIcon": {
        fill: theme.palette.primary.main,
      },
      "& $caption": {
        color: theme.palette.primary.main,
      },
    },
  },
  widgetDragWrapperClone: {
    "& + div": {
      display: "none !important",
    },
  },
  caption: {
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "14px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingTop: 10,
  },
  iconWrapper: {
    padding: 5,
    width: 35,
    height: 35,
    display: "flex",
    alignItems: "center",
    borderRadius: 3,
    backgroundColor: (props: PropTypes) => props.widget.backgroundColor,
  },
}));

type PropTypes = {
  widget: any;
  index: number;
};

const PageEditorElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { widget, index } = props;

  const WidgetIcon = widget.icon;
  return (
    <Draggable draggableId={widget.type} index={index} type={widget.type}>
      {(provided, snapshot) => (
        <>
          <div
            className={classes.widgetDragWrapper}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
          >
            {/*<div className={classes.iconWrapper}>*/}
            <WidgetIcon />
            {/*</div>*/}
            <Typography className={classes.caption} variant={"body1"}>
              {I18n.t(widget.nameKey)}
            </Typography>
          </div>
          {snapshot.isDragging && (
            <div
              className={classNames(
                // i + 1 === elements[key].length ? classes.widgetDragWrapperClone : "",
                classes.widgetDragWrapper
              )}
            >
              <WidgetIcon />
              <Typography className={classes.caption} variant={"body1"}>
                {I18n.t(widget.nameKey)}
              </Typography>
            </div>
          )}
        </>
      )}
    </Draggable>
  );
};

export default PageEditorElement;
