import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { Editor } from "slate";
import { ePageType, IPage, IProduct, IWebsite } from "../../../reducers/constants/objectTypes";
import PPCTop10DefaultStyle from "./ppc/PPCTop10DefaultStyle";
import { emitEvent } from "../../../hooks/useEvents";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  inputRoot: {
    width: 80,
    border: `solid 1px ${theme.palette.divider}`,
    padding: "0 5px",
  },
}));

type PropTypes = {
  editor: Editor;
  page: IPage;
  selectedWebsite: IWebsite;
};

const DocumentStyle = (props: PropTypes) => {
  const { editor, page, selectedWebsite } = props;

  switch (page?.type) {
    // case ePageType.ComparisonTop10:
    // case ePageType.ComparisonTopAlternatives:
    //   return <div>{"Page Design"}</div>;
    case ePageType.PpcTop10:
      return <PPCTop10DefaultStyle selectedWebsite={selectedWebsite} editor={editor} page={page} />;
    default:
      emitEvent("edit-design", { selected: false, open: false });
      return null;
  }
};

export default DocumentStyle;
