import React from "react";
import { ImageList, ImageListItem } from "@material-ui/core";
import ClassNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { getImageCDNUrl } from "../../../../helpers/urlHelper";
import { IFile, IWebsite } from "../../../../reducers/constants/objectTypes";
import ImageDragAndDrop from "./ImageDragAndDrop";
import { validPxSizeImage } from "../../../../helpers/imagePickerHelper";

const useStyles = makeStyles((theme: Theme) => ({
  gridRoot: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    boxSizing: "border-box",
    width: "100%",
    height: "calc(80vh - 226px)",
    paddingTop: "2.5px",
  },
  imageTile: {
    width: "14.4% !important",
    marginLeft: 15,
    marginBottom: 15,
    cursor: "pointer",
  },
  selectedImageTile: {
    borderColor: "#ff0083",
    borderWidth: 3,
    borderStyle: "solid",
  },
  selectedImageIconWrapper: {
    position: "absolute",
    zIndex: 5,
    top: 11,
    left: 10,
    background: "white",
    width: 24,
    height: 24,
    borderRadius: "100%",
  },
  selectedImageIcon: {
    color: theme.palette.primary.main,
  },
}));

type PropTypes = {
  searchText: string;
  website: IWebsite;
  imageSizeLimit: number;
  loading: boolean;
  forcesImageSize: {
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
    ratio?: number;
  };
  validImage: boolean;
  files: IFile[];
  selectedFile: IFile;
  scrollableListRef: any;
  setFiles: (files) => void;
  setSelectedFile: (selectedFile) => void;
  setValidImage: (value) => void;
};

const ImagesManager = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    website,
    searchText,
    forcesImageSize,
    imageSizeLimit,
    loading,
    files,
    selectedFile,
    scrollableListRef,
    validImage,
    setValidImage,
    setSelectedFile,
    setFiles,
  } = props;

  const handleImageSelected = async (file) => {
    if (await validPxSizeImage(file.url, forcesImageSize)) {
      setValidImage(true);
      setSelectedFile(file);
      return;
    }
    setValidImage(false);
    setSelectedFile(null);
  };

  const getFilteredFiles = () => {
    if (!searchText || searchText === "" || !files) {
      return files;
    }
    const testStr = new RegExp(`.*${searchText}.*`, "gi");
    return files.filter(
      (file) =>
        testStr.test(file.title) || testStr.test(file.fileName) || testStr.test(file.alt) || testStr.test(file.caption)
    );
  };

  const filteredFiles = getFilteredFiles();

  return (
    <div className={classes.gridRoot}>
      <ImageList ref={scrollableListRef} rowHeight={100} className={classes.gridList} cols={6}>
        <ImageDragAndDrop
          website={website}
          validImage={validImage}
          imageSizeLimit={imageSizeLimit}
          loading={loading}
          forcesImageSize={forcesImageSize}
          files={files}
          setSelectedFile={setSelectedFile}
          setFiles={setFiles}
          setValidImage={setValidImage}
        />
        {!loading &&
          filteredFiles &&
          filteredFiles.map((file, i) => (
            <ImageListItem
              className={ClassNames(
                classes.imageTile,
                selectedFile && file._id === selectedFile._id && classes.selectedImageTile
              )}
              key={`${file._id}_${i}`}
              cols={1}
              onClick={(e) => handleImageSelected(file)}
            >
              {file._id === selectedFile?._id && (
                <span className={classes.selectedImageIconWrapper}>
                  <CheckCircleIcon className={classes.selectedImageIcon} />{" "}
                </span>
              )}
              <img
                loading={"lazy"}
                src={getImageCDNUrl(file.thumbnailUrl || file.cdnUrl || file.url)}
                alt={file.title}
              />
            </ImageListItem>
          ))}
      </ImageList>
    </div>
  );
};

export default ImagesManager;
