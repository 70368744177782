import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import { IExpert } from "../../../../../reducers/constants/objectTypes";
import ExpertWebsites from "./ExpertWebsites";
import ExpertLocales from "./ExpertLocales";
import ExpertCollapsibleCard from "./ExpertCollapsibleCard";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";

const useStyles = makeStyles((theme: Theme) => ({
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    "&:first-child": {
      marginTop: 0,
    },
  },
  rowGaps: {
    gap: 10,
  },
  textFieldWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  fieldTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    minWidth: 140,
    marginRight: 15,
  },
  profilePicWrapper: {
    marginRight: 40,
  },
  divider: {
    marginLeft: -30,
    marginRight: -30,
    marginTop: 20,
    marginBottom: 20,
  },
}));

type PropTypes = {
  expert: IExpert;

  handleExpertChange: (expert: IExpert) => void;
};

const ExpertAuthorization = (props: PropTypes) => {
  const classes = useStyles(props);
  const { expert, handleExpertChange } = props;

  return (
    <ExpertCollapsibleCard title={I18n.t("experts.profile.settings")}>
      <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
        <ExpertWebsites
          selectedWebsites={expert.user?.websites}
          handleWebsitesChange={(websites) => {
            handleExpertChange({ ...expert, user: { ...expert.user, websites } });
          }}
        />
      </div>
      <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
        <ExpertLocales
          selectedLocales={expert.user?.locales}
          handleLocalesChange={(locales) => {
            console.log("locales", locales);
            handleExpertChange({ ...expert, user: { ...expert.user, locales } });
          }}
        />
      </div>
      <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
        <TextFieldMaxLength
          key={`expert_slug`}
          titleAndLengthLocation={"top"}
          value={expert.slug}
          disabled={true}
          placeholder={I18n.t("experts.profile.write_ph")}
          title={I18n.t("experts.profile.slug")}
          borderRadius={4}
          fullWidth
          handleValueChange={() => {}}
        />
      </div>
    </ExpertCollapsibleCard>
  );
};

export default ExpertAuthorization;
