import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldProperty, { TextFieldPropertyTypes } from "../itemProperties/components/TextFieldProperty";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    borderRadius: 3,
    overflow: "hidden",
    marginTop: (props: PropTypes) => props.marginTop || 0,
    marginBottom: (props: PropTypes) => props.marginBottom || 0,
    "& .MuiInput-multiline": {
      padding: 0,
    },
  },
}));

type PropTypes = Omit<TextFieldPropertyTypes, "onChange"> & {
  onChange: (properties: { value: string }) => void;
  marginTop?: number;
  marginBottom?: number;
};

const GenericValueItemProperty = (props: PropTypes) => {
  const classes = useStyles(props);
  const { onChange } = props;
  const {
    value = "",
    min = 0,
    max,
    placeholder = I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder"),
  } = props;

  return (
    <div className={classes.root}>
      <TextFieldProperty
        placeholder={placeholder}
        {...props}
        onChange={(val) => onChange({ value: val })}
        value={value}
        min={min}
        max={max}
      />
    </div>
  );
};

export default GenericValueItemProperty;
