import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  textFields: {
    width: "100%",
    maxWidth: 661,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
    border: "none",
    borderRadius: 40,
    "& > *": {
      height: 42,
      borderRadius: 40,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium as any,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
        fontWeight: theme.typography.fontWeightLight as any,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 20px",
      [theme.breakpoints.down("md")]: {
        padding: "10px 15px",
      },
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(25px, 14px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
}));

type PropTypes = {
  type?: string;
  value: string;
  onChange: (string) => void;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
};

const FlowTextField = (props: PropTypes) => {
  const classes = useStyles(props);
  const { type = "text", value, placeholder, onChange, error, errorMessage, className } = props;

  return (
    <TextField
      id="outlined-required"
      error={error}
      value={value}
      type={type}
      className={ClassNames(classes.textFields, className)}
      placeholder={placeholder}
      helperText={error ? errorMessage : ""}
      onChange={(event) => onChange(event.target.value)}
      variant="outlined"
    />
  );
};

export default FlowTextField;
