import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function SmallTitleIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 24 28"}>
      <path d="M3.000,6.000 L6.000,6.000 L6.000,17.000 C6.000,18.660 7.340,20.000 9.000,20.000 C10.660,20.000 12.000,18.660 12.000,17.000 L12.000,6.000 L15.000,6.000 C16.660,6.000 18.000,4.660 18.000,3.000 C18.000,1.340 16.660,-0.000 15.000,-0.000 L3.000,-0.000 C1.340,-0.000 0.000,1.340 0.000,3.000 C0.000,4.660 1.340,6.000 3.000,6.000 Z" />
    </SvgIcon>
  );
}

export default SmallTitleIcon;
