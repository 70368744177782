import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { FUNNELS } from "../../../../../../reducers/constants/actionTypes";
import FunnelBaseSelect from "../../FunnelBaseSelect";
import FunnelSwitch from "./FunnelSwitch";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  comparisonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    width: "100%",
  },
  text: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.primary,
  },
  selectWrapper: {
    flex: 1,
  },
}));

const mapStateToProps = (state) => ({
  config: state.funnels.config,
  cvr: state.funnels.cvr,
});

const mapDispatchToProps = (dispatch) => ({
  updateCvr(payload) {
    dispatch({ type: FUNNELS.FUNNEL_CVR_UPDATED, payload });
  },
});

type Props = {
  updateCvr: (payload) => void;
  cvr: { enabled: boolean; step1: string; step2: string };
  config: any;
};

const FunnelSidebarConversionRate = (props: Props) => {
  const classes = useStyles();
  const { config, updateCvr, cvr } = props;
  const [enabled, setEnabled] = React.useState(true);

  const steps = React.useMemo(
    () =>
      config.steps.map((step, index) => ({
        label: `Step ${index + 1}`,
        value: `step${index}`,
      })),
    [config]
  );
  const hasStrategyValues = React.useMemo(
    () => config?.breakdown?.field === "first_page" || config?.breakdown?.field === "url",
    [config]
  );
  return (
    <div className={classes.wrapper}>
      <FunnelSwitch
        title={I18n.t("analytics.funnels.sidebar.labels.show_cvr")}
        checked={cvr.enabled}
        onChange={(enabled) => {
          const newCVR = { ...cvr, enabled };
          if (!cvr.enabled && !cvr.step1 && !cvr.step2 && steps.length > 1) {
            newCVR.step1 = steps[0]?.value;
            newCVR.step2 = steps[steps.length - 1]?.value;
          }
          updateCvr(newCVR);
        }}
      />
      <div
        className={classes.comparisonWrapper}
        style={{
          visibility: cvr.enabled ? "visible" : "hidden",
          display: hasStrategyValues && !cvr.enabled ? "none" : "flex",
        }}
      >
        <Typography className={classes.text}>{I18n.t("common.between")}</Typography>
        <FunnelBaseSelect
          padding={"10px"}
          wrapperClassName={classes.selectWrapper}
          placeholder={"Select..."}
          optionLabel={"label"}
          optionValue={"value"}
          value={steps.find((sp) => sp.value === cvr.step1)}
          options={steps}
          handleOnChange={(t) => {
            updateCvr({ ...cvr, step1: t.value });
          }}
        />
        <Typography className={classes.text}>{I18n.t("common.and")}</Typography>
        <FunnelBaseSelect
          wrapperClassName={classes.selectWrapper}
          padding={"10px"}
          placeholder={"Select..."}
          optionLabel={"label"}
          optionValue={"value"}
          value={steps.find((sp) => sp.value === cvr.step2)}
          options={steps} // {steps.filter((s) => s.value !== cvr.step1)}
          handleOnChange={(t) => {
            updateCvr({ ...cvr, step2: t.value });
          }}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FunnelSidebarConversionRate);
