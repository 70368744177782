import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import MTextField from "../../../../../../../components/MTextField";

const useStyles = makeStyles((theme: Theme) => ({
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: 8,
    width: "100%",
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: "#141414",
  },
  input: {
    margin: 0,
  },
  textInput: {
    padding: 10,
  },
}));

type Props = {
  textFieldId: string;
  label?: string;
  onChange: (t: string) => void;
  defaultValue?: string;
  placeholder?: string;
  borderRadius?: number;
};

const FunnelSideBarTextInput = (props: Props) => {
  const classes = useStyles();

  const { textFieldId, label, onChange, defaultValue, placeholder, borderRadius = 5 } = props;
  const [localValue, setLocalValue] = useState(defaultValue || "");
  React.useEffect(() => {
    setLocalValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={classes.inputWrapper}>
      {label && (
        <label htmlFor={textFieldId} className={classes.inputLabel}>
          {label}
        </label>
      )}
      <MTextField
        customClass={classes.input}
        inputClassName={classes.textInput}
        id={textFieldId}
        value={localValue}
        placeholder={placeholder}
        fullWidth
        outlined
        height={17}
        borderRadius={borderRadius}
        onChange={(text) => {
          setLocalValue(text);
          onChange(text);
        }}
      />
    </div>
  );
};

export default FunnelSideBarTextInput;
