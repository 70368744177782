import { PUBLISHED_PAGES } from "./constants/actionTypes";

const initialState = {
  pages: [],
  paginationPagesCount: 0,
  pagesCount: 0,
  pagesLoaded: true,
  refreshPages: false,
  filter: {
    searchText: "",
    postType: [],
    category: [],
    publishStatus: null,
    tabType: { _id: 0, name: "All" },
    sortField: {
      direction: "desc",
      field: "publishedDate",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PUBLISHED_PAGES.PUBLISHED_PAGES_LOADED: {
      return {
        ...state,
        pages: action.payload.pages,
        paginationPagesCount: action.payload.paginationPagesCount,
        pagesCount: action.payload.pagesCount,
        pagesLoaded: true,
      };
    }
    case PUBLISHED_PAGES.PUBLISHED_PAGES_UPDATE_PAGE:
      return {
        ...state,
        pages: action.payload.pages,
      };
    case PUBLISHED_PAGES.PUBLISHED_PAGES_LOAD_FAILED: {
      state.pages.find((page) => page._id === action.payload.page._id);
      return {
        ...state,
        page: [],
        totalPages: 0,
        pagesLoaded: true,
      };
    }
    case PUBLISHED_PAGES.PUBLISHED_PAGES_REFRESH: {
      return {
        ...state,
        refreshPages: !state.refreshPages,
      };
    }
    case PUBLISHED_PAGES.PUBLISHED_PAGES_LOADING: {
      return {
        ...state,
        pagesLoaded: false,
      };
    }
    case PUBLISHED_PAGES.PUBLISHED_PAGES_ON_FILTER_CHANGED: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
