import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import { AbilityContext } from "../../../../../casl/can";
import RichTextEditor from "../../../../../editor/RichTextEditor";
import richTextEditorHelper from "../../../../../editor/helper/richTextEditorHelper";
import SNReadOnlyEditor from "../../../../../editor/ReadOnlyEditor";

const useStyles = makeStyles((theme: Theme) => ({
  resourcesTab: {},
  richText: {
    fontSize: theme.typography.pxToRem(16),
    height: "calc(100vh - 100px)",
    overflowY: "scroll",
  },
  editable: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  editorToolbar: {
    height: 55,
    // paddingLeft: 40,
    // paddingRight: 30,
  },
  text: {
    padding: 20,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;

  topicId: string;
  richText?: string;
  text: string;

  handleTextChanged?: Function;
};

const EditTopicResourcesTab = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, topicId, richText, text, handleTextChanged } = props;
  const ability = React.useContext<any>(AbilityContext);

  if (ability.can("editor", "role")) {
    return (
      <div className={classes.resourcesTab}>
        <RichTextEditor
          fontSize={16}
          droppableId={"edit_topic_resources_editor"}
          editableCustomClass={classes.editable}
          toolbarCustomClass={classes.editorToolbar}
          key={topicId}
          website={selectedWebsite}
          initialValue={richText}
          onValueChanged={(value) => handleTextChanged(value, richTextEditorHelper.serialize(JSON.parse(value)))}
        />
      </div>
    );
  }

  return (
    <div className={classes.resourcesTab}>
      {richText ? (
        <SNReadOnlyEditor value={richText} customEditableClass={classes.richText} />
      ) : (
        <Typography className={classes.text} color={"textPrimary"} variant={"body1"}>
          {text}
        </Typography>
      )}
    </div>
  );
};

export default React.memo(EditTopicResourcesTab);
