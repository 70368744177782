import React from "react";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    minHeight: 23,
    wordBreak: "break-word",
    width: "fit-content",
    backgroundColor: "#e6e6e6",
    borderRadius: "2.5px",
    paddingRight: 6,
    paddingLeft: 6,
    marginRight: 5,
    marginLeft: 5,
    display: "flex",
    alignItems: "center",
    transition: "all 1s cubic-bezier(.175,.885,.32,1)",
    webkitTransition: "all 1s cubic-bezier(.175,.885,.32,1)",
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
  },
  removeTagIcon: {
    color: theme.palette.text.hint,
    cursor: "pointer",
    height: 10,
    width: 10,
    marginLeft: 10,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  website: IWebsite;
};

const IndexingApiQuota = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website } = props;
  const [quota, setQuota] = React.useState<{ left: string; usage: string; total: string }>();

  React.useEffect(() => {
    agent.Indexing.quota(website)
      .then((res) => {
        console.log("res.quota", res.quota);
        setQuota(res.quota);
      })
      .catch((e) => {});
  }, []);

  if (!quota) {
    return null;
  }

  return (
    <Typography className={classes.text} variant={"subtitle1"}>
      {I18n.t("admin.indexing_api.daily_quota", { quota: `${quota?.usage}/${quota?.total}` })}
    </Typography>
  );
};

export default IndexingApiQuota;
