import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import agent from "../../../agent";
import { ePageType, IAdminUser, ICategory, IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import { PUBLISHED_PAGES } from "../../../reducers/constants/actionTypes";
import PublishedItemList from "./components/PublishedItemList";
import useDebounce from "../../../hooks/useDebounce";
import { api } from "../../../helpers/urlHelper";
import PublishedCluster from "../PublishedCluster/PublishedCluster";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarCategoriesFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarCategoriesFilter";
import ability from "../../../casl/ability";
import MTableToolbarPostTypeFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPostTypeFilter";
import MTableToolbarPublishStatusFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPublishStatusFilter";
import MTableToolbarPublishedTabTypeFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPublishedTabTypeFilter";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  admin: state.home.admin,
  refreshPages: state.publishedPages.refreshPages,
  pagesLoaded: state.publishedPages.pagesLoaded,
  paginationPagesCount: state.publishedPages.paginationPagesCount,
  categories: state.category.categories,
  posts: state.publishedPages.pages,
  postType: state.publishedPages.filter.postType,
  category: state.publishedPages.filter.category,
  publishStatus: state.publishedPages.filter.publishStatus,
  tabType: state.publishedPages.filter.tabType,
  sortField: state.publishedPages.filter.sortField,
  searchText: state.publishedPages.filter.searchText,
});

const mapDispatchToProps = (dispatch) => ({
  loadPages: (payload) => dispatch({ type: PUBLISHED_PAGES.PUBLISHED_PAGES_LOADED, payload }),
  startLoading: () => dispatch({ type: PUBLISHED_PAGES.PUBLISHED_PAGES_LOADING }),
  loadFailed: () => dispatch({ type: PUBLISHED_PAGES.PUBLISHED_PAGES_LOAD_FAILED }),
  onFilterChanged: (payload) => dispatch({ type: PUBLISHED_PAGES.PUBLISHED_PAGES_ON_FILTER_CHANGED, payload }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  admin: IAdminUser;
  loadPages: (payload) => void;
  loadFailed: () => void;
  startLoading: () => void;
  refreshPages: boolean;
  pagesLoaded: boolean;

  paginationPagesCount: number;

  categories: ICategory[];

  posts: IPage[];
  postType: { _id: ePageType; name: string }[];
  category: ICategory[];
  publishStatus: { _id: string; name: string };
  tabType: { _id: number; name: string };
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  searchText: string;
  onFilterChanged: (payload) => void;
};

const Published = (props: PropTypes) => {
  const {
    selectedWebsite,
    admin,
    loadPages,
    startLoading,
    refreshPages,
    categories,
    loadFailed,
    pagesLoaded,
    paginationPagesCount,
    posts,
    postType,
    category,
    publishStatus,
    onFilterChanged,
    tabType,
    sortField,
    searchText,
  } = props;

  const [page, setPage] = useState<number>(1);
  const [paginationLimit, setPaginationLimit] = useState(100);

  const debouncedSearchText = useDebounce(searchText, 500);
  const cleanPostTypes = postType.map((p) => p._id);
  const postCategories = category.map((cat) => cat._id);
  const publishStatusClean = publishStatus?._id;

  useEffect(() => {
    if (tabType._id === 1) {
      return;
    }
    if (selectedWebsite && admin) {
      startLoading();

      loadPages(
        agent.Pages.getPublishedItems(
          selectedWebsite,
          admin,
          page,
          paginationLimit,
          sortField.field,
          sortField.direction,
          cleanPostTypes,
          postCategories,
          debouncedSearchText,
          publishStatusClean
        )
          .then((res) => res)
          .catch((e) => loadFailed())
      );
    }
  }, [
    selectedWebsite,
    admin,
    postType,
    refreshPages,
    category,
    page,
    paginationLimit,
    debouncedSearchText,
    publishStatus,
    sortField,
    tabType,
  ]);

  const exportToCSV = () => {
    const API_ROOT = api.api();

    const endpoint = `/pages/${selectedWebsite._id}/published/csv?${
      cleanPostTypes ? `&postTypes=${cleanPostTypes.join(",")}` : ""
    }${postCategories ? `&postCategories=${postCategories.join(",")}` : ""}${
      publishStatusClean ? `&publishStatus=${publishStatusClean}` : ""
    }&searchText=${searchText}`;

    return ability.can("editor", "role") ? `${API_ROOT}${endpoint}` : null;
  };

  return tabType._id === 0 ? (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.article_cms.published.title`)} />
      <MTableToolbar
        csvURL={exportToCSV()}
        cmsCreateButton
        searchText={searchText}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
      >
        <MTableToolbarPublishedTabTypeFilter
          tabType={tabType}
          handleTabTypeSelected={(value) => onFilterChanged({ tabType: value })}
        />
        <MTableToolbarPostTypeFilter
          postTypes={postType}
          selectedWebsite={selectedWebsite}
          handlePostTypesSelected={(value) => onFilterChanged({ postType: value })}
        />
        <MTableToolbarCategoriesFilter
          handleCategoriesSelected={(value) => onFilterChanged({ category: value })}
          selectedCategories={category}
          categories={categories}
        />
        <MTableToolbarPublishStatusFilter
          handlePublishStatusSelected={(value) => onFilterChanged({ publishStatus: value })}
          publishStatus={publishStatus}
        />
      </MTableToolbar>
      <PublishedItemList
        setSortField={(value) => onFilterChanged({ sortField: value })}
        sortField={sortField}
        posts={posts}
      />
      {pagesLoaded && posts.length > 0 && (
        <MTablePagination
          paginationLimit={paginationLimit}
          page={page}
          paginationPagesCount={paginationPagesCount}
          setPaginationLimit={setPaginationLimit}
          setPage={setPage}
        />
      )}
    </MTableWrapper>
  ) : (
    <PublishedCluster />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Published);
