import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import MButton from "../../../../components/MButton";
import ClassNames from "classnames";
import { val } from "cheerio/lib/api/attributes";

const useStyles = makeStyles((theme: Theme) => ({
  selectedButton: {
    backgroundColor: "#E6EEFF !important",
    color: `${theme.palette.primary.main} !important`,
    border: `1px solid ${theme.palette.primary.main}  !important`,
  },
  button: {
    height: 37,
    padding: "10px 20px",
    borderRadius: 40,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular as any,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

type PropTypes = {
  onChange: (value) => void;
  value: { _id: string; name: string };
  selected: boolean;
};

const FlowTextButton = (props: PropTypes) => {
  const classes = useStyles(props);
  const { value, selected, onChange } = props;

  return (
    <MButton
      key={value._id}
      customClassNames={ClassNames(classes.button, selected && classes.selectedButton)}
      onClick={() => onChange(value)}
    >
      {value.name}
    </MButton>
  );
};

export default FlowTextButton;
