import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Divider from "@material-ui/core/Divider";
import FunnelSidebarHeader from "./components/FunnelSidebarHeader";
import { FUNNELS } from "../../../../../reducers/constants/actionTypes";
import agent from "../../../../../agent";
import FunnelSidebarSteps from "./components/FunnelSidebarSteps";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import FunnelSidebarActions from "../FunnelSidebarActions";
import CfProgressBar from "../../../../../components/CfProgressBar";
import FunnelSidebarConversionRate from "./components/FunnelSidebarConversionRate";
import FunnelSidebarPriority from "./components/FunnelSidebarPriority";

import FunnelSwitch from "./components/FunnelSwitch";

const useStyles = makeStyles((theme: Theme) => ({
  sidebarWrapper: {
    background: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 367,
    maxWidth: 367,
    marginRight: "-20px",
    height: "calc(100vh - 82px)",
    position: "sticky",
    top: 62,
  },
  sidebarSettings: {
    padding: 20,
    paddingTop: 0,
    display: "flex",
    flexGrow: 1,
    overflow: "auto",
    flexDirection: "column",
  },
  endSection: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  pageLoader: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  divider: {
    margin: "24px 0",
  },
  switches: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  showValue: state.funnels.showValue,
  showTraffic: state.funnels.showTraffic,
});

const mapDispatchToProps = (dispatch) => ({
  setEventNames(payload) {
    dispatch({ type: FUNNELS.FUNNEL_EVENT_NAMES_LOADED, payload });
  },
  toggleField(payload) {
    dispatch({ type: FUNNELS.FUNNEL_TOGGLE_FIELD, payload });
  },
});

type Props = {
  selectedWebsite: IWebsite;
  setEventNames: (payload) => void;
  onCancel: () => void;
  toggleField: (payload) => void;
  showValue: boolean;
  showTraffic: boolean;
};

const FunnelSidebar = (props: Props) => {
  const classes = useStyles();

  const { selectedWebsite, setEventNames, onCancel, showTraffic, showValue, toggleField } = props;
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    agent.Funnels.getColumnValues(selectedWebsite, "events", "name", null, 1, 500)
      .then((res) => {
        setLoaded(true);
        setEventNames(res.data);
      })
      .catch((error) => {
        setLoaded(true);
        console.log(error);
      });
  }, []);
  if (!loaded)
    return (
      <div className={classes.sidebarWrapper}>
        <div className={classes.pageLoader}>
          <CfProgressBar />
        </div>
      </div>
    );
  return (
    <div className={classes.sidebarWrapper}>
      <div className={classes.sidebarSettings}>
        <FunnelSidebarHeader />

        <FunnelSidebarSteps />
        {/* <Divider className={classes.divider} /> */}
        {/* <FunnelSidebarFilter /> */}
        <Divider className={classes.divider} />
        <div className={classes.switches}>
          <FunnelSwitch
            title={I18n.t("analytics.funnels.sidebar.labels.show_value")}
            checked={showValue}
            onChange={(value) => toggleField({ showValue: value })}
          />
          <FunnelSwitch
            title={I18n.t("analytics.funnels.sidebar.labels.show_traffic")}
            checked={showTraffic}
            onChange={(value) => toggleField({ showTraffic: value })}
          />
          <FunnelSidebarConversionRate />
          <FunnelSidebarPriority />
        </div>
      </div>

      <div className={classes.endSection}>
        <Divider />
        <FunnelSidebarActions onCancel={onCancel} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FunnelSidebar);
