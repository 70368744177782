import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import ClassNames from "classnames";
import CfProgressBar from "./CfProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(16),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    textTransform: "capitalize",
  },
  progressBarWrapper: {},
  progressBar: {
    justifyContent: "center",
  },
}));

type PropTypes = {
  customClassNames?: string;
  children: string | React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e) => void;
  barSize?: number;
};

const MButton = (props: PropTypes) => {
  const classes = useStyles(props);
  const { children, disabled = false, loading = false, customClassNames = null, onClick = null, barSize = 30 } = props;

  if (loading) {
    return (
      <div className={ClassNames(classes.progressBarWrapper, customClassNames)}>
        <CfProgressBar customClass={classes.progressBar} size={barSize} />
      </div>
    );
  }

  return (
    <Button
      className={ClassNames(classes.button, customClassNames)}
      disabled={disabled}
      onClick={(e) => {
        onClick?.(e);
      }}
    >
      {children}
    </Button>
  );
};

export default MButton;
