import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 36,
    height: 35,
  },
}));

type PropTypes = {
  className?: string;
};

const ComparisonTableIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <svg
      className={ClassNames(classes.icon, className)}
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.166748" width="35" height="35" rx="3" fill="#FE9A00" />
      <g clip-path="url(#clip0_12513_28208)">
        <mask
          id="mask0_12513_28208"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="30"
          height="30"
        >
          <path d="M33 3H3V33H33V3Z" fill="white" />
        </mask>
        <g mask="url(#mask0_12513_28208)">
          <path
            d="M19.25 8H10.5C9.11929 8 8 9.11929 8 10.5V25.5001C8 26.8807 9.11929 28.0001 10.5 28.0001H19.25M24.25 8H25.5C26.8807 8 28 9.11929 28 10.5V11.75M28 24.2501V25.5001C28 26.8807 26.8807 28.0001 25.5 28.0001H24.25M28 16.7501V19.2501M18 5.5V30.5001"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_12513_28208">
          <rect width="30" height="30" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ComparisonTableIcon;
