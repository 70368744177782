import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { web } from "../../helpers/urlHelper";
import { AbilityContext } from "../../casl/can";
import Dashboard from "../Analytics/Dashboard/Dashboard";
import Events from "../Analytics/Events/Events";
import ProductsEditor from "../ProductsEditor/ProductsEditor";
import Settings from "../Analytics/Settings/Settings";
import { IWebsite } from "../../reducers/constants/objectTypes";
import UserEvents from "../Analytics/UserEvents/UserEvents";
import FunnelCreator from "../Analytics/FunnelCreator/FunnelCreator";
import Reports from "../Analytics/Reports/Reports";
import GoalEvents from "../Analytics/GoalEvents/GoalEvents";
import AdsListPage from "../CRO/pages/AdsList/AdsListPage";
import WidgetsBuilder from "../CRO/pages/WidgetsBuilder/WidgetsBuilder";
import EditAd from "../CRO/pages/EditAd/EditAd";
import Campaigns from "../CRO/pages/Campaigns/Campaigns";
import LinksSplitTests from "../CRO/pages/LinksSplitTests/LinksSplitTests";
import Products from "../CRO/pages/Products/Products";
import Orders from "../CRO/pages/Orders/Orders";
import GoalCompletion from "./GoalCompletion/GoalCompletion";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});
type PropTypes = {
  selectedWebsite: IWebsite;
};
const Analytics = (props: PropTypes) => {
  const { selectedWebsite } = props;
  const ability = React.useContext<any>(AbilityContext);

  const routes = React.useMemo(() => {
    const allRoutes = [];
    if (ability.can("view", "cro_dashboard_tab")) {
      allRoutes.push({
        url: web.croDashboard(),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_dashboard`}
            exact={false}
            path={web.croDashboard()}
            component={Dashboard}
          />
        ),
      });
    }
    if (ability.can("view", "cro_dashboard_tab")) {
      allRoutes.push({
        url: web.croGoalCompletion(),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_goal_completion`}
            exact={false}
            path={web.croGoalCompletion()}
            render={() => <GoalCompletion hideHeader />}
          />
        ),
      });
    }

    if (ability.can("view", "cro_reports_tab")) {
      allRoutes.push({
        url: web.croReportsAll(),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_all_reports`}
            exact={true}
            path={web.croReportsAll()}
            render={() => <Reports hideHeader />}
          />
        ),
      });
    }

    if (ability.can("view", "cro_reports_tab")) {
      allRoutes.push({
        url: web.croReports(":reportType"),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_reports`}
            exact={false}
            path={web.croReports(":reportType")}
            render={() => <Reports hideHeader />}
          />
        ),
      });
    }

    if (ability.can("view", "cro_events_tab")) {
      allRoutes.push({
        url: web.croUserEvents(":distinctId"),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_user_events`}
            exact={false}
            path={web.croUserEvents(":distinctId")}
            component={UserEvents}
          />
        ),
      });
      allRoutes.push({
        url: web.croEvents(),
        route: (
          <Route key={`cro_${selectedWebsite._id}_events`} exact={false} path={web.croEvents()} component={Events} />
        ),
      });
    }
    if (ability.can("view", "cro_funnels_tab")) {
      allRoutes.push({
        url: web.croFunnels(),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_funnels`}
            exact={false}
            path={web.croFunnels()}
            component={FunnelCreator}
          />
        ),
      });
    }

    if (ability.can("view", "cro_goals_tab")) {
      allRoutes.push({
        url: web.croGoalEvents(),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_goals`}
            exact={false}
            path={web.croGoalEvents()}
            component={GoalEvents}
          />
        ),
      });
    }

    if (ability.can("view", "cro_settings_tab")) {
      allRoutes.push({
        url: web.croSettings(),
        route: (
          <Route
            key={`cro_${selectedWebsite._id}_settings`}
            exact={false}
            path={web.croSettings()}
            component={Settings}
          />
        ),
      });
    }
    if (ability.can("view", "cro_orders_tab")) {
      allRoutes.push({
        url: web.croOrders(),
        route: (
          <Route key={`cro_${selectedWebsite._id}_orders`} exact={false} path={web.croOrders()} component={Orders} />
        ),
      });
    }
    return allRoutes;
  }, [ability, selectedWebsite]);

  return (
    <Switch>
      {routes.map((route) => route.route)}
      <Redirect exact from={"*"} to={routes[0]?.url} />
    </Switch>
  );
};

export default connect(mapStateToProps)(Analytics);
