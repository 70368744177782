// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-fallthrough */

import { Ability, AbilityBuilder } from "@casl/ability";
import store from "../store";
import {
  ePageType,
  ePostType,
  eUserRoles,
  eVideoProjectState,
  IAdminUser,
  IWebsite,
} from "../reducers/constants/objectTypes";

// Defines how to detect object's type
const subjectName = (item) => {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
};

const ability = new Ability([], { subjectName });

const defineRulesFor = (user: IAdminUser, website: IWebsite) => {
  const { can, rules } = new AbilityBuilder();
  console.log("######defineRulesFor", user);

  if (!user || !user.role) {
    return [];
  }

  can("user_role", user.role);
  switch (user.role) {
    case eUserRoles.OWNER:
      can(eUserRoles.OWNER, "role");
      can("view", "owner_tab");
      can("view", "legacy_account_setup_tab");
      can("view", "legacy_account_pricing_tab");
      can("view", "legacy_login_as_tab");
      can("view", "settings_roles_and_permissions_tab");

    case eUserRoles.ADMIN:
      can(eUserRoles.ADMIN, "role");
      can("view", `video_workflow_${eVideoProjectState.firstCheck}_tab`);
      can("view", `video_workflow_${eVideoProjectState.secondCheck}_tab`);
      can("view", `video_workflow_${eVideoProjectState.review}_tab`);
      can("view", `video_workflow_${eVideoProjectState.deleted}_tab`);

      can("view", "video_workflow_all_states_tab");

      can("view", "site_stats_tab");

      can("view", "customer_tab");
      can("view", "scraping_tab");
      can("view", "admin_tab");
      can("view", "legacy_site_stats_tab");
      can("view", "cro_orders_tab");
      can("view", "entail_tab");
      can("view", "video_tab");

      can("review", "video_project");
    case eUserRoles.CONTENT_MANAGER:
      can(eUserRoles.CONTENT_MANAGER, "role");

      can("view", "cro_tab");
      can("view", "ads_tab");
      // cro tabs
      can("view", "cro_experiments_tab");
      can("view", "cro_ads_tab");
      can("view", "cro_widgets_tab");
      can("view", "content_manager_tab");
      can("view", "legacy_source_files_tab");
      can("view", "legacy_tab");
      can("view", "cro_products_tab");
      can("view", "cro_widgets_display_rules_tab");
      // settings tabs
      can("view", "cms_settings_topics_tab");
      can("view", "cms_settings_bios_tab");
      can("view", "cms_settings_categories_tab");
      can("view", "cms_settings_tab");
      // page builder tabs
      can("view", "page_builder_theme_tab");
      can("view", "page_builder_content_pages_tab");
      can("view", "page_builder_editorial_pages_tab");
      can("view", "page_builder_landing_pages_tab");
      can("view", "page_builder_components_tab");
      can("view", "page_builder_developer_tools_tab");
      // seo tabs
      can("view", "seo_indexing_api_tab");
      can("view", "seo_redirects_tab");
      can("view", "seo_tab");

      can(["create", "delete", "delete_forever", "edit_title"], `${ePostType.LEARNING_CENTER}_topic`);
      can(["create", "delete", "edit_title"], "community_category");
      can("edit_title", ePageType.Targeted);
      can("edit_title", ePageType.ComparisonTopAlternatives);
      can("edit_title", ePageType.ComparisonTop10);
      can("edit_title", ePageType.Blog);
      can("edit_title", ePostType.CUSTOM);
      can("edit_title", ePostType.LEARNING_CENTER);
      can("edit_title", ePostType.COMMUNITY);
      can("edit_title", ePostType.TOPIC);
      can("edit_title", ePageType.ProductPage);
      can("edit_title", ePageType.ProductReview);
      can("edit_title", ePageType.ProductComparison);
      can("edit_title", ePageType.ProductAlternative);
      can(
        ["create", "edit", "delete", "submit_for_review", "review", "publish", "assign_writer", "edit_title"],
        ePostType.COMMUNITY
      );
      can("view", "data_tab");
      // strategy tabs
      can("view", "strategy_mapping_tab");
      can("view", "strategy_settings_tab");
      can("view", "strategy_internal_linking_tab");
      can("view", "strategy_content_gaps_tab");
      can("view", "strategy_fetch_tab");
      can("view", "strategy_exclude_keyword_tab");
      can("view", "strategy_keyword_classification_tab");
      can("view", "strategy_keyword_groups_tab");
      can("view", "strategy_keyword_heading_tab");
      can("view", "strategy_page_keywords_tab");
      can("view", "strategy_outline_tab");
      can("view", "strategy_keyword_group_page_tab");

      can("view", `video_workflow_${eVideoProjectState.review}_tab`);

      can("edit", "video_project_title");

    // can(['create', 'edit', 'delete', 'submit_for_review', 'review', 'publish', 'assign_writer', 'edit_title'], ePostType.TOPIC);
    // case eUserRoles.ANALYST:
    //   can(eUserRoles.ANALYST, "role");
    // can("view", "data_tab");
    // strategy tabs
    // can("view", "strategy_mapping_tab");
    // can("view", "strategy_settings_tab");
    // can("view", "strategy_internal_linking_tab");
    // can("view", "strategy_content_gaps_tab");
    // can("view", "strategy_fetch_tab");
    // can("view", "strategy_exclude_keyword_tab");
    // can("view", "strategy_keyword_classification_tab");
    // can("view", "strategy_keyword_groups_tab");
    // can("view", "strategy_keyword_heading_tab");
    // can("view", "strategy_page_keywords_tab");
    // can("view", "strategy_outline_tab");
    // can("view", "strategy_keyword_group_page_tab");

    case eUserRoles.EDITOR:
      can("takeOver", "page");
      can(eUserRoles.EDITOR, "role");
      can("view", "all_states_tab");
      can("view", "cms_all_states_tab");
      can("view", "editor_tab");
      can("view", "cms_editor_tab");
      can("view", "community_editor_tab");
      can("view", "community_published_tab");
      can("view", "reports_tab");
      can("view", "legacy_insights_tab");
      can("view", "cro_dashboard_tab");
      can("view", "cro_reports_tab");
      can("view", "cro_tab");
      can("view", "cro_events_tab");
      can("view", "cro_funnels_tab");
      can("view", "cro_goals_tab");
      can("view", "cro_funnels_creator_tab");
      can("view", "reports_tab_settings");
      can("view", "cro_settings_tab");
      can("view", "outline_review");
      can("view", "cms_outline_review_tab");
      can("view", "mapping_tab");
      can("view", "cms_mapping_tab");
      can("view", "briefs_tab");
      can("view", "cms_briefs_tab");
      can("view", "fact_check_tab");
      can("view", "cms_fact_check_tab");
      can("view", "video_tab");

      can("delete", "tag");
      can(["create", "delete"], "answer_category");
      can(["create", "delete"], "blog_category");
      can(["review", "update", "publish", "edit", "assign_writer"], `${ePostType.LEARNING_CENTER}_topic`);
      can(
        ["create", "review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"],
        ePageType.Targeted
      );
      can(
        ["create", "review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"],
        ePageType.ComparisonTopAlternatives
      );
      can(
        ["create", "review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"],
        ePageType.ComparisonTop10
      );
      can(
        ["create", "review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"],
        ePageType.ProductPage
      );
      can(
        ["create", "review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"],
        ePageType.ProductReview
      );
      can(
        ["create", "review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"],
        ePageType.ProductComparison
      );
      can(
        ["create", "review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"],
        ePageType.ProductAlternative
      );
      can(["review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"], ePageType.Blog);
      can(["review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"], ePostType.CUSTOM);
      can(["take-over"], ePostType.CUSTOM);
      can(
        ["review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"],
        ePostType.LEARNING_CENTER
      );
      can(["review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"], ePostType.COMMUNITY);
      can(
        ["create", "review", "update", "publish", "edit", "assign_writer", "delete", "delete_forever"],
        ePostType.TOPIC
      );
      can(["create", "delete", "edit", "publish"], "page");

      can("view", `video_workflow_${eVideoProjectState.trim}_tab`);
      can("view", `video_workflow_${eVideoProjectState.brief}_tab`);
      can("view", `video_workflow_${eVideoProjectState.edit}_tab`);
      can("view", `video_workflow_${eVideoProjectState.cards}_tab`);
      can("view", `video_workflow_${eVideoProjectState.captions}_tab`);
      can("view", `video_workflow_${eVideoProjectState.music}_tab`);
      can("view", `video_workflow_${eVideoProjectState.done}_tab`);
      can("view", "video_workflow_all_states_tab");
      can("view", "video_editor_tools");
      can("view", "video_editor_media_tab");
      can("view", "video_editor_files_tab");
      can("view", "video_editor_comments_tab");
      can("view", "video_editor_timeline");

    case eUserRoles.WRITER:
      can(eUserRoles.WRITER, "role");
      can("view", "community_v2");
      can("create", "tag");
      can(["create", "publish", "submit_for_review", "view_resources"], ePostType.COMMUNITY);
      can(["create", "submit_for_review", "view_resources"], ePageType.Targeted);
      can(["create", "submit_for_review", "view_resources"], ePageType.Blog);
      can(["create", "submit_for_review", "view_resources"], ePostType.CUSTOM);
      can(["create", "submit_for_review", "view_resources"], ePostType.LEARNING_CENTER);
      can(["create", "submit_for_review", "view_resources"], ePostType.TOPIC);
      can(["submit_for_review", "view_resources"], ePageType.ComparisonTop10);
      can(["submit_for_review", "view_resources"], ePageType.ComparisonTopAlternatives);
      can(["submit_for_review", "view_resources"], ePageType.ProductPage);
      can(["submit_for_review", "view_resources"], ePageType.ProductReview);
      can(["submit_for_review", "view_resources"], ePageType.ProductComparison);
      can(["submit_for_review", "view_resources"], ePageType.ProductAlternative);
      can(["submit_for_review", "view_resources"], `${ePostType.LEARNING_CENTER}_topic`);
      can("view", "writer_tab");
      can("view", "cms_writer_tab");
      can("view", "community_writer_tab");
      can("view", "published_tab");
      can("view", "cms_published_tab");
      can("view", "cms_tab");

      break;
    case eUserRoles.LIMITED_WRITER:
      can(eUserRoles.LIMITED_WRITER, "role");
      can("view", "cms_tab");
      can("view", "community_v2");
      can(["publish", "submit_for_review", "view_resources"], ePostType.COMMUNITY);
      can(["submit_for_review", "view_resources"], ePageType.Targeted);
      can(["submit_for_review", "view_resources"], ePageType.Blog);
      can(["submit_for_review", "view_resources"], ePostType.CUSTOM);
      can(["submit_for_review", "view_resources"], ePostType.LEARNING_CENTER);
      can(["submit_for_review", "view_resources"], ePostType.TOPIC);
      can(["submit_for_review", "view_resources"], `${ePostType.LEARNING_CENTER}_topic`);
      can("view", "writer_tab");
      can("view", "cms_writer_tab");
      can("view", "community_writer_tab");
      can("view", "published_tab");
      can("view", "cms_published_tab");

      break;
    case eUserRoles.HR:
      can(eUserRoles.HR, "role");
      can("view", "hr_tab");
      can("view", "cro_tab");
      can("view", "cms_tab");
      can("view", "writer_tab");
      can("view", "cms_writer_tab");
      can("view", "community_writer_tab");
      can("view", "settings_roles_and_permissions_tab");
      can("view", "editor_tab");
      can("view", "cms_editor_tab");
      can("view", "community_editor_tab");
      can("view", "community_published_tab");
      can("view", "content_manager_tab");
      can("view", "cro_products_tab");
      // page builder tabs
      can("view", "page_builder_theme_tab");
      can("view", "page_builder_content_pages_tab");
      can("view", "page_builder_editorial_pages_tab");
      can("view", "page_builder_landing_pages_tab");
      can("view", "page_builder_components_tab");
      can("view", "page_builder_developer_tools_tab");
      // settings tabs
      can("view", "cms_settings_tab");
      can("view", "cms_settings_topics_tab");
      can("view", "cms_settings_bios_tab");
      can("view", "cms_settings_categories_tab");
      // seo tabs
      can("view", "seo_tab");
      can("view", "seo_indexing_api_tab");
      can("view", "seo_redirects_tab");

      // strategy tabs
      // can("view", "strategy_mapping_tab");
      // can("view", "strategy_settings_tab");
      // can("view", "strategy_internal_linking_tab");
      // can("view", "strategy_content_gaps_tab");
      // can("view", "strategy_fetch_tab");
      // can("view", "strategy_exclude_keyword_tab");
      // can("view", "strategy_keyword_classification_tab");
      // can("view", "strategy_keyword_groups_tab");
      // can("view", "strategy_keyword_heading_tab");
      // can("view", "strategy_page_keywords_tab");
      // can("view", "strategy_outline_tab");
      // can("view", "strategy_keyword_group_page_tab");
      break;
    case eUserRoles.CUSTOMER:
      can("takeOver", "page");
      can("customer", "role");
      can("view", "community_published_tab");
      can("view", "cms_tab");
      can("view", "mapping_tab");
      can("view", "customer_tab");
      can("view", "reports_tab");
      can("view", "legacy_insights_tab");
      can("view", "cro_tab");
      can("view", "cro_dashboard_tab");
      can("view", "cro_widgets_tab");
      can("view", "cro_widgets_display_rules_tab");
      can("view", "cro_funnels_tab");
      can("view", "cro_goals_tab");
      can("view", "cro_products_tab");
      // can("view", "cro_reports_tab");
      // can("view", "cro_events_tab");
      can("view", "fact_check_tab");
      can("view", "cms_fact_check_tab");
      can("view", "published_tab");
      can("view", "cms_published_tab");
      can("view", "cms_mapping_tab");
      // can("view", "community_v2");
      can("view", "video_tab");

      if (website && website.reviewProcess === "Fact-Check") {
        can(["review", "update", "publish", "edit", "reject", "delete", "view_resources"], ePostType.CUSTOM);
        can(["take-over"], ePostType.CUSTOM);
      }
      can(["submit_for_review", "customer_approval"], ePostType.COMMUNITY);
      can(["submit_for_review", "customer_approval"], ePageType.Targeted);
      can(["submit_for_review", "customer_approval"], ePageType.Blog);
      can(["submit_for_review", "customer_approval"], ePostType.LEARNING_CENTER);
      can(["submit_for_review", "customer_approval"], ePostType.TOPIC);
      can(["submit_for_review", "customer_approval"], `${ePostType.LEARNING_CENTER}_topic`);
      can("view", `video_workflow_${eVideoProjectState.brief}_tab`);
      can("view", `video_workflow_${eVideoProjectState.review}_tab`);
      can("view", `video_workflow_${eVideoProjectState.done}_tab`);
      can("view", "video_workflow_all_states_tab");
      can("view", "video_editor_comments_tab");
      can("edit", "video_project_title");

      break;
    case eUserRoles.CRO_CUSTOMER:
      can(eUserRoles.CRO_CUSTOMER, "role");
      can("view", "cro_tab");
      can("view", "cro_widgets_tab");
      can("view", "cro_widgets_display_rules_tab");
      can("view", "cro_products_tab");
      break;
    default:
      console.log("error: could not find user role");
  }

  return rules;
};

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().home;
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth.admin, currentAuth.selectedWebsite));
  }
});

export default ability;
