import React, { useEffect, useMemo } from "react";
import { Button, TextareaAutosize, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { I18n } from "react-redux-i18n";
import { nanoid } from "nanoid";
import { useSnackbar } from "notistack";
import { cloneDeep, round } from "lodash";

import Comment from "./Comment";
import { useVideoEditorContext } from "../../../VideoEditorContext";
import {
  ITimelineAction,
  ITimelineComment,
  IVideoProject,
  eUserRoles,
} from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import { millisecondsToTime } from "../../../utils";
import ability from "../../../../../casl/ability";
import MDropdown from "../../../../../components/MDropdown";

const useStyles = makeStyles((theme: Theme) => ({
  itemsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: 150,
    height: "100%",
  },
  itemsWrapperFullHeight: {
    paddingBottom: 20,
  },
  commentsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    height: "100%",
    overflowY: "auto",
  },
  commentInputWrapper: {
    width: "100%",
    padding: 20,
    backgroundColor: "#EEF2F9",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  commentInputWrapperTop: {
    display: "flex",
    alignItems: "center",
    gap: 9,
  },
  commentInputTools: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  submitWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  timeText: {
    fontSize: 14,
    lineHeight: "14px",
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  commentUserName: {
    fontSize: 14,
    color: theme.palette.text.primary,
    lineHeight: "100%",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  commentInput: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    minHeight: 40,
    borderRadius: 3,
  },
  addIcon: {
    cursor: "pointer",
    margin: "auto",
    color: "white",
    borderRadius: "100%",
    fontSize: 20,
    backgroundColor: "rgba(0,0,0,0.3)",
    marginRight: 8,
  },
  addIconActive: {
    backgroundColor: theme.palette.primary.main,
  },
  textInput: {
    fontFamily: theme.typography.fontFamily,
    resize: "none",
    border: "none",
    outline: "none",
    width: "100%",
    fontSize: 14,
    padding: "10px 15px",
    backgroundColor: "initial",
  },
  button: {
    textTransform: "capitalize" as const,
    color: "white",
    display: "flex",
    flexDirection: "column" as const,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    padding: "10px 15px",
    height: 30,
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    transition: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      color: "white",
      backgroundColor: "#959BDB",
    },
  },
}));

const visibilityOptions = [
  { label: "Admins", value: "admins" },
  { label: "Clients", value: "clients" },
  { label: "Editors", value: "editors" },
];

const CommentsTab = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    admin,
    selectedWebsite,
    videoProject,
    selectedTimeline,
    commentActions,
    timelineState,
    commentsWrapperRef,
    focusComment,
    jumpToTime,
    editTimelineRows,
  } = useVideoEditorContext();
  const [newCommentBody, setNewCommentBody] = React.useState({
    text: "",
    visibility: ability.can(eUserRoles.ADMIN, "role") ? "editors" : null,
  });

  const [currentExpandedCommentId, setCurrentExpandedCommentId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const prevCommentActions = React.useRef<ITimelineAction[]>([]);

  const hasVideos = useMemo(
    () => selectedTimeline.rows.find(({ type }) => type === "video").actions.length > 0,
    [selectedTimeline]
  );

  useEffect(() => {
    if (!commentActions || commentActions.length === 0 || !prevCommentActions.current.length) {
      return;
    }

    const comments = commentActions.map((action) => action.comment);
    const prevComments = prevCommentActions.current.map((action) => action.comment);

    console.log("comments", comments, prevComments);

    let newReply = null;
    const newComment = comments.find((comment) => {
      const prevComment = prevComments.find((c) => c._id === comment._id);
      if (!prevComment) {
        return true;
      }

      if (comment.replies.length > prevComment.replies.length) {
        newReply = comment.replies.find((r) => !prevComment.replies.find((pr) => pr._id === r._id));
      }

      return false;
    });

    if (newReply) {
      focusComment(newReply._id);
    }

    if (newComment) {
      focusComment(newComment._id);
    }
  }, [commentActions, commentsWrapperRef]);

  const addComment = async () => {
    try {
      if (newCommentBody.text.trim().length === 0) {
        return;
      }

      const newComment = {
        ...newCommentBody,
        replies: [],
      } as ITimelineComment;

      const newActionBody = {
        id: nanoid(10),
        comment: newComment,
        start: round(timelineState.time, 2),
        end: round(timelineState.time, 2),
      } as ITimelineAction;

      setLoading(true);
      const data = await agent.VideoProjects.createComment(
        selectedWebsite,
        videoProject,
        selectedTimeline,
        newActionBody
      );
      const newVideoProject = data.videoProject as IVideoProject;
      const newCommentRow = newVideoProject.timelines.find((t) => t.selected).rows.find((r) => r.type === "comment");
      prevCommentActions.current = commentActions;

      const newTimelineRows = cloneDeep(selectedTimeline.rows);
      const commentRow = newTimelineRows.find((r) => r.type === "comment");
      if (!commentRow) {
        newTimelineRows.push(newCommentRow);
      } else {
        const createdAction = newCommentRow.actions.find((a) => a.id === newActionBody.id);
        commentRow.actions.push(createdAction);
      }

      editTimelineRows(newTimelineRows);
      setNewCommentBody({
        ...newCommentBody,
        text: "",
      });
    } catch (error) {
      enqueueSnackbar(error.response?.body?.message, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const addReply = async (text: string, commentAction: ITimelineAction) => {
    try {
      if (text.trim().length === 0) {
        return;
      }

      setLoading(true);

      const data = await agent.VideoProjects.createCommentReply(
        selectedWebsite,
        videoProject,
        selectedTimeline,
        commentAction,
        text
      );

      const newVideoProject = data.videoProject as IVideoProject;
      const timelineRows = newVideoProject.timelines.find((t) => t.selected).rows;

      const updatedAction = timelineRows
        .find((row) => row.type === "comment")
        .actions.find((a) => a.id === commentAction.id);

      if (!updatedAction) {
        throw new Error("Something went wrong, reply was not created!");
      }

      prevCommentActions.current = commentActions;
      editTimelineRows(
        selectedTimeline.rows.map((row) => {
          if (row.type === "comment") {
            return {
              ...row,
              actions: row.actions.map((a) => {
                if (a.id === updatedAction.id) {
                  return updatedAction;
                }
                return a;
              }),
            };
          }
          return row;
        })
      );
    } catch (error) {
      enqueueSnackbar(error.response?.body?.message, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteComment = async (commentAction: ITimelineAction) => {
    try {
      setLoading(true);
      const data = await agent.VideoProjects.deleteComment(
        selectedWebsite,
        videoProject,
        selectedTimeline,
        commentAction
      );
      const newVideoProject = data.videoProject as IVideoProject;

      const newTimelineRows = videoProject.timelines
        .find((t) => t.selected)
        .rows.map((row) => {
          if (row.type === "comment") {
            return newVideoProject.timelines.find((t) => t.selected).rows.find((r) => r.type === "comment");
          }
          return row;
        });

      editTimelineRows(newTimelineRows);
    } catch (error) {
      enqueueSnackbar(error.response?.body?.message, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteReply = async (replyId: string, commentAction: ITimelineAction) => {
    try {
      setLoading(true);
      const data = await agent.VideoProjects.deleteCommentReply(
        selectedWebsite,
        videoProject,
        selectedTimeline,
        commentAction,
        replyId
      );
      const newVideoProject = data.videoProject as IVideoProject;
      const timelineRows = newVideoProject.timelines.find((t) => t.selected).rows;

      const updatedAction = timelineRows
        .find((row) => row.type === "comment")
        .actions.find((a) => a.id === commentAction.id);

      if (!updatedAction) {
        throw new Error("Something went wrong, reply was not deleted!");
      }

      editTimelineRows(
        selectedTimeline.rows.map((row) => {
          if (row.type === "comment") {
            return {
              ...row,
              actions: row.actions.map((a) => {
                if (a.id === updatedAction.id) {
                  return updatedAction;
                }
                return a;
              }),
            };
          }
          return row;
        })
      );
    } catch (error) {
      enqueueSnackbar(error.response?.body?.message, {
        variant: "error",
      });
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  if (!selectedTimeline || !selectedTimeline._id) {
    return null;
  }

  return (
    <div className={classNames(classes.itemsWrapper, !hasVideos && classes.itemsWrapperFullHeight)}>
      <div className={classes.commentsWrapper} ref={commentsWrapperRef}>
        {commentActions
          .sort((a, b) => a.start - b.start)
          .map((action) => (
            <Comment
              key={action.comment._id}
              comment={action.comment}
              time={action.start}
              onClick={() => {
                jumpToTime(action.start);
              }}
              addReply={(text: string) => addReply(text, action)}
              onReplyClick={(id: string) => {
                if (currentExpandedCommentId === id) {
                  setCurrentExpandedCommentId(null);
                } else {
                  setCurrentExpandedCommentId(id);
                }
              }}
              onDeleteCommentClick={() => deleteComment(action)}
              onDeleteReplyClick={(_id: string) => deleteReply(_id, action)}
              currentExpandedCommentId={currentExpandedCommentId}
              disabled={loading}
            />
          ))}
      </div>
      {hasVideos && (
        <div className={classes.commentInputWrapper}>
          <div className={classes.commentInputWrapperTop}>
            <Typography variant="body1" className={classes.timeText}>
              {millisecondsToTime(timelineState.time * 1000) || "00:00:00"}
            </Typography>
            <Typography variant="body1" className={classes.commentUserName}>
              {admin.name}
            </Typography>
          </div>
          <div className={classes.commentInput}>
            <TextareaAutosize
              autoFocus
              disabled={loading}
              value={newCommentBody.text}
              minRows={1}
              maxRows={5}
              onChange={(e) => setNewCommentBody({ ...newCommentBody, text: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (e.shiftKey) {
                    return;
                  }
                  e.preventDefault();
                  addComment();
                }
              }}
              placeholder={I18n.t("rich_text_editor.insert_comments.comment_ph")}
              className={classes.textInput}
            />
          </div>
          <div className={classes.commentInputTools}>
            <div></div>
            <div className={classes.submitWrapper}>
              {ability.can(eUserRoles.ADMIN, "role") && (
                <MDropdown
                  options={visibilityOptions}
                  value={visibilityOptions.find((option) => option.value === newCommentBody.visibility)}
                  handleOnChange={(option) => setNewCommentBody({ ...newCommentBody, visibility: option.value })}
                  optionLabel="label"
                  optionValue="value"
                />
              )}
              <Button
                disableElevation
                disableRipple
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={loading || newCommentBody.text.trim().length === 0}
                onClick={addComment}
              >
                {I18n.t(`video_workflow.video_tabs.comments.comment`)}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentsTab;
