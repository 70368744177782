import * as React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import set from "lodash/set";
import { find } from "lodash";
import PageSection from "../../components/PageSection";
import {
  IWebsite,
  IWebsiteLearnProps,
  IWebsiteSectionProps,
  IWebsiteThemeProps,
  SNWebsiteAutoCompleteMetaTag,
} from "../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import WebsitesSectionsSwitch from "../../components/WebsitesSectionsSwitch";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import TextInputMentions from "../../components/TextInputMentions";
import { LENGTHS } from "../../../../reducers/constants/consts";
import TextDesignToolWithPreview from "../../components/TextDesignToolWithPreview";
import TextInputToolAndDesign from "../../components/TextInputToolAndDesign";
import MRouterPrompt from "../../components/MRouterPrompt";
import PageLocale from "../../components/PageLocale";
import SelectRow from "../../components/SelectRow";
import PageAccess from "../../components/PageAccess";
import CustomUrlPrefixTextField from "../../components/CustomUrlPrefixTextField";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

const mapStateToProps = (state) => ({
  learn: state.websitePages.learn,
  theme: state.websitePages.theme,
  sections: state.websitePages.sections,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (learn) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "learn",
      value: learn,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  theme: IWebsiteThemeProps;
  learn: IWebsiteLearnProps;
  sections: IWebsiteSectionProps;
  website: IWebsite;
  onPropertyChanged: (learn) => void;
  handleToggleActive: (section, enabled) => void;
};

const PageLearn = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website, sections, learn, theme, handleToggleActive, onPropertyChanged } = props;
  const [error, setError] = React.useState(false);
  const [emptyFields, setEmptyFields] = React.useState([]);

  const [locale, setLocale] = useState(website?.configurations.locale.defaultLocale || null);
  const { defaultLocale } = website?.configurations.locale;

  React.useEffect(() => {
    if (emptyFields.length <= 0 && !error) {
      return;
    }
    textFieldsValidated();
  }, [learn]);
  const textFieldsValidated = () => {
    let valid = true;
    setEmptyFields([]);
    const emptyFields = [];
    if (!learn.name) {
      valid = false;
      emptyFields.push(I18n.t(`website.name`));
    }
    if (!learn.mainPage.slug) {
      valid = false;
      emptyFields.push(I18n.t(`website.slug_title`));
    }
    if (!learn.mainPage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_title`));
    }
    if (!learn.mainPage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_description`));
    }
    if (!learn.mainPage.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.learn.main_page_title`));
    }
    if (!learn.mainPage.description.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.learn.main_page_description`));
    }
    // if (! learn.mainPage.button.text){
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.learn.main_page_button`));
    // }
    if (!learn.categoryPage.slug) {
      emptyFields.push(I18n.t(`website.learn.category_page_slug`));
    }
    if (!learn.categoryPage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.learn.category_page_meta_title`));
    }
    if (!learn.categoryPage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.learn.category_page_meta_description`));
    }
    if (!learn.postPage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.learn.post_page_meta_title`));
    }
    if (!learn.postPage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.learn.post_page_meta_description`));
    }
    setEmptyFields(emptyFields);
    return valid;
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <MRouterPrompt />
      <PageLocale website={website} locale={locale} handleLocaleChange={setLocale} />
      <WebsitesSectionsSwitch
        inSection
        enabled={sections.learn}
        title={I18n.t(`website.page_types.learn`)}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.learn) {
              handleToggleActive("learn", enabled);
            }
            setError(true);
          } else {
            setError(false);
            handleToggleActive("learn", enabled);
          }
        }}
      >
        <CustomUrlPrefixTextField
          previewUrls={[
            {
              key: "website.learn.main_page_preview_url",
              value: locale === defaultLocale ? learn.mainPage.slug : learn.translations[locale]?.mainPage?.slug || "",
              valid: sections.learn,
            },
            {
              key: "website.learn.category_page_preview_url",
              value: `${
                locale === defaultLocale
                  ? learn.categoryPage.slug
                  : learn.translations[locale]?.categoryPage?.slug || ""
              }/category-slug`,
            },
            {
              key: "website.learn.post_page_preview_url",
              value: `${
                locale === defaultLocale ? learn.mainPage.slug : learn.translations[locale]?.mainPage?.slug || ""
              }/category-slug/post-slug`,
            },
          ]}
          locale={locale}
          customUrlPrefix={learn.urlPrefix}
          alternativeUrlPrefix={learn.translations?.[locale]?.urlPrefix}
          handleValueChange={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...learn, urlPrefix: value });
              return;
            }
            learn.translations[locale] = {
              ...(learn.translations[locale] || {}),
              urlPrefix: value,
            };

            onPropertyChanged({ ...learn });
          }}
        />
      </WebsitesSectionsSwitch>

      {error && (
        <Typography variant={"body1"} className={classes.error}>
          {emptyFields.length > 0 && I18n.t("website.error_message")}
        </Typography>
      )}

      <PageSection sectionTitle={I18n.t("website.learn.main_page")}>
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            error={emptyFields.includes(I18n.t(`website.name`))}
            value={locale === defaultLocale ? learn.name : learn.translations[locale]?.name || ""}
            placeholder={I18n.t("website.name_ph")}
            title={I18n.t("website.name")}
            height={37}
            marginBottom={30}
            borderRadius={4}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...learn, name: value });
                return;
              }
              learn.translations[locale] = {
                ...(learn.translations[locale] || {}),
                name: value,
              };

              onPropertyChanged({ ...learn });
            }}
          />

          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            error={emptyFields.includes(I18n.t(`website.slug_title`))}
            value={locale === defaultLocale ? learn.mainPage.slug : learn.translations[locale]?.mainPage?.slug || ""}
            placeholder={I18n.t("website.slug_title_ph")}
            title={I18n.t("website.slug_title")}
            height={37}
            marginBottom={30}
            borderRadius={4}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...learn, mainPage: { ...learn.mainPage, slug: value } });
                return;
              }
              learn.translations[locale] = {
                ...(learn.translations[locale] || {}),
                mainPage: { ...(learn.translations[locale]?.mainPage || {}), slug: value },
              };
              onPropertyChanged({ ...learn });
            }}
          />
        </div>
        <SelectRow
          width={"50%"}
          bold
          flexDirection={"column"}
          rowTitle={I18n.t("website.learn.custom_top_section")}
          value={learn.mainPage.customTopSection ? "Yes" : "No"}
          selectOptions={["Yes", "No"]}
          handleChange={(value) => {
            onPropertyChanged({
              ...learn,
              mainPage: { ...learn.mainPage, customTopSection: value === "Yes" },
            });
          }}
        />

        <Divider className={classes.divider} />
        <TextInputToolAndDesign
          textFieldTitle={I18n.t("website.title")}
          error={emptyFields.includes(I18n.t(`website.learn.main_page_title`))}
          text={
            locale === defaultLocale
              ? learn.mainPage.title
              : { text: learn.translations[locale]?.mainPage?.title || "", css: learn.mainPage.title.css }
          }
          handleTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...learn, mainPage: { ...learn.mainPage, title: value } });
              return;
            }
            set(learn.translations[locale], "mainPage.title", value.text);
            onPropertyChanged({ ...learn });
          }}
          variant={"h1"}
        />

        <TextInputMentions
          tags={[SNWebsiteAutoCompleteMetaTag.year]}
          key={`learn_main_page_meta_title_${locale}`}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          error={emptyFields.includes(I18n.t(`website.meta_title`))}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale ? learn.mainPage.metaTitle : learn.translations[locale]?.mainPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            // onPropertyChanged({...learn, mainPage: {...learn.mainPage, metaTitle: value}})
            if (locale === defaultLocale) {
              onPropertyChanged({ ...learn, mainPage: { ...learn.mainPage, metaTitle: value } });
              return;
            }
            learn.translations[locale] = {
              ...(learn.translations[locale] || {}),
              "mainPage.metaTitle": value,
            };
            onPropertyChanged({ ...learn });
          }}
        />
        <Divider className={classes.divider} />

        <TextInputToolAndDesign
          textFieldTitle={I18n.t("website.description")}
          variant={"h4"}
          error={emptyFields.includes(I18n.t(`website.learn.main_page_description`))}
          text={
            locale === defaultLocale
              ? learn.mainPage.description
              : { text: learn.translations[locale]?.mainPage?.description || "", css: learn.mainPage.description.css }
          }
          handleTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...learn, mainPage: { ...learn.mainPage, description: value } });
              return;
            }
            set(learn.translations[locale], "mainPage.description", value.text);
            onPropertyChanged({ ...learn });
          }}
        />

        <TextInputMentions
          tags={[SNWebsiteAutoCompleteMetaTag.year]}
          key={`learn_main_page_meta_desc_${locale}`}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? learn.mainPage.metaDescription
              : learn.translations[locale]?.mainPage?.metaDescription || ""
          }
          error={emptyFields.includes(I18n.t(`website.meta_description`))}
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...learn,
                mainPage: { ...learn.mainPage, metaDescription: value },
              });
              return;
            }
            learn.translations[locale] = {
              ...(learn.translations[locale] || {}),
              "mainPage.metaDescription": value,
            };
            onPropertyChanged({ ...learn });
          }}
        />
      </PageSection>

      <PageSection sectionTitle={I18n.t("website.learn.category_page")}>
        <TextFieldMaxLength
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.learn.category_page_slug`))}
          value={
            locale === defaultLocale ? learn.categoryPage.slug : learn.translations[locale]?.categoryPage?.slug || ""
          }
          placeholder={I18n.t("website.slug_title_ph")}
          title={I18n.t("website.slug_title")}
          height={37}
          borderRadius={4}
          marginBottom={30}
          handleValueChange={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...learn, categoryPage: { ...learn.categoryPage, slug: value } });
              return;
            }
            learn.translations[locale] = {
              ...(learn.translations[locale] || {}),
              categoryPage: { ...(learn.translations[locale]?.categoryPage || {}), slug: value },
            };
            onPropertyChanged({ ...learn });
          }}
        />

        <TextInputMentions
          key={`learn_category_meta_title_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.categoryName, SNWebsiteAutoCompleteMetaTag.year]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.learn.category_page_meta_title`))}
          text={
            locale === defaultLocale
              ? learn.categoryPage.metaTitle
              : learn.translations[locale]?.categoryPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...learn,
                categoryPage: { ...learn.categoryPage, metaTitle: value },
              });
              return;
            }
            learn.translations[locale] = {
              ...(learn.translations[locale] || {}),
              "categoryPage.metaTitle": value,
            };
            onPropertyChanged({ ...learn });
          }}
        />

        <TextInputMentions
          key={`learn_category_meta_desc_${locale}`}
          tags={[
            SNWebsiteAutoCompleteMetaTag.categoryName,
            SNWebsiteAutoCompleteMetaTag.categoryDescription,
            SNWebsiteAutoCompleteMetaTag.year,
          ]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? learn.categoryPage.metaDescription
              : learn.translations[locale]?.categoryPage?.metaDescription || ""
          }
          error={emptyFields.includes(I18n.t(`website.learn.category_page_meta_description`))}
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...learn,
                categoryPage: { ...learn.categoryPage, metaDescription: value },
              });
              return;
            }
            learn.translations[locale] = {
              ...(learn.translations[locale] || {}),
              "categoryPage.metaDescription": value,
            };
            onPropertyChanged({ ...learn });
          }}
        />
      </PageSection>

      <PageSection sectionTitle={I18n.t("website.learn.post_page")}>
        <TextDesignToolWithPreview
          topTitle
          disableHover
          marginLeft={"-30px"}
          marginRight={"-30px"}
          elementTitle={I18n.t("website.element_design", { element: I18n.t("website.title") })}
          css={learn.postPage.title?.css}
          variant={"h1"}
          exampleText={I18n.t("website.learn.example_title_text")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...learn,
              postPage: { ...learn.postPage, title: { ...learn.postPage.title, css: newCss } },
            });
          }}
        />

        <TextInputMentions
          key={`learn_post_meta_title_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.postTitle, SNWebsiteAutoCompleteMetaTag.year]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale ? learn.postPage.metaTitle : learn.translations[locale]?.postPage?.metaTitle || ""
          }
          error={emptyFields.includes(I18n.t(`website.learn.post_page_meta_title`))}
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...learn, postPage: { ...learn.postPage, metaTitle: value } });
              return;
            }
            learn.translations[locale] = {
              ...(learn.translations[locale] || {}),
              "postPage.metaTitle": value,
            };
            onPropertyChanged({ ...learn });
          }}
        />
        <Divider className={classes.divider} />
        <TextDesignToolWithPreview
          disableHover
          topTitle
          marginLeft={"-30px"}
          marginRight={"-30px"}
          elementTitle={I18n.t("website.element_design", {
            element: I18n.t("website.description"),
          })}
          css={learn.postPage.description?.css}
          variant={"h2"}
          exampleText={I18n.t("website.learn.example_description_text")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...learn,
              postPage: {
                ...learn.postPage,
                description: { ...learn.postPage.description, css: newCss },
              },
            });
          }}
        />

        <TextInputMentions
          key={`learn_post_meta_desc_${locale}`}
          tags={[
            SNWebsiteAutoCompleteMetaTag.postTitle,
            SNWebsiteAutoCompleteMetaTag.postCategoryName,
            SNWebsiteAutoCompleteMetaTag.postText,
            SNWebsiteAutoCompleteMetaTag.postMetaDescription,
            SNWebsiteAutoCompleteMetaTag.year,
          ]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          error={emptyFields.includes(I18n.t(`website.learn.post_page_meta_description`))}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? learn.postPage.metaDescription
              : learn.translations[locale]?.postPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...learn,
                postPage: { ...learn.postPage, metaDescription: value },
              });
              return;
            }
            learn.translations[locale] = {
              ...(learn.translations[locale] || {}),
              "postPage.metaDescription": value,
            };
            onPropertyChanged({ ...learn });
          }}
        />
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLearn);
