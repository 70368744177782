import * as React from "react";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";
import MTableHeaderLabelWithSelectAll from "../../../../components/Table/Table/MTableHeaderLabelWithSelectAll";

type PropTypes = {
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  setSortField?: (input: { field: string; direction: "asc" | "desc" }) => void;
  selectedNr: number;
  clearSelected(): void;
  selectAll(): void;
  total: number;
};

const OutlinePageColumns = (props: PropTypes) => {
  const { sortField, setSortField, total, selectedNr, clearSelected, selectAll } = props;
  return (
    <MTableHeader>
      <MTableHeaderLabelWithSelectAll
        selected={selectedNr > 0}
        onSelectAll={selectAll}
        onClearAll={clearSelected}
        columnWidth={"33%"}
      >
        {numeral(total).format("0,0a")}{" "}
        {total !== 1 ? I18n.t("strategy.outlinePages.topics") : I18n.t("strategy.outlinePages.topic")}
      </MTableHeaderLabelWithSelectAll>
      <MTableHeaderLabel> {I18n.t("strategy.strategyKeywords.listColumns.status")}</MTableHeaderLabel>
      <MTableHeaderSortLabel
        sortField={sortField}
        field={"conversion_potential"}
        onClick={(result) => setSortField(result)}
      >
        {I18n.t("strategy.strategyKeywords.listColumns.stage")}
      </MTableHeaderSortLabel>

      <MTableHeaderLabel>{I18n.t("strategy.strategyKeywords.listColumns.pages")}</MTableHeaderLabel>
      <MTableHeaderSortLabel field={"clicks"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.strategyKeywords.listColumns.traffic")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel onClick={(result) => setSortField(result)} field={"position"} sortField={sortField}>
        {I18n.t("strategy.strategyKeywords.listColumns.position")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel field={"potential_value"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.strategyKeywords.listColumns.potentialValue")}
      </MTableHeaderSortLabel>

      <MTableHeaderLabel> {I18n.t("strategy.strategyKeywords.listColumns.kws")}</MTableHeaderLabel>
      <MTableHeaderSortLabel field={"volume"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.strategyKeywords.listColumns.vol")}
      </MTableHeaderSortLabel>
      <MTableHeaderLabel> {I18n.t("strategy.strategyKeywords.listColumns.marketingGoals")}</MTableHeaderLabel>

      <MTableHeaderLabel>{""}</MTableHeaderLabel>
    </MTableHeader>
  );
};

export default OutlinePageColumns;
