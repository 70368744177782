import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { FUNNELS } from "../../../../../../reducers/constants/actionTypes";
import FunnelStep from "./FunnelStep";

const useStyles = makeStyles((theme: Theme) => ({
  stepsWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: 24,
    marginTop: 24,
  },
  addStepWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
}));

const mapStateToProps = (state) => ({
  config: state.funnels.config,
});

const mapDispatchToProps = (dispatch) => ({
  updateConfig(payload) {
    dispatch({ type: FUNNELS.FUNNEL_CONFIG_UPDATED, payload });
  },
});

type PropTypes = {
  config: any;
  updateConfig: (payload) => void;
};
const FunnelSidebarSteps = (props: PropTypes) => {
  const { config, updateConfig } = props;

  const classes = useStyles();

  const removeStep = (index: number) => {
    const newConfig = { ...config };
    if (newConfig.steps.length > 1) {
      newConfig.steps.splice(index, 1);
      updateConfig(newConfig);
    }
  };
  // filter fields
  // {
  //   condition: "IS",
  //     field: "os",
  //   value: "mac",
  // },

  return (
    <div className={classes.stepsWrapper}>
      {[
        ...(config.steps ? config.steps : []),
        {
          events: [
            {
              condition: "",
              value: "",
              filters: [],
            },
          ],
        },
      ].map((step, index) => (
        <FunnelStep step={step} key={index} index={index} removeStep={removeStep} />
      ))}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FunnelSidebarSteps);
