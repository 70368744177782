import { findIndex } from "lodash";
import { ADS } from "./constants/actionTypes";

const initialState = {
  adsLoaded: false,
  ads: [],
  adsSearchText: "",
  searchConsoleInterval: "last3Months",

  // editAd
  adLoaded: false,
  ad: null,
  selectedPlacement: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADS.ADS_LIST_LOADED:
      return {
        ...state,
        adsLoaded: true,
        ads: action.payload.ads,
      };
    case ADS.ADS_LIST_AD_CREATED:
      return {
        ...state,
        ads: [action.ad, ...state.ads],
      };
    case ADS.ADS_LIST_AD_CHANGED:
      return {
        ...state,
        ads: state.ads.map((ad) => {
          if (ad._id === action.ad._id) {
            return action.ad;
          }
          return ad;
        }),
      };
    case ADS.ADS_LIST_AD_DELETED:
      return {
        ...state,
        ads: state.ads.filter((a) => a._id !== action.ad._id),
      };
    case ADS.ADS_LIST_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        adsSearchText: action.searchText,
      };
    case ADS.ADS_LIST_SEARCH_CONSOLE_INTERVAL_CHANGED:
      return {
        ...state,
        searchConsoleInterval: action.interval,
      };

    case ADS.EDIT_AD_LOADED:
      return {
        ...state,
        adLoaded: true,
        ad: action.ad,
        selectedPlacement:
          state.selectedPlacement ||
          (action.ad.placements.length > 0 ? action.ad.placements[0] : { type: "TALL_SIDE" }),
      };
    case ADS.EDIT_AD_CHANGED:
      return {
        ...state,
        ad: action.ad,
      };
    case ADS.EDIT_AD_PLACEMENT_SELECTED:
      return {
        ...state,
        selectedPlacement: action.placement,
      };
    case ADS.EDIT_AD_PLACEMENT_CHANGED: {
      let { placements } = state.ad;
      const index = findIndex(state.ad.placements, { type: action.placement.type });
      if (index === -1) {
        placements = [...placements, action.placement];
      } else {
        placements[index] = action.placement;
      }
      return {
        ...state,
        ad: { ...state.ad, placements },
        selectedPlacement:
          state.selectedPlacement.type === action.placement.type ? action.placement : state.selectedPlacement,
      };
    }
    case ADS.EDIT_AD_CLEANUP:
      return {
        ...state,
        adLoaded: false,
        ad: null,
        selectedPlacement: null,
      };
    default:
      return state;
  }
};
