/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-plusplus */

import numeral from "numeral";
import { isEqual } from "lodash";
import {
  eWidgetFieldType,
  eWidgetSubtype,
  eWidgetType,
  IProduct,
  IWebsite,
  IWidget,
  IWidgetBulletedListFieldProps,
  IWidgetButtonFieldProps,
  IWidgetCallToActionAndReviewProps,
  IWidgetCallToActionProps,
  IWidgetField,
  IWidgetFieldProperties,
  IWidgetImagesFieldProps,
  IWidgetItem,
  IWidgetLogoFieldProps,
  IWidgetMediaFieldProps,
  IWidgetPriceFieldProps,
  IWidgetProductNameFieldProps,
  IWidgetProductRatingFieldProps,
  IWidgetProductReviewsNumberFieldProps,
  IWidgetProductScoreFieldProps,
  IWidgetShortDescriptionFieldProps,
  IWidgetTopicsWidgetFieldProps,
} from "../../../../../reducers/constants/objectTypes";

type IGetProductDefaultValueProps = {
  widget: IWidget;
  field: IWidgetField;
  product: IProduct;
  defaultsOnly?: boolean;
  website: IWebsite;
};

const transformLoadedWidget = (widget: IWidget) => {
  const customWidgetFieldsOrder = {
    [eWidgetSubtype.PRODUCT_REVIEW]: [
      eWidgetFieldType.INTRO_TEXT,
      eWidgetFieldType.TAG,
      eWidgetFieldType.PRODUCT_NUMBER,
      eWidgetFieldType.LOGO,
      eWidgetFieldType.AVATAR,
      eWidgetFieldType.SCORE,
      eWidgetFieldType.MEDIAS,
      eWidgetFieldType.PRODUCT_NAME,
      eWidgetFieldType.CALL_TO_ACTION,
      eWidgetFieldType.KEY_DECISION_FACTORS,
      eWidgetFieldType.EXPANDABLE_TEXT_SECTION,
      eWidgetFieldType.PROS_CONS,
    ],
    [eWidgetSubtype.CHARTICLE_TOP_PRODUCTS]: [
      eWidgetFieldType.INTRO_TEXT,
      eWidgetFieldType.TAG,
      eWidgetFieldType.PRODUCT_NUMBER,
      eWidgetFieldType.LOGO,
      eWidgetFieldType.AVATAR,
      eWidgetFieldType.PRODUCT_NAME,
      eWidgetFieldType.SCORE,
      eWidgetFieldType.RATING,
      eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS,
      eWidgetFieldType.CALL_TO_ACTION,
      eWidgetFieldType.SHORT_DESCRIPTION,
      eWidgetFieldType.BULLETED_LIST,
      eWidgetFieldType.BUTTON,
    ],
    [eWidgetSubtype.PRODUCT_CTA]: [
      eWidgetFieldType.PRODUCT_NUMBER,
      eWidgetFieldType.MEDIAS,
      eWidgetFieldType.TITLE,
      eWidgetFieldType.PRODUCT_NAME,
      eWidgetFieldType.RATING,
      eWidgetFieldType.PRICE,
      eWidgetFieldType.BUTTON,
    ],
    [eWidgetSubtype.PRODUCT_CTA_BANNER]: [
      eWidgetFieldType.ICON,
      eWidgetFieldType.PRODUCT_NAME,
      eWidgetFieldType.RATING,
      eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS,
      eWidgetFieldType.SHORT_DESCRIPTION,
      eWidgetFieldType.BULLETED_LIST,
      eWidgetFieldType.BUTTON,
    ],
    [eWidgetSubtype.PRODUCT_RATING]: [
      eWidgetFieldType.TAG,
      eWidgetFieldType.SCORE,
      eWidgetFieldType.PRODUCT_NUMBER,
      eWidgetFieldType.LOGO,
      eWidgetFieldType.MEDIA,
      eWidgetFieldType.PRODUCT_NAME,
      eWidgetFieldType.SHORT_DESCRIPTION,
      eWidgetFieldType.RATING,
      eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS,
      eWidgetFieldType.TOPICS,
      eWidgetFieldType.SECONDARY_BUTTON,
      eWidgetFieldType.PRIMARY_BUTTON,
    ],
    [eWidgetSubtype.PRODUCTS_CAROUSEL]: [
      eWidgetFieldType.TAG,
      eWidgetFieldType.SCORE,
      eWidgetFieldType.MEDIA,
      eWidgetFieldType.PRODUCT_NAME,
      eWidgetFieldType.SHORT_DESCRIPTION,
      eWidgetFieldType.RATING,
      eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS,
      eWidgetFieldType.TOPICS,
      eWidgetFieldType.CALL_TO_ACTION,
    ],
  };

  const reorderFields = (fields: IWidgetField[], widgetSubtype: eWidgetSubtype) => {
    const fieldsOrder = customWidgetFieldsOrder[widgetSubtype];
    if (!fieldsOrder) return fields;

    const orderedFields = [];

    fieldsOrder.forEach((fieldType: string) => {
      const field = fields.find((f) => f.type === fieldType);
      if (field) {
        orderedFields.push(field);
      }
    });

    return [...orderedFields, ...fields.filter((f) => !orderedFields.includes(f))];
  };

  if (widget.type !== eWidgetType.CUSTOM_WIDGET) {
    return widget;
  }

  return {
    ...widget,
    items: widget.items.map((item) => ({
      ...item,
      steps: item.steps.map((step) => ({
        ...step,
        fields: reorderFields(step.fields, widget.subtype),
      })),
    })),
  };
};

const isProductField = (field: IWidgetField) => {
  const productFields = [
    eWidgetFieldType.BUTTON,
    eWidgetFieldType.PRODUCT_NAME,
    eWidgetFieldType.SCORE,
    eWidgetFieldType.RATING,
    eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS,
    eWidgetFieldType.SHORT_DESCRIPTION,
    eWidgetFieldType.CALL_TO_ACTION,
    eWidgetFieldType.ICON,
    eWidgetFieldType.LOGO,
    eWidgetFieldType.MEDIAS,
    eWidgetFieldType.BULLETED_LIST,
    eWidgetFieldType.PRICE,
    eWidgetFieldType.TOPICS,
  ];

  return productFields.includes(field.type as eWidgetFieldType);
};

const getProductDefaultValue = ({
  widget,
  field,
  product,
  defaultsOnly = false,
  website,
}: IGetProductDefaultValueProps) => {
  if (!product || !field) return null;

  const { type } = field;
  const properties = field.properties ?? {};

  const {
    callToAction,
    callToActionURL,
    name,
    displayName,
    score,
    rating,
    reviewLink,
    bullets,
    shortDescription,
    numOfReviews,
    pricing,
    link,
    tags,
  } = product;

  // custom fields logic
  if (widget.subtype === eWidgetSubtype.PRODUCTS_CAROUSEL) {
    if (field.type === eWidgetFieldType.CALL_TO_ACTION) {
      const {
        title,
        href,
        openAction = (properties as IWidgetCallToActionProps)?.href &&
        ![callToActionURL, link].includes((properties as IWidgetCallToActionProps)?.href)
          ? "custom"
          : "call_to_action",
      } = properties as IWidgetCallToActionProps;

      if (openAction === "call_to_action") {
        return {
          title: defaultsOnly ? callToAction : title !== undefined ? title : callToAction,
          href: defaultsOnly ? callToActionURL : href !== undefined ? href : callToActionURL,
        };
      }

      if (openAction === "product") {
        return {
          title: defaultsOnly ? callToAction : title !== undefined ? title : callToAction,
          href: defaultsOnly ? link : href !== undefined ? href : link,
        };
      }

      if (defaultsOnly) {
        return {
          title: callToAction,
          href: callToActionURL,
        };
      }

      return {};
    }
  }

  switch (type) {
    case eWidgetFieldType.BUTTON:
    case eWidgetFieldType.SECONDARY_BUTTON:
    case eWidgetFieldType.PRIMARY_BUTTON: {
      const {
        title,
        href,
        openAction = (properties as IWidgetButtonFieldProps)?.href &&
        ![callToActionURL, link].includes((properties as IWidgetButtonFieldProps)?.href)
          ? "custom"
          : "call_to_action",
      } = properties as IWidgetButtonFieldProps;

      if (openAction === "call_to_action") {
        return {
          title: defaultsOnly ? callToAction : title !== undefined ? title : callToAction,
          href: defaultsOnly ? callToActionURL : href !== undefined ? href : callToActionURL,
        };
      }

      if (openAction === "product") {
        return {
          title: defaultsOnly ? callToAction : title !== undefined ? title : callToAction,
          href: defaultsOnly ? link : href !== undefined ? href : link,
        };
      }

      if (defaultsOnly) {
        if (type === eWidgetFieldType.BUTTON || type === eWidgetFieldType.PRIMARY_BUTTON) {
          return {
            title: callToAction,
            href: callToActionURL,
          };
        }

        if (type === eWidgetFieldType.SECONDARY_BUTTON) {
          return {
            title: callToAction,
            href: link,
          };
        }
      }

      return {};
    }

    case eWidgetFieldType.PRODUCT_NAME: {
      const { value } = properties as IWidgetProductNameFieldProps;
      return { value: defaultsOnly ? displayName || name : value || displayName || name };
    }

    case eWidgetFieldType.SCORE: {
      const { value } = properties as IWidgetProductScoreFieldProps;
      return { value: defaultsOnly ? score : value || score };
    }

    case eWidgetFieldType.RATING: {
      const { value } = properties as IWidgetProductRatingFieldProps;
      return { value: defaultsOnly ? rating : value || rating };
    }

    case eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS: {
      const { value } = properties as IWidgetProductReviewsNumberFieldProps;
      return { value: defaultsOnly ? numOfReviews?.toString() : value || numOfReviews?.toString() };
    }

    case eWidgetFieldType.SHORT_DESCRIPTION: {
      const { value } = properties as IWidgetShortDescriptionFieldProps;
      return { value: defaultsOnly ? shortDescription : value || shortDescription };
    }

    case eWidgetFieldType.CALL_TO_ACTION: {
      const { reviewUrl, buttonText, buttonUrl } = properties as IWidgetCallToActionAndReviewProps;
      return {
        reviewUrl: defaultsOnly ? reviewLink : reviewUrl || reviewLink,
        buttonText: defaultsOnly ? callToAction : buttonText || callToAction,
        buttonUrl: defaultsOnly ? callToActionURL : buttonUrl || callToActionURL,
      };
    }

    case eWidgetFieldType.ICON:
    case eWidgetFieldType.LOGO: {
      const imageFile =
        type === eWidgetFieldType.ICON
          ? product?.iconFile || product?.logoFile
          : product?.logoFile || product?.iconFile;

      const defaultSrc = imageFile?.cdnUrl || product?.logo;
      const defaultAlt = imageFile?.alt || `${name}_alt`;
      const defaultSize = imageFile?.size || "";
      const defaultTitle = imageFile?.title ?? (defaultSrc ? name : null);

      const { src, size, title, alt } = properties as IWidgetLogoFieldProps;

      return {
        src: defaultsOnly ? defaultSrc : src || defaultSrc,
        size: defaultsOnly ? defaultSize : size || defaultSize,
        title: defaultsOnly ? defaultTitle : title || defaultTitle,
        alt: defaultsOnly ? defaultAlt : alt || defaultAlt,
      };
    }

    case eWidgetFieldType.MEDIAS: {
      const { medias } = field.properties as IWidgetImagesFieldProps;

      const productImages = product.imageFiles.map(
        ({ cdnUrl, size, title, alt }) =>
          ({
            src: cdnUrl,
            size: numeral(size).format("0.0 b"),
            title,
            alt,
            type: "image",
          } as IWidgetImagesFieldProps["medias"][number])
      );

      return { medias: defaultsOnly ? productImages : medias || productImages };
    }

    case eWidgetFieldType.BULLETED_LIST: {
      const { list } = field.properties as IWidgetBulletedListFieldProps;
      return {
        list: defaultsOnly ? bullets : list || bullets,
      };
    }

    case eWidgetFieldType.PRICE: {
      const { value, currency } = properties as IWidgetPriceFieldProps;
      const websiteCurrency = website.configurations.locale.currency;

      return {
        value: defaultsOnly
          ? pricing?.price || pricing?.startingPrice
          : value || pricing?.price || pricing?.startingPrice,
        currency: defaultsOnly ? websiteCurrency : currency || websiteCurrency,
      };
    }

    case eWidgetFieldType.MEDIA: {
      const { type, image } = properties as IWidgetMediaFieldProps;
      if (type === "image") {
        const { cdnUrl, alt, title, size } = product.imageFiles?.[0] || {};
        return {
          image: {
            src: defaultsOnly ? cdnUrl : image?.src || cdnUrl,
            alt: defaultsOnly ? alt : image?.alt || alt,
            title: defaultsOnly ? title : image?.title || title,
            size: defaultsOnly ? size : image?.size || size,
          },
        };
      }
    }

    case eWidgetFieldType.TOPICS: {
      const { list } = field.properties as IWidgetTopicsWidgetFieldProps;
      return {
        list: defaultsOnly ? tags : list || tags,
      };
    }

    default:
      return null;
  }
};

export const defaultWidgetFieldSettings = {
  hideable: true,
  deletable: false,
  draggable: false,
  forcedPosition: null,
};

const getFieldSettings = (field: IWidgetField, widget: IWidget) => {
  if (widget.templateId) {
    return field?.settings || defaultWidgetFieldSettings;
  }

  if (
    widget.type === eWidgetType.CUSTOM_WIDGET ||
    (widget.type === eWidgetType.INLINE &&
      (widget.subtype === eWidgetSubtype.TEXT_CTA || widget.subtype === eWidgetSubtype.RELATED_CONTENT))
  ) {
    return defaultWidgetFieldSettings;
  }

  return {
    deletable: true,
    hideable: true,
    draggable: true,
    forcedPosition: null,
  };
};

const getWidgetAddableFields = (widget: IWidget) => {
  const formFields = [
    {
      type: "image",
      category: "media",
    },
    {
      type: "short_text",
      category: "input_fields",
      properties: {
        label: "Short Text",
        placeholder: null,
        width: "100%",
        required: false,
        hint: null,
      },
    },
    {
      type: "long_text",
      category: "input_fields",
      properties: {
        showLabel: false,
        width: "100%",
        required: false,
        placeholder: null,
        label: "Long Text",
        hint: null,
      },
    },
    {
      type: "email",
      category: "input_fields",
      properties: {
        label: "Email",
        hint: null,
        placeholder: null,
      },
    },
    {
      type: "url",
      category: "input_fields",
      properties: {
        label: "URL",
        hint: null,
        placeholder: null,
        required: false,
        width: "100%",
      },
    },
    {
      type: "phone",
      category: "input_fields",
      properties: {
        label: "Phone",
        hint: null,
        placeholder: null,
        width: "100%",
      },
    },
    {
      type: "date",
      category: "input_fields",
      properties: {
        label: "Date",
        hint: null,
        placeholder: null,
        dateFormat: null,
        required: false,
        showLabel: false,
        width: "100%",
      },
    },
    // {
    //   type: "file",
    //   label: "File",
    //   category: "input_fields",
    //   properties: {
    //     label: "File",
    //     allowedFileTypes: 'any',
    //     specifiedFileTypes: null,
    //     required: false,
    //     showLabel: false,
    //   },
    // },
    {
      type: "hidden",
      category: "input_fields",
      properties: {
        label: "Hidden",
        placeholder: null,
        width: "100%",
      },
    },
    {
      type: "heading",
      category: "text_fields",
      properties: {
        title: "Heading",
        caption: "",
        placeholder: null,
        alignment: "Left",
        width: "100%",
      },
    },
    {
      type: "paragraph",
      category: "text_fields",
      properties: {
        showLabel: false,
        caption: null,
        title: null,
        width: "100%",
      },
    },
    {
      type: "label",
      category: "text_fields",
      properties: {
        label: "Label",
        width: "100%",
      },
    },
    {
      type: "checkbox",
      category: "selection_fields",
      properties: {
        label: "Checkbox",
        hint: null,
        options: ["Option 1", "Option 2", "Option 3"],
        otherOption: false,
        multipleSelection: false,
        randomizeOptions: false,
        splitOptionsColumns: 1,
        required: false,
        showLabel: false,
        width: "100%",
      },
    },
    {
      type: "dropdown",
      category: "selection_fields",
      properties: {
        label: "Dropdown",
        hint: null,
        placeholder: null,
        dateFormat: null,
        options: ["Option 1", "Option 2", "Option 3"],
        selectedOption: "None",
        required: false,
        showLabel: false,
        width: "100%",
      },
    },
    {
      type: "consent",
      category: "selection_fields",
      properties: {
        required: false,
        hint: null,
        width: "100%",
      },
    },
    {
      type: "star_scale",
      category: "ratings_fields",
      properties: {
        label: "Start Scale",
        hint: null,
        numberOfOptions: 3,
        required: false,
        showLabel: false,
      },
    },
    {
      type: "numbers",
      category: "ratings_fields",
      properties: {
        label: "Numbers",
        hint: null,
        rangeMin: 2,
        rangeMax: 8,
        leftCaption: "Not likely",
        rightCaption: "Very likely",
        required: false,
        showLabel: false,
      },
    },
    {
      type: "smiley",
      category: "ratings_fields",
      properties: {
        label: "Numbers",
        hint: null,
        required: false,
        showLabel: false,
      },
    },
    {
      type: "like",
      category: "ratings_fields",
      properties: {
        label: "Like",
        hint: null,
        thumbUp: "Like",
        thumbDown: "Dislike",
        required: false,
        showLabel: false,
      },
    },
  ] as IWidgetField[];
  const inlineFields = [
    { type: "title", properties: { value: "Title" } },
    { type: "text", properties: { value: '[{"type":"paragraph","children":[{"text":"Text"}]}]' } },
    {
      type: "button",
      properties: { text: "Button Text", includeQueryParams: true, openInNewTab: true, fullWidth: true },
    },
    { type: "image" },
    { type: "coupon" },
    {
      type: "link",
      properties: { text: "Link Text", includeQueryParams: true, openInNewTab: true },
    },
    { type: "form" },
    { type: "inline_html" },
    { type: "iframe" },
    { type: "hubspot_form" },
    {
      type: "related_articles",
      properties: {
        list: [],
      },
    },
  ] as IWidgetField[];

  switch (widget.type) {
    case eWidgetType.FORM:
      return formFields;
    case eWidgetType.CUSTOM_WIDGET:
      return [];
    case eWidgetType.INLINE:
      switch (widget.subtype) {
        case eWidgetSubtype.TEXT_CTA:
        case eWidgetSubtype.RELATED_CONTENT:
          return [];
        default:
          return inlineFields;
      }
    default:
      return inlineFields;
  }
};

const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

const hasVisibleContrast = (color1: string, color2: string) => {
  function parseColor(color: string): [number, number, number] {
    if (color.startsWith("#")) {
      // Hex format
      const hex = color.substring(1);
      const isValidHex = /^[0-9a-f]{3}$|^[0-9a-f]{6}$/i.test(hex);
      if (isValidHex) {
        let r: number;
        let g: number;
        let b: number;
        if (hex.length === 3) {
          r = parseInt(hex[0] + hex[0], 16);
          g = parseInt(hex[1] + hex[1], 16);
          b = parseInt(hex[2] + hex[2], 16);
        } else {
          r = parseInt(hex.substring(0, 2), 16);
          g = parseInt(hex.substring(2, 4), 16);
          b = parseInt(hex.substring(4, 6), 16);
        }
        return [r, g, b];
      }
    } else if (color.startsWith("rgba(") && color.endsWith(")")) {
      // RGBA format
      const rgba = color.substring(5, color.length - 1);
      const [r, g, b, a] = rgba.split(",").map((c) => parseFloat(c.trim()));
      const isValidRgba =
        !Number.isNaN(r) && !Number.isNaN(g) && !Number.isNaN(b) && !Number.isNaN(a) && a >= 0 && a <= 1;
      if (isValidRgba) {
        return [Math.round(r), Math.round(g), Math.round(b)];
      }
    }

    throw new Error("Invalid color format");
  }

  function luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map((v) => {
      const temp = v / 255;
      return temp <= 0.03928 ? temp / 12.92 : ((temp + 0.055) / 1.055) ** 2.4;
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  try {
    const [r1, g1, b1] = parseColor(color1);
    const [r2, g2, b2] = parseColor(color2);
    const lum1 = luminance(r1, g1, b1);
    const lum2 = luminance(r2, g2, b2);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05) > 4.5;
  } catch (e) {
    return false;
  }
};

const excludeSameValueProperties = (obj1: IWidgetFieldProperties, fieldsToCheck: IWidgetFieldProperties) => {
  const result = {};
  if (!fieldsToCheck) return obj1;

  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key) && Object.prototype.hasOwnProperty.call(fieldsToCheck, key)) {
      if (!isEqual(obj1[key], fieldsToCheck[key])) {
        result[key] = obj1[key];
      }
    } else {
      result[key] = obj1[key];
    }
  }

  return result;
};

const handleFieldEditDuringIteration = (widget: IWidget, currentField: IWidgetField, newField: IWidgetField) => {
  let updatedField = { ...currentField };
  switch (widget.subtype) {
    case eWidgetSubtype.PRODUCT_REVIEW:
      if (
        (newField.type === "medias" && newField.visible && currentField.type === "avatar") ||
        (newField.type === "avatar" && newField.visible && currentField.type === "medias")
      ) {
        updatedField = {
          ...updatedField,
          visible: false,
        };
      }
      break;
    case eWidgetSubtype.CHARTICLE_TOP_PRODUCTS:
      if (
        (newField.type === "logo" && newField.visible && currentField.type === "avatar") ||
        (newField.type === "avatar" && newField.visible && currentField.type === "logo")
      ) {
        updatedField = {
          ...currentField,
          visible: false,
        };
      }
      break;
    default:
      break;
  }
  return updatedField;
};

export {
  transformLoadedWidget,
  getFieldSettings,
  getProductDefaultValue,
  capitalizeFirstLetter,
  hasVisibleContrast,
  excludeSameValueProperties,
  handleFieldEditDuringIteration,
  isProductField,
  getWidgetAddableFields,
};
