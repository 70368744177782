import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import * as React from "react";
import { useMemo } from "react";
import moment from "moment";
import { orderBy } from "lodash";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import MTable from "../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";
import { getWebsiteRelativeUrl } from "../../../../helpers/urlHelper";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    padding: 0,
    margin: 0,
  },
  firstColumn: {
    transition: "all 0.3s",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
}));

type Props = {
  data: any[];
  reportType?: string;
  startDate: Date;
  selectedWebsite: IWebsite;
};

const ReportsCTATable = (props: Props) => {
  const { data, reportType, startDate, selectedWebsite } = props;

  const classes = useStyles();

  const dateRange = useMemo(() => {
    const dates = [];

    const start = moment(startDate).startOf("month");
    const end = moment().endOf("month");

    while (start.isBefore(end)) {
      dates.push(start.format("YYYY-MM-DD"));
      start.add(1, "month");
    }

    return dates;
  }, [startDate]);
  const [sortField, setSortField] = React.useState<{ direction: "desc" | "asc" | undefined; field: string }>(null);
  const [sortedData, setSortedData] = React.useState<any[]>([...data]);

  React.useEffect(() => {
    if (sortField) {
      setSortedData(orderBy(data, sortField.field, sortField.direction));
    }
  }, [sortField, data]);

  const getItemRow = (item: any, index: number) => {
    switch (reportType) {
      case "cta-origin":
        return (
          <MTableRow handleRowClick={() => console.log("click")} key={index} fullBorderWidth>
            <MTableCellFirst
              columnWidth={"30%"}
              toolTipProps={{ enterDelay: 300 }}
              actions={[]}
              fontSize={14}
              wrapperClassName={classes.firstColumn}
              onClick={() => window.open(item.origin, "_blank")}
              cellText={getWebsiteRelativeUrl(item.origin, selectedWebsite)}
            />
            {dateRange.map((date, dateIndex) => (
              <MTableCellNumber value={item?.date_data?.[date]} key={dateIndex} type={"bigNumber"} />
            ))}
            <MTableCellNumber value={item.total} type={"bigNumber"} />
          </MTableRow>
        );
      case "cta-by-url":
        return (
          <MTableRow handleRowClick={() => console.log("click")} key={index} fullBorderWidth>
            <MTableCellFirst
              columnWidth={"30%"}
              toolTipProps={{ enterDelay: 300 }}
              actions={[]}
              fontSize={14}
              wrapperClassName={classes.firstColumn}
              onClick={() => window.open(item.url, "_blank")}
              cellText={getWebsiteRelativeUrl(item.url, selectedWebsite)}
            />
            {dateRange.map((date, dateIndex) => (
              <MTableCellNumber value={item?.date_data?.[date]} key={dateIndex} type={"bigNumber"} />
            ))}
            <MTableCellNumber value={item.total} type={"bigNumber"} />
          </MTableRow>
        );
      case "cta-by-destination":
        return (
          <MTableRow handleRowClick={() => console.log("click")} key={index} fullBorderWidth>
            <MTableCellFirst
              columnWidth={"30%"}
              toolTipProps={{ enterDelay: 300 }}
              actions={[]}
              fontSize={14}
              wrapperClassName={classes.firstColumn}
              onClick={() => window.open(item.destination, "_blank")}
              cellText={getWebsiteRelativeUrl(item.destination, selectedWebsite)}
            />
            {dateRange.map((date, dateIndex) => (
              <MTableCellNumber value={item?.date_data?.[date]} key={dateIndex} type={"bigNumber"} />
            ))}
            <MTableCellNumber value={item.total} type={"bigNumber"} />
          </MTableRow>
        );
      default:
        return null;
    }
  };
  const getHeaders = () => {
    switch (reportType) {
      case "cta-origin":
        return (
          <MTableHeader top={0}>
            <MTableHeaderSortLabel
              columnWidth={"30%"}
              field={"origin"}
              onClick={(value) => setSortField(value)}
              sortField={sortField}
            >
              {I18n.t("reports.column_names.landing_page")}
            </MTableHeaderSortLabel>
            {dateRange.map((date, dateIndex) => (
              <MTableHeaderLabel
                // field={`date_data.${date}`}
                // onClick={(value) => setSortField(value)}
                // sortField={sortField}
                key={dateIndex}
                fontSize={14}
              >
                {moment(date).format("MMM")}
              </MTableHeaderLabel>
            ))}
            <MTableHeaderSortLabel
              field={"total"}
              onClick={(value) => setSortField(value)}
              sortField={sortField}
              fontSize={14}
            >
              {I18n.t("reports.column_names.total")}
            </MTableHeaderSortLabel>
          </MTableHeader>
        );
      case "cta-by-destination":
        return (
          <MTableHeader top={0}>
            <MTableHeaderSortLabel
              columnWidth={"30%"}
              field={"destination"}
              onClick={(value) => setSortField(value)}
              sortField={sortField}
            >
              {I18n.t("reports.column_names.destination")}
            </MTableHeaderSortLabel>
            {dateRange.map((date, dateIndex) => (
              <MTableHeaderLabel fontSize={14} key={dateIndex}>
                {moment(date).format("MMM")}
              </MTableHeaderLabel>
            ))}
            <MTableHeaderSortLabel
              field={"total"}
              onClick={(value) => setSortField(value)}
              sortField={sortField}
              fontSize={14}
            >
              {I18n.t("reports.column_names.total")}
            </MTableHeaderSortLabel>
          </MTableHeader>
        );
      case "cta-by-url":
        return (
          <MTableHeader top={0}>
            <MTableHeaderSortLabel
              columnWidth={"30%"}
              field={"url"}
              onClick={(value) => setSortField(value)}
              sortField={sortField}
            >
              {I18n.t("reports.column_names.origin")}
            </MTableHeaderSortLabel>
            {dateRange.map((date, dateIndex) => (
              <MTableHeaderLabel fontSize={14} key={dateIndex}>
                {moment(date).format("MMM")}
              </MTableHeaderLabel>
            ))}
            <MTableHeaderSortLabel
              field={"total"}
              onClick={(value) => setSortField(value)}
              sortField={sortField}
              fontSize={14}
            >
              {I18n.t("reports.column_names.total")}
            </MTableHeaderSortLabel>
          </MTableHeader>
        );
      default:
        return null;
    }
  };

  return (
    <MTableWrapper className={classes.tableWrapper}>
      <MTable>
        {sortedData.length > 0 && getHeaders()}

        {sortedData.length > 0 && sortedData.map((item, index) => getItemRow(item, index))}
      </MTable>
    </MTableWrapper>
  );
};

export default ReportsCTATable;
