import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import _ from "lodash";
import MSelectMultiple from "../../../components/MSelectMultiple";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";
import { IQueryGroup } from "../../../reducers/constants/objectTypes";
import useDebounce from "../../../hooks/useDebounce";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    width: "100%",
    display: "flex",
    marginBottom: 10,
    alignItems: "center",
  },
  selectMultipleWrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  keywordsFieldTitle: {
    minWidth: 170,
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    marginRight: 15,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
  },
  sectionWrapper: {
    width: "100%",
    borderRadius: 5,
    border: `solid 1px ${theme.palette.divider}`,
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 20,
  },
  bodyWrapper: {
    padding: 20,
  },
  titleInput: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  wrap: {
    display: "flex",
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  leftSide: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  rightSide: {
    width: "100%",
    maxWidth: 60,
    display: "flex",
    flexDirection: "column",
  },
}));

type PropTypes = {
  postTitle: {
    title: string;
    targetKeywordsGroups: IQueryGroup[];
    notes: string;
  };
  keywordGroupsOptions: IQueryGroup[];
  handleTitleChange: (value) => void;
};

const TitleSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { postTitle, keywordGroupsOptions, handleTitleChange } = props;
  const [selectedKeywords, setSelectedKeywords] = React.useState(
    postTitle?.targetKeywordsGroups?.map((t) => ({
      name: t,
      id: _.uniqueId(),
    }))
  );
  const keywordsOptions = React.useMemo(
    () => keywordGroupsOptions?.map((t) => ({ name: t.clicks_main_keyword, id: _.uniqueId() })),
    [keywordGroupsOptions]
  );

  const handleAddedToTargetKeywordsGroups = (kwGroup) => {
    const { name } = kwGroup;
    const keywords = [...selectedKeywords, { name, id: _.uniqueId() }];
    setSelectedKeywords(keywords);
    handleTitleChange({
      ...postTitle,
      targetKeywordsGroups: keywords,
    });
  };

  const handleRemovedFromTargetKeywordsGroups = (kwGroup) => {
    const keywords = selectedKeywords.filter((t) => t.id !== kwGroup.id);
    setSelectedKeywords(keywords);
    handleTitleChange({
      ...postTitle,
      targetKeywordsGroups: keywords,
    });
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.leftSide}>
        <Paper elevation={0} className={classes.sectionWrapper}>
          <div className={classes.headerWrapper}>
            <TextFieldMaxLength
              value={postTitle.title}
              placeholder={I18n.t("outline.title_section_title_ph")}
              borderRadius={4}
              borderColor={"transparent"}
              height={37}
              inputClassName={classes.titleInput}
              handleValueChange={(title) =>
                handleTitleChange({
                  ...postTitle,
                  title,
                })
              }
            />
          </div>

          <div className={classes.bodyWrapper}>
            <div className={classes.row}>
              <Typography className={classes.keywordsFieldTitle} variant={"subtitle2"}>
                {I18n.t("outline.target_keyword_groups")}
              </Typography>
              <MSelectMultiple
                creatable={false}
                wrapperClassName={classes.selectMultipleWrapper}
                options={keywordsOptions}
                selectedOptions={selectedKeywords}
                optionLabel={"name"}
                optionValue={"id"}
                borderWidth={1}
                height={37}
                placeholder={I18n.t("outline.target_keyword_groups_ph")}
                handleOptionSelected={handleAddedToTargetKeywordsGroups}
                handleOptionRemoved={handleRemovedFromTargetKeywordsGroups}
              />
            </div>
            <div className={classes.row}>
              <TextFieldMaxLength
                value={postTitle.notes}
                titleAndLengthLocation={"left"}
                title={I18n.t("outline.notes")}
                placeholder={I18n.t("outline.notes_ph")}
                borderRadius={4}
                multiLine
                titleMinWidth={170}
                height={37}
                handleValueChange={(notes) =>
                  handleTitleChange({
                    ...postTitle,
                    notes,
                  })
                }
              />
            </div>
          </div>
        </Paper>
      </div>
      <div className={classes.rightSide}></div>
    </div>
  );
};

export default TitleSection;
