import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Button, Theme, Typography } from "@material-ui/core";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { I18n } from "react-redux-i18n";
import FormWidgetItems from "./FormWidgetItems";
import { IWidgetField } from "../../../../../../../reducers/constants/objectTypes";
import AddButton from "../../../components/AddButton";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({
      padding: "30px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    }),
    container: (props: PropTypes) => {
      const backgroundColor = isDark ? palette.secondary.main : palette.common.white;
      return {
        padding: "0px 15px 15px",
        display: "flex",
        flexDirection: "column",
        backgroundColor,
        borderRadius: 3,
        border: "1px solid rgb(82, 82, 82)",
      };
    },
    header: (props: PropTypes) => ({
      height: 55,
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      marginBottom: 15,
    }),
    properties: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
    leftContainer: (props: PropTypes) => {
      const color = palette.text.primary;
      return {
        display: "flex",
        alignItems: "center",
        maxWidth: 300,
        color,
        marginLeft: -8,
      };
    },
    rightContainer: (props: PropTypes) => ({
      display: "flex",
      alignItems: "center",
      maxWidth: 300,
    }),
    button: (props: PropTypes) => ({
      backgroundColor: "#4353FF",
      color: palette.common.white,
      fontSize: 14,
      textTransform: "none",
      height: 27,
      padding: "5px  10px  5px  10px",
      borderRadius: "3px",
      "&:hover": {
        color: palette.text.primary,
        backgroundColor: palette.action.selected,
      },
    }),
    label: {
      fontSize: 16,
      fontWeight: typography.fontWeightMedium,
    },
  };
});

type PropTypes = {
  fields: IWidgetField[];
  label?: string;
  onItemsReorder: (items: any[]) => void;
  onCopyClick: (field: IWidgetField) => void;
  onDeleteClick: (field: IWidgetField, index: number) => void;
  onClick: (field: IWidgetField, index: number) => void;
  onClose(event: any): void;
  onDone(event: any): void;
  onAddClick(event: any): void;
};
const FormWidgetPage = (props: PropTypes) => {
  const { fields, label, onItemsReorder, onCopyClick, onClick, onDeleteClick, onClose, onDone, onAddClick } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div onClick={onClose} className={classes.leftContainer}>
            <KeyboardArrowLeftRounded />
            <Typography className={classes.label}>{label}</Typography>
          </div>
          <div className={classes.rightContainer}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onDone(e);
              }}
              className={classes.button}
            >
              {I18n.t("widgets_builder.build.item_done_button")}
            </Button>
          </div>
        </div>
        <div className={classes.properties}>
          <FormWidgetItems
            onItemsReorder={onItemsReorder}
            onDeleteClick={onDeleteClick}
            onClick={onClick}
            onCopyClick={onCopyClick}
            fields={fields}
          />
          <AddButton onClick={onAddClick} text={I18n.t("widgets_builder.build.add_item_button")} />
        </div>
      </div>
    </div>
  );
};

export { FormWidgetPage };
