const getWidgetHTMLContainer = (containerId: string): HTMLElement | null => {
  let container = document.querySelector(`[entail-container-id="${containerId}"]`);

  if (container) {
    return container as HTMLElement;
  }

  return null;
};

const scrollToContainer = (containerId: string) => {
  const container = getWidgetHTMLContainer(containerId);
  if (container) {
    container.scrollIntoView({ behavior: "smooth", block: "center" });
    container.style.transition = "box-shadow 1s";
    container.style.boxShadow =
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px";
    setTimeout(() => {
      container.style.boxShadow = "none";
    }, 1000);
  }
};

export { getWidgetHTMLContainer, scrollToContainer };
