import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { IAutoVideoInterview } from "../../../../reducers/constants/objectTypes";
import ListItem from "@material-ui/core/ListItem";
import { default as classNames } from "classnames";
import { Tooltip, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  talkingPointCircle: {
    width: "7px",
    height: "7px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    marginRight: 15,
  },
  listRow: {
    paddingLeft: 0,
    height: 51,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      "& $openIcon": {
        visibility: "visible",
      },
      "& $rowActions": {
        display: "flex",
      },
      "& $metricOnHover": {
        display: "none",
      },
    },
  },
  listItemFirstColumn: {
    width: "35%",
    display: "flex",
    fontWeight: theme.typography.fontWeightBold as any,
    alignItems: "center",
  },
  metric: {
    fontSize: "14px",
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    width: "60%",
  },
}));

type PropTypes = {
  autoVideoInterview: null | IAutoVideoInterview;
};

const AutoVideoDialogListItem = (props: PropTypes) => {
  const classes = useStyles(props);

  const { autoVideoInterview } = props;

  return (
    <>
      {autoVideoInterview?.paragraphs?.map((paragraph, index) => (
        <ListItem key={index} className={classes.listRow}>
          <div className={classes.listItemFirstColumn}>
            <div className={classes.talkingPointCircle}></div>
            {typeof paragraph.title === "string" ? paragraph.title : "-"}
          </div>
          <div className={classNames(classes.metric)}>
            <Tooltip
              title={
                <div style={{ whiteSpace: "pre-line" }}>
                  {paragraph.targetKeywordsGroups?.length > 0 ? paragraph.targetKeywordsGroups?.join(",") : ""}
                </div>
              }
            >
              <Typography
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "pre",
                }}
              >
                {paragraph.targetKeywordsGroups?.length > 0 ? paragraph.targetKeywordsGroups?.join(",") : "-"}
              </Typography>
            </Tooltip>
          </div>
        </ListItem>
      )) || null}
    </>
  );
};

export default AutoVideoDialogListItem;
