import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import {
  IWidgetTopicsWidgetFieldProps,
  IWidgetFieldProperties,
} from "../../../../../../../reducers/constants/objectTypes";
import MNumeratedItem from "../../../../../../../components/MNumeratedItem";
import TextFieldMaxLength from "../../../../../../../components/TextFieldMaxLength";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginBottom: 10,
  },
  input: {
    border: "none",
    width: "100%",
    overflow: "hidden",
    padding: 15,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: 14,
    borderRadius: 3,
  },
}));

type PropTypes = {
  properties?: IWidgetFieldProperties;
  onChange: (properties: IWidgetTopicsWidgetFieldProps) => void;
};

const TopicsWidgetItem = (props: PropTypes) => {
  const classes = useStyles();

  const { onChange, properties } = props;
  const { list = [] } = properties as IWidgetTopicsWidgetFieldProps;

  const handleChange = ({ value, index }) => {
    onChange({
      ...properties,
      // eslint-disable-next-line no-nested-ternary
      list: value
        ? index === list.length
          ? [...list, value]
          : list.map((item, arrIndex) => (index === arrIndex ? value : item))
        : list.filter((item, arrIndex) => arrIndex !== index),
    });
  };

  return (
    <div>
      <Typography className={classes.title} variant={"subtitle2"}>
        {I18n.t("widgets_builder.widget_item_selector.topics")}
      </Typography>
      {[...list, ""].map((value, index, array) => (
        <MNumeratedItem
          key={index}
          index={index}
          showRemoveIcon={index !== array.length - 1}
          onRemove={() => handleChange({ value: "", index })}
        >
          <TextFieldMaxLength
            handleValueChange={(newValue) => handleChange({ value: newValue, index })}
            value={value}
            placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
            outlined={false}
            inputClassName={classes.input}
          />
        </MNumeratedItem>
      ))}
    </div>
  );
};

export default TopicsWidgetItem;
