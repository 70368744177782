import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import classNames from "classnames";
import numeral from "numeral";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "space-around",
    alignItems: "center",
    borderRight: `solid 1px ${theme.palette.divider}`,
    paddingTop: 20,
    "&:hover": {
      backgroundColor: "#FFFBE8",
    },
  },
  date: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    marginBottom: 20,
  },
  titles: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  range: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  red: {
    color: "#BE1D1D",
  },
  green: {
    color: "#009E10",
  },
}));

type PropTypes = {
  post: any;
  index: number;
};

const SinglePageTableRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { post, index } = props; // const total = data[dataSet]['0-3'] + data[dataSet]['4-10'] + data[dataSet]['11-20'] + data[dataSet]['21-50'] + data[dataSet]['51-100'];
  // const totalPrevious = previousData[dataSet]['0-3'] + previousData[dataSet]['4-10'] + previousData[dataSet]['11-20'] + previousData[dataSet]['21-50'] + previousData[dataSet]['51-100'];
  // const totalTrend = total / totalPrevious - 1;
  return (
    <div className={classes.wrapper}>
      <Typography variant={"subtitle2"} className={classes.date}>
        {/*{moment(data.date, 'YYYY-MM-DD').format('MMM DD')}*/}
        {post.title}
      </Typography>
      <Grid container>
        <Grid item container className={classes.titles}>
          <Grid item xs={6}>
            {"Pages"}
          </Grid>
          <Grid item xs={6}>
            {"Change"}
          </Grid>
        </Grid>
        <Grid item container className={classes.range}>
          <Grid item xs={6}>
            {numeral(123).format("0,0[.]")}
          </Grid>
          <Grid item xs={6} className={classNames(1 > 0 && classes.green, 0 < 0 && classes.red)}>
            {numeral(213).format("0 %")}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SinglePageTableRow;
