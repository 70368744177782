import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import FetchKewordItem from "./FetchKewordItem";
import { FETCH_KEYWORDS } from "../../reducers/constants/actionTypes";
import agent from "../../agent";
import MTable from "../../components/Table/Table/MTable";
import MTableRowProgressBar from "../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../components/Table/Rows/MTableRowEmptyRow";
import MTableHeader from "../../components/Table/Table/MTableHeader";
import MTableHeaderLabelWithSelectAll from "../../components/Table/Table/MTableHeaderLabelWithSelectAll";
import MTableHeaderLabel from "../../components/Table/Table/MTableHeaderLabel";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "calc(100vw - 40px)",
    // overflowY: "auto",
    display: "flex",
  },
  content: {
    flex: 1,
    maxWidth: "calc(100vw - 40px)",
    height: "100%",
    position: "relative",
  },
  columns: {
    display: "flex",
    flex: 1,
    backgroundColor: "white",
    padding: "30px 20px",
    marginRight: 5,
    top: 62,
    position: "sticky",
    zIndex: 2,
    height: 80,
    alignItems: "center",
    boxSixing: "border-box",
  },
  firstColumn: {
    display: "flex",
    gap: 15,
    alignItems: "center",
    color: theme.palette.text.primary,
    width: "calc(25.3% + 25px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  firstColumnText: {
    fontSize: theme.typography.pxToRem(21),
  },
  column: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    flexGrow: 1,
    flexBasis: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  lastColumn: {
    flexGrow: 2,
  },
  loadingIconWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  firstRow: {
    borderTop: "1px solid transparent",
  },
  lastRow: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  pipeIcon: {
    height: 19,
    marginRight: 12,
    fill: "red",
  },
  firstColumnWhenSelectedItems: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight as any,
    display: "flex",
    alignItems: "center",
    gap: 10,
    width: "calc(25.3% + 25px)",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cancelBulkIcon: {
    cursor: "pointer",
    strokeWidth: 1,
    fontWeight: theme.typography.fontWeightLight as any,
    marginRight: 12,
    height: 15,
  },
  iconWrapper: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    minWidth: "unset",
    marginRight: 15,
  },
  checkIconWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  icon: {
    width: 25,
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  checkIcon: {
    fill: theme.palette.primary.contrastText,
  },
  noContent: {
    padding: "30px 20px",
  },
}));

type PropTypes = {
  loaded: boolean;
  seedsList: any[];
  fetchSeed: (id) => void;
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  setSortField?: (input: { field: string; direction: "asc" | "desc" }) => void;
  onSeedChanged: (seed) => void;
  handleCheckedBoxChanged: (checkedSeedsList) => void;
  checkedSeedsList: any[];
  onSeedsRemoved: (seeds) => void;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
  checkedSeedsList: state.fetchKeywords.checkedSeedsList,
});

const mapDispatchToProps = (dispatch) => ({
  handleCheckedBoxChanged: (checkedSeedsList) => {
    dispatch({ type: FETCH_KEYWORDS.ON_CHECKED_BOX_CHANGED, checkedSeedsList });
  },
  onSeedChanged: (seed) => {
    dispatch({ type: FETCH_KEYWORDS.ON_SEED_CHANGED, seed });
  },
  onSeedsRemoved: (seeds) => {
    dispatch({ type: FETCH_KEYWORDS.ON_SEEDS_REMOVED, seeds });
  },
});

const FetchKeywordsList = (props: PropTypes) => {
  const {
    seedsList,
    setSortField,
    checkedSeedsList,
    onSeedsRemoved,
    loaded,
    sortField,
    fetchSeed,
    handleCheckedBoxChanged,
  } = props;
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = (seed) => {
    onSeedsRemoved([seed]);
    agent.Seeds.deleteSeeds([seed._id]);
    enqueueSnackbar(I18n.t("fetch_keywords.deleted"));
  };

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabelWithSelectAll
          selected={checkedSeedsList?.length > 0}
          columnWidth={"33%"}
          onSelectAll={() => handleCheckedBoxChanged(seedsList)}
          onClearAll={() => {
            handleCheckedBoxChanged([]);
          }}
        >
          {I18n.t("fetch_keywords.seed")}
        </MTableHeaderLabelWithSelectAll>
        <MTableHeaderLabel> {I18n.t("fetch_keywords.source")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("fetch_keywords.type")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("fetch_keywords.fetch_kws")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("fetch_keywords.last_fetch")} </MTableHeaderLabel>
        <MTableHeaderLabel>{""} </MTableHeaderLabel>
      </MTableHeader>

      {!loaded && <MTableRowProgressBar />}

      {loaded && seedsList?.length < 1 && <MTableRowEmptyRow />}
      {loaded &&
        seedsList?.map((item, index) => (
          <FetchKewordItem
            deleteSeed={handleDelete}
            fetchSeed={fetchSeed}
            selected={checkedSeedsList.find((s) => s._id === item._id)}
            setSelected={(selected) => {
              if (selected) {
                handleCheckedBoxChanged([...checkedSeedsList, item]);
              } else {
                handleCheckedBoxChanged(checkedSeedsList.filter((s) => s._id !== item._id));
              }
            }}
            isFirstRow={index === 0}
            isLastRow={index === seedsList.length - 1}
            seed={item}
            key={index}
          />
        ))}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FetchKeywordsList);
