import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import { useState } from "react";
import { ITag, IWebsite } from "../reducers/constants/objectTypes";
import MSelect from "./MSelect";
import MTag from "./MTag";
import agent from "../agent";

const useStyles = makeStyles((theme: Theme) => ({
  postTagsWrapper: {
    width: "100%",
    marginBottom: 20,
  },
  tagsWrapper: {
    borderTop: `solid 1px ${theme.palette.divider}`,
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: 10,
    "& > *": {
      marginTop: 10,
    },
  },
  selectTagsWrapper: {},
  suggestedTagsWrapper: {},
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  postTags: ITag[] | string[]; // remove the string[]
  suggestedTags?: string[];
  placeholder?: string;
  tags: ITag[] | string[]; // remove the string[]
  customClass?: string;
  height?: number;
  onTagSelected: Function;
  onTagRemoved: Function;
  onTagCreated?: Function;
};

const MPanelTags = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    postTags = [],
    customClass = null,
    suggestedTags,
    placeholder = null,
    tags,
    height = 30,
    selectedWebsite,
  } = props;
  const { onTagSelected, onTagRemoved, onTagCreated = null } = props;
  const theme = useTheme<Theme>();

  const [searchField, setSearchField] = useState("");
  const creatableTags =
    onTagCreated && searchField.length > 0
      ? [...tags, { _id: "new_tag", name: `create: '${searchField}'`, tagName: searchField }]
      : tags;

  const createNewTag = (name) => {
    agent.Tags.new(selectedWebsite._id, name)
      .then((res) => {
        onTagCreated(res.tag);
      })
      .catch((e) => {});
  };

  return (
    <div className={ClassNames(classes.postTagsWrapper, customClass)}>
      {/* <Typography variant={'body2'}>{I18n.t('upload_post.tags_side_bar.tags')}</Typography> */}
      <div className={classes.selectTagsWrapper}>
        <MSelect
          options={creatableTags}
          value={null}
          optionValue={"_id"}
          optionLabel={"name"}
          borderColor={theme.palette.divider}
          borderRadius={0}
          borderWidth={"0px"}
          height={height}
          placeholder={placeholder || I18n.t("upload_post.tags_side_bar.placeholder")}
          searchable
          handleInputChange={(input) => setSearchField(input)}
          handleChange={(tag) => {
            if (tag._id === "new_tag") {
              createNewTag(tag.tagName);
              return;
            }

            onTagSelected(tag);
          }}
        />
        {postTags && postTags.length > 0 && (
          <div className={classes.tagsWrapper}>
            {postTags.map((tag) => (
              <MTag key={tag._id} tag={tag.name} onRemoveClicked={(e) => onTagRemoved(tag)} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(MPanelTags);
