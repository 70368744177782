import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import PageSection from "../../components/PageSection";
import { IWebsiteHomepageProps, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import TextInputMentions from "../../components/TextInputMentions";
import { LENGTHS } from "../../../../reducers/constants/consts";
import ButtonTextAndUrlAndDesign from "../../components/ButtonTextAndUrlAndDesign";
import PostsIds from "../../components/PostsIds";
import Bullets from "../../components/Bullets";
import TextInputToolAndDesign from "../../components/TextInputToolAndDesign";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  idsColumns: {
    display: "flex",
    justifyContent: "space-between",
  },
  ids: {
    width: 300,
    marginBottom: 40,
  },
}));

type PropTypes = {
  theme: IWebsiteThemeProps;
  homepage: IWebsiteHomepageProps;

  onPropertyChanged: (value) => void;
};

const HomepageDefault = (props: PropTypes) => {
  const classes = useStyles(props);
  const { homepage, theme, onPropertyChanged } = props;
  const [topSectionError, setTopSectionError] = React.useState([]);
  const [comparisonError, setComparisonError] = React.useState([]);
  const [ourGoalError, setOurGoalError] = React.useState([]);
  const [featuredPostsError, setFeaturedPostsError] = React.useState([]);
  const [reviewPostsError, setReviewPostsError] = React.useState([]);
  const [targetedPostsError, setTargetedPostsError] = React.useState([]);

  React.useEffect(() => {
    if (topSectionError.length <= 0) {
      return;
    }
    topSectionValidated();
  }, [homepage.topSection, homepage.metaTitle, homepage.metaDescription]);
  React.useEffect(() => {
    if (comparisonError.length <= 0) {
      return;
    }
    comparisonSectionValidated();
  }, [homepage.comparisonSection]);
  React.useEffect(() => {
    if (ourGoalError.length <= 0) {
      return;
    }
    ourGoalSectionValidated();
  }, [homepage.ourGoalSection]);
  React.useEffect(() => {
    if (featuredPostsError.length <= 0) {
      return;
    }
    featuredPostsSectionValidated();
  }, [homepage.featuredPostsSection]);
  React.useEffect(() => {
    if (reviewPostsError.length <= 0) {
      return;
    }
    reviewPostsSectionValidated();
  }, [homepage.reviewsSection]);

  React.useEffect(() => {
    if (targetedPostsError.length <= 0) {
      return;
    }
    targetedPostsSectionValidated();
  }, [homepage.targetedPostsSection]);

  const topSectionValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!homepage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_title`));
    }
    if (!homepage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_description`));
    }
    if (!homepage.topSection.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.top_section_title`));
    }
    if (!homepage.topSection.description.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.top_section_description`));
    }
    if (!homepage.topSection.top10Title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.top_10_title`));
    }
    setTopSectionError(emptyFields);

    return valid;
  };
  const comparisonSectionValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!homepage.comparisonSection.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.comparison_title`));
    }
    if (!homepage.comparisonSection.description.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.comparison_description`));
    }
    setComparisonError(emptyFields);
    return valid;
  };
  const ourGoalSectionValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!homepage.ourGoalSection.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.our_goal_title`));
    }
    if (!homepage.ourGoalSection.description.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.our_goal_description`));
    }
    if (!homepage.ourGoalSection.findMoreDetailsEditorialPolicy.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.our_goal_editorial_policy_text`));
    }
    setOurGoalError(emptyFields);
    return valid;
  };
  const featuredPostsSectionValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!homepage.featuredPostsSection.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.featured_posts_title`));
    }
    setFeaturedPostsError(emptyFields);
    return valid;
  };

  const reviewPostsSectionValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!homepage.reviewsSection.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.reviews_title`));
    }
    setReviewPostsError(emptyFields);
    return valid;
  };
  const targetedPostsSectionValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!homepage.targetedPostsSection.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.targeted_posts_title`));
    }
    setTargetedPostsError(emptyFields);
    return valid;
  };
  return (
    <>
      <PageSection
        sectionTitle={I18n.t("website.homepage.top_section")}
        checked={homepage.topSection.enabled}
        onCheckedChange={(checked) => {
          if (!topSectionValidated()) {
            if (!checked && homepage.topSection.enabled) {
              onPropertyChanged({
                ...homepage,
                topSection: { ...homepage.topSection, enabled: checked },
              });
            }
            return;
          }
          onPropertyChanged({
            ...homepage,
            topSection: { ...homepage.topSection, enabled: checked },
          });
        }}
      >
        {topSectionError.length > 0 && (
          <div className={classes.errorWrapper}>
            <Typography variant={"body1"} className={classes.error}>
              {topSectionError.length > 0 && I18n.t("website.error_message")}
            </Typography>
          </div>
        )}
        <TextInputToolAndDesign
          error={topSectionError.includes(I18n.t(`website.homepage.top_section_title`))}
          textFieldTitle={I18n.t("website.homepage.title")}
          text={homepage.topSection?.title}
          handleTextChanged={(text) =>
            onPropertyChanged({ ...homepage, topSection: { ...homepage.topSection, title: text } })
          }
          variant={"h1"}
        />

        <TextInputMentions
          error={topSectionError.includes(I18n.t(`website.meta_title`))}
          tags={[]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={homepage.metaTitle}
          onTextChanged={(value) => onPropertyChanged({ ...homepage, metaTitle: value })}
        />
        <Divider className={classes.divider} />

        <TextInputToolAndDesign
          error={topSectionError.includes(I18n.t(`website.homepage.top_section_description`))}
          textFieldTitle={I18n.t("website.homepage.description")}
          text={homepage.topSection?.description}
          handleTextChanged={(text) =>
            onPropertyChanged({
              ...homepage,
              topSection: { ...homepage.topSection, description: text },
            })
          }
          variant={"h2"}
        />

        <TextInputMentions
          error={topSectionError.includes(I18n.t(`website.meta_description`))}
          tags={[]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={homepage.metaDescription}
          onTextChanged={(value) => onPropertyChanged({ ...homepage, metaDescription: value })}
        />
        <Divider className={classes.divider} />
        <ButtonTextAndUrlAndDesign
          textFieldTitle={I18n.t("website.button_text")}
          urlFieldTitle={I18n.t("website.button_url")}
          button={homepage.topSection?.button}
          defaultButtonCss={theme.buttons.css}
          handleButtonChanged={(newButton) =>
            onPropertyChanged({
              ...homepage,
              topSection: { ...homepage.topSection, button: newButton },
            })
          }
        />
        <Divider className={classes.divider} />

        <TextInputToolAndDesign
          error={topSectionError.includes(I18n.t(`website.homepage.top_10_title`))}
          textFieldTitle={I18n.t("website.homepage.top_10_title")}
          text={homepage.topSection?.top10Title}
          handleTextChanged={(text) =>
            onPropertyChanged({
              ...homepage,
              topSection: { ...homepage.topSection, top10Title: text },
            })
          }
          variant={"h4"}
        />
      </PageSection>

      <PageSection
        sectionTitle={I18n.t("website.homepage.comparison_section")}
        checked={homepage.comparisonSection.enabled}
        onCheckedChange={(checked) => {
          if (!comparisonSectionValidated()) {
            if (!checked && homepage.comparisonSection.enabled) {
              onPropertyChanged({
                ...homepage,
                comparisonSection: { ...homepage.comparisonSection, enabled: checked },
              });
            }
            return;
          }
          onPropertyChanged({
            ...homepage,
            comparisonSection: { ...homepage.comparisonSection, enabled: checked },
          });
        }}
      >
        {comparisonError.length > 0 && (
          <div className={classes.errorWrapper}>
            <Typography variant={"body1"} className={classes.error}>
              {comparisonError.length > 0 && I18n.t("website.error_message")}
            </Typography>
          </div>
        )}
        <TextInputToolAndDesign
          error={comparisonError.includes(I18n.t(`website.homepage.comparison_title`))}
          textFieldTitle={I18n.t("website.homepage.comparison_title")}
          text={homepage.comparisonSection?.title}
          handleTextChanged={(text) =>
            onPropertyChanged({
              ...homepage,
              comparisonSection: { ...homepage.comparisonSection, title: text },
            })
          }
          variant={"h4"}
        />

        <TextInputToolAndDesign
          error={comparisonError.includes(I18n.t(`website.homepage.comparison_description`))}
          textFieldTitle={I18n.t("website.homepage.comparison_description")}
          text={homepage.comparisonSection?.description}
          handleTextChanged={(text) =>
            onPropertyChanged({
              ...homepage,
              comparisonSection: { ...homepage.comparisonSection, description: text },
            })
          }
          variant={"h6"}
        />
        <Divider className={classes.divider} />

        <PostsIds
          title={I18n.t("website.homepage.postsIds")}
          postsIdsNumber={3}
          postsIds={homepage.comparisonSection?.comparisonIds}
          handleChange={(postsIds) =>
            onPropertyChanged({
              ...homepage,
              comparisonSection: { ...homepage.comparisonSection, comparisonIds: postsIds },
            })
          }
        />
      </PageSection>

      <PageSection
        sectionTitle={I18n.t("website.homepage.our_goal_section")}
        checked={homepage.ourGoalSection.enabled}
        onCheckedChange={(checked) => {
          if (!ourGoalSectionValidated()) {
            if (!checked && homepage.ourGoalSection.enabled) {
              onPropertyChanged({
                ...homepage,
                ourGoalSection: { ...homepage.ourGoalSection, enabled: checked },
              });
            }
            return;
          }
          onPropertyChanged({
            ...homepage,
            ourGoalSection: { ...homepage.ourGoalSection, enabled: checked },
          });
        }}
      >
        {ourGoalError.length > 0 && (
          <div className={classes.errorWrapper}>
            <Typography variant={"body1"} className={classes.error}>
              {ourGoalError.length > 0 && I18n.t("website.error_message")}
            </Typography>
          </div>
        )}
        <TextInputToolAndDesign
          error={ourGoalError.includes(I18n.t(`website.homepage.our_goal_title`))}
          textFieldTitle={I18n.t("website.homepage.our_goal_title")}
          text={homepage.ourGoalSection?.title}
          handleTextChanged={(text) =>
            onPropertyChanged({
              ...homepage,
              ourGoalSection: { ...homepage.ourGoalSection, title: text },
            })
          }
          variant={"h4"}
        />
        <TextInputToolAndDesign
          error={ourGoalError.includes(I18n.t(`website.homepage.our_goal_description`))}
          textFieldTitle={I18n.t("website.homepage.our_goal_description")}
          text={homepage.ourGoalSection?.description}
          handleTextChanged={(text) =>
            onPropertyChanged({
              ...homepage,
              ourGoalSection: { ...homepage.ourGoalSection, description: text },
            })
          }
          variant={"h6"}
        />
        <Divider className={classes.divider} />

        <Bullets
          title={I18n.t("website.homepage.bullets")}
          bulletsNumber={4}
          bullets={homepage.ourGoalSection?.bullets}
          handleChange={(bullets) =>
            onPropertyChanged({
              ...homepage,
              ourGoalSection: { ...homepage.ourGoalSection, bullets },
            })
          }
        />

        <TextFieldMaxLength
          error={ourGoalError.includes(I18n.t(`website.homepage.our_goal_editorial_policy_text`))}
          titleAndLengthLocation={"top"}
          value={homepage.ourGoalSection?.findMoreDetailsEditorialPolicy.text}
          placeholder={""}
          title={I18n.t("website.homepage.our_goal_editorial_policy_text")}
          height={37}
          borderRadius={4}
          marginBottom={30}
          handleValueChange={(value) =>
            onPropertyChanged({
              ...homepage,
              ourGoalSection: {
                ...homepage.ourGoalSection,
                findMoreDetailsEditorialPolicy: {
                  ...homepage.ourGoalSection.findMoreDetailsEditorialPolicy,
                  text: value,
                },
              },
            })
          }
        />
      </PageSection>

      <PageSection
        sectionTitle={I18n.t("website.homepage.featured_posts_section")}
        checked={homepage.featuredPostsSection.enabled}
        onCheckedChange={(checked) => {
          if (!featuredPostsSectionValidated()) {
            if (!checked && homepage.featuredPostsSection.enabled) {
              onPropertyChanged({
                ...homepage,
                featuredPostsSection: { ...homepage.featuredPostsSection, enabled: checked },
              });
            }
            return;
          }
          onPropertyChanged({
            ...homepage,
            featuredPostsSection: { ...homepage.featuredPostsSection, enabled: checked },
          });
        }}
      >
        {featuredPostsError.length > 0 && (
          <div className={classes.errorWrapper}>
            <Typography variant={"body1"} className={classes.error}>
              {featuredPostsError.length > 0 && I18n.t("website.error_message")}
            </Typography>
          </div>
        )}
        <TextInputToolAndDesign
          error={featuredPostsError.includes(I18n.t(`website.homepage.featured_posts_title`))}
          textFieldTitle={I18n.t("website.homepage.featured_posts_title")}
          text={homepage.featuredPostsSection?.title}
          handleTextChanged={(text) =>
            onPropertyChanged({
              ...homepage,
              featuredPostsSection: { ...homepage.featuredPostsSection, title: text },
            })
          }
          variant={"h4"}
        />
        <Divider className={classes.divider} />
        <PostsIds
          title={I18n.t("website.homepage.postsIds")}
          postsIdsNumber={3}
          postsIds={homepage.featuredPostsSection?.featuredPostsIds}
          handleChange={(postsIds) =>
            onPropertyChanged({
              ...homepage,
              featuredPostsSection: {
                ...homepage.featuredPostsSection,
                featuredPostsIds: postsIds,
              },
            })
          }
        />
      </PageSection>

      <PageSection
        sectionTitle={I18n.t("website.homepage.reviews_section")}
        checked={homepage.reviewsSection.enabled}
        onCheckedChange={(checked) => {
          if (!reviewPostsSectionValidated()) {
            if (!checked && homepage.reviewsSection.enabled) {
              onPropertyChanged({
                ...homepage,
                reviewsSection: { ...homepage.reviewsSection, enabled: checked },
              });
            }
            return;
          }
          onPropertyChanged({
            ...homepage,
            reviewsSection: { ...homepage.reviewsSection, enabled: checked },
          });
        }}
      >
        {reviewPostsError.length > 0 && (
          <div className={classes.errorWrapper}>
            <Typography variant={"body1"} className={classes.error}>
              {reviewPostsError.length > 0 && I18n.t("website.error_message")}
            </Typography>
          </div>
        )}
        <TextInputToolAndDesign
          error={reviewPostsError.includes(I18n.t(`website.homepage.reviews_title`))}
          textFieldTitle={I18n.t("website.homepage.reviews_title")}
          text={homepage.reviewsSection?.title}
          handleTextChanged={(text) =>
            onPropertyChanged({
              ...homepage,
              reviewsSection: { ...homepage.reviewsSection, title: text },
            })
          }
          variant={"h4"}
        />
        <Divider className={classes.divider} />
        <PostsIds
          title={I18n.t("website.homepage.postsIds")}
          postsIdsNumber={3}
          postsIds={homepage.reviewsSection?.productReviewsIds}
          handleChange={(postsIds) =>
            onPropertyChanged({
              ...homepage,
              reviewsSection: { ...homepage.reviewsSection, productReviewsIds: postsIds },
            })
          }
        />
      </PageSection>

      <PageSection
        sectionTitle={I18n.t("website.homepage.targeted_posts_section")}
        checked={homepage.targetedPostsSection.enabled}
        onCheckedChange={(checked) => {
          if (!targetedPostsSectionValidated()) {
            if (!checked && homepage.targetedPostsSection.enabled) {
              onPropertyChanged({
                ...homepage,
                targetedPostsSection: { ...homepage.targetedPostsSection, enabled: checked },
              });
            }
            return;
          }
          onPropertyChanged({
            ...homepage,
            targetedPostsSection: { ...homepage.targetedPostsSection, enabled: checked },
          });
        }}
      >
        {targetedPostsError.length > 0 && (
          <div className={classes.errorWrapper}>
            <Typography variant={"body1"} className={classes.error}>
              {targetedPostsError.length > 0 && I18n.t("website.error_message")}
            </Typography>
          </div>
        )}
        <TextInputToolAndDesign
          error={targetedPostsError.includes(I18n.t(`website.homepage.targeted_posts_title`))}
          textFieldTitle={I18n.t("website.homepage.targeted_posts_title")}
          text={homepage.targetedPostsSection?.title}
          handleTextChanged={(text) =>
            onPropertyChanged({
              ...homepage,
              targetedPostsSection: { ...homepage.targetedPostsSection, title: text },
            })
          }
          variant={"h4"}
        />
        <Divider className={classes.divider} />
        <PostsIds
          title={I18n.t("website.homepage.postsIds")}
          postsIdsNumber={3}
          postsIds={homepage.targetedPostsSection?.targetedPostsIds}
          handleChange={(postsIds) =>
            onPropertyChanged({
              ...homepage,
              targetedPostsSection: {
                ...homepage.targetedPostsSection,
                targetedPostsIds: postsIds,
              },
            })
          }
        />
      </PageSection>

      <PageSection sectionTitle={I18n.t("website.blog_posts_ids")}>
        <div className={classes.idsColumns}>
          <div className={classes.ids}>
            <PostsIds
              title={I18n.t("website.blog_top_section")}
              postsIdsNumber={4}
              postsIds={homepage.blogTopPostsIds}
              handleChange={(postsIds) => onPropertyChanged({ ...homepage, blogTopPostsIds: postsIds })}
            />
          </div>

          <div className={classes.ids}>
            <PostsIds
              title={I18n.t("website.blog_bottom_section")}
              postsIdsNumber={4}
              postsIds={homepage.blogBottomPostsIds}
              handleChange={(postsIds) => onPropertyChanged({ ...homepage, blogBottomPostsIds: postsIds })}
            />
          </div>
        </div>
      </PageSection>
    </>
  );
};

export default HomepageDefault;
