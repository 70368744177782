import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import * as React from "react";
import { orderBy } from "lodash";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import MTable from "../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import { CONVERSION_POTENTIAL_FUNNEL_STAGE } from "../../../../helpers/constants";
import { getWebsiteRelativeUrl } from "../../../../helpers/urlHelper";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    padding: 0,
    margin: 0,
  },
  firstColumn: {
    transition: "all 0.3s",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
}));

type Props = {
  data: any[];
  reportType?: string;
  selectedWebsite: IWebsite;
};

const ReportsTable = (props: Props) => {
  const { data, reportType, selectedWebsite } = props;
  const classes = useStyles();
  const [sortField, setSortField] = React.useState<{ direction: "desc" | "asc" | undefined; field: string }>(null);
  const [sortedData, setSortedData] = React.useState<any[]>([...data]);

  React.useEffect(() => {
    if (sortField) {
      setSortedData(orderBy(data, sortField.field, sortField.direction));
    }
  }, [sortField, data]);
  const getItemRow = (item: any, index: number) => {
    switch (reportType) {
      case "top-pages":
        return (
          <MTableRow handleRowClick={() => console.log("click")} key={index} fullBorderWidth>
            <MTableCellFirst
              wrapperClassName={classes.firstColumn}
              onClick={() => window.open(item.page, "_blank")}
              cellText={getWebsiteRelativeUrl(item.page, selectedWebsite)}
              columnWidth={"50%"}
              toolTipProps={{ enterDelay: 300 }}
              actions={[]}
              fontSize={14}
            />
            <MTableCellNumber value={item.total_impressions} type={"bigNumber"} />
            <MTableCellNumber value={item.total_clicks} type={"bigNumber"} />
            <MTableCellNumber value={item.ctr} type={"percentage"} />
            <MTableCellNumber value={item.position} />
          </MTableRow>
        );
      case "top-topics":
        return (
          <MTableRow handleRowClick={() => console.log("click")} key={index} fullBorderWidth>
            <MTableCellFirst
              cellText={item.keyword}
              columnWidth={"50%"}
              toolTipProps={{ enterDelay: 300 }}
              actions={[]}
              fontSize={14}
            />
            <MTableCellNumber value={item.impressions} type={"bigNumber"} />
            <MTableCellNumber value={item.clicks} type={"bigNumber"} />
            <MTableCellNumber value={item.ctr} type={"percentage"} />
            <MTableCellNumber value={item.position} />
            <MTableCellText text={CONVERSION_POTENTIAL_FUNNEL_STAGE[item.conversion_potential]} />
          </MTableRow>
        );
      case "top-queries":
        return (
          <MTableRow handleRowClick={() => console.log("click")} key={index} fullBorderWidth>
            <MTableCellFirst
              cellText={item.keyword}
              columnWidth={"50%"}
              toolTipProps={{ enterDelay: 300 }}
              actions={[]}
              fontSize={14}
            />

            <MTableCellNumber value={item.total_impressions} type={"bigNumber"} />
            <MTableCellNumber value={item.total_clicks} type={"bigNumber"} />
            <MTableCellNumber value={item.ctr} type={"percentage"} />
            <MTableCellNumber value={item.position} />
          </MTableRow>
        );
      default:
        return null;
    }
  };
  const getHeaders = () => {
    switch (reportType) {
      case "top-pages":
        return (
          <MTableHeader top={0}>
            <MTableHeaderSortLabel
              columnWidth={"50%"}
              field={"page"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
            >
              {I18n.t("reports.column_names.page")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"total_impressions"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.impressions")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"total_clicks"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.clicks")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"ctr"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.ctr")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"position"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.position")}
            </MTableHeaderSortLabel>
          </MTableHeader>
        );
      case "top-topics":
        return (
          <MTableHeader top={0}>
            <MTableHeaderSortLabel
              columnWidth={"50%"}
              field={"keyword"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
            >
              {I18n.t("reports.column_names.topic")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"impressions"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.impressions")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"clicks"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.clicks")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"ctr"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.ctr")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"position"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.position")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"conversion_potential"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.funnel_stage")}
            </MTableHeaderSortLabel>
          </MTableHeader>
        );
      case "top-queries":
        return (
          <MTableHeader top={0}>
            <MTableHeaderSortLabel
              columnWidth={"50%"}
              field={"keyword"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
            >
              {I18n.t("reports.column_names.keyword")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"total_impressions"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.impressions")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"total_clicks"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.clicks")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"ctr"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.ctr")}
            </MTableHeaderSortLabel>
            <MTableHeaderSortLabel
              field={"position"}
              sortField={sortField}
              onClick={(sort) => setSortField(sort)}
              fontSize={14}
            >
              {I18n.t("reports.column_names.position")}
            </MTableHeaderSortLabel>
          </MTableHeader>
        );
      default:
        return null;
    }
  };

  return (
    <MTableWrapper className={classes.tableWrapper}>
      <MTable>
        {sortedData.length > 0 && getHeaders()}

        {sortedData.length > 0 && sortedData.map((item, index) => getItemRow(item, index))}
      </MTable>
    </MTableWrapper>
  );
};

export default ReportsTable;
