import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";

import { eWidgetType, IWidget } from "../../../../../../../reducers/constants/objectTypes";
import CustomCssSettings from "./CustomCssSettings";
import DisplayDevicesSettings from "./DialogWidget/DisplayDevicesSettings";
import FrequencySettings from "./DialogWidget/FrequencySettings";
import PagesSettings from "./common/PagesSettings";
import TriggerSettings from "./DialogWidget/TriggerSettings";
import DialogLayoutSettings from "./DialogWidget/LayoutSettings/LayoutSettings";
import InlineLayoutSettings from "./InlineWidget/LayoutSettings";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 25,
  },
  header: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
  },
  backContainer: {
    position: "absolute",
    left: 0,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  backLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: palette.primary.light,
    marginLeft: 0,
  },
  title: {
    fontSize: 14,
    fontWeight: typography.fontWeightMedium as any,
    color: palette.text.primary,
  },
  leftArrowIcon: {
    color: palette.primary.light,
    marginLeft: -8,
  },
}));

type PropTypes = {
  widget: IWidget;
  selectedOption: {
    label: string;
    type: string;
  };
  back: () => void;
};

const SelectedSettingsOption = (props: PropTypes) => {
  const { selectedOption, widget } = props;
  const { back } = props;

  const classes = useStyles(props);

  const settingsOptions = React.useMemo(
    () => ({
      [eWidgetType.DIALOG]: {
        triggers: <TriggerSettings />,
        layout: <DialogLayoutSettings />,
        frequency: <FrequencySettings />,
        pages: <PagesSettings />,
        devices: <DisplayDevicesSettings />,
        custom_css: <CustomCssSettings />,
      },
      [eWidgetType.INLINE]: {
        layout: <InlineLayoutSettings />,
        custom_css: <CustomCssSettings />,
      },
    }),
    []
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.backContainer} onClick={() => back()}>
          <KeyboardArrowLeftRounded className={classes.leftArrowIcon} />
          <Typography className={classes.backLabel}>Back</Typography>
        </div>
        <Typography className={classes.title}>{selectedOption.label}</Typography>
      </div>
      {settingsOptions[widget.type][selectedOption.type]}
    </div>
  );
};

export default SelectedSettingsOption;
