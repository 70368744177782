import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import * as React from "react";
import GoalCompletionReportTypeFilter from "../../components/filters/GoalCompletionReportTypeFilter";
import { IFunnelBreakdownColumn, IWebsite } from "../../../../../reducers/constants/objectTypes";
import MTableToolbarDbBreakdownFilter from "../../../../../components/Table/Toolbar/Filters/MTableToolbarDbBreakdownFilter";
import MTableToolbarTimePeriodFilter from "../../../../../components/Table/Toolbar/Filters/MTableToolbarTimePeriodFilter";
import MTableToolbarTimeFrameFilter from "../../../../../components/Table/Toolbar/Filters/MTableToolbarTimeFrameFilter";

const useStyles = makeStyles((theme: Theme) => ({
  innerWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 65px)",
    marginBottom: 20,
  },
  reportsDashboardWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    margin: 20,
    position: "relative",
    height: "100%",
  },
  reportsHeader: {
    padding: 15,
    display: "flex",
    justifyContent: "space-between",
  },
  headerReportPicker: {
    display: "flex",
    gap: 15,
    alignItems: "center",
  },
  headerReportTimeWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  dropdownWrapper: {
    padding: "3px 10px",
  },
}));

type Props = {
  selectedWebsite: IWebsite;
  selectedReport: string;
  setSelectedReport: (selectedReport: string) => void;
  breakdownFilters: {
    value: any;
    filter: IFunnelBreakdownColumn;
  }[];
  handleBreakdownFiltersChange: (
    breakdownFilters: {
      value: any;
      filter: IFunnelBreakdownColumn;
    }[]
  ) => void;
  selectedTimePeriod: any;
  setSelectedTimePeriod: (selectedTimePeriod: any) => void;
  selectedTimeFrame: any;
  setSelectedTimeFrame: (selectedTimeFrame: any) => void;
};

const GoalCompletionByPageReportHeader = (props: Props) => {
  const classes = useStyles();

  const {
    selectedWebsite,
    selectedReport,
    setSelectedReport,
    breakdownFilters,
    handleBreakdownFiltersChange,
    selectedTimePeriod,
    setSelectedTimePeriod,
    selectedTimeFrame,
    setSelectedTimeFrame,
  } = props;

  console.log("selectedTimePeriod", selectedTimePeriod);
  console.log("selectedTimeFrame", selectedTimeFrame);

  return (
    <div className={classes.reportsHeader}>
      <div className={classes.headerReportPicker}>
        <GoalCompletionReportTypeFilter value={selectedReport} handleOnChange={setSelectedReport} />
        {breakdownFilters.map((breakdownFilter) => (
          <MTableToolbarDbBreakdownFilter
            filter={breakdownFilter.filter}
            columnValue={breakdownFilter.value}
            placeholder={`${breakdownFilter.filter.label}...`}
            website={selectedWebsite}
            handleChange={(filter: IFunnelBreakdownColumn, columnValue: any) => {
              handleBreakdownFiltersChange(
                breakdownFilters.map((bf) => {
                  if (bf.filter.label === filter.label) {
                    return { ...bf, value: columnValue };
                  }
                  return bf;
                })
              );
            }}
          />
        ))}

        {/*<EventFilter selectedWebsite={selectedWebsite} handleChange={setSelectedEvent} value={selectedEvent} />*/}
        {/*<TotalUniqueFilter value={selectedCountFilter} handleOnChange={setSelectedCountFilter} />*/}
      </div>
      <div className={classes.headerReportTimeWrapper}>
        <MTableToolbarTimePeriodFilter value={selectedTimePeriod} handleOnChange={setSelectedTimePeriod} />
        <MTableToolbarTimeFrameFilter value={selectedTimeFrame} handleOnChange={setSelectedTimeFrame} />
      </div>
    </div>
  );
};

export default GoalCompletionByPageReportHeader;
