import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 14,
    height: 14,
    fill: "#F5F6F8",
    stroke: "#4353FF",
  },
}));

type PropTypes = {
  className?: string;
};

const StrokeAllSidesIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 14 14"}>
      <rect x="0.5" y="0.5" width="13" height="13" rx="0.5" fill="#F5F6F8" stroke="#4353FF" />
    </SvgIcon>
  );
};

export default StrokeAllSidesIcon;
