import React from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { cloneDeep, isEqual } from "lodash";
import { I18n } from "react-redux-i18n";

import { ICampaign } from "../../../../../../../../reducers/constants/objectTypes";
import { WIDGET_RULE_EDITOR } from "../../../../../../../../reducers/constants/actionTypes";
import RuleBuilderHeader from "./RuleBuilderHeader";
import MDropdown from "../../../../../../../../components/MDropdown";
import MTextFieldV2 from "../../../../../../../../components/MTextFieldV2";
import MSelect from "../../../../../../../../componentsV2/MSelect";
import TrashCanIcon from "../../../../../../../../icons/TrashCanIcon";
import {
  WidgetRuleEditorRootState,
  WidgetRuleEditorSelectedView,
} from "../../../../../../../../reducers/widgetRuleEditorReducer";

const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "normal",
  },
  itemsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  conditionItem: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
    "&:hover": {
      "& $trashCanIcon": {
        display: "block",
      },
    },
  },
  textField: {
    "& .MuiInputBase-multiline": {
      padding: 0,
    },
  },
  trashCanIcon: {
    fontSize: 18,
    marginRight: 13,
    display: "none",
    cursor: "pointer",
    opacity: 0.4,
    "&:hover": {
      opacity: 1,
    },
  },
  mDropdownPlaceholder: {
    color: theme.palette.text.secondary,
  },
  mDropdownIcon: {
    color: theme.palette.text.secondary,
  },
  operatorDropdown: {
    marginBottom: 20,
  },
}));

const mapStateToProps = (state) => ({
  rule: state.widgetRuleEditor.rule,
});

const mapDispatchToProps = (dispatch) => ({
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PROPERTY_CHANGE, property });
  },
});

type PropTypes = {
  rule: ICampaign;
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => void;
};

const RuleBuilderPageSelect = (props: PropTypes) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  const { rule, handlePropertyChange } = props;
  const [draftRules, setDraftRules] = React.useState(cloneDeep(rule.rules));
  const saveButtonDisabled = isEqual(draftRules, rule.rules);

  const getTextFieldPlaceholder = (type: ICampaign["rules"][0]["type"]) => {
    switch (type) {
      case "exclude_list":
      case "include_list":
        return I18n.t("widgets_display_rules_editor.builder.page_select_view.placeholders.list");
      default:
        return I18n.t("widgets_display_rules_editor.builder.page_select_view.placeholders.paste_url");
    }
  };

  const conditionOptions = ["include", "exclude", "exact", "include_list", "exclude_list"].map(
    (condition) =>
      ({
        label: I18n.t(`widgets_display_rules_editor.builder.page_select_view.conditions.${condition}`),
        value: condition,
      } as const)
  );

  const operatorOptions = [
    { label: I18n.t("widgets_display_rules_editor.builder.page_select_view.operators.and"), value: "and" },
    {
      label: I18n.t("widgets_display_rules_editor.builder.page_select_view.operators.or"),
      value: "or",
    },
  ];

  return (
    <div className={classes.mainWrapper}>
      <RuleBuilderHeader
        title="Select pages"
        onBackClick={() => handlePropertyChange({ selectedView: WidgetRuleEditorSelectedView.MAIN })}
        buttonProps={{
          disabled: saveButtonDisabled,
          onClick: () =>
            handlePropertyChange({
              rule: { ...rule, rules: draftRules },
              selectedView: WidgetRuleEditorSelectedView.MAIN,
            }),
        }}
      />
      <Typography className={classes.title}>
        {I18n.t("widgets_display_rules_editor.builder.page_select_view.define_pages")}
      </Typography>
      <div className={classes.itemsWrapper}>
        {draftRules.map((item, index) => (
          <div className={classes.conditionItem} key={index}>
            {index > 0 && (
              <MDropdown
                classes={{
                  root: classes.operatorDropdown,
                }}
                placeholder={I18n.t("widgets_display_rules_editor.builder.page_select_view.placeholders.operator")}
                value={operatorOptions.find((option) => option.value === item.operator) || null}
                optionLabel="label"
                optionValue="value"
                options={operatorOptions}
                handleOnChange={(option) => {
                  const newRules = [...draftRules];
                  newRules[index].operator = option.value;
                  setDraftRules(newRules);
                }}
              />
            )}
            <MSelect
              options={conditionOptions}
              value={conditionOptions.find((option) => option.value === item.type)}
              optionLabel={"label"}
              optionValue={"value"}
              height={50}
              menuPlacement={"bottom"}
              onChange={(option) => {
                const newRules = [...draftRules];
                newRules[index].type = option.value;
                setDraftRules(newRules);
              }}
              extraControlElement={
                draftRules.length > 1 && (
                  <TrashCanIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setDraftRules(draftRules.filter((_, i) => i !== index));
                    }}
                    className={classes.trashCanIcon}
                  />
                )
              }
            />
            <MTextFieldV2
              className={classes.textField}
              value={item.value}
              onValueChange={(value) => {
                const newRules = [...draftRules];
                newRules[index].value = value;
                setDraftRules(newRules);
              }}
              placeholder={getTextFieldPlaceholder(item.type)}
              multiline={["include_list", "exclude_list"].includes(item.type)}
              minRows={["include_list", "exclude_list"].includes(item.type) ? 5 : 1}
              maxRows={["include_list", "exclude_list"].includes(item.type) ? 10 : 1}
            />
          </div>
        ))}
      </div>

      <MDropdown
        placeholder={I18n.t("widgets_display_rules_editor.builder.page_select_view.placeholders.add_condition")}
        value={{ label: "", value: "" }}
        optionLabel="label"
        optionValue="value"
        options={conditionOptions}
        handleOnChange={(option) =>
          setDraftRules([
            ...draftRules,
            {
              type: option.value,
              value: "",
              operator: "and",
            },
          ])
        }
        classes={{
          placeholder: classes.mDropdownPlaceholder,
          dropDownIcon: classes.mDropdownIcon,
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleBuilderPageSelect);
