import * as React from "react";
import { Editor, Element, Node, Path, Point, Range, Transforms, Text } from "slate";
import { ReactEditor, RenderElementProps, useFocused, useSelected, useSlateStatic } from "slate-react";
import { I18n } from "react-redux-i18n";
import { useTheme } from "@material-ui/core/styles";
import { isEqual } from "lodash";
import { emitEvent, useEvent } from "../../../hooks/useEvents";
import richTextEditorHelper from "../../helper/richTextEditorHelper";
import { Widget } from "../widgets/WithWidgets";
import { editorType, ForcedLayout } from "../../types/editor.Types";

// ReadOnly UI Components
export const LayoutContainerReadOnly = ({
  attributes,
  children,
  element,
}: RenderElementProps & { element: { type: "layout" } }) => (
  <div
    style={{
      marginBottom: 10,
      marginTop: 10,
      position: "relative",
    }}
    {...attributes}
  >
    <div
      style={{
        columnGap: element.layoutType ? 80 : 5,
        display: "grid",
        gridTemplateColumns: element.layout.map((x) => `${x}fr`).join(" "),
        lineBreak: "anywhere",
      }}
    >
      {children}
    </div>
  </div>
);

export const LayoutAreaReadOnly = ({ attributes, children }: RenderElementProps) => {
  const theme = useTheme();
  return (
    <div
      style={
        {
          // paddingLeft: 10,
          // paddingRight: 10,
          // paddingBottom: 10,
        }
      }
      {...attributes}
    >
      {children}
    </div>
  );
};

// UI Components
export const LayoutContainer = ({
  attributes,
  children,
  element,
}: RenderElementProps & { element: { type: "layout" } }) => {
  const focused = useFocused();
  const selected = useSelected();
  const editor = useSlateStatic();

  useEvent("edit-design-change", (event) => {
    // console.log("LayoutContainer", "useEvent", "edit-design-change", event);
    if (element.uuid === event.element.uuid) {
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(
        editor,
        {
          type: "layout",
          layout: event.layout,
        },
        { at: path }
      );
      emitEvent("edit-design", { element: { ...element, layout: event.layout }, selected: true });
    }
  });

  const { layout } = element;
  return (
    <Widget
      title={I18n.t(`rich_text_editor.layouts.${element.layoutType ? element.layoutType : "name"}`)}
      element={element}
      contentEditable
      editable
      handleDeleteClick={() => {
        const path = ReactEditor.findPath(editor, element);
        Transforms.removeNodes(editor, { at: path });
      }}
    >
      <div
        style={{
          marginBottom: 10,
          marginTop: 10,
          marginRight: 0,
          marginLeft: 0,
          position: "relative",
        }}
        {...attributes}
      >
        <div
          style={{
            columnGap: 5,
            display: "grid",
            gridTemplateColumns: layout.map((x) => `${x}fr`).join(" "),
          }}
        >
          {children}
        </div>
      </div>
    </Widget>
  );
};

export const LayoutArea = ({ attributes, children }: RenderElementProps) => {
  const theme = useTheme();
  return (
    <div
      style={{
        position: "relative",
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: 3,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
      }}
      {...attributes}
    >
      {children}
    </div>
  );
};

export const insertLayout = (editor: Editor, layout: [number, ...number[]], at: Path) => {
  richTextEditorHelper.insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading(
    editor,
    [
      {
        type: "layout",
        layout,
        uuid: richTextEditorHelper.getUuid(),
        children: [{ type: "layout-area", children: [{ type: "paragraph", children: [{ text: "" }] }] }],
      },
    ],
    at
  );
  const layoutEntry = Editor.above(editor, { match: (x) => x.type === "layout" });
  if (layoutEntry) {
    Transforms.select(editor, [...layoutEntry[1], 0]);
  } else {
    Transforms.select(editor, at);
  }
};

// Plugin
export function withLayouts(editor: Editor): Editor {
  const { normalizeNode, deleteBackward } = editor;

  editor.deleteBackward = (unit) => {
    if (
      editor.selection &&
      Range.isCollapsed(editor.selection) &&
      // this is just an little optimisation
      // we're only doing things if we're at the start of a layout area
      // and the start of anything will always be offset 0
      // so we'll bailout if we're not at offset 0
      editor.selection.anchor.offset === 0
    ) {
      const [aboveNode, abovePath] = Editor.above(editor, {
        match: (node) => node.type === "layout-area",
      }) || [editor, []];
      if (aboveNode.type === "layout-area" && Point.equals(Editor.start(editor, abovePath), editor.selection.anchor)) {
        return;
      }
    }
    deleteBackward(unit);
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && node.type === "layout") {
      // console.log("normalizeNode", node.type);
      if (node.layout === undefined) {
        Transforms.unwrapNodes(editor, { at: path });
        return;
      }
      if (node.children.length < node.layout.length) {
        // console.log("normalizeNode", node.type, "insert nodes", node.children.length, node.layout.length);
        Transforms.insertNodes(
          editor,
          Array.from({
            length: node.layout.length - node.children.length,
          }).map(() => ({
            type: "layout-area",
            children: [richTextEditorHelper.paragraphElement()],
          })),
          {
            at: [...path, node.children.length],
          }
        );
        return;
      }
      if (node.children.length > node.layout.length) {
        Array.from({
          length: node.children.length - node.layout.length,
        })
          .map((_, i) => i)
          .reverse()
          .forEach((i) => {
            // console.log("normalizeNode", node.type, "remove node", i, path);
            const layoutAreaToRemovePath = [...path, i + node.layout.length];
            const child = node.children[i + node.layout.length] as Element;
            richTextEditorHelper.moveChildren(
              editor,
              layoutAreaToRemovePath,
              [...path, node.layout.length - 1, (node.children[node.layout.length - 1] as Element).children.length],
              (node) => node.type !== "paragraph" || Node.string(child) !== ""
            );

            Transforms.removeNodes(editor, {
              at: layoutAreaToRemovePath,
            });
          });
        return;
      }

      if (node.layoutType === "pros-cons-table") {
        if (!isEqual(node.layout, [1, 1])) {
          Transforms.setNodes(
            editor,
            {
              layout: [1, 1],
            },
            { at: path }
          );
        }
      }
    }
    normalizeNode(entry);
  };
  return editor;
}

export function withForcedLayout(editor: Editor, forcedElements: ForcedLayout[]): Editor {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (forcedElements?.length >= 1 && Editor.isEditor(node)) {
      const foundTypes = [];
      for (const [child, childPath] of Node.children(editor, path)) {
        if (
          Element.isElement(child) &&
          forcedElements.some((fe) => fe.type === child.type && fe.layoutType === child.layoutType)
        ) {
          if (!foundTypes.includes(child.type)) {
            foundTypes.push(child.type);
          }
        }
      }
      if (foundTypes.length === forcedElements.length) {
        return;
      }
      for (const forcedElement of forcedElements.filter((fe) => !foundTypes.includes(fe.type))) {
        forcedElement.insert(editor, [editor.children.length - 1]);
      }
    }
    normalizeNode(entry);
  };

  return editor;
}

export function withTypeValidation(editor: Editor): Editor {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && !Text.isText(node) && !node.type) {
      Transforms.setNodes(
        editor,
        {
          type: editorType.paragraph,
        },
        { at: path }
      );
    }

    normalizeNode(entry);
  };

  return editor;
}
