import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 14,
    height: 14,
  },
}));

type PropTypes = {
  className?: string;
};

const StrokeLeftIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 14 14"}>
      <rect
        x="0.5"
        y="13.5"
        width="13"
        height="13"
        rx="0.5"
        transform="rotate(-90 0.5 13.5)"
        fill="#F5F6F8"
        stroke="#C4C4C4"
      />
      <mask id="path-2-inside-1_3797_243" fill="white">
        <path d="M1 14C0.447716 14 5.33895e-09 13.5523 1.19249e-08 13L1.55023e-07 1C1.61609e-07 0.447716 0.447715 5.33895e-09 1 1.19249e-08L13 1.55023e-07C13.5523 1.61609e-07 14 0.447716 14 1L14 13C14 13.5523 13.5523 14 13 14L1 14Z" />
      </mask>
      <path
        d="M1 14C-0.380712 14 -1.5 12.8807 -1.5 11.5L-1.5 2.5C-1.5 1.11929 -0.380712 -4.53994e-09 1 1.19249e-08L1.5 1.78873e-08C1.5 1.78873e-08 1.5 0.447715 1.5 1L1.5 13C1.5 13.5523 1.5 14 1.5 14L1 14ZM14 1.66948e-07L14 14L14 1.66948e-07ZM14 14L0 14L14 14ZM1.66948e-07 0L14 1.66948e-07L1.66948e-07 0Z"
        fill="#4353FF"
        mask="url(#path-2-inside-1_3797_243)"
      />
    </SvgIcon>
  );
};

export default StrokeLeftIcon;
