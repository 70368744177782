import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { OutlinedInput, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
  },
  valueWrap: {
    display: "flex",
    alignItems: "center",
  },
  backToDefault: {
    color: theme.palette.text.hint,
    fontSize: 16,
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  input: {
    fontSize: theme.typography.pxToRem(14),
    padding: "0 5px",
  },
  notchedOutline: {
    borderColor: `${theme.palette.divider} !important`,
    borderWidth: `1px !important`,
  },
  root: {
    width: 150,
    height: 37,
    borderRadius: 4,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    "&:hover": {
      borderColor: theme.palette.divider,
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  rowTitle: string;
  value: string;
  stepSize?: string;
  defaultValue?: string;
  isValueChanged?: boolean;
  customClass?: any;

  handleChange: any;
};

const InputRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    rowTitle,
    value = "",
    stepSize = "1",
    defaultValue = "",
    isValueChanged = false,
    customClass = null,
    handleChange,
  } = props;

  const handleBackToDefault = () => {
    handleChange(defaultValue === "" ? defaultValue : parseFloat(defaultValue));
  };

  return (
    <div className={classes.row}>
      <Typography variant={"body2"} className={ClassNames(classes.text, isValueChanged && classes.boldText)}>
        {rowTitle}
      </Typography>
      <div className={classes.valueWrap}>
        {isValueChanged && (
          <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
            <CloseIcon className={classes.backToDefault} onClick={handleBackToDefault} />
          </Tooltip>
        )}
        <OutlinedInput
          value={value || defaultValue}
          placeholder={""}
          type="number"
          notched={false}
          inputProps={{ step: stepSize }}
          classes={{
            root: ClassNames(classes.root, customClass),
            input: classes.input,
            notchedOutline: classes.notchedOutline,
          }}
          onChange={(event) => {
            handleChange(parseFloat(event.target.value));
          }}
        />
      </div>
    </div>
  );
};
export default InputRow;
