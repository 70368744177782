import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import KeywordTypes from "./KeywordTypes/KeywordTypes";
import { web } from "../../../../helpers/urlHelper";
import DataSettingsSideBar from "./DataSettingsSideBar";
import JobManagement from "./JobManagement/JobManagement";
import KeywordSERPStats from "./Keyword SERPs/KeywordSERP";
import TopicValue from "./TopicValue/TopicValue";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: 10,
  },
  topicsWrapper: {
    overflow: "hidden",
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    width: "100%",
    marginRight: 40,
    display: "flex",
    flexDirection: "column",
  },
}));

type PropTypes = {};

const DataSettingsMain = (props: PropTypes) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <MHeaderMetaTags title={I18n.t("meta_tags.strategy.settings.title")} />
      <div className={classes.topicsWrapper}>
        <DataSettingsSideBar />

        <div className={classes.content}>
          <Switch>
            <Route
              exact={false}
              path={web.dataSettings("job-management")}
              component={() => <JobManagement refetch={false} />}
            />
            <Route exact={false} path={web.dataSettings("classify-types")} component={() => <KeywordTypes />} />

            <Route exact={false} path={web.dataSettings("serp-stats")} component={() => <KeywordSERPStats />} />
            <Route exact={false} path={web.dataSettings("value-config")} component={() => <TopicValue />} />

            <Redirect exact from={"*"} to={web.dataSettings("classify-types")} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default DataSettingsMain;
