import * as React from "react";
import { I18n } from "react-redux-i18n";
import { IPage, IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { find } from "lodash";
import { makeStyles, Theme } from "@material-ui/core";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../../elementsStyles/components/StylePanelSubSection";
import SwitchBaseComponent from "../../../elementsStyles/BaseComponents/SwitchBaseComponent";
import TextOldElement from "../../../elementsStyles/ElementComponents/TextOldElement";
import BackgroundColorCSS from "../../../elementsStyles/CssComponents/BackgroundColorCSS";
import BorderColorCSS from "../../../elementsStyles/CssComponents/BorderColorCSS";
import HeightCSS from "../../../elementsStyles/CssComponents/HeightCSS";
import ProductPickerElement from "../../../elementsStyles/ElementComponents/ProductPickerElement";
import MultipleProductsPickerElement from "../../../elementsStyles/ElementComponents/MultipleProductsPickerElement";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  numberInput: {
    width: 180,
    height: 32,
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 2,
    padding: "0 8px",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

type PropTypes = {
  editor: Editor;
  selectedWebsite: IWebsite;
  websiteTheme: IWebsiteThemeProps;
  element: any;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
};

const ComparisonTableWidgetStyle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, selectedWebsite, websiteTheme, element, products, handleProductsChange } = props;

  const handleElementDataChange = (data) => {
    console.log("PageEditorProsConsStyle: PageEditorComparisonTableStyle", data);
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const onProductSelected = (prod: IProduct, index: number) => {
    const sProducts = [...(element.data?.products || [{}])];
    let comparisons = [...(element.data?.comparisons || [])];
    if (prod) {
      if (sProducts[index] === undefined && comparisons) {
        comparisons = [
          ...element.data.comparisons?.map((comp) => {
            const filtered = [
              ...(comp.products || []),
              {
                itemNumber: prod.itemNumber,
                selected: false,
              },
            ];
            return { ...comp, products: filtered };
          }),
        ];
      }
      sProducts[index] = { itemNumber: prod.itemNumber };
      //
      handleElementDataChange({ ...element.data, products: sProducts, comparisons });
    } else {
      comparisons = [
        ...element.data.comparisons?.map((comp) => {
          const filtered = [...comp.products?.filter((product) => product.itemNumber !== sProducts[index].itemNumber)];
          return { ...comp, products: filtered };
        }),
      ];
      sProducts.splice(index, 1);
      handleElementDataChange({ ...element.data, products: sProducts, comparisons });
    }
  };
  const getCompleteProductObject = (product) => {
    return find(products, (p) => p?.itemNumber === product?.itemNumber);
  };

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.feature_comparison_table.name")} editor={editor}>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.settings")}>
        {/*<MultipleProductsPickerElement*/}
        {/*  isClearable={false}*/}
        {/*  fullWidth*/}
        {/*  editor={editor}*/}
        {/*  selectedWebsite={selectedWebsite}*/}
        {/*  page={page}*/}
        {/*  onProductsChange={(products: IProduct[]) => {*/}
        {/*  handleElementDataChange({*/}
        {/*  ...element.data,*/}
        {/*  products: products.map((p) => ({*/}
        {/*  itemNumber: p.itemNumber,*/}
        {/*})),*/}
        {/*});*/}
        {/*}}*/}
        {/*  />*/}
        <div>
          {[...(element.data?.products || []), null].map((product, index) => {
            return (
              <ProductPickerElement
                fullWidth
                products={products}
                handleProductsChange={handleProductsChange}
                isClearable
                key={index}
                title={`Product ${index + 1}`}
                selectedWebsite={selectedWebsite}
                product={getCompleteProductObject(product)}
                onProductChange={(selected: IProduct) => onProductSelected(selected, index)}
              />
            );
          })}
        </div>
        <SwitchBaseComponent
          defaultValue={true}
          value={element.data?.showImage}
          title={I18n.t("rich_text_editor.feature_comparison_table.show_image")}
          handleChange={(showImage) => {
            handleElementDataChange({ ...element.data, showImage });
          }}
        />
      </StylePanelSubSection>
      <TextOldElement
        sectionTitle={I18n.t("rich_text_editor.title")}
        cssKey={"titleCss"}
        defaultValue={websiteTheme.typography.h4.css.fontSize}
        handleChange={handleElementDataChange}
        element={element}
        theme={websiteTheme}
      />
      <TextOldElement
        sectionTitle={I18n.t("rich_text_editor.description")}
        cssKey={"descriptionCss"}
        defaultValue={websiteTheme.typography.body2.css.fontSize}
        handleChange={handleElementDataChange}
        element={element}
        theme={websiteTheme}
      />

      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.feature_comparison_table.table_colors")}>
        <BackgroundColorCSS
          title={I18n.t("rich_text_editor.feature_comparison_table.head_row_bg")}
          value={element.data?.headCss?.backgroundColor || websiteTheme.palette.secondary.main}
          defaultValue={websiteTheme.palette.secondary.main}
          isValueChanged={false}
          handleChange={(backgroundColor) => {
            handleElementDataChange({ ...element.data, headCss: { backgroundColor } });
          }}
        />
        <BackgroundColorCSS
          title={I18n.t("rich_text_editor.feature_comparison_table.odd_row_bg")}
          value={element.data?.oddCss?.backgroundColor || websiteTheme.palette.secondary.main}
          defaultValue={websiteTheme.palette.secondary.main}
          isValueChanged={false}
          handleChange={(backgroundColor) => {
            handleElementDataChange({ ...element.data, oddCss: { backgroundColor } });
          }}
        />
        <BackgroundColorCSS
          title={I18n.t("rich_text_editor.feature_comparison_table.even_row_bg")}
          value={element.data?.evenCss.backgroundColor || "#ffffff"}
          defaultValue={"#ffffff"}
          isValueChanged={false}
          handleChange={(backgroundColor) => {
            handleElementDataChange({ ...element.data, evenCss: { backgroundColor } });
          }}
        />
        <BorderColorCSS
          title={I18n.t("rich_text_editor.pricing_table.divider_color")}
          value={element.data?.dividerCss.borderBottomColor || websiteTheme.palette.background.default}
          defaultValue={websiteTheme.palette.background.default}
          isValueChanged={false}
          handleChange={(borderBottomColor) =>
            handleElementDataChange({ ...element.data, dividerCss: { ...element.data.dividerCss, borderBottomColor } })
          }
        />
        <HeightCSS
          value={element.data?.dividerCss?.borderBottomWidth?.split("px")[0] || 1}
          defaultValue={1}
          handleChange={(border) =>
            handleElementDataChange({
              ...element.data,
              dividerCss: { ...element.data.dividerCss, borderBottomWidth: `${border}px` },
            })
          }
        />
      </StylePanelSubSection>
    </StylePanelSection>
  );
};

export default ComparisonTableWidgetStyle;
