import * as React from "react";
import { Input, makeStyles, Theme } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import FontColorCSS from "../../elementsStyles/CssComponents/FontColorCSS";
import MarginCSS from "../../elementsStyles/CssComponents/MarginCSS";
import StylePanelSection from "../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../elementsStyles/components/StylePanelSubSection";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  inputRoot: {
    width: 80,
    border: `solid 1px ${theme.palette.divider}`,
    padding: "0 5px",
  },
}));

type PropTypes = {
  websiteTheme: IWebsiteThemeProps;
  editor: Editor;
  element: any;
};

const DividerStyle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, element, websiteTheme } = props;

  const handleElementDataChange = (data) => {
    console.log("PageEditorDividerStyle: handleElementDataChange: ", data);
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const dividerCss = element.data?.dividerCss || {};
  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.divider.name")} editor={editor}>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.divider.properties")}>
        <div className={classes.row}>
          <Typography variant={"body2"} className={classes.text}>
            {I18n.t("rich_text_editor.divider.width")}
          </Typography>
          <Input
            value={dividerCss?.width?.split("%")[0] || 100}
            type="number"
            disableUnderline
            endAdornment={<InputAdornment position="start">%</InputAdornment>}
            inputProps={{ min: 1, max: 100 }}
            classes={{
              root: classes.inputRoot,
            }}
            onChange={(event) => {
              handleElementDataChange({
                ...element.data,
                dividerCss: { ...dividerCss, width: `${event.target.value}%` },
              });
            }}
          />
        </div>
        <div className={classes.row}>
          <Typography variant={"body2"} className={classes.text}>
            {I18n.t("rich_text_editor.divider.height")}
          </Typography>
          <Input
            value={dividerCss?.height?.split("px")[0] || 1}
            type="number"
            disableUnderline
            endAdornment={<InputAdornment position="start">px</InputAdornment>}
            inputProps={{ min: 1, max: 100 }}
            classes={{
              root: classes.inputRoot,
            }}
            onChange={(event) =>
              handleElementDataChange({
                ...element.data,
                dividerCss: { ...dividerCss, height: `${event.target.value}px` },
              })
            }
          />
        </div>
        <FontColorCSS
          title={I18n.t("rich_text_editor.divider.color")}
          value={dividerCss?.backgroundColor || websiteTheme.palette.divider}
          defaultValue={websiteTheme.palette.divider}
          isValueChanged={false}
          handleChange={(backgroundColor) =>
            handleElementDataChange({
              ...element.data,
              dividerCss: { ...dividerCss, backgroundColor },
            })
          }
        />

        <MarginCSS
          value={dividerCss?.margin || "30px 0px"}
          defaultValue={"30px 0px"}
          handleChange={(margin) =>
            handleElementDataChange({
              ...element.data,
              dividerCss: { ...dividerCss, margin },
            })
          }
        />
      </StylePanelSubSection>
    </StylePanelSection>
  );
};

export default DividerStyle;
