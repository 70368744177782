import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import CfProgressBar from "../../../components/CfProgressBar";
import { PAGE_EDITOR } from "../../../reducers/constants/actionTypes";
import { ePageState, IPage } from "../../../reducers/constants/objectTypes";
import MSwitch from "../../../components/MSwitch";

const useStyles = makeStyles((theme: Theme) => ({
  switchWrapper: {
    cursor: "help",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0 5px 7px",
  },
  autoSaveText: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  progressBar: {
    padding: "9px 15px",
  },
}));

type PropTypes = {
  page: IPage;
  saveInProgress: boolean;
  autoSaveOn: boolean;
  handleAutoSaveOn: (value: boolean) => void;
};

const mapStateToProps = (state) => ({
  page: state.pageEditor.page,
  saveInProgress: state.pageEditor.saveInProgress,
  autoSaveOn: state.pageEditor.autoSaveOn,
});

const mapDispatchToProps = (dispatch) => ({
  handleAutoSaveOn: (value) => dispatch({ type: PAGE_EDITOR.AUTO_SAVE_ON, value }),
});
const PageEditorAutoSaveSwitch = (props: PropTypes) => {
  const classes = useStyles(props);
  const { page, saveInProgress, autoSaveOn, handleAutoSaveOn } = props;

  React.useEffect(() => {
    handleAutoSaveOn(
      ![ePageState.published, ePageState.deleted, ePageState.archived].includes(page.currentState.state)
    );
  }, [page]);

  return (
    <div className={classes.switchWrapper}>
      <Tooltip enterDelay={500} title={I18n.t("common.auto_save_tooltip")} arrow>
        <Typography color={"textSecondary"} variant={"body2"} className={classes.autoSaveText}>
          {I18n.t("common.auto_save")}
        </Typography>
      </Tooltip>
      {saveInProgress && (
        <div className={classes.progressBar}>
          <CfProgressBar size={20} />
        </div>
      )}
      {!saveInProgress && <MSwitch checked={autoSaveOn} onChange={() => handleAutoSaveOn(!autoSaveOn)} />}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorAutoSaveSwitch);
