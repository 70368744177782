import * as React from "react";
import agent from "../../../agent";
import useInterval from "../../../hooks/useInterval";
import { addLocationListener, removeLocationListener } from "../../../store";

type PropTypes = {};

const getBrowserDocumentHiddenProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  }
  if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  }
  if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
  return "";
};

const getBrowserVisibilityProp = () => {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  }
  if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  }
  if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
  return "";
};

const INTERVAL = 60;
const IdleChecker = (props: PropTypes) => {
  const [tabVisible, setTabVisible] = React.useState(true);
  const [idleCounter, setIdleCounter] = React.useState(0);
  const [URI, setURI] = React.useState(null);

  const sendKeepAlive = () => {
    agent.Admin.keepAlive(tabVisible, URI)
      .then((res) => {})
      .catch((e) => {});
  };

  React.useEffect(() => {
    if (URI) {
      sendKeepAlive();
    }
  }, [URI]);

  React.useEffect(() => {
    const listener = (action, location) => {
      // console.log('onLocationChanged - action', action);
      console.log("onLocationChanged - location", location);
      // console.log('onLocationChanged - location',location.pathname);
      // console.log('URI !== location.pathname',URI !== location.pathname);
      if (URI !== location.pathname) {
        setURI(location.pathname);
      }
    };
    addLocationListener(listener);
    return () => {
      removeLocationListener(listener);
    };
  }, []);

  const onMouseMove = (ev) => {
    setIdleCounter(0);
  };

  const onKeyPress = (ev) => {
    setIdleCounter(0);
  };

  const onVisibilityChange = (ev) => {
    setTabVisible(!document[getBrowserDocumentHiddenProp()]);
  };

  React.useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("keydown", onKeyPress);
    window.addEventListener(getBrowserVisibilityProp(), onVisibilityChange, false);
    return () => {
      console.log("remove listeners");
      window.removeEventListener("beforeunload", onMouseMove);
      window.removeEventListener("keydown", onKeyPress);
      window.removeEventListener(getBrowserVisibilityProp(), onVisibilityChange);
    };
  }, []);

  useInterval(() => {
    const idleTime = idleCounter + INTERVAL;
    setIdleCounter(idleTime);
    if (idleTime <= INTERVAL) {
      sendKeepAlive();
    }
  }, INTERVAL * 1000);

  return null;
};

export default IdleChecker;
