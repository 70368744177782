import * as React from "react";
import _, { find, uniqBy } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { PropTypes } from "@material-ui/core";
import { IWebsite, IProduct } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import MSelect from "../../../../../components/MSelect";
import theme from "../../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  writersWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  columnWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 0px 30px",
    maxWidth: (props: PropTypes) => props.maxWidth || "unset",
    minWidth: (props: PropTypes) => props.minWidth || "unset",
    width: "100%",
    gap: 10,
  },
  writersWrapperFullWidth: {
    width: "100%",
  },
  writerWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  writerWrapperFullWidth: {
    flexDirection: "column",
    alignItems: "start",
  },
  writerTitle: {
    maxWidth: 65,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    whiteSpace: "pre",
    fontWeight: (props: PropTypes) => (props.titleAndLengthLocation === "top" ? 700 : "normal"),
  },
  writerTitleFullWidth: {
    maxWidth: "unset",
  },
  clearIcon: {
    fill: "#808080",
    marginLeft: 5,
    cursor: "pointer",
  },
}));

type PropTypes = {
  selectedProducts: any[];
  selectedWebsite: IWebsite;
  product: any;
  title?: string;
  handleChange: Function;
  isClearable?: boolean;
  fullWidth?: boolean;
  titleAndLengthLocation?: "left" | "top";
  borderRadius?: number;
  height?: number;
  maxWidth?: string | number;
  minWidth?: string | number;
};

const mapDispatchToProps = (dispatch) => ({});

const ProductPickerNoRepetitions = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    product,
    selectedProducts,
    selectedWebsite,
    handleChange,
    title = I18n.t("edit_post.select_product"),
    isClearable = false,
    fullWidth = false,
    titleAndLengthLocation = "left",
    borderRadius = 2,
    height = 32,
  } = props;
  const [items, setItems] = React.useState<IProduct[]>([]);

  const getProducts = (search = "") => {
    agent.Product.autoComplete(selectedWebsite._id, search, true)
      .then((results) => {
        setItems(results.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    getProducts();
  }, []);

  return (
    <div
      className={ClassNames(
        titleAndLengthLocation === "top" && classes.columnWrapper,
        titleAndLengthLocation === "left" && classes.writerWrapper,
        fullWidth && classes.writerWrapperFullWidth
      )}
    >
      <Typography
        variant={"body1"}
        className={ClassNames(classes.writerTitle, fullWidth && classes.writerTitleFullWidth)}
        color={"textSecondary"}
      >
        {title}
      </Typography>
      <div className={ClassNames(classes.writersWrapper, fullWidth && classes.writersWrapperFullWidth)}>
        <MSelect
          isClearable={isClearable}
          textColor={theme.palette.text.primary}
          options={items.filter((item) => !selectedProducts.map((prod) => prod.itemNumber).includes(item.itemNumber))}
          width={fullWidth ? "unset" : 200}
          fullWidth={fullWidth}
          value={product}
          searchable
          placeholder={I18n.t("edit_post.products_input_ph")}
          borderColor={theme.palette.divider}
          borderWidth={1}
          borderRadius={borderRadius}
          isBold={false}
          height={height}
          menuPlacement={"bottom"}
          handleInputChange={(search) => {
            getProducts(search);
          }}
          handleChange={(selectedItem) => handleChange(selectedItem)}
          optionLabel={"name"}
          optionValue={"_id"}
        />
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ProductPickerNoRepetitions);
