import React, { useMemo, useState } from "react";
import { I18n } from "react-redux-i18n";
import {
  IWidget,
  IWidgetField,
  IWidgetFieldActionPayload,
  eWidgetFieldType,
  eWidgetSubtype,
  eWidgetType,
} from "../../../../../../../reducers/constants/objectTypes";
import MSwitch from "../../../../../../../components/MSwitch";
import MDynamicListItem, { IDraggableProps } from "../../../../../../../components/MDynamicListItem";

type PropTypes = {
  field: IWidgetField;
  widget: IWidget;
  onClick(): void;
  deleteField?(fieldToDelete: IWidgetFieldActionPayload): void;
  editField(field: IWidgetFieldActionPayload): void;
} & IDraggableProps;

const WidgetField = (props: PropTypes) => {
  const { field, widget } = props;
  const hasMultipleItems = widget.items.length > 1;
  const { deleteField, editField } = props;
  const [showDialog, setShowDialog] = useState(null);

  const handleDelete = () => {
    // if (field.hasTemplateReference && hasMultipleItems) return setShowDialog("delete");
    deleteField({ field, applyOnAllItems: false });
  };

  const handleSwitchToggle = () => {
    // if (field.hasTemplateReference && hasMultipleItems) return setShowDialog("switch");
    editField({
      field: { ...field, visible: !field.visible },
      applyOnAllItems: false,
      changedValues: { visible: !field.visible },
    });

    // editField({ field: { ...field, visible: !field.visible }, applyOnAllItems: false });
  };

  const DialogProps = useMemo(
    () => ({
      delete: {
        title: "Delete item",
        handleClose: () => setShowDialog(false),
        handleApprove: () => deleteField({ field, applyOnAllItems: true }),
        handleDecline: () => deleteField({ field, applyOnAllItems: false }),
        text: "Do you want to delete this item from all included items?",
        buttonApprove: "YES, DELETE ON ALL ITEMS",
        buttonDecline: "NO, DELETE THIS ITEM ONLY",
      },
      switch: {
        title: "Switch item",
        handleClose: () => setShowDialog(false),
        handleApprove: () => {
          editField({
            field: { ...field, visible: !field.visible },
            applyOnAllItems: true,
            changedValues: { visible: !field.visible },
          });
          setShowDialog(false);
        },
        handleDecline: () => {
          editField({
            field: { ...field, visible: !field.visible },
            applyOnAllItems: false,
          });
          setShowDialog(false);
        },
        text: "Do you want to switch this item from all included items?",
        buttonApprove: "YES, SWITCH ON ALL ITEMS",
        buttonDecline: "NO, SWITCH THIS ITEM ONLY",
      },
    }),
    [deleteField, field, editField]
  );

  const RightIconsComponent = () => (
    <>
      {field.settings.hideable && (
        <MSwitch checked={field.visible} onChange={handleSwitchToggle} onClick={(e) => e.stopPropagation()} />
      )}
    </>
  );

  const getFieldTitle = (): string => {
    if (
      [eWidgetSubtype.PRODUCT_REVIEW, eWidgetSubtype.CHARTICLE_TOP_PRODUCTS].includes(widget.subtype) &&
      field.type === eWidgetFieldType.CALL_TO_ACTION
    ) {
      return I18n.t("widgets_builder.widget_item_selector.call_to_action_and_review");
    }
    return I18n.t(`widgets_builder.widget_item_selector.${field?.type}`);
  };

  return (
    <MDynamicListItem
      {...props}
      title={getFieldTitle()}
      rightIconsComponent={<RightIconsComponent />}
      onDelete={field.settings.deletable && deleteField && handleDelete}
    />
  );
};

export default WidgetField;
