import { EDITORIAL_POLICY } from "./constants/actionTypes";

const initialState = {
  loading: true,
  isEditorial: false,
  policy: null,
  policies: [],
  policyType: null,
  policyChanged: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDITORIAL_POLICY.ON_POLICY_LOADED:
      return {
        ...state,
        loading: false,
        isEditorial: true,
        policyChanged: false,
        policy: action.policy,
        policyType: action.policyType,
      };
    case EDITORIAL_POLICY.ON_POLICY_CHANGED:
      return {
        ...state,
        policyChanged: true,
        policy: action.policy,
      };
    case EDITORIAL_POLICY.CLEANUP:
      return initialState;
    case EDITORIAL_POLICY.ON_POLICIES_LOADED:
      return {
        ...state,
        policies: action.policies,
      };
    default:
      return state;
  }
};
