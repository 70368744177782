import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 17,
    height: 14,
    fill: "transparent",
  },
}));

type PropTypes = {
  className?: string;
};

const PaddingBottomIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 17 14"}>
      <path
        d="M0.899902 13.1001L16.0999 13.1001M5.2999 3.3001L5.2999 5.9001C5.2999 7.22558 6.37442 8.3001 7.6999 8.3001L10.2999 8.3001C11.6254 8.3001 12.6999 7.22558 12.6999 5.9001L12.6999 3.3001C12.6999 1.97462 11.6254 0.900099 10.2999 0.900099L7.6999 0.900099C6.37442 0.900099 5.2999 1.97462 5.2999 3.3001Z"
        stroke="#333333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default PaddingBottomIcon;
