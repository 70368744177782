import React from "react";
import { I18n } from "react-redux-i18n";
import { IWidgetInputFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import GenericInputWidgetItem from "./components/GenericInputWidgetItem";

type PropTypes = {
  properties: IWidgetInputFieldProps;
  onPropertyChange(properties: IWidgetInputFieldProps): void;
};

const WebsiteWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;

  return (
    <GenericInputWidgetItem
      labelPlaceholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.website_url")}
      placeholderPlaceholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.website_url_ph")}
      properties={properties}
      onPropertyChange={onPropertyChange}
    />
  );
};

export default WebsiteWidgetItem;
