"use strict";
import * as React from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({}));

type propTypes = {
  className?: string;
  onClick?: Function;
};

function XIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className, onClick = null } = props;
  return (
    <SvgIcon onClick={(e) => onClick && onClick(e)} className={className} viewBox={"0 0 41 41"}>
      <path d="M40.100,0.856 L40.100,0.856 C38.916,-0.328 36.998,-0.328 35.814,0.856 L20.967,15.703 L6.120,0.856 C4.937,-0.328 3.018,-0.328 1.835,0.856 L1.835,0.856 C0.652,2.039 0.652,3.958 1.835,5.141 L16.682,19.988 L1.835,34.835 C0.652,36.018 0.652,37.937 1.835,39.120 L1.835,39.120 C3.018,40.304 4.937,40.304 6.120,39.120 L20.967,24.273 L35.814,39.120 C36.998,40.304 38.916,40.304 40.100,39.120 L40.100,39.120 C41.283,37.937 41.283,36.018 40.100,34.835 L25.253,19.988 L40.100,5.141 C41.283,3.958 41.283,2.039 40.100,0.856 Z" />
    </SvgIcon>
  );
}

export default XIcon;
