import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { I18n } from "react-redux-i18n";
import PageEditorElements from "./PageEditorElements";
import { useEvent } from "../../../hooks/useEvents";
import PageEditorElementStyleWrapper from "./designComponents/PageEditorElementStyleWrapper";
import PageEditorOutline from "./PageEditorOutline";
import PageEditorVersionHistory from "./PageEditorVersionHistory";
import { AbilityContext } from "../../../casl/can";
import PageEditorAssistantWrapper from "./assistant/PageEditorAssistantWrapper";
import { IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import localeHelper from "../../../helpers/localeHelper";
import PageEditorDefaultLocale from "./PageEditorDefaultLocale";
import { colors } from "../../../helpers/constants";
import PageEditorLinks from "../info/PageEditorLinks";

const useStyles = makeStyles((theme: Theme) => ({
  resourcesTab: {
    height: "100%",
    padding: 0,
    backgroundColor: colors.pageEditorBackgroundColor,
    display: "flex",
    flexDirection: "column",
  },
  tabsWrapper: {
    display: "flex",
    padding: "0 25px",
    top: 0,
    zIndex: 1,
    position: "sticky",
    backgroundColor: colors.pageEditorBackgroundColor,
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    padding: "0 25px 15px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
  },
  tabs: {
    "& .MuiTabs-centered": {
      display: "block",
    },
    "& .MuiTab-root": {
      paddingBottom: 0,
      // minHeight: 48,
    },
    "& .MuiTab-wrapper": {
      marginBottom: -10,
      lineHeight: "17px",
    },
  },
  tab: {
    textTransform: "capitalize",
    minWidth: "unset",
    width: "max-content",
    color: theme.palette.text.primary,
    padding: "0 20px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabSelected: {
    fontWeight: theme.typography.fontWeightBold as any,
    borderBottom: "2px solid #4353FF",
    color: theme.palette.text.primary,
  },
}));

type PropTypes = {
  withCustomElements: boolean;
  disableAi?: boolean;
  page?: IPage;
  selectedWebsite: IWebsite;
};

const PageEditorActions = (props: PropTypes) => {
  const classes = useStyles(props);
  const { withCustomElements, disableAi = true, page, selectedWebsite } = props;
  const [selectedTab, setSelectedTab] = React.useState(0);
  const ability = React.useContext<any>(AbilityContext);
  const [showDesignView, setShowDesignView] = React.useState(false);

  const tabs = [];
  useEvent(
    "edit-design",
    (data) => {
      if (!withCustomElements) {
        return;
      }
      if (data.selected) {
        setShowDesignView(true);
      } else if (!data.open) {
        setShowDesignView(false);
        setSelectedTab(tabs.indexOf(tabs.find((t) => t.type === "elements")));
      }
    },
    [withCustomElements]
  );

  useEvent(
    "show-ai-assistant",
    (data) => {
      if (data.selected) {
        if (data.openTab) {
          setSelectedTab(tabs.indexOf(tabs.find((t) => t.type === "assistant")));
        }
      }
    },
    []
  );
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue !== tabs.length) {
      setSelectedTab(newValue);
    }
  };

  tabs.push({
    type: I18n.t("rich_text_editor.types.links"),
    label: I18n.t("rich_text_editor.tabs.links"),
  });

  tabs.push({
    type: I18n.t("rich_text_editor.types.revisions"),
    label: I18n.t("rich_text_editor.tabs.version_history"),
  });

  if (page.locale !== (selectedWebsite.configurations?.locale?.defaultLocale || "en")) {
    tabs.push({
      type: I18n.t("rich_text_editor.types.default_locale"),
      label: I18n.t("rich_text_editor.tabs.default_locale", {
        locale: localeHelper.getLanguageFromLocaleCode(selectedWebsite.configurations?.locale?.defaultLocale || "en"),
      }),
    });
  }

  if (!ability.can("user_role", "customer") && page.outline) {
    tabs.unshift({ type: I18n.t("rich_text_editor.types.outline"), label: I18n.t("rich_text_editor.tabs.brief") });
  }

  if (!disableAi) {
    tabs.unshift({
      type: I18n.t("rich_text_editor.types.assistant"),
      label: I18n.t("rich_text_editor.tabs.assistant"),
    });
  }
  if (withCustomElements) {
    tabs.unshift({
      type: I18n.t("rich_text_editor.types.elements"),
      label: I18n.t("rich_text_editor.tabs.elements"),
    });
  }

  return (
    <div className={classes.resourcesTab}>
      <div className={classes.tabsWrapper}>
        <Tabs
          variant={"scrollable"}
          scrollButtons={"off"}
          indicatorColor="primary"
          value={selectedTab}
          onChange={handleChange}
          className={classes.tabs}
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              classes={{
                selected: classes.tabSelected,
              }}
              className={classes.tab}
              key={tab.type}
              label={tab.label}
            />
          ))}
        </Tabs>
      </div>
      <div className={classes.content}>
        {tabs[selectedTab].type === I18n.t("rich_text_editor.types.outline") && <PageEditorOutline />}
        {tabs[selectedTab].type === I18n.t("rich_text_editor.types.elements") && <PageEditorElements />}
        {tabs[selectedTab].type === I18n.t("rich_text_editor.types.links") && <PageEditorLinks />}
        <PageEditorAssistantWrapper visible={tabs[selectedTab].type === I18n.t("rich_text_editor.types.assistant")} />
        <PageEditorElementStyleWrapper visible={showDesignView} />
        {tabs[selectedTab].type === I18n.t("rich_text_editor.types.revisions") && <PageEditorVersionHistory />}
        {tabs[selectedTab].type === I18n.t("rich_text_editor.types.default_locale") && <PageEditorDefaultLocale />}
      </div>
    </div>
  );
};

export default PageEditorActions;
