import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { I18n } from "react-redux-i18n";
import PageEditorInfo from "../info/PageEditorInfo";
import { colors } from "../../../helpers/constants";
import PageEditorCommentsWrapper from "./PageEditorCommentsWrapper";
import { IPage } from "../../../reducers/constants/objectTypes";
import richTextEditorHelper from "../../../editor/helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  rightSideResourcesTab: {
    height: "100%",
    flex: 1,
    padding: "0",
    overflowY: "auto",
    backgroundColor: colors.pageEditorBackgroundColor,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
  },
  rightSideContent: {
    position: "relative",
  },
  rightSideTabs: {
    top: 0,
    zIndex: 1,
    position: "sticky",
    marginBottom: 20,
    backgroundColor: colors.pageEditorBackgroundColor,
    "& .MuiTabs-centered": {
      display: "block",
    },
    "& .MuiTabs-flexContainer": {
      padding: "0 20px",
    },
    "& .MuiTab-root": {
      paddingBottom: 0,
      width: "50%",
    },
    "& .MuiTab-wrapper": {
      marginBottom: -10,
      lineHeight: "17px",
    },
  },
  tab: {
    textTransform: "capitalize",
    minWidth: "unset",
    width: "max-content",
    color: theme.palette.text.primary,
    padding: "0 20px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabSelected: {
    fontWeight: theme.typography.fontWeightBold as any,
    borderBottom: "2px solid #4353FF",
    color: theme.palette.text.primary,
  },
}));

type PropTypes = {
  page: IPage;
};

const PageEditorRightSideActions = (props: PropTypes) => {
  const classes = useStyles(props);
  const { page } = props;
  const [selectedTab, setSelectedTab] = React.useState(0);
  const tabs = [
    {
      type: "INFO",
      label: I18n.t("rich_text_editor.tabs.info"),
    },
    {
      type: "COMMENTS",
      label: I18n.t("rich_text_editor.tabs.comments"),
    },
  ];

  React.useEffect(() => {
    if (richTextEditorHelper.pageHasUnresolvedComments(JSON.parse(page.richText) || [])) {
      setSelectedTab(1);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue !== tabs.length) {
      setSelectedTab(newValue);
    }
  };

  return (
    <div className={classes.rightSideResourcesTab}>
      <Tabs
        variant={"scrollable"}
        scrollButtons={"off"}
        indicatorColor="primary"
        value={selectedTab}
        onChange={handleChange}
        className={classes.rightSideTabs}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            classes={{
              selected: classes.tabSelected,
            }}
            className={classes.tab}
            key={tab.type}
            label={tab.label}
          />
        ))}
      </Tabs>

      <div className={classes.rightSideContent}>
        {tabs[selectedTab].type === "INFO" && <PageEditorInfo />}
        <PageEditorCommentsWrapper visible={tabs[selectedTab].type === "COMMENTS"} />
      </div>
    </div>
  );
};

export default PageEditorRightSideActions;
