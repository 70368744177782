import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { IWebsiteAnalyticsProps } from "../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import MRouterPrompt from "../../../components/MRouterPrompt";
import SelectRow from "../../../components/SelectRow";
import MCopyToClipboardButton from "../../../../../components/MCopyToClipboardButton";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MTextButton from "../../../../../components/MTextButton";

const useStyles = makeStyles<Theme, PropTypes>((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rowItem: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  analytics: state.websitePages.analytics,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (general) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "analytics",
      value: general,
    }),
});

type PropTypes = {
  analytics: IWebsiteAnalyticsProps;
  onPropertyChanged: (property) => void;
};

const Analytics = (props: PropTypes) => {
  const classes = useStyles(props);
  const { analytics, onPropertyChanged } = props;

  return (
    <PageSection sectionTitle={I18n.t("website.analytics.analytics")}>
      <MRouterPrompt />

      <div className={classes.rowItem}>
        <div className={classes.row}>
          <Typography variant={"body2"} className={ClassNames(classes.text)}>
            {I18n.t("website.analytics.entail_analytics")}
          </Typography>
          {analytics?.entailAnalytics && (
            <MTextButton
              text={I18n.t("website.analytics.copy_script")}
              onClick={() => {
                navigator.clipboard.writeText(`<script>
  !function(t, e) {var o, n, p, r;e.__SV || (window.entailInsights = e,e._i = [],e.init = function(i, s, a) {function g(t, e) {var o = e.split(".");2 == o.length && (t = t[o[0]],e = o[1]),t[e] = function() {t.push([e].concat(Array.prototype.slice.call(arguments, 0)))}}(p = t.createElement("script")).type = "text/javascript",p.async = !0,p.src = "https://cdn.entail-insights.com/js/entail.js",(r = t.getElementsByTagName("script")[0]).parentNode.insertBefore(p, r);var u = e;for (void 0 !== a ? u = e[a] = [] : a = "entailInsights",u.toString = function(t) {var e = "entailInsights";return "entailInsights" !== a && (e += "." + a),t || (e += " (stub)"),e},o = "trackEvent capture opt_out_capturing has_opted_out_capturing opt_in_capturing reset".split(" "),n = 0; n < o.length; n++)g(u, o[n]);e._i.push([i, s, a])},e.__SV = 1)}(document, window.entailInsights || []);
  entailInsights.init('${analytics.entailToken}', {disable_decide: true, autocapture: ${
                  analytics?.entailTracker || false
                }, widgets: ${analytics?.entailWidgets || false}, popups: ${analytics?.entailPopups || false}});
</script>`);
              }}
            />
          )}
        </div>
        <SelectRow
          flexDirection={"column"}
          value={analytics?.entailAnalytics ? "Yes" : "No"}
          selectOptions={["Yes", "No"]}
          handleChange={(value) => {
            onPropertyChanged({ ...analytics, entailAnalytics: value === "Yes" });
          }}
        />
      </div>

      <SelectRow
        bold
        flexDirection={"column"}
        rowTitle={I18n.t("website.analytics.entail_tracker")}
        value={analytics?.entailTracker ? "Yes" : "No"}
        selectOptions={["Yes", "No"]}
        handleChange={(value) => {
          onPropertyChanged({ ...analytics, entailTracker: value === "Yes" });
        }}
      />

      <SelectRow
        bold
        flexDirection={"column"}
        rowTitle={I18n.t("website.analytics.entail_widgets")}
        value={analytics?.entailWidgets ? "Yes" : "No"}
        selectOptions={["Yes", "No"]}
        handleChange={(value) => {
          onPropertyChanged({ ...analytics, entailWidgets: value === "Yes" });
        }}
      />

      <SelectRow
        bold
        flexDirection={"column"}
        rowTitle={I18n.t("website.analytics.entail_popups")}
        value={analytics?.entailPopups ? "Yes" : "No"}
        selectOptions={["Yes", "No"]}
        handleChange={(value) => {
          onPropertyChanged({ ...analytics, entailPopups: value === "Yes" });
        }}
      />

      {analytics?.entailAnalytics && (
        <>
          <TextFieldMaxLength
            key={"site-host"}
            titleAndLengthLocation={"top"}
            value={analytics.entailToken}
            disabled
            placeholder={""}
            title={I18n.t("website.entail_token")}
            height={37}
            multiLine
            borderRadius={4}
            handleValueChange={(newHost) => {}}
            marginBottom={30}
          />
          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            value={(analytics?.entailTrackingDomains || []).join("\n")}
            placeholder={I18n.t("website.analytics.entail_tracking_domains_ph")}
            title={I18n.t("website.analytics.entail_tracking_domains")}
            height={100}
            multiLine={true}
            borderRadius={4}
            handleValueChange={(value) => {
              onPropertyChanged({ ...analytics, entailTrackingDomains: (value || "").split("\n") });
            }}
            onBlur={() =>
              onPropertyChanged({
                ...analytics,
                entailTrackingDomains: (analytics?.entailTrackingDomains || []).filter(Boolean),
              })
            }
            marginBottom={30}
          />
        </>
      )}

      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={analytics?.googleAnalytics}
        placeholder={""}
        title={I18n.t("website.analytics.google_analytics")}
        height={37}
        borderRadius={4}
        handleValueChange={(value) => {
          onPropertyChanged({ ...analytics, googleAnalytics: value });
        }}
        marginBottom={30}
      />

      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={analytics?.facebookPixel}
        placeholder={""}
        title={I18n.t("website.analytics.facebook_pixel")}
        height={37}
        borderRadius={4}
        handleValueChange={(value) => {
          onPropertyChanged({ ...analytics, facebookPixel: value });
        }}
        marginBottom={30}
      />

      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={analytics?.googleTagManager}
        placeholder={""}
        title={I18n.t("website.analytics.google_tag_manager")}
        height={37}
        borderRadius={4}
        handleValueChange={(value) => {
          onPropertyChanged({ ...analytics, googleTagManager: value });
        }}
        marginBottom={30}
      />

      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={analytics?.segment}
        placeholder={""}
        title={I18n.t("website.analytics.segment")}
        height={37}
        borderRadius={4}
        handleValueChange={(value) => {
          onPropertyChanged({ ...analytics, segment: value });
        }}
        marginBottom={30}
      />
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
