import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldMaxLength from "../../../../../../../components/TextFieldMaxLength";

import { IWidgetField, IWidgetFieldProperties } from "../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "../items/components/GenericValueItemProperty";

type PropTypes = {
  selectedField: IWidgetField;
  onChange: (properties: IWidgetFieldProperties) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    marginBottom: 10,
  },
  input: {
    border: `1px solid ${theme.palette.grey["100"]}`,
    width: "100%",
    overflow: "hidden",
    padding: "10px 15px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: 14,
    borderRadius: 3,
    minHeight: 200,
  },
  length: {
    color: theme.palette.text.secondary,
  },
}));

const SelectedWidgetFieldDesign = (props: PropTypes) => {
  const classes = useStyles();
  const { selectedField, onChange } = props;

  const { properties } = selectedField;

  return (
    <div>
      <Typography variant="body1" className={classes.title}>
        Design
      </Typography>
      <div>
        <GenericValueItemProperty
          value={properties.elementId}
          onChange={({ value: elementId }) => onChange({ ...properties, elementId })}
          label={I18n.t(`widgets_builder.widget_editor.fields.labels.element_id`)}
          marginBottom={20}
        />
        <TextFieldMaxLength
          title={I18n.t(`widgets_builder.widget_editor.fields.labels.style`)}
          handleValueChange={(customCss: string) => onChange({ ...properties, customCss })}
          value={properties.customCss}
          placeholder={I18n.t("widgets_builder.widget_editor.fields.placeholders.style")}
          multiLine={true}
          titleAndLengthLocation="top"
          inputClassName={classes.input}
          lengthClassName={classes.length}
          alignTitle="end"
          justifyTitle="space-between"
        />
      </div>
    </div>
  );
};

export default SelectedWidgetFieldDesign;
