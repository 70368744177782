import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import TextFieldMaxLength from "../../../../../../../components/TextFieldMaxLength";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import { IWidgetSettings } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  input: {
    border: `1px solid ${palette.grey["100"]}`,
    width: "100%",
    overflow: "hidden",
    padding: "10px 15px",
    color: palette.text.primary,
    backgroundColor: palette.secondary.dark,
    fontWeight: typography.fontWeightLight as any,
    fontSize: 14,
    borderRadius: 3,
    minHeight: 200,
  },
  length: {
    color: palette.text.secondary,
  },
}));

type PropTypes = {
  settings: IWidgetSettings;
  editSettings: (settings: IWidgetSettings) => void;
};

const mapStateToProps = (state) => ({
  settings: state.widgetBuilder.widget.settings,
});

const mapDispatchToProps = (dispatch) => ({
  editSettings: (payload: IWidgetSettings) => dispatch({ type: WIDGET_BUILDER.EDIT_SETTINGS, payload }),
});

const CustomCssSettings = (props: PropTypes) => {
  const { settings } = props;
  const { editSettings } = props;
  const { customCss } = settings;

  const classes = useStyles();

  return (
    <TextFieldMaxLength
      title={I18n.t(`widgets_builder.widget_editor.Dialog.settings.custom_css`)}
      handleValueChange={(newValue: string) => editSettings({ ...settings, customCss: newValue })}
      value={customCss}
      placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
      multiLine={true}
      titleAndLengthLocation="top"
      inputClassName={classes.input}
      lengthClassName={classes.length}
      alignTitle="end"
      justifyTitle="space-between"
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomCssSettings);
