import { find } from "lodash";
import { EDIT_BLOG_POST } from "./constants/actionTypes";
import { ePostState } from "./constants/objectTypes";

const initialState = {
  postLoaded: false,
  post: null,
  postRichText: null,

  selectedTranslation: null,
  translationRichText: null,
  selectedLocale: null,

  selectedTab: "resources",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_BLOG_POST.CLEANUP:
      return initialState;
    case EDIT_BLOG_POST.TEXT_CHANGED:
      return {
        ...state,
        postRichText: action.richText,
      };
    case EDIT_BLOG_POST.TRANSLATION_TEXT_CHANGED:
      return {
        ...state,
        translationRichText: action.richText,
      };
    case EDIT_BLOG_POST.POST_LOADED:
      return {
        ...state,
        postLoaded: true,
        post: action.post,
        postRichText: action.post.richText,
      };
    case EDIT_BLOG_POST.POST_TRANSLATION_LOADED: {
      const defaultLocale = action.locale === action.post.uploadTo.defaultLocale;
      const selectedTranslation = find(action.post.translations, (t) => t.locale === action.locale);
      return {
        ...state,
        postLoaded: true,
        post: action.post,
        postRichText: action.post.richText,
        selectedLocale: action.locale,
        selectedTranslation,
        translationRichText: selectedTranslation?.richText || null,
        selectedTab: defaultLocale ? "resources" : "original_post",
      };
    }
    case EDIT_BLOG_POST.LOCALE_CHANGED: {
      const defaultLocale = action.locale === state.post.uploadTo.defaultLocale;
      let selectedTranslation = find(state.post.translations, (t) => t.locale === action.locale);
      if (!selectedTranslation) {
        selectedTranslation = {
          locale: action.locale,
          post: state.post._id,
          state: ePostState.UNINITIALIZED,
          title: "",
        };
      }
      return {
        ...state,
        selectedTab: defaultLocale ? "resources" : "original_post",
        selectedLocale: action.locale,
        selectedTranslation: defaultLocale ? null : selectedTranslation,
        translationRichText: defaultLocale ? null : selectedTranslation?.richText,
      };
    }
    case EDIT_BLOG_POST.TRANSLATION_CHANGED:
      return {
        ...state,
        selectedTranslation: action.translation,
      };
    case EDIT_BLOG_POST.POST_CHANGED: {
      const selectedTranslation = state.selectedTranslation
        ? find(action.post.translations, (t) => t.locale === state.selectedTranslation.locale)
        : null;
      return {
        ...state,
        post: action.post,
        // postRichText: state.postRichText || action.post.richText,
        selectedTranslation: selectedTranslation || state.selectedTranslation,
        // translationRichText:  state.translationRichText || (selectedTranslation ? selectedTranslation.richText : action.post.richText),
      };
    }
    case EDIT_BLOG_POST.TAB_CHANGED:
      return {
        ...state,
        selectedTab: action.tab,
      };

    default:
      return state;
  }
};
