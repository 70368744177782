import { connect } from "react-redux";
import React, { useMemo } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Classnames from "classnames";
import KeywordDialogListItem from "./KeywordDialogListItem";
import { I18n } from "react-redux-i18n";
import { IKeywordHeadingCluster } from "../../../../reducers/constants/objectTypes";
import useSort from "../../../../hooks/useSort";
import _ from "lodash";
import { TableSortLabel } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  dialogListSubHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 30px",
    backgroundColor: theme.palette.background.default,
    margin: "15px 0px",
    top: -1,
  },
  dialogTabs: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    width: "10%",
  },
  firstDialogTab: {
    width: "55%",
  },
  tab: {
    padding: "2px 40px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  activeTab: {
    color: theme.palette.text.primary,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    transition: "border .3s",
  },
  headerIcon: {
    width: 24,
    height: 24,
  },
  sortIcon: { color: `${theme.palette.text.primary} !important` },
}));

const mapStateToProps = (state) => ({
  broadKeywords: state.outlinePages.broadKeywords,
  keywords: state.outlinePages.keywords,
});

type PropTypes = {
  broadKeyword: boolean;
  broadKeywords: IKeywordHeadingCluster[];
  keywords: IKeywordHeadingCluster[];
};

const KeywordDialogList = (props: PropTypes) => {
  const classes = useStyles(props);

  const { broadKeyword, broadKeywords, keywords } = props;

  const data = useMemo(() => {
    return broadKeyword ? broadKeywords : keywords;
  }, [broadKeyword, broadKeywords, keywords]);

  const { sortFields, toggleSort } = useSort({
    initialSortFields: {
      clicks: "desc",
      impressions: "desc",
    },
  });

  const columns = useMemo(() => ["keyword", "clicks", "impressions", "volume", "ctr"], []);

  const keywordColumns = React.useMemo(
    () => ({
      keyword: {
        isSortable: false,
        label: I18n.t("strategy.outlinePages.dialog_keywords.keywords"),
      },
      clicks: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_keywords.clicks"),
      },
      impressions: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_keywords.impressions"),
      },
      volume: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_keywords.volume"),
      },
      ctr: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_keywords.ctr"),
      },
    }),
    []
  );

  const sortedData = useMemo(() => {
    if (data) {
      const fields: string[] = [];
      const directions: any[] = [];

      Object.entries(sortFields).forEach(([field, direction]) => {
        fields.push(field);
        directions.push(direction);
      });

      return _.orderBy(data, fields, directions);
    }
    return [];
  }, [sortFields, data]);

  return (
    <div>
      <ListSubheader className={classes.dialogListSubHeader}>
        {columns?.map((col, index) => {
          const columnLabel = keywordColumns[col].label;

          const isSortable = keywordColumns[col].isSortable;
          return (
            <div
              className={index === 0 ? Classnames(classes.dialogTabs, classes.firstDialogTab) : classes.dialogTabs}
              key={index}
            >
              {isSortable ? (
                <TableSortLabel
                  classes={{ icon: classes.sortIcon }}
                  active={!!sortFields[col]}
                  direction={sortFields[col] || undefined}
                  onClick={() => {
                    toggleSort(col);
                  }}
                >
                  {columnLabel}
                </TableSortLabel>
              ) : (
                columnLabel
              )}
            </div>
          );
        })}
      </ListSubheader>
      {sortedData?.map((keyword, index) => (
        <KeywordDialogListItem key={index} keyword={keyword} />
      ))}
    </div>
  );
};

export default connect(mapStateToProps, {})(KeywordDialogList);
