import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";

import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import agent from "../../../agent";
import { KEYWORD_GROUP_PAGES } from "../../../reducers/constants/actionTypes";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import KeywordGroupPageDialog from "./KeywordGroupPageDialog/KeywordGroupPageDialog";
import KeywordGroupPageList from "./KeywordGroupPageList";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarKeywordStrategyTypeFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarKeywordStrategyTypeFilter";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  isDialogOpen: state.keywordGroupPage.isDialogOpen,
  activeDialogTab: state.keywordGroupPage.activeDialogTab,
  searchTextFilter: state.keywordGroupPage.filter.search,
  sortField: state.keywordGroupPage.filter.sortField,
  total: state.keywordGroupPage.total,
  limit: state.keywordGroupPage.limit,
  page: state.keywordGroupPage.page,
  refetch: state.keywordGroupPage.refetch,
  funnelStageFilter: state.keywordGroupPage?.filter?.funnel_stages,
  selectedDataItemsIndexes: state.keywordGroupPage.selectedDataItemsIndexes,
});

const mapDispatchToProps = (dispatch) => ({
  loading() {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_DATA_LOADING, payload: true });
  },
  changeLoadedData(payload) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_DATA_LOADED, payload });
  },
  changeIsDialogOpen(open: boolean) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_IS_DIALOG_OPEN_CHANGED, payload: open });
  },
  changeActiveDialogTab(tab: "outlines" | "keywords" | "broadKeywords" | "pages") {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_ACTIVE_DIALOG_TAB_CHANGED, payload: tab });
  },
  changePage(page: number) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_PAGE_CHANGED, payload: page });
  },
  changeLimit(limit: number) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_LIMIT_CHANGED, payload: limit });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },

  onFilterChanged: (payload) => dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_FILTER_CHANGED, payload }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  searchTextFilter: string | null;
  total: number;
  limit: number;
  page: number;
  isDialogOpen: boolean;
  activeDialogTab: "outlines" | "keywords" | "pages";
  funnelStageFilter: string[];
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  onFilterChanged: (payload) => void;
  refetch: boolean;
  loading: () => void;
  changeLoadedData: (payload: any) => void;
  changeIsDialogOpen: (open: boolean) => void;
  changeActiveDialogTab: (tab: "outlines" | "keywords" | "pages") => void;
  changePage: (page: number) => void;
  changeLimit: (limit: number) => void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  selectedDataItemsIndexes: number[];
};

const KeywordGroupPage = (props: PropTypes) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    changeIsDialogOpen,
    changeActiveDialogTab,
    changeLoadedData,
    changeLimit,
    changePage,
    loading,
    changeSelectedDataItemsIndexes,
    searchTextFilter,
    funnelStageFilter,
    isDialogOpen,
    activeDialogTab,
    limit,
    total,
    page,
    sortField,
    refetch,
    selectedWebsite,
    onFilterChanged,
    selectedDataItemsIndexes,
  } = props;

  useEffect(
    () => () => {
      changeLimit(100);
      changePage(1);
      changeSelectedDataItemsIndexes([]);
    },
    []
  );

  useEffect(() => {
    loading();
    const sort = JSON.stringify({
      [sortField.field]: sortField.direction === "asc" ? "1" : "-1",
    });
    const funnelStage = funnelStageFilter.length > 0 ? funnelStageFilter.join(",") : undefined;

    agent.KeywordGroupPage.getPages(selectedWebsite._id, page, limit, sort, searchTextFilter, funnelStage)
      .then((res) => {
        changeLoadedData(res);
      })
      .catch((err) => {
        enqueueSnackbar(err?.message, { variant: "error" });
      });
  }, [selectedWebsite, limit, page, refetch, searchTextFilter, funnelStageFilter, sortField]);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t("meta_tags.strategy.pages.title")} />
      <MTableToolbar
        searchText={searchTextFilter}
        handleSearchTextChanged={(value) => onFilterChanged({ search: value })}
        selectedRows={selectedDataItemsIndexes?.length || 0}
        onClearSelectedRows={() => changeSelectedDataItemsIndexes([])}
      >
        <MTableToolbarKeywordStrategyTypeFilter
          postTypes={funnelStageFilter}
          handlePostTypesSelected={(value) => onFilterChanged({ funnel_stages: value })}
        />
      </MTableToolbar>
      <KeywordGroupPageList sortField={sortField} setSortField={(value) => onFilterChanged({ sortField: value })} />
      {total > 0 && (
        <MTablePagination
          paginationLimit={limit}
          page={page}
          paginationPagesCount={Math.ceil(total / limit)}
          setPage={changePage}
          setPaginationLimit={changeLimit}
        />
      )}
      <KeywordGroupPageDialog
        isStrategy
        open={isDialogOpen}
        close={() => {
          changeIsDialogOpen(false);
          changeActiveDialogTab("keywords");
        }}
        pageTab={activeDialogTab}
        setPageTab={changeActiveDialogTab}
      />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordGroupPage);
