import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Node } from "slate";
import MTextField from "../../../components/MTextField";
import { IWebsite } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: "100%",
    height: 37,
    padding: "0px 10px",
    margin: 0,
    justifyContent: "center",
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    "&:focus": {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
  },
  textInput: {
    padding: 0,
  },
}));

type PropTypes = {
  children?: React.ReactNode;
  element: any;
  selectedWebsite?: IWebsite;
  handleChange: (tocTitle: string) => void;
};

const TableOfContentsInput = (props: PropTypes) => {
  const classes = useStyles(props);
  const { handleChange, element } = props;
  const [localTocTitle, setLocalTocTitle] = React.useState(element.tocTitle || Node.string(element));

  const handleElementDataChange = (tocTitle) => {
    setLocalTocTitle(tocTitle);
    handleChange(tocTitle);
  };

  return (
    <MTextField
      inputClassName={classes.textInput}
      customClass={classes.textField}
      onChange={(value) => handleElementDataChange(value)}
      value={localTocTitle}
      placeholder={""}
    />
  );
};

export default TableOfContentsInput;
