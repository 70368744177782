import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { eAcfFieldType, eAcfType, IPage, IWebsite } from "../../../../reducers/constants/objectTypes";
import ACFTextInput from "./Components/ACFTextInput";
import ACFTextList from "./Components/ACFTextList";
import ACFSwitch from "./Components/ACFSwitch";
import _ from "lodash";
import ACFObjectList from "./Components/ACFObjectList";
import ACFTableOfContents from "./Components/ACFTableOfContents";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0px ",
    gap: 5,
  },
}));

type PropTypes = {
  page: IPage;
  selectedWebsite?: IWebsite;
  acf: any;
  acfTab: any;
  handleACFChange: (acf: any) => void;
};

const PageEditorACF = (props: PropTypes) => {
  const classes = useStyles(props);
  const { page, selectedWebsite, acf, acfTab, handleACFChange } = props;

  const getSwitchValue = (field) => {
    const value = _.get(acf, field.key);
    if (!field.trueValue) return value;
    return value === field.trueValue;
  };

  const getAcfFieldComponent = (field) => {
    switch (field.type) {
      case eAcfFieldType.Text:
        return (
          <ACFTextInput
            inputWidth={field.width}
            key={field.key}
            label={field.label}
            value={_.get(acf, field.key)}
            handleOnChange={(value) => {
              const newAcf = { ...acf };
              _.set(newAcf, field.key, value);
              handleACFChange(newAcf);
            }}
          />
        );
      case eAcfFieldType.List:
        return (
          <ACFTextList
            key={field.key}
            label={field.label}
            values={acf[field.key]}
            item={field.item}
            handleOnChange={(value) => handleACFChange({ ...acf, [field.key]: value })}
          />
        );
      case eAcfFieldType.Switch:
        return (
          <ACFSwitch
            checkBox={field.checkBox}
            key={field.key}
            label={field.label}
            value={getSwitchValue(field)}
            handleOnChange={(value) => {
              const newAcf = { ...acf };
              _.set(newAcf, field.key, field.trueValue ? (value ? field.trueValue : field.falseValue) : value);
              handleACFChange(newAcf);
            }}
          />
        );
      case eAcfFieldType.ObjectList:
        return (
          <ACFObjectList
            key={field.key}
            label={field.label}
            values={acf[field.key]}
            items={field.items}
            handleOnChange={(value) => handleACFChange({ ...acf, [field.key]: value })}
          />
        );
      case eAcfType.TableOfContents:
        return (
          <ACFTableOfContents
            key={field.key}
            values={acf[field.key]}
            page={page}
            items={field.items}
            handleOnChange={(value) => handleACFChange({ ...acf, [field.key]: value })}
          />
        );
      default:
        return null;
    }
  };

  return <div className={classes.wrapper}>{acfTab.fields.map((field) => getAcfFieldComponent(field))}</div>;
};

export default PageEditorACF;
