import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { COMMUNITY, COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import { ICategory, IQuestion, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import MSelect from "../../../../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  pageTopSection: {
    width: "100%",
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    marginBottom: 7,
  },
  select: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #E5E5E5",
    borderRadius: 4,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  categories: ICategory[];
  selectedQuestion: IQuestion;

  handleQuestionChange: Function;
};

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,
    selectedQuestion: state.communityEditor.question,
    categories: state.category.categories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleQuestionChange: (question) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_QUESTION_CHANGED, question }),
});

const CommunityEditorCategory = (props: PropTypes) => {
  const classes = useStyles(props);
  const { categories, selectedQuestion, handleQuestionChange } = props;

  return (
    <div className={classes.pageTopSection}>
      <Typography className={classes.title} variant={"body2"}>
        {I18n.t("edit_post.category")}
      </Typography>
      <MSelect
        customClass={classes.select}
        options={categories}
        value={_.find(categories, { _id: selectedQuestion.category?._id })}
        optionLabel={"name"}
        optionValue={"_id"}
        height={37}
        placeholder={I18n.t("edit_post.select_category")}
        borderColor={"transparent"}
        isBold={false}
        borderRadius={4}
        menuPlacement={"bottom"}
        fullWidth
        searchable
        getDisplayLabel={(topic) => {
          return `${topic.name} (${topic.questionsCount || 0})`;
        }}
        handleChange={(category) => {
          handleQuestionChange({ ...selectedQuestion, category });
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorCategory);
