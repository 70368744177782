import { KEYWORD_GROUP_PAGES } from "./constants/actionTypes";

const initialState = {
  dataList: [],
  dataLoading: false,
  selectedDataItem: null,
  selectedDataItemsIndexes: [],
  broadKeywords: [],
  total: 0,
  filter: {
    search: "",
    funnel_stages: [],
    sortField: { direction: "asc", field: "page" },
  },
  refresh: false,
  limit: 100,
  page: 1,
  isDialogOpen: false,
  activeDialogTab: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_DATA_LOADED:
      return {
        ...state,
        dataList: action.payload?.data,
        total: action.payload?.pagination?.total,
        dataLoading: false,
      };
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_DATA_LOADING:
      return {
        ...state,
        dataLoading: true,
      };
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
        page: 1,
      };
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_IS_DIALOG_OPEN_CHANGED:
      return {
        ...state,
        isDialogOpen: action.payload,
      };
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_ACTIVE_DIALOG_TAB_CHANGED:
      return {
        ...state,
        activeDialogTab: action.payload,
      };
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_SELECTED_DATA_ITEM_CHANGED:
      return {
        ...state,
        selectedDataItem: action.payload,
      };
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_DATA_SELECTED_ITEMS_CHANGED:
      return {
        ...state,
        selectedDataItemsIndexes: action.payload,
      };
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_REFETCH_CHANGED:
      return {
        ...state,
        refetch: action.payload,
      };
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_PAGE_CHANGED:
      return {
        ...state,
        page: action.payload,
      };
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_LIMIT_CHANGED:
      return {
        ...state,
        limit: action.payload,
      };
    case KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_BROAD_KEYWORDS_LOADED:
      return {
        ...state,
        broadKeywords: action.payload.data,
      };
    default:
      return state;
  }
};
