import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function CodeIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 35 18"}>
      <path d="M4.904,8.518 L9.919,3.508 C10.714,2.714 10.714,1.431 9.919,0.637 C9.123,-0.158 7.839,-0.158 7.044,0.637 L0.562,7.092 C-0.233,7.887 -0.233,9.170 0.562,9.964 L7.044,16.420 C7.839,17.214 9.123,17.214 9.919,16.420 C10.714,15.626 10.714,14.343 9.919,13.548 L4.904,8.518 ZM30.059,8.518 L25.044,13.528 C24.249,14.322 24.249,15.605 25.044,16.399 C25.839,17.194 27.124,17.194 27.919,16.399 L34.401,9.944 C35.196,9.149 35.196,7.866 34.401,7.072 L27.939,0.616 C27.144,-0.178 25.860,-0.178 25.065,0.616 C24.270,1.411 24.270,2.694 25.065,3.488 L30.059,8.518 Z" />
    </SvgIcon>
  );
}

export default CodeIcon;
