import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";

import addImage from "../../../../../img/add_image.png";
import InsertImageDialog from "../../../../Modals/InsertImageDialog/InsertImageDialog";
import { IFile, IWebsite } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ titlePosition }) => ({
    display: "flex",
    flexDirection: titlePosition === "bottom" ? "column-reverse" : "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 10,
  }),
  imagePickerWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: (props: PropTypes) => props.marginTop || 0,
    marginBottom: (props: PropTypes) => props.marginBottom || 0,
  },
  previewImagePlaceHolder: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minWidth: 120,
    minHeight: 120,
    borderRadius: "50%",
    backgroundImage: `url(${addImage})`,
  },
  title: {
    color: (props: PropTypes) => props.titleColor || theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    whiteSpace: "pre",
  },
  sizes: {
    marginLeft: 5,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  image: IFile;

  showSizes?: boolean;
  title?: string;
  titleColor?: string;
  titlePosition?: "top" | "bottom";
  imageWidth?: string | number;
  imageHeight?: string | number;
  marginTop?: number;
  marginBottom?: number;
  rounded?: boolean;

  onImageChanged: (image: IFile) => void;
};

const ExpertImagePicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const { selectedWebsite, image, title = null, showSizes = false, imageWidth = null, imageHeight = null } = props;
  const { onImageChanged } = props;

  return (
    <>
      <div onClick={(e) => setOpen(true)} className={classes.root}>
        {title && (
          <Typography className={ClassNames(classes.title)} variant={"subtitle2"}>
            {title}
            {showSizes && imageWidth && imageHeight && (
              <span className={classes.sizes}>{`${imageWidth}x${imageHeight}px`}</span>
            )}
          </Typography>
        )}
        <img
          className={ClassNames(classes.previewImagePlaceHolder)}
          src={
            image?.cdnUrl ||
            image?.url ||
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          }
          height={120}
          width={120}
          alt={image?.alt || ""}
        />
      </div>

      {open && (
        <InsertImageDialog
          website={selectedWebsite}
          selectedImageFile={image || null}
          handleInsertClick={(file: IFile) => {
            onImageChanged(file);
            setOpen(false);
          }}
          handleCloseClick={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default React.memo(ExpertImagePicker);
