import * as React from "react";
import { useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import { connect } from "react-redux";
import MTableHeader from "../../../components/Table/Table/MTableHeader";
import MTableHeaderSortLabel from "../../../components/Table/Table/MTableHeaderSortLabel";
import MTableHeaderLabel from "../../../components/Table/Table/MTableHeaderLabel";
import { IKeywordGroup } from "../../../reducers/constants/objectTypes";
import MTableRowEmptyRow from "../../../components/Table/Rows/MTableRowEmptyRow";
import MTableRowProgressBar from "../../../components/Table/Rows/MTableRowProgressBar";
import KeywordGroupItem from "./KeywordGroupItem";
import MTable from "../../../components/Table/Table/MTable";

type PropTypes = {
  sortField: any;
  setSortField: (input: any) => void;
  keywordGroupsTotal: number;
  keywordGroupsLoaded: boolean;
  keywordGroups: IKeywordGroup[];
  openKeywordDialog: (keywordGroup: IKeywordGroup, pageTab: boolean) => void;
};

const mapStateToProps = (state) => ({
  keywordGroupsLoaded: state.keywordGroups.keywordGroupsLoaded,
  keywordGroups: state.keywordGroups.keywordGroups,
  keywordGroupsTotal: state.keywordGroups.keywordGroupsTotal,
});

const KeywordGroupItemSubHeader = (props: {
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  setSortField: (input: any) => void;
  keywordGroupsTotal: number;
}) => {
  const theme: any = useTheme();

  return (
    <MTableHeader>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(21)}
        sortField={props.sortField}
        field={"clicks_main_keyword"}
      >
        {I18n.t("queries.groups.query_groups", { total: numeral(props.keywordGroupsTotal).format("0,0a") })}
      </MTableHeaderSortLabel>
      <MTableHeaderLabel> {I18n.t("queries.groups.var")}</MTableHeaderLabel>
      <MTableHeaderLabel> {I18n.t("queries.groups.pages")}</MTableHeaderLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"volume"}
      >
        {I18n.t("queries.groups.vol")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"clicks"}
      >
        {I18n.t("queries.groups.clicks")}
      </MTableHeaderSortLabel>

      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"impressions"}
      >
        {I18n.t("queries.groups.imp")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel onClick={(result) => props.setSortField(result)} sortField={props.sortField} field={"ctr"}>
        {I18n.t("queries.groups.ctr")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"position"}
      >
        {I18n.t("queries.groups.pos")}
      </MTableHeaderSortLabel>
    </MTableHeader>
  );
};

const KeywordGroupItemList = (props: PropTypes) => {
  const { sortField, setSortField, keywordGroupsTotal, keywordGroupsLoaded, keywordGroups, openKeywordDialog } = props;
  return (
    <MTable>
      <KeywordGroupItemSubHeader
        sortField={sortField}
        setSortField={setSortField}
        keywordGroupsTotal={keywordGroupsTotal}
      />
      {!keywordGroupsLoaded && <MTableRowProgressBar />}
      {keywordGroupsLoaded && keywordGroups?.length === 0 && <MTableRowEmptyRow />}
      {keywordGroupsLoaded &&
        keywordGroups?.length > 0 &&
        keywordGroups.map((kg, index) => (
          <KeywordGroupItem openKeywordDialog={openKeywordDialog} key={index} keywordGroup={kg} />
        ))}
    </MTable>
  );
};

export default connect(mapStateToProps)(KeywordGroupItemList);
