import * as React from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import { ePageState, IPage, IWebsite } from "../../../../reducers/constants/objectTypes";
import FieldDateDialog from "../../components/FieldDateDialog";
import { SECOND_EDIT_PAGES } from "../../../../reducers/constants/actionTypes";
import SecondEditPostItemRow from "./SecondEditPostItemRow";
import MTable from "../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";
import agent from "../../../../agent";
import { useSnackbar } from "notistack";
import WorkflowPostTypeDialog from "../../components/WorkflowPostTypeDialog";

type PropTypes = {
  selectedWebsite: IWebsite;
  postsLoaded: boolean;
  inProgress: IPage[];
  rejected: IPage[];
  publicPages: IPage[];
  takenByOthers: IPage[];
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  setSortField: (input: { direction: "desc" | "asc" | undefined; field: string }) => void;
  updatePost: (payload) => void;
  myReviewsCount: number;
  pendingCount: number;
  rejectedCount: number;
  takenByOthersCount: number;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  myReviewsCount: state.workFlowSecondEdit.myReviewsCount,
  pendingCount: state.workFlowSecondEdit.pendingCount,
  rejectedCount: state.workFlowSecondEdit.rejectedCount,
  takenByOthersCount: state.workFlowSecondEdit.takenByOthersCount,
  postsLoaded: state.workFlowSecondEdit.pagesLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  updatePost: (payload) => dispatch({ type: SECOND_EDIT_PAGES.PAGES_UPDATE_PAGE, payload }),
});

const WriterItemListSubHeader = (props: {
  title: string;
  topHeader: boolean;
  sortField?: any;
  setSortField?: (input: any) => void;
}) => {
  const theme: any = useTheme();
  return (
    <MTableHeader>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(21)}
        sortField={props.sortField}
        field={"title"}
      >
        {props.title}
      </MTableHeaderSortLabel>
      {props.topHeader && (
        <>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"dueDate"}
          >
            {I18n.t("workflow.writer.listHeader.due_date")}
          </MTableHeaderSortLabel>

          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"quotaDate"}
          >
            {I18n.t("workflow.writer.listHeader.quota_date")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"locale"}
          >
            {I18n.t("workflow.published.listHeader.locale")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"textWordsCount"}
          >
            {I18n.t("workflow.writer.listHeader.words")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel> {I18n.t("workflow.published.listHeader.vertical")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("workflow.writer.listHeader.category")}</MTableHeaderLabel>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"linksCount"}
          >
            {I18n.t("workflow.writer.listHeader.links")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel>{I18n.t("workflow.writer.listHeader.comments")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("workflow.writer.listHeader.optimization")} </MTableHeaderLabel>
          <MTableHeaderLabel columnWidth={"8%"}> {I18n.t("workflow.writer.listHeader.taken")}</MTableHeaderLabel>
        </>
      )}
    </MTableHeader>
  );
};
const SecondEditItemList = (props: PropTypes) => {
  const {
    postsLoaded,
    rejected,
    inProgress,
    publicPages,
    takenByOthers,
    takenByOthersCount,
    rejectedCount,
    pendingCount,
    myReviewsCount,
    setSortField,
    sortField,
    updatePost,
  } = props;
  const [post, setPost] = React.useState(null);
  const [dateField, setDateField] = React.useState(null);
  const [updateSection, setUpdateSection] = React.useState(null);
  const [changePostType, setChangePostType] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const insertUpdatedPost = (page) => {
    const index = _.findIndex(updateSection.pages, { _id: page?._id });
    if (index >= 0) {
      updateSection.pages[index] = page;
      updatePost({ pages: [...updateSection.pages], key: updateSection.key });
    }
    setUpdateSection(null);
  };

  const updatePostType = (type: string, post: IPage) => {
    if (type === post.type) return;
    agent.Pages.updatePostType(post._id, type)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: "error" });
          return;
        }
        enqueueSnackbar(res.message);
        insertUpdatedPost({ ...post, type });
      })
      .catch((e) => {
        enqueueSnackbar(e?.message, { variant: "error" });
        console.log(e);
      });
  };

  return (
    <MTable>
      {!postsLoaded && <MTableRowProgressBar />}

      {postsLoaded &&
        inProgress.length === 0 &&
        rejected.length === 0 &&
        publicPages.length === 0 &&
        takenByOthers.length === 0 && <MTableRowEmptyRow />}

      {postsLoaded && inProgress.length > 0 && (
        <WriterItemListSubHeader
          setSortField={setSortField}
          sortField={sortField}
          title={I18n.t("workflow.review.publish_reviews_count", { count: myReviewsCount })}
          topHeader
        />
      )}

      {postsLoaded &&
        inProgress.length > 0 &&
        inProgress.map((item, index) => (
          <SecondEditPostItemRow
            onDateUpdate={(field) => {
              setDateField(field);
              setPost(item);
              setUpdateSection({ key: "myReviews", pages: inProgress });
            }}
            onPostTypeUpdate={() => {
              setChangePostType(true);
              setPost(item);
              setUpdateSection({ key: "myReviews", pages: inProgress });
            }}
            key={item._id}
            post={item}
          />
        ))}

      {postsLoaded && takenByOthers.length > 0 && (
        <WriterItemListSubHeader
          // customerMode={customerMode}
          setSortField={setSortField}
          sortField={sortField}
          title={I18n.t("workflow.review.taken_others_count", { count: takenByOthersCount })}
          topHeader
        />
      )}
      {postsLoaded &&
        takenByOthers.length > 0 &&
        takenByOthers.map((item, index) => (
          <SecondEditPostItemRow
            onDateUpdate={(field) => {
              setDateField(field);
              setPost(item);
              setUpdateSection({ key: "takenByOthers", pages: takenByOthers });
            }}
            onPostTypeUpdate={() => {
              setChangePostType(true);
              setPost(item);
              setUpdateSection({ key: "takenByOthers", pages: takenByOthers });
            }}
            key={item._id}
            post={item}
            taken
          />
        ))}

      {postsLoaded && rejected.length > 0 && (
        <WriterItemListSubHeader
          setSortField={setSortField}
          sortField={sortField}
          title={I18n.t("workflow.review.post_rejected_count", { count: rejectedCount })}
          topHeader
        />
      )}
      {postsLoaded &&
        rejected.length > 0 &&
        rejected.map((item, index) => (
          <SecondEditPostItemRow
            onDateUpdate={(field) => {
              setDateField(field);
              setPost(item);
              setUpdateSection({ key: "rejected", pages: rejected });
            }}
            onPostTypeUpdate={() => {
              setChangePostType(true);
              setPost(item);
              setUpdateSection({ key: "rejected", pages: rejected });
            }}
            key={item._id}
            post={item}
            rejected
          />
        ))}

      {postsLoaded && publicPages.length > 0 && (
        <WriterItemListSubHeader
          setSortField={setSortField}
          sortField={sortField}
          title={I18n.t("workflow.review.post_for_review_count", { count: pendingCount })}
          topHeader
        />
      )}
      {postsLoaded &&
        publicPages.length > 0 &&
        publicPages.map((item, index) => (
          <SecondEditPostItemRow
            onDateUpdate={(field) => {
              setDateField(field);
              setPost(item);
              setUpdateSection({ key: "pendingReviews", pages: publicPages });
            }}
            onPostTypeUpdate={() => {
              setChangePostType(true);
              setPost(item);
              setUpdateSection({ key: "pendingReviews", pages: publicPages });
            }}
            key={item._id}
            post={item}
            publicRow
          />
        ))}
      {dateField && post && (
        <FieldDateDialog
          state={ePageState.secondEdit}
          field={dateField}
          post={post}
          onUpdate={(payload) => {
            setPost(null);
            setDateField(null);
            insertUpdatedPost(payload.page);
          }}
          onCancel={() => setPost(null)}
        />
      )}
      {changePostType && post && (
        <WorkflowPostTypeDialog
          post={post}
          open
          savePostType={(type) => {
            updatePostType(type, post);
            setChangePostType(false);
            setPost(null);
          }}
          closeDialog={() => {
            setChangePostType(false);
            setPost(null);
          }}
        />
      )}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondEditItemList);
