import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import LiteRichTextEditor from "../../../../../../../../../editor/elements/liteEditor/LiteRichTextEditor";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    minHeight: 55,
    width: "100%",
    zIndex: 1,
    padding: 15,
    fontSize: 14,
  },
  label: {
    fontSize: 14,
    color: palette.text.primary,
    padding: "15px 15px 0px 15px",
  },
  input: {
    fontSize: 14,
    fontWeight: typography.fontWeightLight as any,
    padding: 0,
    color: palette.text.secondary,
  },
  textField: {
    background: palette.primary.dark,
    width: "100%",
    resize: "none !important" as "none",
    minHeight: 126,
    maxHeight: 126,
    border: "none",
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    fontWeight: typography.fontWeightLight as any,
    display: "flex",
    alignItems: "center",
    color: "#9e9e9e",
    "&:focus": {
      outline: "none",
    },
  },
  editable: {
    maxHeight: 407,
    overflowY: "auto",
  },
}));

const emptyParagraph = JSON.stringify([{ type: "paragraph", children: [{ text: "" }] }]);

type PropTypes = {
  label?: string;
  value?: string;
  placeholder?: string;
  classNames?: { label?: string; textarea?: string };

  onChange(value): void;
};

const TextareaProperty = (props: PropTypes) => {
  const { label, placeholder, classNames = { label: "", textarea: "" }, value = emptyParagraph, onChange } = props;
  const classes = useStyles(props);

  return (
    <div>
      {label && (
        <Typography className={ClassNames(classNames.label, classes.label)} variant={"subtitle2"}>
          {label}
        </Typography>
      )}
      <div className={classNames.textarea}>
        <LiteRichTextEditor
          initialValue={value}
          editorPlaceHolder={placeholder ?? I18n.t("widgets_builder.widget_editor.Form.fields.properties.hint")}
          editableCustomClass={classes.editable}
          onValueChanged={onChange}
        />
      </div>
    </div>
  );
};

export default TextareaProperty;
