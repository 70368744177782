import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#fff",
    fontSize: 16,
  },
}));

type PropTypes = {
  className?: string;
};

const CommentIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 15 13"}>
      <path d="M7.31564 7.38652H8.43932V5.57539H10.2012V4.40631H8.43932V2.59519H7.31564V4.40631H5.55404V5.57539H7.31564V7.38652ZM0.919312 13V1.5257C0.919312 1.10748 1.0627 0.748588 1.34947 0.449016C1.63603 0.149672 1.98284 0 2.38992 0H13.3514C13.7585 0 14.1076 0.149672 14.3988 0.449016C14.6903 0.748588 14.836 1.10748 14.836 1.5257V8.47038C14.836 8.87901 14.6903 9.23312 14.3988 9.53269C14.1076 9.83203 13.7585 9.9817 13.3514 9.9817H3.85367L0.919312 13ZM2.38992 8.96937L3.56234 8.47038H13.3514V1.5257H2.38992V8.96937ZM2.38992 1.5257V8.47038V8.96937V1.5257Z" />
    </SvgIcon>
  );
};

export default CommentIcon;
