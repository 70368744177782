import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import KeywordGroupPageDialogHeader from "./KeywordGroupPageDialogHeader";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import { KEYWORD_GROUP_PAGES } from "../../../../reducers/constants/actionTypes";
import KeywordDialogList from "./KeywordsDialog/KeywordDialogList";
import { push } from "connected-react-router";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    padding: 0,
    "& .MuiDialogContent-root": {
      padding: 30,
    },
  },
  dialogContent: {
    overflowY: "hidden",
  },
  scrollPaper: {
    maxHeight: "100%",
  },
  list: {
    padding: "0",
  },
  listItems: {
    overflowY: "auto",
    height: "calc(100vh - 246px)",
  },
  footer: {
    borderTop: `${theme.palette.divider} solid 1px`,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    marginLeft: "auto",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  item: state.keywordGroupPage.selectedDataItem,
  refetch: state.keywordGroupPage.refetch,
});

const mapDispatch = (dispatch) => ({
  redirect: (url) => dispatch(push(url)),
  changeRefetch(refresh: boolean) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_REFETCH_CHANGED, payload: refresh });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: KEYWORD_GROUP_PAGES.ON_KEYWORD_GROUP_PAGES_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
});

type PropTypes = {
  item: any;
  isStrategy?: boolean;
  open: boolean;
  refetch: boolean;
  selectedWebsite: IWebsite;
  pageTab: "outlines" | "keywords" | "pages";

  redirect: (url: string) => void;
  setPageTab: (page: PropTypes["pageTab"]) => void;
  close: () => void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  changeRefetch: (refresh: boolean) => void;
};

const KeywordGroupPageDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { item, close, open, setPageTab, pageTab } = props;

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={(event) => close()}
      fullWidth
      maxWidth={"xl"}
      classes={{ paper: classes.dialogPaper, paperScrollPaper: classes.scrollPaper }}
    >
      <DialogContent className={classes.dialogContent}>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
            <KeywordGroupPageDialogHeader
              title={item?.page || ""}
              setPageTab={setPageTab}
              pageTab={pageTab}
              close={close}
            />

            <div className={classes.listItems}>
              {pageTab === "keywords" && (
                <KeywordDialogList item={item} setPageTab={setPageTab} pageTab={pageTab} broadKeyword={false} />
              )}
            </div>
          </List>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatch)(KeywordGroupPageDialog);
