import * as React from "react";
import { connect } from "react-redux";
import { ePageState, IPage, IPageCluster, IPageState, IWebsite } from "../../../reducers/constants/objectTypes";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import { ListItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import MPostActionTypography from "../../../components/MPostActionTypography";
import MPageStatusTypography from "../../../components/MPageStatusTypography";
import { colors } from "../../../helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    padding: "15px 0",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  listItemText: {
    margin: 0,
  },
  secondaryAction: {
    right: 0,
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  actionText: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    fontSize: 14,
    color: theme.palette.text.primary,
    "&:before": {
      content: '""',
      width: 7,
      height: 7,
      borderRadius: "50%",
      backgroundColor: "#71D0B8",
    },
  },
  itemPrimaryText: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  circle: {
    width: 7,
    height: 7,
    borderRadius: "50%",
    backgroundColor: "#71D0B8",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  pageLoaded: boolean;
  page: IPage;
  cluster: IPageCluster;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  pageLoaded: state.pageEditor.pageLoaded,
  page: state.pageEditor.page,
  cluster: state.pageEditor.pageCluster,
});

const mapDispatchToProps = (dispatch) => ({});

const PageEditorVersionHistory = (props: PropTypes) => {
  const classes = useStyles(props);
  const { pageLoaded, page, cluster, selectedWebsite } = props;

  if (!pageLoaded) {
    return null;
  }

  const history = React.useMemo(() => {
    const states = cluster.pageHistory?.[page.variant]?.[page.locale] || [];
    states.sort((x, y) => (moment(x.date).isSameOrAfter(moment(y.date)) ? -1 : 1));
    return states;
  }, [selectedWebsite, cluster, page]);

  return (
    <List dense>
      {history.map((progress) => (
        <ListItem key={progress._id} className={classes.listItem}>
          <ListItemText className={classes.listItemText}>
            <Typography variant={"body1"} className={classes.itemPrimaryText}>
              {`${moment(progress.date).format("llll")}`}
            </Typography>
            <Typography className={classes.text}>{progress.actionUser?.name}</Typography>
            <MPostActionTypography action={progress.action} />
          </ListItemText>
          <ListItemSecondaryAction
            classes={{
              root: classes.secondaryAction,
            }}
          >
            <MPageStatusTypography currentState={progress} reviewSteps={selectedWebsite.reviews} />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorVersionHistory);
