import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

import { IWidget } from "../../../../../../../reducers/constants/objectTypes";
import AddButton from "../../../components/AddButton";
import MSwitch from "../../../../../../../components/MSwitch";
import MDynamicListItem from "../../../../../../../components/MDynamicListItem";

const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
  },
  switch: {
    marginRight: 0,
  },
}));

type PropTypes = {
  addWidgetItem(): void;
  editWidgetItems(items: IWidget["items"]): void;
  onItemSelect(index: number): void;
  deleteWidgetItem(index: number): void;
  widget: IWidget;
};

const WidgetItemList = (props: PropTypes) => {
  const classes = useStyles(props);

  const { widget } = props;
  const { addWidgetItem, editWidgetItems, onItemSelect, deleteWidgetItem } = props;

  const onDragEnd = ({ destination, source }: DropResult) => {
    // If the item was not dropped in a valid droppable area, return early
    if (!destination) {
      return;
    }

    // Reorder the fields array based on the drag and drop result
    const newItems = Array.from(widget.items);
    const movedItem = newItems.splice(source.index, 1)[0];
    newItems.splice(destination.index, 0, movedItem);
    editWidgetItems(newItems);
  };

  return (
    <div className={classes.root}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="widget-items-list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {widget.items.map((item, index) => (
                <Draggable
                  draggableId={`widget-item-${item._id ?? item.itemId}`}
                  index={index}
                  key={`widget-item-${item._id ?? item.itemId}`}
                >
                  {(draggableProvided, snapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <MDynamicListItem
                        // rightIconsComponent={
                        //   <MSwitch
                        //     checked={item.visible}
                        //     onChange={(checked) =>
                        //       editWidgetItems(
                        //         widget.items.map((i, idx) => (idx === index ? { ...i, visible: checked } : i))
                        //       )
                        //     }
                        //     onClick={(e) => e.stopPropagation()}
                        //     className={classes.switch}
                        //   />
                        // }
                        isDraggable={true}
                        isDragging={snapshot.isDragging}
                        key={item._id ?? item.itemId}
                        title={
                          item.product?.name ??
                          `${I18n.t(`widgets_builder.widget_editor.${widget.subtype}.item_title`)} ${index + 1}`
                        }
                        onClick={() => onItemSelect(index)}
                        onDelete={widget.items.length > 1 ? () => deleteWidgetItem(index) : null}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <AddButton text={I18n.t(`widgets_builder.widget_editor.add`)} onClick={addWidgetItem} />
    </div>
  );
};

export default WidgetItemList;
