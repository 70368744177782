import { KEYWORD_CLASS_LEVELS } from "../helpers/constants";
import { KEYWORDCLASSIFICATION } from "./constants/actionTypes";

const initialState = {
  data: [],
  selectedKeywordsClassificationList: [],
  filter: {
    searchText: "",
    selectedWordsCount: 0,
    selectedType: "",
    selectedLevel: "",
    sortField: {
      direction: "desc",
      field: "count",
    },
  },
  totalCount: 0,
  loading: false,
  levels: KEYWORD_CLASS_LEVELS.map((level, index) => ({
    _id: index,
    name: level,
  })),
  page: 1,
  paginationNumber: "100",
  searchText: "",
  typeSearchText: "",
  selectedType: "",
  selectedLevel: "",
  selectedTopic: undefined,
  selectedWordsCount: 0,
  tags: [
    { _id: 1, name: "Yes" },
    { _id: 2, name: "No" },
  ],
  types: [],
  sortConfig: {
    count: "desc",
  },
  isAddKeywordsDialogOpen: false,
  isNgramDialogOpen: false,
  wordCounts: [
    { value: 1, name: "One word" },
    { value: 2, name: "Two words" },
    { value: 3, name: "Three words" },
    { value: 4, name: "Four words" },
  ],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case KEYWORDCLASSIFICATION.KEYWORDS_CLASSIFICATION_LOADED:
      return {
        ...state,
        data: action.payload.data,
        totalCount: action.payload.metadata.totalCount,
        loading: false,
      };

    case KEYWORDCLASSIFICATION.LOADING:
      return {
        ...state,
        loading: true,
      };
    case KEYWORDCLASSIFICATION.CHANGE_PAGINATION_NUMBER:
      return {
        ...state,
        paginationNumber: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_SELECTED_TYPE:
      return {
        ...state,
        selectedType: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_SELECTED_LEVEL:
      return {
        ...state,
        selectedLevel: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_SELECTED_TOPIC:
      return {
        ...state,
        selectedTopic: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_SELECTED_WORD_COUNT:
      return {
        ...state,
        selectedWordsCount: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_TYPES:
      return {
        ...state,
        types: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_WORD_COUNTS:
      return {
        ...state,
        wordCounts: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_TYPE_SEARCH_TEXT:
      return {
        ...state,
        typeSearchText: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_SELECTED_KEYWORDS_CLASSIFICATION_LIST:
      return {
        ...state,
        selectedKeywordsClassificationList: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_NGRAM_DIALOG_OPEN:
      return {
        ...state,
        isNgramDialogOpen: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_ADD_KEYWORDS_DIALOG_OPEN:
      return {
        ...state,
        isAddKeywordsDialogOpen: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_SORT_CONFIG:
      return {
        ...state,
        sortConfig: action.payload,
      };
    case KEYWORDCLASSIFICATION.CHANGE_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
