import { HEADING_CLUSTER } from "./constants/actionTypes";

const initialState = {
  headingClusters: [],
  loading: false,
  searchText: "",
  page: 1,
  limit: 20,
  total: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HEADING_CLUSTER.HEADING_CLUSTER_LOADED:
      return {
        ...state,
        headingClusters: action.payload,
        loading: false,
      };
    case HEADING_CLUSTER.HEADING_CLUSTER_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case HEADING_CLUSTER.HEADING_CLUSTER_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        searchText: action.text,
      };
    case HEADING_CLUSTER.HEADING_CLUSTER_PAGE_CHANGED: {
      return {
        ...state,
        page: action.page,
      };
    }
    case HEADING_CLUSTER.HEADING_CLUSTER_LIMIT_CHANGED: {
      return {
        ...state,
        limit: action.limit,
      };
    }
    case HEADING_CLUSTER.HEADING_CLUSTER_TOTAL_CHANGED: {
      return {
        ...state,
        total: action.total,
      };
    }
    default:
      return state;
  }
};
