<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.16663 18C1.76663 18 1.41663 17.85 1.11663 17.55C0.816626 17.25 0.666626 16.9 0.666626 16.5V1.5C0.666626 1.1 0.816626 0.75 1.11663 0.45C1.41663 0.15 1.76663 0 2.16663 0H7.39163C7.60829 0 7.78746 0.0708334 7.92913 0.2125C8.07079 0.354167 8.14163 0.533333 8.14163 0.75C8.14163 0.966667 8.07079 1.14583 7.92913 1.2875C7.78746 1.42917 7.60829 1.5 7.39163 1.5H2.16663V16.5H17.1666V11.275C17.1666 11.0583 17.2375 10.8792 17.3791 10.7375C17.5208 10.5958 17.7 10.525 17.9166 10.525C18.1333 10.525 18.3125 10.5958 18.4541 10.7375C18.5958 10.8792 18.6666 11.0583 18.6666 11.275V16.5C18.6666 16.9 18.5166 17.25 18.2166 17.55C17.9166 17.85 17.5666 18 17.1666 18H2.16663ZM6.69163 11.975C6.55829 11.825 6.48746 11.65 6.47913 11.45C6.47079 11.25 6.54163 11.075 6.69163 10.925L16.1166 1.5H11.3916C11.175 1.5 10.9958 1.42917 10.8541 1.2875C10.7125 1.14583 10.6416 0.966667 10.6416 0.75C10.6416 0.533333 10.7125 0.354167 10.8541 0.2125C10.9958 0.0708334 11.175 0 11.3916 0H17.9166C18.1333 0 18.3125 0.0708334 18.4541 0.2125C18.5958 0.354167 18.6666 0.533333 18.6666 0.75V7.275C18.6666 7.49167 18.5958 7.67083 18.4541 7.8125C18.3125 7.95417 18.1333 8.025 17.9166 8.025C17.7 8.025 17.5208 7.95417 17.3791 7.8125C17.2375 7.67083 17.1666 7.49167 17.1666 7.275V2.575L7.74163 12C7.60829 12.1333 7.43746 12.2 7.22913 12.2C7.02079 12.2 6.84163 12.125 6.69163 11.975Z" fill="#808080" />
</svg>

import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        fill: theme.palette.text.secondary,
        height: 18,
        width: 19,
    },
}));

type PropTypes = {
    className?: string;
};

const OpenInEditor = (props: PropTypes) => {
    const classes = useStyles(props);
    const { className } = props;

    return (
        <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 19 18"}>
            <path d="M17.1666 0C17.5666 0 17.9166 0.150002 18.2166 0.450001C18.5166 0.75 18.6666 1.1 18.6666 1.5V16.5C18.6666 16.9 18.5166 17.25 18.2166 17.55C17.9166 17.85 17.5666 18 17.1666 18H11.9416C11.725 18 11.5458 17.9292 11.4041 17.7875C11.2625 17.6458 11.1916 17.4667 11.1916 17.25C11.1916 17.0333 11.2625 16.8542 11.4041 16.7125C11.5458 16.5708 11.725 16.5 11.9416 16.5H17.1666V1.5H2.16663V6.725C2.16663 6.94167 2.09579 7.12083 1.95413 7.2625C1.81246 7.40417 1.63329 7.475 1.41663 7.475C1.19996 7.475 1.02079 7.40417 0.879126 7.2625C0.737459 7.12083 0.666626 6.94167 0.666626 6.725V1.5C0.666626 1.1 0.816628 0.75 1.11663 0.450001C1.41663 0.150002 1.76663 0 2.16663 0H17.1666Z" />
            <path d="M0.876493 17.733C0.745218 17.5855 0.67548 17.4134 0.667274 17.2166C0.659069 17.0199 0.728809 16.8478 0.876493 16.7002L10.156 7.42976H5.50394C5.29061 7.42976 5.11421 7.36008 4.97473 7.22074C4.83525 7.0814 4.76551 6.90517 4.76551 6.69205C4.76551 6.47894 4.83525 6.30271 4.97473 6.16336C5.11421 6.02402 5.29061 5.95435 5.50394 5.95435H11.9282C12.1415 5.95435 12.3179 6.02402 12.4574 6.16336C12.5969 6.30271 12.6666 6.47894 12.6666 6.69205V13.1101C12.6666 13.3232 12.5969 13.4994 12.4574 13.6388C12.3179 13.7781 12.1415 13.8478 11.9282 13.8478C11.7149 13.8478 11.5385 13.7781 11.399 13.6388C11.2595 13.4994 11.1898 13.3232 11.1898 13.1101V8.48713L1.91028 17.7576C1.77901 17.8888 1.61081 17.9543 1.40569 17.9543C1.20058 17.9543 1.02418 17.8806 0.876493 17.733Z" 
         />
        </SvgIcon >
    );
};

export default OpenInEditor;
