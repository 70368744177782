import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import AdsList from "./components/AdsList";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import { ADS } from "../../../../reducers/constants/actionTypes";
import agent from "../../../../agent";
import MTableToolbar from "../../../../components/Table/Toolbar/MTableToolbar";
import MTextButton from "../../../../components/MTextButton";
import MTableToolbarSearchConsoleIntervalFilter from "../../../../components/Table/Toolbar/Filters/MTableToolbarSearchConsoleIntervalFilter";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";

type PropTypes = {
  selectedWebsite: IWebsite;
  adsSearchText: string;
  searchConsoleInterval:
    | "today"
    | "lastWeek"
    | "last2Weeks"
    | "lastMonth"
    | "last2Months"
    | "last3Months"
    | "last6Months"
    | "lastYear";

  handleSearchTextChanged: Function;
  searchConsoleIntervalChanged: Function;
  handleNewAdCreated: Function;
};
const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  adsSearchText: state.ads.adsSearchText,
  searchConsoleInterval: state.ads.searchConsoleInterval,
});

const mapDispatchToProps = (dispatch) => ({
  handleSearchTextChanged: (searchText) => {
    dispatch({ type: ADS.ADS_LIST_SEARCH_TEXT_CHANGED, searchText });
  },
  searchConsoleIntervalChanged: (interval) =>
    dispatch({ type: ADS.ADS_LIST_SEARCH_CONSOLE_INTERVAL_CHANGED, interval }),
  handleNewAdCreated: (ad) => dispatch({ type: ADS.ADS_LIST_AD_CREATED, ad }),
});
const AdsListPage = (props: PropTypes) => {
  const {
    selectedWebsite,
    searchConsoleInterval,
    adsSearchText,
    searchConsoleIntervalChanged,
    handleSearchTextChanged,
    handleNewAdCreated,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const createNewAd = () => {
    agent.Ads.newAd(selectedWebsite._id, adsSearchText)
      .then((res) => {
        enqueueSnackbar(I18n.t("snackbar.update_success"));
        handleNewAdCreated(res.ad);
        handleSearchTextChanged("");
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.cro.ads.title`)} />
      <MTableToolbar
        searchText={adsSearchText}
        searchPlaceHolder={I18n.t("ads.ads_list.search_ph")}
        handleSearchTextChanged={(value) => handleSearchTextChanged(value)}
      >
        {adsSearchText && <MTextButton text={I18n.t("ads.ads_list.new_ad")} onClick={() => createNewAd()} />}
        <MTableToolbarSearchConsoleIntervalFilter
          interval={searchConsoleInterval}
          handleIntervalChange={(value) => searchConsoleIntervalChanged(value)}
        />
      </MTableToolbar>
      <AdsList />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsListPage);
