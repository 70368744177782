import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import * as React from "react";
import ReportTypeFilter from "../../components/filters/ReportTypeFilter";
import TotalUniqueFilter from "../../components/filters/TotalUniqueFilter";
import TimePeriodFilter from "../../components/filters/TimePeriodFilter";
import TimeFrameFilter from "../../components/filters/TimeFrameFilter";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import ColumnFilter from "../../components/filters/ColumnFIlter";
import BreakdownFilter from "../../components/filters/BreakdownFilter";
import EventFilter from "../../components/filters/EventFilter";

const useStyles = makeStyles((theme: Theme) => ({
  innerWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 65px)",
    marginBottom: 20,
  },
  reportsDashboardWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    margin: 20,
    position: "relative",
    height: "100%",
  },
  reportsHeader: {
    padding: 15,
    display: "flex",
    justifyContent: "space-between",
  },
  headerReportPicker: {
    display: "flex",
    gap: 15,
    alignItems: "center",
  },
  headerReportTimeWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  dropdownWrapper: {
    padding: "3px 10px",
  },
}));

type Props = {
  selectedWebsite: IWebsite;
  selectedReport: string;
  setSelectedReport: (selectedReport: string) => void;
  selectedBreakdown: any;
  setSelectedBreakdown: (selectedBreakdown: any) => void;
  breakdownValue: string;
  setBreakdownValue: (value: string) => void;
  selectedEvent: any;
  setSelectedEvent: (selectedEvent: any) => void;
  selectedCountFilter: any;
  setSelectedCountFilter: (selectedCountFilter: any) => void;
  selectedTimePeriod: any;
  setSelectedTimePeriod: (selectedTimePeriod: any) => void;
  selectedTimeFrame: any;
  setSelectedTimeFrame: (selectedTimeFrame: any) => void;
};

const PageEventsReportHeader = (props: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | boolean>(false);

  const {
    selectedWebsite,
    selectedReport,
    setSelectedReport,
    selectedBreakdown,
    setSelectedBreakdown,
    breakdownValue,
    setBreakdownValue,
    selectedEvent,
    setSelectedEvent,
    selectedCountFilter,
    setSelectedCountFilter,
    selectedTimePeriod,
    setSelectedTimePeriod,
    selectedTimeFrame,
    setSelectedTimeFrame,
  } = props;

  return (
    <div className={classes.reportsHeader}>
      <div className={classes.headerReportPicker}>
        <ReportTypeFilter value={selectedReport} handleOnChange={setSelectedReport} />
        <BreakdownFilter
          value={selectedBreakdown}
          handleChange={(v) => {
            if (v.value.field !== selectedBreakdown.value.field) {
              console.log("setBreakdownValue null", v, selectedBreakdown);
              setBreakdownValue(null);
            }
            setSelectedBreakdown(v);
          }}
        />
        <ColumnFilter
          selectedWebsite={selectedWebsite}
          table={selectedBreakdown.value.table}
          column={selectedBreakdown.value.field}
          value={breakdownValue}
          handleChange={(url) => {
            console.log("setBreakdownValue select", url);
            setBreakdownValue(url);
            //  setTriggerRefresh(!triggerRefresh);
          }}
        />
        <EventFilter selectedWebsite={selectedWebsite} handleChange={setSelectedEvent} value={selectedEvent} />
        <TotalUniqueFilter value={selectedCountFilter} handleOnChange={setSelectedCountFilter} />
      </div>
      <div className={classes.headerReportTimeWrapper}>
        <TimePeriodFilter value={selectedTimePeriod} handleOnChange={setSelectedTimePeriod} />
        <TimeFrameFilter value={selectedTimeFrame} handleOnChange={setSelectedTimeFrame} />
      </div>
    </div>
  );
};

export default PageEventsReportHeader;
