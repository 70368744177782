import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 35,
    height: 35,
  },
}));

type PropTypes = {
  className?: string;
};

const EmbedProductIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0  35 35"} fill="none">
      <rect width="35" height="35" rx="3" fill="#0298C8" />
      <path
        d="M17.5 21.1875L21.465 24.0938C21.66 24.2396 21.8496 24.2448 22.0338 24.1094C22.2179 23.974 22.2775 23.7917 22.2125 23.5625L20.62 18.8438L24.5525 16.1562C24.7475 16.0104 24.8125 15.8333 24.7475 15.625C24.6825 15.4167 24.5308 15.3125 24.2925 15.3125H19.4825L17.955 10.4375C17.89 10.2083 17.7437 10.0938 17.5162 10.0938C17.2887 10.0938 17.1317 10.2083 17.045 10.4375L15.485 15.3125H10.675C10.4367 15.3125 10.285 15.4167 10.22 15.625C10.155 15.8333 10.22 16.0104 10.415 16.1562L14.3475 18.8438L12.7875 23.5938C12.7225 23.8229 12.7821 24 12.9662 24.125C13.1504 24.25 13.34 24.2396 13.535 24.0938L17.5 21.1875ZM17.5 30C15.6583 30 13.9467 29.6823 12.365 29.0469C10.7833 28.4115 9.4075 27.5312 8.2375 26.4062C7.0675 25.2812 6.15208 23.9583 5.49125 22.4375C4.83042 20.9167 4.5 19.2708 4.5 17.5C4.5 15.75 4.83042 14.1146 5.49125 12.5938C6.15208 11.0729 7.0675 9.75 8.2375 8.625C9.4075 7.5 10.7833 6.61458 12.365 5.96875C13.9467 5.32292 15.6583 5 17.5 5C19.32 5 21.0208 5.32292 22.6025 5.96875C24.1842 6.61458 25.56 7.5 26.73 8.625C27.9 9.75 28.8208 11.0729 29.4925 12.5938C30.1642 14.1146 30.5 15.75 30.5 17.5C30.5 19.2708 30.1642 20.9167 29.4925 22.4375C28.8208 23.9583 27.9 25.2812 26.73 26.4062C25.56 27.5312 24.1842 28.4115 22.6025 29.0469C21.0208 29.6823 19.32 30 17.5 30ZM17.5 28.125C20.6417 28.125 23.2687 27.1094 25.3812 25.0781C27.4937 23.0469 28.55 20.5208 28.55 17.5C28.55 14.4792 27.4937 11.9531 25.3812 9.92188C23.2687 7.89062 20.6417 6.875 17.5 6.875C14.3583 6.875 11.7312 7.89062 9.61875 9.92188C7.50625 11.9531 6.45 14.4792 6.45 17.5C6.45 20.5208 7.50625 23.0469 9.61875 25.0781C11.7312 27.1094 14.3583 28.125 17.5 28.125Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default EmbedProductIcon;
