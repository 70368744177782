import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { IWebsite } from "../reducers/constants/objectTypes";
import { clients } from "../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  tabsRoot: {
    borderLeft: `solid 1px ${theme.palette.divider}`,
    paddingLeft: 1,
  },
  tabWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    color: theme.palette.text.secondary,
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main,
    height: 5,
  },
  tabScrollButtons: {
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
  },
  tabRoot: {
    borderRight: `solid 1px ${theme.palette.divider}`,
  },
  tabSelected: {
    color: theme.palette.primary.main,
    "& $tabWrapper": {
      color: theme.palette.primary.main,
    },
  },
  tabLocale: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(18),
  },
  tabState: {
    marginLeft: 10,
    textTransform: "capitalize",
  },
}));

type PropTypes = {
  selectedLocale: string;
  selectedWebsite: IWebsite;

  getTabName?: Function;
  getTabURL?: Function;
  handleLocaleChange: Function;
};

const LocaleTabs = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, selectedLocale, getTabName, getTabURL, handleLocaleChange } = props;

  return (
    <Tabs
      value={selectedLocale || "en"}
      onChange={(l, value) => {
        handleLocaleChange(value);
      }}
      variant="scrollable"
      scrollButtons="auto"
      classes={{
        root: classes.tabsRoot,
        indicator: classes.tabsIndicator,
        scrollButtons: classes.tabScrollButtons,
      }}
    >
      {selectedWebsite.locales.map((websiteLocale) => {
        return (
          <Tab
            key={websiteLocale}
            value={websiteLocale}
            disableRipple
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            label={
              <div className={ClassNames(classes.tabWrapper)}>
                <span className={classes.tabLocale}>{websiteLocale}</span>
                <span className={classes.tabState}>{getTabName ? getTabName(websiteLocale) : "Add"}</span>
                {getTabURL && getTabURL(websiteLocale) && (
                  <Link
                    target={"_blank"}
                    rel="noopener"
                    href={getTabURL(websiteLocale)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconButton edge="end" aria-label="delete">
                      <OpenInNewIcon />
                    </IconButton>
                  </Link>
                )}
              </div>
            }
          />
        );
      })}
    </Tabs>
  );
};

export default LocaleTabs;
