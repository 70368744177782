import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Divider, FormControlLabel, Input, InputAdornment, TextField } from "@material-ui/core";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import _ from "lodash";
import CfButton from "./CfButton";
import SearchIcon from "../icons/SearchIcon";
import classNames from "classnames";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "100%",
    padding: "10px 0px",
  },
  container: {
    padding: "10px 20px",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    minWidth: 300,
    maxWidth: 400,
  },
  options: {
    marginTop: 10,
    maxHeight: 250,
    overflowY: "auto",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 15,
    marginBottom: 5,
  },
  searchField: {
    padding: "8px 0px 8px 0px",
    lineHeight: "16.8px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.primary,
    "&:after": {
      border: "none !important",
    },
    "&:before": {
      borderBottom: `1px solid ${theme.palette.secondary.light} !important`,
    },
  },
  input: {
    padding: 0,
    color: theme.palette.text.primary,
    "&::placeholder": {
      color: theme.palette.text.secondary,
    },
  },
  checkboxContainer: {
    flex: 1,
    alignItems: "center",
    padding: 0,
    margin: 0,
  },
  checkbox: {
    padding: 0,
  },
  checkboxIcon: {
    height: 20,
    width: 20,
    fill: theme.palette.text.secondary,
    marginRight: 7,
  },
  checkedCheckboxIcon: {
    fill: theme.palette.primary.main,
  },
  buttonContainer: {
    borderTop: `1px solid ${theme.palette.secondary.dark}`,
    display: "flex",
    padding: "10px 20px",
    paddingTop: 15,
  },
}));

interface Option {
  [key: string]: string | number | boolean;
  value: string;
  id: string;
}

type PropTypes<T extends Option> = {
  classes?: {
    container?: string;
    options?: string;
  };
  buttonText: string;
  options: T[];
  searchFieldLabel: string;
  searchText: string;
  onSearchTextChange: (searchText: string) => void;
  onOptionSelect: (options: T[]) => void;
  onButtonClick: (e: any) => void;
  defaultValue?: any[];
};

const MultiSelectSearchDropdown = <T extends Option>(props: PropTypes<T>) => {
  const {
    searchFieldLabel,
    onOptionSelect,
    onSearchTextChange,
    options,
    searchText,
    onButtonClick,
    classes: outClasses = {},
    buttonText,
    defaultValue,
  } = props;

  const classes = useStyles(props);

  const [selectedOptions, setSelectedOptions] = React.useState<T[]>(defaultValue || []);

  const [searchTextInternal, setSearchTextInternal] = React.useState<string>(searchText);

  const hanldeOptionsSelect = (option: T, checked: boolean) => {
    let upatedSelectedOptions;
    if (checked) {
      upatedSelectedOptions = [...selectedOptions, option];
      setSelectedOptions(upatedSelectedOptions);
    } else {
      upatedSelectedOptions = selectedOptions.filter((o) => o !== option);
      setSelectedOptions(upatedSelectedOptions);
    }
    onOptionSelect(upatedSelectedOptions);
  };

  const handleSearchTextChangeDebounce = React.useCallback(
    _.debounce((searchText: string) => {
      onSearchTextChange(searchText);
    }, 500),
    []
  );

  const handleSearchTextChange = (searchText: string) => {
    setSearchTextInternal(searchText);
    handleSearchTextChangeDebounce(searchText);
  };

  return (
    <div className={classes.root}>
      <div className={classNames(classes.container, outClasses.container)}>
        <Input
          value={searchTextInternal}
          onChange={(e) => handleSearchTextChange(e.target.value)}
          className={classes.searchField}
          placeholder={searchFieldLabel}
          classes={{
            input: classes.input,
            colorSecondary: classes.input,
          }}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <div className={classNames(classes.options, outClasses.options)}>
          {options?.map((option, index) => (
            <FormControlLabel
              key={index}
              className={classes.checkboxContainer}
              control={
                <Checkbox
                  style={{ padding: 0 }}
                  className={classes.checkbox}
                  icon={<CircleUnchecked className={classes.checkboxIcon} />}
                  checkedIcon={
                    <CircleCheckedFilled className={classNames(classes.checkboxIcon, classes.checkedCheckboxIcon)} />
                  }
                  checked={!!selectedOptions.find((o) => o.id === option.id)}
                  onChange={(_, checked) => {
                    hanldeOptionsSelect(option, checked);
                  }}
                  key={index}
                  value={option}
                />
              }
              label={option.value}
            />
          ))}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <CfButton height={30} text={buttonText} onClick={onButtonClick} />
      </div>
    </div>
  );
};

export default MultiSelectSearchDropdown;
