import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { replace } from "connected-react-router";
import { delay } from "lodash";
import {
  ePostState,
  ePostType,
  eQuestionState,
  IWebsite,
  IWriterCommunityAnswer,
} from "../../../../../../reducers/constants/objectTypes";
import { COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import { AbilityContext } from "../../../../../../casl/can";
import agent from "../../../../../../agent";
import MSavedXMinutesAgo from "../../../../../../components/MSavedXMinutesAgo";
import MButtonGroup from "../../../../../../components/MButtonGroup";
import MAlertDialog from "../../../../../../components/MAlertDialog";
import { clientPages, clients, web } from "../../../../../../helpers/urlHelper";
import richTextEditorHelper from "../../../../../../editor/helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  postBottomBarWrapper: {
    zIndex: 3,
    height: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    borderTop: `${theme.palette.divider} solid 1px`,
    position: "sticky",
    bottom: 0,
  },
  postBottomBarInnerWrapper: {
    // width: 'calc(100% - 2*280px)',
    width: "100%",
    paddingRight: 30,
    paddingLeft: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    minHeight: 40,
  },
  discardButton: {
    marginRight: 20,
  },
  postTypesWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textCounter: {
    marginRight: 20,
  },
  buttonGroup: {
    margin: "0 -10px 0 -5px",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  writerAnswer: IWriterCommunityAnswer;

  returnToPrevious: (to: string) => void;
  questionSaved: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  writerAnswer: state.communityEditor.writerAnswer,
});

const mapDispatchToProps = (dispatch) => ({
  questionSaved: (writerAnswer) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_WRITER_ANSWER_CHANGED, writerAnswer }),
  returnToPrevious: (returnTo) => dispatch(replace(returnTo)),
});

const CommunityEditorAnswerBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, writerAnswer, returnToPrevious, questionSaved } = props;
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ability = React.useContext<any>(AbilityContext);

  const emptyRichText = JSON.stringify([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  const actions = [];
  if (
    (writerAnswer.state === ePostState.UNINITIALIZED ||
      writerAnswer.state === ePostState.NEW ||
      writerAnswer.state === ePostState.DRAFT ||
      writerAnswer.state === ePostState.REJECTED ||
      writerAnswer.state === ePostState.PUBLISH ||
      writerAnswer.state === ePostState.UPDATE ||
      writerAnswer.state === ePostState.INTERNAL_REVIEW ||
      writerAnswer.state === ePostState.DELETED) &&
    ability.can("publish", ePostType.COMMUNITY)
  ) {
    actions.push({
      key: "publish",
      label: I18n.t("edit_post.publish"),
    });
  }

  if (
    writerAnswer.state === ePostState.UNINITIALIZED ||
    writerAnswer.state === ePostState.NEW ||
    writerAnswer.state === ePostState.DRAFT ||
    writerAnswer.state === ePostState.REJECTED ||
    writerAnswer.state === ePostState.DELETED
  ) {
    actions.push({
      key: "save_draft",
      label: I18n.t("edit_post.save_draft"),
    });
  }

  if (writerAnswer.state === ePostState.INTERNAL_REVIEW) {
    actions.push({
      key: "save",
      label: I18n.t("edit_post.save"),
    });
  }

  if (
    (writerAnswer.state === ePostState.PUBLISHED ||
      writerAnswer.state === ePostState.PUBLISH ||
      writerAnswer.state === ePostState.UPDATE) &&
    ability.can("update", ePostType.COMMUNITY)
  ) {
    actions.push({
      key: "update",
      label: I18n.t("edit_post.update"),
    });
  }

  const validPost = (newState: ePostState) => {
    if (
      newState !== ePostState.PUBLISHED &&
      newState !== ePostState.PUBLISH &&
      newState !== ePostState.INTERNAL_REVIEW &&
      newState !== ePostState.UPDATE
    ) {
      return true;
    }
    let valid = true;
    if (!writerAnswer.richText || writerAnswer.richText === emptyRichText) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Title" }));
      valid = false;
    }

    if (!(writerAnswer.user || writerAnswer.writer || writerAnswer.creditedWriter)) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "User" }));
      valid = false;
    }

    if (!writerAnswer.postedTime) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Posted time" }));
      valid = false;
    }

    if (!writerAnswer.richText || writerAnswer.richText.length === 0) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Text" }));
      valid = false;
    }

    return valid;
  };

  const invalidateCache = (question) => {
    const questionUrl = clientPages.questionsPage(selectedWebsite, question);
    const url = clientPages.withCacheInvalidation(selectedWebsite, questionUrl);
    if (question.state === eQuestionState.PUBLISHED) {
      agent.Cache.questionInvalidation(selectedWebsite, question)
        .then((valRes) => {
          delay(() => {
            window.open(url, "_blank");
          }, 3000);
        })
        .catch((e) => {
          enqueueSnackbar(I18n.t("snackbar.skipping_invalidation"));
          window.open(url, "_blank");
        });
    }
  };
  const saveAnswer = (state) => {
    if (!validPost(state)) {
      return;
    }
    writerAnswer.state = state;
    writerAnswer.text = writerAnswer.richText ? richTextEditorHelper.serialize(JSON.parse(writerAnswer.richText)) : "";
    agent.Community.saveAnswer(writerAnswer)
      .then((res) => {
        enqueueSnackbar(I18n.t(`snackbar.post.save.${ePostType.COMMUNITY}`));
        invalidateCache(res.post);
        questionSaved(res.post);

        if (res.post.state !== ePostState.DRAFT) {
          returnToPrevious(web.community("writer"));
        }
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.post.save.error", { msg: e.response.body.message }));
      });
  };

  const onDiscardDraftClicked = () => {
    setDiscardDialogOpen(true);
  };

  const discardAnswer = () => {
    agent.Community.discardAnswer(
      false,
      writerAnswer,
      writerAnswer.state === ePostState.INTERNAL_REVIEW ? "editor" : "writer"
    )
      .then((res) => {
        enqueueSnackbar(I18n.t(`snackbar.post.discard.${ePostType.COMMUNITY}`));
        returnToPrevious(web.community("writer"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.post.discard.error", { msg: e.response.body.message }));
      });
  };

  const onDeleteClicked = () => {
    setDeleteDialogOpen(true);
  };

  const deleteAnswer = () => {
    agent.EditPost.deletePost(ePostType.COMMUNITY, writerAnswer._id)
      .then((res) => {
        enqueueSnackbar(I18n.t(`snackbar.post.delete.${ePostType.COMMUNITY}`));
        returnToPrevious(web.community("writer"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.writerAnswer.delete.error", { msg: e.response.body.message }));
      });
  };

  const handleButtonClick = (key: string) => {
    switch (key) {
      case "publish":
        saveAnswer(ePostState.PUBLISH);
        break;
      case "submit_for_writers":
        saveAnswer(ePostState.NEW);
        break;
      case "save_draft":
        saveAnswer(ePostState.DRAFT);
        break;
      case "save":
        saveAnswer(writerAnswer.state);
        break;
      case "delete":
        saveAnswer(ePostState.DELETED);
        break;
      case "discard":
        onDiscardDraftClicked();
        break;
      case "delete_forever":
        onDeleteClicked();
        break;
      case "reject":
        saveAnswer(ePostState.REJECTED);
        break;
      case "update":
        saveAnswer(ePostState.PUBLISH);
        break;
      case "submit_for_review":
        saveAnswer(ePostState.INTERNAL_REVIEW);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.postBottomBarWrapper}>
      <div className={classes.postBottomBarInnerWrapper}>
        <div className={classes.flexWrapper}>
          <MSavedXMinutesAgo lastSaveTime={writerAnswer.updatedAt} />
        </div>
        <div className={classes.flexWrapper}>
          {/* <MTextCounter */}
          {/*    wrapperClass={classes.textCounter} */}
          {/*    type={"word"} */}
          {/*    text={writerAnswer.text || ""} */}
          {/*    suffix={I18n.t("writers.write_answer.words")} */}
          {/* /> */}
          {actions[0] && (
            <MButtonGroup
              text={actions[0].label}
              onClick={() => handleButtonClick(actions[0].key)}
              options={actions}
              hasDivider={false}
              customClass={classes.buttonGroup}
              onMenuItemClick={(key: string) => handleButtonClick(key)}
            />
          )}
        </div>
      </div>
      {deleteDialogOpen && (
        <MAlertDialog
          open={deleteDialogOpen}
          title={I18n.t("edit_post.delete_dialog.title")}
          description={I18n.t("edit_post.delete_dialog.description")}
          agreeText={I18n.t("edit_post.delete_dialog.agree")}
          disagreeText={I18n.t("edit_post.delete_dialog.cancel")}
          handleClose={(agree) => {
            setDeleteDialogOpen(false);
            if (agree) {
              deleteAnswer();
            }
          }}
        />
      )}
      {discardDialogOpen && (
        <MAlertDialog
          open={discardDialogOpen}
          title={I18n.t("edit_writerAnswer.discard_dialog.title")}
          description={I18n.t("edit_writerAnswer.discard_dialog.description")}
          agreeText={I18n.t("edit_writerAnswer.discard_dialog.agree")}
          disagreeText={I18n.t("edit_writerAnswer.discard_dialog.cancel")}
          handleClose={(agree) => {
            setDiscardDialogOpen(false);
            if (agree) {
              discardAnswer();
            }
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorAnswerBottomBar);
