import { ListItem, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { ReactNode } from "react";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import CopyToClipboardIcon from "../../../../../../../../../icons/CopyToClipboardIcon";
import TrashCanIcon from "../../../../../../../../../icons/TrashCanIcon";
import ArrowRightRoundedIcon from "../../../../../../../../../icons/ArrowRightRoundedIcon";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => {
      const border = isDark ? `1px solid ${palette.grey["200"]}` : `1px solid ${palette.secondary.light}`;
      const backgroundColor = isDark ? "#343434" : palette.common.white;
      const backgroundColorOnHover = palette.action.hover;
      return {
        padding: 15,
        height: 55,
        minHeight: "fit-content",
        marginBottom: -1,
        flex: 1,
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "3px 3px 3px 3px",
        border,
        borderWidth: "1px 1px 1px 1px",
        backgroundColor,
        "&:hover": {
          backgroundColor: backgroundColorOnHover,
        },
      };
    },
    header: (props) => ({
      flex: 1,
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      width: "100%",
      cursor: "pointer",
      "&:hover $leftIcon": {
        visibility: "visible",
      },
    }),
    leftContainer: (props: PropTypes) => ({
      display: "flex",
      alignItems: "center",
      maxWidth: 300,
      flex: 1,
    }),
    icon: (props: PropTypes) => ({
      width: 30,
      height: 30,
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      marginRight: 10,
      borderRadius: 3,
    }),
    title: (props: PropTypes) => {
      const color = palette.text.primary;
      return {
        fontSize: 16,
        fontWeight: typography.fontWeightMedium,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: 150,
        color,
        marginLeft: 10,
      };
    },
    rightIcon: (props: PropTypes) => ({
      color: palette.secondary.main,
    }),
    rightContainer: (props: PropTypes) => ({
      display: "flex",
      flex: 1,
      justifySelf: "flex-end",
      justifyContent: "flex-end",
      alignItems: "center",
      marginRight: -20,
    }),
    leftIcon: (props: PropTypes) => {
      const color = isDark ? palette.text.secondary : palette.secondary.main;
      return {
        visibility: "hidden",
        boxSizing: "content-box",
        color,
        padding: 5,
      };
    },
    arrowIcon: () => {
      const color = isDark ? palette.text.primary : palette.secondary.main;
      return {
        visibility: "visible !important" as "visible",
        color,
      };
    },
    img: {
      height: 30,
      width: 30,
      userSelect: "none",
    },
  };
});

type PropTypes = {
  item: any;
  onClick?: (item: string, index: number) => void;
  onCopyClick?: (item: string) => void;
  onDeleteClick?: (index: number) => void;
  children?: ReactNode;
};

const DraggableItem = (props: PropTypes) => {
  const { onClick, item, onCopyClick, onDeleteClick } = props;
  const classes = useStyles(props);

  return (
    <ListItem className={classes.root} component={"div"}>
      <div className={classes.header} onClick={onClick}>
        <div className={classes.leftContainer}>
          <Typography className={`${classes?.title}`}>{item}</Typography>
        </div>

        <div className={classes.rightContainer}>
          <div onClick={onCopyClick}>
            <CopyToClipboardIcon className={classes.leftIcon} />
          </div>
          <div onClick={onDeleteClick}>
            <TrashCanIcon className={classes.leftIcon} />
          </div>
          <ArrowRightRoundedIcon className={ClassNames(classes.arrowIcon, classes.leftIcon)} />
        </div>
      </div>
    </ListItem>
  );
};

export default DraggableItem;
