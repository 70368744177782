import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
    
}));

type PropTypes = {
    className?: string;
};


const ArrowRightRoundedIcon = (props: PropTypes) => {
    const classes = useStyles(props);
    const { className } = props;

    return (
        <SvgIcon className={ClassNames(className)} viewBox={"0 0 18 14"}>
            <path d="M4.38215 6.5L0.442471 2.48747C0.0857554 2.12416 0.0857558 1.54208 0.442471 1.17877C0.80855 0.805925 1.40929 0.805925 1.77537 1.17877L6.65606 6.1497C6.84702 6.34419 6.84702 6.65581 6.65606 6.8503L1.77537 11.8212C1.40929 12.1941 0.808551 12.1941 0.442472 11.8212C0.0857567 11.4579 0.0857566 10.8758 0.442472 10.5125L4.38215 6.5Z"
            />
        </SvgIcon>
    );
};

export default ArrowRightRoundedIcon;
