import moment from "moment";
import omit from "lodash/omit";

const extractPostIdFromPermalink = (permalink) => {
  // https://m.facebook.com/groups/2033131390248153?view=permalink&id=2704279949799957&ref=group_browse
  // https://m.facebook.com/story/graphql_permalink/?graphql_id=UzpfSTEwMDAwMDI2NTg0NzU2MzpWSzoyMTYzNDIzOTQwNTUyMjMw'
  const postIdRegex = /id=(\d+)/g;
  const match = postIdRegex.exec(permalink);

  return match && match.length > 1 ? match[1] : null;
};

const extractGroupIdFromPermalink = (permalink) => {
  // https://m.facebook.com/groups/2033131390248153?refid=18&ref=group_browse&__tn__=C-R
  // https://m.facebook.com/groups/2033131390248153?view=permalink&id=2704279949799957&ref=group_browse
  const groupIdRegex = /\/groups\/(\d+)/g;
  const match = groupIdRegex.exec(permalink);

  return match && match.length > 1 ? match[1] : null;
};

const extractUserNameFromUserPermalink = (permalink) => {
  // user link types:
  // https://m.facebook.com/profile.php?groupid=2033131390248153&id=100004825535552&refid=18&ref=group_browse&__tn__=C-R',
  // https://m.facebook.com/dustin.reed.946?groupid=2033131390248153&refid=18&ref=group_browse&__tn__=C-R
  // With user name         ^^^^^^^^^^^^^^^

  const userNameRegex = /com\/([\w.]+)\?/g;
  const match = userNameRegex.exec(permalink);
  if (match && match.length > 1 && match[1] !== "profile.php") {
    return match[1];
  }
  return null;
};

const clearMetaFromName = (name) => {
  return name.replace(", profile picture", "");
};

const extractAttachmentsFromPost = (post) => {
  switch (post.type) {
    case "text":
      return null;
    case "album":
      return post.photo_list;
    case "photo":
      return post.photo_id;
    default:
      return null;
  }
};

const daysOfWeek = {
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sun: 7,
};

const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
  // return /^-{0,1}\d+$/.test(value);
};

const convertTextToDate = (dateStr) => {
  // post
  // Yesterday at 10:16 AM
  // 'February 25 at 5:38 AM'
  // 'March 22 at 6:20 PM'
  // 'March 8 at 9:49 PM'
  // comments
  // 1 mo
  // 'last Wed'
  // 'last Tue'
  // '3 wks'
  // '2 wks'
  // '1 wk'
  // 'last Tue'
  // 'last Wed'
  // 'on Sun'
  // 'on Thu'
  // 'on Sun'
  // '15 hrs'
  // '10 hrs'

  // const dateStr = 'February 25 at 5:38 AM';
  const time = moment(dateStr, "MMMM D at h:mm A");
  if (time.isValid()) {
    return time.startOf("day").toDate();
  }

  let date = null;
  const dateArray = dateStr.split(" ");

  if (isNumeric(dateArray[0])) {
    const number = parseInt(dateArray[0]);
    switch (dateArray[1]) {
      case "min":
      case "mins":
        date = moment().subtract(number, "minute");
        break;
      case "hrs":
      case "hr":
        date = moment().subtract(number, "hour");
        break;
      case "wk":
      case "wks":
        date = moment().subtract(number, "week");
        break;
      case "mo":
        date = moment().subtract(number, "month");
        break;
      case "yr":
      case "yrs":
        date = moment().subtract(number, "year");
        break;
    }
  } else if (dateArray[0] === "last") {
    if (daysOfWeek.hasOwnProperty(dateArray[1])) {
      date = moment().subtract(2, "week").isoWeekday(daysOfWeek[dateArray[1]]);
    }
  } else if (dateArray[0] === "on") {
    if (daysOfWeek.hasOwnProperty(dateArray[1])) {
      date = moment().subtract(1, "week").isoWeekday(daysOfWeek[dateArray[1]]);
    }
  } else if (dateArray[0] === "Just") {
    // just now
    date = moment();
  } else if (dateArray[0] === "Yesterday") {
    date = moment().subtract(1, "day");
  }

  return date ? date.startOf("day").toDate() : null;
};

const extractNumberFromText = (text) => {
  console.log(`extractNumberFromText ${text}`);
  if (!text) {
    console.log(`extractNumberFromText return ${0}`);
    return 0;
  }

  let number = text;
  try {
    // Tal Bar and 3.9K others
    console.log("split", text, text.split(" "));
    if (text.split(" ").length >= 2) {
      const numberRegex = /(\d+(\.\d+)?[kKmMbB]?)/g;
      const match = numberRegex.exec(text);
      if (match && match.length > 1) {
        console.log("match[1]", match[1]);
        number = match[1];
      }
    }
  } catch (e) {
    console.log("split error", e);
  }

  if (isNumeric(number)) {
    console.log(`extractNumberFromText return ${parseInt(number)}`);
    return parseInt(number);
  }

  console.log("abbriviate number", number.slice(0, -1));
  console.log("abbriviate number", isNumeric(+number.slice(0, -1)));
  if (isNumeric(+number.slice(0, -1))) {
    const num = +number.slice(0, -1);
    console.log("abbriviate number", num);
    console.log("abbriviate number", number.slice(-1).toLowerCase());
    switch (number.slice(-1).toLowerCase()) {
      case "k":
        console.log(`extractNumberFromText return ${num * 1000}`);
        return num * 1000;
      case "m":
        console.log(`extractNumberFromText return ${num * 1000000}`);
        return num * 1000000;
      case "b":
        console.log(`extractNumberFromText return ${num * 1000000000}`);
        return num * 1000000000;
    }
  }

  console.log(`extractNumberFromText return ${0}`);
  return 0;
};

const getQuestionFromEditedPost = (fbPost) => {
  if (fbPost.type === "question") {
    return omit(fbPost, ["comments", "replies", "tags", "topic"]);
  }

  for (const comment of fbPost.comments) {
    if (comment.type === "question") {
      return omit(comment, ["comments", "replies", "tags", "topic"]);
    }
    for (const reply of comment.replies) {
      if (reply.type === "question") {
        return omit(reply, ["comments", "replies", "tags", "topic"]);
      }
    }
  }

  return null;
};

const getAnswersFromEditedPost = (fbPost) => {
  const answers = [];
  if (fbPost.type === "answer") {
    answers.push(omit(fbPost, ["comments", "replies", "tags", "topic"]));
  }

  for (const comment of fbPost.comments) {
    if (comment.type === "answer") {
      answers.push(omit(comment, ["comments", "replies", "tags", "topic"]));
    }
    for (const reply of comment.replies) {
      if (reply.type === "answer") {
        answers.push(omit(reply, ["comments", "replies", "tags", "topic"]));
      }
    }
  }

  return answers;
};

const fallbackCopyTextToClipboard = (text, callback) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log(`Fallback: Copying text command was ${msg}`);
    callback && callback();
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

const copyStringToClipboard = (text, callback) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, callback);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      console.log("Async: Copying to clipboard was successful!");
      callback && callback();
    },
    (err) => {
      console.error("Async: Could not copy text: ", err);
    }
  );
};

const convertMobileToWebUrl = (url) => {
  if (!url) {
    return url;
  }
  if (url.indexOf("m.") > -1) {
    const newUrl = url.replace("m.", "");
    if (!newUrl.includes("graphql_permalink") && !newUrl.includes("view=permalink") && !newUrl.includes("id=")) {
      return newUrl.split("?")[0];
    }
    return newUrl;
  }
  if (!url.includes("graphql_permalink") && !url.includes("view=permalink") && !url.includes("id=")) {
    return url.split("?")[0];
  }
  return url;
};

export {
  copyStringToClipboard,
  extractPostIdFromPermalink,
  extractGroupIdFromPermalink,
  extractUserNameFromUserPermalink,
  clearMetaFromName,
  extractAttachmentsFromPost,
  convertTextToDate,
  extractNumberFromText,
  getQuestionFromEditedPost,
  getAnswersFromEditedPost,
  convertMobileToWebUrl,
};
