import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

import VideoItemRow from "./VideoItemRow";
import {
  IVideoProject,
  IWebsite,
  eVideoProjectAction,
  eVideoProjectState,
} from "../../../../reducers/constants/objectTypes";
import CfProgressBar from "../../../../components/CfProgressBar";
import { VIDEO_PROJECT } from "../../../../reducers/constants/actionTypes";
import ItemListSubHeader from "./ItemListSubHeader";
import agent from "../../../../agent";
import ability from "../../../../casl/ability";
import { web } from "../../../../helpers/urlHelper";

const useStyles = makeStyles(() => ({
  contentList: {
    paddingLeft: 0,
    maxWidth: "calc(100vw - 40px)",
  },
  root: {
    paddingTop: 0,
  },
}));

type PropTypes = {
  state?: eVideoProjectState;
  selectedWebsite: IWebsite;
  videoProjectsLoaded: boolean;
  videoProjects: IVideoProject[];
  totalItems: number;
  totalPages: number;
  page: number;
  limit: number;
  videoProjectsSortField: { direction: "desc" | "asc" | undefined; field: string };

  updateField: (fieldName, fieldValue) => void;
  refreshList: () => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  videoProjectsLoaded: state.videoWorkFlow.videoProjectsLoaded,
  videoProjects: state.videoWorkFlow.videoProjects,
  totalItems: state.videoWorkFlow.totalItems,
  totalPages: state.videoWorkFlow.totalPages,
  videoProjectsSortField: state.videoWorkFlow.videoProjectsSortField,
  page: state.videoWorkFlow.page,
  limit: state.videoWorkFlow.limit,
});

const mapDispatchToProps = (dispatch) => ({
  updateField: (fieldName, fieldValue) => dispatch({ type: VIDEO_PROJECT.UPDATE_FIELD, fieldName, fieldValue }),
});

const ItemList = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, state, videoProjectsLoaded, videoProjects, videoProjectsSortField, totalItems } = props;
  const { updateField, refreshList } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateVideoProject = async ({
    videoProject,
    action,
  }: {
    videoProject: IVideoProject;
    action: eVideoProjectAction;
  }) => {
    try {
      const data = await agent.VideoProjects.update(selectedWebsite, videoProject, action || eVideoProjectAction.save);
      const updatedVideoProject = data.videoProject as IVideoProject;
      if (!updatedVideoProject) {
        refreshList();
        return;
      }

      if (action === eVideoProjectAction.export) {
        const url = data.exportedVideoUrl;

        // start file download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "video.mp4");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return;
      }

      const canView = ability.can("view", `video_workflow_${updatedVideoProject.currentState.state}_tab`);
      if (canView) {
        history.push(web.videoWorkflow(updatedVideoProject.currentState.state));
        return;
      }
      refreshList();
    } catch (error) {
      enqueueSnackbar(error.response?.body?.message, {
        variant: "error",
      });
      console.log("error", error);
    }
  };

  return (
    <div className={classes.contentList}>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        {!videoProjectsLoaded && (
          <ListItem
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CfProgressBar />
          </ListItem>
        )}

        {videoProjectsLoaded && (
          <>
            <ItemListSubHeader
              totalItems={totalItems}
              sortField={videoProjectsSortField}
              setSortField={(sortField) => updateField("videoProjectsSortField", sortField)}
              topHeader
              showStatusField={!state}
            />
            <>
              {videoProjects.map((item) => (
                <VideoItemRow
                  videoProject={item}
                  key={item._id}
                  showStatusField={!state}
                  updateVideoProject={updateVideoProject}
                />
              ))}
            </>
          </>
        )}
      </List>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
