import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { ICategory } from "../../../../../reducers/constants/objectTypes";
import listHelper from "../../../../../helpers/listHelper";
import MSelect from "../../../../../components/MSelect";
import theme from "../../../../../themes/theme";
import DragIcon from "../../../../../icons/DragIcon";

const useStyles = makeStyles((theme: Theme) => ({
  writersWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  writersWrapperFullWidth: {
    width: "100%",
  },
  writerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // whiteSpace: 'pre',
    marginBottom: 10,
  },
  writerWrapperFullWidth: {
    flexDirection: "column",
    alignItems: "start",
    gap: 7,
  },
  writerTitle: {
    maxWidth: 65,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    whiteSpace: "pre",
  },
  writerTitleFullWidth: {
    maxWidth: "unset",
  },
  clearIcon: {
    fill: "#808080",
    marginLeft: 5,
    cursor: "pointer",
  },
  list: {},
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.divider,
      "& $secondaryAction": {
        opacity: 1,
      },
      "& $dragIcon": {
        opacity: 1,
      },
    },
  },
  dragIcon: {
    opacity: 0,
  },
  listAvatar: {
    minWidth: 36,
  },
  avatar: {
    fontSize: 12,
    width: 20,
    height: 20,
  },
  secondaryAction: {
    opacity: 0,
  },
  deleteIcon: {
    fontSize: 14,
  },
  visibilityOffIcon: {
    fontSize: 14,
  },
  blue: {
    fill: "blue",
  },
  text: {
    color: `${theme.palette.text.primary} !important`,
    fontSize: `${theme.typography.pxToRem(12)} !important`,
  },
}));

type PropTypes = {
  rowTitle?: string;
  isClearable?: boolean;
  categories: ICategory[];
  selectedCategories: any[];
  handleCategoriesChange: (categories: any[]) => void;
};

const mapStateToProps = (state) => ({
  categories: state.category.categories,
});

const mapDispatchToProps = (dispatch) => ({});

const BlogMultipleCategorySelector = (props: PropTypes) => {
  const classes = useStyles(props);
  const { rowTitle, isClearable = false, categories = [], selectedCategories = [], handleCategoriesChange } = props;
  const selectedItems = selectedCategories.length !== 0 ? selectedCategories : categories;

  const onProductsChanged = (uCategories: ICategory[]) => {
    handleCategoriesChange(uCategories);
  };

  const handleRemoveProduct = (index) => {
    selectedItems.splice(index, 1);
    onProductsChanged(selectedItems);
  };
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const newItems = listHelper.reorder(selectedItems, source.index, destination.index);
    onProductsChanged(newItems);
  };

  return (
    <div className={ClassNames(classes.writerWrapper, classes.writerWrapperFullWidth)}>
      <Typography
        variant={"body1"}
        className={ClassNames(classes.writerTitle, classes.writerTitleFullWidth)}
        color={"textSecondary"}
      >
        {rowTitle || I18n.t("rich_text_editor.select_categories")}
      </Typography>
      <div className={ClassNames(classes.writersWrapper, classes.writersWrapperFullWidth)}>
        <MSelect
          isClearable={isClearable}
          // textColor={theme.palette.text.primary}
          textColor={theme.palette.common.white}
          textColorHover={theme.palette.common.white}
          optionBackgroundColorHover={theme.palette.secondary.main}
          options={categories}
          width={"unset"}
          fullWidth
          value={null}
          searchable
          placeholder={I18n.t("edit_post.search_categories")}
          borderColor={theme.palette.divider}
          borderWidth={1}
          borderRadius={3}
          isBold={false}
          height={37}
          menuPlacement={"bottom"}
          handleChange={(selectedItem) => {
            if (selectedItem) {
              if (!selectedCategories?.includes(selectedItem)) {
                onProductsChanged([
                  ...(selectedItems || []),
                  { name: selectedItem.titleBlog || selectedItem.name, slug: selectedItem.slug },
                ]);
              }
            }
          }}
          optionLabel={"name"}
          optionValue={"slug"}
        />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="products-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={{ width: "100%" }}>
              <List dense className={classes.list}>
                {selectedItems?.map((category, index) => (
                  <Draggable draggableId={category.slug} index={index} key={category.slug}>
                    {(provided, snapshot) => (
                      <ListItem
                        classes={{
                          container: classes.listItem,
                        }}
                        disableGutters
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={snapshot.isDragging ? classes.draggingListItem : ""}
                      >
                        <DragIcon className={classes.dragIcon} />
                        <ListItemAvatar className={classes.listAvatar}>
                          <Avatar className={classes.avatar}>{index + 1}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          classes={{ primary: classes.text }}
                          primary={category?.titleBlog || category?.name}
                        />
                        <ListItemSecondaryAction className={classes.secondaryAction}>
                          <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveProduct(index)}>
                            <CloseIcon className={classes.deleteIcon} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogMultipleCategorySelector);
