import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

<svg xmlns="http://www.w3.org/2000/svg" width="3" height="3" viewBox="0 0 3 3" fill="none">
  <circle opacity="0.7" cx="1.5" cy="1.5" r="1.5" fill="white" />
</svg>;

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 3,
    height: 3,
    fill: "#fff",
  },
}));

type PropTypes = {
  className?: string;
};

const EllipseIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 3 3"} fill="none">
      <circle opacity="0.7" cx="1.5" cy="1.5" r="1.5" />
    </SvgIcon>
  );
};
export default EllipseIcon;
