import * as React from "react";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import List from "@material-ui/core/List";
import { ListItem, ListItemText, Theme, Typography } from "@material-ui/core";
import moment from "moment";
import { ISourceFile } from "../../../../../reducers/constants/objectTypes";
import { DATE_FORMAT } from "../../../../../reducers/constants/consts";
import CommentField from "./CommentField";
import TrashCanIcon from "../../../../../icons/TrashCanIcon";

const useStyles = makeStyles(({ palette, typography }: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    borderBottom: `solid 1px ${palette.divider}`,
    minHeight: 57.6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palette.background.paper,
  },
  historyList: {
    overflow: "auto",
    "& .MuiList-root": {
      padding: 0,
    },
  },
  listItem: {
    cursor: "pointer",
    borderBottom: `${palette.divider} solid 1px`,
    "&:hover": {
      transition: "500ms",
      backgroundColor: palette.secondary.main,
    },
    "& .MuiListItemText-root": {
      margin: 0,
    },
    paddingBlock: 14,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  selectedListItem: {
    backgroundColor: palette.secondary.main,
    "& $itemPrimaryText": {
      color: palette.primary.main,
    },
    "& $itemSecondaryText": {
      color: palette.primary.main,
    },
  },
  listItemPosition: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  itemPrimaryText: {
    color: palette.text.secondary,
  },
  itemSecondaryText: {
    color: palette.text.disabled,
  },
  tableTitleHead: {
    color: "black",
    fontSize: "20px",
    fontWeight: theme.typography.fontWeightBold as any,
    width: "35%",
    paddingRight: 10,
  },
  commentField: {
    color: palette.text.secondary,
    paddingTop: 6,
    paddingBottom: 0,
    fontSize: typography.body2.fontSize,
    lineHeight: typography.body2.lineHeight,
    fontFamily: typography.body2.fontFamily,
  },
  trashIcon: {
    color: palette.text.primary,
    opacity: 0.5,
    fontSize: 18,
    marginTop: 4,
    "&:hover": {
      opacity: 1,
      color: palette.primary.main,
    },
  },
}));

type PropTypes = {
  historyList?: ISourceFile["transcriptionHistory"];
  selectedHistory?: ISourceFile["transcriptionHistory"][number];
  selectHistory(historyItem: ISourceFile["transcriptionHistory"][number]): void;
  editHistory(params: { fields: ISourceFile["transcriptionHistory"][number]; withSuccessMessage?: boolean }): void;
  deleteHistory(historyItem: ISourceFile["transcriptionHistory"][number]): void;
};

const SourceFilesHistory = (props: PropTypes) => {
  const classes = useStyles(props);
  const { historyList = [], selectedHistory } = props;
  const { selectHistory, editHistory, deleteHistory } = props;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography color={"textPrimary"} variant={"h5"}>
          {I18n.t("dialogs.rephrase.history")}
        </Typography>
      </div>
      <div className={classes.historyList}>
        <List component="nav">
          <ListItem
            className={ClassNames(
              classes.listItem,
              classes.listItemPosition,
              !selectedHistory?._id && classes.selectedListItem
            )}
            onClick={() => selectHistory(null)}
          >
            <ListItemText
              primary={I18n.t("dialogs.rephrase.current")}
              primaryTypographyProps={{
                variant: "subtitle2",
                className: classes.itemPrimaryText,
              }}
              secondary={I18n.t("dialogs.rephrase.now")}
              secondaryTypographyProps={{
                variant: "body2",
                className: classes.itemSecondaryText,
              }}
            />
          </ListItem>
          {historyList
            .sort(
              (a: { createdAt: Date }, b: { createdAt: Date }) =>
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            )
            .map((historyItem) => (
              <ListItem
                className={ClassNames(
                  classes.listItem,
                  historyItem._id === selectedHistory?._id && classes.selectedListItem
                )}
                key={historyItem._id}
                onClick={() => selectHistory(historyItem)}
              >
                <div className={classes.listItemPosition}>
                  <ListItemText
                    primary={moment(historyItem.createdAt).format(DATE_FORMAT.READABLE)}
                    primaryTypographyProps={{
                      variant: "subtitle2",
                      className: classes.itemPrimaryText,
                    }}
                    secondary={historyItem?.createdBy?.name}
                    secondaryTypographyProps={{
                      variant: "body2",
                      className: classes.itemSecondaryText,
                    }}
                  />
                  <CommentField
                    comment={historyItem.comment}
                    onChange={(comment) =>
                      editHistory({ fields: { ...historyItem, comment }, withSuccessMessage: false })
                    }
                  />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteHistory(historyItem);
                  }}
                >
                  <TrashCanIcon className={classes.trashIcon} />
                </div>
              </ListItem>
            ))}
        </List>
      </div>
    </div>
  );
};

export default SourceFilesHistory;
