import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { IWebsite } from "../../reducers/constants/objectTypes";
import agent from "../../agent";
import FunnelBaseSelect from "../../containers/Analytics/FunnelCreator/components/FunnelBaseSelect";
import MEventStepFilter from "./MEventStepFilter";

const useStyles = makeStyles((theme: Theme) => ({
  stepEventWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
    position: "relative",
  },
  funnelBaseTitleWrapper: {
    height: 50,
    borderRadius: 3,
    border: "none",
    backgroundColor: "#EEF2F9",
    color: theme.palette.text.primary,
    padding: "0px 8px 0px 15px",
    "& p": {
      fontWeight: theme.typography.fontWeightRegular as any,
      "&:hover": {
        color: theme.palette.text.primary,
      },
    },
    "& svg": {
      color: `${theme.palette.text.primary} !important`,
      fill: `${theme.palette.text.primary} !important`,
      fontSize: 18,
      "&:hover": {
        color: `${theme.palette.primary.main} !important`,
        fill: `${theme.palette.primary.main} !important`,
      },
    },
  },
  stepEventFilter: {
    borderRadius: 3,
    border: "none",
    backgroundColor: "#EEF2F9",
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: "12px 15px",
    "& *": {
      padding: 0,
    },
    "& div": {
      display: "flex",
      gap: 5,
    },
  },
  disabledWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
}));

type Props = {
  event: any;
  eventNames: any[];
  selectedWebsite: IWebsite;
  disabled?: boolean;
  onClearEvent: (event) => void;
  handleEventChange: (event) => void;
};

const MEvent = (props: Props) => {
  const classes = useStyles();

  const { selectedWebsite, eventNames, event, disabled, onClearEvent, handleEventChange } = props;

  const [dynamicColumns, setDynamicColumns] = useState({});

  const [stepDynamicColumns, setStepDynamicColumns] = useState([]);

  useEffect(() => {
    if (event?.value) {
      const eventName = event.value;

      if (dynamicColumns[eventName]) {
        setStepDynamicColumns(dynamicColumns[eventName]);
      } else if (eventName) {
        agent.Funnels.getDynamicColumns(selectedWebsite, eventName)
          .then((res) => {
            setStepDynamicColumns(res.data);
            setDynamicColumns({ eventName, data: res.data });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [event]);

  return (
    <div className={classes.stepEventWrapper}>
      <FunnelBaseSelect
        titleWrapperClassName={classes.funnelBaseTitleWrapper}
        showDropdownIcon={false}
        optionLabel={"label"}
        optionValue={"value"}
        placeholder={"Select event..."}
        onClear={() => onClearEvent(event)}
        onFilterClick={() => {
          if (event?.filters?.length === 0 || event.filters[event.filters.length - 1]?.field != null) {
            event.filters.push({
              column: "",
              condition: "is",
              value: "",
            });
            handleEventChange({ ...event });
            return;
          }
        }}
        options={eventNames}
        value={eventNames.find((e) => e.value === event?.value)}
        handleOnChange={(t) => {
          if (!event) {
            handleEventChange({
              condition: "",
              value: t.value,
              filters: [],
            });
            return;
          } else {
            event.value = t.value;
          }
          handleEventChange({ ...event });
        }}
      />
      {event?.value &&
        event?.filters.map((filter, filterIndex) => (
          <MEventStepFilter
            selectedWebsite={selectedWebsite}
            key={filterIndex}
            dynamicColumns={stepDynamicColumns}
            event={event}
            filter={filter}
            filterIndex={filterIndex}
            handleEventChange={(e) => handleEventChange({ ...e })}
            className={classes.stepEventFilter}
          />
        ))}
      {disabled && <div className={classes.disabledWrapper} />}
    </div>
  );
};

export default MEvent;
