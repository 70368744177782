import React, { useMemo } from "react";
import { Button, Theme, Typography } from "@material-ui/core";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";

import {
  IWidgetField,
  IWidgetItem,
  IWidgetFieldActionPayload,
  IWidget,
  eWidgetSubtype,
  eWidgetFieldType,
} from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  header: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  leftContainer: ({ showBackArrow }: { showBackArrow: boolean }) => ({
    display: "flex",
    alignItems: "center",
    color: palette.text.primary,
    cursor: showBackArrow ? "pointer" : "initial",
    flex: 1,
    minWidth: 0,
  }),
  rightContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: 300,
    textAlign: "right",
    marginLeft: 10,
  },
  button: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    fontSize: 14,
    textTransform: "none",
    height: 30,
    padding: 10,
    borderRadius: "20px",
    fontWeight: typography.fontWeightMedium as any,
    "&:hover": {
      backgroundColor: palette.primary.light,
      color: palette.common.white,
    },
    "&:disabled": {
      borderColor: palette.primary.dark,
      color: palette.primary.dark,
      backgroundColor: palette.action.selected,
      cursor: "initial",
    },
  },
  leftArrow: {
    marginLeft: -8,
  },
  label: {
    fontSize: 14,
    fontWeight: typography.fontWeightMedium as any,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

type PropTypes = {
  widget: IWidget;
  selectedField: IWidgetField;
  selectedWidgetItem: IWidgetItem;
  hasUnsavedChanges: boolean;
  className?: string;
  setSelectedFieldId: (selectedField: string) => void;
  back(): void;
  editField: (payload: IWidgetFieldActionPayload) => void;
};

const SelectedWidgetItemHeader = (props: PropTypes) => {
  const { widget, selectedField, selectedWidgetItem, hasUnsavedChanges, className } = props;
  const { setSelectedFieldId, back, editField } = props;
  const showBackArrow = useMemo(() => !!(back || selectedField), [back, selectedField]);
  const classes = useStyles({ ...props, showBackArrow });

  const getFieldTitle = (): string => {
    if (
      [eWidgetSubtype.PRODUCT_REVIEW, eWidgetSubtype.CHARTICLE_TOP_PRODUCTS].includes(widget.subtype) &&
      selectedField.type === eWidgetFieldType.CALL_TO_ACTION
    ) {
      return I18n.t("widgets_builder.widget_item_selector.call_to_action_and_review");
    }
    return I18n.t(`widgets_builder.widget_item_selector.${selectedField.type}`);
  };

  return (
    <div className={classNames(classes.header, className)}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (selectedField) return setSelectedFieldId(null);
          return back && back();
        }}
        className={classes.leftContainer}
      >
        {showBackArrow && <KeyboardArrowLeftRounded className={classes.leftArrow} />}
        <Typography className={classes.label}>
          {!selectedField ? selectedWidgetItem?.name || selectedWidgetItem.tempTitle : getFieldTitle()}
        </Typography>
      </div>
      {selectedField && selectedField.type !== "form" && (
        <div className={classes.rightContainer}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              editField({ field: selectedField });
              setSelectedFieldId(null);
            }}
            className={classes.button}
            disabled={!hasUnsavedChanges}
          >
            {I18n.t("widgets_builder.build.item_done_button")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SelectedWidgetItemHeader;
