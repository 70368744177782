import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { useState } from "react";
import { useSlate } from "slate-react";
import TableIcon from "../toolbar/icons/TableIcon";
import TableModal from "./TableModal";
import { isSelectionTable } from "./tables";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      // padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
    activeIcon: {
      fill: theme.palette.primary.main,
    },
    paper: {
      padding: 10,
    },
    imageDetailsWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    imageSizeWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    sizeField: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold as any,
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
    },
  })
);

type PropTypes = {};

function InsertTableButton(props: PropTypes) {
  const classes = useStyles(props);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [isActive, setIsActive] = useState(false);
  const tableModalRef = React.useRef<HTMLButtonElement>(null);
  // const editor = useSlateStatic();
  const editor = useSlate();

  const tableActive = isSelectionTable(editor);
  return (
    <div style={{ display: "contents" }}>
      <Tooltip enterDelay={2000} title={I18n.t("rich_text_editor.tooltips.insert_table")}>
        <IconButton
          id={"insert-table-button"}
          className={classes.button}
          disableRipple
          ref={tableModalRef}
          onClick={(e) => {
            if (!isActive) {
              setIsActive(true);
            }
          }}
        >
          <TableIcon className={ClassNames(classes.icon, (isActive || tableActive) && classes.activeIcon)} />
        </IconButton>
      </Tooltip>
      <TableModal anchorRef={tableModalRef} open={isActive || tableActive} closePopover={() => setIsActive(false)} />
    </div>
  );
}

export default InsertTableButton;
