import React from "react";
import numeral from "numeral";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  red: {
    color: "red",
  },
  suffix: {
    marginLeft: 5,
  },
}));

type PropTypes = {
  wrapperClass?: string;
  type?: "char" | "word";
  text: string;
  suffix?: string;
  desiredCount?: number;
};

const MTextCounter = (props: PropTypes) => {
  const classes = useStyles(props);
  const { type = "char", text, desiredCount, suffix, wrapperClass } = props;

  const count = text ? (type === "char" ? text.length : text.split(/\s+/).length) : 0;
  return (
    <div className={wrapperClass} style={{ display: "flex" }}>
      <Typography color={"textSecondary"} variant={"body2"}>
        {numeral(count).format("0,0[.]")}
      </Typography>

      {desiredCount && (
        <Typography className={count > desiredCount && classes.red} color={"textSecondary"} variant={"body2"}>
          {`/${numeral(desiredCount).format("0,0[.]")}`}
        </Typography>
      )}

      {suffix && (
        <Typography className={classes.suffix} color={"textSecondary"} variant={"body2"}>
          {suffix}
        </Typography>
      )}
    </div>
  );
};

export default MTextCounter;
