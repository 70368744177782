import React from "react";
import { makeStyles, Theme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import theme from "../../../../../themes/theme";
import PageSection from "./PageSection";
import MSelect from "../../../../../components/MSelect";
import green_frame from "../../../../../img/green_frame.svg";
import { IProposal } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  selectRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  select: {
    width: 150,
    textTransform: "capitalize",
  },
  selectText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
    marginRight: 10,
  },
  firstHeader: {
    width: "65%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  header: {
    width: "8%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  quantityHeader: {
    width: "15%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  headersRow: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    gap: 25,
  },
  row: {
    display: "flex",
    alignItems: "center",
    padding: 12,
    gap: 25,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  firstColumn: {
    width: "65%",
    display: "flex",
    alignItems: "center",
    gap: 25,
  },
  column: {
    fontSize: theme.typography.pxToRem(14),
    width: "8%",
  },
  quantityColumn: {
    display: "flex",
    alignItems: "center",
    width: "15%",
  },
  quantityField: {
    width: 41,
    display: "flex",
    justifyContent: "center",
    fontSize: theme.typography.pxToRem(14),
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 2,
    padding: "5px 11px",
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  minusIcon: {
    fontSize: 16,
    marginRight: 5,
    cursor: "pointer",
  },
  addIcon: {
    fontSize: 16,
    marginLeft: 5,
    cursor: "pointer",
  },
}));

type PropTypes = {
  proposal: IProposal;
  handleChange: (value) => void;
};

const CustomizePlan = (props: PropTypes) => {
  const classes = useStyles(props);
  const { proposal, handleChange } = props;

  return (
    <PageSection
      title={I18n.t("account.pricing.customize_plan.title")}
      subtitle={I18n.t("account.pricing.customize_plan.subtitle")}
    >
      <div className={classes.selectRow}>
        <Typography variant={"body1"} className={classes.selectText}>
          {I18n.t("account.pricing.customize_plan.content_level")}
        </Typography>
        <MSelect
          customClass={classes.select}
          options={proposal.contentLevels}
          value={_.find(proposal.contentLevels, { name: proposal.selectedContentLevel?.name })}
          optionValue={"_id"}
          optionLabel={"name"}
          height={30}
          textColor={theme.palette.text.primary}
          placeholder={I18n.t("account.pricing.customize_plan.select_ph")}
          borderWidth={1}
          borderRadius={3}
          isBold={false}
          handleChange={(value) => {
            handleChange({ ...proposal, selectedContentLevel: value });
            // setSelectedLevel(value);
          }}
        />
      </div>
      <div className={classes.headersRow}>
        <Typography variant={"subtitle2"} className={classes.firstHeader}>
          {I18n.t("account.pricing.customize_plan.content_item")}
        </Typography>
        <Typography variant={"subtitle2"} className={classes.header}>
          {I18n.t("account.pricing.customize_plan.price")}
        </Typography>
        <Typography variant={"subtitle2"} className={classes.quantityHeader}>
          {I18n.t("account.pricing.customize_plan.quantity")}
        </Typography>
        <Typography variant={"subtitle2"} className={classes.header}>
          {I18n.t("account.pricing.customize_plan.total")}
        </Typography>
      </div>
      {proposal?.contentItems.map((item, i) => {
        const currentLevelPrice = item.prices.find(
          (p) => p.contentLevelId === proposal.selectedContentLevel?._id
        )?.price;
        return (
          <div key={item._id} className={classes.row}>
            <div className={classes.firstColumn}>
              <img src={green_frame} alt={"green-frame"} />
              <Typography variant={"body1"} className={classes.name}>
                {item.name}
              </Typography>
            </div>
            <Typography variant={"body1"} className={classes.column}>
              {numeral(currentLevelPrice).format("$0,0[.]00")}
            </Typography>
            <div className={classes.quantityColumn}>
              <RemoveIcon
                className={classes.minusIcon}
                onClick={() => {
                  if (item.quantity > 0) {
                    const newContentItems = [...proposal.contentItems];
                    newContentItems[i].quantity = item.quantity - 1;
                    handleChange({ ...proposal, contentItems: newContentItems });
                  }
                }}
              />
              <Typography variant={"body1"} className={classes.quantityField}>
                {item.quantity}
              </Typography>
              <AddIcon
                className={classes.addIcon}
                onClick={() => {
                  const newContentItems = [...proposal.contentItems];
                  newContentItems[i].quantity = item.quantity + 1;
                  handleChange({ ...proposal, contentItems: newContentItems });
                }}
              />
            </div>
            <Typography variant={"body1"} className={classes.column}>
              {numeral(item.quantity * numeral(currentLevelPrice).value()).format("$0,0[.]00")}
            </Typography>
          </div>
        );
      })}
    </PageSection>
  );
};

export default CustomizePlan;
