import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import LinkIcon from "@material-ui/icons/Link";
import CopyToClipboardIcon from "../../../icons/CopyToClipboardIcon";
import { copyStringToClipboard } from "../../../helpers/fbScraperHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold as any,
      color: theme.palette.text.primary,
    },
    innerIdentSection: {
      marginLeft: 10,
      marginTop: 5,
      fontSize: theme.typography.pxToRem(14),
    },
    linksWrapper: {
      marginBottom: 20,
      display: "flex",
      flexDirection: "column",
    },
    link: {
      paddingLeft: 3,
      fontSize: theme.typography.pxToRem(14),
      height: 30,
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        "& $copyToClipboardButton": {
          display: "block",
        },
      },
    },
    linkActions: {
      display: "flex",
      alignItems: "center",
    },
    copyToClipboardButton: {
      padding: "0px 5px 0px 5px",
      display: "none",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    copyToClipboardIcon: {
      width: 20,
      height: 20,
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
  })
);

type PropTypes = {
  links: any;
};

const EditorOutlineLinks = (props: PropTypes) => {
  const classes = useStyles(props);
  const { links = [] } = props;
  const { enqueueSnackbar } = useSnackbar();

  if (links.length === 0) {
    return null;
  }

  return (
    <div className={classes.linksWrapper}>
      <Typography className={classes.innerTitle}>{I18n.t("outline.links_and_anchors")}</Typography>
      <div className={classes.innerIdentSection}>
        {links.map((link, index) => (
          <div key={`${link}_${index}`} className={classes.link}>
            <a href={link.url}>{link.text}</a>
            <div className={classes.linkActions}>
              <IconButton
                disableRipple
                className={classes.copyToClipboardButton}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  copyStringToClipboard(link.url, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard")));
                }}
              >
                <LinkIcon className={classes.copyToClipboardIcon} />
              </IconButton>
              <IconButton
                disableRipple
                className={classes.copyToClipboardButton}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  copyStringToClipboard(link.text, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard")));
                }}
              >
                <CopyToClipboardIcon className={classes.copyToClipboardIcon} />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditorOutlineLinks;
