import { FilterFn } from "@tanstack/react-table";
import React from "react";

import { rankItem } from "@tanstack/match-sorter-utils";

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the ranking info
  addMeta(itemRank);

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const getTableMeta = (
  setData: React.Dispatch<React.SetStateAction<any[]>>,
  skipAutoResetPageIndex: () => void
) =>
  ({
    updateData: (rowIndex, columnId, value) => {
      // Skip age index reset until after next rerender
      skipAutoResetPageIndex();
      setData((old) =>
        old.map((row, index) => {
          if (index !== rowIndex) return row;

          return {
            ...old[rowIndex]!,
            [columnId]: value,
          };
        })
      );
    },
  } as any);
