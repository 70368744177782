type IGetVideoCoverProps = {
  seekTo?: number;
} & (
  | {
      type: "file";
      file: File;
    }
  | {
      type: "url";
      url: string;
    }
);

const getVideoCoverAndDuration = (params: IGetVideoCoverProps) => {
  const { seekTo = 0, type } = params;

  console.log("getting video cover for: ", type === "file" ? params.file : params.url);
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", type === "url" ? params.url : URL.createObjectURL(params.file));
    videoPlayer.setAttribute("crossorigin", "anonymous");
    videoPlayer.load();
    console.log("getVideoCover -> videoPlayer load");
    videoPlayer.addEventListener("error", (ex) => {
      console.log("getVideoCover -> error", ex);
      reject(ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      console.log("getVideoCover -> loadedmetadata");
      // seek to user defined timestamp (in seconds) if possible
      // TODO: check if it works properly
      // if (videoPlayer.duration < seekTo) {
      //   reject(new Error("video is too short."));
      //   return;
      // }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("getVideoCover -> seeked");
        console.log("video is now paused at %ss.", seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve({
              blob,
              duration: videoPlayer.duration,
            });
          },
          "image/jpeg",
          0.75 /* quality */
        );
      });
    });
  });
};

export default {
  getVideoCoverAndDuration,
};
