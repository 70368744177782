import React from "react";
import { Helmet } from "react-helmet-async";

type PropTypes = {
  title: string;
};
const MHeaderMetaTags = (props: PropTypes) => {
  const { title } = props;
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
      </Helmet>
    </>
  );
};
export default MHeaderMetaTags;
