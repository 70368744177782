import { VIDEO_PROJECT } from "./constants/actionTypes";

const initialState = {
  // list of video projects
  videoProjectsLoaded: false,
  videoProjects: [],
  totalItems: 0,
  totalPages: 0,
  page: 1,
  limit: 20,
  refreshVideoProjects: false,
  videoProjectsSortField: { direction: "desc", field: "updatedAt" },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_PROJECT.PROJECTS_LOADED:
      return {
        ...state,
        videoProjectsLoaded: true,
        refreshVideoProjects: false,
        videoProjects: action.payload.videoProjects,
        page: action.payload.page,
        limit: action.payload.limit,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      };
    case VIDEO_PROJECT.PROJECTS_LOAD_FAILED:
      return {
        ...state,
        videoProjectsLoaded: true,
        videoProjects: [],
      };
    case VIDEO_PROJECT.PROJECTS_LOADING:
      return {
        ...state,
        videoProjectsLoaded: false,
      };
    case VIDEO_PROJECT.PROJECTS_REFRESH:
      return {
        ...state,
        refreshVideoProjects: !state.refreshVideoProjects,
      };
    case VIDEO_PROJECT.UPDATE_FIELD:
      return {
        ...state,
        [action.fieldName]: action.fieldValue,
      };
    default:
      return state;
  }
};
