import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { IAnalyticsEvent } from "../../../../reducers/constants/objectTypes";
import Typography from "@material-ui/core/Typography";
import theme from "../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  topicName: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: 4,
    fontWeight: theme.typography.fontWeightRegular as any,
    cursor: "pointer",
    width: "fit-content",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.primary.main,
  },
}));

type PropTypes = {
  event: IAnalyticsEvent;
};

const ActivityFeedEventDetails = (props: PropTypes) => {
  const classes = useStyles(props);
  const { event } = props;

  if (event.name === "page-view") {
    return (
      <Link
        component={"a"}
        href={event.url}
        onClick={(e) => {
          e.stopPropagation();
        }}
        target={"_blank"}
        className={classes.topicName}
        variant="subtitle2"
      >
        {event.url}
      </Link>
    );
  }

  if (event.name === "button-click") {
    return (
      <>
        {event.event_data?.text && (
          <Typography className={classes.topicName} variant="subtitle2">
            {`clicked on `}
            <span className={classes.bold}>{`"${event.event_data?.text}"`}</span>
          </Typography>
        )}
      </>
    );
  }

  if (event.name === "internal-link-click" || event.name === "header-click" || event.name === "outbound-link-click") {
    return (
      <div className={classes.wrapper}>
        {event.event_data?.text && (
          <Typography className={classes.topicName} variant="subtitle2">
            {`clicked on `}
            <span className={classes.bold}>{`"${event.event_data?.text}"`}</span>
          </Typography>
        )}
        {event.event_data?.href && (
          <>
            <Typography className={classes.topicName} variant="subtitle2">
              {` and open `}
              <Link
                href={event.url}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                target={"_blank"}
                className={classes.topicName}
                variant="subtitle2"
              >
                {event.event_data?.href}
              </Link>
            </Typography>
          </>
        )}
      </div>
    );
  }

  return null;
};

export default ActivityFeedEventDetails;
