import { Button, Input, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { ReactEditor, useSlateStatic } from "slate-react";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import { Transforms } from "slate";
import { Widget } from "../WithWidgets";
import { IFile, IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { ArrowForward } from "@material-ui/icons";
import RatingStarIcon from "../../../../icons/RatingStarIcon";
import { find } from "lodash";
import TextAreaWidgetBaseElement from "../baseElements/TextAreaWidgetBaseElement";
import ImagePicker from "../../../../components/ImagePicker";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    display: "flex",
    flexDirection: "column",
  },
  productTitle: (props: PropTypes) => ({
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 2,
    textTransform: "capitalize",
    ...props.element.data?.productTitleCss,
  }),
  productName: (props: PropTypes) => ({
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(18),
    ...props.element.data?.productNameCss,
  }),
  logoAndRatingWrapper: {
    alignItems: "center",
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
  },
  ratingWrapper: {
    width: "100%",
    paddingLeft: 25,
    marginLeft: 25,
    borderLeft: `${theme.palette.divider} solid 1px`,
  },
  ctaSection: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  checklistSection: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    marginLeft: 10,
    "&:first-child": {
      marginLeft: 0,
    },
  },
  productCTAUrlWrapper: {
    border: `solid 1px ${theme.palette.divider}`,
    marginLeft: 10,
  },
  clearIcon: {
    cursor: "pointer",
    fill: theme.palette.text.secondary,
    marginLeft: 10,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  hidden: {
    visibility: "hidden",
  },
  checkIcon: (props: PropTypes) => ({
    fill: "#35c376",
    ...props.element.data?.prosCss,
  }),
  horizontalDivider: {
    width: 60,
    height: 2,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: theme.palette.divider,
  },
  ctaButtonInput: {
    lineHeight: 1,
    textAlign: "start",
  },
  ctaButton: (props: PropTypes) => ({
    fontWeight: theme.typography.fontWeightLight as any,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "10px 30px",
    fontSize: theme.typography.pxToRem(16),
    borderRadius: 30,
    textTransform: "capitalize",
    marginLeft: 10,
    height: "auto",
    whiteSpace: "pre",
    minHeight: 40,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    ...props.element.data?.buttonCss,
  }),
  rating: {
    display: "flex",
    alignItems: "center",
  },
  ratingStarIcon: {
    marginRight: 10,
  },
  ratingText: {
    width: "100%",
    maxWidth: 78,
  },
  ctaTitle: {
    width: "100%",
  },
}));
type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  selectedWebsite: IWebsite;
  products: IProduct[];
  websiteTheme: IWebsiteThemeProps;
};

export const getRawText = (n: Node) => {
  if (!n.data) {
    return "";
  }
  return (
    [
      n.data.productName || "",
      n.data.productTitle || "",
      n.data.callToActionTitle || "",
      n.data.productBullets?.map((bullet) => `${bullet.name}\n${bullet.text}`)?.join("\n"),
    ]?.join("\n") || ""
  );
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  websiteTheme: state.home.selectedWebsite.configurations.theme,
});

const ProductDetailsWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, selectedWebsite, products, websiteTheme } = props;
  const editor = useSlateStatic();

  const handleChange = (data) => {
    console.log("handleChange", data, ReactEditor.findPath(editor as ReactEditor, element));
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
    console.log("handleChange done");
  };

  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  const defaultData = {
    productTitleCss: {
      fontSize: websiteTheme.typography.h3.css.fontSize,
    },
    productNameCss: {
      fontSize: websiteTheme.typography.subtitle1.css.fontSize,
    },
    buttonCss: {
      fontSize: websiteTheme.typography.body1.css.fontSize,
      fontWeight: websiteTheme.typography.body1.css.fontWeight,
      margin: "0 0 0 10px",
      backgroundColor: websiteTheme.palette.primary.main,
      color: "white",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: websiteTheme.palette.primary.main,
      padding: "10px 30px",
      borderRadius: 30,
      "&:hover": {
        backgroundColor: websiteTheme.palette.primary.light,
        borderColor: websiteTheme.palette.primary.light,
      },
    },
  };
  React.useEffect(() => {
    handleChange({ ...defaultData, ...element.data });
  }, []);

  return (
    <div {...attributes}>
      <Widget title={I18n.t("rich_text_editor.custom_components.product_details")} element={element} editable>
        <div className={classes.wrap}>
          {/*<TextAreaWidgetBaseElement*/}
          {/*  className={classes.productTitle}*/}
          {/*  text={element.data.productTitle || product?.displayName || product?.name || ""}*/}
          {/*  placeholder={I18n.t("rich_text_editor.product_details.product_title_ph")}*/}
          {/*  onChange={(productTitle) => handleChange({ ...element.data, productTitle })}*/}
          {/*/>*/}
          <div className={classes.logoAndRatingWrapper}>
            <ImagePicker
              rounded
              imageWidth={50}
              image={
                element.data.productLogo || (product?.logo ? ({ url: product?.logo } as IFile) : product?.logoFile)
              }
              selectedWebsite={selectedWebsite}
              onImageChanged={(productLogo) => {
                if (!productLogo || typeof productLogo === "string") {
                  handleChange({ ...element.data, productLogo });
                } else {
                  handleChange({
                    ...element.data,
                    productLogo: {
                      _id: productLogo._id,
                      cdnUrl: productLogo.cdnUrl,
                      url: productLogo.url,
                      title: productLogo.title,
                      alt: productLogo.alt,
                      caption: productLogo.caption,
                    },
                  });
                }
              }}
            />
            <div className={classes.ratingWrapper}>
              <TextAreaWidgetBaseElement
                className={classes.productName}
                text={element.data.productName || product?.name || ""}
                placeholder={I18n.t("rich_text_editor.product_details.product_name_ph")}
                onChange={(productName) => handleChange({ ...element.data, productName })}
              />
              <div className={classes.rating}>
                <RatingStarIcon active className={classes.ratingStarIcon} />
                <Input
                  value={element.data.productRating || product?.rating || ""}
                  placeholder={I18n.t("rich_text_editor.product_details.product_rating_ph")}
                  type="number"
                  inputProps={{ step: "0.1", min: 1, max: 5 }}
                  disableUnderline
                  classes={{
                    root: classes.ratingText,
                  }}
                  onChange={(event) => handleChange({ ...element.data, productRating: event.target.value })}
                />
                <Typography variant={"body1"}>{I18n.t("rich_text_editor.product_details.overall_rating")}</Typography>
              </div>
            </div>
          </div>
          <ImagePicker
            image={element.data.productImage || product?.imageFiles?.[0] || { url: product?.images?.[0] } || null}
            selectedWebsite={selectedWebsite}
            onImageChanged={(productImage) => {
              if (!productImage || typeof productImage === "string") {
                handleChange({ ...element.data, productImage });
              } else {
                handleChange({
                  ...element.data,
                  productImage: {
                    _id: productImage._id,
                    cdnUrl: productImage.cdnUrl,
                    url: productImage.url,
                    title: productImage.title,
                    alt: productImage.alt,
                    caption: productImage.caption,
                  },
                });
              }
            }}
          />
          <div className={classes.ctaSection}>
            <div className={classes.row}>
              <TextAreaWidgetBaseElement
                className={classes.ctaTitle}
                text={element.data.callToActionTitle || ""}
                placeholder={I18n.t("rich_text_editor.product_details.cta_title_ph")}
                onChange={(callToActionTitle) => handleChange({ ...element.data, callToActionTitle })}
              />
              <Button endIcon={<ArrowForward />} className={classes.ctaButton}>
                <span className={classes.ctaButtonInput}>
                  {element.data?.buttonText ||
                    element.data?.callToActionText ||
                    product?.callToAction ||
                    I18n.t("rich_text_editor.product_details.cta_button_text")}
                </span>
              </Button>
            </div>
          </div>
          <div className={classes.horizontalDivider}></div>
          <div className={classes.checklistSection}>
            <Typography variant={"subtitle2"}>{I18n.t("rich_text_editor.product_details.checklist")}</Typography>
            {[...(element.data.productBullets || []), { name: "", text: "" }].map((bullet, index) => (
              <div key={`bullet_${index}`} className={classes.row}>
                <CheckIcon className={classes.checkIcon} />
                <TextAreaWidgetBaseElement
                  className={classes.textField}
                  text={bullet.name}
                  placeholder={I18n.t("rich_text_editor.product_details.bullet_name_ph")}
                  onChange={(value) => {
                    const productBullets = [...(element.data.productBullets || [])];
                    if (index >= bullet.length) {
                      productBullets.push({ name: value, text: bullet.text });
                    } else {
                      productBullets[index] = { name: value, text: bullet.text };
                    }
                    handleChange({ ...element.data, productBullets });
                  }}
                />
                <TextAreaWidgetBaseElement
                  className={classes.textField}
                  text={bullet.text}
                  placeholder={I18n.t("rich_text_editor.product_details.bullet_text_ph")}
                  onChange={(value) => {
                    const productBullets = [...(element.data.productBullets || [])];
                    if (index >= bullet.length) {
                      productBullets.push({ name: bullet.name, text: value });
                    } else {
                      productBullets[index] = { name: bullet.name, text: value };
                    }
                    handleChange({ ...element.data, productBullets });
                  }}
                />
                <ClearIcon
                  className={ClassNames(
                    classes.clearIcon,
                    [...(element.data.productBullets || [])].length === index && classes.hidden
                  )}
                  onClick={() => {
                    const productBullets = [...element.data.productBullets].filter((x, i) => index !== i);
                    handleChange({ ...element.data, productBullets });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        {children}
      </Widget>
    </div>
  );
};

export default connect(mapStateToProps)(ProductDetailsWidget);
