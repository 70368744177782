import React from "react";
import { I18n } from "react-redux-i18n";
import ContentItems from "./ContentItems";
import ContentLevels from "./ContentLevels";
import Plans from "./Plans";
import { IProposal } from "../../../../../reducers/constants/objectTypes";
import MHeaderMetaTags from "../../../../../components/MHeaderMetaTags";

type PropTypes = {
  proposal: IProposal;
  handleChange: (value) => void;
};

const Setup = (props: PropTypes) => {
  const { proposal, handleChange } = props;
  return (
    <>
      <MHeaderMetaTags title={I18n.t(`meta_tags.legacy.account_setup.title`)} />
      <Plans proposal={proposal} handleChange={(value) => handleChange(value)} />
      <ContentLevels proposal={proposal} handleChange={handleChange} />
      <ContentItems proposal={proposal} handleChange={handleChange} />
    </>
  );
};

export default Setup;
