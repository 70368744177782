import React from "react";
import { I18n } from "react-redux-i18n";
import GenericInputWidgetItem from "./components/GenericInputWidgetItem";
import { IWidgetInputFieldProps } from "../../../../../../../reducers/constants/objectTypes";

type PropTypes = {
  properties: IWidgetInputFieldProps;
  onPropertyChange(properties: IWidgetInputFieldProps): void;
};

const EmailWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;

  return <GenericInputWidgetItem properties={properties} onPropertyChange={onPropertyChange} />;
};

export default EmailWidgetItem;
