import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { AddRounded } from "@material-ui/icons";
import ClassNames from "classnames";
import { connect } from "react-redux";

import { WidgetRuleEditorRootState } from "../../../../../../../reducers/widgetRuleEditorReducer";
import { IWidget } from "../../../../../../../reducers/constants/objectTypes";
import WidgetPicker from "../../../../../../Modals/WidgetPicker/WidgetPicker";
import { WIDGET_RULE_EDITOR } from "../../../../../../../reducers/constants/actionTypes";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: 0,
    left: 0,
  },
  previewWrapper: {
    height: "100%",
    width: "100%",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.common.white,
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`,

    // add animation for header
    height: 0,
    opacity: 0,
    overflow: "hidden",
    transition: "height 0ms 400ms, opacity 400ms 0ms",
  },
  showHeader: {
    height: 40,
    transition: "height 400ms 0ms",
    opacity: 1,
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  paper: {
    width: "100%",
    height: "100%",
    margin: "auto",
    boxSizing: "content-box",
    background: "transparent",
    border: "none",
    display: "flex",
    position: "relative",
    overflow: "scroll",
    maxWidth: 1440,
  },
  imagePreview: {
    height: "auto",
  },
  paperMobile: {
    display: "flex",
    justifyContent: "center",
    maxWidth: 390,
  },
  centeredPaper: {
    display: "flex",
    justifyContent: "center",
  },
  innerPreview: {
    margin: "auto",
  },
  iframeWrapper: {
    minWidth: 1440,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 14px 0px",
    border: 0,
  },
  iframeWrapperMobile: {
    width: 390,
    minWidth: 390,
  },
  xpathContainers: {
    overflow: "scroll",
  },
  xpathContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  xpathContainerActive: {
    cursor: "pointer",
    borderRadius: 5,
    border: `1px solid ${theme.palette.primary.main}`,
    background: "rgba(67, 83, 255, 0.10)",
  },
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    minHeight: 55,
    paddingRight: 17,
    paddingLeft: 17,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    position: "relative",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginLeft: 15,
    borderRadius: "5px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    userSelect: "none",
  },
  divider: {
    height: 26,
    margin: "0 15px",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  optionPlaceholder: {
    fontWeight: theme.typography.fontWeightRegular as any,
  },
}));

type PropTypes = {
  mobilePreview: boolean;
  preview: WidgetRuleEditorRootState["preview"];
  addContainerMode: boolean;
  reselectPositionMode?: boolean;
  selectedPositionEdit: WidgetRuleEditorRootState["selectedPositionEdit"];
  handleWidgetInsert: (widget: IWidget, xpath: any) => void;
  handleWidgetReselect: (xpath: any) => void;
  handlePreviewPropertyChange: (property: Partial<WidgetRuleEditorRootState["preview"]>) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  rule: state.widgetRuleEditor.rule,
  addContainerMode: state.widgetRuleEditor.addContainerMode,
  reselectPositionMode: state.widgetRuleEditor.reselectPositionMode,
  draftContainer: state.widgetRuleEditor.draftContainer,
  preview: state.widgetRuleEditor.preview,
  selectedWidgetContainerId: state.widgetRuleEditor.selectedWidgetContainerId,
  selectedPositionEdit: state.widgetRuleEditor.selectedPositionEdit,
});

const mapDispatchToProps = (dispatch) => ({
  handlePreviewPropertyChange: (property: Partial<WidgetRuleEditorRootState["preview"]>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PREVIEW_PROPERTY_CHANGE, property });
  },
});

const Preview = React.memo((props: PropTypes) => {
  const classes = useStyles(props);
  const {
    mobilePreview,
    preview,
    addContainerMode,
    reselectPositionMode,
    selectedPositionEdit,
    handleWidgetInsert,
    handleWidgetReselect,
    handlePreviewPropertyChange,
  } = props;

  const [selectedXPath, setSelectedXPath] = React.useState(null);
  const [currentHoveredXPath, setCurrentHoveredXPath] = React.useState("");

  const selectableMode = addContainerMode || reselectPositionMode;

  useEffect(() => {
    if (preview?.XPathCollections?.sizePos?.length > 0) {
      handlePreviewPropertyChange({ XPathCollectionsRendered: true });
    }

    return () => {
      handlePreviewPropertyChange({ XPathCollectionsRendered: false });
    };
  }, [preview.XPathCollections]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.previewWrapper} onClick={(e) => e.stopPropagation()}>
          <div className={ClassNames(classes.header, selectedPositionEdit && classes.showHeader)}>
            <Typography variant="h6" className={classes.headerTitle}>
              Select widget position for {selectedPositionEdit === "mobile" ? "mobile" : "desktop"}
            </Typography>
          </div>
          <div
            className={ClassNames(
              classes.paper,
              mobilePreview && classes.paperMobile,
              preview.fullScreen && classes.centeredPaper
            )}
          >
            <div className={classes.innerPreview}>
              <img className={classes.imagePreview} src={preview.previewBlobUrl} />
              <div className={classes.xpathContainers} id={"xpath-containers-list"}>
                {preview?.XPathCollections.sizePos.map((xpathCollection, index) => (
                  <div
                    key={xpathCollection.xpath}
                    className={ClassNames(
                      classes.xpathContainer,
                      (currentHoveredXPath === xpathCollection.fullXPath ||
                        selectedXPath?.fullXPath === xpathCollection.fullXPath) &&
                        classes.xpathContainerActive
                    )}
                    onMouseEnter={() => selectableMode && setCurrentHoveredXPath(xpathCollection.fullXPath)}
                    onMouseLeave={() => selectableMode && setCurrentHoveredXPath(null)}
                    onClick={() => {
                      if (!selectableMode) return;
                      if (reselectPositionMode) {
                        handleWidgetReselect(xpathCollection);
                      } else {
                        setSelectedXPath(xpathCollection);
                      }
                    }}
                    style={{
                      top: xpathCollection.top,
                      left: xpathCollection.left,
                      width: xpathCollection.width,
                      height: xpathCollection.height,
                    }}
                    {...xpathCollection.attributes}
                  >
                    {(currentHoveredXPath === xpathCollection.fullXPath ||
                      selectedXPath?.fullXPath === xpathCollection.fullXPath) && <AddRounded />}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addContainerMode && selectedXPath && (
        <WidgetPicker
          handleClose={() => {
            setSelectedXPath(null);
          }}
          handleWidgetInsert={(widget: IWidget) => {
            handleWidgetInsert(widget, selectedXPath);
            setSelectedXPath(null);
          }}
        />
      )}
    </>
  );
});

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
