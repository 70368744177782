import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    width: "100%",
    display: "flex",
    marginBottom: 10,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  closableRow: {
    // padding: '0 20px',
    display: "flex",
    alignItems: "center",
    // borderBottom: `solid 1px ${theme.palette.divider}`,
    "&:last-child": {
      // borderBottom: 'none',
    },
  },
  urlTextFieldInput: {
    minWidth: 100,
  },
  listItemIcon: {
    cursor: "pointer",
    marginLeft: 10,
    marginTop: -10,
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  hidden: {
    visibility: "hidden",
  },
  linksWrapper: {},
}));

type PropTypes = {
  links:
    | [
        {
          url: string;
          text: string;
        }
      ]
    | any;
  handleLinksChange: (links) => void;
};

const newLinkObject = { url: "", text: "" };
const FAQLinks = (props: PropTypes) => {
  const classes = useStyles(props);
  const { links = [], handleLinksChange } = props;

  return (
    <div className={classes.linksWrapper}>
      {[...links, newLinkObject].map((link, index) => (
        <div className={classes.closableRow}>
          <div className={classes.row}>
            <TextFieldMaxLength
              value={link.url}
              titleAndLengthLocation={"left"}
              title={I18n.t("outline.link", { number: index + 1 })}
              placeholder={I18n.t("outline.select_page")}
              borderRadius={4}
              titleMinWidth={170}
              height={37}
              marginRight={10}
              inputClassName={classes.urlTextFieldInput}
              handleValueChange={(value) => {
                if (links.length === index) {
                  handleLinksChange([...links, { url: value, text: "" }]);
                  return;
                }

                const updatedLinks = [...links];
                updatedLinks[index].url = value;
                handleLinksChange(updatedLinks);
              }}
            />
            <TextFieldMaxLength
              value={link.text}
              placeholder={I18n.t("outline.anchor_text")}
              borderRadius={4}
              titleMinWidth={170}
              height={37}
              handleValueChange={(value) => {
                if (links.length === index) {
                  handleLinksChange([...links, { url: "", text: value }]);
                  return;
                }

                const updatedLinks = [...links];
                updatedLinks[index].text = value;
                handleLinksChange(updatedLinks);
              }}
            />
          </div>
          <CloseIcon
            className={ClassNames(classes.listItemIcon, links.length === index && classes.hidden)}
            onClick={() => {
              const updatedLinks = [...links];
              updatedLinks.splice(index, 1);
              handleLinksChange(updatedLinks);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default FAQLinks;
