import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";

import { colors, widgetFieldIcons } from "../../../../../../../../helpers/constants";
import { IWidgetField } from "../../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  itemWrapper: {
    background: palette.secondary.dark,
    cursor: "pointer",
    padding: "20px",
    textAlign: "center",
    width: "100%",
    "&:hover": {
      background: colors.widgetOnHoverBackgroundColor,
    },
  },
  content: {
    flexGrow: 1,
  },
  labelWrapper: {
    marginTop: "5px",
    ":first-letter": {
      textTransform: "uppercase",
    },
  },
  img: {
    height: "30px",
    width: "30px",
    userSelect: "none",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    userSelect: "none",
    fontWeight: typography.fontWeightRegular as any,
    lineHeight: "normal",
  },
}));

type PropTypes = {
  item: IWidgetField;
  onClick: () => void;
};

const FieldItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { item, onClick } = props;

  return (
    <div className={classes.itemWrapper} onClick={onClick}>
      <div className={classes.imgContainer}>
        <img src={widgetFieldIcons[item?.type]} className={classes.img} />
      </div>
      <Typography variant={"body1"} className={classes.text}>
        {I18n.t(`widgets_builder.widget_item_selector.${item?.type}`)}
      </Typography>
    </div>
  );
};

export default FieldItem;
