import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useParams } from "react-router";
import { goBack, replace } from "connected-react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { I18n } from "react-redux-i18n";

import CfProgressBar from "../../../../components/CfProgressBar";
import { IExpert, IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import ExpertBottomBar from "./components/ExpertBottomBar";
import { web } from "../../../../helpers/urlHelper";
import ExpertHeader from "./components/ExpertHeader";
import ExpertOnlineProfile from "./components/ExpertOnlineProfile";
import ExpertEvaluation from "./components/ExpertEvaluation";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    overflowY: "auto",
    minHeight: "100vh",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: theme.palette.secondary.main,
    zIndex: 101,
  },
  pageLoader: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  detailsWrapper: {
    width: "100%",
    marginInline: "auto",
    maxWidth: "960px",
    display: "flex",
    minHeight: "calc(100vh - 125px)",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
  tabsWrapper: {
    marginBottom: 10,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    width: "100%",
  },
  tabIndicator: {
    paddingBottom: 3,
  },
  tab: {
    fontSize: 16,
    color: theme.palette.grey[500],
    textTransform: "none",
    minHeight: 60,
  },
  selectedTab: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;

  updateExpertId: (expertId: string) => void;
  returnToPreviousScreen: () => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  updateExpertId: (expertId) => dispatch(replace(web.entailExperts(expertId))),
  returnToPreviousScreen: () => dispatch(goBack()),
});

const defaultExpert = {
  visibility: {
    previousExperience: true,
    education: true,
    certifications: true,
    socialProfiles: true,
  },
  evaluation: {
    videoAssessment: {
      englishProficiency: null,
      willingToBeFilmed: null,
      videoCompatibility: null,
      internetConnection: null,
      mic: null,
      camera: null,
      lighting: null,
    },
    expertise: {
      subjectMatterKnowledge: null,
      currentIndustryTrends: null,
      practicalPersonalExamples: null,
      conveyConceptsConcisely: null,
    },
    onScreenPresence: {
      telegenicAppearance: null,
      charismaticPersonality: null,
      environmentAndSetting: null,
      backgroundNoise: null,
    },
    easeOfCollaboration: {
      punctuality: null,
      preparedness: null,
      responsiveness: null,
    },
    formatCompatibility: {
      interviews: null,
      tutorials: null,
      productReviewsAndDemos: null,
      exercises: null,
    },
  },
};

const ExpertPage = (props: PropTypes) => {
  const classes = useStyles(props);
  const { expertId } = useParams();
  const [expertLoaded, setExpertLoaded] = React.useState(false);
  const [expert, setExpert] = React.useState<IExpert>(null);
  const [tabIndex, setTabIndex] = React.useState(0);
  const rootElementRef = React.useRef<HTMLDivElement>(null);

  const { selectedWebsite, updateExpertId, returnToPreviousScreen } = props;

  React.useEffect(() => {
    if (!expertId) {
      return;
    }
    if (expertId === "new") {
      setExpert({
        website: selectedWebsite,
        ...defaultExpert,
      } as unknown as IExpert);
      setExpertLoaded(true);
      return;
    }
    agent.Experts.get(expertId)
      .then((res) => {
        setExpert(res.expert);
        setExpertLoaded(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [expertId, selectedWebsite]);

  const handleExpertChanged = React.useCallback(
    (newExpert: IExpert) => {
      if (newExpert._id && expertId !== newExpert._id) {
        updateExpertId(newExpert._id);
      }
      setExpert(newExpert);
    },
    [expertId, updateExpertId]
  );

  const tabContents = React.useMemo(
    () => [
      <ExpertOnlineProfile
        expert={expert}
        selectedWebsite={selectedWebsite}
        rootElementRef={rootElementRef}
        handleExpertChange={handleExpertChanged}
      />,
      <ExpertEvaluation expert={expert} rootElementRef={rootElementRef} handleExpertChange={handleExpertChanged} />,
    ],
    [expert, selectedWebsite, handleExpertChanged]
  );

  const evaluationStatus = React.useMemo(() => {
    const { evaluation } = expert || {};
    if (!evaluation) {
      return `0/0`;
    }

    const totalFields = Object.values(evaluation).flatMap(Object.values).length;
    const scoredFields = Object.values(evaluation).flatMap(Object.values).filter(Boolean).length;

    return `${scoredFields}/${totalFields}`;
  }, [expert]);

  return (
    <div className={classes.wrapper} ref={rootElementRef}>
      {!expertLoaded && (
        <div className={classes.pageLoader}>
          <CfProgressBar />
        </div>
      )}
      {expertLoaded && expert && <ExpertHeader expert={expert} handleGoBackClick={() => returnToPreviousScreen()} />}
      {expertLoaded && expert && (
        <div className={classes.detailsWrapper}>
          <Tabs
            className={classes.tabsWrapper}
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, v) => setTabIndex(v)}
            classes={{ indicator: classes.tabIndicator }}
          >
            <Tab
              label={I18n.t("experts.profile.tabs.online_profile")}
              classes={{ root: classes.tab, selected: classes.selectedTab }}
            />
            <Tab
              label={`${I18n.t("experts.profile.tabs.evaluation")} (${evaluationStatus})`}
              classes={{ root: classes.tab, selected: classes.selectedTab }}
            />
          </Tabs>
          {tabContents[tabIndex]}
        </div>
      )}
      {expertLoaded && expert && (
        <ExpertBottomBar
          expert={expert}
          selectedWebsite={selectedWebsite}
          handleExpertUpdated={(exp) => handleExpertChanged(exp)}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpertPage);
