import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import TagWriterSelect from "./TagWriterSelect";

const useStyles = makeStyles((theme: Theme) => ({
  sidebarWrapper: {
    height: "100%",
    padding: 20,
    backgroundColor: theme.palette.common.white,
    maxWidth: 280,
    width: "100%",
    borderLeft: `${theme.palette.divider} solid 1px`,
    zIndex: 1,
  },
}));

type PropTypes = {};

const TagRightSidebar = (props: PropTypes) => {
  const classes = useStyles(props);

  return (
    <div className={classes.sidebarWrapper}>
      <TagWriterSelect />
    </div>
  );
};

export default TagRightSidebar;
