import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  ColumnResizeMode,
  ColumnFiltersState,
  getSortedRowModel,
  SortingState,
  useReactTable,
  RowSelectionState,
} from "@tanstack/react-table";
import moment from "moment";
import numeral from "numeral";
import { connect } from "react-redux";
import { fuzzyFilter } from "./TableModels";
import CfSearchInput from "../../../../../components/CfSearchInput";
import { DATE_FORMAT } from "../../../../../reducers/constants/consts";
import InsightsTableCheckBox from "./InsightsTableCheckBox";
import theme from "../../../../../themes/theme";
import InsightsPagination from "./InsightsPagination";
import { INSIGHTS } from "../../../../../reducers/constants/actionTypes";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import EntailURLToolbar from "../../../../../components/EntailURLToolbar";
import CfProgressBar from "../../../../../components/CfProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  insightsTable: {
    marginTop: 25,
    paddingBottom: 20,
    height: "100%",
    overflowY: "auto",
  },
  tableWrapper: {
    marginTop: 25,
    position: "relative",
    maxWidth: "calc(100vw - 140px)",
    overflowX: "scroll",
    width: "100%",
  },

  outer: {
    position: "relative",
  },

  inner: {
    overflowX: "scroll",
    overflowY: "visible",
    width: "800px",
    marginLeft: "200px",
  },
  table: {
    backgroundColor: "white",
    borderSpacing: "0px",
    borderCollapse: "collapse",
  },
  tableHeaderRow: {
    height: 40,
    borderBottom: `${theme.palette.divider} solid 1px`,
  },
  tableRow: {
    height: 40,
    borderBottom: `${theme.palette.divider} solid 1px`,
    "&:hover": {
      "& $tableCell": {
        backgroundColor: theme.palette.divider,
      },
      "& $actions": {
        display: "flex",
      },
    },
  },
  tableCell: {
    padding: "0px 10px 0px 10px",
    fontSize: 12,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  first_left: {
    position: "sticky",
    left: 0,
    padding: "0px 10px 0px 0px",
    backgroundColor: "white",
  },
  second_left: {
    paddingLeft: "0!important",
    position: "sticky",
    minWidth: 180,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    backgroundColor: "white",
    cursor: "pointer",
  },
  third_left: {
    borderRight: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    left: 215,
    minWidth: 100,
    textAlign: "end",
    backgroundColor: "white",
    cursor: "pointer",
  },
  average_column: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  tableHeadFirst: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    flexGrow: 1,
    flexBasis: 0,
    minWidth: "10px",
    fontWeight: theme.typography.fontWeightBold as any,
    textAlign: "left",
    userSelect: "none",

    position: "sticky",
    left: 0,
  },
  checkBoxCol: {
    position: "sticky",
    left: 0,
  },
  nameCol: {
    position: "sticky",
    left: 26,
  },
  avgCol: {
    position: "sticky",
    left: 136,
  },
  websiteHead: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    flexGrow: 1,
    flexBasis: 0,
    minWidth: "10px",
    fontWeight: theme.typography.fontWeightBold as any,
    textAlign: "left",
    userSelect: "none",
  },
  tableMoreOptionsHead: {
    width: 30,
  },
  website: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    flexGrow: 1,
    flexBasis: 0,
    minWidth: "30px",

    position: "sticky",
    left: 30,
  },
  columnFirst: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    flexGrow: 1,
    flexBasis: 0,
    minWidth: "10px",

    position: "sticky",
    left: 0,
  },
  column: {
    minWidth: "110px",
    textAlign: "right",
  },
  row: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 5,
    paddingBottom: 5,
    display: "flex",
    top: 0,
    zIndex: 3,
  },
  horizontalTable: {
    overflowX: "scroll",
    width: "500px",
  },
  searchField: {
    width: "100%",
    maxWidth: 317,
    height: "unset",
  },
  searchInput: {
    height: 40,
    borderRadius: 3,
    padding: 10,
    backgroundColor: "white",
  },
  tableHeadCell: {
    textTransform: "capitalize",
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular as any,
    padding: "0px 10px 0px 10px",
  },
  nameCell: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold as any,
    cursor: "pointer",
    maxWidth: "300px",
    textTransform: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  nameCellWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    paddingRight: 35,
  },
  actions: {
    ["@media (max-width:1600px)"]: {
      width: "auto",
      position: "absolute",
      zIndex: "10",
    },
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 15,
  },
}));

// let defCols: ColumnDef<any>[] = [];

// let defaultColumns : ColumnDef<any>[] = [];

type PropTypes = {
  data: any;
  page: number;
  totalPages: number;
  pageSize: number;
  colors: any;
  seriesHeaders: any[];
  rowSelection: RowSelectionState;
  handleValueChanged: (name: string, value: any) => void;
  reportType: "website" | "page" | "allPages" | "event";
  isLoading: boolean;
};

const mapStateToProps = (state) => ({
  data: state.insights.data,
  page: state.insights.page,
  totalPages: state.insights.totalPages,
  colors: state.insights.colors,
  pageSize: state.insights.pageSize,
  seriesHeaders: state.insights.seriesHeaders,
  rowSelection: state.insights.rowSelection,
  reportType: state.insights.reportType,
  isLoading: state.insights.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  handleValueChanged: (name, value) => dispatch({ type: INSIGHTS.ON_VALUE_CHANGED, name, value }),
});

const InsightsTable = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    data,
    page,
    totalPages,
    pageSize,
    colors,
    seriesHeaders,
    rowSelection,
    handleValueChanged,
    reportType,
    isLoading,
  } = props;

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnResizeMode, setColumnResizeMode] = React.useState<ColumnResizeMode>("onChange");

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState("");

  const getNameValue = (value) => {
    switch (value) {
      case "ctr":
        return "SERP CTR";

      case "clicks":
        return "Organic Traffic";
      default:
        return _.capitalize(value);
    }
  };

  const defaultColumns = [
    {
      id: "select",
      header: ({ table, header }) => (
        <th key={header.id} className={ClassNames(classes.tableCell, classes.first_left)}>
          <InsightsTableCheckBox
            iconColor={theme.palette.primary.main}
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            value={"header"}
            handleChange={table.getToggleAllRowsSelectedHandler()}
          />
        </th>
      ),
      cell: ({ row, cell }) => {
        return (
          <td key={cell.id} className={ClassNames(classes.tableCell, classes.first_left)}>
            <InsightsTableCheckBox
              stringToColor={row.original?.name}
              iconColor={colors[row.original?.name]}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              handleChange={row.getToggleSelectedHandler()}
              value={"row"}
            />
          </td>
        );
      },
    },
    {
      id: "name",
      accessorKey: "name",
      header: (props: any) => {
        const { header, table } = props;
        return (
          <th
            key={header.id}
            style={{ textAlign: "left" }}
            colSpan={header.colSpan}
            className={ClassNames(classes.tableCell, classes.tableHeadCell, classes.second_left)}
          >
            {header.isPlaceholder ? null : (
              <div
                className={header.column.getCanSort() ? "cursor-pointer select-none resizer" : ""}
                onClick={table.getToggleAllRowsSelectedHandler()}
                style={{
                  transform:
                    columnResizeMode === "onEnd" && header.column.getIsResizing()
                      ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                      : "",
                }}
              >
                {header.id}
              </div>
            )}
          </th>
        );
      },
      cell: ({ row, cell, getValue }) => (
        <td
          key={cell.id}
          className={ClassNames(classes.tableCell, classes.tableHeadCell, classes.second_left)}
          onClick={row.getToggleSelectedHandler()}
        >
          <div className={classes.nameCellWrapper}>
            <Tooltip title={getNameValue(getValue())}>
              <Typography className={classes.nameCell}>{getNameValue(getValue())}</Typography>
            </Tooltip>
            {(reportType === "allPages" || reportType === "event") && (
              <EntailURLToolbar customClass={classes.actions} url={getNameValue(getValue())} />
            )}
          </div>
        </td>
      ),
    },
    {
      id: "average",
      accessorKey: "average",
      header: (header) => (
        <th
          key={header.id}
          className={ClassNames(classes.tableCell, classes.tableHeadCell, classes.third_left)}
          onClick={header.column.getToggleSortingHandler()}
        >
          <div className={classes.average_column}>
            {I18n.t("insights.average")}
            {{
              asc: <ArrowDropUp />,
              desc: <ArrowDropDown />,
            }[header.column.getIsSorted() as string] ?? null}
          </div>
        </th>
      ),
      cell: ({ cell, getValue }) => (
        <td key={cell.id} className={ClassNames(classes.tableCell, classes.third_left)}>
          {numeral(getValue()).format("0,0")}
        </td>
      ),
    },
    ...(seriesHeaders || []).map((element) => ({
      id: element,
      accessorFn: (originalRow, index: number) => {
        return originalRow.series[element] || 0;
      },
      header: ({ header }) => (
        <th
          key={header.id}
          className={ClassNames(classes.tableCell, classes.column, classes.tableHeadCell)}
          onClick={header.column.getToggleSortingHandler()}
        >
          <div className={classes.average_column}>
            {reportType === "event" ? header.id : moment(header.id).format(DATE_FORMAT.READABLE_CHART)}
            {{
              asc: <ArrowDropUp />,
              desc: <ArrowDropDown />,
            }[header.column.getIsSorted() as string] ?? null}
          </div>
        </th>
      ),
      cell: ({ cell, getValue }) => {
        return (
          <td key={cell.id} className={ClassNames(classes.tableCell, classes.column)}>
            {cell.row.original.name === "ctr" ? (
              <>{numeral(getValue()).format("0%")}</>
            ) : (
              <>{numeral(getValue()).format("0,0[.]")}</>
            )}
          </td>
        );
      },
    })),
  ].filter((row) => !(reportType === "event" && row.id === "average"));

  console.log("render table");
  const table = useReactTable({
    data,
    columns: defaultColumns,
    columnResizeMode,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: (updaterOrValue) => {
      if (typeof updaterOrValue === "function") {
        handleValueChanged("rowSelection", updaterOrValue(rowSelection));
      } else {
        handleValueChanged("rowSelection", updaterOrValue);
      }
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      rowSelection,
      sorting,
      columnFilters,
      globalFilter,
    },
    debugTable: true,
  });

  return isLoading ? (
    <div className={classes.loaderWrapper}>
      <CfProgressBar />
    </div>
  ) : (
    <div className={classes.insightsTable}>
      <div>
        <CfSearchInput
          customClass={classes.searchField}
          customInputClass={classes.searchInput}
          placeholder={I18n.t("insights.search_ph")}
          handleSearchTextChange={(text) => setGlobalFilter(text)}
        />
      </div>

      <div className={classes.tableWrapper}>
        <table className={classes.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <tr key={`hg_${headerGroup.id}_${index}`} className={ClassNames(classes.tableHeaderRow)}>
                {headerGroup.headers.map((header, index) => (
                  <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className={classes.tableRow}>
                {row.getVisibleCells().map((cell) => (
                  <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <InsightsPagination
        pageSize={pageSize}
        page={page}
        paginationPagesCount={totalPages}
        setPageSize={(value) => handleValueChanged("pageSize", value)}
        setPage={(value) => handleValueChanged("page", value)}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsTable);
