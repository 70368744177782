import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import _ from "lodash";
import ClassNames from "classnames";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import agent from "../../../../agent";
import EditUserRow from "./components/EditUserRow";
import UserRow from "./components/UserRow";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import MTable from "../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";

const tableHeader = "transparent";
const tableHeaderBorder = "transparent";
const tableBorder = "transparent";
const rowBg = "transparent";

const useStyles = makeStyles((theme: Theme) => ({
  usersTableWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    backgroundColor: "white",
  },
  tableContainer: {
    display: "block",
    margin: "2em auto",
    width: "90%",
    maxWidth: 1200,
    minWidth: 950,
    overflowX: "auto",
    boxSizing: "border-box",
    "& div": {
      boxSizing: "border-box",
    },
  },

  flexTable: {
    display: "flex",
    flexFlow: "row wrap",
    borderLeft: `solid 1px ${tableBorder}`,
    transition: "0.5s",
    // '&:first-of-type': {
    "&$header": {
      borderTop: `solid 1px ${tableHeaderBorder}`,
      borderLeft: `solid 1px ${tableHeaderBorder}`,
      "& $flexRow": {
        background: tableHeader,
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold as any,
        borderColor: tableHeaderBorder,
      },
    },
    "&$row:nth-child(odd)": {
      "& $flexRow": {
        background: rowBg,
      },
    },
    "&:hover": {
      background: theme.palette.secondary.main,
      transition: "500ms",
      borderRadius: 50,
      "&$topHeader": {
        backgroundColor: "transparent",
      },
      "&$header": {
        backgroundColor: "transparent",
      },
      "& $editIcon": {
        visibility: "unset",
        width: "unset",
        marginRight: 28,
      },
      "& $websiteIcon": {
        visibility: "hidden",
        width: 0,
        margin: 0,
      },
    },
  },

  flexRow: {
    width: "calc(100% / 8)",
    textAlign: "left",
    alignItems: "center",
    display: "flex",
    padding: "0em 1em",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    height: 75,
    borderRight: `solid 1px ${tableBorder}`,
    borderBottom: `solid 1px ${tableBorder}`,
    "& $first": {
      width: "100%",
      borderBottom: `solid 1px ${tableBorder}`,
    },
  },
  header: {
    "& $flexRow": {
      borderBottom: "solid 1px",
    },
  },

  rowspan: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "flex-start",
    justifyContent: "center",
  },

  column: {
    display: "flex",
    flexFlow: "column wrap",
    width: "75%",
    padding: 0,
    "& $flexRow": {
      display: "flex",
      flexFlow: "row wrap",
      width: "100%",
      padding: 0,
      border: 0,
      borderBottom: `solid 1px ${tableBorder}`,
      "&:hover": {
        background: "#F5F5F5",
        transition: "500ms",
      },
    },
  },
  flexCell: {
    width: "calc(100% / 3)", // 1px = border right
    textAlign: "center",
    padding: "0.5em 0.5em",
    borderRight: `solid 1px ${tableBorder}`,
    // flex: 1 1 33.3%;
    "&:last-child": {
      // border-right: 0;
    },
  },
  first: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  row: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  topHeader: {
    "& $flexRow": {
      paddingLeft: 100,
      height: 30,
      color: theme.palette.text.hint,
      textAlign: "center",
      fontSize: theme.typography.pxToRem(12),
    },
  },
  spacer: { width: "35%" },
  total: {
    width: "33%",
  },
  monthly: { width: "32%" },

  name: {
    width: "20%",
  },
  nameWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  userName: { width: "10%" },
  role: { width: "10%" },
  postTypes: { width: "10%" },
  locales: { width: "10%" },
  websites: { width: "10%" },
  email: { width: "10%" },
  ppw: { width: "10%" },
  password: { width: "10%" },

  websiteIcon: {
    marginRight: 20,
    fill: theme.palette.primary.main,
  },
  editIcon: {
    fontSize: theme.typography.pxToRem(16),
    cursor: "pointer",
    marginRight: 0,
    visibility: "hidden",
    width: 0,
    "&:hover": {
      fill: theme.palette.primary.main,
      transition: "500ms",
    },
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    marginRight: 5,
    marginLeft: 5,
    paddingRight: 10,
    paddingLeft: 10,
    minWidth: 0,
    minHeight: 30,
  },
  textField: {
    margin: 0,
  },
  textFieldInput: {},
  red: {
    color: "red",
  },
  newUserButton: {
    padding: 20,
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type PropTypes = {
  websitesLoaded: boolean;
  websites: IWebsite[];
};

const mapStateToProps = (state) => ({
  websitesLoaded: state.home.websitesLoaded,
  websites: state.home.websites,
});

const mapDispatchToProps = (dispatch) => ({});

const RolesAndPermissions = (props: PropTypes) => {
  const classes = useStyles(props);
  const { websitesLoaded, websites } = props;
  const [newUser, setNewUser] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [editedUser, setEditedUser] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!websitesLoaded) {
      return;
    }
    agent.Admin.getUsers()
      .then((res) => {
        setUsers(res.users);
      })
      .catch((e) => {});
  }, [websitesLoaded]);

  const onUpdateUserClicked = (u, isCreateNewUser) => {
    if (!u.name || !u.username || !u.email || !u.role) {
      alert("All fields are required!");
      return;
    }
    agent.Auth.upsertUser(u)
      .then((res) => {
        if (isCreateNewUser) {
          setNewUser(null);
          setUsers([...users, res.user]);
        } else {
          setEditedUser(null);
          setUsers(
            users.map((user) => {
              if (user._id === res.user._id) {
                return res.user;
              }
              return user;
            })
          );
        }
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  const deleteUser = (user) => {
    agent.Admin.deleteUser(user)
      .then((res) => {
        setUsers(_.filter(users, (u) => u._id !== user._id));
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  return (
    <MTableWrapper>
      <MTable>
        <MTableHeader>
          <MTableHeaderLabel columnWidth={"20%"}> {I18n.t("manage_content.users.headers.name")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("manage_content.users.headers.last_active")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("manage_content.users.headers.user_name")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("manage_content.users.headers.role")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("manage_content.users.headers.websites")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("manage_content.users.headers.locales")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("manage_content.users.headers.ppw")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("manage_content.users.headers.ppt")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("manage_content.users.headers.email")}</MTableHeaderLabel>
        </MTableHeader>
        {users === null && <MTableRowProgressBar />}
        {users && users.length === 0 && <MTableRowEmptyRow />}
        {users &&
          users.length > 0 &&
          users.map((user, index) => (
            <>
              {editedUser !== null && user._id === editedUser._id ? (
                <EditUserRow
                  key={editedUser._id}
                  user={editedUser}
                  websites={websites}
                  handleUserChanged={(u) => setEditedUser(u)}
                  handleSaveClick={() => onUpdateUserClicked(editedUser, false)}
                  handleCancelClick={() => setEditedUser(null)}
                  handleDeleteUserClick={() => deleteUser(user)}
                />
              ) : (
                <UserRow key={user._id} user={user} handleEditUserClick={() => setEditedUser(user)} />
              )}
            </>
          ))}
      </MTable>
      {!newUser && (
        <ListItemText
          onClick={() => setNewUser({})}
          primary={I18n.t("manage_content.users.buttons.new_user")}
          primaryTypographyProps={{ variant: "body2", className: classes.newUserButton }}
        />
      )}
      {newUser && (
        <EditUserRow
          key={"new_user_id"}
          user={newUser}
          websites={websites}
          handleUserChanged={(u) => setNewUser(u)}
          handleSaveClick={() => onUpdateUserClicked(newUser, true)}
          handleCancelClick={() => setNewUser(null)}
        />
      )}
    </MTableWrapper>
  );

  return (
    <div className={classes.usersTableWrapper}>
      <div className={classes.tableContainer} role="table" aria-label="Destinations">
        <div className={ClassNames(classes.flexTable, classes.header)} role="rowgroup">
          <div className={ClassNames(classes.flexRow, classes.name)} role="columnheader">
            {I18n.t("manage_content.users.headers.name")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.userName)} role="columnheader">
            {I18n.t("manage_content.users.headers.user_name")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.role)} role="columnheader">
            {I18n.t("manage_content.users.headers.role")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.ppw)} role="columnheader">
            {I18n.t("manage_content.users.headers.ppw")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.postTypes)} role="columnheader">
            {I18n.t("manage_content.users.headers.post_types")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.locales)} role="columnheader">
            {I18n.t("manage_content.users.headers.locales")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.websites)} role="columnheader">
            {I18n.t("manage_content.users.headers.websites")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.email)} role="columnheader">
            {I18n.t("manage_content.users.headers.email")}
          </div>
        </div>
        {users &&
          users.map((user, index) => (
            <div key={user._id}>
              {editedUser !== null && user._id === editedUser._id ? (
                <EditUserRow
                  key={editedUser._id}
                  user={editedUser}
                  websites={websites}
                  handleUserChanged={(u) => setEditedUser(u)}
                  handleSaveClick={() => onUpdateUserClicked(editedUser, false)}
                  handleCancelClick={() => setEditedUser(null)}
                  handleDeleteUserClick={() => deleteUser(user)}
                />
              ) : (
                <UserRow key={user._id} user={user} handleEditUserClick={() => setEditedUser(user)} />
              )}
            </div>
          ))}
        {!newUser && (
          <Typography
            onClick={() => setNewUser({})}
            color={"textSecondary"}
            className={classes.newUserButton}
            variant={"body2"}
          >
            {I18n.t("manage_content.users.buttons.new_user")}
          </Typography>
        )}
        {newUser && (
          <EditUserRow
            key={"new_user_id"}
            user={newUser}
            websites={websites}
            handleUserChanged={(u) => setNewUser(u)}
            handleSaveClick={() => onUpdateUserClicked(newUser, true)}
            handleCancelClick={() => setNewUser(null)}
          />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesAndPermissions);
