import { omit } from "lodash";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const MIFrameWrapper = ({ children, ...props }) => {
  const [contentRef, setContentRef] = React.useState(null);
  const mountNode = contentRef?.contentWindow?.document;
  const observerRef = useRef(null);

  useEffect(() => {
    if (!contentRef) return;

    const iframeDocument = contentRef.contentWindow?.document;
    if (!iframeDocument) return;

    const widgetRoot = iframeDocument.getElementById("widget-root");

    if (widgetRoot) {
      // Calculate and initially set the height
      const height = widgetRoot.scrollHeight;
      if (props.onHeightChange) {
        props.onHeightChange(height);
      }

      if (props.onObserverChange) {
        props.onObserverChange();
      }

      // Create a MutationObserver to watch for changes in the iframe's content
      observerRef.current = new MutationObserver(() => {
        if (props.onObserverChange) {
          props.onObserverChange();
        }
        if (props.onHeightChange) {
          const widgetRoot = iframeDocument.getElementById("widget-root");
          if (!widgetRoot) return;
          const newHeight = widgetRoot.scrollHeight;
          props.onHeightChange(newHeight);
        }
      });

      // Start observing the widgetRoot element for changes
      observerRef.current.observe(widgetRoot, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    // eslint-disable-next-line consistent-return
    return () => {
      // Cleanup: disconnect the observer when the component unmounts
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [contentRef, props]);

  return (
    <iframe {...omit(props, ["onHeightChange", "onObserverChange"])} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode?.body)}
    </iframe>
  );
};

export default MIFrameWrapper;
