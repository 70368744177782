import { Editor, Range, Transforms } from "slate";
import { editorType } from "../../types/editor.Types";
import RichTextEditorHelper from "../../helper/richTextEditorHelper";

const isCommentActive = (editor) => {
  const [comment] = Editor.nodes(editor, { match: (n) => n.type === editorType.comment });
  return !!comment;
};

const unwrapComment = (editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === editorType.comment });
};

const wrapComment = (editor, comments) => {
  const { selection } = editor;
  if (isCommentActive(editor)) {
    console.log("wrapComment: update current comment");
    Transforms.setNodes(
      editor,
      {
        comments,
      },
      {
        match: (n) => n.type === editorType.comment,
      }
    );
    return;
  }

  console.log("wrapComment: selection=", selection);
  const isCollapsed = selection && Range.isCollapsed(selection);
  console.log("wrapComment: isCollapsed=", isCollapsed);
  const comment = {
    type: editorType.comment,
    uuid: RichTextEditorHelper.getUuid(),
    comments,
    // children: isCollapsed ? [{ text: '' }] : [],
    children: [],
  };

  console.log("wrapComment: comments=", comment);

  if (isCollapsed) {
    console.log("wrapComment: isCollapsed=", isCollapsed);
    Transforms.insertNodes(editor, comment);
    console.log("wrapComment: isCollapsed end=", isCollapsed);
  } else {
    console.log("wrapComment: isCollapsed", isCollapsed);
    Transforms.wrapNodes(editor, comment, { split: true });
    Transforms.collapse(editor, { edge: "end" });
    console.log("wrapComment: isCollapsed end=", isCollapsed);
  }
};

const insertComment = (editor, comments) => {
  console.log("insertComment", comments);
  if (editor.selection) {
    console.log("wrap comment");
    wrapComment(editor, comments);
  }
};

const removeComment = (editor) => {
  console.log("removeComment");
  unwrapComment(editor);
};

const withComments = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => (element.type === editorType.comment ? true : isInline(element));

  // editor.insertText = text => {
  //   if (false && text) {
  //   // if (text && isUrl(text)) {
  //     wrapComment(editor, text);
  //   } else {
  //     insertText(text);
  //   }
  // };

  // editor.insertData = data => {
  //   console.log('withLinks insertData');
  //   const text = data.getData('text/plain');
  //
  //   // if (text && isUrl(text)) {
  //   if (text) {
  //     wrapComment(editor, text);
  //   } else {
  //     insertData(data);
  //   }
  // };

  return editor;
};

export default {
  withComments,
  insertComment,
  removeComment,
  isCommentActive,
};
