import React from "react";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import ButtonRightIcon from "../../../../icons/ButtonRightIcon";
import { buttonDefaultCss } from "../../../../containers/WebsiteConfigurations/WebsiteEditor/consts/CssConsts";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: "none",
    width: (props: PropTypes) => props.css?.width,
  },
  callToActionBtnProps: (props: any) => ({
    ...props.defaultCss,
    ...props.css,
    "&:hover": {
      ...props.defaultCss["&:hover"],
      ...props.hoverCss,
    },
  }),
  callToActionWithArrow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  arrow: {
    marginLeft: 15,
    width: 12,
    height: 12,
  },
}));

type PropTypes = {
  className?: any;
  text: string;
  url: string;
  css: object;
  hoverCss: object;
  readMode?: boolean;
  showArrow?: boolean;

  websiteTheme: IWebsiteThemeProps;
};

const ButtonWidgetBaseElement = (props: PropTypes) => {
  const { text, url, readMode = false, showArrow = false, css, hoverCss, websiteTheme, className = null } = props;
  const defaultCss = React.useMemo(() => buttonDefaultCss(websiteTheme), [websiteTheme]);
  const classes = useStyles({ ...props, defaultCss });

  if (readMode) {
    return (
      <a href={url} target={"_blank"} className={classes.link}>
        <button
          className={ClassNames(classes.callToActionBtnProps, className, showArrow && classes.callToActionWithArrow)}
        >
          {text}
          {showArrow && <ButtonRightIcon className={classes.arrow} />}
        </button>
      </a>
    );
  }

  return (
    <button className={ClassNames(classes.callToActionBtnProps, className, showArrow && classes.callToActionWithArrow)}>
      <span>{text}</span>
      {showArrow && <ButtonRightIcon className={classes.arrow} />}
    </button>
  );
};

export default ButtonWidgetBaseElement;
