import React from "react";
import { I18n } from "react-redux-i18n";
import SelectProperty from "./itemProperties/components/SelectProperty";
import { eWidgetFieldDateFormat, IWidgetDateFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import GenericInputWidgetItem from "./components/GenericInputWidgetItem";

type PropTypes = {
  properties: IWidgetDateFieldProps;
  onPropertyChange(properties: IWidgetDateFieldProps): void;
};

const DateWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;

  const onDateFormatChange = (dateFormat: eWidgetFieldDateFormat) => {
    onPropertyChange({ ...properties, dateFormat });
  };

  return (
    <GenericInputWidgetItem properties={properties} onPropertyChange={onPropertyChange}>
      <SelectProperty
        label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.date_format")}
        showLabel
        onChange={onDateFormatChange}
        options={[eWidgetFieldDateFormat.READABLE, eWidgetFieldDateFormat.CALENDAR]}
        value={properties.dateFormat}
      />
    </GenericInputWidgetItem>
  );
};

export default DateWidgetItem;
