import * as React from "react";
import { connect } from "react-redux";
import { IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import EditorOutline from "../components/EditorOutline";
import { PAGE_EDITOR } from "../../../reducers/constants/actionTypes";

type PropTypes = {
  selectedWebsite: IWebsite;
  pageLoaded: boolean;
  page: IPage;

  handlePageChange: (page: IPage) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  pageLoaded: state.pageEditor.pageLoaded,
  page: state.pageEditor.page,
});

const mapDispatchToProps = (dispatch) => ({
  handlePageChange: (page) => dispatch({ type: PAGE_EDITOR.PAGE_CHANGED, page }),
});

const PageEditorOutline = (props: PropTypes) => {
  const { pageLoaded, page, selectedWebsite, handlePageChange } = props;

  if (!pageLoaded || !page.outline) {
    return null;
  }

  return <EditorOutline outline={page.outline} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorOutline);
