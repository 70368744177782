import React from "react";

const LiteRenderLeaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = (
      <pre
        style={{
          background: "#e8f1ff",
          border: "1px solid #ddd",
          borderLeft: "3px solid #f36d33",
          color: "#666",
          pageBreakInside: "avoid",
          fontFamily: "monospace",
          fontSize: 15,
          lineHeight: 1.6,
          marginBottom: "1.6em",
          maxWidth: "100%",
          overflow: "auto",
          padding: "1em 1.5em",
          display: "block",
          wordWrap: "break-word",
        }}
      >
        <code>{children}</code>
      </pre>
    );
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.color) {
    children = <span style={{ color: leaf.color }}>{children}</span>;
  }

  // if (leaf.placeholder) {
  // 	return <span style={{position: 'relative'}}>
  // 		<span {...attributes}>{children}</span>
  // 		<span
  // 			style={{ opacity: 0.3 }}
  // 			contentEditable={false}
  // 		>
  // 			Type / to open menu
  // 		</span>
  // 	</span>;
  // }

  return <span {...attributes}>{children}</span>;
};

export default LiteRenderLeaf;
