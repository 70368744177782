import { Variant } from "@material-ui/core/styles/createTypography";
import { IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";

export const fontOptions = [
  "adobe-caslon-pro, serif",
  '"Arpona Sans",sans-serif',
  '"Archivo",Sans-serif',
  '"Avenir Next", sans-serif',
  '"Bagoss Condensed", sans-serif',
  "Cera Pro",
  "Circularstd, sans-serif",
  "'DM Sans', sans-serif",
  "Dosis, sans-serif",
  '"Figtree", sans-serif',
  "'Fira Sans', sans-serif",
  "'GothamPro', sans-serif",
  "GothamPro-Medium",
  "Graphik,sans-serif",
  "Graphik Web,sans-serif",
  "GT Alpina,sans-serif",
  '"Gt walsheim pro", sans-serif',
  '"Hank Rounded", sans-serif',
  '"Helvetica Neue",Helvetica,Arial,sans-serif',
  "Inter, sans-serif",
  '"Lato", sans-serif',
  "Metropolis, Arial, Helvetica",
  "Montserrat,Nunito Sans,sans-serif",
  "Mulish",
  '"Neue Swift", serif',
  "Neue-Haas-Grotesk-Text",
  "Noto Sans,Arial,Helvetica,sans-serif",
  "Noto Serif JP",
  "Noto Serif",
  "Nunito Sans,sans-serif",
  "'Open Sans',sans-serif",
  "Poppins, sans-serif",
  "Railroad-Gothic-ATF",
  '"Roboto", sans-serif',
  "Rubik,Arial,Helvetica,sans-serif",
  "Sharp Sans, sans-serif",
  "source-sans-pro, sans-serif",
  "Sora, Sans-Serif",
  "Suisseintl, sans-serif",
  "Ubuntu, sans-serif",
  '"Unna",serif',
  '"Varela Round", sans-serif',
  "Verdana, sans-serif",
  "Raleway, sans-serif",
];
export const fontStyleOptions = ["inherit", "initial", "italic", "normal", "oblique", "unset"];
export const weightOptions = [
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
  "lighter",
  "normal",
  "bold",
];
export const letterCaseOptions = ["lowercase", "uppercase", "capitalize", "none"];
export const textDecorationOptions = ["underline", "overline", "line-through", "none"];
export const borderStyleOptions = ["solid", "dashed", "dotted", "double", "groove", "inset", "outset", "ridge", "none"];

export const buttonDefaultCss = (websiteTheme: IWebsiteThemeProps, elementDefaultCss = {}) => ({
  cursor: "pointer",
  fontSize: 14,
  whiteSpace: "pre",
  color: websiteTheme.palette.secondary.light,
  backgroundColor: websiteTheme.palette.primary.main,
  textTransform: "capitalize" as "capitalize",
  lineHeight: 1.1,

  padding: "15px 25px 15px 25px",
  margin: "0px 0px 0px 0px",

  borderStyle: "none",
  borderWidth: "0px 0px 0px 0px",
  borderRadius: "0px 0px 0px 0px",
  // minHeight: 45,
  textAlign: "center",
  transition: "all 1s cubic-bezier(.175,.885,.32,1)",
  webkitTransition: "all 1s cubic-bezier(.175,.885,.32,1)",
  ...elementDefaultCss,
  ...websiteTheme.buttons.css,
  "&:hover": {
    color: websiteTheme.palette.background.default,
    backgroundColor: websiteTheme.palette.primary.light,
    ...websiteTheme.buttons.css["&:hover"],
    ...(elementDefaultCss?.["&:hover"] || {}),
  },
});

export const cardDefaultCss = (websiteTheme: IWebsiteThemeProps, elementDefaultCss = {}) => ({
  padding: "10px",
  backgroundColor: websiteTheme.palette.background.default,
  borderColor: websiteTheme.palette.divider,
  borderStyle: "solid",
  borderWidth: "1px 1px 1px 1px",
  borderRadius: "0px 0px 0px 0px",
  ...elementDefaultCss,
});

export const typographyDefaultCss = (websiteTheme: IWebsiteThemeProps, variant: Variant, elementDefaultCss = {}) => ({
  ...(websiteTheme.typography[variant]?.css || {}),
  ...elementDefaultCss,
  "&:hover": {
    ...(websiteTheme.typography[variant]?.css?.["&:hover"] || {}),
    ...(elementDefaultCss?.["&:hover"] || {}),
  },
});
