import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Theme } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";
import { IWidgetPickerState } from "../../../../reducers/widgetPickerReducer";
import ability from "../../../../casl/ability";
import { eUserRoles } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  emptyContainer: {
    height: 77,
    borderTop: "1px solid #E5E5E5",
  },
  container: {
    background: "#FFFFFF",
    height: 77,
    padding: "0px 20px",
    gap: "20px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1px solid #E5E5E5",
  },
  primaryButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    textTransform: "none",
    height: 38,
    padding: "10px 20px",
    borderRadius: 20,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  secondaryButton: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

type PropTypes = {
  selectedWidget: IWidgetPickerState["selectedWidget"];
  handleWidgetEditClick: () => void;
  handleTemplateSelectClick: () => void;
  handleWidgetInsertClick?: () => void;
};

const WidgetPickerPreviewBottom = (props: PropTypes) => {
  const { selectedWidget } = props;
  const { handleWidgetEditClick, handleWidgetInsertClick, handleTemplateSelectClick } = props;

  const classes = useStyles();

  if (!selectedWidget) {
    return <div className={classes.emptyContainer} />;
  }

  if (selectedWidget.isTemplate) {
    return (
      <div className={classes.container}>
        {ability.can(eUserRoles.ADMIN, "role") ? (
          <Button
            className={classNames(classes.secondaryButton, classes.primaryButton)}
            onClick={handleWidgetEditClick}
          >
            {I18n.t("widget_picker.bottom.edit_template")}
          </Button>
        ) : (
          <div></div>
        )}
        <Button className={classes.primaryButton} onClick={handleTemplateSelectClick}>
          {I18n.t("widget_picker.bottom.select_template")}
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Button className={classNames(classes.secondaryButton, classes.primaryButton)} onClick={handleWidgetEditClick}>
        {I18n.t("widget_picker.bottom.edit_widget")}
      </Button>
      {handleWidgetInsertClick && (
        <Button className={classes.primaryButton} onClick={handleWidgetInsertClick}>
          {I18n.t("widget_picker.bottom.insert_widget")}
        </Button>
      )}
    </div>
  );
};

export default WidgetPickerPreviewBottom;
