import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { loadTranslations, setLocale, syncTranslationWithStore, i18nReducer } from "react-redux-i18n";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { translations } from "../locales/translations";
import reducer from "./reducer";
import { promiseMiddleware } from "./middlewares";
import { mixpanelMiddleware } from "./reducers/middlewares/mixpanelMiddleware";
import mixpanel from "mixpanel-browser/src/loaders/loader-module-core";
import { PROD, STAGING } from "./reducers/constants/consts";

mixpanel.init("325516ce315044d63602c7644a967d6d", { debug: true, track_pageview: false, persistence: "localStorage" });

export const history = createBrowserHistory();

const locationChangeListeners = new Set();
export const addLocationListener = (listener: Function) => {
  locationChangeListeners.add(listener);
};
export const removeLocationListener = (listener) => {
  locationChangeListeners.delete(listener);
};

const unlisten = history.listen((location, action) => {
  console.log("listen", action, location);
  for (const listener of Array.from(locationChangeListeners).reverse()) {
    const call: any = listener;
    call(action, location);
  }
});

// Build the middleware for intercepting and dispatching navigation actions
const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  if (PROD || STAGING) {
    return applyMiddleware(
      thunk,
      myRouterMiddleware,
      promiseMiddleware,
      mixpanelMiddleware(mixpanel, true),
      // facebookPixelMiddleware(true),
      createLogger()
    );
  }
  // Enable additional logging in non-production environments.
  return applyMiddleware(
    thunk,
    myRouterMiddleware,
    promiseMiddleware,
    mixpanelMiddleware(mixpanel, false),
    // facebookPixelMiddleware(false),
    createLogger()
  );
};

const store = createStore(reducer(history), composeWithDevTools(getMiddleware()));
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale("en"));

export default store;
