import { makeStyles } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { WIDGET_BUILDER } from "../../../../../../../../reducers/constants/actionTypes";
import { IWidgetSettings } from "../../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "../../../build/items/components/GenericValueItemProperty";
import CustomSwitchOption from "../../CustomSwitchOption";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
  },
}));

type PropTypes = {
  settings: IWidgetSettings;
  editSettings: (settings: IWidgetSettings) => void;
};

const mapStateToProps = (state) => ({
  settings: state.widgetBuilder.widget.settings,
});

const mapDispatchToProps = (dispatch) => ({
  editSettings: (payload: IWidgetSettings) => dispatch({ type: WIDGET_BUILDER.EDIT_SETTINGS, payload }),
});

const defaultTriggerSettingsValues: IWidgetSettings["triggers"] = {
  onPageLoad: {
    value: false,
  },
  timeOnPage: {
    value: false,
    duration: "1",
  },
  onScroll: {
    value: false,
    scrollPosition: "50",
  },
  onScrollElement: {
    value: false,
    elementId: null,
  },
  onClick: {
    value: false,
    elementId: null,
  },
  onExitIntent: {
    value: false,
  },
};

const TriggerSettings = (props: PropTypes) => {
  const classes = useStyles();

  const { settings } = props;
  const { editSettings } = props;

  const triggerSettings = { ...defaultTriggerSettingsValues, ...(settings.triggers ?? {}) };

  const { onPageLoad, timeOnPage, onScroll, onScrollElement, onClick, onExitIntent } = triggerSettings;

  const onChange = (field: Partial<IWidgetSettings["triggers"]>) => {
    editSettings({ ...settings, triggers: { ...triggerSettings, ...field } });
  };

  const getText = (fieldKey: string, type: string) =>
    I18n.t(`widgets_builder.widget_editor.Dialog.settings.triggers.${fieldKey}.${type}`);

  return (
    <div className={classes.root}>
      <CustomSwitchOption
        title={getText("onPageLoad", "title")}
        onChange={(value) => onChange({ onPageLoad: { ...onPageLoad, value } })}
        checked={onPageLoad.value}
        label={getText("onPageLoad", "label")}
      />

      <CustomSwitchOption
        title={getText("timeOnPage", "title")}
        onChange={(value) => onChange({ timeOnPage: { ...timeOnPage, value } })}
        checked={timeOnPage.value}
        label={getText("timeOnPage", "label")}
      >
        {timeOnPage.value && (
          <GenericValueItemProperty
            value={timeOnPage.duration}
            numbersOnly
            noDecimals
            onChange={({ value }) => onChange({ timeOnPage: { ...timeOnPage, duration: value } })}
            label={getText("timeOnPage", "durationLabel")}
            labelMinWidth={150}
            inlineLabel
          />
        )}
      </CustomSwitchOption>

      <CustomSwitchOption
        title={getText("onScroll", "title")}
        onChange={(value) => onChange({ onScroll: { ...onScroll, value } })}
        checked={onScroll.value}
        label={getText("onScroll", "label")}
      >
        {onScroll.value && (
          <GenericValueItemProperty
            value={onScroll.scrollPosition}
            numbersOnly
            onChange={({ value }) => onChange({ onScroll: { ...onScroll, scrollPosition: value } })}
            label={getText("onScroll", "scrollPositionLabel")}
            labelMinWidth={150}
            inlineLabel
          />
        )}
      </CustomSwitchOption>

      <CustomSwitchOption
        title={getText("onScrollElement", "title")}
        onChange={(value) => onChange({ onScrollElement: { ...onScrollElement, value } })}
        checked={onScrollElement.value}
        label={getText("onScrollElement", "label")}
      >
        {onScrollElement.value && (
          <GenericValueItemProperty
            value={onScrollElement.elementId}
            onChange={({ value }) => onChange({ onScrollElement: { ...onScrollElement, elementId: value } })}
            label={getText("onScrollElement", "elementIdLabel")}
          />
        )}
      </CustomSwitchOption>

      <CustomSwitchOption
        title={getText("onClick", "title")}
        onChange={(value) => onChange({ onClick: { ...onClick, value } })}
        checked={onClick.value}
        label={getText("onClick", "label")}
      >
        {onClick.value && (
          <GenericValueItemProperty
            value={onClick.elementId}
            onChange={({ value }) => onChange({ onClick: { ...onClick, elementId: value?.replace(/\s/g, "") } })}
            label={getText("onClick", "elementIdLabel")}
          />
        )}
      </CustomSwitchOption>
      <CustomSwitchOption
        title={getText("onExitIntent", "title")}
        onChange={(value) => onChange({ onExitIntent: { ...onExitIntent, value } })}
        checked={onExitIntent.value}
        label={getText("onExitIntent", "label")}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TriggerSettings);
