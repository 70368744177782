import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PageSection from "../../components/PageSection";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";

import MButton from "../../../../components/MButton";
import agent from "../../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  title: {},
}));

const mapStateToProps = (state) => ({
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

type PropTypes = {
  website: IWebsite;
};

const PageCacheInvalidation = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website } = props;
  const [cacheRules, setCacheRules] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const updateUrls = () => {
    const rulesArray = cacheRules.split("\n");

    if (!rulesArray || rulesArray.length === 0) {
      console.log("return");
      return;
    }
    setLoading(true);
    console.log("seneding request");

    agent.Cache.cloudFrontInvalidation(website, rulesArray)
      .then((res) => {
        setLoading(false);
        if (res.error) {
          enqueueSnackbar(I18n.t("snackbar.update_error", { msg: res.message }));
          return;
        }
        setResults(res.results);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  if (!website.configurations.siteDetails.distributionId) {
    return (
      <PageSection className={classes.wrapper} sectionTitle={I18n.t("website.page_types.cache-invalidation")}>
        <Typography className={classes.title} variant={"subtitle2"}>
          {I18n.t("website.cache.missing_id")}
        </Typography>
      </PageSection>
    );
  }

  return (
    <PageSection className={classes.wrapper} sectionTitle={I18n.t("website.page_types.cache-invalidation")}>
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        title={I18n.t("website.cache.title")}
        titleClassName={classes.title}
        value={cacheRules}
        placeholder={I18n.t("website.cache.placeholder")}
        height={200}
        multiLine
        borderRadius={4}
        handleValueChange={(propValue) => {
          setCacheRules(propValue);
        }}
        marginBottom={30}
      />
      <MButton
        loading={loading}
        // customClassNames={classes.button}
        disabled={!cacheRules}
        onClick={(e) => {
          e.stopPropagation();
          if (results) {
            setResults(null);
            setCacheRules("");
            return;
          }
          updateUrls();
        }}
      >
        {results ? I18n.t("admin.indexing_api.clear") : I18n.t("admin.indexing_api.update")}
      </MButton>
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCacheInvalidation);
