import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

import GenericOptionContainer from "../../../../../WidgetsBuilder/FormWidgetEditor/components/GenericOptionContainer";
import ArrowRightRoundedIcon from "../../../../../../../../icons/ArrowRightRoundedIcon";
import MSwitch from "../../../../../../../../components/MSwitch";
import { colors } from "../../../../../../../../helpers/constants";
import LocateIcon from "../../../../../../../../icons/LocateIcon";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: 2,
    marginBottom: 2,
  },
  leftSide: {
    borderRadius: 3,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#EEF2F9",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 55,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: colors.widgetOnHoverBackgroundColor,
    },
  },
  rightSide: {
    minWidth: 0,
    width: "100%",
    position: "relative",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginBottom: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  leftContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    minWidth: 0,
    "&:hover": {
      "& $title": {
        color: palette.primary.main,
        textDecoration: "underline",
      },
      "& svg": {
        fill: palette.primary.main,
      },
    },
  },
  disabled: {
    "& $title": {
      color: palette.text.secondary,
    },
    "& svg": {
      fill: palette.text.secondary,
    },
    "&:hover": {
      cursor: "default",
      "& $title": {
        textDecoration: "none",
        color: palette.text.secondary,
      },
      "& svg": {
        fill: palette.text.secondary,
      },
    },
  },
  textWrapper: {
    overflow: "hidden",
  },
  title: {
    fontSize: 14,
    fontWeight: typography.fontWeightRegular as any,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: palette.text.primary,
  },
  rightContainer: {
    display: "flex",
    flexShrink: 0,
    justifySelf: "flex-end",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "right",
    marginLeft: 10,
    minWidth: 30,
    gap: 13,
  },
  arrowIcon: {
    fontSize: 19,
    color: palette.text.primary,
    opacity: 1,
    padding: 5,
    paddingLeft: 0,
    boxSizing: "content-box",
    marginRight: -15,
  },
}));

type PropTypes = {
  title: string;
  enabled?: boolean;
  id?: string;
  handleSwitchChange: (enabled: boolean) => void;
  onEditClick: () => void;
  onLocateClick?: () => void;
};

const WidgetContainerListItem = (props: PropTypes) => {
  const { title, enabled, id } = props;
  const { onLocateClick, onEditClick, handleSwitchChange } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div
        id={id}
        className={ClassNames(classes.leftSide, (!enabled || !onLocateClick) && classes.disabled)}
        onClick={() => {
          if (onLocateClick) {
            onLocateClick();
          }
        }}
      >
        <LocateIcon />
      </div>

      <GenericOptionContainer customClass={classes.rightSide} onClick={onEditClick}>
        <>
          <div className={ClassNames(classes.leftContainer, !enabled && classes.disabled)}>
            <div className={classes.textWrapper}>
              <Typography className={classes.title}>{title}</Typography>
            </div>
          </div>

          <div className={classes.rightContainer}>
            <div onClick={(e) => e.stopPropagation()}>
              <MSwitch
                checked={enabled}
                onChange={(enabled) => {
                  handleSwitchChange(enabled);
                }}
              />
            </div>
            <ArrowRightRoundedIcon className={classes.arrowIcon} />
          </div>
        </>
      </GenericOptionContainer>
    </div>
  );
};

export default WidgetContainerListItem;
