import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React from "react";
import { I18n } from "react-redux-i18n";
import CustomTooltip from "./utils/CustomTooltip";
import theme from "../../../../../themes/theme";

type Props = {
  data: any[];
  timeFormat: (item: any) => string;
  numberFormat: (item: any) => string;
  fullDateFormat: (item: any) => string;
  titlesFormat: (value: any, name: any) => any[];
  width?: string;
  height?: number;
};

const IndexedQueriesChart = (props: Props) => {
  const { data, timeFormat, titlesFormat, numberFormat, width, fullDateFormat, height } = props;

  return (
    <ResponsiveContainer width={width || "100%"} height={height || 400}>
      <ComposedChart data={data}>
        <XAxis
          dataKey={"week_date"}
          axisLine={false}
          tickLine={false}
          tickMargin={16}
          tick={{ fontSize: 12 }}
          interval={"preserveStartEnd"}
          tickFormatter={timeFormat}
        />
        <YAxis
          dataKey={"cumulative_count"}
          yAxisId="left"
          axisLine={false}
          tickLine={false}
          width={40}
          tickMargin={10}
          tick={{ fontSize: 12 }}
          type="number"
          tickFormatter={numberFormat}
        />
        <Tooltip
          cursor={false}
          content={
            <CustomTooltip
              getTotal={false}
              translations={{
                cumulative_count: I18n.t("reports.dashboard.total_queries"),
              }}
            />
          }
          labelFormatter={fullDateFormat}
          formatter={titlesFormat}
          labelStyle={{ marginBottom: 5, fontWeight: theme.typography.fontWeightBold }}
        />
        <CartesianGrid stroke="#E5E5E5" vertical={false} strokeDasharray={1} />
        <Bar yAxisId={"left"} fill="#4353FF" maxBarSize={80} dataKey="cumulative_count" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default IndexedQueriesChart;
