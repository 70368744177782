import * as React from "react";
import { I18n } from "react-redux-i18n";
import { find } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { TransitionProps } from "@material-ui/core/transitions";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import agent from "../../../agent";
import { IFile, IWebsite } from "../../../reducers/constants/objectTypes";
import ModalsCloseButton from "../components/ModalsCloseButton";
import CfButton from "../../../components/CfButton";
import CfSearchInput from "../../../components/CfSearchInput";
import ImagesManager from "./components/ImagesManager";
import ImageDetails from "./components/ImageDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      height: "80vh",
    },
    titleWrapper: {
      height: 60,
      minHeight: 60,
      borderBottom: `${theme.palette.divider} solid 1px`,
      paddingLeft: 30,
      paddingRight: 30,
      display: "flex",
      alignItems: "center",
    },
    dialogContent: {
      display: "flex",
      padding: "0 30px",
      gap: 60,
    },
    imagesWrapper: {
      width: 750,
      display: "flex",
      flexDirection: "column",
      gap: 20,
      overflow: "scroll",
    },
    search: {
      width: "-webkit-fill-available",
      height: "auto",
    },
    searchIcon: {},
    searchInput: {
      height: 70,
      paddingLeft: 10,
      backgroundColor: "white",
      "&:before": {
        borderBottomColor: theme.palette.divider,
      },
    },
    detailsWrapper: {
      width: 460,
      padding: "10px 10px 10px 0",
      overflow: "scroll",
    },
    footer: {
      height: 80,
      minHeight: 80,
      borderTop: `${theme.palette.divider} solid 1px`,
      paddingLeft: 25,
      paddingRight: 25,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    closeIcon: {
      width: 30,
      height: 30,
      color: theme.palette.text.primary,
    },
  })
);

type PropTypes = {
  website: IWebsite;
  imageSizeLimit?: number;
  selectedImageFile?: IFile;
  forcesImageSize?: {
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
    ratio?: number;
  };
  handleInsertClick?: (file) => void;
  handleCloseClick?: () => void;
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const InsertImageDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    website,
    imageSizeLimit = null,
    selectedImageFile = null,
    forcesImageSize = null,
    handleInsertClick,
    handleCloseClick,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<IFile>(selectedImageFile);
  const [selectedFileSize, setSelectedFileSize] = React.useState<any>(null);
  const [files, setFiles] = React.useState<IFile[]>(null);
  const [searchText, setSearchText] = React.useState("");
  const [alt, setAlt] = React.useState(null);
  const [caption, setCaption] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [validImage, setValidImage] = React.useState(true);
  const [AIAltGeneration, setAIAltGeneration] = React.useState(false);
  const [AIAltGenerationError, setAIAltGenerationError] = React.useState(false);
  const scrollableListRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (!selectedFile || selectedFile.alt || AIAltGenerationError) {
      return;
    }
    setAIAltGeneration(true);
    agent.Files.getAltText(selectedFile)
      .then((res) => {
        console.log(res);
        setAIAltGenerationError(res.altGenerateError);
        setSelectedFile(res.file);
        setFiles(
          files.map((file) => {
            if (file._id === res.file._id) {
              return res.file;
            }
            return file;
          })
        );
      })
      .catch((e) => {
        console.log("AI alt failed");
      })
      .finally(() => {
        setAIAltGeneration(false);
      });
  }, [selectedFile]);

  const downloadFiles = () => {
    setLoading(true);
    agent.Files.getList(website._id)
      .then((res) => {
        setFiles(res.files);
        setSelectedFile(find(res.files, { _id: selectedFile._id }));
        setAIAltGenerationError(false);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onOpen = () => {
    downloadFiles();
  };

  const close = (e) => {
    e.stopPropagation();
    if (selectedImageFile?.updatedAt < selectedFile?.updatedAt) {
      handleInsertClick(selectedFile);
      return;
    }
    handleCloseClick();
  };

  const validateFields = () =>
    (title || selectedFile.title) && (alt || selectedFile.alt) && (caption || selectedFile.caption);

  // TODO: get real image weight
  const getImageSize = (file) => {
    // const iTime = performance.getEntriesByName(file.cdnUrl)[0];
    // console.log(iTime)
    // fetch(file.cdnUrl).then(r => r.blob()).then(fileImg => setSelectedFileSize(fileImg.size));
  };

  // React.useEffect(() => {
  // 	console.log('scroll to useEffect', selectedFile, scrollableListRef, files?.length);
  // 	if (!selectedFile || !scrollableListRef.current) {
  // 		return;
  // 	}
  // 	console.log('scroll to');
  // 	scrollableListRef.current?.scrollBy(200,200);
  // 		// .scrollTo(300, 200);
  //
  // }, [selectedFile, scrollableListRef, files]);

  // const updateImageFile = () => {
  // 	agent.Files.updateDetails({
  // 		...selectedFile,
  // 		alt: alt !== null ? alt : selectedFile.alt,
  // 		caption: caption !== null ? caption : selectedFile.caption,
  // 		desiredWidth: desiredWidth !== null ? desiredWidth : selectedFile.desiredWidth,
  // 		title: title !== null ? title : selectedFile.title,
  // 	}).then(res => {
  // 		const { file } = res;
  // 		setSelectedFile(file);
  // 		setFiles(files.map(f => f._id === file._id ? file : f));
  // 		setAlt(null);
  // 		setDesiredWidth(null);
  // 		setCaption(null);
  // 		setTitle(null);
  // 	}).catch(err => {
  // 		console.log('Error:', err);
  // 	});
  // }

  const handeFileSection = (file: IFile) => {
    setAIAltGenerationError(false);
    setSelectedFile(file);
  };

  return (
    <Dialog
      open
      fullWidth={false}
      maxWidth={"lg"}
      classes={{
        paper: classes.dialogPaper,
      }}
      onEntered={(e) => onOpen()}
      onClose={(event) => close(event)}
      TransitionComponent={Transition}
    >
      <div className={classes.titleWrapper}>
        <Typography color={"textPrimary"} variant={"h6"}>
          {I18n.t("rich_text_editor.insert_image_dialog.title")}
        </Typography>
        <ModalsCloseButton onClick={(event) => close(event)} customClass={classes.closeIcon} />
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.imagesWrapper}>
          <CfSearchInput
            customClass={classes.search}
            customInputClass={classes.searchInput}
            customSearchIconClass={classes.searchIcon}
            variant={"standard"}
            showSearchIcon
            searchText={searchText}
            placeholder={I18n.t("rich_text_editor.insert_image_dialog.search_input.placeholder")}
            handleSearchTextChange={(text) => setSearchText(text)}
          />
          <ImagesManager
            website={website}
            searchText={searchText}
            imageSizeLimit={imageSizeLimit}
            loading={loading}
            forcesImageSize={forcesImageSize}
            files={files}
            validImage={validImage}
            selectedFile={selectedFile}
            scrollableListRef={scrollableListRef}
            setFiles={setFiles}
            setSelectedFile={handeFileSection}
            setValidImage={setValidImage}
          />
        </div>
        <div className={classes.detailsWrapper}>
          <ImageDetails
            selectedFile={selectedFile}
            files={files}
            AIAltGeneration={AIAltGeneration}
            error={error}
            title={title}
            alt={alt}
            caption={caption}
            forcesImageSize={forcesImageSize}
            validImage={validImage}
            setTitle={setTitle}
            setAlt={setAlt}
            setCaption={setCaption}
            setSelectedFile={handeFileSection}
            setFiles={setFiles}
          />
        </div>
      </DialogContent>
      <div className={classes.footer}>
        <CfButton
          height={40}
          disabled={!selectedFile}
          text={I18n.t("rich_text_editor.insert_image_dialog.insert")}
          onClick={(e) => {
            e.stopPropagation();
            console.log(title || selectedFile.title);
            console.log(alt || selectedFile.alt);
            console.log(caption || selectedFile.caption);
            if (!validateFields()) {
              setError(true);
              return;
            }
            if (handleInsertClick) {
              handleInsertClick(selectedFile);
            }
          }}
        />
      </div>
    </Dialog>
  );
};

export default InsertImageDialog;
