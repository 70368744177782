import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 14,
    width: 18,
  },
}));

type PropTypes = {
  className?: string;
};

const ArrowRightIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 18 14"}>
      <path d="M18.0001 7C17.9918 6.78875 17.9048 6.54458 17.76 6.39062L12.4419 0.776874C12.1314 0.40894 11.534 0.416318 11.1849 0.747859C10.8357 1.0794 10.8071 1.66653 11.1586 1.99564L15.0548 6.11361L0.886599 6.11361C0.397065 6.11361 0.000244141 6.51049 0.000244141 7C0.000244141 7.48955 0.397065 7.88638 0.886599 7.88638L15.0548 7.88638L11.1586 12.0044C10.8072 12.3335 10.8359 12.9231 11.1852 13.2546C11.5343 13.5862 12.1401 13.5876 12.4421 13.2231L17.7602 7.60939C17.9219 7.43749 17.9977 7.23696 18.0001 7Z" />
    </SvgIcon>
  );
};

export default ArrowRightIcon;
