import { connect } from "react-redux";
import React, { useMemo } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Classnames from "classnames";
import { I18n } from "react-redux-i18n";
import { TableSortLabel } from "@material-ui/core";
import _ from "lodash";
import classNames from "classnames";
import useSort from "../../../../../../hooks/useSort";
import KeywordDialogListItem from "./KeywordDialogListItem";
import { IKeywordHeadingCluster } from "../../../../../../reducers/constants/objectTypes";
import numeral from "numeral";

const useStyles = makeStyles((theme: Theme) => ({
  dialogListSubHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0",
    backgroundColor: theme.palette.background.default,
    margin: "15px 0px",
    top: -1,
  },
  navDialogTabs: {
    display: "flex",
    width: "55%",
    flexDirection: "row",
  },
  dialogTabs: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    width: "10%",
  },
  firstDialogTab: {
    width: "55%",
  },
  tab: {
    padding: "2px 40px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    cursor: "pointer",
    flex: "0 0 auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    borderBottom: `1px solid #C4C4C4`,
  },
  activeTab: {
    color: theme.palette.text.primary,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    transition: "border .3s",
  },
  headerIcon: {
    width: 24,
    height: 24,
  },
  sortIcon: { color: `${theme.palette.text.primary} !important` },
}));

const mapStateToProps = (state) => ({
  broadKeywords: state.keywordStrategy.broadKeywords,
  keywords: state.outlinePages.keywords,
  pages: state.keywordStrategy.pages,
  serps: state.keywordStrategy.serps,
});

type PropTypes = {
  broadKeyword: boolean;
  broadKeywords: IKeywordHeadingCluster[];
  pages: any[];
  serps: any[];
  pageTab: "keywords" | "pages" | "serps";
  setPageTab: (tab: "keywords" | "pages" | "serps") => void;
  cms?: boolean;
};

const KeywordDialogList = (props: PropTypes) => {
  const classes = useStyles(props);

  const { broadKeywords, setPageTab, serps, pageTab, cms, pages } = props;
  const [lastSortField, setLastSortField] = React.useState<string>("clicks");

  const { sortFields, toggleSort } = useSort({
    initialSortFields: {
      clicks: "desc",
      impressions: "desc",
    },
  });

  const columns = useMemo(() => ["keyword", "clicks", "impressions", "volume", "ctr", "position"], []);

  const keywordColumns = React.useMemo(
    () => ({
      keyword: {
        isSortable: false,
        label: "",
      },
      clicks: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_keywords.clicks"),
      },
      impressions: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_keywords.impressions"),
      },
      volume: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_keywords.volume"),
      },
      ctr: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_keywords.ctr"),
      },
      position: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_keywords.position"),
      },
    }),
    []
  );

  const sortedData = useMemo(() => {
    if (broadKeywords) {
      const fields: string[] = [];
      const directions: any[] = [];

      Object.entries(sortFields).forEach(([field, direction]) => {
        if (field === lastSortField) {
          fields.unshift(field);
          directions.unshift(direction);
          return;
        }
        fields.push(field);
        directions.push(direction);
      });

      return _.orderBy(broadKeywords, fields, directions);
    }
    return [];
  }, [sortFields, broadKeywords]);

  return (
    <div>
      <ListSubheader className={classes.dialogListSubHeader}>
        {columns?.map((col, index) => {
          const columnLabel = keywordColumns[col].label;

          const { isSortable } = keywordColumns[col];
          return (
            <div
              className={index === 0 ? Classnames(classes.dialogTabs, classes.firstDialogTab) : classes.dialogTabs}
              key={index}
            >
              {isSortable ? (
                <TableSortLabel
                  classes={{ icon: classes.sortIcon }}
                  active={!!sortFields[col]}
                  direction={sortFields[col] || undefined}
                  onClick={() => {
                    setLastSortField(col);
                    toggleSort(col);
                  }}
                >
                  {columnLabel}
                </TableSortLabel>
              ) : index === 0 ? (
                <div className={classes.navDialogTabs}>
                  <div
                    className={classNames(pageTab === "keywords" && classes.activeTab, classes.tab)}
                    onClick={() => {
                      setPageTab("keywords");
                    }}
                  >
                    {numeral(broadKeywords?.length || 0).format("0,0a")}{" "}
                    {broadKeywords?.length === 1
                      ? I18n.t("strategy.outlinePages.dialog_header.keyword")
                      : I18n.t("strategy.outlinePages.dialog_header.keywords")}
                  </div>
                  <div
                    className={classNames(pageTab === "pages" && classes.activeTab, classes.tab)}
                    onClick={() => {
                      setPageTab("pages");
                    }}
                  >
                    {numeral(pages?.length || 0).format("0,0a")}{" "}
                    {pages?.length === 1
                      ? I18n.t("strategy.outlinePages.dialog_header.page")
                      : I18n.t("strategy.outlinePages.dialog_header.pages")}
                  </div>
                  <div
                    className={Classnames(pageTab === "serps" && classes.activeTab, classes.tab)}
                    onClick={() => {
                      setPageTab("serps");
                    }}
                  >
                    {numeral(serps?.length || 0).format("0,0a")}{" "}
                    {serps?.length === 1
                      ? I18n.t("strategy.outlinePages.dialog_header.serp")
                      : I18n.t("strategy.outlinePages.dialog_header.serps")}
                  </div>
                </div>
              ) : (
                columnLabel
              )}
            </div>
          );
        })}
      </ListSubheader>
      {sortedData?.map((keyword, index) => (
        <KeywordDialogListItem cms={cms} key={index} keyword={keyword} />
      ))}
    </div>
  );
};

export default connect(mapStateToProps, {})(KeywordDialogList);
