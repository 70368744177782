import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { find } from "lodash";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { Theme } from "@material-ui/core";

import MSelect from "../../../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  title: {
    color: (props: PropTypes) => props.titleColor || theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 10,
    whiteSpace: "pre",
  },
  experienceWrapper: {
    width: ({ fullWidth }: PropTypes) => (fullWidth ? "100%" : 200),
  },
}));

type PropTypes = {
  title?: string;
  titleColor?: string;
  year?: number;
  fullWidth?: boolean;
  placeholder?: string;

  handleYearChange: (year: number) => void;
};

const EducationYear = (props: PropTypes) => {
  const classes = useStyles(props);
  const { year, title, placeholder } = props;
  const { handleYearChange } = props;

  const currentYear = new Date().getFullYear();

  const years = React.useMemo(
    () =>
      Array.from(Array(40).keys()).map((key) => ({
        value: currentYear - key,
        key: currentYear - key,
      })),
    [currentYear]
  );

  return (
    <div className={classes.experienceWrapper}>
      {title && (
        <Typography className={ClassNames(classes.title)} variant={"subtitle2"}>
          {title}
        </Typography>
      )}
      <MSelect
        customClass={classes.wrapper}
        fullWidth
        options={years}
        value={year !== null ? find(years, { value: year }) : null}
        optionLabel={"key"}
        optionValue={"key"}
        borderWidth={1}
        height={38}
        placeholder={placeholder || "Select"}
        isBold={false}
        handleChange={(item) => {
          handleYearChange(item.value);
        }}
        menuPosition={"fixed"}
      />
    </div>
  );
};

export default EducationYear;
