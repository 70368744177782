import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { push } from "connected-react-router";
import { I18n } from "react-redux-i18n";
import ContentGapsDialogHeader from "./ContentGapsDialogHeader";
import { ePageType, IWebsite } from "../../../../../reducers/constants/objectTypes";
import { CONTENT_GAPS } from "../../../../../reducers/constants/actionTypes";
import agent from "../../../../../agent";
import KeywordDialogList from "./KeywordsDialog/KeywordDialogList";
import PagesDialogList from "./PagesDialog/PagesDialogList";
import MButtonGroup from "../../../../../components/MButtonGroup";
import SerpDialogList from "../../../OutlinePages/OutlineDialog/SerpDialog/SerpsDialogList";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    padding: 0,
    "& .MuiDialogContent-root": {
      padding: 30,
    },
  },
  dialogContent: {
    overflowY: "hidden",
  },
  scrollPaper: {
    maxHeight: "100%",
  },
  list: {
    padding: "0",
  },
  listItems: {
    overflowY: "auto",
    height: "calc(100vh - 246px)",
  },
  footer: {
    borderTop: `${theme.palette.divider} solid 1px`,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    marginLeft: "auto",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  item: state.contentGaps.selectedDataItem,
  refetch: state.contentGaps.refetch,
});

const mapDispatch = (dispatch) => ({
  redirect: (url) => dispatch(push(url)),
  changeRefetch(refresh: boolean) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_REFETCH_CHANGED, payload: refresh });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: CONTENT_GAPS.ON_CONTENT_GAPS_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
});

type PropTypes = {
  item: any;
  isStrategy?: boolean;
  open: boolean;
  refetch: boolean;
  selectedWebsite: IWebsite;
  pageTab: "outlines" | "keywords" | "pages" | "serps";

  redirect: (url: string) => void;
  setPageTab: (page: PropTypes["pageTab"]) => void;
  close: () => void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  changeRefetch: (refresh: boolean) => void;
};

const ContentGapsDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const {
    refetch,
    changeRefetch,
    changeSelectedDataItemsIndexes,
    isStrategy = false,
    item,
    close,
    open,
    setPageTab,
    pageTab,
    selectedWebsite,
  } = props;

  const submitForApproval = async (postType) => {
    close();
    try {
      await agent.KeywordStrategy.updateKeywordStrategy(
        selectedWebsite._id,
        item.keyword,
        {
          ...item,
          status: "pending",
          submitted_at: new Date(),
          post_type: postType,
        },
        "true"
      );
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.pending_success"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    changeRefetch(!refetch);
    changeSelectedDataItemsIndexes([]);
  };

  const approve = async () => {
    close();
    try {
      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, {
        ...item,
        status: "approved",
        reviewed_at: new Date(),
      });
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.approved"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    changeRefetch(!refetch);
    changeSelectedDataItemsIndexes([]);
  };

  const reject = async (pageType?: ePageType) => {
    close();
    try {
      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, {
        ...item,
        status: "rejected",
        reviewed_at: new Date(),
      });
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.reject_sucecss"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    changeRefetch(!refetch);
    changeSelectedDataItemsIndexes([]);
  };

  const buttonOptions = useMemo(
    () => [
      { label: I18n.t("strategy.strategyKeywords.btnOptions.targeted"), key: "targeted" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.community"), key: "community" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.article"), key: "article" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.top10"), key: "top10" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.review"), key: "review" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.comparison"), key: "comparison" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.list_article"), key: "list_article" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.short_video"), key: "short_video" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.long_video"), key: "long_video" },
    ],
    []
  );

  const optionsWhenStrategy = () => [
    {
      key: "submit_for_approval",
      label: I18n.t("strategy.outlinePages.submit_for_approval"),
    },
  ];

  const optionsWhenNotStrategy = () => [
    {
      key: "approve",
      label: I18n.t("strategy.outlinePages.approve.action"),
    },
    {
      key: "reject",
      label: I18n.t("strategy.outlinePages.submit_for_reject"),
    },
  ];

  const actionOptions = isStrategy ? optionsWhenStrategy() : optionsWhenNotStrategy();

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={(event) => close()}
      fullWidth
      maxWidth={"xl"}
      classes={{ paper: classes.dialogPaper, paperScrollPaper: classes.scrollPaper }}
    >
      <DialogContent className={classes.dialogContent}>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
            <ContentGapsDialogHeader
              title={item?.keyword || ""}
              setPageTab={setPageTab}
              pageTab={pageTab}
              close={close}
            />

            <div className={classes.listItems}>
              {pageTab === "keywords" && (
                <KeywordDialogList setPageTab={setPageTab} pageTab={pageTab} broadKeyword={false} />
              )}
              {pageTab === "pages" && <PagesDialogList setPageTab={setPageTab} pageTab={pageTab} />}
              {pageTab === "serps" && <SerpDialogList pageTab={pageTab} setPageTab={setPageTab} />}
            </div>
          </List>

          {isStrategy && (
            <MButtonGroup
              wrapperCustomClass={classes.button}
              stopPropagation={true}
              hasDivider={false}
              customClass={classes.button}
              onClick={(e) => {
                e.stopPropagation();
                submitForApproval("article");
              }}
              onMenuItemClick={(key) => {
                submitForApproval(key);
              }}
              text={I18n.t("strategy.strategyKeywords.btnOptions.article")}
              options={buttonOptions}
            />
          )}

          {item?.status !== "approved" && !isStrategy && (
            <MButtonGroup
              wrapperCustomClass={classes.button}
              onClick={async () => {
                await approve();
              }}
              onMenuItemClick={async (key) => {
                if (key === "reject") {
                  await reject();
                } else if (key === "approve") {
                  await approve();
                }
              }}
              text={(() => I18n.t("strategy.outlinePages.approve.action"))()}
              options={actionOptions}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatch)(ContentGapsDialog);
