import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import numeral from "numeral";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { eActionIcon, ICampaign, ICampaignAnalytics, IWidget } from "../../../../../../reducers/constants/objectTypes";
import MTableHeader from "../../../../../../components/Table/Table/MTableHeader";
import CfProgressBar from "../../../../../../components/CfProgressBar";
import MTable from "../../../../../../components/Table/Table/MTable";
import MTableHeaderLabel from "../../../../../../components/Table/Table/MTableHeaderLabel";
import MTableRow from "../../../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../../../components/Table/Cells/MTableCellFirst";
import MTableCellText from "../../../../../../components/Table/Cells/MTableCellText";
import theme from "../../../../../../themes/theme";
import { copyStringToClipboard } from "../../../../../../helpers/fbScraperHelper";
import agent from "../../../../../../agent";
import { sumBy, set } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: "100%",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    minHeight: 220,
    padding: 20,
  },
  table: {
    width: 750,
  },
  firstCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  header: {
    padding: "10px 10px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  row: {
    padding: "10px 10px",
    border: "none",
    height: "auto",
    "&:hover": {
      padding: "10px 10px !important",
      border: "none",
    },
  },
  actions: {
    marginLeft: 5,
    position: "absolute",
    right: 0,
    zIndex: 1,
    padding: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}));

// const queries = null;
// const queries: string[] = ['First Query', 'Second Query', 'Third Query'];

type PropTypes = {
  open: boolean;
  campaign: ICampaign;
  startDate: Date;
  firstColumnWidth?: string | number;

  handleClose?: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CampaignAnalyticsDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const [loading, setLoading] = React.useState(false);
  const [analytics, setAnalytics] = React.useState<ICampaignAnalytics[]>([]);
  const [widgets, setWidgets] = React.useState<IWidget[]>([]);
  const { open, campaign, startDate, firstColumnWidth = "40%", handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();

  if (!open) {
    return null;
  }

  React.useEffect(() => {
    if (!campaign) {
      return;
    }
    console.log("CampaignAnalyticsDialog campaign", campaign);
    setLoading(true);
    agent.Campaigns.getCampaignIdAnalytics(
      campaign._id,
      campaign.website?._id || (campaign.website as any),
      startDate.toISOString()
    )
      .then((res) => {
        setAnalytics(res.analytics);
        setWidgets(res.widgets);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: "Something went wrong!" }));
        handleClose();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [campaign, startDate]);

  const campaignAnalytics = React.useMemo(() => {
    const mid = analytics.reduce(
      (acc, item) => {
        return {
          totalViews: acc.totalViews + item.totalViews,
          totalWidgetViews: acc.totalWidgetViews + item.totalWidgetViews,
          totalClicks: acc.totalClicks + item.totalClicks,
          totalInteractions: acc.totalInteractions + item.totalInteractions,
        };
      },
      {
        totalViews: 0,
        totalWidgetViews: 0,
        totalClicks: 0,
        totalInteractions: 0,
        viewsByPage: {},
        widgetViewsByPageAndWidget: {},
        clicksByPageAndWidget: {},
        interactionsByPageAndWidget: {},
      }
    );

    mid["viewsByPage"] = analytics.reduce(
      (a, obj) =>
        Object.entries(obj.viewsByPage).reduce((a, [key, val]) => {
          a[key] = (a?.[key] || 0) + val;
          return a;
        }, a),
      {}
    );

    mid["widgetViewsByPageAndWidget"] = analytics.reduce(
      (a, obj) =>
        Object.entries(obj.widgetViewsByPageAndWidget).reduce((a, [pageUrl, val]) => {
          a[pageUrl] = Object.entries(val || {}).reduce((b, [widgetId, widgetVal]) => {
            b[widgetId] = (b?.[widgetId] || 0) + (widgetVal || 0);
            return b;
          }, a[pageUrl] || {});
          return a;
        }, a),
      {}
    );

    mid["clicksByPageAndWidget"] = analytics.reduce(
      (a, obj) =>
        Object.entries(obj.clicksByPageAndWidget).reduce((a, [pageUrl, val]) => {
          console.log("pageUrl", pageUrl);
          a[pageUrl] = Object.entries(val || {}).reduce((b, [widgetId, widgetVal]) => {
            console.log("widgetId", widgetId, b?.[widgetId], widgetVal);
            b[widgetId] = (b?.[widgetId] || 0) + sumBy(widgetVal as any[], "count");
            console.log("widgetId b[widgetId]", widgetId, b[widgetId]);
            return b;
          }, a[pageUrl] || {});
          return a;
        }, a),
      {}
    );

    mid["interactionsByPageAndWidget"] = analytics.reduce(
      (a, obj) =>
        Object.entries(obj.interactionsByPageAndWidget).reduce((a, [pageUrl, val]) => {
          a[pageUrl] = Object.entries(val || {}).reduce((b, [widgetId, widgetVal]) => {
            b[widgetId] = (b?.[widgetId] || 0) + sumBy(widgetVal as any[], "count");
            return b;
          }, a[pageUrl] || {});
          return a;
        }, a),
      {}
    );

    console.log("analytics", analytics);
    console.log("mid", mid);

    return mid;
  }, [analytics]);

  const table = React.useMemo(() => {
    const table = {};
    console.log("campaignAnalytics", campaignAnalytics);

    if (!campaignAnalytics) {
      return table;
    }

    Object.keys(campaignAnalytics?.widgetViewsByPageAndWidget || {}).map((pageUrl, index) => {
      console.log("pageUrl", pageUrl);
      Object.keys(campaignAnalytics.widgetViewsByPageAndWidget[pageUrl] || {}).map((widgetId, index) => {
        console.log("pageUrl", pageUrl, widgetId);
        set(table, [pageUrl, widgetId, "views"], campaignAnalytics.widgetViewsByPageAndWidget[pageUrl][widgetId]);
      });
    });

    Object.keys(campaignAnalytics?.clicksByPageAndWidget || {}).map((pageUrl, index) => {
      console.log("clicksByPageAndWidget pageUrl", pageUrl);
      Object.keys(campaignAnalytics.clicksByPageAndWidget[pageUrl] || {}).map((widgetId, index) => {
        console.log("clicksByPageAndWidget pageUrl", pageUrl, widgetId);
        set(table, [pageUrl, widgetId, "clicks"], campaignAnalytics.clicksByPageAndWidget[pageUrl][widgetId]);
      });
    });

    Object.keys(campaignAnalytics?.interactionsByPageAndWidget || {}).map((pageUrl, index) => {
      console.log("interactionsByPageAndWidget pageUrl", pageUrl);
      Object.keys(campaignAnalytics.interactionsByPageAndWidget[pageUrl] || {}).map((widgetId, index) => {
        console.log("interactionsByPageAndWidget pageUrl", pageUrl, widgetId);
        set(
          table,
          [pageUrl, widgetId, "interactions"],
          campaignAnalytics.interactionsByPageAndWidget[pageUrl][widgetId]
        );
      });
    });

    return table;
  }, [campaignAnalytics]);

  const tableActions = React.useMemo(() => {
    return [
      {
        text: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
        tooltip: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
        onClick: (event, row) => {
          window.open(row[0], "_blank");
        },
        iconName: eActionIcon.openInNew,
      },
      // {
      //   text: I18n.t(`learning_center_content.tooltip.copy_title`),
      //   tooltip: I18n.t(`learning_center_content.tooltip.copy_title`),
      //   onClick: (event, row) =>
      //     copyStringToClipboard(row[0], () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
      //   iconName: eActionIcon.copyName,
      // },
      {
        text: I18n.t(`learning_center_content.tooltip.copy_url`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_url`),
        onClick: (event, row) =>
          copyStringToClipboard(row[0], () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
        iconName: eActionIcon.copyToClipboard,
      },
    ];
  }, []);

  console.log("table", table);

  return (
    <Dialog
      className={classes.dialog}
      maxWidth={"lg"}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose()}
    >
      <DialogContent className={classes.dialogContent}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CfProgressBar />
          </div>
        ) : (
          <MTable>
            <MTableHeader top={0} className={classes.header}>
              <MTableHeaderLabel columnWidth={firstColumnWidth}>{I18n.t("widgets_main.list.pages")}</MTableHeaderLabel>
              <MTableHeaderLabel columnWidth={"30%"}>{I18n.t("widgets_main.list.widget_name")}</MTableHeaderLabel>
              <MTableHeaderLabel>{I18n.t("widgets_main.list.widget_views")}</MTableHeaderLabel>
              <MTableHeaderLabel>{I18n.t("widgets_main.list.clicks")}</MTableHeaderLabel>
              <MTableHeaderLabel>{I18n.t("widgets_main.list.cpm")}</MTableHeaderLabel>
            </MTableHeader>
            {Object.keys(table).map((pageUrl, rowIndex) => (
              <>
                {Object.keys(table[pageUrl]).map((widgetId, widgetIndex) => (
                  <MTableRow
                    key={`row_${pageUrl}_${widgetId}`}
                    handleRowClick={(e) => e.stopPropagation()}
                    customClassName={classes.row}
                    showBorder={false}
                  >
                    <MTableCellFirst
                      columnWidth={firstColumnWidth}
                      wrapperClassName={classes.firstCell}
                      fontSize={theme.typography.pxToRem(14)}
                      cellText={pageUrl}
                      actionsObject={[pageUrl]}
                      actions={tableActions}
                      actionClassName={classes.actions}
                    />
                    <MTableCellText
                      columnWidth={"30%"}
                      text={widgets.find((w) => w.uploadedId === widgetId)?.name || "-"}
                    />
                    <MTableCellText text={numeral(table[pageUrl][widgetId].views).format("0,0[.]")} />
                    <MTableCellText text={numeral(table[pageUrl][widgetId].clicks).format("0,0[.]")} />
                    <MTableCellText
                      text={numeral(
                        ((table[pageUrl][widgetId].clicks || 0) * 1000) / (table[pageUrl][widgetId].views || 1)
                      ).format("0,0[.]")}
                    />
                  </MTableRow>
                ))}
              </>
            ))}
          </MTable>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CampaignAnalyticsDialog;
