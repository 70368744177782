import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";

type PropTypes = {
  label: string;
  onClick: () => void;
};

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  text: {
    fontSize: 12,
    fontWeight: typography.fontWeightMedium as any,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: palette.text.primary,
    cursor: "pointer",
    "&:hover": {
      color: palette.primary.light,
    },
  },
}));

const ResetToDefaults = (props: PropTypes) => {
  const classes = useStyles(props);
  const { onClick, label } = props;

  return (
    <div className={classes.root}>
      <Typography onClick={onClick} className={classes.text} variant="h6">
        {label}
      </Typography>
    </div>
  );
};

export default ResetToDefaults;
