import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import numeral from "numeral";
import { IAnalyticsEventRow, IGoal, IWebsite } from "../../../reducers/constants/objectTypes";
import MTable from "../../../components/Table/Table/MTable";
import MTableHeader from "../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../../components/Table/Table/MTableHeaderSortLabel";
import ListItem from "@material-ui/core/ListItem";
import CfProgressBar from "../../../components/CfProgressBar";
import theme from "../../../themes/theme";
import GoalEventListItem from "./GoalEventListItem";

type PropTypes = {
  loading: boolean;
  events: IAnalyticsEventRow[];
  goals: IGoal[];
  selectedWebsite: IWebsite;
  page: number;
  totalPages: number;
  pageSize: number;
  totalEvents: number;
  timePeriod: any;
  sortField: { direction: "desc" | "asc" | undefined; field: string };

  handleSortFieldChanged: (sortField: { direction: "desc" | "asc" | undefined; field: string }) => void;
};

const GoalEventsList = (props: PropTypes) => {
  const { loading, goals, events, selectedWebsite, timePeriod, totalEvents = 0 } = props;
  const { handleSortFieldChanged } = props;

  return (
    <>
      <MTable>
        <MTableHeader>
          <MTableHeaderLabel columnWidth={"30%"} fontSize={theme.typography.pxToRem(20)}>
            {I18n.t("event_goals.list.goals", { count: numeral(totalEvents || 0).format("0,0a") })}
          </MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("event_goals.list.value")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("event_goals.list.attribution")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("event_goals.list.type")}</MTableHeaderLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleSortFieldChanged(result)}
            sortField={props.sortField}
            field={"created_at"}
          >
            {I18n.t("event_goals.list.time")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel> {I18n.t("event_goals.list.journey")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("event_goals.list.country")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("event_goals.list.source")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("event_goals.list.landing_page")}</MTableHeaderLabel>
        </MTableHeader>

        {loading && (
          <ListItem
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CfProgressBar />
          </ListItem>
        )}

        {!loading &&
          events.map((event) => (
            <GoalEventListItem
              selectedWebsite={selectedWebsite}
              event={event}
              timePeriod={timePeriod}
              key={event.id}
              goal={goals.find((goal) => goal._id === event?.goal_id)}
              handleClick={() => console.log("clicked")}
            />
          ))}
      </MTable>
    </>
  );
};

export default GoalEventsList;
