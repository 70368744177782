import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import useInterval from "../hooks/useInterval";
import MSavedXMinutesAgo from "./MSavedXMinutesAgo";
import useKey from "../hooks/useKey";

const useStyles = makeStyles((theme: Theme) => ({
  autoSaveWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  switchWrapper: {
    cursor: "help",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 20,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 20,
    paddingRight: 20,
    paddingLeft: 20,
  },
  progressBar: {
    padding: "9px 15px",
  },
}));

type PropTypes = {
  lastSaveTime: Date;
  enableAutoSave: boolean;
  interval?: number;
  isAutoSaveOn: boolean;

  handleSavePost: () => void;
};

const MPageEditorAutoSave = (props: PropTypes) => {
  const classes = useStyles(props);
  const { interval = 60, lastSaveTime, enableAutoSave, isAutoSaveOn } = props;
  const { handleSavePost } = props;

  useKey("ctrls", (event) => {
    if (!enableAutoSave) {
      return;
    }
    console.log("Ctrl+S fired!");
    event.preventDefault();
    handleSavePost();
  });

  useInterval(() => {
    if (!isAutoSaveOn) {
      return;
    }
    console.log("useInterval - save");
    handleSavePost();
  }, interval * 1000);

  return (
    <div className={classes.autoSaveWrapper}>
      <MSavedXMinutesAgo lastSaveTime={lastSaveTime} />
    </div>
  );
};

export default MPageEditorAutoSave;
