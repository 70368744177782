import React from "react";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { IFile, IWebsiteThemeProps } from "../../../../../../../../reducers/constants/objectTypes";
import { getImageCDNUrl } from "../../../../../../../helpers/urlHelper";

const useStyles = makeStyles((theme) => ({
  bottomRowWrapper: {
    display: "flex",
    gap: "5px",
    width: "100%",
    height: "100%",
    flexDirection: "row",
  },

  subImage: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    border: (props: PropTypes) => `1px solid ${props.websiteTheme.palette.divider}`,
    "&:hover": {
      opacity: 0.7,
      transition: "opacity .4s",
    },
  },
}));

type PropTypes = {
  images: IFile[] | null;
  setMainImage: (image: IFile) => void;
  websiteTheme: IWebsiteThemeProps;
};

const ProductCTADefaultThumbnailImages = (props: PropTypes) => {
  const { images, setMainImage } = props;

  const classes = useStyles(props);
  const imageCount = images.length;

  return (
    <div className={classes.bottomRowWrapper}>
      {images?.map((img, index) => (
        <picture
          style={{
            width: `calc(100% / ${imageCount})`,
            maxWidth: "20%",
            cursor: "pointer",
          }}
          key={index}
        >
          <img
            src={getImageCDNUrl(img.url)}
            alt={img.alt}
            title={img.title}
            className={classNames(classes.subImage)}
            onClick={() => setMainImage(img)}
          />
        </picture>
      ))}
    </div>
  );
};

export default ProductCTADefaultThumbnailImages;
