import React from "react";
import numeral from "numeral";
import { ListItem, Typography } from "@material-ui/core";
import { IKeywordGroup, IKeywordGroupWithMetrics, IOpportunity } from "../../../../../reducers/constants/objectTypes";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  metric: {
    fontSize: "14px",
    flexBasis: 0,
    flexGrow: 1,
    color: theme.palette.text.primary,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "end",
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "0 10px",
    height: 57,
  },
  listItemFirstColumn: {
    width: "calc(60% - 10px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  keywordGroup: IKeywordGroupWithMetrics;
};

const KeywordTabTotalItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { keywordGroup } = props;

  return (
    <ListItem className={classes.listRow}>
      <div className={classes.listItemFirstColumn}>
        {`Total queries: ${numeral(keywordGroup?.keywords?.length || 0).format("0,0[.]")}`}
      </div>
      <div className={classes.metric}>
        <Typography>-</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>-</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(keywordGroup?.volume || 0).format("0,0a")}</Typography>
      </div>

      <div className={classes.metric}>
        <Typography className={classes.clicksText}>{numeral(keywordGroup?.clicks || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography className={classes.impText}>{numeral(keywordGroup?.impressions || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography className={classes.ctrText}>{numeral(keywordGroup?.ctr || 0).format("0.0%")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography className={classes.posText}>{numeral(keywordGroup?.position || 0).format("0.0")}</Typography>
      </div>
    </ListItem>
  );
};

export default KeywordTabTotalItem;
