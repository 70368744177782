import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  IWidgetFieldProperties,
  IWidgetIntroTextFieldProps,
} from "../../../../../../../reducers/constants/objectTypes";
import LiteRichTextEditor from "../../../../../../../editor/elements/liteEditor/LiteRichTextEditor";
import { editorType } from "../../../../../../../editor/types/editor.Types";

const useStyles = makeStyles(() => ({
  editableCustomClass: {
    borderBottomLeftRadius: 3,
    borderBottomRightRadiusRadius: 3,
  },
}));

type PropTypes = {
  properties: IWidgetFieldProperties;
  onChange: (properties: IWidgetIntroTextFieldProps) => void;
};

const IntroTextWidgetItem = (props: PropTypes) => {
  const { onChange, properties } = props;
  const { value = "" } = properties as IWidgetIntroTextFieldProps;

  const classes = useStyles();

  return (
    <LiteRichTextEditor
      editorToolbarPostion="top"
      onValueChanged={(content) => onChange({ ...properties, value: content })}
      initialValue={value}
      editableCustomClass={classes.editableCustomClass}
      elements={[editorType.headingOne, editorType.headingTwo]}
    />
  );
};

export default IntroTextWidgetItem;
