import * as React from "react";
import { I18n } from "react-redux-i18n";
import { IWebsite, IWebsiteDynamicSectionProps } from "../../../../../../../../reducers/constants/objectTypes";
import PageSection from "../../../../../../components/PageSection";
import TextInputMentions from "../../../../../../components/TextInputMentions";
import { LENGTHS } from "../../../../../../../../reducers/constants/consts";

type PropTypes = {
  dynamicSection: IWebsiteDynamicSectionProps;
  website: IWebsite;
  onPropertyChanged: (dynamicSection) => void;
  locale: string;
  defaultLocale: string;
  emptyFields: string[];
};

const SafeBooksPageBlog = (props: PropTypes) => {
  const { dynamicSection, onPropertyChanged, emptyFields, locale, defaultLocale, website } = props;
  const { safebooksTemplate } = dynamicSection;

  const [mainPageEmptyFields, setMainPageEmptyFields] = React.useState([]);

  const mainPageValidated = () => {
    let valid = true;
    const requiredFields = [];
    setMainPageEmptyFields(requiredFields);
    if (!safebooksTemplate.mainPage.metaTitle) {
      valid = false;
      requiredFields.push(I18n.t(`website.meta_title`));
    }
    if (!safebooksTemplate.mainPage.metaDescription) {
      valid = false;
      requiredFields.push(I18n.t(`website.meta_description`));
    }
    setMainPageEmptyFields(requiredFields);
    return valid;
  };
  React.useEffect(() => {
    console.log("mainPageEmptyFields", mainPageEmptyFields, safebooksTemplate);
    if (mainPageEmptyFields.length <= 0) {
      return;
    }
    mainPageValidated();
  }, [safebooksTemplate, dynamicSection]);

  return (
    <>
      <PageSection
        sectionTitle={I18n.t("website.blog.main_page")}
        checked={safebooksTemplate.mainPage.enabled}
        onCheckedChange={(checked) => {
          if (!mainPageValidated()) {
            if (!checked && safebooksTemplate.mainPage.enabled) {
              onPropertyChanged({
                ...dynamicSection,
                safebooksTemplate: {
                  ...safebooksTemplate,
                  mainPage: { ...safebooksTemplate.mainPage, enabled: checked },
                },
              });
            }
            return;
          }
          onPropertyChanged({
            ...dynamicSection,
            safebooksTemplate: { ...safebooksTemplate, mainPage: { ...safebooksTemplate.mainPage, enabled: checked } },
          });
        }}
      >
        <TextInputMentions
          key={`blog_main_page_meta_title_${locale}`}
          tags={[]}
          error={mainPageEmptyFields.includes(I18n.t(`website.meta_title`))}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? safebooksTemplate.mainPage.metaTitle
              : dynamicSection.translations[locale]?.safebooksTemplate.mainPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                safebooksTemplate: {
                  ...dynamicSection.safebooksTemplate,
                  mainPage: {
                    ...dynamicSection.safebooksTemplate.mainPage,
                    metaTitle: value,
                  },
                },
              });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              safebooksTemplate: {
                ...(dynamicSection.translations[locale]?.safebooksTemplate || {}),
                "mainPage.metaTitle": value,
              },
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
        <TextInputMentions
          key={`blog_main_page_meta_desc_${locale}`}
          error={mainPageEmptyFields.includes(I18n.t(`website.meta_description`))}
          tags={[]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? safebooksTemplate.mainPage.metaDescription
              : dynamicSection.translations[locale]?.safebooksTemplate?.mainPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                safebooksTemplate: {
                  ...dynamicSection.safebooksTemplate,
                  mainPage: { ...dynamicSection.safebooksTemplate.mainPage, metaDescription: value },
                },
              });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              safebooksTemplate: {
                ...(dynamicSection.translations[locale]?.safebooksTemplate || {}),
                "mainPage.metaDescription": value,
              },
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
      </PageSection>
    </>
  );
};

export default SafeBooksPageBlog;
