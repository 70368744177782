import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { useMemo } from "react";
import moment from "moment";
import { connect } from "react-redux";
import CfButton from "../../components/CfButton";
import { CONVERSION_POTENTIAL_FUNNEL_STAGE, STRATEGY_POST_TYPES } from "../../helpers/constants";
import CopyToClipboardIcon from "../../icons/CopyToClipboardIcon";
import MButtonGroup from "../../components/MButtonGroup";
import { AbilityContext } from "../../casl/can";
import MTableRow from "../../components/Table/Rows/MTableRow";
import MTableCellFirstWithSelect from "../../components/Table/Cells/MTableCellFirstWithSelect";
import MTableCellText from "../../components/Table/Cells/MTableCellText";
import MTableCell from "../../components/Table/Cells/MTableCell";
import MTableCellNumber from "../../components/Table/Cells/MTableCellNumber";
import MTableCellStatus from "../../components/Table/Cells/MTableCellStatus";
import CopyNameIcon from "../../icons/CopyNameIcon";
import MActionsDropdown from "../../components/MActionsDropdown";
import { eActionIcon } from "../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  actionsWrapper: {
    display: "none",
    justifyContent: "flex-end",
  },
  itemWrapper: {
    "&:hover": {
      "& $actions": {
        display: "flex",
      },
      "& $actionsWrapper": {
        display: "flex",
      },
    },
  },

  selected: {
    backgroundColor: theme.palette.action.hover,
  },
  selectedIcon: {
    fill: theme.palette.primary.contrastText,
  },
  selectedIconWrapper: {
    height: 25,
    minWidth: 25,
    padding: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "flex",
  },
  hoverIconWrapper: {
    height: 25,
    minWidth: 25,
    padding: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#B780FE",
    borderRadius: "50%",
    display: "none",
  },
  createButton: {
    height: 36,
    zIndex: 30,
  },

  avatar: {
    width: 25,
    height: 25,
    color: theme.palette.common.white,
    backgroundColor: "#808AFE",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  avatarWhenNonSelectable: {
    width: 25,
    height: 25,
    color: theme.palette.common.white,
    backgroundColor: "#808AFE",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  hideColumn: {},
  rejectIcon: {
    fill: theme.palette.text.secondary,
    cursor: "pointer",
    height: 18,
    width: 18,
  },
  iconButton: {
    padding: 0,
  },
  columnPopup: {
    "&:hover": {
      color: theme.palette.primary.main,
      transition: "color 0.2s ease-in-out",
    },
  },
  actions: {},
}));

type PropTypes = {
  item: any;
  onClick: () => void;
  setSelect?(selected: boolean): void;
  selected?: boolean;
  approve?: (outline) => void;
  returnToPending?: (outline) => void;
  reject?: (outline) => void;
  returnToMapping?: (outline) => void;
  noSelectable?: boolean;
  openTitleDialog?: (strategyKeyword: any) => void;
  openPostTypeDialog?: (strategyKeyword: any) => void;
  openFeedbackDialog?: (strategyKeyword: any) => void;
  changeFeedbackModify?: (val: boolean) => void;
  selectedColumns?: string[];
};

const mapStateToProps = (state) => ({
  selectedColumns: state.keywordStrategy.selectedColumns,
});

const MappingListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    item = {},
    onClick,
    setSelect = () => {},
    selected,
    noSelectable = false,
    returnToPending,
    openTitleDialog,
    openFeedbackDialog,
    changeFeedbackModify,
    approve,
    returnToMapping,
    openPostTypeDialog,
    selectedColumns,
  } = props;
  const ability = React.useContext<any>(AbilityContext);

  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const buttonOptions = useMemo(() => {
    if (noSelectable) {
      return [
        {
          label: I18n.t("strategy.strategyKeywords.return_to_pending"),
          value: "return_to_pending",
          onClick: () => returnToPending(item),
        },
      ];
    }
    const values = [
      { label: I18n.t("strategy.strategyKeywords.approve"), value: "approve", onClick: () => approve(item) },
      {
        label: I18n.t("strategy.strategyKeywords.reject"),
        value: "reject",
        onClick: () => {
          openFeedbackDialog(item);
          changeFeedbackModify(false);
        },
      },
    ];

    if (ability.can("view", "data_tab") && item.status === "pending") {
      values.push({
        label: I18n.t("strategy.strategyKeywords.return_to_mapping"),
        value: "return_to_mapping",
        onClick: () => returnToMapping(item),
      });
    }

    return values;
  }, [ability, item]);

  const actions = React.useMemo(() => {
    return [
      {
        text: showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title"),
        tooltip: showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title"),
        onClick: () => handleTitleCopy(),
        iconName: eActionIcon.copyToClipboard,
      },
      {
        text: showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title"),
        tooltip: showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title"),
        onClick: () => handleSuggestedTitleCopy(),
        iconName: eActionIcon.copyName,
      },
    ];
  }, [item, showTitleCopyTooltip]);

  const columnSet = useMemo(() => new Set(selectedColumns), [selectedColumns]);

  const handleTitleCopy = (() => {
    let timeoutId;
    return () => {
      if (item.keyword) {
        navigator.clipboard.writeText(item.keyword);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();

  const handleSuggestedTitleCopy = (() => {
    let timeoutId;
    return () => {
      if (item.suggested_title) {
        navigator.clipboard.writeText(item.suggested_title);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();

  const theme = useTheme();

  return (
    <MTableRow customClassName={classes.itemWrapper} handleRowClick={onClick} selected={selected}>
      <MTableCellFirstWithSelect
        columnWidth={"20%"}
        fontSize={theme.typography.pxToRem(14)}
        selected={selected}
        onSelect={setSelect}
        avatarType={"A"}
        cellText={item.keyword}
        actions={actions}
        actionClassName={classes.actions}
      />
      {columnSet.has("suggested_title") && (
        <MTableCell
          onClick={() => openTitleDialog(item)}
          columnWidth={"10%"}
          tooltip={item.suggested_title || I18n.t("strategy.strategyKeywords.add")}
        >
          {item.suggested_title || I18n.t("strategy.strategyKeywords.add")}
        </MTableCell>
      )}
      {columnSet.has("feedback") && (
        <MTableCell
          onClick={() => {
            openFeedbackDialog(item);
            changeFeedbackModify(true);
          }}
          tooltip={item.feedback || "Add feedback"}
        >
          {_.capitalize(item.feedback ? "View" : "Add")}
        </MTableCell>
      )}
      {columnSet.has("status") && (
        <MTableCellStatus status={_.capitalize(item.status)} isDefaultColor={_.capitalize(item.status) === "Pending"} />
      )}
      {columnSet.has("conversion_potential") && (
        <MTableCellText text={_.capitalize(CONVERSION_POTENTIAL_FUNNEL_STAGE[item.conversion_potential] || "None")} />
      )}
      {columnSet.has("post_type") && (
        <MTableCell
          onClick={() => openPostTypeDialog(item)}
          tooltip={_.capitalize(STRATEGY_POST_TYPES[item.post_type] || "Add post type")}
        >
          {_.capitalize(STRATEGY_POST_TYPES[item.post_type] || "Add")}
        </MTableCell>
      )}
      {columnSet.has("pages") && <MTableCellNumber type={"bigNumber"} value={item.pages} placeHolder={"-"} />}
      {columnSet.has("clicks") && <MTableCellNumber type={"bigNumber"} value={item.clicks} />}
      {columnSet.has("variations") && <MTableCellNumber type={"bigNumber"} value={item.variations} placeHolder={"-"} />}
      {columnSet.has("volume") && (
        <MTableCellNumber type={"bigNumber"} value={item.volume || item.strategy?.volume} placeHolder={"-"} />
      )}
      {columnSet.has("reviewed_at") && (
        <MTableCellText text={item.reviewed_at ? moment(item.reviewed_at).fromNow() : "-"} />
      )}
      {columnSet.has("added_at") && (
        <MTableCellText text={item.submitted_at ? moment(item.submitted_at).fromNow() : "-"} />
      )}

      <MTableCell>
        {buttonOptions.length > 0 && (
          <div className={classes.actionsWrapper}>
            <MActionsDropdown placement={"bottom-end"} options={buttonOptions} />{" "}
          </div>
        )}
      </MTableCell>
    </MTableRow>
  );
};

export default connect(mapStateToProps)(MappingListItem);
