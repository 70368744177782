import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useSnackbar } from "notistack";
import { push } from "connected-react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import LeftSideScreen from "./components/LeftSideScreen";
import MainScreen from "./components/MainScreen";
import agent from "../../agent";
import { OUTLINE } from "../../reducers/constants/actionTypes";
import { IOutline, IWebsite } from "../../reducers/constants/objectTypes";
import OutlineBottomBar from "./components/OutlineBottomBar";
import { web } from "../../helpers/urlHelper";
import CfProgressBar from "../../components/CfProgressBar";

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    zIndex: 100,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#f5f6f8",
  },
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: 55,
    paddingRight: 20,
    paddingLeft: 40,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: 10,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  content: {
    height: "calc(100vh - 115px)",
    display: "flex",
    padding: "20px 20px 0 20px",
  },
}));

type PropTypes = {
  view: "DATA_VIEW" | "EDITOR_VIEW";
  outlineLoaded: boolean;
  outline: IOutline;
  outlineWebsite: IWebsite;

  onTagsLoaded: (value) => void;
  onCategoriesLoaded: (value) => void;
  onKeywordsLoaded: (value) => void;
  onOutlineLoaded: (outline: IOutline) => void;
  returnToOutlinePagesList: (view) => void;
  cleanUp: () => void;
  onOutlinePropertyChanged: (name, value) => void;
};

const mapStateToProps = (state) => ({
  outlineLoaded: state.outline.outlineLoaded,
  outline: state.outline.outline,
  outlineWebsite: state.outline.outlineWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onTagsLoaded: (payload) => dispatch({ type: OUTLINE.ON_OUTLINE_TAGS_LOADED, payload }),
  onCategoriesLoaded: (payload) => dispatch({ type: OUTLINE.ON_OUTLINE_CATEGORIES_LOADED, payload }),
  onKeywordsLoaded: (payload) => dispatch({ type: OUTLINE.ON_OUTLINE_KEYWORD_GROUPS_LOADED, payload }),
  onOutlineLoaded: (outline: IOutline) => dispatch({ type: OUTLINE.ON_OUTLINE_PAGE_LOADED, outline }),
  cleanUp: () => dispatch({ type: OUTLINE.OUTLINE_PAGE_CLEANUP }),
  returnToOutlinePagesList: (view) => {
    if (view === "DATA_VIEW") {
      dispatch(push(web.outlines()));
    } else if (view === "EDITOR_VIEW") {
      dispatch(push(web.workflowOutlines()));
    }
  },
  onOutlinePropertyChanged: (name, value) => dispatch({ type: OUTLINE.ON_OUTLINE_PROPERTY_CHANGED, name, value }),
});

const OutlineEditor = (props: PropTypes) => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const { outlineLoaded, outline, outlineWebsite, view, onTagsLoaded, onOutlineLoaded, onKeywordsLoaded } = props;
  const { onCategoriesLoaded, returnToOutlinePagesList, cleanUp, onOutlinePropertyChanged } = props;
  const { outlineId } = useParams();

  React.useEffect(() => {
    agent.Outlines.takeover(outlineId, view)
      .then((res) => {
        onOutlineLoaded(res.outline);
      })
      .catch((e) => {
        returnToOutlinePagesList(view);
        enqueueSnackbar(I18n.t("snackbar.outline.unavailable_outline"));
      });
  }, [onOutlineLoaded]);

  React.useEffect(() => {
    if (!outlineWebsite) {
      return;
    }
    console.log("outlineWebsite - load all", outlineWebsite);
    onCategoriesLoaded(agent.Categories.all(outlineWebsite._id));
    onTagsLoaded(agent.Tags.all(outlineWebsite._id));
    onKeywordsLoaded(agent.QueryGroups.getQueryGroups(outlineWebsite._id, 1, 10));
  }, [outlineWebsite?._id]);

  // React.useEffect(() => {
  // 	setLoading(true);
  // 	agent.Outlines.takeover(outlineId, view)
  // 		.then(res => {
  // 			onOutlinePageLoaded(res.outlinePage);
  // 			setLoading(false);
  // 		}).catch(e => {
  // 		returnToOutlinePagesList(view);
  // 		enqueueSnackbar(I18n.t('snackbar.outline.unavailable_outline'));
  // 		setLoading(false);
  // 	});
  // 	if (website) {
  // 		onCategoriesLoaded(agent.Categories.all(website._id));
  // 		onTagsLoaded(agent.Tags.all(website._id));
  // 		onKeywordsLoaded(agent.Keywords.all(website._id));
  // 	}
  // }, [onOutlinePageLoaded, onCategoriesLoaded, onTagsLoaded, onKeywordsLoaded]);

  if (!outlineLoaded) {
    return (
      <div className={classes.pageWrapper}>
        <CfProgressBar />
      </div>
    );
  }

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.toolbar}>
        <div className={classes.toolbarDetails}>
          <ArrowBackIcon
            className={classes.backIcon}
            onClick={() => {
              console.log("back");
              returnToOutlinePagesList(view);
            }}
          />
          <Typography color={"textPrimary"} variant={"subtitle2"}>
            {I18n.t("outline.generator_title", { website: outline?.website?.name })}
          </Typography>
        </div>
        {/* {o && ( */}
        {/*	<Typography color={'textSecondary'} variant={'body2'}> */}
        {/*		{DEV ? post.state : ''} */}
        {/*		<Link */}
        {/*			target={'_blank'} */}
        {/*			rel="noopener" */}
        {/*			href={web.customerPostPreview(post)} */}
        {/*		> */}
        {/*			{I18n.t('upload_blog_post.Preview')} */}
        {/*		</Link> */}
        {/*	</Typography> */}
        {/* )} */}
      </div>
      <div className={classes.content}>
        <LeftSideScreen />
        <MainScreen />
      </div>
      <OutlineBottomBar outlineId={outlineId} view={view} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlineEditor);
