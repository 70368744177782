import React from "react";
import { I18n } from "react-redux-i18n";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { IWebsite } from "../../../../reducers/constants/objectTypes";
import ReportCard from "./ReportCard";
import useReport from "./useReport";
import { REPORT_CACHE_KEY_NAME } from "../../../../helpers/constants";

import IndexedQueriesChart from "./charts/IndexedQueriesChart";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: 150,
    margin: 0,
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.pxToRem(14),
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  startDate: Date;
};

const IndexedQueriesReport = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, startDate } = props;
  const [selectedTimeFrame, setSelectedTimeFrame] = React.useState({ value: "month", label: "Month" });

  const extraParams = React.useMemo(() => ({ timeFrame: selectedTimeFrame.value }), [selectedTimeFrame.value]);

  const {
    loading,
    setTriggerRefresh,
    triggerRefresh,
    fetchResponse,
    dateFormat,
    monthFormat,
    fullDateFormat,
    numberFormat,
    titlesFormat,
  } = useReport({
    selectedWebsite,
    startDate,
    reportType: "indexed-queries",
    extraParams,
  });

  const { data, cacheData } = React.useMemo(() => {
    if (fetchResponse) {
      return {
        data: fetchResponse.results,
        cacheData: {
          cache: fetchResponse.cache,
          lastFetch: fetchResponse.fetchedTime,
        },
      };
    }
    return {
      data: [],
    };
  }, [fetchResponse]);

  return (
    <ReportCard
      subTitle={I18n.t("reports.dashboard.all_pages")}
      reportTypeName={"indexed-queries"}
      containsData={data.length > 0}
      cacheData={cacheData}
      setRefreshCache={() => {
        sessionStorage.setItem(REPORT_CACHE_KEY_NAME, "true");
        setTriggerRefresh(!triggerRefresh);
      }}
      loading={loading}
      showTimeFrameSelect
      timeFrameProps={{ value: selectedTimeFrame, handleChange: setSelectedTimeFrame }}
    >
      <IndexedQueriesChart
        data={data}
        timeFormat={selectedTimeFrame.value === "month" ? monthFormat : dateFormat}
        numberFormat={numberFormat}
        fullDateFormat={fullDateFormat}
        titlesFormat={titlesFormat}
      />
    </ReportCard>
  );
};

export default IndexedQueriesReport;
