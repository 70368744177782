import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { LinkPreview } from "../../../components/linkPreview/LinkPreview";

const useStyles = makeStyles((theme: Theme) => ({
  preview: {
    "& .Description": {
      fontSize: 16,
      lineHeight: 1.3,
    },
  },
}));
type PropTypes = {
  url: string;
  width: number | string;
  onSuccess?: (metadata: any) => void;
};

function LinkPreviewer(props: PropTypes) {
  const classes = useStyles(props);
  const { url, width, onSuccess } = props;

  return (
    <LinkPreview
      url={url}
      width={width}
      className={classes.preview}
      borderColor={"transparent"}
      onSuccess={onSuccess}
    />
  );
}

export default LinkPreviewer;
