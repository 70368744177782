import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import CfButton from "../../components/CfButton";
import AuthNavbar from "./components/AuthNavbar";
import AuthCard from "./components/AuthCard";
import AuthTextField from "./components/AuthTextField";
import { useEffect } from "react";
import agent from "../../agent";
import useQuery from "../../hooks/useQuery";
import { useSnackbar } from "notistack";
import { replace } from "connected-react-router";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
  },
  title: {
    margin: "24px 0",
  },
  description: {
    width: "282px",
    height: 38,
    color: "#808080",
    fontSize: 16,
    lineHeight: "19px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    marginBottom: 24,
  },
  email: {
    color: "black",
  },
  divider: {
    color: "black",
    height: 1,
    width: 121,
    backgroundColor: "#E5E5E5",
  },
  credentialsWrapper: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    marginTop: 10,
    marginBottom: 20,
    minWidth: 200,
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  terms: {
    width: 290,
    height: 60,
    marginTop: 20,
    textAlign: "center",
  },
}));

type PropTypes = {
  onResendClick: (to: string) => void;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  onResendClick: (to) => dispatch(replace(to)),
});

const LinkExpired = (props: PropTypes) => {
  const classes = useStyles(props);

  const { onResendClick } = props;

  return (
    <section className={classes.pageWrapper}>
      <AuthNavbar />
      <div className={classes.contentWrapper}>
        <AuthCard customClass={classes.title} title={I18n.t("login.link_expired")}>
          <Typography variant={"body2"} className={classes.description}>
            <div>{I18n.t("login.link_expired_description")}</div>
          </Typography>

          <CfButton
            fullWidth
            customClass={classes.button}
            text={I18n.t("login.resend_link")}
            onClick={() => onResendClick("/forgot-password")}
          />
        </AuthCard>
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkExpired);
