import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router-dom";
import { web } from "../../../helpers/urlHelper";
import theme from "../../../themes/theme";
import MTabs from "../../../components/MTabs";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import { AbilityContext } from "../../../casl/can";
import { getCmsSettingsTabs, getSeoTabs } from "../../../helpers/tabHelper";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    marginLeft: "-25px",
    marginRight: "-30px",
    flexDirection: "column",
    height: 50,
    justifyContent: "center",
    borderTop: `solid 1px ${theme.palette.divider}`,
  },
  tabsWrapper: {
    display: "flex",
    justifyContent: "start",
    paddingLeft: 10,
    paddingRight: 30,
    marginLeft: 0,
  },
  tabsRoot: {
    minHeight: 40,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight as any,
    paddingRight: 0,
    paddingLeft: 0,
    "& p": {
      textTransform: "lowercase",
      "&:first-letter": {
        textTransform: "uppercase",
      },
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};

const SubPagesTabs = (props: PropTypes) => {
  const classes = useStyles(props);
  const match = useRouteMatch(`${web.homePage()}/:main/:page`);
  const ability = React.useContext<any>(AbilityContext);

  const tabs = React.useMemo(() => {
    if (!match?.params) {
      return [];
    }
    switch (`${match.params.main}/${match.params.page}`) {
      case "cms/settings":
        return getCmsSettingsTabs(ability);
      case "page-builder/seo":
        return getSeoTabs(ability);
      default:
        return [];
    }
  }, [match]);

  if (tabs.length === 0) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <MTabs
        tabsWrapperClass={classes.tabsWrapper}
        activeColor={theme.palette.primary.main}
        tabsRootClass={classes.tabsRoot}
        tabs={tabs}
      />
    </div>
  );
};

export default SubPagesTabs;
