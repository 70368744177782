import * as React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

type PropTypes = {
  className?: string;
};

function QuotesIcon(props: PropTypes) {
  const { className } = props;
  return (
    <SvgIcon className={className} viewBox={"0 0 35 31"}>
      <path d="M7.0809 0.195312C3.42153 0.195312 0.453125 3.24782 0.453125 7.01401C0.453125 10.7779 3.42153 13.8327 7.0809 13.8327C13.7064 13.8327 9.29016 27.0133 0.453125 27.0133V30.1953C16.2248 30.1976 22.4048 0.195312 7.0809 0.195312ZM26.1733 0.195312C22.5162 0.195312 19.5478 3.24782 19.5478 7.01401C19.5478 10.7779 22.5162 13.8327 26.1733 13.8327C32.801 13.8327 28.3848 27.0133 19.5478 27.0133V30.1953C35.3171 30.1976 41.4972 0.195312 26.1733 0.195312Z" />
    </SvgIcon>
  );
}

export default QuotesIcon;
