import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import MSelect from "../../MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    flexGrow: 1,
    flexBasis: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingRight: 10,
  },
  select: {
    textTransform: "capitalize",
    cursor: "pointer",
    marginLeft: -10,
    paddingRight: 10,
  },
}));

type PropTypes = {
  options: any[];
  value: any;
  optionLabel?: string;
  optionValue?: string;
  placeholder: string;
  handleInputChange?: (input: string) => void;
  handleChange?: (newValue, actionMeta) => void;
  searchable?: boolean;
};

const MTableCellSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    options,
    value,
    optionLabel = "name",
    optionValue = "_id",
    placeholder,
    handleInputChange,
    handleChange,
    searchable = true,
  } = props;

  return (
    <div className={classes.column}>
      <MSelect
        customClass={classes.select}
        options={options}
        value={value}
        optionLabel={optionLabel}
        optionValue={optionValue}
        height={40}
        borderRadius={0}
        borderWidth={"0"}
        menuPlacement={"bottom"}
        placeholder={placeholder}
        searchable={searchable}
        handleInputChange={handleInputChange}
        handleChange={handleChange}
      />
    </div>
  );
};

export default MTableCellSelect;
