import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import { kebabCase } from "lodash";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import MRouterPrompt from "../../../components/MRouterPrompt";
import SelectRow from "../../../components/SelectRow";
import { IWebsiteDetailsProps, IWebsiteReviewStep } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  shopifyInputWrapper: {
    display: "flex",
    marginLeft: "-10px",
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  selects: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  selectWrap: {
    width: 300,
  },
  imagesWrapper: {
    display: "flex",
  },
  imageRow: {
    display: "flex",
    flexDirection: "column",
    marginRight: 80,
    "&:last-child": {
      marginRight: 0,
    },
  },
  imageFieldTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 15,
  },
  shopifyDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
}));

const mapStateToProps = (state) => ({
  reviews: state.websitePages.reviews,
  reviewProcess: state.websitePages.reviewProcess,
  websiteActive: state.websitePages.websiteActive,
  websiteUploadContent: state.websitePages.websiteUploadContent,
  secondEditStep: state.websitePages.secondEditStep,
  siteDetails: state.websitePages.siteDetails,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (name, value) => dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED, name, value }),
});

type PropTypes = {
  websiteActive: boolean;
  websiteUploadContent: boolean;
  secondEditStep: boolean;
  reviews: IWebsiteReviewStep[];
  reviewProcess: "None" | "Fact-Check" | "Custom";
  onPropertyChanged: (name, value) => void;
  siteDetails: IWebsiteDetailsProps;
};

const reviewOptions = ["none", "factCheck", "regulationCheck"];

const SiteDetailsCMS = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    websiteActive = false,
    websiteUploadContent = false,
    secondEditStep,
    reviewProcess = "None",
    reviews = [],
    siteDetails,
    onPropertyChanged,
  } = props;

  return (
    <>
      <PageSection sectionTitle={I18n.t("website.cms")}>
        <MRouterPrompt />
        <div className={classes.inputRowWrapper}>
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.active")}
            value={websiteActive ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              onPropertyChanged("websiteActive", value === "Yes");
            }}
          />
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.upload_content")}
            value={websiteUploadContent ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              onPropertyChanged("websiteUploadContent", value === "Yes");
            }}
          />
        </div>
        <div className={classes.inputRowWrapper}>
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.second_edit")}
            value={secondEditStep ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              onPropertyChanged("secondEditStep", value === "Yes");
            }}
          />
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.review_process")}
            value={reviewProcess}
            selectOptions={["None", "Fact-Check", "Custom"]}
            handleChange={(value) => {
              onPropertyChanged("reviewProcess", value);
            }}
          />
        </div>
        {reviewProcess === "Fact-Check" && (
          <div className={classes.inputRowWrapper}>
            <SelectRow
              bold
              flexDirection={"column"}
              width={"calc(50% - 10px)"}
              rowTitle={I18n.t("website.show_fact_checker")}
              value={siteDetails.factCheckerActive ? "Yes" : "No"}
              selectOptions={["Yes", "No"]}
              handleChange={(value) => {
                onPropertyChanged("siteDetails", { ...siteDetails, factCheckerActive: value === "Yes" });
              }}
            />
          </div>
        )}

        <>
          {reviewProcess === "Custom" && (
            <>
              <Divider className={classes.divider} />
              {reviews.map((review, index) => (
                <div key={`review_${index}`} className={classes.inputRowWrapper}>
                  <SelectRow
                    bold
                    flexDirection={"column"}
                    rowTitle={I18n.t("website.review_type")}
                    getLabel={(option) => I18n.t(`website.${option}`)}
                    value={review.reviewType}
                    selectOptions={reviewOptions}
                    handleChange={(value) => {
                      const nReviews = [...reviews];
                      nReviews[index].reviewType = value;
                      onPropertyChanged("reviews", nReviews);
                    }}
                  />
                  <TextFieldMaxLength
                    key={"site-name"}
                    titleAndLengthLocation={"top"}
                    value={review.reviewName}
                    placeholder={""}
                    title={I18n.t("website.name")}
                    maxLength={30}
                    height={37}
                    borderRadius={4}
                    marginBottom={30}
                    handleValueChange={(newName) => {
                      const nReviews = [...reviews];
                      nReviews[index].reviewName = newName;
                      nReviews[index].reviewStepName = kebabCase(newName);
                      onPropertyChanged("reviews", nReviews);
                    }}
                  />

                  {review.reviewType === "regulationCheck" && (
                    <>
                      <TextFieldMaxLength
                        key={"review-locales"}
                        titleAndLengthLocation={"top"}
                        value={review.locales?.join(",") || ""}
                        placeholder={""}
                        title={I18n.t("website.locales")}
                        maxLength={30}
                        height={37}
                        borderRadius={4}
                        marginBottom={30}
                        handleValueChange={(newLocales) => {
                          const nReviews = [...reviews];
                          nReviews[index].locales = newLocales.split(",");
                          onPropertyChanged("reviews", nReviews);
                        }}
                      />
                      <TextFieldMaxLength
                        key={"review-approving-locales"}
                        titleAndLengthLocation={"top"}
                        value={review.approvingLocales?.join(",") || ""}
                        placeholder={""}
                        title={I18n.t("website.approving_locales")}
                        maxLength={30}
                        height={37}
                        borderRadius={4}
                        marginBottom={30}
                        handleValueChange={(newLocales) => {
                          const nReviews = [...reviews];
                          nReviews[index].approvingLocales = newLocales.split(",");
                          onPropertyChanged("reviews", nReviews);
                        }}
                      />
                    </>
                  )}
                </div>
              ))}
              <div className={classes.inputRowWrapper}>
                <SelectRow
                  bold
                  flexDirection={"column"}
                  rowTitle={I18n.t("website.review_type")}
                  getLabel={(option) => I18n.t(`website.${option}`)}
                  value={reviewOptions[0]}
                  selectOptions={reviewOptions}
                  handleChange={(value) => {
                    const nReviews = [...reviews];
                    nReviews.push({ reviewType: value });
                    onPropertyChanged("reviews", nReviews);
                  }}
                />
                <TextFieldMaxLength
                  key={"site-name"}
                  titleAndLengthLocation={"top"}
                  value={null}
                  placeholder={""}
                  title={I18n.t("website.name")}
                  maxLength={30}
                  height={37}
                  borderRadius={4}
                  marginBottom={30}
                  handleValueChange={(newName) => {
                    const nReviews = [...reviews];
                    nReviews.push({ reviewName: newName, reviewStepName: kebabCase(newName) });
                    onPropertyChanged("reviews", nReviews);
                  }}
                />
              </div>
            </>
          )}
        </>
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetailsCMS);
