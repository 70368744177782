import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import { IWebsite, IWebsiteGSC } from "../../../../../reducers/constants/objectTypes";
import MRouterPrompt from "../../../components/MRouterPrompt";
import moment from "moment/moment";
import { DATE_FORMAT } from "../../../../../reducers/constants/consts";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme: Theme) => ({
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  shopifyInputWrapper: {
    display: "flex",
    marginLeft: "-10px",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  selects: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  selectWrap: {
    width: 300,
  },
  imagesWrapper: {
    display: "flex",
  },
  imageRow: {
    display: "flex",
    flexDirection: "column",
    marginRight: 80,
    "&:last-child": {
      marginRight: 0,
    },
  },
  imageFieldTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 15,
  },
  shopifyDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  gsc: state.websitePages.gsc,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (value) => dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED, name: "gsc", value }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  gsc: IWebsiteGSC;
  onPropertyChanged: (value) => void;
};

const SiteDetailsGSC = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    gsc = {
      website: selectedWebsite,
      enabled: false,
      gscDomain: "",
      lastScan: null,
      minAvailableDate: null,
      maxAvailableDate: null,
      lastAvailableDatesScan: null,
      serpEnabled: false,
    },
    onPropertyChanged,
  } = props;
  return (
    <>
      <PageSection
        checked={gsc?.enabled || false}
        onCheckedChange={(enabled) => onPropertyChanged({ ...gsc, enabled })}
        sectionTitle={I18n.t("website.gsc")}
      >
        <MRouterPrompt />
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            key={"sc-domain"}
            titleAndLengthLocation={"top"}
            value={gsc?.gscDomain || ""}
            placeholder={I18n.t("website.sc-domain_ph")}
            title={I18n.t("website.sc-domain")}
            maxLength={100}
            height={37}
            borderRadius={4}
            handleValueChange={(gscDomain) => {
              onPropertyChanged({ ...gsc, gscDomain });
            }}
            marginBottom={30}
          />
        </div>
        <Divider className={classes.divider} />
        <div style={{ display: "flex", gap: 20 }}>
          <TextFieldMaxLength
            key={"lastScan"}
            titleAndLengthLocation={"top"}
            value={gsc?.lastScan ? moment(gsc?.lastScan).format(DATE_FORMAT.READABLE) : "-"}
            disabled
            placeholder={""}
            title={I18n.t("website.lastScan")}
            maxLength={5}
            height={37}
            borderRadius={4}
            handleValueChange={null}
            marginBottom={30}
          />
          <TextFieldMaxLength
            key={"lastAvailableDatesScan"}
            titleAndLengthLocation={"top"}
            value={gsc?.lastAvailableDatesScan ? moment(gsc.lastAvailableDatesScan).format(DATE_FORMAT.READABLE) : "-"}
            disabled
            placeholder={""}
            title={I18n.t("website.lastAvailableDatesScan")}
            maxLength={5}
            height={37}
            borderRadius={4}
            handleValueChange={null}
            marginBottom={30}
          />
          <TextFieldMaxLength
            key={"minAvailableDate"}
            titleAndLengthLocation={"top"}
            value={gsc?.minAvailableDate ? moment(gsc.minAvailableDate).format(DATE_FORMAT.READABLE) : "-"}
            disabled
            placeholder={""}
            title={I18n.t("website.minAvailableDate")}
            maxLength={5}
            height={37}
            borderRadius={4}
            handleValueChange={null}
            marginBottom={30}
          />
          <TextFieldMaxLength
            key={"maxAvailableDate"}
            titleAndLengthLocation={"top"}
            value={gsc?.maxAvailableDate ? moment(gsc.maxAvailableDate).format(DATE_FORMAT.READABLE) : "-"}
            disabled
            placeholder={""}
            title={I18n.t("website.maxAvailableDate")}
            maxLength={5}
            height={37}
            borderRadius={4}
            handleValueChange={null}
            marginBottom={30}
          />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
            Enable SERPs
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={gsc?.serpEnabled}
                  onChange={() => onPropertyChanged({ ...gsc, serpEnabled: !gsc?.serpEnabled })}
                  color="primary"
                />
              }
              labelPlacement="start"
              label={I18n.t(`website.${gsc?.serpEnabled ? "enabled" : "disabled"}`)}
            />
          </FormGroup>
        </div>
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetailsGSC);
