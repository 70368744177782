import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { useSlate, useSlateStatic } from "slate-react";
import AssistantList from "./components/AssistantList";
import NewTaskAssistant from "./components/NewTaskAssistant";
import { useEvent } from "../../../../hooks/useEvents";
import { IPage, IWebsite } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  snackBarRoot: {
    backgroundColor: "#fff",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  page: IPage;
};

const PageEditorAssistant = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, page } = props;
  const [showNewTask, setShowNewTask] = React.useState(true);
  const editor = useSlateStatic();

  useEvent(
    "show-ai-assistant",
    (data) => {
      if (data.selected) {
        if (data.openTab) {
          setShowNewTask(true);
        }
      }
    },
    []
  );

  if (showNewTask) {
    return (
      <NewTaskAssistant
        selectedWebsite={selectedWebsite}
        page={page}
        navigateBack={(value) => setShowNewTask(!value)}
      />
    );
  }
  return <AssistantList />;
};

export default PageEditorAssistant;
