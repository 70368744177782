import * as React from "react";
import theme from "../../../themes/theme";
import TextBox from "./TextBox";

const TextBoxElementReadOnly = ({ attributes, children, element }) => {
  const textBoxCss = {
    margin: "20px 0",
    padding: "1px 10px",
    color: theme.palette.text.primary,
    background: theme.palette.secondary.main,
    ...(element.data?.textBoxCss || {}),
  };
  return (
    <TextBox textBoxStyle={textBoxCss} attributes={attributes}>
      {children}
    </TextBox>
  );
};

export default TextBoxElementReadOnly;
