import * as React from "react";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";
import ButtonDesignToolWithPreview from "./ButtonDesignToolWithPreview";
import SelectRow from "./SelectRow";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: 30,
  },
}));

type PropTypes = {
  textFieldTitle: string;
  urlFieldTitle: string;
  defaultButtonCss: any;
  background?: any;
  button: {
    text: string;
    url: string;
    css: any;
    arrow?: boolean;
  };
  toggleArrow?: boolean;

  handleButtonChanged: (button) => void;
};

const ButtonTextAndUrlAndDesign = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    textFieldTitle,
    button,
    defaultButtonCss,
    urlFieldTitle,
    handleButtonChanged,
    background,
    toggleArrow = false,
  } = props;

  return (
    <div className={classes.wrapper}>
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={button?.text || ""}
        placeholder={""}
        title={textFieldTitle}
        maxLength={200}
        height={37}
        borderRadius={4}
        handleValueChange={(propValue) => {
          handleButtonChanged({ ...button, text: propValue });
        }}
        marginBottom={30}
      />

      {toggleArrow && (
        <SelectRow
          width={"50%"}
          bold
          flexDirection={"column"}
          rowTitle={I18n.t("website.show_arrow")}
          value={button.arrow ? "Yes" : "No"}
          selectOptions={["Yes", "No"]}
          handleChange={(value) => {
            handleButtonChanged({ ...button, arrow: value === "Yes" });
          }}
        />
      )}
      <ButtonDesignToolWithPreview
        background={background}
        topTitle
        disableHover
        marginLeft={"-30px"}
        showArrow={button.arrow != null && button.arrow}
        marginRight={"-30px"}
        exampleText={button.text}
        elementTitle={I18n.t("website.buttons")}
        elementCss={button?.css || {}}
        defaultElementCss={defaultButtonCss}
        handleCssChanged={(newCss) => {
          handleButtonChanged({ ...button, css: newCss });
        }}
      />
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={button?.url || ""}
        placeholder={""}
        title={urlFieldTitle}
        maxLength={200}
        height={37}
        borderRadius={4}
        handleValueChange={(propValue) => {
          handleButtonChanged({ ...button, url: propValue });
        }}
        marginBottom={30}
        marginTop={30}
      />
    </div>
  );
};

export default ButtonTextAndUrlAndDesign;
