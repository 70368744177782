import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useState } from "react";
import { ePostType, ILearnTopic, IWebsite } from "../../../../../reducers/constants/objectTypes";
import { EDIT_TOPIC } from "../../../../../reducers/constants/actionTypes";
import agent from "../../../../../agent";
import MSelect from "../../../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  writersWrapper: {
    width: "100%",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  topic: ILearnTopic;
  topicType: ePostType;

  handleTopicChanged: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  topic: state.editTopic.topic,
  topicType: state.editTopic.topicType,
});

const mapDispatchToProps = (dispatch) => ({
  handleTopicChanged: (topic) => dispatch({ type: EDIT_TOPIC.TOPIC_CHANGED, topic }),
});

const EditTopicInfoTabWriter = (props: PropTypes) => {
  const classes = useStyles(props);
  const { topic, topicType, selectedWebsite, handleTopicChanged } = props;
  const [writers, setWriters] = useState([]);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    agent.Writers.getWriters(selectedWebsite, topicType)
      .then((res) => {
        setWriters(res.writers);
      })
      .catch((e) => {
        // show error message
      });
  }, [selectedWebsite, topicType]);

  const selectableWriters = [{ email: "All", name: "Any writer" }, ...writers];

  return (
    <div className={classes.writersWrapper}>
      <MSelect
        options={selectableWriters}
        value={_.find(selectableWriters, { email: topic?.writer?.email || "All" })}
        optionLabel={"name"}
        optionValue={"_id"}
        height={40}
        borderRadius={0}
        menuPlacement={"bottom"}
        fullWidth
        searchable
        getDisplayLabel={(writer) => `${writer.name}`}
        handleChange={(writer) => {
          handleTopicChanged({ ...topic, writer: writer.name === "All" ? null : writer });
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTopicInfoTabWriter);
