import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { I18n } from "react-redux-i18n";
import { TransitionProps } from "@material-ui/core/transitions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import { useSnackbar } from "notistack";
import MTextField from "../../../components/MTextField";
import CfButton from "../../../components/CfButton";
import agent from "../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderWidth: 0,
  },
  titleWrapper: {
    height: 85,
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontSize: theme.typography.pxToRem(24),
    color: "white",
    paddingLeft: 40,
  },
  title: {
    margin: 0,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    minHeight: 220,
    padding: 40,
  },
  dialogActions: {
    padding: "20px 40px 20px 40px",
    borderTop: `${theme.palette.divider} solid 1px`,
  },
  closeButton: {
    position: "absolute",
    right: 20,
    // top: theme.spacing(1),
    color: "white",
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(36),
  },
  searchTextField: {
    marginTop: 5,
    marginBottom: 20,
  },
  searchTextFieldInput: {
    height: 18,
  },
}));

type PropTypes = {
  open: boolean;
  handleCloseClick: Function;
  handleWebsiteCreated: Function;
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const removeTrailingSlash = (str) => {
  return str.replace(/\/+$/, "");
};

const NewWebsiteDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, handleCloseClick, handleWebsiteCreated } = props;
  const [loading, setLoading] = React.useState(false);
  // const [website, setWebsite] = React.useState({name:"", url:"", dbName:""});
  const [websiteName, setWebsiteName] = React.useState("");
  const [websiteUrl, setWebsiteUrl] = React.useState("");
  const [websiteS3BucketFolder, setWebsiteS3BucketFolder] = React.useState("");
  const [websiteDbName, setWebsiteDbName] = React.useState("");
  const [websiteHost, setWebsiteHost] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const onOpen = () => {
    console.log("onOpen");
  };

  const close = () => {
    setLoading(false);
    setWebsiteName("");
    setWebsiteUrl("");
    setWebsiteS3BucketFolder("");
    setWebsiteDbName("");
    setWebsiteHost("");
    handleCloseClick();
  };

  const createWebsite = () => {
    setLoading(true);

    agent.Websites.newWebsite({
      websiteName,
      websiteUrl,
      websiteS3BucketFolder: websiteS3BucketFolder.toLowerCase(),
      websiteDbName: websiteDbName.toLowerCase(),
      websiteHost,
    })
      .then((res) => {
        console.log(`newWebsite: ${JSON.stringify(res.website)}`);
        handleWebsiteCreated(res.website);
        close();
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      onEntered={(e) => onOpen()}
      onClose={(event) => close()}
      fullWidth
      maxWidth={"md"}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.titleWrapper}>
        <div className={classes.dialogTitle}>
          <Typography color={"inherit"} className={classes.title} variant={"h3"}>
            {I18n.t("website.new_website_dialog.title")}
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div style={{ width: "100%" }}>
          <Typography color={"textPrimary"} variant={"body1"}>
            {I18n.t("website.new_website_dialog.website_name")}
          </Typography>
          <MTextField
            fullWidth
            outlined
            autoFocus
            customClass={classes.searchTextField}
            inputClassName={classes.searchTextFieldInput}
            value={websiteName}
            placeholder={I18n.t("website.new_website_dialog.website_name_ph")}
            onChange={(userInput) => {
              setWebsiteName(userInput);
            }}
          />
          <Typography color={"textPrimary"} variant={"body1"}>
            {I18n.t("website.new_website_dialog.website_url")}
          </Typography>
          <MTextField
            fullWidth
            outlined
            customClass={classes.searchTextField}
            inputClassName={classes.searchTextFieldInput}
            value={websiteUrl}
            placeholder={I18n.t("website.new_website_dialog.website_url_ph")}
            onChange={(userInput) => {
              setWebsiteUrl(removeTrailingSlash(userInput));
            }}
          />
          <Typography color={"textPrimary"} variant={"body1"}>
            {I18n.t("website.new_website_dialog.website_host")}
          </Typography>
          <MTextField
            fullWidth
            outlined
            customClass={classes.searchTextField}
            inputClassName={classes.searchTextFieldInput}
            value={websiteHost}
            placeholder={I18n.t("website.new_website_dialog.website_host_ph")}
            onChange={(userInput) => {
              setWebsiteHost(removeTrailingSlash(userInput));
            }}
          />
          <Typography color={"textPrimary"} variant={"body1"}>
            {I18n.t("website.new_website_dialog.website_s3BucketFolder")}
          </Typography>
          <MTextField
            fullWidth
            outlined
            customClass={classes.searchTextField}
            inputClassName={classes.searchTextFieldInput}
            value={websiteS3BucketFolder}
            placeholder={I18n.t("website.new_website_dialog.website_s3BucketFolder_ph")}
            onChange={(userInput) => {
              setWebsiteS3BucketFolder(userInput);
            }}
          />
          <Typography color={"textPrimary"} variant={"body1"}>
            {I18n.t("website.new_website_dialog.website_dbName")}
          </Typography>
          <MTextField
            fullWidth
            outlined
            customClass={classes.searchTextField}
            inputClassName={classes.searchTextFieldInput}
            value={websiteDbName}
            placeholder={I18n.t("website.new_website_dialog.website_dbName_ph")}
            onChange={(userInput) => {
              setWebsiteDbName(userInput);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CfButton
          height={37}
          text={I18n.t("website.new_website_dialog.create_button")}
          disabled={
            websiteName.length === 0 ||
            websiteHost.length === 0 ||
            websiteDbName.length === 0 ||
            websiteS3BucketFolder.length === 0 ||
            websiteUrl.length === 0
          }
          loading={loading}
          onClick={(e) => createWebsite()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default NewWebsiteDialog;
