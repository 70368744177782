import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import Typography from "@material-ui/core/Typography";
import { Chip, Paper, Popover } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import edit from "../../../img/edit.png";
import SelectRow from "./SelectRow";
import InputRow from "./InputRow";
import ColorPickerRow from "./ColorPickerRow";
import DimensionsInputRow from "./DimensionsInputRow";
import {
  borderStyleOptions,
  fontOptions,
  fontStyleOptions,
  letterCaseOptions,
  textDecorationOptions,
  weightOptions,
} from "../WebsiteEditor/consts/CssConsts";

const useStyles = makeStyles((theme: Theme) => ({
  elementTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 15,
  },
  designPropsRow: {
    display: "flex",
    padding: "25px 30px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& $editWrapper": {
        display: "flex",
      },
    },
  },
  designWrapper: {
    width: "100%",
    cursor: "pointer",
    justifyContent: "space-between",
  },

  popoverPaper: {
    borderRadius: 0,
    boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.15)",
    border: "none",
    marginLeft: 10,
  },
  wrapper: {
    height: "100%",
    width: 416,
    border: "none",
    borderRadius: 0,
  },
  innerWrapper: {
    padding: "15px 20px",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 15,
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },

  editImage: {
    marginLeft: 15,
    cursor: "pointer",
  },
  toggleGroup: {
    width: "100%",
    marginBottom: 20,
  },
  toggleButton: {
    width: "100%",
  },
  editWrapper: {
    display: "none",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  designText: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  tagsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  previewWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
}));

type PropTypes = {
  theme: IWebsiteThemeProps;
  elementTitle?: string;
  elementCss: any;
  defaultElementCss: any;
  exampleText?: string;

  handleCssChanged: (css) => void;
};

const mapStateToProps = (state) => ({
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({});

const TagDesignToolWithPreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { elementCss, defaultElementCss } = props;
  const [hover, setHover] = React.useState(false);
  const [css, setCss] = React.useState<any>({});
  const [hoverCss, setHoverCss] = React.useState<any>({});
  const [finalDefaultCss, setFinalDefaultCss] = React.useState<any>({});
  const [finalDefaultHoverCss, setFinalDefaultHoverCss] = React.useState<any>({});
  const { theme, elementTitle, exampleText, handleCssChanged } = props;

  React.useEffect(() => {
    setCss(elementCss);
    setFinalDefaultCss({
      color: theme.palette.text.primary,
      textTransform: "capitalize",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      broderRadius: 20,
      ...defaultElementCss,
    });
    setHoverCss(elementCss?.["&:hover"]);
    setFinalDefaultHoverCss({
      textTransform: "capitalize",
      color: theme.palette.background.default,
      borderColor: theme.palette.primary.main,
      // borderWidth: 1,
      backgroundColor: theme.palette.primary.main,
      ...defaultElementCss,
      ...defaultElementCss?.["&:hover"],
    });
  }, [hover, elementCss, defaultElementCss, theme.palette]);

  const handleRegularCssChanged = (cssName, cssValue) => {
    if (cssValue === null || cssValue === "" || cssValue === finalDefaultCss[cssName]) {
      delete css[cssName];
      handleCssChanged(css);
      setCss(css);
      return;
    }
    const newCss = { ...css, [cssName]: cssValue };
    handleCssChanged(newCss);
    setCss(newCss);
  };

  const handleHoverCssChanged = (cssName, cssValue) => {
    if (cssValue === null || cssValue === "" || cssValue === finalDefaultCss["&:hover"][cssName]) {
      delete hoverCss[cssName];
      const newCss = { ...elementCss, "&:hover": hoverCss };
      handleCssChanged(newCss);
      setHoverCss(css);
      return;
    }
    const newCss = { ...elementCss, "&:hover": { ...elementCss["&:hover"], [cssName]: cssValue } };
    handleCssChanged(newCss);
    setHoverCss({ ...elementCss["&:hover"], [cssName]: cssValue });
  };

  const onCssChanged = (cssName, cssValue, hoverable) => {
    if (!hoverable || !hover) {
      handleRegularCssChanged(cssName, cssValue);
    } else {
      handleHoverCssChanged(cssName, cssValue);
    }
  };

  const handleHoverView = (event: React.MouseEvent<HTMLElement>, newHover: boolean | null) => {
    if (newHover !== null) {
      setHover(newHover);
    }
  };

  return (
    <div className={classes.designPropsRow}>
      <PopupState variant="popover" popupId="popover">
        {(popupState) => (
          <div style={{ display: "contents" }}>
            <div className={classes.designWrapper} {...bindTrigger(popupState)}>
              <div style={{ minWidth: "50%" }}>
                <Typography variant={"body2"} className={classes.elementTitle}>
                  {I18n.t("website.element_design", { element: elementTitle })}
                </Typography>
                <div className={classes.tagsWrapper}>
                  <div className={classes.previewWrapper}>
                    <Chip
                      label={exampleText || I18n.t("website.tag")}
                      clickable
                      style={{ ...finalDefaultCss, ...css }}
                    />
                    <Chip
                      label={exampleText || I18n.t("website.tag_on_hover")}
                      clickable
                      style={{
                        ...finalDefaultCss,
                        ...finalDefaultHoverCss,
                        ...elementCss,
                        ...css,
                        ...hoverCss,
                      }}
                    />
                  </div>
                  <div className={classes.editWrapper}>
                    <img width={12} height={12} src={edit} alt={"edit_image"} className={classes.editImage} />
                    <Typography variant={"body2"} className={classes.designText}>
                      {I18n.t("website.edit")}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              elevation={0}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <Paper className={classes.wrapper}>
                <div className={classes.titleWrapper}>
                  <Typography color={"textPrimary"} variant={"subtitle1"}>
                    {`${elementTitle} Typography`}
                  </Typography>
                  <CloseIcon className={classes.closeIcon} onClick={popupState.close} />
                </div>
                <div className={classes.innerWrapper}>
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.font")}
                    value={css?.fontFamily}
                    defaultValue={finalDefaultCss?.fontFamily || theme.typography.fontFamily}
                    isValueChanged={
                      css?.fontFamily &&
                      css?.fontFamily !== (finalDefaultCss?.fontFamily || theme.typography.fontFamily)
                    }
                    selectOptions={fontOptions}
                    handleChange={(font) => {
                      onCssChanged("fontFamily", font, false);
                    }}
                  />

                  <InputRow
                    rowTitle={I18n.t("website.design_tool.size")}
                    value={css?.fontSize}
                    defaultValue={finalDefaultCss?.fontSize}
                    isValueChanged={css?.fontSize && css?.fontSize !== finalDefaultCss?.fontSize}
                    handleChange={(size) => {
                      onCssChanged("fontSize", size, false);
                    }}
                  />

                  <InputRow
                    rowTitle={I18n.t("website.design_tool.line_height")}
                    value={css?.lineHeight}
                    stepSize={"0.1"}
                    defaultValue={finalDefaultCss?.lineHeight}
                    isValueChanged={css?.lineHeight && css?.lineHeight !== finalDefaultCss?.lineHeight}
                    handleChange={(lineHeight) => {
                      onCssChanged("lineHeight", lineHeight, false);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.letter_spacing")}
                    value={css?.letterSpacing}
                    defaultValue={finalDefaultCss?.letterSpacing}
                    isValueChanged={css?.letterSpacing && css?.letterSpacing !== finalDefaultCss?.letterSpacing}
                    handleChange={(letterSpacing) => {
                      onCssChanged("letterSpacing", letterSpacing, false);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.padding")}
                    value={css?.padding}
                    defaultValue={finalDefaultCss?.padding}
                    isValueChanged={css?.padding && css?.padding !== finalDefaultCss?.padding}
                    handleChange={(padding) => {
                      onCssChanged("padding", padding, false);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.margin")}
                    value={css?.margin}
                    defaultValue={finalDefaultCss?.margin}
                    isValueChanged={css?.margin && css?.margin !== finalDefaultCss?.margin}
                    handleChange={(margin) => {
                      onCssChanged("margin", margin, false);
                    }}
                  />

                  <ToggleButtonGroup
                    className={classes.toggleGroup}
                    size="small"
                    exclusive
                    value={hover}
                    onChange={handleHoverView}
                    aria-label="device"
                  >
                    <ToggleButton className={classes.toggleButton} value={false} aria-label="regular">
                      <Typography color={"textPrimary"} variant={"body1"}>
                        {I18n.t("website.design_tool.regular")}
                      </Typography>
                    </ToggleButton>
                    <ToggleButton className={classes.toggleButton} value={true} aria-label="hover">
                      <Typography color={"textPrimary"} variant={"body1"}>
                        {I18n.t("website.design_tool.hover")}
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {hover ? (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.text_color")}
                      value={hoverCss?.color}
                      defaultValue={finalDefaultHoverCss?.color}
                      isValueChanged={hoverCss?.color && hoverCss?.color !== finalDefaultHoverCss?.color}
                      handleChange={(color) => {
                        onCssChanged("color", color, true);
                      }}
                    />
                  ) : (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.text_color")}
                      value={css?.color}
                      defaultValue={finalDefaultCss?.color}
                      isValueChanged={css?.color && css?.color !== finalDefaultCss?.color}
                      handleChange={(color) => {
                        onCssChanged("color", color, true);
                      }}
                    />
                  )}

                  {hover ? (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.background_color")}
                      value={hoverCss?.backgroundColor}
                      defaultValue={finalDefaultHoverCss?.backgroundColor}
                      isValueChanged={
                        hoverCss?.backgroundColor && hoverCss?.backgroundColor !== finalDefaultHoverCss?.backgroundColor
                      }
                      handleChange={(backgroundColor) => {
                        onCssChanged("backgroundColor", backgroundColor, true);
                      }}
                    />
                  ) : (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.background_color")}
                      value={css?.backgroundColor}
                      defaultValue={finalDefaultCss?.backgroundColor}
                      isValueChanged={css?.backgroundColor && css?.backgroundColor !== finalDefaultCss?.backgroundColor}
                      handleChange={(backgroundColor) => {
                        onCssChanged("backgroundColor", backgroundColor, true);
                      }}
                    />
                  )}

                  {hover ? (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.weight")}
                      value={hoverCss?.fontWeight}
                      defaultValue={finalDefaultHoverCss?.fontWeight}
                      isValueChanged={hoverCss?.fontWeight && hoverCss?.fontWeight !== finalDefaultHoverCss?.fontWeight}
                      selectOptions={weightOptions}
                      handleChange={(weight) => {
                        onCssChanged("fontWeight", weight, true);
                      }}
                    />
                  ) : (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.weight")}
                      value={css?.fontWeight}
                      defaultValue={finalDefaultCss?.fontWeight}
                      isValueChanged={css?.fontWeight && css?.fontWeight !== finalDefaultCss?.fontWeight}
                      selectOptions={weightOptions}
                      handleChange={(weight) => {
                        onCssChanged("fontWeight", weight, true);
                      }}
                    />
                  )}

                  {hover ? (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.font_style")}
                      value={hoverCss?.fontStyle}
                      defaultValue={finalDefaultHoverCss?.fontStyle}
                      isValueChanged={hoverCss?.fontStyle && hoverCss?.fontStyle !== finalDefaultHoverCss?.fontStyle}
                      selectOptions={fontStyleOptions}
                      handleChange={(fontStyle) => {
                        onCssChanged("fontStyle", fontStyle, true);
                      }}
                    />
                  ) : (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.font_style")}
                      value={css?.fontStyle}
                      defaultValue={finalDefaultCss?.fontStyle}
                      isValueChanged={css?.fontStyle && css?.fontStyle !== finalDefaultCss?.fontStyle}
                      selectOptions={fontStyleOptions}
                      handleChange={(fontStyle) => {
                        onCssChanged("fontStyle", fontStyle, true);
                      }}
                    />
                  )}

                  {hover ? (
                    <SelectRow
                      id={"textTransform-hover"}
                      rowTitle={I18n.t("website.design_tool.letter_case")}
                      value={hoverCss?.textTransform}
                      defaultValue={finalDefaultHoverCss?.textTransform}
                      isValueChanged={
                        hoverCss?.textTransform && hoverCss?.textTransform !== finalDefaultHoverCss?.textTransform
                      }
                      selectOptions={letterCaseOptions}
                      handleChange={(letterCase) => {
                        onCssChanged("textTransform", letterCase, true);
                      }}
                    />
                  ) : (
                    <SelectRow
                      id={"textTransform"}
                      rowTitle={I18n.t("website.design_tool.letter_case")}
                      value={css?.textTransform}
                      defaultValue={finalDefaultCss?.textTransform}
                      isValueChanged={css?.textTransform && css?.textTransform !== finalDefaultCss?.textTransform}
                      selectOptions={letterCaseOptions}
                      handleChange={(letterCase) => {
                        onCssChanged("textTransform", letterCase, true);
                      }}
                    />
                  )}

                  {hover ? (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.text_decoration")}
                      value={hoverCss?.textDecoration}
                      defaultValue={finalDefaultHoverCss?.textDecoration}
                      isValueChanged={
                        hoverCss?.textDecoration && hoverCss?.textDecoration !== finalDefaultHoverCss?.textDecoration
                      }
                      selectOptions={textDecorationOptions}
                      handleChange={(textDecoration) => {
                        onCssChanged("textDecoration", textDecoration, true);
                      }}
                    />
                  ) : (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.text_decoration")}
                      value={css?.textDecoration}
                      defaultValue={finalDefaultCss?.textDecoration}
                      isValueChanged={css?.textDecoration && css?.textDecoration !== finalDefaultCss?.textDecoration}
                      selectOptions={textDecorationOptions}
                      handleChange={(textDecoration) => {
                        onCssChanged("textDecoration", textDecoration, true);
                      }}
                    />
                  )}

                  {hover ? (
                    <InputRow
                      rowTitle={I18n.t("website.design_tool.radius")}
                      value={hoverCss?.borderRadius}
                      defaultValue={finalDefaultHoverCss?.borderRadius}
                      isValueChanged={
                        hoverCss?.borderRadius && hoverCss?.borderRadius !== finalDefaultHoverCss?.borderRadius
                      }
                      handleChange={(borderRadius) => {
                        onCssChanged("borderRadius", borderRadius, true);
                      }}
                    />
                  ) : (
                    <InputRow
                      rowTitle={I18n.t("website.design_tool.radius")}
                      value={css?.borderRadius}
                      defaultValue={finalDefaultCss?.borderRadius}
                      isValueChanged={css?.borderRadius && css?.borderRadius !== finalDefaultCss?.borderRadius}
                      handleChange={(borderRadius) => {
                        onCssChanged("borderRadius", borderRadius, true);
                      }}
                    />
                  )}

                  {hover ? (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.border_style")}
                      value={hoverCss?.borderStyle}
                      defaultValue={finalDefaultHoverCss?.borderStyle}
                      selectOptions={borderStyleOptions}
                      isValueChanged={
                        hoverCss?.borderStyle && hoverCss?.borderStyle !== finalDefaultHoverCss?.borderStyle
                      }
                      handleChange={(borderStyle) => {
                        onCssChanged("borderStyle", borderStyle, true);
                      }}
                    />
                  ) : (
                    <SelectRow
                      rowTitle={I18n.t("website.design_tool.border_style")}
                      value={css?.borderStyle}
                      defaultValue={finalDefaultCss?.borderStyle}
                      selectOptions={borderStyleOptions}
                      isValueChanged={css?.borderStyle && css?.borderStyle !== finalDefaultCss?.borderStyle}
                      handleChange={(borderStyle) => {
                        onCssChanged("borderStyle", borderStyle, true);
                      }}
                    />
                  )}

                  {hover ? (
                    <InputRow
                      rowTitle={I18n.t("website.design_tool.border_width")}
                      value={hoverCss?.borderWidth}
                      defaultValue={finalDefaultHoverCss?.borderWidth}
                      isValueChanged={
                        hoverCss?.borderWidth && hoverCss?.borderWidth !== finalDefaultHoverCss?.borderWidth
                      }
                      handleChange={(borderWidth) => {
                        onCssChanged("borderWidth", borderWidth, true);
                      }}
                    />
                  ) : (
                    <InputRow
                      rowTitle={I18n.t("website.design_tool.border_width")}
                      value={css?.borderWidth}
                      defaultValue={finalDefaultCss?.borderWidth}
                      isValueChanged={css?.borderWidth && css?.borderWidth !== finalDefaultCss?.borderWidth}
                      handleChange={(borderWidth) => {
                        onCssChanged("borderWidth", borderWidth, true);
                      }}
                    />
                  )}

                  {hover ? (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.border_color")}
                      value={hoverCss?.borderColor}
                      defaultValue={finalDefaultHoverCss?.borderColor || theme.palette.primary.dark}
                      isValueChanged={
                        hoverCss?.borderColor && hoverCss?.borderColor !== finalDefaultHoverCss?.borderColor
                      }
                      handleChange={(borderColor) => {
                        onCssChanged("borderColor", borderColor, true);
                      }}
                    />
                  ) : (
                    <ColorPickerRow
                      rowTitle={I18n.t("website.design_tool.border_color")}
                      value={css?.borderColor}
                      defaultValue={finalDefaultCss?.borderColor || theme.palette.text.primary}
                      isValueChanged={css?.borderColor && css?.borderColor !== finalDefaultCss?.borderColor}
                      handleChange={(borderColor) => {
                        onCssChanged("borderColor", borderColor, true);
                      }}
                    />
                  )}
                </div>
              </Paper>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TagDesignToolWithPreview);
