import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 36,
    height: 35,
  },
}));

type PropTypes = {
  className?: string;
};

const ButtonBlueIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 36 35"} fill="none">
      <rect x="0.168335" width="35" height="35" rx="3" fill="#0298C8" />
      <path
        d="M13.9057 22.1601C13.8751 22.6127 13.4698 22.9502 13.0255 22.859C11.1551 22.4753 9.56381 21.5884 8.25148 20.1982C6.75049 18.6083 6 16.7187 6 14.5294C6 12.1674 6.86683 10.1554 8.60049 8.4932C10.3342 6.83107 12.4328 6 14.8965 6C17.1799 6 19.1508 6.71407 20.8092 8.14221C22.2401 9.37438 23.1598 10.8711 23.5684 12.6324C23.6761 13.0967 23.3281 13.5278 22.8533 13.5697C22.6107 13.5911 22.3708 13.5057 22.1963 13.3358L22.0575 13.2006C21.8901 13.0378 21.774 12.8307 21.7063 12.6072C21.2885 11.2271 20.4956 10.0838 19.3276 9.17722C18.0639 8.19633 16.5868 7.70589 14.8965 7.70589C12.9393 7.70589 11.2638 8.37403 9.86997 9.71031C8.47619 11.0466 7.7793 12.653 7.7793 14.5294C7.7793 16.15 8.29655 17.5716 9.33107 18.7942C10.2989 19.9379 11.566 20.7298 13.0179 21.1297C13.215 21.184 13.3984 21.2814 13.546 21.4229L13.664 21.5361C13.833 21.6981 13.9215 21.9266 13.9057 22.1601ZM20.166 21.3104L19.2421 24.0103C19.1166 24.3427 18.8805 24.5073 18.5338 24.504C18.187 24.5007 17.9624 24.3329 17.8597 24.0005L15.2831 15.7695C15.2101 15.5224 15.2786 15.2982 15.4885 15.097C15.6983 14.8958 15.9321 14.8302 16.1899 14.9001L24.775 17.3704C25.1218 17.4688 25.2968 17.6843 25.3003 18.0167C25.3037 18.3491 25.132 18.5755 24.7853 18.6958L21.9692 19.5815L29.6202 26.884C29.8734 27.1268 30 27.4188 30 27.7599C30 28.1011 29.8734 28.3931 29.6202 28.6359C29.367 28.8786 29.0607 29 28.7014 29C28.3422 29 28.0359 28.8786 27.7827 28.6359L20.166 21.3104Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default ButtonBlueIcon;
