import * as React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import { colors } from "../../../../../../helpers/constants";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    padding: 15,
    height: 55,
    marginBottom: 2,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    borderRadius: 3,
    borderWidth: 1,
    backgroundColor: "#EEF2F9",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      cursor: ({ onClick }: PropTypes) => (onClick ? "pointer" : "default"),
      backgroundColor: ({ onClick }: PropTypes) =>
        onClick ? colors.widgetOnHoverBackgroundColor : palette.secondary.dark,
    },
  },
}));

type PropTypes = {
  children?: JSX.Element;
  customClass?: string;
  onClick?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const GenericOptionContainer = (props: PropTypes) => {
  const { onClick } = props;
  const { children, customClass } = props;
  const classes = useStyles(props);

  return (
    <div className={ClassNames(classes.root, customClass)} onClick={onClick && onClick} {...props}>
      {children}
    </div>
  );
};

export default GenericOptionContainer;
