import React, { useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { useParams, useLocation } from "react-router";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import { replace } from "connected-react-router";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import useReport from "../Dashboard/components/useReport";
import CfProgressBar from "../../../components/CfProgressBar";
import OrganicTrafficChart from "../Dashboard/components/charts/OrganicTrafficChart";
import IndexedQueriesChart from "../Dashboard/components/charts/IndexedQueriesChart";
import DelivrablesChart from "../Dashboard/components/charts/DelivrablesChart";
import ImpressionsChart from "../Dashboard/components/charts/ImpressionsChart";
import ReportsTable from "./components/ReportsTable";
import ReportsCTATable from "./components/ReportsCTATable";
import CTAClicksChart from "../Dashboard/components/charts/CTAClicksChart";
import ReportsHeader from "./components/ReportsHeader";
import PageEventsReport from "./PageEventsReport/PageEventsReport";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";
import TimePeriodFilter, { options as timePeriodOptions } from "./components/filters/TimePeriodFilter";
import TimeFrameFilter, { options as timeFrameOptions } from "./components/filters/TimeFrameFilter";
import PageSourceFilter, { options as pageSourceOptions } from "./components/filters/PageSourceFilter";
import TotalUniqueFilter, { options as totalUniqueOptions } from "./components/filters/TotalUniqueFilter";
import ReportTypeFilter, { options as pageTypeOptions } from "./components/filters/ReportTypeFilter";
import ColumnFilter from "./components/filters/ColumnFIlter";
import EventsFilter from "./components/filters/EventsFilter";
import BreakdownFilter from "./components/filters/BreakdownFilter";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: "#F5F6F8",
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  reportWrapper: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    width: "100%",
    marginBottom: 20,
  },
  innerWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 65px)",
    marginBottom: 20,
  },
  reportsDashboardWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    margin: 20,
    position: "relative",
    height: "100%",
  },
  pagesHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 25px",
    backgroundColor: theme.palette.common.white,
  },
  emptyText: {
    padding: "20px",
  },
  icon: {
    width: "18px",
    height: "18px",
  },
  iconBtn: {
    padding: 0,
    width: "24px",
    height: "24px",
  },
  reportTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  pageHeaderTitle: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  pageEndSection: {
    display: "flex",
    gap: 15,
    alignItems: "center",
  },
  reportTableWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: 38,
    overflowY: "auto",
  },
  graphWrapper: {
    display: "flex",
    paddingLeft: 20,
    flexDirection: "column",
    rowGap: 38,
  },
  graphTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    marginTop: 18,
  },
  reportsHeader: {
    padding: 15,
    display: "flex",
    justifyContent: "space-between",
  },
  tableWrapper: {
    marginTop: 10,
    paddingLeft: "15px",
  },
  progressWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerReportPicker: {
    display: "flex",
    gap: 15,
    alignItems: "center",
  },
  headerReportTimeWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  returnTo?: string;
  returnToPreviousScreen: (url: string) => void;
  hideHeader?: boolean;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  returnToPreviousScreen: (returnTo) => dispatch(replace(returnTo)),
});
const Reports = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, returnTo, returnToPreviousScreen, hideHeader = false } = props;

  const { reportType } = useParams();

  const location = useLocation();

  const [selectedTimePeriod, setSelectedTimePeriod] = React.useState(timePeriodOptions[2]);
  const [selectedTimeFrame, setSelectedTimeFrame] = React.useState(timeFrameOptions[2]);
  const [selectedReport, setSelectedReport] = React.useState(null);
  const [selectedUrl, setSelectedUrl] = React.useState(null);
  const [selectedPageOptions, setSelectedPageOptions] = React.useState(pageSourceOptions[0]);
  const [selectedEvents, setSelectedEvents] = React.useState([]);
  const [selectedBreakdown, setSelectedBreakdown] = React.useState({
    label: "URL",
    value: {
      field: "url",
      table: "events",
      eventTable: "e",
    },
  });
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(100);
  const [selectedCountFilter, setSelectedCountFilter] = React.useState(totalUniqueOptions[0]);

  useEffect(() => {
    try {
      const query = new URLSearchParams(location.search);
      const queryPage = query.get("traffic");
      if (reportType === "page-events" && queryPage) {
        setSelectedUrl({ value: queryPage, label: queryPage });
      }
      if (queryPage === "entail_pages") {
        setSelectedPageOptions(pageSourceOptions[1]);
      }
    } catch (error) {}
  }, [location]);

  useEffect(() => {
    if (reportType) {
      setSelectedReport(pageTypeOptions.find((option) => option.value === reportType));
      return;
    }
    setSelectedReport(pageTypeOptions[0]);
  }, [reportType]);

  const startDate = React.useMemo(() => {
    return moment().subtract(selectedTimePeriod.value, "month").toDate();
  }, [selectedTimePeriod]);

  const extraParams = React.useMemo(
    () => ({ pageFilter: selectedWebsite.configurations.siteDetails.urlPrefix }),
    [selectedWebsite]
  );

  const pageEventsExtraParams = React.useMemo(
    () => ({
      pageUrl: selectedUrl?.value,
      timeFrame: selectedTimeFrame.value,
      events: selectedEvents.map((event) => event.value).join(","),
      column: selectedBreakdown.value.field,
      page,
      table: selectedBreakdown.value.eventTable,
      limit,
      countFilter: selectedCountFilter.value,
    }),
    [selectedUrl, selectedTimeFrame, selectedEvents, selectedBreakdown, page, limit, selectedCountFilter]
  );

  const getExtraParams = () => {
    if (selectedReport?.value === "page-events") {
      return pageEventsExtraParams;
    }
    if (selectedPageOptions?.value === "entail_pages") {
      return extraParams;
    }
    return null;
  };

  const [categories, setCategories] = React.useState([]);
  const [wordCountData, setData] = React.useState([]);

  const getData = (results, categoriesNames) => {
    const groupedByDateArray = Object.entries(_.groupBy(results, (obj) => obj.date));
    return groupedByDateArray.map((item) => {
      let dayInfo = { date: item[0] };
      categoriesNames.map((name) => {
        let total = 0;
        item[1].map((subItem) => {
          if (name === subItem.category.name) {
            total = subItem.total_words;
          }
          return null;
        });
        dayInfo = { ...dayInfo, [`${name}`]: total };
        return null;
      });
      return dayInfo;
    });
  };
  const getCategoriesNames = (results) =>
    _.uniq(results.reduce((accum, currentVal) => [...accum, currentVal.category.name], []));

  const {
    loading,
    fetchResponse,
    dateFormat,
    fullDateFormat,
    numberFormat,
    titlesFormat,
    monthFormat,
    triggerRefresh,
    setTriggerRefresh,
  } = useReport({
    selectedWebsite,
    startDate,
    reportType: selectedReport?.reportType,
    extraParams: getExtraParams(),
  });

  const { data, cacheData, totalPages, totals } = React.useMemo(() => {
    if (selectedReport?.reportType === "words-count") {
      const categoriesNames = getCategoriesNames(fetchResponse.categoriesWordCount);
      setCategories(categoriesNames);
      setData(getData(fetchResponse.categoriesWordCount, categoriesNames));
      return {
        data: [],
      };
    }

    if (selectedReport?.reportType === "article-count") {
      const categoriesNames = getCategoriesNames(fetchResponse.categoriesArticleCount);
      setCategories(categoriesNames);
      setData(getData(fetchResponse.categoriesArticleCount, categoriesNames));
      return {
        data: [],
      };
    }

    if (fetchResponse) {
      return {
        data: fetchResponse.results,
        totalPages: fetchResponse.totalPages,
        totals: fetchResponse.totals,
        cacheData: {
          cache: fetchResponse.cache,
          lastFetch: fetchResponse.fetchedTime,
        },
      };
    }
    return {
      data: [],
    };
  }, [fetchResponse]);

  const dataIsEmpty = () => {
    if (selectedReport?.reportType === "words-count" || selectedReport?.reportType === "article-count") {
      return wordCountData?.length === 0;
    }
    return data?.length === 0;
  };

  const renderReport = () => {
    if (selectedReport?.value === "page-events") {
      return (
        <PageEventsReport
          website={selectedWebsite}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
        />
      );
    }

    return (
      <div className={classes.innerWrapper}>
        <div className={classes.reportsDashboardWrapper}>
          <div className={classes.reportsHeader}>
            <div className={classes.headerReportPicker}>
              <ReportTypeFilter value={selectedReport} handleOnChange={setSelectedReport} />
              <PageSourceFilter value={selectedPageOptions} handleOnChange={setSelectedPageOptions} />
            </div>
            <div className={classes.headerReportTimeWrapper}>
              <TimePeriodFilter value={selectedTimePeriod} handleOnChange={setSelectedTimePeriod} />
            </div>
          </div>
          <Divider />
          <>
            {loading ? (
              <div className={classes.progressWrapper}>
                <CfProgressBar />
              </div>
            ) : dataIsEmpty() ? (
              <div className={classes.emptyText}>{I18n.t("reports.dashboard.nothing_to_show")}</div>
            ) : (
              <>
                {selectedReport?.tabular && !selectedReport?.pivot && (
                  <div className={classes.reportTableWrapper}>
                    <ReportsTable selectedWebsite={selectedWebsite} data={data} reportType={selectedReport?.value} />
                  </div>
                )}
                {selectedReport?.tabular && selectedReport?.pivot && (
                  <div className={classes.reportTableWrapper}>
                    <ReportsCTATable
                      selectedWebsite={selectedWebsite}
                      startDate={startDate}
                      data={data}
                      reportType={selectedReport?.value}
                    />
                  </div>
                )}
                {selectedReport?.value === "search-console" && (
                  <div className={classes.graphWrapper}>
                    <Typography className={classes.graphTitle}>{selectedReport?.label}</Typography>
                    <OrganicTrafficChart
                      data={data}
                      numberFormat={numberFormat}
                      height={500}
                      fullDateFormat={fullDateFormat}
                      titlesFormat={titlesFormat}
                      timeFormat={monthFormat}
                    />
                  </div>
                )}
                {selectedReport?.value === "indexed-queries" && (
                  <div className={classes.graphWrapper}>
                    <Typography className={classes.graphTitle}>{selectedReport?.label}</Typography>
                    <IndexedQueriesChart
                      data={data}
                      height={500}
                      timeFormat={monthFormat}
                      numberFormat={numberFormat}
                      fullDateFormat={fullDateFormat}
                      titlesFormat={titlesFormat}
                    />
                  </div>
                )}
                {selectedReport?.value === "words-count" && (
                  <div className={classes.graphWrapper}>
                    <Typography className={classes.graphTitle}>{selectedReport?.label}</Typography>
                    <DelivrablesChart
                      data={wordCountData}
                      dateFormat={dateFormat}
                      numberFormat={numberFormat}
                      fullDateFormat={fullDateFormat}
                      height={500}
                      categories={categories}
                    />
                  </div>
                )}
                {selectedReport?.value === "article-count" && (
                  <div className={classes.graphWrapper}>
                    <Typography className={classes.graphTitle}>{selectedReport?.label}</Typography>
                    <DelivrablesChart
                      data={wordCountData}
                      dateFormat={dateFormat}
                      numberFormat={numberFormat}
                      fullDateFormat={fullDateFormat}
                      height={500}
                      categories={categories}
                    />
                  </div>
                )}
                {selectedReport?.value === "impressions" && (
                  <div className={classes.graphWrapper}>
                    <Typography className={classes.graphTitle}>{selectedReport?.label}</Typography>
                    <ImpressionsChart
                      data={data}
                      timeFormat={monthFormat}
                      numberFormat={numberFormat}
                      fullDateFormat={fullDateFormat}
                      height={500}
                      titlesFormat={titlesFormat}
                    />
                  </div>
                )}
                {selectedReport?.value === "cta-clicks" && (
                  <div className={classes.graphWrapper}>
                    <Typography className={classes.graphTitle}>{selectedReport?.label}</Typography>
                    <CTAClicksChart
                      data={data}
                      timeFormat={monthFormat}
                      numberFormat={numberFormat}
                      fullDateFormat={fullDateFormat}
                      height={500}
                      titlesFormat={titlesFormat}
                    />
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    );
  };
  if (hideHeader) {
    return (
      <div className={classes.reportWrapper}>
        <MHeaderMetaTags title={I18n.t(`meta_tags.cro.reports.title`)} />
        {renderReport()}
      </div>
    );
  }

  console.log("new one 1");
  return (
    <div className={classes.wrapper}>
      <ReportsHeader
        fetchTime={cacheData?.lastFetch}
        returnToPreviousScreen={() => {
          if (returnTo) {
            returnToPreviousScreen(returnTo);
          }
        }}
      />
      {renderReport()}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
