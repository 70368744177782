import * as React from "react";
import { I18n } from "react-redux-i18n";

import { IExpert, IWebsite } from "../../../../../reducers/constants/objectTypes";
import AddNewButton from "./AddNewButton";
import useDebounce from "../../../../../hooks/useDebounce";
import ExpertCollapsibleCard from "./ExpertCollapsibleCard";
import ExperienceItem from "./ExperienceItem";

type PropTypes = {
  selectedWebsite: IWebsite;
  expert: IExpert;

  handleExpertChange: (expert: IExpert) => void;
};

const emptyExperience = {
  companyPic: null,
  companyName: "",
  positionTitle: "",
  startYear: null,
  startMonth: null,
  endYear: null,
  endMonth: null,
};

const PreviousExperience = (props: PropTypes) => {
  const { selectedWebsite, expert } = props;
  const { handleExpertChange } = props;

  const [experience, setExperience] = React.useState<IExpert["previousExperience"]>(expert?.previousExperience || []);

  const debouncedExperience = useDebounce(experience, 500);

  const isItemFilled = (item: IExpert["previousExperience"][number]) => {
    const { companyName, positionTitle, startYear, startMonth } = item;
    return companyName && positionTitle && startYear && startMonth;
  };

  React.useEffect(() => {
    if (debouncedExperience.length === 0) {
      handleExpertChange({ ...expert, previousExperience: [] });
      return;
    }

    handleExpertChange({
      ...expert,
      previousExperience: debouncedExperience.filter(isItemFilled),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedExperience]);

  const handleItemChange = (item: IExpert["previousExperience"][number], index: number) => {
    if (index === experience.length) {
      setExperience([...experience, item]);
    } else {
      setExperience(experience.map((e, i) => (i === index ? item : e)));
    }
  };

  const handleDeleteClick = (index: number) => {
    setExperience(experience.filter((_, i) => i !== index));
  };

  return (
    <ExpertCollapsibleCard
      title={I18n.t("experts.profile.professional_experience")}
      bottomComponent={
        <AddNewButton
          onClick={() =>
            setExperience([
              ...experience,
              ...(experience.length === 0 ? [emptyExperience, emptyExperience] : [emptyExperience]),
            ])
          }
        >
          {I18n.t("experts.profile.add_experience")}
        </AddNewButton>
      }
    >
      {[...experience, ...(experience.length === 0 ? [emptyExperience] : [])].map((item, index) => (
        <ExperienceItem
          key={index}
          item={item}
          index={index}
          handleItemChange={handleItemChange}
          handleDeleteClick={handleDeleteClick}
          selectedWebsite={selectedWebsite}
        />
      ))}
    </ExpertCollapsibleCard>
  );
};

export default PreviousExperience;
