import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import addImage from "../img/image_placeholder.svg";
import InsertImageDialog from "../containers/Modals/InsertImageDialog/InsertImageDialog";
import { IFile, IWebsite } from "../reducers/constants/objectTypes";
import { getImageCDNUrl } from "../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  previewImagePlaceHolder: {
    backgroundColor: theme.palette.secondary.main,
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    maxHeight: 200,
    backgroundImage: `url(${addImage})`,
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  image: IFile;

  imageWidth?: string | number;
  imageHeight?: string | number;
  marginTop?: number;
  marginBottom?: number;
  rounded?: boolean;
  objectFit?: "cover" | "contain";

  onImageChanged: Function;
};

const ImagePicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const { selectedWebsite, image, objectFit = "cover", rounded = false, imageWidth = null, imageHeight = null } = props;
  const { onImageChanged } = props;

  return (
    <>
      <div className={classes.imageWrapper} onClick={(e) => setOpen(true)}>
        <img
          className={ClassNames(!image?.url && classes.previewImagePlaceHolder)}
          src={
            getImageCDNUrl(image?.cdnUrl || image?.url) ||
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          }
          height={rounded ? imageWidth : imageHeight || "auto"}
          width={imageWidth || "100%"}
          style={{
            borderRadius: rounded ? "50%" : 5,
            objectFit,
            cursor: "pointer",
          }}
          alt={image?.alt || ""}
        />
      </div>

      {open && (
        <InsertImageDialog
          website={selectedWebsite}
          selectedImageFile={image || null}
          handleInsertClick={(file: IFile) => {
            onImageChanged(file);
            setOpen(false);
          }}
          handleCloseClick={(e) => setOpen(false)}
        />
      )}
    </>
  );
};

export default React.memo(ImagePicker);
