import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { useTheme } from "@material-ui/core/styles";
import agent from "../../../../agent";
import { KEYWORD_STRATEGY, OUTLINE_PAGES } from "../../../../reducers/constants/actionTypes";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import OutlinePageDialog from "./OutlineDialog/OutlinePageDialog";
import OutlinePageList from "./OutlinePageList";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarKeywordStrategyTypeFilter from "../../../../components/Table/Toolbar/Filters/MTableToolbarKeywordStrategyTypeFilter";
import MTableToolbarBaseRangeSelect from "../../../../components/Table/Toolbar/MTableToolbarBaseRangeSelect";
import MTableToolbarMultipleTextFieldInput from "../../../../components/Table/Toolbar/MTableToolbarMultipleTextFieldInput";
import MTablePagination from "../../../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";
import MTableToolbarOutlineStateFilter from "../../../../components/Table/Toolbar/Filters/MTableToolbarOutlineStateFilter";
import MTableToolbarKeywordStrategyStatusFilter from "../../../../components/Table/Toolbar/Filters/MTableToolbarKeywordStrategyStatusFilter";
import CreateNewArticleDialog from "../../../Main/components/CreateNewArticleDialog";
import MappingCreateNewArticleDialog from "./MappingCreateNewArticleDialog";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  isDialogOpen: state.keywordStrategy.isDialogOpen,
  activeDialogTab: state.keywordStrategy.activeDialogTab,
  searchTextFilter: state.keywordStrategy.filter.search,
  postTypesFilter: state.keywordStrategy.filter.post_types,
  sortField: state.keywordStrategy.filter.sortField,
  total: state.keywordStrategy.total,
  limit: state.keywordStrategy.limit,
  page: state.keywordStrategy.page,
  statusFilter: state.keywordStrategy.filter.status,
  refetch: state.keywordStrategy.refetch,
  postPagesFilter: state.keywordStrategy?.filter?.pages,
  postClicksFilter: state.keywordStrategy?.filter?.clicks,
  postPositionFilter: state.keywordStrategy?.filter?.position,
  postVolumeFilter: state.keywordStrategy?.filter?.volume,
  exclude: state.keywordStrategy?.filter?.exclude,
  include: state.keywordStrategy?.filter?.include,
  selectedDataItemsIndexes: state.keywordStrategy.selectedDataItemsIndexes,
  data: state.keywordStrategy.dataList,
});

const mapDispatchToProps = (dispatch) => ({
  loading() {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_LOADING, payload: true });
  },
  changeLoadedData(payload) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_LOADED, payload });
  },
  changeIsDialogOpen(open: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_IS_DIALOG_OPEN_CHANGED, payload: open });
  },
  changeActiveDialogTab(tab: "outlines" | "keywords" | "broadKeywords" | "pages") {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_ACTIVE_DIALOG_TAB_CHANGED, payload: tab });
  },
  changePage(page: number) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_PAGE_CHANGED, payload: page });
  },
  changeLimit(limit: number) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_LIMIT_CHANGED, payload: limit });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
  changePageFilter: (page) =>
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_PAGE_FILTER_CHANGED, payload: page }),
  onFilterChanged: (payload) => dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_FILTER_CHANGED, payload }),
  changeClicksFilter: (clicks) =>
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_CLICKS_FILTER_CHANGED, payload: clicks }),
  changePositionFilter: (position) =>
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_POSITION_FILTER_CHANGED, payload: position }),
  changeVolumeFilter: (volume) =>
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_VOLUME_FILTER_CHANGED, payload: volume }),
  changePostTypeFilter: (postType) =>
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_POST_TYPE_FILTER_CHANGED, payload: postType }),
  changeSearchTextFilter: (searchText) =>
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SEARCH_TEXT_FILTER_CHANGED, payload: searchText }),
  changeExcludeFilter: (exclude) =>
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_EXCLUDE_FILTER_CHANGED, payload: exclude }),
  changeIncludeFilter: (include) =>
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_INCLUDE_FILTER_CHANGED, payload: include }),
  // old
  emptyKeywords: () => dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGES_KEYWORDS_EMPTY }),
  changeSort(sort: { field: string; direction: "desc" | "asc" }) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SORT_CHANGED, payload: sort });
  },
  changeLoading(loading: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_LOADING, payload: loading });
  },
  changeSerpsLoaded(payload) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SERPS_LOADED, payload });
  },
  changeRefetch(refresh: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_REFETCH_CHANGED, payload: refresh });
  },
});

type PropTypes = {
  changePostTypeFilter: (postTypes: string[]) => void;
  changeSearchTextFilter: (searchText: string) => void;
  selectedWebsite: IWebsite;
  searchTextFilter: string | null;
  total: number;
  limit: number;
  page: number;
  isDialogOpen: boolean;
  activeDialogTab: "outlines" | "keywords" | "pages";
  postTypesFilter: string[];
  sortField: { field: string; direction: "desc" | "asc" };
  statusFilter: string[];
  refetch: boolean;
  postVolumeFilter: number[];
  postPagesFilter: number[];
  postClicksFilter: number[];
  postPositionFilter: number[];
  data: any[];
  loading: () => void;
  changeLoadedData: (payload: any) => void;
  changeIsDialogOpen: (open: boolean) => void;
  changeActiveDialogTab: (tab: "outlines" | "keywords" | "pages") => void;
  changePageFilter: (pages: number[]) => void;
  changeClicksFilter: (clicks: number[]) => void;
  changeVolumeFilter: (volume: number[]) => void;
  changePositionFilter: (position: number[]) => void;
  changePage: (page: number) => void;
  changeLimit: (limit: number) => void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  selectedDataItemsIndexes: number[];
  changeExcludeFilter: (exclude: string[]) => void;
  changeIncludeFilter: (include: string[]) => void;
  exclude: string[];
  include: string[];
  changeSort: (sort: { field: string; direction: "desc" | "asc" }) => void;
  changeSerpsLoaded: (payload: any) => void;
  onFilterChanged: (payload) => void;
  emptyKeywords: () => void;
  changeLoading(loading: boolean): void;
  changeRefetch(refresh: boolean): void;
};

const Mapping = (props: PropTypes) => {
  const { enqueueSnackbar } = useSnackbar();

  const [contentDialogOpen, setContentDialogOpen] = useState(false);
  const theme = useTheme();
  const {
    changeIsDialogOpen,
    changeActiveDialogTab,
    changeLoadedData,
    changeLimit,
    changePage,
    loading,
    changePostTypeFilter,
    changeSearchTextFilter,
    changeSelectedDataItemsIndexes,
    searchTextFilter,
    isDialogOpen,
    activeDialogTab,
    limit,
    total,
    page,
    postTypesFilter,
    sortField,
    refetch,
    selectedWebsite,
    changeClicksFilter,
    changePageFilter,
    statusFilter,
    changeVolumeFilter,
    postPagesFilter,
    changeSerpsLoaded,
    postClicksFilter,
    postVolumeFilter,
    changeExcludeFilter,
    changePositionFilter,
    changeIncludeFilter,
    exclude,
    changeSort,
    include,
    onFilterChanged,
    selectedDataItemsIndexes,
    changeLoading,
    changeRefetch,
    data,
    postPositionFilter,
  } = props;

  const fetchData = async () => {
    const sort = JSON.stringify({
      [sortField.field]: sortField.direction === "asc" ? "1" : "-1",
    });
    const filter = postTypesFilter.length > 0 ? JSON.stringify({ conversion_potential: postTypesFilter }) : undefined;

    const excludeFilters = exclude.length > 0 ? exclude.join("|") : undefined;
    const cleanedStatusFilter =
      statusFilter.length === 0
        ? JSON.stringify(["new", "draft", "review", "published", "approved", "pending", "rejected"])
        : JSON.stringify(statusFilter);

    const includeFilters = include.length > 0 ? include.join("|") : undefined;

    try {
      return await agent.KeywordStrategy.getKeywordStrategies(
        selectedWebsite._id,
        filter,
        limit,
        page,
        sort,
        searchTextFilter,
        cleanedStatusFilter,
        undefined,
        undefined,
        excludeFilters,
        includeFilters,
        postPagesFilter?.[0],
        postPagesFilter?.[1],
        postClicksFilter?.[0],
        postClicksFilter?.[1],
        postVolumeFilter?.[0],
        postVolumeFilter?.[1],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        postPositionFilter?.[0],
        postPositionFilter?.[1]
      );
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    changeLimit(100);
    changePage(1);
    changePostTypeFilter([]);
    changeVolumeFilter([]);
    changeClicksFilter([]);
    changePageFilter([]);
    changeSearchTextFilter("");
    changeSelectedDataItemsIndexes([]);
    changeExcludeFilter([]);
    changeSort({ field: "potential_value", direction: "desc" });
    changeIncludeFilter([]);
  }, [selectedWebsite]);

  useEffect(
    () => () => {
      changeLimit(100);
      changePage(1);
      changePostTypeFilter([]);
      changeVolumeFilter([]);
      onFilterChanged({ status: [] });
      changeClicksFilter([]);
      changePositionFilter([]);
      changePageFilter([]);
      changeSearchTextFilter("");
      changeSelectedDataItemsIndexes([]);
      changeExcludeFilter([]);
      changeSort({ field: "potential_value", direction: "desc" });
      changeIncludeFilter([]);
    },
    []
  );

  const [actionsLoading, setActionsLoading] = useState(false);

  useEffect(() => {
    loading();
    (async () => {
      changeLoadedData((await fetchData()) ?? []);
    })();
  }, [
    selectedWebsite,
    searchTextFilter,
    postTypesFilter,
    postPagesFilter,
    postClicksFilter,
    postVolumeFilter,
    postPositionFilter,
    statusFilter,
    sortField,
    exclude,
    include,
    limit,
    page,
    refetch,
  ]);

  const submitForApproveAll = async () => {
    const keywordsData = selectedDataItemsIndexes.map((i) => data[i]);
    const keywords = keywordsData.map((item) => item.keyword);
    try {
      const topicWithNoFunnel = keywordsData.some((keyword) => keyword.conversion_potential === "None");

      if (topicWithNoFunnel) {
        throw new Error("Submission failed. Topic has no funnel stage");
      }
      changeLoading(true);

      await agent.KeywordStrategy.updateAllStatus(selectedWebsite._id, JSON.stringify(keywords), "pending", "true");
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.pending_success"));
      changeRefetch(!refetch);
      changeSelectedDataItemsIndexes([]);
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const createContent = async () => {
    setContentDialogOpen(true);
  };

  const mergeTopics = async () => {
    setActionsLoading(true);
    const keywordsData = selectedDataItemsIndexes.map((i) => data[i]);
    const keywords = keywordsData.map((item) => item.keyword);
    try {
      const { data } = await agent.KeywordStrategy.mergeTopics(selectedWebsite._id, keywords);

      setActionsLoading(false);

      enqueueSnackbar(
        I18n.t("strategy.strategyKeywords.merge_success", {
          mainKeyword: data?.mainKeyword || "",
        })
      );
      changeRefetch(!refetch);
      changeSelectedDataItemsIndexes([]);
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };
  const selectedRowActions = React.useMemo(
    () => [
      {
        onClick: submitForApproveAll,
        text: I18n.t("strategy.outlinePages.transfer_action"),
        tooltip: I18n.t("strategy.outlinePages.describe_transfer_action"),
        color: theme.palette.primary.main,
      },
      {
        onClick: mergeTopics,
        text: I18n.t("strategy.outlinePages.merge_action"),
        tooltip: I18n.t("strategy.outlinePages.describe_merge_action"),
        color: theme.palette.text.primary,
      },
      {
        onClick: createContent,
        text: I18n.t("strategy.outlinePages.create_content"),
        tooltip: I18n.t("strategy.outlinePages.describe_create_content"),
        color: theme.palette.text.primary,
      },
    ],
    [selectedDataItemsIndexes]
  );

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t("meta_tags.strategy.mapping.title")} />
      <MTableToolbar
        actionsLoading={actionsLoading}
        selectedRows={selectedDataItemsIndexes?.length}
        selectedRowActions={selectedRowActions}
        onClearSelectedRows={() => changeSelectedDataItemsIndexes([])}
        searchText={searchTextFilter}
        handleSearchTextChanged={(value) => onFilterChanged({ search: value })}
      >
        <MTableToolbarKeywordStrategyTypeFilter
          postTypes={postTypesFilter}
          handlePostTypesSelected={(value) => onFilterChanged({ post_types: value })}
        />
        <MTableToolbarBaseRangeSelect
          title={I18n.t("strategy.outlinePages.pages_title")}
          placeholder={I18n.t("strategy.outlinePages.page_title")}
          handleChange={(value) => onFilterChanged({ pages: value })}
          rangValues={postPagesFilter}
        />
        <MTableToolbarBaseRangeSelect
          handleChange={(value) => onFilterChanged({ clicks: value })}
          rangValues={postClicksFilter}
          title={I18n.t("strategy.outlinePages.traffic_title")}
          placeholder={I18n.t("strategy.outlinePages.traffic_filter")}
        />
        <MTableToolbarBaseRangeSelect
          handleChange={(value) => onFilterChanged({ volume: value })}
          rangValues={postVolumeFilter}
          title={I18n.t("strategy.outlinePages.volume_title")}
          placeholder={I18n.t("strategy.outlinePages.volume_filter")}
        />
        <MTableToolbarBaseRangeSelect
          handleChange={(value) => onFilterChanged({ position: value })}
          rangValues={postPositionFilter}
          title={I18n.t("strategy.outlinePages.position_title")}
          placeholder={I18n.t("strategy.outlinePages.position_filter")}
        />
        <MTableToolbarMultipleTextFieldInput
          title={I18n.t("strategy.outlinePages.exclude")}
          placeholder={I18n.t("strategy.outlinePages.exclude")}
          selectedLabel={I18n.t("strategy.outlinePages.excluded")}
          handleChange={(value) => onFilterChanged({ exclude: value })}
          selectedOptions={exclude}
        />
        <MTableToolbarMultipleTextFieldInput
          title={I18n.t("strategy.outlinePages.include")}
          placeholder={I18n.t("strategy.outlinePages.include")}
          selectedLabel={I18n.t("strategy.outlinePages.included")}
          handleChange={(value) => onFilterChanged({ include: value })}
          selectedOptions={include}
        />
        <MTableToolbarKeywordStrategyStatusFilter
          options={[
            {
              _id: "new",
              name: "New",
            },
            {
              _id: "rejected",
              name: "Rejected",
            },
            {
              _id: "approved",
              name: "Approved",
            },
            {
              _id: "pending",
              name: "Pending",
            },
          ]}
          status={statusFilter}
          handlePostStatusSelected={(value) => onFilterChanged({ status: value })}
        />
      </MTableToolbar>

      <OutlinePageList sortField={sortField} setSortField={(value) => onFilterChanged({ sortField: value })} />
      {total > 0 && (
        <MTablePagination
          paginationLimit={limit}
          page={page}
          paginationPagesCount={Math.ceil(total / limit)}
          setPaginationLimit={changeLimit}
          setPage={changePage}
        />
      )}
      <OutlinePageDialog
        isStrategy
        open={isDialogOpen}
        close={() => {
          changeIsDialogOpen(false);
          changeActiveDialogTab("keywords");
          changeSerpsLoaded({ data: [] });
        }}
        pageTab={activeDialogTab}
        setPageTab={changeActiveDialogTab}
      />
      <MappingCreateNewArticleDialog
        data={data}
        handleCloseClick={() => setContentDialogOpen(false)}
        open={contentDialogOpen}
        setActionsLoading={setActionsLoading}
      />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapping);
