import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { eActionIcon, IWebsite } from "../../../../reducers/constants/objectTypes";
import { getOpportunityPageUrl, web } from "../../../../helpers/urlHelper";
import { CONVERSION_POTENTIAL_FUNNEL_STAGE } from "../../../../helpers/constants";
import CopyToClipboardIcon from "../../../../icons/CopyToClipboardIcon";
import OpenInEditor from "../../../../icons/OpenInEditor";
import CopyNameIcon from "../../../../icons/CopyNameIcon";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirstWithSelect from "../../../../components/Table/Cells/MTableCellFirstWithSelect";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MTableCellActions from "../../../../components/Table/Cells/MTableCellActions";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    position: "relative",
    "&:hover": {
      "& $actions": {
        display: "flex",
      },
    },
  },
  actions: {
    position: "absolute",
    zIndex: 10,
    left: "30%",
    backgroundColor: theme.palette.secondary.main,
    gap: 15,
    alignItems: "center",
  },
}));

type PropTypes = {
  item: any;
  onClick: (outline: any, pageTab: "keywords" | "broadKeywords") => void;
  redirect: (url: string) => void;
  setSelect(selected: boolean): void;
  selected: boolean;
  selectedWebsite: IWebsite;
  isFirstRow: boolean;
  isLastRow: boolean;
  openPost: (pageId: string, currentUrl?: string) => void;
};

const mapDispatchToProps = (dispatch) => ({
  openPost: (pageId, currentUrl) => dispatch(push(web.editPage(pageId, currentUrl))),
});

const InternalLinkingListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { item, onClick, setSelect, selected, isFirstRow, isLastRow, openPost } = props;

  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return (type) => {
      if (item.page) {
        navigator.clipboard.writeText(type === "page" ? item.page : item.metadata?.title);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();
  const theme = useTheme();

  const actions = React.useMemo(() => {
    const newActions = [];
    if (item.metadata?.title) {
      newActions.push({
        iconName: eActionIcon.copyName,
        tooltip: showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title"),
        tooltipDelay: 200,
        onClick: (e) => handleTitleCopy("title"),
      });
    }
    newActions.push({
      iconName: eActionIcon.copyToClipboard,
      tooltip: showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_url"),
      tooltipDelay: 200,

      onClick: (e) => handleTitleCopy("page"),
    });

    newActions.push({
      iconName: eActionIcon.openInNew,
      tooltip: I18n.t("outline.icons.open_in_new_tab"),
      onClick: (e) => window.open(item.page, "_blank"),
      tooltipDelay: 200,
    });
    if (item.metadata?.entail_page_id) {
      newActions.push({
        iconName: eActionIcon.openInEditor,
        tooltip: I18n.t("outline.icons.open_in_editor"),
        onClick: (e) => openPost(item.metadata?.entail_page_id),
        tooltipDelay: 200,
      });
    }
    return newActions;
  }, [item, showTitleCopyTooltip]);

  return (
    <MTableRow
      handleRowClick={() => onClick(item, "keywords")}
      selected={selected}
      customClassName={classes.itemWrapper}
    >
      <MTableCellFirstWithSelect
        selected={selected}
        onSelect={setSelect}
        avatarType={"A"}
        cellText={item.metadata?.title ? item.metadata?.title : getOpportunityPageUrl(item.page, true)}
        columnWidth={"40%"}
        fontSize={theme.typography.pxToRem(14)}
      />
      <MTableCellNumber value={item.related_pages_count} type={"bigNumber"} />
      <MTableCellText text={_.capitalize(CONVERSION_POTENTIAL_FUNNEL_STAGE[item.conversion_potential] || "None")} />
      <MTableCellNumber value={item.topics} type={"bigNumber"} />
      <MTableCellNumber value={item.kws} type={"bigNumber"} />
      <MTableCellNumber value={item.position} type={"bigNumber"} />
      <MTableCellNumber value={item.clicks} type={"bigNumber"} />
      <MTableCellActions actions={actions} customClass={classes.actions} />
    </MTableRow>
  );
};

export default connect(null, mapDispatchToProps)(InternalLinkingListItem);
