import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 36,
    height: 35,
  },
}));

type PropTypes = {
  className?: string;
};

const ColumnsIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 36 35"} fill="none">
      <rect x="0.5" width="35" height="35" rx="3" fill="#00BF8F" />
      <path
        d="M8.76922 26.2493H13.8413V8.74935H8.76922C8.54486 8.74935 8.36057 8.83348 8.21634 9.00175C8.07211 9.17002 8 9.38502 8 9.64677V25.3519C8 25.6137 8.07211 25.8287 8.21634 25.9969C8.36057 26.1652 8.54486 26.2493 8.76922 26.2493ZM15.0913 26.2493H20.9087V8.74935H15.0913V26.2493ZM22.1587 26.2493H27.2308C27.4551 26.2493 27.6394 26.1652 27.7837 25.9969C27.9279 25.8287 28 25.6137 28 25.3519V9.64677C28 9.38502 27.9279 9.17002 27.7837 9.00175C27.6394 8.83348 27.4551 8.74935 27.2308 8.74935H22.1587V26.2493ZM8.76922 27.7077C8.19391 27.7077 7.71354 27.4829 7.32812 27.0332C6.94271 26.5835 6.75 26.0231 6.75 25.3519V9.64677C6.75 8.97557 6.94271 8.41515 7.32812 7.96549C7.71354 7.51584 8.19391 7.29102 8.76922 7.29102H27.2308C27.8061 7.29102 28.2865 7.51584 28.6719 7.96549C29.0573 8.41515 29.25 8.97557 29.25 9.64677V25.3519C29.25 26.0231 29.0573 26.5835 28.6719 27.0332C28.2865 27.4829 27.8061 27.7077 27.2308 27.7077H8.76922Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default ColumnsIcon;
