import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { ILearnTopic, ISEOKeyword } from "../../../../../reducers/constants/objectTypes";
import { EDIT_TOPIC } from "../../../../../reducers/constants/actionTypes";
import agent from "../../../../../agent";
import MSelectMultiple from "../../../../../components/MSelectMultiple";

const useStyles = makeStyles((theme: Theme) => ({
  postTagsWrapper: {
    width: "100%",
    borderBottom: `solid 1px ${theme.palette.divider}`,
    paddingBottom: 10,
    marginBottom: 10,
  },
  tagsWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > *": {
      marginTop: 10,
    },
  },
  selectTagsWrapper: {},
  suggestedTagsWrapper: {},
}));

type PropTypes = {
  topic: ILearnTopic;

  keywords: ISEOKeyword[];
  keywordsSearchField: string;

  onKeywordsLoaded: Function;
  onKeywordSelected: Function;
  onKeywordRemoved: Function;
  onKeywordsInputChanged: Function;
  onKeywordCreated: Function;
};

const mapStateToProps = (state) => {
  return {
    topic: state.editTopic.topic,
    keywords: state.editTopic.keywords,
    keywordsSearchField: state.editTopic.keywordsSearchField,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onKeywordsLoaded: (payload) => dispatch({ type: EDIT_TOPIC.ON_KEYWORDS_LOADED, payload }),
  onKeywordsInputChanged: (input) => dispatch({ type: EDIT_TOPIC.ON_KEYWORDS_SEARCH_FIELD_CHANGED, input }),
  onKeywordSelected: (keyword) => dispatch({ type: EDIT_TOPIC.ON_KEYWORD_SELECTED, keyword }),
  onKeywordRemoved: (keyword) => dispatch({ type: EDIT_TOPIC.ON_KEYWORD_REMOVED, keyword }),
  onKeywordCreated: (payload) => dispatch({ type: EDIT_TOPIC.ON_NEW_KEYWORD_CREATED, payload }),
});

const EditTopicInfoTabSEOKeywords = (props: PropTypes) => {
  const classes = useStyles(props);
  const { topic, keywords, keywordsSearchField } = props;
  const { onKeywordsLoaded, onKeywordSelected, onKeywordRemoved, onKeywordsInputChanged, onKeywordCreated } = props;

  React.useEffect(() => {
    onKeywordsLoaded(agent.SEOKeywords.all());
  }, [onKeywordsLoaded]);

  const creatableKeywords =
    keywordsSearchField.length > 0
      ? [...keywords, { _id: "new_kw", name: `create: '${keywordsSearchField}'`, keywordName: keywordsSearchField }]
      : keywords;

  return (
    <MSelectMultiple
      options={creatableKeywords}
      selectedOptions={topic.keywords}
      optionLabel={"name"}
      optionValue={"_id"}
      placeholder={I18n.t("targeted_posts.details.keywords")}
      handleCreateOption={(name) => onKeywordCreated(agent.SEOKeywords.new(name))}
      handleOptionRemoved={(keyword) => onKeywordRemoved(keyword)}
      handleOptionSelected={(keyword) => onKeywordSelected(keyword)}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTopicInfoTabSEOKeywords);
