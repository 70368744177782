import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { COMMUNITY, COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import { IQuestion, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import MSelect from "../../../../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  postTopicWrapper: {
    marginBottom: 20,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fieldLabel: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    marginBottom: 7,
  },
  select: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #E5E5E5",
    borderRadius: 4,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedQuestion: IQuestion;

  handleQuestionChange: Function;
};

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,
    selectedQuestion: state.communityEditor.question,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleQuestionChange: (question) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_QUESTION_CHANGED, question }),
});

const localeNames = {
  en: "English",
  es: "Spanish",
  de: "German",
  hr: "Croatian",
};
const CommunityEditorLocale = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, selectedQuestion, handleQuestionChange } = props;

  if (selectedWebsite.locales.length < 2) {
    return null;
  }

  const locales = selectedWebsite.locales.map((l) => ({ value: l, label: localeNames[l] }));

  return (
    <div className={classes.postTopicWrapper}>
      <Typography color={"textSecondary"} className={classes.fieldLabel} variant={"body2"}>
        {I18n.t("upload_post.post_locale")}
      </Typography>
      <MSelect
        customClass={classes.select}
        options={locales}
        value={_.find(locales, (l) => l.value === selectedQuestion.locale)}
        optionLabel={"label"}
        optionValue={"value"}
        height={37}
        textColor={"#808080"}
        borderColor={"transparent"}
        isBold={false}
        borderRadius={4}
        menuPlacement={"bottom"}
        fullWidth
        searchable
        handleChange={(value) => handleQuestionChange({ ...selectedQuestion, locale: value.value })}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorLocale);
