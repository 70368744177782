import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import MTextButton from "../../../MTextButton";

const useStyles = makeStyles((theme: Theme) => ({
  textButton: {
    minWidth: "unset",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: 0,
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
}));

type PropTypes = {
  url: string;
};

const MTableToolbarExportToCsvButton = (props: PropTypes) => {
  const classes = useStyles(props);
  const { url } = props;

  return (
    <MTextButton
      customClass={classes.textButton}
      onClick={() => {
        window.open(url, "_blank");
      }}
      text={I18n.t("workflow.writer.export_csv")}
    />
  );
};

export default MTableToolbarExportToCsvButton;
