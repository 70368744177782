import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { TextField, Theme } from "@material-ui/core";
import CfButton from "./CfButton";
import { I18n } from "react-redux-i18n";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) => ({
  inputWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: "20px 20px 10px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    rowGap: "20px",
    borderRadius: "5px",
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25);",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  rangeWrapper: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    columnGap: "10px",
    width: "100%",
  },
  clearBtn: {
    fontSize: "14px",
    textTransform: "none",
    paddingLeft: "0px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "transparent",
    },
  },
  clearBtnLabel: {
    justifyContent: "normal",
  },
  inputFieldWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  inputLabel: {
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  submitBtn: {
    fontSize: "14px",
    color: theme.palette.common.white,
    padding: "7px 20px",
    backgroundColor: theme.palette.primary.main,
    minHeight: "35px",
  },
  btnWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

type PropTypes = {
  title: string;
  values: { minValue: number | undefined; maxValue: number | undefined };
  handleMinValueChange: (value: number | undefined) => void;
  handleMaxValueChange: (value: number | undefined) => void;
  handleValueChange: ({ minValue, maxValue }: { minValue: number | undefined; maxValue: number | undefined }) => void;
  handleSubmit: (values?: any) => void;
  submitButtonTitle?: string;
  minLabel?: string;
  maxLabel?: string;
};

function RangeInput(props: { value: number | undefined; handleValueChange: (value: number) => void; label: string }) {
  const classes = useStyles();
  const { handleValueChange, value, label } = props;

  return (
    <div className={classes.inputFieldWrapper}>
      <Typography variant={"subtitle1"} className={classes.inputLabel}>
        {label}
      </Typography>
      <TextField
        value={value ?? ""}
        type={"number"}
        variant={"standard"}
        onChange={(event) => {
          handleValueChange(event.currentTarget.value ? Number(event.currentTarget.value) : undefined);
        }}
      />
    </div>
  );
}

function MRangeInput(props: PropTypes) {
  const classes = useStyles();

  const {
    title,
    handleMinValueChange,
    handleMaxValueChange,
    values: { minValue, maxValue },
    handleSubmit,
    submitButtonTitle,
    maxLabel,
    handleValueChange,
    minLabel,
  } = props;

  return (
    <div className={classes.inputWrapper}>
      <Typography variant={"subtitle1"} className={classes.title}>
        {title}
      </Typography>
      <div className={classes.rangeWrapper}>
        <RangeInput
          value={minValue}
          handleValueChange={handleMinValueChange}
          label={minLabel || I18n.t("strategy.rangeInput.minLabel")}
        />
        <RangeInput
          value={maxValue}
          handleValueChange={handleMaxValueChange}
          label={maxLabel || I18n.t("strategy.rangeInput.maxLabel")}
        />
      </div>
      <div className={classes.btnWrapper}>
        <Button
          onClick={() => {
            handleValueChange({ minValue: undefined, maxValue: undefined });
            handleSubmit([undefined, undefined]);
          }}
          className={classes.clearBtn}
          color={"primary"}
          classes={{
            label: classes.clearBtnLabel,
          }}
        >
          {I18n.t("strategy.multipleInput.clear")}
        </Button>
        <CfButton
          onClick={() => {
            handleSubmit();
          }}
          customClass={classes.submitBtn}
          text={submitButtonTitle || I18n.t("strategy.rangeInput.submitLabel")}
        />
      </div>
    </div>
  );
}

export default MRangeInput;
