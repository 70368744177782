import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Droppable } from "react-beautiful-dnd";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";
import { findIndex, groupBy } from "lodash";
import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ProsConsIcon from "../../../icons/editor/ProsConsIcon";
import EmbedProductIcon from "../../../icons/editor/EmbedProductIcon";
import TableIcon from "../../../icons/editor/TableIcon";
import FAQIcon from "../../../icons/editor/FAQIcon";
import ProductDetailsIcon from "../../../icons/editor/ProductDetailsIcon";
import TextBoxIcon from "../../../icons/editor/TextBoxIcon";
import DividerIcon from "../../../icons/editor/DividerIcon";
import Search from "../../../icons/SearchIcon";
import PageEditorElement from "./PageEditorElement";
import EntailWidgetsIcon from "../../../icons/editor/EntailWidgetsIcon";
import ButtonBlueIcon from "../../../icons/editor/ButtonBlueIcon";
import ComparisonTableIcon from "../../../icons/editor/ComparisonTableIcon";
import ColumnsIcon from "../../../icons/editor/ColumnsIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: "20px 25px",
    },
    widgetsWrapper: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      columnGap: "2px",
      rowGap: "2px",
    },
    widgetDragWrapper: {
      cursor: "grab",
      height: 84,
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "calc(33% - 1px)",
      // maxWidth: '49%',
      backgroundColor: theme.palette.background.paper,
      textAlign: "center",
      "&:hover": {
        "& $widgetIcon": {
          fill: theme.palette.primary.main,
        },
        "& $caption": {
          color: theme.palette.primary.main,
        },
      },
    },
    widgetDragWrapperClone: {
      "& + div": {
        display: "none !important",
      },
    },
    caption: {
      fontWeight: theme.typography.fontWeightRegular as any,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: "14px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      paddingTop: 10,
    },
    searchField: {
      width: "100%",
      margin: "20px 0",
      padding: 10,
    },
    searchTextFieldFocused: {
      borderBottom: "none",
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
    },
    searchTextFieldOutlinedInput: {
      height: "40px",
      borderBottom: `solid 1px ${theme.palette.divider}`,
      "&:before": {
        borderBottom: "none !important",
        transition: "none",
      },
      "&:after": {
        borderBottom: "none !important",
        transition: "none",
      },
    },
    input: {
      width: "100%",
      marginRight: 10,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: theme.palette.text.primary,
      padding: "11px 0",
    },
    searchIcon: {
      width: 14,
      height: 14,
      marginRight: 10,
      color: "#9E9E9E",
    },
    accordion: {
      backgroundColor: "inherit",
      boxShadow: "none",
      border: "none",
      margin: "0 0 10px 0 !important",
      "&:before": {
        backgroundColor: "inherit",
      },
      "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 30,
        maxHeight: 30,
      },
    },
    accordionSummary: {
      minHeight: 30,
      maxHeight: 30,
      padding: 0,
    },
    accordionDetails: {
      padding: "0 0 0 0",
    },
    editorWidgetList: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
    },
    listHeaderTitle: {
      fontSize: 14,
      textTransform: "capitalize",
      marginBottom: 5,
    },
    accordionIcon: {},
  })
);

type PropTypes = {};
export const editorWidgetList = [
  {
    type: "entail-widget",
    nameKey: "rich_text_editor.custom_components.entail_widget",
    name: I18n.t("rich_text_editor.custom_components.entail_widget"),
    group: "Widgets",
    icon: EntailWidgetsIcon,
  },
  {
    type: "product-embed",
    nameKey: "rich_text_editor.custom_components.product_embed",
    name: I18n.t("rich_text_editor.custom_components.product_embed"),
    group: "Products",
    icon: EmbedProductIcon,
  },
  // {
  //   type: "product-cta",
  //   nameKey: "rich_text_editor.custom_components.product_cta",
  //   name: I18n.t("rich_text_editor.custom_components.product_cta"),
  //   group: "Product",
  //   icon: ProductCTAIcon,
  // },
  // {
  //   type: "product-cta-horizontal",
  //   nameKey: "rich_text_editor.custom_components.product_cta_horizontal",
  //   name: I18n.t("rich_text_editor.custom_components.product_cta_horizontal"),
  //   group: "Product",
  //   icon: ProductCTABannerIcon,
  // },
  {
    type: "product-details",
    nameKey: "rich_text_editor.custom_components.product_details",
    name: I18n.t("rich_text_editor.custom_components.product_details"),
    group: "Products",
    icon: ProductDetailsIcon,
  },
  // {
  //   type: editorType.productReview,
  //   nameKey: "rich_text_editor.custom_components.product_review",
  //   name: "rich_text_editor.custom_components.product_review",
  //   icon: ProductIcon,
  //   group: "Product",
  // },
  // {
  //   type: "comparison-table",
  //   nameKey: "rich_text_editor.custom_components.comparison_table",
  //   name: I18n.t("rich_text_editor.custom_components.comparison_table"),
  //   group: "Lists",
  //   icon: ComparisonIcon,
  // },
  // {
  //   type: "pricing-table",
  //   nameKey: "rich_text_editor.custom_components.pricing_table",
  //   name: I18n.t("rich_text_editor.custom_components.pricing_table"),
  //   group: "Lists",
  //   icon: TableIcon,
  // },
  // {
  //   type: "faq",
  //   nameKey: "rich_text_editor.custom_components.faq",
  //   name: I18n.t("rich_text_editor.custom_components.faq"),
  //   group: "Lists",
  //   icon: FAQIcon,
  // },
  {
    type: "text-box",
    nameKey: "rich_text_editor.custom_components.text_box",
    name: I18n.t("rich_text_editor.custom_components.text_box"),
    group: "Layout",
    icon: TextBoxIcon,
  },
  {
    type: "pros-cons-table",
    nameKey: "rich_text_editor.custom_components.pros_cons_table",
    name: I18n.t("rich_text_editor.custom_components.pros_cons_table"),
    group: "Lists & tables",
    icon: ProsConsIcon,
  },
  // {
  //   type: "product-cta",
  //   nameKey: "rich_text_editor.custom_components.product_cta",
  //   name: I18n.t("rich_text_editor.custom_components.product_cta"),
  //   group: "Widgets",
  //   icon: ProductCTAIcon,
  // },
  // {
  //   type: "product-cta-horizontal",
  //   nameKey: "rich_text_editor.custom_components.product_cta_horizontal",
  //   name: I18n.t("rich_text_editor.custom_components.product_cta_horizontal"),
  //   group: "Widgets",
  //   icon: ProductCTABannerIcon,
  // },
  // {
  //   type: "product-review-widget",
  //   nameKey: "rich_text_editor.custom_components.product_review",
  //   name: I18n.t("rich_text_editor.custom_components.product_review"),
  //   group: "Widgets",
  //   icon: EmbedProductIcon,
  // },
  {
    type: "comparison-table",
    nameKey: "rich_text_editor.custom_components.comparison_table",
    name: I18n.t("rich_text_editor.custom_components.comparison_table"),
    group: "Lists & tables",
    icon: ComparisonTableIcon,
  },
  {
    type: "pricing-table",
    nameKey: "rich_text_editor.custom_components.pricing_table",
    name: I18n.t("rich_text_editor.custom_components.pricing_table"),
    group: "Lists & tables",
    icon: TableIcon,
  },
  {
    type: "faq",
    nameKey: "rich_text_editor.custom_components.faq",
    name: I18n.t("rich_text_editor.custom_components.faq"),
    group: "Lists & tables",
    icon: FAQIcon,
  },
  // {
  //   type: editorType.productReview,
  //   nameKey: "rich_text_editor.custom_components.product_review",
  //   name: "rich_text_editor.custom_components.product_review",
  //   icon: ProductIcon,
  //   group: "Widgets",
  // },
  {
    type: "layouts",
    nameKey: "rich_text_editor.custom_components.columns",
    name: I18n.t("rich_text_editor.custom_components.columns"),
    group: "Layout",
    icon: ColumnsIcon,
  },
  {
    type: "divider",
    nameKey: "rich_text_editor.custom_components.divider",
    name: I18n.t("rich_text_editor.custom_components.divider"),
    group: "Layout",
    icon: DividerIcon,
  },
  {
    type: "button",
    nameKey: "rich_text_editor.custom_components.button",
    name: I18n.t("rich_text_editor.custom_components.button"),
    group: "Products",
    icon: ButtonBlueIcon,
  },
  // {
  //   type: "charticle-top-products",
  //   nameKey: "rich_text_editor.custom_components.charticle_top_products",
  //   name: I18n.t("rich_text_editor.custom_components.charticle_top_products"),
  //   group: "Products",
  //   icon: TopProductsIcon,
  // },
];

const PageEditorElements = (props: PropTypes) => {
  const classes = useStyles(props);
  const [searchText, setSearchText] = useState<string>("");
  const elements = groupBy(
    editorWidgetList.filter((x) => x.name.toLowerCase().includes(searchText.toLowerCase())),
    "group"
  );
  return (
    <Droppable droppableId={"widgets-drawer-list"} isDropDisabled={true}>
      {(provided, snapshot) => (
        <>
          <TextField
            id="outlined-search"
            placeholder={I18n.t("rich_text_editor.search_elements_ph")}
            type="search"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            className={classes.searchField}
            margin="normal"
            variant={"standard"}
            autoFocus={false}
            InputLabelProps={{
              shrink: true,
              classes: {
                focused: classes.searchTextFieldFocused,
              },
            }}
            InputProps={{
              classes: {
                input: classes.input,
                root: classes.searchTextFieldOutlinedInput,
                focused: classes.searchTextFieldFocused,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Search className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.editorWidgetList} ref={provided.innerRef} {...provided.droppableProps}>
            {Object.keys(elements).map((key, index) => (
              <div>
                {/* <Accordion defaultExpanded elevation={0} className={classes.accordion}>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<AccordionArrowIcon className={classes.accordionIcon} />}
                >
                  <Typography className={classes.listHeaderTitle} variant={"body1"}>
                    {I18n.t(`rich_text_editor.${key}`)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <div className={classNames(classes.widgetsWrapper)}>
                    {elements[key].map((widget, i) => (
                      <PageEditorElement
                        key={`${widget.type}_${index}`}
                        widget={widget}
                        index={findIndex(editorWidgetList, { type: widget.type })}
                      />
                    ))}
                     {provided.placeholder}
                  </div>
                </AccordionDetails>
              </Accordion> */}

                <Typography className={classes.listHeaderTitle} variant={"body1"}>
                  {I18n.t(`rich_text_editor.${key}`)}
                </Typography>
                <div className={classNames(classes.widgetsWrapper)}>
                  {elements[key].map((widget, i) => (
                    <PageEditorElement
                      key={`${widget.type}_${index}`}
                      widget={widget}
                      index={findIndex(editorWidgetList, { type: widget.type })}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </Droppable>
  );
};

export default PageEditorElements;
