import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import ArrowRightRoundedIcon from "../../../../../../icons/ArrowRightRoundedIcon";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: {
      //   backgroundColor: palette.grey["100"],
      cursor: "pointer",
      display: "flex",
      color: "white",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      height: "auto",
    },
    icon: {
      marginRight: -15,
    },
    img: {
      height: 50,
      width: 50,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold as any,
      fontSize: 14,
    },
    description: {
      fontSize: 12,
      color: palette.text.secondary,
    },
    texts: {
      marginLeft: -45,
    },
  };
});

type PropTypes = {
  item?: any;
  onClick?: (event: any) => void;
};

const IntegrationTargetingItem = (props: PropTypes) => {
  const { onClick = null, item } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.root} onClick={() => onClick(item)}>
      <img className={classes.img} src={item?.img} />

      <div className={classes.texts}>
        <Typography className={classes.title}>{item?.title}</Typography>
        <Typography className={classes.description}>{item?.description}</Typography>
      </div>

      <ArrowRightRoundedIcon className={classes.icon} />
    </div>
  );
};

export default IntegrationTargetingItem;
