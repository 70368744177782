import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { push } from "connected-react-router";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ClassNames from "classnames";
import { useRouteMatch } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { web } from "../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.common.white,
    maxWidth: 317,
    width: "100%",
    borderRight: `${theme.palette.divider} solid 1px`,
    borderBottom: `${theme.palette.divider} solid 1px`,
    zIndex: 1,
  },
  list: {
    display: "inline",
    justifyContent: "space-around",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    borderBottom: `${theme.palette.divider} solid 1px`,
    "&:hover": {
      transition: "500ms",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  groupList: {
    position: "relative",
    padding: 0,
  },
  itemPrimaryText: {
    color: theme.palette.text.primary,
    lineHeight: 2,
  },
  itemSelected: {
    color: theme.palette.primary.main,
  },
  chevronRightIcon: {
    fontSize: 20,
  },
}));

type PropTypes = {
  handleSectionClick: (page) => void;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  handleSectionClick: (page) => dispatch(push(web.dataSettings(page.type))),
});

const pagesList = [
  { type: "job-management" },
  { type: "classify-types" },
  { type: "serp-stats" },
  { type: "value-config" },
];

const DataSettingsSideBar = (props: PropTypes) => {
  const classes = useStyles();
  const { handleSectionClick } = props;

  const pageTypeMatch = useRouteMatch({
    path: web.dataSettings(":pageType"),
    strict: true,
    sensitive: true,
  });

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <List component="nav" className={classes.groupList}>
          {pagesList && pagesList.length > 0 ? (
            pagesList.map((page) => (
              <ListItem key={page.type} className={classes.listItem} onClick={(event) => handleSectionClick(page)}>
                <ListItemText
                  primary={I18n.t(`strategy.data_settings_page_types.${page.type}`)}
                  primaryTypographyProps={{
                    variant: "subtitle2",
                    className: ClassNames(
                      classes.itemPrimaryText,
                      page.type === pageTypeMatch?.params?.pageType && classes.itemSelected
                    ),
                  }}
                />
                <ChevronRightIcon className={classes.chevronRightIcon} />
              </ListItem>
            ))
          ) : (
            <ListItem button>
              <ListItemText primary={I18n.t("strategy.settings.nothing_to_show")} />
            </ListItem>
          )}
        </List>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSettingsSideBar);
