import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    height: "20px",
    width: "20px",
    fill: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  className?: string;
};

function TableToggleHeaderIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 24 24"}>
      <path d="M1 6v13a2 2 0 0 0 2 2h6V11h12V6a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2m2 0h4v3H3m4 10H3v-3h4m0-2H3v-3h4m2-2V6h4v3m6 0h-4V6h4m-2 10a1 1 0 1 1-1 1a1 1 0 0 1 1-1m0-3a6.45 6.45 0 0 1 6 4a6.5 6.5 0 0 1-12 0a6.45 6.45 0 0 1 6-4m0 1.5a2.5 2.5 0 1 0 2.5 2.5a2.5 2.5 0 0 0-2.5-2.5" />
    </SvgIcon>
  );
}

export default TableToggleHeaderIcon;
