import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import { INTERNAL_LINKING } from "../../../../reducers/constants/actionTypes";
import agent from "../../../../agent";
import InternalLinkingListItem from "./InternalLinkingListItem";
import InternalLinkingColumns from "./InternalLinkingColumns";
import MTable from "../../../../components/Table/Table/MTable";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  loading: state.internalLinking.dataLoading,
  data: state.internalLinking.dataList,
  selectedDataItemsIndexes: state.internalLinking.selectedDataItemsIndexes,
  refetch: state.internalLinking.refetch,
  total: state.internalLinking.total,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoading(loading: boolean) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_DATA_LOADING, payload: loading });
  },
  changeIsDialogOpen(open: boolean) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_IS_DIALOG_OPEN_CHANGED, payload: open });
  },
  changeActiveDialogTab(tab: "outlines" | "keywords" | "broadKeywords" | "pages") {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_ACTIVE_DIALOG_TAB_CHANGED, payload: tab });
  },
  changeSort(sort: { field: string; direction: "desc" | "asc" }) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_SORT_CHANGED, payload: sort });
  },
  changeRefetch(refresh: boolean) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_REFETCH_CHANGED, payload: refresh });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
  changeSelectedItem(item: any) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_SELECTED_DATA_ITEM_CHANGED, payload: item });
  },
  changeRelatedPagesLoaded(payload: any) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_RELATED_PAGES_LOADED, payload });
  },
  redirect: (url) => dispatch(push(url)),
});

type PropTypes = {
  loading: boolean;
  data: any[];
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  selectedDataItemsIndexes: number[];
  refetch: boolean;
  selectedWebsite: IWebsite;

  changeLoading(loading: boolean): void;
  changeRelatedPagesLoaded(payload: any): void;
  changeSelectedItem(item: any): void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  changeSort(sort: { field: string; direction: "desc" | "asc" }): void;
  changeIsDialogOpen: (open: boolean) => void;
  changeActiveDialogTab: (tab: "outlines" | "keywords" | "pages") => void;
  changeRefetch: (refresh: boolean) => void;
  setSortField: (input: { direction: "desc" | "asc" | undefined; field: string }) => void;
  redirect: (url: string) => void;
  total: number;
};

const InternalLinkingList = (props: PropTypes) => {
  const {
    selectedWebsite,
    changeIsDialogOpen,
    changeSelectedDataItemsIndexes,
    loading,
    selectedDataItemsIndexes,
    data,
    redirect,
    sortField,
    total,
    changeSelectedItem,
    changeRelatedPagesLoaded,
    setSortField,
  } = props;

  const openDialog = async (page: any) => {
    changeSelectedItem(page);
    changeRelatedPagesLoaded(await agent.KeywordGroupPage.getRelatedPages(selectedWebsite._id, page.page));
    changeIsDialogOpen(true);
  };

  return (
    <MTable>
      <InternalLinkingColumns
        total={total}
        selectAll={() => {
          changeSelectedDataItemsIndexes(data.map((item, index) => index));
        }}
        clearSelected={() => {
          changeSelectedDataItemsIndexes([]);
        }}
        selectedNr={selectedDataItemsIndexes.length}
        sortField={sortField}
        setSortField={setSortField}
      />
      {!loading && data?.length < 1 && <MTableRowEmptyRow />}

      {loading && <MTableRowProgressBar />}

      {!loading &&
        data?.length > 0 &&
        data.map((item, index) => (
          <InternalLinkingListItem
            isFirstRow={index === 0}
            isLastRow={index === data.length - 1}
            selectedWebsite={selectedWebsite}
            redirect={redirect}
            selected={selectedDataItemsIndexes.includes(index)}
            setSelect={(selected) => {
              if (selected) {
                changeSelectedDataItemsIndexes([...selectedDataItemsIndexes, index]);
              } else {
                changeSelectedDataItemsIndexes(selectedDataItemsIndexes.filter((i) => i !== index));
              }
            }}
            item={item}
            key={index}
            onClick={() => {
              openDialog(item);
            }}
          />
        ))}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalLinkingList);
