import React from "react";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";
import { AUTOOUTLINE_STATES } from "../../../../helpers/constants";

type PropTypes = {
  state: { _id: string; name: string }[];
  handleOutlineStateSelected: (pageState: { _id: string; name: string }[]) => void;
};

const MTableToolbarOutlineStateFilter = (props: PropTypes) => {
  const { state, handleOutlineStateSelected } = props;

  const stateTypes = AUTOOUTLINE_STATES.map((value) => ({
    _id: value,
    name: _.capitalize(value),
  }));
  return (
    <MTableToolbarBaseSelect
      multipleOptions
      options={stateTypes}
      selectedOptions={state}
      placeholder={I18n.t("strategy.outlinePages.state_filter")}
      title={I18n.t("strategy.outlinePages.states")}
      handleChange={handleOutlineStateSelected}
    />
  );
};

export default MTableToolbarOutlineStateFilter;
