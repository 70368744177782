import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview/web";
import SinglePageTableRow from "./SinglePageTableRow";
import SinglePageTableHeader from "./SinglePageTableHeader";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: 20,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 10,
  },
  table: {
    width: "100%",
    flex: 1,
    minHeight: 1,
    minWidth: 1,
    display: "flex",
    flexDirection: "column",
    border: `solid 1px ${theme.palette.divider}`,
  },
  date: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    marginBottom: 20,
  },
  titles: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
    marginBottom: 20,
    visibility: "hidden",
  },
  range: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
}));

type PropTypes = {
  posts: any[];
  hiddenAreas: any;
  handleHiddenAreasChange: Function;
};

const SinglePageTable = (props: PropTypes) => {
  const classes = useStyles(props);
  const { posts, hiddenAreas, handleHiddenAreasChange } = props;
  const recyclerViewRef = React.useRef(null);
  const tableRef = React.useRef(null);

  const width = tableRef?.current?.offsetWidth || 0;

  console.log("width", width);

  const layoutProvider: any = new LayoutProvider(
    (index) => {
      return "ROW";
    },
    (type, dim) => {
      console.log("dim", width);
      dim.width = width;
      dim.height = 50;
    }
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.table} style={{ height: 600 }} ref={tableRef}>
        <SinglePageTableHeader hiddenAreas={hiddenAreas} handleHiddenAreasChange={handleHiddenAreasChange} />
        <RecyclerListView
          ref={recyclerViewRef}
          canChangeSize
          initialOffset={0}
          initialRenderIndex={0}
          forceNonDeterministicRendering
          dataProvider={new DataProvider((r1, r2) => {
            return r1 !== r2;
          }).cloneWithRows(posts)}
          layoutProvider={layoutProvider}
          rowRenderer={(type, data, index) => <SinglePageTableRow index={index} post={data} />}
        />
      </div>
    </div>
  );
};

export default SinglePageTable;
