import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PageSection from "../../components/PageSection";
import { IWebsiteArticleProps, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import TextDesignToolWithPreview from "../../components/TextDesignToolWithPreview";
import QuoteDesignToolWithPreview from "../../components/QuoteDesignToolWithPreview";
import CodeDesignToolWithPreview from "../../components/CodeDesignToolWithPreview";
import ListDesignToolWithPreview from "../../components/ListDesignToolWithPreview";
import LinkDesignToolWithPreview from "../../components/LinkDesignToolWithPreview";
import MRouterPrompt from "../../components/MRouterPrompt";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  innerWrapper: {
    marginRight: "-30px",
    marginLeft: "-30px",
    marginTop: "-30px",
  },
  elementWrap: {
    borderBottom: `${theme.palette.divider} solid 1px`,
    marginTop: "-10px",
    marginBottom: 20,
    padding: "0px 30px 5px 30px",
  },
}));

const mapStateToProps = (state) => ({
  article: state.websitePages.article,
  theme: state.websitePages.theme,
  initialArticle: state.websitePages.initialArticle,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (article) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "article",
      value: article,
    }),
});

type PropTypes = {
  theme: IWebsiteThemeProps;
  initialArticle: IWebsiteArticleProps;
  article: IWebsiteArticleProps;

  onPropertyChanged: (article) => void;
};

const Article = (props: PropTypes) => {
  const classes = useStyles(props);
  const { article, theme, initialArticle, onPropertyChanged } = props;

  return (
    <PageSection sectionTitle={I18n.t("website.article_design")}>
      <MRouterPrompt />
      <div className={classes.innerWrapper}>
        <TextDesignToolWithPreview
          elementTitle={I18n.t("website.H2_title")}
          css={article.h2?.css}
          defaultCss={{ ...theme.typography.h2?.css, ...initialArticle.h2.css }}
          variant={"h2"}
          exampleText={I18n.t("website.H2_example")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...article, h2: { ...article.h2, css: newCss } });
          }}
        />

        <TextDesignToolWithPreview
          elementTitle={I18n.t("website.H3_title")}
          css={article.h3?.css}
          defaultCss={{ ...theme.typography.h3?.css, ...initialArticle.h3.css }}
          variant={"h3"}
          exampleText={I18n.t("website.H3_example")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...article, h3: { ...article.h3, css: newCss } });
          }}
        />

        <TextDesignToolWithPreview
          elementTitle={I18n.t("website.H4_title")}
          css={article.h4?.css}
          defaultCss={{ ...theme.typography.h4?.css, ...initialArticle.h4.css }}
          variant={"h4"}
          exampleText={I18n.t("website.H4_example")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...article, h4: { ...article.h4, css: newCss } });
          }}
        />

        <TextDesignToolWithPreview
          elementTitle={I18n.t("website.H5_title")}
          css={article.h5?.css}
          defaultCss={{ ...theme.typography.h5?.css, ...initialArticle.h5.css }}
          variant={"h5"}
          exampleText={I18n.t("website.H5_example")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...article, h5: { ...article.h5, css: newCss } });
          }}
        />

        <TextDesignToolWithPreview
          elementTitle={I18n.t("website.H6_title")}
          css={article.h6?.css}
          defaultCss={{ ...theme.typography.h6?.css, ...initialArticle.h6.css }}
          variant={"h6"}
          exampleText={I18n.t("website.H6_example")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...article, h6: { ...article.h6, css: newCss } });
          }}
        />

        <TextDesignToolWithPreview
          elementTitle={I18n.t("website.paragraphs")}
          css={article.paragraphs?.css}
          defaultCss={{ ...theme.typography.h6?.css, ...initialArticle.paragraphs.css }}
          variant={"h6"}
          exampleText={I18n.t("website.paragraph_example")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...article, paragraphs: { ...article.paragraphs, css: newCss } });
          }}
        />

        <LinkDesignToolWithPreview
          elementTitle={I18n.t("website.links")}
          elementCss={article.links?.css}
          defaultElementCss={{ ...theme.links?.css, ...initialArticle.links.css }}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...article, links: { css: newCss } });
          }}
        />

        <QuoteDesignToolWithPreview
          elementTitle={I18n.t("website.quotes")}
          css={article.quotes?.css}
          defaultCss={initialArticle.quotes.css}
          exampleText={I18n.t("website.quotes_example")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...article, quotes: { ...article.quotes, css: newCss } });
          }}
        />

        <CodeDesignToolWithPreview
          elementTitle={I18n.t("website.code")}
          css={article.code?.css}
          defaultCss={initialArticle.code?.css}
          exampleText={I18n.t("website.code_example")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...article, code: { ...article.code, css: newCss } });
          }}
        />
        <Divider />

        <ListDesignToolWithPreview
          elementTitle={I18n.t("website.ordered_list")}
          css={article.orderedLists?.css}
          defaultCss={initialArticle.orderedLists?.css}
          listType={"ordered-list"}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...article,
              orderedLists: { ...article.orderedLists, css: newCss },
            });
          }}
        />
        <Divider />
        <ListDesignToolWithPreview
          elementTitle={I18n.t("website.unordered_list")}
          css={article.unorderedLists?.css}
          defaultCss={initialArticle.unorderedLists?.css}
          listType={"unordered-list"}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...article,
              unorderedLists: { ...article.unorderedLists, css: newCss },
            });
          }}
        />
      </div>
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);
