import React, { ChangeEvent, FunctionComponent, useRef } from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import theme from "../../../../themes/theme";

const useStyles = makeStyles(() => ({
  label: {
    display: "flex",
    alignItems: "center",
    padding: "6.5px 20px",
    borderRadius: 3,
    fontWeight: theme.typography.fontWeightLight as any,
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  input: {
    display: "none",
  },
}));

interface FileUploadProps {
  handleOnSelect: (e: FileList | null) => void;
}
const availableTypes = [".amr", ".flac", ".wav", ".ogg", ".mp3", ".mp4", ".webm"];
const FileUpload: FunctionComponent<FileUploadProps> = ({ handleOnSelect }) => {
  const classes = useStyles();

  return (
    <label className={classes.label}>
      <input
        className={classes.input}
        type="file"
        accept={availableTypes.join(",")}
        onChange={(e: ChangeEvent) => handleOnSelect((e?.target as HTMLInputElement)?.files)}
      />
      {I18n.t("source_file_uploader_dialog.choose_file")}
    </label>
  );
};

export default FileUpload;
