import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MTextField from "../../../../../components/MTextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import classNames from "classnames";
import { CheckBox } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) => ({
  briefRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  switchLabel: {
    fontWeight: theme.typography.fontWeightRegular as any,
    marginLeft: 5,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  switchWrapper: {
    display: "flex",
    padding: "5px 0px",
    margin: 0,
    alignItems: "center",
    gap: 18,
    "& .MuiTypography-body1": {
      lineHeight: "normal",
      fontSize: theme.typography.pxToRem(14),
      margin: 0,
    },
  },
  switch: {
    "& .MuiSwitch-thumb": {
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      width: 35,
      height: 12,
    },
  },
  switchDisabled: {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#B2B7C2",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#CCCFD6",
    },
  },
  selectedCheckBox: {
    color: `${theme.palette.primary.main} !important`,
  },
}));

type PropTypes = {
  label: string;
  value: any;
  handleOnChange: (val: any) => void;
  checkBox?: boolean;
};

const ACFSwitch = (props: PropTypes) => {
  const classes = useStyles(props);
  const { label, value, checkBox = false, handleOnChange } = props;

  return (
    <div className={classes.briefRow}>
      <FormControlLabel
        className={classes.switchWrapper}
        labelPlacement={"start"}
        control={
          checkBox ? (
            <Checkbox
              onChange={(e) => handleOnChange(e.target.checked)}
              value={value}
              className={classNames(classes.switch, value && classes.selectedCheckBox)}
            />
          ) : (
            <Switch
              className={classNames(classes.switch, !value ? classes.switchDisabled : "")}
              checked={value}
              onChange={(e) => handleOnChange(e.target.checked)}
              name="toc"
              size={"small"}
              inputProps={{ "aria-label": "Activate Brief" }}
              color="primary"
            />
          )
        }
        label={
          <Typography className={classes.switchLabel} variant="body1">
            {label}
          </Typography>
        }
      />
    </div>
  );
};

export default ACFSwitch;
