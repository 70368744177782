// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { ChatBubble } from "@material-ui/icons";

import { useVideoEditorContext } from "../VideoEditorContext";

const useStyles = makeStyles(() => ({
  commentsPanelWrapper: {
    backgroundColor: "#20222B",
    width: "100%",
    borderBottom: "1px solid #000",
    overflow: "hidden",
  },
  commentsPanel: {
    height: 35,
    position: "relative",
  },
  chatIcon: {
    color: "#0298C8",
    width: 14,
    height: 14,
    position: "absolute",
    cursor: "pointer",
  },
}));

const CommentsPanel = () => {
  const { jumpToTime, focusComment, selectedTimeline, commentActions } = useVideoEditorContext();

  const classes = useStyles();

  const totalVideoLength = useMemo(
    () => selectedTimeline.rows.find((row) => row.type === "video").actions.slice(-1)[0]?.end || 0,
    [selectedTimeline.rows]
  );

  const comments = useMemo(
    () => commentActions.filter((a) => a.start < totalVideoLength),
    [commentActions, totalVideoLength]
  );

  const getLeft = (start: number) => {
    const left = (start / totalVideoLength) * 100;
    if (left < 1) {
      return "0%";
    }
    if (left > 99) {
      return `calc(100% - 15px)`;
    }
    return `calc(${(start / totalVideoLength) * 100}% - 8px)`;
  };

  return (
    <div className={classes.commentsPanelWrapper}>
      <div className={classes.commentsPanel}>
        {comments.map((commentAction, index) => (
          <ChatBubble
            key={index}
            className={classes.chatIcon}
            style={{
              left: getLeft(commentAction.start),
              top: 10,
            }}
            onClick={() => {
              jumpToTime(commentAction.start);
              focusComment(commentAction.comment._id);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CommentsPanel;
