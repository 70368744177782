import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { web } from "../../../helpers/urlHelper";
import SiteDetails from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/DeveloperTools/SiteDetails";
import Analytics from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/DeveloperTools/Analytics";
import ThemeBreakPoints from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/Theme/ThemeBreakPoints";
import SiteDetailsCMS from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/DeveloperTools/SiteDetailsCMS";
import SiteDetailsLocale from "../../WebsiteConfigurations/components/SiteDetailsLocale";
import SiteLinks from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/DeveloperTools/SiteLinks";
import PageCustomCode from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageCustomCode";
import SiteDetailsGSC from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/DeveloperTools/SiteDetailsGSC";
import PageCacheInvalidation from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/PageCacheInvalidation";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const DeveloperTools = () => (
  <div>
    <MHeaderMetaTags title={I18n.t(`meta_tags.page_builder.developer_tools.title`)} />
    <Switch>
      <Route exact={false} path={web.developerTools("site-details")} component={SiteDetails} />
      <Route exact={false} path={web.developerTools("cms")} component={SiteDetailsCMS} />
      <Route exact={false} path={web.developerTools("locale")} component={SiteDetailsLocale} />
      <Route exact={false} path={web.developerTools("links")} component={SiteLinks} />
      <Route exact={false} path={web.developerTools("break-points")} component={ThemeBreakPoints} />
      <Route exact={false} path={web.developerTools("analytics")} component={Analytics} />
      <Route exact={false} path={web.developerTools("custom-code")} component={PageCustomCode} />
      <Route exact={false} path={web.developerTools("gsc")} component={SiteDetailsGSC} />
      <Route exact={false} path={web.developerTools("cache-invalidation")} component={PageCacheInvalidation} />

      <Redirect exact from={"*"} to={web.developerTools("site-details")} />
    </Switch>
  </div>
);

export default DeveloperTools;
