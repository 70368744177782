import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { ePageType, ePostType, IAdminUser } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    display: "block",
    padding: 0,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: "white",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      background: theme.palette.secondary.main,
      transition: "500ms",
      "& $editIcon": {
        visibility: "unset",
        width: "unset",
        marginRight: 28,
      },
    },
  },
  rowWrapper: {
    display: "flex",
    flexFlow: "row wrap",
    transition: "0.5s",
    paddingTop: 20,
    paddingBottom: 20,
  },
  firstColumn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    width: "20%",
  },
  column: {
    flexGrow: 1,
    flexBasis: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  nameWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  editIcon: {
    fontSize: theme.typography.pxToRem(16),
    cursor: "pointer",
    marginRight: 0,
    visibility: "hidden",
    width: 0,
    "&:hover": {
      fill: theme.palette.primary.main,
      transition: "500ms",
    },
  },
}));

type PropTypes = {
  user: IAdminUser;
  handleEditUserClick: Function;
};

const formatToLabel = (x) => {
  const splitUpString = x.replace(/([A-Z])/g, " $1").trim();
  if (splitUpString.length === 0) return splitUpString;
  return splitUpString[0].toUpperCase() + splitUpString.substring(1);
};

const UserRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { user, handleEditUserClick } = props;

  const dynamicPageTypes = [];
  user.websites.forEach((website) => {
    website.configurations.dynamicSections.forEach((section) => {
      dynamicPageTypes.push(section.type);
    });
  });

  const staticPageTypes = Object.values(ePageType);

  const getPageTypes = () => {
    if (user.pageTypes.length === dynamicPageTypes.length + staticPageTypes.length + 1) return "All";
    return user.pageTypes?.map((x) => formatToLabel(x)).join(", ") || "None";
  };

  const getPrefix = (name: string) => {
    let prefix = "";
    for (let i = 0; i < name.length; i++) {
      if (name[i] === name[i].toUpperCase()) prefix += name[i];
    }

    if (prefix.length === 0) prefix = name[0].toUpperCase();
    return prefix;
  };

  const getPrice = (key: "pptByType" | "ppwByType") => {
    const dynamicPageTypes = [];
    if (user.websites) {
      user.websites.forEach((website) => {
        website.configurations.dynamicSections.forEach((section) => {
          dynamicPageTypes.push(section.type);
        });
      });
    }

    const staticPageTypes = Object.values(ePageType);

    const prices = [];
    [...staticPageTypes, ...dynamicPageTypes, ePostType.COMMUNITY].forEach((type) => {
      prices.push({ prefix: getPrefix(type), type: user[key][type] || 0 });
    });
    return prices.map((x) => `${x.prefix} - ${x.type}`).join(", ");
  };

  return (
    <ListItem className={classes.itemWrapper}>
      <div className={classes.rowWrapper}>
        <div className={classes.firstColumn}>
          <div className={classes.nameWrapper}>
            {user.name}
            <EditIcon className={classes.editIcon} onClick={(e) => handleEditUserClick(user)} />
          </div>
        </div>
        <div className={classes.column} role="cell">
          {user.lastActiveDate ? moment(user.lastActiveDate).format("DD-MM-YYYY") : "-"}
        </div>
        <div className={classes.column} role="cell">
          {user.username}
        </div>
        <div className={classes.column} role="cell">
          {I18n.t(`manage_content.users.roles.${user.role}`)}
        </div>
        <div className={classes.column} role="cell">
          {user.role === "admin" || user.role === "owner" ? "All" : (user.websites || []).map((w) => w.name).join(", ")}
        </div>
        <div className={classes.column} role="cell">
          {(user.locales || ["None"]).join(", ") || "None"}
        </div>
        {/* <div className={classes.column} role="cell"> */}
        {/*  {getPageTypes()} */}
        {/* </div> */}
        <div className={classes.column} role="cell">
          <Tooltip enterDelay={500} title={"Price Per Word"}>
            <span>{getPrice("ppwByType")}</span>
          </Tooltip>
        </div>
        <div className={classes.column} role="cell">
          <Tooltip enterDelay={500} title={"Price Per Translation"}>
            <span>{getPrice("pptByType")}</span>
          </Tooltip>
        </div>

        <div className={classes.column} role="cell">
          {user.email}
        </div>
      </div>
    </ListItem>
  );
};

export default React.memo(UserRow);
