import "react-hot-loader";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

//const isProduction = process.env.NODE_ENV === "production";

// Sentry.init({
//   dsn: isProduction
//     ? "https://63895079b53d3545d022de356eefba95@o4507029716271104.ingest.us.sentry.io/4507029722759168"
//     : "",
//   environment: process.env.NODE_ENV,
//   // release: `${process.env.APP_NAME}-${process.env.APP_VERSION}`,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [/^https:\/\/app\.entail\.ai\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.render(<App />, document.getElementById("app"));
