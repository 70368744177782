import { THIRD_FACT_CHECK_PAGES } from "./constants/actionTypes";

const initialState = {
  myPages: [],
  takenByOthers: [],
  pending: [],
  paginationPagesCount: 0,
  pagesLoaded: true,
  refreshPages: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case THIRD_FACT_CHECK_PAGES.THIRD_FACT_CHECK_PAGES_LOADED:
      return {
        ...state,
        myPages: action.payload.pages.myReviews,
        takenByOthers: action.payload.pages.takenByOthers,
        pending: action.payload.pages.pending,
        paginationPagesCount: action.payload.paginationPagesCount,
        pagesLoaded: true,
      };
    case THIRD_FACT_CHECK_PAGES.THIRD_FACT_CHECK_PAGES_LOAD_FAILED:
      return {
        ...state,
        pagesLoaded: true,
        draftPages: [],
        takenByOthers: [],
        publicPages: [],
        rejected: [],
      };
    case THIRD_FACT_CHECK_PAGES.THIRD_FACT_CHECK_PAGES_LOADING:
      return {
        ...state,
        pagesLoaded: false,
      };

    case THIRD_FACT_CHECK_PAGES.THIRD_FACT_CHECK_PAGES_REFRESH:
      return {
        ...state,
        refreshPages: !state.refreshPages,
      };

    default:
      return state;
  }
};
