import { INTERNAL_LINKING } from "./constants/actionTypes";

const initialState = {
  dataList: [],
  dataLoading: false,
  selectedDataItem: null,
  selectedDataItemsIndexes: [],
  relatedPages: [],
  relatedKeywords: [],
  total: 0,
  sort: {
    direction: "asc",
    field: "page",
  },
  filter: {
    searchText: "",
    funnel_stages: [],
    sortField: {
      direction: "asc",
      field: "page",
    },
  },
  refresh: false,
  limit: 100,
  page: 1,
  isDialogOpen: false,
  activeDialogTab: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_DATA_LOADED:
      return {
        ...state,
        dataList: action.payload?.data,
        total: action.payload?.pagination?.total,
        dataLoading: false,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_FUNNEL_STAGE_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          funnel_stages: action.payload,
        },
        page: 1,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_DATA_LOADING:
      return {
        ...state,
        dataLoading: true,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_SEARCH_TEXT_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          search: action.payload,
        },
        page: 1,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_IS_DIALOG_OPEN_CHANGED:
      return {
        ...state,
        isDialogOpen: action.payload,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_ACTIVE_DIALOG_TAB_CHANGED:
      return {
        ...state,
        activeDialogTab: action.payload,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_SORT_CHANGED:
      return {
        ...state,
        sort: action.payload,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_SELECTED_DATA_ITEM_CHANGED:
      return {
        ...state,
        selectedDataItem: action.payload,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_DATA_SELECTED_ITEMS_CHANGED:
      return {
        ...state,
        selectedDataItemsIndexes: action.payload,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_REFETCH_CHANGED:
      return {
        ...state,
        refetch: action.payload,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_PAGE_CHANGED:
      return {
        ...state,
        page: action.payload,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_LIMIT_CHANGED:
      return {
        ...state,
        limit: action.payload,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_RELATED_KEYWORDS_CHANGED:
      return {
        ...state,
        relatedKeywords: action.payload,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_RELATED_PAGES_LOADED:
      return {
        ...state,
        relatedPages: action.payload.data,
      };
    case INTERNAL_LINKING.ON_INTERNAL_LINKING_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
