import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { I18n } from "react-redux-i18n";
import { Button, Theme, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import FontColorCSS from "../../../../../../../editor/elementsStyles/CssComponents/FontColorCSS";
import ListBaseComponent from "../../../../../../../editor/elementsStyles/BaseComponents/ListBaseComponent";
import SelectProperty from "../../build/items/itemProperties/components/SelectProperty";
import DesignAppearanceModel from "../models/DesignAppearanceModel";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import theme from "../../../../../../../themes/theme";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    padding: "30px",
  },
  wrapper: {
    backgroundColor: palette.secondary.main,
    borderRadius: "5px",
    padding: "25px 15px 15px 15px",
  },
  headerItems: {
    fontSize: "16px",
    verticalAlign: "middle",
    display: "flex",
    padding: "0px 0px 10px 0px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowBack: {
    display: "flex",
    marginLeft: "-8px",
    cursor: "pointer",
  },
  backIcon: {
    height: "22px",
    color: palette.common.white,
  },
  button: {
    background: palette.primary.light,
    justifySelf: "flex-end",
    height: "27px",
    width: "54px",
    fontSize: "14px",
    fontWeight: typography.fontWeightLight,
    textTransform: "none",
  },
  head: {
    userSelect: "none",
    color: palette.common.white,
    fontSize: "16px",
    lineHeight: "21px",
  },
  body: {
    background: palette.primary.dark,
    width: "100%",
    borderRadius: "5px",
    marginTop: "10px",
    padding: "15px",
  },
  marginTop: {
    marginTop: 10,
  },
}));

type PropTypes = {
  appearance: DesignAppearanceModel;
  goBack: (any) => void;
  editAppearance: (appearance: DesignAppearanceModel) => void;
};

const mapStateToProps = (state) => ({
  appearance: state.widgetBuilder.widget.appearance,
});

const mapDispatchToProps = (dispatch) => ({
  editAppearance: (appearance) => dispatch({ type: WIDGET_BUILDER.DESIGN, key: "appearance", value: appearance }),
});

const GeneralAppearanceDesign = (props: PropTypes) => {
  const classes = useStyles(props);
  const { goBack, appearance, editAppearance } = props;
  const [colorScheme, setColorScheme] = React.useState(appearance?.colorScheme || "Light");
  const [textColor, setTextColor] = React.useState(appearance?.textColor || "white");
  const [typography, setTypography] = React.useState(appearance?.typography || "Montserrat");

  const borderStyleOptions = [
    "Montserrat",
    "font 2",
    "font 3",
    "font 4",
    "font 5",
    "font 6",
    "font 7",
    "font 8",
    "font 9",
  ];

  function handleClick() {
    const body = {
      colorScheme,
      textColor,
      typography,
    };

    editAppearance(body);
    goBack(null);
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.headerItems}>
          <div className={classes.arrowBack} onClick={() => goBack(null)}>
            <KeyboardArrowLeftRounded className={classes.backIcon} />
            <Typography variant={"h6"} className={classes.head}>
              {I18n.t("widgets_builder.design.appearance")}
            </Typography>
          </div>
          <Button
            className={classes.button}
            onClick={() => {
              handleClick();
            }}
          >
            {I18n.t("widgets_builder.design.done")}
          </Button>
        </div>
        <div className={classes.body}>
          <SelectProperty
            label={I18n.t("widgets_builder.design.color_scheme")}
            showLabel
            value={colorScheme}
            options={["Light", "Dark"]}
            onChange={(option) => {
              setColorScheme(option);
            }}
          />

          <div className={classes.marginTop}>
            <FontColorCSS
              title={I18n.t("widgets_builder.design.text_color")}
              value={textColor}
              defaultValue={"white"}
              isValueChanged={true}
              handleChange={(c) => {
                setTextColor(c);
              }}
            />
          </div>

          <ListBaseComponent
            title={I18n.t("widgets_builder.design.typography")}
            defaultValue={"test"}
            value={typography}
            selectOptions={borderStyleOptions}
            isValueChanged={false}
            borderColor="#525252"
            handleChange={(style) => {
              setTypography(style);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralAppearanceDesign);
