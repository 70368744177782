import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import agent from "../../../../agent";
import { INTERNAL_LINKING } from "../../../../reducers/constants/actionTypes";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import InternalLinkingList from "./InternalLinkingList";
import InternalLinkingDialog from "./InternalLinkingDialog/InternalLinkingDialog";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarKeywordStrategyTypeFilter from "../../../../components/Table/Toolbar/Filters/MTableToolbarKeywordStrategyTypeFilter";
import MTablePagination from "../../../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  isDialogOpen: state.internalLinking.isDialogOpen,
  activeDialogTab: state.internalLinking.activeDialogTab,
  searchTextFilter: state.internalLinking.filter.search,
  sortField: state.internalLinking.filter.sortField,
  sortDirection: state.internalLinking.sort.direction,
  total: state.internalLinking.total,
  limit: state.internalLinking.limit,
  page: state.internalLinking.page,
  refetch: state.internalLinking.refetch,
  funnelStageFilter: state.internalLinking?.filter?.funnel_stages,
  selectedDataItemsIndexes: state.internalLinking.selectedDataItemsIndexes,
});

const mapDispatchToProps = (dispatch) => ({
  loading() {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_DATA_LOADING, payload: true });
  },
  changeLoadedData(payload) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_DATA_LOADED, payload });
  },
  changeIsDialogOpen(open: boolean) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_IS_DIALOG_OPEN_CHANGED, payload: open });
  },
  changeActiveDialogTab(tab: "outlines" | "keywords" | "broadKeywords" | "pages") {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_ACTIVE_DIALOG_TAB_CHANGED, payload: tab });
  },
  changePage(page: number) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_PAGE_CHANGED, payload: page });
  },
  changeLimit(limit: number) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_LIMIT_CHANGED, payload: limit });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
  changeSort(sort: { field: string; direction: "desc" | "asc" }) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_SORT_CHANGED, payload: sort });
  },
  changeSearchTextFilter: (searchText) =>
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_SEARCH_TEXT_FILTER_CHANGED, payload: searchText }),
  changeFunnelStageFilter: (funnelStage) =>
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_FUNNEL_STAGE_FILTER_CHANGED, payload: funnelStage }),
  onFilterChanged: (payload) => dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_FILTER_CHANGED, payload }),
});

type PropTypes = {
  // changePostTypeFilter: (postTypes: string[]) => void;
  changeSearchTextFilter: (searchText: string) => void;
  selectedWebsite: IWebsite;
  searchTextFilter: string | null;
  funnelStageFilter: string[];
  changeFunnelStageFilter: (funnelStage: string[]) => void;
  selectedDataItemsIndexes: number[];
  total: number;
  limit: number;
  page: number;
  isDialogOpen: boolean;
  activeDialogTab: "outlines" | "keywords" | "pages";
  // postTypesFilter: string[];
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  changeSort(sort: { field: string; direction: "desc" | "asc" }): void;

  refetch: boolean;
  // postVolumeFilter: number[];
  // postPagesFilter: number[];
  // postClicksFilter: number[];

  loading: () => void;
  changeLoadedData: (payload: any) => void;
  changeIsDialogOpen: (open: boolean) => void;
  changeActiveDialogTab: (tab: "outlines" | "keywords" | "pages") => void;
  // changePageFilter: (pages: number[]) => void;
  // changeClicksFilter: (clicks: number[]) => void;
  // changeVolumeFilter: (volume: number[]) => void;
  changePage: (page: number) => void;
  changeLimit: (limit: number) => void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  onFilterChanged: (payload) => void;
};

const KeywordGroupPage = (props: PropTypes) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    changeIsDialogOpen,
    changeLoadedData,
    changeLimit,
    changeFunnelStageFilter,
    funnelStageFilter,
    changePage,
    loading,
    changeSearchTextFilter,
    changeSelectedDataItemsIndexes,
    searchTextFilter,
    isDialogOpen,
    limit,
    total,
    page,
    sortField,
    refetch,
    selectedWebsite,
    selectedDataItemsIndexes,
    changeSort,
    onFilterChanged,
  } = props;

  useEffect(
    () => () => {
      changeIsDialogOpen(false);
      changeLimit(100);
      changePage(1);
      changeSort({
        direction: "asc",
        field: "page",
      });
      changeSearchTextFilter("");
      changeFunnelStageFilter([]);
      changeSelectedDataItemsIndexes([]);
    },
    []
  );

  useEffect(() => {
    loading();
    const sort = JSON.stringify({
      [sortField.field]: sortField.direction === "asc" ? "1" : "-1",
    });
    const funnelStage = funnelStageFilter.length > 0 ? funnelStageFilter.join(",") : undefined;

    agent.KeywordGroupPage.getPages(selectedWebsite._id, page, limit, sort, searchTextFilter, funnelStage)
      .then((res) => {
        changeLoadedData(res);
      })
      .catch((err) => {
        enqueueSnackbar(err?.message, { variant: "error" });
      });
  }, [selectedWebsite, limit, page, refetch, funnelStageFilter, searchTextFilter, sortField]);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t("meta_tags.strategy.links.title")} />
      <MTableToolbar
        searchText={searchTextFilter}
        handleSearchTextChanged={(value) => onFilterChanged({ search: value })}
        selectedRows={selectedDataItemsIndexes?.length || 0}
        onClearSelectedRows={() => changeSelectedDataItemsIndexes([])}
      >
        <MTableToolbarKeywordStrategyTypeFilter
          postTypes={funnelStageFilter}
          handlePostTypesSelected={(value) => onFilterChanged({ funnel_stages: value })}
        />
      </MTableToolbar>
      <InternalLinkingList sortField={sortField} setSortField={(value) => onFilterChanged({ sortField: value })} />
      {total > 0 && (
        <MTablePagination
          paginationLimit={limit}
          page={page}
          paginationPagesCount={Math.ceil(total / limit)}
          setPage={changePage}
          setPaginationLimit={changeLimit}
        />
      )}
      <InternalLinkingDialog
        open={isDialogOpen}
        close={() => {
          changeIsDialogOpen(false);
        }}
      />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordGroupPage);
