import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import set from "lodash/set";
import TextInputToolAndDesign from "../../../../components/TextInputToolAndDesign";
import TextFieldMaxLength from "../../../../../../components/TextFieldMaxLength";
import { WEBSITE_PAGES } from "../../../../../../reducers/constants/actionTypes";
import {
  IWebsiteDynamicSectionProps,
  IWebsiteSectionProps,
  IWebsiteThemeProps,
  SNWebsiteAutoCompleteMetaTag,
} from "../../../../../../reducers/constants/objectTypes";
import TextInputMentions from "../../../../components/TextInputMentions";
import { LENGTHS } from "../../../../../../reducers/constants/consts";
import TextDesignToolWithPreview from "../../../../components/TextDesignToolWithPreview";
import PageSection from "../../../../components/PageSection";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

const mapStateToProps = (state) => ({
  theme: state.websitePages.theme,
  sections: state.websitePages.sections,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (dynamicSection) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "dynamicSection",
      value: dynamicSection,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  theme: IWebsiteThemeProps;
  sections: IWebsiteSectionProps;
  dynamicSection: IWebsiteDynamicSectionProps;
  emptyFields: string[];
  onPropertyChanged: (learn) => void;
  handleToggleActive: (section, enabled) => void;
  locale: string;
  defaultLocale: string;
};

const LearnTemplate = (props: PropTypes) => {
  const classes = useStyles(props);
  const { dynamicSection, onPropertyChanged, emptyFields, locale, defaultLocale } = props;
  const [mainPageEmptyFields, setMainPageEmptyFields] = React.useState([]);
  const [categoryPageEmptyFields, setCategoryPageEmptyFields] = React.useState([]);

  const mainPageValidated = () => {
    let valid = true;
    const requiredFields = [];
    setMainPageEmptyFields(requiredFields);
    if (!dynamicSection.mainPage.title?.text) {
      valid = false;
      requiredFields.push(I18n.t(`website.learn.main_page_title`));
    }
    if (!dynamicSection.mainPage.metaTitle) {
      valid = false;
      requiredFields.push(I18n.t(`website.meta_title`));
    }
    if (!dynamicSection.mainPage.description?.text) {
      valid = false;
      requiredFields.push(I18n.t(`website.learn.main_page_description`));
    }
    if (!dynamicSection.mainPage.metaDescription) {
      valid = false;
      requiredFields.push(I18n.t(`website.meta_description`));
    }

    setMainPageEmptyFields(requiredFields);
    return valid;
  };
  const categoryPageValidated = () => {
    let valid = true;
    const requiredFields = [];
    setCategoryPageEmptyFields(requiredFields);
    if (!dynamicSection.categoryPage.slug) {
      requiredFields.push(I18n.t(`website.learn.category_page_slug`));
    }
    if (!dynamicSection.categoryPage.metaTitle) {
      valid = false;
      requiredFields.push(I18n.t(`website.learn.category_page_meta_title`));
    }
    if (!dynamicSection.categoryPage.metaDescription) {
      valid = false;
      requiredFields.push(I18n.t(`website.learn.category_page_meta_description`));
    }
    setCategoryPageEmptyFields(requiredFields);
    return valid;
  };

  return (
    <>
      <PageSection
        sectionTitle={I18n.t("website.new_content_page.main_page")}
        checked={dynamicSection.mainPage.enabled}
        onCheckedChange={(checked) => {
          if (!mainPageValidated()) {
            if (!checked && dynamicSection.mainPage.enabled) {
              onPropertyChanged({
                ...dynamicSection,
                mainPage: { ...dynamicSection.mainPage, enabled: checked },
              });
            }
            return;
          }
          onPropertyChanged({
            ...dynamicSection,
            mainPage: { ...dynamicSection.mainPage, enabled: checked },
          });
        }}
      >
        {mainPageEmptyFields.length > 0 && (
          <div className={classes.errorWrapper}>
            <Typography variant={"body1"} className={classes.error}>
              {I18n.t("website.error_message")}
            </Typography>
          </div>
        )}
        <TextInputToolAndDesign
          textFieldTitle={I18n.t("website.title")}
          error={mainPageEmptyFields.includes(I18n.t(`website.learn.main_page_title`))}
          text={
            locale === defaultLocale
              ? dynamicSection.mainPage?.title
              : {
                  text: dynamicSection.translations[locale]?.mainPage?.title || "",
                  css: dynamicSection.mainPage.title.css,
                }
          }
          handleTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...dynamicSection, mainPage: { ...dynamicSection.mainPage, title: value } });
              return;
            }
            set(dynamicSection.translations[locale], "mainPage.title", value.text);
            onPropertyChanged({ ...dynamicSection });
          }}
          variant={"h1"}
        />

        <TextInputMentions
          tags={[SNWebsiteAutoCompleteMetaTag.year]}
          key={`dynamic_section_learn_main_page_meta_title_${locale}`}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          error={mainPageEmptyFields.includes(I18n.t(`website.meta_title`))}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? dynamicSection.mainPage.metaTitle
              : dynamicSection.translations[locale]?.mainPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...dynamicSection, mainPage: { ...dynamicSection.mainPage, metaTitle: value } });
              return;
            }
            set(dynamicSection.translations[locale], "mainPage.metaTitle", value);
            onPropertyChanged({ ...dynamicSection });
          }}
        />
        <Divider className={classes.divider} />

        <TextInputToolAndDesign
          textFieldTitle={I18n.t("website.description")}
          variant={"h4"}
          error={mainPageEmptyFields.includes(I18n.t(`website.learn.main_page_description`))}
          text={
            locale === defaultLocale
              ? dynamicSection.mainPage.description
              : {
                  text: dynamicSection.translations[locale]?.mainPage?.description || "",
                  css: dynamicSection.mainPage.description.css,
                }
          }
          handleTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...dynamicSection, mainPage: { ...dynamicSection.mainPage, description: value } });
              return;
            }
            set(dynamicSection.translations[locale], "mainPage.description", value.text);
            onPropertyChanged({ ...dynamicSection });
          }}
        />

        <TextInputMentions
          tags={[SNWebsiteAutoCompleteMetaTag.year]}
          key={`dynamic_section_learn_main_page_meta_description_${locale}`}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          error={mainPageEmptyFields.includes(I18n.t(`website.meta_description`))}
          text={
            locale === defaultLocale
              ? dynamicSection.mainPage.metaDescription
              : dynamicSection.translations[locale]?.mainPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                mainPage: { ...dynamicSection.mainPage, metaDescription: value },
              });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              "mainPage.metaDescription": value,
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
      </PageSection>
      <PageSection
        checked={dynamicSection.categoryPage.enabled}
        onCheckedChange={(checked) => {
          if (!categoryPageValidated()) {
            if (!checked && dynamicSection.categoryPage.enabled) {
              onPropertyChanged({
                ...dynamicSection,
                categoryPage: { ...dynamicSection.categoryPage, enabled: checked },
              });
            }
            return;
          }
          onPropertyChanged({
            ...dynamicSection,
            categoryPage: { ...dynamicSection.categoryPage, enabled: checked },
          });
        }}
        sectionTitle={I18n.t("website.new_content_page.category_page")}
      >
        {categoryPageEmptyFields.length > 0 && (
          <div className={classes.errorWrapper}>
            <Typography variant={"body1"} className={classes.error}>
              {I18n.t("website.error_message")}
            </Typography>
          </div>
        )}
        <TextFieldMaxLength
          titleAndLengthLocation={"top"}
          error={categoryPageEmptyFields.includes(I18n.t(`website.learn.category_page_slug`))}
          value={
            locale === defaultLocale
              ? dynamicSection.categoryPage.slug
              : dynamicSection.translations[locale]?.categoryPage?.slug || ""
          }
          placeholder={I18n.t("website.slug_title_ph")}
          title={I18n.t("website.slug_title")}
          height={37}
          borderRadius={4}
          marginBottom={30}
          handleValueChange={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...dynamicSection, categoryPage: { ...dynamicSection.categoryPage, slug: value } });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              categoryPage: { ...(dynamicSection.categoryPage || {}), slug: value },
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />

        <TextInputMentions
          key={`dynamic_section_learn_category_page_meta_title_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.categoryName, SNWebsiteAutoCompleteMetaTag.year]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          error={categoryPageEmptyFields.includes(I18n.t(`website.learn.category_page_meta_title`))}
          text={
            locale === defaultLocale
              ? dynamicSection.categoryPage.metaTitle
              : dynamicSection.translations[locale]?.categoryPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                categoryPage: { ...dynamicSection.categoryPage, metaTitle: value },
              });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              "categoryPage.metaTitle": value,
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />

        <TextInputMentions
          key={`dynamic_section_learn_category_page_meta_description_${locale}`}
          tags={[
            SNWebsiteAutoCompleteMetaTag.categoryName,
            SNWebsiteAutoCompleteMetaTag.categoryDescription,
            SNWebsiteAutoCompleteMetaTag.year,
          ]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          error={categoryPageEmptyFields.includes(I18n.t(`website.learn.category_page_meta_description`))}
          text={
            locale === defaultLocale
              ? dynamicSection.categoryPage.metaDescription
              : dynamicSection.translations[locale]?.categoryPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                categoryPage: { ...dynamicSection.categoryPage, metaDescription: value },
              });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              "categoryPage.metaDescription": value,
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
      </PageSection>
      <PageSection sectionTitle={I18n.t("website.new_content_page.post_page")}>
        <TextDesignToolWithPreview
          topTitle
          disableHover
          marginLeft={"-30px"}
          marginRight={"-30px"}
          elementTitle={I18n.t("website.element_design", { element: I18n.t("website.title") })}
          css={dynamicSection.postPage.title?.css}
          variant={"h1"}
          exampleText={I18n.t(`website.new_content_page.post_page_meta_title`, {
            name: dynamicSection.name || "Targeted",
          })}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...dynamicSection,
              postPage: { ...dynamicSection.postPage, title: { ...dynamicSection.postPage.title, css: newCss } },
            });
          }}
        />

        <TextInputMentions
          key={`dynamic_section_learn_post_page_meta_title_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.postTitle, SNWebsiteAutoCompleteMetaTag.year]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.learn.post_page_meta_title`))}
          text={
            locale === defaultLocale
              ? dynamicSection.postPage.metaTitle
              : dynamicSection.translations[locale]?.postPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...dynamicSection, postPage: { ...dynamicSection.postPage, metaTitle: value } });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              "postPage.metaTitle": value,
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
        <Divider className={classes.divider} />
        <TextDesignToolWithPreview
          disableHover
          topTitle
          marginLeft={"-30px"}
          marginRight={"-30px"}
          elementTitle={I18n.t("website.element_design", {
            element: I18n.t("website.description"),
          })}
          css={dynamicSection.postPage.description?.css}
          variant={"h2"}
          exampleText={I18n.t("website.glossary.example_description_text")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...dynamicSection,
              postPage: {
                ...dynamicSection.postPage,
                description: { ...dynamicSection.postPage.description, css: newCss },
              },
            });
          }}
        />

        <TextInputMentions
          key={`dynamic_section_learn_post_page_meta_description_${locale}`}
          tags={[
            SNWebsiteAutoCompleteMetaTag.postTitle,
            SNWebsiteAutoCompleteMetaTag.postCategoryName,
            SNWebsiteAutoCompleteMetaTag.postText,
            SNWebsiteAutoCompleteMetaTag.postMetaDescription,
            SNWebsiteAutoCompleteMetaTag.year,
          ]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          error={emptyFields.includes(I18n.t(`website.learn.post_page_meta_description`))}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? dynamicSection.postPage.metaDescription
              : dynamicSection.translations[locale]?.postPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                postPage: { ...dynamicSection.postPage, metaDescription: value },
              });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              "postPage.metaDescription": value,
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LearnTemplate);
