import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import SelectRow from "../../../../components/SelectRow";
import { fontOptions } from "../../../consts/CssConsts";
import { useState } from "react";
import TextFieldMaxLength from "../../../../../../components/TextFieldMaxLength";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";

const useStyles = makeStyles((theme: Theme) => ({
  innerWrapper: {
    marginRight: "-30px",
    marginLeft: "-30px",
  },
  elementWrap: {
    borderBottom: `${theme.palette.divider} solid 1px`,
    marginTop: "-10px",
    marginBottom: 0,
    padding: "0px 30px 5px 30px",
  },
  inputClassName: {
    width: 320,
  },
}));

type PropTypes = {
  fontFamily: string;

  handleFontFamilyChange: (fontFamily) => void;
};

const MainPageTypographyFontFamily = (props: PropTypes) => {
  const classes = useStyles(props);
  const { fontFamily, handleFontFamilyChange } = props;
  const [customFontFamily, setCustomFontFamily] = useState(false);
  return (
    <div className={classes.elementWrap}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={customFontFamily}
              onChange={(e) => {
                e.stopPropagation();
                setCustomFontFamily(!customFontFamily);
              }}
              color="primary"
            />
          }
          labelPlacement="start"
          label={I18n.t("website.design_tool.custom")}
        />
      </FormGroup>
      {!customFontFamily && (
        <SelectRow
          bold={true}
          width={320}
          rowTitle={I18n.t("website.design_tool.font")}
          value={fontFamily || ""}
          selectOptions={fontOptions}
          handleChange={(selectedFontFamily) => {
            handleFontFamilyChange(selectedFontFamily);
          }}
        />
      )}
      {customFontFamily && (
        <TextFieldMaxLength
          titleAndLengthLocation={"left"}
          alignTitle={"start"}
          value={fontFamily || ""}
          placeholder={I18n.t("website.slug_title_ph")}
          title={I18n.t("website.design_tool.font")}
          inputClassName={classes.inputClassName}
          height={37}
          marginBottom={15}
          borderRadius={4}
          handleValueChange={(value) => handleFontFamilyChange(value)}
        />
      )}
    </div>
  );
};

export default MainPageTypographyFontFamily;
