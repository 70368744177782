import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { find, findIndex } from "lodash";
import TextAreaWidgetBaseElement from "../baseElements/TextAreaWidgetBaseElement";
import ImagePlaceHolder from "../../../../icons/ImagePlaceHolder";
import { IProduct, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { Widget } from "../WithWidgets";
import DropDownBaseComponent from "../../../elementsStyles/BaseComponents/DropDownBaseComponent";
import { getImageCDNUrl } from "../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  featureComparisons: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid transparent",
    borderRadius: 3,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  tableTopRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 85,
    padding: "15px 0px 15px 0px",
    alignItems: "center",
  },
  tableTitle: (props: PropTypes) => ({
    fontSize: 21,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.3,
    // marginBottom: 10,
    ...props.element.data?.tableTitleCss,
  }),
  productsWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    alignItems: "start",
  },
  productsWrapperHideLabel: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    alignItems: "center",
  },
  productWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 10,
    width: 70,
  },
  productLogoPh: {
    height: 70,
    width: 70,
    backgroundColor: "#808080",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  productLogo: {
    height: 70,
    width: 70,
    objectFit: "contain",
  },
  productTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: "16.8px",
    textAlign: "center",
  },
  productComparisonsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  comparisonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    padding: "15px 0px",
  },
  comparisonRowWrapper: (props: PropTypes) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: 85,
    ...props.element.data?.dividerCss,
  }),
  featureTitle: (props: PropTypes) => ({
    lineHeight: "19.2px",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 3,
    ...props.element.data?.itemTitleCss,
  }),
  productSelectionWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  productSelect: {
    width: 70,
  },
  productSelectCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  productSwitchWrapper: {
    width: 70,
    display: "flex",
    flexDirection: "column",
  },
  priceText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "14.4px",
    textAlign: "center",
    marginTop: 5,
  },
  closeIconWrapper: {
    width: 60,
    fontSize: "18px !important",
  },
  uncheckIcon: {
    fill: "#C4C4C4",
    fontSize: 21,
  },
  checkIcon: {
    fill: "#4353FF",
    fontSize: 21,
  },
  clearIcon: {
    fill: "#808080",
    marginLeft: 20,
    cursor: "pointer",
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  theme: IWebsiteThemeProps;
  products: IProduct[];
};

export const getRawText = (n: Node) => {
  if (!n.data) {
    return "";
  }
  return (
    [
      n.data.title || "",
      n.data.comparisons?.map((c) => `${c.title}`)?.join("\n"),
      n.data.comparisons?.map((c) => c.products?.map((p) => `${p.price}`)?.join("\n")),
    ]?.join("\n") || ""
  );
};

const mapStateToProps = (state) => ({
  theme: state.home.selectedWebsite.configurations.theme,
});

const PricingTableWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, theme, products } = props;
  const editor = useSlateStatic();
  const ownPath = ReactEditor.findPath(editor as ReactEditor, element);

  const handleChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ownPath });
  };

  const getEditableChangingFieldComponent = React.useCallback(
    (comparison, index, handleComparisonChange) => {
      const productColumnData = find(comparison.products, (p) => p._id === element.data.products[index].itemNumber);
      return (
        <div className={classes.productSwitchWrapper}>
          <DropDownBaseComponent
            defaultValue={"Checkbox"}
            value={productColumnData?.selectType || "Checkbox"}
            tags={["Checkbox", "Text"]}
            handleChange={(event) => {
              const comparisonProducts = [...(comparison.products || [])];
              const productIndex = findIndex(
                comparison.products,
                (p) => p._id === element.data.products[index].itemNumber
              );
              if (productIndex > -1) {
                comparisonProducts[productIndex] = {
                  ...comparisonProducts[productIndex],
                  selectType: event.target.value,
                  selected: event.target.value === "Text" ? null : comparisonProducts[productIndex].selected,
                  price: event.target.value === "Text" ? comparisonProducts[productIndex].text : null,
                };
              } else {
                comparisonProducts.push({
                  selectType: event.target.value,
                  selected: null,
                  price: null,
                  _id: element.data.products[index].itemNumber,
                });
              }
              handleComparisonChange({ ...comparison, products: comparisonProducts });
            }}
          />

          {productColumnData?.selectType === "Text" ? (
            <TextAreaWidgetBaseElement
              className={classes.priceText}
              text={productColumnData?.price}
              placeholder={I18n.t("rich_text_editor.pricing_table.price_ph")}
              onChange={(price) => {
                const comparisonProducts = [...(comparison.products || [])];
                const productIndex = findIndex(
                  comparison.products,
                  (p) => p._id === element.data.products[index].itemNumber
                );
                if (productIndex > -1) {
                  comparisonProducts[productIndex] = {
                    ...comparisonProducts[productIndex],
                    price,
                  };
                } else {
                  comparisonProducts.push({
                    selectedType: "Text",
                    selected: null,
                    price,
                    _id: element.data.products[index].itemNumber,
                  });
                }
                handleComparisonChange({ ...comparison, products: comparisonProducts });
              }}
            />
          ) : (
            <div
              className={ClassNames(classes.productSelect, classes.productSelectCenter)}
              onClick={() => {
                const comparisonProducts = [...(comparison.products || [])];
                const productIndex = findIndex(
                  comparison.products,
                  (p) => p._id === element.data.products[index].itemNumber
                );
                if (productIndex > -1) {
                  comparisonProducts[productIndex] = {
                    ...comparisonProducts[productIndex],
                    selected: !comparisonProducts[productIndex].selected,
                  };
                } else {
                  comparisonProducts.push({
                    selectedType: "Checkbox",
                    selected: true,
                    price: null,
                    _id: element.data.products[index].itemNumber,
                  });
                }
                handleComparisonChange({ ...comparison, products: comparisonProducts });
              }}
            >
              {!productColumnData?.selected ? (
                <RadioButtonUncheckedIcon className={classes.uncheckIcon} />
              ) : (
                <CheckCircleIcon className={classes.checkIcon} />
              )}
            </div>
          )}
        </div>
      );
    },
    [element]
  );

  const getProductByItemNumber = (itemNumber) => find(products, (p) => p.itemNumber === itemNumber);

  return (
    <div {...attributes} contentEditable={false}>
      <Widget title={I18n.t("rich_text_editor.custom_components.pricing_table")} element={element} editable>
        <div className={classes.featureComparisons}>
          <div
            className={classes.tableTopRow}
            style={{ backgroundColor: theme.palette.secondary.main, ...element.data.headCss }}
          >
            <TextAreaWidgetBaseElement
              className={classes.tableTitle}
              text={element.data.title || ""}
              placeholder={I18n.t("rich_text_editor.pricing_table.title_ph")}
              onChange={(title) => handleChange({ ...element.data, title })}
            />
            <div className={classes.productsWrapper}>
              {(element.data.products || []).map((prod, index) => {
                const product = getProductByItemNumber(prod.itemNumber);
                return (
                  <div className={classes.productWrapper} key={`${product?.name}_${index}`}>
                    {element.data.showLogo && (
                      <>
                        {product?.iconFile?.url || product?.logo || product?.logoFile?.url ? (
                          <img
                            className={classes.productLogo}
                            alt={product?.iconFile?.alt || product?.logoFile?.alt || `Product ${index}`}
                            src={getImageCDNUrl(product?.iconFile?.url || product?.logo || product?.logoFile?.url)}
                          />
                        ) : (
                          <div className={classes.productLogoPh}>
                            <ImagePlaceHolder />
                          </div>
                        )}
                      </>
                    )}
                    <Typography className={classes.productTitle}>{product?.displayName || product?.name}</Typography>
                  </div>
                );
              })}
              <div className={classes.closeIconWrapper} />
            </div>
          </div>

          <div className={classes.productComparisonsWrapper}>
            {[
              ...(element.data.comparisons || []),
              {
                title: "",
                products: [
                  ...(element.data.products?.map((product, index) => ({
                    itemNumber: product.itemNumber,
                    selectType: "Checkbox",
                    selected: false,
                    price: null,
                  })) || []),
                ],
              },
            ].map((comp, compIndex) => (
              <div
                key={`con_${compIndex}`}
                className={classes.comparisonRowWrapper}
                style={{
                  backgroundColor: compIndex % 2 === 0 ? "#ffffff" : theme.palette.secondary.main,
                  ...(compIndex % 2 === 0 ? element.data.evenCss : element.data.oddCss),
                }}
              >
                <div className={ClassNames(classes.comparisonWrapper)}>
                  <TextAreaWidgetBaseElement
                    className={classes.featureTitle}
                    text={comp.title}
                    placeholder={I18n.t(`rich_text_editor.pricing_table.compared_item_ph`)}
                    onChange={(value) => {
                      const updatedComparisons = [...(element.data.comparisons || [])];
                      if (compIndex >= updatedComparisons.length) {
                        updatedComparisons.push({ title: value });
                      } else {
                        updatedComparisons[compIndex] = { ...updatedComparisons[compIndex], title: value };
                      }
                      handleChange({ ...element.data, comparisons: updatedComparisons });
                    }}
                  />

                  <div className={classes.productSelectionWrapper}>
                    {Array(element.data.products?.length)
                      .fill()
                      .map((dummy, index) =>
                        getEditableChangingFieldComponent(comp, index, (comparison) => {
                          const comparisons = [...(element.data.comparisons || [])];
                          if (compIndex >= comparisons.length) {
                            comparisons.push(comparison);
                          } else {
                            comparisons[compIndex] = comparison;
                          }
                          handleChange({ ...element.data, comparisons });
                        })
                      )}
                  </div>
                </div>

                <div className={classes.closeIconWrapper}>
                  {element.data.comparisons &&
                    element.data.comparisons.length !== 0 &&
                    element.data.comparisons.length !== compIndex && (
                      <CloseIcon
                        className={classes.clearIcon}
                        onClick={() => {
                          const updatedComparisons = [...element.data.comparisons];
                          updatedComparisons.splice(compIndex, 1);
                          handleChange({ ...element.data, comparisons: updatedComparisons });
                        }}
                      />
                    )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {children}
      </Widget>
    </div>
  );
};

export default connect(mapStateToProps)(PricingTableWidget);
