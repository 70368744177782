import React from "react";
import { I18n } from "react-redux-i18n";
import Paper from "@material-ui/core/Paper";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import ExpandIcon from "../../../../icons/ExpandIcon";
import { web } from "../../../../helpers/urlHelper";
import CfProgressBar from "../../../../components/CfProgressBar";
import MSelect from "../../../../components/MSelect";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: "100%",
    display: "flex",
    gap: 20,
    flexDirection: "column",
    padding: 20,
    minWidth: 0,
    boxShadow: "1px 1px 7px 0px #00000026",
    borderRadius: 3,
    border: "none",
    "& .recharts-tooltip-wrapper": {
      outline: "none !important",
    },
    "& .recharts-default-tooltip": {
      border: `1px solid ${theme.palette.divider} !important`,
    },
  },
  bottomWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  currentPage: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.secondary,
  },
  pagination: {
    display: "flex",
    columnGap: 20,
    alignItems: "center",
  },

  select: {
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.secondary,
  },
  cacheRevalidator: {
    display: "flex",
    columnGap: "5px",
    alignItems: "center",
    "&:hover $cacheText": {
      color: theme.palette.text.primary,
    },
  },
  cacheText: {
    fontSize: "14px",
    color: theme.palette.text.secondary,
    transition: "color 0.2s ease-in-out",
  },
  topWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    alignItems: "start",
  },
  top: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  infoWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  bottom: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    rowGap: "20px",
  },
  totalCountText: {
    color: theme.palette.text.primary,
    fontSize: "11px",
    paddingRight: "20px",
  },
  totalCount: {
    display: "flex",
  },
  refetchBtn: {
    textTransform: "none",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  text: {
    fontSize: 14,
  },
  secondaryHeader: {
    width: "100%",
  },
  reportsWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: 15,
    height: "100%",
    minWidth: 0,
  },
  reportTitle: {
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: 16,
    color: theme.palette.text.primary,
  },

  progressBar: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  legendWrap: {
    display: "flex",
  },
  container: {
    display: "flex",
    gap: 5,
    marginRight: 20,
    "&:last-child": {
      marginRight: 0,
    },
  },
  rightContainer: {
    display: "flex",
    gap: 10,
  },
  titleWrapper: {
    display: "flex",
    gap: 10,
    border: "none",

    "&:hover $iconBtn": {
      display: "block",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconBtn: {
    display: "none",
    transition: "all 0.3s",
    padding: 0,
  },
  icon: {
    height: 14,
    width: 14,
    fill: theme.palette.text.primary,
  },
  chartTitle: {
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: "16px",
    color: theme.palette.text.primary,
    borderBottom: `1px solid transparent`,
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.common.black}`,
    },
  },
  reportTitleWrapper: {
    display: "flex",
    columnGap: 3,
  },
  hideTitle: {
    visibility: "hidden",
  },
  moreInfo: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: 10,
    boxShadow: "-2px 0px 5px 0px #0000001A",
    borderRadius: 3,
  },
}));

type WithTimeFrameSelect = {
  showTimeFrameSelect: true;
  timeFrameProps: {
    value: {
      label: string;
      value: string;
    };
    handleChange: (timeFrame: { label: string; value: string }) => void;
  };
};

type WithoutTimeFrameSelect = {
  showTimeFrameSelect?: false;
  timeFrameProps?: never;
};

type PropTypes = {
  id?: string;
  loading: boolean;
  containsData: boolean;
  openReportInExpandView: (reportType: string, query?: string, currentUrl?: string) => void;
  reportTypeName?: string;
  pagination?: {
    pageSize: number;
    pageIndex: number;
  };

  hidePagination?: boolean;
  totalPageCount?: number;
  limitOptions?: Array<{ name: string; value: number }>;
  setPagination?: (pagination: { pageSize: number; pageIndex: number }) => void;
  subTitle?: string;
  showTimeFrameSelect?: boolean;
  className?: string;
  children: React.ReactNode;
  setRefreshCache?: () => void;
  entailTraffic?: boolean;
  cacheData?: {
    cache: boolean;
    lastFetch: Date;
  };
  extraOptionsComponent?: React.ReactNode;
  secondaryHeader?: React.ReactNode;
  periodTotalCount?: number;
} & (WithTimeFrameSelect | WithoutTimeFrameSelect);

const options = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

const mapDispatchToProps = (dispatch) => ({
  openReportInExpandView: (report, query?: string, currentUrl?: string) => {
    dispatch(push(web.croReports(report, query, currentUrl)));
  },
});

const ReportCard = (props: PropTypes) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const {
    loading,
    containsData,
    periodTotalCount,
    pagination,
    limitOptions,
    setPagination,
    totalPageCount,
    cacheData,
    showTimeFrameSelect,
    timeFrameProps,
    className,
    hidePagination,
    children,
    subTitle,
    extraOptionsComponent,
    setRefreshCache,
    openReportInExpandView,
    reportTypeName,
    id,
    entailTraffic,
    secondaryHeader,
  } = props;
  const location = useLocation();

  return (
    <div className={classes.reportsWrapper} id={id}>
      <Paper className={classNames(className, classes.paper)}>
        <div className={classes.topWrapper}>
          <div className={classes.top}>
            <div
              className={classes.titleWrapper}
              onClick={() => {
                const queryTraffic = entailTraffic ? "entail_pages" : undefined;
                openReportInExpandView(reportTypeName, queryTraffic, location.pathname);
              }}
            >
              <Typography className={classes.chartTitle} variant={"subtitle2"}>
                {subTitle}
              </Typography>

              <IconButton className={classes.iconBtn}>
                <ExpandIcon className={classes.icon} />
              </IconButton>
            </div>
            <div className={classes.rightContainer}>
              {extraOptionsComponent}
              {showTimeFrameSelect && (
                <MSelect
                  options={options}
                  value={timeFrameProps.value}
                  optionBackgroundColorHover={"#E6EEFF"}
                  changeColorOnMenuOpen={true}
                  closedTextColor={theme.palette.text.primary}
                  optionValue={"value"}
                  selectedTextColor={theme.palette.primary.main}
                  textColor={theme.palette.primary.main}
                  valuePadding={"2px 0px 2px 0px"}
                  optionLabel={"label"}
                  borderColor={"transparent"}
                  isBold={false}
                  borderRadius={0}
                  borderWidth={0}
                  height={32}
                  placeholder={I18n.t("reports.dashboard.select_ph")}
                  handleChange={timeFrameProps.handleChange}
                />
              )}
            </div>
          </div>
          <div className={classes.secondaryHeader}>{secondaryHeader}</div>
        </div>

        {loading && (
          <div className={classes.progressBar}>
            <CfProgressBar />
          </div>
        )}
        {!loading && !containsData && <div>{I18n.t("reports.dashboard.nothing_to_show")}</div>}
        {!loading && containsData && <div className={classes.bottom}>{children}</div>}

        <div className={classes.bottomWrapper}>
          <div className={classes.cacheRevalidator}>
            <Tooltip title={I18n.t("reports.dashboard.refetch")}>
              <Button
                variant={"text"}
                className={classes.refetchBtn}
                onClick={() => {
                  if (setRefreshCache) setRefreshCache();
                }}
              >
                <Typography className={classes.cacheText}>
                  <span>{I18n.t("reports.dashboard.updated")}: </span>
                  {cacheData && (
                    <span>
                      {cacheData.cache ? moment(cacheData.lastFetch).fromNow() : I18n.t("reports.dashboard.live")}
                    </span>
                  )}
                </Typography>
              </Button>
            </Tooltip>
          </div>

          {periodTotalCount ? (
            <div className={classes.totalCount}>
              <Typography className={classes.totalCountText}>{`${I18n.t(
                "reports.dashboard.period_total_count"
              )}: ${periodTotalCount}`}</Typography>
            </div>
          ) : null}
          {!hidePagination && pagination && totalPageCount !== 0 && (
            <div className={classes.pagination}>
              <MSelect
                textColor={"#808080"}
                selectedTextColor={"#808080"}
                textColorHover={"#333333"}
                customClass={classes.select}
                options={limitOptions}
                value={limitOptions.find((v) => v.value === pagination.pageSize) ?? limitOptions[0]}
                optionLabel={"name"}
                handleChange={(limit) => {
                  setPagination({ ...pagination, pageSize: limit.value });
                }}
                optionValue={"value"}
                height={40}
                borderRadius={0}
                borderWidth={"0"}
                menuPlacement={"bottom"}
              />

              <div className={classes.currentPage}>
                <IconButton
                  onClick={() =>
                    setPagination({
                      ...pagination,
                      pageIndex: pagination.pageIndex - 1,
                    })
                  }
                  disabled={pagination.pageIndex === 0}
                >
                  <ChevronLeft />
                </IconButton>

                <div>
                  <span>{pagination.pageIndex + 1}</span> of <span>{totalPageCount}</span>
                </div>
                <IconButton
                  onClick={() =>
                    setPagination({
                      ...pagination,
                      pageIndex: pagination.pageIndex + 1,
                    })
                  }
                  disabled={pagination.pageIndex === totalPageCount - 1}
                >
                  <ChevronRight />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ReportCard);
