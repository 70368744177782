import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import { I18n } from "react-redux-i18n";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { sumBy } from "lodash";
import { ILinksSplitTest, ILinksSplitTestState, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import CfButton from "../../../../../../../components/CfButton";
import agent from "../../../../../../../agent";
import ArrowLeftIcon from "../../../../../../../icons/ArrowLeftIcon";
import { Divider } from "@material-ui/core";
import MPageActionsButtonGroup from "../../../../../../../components/MPageActionsButtonGroup";

const useStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    height: 55,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: theme.typography.pxToRem(24),
    paddingLeft: 40,
    paddingRight: 40,
    borderBottom: `solid 5px #E4EAF5`,
  },
  leftSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    marginRight: 20,
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  title: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: 10,
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(36),
  },
  stateTypography: {
    marginRight: 10,
  },
  divider: {
    height: 20,
    width: 1,
    marginRight: 20,
    marginLeft: 20,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  test: ILinksSplitTest;

  handleTestChanged: (test: ILinksSplitTest) => void;
  closeDialog: () => void;
};

const EditLinksSplitTestDialogTitle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { test, selectedWebsite, handleTestChanged, closeDialog } = props;
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const actions = React.useMemo(() => {
    if (test.state === ILinksSplitTestState.ACTIVE) {
      return [
        {
          label: I18n.t("links_split_test.editor.update_experiment"),
          action: "Update",
          subAction: "",
        },
        {
          label: I18n.t("links_split_test.editor.end_experiment"),
          action: "End",
          subAction: "",
        },
      ];
    }
    if (test.state === ILinksSplitTestState.DRAFT) {
      return [
        {
          label: I18n.t("links_split_test.editor.save_experiment"),
          action: "Save",
          subAction: "",
        },
        {
          label: I18n.t("links_split_test.editor.start_experiment"),
          action: "Start",
          subAction: "",
        },
        // {
        //   label: I18n.t("links_split_test.editor.start_experiment"),
        //   action: "Delete",
        //   subAction: "",
        // },
      ];
    }
  }, [test]);

  const validateTest = () => {
    if (!test.name) {
      alert("Test name is missing");
      return false;
    }

    if (!test.description) {
      alert("Test description is missing");
      return false;
    }

    if (test.state === ILinksSplitTestState.DRAFT) {
      return true;
    }

    if (!test.allProducts && test.products.length === 0) {
      alert("Test product is missing");
      return false;
    }

    if (test.allProducts && test.products.length > 0) {
      alert("All products is selected, please remove all products");
      return false;
    }

    if (sumBy(test.groups, "weight") !== 100) {
      alert("Sum of all weights must be 100");
      return false;
    }

    return true;
  };

  const updateTest = (state: ILinksSplitTestState.DRAFT | ILinksSplitTestState.ACTIVE) => {
    if (!validateTest()) {
      return;
    }
    setLoading(true);
    agent.LinksSplitTest.upsert(selectedWebsite._id, { ...test, state })
      .then((res) => {
        handleTestChanged(res.test);
        setLoading(false);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
        closeDialog();
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
        setLoading(false);
      });
  };

  const handleButtonClick = (action) => {
    switch (action.action) {
      case "Start":
      case "Update":
        updateTest(ILinksSplitTestState.ACTIVE);
        break;
      case "End":
      case "Save":
        updateTest(ILinksSplitTestState.DRAFT);
        break;
      case "Delete":
      default:
        throw new Error("Error: Unknown operation");
    }
  };

  return (
    <DialogTitle disableTypography className={classes.titleWrapper}>
      <div className={classes.leftSide}>
        <div className={classes.toolbarDetails}>
          <ArrowLeftIcon className={classes.arrowIcon} onClick={() => closeDialog()} />
        </div>
        <Typography color={"inherit"} className={classes.title} variant={"subtitle2"}>
          {I18n.t("links_split_test.editor.experiment")}
        </Typography>
        <Divider orientation={"vertical"} className={classes.divider} />
        <Typography color={"textSecondary"} className={classes.stateTypography} variant={"body1"}>
          {I18n.t(`links_split_test.editor.header_${test.state.toLowerCase()}`)}
        </Typography>
        <Typography color={"textPrimary"} className={classes.title} variant={"subtitle2"}>
          {test.name}
        </Typography>
      </div>

      <MPageActionsButtonGroup
        loading={loading}
        mainAction={actions[0]}
        handleActionOnClick={(action) => {
          handleButtonClick(action);
        }}
        actions={actions}
      />
    </DialogTitle>
  );
};

export default EditLinksSplitTestDialogTitle;
