import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { ReactEditor, useSelected, useSlateStatic } from "slate-react";
import Typography from "@material-ui/core/Typography";
import { Node, Path, Transforms } from "slate";
import { I18n } from "react-redux-i18n";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { ClickAwayListener, Popper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CheckIcon from "@material-ui/icons/Check";
import { useState } from "react";
import { useSelector } from "react-redux";
import ClassNames from "classnames";
import moment from "moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import _ from "lodash";
import comment from "./commentV2";
import CommentDeleteIcon from "../../../icons/CommentDeleteIcon";
import CommentResolvedIcon from "../../../icons/CommentResolvedIcon";
import { editorType } from "../../types/editor.Types";
import agent from "../../../agent";
import { IPage } from "../../../reducers/constants/objectTypes";
import theme from "../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  comment: {
    backgroundColor: "#FAF1C3",
    borderBottom: "2px solid #f7db48",
  },
  linkTooltip: {
    margin: 0,
    padding: 0,
    backgroundColor: "white",
    maxWidth: "unset",
  },
  linkTooltipPopper: {
    zIndex: 2,
  },
  commentResolved: {
    backgroundColor: "#CFF5C1",
    borderBottom: "2px solid #71b559",
  },
  popper: {
    zIndex: 999,
  },
  tooltip: {
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: 10,
  },
  commentListItem: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    alignItems: "flex-start",
  },
  tooltipComment: {
    color: "black",
    marginTop: 10,
    marginBottom: 10,
  },
  paper: {
    width: 320,
  },
  textToDisplayDetailsWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 0px 16px 16px",
  },
  commentsList: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 400px)",
    overflowY: "scroll",
    overflowX: "hidden",
    paddingRight: 16,
  },
  actionIcon: {
    fontSize: 16,
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  actionIconActive: {
    color: theme.palette.primary.main,
  },
  userNameText: {
    marginLeft: 8,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  agoText: {
    fontSize: 13,
    marginLeft: 8,
  },
  addIcon: {
    cursor: "pointer",
    margin: "auto",
    color: "white",
    borderRadius: "100%",
    fontSize: 20,
    backgroundColor: "rgba(0,0,0,0.3)",
    marginRight: 8,
  },
  addIconActive: {
    backgroundColor: theme.palette.primary.main,
  },
  textInput: {
    fontFamily: theme.typography.fontFamily,
    resize: "none",
    border: "none",
    outline: "none",
    width: "85%",
    height: "80%",
    marginLeft: 8,
    fontSize: 12,
    padding: 6,
    backgroundColor: "initial",
  },
  chatActionsInnerWrapper: {
    marginRight: -12,
  },
  chatActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "0px 16px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  resolveButton: {
    background: "none",
    border: "none",
  },
  userDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
    width: "100%",
  },
  commentText: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(24),
    marginLeft: 32,
    color: theme.palette.text.primary,
  },
  removeCommentWrapper: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    transform: "translateX(6px)",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
    paddingRight: 16,
  },
  textInputWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f5f6f8",
    minHeight: 40,
    flexGrow: 1,
    marginLeft: 8,
    borderRadius: 6,
  },
  commentsPopper: {
    position: "absolute",
    zIndex: 3,
    opacity: 0,
    pointerEvents: "none",
    left: -2000,
  },
  commentMenuIconButton: {
    padding: "0px 8px",
  },
  deleteButton: {
    backgroundColor: "white",
    boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "white",
    },
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  editorMode?: boolean;
  onCommentUpdate?: () => void;
};

const CommentElementV2 = React.memo(
  (props: PropTypes) => {
    const classes = useStyles(props);
    const { attributes, children, element, editorMode = false, onCommentUpdate } = props;
    const editor = useSlateStatic();
    const selected = useSelected();

    const alignment = element.alignment || "left";
    const [open, setOpen] = useState(false);
    const [commentText, setCommentText] = useState("");
    const anchorRef = React.useRef<HTMLButtonElement>({});
    const popperRef = React.useRef(null);
    const [clickedCommentIndex, setClickedCommentIndex] = useState(null);
    const admin = useSelector((state: any) => state.home.admin);
    const messagesEndRef = React.useRef(null);
    const [deleteEmptyComment, setDeleteEmptyComment] = useState(false);

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollTo(0, 1000000);
    };

    React.useEffect(() => {
      // Scroll to bottom when adding a new comment
      scrollToBottom();
    }, [element.comments]);

    React.useEffect(() => {
      // console.log('cmv2 editor,', editor);
      // console.log('cmv2 selected,', selected);
      // console.log('cmv2 inFocus,', inFocus);

      const el = popperRef.current;
      const { selection } = editor;

      if (!el) {
        // console.log('cmv2', 'no el');
        return;
      }

      if (selection !== null && selection.anchor !== null && selected) {
        const parentPath = Path.parent(selection.anchor.path);
        const lastElementInPath = Node.get(editor, parentPath);
        if (lastElementInPath.type === editorType.commentV2) {
          setOpen(true);
          setDeleteEmptyComment(false);
          scrollToBottom();
          return;
        }
      }
      if (!deleteEmptyComment && element.comments?.length === 0) {
        setDeleteEmptyComment(true);
        try {
          const elementPath = ReactEditor.findPath(editor as ReactEditor, element);
          Transforms.unwrapNodes(editor, { at: elementPath, match: (n) => n.type === "commentV2" });
        } catch (e) {
          console.log("error:::", e);
        }
      }
      setOpen(false);
    });

    React.useEffect(() => {}, [deleteEmptyComment]);
    const getContrastTextColor = (hex) => {
      const hexcolor = hex.replace("#", "");
      const r = parseInt(hexcolor.substr(0, 2), 16);
      const g = parseInt(hexcolor.substr(2, 2), 16);
      const b = parseInt(hexcolor.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    };

    const stringToColour = (str) => {
      let hash = 0;
      for (let i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let colour = "#";
      for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += `00${value.toString(16)}`.substr(-2);
      }
      return colour;
    };

    const getAvatarColorsCss = (str) => {
      const backgroundColor = stringToColour(str);
      return {
        backgroundColor,
        color: getContrastTextColor(backgroundColor),
      };
    };

    // const constructInitials = name => name.match(/\b(\w)/g).join('');
    const constructInitials = (name) => (name?.[0] || "a").toUpperCase();

    const deleteComment = (index) => {
      // console.log('remove comment ');
      // check if there is only 1 inserted comment and remove comment node from selection
      if (element.comments.length === 1) {
        comment.removeComment(editor);
        onCommentUpdate();
        return;
      }
      Transforms.setNodes(
        editor,
        {
          comments: element.comments.filter((c, i) => i !== index),
        },
        {
          match: (n) => n.type === editorType.commentV2 && n.uuid === element.uuid,
        }
      );
      setClickedCommentIndex(null);
      onCommentUpdate();
    };

    const handleCommentChanged = (changedComments) => {
      Transforms.setNodes(
        editor,
        {
          comments: changedComments,
          resolved: false,
        },
        {
          match: (n) => n.type === editorType.commentV2 && n.uuid === element.uuid,
        }
      );
      onCommentUpdate();
    };

    const toggleResolveComments = (resolved) => {
      const comments = [...element.comments];
      if (resolved) {
        comments.push({
          type: "resolve",
          text: "",
          checked: false,
          name: admin.name,
          photoUrl: admin.photoUrl || null,
          userId: admin._id,
          date: new Date(),
        });
      }
      Transforms.setNodes(
        editor,
        {
          comments,
          resolved,
        },
        {
          match: (n) => n.type === editorType.commentV2 && n.uuid === element.uuid,
        }
      );
      onCommentUpdate();
    };

    const addComment = () => {
      if (commentText.trim().length === 0) return; // check if text contains only whitespaces
      const commentsToAdd = [...element.comments];
      if (commentText.length > 0) {
        commentsToAdd.push({
          type: "comment",
          text: commentText,
          checked: false,
          notification: false,
          name: admin.name,
          photoUrl: admin.photoUrl || null,
          userId: admin._id,
          date: new Date(),
        });
      }
      handleCommentChanged(commentsToAdd);
      setCommentText("");
    };

    return (
      <Tooltip
        open={open}
        ref={popperRef}
        classes={{
          tooltip: classes.linkTooltip,
          popper: classes.linkTooltipPopper,
        }}
        PopperProps={{
          disablePortal: true,
          // keepMounted: true,
        }}
        // onOpen={() => console.log('open')}
        // onClose={() => console.log('closed')}
        placement={"bottom"}
        interactive
        title={
          <div className={classes.paper} contentEditable={false}>
            <div className={classes.chatActions}>
              <div className={classes.chatActionsInnerWrapper}>
                {editorMode && (
                  <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.tooltips.delete_thread")}>
                    <IconButton
                      className={classes.resolveButton}
                      onClick={() => {
                        comment.removeComment(editor);
                        onCommentUpdate();
                      }}
                    >
                      <CommentDeleteIcon className={classes.actionIcon} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.tooltips.mark_resolved")}>
                  <IconButton
                    className={classes.resolveButton}
                    onClick={() => toggleResolveComments(!element.resolved)}
                  >
                    <CommentResolvedIcon
                      className={ClassNames(classes.actionIcon, element.resolved && classes.actionIconActive)}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            <div className={classes.textToDisplayDetailsWrapper}>
              <List className={classes.commentsList} ref={messagesEndRef}>
                {element.comments.length > 0 &&
                  element.comments.map((c, index) => (
                    <ListItem key={`${element.uuid}_comment_${index}`} className={classes.commentListItem}>
                      <div className={classes.userDetailsWrapper}>
                        <Avatar
                          style={{
                            width: 24,
                            height: 24,
                            fontSize: 12,
                            ...getAvatarColorsCss(c.name),
                          }}
                        >
                          {c.photoUrl || constructInitials(c.name)}
                        </Avatar>
                        <Typography className={classes.userNameText} variant={"subtitle1"}>
                          {c.name}
                        </Typography>
                        <Typography className={classes.agoText} variant="body2">
                          {moment(c.date).fromNow()}
                        </Typography>

                        {c.userId === admin._id && (
                          <div className={classes.removeCommentWrapper} contentEditable={false}>
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                              className={classes.commentMenuIconButton}
                              ref={(ref) => (anchorRef.current[index] = ref)}
                              onClick={() => {
                                console.log("onClick", index, clickedCommentIndex);
                                setClickedCommentIndex(index);
                              }}
                            >
                              <MoreHorizIcon className={classes.actionIcon} />
                            </IconButton>

                            {index === clickedCommentIndex && (
                              <ClickAwayListener
                                onClickAway={() => {
                                  setClickedCommentIndex(null);
                                }}
                              >
                                <Popper
                                  open
                                  anchorEl={anchorRef.current[index]}
                                  disablePortal
                                  placement={"bottom"}
                                  modifiers={{
                                    flip: {
                                      enabled: false,
                                    },
                                    preventOverflow: {
                                      enabled: true,
                                      boundariesElement: "scrollParent",
                                    },
                                  }}
                                >
                                  <Button className={classes.deleteButton} onClick={() => deleteComment(index)}>
                                    {I18n.t("rich_text_editor.insert_comments.delete")}
                                  </Button>
                                </Popper>
                              </ClickAwayListener>
                            )}
                          </div>
                        )}
                      </div>
                      <Typography className={classes.commentText} variant={"body1"}>
                        {c.type === "resolve" ? (
                          <span
                            style={{
                              color: "#37944C",
                              fill: "#37944C",
                              fontWeight: theme.typography.fontWeightBold as any,
                              fontSize: 14,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <CheckIcon style={{ fontSize: 18, marginRight: 5 }} />
                            {"Resolved"}
                          </span>
                        ) : (
                          c.text
                        )}
                      </Typography>
                    </ListItem>
                  ))}
              </List>
              <div className={classes.inputWrapper}>
                <Avatar style={{ width: 24, height: 24, fontSize: 12, ...getAvatarColorsCss(admin.name) }}>
                  {admin.photoUrl ? admin.photoUrl : constructInitials(admin.name)}
                </Avatar>
                <div className={classes.textInputWrapper}>
                  <TextareaAutosize
                    autoFocus
                    value={commentText}
                    minRows={1}
                    maxRows={5}
                    onChange={(e) => setCommentText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (e.shiftKey) {
                          return;
                        }
                        e.preventDefault();
                        addComment();
                      }
                    }}
                    placeholder={I18n.t("rich_text_editor.insert_comments.comment_ph")}
                    className={classes.textInput}
                  />
                  <ArrowUpwardIcon
                    className={ClassNames(classes.addIcon, commentText.trim().length !== 0 && classes.addIconActive)}
                    onClick={addComment}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      >
        <span
          className={element.resolved ? classes.commentResolved : classes.comment}
          style={{ textAlign: alignment }}
          {...attributes}
        >
          {children}
        </span>
      </Tooltip>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps?.element?.children?.length > 1) {
      return _.isEqual(prevProps.children, nextProps.children);
    }
    return _.isEqual(prevProps.element, nextProps.element);
  }
);

export default CommentElementV2;
