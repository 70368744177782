import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import numeral from "numeral";
import Tooltip from "@material-ui/core/Tooltip";
import { I18n } from "react-redux-i18n";
import { getOpportunityPageUrl } from "../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  group: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  metric: {
    fontSize: "14px",
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: "100%",
    width: "10%",
  },
  trafficMetric: {
    marginRight: "5px",
    fontSize: "14px",
  },
  trafficMetricLeft: {
    marginLeft: "5px",
    fontSize: "14px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  number: {
    width: 20,
    height: 20,
    backgroundColor: "lightblue",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    fontWeight: theme.typography.fontWeightBold as any,
    fontsize: 14,
    lineHeight: "16.8px",
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    padding: "20px 30px",
    justifyContent: "space-between",
    fontSize: "14px",
  },
  listItemFirstColumn: {
    width: "55%",
  },
  firstColumn: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: "14px",
    color: theme.palette.text.primary,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    transition: "color .2s ease-in-out",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  postTitleField: {
    color: theme.palette.text.secondary,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: "1.5rem",
    height: "1.5rem",
    fontFamily: theme.typography.body2.fontFamily,
    cursor: "initial",
  },
}));

type PropTypes = {
  page: any;
};

const PagesDialogListItem = (props: PropTypes) => {
  const { page } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.group}>
      <ListItem className={classNames(classes.listRow)}>
        <div
          className={classNames(classes.metric, classes.listItemFirstColumn)}
          onClick={() => {
            window.open(page.page, "_blank");
          }}
        >
          <Tooltip title={I18n.t("strategy.outlinePages.open_in_tab")} placement={"bottom-start"}>
            <Typography className={classes.firstColumn}>{getOpportunityPageUrl(page.page, true)}</Typography>
          </Tooltip>
        </div>
        <div className={classes.metric}>
          <Typography>{numeral(page.clicks || 0).format("0,0a")}</Typography>
        </div>
        <div className={classes.metric}>
          <Typography>{numeral(page.impressions || 0).format("0,0a")}</Typography>
        </div>
        <div className={classes.metric}>
          <Typography>{numeral(page.ctr || 0).format("0.0%")}</Typography>
        </div>
        <div className={classes.metric}>
          <Typography>{numeral(page.position || 0).format("0.0")}</Typography>
        </div>
      </ListItem>
    </div>
  );
};

export default PagesDialogListItem;
