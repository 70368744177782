import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { replace } from "connected-react-router";
import CfButton from "../../../components/CfButton";
import { HOME } from "../../../reducers/constants/actionTypes";
import agent from "../../../agent";
import AuthCard from "../../Authentication/components/AuthCard";
import { IAdminUser } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
  },
  credentialsWrapper: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    marginTop: 20,
    minWidth: 200,
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: theme.typography.fontWeightRegular as any,
  },
}));

type PropTypes = {
  loadSubUser: () => void;
  nav: (to: string) => void;
};
const mapDispatchToProps = (dispatch) => ({
  loadSubUser: (payload) => dispatch({ type: HOME.SUB_ADMIN_USER_LOADED }),

  nav: (to: string) => dispatch(replace(to)),
});
const LoginAs = (props: PropTypes) => {
  const classes = useStyles(props);

  const { loadSubUser } = props;

  const [user, setUser] = useState<IAdminUser | null>(null);

  const [users, setUsers] = useState<IAdminUser[]>([]);

  useEffect(() => {
    agent.Admin.getUsers()
      .then((res) => {
        setUsers(res.users);
      })
      .catch((e) => console.log(e));
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const onLogin = () => {
    if (!user) return null;

    agent.Auth.loginAs(user.email)
      .then(() => {
        loadSubUser();
        document.location.reload();
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  return (
    <div className={classes.contentWrapper}>
      <MHeaderMetaTags title={I18n.t(`meta_tags.legacy.login_as.title`)} />
      <AuthCard>
        <div className={classes.credentialsWrapper}>
          <MSelect
            options={users}
            value={user || null}
            optionLabel={"email"}
            searchable={true}
            optionValue={"_id"}
            placeholder={I18n.t("login.email_address_as")}
            height={40}
            borderRadius={0}
            borderWidth={"0"}
            handleChange={(user) => setUser(user)}
          />
        </div>
        <CfButton
          fullWidth
          customClass={classes.button}
          text={I18n.t("login.button_as")}
          onClick={(e) => {
            onLogin();
          }}
        />
      </AuthCard>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(LoginAs);
