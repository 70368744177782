import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles((theme: Theme) => ({
  snackBar: {
    minWidth: 320,
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    "& p": {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightRegular as any,
      color: theme.palette.text.primary,
      lineHeight: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& div": {
      display: "flex",
      alignItems: "center",
      gap: 24,
      "& p": {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold as any,
        color: theme.palette.primary.main,
        cursor: "pointer",
      },
      "& svg": {
        cursor: "pointer",
        color: theme.palette.text.primary,
        fontSize: 18,
      },
    },
  },
}));

type PropTypes = {
  id: string;
  message: string;
  duration?: number;
  onUndo?: () => void;
} & Record<string, any>;

const MUndoSnackbarContent = forwardRef((props: PropTypes, ref: any) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const { open, message, duration, id, onUndo } = props;

  React.useEffect(() => {
    setTimeout(() => {
      closeSnackbar(id);
    }, duration || 5000);
  }, [open]);

  const handleClose = () => {
    closeSnackbar(id);
  };

  return (
    <div ref={ref} className={classes.snackBar}>
      <Typography variant="body1">{message}</Typography>
      <div>
        <Typography
          variant="body1"
          onClick={() => {
            onUndo();
            handleClose();
          }}
        >
          {I18n.t("snackbar.undo")}
        </Typography>
        <Close onClick={handleClose} />
      </div>
    </div>
  );
});

export default MUndoSnackbarContent;
