import * as React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";

import moment from "moment";
import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableRowProgressBar from "../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../components/Table/Rows/MTableRowEmptyRow";
import MTable from "../../../components/Table/Table/MTable";
import FunnelsTableHeader from "./components/FunnelsTableHeader";
import FunnelsItemRow from "./components/FunnelsItemRow";
import numeral from "numeral";
import FunnelSidebar from "./components/FunnelSidebar/FunnelSidebar";
import { FUNNELS } from "../../../reducers/constants/actionTypes";
import MTableToolbarFunnelSelect from "./components/MTableToolbarFunnelSelect";
import MTableToolbarDivider from "../../../components/Table/Toolbar/MTableToolbarDivider";
import MTableToolbarBaseActionButton from "../../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import MActionsDropdown from "../../../components/MActionsDropdown";
import FunnelBaseSelect from "./components/FunnelBaseSelect";
import { isConfigValid } from "../../../helpers/funnelHelper";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  config: state.funnels.config,
  funnelId: state.funnels.funnelId,
  cvr: state.funnels.cvr,
  priority: state.funnels.priority,
  showValue: state.funnels.showValue,
  showTraffic: state.funnels.showTraffic,
});

const mapDispatchToProps = (dispatch) => ({
  updateConfig: (config) => dispatch({ type: FUNNELS.FUNNEL_CONFIG_UPDATED, payload: config }),
  updateFunnelName(payload) {
    dispatch({ type: FUNNELS.FUNNEL_FUNNEL_NAME_CHANGED, payload });
  },
  setEventNames(payload) {
    dispatch({ type: FUNNELS.FUNNEL_EVENT_NAMES_LOADED, payload });
  },
  updateFunnelDetails(payload) {
    dispatch({ type: FUNNELS.FUNNEL_DETAILS_LOADED, payload });
  },
  clearFunnels: () => dispatch({ type: FUNNELS.FUNNELS_CLEAR }),
  funnelsLoaded(payload) {
    dispatch({ type: FUNNELS.FUNNELS_LOADED, payload });
  },
  clearDynamicColumns() {
    dispatch({ type: FUNNELS.FUNNELS_DYNAMIC_COLUMNS_CLEANUP });
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    columnGap: 10,
    margin: "20px 20px 0px 0px",
    width: "100%",
  },
  tableWrapper: {
    margin: "0px 0px 0px 20px",
  },
  table: {},
  sideBarOpenTable: {
    width: "100%",
  },
  sidebarOpenInnerTable: {
    maxWidth: "calc(100vw - 420px)",
  },
  toolbarWrapper: {
    gap: 15,
  },
  helpTextWrapper: {
    display: "flex",
    columnGap: 5,
    alignItems: "center",
  },
  infoIcon: {
    height: 14,
    width: 14,
  },
  actionTitle: {
    margin: "0 5px",
  },
  helperText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}));

type PropTypes = {
  config: any;
  selectedWebsite: IWebsite;
  updateConfig: (config: any) => void;
  updateFunnelName: (payload) => void;
  setEventNames: (payload) => void;
  clearDynamicColumns: () => void;
  updateFunnelDetails: (payload) => void;
  clearFunnels: () => void;
  funnelsLoaded: (payload) => void;
  funnelId: string;
  cvr: { enabled: boolean; step1: string; step2: string };
  priority: {
    enabled: boolean;
    step: string;
    column: string;
  };
  showValue: boolean;
  showTraffic: boolean;
};

export type ReportsTableFilters = {
  report: {
    selected: { value: string; label: string };
    options: { value: string; label: string }[];
  };
  event: {
    selected: { value: string; label: string };
    options: { value: string; label: string }[];
  };
};

export type ReportsDataFilters = {
  month: {
    selected: { value: number; label: string };
    options: { value: number; label: string }[];
  };
  table: {
    selected: { value: number; label: string };
    options: { value: number; label: string }[];
  };
  column: {
    selected: { value: number; label: string };
    options: { value: number; label: string }[];
  };
};

const FunnelCreator = (props: PropTypes) => {
  const {
    selectedWebsite,
    config,
    updateFunnelName,
    setEventNames,
    updateConfig,
    updateFunnelDetails,
    clearFunnels,
    funnelsLoaded,
    funnelId,
    clearDynamicColumns,
    priority,
    cvr,
    showValue,
    showTraffic,
  } = props;
  const classes = useStyles(props);
  const [loaded, setLoaded] = React.useState(false);
  const [openFunnel, setOpenFunnel] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState<ReportsTableFilters>({
    report: {
      selected: { value: "pages", label: "Landing Pages" },
      options: [{ value: "pages", label: "Landing Pages" }],
    },
    event: {
      selected: { value: "funnel", label: "Funnel" },
      options: [{ value: "funnel", label: "Funnel" }],
    },
  });
  const [dataFilters, setDataFilters] = React.useState<ReportsDataFilters>({
    month: {
      selected: { value: 3, label: "Last 3 months" },
      options: [
        { value: 1, label: "Last month" },
        { value: 3, label: "Last 3 months" },
        { value: 6, label: "Last 6 months" },
        { value: 12, label: "Last 12 months" },
      ],
    },
    table: {
      selected: { value: 1, label: "Table" },
      options: [{ value: 1, label: "Table" }],
    },
    column: {
      selected: { value: 1, label: "Columns" },
      options: [{ value: 1, label: "Columns" }],
    },
  });
  const [sortField, setSortField] = useState<{ direction: "desc" | "asc" | undefined; field: string }>({
    direction: "desc",
    field: "page",
  });
  const [events, setEvents] = React.useState([]);

  const [eventsFiltered, setEventsFiltered] = React.useState([]);
  const [funnelMap, setFunnelMap] = React.useState({});
  const [totalPages, setTotalPages] = React.useState(0);
  const [pagesCount, setPagesCount] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState<number>(1);
  const [paginationLimit, setPaginationLimit] = useState(100);
  const [search, setSearch] = useState("");

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }

    if (!isConfigValid(config)) {
      return;
    }

    setLoaded(false);

    const startDate = moment().subtract(dataFilters.month.selected.value, "month").format("YYYY-MM-DD HH:MM");
    const endDate = moment().format("YYYY-MM-DD HH:MM");
    agent.Funnels.getFunnel(
      selectedWebsite,
      {
        ...config,
        startDate,
        endDate,
      },
      page,
      paginationLimit,
      search
    )
      .then((res) => {
        setEvents(res.data);
        setFunnelMap(res.map);
        setTotalPages(res.totalPages);
        setPagesCount(res.count);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        setEvents([]);
        enqueueSnackbar("Failed to load events", { variant: "error" });
        console.log("e", e);
      });
  }, [config, enqueueSnackbar, selectedWebsite, dataFilters, page, paginationLimit, search]);

  const clearAll = (clearEventNames = true) => {
    if (clearEventNames) {
      setEventNames([]);
      clearDynamicColumns();
    }
    setEvents([]);
    setFunnelMap([]);
    setTotalPages(0);

    clearFunnels();
  };
  React.useEffect(
    () => () => {
      clearAll();
    },
    []
  );
  const getFunnels = () => {
    agent.Funnels.getFunnels(selectedWebsite, 1).then((res) => {
      funnelsLoaded(res.data);
      if (res.data?.length > 0) {
        updateFunnelDetails({ ...res.data[0] });
      }
    });
  };
  const onFunnelDelete = () => {
    agent.Funnels.deleteFunnel(funnelId)
      .then(() => {
        enqueueSnackbar(I18n.t("analytics.funnels.popup_messages.funnel_deleted"));
        getFunnels();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };
  return (
    <div className={classes.pageWrapper}>
      <MHeaderMetaTags title={I18n.t(`meta_tags.cro.funnel_creator.title`)} />
      {openFunnel && (
        <FunnelSidebar
          onCancel={() => {
            setOpenFunnel(false);
          }}
        />
      )}
      {/* <FunnelsHeader */}
      {/*  setTableFilters={setTableFilters} */}
      {/*  tableFilters={tableFilters} */}
      {/*  setDataFilters={setDataFilters} */}
      {/*  dataFilters={dataFilters} */}
      {/*  loading={!loaded} */}
      {/* /> */}

      {/* <div className={classes.helpTextWrapper}> */}
      {/*  <InfoIcon className={classes.infoIcon} /> */}
      {/*  <Typography className={classes.helperText}>{I18n.t("analytics.funnels.helper_text")}</Typography> */}
      {/* </div> */}
      <MTableWrapper className={ClassNames(classes.tableWrapper, openFunnel && classes.sideBarOpenTable)}>
        <MTableToolbar
          className={classes.toolbarWrapper}
          searchText={search}
          handleSearchTextChanged={(text) => setSearch(text)}
          searchPlaceHolder={`Search ${numeral(pagesCount).format("0,0")} pages/topics`}
          actions={[
            <FunnelBaseSelect
              actionsMarginLeft={"-5px"}
              dynamicTextWidth
              search={false}
              flexDirection={"row"}
              optionValue={"value"}
              optionLabel={"label"}
              border={"none"}
              value={dataFilters.month.selected}
              options={dataFilters.month.options}
              handleOnChange={(v) => {
                setDataFilters({
                  ...dataFilters,
                  month: {
                    ...dataFilters.month,
                    selected: v,
                  },
                });
              }}
              placeholder={""}
            />,
            <MTableToolbarDivider />,
            <MTableToolbarBaseActionButton
              handleClick={() => {
                setOpenFunnel(true);
                clearAll(false);
                updateFunnelName(I18n.t("analytics.funnels.table.new_funnel"));
              }}
              text={I18n.t("analytics.funnels.table.new_funnel")}
            />,
            <MActionsDropdown
              stopPropagationOnOpen={false}
              iconVertical
              options={
                funnelId
                  ? [
                      {
                        label: I18n.t("common.edit"),
                        value: "0",
                        onClick: () => {
                          setOpenFunnel(true);
                        },
                      },
                      {
                        label: I18n.t("common.delete"),
                        value: "1",
                        onClick: () => {
                          onFunnelDelete();
                        },
                      },
                    ]
                  : []
              }
            />,
          ]}
          leftActions={[
            <MTableToolbarFunnelSelect
              clearOnError={() => {
                setEvents([]);
                setFunnelMap({});
                setTotalPages(0);
                setPagesCount(0);
              }}
            />,
          ]}
        />
        <MTable className={ClassNames(classes.table, openFunnel && classes.sidebarOpenInnerTable)}>
          {/* {loaded && eventsFiltered.length > 0 && ( */}
          <FunnelsTableHeader
            priority={priority}
            cvr={cvr}
            showValue={showValue}
            showTraffic={showTraffic}
            setSearchText={(val) => {
              setPage(1);
              setSearch(val);
            }}
            totalPageCount={pagesCount}
            hasBreakdown={!!config?.breakdown}
            hasStrategyValues={config?.breakdown?.field === "first_page" || config?.breakdown?.field === "url"}
            setLoaded={setLoaded}
            sortField={sortField}
            setSortField={setSortField}
            funnelMap={funnelMap}
            events={events}
            setEvents={setEventsFiltered}
          />
          {/* )} */}

          {!loaded && <MTableRowProgressBar />}
          {loaded && eventsFiltered.length === 0 && <MTableRowEmptyRow />}

          {loaded &&
            eventsFiltered.length > 0 &&
            eventsFiltered.map((item, index) => (
              <FunnelsItemRow
                priority={priority}
                cvr={cvr}
                showValue={showValue}
                showTraffic={showTraffic}
                hasBreakdown={!!config?.breakdown}
                hasStrategyValues={config?.breakdown?.field === "first_page" || config?.breakdown?.field === "url"}
                selectedWebsite={selectedWebsite}
                funnelMap={funnelMap}
                item={item}
                key={index}
              />
            ))}
        </MTable>
        {loaded && paginationLimit > 0 && (
          <MTablePagination
            paginationLimit={paginationLimit}
            page={page}
            paginationPagesCount={totalPages}
            setPaginationLimit={setPaginationLimit}
            setPage={setPage}
          />
        )}
      </MTableWrapper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FunnelCreator);
