import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { ePageState, IPage, IPageCluster, IWebsite } from "../../reducers/constants/objectTypes";
import { find } from "lodash";
import MSelect from "../../components/MSelect";
import { replace } from "connected-react-router";
import { web } from "../../helpers/urlHelper";
import useQuery from "../../hooks/useQuery";
import theme from "../../themes/theme";
import { STATE_COLORS } from "../../reducers/constants/consts";

const useStyles = makeStyles((theme: Theme) => ({
  selector: {},
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  cluster: IPageCluster;
  page: IPage;

  openPageEditor: (pageId: string, currentUrl) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  cluster: state.pageEditor.pageCluster,
  page: state.pageEditor.page,
});

const mapDispatchToProps = (dispatch) => ({
  openPageEditor: (pageId, currentUrl) => dispatch(replace(web.editPage(pageId, currentUrl))),
});

const PageEditorPageStatesDropDown = (props: PropTypes) => {
  const classes = useStyles(props);
  const queries = useQuery();
  const { selectedWebsite, page, cluster, openPageEditor } = props;

  const pages = React.useMemo(() => {
    return cluster.pages.filter(
      (p) => p.variant === page.variant && p.locale === page.locale && p.currentState.state !== ePageState.archived
    );
  }, [selectedWebsite, cluster, page]);

  const handlePageSelect = (page: IPage) => {
    openPageEditor(page._id, queries.get("currentUrl"));
  };

  const getSubStateName = (subState) => {
    for (const review of selectedWebsite.reviews) {
      if (review.reviewStepName === subState) {
        return review.reviewName;
      }
    }
    return subState;
  };

  return (
    <MSelect
      optionValue={"_id"}
      optionLabel={"title"}
      options={pages}
      borderColor={"transparent"}
      textColor={theme.palette.text.primary}
      placeholder={I18n.t("Pages.select_template_ph")}
      getDisplayLabel={(displayPage: IPage) => (
        <>
          <span style={{ color: STATE_COLORS[displayPage.currentState.state], marginRight: 5 }}>{`${I18n.t(
            `workflow.states.${displayPage.currentState.state}`
          )}:`}</span>
          {displayPage.currentState.subState && (
            <span style={{ marginRight: 5 }}>{`[${getSubStateName(displayPage.currentState.subState)}]:`}</span>
          )}
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {displayPage.title || "Missing Title !"}
          </span>
        </>
      )}
      customClass={classes.selector}
      handleChange={(newLocale) => {
        handlePageSelect(newLocale);
      }}
      value={find(pages, { _id: page._id })}
      height={24}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorPageStatesDropDown);
