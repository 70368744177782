import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
const useStyles = makeStyles((theme: Theme) => ({
  column: {
    flexGrow: 1,
    flexBasis: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: 10,
    fontSize: 12,
  },
  tooltip: {
    background: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
  },
}));

type PropTypes = {
  text: string | number;
  fontSize?: number | string;
  fontWeight?: number | string;
  columnWidth?: string | number;
  toolTipProps?: Partial<TooltipProps>;
  textColor?: string;
};

const MTableCellText = (props: PropTypes) => {
  const theme: any = useTheme();
  const classes = useStyles(props);
  const {
    text = "-",
    fontSize = 12,
    fontWeight = 600,
    columnWidth = undefined,
    textColor = undefined,
    toolTipProps = {},
  } = props;

  return (
    <Tooltip
      enterDelay={toolTipProps.enterDelay || 1000}
      title={toolTipProps.title || text}
      {...toolTipProps}
      classes={{ ...(toolTipProps.classes || {}), tooltip: ClassNames(classes.tooltip, toolTipProps.classes?.tooltip) }}
    >
      <div
        className={classes.column}
        style={{
          fontSize,
          fontWeight,
          color: toolTipProps.title ? textColor || theme.palette.primary.main : "inherit",
          flexGrow: columnWidth ? "unset" : 1,
          flexBasis: columnWidth ? "unset" : 0,
          width: columnWidth,
        }}
      >
        <span>{text}</span>
      </div>
    </Tooltip>
  );
};

export default MTableCellText;
