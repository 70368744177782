import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { replace } from "connected-react-router";
import { useParams } from "react-router";
import { delay } from "lodash";
import agent from "../../../../../../agent";
import { COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import { eQuestionState, IQuestion, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import MButtonGroup from "../../../../../../components/MButtonGroup";
import { AbilityContext } from "../../../../../../casl/can";
import { clientPages, web } from "../../../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  postBottomBarWrapper: {
    zIndex: 3,
    height: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: theme.palette.common.white,
    borderTop: `${theme.palette.divider} solid 1px`,
    position: "sticky",
    bottom: 0,
  },
  postBottomBarInnerWrapper: {
    // width: 'calc(100% - 2*280px)',
    width: "100%",
    maxWidth: 1280,
    paddingRight: 20,
    paddingLeft: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    minHeight: 40,
  },
  buttonGroup: {
    margin: "0 -10px 0 -5px",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedQuestion: IQuestion;

  onQuestionsSaved: Function;
  returnToPrevious: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  selectedQuestion: state.communityEditor.question,
});

const mapDispatchToProps = (dispatch) => ({
  onQuestionsSaved: (question) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_QUESTION_SAVED, question }),
  returnToPrevious: (returnTo) => dispatch(replace(returnTo)),
});

const CommunityEditorBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedQuestion } = props;
  const { onQuestionsSaved, returnToPrevious, selectedWebsite } = props;
  const { type } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const ability = React.useContext<any>(AbilityContext);

  const validateQuestion = () => {
    if (!selectedQuestion.title || selectedQuestion.title.length === 0) {
      return { valid: false, message: "Question title is missing" };
    }
    if (!selectedQuestion.postedTime) {
      return { valid: false, message: "Posted time is missing" };
    }
    // if (! selectedQuestion.user) {
    //   return {valid: false, message: 'User is missing'};
    // }
    return { valid: true };
  };
  const emptyRichText = JSON.stringify([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  const validateAnswers = () => {
    for (let i = 0; i < selectedQuestion.answers.length; i += 1) {
      if (!selectedQuestion.answers[i].richText || selectedQuestion.answers[i].richText === emptyRichText) {
        return { valid: false, message: `Answer ${i + 1} is missing` };
      }
      if (!selectedQuestion.answers[i].user && !selectedQuestion.answers[i].creditedWriter) {
        return { valid: false, message: `Answer ${i + 1} user is missing` };
      }
      if (!selectedQuestion.answers[i].postedTime) {
        return { valid: false, message: `Answer ${i + 1} Posted time is missing` };
      }
    }
    return { valid: true };
  };

  const valid = () => {
    if (!selectedQuestion.category) {
      alert("Question category is missing");
      return false;
    }
    const validQuestion = validateQuestion();
    console.log("validQuestion", validQuestion);
    if (!validQuestion.valid) {
      alert(validQuestion.message);
      return false;
    }
    const validAnswers = validateAnswers();
    console.log("validAnswers", validAnswers);
    if (!validAnswers.valid) {
      alert(validAnswers.message);
      return false;
    }
    return true;
  };

  const invalidateCache = (question) => {
    const questionUrl = clientPages.questionsPage(selectedWebsite, question);
    const url = clientPages.withCacheInvalidation(selectedWebsite, questionUrl);
    if (selectedQuestion.state === eQuestionState.PUBLISHED) {
      agent.Cache.questionInvalidation(selectedWebsite, question)
        .then((valRes) => {
          delay(() => {
            window.open(url, "_blank");
          }, 3000);
        })
        .catch((e) => {
          enqueueSnackbar(I18n.t("snackbar.skipping_invalidation"));
          window.open(url, "_blank");
        });
    }
  };

  const saveQuestionV2 = (question) => {
    agent.Community.updateV2(question)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(I18n.t("snackbar.questions_saved_error", { msg: res.message }));
          return;
        }
        invalidateCache(res.question);
        onQuestionsSaved(res.question);
        enqueueSnackbar(I18n.t("snackbar.questions_saved_success"));
        returnToPrevious(web.community(type));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.questions_saved_error", { msg: e.msg }));
      });
  };

  const saveQuestion = (question) => {
    if (ability.can("view", "community_v2")) {
      saveQuestionV2(question);
      return;
    }
    console.log("save question");
    agent.Community.update(question)
      .then((res) => {
        enqueueSnackbar(I18n.t("snackbar.questions_saved_success"));
        invalidateCache(res.question);
        onQuestionsSaved(res.question);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.questions_saved_error", { msg: e.msg }));
      })
      .finally(() => {
        returnToPrevious(web.community(type));
      });
  };

  const onUpdateClicked = (state) => {
    if (!valid()) {
      return;
    }
    saveQuestion({ ...selectedQuestion, state });
  };

  const onDeleteClicked = () => {
    saveQuestion({ ...selectedQuestion, state: eQuestionState.DELETED });
  };

  return (
    <div className={classes.postBottomBarWrapper}>
      <div className={classes.postBottomBarInnerWrapper}>
        <div className={classes.flexWrapper}>
          {/* NEW POST */}
          {selectedQuestion.state === eQuestionState.NEW && (
            <MButtonGroup
              text={I18n.t("forum.save_draft")}
              hasDivider={false}
              customClass={classes.buttonGroup}
              onClick={(e) => onUpdateClicked(eQuestionState.DRAFT)}
              options={[
                {
                  key: "save_draft",
                  label: I18n.t("forum.save_draft"),
                },
                {
                  key: "publish",
                  label: I18n.t("forum.publish"),
                },
                {
                  key: "delete",
                  label: I18n.t("forum.delete"),
                },
              ]}
              onMenuItemClick={(key: string) => {
                switch (key) {
                  case "save_draft":
                    // save draft
                    onUpdateClicked(eQuestionState.DRAFT);
                    break;
                  case "publish":
                    // publish
                    onUpdateClicked(eQuestionState.PUBLISH);
                    break;
                  case "delete":
                    // delete
                    onDeleteClicked();
                    break;
                  default:
                    break;
                }
              }}
            />
          )}

          {/* PUBLISHED POST */}
          {selectedQuestion.state === eQuestionState.PUBLISHED && (
            <MButtonGroup
              text={I18n.t("forum.update")}
              hasDivider={false}
              customClass={classes.buttonGroup}
              onClick={(e) => onUpdateClicked(eQuestionState.PUBLISH)}
              options={[
                {
                  key: "update",
                  label: I18n.t("forum.update"),
                },
                {
                  key: "delete",
                  label: I18n.t("forum.delete"),
                },
              ]}
              onMenuItemClick={(key: string) => {
                switch (key) {
                  case "update":
                    // update
                    onUpdateClicked(eQuestionState.PUBLISH);
                    break;
                  case "delete":
                    // delete
                    onDeleteClicked();
                    break;
                  default:
                    break;
                }
              }}
            />
          )}
          {/* DRAFT OR DELETED POST */}
          {(selectedQuestion.state === eQuestionState.DRAFT ||
            selectedQuestion.state === eQuestionState.DELETED ||
            selectedQuestion.state === eQuestionState.PUBLISH) && (
            <MButtonGroup
              text={I18n.t("forum.save_draft")}
              hasDivider={false}
              customClass={classes.buttonGroup}
              onClick={(e) => onUpdateClicked(eQuestionState.DRAFT)}
              options={[
                {
                  key: "save_draft",
                  label: I18n.t("forum.save_draft"),
                },
                {
                  key: "publish",
                  label: I18n.t("forum.publish"),
                },
                {
                  key: "delete",
                  label: I18n.t("forum.delete"),
                },
              ]}
              onMenuItemClick={(key: string) => {
                switch (key) {
                  case "save_draft":
                    // save draft
                    onUpdateClicked(eQuestionState.DRAFT);
                    break;
                  case "publish":
                    // publish
                    onUpdateClicked(eQuestionState.PUBLISH);
                    break;
                  case "delete":
                    // delete
                    onDeleteClicked();
                    break;
                  default:
                    break;
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorBottomBar);
