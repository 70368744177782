import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import { useState } from "react";
import { find } from "lodash";
import PageSection from "../../components/PageSection";
import {
  IWebsite,
  IWebsiteCategoryProps,
  IWebsiteSectionProps,
  SNWebsiteAutoCompleteMetaTag,
} from "../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import TextInputMentions from "../../components/TextInputMentions";
import { LENGTHS } from "../../../../reducers/constants/consts";
import TextDesignToolWithPreview from "../../components/TextDesignToolWithPreview";
import MRouterPrompt from "../../components/MRouterPrompt";
import CustomUrlPrefixTextField from "../../components/CustomUrlPrefixTextField";

const useStyles = makeStyles((theme: Theme) => ({
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  mentionWrapper: {
    // marginTop: 5,
    // marginBottom: 5,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

const mapStateToProps = (state) => ({
  website: state.home.selectedWebsite,
  category: state.websitePages.category,
  sections: state.websitePages.sections,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (category) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "category",
      value: category,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  website: IWebsite;
  category: IWebsiteCategoryProps;
  sections: IWebsiteSectionProps;
  onPropertyChanged: (category) => void;
  handleToggleActive: (section, enabled) => void;
};

const PageCategory = (props: PropTypes) => {
  const classes = useStyles(props);
  const { sections, website, category, onPropertyChanged, handleToggleActive } = props;
  const [error, setError] = React.useState(false);
  const [emptyFields, setEmptyFields] = React.useState([]);
  const [locale, setLocale] = useState(website?.configurations.locale.defaultLocale || null);
  const { defaultLocale } = website?.configurations.locale;

  const textFieldsValidated = () => {
    const valid = true;
    // setEmptyFields([]);
    // const emptyFields = [];
    // if (!blog.name) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.name`));
    // }
    // if (!blog.mainPage.slug) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.slug_title`));
    // }
    // if (!blog.mainPage.metaTitle) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.meta_title`));
    // }
    // if (!blog.mainPage.metaDescription) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.meta_description`));
    // }
    // if (!blog.mainPage.title.text) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.title`));
    // }
    // if (!blog.mainPage.description.text) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.description`));
    // }
    // if (!blog.categoryPage.slug) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.blog.category_page_slug`));
    // }
    // if (!blog.categoryPage.metaTitle) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.blog.category_page_meta_title`));
    // }
    // if (!blog.categoryPage.metaDescription) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.blog.category_page_meta_description`));
    // }
    // if (!blog.postPage.metaTitle) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.blog.post_page_meta_title`));
    // }
    // if (!blog.postPage.metaDescription) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.blog.post_page_meta_description`));
    // }
    // setEmptyFields(emptyFields);
    return valid;
  };

  return (
    <>
      <PageSection
        website={website}
        locale={locale}
        handleLocaleChange={setLocale}
        checked={sections.category}
        onCheckedChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.category) {
              handleToggleActive("category", enabled);
            }
            setError(true);
          } else {
            setError(false);
            handleToggleActive("category", enabled);
          }
        }}
        sectionTitle={I18n.t("website.category.name")}
      >
        <MRouterPrompt />
        <CustomUrlPrefixTextField
          marginBottom={30}
          previewUrls={[{ key: "website.category.page_preview_url", value: `${category.slug}/category-slug` }]}
          handleValueChange={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...category, urlPrefix: value });
              return;
            }
            category.translations[locale] = {
              ...(category.translations[locale] || {}),
              urlPrefix: value,
            };
            onPropertyChanged({ ...category });
          }}
          customUrlPrefix={category.urlPrefix}
          locale={locale}
          alternativeUrlPrefix={category.translations?.[locale]?.urlPrefix}
        />
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            value={locale === defaultLocale ? category.name : category.translations[locale]?.name || ""}
            placeholder={I18n.t("website.name_ph")}
            title={I18n.t("website.name")}
            height={37}
            borderRadius={4}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...category, name: value });
                return;
              }
              category.translations[locale] = {
                ...(category.translations[locale] || {}),
                name: value,
              };
              onPropertyChanged({ ...category });
            }}
          />

          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            value={locale === defaultLocale ? category.slug : category.translations[locale]?.slug || ""}
            placeholder={I18n.t("website.slug_title_ph")}
            title={I18n.t("website.slug_title")}
            height={37}
            borderRadius={4}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...category, slug: value });
                return;
              }
              category.translations[locale] = {
                ...(category.translations[locale] || {}),
                slug: value,
              };
              onPropertyChanged({ ...category });
            }}
          />
        </div>
        <Divider className={classes.divider} />

        <TextDesignToolWithPreview
          topTitle
          disableHover
          marginRight={"-30px"}
          marginLeft={"-30px"}
          elementTitle={I18n.t("website.element_design", { element: I18n.t("website.title") })}
          css={category.title?.css}
          variant={"h1"}
          exampleText={I18n.t("website.category.example_title_text")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...category, title: { ...category.title, css: newCss } });
          }}
        />

        <TextInputMentions
          key={`category_meta_title_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.categoryName]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={locale === defaultLocale ? category.metaTitle : category.translations[locale]?.metaTitle || ""}
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...category, metaTitle: value });
              return;
            }
            category.translations[locale] = {
              ...(category.translations[locale] || {}),
              metaTitle: value,
            };
            onPropertyChanged({ ...category });
          }}
        />

        <TextInputMentions
          key={`category_meta_desc_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.categoryName, SNWebsiteAutoCompleteMetaTag.categoryDescription]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale ? category.metaDescription : category.translations[locale]?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...category, metaDescription: value });
              return;
            }
            category.translations[locale] = {
              ...(category.translations[locale] || {}),
              metaDescription: value,
            };
            onPropertyChanged({ ...category });
          }}
        />
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCategory);
