import * as React from "react";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Variant } from "@material-ui/core/styles/createTypography";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { ArrowDownward, ArrowDropDown, ArrowDropUp, ArrowUpward } from "@material-ui/icons";
import { TableSortLabel } from "@material-ui/core";
import theme from "../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  icon: { color: `${theme.palette.text.primary} !important` },
}));

type PropTypes = {
  className: string;
  text: string;
  variant: Variant;
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  reverse?: boolean;
  field: string;
  onClick: Function;
};

const MSortLabel = (props: PropTypes) => {
  const { text, className, reverse, variant, sortField, onClick, field } = props;
  const classes = useStyles(props);
  return (
    <Typography className={ClassNames(classes.wrapper, className)} variant={variant}>
      <TableSortLabel
        key={field}
        disabled={sortField === undefined}
        classes={{ icon: classes.icon }}
        active={field === sortField?.field}
        direction={field === sortField?.field ? sortField.direction : reverse ? "desc" : "asc"}
        onClick={() => {
          let direction = field === sortField?.field && sortField?.direction === "asc" ? "desc" : "asc";

          if (reverse) {
            direction = field === sortField?.field && sortField?.direction === "desc" ? "asc" : "desc";
          }

          onClick({
            field,
            direction,
          });
        }}
      >
        {text}
      </TableSortLabel>
    </Typography>
  );
};

export default MSortLabel;
