import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#fff",
    fontSize: 16,
  },
}));

type PropTypes = {
  className?: string;
};

const UnderlineIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 13 15"}>
      <path d="M6.83594 11.6387C5.37745 11.6387 4.15724 11.1625 3.17529 10.2101C2.19334 9.2577 1.70236 8.07423 1.70236 6.65966V0.987395C1.70236 0.707283 1.80345 0.472549 2.00561 0.283193C2.20778 0.0943977 2.44605 0 2.72041 0C3.00922 0 3.25125 0.0943977 3.44648 0.283193C3.64114 0.472549 3.73847 0.707283 3.73847 0.987395V6.65966C3.73847 7.52801 4.03103 8.24566 4.61615 8.81261C5.2007 9.38011 5.94063 9.66387 6.83594 9.66387C7.73125 9.66387 8.47117 9.38011 9.05572 8.81261C9.64085 8.24566 9.93341 7.52801 9.93341 6.65966V0.987395C9.93341 0.707283 10.0345 0.472549 10.2367 0.283193C10.4388 0.0943977 10.6771 0 10.9515 0C11.2403 0 11.482 0.0943977 11.6767 0.283193C11.8719 0.472549 11.9695 0.707283 11.9695 0.987395V6.65966C11.9695 8.07423 11.4785 9.2577 10.4966 10.2101C9.51464 11.1625 8.29442 11.6387 6.83594 11.6387ZM1.65904 15C1.428 15 1.23305 14.923 1.0742 14.7689C0.91536 14.6148 0.835938 14.4258 0.835938 14.2017C0.835938 13.9916 0.91536 13.8095 1.0742 13.6555C1.23305 13.5014 1.428 13.4244 1.65904 13.4244H12.0345C12.2511 13.4244 12.4388 13.5014 12.5977 13.6555C12.7565 13.8095 12.8359 13.9916 12.8359 14.2017C12.8359 14.4258 12.7565 14.6148 12.5977 14.7689C12.4388 14.923 12.2511 15 12.0345 15H1.65904Z" />
    </SvgIcon>
  );
};

export default UnderlineIcon;
