import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import { I18n } from "react-redux-i18n";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CopyNameIcon from "../../../../../icons/CopyNameIcon";
import CopyToClipboardIcon from "../../../../../icons/CopyToClipboardIcon";
import { IKeywordSerpCleaned } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  group: {
    "&:hover": {
      backgroundColor: "#E6EEFF",
      borderRadius: 30,
      transition: "border-radius 100ms",
      "& $serpTitle": {
        transition: "color 200ms",
        color: theme.palette.primary.main,
      },
      "& $serpUrl": {
        transition: "color 200ms",
        color: theme.palette.primary.main,
      },
      "& $actionsWrapper": {
        display: "flex",
      },
      position: "relative",
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actionsWrapper: {
    gap: 20,
    alignItems: "center",
    display: "none",
    position: "absolute",
    zIndex: 2,
    left: "40%",
    width: "fit-content",
    backgroundColor: "#E6EEFF",
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionIcon: {
    height: 18,
    width: 18,
    fill: theme.palette.text.secondary,
  },
  iconBtn: {
    padding: 0,
  },
  serpRowTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  metric: {
    fontSize: "14px",
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: "100%",
    width: "10%",
  },
  trafficMetric: {
    marginRight: "5px",
    fontSize: "14px",
  },
  trafficMetricLeft: {
    marginLeft: "5px",
    fontSize: "14px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  number: {
    width: 20,
    height: 20,
    backgroundColor: "lightblue",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    fontWeight: theme.typography.fontWeightBold as any,
    fontsize: 14,
    lineHeight: "16.8px",
  },
  serpTitle: {
    fontSize: 14,
    fontWeight: 600,

    color: theme.palette.text.primary,
  },
  serpUrl: {
    fontSize: 12,

    color: theme.palette.text.secondary,
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    padding: "12px 30px",
    justifyContent: "space-between",
    fontSize: "14px",
  },
  listItemFirstColumn: {
    width: "55%",
  },
  firstColumn: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: "14px",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "color .2s ease-in-out",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  postTitleField: {
    color: theme.palette.text.secondary,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: "1.5rem",
    height: "1.5rem",
    fontFamily: theme.typography.body2.fontFamily,
    cursor: "initial",
  },
}));

type PropTypes = {
  serp: IKeywordSerpCleaned;
};

const SerpDialogListItem = (props: PropTypes) => {
  const { serp } = props;
  const classes = useStyles(props);

  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return () => {
      navigator.clipboard.writeText(serp.title);
      setShowTitleCopyTooltip(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setShowTitleCopyTooltip(false);
      }, 1000);
    };
  })();

  return (
    <div className={classes.group}>
      <ListItem className={classNames(classes.listRow)}>
        <div
          className={classNames(classes.metric, classes.listItemFirstColumn)}
          onClick={(e) => {
            e.stopPropagation();
            window.open(serp.url, "_blank");
          }}
        >
          <div className={classes.serpRowTitle}>
            <Typography className={classes.serpTitle}>{serp.title}</Typography>
            <Typography className={classes.serpUrl}>{serp.url}</Typography>
          </div>
        </div>
        <div className={classNames(classes.actionsWrapper)}>
          <Tooltip
            className={classes.actionIconWrapper}
            arrow
            onClick={(e) => {
              e.stopPropagation();
              window.open(serp.url, "_blank");
            }}
            title={I18n.t("outline.icons.open_in_new_tab")}
          >
            <div>
              <OpenInNewIcon className={classes.actionIcon} />
            </div>
          </Tooltip>
          <Tooltip
            arrow
            title={showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title")}
            className={classes.actionIconWrapper}
          >
            <div>
              <CopyToClipboardIcon
                className={classNames(classes.actionIcon)}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTitleCopy();
                }}
              />
            </div>
          </Tooltip>
        </div>
      </ListItem>
    </div>
  );
};

export default SerpDialogListItem;
