import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { IAnalyticsEventRow, IGoal, IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import MTableRow from "../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../components/Table/Cells/MTableCellFirst";
import MTableCellDate from "../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../components/Table/Cells/MTableCellText";
import { web } from "../../../helpers/urlHelper";
import MTableCellLink from "../../../components/Table/Cells/MTableCellLink";
import localeHelper from "../../../helpers/localeHelper";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
  },
  avatarWrapper: {
    backgroundColor: theme.palette.primary.main,
    height: 25,
    width: 25,
    borderRadius: 100,
    marginRight: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pagesTooltip: {
    maxHeight: 200,
    maxWidth: 450,
    padding: 10,
    background: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    margin: 0,
    marginLeft: "-10px",
    marginTop: 3,
    overflow: "auto",
  },
  imageTooltip: {
    // maxHeight: 200,
    maxWidth: 600,
    width: "100%",
    padding: 10,
    background: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    margin: 0,
    marginLeft: "-10px",
    marginTop: 3,
    overflow: "auto",
  },
  analyticsTooltip: {
    maxHeight: 300,
    maxWidth: 800,
    padding: 10,
    fontWeight: theme.typography.fontWeightRegular as any,
    background: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    margin: 0,
    marginLeft: "-10px",
    marginTop: 3,
    overflow: "auto",
  },
  pageItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 3,
  },
  editPageContainer: {
    display: "flex",
  },
  divider: {
    color: theme.palette.text.primary,
    padding: "2px 5px",
  },
  pageTitle: {
    fontSize: 14,
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  editPageWrapper: {
    flexGrow: 1,
    flexShrink: 0,
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      "& $editIcon": {
        fill: theme.palette.primary.main,
      },
      "& $pageTitle": {
        color: theme.palette.primary.main,
      },
    },
  },
  editIcon: {
    fontSize: 15,
    fill: theme.palette.text.primary,
  },
  linkIcon: {
    fontSize: 14,
    marginLeft: 5,
  },
  widgetImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    // background: "cyan",
  },
  hoveredCell: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type PropTypes = {
  event: IAnalyticsEventRow;
  selectedWebsite: IWebsite;
  goal: IGoal;

  timePeriod: any;
  handleClick: (id: string) => void;
};

const GoalEventListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { event, goal, timePeriod, selectedWebsite } = props;
  const { handleClick } = props;
  const theme = useTheme();

  // const options = useMemo(() => {
  //   const actionsArr = [];
  //
  //   if (widget.status !== "Deleted") {
  //     actionsArr.push({
  //       value: eWidgetAction.delete,
  //       label: I18n.t("widgets_builder.actions.delete"),
  //       onClick: () => deleteWidget(widget._id),
  //     });
  //   }
  //
  //   if (widget.status === "Deleted") {
  //     actionsArr.push({
  //       value: eWidgetAction.restore,
  //       label: I18n.t("widgets_builder.actions.restore"),
  //       onClick: () => restoreWidget(widget),
  //     });
  //     actionsArr.push({
  //       value: eWidgetAction.delete,
  //       label: I18n.t("widgets_builder.actions.delete_permanently"),
  //       onClick: () => deleteWidget(widget._id),
  //     });
  //   }
  //
  //   if (duplicateWidget) {
  //     actionsArr.push({
  //       value: eWidgetAction.duplicate,
  //       label: I18n.t("widgets_builder.actions.duplicate"),
  //       onClick: () => duplicateWidget(widget),
  //     });
  //   }
  //
  //   return actionsArr;
  // }, [deleteWidget, duplicateWidget, restoreWidget, widget]);

  return (
    <MTableRow
      handleRowClick={() => {
        handleClick(event.id);
      }}
    >
      <MTableCellFirst
        fontSize={theme.typography.pxToRem(14)}
        columnWidth={"30%"}
        cellText={goal?.name}
        avatarType={goal?.name}
        actions={[]}
      />
      <MTableCellText
        text={localeHelper.getCurrencyText(parseFloat(goal?.value?.value || "0"), goal?.value?.currency?.code || "USD")}
      />
      <MTableCellText text={"-"} />
      <MTableCellText text={"-"} />
      <MTableCellDate date={event.created_at} />
      <MTableCellLink href={web.croUserEvents(event.device_id)} text={event.device_id} />
      <MTableCellText text={localeHelper.getCountryFromCountryCode(event.country) || "-"} />
      <MTableCellLink
        href={event.initial_referrer}
        text={event?.initial_referrer?.replace(selectedWebsite.url, "") || "-"}
      />
      <MTableCellLink href={event.first_page} text={event?.first_page?.replace(selectedWebsite.url, "") || "-"} />
    </MTableRow>
  );
};

export default GoalEventListItem;
