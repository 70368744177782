"use strict";
import * as React from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({}));

type propTypes = {
  className?: string;
  style?: object;
};

function VIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className, style } = props;
  return (
    <SvgIcon className={className} style={style} viewBox={"0 0 15 12"}>
      <path d="M6.0315 10.9482C5.46509 11.5258 4.53491 11.5258 3.9685 10.9482L0.867167 7.78596C0.385538 7.29487 0.385539 6.50864 0.867168 6.01755C1.36223 5.51275 2.17526 5.51275 2.67033 6.01755L5 8.39299L12.3297 0.919297C12.8247 0.414502 13.6378 0.414503 14.1328 0.919297C14.6145 1.41039 14.6145 2.19662 14.1328 2.68771L6.0315 10.9482Z" />
    </SvgIcon>
  );
}

export default VIcon;
