import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  rowWrapper: {
    display: "flex",
    padding: "0 20px",
    fontSize: theme.typography.pxToRem(12),
    height: 53,
    [`&:hover .row-actions-wrapper`]: {
      // don't remove! - show actions on row hover
      display: "flex",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      transition: "border-radius 500ms",
      marginTop: -1,
      marginBottom: 1,
      paddingTop: 1,
      "& $rowInnerWrapperSelected": {
        paddingTop: 1,
        "&:after": {
          content: "''",
          position: "absolute",
          bottom: 0,
          left: 60,
          right: 20,
          width: "calc(100% - 80px)",
          borderBottom: `1px solid transparent`,
        },
      },
      "& $rowInnerWrapperSelectedFullWidth": {
        borderBottom: "none",
      },
    },
  },
  rowInnerWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 5,
    cursor: "pointer",
  },
  rowInnerWrapperSelected: {
    "&:after": {
      content: "''",
      position: "absolute",
      marginBottom: "-1px",
      bottom: 0,
      left: 60,
      right: 20,
      width: "calc(100% - 80px)",
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  rowInnerWrapperSelectedFullWidth: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  rowTransparentBorder: {
    borderColor: "transparent",
    "&:after": {
      borderColor: "transparent",
    },
  },
  selected: {
    backgroundColor: theme.palette.action.hover,
  },
}));

type PropTypes = {
  children: string | React.ReactNode;
  handleRowClick: (e: any) => void;
  customClassName?: string;
  customInnerWrapperClassName?: string;
  selected?: boolean;
  fullBorderWidth?: boolean;
  showBorder?: boolean;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
};

const MTableRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    children,
    customClassName,
    customInnerWrapperClassName,
    handleRowClick,
    selected,
    fullBorderWidth = false,
    showBorder = true,
    handleMouseLeave,
    handleMouseEnter,
  } = props;

  return (
    <ListItem
      onClick={handleRowClick}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      className={ClassNames(classes.rowWrapper, customClassName, selected && classes.selected)}
    >
      <div
        className={ClassNames(
          classes.rowInnerWrapper,
          fullBorderWidth && classes.rowInnerWrapperSelectedFullWidth,
          !fullBorderWidth && classes.rowInnerWrapperSelected,
          !showBorder && classes.rowTransparentBorder,
          customInnerWrapperClassName
        )}
      >
        {children}
      </div>
    </ListItem>
  );
};

export default MTableRow;
