import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { BlockQuote } from "../../types/editor.Types";
import SwitchBaseComponent from "../../elementsStyles/BaseComponents/SwitchBaseComponent";
import TextFieldProperty from "../../../containers/CRO/pages/WidgetsBuilder/FormWidgetEditor/build/items/itemProperties/components/TextFieldProperty";
import StylePanelSection from "../../elementsStyles/components/StylePanelSection";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  detailsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  container: {
    padding: 15,
    display: "grid",
    gap: 20,
  },
  textFieldWrapper: {
    backgroundColor: theme.palette.secondary.main,
  },
  inputLabel: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 14,
  },
}));

type PropTypes = {
  editor: Editor;
  element: BlockQuote;
};

const BlockQuoteElementStyle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, element } = props;
  const handleElementDataChange = (data) => {
    console.log("PageEditorBlockQuoteElement: handleElementDataChange", data);
    Transforms.setNodes(editor, data, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.quote_element.name")} editor={editor}>
      <div className={classes.container}>
        <SwitchBaseComponent
          defaultValue={false}
          value={element.showDetails}
          title={I18n.t("rich_text_editor.quote_element.show_details")}
          handleChange={(value) => {
            handleElementDataChange({ ...element, showDetails: value });
          }}
        />
        {element.showDetails && (
          <div className={classes.detailsWrapper}>
            <div className={classes.row}>
              <Typography className={classes.inputLabel} variant={"subtitle2"}>
                {I18n.t("rich_text_editor.quote_element.name_title")}
              </Typography>
            </div>
            <TextFieldProperty
              placeholder={I18n.t("rich_text_editor.quote_element.name_title_ph")}
              value={element.name}
              onChange={(newValue) => handleElementDataChange({ ...element, name: newValue })}
              margin={"0 0 10px 0"}
              borderRadius={5}
              classes={{
                textFieldWrapper: classes.textFieldWrapper,
              }}
            />
            <div className={classes.row}>
              <Typography className={classes.inputLabel} variant={"subtitle2"}>
                {I18n.t("rich_text_editor.quote_element.job_title")}
              </Typography>
            </div>
            <TextFieldProperty
              placeholder={I18n.t("rich_text_editor.quote_element.job_title_ph")}
              value={element.jobTitle}
              onChange={(newValue) => handleElementDataChange({ ...element, jobTitle: newValue })}
              margin={"0 0 10px 0"}
              borderRadius={5}
              classes={{
                textFieldWrapper: classes.textFieldWrapper,
              }}
            />
          </div>
        )}
      </div>
    </StylePanelSection>
  );
};

export default BlockQuoteElementStyle;
