import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Switch, TextField, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import ClassNames from "classnames";
import { flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { KEYWORDTYPES } from "../../../../../reducers/constants/actionTypes";
import InsightsTableCheckBox from "../../../../CRO/Inssights/Components/table/InsightsTableCheckBox";
import theme from "../../../../../themes/theme";
import KeywordTypesPagination from "./KeywordTypesPagination";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import MSortLabel from "../../../../../components/MSortLabel";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 20,
    height: "100%",
  },
  tableStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerWrapStyle: {
    width: "100%",
    padding: "12px 10px 20px",
  },
  headerRowStyle: {
    display: "flex",
    width: "100%",
  },
  tableBodyStyle: {},
  tableBodyRowStyle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "12px 10px 20px",
    height: 53,
    borderBottom: `solid 1px ${theme.palette.divider}`,

    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& $editInput": {
        display: "block",
      },
      "& $editInput2": {
        display: "block",
      },
      "& $textInput": {
        display: "none",
      },
      "& $textInput2": {
        display: "none",
      },
    },
  },

  theadSecond: {
    width: "25%",
    textAlign: "left",
    alignSelf: "center",
    height: "19px",
  },
  tdataSecond: {
    width: "25%",
    fontSize: 19,
  },
  theadThird: {
    alignSelf: "center",
    height: 20,
    textAlign: "left",
    fontSize: 16,
    width: "50%",
  },
  tdataThird: {
    width: "50%",
  },
  tdataFour: {
    width: "20%",
    display: "flex",
    justifyContent: "end",
  },
  editInput: {
    outline: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 2,
    display: "none",
    "& > :first-child": {
      width: "100%",
    },
  },
  editInput2: {
    outline: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 2,
    display: "none",
    "& > :first-child": {
      width: "100%",
    },
    marginLeft: "5px",
  },
  textInput: {
    maxWidth: "250px",
    fontSize: 14,
    paddingLeft: 3,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textInput2: {
    maxWidth: "500px",
    fontSize: 14,
    paddingLeft: 3,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  input: {
    fontSize: 14,
    paddingLeft: 3,
  },
  input2: {
    fontSize: 14,
    paddingLeft: 3,
  },
  headerColor: {
    color: theme.palette.text.primary,
  },
  checkboxAll: {
    paddingTop: 10,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  data: any[];
  page: number;
  totalPages: number;
  pageSize: number;
  searchText: string;
  rowSelected: [];

  keywordTypes: any;

  handleDataLoaded: (payload) => void;

  handleOnPageChanged: (page: number) => void;
  handleOnPageSizeChanged: (pageSize: number) => void;
  handleOnRowSelectedChanged: (rowSelected: any) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  data: state.keywordTypes.data,
  searchText: state.keywordTypes.searchText,
  page: state.keywordTypes.page,
  totalPages: state.keywordTypes.totalPages,
  pageSize: state.keywordTypes.pageSize,
  rowSelected: state.keywordTypes.rowSelected,
});

const mapDispatchToProps = (dispatch) => ({
  handleDataLoaded: (payload) => {
    dispatch({ type: KEYWORDTYPES.KEYWORDTYPES_LOADED, payload });
  },
  handleOnPageChanged: (page) => dispatch({ type: KEYWORDTYPES.ON_PAGE_CHANGED, page }),
  handleOnPageSizeChanged: (pageSize) => dispatch({ type: KEYWORDTYPES.ON_PAGESIZE_CHANGED, pageSize }),
  handleOnRowSelectedChanged: (rowSelected) => dispatch({ type: KEYWORDTYPES.ON_ROWSELECTED_CHANGED, rowSelected }),
});

const KeywordTypesList = (props: PropTypes) => {
  const classes = useStyles();
  const { selectedWebsite, data, page, totalPages, pageSize, searchText, rowSelected } = props;
  const { handleDataLoaded, handleOnPageChanged, handleOnPageSizeChanged, handleOnRowSelectedChanged } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [sortField, setSortField] = React.useState<{ direction: "desc" | "asc" | undefined; field: string }>({
    direction: undefined,
    field: "",
  });

  const editKeywordType = (id, keywordType) => {
    agent.KeywordTypes.updateKeywordType(id, keywordType)
      .then((res) => {
        handleDataLoaded(agent.KeywordTypes.getKeywordsTypes(null, searchText, pageSize, page));
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  function getIsWebsiteInKeyword(i: number) {
    return data[i].websites.includes(selectedWebsite._id);
  }

  function addWebsite(i: number) {
    if (getIsWebsiteInKeyword(i)) {
      agent.KeywordTypes.removeWebsite([data[i]._id], selectedWebsite._id)
        .then((res) => {
          handleDataLoaded(agent.KeywordTypes.getKeywordsTypes(null, searchText, pageSize, page));
        })
        .catch((e) => {
          console.log("Error", e);
        });
    } else {
      agent.KeywordTypes.addWebsite([data[i]._id], selectedWebsite._id)
        .then((res) => {
          handleDataLoaded(agent.KeywordTypes.getKeywordsTypes(null, searchText, pageSize, page));
        })
        .catch((e) => {
          console.log("Error", e);
        });
    }
  }

  const columns = [
    {
      id: "select",
      accessorKey: "select",
      header: ({ table }) => (
        <InsightsTableCheckBox
          iconColor={theme.palette.primary.main}
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          value={"header"}
          rootClassName={classes.checkboxAll}
          handleChange={(e) => {
            table.getToggleAllRowsSelectedHandler()(e);

            if (e.target.checked === false) {
              handleOnRowSelectedChanged([]);
            } else {
              handleOnRowSelectedChanged(
                data.map((d, index) => ({
                  id: index.toString(),
                  index,
                  original: d,
                }))
              );
            }
          }}
        />
      ),
      cell: ({ table, row, cell }) => (
        <InsightsTableCheckBox
          stringToColor={row.original?.name}
          iconColor={theme.palette.primary.main}
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          handleChange={(e) => {
            row.getToggleSelectedHandler()(e); // async

            if (e.target.checked) {
              return handleOnRowSelectedChanged([
                ...rowSelected,
                { id: row.id, index: row.index, original: row.original },
              ]);
            }
            handleOnRowSelectedChanged(rowSelected.filter((r) => r.id !== row.id));
          }}
          value={"row"}
        />
      ),
    },
    {
      id: "classify_type",
      accessorKey: "name",
      header: (header) => (
        <MSortLabel
          onClick={(result) => {
            setSortField(result);
            header.column.toggleSorting(sortField.direction === "asc");
          }}
          sortField={sortField}
          field={"keyword"}
          text={I18n.t("strategy.settings.classify_types")}
          variant={"subtitle2"}
          className={classes.headerColor}
        />
      ),
      cell: ({ getValue, row: { index }, column: { id }, table }) => {
        const initialValue = getValue();
        const [value, setValue] = React.useState(initialValue);

        const onBlur = () => {
          table.options.meta?.updateData(index, value, "name");
        };

        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        return (
          <>
            <TextField
              value={value as string}
              className={classes.editInput}
              onChange={(e) => setValue(e.target.value)}
              onBlur={onBlur}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input,
                },
              }}
            />
            <Typography className={classes.textInput}>{value as string}</Typography>
          </>
        );
      },
    },
    {
      id: "description",
      accessorKey: "description",
      header: () => <span>{I18n.t("strategy.settings.description")}</span>,
      cell: ({ getValue, row: { index }, column: { id }, table }) => {
        const initialValue = getValue();
        const [value, setValue] = React.useState(initialValue);

        const onBlur = () => {
          table.options.meta?.updateData(index, value, "description");
        };

        React.useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        return (
          <>
            <TextField
              value={value as string}
              className={classes.editInput2}
              onChange={(e) => setValue(e.target.value)}
              onBlur={onBlur}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.input2,
                },
              }}
            />
            <Typography className={classes.textInput2}>{value as string}</Typography>
          </>
        );
      },
    },
    {
      id: "switch",
      cell: ({ row: { index }, table }) => (
        <Switch color="primary" checked={getIsWebsiteInKeyword(index)} onChange={(val) => addWebsite(index)} />
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData(rowIndex, value, prop) {
        if (data[rowIndex][prop] !== value) {
          data[rowIndex][prop] = value;
          editKeywordType(data[rowIndex]._id, data[rowIndex]);
        }
      },
    },
  });

  React.useEffect(() => {
    table.resetRowSelection();
  }, [page]);

  React.useEffect(() => {
    if (rowSelected.length === 0) table.resetRowSelection();
  }, [rowSelected]);

  return (
    <div className={classes.root}>
      <table className={classes.tableStyle}>
        <thead className={classes.headerWrapStyle}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={classes.headerRowStyle}>
              {headerGroup.headers.map((header, i) => (
                <th
                  key={header.id}
                  className={ClassNames(i === 1 && classes.theadSecond, i === 2 && classes.theadThird)}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={classes.tableBodyStyle}>
          {table.getRowModel().rows.map((row, y) => (
            <tr
              key={row.id}
              className={classes.tableBodyRowStyle}
              onClick={() => {
                row.toggleSelected();
              }}
            >
              {row.getVisibleCells().map((cell, x) => (
                <td
                  key={cell.id}
                  className={ClassNames(
                    x === 1 && classes.tdataSecond,
                    x === 2 && classes.tdataThird,
                    x === 3 && classes.tdataFour
                  )}
                >
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <KeywordTypesPagination
        pageSize={pageSize}
        page={page}
        paginationPagesCount={totalPages}
        setPageSize={(value) => handleOnPageSizeChanged(value)}
        setPage={(value) => handleOnPageChanged(value)}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordTypesList);
