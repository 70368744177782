import React from "react";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  switch: {
    "& .MuiSwitch-thumb": {
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      width: 35,
      height: 12,
    },
  },
  switchDisabled: {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#B2B7C2",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#CCCFD6",
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  valueWrap: {
    display: "flex",
    alignItems: "center",
    border: "none",
  },
}));

type PropTypes = {
  title?: string;
  value: boolean;
  defaultValue?: boolean;
  handleChange: (isChecked: boolean) => void;
};

const SwitchBaseComponent = (props: PropTypes) => {
  const classes = useStyles(props);
  const { title = null, value = false, defaultValue = false, handleChange } = props;

  return (
    <div className={classes.row}>
      {title && (
        <Typography variant={"body2"} className={classNames(classes.text, value !== defaultValue && classes.boldText)}>
          {title}
        </Typography>
      )}
      <div className={classes.valueWrap}>
        <Switch
          className={classNames(classes.switch, !value ? classes.switchDisabled : "")}
          checked={value}
          onChange={(event) => handleChange(event.target.checked)}
          name="toc"
          size={"small"}
          inputProps={{ "aria-label": "Activate Brief" }}
          color="primary"
        />
      </div>
    </div>
  );
};

export default SwitchBaseComponent;
