import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles";
import ColorPicker from "./ColorPicker";
import boldIcon from "../../../components/Editor/icons/BoldIcon";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    flexDirection: (props: PropTypes) => props.flexDirection || "row",
    marginBottom: 15,
  },
  elementTitle: {
    color: theme.palette.text.primary,
    minWidth: 150,
    marginBottom: (props: PropTypes) => (props.flexDirection === "column" ? 8 : 0),
  },
}));

type PropTypes = {
  elementTitle: string;
  color: string;
  flexDirection?: "row" | "column";

  updatePage: Function;
};

const ColorTool = (props: PropTypes) => {
  const classes = useStyles(props);
  const { elementTitle, color, updatePage, flexDirection = "row" } = props;

  return (
    <div className={classes.row}>
      <Typography variant={"body2"} className={classes.elementTitle}>
        {elementTitle}
      </Typography>
      <ColorPicker color={color || "#000000"} handleChange={updatePage} top={"0px"} left={"180px"} />
    </div>
  );
};

export default ColorTool;
