import * as React from "react";
import { useMemo } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import { I18n } from "react-redux-i18n";

import {
  IAdminUser,
  IVideoProject,
  IWebsite,
  eVideoProjectAction,
  eVideoProjectState,
} from "../../../../reducers/constants/objectTypes";

import { VIDEO_PROJECT } from "../../../../reducers/constants/actionTypes";
import { web } from "../../../../helpers/urlHelper";
import MVideoProjectAvatar from "../../../../components/MVideoProjectAvatar";
import MVideoStatusTypography from "./MVideoStatusTypography";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import MTableCell from "../../../../components/Table/Cells/MTableCell";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MActionsDropdown from "../../../../components/MActionsDropdown";
import ability from "../../../../casl/ability";

const useStyles = makeStyles(() => ({
  optionsCell: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 40,
  },
}));

type PropTypes = {
  showStatusField?: boolean;
  selectedWebsite: IWebsite;
  user: IAdminUser;
  videoProject: IVideoProject & { commentsCount: number };

  updateVideoProject: ({ videoProject, action }: { videoProject: IVideoProject; action: eVideoProjectAction }) => void;
  selectVideoProject: (projectId: string, currentUrl: string) => void;
  refreshVideoProjects: () => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  user: state.home.admin,
});

const mapDispatchToProps = (dispatch) => ({
  selectVideoProject: (videoId: string, currentUrl: string) =>
    dispatch(push(web.videoWorkflowEditVideo(videoId, currentUrl))),
  refreshVideoProjects: () => dispatch({ type: VIDEO_PROJECT.PROJECTS_REFRESH }),
});

const VideoItemRow = (props: PropTypes) => {
  const { videoProject, showStatusField, selectVideoProject } = props;
  const { updateVideoProject } = props;
  const theme: any = useTheme();
  const location = useLocation();
  const classes = useStyles();

  const options = React.useMemo(() => {
    const actionsArr: {
      value: eVideoProjectAction;
      label: string;
    }[] = [];

    const selectedTimeline = videoProject.timelines.find((t) => t.selected);
    const hasVideos = selectedTimeline.rows.reduce(
      (acc, row) => row.actions.reduce((acc2, action) => Math.max(acc2, action.end), acc),
      0
    );

    if (ability.can("customer", "role")) {
      actionsArr.push({ value: eVideoProjectAction.export, label: I18n.t(`video_workflow.actions.export`) });
      if (videoProject.currentState.state === eVideoProjectState.review) {
        actionsArr.push(
          { value: eVideoProjectAction.done, label: I18n.t(`video_workflow.actions.approve`) },
          { value: eVideoProjectAction.secondCheck, label: I18n.t(`video_workflow.actions.return_to_editor`) }
        );
      }
      return actionsArr;
    }

    if (videoProject.currentState.state === eVideoProjectState.deleted) {
      actionsArr.push({
        value: eVideoProjectAction.deleteForever,
        label: I18n.t(`video_workflow.actions.delete_permanently`),
      });
    } else {
      actionsArr.push({ value: eVideoProjectAction.delete, label: I18n.t(`video_workflow.actions.delete`) });
    }

    if (hasVideos) {
      actionsArr.push({ value: eVideoProjectAction.export, label: I18n.t(`video_workflow.actions.export`) });
    }

    const sendToActions = [
      { value: eVideoProjectAction.brief, label: I18n.t(`video_workflow.tabs.Brief`) },
      { value: eVideoProjectAction.trim, label: I18n.t(`video_workflow.tabs.Trim`) },
      { value: eVideoProjectAction.edit, label: I18n.t(`video_workflow.tabs.Edit`) },
      { value: eVideoProjectAction.firstCheck, label: I18n.t(`video_workflow.tabs.FirstCheck`) },
      { value: eVideoProjectAction.secondCheck, label: I18n.t(`video_workflow.tabs.SecondCheck`) },
    ];

    if (ability.can("review", "video_project")) {
      sendToActions.push(
        { value: eVideoProjectAction.review, label: I18n.t(`video_workflow.tabs.Review`) },
        { value: eVideoProjectAction.done, label: I18n.t(`video_workflow.tabs.Done`) }
      );
    }

    return [
      ...actionsArr,
      {
        label: "Send to",
        nested: true,
        options: sendToActions.filter(
          ({ value }) => (value as unknown as eVideoProjectState) !== videoProject.currentState.state
        ),
      },
    ];
  }, [videoProject]);

  return (
    <MTableRow
      handleRowClick={() => {
        selectVideoProject(videoProject._id, location.pathname);
      }}
    >
      <MTableCellFirst
        fontSize={theme.typography.pxToRem(14)}
        columnWidth={"50%"}
        cellText={videoProject.title}
        prefix={<MVideoProjectAvatar size={25} />}
      />

      {showStatusField && (
        <MTableCell>
          <MVideoStatusTypography currentState={videoProject.currentState} />
        </MTableCell>
      )}
      <MTableCellText text={(videoProject.commentsCount || "0").toString()} />
      <MTableCell className={classes.optionsCell}>
        <MActionsDropdown
          options={options}
          onClick={(o) =>
            updateVideoProject({
              videoProject,
              action: o.value as eVideoProjectAction,
            })
          }
        />
      </MTableCell>

      {/* <div className={classes.column}>{localeHelper.getLanguageFromLocaleCode(videoProject.locale)}</div> */}
      {/* <div className={classes.column}>{videoProject.type}</div> */}
      {/* <div className={classes.column}>
          <Tooltip enterDelay={2000} title={videoProject.category?.name || "-"}>
            <span>{videoProject.category?.name || "-"}</span>
          </Tooltip>
        </div> */}
      {/* <div className={classes.column}>{videoProject.tags?.length || "0"}</div> */}

      {/* time */}
      {/* <div className={classes.column}>{"-"}</div> */}

      {/* comments */}

      {/* presenter */}
      {/* <div className={classes.column}>{"-"}</div> */}

      {/* interviewer */}
      {/* <div className={classes.column}>{"-"}</div> */}

      {/* vol */}
      {/* <div className={classes.column}>{"-"}</div> */}
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoItemRow);
