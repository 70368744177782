import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import CurrencyPickerElement from "../../../../../../../editor/elementsStyles/ElementComponents/CurrencyPickerElement";
import { IWidgetFieldProperties, IWidgetPriceFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "./components/GenericValueItemProperty";

type PropTypes = {
  properties: IWidgetFieldProperties;
  fieldType: string;
  onChange: (properties: IWidgetPriceFieldProps) => void;
};

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "flex",
    gap: 10,
  },
  select: {
    borderRadius: 5,
    backgroundColor: palette.secondary.dark,
    width: 120,
    border: 0,
  },
  dropdownIndicator: {
    fill: palette.text.secondary,
    color: palette.text.secondary,
    "&:hover": {
      fill: palette.text.secondary,
      color: palette.text.secondary,
    },
  },
}));

const ProductPriceWidgetItem = (props: PropTypes) => {
  const { onChange } = props;
  const properties = props.properties as IWidgetPriceFieldProps;
  const { value = "", currency } = properties;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <CurrencyPickerElement value={currency} onChange={(val) => onChange({ ...properties, currency: val })} />
      <GenericValueItemProperty
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onChange={({ value }) => onChange({ ...properties, value })}
        value={value}
        numbersOnly
      />
    </div>
  );
};

export default ProductPriceWidgetItem;
