import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { ePostType, IAdminUser, IWriterCommunityAnswer } from "../../../../reducers/constants/objectTypes";
import { web } from "../../../../helpers/urlHelper";
import MTextButton from "../../../../components/MTextButton";
import agent from "../../../../agent";
import { COMMUNITY } from "../../../../reducers/constants/actionTypes";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import MTableCellDate from "../../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MTableCell from "../../../../components/Table/Cells/MTableCell";

const useStyles = makeStyles((theme: Theme) => ({
  removeButton: {
    color: theme.palette.primary.main,
    padding: 0,
    minWidth: "unset",
    height: 50,
  },
}));

type PropTypes = {
  answer: IWriterCommunityAnswer;
  admin: IAdminUser;

  writeAnswer: (post) => void;
  refreshAnswerList: Function;
};

const mapStateToProps = (state) => ({
  admin: state.home.admin,
});

const mapDispatchToProps = (dispatch) => ({
  writeAnswer: (post) => dispatch(push(web.communityAnswerEditor(post._id), { publicPost: true })),
  refreshAnswerList: () => dispatch({ type: COMMUNITY.ANSWERS_REFRESH }),
});

const WriterCommunityPostItemRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { answer, admin, writeAnswer, refreshAnswerList } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme: any = useTheme();
  const discardAnswer = (e) => {
    e.stopPropagation();
    agent.Community.discardAnswer(false, answer, "writer")
      .then((res) => {
        enqueueSnackbar(I18n.t("writers.answers.removed_successfully"));
        refreshAnswerList();
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("writers.answers.error_removing"));
      });
  };

  return (
    <MTableRow
      handleRowClick={() => {
        writeAnswer(answer);
      }}
    >
      <MTableCellFirst
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(14)}
        avatarType={ePostType.COMMUNITY_ANSWER as ePostType}
        cellText={answer.question.title}
      />
      <MTableCellDate date={answer.createdAt} />
      <MTableCellText text={answer.question.category?.name} />
      <MTableCell>
        {answer.writer?._id === admin._id && (
          <MTextButton customClass={classes.removeButton} text={"Remove"} onClick={(e) => discardAnswer(e)} />
        )}
      </MTableCell>
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WriterCommunityPostItemRow);
