import * as React from "react";
import _, { findIndex } from "lodash";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useState } from "react";
import MSelect from "../../../components/MSelect";
import { IPage, IPageCluster } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 3,
  },
  title: {
    minWidth: 80,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 7,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
  },
}));

type PropTypes = {
  showLabel?: boolean;
  selectedContext?: string;
  cluster: IPageCluster;

  onContextSelected: (vertical) => void;
  handleClusterChange: (cluster: IPageCluster) => void;
};

// dev: {
// 	id: '626a4522850bee006ee84616',
// 		name: 'Main Context',
// },
export const NIverticals = [
  {
    id: "626a4522850bee006ee84616",
    name: "Test",
    url: "https://www.top10.com/test",
    urlTopReads: "https://www.top10.com/test/top-reads",
  },
  {
    id: "627a3e8db37e27fd14cc91b8",
    name: "Meal Delivery",
    url: "https://www.top10.com/meal-delivery",
    urlTopReads: "https://www.top10.com/meal-delivery/top-reads",
  },
  {
    id: "627a42269ae73d4a004fd8f0",
    name: "Dating",
    url: "https://www.top10.com/dating",
    urlTopReads: "https://www.top10.com/dating/top-reads",
  },
  {
    id: "627a423a9ae73d28a24fd907",
    name: "Psychic Reading",
    url: "https://www.top10.com/psychic-reading",
    urlTopReads: "https://www.top10.com/psychic-reading/top-reads",
  },
  {
    id: "627a4248bf9b5ddda8ae6549",
    name: "Home Warranty",
    url: "https://www.top10.com/home-warranty",
    urlTopReads: "https://www.top10.com/home-warranty/top-reads",
  },
  {
    id: "627a425d9ae73df0894fd922",
    name: "Website Builders",
    url: "https://www.top10.com/website-builders",
    urlTopReads: "https://www.top10.com/website-builders/top-reads",
  },
  {
    id: "6354fce4ffa67a1b29e9dca9",
    name: "DNA Testing",
    url: "https://www.top10.com/dna-testing",
    urlTopReads: "https://www.top10.com/dna-testing/top-reads",
  },
  {
    id: "6354fd09f775a8287a34c961",
    name: "Online Therapy",
    url: "https://www.top10.com/online-therapy",
    urlTopReads: "https://www.top10.com/online-therapy/top-reads",
  },
  {
    id: "6354fd22f775a8287a34cbe8",
    name: "VoIP",
    url: "https://www.top10.com/voip",
    urlTopReads: "https://www.top10.com/voip/top-reads",
  },
  {
    id: "6354fd35f775a8287a34cf85",
    name: "Hosting",
    url: "https://www.top10.com/hosting",
    urlTopReads: "https://www.top10.com/hosting/top-reads",
  },
  {
    id: "6354fd43b86f4f7495eff3e0",
    name: "Background Check",
    url: "https://www.top10.com/background-check",
    urlTopReads: "https://www.top10.com/background-check/top-reads",
  },
];

const PageEditorNIVertical = (props: PropTypes) => {
  const classes = useStyles(props);
  const { showLabel = true, selectedContext = "", cluster } = props;
  const { onContextSelected, handleClusterChange } = props;
  const [tries, setTries] = useState(0);
  const theme = useTheme<Theme>();
  React.useEffect(() => {
    if (!cluster || tries > 3) {
      return;
    }
    const index = findIndex(cluster.publishHistory, (ph) => ph.publishStatus === "PENDING");
    if (index < 0) {
      return;
    }
    setTries(tries + 1);
    setTimeout(
      () =>
        agent.NI.getPageClusterPublishStatus(cluster)
          .then((res) => {
            // console.log(res);
            handleClusterChange(res.cluster);
          })
          .catch((e) => {
            console.log(e);
          }),
      5000
    );
  }, [cluster]);

  if (true) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      {showLabel && (
        <Typography variant={"body1"} className={classes.title}>
          {I18n.t("custom_post.context")}
        </Typography>
      )}
      <MSelect
        customClass={classes.selectCustomClass}
        options={NIverticals}
        value={_.find(NIverticals, { id: selectedContext }) || null}
        optionLabel={"name"}
        optionValue={"_id"}
        height={31}
        borderWidth={"0px"}
        isBold={false}
        textColor={theme.palette.text.primary}
        singleValueStyles={{ fontSize: theme.typography.pxToRem(12) }}
        menuPlacement={"bottom"}
        fullWidth
        searchable
        getDisplayLabel={(vertical) => `${vertical.name}`}
        handleChange={(vertical) => {
          onContextSelected(vertical.id);
        }}
      />
    </div>
  );
};

export default PageEditorNIVertical;
