import { MODALS } from "./constants/actionTypes";

const initialState = {
  modalType: null,
  modalsStack: [],

  error: false,
  errorMessage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MODALS.OPEN: {
      const modalsStack = state.modalsStack;
      if (state.modalType) {
        modalsStack.push({ ...state });
      }
      return {
        modalsStack,
        modalType: action.modalType,
        ...action.modalProps,

        error: state.error,
        errorMessage: state.errorMessage,
      };
    }
    case MODALS.CLOSE: {
      const modalsStack = state.modalsStack;
      if (modalsStack.length > 0) {
        const props = modalsStack.pop();
        return {
          ...props,
          modalsStack,
        };
      }
      return initialState;
    }
    case MODALS.PROPS_CHANGED:
      return {
        ...state,
        ...action.props,

        error: state.error,
        errorMessage: state.errorMessage,
      };

    case MODALS.ON_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
      };
    case MODALS.CLOSE_ERROR:
      return {
        ...state,
        error: false,
        errorMessage: null,
      };
    case MODALS.CLEAR_PROPS: {
      return initialState;
    }
    default:
      return state;
  }
};
