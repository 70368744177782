import * as React from "react";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { OUTLINE } from "../../../reducers/constants/actionTypes";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";
import MSelect from "../../../components/MSelect";
import { IOutline } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  sectionWrapper: {
    width: "100%",
    borderRadius: 5,
    border: `solid 1px ${theme.palette.divider}`,
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  wrap: {
    display: "flex",
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  leftSide: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  rightSide: {
    width: "100%",
    maxWidth: 60,
    display: "flex",
    flexDirection: "column",
  },
  rectangle: {
    width: 50,
    height: 210,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    marginLeft: 10,
    padding: 20,
  },
  icon: {
    marginBottom: 20,
    cursor: "pointer",
    "&:hover": {
      fill: theme.palette.primary.main,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  displayed: {
    display: "flex",
  },
  notDisplayed: {
    display: "none",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 20,
  },
  titleInput: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  select: {
    textTransform: "capitalize",
    width: 140,
  },
}));

type PropTypes = {
  children: React.ReactElement | React.ReactElement[];
  paragraphIndex: number;
  outline: IOutline[];
  onOutlinePropertyChanged: (value) => void;
};

const mapStateToProps = (state) => ({
  outline: state.outline.outline,
});

const mapDispatchToProps = (dispatch) => ({
  onOutlinePropertyChanged: (paragraphs) =>
    dispatch({ type: OUTLINE.ON_OUTLINE_PROPERTY_CHANGED, name: "paragraphs", value: paragraphs }),
});

const ParagraphActions = (props: PropTypes) => {
  const classes = useStyles(props);
  const { paragraphIndex, outline, children, onOutlinePropertyChanged } = props;
  const [displayOnHover, setDisplayOnHover] = React.useState(false);
  const selectOptions = [
    { _id: 1, name: "PARAGRAPH" },
    { _id: 2, name: "FAQ" },
  ];

  const paragraphMove = (fromIndex, toIndex) => {
    const paragraphs = [...outline.paragraphs];
    const element = paragraphs[fromIndex];
    paragraphs.splice(fromIndex, 1);
    paragraphs.splice(toIndex, 0, element);
    return paragraphs;
  };

  const moveBackward = () => {
    console.log("moveBackward");
    if (paragraphIndex === 0) {
      return;
    }
    onOutlinePropertyChanged(paragraphMove(paragraphIndex, paragraphIndex - 1));
  };

  const moveForward = () => {
    console.log("moveForward", outline.paragraphs);
    if (paragraphIndex === outline.paragraphs.length) {
      return;
    }
    const upda = paragraphMove(paragraphIndex, paragraphIndex + 1);
    console.log(upda);
    onOutlinePropertyChanged(upda);
  };

  const addParagraph = (type) => {
    console.log("addParagraph");
    let newParagraph;
    if (type === "PARAGRAPH") {
      newParagraph = {
        type: "PARAGRAPH",
        title: "",
        data: {
          targetKeywordsGroups: [],
          links: [],
          notes: "",
        },
      };
    } else if (type === "FAQ") {
      newParagraph = {
        type: "FAQ",
        title: "",
        data: {
          questions: [],
        },
      };
    }
    const updatedParagraphs = [...outline.paragraphs];
    updatedParagraphs.splice(paragraphIndex + 1, 0, newParagraph);
    onOutlinePropertyChanged(updatedParagraphs);
  };

  const replaceParagraph = (type) => {
    console.log("replaceParagraph");
    let newParagraph;
    if (type === "PARAGRAPH") {
      newParagraph = {
        type: "PARAGRAPH",
        title: "",
        data: {
          targetKeywordsGroups: [],
          links: [],
          notes: "",
        },
      };
    } else if (type === "FAQ") {
      newParagraph = {
        type: "FAQ",
        title: "",
        data: {
          questions: [],
        },
      };
    }
    const updatedParagraphs = [...outline.paragraphs];
    updatedParagraphs.splice(paragraphIndex, 1, newParagraph);
    onOutlinePropertyChanged(updatedParagraphs);
  };

  const copyParagraph = () => {
    const updatedParagraphs = [...outline.paragraphs];
    const updatedParagraph = { ...outline.paragraphs[paragraphIndex] };
    updatedParagraphs.splice(paragraphIndex + 1, 0, updatedParagraph);
    onOutlinePropertyChanged(updatedParagraphs);
  };

  const deleteParagraph = () => {
    const updatedParagraphs = [...outline.paragraphs];
    updatedParagraphs.splice(paragraphIndex, 1);
    onOutlinePropertyChanged(updatedParagraphs);
  };

  return (
    <div
      className={classes.wrap}
      onMouseEnter={() => setDisplayOnHover(true)}
      onMouseLeave={() => setDisplayOnHover(false)}
    >
      <div className={classes.leftSide}>
        <Paper elevation={0} className={classes.sectionWrapper}>
          <div className={classes.headerWrapper}>
            <TextFieldMaxLength
              value={outline.paragraphs[paragraphIndex]?.title}
              placeholder={
                outline.paragraphs[paragraphIndex]?.type === "PARAGRAPH"
                  ? I18n.t("outline.paragraph_section_title_ph")
                  : I18n.t("outline.FAQ_section_title_ph")
              }
              borderRadius={4}
              height={37}
              borderColor={"transparent"}
              inputClassName={classes.titleInput}
              handleValueChange={(title) => {
                const updatedParagraphs = [...outline.paragraphs];
                updatedParagraphs[paragraphIndex].title = title;
                onOutlinePropertyChanged(updatedParagraphs);
              }}
            />
            <MSelect
              customClass={classes.select}
              options={selectOptions}
              value={selectOptions.find((option) => option.name === outline.paragraphs[paragraphIndex]?.type)}
              optionValue={"_id"}
              optionLabel={"name"}
              height={37}
              placeholder={I18n.t("outline.post_type_ph")}
              borderWidth={1}
              borderRadius={4}
              isBold={false}
              textColor={"#333333"}
              getDisplayLabel={(value) => `${value.name.toLowerCase()}`}
              handleChange={(value) => replaceParagraph(value.name)}
            />
          </div>
          <>{children}</>
        </Paper>
      </div>
      <div className={classes.rightSide}>
        <div className={ClassNames(classes.rectangle, displayOnHover ? classes.displayed : classes.notDisplayed)}>
          <KeyboardArrowUpRoundedIcon className={classes.icon} onClick={() => moveBackward()} />
          <AddCircleOutlineRoundedIcon
            className={classes.icon}
            onClick={() => addParagraph(outline.paragraphs[paragraphIndex].type)}
          />
          <FileCopyOutlinedIcon className={classes.icon} onClick={() => copyParagraph()} />
          <DeleteOutlineRoundedIcon className={classes.icon} onClick={() => deleteParagraph()} />
          <KeyboardArrowDownRoundedIcon className={classes.icon} onClick={() => moveForward()} />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ParagraphActions);
