import { KEYWORDTYPES } from "./constants/actionTypes";

const initialState = {
  data: [],
  page: 1,
  pageSize: 50,
  totalPages: null,
  searchText: "",
  loading: false,
  rowSelected: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case KEYWORDTYPES.KEYWORDTYPES_LOADED:
      return {
        ...state,

        data: action.payload.data,
        totalPages: action.payload.metadata.pagesCount,
        page: action.payload.metadata.page + 1,
        pageSize: action.payload.metadata.pageSize,
        loading: false,
      };

    case KEYWORDTYPES.LOADING:
      return {
        ...state,
        loading: true,
      };

    case KEYWORDTYPES.ON_PAGE_CHANGED:
      return {
        ...state,
        page: action.page,
        rowSelected: []
      };

    case KEYWORDTYPES.ON_PAGESIZE_CHANGED:
      return {
        ...state,
        pageSize: action.pageSize,
      };

    case KEYWORDTYPES.ON_SEARCHTEXT_CHANGED:
      return {
        ...state,
        searchText: action.searchText,
      };

    case KEYWORDTYPES.ON_ROWSELECTED_CHANGED:
      return {
        ...state,
        rowSelected: action.rowSelected,
      }; 

    default:
      return state;
  }
};
