import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import ExpertsListPage from "./ExpertsList/ExpertsListPage";
import { web } from "../../../helpers/urlHelper";
import ExpertPage from "./ExpertPage/ExpertPage";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const Experts = () => (
  <>
    <MHeaderMetaTags title={I18n.t(`meta_tags.market_place.experts.title`)} />
    <Switch>
      <Route exact={false} path={web.entailExperts(":expertId")} component={ExpertPage} />
      <Route exact={false} path={web.entailExperts()} component={ExpertsListPage} />
      <Redirect exact from={"*"} to={web.entailAudiences()} />
    </Switch>
  </>
);

export default Experts;
