import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import classNames from "classnames";
import { I18n } from "react-redux-i18n";
import CfButton from "../../components/CfButton";
import KeyIcon from "../../icons/KeyIcon";
import GlobeIcon from "../../icons/GlobIcon";
import AttachIcon from "../../icons/AttachIcon";
import MTableRow from "../../components/Table/Rows/MTableRow";
import MTableCellFirstWithSelect from "../../components/Table/Cells/MTableCellFirstWithSelect";
import MTableCellText from "../../components/Table/Cells/MTableCellText";
import MTableCellNumber from "../../components/Table/Cells/MTableCellNumber";
import MTableCellDate from "../../components/Table/Cells/MTableCellDate";
import { DATE_FORMAT } from "../../reducers/constants/consts";
import MTableCell from "../../components/Table/Cells/MTableCell";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    "&:hover": {
      "& $fetchButton": {
        display: "flex",
      },
      "& $deleteButton": {
        display: "flex",
      },
    },
  },

  icon: {
    width: 25,
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  globeIconWrapper: {
    backgroundColor: "#4E38D6",
  },
  keyIconWrapper: {
    backgroundColor: "#84C16E",
  },
  attachIconWrapper: {
    backgroundColor: "#388AD6",
  },
  checkIconWrapper: {
    backgroundColor: theme.palette.primary.main,
  },

  fetchButton: {
    color: theme.palette.common.white,
    display: "none",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 25,
    padding: "10px 30px",
    marginLeft: 10,
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: theme.typography.pxToRem(14),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  deleteButton: {
    color: "red",
    display: "none",
    backgroundColor: "transparent",
    padding: 0,
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: theme.typography.pxToRem(14),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonsWrapper: {
    display: "flex",
  },
}));

type PropTypes = {
  seed: any;
  isLastRow: boolean;
  isFirstRow: boolean;
  selected: boolean;
  setSelected: (selected: boolean) => void;
  fetchSeed: (id) => void;
  deleteSeed: (seed) => void;
};

const FetchKewordItem = (props: PropTypes) => {
  const { selected, deleteSeed, seed, setSelected, fetchSeed } = props;

  const classes = useStyles(props);

  const Icon = () => {
    const iconClass = {
      keyword: classes.keyIconWrapper,
      domain: classes.globeIconWrapper,
      page: classes.attachIconWrapper,
    }[seed.type];

    const IconComponent = {
      keyword: KeyIcon,
      domain: GlobeIcon,
      page: AttachIcon,
    }[seed.type];

    return (
      <div className={classNames(classes.icon, iconClass)}>
        <IconComponent />
      </div>
    );
  };

  return (
    <MTableRow handleRowClick={() => {}} customClassName={classes.row}>
      <MTableCellFirstWithSelect
        selected={selected}
        onSelect={setSelected}
        columnWidth={"33%"}
        cellText={seed.seed}
        prefix={<Icon />}
      />

      <MTableCellText text={seed.source} />
      <MTableCellText text={seed.type} />
      <MTableCellNumber value={seed.fetchedKw} placeHolder={"-"} type={"bigNumber"} />
      <MTableCellDate date={seed.lastFetch} dateFormat={DATE_FORMAT.DAY_MONTH_YEAR} />
      <MTableCell className={classes.buttonsWrapper}>
        <CfButton
          height={36}
          customClass={classes.deleteButton}
          text={I18n.t("fetch_keywords.delete")}
          onClick={(e) => {
            e.stopPropagation();
            deleteSeed(seed);
          }}
        />
        <CfButton
          height={36}
          customClass={classes.fetchButton}
          onClick={(e) => {
            e.stopPropagation();
            fetchSeed(seed._id);
          }}
          text={I18n.t("fetch_keywords.fetch")}
        />
      </MTableCell>
    </MTableRow>
  );
};

export default FetchKewordItem;
