import * as React from "react";
import { I18n } from "react-redux-i18n";
import { IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { makeStyles, Theme } from "@material-ui/core";
import { find } from "lodash";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../../elementsStyles/components/StylePanelSubSection";
import ProductPickerElement from "../../../elementsStyles/ElementComponents/ProductPickerElement";
import SwitchBaseComponent from "../../../elementsStyles/BaseComponents/SwitchBaseComponent";
import TextOldElement from "../../../elementsStyles/ElementComponents/TextOldElement";
import BackgroundColorCSS from "../../../elementsStyles/CssComponents/BackgroundColorCSS";
import BorderColorCSS from "../../../elementsStyles/CssComponents/BorderColorCSS";
import HeightCSS from "../../../elementsStyles/CssComponents/HeightCSS";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  numberInput: {
    width: 180,
    height: 32,
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 2,
    padding: "0 8px",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

type PropTypes = {
  editor: Editor;
  selectedWebsite: IWebsite;
  websiteTheme: IWebsiteThemeProps;
  element: any;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
};

const PricingTableWidgetStyle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, selectedWebsite, websiteTheme, element, products, handleProductsChange } = props;

  const handleElementDataChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const getCompleteProductObject = (itemNumber) => {
    return find(products, (p) => p.itemNumber === itemNumber);
  };

  const onProductSelected = (prod: IProduct, index: number) => {
    const products = [...(element.data?.products || [{}])];
    let comparisons = [...(element.data?.comparisons || [])];
    if (prod) {
      if (products[index] === undefined && comparisons) {
        comparisons = [
          ...element.data.comparisons?.map((comp) => {
            const filtered = [
              ...(comp.products || []),
              {
                itemNumber: prod.itemNumber,
                selected: false,
                selectType: "Checkbox",
                price: prod.pricing?.startingPrice || prod.pricing?.price,
              },
            ];
            return { ...comp, products: filtered };
          }),
        ];
      }
      products[index] = {
        itemNumber: prod.itemNumber,
      };
      //
      handleElementDataChange({ ...element.data, products, comparisons });
    } else {
      const comparisons = [
        ...element.data.comparisons?.map((comp) => {
          const filtered = [...comp.products?.filter((product) => product.itemNumber !== products[index].itemNumber)];
          return { ...comp, products: filtered };
        }),
      ];
      products.splice(index, 1);
      handleElementDataChange({ ...element.data, products, comparisons });
    }
  };

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.pricing_table.name")} editor={editor}>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.settings")}>
        <div>
          {[...(element.data?.products || []), null].map((product, index) => {
            return (
              <ProductPickerElement
                fullWidth
                isClearable
                products={products}
                handleProductsChange={handleProductsChange}
                key={index}
                title={`Product ${index + 1}`}
                selectedWebsite={selectedWebsite}
                product={getCompleteProductObject(product?.itemNumber)}
                onProductChange={(selected: IProduct) => onProductSelected(selected, index)}
              />
            );
          })}
        </div>
        <SwitchBaseComponent
          defaultValue={true}
          value={element.data?.showLogo}
          title={I18n.t("rich_text_editor.pricing_table.show_logo")}
          handleChange={(showLogo) => handleElementDataChange({ ...element.data, showLogo })}
        />
      </StylePanelSubSection>

      <TextOldElement
        sectionTitle={I18n.t("rich_text_editor.pricing_table.table_title")}
        cssKey={"tableTitleCss"}
        handleChange={handleElementDataChange}
        element={element}
        theme={websiteTheme}
      />

      <TextOldElement
        sectionTitle={I18n.t("rich_text_editor.pricing_table.item_title")}
        cssKey={"itemTitleCss"}
        handleChange={handleElementDataChange}
        element={element}
        theme={websiteTheme}
      />

      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.pricing_table.table_colors")}>
        <BackgroundColorCSS
          title={I18n.t("rich_text_editor.pricing_table.head_row_bg")}
          value={element.data?.headCss?.backgroundColor || websiteTheme.palette.secondary.main}
          defaultValue={websiteTheme.palette.secondary.main}
          isValueChanged={false}
          handleChange={(value) =>
            handleElementDataChange({ ...element.data, headCss: { ...element.data.headCss, backgroundColor: value } })
          }
        />
        <BackgroundColorCSS
          title={I18n.t("rich_text_editor.pricing_table.odd_row_bg")}
          value={element.data?.oddCss?.backgroundColor || websiteTheme.palette.secondary.main}
          defaultValue={websiteTheme.palette.secondary.main}
          isValueChanged={false}
          handleChange={(backgroundColor) =>
            handleElementDataChange({ ...element.data, oddCss: { ...element.data.oddCss, backgroundColor } })
          }
        />
        <BackgroundColorCSS
          title={I18n.t("rich_text_editor.pricing_table.even_row_bg")}
          value={element.data?.evenCss?.backgroundColor || websiteTheme.palette.background.default}
          defaultValue={websiteTheme.palette.background.default}
          isValueChanged={false}
          handleChange={(backgroundColor) =>
            handleElementDataChange({ ...element.data, evenCss: { ...element.data.evenCss, backgroundColor } })
          }
        />
        <BorderColorCSS
          title={I18n.t("rich_text_editor.pricing_table.divider_color")}
          value={element.data?.dividerCss?.borderBottomColor || websiteTheme.palette.divider}
          defaultValue={websiteTheme.palette.divider}
          isValueChanged={false}
          handleChange={(borderBottomColor) =>
            handleElementDataChange({ ...element.data, dividerCss: { ...element.data.dividerCss, borderBottomColor } })
          }
        />
        <HeightCSS
          value={element.data?.dividerCss?.borderBottomWidth}
          title={I18n.t("rich_text_editor.pricing_table.divider_height")}
          defaultValue={1}
          handleChange={(event) =>
            handleElementDataChange({
              ...element.data,
              dividerCss: { ...element.data.dividerCss, borderBottomWidth: parseInt(event.target.value, 10) },
            })
          }
        />
      </StylePanelSubSection>
    </StylePanelSection>
  );
};

export default PricingTableWidgetStyle;
