import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Theme, Typography, TextField, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import EditIcon from "@material-ui/icons/Edit";
import { I18n } from "react-redux-i18n";
import { replace } from "connected-react-router";
import {
  IWebsite,
  ICampaign,
  eCampaignAction,
  eCampaignStatus,
  eMpEvent,
} from "../../../../../../reducers/constants/objectTypes";
import { HOME, WIDGET_RULE_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import { web } from "../../../../../../helpers/urlHelper";
import agent from "../../../../../../agent";
import ArrowLeftIcon from "../../../../../../icons/ArrowLeftIcon";
import MPageActionsButtonGroup from "../../../../../../components/MPageActionsButtonGroup";
import { WidgetRuleEditorRootState } from "../../../../../../reducers/widgetRuleEditorReducer";
import classNames from "classnames";
import { DesktopWindows, Smartphone } from "@material-ui/icons";
import MAddToSiteButton from "../../../../../../components/MAddToSiteButton";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: 55,
    paddingRight: 17,
    paddingLeft: 17,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    position: "relative",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  iconsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
    position: "absolute",
    left: "50%",
  },
  fullScreenText: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginRight: 15,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.light,
    },
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    width: 300,
    height: "100%",
    boxSizing: "border-box",
  },
  label: {
    marginLeft: 15,
    borderRadius: "5px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    userSelect: "none",
  },
  divider: {
    height: 26,
    margin: "0 15px",
  },
  title: {
    fontSize: 14,
    maxWidth: 400,
    fontWeight: theme.typography.fontWeightBold as any,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginBottom: -1,
  },
  editTitleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  inputWrapper: {
    maxWidth: 600,
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  inputLabel: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  btnEdit: {
    height: 35,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    textTransform: "none",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  iconEdit: { height: 16, width: 16, marginLeft: 5, cursor: "pointer" },
  arrowIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  deviceIcon: {
    color: theme.palette.text.primary,
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
      cursor: "pointer",
    },
  },
  activeDeviceIcon: {
    color: theme.palette.primary.main,
    opacity: 1,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const mapStateToProps = (state) => ({
  website: state.home.selectedWebsite,
  rule: state.widgetRuleEditor.rule,
  preview: state.widgetRuleEditor.preview,
  addContainerMode: state.widgetRuleEditor.addContainerMode,
  fromOnboarding: state.widgetRuleEditor.fromOnboarding,
});

const mapDispatchToProps = (dispatch) => ({
  returnToPreviousScreen: () => dispatch(replace(web.croCampaigns())),
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PROPERTY_CHANGE, property });
  },
  handlePreviewPropertyChange: (property: Partial<WidgetRuleEditorRootState["preview"]>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PREVIEW_PROPERTY_CHANGE, property });
  },
  unmount: () => dispatch({ type: WIDGET_RULE_EDITOR.UNMOUNT }),
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
});

type PropTypes = {
  website: IWebsite;
  rule: ICampaign;
  preview: WidgetRuleEditorRootState["preview"];
  addContainerMode?: boolean;

  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => void;
  handlePreviewPropertyChange: (property: Partial<WidgetRuleEditorRootState["preview"]>) => void;
  returnToPreviousScreen: () => void;
  unmount: () => void;
  fromOnboarding: boolean;
  mpEvent: (event: string, props: object) => void;
};

const DEFAULT_INPUT_WIDTH = 200;
const WIDTH_RATIO = 8;

const CampaignEditorHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { rule, website, preview, addContainerMode, fromOnboarding } = props;
  const { handlePropertyChange, handlePreviewPropertyChange, unmount, mpEvent } = props;
  const { mobilePreview, fullScreen } = preview;
  const [isEditing, setIsEditing] = React.useState(false);
  const [ruleName, setRuleName] = React.useState(rule.name);
  const { enqueueSnackbar } = useSnackbar();

  const [inputWidth, setInputWidth] = React.useState(DEFAULT_INPUT_WIDTH);

  React.useEffect(() => {
    if (ruleName.length * WIDTH_RATIO > DEFAULT_INPUT_WIDTH) {
      setInputWidth((ruleName.length + 1) * WIDTH_RATIO);
    } else {
      setInputWidth(DEFAULT_INPUT_WIDTH);
    }
  }, [ruleName]);

  const returnToPreviousScreen = () => {
    props.returnToPreviousScreen();
    unmount();
  };

  const updateRuleName = () => {
    if (ruleName === rule.name) {
      setIsEditing(false);
      return;
    }

    if (!ruleName?.trim()) {
      enqueueSnackbar(I18n.t("widgets_display_rules_editor.header.not_empty_name"));
      return;
    }

    agent.Campaigns.updateCampaignName(website._id, rule._id, ruleName)
      .then(() => {
        enqueueSnackbar(I18n.t("widgets_display_rules_workflow.actions.name_updated"));
        setIsEditing(false);
        handlePropertyChange({ rule: { ...rule, name: ruleName } });
      })
      .catch((e) => {
        enqueueSnackbar(e.message);
      });
  };

  const saveRule = (action: eCampaignAction) => {
    agent.Campaigns.updateCampaign(website._id, rule, action)
      .then((res) => {
        enqueueSnackbar(I18n.t("widgets_display_rules_workflow.actions.updated_successfully"));
        if (action === eCampaignAction.PUBLISH && rule.previewUrl) {
          window.open(rule.previewUrl, "_blank");
        }
        handlePropertyChange({ rule: { ...rule, status: res.rule.status } });
      })
      .catch((e) => {
        enqueueSnackbar(e.response.body.message ?? "Error occurred while saving widget");
      });
  };

  const restoreRule = (action: eCampaignAction) => {
    agent.Campaigns.updateCampaign(website._id, rule, action)
      .then(() => enqueueSnackbar(I18n.t("widgets_display_rules_workflow.actions.restored_successfully")))
      .catch((e) => {
        enqueueSnackbar(e.response.body.message ?? "Error occurred while saving widget");
      });
  };

  const deleteRule = () => {
    agent.Campaigns.deleteCampaign(website._id, rule._id)
      .then((res) => {
        enqueueSnackbar(I18n.t("widgets_display_rules_workflow.actions.deleted_successfully"));
        returnToPreviousScreen();
      })
      .catch((e) => {
        enqueueSnackbar(
          e.response.body.message ?? I18n.t("widgets_display_rules_workflow.actions.something_went_wrong")
        );
      });
  };

  const handleActionClick = (action: eCampaignAction) => {
    switch (action) {
      case eCampaignAction.SAVE:
      case eCampaignAction.PUBLISH:
      case eCampaignAction.UNPUBLISH:
        saveRule(action);
        break;
      case eCampaignAction.DELETE:
      case eCampaignAction.DELETE_PERMANENTLY:
        deleteRule();
        break;
      case eCampaignAction.RESTORE:
        restoreRule(action);
        break;
      case eCampaignAction.AddScript:
        saveRule(action);
        returnToPreviousScreen();
        break;
      default:
        throw new Error(`Unknown type: ${action}`);
    }
  };

  const actions = [];

  switch (rule.status) {
    case eCampaignStatus.DRAFT:
      actions.push(
        { action: eCampaignAction.PUBLISH, label: I18n.t("widgets_display_rules_workflow.actions.publish") },
        { action: eCampaignAction.SAVE, label: I18n.t("widgets_display_rules_workflow.actions.save") },
        { action: eCampaignAction.DELETE, label: I18n.t("widgets_display_rules_workflow.actions.delete") }
      );
      break;
    case eCampaignStatus.ACTIVE:
      actions.push(
        { action: eCampaignAction.PUBLISH, label: I18n.t("widgets_display_rules_workflow.actions.publish") },
        { action: eCampaignAction.SAVE, label: I18n.t("widgets_display_rules_workflow.actions.save") },
        {
          action: eCampaignAction.UNPUBLISH,
          label: I18n.t("widgets_display_rules_workflow.actions.unpublish"),
        },
        { action: eCampaignAction.DELETE, label: I18n.t("widgets_display_rules_workflow.actions.delete") }
      );
      break;
    case eCampaignStatus.DELETED:
      actions.push(
        { action: eCampaignAction.RESTORE, label: I18n.t("widgets_display_rules_workflow.actions.restore") },
        {
          action: eCampaignAction.DELETE_PERMANENTLY,
          label: I18n.t("widgets_display_rules_workflow.actions.delete_permanently"),
        }
      );
      break;
    default:
      throw new Error(`Unhandled status code - ${rule.status}`);
  }

  if (addContainerMode) {
    <div className={classes.toolbar}>
      <div className={classes.wrapper}>
        <div>
          <ArrowLeftIcon
            className={classes.arrowIcon}
            onClick={() => handlePropertyChange({ addContainerMode: true })}
          />
        </div>
        <div className={classes.toolbarDetails}>
          <Typography className={classes.label}>{I18n.t("widgets_display_rules_editor.header.add_widget")}</Typography>
        </div>
      </div>
    </div>;
  }

  return (
    <div className={classes.toolbar}>
      <div className={classes.wrapper}>
        {!fullScreen && (
          <>
            <div>
              <ArrowLeftIcon className={classes.arrowIcon} onClick={returnToPreviousScreen} />
            </div>
            <div className={classes.toolbarDetails}>
              <Typography className={classes.label}>
                {I18n.t("widgets_display_rules_editor.header.campaign")}
              </Typography>
              <Divider orientation={"vertical"} className={classes.divider} />
              {!isEditing && (
                <>
                  <Typography className={classes.title} color={"textPrimary"} variant={"body2"}>
                    {ruleName}
                  </Typography>
                  <EditIcon
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    className={classes.iconEdit}
                  />
                </>
              )}
              {isEditing && (
                <div className={classes.inputWrapper}>
                  <TextField
                    className={classes.input}
                    onChange={(event) => setRuleName(event.target.value)}
                    value={ruleName}
                    style={{ width: inputWidth, maxWidth: 400 }}
                    InputProps={{
                      style: { width: inputWidth, maxWidth: 400 },
                      classes: {
                        input: classes.inputLabel,
                      },
                    }}
                  />
                  <Button className={classes.btnEdit} onClick={updateRuleName}>
                    {I18n.t("widgets_display_rules_editor.header.done")}
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className={classes.iconsWrapper}>
        <DesktopWindows
          className={classNames(classes.deviceIcon, !mobilePreview && classes.activeDeviceIcon)}
          onClick={() => handlePreviewPropertyChange({ mobilePreview: false })}
        />
        <Smartphone
          className={classNames(classes.deviceIcon, mobilePreview && classes.activeDeviceIcon)}
          onClick={() => handlePreviewPropertyChange({ mobilePreview: true })}
        />
      </div>
      <div className={classes.wrapper}>
        {fullScreen && (
          <Typography
            className={classes.fullScreenText}
            onClick={() => handlePreviewPropertyChange({ fullScreen: false })}
          >
            {I18n.t("widgets_display_rules_editor.header.exit_full_screen")}
          </Typography>
        )}
        {!fullScreen && (
          <>
            <Typography
              className={classes.fullScreenText}
              onClick={() => handlePreviewPropertyChange({ fullScreen: true })}
            >
              {I18n.t("widgets_display_rules_editor.header.full_screen")}
            </Typography>
            <MAddToSiteButton marginRight={15} handleOnClick={() => handleActionClick(eCampaignAction.AddScript)} />

            <MPageActionsButtonGroup
              id={"publish-widgets"}
              loading={false}
              mainAction={actions[0]}
              handleActionOnClick={(option) => {
                mpEvent(eMpEvent.croCampaignButtonClick, {
                  type: "campaign_save",
                  text: option.label,
                });
                handleActionClick(option.action);
              }}
              actions={actions}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignEditorHeader);
