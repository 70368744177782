import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";

type PropTypes = {
  size?: number;
};

const MVideoProjectAvatar = (props: PropTypes) => {
  const { size = 25 } = props;

  const avatarProps = React.useMemo(
    () => ({
      initials: "V",
      backgroundColor: "#FF0100",
      color: "white",
      tooltip: "Video",
      marginRight: 25,
    }),
    []
  );

  return (
    <Tooltip enterDelay={2000} title={avatarProps.tooltip}>
      <Avatar
        // className={classes.pricePerWord}
        style={{
          width: size,
          height: size,
          whiteSpace: "pre-wrap",
          fontSize: 12,
          lineHeight: 1.25,
          color: avatarProps.color,
          backgroundColor: avatarProps.backgroundColor,
          marginRight: avatarProps.marginRight,
        }}
      >
        {avatarProps.initials.toUpperCase()}
      </Avatar>
    </Tooltip>
  );
};

export default MVideoProjectAvatar;
