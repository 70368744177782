import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 35,
    height: 35,
  },
}));

type PropTypes = {
  className?: string;
};

const FAQIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 35 35"} fill="none">
      <rect width="35" height="35" rx="3" fill="#FE9A00" />
      <path
        d="M18 23.7499V23.8048M14.8764 13.6527C14.8764 11.8942 16.2755 10.4687 18.0014 10.4687C19.7272 10.4687 21.1264 11.8942 21.1264 13.6527C21.1264 15.4112 19.7272 16.8367 18.0014 16.8367C18.0014 16.8367 18 17.7871 18 18.9594M30.5 9.68747L30.5 25.3125C30.5 27.9013 28.4013 30 25.8125 30H10.1875C7.59867 30 5.5 27.9013 5.5 25.3125V9.68747C5.5 7.09865 7.59867 5 10.1875 5H25.8125C28.4013 5 30.5 7.09865 30.5 9.68747Z"
        stroke="white"
        fill="none"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
export default FAQIcon;
