import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles(() => ({
  icon: {
    height: 17,
    width: 16,
    color: "#000",
  },
}));

type PropTypes = {
  className?: string;
  onClick?: () => void;
};

const ElementsIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className, onClick } = props;

  return (
    <SvgIcon onClick={onClick} className={ClassNames(classes.icon, className)} viewBox={"0 0 17 16"}>
      <path d="M1.80771 6.83566C1.30257 6.83566 0.875003 6.66476 0.525002 6.32298C0.175001 5.98119 0 5.56366 0 5.0704V1.76525C0 1.27199 0.175001 0.854466 0.525002 0.51268C0.875003 0.170893 1.30257 0 1.80771 0H8.7161C9.22124 0 9.64881 0.170893 9.99881 0.51268C10.3488 0.854466 10.5238 1.27199 10.5238 1.76525V5.0704C10.5238 5.56366 10.3488 5.98119 9.99881 6.32298C9.64881 6.66476 9.22124 6.83566 8.7161 6.83566H1.80771ZM1.80771 5.37091H8.7161C8.79303 5.37091 8.86356 5.3396 8.92768 5.27699C8.99178 5.21439 9.02383 5.14553 9.02383 5.0704V1.76525C9.02383 1.69013 8.99178 1.62126 8.92768 1.55867C8.86356 1.49607 8.79303 1.46478 8.7161 1.46478H1.80771C1.73077 1.46478 1.66025 1.49607 1.59613 1.55867C1.53203 1.62126 1.49998 1.69013 1.49998 1.76525V5.0704C1.49998 5.14553 1.53203 5.21439 1.59613 5.27699C1.66025 5.3396 1.73077 5.37091 1.80771 5.37091ZM1.80771 16C1.30257 16 0.875003 15.8291 0.525002 15.4873C0.175001 15.1455 0 14.728 0 14.2347V10.9296C0 10.4363 0.175001 10.0188 0.525002 9.67702C0.875003 9.33524 1.30257 9.16434 1.80771 9.16434H15.1923C15.6974 9.16434 16.125 9.33524 16.475 9.67702C16.825 10.0188 17 10.4363 17 10.9296V14.2347C17 14.728 16.825 15.1455 16.475 15.4873C16.125 15.8291 15.6974 16 15.1923 16H1.80771ZM1.80771 14.5352H15.1923C15.2692 14.5352 15.3398 14.5039 15.4039 14.4413C15.468 14.3787 15.5 14.3099 15.5 14.2347V10.9296C15.5 10.8545 15.468 10.7856 15.4039 10.723C15.3398 10.6604 15.2692 10.6291 15.1923 10.6291H1.80771C1.73077 10.6291 1.66025 10.6604 1.59613 10.723C1.53203 10.7856 1.49998 10.8545 1.49998 10.9296V14.2347C1.49998 14.3099 1.53203 14.3787 1.59613 14.4413C1.66025 14.5039 1.73077 14.5352 1.80771 14.5352Z" />
    </SvgIcon>
  );
};

export default ElementsIcon;
