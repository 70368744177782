import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 17,
    height: 17,
    fill: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  className?: string;
};

const SearchIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 15 15"}>
      <path d="M13.4654 13.5171L8.69987 9.08573C8.30439 9.40445 7.843 9.65268 7.31569 9.83043C6.78838 10.0082 6.22812 10.097 5.6349 10.097C4.21117 10.097 3.00495 9.63735 2.01625 8.71798C1.02755 7.7986 0.533203 6.68922 0.533203 5.38983C0.533203 4.09044 1.02755 2.98106 2.01625 2.06168C3.00495 1.14231 4.20458 0.682617 5.61512 0.682617C7.01249 0.682617 8.20222 1.14231 9.18433 2.06168C10.1664 2.98106 10.6575 4.09044 10.6575 5.38983C10.6575 5.91694 10.5652 6.42566 10.3807 6.916C10.1961 7.40633 9.91927 7.86602 9.55015 8.29506L14.3552 12.7265C14.4739 12.8245 14.5332 12.9502 14.5332 13.1034C14.5332 13.2566 14.4673 13.3945 14.3355 13.5171C14.2168 13.6275 14.0718 13.6826 13.9004 13.6826C13.7291 13.6826 13.5841 13.6275 13.4654 13.5171ZM5.61512 8.99379C6.68292 8.99379 7.59253 8.64136 8.34394 7.93651C9.09535 7.23165 9.47106 6.38276 9.47106 5.38983C9.47106 4.3969 9.09535 3.54801 8.34394 2.84315C7.59253 2.1383 6.68292 1.78587 5.61512 1.78587C4.53414 1.78587 3.61465 2.1383 2.85665 2.84315C2.09865 3.54801 1.71964 4.3969 1.71964 5.38983C1.71964 6.38276 2.09865 7.23165 2.85665 7.93651C3.61465 8.64136 4.53414 8.99379 5.61512 8.99379Z" />
    </SvgIcon>
  );
};

export default SearchIcon;
