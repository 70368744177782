import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: 50,
    display: "flex",
    flexDirection: "row",
  },
  paper: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
    padding: 30,
  },
  adImage: {
    backgroundSize: "contain",
  },
}));

type PropTypes = {
  iframeUrl: string;
  width: number;
  height: number;
};

const AdIframePreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { iframeUrl, width, height } = props;

  return (
    <div className={classes.wrapper}>
      <Paper elevation={0} className={classes.paper}>
        <iframe frameBorder={"no"} scrolling={"no"} style={{ width, height }} src={iframeUrl} />
      </Paper>
    </div>
  );
};

export default AdIframePreview;
