import * as React from "react";
import { I18n } from "react-redux-i18n";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import StylePanelSubSection from "../components/StylePanelSubSection";
import FontSizeCSS from "../CssComponents/FontSizeCSS";
import FontColorCSS from "../CssComponents/FontColorCSS";

type PropTypes = {
  sectionTitle: string;
  cssKey: string;
  defaultValue?: string;
  handleChange: any;
  element: any;
  theme: IWebsiteThemeProps;
};

const TextOldElement = (props: PropTypes) => {
  const { theme, element, cssKey, defaultValue, sectionTitle, handleChange } = props;
  return (
    <StylePanelSubSection sectionTitle={sectionTitle}>
      <FontSizeCSS
        value={element.data?.[cssKey]?.fontSize || undefined}
        defaultValue={defaultValue || theme.typography.body2.css.fontSize}
        handleChange={(fontSize) => {
          handleChange({ ...element.data, [cssKey]: { ...element.data?.[cssKey], fontSize } });
        }}
      />
      <FontColorCSS
        value={element.data?.[cssKey]?.color || theme.palette.text.primary}
        defaultValue={theme.palette.text.primary}
        isValueChanged={false}
        handleChange={(color) => {
          handleChange({ ...element.data, [cssKey]: { ...element.data?.[cssKey], color } });
        }}
      />
    </StylePanelSubSection>
  );
};

export default TextOldElement;
