import * as React from "react";

type PropTypes = {
  visible: boolean;
};

const PageEditorCommentsWrapper = (props: PropTypes) => {
  const { visible } = props;
  return <div style={{ display: visible ? "block" : "none" }} id={"page_editor_comments_wrapper"} />;
};

export default PageEditorCommentsWrapper;
