import * as React from "react";
import { useSnackbar } from "notistack";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useParams } from "react-router";

import ProductsList from "./components/ProductsList";
import { IProduct, IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import { web } from "../../../../helpers/urlHelper";
import MPagination from "../../../../components/MPagination";
import useQuery from "../../../../hooks/useQuery";
import MTableWrapper from "../../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarBaseActionButton from "../../../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";
import ProductDialog from "../../../Modals/ProductDialog/ProductDialog";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  openProductPage: (productId: string) => {
    dispatch(push(web.croProducts(productId)));
  },
});

type PropTypes = {
  selectedWebsite: IWebsite;
};

const Products = (props: PropTypes) => {
  const { selectedWebsite } = props;

  const [loaded, setLoaded] = React.useState(false);
  const [products, setProducts] = React.useState<IProduct[]>([]);
  const { productId } = useParams();

  const queries = useQuery();
  const [page, setPage] = React.useState(queries.get("page") ? parseInt(queries.get("page"), 10) : 1);
  const [limit, setLimit] = React.useState(100);
  const [totalPages, setTotalPages] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState<IProduct | null>(null);

  const selectProduct = (product: IProduct) => {
    setSelectedProduct(product);
    window.history.pushState({}, "", web.croProducts(product._id));
  };

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }

    setLoaded(false);
    agent.Product.all(selectedWebsite._id, page, limit, searchText)
      .then((res) => {
        setProducts(res.items);
        setTotalPages(res.pages);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        console.log("e", e);
      });
  }, [selectedWebsite, searchText, page, limit]);

  React.useEffect(() => {
    console.log("productId", productId);
    if (!productId) {
      return;
    }
    agent.Product.get(selectedWebsite._id, productId)
      .then((res) => {
        setSelectedProduct(res.product);
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, [productId, selectedWebsite]);

  // const deleteProduct = (test) => {
  //   agent.LinksSplitTest.deleteTest(selectedWebsite._id, test)
  //     .then((res) => {
  //       setRefresh(true);
  //       enqueueSnackbar(I18n.t("snackbar.update_success"));
  //     })
  //     .catch((e) => {
  //       enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
  //       setRefresh(true);
  //       console.log("e", e);
  //     });
  // };

  const createNewProduct = () => {
    agent.Product.update({ name: `New Product ${Math.random().toString(36).substr(2, 5)}`, website: selectedWebsite })
      .then((res) => {
        enqueueSnackbar(I18n.t("snackbar.create_success", { name: res.product.name }));
        selectProduct(res.product);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.create_error", { msg: e.message }));
      });
  };
  const handleDeleteProduct = (product) => {
    agent.Product.remove(product)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(I18n.t("snackbar.update_error", { msg: res.message }));
          return;
        }
        setProducts(products.filter((p) => p._id !== product._id));
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: err.message || err.msg }));
      });
  };

  return (
    <>
      <MTableWrapper>
        <MHeaderMetaTags title={I18n.t(`meta_tags.cro.products.title`)} />
        <MTableToolbar
          searchText={searchText}
          searchPlaceHolder={I18n.t("products.table.search_placeholder")}
          handleSearchTextChanged={(search) => {
            setPage(1);
            setSearchText(search);
          }}
          actions={[
            <MTableToolbarBaseActionButton
              handleClick={() => createNewProduct()}
              text={I18n.t("products.table.new_product")}
            />,
          ]}
        />
        <ProductsList
          loaded={loaded}
          products={products}
          openProductPage={selectProduct}
          handleDeleteProductClick={(product) => handleDeleteProduct(product)}
        />
        {totalPages > 0 && (
          <MPagination
            paginationLimit={limit}
            page={page}
            paginationPagesCount={totalPages}
            setPaginationLimit={(l) => setLimit(l)}
            setPage={(p) => setPage(p)}
          />
        )}
      </MTableWrapper>
      {selectedProduct && (
        <ProductDialog
          selectedWebsite={selectedWebsite}
          product={selectedProduct}
          onClose={() => {
            setSelectedProduct(null);
            window.history.pushState({}, "", web.croProducts());
          }}
          onProductUpdated={(product) => {
            const index = products.findIndex((p) => p._id === product._id);
            const newProducts = [...products];
            if (index > -1) {
              newProducts[index] = product;
            } else {
              newProducts.unshift(product);
            }
            setProducts(newProducts);
          }}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
