import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles<Theme, PropTypes>((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 10,
  },
  cell: {
    width: 60,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    border: `${theme.palette.divider} 1px solid`,
    borderLeft: `none`,
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "17px",
  },
  firstCell: {
    borderRadius: "5px 0px 0px 5px",
    border: `${theme.palette.divider} 1px solid`,
    borderRight: `${theme.palette.divider} 1px solid`,
  },
  lastCell: {
    borderRadius: "0px 5px 5px 0px",
  },
  selectedCell: {
    backgroundColor: "#609EFF",
    border: `#609EFF 1px solid`,
    color: "white",
  },
  valueWrap: (props: PropTypes) => ({
    display: "flex",
    alignItems: "center",
  }),
  backToDefault: {
    color: theme.palette.text.hint,
    fontSize: 16,
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    //maxWidth: 60,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  id?: string;
  rowTitle: string;
  bold?: boolean;
  value: string;
  defaultValue?: string;
  isValueChanged?: boolean;
  selectOptions: any[];
  handleChange: any;
};

const ListElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    id,
    rowTitle,
    value = "",
    defaultValue = "",
    isValueChanged = false,
    bold = false,
    selectOptions,
    handleChange,
  } = props;

  const handleBackToDefault = () => {
    handleChange(defaultValue);
  };

  return (
    <div id={id} className={classes.row}>
      <Typography variant={"body2"} className={ClassNames(classes.text, (isValueChanged || bold) && classes.boldText)}>
        {rowTitle}
      </Typography>
      <div className={classes.valueWrap}>
        {isValueChanged && (
          <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
            <CloseIcon className={classes.backToDefault} onClick={handleBackToDefault} />
          </Tooltip>
        )}
        {selectOptions.map((option, index) => {
          return (
            <div
              className={ClassNames(
                classes.cell,
                index === 0 ? classes.firstCell : "",
                index === selectOptions.length - 1 ? classes.lastCell : "",
                value === option ? classes.selectedCell : ""
              )}
              onClick={(event) => {
                handleChange(event.target.innerText.toLowerCase());
              }}
            >
              {option}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ListElement;
