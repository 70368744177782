import { Switch, Theme, makeStyles } from "@material-ui/core";
import ClassNames from "classnames";
import React from "react";

type PropTypes = {
  onChange: (checked: boolean, e?: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label?: string;
  checked?: boolean;
  className?: string;
};

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  switch: {
    width: 30,
    padding: 0,
    paddingBlock: 14,
  },
  offSwitch: {},
  thumb: {
    height: 15,
    width: 15,
  },
  switchBase: {
    padding: 0,
    color: "#B2B7C2",
    transform: "translate(1px, 11px)",
    "&$checked": {
      color: palette.primary.main,
      transform: "translate(15px, 11px)",
    },
    "&$checked + $track": {
      backgroundColor: palette.primary.main,
      opacity: 0.4,
      marginLeft: 0,
      marginRight: 2,
    },
    "&disabled + $checked": {
      color: "black",
    },
  },
  checked: {},
  track: {
    marginLeft: 2,
  },
  disabled: {
    color: "#424242",
    cursor: "not-allowed",
    "&$checked": {
      color: "#424242",
      cursor: "not-allowed",
    },
    "&$checked + $track": {
      backgroundColor: palette.common.white,
      opacity: 0.1,
    },
  },
}));

const MSwitch = (props: PropTypes) => {
  const { checked, className } = props;
  const { onClick, onChange } = props;
  const classes = useStyles(props);

  return (
    <Switch
      checked={checked}
      onChange={(e, value) => onChange(value, e)}
      className={ClassNames(!checked && classes.offSwitch, classes.switch, className)}
      onClick={onClick}
      classes={{
        switchBase: classes.switchBase,
        checked: classes.checked,
        track: classes.track,
        disabled: classes.disabled,
        thumb: classes.thumb,
      }}
    />
  );
};

export default MSwitch;
