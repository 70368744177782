import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import MSelect from "../../../components/MSelect";
import _ from "lodash";
import { useTheme } from "@material-ui/styles";
import theme from "../../../themes/theme";

const useStyles = makeStyles<Theme, PropTypes>((theme) => ({
  row: (props: PropTypes) => ({
    display: "flex",
    justifyContent: props.flexDirection === "column" ? "start" : "space-between",
    flexDirection: props.flexDirection,
    alignItems: props.flexDirection === "column" ? "start" : "center",
    ...(props.flexDirection === "column" && { width: "100%" }),
    marginBottom: 10,
  }),
  valueWrap: (props: PropTypes) => ({
    display: "flex",
    alignItems: "center",
    ...(props.flexDirection === "column" && { width: "100%" }),
  }),
  backToDefault: {
    color: theme.palette.text.hint,
    fontSize: 16,
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectWrap: {
    width: (props) => (props.flexDirection === "column" ? props.width || "100%" : props.width || 180),
    textTransform: "capitalize",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
  },
  arrowIcon: {
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  selectMenu: {
    paddingLeft: 5,
    textTransform: "capitalize",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    //maxWidth: 60,
    marginBottom: (props: PropTypes) => (props.flexDirection === "column" ? 12 : 0),
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  id?: string;
  title: string;
  bold?: boolean;
  value: string;
  defaultValue?: string;
  isValueChanged?: boolean;
  flexDirection?: "column" | "row";
  selectOptions: any[];
  width?: number | string;
  handleChange: any;
  height?: number;
  borderColor?: theme.palette.divider;
};

const ListBaseComponent = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    id,
    title,
    value = "",
    defaultValue = "",
    isValueChanged = false,
    bold = false,
    height = 32,
    selectOptions,
    handleChange,
    borderColor,
  } = props;

  const optionsList = selectOptions.map((option, index) => ({
    _id: index,
    name: option,
  }));

  const handleBackToDefault = () => {
    handleChange(defaultValue);
  };
  const theme = useTheme<Theme>();
  return (
    <div id={id} className={classes.row}>
      <Typography variant={"body2"} className={ClassNames(classes.text, (isValueChanged || bold) && classes.boldText)}>
        {title}
      </Typography>
      <div className={classes.valueWrap}>
        {isValueChanged && (
          <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
            <CloseIcon className={classes.backToDefault} onClick={handleBackToDefault} />
          </Tooltip>
        )}
        <MSelect
          height={height}
          options={optionsList}
          value={_.find(optionsList, { name: value })}
          optionLabel={"name"}
          optionValue={"_id"}
          menuPlacement={"bottom"}
          borderWidth={"0px"}
          borderColor={borderColor}
          customClass={classes.selectWrap}
          customDropdownIndicatorClasses={classes.arrowIcon}
          optionBackgroundColorHover={theme.palette.secondary.main}
          handleChange={(value) => handleChange(value.name)}
        />
      </div>
    </div>
  );
};
export default ListBaseComponent;
