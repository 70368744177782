import React from "react";
import { connect } from "react-redux";

import WidgetContainerMainView from "./components/WidgetContainerMainView";
import {
  WidgetRuleEditorRootState,
  WidgetRuleEditorSelectedView,
} from "../../../../../../../../../reducers/widgetRuleEditorReducer";
import WidgetContainerPositionView from "./components/WidgetContainerPositionView";
import { WIDGET_RULE_EDITOR } from "../../../../../../../../../reducers/constants/actionTypes";

const mapStateToProps = (state) => ({
  rule: state.widgetRuleEditor.rule,
  selectedView: state.widgetRuleEditor.selectedView,
  selectedWidgetContainerId: state.widgetRuleEditor.selectedWidgetContainerId,
});

const mapDispatchToProps = (dispatch) => ({
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PROPERTY_CHANGE, property });
  },
});

type PropTypes = {
  rule: WidgetRuleEditorRootState["rule"];
  selectedView: WidgetRuleEditorSelectedView;
  selectedWidgetContainerId: string;
};

const RuleBuilderContainerSettings = (props: PropTypes) => {
  const { rule, selectedView, selectedWidgetContainerId } = props;
  const selectedWidgetContainer = rule.widgetContainers.find(
    (container) => container.containerId === selectedWidgetContainerId
  );

  if (!selectedWidgetContainer) {
    return null;
  }

  switch (selectedView) {
    case WidgetRuleEditorSelectedView.WIDGET_CONTAINER_SETTINGS:
      return <WidgetContainerMainView />;
    case WidgetRuleEditorSelectedView.WIDGET_CONTAINER_SETTINGS_POSITION:
      return <WidgetContainerPositionView />;
    default:
      throw new Error("Invalid widget container settings view");
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleBuilderContainerSettings);
