// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-param-reassign */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-shadow */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useRef, FC, useCallback, useEffect } from "react";
import { Timeline, TimelineAction } from "@xzdarcy/react-timeline-editor";
import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _, { cloneDeep, debounce, pick, round } from "lodash";
import { Droppable } from "react-beautiful-dnd";

import { useVideoEditorContext } from "../VideoEditorContext";
import { ITimelineAction, ITimelineRow } from "../../../reducers/constants/objectTypes";
import ability from "../../../casl/ability";
import { DEV } from "../../../reducers/constants/consts";

type OnActionResizingParams = {
  action: ITimelineAction;
  row: ITimelineRow;
  start: number;
  end: number;
  dir: "left" | "right";
};

const useStyles = makeStyles((theme: Theme) => ({
  timelinePanelWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    "& .timeline-editor": {
      backgroundColor: "#fff",
    },
    "& .timeline-editor-time-area": {
      backgroundColor: "#F2F6FC",
    },
    "& .timeline-editor-time-unit-scale": {
      color: theme.palette.text.secondary,
    },
    "& .timeline-editor-time-unit": {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    "& .timeline-editor-edit-row": {
      backgroundImage: "none",
      backgroundColor: "#F5F6F8",
    },
    "& .timeline-editor-edit-row:last-child": {
      backgroundColor: "unset",
    },
    "& .timeline-editor-cursor": {
      borderLeft: "2px solid #000",
      borderRight: "2px solid #000",
      "& > path": {
        fill: "#000",
      },
    },
    "& .timeline-editor-action": {
      backgroundColor: "unset",
    },
  },
  timelinePanelActions: {
    overflow: "overlay",
    width: 150,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingRight: 2,
  },
  timelinePanelActionsTop: {
    height: 32,
    backgroundColor: "#E8EEFA",
    marginBottom: 10,
  },
  actionItem: {
    width: "100%",
    textTransform: "capitalize",
    color: theme.palette.text.secondary,
    fontSize: 14,
    height: 40,
    backgroundColor: "#F5F6F8",
    marginBottom: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CustomScale = (props: { scale: number }) => {
  const { scale } = props;
  const min = parseInt(`${scale / 60}`, 10);
  const second = `${scale % 60}`.padStart(2, "0");
  return <>{`${min}:${second}`}</>;
};

const CustomRender: FC<{
  action: ITimelineAction;
  row: ITimelineRow;
  selectedActionId: string;
}> = ({ row, action, selectedActionId }) => {
  const selected = selectedActionId === action.id;
  const actionIndex = row.actions.findIndex((a) => a.id === action.id);
  return (
    <div
      style={{
        marginLeft: actionIndex * 3,
        width: "100%",
        height: "100%",
        color: "white",
        borderRadius: 5,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: `${selected ? "#8498c0" : "#C7D5F0"}`,
        wordBreak: "break-all",
      }}
    >
      {DEV ? JSON.stringify(pick(action, ["id", "start", "end", "trimStart", "trimEnd", "minStart", "maxEnd"])) : ""}
    </div>
  );
};

const TimelinePanel = () => {
  const {
    videoProject,
    selectedTimeline,
    timelineEffects,
    selectedActionId,
    timelineRef,
    timelineState,
    jumpToTime,
    setSelectedActionId,
    editTimelineRows,
    editVideoEditorTimelineProperty,
  } = useVideoEditorContext();
  const { zoom, zoomWidth } = timelineState;
  const timelineRows = selectedTimeline.rows;

  const [currentResizeVideoActionIndex, setCurrentResizeVideoActionIndex] = React.useState<number>(null);
  const [editorData, setEditorData] = React.useState<ITimelineRow[]>(
    cloneDeep(timelineRows.filter((row) => row.type !== "comment"))
  );

  const classes = useStyles();
  const domRef = useRef<HTMLDivElement>();
  const timelineDomRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const newRows = timelineRows
      .filter((row) => row.type !== "comment")
      .map((r) => ({
        ...r,
        actions: r.actions.map((a, i) => {
          const canEdit = ability.can("edit", "video_project");
          return {
            ...a,
            flexible: canEdit,
            // todo: replace it with canEdit when ready
            movable: false,
          };
        }),
      }));
    setEditorData(cloneDeep(newRows));

    const adjustZoom = () => {
      // adjusts zoom to fit the timeline on screen
      if (!timelineDomRef?.current || !timelineDomRef.current?.offsetWidth) return;

      const timelineWidth = timelineDomRef.current.offsetWidth - 160;
      const totalActionsDuration = newRows.reduce(
        (acc, row) => row.actions.reduce((acc, action) => acc + round(action.end - action.start, 0), acc),
        0
      );

      if (totalActionsDuration === 0) return;
      let zoom = 10;

      function calculatePixelsAtZoom(x: number) {
        return timelineState.zoomWidth / x;
      }

      if (totalActionsDuration * calculatePixelsAtZoom(zoom) > timelineWidth) {
        while (totalActionsDuration * calculatePixelsAtZoom(zoom) > timelineWidth) {
          zoom += 5;
        }
      }

      const zoomScale = Math.max(zoom / 100, 1);
      editVideoEditorTimelineProperty({
        zoom: round(zoom, 0),
        scale: zoomScale,
      });
    };

    adjustZoom();
  }, [editVideoEditorTimelineProperty, timelineRows, timelineState.zoomWidth]);

  useEffect(() => {
    if (!timelineRef.current) return;
    const engine = timelineRef.current;
    engine.listener.on("play", () => {
      console.log("play trigger");
      editVideoEditorTimelineProperty({
        isPlaying: true,
      });
    });
    engine.listener.on("paused", () => {
      console.log("paused trigger");
      editVideoEditorTimelineProperty({
        isPlaying: false,
      });
    });
    engine.listener.on("afterSetTime", ({ time }) =>
      editVideoEditorTimelineProperty({
        time,
      })
    );
    engine.listener.on("setTimeByTick", ({ time }) =>
      editVideoEditorTimelineProperty({
        time,
      })
    );

    // eslint-disable-next-line consistent-return
    return () => {
      if (!engine) return;
      engine.pause();
      engine.listener.offAll();
    };
  }, [editVideoEditorTimelineProperty, timelineRef]);

  const updateTimelineRows = useCallback(
    (rows: ITimelineRow[]) => {
      editTimelineRows(rows);
    },
    [editTimelineRows]
  );

  const onActionResizing = useCallback(
    (params: OnActionResizingParams) => {
      const { action: paramAction, row, start, end, dir } = params;
      const oldAction = timelineRows.find((r) => r.type === row.type).actions.find((a) => a.id === paramAction.id);
      const newAction = { ...oldAction };
      const currentActionIndex = row.actions.findIndex((a) => a.id === oldAction.id);

      let timeDifference = 0;

      if (dir === "left") {
        timeDifference = round(start - oldAction.start, 2);
        console.log("timeDifference", timeDifference, start, oldAction.start);
        newAction.trimStart = round(newAction.trimStart + timeDifference, 2);
        const prevAction = row.actions[currentActionIndex - 1];
        newAction.end = round(newAction.end - timeDifference);

        if (prevAction) {
          newAction.start = prevAction.end;
        } else {
          newAction.start = start;
        }
      } else if (dir === "right") {
        timeDifference = round(end - oldAction.end, 2);
        console.log("timeDifference", timeDifference, end, oldAction.end);
        newAction.trimEnd = round(newAction.trimEnd + timeDifference, 2);
        newAction.end = round(newAction.end + timeDifference, 2);
      }

      row.actions[currentActionIndex] = newAction;
      const newRows = [...timelineRows];
      console.log("resizing new", row.actions, newAction);
      const newActions = row.actions.map((a, index) => {
        console.log("resizing prev", row.actions?.[index - 1]);
        return {
          ...a,
          id: `${row.type}-${index}`,
          start: row.actions?.[index - 1]?.end || 0,
          end:
            index > currentActionIndex
              ? round(dir === "left" ? a.end - timeDifference : a.end + timeDifference, 2)
              : a.end,
          minStart: round((row.actions?.[index - 1]?.end || 0) - a.trimStart, 2),
          maxEnd: round(a.end + (a.video.duration - a.trimEnd), 2),
        };
      });

      const currentRowIndex = newRows.findIndex((r) => r.type === row.type);
      newRows[currentRowIndex].actions = newActions;
      updateTimelineRows(newRows);
    },
    [timelineRows, updateTimelineRows]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnActionResizing = useCallback(
    debounce((params: OnActionResizingParams) => {
      onActionResizing(params);
    }, 200),
    [onActionResizing]
  );

  React.useEffect(() => {
    if (!timelineDomRef.current) return;
    const videoActions = timelineDomRef.current.getElementsByClassName("timeline-editor-action-flexible");
    if (!videoActions || !videoActions.length) return;
    Array.from(videoActions as HTMLCollectionOf<HTMLElement>).forEach((videoAction, index) => {
      if (index === currentResizeVideoActionIndex) videoAction.style.zIndex = "100";
      else videoAction.style.zIndex = "0";
    });
  }, [currentResizeVideoActionIndex]);

  return (
    <div id="timepanel" className={classes.timelinePanelWrapper} ref={timelineDomRef}>
      <div
        ref={domRef}
        onScroll={(e) => {
          const target = e.target as HTMLDivElement;
          timelineRef.current.setScrollTop(target.scrollTop);
        }}
        className={classes.timelinePanelActions}
      >
        <div className={classes.timelinePanelActionsTop}></div>
        {editorData.map((row, index) => (
          <Typography variant="body1" className={classes.actionItem} key={index}>
            {row.id}
          </Typography>
        ))}
      </div>
      <Droppable droppableId="timeline">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={{ width: "100%", height: `100%` }}>
            <Timeline
              style={{ width: "100%", height: `100%` }}
              ref={timelineRef}
              editorData={editorData}
              effects={timelineEffects}
              dragLine={true}
              autoScroll={true}
              scale={zoom}
              rowHeight={40}
              scaleWidth={zoomWidth}
              scaleSplitCount={10}
              onChange={() => {}}
              onClickRow={(_, param: { row: ITimelineRow; time: number }) => {
                console.log(`onClickRow ${JSON.stringify(param)}`);
              }}
              onClickTimeArea={(time: number) => {
                console.log(`onClickTimeArea ${time}`);
                jumpToTime(time);
                return false;
              }}
              // onClickTimeArea={(time: number, e: MouseEvent<HTMLDivElement, MouseEvent>) => {
              //   console.log(`onClickTimeArea ${time}`);
              //   return true;
              // }}
              onClickAction={(_, param: { action: TimelineAction; row: ITimelineRow; time: number }) => {
                console.log(`onClickAction ${JSON.stringify(param)}`);
                setSelectedActionId(param.action.id);
                timelineRef.current.setTime(param?.action?.start >= 0 ? param.action.start : param.time);
              }}
              onCursorDrag={(time: number) => console.log(`onCursorDrag ${time}`)}
              getScaleRender={(sc) => <CustomScale scale={sc} />}
              onScroll={({ scrollTop }) => {
                domRef.current.scrollTop = scrollTop;
              }}
              onActionResizeStart={({ action }) =>
                setCurrentResizeVideoActionIndex(
                  timelineRows.find((row) => row.type === "video").actions.findIndex((a) => a.id === action.id)
                )
              }
              onActionResizeEnd={({ action }) => setCurrentResizeVideoActionIndex(null)}
              getActionRender={(action, row) => (
                <CustomRender action={action} row={row as ITimelineRow} selectedActionId={selectedActionId} />
              )}
              onActionResizing={(params) => {
                debouncedOnActionResizing(params as OnActionResizingParams);
              }}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default TimelinePanel;
