import React from "react";
import ClassNames from "classnames";
import numeral from "numeral";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { ImageListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import agent from "../../../../agent";
import CfProgressBar from "../../../../components/CfProgressBar";
import { IFile, IWebsite } from "../../../../reducers/constants/objectTypes";
import { validPxSizeImage } from "../../../../helpers/imagePickerHelper";

const useStyles = makeStyles((theme: Theme) => ({
  otherOptionWrapper: {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='1' stroke-dasharray='12%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
		border-radius: 10px;`,
    borderRadius: 10,
    background: "#F5F6F8",
    margin: "0 0 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 60,
    height: 211,
    justifyContent: "center",
  },
  errorBorderColor: {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='red' stroke-width='1' stroke-dasharray='12%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
		border-radius: 10px;`,
  },
  dragText: {
    margin: "auto",
  },
  dropImagesText: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.secondary,
  },
  orText: {
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
    color: theme.palette.text.secondary,
  },
  uploadImageWrapper: {},
  input: {
    display: "none",
  },
  uploadButton: {
    textTransform: "capitalize",
    borderRadius: 30,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  infoMessage: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    fontSize: 14,
    marginTop: 15,
    color: theme.palette.text.secondary,
  },
  redMessage: {
    color: "#F04444",
  },
}));

type PropTypes = {
  website: IWebsite;
  validImage: boolean;
  imageSizeLimit: number;
  loading: boolean;
  forcesImageSize: {
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
    ratio?: number;
  };
  files: IFile[];
  setSelectedFile: (selectedFile) => void;
  setFiles: (files) => void;
  setValidImage: (value) => void;
};

const MAX_IMAGE_SIZE_LIMIT = 500000;

const ImageDragAndDrop = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    website,
    validImage,
    forcesImageSize,
    imageSizeLimit,
    loading,
    files,
    setValidImage,
    setFiles,
    setSelectedFile,
  } = props;
  const [uploadInProgress, setUploadInProgress] = React.useState(false);
  const [dragOver, setDragOver] = React.useState(false);

  const uploadFile = (image) => {
    console.log("uploadFile", image);
    if (!image.type.match(/image\/.*/g)) {
      alert("This is not an Image File!");
      return;
    }

    if (image.size > (imageSizeLimit || MAX_IMAGE_SIZE_LIMIT)) {
      alert(
        `File size exceeds the maximum limit of ${numeral(imageSizeLimit || MAX_IMAGE_SIZE_LIMIT).format(
          "0.0 b"
        )}!, uploaded file size - ${numeral(image.size).format("0.0 b")}`
      );
      return;
    }
    setUploadInProgress(true);

    const formData = new FormData();
    formData.append("file", image);
    console.log("uploadFile: formData", formData);

    agent.Files.upload(website._id, image, formData)
      .then((res) => {
        console.log("file upload res", res);
        setUploadInProgress(false);
        setSelectedFile(res.file);
        setFiles([res.file, ...files]);
      })
      .catch((e) => {
        console.log("file upload error", e.message);
        setUploadInProgress(false);
      });
  };
  const onDropFile = (file) => {
    uploadFile(file);
  };

  const handleImageUploaded = async ({ target }) => {
    if (await validPxSizeImage(URL.createObjectURL(target.files[0]), forcesImageSize)) {
      setValidImage(true);
      uploadFile(target.files[0]);
      return;
    }
    setValidImage(false);
    setSelectedFile(null);
  };

  return (
    <ImageListItem cols={6} rows={3} style={{ height: "auto", width: "100%" }}>
      <div
        className={ClassNames(classes.otherOptionWrapper, !validImage && classes.errorBorderColor)}
        onDragOver={(e) => {
          e.preventDefault();
          setDragOver(!uploadInProgress);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setDragOver(false);
        }}
        onDrop={(e) => {
          e.preventDefault();
          const file = e.dataTransfer.files[0];
          onDropFile(file);
        }}
      >
        {dragOver ? (
          <Typography className={classes.dragText} variant="h5">
            {I18n.t("rich_text_editor.insert_image_dialog.release_drag")}
          </Typography>
        ) : (
          <>
            <Typography className={classes.dropImagesText} variant={"body1"}>
              {I18n.t("rich_text_editor.insert_image_dialog.drop_images")}
            </Typography>
            <Typography className={classes.orText} variant={"body2"}>
              {I18n.t("rich_text_editor.insert_image_dialog.or")}
            </Typography>
            <div className={classes.uploadImageWrapper}>
              <input
                accept="image/*"
                className={classes.input}
                id="upload-image-button"
                onChange={(e) => handleImageUploaded(e)}
                type="file"
              />
              <label htmlFor="upload-image-button">
                {uploadInProgress && <CfProgressBar />}
                {!uploadInProgress && (
                  <Button className={classes.uploadButton} variant="outlined" component="span">
                    {I18n.t("rich_text_editor.insert_image_dialog.upload")}
                  </Button>
                )}
              </label>
            </div>
          </>
        )}
      </div>
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CfProgressBar />
        </div>
      )}
    </ImageListItem>
  );
};

export default ImageDragAndDrop;
