import { Divider, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldProperty from "./itemProperties/components/TextFieldProperty";
import RequiredFieldProperty from "./itemProperties/components/RequiredFieldProperty";
import TextareaProperty from "./itemProperties/components/TextareaProperty";
import { IWidgetCheckBoxFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import MultipleOptionsFieldProp from "./components/MultipleOptionsFieldProp";
import SwitchProperty from "./itemProperties/components/SwitchProperty";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({}),
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
    buttonAdd: () => {
      const backgroundColor = isDark ? palette.secondary.main : palette.common.white;
      return {
        backgroundColor,
        marginTop: -2,
      };
    },
    dividerSettings: {
      border: "0.5px solid #404142",
    },
    switch: {
      padding: 0,
      height: 55,
    },
  };
});

type PropTypes = {
  properties: IWidgetCheckBoxFieldProps;
  onPropertyChange(properties: IWidgetCheckBoxFieldProps): void;
};

const CheckboxWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.label")}
          placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.label_ph")}
          onChange={(label) => onPropertyChange({ ...properties, label })}
          value={properties?.label}
        />
        <Divider className={classes.divider} />
        <TextareaProperty onChange={(hint) => onPropertyChange({ ...properties, hint })} value={properties?.hint} />
      </div>

      <MultipleOptionsFieldProp
        options={properties.options}
        handleOptionsChanges={(options) => onPropertyChange({ ...properties, options })}
      />

      <SwitchProperty
        className={classes.switch}
        label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.other_option")}
        checked={properties.otherOption}
        onChange={(checked) => onPropertyChange({ ...properties, otherOption: checked })}
      />
      <Divider className={classes.dividerSettings} />
      <SwitchProperty
        className={classes.switch}
        label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.multiple_selection")}
        checked={properties.multipleSelection}
        onChange={(checked) => onPropertyChange({ ...properties, multipleSelection: checked })}
      />
      <Divider className={classes.dividerSettings} />
      <SwitchProperty
        className={classes.switch}
        label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.randomize_options")}
        checked={properties.randomizeOptions}
        onChange={(checked) => onPropertyChange({ ...properties, randomizeOptions: checked })}
      />
      <Divider className={classes.dividerSettings} />
      <RequiredFieldProperty
        required={properties.required}
        onChange={(required) => onPropertyChange({ ...properties, required })}
      />
    </div>
  );
};

export default CheckboxWidgetItem;
