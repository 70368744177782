import * as React from "react";
import { connect } from "react-redux";
import _, { find } from "lodash";
import { I18n } from "react-redux-i18n";
import KeywordsClassificationList from "./KeywordsClassificationList";
import MTableWrapper from "../../components/Table/MTableWrapper";
import MTableToolbar from "../../components/Table/Toolbar/MTableToolbar";
import { IKeywordClassification, IWebsite } from "../../reducers/constants/objectTypes";
import { KEYWORDCLASSIFICATION } from "../../reducers/constants/actionTypes";
import MTableToolbarBaseSelect from "../../components/Table/Toolbar/MTableToolbarBaseSelect";
import MTableToolbarBaseActionButton from "../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import agent from "../../agent";
import AddKeywordsDialog from "../KeywordsClassification/components/AddKeywordsDialog";
import NgramGeneratorDialog from "../KeywordsClassification/components/NgramGeneratorDialog";
import MTablePagination from "../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../components/MHeaderMetaTags";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  selectOptionLabel: {
    textTransform: "none",
  },
}));
const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  wordCounts: state.keywordsClassification.wordCounts,
  selectedWordsCount: state.keywordsClassification.filter.selectedWordsCount,
  searchText: state.keywordsClassification.filter.searchText,
  selectedType: state.keywordsClassification.filter.selectedType,
  selectedLevel: state.keywordsClassification.filter.selectedLevel,
  selectedTopic: state.keywordsClassification.selectedTopic,
  selectedTypeFilter: state.keywordsClassification.filter.selectedType,
  selectedLevelFilter: state.keywordsClassification.filter.selectedLevel,
  selectedTopicFilter: state.keywordsClassification.selectedTopic,
  types: state.keywordsClassification.types,
  levels: state.keywordsClassification.levels,
  selectedKeywordsClassificationList: state.keywordsClassification.selectedKeywordsClassificationList,
  topics: state.keywordsClassification.tags,
  sortField: state.keywordsClassification.filter.sortField,
  paginationNumber: state.keywordsClassification.paginationNumber,
  page: state.keywordsClassification.page,
  totalCount: state.keywordsClassification.totalCount,
  isAddKeywordsDialogOpen: state.keywordsClassification.isAddKeywordsDialogOpen,
  isNgramDialogOpen: state.keywordsClassification.isNgramDialogOpen,
});

const mapDispatchToProps = (dispatch) => ({
  changePaginationNumber: (paginationNumber) => {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_PAGINATION_NUMBER, payload: paginationNumber });
  },
  changePage: (page) => {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_PAGE, payload: page });
  },
  changeSelectedWordCount: (wordCount) => {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_SELECTED_WORD_COUNT, payload: wordCount });
  },
  changeSearchText: (text) => {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_SEARCH_TEXT, payload: text });
  },
  changeSelectedTopic(topic: string) {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_SELECTED_TOPIC, payload: topic });
  },
  changeSelectedType(type: string) {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_SELECTED_TYPE, payload: type });
  },
  changeSelectedLevel(level: string) {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_SELECTED_LEVEL, payload: level });
  },
  changeTypes(types) {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_TYPES, payload: types });
  },
  toggleAddKeywordsDialog: (open) => {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_ADD_KEYWORDS_DIALOG_OPEN, payload: open });
  },
  toggleNgramGeneratorDialog: (open) => {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_NGRAM_DIALOG_OPEN, payload: open });
  },
  onFilterChanged: (payload) => {
    dispatch({ type: KEYWORDCLASSIFICATION.CHANGE_FILTERS, payload });
  },
  loading: (loading) => {
    dispatch({ type: KEYWORDCLASSIFICATION.LOADING, loading });
  },
  handleCheckedBoxChanged: (selectedKeywordsClassificationList) => {
    dispatch({
      type: KEYWORDCLASSIFICATION.CHANGE_SELECTED_KEYWORDS_CLASSIFICATION_LIST,
      payload: selectedKeywordsClassificationList,
    });
  },
  keywordsClassificationLoaded: (payload) => {
    dispatch({ type: KEYWORDCLASSIFICATION.KEYWORDS_CLASSIFICATION_LOADED, payload });
  },
});

type PropTypes = {
  wordCounts: {
    value: number;
    name: string;
  }[];
  loading: () => void;
  keywordsClassificationLoaded: (payload: any) => void;
  handleCheckedBoxChanged: (checkedKeywordsClassificationList: IKeywordClassification[]) => void;
  selectedWordsCount: number;
  searchText: string;
  selectedTypeFilter: string;
  selectedLevelFilter: string;
  selectedTopicFilter: string;
  selectedWebsite: IWebsite;
  types: any[];
  levels: any[];
  topics: any[];
  selectedType: string;
  selectedLevel: string;
  selectedTopic: string;
  selectedKeywordsClassificationList: IKeywordClassification[];
  sortField: { field: string; direction: "asc" | "desc" };
  toggleAddKeywordsDialog: (open: boolean) => void;
  toggleNgramGeneratorDialog: (open: boolean) => void;
  changeTypes: (types) => void;
  changeSelectedTopic: (topic: string) => void;
  changeSelectedType: (type: string) => void;
  changeSelectedLevel: (level: string) => void;
  changeSelectedWordCount: (wordCount: string) => void;
  changeSearchText: (text: string) => void;
  onFilterChanged: (payload) => void;
  page: number;
  totalCount: number;
  paginationNumber: string;
  changePage(page: number): void;
  changePaginationNumber: (paginationNumber: string) => void;
  isAddKeywordsDialogOpen: boolean;
  isNgramDialogOpen: boolean;
};
const ExcludeKeywords = (props: PropTypes) => {
  const classes = useStyles(props);

  const {
    searchText,
    onFilterChanged,
    wordCounts,
    selectedWordsCount,
    types,
    selectedTypeFilter,
    levels,
    selectedLevelFilter,
    toggleNgramGeneratorDialog,
    toggleAddKeywordsDialog,
    selectedWebsite,
    changeTypes,
    sortField,
    loading,
    handleCheckedBoxChanged,
    selectedLevel,
    selectedType,
    selectedTopic,
    page,
    paginationNumber,
    selectedKeywordsClassificationList,
    keywordsClassificationLoaded,
    totalCount,
    changePage,
    changePaginationNumber,
    isAddKeywordsDialogOpen,
    isNgramDialogOpen,
  } = props;

  const fetchTypes = async (search) => {
    agent.KeywordsClassification.getKeywordsTypes(selectedWebsite._id, search, undefined, undefined, true)
      .then((res) => {
        changeTypes(_.orderBy(res.data, ["name"], ["asc"]));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    fetchTypes("");
  }, []);

  const getKeywords = async () => {
    loading();
    const res = await agent.KeywordsClassification.getKeywords(
      selectedWebsite._id,
      selectedTopic,
      selectedLevel,
      selectedType,
      selectedWordsCount,
      searchText,
      paginationNumber,
      page,
      { [sortField.field]: sortField.direction }
    );
    keywordsClassificationLoaded(res);
  };

  React.useEffect(() => {
    getKeywords();
  }, [
    selectedWebsite._id,
    selectedTopic,
    selectedLevel,
    selectedType,
    selectedWordsCount,
    searchText,
    paginationNumber,
    page,
    sortField,
  ]);
  const deleteMany = async () => {
    loading();
    handleCheckedBoxChanged([]);
    await agent.KeywordsClassification.deleteManyKeywords(selectedKeywordsClassificationList.map((item) => item._id));
    getKeywords();
  };

  const selectedRowActions = React.useMemo(
    () => [
      {
        onClick: deleteMany,
        text: I18n.t("classify_keywords.exclude_exact_match"),
        tooltip: I18n.t("classify_keywords.exclude_exact_match"),
        color: "#F04444",
      },
    ],
    [selectedKeywordsClassificationList]
  );
  return (
    <MTableWrapper>
      {/* <KeywordsClassificationHeader /> */}
      <MHeaderMetaTags title={I18n.t("meta_tags.strategy.classify.title")} />
      <MTableToolbar
        searchText={searchText}
        selectedRows={selectedKeywordsClassificationList?.length}
        onClearSelectedRows={() => handleCheckedBoxChanged([])}
        selectedRowActions={selectedRowActions}
        searchPlaceHolder={I18n.t("classify_keywords.search")}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
        actions={[
          <MTableToolbarBaseActionButton
            handleClick={() => {
              toggleNgramGeneratorDialog(true);
            }}
            text={I18n.t("classify_keywords.generate_ngrams")}
          />,
          <MTableToolbarBaseActionButton
            handleClick={() => {
              toggleAddKeywordsDialog(true);
            }}
            text={I18n.t("classify_keywords.add_keywords")}
          />,
        ]}
      >
        <MTableToolbarBaseSelect
          options={wordCounts}
          optionValue={"value"}
          placeholder={I18n.t("classify_keywords.word_count_ph")}
          labelClassName={classes.selectOptionLabel}
          selectedOptions={selectedWordsCount ? [find(wordCounts, { value: selectedWordsCount })] : []}
          handleChange={(values) => {
            onFilterChanged({ selectedWordsCount: values?.[0]?.value });
          }}
        />
        <MTableToolbarBaseSelect
          options={types}
          placeholder={I18n.t("classify_keywords.select_type")}
          selectedOptions={selectedTypeFilter ? [find(types, { _id: selectedTypeFilter })] : []}
          handleChange={(values) => {
            onFilterChanged({ selectedType: values?.[0]?._id });
          }}
        />
        <MTableToolbarBaseSelect
          options={levels}
          placeholder={I18n.t("classify_keywords.select_level")}
          selectedOptions={selectedLevelFilter ? [find(wordCounts, { name: selectedLevelFilter })] : []}
          handleChange={(values) => {
            onFilterChanged({ selectedLevel: values?.[0]?.name });
          }}
        />
      </MTableToolbar>
      <KeywordsClassificationList
        sortField={sortField}
        setSortFiled={(value) => onFilterChanged({ sortField: value })}
      />
      {totalCount > 0 && (
        <MTablePagination
          paginationLimit={Number(paginationNumber)}
          page={page}
          paginationPagesCount={Math.ceil(totalCount / parseInt(paginationNumber, 10))}
          setPaginationLimit={(v) => changePaginationNumber(String(v))}
          setPage={changePage}
        />
      )}

      <AddKeywordsDialog
        open={isAddKeywordsDialogOpen}
        types={types}
        handleCloseClick={() => toggleAddKeywordsDialog(false)}
        handleLoadKeywords={getKeywords}
      />
      <NgramGeneratorDialog
        open={isNgramDialogOpen}
        handleCloseClick={() => toggleNgramGeneratorDialog(false)}
        handleLoadKeywords={getKeywords}
      />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExcludeKeywords);
