import React from "react";
import { I18n } from "react-redux-i18n";
import GenericInputWidgetItem from "./components/GenericInputWidgetItem";
import { IWidgetInputFieldProps } from "../../../../../../../reducers/constants/objectTypes";

type PropTypes = {
  properties: IWidgetInputFieldProps;
  onPropertyChange(properties: IWidgetInputFieldProps): void;
};

const PhoneWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;

  return (
    <GenericInputWidgetItem
      labelPlaceholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.phone")}
      placeholderPlaceholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.phone_ph")}
      properties={properties}
      onPropertyChange={onPropertyChange}
    />
  );
};

export default PhoneWidgetItem;
