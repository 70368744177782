import React, { createContext, useContext, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";
import { TimelineEffect, TimelineState } from "@xzdarcy/react-timeline-editor";

import {
  IWebsite,
  IAdminUser,
  ICategory,
  IVideoProject,
  ITimelineRow,
  ITimelineState,
  IVideoElement,
  IVideoProjectTimeline,
  eVideoProjectTool,
  ITimelineAction,
} from "../../reducers/constants/objectTypes";
import { VIDEO_EDITOR } from "../../reducers/constants/actionTypes";

type ReduxRootState = {
  videoEditor: IVideoEditor;
  home: {
    selectedWebsite: IWebsite;
    admin: IAdminUser;
  };
  category: {
    categories: ICategory[];
  };
};

export type IVideoEditor = {
  canvas: { width: number; height: number; color: string };
  videoProject: IVideoProject;
  // todo - remove videoElement
  videoElement: IVideoElement;
  timelineState: ITimelineState;
  timelineEffects: Record<string, TimelineEffect>;
  selectedActionId: string | null;
  selectedTool?: eVideoProjectTool;
  selectedTab: "media" | "files" | "comments";
  pendingUploads: {
    videos: File[];
    audios: File[];
    images: File[];
  };
};

type VideoEditorContextProps = IVideoEditor & {
  admin: IAdminUser;
  selectedWebsite: IWebsite;
  categories: ICategory[];
  selectedTimeline: IVideoProjectTimeline;
  commentActions: ITimelineAction[];
  timelineRef: React.RefObject<TimelineState>;
  videoPlayerRef: React.RefObject<HTMLVideoElement>;
  commentsWrapperRef: React.RefObject<HTMLDivElement>;
  jumpToTime: (time: number) => void;
  setSelectedActionId: (selectedActionId: string | null) => void;
  setSelectedTimeline: (fileIndex: number) => void;
  setPendingUploads: (pendingUploads: { videos: File[]; audios: File[]; images: File[] }) => void;
  editVideoProject: (videoProject: IVideoProject) => void;
  editVideoEditorProperty: (value: Partial<IVideoEditor>) => void;
  editVideoEditorTimelineProperty: (value: Partial<IVideoEditor["timelineState"]>) => void;
  editTimelineRows: (timelineRows: ITimelineRow[]) => void;
  focusComment: (commentId: string) => void;
  returnToPreviousScreen: () => void;
};

const VideoEditorContext = createContext<VideoEditorContextProps | undefined>(undefined);

export const VideoEditorProvider = ({ children }) => {
  const timelineRef = React.useRef<TimelineState>(null);
  const videoPlayerRef = React.useRef(null);
  const commentsWrapperRef = React.useRef(null);
  const dispatch = useDispatch();

  const { videoEditor, selectedWebsite, admin, categories } = useSelector((state: ReduxRootState) => ({
    selectedWebsite: state.home.selectedWebsite,
    admin: state.home.admin,
    categories: state.category.categories,
    videoEditor: state.videoEditor,
  }));

  const { videoProject } = videoEditor;

  const selectedTimeline = useMemo(() => videoProject.timelines.find((timeline) => timeline.selected), [videoProject]);
  const commentActions = useMemo(
    () => selectedTimeline.rows.find(({ type }) => type === "comment")?.actions || [],
    [selectedTimeline]
  );

  const returnToPreviousScreen = useCallback(() => {
    dispatch(goBack());
    dispatch({ type: VIDEO_EDITOR.PROJECT_UNLOADED });
  }, [dispatch]);

  const setSelectedTimeline = useCallback(
    (fileIndex: number) => {
      timelineRef.current?.setTime(0);
      dispatch({
        type: VIDEO_EDITOR.SET_SELECTED_TIMELINE,
        payload: {
          fileIndex,
        },
      });
    },
    [dispatch]
  );

  const editVideoProject = useCallback(
    (videoProject: IVideoProject) => dispatch({ type: VIDEO_EDITOR.EDIT_PROJECT, payload: { videoProject } }),
    [dispatch]
  );

  const editTimelineRows = useCallback(
    (timelineRows: ITimelineRow[]) => dispatch({ type: VIDEO_EDITOR.ROWS_CHANGED, timelineRows }),
    [dispatch]
  );

  const editVideoEditorTimelineProperty = useCallback(
    (newValue: Partial<ITimelineState>) =>
      dispatch({
        type: VIDEO_EDITOR.TIMELINE_STATE_PROPERTY_CHANGED,
        property: newValue,
      }),
    [dispatch]
  );

  const editVideoEditorProperty = useCallback(
    (newValue: Partial<IVideoEditor>) => dispatch({ type: VIDEO_EDITOR.PROPERTY_CHANGE, property: newValue }),
    [dispatch]
  );

  const setSelectedActionId = useCallback(
    (selectedActionId: string | null) =>
      dispatch({
        type: VIDEO_EDITOR.SET_SELECTED_ACTION_ID,
        payload: {
          selectedActionId,
        },
      }),
    [dispatch]
  );

  const setPendingUploads = useCallback(
    (pendingUploads) =>
      dispatch({
        type: VIDEO_EDITOR.SET_PENDING_UPLOADS,
        payload: { pendingUploads },
      }),
    [dispatch]
  );

  const jumpToTime = useCallback(
    (time: number) => {
      timelineRef.current?.setTime(time);
      if (videoPlayerRef.current) {
        videoPlayerRef.current.currentTime = time;
      }
    },
    [videoEditor.timelineState]
  );

  const focusComment = useCallback(
    (commentId: string) => {
      if (videoEditor.selectedTab !== "comments") {
        editVideoEditorProperty({ selectedTab: "comments" });
      }
      const commentsWrapper = commentsWrapperRef.current;
      if (!commentsWrapper) return;
      const commentEl = commentsWrapper.querySelector(`[data-comment-id="${commentId}"]`);
      if (commentEl) {
        commentEl.scrollIntoView({ behavior: "smooth", block: "center" });
        commentEl.classList.add("highlighted");
        setTimeout(() => {
          commentEl.classList.remove("highlighted");
        }, 2000);
      }
    },
    [editVideoEditorProperty, videoEditor.selectedTab]
  );

  const contextValue: VideoEditorContextProps = {
    admin,
    selectedWebsite,
    categories,
    timelineRef,
    videoPlayerRef,
    commentsWrapperRef,
    selectedTimeline,
    commentActions,
    ...videoEditor,
    focusComment,
    jumpToTime,
    setSelectedTimeline,
    setPendingUploads,
    setSelectedActionId,
    editVideoEditorTimelineProperty,
    editTimelineRows,
    editVideoEditorProperty,
    editVideoProject,
    returnToPreviousScreen,
  };

  return <VideoEditorContext.Provider value={contextValue}>{children}</VideoEditorContext.Provider>;
};

export const useVideoEditorContext = () => {
  const context = useContext(VideoEditorContext);
  if (!context) {
    throw new Error("useVideoEditorContext must be used within a VideoEditorProvider");
  }
  return context;
};
