import * as React from "react";
import PageClusterList from "../PageClusterList/PageClusterList";
import { ePageState } from "../../../reducers/constants/objectTypes";

type PropTypes = {};

const AllStates = (props: PropTypes) => {
  return (
    <PageClusterList
      states={[
        ePageState.writer,
        ePageState.deleted,
        ePageState.edit,
        ePageState.secondEdit,
        ePageState.factCheck,
        ePageState.review,
        ePageState.published,
      ]}
    />
  );
};

export default AllStates;
