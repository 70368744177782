import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { push } from "connected-react-router";
import { useSnackbar } from "notistack";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import { isUrl, web } from "../../../../../helpers/urlHelper";
import agent from "../../../../../agent";
import Typography from "@material-ui/core/Typography";
import FlowTextField from "../../../../Authentication/OnboardingFlow/components/FlowTextField";
import MButton from "../../../../../components/MButton";
import new_campaign from "../../../../../img/new_campaign.png";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  topWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "55%",
    alignItems: "center",
  },
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 40,
    marginTop: 48,
    textAlign: "center",
  },
  description: {
    fontSize: 18,
    lineHeight: "28px",
    marginBottom: 30,
    maxWidth: 566,
    marginTop: 20,
    fontWeight: theme.typography.fontWeightLight as any,
    textAlign: "center",
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: 10,
    marginBottom: 50,
  },
  button: {
    borderRadius: 20,
    padding: "10px 20px",
    height: 40,
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  inputText: {
    maxWidth: 379,
    backgroundColor: theme.palette.common.white,
  },
}));

type PropTypes = {
  openRule: (id: string) => void;
  selectedWebsite: IWebsite;
  onCreateCampaign: () => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  openRule: (id) => dispatch(push(web.croCampaignEditor(id))),
});

const NewCampaign = (props: PropTypes) => {
  const classes = useStyles(props);

  const { selectedWebsite, openRule, onCreateCampaign } = props;

  const [loading, setLoading] = React.useState(false);
  const [pageUrl, setPageUrl] = React.useState("");

  const { enqueueSnackbar } = useSnackbar();

  const createNewCampaign = () => {
    if (!isUrl(pageUrl)) {
      return enqueueSnackbar(I18n.t("widgets_display_rules_workflow.new_campaign.invalid_url"));
    }

    const urlParts = pageUrl.split("/")?.filter((part) => part);
    const lastPart = urlParts?.length > 1 ? urlParts[urlParts.length - 1] : "New campaign";

    setLoading(true);
    agent.Campaigns.createCampaign(selectedWebsite._id, {
      name: lastPart,
      previewUrl: pageUrl,
      rules: [{ type: "exact", value: pageUrl, operator: "and" }],
    })
      .then((res) => {
        if (res.error) {
          return enqueueSnackbar(I18n.t("widgets_display_rules_workflow.something_went_wrong"));
        }
        onCreateCampaign();
        return openRule(res.displayRule._id);
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <section className={classes.pageWrapper}>
      <div className={classes.topWrapper}>
        <Typography className={classes.title}>{I18n.t("widgets_display_rules_workflow.new_campaign.title")}</Typography>
        <Typography className={classes.description}>
          {I18n.t("widgets_display_rules_workflow.new_campaign.description")}
        </Typography>
        <div className={classes.inputWrapper}>
          <FlowTextField
            className={classes.inputText}
            placeholder={I18n.t("widgets_display_rules_workflow.new_campaign.url_ph")}
            value={pageUrl}
            onChange={(v) => {
              setPageUrl(v);
            }}
          />
          <MButton onClick={createNewCampaign} customClassNames={classes.button} loading={loading}>
            {I18n.t("widgets_display_rules_workflow.new_campaign.button")}
          </MButton>
        </div>
      </div>

      <img src={new_campaign} alt={"New Campaign Image"} />
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCampaign);
