import React from "react";
import { IReportsCustomReportRow } from "../../../../reducers/constants/objectTypes";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import MTextField from "../../../../components/MTextField";
import { I18n } from "react-redux-i18n";
import { CUSTOM_GRAPH_TYPES } from "../../../../helpers/constants";
import MSelect from "../../../../components/MSelect";
import IconButton from "@material-ui/core/IconButton";
import XIcon from "../../../../icons/XIcon";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    columnGap: "20px",
  },
  deleteIcon: {
    height: "15px",
    width: "15px",
    fill: theme.palette.error.main,
  },
}));

type Props = {
  row: IReportsCustomReportRow;
  index: number;
  handleTextChange: (value: string, index: number) => void;
  handleNameChange: (value: string, index: number) => void;
  handleSelectChange: (value: string, index: number) => void;
  handleDeleteRow: (index: number) => void;
};

const CustomReportSettingsRow = (props: Props) => {
  const classes = useStyles(props);

  const { row, index } = props;

  return (
    <div className={classes.row}>
      <MTextField
        fullWidth
        value={row.name}
        outlined
        height={35}
        borderRadius={3}
        placeholder={I18n.t("reports.dashboard.redash_report_name")}
        onChange={(value) => {
          props.handleNameChange(value, index);
        }}
      />
      <MTextField
        fullWidth
        value={row.id}
        outlined
        height={35}
        borderRadius={3}
        placeholder={I18n.t("reports.dashboard.redash_report_id")}
        onChange={(value) => {
          props.handleTextChange(value, index);
        }}
      />

      <MSelect
        options={CUSTOM_GRAPH_TYPES}
        fullWidth
        optionValue={"value"}
        optionLabel={"label"}
        handleChange={(value) => {
          props.handleSelectChange(value.value, index);
        }}
        value={CUSTOM_GRAPH_TYPES.find((item) => item.value === row.graphType)}
        height={35}
      />

      <IconButton
        onClick={() => {
          props.handleDeleteRow(index);
        }}
      >
        <XIcon className={classes.deleteIcon} />
      </IconButton>
    </div>
  );
};

export default CustomReportSettingsRow;
