import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import StylePanelSection from "../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../elementsStyles/components/StylePanelSubSection";
import FontColorCSS from "../../elementsStyles/CssComponents/FontColorCSS";
import IconPickerElement from "../../elementsStyles/ElementComponents/IconPickerElement";
import NumberInputBaseComponent from "../../elementsStyles/BaseComponents/NumberInputBaseComponent";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  editor: Editor;
  websiteTheme: IWebsiteThemeProps;
  element: any;
};

const BulletedListStyle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, element, websiteTheme } = props;

  const handleElementDataChange = (data) => {
    console.log("PageEditorBulletedListStyle: handleElementDataChange", data);
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.tooltips.bulleted_list")} editor={editor}>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.pros_cons_table.icons")}>
        <NumberInputBaseComponent
          rowTitle={I18n.t("rich_text_editor.bulleted_list.padding_left")}
          value={element.data?.paddingLeft}
          defaultValue={!element.forcedLayout ? 10 : 0}
          postFix={"px"}
          handleChange={(paddingLeft) => {
            handleElementDataChange({ ...element.data, paddingLeft });
          }}
        />
        <IconPickerElement
          icon={element.data?.icon}
          color={element.data?.fill}
          onIconChange={(icon) => {
            handleElementDataChange({ ...element.data, icon: icon.key });
          }}
        />
        <FontColorCSS
          title={I18n.t("edit_post.select_color")}
          value={element.data?.fill || "#000000"}
          defaultValue={websiteTheme.palette.primary.main}
          isValueChanged={false}
          handleChange={(fill) => {
            handleElementDataChange({ ...element.data, fill });
          }}
        />
      </StylePanelSubSection>
    </StylePanelSection>
  );
};

export default BulletedListStyle;
