import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import EventItem from "./EventItem";
import { IAnalyticsEventRow } from "../../../../reducers/constants/objectTypes";
import MTable from "../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";

const useStyles = makeStyles((theme: Theme) => ({
  contentList: {
    paddingRight: 20,
    paddingLeft: 0,
    maxWidth: "calc(100vw - 40px)",
  },
  root: {
    paddingTop: 0,
  },
  subHeader: {
    top: 62,
    position: "sticky",
    padding: "30px 20px",
    marginRight: 5,
    display: "flex",
    zIndex: 3,
    backgroundColor: "white",
  },
  tableHiddenHead: {
    width: 20,
  },
  tableWideHead: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(21),
    width: "20%",
  },
  tableHead: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    flexGrow: 1,
    flexBasis: 0,
  },
  tableMoreOptionsHead: {
    width: 30,
  },
}));

type PropTypes = {
  searchText: string;
  loaded: boolean;
  events: IAnalyticsEventRow[];

  handleUserClick: (distinctId: string) => void;
};

const EventsList = (props: PropTypes) => {
  const classes = useStyles(props);
  const { searchText, loaded, events, handleUserClick } = props;

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabel> {I18n.t("analytics.events.table.name")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("analytics.events.table.time")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("analytics.events.table.distinct_id")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("analytics.events.table.city")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("analytics.events.table.country")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("analytics.events.table.operating_system")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("analytics.events.table.landing_page")}</MTableHeaderLabel>
        <Typography className={classes.tableMoreOptionsHead} variant={"subtitle2"}>
          {""}
        </Typography>
      </MTableHeader>
      {!loaded && <MTableRowProgressBar />}
      {loaded && events.length === 0 && <MTableRowEmptyRow />}
      {loaded &&
        events.length > 0 &&
        events.map((event, index) => (
          <EventItem
            key={event.id}
            event={event}
            handleUserClick={handleUserClick}
            handleEventClick={() => console.log(event)}
          />
        ))}
    </MTable>
  );
};

export default EventsList;
