import * as React from "react";
import { I18n } from "react-redux-i18n";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Typography } from "@material-ui/core";

import StylePanelSection from "../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../elementsStyles/components/StylePanelSubSection";
import WidgetPicker from "../../../containers/Modals/WidgetPicker/WidgetPicker";
import FormWidgetEditor from "../../../containers/CRO/pages/WidgetsBuilder/FormWidgetEditor/FormWidgetEditor";
import MMarginPicker from "../../../components/MMarginPicker";
import agent from "../../../agent";

// eslint-disable-next-line @typescript-eslint/no-shadow
const useStyles = makeStyles((theme: Theme) => ({
  panelSection: {
    margin: 0,
    padding: 0,
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  panelSubSection: {
    margin: 0,
    padding: 0,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: 10,
    alignItems: "center",
  },
  widgetWrapper: {
    backgroundColor: "#EEF2F9",
    padding: "0 15px",
    borderRadius: 5,
    height: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
    "&:hover": {
      cursor: "pointer",
      "& $editIcon": {
        fill: theme.palette.primary.main,
      },
      "& $widgetName": {
        color: theme.palette.primary.main,
      },
    },
  },
  widgetName: {
    fontSize: 14,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular as any,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  replaceText: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular as any,
    textAlign: "right",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.light,
    },
  },
  editIcon: {
    fontSize: 14,
    fill: theme.palette.text.primary,
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    marginTop: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectCustomClass: {
    backgroundColor: "#EEF2F9",
    borderRadius: 5,
    border: 0,
  },
  selectIndicator: {
    marginRight: 5,
    width: 12,
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.secondary,
    },
  },
  analyticsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  analyticRow: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
  },
  analyticFieldsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 3,
    "& .MuiTextField-root": {
      padding: "0px 15px",
      minHeight: "unset",
      height: 38,
      display: "flex",
      justifyContent: "center",
      borderRadius: 5,
    },
  },
  marginPickerWrapper: {
    marginTop: 20,
    backgroundColor: "#EEF2F9",
    padding: "0 15px",
    borderRadius: 5,
    height: 50,
    display: "flex",
  },
  marginPicker: {
    width: "100%",
    "& > p": {
      fontWeight: theme.typography.fontWeightRegular as any,
    },
  },
}));

type PropTypes = {
  editor: Editor;
  element: any;
};

const EntailWidgetStyle = (props: PropTypes) => {
  const { editor, element } = props;
  const [widgetPickerOpen, setWidgetPickerOpen] = React.useState(false);
  const [widgetEditorOpen, setWidgetEditorOpen] = React.useState(false);
  const [selectedWidget, setSelectedWidget] = React.useState(null);
  const classes = useStyles();

  const changeWidget = (widget) => {
    setSelectedWidget(widget);
    const data = { ...element.data, widget: { id: widget._id, uploadedId: widget.uploadedId, name: widget.name } };
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const removeWidget = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path });
  };

  React.useEffect(() => {
    if (!element?.data?.widget?.uploadedId) {
      setWidgetPickerOpen(true);
    }
  }, [element?.data?.widget?.uploadedId]);

  React.useEffect(() => {
    if (element?.data?.widget?.id !== selectedWidget?._id) {
      agent.Widgets.getWidget(element?.data?.widget?.id)
        .then((res) => setSelectedWidget(res.widget))
        .catch(console.error);
    }
  }, [element?.data?.widget?.id]);

  return (
    <>
      <StylePanelSection
        className={classes.panelSection}
        sectionTitle={I18n.t("rich_text_editor.entail_widgets.name")}
        editor={editor}
      >
        <StylePanelSubSection
          className={classes.panelSubSection}
          sectionTitle={I18n.t("rich_text_editor.entail_widgets.widget")}
        >
          <div>
            <div
              className={classes.widgetWrapper}
              onClick={() => {
                if (element?.data?.widget?.id) {
                  setWidgetEditorOpen(true);
                } else {
                  setWidgetPickerOpen((prev) => !prev);
                }
              }}
            >
              <Typography variant={"subtitle2"} className={classes.widgetName}>
                {element?.data?.widget?.name || I18n.t("rich_text_editor.entail_widgets.select_widget")}
              </Typography>
              <EditOutlinedIcon className={classes.editIcon} />
            </div>
            <Typography variant={"subtitle2"} className={classes.replaceText} onClick={() => setWidgetPickerOpen(true)}>
              {I18n.t("rich_text_editor.entail_widgets.replace")}
            </Typography>
          </div>
          <div className={classes.marginPickerWrapper}>
            <MMarginPicker
              className={classes.marginPicker}
              margin={{
                top: element?.data?.margin?.top || 0,
                bottom: element?.data?.margin?.bottom || 0,
              }}
              onChange={(margin) => {
                const data = { ...element.data, margin };
                Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
              }}
            />
          </div>
        </StylePanelSubSection>
      </StylePanelSection>
      {widgetPickerOpen && (
        <WidgetPicker
          defaultWidget={selectedWidget}
          handleWidgetInsert={(widget) => {
            changeWidget(widget);
            setWidgetPickerOpen(false);
          }}
          handleClose={() => {
            removeWidget();
            setWidgetPickerOpen(false);
          }}
        />
      )}
      {widgetEditorOpen && (
        <FormWidgetEditor
          widgetId={element?.data?.widget?.id}
          handleClose={(widget) => {
            setWidgetEditorOpen(false);
            changeWidget(widget);
          }}
        />
      )}
    </>
  );
};

export default EntailWidgetStyle;
