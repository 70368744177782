import React from "react";
import { Editor, Element, Point, Range, Transforms } from "slate";

/**
 * Checks if the selection is a table or not.
 *
 * @param {Object} editor Editor in which the table is to be checked
 * @returns {boolean} Selection in editor is table or not
 */
export const isSelectionTable = (editor) => {
  const [tableNode] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
  });

  return !!tableNode;
};

/**
 * Extends the editor's features by including the table feature.
 *
 * @param {Object} editor Editor to be improved
 * @returns {Object} Editor with the table functionality
 */

export const withTables = (editor: Editor) => {
  const { deleteBackward, deleteForward, insertBreak } = editor;

  editor.deleteBackward = (unit) => {
    const { selection } = editor;
    if (selection) {
      const [cell] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "table-cell",
      });
      const prevNodePath = Editor.before(editor, selection);

      const [tableNode] = Editor.nodes(editor, {
        at: prevNodePath,
        match: (n) => !Editor.isEditor(n) && Element.isElement && n.type === "table-cell",
      });

      if (cell) {
        const [, cellPath] = cell;

        const start = Editor.start(editor, cellPath);
        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }
      if (!cell && tableNode) {
        return;
      }
    }

    deleteBackward(unit);
  };
  editor.deleteForward = (unit) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "table-cell",
      });

      const prevNodePath = Editor.after(editor, selection);
      const [tableNode] = Editor.nodes(editor, {
        at: prevNodePath,
        match: (n) => !Editor.isEditor(n) && Element.isElement && n.type === "table-cell",
      });

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }
      if (!cell && tableNode) {
        return;
      }
    }

    deleteForward(unit);
  };

  editor.insertBreak = () => {
    const { selection } = editor;
    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      });

      if (table) {
        return;
      }
    }

    insertBreak();
  };
  return editor;
};

/**
 * Inserts and Deletes rows and columns into the editor
 *
 * @param {Object} editor    Editor which has to be improved
 * @param {Node}   tableNode table which has to be edited
 * @param {string} action    action to be performed
 * @param {Path}   path      the path of the table
 */
export const handleCells = (tableNode, path, action, editor) => {
  console.log("handleCells tableNode", tableNode);
  let headers = tableNode?.headers || false;
  const headersCells = !headers
    ? []
    : Array.from(tableNode.children[0].children, (header) => {
        console.log("header", header, Array.from(header.children));
        return header?.children?.[0]?.text;
      });
  console.log("handleCells headersCells", headersCells);
  const body = Array.from(tableNode.children[headers ? 1 : 0].children, (body) => {
    console.log("body", body, Array.from(body.children));
    return Array.from(body.children, (rows) => {
      console.log("rows", rows, Array.from(rows.children));
      return rows?.children?.[0]?.text;
    });
  });
  console.log("handleCells body", body);
  let existingText = body;
  if (headersCells.length > 0) {
    existingText.unshift(headersCells);
  }
  console.log("handleCells existingText", existingText);
  const columns = existingText[0].length;
  if (action === "row") {
    existingText.push(Array(columns).fill(""));
  } else if (action === "col") {
    existingText = Array.from(existingText, (item) => {
      item.push("");
      return item;
    });
  } else if (action === "drow") {
    existingText.pop();
  } else if (action === "header") {
    headers = !headers;
  } else {
    existingText = Array.from(existingText, (item) => {
      item.pop("");
      return item;
    });
  }
  const newTable = createTableNode(headers, existingText);
  Transforms.insertNodes(editor, newTable, {
    at: path,
  });
};

const createTableCell = (text, headerRow) => ({
  type: headerRow ? "table-header" : "table-cell",
  children: [{ text }],
});

const createRow = (cellText, headerRow, rowIndex) => {
  const newRow = Array.from(cellText, (value) => createTableCell(value, headerRow));
  return {
    type: headerRow ? "table-head" : "table-row",
    rowIndex,
    children: newRow,
  };
};

const tableBody = (children) => ({
  type: "table-body",
  children,
});

const createTableNode = (headers, cellText) => {
  const tableChildren = Array.from(cellText, (value, index) => createRow(value, headers && index === 0, index));

  if (headers) {
    let headerRow = tableChildren[0];
    tableChildren.splice(0, 1);
    const body = tableBody(tableChildren);
    return { type: "table", headers, children: [headerRow, body] };
  }
  return { type: "table", headers, children: [tableBody(tableChildren)] };
  // console.log('createTableNode', tableChildren)
  // tableChildren.splice(headers ? 1 : 0, 0, tableBody);
  // console.log('createTableNode splice', tableChildren)
  // return { type: "table", headers, children: tableChildren };
};

/**
 * Inserts a table into the editor
 *
 * @param {Object} editor  Editor in which image is to be inserted
 * @param {boolean} headers include headers
 * @param {number} rows    rows of the table.
 * @param {number} columns columns of the table.
 */
export const insertTable = (editor, rows, columns) => {
  const [tableNode] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
    mode: "highest",
  });

  if (tableNode) return;
  if (!rows || !columns) {
    return;
  }
  const cellText = Array.from({ length: rows }, () => Array.from({ length: columns }, () => ""));
  const newTable = createTableNode(true, cellText);
  Transforms.insertNodes(editor, newTable, {
    mode: "highest",
  });
  Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { mode: "highest" });
};
