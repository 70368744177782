import React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Settings } from "@material-ui/icons";
import {
  IWidgetFieldProperties,
  IWidgetDialogRedirectToURLProps,
  IWidgetDialogCloseDialogProps,
} from "../../../../../../../../reducers/constants/objectTypes";
import SelectProperty from "../itemProperties/components/SelectProperty";
import GenericValueItemProperty from "./GenericValueItemProperty";
import { capitalizeFirstLetter } from "../../../../helpers/widgets";
import CustomPopover from "../../../components/CustomPopover";
import SwitchProperty from "../itemProperties/components/SwitchProperty";

type PropTypes = {
  fieldType: string;
  properties: IWidgetFieldProperties;
  onChange: (properties: IWidgetDialogRedirectToURLProps | IWidgetDialogCloseDialogProps) => void;
  settingsLabel: string;
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  icon: {
    cursor: "pointer",
  },
}));

const GenericClickActionWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);

  const { onChange } = props;
  const { settingsLabel, fieldType } = props;
  const properties = props.properties as IWidgetDialogRedirectToURLProps;
  const {
    text = "",
    action = "redirect_to_url",
    url = "",
    linkType = "url",
    openInNewTab,
    includeQueryParams,
  } = properties;

  const [showSettingsToolbar, setShowSettingsToolbar] = React.useState(false);
  const settingsRef = React.useRef(null);

  return (
    <div className={classes.container}>
      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, text: value })}
        value={text}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.text_type`, {
          type: capitalizeFirstLetter(fieldType),
        })}
      />
      <SelectProperty
        options={["redirect_to_url", "close_dialog"]}
        value={action}
        onChange={(option: IWidgetDialogRedirectToURLProps["action"]) => onChange({ ...properties, action: option })}
      />

      {action === "redirect_to_url" && (
        <div ref={settingsRef}>
          <GenericValueItemProperty
            onChange={({ value }) => onChange({ ...properties, url: value })}
            value={url}
            label={settingsLabel}
            icon={<Settings className={classes.icon} onClick={() => setShowSettingsToolbar(true)} />}
          />

          <CustomPopover
            open={showSettingsToolbar}
            label={settingsLabel}
            anchorEl={settingsRef.current}
            onClose={() => setShowSettingsToolbar(false)}
          >
            <div className={classes.container}>
              <SelectProperty
                options={["url", "email", "phone"]}
                value={linkType}
                onChange={(option) =>
                  onChange({ ...properties, linkType: option as IWidgetDialogRedirectToURLProps["linkType"] })
                }
              />
              <GenericValueItemProperty
                onChange={({ value }) => onChange({ ...properties, url: value })}
                value={url}
                label={I18n.t(`widgets_builder.widget_editor.fields.labels.${linkType}`)}
              />
              {linkType === "url" && (
                <>
                  <SwitchProperty
                    checked={openInNewTab}
                    onChange={(checked) => onChange({ ...properties, openInNewTab: checked })}
                    label={I18n.t(`widgets_builder.widget_editor.fields.labels.open_in_new_tab`)}
                  />
                  <SwitchProperty
                    checked={includeQueryParams}
                    onChange={(checked) => onChange({ ...properties, includeQueryParams: checked })}
                    label={I18n.t(`widgets_builder.widget_editor.fields.labels.include_query_params`)}
                  />
                </>
              )}
            </div>
          </CustomPopover>
        </div>
      )}
    </div>
  );
};

export default GenericClickActionWidgetItem;
