import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import BriefSerpDialogListItem from "./BriefSerpDialogListItem";
import Typography from "@material-ui/core/Typography";
import { IKeywordSerpCleaned } from "../../../reducers/constants/objectTypes";
import CfProgressBar from "../../../components/CfProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  dialogListSubHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
    backgroundColor: theme.palette.background.default,
    margin: "15px 0px",
    top: 2,
  },
  navDialogTabs: {
    display: "flex",
    width: "55%",
    flexDirection: "row",
  },
  dialogTabs: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    flex: "0 0 auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: theme.typography.fontWeightBold as any,
    width: "10%",
  },
  firstDialogTab: {
    width: "55%",
  },
  tab: {
    padding: "2px 40px",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    cursor: "pointer",
    borderBottom: `1px solid #C4C4C4`,
  },
  activeTab: {
    color: theme.palette.text.primary,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    transition: "border .3s",
  },
  headerIcon: {
    width: 24,
    height: 24,
  },
  subDialogTitleWrapper: {
    marginTop: 30,
    marginBottom: 10,
  },
  subDialogTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },

  sortIcon: { color: `${theme.palette.text.primary} !important` },
}));

const mapStateToProps = (state) => ({
  serps: state.outlinePages.serps,
  serpsLoading: state.outlinePages.serpsLoading,
});

type PropTypes = {
  serps: IKeywordSerpCleaned[];
  serpsLoading: boolean;
  pageTab: "outlines" | "keywords" | "broadKeywords" | "pages" | "autoVideoInterview" | "serps";
  setPageTab: (tab: "outlines" | "keywords" | "broadKeywords" | "pages" | "autoVideoInterview" | "serps") => void;
};

const SerpDialogList = (props: PropTypes) => {
  const classes = useStyles(props);

  const { serps, serpsLoading } = props;

  return (
    <div>
      <div className={classes.subDialogTitleWrapper}>
        <Typography className={classes.subDialogTitle}>
          {serps?.length}{" "}
          {serps?.length === 1
            ? I18n.t("strategy.outlinePages.dialog_header.result")
            : I18n.t("strategy.outlinePages.dialog_header.results")}
        </Typography>
      </div>
      {serpsLoading ? <CfProgressBar /> : serps?.map((s, index) => <BriefSerpDialogListItem key={index} serp={s} />)}
    </div>
  );
};
export default connect(mapStateToProps, null)(SerpDialogList);
