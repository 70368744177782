import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import AppBar from "@material-ui/core/AppBar";
import { MANAGE_WEBSITES } from "../../../../../reducers/constants/actionTypes";
import { ICategory, IWebsite } from "../../../../../reducers/constants/objectTypes";
import CfProgressBar from "../../../../../components/CfProgressBar";
import MTextField from "../../../../../components/MTextField";
import TopicsBottomBar from "./TopicsBottomBar";
import LocaleTabs from "../../../../../components/LocaleTabs";

const useStyles = makeStyles((theme: Theme) => ({
  topicDetailsWrapper: {
    width: "100%",
    overflowY: "scroll",
    height: "100%",
  },
  topicDetailsInnerWrapper: {
    // height: '100%',
    padding: "0px 20px 20px 20px",
    maxWidth: 1280,
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    padding: 30,
  },
  description: {
    marginTop: 30,
    // fontSize: theme.typography.pxToRem(14),
    paddingBottom: 20,
  },
  topicDescription: {
    margin: 0,
    minHeight: 150,
  },
  topicDescriptionInput: {
    fontSize: theme.typography.pxToRem(16),
  },
  saveWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  saveButton: {
    paddingRight: 40,
    paddingLeft: 40,
    minHeight: 40,
  },
  charCounter: {
    marginRight: 10,
  },
  charCounterError: {
    color: theme.palette.error.main,
  },

  topicNameWrapper: {
    marginBottom: 10,
    padding: 20,
  },
  topicName: {},
  nameField: {
    marginTop: 10,
    marginBottom: 0,
    paddingLeft: 0,
  },
  appBar: {
    zIndex: 4,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
  },
}));

type PropTypes = {
  categoriesLoaded: boolean;
  selectedCategory: ICategory;
  selectedWebsite: IWebsite;
  categoriesSelectedLocale: string;

  handleCategoryChanged: Function;
  handleLocaleChanged: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  categoriesLoaded: state.category.categoriesLoaded,
  selectedCategory: state.manageWebsites.selectedCategory,
  categoriesSelectedLocale: state.manageWebsites.categoriesSelectedLocale,
});

const mapDispatchToProps = (dispatch) => ({
  handleCategoryChanged: (category) => {
    dispatch({ type: MANAGE_WEBSITES.ON_CATEGORY_CHANGED, category });
  },
  handleLocaleChanged: (locale) => {
    dispatch({ type: MANAGE_WEBSITES.ON_CATEGORIES_LOCALE_CHANGED, locale });
  },
});

const TopicDetails = (props: PropTypes) => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedWebsite,
    categoriesLoaded,
    selectedCategory,
    categoriesSelectedLocale,
    handleLocaleChanged,
    handleCategoryChanged,
  } = props;

  if (!categoriesLoaded) {
    return <CfProgressBar />;
  }

  if (!selectedCategory) {
    return (
      <div className={classes.topicDetailsWrapper}>
        <div className={classes.topicDetailsInnerWrapper}>
          <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.description} variant={"subtitle2"}>
              {I18n.t("manage_topics.no_topics")}
            </Typography>
          </Paper>
          <TopicsBottomBar />
        </div>
      </div>
    );
  }

  const selectedLocale = categoriesSelectedLocale || selectedWebsite.defaultLocale;
  const defaultLocale = selectedLocale === selectedWebsite.defaultLocale;
  return (
    <div className={classes.topicDetailsWrapper} key={selectedCategory._id}>
      <div className={classes.topicDetailsInnerWrapper}>
        <AppBar elevation={0} position="sticky" className={classes.appBar}>
          <LocaleTabs
            selectedLocale={selectedLocale}
            selectedWebsite={selectedWebsite}
            handleLocaleChange={(l) => handleLocaleChanged(l)}
            getTabName={(loc) => {
              if (loc === "en" || selectedCategory.translations[loc]) {
                return "Edit";
              }
              return "Add";
            }}
          />
        </AppBar>

        {/* NAME */}
        <Paper elevation={0} className={classes.topicNameWrapper}>
          <Typography className={classes.topicName} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manage_topics.category_name")}
          </Typography>
          <MTextField
            fullWidth
            disableUnderline={false}
            customClass={classes.nameField}
            value={defaultLocale ? selectedCategory.name : selectedCategory.translations[selectedLocale]?.name}
            placeholder={I18n.t("manage_topics.category_name_ph")}
            onChange={(name) => {
              if (defaultLocale) {
                handleCategoryChanged({ ...selectedCategory, name });
                return;
              }
              handleCategoryChanged({
                ...selectedCategory,
                translations: {
                  ...selectedCategory.translations,
                  [selectedLocale]: { ...(selectedCategory.translations[selectedLocale] || {}), name },
                },
              });
            }}
          />
        </Paper>

        {/* CATEGORY PAGE */}
        <Paper elevation={0} className={classes.topicNameWrapper}>
          <Typography className={classes.topicName} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manage_topics.category_title")}
          </Typography>
          <MTextField
            fullWidth
            disableUnderline={false}
            customClass={classes.nameField}
            value={
              defaultLocale
                ? selectedCategory.title || selectedCategory.name
                : selectedCategory.translations[selectedLocale]?.title ||
                  selectedCategory.translations[selectedLocale]?.name
            }
            placeholder={I18n.t("manage_topics.category_title_ph")}
            onChange={(title) => {
              if (defaultLocale) {
                handleCategoryChanged({ ...selectedCategory, title });
                return;
              }
              handleCategoryChanged({
                ...selectedCategory,
                translations: {
                  ...selectedCategory.translations,
                  [selectedLocale]: { ...(selectedCategory.translations[selectedLocale] || {}), title },
                },
              });
            }}
          />

          <Typography className={classes.description} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manage_topics.description")}
          </Typography>
          <MTextField
            id={"topic_description"}
            fullWidth
            customClass={classes.topicDescription}
            placeholder={I18n.t("manage_topics.description_placeholder")}
            value={
              defaultLocale ? selectedCategory.description : selectedCategory.translations[selectedLocale]?.description
            }
            onChange={(description: string) => {
              if (defaultLocale) {
                handleCategoryChanged({ ...selectedCategory, description });
                return;
              }
              handleCategoryChanged({
                ...selectedCategory,
                translations: {
                  ...selectedCategory.translations,
                  [selectedLocale]: { ...(selectedCategory.translations[selectedLocale] || {}), description },
                },
              });
            }}
            multiline
            inputClassName={classes.topicDescriptionInput}
          />
          <div className={classes.saveWrapper}>
            <Typography
              className={ClassNames(
                classes.charCounter,
                selectedCategory.description?.length >= 1000 && classes.charCounterError
              )}
              variant={"body1"}
            >
              {I18n.t("manage_topics.word_count", { count: selectedCategory.description?.length, maxCount: 1000 })}
            </Typography>
          </div>
        </Paper>

        {/* COMMUNITY */}
        <Paper elevation={0} className={classes.topicNameWrapper}>
          <Typography className={classes.topicName} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manage_topics.community_category_title")}
          </Typography>
          <MTextField
            fullWidth
            disableUnderline={false}
            customClass={classes.nameField}
            value={
              defaultLocale
                ? selectedCategory.titleCommunity || selectedCategory.name
                : selectedCategory.translations[selectedLocale]?.titleCommunity ||
                  selectedCategory.translations[selectedLocale]?.name
            }
            placeholder={I18n.t("manage_topics.community_category_title_ph")}
            onChange={(titleCommunity) => {
              if (defaultLocale) {
                handleCategoryChanged({ ...selectedCategory, titleCommunity });
                return;
              }
              handleCategoryChanged({
                ...selectedCategory,
                translations: {
                  ...selectedCategory.translations,
                  [selectedLocale]: { ...(selectedCategory.translations[selectedLocale] || {}), titleCommunity },
                },
              });
            }}
            // handleEnterClick={() => addUrl()}
          />

          <Typography className={classes.description} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manage_topics.community_description")}
          </Typography>
          <MTextField
            id={"community_topic_description"}
            fullWidth
            customClass={classes.topicDescription}
            placeholder={I18n.t("manage_topics.community_description_placeholder")}
            value={
              defaultLocale
                ? selectedCategory.descriptionCommunity
                : selectedCategory.translations[selectedLocale]?.descriptionCommunity
            }
            onChange={(descriptionCommunity: string) => {
              if (defaultLocale) {
                handleCategoryChanged({ ...selectedCategory, descriptionCommunity });
                return;
              }
              handleCategoryChanged({
                ...selectedCategory,
                translations: {
                  ...selectedCategory.translations,
                  [selectedLocale]: { ...(selectedCategory.translations[selectedLocale] || {}), descriptionCommunity },
                },
              });
            }}
            multiline
            inputClassName={classes.topicDescriptionInput}
          />
          <div className={classes.saveWrapper}>
            <Typography
              className={ClassNames(
                classes.charCounter,
                selectedCategory.descriptionCommunity?.length >= 1000 && classes.charCounterError
              )}
              variant={"body1"}
            >
              {I18n.t("manage_topics.word_count", {
                count: selectedCategory.descriptionCommunity?.length,
                maxCount: 1000,
              })}
            </Typography>
          </div>
        </Paper>

        {/* BLOG */}
        <Paper elevation={0} className={classes.topicNameWrapper}>
          <Typography className={classes.topicName} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manage_topics.blog_category_title")}
          </Typography>
          <MTextField
            fullWidth
            disableUnderline={false}
            customClass={classes.nameField}
            value={
              defaultLocale
                ? selectedCategory.titleBlog || selectedCategory.name
                : selectedCategory.translations[selectedLocale]?.titleBlog ||
                  selectedCategory.translations[selectedLocale]?.name
            }
            placeholder={I18n.t("manage_topics.blog_category_title_ph")}
            onChange={(titleBlog) => {
              if (defaultLocale) {
                handleCategoryChanged({ ...selectedCategory, titleBlog });
                return;
              }
              handleCategoryChanged({
                ...selectedCategory,
                translations: {
                  ...selectedCategory.translations,
                  [selectedLocale]: { ...(selectedCategory.translations[selectedLocale] || {}), titleBlog },
                },
              });
            }}
            // handleEnterClick={() => addUrl()}
          />

          <Typography className={classes.description} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manage_topics.blog_description")}
          </Typography>
          <MTextField
            id={"topic_description"}
            fullWidth
            customClass={classes.topicDescription}
            placeholder={I18n.t("manage_topics.blog_description_placeholder")}
            value={
              defaultLocale
                ? selectedCategory.descriptionBlog
                : selectedCategory.translations[selectedLocale]?.descriptionBlog
            }
            onChange={(descriptionBlog: string) => {
              if (defaultLocale) {
                handleCategoryChanged({ ...selectedCategory, descriptionBlog });
                return;
              }
              handleCategoryChanged({
                ...selectedCategory,
                translations: {
                  ...selectedCategory.translations,
                  [selectedLocale]: { ...(selectedCategory.translations[selectedLocale] || {}), descriptionBlog },
                },
              });
            }}
            multiline
            inputClassName={classes.topicDescriptionInput}
          />
          <div className={classes.saveWrapper}>
            <Typography
              className={ClassNames(
                classes.charCounter,
                selectedCategory.descriptionBlog?.length >= 1000 && classes.charCounterError
              )}
              variant={"body1"}
            >
              {I18n.t("manage_topics.word_count", { count: selectedCategory.descriptionBlog?.length, maxCount: 1000 })}
            </Typography>
          </div>
        </Paper>

        {/* TARGETED */}
        <Paper elevation={0} className={classes.topicNameWrapper}>
          <Typography className={classes.topicName} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manage_topics.targeted_category_title")}
          </Typography>
          <MTextField
            fullWidth
            disableUnderline={false}
            customClass={classes.nameField}
            value={
              defaultLocale
                ? selectedCategory.titleTargeted || selectedCategory.name
                : selectedCategory.translations[selectedLocale]?.titleTargeted ||
                  selectedCategory.translations[selectedLocale]?.name
            }
            placeholder={I18n.t("manage_topics.targeted_category_title_ph")}
            onChange={(titleTargeted) => {
              if (defaultLocale) {
                handleCategoryChanged({ ...selectedCategory, titleTargeted });
                return;
              }
              handleCategoryChanged({
                ...selectedCategory,
                translations: {
                  ...selectedCategory.translations,
                  [selectedLocale]: { ...(selectedCategory.translations[selectedLocale] || {}), titleTargeted },
                },
              });
            }}
            // handleEnterClick={() => addUrl()}
          />

          <Typography className={classes.description} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manage_topics.targeted_description")}
          </Typography>
          <MTextField
            id={"topic_description"}
            fullWidth
            customClass={classes.topicDescription}
            placeholder={I18n.t("manage_topics.targeted_description_placeholder")}
            value={
              defaultLocale
                ? selectedCategory.descriptionTargeted
                : selectedCategory.translations[selectedLocale]?.descriptionTargeted
            }
            onChange={(descriptionTargeted: string) => {
              if (defaultLocale) {
                handleCategoryChanged({ ...selectedCategory, descriptionTargeted });
                return;
              }
              handleCategoryChanged({
                ...selectedCategory,
                translations: {
                  ...selectedCategory.translations,
                  [selectedLocale]: { ...(selectedCategory.translations[selectedLocale] || {}), descriptionTargeted },
                },
              });
            }}
            multiline
            inputClassName={classes.topicDescriptionInput}
          />
          <div className={classes.saveWrapper}>
            <Typography
              className={ClassNames(
                classes.charCounter,
                selectedCategory.descriptionTargeted?.length >= 1000 && classes.charCounterError
              )}
              variant={"body1"}
            >
              {I18n.t("manage_topics.word_count", {
                count: selectedCategory.descriptionTargeted?.length,
                maxCount: 1000,
              })}
            </Typography>
          </div>
        </Paper>
      </div>
      <TopicsBottomBar />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicDetails);
