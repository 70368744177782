// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from "react";
import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Movie } from "@material-ui/icons";
import { Draggable, Droppable } from "react-beautiful-dnd";

import { useVideoEditorContext } from "../../../VideoEditorContext";
import UploadNewMedia from "./components/UploadNewMedia";
import UploadFileItem from "./components/UploadFileItem";
import videoHelper from "../../../../../helpers/videoHelper";
import { ISourceFile } from "../../../../../reducers/constants/objectTypes";
import { colors } from "../../../../../helpers/constants";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  mediaTab: {},
  input: {
    display: "none",
  },
  itemsWrapper: {
    display: "flex",
    gap: 20,
    flexWrap: "wrap",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    width: 160,
  },
  thumbnail: {
    border: `1px solid ${palette.divider}`,
    height: 80,
    width: "100%",
    position: "relative",
    cursor: "pointer",
    backgroundColor: colors.widgetOnHoverBackgroundColor,
  },
  itemText: {
    fontSize: 12,
    color: palette.grey[500],
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  previewImage: {
    width: "inherit",
    height: "inherit",
    objectFit: "cover",
  },
  movieIcon: {
    width: "100%",
    height: "100%",
  },
  selectedItem: {
    color: palette.primary.main,
  },
  filesWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    flexWrap: "wrap",
  },
}));

type Medias = (ISourceFile & { thumbnail?: string })[];

const MediaTab = () => {
  const classes = useStyles();
  const { videoProject, pendingUploads } = useVideoEditorContext();
  const [medias, setMedias] = React.useState<Medias>([]);

  useEffect(() => {
    const loadThumbnails = async () => {
      const newMediasPromises = videoProject.videos
        .filter((v) => !medias.some((media) => v._id === media._id))
        .map((sourceFile) =>
          videoHelper
            .getVideoCoverAndDuration({
              type: "url",
              url: sourceFile.cdnUrl,
              // seekTo: 5,
            })
            .then((res: { blob: Blob; duration: number }) => ({
              ...sourceFile,
              thumbnail: URL.createObjectURL(res.blob),
            }))
            .catch((error) => {
              console.error("Error getting video cover and duration", error);
              return {
                ...sourceFile,
                thumbnail: null,
              };
            })
        );

      const newMedias = await Promise.all(newMediasPromises);

      if (newMedias.length === 0) return;
      setMedias([...medias, ...newMedias]);

      console.log("newMedias", newMedias);
    };
    loadThumbnails();
  }, [videoProject.videos, medias]);

  console.log("MediaTab", pendingUploads);
  return (
    <>
      <div className={classes.mediaTab}>
        <UploadNewMedia />
        <Droppable droppableId="medias-tab">
          {(provided) => (
            <div className={classes.itemsWrapper} {...provided.droppableProps} ref={provided.innerRef}>
              {pendingUploads?.videos.length > 0 && (
                <div className={classes.filesWrapper}>
                  {pendingUploads?.videos?.map((file, index) => (
                    <UploadFileItem key={`video_${index}`} file={file} />
                  ))}
                </div>
              )}
              {pendingUploads?.audios?.map((file, index) => (
                <div key={`audio_${index}`}>{`file=${file}`}</div>
              ))}
              {pendingUploads?.images?.map((file, index) => (
                <div key={`image_${index}`}>{`file=${file}`}</div>
              ))}
              {medias.map((media, index) => (
                <Draggable draggableId={media._id} key={media._id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={classes.item}
                      ref={provided.innerRef}
                      // snapshot={snapshot}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className={classes.thumbnail}>
                        {media.thumbnail ? (
                          <img className={classes.previewImage} src={media.thumbnail} alt={media.fileName} />
                        ) : (
                          <Movie className={classes.movieIcon} />
                        )}
                      </div>
                      <Typography className={classes.itemText} variant="body2">
                        {media.fileName}
                      </Typography>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
};

export default MediaTab;
