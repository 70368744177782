import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core";

import WidgetBuildTab from "./WidgetBuildTab";
import WidgetDesignTab from "../design/WidgetDesignTab";
import WidgetSettingsTab from "./WidgetSettingsTab";
import WidgetTargetingTab from "./WidgetTargetingTab";
import { eWidgetType, IWidget } from "../../../../../../reducers/constants/objectTypes";
import { colors } from "../../../../../../helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  resourcesTab: {
    height: "100%",
    flex: 1,
    overflowY: "scroll",
  },
  tabs: {
    position: "sticky",
    top: 0,
    zIndex: 5,
    backgroundColor: colors.pageEditorBackgroundColor,
  },
  tab: {
    textTransform: "capitalize",
    minWidth: "unset",
    paddingRight: 20,
    paddingLeft: 20,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabSelected: {
    fontWeight: theme.typography.fontWeightBold as any,
    borderBottom: "2px solid #4353FF",
  },
}));

const enum TabTypes {
  BUILD = "build",
  DESIGN = "design",
  SETTINGS = "settings",
  TARGETING = "targeting",
}

const widgetTabs = {
  [eWidgetType.FORM]: [TabTypes.BUILD],
  [eWidgetType.CUSTOM_WIDGET]: [TabTypes.BUILD],
  [eWidgetType.DIALOG]: [TabTypes.BUILD, TabTypes.SETTINGS],
  [eWidgetType.INLINE]: [TabTypes.BUILD, TabTypes.SETTINGS],
};

type PropTypes = {
  widget: IWidget;
};

const WidgetEditorActions = ({ widget }: PropTypes) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(widgetTabs[widget.type][0]);

  const tabLabels = {
    [TabTypes.BUILD]: I18n.t("widget_builder.tabs.build"),
    [TabTypes.DESIGN]: I18n.t("widget_builder.tabs.design"),
    [TabTypes.SETTINGS]: I18n.t("widget_builder.tabs.settings"),
    [TabTypes.TARGETING]: I18n.t("widget_builder.tabs.targeting"),
  };

  const selectedTabContent = useMemo(() => {
    switch (selectedTab) {
      case TabTypes.BUILD:
        return <WidgetBuildTab widget={widget} />;
      case TabTypes.DESIGN:
        return <WidgetDesignTab />;
      case TabTypes.SETTINGS:
        return <WidgetSettingsTab />;
      case TabTypes.TARGETING:
        return <WidgetTargetingTab />;
      default:
        return null;
    }
  }, [selectedTab, widget]);

  const handleChange = (_event, newValue: TabTypes) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.resourcesTab}>
      <div>
        {widgetTabs[widget.type].length > 1 && (
          <Tabs
            orientation="horizontal"
            centered
            variant="fullWidth"
            value={selectedTab}
            onChange={handleChange}
            className={classes.tabs}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            {widgetTabs[widget.type].map((tab) => (
              <Tab
                classes={{ selected: classes.tabSelected }}
                className={classes.tab}
                key={tab}
                label={tabLabels[tab]}
                value={tab}
              />
            ))}
          </Tabs>
        )}

        {selectedTabContent}
      </div>
    </div>
  );
};

export default WidgetEditorActions;
