import * as React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

type PropTypes = {
  className?: string;
};

function OpenQuoteIcon(props: PropTypes) {
  const { className } = props;
  return (
    <SvgIcon className={className} viewBox={"0 0 35 31"}>
      <path d="M27.9191 0.695312C31.5785 0.695312 34.5469 3.74782 34.5469 7.51401C34.5469 11.2779 31.5785 14.3327 27.9191 14.3327C21.2936 14.3327 25.7098 27.5133 34.5469 27.5133V30.6953C18.7752 30.6976 12.5952 0.695312 27.9191 0.695312ZM8.82674 0.695312C12.4838 0.695312 15.4522 3.74782 15.4522 7.51401C15.4522 11.2779 12.4838 14.3327 8.82674 14.3327C2.19896 14.3327 6.61521 27.5133 15.4522 27.5133V30.6953C-0.317135 30.6976 -6.49715 0.695312 8.82674 0.695312Z" />
    </SvgIcon>
  );
}

export default OpenQuoteIcon;
