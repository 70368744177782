import React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import {
  IWidgetFieldProperties,
  IWidgetInlineButtonFieldProps,
} from "../../../../../../../reducers/constants/objectTypes";
import SwitchProperty from "./itemProperties/components/SwitchProperty";
import GenericValueItemProperty from "./components/GenericValueItemProperty";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

type PropTypes = {
  properties: IWidgetFieldProperties;
  fieldType: string;
  onChange: (properties: IWidgetInlineButtonFieldProps) => void;
};

const InlineButtonWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { onChange } = props;
  const properties = props.properties as IWidgetInlineButtonFieldProps;
  const { text = "", url = "", fullWidth, showArrow = false, openInNewTab, includeQueryParams } = properties;

  return (
    <div className={classes.root}>
      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, text: value })}
        value={text}
        label={I18n.t("widgets_builder.widget_editor.fields.labels.title")}
      />

      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, url: value })}
        value={url}
        label={I18n.t("widgets_builder.widget_editor.fields.labels.url")}
      />

      <SwitchProperty
        checked={showArrow}
        onChange={(checked) => onChange({ ...properties, showArrow: checked })}
        label={I18n.t("widgets_builder.widget_editor.fields.labels.show_arrow")}
      />

      <SwitchProperty
        checked={openInNewTab}
        onChange={(checked) => onChange({ ...properties, openInNewTab: checked })}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.open_in_new_tab`)}
      />
      <SwitchProperty
        checked={includeQueryParams}
        onChange={(checked) => onChange({ ...properties, includeQueryParams: checked })}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.include_query_params`)}
      />
      <SwitchProperty
        checked={fullWidth}
        onChange={(checked) => props.onChange({ ...properties, fullWidth: checked })}
        label={I18n.t(`widgets_builder.widget_editor.fields.labels.full_width`)}
      />
    </div>
  );
};

export default InlineButtonWidgetItem;
