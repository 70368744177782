import { Divider, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import { IWidgetInputFieldProps } from "../../../../../../../../reducers/constants/objectTypes";
import TextareaProperty from "../itemProperties/components/TextareaProperty";
import TextFieldProperty from "../itemProperties/components/TextFieldProperty";
import RequiredFieldProperty from "../itemProperties/components/RequiredFieldProperty";
import WidthProperty from "../itemProperties/components/WidthProperty";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
    textField: {
      background: palette.primary.dark,
      width: "100%",
      resize: "none !important" as "none",
      minHeight: 126,
      maxHeight: 126,
      border: "none",
      padding: "10px",
      lineHeight: "17px",
      borderRadius: 5,
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      color: "white",
      "&:focus": {
        outline: "none",
      },
    },
  };
});

type PropTypes = {
  labelLabel?: string;
  labelPlaceholder?: string;
  placeholderLabel?: string;
  placeholderPlaceholder?: string;

  hintDisabled?: boolean;
  requiredDisabled?: boolean;
  widthDisabled?: boolean;
  properties: IWidgetInputFieldProps;
  onPropertyChange(properties: IWidgetInputFieldProps): void;

  children?: any;
};

const GenericInputWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { onPropertyChange, properties } = props;
  const {
    labelLabel = null,
    labelPlaceholder = null,
    placeholderLabel = null,
    placeholderPlaceholder = null,
    children = null,
  } = props;
  const { hintDisabled = false, requiredDisabled = false, widthDisabled = false } = props;
  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <TextFieldProperty
          label={labelLabel || I18n.t("widgets_builder.widget_editor.Form.fields.properties.label")}
          placeholder={labelPlaceholder || I18n.t("widgets_builder.widget_editor.Form.fields.properties.label_ph")}
          onChange={(label) => onPropertyChange({ ...properties, label })}
          value={properties?.label}
        />
        <Divider className={classes.divider} />
        <TextFieldProperty
          label={placeholderLabel || I18n.t("widgets_builder.widget_editor.Form.fields.properties.placeholder")}
          placeholder={
            placeholderPlaceholder || I18n.t("widgets_builder.widget_editor.Form.fields.properties.placeholder_ph")
          }
          onChange={(placeholder) => onPropertyChange({ ...properties, placeholder })}
          value={properties?.placeholder}
        />
        {!hintDisabled && (
          <>
            <Divider className={classes.divider} />
            <TextareaProperty onChange={(hint) => onPropertyChange({ ...properties, hint })} value={properties?.hint} />
          </>
        )}
      </div>

      {!requiredDisabled && (
        <RequiredFieldProperty
          required={properties.required}
          onChange={(required) => onPropertyChange({ ...properties, required })}
        />
      )}
      {!widthDisabled && (
        <WidthProperty value={properties.width} onChange={(width) => onPropertyChange({ ...properties, width })} />
      )}
      {children}
    </div>
  );
};

export default GenericInputWidgetItem;
