import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { Paper, Theme } from "@material-ui/core";
import classNames from "classnames";

import { IWebsite, IWidget, eWidgetSubtype } from "../../../../reducers/constants/objectTypes";
import { DesktopWindows, Smartphone } from "@material-ui/icons";
import MDangerouslySetHtmlContent from "../../../../components/MDangerouslySetHtmlContent";
import MIFrameWrapper from "../../../../components/MIFrameWrapper";
import widgetsAgent from "../../../../widgetsAgent";
import CfProgressBar from "../../../../components/CfProgressBar";
import { IWidgetPickerState } from "../../../../reducers/widgetPickerReducer";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.common.white,
    height: "calc(100vh - 142px)",
    width: "100%",
    overflowY: "auto",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  iconsWrapper: {
    display: "flex",
    gap: 20,
    marginBottom: 20,
  },
  deviceIcon: {
    color: theme.palette.text.primary,
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
      cursor: "pointer",
    },
  },
  activeDeviceIcon: {
    color: theme.palette.primary.main,
    opacity: 1,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  paper: {
    width: "100%",
    maxWidth: 790,
    height: "100%",
    margin: "auto",
    boxSizing: "content-box",
    background: "transparent",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paperMobile: () => ({
    maxWidth: 425,
  }),
  loaderWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iframeWrapper: {
    display: "block",
    width: "100%",
    height: "100%",
    border: "none",
    background: "transparent",
  },
  iframeWrapperMobile: {
    width: "100%",
    borderRadius: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
  responsive: {
    position: "absolute",
    right: 0,
    top: 100,
    padding: 5,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 3,
  },
  carouselWrapper: {
    maxHeight: 480,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 3,
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
    padding: 15,
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  selectedWidget: state.widgetPicker.selectedWidget,
});

const mapDispatchToProps = (dispatch) => ({});

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedWidget: IWidgetPickerState["selectedWidget"];
};

const WidgetPickerPreview = (props: PropTypes) => {
  const { selectedWidget, selectedWebsite } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [html, setHtml] = React.useState(null);
  const [mobile, setMobile] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const paperRef = React.useRef(null);

  const classes = useStyles();

  React.useEffect(() => {
    if (!selectedWidget) {
      return;
    }

    if (selectedWidget.isTemplate) {
      setLoading(true);
      widgetsAgent.Widgets.templatePreview(selectedWidget, selectedWebsite.host)
        .then((res) => {
          console.log("res", res);
          setHtml(res.html);
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          console.log(e, e.message);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    if (!selectedWidget.uploadedId) {
      setHtml(null);
      return;
    }

    setLoading(true);

    widgetsAgent.Widgets.editorPreview((selectedWidget as IWidget).uploadedId, selectedWebsite.host)
      .then((html) => {
        setHtml(html);
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
        console.log(e, e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedWidget, enqueueSnackbar]);

  const onObserverChange = () => {
    const iframeDocument = paperRef.current?.querySelector("iframe")?.contentWindow?.document;

    if (!iframeDocument) return;

    iframeDocument.querySelector("body").style.display = "flex";
    iframeDocument.querySelector("body").style.justifyContent = "center";
    iframeDocument.querySelector("body").style.alignItems = "center";

    if (iframeDocument.querySelector("body")?.children?.[0]) {
      iframeDocument.querySelector("body").children[0].style.width = "100%";
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.iconsWrapper}>
        <DesktopWindows
          className={classNames(classes.deviceIcon, !mobile && classes.activeDeviceIcon)}
          onClick={() => setMobile(false)}
        />
        <Smartphone
          className={classNames(classes.deviceIcon, mobile && classes.activeDeviceIcon)}
          onClick={() => setMobile(true)}
        />
      </div>
      <Paper elevation={0} className={classNames(classes.paper, mobile && classes.paperMobile)} ref={paperRef}>
        {loading ? (
          <div className={classes.loaderWrapper}>
            <CfProgressBar />
          </div>
        ) : (
          selectedWidget &&
          html && (
            <MIFrameWrapper
              allow={"autoplay; fullscreen"}
              className={classNames(
                classes.iframeWrapper,
                mobile && classes.iframeWrapperMobile,
                selectedWidget.subtype === eWidgetSubtype.PRODUCTS_CAROUSEL && classes.carouselWrapper
              )}
              onObserverChange={onObserverChange}
            >
              <MDangerouslySetHtmlContent html={html} />
            </MIFrameWrapper>
          )
        )}
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetPickerPreview);
