import React, { useRef } from "react";
import { MyDragDropContext } from "../containers/Main/components/DragAndDropContext";

//https://github.com/clauderic/dnd-kit/blob/01f0a2dce5c696895d641bbdab7341461ffa38f0/packages/core/src/components/DndMonitor/types.ts
export interface DragMonitorListener {
  onDragStart?(item): void;
  onDragEnd?(item): void;
}

export default function useDragMonitor(droppableId, listener: DragMonitorListener) {
  const { addEvent, removeEvent } = React.useContext<any>(MyDragDropContext);
  const savedListener = useRef<DragMonitorListener>();

  React.useEffect(() => {
    savedListener.current = listener;
    addEvent(droppableId, ({ action, item }) => {
      if (action === "DRAG_START") {
        savedListener.current.onDragStart?.(item);
      } else if (action === "DRAG_END") {
        savedListener.current.onDragEnd?.(item);
      }
    });
    return () => {
      removeEvent(droppableId);
    };
  }, []);
}
