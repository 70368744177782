import React from "react";
import Typography from "@material-ui/core/Typography";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import theme from "../../../themes/theme";
import MTablePaginationLimitSelect from "./MTablePaginationLimitSelect";
import ClassNames from "classnames";

const useStyles = makeStyles(() => ({
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: 20,
  },
  recordsLimitField: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  recordsLimitTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: 10,
  },
}));

type PropTypes = {
  paginationLimitOptions?: {
    value: number;
    label: string;
  }[];

  paginationLimit: number;
  page: number;
  paginationPagesCount: number;

  setPaginationLimit: (limit: number) => void;
  setPage: (limit: number) => void;
  className?: string;
};

const defaultLimits = [
  {
    value: 20,
    label: "20",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 200,
    label: "200",
  },
];

const MTablePagination = (props: PropTypes) => {
  const classes = useStyles();
  const {
    paginationLimitOptions = undefined,
    paginationLimit,
    page,
    paginationPagesCount,
    setPaginationLimit,
    setPage,
    className,
  } = props;

  return (
    <div className={ClassNames(classes.paginationWrapper, className)}>
      <div className={classes.recordsLimitField}>
        <Typography className={classes.recordsLimitTitle} variant={"body1"}>
          {I18n.t("queries.groups.limit")}
        </Typography>
        <MTablePaginationLimitSelect
          limit={paginationLimit}
          menuPlacement={"top"}
          handleLimitChange={(limit) => {
            setPage(1);
            setPaginationLimit(limit);
          }}
          options={paginationLimitOptions || defaultLimits}
        />
      </div>
      <Pagination page={page} count={paginationPagesCount || 1} color={"primary"} onChange={(e, val) => setPage(val)} />
    </div>
  );
};

export default MTablePagination;
