import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import {
  eCustomerPostState,
  ePostState,
  ePostType,
  ILearnTopic,
  ITag,
  IWebsite,
} from "../../../../reducers/constants/objectTypes";
import MTextCounter from "../../../../components/MTextCounter";
import agent from "../../../../agent";
import MButtonGroup from "../../../../components/MButtonGroup";
import MSavedXMinutesAgo from "../../../../components/MSavedXMinutesAgo";
import { EDIT_TOPIC } from "../../../../reducers/constants/actionTypes";
import MAlertDialog from "../../../../components/MAlertDialog";
import { AbilityContext } from "../../../../casl/can";
import { clients } from "../../../../helpers/urlHelper";
import richTextEditorHelper from "../../../../editor/helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  postBottomBarWrapper: {
    zIndex: 3,
    height: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    borderTop: `${theme.palette.divider} solid 1px`,
    position: "sticky",
    bottom: 0,
  },
  postBottomBarInnerWrapper: {
    // width: 'calc(100% - 2*280px)',
    width: "100%",
    paddingRight: 30,
    paddingLeft: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    minHeight: 40,
  },
  discardButton: {
    marginRight: 20,
    marginLeft: 20,
  },
  postTypesWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textCounter: {
    marginRight: 20,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  topic: ITag;

  returnToPreviousScreen: Function;
  topicSaved: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  topic: state.editTopic.topic,
});

const mapDispatchToProps = (dispatch) => ({
  topicSaved: (topic) => dispatch({ type: EDIT_TOPIC.TOPIC_CHANGED, topic }),
});

const EditTopicBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, topic, returnToPreviousScreen, topicSaved } = props;
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ability = React.useContext<any>(AbilityContext);

  const actions = [];

  if (ability.can("customer_approval", ePostType.TOPIC)) {
    actions.push({
      key: "customer_approval",
      label: I18n.t("edit_post.customer_approval"),
    });
  }

  if (
    (topic.state === ePostState.UNINITIALIZED ||
      topic.state === ePostState.NEW ||
      topic.state === ePostState.DRAFT ||
      topic.state === ePostState.DELETED) &&
    ability.can("assign_writer", ePostType.TOPIC)
  ) {
    actions.push({
      key: "submit_for_writers",
      label: I18n.t("edit_post.submit_for_writers"),
    });
  }

  if (
    // topic.customerState !== eCustomerPostState.EDIT_REQUEST &&
    (topic.state === ePostState.UNINITIALIZED ||
      topic.state === ePostState.NEW ||
      topic.state === ePostState.DRAFT ||
      topic.state === ePostState.REJECTED ||
      topic.state === ePostState.PUBLISHED ||
      topic.state === ePostState.PUBLISH ||
      topic.state === ePostState.UPDATE ||
      topic.state === ePostState.DELETED) &&
    ability.can("submit_for_review", ePostType.TOPIC)
  ) {
    actions.push({
      key: "submit_for_review",
      label: I18n.t("edit_post.submit_for_review"),
    });
  }

  if (
    // topic.state === ePostState.UNINITIALIZED ||
    // topic.state === ePostState.NEW ||
    // topic.state === ePostState.DRAFT ||
    // topic.state === ePostState.REJECTED ||
    // topic.state === ePostState.DELETED ||
    (topic.state === ePostState.PUBLISH ||
      topic.state === ePostState.UPDATE ||
      topic.state === ePostState.INTERNAL_REVIEW) &&
    ability.can("publish", ePostType.TOPIC)
  ) {
    actions.push({
      key: "publish",
      label: I18n.t("edit_post.publish"),
    });
  }

  if (
    topic.state === ePostState.UNINITIALIZED ||
    topic.state === ePostState.NEW ||
    topic.state === ePostState.DRAFT ||
    topic.state === ePostState.REJECTED ||
    topic.state === ePostState.DELETED
  ) {
    actions.push({
      key: "save_draft",
      label: I18n.t("edit_post.save_draft"),
    });
  }

  if (topic.state === ePostState.INTERNAL_REVIEW) {
    actions.push({
      key: "save",
      label: I18n.t("edit_post.save"),
    });
  }

  if (topic.state === ePostState.INTERNAL_REVIEW && ability.can("review", ePostType.TOPIC)) {
    actions.push({
      key: "reject",
      label: I18n.t("edit_post.reject"),
    });
  }

  if (
    (topic.state === ePostState.PUBLISHED || topic.state === ePostState.PUBLISH || topic.state === ePostState.UPDATE) &&
    ability.can("update", ePostType.TOPIC)
  ) {
    actions.push({
      key: "update",
      label: I18n.t("edit_post.update"),
    });
  }

  if (
    (topic.state === ePostState.DRAFT ||
      topic.state === ePostState.REJECTED ||
      topic.state === ePostState.INTERNAL_REVIEW ||
      topic.state === ePostState.PUBLISHED ||
      topic.state === ePostState.DELETE ||
      topic.state === ePostState.PUBLISH ||
      topic.state === ePostState.UPDATE) &&
    ability.can("delete", ePostType.TOPIC)
  ) {
    actions.push({
      key: "delete",
      label: I18n.t("edit_post.delete"),
    });
  }

  if (
    topic.state === ePostState.UNINITIALIZED ||
    ((topic.state === ePostState.NEW ||
      topic.state === ePostState.DRAFT ||
      topic.state === ePostState.REJECTED ||
      topic.state === ePostState.INTERNAL_REVIEW ||
      topic.state === ePostState.DELETE ||
      topic.state === ePostState.DELETED) &&
      ability.can("delete_forever", ePostType.TOPIC))
  ) {
    actions.push({
      key: "delete_forever",
      label: I18n.t("edit_post.delete_forever"),
    });
  }

  const validTopic = (newState: ePostState) => {
    if (
      newState !== ePostState.PUBLISHED &&
      newState !== ePostState.PUBLISH &&
      newState !== ePostState.INTERNAL_REVIEW &&
      newState !== ePostState.UPDATE
    ) {
      return true;
    }
    let valid = true;
    if (!topic.name || topic.name.length === 0) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Name" }));
      valid = false;
    }

    if (!topic.metaDescription || topic.metaDescription.length === 0) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Meta description" }));
      valid = false;
    }

    return valid;
  };

  const saveTopic = (state?: ePostState, customerState?: eCustomerPostState) => {
    if (!validTopic(state)) {
      return;
    }
    topic.state = state;
    topic.text = richTextEditorHelper.serialize(JSON.parse(topic.richText));
    setSaveInProgress(true);
    agent.Tags.update(topic)
      .then((res) => {
        console.log("res", res);
        enqueueSnackbar(I18n.t("snackbar.topic.save.success"));
        setSaveInProgress(false);
        if (!ability.can("customer", "role") && res.topic.state === ePostState.PUBLISHED) {
          window.open(
            clients.withCacheInvalidation(selectedWebsite, clients.topicPage(selectedWebsite, res.topic)),
            "_blank"
          );
        }
        if (res.topic.state !== ePostState.DRAFT) {
          returnToPreviousScreen();
          return;
        }
        topicSaved(res.topic);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.topic.save.error", { msg: e.response.body.message }));
        setSaveInProgress(false);
      });
  };

  const onDeleteClicked = () => {
    setDeleteDialogOpen(true);
  };

  const deleteTopicForever = () => {
    // agent.Learn.deleteTopic(topic._id)
    //   .then(res => {
    //     enqueueSnackbar(I18n.t('snackbar.topic.delete.success'));
    //     returnToPreviousScreen();
    //   }).catch(e => {
    //   enqueueSnackbar(I18n.t('snackbar.topic.delete.error', {msg: e.response.body.message}));
    // });
  };

  const onDiscardDraftClicked = () => {
    setDiscardDialogOpen(true);
  };

  const discardTopic = () => {
    agent.Tags.update({ ...topic, writer: null })
      .then((res) => {
        enqueueSnackbar(I18n.t("snackbar.topic.discard.success"));
        returnToPreviousScreen();
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.topic.discard.error", { msg: e.response.body.message }));
      });
  };

  const handleButtonClick = (key: string) => {
    switch (key) {
      case "publish":
        saveTopic(ePostState.PUBLISH);
        break;
      case "submit_for_writers":
        saveTopic(ePostState.NEW);
        break;
      case "save_draft":
        saveTopic(ePostState.DRAFT);
        break;
      case "save":
        saveTopic(topic.state);
        break;
      case "delete":
        saveTopic(ePostState.DELETE);
        break;
      case "discard":
        onDiscardDraftClicked();
        break;
      case "delete_forever":
        onDeleteClicked();
        break;
      case "reject":
        saveTopic(ePostState.REJECTED);
        break;
      case "update":
        saveTopic(ePostState.PUBLISH);
        break;
      case "submit_for_review": {
        if (ability.can("customer", "role")) {
          saveTopic(topic.state, eCustomerPostState.EDIT_REQUEST);
        } else {
          saveTopic(ePostState.INTERNAL_REVIEW);
        }
        break;
      }
      case "customer_approval":
        saveTopic(topic.state, eCustomerPostState.APPROVED);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.postBottomBarWrapper}>
      <div className={classes.postBottomBarInnerWrapper}>
        <div className={classes.flexWrapper}>
          {/* { topic.state !== ePostState.PUBLISHED && */}
          {/* topic.state !== ePostState.REJECTED && */}
          {/* topic.state !== ePostState.UNINITIALIZED && */}
          {/* ( */}
          {/*  <CfButton */}
          {/*    outlined */}
          {/*    gray */}
          {/*    height={40} */}
          {/*    customClass={classes.discardButton} */}
          {/*    text={I18n.t('edit_post.discard_draft')} */}
          {/*    onClick={() => handleButtonClick('discard')} */}
          {/*  /> */}
          {/* )} */}
          {/* { topic.state === ePostState.UNINITIALIZED && ( */}
          {/*  <CfButton */}
          {/*    outlined */}
          {/*    gray */}
          {/*    height={40} */}
          {/*    customClass={classes.discardButton} */}
          {/*    text={I18n.t('edit_post.delete')} */}
          {/*    onClick={() => handleButtonClick('delete_forever')} */}
          {/*  /> */}
          {/* )} */}
          <MSavedXMinutesAgo lastSaveTime={topic.updatedAt} />
        </div>
        <div className={classes.flexWrapper}>
          <MTextCounter
            wrapperClass={classes.textCounter}
            type={"word"}
            text={topic.text || ""}
            suffix={I18n.t("writers.write_answer.words")}
          />
          {actions[0] && (
            <MButtonGroup
              text={actions[0].label}
              onClick={() => handleButtonClick(actions[0].key)}
              options={actions}
              onMenuItemClick={(key: string) => handleButtonClick(key)}
            />
          )}
        </div>
      </div>
      {deleteDialogOpen && (
        <MAlertDialog
          open={deleteDialogOpen}
          title={I18n.t("edit_topic.delete_dialog.title")}
          description={I18n.t("edit_topic.delete_dialog.description")}
          agreeText={I18n.t("edit_topic.delete_dialog.agree")}
          disagreeText={I18n.t("edit_topic.delete_dialog.cancel")}
          handleClose={(agree) => {
            setDeleteDialogOpen(false);
            if (agree) {
              deleteTopicForever();
            }
          }}
        />
      )}
      {discardDialogOpen && (
        <MAlertDialog
          open={discardDialogOpen}
          title={I18n.t("edit_topic.discard_dialog.title")}
          description={I18n.t("edit_topic.discard_dialog.description")}
          agreeText={I18n.t("edit_topic.discard_dialog.agree")}
          disagreeText={I18n.t("edit_topic.discard_dialog.cancel")}
          handleClose={(agree) => {
            setDiscardDialogOpen(false);
            if (agree) {
              discardTopic();
            }
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTopicBottomBar);
