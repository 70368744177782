import React from "react";
import { I18n } from "react-redux-i18n";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
    textTransform: "unset",
    padding: "10px 20px",
    borderRadius: 20,
    height: 36,
    minHeight: 36,
    "&:hover": {
      backgroundColor: "#5967FF",
    },
  },
}));

type PropTypes = {
  text?: string;
  className?: string;
  onClick?: (e: any) => void;
};
const SaveChangesButton = (props: PropTypes) => {
  const classes = useStyles(props);
  const { text, className, onClick } = props;
  return (
    <Button className={ClassNames(classes.button, className)} onClick={onClick}>
      {text || I18n.t("website.save_changes")}
    </Button>
  );
};

export default SaveChangesButton;
