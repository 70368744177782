import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import CfProgressBar from "../../CfProgressBar";

const MTableRowProgressBar = ({ size = undefined }) => (
  <ListItem
    style={{
      display: "flex",
      justifyContent: "center",
    }}
  >
    <CfProgressBar size={size} />
  </ListItem>
);

export default MTableRowProgressBar;
