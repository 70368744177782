import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { web } from "../../helpers/urlHelper";
import { IWebsite } from "../../reducers/constants/objectTypes";
import TagPages from "./pages/Tag/TagPages";
import WritersBio from "./pages/WritersBio/WritersBio";
import Topics from "./pages/Topics/Topics";
import RolesAndPermissions from "./pages/RolesAndPermissions/RolesAndPermissions";
import { AbilityContext } from "../../casl/can";
import MHeaderMetaTags from "../../components/MHeaderMetaTags";

type PropTypes = {
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

const Settings = (props: PropTypes) => {
  const { selectedWebsite } = props;
  const ability = React.useContext<any>(AbilityContext);

  const routes = React.useMemo(() => {
    const allRoutes = [];
    if (ability.can("view", "cms_settings_topics_tab")) {
      allRoutes.push({
        url: web.settingsTopics(),
        route: (
          <Route
            key={`cms_settings_${selectedWebsite._id}_topics`}
            exact={false}
            path={web.settingsTopics()}
            component={TagPages}
          />
        ),
      });
    }
    if (ability.can("view", "cms_settings_bios_tab")) {
      allRoutes.push({
        url: web.settingsBios(":writerId"),
        route: (
          <Route
            key={`cms_settings_${selectedWebsite._id}_bios_id`}
            exact={false}
            path={web.settingsBios(":writerId")}
            component={WritersBio}
          />
        ),
      });
      allRoutes.push({
        url: web.settingsBios(),
        route: (
          <Route
            key={`cms_settings_${selectedWebsite._id}_bios`}
            exact={false}
            path={web.settingsBios()}
            component={WritersBio}
          />
        ),
      });
    }
    if (ability.can("view", "cms_settings_categories_tab")) {
      allRoutes.push({
        url: web.settingsCategories(),
        route: (
          <Route
            key={`cms_settings_${selectedWebsite._id}_categories`}
            exact={false}
            path={web.settingsCategories()}
            component={Topics}
          />
        ),
      });
    }
    if (ability.can("view", "settings_roles_and_permissions_tab")) {
      allRoutes.push({
        url: web.settingsRolesAndPermissions(),
        route: (
          <Route
            key={`cms_settings_${selectedWebsite._id}_roles_and_permissions`}
            exact={false}
            path={web.settingsRolesAndPermissions()}
            component={RolesAndPermissions}
          />
        ),
      });
    }
    return allRoutes;
  }, [selectedWebsite, ability]);
  return (
    <>
      <MHeaderMetaTags title={I18n.t(`meta_tags.article_cms.settings.title`)} />
      <Switch>
        {routes.map((route) => route.route)}
        <Redirect exact from={"*"} to={routes[0]?.url} />
      </Switch>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
