import * as React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment/moment";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { INSIGHTS } from "../../../../reducers/constants/actionTypes";
import agent from "../../../../agent";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import { useCallback, useEffect, useState } from "react";
import MSelect from "../../../../components/MSelect";
import useEffectDebugger from "../../../../hooks/useEffectDebugger";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import MTextField from "../../../../components/MTextField";
import MTextBox from "../../../../components/MTextBox";
import { TextField } from "@material-ui/core";
import useDebounce from "../../../../hooks/useDebounce";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    paddingBottom: 20,
    borderBottom: `${theme.palette.divider} solid 1px`,
  },
  subWrapper: {
    display: "flex",
  },
  reportSelectRoot: {
    paddingLeft: "15px",
    paddingRight: "15px",
    minWidth: "70px",
  },
  reportSelect: {
    height: 32,
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fill: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold as any,
    marginRight: 10,
  },
  reportIcon: {
    color: "white",
  },
  defaultSelectRoot: {
    paddingLeft: 12,
  },
  startDateSelect: {
    height: 32,
    borderRadius: 3,
    border: `${theme.palette.divider} solid 1px`,
  },
  unitSelect: {
    marginLeft: 10,
    height: 32,
    borderRadius: 3,
    border: `${theme.palette.divider} solid 1px`,
  },
  countrySelect: {
    marginLeft: 10,
    height: 32,
    borderRadius: 3,
    border: `${theme.palette.divider} solid 1px`,
    borderBottom: "none",
  },
  urlSelect: {
    marginLeft: 10,
    height: 32,
    borderRadius: 3,
    border: `${theme.palette.divider} solid 1px`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  urlField: {
    margin: 0,
    height: 32,
    border: `${theme.palette.divider} solid 1px`,
    borderLeft: "none",
    padding: "2px 12px",
  },
  toggleButtonGroup: {
    height: 30,
    marginRight: 20,
  },
  toggleButtonRoot: {
    borderRadius: 30,
    textTransform: "none",
  },
  conversionFilterWrapper: {
    display: "flex",
    columnGap: "5px",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  reportType: state.insights.reportType,
  startDate: state.insights.startDate,
  endDate: state.insights.endDate,
  interval: state.insights.interval,
  urls: state.insights.urls,
  eventNames: state.insights.eventNames,
  urlSource: state.insights.urlSource,
  pageSize: state.insights.pageSize,
  page: state.insights.page,
  searchText: state.insights.searchText,
});

const mapDispatchToProps = (dispatch) => ({
  handleReportLoaded: (payload) => dispatch({ type: INSIGHTS.ON_REPORT_LOADED, payload }),
  handleValueChanged: (name, value) => dispatch({ type: INSIGHTS.ON_VALUE_CHANGED, name, value }),
  handleURLsLoaded: (payload) => dispatch({ type: INSIGHTS.ON_URLS_LOADED, payload }),
  handleEventNamesLoaded: (payload) => dispatch({ type: INSIGHTS.ON_EVENT_NAMES_LOADED, payload }),
  isLoading: () => dispatch({ type: INSIGHTS.IS_LOADING }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  reportType: "website" | "page" | "allPages" | "event" | "conversion";
  interval: "hour" | "day" | "week" | "month";
  startDate: string;
  endDate: string;
  urls: string[];
  eventNames: string[];
  page: number;
  pageSize: number;
  urlSource: string | null;
  handleReportLoaded: (payload: any) => void;
  handleURLsLoaded: (payload: any) => void;
  handleEventNamesLoaded: (payload: any) => void;
  handleValueChanged: (name: string, value: any) => void;
  isLoading: () => void;
  searchText: string;
};

const InsightsHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    startDate,
    endDate,
    handleReportLoaded,
    handleURLsLoaded,
    handleEventNamesLoaded,
    isLoading,
  } = props;
  const { reportType, interval, handleValueChanged, urls, eventNames, urlSource, page, pageSize, searchText } = props;

  const groupByValues = [
    { label: I18n.t("cro.filter_labels.landing_page"), value: "url" },
    { label: I18n.t("cro.filter_labels.referer"), value: "referrer" },
  ];

  const urlRules = [
    { label: I18n.t("cro.filter_labels.url_contains"), value: "contains" },
    { label: I18n.t("cro.filter_labels.url_not_contains"), value: "not-contains" },
  ];

  const [selectedURL, setSelectedURL] = useState<{ value: string; label: string } | null>(null);
  const [selectedEventName, setSelectedEventName] = useState<{ value: string; label: string } | null>(null);
  const [eventNameFilter, setEventNameFilter] = useState<{ value: string; label: string } | null>(null);
  const [groupBy, setGroupBy] = useState<string>(groupByValues[0].value);
  const [urlRule, setUrlRule] = useState<string>(urlRules[0].value);

  const [conversionUrlFilter, setConversionUrlFilter] = useState<string>("");

  const debouncedConversionFilter = useDebounce(conversionUrlFilter, 500);

  const [searchField, setSearchField] = useState<string | null>();

  const handleTextChange = useCallback(_.debounce(setSearchField, 500), []);

  React.useEffect(() => {
    handleURLsLoaded(agent.Insights.getURLs(selectedWebsite._id));
    handleEventNamesLoaded(agent.Insights.getEventNames(selectedWebsite._id));
  }, [selectedWebsite]);

  useEffect(() => {
    if (searchField) {
      handleURLsLoaded(agent.Insights.searchURLs(selectedWebsite._id, searchField));
    }
  }, [searchField]);

  React.useEffect(() => {
    if (eventNames?.length > 0 && selectedEventName?.value !== eventNames[0]) {
      setSelectedEventName({ value: eventNames[0], label: _.capitalize(eventNames[0].split("-").join(" ")) });
    }
  }, [eventNames, reportType]);

  React.useEffect(() => {
    setSelectedURL(null);
  }, [reportType]);

  useEffectDebugger(
    () => {
      if (reportType !== "event") {
        return;
      }

      const eventNameValue = eventNameFilter ? eventNameFilter.value : null;

      isLoading();

      handleReportLoaded(
        agent.Insights.getPageEvents(
          selectedWebsite._id,
          startDate,
          endDate,
          pageSize,
          page,
          searchText,
          eventNameValue
        )
      );
    },
    [selectedWebsite, startDate, endDate, reportType, page, pageSize, searchText, eventNameFilter],
    [
      "ap_selectedWebsite",
      "ap_startDate",
      "ap_endDate",
      "ap_reportType",
      "ap_page",
      "ap_pageSize",
      "ap_searchText",
      "ap_eventNameFilter",
    ]
  );

  useEffectDebugger(
    () => {
      if (reportType !== "allPages") {
        return;
      }

      if (!selectedEventName) {
        return;
      }
      isLoading();

      handleReportLoaded(
        agent.Insights.getPages(
          selectedWebsite._id,
          startDate,
          endDate,
          pageSize,
          page,
          interval,
          selectedEventName.value
        )
      );
    },
    [selectedWebsite, startDate, endDate, reportType, interval, selectedEventName, page, pageSize],
    [
      "ap_selectedWebsite",
      "ap_startDate",
      "ap_endDate",
      "ap_reportType",
      "ap_interval",
      "ap_selectedEventName",
      "ap_page",
      "ap_pageSize",
    ]
  );

  useEffectDebugger(
    () => {
      if (reportType !== "website") {
        return;
      }
      isLoading();

      handleReportLoaded(agent.Insights.getEvents(selectedWebsite._id, startDate, endDate, pageSize, page, interval));
    },
    [selectedWebsite, startDate, endDate, reportType, interval, page, pageSize],
    ["w_selectedWebsite", "w_startDate", "w_endDate", "w_reportType", "w_interval", "w_page", "w_pageSize"]
  );

  useEffectDebugger(
    () => {
      if (reportType !== "page") {
        return;
      }

      if (!selectedURL) {
        return;
      }
      isLoading();

      handleReportLoaded(
        agent.Insights.getEvents(
          selectedWebsite._id,
          startDate,
          endDate,
          pageSize,
          page,
          interval,
          selectedURL.value,
          urlSource
        )
      );
    },
    [selectedWebsite, startDate, endDate, reportType, interval, selectedURL, page, pageSize],
    [
      "p_selectedWebsite",
      "p_startDate",
      "p_endDate",
      "p_reportType",
      "p_interval",
      "p_selectedURL",
      "p_page",
      "p_pageSize",
    ]
  );

  useEffectDebugger(
    () => {
      if (reportType !== "conversion") {
        return;
      }

      isLoading();

      handleReportLoaded(
        agent.Insights.getConversion(
          selectedWebsite._id,
          startDate,
          endDate,
          pageSize,
          page,
          interval,
          groupBy,
          urlRule,
          debouncedConversionFilter
        )
      );
    },
    [
      selectedWebsite,
      startDate,
      endDate,
      reportType,
      interval,
      groupBy,
      urlRule,
      debouncedConversionFilter,
      page,
      pageSize,
    ],
    [
      "p_selectedWebsite",
      "p_startDate",
      "p_endDate",
      "p_reportType",
      "p_interval",
      "p_groupBy",
      "p_urlRule",
      "p_conversionUrlFilter",
      "p_page",
      "p_pageSize",
    ]
  );

  const urlValues = urls?.map((value, index) => ({ value, label: value }));
  const eventNamesValues = eventNames?.map((value, index) => ({
    value,
    label: _.capitalize(value.split("-").join(" ")),
  }));
  const renderFilterByReportType = () => {
    switch (reportType) {
      case "page":
        return (
          <MSelect
            placeholder={I18n.t("insights.search")}
            options={urlValues}
            optionLabel={"label"}
            capitalize={false}
            optionValue={"value"}
            searchable={true}
            value={selectedURL}
            height={32}
            handleInputChange={(input) => handleTextChange(input)}
            borderRadius={0}
            handleChange={(value) => setSelectedURL(value)}
          />
        );
      case "allPages":
        return (
          <MSelect
            options={eventNamesValues}
            optionLabel={"label"}
            optionValue={"value"}
            value={selectedEventName}
            borderRadius={0}
            height={32}
            handleChange={(value) => setSelectedEventName(value)}
          />
        );
      case "event":
        return (
          <MSelect
            options={eventNamesValues}
            optionLabel={"label"}
            isClearable={true}
            optionValue={"value"}
            value={eventNameFilter}
            borderRadius={0}
            height={32}
            handleChange={(value) => setEventNameFilter(value)}
          />
        );
      case "conversion":
        return (
          <div className={classes.conversionFilterWrapper}>
            <Select
              id="group-by-select"
              className={classes.unitSelect}
              value={groupBy}
              classes={{
                root: classes.defaultSelectRoot,
              }}
              onChange={(e) => setGroupBy(e.target.value as string)}
              disableUnderline
              label="StartDate"
            >
              {groupByValues.map((row, index) => (
                <MenuItem value={row.value} key={index}>
                  {row.label}
                </MenuItem>
              ))}
            </Select>
            <MSelect
              options={[]}
              value={null}
              height={32}
              placeholder={"Country"}
              customClass={classes.countrySelect}
            />
            <div>
              <Select
                id="url-rule-select"
                className={classes.urlSelect}
                value={urlRule}
                classes={{
                  root: classes.defaultSelectRoot,
                }}
                onChange={(e) => setUrlRule(e.target.value as string)}
                disableUnderline
                label="StartDate"
              >
                {urlRules.map((row, index) => (
                  <MenuItem value={row.value} key={index}>
                    {row.label}
                  </MenuItem>
                ))}
              </Select>
              <MTextField
                value={conversionUrlFilter}
                customClass={classes.urlField}
                onChange={(text) => setConversionUrlFilter(text)}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.subWrapper}>
        <Select
          id="report"
          className={classes.reportSelect}
          value={reportType}
          onChange={(e) => handleValueChanged("reportType", e.target.value)}
          disableUnderline
          classes={{
            icon: classes.reportIcon,
            root: classes.reportSelectRoot,
          }}
          label="Report"
        >
          <MenuItem value={"website"}>{I18n.t("cro.report_type.website")}</MenuItem>
          <MenuItem value={"page"}>{I18n.t("cro.report_type.page")}</MenuItem>
          <MenuItem value={"allPages"}>{I18n.t("cro.report_type.allPages")}</MenuItem>
          <MenuItem value={"event"}>{I18n.t("cro.report_type.event")}</MenuItem>
          <MenuItem value={"conversion"}>{I18n.t("cro.report_type.conversion")}</MenuItem>
        </Select>
        {renderFilterByReportType()}
      </div>

      <div>
        <Select
          id="start-date-select"
          className={classes.startDateSelect}
          value={startDate}
          classes={{
            root: classes.defaultSelectRoot,
          }}
          onChange={(e) => {
            // console.log("handleStartDateChange(e.target.value)", e.target.value);
            handleValueChanged("startDate", e.target.value);
          }}
          disableUnderline
          label="StartDate"
        >
          <MenuItem value={moment().subtract(7, "day").format("YYYY-MM-DD")}>
            {I18n.t("cro.date_range.last_7_days")}
          </MenuItem>
          <MenuItem value={moment().subtract(28, "day").format("YYYY-MM-DD")}>
            {I18n.t("cro.date_range.last_28_days")}
          </MenuItem>
          <MenuItem value={moment().subtract(3, "month").format("YYYY-MM-DD")}>
            {I18n.t("cro.date_range.last_3_months")}
          </MenuItem>
          <MenuItem value={moment().subtract(6, "month").format("YYYY-MM-DD")}>
            {I18n.t("cro.date_range.last_6_months")}
          </MenuItem>
          <MenuItem value={moment().subtract(12, "month").format("YYYY-MM-DD")}>
            {I18n.t("cro.date_range.last_12_months")}
          </MenuItem>
          <MenuItem value={moment().subtract(16, "month").format("YYYY-MM-DD")}>
            {I18n.t("cro.date_range.last_16_months")}
          </MenuItem>
        </Select>

        <Select
          id="unit-select"
          className={classes.unitSelect}
          value={interval}
          classes={{
            root: classes.defaultSelectRoot,
          }}
          onChange={(e) => handleValueChanged("interval", e.target.value)}
          disableUnderline
          label="StartDate"
        >
          <MenuItem value={"hour"}>{I18n.t("cro.interval.hour")}</MenuItem>
          <MenuItem value={"day"}>{I18n.t("cro.interval.day")}</MenuItem>
          <MenuItem value={"week"}>{I18n.t("cro.interval.week")}</MenuItem>
          <MenuItem value={"month"}>{I18n.t("cro.interval.month")}</MenuItem>
        </Select>
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(InsightsHeader);
