import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import numeral from "numeral";
import Tooltip from "@material-ui/core/Tooltip";
import { TooltipProps } from "@material-ui/core";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    flexGrow: 1,
    flexBasis: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: 10,
    fontSize: 12,
  },
}));

type PropTypes = {
  value: number;
  type?: "currency" | "number" | "percentage" | "bigNumber" | "decimal";
  tooltipText?: string;
  placeHolder?: string;
  className?: string;
  fontSize?: number | string;
  fontWeight?: number | string;
  toolTipProps?: Partial<TooltipProps>;
  onClick?: (e: any) => void;
};

const formats = {
  currency: "$0,0[.]0 a",
  number: "0,0",
  percentage: "0,0[.]0%",
  bigNumber: "0,0[.]0a",
  decimal: "0.0a",
};

const MTableCellNumber = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    value,
    type = "number",
    tooltipText,
    placeHolder,
    fontSize = 12,
    fontWeight = 500,
    toolTipProps,
    className,
    onClick,
  } = props;

  return (
    <div onClick={onClick} className={ClassNames(classes.column, className)} style={{ fontSize, fontWeight }}>
      <Tooltip enterDelay={2000} title={tooltipText || numeral(value || 0).format(formats[type])} {...toolTipProps}>
        <span>{!value && value !== 0 && placeHolder ? placeHolder : numeral(value || 0).format(formats[type])}</span>
      </Tooltip>
    </div>
  );
};

export default MTableCellNumber;
