import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles(() => ({
  icon: {
    height: 18,
    width: 18,
    color: "#000",
  },
}));

type PropTypes = {
  className?: string;
  onClick?: () => void;
};

const TransitionIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className, onClick } = props;

  return (
    <SvgIcon onClick={onClick} className={ClassNames(classes.icon, className)} viewBox={"0 0 15 20"}>
      <path d="M6.78513 18C5.04587 18 3.56353 17.3873 2.33812 16.1619C1.11271 14.9365 0.5 13.4541 0.5 11.7149C0.5 9.97563 1.11271 8.4933 2.33812 7.26788C3.56353 6.04247 5.04587 5.42977 6.78513 5.42977C8.52437 5.42977 10.0067 6.04247 11.2321 7.26788C12.4575 8.4933 13.0702 9.97563 13.0702 11.7149C13.0702 13.4541 12.4575 14.9365 11.2321 16.1619C10.0067 17.3873 8.52437 18 6.78513 18ZM6.78513 16.5496C8.12274 16.5496 9.26293 16.0782 10.2057 15.1354C11.1485 14.1927 11.6199 13.0525 11.6199 11.7149C11.6199 10.3773 11.1485 9.23707 10.2057 8.2943C9.26293 7.35153 8.12274 6.88014 6.78513 6.88014C5.44752 6.88014 4.30733 7.35153 3.36455 8.2943C2.42178 9.23707 1.9504 10.3773 1.9504 11.7149C1.9504 13.0525 2.42178 14.1927 3.36455 15.1354C4.30733 16.0782 5.44752 16.5496 6.78513 16.5496ZM14.1934 12.2244C14.2132 12.1463 14.2231 12.0656 14.2231 11.9824V11.7149C14.2231 9.65826 13.4976 7.90443 12.0466 6.4534C10.5956 5.00237 8.84174 4.27685 6.78513 4.27685H6.51762C6.4344 4.27685 6.35373 4.28676 6.27562 4.30659C6.64504 3.12396 7.36126 2.11055 8.42427 1.26634C9.48728 0.422113 10.7508 0 12.2149 0C13.9541 0 15.4365 0.612706 16.6619 1.83812C17.8873 3.06353 18.5 4.54587 18.5 6.28513C18.5 7.74918 18.0779 9.01272 17.2337 10.0757C16.3895 11.1387 15.376 11.855 14.1934 12.2244Z" />
    </SvgIcon>
  );
};

export default TransitionIcon;
