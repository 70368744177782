import { makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { I18n } from "react-redux-i18n";
import ButtonWidgetBaseElement from "../baseElements/ButtonWidgetBaseElement";
import { find } from "lodash";
import { CharticleTopProduct, CharticleTopProductData } from "../../../types/editor.Types";
import { Widget } from "../WithWidgets";
import { connect } from "react-redux";
import { IPage, IProduct, IWebsite } from "../../../../reducers/constants/objectTypes";
import RatingStarIcon from "../../../../icons/RatingStarIcon";
import { Rating } from "@material-ui/lab";
import ClassNames from "classnames";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  product: (props: PropTypes) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    borderColor: props.website.configurations.theme.palette.divider,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: props.website.configurations.theme.palette.background.default,
    justifyContent: "space-between",
    padding: 24,
    ...props.element.data?.card?.css,
  }),
  position: {
    position: "absolute",
    left: -15,
    top: 8,
    backgroundColor: "white",
    borderRadius: "50%",
    border: (props: PropTypes) => `1px solid ${props.website.configurations.theme.palette.divider}`,
    height: 30,
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoWrapper: {},
  leftWrapper: {
    width: "70%",
    display: "flex",
  },
  logoImage: {
    width: 160,
    height: "auto",
    objectFit: "contain",
  },
  mainWrapper: {
    width: 210,
    marginLeft: 30,
  },
  rightWrapper: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
  },
  scoreRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginBottom: 10,
  },
  scoreText: {
    color: "#00B890",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular as any,
    marginRight: 4,
  },
  scoreValue: {
    color: "white",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  scoreBackground: {
    width: 40,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00B890",
    borderRadius: 8,
  },
  productName: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(16),
  },
  logoAndRatingWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  ratingWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  rating: {
    display: "flex",
    alignItems: "center",
  },
  ratingStarIcon: {
    marginRight: 6,
  },
  ratingInputRoot: {
    width: 50,
    marginRight: 10,
  },
  reviewsInputRoot: {
    width: 65,
    marginRight: 10,
  },
  numOfReviewsText: {
    fontSize: theme.typography.pxToRem(14),
  },
  ratingText: {
    fontWeight: theme.typography.fontWeightRegular as any,
    marginLeft: 5,
  },
  shortDescriptionText: (props: PropTypes) => ({
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: 1.5,
    color: props.website.configurations.theme.palette.text.primary,
    margin: "14px 0px 0px 0px",
    ...props.element.data.shortDescription?.css,
  }),
  iconFilled: {
    fill: "#FFBD06",
  },
  iconHover: {
    fill: "#FFBD06",
  },
  decimal: {
    padding: 2,
    fontSize: theme.typography.pxToRem(16),
  },
  bulletsList: (props: PropTypes) => ({
    margin: props.element.data.bullets?.css?.listMargin || "24px 0px 0px 0px",
    padding: props.element.data.bullets?.css?.listPadding || "0px",
  }),
  bullet: (props: PropTypes) => ({
    display: "flex",
    alignItems: "center",
    margin: "4px 0px 0px 0px",
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightLight as any,
    color: props.website.configurations.theme.palette.text.primary,
    ...props.element.data.bullets?.css,
  }),
  bulletIcon: (props: PropTypes) => ({
    color: props.website.configurations.theme.palette.primary.main,
    fontSize: 20,
    marginRight: 4,
  }),
  circleBulletIcon: (props: PropTypes) => ({
    color: props.website.configurations.theme.palette.primary.main,
    fontSize: 12,
    marginRight: 12,
  }),
  buttonContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "end",
  },
  buttonContainerCentered: {
    justifyContent: "center",
  },
  placeHolder: (props: PropTypes) => ({
    width: "100%",
    height: 80,
    borderRadius: 4,
    padding: 24,
    border: `solid 1px ${props.website.configurations.theme.palette.divider}`,
  }),
  buttonStyle: {
    width: "fit-content",
    minWidth: 133,
    whiteSpace: "normal",
    lineHeight: 1.5,
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: CharticleTopProduct;
  website: IWebsite;
  products: IProduct[];

  readMode?: boolean;

  page: IPage;
};

export const getRawText = (n: Node) => {
  return "";
};

const mapStateToProps = (state) => ({
  page: state.pageEditor.page,
});
const CharticleTopProductWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, website, products = [], page, readMode = false } = props;
  const editor = useSlateStatic();
  const websiteTheme = website.configurations.theme;

  const scoreTexts = {
    GOOD: "Good",
    VERY_GOOD: "Very Good",
    EXCELLENT: "Excellent",
  };

  const getScoreText = (sc) => {
    let scoreText;
    const score = parseFloat(sc);
    if (score >= 6 && score < 8) {
      scoreText = scoreTexts.GOOD;
    } else if (score >= 8 && score < 9) {
      scoreText = scoreTexts.VERY_GOOD;
    } else {
      scoreText = scoreTexts.EXCELLENT;
    }
    return scoreText;
  };

  const bulletsIcon = {
    v_icon: (color: string) => <CheckIcon style={{ color }} className={classes.bulletIcon} />,
    x_icon: (color: string) => <ClearIcon style={{ color }} className={classes.bulletIcon} />,
    disc_icon: (color: string) => <CircleIcon style={{ color }} className={classes.circleBulletIcon} />,
  };

  const handleChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  const defaultData: CharticleTopProductData = {
    uuid: null,
    position: {
      show: true,
      index: "",
    },
    logo: {
      show: true,
    },
    rating: {
      show: true,
    },
    productName: {
      show: true,
      css: {},
    },
    shortDescription: {
      show: false,
      css: {},
    },
    bullets: {
      show: false,
      css: {},
      icon: {
        key: "v_icon",
        color: null,
      },
    },
    score: {
      show: true,
    },
    button: {
      show: true,
      text: null,
      css: {},
      showArrow: false,
    },
    product: {
      itemNumber: null,
    },
    card: {
      css: {},
    },
  };

  React.useEffect(() => {
    handleChange({ ...defaultData, ...element.data });
  }, []);

  console.log("CharticleTopProductList", element);
  return (
    <div {...attributes}>
      <Widget
        readMode={readMode}
        title={I18n.t("rich_text_editor.custom_components.charticle_top_products")}
        element={element}
        editable
      >
        <div className={classes.wrap}>
          {!product ? (
            <div className={classes.placeHolder}>
              <span>{I18n.t("rich_text_editor.product_cta.select_product")}</span>
            </div>
          ) : (
            <div className={classes.product}>
              <div className={classes.leftWrapper}>
                {element.data.position?.show && <div className={classes.position}>{element.data.position.index}</div>}
                {element.data.logo?.show && (
                  <div className={classes.logoWrapper}>
                    {element.data.logo?.show && (
                      <img className={classes.logoImage} src={product?.logoFile?.cdnUrl} alt={product?.logoFile?.alt} />
                    )}
                  </div>
                )}
                <div className={classes.mainWrapper}>
                  {element.data.rating?.show && (
                    <div className={classes.rating}>
                      {/*<RatingStarIcon active className={classes.ratingStarIcon} />*/}
                      <Rating
                        readOnly
                        classes={{
                          decimal: classes.decimal,
                          iconFilled: classes.iconFilled,
                          iconHover: classes.iconHover,
                        }}
                        value={parseFloat(product?.rating || "0")}
                        defaultValue={0}
                        precision={0.1}
                        icon={<RatingStarIcon />}
                        emptyIcon={<RatingStarIcon empty />}
                      />
                      <Typography
                        style={{ color: websiteTheme.palette.primary.main }}
                        className={classes.ratingText}
                        variant={"body2"}
                      >
                        {I18n.t("rich_text_editor.call_to_action.reviews", { count: product?.numOfReviews || 0 })}
                      </Typography>
                    </div>
                  )}
                  {element.data.shortDescription?.show && product?.shortDescription && (
                    <Typography className={classes.shortDescriptionText} variant={"body2"}>
                      {product.shortDescription}
                    </Typography>
                  )}
                  {element.data.bullets?.show && product.bullets.length > 0 && (
                    <div className={classes.bulletsList}>
                      {product.bullets.map((bullet) => (
                        <div key={bullet} className={classes.bullet}>
                          {bulletsIcon[element.data.bullets.icon.key || "disc_icon"](element.data.bullets.icon.color)}
                          <span>{bullet}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.rightWrapper}>
                {element.data.score?.show && (
                  <div className={classes.scoreRow}>
                    <span className={classes.scoreText}>{getScoreText(product.score || "6")}</span>
                    <div className={classes.scoreBackground}>
                      <span className={classes.scoreValue}>{product.score || "6"}</span>
                    </div>
                  </div>
                )}
                {element.data.button?.show && (
                  <div
                    className={ClassNames(
                      classes.buttonContainer,
                      (product.shortDescription || (product.bullets && product.bullets.length > 0)) &&
                        classes.buttonContainerCentered
                    )}
                  >
                    <ButtonWidgetBaseElement
                      text={
                        product?.callToAction ||
                        element.data.button.text ||
                        I18n.t("rich_text_editor.choose_product_to_add", { field: "Call To Action" })
                      }
                      url={
                        product?.callToActionURL || I18n.t("rich_text_editor.choose_product_to_add", { field: "URL" })
                      }
                      css={element.data.button.css}
                      hoverCss={element.data.button.css?.["&:hover"] || {}}
                      showArrow={element.data?.button.showArrow || false}
                      readMode={false}
                      websiteTheme={websiteTheme}
                      className={classes.buttonStyle}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div style={{ display: "none" }}>{children}</div>
      </Widget>
    </div>
  );
};

export default connect(mapStateToProps)(CharticleTopProductWidget);
