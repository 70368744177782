import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { web } from "../../../helpers/urlHelper";
import ColorThemePalette from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/Theme/ColorThemePalette";
import Article from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/Article";
import MainPageTypography from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/Theme/MainPageTypography";
import ElementDesign from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/Theme/ElementDesign";
import ImageDesign from "../../WebsiteConfigurations/WebsiteEditor/WebsitePages/Theme/ImageDesign";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const DesignTheme = () => (
  <div>
    <MHeaderMetaTags title={I18n.t(`meta_tags.page_builder.theme.title`)} />
    <Switch>
      <Route exact={false} path={web.theme("color-theme")} component={ColorThemePalette} />
      <Route exact={false} path={web.theme("article")} component={Article} />
      <Route exact={false} path={web.theme("main-page-typography")} component={MainPageTypography} />
      <Route exact={false} path={web.theme("content-elements")} component={ElementDesign} />
      {/* <Route exact={false} path={web.theme("tables")} /> */}
      <Route exact={false} path={web.theme("images")} component={ImageDesign} />

      <Redirect exact from={"*"} to={web.theme("color-theme")} />
    </Switch>
  </div>
);

export default DesignTheme;
