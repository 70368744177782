import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { kebabCase } from "lodash";
import { IExpert, IWebsite } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import MButtonGroup from "../../../../../components/MButtonGroup";

const useStyles = makeStyles((theme: Theme) => ({
  bottomBar: {
    minHeight: 60,
    paddingRight: 40,
    paddingLeft: 40,
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderTop: `${theme.palette.divider} solid 1px`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    bottom: 0,
    position: "sticky",
  },
  middleWrapper: {
    width: 960,
    margin: "auto",
    display: "flex",
    justifyContent: "end",
    paddingRight: 265,
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(18),
    textTransform: "capitalize",
    padding: "12px 40px",
    borderRadius: 75,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  expert: IExpert;

  handleExpertUpdated: (expert: IExpert) => void;
};

const ExpertBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, expert, handleExpertUpdated } = props;
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const validExpert = () => {
    if (!expert.email) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Email" }));
      return false;
    }

    if (!expert.firstName) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "First Name" }));
      return false;
    }
    if (!expert.lastName) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Last Name" }));
      return false;
    }

    if (!expert.user?.websites || expert.user.websites.length === 0) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Websites" }));
      return false;
    }

    if (!expert.user?.locales || expert.user.locales.length === 0) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Languages" }));
      return false;
    }

    if (!expert.active) {
      return true;
    }

    return true;
  };

  const update = () => {
    if (!validExpert()) {
      return;
    }

    if (!expert.website) {
      expert.website = selectedWebsite;
    }

    if (!expert.slug) {
      expert.slug = kebabCase(`${expert.firstName} ${expert.lastName}`);
    }

    agent.Experts.update(expert)
      .then((res) => {
        handleExpertUpdated(res.expert);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  return (
    <div className={classes.bottomBar}>
      <div className={classes.middleWrapper}>
        {/* <MAutoSave autoSaveOn interval={300} lastSaveTime={selectedAudience.updatedAt} handleSavePost={() => update()} /> */}
        <MButtonGroup
          loading={saveInProgress}
          textTransform={"none"}
          text={I18n.t("experts.profile.save_changes")}
          onClick={(e) => update()}
          options={[
            {
              key: "save",
              label: I18n.t("experts.profile.save_changes"),
            },
            {
              key: "delete",
              label: I18n.t("upload_post.delete"),
            },
          ]}
          onMenuItemClick={(key: string) => {
            switch (key) {
              case "save":
                return update();
              case "delete":
              default:
                return null;
            }
          }}
        />
      </div>
    </div>
  );
};

export default ExpertBottomBar;
