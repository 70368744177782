import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useMemo } from "react";
import { find } from "lodash";
import theme from "../../themes/theme";
import { DEV } from "../../reducers/constants/consts";
import { CATEGORY, HOME } from "../../reducers/constants/actionTypes";
import agent from "../../agent";
import { web } from "../../helpers/urlHelper";
import { IAdminUser, IWebsite } from "../../reducers/constants/objectTypes";
import { AbilityContext } from "../../casl/can";
import Entail from "../Entail/Entail";
import CRO from "../CRO/CRO";
import Analysis from "../Analysis/Analysis";
import PostEditors from "../PostEditors/PostEditors";
import WorkFlow from "../WorkFlow/WorkFlow";
import VideoWorkFlow from "../VideoWorkFlow/VideoWorkFlow";
import PageEditor from "../PageEditor/PageEditor";
import Data from "../Data/Data";
import PagesTabs from "./components/PagesTabs";
import Community from "../Community/Community";
import DropdownTabs from "./components/DropdownTabs";
import UserDialog from "./components/UserDialog";
import PipeSeparatorIcon from "../../icons/PipeSeparatorIcon";
import PageBuilder from "../PageBuilder/PageBuilder";
import HeaderTabSettings from "./components/HeaderTabSettings";
import SubPagesTabs from "./components/SubPagesTabs";
import Legacy from "../Legacy/Legacy";
import {
  getCommunityTabs,
  getCampaignsTabs,
  getLegacyTabs,
  getPageBuilderTabs,
  getStrategyTabs,
  getVideoTabs,
  getWorkFlowTabs,
  getAnalyticsTabs,
} from "../../helpers/tabHelper";
import Reports from "../Analytics/Reports/Reports";
import VideoEditor from "../VideoEditor/VideoEditor";
import Analytics from "../Analytics/Analytics";
import IntegrationInstructions from "../Authentication/OnboardingFlow/instructions/IntegrationInstructions";

const useStyles = makeStyles(() => ({
  pageWrapper: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    display: "flex",
  },
  appBar: {
    zIndex: 4,
    backgroundColor: theme.palette.background.default,
    // justifyContent: 'space-between',
    display: "flex",
    flexDirection: "column",
    paddingLeft: 25,
    paddingRight: 30,
  },
  mainHeader: {
    display: "flex",
    flexDirection: "row",
    height: 60,
  },
  leftMainHeaderWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  adminWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  newPostWrapper: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
  },
  tabs: {
    marginRight: "auto",
    marginLeft: "auto",
    width: "unset",
  },
  userImageWrapper: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 0,
    marginLeft: 10,
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  userNameText: {
    marginLeft: 9,
    minWidth: 60,
    alignSelf: "center",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  snackbarDismissButton: {
    color: theme.palette.primary.main,
  },

  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  drawerWithPaddingLeft: {
    // paddingLeft: 20,
    marginLeft: 20,
  },
  logo: {
    color: theme.palette.primary.dark,
    width: 97,
    cursor: "pointer",
  },
  ai: {
    width: 11,
    height: 7,
    position: "relative",
    left: -10,
    bottom: 5,
  },
  pipeIcon: {
    alignSelf: "center",
    strokeWidth: 1,
    height: 24,
    marginRight: 25,
    marginLeft: 25,
  },
}));

type PropTypes = {
  admin: IAdminUser;
  adminLoaded: boolean;
  selectedWebsite: IWebsite;
  isWebsiteListActive: boolean;
  websites: IWebsite[];

  handleCategoriesLoaded: (payload: any) => void;
  onWebsiteSelected: (website: IWebsite) => void;
  subLogin: boolean;

  removeSubUser: () => void;
};

const mapStateToProps = (state) => ({
  adminLoaded: state.home.adminLoaded,
  admin: state.home.admin,
  websites: state.home.websites,
  selectedWebsite: state.home.selectedWebsite,
  isWebsiteListActive: state.home.isWebsiteListActive,
  subLogin: state.home.subLogin,
});

const mapDispatchToProps = (dispatch) => ({
  handleCategoriesLoaded: (payload) => dispatch({ type: CATEGORY.CATEGORIES_LOADED, payload }),
  onWebsiteSelected: (selectedWebsite) => dispatch({ type: HOME.ON_WEBSITES_SELECTED, selectedWebsite }),
  removeSubUser: () => dispatch({ type: HOME.SUB_ADMIN_USER_REMOVE }),
});

const MainRouter = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    admin,
    adminLoaded,
    selectedWebsite,
    websites,
    onWebsiteSelected,
    handleCategoriesLoaded,
    subLogin,
    removeSubUser,
  } = props;
  const { websiteName } = useParams();
  const ability = React.useContext<any>(AbilityContext);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    handleCategoriesLoaded(agent.Categories.all(selectedWebsite._id));
  }, [selectedWebsite, handleCategoriesLoaded]);

  React.useEffect(() => {
    function keepOnPage(ev) {
      ev.preventDefault();
      ev.returnValue = "Are you sure you want to close?";
      return ev;
    }

    if (!DEV) {
      window.addEventListener("beforeunload", keepOnPage);
    }

    return () => {
      if (!DEV) {
        window.removeEventListener("beforeunload", keepOnPage);
      }
    };
  }, []);

  React.useEffect(() => {
    // console.log("Main useEffect websiteNameMatch", selectedWebsite?.name, websiteName);

    if (!selectedWebsite || !websiteName || selectedWebsite.name === websiteName) {
      // console.log("Main useEffect websiteNameMatch return 1");
      return;
    }

    if (!websites) {
      // console.log("Main useEffect websiteNameMatch return 2");
      return;
    }

    const website = find(websites, (w) => w.name === websiteName);
    // console.log("Main useEffect websiteNameMatch  select website", website);
    if (website) {
      onWebsiteSelected(website);
    }
  }, [selectedWebsite, websiteName, onWebsiteSelected]);

  const essentials = useMemo(() => {
    if (!selectedWebsite) return [];
    const items = [];
    items.push({
      url: web.workflow(),
      backgroundColor: "#4E3FC9",
      label: I18n.t("main.tabs.article_cms"),
      description: I18n.t("main.tabs.article_cms_description"),
      canView: ability.can("view", "cms_tab") && getWorkFlowTabs(ability, selectedWebsite).length > 0,
      key: "cms",
      parent: "entail_cms",
    });
    items.push({
      url: web.videoWorkflow(),
      backgroundColor: "#1270BC",
      label: I18n.t("main.tabs.video_cms"),
      description: I18n.t("main.tabs.video_cms_description"),
      canView:
        selectedWebsite.configurations.siteDetails.videoCmsAccess &&
        ability.can("view", "video_tab") &&
        getVideoTabs(ability).length > 0,
      key: "video",
      parent: "entail_cms",
    });

    items.push({
      url: web.strategy(),
      mainLabel: I18n.t("main.tabs.strategy_software"),
      label: I18n.t("main.tabs.strategy"),
      parent: "entail_cms",
      backgroundColor: "#073E7F",
      description: I18n.t("main.tabs.strategy_description"),
      canView: ability.can("view", "data_tab") && getStrategyTabs(ability).length > 0,
      key: "strategy",
    });

    items.push({
      url: web.pageBuilder(),
      label: I18n.t("main.tabs.page_builder"),
      backgroundColor: "#9A2974",
      description: I18n.t("main.tabs.page_builder_description"),
      canView: ability.can("view", "content_manager_tab") && getPageBuilderTabs(ability).length > 0,
      key: "page_builder",
      parent: "entail_cms",
    });
    items.push({
      url: web.community(),
      label: I18n.t("main.tabs.community"),
      description: I18n.t("main.tabs.community_description"),
      canView:
        !ability.can("customer", "role") &&
        ability.can("view", "cms_tab") &&
        selectedWebsite.configurations.sections.forum &&
        getCommunityTabs(ability).length > 0,
      key: "community",
      parent: "entail_cms",
    });
    items.push({
      url: web.campaigns(),
      label: I18n.t("main.tabs.cro"),
      backgroundColor: "#AC0F22",
      description: I18n.t("main.tabs.cro_description"),
      canView: ability.can("view", "cro_tab") && getCampaignsTabs(ability).length > 0,
      key: "cro",
      parent: "entail_cro",
    });

    items.push({
      url: web.analytics(),
      label: I18n.t("main.tabs.cro_analytics"),
      backgroundColor: "#AC0F22",
      description: I18n.t("main.tabs.cro_analytics_description"),
      canView: ability.can("view", "cro_tab") && getAnalyticsTabs(ability).length > 0,
      key: "cro_analytics",
      parent: "entail_cro",
    });

    if (ability.can("view", "legacy_tab") || getLegacyTabs(ability, selectedWebsite).length > 0) {
      items.push({
        url: web.legacy(),
        backgroundColor: "#0948CB",
        label: I18n.t("main.tabs.legacy"),
        description: I18n.t("main.tabs.legacy_description"),
        canView: true,
        tab: true,
        parent: "entail_legacy",
      });
    }
    return items;
  }, [selectedWebsite, ability]);

  const routes = React.useMemo(() => {
    if (!selectedWebsite) return [];
    const allRoutes = [];
    if (ability.can("view", "cms_tab")) {
      allRoutes.push({
        url: web.workflow(),
        route: <Route exact={false} key={selectedWebsite._id} path={web.workflow()} render={() => <WorkFlow />} />,
      });
      if (selectedWebsite.configurations.sections.forum && !ability.can("customer", "role")) {
        allRoutes.push({
          url: web.community(),
          route: <Route exact={false} key={selectedWebsite._id} path={web.community()} render={() => <Community />} />,
        });
      }
    }
    if (selectedWebsite.configurations.siteDetails.videoCmsAccess && ability.can("view", "video_tab")) {
      allRoutes.push({
        url: web.videoWorkflow(),
        route: (
          <Route exact={false} key={selectedWebsite._id} path={web.videoWorkflow()} render={() => <VideoWorkFlow />} />
        ),
      });
    }
    if (ability.can("view", "content_manager_tab")) {
      allRoutes.push({
        url: web.pageBuilder(),
        route: (
          <Route exact={false} key={selectedWebsite._id} path={web.pageBuilder()} render={() => <PageBuilder />} />
        ),
      });
      allRoutes.push({
        url: web.analysis(),
        route: <Route exact={false} key={selectedWebsite._id} path={web.analysis()} render={() => <Analysis />} />,
      });
    }
    if (ability.can("view", "data_tab")) {
      allRoutes.push({
        url: web.strategy(),
        route: <Route exact={false} key={selectedWebsite._id} path={web.strategy()} render={() => <Data />} />,
      });
    }

    if (ability.can("view", "legacy_tab") || getLegacyTabs(ability, selectedWebsite).length > 0) {
      allRoutes.push({
        url: web.legacy(),
        route: <Route exact={false} key={selectedWebsite._id} path={web.legacy()} render={() => <Legacy />} />,
      });
    }
    if (ability.can("view", "cro_tab")) {
      allRoutes.push({
        url: web.campaigns(),
        route: <Route exact={false} key={selectedWebsite._id} path={web.campaigns()} render={() => <CRO />} />,
      });
    }

    if (ability.can("view", "cro_tab")) {
      allRoutes.push({
        url: web.analytics(),
        route: <Route exact={false} key={selectedWebsite._id} path={web.analytics()} render={() => <Analytics />} />,
      });
    }

    if (selectedWebsite.name === "Entail") {
      allRoutes.push({
        url: web.entail(),
        route: <Route exact={false} key={selectedWebsite._id} path={web.entail()} render={() => <Entail />} />,
      });
    }

    return allRoutes;
  }, [selectedWebsite, ability]);

  if (!selectedWebsite) return null;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.pageWrapper} style={subLogin ? { border: "3px solid red" } : null}>
        <AppBar elevation={0} position="sticky" className={classes.appBar}>
          <div className={classes.mainHeader}>
            <div className={classes.leftMainHeaderWrapper}>
              <DropdownTabs essentials={essentials} />
              <PipeSeparatorIcon className={classes.pipeIcon} />
            </div>

            <PagesTabs selectedWebsite={selectedWebsite} />

            <div className={classes.adminWrapper}>
              <HeaderTabSettings selectedWebsite={selectedWebsite} />

              {adminLoaded && (
                <div className={classes.userImageWrapper}>
                  {admin && <UserDialog admin={admin} subLogin={subLogin} removeSubUser={removeSubUser} />}
                  {!admin && (
                    <Typography className={classes.userNameText} variant={"subtitle2"}>
                      {I18n.t("header.sign_in")}
                    </Typography>
                  )}
                </div>
              )}
            </div>
          </div>
          <SubPagesTabs selectedWebsite={selectedWebsite} />
        </AppBar>

        <main className={classes.content}>
          {/* {websites.length > 1 && <WebsiteDrawer ref={websiteDrawerRef} />} */}
          <Switch>
            {routes.map((route) => route.route)}
            <Route
              exact={false}
              key={selectedWebsite._id}
              path={[web.editorPostPrefix(), web.editorTopicPrefix()]}
              render={() => <PostEditors />}
            />
            <Route
              exact={false}
              key={selectedWebsite._id}
              path={[web.videoWorkflowEditVideo(":videoProjectId")]}
              render={() => <VideoEditor />}
            />
            <Route
              exact={false}
              key={selectedWebsite._id}
              path={[web.editPage(":pageId")]}
              render={(routeProps) => (
                <PageEditor
                  role={"WRITER"}
                  returnTo={new URLSearchParams(routeProps.location.search).get("returnTo")}
                />
              )}
            />
            <Route
              key={`cro_${selectedWebsite._id}_reports`}
              exact={false}
              path={[web.croReports(":reportType")]}
              render={(routeProps) => {
                if (ability.can("view", "cro_reports_tab")) {
                  return <Reports returnTo={new URLSearchParams(routeProps.location.search).get("returnTo")} />;
                }
                return <Redirect to={routes[0]?.url} />;
              }}
            />

            <Redirect from={"*"} exact to={routes[0]?.url} />
          </Switch>
        </main>
        <IntegrationInstructions />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainRouter);
