import * as React from "react";
import { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { useLocation } from "react-router-dom";
import numeral from "numeral";
import {
  IWebsite,
  ICampaign,
  eCampaignAction,
  eCampaignStatus,
  eActionIcon,
} from "../../../../../../reducers/constants/objectTypes";
import MActionsDropdown from "../../../../../../components/MActionsDropdown";
import MTableCellDate from "../../../../../../components/Table/Cells/MTableCellDate";
import MTableCellFirst from "../../../../../../components/Table/Cells/MTableCellFirst";
import MTableRow from "../../../../../../components/Table/Rows/MTableRow";
import MTableCell from "../../../../../../components/Table/Cells/MTableCell";
import { sortBy } from "lodash";
import MTableCellWithElementListTooltip from "../../../../../../components/Table/Cells/MTableCellWithElementListTooltip";
import WidgetAvatar from "../../../../../../icons/WidgetAvatar";
import MTableCellNumber from "../../../../../../components/Table/Cells/MTableCellNumber";
import LoudspeakerIcon from "../../../../../../icons/LoudspeakerIcon";
import MTableCellFirstWithSelect from "../../../../../../components/Table/Cells/MTableCellFirstWithSelect";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
  },
  avatarWrapper: {
    backgroundColor: theme.palette.primary.main,
    height: 25,
    width: 25,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pagesTooltip: {
    maxHeight: 200,
    maxWidth: 450,
    padding: 10,
    background: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    margin: 0,
    marginLeft: "-10px",
    marginTop: 3,
    overflow: "auto",
  },
  analyticsTooltip: {
    maxHeight: 300,
    maxWidth: 800,
    padding: 10,
    fontWeight: theme.typography.fontWeightRegular as any,
    background: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    margin: 0,
    marginLeft: "-10px",
    marginTop: 3,
    overflow: "auto",
  },
  pageItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 3,
  },
  divider: {
    color: theme.palette.text.primary,
    padding: "2px 5px",
  },
  pageTitle: {
    fontSize: 14,
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  editPageWrapper: {
    flexGrow: 1,
    flexShrink: 0,
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      "& $editIcon": {
        fill: theme.palette.primary.main,
      },
      "& $pageTitle": {
        color: theme.palette.primary.main,
      },
    },
  },
  editIcon: {
    fontSize: 15,
    fill: theme.palette.text.primary,
  },
  hoveredCell: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type PropTypes = {
  campaign: ICampaign;
  selectedWebsite: IWebsite;
  timePeriod: any;
  isChecked?: boolean;

  handleClick: (id: string) => void;
  deleteCampaign: (id: string) => void;
  publishCampaign: (campaign: ICampaign) => void;
  unPublishCampaign: (campaign: ICampaign) => void;
  restoreCampaign: (campaign: ICampaign) => void;
  openWidgetAnalyticsDialog: (campaign: ICampaign) => void;
  handleCheckChange: (checked: boolean) => void;
};

const CampaignListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { campaign, timePeriod, selectedWebsite, isChecked } = props;
  const {
    handleClick,
    deleteCampaign,
    publishCampaign,
    unPublishCampaign,
    restoreCampaign,
    openWidgetAnalyticsDialog,
    handleCheckChange,
  } = props;
  const theme = useTheme();
  const location = useLocation();

  const options = useMemo(() => {
    const optionsArr = [];

    switch (campaign.status) {
      case eCampaignStatus.DRAFT:
        optionsArr.push(
          {
            value: eCampaignAction.PUBLISH,
            label: I18n.t("widgets_display_rules_workflow.actions.publish"),
          },
          {
            value: eCampaignAction.DELETE,
            label: I18n.t("widgets_display_rules_workflow.actions.delete"),
          }
        );
        break;
      case eCampaignStatus.ACTIVE:
        optionsArr.push(
          {
            value: eCampaignAction.UNPUBLISH,
            label: I18n.t("widgets_display_rules_workflow.actions.unpublish"),
          },
          {
            value: eCampaignAction.DELETE,
            label: I18n.t("widgets_display_rules_workflow.actions.delete"),
          }
        );
        break;
      case eCampaignStatus.DELETED:
        optionsArr.push(
          { value: eCampaignAction.RESTORE, label: I18n.t("widgets_display_rules_workflow.actions.restore") },
          {
            value: eCampaignAction.DELETE_PERMANENTLY,
            label: I18n.t("widgets_display_rules_workflow.actions.delete_permanently"),
          }
        );
        break;
      default:
        throw new Error(`Unhandled status code - ${campaign.status}`);
    }

    return optionsArr;
  }, [deleteCampaign, restoreCampaign, campaign]);

  const handleActionClick = (action: eCampaignAction) => {
    switch (action) {
      case eCampaignAction.DELETE:
      case eCampaignAction.DELETE_PERMANENTLY:
        deleteCampaign(campaign._id);
        break;
      case eCampaignAction.RESTORE:
        restoreCampaign(campaign);
        break;
      case eCampaignAction.PUBLISH:
        publishCampaign(campaign);
        break;
      case eCampaignAction.UNPUBLISH:
        unPublishCampaign(campaign);
        break;
      default:
        throw new Error(`Unknown type: ${action}`);
    }
  };

  return (
    <MTableRow
      handleRowClick={() => {
        handleClick(campaign._id);
      }}
    >
      <MTableCellFirstWithSelect
        fontSize={theme.typography.pxToRem(14)}
        columnWidth={"50%"}
        cellText={campaign.name}
        selected={isChecked}
        onSelect={handleCheckChange}
        prefix={
          <div className={classes.avatarWrapper}>
            <LoudspeakerIcon />
          </div>
        }
      />
      <MTableCellDate date={campaign.updatedAt} />
      <MTableCell>{campaign.status}</MTableCell>

      <MTableCellNumber
        className={classes.hoveredCell}
        onClick={(e) => {
          e.stopPropagation();
          openWidgetAnalyticsDialog(campaign);
        }}
        value={campaign.analytics?.[timePeriod.key]?.totalViews || 0}
      />

      <MTableCellNumber
        className={classes.hoveredCell}
        onClick={(e) => {
          e.stopPropagation();
          openWidgetAnalyticsDialog(campaign);
        }}
        value={campaign.analytics?.[timePeriod.key]?.totalWidgetViews || 0}
      />

      <MTableCellNumber
        className={classes.hoveredCell}
        onClick={(e) => {
          e.stopPropagation();
          openWidgetAnalyticsDialog(campaign);
        }}
        value={campaign.analytics?.[timePeriod.key]?.totalClicks || 0}
      />

      <MTableCellNumber
        className={classes.hoveredCell}
        onClick={(e) => {
          e.stopPropagation();
          openWidgetAnalyticsDialog(campaign);
        }}
        value={(campaign.analytics?.[timePeriod.key]?.ctr || 0) * 1000}
      />

      <MTableCell>{campaign.widgetContainers.length}</MTableCell>

      <MTableCell>
        <MActionsDropdown options={options} onClick={(option) => handleActionClick(option.value as eCampaignAction)} />
      </MTableCell>
    </MTableRow>
  );
};

export default CampaignListItem;
