import * as React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { Button } from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import { camelCase } from "lodash";
import {
  IWebsite,
  IWebsiteConfigurationsProps,
  IWebsiteDynamicSectionProps,
  IWebsiteGSC,
  IWebsiteReviewStep,
} from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import { web } from "../../../helpers/urlHelper";
import { HOME, WEBSITE_PAGES } from "../../../reducers/constants/actionTypes";
import SaveChangesButton from "../../../components/SaveChangesButton";

const useStyles = makeStyles((theme: Theme) => ({
  bottomBar: {
    minHeight: 60,
    paddingRight: 40,
    paddingLeft: 40,
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderTop: `${theme.palette.divider} solid 1px`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    bottom: 0,
    position: "sticky",
    zIndex: 100,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  websitePages: IWebsiteConfigurationsProps;
  dynamicSection: IWebsiteDynamicSectionProps;
  updatedSections: IWebsiteConfigurationsProps;
  changesLostWarning: boolean;
  name: string;
  url: string;
  host: string;
  publisherName: string;
  websiteActive: boolean;
  websiteUploadContent: boolean;
  secondEditStep: boolean;
  reviews: IWebsiteReviewStep[];
  reviewProcess: "None" | "Fact-Check" | "Custom";
  gsc: IWebsiteGSC;
  onWebsiteUpdated: (website) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  updatedSections: state.websitePages.updatedSections,
  changesLostWarning: state.websitePages.changesLostWarning,
  name: state.websitePages.name,
  url: state.websitePages.url,
  host: state.websitePages.host,
  publisherName: state.websitePages.publisherName,
  reviews: state.websitePages.reviews,
  reviewProcess: state.websitePages.reviewProcess,
  websiteActive: state.websitePages.websiteActive,
  websiteUploadContent: state.websitePages.websiteUploadContent,
  secondEditStep: state.websitePages.secondEditStep,
  websitePages: state.websitePages,
  dynamicSection: state.websitePages.dynamicSection,
  gsc: state.websitePages.gsc,
});

const mapDispatchToProps = (dispatch) => ({
  onWebsiteUpdated: (website) => {
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_CONFIGURATIONS_LOADED, website });
    dispatch({ type: HOME.ON_WEBSITE_UPDATED, website });
  },
});
const WebsiteBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedWebsite,
    onWebsiteUpdated,
    updatedSections,
    url,
    name,
    host,
    publisherName,
    reviews,
    reviewProcess,
    websiteActive,
    websiteUploadContent,
    secondEditStep,
    changesLostWarning,
    dynamicSection,
    gsc,
  } = props;

  const pageTypeMatch = useRouteMatch({
    path: web.developerTools(":pageType"),
    strict: true,
    sensitive: true,
  });

  const currentPage = pageTypeMatch?.params?.pageType;

  const updateGSC = () => {
    console.log("updateGSC");
    agent.Websites.updateGSC(selectedWebsite._id, gsc)
      .then((res) => {
        onWebsiteUpdated(res.website);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  const update = () => {
    console.log("currentPage", currentPage);
    if (currentPage === "gsc") {
      updateGSC();
      return;
    }
    if (!changesLostWarning) {
      enqueueSnackbar(I18n.t("snackbar.update_empty"));
      return;
    }
    const newUpdatedSections = { ...updatedSections };
    const dynamicSections = [...selectedWebsite.configurations.dynamicSections];
    if (dynamicSection.name) {
      const currentDynamicSection = dynamicSections.find((section) => section._id === dynamicSection._id);
      if (currentDynamicSection) {
        dynamicSections[dynamicSections.findIndex((section) => section._id === dynamicSection._id)] = {
          ...dynamicSection,
        };
      } else {
        const duplicateDynamicSection = dynamicSections.find((ds) => ds.name === dynamicSection.name);
        if (duplicateDynamicSection !== undefined) {
          enqueueSnackbar(
            I18n.t("snackbar.update_error", {
              msg: `${dynamicSection.name} already exists please choose a different name`,
            })
          );
          return;
        }
        dynamicSections.push({ ...dynamicSection, type: camelCase(dynamicSection.name) });
      }
      delete newUpdatedSections.dynamicSection;
    }
    const configurations = {
      ...newUpdatedSections,
      dynamicSections,
    };

    const clearReviews = reviews.filter((review) => review.reviewType !== "none");
    agent.Websites.updateConfigurations({
      _id: selectedWebsite._id,
      url,
      name,
      host,
      publisherName,
      active: websiteActive,
      uploadContent: websiteUploadContent,
      secondEditStep,
      reviews: clearReviews,
      reviewProcess,
      configurations,
    })
      .then(async (res) => {
        onWebsiteUpdated(res.website);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  return (
    <div className={classes.bottomBar}>
      <SaveChangesButton onClick={(e) => update()} text={I18n.t("website.save_changes")} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteBottomBar);
