import * as React from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { push } from "connected-react-router";
import { useSnackbar } from "notistack";
import { ADS } from "../../../../../reducers/constants/actionTypes";
import { IAd, IWebsite } from "../../../../../reducers/constants/objectTypes";
import AdItem from "./AdItem";
import agent from "../../../../../agent";
import { web } from "../../../../../helpers/urlHelper";
import MTable from "../../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../../components/Table/Rows/MTableRowEmptyRow";

type PropTypes = {
  selectedWebsite: IWebsite;
  adsLoaded: boolean;
  ads: IAd[];
  adsSearchText: string;
  searchConsoleInterval:
    | "today"
    | "lastWeek"
    | "last2Weeks"
    | "lastMonth"
    | "last2Months"
    | "last3Months"
    | "last6Months"
    | "lastYear";

  handleAdsLoaded: Function;
  handleAdDeleted: Function;

  openAdPage: Function;
  handleAdChanged: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  adsSearchText: state.ads.adsSearchText,
  searchConsoleInterval: state.ads.searchConsoleInterval,

  adsLoaded: state.ads.adsLoaded,
  ads: state.ads.ads,
});

const mapDispatchToProps = (dispatch) => ({
  handleAdsLoaded: (payload) => {
    dispatch({ type: ADS.ADS_LIST_LOADED, payload });
  },
  handleAdDeleted: (ad) => {
    dispatch({ type: ADS.ADS_LIST_AD_DELETED, ad });
  },
  handleAdChanged: (ad) => {
    dispatch({ type: ADS.ADS_LIST_AD_CHANGED, ad });
  },
  openAdPage: (ad) => dispatch(push(web.adsList(ad._id))),
});

const AdsList = (props: PropTypes) => {
  const { selectedWebsite, adsLoaded, ads, adsSearchText, searchConsoleInterval } = props;
  const { handleAdDeleted, handleAdsLoaded, handleAdChanged, openAdPage } = props;
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    handleAdsLoaded(agent.Ads.getAds(selectedWebsite._id));
  }, [selectedWebsite, handleAdsLoaded]);

  const onDeleteClusterClicked = (ad) => {
    if (confirm("All changes will be lost. Are you sure you want to delete this ad?")) {
      agent.Ads.deleteAd(ad._id)
        .then((res) => {
          handleAdDeleted(ad);
          enqueueSnackbar(I18n.t("snackbar.update_success"));
        })
        .catch((e) => {
          enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
        });
    }
  };

  const updateAd = (ad) => {
    agent.Ads.updateAd(ad)
      .then((res) => {
        handleAdChanged(ad);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  let filteredAds = adsLoaded ? ads : [];
  if (adsSearchText !== "") {
    filteredAds = filteredAds.filter((c) => c.name.toLowerCase().includes(adsSearchText.toLowerCase()));
  }
  const theme = useTheme();

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabel columnWidth={"40%"} fontSize={theme.typography.pxToRem(21)}>
          {I18n.t("ads.ads_list.header.ad_name")}
        </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("ads.ads_list.header.status")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("ads.ads_list.header.last_edit")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("ads.ads_list.header.business")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("ads.ads_list.header.placements")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("ads.ads_list.header.start_date")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("ads.ads_list.header.impressions")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("ads.ads_list.header.clicks")}</MTableHeaderLabel>
        <MTableHeaderLabel columnWidth={"100px"}> {""}</MTableHeaderLabel>
      </MTableHeader>
      {!adsLoaded && <MTableRowProgressBar />}
      {adsLoaded && filteredAds.length === 0 && <MTableRowEmptyRow />}
      {adsLoaded &&
        filteredAds.length > 0 &&
        filteredAds.map((ad, index) => (
          <AdItem
            key={ad._id}
            ad={ad}
            searchConsoleInterval={searchConsoleInterval}
            handleAdChanged={(newAd) => updateAd(newAd)}
            handleDeleteClick={() => onDeleteClusterClicked(ad)}
            openAdPage={() => openAdPage(ad)}
          />
        ))}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsList);
