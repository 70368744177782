import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { ReactEditor, useSlate } from "slate-react";
import { ComponentType, useState } from "react";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import { Transforms } from "slate";
import HyperLinkIcon from "../toolbar/icons/HyperLinkIcon";
import link from "./link";
import LinkEditor from "./LinkEditor";
import { withHttps } from "../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: 999,
  },
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  activeIcon: {
    fill: theme.palette.primary.main,
  },
}));

type PropTypes = {
  Icon?: ComponentType<any>;
  onClose?: Function;
};

function LinkButton(props: PropTypes) {
  const classes = useStyles(props);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const editor = useSlate();
  const { Icon = HyperLinkIcon, onClose } = props;
  return (
    <div style={{ display: "contents" }}>
      <Tooltip enterDelay={2000} title={I18n.t("rich_text_editor.tooltips.insert_link")}>
        <IconButton
          id={"insert-link-button"}
          className={classes.button}
          ref={anchorRef}
          disableRipple
          onClick={(event) => {
            if (onClose) {
              onClose();
            }
            event.preventDefault();
            if (link.isLinkActive(editor)) {
              link.removeLink(editor);
              return;
            }
            link.insertLink(editor, { url: "", newTab: true, rel: "follow", linkType: "url" });
            Transforms.select(editor, { ...editor.selection });
          }}
        >
          <Icon className={ClassNames(classes.icon, link.isLinkActive(editor) && classes.activeIcon)} />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default LinkButton;
