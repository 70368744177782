import React from "react";
import Typography from "@material-ui/core/Typography";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import theme from "../../../../../themes/theme";
import MQueryGroupLimitPicker from "../../../../../components/MQueryGroupLimitPicker";

const useStyles = makeStyles(() => ({
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: 20,
  },
  recordsLimitField: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  recordsLimitTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: 10,
  },
}));

type PropTypes = {
  pageSize: number;
  page: number;
  paginationPagesCount: number;
  setPageSize: (limit: number) => void;
  setPage: (limit: number) => void;
};

const ExpertsPagination = (props: PropTypes) => {
  const classes = useStyles();
  const { pageSize, page, paginationPagesCount, setPageSize, setPage } = props;
  const paginationLimitOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 100,
      label: "100",
    },
  ];

  return (
    <div className={classes.paginationWrapper}>
      <div className={classes.recordsLimitField}>
        <Typography className={classes.recordsLimitTitle} variant={"body1"}>
          {I18n.t("queries.groups.limit")}
        </Typography>
        <MQueryGroupLimitPicker
          limit={pageSize}
          menuPlacement={"top"}
          handleLimitChange={(limit) => {
            setPageSize(limit);
          }}
          options={paginationLimitOptions}
        />
      </div>
      <Pagination page={page} count={paginationPagesCount || 1} color={"primary"} onChange={(e, val) => setPage(val)} />
    </div>
  );
};

export default ExpertsPagination;
