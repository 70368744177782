import _ from "lodash";
import { SCRAPING } from "./constants/actionTypes";

const initialState = {
  workersLoaded: false,
  workers: [],
  selectedWorker: null,
  workersFilter: "all",
  workersSearchText: "",

  keywordsLoaded: false,
  keywords: [],
  keywordsSearchText: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SCRAPING.WORKERS_LOADED:
      return {
        ...state,
        workersLoaded: true,
        workers: action.payload.workers,
        selectedWorker: state.selectedWorker || action.payload?.workers[0],
      };
    case SCRAPING.WORKERS_FILTER_CHANGED:
      return {
        ...state,
        workersFilter: action.websiteId,
      };
    case SCRAPING.WORKERS_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        workersSearchText: action.text,
      };
    case SCRAPING.WORKERS_WORKER_SELECTED:
      return {
        ...state,
        selectedWorker: action.worker,
      };
    case SCRAPING.WORKERS_WORKER_UPDATED:
      return {
        ...state,
        selectedWorker: action.payload.worker,
        workers: state.workers.map((w) => {
          if (w._id === action.payload.worker._id) {
            return action.payload.worker;
          }
          return w;
        }),
      };

    case SCRAPING.KEYWORDS_LOADED:
      return {
        ...state,
        keywordsLoaded: true,
        keywords: action.payload.keywords,
      };
    case SCRAPING.KEYWORDS_CREATED:
      return {
        ...state,
        keywords: _.uniqBy([...state.keywords, ...action.keywords], "_id").sort((a, b) => a.name.localeCompare(b.name)),
      };
    case SCRAPING.KEYWORDS_KEYWORD_DELETED:
      return {
        ...state,
        keywords: state.keywords.filter((k) => k._id !== action.keyword._id),
      };
    case SCRAPING.KEYWORDS_KEYWORD_UPDATED:
      return {
        ...state,
        keywords: state.keywords.map((k) => {
          if (k._id === action.keyword._id) {
            return action.keyword;
          }
          return k;
        }),
      };
    case SCRAPING.KEYWORDS_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        keywordsSearchText: action.searchText,
      };
    default:
      return state;
  }
};
