import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 20,
    height: 16,
  },
}));

type PropTypes = {
  className?: string;
  onClick?: (e: any) => void;
};

function TrashIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className, onClick } = props;
  return (
    <SvgIcon className={className} viewBox={"0 0 16 16"} onClick={onClick}>
      <path d="M2.43748 15.5C2.10415 15.5 1.81248 15.375 1.56248 15.125C1.31248 14.875 1.18748 14.5833 1.18748 14.25V2.375H0.958313C0.777757 2.375 0.628452 2.31597 0.510396 2.19792C0.392341 2.07986 0.333313 1.93056 0.333313 1.75C0.333313 1.56944 0.392341 1.42014 0.510396 1.30208C0.628452 1.18403 0.777757 1.125 0.958313 1.125H4.24998C4.24998 0.944444 4.30901 0.795139 4.42706 0.677083C4.54512 0.559028 4.69442 0.5 4.87498 0.5H9.12498C9.30554 0.5 9.45484 0.559028 9.5729 0.677083C9.69095 0.795139 9.74998 0.944444 9.74998 1.125H13.0416C13.2222 1.125 13.3715 1.18403 13.4896 1.30208C13.6076 1.42014 13.6666 1.56944 13.6666 1.75C13.6666 1.93056 13.6076 2.07986 13.4896 2.19792C13.3715 2.31597 13.2222 2.375 13.0416 2.375H12.8125V14.25C12.8125 14.5833 12.6875 14.875 12.4375 15.125C12.1875 15.375 11.8958 15.5 11.5625 15.5H2.43748ZM2.43748 2.375V14.25H11.5625V2.375H2.43748ZM4.64581 11.8333C4.64581 12.0139 4.70484 12.1632 4.8229 12.2812C4.94095 12.3993 5.09026 12.4583 5.27081 12.4583C5.45137 12.4583 5.60067 12.3993 5.71873 12.2812C5.83679 12.1632 5.89581 12.0139 5.89581 11.8333V4.77083C5.89581 4.59028 5.83679 4.44097 5.71873 4.32292C5.60067 4.20486 5.45137 4.14583 5.27081 4.14583C5.09026 4.14583 4.94095 4.20486 4.8229 4.32292C4.70484 4.44097 4.64581 4.59028 4.64581 4.77083V11.8333ZM8.10415 11.8333C8.10415 12.0139 8.16317 12.1632 8.28123 12.2812C8.39929 12.3993 8.54859 12.4583 8.72915 12.4583C8.9097 12.4583 9.05901 12.3993 9.17706 12.2812C9.29512 12.1632 9.35415 12.0139 9.35415 11.8333V4.77083C9.35415 4.59028 9.29512 4.44097 9.17706 4.32292C9.05901 4.20486 8.9097 4.14583 8.72915 4.14583C8.54859 4.14583 8.39929 4.20486 8.28123 4.32292C8.16317 4.44097 8.10415 4.59028 8.10415 4.77083V11.8333ZM2.43748 2.375V14.25V2.375Z" />
    </SvgIcon>
  );
}

export default TrashIcon;
