import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: 10,
  },
}));

type PropTypes = {
  className?: string;
  onClick?: Function;
};

function DownChevronIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className, onClick = null } = props;
  return (
    <SvgIcon
      onClick={(e) => onClick && onClick(e)}
      className={ClassNames(classes.svg, className)}
      viewBox={"0 0 17 11"}
    >
      <path d="M8.479,10.990 L8.479,10.990 C8.155,10.990 7.846,10.859 7.615,10.621 L0.347,3.127 C-0.128,2.636 -0.128,1.839 0.349,1.348 C0.825,0.857 1.598,0.857 2.074,1.348 L8.479,7.955 L14.887,1.348 C15.364,0.857 16.134,0.857 16.611,1.348 C17.087,1.839 17.087,2.636 16.611,3.127 L9.340,10.621 C9.112,10.859 8.802,10.990 8.479,10.990 Z" />
    </SvgIcon>
  );
}

export default DownChevronIcon;
