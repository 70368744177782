import React, { ReactElement } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import MTableToolbarSearchInput from "./MTableToolbarSearchInput";
import MTableToolbarExportToCsvButton from "./Actions/MTableToolbarExportToCsvButton";
import MTableToolbarSelectedRowsActions from "./MTableToolbarSelectedRowsActions";
import MTableCMSCreateButton from "./Actions/MTableCMSCreateButton";
import CfProgressBar from "../../CfProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: "0 -10px",
    marginTop: "-8px",
    padding: "0 30px",
    paddingBottom: "12px",
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginLeft: "auto",
    alignItems: "center",
  },
}));

type PropTypes = {
  children?: string | React.ReactNode;
  csvURL?: string;
  className?: string;
  actions?: ReactElement[];
  leftActions?: ReactElement[];
  searchText?: string;
  handleSearchTextChanged?: (text: string) => void;
  selectedRows?: number;
  onClearSelectedRows?: () => void;
  selectedRowActions?: { onClick: Function; color?: string; text: string; tooltip?: string }[] | React.ReactNode[];
  searchPlaceHolder?: string;
  cmsCreateButton?: boolean;
  actionsLoading?: boolean;
};

const MTableToolbar = (props: PropTypes) => {
  const classes = useStyles();
  const {
    children,
    csvURL = null,
    searchText = null,
    handleSearchTextChanged = null,
    actions = [],
    selectedRows = -1,
    selectedRowActions = [],
    leftActions = [],
    onClearSelectedRows,
    searchPlaceHolder,
    className,
    cmsCreateButton = false,
    actionsLoading,
  } = props;

  return (
    <div className={classNames(classes.headerWrapper, className)}>
      {leftActions.map((action, i) => React.cloneElement(action, { key: `toolbar_left_action_${i + 1}` }))}
      {selectedRows > 0 &&
        (actionsLoading ? (
          <CfProgressBar size={24} />
        ) : (
          <MTableToolbarSelectedRowsActions
            selectedRowActions={selectedRowActions}
            selectedRows={selectedRows}
            onClearSelectedRows={onClearSelectedRows}
          />
        ))}
      {selectedRows < 1 && (
        <>
          {handleSearchTextChanged && (
            <MTableToolbarSearchInput
              placeHolder={searchPlaceHolder}
              searchText={searchText}
              handleSearchTextChanged={handleSearchTextChanged}
            />
          )}
          {children}
        </>
      )}
      {(cmsCreateButton || csvURL || actions?.length > 0) && (
        <div className={classes.actionButtons}>
          {actions.map((action, i) => React.cloneElement(action, { key: `toolbar_action_${i + 1}` }))}
          {csvURL && <MTableToolbarExportToCsvButton url={csvURL} />}
          {cmsCreateButton && <MTableCMSCreateButton divider={csvURL != null || actions?.length > 0} />}
        </div>
      )}
    </div>
  );
};

export default MTableToolbar;
