import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#808080",
  },
}));

type PropTypes = {
  className?: string;
};

const InfoIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} width="14" height="14" viewBox="0 0 14 14">
      <path d="M6.71116 10.25C6.85662 10.25 6.97848 10.2008 7.07672 10.1025C7.17496 10.0041 7.22408 9.88224 7.22408 9.73685V6.82893C7.22408 6.68355 7.17488 6.56168 7.07649 6.46332C6.97809 6.36497 6.85617 6.31579 6.71071 6.31579C6.56525 6.31579 6.4434 6.36497 6.34516 6.46332C6.24692 6.56168 6.19779 6.68355 6.19779 6.82893V9.73685C6.19779 9.88224 6.24699 10.0041 6.34538 10.1025C6.44378 10.2008 6.56571 10.25 6.71116 10.25ZM6.71094 5.14474C6.86752 5.14474 6.99877 5.09178 7.10468 4.98587C7.2106 4.87995 7.26356 4.7487 7.26356 4.59212C7.26356 4.43555 7.2106 4.3043 7.10468 4.19837C6.99877 4.09246 6.86752 4.0395 6.71094 4.0395C6.55436 4.0395 6.42311 4.09246 6.31719 4.19837C6.21127 4.3043 6.15832 4.43555 6.15832 4.59212C6.15832 4.7487 6.21127 4.87995 6.31719 4.98587C6.42311 5.09178 6.55436 5.14474 6.71094 5.14474ZM6.71208 13.5C5.81307 13.5 4.96805 13.3294 4.17701 12.9882C3.38596 12.647 2.69786 12.184 2.11271 11.5991C1.52754 11.0142 1.06429 10.3264 0.722951 9.53569C0.381609 8.745 0.210938 7.90016 0.210938 7.00115C0.210938 6.10214 0.381535 5.25711 0.722728 4.46607C1.06392 3.67502 1.52696 2.98692 2.11185 2.40177C2.69675 1.81661 3.38455 1.35336 4.17525 1.01201C4.96593 0.670672 5.81078 0.5 6.70979 0.5C7.6088 0.5 8.45383 0.670598 9.24487 1.01179C10.0359 1.35299 10.724 1.81603 11.3092 2.40091C11.8943 2.98581 12.3576 3.67361 12.6989 4.46431C13.0403 5.255 13.2109 6.09984 13.2109 6.99885C13.2109 7.89786 13.0403 8.74289 12.6991 9.53393C12.358 10.325 11.8949 11.0131 11.31 11.5982C10.7251 12.1834 10.0373 12.6466 9.24663 12.988C8.45594 13.3293 7.61109 13.5 6.71208 13.5ZM6.71094 12.4737C8.23901 12.4737 9.53331 11.9434 10.5938 10.8829C11.6544 9.82238 12.1846 8.52807 12.1846 7C12.1846 5.47193 11.6544 4.17762 10.5938 3.1171C9.53331 2.05657 8.23901 1.5263 6.71094 1.5263C5.18286 1.5263 3.88856 2.05657 2.82803 3.1171C1.7675 4.17762 1.23724 5.47193 1.23724 7C1.23724 8.52807 1.7675 9.82238 2.82803 10.8829C3.88856 11.9434 5.18286 12.4737 6.71094 12.4737Z" />
    </SvgIcon>
  );
};

export default InfoIcon;
