import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { IAnalyticsEvent } from "../../../../reducers/constants/objectTypes";
import { Collapse } from "@material-ui/core";
import { isObject } from "lodash";
import { DATE_FORMAT } from "../../../../reducers/constants/consts";
import moment from "moment";
import localeHelper from "../../../../helpers/localeHelper";
import { web } from "../../../../helpers/urlHelper";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) => ({
  collapseContainer: {
    width: "100%",
  },
  collapseWrapper: {
    minHeight: 100,
  },
  itemsWrapper: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "repeat(3, 33%)",
  },
  item_col_1: {
    gridColumn: 1,
  },
  item_col_2: {
    gridColumn: 2,
  },
  item_col_3: {
    gridColumn: 3,
  },
  item: {
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: "0px 15px",
    padding: 10,
  },
  itemKey: {
    fontSize: 12,
    whiteSpace: "nowrap",
  },
  eventField: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  itemValue: {
    fontSize: 12,
    marginLeft: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

const keyValue = {
  api_timestamp: {
    name: "created_at",
    get: (val: string) => moment(val).format(DATE_FORMAT.DATETIME),
  },
  browser: {
    name: "browser",
  },
  browser_version: {
    name: "browser_version",
  },
  browser_language: {
    name: "browser_language",
  },
  city: {
    name: "city",
  },
  country: {
    name: "country",
    get: (val: string) => localeHelper.getCountryFromCountryCode(val) || "-",
  },
  current_domain: {
    name: "domain",
  },
  current_url: {
    name: "url",
    get: (val: string) => (
      <Link href={val} onClick={(e) => e.stopPropagation()} target={"_blank"}>
        {val}
      </Link>
    ),
  },
  distinct_id: {
    name: "device_id",
    get: (val: string) => (
      <Link target={"_blank"} href={web.croUserEvents(val)} onClick={(e) => e.stopPropagation()}>
        {val}
      </Link>
    ),
  },
  // device_id: "device_id",
  referrer: {
    name: "referrer",
  },
  referring_domain: {
    name: "referrer_source",
  },
  initial_referrer: {
    name: "initial_referrer",
  },
  initial_referring_domain: {
    name: "initial_referrer_source",
  },
  operating_system: {
    name: "operating_system",
  },
  operating_system_version: {
    name: "operating_system_version",
  },
  page_title: {
    name: "page_title",
  },
  screen_height: {
    name: "screen_height",
  },
  screen_width: {
    name: "screen_width",
  },
  time: {
    name: "created_at",
    get: (val: string) => moment(val).format(DATE_FORMAT.DATETIME),
  },
  // time_processed_utc: {
  //   name: "updated_at",
  // },
  // url_protocol: {
  //   name: "url_protocol",
  // },
};

type PropTypes = {
  event: IAnalyticsEvent;
  collapsed: boolean;
};

const EventCollapseItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { event, collapsed } = props;

  const eventFields = React.useMemo(() => {
    const list = [];
    if (event.event_data) {
      Object.keys(event.event_data).forEach((key) => {
        list.push({
          label: I18n.t(`analytics.events.table.${key}`),
          value: isObject(event.event_data[key]) ? JSON.stringify(event.event_data[key]) : event.event_data[key],
          eventField: true,
        });
      });
    }

    const fields = Object.keys(keyValue).map((key) => ({
      label: I18n.t(`analytics.events.table.${key}`),
      value: keyValue[key]?.get ? keyValue[key].get(event[keyValue[key].name]) : event[keyValue[key].name],
      eventField: false,
    }));

    list.push(...fields);

    return list;
  }, [event]);

  return (
    <Collapse
      in={!collapsed}
      classes={{
        root: classes.collapseContainer,
        wrapper: classes.collapseWrapper,
      }}
    >
      <div className={classes.itemsWrapper}>
        {eventFields.map((eventField, index) => (
          <div
            className={ClassNames(
              classes.item,
              index % 1 === 0 && classes.item_col_1,
              index % 2 === 0 && classes.item_col_2,
              index % 3 === 0 && classes.item_col_3
            )}
            key={eventField.label}
          >
            <Typography
              className={ClassNames(classes.itemKey, eventField.eventField && classes.eventField)}
              variant="body1"
            >
              {eventField.label}
            </Typography>
            <Typography className={classes.itemValue} variant="body2">
              {eventField.value}
            </Typography>
          </div>
        ))}
      </div>
    </Collapse>
  );
};

export default EventCollapseItem;
