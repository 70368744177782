import React, { useMemo, useState } from "react";
import { I18n } from "react-redux-i18n";
import { ResponsiveContainer } from "recharts";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import numeral from "numeral";
import Tooltip from "@material-ui/core/Tooltip";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import ReportCard from "./ReportCard";
import useReport from "./useReport";
import {
  ACTION_REPORTS_OPTIONS,
  ANALYTICS_DASHBOARD_METRICS_OPTIONS,
  CONVERSION_POTENTIAL_FUNNEL_STAGE,
  REPORT_CACHE_KEY_NAME,
} from "../../../../helpers/constants";
import AnalyticsTable, { AnalyticsTableCellLink } from "./charts/AnalyticsTable";
import { TopQueriesInterface } from "../types";
import MSelect from "../../../../components/MSelect";
import TopQueryPageTopicChart from "./charts/TopQueryPageTopicChart";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: 150,
    margin: 0,
  },
  chartContainer: {
    overflowY: "auto",
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.pxToRem(14),
  },
  pageRowStyle: {
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    cursor: "pointer",
  },
  secondaryHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  startDate: Date;
};

type ReportSecondaryHeaderProps = {
  reportType: any;
  setReportType: (reportType: string) => void;
  options: any[];

  selectedMetric: any;
  setSelectedMetric: (metric: string) => void;
};

const ReportSecondaryHeader = (props: ReportSecondaryHeaderProps) => {
  const classes = useStyles();

  const theme = useTheme();
  const { reportType, setReportType, options, setSelectedMetric, selectedMetric } = props;

  return (
    <div className={classes.secondaryHeaderWrapper}>
      <MSelect
        options={options}
        value={options.find((option) => option.value === reportType)}
        optionValue={"value"}
        textColor={theme.palette.primary.main}
        selectedTextColor={theme.palette.primary.main}
        optionBackgroundColorHover={"#E6EEFF"}
        optionLabel={"label"}
        valuePadding={"2px 0px 2px 0px"}
        borderColor={"transparent"}
        borderRadius={0}
        changeColorOnMenuOpen={true}
        closedTextColor={theme.palette.text.primary}
        borderWidth={0}
        height={32}
        isBold={false}
        placeholder={I18n.t("reports.dashboard.select_ph")}
        handleChange={(option) => {
          if (option) {
            setReportType(option.value);
          }
        }}
      />
      <MSelect
        options={ANALYTICS_DASHBOARD_METRICS_OPTIONS}
        optionBackgroundColorHover={"#E6EEFF"}
        value={ANALYTICS_DASHBOARD_METRICS_OPTIONS.find((option) => option.value === selectedMetric)}
        optionValue={"value"}
        optionLabel={"label"}
        textColor={theme.palette.primary.main}
        selectedTextColor={theme.palette.primary.main}
        valuePadding={"2px 0px 2px 0px"}
        borderColor={"transparent"}
        changeColorOnMenuOpen={true}
        closedTextColor={theme.palette.text.primary}
        borderRadius={0}
        borderWidth={0}
        height={32}
        isBold={false}
        placeholder={I18n.t("reports.dashboard.select_ph")}
        handleChange={(option) => {
          if (option) {
            setSelectedMetric(option.value);
          }
        }}
      />
    </div>
  );
};

const TopQueryPageTopicEntailReport = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, startDate } = props;

  const REPORT_TYPES: { value: string; label: string }[] = useMemo(() => {
    return [
      {
        value: "top-queries",
        label: I18n.t("reports.dashboard.top_queries"),
      },
      {
        value: "top-topics",
        label: I18n.t("reports.dashboard.top_topics"),
      },
      {
        value: "top-pages",
        label: I18n.t("reports.dashboard.top_pages"),
      },
    ];
  }, []);

  const [reportType, setReportType] = useState<any>(REPORT_TYPES[0].value);
  const [selectedMetrics, setSelectedMetrics] = useState<any>(ANALYTICS_DASHBOARD_METRICS_OPTIONS[0].value);
  const extraParams = React.useMemo(
    () => ({ pageFilter: "true", metric: selectedMetrics }),
    [selectedWebsite, selectedMetrics]
  );

  const {
    loading,
    triggerRefresh,
    monthFormat,
    numberFormat,
    fullDateFormat,
    titlesFormat,
    setTriggerRefresh,
    fetchResponse,
  } = useReport({
    selectedWebsite,
    startDate,
    reportType,
    extraParams,
  });

  const columns = useMemo(() => {
    switch (reportType) {
      case "top-queries":
        return [
          {
            accessorKey: "keyword",
            header: I18n.t("reports.column_names.keyword"),
            enableSorting: true,
            cell: (cellProps) => (
              <Tooltip title={cellProps.cell.getValue()} placement={"bottom-start"}>
                <span>{cellProps.cell.getValue()}</span>
              </Tooltip>
            ),
          },
          {
            accessorKey: "total_impressions",
            header: I18n.t("reports.column_names.impressions"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0,0a")}</>,
          },
          {
            accessorKey: "total_clicks",
            header: I18n.t("reports.column_names.clicks"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0,0a")}</>,
          },
          {
            accessorKey: "ctr",
            header: I18n.t("reports.column_names.ctr"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0.0%")}</>,
          },
          {
            accessorKey: "position",
            header: I18n.t("reports.column_names.position"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0.0")}</>,
          },
        ];
      case "top-topics":
        return [
          {
            accessorKey: "keyword",
            header: I18n.t("reports.column_names.topic"),
            enableSorting: true,
            cell: (cellProps) => (
              <Tooltip title={cellProps.cell.getValue()} placement={"bottom-start"}>
                <span>{cellProps.cell.getValue()}</span>
              </Tooltip>
            ),
          },
          {
            accessorKey: "impressions",
            header: I18n.t("reports.column_names.impressions"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0,0a")}</>,
          },
          {
            accessorKey: "clicks",
            header: I18n.t("reports.column_names.clicks"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0,0a")}</>,
          },
          {
            accessorKey: "ctr",
            header: I18n.t("reports.column_names.ctr"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0.0%")}</>,
          },
          {
            accessorKey: "position",
            header: I18n.t("reports.column_names.position"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0.0")}</>,
          },
          {
            accessorKey: "conversion_potential",
            header: I18n.t("reports.column_names.funnel_stage"),
            cell: (cellProps) => <>{CONVERSION_POTENTIAL_FUNNEL_STAGE[cellProps.cell.getValue() as string]}</>,
          },
        ];
      case "top-pages":
        return [
          {
            accessorKey: "page",
            header: I18n.t("reports.column_names.page"),
            enableSorting: true,
            cell: (cellProps) => (
              <AnalyticsTableCellLink selectedWebsite={selectedWebsite} url={cellProps.cell.getValue()} />
            ),
          },
          {
            accessorKey: "total_impressions",
            header: I18n.t("reports.column_names.impressions"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0,0a")}</>,
          },
          {
            accessorKey: "total_clicks",
            header: I18n.t("reports.column_names.clicks"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0,0a")}</>,
          },
          {
            accessorKey: "ctr",
            header: I18n.t("reports.column_names.ctr"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0.0%")}</>,
          },
          {
            accessorKey: "position",
            header: I18n.t("reports.column_names.position"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0.0")}</>,
          },
        ];
      default:
        return [];
    }
  }, [reportType, selectedWebsite]);

  const LIMIT_VALUES = useMemo(
    () => [
      {
        name: "5 rows",
        value: 5,
      },
      {
        name: "10 rows",
        value: 10,
      },
      {
        name: "20 rows",
        value: 20,
      },
      {
        name: "50 rows",
        value: 50,
      },
      {
        name: "100 rows",
        value: 100,
      },
    ],
    []
  );

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });

  const { data, cacheData } = React.useMemo(() => {
    if (fetchResponse) {
      return {
        data: fetchResponse.results,
        cacheData: {
          cache: fetchResponse.cache,
          lastFetch: fetchResponse.fetchedTime,
        },
      };
    }
    return {
      data: [],
    };
  }, [fetchResponse]);

  return (
    <ReportCard
      subTitle={I18n.t("reports.dashboard.entail_pages")}
      cacheData={cacheData}
      setRefreshCache={() => {
        sessionStorage.setItem(REPORT_CACHE_KEY_NAME, "true");
        setTriggerRefresh(!triggerRefresh);
      }}
      containsData={data.length > 0}
      loading={loading}
      totalPageCount={Math.ceil(data.length / pagination.pageSize)}
      pagination={pagination}
      limitOptions={LIMIT_VALUES}
      secondaryHeader={
        <ReportSecondaryHeader
          reportType={reportType}
          options={REPORT_TYPES}
          selectedMetric={selectedMetrics}
          setSelectedMetric={setSelectedMetrics}
          setReportType={setReportType}
        />
      }
      reportTypeName={reportType}
      setPagination={setPagination}
    >
      {selectedMetrics === "all" ? (
        <ResponsiveContainer width={"98%"} height={400} className={classes.chartContainer}>
          <AnalyticsTable<any>
            selectedWebsite={selectedWebsite}
            pagination={pagination}
            columns={columns}
            data={data}
          />
        </ResponsiveContainer>
      ) : (
        <TopQueryPageTopicChart
          data={data}
          timeFormat={monthFormat}
          metric={selectedMetrics}
          numberFormat={selectedMetrics === "ctr" ? (tickItem) => numeral(tickItem).format("0,0[.]0%") : numberFormat}
          fullDateFormat={fullDateFormat}
          titlesFormat={titlesFormat}
        />
      )}
    </ReportCard>
  );
};

export default TopQueryPageTopicEntailReport;
