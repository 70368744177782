import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.palette.background.default,
    margin: "25px 20px 0 20px",
    padding: "20px 10px 0",
  },
}));

type PropTypes = { children: string | React.ReactNode; className?: string };

const MTableWrapper = (props: PropTypes) => {
  const classes = useStyles();

  const { children, className } = props;

  return <div className={Classnames(classes.tableWrapper, className)}>{children}</div>;
};

export default MTableWrapper;
