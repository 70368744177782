import { TimelineAction, TimelineRow } from "@xzdarcy/react-timeline-editor";

export enum ePageState {
  writer = "Writer",
  edit = "Edit",
  secondEdit = "SecondEdit",
  review = "Review",
  factCheck = "FactCheck",
  deleted = "Deleted", // final state
  published = "Published", // final state
  archived = "Archived", // final state
}

export enum eActionIcon {
  search = "search",
  openInNew = "openInNew",
  copyToClipboard = "copyToClipboard",
  copyName = "copyName",
  analytics = "analytics",
  trashCan = "trashCan",
  openInEditor = "openInEditor",
  photo = "Photo",
  note = "note",
  info = "info",
}

export enum eModeratorAction {
  APPROVE = "APPROVE",
  DECLINE = "DECLINE",
  SAVE = "SAVE",
}

export enum eBlogTemplate {
  default = "default",
  mayple = "mayple",
  safebooks = "safebooks",
  jit = "jit",
}

export enum eTOCType {
  default = "default",
  sideTOC = "sideTOC",
  custom = "custom",
}
export enum ePageAction {
  create = "Create",
  returnToWriter = "ReturnToWriter",
  submitForEdit = "SubmitForEdit",
  submitForSecondEdit = "SubmitForSecondEdit",
  returnToEdit = "ReturnToEdit",
  returnToSecondEdit = "ReturnToSecondEdit",
  submitForFactCheck = "SubmitForFactCheck",

  approveFactCheck = "ApproveFactCheck",
  submitForReview = "SubmitForReview",
  returnToReview = "ReturnToReview",
  approveRegulation = "ApproveRegulation",
  declineRegulation = "DeclineRegulation",
  returnToFactCheck = "ReturnToFactCheck",
  deleteForever = "DeleteForever",
  delete = "Delete",
  publish = "Publish",
  unpublish = "Unpublish",
  takeOver = "TakeOver",
  update = "Update",
  save = "Save",
  autoSave = "AutoSave",

  exportPage = "ExportPage",
}

export enum eSectionAccess {
  full = "Full",
  api_only = "API only",
  web_only = "Web only",
  none = "None",
}

export interface IWebsiteConfigurationsProps {
  siteDetails: IWebsiteDetailsProps;
  locale: IWebsiteLocale;
  analytics: IWebsiteAnalyticsProps;
  sections: IWebsiteSectionProps;
  theme: IWebsiteThemeProps;
  article: IWebsiteArticleProps;
  category: IWebsiteCategoryProps;
  topic: IWebsiteTopicProps;
  blog: IWebsiteBlogProps;
  forum: IWebsiteForumProps;
  homepage: IWebsiteHomepageProps;
  resourcePage: IWebsiteResourcePageProps;
  learn: IWebsiteLearnProps;
  comparisonTopAlternative: IWebsiteComparisonTopAlternativeProps;
  comparisonTop10: IWebsiteComparisonTop10Props;
  productComparison: IWebsiteProductComparisonProps;
  productPage: IWebsiteProductPageProps;
  productReview: IWebsiteProductReviewProps;
  productAlternative: IWebsiteProductAlternativeProps;
  glossary: IWebsiteGlossaryProps;
  customCode: IWebsiteCustomCodeProps;
  commonComponents: IWebsiteCommonComponents;

  ppcTop10: IWebsitePPCTop10Props;
  dynamicSections: IWebsiteDynamicSectionProps[];
  staticPages: IWebsiteStaticPageProps[];
}

export interface IWebsiteDynamicSectionProps {
  _id: string;
  name: string;
  access: eSectionAccess;
  template: string;
  blogTemplate: string;
  type: string;
  enabled: boolean;
  sitemap: boolean;
  urlPrefix: string;
  mainPage: {
    slug: string;
    metaTitle: string;
    enabled: boolean;
    customTopSection: boolean;
    metaDescription: string;
    title: {
      text: string;
      css: any;
    };
    description: {
      text: string;
      css: any;
    };
  };
  categoryPage: {
    slug: string;
    enabled: boolean;
    metaTitle: string;
    metaDescription: string;
  };
  postPage: {
    metaTitle: string;
    slug: string;
    metaDescription: string;
    title: {
      css: object;
    };
    description: {
      css: object;
    };
  };
  maypleTemplate: {
    mainPage: {
      metaTitle: string;
      enabled: boolean;
      metaDescription: string;
    };
    featuredPost: {
      slug: string;
      title: string;
    };
    topArticles: {
      title: {
        text: string;
        css: any;
      };
      posts: {
        slug: string;
        title: string;
      }[];
    };
    categories: { name: string; slug: string }[];
  };
  safebooksTemplate: {
    mainPage: {
      metaTitle: string;
      enabled: boolean;
      metaDescription: string;
    };
    bottomWidgetId: string;
  };
  jitTemplate: {
    mainPage: {
      title: {
        text: string;
        css: any;
      };
      description: {
        text: string;
        css: any;
      };
      metaTitle: string;
      metaDescription: string;
      button: {
        text: string;
        url: string;
        css: object;
      };
      backgroundImage: {
        _id: string;
        url: string;
        alt: string;
        title: string;
        caption: string;
      };
      mainImage: {
        _id: string;
        url: string;
        alt: string;
        title: string;
        caption: string;
      };
      articles: {
        title: string;
        url: string;
      }[];
    };
  };
  translations: Map<
    string,
    {
      name: string;
      urlPrefix: string;
      mainPage: {
        slug: string;
        title: string;
        description: string;
        metaTitle: string;
        metaDescription: string;
      };
      categoryPage: {
        slug: string;
        metaTitle: string;
        metaDescription: string;
      };
      postPage: {
        metaTitle: string;
        metaDescription: string;
      };
      maypleTemplate: {
        mainPage: {
          metaTitle: string;
          metaDescription: string;
        };
        safebooksTemplate: {
          mainPage: {
            metaTitle: string;
            metaDescription: string;
          };
          bottomWidgetId: string;
        };
        jitTemplate: {
          mainPage: {
            title: string;
            description: string;
            metaTitle: string;
            metaDescription: string;
            button: {
              text: string;
              url: string;
            };
            articles: {
              title: string;
              url: string;
            }[];
          };
        };
        topArticles: {
          title: string;
        };
      };
    }
  >;
}

export interface IWebsiteStaticPageProps {
  name: string;
  url: string;
  sitemap: boolean;
  urlPrefix: string;
  path: string;
}

export interface IWebsite {
  _id: string;
  children: IWebsite[];
  name: string;
  url: string;
  s3BucketFolder: string;
  publisherName: string;
  host: string;
  plan: string;
  startDate: Date;
  active: boolean;
  uploadContent: boolean;
  secondEditStep: boolean;

  // customerReview: boolean;
  // secondCustomerReview: boolean;
  // thirdCustomerReview: boolean;
  // fourthCustomerReview: boolean;

  role: string;
  type: string;
  targetAudience: string;
  targetAudienceGoal: string;

  reviewProcess: "None" | "Fact-Check" | "Custom";
  reviews: IWebsiteReviewStep[];
  questionsCount: number;
  blogPostsCount: number;
  targetedPostsCount: number;
  questions: string[];
  posts: [
    {
      editedPost: string;
      url: string;
    }
  ];
  configurations: IWebsiteConfigurationsProps;
  gsc: IWebsiteGSC;
  postsGoalByLocale: Map<
    string,
    {
      answers: number;
      questions: number;
      blog: number;
      targeted: number;
      learningCenterPosts: number;
      learningCenterTopics: number;
      top10: number;
      topAlternatives: number;
      productReviews: number;
      productAlternatives: number;
      productComparisons: number;
    }
  >;
  // communityUrlPrefix: string
  // blogUrlPrefix: string
  // targetedUrlPrefix: string
  learningCenterUrlPrefix: string;
  // topicsUrlPrefix: string
  // categoriesUrlPrefix: string
  // comparisonTop10UrlPrefix: string
  // comparisonTopAlternativesUrlPrefix: string
  // productPageUrlPrefix: string
  // productReviewUrlPrefix: string
  // productAlternativesUrlPrefix: string
  pagesUrlPrefix: string;

  defaultLocale: string;
  locales: string[];
}

export interface IOrganization {
  _id: string;
  active: boolean;
  name: string;
  websites: { website: IWebsite; role: string; status: string }[];
  collaborators: {
    user: IAdminUser;
    role: string;
    status: string;
  }[];
}

export enum SNWebsiteAutoCompleteMetaTag {
  year = "Year",
  postTitle = "Post Title",
  postText = "Post Text",
  postCategoryName = "Post Category Name",
  postMetaDescription = "Post Meta Description",

  questionText = "Question Text",
  answerText = "Answer Text",

  categoryName = "Category Name",
  categoryDescription = "Category Description",

  topicName = "Topic Name",
  topicTitle = "Topic Title",
  topicMetaDescription = "Topic Meta Description",

  searchQuery = "Search Query",

  postID = "Post ID",
  productID = "Product ID",
  slug = "Slug",
  postType = "Post Type",
}

export interface ISearchQueryCategory {
  active: boolean;
  name: string;
  description: string;
  searchedBy: string;
  intent: string;
  secondaryIntent: string;
  queries: string[];
}

export interface IWriterBio {
  _id: string;
  alternativeId: string;

  user: IAdminUser;
  website: IWebsite;
  categories: ICategory[];

  active: boolean;

  general: {
    active: boolean;
    profileImage: IFile;
    name: string;
    firstName: string;
    lastName: string;
    displayName: string;
    role: string;
    title: string;
    showTitleInArticle: boolean;
    expertiseList: string[];
    educationList: string[];
    socials: {
      facebook: string;
      twitter: string;
      youTube: string;
      linkedin: string;
    };
  };

  preview: {
    active: boolean;
    text: string;
  };

  highlights: {
    active: boolean;
    option1: string;
    option2: string;
    option3: string;
    option4: string;
  };

  expertise: {
    active: boolean;
    title: string;
    text: string;
  };

  quote: {
    active: boolean;
    name: string;
    text: string;
  };

  publishHistory: {
    action: "CREATE" | "UPDATE" | "DELETE";
    date: Date;
    user: IAdminUser;
    publishData: object;
    responseData: object;
    publishStatus: "OK" | "ERROR" | "PENDING" | null;

    customerStatusDate: object;
    customerStatusData: object;
    errorReason: string;
  }[];

  metaDescription: string;

  uploadedUrl: string;
  uploadedId: string;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  updatedAt: Date;
}

export interface IAdminUser {
  _id: string;
  name: string;
  email: string;
  password?: string;
  lastActiveDate: Date;
  username: string;
  photoUrl: string;
  locales: string[];
  bios: IWriterBio[];
  // role: 'owner' | 'entail' | 'admin' | 'content_manager' |'editor' | 'writer' | 'limited_writer' | 'credited_writer' | 'customer'
  role: eUserRoles;
  websites: IWebsite[];
  websiteRoles: { website: IWebsite; role: string }[];

  initialPassword?: boolean;
  postTypes: {
    community: boolean;
    answers: boolean;
    blog: boolean;
    learningCenter: boolean;
    topic: boolean;
    comparison: boolean;
    productPage: boolean;
    productReview: boolean;
    productAlternative: boolean;
    productComparison: boolean;
    customPost: boolean;
  };
  pageTypes: string[];
  ppwToType: {
    Community: number;
    CommunityAnswer: number;
    Blog: number;
    Targeted: number;
    Topic: number;
    ComparisonTop10: number;
    ComparisonTopSEO: number;
    ComparisonTopAlternatives: number;
    ProductReview: number;
    ProductPage: number;
    ProductAlternative: number;
    ProductComparison: number;
  };
  pptToType: {
    Community: number;
    CommunityAnswer: number;
    Blog: number;
    Targeted: number;
    Topic: number;
    ComparisonTop10: number;
    ComparisonTopSEO: number;
    ComparisonTopAlternatives: number;
    ProductReview: number;
    ProductPage: number;
    ProductAlternative: number;
    ProductComparison: number;
  };
}

export interface IUser {
  _id: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  gender: "male" | "female" | "none";

  questions: number;
  answers: number;

  photoUrl: string;
  phoneNumber: string;

  socialNetwork?: {
    provider?: "google" | "facebook" | "github" | "linkedin";
  } & any;
  admin: boolean;
}

export interface ITopic {
  _id: string;
  name: string;
  title: string;
  description: string;
  slug: string;
  postsCount?: number;
  category?: ICategory;
  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;
  website: IWebsite;
}

export interface ITag {
  _id: string;
  name: string;
  slug: string;
  phrases: string[];
  metaDescription: string;
  title: string;
  text: string;
  richText: string;
  website: IWebsite;
  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;

  totalQuestions?: number;
  totalBlogPosts?: number;
  totalTargetedPosts?: number;
  totalLearnPost?: number;
  totalPosts?: number;

  state: ePostState;

  uploaded: boolean;
  uploadedTime: Date;
  uploadedUrl: string;
  uploadedId: string;

  editor: IAdminUser;
  writer: IAdminUser;
  creditedWriter: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  translations: `Map<string, {
    name: string
    metaDescription: string
    title: string
    text: string
    richText: string
  }>`;
}

export interface ICategory {
  id: any;
  _id: string;
  name: string;
  slug: string;

  title: string;
  description: string;

  titleCommunity: string;
  descriptionCommunity: string;

  titleBlog: string;
  descriptionBlog: string;

  titleTargeted: string;
  descriptionTargeted: string;

  questionsCount?: number;
  pagesCount?: number;

  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;
  googleSearchConsoleStatisticsCommunity: IGoogleSearchConsoleStatistics;
  googleSearchConsoleStatisticsBlog: IGoogleSearchConsoleStatistics;
  googleSearchConsoleStatisticsTargeted: IGoogleSearchConsoleStatistics;

  translations: Map<
    string,
    {
      name: string;
      slug: string;

      title: string;
      description: string;

      titleCommunity: string;
      descriptionCommunity: string;

      titleBlog: string;
      descriptionBlog: string;

      titleTargeted: string;
      descriptionTargeted: string;
    }
  >;

  website: IWebsite;

  updatedAt: Date;
  createdAt: Date;
}

export enum eEditedPostTextType {
  QUESTION = "question",
  ANSWER = "answer",
  DELETED = "deleted",
  MERGED = "merged",
}

export enum eEditedPostSuitableFor {
  TITLE_OR_QUESTION = "title/question",
  TARGETED = "targeted",
  LEARN = "learn",
  INFOGRAPHIC = "infographic",
  E_BOOK = "e_book",
  COURSE = "course",
  BLOG = "blog",
  GUIDE = "guide",
  VIDEO = "video",
  NEWS = "news",
  BIZ = "biz",
}

export interface IEditedFbPostReply {
  fbId: string;
  text: string;
  richText: string;
  originalText: string;
  user: IUser;
  expertUser: boolean;
  postedTime: Date;
  originalPostedTime: Date;
  type: eEditedPostTextType;
  mergedWith: string;
}

export interface IEditedFbPostComment {
  fbId: string;
  text: string;
  richText: string;
  originalText: string;
  user: IUser;
  expertUser: boolean;
  postedTime: Date;
  originalPostedTime: Date;
  type: eEditedPostTextType;
  mergedWith: string;
  replies: IEditedFbPostReply[];
}

export interface IContactUserTopic {
  _id: string;
  name: string;
}

export interface IEditedFbPost {
  _id: string;
  facebookPost: string;
  fbId: string;
  user: IUser;
  expertUser: boolean;
  locale: string;
  title: string;
  text: string;
  richText: string;
  originalText: string;
  topic: ITopic;
  category: ICategory;
  tags: ITag[];
  scrapingKeyword: IWorkerScrapingKeyword;
  postedTime: Date;
  originalPostedTime: Date;
  type: eEditedPostTextType;
  suitableFor: eEditedPostSuitableFor[];
  sourceURLs: string[];
  contactUserAbout: IContactUserTopic[];
  uploadTo: IWebsite;
  draft: boolean;
  archived: boolean;
  mergedWith: string;

  question?: IQuestion;

  communityPosts?: IQuestion[];
  learningCenterPosts?: ILearnPost[];

  comments: IEditedFbPostComment[];

  createdBy: IAdminUser;
  createdDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IAnswer {
  _id: string;
  originalText: string;
  text: string;
  richText: string;
  accepted: boolean;
  publish: boolean;
  published: boolean;
  slug: string;
  user: IUser;
  expert: IIndustryExpert;
  postedTime: Date;

  moderatorState: "NEW" | "SKIP" | "APPROVED" | "DECLINED";

  writer: IAdminUser;
  creditedWriter: IAdminUser;
}

// 'owner' | 'entail' | 'admin' | 'content_manager' |'editor' | 'writer' | 'limited_writer' | 'credited_writer' | 'customer'
export enum eUserRoles {
  OWNER = "owner",
  ADMIN = "admin",
  CONTENT_MANAGER = "content_manager",
  HR = "hr",
  EDITOR = "editor",
  // ANALYST = "analyst",
  WRITER = "writer",
  LIMITED_WRITER = "limited_writer",
  CREDITED_WRITER = "credited_writer",
  CUSTOMER = "customer",
  CRO_CUSTOMER = "cro_customer",
}

export enum ePostType {
  BLOG = "blog",
  TARGETED = "targeted",
  LEARNING_CENTER = "learning_center",
  COMMUNITY = "community",
  COMMUNITY_ANSWER = "community_answer",
  TOPIC = "topic",
  COMPARISON = "comparison",
  PRODUCT_REVIEW = "product_review",
  PRODUCT_PAGE = "product_page",
  PRODUCT_ALTERNATIVE = "product_alternative",
  PRODUCT_COMPARISON = "product_comparison",
  CUSTOM = "custom",
}

export enum eQuestionState {
  NEW = "NEW",
  DRAFT = "DRAFT",
  DELETED = "DELETED",
  INTERNAL_REVIEW = "INTERNAL_REVIEW",
  REWRITE = "REWRITE",
  SEND_TO_CUSTOMER = "SEND_TO_CUSTOMER",
  CUSTOMER_REJECT = "CUSTOMER_REJECT",
  CUSTOMER_EDIT_REQUEST = "CUSTOMER_EDIT_REQUEST",
  CUSTOMER_DRAFT = "CUSTOMER_DRAFT",
  CUSTOMER_APPROVED = "CUSTOMER_APPROVED",
  PUBLISH = "PUBLISH",
  PUBLISHED = "PUBLISHED",
}

export enum ePostState {
  UNINITIALIZED = "UNINITIALIZED",
  NEW = "NEW",
  DRAFT = "DRAFT",
  INTERNAL_REVIEW = "INTERNAL_REVIEW",
  REJECTED = "REJECTED",
  SEND_TO_CUSTOMER = "SEND_TO_CUSTOMER",
  CUSTOMER_REJECT = "CUSTOMER_REJECT",
  CUSTOMER_EDIT_REQUEST = "CUSTOMER_EDIT_REQUEST",
  CUSTOMER_DRAFT = "CUSTOMER_DRAFT",
  CUSTOMER_APPROVED = "CUSTOMER_APPROVED",
  DELETE = "DELETE",
  DELETED = "DELETED",
  PUBLISH = "PUBLISH",
  UPDATE = "UPDATE",
  PUBLISHED = "PUBLISHED",
}

export enum eCustomerPostState {
  DRAFT = "DRAFT",
  REJECTED = "REJECTED",
  EDIT_REQUEST = "EDIT_REQUEST",
  APPROVED = "APPROVED",
}

export enum eCustomPostType {
  NI_TOP_10_ARTICLE = "ni_top_10_article",
  WORDPRESS_ARTICLE = "wordpress_article",
}

export interface IQuestion {
  _id: string;
  locale: string;
  user: IUser;
  title: string;
  slugTitle: string;
  originalText: string;
  text: string;
  richText: string;
  topic: ITopic;
  category: ICategory;
  product: IProduct;
  products: IProduct[];
  tags: ITag[];
  postedTime: Date;

  totalAnswers: number;
  answers: IAnswer[];
  sourceURLs: string[];

  moderatorState: "NEW" | "SKIP" | "APPROVED" | "DECLINED";

  state: eQuestionState;
  draft: boolean;
  deleted: boolean;

  writer: IAdminUser;
  creditedWriter: IAdminUser;
  editor: IAdminUser;

  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;
  uploadTo: IWebsite;
  uploaded: boolean;
  uploadedTime: Date;
  questionUrl: string;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  deletedBy?: IAdminUser;
  deletedDate?: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IWriterCommunityAnswer {
  _id: string;
  question: IQuestion;
  questionAnswerId: string;
  website: IWebsite;
  text: string;
  user: IUser;
  expert: IIndustryExpert;
  richText: string;
  resourceText: string;
  resourceRichText: string;
  postedTime: Date;
  state: ePostState;

  tags: ITag[];
  keywords: ISEOKeyword[];
  sourceURLs: string[];

  writer: IAdminUser;
  creditedWriter: IAdminUser;
  editor: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IContentDomain {
  _id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IContent {
  _id: string;
  URL: string;
  content: string;
  domains: IContentDomain[];

  savedBy: IAdminUser;
  savedTime: Date;

  checkedByUs: boolean;
  deleted: boolean;
  locked: boolean;

  communityPosts?: IQuestion[];
  learningCenterPosts?: ILearnPost[];

  createdAt: Date;
  updatedAt: Date;
}

export interface IBlogAuthor {
  _id: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  bio: string;
  websiteUrl: string;
  gender: "male" | "female" | "none";
  photoUrl: string;
  blogPostsCount: number;

  createdAt: Date;
  updatedAt: Date;
}

export interface IBlogPost {
  _id: string;
  translations: IBlogPostTranslation[];
  author: IBlogAuthor;
  expert: IIndustryExpert;
  title: string;
  changingTitle: string;
  richTitle: string;
  originalText: string;
  text: string;
  richText: string;
  metaDescription: string;
  product: IProduct;
  category: ICategory;
  topic: ITopic;
  postedTime: Date;
  keywords: ISEOKeyword[];
  tags: ITag[];
  outline: IOutline;
  state: ePostState;
  customerState: eCustomerPostState;
  featured: boolean;

  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;

  resourceText: string;
  resourceTitle: string;
  resourceRichText: string;

  sourceURLs: string[];

  previewImage: IFile;

  uploadTo: IWebsite;
  uploaded: boolean;
  uploadedPostUrl: string;
  uploadedTime: Date;

  editor: IAdminUser;
  clientEditor: IAdminUser;
  writer: IAdminUser;
  creditedWriter: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IBlogPostTranslation {
  _id: string;
  post: IBlogPost;
  locale: "en" | "es" | "de";
  website: IWebsite;

  changingTitle: string;
  title: string;
  richTitle: string;
  originalText: string;
  text: string;
  richText: string;
  metaDescription: string;
  postedTime: Date;

  featured: boolean;

  author: IBlogAuthor;
  expert: IIndustryExpert;

  resourceText: string;
  resourceTitle: string;
  resourceRichText: string;

  previewImage: IFile;

  state: ePostState;

  uploaded: boolean;
  uploadedPostUrl: string;
  uploadedTime: Date;
  uploadedPostId: string;

  editor: IAdminUser;
  writer: IAdminUser;
  creditedWriter: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IPagePublishHistory {
  variant: string;
  locale: string;
  action: "CREATE" | "UPDATE" | "DELETE";
  page: IPage;
  date: Date;
  user: IAdminUser;
  publishData: Date;
  responseData: object;
  publishStatus: "OK" | "ERROR" | "PENDING";
  errorReason: string;
}

export enum eAcfType {
  Text = "text",
  Repeater = "repeater",
  SidebarCTA = "sidebarCTA",
  TableOfContents = "tableOfContents",
}
export enum eAcfFieldType {
  Text = "text",
  List = "list",
  Switch = "switch",
  ObjectList = "objectList",
  richText = "richText",
}

export enum ePageType {
  Blog = "Blog",
  Targeted = "Targeted",
  ComparisonTop10 = "ComparisonTop10",
  ComparisonTopAlternatives = "ComparisonTopAlternatives",
  ProductReview = "ProductReview",
  ProductPage = "ProductPage",
  ProductAlternative = "ProductAlternative",
  ProductComparison = "ProductComparison",
  PpcTop10 = "PPCTop10",
}
export enum ePagePublishAction {
  update = "Update",
  publish = "Publish",
  create = "Create",
}

export interface IPageCluster {
  _id: string;
  type: ePageType | string;
  website: IWebsite;

  variants: string[];
  locales: string[];

  publishedPages: Map<string, [{ page: IPage; locale: string }]>;
  publishHistory: IPagePublishHistory[];
  // versionHistory: [{ state: IPageState; locale: string; variant: string }];
  pageHistory: Map<string, Map<string, IPageState[]>>;

  pages: IPage[];

  createdBy: IAdminUser;
  createdDate: Date;
  deletedBy: IAdminUser;
  deletionDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IPageState {
  _id: string;
  date: Date;
  action: ePageAction;
  actionReason: string;
  actionUser: IAdminUser;
  state: ePageState;
  subState: string;
}

export interface IPage {
  _id: string;
  cluster: IPageCluster;
  type: ePageType | string;
  website: IWebsite;
  variant: string;
  locale: string;

  replacedBy: IPage;
  replaces: IPage;

  factChecked: boolean;

  brief: string;
  briefRichText: string;
  briefWordsCount: number;

  showBrief: boolean;
  showTOC: boolean;
  showCoverImage: boolean;

  slug: string;

  title: string;
  richTitle: string;
  titleWordsCount: number;

  subtitle: string;
  richSubtitle: string;
  subtitleWordsCount: number;
  text: string;
  richText: string;
  textWordsCount: number;

  linksCount: number;

  resources: {
    title: string;
    text: string;
    richText: string;
  };

  customFields: object;

  featured: boolean;
  best: boolean;
  outline: IOutline;
  categories: ICategory[];
  tags: ITag[];
  products: IProduct[];
  keywordGroups: any[];

  previewImage: IFile;
  previewVideo: ICoverVideo;

  metaTitle: string;
  metaDescription: string;
  index: boolean;
  follow: boolean;

  currentState: IPageState;

  url: string;

  creditedWriter: IAdminUser;

  writer: IAdminUser;
  editor: IAdminUser;
  secondEditor: IAdminUser;
  factChecker: IAdminUser;
  reviews: {
    reviewer: IAdminUser;
    subState: string;
    reviewDate: Date;
    pass: boolean;
  }[];
  // factChecker: IAdminUser;
  // secondFactChecker: IAdminUser;
  // thirdFactChecker: IAdminUser;
  // fourthFactChecker: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;
  deletedBy: IAdminUser;
  deletionDate: Date;

  externalId: string;

  createdAt: Date;

  dueDate: Date;
  quotaDate: Date;

  firstPublishDate: Date;
  lastPublishDate: Date;
  updatedAt: Date;

  resolvedCommentsCount: number;
  openCommentsCount: number;
  imported: boolean;
  optimize: boolean;
}
// .select("-richText -text ")

export interface ICustomPost {
  type: eCustomPostType;
  _id: string;
  title: string;
  changingTitle: string;
  richTitle: string;

  text: string;
  richText: string;

  brief: string;

  url: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;

  customFields: any;

  postedTime: Date;
  category: ICategory;
  categories: ICategory[];
  tags: ITag[];
  state: ePostState;
  customerState: eCustomerPostState;

  previewImage: IFile;

  revisions: [
    {
      date: Date;
      state: ePostState;
      writer: IAdminUser;
    }
  ];

  uploads: [
    {
      date: Date;
      fileName: string;
      articleJson: any;
      uploadStatus: any;
      customerStatus: string;
      customerStatusObject: any;
      customerStatusDate: Date;
    }
  ];

  website: IWebsite;
  published: boolean;
  lastPublishedTime: Date;
  firstPublishedTime: Date;
  publishedPostUrl: string;
  publishedPostId: string;

  outline: IOutline;

  editor: IAdminUser;
  writer: IAdminUser;
  creditedWriter: IAdminUser;
  clientEditor: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IFbGroup {
  _id: string;
  fbId: string;
  name: string;
  url: string;
  pendingPosts: number;
  enableScraping: boolean;
  websites: IWebsite[];
  counters?: any;
}

// export interface IPage {
//   _id: string;
//   title: string;
//   metaDescription: string;
//   data: any[];
//   state: "NEW" | "DRAFT" | "TO_BE_PUBLISHED" | "TO_BE_UPDATED" | "PUBLISHED" | "DELETED";
//   slug: string;
//   template: IPageTemplate;
//   googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;
//   website: IWebsite;
// }

// export interface IPageTemplate {
//   _id: string;
//   name: string;
//   description: string;
//   structure: any[];
// }

// export interface IFbPost {
//   _id: string
//   fbId: string
//   name: string
//   url: string
//   pendingPosts: number
//   group: IFbGroup
//   checkedByUs: boolean
//   skipped: boolean
//   skippedAt: Date
//   deleted: boolean
//   locked: boolean
// }

export interface ITargetedPost {
  _id: string;
  translations: ITargetedPostTranslation[];
  changingTitle: string;
  title: string;
  richTitle: string;
  originalText: string;
  text: string;
  richText: string;
  resourceText: string;
  resourceTitle: string;
  resourceRichText: string;
  metaDescription: string;
  category: ICategory;
  topic: ITopic;
  tags: ITag[];
  outline: IOutline;
  keywords: ISEOKeyword[];
  sourceURLs: string[];

  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;
  expert: IIndustryExpert;

  state: ePostState;
  customerState: eCustomerPostState;

  facebookPostId: string;

  uploadTo: IWebsite;
  uploaded: boolean;
  uploadedTime: Date;
  uploadedPostUrl: string;
  uploadedPostId: string;

  editor: IAdminUser;
  clientEditor: IAdminUser;
  writer: IAdminUser;
  creditedWriter: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface ITargetedPostTranslation {
  _id: string;
  post: ITargetedPost;
  locale: string;
  website: IWebsite;

  changingTitle: string;
  title: string;
  richTitle: string;
  originalText: string;
  text: string;
  richText: string;

  resourceText: string;
  resourceTitle: string;
  resourceRichText: string;

  metaDescription: string;

  expert: IIndustryExpert;

  state: ePostState;

  uploaded: boolean;
  uploadedTime: Date;
  uploadedPostUrl: string;
  uploadedPostId: string;

  editor: IAdminUser;
  writer: IAdminUser;
  creditedWriter: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IProduct {
  _id: string;
  active: boolean;
  type: string;

  autoSlug: string;
  slug: string;

  itemNumber: string;

  logo: string;
  iconFile: IFile;
  logoFile: IFile;
  avatarFile: IFile;

  name: string;
  displayName: string;

  shortDescription: string;
  description: string;
  richDescription: string;

  // being used as Primary CTA Link
  callToAction: string;
  callToActionURL: string;

  // renamed into Secondary CTA URL
  linkText: string;
  link: string;

  ctaQueryParams: string; // split test link

  reviewLink: string;
  images: string[];
  imageFiles: IFile[];

  rating: string;
  numOfReviews: number;
  score: string;

  bullets: string[];
  tags: string[];

  pricing: {
    payment: "free" | "recurring" | "one_time" | null;
    freePlan: boolean;
    freeTrial: boolean;
    freeTrialDays: number;
    startingPrice: string;
    startingPriceBillingCycle: "month" | "year";
    price: string;
    priceBillingCycle: "month" | "year";
    currency: {
      symbol: string;
      code: string;
    };
  };

  website: IWebsite;
  goal: IGoal;

  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export enum eComparisonPostTemplate {
  TOP_10 = "TOP_10",
  TOP_ALTERNATIVES = "TOP_ALTERNATIVES",
  TOP_SEO = "TOP_SEO",
}

export interface IComparisonPost {
  _id: string;
  slugTitle: string;

  pageTitle: string;
  pageText: string;
  pageCoverImage: IFile;

  postTitle: string;
  postRichTitle: string;
  postText: string;
  postRichText: string;
  postBottomText: string;
  postBottomRichText: string;

  topSeoTitle: string;
  topSeoDetails: Map<
    string,
    {
      title: string;
      productNumber: string;
      productLogo: IFile;
      productImage: IFile;
      callToActionTitle: string;
      callToActionReviewUrl: string;
      bullets: [
        {
          name: string;
          text: string;
        }
      ];
      text: string;
      richText: string;
      prosConsTitle: string;
      pros: string[];
      cons: string[];
    }
  >;

  postOriginalText: string;
  postResourceTitle: string;
  postResourceText: string;
  postResourceRichText: string;

  sideSectionTitle: string;
  sideSectionText: string;

  mainProduct: IProduct;
  reviewItems: IProduct[];

  template: eComparisonPostTemplate;

  category: ICategory;
  tags: ITag[];
  keywords: ISEOKeyword[];
  sourceURLs: string[];
  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;

  expert: IIndustryExpert;

  metaDescription: string;

  state: ePostState;
  website: IWebsite;

  uploaded: boolean;
  uploadedTime: Date;
  uploadedPostUrl: string;
  uploadedPostId: string;

  writer: IAdminUser;
  creditedWriter: IAdminUser;
  editor: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IProductReview {
  _id: string;
  website: IWebsite;
  state: ePostState;

  metaDescription: string;

  changingTitle: string;
  pageTitle: string;
  pageText: string;
  pageRichText: string;

  pros: string[];
  cons: string[];

  reviewText: string;
  reviewRichText: string;

  category: ICategory;
  tags: ITag[];
  keywords: ISEOKeyword[];
  sourceURLs: string[];
  coverImage: IFile;

  product: IProduct;

  expert: IIndustryExpert;

  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;

  uploaded: boolean;
  uploadedTime: Date;
  uploadedPostUrl: string;
  uploadedPostId: string;

  writer: IAdminUser;
  creditedWriter: IAdminUser;
  editor: IAdminUser;
  editorNotes: string;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IProductPage {
  _id: string;
  website: IWebsite;
  state: ePostState;

  metaDescription: string;

  changingTitle: string;
  title: string;
  text: string;
  richText: string;

  bulletsTitle: string;
  bullets: {
    name: string;
    text: string;
  }[];

  reviewText: string;
  reviewRichText: string;

  category: ICategory;
  tags: ITag[];
  keywords: ISEOKeyword[];
  sourceURLs: string[];
  coverImage: IFile;

  product: IProduct;

  expert: IIndustryExpert;

  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;

  uploaded: boolean;
  uploadedTime: Date;
  uploadedPostUrl: string;
  uploadedPostId: string;

  writer: IAdminUser;
  creditedWriter: IAdminUser;
  editor: IAdminUser;
  editorNotes: string;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IProductAlternative {
  _id: string;
  website: IWebsite;
  state: ePostState;
  metaDescription: string;

  product: IProduct;
  competitorProduct: IProduct;

  changingTitle: string;
  title: string;
  text: string;

  reasonsWhy: {
    include: boolean;
    title: string;
    reasons: {
      title: string;
      description: string;
    }[];
  };

  comparisonTable: {
    include: boolean;
    title: string;
    description: string;
    features: {
      name: string;
      competitor: boolean;
      yourApp: boolean;
    }[];
  };

  testimonial: {
    include: boolean;
    text: string;
    userName: string;
  };

  post: {
    include: boolean;
    text: string;
    richText: string;
  };

  category: ICategory;
  tags: ITag[];
  keywords: ISEOKeyword[];
  sourceURLs: string[];
  coverImage: IFile;

  expert: IIndustryExpert;

  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;

  uploaded: boolean;
  uploadedTime: Date;
  uploadedPostUrl: string;
  uploadedPostId: string;

  writer: IAdminUser;
  creditedWriter: IAdminUser;
  editor: IAdminUser;
  editorNotes: string;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IProductComparison {
  _id: string;
  website: IWebsite;
  state: ePostState;
  metaDescription: string;
  pageURL: string;
  title: string;
  changingTitle: string;

  products: IProduct[];

  aboveFeaturesTable: {
    text: string;
    richText: string;
  };

  featuresTable: {
    header: string;
    title: string;
    text: string;
    rows: {
      title: string;
      description: string;
      products: {
        productId: string;
        checked: boolean;
      }[];
    }[];
  };

  belowFeaturesTable: {
    text: string;
    richText: string;
  };

  pricingTable: {
    title: string;
    text: string;
    rows: {
      title: string;
      description: string;
      products: {
        productId: string;
        checked: boolean;
        text: string;
      }[];
    }[];
  };

  belowPricingTable: {
    text: string;
    richText: string;
  };

  sideComparisonPost: IComparisonPost;

  category: ICategory;
  tags: ITag[];
  keywords: ISEOKeyword[];
  sourceURLs: string[];
  coverImage: IFile;

  expert: IIndustryExpert;

  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;

  uploaded: boolean;
  uploadedTime: Date;
  uploadedPostUrl: string;
  uploadedPostId: string;

  writer: IAdminUser;
  creditedWriter: IAdminUser;
  editor: IAdminUser;
  editorNotes: string;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  createdAt: Date;
  updatedAt: Date;
}

export interface IFile {
  _id: string;
  fileName: string;
  url: string;
  cdnUrl: string;
  thumbnailUrl: string;
  mimeType: string;
  extType: string;
  size: string;
  hash: string;
  bucket: string;
  key: string;

  desiredWidth: number;
  width: number;
  height: number;

  alt: string;
  title: string;
  caption: string;

  website: IWebsite;

  createdAt: Date;
  updatedAt: Date;
}

export interface ICoverVideo {
  videoId: string;
  url: string;
  width: number;
  height: number;
  type: string;
  structuredData: any;
}

export interface ISEOKeyword {
  _id: string;
  name: string;
}

export interface ILearnTopic {
  _id: string;
  name: string;
  order: number;
  text: string;
  richText: string;
  description: string;
  metaDescription: string;
  resourceText: string;
  resourceRichText: string;
  keywords: ISEOKeyword[];

  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;
  expert: IIndustryExpert;

  slug: string;
  uploadedUrl: string;
  uploadedTime: Date;

  state: ePostState;
  customerState: eCustomerPostState;

  parent: ILearnTopic;
  children: ILearnTopic[];
  website: IWebsite;

  editor: IAdminUser;
  writer: IAdminUser;
  creditedWriter: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  updatedAt: Date;
  createdAt: Date;

  posts?: ILearnPost[];
}

export interface ILearnPost {
  _id: string;
  order: number;
  title: string;
  changingTitle: string;
  richTitle: string;
  text: string;
  richText: string;
  metaDescription: string;
  topic: ILearnTopic;
  tags: ITag[];
  keywords: ISEOKeyword[];
  sourceURLs: string[];

  expert: IIndustryExpert;

  state: ePostState;
  customerState: eCustomerPostState;

  resourceText: string;
  resourceTitle: string;
  resourceRichText: string;

  googleSearchConsoleStatistics: IGoogleSearchConsoleStatistics;

  editedPostsSource: IEditedFbPost[];
  contentPostsSource: IContent[];

  history: [
    {
      initiator: string;
      action: string;
      date: Date;
    }
  ];

  uploadTo: IWebsite;
  uploaded: boolean;
  uploadedTime: Date;
  uploadedPostUrl: string;
  uploadedPostId: string;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  editor: IAdminUser;
  writer: IAdminUser;
  creditedWriter: IAdminUser;

  updatedAt: Date;
  createdAt: Date;
}

export interface ITemplateText {
  text: string;
  color: string;
  fontSize: number;
  fontWeight?: "bold" | "normal";
}

export interface ITemplateButton {
  link: string;
  text: string;
}

export interface ITemplateImage {
  url: string;
  alt: string;
  width: number;
  title?: ITemplateText;
  caption?: ITemplateText;
}

export interface ITemplateTextLink {
  link: string;
  text: string;
  color: string;
  fontSize: number;
  fontWeight?: "bold" | "normal";
}

export interface IIndustryExpert {
  _id: string;
  facebookUser: any;
  source: "FACEBOOK" | "MANUAL";
  name: string;
  email: string;
  attribution: string;
  profilePicUrl: string;
  facebookProfileUrl: string;
  websiteUrl: string[];
  facebookPageUrl: string[];
  linkedInUrl: string[];
  instagramUrl: string[];
  facebookGroupUrl: string[];
  youTubeChannelUrl: string[];
  notes: string;
  lastContacted: Date;
  contactedBy: string;
  permission: "AGREE_ALL" | "REFUSED_ALL" | "HAS_NOT_RESPONDED";
  expertIn: IWebsite[];

  postsCount?: number;
  commentsCount?: number;
  repliesCount?: number;

  potential?: boolean;
}

export enum eClusterPageType {
  NONE = "NONE",
  LEARN_TOPIC = "LEARN_TOPIC",
  LEARN_SUB_TOPIC = "LEARN_SUB_TOPIC",
  LEARN_POST = "LEARN_POST",
  BLOG_POST = "BLOG_POST",
  COMMUNITY_POST = "COMMUNITY_POST",
  TARGETED_POST = "TARGETED_POST",
  LANDING_PAGE = "LANDING_PAGE",
  TOPIC_PAGE = "TOPIC_PAGE",
}

export interface IClusterPage {
  _id: string;
  pageType: eClusterPageType;
  linkedAt: Date;

  learnTopic: ILearnTopic;
  learnPost: ILearnPost;
  blogPost: IBlogPost;
  communityPost: IQuestion;
  targetedPost: ITargetedPost;
  landingPage: IPage;
  topicPage: ITag;
}

export interface ICluster {
  _id: string;
  name: string;
  website: IWebsite;
  page: IClusterPage;
  linkingPages: IClusterPage[];
  scrapingKeywords: IWorkerScrapingKeyword[];
  createdAt: Date;
  updatedAt: Date;
}

// Search Console Importer Object
export interface IQuery {
  id: string;
  position: string;
  query: string;
  clicks: string;
  impressions: string;
  pages: string;
  totalPositionImpression: string;
  updatedAt: Date;
  createdAt: Date;
}

// Search Console Importer Object
export interface IQueryGroup {
  _id: string;
  id: string;
  groupKeyword: string;
  name: string;
  seed: string;
  mainQuery: string;
  searchIntent: string;
  modifiers: string;
  negativeModifiers: string;
  website: IWebsite;
  // mainQuery: IGoogleSearchConsoleQueryStatistics
  googleQueries: IQuery[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IKeyword {
  _id: string;
  domain: string;
  keyword: string;
  clicks: number;
  impressions: number;
  position: number;
  groups: string[];
  ctr?: number;
  keywordPages: IKeywordPage[];
  pages?: number;
  lastSerpFetch?: Date;
  maxDate: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IGroupKeyword {
  _id: string;
  domain: string;
  groupHash?: string;
  keywordCount?: number;
  keywords: IKeyword[];
  mainKeyword?: IKeyword;
  algoVersion: string;
  modifiers?: string;
  negativeModifiers?: string;
  searchIntent?: string;
  seed?: string;
  pages?: number;
  clicks?: number;
  impressions?: number;
  position?: number;
  ctr?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IKeywordPage {
  _id: string;
  domain: string;
  page: string;
  keywords?: IKeyword[];
  clicks: number;
  impressions: number;
  position: number;
  ctr: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IOpportunity {
  _id: string;
  domain: string;
  groupHash?: string;
  keywordCount?: number;
  keywords: IKeyword[];
  mainKeyword?: IKeyword;
  algoVersion: string;
  modifiers?: string;
  negativeModifiers?: string;
  searchIntent?: string;
  seed?: string;
  pages?: number;
  keywordPages?: IKeywordPage[];
  clicks?: number;
  impressions?: number;
  position?: number;
  ctr?: number;
  variations?: number;
  createdAt?: Date;
  updatedAt?: Date;
  total?: number;
}

export interface IKeywordGroup {
  domain: string;
  clicks_main_keyword: string;
  impressions_main_keyword: string;
  clicks?: number;
  impressions?: number;
  position?: number;
  ctr?: number;
  volume?: number;
  variations?: number;
  pages?: number;
}

export interface IKeywordGroupMember {
  keyword: string;
  domain: string;
  clicks?: number;
  impressions?: number;
  position?: number;
  ctr?: number;
  volume?: number;
}

export interface IKeywordGroupPage {
  page: string;
  domain: string;
  clicks?: number;
  impressions?: number;
  position?: number;
  ctr?: number;
}

export interface IKeywordGroupWithMetrics extends IKeywordGroup {
  keywords: IKeywordGroupMember[];
  pages: IKeywordGroupPage[];
}

export interface IQueryGroupMapping {
  id: string;
  name: string;
  parentId: string;
  parent: IQueryGroupContainer;
  childId: string;
  child: IQueryGroupContainer;
  children: IQueryGroupContainer[];
  keywordGroup: IQueryGroup;
  website: IWebsite;
  createdAt: Date;
  updatedAt: Date;
}

export interface IQueryGroupContainer {
  id: string;
  name: string;
  groupsMapping: IQueryGroupMapping[];
  website: IWebsite;
  createdAt: Date;
  updatedAt: Date;
}

export interface IGoogleSearchConsoleStatisticsResults {
  startDate: Date;
  endDate: Date;
  clicks: number;
  impressions: number;
  ctr: number;
  position: number;
}

export interface IGoogleSearchConsoleStatistics {
  _id: string;
  website: IWebsite;
  pageURL: string;
  today: IGoogleSearchConsoleStatisticsResults;
  lastWeek: IGoogleSearchConsoleStatisticsResults;
  last2Weeks: IGoogleSearchConsoleStatisticsResults;
  lastMonth: IGoogleSearchConsoleStatisticsResults;
  last2Months: IGoogleSearchConsoleStatisticsResults;
  last3Months: IGoogleSearchConsoleStatisticsResults;
  last6Months: IGoogleSearchConsoleStatisticsResults;
  lastYear: IGoogleSearchConsoleStatisticsResults;

  pageType:
    | "LEARN_TOPIC"
    | "LEARN_SUB_TOPIC"
    | "LEARN_POST"
    | "BLOG_CATEGORY"
    | "BLOG_POST"
    | "COMMUNITY_CATEGORY"
    | "COMMUNITY_POST"
    | "TARGETED_CATEGORY"
    | "TARGETED_POST"
    | "LANDING_PAGE"
    | "TOPIC_PAGE"
    | "GENERAL"
    | "NONE";
  postId: string;
}

export interface IGoogleSearchConsoleQueryStatistics {
  _id: string;
  website: IWebsite;
  query: string;
  pagesCount: number;
  today: IGoogleSearchConsoleStatisticsResults;
  lastWeek: IGoogleSearchConsoleStatisticsResults;
  last2Weeks: IGoogleSearchConsoleStatisticsResults;
  lastMonth: IGoogleSearchConsoleStatisticsResults;
  last2Months: IGoogleSearchConsoleStatisticsResults;
  last3Months: IGoogleSearchConsoleStatisticsResults;
  last6Months: IGoogleSearchConsoleStatisticsResults;
  lastYear: IGoogleSearchConsoleStatisticsResults;
}

export interface IWorkerCommand {
  _id: string;
  state: "PENDING" | "DELIVERED" | "DONE" | "DELETED";

  type: "SCRAPE_GROUP" | "SCRAPE_USER_GROUPS" | "SCRAPE_KEYWORD";
  scraperId: IWorker;
  groupFbId: string;
  pageFbId: string;
  keyword: IWorkerScrapingKeyword;

  sentAt: Date;
  completedAt: Date;
  completionStatus: "SUCCESS" | "ERROR" | "SKIP";
  completionParams: string;
}

export interface IWorker {
  _id: string;
  facebookId: string;
  userName: string;
  email: string;
  password: string;
  url: string;

  name: string;
  phone: string;
  gender: "male" | "female" | "none";

  locale: string;
  country: string;
  macAddress: string;
  queueName: string;

  websites: IWebsite[];
  site: string[];

  userAgent: string;
  cookies: string;
  siteName: string;

  isMobile: boolean;
  isBlocked: boolean;
  blockedAt: Date;

  lastKeepAlive: Date;

  groups: IFbGroup[];
  groupsUploadedAt: Date;

  lastCommand: IWorkerCommand;
  lastCommandAt: Date;
  lastScrapePageCommand: IWorkerCommand;
  lastScrapePageCommandAt: Date;
  lastScrapeGroupCommand: IWorkerCommand;
  lastScrapeGroupCommandAt: Date;
  lastSearchCommand: IWorkerCommand;
  lastSearchCommandAt: Date;
}

export interface IWorkerScrapingKeyword {
  _id: string;
  name: string;
  active: boolean;
  website: IWebsite;
  lastCommand: IWorkerCommand;
  lastCommandAt: Date;
  pendingPosts?: number;
}

export interface IAdPlacement {
  _id: string;
  type:
    | "BLOG_FEED_IMAGE"
    | "BLOG_MOBILE_FEED_IMAGE"
    | "TALL_SIDE"
    | "FORUM_POST_IMAGE"
    | "FORUM_POST_TEXT_ONLY"
    | "MOBILE_FEED_CENTER"
    | "POST_FORM";
  // title: string
  // text: string
  // link: string
  // callToAction: string
  // platforms: ('MOBILE' | 'WEB')[]

  impressions: number;
  clicks: number;

  active: boolean;

  imageFile: IFile;
  // imageAlt: string
  imageTooltip: string;

  embedHtml: string;
  iframeURL: string;
  iframeWidth: number;
  iframeHeight: number;
}

export interface IAdBusiness {
  _id: string;
  name: string;
  profileImageFile: IFile;
  websiteURL: string;
}

export interface IAd {
  _id: string;
  status: "DRAFT" | "OFF" | "PUBLISH" | "PUBLISHED";
  website: IWebsite;
  name: string;
  business: IAdBusiness;
  title: string;
  text: string;
  richText: string;
  link: string;
  callToAction: string;

  tags: ITag[];

  form: {
    logo: IFile;
    title: string;
    text: string;
    button: string;
    consentText: string;
    emails: string;
    fields: {
      name: string;
      placeholder: string;
      required: boolean;
      type: "text" | "number" | "email" | "phone";
    }[];
  };

  active: boolean;
  published: boolean;

  placementStatisticsUpdatedAt: Date;
  placements: IAdPlacement[];

  updatedAt: Date;
  createdAt: Date;
}

export interface IAudienceCategory {
  _id: string;
  alternativeId: string;

  website: IWebsite;
  name: string;

  metaDescription: string;
  title: string;
  description: string;

  writer: IAdminUser;
  creditedWriter: IAdminUser;
  editor: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;
}

export interface IAudienceTopic {
  _id: string;
  alternativeId: string;

  website: IWebsite;
  name: string;

  title: string;
  text: string;
  metaDescription: string;

  writer: IAdminUser;
  creditedWriter: IAdminUser;
  editor: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;
}

export interface IAudience {
  _id: string;
  alternativeId: string;

  website: IWebsite;
  active: boolean;

  displayName: string;
  name: string;

  categories: IAudienceCategory[];
  topics: IAudienceTopic[];

  appearance: {
    active: boolean;
    consumer: boolean;
    business: boolean;
    image: IFile;
    title: string;
    description: string;
  };

  pageTop: {
    active: boolean;
    image: IFile;
    title: string;
    description: string;
    button: string;
  };

  searchQueries: {
    active: boolean;
    title: string;
    secondaryTitle: string;
    categories: [
      {
        active: boolean;
        name: string;
        description: string;
        searchedBy: string;
        mainIntent: string;
        secondaryIntent: string;
        queries: string[];
      }
    ];
  };

  convert: {
    active: boolean;
    image: IFile;
    title: string;
    description: string;
    button: string;
  };

  growth: {
    active: boolean;
    image: IFile;
    title: string;
    description: string;
    button: string;
  };

  cost: {
    active: boolean;
    image: IFile;
    title: string;
    description: string;
    button: string;
  };

  callToAction: {
    active: boolean;
    title: string;
    description: string;
    button: string;
  };

  uploadedUrl: string;
  uploadedId: string;

  writer: IAdminUser;
  creditedWriter: IAdminUser;
  editor: IAdminUser;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;

  updatedAt: Date;
}

export interface IEditorialPolicy {
  _id: string;
  type: "EDITORIAL_PROCESS" | "COMMUNITY_GUIDELINES" | "EDITORIAL_TEAM";

  logo: IFile;
  title: string;
  socials: {
    facebook: string;
    twitter: string;
    youTube: string;
    linkedin: string;
  };
  text: string;
  richText: string;

  metaDescription: string;
  website: IWebsite;

  createdAt: Date;
  updatedAt: Date;
}

export interface IWebsiteArticleProps {
  h2: {
    css: any;
  };
  h3: {
    css: any;
  };
  h4: {
    css: any;
  };
  h5: {
    css: any;
  };
  h6: {
    css: any;
  };
  paragraphs: {
    css: any;
  };
  code: {
    css: any;
  };
  table: {
    css: any;
  };
  links: {
    css: any;
  };
  image: {
    css: any;
  };
  quotes: {
    css: any;
  };
  orderedLists: {
    css: any;
  };
  unorderedLists: {
    css: any;
  };
}

export interface IWebsiteThemeProps {
  typography: {
    fontFamily: string;
    h1: {
      css: any;
    };
    h2: {
      css: any;
    };
    h3: {
      css: any;
    };
    h4: {
      css: any;
    };
    h5: {
      css: any;
    };
    h6: {
      css: any;
    };
    subtitle1: {
      css: any;
    };
    subtitle2: {
      css: any;
    };
    body1: {
      css: any;
    };
    body2: {
      css: any;
    };
  };
  palette: {
    primary: {
      main: string;
      light: string;
      dark: string;
    };
    secondary: {
      main: string;
      light: string;
      dark: string;
    };
    background: {
      paper: string;
      default: string;
    };
    text: {
      primary: string;
      secondary: string;
      disabled: string;
    };
    divider: string;
  };
  breakpoints: {
    values: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
  };
  links: {
    css: any;
  };
  buttons: {
    css: any;
  };
  tags: {
    css: any;
  };
  textFields: {
    css: any;
  };
  table: {
    css: any;
    headerCss: any;
    bodyCss: any;
  };
  inPostTags: {
    css: any;
  };
}

export interface IWebsiteCommonComponents {
  loginDialog: {
    title: string;
    text: string;
    terms: string;
    loginDialogImage: IFile;
  };
  sideSubscribe: {
    enabled: boolean;
    title: string;
    text: string;
    terms: string;
    customElementCode: string;
  };
  poweredBy: {
    enabled: boolean;
    text: string;
    link: string;
    linkName: string;
    color: string;
    colorOnHover: string;
    backgroundColor: string;
  };
  addons: {
    toc: {
      type: string;
      name: string;
      defaultState: boolean;
      showH3s: boolean;
    };
  };
}

export interface IWebsiteSectionProps {
  category: boolean;
  topic: boolean;
  blog: boolean;
  forum: boolean;
  learn: boolean;
  homepage: boolean;
  comparisonTop10: boolean;
  comparisonTopAlternative: boolean;
  productComparison: boolean;
  productPage: boolean;
  productReview: boolean;
  productAlternative: boolean;
  glossary: boolean;

  editorialPolicy: boolean;
  editorialTeam: boolean;
  communityGuidelines: boolean;
  contentPrinciples: boolean;
  factChecked: boolean;

  ppcTop10: boolean;
  resourcePage: boolean;
}

export interface IWebsiteCustomCodeProps {
  head: string;
  body: string;
  globalCSS: any;
  muiOverrides: any;
  // hooks: {
  // 	inPageLinks: string;
  // };
}

export interface IWebsiteAnalyticsProps {
  entailAnalytics: boolean;
  entailWidgets: boolean;
  entailPopups: boolean;
  entailTracker: boolean;
  entailTrackingDomains: string[];
  facebookPixel: string;
  googleAnalytics: string;
  googleTagManager: string;
  entailToken: string;
  segment: string;
  entailInstalled: boolean;
}

export interface IWebsiteLocale {
  defaultLocale?: string;
  inSitemap: boolean;
  currency: {
    symbol: string;
    code: string;
  };
  alternateLocales: [
    {
      locale: string;
      urlPrefix: string;
      inSitemap: boolean;
      currency: {
        symbol: string;
        code: string;
      };
      replacements: [
        {
          replace: string;
          with: string;
        }
      ];
    }
  ];
}

export interface IWebsiteDetailsProps {
  lazyLoad: boolean;
  imageOptimization: boolean;
  productsSplitTests: boolean;
  monitoring: boolean;
  videoCmsAccess: boolean;
  trailingSlash: boolean;
  trailingSlashAutoRedirect: boolean;
  urlPrefix: string;
  apiUrl: string;
  platform: string;
  webflow: {
    token?: string;
    siteId?: string;
    blogCollectionId?: string;
    authorsCollectionId?: string;
    categoriesCollectionId?: string;
  };
  shopUrl: string;
  shopApiKey: string;
  shopSecret: string;
  integrationType: string;
  customHeaderAndFooter: boolean;
  logo: IFile;
  defaultPreviewImage: IFile;
  favicon: IFile;
  privacyPolicyURL: string;
  termsAndConditionsURL: string;
  distributionId: string;
  mediaWhiteLabelUrlPrefix: string;
  staticSourcesWhiteLabelUrlPrefix: string;
  factCheckerActive: boolean;
  clearWordpressCache: boolean;
  targetAudience: {
    pageUrl: string;
    target: {
      _id: string;
      name: string;
    };
    goal: {
      _id: string;
      name: string;
    };
    type: {
      _id: string;
      name: string;
    };
  };
}

export interface IWebsiteReviewStep {
  reviewType: "factCheck" | "regulationCheck";
  reviewName: string;
  reviewStepName: string;
  dependencies: string[];
  locales: string[];
  approvingLocales: string[];
}
export interface IWebsiteGSC {
  website: IWebsite;
  enabled: boolean;
  serpEnabled?: boolean;
  gscDomain: string;
  saleValue?: number;
  cvr: { [key: string]: number };
  serpCtr?: number;
  lastAvailableDatesScan: Date;
  minAvailableDate: Date;
  maxAvailableDate: Date;
  lastScan: Date;
}

export interface IWebsiteBlogProps {
  access: eSectionAccess;
  name: string;
  urlPrefix: string;
  template: string;
  mainPage: {
    slug: string;
    metaTitle: string;
    metaDescription: string;
    title: {
      text: string;
      css: any;
    };
    description: {
      text: string;
      css: any;
    };
  };
  categoryPage: {
    slug: string;
    metaTitle: string;
    metaDescription: string;
  };
  postPage: {
    metaTitle: string;
    metaDescription: string;
    title: {
      css: object;
    };
  };
  maypleTemplate: {
    mainPage: {
      metaTitle: string;
      metaDescription: string;
    };
    featuredPost: {
      slug: string;
      title: string;
    };
    topArticles: {
      title: {
        text: string;
        css: any;
      };
      posts: {
        slug: string;
        title: string;
      }[];
    };
    categories: { name: string; slug: string }[];
  };
  safebooksTemplate: {
    mainPage: {
      metaTitle: string;
      metaDescription: string;
    };
    bottomWidgetId: string;
  };
  jitTemplate: {
    mainPage: {
      title: {
        text: string;
        css: any;
      };
      description: {
        text: string;
        css: any;
      };
      metaTitle: string;
      metaDescription: string;
      button: {
        text: string;
        url: string;
        css: object;
      };
      backgroundImage: {
        _id: string;
        url: string;
        alt: string;
        title: string;
        caption: string;
      };
      mainImage: {
        _id: string;
        url: string;
        alt: string;
        title: string;
        caption: string;
      };
      articles: {
        title: string;
        url: string;
      }[];
    };
  };
  translations: Map<
    string,
    {
      name: string;
      urlPrefix: string;
      mainPage: {
        title: string;
        slug: string;
        description: string;
        metaTitle: string;
        metaDescription: string;
      };
      categoryPage: {
        slug: string;
        metaTitle: string;
        metaDescription: string;
      };
      postPage: {
        metaTitle: string;
        metaDescription: string;
      };
      maypleTemplate: {
        mainPage: {
          metaTitle: string;
          metaDescription: string;
        };
        topArticles: {
          title: string;
        };
      };
      safebooksTemplate: {
        mainPage: {
          metaTitle: string;
          metaDescription: string;
        };
        bottomWidgetId: string;
      };
      jitTemplate: {
        mainPage: {
          title: string;
          description: string;
          metaTitle: string;
          metaDescription: string;
          button: {
            text: string;
            url: string;
          };
          articles: {
            title: string;
            url: string;
          }[];
        };
      };
    }
  >;
}

export interface IWebsitePPCTop10Props {
  access: eSectionAccess;
  name: string;
  urlPrefix: string;
  postPage: {
    slug: string;
    metaTitle: string;
    metaDescription: string;
    title: {
      css: object;
    };
  };
  translations: Map<
    string,
    {
      name: string;
      urlPrefix: string;
      postPage: {
        slug: string;
        metaTitle: string;
        metaDescription: string;
      };
    }
  >;
}
export interface IWebsiteForumProps {
  name: string;
  urlPrefix: string;
  mainPage: {
    slug: string;
    metaTitle: string;
    metaDescription: string;
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    button: {
      text: string;
      url: string;
      css: object;
    };
  };
  categoryPage: {
    slug: string;
    metaTitle: string;
    metaDescription: string;
  };
  postPage: {
    metaTitle: string;
    metaDescription: string;
    title: {
      css: object;
    };
  };
}

export interface IWebsiteCategoryProps {
  name: string;
  slug: string;
  metaTitle: string;
  urlPrefix: string;
  metaDescription: string;
  title: {
    css: object;
  };
  translations: Map<
    string,
    { slug: string; urlPrefix: string; metaTitle: string; metaDescription: string; name: string }
  >;
}

export interface IWebsiteTopicProps {
  name: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  title: {
    css: object;
  };
  translations: Map<string, { metaTitle: string; metaDescription: string; name: string }>;
}

export interface IWebsiteGlossaryProps {
  name: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  urlPrefix: string;
  title: {
    text: string;
    css: object;
  };
  description: {
    text: string;
    css: object;
  };
}

export interface IWebsiteLearnProps {
  access: eSectionAccess;
  name: string;
  urlPrefix: string;
  mainPage: {
    slug: string;
    metaTitle: string;
    metaDescription: string;
    customTopSection: boolean;
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
  };
  categoryPage: {
    slug: string;
    metaTitle: string;
    metaDescription: string;
  };
  postPage: {
    metaTitle: string;
    metaDescription: string;
    title: {
      css: object;
    };
    description: {
      css: object;
    };
  };
  translations: Map<
    string,
    {
      name: string;
      urlPrefix: string;
      mainPage: {
        slug: string;
        title: string;
        description: string;
        metaTitle: string;
        metaDescription: string;
      };
      categoryPage: {
        slug: string;
        metaTitle: string;
        metaDescription: string;
      };
      postPage: {
        metaTitle: string;
        metaDescription: string;
      };
    }
  >;
}

export interface IWebsiteHomepageProps {
  template: string;
  name: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  topSection: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    button: {
      text: string;
      url: string;
      css: object;
    };
    top10Title: {
      text: string;
      css: object;
    };
  };
  comparisonSection: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    comparisonIds: string[];
  };
  ourGoalSection: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    bullets: [
      {
        name: string;
        text: string;
      }
    ];
    findMoreDetailsEditorialPolicy: {
      text: string;
    };
  };
  featuredPostsSection: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
    featuredPostsIds: string[];
  };
  reviewsSection: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
    productReviewsIds: string[];
  };
  targetedPostsSection: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
    targetedPostsIds: string[];
  };
  blogTopPostsIds: string[];
  blogBottomPostsIds: string[];

  metaTitleKeepshoppers: string;
  metaDescriptionKeepshoppers: string;
  topSectionKeepshoppers: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    banners: {
      title: {
        text: string;
        css: object;
      };
      description: {
        text: string;
        css: object;
      };
      background: object;
      button: {
        text: string;
        url: string;
        css: object;
      };
      image: IFile;
    }[];
  };
  tabsSectionKeepshoppers: {
    enabled: boolean;
    tabs: {
      title: string;
      icon: IFile;
      url: string;
    }[];
  };
  topProductsSectionKeepshoppers: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    products: {
      itemNumber: string;
      name: string;
      url: string;
      displayName: string;
      reviewLink: string;
    }[];
  };
  recommendedPostsSectionKeepshoppers: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
    postIds: string[];
  };
  comparisonSectionKeepshoppers: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
  };
  reviewsSectionKeepshoppers: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
  };
  blogPostsSectionKeepshoppers: {
    enabled: boolean;
    title: {
      text: string;
      css: object;
    };
  };
}

export interface IWebsiteResourcePageProps {
  name: string;
  metaTitle: string;
  urlPrefix: string;
  slug: string;
  metaDescription: string;
  template: string;
  topSection: {
    enabled: boolean;
    featuredPost: {
      slug: string;
      title: string;
    };
    topArticles: {
      slug: string;
      title: string;
    }[];
    editorPicks: {
      slug: string;
      title: string;
    }[];
  };
  featuredBanner: {
    enabled: boolean;
    background: object;
    title: {
      text: string;
      css: object;
    };
    button: {
      text: string;
      url: string;
      css: object;
      arrow: boolean;
    };
    postPage: {
      titleCss: object;
      briefCSS: object;
    };
    post: {
      slug: string;
      title: string;
    };
  };
  dynamicBanner: {
    enabled: boolean;
    background: object;
    title: {
      text: string;
      css: object;
    };
    button: {
      text: string;
      url: string;
      css: object;
      arrow: boolean;
    };
    customHtml: {
      enabled: boolean;
      value: string;
    };
  };
}

export interface IWebsiteComparisonTopAlternativeProps {
  enabled: boolean;
  access: eSectionAccess;
  name: string;
  urlPrefix: string;
  mainPage: {
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    listTitle: {
      text: string;
      css: object;
    };
    metaTitle: string;
    metaDescription: string;
    slug: string;
  };
  postPage: {
    metaTitle: string;
    metaDescription: string;
    disclaimer: string;
    title: {
      css: object;
    };
  };
}

export interface IWebsiteComparisonTop10Props {
  enabled: boolean;
  access: eSectionAccess;
  name: string;
  urlPrefix: string;
  mainPage: {
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    listTitle: {
      text: string;
      css: object;
    };
    metaTitle: string;
    metaDescription: string;
    slug: string;
  };
  postPage: {
    metaTitle: string;
    metaDescription: string;
    disclaimer: string;
    title: {
      css: object;
    };
  };
}

export interface IWebsiteProductComparisonProps {
  enabled: boolean;
  access: eSectionAccess;
  name: string;
  urlPrefix: string;
  mainPage: {
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    listTitle: {
      text: string;
      css: object;
    };
    metaTitle: string;
    metaDescription: string;
    slug: string;
  };
  postPage: {
    metaTitle: string;
    metaDescription: string;
    disclaimer: string;
    title: {
      css: object;
    };
  };
}

export interface IWebsiteProductReviewProps {
  enabled: boolean;
  access: eSectionAccess;
  urlPrefix: string;
  name: string;
  mainPage: {
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    listTitle: {
      text: string;
      css: object;
    };
    metaTitle: string;
    metaDescription: string;
    slug: string;
  };
  postPage: {
    metaTitle: string;
    metaDescription: string;
    disclaimer: string;
    title: {
      css: object;
    };
  };
}

export interface IWebsiteProductAlternativeProps {
  enabled: boolean;
  access: eSectionAccess;
  name: string;
  urlPrefix: string;
  mainPage: {
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    listTitle: {
      text: string;
      css: object;
    };
    metaTitle: string;
    metaDescription: string;
    slug: string;
  };
  postPage: {
    metaTitle: string;
    metaDescription: string;
    disclaimer: string;
    title: {
      css: object;
    };
  };
}

export interface IWebsiteProductPageProps {
  enabled: boolean;
  name: string;
  urlPrefix: string;
  mainPage: {
    title: {
      text: string;
      css: object;
    };
    description: {
      text: string;
      css: object;
    };
    listTitle: {
      text: string;
      css: object;
    };
    metaTitle: string;
    metaDescription: string;
    slug: string;
  };
  postPage: {
    metaTitle: string;
    metaDescription: string;
    disclaimer: string;
    title: {
      css: object;
    };
  };
}

export interface IOutlineParagraphData {
  targetKeywordsGroups: IQueryGroup[];
  h3: string[];
  links: {
    url: string;
    text: string;
  }[];
  notes: string;
}

export interface IAutoOutlineParagraphData {
  targetKeywordsGroups: string[];
  h3: string[];
  notes: string;
}

export interface IOutlineParagraphFAQData {
  questions: {
    question: string;
    targetKeywordsGroups: IQueryGroup[];
    links: {
      url: string;
      text: string;
    }[];
    notes: string;
  }[];
}

export interface IAutoVideoInterview {
  website: IWebsite;
  state: "NEW" | "DRAFT" | "REVIEW" | "PUBLISHED";
  keywordGroup: string;
  paragraphs: { title: string; targetKeywordsGroups: string[] }[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IAutoOutline {
  _id: string;
  website: IWebsite;
  state: "NEW" | "DRAFT" | "REVIEW" | "POST" | "PENDING" | "APPROVED" | "REJECTED";
  outline?: IOutline;
  postType: ePageType;
  funnelStage?: string;
  broadKeywordsCount?: number;
  category?: ICategory;
  keywordGroup?: string;
  keywordsCount?: number;
  volume?: number;
  page: IPage | null;
  pageCluster: IPageCluster | null;
  postTitle: {
    title?: string;
    targetKeywordsGroups?: string[];
    notes?: string;
  };
  paragraphs: {
    type: "PARAGRAPH";
    title: string;
    data: IAutoOutlineParagraphData;
  }[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IAutoOutlineWithStrategy extends IAutoOutline {
  strategy?: IKeywordStrategy;
  pagesCount?: number;
}
export interface IOutline {
  _id: string;
  website: IWebsite;
  state: "NEW" | "DRAFT" | "REVIEW" | "POST";
  analyst: IAdminUser;
  editor: IAdminUser;
  createdAt: Date;
  dueDate: Date;
  quotaDate: Date;
  postType: ePageType;
  wordCount: number;
  category: ICategory;
  tags: ITag[];
  links: string[];
  locale: string;
  postTitle: {
    title: string;
    targetKeywordsGroups: IQueryGroup[];
    notes: string;
  };
  paragraphs: [
    {
      type: "PARAGRAPH" | "FAQ";
      title: string;
      data: IOutlineParagraphData | IOutlineParagraphFAQData;
    }
  ];
}

interface IKeywordStrategyWithCount extends IKeywordStrategy {
  keyword_count: number;
  broad_keyword_count: number;
}
export interface IOutlineWithStrategy extends IOutline {
  outline: string;
  strategy?: IKeywordStrategyWithCount;
}

export interface IStrategy {
  name: string;
  website: IWebsite;
  url: string;
  languages: string[];
  active: boolean;
  startDate: Date;
  locales: string[];
  createdBy: IAdminUser;
  monthlyQuota: {
    community: {
      posts: number;
      words: number;
    };
    targetedPosts: {
      posts: number;
      words: number;
    };
    blog: {
      posts: number;
      words: number;
    };
    reviews: {
      posts: number;
      words: number;
    };
    comparison: {
      posts: number;
      words: number;
    };
    alternatives: {
      posts: number;
      words: number;
    };
    bestProductsPPC: {
      posts: number;
      words: number;
    };
    bestProductsSEO: {
      posts: number;
      words: number;
    };
    bestAlternatives: {
      posts: number;
      words: number;
    };
  };
  audiences: any[];
  competitors: {
    url: string;
    type: "website" | "root domain" | "subdomain" | "page" | "subfolder";
  }[];
  marketingActivities: {
    googlePPC: {
      url: string;
      active: boolean;
    };
    socialMediaMarketing: {
      url: string;
      active: boolean;
    };
    facebookAdvertising: {
      url: string;
      active: boolean;
    };
    emailMarketing: {
      url: string;
      active: boolean;
    };
  };
  topicsToInclude: string[];
  topicsToExclude: string[];
  keywordsToInclude: string[];
  keywordsToExclude: string[];
  pagesToStrengthen: {
    url: string;
    type: "product" | "review" | "top10list" | "homepage" | "article";
  }[];
  writerProfile: string;
  notes: string;
}

export interface IDataTitle {
  _id: string;
  website: IWebsite;
  name: string;
  var: number;
  mod: number;
  uvp: number;
  draftTitle: string;
  publishedDate: Date;
}
export interface IExcludedKeywordSeed {
  id: string;
  seed: string;
  type: string;
  excluded_keywords_count: number;
  website_id: string;

  domain: string;
  created_at: Date;
  updated_at: Date;
}

export interface IDataPage {
  _id: string;
  website: IWebsite;
  name: string;
  pub: Date;
  mod: Date;
  clicks: number;
  maxClicks: number;
  imp: number;
  maxImp: number;
  none: number;
  conflicts: number;
  trend: IFile;
  groups: {
    _id: string;
    name: string;
    variations: number;
    maxVariations: number;
    vol: number;
    cli: number;
    imp: number;
    ctr: number;
    pos: number;
    status: "Targeted";
    "Not Targeted";
  }[];
  publishedDate: Date;
}

export interface ISerpData {
  _id: string;
  type: string;
  backLinksCount: number;
  dr: number;
  refDomainsCount: number;
  urlRating: number;
  keywordGroupId: number;
  order: number;
  createdAt: Date;
}
export interface IKeywordClassification {
  _id: string;
  keyword: string;
  type: IKeywordType;
  level: "1st" | "2nd" | "3rd" | "Amplifier";
  tags: ITag[];
  goals: IWebsiteGoal[];
  website: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface IKeywordType {
  _id: string;
  name: string;
  websiteId: string;
}

export enum eWidgetFieldWidth {
  Full = "100%",
  Half = "50%",
  Third = "33%",
  "20%" = "20%",
}

export enum eWidgetFieldDateFormat {
  READABLE = "YYYY.MM.DD",
  CALENDAR = "YYYY-MM-DD",
}

export enum eWidgetItemsAlignment {
  LEFT = "Left",
  CENTER = "Center",
  RIGHT = "Right",
}
export interface IWidgetHeadingFieldProps {
  title: string;
  caption: string;
  alignment: string;
}

export interface IWidgetParagraphFieldProps {
  text: string;
  showText: boolean;
}

export interface IWidgetLabelFieldProps {
  text: string;
  width: eWidgetFieldWidth;
}

export interface IWidgetInputFieldProps {
  label: string;
  placeholder: string;
  hint: string;
  required: boolean;
  width: eWidgetFieldWidth;
}

export interface IWidgetHiddenFieldProps {
  label: string;
  placeholder: string;
}

export interface IWidgetDateFieldProps extends IWidgetInputFieldProps {
  dateFormat: eWidgetFieldDateFormat;
}

export interface IWidgetCheckBoxFieldProps extends IWidgetInputFieldProps {
  otherOption: boolean;
  multipleSelection: boolean;
  randomizeOptions: boolean;
  showLabel: boolean;
  splitOptionsColumns: number;
  options: string[];
}

export interface IWidgetDropDownFieldProps extends IWidgetInputFieldProps {
  selectedOption: string;
  showLabel: boolean;
  options: string[];
}

export interface IWidgetConsentFieldProps {
  text: string;
  required: boolean;
  width: eWidgetFieldWidth;
}

export interface IWidgetStarRatingFieldProps {
  label: string;
  hint: string;
  numberOfOption: number;
  required: boolean;
  width: eWidgetFieldWidth;
  numberOfOptions: number;
}

export interface IWidgetNumbersRatingFieldProps {
  label: string;
  hint: string;
  required: boolean;
  width: eWidgetFieldWidth;
  leftCaption: string;
  rangeMax: number;
  rangeMin: number;
  rightCaption: string;
  showLabel: boolean;
}

export interface IWidgetSmileyRatingFieldProps {
  label: string;
  hint: string;
  required: boolean;
  showLabel: boolean;
}

export interface IWidgetLikeRatingFieldProps {
  label: string;
  hint: string;
  required: boolean;
  showLabel: boolean;
  thumbDown: string;
  thumbUp: string;
}

export interface IWidgetCallToActionAndReviewProps {
  tagline: string;
  reviewText: string;
  reviewUrl: string;
  buttonUrl: string;
  buttonText: string;
  openInNewTab: boolean;
  includeQueryParams: boolean;
}

export interface IWidgetImageProps {
  src: string;
  size: string;
  title: string;
  alt: string;
}

export interface IWidgetVideoProps {
  video: {
    videoId: string;
    url: string;
    width: number;
    height: number;
    type: string;
    structuredData: any;
  };
  thumbnail: { url: string; alt: string; title: string; caption: string; height: number; width: number };
}

export type IWidgetLogoFieldProps = IWidgetImageProps;

export type IWidgetImageFieldProps = IWidgetImageProps;

export interface IWidgetImagesFieldProps {
  medias: IWidgetImageProps[];
}

export interface IWidgetMediaFieldProps {
  type: "image" | "video";
  image: IWidgetImageProps;
  video: IWidgetVideoProps;
}

export interface IWidgetProsAndConsFieldProps {
  title: string;
  pros: string[];
  cons: string[];
}

export interface IWidgetExpandableTextSectionFieldProps {
  value: {
    content: string;
    title: string;
  }[];
}

export interface IWidgetBulletedListFieldProps {
  list: string[];
}
export interface IWidgetButtonFieldProps {
  openAction?: "product" | "call_to_action" | "custom" | "dialog";
  title: string;
  href: string;
  dialogId?: string;
  showArrow?: boolean;
  openInNewTab: boolean;
  includeQueryParams: boolean;
}

export type IWidgetValueProperty = {
  value: string;
};

export type IWidgetTitleFieldProps = IWidgetValueProperty;

export type IWidgetProductNameFieldProps = IWidgetValueProperty;

export type IWidgetProductScoreFieldProps = IWidgetValueProperty & {
  labelText: string;
};

export type IWidgetProductNumberFieldProps = IWidgetValueProperty;

export type IWidgetProductRatingFieldProps = IWidgetValueProperty;

export type IWidgetProductReviewsNumberFieldProps = IWidgetValueProperty & {
  labelText: string;
};

export type IWidgetShortDescriptionFieldProps = IWidgetValueProperty;

export type IWidgetTextFieldProps = IWidgetValueProperty;

export type IWidgetTagFieldProps = IWidgetValueProperty;

export type IWidgetInlineHTMLFieldProps = IWidgetValueProperty;

export type IWidgetIframeFieldProps = IWidgetValueProperty;

export type IWidgetIntroTextFieldProps = IWidgetValueProperty;

export type IWidgetTextCTAFieldProps = IWidgetValueProperty;

export type IWidgetTopicsWidgetFieldProps = {
  list: string[];
};

export type IWidgetDialogButtonFieldProps = (IWidgetDialogCloseDialogProps | IWidgetDialogRedirectToURLProps) & {
  fullWidth?: boolean;
};

export type IWidgetLinkDialogRedirectToURLProps = IWidgetDialogRedirectToURLProps;
export type IWidgetLinkDialogCloseDialogProps = IWidgetDialogCloseDialogProps;

export interface IWidgetDialogRedirectToURLProps extends IWidgetGenericClickActionFieldProps {
  action: "redirect_to_url";
  linkType: "url" | "email" | "phone";
  url: string;
  openInNewTab: boolean;
  includeQueryParams: boolean;
}
export interface IWidgetDialogCloseDialogProps extends IWidgetGenericClickActionFieldProps {
  action: "close_dialog";
}

export interface IWidgetGenericClickActionFieldProps {
  text: string;
  action: "redirect_to_url" | "close_dialog";
}

export interface IWidgetCouponFieldProps {
  code: string;
  clickToCopyText: string;
  copiedText: string;
}
export type IWidgetPriceFieldProps = {
  currency: { code: string; symbol: string };
} & IWidgetValueProperty;

export type IWidgetKeyDecisionFactorsFieldProps = {
  list: {
    title: string;
    description: string;
  }[];
};

export interface IWidgetInlineButtonFieldProps {
  text: string;
  url: string;
  showArrow?: boolean;
  openInNewTab: boolean;
  includeQueryParams: boolean;
  fullWidth: boolean;
}

export interface IWidgetInlineLinkFieldProps {
  text: string;
  url: string;
  openInNewTab: boolean;
  includeQueryParams: boolean;
}

export interface IWidgetRelatedArticlesFieldProps {
  list: {
    page?: IPage;
  }[];
}

export interface IWidgetHubspotFormFieldProps {
  region: string;
  portalId: string;
  formId: string;
}

export interface IWidgetCallToActionProps {
  openAction?: "product" | "call_to_action" | "custom" | "dialog";
  title?: string;
  href?: string;
  dialogId?: string;
  openInNewTab?: boolean;
  includeQueryParams?: boolean;
}

export type IWidgetFieldProperties = {
  elementId?: string;
  customCss?: string;
} & (
  | IWidgetHeadingFieldProps
  | IWidgetParagraphFieldProps
  | IWidgetLabelFieldProps
  | IWidgetInputFieldProps
  | IWidgetHiddenFieldProps
  | IWidgetDateFieldProps
  | IWidgetCheckBoxFieldProps
  | IWidgetConsentFieldProps
  | IWidgetDropDownFieldProps
  | IWidgetStarRatingFieldProps
  | IWidgetNumbersRatingFieldProps
  | IWidgetSmileyRatingFieldProps
  | IWidgetLikeRatingFieldProps
  | IWidgetCallToActionAndReviewProps
  | IWidgetMediaFieldProps
  | IWidgetImagesFieldProps
  | IWidgetLogoFieldProps
  | IWidgetImageFieldProps
  | IWidgetExpandableTextSectionFieldProps
  | IWidgetProsAndConsFieldProps
  | IWidgetBulletedListFieldProps
  | IWidgetButtonFieldProps
  | IWidgetTitleFieldProps
  | IWidgetProductNameFieldProps
  | IWidgetProductScoreFieldProps
  | IWidgetProductNumberFieldProps
  | IWidgetProductRatingFieldProps
  | IWidgetProductReviewsNumberFieldProps
  | IWidgetGenericClickActionFieldProps
  | IWidgetCouponFieldProps
  | IWidgetPriceFieldProps
  | IWidgetKeyDecisionFactorsFieldProps
  | IWidgetTextFieldProps
  | IWidgetTagFieldProps
  | IWidgetInlineHTMLFieldProps
  | IWidgetIframeFieldProps
  | IWidgetInlineButtonFieldProps
  | IWidgetInlineLinkFieldProps
  | IWidgetRelatedArticlesFieldProps
  | IWidgetIntroTextFieldProps
  | IWidgetTextCTAFieldProps
  | IWidgetTopicsWidgetFieldProps
  | IWidgetHubspotFormFieldProps
  | IWidgetCallToActionProps
);

export interface IWidgetField {
  _id?: string;
  title?: string;
  fieldId: string;
  form?: IWidget;
  type: string;
  visible: boolean;
  category?: string;
  properties?: IWidgetFieldProperties;
  settings?: {
    deletable: boolean;
    hideable: boolean;
    draggable: boolean;
    forcedPosition?: number;
    addableFields?: [];
  };
}

export interface IWidgetChangedValues {
  visible?: boolean;
  properties?: IWidgetFieldProperties;
}

export interface IResetToProductDefaults {
  hasChanges: boolean;
  onClick: () => void | null;
}

export interface IWidgetFieldActionPayload {
  field: IWidgetField;
  applyOnAllItems?: boolean;
  changedValues?: IWidgetChangedValues;
}

export enum eWidgetItemType {
  BLANK_BANNER = "blank_banner",
  IMAGE_BANNER = "image_banner",
  EMAIL_CAPTURE = "email_capture",
  RELATED_ARTICLES = "related_articles",
}

export interface IWidgetItem {
  _id: string;
  tempTitle?: string;
  itemId?: string;
  multistep: boolean;
  product: IProduct;

  name?: string;
  templateItemId?: string;
  type?: eWidgetItemType;
  visible?: boolean;

  steps: {
    fields: IWidgetField[];
  }[];
}

export interface IWidgetButtonProps {
  alignment?: "Left" | "Center" | "Right";
  textAlign?: string;
  fontSize?: string;
  textColor?: string;
  backgroundColor?: string;
  borderRadius?: string;
  padding?: string;
  showArrow?: boolean;
  text?: string;
  href?: string;
}

export interface IWidgetSettings {
  triggers: {
    onPageLoad: {
      value: boolean;
    };
    timeOnPage: {
      value: boolean;
      duration: string;
    };
    onScroll: {
      value: boolean;
      scrollPosition: string;
    };
    onScrollElement: {
      value: boolean;
      elementId: string;
    };
    onClick: {
      value: boolean;
      elementId: string;
    };
    onExitIntent: {
      value: boolean;
    };
  };
  pages: {
    value: "all" | "excluded" | "specific";
    excludedPages: string[];
    specificPages: string[];
  };
  frequency: "everytime" | "until_close" | "once" | "everyday" | "everyweek";
  layout?: {
    sticky?: boolean;
    marginTop?: number;
    spaceBetweenItems?: number;
    type?: "modal" | "slide_in" | "pane";
    position?: "left" | "center" | "right";
    verticalOffset?: number;
    horizontalOffset?: number;
    width?: number;
    alignment?:
      | "top_left"
      | "top"
      | "top_right"
      | "left"
      | "center"
      | "right"
      | "bottom_left"
      | "bottom"
      | "bottom_right";
    padding?: number;
    spacing?: number;
    showCloseButton?: boolean;
    closeButtonColor?: string;
    closeOnEsc?: boolean;
    showBackdrop?: boolean;
    closeOnBackdropClick?: boolean;
    backdropOpacity?: number;
    shadowRoot?: boolean;
  };
  displayDevices: ("desktop" | "tablet" | "mobile")[];
  customCss?: string;
}

export interface IWidget {
  _id: string;
  uuid?: string;
  uploadedId?: string;
  website: IWebsite;
  name: string;
  type: eWidgetType;
  subtype: eWidgetSubtype;
  status: eWidgetStatus;
  thumbnail: string;
  isInlineField?: boolean;

  items: IWidgetItem[];
  pages?: IPage[];
  campaigns?: ICampaign[];

  // template fields
  templateId?: string;
  templateName?: string;

  isTemplate?: boolean;
  templateWebsites?: IWebsite[];

  addableFields?: IWidgetField[];
  settings?: IWidgetSettings;

  analytics: {
    totalLoads: number;
    totalWidgetViews: number;
    totalClicks: number;
    totalInteractions: number;
  };

  appearance: {
    colorSchema: string;
    textColor: string;
    typography: string;
  };
  layout: {
    visualization: string;
    panePosition: string;
    formWidth: string;
  };
  header: string;
  button: IWidgetButtonProps;
  background: {
    defaultWhiteBackground: boolean;
    backgroundType: "Color" | "Gradient" | "Image";
    value: string;
    borderRadius: number;
    borderColor: string;
  };
  history: [
    {
      user: IAdminUser;
      status: string;
      date: Date;
    }
  ];
  createdBy: IAdminUser;
  createdAt: Date;
  updatedAt: Date;
}

export interface IWidgetAnalytics extends Document {
  _id: string;
  website: IWebsite;
  widget: IWidget;
  date: Date;

  totalLoads: number;
  loadsByPage: { [key: string]: number };
  totalWidgetViews: number;
  widgetViewsByPage: { [key: string]: number };
  totalClicks: number;
  clicksByPage: object;
  totalInteractions: number;
  interactionsByPage: object;
}

export enum eWidgetType {
  FORM = "Form",
  CUSTOM_WIDGET = "CustomWidget",
  DIALOG = "Dialog",
  INLINE = "Inline",
}

export enum eWidgetSubtype {
  PRODUCT_REVIEW = "ProductReview",
  CHARTICLE_TOP_PRODUCTS = "CharticleTopProducts",
  PRODUCT_CTA = "ProductCTA",
  PRODUCT_CTA_BANNER = "ProductCTABanner",
  RELATED_CONTENT = "RelatedContent",
  TEXT_CTA = "TextCTA",
  PRODUCT_RATING = "ProductRating",
  PRODUCTS_CAROUSEL = "ProductsCarousel",
}

export enum eWidgetAction {
  delete = "Delete",
  update = "Update",
  publish = "Publish",
  export_template = "ExportTemplate",
  delete_permanently = "DeletePermanently",
  duplicate = "Duplicate",
  restore = "Restore",
}
export enum eWidgetStatus {
  Draft = "Draft",
  Active = "Active",
  Deleted = "Deleted",
}

export enum eWidgetFieldType {
  SCORE = "score",
  CALL_TO_ACTION = "call_to_action",
  BULLETED_LIST = "bulleted_list",
  BUTTON = "button",
  MEDIA = "media",
  MEDIAS = "medias",
  TITLE = "title",
  PRODUCT_NUMBER = "product_number",
  PRODUCT_NAME = "product_name",
  RATING = "rating",
  PROS_CONS = "pros_cons",
  EXPANDABLE_TEXT_SECTION = "expandable_text_section",
  IMAGE = "image",
  LOGO = "logo",
  ICON = "icon",
  AVATAR = "avatar",
  PRODUCT_NUMBER_OF_REVIEWS = "product_number_of_reviews",
  SHORT_DESCRIPTION = "short_description",
  TEXT = "text",
  COUPON = "coupon",
  LINK = "link",
  FORM = "form",
  PRICE = "price",
  KEY_DECISION_FACTORS = "key_decision_factors",
  TAG = "tag",
  INLINE_HTML = "inline_html",
  IFRAME = "iframe",
  RELATED_ARTICLES = "related_articles",
  INTRO_TEXT = "intro_text",
  URL = "url",
  TEXT_CTA = "text_cta",
  TOPICS = "topics",
  SECONDARY_BUTTON = "secondary_button",
  PRIMARY_BUTTON = "primary_button",
  HUBSPOT_FORM = "hubspot_form",
}

export const eWidgetProductDefaultFields = [
  eWidgetFieldType.PRODUCT_NAME,
  eWidgetFieldType.BUTTON,
  eWidgetFieldType.SCORE,
  eWidgetFieldType.RATING,
  eWidgetFieldType.CALL_TO_ACTION,
  eWidgetFieldType.LOGO,
  eWidgetFieldType.ICON,
  eWidgetFieldType.BULLETED_LIST,
  eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS,
  eWidgetFieldType.MEDIAS,
  eWidgetFieldType.SHORT_DESCRIPTION,
  eWidgetFieldType.PRICE,
  eWidgetFieldType.SECONDARY_BUTTON,
  eWidgetFieldType.PRIMARY_BUTTON,
  eWidgetFieldType.MEDIA,
  eWidgetFieldType.TOPICS,
];

export enum eFormDesignLayout {
  Pane = "pane",
  Embedded = "embedded",
}

export enum eFormBackgroundOption {
  Color = "Color",
  Gradient = "Gradient",
  Image = "Image",
  Video = "Video",
}

export interface IKeywordHeadingCluster {
  domain: string;
  main_keyword: string;
  keyword: string;
  is_raw: boolean;
}

export interface IKeywordSerp {
  name: string;
  serp_results: string;
  serp_date: Date;
}

export interface IKeywordSerpCleaned {
  title: string;
  url: string;
}

export interface IOutlinePages {
  domain: string;
  main_keyword: string;
  page: string;
  total_traffic: number;
  keyword_traffic: number;
}

export interface IKeywordStrategy {
  domain: string;
  keyword: string;
  conversion_potential: string;
  suggested_title: string | null;
  type: string;
  topics: string;
  search_intent: string;
  template: string;
  feedback?: string;
  post_type: string;
  impressions?: number;
  clicks?: number;
  ctr?: number;
  position?: number;
  volume?: number;
  createdAt: Date;
  internal_note?: string;
}

export interface IKeywordStrategyWithTopics extends IKeywordStrategy {
  topicGroup: IKeywordStrategy[];
}

export interface IKeywordHeadingClusterWithStrategy extends IKeywordHeadingCluster {
  strategy?: IKeywordStrategy;
}
export interface KeywordAggregationOption {
  _id: string;
  name: string;
}

export interface IProposal {
  website: IWebsite;
  annualDiscount: string;
  selectedContentLevel: {
    _id: string;
    name: string;
  };
  plans: [
    {
      _id: string;
      name: string;
      price: string;
      discount: string;
    }
  ];
  contentLevels: [
    {
      _id: string;
      name: string;
    }
  ];
  contentItems: [
    {
      _id: string;
      name: string;
      status: boolean;
      quantity: number;
      prices: [
        {
          contentLevelId: string;
          price: string;
        }
      ];
    }
  ];
}

export interface IExpert {
  _id: string;
  active: boolean;
  website: IWebsite;
  externalId: string;
  user: IAdminUser;

  slug: string;
  profilePic: IFile;
  firstName: string;
  lastName: string;
  title: string;
  location: string;

  jobTitle: string;
  experienceInYears: number;
  companyName: string;
  companyURL: string;

  previousExperience: {
    companyPic: IFile;
    companyName: string;
    positionTitle: string;
    startMonth: number;
    startYear: number;
    endMonth?: number;
    endYear?: number;
  }[];

  shortDescription: string;
  expertise: string[];

  phoneNumber: string;
  email: string;
  educationAndCertifications: {
    type: string;
    education: string;
    year: number;
    institute: string;
  }[];
  socialLinks: {
    type: string;
    url: string;
    active: boolean;
  }[];

  visibility: {
    previousExperience: boolean;
    education: boolean;
    certifications: boolean;
    socialLinks: boolean;
  };

  evaluation: {
    videoAssessment: {
      englishProficiency: number;
      willingToBeFilmed: number;
      videoCompatibility: number;
      internetConnection: number;
      mic: number;
      camera: number;
      lighting: number;
    };
    expertise: {
      subjectMatterKnowledge: number;
      currentIndustryTrends: number;
      practicalPersonalExamples: number;
      conveyConceptsConcisely: number;
    };
    onScreenPresence: {
      telegenicAppearance: number;
      charismaticPersonality: number;
      environmentAndSetting: number;
      backgroundNoise: number;
    };
    easeOfCollaboration: {
      punctuality: number;
      preparedness: number;
      responsiveness: number;
    };
    formatCompatibility: {
      interviews: number;
      tutorials: number;
      productReviewsAndDemos: number;
      exercises: number;
    };
  };

  uploadedUrl: string;

  createdBy: IAdminUser;
  createdDate: Date;
  deletedBy: IAdminUser;
  deletedDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;
}

export interface ILandingPage {
  _id: string;
  name: string;
  type: string;
  metaTitle: string;
  metaDescription: string;
  index: boolean;
  active: boolean;
  slug: string;
  status: "DRAFT" | "PUBLISHED";
  content: IGatedContentLandingPage;
  website: IWebsite;
  createdBy: IAdminUser;
  createdAt: Date;
  publishedBy: IAdminUser;
  publishedAt: Date;
  firstPublishedAt: Date;
  externalId: string;
}

export interface IGatedContentLandingPage {
  topFirstText: string;
  topTitle: string;
  topDescription: string;
  backgroundImage: {
    _id: string;
    url: string;
    cdnUrl: string;
    alt: string;
    title: string;
    caption: string;
  };
  emailCaptureTitle: string;
  emailPlaceholder: string;
  disclaimerText: string;
  privacyPolicyUrl: string;
  buttonText: string;
  emailCaptureImage: {
    _id: string;
    url: string;
    cdnUrl: string;
    alt: string;
    title: string;
    caption: string;
  };
  descriptionSectionActive: boolean;
  descriptionSectionTitle: string;
  descriptionSectionList: {
    title: string;
    description: string;
  }[];
  faqActive: boolean;
  faqTitle: string;
  faqList: {
    question: string;
    answer: string;
  }[];
}

export interface ISourceFile {
  _id: string;
  fileName: string;
  url: string;
  cdnUrl: string;
  thumbnailUrl: string;
  mimeType: string;
  extType: string;
  size: string;
  hash: string;
  bucket: string;
  key: string;

  title: string;
  description: string;
  caption: string;

  languageCode: string;
  type: "Audio" | "Video";
  speakers: number;
  duration: number;

  website: IWebsite;

  externalId: string;

  transcribeJobName: string;
  transcribeError: string;
  transcribedStart: Date;
  transcribedEnd: Date;

  transcriptionRichText: string;
  rephrasedTranscriptionRichText: string;
  rephrasedTranscriptionTitle: string;

  transcriptionHistory: {
    _id: string;
    transcriptionRichText: string;
    rephrasedTranscriptionRichText: string;
    rephrasedTranscriptionTitle: string;
    comment: string;
    createdBy: IAdminUser;
    createdAt: Date;
    updatedAt: Date;
  }[];

  chatGptRephraseStart: Date;
  chatGptRephraseEnd: Date;
  chatGptRephraseError: string;

  transcript: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum eVideoProjectAction {
  create = "Create",
  save = "Save",
  export = "Export",
  delete = "Delete",
  deleteForever = "DeleteForever",
  trim = "Trim",
  brief = "Brief",
  firstCheck = "FirstCheck",
  edit = "Edit",
  cards = "Cards",
  captions = "Captions",
  music = "Music",
  secondCheck = "SecondCheck",
  review = "Review",
  done = "Done", // final state
}

export enum eVideoProjectState {
  trim = "Trim",
  brief = "Brief",
  firstCheck = "FirstCheck",
  edit = "Edit",
  cards = "Cards",
  captions = "Captions",
  music = "Music",
  secondCheck = "SecondCheck",
  review = "Review",
  done = "Done", // final state
  deleted = "Deleted", // final state
}

export enum eVideoProjectTool {
  TRIM = "Trim",
  FRAME = "Frame",
  ADJUST = "Adjust",
  TRANSITION = "Transition",
  ELEMENTS = "Elements",
  CAPTIONS = "Captions",
  MUSIC = "Music",
  REVIEW = "Review",
}

export interface IVideoProjectState {
  _id?: string;
  date: Date;
  action: eVideoProjectAction;
  actionReason?: string;
  actionUser: IAdminUser;
  state: eVideoProjectState;
  subState?: string;
}

export interface IVideoProject {
  _id: string;
  type: string;
  website: IWebsite;
  variant: string;
  locale: string;

  category: ICategory;
  tags: ITag[];

  title: string;

  videos: ISourceFile[];
  images: IFile[];
  audios: ISourceFile[];

  exportedVideos: [
    {
      video: ISourceFile;
      date: Date;
      width: number;
      height: number;
      platform: "youtube" | "facebook" | "instagram" | "twitter" | "tiktok" | "linkedin";
    }
  ];

  timelines: IVideoProjectTimeline[];

  customFields: object;

  currentState: IVideoProjectState;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishAction: "Create" | "Publish" | "Update";
  publishedDate: Date;
  deletedBy: IAdminUser;
  deletionDate: Date;

  dueDate: Date;
  quotaDate: Date;

  externalId: string;
}

export interface ITimelineComment {
  _id: string;
  user: IAdminUser;
  text: string;
  replies: ITimelineComment[];
  visibility: "admins" | "editors" | "clients";
  createdAt: Date;
  updatedAt?: Date;
}

export interface IVideoProjectTimeline {
  _id: string;
  selected: boolean;
  rows: ITimelineRow[];
  createdBy: IAdminUser;
  createdDate: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITimelineState {
  time: number;
  isPlaying: boolean;
  zoom: number;
  zoomWidth: number;
  scale: number;
  volume: number;
  isBuffering: boolean;
  playAfterBuffering: boolean;
}

export interface ITimelineAction extends TimelineAction {
  _id?: string;
  deleted?: boolean;
  trimStart?: number;
  trimEnd?: number;
  video?: IVideoElement;
  comment?: ITimelineComment;
}

export interface ITimelineRow extends TimelineRow {
  type: "video" | "audio" | "image" | "canvas" | "comment";
  actions: ITimelineAction[];
}

export interface IVideoElement {
  id: string;
  src: string;
  file: ISourceFile;
  duration: number;
  x: number;
  y: number;
  startTime: number;
  endTime: number;
  width: number;
  height: number;
}

export interface IReport {
  active: boolean;
  name: string;
  type: string;
  data: any[];
}

export interface IReportsCustomReportRow {
  _id: string;
  id: string;
  name: string;
  graphType: string;
  customSettings: any;
}

export interface IWebsiteGoal {
  _id: string;
  name: string;
  value?: number;
}

export interface IReportSettingsValue {
  businessDefinition?: {
    businessType?: string;
    companyType?: string;
    industry?: string;
  };
  cvrFunnelStage?: { [key: string]: number };
  goals: IWebsiteGoal[];
}
export interface IReportsSettings {
  website: IWebsite;
  ctaSettings: {
    includeOutboundLinkClick: boolean;
    includeInternalLinkClick: boolean;
    internalLinksLike: string[];
    destinationLike: string[];
  };
  customReports: IReportsCustomReportRow[];
  reportsView: {
    searchConsole: boolean;
    searchConsoleEntail: boolean;
    wordsCount: boolean;
    impressions: boolean;
    impressionsEntail: boolean;
    articleCount: boolean;
    indexedQueries: boolean;
    indexedQueriesEntail: boolean;
    trafficTrend: boolean;
    ctaClicks: boolean;
    ctaClicksEntail: boolean;
    topPages: boolean;
    topPagesEntail: boolean;
    topQueries: boolean;
    topQueriesEntail: boolean;
    topTopics: boolean;
    topTopicsEntail: boolean;
    ctaByUrl: boolean;
    ctaByUrlEntail: boolean;
    ctaByDestination: boolean;
    ctaByDestinationEntail: boolean;
    ctaByLandingPage: boolean;
    ctaByLandingPageEntail: boolean;
  };
  value: IReportSettingsValue;
}

export enum ILinksSplitTestState {
  DRAFT = "Draft",
  ACTIVE = "Active",
  ACTIVE_WINNER = "Active-winner",
  ARCHIVED = "Archived",
}

export interface ILinksSplitTestGroup {
  variant: string;
  name: string;
  description: string;
  url: string;
  weight: number;
  type: string;
  visits?: number;
}
export interface ILinksSplitTest {
  _id: string;
  name: string;
  description: string;
  itemNumber: string;

  allProducts: boolean;
  products: IProduct[];
  productNames: string[];
  productItemNumbers: string[];

  countriesSelector: "AllCountries" | "IncludedCountries" | "ExcludedCountries";
  countries: string[];

  analytics?: {
    clicks: number;
    lastClickDate: Date;
    groups: [
      {
        visits: number;
        variant: string;
      }
    ];
  };

  groups: ILinksSplitTestGroup[];

  website: IWebsite;

  state: ILinksSplitTestState;
  publishTime: Date;
  externalId: string;

  createdBy: IAdminUser;
  createdDate: Date;
  publishedBy: IAdminUser;
  publishedDate: Date;
}

export interface IAnalyticsSession {
  id: string;
  device_id: string;
  website_id: string;
  hostname: string;
  domain: string;
  entry_page: string;
  exit_page: string;
  is_bounce: boolean;
  duration: number;
  page_views: number;
  referrer: string;
  referrer_source: string;
  utm_medium: string;
  utm_source: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
  browser: string;
  browser_version: string;
  browser_language: string;
  country: string;
  city: string;
  created_at: Date;
  updated_at: Date;
}

export interface IAnalyticsDevice {
  id: string;
  website_id: string;
  device_uuid: string;
  device_type: string;
  user_agent: string;
  model: string;
  manufacturer: string;
  operating_system: string;
  operating_system_version: string;
  screen_width: string;
  screen_height: string;
  first_page: string;
  last_seen: Date;
  created_at: Date;
}
export interface IAnalyticsEvent {
  id: string;
  name: string;
  session_id: string;
  device_id: string;
  website_id: string;
  event_data: object;
  url: string;
  domain: string;
  referrer: string;
  referrer_source: string;
  utm_medium: string;
  utm_source: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
  query_params: object;
  created_at: Date;
}

export interface IFunnelColumn {
  field: string;
  table: string;
}

export interface IFunnelStepFilter {
  field: string;
  table: string;
  condition: string;
  value: string;
}

export interface IAnalyticsEventRow extends IAnalyticsEvent, IAnalyticsSession, IAnalyticsDevice {
  goal_id?: string;
}

export interface IWidgetContainerPosition {
  selector: {
    type: "css" | "XPath";
    value: string;
    shortestCssSelector: string;
  };
  position: "above" | "below";
  margin: {
    top: number;
    bottom: number;
  };
}

export enum eCampaignAction {
  SAVE = "Save",
  PUBLISH = "Publish",
  UNPUBLISH = "Unpublish",
  DELETE = "Delete",
  RESTORE = "Restore",
  DELETE_PERMANENTLY = "DeletePermanently",
  AddScript = "AddScript",
}

export enum eCampaignStatus {
  DRAFT = "Draft",
  ACTIVE = "Active",
  DELETED = "Deleted",
}

export interface ICampaignAnalytics extends Document {
  _id: string;
  website: IWebsite;
  campaign: ICampaign;
  date: Date;

  totalViews: number;
  viewsByPage: { [key: string]: number };
  totalWidgetViews: number;
  widgetViewsByPageAndWidget: object;
  totalClicks: number;
  clicksByPageAndWidget: object;
  totalInteractions: number;
  interactionsByPageAndWidget: object;
}

export interface ICampaign {
  _id: string;
  uploadedId?: string;
  status: eCampaignStatus;
  website: IWebsite;
  name: string;
  previewUrl: string;
  rules: {
    type: "include" | "exclude" | "exact" | "include_list" | "exclude_list";
    value: string;
    operator: "and" | "or";
  }[];
  widgetContainers: {
    containerId: string;
    enabled: boolean;
    widgetFilter: {
      value: "all" | "specific";
      widget?: IWidget;
    };
    position: {
      sameDesktopMobilePosition: boolean;
      desktop_mobile: IWidgetContainerPosition;
      desktop: IWidgetContainerPosition & { enabled: boolean };
      mobile: IWidgetContainerPosition & { enabled: boolean };
    };
  }[];
  createdBy: IAdminUser;
  createdAt: Date;
  updatedAt: Date;
  analytics: {
    totalViews: number;
    totalWidgetViews: number;
    totalClicks: number;
    totalInteractions: number;
  };
  history: [
    {
      user: IAdminUser;
      status: string;
      date: Date;
    }
  ];
}

export interface IEvent {
  condition: "and" | "or";
  value: string;
  filters: {
    column: string;
    condition: "is" | "isNot" | "isSet" | "isNotSet" | "contains" | "doesNotContain";
    value: string[];
    field: string;
    table: string;
    eventTable: string;
  }[];
}

export interface IGoalType {
  _id: string;
  type:
    | "add_to_cart"
    | "add_to_wishlist"
    | "complete_registration"
    | "contact"
    | "customize_product"
    | "donate"
    | "find_location"
    | "initiate_checkout"
    | "lead"
    | "purchase"
    | "schedule"
    | "search"
    | "start_trial"
    | "submit_application"
    | "subscribe"
    | "view_content";
  name: string;
  description: string;
  code: string;
  events: IEvent[];
}

export interface IGoal {
  _id: string;
  goalId?: string;
  website: IWebsite;
  goalType: IGoalType;
  name: string;
  description: string;
  goalTracking: "rule" | "code";
  value: {
    currency: {
      code: string;
      symbol: string;
    };
    value: string;
    type: "estimated" | "transaction";
  };
  events: IEvent[];
  createdAt: Date;
  updatedAt: Date;
}

export enum eMpEvent {
  pageView = "page-view",
  onboardingPersonaliseButtonClick = "p onboarding personalize button click",
  websiteButtonClick = "w button click",
  websiteLinkClick = "w link click",
  signupError = "w signup failed",
  signupSuccess = "w signup success",
  onboardingAccountSuccess = "p onboarding account success",
  onboardingAccountError = "p onboarding account failed",
  croCampaignButtonClick = "p cro campaign button click",
  croEmbedButtonClick = "p cro embed button click",
  onboardingPreviewButtonClick = "p onboarding preview button click",
  croInsertWidgetButtonClick = "p cro insert widget button click",
  croInstallButtonClick = "p cro install button click",
  onboardingOptimizeButtonClick = "p onboarding optimize button click",
  onboardingAccountButtonClick = "p onboarding account button click",
}

export enum eEntailProducts {
  cms = "cms",
  cro = "cro",
}

export interface IFunnelBreakdownColumn {
  label: string;
  value: {
    field: string;
    table: string;
    eventTable?: string;
    like?: boolean;
    getLabel?: (value: any) => string;
  };
  autocomplete?: {
    field: string;
    table: string;
  };
}

export interface IDomElement {
  fullXPath: string;
  shortestCssSelector: string;
  xpath: string;
  id?: string;
  width: string;
  height: string;
  left: string;
  top: string;
  tagName?: string;
  tagType?: string;
  isClickable: boolean;
  className: string;
  attributes: any;
  nodeType?: string;
  childrenCount: number;
  children: {
    tagName?: string;
    className: string;
    nodeType?: string;
  }[];
}
