import { Button, Input, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { I18n } from "react-redux-i18n";
import TextAreaWidgetBaseElement from "../../baseElements/TextAreaWidgetBaseElement";
import { find } from "lodash";
import { ArrowForward } from "@material-ui/icons";
import { Widget } from "../../WithWidgets";
import { IProduct, IWebsite, IWebsiteThemeProps } from "../../../../../reducers/constants/objectTypes";
import RatingStarIcon from "../../../../../icons/RatingStarIcon";
import ImagePicker from "../../../../../components/ImagePicker";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: (props: PropTypes) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    borderRadius: 5,
    ...props.element.data?.backgroundCss,
  }),
  productName: (props: PropTypes) => ({
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(18),
    ...props.element.data?.productNameCss,
  }),
  logoAndRatingWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  ratingWrapper: {
    marginLeft: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  ctaButtonInput: {
    lineHeight: 1,
    textAlign: "start",
  },
  ctaButton: (props: PropTypes) => ({
    whiteSpace: "pre",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightLight as any,
    marginLeft: 10,
    height: "auto",
    minHeight: 50,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: "10px 30px",
    borderRadius: 30,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    ...props.element.data?.buttonCss,
  }),
  rating: {
    display: "flex",
    alignItems: "center",
  },
  ratingStarIcon: {
    marginRight: 10,
  },
  ratingInputRoot: {
    width: 50,
    marginRight: 10,
  },
  reviewsInputRoot: {
    width: 65,
    marginRight: 10,
  },
  numOfReviewsText: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  selectedWebsite: IWebsite;
  products: IProduct[];
  theme: IWebsiteThemeProps;
};

export const getRawText = (n: Node) => {
  if (!n.data) {
    return "";
  }
  return [n.data.productName || ""]?.join("\n") || "";
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  theme: state.home.selectedWebsite.configurations.theme,
});

const CallToActionWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, selectedWebsite, products = [], theme } = props;
  const editor = useSlateStatic();

  const handleChange = (data) => {
    console.log("handleChange", data, ReactEditor.findPath(editor as ReactEditor, element));
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
    console.log("handleChange done");
  };
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  const defaultData = {
    productNameCss: {
      fontSize: 18,
      color: theme.palette.text.primary,
    },
    backgroundCss: {
      backgroundColor: theme.palette.secondary.main,
    },
    buttonCss: {
      fontSize: 16,
      margin: "0 0 0 10px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
      padding: "10px 30px",
      borderRadius: 30,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },
    },
  };

  React.useEffect(() => {
    handleChange({ ...defaultData, ...element.data });
  }, []);

  return (
    <div {...attributes}>
      <Widget title={I18n.t("rich_text_editor.custom_components.call_to_action")} element={element} editable>
        <div className={classes.wrap}>
          <div className={classes.logoAndRatingWrapper}>
            <ImagePicker
              rounded
              imageWidth={50}
              image={
                element.data.productLogo || (product?.logo ? ({ url: product?.logo } as IFile) : product?.logoFile)
              }
              selectedWebsite={selectedWebsite}
              onImageChanged={(productLogo) => {
                if (!productLogo || typeof productLogo === "string") {
                  handleChange({ ...element.data, productLogo });
                } else {
                  handleChange({
                    ...element.data,
                    productLogo: {
                      _id: productLogo._id,
                      cdnUrl: productLogo.cdnUrl,
                      url: productLogo.url,
                      title: productLogo.title,
                      alt: productLogo.alt,
                      caption: productLogo.caption,
                    },
                  });
                }
              }}
            />
            <div className={classes.ratingWrapper}>
              <TextAreaWidgetBaseElement
                className={classes.productName}
                text={element.data.productName || product?.name || ""}
                placeholder={I18n.t("rich_text_editor.call_to_action.product_name_ph")}
                onChange={(productName) => handleChange({ ...element.data, productName })}
              />
              <div className={classes.rating}>
                <RatingStarIcon active className={classes.ratingStarIcon} />
                <Input
                  value={element.data?.productRating || product?.rating || ""}
                  placeholder={I18n.t("rich_text_editor.call_to_action.product_rating_ph")}
                  type="number"
                  inputProps={{ step: "0.1", min: 1, max: 5 }}
                  disableUnderline
                  classes={{
                    root: classes.ratingInputRoot,
                  }}
                  onChange={(event) => handleChange({ ...element.data, productRating: event.target.value })}
                />
                <div className={classes.rating}>
                  <Typography variant={"body1"}>{"( "}</Typography>
                  <Input
                    value={element.data.numOfReviews || product?.numOfReviews || ""}
                    placeholder={I18n.t("rich_text_editor.call_to_action.reviews_number")}
                    type="number"
                    inputProps={{ min: 0 }}
                    disableUnderline
                    classes={{
                      root: classes.reviewsInputRoot,
                      input: classes.numOfReviewsText,
                    }}
                    onChange={(event) => handleChange({ ...element.data, numOfReviews: event.target.value })}
                  />
                  <Typography className={classes.numOfReviewsText} variant={"body1"}>
                    {`${I18n.t("rich_text_editor.call_to_action.reviews")})`}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <Button endIcon={<ArrowForward />} className={classes.ctaButton}>
            <span className={classes.ctaButtonInput}>
              {element.data?.buttonText ||
                element.data?.callToActionText ||
                product?.callToAction ||
                I18n.t("rich_text_editor.call_to_action.cta_button_text")}
            </span>
          </Button>
        </div>
        {children}
      </Widget>
    </div>
  );
};

export default connect(mapStateToProps)(CallToActionWidget);
