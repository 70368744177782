import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { web } from "../../../helpers/urlHelper";
import EditorialPolicy from "../../Manager/Pages/EditorialPolicy/EditorialPolicy";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const EditorialPages = () => (
  <div>
    <MHeaderMetaTags title={I18n.t(`meta_tags.page_builder.editorial_pages.title`)} />
    <Switch>
      <Route
        exact={false}
        path={web.editorialPages("editorial-policy")}
        render={() => <EditorialPolicy type={"EDITORIAL_PROCESS"} />}
      />
      <Route
        exact={false}
        path={web.editorialPages("community-guidelines")}
        render={() => <EditorialPolicy type={"COMMUNITY_GUIDELINES"} />}
      />
      <Route
        exact={false}
        path={web.editorialPages("content-principles")}
        render={() => <EditorialPolicy type={"CONTENT_PRINCIPLES"} />}
      />
      <Route
        exact={false}
        path={web.editorialPages("editorial-team")}
        render={() => <EditorialPolicy type={"EDITORIAL_TEAM"} />}
      />
      <Route
        exact={false}
        path={web.editorialPages("fact-checked")}
        render={() => <EditorialPolicy type={"FACT_CHECKED"} />}
      />
      <Redirect exact from={"*"} to={web.editorialPages("editorial-policy")} />
    </Switch>
  </div>
);

export default EditorialPages;
