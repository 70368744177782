import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme: Theme) => ({
  summaryWrapper: {},
  prosAndCons: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  pros: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
  },
  cons: {
    marginLeft: 40,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      marginTop: 20,
    },
  },
  prosWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginRight: 20,
    marginBottom: 20,
  },
  consWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    marginLeft: 20,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  prosTitle: (props: PropTypes) => ({
    flex: 1,
    marginRight: 20,
    lineHeight: theme.typography.pxToRem(32),
    borderBottom: `solid 1px ${theme.palette.divider}`,
    paddingBottom: 10,
    marginBottom: 30,
    textTransform: "capitalize",
    ...props.element.titleCss,
  }),
  consTitle: (props: PropTypes) => ({
    flex: 1,
    marginLeft: 20,
    lineHeight: theme.typography.pxToRem(32),
    borderBottom: `solid 1px ${theme.palette.divider}`,
    paddingBottom: 10,
    marginBottom: 30,
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
    ...props.element.titleCss,
  }),
  checkIcon: (props: PropTypes) => ({
    fill: "#35c376",
    marginRight: 10,
    ...props.element.data.prosCss,
  }),
  clearIcon: (props: PropTypes) => ({
    fill: "red",
    marginRight: 10,
    ...props.element.data.consCss,
  }),
  prosConsText: {
    lineHeight: 1.5,
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
};

const NiProsConsTableReadOnly = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element } = props;
  console.log(element.data);
  return (
    <div {...attributes} className={classes.summaryWrapper} contentEditable={false}>
      <div className={classes.prosAndCons}>
        <div className={classes.pros}>
          <Typography className={classes.prosTitle} variant={"subtitle1"}>
            {I18n.t("superbnode/pages.product.review.pros")}
          </Typography>
          {element.data.pros?.map((pro) => (
            <div key={`pro_${pro}`} className={classes.prosWrapper}>
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.prosConsText} variant={"body1"}>
                {pro}
              </Typography>
            </div>
          ))}
        </div>
        <div className={classes.cons}>
          <Typography className={classes.consTitle} variant={"subtitle1"}>
            {I18n.t("superbnode/pages.product.review.cons")}
          </Typography>
          {element.data.cons?.map((con) => (
            <div key={`con_${con}`} className={classes.consWrapper}>
              <ClearIcon className={classes.clearIcon} />
              <Typography className={classes.prosConsText} variant={"body1"}>
                {con}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      {children}
    </div>
  );
};

export default NiProsConsTableReadOnly;
