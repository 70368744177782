import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { ePageState, ePageType, ICategory, IPageCluster, IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import { PUBLISHED_PAGES, WORKFLOW_PUBLISHED_CLUSTER } from "../../../reducers/constants/actionTypes";
import useDebounce from "../../../hooks/useDebounce";
import PublishClusterItemList from "./components/PublishClusterListItem";
import Published from "../Published/Published";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarPublishedTabTypeFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPublishedTabTypeFilter";
import MTableToolbarPostTypeFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPostTypeFilter";
import MTableToolbarCategoriesFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarCategoriesFilter";
import MTableToolbarPublishStatusFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPublishStatusFilter";
import { api } from "../../../helpers/urlHelper";
import ability from "../../../casl/ability";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";

type PropTypes = {
  pagesLoaded: boolean;
  selectedWebsite: IWebsite;
  clustersLoaded: boolean;
  clusters: IPageCluster[];
  refreshClusters: boolean;
  paginationPagesCount: number;
  categories: ICategory[];

  handleClustersLoaded: Function;
  pagesCount: number;
  postType: { _id: ePageType; name: string }[];
  category: ICategory[];
  publishStatus: { _id: string; name: string };
  tabType: { _id: number; name: string };
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  searchText: string;
  onFilterChanged: (payload) => void;
  onFilterPublishedChanged: (payload) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  clustersLoaded: state.workFlowPublishedCluster.loaded,
  clusters: state.workFlowPublishedCluster.items,
  refreshClusters: state.workFlowPublishedCluster.refreshItemList,
  paginationPagesCount: state.workFlowPublishedCluster.paginationPagesCount,
  pagesLoaded: state.workFlowPublishedCluster.pagesLoaded,
  categories: state.category.categories,
  pagesCount: state.workFlowPublishedCluster.pagesCount,
  postType: state.workFlowPublishedCluster.filter.postType,
  category: state.workFlowPublishedCluster.filter.category,
  publishStatus: state.workFlowPublishedCluster.filter.publishStatus,
  searchText: state.workFlowPublishedCluster.filter.searchText,
  tabType: state.publishedPages.filter.tabType,
  sortField: state.workFlowPublishedCluster.filter.sortField,
});

const mapDispatchToProps = (dispatch) => ({
  handleClustersLoaded: (payload) => {
    dispatch({ type: WORKFLOW_PUBLISHED_CLUSTER.LOADED, payload });
  },
  onFilterChanged: (payload) =>
    dispatch({ type: WORKFLOW_PUBLISHED_CLUSTER.WORKFLOW_PUBLISHED_CLUSTER_ON__FILTER_CHANGED, payload }),
  onFilterPublishedChanged: (payload) => dispatch({ type: PUBLISHED_PAGES.PUBLISHED_PAGES_ON_FILTER_CHANGED, payload }),
});

const PublishedCluster = (props: PropTypes) => {
  const {
    selectedWebsite,
    handleClustersLoaded,
    refreshClusters,
    clusters,
    clustersLoaded,
    paginationPagesCount,
    categories,
    pagesLoaded,
    pagesCount,
    postType,
    category,
    publishStatus,
    sortField,
    tabType,
    searchText,
    onFilterChanged,
    onFilterPublishedChanged,
  } = props;
  const states = [ePageState.published];
  const [page, setPage] = useState<number>(1);
  const [paginationLimit, setPaginationLimit] = useState(100);

  const debouncedSearchText = useDebounce(searchText, 500);
  const cleanPostTypes = postType.map((p) => p._id);
  const postCategories = category.map((cat) => cat._id);
  const publishStatusClean = publishStatus?.name;

  React.useEffect(() => {
    if (tabType._id === 0) {
      return;
    }
    if (!selectedWebsite) {
      return;
    }

    handleClustersLoaded(
      agent.Pages.getPublishedClusters(
        selectedWebsite,
        page,
        paginationLimit,
        states,
        sortField.field,
        sortField.direction,
        cleanPostTypes,
        postCategories,
        publishStatusClean,
        searchText
      )
    );
  }, [
    selectedWebsite,
    refreshClusters,
    page,
    paginationLimit,
    postType,
    debouncedSearchText,
    category,
    publishStatus,
    sortField,
    tabType,
  ]);

  const exportToCSV = () => {
    const API_ROOT = api.api();

    const endpoint = `/pages/${selectedWebsite._id}/published/csv?${
      cleanPostTypes ? `&postTypes=${cleanPostTypes.join(",")}` : ""
    }${postCategories ? `&postCategories=${postCategories.join(",")}` : ""}${
      publishStatusClean ? `&publishStatus=${publishStatusClean}` : ""
    }&searchText=${searchText}`;

    return ability.can("editor", "role") ? `${API_ROOT}${endpoint}` : null;
  };

  return tabType._id === 1 ? (
    <MTableWrapper>
      <MTableToolbar
        csvURL={exportToCSV()}
        searchText={searchText}
        cmsCreateButton
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
      >
        <MTableToolbarPublishedTabTypeFilter
          tabType={tabType}
          handleTabTypeSelected={(value) => onFilterPublishedChanged({ tabType: value })}
        />
        <MTableToolbarPostTypeFilter
          postTypes={postType}
          selectedWebsite={selectedWebsite}
          handlePostTypesSelected={(value) => onFilterChanged({ postType: value })}
        />
        <MTableToolbarCategoriesFilter
          handleCategoriesSelected={(value) => onFilterChanged({ category: value })}
          selectedCategories={category}
          categories={categories}
        />
        <MTableToolbarPublishStatusFilter
          handlePublishStatusSelected={(value) => onFilterChanged({ publishStatus: value })}
          publishStatus={publishStatus}
        />
      </MTableToolbar>
      <PublishClusterItemList
        pagesCount={pagesCount}
        sortField={sortField}
        setSortField={(value) => onFilterChanged({ sortField: value })}
        clusters={clusters}
        clustersLoaded={clustersLoaded}
      />

      {pagesLoaded && clusters?.length > 0 && (
        <MTablePagination
          paginationLimit={paginationLimit}
          page={page}
          paginationPagesCount={paginationPagesCount}
          setPaginationLimit={setPaginationLimit}
          setPage={setPage}
        />
      )}
    </MTableWrapper>
  ) : (
    <Published />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishedCluster);
