import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { I18n } from "react-redux-i18n";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DialogActions from "@material-ui/core/DialogActions";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { ISourceFile, IWebsite } from "../../../../../reducers/constants/objectTypes";
import RichTextEditor from "../../../../../editor/RichTextEditor";
import CfButton from "../../../../../components/CfButton";
import CfProgressBar from "../../../../../components/CfProgressBar";
import MPageActionsButtonGroup from "../../../../../components/MPageActionsButtonGroup";
import { web } from "../../../../../helpers/urlHelper";
import MTextCounter from "../../../../../components/MTextCounter";
import richTextEditorHelper from "../../../../../editor/helper/richTextEditorHelper";
import SourceFilesHistory from "./SourceFilesHistory";
import useRephraseDialog from "./useRephraseDialog";
import { splitTextWithDots } from "../../../../../helpers/textHelper";

const useStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    height: 60,
    borderBottom: `${theme.palette.divider} solid 1px`,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 0,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: 20,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  diffViews: {
    display: "flex",
    flexDirection: "column",
  },
  scrollableContent: {
    overflowY: "auto",
  },

  cancelButton: {
    marginRight: 10,
  },
  tabsWrapper: {},
  halfScreen: {
    padding: 10,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  part: {
    padding: 10,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  leftSide: {
    flex: 1,
    borderRight: `${theme.palette.divider} solid 1px`,
    overflow: "auto",
  },
  rightSide: {
    flex: 1,
    overflow: "auto",
  },
  dialogActions: {
    paddingRight: 40,
    paddingLeft: "calc(40px + 15%)",
    paddingTop: 25,
    paddingBottom: 25,
    borderTop: `${theme.palette.divider} solid 1px`,
    justifyContent: "initial",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  buttonContainer: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 20,
    "&:nth-of-type(1)": {
      paddingRight: 40,
    },
  },
  historyContainer: {
    width: "15%",
    borderRight: `${theme.palette.divider} solid 1px`,
    overflow: "auto",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  sourceFileId: string;

  handleSourceFileChanged(sourceFile: ISourceFile): void;
  handleCloseClick(): void;
  openPost(pageId: string, currentUrl: string): void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  openPost: (pageId, currentUrl) => dispatch(push(web.editPage(pageId, currentUrl))),
});

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const SourceFileRephraseDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const { handleCloseClick } = props;

  const {
    sourceFile,
    transcriptionRichText,
    rephrasedTranscriptionRichText,
    rephrasedTranscriptionTitle,
    key,
    selectedHistory,
    loading,
    submitLoading,
    pageActions,
    isSaveButtonEnabled,
    isActionButtonEnabled,
    isRephrasedButtonEnabled,
    onChange,
    selectHistory,
    rephraseTranscript,
    createNewPage,
    editSourceFile,
    deleteHistory,
  } = useRephraseDialog(props);

  return (
    <Dialog open fullScreen onClose={handleCloseClick} TransitionComponent={Transition}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CfProgressBar />
        </div>
      ) : (
        <>
          <DialogTitle disableTypography className={classes.titleWrapper}>
            <div className={classes.dialogTitle}>
              <ArrowBackIcon className={classes.backIcon} onClick={handleCloseClick} />
              <Typography color={"textPrimary"} variant={"h5"}>
                {I18n.t("dialogs.rephrase.title")}{" "}
                {sourceFile?.description ? `| ${splitTextWithDots(sourceFile.description)}` : ""}
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.historyContainer}>
              <SourceFilesHistory
                selectedHistory={selectedHistory}
                historyList={sourceFile?.transcriptionHistory}
                selectHistory={selectHistory}
                editHistory={editSourceFile}
                deleteHistory={deleteHistory}
              />
            </div>
            <div className={classes.leftSide} key={key.transcription}>
              <RichTextEditor
                droppableId={"rephrase_left_side"}
                fontSize={16}
                website={selectedWebsite}
                title={sourceFile?.title || ""}
                withCustomElements={false}
                initialValue={transcriptionRichText}
                enableComments
                onValueChanged={(value) => onChange({ transcriptionRichText: value })}
              />
            </div>
            <div className={classes.rightSide}>
              {submitLoading ? (
                <div className={classes.loadingContainer}>
                  <CfProgressBar />
                </div>
              ) : (
                rephrasedTranscriptionRichText && (
                  <div key={key.rephrasedTranscription}>
                    <RichTextEditor
                      droppableId={"rephrase_right_side"}
                      fontSize={16}
                      website={selectedWebsite}
                      title={rephrasedTranscriptionTitle}
                      onTitleChanged={(title) => onChange({ rephrasedTranscriptionTitle: title })}
                      withCustomElements={false}
                      enableComments
                      initialValue={rephrasedTranscriptionRichText}
                      onValueChanged={(richText) => onChange({ rephrasedTranscriptionRichText: richText })}
                    />
                  </div>
                )
              )}
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <div className={classes.buttonContainer}>
              <MTextCounter
                type={"word"}
                text={transcriptionRichText ? richTextEditorHelper.serialize(JSON.parse(transcriptionRichText)) : ""}
                suffix={I18n.t("writers.write_answer.words")}
              />
              <CfButton
                height={40}
                text={I18n.t("dialogs.rephrase.save")}
                onClick={() =>
                  editSourceFile({
                    fields: { transcriptionRichText, rephrasedTranscriptionRichText, rephrasedTranscriptionTitle },
                  })
                }
                disabled={!isSaveButtonEnabled}
              />
              <CfButton
                height={40}
                text={I18n.t("dialogs.rephrase.rephrase")}
                onClick={rephraseTranscript}
                disabled={!isRephrasedButtonEnabled}
              />
            </div>
            <div className={classes.buttonContainer}>
              <MTextCounter
                type={"word"}
                text={
                  rephrasedTranscriptionRichText
                    ? richTextEditorHelper.serialize(JSON.parse(rephrasedTranscriptionRichText))
                    : ""
                }
                suffix={I18n.t("writers.write_answer.words")}
              />
              <MPageActionsButtonGroup
                loading={false}
                mainAction={pageActions[0]}
                handleActionOnClick={(option) => {
                  createNewPage(option.action);
                }}
                actions={pageActions}
                disabled={!isActionButtonEnabled}
              />
            </div>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceFileRephraseDialog);
