import { ListItem, Popover, TextField, Tooltip, Typography, Link } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { default as ClassNames, default as classNames } from "classnames";
import React, { useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import agent from "../../../../agent";
import MultiSelectSearchDropdown from "../../../../components/MultiSelectSearchDropdown";
import ArrowRightRoundedIcon from "../../../../icons/ArrowRightRoundedIcon";
import {
  IWebsite,
  IAutoOutlineWithStrategy,
  IAutoOutlineParagraphData,
  IKeywordGroupMember,
} from "../../../../reducers/constants/objectTypes";
import AITitleGenerator from "./AITitleGenerator";
import NotesDialog from "./NotesDialog";

const useStyles = makeStyles((theme: Theme) => ({
  popoverPaper: {
    left: 130,
  },
  rowActions: {
    flexDirection: "row",
    alignItems: "center",
    display: "none",
    columnGap: "15px",
    justifyContent: "end",
    position: "absolute",
    right: 20,
    backgroundColor: theme.palette.common.white,
  },
  rowActionText: {
    fontSize: 14,
    padding: 0,
    margin: 0,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  rowActionsIcon: {
    width: 3,
    height: 3,
    fill: theme.palette.primary.main,
  },
  group: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  metric: {
    fontSize: "14px",
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    width: "10%",
  },
  lastColumns: {
    width: "7%",
  },
  kws: {
    width: "30%",
    paddingRight: "20px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    "&:hover": {
      "& $aiIconWrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  iconContainer: {
    "&:hover": {
      // "& $aiIconWraper": {
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      // },
    },
  },
  number: {
    width: 20,
    height: 20,
    padding: 10,
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    fontWeight: theme.typography.fontWeightBold as any,
    fontsize: 14,
    lineHeight: "16.8px",
  },
  aiIconWraper: {
    display: "none",
  },
  aiIcon: {
    height: 20,
    width: 20,
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    height: 51,
    // height: "100%",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    padding: "20px 0px",
    paddingRight: "30px",
    justifyContent: "space-between",
    "&:hover": {
      "& $openIcon": {
        visibility: "visible",
      },
      "& $rowActions": {
        display: "flex",
      },
    },
  },
  listItemFirstColumn: {
    width: "30%",
    display: "flex",
    gap: 20,
    flexDirection: "column",
  },
  closeAiAssistantIcon: {
    // alignSelf: "flex-end",
    width: 20,
    height: 20,
    marginLeft: 10,
  },
  button: {
    padding: "0", //"0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  postTitleField: {
    width: "100%",
    // fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: "1.5rem",
    height: 51, //"1.5rem",
    fontFamily: theme.typography.body2.fontFamily,
    cursor: "initial",
  },
  notesText: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  metricOnHover: {},
  aiIconWrapper: {
    marginRight: 20,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: -10,
    color: theme.palette.text.primary,
  },
  openIcon: {
    visibility: "hidden",
    transform: "rotate(90deg)",
    height: "17px",
    width: "17px",
  },
  collapseIcon: {
    transform: "rotate(90deg)",
    height: "17px",
    width: "17px",
  },
}));

type PropTypes = {
  keywords: IKeywordGroupMember[];
  selectedWebsite: IWebsite;
  outline: IAutoOutlineWithStrategy;
  postTitleChanged: (postTitle: { title?: string; targetKeywordsGroups?: string[] }) => void;
  paragraphChanged: (
    paragrahp: {
      title?: string;
      data?: {
        h3?: string[];
        notes?: string;
        targetKeywordsGroups: string[];
      };
    },
    index: number
  ) => void;
  dataH3Change(text: string, index: number, h3Index): void;
  handleParagraphDelete: (index: number) => void;
  handleH3Delete: (index: number, h3Index: number) => void;
  handleParagraphAdd: (
    newIndex: number,
    createData: {
      type: "PARAGRAPH";
      title: string;
      data: IAutoOutlineParagraphData;
    }
  ) => void;
  handleH3Add: (rowIndex: number, newIndex: number, createData: string) => void;
  addTitleNotes: (notes: string) => void;
  addParagraphNotes: (notes: string, index: number) => void;
};

const OutlinesDialogListItem = (props: PropTypes) => {
  const classes = useStyles(props);

  const {
    selectedWebsite,
    outline,
    postTitleChanged,
    paragraphChanged,
    dataH3Change,
    handleH3Delete,
    handleParagraphDelete,
    handleParagraphAdd,
    handleH3Add,
    addParagraphNotes,
    addTitleNotes,
    keywords,
  } = props;

  const [isPostTitleFocused, setIsPostTitleFocused] = useState(false);
  const [isAnyParagraphFocused, setIsAnyParagraphFocused] = useState(() => outline?.paragraphs.map(() => false));
  const [isAnyDataH3Focused, setIsAnyDataH3Focused] = useState(() =>
    outline?.paragraphs?.map((p) => (p?.data as any)?.h3?.map(() => false))
  );

  const [notesDialogData, setNotesDialogData] = useState<{
    paragraphIndex?: number;
    notes: string;
    open: boolean;
    title: string;
    type: string;
  }>({
    paragraphIndex: undefined,
    notes: "",
    open: false,
    title: "",
    type: "",
  });

  const setOpenNotesDialog = (openValue: boolean) => {
    setNotesDialogData((oldVal) => ({
      ...oldVal,
      open: openValue,
    }));
  };

  const openNotesDialog = (data: { title: string; notes: string; paragraphIndex?: number; type: string }) => {
    setNotesDialogData({ ...data, open: true });
  };

  const saveNotes = (notesValue: string) => {
    if (notesDialogData.type === "H1") {
      addTitleNotes(notesValue);
    }
    if (notesDialogData.type === "H2") {
      addParagraphNotes(notesValue, notesDialogData.paragraphIndex);
    }
    setNotesDialogData({
      paragraphIndex: undefined,
      notes: "",
      open: false,
      title: "",
      type: "",
    });
  };

  useEffect(() => {
    setIsPostTitleFocused(false);
    setIsAnyParagraphFocused(() => outline?.paragraphs.map(() => false));
    setIsAnyDataH3Focused(() => {
      return outline?.paragraphs?.map((p) => (p?.data as any)?.h3?.map(() => false));
    });
  }, [outline]);

  const OtherRestColumns = (props: {
    type?: "H1" | "H2" | "H3";
    keywords?: string[];
    title?: string;
    index?: number;
    notes?: string;
    postTitleChanged: (postTitle: { title?: string; targetKeywordsGroups?: string[] }) => void;
  }) => {
    const { type, keywords, notes, title, index, postTitleChanged } = props;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [searchText, setSearchText] = React.useState("");

    const defaultOptions = React.useMemo(() => {
      return (
        keywords?.map((k, index) => ({
          value: k,
          id: String(index),
        })) || []
      );
    }, [keywords]);

    const [options, setOptions] = React.useState<any[]>(defaultOptions);
    const [kws, setKws] = useState<any[]>(defaultOptions);

    const handleSearchTextChange = async (text: string) => {
      setSearchText(text);
      if (text === "") {
        setOptions(defaultOptions);
        return;
      }
      try {
        const { data } = await agent.KeywordGroups.getKeywords(selectedWebsite._id, 30, text);

        const newOptions = [
          ...defaultOptions,
          ...data.map((kw) => ({
            ...kw,
            value: kw.keyword,
          })),
        ].map((k, index) => ({
          ...k,
          id: String(index),
        }));

        setOptions(newOptions);
      } catch (error) {
        setOptions(defaultOptions);
      }
    };

    const handleClick = (event: React.MouseEvent<any>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleButtonClick = () => {
      if (type === "H1") {
        postTitleChanged({ targetKeywordsGroups: kws.map(({ value }) => value) });
      } else if (type === "H2") {
        paragraphChanged({ data: { targetKeywordsGroups: kws.map(({ value }) => value) } }, index);
      }
    };

    const open = Boolean(anchorEl) && type !== "H3";
    return (
      <>
        <div className={classes.metric}>
          <Typography>{type}</Typography>
        </div>

        <div
          className={classNames(classes.metric, classes.notesText)}
          onClick={() => {
            if (type === "H2") {
              openNotesDialog({
                title,
                paragraphIndex: index,
                notes,
                type,
              });
            }

            if (type === "H1") {
              openNotesDialog({
                title,
                type,
                notes,
              });
            }
          }}
        >
          <Typography>
            {type === "H3"
              ? "-"
              : notes
              ? I18n.t("strategy.outlinePages.dialog_outlines.edit_notes")
              : I18n.t("strategy.outlinePages.dialog_outlines.add_notes")}
          </Typography>
        </div>

        <div className={classNames(classes.metric, classes.kws)}>
          <Tooltip
            onClick={handleClick}
            title={<div style={{ whiteSpace: "pre-line" }}>{keywords?.length > 0 ? keywords?.join(",") : ""}</div>}
          >
            <Typography
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "pre",
              }}
            >
              {keywords?.length > 0 ? keywords?.join(",") : "-"}
            </Typography>
          </Tooltip>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MultiSelectSearchDropdown
              buttonText="Apply"
              onButtonClick={handleButtonClick}
              options={options}
              defaultValue={kws}
              searchFieldLabel="Search topics..."
              onOptionSelect={setKws}
              onSearchTextChange={handleSearchTextChange}
              searchText={searchText}
            />
          </Popover>
        </div>

        <div className={classNames(classes.lastColumns, classes.metric)}>
          <Typography>-</Typography>
        </div>

        <div className={classNames(classes.lastColumns, classes.metric)}>
          <Typography>-</Typography>
        </div>

        <div className={classNames(classes.lastColumns, classes.metric)}>
          <Typography>-</Typography>
        </div>
      </>
    );
  };

  const RowActions = (props: { rowIndex?: number; h3Index?: number; type: "H1" | "H2" | "H3" }) => {
    const { type, rowIndex, h3Index } = props;
    // return null;
    return (
      <div className={ClassNames(classes.rowActions)}>
        {type !== "H1" && (
          <>
            <Tooltip enterDelay={500} title={I18n.t(`strategy.outlinePages.dialog_outlines.add_above`)}>
              <IconButton
                disableRipple
                className={classes.button}
                onClick={(event) => {
                  event.stopPropagation();

                  if (type === "H2") {
                    handleParagraphAdd(rowIndex, {
                      data: {
                        h3: [],
                        targetKeywordsGroups: [],
                        notes: "",
                      },
                      title: "New H2",
                      type: "PARAGRAPH",
                    });
                  }
                  if (type === "H3") {
                    handleH3Add(rowIndex, h3Index, "New H3");
                  }
                }}
              >
                <Link className={classes.rowActionText}>
                  {I18n.t(`strategy.outlinePages.dialog_outlines.heading_above`)}
                </Link>
              </IconButton>
            </Tooltip>
            <FiberManualRecordIcon className={classes.rowActionsIcon} />
          </>
        )}

        {type !== "H1" && (
          <>
            <Tooltip enterDelay={500} title={I18n.t(`strategy.outlinePages.dialog_outlines.add_below`)}>
              <IconButton
                disableRipple
                className={classes.button}
                onClick={(event) => {
                  event.stopPropagation();
                  if (type === "H2") {
                    handleParagraphAdd(rowIndex + 1, {
                      data: {
                        h3: [],
                        targetKeywordsGroups: [],
                        notes: "",
                      },
                      title: "New H2",
                      type: "PARAGRAPH",
                    });
                  }
                  if (type === "H3") {
                    handleH3Add(rowIndex, h3Index + 1, "New H3");
                  }
                }}
              >
                <Link className={classes.rowActionText}>
                  {I18n.t(`strategy.outlinePages.dialog_outlines.heading_below`)}
                </Link>
              </IconButton>
            </Tooltip>
            <FiberManualRecordIcon className={classes.rowActionsIcon} />
          </>
        )}

        {type === "H2" && (
          <>
            <Tooltip enterDelay={500} title={I18n.t(`strategy.outlinePages.dialog_outlines.add_h3_heading`)}>
              <IconButton
                disableRipple
                className={classes.button}
                onClick={(event) => {
                  event.stopPropagation();
                  handleH3Add(rowIndex, 0, "New H3");
                }}
              >
                <Link className={classes.rowActionText}>{I18n.t(`strategy.outlinePages.dialog_outlines.h3`)}</Link>
              </IconButton>
            </Tooltip>
            <FiberManualRecordIcon className={classes.rowActionsIcon} />
          </>
        )}

        {type !== "H1" && (
          <Tooltip enterDelay={500} title={I18n.t(`strategy.outlinePages.dialog_outlines.delete_paragraph`)}>
            <IconButton
              disableRipple
              className={classes.button}
              onClick={(event) => {
                event.stopPropagation();

                if (type === "H3") {
                  handleH3Delete(rowIndex, h3Index);
                }
                if (type === "H2") {
                  handleParagraphDelete(rowIndex);
                }
              }}
            >
              <Link className={classes.rowActionText}>Delete</Link>
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const FirstColumn = (props: {
    value: string;
    type: "1" | "2" | "3";
    onFieldChange(value: string): void;
    isFieldFocused: boolean;
    // isUsingAIAssistant: boolean;
    // setIsUsingAIAssistant: (value: boolean) => void;
    setIsFieldFocused(value: boolean): void;
  }) => {
    const { type, isFieldFocused, setIsFieldFocused, onFieldChange, value } = props;
    const [input, setInput] = useState(value);
    const backgroundColor = {
      "1": "#0269C8",
      "2": "#0298C8",
      "3": "#18C0F4",
    }[type];

    const [firstTime, setFirstTime] = useState(true);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <div className={classes.listItemFirstColumn}>
        <>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            style={{
              left: 115,
              top: -10,
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <AITitleGenerator
              field={value}
              page={null}
              selectedWebsite={selectedWebsite}
              onChange={(v) => {
                if (firstTime) {
                  setFirstTime(false);
                  return;
                }
                onFieldChange(v);
              }}
              text={value}
              placeholder={""}
            />
          </Popover>
        </>

        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ fontWeight: type === "3" ? 500 : 700 }}
          className={classes.title}
        >
          <div className={classes.iconContainer}>
            <div className={classes.number} style={{ backgroundColor }}>
              {type}
            </div>
          </div>
          <TextField
            fullWidth
            inputProps={{ className: classes.postTitleField }}
            value={input}
            onChange={(event) => {
              setInput(event.target.value);
            }}
            onBlur={() => {
              onFieldChange(input);
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />

          {!isFieldFocused && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e as any);
              }}
              className={classes.aiIconWrapper}
            >
              <ArrowRightRoundedIcon className={classes.openIcon} />
              {open && <ArrowRightRoundedIcon className={classes.collapseIcon} />}
            </div>
          )}
        </div>
      </div>
    );
  };

  const Row = (props: {
    h3Index?: number;
    isFieldFocused: boolean;
    setIsFieldFocused(value: boolean): void;
    value: string;
    onFieldChange(value: string): void;
    type: "1" | "2" | "3";
    keywords?: string[];
    title?: string;
    index?: number;
    notes?: string;
  }) => {
    return (
      <>
        <FirstColumn
          value={props.value}
          isFieldFocused={props.isFieldFocused}
          onFieldChange={props.onFieldChange}
          setIsFieldFocused={props.setIsFieldFocused}
          type={props.type}
        />
        <OtherRestColumns
          postTitleChanged={postTitleChanged}
          type={`H${props.type}` as any}
          keywords={props.keywords}
          title={props.title}
          notes={props.notes}
          index={props.index}
        />
        <RowActions h3Index={props.h3Index} rowIndex={props.index} type={`H${props.type}` as any} />
      </>
    );
  };

  return (
    <div>
      <div className={classes.group}>
        <ListItem className={classNames(classes.listRow)}>
          <Row
            value={outline.postTitle.title}
            isFieldFocused={isPostTitleFocused}
            onFieldChange={(value) => {
              postTitleChanged({ title: value });
            }}
            setIsFieldFocused={setIsPostTitleFocused}
            type={"1"}
            keywords={outline.postTitle.targetKeywordsGroups}
            title={outline.postTitle?.title || ""}
            notes={outline.postTitle?.notes || ""}
          />
        </ListItem>
      </div>
      {outline?.paragraphs?.map((p, index) => (
        <div key={`p-${index}-${p?.title}`} className={classes.group}>
          {p?.title && (
            <ListItem className={classNames(classes.listRow)}>
              <Row
                value={p?.title}
                isFieldFocused={isAnyParagraphFocused[index]}
                onFieldChange={(text) => {
                  paragraphChanged({ title: text }, index);
                }}
                setIsFieldFocused={(boolean) => {
                  setIsAnyParagraphFocused((prev) => {
                    const newPrev = [...prev];
                    newPrev[index] = boolean;
                    return newPrev;
                  });
                }}
                title={p.title || ""}
                index={index}
                type={"2"}
                keywords={p.data.targetKeywordsGroups}
                notes={p.data?.notes || ""}
              />
            </ListItem>
          )}
          {(p?.data as IAutoOutlineParagraphData)?.h3?.map((h, h3Index) => (
            <ListItem key={`h3-${h3Index}-${h}`} className={classNames(classes.listRow)}>
              <Row
                value={h}
                isFieldFocused={isAnyDataH3Focused[index]?.[h3Index] || false}
                onFieldChange={(text) => {
                  dataH3Change(text, index, h3Index);
                }}
                setIsFieldFocused={(boolean) => {
                  setIsAnyDataH3Focused((prev) => {
                    const newPrev = [...prev];
                    newPrev[index][h3Index] = boolean;
                    return newPrev;
                  });
                }}
                type={"3"}
                index={index}
                h3Index={h3Index}
              />
            </ListItem>
          ))}
        </div>
      ))}
      <NotesDialog
        title={notesDialogData.title}
        notes={notesDialogData.notes}
        open={notesDialogData.open}
        setOpen={setOpenNotesDialog}
        type={notesDialogData.type}
        save={saveNotes}
        setNotes={(notes) => {
          setNotesDialogData((oldVal) => ({
            ...oldVal,
            notes,
          }));
        }}
      />
    </div>
  );
};

export default OutlinesDialogListItem;
