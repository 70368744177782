import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { Transforms } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";
import { I18n } from "react-redux-i18n";
import { Widget } from "../widgets/WithWidgets";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    fill: "white",
    color: "white",
    "&:hover": {
      // backgroundColor: 'transparent',
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },

  outerWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    "&:hover": {
      "& $deleteIconWrapper": {
        visibility: "visible",
      },
    },
  },
  videoWrapper: {
    position: "relative",
  },
  frame: {
    margin: "auto",
  },
}));

const EmbedElement = ({ attributes, children, element }) => {
  const classes = useStyles();
  const editor = useSlateStatic();

  const { url, width = "100%", height = null } = element;
  return (
    <Widget
      title={I18n.t("rich_text_editor.tooltips.video")}
      element={element}
      contentEditable
      editable
      handleDeleteClick={() => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        Transforms.removeNodes(editor, { at: path });
      }}
    >
      <div {...attributes}>
        <div contentEditable={false} className={classes.outerWrapper}>
          <iframe
            src={url}
            scrolling="no"
            frameBorder="no"
            allowTransparency={true}
            allowFullScreen={true}
            style={{ margin: "auto", maxHeight: 1000 }}
            width={"100%"}
            // width={"100%"}
            height={element.subtype === "youtube" ? 337 : height || "320px"}
          ></iframe>
        </div>
        {children}
      </div>
    </Widget>
  );
};

export default EmbedElement;
