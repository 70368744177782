import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import ClassNames from "classnames";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import _ from "lodash";
import { useSnackbar } from "notistack";
import CfButton from "../../../components/CfButton";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";
import MSelect from "../../../components/MSelect";
import { IKeywordType, IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import { KEYWORD_CLASS_LEVELS } from "../../../helpers/constants";
import { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderWidth: 0,
    height: "100%",
    maxHeight: 631,
    padding: 30,
  },
  titleWrapper: {
    height: 114,
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
  },
  titleAndCloseIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  error: {
    color: "red",
    fontSize: theme.typography.pxToRem(12),
  },
  dialogContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  column: {
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginRight: 20,
    "&:last-child": {
      marginRight: 0,
    },
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: "30px 0 0 0",
  },
  countersAndButton: {
    display: "flex",
    alignItems: "center",
  },
  counter: {
    marginRight: 10,
  },
  counterText: {
    marginLeft: 5,
  },
  closeButton: {
    padding: 0,
  },
  columnTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 5,
  },
  columnTitleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  columnText: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: 10,
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(26),
    color: theme.palette.text.secondary,
  },
  textField: {
    height: "100%",
  },
  inputTextField: {
    padding: 10,
  },
  button: {
    marginLeft: 5,
  },
  hidden: {
    visibility: "hidden",
  },
  closableRow: {
    width: "100%",
    display: "flex",
  },
  row: {
    width: "100%",
    display: "flex",
    marginBottom: 10,
  },
  queryTextField: {
    width: "40%",
  },
  select: {
    width: "20%",
    textTransform: "capitalize",
    marginRight: 10,
  },
  queryCloseIcon: {
    width: "3%",
    cursor: "pointer",
    marginTop: 5,
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  title: {
    width: 260,
    fontSize: theme.typography.pxToRem(14),
    marginRight: 15,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  headersRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  firstHeader: {
    width: "40%",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  lastHeader: {
    width: "3%",
  },
  header: {
    width: "20%",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  open: boolean;
  types: IKeywordType[];
  selectedWebsite: IWebsite;
  handleCloseClick: () => void;
  handleLoadKeywords?: () => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const AddKeywordsDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, types, selectedWebsite, handleCloseClick, handleLoadKeywords } = props;
  const [queries, setQueries] = React.useState([]);
  const [error, setError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const levels = KEYWORD_CLASS_LEVELS.map((k, index) => ({
    _id: index,
    name: k,
  }));

  useEffect(() => {
    setQueries([]);
  }, [selectedWebsite]);

  const isValid = (queriesList: any[]) => {
    let valid = true;
    queriesList.map((query) => {
      if (!query.keyword || query.keyword === "") {
        valid = false;
      }
      return query;
    });
    setError(!valid);
    return valid;
  };

  const createKeywords = () => {
    const createData = queries.map((row) => ({
      ...row,
      type: row.type?._id,
      website: selectedWebsite._id,
    }));
    console.log({ createData });
    agent.KeywordsClassification.createKeyword(createData)
      .then((res) => {
        handleCloseClick();
        enqueueSnackbar(I18n.t("snackbar.create_success"));
        setQueries([]);
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(I18n.t("snackbar.create_error", { msg: e.message }));
      });
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      onClose={() => handleCloseClick}
      fullWidth
      maxWidth={"lg"}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.titleWrapper}>
        <div className={classes.titleAndCloseIcon}>
          <Typography variant={"subtitle1"}>{I18n.t("classify_keywords.dialog.title")}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseClick}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <Typography className={classes.subtitle} variant={"body1"}>
          {I18n.t("classify_keywords.dialog.subtitle")}
        </Typography>
        {error && (
          <Typography className={classes.error} variant={"body1"}>
            {I18n.t("classify_keywords.dialog.error")}
          </Typography>
        )}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.headersRow}>
          <Typography className={classes.firstHeader}>{I18n.t("classify_keywords.dialog.query")}</Typography>
          <Typography className={classes.header}>{I18n.t("classify_keywords.dialog.level")}</Typography>
          <Typography className={classes.header}>{I18n.t("classify_keywords.dialog.type")}</Typography>
          <Typography className={classes.lastHeader}></Typography>
        </div>
        {[...queries, { keyword: "", type: null, level: null, tag: [] }].map((item, index) => (
          <div className={classes.closableRow} key={index}>
            <div className={classes.row}>
              <TextFieldMaxLength
                wrapperClassName={classes.queryTextField}
                value={item.keyword}
                titleAndLengthLocation={"left"}
                title={""}
                outlined={false}
                placeholder={I18n.t("classify_keywords.dialog.add_keyword_ph")}
                borderRadius={4}
                titleMinWidth={0}
                alignTitle={"start"}
                height={37}
                marginRight={10}
                handleValueChange={(value) => {
                  if (queries.length === index) {
                    const updatedQueries = [
                      ...queries,
                      {
                        keyword: value,
                        type: queries?.[index]?.type,
                        level: queries?.[index]?.level,
                        tag: queries?.[index]?.tag,
                      },
                    ];
                    setQueries(updatedQueries);
                  } else {
                    const updatedQueries = [...queries];
                    updatedQueries[index].keyword = value;
                    setQueries(updatedQueries);
                  }
                }}
              />

              <MSelect
                customClass={classes.select}
                options={levels}
                value={_.find(levels, { name: item.level }) || null}
                optionValue={"_id"}
                optionLabel={"name"}
                height={37}
                placeholder={I18n.t("classify_keywords.dialog.select_ph")}
                borderWidth={1}
                borderRadius={4}
                isBold={false}
                textColor={"#333333"}
                handleChange={(value) => {
                  if (queries.length === index) {
                    const updatedQueries = [
                      ...queries,
                      {
                        keyword: queries?.[index]?.keyword,
                        type: queries?.[index]?.type,
                        level: value.name,
                        tag: queries?.[index]?.tag,
                      },
                    ];
                    setQueries(updatedQueries);
                    return;
                  }
                  const updatedQueries = [...queries];
                  updatedQueries[index].level = value.name;
                  setQueries(updatedQueries);
                }}
              />
              <MSelect
                customClass={classes.select}
                options={types}
                value={item.type || null}
                optionValue={"_id"}
                optionLabel={"name"}
                height={37}
                placeholder={I18n.t("classify_keywords.dialog.select_ph")}
                borderWidth={1}
                borderRadius={4}
                isBold={false}
                textColor={"#333333"}
                handleChange={(value) => {
                  if (queries.length === index) {
                    const updatedQueries = [
                      ...queries,
                      {
                        keyword: queries?.[index]?.keyword,
                        type: value,
                        level: queries?.[index]?.level,
                        tag: queries?.[index]?.tag,
                      },
                    ];
                    setQueries(updatedQueries);
                    return;
                  }
                  const updatedQueries = [...queries];
                  updatedQueries[index].type = value;
                  setQueries(updatedQueries);
                }}
              />
            </div>
            <CloseIcon
              className={ClassNames(classes.queryCloseIcon, queries.length === index && classes.hidden)}
              onClick={() => {
                const updatedQueries = Array.from(queries);
                updatedQueries.splice(index, 1);
                setQueries(updatedQueries);
              }}
            />
          </div>
        ))}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <CfButton
          height={40}
          disabled={!queries || queries.length === 0}
          text={I18n.t("classify_keywords.dialog.add_queries")}
          customClass={classes.button}
          onClick={(e) => {
            if (isValid(queries)) {
              e.preventDefault();
              createKeywords();
              handleLoadKeywords();
            }
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AddKeywordsDialog);
