import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { replace } from "connected-react-router";
import { delay, filter, find, findIndex, uniq } from "lodash";
import Typography from "@material-ui/core/Typography";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Divider } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {
  ePageAction,
  ePageState,
  ePageType,
  eUserRoles,
  IPage,
  IPageCluster,
  IWebsite,
} from "../../reducers/constants/objectTypes";
import MTextCounter from "../../components/MTextCounter";
import agent from "../../agent";
import MAlertDialog from "../../components/MAlertDialog";
import { AbilityContext } from "../../casl/can";
import { api, clientPages, getWordPressManuallyExportedPage, web } from "../../helpers/urlHelper";
import { PAGE_EDITOR } from "../../reducers/constants/actionTypes";
import MPageActionsButtonGroup from "../../components/MPageActionsButtonGroup";
import useQuery from "../../hooks/useQuery";
import richTextEditorHelper from "../../editor/helper/richTextEditorHelper";
import MPageEditorAutoSave from "../../components/MPageEditorAutoSave";

const useStyles = makeStyles((theme: Theme) => ({
  updateButtonWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 15,
  },
  postBottomBarInnerWrapper: {
    // width: 'calc(100% - 2*280px)',
    width: "100%",
    paddingRight: 15,
    paddingLeft: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    minHeight: 40,
  },
  discardButton: {
    marginRight: 20,
  },
  postTypesWrapper: {
    display: "flex",
    alignItems: "center",
  },
  invalidatingText: {
    marginRight: 20,
  },
  linkIcon: {
    color: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  divider: {
    height: 26,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  page: IPage;
  pageRichText: string;
  publishAction?: ePageAction;
  saveInProgress: boolean;
  autoSaveOn: boolean;
  returnToPreviousScreen: () => void;
  pageSaved: (page: IPage, cluster: IPageCluster, returnTo: string) => void;
  handleSaveInProgress: (value: boolean) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  page: state.pageEditor.page,
  pageRichText: state.pageEditor.pageRichText,
  saveInProgress: state.pageEditor.saveInProgress,
  autoSaveOn: state.pageEditor.autoSaveOn,
});

const mapDispatchToProps = (dispatch) => ({
  pageSaved: (page, cluster, returnTo) => {
    dispatch({ type: PAGE_EDITOR.PAGE_UPDATED, page, cluster, returnTo });
    dispatch(replace(web.editPage(page._id, returnTo)));
  },
  handleSaveInProgress: (value) => dispatch({ type: PAGE_EDITOR.SAVE_IN_PROGRESS, value }),
});

const PageEditorUpdateButton = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    page,
    pageRichText,
    publishAction = ePageAction.publish,
    saveInProgress,
    autoSaveOn,
    returnToPreviousScreen,
    pageSaved,
    handleSaveInProgress,
  } = props;
  const [invalidatingCache, setInvalidatingCache] = React.useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ability = React.useContext<any>(AbilityContext);
  const { secondEditStep } = selectedWebsite;
  const factCheckingEnabled = selectedWebsite.reviewProcess === "Fact-Check";
  const customReviewProcess = selectedWebsite.reviewProcess === "Custom";
  const queries = useQuery();

  let actions = [];
  switch (page.currentState.state) {
    case ePageState.writer:
      actions = [ePageAction.save, ePageAction.submitForEdit];
      if (ability.can(eUserRoles.EDITOR, "role")) {
        if (secondEditStep) {
          actions.push(ePageAction.submitForSecondEdit);
        }
        if (factCheckingEnabled) {
          actions.push(ePageAction.submitForFactCheck);
        }
        if (customReviewProcess) {
          selectedWebsite.reviews
            .filter((wr) => wr.reviewType === "factCheck")
            .map((wr) => {
              actions.push({
                action: ePageAction.submitForReview,
                subAction: wr.reviewStepName,
                label: `Submit for ${wr.reviewName}`,
              });
            });
        }
        actions.push(ePageAction.delete);
      }
      if (ability.can(eUserRoles.CONTENT_MANAGER, "role")) {
        actions.push(publishAction);
      }
      break;
    case ePageState.edit:
      actions = [ePageAction.save, ePageAction.returnToWriter];
      if (ability.can(eUserRoles.EDITOR, "role")) {
        if (secondEditStep) {
          actions.push(ePageAction.submitForSecondEdit);
        }
        if (factCheckingEnabled) {
          actions.push(ePageAction.submitForFactCheck);
        } else if (customReviewProcess) {
          const regulations = selectedWebsite.reviews.filter((wr) => wr.reviewType === "regulationCheck");
          console.log("regulations", regulations);
          if (regulations.length > 0) {
            const nextReviewSteps = page.optimize
              ? regulations
              : filter(regulations, (wr) => !find(page.reviews, (pr) => pr.subState === wr.reviewStepName && pr.pass));
            console.log("nextReviewSteps", nextReviewSteps);
            const nextReviewStep = find(nextReviewSteps, (s) => s.locales.includes(page.locale));
            console.log("nextReviewStep", nextReviewStep);
            if (nextReviewStep) {
              actions.push({
                action: ePageAction.submitForReview,
                subAction: nextReviewStep.reviewStepName,
                label: `Submit for ${nextReviewStep.reviewName}`,
              });
            } else {
              actions.push(publishAction);
            }
          }

          const factChecks = selectedWebsite.reviews.filter((wr) => wr.reviewType === "factCheck");
          console.log("factChecks", factChecks);
          if (factChecks.length > 0) {
            const previousReviewSteps = filter(factChecks, (wr) =>
              find(page.reviews, (pr) => pr.subState === wr.reviewStepName)
            );
            console.log("previousReviewSteps", previousReviewSteps);
            const nextReviewSteps = page.optimize
              ? factChecks
              : filter(factChecks, (wr) => !find(page.reviews, (pr) => pr.subState === wr.reviewStepName));
            console.log("nextReviewSteps", nextReviewSteps);
            previousReviewSteps.map((prs) => {
              actions.push({
                action: ePageAction.submitForReview,
                subAction: prs.reviewStepName,
                label: `Return to ${prs.reviewName}`,
              });
            });

            nextReviewSteps.map((nrs) => {
              actions.push({
                action: ePageAction.submitForReview,
                subAction: nrs.reviewStepName,
                label: `Submit for ${nrs.reviewName}`,
              });
            });
          }
        } else {
          actions.push(publishAction);
        }
      }

      if (ability.can(eUserRoles.CONTENT_MANAGER, "role")) {
        actions.push(publishAction);
      }

      actions.push(ePageAction.delete);

      break;

    case ePageState.secondEdit:
      actions = [ePageAction.save, ePageAction.returnToWriter, ePageAction.returnToEdit];
      if (ability.can(eUserRoles.EDITOR, "role")) {
        if (factCheckingEnabled) {
          actions.push(ePageAction.submitForFactCheck);
        } else if (customReviewProcess) {
          const regulations = selectedWebsite.reviews.filter((wr) => wr.reviewType === "regulationCheck");
          console.log("regulations", regulations);
          if (regulations.length > 0) {
            const nextReviewSteps = page.optimize
              ? regulations
              : filter(regulations, (wr) => !find(page.reviews, (pr) => pr.subState === wr.reviewStepName && pr.pass));
            console.log("nextReviewSteps", nextReviewSteps);
            const nextReviewStep = find(nextReviewSteps, (s) => s.locales.includes(page.locale));
            console.log("nextReviewStep", nextReviewStep);
            if (nextReviewStep) {
              actions.push({
                action: ePageAction.submitForReview,
                subAction: nextReviewStep.reviewStepName,
                label: `Submit for ${nextReviewStep.reviewName}`,
              });
            } else {
              actions.push(publishAction);
            }
          }

          const factChecks = selectedWebsite.reviews.filter((wr) => wr.reviewType === "factCheck");
          console.log("factChecks", factChecks);
          if (factChecks.length > 0) {
            const previousReviewSteps = filter(factChecks, (wr) =>
              find(page.reviews, (pr) => pr.subState === wr.reviewStepName)
            );
            console.log("previousReviewSteps", previousReviewSteps);
            const nextReviewSteps = page.optimize
              ? factChecks
              : filter(factChecks, (wr) => !find(page.reviews, (pr) => pr.subState === wr.reviewStepName));
            console.log("nextReviewSteps", nextReviewSteps);
            previousReviewSteps.map((prs) => {
              actions.push({
                action: ePageAction.submitForReview,
                subAction: prs.reviewStepName,
                label: `Return to ${prs.reviewName}`,
              });
            });

            nextReviewSteps.map((nrs) => {
              actions.push({
                action: ePageAction.submitForReview,
                subAction: nrs.reviewStepName,
                label: `Submit for ${nrs.reviewName}`,
              });
            });
          }
        } else {
          actions.push(publishAction);
        }
      }

      if (ability.can(eUserRoles.CONTENT_MANAGER, "role")) {
        actions.push(publishAction);
      }

      actions.push(ePageAction.delete);

      break;

    case ePageState.factCheck:
      actions = [
        ...(ability.can(eUserRoles.EDITOR, "role") || ability.can("user_role", eUserRoles.CUSTOMER)
          ? [publishAction, ePageAction.save]
          : []),
        ...(ability.can(eUserRoles.EDITOR, "role") ? [ePageAction.returnToEdit] : []),
        ...(secondEditStep ? [ePageAction.returnToSecondEdit] : []),
        ...(ability.can(eUserRoles.EDITOR, "role") ? [ePageAction.delete] : []),
      ];
      break;
    case ePageState.review: {
      const reviewStep = findIndex(selectedWebsite.reviews, (r) => r.reviewStepName === page.currentState.subState);
      const currentReviewStep = selectedWebsite.reviews[reviewStep];
      const previousReview = selectedWebsite.reviews?.[reviewStep - 1] || null;
      const nextReview = selectedWebsite.reviews?.[reviewStep + 1] || null;

      actions = [ePageAction.save];
      const regulations = selectedWebsite.reviews.filter((wr) => wr.reviewType === "regulationCheck");
      if (regulations.length > 0) {
        actions.push(
          {
            action: ePageAction.approveRegulation,
            subAction: nextReview ? nextReview.reviewStepName : null,
            label: I18n.t(`workflow.actions.${ePageAction.approveRegulation}`),
          },
          ePageAction.declineRegulation
        );
      }
      const factChecks = selectedWebsite.reviews.filter((wr) => wr.reviewType === "factCheck");
      if (factChecks.length > 0) {
        actions.push({
          action: ePageAction.approveFactCheck,
          subAction: nextReview ? nextReview.reviewStepName : null,
          label: nextReview
            ? I18n.t(`workflow.actions.ApproveFactCheck`, { name: currentReviewStep.reviewName })
            : I18n.t(`workflow.actions.${publishAction}`),
        });

        if (previousReview) {
          actions.push({
            action: ePageAction.returnToReview,
            subAction: previousReview.reviewStepName,
            label: `Return to ${previousReview.reviewName}`,
          });
        }
      }
      if (!ability.can("user_role", eUserRoles.CUSTOMER)) {
        actions.push(ePageAction.returnToEdit);
      }
      if (secondEditStep) {
        actions.push(ePageAction.returnToSecondEdit);
      }

      break;
    }
    case ePageState.published: {
      if (ability.can(eUserRoles.EDITOR, "role")) {
        actions = [ePageAction.update, ePageAction.returnToWriter, ePageAction.returnToEdit];
      } else if (ability.can("user_role", eUserRoles.CUSTOMER)) {
        actions = [ePageAction.update];
      }
      if (secondEditStep) {
        actions.push(ePageAction.returnToSecondEdit);
      }
      if (factCheckingEnabled) {
        actions.push(ePageAction.returnToFactCheck);
      }
      if (ability.can(eUserRoles.CONTENT_MANAGER, "role")) {
        actions.push(ePageAction.delete);
      }
      break;
    }
    case ePageState.archived:
    case ePageState.deleted:
      actions = [
        ...(ability.can(eUserRoles.EDITOR, "role")
          ? [ePageAction.deleteForever, ePageAction.returnToWriter, ePageAction.returnToEdit]
          : []),
      ];
      if (secondEditStep) {
        actions.push(ePageAction.returnToSecondEdit);
      }
      break;
    default:
      break;
  }

  if (
    selectedWebsite.configurations.siteDetails.platform === "WordPress" &&
    selectedWebsite.configurations.siteDetails.integrationType === "WordPress Manual Integration" &&
    [ePageState.edit, ePageState.factCheck, ePageState.review, ePageState.published].includes(page.currentState.state)
  ) {
    actions.push(ePageAction.exportPage);
  }

  actions = uniq(actions);

  const writerBioAction = (href) => (
    <a href={href} target={"_blank"}>
      <OpenInNewIcon className={classes.linkIcon} />
    </a>
  );
  const validPost = (newState: ePageState) => {
    if (newState !== ePageState.published && newState !== ePageState.edit && newState !== ePageState.factCheck) {
      return true;
    }

    let valid = true;
    if (!page.title) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Title" }));
      valid = false;
    }

    if (!page.metaDescription || page.metaDescription.length === 0) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Meta description" }));
      valid = false;
    }

    if (
      newState !== ePageState.edit &&
      !["niArticle", "niProductReview"].includes(page.type) &&
      (!page.categories || page.categories.length === 0)
    ) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Category" }));
      valid = false;
    }

    if (!pageRichText || pageRichText.length === 0) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Text" }));
      valid = false;
    }

    if (
      ![ePageType.Targeted, "niProductReview"].includes(page.type) &&
      newState !== ePageState.edit &&
      !page.previewImage
    ) {
      enqueueSnackbar(I18n.t("snackbar.invalid.missing_field", { field: "Preview Image" }));
      valid = false;
    }

    return valid;
  };

  const saveAnswer = (selectedAction?: ePageAction | { action: string; subAction: string; label: string }) => {
    const action = selectedAction?.action || selectedAction;
    const subAction = selectedAction?.subAction || null;
    if (!validPost(page.currentState.state)) {
      return;
    }

    const { currentState } = page;
    page.richText =
      action === ePageAction.publish || action === ePageAction.update
        ? richTextEditorHelper.checkFaqGoogleSchemaMarkup(pageRichText)
        : pageRichText;
    page.text = richTextEditorHelper.serialize(JSON.parse(pageRichText));
    handleSaveInProgress(true);

    if (page.currentState.state === ePageState.deleted && action === ePageAction.deleteForever) {
      agent.Pages.deleteForever(page._id)
        .then((res) => {
          enqueueSnackbar(I18n.t(`edit_post.delete_forever.success`));
          returnToPreviousScreen();
        })
        .catch((err) => {
          enqueueSnackbar(I18n.t(`edit_post.delete_forever.error`));
        });
      return;
    }

    agent.Pages.savePage(selectedWebsite, page, action, subAction)
      .then((res) => {
        console.log("res", res);
        if (res.publishStatus?.error) {
          enqueueSnackbar(I18n.t(`snackbar.post.save.Save`));
          enqueueSnackbar(
            <span style={{ whiteSpace: "pre" }}>
              {I18n.t("snackbar.post.publish.error", { msg: res.publishStatus?.message })}
            </span>
          );
        } else {
          enqueueSnackbar(I18n.t(`snackbar.post.save.${action}`));
        }
        handleSaveInProgress(false);

        if (
          !res.publishStatus?.error &&
          !ability.can("user_role", "customer") &&
          res.page.currentState.state === ePageState.published
        ) {
          const pageUrl = clientPages.getPageUrl(res.page, selectedWebsite);
          const url = clientPages.withCacheInvalidation(selectedWebsite, pageUrl);
          if (action === ePageAction.update || action === ePageAction.publish) {
            setInvalidatingCache(true);
            agent.Cache.articleInvalidation(selectedWebsite, res.page)
              .then((valRes) => {
                setInvalidatingCache(false);
                delay(() => {
                  window.open(url, "_blank");
                }, 3000);
              })
              .catch((e) => {
                enqueueSnackbar(I18n.t("snackbar.skipping_invalidation"));
                setInvalidatingCache(false);
                window.open(url, "_blank");
              });
          } else {
            window.open(url, "_blank");
          }
        }

        pageSaved(res.page, res.cluster, queries.get("returnTo"));

        if (
          !res.publishStatus?.error &&
          (currentState.state !== res.page.currentState.state ||
            currentState.subState !== res.page.currentState.subState)
        ) {
          returnToPreviousScreen();
        }
      })
      .catch((e) => {
        if (e.response?.body?.bioErrors) {
          e.response.body.bioErrors.forEach((bioError) => {
            enqueueSnackbar(I18n.t("snackbar.invalid.missing_writer_bio", { field: bioError.field }), {
              action: writerBioAction(web.settingsBios(bioError._id)),
            });
          });
        } else {
          enqueueSnackbar(I18n.t("snackbar.post.save.error", { msg: e.response?.body?.message }));
        }
        handleSaveInProgress(false);
      });
  };

  const onDiscardDraftClicked = () => {
    setDiscardDialogOpen(true);
  };

  const discardAnswer = () => {
    // todo: fix now
    // agent.EditPost.discardAnswer(
    //   false,
    //   ePostType.BLOG,
    //   page,
    //   page.currentState.state === ePageState.edit ? "editor" : "writer"
    // )
    //   .then((res) => {
    //     enqueueSnackbar(I18n.t(`snackbar.post.discard.post_discarded`));
    //     returnToPreviousScreen();
    //   })
    //   .catch((e) => {
    //     enqueueSnackbar(I18n.t("snackbar.post.discard.error", { msg: e.response.body.message }));
    //   });
  };

  const handleButtonClick = (action: ePageAction) => {
    switch (action) {
      case ePageAction.exportPage:
        window.open(getWordPressManuallyExportedPage(selectedWebsite, page), "_blank");
        break;
      default:
        saveAnswer(action);
    }
  };

  return (
    <div className={classes.updateButtonWrapper}>
      {(page.type === "niArticle" ||
        selectedWebsite._id === "65a6668c7fd8940b5b076ebb" || // myh
        selectedWebsite._id === "65c9c40df7f8613a37f36553") && ( // myh dev
        <>
          <Typography color={"textSecondary"} variant={"body2"}>
            <Link
              target={"_blank"}
              rel="noopener"
              href={`${api.api()}/page-previews/${selectedWebsite._id}/preview/${page._id}`}
            >
              {I18n.t("upload_blog_post.preview")}
            </Link>
          </Typography>
          <Divider orientation={"vertical"} className={classes.divider} />
        </>
      )}

      <MPageEditorAutoSave
        enableAutoSave={
          ![ePageState.published, ePageState.deleted, ePageState.archived].includes(page.currentState.state)
        }
        interval={180}
        lastSaveTime={page.updatedAt}
        isAutoSaveOn={autoSaveOn}
        handleSavePost={() => saveAnswer(ePageAction.autoSave)}
      />
      <Divider orientation={"vertical"} className={classes.divider} />
      {invalidatingCache && (
        <Typography color={"textSecondary"} variant={"body2"} className={classes.invalidatingText}>
          {I18n.t("edit_post.invalidating_cache")}
        </Typography>
      )}
      <MTextCounter
        type={"word"}
        text={richTextEditorHelper.serialize(JSON.parse(pageRichText)) || ""}
        suffix={I18n.t("writers.write_answer.words")}
        desiredCount={page.outline?.wordCount || undefined}
      />
      <Divider orientation={"vertical"} className={classes.divider} />

      {actions[0] && (
        <MPageActionsButtonGroup
          loading={saveInProgress || invalidatingCache}
          mainAction={actions[0]}
          handleActionOnClick={(action) => {
            handleButtonClick(action);
          }}
          actions={actions}
        />
      )}
      {discardDialogOpen && (
        <MAlertDialog
          open={discardDialogOpen}
          title={I18n.t("edit_post.discard_dialog.title")}
          description={I18n.t("edit_post.discard_dialog.description")}
          agreeText={I18n.t("edit_post.discard_dialog.agree")}
          disagreeText={I18n.t("edit_post.discard_dialog.cancel")}
          handleClose={(agree) => {
            setDiscardDialogOpen(false);
            if (agree) {
              discardAnswer();
            }
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorUpdateButton);
