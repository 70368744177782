import { makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { I18n } from "react-redux-i18n";
import { find } from "lodash";
import { IProduct } from "../../../../reducers/constants/objectTypes";
import MPictureCarousel from "../../../../components/MPictureCarousel";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  innerWrapper: {
    width: "100%",
    maxWidth: (props) => props.element.data?.productWrapperWidth || 395,
  },
  productEmbed: (props: PropTypes) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: props.element.data?.backgroundColor || theme.palette.secondary.main,
    padding: 10,
  }),
  productTitle: (props: PropTypes) => ({
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightBold as any,
    whiteSpace: "normal",
    textAlign: "center",
    marginTop: 20,
    ...props.element.data?.titleCss,
  }),
  productPrice: (props: PropTypes) => ({
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.3,
    marginTop: 20,
    fontWeight: theme.typography.fontWeightLight as any,
    textTransform: "uppercase",
    whiteSpace: "normal",
    textAlign: "center",
    ...props.element.data?.priceCss,
  }),
  productButton: (props: PropTypes) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    cursor: "pointer",
    height: 44,
    backgroundColor: "white",
    color: "black",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium as any,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    ...props.element.data?.buttonCss,
  }),
  link: {
    textDecoration: "none",
  },
  imageWrapper: {},
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  products: IProduct[];
};

const ProductEmbedWidgetReadOnly = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, products } = props;
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  return (
    <div {...attributes} contentEditable={false}>
      <div className={classes.wrapper}>
        <div className={classes.innerWrapper}>
          <div className={classes.productEmbed}>
            <div className={classes.imageWrapper}>
              <MPictureCarousel
                pictures={product?.imageFiles.map((image) => ({
                  src: image.url,
                  alt: image.alt,
                  title: image.title,
                  caption: image.caption,
                  ctaUrl: product?.callToActionURL,
                }))}
                sponsored
              />
            </div>

            <Typography className={classes.productTitle}>{element.data.productTitle || product?.name || ""}</Typography>

            {element.data.showPrice && (
              <Typography className={classes.productPrice}>
                {element.data.productPrice || product?.pricing.price || product?.pricing?.startingPrice || ""}
              </Typography>
            )}
          </div>
          <a
            className={classes.link}
            href={element.data?.buttonUrl || element.data?.callToActionURL || product?.callToActionURL}
            target={"_blank"}
          >
            <div className={classes.productButton}>
              <span>
                {element.data.buttonText ||
                  element.data?.productButton ||
                  product?.callToAction ||
                  I18n.t("rich_text_editor.product_embed.shop_now")}
              </span>
            </div>
          </a>
        </div>
      </div>
      {children}
    </div>
  );
};

export default ProductEmbedWidgetReadOnly;
