"use strict";
import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  star: {
    fill: "inherit",
  },
  empty: {
    fill: "rgb(153, 158, 178)",
  },
  active: {
    fill: "rgb(255,189,6)",
  },
}));

type propTypes = {
  className?: string;
  empty?: boolean;
  active?: boolean;
};

function RatingStarIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className, empty, active } = props;
  return (
    <SvgIcon
      fontSize={"inherit"}
      className={ClassNames(classes.star, empty && classes.empty, active && classes.active, className)}
      viewBox={"0 0 32 32"}
    >
      <path d="M18.436,1.979 L21.558,9.589 L29.701,10.133 C31.872,10.269 32.822,12.987 31.058,14.482 L24.815,19.782 L26.715,27.664 C27.258,29.838 24.951,31.469 23.051,30.382 L16.129,26.033 L9.208,30.382 C7.308,31.469 5.001,29.838 5.544,27.664 L7.444,19.782 L1.201,14.482 C-0.427,13.123 0.387,10.405 2.558,10.133 L10.701,9.589 L13.822,1.979 C14.772,-0.059 17.622,-0.059 18.436,1.979 Z" />
    </SvgIcon>
  );
}

export default RatingStarIcon;
