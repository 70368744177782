import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import RedirectsSearchBar from "./components/RedirectsSearchBar";
import OrdersList from "./components/OrdersList";
import agent from "../../../../agent";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";

const useStyles = makeStyles((theme: Theme) => ({
  learningCenter: {
    height: "100%",
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  learningCenterContent: {
    backgroundColor: "white",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 93px)",
    overflow: "auto",
  },
  learningCenterInnerContent: {
    width: "100%",
    maxWidth: 1350,
    marginRight: "auto",
    marginLeft: "auto",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

type PropTypes = {
  selectedWebsite: IWebsite;
};

const Orders = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const [searchText, setSearchText] = React.useState("");
  const [orders, setOrders] = React.useState<any[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [refresh, setRefresh] = React.useState(true);
  const [hardRefresh, setHardRefresh] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    if (!refresh) {
      return;
    }
    setLoaded(false);
    console.log("useEffect getOrders");
    agent.Orders.getOrders(selectedWebsite._id, hardRefresh)
      .then((res) => {
        setOrders(res.orders);
        setRefresh(false);
        setHardRefresh(false);
        setLoaded(true);
      })
      .catch((e) => {
        setLoaded(true);
        setRefresh(false);
        console.log("e", e);
      });
  }, [selectedWebsite, refresh, hardRefresh]);

  // const deleteRule = (rule) => {
  //   agent.Redirects.deleteRule(selectedWebsite._id, rule)
  //     .then(res => {
  //       setRefresh(true);
  //       enqueueSnackbar(I18n.t('snackbar.update_success'));
  //     }).catch(e => {
  //     enqueueSnackbar(I18n.t('snackbar.update_error', {msg: e.message}));
  //     setRefresh(true);
  //     console.log('e', e)
  //   })
  // }

  return (
    <div className={classes.learningCenter}>
      <MHeaderMetaTags title={I18n.t(`meta_tags.cro.orders.title`)} />
      <div className={classes.learningCenterContent}>
        <RedirectsSearchBar searchText={searchText} handleSearchTextChanged={(text) => setSearchText(text)} />
        <div className={classes.learningCenterInnerContent}>
          <OrdersList loaded={loaded} orders={orders} searchText={searchText} selectedWebsite={selectedWebsite} />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
