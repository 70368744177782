import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { IAd, IAdBusiness, IFile, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import InsertImageDialog from "../../../../../../Modals/InsertImageDialog/InsertImageDialog";
import agent from "../../../../../../../agent";
import MProfileImage from "../../../../../../../components/MProfileImage";
import MSelect from "../../../../../../../components/MSelect";
import adsBusinessImagePlaceholder from "../../../../../../../img/ads_business_image_placeholder.svg";
import theme from "../../../../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  businessSelectorWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  userSelectorWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  selectWrapper: {
    minWidth: 250,
    marginLeft: 10,
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  ad: IAd;

  onBusinessSelected: Function;
};

const AdBusinessSelector = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, ad, onBusinessSelected } = props;
  const [input, setInput] = React.useState(ad.business?.name || "");
  const [businesses, setBusinesses] = React.useState<IAdBusiness[]>([]);
  const [insertImageOpen, setInsertImageOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    agent.Ads.getBusinesses(selectedWebsite._id)
      .then((res) => {
        setBusinesses(res.businesses);
      })
      .catch((e) => {
        console.error("Error", e);
        enqueueSnackbar(e?.response?.body?.message || "Something went wrong");
        // returnToPreviousScreen();
      });
  }, [selectedWebsite]);

  const allBusinesses = [...businesses];
  if (input.length > 0) {
    allBusinesses.push({ _id: "new_business", name: `create: '${input}'`, input });
  }

  return (
    <div className={classes.businessSelectorWrapper}>
      <Typography className={classes.fieldTitle} variant={"body1"}>
        {I18n.t("ads.edit_ad.content.business_name")}
      </Typography>
      <div className={classes.userSelectorWrapper}>
        <MProfileImage
          onClick={() => setInsertImageOpen(true)}
          width={60}
          defaultImage={adsBusinessImagePlaceholder}
          image={ad?.business?.profileImageFile?.cdnUrl || ad?.business?.profileImageFile?.url}
        />
        <div className={classes.selectWrapper}>
          <MSelect
            height={45}
            borderWidth={1}
            borderColor={theme.palette.divider}
            borderRadius={25}
            placeholder={I18n.t("ads.edit_ad.content.business_name_ph")}
            // options={allUsers.map(user => ({...user, label: `${user.displayName} ${user.answers}`}))}
            options={allBusinesses}
            value={ad.business ? _.find(allBusinesses, { _id: ad.business?._id }) : null}
            searchable
            handleInputChange={(input) => {
              setInput(input);
              // if (input === '') {
              //   setAuthors([]);
              //   return;
              // }
              // agent.Blog.authorsAutoComplete(website._id, input)
              //   .then(results => {
              //     console.log('resulsts', results);
              //     setAuthors(results.authors);
              //   }).catch(e => {
              //   console.log(e);
              // });
            }}
            handleChange={(business) => {
              if (business._id === "new_business") {
                console.log("create new business", business.input);
                agent.Ads.newBusiness(selectedWebsite._id, business.input)
                  .then((res) => {
                    onBusinessSelected(res.business);
                  })
                  .catch((e) => {
                    console.error("Error", e);
                    enqueueSnackbar(e?.response?.body?.message || "Something went wrong");
                  });
                return;
              }
              onBusinessSelected(business);
            }}
            optionLabel={"name"}
            optionValue={"_id"}
            fullWidth
          />
        </div>
        {insertImageOpen && (
          <InsertImageDialog
            website={selectedWebsite}
            selectedImageFile={ad?.business?.profileImageFile || null}
            handleInsertClick={(file: IFile) => {
              agent.Ads.updateBusiness({ ...ad.business, profileImageFile: file })
                .then((res) => {
                  onBusinessSelected(res.business);
                })
                .catch((e) => {
                  console.error("Error", e);
                  enqueueSnackbar(e?.response?.body?.message || "Something went wrong");
                });

              // onImageChanged({
              //   ...image,
              //   url: file.url,
              //   width: file.desiredWidth,
              //   alt: file.alt
              // });
              setInsertImageOpen(false);
            }}
            handleCloseClick={(e) => setInsertImageOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(AdBusinessSelector);
