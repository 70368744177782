import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import theme from "../../themes/theme";
import { web } from "../../helpers/urlHelper";
import MTabs from "../../components/MTabs";
import { IWebsite } from "../../reducers/constants/objectTypes";
import QueryGroups from "./Pages/QueryGroups/QueryGroups";
import Positions from "./Pages/Positions/Positions";
import Content from "./Pages/Content/Content";
import SinglePageTraffic from "./Pages/SinglePageTraffic/SinglePageTraffic";

const useStyles = makeStyles(() => ({
  pageWrapper: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    width: "100vw",
  },
  appBar: {
    zIndex: 3,
    display: "flex",
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    top: 52,
  },
  websitePickerWrapper: {
    width: "18%",
  },
  tabsWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "64%",
    marginLeft: 0,
  },
  tabsRoot: {
    minHeight: 40,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular as any,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const Analysis = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.appBar}>
        <div className={classes.websitePickerWrapper}>{/* <HeaderWebsitePicker /> */}</div>
        <MTabs
          tabsWrapperClass={classes.tabsWrapper}
          activeBorderColor={theme.palette.primary.main}
          activeColor={theme.palette.text.primary}
          tabsRootClass={classes.tabsRoot}
          tabs={[
            // {
            //   url: web.analysisQueryGroups(),
            //   label: I18n.t('analysis.tabs.query_groups'),
            // },
            {
              url: web.analysisMetrics(),
              label: I18n.t("analysis.tabs.metrics"),
            },
            {
              url: web.analysisSinglePage(),
              label: I18n.t("analysis.tabs.single_page_traffic"),
            },
            {
              url: web.analysisContent(),
              label: I18n.t("analysis.tabs.content"),
            },
          ]}
        />
      </div>

      <div className={classes.content}>
        <Switch>
          <Route
            exact={false}
            path={web.analysisSinglePage()}
            render={() => <SinglePageTraffic selectedWebsite={selectedWebsite} />}
          />
          <Route
            exact={false}
            path={web.analysisMetrics()}
            render={() => <Positions selectedWebsite={selectedWebsite} />}
          />
          <Route exact={false} path={web.analysisQueryGroups()} component={QueryGroups} />
          <Route
            exact={false}
            path={web.analysisContent()}
            render={() => <Content selectedWebsite={selectedWebsite} />}
          />
          <Redirect exact from={"*"} to={web.analysisMetrics()} />
        </Switch>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Analysis);
