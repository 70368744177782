import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { push } from "connected-react-router";

import { web } from "../../../../../../helpers/urlHelper";
import { WIDGET_BUILDER } from "../../../../../../reducers/constants/actionTypes";
import { IWidget } from "../../../../../../reducers/constants/objectTypes";
import ArrowLeftIcon from "../../../../../../icons/ArrowLeftIcon";
import { WidgetTypeAlias } from "../../constants";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: 55,
    paddingRight: 17,
    paddingLeft: 17,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    padding: "17px 20px 14px 20px",
    borderRadius: "5px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    userSelect: "none",
  },
  widgetNameLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    userSelect: "none",
  },
  widgetName: {
    padding: "17px 20px 14px 20px",
    fontWeight: theme.typography.fontWeightBold as any,
    userSelect: "none",
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
  },
  verticalDivider: { height: "23px", marginTop: 16, width: 1.5, background: "#E5E5E5", borderRadius: "5px" },
  arrowIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  widget: IWidget;

  handleClose: () => void;
};

const mapStateToProps = (state) => ({
  widget: state.widgetBuilder.widget,
});

const mapDispatchToProps = (dispatch) => ({});

const WidgetEditorHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { widget } = props;
  const { handleClose } = props;

  return (
    <div className={classes.toolbar}>
      <div className={classes.wrapper}>
        <div className={classes.toolbarDetails}>
          <ArrowLeftIcon className={classes.arrowIcon} onClick={handleClose} />
        </div>
        <Typography className={classes.label}>
          {I18n.t(`widgets_builder.header.${widget.isTemplate ? "edit_template" : "edit_widget"}`)}
        </Typography>
        <div className={classes.verticalDivider}></div>
        <Typography className={classes.label}>{WidgetTypeAlias[widget.type]}</Typography>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetEditorHeader);
