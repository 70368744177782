import find from "lodash/find";
import orderBy from "lodash/orderBy";
import filter from "lodash/filter";
import _ from "lodash";
import { LEARN, TARGETED_POSTS } from "./constants/actionTypes";

const initialState = {
  topicsLoaded: false,
  topics: null,
  selectedTopic: null,
  selectedSubtopic: null,

  postsLoaded: false,
  posts: [],
  selectedPost: null,

  tags: [],
  tagsSearchField: "",

  keywords: [],
  keywordsSearchField: "",

  contentSourcePickerDialogOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LEARN.TOPICS_LOADED:
      return {
        ...state,
        topicsLoaded: true,
        topics: action.payload.topics,
      };
    case LEARN.TOPIC_SELECTED:
      return {
        ...state,
        selectedTopic: action.topic,
      };
    case LEARN.TOPIC_CREATED:
      return {
        ...state,
        topics: [...state.topics, action.topic],
      };
    case LEARN.TOPICS_POSITION_UPDATED: {
      const topics = state.topics.map((topic) => {
        const t = find(action.payload.topics, { _id: topic._id });
        if (t) {
          return { ...topic, order: t.order };
        }
        return topic;
      });
      return {
        ...state,
        topics: orderBy(topics, ["order"]),
      };
    }
    case LEARN.SUBTOPIC_CREATED: {
      const selectedTopic = { ...state.selectedTopic, children: [...state.selectedTopic.children, action.topic] };
      return {
        ...state,
        selectedTopic,
        topics: state.topics.map((topic) => {
          if (topic._id === selectedTopic._id) {
            return selectedTopic;
          }
          return topic;
        }),
      };
    }
    case LEARN.SUBTOPICS_POSITION_UPDATED: {
      const topics = state.topics.map((topic) => {
        if (topic._id === action.payload.topics[0].parent) {
          return {
            ...topic,
            children: orderBy(
              topic.children.map((child) => {
                const c = find(action.payload.topics, { _id: child._id });
                if (c) {
                  console.log(`${child.name} from ${child.order} to ${c.order}`);
                  return { ...child, order: c.order };
                }
                return child;
              }),
              ["order"]
            ),
          };
        }
        return topic;
      });

      return {
        ...state,
        topics,
        selectedTopic: find(topics, { _id: state.selectedTopic._id }),
      };
    }
    case LEARN.SUBTOPIC_SELECTED:
      return {
        ...state,
        selectedSubtopic: action.subtopic,
      };
    case LEARN.TOPIC_CHANGED:
      return {
        ...state,
        selectedTopic: state.selectedTopic._id === action.topic._id ? action.topic : state.selectedTopic,
        selectedSubtopic:
          state.selectedSubtopic && state.selectedSubtopic._id === action.topic._id
            ? action.topic
            : state.selectedSubtopic,
      };
    case LEARN.TOPIC_SAVED:
      return {
        ...state,
        topics: state.topics.map((topic) => {
          if (topic._id === action.topic._id) {
            return action.topic;
          }
          if (topic._id === action.topic.parent) {
            return {
              ...topic,
              children: topic.children.map((child) => {
                if (child._id === action.topic._id) {
                  return action.topic;
                }
                return child;
              }),
            };
          }
          return topic;
        }),
        selectedTopic: state.selectedTopic._id === action.topic._id ? action.topic : state.selectedTopic,
        selectedSubtopic:
          state.selectedSubtopic && state.selectedSubtopic._id === action.topic._id
            ? action.topic
            : state.selectedSubtopic,
      };
    case LEARN.POSTS_LOADED:
      return {
        ...state,
        postsLoaded: true,
        posts: action.payload.posts,
      };
    case LEARN.POST_CREATED:
      return {
        ...state,
        posts: [...state.posts, action.post],
      };
    case LEARN.POST_SELECTED:
      return {
        ...state,
        selectedPost: action.post,
      };
    case LEARN.POST_CHANGED:
      return {
        ...state,
        selectedPost: action.post,
      };
    case LEARN.POST_SAVED:
      return {
        ...state,
        selectedPost: action.post,
        posts: state.posts.map((post) => {
          if (post._id === action.post._id) {
            return action.post;
          }
          return post;
        }),
      };
    case LEARN.POSTS_POSITION_UPDATED: {
      const posts = state.posts.map((post) => {
        const p = find(action.payload.posts, { _id: post._id });
        if (p) {
          return { ...post, order: p.order };
        }
        return post;
      });
      return {
        ...state,
        posts: orderBy(posts, ["order"]),
      };
    }
    case LEARN.ON_TAGS_LOADED:
      return {
        ...state,
        tags: action.payload.tags,
      };
    case LEARN.ON_TAG_SELECTED:
      return {
        ...state,
        selectedPost: {
          ...state.selectedPost,
          tags: [...state.selectedPost.tags, action.tag],
        },
      };
    case LEARN.ON_TAG_REMOVED:
      return {
        ...state,
        selectedPost: {
          ...state.selectedPost,
          tags: filter(state.selectedPost.tags, (tag) => tag.name !== action.tag.name),
        },
      };
    case LEARN.ON_TAGS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        tagsSearchField: action.input,
      };
    case LEARN.ON_NEW_TAG_CREATED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        tags: _.uniqBy([...state.tags, action.payload.tag], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        tagsSearchField: "",
        selectedPost: {
          ...state.selectedPost,
          tags: _.uniqBy([...state.selectedPost.tags, action.payload.tag], "_id"),
        },
      };
    case LEARN.ON_KEYWORDS_LOADED:
      return {
        ...state,
        keywords: action.payload.keywords,
      };
    case LEARN.ON_KEYWORD_SELECTED:
      return {
        ...state,
        selectedPost: {
          ...state.selectedPost,
          keywords: [...state.selectedPost.keywords, action.keyword],
        },
      };
    case LEARN.ON_KEYWORD_REMOVED:
      return {
        ...state,
        selectedPost: {
          ...state.selectedPost,
          keywords: filter(state.selectedPost.keywords, (keyword) => keyword.name !== action.keyword.name),
        },
      };
    case LEARN.ON_KEYWORDS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        keywordsSearchField: action.input,
      };
    case LEARN.ON_NEW_KEYWORD_CREATED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        keywords: _.uniqBy([...state.keywords, action.payload.keyword], "_id").sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        keywordsSearchField: "",
        selectedPost: {
          ...state.selectedPost,
          keywords: _.uniqBy([...state.selectedPost.keywords, action.payload.keyword], "_id"),
        },
      };
    case LEARN.SET_CONTENT_SOURCE_PICKER_DIALOG_OPEN:
      return {
        ...state,
        contentSourcePickerDialogOpen: action.open,
      };
    case LEARN.ON_SOURCE_URL_ADDED:
      return {
        ...state,
        selectedPost: {
          ...state.selectedPost,
          sourceURLs: [...(state.selectedPost.sourceURLs || []), action.url],
        },
      };
    case LEARN.ON_SOURCE_URL_REMOVED:
      return {
        ...state,
        selectedPost: {
          ...state.selectedPost,
          sourceURLs: filter(state.selectedPost.sourceURLs, (url) => url !== action.url),
        },
      };
    default:
      return state;
  }
};
