import React from "react";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Link from "@material-ui/core/Link";
import ClassNames from "classnames";
import { Variant } from "@material-ui/core/styles/createTypography";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  typography: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  link: {},
}));

type propTypes = {
  href: string;
  newTab?: boolean;
  children: string | React.ReactNode;
  typographyClassName?: string;
  typographyColor?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  linkClassName?: string;
  variant?: Variant;
  underline?: "none" | "hover" | "always";

  onClick?: Function;
};

const RLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const MLink = (props: propTypes) => {
  const classes = useStyles(props);
  const {
    href,
    children,
    typographyColor = "inherit",
    typographyClassName = null,
    linkClassName = null,
    variant = "body2",
    underline = "hover",
    newTab = false,
  } = props;
  const { onClick } = props;

  const innerLinkProps = href && href.includes("http") ? {} : { to: href, component: RLink };

  return (
    <Typography
      color={typographyColor}
      variant={variant}
      className={ClassNames(classes.typography, typographyClassName)}
    >
      <Link
        color="inherit"
        onClick={(e: any) => onClick?.(e)}
        href={href}
        target={newTab ? "_blank" : ""}
        rel={newTab ? "noopener" : ""}
        underline={underline}
        className={ClassNames(classes.link, linkClassName)}
        {...innerLinkProps}
      >
        {children}
      </Link>
    </Typography>
  );
};

export default MLink;
