"use strict";
import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
import { Transforms } from "slate";
import { useSlate } from "slate-react";
import { ComponentType } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  activeIcon: {
    fill: theme.palette.primary.main,
  },
}));

type propTypes = {
  tooltip: string;
  align: string;
  Icon: ComponentType<any>;
};

function AlignmentButton(props: propTypes) {
  const classes = useStyles(props);
  const editor = useSlate();
  const { Icon, align, tooltip } = props;

  const toggleAlignment = (editor, alignment) => {
    Transforms.setNodes(editor, {
      alignment,
    });
  };

  return (
    <Tooltip enterDelay={2000} title={tooltip}>
      <IconButton
        className={classes.button}
        disableRipple
        onMouseDown={(event) => {
          event.preventDefault();
          toggleAlignment(editor, align);
        }}
        aria-label={`align ${align}`}
      >
        <Icon className={ClassNames(classes.icon, false && classes.activeIcon)} />
      </IconButton>
    </Tooltip>
  );
}

export default AlignmentButton;
