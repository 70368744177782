import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { IAnalyticsDevice, IAnalyticsSession } from "../../../../reducers/constants/objectTypes";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RoomIcon from "@material-ui/icons/Room";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import { I18n } from "react-redux-i18n";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import TabletIcon from "@material-ui/icons/Tablet";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import moment from "moment";
import { DATE_FORMAT } from "../../../../reducers/constants/consts";
import Link from "@material-ui/core/Link";
import localeHelper from "../../../../helpers/localeHelper";

const useStyles = makeStyles((theme: Theme) => ({
  profile: {
    margin: "0px 10px 20px 0",
    minHeight: 250,
  },
  root: {
    paddingTop: 0,
  },
  subHeader: {
    height: 60,
    display: "flex",
    alignItems: "center",
  },
  url: {
    wordWrap: "break-word",
  },
}));

type PropTypes = {
  loaded: boolean;
  device: IAnalyticsDevice;
  lastSession: IAnalyticsSession;
};
const UserEventsProfile = (props: PropTypes) => {
  const classes = useStyles(props);
  const { loaded, device, lastSession } = props;

  return (
    <Paper className={classes.profile}>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        <ListSubheader className={classes.subHeader}>
          <Typography variant={"subtitle2"}>{I18n.t("analytics.user_events.user_profile")}</Typography>
        </ListSubheader>
        {loaded && (
          <>
            <ListItem>
              <ListItemIcon>
                <PermIdentityIcon />
              </ListItemIcon>
              <ListItemText primary={device.device_uuid} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <RoomIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${lastSession.city}, ${localeHelper.getCountryFromCountryCode(lastSession.country) || ""}`}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FlightLandIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    className={classes.url}
                    href={device.first_page}
                    onClick={(e) => e.stopPropagation()}
                    target={"_blank"}
                    // className={classes.topicName}
                    variant="subtitle2"
                  >
                    {device.first_page}
                  </Link>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={moment(device.last_seen || device.created_at).format(DATE_FORMAT.COMPLETE)} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                {device.device_type === "desktop" && <DesktopWindowsIcon />}
                {device.device_type === "tablet" && <TabletIcon />}
                {device.device_type === "mobile" && <SmartphoneIcon />}
              </ListItemIcon>
              <ListItemText
                primary={`${device.manufacturer ? `${device.manufacturer} - ` : ""}${device.model}, ${
                  device.operating_system
                } ${device.operating_system_version}`}
              />
            </ListItem>
          </>
        )}
      </List>
    </Paper>
  );
};

export default UserEventsProfile;
