import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { Divider } from "@material-ui/core";
import _ from "lodash";
import { IFile, IWebsite, IWriterBio } from "../../../../../../reducers/constants/objectTypes";
import { WRITER_BIO } from "../../../../../../reducers/constants/actionTypes";
import TextFieldMaxLength from "../../../../../../components/TextFieldMaxLength";
import AImagePicker from "../../../../../../components/AImagePicker";
import WriterRole from "./WriterRole";
import MChipsTextField from "../../../../../../components/MChipsTextField";
import ImagePicker from "../../../../../WebsiteConfigurations/components/ImagePicker";
import SelectRow from "../../../../../WebsiteConfigurations/components/SelectRow";
import { fontOptions } from "../../../../../WebsiteConfigurations/WebsiteEditor/consts/CssConsts";
import BioCategories from "../../components/BioCategories";
import MSelect from "../../../../../../components/MSelect";
import YesNoSelect from "./YesNoSelect";

const useStyles = makeStyles((theme: Theme) => ({
  sectionWrapper: {
    borderRadius: 0,
    border: "none",
    marginTop: 10,
  },
  innerWrapper: {
    padding: "0px 30px 30px 30px",
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    textTransform: "capitalize",
  },
  nameField: {
    marginTop: 10,
    marginBottom: 0,
  },
  titleWrapper: {
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 20,
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    "&:first-child": {
      marginTop: 0,
    },
  },
  expertiseWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
  },
  columnWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  imageWrapper: {
    minWidth: 140,
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 15,
  },
  textFieldWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  textFieldWrapperTop: {
    justifyContent: "flex-start",
    paddingTop: 10,
  },
  infoText: {
    marginTop: 10,
  },
  roleTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 10,
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  fieldTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    minWidth: 140,
    marginRight: 15,
  },
  checkBoxWrapper: {
    border: `${theme.palette.divider} solid 1px`,
    marginLeft: 0,
    paddingRight: 10,
    minHeight: 35,
  },
  imageRow: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    marginRight: 15,
    "&:last-child": {
      marginRight: 0,
    },
  },

  imageFieldTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(14),
  },
  imageFieldDimensions: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
  },
  imageFieldWrapper: {
    display: "flex",
    marginBottom: 15,
    gap: 10,
  },
  inputRowWrapper: {
    marginTop: 30,
    display: "flex",
    gap: 20,
  },
  categories: {
    width: "100%",
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  general: IWriterBio["general"];

  metaDescription: string;

  onPropertyChanged: (general) => null;
  onMetaDescriptionChanged: (metaDescription) => null;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  general: state.writerBio.general,
  metaDescription: state.writerBio.metaDescription,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (appearance) =>
    dispatch({ type: WRITER_BIO.ON_PROPERTY_CHANGED, name: "general", value: appearance }),
  onMetaDescriptionChanged: (metaDescription) =>
    dispatch({
      type: WRITER_BIO.ON_PROPERTY_CHANGED,
      name: "metaDescription",
      value: metaDescription,
    }),
});

const PageTopSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, general, metaDescription, onMetaDescriptionChanged } = props;
  const { onPropertyChanged } = props;

  console.log("PageTopSection-metaDescription", metaDescription);
  return (
    <>
      <Paper elevation={0} className={classes.sectionWrapper}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manager.writers_bio.page_top")}
          </Typography>
        </div>

        <div className={classes.innerWrapper}>
          <div className={classes.imageRow}>
            <div className={classes.imageFieldWrapper}>
              <Typography variant={"body1"} className={classes.imageFieldTitle}>
                {I18n.t("manager.writers_bio.profile_pic")}
              </Typography>
              <Typography variant={"body1"} className={classes.imageFieldDimensions}>
                {I18n.t("website.login_dimensions")}
              </Typography>
            </div>
            <ImagePicker
              key={`login-image`}
              selectedWebsite={selectedWebsite}
              image={general?.profileImage}
              objectFit={"contain"}
              imageHeight={"152px"}
              imageWidth={"152px"}
              forcesImageSize={{ minWidth: 300, minHeight: 300, maxWidth: 600, maxHeight: 600 }}
              onImageChanged={(image) => {
                onPropertyChanged({ ...general, profileImage: image });
              }}
            />
          </div>
          <div className={ClassNames(classes.inputRowWrapper, classes.categories)}>
            <BioCategories />
          </div>
          <div className={classes.inputRowWrapper}>
            <TextFieldMaxLength
              titleAndLengthLocation={"top"}
              key={`page_top_name`}
              value={general?.displayName}
              placeholder={I18n.t("manager.writers_bio.name_ph")}
              title={I18n.t("manager.writers_bio.name")}
              maxLength={45}
              height={37}
              borderRadius={4}
              handleValueChange={(name) => {
                onPropertyChanged({
                  ...general,
                  name,
                  displayName: name,
                  firstName: _.words(name)?.[0] || "",
                  lastName: _.last(_.words(name)) || "",
                });
              }}
            />
            <div className={classes.columnWrapper}>
              <Typography className={classes.roleTitle} variant={"subtitle2"}>
                {I18n.t("manager.writers_bio.role")}
              </Typography>
              <WriterRole
                role={general.role}
                onRoleChanged={(role) => onPropertyChanged({ ...general, role: role.value })}
              />
            </div>
          </div>
          <div className={classes.inputRowWrapper}>
            <TextFieldMaxLength
              titleAndLengthLocation={"top"}
              key={`page_top_title`}
              value={general?.title}
              placeholder={I18n.t("manager.writers_bio.title_ph")}
              title={I18n.t("manager.writers_bio.title")}
              maxLength={45}
              height={37}
              borderRadius={4}
              handleValueChange={(title) => {
                onPropertyChanged({ ...general, title });
              }}
            />
            <div className={classes.columnWrapper}>
              <Typography className={classes.roleTitle} variant={"subtitle2"}>
                {I18n.t("manager.writers_bio.show_title_in_article")}
              </Typography>
              <YesNoSelect
                selected={general.showTitleInArticle}
                handleSelectedChange={(selected) => onPropertyChanged({ ...general, showTitleInArticle: selected })}
              />
            </div>
          </div>
          <Divider className={classes.divider} />
          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            value={metaDescription}
            placeholder={I18n.t("manager.writers_bio.meta_description_ph")}
            title={I18n.t("manager.writers_bio.meta_description")}
            maxLength={160}
            height={71}
            multiLine
            borderRadius={4}
            handleValueChange={(md) => onMetaDescriptionChanged(md)}
          />
          <Divider className={classes.divider} />
          <div className={classes.expertiseWrapper}>
            <div className={classes.columnWrapper}>
              <Typography className={classes.fieldTitle} variant={"subtitle2"}>
                {I18n.t("manager.writers_bio.expertise_optional")}
              </Typography>
              <MChipsTextField
                chips={general.expertiseList}
                onChipsChanged={(expertiseList) => onPropertyChanged({ ...general, expertiseList })}
              />
            </div>

            <div className={classes.columnWrapper}>
              <Typography className={classes.fieldTitle} variant={"subtitle2"}>
                {I18n.t("manager.writers_bio.education_optional")}
              </Typography>
              <MChipsTextField
                chips={general.educationList}
                onChipsChanged={(educationList) => onPropertyChanged({ ...general, educationList })}
              />
            </div>
          </div>
        </div>
      </Paper>
      <Paper elevation={0} className={classes.sectionWrapper}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
            {I18n.t("manager.writers_bio.socials")}
          </Typography>
        </div>
        <div className={classes.innerWrapper}>
          <div className={classes.inputRowWrapper}>
            <TextFieldMaxLength
              key={`facebook`}
              titleAndLengthLocation={"top"}
              value={general?.socials.facebook}
              placeholder={I18n.t("manager.writers_bio.url_ph")}
              title={I18n.t("manager.writers_bio.facebook")}
              height={37}
              borderRadius={4}
              handleValueChange={(facebook) => {
                onPropertyChanged({ ...general, socials: { ...general.socials, facebook } });
              }}
            />
            <TextFieldMaxLength
              key={`twitter`}
              titleAndLengthLocation={"top"}
              value={general?.socials.twitter}
              placeholder={I18n.t("manager.writers_bio.url_ph")}
              title={I18n.t("manager.writers_bio.twitter")}
              height={37}
              borderRadius={4}
              handleValueChange={(twitter) => {
                onPropertyChanged({ ...general, socials: { ...general.socials, twitter } });
              }}
            />
          </div>
          <div className={classes.inputRowWrapper}>
            <TextFieldMaxLength
              key={`youtube`}
              titleAndLengthLocation={"top"}
              value={general?.socials.youTube}
              placeholder={I18n.t("manager.writers_bio.url_ph")}
              title={I18n.t("manager.writers_bio.youtube")}
              height={37}
              borderRadius={4}
              handleValueChange={(youTube) => {
                onPropertyChanged({ ...general, socials: { ...general.socials, youTube } });
              }}
            />
            <TextFieldMaxLength
              key={`linkedin`}
              titleAndLengthLocation={"top"}
              value={general?.socials.linkedin}
              placeholder={I18n.t("manager.writers_bio.url_ph")}
              title={I18n.t("manager.writers_bio.linkedin")}
              height={37}
              borderRadius={4}
              handleValueChange={(linkedin) => {
                onPropertyChanged({ ...general, socials: { ...general.socials, linkedin } });
              }}
            />
          </div>
        </div>
      </Paper>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTopSection);
