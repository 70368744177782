import React from "react";
import { makeStyles } from "@material-ui/styles";
import { InputBase, Theme, Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
  },
  marginSelectorWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  marginRightContainer: {
    display: "flex",
  },
  marginBox: {
    width: 50,
    height: "auto",
    marginRight: 0,
    borderRadius: "3px 0 0 3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.divider}`,
  },
  marginIcon: {
    width: 14,
    height: 14,
    border: `1px solid #C4C4C4`,
    backgroundColor: "#F5F6F8",
  },
  marginIconTopFill: { borderTopColor: theme.palette.primary.main, borderTopWidth: 2 },
  marginIconBottomFill: { borderBottomColor: theme.palette.primary.main, borderBottomWidth: 2 },
  marginInputsWrapper: {
    width: "130px",
    display: "flex",
    "& $marginInputWrapper:first-child $marginInput": {
      borderRadius: 0,
      borderRight: "none",
      borderLeft: "none",
      "& .MuiInputBase-input": {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    "& $marginInputWrapper:last-child $marginInput": {
      borderLeft: "none",
      borderRadius: "0 3px 3px 0",
    },
  },
  marginInputWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 3,
  },
  marginInput: {
    padding: "5px 0px 5px 10px",
    border: `1px solid ${theme.palette.divider}`,
    fontSize: 14,
    color: theme.palette.text.secondary,
    "& .MuiInputBase-input": {
      padding: 0,
      textAlign: "center",
    },
  },
  marginInputLabel: {
    fontSize: 10,
  },
}));

type PropTypes = {
  margin: {
    top: number;
    bottom: number;
  };
  className?: string;
  onChange: (margin: { top: number; bottom: number }) => void;
};

const MMarginPicker = (props: PropTypes) => {
  const { margin, className, onChange } = props;
  const [currentMarginEditing, setCurrentMarginEditing] = React.useState<"top" | "bottom">(null);
  const classes = useStyles(props);

  return (
    <div className={classNames(className, classes.marginSelectorWrapper)}>
      <Typography className={classes.title}>{I18n.t("rich_text_editor.entail_widgets.margin")}</Typography>
      <div className={classes.marginRightContainer}>
        <div className={classes.marginBox}>
          <div
            className={classNames(
              classes.marginIcon,
              currentMarginEditing === "top" && classes.marginIconTopFill,
              currentMarginEditing === "bottom" && classes.marginIconBottomFill
            )}
          />
        </div>
        <div className={classes.marginInputsWrapper}>
          <Tooltip title={I18n.t("rich_text_editor.entail_widgets.add_margin_top")} enterDelay={500} placement="bottom">
            <div className={classes.marginInputWrapper}>
              <InputBase
                className={classes.marginInput}
                value={margin.top}
                type="number"
                onFocus={() => setCurrentMarginEditing("top")}
                onBlur={() => setCurrentMarginEditing(null)}
                onChange={(e) =>
                  onChange({
                    ...margin,
                    top: parseInt(e.target.value),
                  })
                }
                placeholder="0"
                fullWidth
              />
            </div>
          </Tooltip>
          <Tooltip
            title={I18n.t("rich_text_editor.entail_widgets.add_margin_bottom")}
            enterDelay={500}
            placement="bottom"
          >
            <div className={classes.marginInputWrapper}>
              <InputBase
                className={classes.marginInput}
                value={margin.bottom}
                type="number"
                onFocus={() => setCurrentMarginEditing("bottom")}
                onBlur={() => setCurrentMarginEditing(null)}
                onChange={(e) =>
                  onChange({
                    ...margin,
                    bottom: parseInt(e.target.value),
                  })
                }
                placeholder="0"
                fullWidth
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default MMarginPicker;
