import * as React from "react";
import { connect } from "react-redux";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { IWebsite, IWebsiteHomepageProps, IWebsiteThemeProps } from "../../../../../reducers/constants/objectTypes";
import ImagePicker from "../../../components/ImagePicker";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  tabsWrap: {
    border: `solid 1px ${theme.palette.divider}`,
    padding: 10,
    marginBottom: 10,
  },
  closableRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  bannersTitle: {
    marginBottom: 20,
  },
  tabTitle: {},
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  closeIcon: {
    cursor: "pointer",
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  hidden: {
    visibility: "hidden",
  },
  iconTitle: {
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

type PropTypes = {
  selectedWebsite: IWebsite;
  theme: IWebsiteThemeProps;
  homepage: IWebsiteHomepageProps;
  onPropertyChanged: (value) => void;
  errorTabs?: any;
};

const TabsInput = (props: PropTypes) => {
  const classes = useStyles(props);
  const { homepage, selectedWebsite, onPropertyChanged, errorTabs = [] } = props;

  return (
    <>
      {[...(homepage?.tabsSectionKeepshoppers?.tabs || []), { title: "", icon: null, url: "" }].map((item, index) => (
        <div key={`_${index}_`} className={classes.tabsWrap}>
          <div className={classes.closableRow}>
            <Typography className={classes.tabTitle}>
              {I18n.t("website.homepage.templates.keepshoppers.tabs_section.tab", { num: index + 1 })}
            </Typography>
            <CloseIcon
              className={ClassNames(
                classes.closeIcon,
                (homepage?.tabsSectionKeepshoppers?.tabs || []).length === index && classes.hidden
              )}
              onClick={() => {
                const updatedTabs = [...homepage.tabsSectionKeepshoppers.tabs].filter((x, i) => index !== i);
                onPropertyChanged({
                  ...homepage,
                  tabsSectionKeepshoppers: { ...homepage?.tabsSectionKeepshoppers, tabs: updatedTabs },
                });
              }}
            />
          </div>
          <div className={classes.container}>
            <div className={classes.row}>
              <TextFieldMaxLength
                titleAndLengthLocation={"top"}
                value={item?.title || ""}
                error={errorTabs.includes(`tab_${index}_title`)}
                placeholder={""}
                title={I18n.t("website.homepage.templates.keepshoppers.tabs_section.tab_title")}
                maxLength={200}
                height={37}
                borderRadius={4}
                handleValueChange={(value) => {
                  if ((homepage?.tabsSectionKeepshoppers?.tabs || []).length === index) {
                    const updatedTabs = [
                      ...(homepage?.tabsSectionKeepshoppers?.tabs || []),
                      {
                        title: value,
                        icon: item.icon,
                        url: item.url,
                      },
                    ];
                    onPropertyChanged({
                      ...homepage,
                      tabsSectionKeepshoppers: { ...homepage?.tabsSectionKeepshoppers, tabs: updatedTabs },
                    });
                    return;
                  }
                  const updatedTabs = [...(homepage?.tabsSectionKeepshoppers?.tabs || [])];
                  updatedTabs[index].title = value;
                  onPropertyChanged({
                    ...homepage,
                    tabsSectionKeepshoppers: { ...homepage?.tabsSectionKeepshoppers, tabs: updatedTabs },
                  });
                }}
                marginBottom={30}
                marginRight={30}
              />

              <TextFieldMaxLength
                titleAndLengthLocation={"top"}
                value={item?.url || ""}
                placeholder={""}
                title={I18n.t("website.homepage.templates.keepshoppers.tabs_section.tab_url")}
                maxLength={200}
                height={37}
                borderRadius={4}
                handleValueChange={(value) => {
                  if ((homepage?.tabsSectionKeepshoppers?.tabs || []).length === index) {
                    const updatedTabs = [
                      ...(homepage?.tabsSectionKeepshoppers?.tabs || []),
                      {
                        title: item.title,
                        icon: item.icon,
                        url: value,
                      },
                    ];
                    onPropertyChanged({
                      ...homepage,
                      tabsSectionKeepshoppers: { ...homepage?.tabsSectionKeepshoppers, tabs: updatedTabs },
                    });
                    return;
                  }
                  const updatedTabs = [...(homepage?.tabsSectionKeepshoppers?.tabs || [])];
                  updatedTabs[index].url = value;
                  onPropertyChanged({
                    ...homepage,
                    tabsSectionKeepshoppers: { ...homepage?.tabsSectionKeepshoppers, tabs: updatedTabs },
                  });
                }}
                marginBottom={30}
              />
            </div>
            <Typography variant={"subtitle2"} className={classes.iconTitle}>
              {I18n.t("website.homepage.templates.keepshoppers.tabs_section.tab_icon")}
            </Typography>

            <ImagePicker
              error={errorTabs.includes(`tab_${index}_icon`)}
              selectedWebsite={selectedWebsite}
              image={item.icon}
              objectFit={"contain"}
              imageHeight={"70px"}
              imageWidth={"70px"}
              onImageChanged={(value) => {
                if ((homepage?.tabsSectionKeepshoppers?.tabs || []).length === index) {
                  const updatedTabs = [
                    ...(homepage?.tabsSectionKeepshoppers?.tabs || []),
                    {
                      title: item.title,
                      icon: value,
                      url: item.url,
                    },
                  ];
                  onPropertyChanged({
                    ...homepage,
                    tabsSectionKeepshoppers: { ...homepage?.tabsSectionKeepshoppers, tabs: updatedTabs },
                  });
                  return;
                }
                const updatedTabs = [...(homepage?.tabsSectionKeepshoppers?.tabs || [])];
                updatedTabs[index].icon = value;
                onPropertyChanged({
                  ...homepage,
                  tabsSectionKeepshoppers: { ...homepage?.tabsSectionKeepshoppers, tabs: updatedTabs },
                });
              }}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TabsInput);
