import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import TextInputMentions from "./TextInputMentions";
import { LENGTHS } from "../../../reducers/constants/consts";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";
import TextInputAndDesign from "./TextInputAndDesign";
import {
  IWebsiteComparisonTop10Props,
  IWebsiteComparisonTopAlternativeProps,
  IWebsiteProductAlternativeProps,
  IWebsiteProductComparisonProps,
  IWebsiteProductPageProps,
  IWebsiteProductReviewProps,
} from "../../../reducers/constants/objectTypes";
import TextInputToolAndDesign from "./TextInputToolAndDesign";

const useStyles = makeStyles((theme: Theme) => ({
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));
type PropTypes = {
  emptyFields: any;
  page:
    | IWebsiteProductReviewProps
    | IWebsiteProductPageProps
    | IWebsiteProductAlternativeProps
    | IWebsiteProductComparisonProps
    | IWebsiteComparisonTop10Props
    | IWebsiteComparisonTopAlternativeProps;
  onPropertyChanged: (property) => void;
};

const WebsiteProductPagesMainPage = (props: PropTypes) => {
  const classes = useStyles(props);
  const { page, onPropertyChanged, emptyFields } = props;
  return (
    <>
      <div className={classes.inputRowWrapper}>
        <TextFieldMaxLength
          error={emptyFields.includes("name")}
          titleAndLengthLocation={"top"}
          value={page.name}
          placeholder={I18n.t("website.name_ph")}
          title={I18n.t("website.name")}
          height={37}
          borderRadius={4}
          handleValueChange={(value) => onPropertyChanged({ ...page, name: value })}
        />

        <TextFieldMaxLength
          error={emptyFields.includes("mainPage.slug")}
          titleAndLengthLocation={"top"}
          value={page.mainPage.slug}
          placeholder={I18n.t("website.slug_title_ph")}
          title={I18n.t("website.slug_title")}
          height={37}
          borderRadius={4}
          handleValueChange={(value) => onPropertyChanged({ ...page, mainPage: { ...page.mainPage, slug: value } })}
        />
      </div>
      <Divider className={classes.divider} />
      <TextInputToolAndDesign
        error={emptyFields.includes("mainPage.title")}
        textFieldTitle={I18n.t("website.title")}
        text={page.mainPage?.title}
        handleTextChanged={(text) => onPropertyChanged({ ...page, mainPage: { ...page.mainPage, title: text } })}
        variant={"h1"}
      />

      <TextInputMentions
        tags={[]}
        error={emptyFields.includes("mainPage.metaTitle")}
        placeholder={I18n.t("website.meta_title_ph")}
        title={I18n.t("website.meta_title")}
        maxLength={LENGTHS.META_TITLE}
        titleAndLengthLocation={"top"}
        text={page.mainPage.metaTitle}
        onTextChanged={(value) => onPropertyChanged({ ...page, mainPage: { ...page.mainPage, metaTitle: value } })}
      />
      <Divider className={classes.divider} />
      <TextInputToolAndDesign
        error={emptyFields.includes("mainPage.description")}
        textFieldTitle={I18n.t("website.description")}
        variant={"h2"}
        text={page.mainPage?.description}
        handleTextChanged={(text) => onPropertyChanged({ ...page, mainPage: { ...page.mainPage, description: text } })}
      />

      <TextInputMentions
        tags={[]}
        error={emptyFields.includes("mainPage.metaDescription")}
        placeholder={I18n.t("website.meta_description_ph")}
        title={I18n.t("website.meta_description")}
        maxLength={LENGTHS.META_DESCRIPTION}
        titleAndLengthLocation={"top"}
        text={page.mainPage.metaDescription}
        onTextChanged={(value) =>
          onPropertyChanged({ ...page, mainPage: { ...page.mainPage, metaDescription: value } })
        }
      />
      <Divider className={classes.divider} />

      <TextInputToolAndDesign
        error={emptyFields.includes("mainPage.listTitle")}
        textFieldTitle={I18n.t("website.list_title")}
        variant={"h2"}
        text={page.mainPage?.listTitle}
        handleTextChanged={(text) => onPropertyChanged({ ...page, mainPage: { ...page.mainPage, listTitle: text } })}
      />
    </>
  );
};

export default WebsiteProductPagesMainPage;
