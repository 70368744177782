import { Divider, Slider, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldProperty from "./itemProperties/components/TextFieldProperty";
import RequiredFieldProperty from "./itemProperties/components/RequiredFieldProperty";
import TextareaProperty from "./itemProperties/components/TextareaProperty";
import { IWidgetNumbersRatingFieldProps } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({
      display: "flex",
      flexDirection: "column",
      gap: 10,
    }),
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
    sliderHeader: {
      display: "flex",
      alignItems: "center",
      height: 55,
      padding: 15,
      width: "100%",
    },
    slider: {
      padding: 10,
      color: "black",
      width: "calc(100% - 50px)",
      margin: 15,
    },
    title: {
      color: palette.text.primary,
      flex: 1,
    },
    rangeNumbers: {
      color: palette.text.primary,
      alignSelf: "end",
    },
    rangeNumberColor: {
      color: "rgb(67, 83, 255)",
    },
    thumb: {
      background: "#fff",
      "&~&": {
        background: "#fff",
      },
    },
    mark: {
      background: "#fff",
    },
    rail: {
      background: "linear-gradient(to right, #fff, #fff 50%, #fff 50%, #fff);",
    },
    track: {
      background: "rgb(67, 83, 255)",
    },
    valueLabel: {
      "&>*": {
        background: "rgb(67, 83, 255)",
      },
    },
    boxNumbers: { width: "100%", padding: "0px 15px 0px 15px;" },
    sliderWrapper: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  };
});

type PropTypes = {
  properties: IWidgetNumbersRatingFieldProps;
  onPropertyChange(properties: IWidgetNumbersRatingFieldProps): void;
};

const NumbersWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;
  const classes = useStyles(props);

  const handleRangeChange = (event: any, newValue: number | number[]) => {
    const rangeMin = newValue[0];
    const rangeMax = newValue[1];

    onPropertyChange({ ...properties, rangeMax, rangeMin });
  };

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.label")}
          placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.label_ph")}
          onChange={(label) => onPropertyChange({ ...properties, label })}
          value={properties?.label}
        />
        <Divider className={classes.divider} />
        <TextareaProperty onChange={(hint) => onPropertyChange({ ...properties, hint })} value={properties?.hint} />

        <div style={{ width: "100%" }}>
          <div className={classes.sliderHeader}>
            <Typography className={classes.title}>Range</Typography>

            <Typography className={classes.rangeNumbers}>
              <span className={classes.rangeNumberColor}>{properties.rangeMin}</span>
              <span>-</span>
              <span className={classes.rangeNumberColor}>{properties.rangeMax}</span>
            </Typography>
          </div>
          <div className={classes.sliderWrapper}>
            <Slider
              max={10}
              min={0}
              classes={{
                thumb: classes.thumb,
                rail: classes.rail,
                track: classes.track,
                valueLabel: classes.valueLabel,
                mark: classes.mark,
              }}
              marks
              valueLabelDisplay="auto"
              value={[properties.rangeMin || 0, properties.rangeMax || 10]}
              onChange={(event, value) => handleRangeChange(event, value)}
            />
          </div>
        </div>
        <Divider className={classes.divider} />
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.left_caption")}
          placeholder={`${I18n.t("widgets_builder.widget_editor.Form.fields.properties.left_caption")}...`}
          onChange={(leftCaption) => onPropertyChange({ ...properties, leftCaption })}
          value={properties?.leftCaption}
        />
        <Divider className={classes.divider} />
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.right_caption")}
          placeholder={`${I18n.t("widgets_builder.widget_editor.Form.fields.properties.right_caption")}...`}
          onChange={(rightCaption) => onPropertyChange({ ...properties, rightCaption })}
          value={properties?.rightCaption}
        />
      </div>

      <RequiredFieldProperty
        required={properties.required}
        onChange={(required: boolean) => onPropertyChange({ ...properties, required })}
      />
    </div>
  );
};

export default NumbersWidgetItem;
