import React, { useEffect, useMemo, useState } from "react";
import { Dialog, DialogContent, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import XIcon from "../../icons/XIcon";
import { IKeywordStrategy, IWebsite } from "../../reducers/constants/objectTypes";
import { connect } from "react-redux";
import MButton from "../../components/MButton";
import MSelect from "../../components/MSelect";
import { STRATEGY_POST_TYPES } from "../../helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    padding: 0,
    width: "50%",
    "& .MuiDialogContent-root": {
      padding: 24,
    },
    minHeight: 500,
    overflow: "hidden",
    backgroundColor: "transparent",
    boxShadow: "none",
    borderRadius: 0,
    border: "none",
  },
  dialogTitle: {
    fontSize: "25px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  firstChildWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  closeButton: {
    padding: 0,
    marginRight: 0,
    alignSelf: "flex-start",
  },
  headerIcon: {
    width: 20,
    height: 20,
    fill: theme.palette.text.primary,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    justifyContent: "space-between",
    overflow: "auto",
    maxHeight: 247,
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
  },
  charCounter: {
    display: "flex",
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.disabled,
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    width: "50%",
  },
  textField: {
    margin: 0,
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: 14,
    zIndex: 100,
  },
  buttonWrapper: {
    marginTop: "25px",
    marginLeft: "auto",
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
    borderRadius: "20px",
    padding: "10px 20px",
    height: 36,
  },
}));

type PropTypes = {
  open: boolean;
  closeDialog: () => void;
  savePostType: (item: any, post_type: string) => void;
  keywordStrategy: IKeywordStrategy;
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  keywordStrategy: state.keywordStrategy.selectedDataItem,
});

const MappingPostTypeDialog = (props: PropTypes) => {
  const { open, closeDialog, keywordStrategy, savePostType } = props;
  const classes = useStyles();

  const [currentPostType, setCurrentPostType] = useState(
    keywordStrategy?.post_type && {
      name: STRATEGY_POST_TYPES[keywordStrategy.post_type],
      id: keywordStrategy.post_type,
    }
  );

  const selectOptions = useMemo(
    () => [
      { name: I18n.t("strategy.strategyKeywords.labelOptions.targeted"), id: "targeted" },
      { name: I18n.t("strategy.strategyKeywords.labelOptions.community"), id: "community" },
      { name: I18n.t("strategy.strategyKeywords.labelOptions.article"), id: "article" },
      { name: I18n.t("strategy.strategyKeywords.labelOptions.top10"), id: "top10" },
      { name: I18n.t("strategy.strategyKeywords.labelOptions.review"), id: "review" },
      { name: I18n.t("strategy.strategyKeywords.labelOptions.comparison"), id: "comparison" },
      { name: I18n.t("strategy.strategyKeywords.labelOptions.list_article"), id: "list_article" },
      { name: I18n.t("strategy.strategyKeywords.btnOptions.product"), id: "product" },
      { name: I18n.t("strategy.strategyKeywords.labelOptions.short_video"), id: "short_video" },
      { name: I18n.t("strategy.strategyKeywords.labelOptions.long_video"), id: "long_video" },
    ],
    []
  );

  console.log(currentPostType);

  useEffect(() => {
    setCurrentPostType(
      keywordStrategy?.post_type && {
        name: STRATEGY_POST_TYPES[keywordStrategy.post_type],
        id: keywordStrategy.post_type,
      }
    );

    return () => {
      setCurrentPostType(undefined);
    };
  }, [keywordStrategy]);

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={closeDialog}
      fullWidth
      maxWidth={"xl"}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.contentWrapper}>
        <div className={classes.firstChildWrapper}>
          <div className={classes.titleWrapper}>
            <Typography variant={"subtitle1"} className={classes.dialogTitle}>
              {I18n.t("workflow.mapping.edit_postType")}
            </Typography>
            <IconButton className={classes.closeButton} onClick={closeDialog}>
              <XIcon className={classes.headerIcon} />
            </IconButton>
          </div>

          <div className={classes.column}>
            <Typography className={classes.inputLabel} variant={"subtitle2"}>
              {I18n.t("workflow.mapping.edit_postType_description")}
            </Typography>
            <MSelect
              value={currentPostType || null}
              options={selectOptions}
              optionValue={"id"}
              optionLabel={"name"}
              customClass={classes.textField}
              height={37}
              borderWidth={1}
              width={200}
              dropdownZIndexPriority
              borderRadius={5}
              maxMenuHeight={320}
              handleChange={(postType) => {
                setCurrentPostType(postType);
              }}
            />
          </div>
        </div>

        <div className={classes.buttonWrapper}>
          <MButton
            customClassNames={classes.saveBtn}
            onClick={() => {
              if (keywordStrategy && currentPostType) {
                savePostType(keywordStrategy, currentPostType.id);
              }
            }}
          >
            {I18n.t("workflow.mapping.save")}
          </MButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, {})(MappingPostTypeDialog);
