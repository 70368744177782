import React, { forwardRef, useMemo, useState } from "react";
import { IReportsCustomReportRow, IWebsite } from "../../../../reducers/constants/objectTypes";
import useReport from "./useReport";
import { REPORT_CACHE_KEY_NAME } from "../../../../helpers/constants";
import { ResponsiveContainer } from "recharts";
import MaterialTable from "material-table";
import { ArrowDownward, ChevronLeft, ChevronRight, FirstPage, LastPage } from "@material-ui/icons";
import { I18n } from "react-redux-i18n";
import ReportCard from "./ReportCard";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import Tooltip from "@material-ui/core/Tooltip";
import numeral from "numeral";
import moment from "moment";
import agent from "../../../../agent";
import { useSnackbar } from "notistack";
import MButton from "../../../../components/MButton";
import _ from "lodash";
import { AbilityContext } from "../../../../casl/can";
import AnalyticsTable from "./charts/AnalyticsTable";
import { CTAClicksByUrlInterface } from "../types";

type Props = {
  customReport: IReportsCustomReportRow;
  selectedWebsite: IWebsite;
  startDate: Date;
};

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: 150,
    margin: 0,
  },
  chartContainer: {
    overflowY: "auto",
    zIndex: 1,
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.pxToRem(14),
  },
  pageRowStyle: {
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    cursor: "pointer",
  },
  pivotSaveBtn: {
    maxWidth: "100px",
  },
}));

const CustomRedashReport = (props: Props) => {
  const classes = useStyles(props);

  const ability = React.useContext<any>(AbilityContext);

  const { customReport, selectedWebsite, startDate } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [pivotState, setPivotState] = useState(customReport.customSettings);

  const handleSaveReportsSettings = () => {
    agent.ReportsSettings.updateCustomReport(selectedWebsite, customReport._id, {
      ...customReport,
      customSettings: _.omit(pivotState, ["data"]),
    })
      .then(() => {
        enqueueSnackbar(I18n.t(`snackbar.update_success`));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  const extraParams = React.useMemo(() => ({ id: customReport.id }), [customReport]);

  const { loading, triggerRefresh, setTriggerRefresh, fetchResponse } = useReport({
    selectedWebsite,
    startDate,
    reportType: "custom",
    extraParams,
  });

  const { data, columns, cacheData } = React.useMemo(() => {
    if (fetchResponse) {
      return {
        data: fetchResponse?.results?.rows || [],
        columns: fetchResponse?.results?.columns || [],
        cacheData: {
          cache: fetchResponse.cache,
          lastFetch: fetchResponse.fetchedTime,
        },
      };
    }
    return {
      data: [],
    };
  }, [fetchResponse]);

  const LIMIT_VALUES = useMemo(
    () => [
      {
        name: "5 rows",
        value: 5,
      },
      {
        name: "10 rows",
        value: 10,
      },
      {
        name: "20 rows",
        value: 20,
      },
      {
        name: "50 rows",
        value: 50,
      },
      {
        name: "100 rows",
        value: 100,
      },
    ],
    []
  );

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });

  const tableColumns = React.useMemo(() => {
    if (columns) {
      return columns.map((col) => ({
        header: col.friendly_name,
        accessorKey: col.name,
        cell: (cellProps) =>
          col.type === "string" ? (
            <Tooltip title={cellProps.cell.getValue()}>
              <>{cellProps.cell.getValue()}</>
            </Tooltip>
          ) : (
            <>
              {(col.type === "datetime" || col.type === "date") &&
                moment(cellProps.cell.getValue()).format("YYYY-MM-DD HH:mm")}
              {(col.type === "float" || col.type === "integer") && numeral(cellProps.cell.getValue()).format("0,0.0a")}
            </>
          ),
      }));
    }
    return [];
  }, [columns]);

  return (
    <ReportCard
      title={`${I18n.t("reports.dashboard.custom_report")} - ${customReport.name}`}
      cacheData={cacheData}
      setRefreshCache={() => {
        sessionStorage.setItem(REPORT_CACHE_KEY_NAME, "true");
        setTriggerRefresh(!triggerRefresh);
      }}
      containsData={data.length > 0}
      loading={loading}
      totalPageCount={Math.ceil(data.length / pagination.pageSize)}
      pagination={customReport.graphType === "table" && pagination}
      limitOptions={LIMIT_VALUES}
      setPagination={setPagination}
    >
      {customReport.graphType === "table" && (
        <ResponsiveContainer width={"98%"} height={400} className={classes.chartContainer}>
          <AnalyticsTable<{ [key: string]: any }>
            selectedWebsite={selectedWebsite}
            pagination={pagination}
            columns={tableColumns}
            data={data}
          />
        </ResponsiveContainer>
      )}

      {customReport.graphType === "pivot" && (
        <>
          <ResponsiveContainer width={"98%"} height={400} className={classes.chartContainer}>
            <PivotTableUI data={data} onChange={(s) => setPivotState(s)} {...pivotState} />
          </ResponsiveContainer>
          {ability.can("view", "data_tab") && (
            <MButton onClick={handleSaveReportsSettings} customClassNames={classes.pivotSaveBtn}>
              {I18n.t("reports.dashboard.save")}
            </MButton>
          )}
        </>
      )}
    </ReportCard>
  );
};

export default CustomRedashReport;
