import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { ePostType, ITag, IWebsite } from "../../../../reducers/constants/objectTypes";
import { EDIT_TOPIC } from "../../../../reducers/constants/actionTypes";
import MTextBox from "../../../../components/MTextBox";
import EditTopicBottomBar from "./EditTopicBottomBar";
import { AbilityContext } from "../../../../casl/can";
import RichTextEditor from "../../../../editor/RichTextEditor";
import richTextEditorHelper from "../../../../editor/helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  editor: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
    borderRight: `solid 1px ${theme.palette.divider}`,
  },
  textEditor: {
    overflowY: "auto",
    height: "calc(100vh - 115px)",
  },
  editorToolbar: {
    height: 55,
    paddingLeft: 20,
    // paddingRight: 30,
  },
  titleCustomClass: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  editable: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  metaDescription: {
    height: 130,
  },
  metaDescriptionPaper: {
    borderRadius: 0,
    paddingLeft: 40,
    border: "none",
    borderBottom: `${theme.palette.divider} solid 1px`,
  },
}));

type PropTypes = {
  action: "CREATE" | "WRITE" | "REVIEW";
  selectedWebsite: IWebsite;
  topicLoaded: boolean;
  topic: ITag;

  handleTopicChanged: Function;
  returnToPreviousScreen: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  topicLoaded: state.editTopic.topicLoaded,
  topic: state.editTopic.topic,
});

const mapDispatchToProps = (dispatch) => ({
  handleTopicChanged: (topic) => dispatch({ type: EDIT_TOPIC.TOPIC_CHANGED, topic }),
});

const EditTopicEditor = (props: PropTypes) => {
  const classes = useStyles(props);
  const { action, selectedWebsite, topicLoaded, topic, handleTopicChanged, returnToPreviousScreen } = props;
  const ability = React.useContext<any>(AbilityContext);

  return (
    <div className={classes.editor}>
      {topic && (
        <div className={classes.textEditor}>
          <MTextBox
            collapsable={false}
            paperCustomClass={classes.metaDescriptionPaper}
            customClass={classes.metaDescription}
            text={topic.metaDescription}
            handleTextChanged={(metaDescription) => handleTopicChanged({ ...topic, metaDescription })}
          />
          <RichTextEditor
            fontSize={16}
            droppableId={"edit_topic_editor"}
            editorMode={ability.can("editor", "role")}
            enableComments={ability.can("review", `${ePostType.LEARNING_CENTER}_topic`)}
            editableCustomClass={classes.editable}
            toolbarCustomClass={classes.editorToolbar}
            titleCustomClass={classes.titleCustomClass}
            key={topic._id}
            website={selectedWebsite}
            title={topic.title}
            editableTitle
            editTitleAlert={false}
            titlePlaceHolder={I18n.t("edit_topic.title_ph")}
            onTitleChanged={(title) => handleTopicChanged({ ...topic, title })}
            initialValue={topic.richText}
            onValueChanged={(value) =>
              handleTopicChanged({
                ...topic,
                richText: value,
                text: richTextEditorHelper.serialize(JSON.parse(value)),
              })
            }
          />
        </div>
      )}
      {topicLoaded && <EditTopicBottomBar returnToPreviousScreen={returnToPreviousScreen} />}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTopicEditor);
