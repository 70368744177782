import React from "react";
import { I18n } from "react-redux-i18n";
import TextInputMentions from "./TextInputMentions";
import { LENGTHS } from "../../../reducers/constants/consts";
import {
  IWebsiteComparisonTop10Props,
  IWebsiteComparisonTopAlternativeProps,
  IWebsiteProductAlternativeProps,
  IWebsiteProductComparisonProps,
  IWebsiteProductPageProps,
  IWebsiteProductReviewProps,
  SNWebsiteAutoCompleteMetaTag,
} from "../../../reducers/constants/objectTypes";
import TextDesignToolWithPreview from "./TextDesignToolWithPreview";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";

type PropTypes = {
  emptyFields: any;
  pageName: string;
  page:
    | IWebsiteProductReviewProps
    | IWebsiteProductPageProps
    | IWebsiteProductAlternativeProps
    | IWebsiteProductComparisonProps
    | IWebsiteComparisonTop10Props
    | IWebsiteComparisonTopAlternativeProps;
  onPropertyChanged: (property) => void;
};

const ProductPagesPostSection = (props: PropTypes) => {
  const { page, onPropertyChanged, emptyFields, pageName } = props;

  console.log("pageName:::::::", pageName);
  return (
    <>
      <TextDesignToolWithPreview
        topTitle
        disableHover
        marginLeft={"-30px"}
        marginRight={"-30px"}
        elementTitle={I18n.t("website.element_design", { element: I18n.t("website.title") })}
        css={page.postPage.title?.css}
        variant={"h1"}
        exampleText={I18n.t("website.product_page.example_title_text", { name: pageName })}
        handleCssChanged={(newCss) => {
          onPropertyChanged({
            ...page,
            postPage: { ...page.postPage, title: { ...page.postPage.title, css: newCss } },
          });
        }}
      />

      <TextInputMentions
        error={emptyFields.includes("postPage.metaTitle")}
        tags={[SNWebsiteAutoCompleteMetaTag.postTitle]}
        placeholder={I18n.t("website.meta_title_ph")}
        title={I18n.t("website.meta_title")}
        maxLength={LENGTHS.META_TITLE}
        titleAndLengthLocation={"top"}
        text={page.postPage.metaTitle}
        onTextChanged={(value) => onPropertyChanged({ ...page, postPage: { ...page.postPage, metaTitle: value } })}
      />

      <TextInputMentions
        error={emptyFields.includes("postPage.metaDescription")}
        tags={[SNWebsiteAutoCompleteMetaTag.postTitle, SNWebsiteAutoCompleteMetaTag.postMetaDescription]}
        placeholder={I18n.t("website.meta_description_ph")}
        title={I18n.t("website.meta_description")}
        maxLength={LENGTHS.META_DESCRIPTION}
        titleAndLengthLocation={"top"}
        text={page.postPage.metaDescription}
        onTextChanged={(value) =>
          onPropertyChanged({ ...page, postPage: { ...page.postPage, metaDescription: value } })
        }
      />

      <TextFieldMaxLength
        error={emptyFields.includes("postPage.disclaimer")}
        titleAndLengthLocation={"top"}
        value={page.postPage?.disclaimer}
        placeholder={""}
        title={I18n.t("website.disclaimer")}
        maxLength={300}
        height={37}
        multiLine
        borderRadius={4}
        marginBottom={30}
        handleValueChange={(value) => onPropertyChanged({ ...page, postPage: { ...page.postPage, disclaimer: value } })}
      />
    </>
  );
};

export default ProductPagesPostSection;
