import React, { useEffect } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import agent from "../../../agent";
import { IAdminUser, ICategory, IWebsite } from "../../../reducers/constants/objectTypes";
import { COMMUNITY } from "../../../reducers/constants/actionTypes";
import WriterCommunityItemList from "./components/WriterCommunityItemList";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  admin: state.home.admin,
  selectedWebsite: state.home.selectedWebsite,
  categories: state.category.categories,
  refreshPages: state.community.writeRefreshAnswers,
  sortField: state.community.writerFilter.sortField,
});

const mapDispatchToProps = (dispatch) => ({
  loadAnswers: (payload) => dispatch({ type: COMMUNITY.ANSWERS_LOADED, payload }),
  startLoading: () => dispatch({ type: COMMUNITY.ANSWERS_LOADING }),
  onFilterChanged: (payload) => dispatch({ type: COMMUNITY.ANSWERS_ON_FILTER_CHANGED, payload }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  admin: IAdminUser;
  loadAnswers: (payload) => void;
  startLoading: () => void;
  refreshPages: boolean;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  categories: ICategory[];
  onFilterChanged: (payload) => void;
};

const Write = (props: PropTypes) => {
  const { selectedWebsite, admin, loadAnswers, startLoading, refreshPages, onFilterChanged, sortField } = props;

  useEffect(() => {
    startLoading();

    loadAnswers(
      agent.Community.pendingAnswers(selectedWebsite, sortField.field, sortField.direction)
        .then((res) => res)
        .catch((e) => {})
    );
  }, [selectedWebsite, admin, refreshPages, sortField]);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.community.create.title`)} />
      <WriterCommunityItemList sortField={sortField} setSortField={(value) => onFilterChanged({ sortField: value })} />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Write);
