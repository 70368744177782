import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import ClassNames from "classnames";
import { useMemo } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { IPage, IPageCluster } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusWrapper: {
      display: "flex",
      flexDirection: "column",
      whiteSpace: "pre",
      padding: "10px 15px",
    },
    statusRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 15,
      alignItems: "center",
      gap: 5,
    },
    status: {
      display: "flex",
    },
    green: {
      color: "green",
    },
    red: {
      color: "red",
    },
    flexOne: {
      flex: 1,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.2,
    },
    flexOneTitle: {
      flex: 1,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightBold as any,
      lineHeight: 1.2,
    },
    showWrapper: {
      color: "#609EFF",
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold as any,
      display: "flex",
      cursor: "pointer",
    },
    showMore: {
      color: "inherit",
    },
    showMoreIcon: {
      fontSize: theme.typography.pxToRem(18),
    },
  })
);

type PropTypes = {
  cluster: IPageCluster;
  page: IPage;
};

const PageEditorUploadStatus = (props: PropTypes) => {
  const classes = useStyles(props);
  const { page, cluster } = props;
  const [showMore, setShowMore] = React.useState(true);

  // if (!page || !cluster || cluster.publishHistory.length === 0) {
  //   return null;
  // }

  const phs = useMemo(
    () =>
      cluster?.publishHistory
        ?.filter((ph) => ph.variant === page?.variant && ph.locale === page?.locale)
        .sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? 1 : -1)) || [],
    [cluster, page]
  );

  if (!page || !cluster || cluster.publishHistory.length === 0 || phs.length === 0) {
    return null;
  }

  return (
    <div className={classes.statusWrapper}>
      <div className={classes.statusRow}>
        <Typography className={classes.flexOneTitle} variant={"subtitle2"}>
          {I18n.t("custom_post.date")}
        </Typography>
        <Typography className={classes.flexOneTitle} variant={"subtitle2"}>
          {I18n.t("custom_post.time")}
        </Typography>
        <Typography className={classes.flexOneTitle} variant={"subtitle2"}>
          {I18n.t("custom_post.status")}
        </Typography>
      </div>

      {[...(showMore ? phs.slice(0, 3) : phs)].map((publishHistory, index) => (
        <div key={`upload_${index}`} className={classes.statusRow}>
          <Typography className={classes.flexOne} variant={"body1"}>
            {moment(publishHistory.date).format("MMM D, YY")}
          </Typography>
          <Typography className={classes.flexOne} variant={"body1"}>
            {moment(publishHistory.date).format("HH:mm")}
          </Typography>
          <Tooltip title={JSON.stringify(publishHistory?.errorReason || "-")}>
            <Typography
              variant={"body1"}
              color={"textSecondary"}
              className={ClassNames(
                classes.flexOne,
                classes.status,
                publishHistory?.publishStatus === "OK" ? classes.green : classes.red
              )}
            >
              {publishHistory?.publishStatus}
              {publishHistory?.publishStatus === "ERROR" && <InfoIcon fontSize={"small"} />}
            </Typography>
          </Tooltip>
        </div>
      ))}
      {phs.length > 3 && (
        <div className={classes.showWrapper} onClick={() => setShowMore(!showMore)}>
          <Typography className={classes.showMore} variant={"body2"}>
            {I18n.t(`custom_post.${showMore ? "show_more" : "show_less"}`)}
          </Typography>
          {showMore ? (
            <ExpandMoreIcon className={classes.showMoreIcon} />
          ) : (
            <ExpandLessIcon className={classes.showMoreIcon} />
          )}
        </div>
      )}
    </div>
  );
};

export default PageEditorUploadStatus;
