import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import agent from "../../../agent";
import { ePageType, IAdminUser, ICategory, IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import { WRITE_PAGES } from "../../../reducers/constants/actionTypes";
import WriterItemList from "./components/WriterItemList";
import useDebounce from "../../../hooks/useDebounce";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarPostTypeFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPostTypeFilter";
import MTableToolbarCategoriesFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarCategoriesFilter";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  admin: state.home.admin,
  refreshPages: state.writePages.refreshPages,
  paginationPagesCount: state.writePages.paginationPagesCount,
  pagesLoaded: state.writePages.pagesLoaded,
  categories: state.category.categories,

  rejected: state.writePages.rejected,
  inProgress: state.writePages.draftPages,
  publicPages: state.writePages.publicPages,
  takenByOthers: state.writePages.takenByOthers,

  postType: state.writePages.filter.postType,
  category: state.writePages.filter.category,
  searchText: state.writePages.filter.searchText,
  sortField: state.writePages.filter.sortField,
});

const mapDispatchToProps = (dispatch) => ({
  loadPages: (payload) => dispatch({ type: WRITE_PAGES.WRITE_PAGES_LOADED, payload }),
  startLoading: () => dispatch({ type: WRITE_PAGES.WRITE_PAGES_LOADING }),
  loadFailed: () => dispatch({ type: WRITE_PAGES.WRITE_PAGES_LOAD_FAILED }),
  onFilterChanged: (payload) => dispatch({ type: WRITE_PAGES.WRITE_PAGES_ON_FILTER_CHANGED, payload }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  admin: IAdminUser;
  paginationPagesCount: number;
  refreshPages: boolean;
  pagesLoaded: boolean;
  categories: ICategory[];

  loadPages: (payload) => void;
  loadFailed: () => void;
  startLoading: () => void;

  inProgress: IPage[];
  rejected: IPage[];
  publicPages: IPage[];
  takenByOthers: IPage[];
  postType: { _id: ePageType; name: string }[];
  category: ICategory[];
  searchText: string;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  onFilterChanged: (payload) => void;
};

const Write = (props: PropTypes) => {
  const {
    selectedWebsite,
    admin,
    loadPages,
    startLoading,
    refreshPages,
    categories,
    loadFailed,
    pagesLoaded,
    paginationPagesCount,
    takenByOthers,
    publicPages,
    inProgress,
    rejected,
    postType,
    category,
    searchText,
    sortField,
    onFilterChanged,
  } = props;

  const [page, setPage] = useState<number>(1);
  const [paginationLimit, setPaginationLimit] = useState(100);
  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    if (selectedWebsite && admin) {
      const cleanPostTypes = postType.map((p) => p._id);
      const postCategories = category.map((cat) => cat._id);

      startLoading();
      loadPages(
        agent.Pages.getWriterItems(
          selectedWebsite,
          admin,
          page,
          paginationLimit,
          sortField.field,
          sortField.direction,
          cleanPostTypes,
          postCategories,
          searchText
        )
          .then((res) => res)
          .catch((e) => loadFailed())
      );
    }
  }, [selectedWebsite, admin, postType, refreshPages, category, debouncedSearchText, page, paginationLimit, sortField]);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.article_cms.create.title`)} />
      <MTableToolbar
        cmsCreateButton
        searchText={searchText}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
      >
        <MTableToolbarPostTypeFilter
          postTypes={postType}
          selectedWebsite={selectedWebsite}
          handlePostTypesSelected={(value) => onFilterChanged({ postType: value })}
        />
        <MTableToolbarCategoriesFilter
          handleCategoriesSelected={(value) => onFilterChanged({ category: value })}
          selectedCategories={category}
          categories={categories}
        />
      </MTableToolbar>
      <WriterItemList
        setSortField={(value) => onFilterChanged({ sortField: value })}
        sortField={sortField}
        takenByOthers={takenByOthers}
        inProgress={inProgress}
        rejected={rejected}
        publicPages={publicPages}
      />
      {pagesLoaded &&
        (takenByOthers.length > 0 || rejected.length > 0 || inProgress.length > 0 || publicPages.length > 0) && (
          <MTablePagination
            paginationLimit={paginationLimit}
            page={page}
            paginationPagesCount={paginationPagesCount}
            setPaginationLimit={setPaginationLimit}
            setPage={setPage}
          />
        )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Write);
