import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  className?: string;
};

function UndoIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={className} viewBox={"0 0 17 16"}>
      <path d="M3.98956 15.5C3.76875 15.5 3.58616 15.4277 3.44179 15.2832C3.29741 15.1386 3.22522 14.9558 3.22522 14.7347C3.22522 14.5136 3.29741 14.3308 3.44179 14.1862C3.58616 14.0417 3.76875 13.9694 3.98956 13.9694H10.5883C11.7772 13.9694 12.8006 13.574 13.6583 12.7832C14.5161 11.9923 14.945 11.0102 14.945 9.83673C14.945 8.66327 14.5161 7.68112 13.6583 6.89031C12.8006 6.09949 11.7772 5.70408 10.5883 5.70408H3.07236L5.44179 8.07653C5.59465 8.22959 5.67108 8.40816 5.67108 8.61224C5.67108 8.81633 5.59465 8.9949 5.44179 9.14796C5.28892 9.30102 5.11058 9.37755 4.90675 9.37755C4.70293 9.37755 4.52459 9.30102 4.37172 9.14796L0.702932 5.47449C0.618006 5.38946 0.558558 5.30442 0.524588 5.21939C0.490618 5.13435 0.473633 5.04082 0.473633 4.93878C0.473633 4.83673 0.490618 4.7432 0.524588 4.65816C0.558558 4.57313 0.618006 4.4881 0.702932 4.40306L4.37172 0.729592C4.52459 0.576531 4.70293 0.5 4.90675 0.5C5.11058 0.5 5.28892 0.576531 5.44179 0.729592C5.59465 0.882653 5.67108 1.06122 5.67108 1.26531C5.67108 1.46939 5.59465 1.64796 5.44179 1.80102L3.07236 4.17347H10.5628C12.1764 4.17347 13.5649 4.71769 14.7284 5.80612C15.8919 6.89456 16.4736 8.23809 16.4736 9.83673C16.4736 11.4354 15.8919 12.7789 14.7284 13.8673C13.5649 14.9558 12.1764 15.5 10.5628 15.5H3.98956Z" />
    </SvgIcon>
  );
}

export default UndoIcon;
