import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import MTextArea from "../../../../components/MTextArea";
import MButton from "../../../../components/MButton";
import { isUrl } from "../../../../helpers/urlHelper";
import agent from "../../../../agent";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import IndexingApiQuota from "./components/IndexingApiQuota";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";

const useStyles = makeStyles((theme: Theme) => ({
  topicsWrapper: {
    display: "flex",
    width: "100%",
    padding: 20,
  },
  paper: {
    display: "flex",
    padding: 25,
    flexDirection: "column",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  urlsTextArea: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    padding: 10,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.3,
    minHeight: 200,
    margin: "10px 0px 20px 0px",
  },
  button: {
    width: 300,
    alignSelf: "end",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const IndexingAPI = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const [urls, setUrls] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  console.log("render upload posts");
  const updateUrls = () => {
    let validUrls = true;
    const urlArray = urls.split("\n").filter((u, index) => {
      if (!u.includes(selectedWebsite.host)) {
        enqueueSnackbar(`Error: line ${index + 1}: "${u}" must be in the same domain as "${selectedWebsite.host}"`);
        validUrls = false;
        return;
      }
      if (isUrl(u)) {
        console.log("valid URl", u);
        return true;
      }
      enqueueSnackbar(`Error: line ${index + 1}: "${u}" is invalid`);
      validUrls = false;
      console.log("invalid URl", u);
      return false;
    });

    if (!validUrls || urlArray.length === 0) {
      console.log("return");
      return;
    }
    setLoading(true);
    console.log("seneding request");

    agent.Indexing.indexUrls(selectedWebsite, urlArray)
      .then((res) => {
        setLoading(false);
        if (res.error) {
          enqueueSnackbar(I18n.t("snackbar.update_error", { msg: res.message }));
          return;
        }
        setResults(res.results);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  return (
    <div className={classes.topicsWrapper}>
      <MHeaderMetaTags title={I18n.t(`meta_tags.seo.indexing_api.title`)} />
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <div>
            <Typography color={"textPrimary"} variant={"h4"}>
              {I18n.t("admin.indexing_api.title")}
            </Typography>
            <Typography color={"textSecondary"} variant={"h6"}>
              {I18n.t("admin.indexing_api.description")}
            </Typography>
          </div>
          <IndexingApiQuota key={results} website={selectedWebsite} />
        </div>
        {results && (
          <>
            <Typography style={{ marginTop: 30, marginBottom: 20 }} variant={"h6"}>
              {I18n.t("admin.indexing_api.results")}
            </Typography>
            {Object.keys(results).map((url) => (
              <Typography
                style={{ marginBottom: 10, marginLeft: 10 }}
                variant={"subtitle2"}
                key={url}
              >{`${url} - ${results[url]}`}</Typography>
            ))}
          </>
        )}
        {!results && (
          <MTextArea
            lineNumbers
            className={classes.urlsTextArea}
            text={urls}
            placeholder={I18n.t("admin.indexing_api.urls_ph")}
            onChange={(_urls) => setUrls(_urls)}
          />
        )}
        <MButton
          loading={loading}
          customClassNames={classes.button}
          disabled={!urls}
          onClick={(e) => {
            e.stopPropagation();
            if (results) {
              setResults(null);
              setUrls("");
              return;
            }
            updateUrls();
          }}
        >
          {results ? I18n.t("admin.indexing_api.clear") : I18n.t("admin.indexing_api.update")}
        </MButton>
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps)(IndexingAPI);
