import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import ArrowLeftIcon from "../../../../icons/ArrowLeftIcon";
import MSavedXMinutesAgo from "../../../../components/MSavedXMinutesAgo";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    minHeight: 65,
    height: 65,
    paddingRight: 25,
    paddingLeft: 25,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
  },
  rightSide: {},
  arrowIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    marginRight: 20,
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  headerTitle: {
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  returnToPreviousScreen: () => void;
  fetchTime?: Date;
};

const ReportsHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { returnToPreviousScreen, fetchTime } = props;

  return (
    <div className={classes.toolbar}>
      <div className={classes.leftSide}>
        <div>
          <ArrowLeftIcon className={classes.arrowIcon} onClick={returnToPreviousScreen} />
        </div>
        <Typography className={classes.headerTitle} color={"textPrimary"} variant={"body2"}>
          {I18n.t("reports.name")}
        </Typography>
      </div>
      <div className={classes.rightSide}>
        <MSavedXMinutesAgo lastSaveTime={fetchTime} action={"Updated"} />
      </div>
    </div>
  );
};

export default ReportsHeader;
