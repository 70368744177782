import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import * as React from "react";
import { useMemo } from "react";
import { capitalize, find, orderBy } from "lodash";
import moment from "moment/moment";
import ClassNames from "classnames";
import _ from "lodash";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@material-ui/core";
import MTableCellFirst from "../../../../../components/Table/Cells/MTableCellFirst";
import MTableCellNumber from "../../../../../components/Table/Cells/MTableCellNumber";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../../../../components/Table/Table/MTableHeaderSortLabel";
import { DATE_FORMAT } from "../../../../../reducers/constants/consts";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    backgroundColor: "white",
    padding: "30px 20px 0px 0px",
    width: 80,
  },
  firstColumn: {
    backgroundColor: theme.palette.common.white,
  },
  headerFirstColumn: {
    zIndex: 100,
    backgroundColor: theme.palette.common.white,
  },
  pageEventsAvatar: {
    backgroundColor: "#B780FE !important",
  },
  table: {
    position: "relative",
    borderSpacing: "20px 0",
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: "auto",
    // height: "calc(100vh - 225px)",
  },
  tableRow: {
    height: 53,
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      transition: "border-radius 500ms",
      "& $firstColumn": {
        backgroundColor: theme.palette.secondary.main,
      },
      "& $headerFirstColumn": {
        backgroundColor: theme.palette.secondary.main,
      },
      "& $thFirst": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  thFirst: {
    position: "sticky",
    left: 0,
    width: "50%",
    paddingLeft: 30,
    backgroundColor: theme.palette.common.white,
  },
  thFirstNoWidth: {
    position: "sticky",
    left: 0,
    width: 120,
    maxWidth: 120,
    paddingLeft: 30,
  },
  rowCell: {
    width: 80,
    maxWidth: 80,
    paddingRight: 20,
    paddingLeft: 0,
    cursor: "pointer",
  },
  tableHeader: {
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    top: 0,
    borderBottom: `30px solid ${theme.palette.common.white}`,
  },
  tableFooter: {
    width: "100%",
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    bottom: 0,
  },
  totalTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 16,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    textAlign: "right",
    padding: "10px 30px 10px 0px",
    height: 50,
    width: "30%",
  },
  firstRow: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  footer: {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

type Props = {
  expandedData: {
    timeFrame: string;
    eventName: string;
    eventProps: string[];
    results: any[];
    totalPages: number;
    total: number;
    totals: any;
  };
  dateRange: string[];
  setExpanded: (event: string | boolean) => void;
};

const PageEventsReportBreakdownTable = (props: Props) => {
  const classes = useStyles();

  const { expandedData, dateRange, setExpanded } = props;

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {expandedData?.eventProps.length === 0 ? (
              <TableCell className={ClassNames(classes.thFirst, classes.headerWrapper)}>
                <MTableHeaderLabel fontSize={12} className={classes.headerFirstColumn}>
                  {I18n.t("reports.column_names.events", expandedData?.total || 0)}
                </MTableHeaderLabel>
              </TableCell>
            ) : (
              <TableCell className={ClassNames(classes.thFirstNoWidth, classes.headerWrapper)}>
                <MTableHeaderLabel fontSize={12} className={classes.headerFirstColumn}>
                  {capitalize(expandedData?.eventProps[0])}
                </MTableHeaderLabel>
              </TableCell>
            )}
            {expandedData?.eventProps?.slice(1)?.map((eventProp, eventPropIndex) => (
              <TableCell key={eventProp} className={classes.headerWrapper}>
                <MTableHeaderLabel fontSize={12}>{capitalize(eventProp)}</MTableHeaderLabel>
              </TableCell>
            ))}
            {dateRange.map((date, dateIndex) => (
              <TableCell key={dateIndex} className={classes.headerWrapper}>
                <MTableHeaderLabel fontSize={12}>
                  {moment(date).format(
                    expandedData.timeFrame === "month" ? DATE_FORMAT.READABLE_MONTH_YEAR : DATE_FORMAT.READABLE
                  )}
                </MTableHeaderLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {expandedData?.results?.map((result, resultIndex) => (
            <TableRow key={`expanded_${resultIndex}`} className={classes.tableRow}>
              {expandedData?.eventProps.length === 0 ? (
                <TableCell className={ClassNames(classes.thFirst, classes.firstRow)}>
                  <MTableCellFirst
                    wrapperClassName={classes.firstColumn}
                    avatarType={"E"}
                    avatarClassName={classes.pageEventsAvatar}
                    cellText={result.name}
                    toolTipProps={{ enterDelay: 300, placement: "top-start" }}
                    actions={[]}
                    fontSize={12}
                  />
                </TableCell>
              ) : (
                <TableCell className={ClassNames(classes.thFirstNoWidth, classes.firstRow)}>
                  <MTableCellFirst
                    wrapperClassName={classes.firstColumn}
                    avatarType={"E"}
                    avatarClassName={classes.pageEventsAvatar}
                    cellText={result[expandedData?.eventProps[0]]}
                    toolTipProps={{ enterDelay: 300, placement: "top-start" }}
                    actions={[]}
                    fontSize={12}
                  />
                </TableCell>
              )}
              {expandedData?.eventProps?.slice(1)?.map((eventProp, eventPropIndex) => (
                <TableCell key={`expanded_${resultIndex}_${result[eventProp]}`} className={classes.rowCell}>
                  <MTableCellText fontSize={12} text={result[eventProp]} />
                </TableCell>
              ))}
              {dateRange.map((date, dateIndex) => (
                <TableCell key={`${resultIndex}_${dateIndex}`} className={classes.rowCell}>
                  <MTableCellNumber
                    value={result?.date_data?.[date]}
                    type={"bigNumber"}
                    toolTipProps={{ enterDelay: 300, placement: "top-start" }}
                    fontSize={12}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter className={classes.footer}>
          <TableRow>
            {Array.from(Array(Math.max((expandedData?.eventProps?.length || 0) - 1, 0)).keys()).map((num) => (
              <TableCell key={`total_dummy_${num}`} className={classes.rowCell}></TableCell>
            ))}
            <TableCell className={ClassNames(classes.thFirst, classes.totalTitle)}>{"Totals:"}</TableCell>
            {dateRange.map((date, dateIndex) => (
              <TableCell key={`total_${dateIndex}`} className={classes.rowCell}>
                <MTableCellNumber
                  value={find(expandedData?.totals, { date: date })?.value || 0}
                  type={"bigNumber"}
                  toolTipProps={{ enterDelay: 300, placement: "top-start" }}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default PageEventsReportBreakdownTable;
