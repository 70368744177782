import * as React from "react";
import { useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import _ from "lodash";
import { IPage, IPageCluster } from "../../../../reducers/constants/objectTypes";
import PageClusterRow from "./PageClusterRow";
import FieldDateDialog from "../../components/FieldDateDialog";
import { WORKFLOW_ALL_STATES } from "../../../../reducers/constants/actionTypes";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTable from "../../../../components/Table/Table/MTable";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";
import agent from "../../../../agent";
import { useSnackbar } from "notistack";
import WorkflowPostTypeDialog from "../../components/WorkflowPostTypeDialog";

type PropTypes = {
  clustersLoaded: boolean;
  clusters: IPageCluster[];
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  setSortField?: (input: { field: string; direction: "asc" | "desc" }) => void;
  updatePost: (payload) => void;
  pagesCount: number;
};

const mapStateToProps = (state) => ({
  pagesCount: state.workFlowAllStates.pagesCount,
});

const mapDispatchToProps = (dispatch) => ({
  updatePost: (payload) => dispatch({ type: WORKFLOW_ALL_STATES.UPDATE_PAGE, payload }),
});

const PageClusterItemList = (props: PropTypes) => {
  const { clustersLoaded, pagesCount, clusters, sortField, setSortField, updatePost } = props;
  const [post, setPost] = React.useState(null);
  const [dateField, setDateField] = React.useState(null);
  const [pageCluster, setPageCluster] = React.useState(null);
  const [changePostType, setChangePostType] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme: any = useTheme();

  const insertUpdatedPost = (page) => {
    const { pages } = pageCluster;
    const index = _.findIndex(pages, { _id: page?._id });
    if (index >= 0) {
      pages[index] = page;
      clusters[_.findIndex(clusters, { _id: pageCluster?._id })] = { ...pageCluster, pages };
      updatePost({ clusters: [...clusters] });
    }
    setPageCluster(null);
  };
  const updatePostType = (type: string, post: IPage) => {
    if (type === post.type) return;
    agent.Pages.updatePostType(post._id, type)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(res.message, { variant: "error" });
          return;
        }
        enqueueSnackbar(res.message);
        insertUpdatedPost({ ...post, type });
      })
      .catch((e) => {
        enqueueSnackbar(e?.message, { variant: "error" });
        console.log(e);
      });
  };

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          columnWidth={"calc(34% - 40px)"}
          fontSize={theme.typography.pxToRem(21)}
          sortField={props.sortField}
          field={"title"}
        >
          {I18n.t("in_progress.header.posts", { count: pagesCount || 0 })}
        </MTableHeaderSortLabel>
        <MTableHeaderLabel> {I18n.t("in_progress.header.status")}</MTableHeaderLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"createdAt"}
        >
          {I18n.t("in_progress.header.created")}
        </MTableHeaderSortLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"dueDate"}
        >
          {I18n.t("workflow.writer.listHeader.due_date")}
        </MTableHeaderSortLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"quotaDate"}
        >
          {I18n.t("workflow.writer.listHeader.quota_date")}
        </MTableHeaderSortLabel>

        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"type"}
        >
          {I18n.t("workflow.published.listHeader.vertical")}
        </MTableHeaderSortLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"locale"}
        >
          {I18n.t("in_progress.header.locale")}
        </MTableHeaderSortLabel>
        <MTableHeaderLabel> {I18n.t("in_progress.header.category")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("in_progress.header.topics")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("in_progress.header.links")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("in_progress.header.meta_desc")}</MTableHeaderLabel>
        <MTableHeaderSortLabel
          onClick={(result) => props.setSortField(result)}
          sortField={props.sortField}
          field={"textWordsCount"}
        >
          {I18n.t("in_progress.header.words")}
        </MTableHeaderSortLabel>
        <MTableHeaderLabel> {I18n.t("in_progress.header.writer")}</MTableHeaderLabel>
      </MTableHeader>

      {!clustersLoaded && <MTableRowProgressBar />}
      {clustersLoaded && clusters.length === 0 && <MTableRowEmptyRow />}
      {clustersLoaded &&
        clusters.length > 0 &&
        clusters.map((cluster, index) => (
          <PageClusterRow
            onDateUpdate={(field, page) => {
              setDateField(field);
              setPageCluster(cluster);
              setPost(page);
            }}
            onPostTypeUpdate={(page) => {
              setChangePostType(true);
              setPost(page);
              setPageCluster(cluster);
            }}
            key={cluster._id}
            cluster={cluster}
          />
        ))}
      {dateField && post && (
        <FieldDateDialog
          state={"allStates"}
          field={dateField}
          post={post}
          onUpdate={(payload) => {
            setPost(null);
            setDateField(null);
            insertUpdatedPost(payload.page);
          }}
          onCancel={() => setPost(null)}
        />
      )}
      {changePostType && post && (
        <WorkflowPostTypeDialog
          post={post}
          open
          savePostType={(type) => {
            updatePostType(type, post);
            setChangePostType(false);
            setPost(null);
          }}
          closeDialog={() => {
            setChangePostType(false);
            setPost(null);
          }}
        />
      )}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageClusterItemList);
