import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import MTextButton from "../../../../../../components/MTextButton";
import { COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import { IQuestion } from "../../../../../../reducers/constants/objectTypes";
import CommunityEditorAnswer from "./CommunityEditorAnswer";
import CommunityEditorWriterAnswer from "./CommunityEditorWriterAnswer";

const useStyles = makeStyles((theme: Theme) => ({
  answersWrapper: {
    height: "100%",
    marginTop: 10,
    width: "100%",
  },
}));

type PropTypes = {
  selectedQuestion: IQuestion;

  addNewAnswer: Function;
  isAnswerEditor: Boolean;
};

const mapStateToProps = (state) => {
  return {
    selectedQuestion: state.communityEditor.question,
    isAnswerEditor: state.communityEditor.isAnswerEditor,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewAnswer: (index) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_ADD_NEW_ANSWER, index }),
});

const CommunityEditorAnswersList = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedQuestion, addNewAnswer, isAnswerEditor } = props;

  return (
    <div className={classes.answersWrapper}>
      {selectedQuestion &&
        selectedQuestion.answers.length > 0 &&
        selectedQuestion.answers.map((answer, index) => (
          <CommunityEditorAnswer key={`answer_${answer._id || "new"}_${index}`} index={index} />
        ))}
      {selectedQuestion && isAnswerEditor && <CommunityEditorWriterAnswer />}
      {selectedQuestion && !isAnswerEditor && (
        <MTextButton
          text={I18n.t("upload_post.new_answer")}
          onClick={(e) => addNewAnswer(selectedQuestion.answers.length)}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorAnswersList);
