import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { Divider } from "@material-ui/core";
import set from "lodash/set";
import TextInputMentions from "../../../../components/TextInputMentions";
import { IWebsiteBlogProps, SNWebsiteAutoCompleteMetaTag } from "../../../../../../reducers/constants/objectTypes";
import PageSection from "../../../../components/PageSection";
import TextInputToolAndDesign from "../../../../components/TextInputToolAndDesign";
import { LENGTHS } from "../../../../../../reducers/constants/consts";
import TextFieldMaxLength from "../../../../../../components/TextFieldMaxLength";
import TextDesignToolWithPreview from "../../../../components/TextDesignToolWithPreview";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

type PropTypes = {
  blog: IWebsiteBlogProps;
  onPropertyChanged: (blog) => void;
  locale: string;
  defaultLocale: string;
  emptyFields: string[];
};

const DefaultPageBlog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { blog, onPropertyChanged, emptyFields, locale, defaultLocale } = props;

  return (
    <>
      <PageSection sectionTitle={I18n.t("website.blog.main_page")}>
        <TextInputToolAndDesign
          error={emptyFields.includes(I18n.t(`website.title`))}
          textFieldTitle={I18n.t("website.title")}
          text={
            locale === defaultLocale
              ? blog.mainPage.title
              : { text: blog.translations[locale]?.mainPage?.title || "", css: blog.mainPage.title.css }
          }
          handleTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...blog, mainPage: { ...blog.mainPage, title: value } });
              return;
            }
            set(blog.translations[locale], "mainPage.title", value.text);
            onPropertyChanged({ ...blog });
          }}
          variant={"h1"}
        />

        <TextInputMentions
          key={`blog_main_page_meta_title_${locale}`}
          tags={[]}
          error={emptyFields.includes(I18n.t(`website.meta_title`))}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale ? blog.mainPage.metaTitle : blog.translations[locale]?.mainPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...blog, mainPage: { ...blog.mainPage, metaTitle: value } });
              return;
            }
            blog.translations[locale] = {
              ...(blog.translations[locale] || {}),
              "mainPage.metaTitle": value,
            };
            onPropertyChanged({ ...blog });
          }}
        />
        <Divider className={classes.divider} />
        <TextInputToolAndDesign
          error={emptyFields.includes(I18n.t(`website.description`))}
          textFieldTitle={I18n.t("website.description")}
          variant={"h2"}
          text={
            locale === defaultLocale
              ? blog.mainPage.description
              : { text: blog.translations[locale]?.mainPage?.description || "", css: blog.mainPage.description.css }
          }
          handleTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...blog, mainPage: { ...blog.mainPage, description: value } });
              return;
            }
            set(blog.translations[locale], "mainPage.description", value.text);
            onPropertyChanged({ ...blog });
          }}
        />

        <TextInputMentions
          key={`blog_main_page_meta_desc_${locale}`}
          error={emptyFields.includes(I18n.t(`website.meta_description`))}
          tags={[]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? blog.mainPage.metaDescription
              : blog.translations[locale]?.mainPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...blog,
                mainPage: { ...blog.mainPage, metaDescription: value },
              });
              return;
            }
            blog.translations[locale] = {
              ...(blog.translations[locale] || {}),
              "mainPage.metaDescription": value,
            };
            onPropertyChanged({ ...blog });
          }}
        />
      </PageSection>
    </>
  );
};

export default DefaultPageBlog;
