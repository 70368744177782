import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { web } from "../../helpers/urlHelper";
import { AbilityContext } from "../../casl/can";
import DataSettingsMain from "./pages/Settings/DataSettingsMain";
import OutlinePage from "./pages/OutlinePages/OutlinePage";
import InternalLinking from "./pages/InternalLinking/InternalLinking";
import ContentGaps from "./pages/ContentGaps/ContentGaps";
import FetchKeywords from "../FetchKeywords/FetchKeywords";
import ExcludeKeywords from "../ExcludeKeywords/ExcludeKeywords";
import KeywordsClassification from "../KeywordsClassification1/KeywordsClassification";
import KeywordGroups from "../KeywordGroups/KeywordGroups";
import OutlineEditor from "../OutlineEditor/OutlineEditor";
import OutlineList from "../OutlineList/OutlineList";
import KeywordGroupPage from "./KeywordGroupPages/KeywordGroupPage";
import { IWebsite } from "../../reducers/constants/objectTypes";

const useStyles = makeStyles(() =>
  createStyles({
    pageWrapper: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flexGrow: 1,
      display: "flex",
    },
  })
);

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});
type PropTypes = {
  selectedWebsite: IWebsite;
};
const Data = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const ability = React.useContext<any>(AbilityContext);
  const routes = React.useMemo(() => {
    const allRoutes = [];
    if (ability.can("view", "strategy_mapping_tab")) {
      allRoutes.push({
        url: web.outlinePages(),
        route: (
          <Route
            key={`strategy_${selectedWebsite._id}_mapping`}
            exact={false}
            path={web.outlinePages()}
            render={() => <OutlinePage />}
          />
        ),
      });
    }
    if (ability.can("view", "strategy_fetch_tab")) {
      allRoutes.push({
        url: web.fetchKeywords(),
        route: (
          <Route
            key={`strategy_${selectedWebsite._id}_fetch`}
            exact={false}
            path={web.fetchKeywords()}
            render={() => <FetchKeywords />}
          />
        ),
      });
    }
    if (ability.can("view", "strategy_exclude_keyword_tab")) {
      allRoutes.push({
        url: web.excludeKeywords(),
        route: (
          <Route
            exact={false}
            key={`strategy_${selectedWebsite._id}_exclude_keyword`}
            path={web.excludeKeywords()}
            render={() => <ExcludeKeywords />}
          />
        ),
      });
    }
    if (ability.can("view", "strategy_keyword_classification_tab")) {
      allRoutes.push({
        url: web.keywordsClassification(),
        route: (
          <Route
            exact={false}
            key={`strategy_${selectedWebsite._id}_keyword_classification`}
            path={web.keywordsClassification()}
            render={() => <KeywordsClassification />}
          />
        ),
      });
    }
    if (ability.can("view", "strategy_keyword_groups_tab")) {
      allRoutes.push({
        url: web.dataGroups(),
        route: (
          <Route
            exact={false}
            key={`strategy_${selectedWebsite._id}_keyword_groups`}
            path={web.dataGroups()}
            render={() => <KeywordGroups />}
          />
        ),
      });
    }
    if (ability.can("view", "strategy_outline_tab")) {
      allRoutes.push({
        url: web.outlines(":outlineId"),
        route: (
          <Route
            exact={false}
            key={`strategy_${selectedWebsite._id}_outline_editor`}
            path={web.outlines(":outlineId")}
            render={() => <OutlineEditor view={"DATA_VIEW"} />}
          />
        ),
      });
      allRoutes.push({
        url: web.outlines(),
        route: (
          <Route
            exact={false}
            key={`strategy_${selectedWebsite._id}_outline_list`}
            path={web.outlines()}
            component={() => <OutlineList view={"DATA_VIEW"} />}
          />
        ),
      });
    }
    if (ability.can("view", "strategy_keyword_group_page_tab")) {
      allRoutes.push({
        url: web.keywordGroupPage(),
        route: (
          <Route
            exact={false}
            key={`strategy_${selectedWebsite._id}_key_word_group_page`}
            path={web.keywordGroupPage()}
            render={() => <KeywordGroupPage />}
          />
        ),
      });
    }
    if (ability.can("view", "strategy_internal_linking_tab")) {
      allRoutes.push({
        url: web.internalLinking(),
        route: (
          <Route
            exact={false}
            key={`strategy_${selectedWebsite._id}_internal_linking`}
            path={web.internalLinking()}
            render={() => <InternalLinking />}
          />
        ),
      });
    }
    if (ability.can("view", "strategy_content_gaps_tab")) {
      allRoutes.push({
        url: web.contentGaps(),
        route: (
          <Route
            exact={false}
            key={`strategy_${selectedWebsite._id}_content_gaps`}
            path={web.contentGaps()}
            render={() => <ContentGaps />}
          />
        ),
      });
    }
    if (ability.can("view", "strategy_settings_tab")) {
      allRoutes.push({
        url: web.dataSettings(),
        route: (
          <Route
            exact={false}
            key={`strategy_${selectedWebsite._id}_data_settings`}
            path={web.dataSettings()}
            render={() => <DataSettingsMain />}
          />
        ),
      });
    }

    return allRoutes;
  }, [ability, selectedWebsite]);
  return (
    <div className={classes.pageWrapper}>
      <div className={classes.content}>
        <Switch>
          {/* {ability.can("view", "strategy_keyword_heading_tab") && ( */}
          {/*  <Route exact={false} path={web.keywordHeadingCluster()} render={() => <HeadingCluster isRaw={true} />} /> */}
          {/* )} */}
          {/* {ability.can("view", "strategy_page_keywords_tab") && ( */}
          {/*  <Route exact={false} path={web.pageKeywords()} render={() => <HeadingCluster isRaw={false} />} /> */}
          {/* )} */}

          {/* <Route */}
          {/*  exact={false} */}
          {/*  path={web.serp()} */}
          {/*  render={() => <GroupSerpDialogUI handleCloseClick={() => {}} open={true} />} */}
          {/* /> */}

          {routes.map((route) => route.route)}
          <Redirect exact from={"*"} to={routes[0]?.url} />
        </Switch>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Data);
