import * as React from "react";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { unionBy } from "lodash";
import ClassNames from "classnames";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { ePostType, IAdminUser, IUser, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import agent from "../../../../../../agent";
import MTextField from "../../../../../../components/MTextField";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  userSelectorWrapper: {
    display: "flex",
    alignItems: "center",
  },
  selectUser: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginLeft: 5,
  },
  tab: {
    height: 55,
    textTransform: "capitalize",
  },
  popoverPaper: {
    height: 300,
  },
  itemsList: {
    overflowY: "auto",
    maxHeight: 165,
    minHeight: 40,
  },
  listItem: {
    cursor: "pointer",
    height: 38,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& $itemsListItemText": {
        color: theme.palette.primary.main,
      },
    },
  },
  itemsListItemText: {
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
  },
  itemListSelected: {
    color: theme.palette.primary.main,
  },
  searchTextField: {
    padding: 10,
    marginTop: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  creditedUserWrapper: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "center",
  },
}));

type PropTypes = {
  customClassName?: string;
  selectedWebsite: IWebsite;

  user: IUser;
  creditedWriter: IAdminUser;
  transformHorizontal?: "left" | "center" | "right" | number;
  onUserSelected: (user) => void;
  onCreditedWriterSelected: (writer) => void;
};

const CommunityEditorAnswerUserSelector = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, user, creditedWriter, transformHorizontal = "right", customClassName = null } = props;
  const { onUserSelected, onCreditedWriterSelected } = props;
  const [searchText, setSearchText] = React.useState("");
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [creditedWriters, setCreditedWriters] = React.useState<IAdminUser[]>([]);
  const [selectedTab, setSelectedTab] = React.useState(creditedWriter ? 1 : 0);

  console.log("selectedUser", user);

  const closePopup = (popupState) => {
    setSearchText("");
    setUsers([]);
    setCreditedWriters([]);
    popupState.close();
  };

  const allUsers = user ? unionBy([user], users, "_id") : users;
  const allCreditedWriters = creditedWriter ? unionBy([creditedWriter], creditedWriters, "_id") : creditedWriters;

  const onEnter = () => {
    agent.User.autoComplete(selectedWebsite._id, searchText || "")
      .then((results) => {
        setUsers(results.users);
      })
      .catch((e) => {
        console.log(e);
      });
    agent.Writers.getWriters(selectedWebsite, ePostType.COMMUNITY_ANSWER, "en", true, searchText || "")
      .then((results) => {
        setCreditedWriters(results.writers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  console.log("creditedWriter", creditedWriter);
  console.log("user", user);

  return (
    <div className={ClassNames(classes.userSelectorWrapper, customClassName)}>
      <PopupState variant="popover" popupId="user-popup-popover">
        {(popupState) => (
          <div className={classes.creditedUserWrapper}>
            <Typography color={"textSecondary"} variant={"inherit"}>
              {user ? I18n.t("edit_post.user") : I18n.t("edit_post.credited_writer")}:
            </Typography>
            <div className={classes.selectUser} role="cell" {...bindTrigger(popupState)}>
              {creditedWriter?.name || user?.displayName || "Select..."}
              <ExpandMore />
            </div>
            <Popover
              {...bindPopover(popupState)}
              onEnter={() => onEnter()}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: transformHorizontal,
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: transformHorizontal,
              }}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <div>
                <Tabs
                  value={selectedTab}
                  onChange={(e, tab) => setSelectedTab(tab)}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab className={classes.tab} label={"Users"} />
                  <Tab className={classes.tab} label={"Credited Writer"} />
                </Tabs>

                <MTextField
                  fullWidth
                  autoFocus
                  customClass={classes.searchTextField}
                  value={searchText}
                  placeholder={"Write name to see results..."}
                  onChange={(text: string) => {
                    setSearchText(text);
                    agent.User.autoComplete(selectedWebsite._id, text)
                      .then((results) => {
                        setUsers(results.users);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                    agent.Writers.getWriters(selectedWebsite, ePostType.COMMUNITY_ANSWER, "en", true, text || "")
                      .then((results) => {
                        setCreditedWriters(results.writers);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }}
                />

                {selectedTab === 0 && (
                  <div style={{ width: "100%" }}>
                    <List className={classes.itemsList}>
                      {allUsers.map((aUser) => (
                        <ListItem
                          key={aUser._id}
                          className={classes.listItem}
                          onClick={(event) => {
                            onUserSelected(aUser);
                            closePopup(popupState);
                          }}
                        >
                          <ListItemText
                            primary={aUser.displayName}
                            primaryTypographyProps={{
                              variant: "subtitle2",
                              className: ClassNames(
                                classes.itemsListItemText,
                                user?._id === aUser._id && classes.itemListSelected
                              ),
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}

                {selectedTab === 1 && (
                  <div style={{ width: "100%" }}>
                    <List className={classes.itemsList}>
                      {allCreditedWriters.map((writer) => (
                        <ListItem
                          key={writer._id}
                          className={classes.listItem}
                          onClick={(event) => {
                            onCreditedWriterSelected(writer);
                            closePopup(popupState);
                          }}
                        >
                          <ListItemText
                            primary={writer.name}
                            primaryTypographyProps={{
                              variant: "subtitle2",
                              className: ClassNames(
                                classes.itemsListItemText,
                                creditedWriter?._id === writer._id && classes.itemListSelected
                              ),
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}
              </div>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default React.memo(CommunityEditorAnswerUserSelector);
