import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import TopicList from "./components/TopicList";
import TopicDetails from "./components/TopicDetails";
import TopicRightSidebar from "./components/TopicRightSidebar";

const useStyles = makeStyles((theme: Theme) => ({
  topicsWrapper: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    overflow: "hidden",
    height: "calc(100vh - 130px)",
    margin: "25px 20px 0 20px",
  },
}));

type PropTypes = {};

const Topics = (props: PropTypes) => {
  const classes = useStyles(props);

  console.log("render upload posts");

  return (
    <div className={classes.topicsWrapper}>
      <TopicList />
      <TopicDetails />
      <TopicRightSidebar />
    </div>
  );
};

export default Topics;
