import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import MTextField from "../../../../../components/MTextField";

const useStyles = makeStyles((theme: Theme) => ({
  searchBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f5f6f8",
    alignItems: "center",
    paddingLeft: 30,
    paddingRight: 25,
    height: 85,
  },
  searchBarInner: {
    width: "100%",
    maxWidth: 1350,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: "auto",
    marginLeft: "auto",
    height: 85,
    alignItems: "center",
  },
  searchTextField: {
    margin: 0,
    width: 500,
    marginLeft: 25,
  },
  searchTextFieldInput: {
    borderRadius: 20,
    paddingLeft: 30,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: "white",
    height: 27,
  },
}));

type PropTypes = {
  searchText: string;

  handleSearchTextChanged: Function;
};

const RedirectsSearchBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { searchText, handleSearchTextChanged } = props;

  return (
    <div className={classes.searchBar}>
      <div className={classes.searchBarInner}>
        <MTextField
          customClass={classes.searchTextField}
          inputClassName={classes.searchTextFieldInput}
          placeholder={"Write URL or type..."}
          value={searchText}
          handleEnterClick={() => null}
          onChange={(text) => handleSearchTextChanged(text)}
        />
      </div>
    </div>
  );
};

export default RedirectsSearchBar;
