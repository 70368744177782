import * as React from "react";
import { Editor } from "slate";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { IPage, IProduct, IWebsite } from "../../../../reducers/constants/objectTypes";
import StylePanelSubSection from "../../../elementsStyles/components/StylePanelSubSection";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import MultipleProductsPickerElement from "../../../elementsStyles/ElementComponents/MultipleProductsPickerElement";
import { PAGE_EDITOR } from "../../../../reducers/constants/actionTypes";
import PPCHeaderImage from "./PPCHeaderImage";
import TextFieldElement from "../../../elementsStyles/ElementComponents/TextFieldElement";

type PropTypes = {
  editor: Editor;
  page: IPage;
  selectedWebsite: IWebsite;
  handlePageChange: (page: IPage) => void;
};
const mapDispatchToProps = (dispatch) => ({
  handlePageChange: (page) => dispatch({ type: PAGE_EDITOR.PAGE_CHANGED, page }),
});
const PPCTop10DefaultStyle = (props: PropTypes) => {
  const { editor, page, selectedWebsite, handlePageChange } = props;

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.ppc_top_10.name")} editor={editor}>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.ppc_top_10.settings")}>
        <MultipleProductsPickerElement
          rowTitle={I18n.t("rich_text_editor.ppc_top_10.top_10_products")}
          products={page.products}
          handleProductsChange={(products) => handlePageChange({ ...page, products })}
          editor={editor}
          fullWidth
          selectedWebsite={selectedWebsite}
          onProductsChange={function (products: IProduct[]): void {}}
        />
        <TextFieldElement
          direction={"column"}
          title={I18n.t("rich_text_editor.ppc_top_10.top_3_title")}
          placeholder={I18n.t("rich_text_editor.ppc_top_10.top_3_title_ph")}
          value={page.customFields?.top3Title}
          handleChange={(top3Title) => {
            handlePageChange({ ...page, customFields: { ...page.customFields, top3Title } });
          }}
        />
        <PPCHeaderImage
          selectedWebsite={selectedWebsite}
          previewImage={page.customFields?.headerImage}
          onImageSelected={(headerImage) => {
            handlePageChange({ ...page, customFields: { ...page.customFields, headerImage } });
          }}
        />
      </StylePanelSubSection>
    </StylePanelSection>
  );
};

export default connect(null, mapDispatchToProps)(PPCTop10DefaultStyle);
