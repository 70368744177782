import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ClassNames from "classnames";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Link from "@material-ui/core/Link";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import { useState } from "react";
import { filter } from "lodash";
import { useSnackbar } from "notistack";
import { ITag, IWebsite } from "../../../../../reducers/constants/objectTypes";
import { MANAGE_CONTENT } from "../../../../../reducers/constants/actionTypes";
import agent from "../../../../../agent";
import CfSearchInput from "../../../../../components/CfSearchInput";
import { clients } from "../../../../../helpers/urlHelper";
import CfCheckBox from "../../../../../components/CfCheckBox";
import MTextButton from "../../../../../components/MTextButton";

const useStyles = makeStyles((theme: Theme) => ({
  groupWrapper: {
    height: "100%",
    // padding: 20,
    backgroundColor: theme.palette.common.white,
    maxWidth: 280,
    width: "100%",
    borderRight: `${theme.palette.divider} solid 1px`,
    zIndex: 1,
  },
  listContainer: {
    display: "inline",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  groupList: {
    position: "relative",
    overflow: "auto",
    height: "calc(100% - 54px)",
  },
  itemPrimaryText: {
    color: theme.palette.text.secondary,
  },
  itemSecondaryText: {
    color: theme.palette.text.disabled,
    marginTop: 5,
  },
  itemSelected: {
    color: theme.palette.primary.main,
  },
  missingDescription: {
    position: "absolute",
    content: " ",
    right: 50,
    backgroundColor: "#FF0000",
    borderRadius: "50%",
    opacity: 0.5,
    width: 10,
    height: 10,
    pointerEvents: "none",
  },
  missingLocale: {
    position: "absolute",
    right: 50,
    color: "#FF0000",
    pointerEvents: "none",
  },
  topDivider: {
    marginBottom: 20,
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      transition: "500ms",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  search: {
    width: "-webkit-fill-available",
    height: "unset",
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 10,
  },
  searchInput: {
    height: 35,
    // backgroundColor: '#4d4d4d',
    borderRadius: 18,
  },
  searchIcon: {
    opacity: 0.2,
    // color: 'white',
  },
  cbRoot: {
    padding: "20px 20px 0px 20px",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  tagsLoaded: boolean;
  tags: ITag[];
  selectedTag: ITag;

  handleTagsLoaded: Function;
  handleTagClick: Function;
  handleTagCreation: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  tagsLoaded: state.manageContent.tagsLoaded,
  tags: state.manageContent.tags,
  selectedTag: state.manageContent.selectedTag,
});

const mapDispatchToProps = (dispatch) => ({
  handleTagsLoaded: (payload) => dispatch({ type: MANAGE_CONTENT.ON_TAGS_LOADED, payload }),
  handleTagClick: (tag) => dispatch({ type: MANAGE_CONTENT.ON_TAG_SELECTED, tag }),
  handleTagCreation: (payload) => dispatch({ type: MANAGE_CONTENT.ON_TAG_CREATED, payload }),
});

const TagsList = (props: PropTypes) => {
  const classes = useStyles(props);
  const { tagsLoaded, tags, selectedTag, selectedWebsite } = props;
  const { handleTagsLoaded, handleTagClick, handleTagCreation } = props;
  const [searchText, setSearchText] = useState("");
  const [onlyWithText, setOnlyWithText] = useState(false);
  const locales = filter(selectedWebsite.locales, (l) => l !== selectedWebsite.defaultLocale);
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    handleTagsLoaded(agent.Tags.all(selectedWebsite._id, true, onlyWithText));
  }, [selectedWebsite, handleTagsLoaded, onlyWithText]);

  const syncTags = () => {
    agent.Tags.sync(selectedWebsite._id).then((res) => {
      handleTagsLoaded(agent.Tags.all(selectedWebsite._id, true));
    });
  };

  const filteredTags =
    tags.length === 0 || searchText === ""
      ? tags
      : filter(
          tags,
          (t) =>
            t.name.toLowerCase().includes(searchText.toLowerCase()) ||
            t.title?.toLowerCase().includes(searchText.toLowerCase())
        );

  const createNewTopic = (name: string) => {
    agent.Tags.new(selectedWebsite._id, name)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(I18n.t("snackbar.update_error", { msg: res.message }));
          return;
        }
        handleTagCreation(res);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  return (
    <div className={classes.groupWrapper}>
      {/* <div> */}
      {/*  <SyncIcon onClick={() => syncTags()} /> */}
      {/* </div> */}
      <CfCheckBox
        customClassName={classes.cbRoot}
        value={"example check box"}
        label={I18n.t("manage_content.tag_pages.with_text_only")}
        handleChange={(checked) => setOnlyWithText(checked)}
        checked={onlyWithText}
      />
      <div className={classes.searchWrapper}>
        <CfSearchInput
          customClass={classes.search}
          customInputClass={classes.searchInput}
          customSearchIconClass={classes.searchIcon}
          borderColor={"transparent"}
          // color={'white'}
          searchText={searchText}
          autoSearch
          placeholder={I18n.t("upload_post.search_in_list")}
          handleSearchTextChange={(text) => setSearchText(text)}
        />
        <MTextButton text={I18n.t("products.add")} disabled={!searchText} onClick={() => createNewTopic(searchText)} />
      </div>

      <div className={classes.listContainer}>
        <List component="nav" className={classes.groupList}>
          {tagsLoaded &&
            filteredTags.length > 0 &&
            filteredTags.map((tag) => (
              <ListItem
                // button
                key={tag._id}
                className={classes.listItem}
                // className={ClassNames(topic.description.length === 0 && classes.missingDescription)}
                onClick={(event) => handleTagClick(tag)}
              >
                <ListItemText
                  primary={`${tag.name}`}
                  primaryTypographyProps={{
                    variant: "subtitle2",
                    className: ClassNames(classes.itemPrimaryText, tag._id === selectedTag._id && classes.itemSelected),
                  }}
                  secondary={`${tag.state} ,${I18n.t("manage_content.tag_pages.post_count", {
                    count: tag.totalPosts,
                  })}`}
                  secondaryTypographyProps={{
                    variant: "body2",
                    className: ClassNames(
                      classes.itemSecondaryText,
                      tag._id === selectedTag._id && classes.itemSelected
                    ),
                  }}
                />
                {/* <ListItemSecondaryAction> */}
                {/*  {tag.uploadedUrl && ( */}
                {/*    <Link */}
                {/*      target={'_blank'} */}
                {/*      rel="noopener" */}
                {/*      href={clients.topicPage(selectedWebsite, tag)} */}
                {/*      onClick={(e) => e.stopPropagation()} */}
                {/*    > */}
                {/*      <IconButton edge="end" aria-label="delete"> */}
                {/*        <OpenInNewIcon /> */}
                {/*      </IconButton> */}
                {/*    </Link> */}
                {/*  )} */}
                {/* </ListItemSecondaryAction> */}

                {locales.map((locale, index) => {
                  if (tag.translations[locale] && tag.translations[locale].name) {
                    return null;
                  }
                  return (
                    <div
                      className={classes.missingLocale}
                      style={{
                        top: 20 * index,
                      }}
                    >
                      {locale.toUpperCase()}
                    </div>
                  );
                })}
              </ListItem>
            ))}
          {!tagsLoaded && (
            <ListItem button>
              <ListItemText primary="Loading..." />
            </ListItem>
          )}
        </List>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsList);
