import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import { IExpert } from "../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import EducationYear from "./EducationYear";
import useDebounce from "../../../../../hooks/useDebounce";
import ExpertCollapsibleCard from "./ExpertCollapsibleCard";

const useStyles = makeStyles((theme: Theme) => ({
  innerWrapper: {
    padding: "30px 0px 30px 30px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 8,
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    "&:first-child": {
      marginTop: 0,
    },
  },
  rowGaps: {
    gap: 20,
  },

  textFieldWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  fieldTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    minWidth: 140,
    marginRight: 15,
  },
  profilePicWrapper: {
    marginRight: 40,
  },
  divider: {
    marginLeft: -30,
    marginTop: 20,
    marginBottom: 20,
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  itemDetails: {
    width: "100%",
  },
  itemDeleteIcon: {
    width: 30,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    paddingBottom: 7,
    marginLeft: 20,
  },
  deleteIcon: {
    cursor: "pointer",
    fill: "#808080",
    color: "#808080",
    "&:hover": {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  expert: IExpert;

  handleExpertChange: (expert: IExpert) => void;
};

const ExpertEducation = (props: PropTypes) => {
  const classes = useStyles(props);
  const { expert, handleExpertChange } = props;

  const [educations, setEducations] = React.useState(
    expert?.educationAndCertifications?.filter(({ type }) => type === "Education") || []
  );

  const debouncedEducations = useDebounce(educations, 500);

  React.useEffect(() => {
    if (debouncedEducations.length === 0) {
      handleExpertChange({ ...expert, educationAndCertifications: [] });
      return;
    }
    handleExpertChange({
      ...expert,
      educationAndCertifications: [
        ...expert.educationAndCertifications.filter(({ type }) => type !== "Education"),
        ...debouncedEducations.filter(({ education, institute }) => education && institute),
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEducations]);

  const handleItemChange = (item: IExpert["educationAndCertifications"][number], index: number) => {
    if (index === educations.length) {
      setEducations([...educations, item]);
    } else {
      setEducations(educations.map((education, i) => (i === index ? item : education)));
    }
  };

  const handleDeleteClick = (index: number) => {
    setEducations(educations.filter((_, i) => i !== index));
  };

  const isItemFilled = (item: IExpert["educationAndCertifications"][number]) => {
    if (!item) return true;
    const { education, institute, year } = item;
    return education || institute || year;
  };

  return (
    <ExpertCollapsibleCard
      showSwitch
      title={I18n.t("experts.profile.education")}
      checked={expert.visibility.education}
      onSwitch={(checked) =>
        handleExpertChange({
          ...expert,
          visibility: {
            ...expert.visibility,
            education: checked,
          },
        })
      }
    >
      {[
        ...educations,
        ...(educations.length === 0 || isItemFilled(educations[educations.length - 1])
          ? [
              {
                type: "Education",
                education: null,
                year: null,
                institute: null,
              },
            ]
          : []),
      ].map((item, index, array) => (
        <div className={classes.itemWrapper} key={`expert_education_${index}`}>
          <div className={classes.itemDetails}>
            <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
              <TextFieldMaxLength
                key={`education_expert${index}_education`}
                titleAndLengthLocation={"top"}
                value={item.education}
                placeholder={I18n.t("experts.profile.write_ph")}
                title={index === 0 && I18n.t("experts.profile.qualification")}
                borderRadius={4}
                handleValueChange={(education) => {
                  handleItemChange({ ...item, education }, index);
                }}
              />

              <TextFieldMaxLength
                key={`education_expert${index}_institute`}
                titleAndLengthLocation={"top"}
                value={item.institute}
                placeholder={I18n.t("experts.profile.write_ph")}
                title={index === 0 && I18n.t("experts.profile.institution")}
                borderRadius={4}
                handleValueChange={(institute) => {
                  handleItemChange({ ...item, institute }, index);
                }}
              />

              <EducationYear
                year={item.year}
                title={index === 0 && I18n.t("experts.profile.year")}
                handleYearChange={(year) => {
                  handleItemChange({ ...item, year }, index);
                }}
              />
            </div>
          </div>
          <div className={classes.itemDeleteIcon}>
            {array.length > 1 && index !== array.length - 1 && (
              <ClearIcon onClick={() => handleDeleteClick(index)} className={classes.deleteIcon} />
            )}
          </div>
        </div>
      ))}
    </ExpertCollapsibleCard>
  );
};

export default ExpertEducation;
