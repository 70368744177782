import { Theme } from "@material-ui/core";
import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { IWidgetFieldProperties, IWidgetTextFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import TextareaProperty from "./itemProperties/components/TextareaProperty";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  label: {
    padding: 0,
  },
  textarea: {
    border: `1px solid ${palette.grey["100"]}`,
    width: "100%",
    borderRadius: 3,
    backgroundColor: palette.primary.dark,
  },
}));

type PropTypes = {
  properties: IWidgetFieldProperties;
  fieldType: string;
  onChange: (properties: IWidgetTextFieldProps) => void;
};

const TextWidgetItem = (props: PropTypes) => {
  const { onChange, properties } = props;
  const { value = "" } = properties as IWidgetTextFieldProps;
  const classes = useStyles();

  return (
    <TextareaProperty
      placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.text")}
      onChange={(val) => onChange({ value: val })}
      value={value}
      classNames={{ label: classes.label, textarea: classes.textarea }}
    />
  );
};

export default TextWidgetItem;
