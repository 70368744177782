import * as React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { push } from "connected-react-router";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import OutlinePageColumns from "./OutlinePageColumns";
import { KEYWORD_STRATEGY } from "../../../../reducers/constants/actionTypes";
import agent from "../../../../agent";
import OutlinePageListItem from "./OutlinePageListItem";
import MTable from "../../../../components/Table/Table/MTable";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";
import { useState } from "react";
import set from "lodash/set";
import OutlineInternalNoteDialog from "./OutlineDialog/OutlineInternalNoteDialog";
import OutlinePageListItemCluster from "./OutlinePageListItemCluster";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  loading: state.keywordStrategy.dataLoading,
  data: state.keywordStrategy.dataList,
  sortDirection: state.keywordStrategy.sort.direction,
  selectedDataItemsIndexes: state.keywordStrategy.selectedDataItemsIndexes,
  refetch: state.keywordStrategy.refetch,
  total: state.keywordStrategy.total,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoading(loading: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_LOADING, payload: loading });
  },
  changeIsDialogOpen(open: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_IS_DIALOG_OPEN_CHANGED, payload: open });
  },
  changeActiveDialogTab(tab: "outlines" | "keywords" | "broadKeywords" | "pages") {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_ACTIVE_DIALOG_TAB_CHANGED, payload: tab });
  },
  changeSort(sort: { field: string; direction: "desc" | "asc" }) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SORT_CHANGED, payload: sort });
  },
  changeRefetch(refresh: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_REFETCH_CHANGED, payload: refresh });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
  changeSelectedItem(item: any) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SELECTED_DATA_ITEM_CHANGED, payload: item });
  },
  changeBroadKeywordsLoaded(payload: any) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_BROAD_KEYWORDS_LOADED, payload });
  },
  changeSerpsLoaded(payload) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SERPS_LOADED, payload });
  },
  changeSerpsLoading() {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SERPS_LOADING });
  },
  updateItem(item: any) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_UPDATE_ITEM, payload: item });
  },
  // old
  redirect: (url) => dispatch(push(url)),
});

type PropTypes = {
  loading: boolean;
  data: any[];
  sortField: { field: string; direction: "desc" | "asc" };
  setSortField: (sort: { field: string; direction: "desc" | "asc" }) => void;
  selectedDataItemsIndexes: number[];
  refetch: boolean;
  selectedWebsite: IWebsite;

  changeSerpsLoaded(payload: any): void;
  changeSerpsLoading(): void;
  changeLoading(loading: boolean): void;
  changeBroadKeywordsLoaded(payload: any): void;
  changeSelectedItem(item: any): void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  changeSort(sort: { field: string; direction: "desc" | "asc" }): void;
  changeIsDialogOpen: (open: boolean) => void;
  changeActiveDialogTab: (tab: "outlines" | "keywords" | "pages") => void;
  changeRefetch: (refresh: boolean) => void;

  redirect: (url: string) => void;
  total: number;
  updateItem: (item: any) => void;
};

const OutlinePageList = (props: PropTypes) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    changeLoading,
    selectedWebsite,
    changeSerpsLoaded,
    changeActiveDialogTab,
    changeIsDialogOpen,
    changeSelectedDataItemsIndexes,
    loading,
    selectedDataItemsIndexes,
    data,
    changeSerpsLoading,
    refetch,
    redirect,
    changeRefetch,
    sortField,
    setSortField,
    total,
    changeSelectedItem,
    changeBroadKeywordsLoaded,
    updateItem,
  } = props;
  const [internalNoteDialogOpen, setInternalNoteDialogOpen] = useState(false);

  const sumbitForApproval = async (item, postType) => {
    try {
      if (item.conversion_potential === "None") {
        throw new Error("Submission failed. Topic has no funnel stage");
      }
      const data = {
        ...item,
        status: "pending",
        submitted_at: new Date(),
        post_type: postType,
      };
      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, data, "true");
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.pending_success"));
      updateItem(data);
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const deleteOutline = async (item) => {
    changeLoading(true);
    try {
      await agent.KeywordStrategy.deleteKeywordStrategy(selectedWebsite._id, item.keyword);
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.topic_deleted"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }

    changeLoading(false);
    changeRefetch(!refetch);
    changeSelectedDataItemsIndexes([]);
  };

  const openDialog = async (strategyKeywords: any, tab: "outlines" | "keywords" | "pages") => {
    changeSelectedItem(strategyKeywords);
    changeBroadKeywordsLoaded(
      await agent.KeywordStrategy.getBroadKeywords(selectedWebsite._id, strategyKeywords.keyword)
    );
    changeSerpsLoading();
    agent.KeywordStrategy.getSerps(selectedWebsite._id, strategyKeywords.keyword)
      .then((serps) => {
        changeSerpsLoaded(serps);
      })
      .catch((e) => {
        console.log(e);
      });
    changeActiveDialogTab(tab);
    changeIsDialogOpen(true);
  };

  const changeInternalNote = async (item, internal_note) => {
    try {
      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, {
        ...item,
        internal_note,
      });
      updateItem({ ...item, internal_note });
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.internal_note"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    setInternalNoteDialogOpen(false);
  };

  const openInternalNoteDialog = (strategyKeyword: any) => {
    changeSelectedItem(strategyKeyword);

    setInternalNoteDialogOpen(true);
  };

  return (
    <MTable>
      <OutlinePageColumns
        total={total}
        selectAll={() => {
          changeSelectedDataItemsIndexes(data.map((item, index) => index));
        }}
        clearSelected={() => {
          changeSelectedDataItemsIndexes([]);
        }}
        selectedNr={selectedDataItemsIndexes.length}
        sortField={sortField}
        setSortField={setSortField}
      />
      {!loading && data?.length < 1 && <MTableRowEmptyRow />}

      {loading && <MTableRowProgressBar />}

      {!loading &&
        data?.length > 0 &&
        data.map((item, index) => (
          <OutlinePageListItemCluster
            item={item}
            key={index}
            openInternalNoteDialog={openInternalNoteDialog}
            selectedWebsite={selectedWebsite}
            redirect={redirect}
            selected={selectedDataItemsIndexes.includes(index)}
            setSelect={(selected) => {
              if (selected) {
                changeSelectedDataItemsIndexes([...selectedDataItemsIndexes, index]);
              } else {
                changeSelectedDataItemsIndexes(selectedDataItemsIndexes.filter((i) => i !== index));
              }
            }}
            approve={sumbitForApproval}
            onClick={(kwStrategy) => {
              openDialog(kwStrategy, "pages");
            }}
            deleteOutline={deleteOutline}
          />
        ))}
      <OutlineInternalNoteDialog
        open={internalNoteDialogOpen}
        changedInternalNote={changeInternalNote}
        closeDialog={() => setInternalNoteDialogOpen(false)}
      />
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlinePageList);
