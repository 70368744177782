import * as React from "react";
import { I18n } from "react-redux-i18n";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import { filter, find } from "lodash";
import { IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { CharticleTopProducts } from "../../../types/editor.Types";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../../elementsStyles/components/StylePanelSubSection";
import MultipleProductsPickerElement from "../../../elementsStyles/ElementComponents/MultipleProductsPickerElement";
import TypographyStyleElement from "../../../elementsStyles/ElementComponents/TypographyStyleElement";
import ButtonStyleElement from "../../../elementsStyles/ElementComponents/ButtonStyleElement";
import CardStyleElement from "../../../elementsStyles/ElementComponents/CardStyleElement";
import ListStyleElement from "../../../elementsStyles/ElementComponents/ListStyleElement";
import TextFieldElement from "../../../elementsStyles/ElementComponents/TextFieldElement";
import theme from "../../../../themes/theme";

type PropTypes = {
  editor: Editor;
  selectedWebsite: IWebsite;
  element: CharticleTopProducts;
  websiteTheme: IWebsiteThemeProps;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
};

const PageEditorCharticleTopProductsStyle = (props: PropTypes) => {
  const { editor, selectedWebsite, element, products, handleProductsChange, websiteTheme } = props;

  const handleElementDataChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };
  const selectedProducts =
    element.data.products?.map((ep) => find(products, (p) => p.itemNumber === ep?.itemNumber)) || [];
  return (
    <StylePanelSection
      sectionTitle={I18n.t("rich_text_editor.custom_components.charticle_top_products")}
      editor={editor}
    >
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.product_cta.products")}>
        <MultipleProductsPickerElement
          isClearable={false}
          fullWidth
          editor={editor}
          selectedWebsite={selectedWebsite}
          products={selectedProducts}
          handleProductsChange={handleProductsChange}
          onProductsChange={(uProducts: IProduct[]) => {
            handleElementDataChange({
              ...element.data,
              products: uProducts.map((p, index) => ({
                itemNumber: p.itemNumber,
                position: index + 1,
              })),
            });
          }}
        />
      </StylePanelSubSection>

      <CardStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.product_cta.card")}
        card={element.data.card}
        elementDefaultCss={{
          borderRadius: 4,
          padding: "24px",
        }}
        websiteTheme={websiteTheme}
        handleChange={(card) => {
          handleElementDataChange({ ...element.data, card });
        }}
      />

      <StylePanelSubSection
        divider
        sectionTitle={I18n.t("rich_text_editor.position")}
        show={element.data?.position.show}
        handleShowChange={(value) =>
          handleElementDataChange({ ...element.data, position: { ...element.data.position, show: value } })
        }
      >
        {element.data.position.show &&
          element.data?.products.map((p, i) => {
            const product = find(products, (p2) => p2.itemNumber === p.itemNumber);
            return (
              <TextFieldElement
                title={product.name || I18n.t("rich_text_editor.position_index")}
                placeholder={I18n.t("rich_text_editor.position_index_ph")}
                value={p.position || `${i + 1}`}
                handleChange={(index) => {
                  const prods = [...(element.data?.products || [])];
                  prods[i] = { ...p, position: index };
                  handleElementDataChange({ ...element.data, products: prods });
                }}
              />
            );
          })}
      </StylePanelSubSection>

      <StylePanelSubSection
        divider
        sectionTitle={I18n.t("rich_text_editor.logo")}
        show={element.data?.logo.show}
        handleShowChange={(value) =>
          handleElementDataChange({ ...element.data, logo: { ...element.data.logo, show: value } })
        }
      >
        {}
      </StylePanelSubSection>

      <StylePanelSubSection
        divider
        sectionTitle={I18n.t("rich_text_editor.rating")}
        show={element.data?.rating.show}
        handleShowChange={(value) =>
          handleElementDataChange({ ...element.data, rating: { ...element.data.rating, show: value } })
        }
      >
        {}
      </StylePanelSubSection>

      <TypographyStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.short_description")}
        typography={element.data?.shortDescription}
        elementDefaultCss={{
          fontSize: 16,
          fontWeight: theme.typography.fontWeightRegular as any,
          color: websiteTheme.palette.text.primary,
          margin: "14px 0px 0px 0px",
          padding: "0px 0px 0px 0px",
        }}
        variant={"h6"}
        handleChange={(data) => handleElementDataChange({ ...element.data, shortDescription: data })}
        theme={websiteTheme}
      />

      <ListStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.bullets")}
        list={element.data?.bullets}
        elementDefaultCss={{
          display: "flex",
          alignItems: "center",
          margin: "4px 0px 0px 0px",
          lineHeight: 1.5,
          fontSize: 16,
          fontWeight: theme.typography.fontWeightRegular as any,
          listMargin: "24px 0px 0px 0px",
        }}
        variant={"subtitle1"}
        handleChange={(data) => handleElementDataChange({ ...element.data, bullets: data })}
        websiteTheme={websiteTheme}
      />

      <ButtonStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.product_cta.button")}
        button={element.data.button}
        // defaultText={product?.callToAction || ""}
        // defaultUrl={product?.callToActionURL || ""}
        theme={websiteTheme}
        handleChange={(button) => {
          handleElementDataChange({ ...element.data, button });
        }}
      />
    </StylePanelSection>
  );
};

export default PageEditorCharticleTopProductsStyle;
