import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import ClassNames from "classnames";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import { DatePickerView } from "@material-ui/pickers/DatePicker/DatePicker";

const useStyles = makeStyles((theme: Theme) => ({
  postedTimeWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  datesWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  root: {
    width: "100%",
  },
  text: {
    color: theme.palette.text.hint,
  },
  originalDate: {
    padding: 0,
  },
  dateIcon: {
    marginLeft: 10,
    fontSize: theme.typography.pxToRem(18),
  },
  dateIconReadOnly: {
    color: theme.palette.text.disabled,
  },
}));

type PropTypes = {
  customClass?: string;
  inputClassName?: string;
  disabled?: boolean;
  disableUnderline?: boolean;
  disableDateIcon?: boolean;
  dateFormat?: string;
  placeholder?: string;
  disableFuture?: boolean;

  date: moment.Moment;

  onDateChanged: (Date) => void;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  views?: DatePickerView[];
};

const MDatePicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    customClass = null,
    disabled = false,
    disableUnderline = true,
    disableDateIcon = false,
    disableFuture = true,
    inputClassName = null,
    date,
    dateFormat = "MM-DD-YYYY",
    placeholder = null,
    views = null,
    maxDate = moment().add(1, "day"),
    minDate = undefined,
  } = props;
  const { onDateChanged } = props;
  return (
    <div className={ClassNames(classes.postedTimeWrapper)}>
      <div className={ClassNames(classes.datesWrapper, customClass)}>
        <DatePicker
          disabled={disabled}
          value={date}
          views={views}
          placeholder={placeholder || dateFormat}
          disableFuture={disableFuture}
          onChange={(cDate) => onDateChanged(cDate.toDate())}
          minDate={minDate}
          maxDate={maxDate}
          format={dateFormat}
          minDateMessage={""}
          InputProps={{
            classes: {
              root: ClassNames(classes.root, inputClassName),
            },
            disableUnderline,
          }}
        />
      </div>
      {!disableDateIcon && <EventIcon className={ClassNames(classes.dateIcon, disabled && classes.dateIconReadOnly)} />}
    </div>
  );
};

export default MDatePicker;
