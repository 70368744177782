import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import find from "lodash/find";
import filter from "lodash/filter";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state/index";
import { makeStyles } from "@material-ui/styles";
import { IAdminUser, IWebsite } from "../../../../../reducers/constants/objectTypes";
import CfCheckBox from "../../../../../components/CfCheckBox";
import theme from "../../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  selectorWrapper: {
    display: "flex",
  },
  dropDownWrapper: {},
  newItemButton: {
    width: "100%",
    height: 60,
    borderRadius: "0px 0px 5px 5px",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
    textTransform: "capitalize" as const,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  newItemButtonLocked: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.secondary,
    },
  },
  searchWrapper: {
    height: 80,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchTextField: {
    margin: "0px 10px 0px 10px",
  },
  searchTextFieldLabel: {
    "&$searchTextFieldFocused": {
      color: theme.palette.text.secondary,
    },
  },
  searchTextFieldFocused: {},
  searchTextFieldUnderline: {
    "&:after": {
      borderBottomColor: theme.palette.text.secondary,
    },
  },
  searchTextFieldOutlinedInput: {
    height: 40,
    paddingRight: 0,
    "&$searchTextFieldNotchedOutline": {
      borderColor: "yellow",
      borderWidth: 1,
    },
    "&$searchTextFieldFocused $searchTextFieldNotchedOutline": {
      borderColor: theme.palette.divider,
      borderWidth: 1,
    },
  },
  searchTextFieldNotchedOutline: {
    borderColor: `${theme.palette.divider} !important`,
    // borderWidth: 1,
    borderWidth: "0px 0px 1px 0px !important",
    borderRadius: 0,
  },
  searchIcon: {
    color: theme.palette.primary.dark,
  },
  itemsList: {
    overflowY: "auto" as const,
    maxHeight: 240,
    minHeight: 40,
  },
  itemsListItem: {
    cursor: "pointer",
    height: 38,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& $itemsListItemText": {
        color: theme.palette.primary.main,
      },
      "& $listItemIcon": {
        visibility: "unset",
      },
    },
  },
  selectWebsites: {
    cursor: "pointer",
  },
  itemsListItemText: {
    fontWeight: theme.typography.fontWeightMedium as any,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
  },
  itemsListSelected: {
    backgroundColor: theme.palette.secondary.main,
    "& $itemsListItemText": {
      color: theme.palette.primary.main,
    },
  },
  listItemIcon: {
    minWidth: "unset",
  },
  popoverPaper: {},
  popoverOfflineIcon: {
    fill: "#627cfb",
    backgroundColor: theme.palette.primary.light,
    borderRadius: "50%",
    fontSize: 16,
    marginLeft: 10,
    "&:hover": {
      fill: "#627cfb",
    },
  },
  inputLabel: {
    fontWeight: theme.typography.fontWeightMedium as any,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    marginBottom: "4px",
  },
}));

type PropTypes = {
  websites: IWebsite[];
  user: IAdminUser;

  buttonWrapperClass?: any;
  width?: number;

  transformHorizontal?: "left" | "center" | "right" | number;

  handleUserChanged: Function;
};

const UserWebsitesPicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const { websites, user, handleUserChanged } = props;
  const { buttonWrapperClass, transformHorizontal = "right", width = null } = props;

  const onWebsiteSelected = (website) => {
    if (find(user.websites, (uw) => uw._id === website._id) !== undefined) {
      handleUserChanged({ ...user, websites: filter(user.websites, (uw) => uw._id !== website._id) });
    } else {
      handleUserChanged({ ...user, websites: [...(user.websites || []), website] });
    }
  };

  return (
    <div className={classes.selectorWrapper}>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div className={buttonWrapperClass || null}>
            <div className={classes.selectWebsites} role="cell" {...bindTrigger(popupState)}>
              {user.websites && user.websites.length > 0 ? user.websites.map((w) => w.name).join(", ") : "Select..."}
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: transformHorizontal,
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: transformHorizontal,
              }}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <div style={{ width: width || "unset" }}>
                <List className={classes.itemsList}>
                  {websites.map((website) => (
                    <ListItem
                      key={website._id}
                      classes={{
                        container: ClassNames(classes.itemsListItem),
                      }}
                      // onClick={event => onItemSelected(popupState, item)}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <CfCheckBox
                          checked={find(user.websites, (uw) => uw._id === website._id) !== undefined}
                          value={website.name}
                          handleChange={() => onWebsiteSelected(website)}
                        />
                      </ListItemIcon>
                      <ListItemText className={classes.itemsListItemText} primary={website.name} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default UserWebsitesPicker;
