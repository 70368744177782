import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { Button, Theme, Typography } from "@material-ui/core";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { I18n } from "react-redux-i18n";
import { IWebsite, IWidgetField, IWidgetInputFieldProps } from "../../../../../../../../reducers/constants/objectTypes";
import ParagraphWidgetItem from "../ParagraphWidgetItem";
import ShortTextWidgetItem from "../ShortTextWidgetItem";
import EmailWidgetItem from "../EmailWidgetItem";
import PhoneWidgetItem from "../PhoneWidgetItem";
import WebsiteWidgetItem from "../WebsiteWidgetItem";
import LongTextWidgetItem from "../LongTextWidgetItem";
import DateWidgetItem from "../DateWidgetItem";
import HiddenWidgetItem from "../HiddenWidgetItem";
import HeadingWidgetItem from "../HeadingWidgetItem";
import LabelWidgetItem from "../LabelWidgetItem";
import DropdownWidgetItem from "../DropdownWidgetItem";
import ConsentWidgetItem from "../ConsentWidgetItem";
import StartScaleWidgetItem from "../StarScaleWidgetItem";
import SmileWidgetItem from "../SmileyWidgetItem";
import LikeWidgetItem from "../LikeWidgetItem";
import NumbersWidgetItem from "../NumbersWidgetItem";
import CheckboxWidgetItem from "../CheckboxWidgetItem";
import SubmitButtonWidgetItem from "../ButtonWidgetItem";
import GenericMediaItem from "../components/GenericMediaItem";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: ({ noPadding }: PropTypes) => ({
      padding: !noPadding ? 20 : 0,
    }),
    container: {
      display: "flex",
      flexDirection: "column",
    },
    header: (props: PropTypes) => ({
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      marginBottom: 15,
    }),
    properties: {},
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
    leftContainer: (props: PropTypes) => {
      const color = palette.text.primary;
      return {
        display: "flex",
        alignItems: "center",
        maxWidth: 300,
        color,
        marginLeft: -8,
      };
    },
    rightContainer: (props: PropTypes) => ({
      display: "flex",
      alignItems: "center",
      maxWidth: 300,
    }),
    button: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      fontSize: 14,
      textTransform: "none",
      height: 30,
      padding: 10,
      borderRadius: "20px",
      fontWeight: typography.fontWeightMedium as any,
      "&:hover": {
        backgroundColor: palette.primary.light,
        color: palette.common.white,
      },
      "&:disabled": {
        borderColor: palette.primary.dark,
        color: palette.primary.dark,
        backgroundColor: palette.action.selected,
        cursor: "initial",
      },
    },
    label: {
      fontSize: 14,
      fontWeight: typography.fontWeightMedium as any,
    },
  };
});

type PropTypes = {
  field: IWidgetField;
  noPadding?: boolean;
  selectedWebsite: IWebsite;
  onCancel(): void;
  onDone(field: IWidgetField): void;
};

const WidgetField = (props: any) => {
  switch (props.type) {
    //   inputs
    case "image":
      return (
        <GenericMediaItem
          type="image"
          handleChange={(value) => props.onPropertyChange({ ...props?.properties, ...value })}
          media={props?.properties}
          selectedWebsite={props.selectedWebsite}
        />
      );
    case "short_text":
      return <ShortTextWidgetItem {...props} />;
    case "long_text":
      return <LongTextWidgetItem {...props} />;
    case "email":
      return <EmailWidgetItem {...props} />;
    case "url":
      return <WebsiteWidgetItem {...props} />;
    case "phone":
      return <PhoneWidgetItem {...props} />;
    case "date":
      return <DateWidgetItem {...props} />;
    case "hidden":
      return <HiddenWidgetItem {...props} />;

    // text fields
    case "heading":
      return <HeadingWidgetItem {...props} />;
    case "paragraph":
      return <ParagraphWidgetItem {...props} />;
    case "label":
      return <LabelWidgetItem {...props} />;

    //   selections
    case "checkbox":
      return <CheckboxWidgetItem {...props} />;
    case "dropdown":
      return <DropdownWidgetItem {...props} />;
    case "consent":
      return <ConsentWidgetItem {...props} />;

    //   ratings
    case "star_scale":
      return <StartScaleWidgetItem {...props} />;
    case "numbers":
      return <NumbersWidgetItem {...props} />;
    case "smiley":
      return <SmileWidgetItem {...props} />;
    case "like":
      return <LikeWidgetItem {...props} />;

    case "submit_button":
      return <SubmitButtonWidgetItem {...props} />;

    // case "title":
    //   return <TitleWidgetItem {...props} />;
    // case "file":
    //   return <FileWidgetItem {...props} />;
    default:
      throw new Error(`Error: Unhandled type: ${props.type}`);
  }
};

const ItemProperties = (props: PropTypes) => {
  const { field, selectedWebsite, onCancel, onDone } = props;
  const classes = useStyles(props);
  const [properties, setProperties] = useState({ ...field.properties });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setProperties(field.properties);
              onCancel();
            }}
            className={classes.leftContainer}
          >
            <KeyboardArrowLeftRounded />
            <Typography className={classes.label}>
              {I18n.t(`widgets_builder.widget_item_selector.${field?.type}`) || field.type}
            </Typography>
          </div>
          <div className={classes.rightContainer}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onDone({
                  ...field,
                  properties,
                });
              }}
              className={classes.button}
            >
              {I18n.t("widgets_builder.build.item_done_button")}
            </Button>
          </div>
        </div>
        <div className={classes.properties}>
          <WidgetField
            onPropertyChange={(properties) => {
              setProperties(properties);
            }}
            selectedWebsite={selectedWebsite}
            properties={properties}
            type={field.type}
          />
        </div>
      </div>
    </div>
  );
};

export default ItemProperties;
