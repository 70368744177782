import { WRITE_PAGES } from "./constants/actionTypes";

const initialState = {
  draftPages: [],
  draftCount: 0,
  publicPages: [],
  publicCount: 0,
  rejected: [],
  rejectedCount: 0,
  paginationPagesCount: 0,
  takenByOthers: [],
  takenByOthersCount: 0,

  pagesLoaded: true,
  refreshPages: false,
  filter: {
    searchText: "",
    postType: [],
    category: [],
    sortField: { direction: "desc", field: "updated" },
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case WRITE_PAGES.WRITE_PAGES_LOADED:
      return {
        ...state,
        draftPages: action.payload.pages.draft || [],
        draftCount: action.payload.pages.draftCount || 0,
        publicPages: action.payload.pages.public || [],
        publicCount: action.payload.pages.publicCount || 0,
        rejected: action.payload.pages.rejected || [],
        rejectedCount: action.payload.pages.rejectedCount || 0,
        takenByOthers: action.payload.pages.takenByOthers || [],
        takenByOthersCount: action.payload.pages.takenByOthersCount || 0,
        paginationPagesCount: action.payload.paginationPagesCount,
        pagesLoaded: true,
      };
    case WRITE_PAGES.WRITE_PAGES_UPDATE_PAGE: {
      return {
        ...state,
        [action.payload.key]: action.payload.pages,
      };
    }
    case WRITE_PAGES.WRITE_PAGES_LOAD_FAILED:
      return {
        ...state,
        pagesLoaded: true,
        draftPages: [],
        publicPages: [],
        rejected: [],
      };
    case WRITE_PAGES.WRITE_PAGES_LOADING:
      return {
        ...state,
        pagesLoaded: false,
      };

    case WRITE_PAGES.WRITE_PAGES_REFRESH:
      return {
        ...state,
        refreshPages: !state.refreshPages,
      };
    case WRITE_PAGES.WRITE_PAGES_ON_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
