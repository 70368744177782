import React from "react";
import { I18n } from "react-redux-i18n";
import { IWidgetFieldProperties, IWidgetProductNameFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "./components/GenericValueItemProperty";

type PropTypes = {
  properties: IWidgetFieldProperties;
  fieldType: string;
  onChange: (properties: IWidgetProductNameFieldProps) => void;
};

const ProductNameWidgetItem = (props: PropTypes) => {
  const { onChange, properties, fieldType } = props;
  const { value = "" } = properties as IWidgetProductNameFieldProps;
  return (
    <GenericValueItemProperty
      onChange={onChange}
      value={value}
      label={I18n.t(`widgets_builder.widget_item_selector.${fieldType}`)}
    />
  );
};

export default ProductNameWidgetItem;
