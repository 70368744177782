import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function BoldIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 24 28"}>
      <path d="M18.164,13.580 C20.143,12.240 21.531,10.040 21.531,8.000 C21.531,3.480 17.960,0.000 13.368,0.000 L2.654,0.000 C1.531,0.000 0.613,0.900 0.613,2.000 L0.613,26.000 C0.613,27.100 1.531,28.000 2.654,28.000 L14.450,28.000 C18.674,28.000 22.531,24.620 22.552,20.460 C22.572,17.400 20.817,14.780 18.164,13.580 ZM6.735,5.000 L12.858,5.000 C14.552,5.000 15.919,6.340 15.919,8.000 C15.919,9.660 14.552,11.000 12.858,11.000 L6.735,11.000 L6.735,5.000 ZM13.878,23.000 L6.735,23.000 L6.735,17.000 L13.878,17.000 C15.572,17.000 16.939,18.340 16.939,20.000 C16.939,21.660 15.572,23.000 13.878,23.000 Z" />
    </SvgIcon>
  );
}

export default BoldIcon;
