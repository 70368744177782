import { Theme, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import React from "react";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: {
      //   backgroundColor: palette.grey["100"],
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      color: "white",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      height: "auto",
    },
    text: {
      fontSize: 15,
      color: palette.text.secondary,
    },
    btnConnect: {
      width: "100%",
      marginTop: 20,
      padding: "10px 0px 10px 0px",
      background: "#505050",
    },
    btnDelete: {
      width: "100%",
      marginTop: 20,
      padding: "10px 0px 10px 0px",
      color: "#d9534f",
      background: "#505050",
    },
    btnGoToZapier: {
      width: "100%",
      marginTop: 20,
      padding: "10px 0px 10px 0px",
      color: "#5271FF",
      background: "#505050",
    },
    marginTop: {
      marginTop: 10,
    },
    marginTop2: {
      marginTop: 25,
    },
    zapierToken: {
      background: "#505050",
      width: "100%",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "row",
      padding: 10,
      marginTop: 20,
      justifyContent: "space-between",
    },
    tokenText: {
      fontSize: 13,
      color: palette.text.secondary,
    },
    btnCopy: {
      height: 50,
      background: "#fff",
      color: "black",
    },
  };
});

type PropTypes = {};

const ZapierIntegration = (props: PropTypes) => {
  const classes = useStyles(props);

  const [hasIntegration, setHasIntegraiton] = React.useState(false);

  if (!hasIntegration) {
    return (
      <div className={classes.root}>
        <Typography className={classes.text}>{I18n.t("widgets_builder.targeting.zapier_description")}</Typography>
        <Button className={classes.btnConnect} onClick={() => setHasIntegraiton(true)}>
          {I18n.t("widgets_builder.targeting.connect")}
        </Button>
      </div>
    );
  }

  if (hasIntegration) {
    return (
      <div className={classes.root}>
        <div>
          <Typography className={classes.text}>{I18n.t("widgets_builder.targeting.step_1")}</Typography>

          <div className={classes.zapierToken}>
            <div>
              <Typography className={classes.tokenText}>
                {I18n.t("widgets_builder.targeting.zapier_connection_token")}
              </Typography>
              <TextField
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <Button className={classes.btnCopy}>Copy</Button>
          </div>
        </div>

        <div className={classes.marginTop2}>
          <Typography className={classes.text}>{I18n.t("widgets_builder.targeting.step_2")}</Typography>
          <Button className={classes.btnGoToZapier}>{I18n.t("widgets_builder.targeting.go_to_zapier")}</Button>
        </div>

        <div className={classes.marginTop2}>
          <Typography className={ClassNames(classes.text, classes.marginTop)}>
            {I18n.t("widgets_builder.targeting.step_3")}
          </Typography>
          <Typography className={ClassNames(classes.text, classes.marginTop)}>
            {I18n.t("widgets_builder.targeting.in_case_of_delete_integration")}
          </Typography>
          <Button className={classes.btnDelete}>{I18n.t("widgets_builder.targeting.delete_integration")}</Button>
        </div>
      </div>
    );
  }
};

export default ZapierIntegration;
