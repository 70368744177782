import ClassNames from "classnames";
import { Button, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { ButtonProps } from "@material-ui/core";
import { colors } from "../../../../../helpers/constants";

type PropTypes = {
  text?: string;
  className?: string;
  onClick?(event: any): unknown;
  id?: string;
} & ButtonProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: 14,
    marginTop: 2,
    borderRadius: 3,
    width: "100%",
    padding: 10,
    height: 55,
    textTransform: "none",
    backgroundColor: "#DEE1FF",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#C4C8FF",
      color: theme.palette.primary.light,
      "& $addIcon": {
        fill: theme.palette.primary.light,
        color: theme.palette.primary.light,
      },
    },
  },
  addIcon: {
    height: "22px",
    marginBottom: 2,
  },
}));

const AddButton = (props: PropTypes) => {
  const classes = useStyles(props);

  const { className, text = I18n.t("widgets_builder.widget_editor.fields.add"), id, ...buttonProps } = props;
  return (
    <Button id={id} className={ClassNames(classes.root, className)} {...buttonProps}>
      <AddIcon className={classes.addIcon} />
      {text}
    </Button>
  );
};

export default AddButton;
