import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { useSlate } from "slate-react";
import AiIcon from "../toolbar/icons/hover/AiIcon";
import { emitEvent } from "../../../hooks/useEvents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
    activeIcon: {
      fill: theme.palette.primary.main,
    },
  })
);

type PropTypes = {
  onClick?: Function;
};

const AiAssistantButton = (props: PropTypes) => {
  const classes = useStyles(props);

  const { onClick } = props;
  return (
    <div style={{ display: "contents" }}>
      <Tooltip enterDelay={2000} title={I18n.t("rich_text_editor.tooltips.ai_select")}>
        <IconButton
          id={"ai-select-button"}
          className={classes.button}
          disableRipple
          onClick={(event) => {
            event.preventDefault();
            emitEvent("show-ai-assistant", { selected: true, openTab: true });
            if (onClick) {
              onClick();
            }
          }}
        >
          <AiIcon className={ClassNames(classes.icon)} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default AiAssistantButton;
