import * as React from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import List from "@material-ui/core/List";
import { find } from "lodash";
import { I18n } from "react-redux-i18n";

import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import MButtonGroup from "../../../../../components/MButtonGroup";
import MButton from "../../../../../components/MButton";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import { useEvent } from "../../../../../hooks/useEvents";
import { colors } from "../../../../../helpers/constants";
import SendIcon from "../../../../../icons/editor/SendIcon";

const useStyles = makeStyles((theme: Theme) => ({
  promptsSectionWrapper: {
    // height: "50%",
    display: "flex",
    flexDirection: "column",
    borderTop: `solid 1px ${theme.palette.divider}`,
    position: "sticky",
    bottom: 0,
    padding: "15px 0 0",
    gap: 15,
  },
  promptButton: {
    padding: 0,
    paddingRight: 5,
    minHeight: 0,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    minWidth: 0,
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  promptsWrapper: {
    gap: 15,
    display: "flex",
    flexDirection: "column",
    margin: 0,
  },
  promptWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  promptTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.secondary,
    marginBottom: 6,
  },
  customPromptTitle: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.secondary,
    marginTop: 15,
    marginBottom: 6,
  },
  gridItem: {
    "& .MuiGrid-item": {
      padding: 3,
    },
  },
  prompt: {
    backgroundColor: colors.promptBackgroundColor,
    color: theme.palette.primary.main,
    borderRadius: 3,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    cursor: "pointer",
    height: 27,
    textTransform: "unset",
    padding: "5px 8px",
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
    },
  },
  selectedPrompt: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  customWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 15,
  },
  sendIcon: {
    width: 29,
    height: 29,
    marginBottom: 8,
    fill: theme.palette.primary.main,
    cursor: "pointer",
  },
  disabledSendIcon: {
    fill: colors.disabledSendIconColor,
  },
  textInput: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    border: "none",
  },
}));

type PropTypes = {
  onRequest: Function;
};
const options = [
  { label: "All prompts", key: "all_prompts", prompts: [] },
  {
    label: "Rephrase",

    key: "rephrase",
    prompts: [
      { label: "Rephrase", key: "rephrase", prompt: "Rephrase text" },
      { label: "Shorten", key: "shorten", prompt: "Shorten text" },
      { label: "Summarize", key: "summarize", prompt: "Summarize text" },
      { label: "Clarify", key: "clarify", prompt: "Clarify text" },
      { label: "Fix grammar", key: "fix_grammar", prompt: "Fix grammar and punctuation" },
    ],
  },
  {
    label: "Find",
    key: "find",
    prompts: [
      {
        label: "Statistics",
        key: "statistics",
        prompt: "Find data and statistics that are relevant to this article and interesting to the target readers",
      },
      {
        label: "Relevant quotes",
        key: "relevant_quotes",
        prompt: "Find quotes that are relevant to this article and interesting to the target readers",
      },
    ],
  },
  {
    label: "Create",
    key: "create",
    prompts: [
      {
        label: "Table",
        key: "table",
        prompt: "Create a table from the selected text",
      },
    ],
  },
  {
    label: "Custom Prompt",
    key: "write_prompt",
    prompts: [],
  },
];

const PageEditorAssistant = (props: PropTypes) => {
  const classes = useStyles(props);
  const { onRequest } = props;
  const [option, setOption] = React.useState(options[0]);
  const [selectedPrompt, setSelectedPrompt] = React.useState(null);
  const [customText, setCustomText] = React.useState("");
  useEvent(
    "show-ai-assistant",
    (data) => {
      setCustomText("");
      setSelectedPrompt(null);
      setOption(options[0]);
    },
    []
  );

  return (
    <List className={classes.promptsSectionWrapper}>
      <MButtonGroup
        onMenuItemClick={(key) => setOption(options.find((opt) => opt.key === key))}
        onClick={() => {}}
        wholePress
        hasDivider={false}
        customClass={classes.promptButton}
        options={options}
        text={option?.label}
      />
      <div className={classes.promptsWrapper}>
        {options.slice(1, options.length).map(
          (op) =>
            (option.key === "all_prompts" || option.key === op.key) && (
              <div key={`prompts_title_${op.key}`} className={classes.promptWrapper}>
                {option.key === "all_prompts" && (
                  <Typography
                    className={ClassNames(classes.promptTitle, op.key === "write_prompt" && classes.customPromptTitle)}
                  >
                    {op.label}
                  </Typography>
                )}
                {op.key !== "write_prompt" ? (
                  <Grid container spacing={1} className={classes.gridItem}>
                    {op.prompts.map((pr) => (
                      <Grid item key={`prompt_${pr.key}`}>
                        <MButton
                          customClassNames={ClassNames(
                            classes.prompt,
                            pr.key === selectedPrompt?.key && classes.selectedPrompt
                          )}
                          onClick={() => {
                            setSelectedPrompt(pr);
                            setOption(op);
                            onRequest(pr.prompt || pr.label);
                          }}
                        >
                          {pr.label}
                        </MButton>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <div className={classes.customWrapper}>
                    <TextFieldMaxLength
                      value={customText}
                      placeholder={I18n.t("ai_assistant.custom_text_ph")}
                      borderRadius={3}
                      height={40}
                      onEnterKey={(v) => {
                        if (v) {
                          setSelectedPrompt(null);
                          onRequest(v);
                          setOption(options[options.length - 1]);
                          setCustomText("");
                        }
                      }}
                      inputClassName={classes.textInput}
                      multiLine
                      handleValueChange={(text) => setCustomText(text)}
                    />
                    <SendIcon
                      className={ClassNames(classes.sendIcon, customText === "" && classes.disabledSendIcon)}
                      onClick={() => {
                        if (customText) {
                          setSelectedPrompt(null);
                          onRequest(customText);
                          setOption(options[options.length - 1]);
                          setCustomText("");
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )
        )}
      </div>
    </List>
  );
};

export default PageEditorAssistant;
