import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  icon: { color: `${theme.palette.text.primary} !important` },
}));

type PropTypes = {
  columnWidth?: number | string;
  fontSize?: number | string;
  className?: string;

  children: string | React.ReactNode;
};

const MTableHeaderLabel = (props: PropTypes) => {
  const { children, className, fontSize = 14, columnWidth } = props;
  const classes = useStyles(props);

  return (
    <Typography
      className={ClassNames(classes.wrapper, className)}
      variant={"subtitle2"}
      style={{
        fontSize,
        flexGrow: columnWidth ? undefined : 1,
        flexBasis: columnWidth ? undefined : 0,
        width: columnWidth,
      }}
    >
      <span> {children}</span>
    </Typography>
  );
};

export default MTableHeaderLabel;
