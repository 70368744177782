import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import numeral from "numeral";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { isNumber } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: theme.typography.pxToRem(14),
    paddingRight: (props: PropTypes) => props.paddingRight || 10,
    flexGrow: (props: PropTypes) => (props?.columnWidth ? null : 1),
    flexBasis: (props: PropTypes) => (props?.columnWidth ? null : 0),
    width: (props: PropTypes) => props?.columnWidth || "auto",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  tooltipWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    padding: "10px 0px",
  },
  tooltipItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "7px 10px",
    flexDirection: "row",
    gap: 10,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    padding: 0,
    margin: 0,
    marginLeft: "-10px",
    marginTop: 3,
    minWidth: 202,
  },
  itemLabel: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  itemValue: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight as any,
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
}));

type PropTypes = {
  value: number;
  type?: "currency" | "number" | "percentage" | "bigNumber" | "decimal";
  tooltipTexts: { name: string; value: any; format?: string }[];
  placeHolder?: string;
  columnWidth?: string;
  paddingRight?: number | string;
};

const formats = {
  currency: "$0,0[.]0 a",
  number: "0,0",
  percentage: "0,0[.]0%",
  bigNumber: "0,0[.]0a",
  decimal: "0.0a",
};

const FunnelTableCell = (props: PropTypes) => {
  const classes = useStyles(props);
  const { value, type = "bigNumber", tooltipTexts } = props;

  return (
    <div className={classes.column}>
      <Tooltip
        enterDelay={500}
        placement={"bottom-start"}
        classes={{ tooltip: classes.tooltip }}
        interactive
        title={
          <div className={classes.tooltipWrapper}>
            {tooltipTexts.map((item, index) => (
              <div className={classes.tooltipItem} key={`${item.name}_${index + 1}`}>
                <Typography color={"textPrimary"} className={classes.itemLabel}>
                  {item.name}
                </Typography>
                <Typography className={classes.itemValue}>
                  {isNumber(item.value)
                    ? numeral(item.value || 0).format(formats[item.format || "number"])
                    : item.value || "-"}
                </Typography>
              </div>
            ))}
          </div>
        }
      >
        <span>{!value ? "-" : numeral(value || 0).format(formats[type])}</span>
      </Tooltip>
    </div>
  );
};

export default FunnelTableCell;
