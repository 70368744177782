import React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";

import {
  IWebsite,
  IWidgetFieldProperties,
  IWidgetRelatedArticlesFieldProps,
} from "../../../../../../../../reducers/constants/objectTypes";
import PageSelector from "./components/PageSelector";
import MNumeratedItem from "../../../../../../../../components/MNumeratedItem";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  itemWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
}));

type PropTypes = {
  properties?: IWidgetFieldProperties;
  selectedWebsite: IWebsite;
  onChange: (properties: IWidgetRelatedArticlesFieldProps) => void;
};

const RelatedArticlesWidgetItem = (props: PropTypes) => {
  const classes = useStyles();
  const propsProperties = props.properties as IWidgetRelatedArticlesFieldProps;
  const properties = {
    ...propsProperties,
    list: propsProperties?.list || [],
  } as IWidgetRelatedArticlesFieldProps;

  const { onChange } = props;
  const { list } = properties;

  const handleChange = ({ item, index }: { item: IWidgetRelatedArticlesFieldProps["list"][number]; index: number }) => {
    let updatedValues: IWidgetRelatedArticlesFieldProps["list"];
    if (!item.page) {
      updatedValues = list.filter((_, i) => i !== index);
    } else {
      updatedValues = [...list];
      updatedValues[index] = item;
    }

    onChange({ ...properties, list: updatedValues });
  };

  const removeItem = (index: number) => {
    const updatedValues = list.filter((_, i) => i !== index);
    onChange({ ...properties, list: updatedValues });
  };

  return (
    <div className={classes.root}>
      {[
        ...list,
        {
          title: "",
          page: null,
        },
      ].map((item, index, array) => (
        <div key={index}>
          <MNumeratedItem index={index} showRemoveIcon={index !== array.length - 1} onRemove={() => removeItem(index)}>
            <div className={classes.itemWrapper}>
              <PageSelector
                selectedWebsite={props.selectedWebsite}
                value={item.page}
                onChange={(page) => handleChange({ item: { ...item, page }, index })}
              />
            </div>
          </MNumeratedItem>
        </div>
      ))}
    </div>
  );
};

export default RelatedArticlesWidgetItem;
