import React, { useState } from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { OutlinedInput, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditorLinkIcon from "../../../icons/EditorLinkIcon";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  valueWrap: {
    display: "flex",
    alignItems: "center",
  },
  backToDefault: {
    color: theme.palette.text.hint,
    fontSize: 16,
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  inputWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "none",
    // width: 40,
    width: 30,
  },
  prependedIconWrapper: {
    // width: 37,
    width: 30,
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `${theme.palette.divider} 1px solid`,
    borderRadius: "3px 0 0 3px",
    borderRight: "none",
  },
  syncIconWrapper: {
    // width: 34,
    width: 30,
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `${theme.palette.divider} 1px solid`,
    borderRadius: "0 3px 3px 0",
    cursor: "pointer",
    borderLeft: "none",
  },
  syncIconWrapperSelected: {
    backgroundColor: "#609EFF",
    borderColor: "#609EFF",
    stroke: "white",
  },
  input: {
    height: 35,
    maxWidth: 37,
    textAlign: "center",
    fontSize: theme.typography.pxToRem(14),
    padding: "0 5px",
  },
  root: {
    borderRadius: 0,
    "&:first-child": {
      borderColor: theme.palette.divider,
      borderWidth: 1,
    },
    "&:last-child": {
      borderColor: theme.palette.divider,
      borderWidth: 1,
    },
  },
  notchedOutlineLast: {
    borderColor: `${theme.palette.divider} !important`,
    borderWidth: `1px !important`,
  },
  notchedOutline: {
    borderColor: `${theme.palette.divider} !important`,
    borderWidth: `1px !important`,
    borderRight: "none",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  inputTitle: {
    fontSize: theme.typography.pxToRem(9),
    lineHeight: 1,
    textTransform: "uppercase",
    marginTop: 5,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  rowTitle: string;
  value: string | number;
  defaultValue?: string;
  icons: JSX.Element[];

  handleChange: any;
};

const DimensionsBaseComponent = (props: PropTypes) => {
  const classes = useStyles(props);
  const { rowTitle, value = "", defaultValue = "", icons, handleChange } = props;

  const handleBackToDefault = () => {
    handleChange(defaultValue);
  };

  const [inputSelected, setInputSelected] = useState(0);
  const [areInputsInSync, setAreInputsInSync] = useState(false);

  const getValues = (valuesList: string[]) => {
    let values = [];
    if (valuesList.length == 1) {
      values[0] = valuesList[0] || "0";
      values[1] = valuesList[0] || "0";
      values[2] = valuesList[0] || "0";
      values[3] = valuesList[0] || "0";
    } else if (valuesList.length == 2) {
      values[0] = valuesList[0] || "0";
      values[1] = valuesList[1] || "0";
      values[2] = valuesList[0] || "0";
      values[3] = valuesList[1] || "0";
    } else if (valuesList.length == 3) {
      values[0] = valuesList[0] || "0";
      values[1] = valuesList[1] || "0";
      values[2] = valuesList[2] || "0";
      values[3] = valuesList[1] || "0";
    } else {
      values[0] = valuesList[0] || "0";
      values[1] = valuesList[1] || "0";
      values[2] = valuesList[2] || "0";
      values[3] = valuesList[3] || "0";
    }
    return values;
  };

  let values = getValues(value.toString().replaceAll("px", "").split(" "));

  const handleInputChanged = (value, input) => {
    if (areInputsInSync) {
      return handleChange(`${value}px ${value}px ${value}px ${value}px`);
    }
    handleChange(
      `${input === 0 ? value || "0" : values[0]}px ${input === 1 ? value || "0" : values[1]}px ${
        input === 2 ? value || "0" : values[2]
      }px ${input === 3 ? value || "0" : values[3]}px`
    );
  };

  const handleKeyPressed = (event, inputNumber) => {
    const stepNumber = event.shiftKey ? 10 : 1;
    if (event.code === "ArrowUp") {
      handleInputChanged(parseInt(event.target.value) + stepNumber, inputNumber);
    } else if (event.code === "ArrowDown") {
      handleInputChanged(parseInt(event.target.value) - stepNumber, inputNumber);
    }
  };

  return (
    <div className={classes.row}>
      <Typography variant={"body2"} className={ClassNames(classes.text, value != defaultValue && classes.boldText)}>
        {rowTitle}
      </Typography>
      <div className={classes.valueWrap}>
        {value != defaultValue && (
          <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
            <CloseIcon className={classes.backToDefault} onClick={handleBackToDefault} />
          </Tooltip>
        )}
        {!!icons.length && (
          <div className={classes.prependedIconWrapper}>{icons[areInputsInSync ? 0 : inputSelected]}</div>
        )}
        <div className={classes.inputWrap}>
          <OutlinedInput
            onFocus={() => setInputSelected(1)}
            onBlur={() => setInputSelected(0)}
            onKeyDown={(event) => handleKeyPressed(event, 0)}
            value={values?.[0] || ""}
            placeholder={""}
            classes={{
              root: classes.root,
              input: classes.input,
              notchedOutline: classes.notchedOutline,
            }}
            onChange={(event) => {
              handleInputChanged(event.target.value, 0);
            }}
          />
        </div>
        <div className={classes.inputWrap}>
          <OutlinedInput
            onFocus={() => setInputSelected(2)}
            onBlur={() => setInputSelected(0)}
            onKeyDown={(event) => handleKeyPressed(event, 1)}
            value={values?.[1] || ""}
            placeholder={""}
            classes={{
              root: classes.root,
              input: classes.input,
              notchedOutline: classes.notchedOutline,
            }}
            onChange={(event) => {
              handleInputChanged(event.target.value, 1);
            }}
          />
        </div>
        <div className={classes.inputWrap}>
          <OutlinedInput
            onFocus={() => setInputSelected(3)}
            onBlur={() => setInputSelected(0)}
            onKeyDown={(event) => handleKeyPressed(event, 2)}
            value={values?.[2] || ""}
            placeholder={""}
            classes={{
              root: classes.root,
              input: classes.input,
              notchedOutline: classes.notchedOutline,
            }}
            onChange={(event) => {
              handleInputChanged(event.target.value, 2);
            }}
          />
        </div>
        <div className={classes.inputWrap}>
          <OutlinedInput
            onFocus={() => setInputSelected(4)}
            onBlur={() => setInputSelected(0)}
            onKeyDown={(event) => handleKeyPressed(event, 3)}
            value={values?.[3] || ""}
            classes={{
              root: classes.root,
              input: classes.input,
              notchedOutline: classes.notchedOutlineLast,
            }}
            onChange={(event) => {
              handleInputChanged(event.target.value, 3);
            }}
          />
        </div>
        <div
          className={ClassNames(classes.syncIconWrapper, areInputsInSync ? classes.syncIconWrapperSelected : "")}
          onClick={() => setAreInputsInSync(!areInputsInSync)}
        >
          <EditorLinkIcon className={areInputsInSync ? classes.syncIconWrapperSelected : ""} />
        </div>
      </div>
    </div>
  );
};
export default DimensionsBaseComponent;
