import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import IntegrationTargetingItem from "../components/IntegrationTargetingItem";
import ZapierImg from "../../../../../../img/zapier-image.jpg";
import ZapierIntegration from "../components/ZapierIntegration";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({
      padding: "30px",
    }),
    container: (props: PropTypes) => {
      const backgroundColor = isDark ? palette.secondary.main : palette.common.white;
      return {
        padding: "15px 15px 15px",
        display: "flex",
        flexDirection: "column",
        backgroundColor,
        borderRadius: 5,
        border: "1px solid rgb(82, 82, 82)",
      };
    },
    header: (props: PropTypes) => ({
      height: 55,
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      marginBottom: 15,
    }),
    properties: {},
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
    leftContainer: (props: PropTypes) => {
      const color = palette.text.primary;
      return {
        display: "flex",
        alignItems: "center",
        maxWidth: 300,
        color,
        marginLeft: -8,
      };
    },
    rightContainer: (props: PropTypes) => ({
      display: "flex",
      alignItems: "center",
      maxWidth: 300,
    }),
    button: (props: PropTypes) => ({
      backgroundColor: "#4353FF",
      color: palette.common.white,
      fontSize: 14,
      textTransform: "none",
      height: 27,
      padding: "5px  10px  5px  10px",
      borderRadius: "3px",
      "&:hover": {
        color: palette.text.primary,
        backgroundColor: palette.action.selected,
      },
    }),
    label: {
      fontSize: 16,
      fontWeight: typography.fontWeightMedium,
    },
  };
});

type PropTypes = {};

const TargetingItem = (title) => {
  switch (title) {
    case "Zapier":
      return <ZapierIntegration />;
      break;
    default:
      throw new Error(`Error: Unhandled title: ${title}`);
  }
};

const WidgetTargetingTab = (props: PropTypes) => {
  const classes = useStyles(props);
  const [selectedItem, setSelectedItem] = React.useState<string>(null);

  const items = [
    {
      title: "Zapier",
      description: "Send data to 5,000+ services via Zapier.",
      img: ZapierImg,
    },
  ];

  if (!selectedItem) {
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          {items.map((item) => (
            <IntegrationTargetingItem
              item={item}
              onClick={(i) => {
                setSelectedItem(i.title);
              }}
            />
          ))}
        </div>
      </div>
    );
  }
  if (selectedItem) {
    return (
      <div className={classes.root}>
        <div className={classes.container}>{TargetingItem(selectedItem)}</div>
      </div>
    );
  }
};

export default WidgetTargetingTab;
