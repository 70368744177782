import React from "react";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    gap: 5,
    alignItems: "flex-start",
    width: "100%",
  },
  title: ({ titleMinWidth }: { titleMinWidth?: number }) => ({
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "normal",
    minWidth: titleMinWidth || 150,
    marginTop: 16,
  }),
  disabled: {
    opacity: 0.5,
  },
}));

type PropTypes = {
  title: string;
  titleMinWidth?: number;
  disabled?: boolean;
  children: React.ReactNode;
};

const ModalInputField = (props: PropTypes) => {
  const { title, titleMinWidth, disabled, children } = props;
  const classes = useStyles({ titleMinWidth });

  return (
    <div className={classes.wrapper}>
      <Typography className={classNames(classes.title, disabled && classes.disabled)}>{title}</Typography>
      {children}
    </div>
  );
};

export default ModalInputField;
