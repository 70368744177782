import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import PageSection from "../../components/PageSection";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import { IWebsiteCustomCodeProps } from "../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import TextFieldJSON from "../../../../components/TextFieldJSON";
import MRouterPrompt from "../../components/MRouterPrompt";

import { AbilityContext } from "../../../../casl/can";

const mapStateToProps = (state) => ({
  customCode: state.websitePages.customCode,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (customCode) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "customCode",
      value: customCode,
    }),
});

type PropTypes = {
  customCode: IWebsiteCustomCodeProps;
  onPropertyChanged: (customCode) => void;
};

const PageCustomCode = (props: PropTypes) => {
  const { customCode, onPropertyChanged } = props;
  const ability = React.useContext<any>(AbilityContext);

  return (
    <>
      <PageSection sectionTitle="Custom Styles">
        <MRouterPrompt />
        <TextFieldMaxLength
          titleAndLengthLocation={"top"}
          title={I18n.t("website.custom_code.head")}
          value={customCode?.head || ""}
          placeholder={I18n.t("website.custom_code.head_ph")}
          height={200}
          multiLine
          borderRadius={4}
          handleValueChange={(propValue) => {
            onPropertyChanged({ ...customCode, head: propValue });
          }}
          marginBottom={30}
        />
        <TextFieldMaxLength
          titleAndLengthLocation={"top"}
          title={I18n.t("website.custom_code.body")}
          value={customCode?.body || ""}
          placeholder={I18n.t("website.custom_code.body_ph")}
          height={200}
          multiLine
          borderRadius={4}
          handleValueChange={(propValue) => {
            onPropertyChanged({ ...customCode, body: propValue });
          }}
          marginBottom={30}
        />
        <TextFieldJSON
          title={I18n.t("website.custom_code.global")}
          value={customCode?.globalCSS}
          handleValueChange={(json) => onPropertyChanged({ ...customCode, globalCSS: json })}
          width={"100%"}
          height={"200px"}
          marginBottom={30}
        />
        <TextFieldJSON
          title={I18n.t("website.custom_code.mui")}
          value={customCode?.muiOverrides}
          handleValueChange={(json) => onPropertyChanged({ ...customCode, muiOverrides: json })}
          width={"100%"}
          height={"200px"}
          marginBottom={30}
        />

        {/*{ability.can('owner', 'role') && (*/}
        {/*	<PageLinksHook*/}
        {/*		title={I18n.t('website.custom_code.page_href_hook')}*/}
        {/*		code={customCode.hooks.inPageLinks}*/}
        {/*		onPropertyChanged={(c) =>*/}
        {/*			onPropertyChanged({ ...customCode, hooks: { ...customCode.hooks, inPageLinks: c } })*/}
        {/*		}*/}
        {/*	/>*/}
        {/*)}*/}
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCustomCode);
