import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { replace } from "connected-react-router";
import { I18n } from "react-redux-i18n";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { useParams, useLocation } from "react-router-dom";
import agent from "../../agent";
import { EDIT_TOPIC } from "../../reducers/constants/actionTypes";
import { ePostState, ePostType, IAdminUser, ILearnTopic, ITopic } from "../../reducers/constants/objectTypes";
import EditTopicEditor from "./components/editor/EditTopicEditor";
import EditTopicResources from "./components/resources/EditTopicResources";
import { web } from "../../helpers/urlHelper";
import { AbilityContext } from "../../casl/can";

const useStyles = makeStyles((theme: Theme) => ({
  answersWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 50,
    paddingRight: 40,
    paddingLeft: 40,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  answersInnerWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 50px)",
  },
  editor: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
    borderRight: `solid 1px ${theme.palette.divider}`,
  },
  resources: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
  },
  tabsWrapper: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
}));

type PropTypes = {
  admin: IAdminUser;

  returnTo?: string;
  topicLoaded: boolean;
  topic: ILearnTopic | ITopic;

  handleTopicLoaded: Function;
  returnToPrevious: Function;
  cleanUp: Function;
};

const mapStateToProps = (state) => ({
  admin: state.home.admin,

  topicLoaded: state.editTopic.topicLoaded,
  topic: state.editTopic.topic,
});

const mapDispatchToProps = (dispatch) => ({
  handleTopicLoaded: (topic, topicType) => dispatch({ type: EDIT_TOPIC.TOPIC_LOADED, topic, topicType }),
  cleanUp: () => dispatch({ type: EDIT_TOPIC.CLEANUP }),
  returnToPrevious: (returnTo) => dispatch(replace(returnTo)),
});

const EditTopic = (props: PropTypes) => {
  const classes = useStyles(props);
  const { topicLoaded, topic, admin, returnTo } = props;
  const { handleTopicLoaded, returnToPrevious, cleanUp } = props;
  const { type, topicId } = useParams();
  const [blockingDialogOpen, setBlockingDialogOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const ability = React.useContext<any>(AbilityContext);

  const exitScreen = () => {
    console.log("returnTo", returnTo);
    if (returnTo && returnTo !== location.resources) {
      console.log("returnTo --->", returnTo);
      returnToPrevious(returnTo);
      return;
    }
    returnToPrevious(web.writerWrite());
  };

  React.useEffect(() => {
    console.log("download topic", type, topicId);
    agent.Tags.getTag(topicId)
      .then((res) => {
        handleTopicLoaded(res.tag, type);
      })
      .catch((e) => {
        enqueueSnackbar(e.response.body.message);
        exitScreen();
      });
    return () => {
      cleanUp();
    };
  }, [handleTopicLoaded, type, topicId]);

  React.useEffect(() => {
    if (!topic || !admin) {
      return;
    }
    if (ability.can("content_manager", "role")) {
      return;
    }
    console.log("topic.state", topic.state);
    console.log("topic.state", topic.state);
    if (topic.state === ePostState.INTERNAL_REVIEW && topic.editor && topic.editor._id !== admin._id) {
      setBlockingDialogOpen(true);
    }
    if (
      (topic.state === ePostState.NEW || topic.state === ePostState.DRAFT) &&
      topic.writer &&
      topic.writer._id !== admin._id
    ) {
      setBlockingDialogOpen(true);
    }
  }, [topic, admin, ability]);

  return (
    <div className={classes.answersWrapper}>
      <div className={classes.toolbar}>
        <div className={classes.toolbarDetails}>
          <ArrowBackIcon className={classes.backIcon} onClick={() => exitScreen()} />
          <Typography color={"textPrimary"} variant={"subtitle2"}>
            {!topicLoaded ? "" : `${topic.parent ? `${topic.parent.name}/` : ""}${topic.name}`}
          </Typography>
        </div>
        {topicLoaded && (
          <Typography color={"textPrimary"} variant={"subtitle2"}>
            {I18n.t(`learning_center_content.status.${topic.state?.toLowerCase() || "Unknown"}`)}
          </Typography>
        )}
      </div>
      <div className={classes.answersInnerWrapper}>
        <EditTopicEditor returnToPreviousScreen={exitScreen} />
        <EditTopicResources />
      </div>
      {/*{blockingDialogOpen && (*/}
      {/*  <EditPostBlockingDialog*/}
      {/*    open*/}
      {/*    handleAgree={() => exitScreen()}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTopic);
