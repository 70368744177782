import numeral from "numeral";
import agent from "../agent";
import { IVideoProject, IWebsite } from "../reducers/constants/objectTypes";
// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
export default class VideoProjectFileUploaderHelper {
  website: IWebsite;

  videoProject: IVideoProject;

  chunkSize: any;

  threadsQuantity: number;

  file: any;

  fileName: any;

  fileExt: any;

  aborted: boolean;

  uploadedSize: number;

  progressCache: any;

  activeConnections: any;

  parts: any[];

  uploadedParts: any[];

  fileId: null;

  fileKey: null;

  duration: null;

  onProgressFn: (err: any) => void;

  onSuccessFn: (item: any) => void;

  onErrorFn: (err: any) => void;

  constructor(website, videoProject, options: any) {
    console.log("options", options);
    this.website = website;
    this.videoProject = videoProject;
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || 1024 * 1024 * 10;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.duration = options.duration;
    this.file = options.file;
    this.fileExt = options.fileName.split(`.`).pop();
    this.fileName = `${options.fileName.split(`.${this.fileExt}`)[0].replace(/\s/g, "_").replace(/\W/g, "")}.${
      this.fileExt
    }`;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.onProgressFn = () => {};
    this.onSuccessFn = () => {};
    this.onErrorFn = () => {};
  }

  // starting the multipart upload request
  start() {
    this.initialize();
  }

  async initialize() {
    try {
      // adding the file extension (if present) to fileName
      const { fileName } = this;

      // initializing the multipart request
      const videoInitializationUploadInput = {
        name: fileName,
      };

      const AWSFileDataOutput = await agent.VideoProjectFileUploader.createMultipart(
        this.videoProject,
        this.website,
        videoInitializationUploadInput
      );
      // const initializeReponse = await api.request({
      //   url: "/create-multipart",
      //   method: "POST",
      //   data: videoInitializationUploadInput,
      // });

      this.fileId = AWSFileDataOutput.UploadId;
      this.fileKey = AWSFileDataOutput.Key;

      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize);
      console.log("this.file.size", numeral(this.file.size).format("0.00b"));
      console.log("this.chunkSize", numeral(this.chunkSize).format("0.00b"));
      console.log("numberOfparts", numberOfparts);

      const AWSMultipartFileDataInput = {
        UploadId: this.fileId,
        name: this.fileKey,
        parts: numberOfparts,
      };

      const urlsResponse = await agent.VideoProjectFileUploader.getPresignedUrl(
        this.website,
        AWSMultipartFileDataInput
      );
      // const urlsResponse = await api.request({
      //   url: "/get-presigned-url",
      //   method: "POST",
      //   data: AWSMultipartFileDataInput,
      // });

      // const newParts: any[] = [];
      // for (let i = 0; i < numberOfparts; i += 1) {
      //   newParts.push({
      //     signedUrl: urlsResponse.data[i].signedUrl,
      //     PartNumber: urlsResponse.data[i].PartNumber,
      //   });
      // }
      this.parts.push(...urlsResponse.data);

      this.sendNext();
    } catch (error) {
      console.log("initialize error", error);
      await this.complete(error);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;
    console.log("sendNext this.parts", this.parts);

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        console.log("sendNext complate");
        this.complete();
      }

      return;
    }

    const part: any = this.parts.pop();

    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      console.log("sentSize", numeral(sentSize).format("0.00b"));
      console.log("this.chunkSize", numeral(this.chunkSize).format("0.00b"));
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          console.log("this.sendChunk part", part);
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);
          console.log("sendChunk error", error);
          this.complete(error);
        });
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error?: any) {
    console.log("complete", error);
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      console.log("complete", "sendCompleteRequest");
      await this.sendCompleteRequest();
    } catch (error: any) {
      console.log("complete error", error);
      this.onErrorFn(error);
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        UploadId: this.fileId,
        name: this.fileKey,
        parts: this.uploadedParts,
        size: this.file.size,
        fileName: this.file.name,
        duration: this.duration,
      };

      const { sourceFile } = await agent.VideoProjectFileUploader.completeMultipart(
        this.videoProject,
        this.website,
        videoFinalizationMultiPartInput
      );

      this.onSuccessFn({
        sourceFile,
      });
      // await api.request({
      //   url: "/complete-multipart",
      //   method: "POST",
      //   data: videoFinalizationMultiPartInput,
      // });
    }
  }

  sendChunk(chunk: any, part: any, sendChunkStarted: any) {
    console.log("sendChunk", part, sendChunkStarted);
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          console.log("status", status);

          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }

          resolve(1);
        })
        .catch((error) => {
          console.log("sendChunk error", error);
          reject(error);
        });
    });
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: any, event: any) {
    if (this.file) {
      if (event.type === "progress" || event.type === "error" || event.type === "abort") {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent,
        total,
        percentage,
      });
    }
  }

  // uploading a part through its pre-signed URL
  upload(file: any, part: any, sendChunkStarted: any) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(this, part.PartNumber - 1);

        xhr.upload.addEventListener("progress", progressListener);

        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            console.log("onreadystatechange", xhr);
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader("ETag");

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ""),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          console.log("onerror", error);
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.onabort = () => {
          console.log("onabort");
          reject(new Error("Upload canceled by user"));
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  onProgress(onProgress: any) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError: any) {
    this.onErrorFn = onError;
    return this;
  }

  onSuccess(onSuccess: any) {
    this.onSuccessFn = onSuccess;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
