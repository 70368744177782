import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { WRITER_BIO } from "../../../../../../reducers/constants/actionTypes";
import TextFieldMaxLength from "../../../../../../components/TextFieldMaxLength";

const useStyles = makeStyles((theme: Theme) => ({
  sectionWrapper: {
    borderRadius: 0,
    border: "none",
    marginTop: 10,
  },
  innerWrapper: {
    padding: 30,
  },
  sectionNameAndSwitchWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "13px 20px",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    textTransform: "capitalize",
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    "&:first-child": {
      marginTop: 0,
    },
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  titleAndHintWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    minWidth: 140,
    marginRight: 15,
  },
  hint: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: 5,
  },
}));

type PropTypes = {
  preview: {
    active: false;
    text: null;
  };

  onPropertyChanged: (value) => void;
};

const mapStateToProps = (state) => ({
  preview: state.writerBio.preview,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (preview) => dispatch({ type: WRITER_BIO.ON_PROPERTY_CHANGED, name: "preview", value: preview }),
});

const BioPreviewSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { preview } = props;
  const { onPropertyChanged } = props;

  return (
    <Paper elevation={0} className={classes.sectionWrapper}>
      <div className={classes.sectionNameAndSwitchWrapper}>
        <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
          {I18n.t("manager.writers_bio.bio_preview_tooltip")}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={preview.active}
                onChange={() => {
                  onPropertyChanged({ ...preview, active: !preview.active });
                }}
                color="primary"
              />
            }
            labelPlacement="start"
            label={I18n.t(`manager.writers_bio.${preview.active ? "active" : "inactive"}`)}
          />
        </FormGroup>
      </div>
      <div className={classes.innerWrapper}>
        <TextFieldMaxLength
          titleAndLengthLocation={"top"}
          value={preview.text}
          placeholder={I18n.t("manager.writers_bio.lorem_ph")}
          title={I18n.t("manager.writers_bio.bio_preview")}
          maxLength={300}
          height={71}
          multiLine
          borderRadius={4}
          marginTop={10}
          handleValueChange={(text) => {
            onPropertyChanged({ ...preview, active: true, text });
          }}
        />
      </div>
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BioPreviewSection);
