import React from "react";
import { I18n } from "react-redux-i18n";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { IProduct } from "../../../../reducers/constants/objectTypes";
import ModalInputField from "../../components/ModalInputField";
import MTextFieldV2 from "../../../../components/MTextFieldV2";

type PropTypes = {
  product: IProduct;
  onChange: (product: IProduct) => void;
};

const ReviewsView = (props: PropTypes) => {
  const { product, onChange } = props;

  return (
    <>
      <ModalInputField title={I18n.t("product_dialog.reviews_view.number_of_reviews")}>
        <MTextFieldV2
          value={product.numOfReviews}
          placeholder={I18n.t("product_dialog.reviews_view.number_of_reviews_ph")}
          noDecimals
          onNumberChange={(numOfReviews) => onChange({ ...product, numOfReviews })}
          type="number"
        />
      </ModalInputField>
      <ModalInputField title={I18n.t("product_dialog.reviews_view.score")}>
        <MTextFieldV2
          value={product.score}
          placeholder={I18n.t("product_dialog.reviews_view.score_ph")}
          onValueChange={(score) => onChange({ ...product, score })}
          type="number"
          min={1}
          max={10}
          step={0.1}
        />
      </ModalInputField>
      <ModalInputField title={I18n.t("product_dialog.reviews_view.rating")}>
        <MTextFieldV2
          value={product.rating}
          placeholder={I18n.t("product_dialog.reviews_view.rating_ph")}
          onValueChange={(rating) => onChange({ ...product, rating })}
          type="number"
          min={1}
          max={5}
          step={0.1}
        />
      </ModalInputField>
    </>
  );
};

export default ReviewsView;
