import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles(() => ({
  icon: {
    height: 20,
    width: 15,
    color: "#fff",
  },
}));

type PropTypes = {
  className?: string;
  onClick?: () => void;
};

const CutIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className, onClick } = props;

  return (
    <SvgIcon onClick={onClick} className={ClassNames(classes.icon, className)} viewBox={"0 0 15 20"}>
      <path d="M3.81395 0.562012H0.47661V2.22207H3.81395V17.902H0.31427V19.562H3.81395C4.26217 19.562 4.64586 19.3995 4.96504 19.0744C5.28423 18.7493 5.44382 18.3585 5.44382 17.902V2.22207C5.44382 1.76555 5.28423 1.37475 4.96504 1.04965C4.64586 0.724559 4.26217 0.562012 3.81395 0.562012Z" />
      <path d="M11.4717 0.562012H14.8091V2.22207H11.4717V17.902H14.9714V19.562H11.4717C11.0235 19.562 10.6398 19.3995 10.3206 19.0744C10.0015 18.7493 9.84187 18.3585 9.84187 17.902V2.22207C9.84187 1.76555 10.0015 1.37475 10.3206 1.04965C10.6398 0.724559 11.0235 0.562012 11.4717 0.562012Z" />
    </SvgIcon>
  );
};

export default CutIcon;
