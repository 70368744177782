import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import List from "@material-ui/core/List";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { ListSubheader, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import Classnames from "classnames";
import agent from "../../../../../agent";
import { IExpert, IWebsite } from "../../../../../reducers/constants/objectTypes";
import ExpertsListItem from "./ExpertsListItem";

const useStyles = makeStyles(({ palette, typography }: Theme) => ({
  pageWrapper: {
    padding: "0px 10px 0px 10px",
  },
  root: {
    paddingTop: 0,
  },
  subHeader: {
    top: 62,
    position: "sticky",
    display: "flex",
    backgroundColor: "white",
    padding: "30px 20px",
    zIndex: 2,
  },
  tableTitleHead: {
    color: "black",
    fontSize: "20px",
    fontWeight: typography.fontWeightBold,
    width: "40%",
    paddingRight: 10,
  },
  tableHead: {
    color: "black",
    fontSize: "16px",
    fontWeight: typography.fontWeightBold,
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "8%",
    alignSelf: "center",
    paddingRight: 10,
  },
  tableHeadEmail: {
    width: "16%",
  },
  tableHeadWebsites: {
    width: "20%",
  },
  tableMoreOptionsHead: {
    width: 40,
  },
  iconColumn: {
    width: 40,
  },
}));

type PropTypes = {
  experts: IExpert[];
  website: IWebsite;
  totalExpertsCount: number;

  openExpertPage(expertId: string): void;

  handleExpertChanged(): void;
};

const ExpertsList = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website, experts, totalExpertsCount, openExpertPage, handleExpertChanged } = props;
  const { enqueueSnackbar } = useSnackbar();
  const handleExpertDeleteClick = (expert: IExpert) => {
    agent.Experts.delete(website, expert)
      .then((res) => {
        if (res.error) return;
        enqueueSnackbar(res.message ?? I18n.t("experts.list.expert_deleted_successfully"));
        handleExpertChanged();
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  return (
    <div className={classes.pageWrapper}>
      <List aria-labelledby="nested-list-subheader" className={classes.root}>
        <ListSubheader className={classes.subHeader}>
          <Typography className={classes.tableTitleHead} variant={"subtitle2"}>
            {I18n.t("experts.list.experts", { count: totalExpertsCount })}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("experts.list.active")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("experts.list.created")}
          </Typography>
          <Typography className={Classnames(classes.tableHead, classes.tableHeadEmail)} variant={"subtitle2"}>
            {I18n.t("experts.list.email")}
          </Typography>
          <Typography className={Classnames(classes.tableHead, classes.tableHeadWebsites)} variant={"subtitle2"}>
            {I18n.t("experts.list.websites")}
          </Typography>
          <Typography className={classes.tableHead} variant={"subtitle2"}>
            {I18n.t("experts.list.locales")}
          </Typography>
          <div className={classes.iconColumn} />
        </ListSubheader>

        {experts.map((item: IExpert) => (
          <ExpertsListItem
            key={item._id}
            expert={item}
            selectedWebsite={website}
            handleClick={(expert) => openExpertPage(expert._id)}
            handleExpertChange={(expert) => handleExpertChanged()}
            deleteExpert={() => {
              handleExpertDeleteClick(item);
            }}
          />
        ))}
      </List>
    </div>
  );
};

export default ExpertsList;
