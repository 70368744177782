import { IKeyword, IKeywordGroupWithMetrics, IOpportunity } from "../../../../../reducers/constants/objectTypes";
import { DialogTitle, IconButton, Typography } from "@material-ui/core";
import XIcon from "../../../../../icons/XIcon";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
  },
  headerIcon: {
    width: 24,
    height: 24,
  },
  dialogTitleWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  opportunityTitle: {
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightBold as any,
    padding: 0,
  },
  dialogTitle: {
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  dialogTitleInnerWrapper: {
    padding: "10px 0",
  },
}));

type PropTypes = {
  keywordGroup: IKeywordGroupWithMetrics;
  onClick: () => void;
};

const KeywordGroupDialogHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { keywordGroup, onClick } = props;

  return (
    <div className={classes.dialogHeader}>
      <div className={classes.dialogTitleWrapper}>
        <Typography className={classes.dialogTitle}>Query Group:</Typography>
        <DialogTitle className={classes.dialogTitleInnerWrapper}>
          <Typography className={classes.opportunityTitle}>
            {keywordGroup?.clicks_main_keyword || "New Group"}
          </Typography>
        </DialogTitle>
      </div>

      <IconButton style={{ marginRight: -10 }} onClick={onClick}>
        <XIcon className={classes.headerIcon} />
      </IconButton>
    </div>
  );
};

export default KeywordGroupDialogHeader;
