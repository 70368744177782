import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 17,
    height: 17,
    fill: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  className?: string;
};

const SearchIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 14 14"}>
      <path d="M1.91557 13.1826C1.52929 13.1826 1.20232 13.0488 0.934675 12.7811C0.667027 12.5135 0.533203 12.1865 0.533203 11.8003V1.56498C0.533203 1.1787 0.667027 0.851737 0.934675 0.584089C1.20232 0.316441 1.52929 0.182617 1.91557 0.182617H6.16555C6.32829 0.182617 6.46456 0.237524 6.57436 0.347336C6.68416 0.457135 6.73906 0.593406 6.73906 0.756148C6.73906 0.918891 6.68416 1.05516 6.57436 1.16496C6.46456 1.27476 6.32829 1.32966 6.16555 1.32966H1.91557C1.85673 1.32966 1.8028 1.35417 1.75377 1.40319C1.70475 1.45222 1.68025 1.50615 1.68025 1.56498V11.8003C1.68025 11.8591 1.70475 11.913 1.75377 11.962C1.8028 12.0111 1.85673 12.0356 1.91557 12.0356H12.1508C12.2097 12.0356 12.2636 12.0111 12.3126 11.962C12.3616 11.913 12.3862 11.8591 12.3862 11.8003V7.55027C12.3862 7.38753 12.4411 7.25126 12.5509 7.14146C12.6607 7.03166 12.7969 6.97676 12.9597 6.97676C13.1224 6.97676 13.2587 7.03166 13.3685 7.14146C13.4783 7.25126 13.5332 7.38753 13.5332 7.55027V11.8003C13.5332 12.1865 13.3994 12.5135 13.1317 12.7811C12.8641 13.0488 12.5371 13.1826 12.1508 13.1826H1.91557ZM12.3862 2.13553L5.69202 8.82967C5.58613 8.93556 5.45305 8.98972 5.29277 8.99217C5.13247 8.99462 4.99693 8.94045 4.88615 8.82967C4.77537 8.71889 4.71998 8.58458 4.71998 8.42674C4.71998 8.2689 4.77537 8.13458 4.88615 8.0238L11.5803 1.32966H9.13613C8.97339 1.32966 8.83712 1.27476 8.72732 1.16496C8.61752 1.05516 8.56262 0.918891 8.56262 0.756148C8.56262 0.593406 8.61752 0.457135 8.72732 0.347336C8.83712 0.237524 8.97339 0.182617 9.13613 0.182617H13.5332V4.57969C13.5332 4.74243 13.4783 4.8787 13.3685 4.9885C13.2587 5.0983 13.1224 5.1532 12.9597 5.1532C12.7969 5.1532 12.6607 5.0983 12.5509 4.9885C12.4411 4.8787 12.3862 4.74243 12.3862 4.57969V2.13553Z" />
    </SvgIcon>
  );
};

export default SearchIcon;
