import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import TextareaAutosize from "react-textarea-autosize";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "inline-flex",
    gap: 10,
    lineHeight: 1.3,
    borderRadius: 5,
    padding: "20px 10px",
  },
  textInput: {
    fontFamily: theme.typography.fontFamily,
    resize: "none",
    border: "none",
    outline: "none",
    width: "100%",
    // height: "100%",
    margin: (props: PropTypes) => props.margin || 0,
    fontSize: (props: PropTypes) => props.fontSize || 16,
    padding: (props: PropTypes) => props.padding || 0,
    lineHeight: 1.3,
    backgroundColor: "initial",
  },
  numbers: {
    width: 20,
    borderRight: `1px solid ${theme.palette.divider}`,
    textAlign: "center",
    "& span": {
      counterIncrement: "linenumber",
      "&::before": {
        content: "counter(linenumber)",
        display: "block",
        color: "#506882",
      },
    },
  },
  number: {
    lineHeight: 1.3,
    fontSize: (props: PropTypes) => props.fontSize || 16,
  },
}));

type PropTypes = {
  minRows?: number;
  maxRows?: number;
  text: string;
  fontSize?: string | number;
  margin?: string | number;
  padding?: string | number;
  className?: string;
  placeholder?: string;
  lineNumbers?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange: (text: string) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  removeHeight?: boolean;
  autoFocus?: boolean;
};

const MTextArea = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    text,
    minRows = 1,
    maxRows = undefined,
    className = undefined,
    onChange,
    onKeyDown = null,
    onBlur = null,
    onFocus = null,
    lineNumbers = false,
    removeHeight = false,
    fontSize = 16,
    autoFocus,
    placeholder = I18n.t("rich_text_editor.insert_comments.comment_ph"),
  } = props;
  const [cursor, setCursor] = React.useState(null);
  const ref = React.useRef(null);
  const numbersRef = React.useRef(null);
  //https://stackoverflow.com/questions/46000544/react-controlled-input-cursor-jumps
  React.useEffect(() => {
    const input = ref.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
      if (removeHeight) {
        /// unset height to allow minimum height to work
        /// https://github.com/Andarist/react-textarea-autosize/issues/332
        input.style.setProperty("height", `unset`, "important");
      }
    }
  }, [ref, cursor, text]);

  const handleChange = (e) => {
    console.log("handleChange", e.target.value);
    setCursor(e.target.selectionStart);
    onChange && onChange(e.target.value);
  };

  if (lineNumbers) {
    return (
      <div className={ClassNames(classes.wrapper, className)}>
        <div className={classes.numbers} ref={numbersRef}>
          {text?.split("\n").map((t, i) => (
            <span className={classes.number} key={`line_number_${i}`}></span>
          ))}
        </div>
        <TextareaAutosize
          ref={ref}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
          value={text}
          minRows={minRows}
          maxRows={maxRows}
          onChange={handleChange}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
          placeholder={placeholder}
          onPaste={(e) => e.stopPropagation()}
          className={classes.textInput}
        />
      </div>
    );
  }

  return (
    <TextareaAutosize
      ref={ref}
      onBlur={onBlur}
      onFocus={onFocus}
      value={text}
      autoFocus={autoFocus}
      minRows={minRows}
      maxRows={maxRows}
      onChange={handleChange}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      placeholder={placeholder}
      onPaste={(e) => e.stopPropagation()}
      className={ClassNames(classes.textInput, className)}
    />
  );
};

export default MTextArea;
