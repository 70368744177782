import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { uniqBy } from "lodash";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { WRITER_BIO } from "../../../../../reducers/constants/actionTypes";
import { ICategory, IWriterBio } from "../../../../../reducers/constants/objectTypes";
import MSelectMultiple from "../../../../../components/MSelectMultiple";

const useStyles = makeStyles((theme) => ({
  bioCategoriesWrapper: {
    width: "100%",
  },
  wrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 10,
    whiteSpace: "pre",
  },
}));

const mapStateToProps = (state) => ({
  allCategories: state.category.categories,
  categories: state.writerBio.categories,
});

const mapDispatchToProps = (dispatch) => ({
  onCategoriesChanged: (categories) =>
    dispatch({ type: WRITER_BIO.ON_PROPERTY_CHANGED, name: "categories", value: categories }),
});

type PropTypes = {
  allCategories: ICategory[];
  categories: ICategory[];

  onCategoriesChanged: (categories: ICategory[]) => void;
};

const BioCategories = (props: PropTypes) => {
  const classes = useStyles(props);
  const { allCategories, categories, onCategoriesChanged } = props;

  return (
    <div className={classes.bioCategoriesWrapper}>
      <Typography className={ClassNames(classes.title)} variant={"subtitle2"}>
        {I18n.t("manager.writers_bio.categories")}
      </Typography>
      <MSelectMultiple
        wrapperClassName={classes.wrapper}
        options={allCategories}
        selectedOptions={categories}
        optionLabel={"name"}
        optionValue={"_id"}
        borderWidth={1}
        height={38}
        fullWidth
        placeholder={I18n.t("manager.writers_bio.select")}
        handleOptionSelected={(category: ICategory) => {
          onCategoriesChanged(uniqBy([...(categories || []), category], "_id"));
        }}
        handleOptionRemoved={(category: ICategory) => {
          const filteredTopics = (categories || []).filter((t) => t._id !== category._id);
          onCategoriesChanged(filteredTopics);
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BioCategories);
