import React from "react";
import { Theme, Typography, makeStyles } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import MTextFieldV2 from "../../../../../../../components/MTextFieldV2";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import { IWidget } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  nameWrapper: ({ margin }: PropTypes) => ({
    margin: margin || "20px 20px 0 20px",
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
  itemWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
  },
}));

const mapStateToProps = (state) => ({
  widget: state.widgetBuilder.widget,
});

const mapDispatchToProps = (dispatch) => ({
  editWidgetName: (name: string) => dispatch({ type: WIDGET_BUILDER.EDIT_WIDGET_PROPERTY, property: { name } }),
});

type PropTypes = {
  margin?: string;
  widget: IWidget;
  editWidgetName: (name: string) => void;
};

const EditWidgetName = (props: PropTypes) => {
  const { widget, editWidgetName } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.nameWrapper}>
      <div className={classes.itemWrapper}>
        <Typography className={classes.title}>{I18n.t("widgets_builder.build.widget_name")}</Typography>
        <MTextFieldV2
          value={widget.name}
          placeholder={I18n.t("widgets_builder.build.widget_name_ph")}
          onDebounceChange={editWidgetName}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWidgetName);
