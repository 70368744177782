import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.text.primary,
  },
}));

type PropTypes = {
  className?: string;
  onClick?: () => void;
};

const InsertImageIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className, onClick } = props;

  return (
    <SvgIcon
      onClick={onClick}
      className={ClassNames(classes.icon, className)}
      width="22"
      height="21"
      viewBox="0 0 22 21"
    >
      <path
        id="Vector"
        d="M16.5 19H2.5V5H11.5V3H2.5C1.4 3 0.5 3.9 0.5 5V19C0.5 20.1 1.4 21 2.5 21H16.5C17.6 21 18.5 20.1 18.5 19V10H16.5V19ZM8.71 15.83L6.75 13.47L4 17H15L11.46 12.29L8.71 15.83ZM18.5 3V0H16.5V3H13.5C13.51 3.01 13.5 5 13.5 5H16.5V7.99C16.51 8 18.5 7.99 18.5 7.99V5H21.5V3H18.5Z"
      />
    </SvgIcon>
  );
};

export default InsertImageIcon;
