import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { IAnalyticsEvent } from "../../../../reducers/constants/objectTypes";
import EventCollapseItem from "./EventCollapseItem";
import { DATE_FORMAT } from "../../../../reducers/constants/consts";
import { I18n } from "react-redux-i18n";
import ActivityFeedEventDetails from "./ActivityFeedEventDetails";
const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "0 20px",
    fontSize: theme.typography.pxToRem(12),
    // borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      transition: "border-radius 500ms",
      marginTop: -1,
      marginBottom: 1,
      paddingTop: 1,
      "& $topicWrapper": {
        paddingTop: 1,
        // borderBottom: "1px solid transparent",
      },
      "& $actions": {
        display: "flex",
      },
      "& $takenByText": {
        display: "none",
      },
      "& $takeOverButton": {
        display: "flex",
      },
    },
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    height: 53,
    flexDirection: "row",
    alignItems: "center",
    marginRight: 5,
    cursor: "pointer",
  },
  eventWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  topicNameWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 20,
    height: "100%",
  },
  urlWrapper: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  eventTime: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  topicName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
    cursor: "pointer",
    width: "fit-content",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  collapse: {},
  iconColumn: {
    // padding: '19px 14px 16px 16px',
    paddingLeft: 10,
    // borderBottom: '1px solid #fff',
    marginBottom: -1,
    width: 55,
  },
  hiddenColumn: {
    width: 20,
  },
  wideColumn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    width: "20%",
  },
  column: {
    flexGrow: 1,
    flexBasis: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    height: "100%",
  },
  timeColumn: {
    width: 85,
  },
  hoverableColumn: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  moreOptionsColumn: {
    width: 30,
  },
  actions: {},
  tooltip: {
    whiteSpace: "pre-wrap",
  },
  collapseContainer: {
    width: "100%",
  },
  collapseWrapper: {
    minHeight: 100,
  },
  distinctId: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type PropTypes = {
  event: IAnalyticsEvent;
};

const ActivityFeedItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { event } = props;
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <ListItem onClick={() => setCollapsed(!collapsed)} className={classes.itemWrapper}>
      <div className={classes.rowWrapper}>
        <div className={classes.timeColumn}>
          <div className={classes.urlWrapper}>
            <Typography className={classes.eventTime} variant="subtitle2">
              {moment(event.created_at).format(DATE_FORMAT.TIME)}
            </Typography>
          </div>
        </div>
        <div className={classes.column}>
          <div className={classes.eventWrapper}>
            <div className={classes.topicNameWrapper}>
              <Tooltip enterDelay={1000} title={event.name} arrow>
                <div className={classes.urlWrapper}>
                  <Typography className={classes.topicName} variant="subtitle2">
                    {I18n.t(`analytics.events.names.${event.name}`)}
                  </Typography>
                </div>
              </Tooltip>
            </div>
            <ActivityFeedEventDetails event={event} />
          </div>
        </div>
      </div>
      <EventCollapseItem event={event} collapsed={collapsed} />
    </ListItem>
  );
};

export default ActivityFeedItem;
