import { Divider, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldProperty from "./itemProperties/components/TextFieldProperty";
import RequiredFieldProperty from "./itemProperties/components/RequiredFieldProperty";
import WidthProperty from "./itemProperties/components/WidthProperty";
import SelectProperty from "./itemProperties/components/SelectProperty";
import TextareaProperty from "./itemProperties/components/TextareaProperty";
import { eWidgetFieldWidth, IWidgetStarRatingFieldProps } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({
      display: "flex",
      flexDirection: "column",
      gap: 10,
    }),
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
    selectProperty: {
      marginBottom: 5,
    },
  };
});

type PropTypes = {
  properties: IWidgetStarRatingFieldProps;
  onPropertyChange(properties: IWidgetStarRatingFieldProps): void;
};

const StartScaleWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;
  const classes = useStyles(props);

  const onHintChange = (event: any) => {
    const hint = event;
    onPropertyChange({ ...properties, hint });
  };

  // const onPlaceholderChange = (event: any) => {
  //     const placeholder = event.target.value;
  //     onPropertyChange({ ...properties, placeholder });
  // }

  const onRequiredChange = (required: boolean) => {
    onPropertyChange({ ...properties, required });
  };

  const onWidthChange = (width: eWidgetFieldWidth) => {
    onPropertyChange({ ...properties, width });
  };

  const onNumberOfOptionsChange = (numberOfOption: string) => {
    onPropertyChange({ ...properties, numberOfOptions: parseInt(numberOfOption, 10) });
  };

  console.log("StartScaleWidgetItem:properties", properties);

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.label")}
          placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.label_ph")}
          onChange={(label) => onPropertyChange({ ...properties, label })}
          value={properties?.label}
        />
        <Divider className={classes.divider} />
        <TextareaProperty onChange={onHintChange} value={properties?.hint} />
      </div>

      <RequiredFieldProperty required={properties.required} onChange={onRequiredChange} />
      <SelectProperty
        label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.number_of_options")}
        showLabel
        options={["3", "5"]}
        value={properties?.numberOfOptions?.toString()}
        onChange={onNumberOfOptionsChange}
        className={classes.selectProperty}
      />
      <WidthProperty value={properties.width} onChange={onWidthChange} />
    </div>
  );
};

export default StartScaleWidgetItem;
