import React, { useState } from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Editor, Transforms, Range, Element } from "slate";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles";
import { insertTable, handleCells, isSelectionTable } from "./tables";
import RowIcon from "./icons/TableRowsIcon";
import ColumnIcon from "./icons/TableColIcon";
import DeleteIcon from "./icons/TableDeleteTableIcon";
import DeleteRowIcon from "./icons/TableDeleteRowIcon";
import DeleteColIcon from "./icons/TableDeleteColIcon";
import TableToggleHeaderIcon from "./icons/TableToggleHeaderIcon";
import MTextField from "../../../components/MTextField";
import CfButton from "../../../components/CfButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 999,
    },
    modalWrapper: {
      // position: 'absolute',
      // zIndex: 3000,
      // top: '-10000px',
      // left: '-10000px',
      // marginTop: '-6px',
      // opacity: 0,
      // backgroundColor: '#ffffff',
      // border: '1px solid #d4d4d5',
      // borderRadius: '0.3rem',
      lineHeight: "1.4285em",
      // maxWidth: 300,
      // padding: '0.833em 1em',
      fontWeight: theme.typography.fontWeightLight as any,
      fontStyle: "normal",
      // color: 'rgba(0, 0, 0, 0.87)',
      // boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12) 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
      // '& > *': {
      // 	display: 'inline-block',
      // },
    },
    modalCaret: {
      position: "absolute",
      zIndex: 4000,
      left: "calc(50% - 5px)",
      top: "-10px",
      height: 0,
      width: 0,
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderBottom: "10px solid #d4d4d5",
      transition: "opacity 0.75s",
    },
    iconHolder: {
      cursor: "pointer",
      width: 25,
      height: 25,
      borderRadius: 3,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      padding: "-2px 3px",
      margin: 5,
      "&:hover": {
        backgroundColor: "#eee",
      },
    },
    iconHolderActive: {
      fill: theme.palette.primary.main,
    },
    InlineFormField: {
      display: "flex",
      flexDirection: "row",
    },
    InputFieldWrapper: {
      width: 270,
      display: "flex",
      flexDirection: "column",
    },
    InputFieldLabel: {
      fontWeight: theme.typography.fontWeightBold as any,
      fontSize: 12,
    },
    InlineFormButton: {
      marginLeft: 10,
      alignSelf: "flex-end",
      height: 38,
      width: 90,
      border: "none",
      color: "#fff",
      borderRadius: 3,
      backgroundColor: "#0043ba",
      "&:hover": {
        backgroundColor: "#265fc4",
      },
    },
    popupStyles: {
      padding: "0.2em 0.5em 0.2em 0.5em",
      zIndex: 9999,
    },
    paper: {
      padding: 20,
      // width: 440,
    },
    textToDisplayDetailsWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    fieldLabel: {
      fontWeight: theme.typography.fontWeightBold as any,
      fontSize: theme.typography.pxToRem(12),
    },
    linkDetailsField: {},
  })
);

//
// const popupStyles = {
// 	padding: "0.2em 0.5em 0.2em 0.5em",
// 	zIndex: "9999",
// };
//
// // const TableMenu = React.forwardRef(({ ...props }) => {
// 	const classes = useStyles(props);
//
// 	return (
// 		<div className={classes.modalWrapper} ref={props.ref} {...props} >
// 			{props.children}
// 		</div>
// 		);
// });
//
// TableMenu.displayName = "TableMenu";

type PropTypes = {
  open: boolean;
  anchorRef: any;
  closePopover: Function;
};
// https://github.com/accordproject/web-components
const TableModal = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, anchorRef, closePopover } = props;
  const editor = useSlateStatic();
  const [originalSelection, setOriginalSelection] = useState(null);
  const [rows, setRows] = useState(3);
  const [cols, setCols] = useState(3);

  // useEffect(() => {
  // 	//todo: fix popup position when focusing table
  // 	setOriginalSelection(editor.selection);
  // 	if (props.open) {
  // 		const el = anchorRef.current;
  //
  // 		if (!el) {
  // 			return;
  // 		}
  //
  // 		const domRange = ReactEditor.toDOMRange(editor, editor.selection);
  // 		const rect = domRange.getBoundingClientRect();
  // 		const CARET_TOP_OFFSET = 15;
  // 		el.style.opacity = 1;
  // 		el.style.top = `${
  // 			rect.top + rect.height + window.scrollY + CARET_TOP_OFFSET
  // 		}px`;
  // 		const hyperlinkCaret = el.children[0];
  // 		let calPos = rect.left - el.offsetWidth / 2;
  //
  //
  // 		// When the modal goes off page from left side
  // 		if (calPos < 0) {
  // 			// start from 10px
  // 			calPos = 10;
  // 			hyperlinkCaret.style.left = `${rect.left - 10}px`;
  // 		}
  //
  // 		// calculate the endpoint of the modal
  // 		const rightEndPos = calPos + el.offsetWidth;
  // 		const containerWidth = el.parentElement.offsetWidth;
  //
  // 		// When the modal goes off the page from right side
  // 		if (rightEndPos > containerWidth) {
  // 			let diff = rightEndPos - containerWidth;
  // 			// extra space of 10px on right side to look clean
  // 			diff += 10;
  // 			calPos -= diff;
  // 			const shift = diff - 5;
  // 			hyperlinkCaret.style.left = `calc(50% + ${shift}px)`;
  // 		}
  //
  // 		el.style.left = `${calPos}px`;
  // 		// const x = window.scrollX;
  // 		// const y = window.scrollY;
  // 		// window.scrollTo(x, y);
  // 	}
  // 	return () => {
  // 		const el = anchorRef.current;
  //
  // 		if (!el) {
  // 			return;
  // 		}
  // 		el.style.top ='0px';
  // 		el.style.left ='0px';
  // 	}
  // }, [editor, props.open]);

  /**
   * Inserts the table in the document.
   */
  const createTable = () => {
    Transforms.select(editor, originalSelection);
    insertTable(editor, rows, cols);
    Transforms.collapse(editor, { edge: "end" });
    ReactEditor.focus(editor);
    props.closePopover();
  };

  /**
   * Inserts and Deletes rows and columns from the table
   *  @param {string} action action to be performed
   */
  const handleClick = (action) => {
    Transforms.select(editor, originalSelection);

    const { selection } = editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      });
      if (tableNode) {
        const [oldTable, path] = tableNode;
        removeTable(editor);
        if (action === "row") {
          handleCells(oldTable, path, "row", editor);
        } else if (action === "col") {
          handleCells(oldTable, path, "col", editor);
        } else if (action === "header") {
          handleCells(oldTable, path, "header", editor);
        } else if (action === "drow") {
          handleCells(oldTable, path, "drow", editor);
        } else {
          handleCells(oldTable, path, "dcol", editor);
        }
      }
    }

    Transforms.deselect(editor);
    ReactEditor.focus(editor);
    props.closePopover();
  };

  /**
   * Deletes the table from the document.
   */
  const removeTable = () => {
    Transforms.select(editor, originalSelection);
    Transforms.removeNodes(editor, {
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      mode: "highest",
    });

    Transforms.deselect(editor);
    ReactEditor.focus(editor);
    props.closePopover();
  };

  return (
    <Popper
      open={props.open}
      anchorEl={anchorRef.current}
      role={undefined}
      className={classes.popper}
      transition
      // disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={(e) => closePopover()}>
              <div className={classes.modalWrapper}>
                <div className={classes.modalCaret} />
                <div onSubmit={createTable}>
                  {!isSelectionTable(editor) && (
                    <div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className={classes.textToDisplayDetailsWrapper} style={{ marginRight: 20 }}>
                          <Typography color={"textSecondary"} className={classes.fieldLabel} variant={"body2"}>
                            {"Number of rows"}
                          </Typography>
                          <MTextField
                            fullWidth
                            outlined
                            type={"number"}
                            customClass={classes.linkDetailsField}
                            value={rows.toString()}
                            onChange={(newRows) => setRows(parseInt(newRows, 10))}
                            placeholder={"Rows"}
                          />
                        </div>
                        <div className={classes.textToDisplayDetailsWrapper}>
                          <Typography color={"textSecondary"} className={classes.fieldLabel} variant={"body2"}>
                            {"Number of columns"}
                          </Typography>
                          <MTextField
                            fullWidth
                            outlined
                            type={"number"}
                            customClass={classes.linkDetailsField}
                            value={cols.toString()}
                            onChange={(newCols) => setCols(parseInt(newCols, 10))}
                            placeholder={"Columns"}
                          />
                        </div>
                      </div>
                      <CfButton text={"Create Table"} onClick={createTable} />
                    </div>
                  )}

                  {isSelectionTable(editor) && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Tooltip title={"Toggle Table Header"}>
                        <div
                          className={classes.iconHolder}
                          onClick={() => {
                            // setHeaders(! headers);
                            handleClick("header");
                          }}
                          aria-label="Toggle Table Header"
                        >
                          {/* <DeleteColIcon className={headers ? classes.iconHolderActive : null} /> */}
                          <TableToggleHeaderIcon />
                        </div>
                      </Tooltip>
                      <Tooltip title={"Insert new row"}>
                        <div
                          className={classes.iconHolder}
                          onClick={(e) => {
                            e.preventDefault();
                            handleClick("row");
                          }}
                          aria-label="Insert new row"
                        >
                          <RowIcon />
                        </div>
                      </Tooltip>
                      <Tooltip title={"Insert new column"}>
                        <div
                          className={classes.iconHolder}
                          onClick={() => handleClick("col")}
                          aria-label="Insert new column"
                        >
                          <ColumnIcon />
                        </div>
                      </Tooltip>
                      <Tooltip title={"Delete a row"}>
                        <div
                          className={classes.iconHolder}
                          onClick={() => handleClick("drow")}
                          aria-label="Delete a row"
                        >
                          <DeleteRowIcon />
                        </div>
                      </Tooltip>
                      <Tooltip title={"Delete a column"}>
                        <div
                          className={classes.iconHolder}
                          onClick={() => handleClick("dcol")}
                          aria-label="Delete a column"
                        >
                          <DeleteColIcon />
                        </div>
                      </Tooltip>
                      <Tooltip title={"Delete table"}>
                        <div className={classes.iconHolder} onClick={removeTable} aria-label="Delete table">
                          <DeleteIcon />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

TableModal.displayName = "TableModal";

export default TableModal;
