import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router";

import { IAdminUser, IOrganization, IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import { HOME } from "../../../reducers/constants/actionTypes";
import { web } from "../../../helpers/urlHelper";
import Personalize from "./Personalize";
import WebsiteInfo from "./WebsiteInfo";
import SelectPage from "./SelectPage";
import PagePreview from "./PagePreview";

type PropTypes = {
  user: IAdminUser;
  website: IWebsite;

  pageLoaded: Function;
  mpEvent: (event: string, props: object) => void;
  adminUserLoadFailed: () => void;
  onAdminUserLoaded: (result) => void;
  onWebsiteSelected: (website: IWebsite) => void;
  onOrganizationsLoaded: (organization: IOrganization) => void;
  onBoardingDataUpdated: (payload: any) => void;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  adminUserLoadFailed: () => dispatch({ type: HOME.ADMIN_USER_LOADED_FAILED }),
  onAdminUserLoaded: (result) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: result.user }),
  onWebsiteSelected: (selectedWebsite) => dispatch({ type: HOME.ON_WEBSITES_SELECTED, selectedWebsite }),
  onBoardingDataUpdated: (data) => dispatch({ type: HOME.ONBOARDING_DATA_UPDATED, data }),
});

const OnboardingFlow = (props: PropTypes) => {
  const { user, website, onAdminUserLoaded, adminUserLoadFailed, onWebsiteSelected, onBoardingDataUpdated } = props;
  const [userLoaded, setUserLoaded] = React.useState(false);

  const routes = React.useMemo(() => {
    const all = [];
    if (user) {
      all.push({
        url: web.personalize(),
        route: <Route exact={false} path={web.personalize()} component={() => <Personalize />} />,
      });
      all.push({
        url: web.websiteInfo(),
        route: <Route exact={false} path={web.websiteInfo()} component={() => <WebsiteInfo />} />,
      });
      all.push({
        url: web.selectPage(),
        route: <Route exact={false} path={web.selectPage()} component={() => <SelectPage />} />,
      });
      all.push({
        url: web.websitePagePreview(),
        route: <Route exact={false} path={web.websitePagePreview()} component={() => <PagePreview />} />,
      });
    }
    return all;
  }, [user]);

  React.useEffect(() => {
    if (user) return;
    agent.Auth.getUser()
      .then((res) => {
        if (res.user?.websites.length > 0) {
          const unfinishedWebsite = res.user.websites.find((w) => !w.configurations?.analytics?.entailInstalled);
          if (unfinishedWebsite) {
            onWebsiteSelected(unfinishedWebsite);
          }
        }
        onAdminUserLoaded(res);
      })
      .catch((e) => {
        adminUserLoadFailed();
        console.log(e);
      })
      .finally(() => setUserLoaded(true));
  }, [user]);

  if (!user && !userLoaded) return null;

  return (
    <Switch>
      {routes.map((route) => route.route)}
      <Redirect exact from={"*"} to={routes?.[0]?.url || web.signUp()} />
    </Switch>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingFlow);
