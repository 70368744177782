import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 44,
    width: 44,
  },
}));

type PropTypes = {
  className?: string;
};

const EntailSquareLogo = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 44 44"}>
      <rect x="0.262695" width="43.4747" height="43.4747" rx="2" fill="#2D6FF6" />
      <path
        d="M7.35889 35.9534V8.51526H23.2307V12.5526H12.3891V20.0785H22.0518V23.9982H12.3891V31.9161H40.0009V35.9534H7.35889Z"
        fill="white"
      />
      <path
        d="M38.0411 24.7881V25.0728H36.2992L35.8687 24.0728H33.9282L33.4977 25.0728H31.7559V24.7881H32.5175L34.3984 20.4434H35.3985L37.2728 24.7881H38.0411ZM34.9018 21.8209L34.319 23.1654H35.478L34.9018 21.8209Z"
        fill="white"
      />
      <path
        d="M39.5387 24.7881V25.0728H37.3663V24.7881H37.9359V20.8748L38.9691 21.3895V24.7881H39.5387Z"
        fill="white"
      />
      <path d="M37.9192 19.396L38.9599 19.8691V20.9098L37.9192 20.4368V19.396Z" fill="#FFC700" />
    </SvgIcon>
  );
};

export default EntailSquareLogo;
