import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { IAnalyticsEvent } from "../../../../reducers/constants/objectTypes";
import { Collapse } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  collapseContainer: {
    width: "100%",
  },
  collapseWrapper: {
    minHeight: 100,
  },
  itemsWrapper: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "repeat(3, 33%)",
  },
  item_col_1: {
    gridColumn: 1,
  },
  item_col_2: {
    gridColumn: 2,
  },
  item_col_3: {
    gridColumn: 3,
  },
  item: {
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: "0px 15px",
    padding: 10,
  },
  itemKey: {
    fontSize: 12,
  },
  itemValue: {
    fontSize: 12,
    marginLeft: 10,
  },
}));

const keyValue = {
  api_timestamp: "created_at",
  browser: "browser",
  browser_version: "browser_version",
  browser_language: "browser_language",
  city: "city",
  country: "country",
  current_domain: "domain",
  current_url: "url",
  device_id: "device_id",
  distinct_id: "device_id",
  initial_referrer: "referrer",
  initial_referring_domain: "referrer_source",
  operating_system: "operating_system",
  operating_system_version: "operating_system_version",
  page_title: "page_title",
  region: "region",
  height: "screen_height",
  screen_width: "screen_width",
  time: "created_at",
  time_processed_utc: "updated_at",
  url_protocol: "url_protocol",
};

type PropTypes = {
  event: IAnalyticsEvent;
  collapsed: boolean;
};

const EventCollapseItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { event, collapsed } = props;

  return (
    <Collapse
      in={!collapsed}
      classes={{
        root: classes.collapseContainer,
        wrapper: classes.collapseWrapper,
      }}
    >
      <div className={classes.itemsWrapper}>
        {Object.keys(keyValue).map((key, index) => (
          <div
            className={ClassNames(
              classes.item,
              index % 1 === 0 && classes.item_col_1,
              index % 2 === 0 && classes.item_col_2,
              index % 3 === 0 && classes.item_col_3
            )}
            key={key}
          >
            <Typography className={classes.itemKey} variant="body1">
              {I18n.t(`analytics.events.table.${key}`)}
            </Typography>
            <Typography className={classes.itemValue} variant="body2">
              {event[keyValue[key]]}
            </Typography>
          </div>
        ))}
      </div>
    </Collapse>
  );
};

export default EventCollapseItem;
