import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import defaultHeaderProfile from "../img/default_header_profile_pic.png";
import defaultProfile from "../img/default_profile_pic.png";
import { getImageCDNUrl } from "../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  profileImage: {
    // background: `url(${defaultProfile}) no-repeat`,
    backgroundSize: "contain",
    borderRadius: "50%",
  },
}));

type CfProfileImageProps = {
  header?: boolean;
  image?: string;
  defaultImage?: string;
  width: number;
  onClick?: Function;
  disabled?: boolean;
};

const MProfileImage = (props: CfProfileImageProps) => {
  const classes = useStyles(props);
  const { image = null, width, defaultImage = null, header = false, onClick } = props;

  return (
    <img
      width={width}
      height={width}
      src={
        getImageCDNUrl(image || defaultImage || defaultProfile) /* (header ? defaultHeaderProfile : defaultProfile) */
      }
      className={classes.profileImage}
      alt={"profile"}
      onClick={(event) => onClick(event)}
    />
  );
};

export default MProfileImage;
