import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { uniqBy } from "lodash";
import { ReactEditor } from "slate-react";
import PageEditorNavbar from "./components/PageEditorNavbar";
import { IPage, IWebsite } from "../../reducers/constants/objectTypes";
import { AbilityContext } from "../../casl/can";
import CfProgressBar from "../../components/CfProgressBar";
import RichTextEditor from "../../editor/RichTextEditor";
import { ForcedLayout } from "../../editor/types/editor.Types";

const useStyles = makeStyles((theme: Theme) => ({
  editorWrapper: {
    backgroundColor: theme.palette.background.paper,
  },
  textEditor: {},
  editorToolbar: {
    height: 55,
    paddingLeft: 28,
  },
  titleCustomClass: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  feedWrapper: {
    maxWidth: 700,
    paddingBottom: 100,
    margin: "auto",
  },
  editable: {
    paddingLeft: 40,
    paddingRight: 40,
    minHeight: "calc(100vh - 370px) !important",
  },
  metaTitle: {
    minHeight: 80,
    height: "auto",
  },
  metaDescription: {
    minHeight: 140,
    height: "auto",
  },
  metaTitlePaper: {
    borderRadius: 0,
    paddingLeft: 40,
    paddingRight: 40,
    border: "none",
    borderBottom: `${theme.palette.divider} solid 1px`,
  },
  metaDescriptionPaper: {
    borderRadius: 0,
    paddingLeft: 40,
    paddingRight: 40,
    border: "none",
    borderBottom: `${theme.palette.divider} solid 1px`,
  },
  contents: {
    height: 50,
    width: "100%",
    backgroundColor: "aqua",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  pageLoaded: boolean;
  page: IPage;
  pageRichText: string;
  withCustomElements: boolean;

  handleEditorChanged?: (editor: ReactEditor) => void;
  handlePageChanged: (page: IPage) => void;
  handlePageTextChanged: (richText: string) => void;
  forcedElements?: ForcedLayout[];
  disableAi?: boolean;
};

const PageEditorWYSIWYG = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    pageLoaded,
    page,
    pageRichText,
    withCustomElements,
    forcedElements = [],
    handlePageChanged,
    handleEditorChanged = null,
    handlePageTextChanged,
    disableAi = true,
  } = props;
  const ability = React.useContext<any>(AbilityContext);

  if (!pageLoaded) {
    return (
      <div className={classes.textEditor} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CfProgressBar />
      </div>
    );
  }

  return (
    <div className={classes.textEditor}>
      <PageEditorNavbar
        websiteConfigurationsProps={selectedWebsite.configurations}
        page={page}
        selectedWebsite={selectedWebsite}
        pageRichText={pageRichText}
        handlePageChanged={handlePageChanged}
      />

      <div className={classes.editorWrapper}>
        <RichTextEditor
          key={page._id}
          droppableId={"page-editor"}
          coverImage={page.previewImage}
          coverVideo={page.previewVideo}
          withAiAssistant={true}
          handlePageChanged={handlePageChanged}
          fontSize={16}
          enableCoverMedia={!["niProductReview"].includes(page.type)}
          disableAi={disableAi}
          titleDesiredCount={150}
          enforceTitleDesiredCount
          imageSizeLimit={350 * 1000}
          feedWrapperCustomClass={classes.feedWrapper}
          editableCustomClass={classes.editable}
          toolbarCustomClass={classes.editorToolbar}
          titleCustomClass={classes.titleCustomClass}
          forcedElements={forcedElements}
          enableEditableVoids
          page={page}
          withTOC
          products={page.products || []}
          handleProductsChange={(products) =>
            handlePageChanged({ ...page, products: uniqBy([...(products || []), ...(page.products || [])], "_id") })
          }
          enableTables
          enableComments={
            ability.can("editor", "role") ||
            (selectedWebsite.reviewProcess === "Fact-Check" && ability.can("user_role", "customer")) ||
            (selectedWebsite.reviewProcess === "Custom" && ability.can("user_role", "customer"))
          }
          withCustomElements={withCustomElements}
          editorMode={ability.can("editor", "role") || ability.can("user_role", "customer")}
          website={selectedWebsite}
          brief={page.brief || page.subtitle}
          onSubtitleChanged={(brief) => handlePageChanged({ ...page, brief })}
          title={page.title}
          onTitleChanged={(title) => handlePageChanged({ ...page, title })}
          initialValue={pageRichText}
          onValueChanged={(value) => handlePageTextChanged(value)}
          handleEditorChange={handleEditorChanged}
          // onValueChanged={(value) => handleArticleTextChanged({ ...post, richText: , text: richTextEditorHelper.serialize(JSON.parse(value)) })}
        />
      </div>
    </div>
  );
};

export default PageEditorWYSIWYG;
