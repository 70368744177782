import * as React from "react";
import { I18n } from "react-redux-i18n";
import NumberInputBaseComponent from "../BaseComponents/NumberInputBaseComponent";

type PropTypes = {
  value: number;
  maxValue: number;
  defaultValue?: number;
  handleChange: (maxImages: number) => void;
};

const MaxImagesCSS = (props: PropTypes) => {
  const { value, defaultValue, maxValue, handleChange } = props;
  return (
    <NumberInputBaseComponent
      rowTitle={I18n.t("rich_text_editor.max_images")}
      defaultValue={defaultValue}
      postFix={`${value || defaultValue || 0}/${maxValue}`}
      width={80}
      value={value}
      maxValue={maxValue}
      handleChange={(fontSize) => handleChange(parseFloat(fontSize))}
    />
  );
};

export default MaxImagesCSS;
