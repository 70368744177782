import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import agent from "../../../../../../../agent";
import { WIDGET_RULE_EDITOR } from "../../../../../../../reducers/constants/actionTypes";
import { IWebsite, ICampaign } from "../../../../../../../reducers/constants/objectTypes";
import { WidgetRuleEditorRootState } from "../../../../../../../reducers/widgetRuleEditorReducer";
import { getWidgetHTMLContainer, scrollToContainer } from "../../../../../../../helpers/htmlPreviewHelper";

//
// const b64toBlob = (base64, type = "application/octet-stream") => {
//   console.log("b64toBlob");
//   console.log("b64toBlob", Buffer.from(base64, "base64").toString("utf8"));
//   fetch(`data:${type};base64,${base64}`).then((res) => res.blob());
// };

function bufferToBlob(image, mimeType) {
  if (image?.type === "Buffer") {
    const uint8Array = new Uint8Array(image.data);
    return new Blob([uint8Array], { type: mimeType });
  } else {
    let byteArray;
    try {
      byteArray = image.split(",").map(Number);
    } catch (e) {
      byteArray = Object.values(image);
    }
    // Create a Uint8Array from the buffer data
    const uint8Array = new Uint8Array(byteArray);
    // Create a Blob from the Uint8Array
    return new Blob([uint8Array], { type: mimeType });
  }
}

const b64toBlob = (base64, type = "application/octet-stream") =>
  fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

function base64ToBlob(base64, mimeType) {
  console.log("base64ToBlob");
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}

export const loadHtml = async (
  dispatch: any,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  options: {
    rule: ICampaign;
    selectedWebsite: IWebsite;
    preview: WidgetRuleEditorRootState["preview"];
    selectedWidgetContainerId?: WidgetRuleEditorRootState["selectedWidgetContainerId"];
    noCache?: boolean;
  },
  setAbortController?: (controller: AbortController) => void
) => {
  const { rule, selectedWebsite, preview, selectedWidgetContainerId, noCache } = options;
  if (!rule.previewUrl) return;
  const controller = new AbortController();
  if (setAbortController) {
    setAbortController(controller);
  }

  dispatch({ type: WIDGET_RULE_EDITOR.PREVIEW_PROPERTY_CHANGE, property: { loading: true } });
  agent.Campaigns.previewCampaign(selectedWebsite, rule, preview.mobilePreview, noCache, controller.signal)
    .then(async (res) => {
      console.log("response image", res.image);
      console.log("response xpath", res.XPathCollections);
      const blob = await bufferToBlob(res.image, "image/png");
      dispatch({
        type: WIDGET_RULE_EDITOR.PREVIEW_PROPERTY_CHANGE,
        property: {
          previewBlobUrl: URL.createObjectURL(blob),
          plainHtml: res.html,
          htmlWithContainers: res.html,
          XPathCollections: res.XPathCollections,
        },
      });

      const regex = /entail-container-id="([^"]+)"/g;
      // Array to store the extracted values
      const ids = [];

      // Use the regular expression to find matches
      let match;
      while ((match = regex.exec(res.html)) !== null) {
        ids.push(match[1]); // match[1] contains the value of entail-container-id
      }

      console.log("container ids", ids);

      const reOrderedContainers = ids.map((id) =>
        rule.widgetContainers.find((container) => container.containerId === id)
      );
      const remainingContainers = rule.widgetContainers.filter((container) => !ids.includes(container.containerId));
      dispatch({
        type: WIDGET_RULE_EDITOR.PROPERTY_CHANGE,
        property: {
          rule: {
            ...rule,
            widgetContainers: [...reOrderedContainers, ...remainingContainers],
          },
        },
      });

      setTimeout(() => {
        const widgetHTMLContainer = getWidgetHTMLContainer(selectedWidgetContainerId);
        if (widgetHTMLContainer) {
          scrollToContainer(selectedWidgetContainerId);
        }
      }, 200);
    })
    .catch((e) => {
      if (controller.signal.aborted) {
        console.log("aborted");
        return;
      }
      console.error(e, e.message);

      const getErrorMsg = () => {
        if ((e?.response?.body?.message || e.message).includes("<!DOCTYPE html>")) {
          return "Sorry, something went wrong, please try again.";
        }
        return e?.response?.body?.message || e.message;
      };

      enqueueSnackbar(getErrorMsg(), { variant: "error" });
      if (!preview.plainHtml && e?.response?.body?.plainHtml) {
        dispatch({
          type: WIDGET_RULE_EDITOR.PREVIEW_PROPERTY_CHANGE,
          property: { plainHtml: e?.response?.body?.plainHtml },
        });
      }
    })
    .finally(() => {
      console.log("finally");
      dispatch({ type: WIDGET_RULE_EDITOR.PREVIEW_PROPERTY_CHANGE, property: { loading: false } });
    });
};
