import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import MScrollSpyTabs from "../../../../../components/MScrollSpyTabs";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    gap: 30,
  },
  sectionsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    minWidth: 0,
    flex: 1,
  },
  scrollSpyTabs: {
    position: "sticky",
    top: 0,
    minWidth: 205,
  },
  tabIndicator: {
    left: 0,
    paddingLeft: 3,
  },
  tab: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    textTransform: "none",
    minHeight: 60,
    color: theme.palette.common.black,
    textAlign: "left",
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
      marginLeft: 30,
    },
  },
  selectedTab: {
    color: theme.palette.primary.main,
  },
}));

type PropTypes = {
  rootElementRef: React.RefObject<HTMLElement>;
  children: React.ReactNode;
  scrollMargin?: number;
  sections: {
    label: string;
    ref: React.MutableRefObject<HTMLDivElement>;
  }[];
  classes?: {
    [key in keyof ReturnType<typeof useStyles>]?: string;
  };
};

const TabLayout = (props: PropTypes) => {
  const { sections, children, scrollMargin, rootElementRef, classes: propClasses } = props;
  const classes = useStyles(props);

  return (
    <div className={ClassNames(classes.wrapper, propClasses.wrapper)}>
      <div className={ClassNames(classes.sectionsWrapper, propClasses.sectionsWrapper)}>{children}</div>
      <div>
        <MScrollSpyTabs
          sections={sections}
          scrollingElementRef={rootElementRef}
          scrollMargin={scrollMargin}
          scrollOffsetTop={65}
          TabsProps={{
            orientation: "vertical",
            classes: {
              root: ClassNames(classes.scrollSpyTabs, propClasses.scrollSpyTabs),
              indicator: ClassNames(classes.tabIndicator, propClasses.tabIndicator),
            },
          }}
          TabProps={{
            classes: {
              root: ClassNames(classes.tab, propClasses.tab),
              selected: ClassNames(classes.selectedTab, propClasses.selectedTab),
            },
          }}
        />
      </div>
    </div>
  );
};

export default TabLayout;
