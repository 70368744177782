import * as React from "react";
import { useCallback, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import useDebounce from "../../../../../../../../../hooks/useDebounce";
import agent from "../../../../../../../../../agent";
import MSelect from "../../../../../../../../../components/MSelect";
import { IWebsite, IPage } from "../../../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  productWrapper: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    minWidth: 80,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 7,
  },
  selectCustomClass: {
    width: "100%",
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 3,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  value: IPage;

  onChange: (value: IPage) => void;
};

const PageSelector = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, value } = props;
  const { onChange } = props;
  const [items, setItems] = React.useState<IPage[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const theme = useTheme<Theme>();

  const searchPages = useCallback(
    (search = "") => {
      agent.Pages.searchPublished(selectedWebsite._id, search)
        .then((results) => {
          setItems(results.pages);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [selectedWebsite._id]
  );

  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    searchPages(debouncedSearchText);
  }, [debouncedSearchText, searchPages]);

  return (
    <div className={classes.productWrapper}>
      <MSelect
        customClass={classes.selectCustomClass}
        isClearable={false}
        options={items}
        value={value ?? null}
        searchable
        placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.select_article_ph")}
        height={50}
        borderWidth={"0px"}
        isBold={false}
        menuPlacement={"bottom"}
        singleValueStyles={{ fontSize: theme.typography.pxToRem(14) }}
        handleInputChange={setSearchText}
        handleChange={onChange}
        optionLabel={"title"}
        optionValue={"_id"}
      />
    </div>
  );
};

export default PageSelector;
