import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { useSlate } from "slate-react";
import { useState } from "react";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ImageIcon from "../toolbar/icons/ImageIcon";
import { IFile, IWebsite } from "../../../reducers/constants/objectTypes";
import image from "./image";
import InsertImageDialog from "../../../containers/Modals/InsertImageDialog/InsertImageDialog";
import RichTextEditorHelper from "../../helper/richTextEditorHelper";
import MTextField from "../../../components/MTextField";
import MTextButton from "../../../components/MTextButton";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  activeIcon: {
    fill: theme.palette.primary.main,
  },
  paper: {
    padding: 10,
  },
  imageDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  imageSizeWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  sizeField: {},
  bold: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
  },
}));

type PropTypes = {
  website: IWebsite;
  imageSizeLimit?: number;
};

function InsertImageButton(props: PropTypes) {
  const classes = useStyles(props);
  const editor = useSlate();
  const { website, imageSizeLimit } = props;
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [insertImageDialogOpen, setInsertImageDialogOpen] = useState(false);

  const [height, setHeight] = useState("100%");
  const [width, setWidth] = useState("100%");

  const isActive = RichTextEditorHelper.isBlockActive(editor, "image");
  return (
    <div style={{ display: "contents" }}>
      <Tooltip enterDelay={2000} title={I18n.t("rich_text_editor.tooltips.insert_image")}>
        <IconButton
          id={"insert-image-button"}
          ref={anchorRef}
          className={classes.button}
          disableRipple
          onClick={(e) => !isActive && setInsertImageDialogOpen(true)}
        >
          <ImageIcon className={ClassNames(classes.icon, isActive && classes.activeIcon)} />
        </IconButton>
      </Tooltip>
      <Popper open={false && isActive} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper className={classes.paper}>
              <div className={classes.imageDetailsWrapper}>
                <div className={classes.imageSizeWrapper}>
                  <Typography color={"textSecondary"} className={classes.bold} variant={"body2"}>
                    {I18n.t("rich_text_editor.rich_text_editor.width")}
                  </Typography>
                  <MTextField
                    fullWidth
                    customClass={classes.sizeField}
                    value={width}
                    onChange={(text) => setWidth(text)}
                  />
                </div>
                <div className={classes.imageSizeWrapper}>
                  <Typography color={"textSecondary"} className={classes.bold} variant={"body2"}>
                    {I18n.t("rich_text_editor.rich_text_editor.height")}
                  </Typography>
                  <MTextField
                    fullWidth
                    customClass={classes.sizeField}
                    value={height}
                    onChange={(text) => setHeight(text)}
                  />
                </div>
              </div>
              <div className={classes.buttons}>
                <MTextButton text={I18n.t("rich_text_editor.insert_image.update")} onClick={(e) => null} />
                <MTextButton
                  text={I18n.t("rich_text_editor.insert_image.open_dialog")}
                  onClick={(e) => setInsertImageDialogOpen(true)}
                />
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
      {insertImageDialogOpen && (
        <InsertImageDialog
          website={website}
          imageSizeLimit={imageSizeLimit}
          handleInsertClick={(file: IFile) => {
            image.insertImage(editor, file);
            setInsertImageDialogOpen(false);
          }}
          handleCloseClick={() => setInsertImageDialogOpen(false)}
        />
      )}
    </div>
  );
}

export default InsertImageButton;
