import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import { I18n } from "react-redux-i18n";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CopyToClipboardIcon from "../../../icons/CopyToClipboardIcon";
import { IKeywordSerpCleaned } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  group: {
    "&:hover": {
      backgroundColor: "#E6EEFF",
      borderRadius: 30,
      transition: "border-radius 100ms",
      "& $serpTitle": {
        transition: "color 200ms",
        color: theme.palette.primary.main,
      },
      "& $serpUrl": {
        transition: "color 200ms",
        color: theme.palette.primary.main,
      },
      "& $actionsWrapper": {
        display: "flex",
      },
      position: "relative",
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actionsWrapper: {
    gap: 20,
    alignItems: "center",
    display: "none",
    position: "absolute",
    zIndex: 2,
    left: "40%",
    width: "fit-content",
    backgroundColor: "#E6EEFF",
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionIcon: {
    height: 18,
    width: 18,
    fill: theme.palette.text.secondary,
  },
  serpRowTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  metric: {
    fontSize: theme.typography.pxToRem(14),
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: "100%",
    width: "10%",
  },
  serpTitle: {
    fontSize: theme.typography.pxToRem(14),

    fontWeight: theme.typography.fontWeightBold as any,

    color: theme.palette.text.primary,
  },
  serpUrl: {
    fontSize: theme.typography.pxToRem(12),

    color: theme.palette.text.secondary,
  },
  listRow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    padding: "12px 30px",
    justifyContent: "space-between",
    fontSize: theme.typography.pxToRem(14),
  },
  listItemFirstColumn: {
    width: "55%",
  },
}));

type PropTypes = {
  serp: IKeywordSerpCleaned;
};

const BriefSerpDialogListItem = (props: PropTypes) => {
  const { serp } = props;
  const classes = useStyles(props);

  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return () => {
      navigator.clipboard.writeText(serp.title);
      setShowTitleCopyTooltip(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setShowTitleCopyTooltip(false);
      }, 1000);
    };
  })();

  return (
    <div className={classes.group}>
      <ListItem className={classNames(classes.listRow)}>
        <div
          className={classNames(classes.metric, classes.listItemFirstColumn)}
          onClick={(e) => {
            e.stopPropagation();
            window.open(serp.url, "_blank");
          }}
        >
          <div className={classes.serpRowTitle}>
            <Typography className={classes.serpTitle}>{serp.title}</Typography>
            <Typography className={classes.serpUrl}>{serp.url}</Typography>
          </div>
        </div>
        <div className={classNames(classes.actionsWrapper)}>
          <Tooltip
            className={classes.actionIconWrapper}
            arrow
            onClick={(e) => {
              e.stopPropagation();
              window.open(serp.url, "_blank");
            }}
            title={I18n.t("outline.icons.open_in_new_tab")}
          >
            <div>
              <OpenInNewIcon className={classes.actionIcon} />
            </div>
          </Tooltip>
          <Tooltip
            arrow
            title={showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title")}
            className={classes.actionIconWrapper}
          >
            <div>
              <CopyToClipboardIcon
                className={classNames(classes.actionIcon)}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTitleCopy();
                }}
              />
            </div>
          </Tooltip>
        </div>
      </ListItem>
    </div>
  );
};

export default BriefSerpDialogListItem;
