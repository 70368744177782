import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";
import { Rating } from "@material-ui/lab";
import numeral from "numeral";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ProductCTA } from "../../../../types/editor.Types";
import { IProduct, IWebsiteThemeProps } from "../../../../../reducers/constants/objectTypes";
import TextAreaWidgetBaseElement from "../../baseElements/TextAreaWidgetBaseElement";
import ButtonWidgetBaseElement from "../../baseElements/ButtonWidgetBaseElement";

const useStyles = makeStyles((theme: Theme) => ({
  productTextWrapper: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  ctaNumberDefault: {
    fontSize: 70,
    fontWeight: theme.typography.fontWeightBold as any,
    margin: "0px 0px 15px 0px",
  },
  ctaNumber: (props: PropTypes) => ({
    ...(props.element.data.productNumber?.css || {}),
  }),

  productTitleDefault: {
    fontSize: 21,
    fontWeight: theme.typography.fontWeightBold as any,
    color: (props: PropTypes) => props.websiteTheme.palette.primary.main,
  },
  titleCss: (props: PropTypes) => ({ ...(props.element.data.title?.css || {}) }),
  productNameDefault: {
    fontSize: 14,
  },
  priceDefault: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  buttonDefault: {
    padding: "15px 90px",
  },
  innerTextWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
    gap: "8px",
    width: "100%",
  },
  pricing: {
    display: "flex",
    gap: "10px",
    justifyContent: "start",
  },
  callToActionBtn: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  btnWrapper: {},
  rating: {
    display: "flex",
    gap: "5px",
    alignItems: "self-end",
  },
  ratingText: {
    fontSize: "16px",
    justifyContent: "center",
  },
}));

type PropTypes = {
  product?: IProduct;
  element: ProductCTA;
  readMode?: boolean;
  websiteTheme: IWebsiteThemeProps;

  handleChange?: (value: any) => void;
};

const ProductCTAText = (props: PropTypes) => {
  const { product, element, readMode, websiteTheme, handleChange } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.productTextWrapper}>
      <div className={classes.innerTextWrapper}>
        {element.data.productNumber?.show && (
          <>
            {readMode ? (
              <Typography
                style={{ ...element.data.productNumber.css }}
                variant={"h2"}
                className={classNames(classes.ctaNumberDefault, classes.ctaNumber)}
              >
                {element.data.productNumber?.number || I18n.t("rich_text_editor.product_cta.cta_num_ph")}
              </Typography>
            ) : (
              <TextAreaWidgetBaseElement
                className={classNames(classes.ctaNumberDefault, classes.ctaNumber)}
                // style={element.data.productNumber.css}
                text={element.data.productNumber?.number || ""}
                placeholder={I18n.t("rich_text_editor.product_cta.cta_num_ph")}
                onChange={(ctaNumber) =>
                  handleChange({ ...element.data, productNumber: { ...element.data.productNumber, number: ctaNumber } })
                }
              />
            )}
          </>
        )}
        {element.data.title?.show && (
          <>
            {readMode ? (
              <Typography
                // style={{ ...element.data.title.css }}
                variant={"h3"}
                className={classNames(classes.productTitleDefault, classes.titleCss)}
              >
                {element.data.title.title || ""}
              </Typography>
            ) : (
              <TextAreaWidgetBaseElement
                className={classNames(classes.productTitleDefault, classes.titleCss)}
                text={element.data.title.title}
                // style={element.data.title.css}
                placeholder={I18n.t("rich_text_editor.product_cta.cta_title_ph")}
                onChange={(ctaTitle) =>
                  handleChange({ ...element.data, title: { ...element.data.title, title: ctaTitle } })
                }
              />
            )}
          </>
        )}

        {element.data.productName?.show && (
          <Typography style={{ ...element.data.productName.css }} className={classNames(classes.productNameDefault)}>
            {product?.displayName ||
              product?.name ||
              I18n.t("rich_text_editor.choose_product_to_add", { field: "Product Name" })}
          </Typography>
        )}

        {element.data.rating?.show && (
          <div className={classes.rating}>
            <Rating value={parseInt(product?.rating || "0")} precision={0.5} readOnly={true} />
            <Typography className={classNames(classes.ratingText)}>
              {numeral(product?.rating || 0).format("0.0")}
            </Typography>
          </div>
        )}

        {element.data.price?.show && (
          <div className={classes.pricing}>
            <Typography style={{ ...element.data.price.css }} className={classNames(classes.priceDefault)}>
              {product?.pricing?.startingPrice
                ? `${element.data.price.priceCurrency?.symbol || ""}${product.pricing.startingPrice}`
                : I18n.t("rich_text_editor.choose_product_to_add", { field: "Product Price" })}
            </Typography>
          </div>
        )}

        {element.data.button?.show && (
          <ButtonWidgetBaseElement
            className={classes.buttonDefault}
            text={
              product?.callToAction || I18n.t("rich_text_editor.choose_product_to_add", { field: "Call To Action" })
            }
            url={product?.callToActionURL || I18n.t("rich_text_editor.choose_product_to_add", { field: "URL" })}
            css={element.data.button.css}
            hoverCss={element.data.button.css?.["&:hover"] || {}}
            showArrow={element.data?.button.showArrow || false}
            readMode={readMode}
            websiteTheme={websiteTheme}
          />
        )}
      </div>
    </div>
  );
};

export default ProductCTAText;
