import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { IProduct } from "../../../../reducers/constants/objectTypes";
import ModalInputField from "../../components/ModalInputField";
import MTextFieldV2 from "../../../../components/MTextFieldV2";
import ModalDropdownField from "../../components/ModalDropdownField";
import MSelect from "../../../../componentsV2/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    width: "100%",
    height: 25,
  },
}));

type PropTypes = {
  product: IProduct;
  onChange: (product: IProduct) => void;
};

const URLsView = (props: PropTypes) => {
  const classes = useStyles();
  const { product, onChange } = props;

  const callToActionTextOptions = [
    "Add to Card",
    "Book a Demo",
    "Buy",
    "Buy Now",
    "Compare Prices Now",
    "Contact Us",
    "Create Free Account",
    "Download App",
    "Download",
    "Free Download",
    "Get App",
    "Get Betting",
    "Get Instant Quote",
    "Get Quote Now",
    "Get Started",
    "Install App",
    "Install Now",
    "Install for Free",
    "Install",
    "Join Now",
    "Learn More",
    "More Info",
    "Open Account",
    "Open",
    "Request Demo",
    "Schedule a call",
    "See Page",
    "See full profile",
    "Sign Up Now",
    "Sign Up for Free",
    "Start Free Trial",
    "Start Free",
    "Start Now",
    "Start Quiz Now",
    "Start Survey Now",
    "Try on Shopify",
    "Visit Site",
    "Aposte Agora",
    "Acheter maintenant",
    "Comprar ahora",
    "Otvori nalog",
    "Otvori račun",
    "Jetzt kaufen",
  ].map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <>
      <ModalInputField title={I18n.t("product_dialog.urls_view.primary_cta_url")}>
        <MTextFieldV2
          value={product.callToActionURL}
          placeholder={I18n.t("product_dialog.urls_view.type_ph")}
          onValueChange={(callToActionURL) => onChange({ ...product, callToActionURL })}
        />
      </ModalInputField>
      <ModalInputField title={I18n.t("product_dialog.urls_view.primary_cta_text")}>
        <MSelect
          options={callToActionTextOptions}
          value={callToActionTextOptions.find((option) => option.value === product.callToAction)}
          onChange={(option) => onChange({ ...product, callToAction: option.value })}
          placeholder={I18n.t("product_dialog.urls_view.select_ph")}
          optionLabel="label"
          optionValue="value"
          isSearchable
        />
      </ModalInputField>
      <div className={classes.divider}></div>
      <ModalInputField title={I18n.t("product_dialog.urls_view.secondary_cta_url")}>
        <MTextFieldV2
          value={product.link}
          placeholder={I18n.t("product_dialog.urls_view.type_ph")}
          onValueChange={(link) => onChange({ ...product, link })}
        />
      </ModalInputField>
      <ModalInputField title={I18n.t("product_dialog.urls_view.secondary_cta_text")}>
        <MSelect
          options={callToActionTextOptions}
          value={callToActionTextOptions.find((option) => option.value === product.linkText)}
          onChange={(option) => onChange({ ...product, linkText: option.value })}
          placeholder={I18n.t("product_dialog.urls_view.select_ph")}
          optionLabel="label"
          optionValue="value"
          isSearchable
        />
      </ModalInputField>
      <div className={classes.divider}></div>
      <ModalInputField title={I18n.t("product_dialog.urls_view.review_url")}>
        <MTextFieldV2
          value={product.reviewLink}
          placeholder={I18n.t("product_dialog.urls_view.type_ph")}
          onValueChange={(reviewLink) => onChange({ ...product, reviewLink })}
        />
      </ModalInputField>
    </>
  );
};

export default URLsView;
