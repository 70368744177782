import React from "react";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import ClassNames from "classnames";
import { makeStyles, Theme } from "@material-ui/styles";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";
import MSelect from "../../../../../components/MSelect";
import MTextField from "../../../../../components/MTextField";
import theme from "../../../../../themes/theme";
import PageSection from "./PageSection";
import green_frame from "../../../../../img/green_frame.svg";
import { IProposal } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  headersRow: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    gap: 10,
  },
  row: {
    display: "flex",
    alignItems: "center",
    padding: 12,
    gap: 10,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  firstColumn: {
    width: "35%",
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  nameField: {
    margin: 0,
  },
  levelField: {
    margin: 0,
  },
  field: {
    width: "12%",
    margin: 0,
  },
  fieldWrap: {
    width: "12%",
  },
  centered: {
    textAlign: "center",
  },
  statusField: {
    width: "6%",
    margin: 0,
  },
  lastField: {
    width: "2%",
    margin: 0,
    textAlign: "end",
  },
  nameInput: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.pxToRem(14),
  },
  firstHeader: {
    width: "35%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  statusHeader: {
    width: "6%",
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  lastHeader: {
    width: "2%",
    fontSize: theme.typography.pxToRem(14),
    textAlign: "end",
  },
  header: {
    width: "12%",
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selectRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  select: {
    width: 150,
    textTransform: "capitalize",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
    marginRight: 10,
  },
  headersDivider: {
    height: 19,
    margin: "0 10px",
  },
  columnsDivider: {
    height: 27,
    margin: "0 10px",
  },
  trashIcon: {
    color: theme.palette.divider,
    cursor: "pointer",
    transition: "color 0.3s ease",
    "&:hover": {
      color: theme.palette.text.secondary,
    },
  },
  addWrap: {
    display: "flex",
    alignItems: "center",
    marginTop: 12,
    cursor: "pointer",
  },
  addText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    marginLeft: 5,
  },
  addIcon: {
    fontSize: 16,
  },
}));

type PropTypes = {
  proposal: IProposal;

  handleChange: (value) => void;
};

const ContentItems = (props: PropTypes) => {
  const classes = useStyles(props);
  const { proposal, handleChange } = props;

  const addContent = () => {
    const newPrices = proposal.contentLevels.map((level) => ({ contentLevelId: level._id, price: "" }));
    handleChange({
      ...proposal,
      contentItems: [...proposal.contentItems, { name: "", quantity: 0, status: false, prices: newPrices }],
    });
  };

  const deleteContent = (index: number) => {
    const newContentItems = [...proposal.contentItems].filter((item, i) => i !== index);
    handleChange({ ...proposal, contentItems: newContentItems });
  };

  return (
    <PageSection
      title={I18n.t("account.setup.content_items.title")}
      subtitle={I18n.t("account.setup.content_items.subtitle")}
    >
      <div className={classes.selectRow}>
        <Typography variant={"body1"} className={classes.text}>
          {I18n.t("account.setup.content_items.default")}
        </Typography>
        <MSelect
          customClass={classes.select}
          options={proposal.contentLevels}
          value={_.find(proposal.contentLevels, { name: proposal.selectedContentLevel?.name })}
          optionValue={"_id"}
          optionLabel={"name"}
          height={30}
          textColor={theme.palette.text.primary}
          placeholder={I18n.t("account.setup.content_items.select_ph")}
          borderWidth={1}
          borderRadius={3}
          isBold={false}
          handleChange={(value) => {
            handleChange({
              ...proposal,
              selectedContentLevel: value,
            });
          }}
        />
      </div>
      <div className={classes.headersRow}>
        <Typography variant={"subtitle2"} className={classes.firstHeader}>
          {I18n.t("account.setup.content_items.content_item")}
        </Typography>
        {proposal.contentLevels.map((level) => (
          <Typography key={level._id} variant={"subtitle2"} className={classes.header}>
            {level.name}
          </Typography>
        ))}
        <Divider orientation={"vertical"} className={classes.headersDivider} />
        <Typography variant={"subtitle2"} className={classes.header}>
          {I18n.t("account.setup.content_items.default_quantity")}
        </Typography>
        <Typography variant={"subtitle2"} className={ClassNames(classes.statusHeader, classes.centered)}>
          {I18n.t("account.setup.content_items.status")}
        </Typography>
        <Typography variant={"subtitle2"} className={classes.lastHeader} />
      </div>
      {proposal?.contentItems.map((item, i) => (
        <div key={item._id} className={classes.row}>
          <div className={classes.firstColumn}>
            <img src={green_frame} alt={"green-frame"} />
            <MTextField
              borderRadius={3}
              borderColor={theme.palette.divider}
              height={27}
              outlined
              customClass={classes.nameField}
              inputClassName={classes.nameInput}
              fullWidth
              value={item.name}
              onChange={(value) => {
                const newContentItems = [...proposal.contentItems];
                newContentItems[i].name = value;
                handleChange({ ...proposal, contentItems: newContentItems });
              }}
            />
          </div>
          {proposal?.contentLevels.map((level, x) => (
            <div key={level._id} className={classes.fieldWrap}>
              {item?.prices.map((priceItem, y) => {
                if (priceItem.contentLevelId === level._id) {
                  return (
                    <MTextField
                      key={priceItem._id}
                      borderRadius={3}
                      borderColor={theme.palette.divider}
                      height={27}
                      outlined
                      customClass={classes.levelField}
                      inputClassName={classes.input}
                      fullWidth
                      value={numeral(priceItem.price).format("0,0[.]00")}
                      onChange={(value) => {
                        const newPrices = [...item.prices];
                        newPrices[y].price = value;
                        const newContentItems = [...proposal.contentItems];
                        newContentItems[i].prices = newPrices;
                        handleChange({ ...proposal, contentItems: newContentItems });
                      }}
                      startAdornment={"$"}
                    />
                  );
                }
                return <></>;
              })}
            </div>
          ))}
          <Divider orientation={"vertical"} className={classes.columnsDivider} />
          <MTextField
            borderRadius={3}
            borderColor={theme.palette.divider}
            height={27}
            outlined
            customClass={classes.field}
            inputClassName={classes.input}
            fullWidth
            value={item.quantity}
            onChange={(value) => {
              const newContentItems = [...proposal.contentItems];
              newContentItems[i].quantity = numeral(value).value();
              handleChange({ ...proposal, contentItems: newContentItems });
            }}
          />
          <div className={ClassNames(classes.statusField, classes.centered)}>
            <Switch
              checked={item.status}
              onChange={() => {
                const newContentItems = [...proposal.contentItems];
                newContentItems[i].status = !item.status;
                handleChange({ ...proposal, contentItems: newContentItems });
              }}
              size={"small"}
              color="primary"
            />
          </div>
          <div className={classes.lastField}>
            <DeleteOutlineIcon className={classes.trashIcon} onClick={() => deleteContent(i)} />
          </div>
        </div>
      ))}
      <div className={classes.addWrap} onClick={() => addContent()}>
        <AddIcon className={classes.addIcon} />
        <Typography variant={"body1"} className={classes.addText}>
          {I18n.t("account.setup.content_items.add")}
        </Typography>
      </div>
    </PageSection>
  );
};

export default ContentItems;
