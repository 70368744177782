import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";

import { IExpert } from "../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import EducationYear from "./EducationYear";
import AddNewButton from "./AddNewButton";
import useDebounce from "../../../../../hooks/useDebounce";
import ExpertCollapsibleCard from "./ExpertCollapsibleCard";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    borderBottom: `${theme.palette.divider} solid 1px`,
    paddingBottom: 10,
    marginBottom: 30,
    "&:last-child": {
      borderBottom: "none",
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    borderTop: "none",
    paddingTop: 0,
    marginTop: 0,
  },
  positionTitle: {
    fontSize: 16,
  },
  deleteText: {
    fontSize: 14,
    cursor: "pointer",
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
  },
  rowGaps: {
    gap: 10,
  },
  textFieldWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  fieldTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    minWidth: 140,
    marginRight: 15,
  },
  profilePicWrapper: {
    marginRight: 40,
  },
  divider: {
    marginLeft: -30,
    marginRight: -30,
    marginTop: 20,
    marginBottom: 20,
  },
  dropdownWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    marginTop: 10,
    alignItems: "flex-end",
  },
  rowsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

type PropTypes = {
  expert: IExpert;

  handleExpertChange: (expert: IExpert) => void;
};

const emptyCertification = {
  type: "Certification",
  education: null,
  year: null,
  institute: null,
};

const ExpertCertifications = (props: PropTypes) => {
  const classes = useStyles(props);

  const { handleExpertChange } = props;
  const { expert } = props;

  const [certifications, setCertifications] = React.useState(
    expert?.educationAndCertifications?.filter(({ type }) => type === "Certification") || []
  );

  const debouncedEducations = useDebounce(certifications, 500);

  React.useEffect(() => {
    if (debouncedEducations.length === 0) {
      handleExpertChange({ ...expert, educationAndCertifications: [] });
      return;
    }
    handleExpertChange({
      ...expert,
      educationAndCertifications: [
        ...expert.educationAndCertifications.filter(({ type }) => type !== "Certification"),
        ...debouncedEducations.filter(({ education, institute }) => education && institute),
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEducations]);

  const handleItemChange = (item: IExpert["educationAndCertifications"][number], index: number) => {
    if (index === certifications.length) {
      setCertifications([...certifications, item]);
    } else {
      setCertifications(certifications.map((education, i) => (i === index ? item : education)));
    }
  };

  const handleDeleteClick = (index: number) => {
    setCertifications(certifications.filter((_, i) => i !== index));
  };

  return (
    <ExpertCollapsibleCard
      showSwitch
      title={I18n.t("experts.profile.licenses_certifications")}
      checked={expert.visibility.certifications}
      onSwitch={(checked) =>
        handleExpertChange({
          ...expert,
          visibility: {
            ...expert.visibility,
            certifications: checked,
          },
        })
      }
      bottomComponent={
        <AddNewButton
          onClick={() =>
            setCertifications([
              ...certifications,
              ...(certifications.length === 0 ? [emptyCertification, emptyCertification] : [emptyCertification]),
            ])
          }
        >
          {I18n.t("experts.profile.add_accreditation")}
        </AddNewButton>
      }
    >
      <div>
        {[...certifications, ...(certifications.length === 0 ? [emptyCertification] : [])].map((item, index, array) => (
          <div className={classes.container} key={`expert_certification_${index}`}>
            <div className={classes.row}>
              <Typography variant="h4" className={classes.positionTitle}>{`${I18n.t(
                "experts.profile.accreditation"
              )} #${index + 1}`}</Typography>
              {array.length > 1 && (
                <Typography onClick={() => handleDeleteClick(index)} variant="body1" className={classes.deleteText}>
                  {I18n.t("experts.profile.delete")}
                </Typography>
              )}
            </div>
            <div className={classes.rowsContainer}>
              <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
                <TextFieldMaxLength
                  key={`certification_expert${index}_education`}
                  titleAndLengthLocation={"top"}
                  value={item.education}
                  placeholder={I18n.t("experts.profile.write_ph")}
                  title={I18n.t("experts.profile.accreditation")}
                  borderRadius={4}
                  handleValueChange={(education) => {
                    handleItemChange({ ...item, education }, index);
                  }}
                />
              </div>
              <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
                <TextFieldMaxLength
                  key={`certification_expert${index}_institute`}
                  titleAndLengthLocation={"top"}
                  value={item.institute}
                  placeholder={I18n.t("experts.profile.write_ph")}
                  title={I18n.t("experts.profile.issuer")}
                  borderRadius={4}
                  handleValueChange={(institute) => {
                    handleItemChange({ ...item, institute }, index);
                  }}
                />

                <EducationYear
                  year={item.year}
                  title={I18n.t("experts.profile.issue_date")}
                  handleYearChange={(year) => {
                    handleItemChange({ ...item, year }, index);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </ExpertCollapsibleCard>
  );
};

export default ExpertCertifications;
