import * as React from "react";
import { isInteger } from "lodash";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { IWidget, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import SelectedWidgetItem from "../components/SelectedWidgetItem";
import BackgroundPickerWidgetItem from "../items/BackgroundPickerWidgetItem";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import EditWidgetName from "../components/EditWidgetName";

type PropTypes = {
  widget: IWidget;
  selectedWebsite: IWebsite;
  selectedItem: number;

  editBackground: (payload: IWidget["background"]) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  widget: state.widgetBuilder.widget,
  selectedItem: state.widgetBuilder.selectedItem,
});

const mapDispatchToProps = (dispatch) => ({
  editBackground: (background: IWidget["background"]) => dispatch({ type: WIDGET_BUILDER.EDIT_BACKGROUND, background }),
});

const DialogWidgetBuildTab = (props: PropTypes) => {
  const { selectedWebsite, widget, selectedItem } = props;
  const { editBackground } = props;

  const selectedWidgetItem = React.useMemo(() => {
    if (!isInteger(selectedItem)) return null;
    return {
      ...widget.items[selectedItem],
      tempTitle: `${I18n.t(`widgets_builder.widget_editor.${widget.type}.item_title`)}`,
    };
  }, [selectedItem, widget.items, widget.type]);

  return selectedWidgetItem ? (
    <SelectedWidgetItem selectedWidgetItem={selectedWidgetItem}>
      <>
        <EditWidgetName margin="0 0 10px 0" />
        <BackgroundPickerWidgetItem
          background={widget.background}
          defaultColor={selectedWebsite.configurations.theme.palette.secondary.main}
          showCornerRadiusSettings
          onChange={editBackground}
        />
      </>
    </SelectedWidgetItem>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogWidgetBuildTab);
