import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import numeral from "numeral";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import { Avatar } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { useState } from "react";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "../../icons/CheckIcon";
import { eActionIcon, IAutoOutlineWithStrategy, IWebsite } from "../../reducers/constants/objectTypes";
import TrashCanIcon from "../../icons/TrashCanIcon";
import { CONVERSION_POTENTIAL_FUNNEL_STAGE } from "../../helpers/constants";
import CopyToClipboardIcon from "../../icons/CopyToClipboardIcon";
import MTableRow from "../../components/Table/Rows/MTableRow";
import MTableCellText from "../../components/Table/Cells/MTableCellText";
import MTableCellDate from "../../components/Table/Cells/MTableCellDate";
import MTableCellFirst from "../../components/Table/Cells/MTableCellFirst";
import MTableCellNumber from "../../components/Table/Cells/MTableCellNumber";
import MTableCell from "../../components/Table/Cells/MTableCell";
import CopyNameIcon from "../../icons/CopyNameIcon";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $actions": {
        display: "flex",
      },
      "& $hideColumn": {
        display: "none",
        // visibility: "hidden",
      },
      "& $avatar": {
        marginBottom: -1,
        display: "none",
      },
      "& $hoverIconWrapper": {
        display: "flex",
        marginBottom: -1,
      },
      position: "relative",
      zIndex: 1,
    },
    "&:hover $topicWrapper, &:hover + $itemWrapper $topicWrapper": {
      borderTop: "1px solid transparent",
      borderBottom: "1px solid transparent",
    },
  },
  topicWrapper: {
    height: 53,
    width: "100%",
    borderTop: (props: PropTypes) =>
      props.isFirstRow ? "1px solid transparent" : `1px solid ${theme.palette.divider}`,
    borderBottom: (props: PropTypes) =>
      props.isLastRow ? `1px solid ${theme.palette.divider}` : "1px solid transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 5,
    cursor: "pointer",
  },
  topicNameWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 20,
  },
  topicName: {
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
    cursor: "pointer",
    width: "fit-content",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  iconColumn: {
    width: 40,
    display: "flex",
    alignItems: "center",
  },
  iconColumnPh: {
    width: 40,
  },
  firstColumn: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    width: "calc(35% - 25px)",
    position: "relative",
  },
  rowIcon: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
    transition: "color 0.2s ease-in-out",
  },
  column: {
    flexGrow: 1,
    width: "10%",
    flexBasis: 0,
    fontSize: "14px",
    fontWeight: 400,
    paddingRight: 10,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  iconButton: {
    padding: 0,
  },
  moreOptionsColumn: {
    width: 20,
  },
  actions: {},
  writerName: {},
  tooltip: {
    whiteSpace: "pre-wrap",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  selected: {
    backgroundColor: theme.palette.action.hover,
    // borderRadius: 30,
  },
  selectedIcon: {
    fill: theme.palette.primary.contrastText,
  },
  deactiveIcon: {
    fill: theme.palette.warning.main,
  },
  selectedIconWrapper: {
    height: 25,
    minWidth: 25,
    padding: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    display: "flex",
  },
  hoverIconWrapper: {
    height: 25,
    minWidth: 25,
    padding: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#B780FE",
    borderRadius: "50%",
    display: "none",
  },
  createButton: {
    height: 36,
    // maxWidth: 150,
  },
  createButtonArrowIcon: {
    rotate: "180deg",
    fill: theme.palette.primary.contrastText,
  },
  avatar: {
    width: 25,
    height: 25,
    color: theme.palette.common.white,
    backgroundColor: "#808AFE",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  avatarWhenNonSelectable: {
    width: 25,
    height: 25,
    color: theme.palette.common.white,
    backgroundColor: "#808AFE",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  hideColumn: {},
  selectIcon: {
    cursor: "pointer",
    marginRight: 15,
  },
  actionsWrapper: {
    display: "none",
    gap: 15,
    alignItems: "center",
    justifyContent: "flex-end",
    zIndex: 2,
    position: "absolute",
    right: 20,
    backgroundColor: theme.palette.secondary.main,
  },
  rejectIcon: {
    fill: theme.palette.text.secondary,
    cursor: "pointer",
    height: 18,
    width: 18,
  },
}));

type PropTypes = {
  outline: IAutoOutlineWithStrategy;
  openOutlineDialog: (outline: IAutoOutlineWithStrategy, pageTab: "outlines" | "keywords" | "broadKeywords") => void;
  selectedWebsite: IWebsite;

  isFirstRow: boolean;
  isLastRow: boolean;
  updateAutoOutline: (outline: IAutoOutlineWithStrategy) => void;
  deleteAutoOutline: (id: string) => void;
  openFeedbackDialog: (item: IAutoOutlineWithStrategy) => void;
};

const BriefsListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { outline, openOutlineDialog, updateAutoOutline, openFeedbackDialog, deleteAutoOutline } = props;
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(false);

  const getPluralVersion = (count) => (count !== 1 ? "pages" : "page");

  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return () => {
      if (outline?.postTitle?.title || "") {
        navigator.clipboard.writeText(outline.postTitle.title);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();

  const actions = React.useMemo(() => {
    return [
      {
        text: I18n.t("outline.icons.delete_brief"),
        tooltip: I18n.t("outline.icons.delete_brief"),
        onClick: () => deleteAutoOutline(outline._id),
        iconName: eActionIcon.trashCan,
      },
      {
        text: showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title"),
        tooltip: showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title"),
        onClick: () => handleTitleCopy(),
        iconName: eActionIcon.copyToClipboard,
      },
    ];
  }, [outline]);

  const FirstColumn = () => (
    <div
      className={classes.selectIcon}
      onClick={(e) => {
        e.stopPropagation();
        setIsChecked((oldVal) => !oldVal);
      }}
    >
      <div className={classes.hoverIconWrapper} style={isChecked ? { display: "flex" } : null}>
        <CheckIcon className={classes.selectedIcon} />
      </div>
      {!isChecked && <Avatar className={classes.avatar}>{"A"}</Avatar>}
    </div>
  );

  const LastColumns = () => (
    <>
      <MTableCellText
        text={outline.pagesCount ? `${outline.pagesCount} ${getPluralVersion(outline.pagesCount)}` : "-"}
      />
    </>
  );

  return (
    <MTableRow
      handleRowClick={() => {
        openOutlineDialog(outline, "outlines");
      }}
      customClassName={classes.itemWrapper}
    >
      <MTableCellFirst
        columnWidth={"33%"}
        prefix={<FirstColumn />}
        fontSize={theme.typography.pxToRem(14)}
        cellText={isChecked ? outline.keywordGroup || "Missing Title !" : outline.postTitle.title || "Missing Title !"}
        actions={actions}
        actionClassName={classes.actions}
      />

      <MTableCell
        onClick={() => {
          openFeedbackDialog(outline);
        }}
        tooltip={outline?.strategy?.feedback || "Add feedback"}
      >
        {_.capitalize(outline?.strategy?.feedback ? "View" : "Add")}
      </MTableCell>
      <MTableCellDate date={outline.createdAt} />
      <MTableCellText text={_.capitalize(outline.postType || "")} />
      <MTableCellText text={_.capitalize(CONVERSION_POTENTIAL_FUNNEL_STAGE[outline.funnelStage] || "None")} />
      <LastColumns />
    </MTableRow>
  );
};

export default BriefsListItem;
