import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LaunchIcon from "@material-ui/icons/Launch";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  sectionNameAndSwitchWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "13px 30px",
  },
  sectionWrapper: {
    marginBottom: 10,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  pipe: {
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: theme.palette.divider,
    width: 1,
    height: 21,
  },
  rightWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.divider,
  },
  activeIcon: {
    color: theme.palette.text.secondary,
  },
  labelRoot: {
    marginRight: -12,
  },
}));

type PropTypes = {
  active: boolean;
  slug: string;
  handleActiveChange: (active: boolean) => void;
};

const ExpertStatusSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { active, slug } = props;
  const { handleActiveChange } = props;

  return (
    <Paper elevation={0} className={classes.sectionWrapper}>
      <div className={classes.sectionNameAndSwitchWrapper}>
        <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
          {I18n.t("experts.profile.status")}
        </Typography>
        <div className={classes.rightWrapper}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={() => {
                    handleActiveChange(!active);
                  }}
                  color="primary"
                />
              }
              classes={{ root: classes.labelRoot }}
              label=""
            />
          </FormGroup>
          <span className={classes.pipe} />
          <a href={active ? `https://entail.ai/experts/${slug}` : null} target={active ? "_blank" : null}>
            <LaunchIcon onClick={() => {}} className={ClassNames(classes.icon, active && classes.activeIcon)} />
          </a>
        </div>
      </div>
    </Paper>
  );
};

export default ExpertStatusSection;
