import React from "react";
import { I18n } from "react-redux-i18n";
import SelectProperty from "./SelectProperty";
import { eWidgetFieldWidth } from "../../../../../../../../../reducers/constants/objectTypes";

type PropTypes = {
  value?: string;
  onChange?(event: eWidgetFieldWidth): unknown;
};

const WidthProperty = (props: PropTypes) => {
  const { value = eWidgetFieldWidth.Full, onChange } = props;
  return (
    <SelectProperty
      showLabel
      label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.width_property_label")}
      value={value}
      options={[eWidgetFieldWidth.Full, eWidgetFieldWidth.Half, eWidgetFieldWidth.Third, eWidgetFieldWidth["20%"]]}
      onChange={onChange}
    />
  );
};

export default WidthProperty;
