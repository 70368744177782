import moment from "moment";
import { GSC_QUERIES } from "./constants/actionTypes";

const initialState = {
  searchConsoleInterval: "last3Months",
  startDate: moment().subtract(3, "month"),
  endDate: moment(),

  queriesLoaded: false,
  refreshItemList: false,
  queries: [],
  queriesTotal: 0,
  queriesLimit: 500,
  queriesSearchText: "",

  queryGroupLoaded: false,
  queryGroup: null,

  gscQueries: [],
  gscQueriesLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GSC_QUERIES.QUERY_GROUP_LIMIT_CHANGED:
      return {
        ...state,
        queriesLimit: action.limit,
      };
    case GSC_QUERIES.START_LOADING:
      return {
        ...state,
        queriesLoaded: false,
      };
    case GSC_QUERIES.QUERIES_LOADED:
      return {
        ...state,
        queriesLoaded: true,
        data: action.payload.queryGroups,
        queriesTotal: action.payload.total,
      };
    case GSC_QUERIES.QUERY_GROUP_CREATED:
      return {
        ...state,
        data: [action.queryGroup, ...state.queries],
      };
    case GSC_QUERIES.QUERY_CHANGED:
      return {
        ...state,
        data: state.queries.map((query) => {
          if (query._id === action.queryGroup._id) {
            return action.queryGroup;
          }
          return query;
        }),
      };
    case GSC_QUERIES.QUERY_DELETED:
      return {
        ...state,
        data: state.queries.filter((c) => c._id !== action.queryGroup._id),
      };
    case GSC_QUERIES.REFRESH_QUERIES_LIST:
      return {
        ...state,
        refreshItemList: true,
      };
    case GSC_QUERIES.QUERY_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        queriesSearchText: action.searchText,
      };

    case GSC_QUERIES.QUERY_GROUP_LOADED: {
      return {
        ...state,
        queryGroupLoaded: true,
        queryGroup: action.queryGroup,
      };
    }
    case GSC_QUERIES.QUERY_GROUP_CLEANUP:
      return {
        ...state,
        queryGroupLoaded: false,
        queryGroup: null,
      };
    case GSC_QUERIES.QUERY_GROUP_LOADING_QUERIES:
      return {
        ...state,
        gscQueriesLoaded: false,
      };
    case GSC_QUERIES.QUERY_GROUP_QUERIES_LOADED:
      return {
        ...state,
        gscQueries: action.payload.queries,
        gscQueriesLoaded: true,
        refreshItemList: false,
      };
    // case GSC_QUERIES.QUERY_GROUP_QUERIES_CLEANUP:
    //   return {
    //     ...state,
    //     gscQueries: initialState.gscQueries,
    //     gscQueriesLoaded: false,
    //     refreshItemList: true,
    //   }
    case GSC_QUERIES.QUERY_GROUP_SEARCH_CONSOLE_INTERVAL_CHANGED: {
      const endDate = moment();
      let { startDate } = state;
      switch (action.interval) {
        case "today":
          startDate = moment();
          break;
        case "lastWeek":
          startDate = moment().subtract(1, "week");
          break;
        case "last2Weeks":
          startDate = moment().subtract(2, "week");
          break;
        case "lastMonth":
          startDate = moment().subtract(1, "month");
          break;
        case "last2Months":
          startDate = moment().subtract(2, "month");
          break;
        case "last3Months":
          startDate = moment().subtract(3, "month");
          break;
        case "last6Months":
          startDate = moment().subtract(6, "month");
          break;
        case "lastYear":
          startDate = moment().subtract(1, "year");
          break;
        default:
          startDate = moment().subtract(3, "month");
      }
      return {
        ...state,
        searchConsoleInterval: action.interval,
        startDate,
        endDate,
      };
    }
    default:
      return state;
  }
};
