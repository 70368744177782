import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import DropDownIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      color: theme.palette.text.primary,
      position: "absolute !important",
      right: "0 !important",
      pointerEvents: "none !important",
      fontSize: 16,
    },
    label: {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightRegular as any,
      lineHeight: "14.4px",
    },

    selectWrapper: {
      padding: 0,
    },
    select: {
      fontSize: 12,
      paddingRight: "15px !important",
      textAlign: "center",
    },
    menuItemRoot: {
      textTransform: "capitalize",
    },
  };
});

type PropTypes = {
  tags: any[];
  value: string;
  defaultValue?: string;
  handleChange: any;
};

const DropDownBaseComponent = (props: PropTypes) => {
  const classes = useStyles(props);
  const { handleChange, defaultValue, value, tags } = props;

  return (
    <Select
      className={classes.selectWrapper}
      classes={{ select: classes.select }}
      disableUnderline
      onClose={(event) => {
        setTimeout(() => {
          document.activeElement.blur();
        }, 0);
      }}
      IconComponent={() => <DropDownIcon className={classes.icon} />}
      labelId="select-tag"
      id="select-tag"
      value={value || defaultValue}
      onChange={handleChange}
    >
      {tags.map((item) => (
        <MenuItem key={item} value={item} classes={{ root: classes.menuItemRoot }}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};
export default DropDownBaseComponent;
