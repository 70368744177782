import * as React from "react";
import { IWebsite, IWebsiteHomepageProps, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { connect } from "react-redux";
import TopProductsKeepshoppers from "./HomepageComponents/TopProductsKeepshoppers";
import TopSectionKeepshoppers from "./HomepageComponents/TopSectionKeepshoppers";
import TabsSectionKeepshoppers from "./HomepageComponents/TabsSectionKeepshoppers";
import RecommendedPostsKeepshoppers from "./HomepageComponents/RecommendedPostsKeepshoppers";
import ComparisonsKeepshoppers from "./HomepageComponents/ComparisonsKeepshoppers";
import ReviewsKeepshoppers from "./HomepageComponents/ReviewsKeepshoppers";
import BlogPostsKeepshoppers from "./HomepageComponents/BlogPostsKeepshoppers";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});
const mapDispatchToProps = (dispatch) => ({});

type PropTypes = {
  selectedWebsite: IWebsite;
  theme: IWebsiteThemeProps;
  homepage: IWebsiteHomepageProps;
  onPropertyChanged: (value) => void;
};

const HomepageKeepshoppers = (props: PropTypes) => {
  const { selectedWebsite, homepage, theme, onPropertyChanged } = props;
  return (
    <>
      <TopSectionKeepshoppers homepage={homepage} theme={theme} onPropertyChanged={onPropertyChanged} />
      <TabsSectionKeepshoppers homepage={homepage} theme={theme} onPropertyChanged={onPropertyChanged} />
      <TopProductsKeepshoppers
        homepage={homepage}
        selectedWebsite={selectedWebsite}
        onPropertyChanged={onPropertyChanged}
      />
      <RecommendedPostsKeepshoppers homepage={homepage} onPropertyChanged={onPropertyChanged} />
      <ComparisonsKeepshoppers homepage={homepage} onPropertyChanged={onPropertyChanged} />
      <ReviewsKeepshoppers homepage={homepage} onPropertyChanged={onPropertyChanged} />
      <BlogPostsKeepshoppers homepage={homepage} onPropertyChanged={onPropertyChanged} />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageKeepshoppers);
