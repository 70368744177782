import { makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { I18n } from "react-redux-i18n";
import { Transforms } from "slate";
import { find } from "lodash";
import ClearIcon from "@material-ui/icons/Clear";
import ClassNames from "classnames";
import { IFile, IProduct, IWebsite } from "../../../../reducers/constants/objectTypes";
import { Widget } from "../WithWidgets";
import { ProductReview, ProductReviewData } from "../../../types/editor.Types";
import TextAreaWidgetBaseElement from "../baseElements/TextAreaWidgetBaseElement";
import PipeSeparatorIcon from "../../../../icons/PipeSeparatorIcon";
import ArrowRightIcon from "../../../../icons/ArrowRightIcon";
import ButtonWidgetBaseElement from "../baseElements/ButtonWidgetBaseElement";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  placeHolder: (props: PropTypes) => ({
    width: "100%",
    height: 80,
    borderRadius: 4,
    padding: 24,
    border: `solid 1px ${props.website.configurations.theme.palette.divider}`,
  }),
  topWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  rightWrapper: {},
  ctaNumberDefault: {
    fontSize: 40,
    fontWeight: theme.typography.fontWeightBold as any,
    maxWidth: 100,
    color: (props) => props.website.configurations.theme.palette.primary.main,
  },
  ctaNumber: (props: PropTypes) => ({
    ...(props.element.data.productNumber?.css || {}),
  }),
  separatorIcon: {
    stroke: (props) => props.website.configurations.theme.palette.divider,
    margin: "0px 15px",
    height: 41,
  },
  image: {
    width: 101,
    height: 41,
  },
  scoreRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginBottom: 10,
  },
  scoreText: {
    color: "#00B890",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular as any,
    marginRight: 4,
  },
  scoreValue: {
    color: "white",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  scoreBackground: {
    width: 40,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00B890",
    borderRadius: 8,
  },
  imagesWrapper: {
    display: "flex",
    flexDirection: "row",
    height: 310,
    marginTop: 16,
  },
  leftImagesWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  rightImageWrapper: {},
  leftImage: {
    width: 150,
    minHeight: 97,
    marginRight: 10,
    cursor: "pointer",
    borderRadius: 3,
  },
  rightImage: {
    width: "100%",
    height: "100%",
    borderRadius: 3,
  },
  bottomWrapper: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
  },
  textButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  description: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: theme.typography.fontWeightLight as any,
    color: (props: PropTypes) => props.website.configurations.theme.palette.text.secondary,
  },
  reviewLinkWrapper: {
    display: "flex",
    flexDirection: "row",
    color: (props: PropTypes) => props.website.configurations.theme.palette.primary.main,
    gap: 8,
    alignItems: "center",
    cursor: "pointer",
  },
  reviewText: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: theme.typography.fontWeightBold as any,
    textDecoration: "underline",
    color: (props: PropTypes) => props.website.configurations.theme.palette.primary.main,
  },
  buttonDefault: {
    marginLeft: 40,
    minWidth: 133,
    minHeight: 40,
    borderRadius: 34,
  },
  reviewsListWrapper: {
    marginTop: 14,
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  reviewRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  reviewWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  row: {
    display: "flex",
    flexDirection: "row",
  },
  fieldTitle: {
    width: 100,
    marginRight: 10,
    whiteSpace: "nowrap",
    height: 30,
  },
  title: {},
  clearIcon: {
    cursor: "pointer",
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  hidden: {
    visibility: "hidden",
  },
  reviewsTitle: {
    marginTop: 10,
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: ProductReview;
  products: IProduct[];
  website: IWebsite;
};

export const getRawText = (n: Node) => "";

const ProductReviewWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, website, products } = props;
  const editor = useSlateStatic();
  const websiteTheme = website.configurations.theme;
  const handleChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const defaultData: ProductReviewData = {
    button: {
      show: true,
      showArrow: false,
      css: {},
      alignment: "right",
    },
    productNumber: {
      show: true,
      number: "1",
      css: {},
    },
    logo: {
      show: true,
    },
    product: {
      itemNumber: null,
    },
    maxImages: 4,
    reviews: { list: [], show: true },
  };

  const scoreTexts = {
    GOOD: "Good",
    VERY_GOOD: "Very Good",
    EXCELLENT: "Excellent",
  };

  const getScoreText = (sc) => {
    let scoreText;
    const score = parseFloat(sc);
    if (score >= 6 && score < 8) {
      scoreText = scoreTexts.GOOD;
    } else if (score >= 8 && score < 9) {
      scoreText = scoreTexts.VERY_GOOD;
    } else {
      scoreText = scoreTexts.EXCELLENT;
    }
    return scoreText;
  };

  const [rightImage, setRightImage] = React.useState<{ image: IFile; index: number }>({ image: null, index: null });

  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);
  React.useEffect(() => {
    handleChange({ ...defaultData, ...(element.data || {}) });
    setRightImage({ image: product?.imageFiles?.[0], index: 0 });
  }, [product]);
  return (
    <div {...attributes}>
      <Widget title={I18n.t("rich_text_editor.custom_components.product_review")} element={element} editable>
        {!product ? (
          <div className={classes.placeHolder}>
            <span>{I18n.t("rich_text_editor.product_cta.select_product")}</span>
          </div>
        ) : (
          <div className={classes.wrapper}>
            <div className={classes.topWrapper}>
              <div className={classes.leftWrapper}>
                {element?.data?.productNumber?.show && (
                  <TextAreaWidgetBaseElement
                    className={ClassNames(classes.ctaNumberDefault, classes.ctaNumber)}
                    text={element.data.productNumber?.number || ""}
                    placeholder={I18n.t("rich_text_editor.product_review.num_ph")}
                    onChange={(ctaNumber) =>
                      handleChange({
                        ...element.data,
                        productNumber: { ...element.data.productNumber, number: ctaNumber },
                      })
                    }
                  />
                )}

                {element?.data?.productNumber?.show && element?.data?.logo?.show && (
                  <PipeSeparatorIcon className={classes.separatorIcon} />
                )}
                {element?.data?.logo?.show && (
                  <img
                    className={classes.image}
                    src={product?.logoFile?.url || product?.logo}
                    alt={product?.logoFile?.alt}
                  />
                )}
              </div>
              <div className={classes.rightWrapper}>
                <div className={classes.scoreRow}>
                  <span className={classes.scoreText}>{getScoreText(product?.score || "6")}</span>
                  <div className={classes.scoreBackground}>
                    <span className={classes.scoreValue}>{product?.score || "6"}</span>
                  </div>
                </div>
              </div>
            </div>
            {product?.imageFiles?.length > 0 && (
              <div className={classes.imagesWrapper}>
                <div className={classes.leftImagesWrapper}>
                  {product.imageFiles.slice(0, element.data.maxImages).map(
                    (image, index) =>
                      index !== rightImage.index && (
                        <img
                          onClick={() => {
                            setRightImage({ image, index });
                          }}
                          key={`${index}_${image._id}`}
                          src={image.url}
                          alt={image.alt}
                          className={classes.leftImage}
                        />
                      )
                  )}
                </div>
                <div className={classes.rightImageWrapper}>
                  <img
                    key={`${rightImage?.image?._id}`}
                    src={rightImage?.image?.url}
                    alt={rightImage?.image?.alt}
                    className={classes.rightImage}
                  />
                </div>
              </div>
            )}
            <div className={classes.bottomWrapper}>
              <div className={classes.textButtonWrapper}>
                <div className={classes.textWrapper}>
                  <Typography className={classes.description}>{product?.shortDescription}</Typography>
                  <a className={classes.reviewText}>
                    <div className={classes.reviewLinkWrapper}>
                      <Typography>
                        {I18n.t("rich_text_editor.product_review.review_link", { name: product?.name || "This" })}
                      </Typography>
                      <ArrowRightIcon />
                    </div>
                  </a>
                </div>
                <ButtonWidgetBaseElement
                  className={classes.buttonDefault}
                  text={
                    product?.callToAction ||
                    I18n.t("rich_text_editor.choose_product_to_add", { field: "Call To Action" })
                  }
                  url={product?.callToActionURL || I18n.t("rich_text_editor.choose_product_to_add", { field: "URL" })}
                  css={element.data.button.css}
                  hoverCss={element.data.button.css?.["&:hover"] || {}}
                  showArrow={element.data?.button.showArrow || false}
                  readMode={false}
                  websiteTheme={websiteTheme}
                />
              </div>
              {element?.data?.reviews?.show && (
                <div className={classes.reviewsListWrapper}>
                  <Typography className={classes.reviewsTitle} variant={"subtitle1"}>
                    {I18n.t("rich_text_editor.product_review.reviews")}
                  </Typography>
                  {[...(element.data.reviews.list || []), { title: "", description: "" }].map((review, index) => (
                    <div className={classes.reviewRow}>
                      <div key={`review_${index}`} className={classes.reviewWrapper}>
                        <div className={classes.row}>
                          <Typography variant={"body1"} className={classes.fieldTitle}>
                            {I18n.t("rich_text_editor.product_review.title", { number: index + 1 })}
                          </Typography>
                          <TextAreaWidgetBaseElement
                            className={classes.title}
                            text={review.title}
                            // style={element.data.question?.css}
                            placeholder={I18n.t("rich_text_editor.product_review.title_ph")}
                            onChange={(title) => {
                              const newReviews = [...(element.data.reviews.list || [])];
                              if (index >= newReviews.length) {
                                newReviews.push({ title, description: review.description });
                              } else {
                                newReviews[index] = { title, description: review.description };
                              }
                              handleChange({
                                ...element.data,
                                reviews: { ...element.data?.reviews, list: newReviews },
                              });
                            }}
                          />
                        </div>
                        <div className={classes.row}>
                          <Typography variant={"body1"} className={classes.fieldTitle}>
                            {I18n.t("rich_text_editor.product_review.description", { number: index + 1 })}
                          </Typography>
                          <TextAreaWidgetBaseElement
                            className={classes.title}
                            text={review.description}
                            placeholder={I18n.t("rich_text_editor.product_review.description_ph")}
                            onChange={(description) => {
                              const newReviews = [...(element.data.reviews.list || [])];
                              if (index >= newReviews.length) {
                                newReviews.push({ title: review.title, description });
                              } else {
                                newReviews[index] = { title: review.title, description };
                              }
                              handleChange({ ...element.data, reviews: { ...element.data.reviews, list: newReviews } });
                            }}
                          />
                        </div>
                      </div>
                      <ClearIcon
                        className={ClassNames(
                          classes.clearIcon,
                          [...(element.data.reviews.list || [])].length === index && classes.hidden
                        )}
                        onClick={() => {
                          const newReviews = [...element.data.reviews.list].filter((x, i) => index !== i);
                          handleChange({ ...element.data, reviews: { ...element.data.reviews, list: newReviews } });
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {children}
      </Widget>
    </div>
  );
};

export default ProductReviewWidget;
