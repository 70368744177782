import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { web } from "../../helpers/urlHelper";
import Write from "./Write/Write";
import AllStates from "./AllStates/AllStates";
import Edit from "./Edit/Edit";
import FactCheck from "./FactCheck/FactCheck";
import Statistics from "./Statistics/Statistics";
import { AbilityContext } from "../../casl/can";
import { IWebsite } from "../../reducers/constants/objectTypes";
import ReviewStep from "./ReviewStep/ReviewStep";
import Published from "./Published/Published";
import Mapping from "../Mapping/Mapping";
import Briefs from "../Briefs/Briefs";
import SecondEdit from "./SecondEdit/SecondEdit";
import Settings from "../Settings/Settings";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});
type PropTypes = {
  selectedWebsite: IWebsite;
};

const WorkFlow = (props: PropTypes) => {
  const { selectedWebsite } = props;
  const ability = React.useContext<any>(AbilityContext);

  const routes = React.useMemo(() => {
    const allRoutes = [];
    if (ability.can("view", "cms_all_states_tab")) {
      allRoutes.push({
        url: web.workflowAllStates(),
        route: (
          <Route
            key={`cms_${selectedWebsite._id}_all`}
            exact={false}
            path={web.workflowAllStates()}
            component={() => <AllStates />}
          />
        ),
      });
    }

    if (ability.can("view", "cms_published_tab")) {
      allRoutes.push({
        url: web.workflowPublished(),
        route: (
          <Route
            key={`cms_${selectedWebsite._id}_published`}
            exact={false}
            path={web.workflowPublished()}
            component={() => <Published />}
          />
        ),
      });
    }
    if (ability.can("view", "cms_writer_tab")) {
      allRoutes.push({
        url: web.workflowWrite(),
        route: (
          <Route
            key={`cms_${selectedWebsite._id}_write`}
            exact={false}
            path={web.workflowWrite()}
            component={() => <Write />}
          />
        ),
      });
    }
    if (ability.can("view", "cms_editor_tab")) {
      allRoutes.push({
        route: (
          <Route
            key={`cms_${selectedWebsite._id}_edit`}
            exact={false}
            path={web.workflowEdit()}
            component={() => <Edit />}
          />
        ),
        url: web.workflowEdit(),
      });
      if (selectedWebsite.secondEditStep) {
        allRoutes.push({
          route: (
            <Route
              key={`cms_${selectedWebsite._id}_second_edit`}
              exact={false}
              path={web.workflowSecondEdit()}
              component={() => <SecondEdit />}
            />
          ),
          url: web.workflowSecondEdit(),
        });
      }
    }
    if (ability.can("view", "cms_fact_check_tab")) {
      allRoutes.push({
        url: web.workflowFactCheck(),
        route: (
          <Route
            key={`cms_${selectedWebsite._id}_fact-check`}
            exact={false}
            path={web.workflowFactCheck()}
            component={() => <FactCheck />}
          />
        ),
      });
      allRoutes.push({
        url: web.workflowReviewStep(":step"),
        route: (
          <Route
            key={`cms_${selectedWebsite._id}_review_step`}
            exact={false}
            path={web.workflowReviewStep(":step")}
            component={() => <ReviewStep />}
          />
        ),
      });
    }
    if (ability.can("view", "cms_mapping_tab")) {
      allRoutes.push({
        url: web.workflowMapping(),
        route: (
          <Route
            key={`cms_${selectedWebsite._id}_strategy`}
            exact={false}
            path={web.workflowMapping()}
            component={() => <Mapping />}
          />
        ),
      });
    }
    if (ability.can("view", "cms_briefs_tab")) {
      allRoutes.push({
        url: web.workflowBriefs(),

        route: (
          <Route
            key={`cms_${selectedWebsite._id}_briefs`}
            exact={false}
            path={web.workflowBriefs()}
            component={() => <Briefs />}
          />
        ),
      });
    }
    /*    if (ability.can("view", "cms_outline_review_tab")) {
      allRoutes.push({
        url: web.workflowOutlines(":outlineId"),
        route: (
          <Route
            exact={false}
            key={`cms_${selectedWebsite._id}_outline_editor`}
            path={web.workflowOutlines(":outlineId")}
            component={() => <OutlineEditor view={"EDITOR_VIEW"} />}
          />
        ),
      });
      allRoutes.push({
        url: web.workflowOutlines(),
        route: (
          <Route
            exact={false}
            key={`cms_${selectedWebsite._id}_outlines`}
            path={web.workflowOutlines()}
            component={() => (
              <OutlineList metaTitle={I18n.t(`meta_tags.article_cms.outline.title`)} view={"EDITOR_VIEW"} />
            )}
          />
        ),
      });
    }*/
    if (ability.can("view", "site_stats_tab")) {
      allRoutes.push({
        url: web.workflowSiteStats(),
        route: (
          <Route
            key={`cms_${selectedWebsite._id}_site_stats`}
            exact={false}
            path={web.workflowSiteStats()}
            component={() => <Statistics />}
          />
        ),
      });
    }
    if (ability.can("view", "cms_settings_tab")) {
      allRoutes.push({
        url: web.workflowSettings(),
        route: (
          <Route
            key={`cms_${selectedWebsite._id}_settings`}
            exact={false}
            path={web.workflowSettings()}
            component={() => <Settings />}
          />
        ),
      });
    }
    return allRoutes;
  }, [selectedWebsite, ability]);

  return (
    <Switch>
      {routes.map((route) => route.route)}
      <Redirect exact from={"*"} to={routes[0]?.url} />
    </Switch>
  );
};

export default connect(mapStateToProps)(WorkFlow);
