import { WORKFLOW_PUBLISHED_CLUSTER } from "./constants/actionTypes";

const initialState = {
  loaded: false,
  refreshItemList: false,
  pagesLoaded: false,
  items: [],
  paginationPagesCount: 0,
  filter: {
    searchText: "",
    postType: [],
    category: [],
    publishStatus: null,
    sortField: {
      direction: "desc",
      field: "publishedDate",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WORKFLOW_PUBLISHED_CLUSTER.LOADED:
      return {
        ...state,
        loaded: true,
        refreshItemList: false,
        items: action.payload.pageClusters,
        paginationPagesCount: action.payload.paginationPagesCount,
        pagesCount: action.payload.pagesCount,
        pagesLoaded: true,
      };
    case WORKFLOW_PUBLISHED_CLUSTER.REFRESH:
      return {
        ...state,
        refreshItemList: true,
      };
    case WORKFLOW_PUBLISHED_CLUSTER.WORKFLOW_PUBLISHED_CLUSTER_ON__FILTER_CHANGED: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
