import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import ClassNames from "classnames";
import { useSlate } from "slate-react";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import { HistoryEditor } from "slate-history/dist/history-editor";
import UndoIcon from "../../../icons/UndoIcon";
import RedoIcon from "../../../icons/RedoIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      fontSize: 16,
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
    activeIcon: {
      fill: theme.palette.primary.main,
    },
    image: {},
    focused: {
      boxShadow: "0 0 0 3px #B4D5FF",
    },
    imageDetailsWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    imageSizeWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    sizeField: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold as any,
    },
    imageWrapper: {
      display: "flex",
      position: "relative",
    },
    floatingBoard: {
      position: "absolute",
      right: 0,
      left: 0,
      background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%);",
    },
  })
);

type propTypes = {
  undoButton: boolean;
};

const UndoRedoButton = (props: propTypes) => {
  const classes = useStyles(props);
  const { undoButton } = props;
  const editor = useSlate();
  const { undos, redos } = (editor as HistoryEditor).history;
  return (
    <div style={{ display: "contents" }}>
      <Tooltip enterDelay={2000} title={I18n.t(`rich_text_editor.tooltips.${undoButton ? "undo" : "redo"}`)}>
        <span>
          <IconButton
            id={`${undoButton ? "undo" : "redo"}_icon_button`}
            className={classes.button}
            disableRipple
            disabled={(undoButton && undos.length === 0) || (!undoButton && redos.length === 0)}
            onClick={(event) => {
              event.preventDefault();
              console.log((editor as HistoryEditor).history);
              if (undoButton) {
                (editor as HistoryEditor).undo();
              } else {
                (editor as HistoryEditor).redo();
              }
            }}
          >
            {undoButton && <UndoIcon className={ClassNames(classes.icon)} />}
            {!undoButton && <RedoIcon className={ClassNames(classes.icon)} />}
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default UndoRedoButton;
