import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import MButton from "../../../../../components/MButton";
import Divider from "@material-ui/core/Divider";
import DownChevronIcon from "../../../../../icons/DownChevronIcon";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import { useSnackbar } from "notistack";
import { copyStringToClipboard } from "../../../../../helpers/fbScraperHelper";
import wordpress_instruction from "../../../../../img/wordpress_instruction.png";
import gmt_instruction from "../../../../../img/gmt_instruction.png";
import shopify_instruction_1 from "../../../../../img/shopify_instruction_1.png";
import shopify_instruction_2 from "../../../../../img/shopify_instruction_2.png";
import shopify_instruction_3 from "../../../../../img/shopify_instruction_3.png";
import shopify_instruction_4 from "../../../../../img/shopify_instruction_4.png";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    width: "100%",
  },
  topWrapper: {
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: 0,
    zIndex: 100,
    gap: 10,
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: "0 24px",
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    justifyContent: "space-between",
  },
  headerLeftSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "30px 24px 0px",
    width: "100%",
  },
  text: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  codeRow: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    width: "100%",
  },
  scriptWrapper: {
    backgroundColor: "#EEF2F9",
    border: `1px solid ${theme.palette.divider}`,
    padding: 15,
    borderRadius: 3,
    flex: 1,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    height: 40,
  },
  script: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: 14,
    textOverflow: "ellipsis",
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "21px",
  },
  copyButton: {
    padding: "15px 30px",
    height: 40,
    borderRadius: 20,
    textTransform: "none",
    visibility: "visible",
  },
  hideTopCopyButton: {
    visibility: "hidden",
  },
  divider: {
    margin: "20px 0px",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: 0,
    paddingInlineStart: 25,
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  listText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "25px",
  },
  showMore: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    cursor: "pointer",
    color: theme.palette.primary.main,
    gap: 5,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  showLessIcon: {
    transform: "rotate(180deg)",
  },

  icon: {},
  arrowBack: {
    cursor: "pointer",
  },
  image: {
    width: "100%",
    height: "auto",
  },
}));

type PropTypes = {
  handleClose: () => void;
  entailToken: string;
  site: String;
  handleCopyScript: () => void;
};

const WebsiteInstruction = (props: PropTypes) => {
  const classes = useStyles(props);

  const { entailToken, site, handleClose, handleCopyScript } = props;

  const [showMore, setShowMore] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const script = ` < script > !function(t, e) {
    var o, n, p, r;
    e.__SV || (window.entailInsights = e, e._i = [], e.init = function(i, s, a) {
      function g(t, e) {
        var o = e.split(".");
        2 == o.length && (t = t[o[0]], e = o[1]), t[e] = function() {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
        }
      }

      (p = t.createElement("script")).type = "text/javascript", p.async = !0, p.src = "https://cdn.entail-insights.com/js/entail.js", (r = t.getElementsByTagName("script")[0]).parentNode.insertBefore(p, r);
      var u = e;
      for (void 0 !== a ? u = e[a] = [] : a = "entailInsights", u.toString = function(t) {
        var e = "entailInsights";
        return "entailInsights" !== a && (e += "." + a), t || (e += " (stub)"), e
      }, o = "trackEvent capture opt_out_capturing has_opted_out_capturing opt_in_capturing reset".split(" "), n = 0; n < o.length; n++) g(u, o[n]);
      e._i.push([i, s, a])
    }, e.__SV = 1)
  }(document, window.entailInsights || []);
  entailInsights.init('${entailToken ? entailToken : "ENTAIL_TOKEN"}', { disable_decide: false });
</script>`;

  const shopify = {
    name: "Shopify",
    description: "These instructions explain how to edit templates via Shopify admin interface.",
    list: [
      {
        text: "Copy your code snippet above",
      },
      {
        text: "Login to your Shopify dashboard",
      },
      {
        text: "Go to 'Online Store' -> 'Themes' (see image below)",
        image: shopify_instruction_1,
      },
      {
        text: "On your theme, click 'Actions' -> 'Edit code' (see image below)",
        image: shopify_instruction_2,
      },
      {
        text: "You should now be seeing a code editor. Click on 'theme.liquid' under 'Layout' on the left sidebar (see image below)",
        image: shopify_instruction_3,
      },
      {
        text: "Navigate until you see the closing </head> tag. Paste your snippet there, before that tag, like in the image below:",
        image: shopify_instruction_4,
      },
      {
        text: "Click the  save button at the top right, and you're good to go. Entail.JS should now be capturing events on your Shopify store!",
      },
    ],
  };

  const wordPress = {
    name: "WordPress",
    description: "These instructions explain how to edit templates via WordPress's admin interface.",
    list: [
      {
        text: "Copy your code snippet above",
      },
      {
        text: "Login to your WordPress admin dashboard",
      },
      {
        text: "Go to 'Appearance' -> 'Theme Editor'",
      },
      {
        text: "Select your theme in the editor drop-down menu to the right and click the header.php file in the file column to the right (see image below)",
        image: wordpress_instruction,
      },
      {
        text: "You should now see the contents of the header.php template file in the code editing view. You should copy all the text/code and save it somewhere as a backup.",
      },
      {
        text: "Find the closing </head> in the code editor and paste the Entail.JS snippet before it (see the above image).",
      },
      {
        text: "Click the 'Update File' button at the bottom. You're good to go - Entail.JS Should now be working on your WordPress website!",
      },
    ],
  };

  const gtm = {
    name: "Google Tag Manager",
    description: "To add Entail.JS to your website using Google Tag Manager, follow these steps:",
    list: [
      {
        text: "Copy your code snippet above",
      },
      {
        text: "Access your Google Tag Manager dashboard and navigate to the desired account/container \n that is integrated with the website you want to add Entail.JS",
      },
      {
        text: "Click to add a new tag:",
        image: gmt_instruction,
      },
      {
        text: "On the page to configure a new tag, add your Entail.JS snippet as a 'Custom HTML Tag' under 'Tag Configuration'.",
      },
      {
        text: "For the trigger, select the default \"All Pages—Page View\" trigger and then click 'Save' at the top right corner of the drawer.",
      },
      {
        text: "Back on the main dashboard, click 'Submit' to update your website with the new Entail.JS tag.",
      },
      {
        text: "You're done! Entail.JS is now configured for your website.",
      },
    ],
  };

  const anyWebsite = {
    name: "Any Website",
    description: "To add Entail.JS to your website, follow the steps below:",
    list: [
      {
        text: "Copy your code snippet above",
      },
      {
        text: "Add the script  within the <head> tags at the base of your product - ideally just before the closing </head> tag. ",
      },

      {
        text: "Once the snippet is added, Entail.JS automatically captures page views.",
      },
    ],
  };

  const webFlow = {
    name: "WebFlow",
    description: "To add Entail.JS to your WebFlow website, follow the steps below:",
    list: [
      {
        text: "Copy your code snippet above",
      },
      {
        text: 'With the snippet copied, go to your WebFlow site settings by clicking on the menu icon at the top left. If you haven’t already, sign up for the "Basic" site plan, which enables you to add custom code.',
      },
      {
        text: 'Go to the "Custom code" tab in site settings. In the "Head code" section, paste your Entail.JS snippet and press "Save". Then, publish your site.',
      },
      {
        text: "You're done! Entail.JS is now configured for your WebFlow website.",
      },
    ],
  };

  const siteInstructions = React.useMemo(() => {
    switch (site) {
      case "shopify":
        return shopify;
      case "wordpress":
        return wordPress;
      case "gtm":
        return gtm;
      case "web_flow":
        return webFlow;
      default:
        return anyWebsite;
    }
  }, [site]);

  const getSiteInstruction = () => {};

  /// copy the code snippet
  const copyScript = () => {
    handleCopyScript();
    copyStringToClipboard(script, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard")));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.topWrapper}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerLeftSide}>
            <ArrowBackIcon className={classes.arrowBack} onClick={handleClose} />
            <Typography className={classes.title}>{I18n.t(`onboarding.integration.sites.${site}`)}</Typography>
          </div>

          <MButton
            onClick={copyScript}
            customClassNames={ClassNames(classes.copyButton, showMore && classes.hideTopCopyButton)}
          >
            {"Copy script"}
          </MButton>
        </div>
      </div>
      <div className={ClassNames(classes.details)}>
        <Typography className={classes.text}>{siteInstructions?.description}</Typography>
        <div className={classes.codeRow}>
          <div className={classes.scriptWrapper}>
            <Typography className={classes.script}>{script}</Typography>
          </div>
          {showMore && (
            <MButton onClick={copyScript} customClassNames={classes.copyButton}>
              {"Copy script"}
            </MButton>
          )}
        </div>
        <Divider className={classes.divider} />
        <Typography className={classes.subTitle}>{"Instructions"}</Typography>
        <ol className={classes.list}>
          {[...(showMore ? siteInstructions.list.slice(0, 4) : siteInstructions.list)].map((instruction, index) => (
            <div className={classes.listItem} key={`key_index_${index}`}>
              <li className={classes.listText}>
                <Typography className={classes.text}>{instruction.text}</Typography>
              </li>
              {!showMore && instruction?.image && (
                <img className={classes.image} src={instruction.image} alt={instruction.text} />
              )}
            </div>
          ))}
        </ol>
        {siteInstructions?.list.length > 4 && (
          <div
            className={classes.showMore}
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            <Typography>{showMore ? "Show more" : "Show less"}</Typography>
            <DownChevronIcon className={ClassNames(classes.icon, !showMore && classes.showLessIcon)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WebsiteInstruction;
