import * as React from "react";
import numeral from "numeral";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { RadioButtonChecked } from "@material-ui/icons";
import Radio from "@material-ui/core/Radio/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import MButton from "../../../../../components/MButton";
import agent from "../../../../../agent";
import { IProposal, IWebsite } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  rightSideWrap: {
    width: "100%",
    maxWidth: 350,
    display: "flex",
    padding: "40px 0",
  },
  paper: {
    width: "100%",
    height: "fit-content",
    // maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    padding: 30,
    gap: 20,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    padding: "10px 0",
    borderBottom: `solid 1px ${theme.palette.text.secondary}`,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight as any,
  },
  leftSideText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight as any,
    marginRight: 10,
  },
  contentWrap: {
    display: "flex",
    flexDirection: "column",
  },
  firstRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  radioButtonsRow: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  totalCostWrap: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0 0",
    borderTop: `solid 1px ${theme.palette.text.secondary}`,
  },
  button: {
    width: "100%",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: "12px 30px",
    borderRadius: 75,
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  blueLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 3,
    color: "#2787BD",
    backgroundColor: "#C9ECFF",
    border: "solid 1px #7ACFFF",
    fontSize: theme.typography.pxToRem(14),
    textAlign: "center",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  proposal: IProposal;
  handleChange: (value) => void;
};

const TotalCost = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, proposal, handleChange } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRadioButton, setSelectedRadioButton] = React.useState("annual");

  const getPlan = (value) => {
    let plan = proposal?.plans[0];
    for (let i = 0; i < proposal?.plans.length; i += 1) {
      if (value >= numeral(proposal?.plans[i].price).value()) {
        plan = proposal?.plans[i];
      }
    }
    return plan;
  };

  const totalValue = proposal?.contentItems.reduce(
    (accCost, item) =>
      accCost +
      numeral(item.prices.find((p) => p.contentLevelId === proposal.selectedContentLevel?._id)?.price).value() *
        item.quantity,
    0
  );
  const plan = getPlan(totalValue);
  const planDiscount = numeral(plan.discount).value() / 100;
  const planDiscountValue = totalValue * planDiscount;
  const subTotal = totalValue - planDiscountValue;
  const annualDiscount = numeral(proposal.annualDiscount).value() / 100;
  const annualDiscountValue = subTotal * annualDiscount;
  const total = subTotal - annualDiscountValue;

  const save = () => {
    agent.Proposals.update(selectedWebsite, proposal)
      .then((res) => {
        handleChange(res.proposal);
        enqueueSnackbar(I18n.t(`snackbar.update_success`));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  return (
    <div className={classes.rightSideWrap}>
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.radioButtonsRow}>
          <FormControlLabel
            label={<strong>{I18n.t("account.total_cost.annual")}</strong>}
            control={
              <Radio
                disableRipple
                className={classes.root}
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonChecked fontSize="small" />}
                color="primary"
                checked={selectedRadioButton === "annual"}
                onChange={() => setSelectedRadioButton("annual")}
              />
            }
          />
          <FormControlLabel
            label={<strong>{I18n.t("account.total_cost.monthly")}</strong>}
            control={
              <Radio
                disableRipple
                className={classes.root}
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonChecked fontSize="small" />}
                color="primary"
                checked={selectedRadioButton === "monthly"}
                onChange={() => setSelectedRadioButton("monthly")}
              />
            }
          />
        </div>
        {selectedRadioButton === I18n.t("account.total_cost.annual").toLowerCase() && (
          <Typography variant={"body1"} className={classes.blueLine}>
            {I18n.t("account.total_cost.text1")}
            {numeral(annualDiscountValue * 12).format("$0,0")}
            {I18n.t("account.total_cost.text2")}
          </Typography>
        )}
        <Typography variant={"body1"} className={classes.title}>
          {`${plan?.name || I18n.t("account.total_cost.no")} plan`}
        </Typography>
        <div className={classes.contentWrap}>
          <div className={classes.row}>
            <Typography variant={"body1"} className={classes.subtitle}>
              {I18n.t("account.total_cost.content")}
            </Typography>
            <Typography variant={"body1"} className={classes.subtitle}>
              {I18n.t("account.total_cost.qty")}
            </Typography>
          </div>
          {proposal?.contentItems.map((contentItem, i) => (
            <div key={contentItem._id}>
              {contentItem.status && contentItem.quantity > 0 && (
                <div className={classes.row}>
                  <Typography variant={"body1"} className={classes.leftSideText}>
                    {contentItem.name}
                  </Typography>
                  <Typography variant={"body1"} className={classes.text}>
                    {contentItem.quantity}
                  </Typography>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={classes.totalCostWrap}>
          <div className={classes.row}>
            <Typography variant={"body1"} className={classes.subtitle}>
              {I18n.t("account.total_cost.total_value")}
            </Typography>
            <Typography variant={"body1"} className={classes.subtitle}>
              {numeral(totalValue).format("$0,0[.]00")}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant={"body1"} className={classes.leftSideText}>
              {`${I18n.t("account.total_cost.discount")} (${numeral(planDiscount).format("0%")})`}
            </Typography>
            <Typography variant={"body1"} className={classes.text}>
              {"-"}
              {numeral(planDiscountValue).format("$0,0[.]00")}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant={"body1"} className={classes.subtitle}>
              {I18n.t("account.total_cost.sub_total")}
            </Typography>
            <Typography variant={"body1"} className={classes.subtitle}>
              {numeral(subTotal).format("$0,0[.]00")}
            </Typography>
          </div>
          {selectedRadioButton === I18n.t("account.total_cost.annual").toLowerCase() && (
            <>
              <div className={classes.row}>
                <Typography variant={"body1"} className={classes.leftSideText}>
                  {`${I18n.t("account.total_cost.annual_discount")} (${numeral(annualDiscount).format("0%")})`}
                </Typography>
                <Typography variant={"body1"} className={classes.text}>
                  {"-"}
                  {numeral(annualDiscountValue).format("$0,0[.]00")}
                </Typography>
              </div>
              <div className={classes.row}>
                <Typography variant={"body1"} className={classes.subtitle}>
                  {I18n.t("account.total_cost.total")}
                </Typography>
                <Typography variant={"body1"} className={classes.subtitle}>
                  {numeral(total).format("$0,0[.]00")}
                </Typography>
              </div>
            </>
          )}
        </div>
        <MButton customClassNames={classes.button} onClick={() => save()}>
          {I18n.t("account.total_cost.save_plan")}
        </MButton>
      </Paper>
    </div>
  );
};

export default TotalCost;
