import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { Switch } from "@material-ui/core";
import { IExpert } from "../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import ExpertCollapsibleCard from "./ExpertCollapsibleCard";

const useStyles = makeStyles((theme: Theme) => ({
  innerWrapper: {
    padding: 30,
  },
  rowWrapper: {
    flexWrap: "wrap",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    "&:first-child": {
      marginTop: 0,
    },
  },
  rowGaps: {
    rowGap: 10,
    columnGap: 50,
  },
  socialWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    "& .MuiSwitch-sizeSmall": {
      marginLeft: 10,
    },
  },
  socialInnerWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  socialTitle: {
    marginRight: 10,
    marginBottom: 5,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
}));

type SocialProfilePropTypes = {
  url: string;
  active: boolean;
  title: string;

  handleUrlChange: (url: string) => void;
  handleActiveChange: (active: boolean) => void;
};
const SocialProfile = (props: SocialProfilePropTypes) => {
  const classes = useStyles(props);
  const { url, active, handleUrlChange, handleActiveChange, title } = props;
  return (
    <div className={classes.socialWrapper}>
      <Typography className={classes.socialTitle} variant={"subtitle2"}>
        {title}
      </Typography>
      <div className={classes.socialInnerWrapper}>
        <TextFieldMaxLength
          key={`expert_social_${title}`}
          titleAndLengthLocation={"top"}
          value={url}
          placeholder={I18n.t("experts.profile.write_ph")}
          borderRadius={4}
          handleValueChange={handleUrlChange}
        />
        <Switch size={"small"} color="primary" checked={active} onChange={() => handleActiveChange(!active)} />
      </div>
    </div>
  );
};

type PropTypes = {
  expert: IExpert;

  handleExpertChange: (expert: IExpert) => void;
};
const SocialProfiles = (props: PropTypes) => {
  const classes = useStyles(props);
  const { expert, handleExpertChange } = props;

  const socialProfiles = ["linkedin", "twitter", "facebook", "instagram", "youtube", "tiktok"].map(
    (social) =>
      (expert.socialLinks || []).find((socialLink) => socialLink.type === social) || {
        type: social,
        url: "",
        active: false,
      }
  );

  return (
    <ExpertCollapsibleCard
      showSwitch
      title={I18n.t("experts.profile.social_profiles")}
      checked={expert.visibility.socialLinks}
      onSwitch={(checked) =>
        handleExpertChange({
          ...expert,
          visibility: {
            ...expert.visibility,
            socialLinks: checked,
          },
        })
      }
    >
      <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
        {socialProfiles.map((socialProfile) => (
          <SocialProfile
            key={socialProfile.type}
            url={socialProfile.url}
            active={socialProfile.active}
            title={I18n.t(`experts.profile.${socialProfile.type}_url`)}
            handleUrlChange={(url) => {
              const socialLinks = [...(expert.socialLinks || [])];
              const socialLink = socialLinks.find((sL) => sL.type === socialProfile.type);
              if (socialLink) {
                socialLink.url = url;
                socialLink.active = true;
              } else {
                socialLinks.push({
                  type: socialProfile.type,
                  url,
                  active: true,
                });
              }
              handleExpertChange({
                ...expert,
                socialLinks,
              });
            }}
            handleActiveChange={(active) => {
              const socialLinks = [...(expert.socialLinks || [])];
              const socialLink = socialLinks.find((sL) => sL.type === socialProfile.type);
              if (socialLink) {
                socialLink.active = active;
              } else {
                socialLinks.push({
                  type: socialProfile.type,
                  url: socialProfile.url,
                  active,
                });
              }
              handleExpertChange({
                ...expert,
                socialLinks,
              });
            }}
          />
        ))}
      </div>
    </ExpertCollapsibleCard>
  );
};

export default SocialProfiles;
