import { ANSWERS } from "./constants/actionTypes";
import { ePostType } from "./constants/objectTypes";

const initialState = {
  postsLoaded: false,
  refreshPostList: false,
  targeted: {},
  targetedTranslations: {},
  blog: {},
  blogTranslations: {},
  learningCenter: {},
  learningCenterTopics: {},
  community: {},
  comparison: {},
  productPages: {},
  productReviews: {},
  productAlternatives: {},
  productComparisons: {},
  customPosts: {},
  tags: {},
  publicPosts: [],

  pending: [],
  inProgress: [],
  // inProgressByOthers: [],
  rejected: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ANSWERS.POSTS_LOADED:
      return {
        ...state,
        postsLoaded: true,
        refreshPostList: false,

        inProgress: [].concat.apply(
          [],
          [
            action.payload.community.personal.map((p) => ({ ...p, postType: ePostType.COMMUNITY })),
            action.payload.targeted.personal.map((p) => ({ ...p, postType: ePostType.TARGETED })),
            action.payload.targetedTranslations.personal.map((p) => ({ ...p, postType: "TARGETED_TRANSLATION" })),
            action.payload.blog.personal.map((p) => ({ ...p, postType: ePostType.BLOG })),
            action.payload.blogTranslations.personal.map((p) => ({ ...p, postType: "BLOG_TRANSLATION" })),
            action.payload.learningCenter.personal.map((p) => ({ ...p, postType: ePostType.LEARNING_CENTER })),
            action.payload.tags.personal.map((p) => ({ ...p, postType: ePostType.TOPIC })),
            action.payload.learningCenterTopics.personal.map((p) => ({ ...p, postType: "LEARNING_CENTER_TOPIC" })),
            action.payload.comparison.personal.map((p) => ({ ...p, postType: ePostType.COMPARISON })),
            action.payload.productPages.personal.map((p) => ({ ...p, postType: ePostType.PRODUCT_PAGE })),
            action.payload.productReviews.personal.map((p) => ({ ...p, postType: ePostType.PRODUCT_REVIEW })),
            action.payload.productAlternatives.personal.map((p) => ({ ...p, postType: ePostType.PRODUCT_ALTERNATIVE })),
            action.payload.productComparisons.personal.map((p) => ({ ...p, postType: ePostType.PRODUCT_COMPARISON })),
            action.payload.customPosts.personal.map((p) => ({ ...p, postType: ePostType.CUSTOM })),
          ]
        ),
        // inProgressByOthers: [].concat.apply([], [
        //   action.payload.targeted.inProgressByOthers.map(p => ({...p, postType: ePostType.TARGETED})),
        //   action.payload.targetedTranslations.inProgressByOthers.map(p => ({...p, postType: 'TARGETED_TRANSLATION'})),
        //   action.payload.blog.inProgressByOthers.map(p => ({...p, postType: ePostType.BLOG})),
        //   action.payload.blogTranslations.inProgressByOthers.map(p => ({...p, postType: 'BLOG_TRANSLATION'})),
        //   action.payload.learningCenter.inProgressByOthers.map(p => ({...p, postType: ePostType.LEARNING_CENTER})),
        //   action.payload.tags.inProgressByOthers.map(p => ({...p, postType: ePostType.TOPIC})),
        //   action.payload.learningCenterTopics.inProgressByOthers.map(p => ({...p, postType: 'LEARNING_CENTER_TOPIC'})),
        //   action.payload.comparison.inProgressByOthers.map(p => ({...p, postType: ePostType.COMPARISON})),
        //   action.payload.productPages.inProgressByOthers.map(p => ({...p, postType: ePostType.PRODUCT_PAGE})),
        //   action.payload.productReviews.inProgressByOthers.map(p => ({...p, postType: ePostType.PRODUCT_REVIEW})),
        //   action.payload.productAlternatives.inProgressByOthers.map(p => ({...p, postType: ePostType.PRODUCT_ALTERNATIVE})),
        //   action.payload.productComparisons.inProgressByOthers.map(p => ({...p, postType: ePostType.PRODUCT_COMPARISON})),
        //   action.payload.customPosts.inProgressByOthers.map(p => ({...p, postType: ePostType.CUSTOM})),
        // ]),
        pending: action.payload.publicPosts.map((post) => {
          let postType;
          switch (post.itemType) {
            case "communityPost":
              postType = ePostType.COMMUNITY;
              break;
            case "blogPost":
              postType = ePostType.BLOG;
              break;
            case "blogPostTranslation":
              postType = "BLOG_TRANSLATION";
              break;
            case "targetedPost":
              postType = ePostType.TARGETED;
              break;
            case "targetedPostTranslation":
              postType = "TARGETED_TRANSLATION";
              break;
            case "productAlternative":
              postType = ePostType.PRODUCT_ALTERNATIVE;
              break;
            case "productPage":
              postType = ePostType.PRODUCT_PAGE;
              break;
            case "productReview":
              postType = ePostType.PRODUCT_REVIEW;
              break;
            case "productComparison":
              postType = ePostType.PRODUCT_COMPARISON;
              break;
            case "learningCenterTopic":
              postType = "LEARNING_CENTER_TOPIC";
              break;
            case "learningCenterPost":
              postType = ePostType.LEARNING_CENTER;
              break;
            case "comparisonPost":
              postType = ePostType.COMPARISON;
              break;
            case "topic":
              postType = ePostType.TOPIC;
              break;
            case "customPost":
              postType = ePostType.CUSTOM;
              break;
            default:
              console.log("Error: Unknown type", post.itemType);
          }
          return { ...post, postType };
        }),
        rejected: [].concat.apply(
          [],
          [
            action.payload.community.rejected.map((p) => ({ ...p, postType: ePostType.COMMUNITY })),
            action.payload.targeted.rejected.map((p) => ({ ...p, postType: ePostType.TARGETED })),
            action.payload.targetedTranslations.rejected.map((p) => ({ ...p, postType: "TARGETED_TRANSLATION" })),
            action.payload.blog.rejected.map((p) => ({ ...p, postType: ePostType.BLOG })),
            action.payload.blogTranslations.rejected.map((p) => ({ ...p, postType: "BLOG_TRANSLATION" })),
            action.payload.learningCenter.rejected.map((p) => ({ ...p, postType: ePostType.LEARNING_CENTER })),
            action.payload.tags.rejected.map((p) => ({ ...p, postType: ePostType.TOPIC })),
            action.payload.learningCenterTopics.rejected.map((p) => ({ ...p, postType: "LEARNING_CENTER_TOPIC" })),
            action.payload.comparison.rejected.map((p) => ({ ...p, postType: ePostType.COMPARISON })),
            action.payload.productPages.rejected.map((p) => ({ ...p, postType: ePostType.PRODUCT_PAGE })),
            action.payload.productReviews.rejected.map((p) => ({ ...p, postType: ePostType.PRODUCT_REVIEW })),
            action.payload.productAlternatives.rejected.map((p) => ({ ...p, postType: ePostType.PRODUCT_ALTERNATIVE })),
            action.payload.productComparisons.rejected.map((p) => ({ ...p, postType: ePostType.PRODUCT_COMPARISON })),
            action.payload.customPosts.rejected.map((p) => ({ ...p, postType: ePostType.CUSTOM })),
          ]
        ),

        targeted: action.payload.targeted,
        targetedTranslations: action.payload.targetedTranslations,
        blog: action.payload.blog,
        blogTranslations: action.payload.blogTranslations,
        learningCenter: action.payload.learningCenter,
        learningCenterTopics: action.payload.learningCenterTopics,
        community: action.payload.community,
        comparison: action.payload.comparison,
        productPages: action.payload.productPages,
        productReviews: action.payload.productReviews,
        productAlternatives: action.payload.productAlternatives,
        productComparisons: action.payload.productComparisons,
        customPosts: action.payload.customPosts,
        tags: action.payload.tags,
        publicPosts: action.payload.publicPosts,
      };
    case ANSWERS.REFRESH_POST_LIST:
      return {
        ...state,
        refreshPostList: true,
      };
    default:
      return state;
  }
};
