import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import ClassNames from "classnames";
import MSelect from "./MSelect";
import MTag from "./MTag";

const useStyles = makeStyles((theme: Theme) => ({
  optionsWrapper: {
    width: "100%",
    paddingBottom: 10,
    marginBottom: 20,
  },
  selectOptionsWrapper: {},
  selectedOptionsWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > *": {
      marginTop: 10,
    },
  },
}));

type PropTypes = {
  selectedOptions: any[];
  options: any[];
  optionValue?: string;
  optionLabel?: string;

  wrapperClassName?: string;
  title?: string;
  disabled?: boolean;
  creatable?: boolean;
  placeholder?: string;
  borderWidth?: number | string;
  height?: number;
  fullWidth?: boolean;
  handleInputChange?: (input: string) => void;
  handleOptionSelected: Function;
  handleOptionRemoved: Function;
  handleCreateOption?: Function;
};

const MSelectMultiple = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedOptions,
    options,
    optionValue = "_id",
    optionLabel = "name",
    creatable = true,
    title = null,
    placeholder,
    wrapperClassName = null,
    borderWidth = null,
    height = 30,
    disabled = null,
    fullWidth = false,
  } = props;

  const { handleOptionSelected, handleOptionRemoved, handleCreateOption, handleInputChange } = props;
  const [searchField, setSearchField] = useState("");
  const creatableOption =
    searchField.length > 0 && creatable
      ? [...options, { _id: "new_option", name: `create: '${searchField}'`, optionName: searchField }]
      : options;

  return (
    <div className={ClassNames(classes.optionsWrapper, wrapperClassName)}>
      {title && <Typography variant={"body2"}>{title}</Typography>}
      <div className={classes.selectOptionsWrapper}>
        <MSelect
          options={creatableOption}
          value={null}
          optionValue={optionValue}
          optionLabel={optionLabel}
          height={height}
          searchable
          fullWidth={fullWidth}
          disabled={disabled}
          placeholder={placeholder}
          borderWidth={borderWidth}
          handleInputChange={(input) => {
            setSearchField(input);
            handleInputChange?.(input);
          }}
          handleChange={(option) => {
            if (option._id === "new_option") {
              handleCreateOption(option.optionName);
              return;
            }
            handleOptionSelected(option);
          }}
        />
        <div className={classes.selectedOptionsWrapper}>
          {selectedOptions &&
            selectedOptions.map((option) => (
              <MTag
                key={option[optionValue]}
                tag={option[optionLabel]}
                onRemoveClicked={(e) => handleOptionRemoved(option)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(MSelectMultiple);
