import { FUNNELS } from "./constants/actionTypes";

const initialState = {
  dynamicColumns: {},
  eventNames: [],
  funnels: [],
  funnelId: null,
  funnelName: "",
  config: {
    funnelName: "",
    steps: [],
    filter: null,
  },
  cvr: {
    enabled: false,
    step1: null,
    step2: null,
  },
  priority: {
    enabled: false,
    column: null,
    step: null,
  },
  showValue: true,
  showTraffic: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FUNNELS.FUNNEL_CVR_UPDATED:
      return {
        ...state,
        cvr: action.payload,
      };
    case FUNNELS.FUNNEL_PRIORITY_UPDATED:
      return {
        ...state,
        priority: action.payload,
      };
    case FUNNELS.FUNNELS_DYNAMIC_COLUMNS_LOADED:
      return {
        ...state,
        dynamicColumns: {
          ...state.dynamicColumns,
          [action.payload.eventName]: action.payload.data,
        },
      };
    case FUNNELS.FUNNELS_DYNAMIC_COLUMNS_CLEANUP:
      return {
        ...state,
        dynamicColumns: {},
      };
    case FUNNELS.FUNNELS_CLEAR:
      return {
        ...state,
        funnelId: null,
        funnelName: "",
        config: {
          funnelName: "",
          steps: [],
        },
        cvr: {
          enabled: false,
          step1: null,
          step2: null,
        },
        priority: {
          enabled: false,
          column: null,
          step: null,
        },
        showValue: true,
        showTraffic: true,
      };
    case FUNNELS.FUNNELS_LOADED:
      return {
        ...state,
        funnels: action.payload,
      };
    case FUNNELS.FUNNEL_DETAILS_LOADED:
      return {
        ...state,
        funnelId: action.payload._id,
        funnelName: action.payload.funnelName,
        config: action.payload.config,
        cvr: action.payload.cvr || {
          enabled: false,
          step1: null,
          step2: null,
        },
        priority: action.payload.priority || {
          enabled: false,
          column: null,
          step: null,
        },
        showValue: action.payload.showValue,
        showTraffic: action.payload.showTraffic,
      };
    case FUNNELS.FUNNEL_COLUMNS_LOADED:
      return {
        ...state,
        columns: action.payload,
      };
    case FUNNELS.FUNNEL_CONFIG_LOADED:
      return {
        ...state,
        config: action.payload,
      };
    case FUNNELS.FUNNEL_FUNNEL_NAME_CHANGED:
      return {
        ...state,
        funnelName: action.payload,
      };
    case FUNNELS.FUNNEL_CONFIG_UPDATED:
      return {
        ...state,
        config: action.payload,
      };
    case FUNNELS.FUNNEL_EVENT_NAMES_LOADED:
      return {
        ...state,
        eventNames: action.payload,
      };
    case FUNNELS.FUNNEL_TOGGLE_FIELD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
