import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import AdBusinessSelector from "./components/AdBusinessSelector";
import { IAd, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import CfProgressBar from "../../../../../../components/CfProgressBar";
import { ADS } from "../../../../../../reducers/constants/actionTypes";
import AdDetails from "./components/AdDetails";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    height: "100%",
    width: 380,
    minWidth: 380,
    borderRight: `${theme.palette.divider} solid 1px`,
    overflowY: "auto",
  },
  title: {
    height: 60,
    paddingLeft: 30,
    display: "flex",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 1,
  },
  adContentWrapper: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  adLoaded: boolean;
  ad: IAd;

  handleAdChanged: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  adLoaded: state.ads.adLoaded,
  ad: state.ads.ad,
});

const mapDispatchToProps = (dispatch) => ({
  handleAdChanged: (ad) => {
    dispatch({ type: ADS.EDIT_AD_CHANGED, ad });
  },
});

const AdContent = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, adLoaded, ad, handleAdChanged } = props;

  if (!adLoaded) {
    return (
      <div className={classes.content}>
        <CfProgressBar />
      </div>
    );
  }

  return (
    <div className={classes.content}>
      <Typography className={classes.title} variant={"subtitle2"}>
        {I18n.t("ads.edit_ad.content.title")}
      </Typography>
      <div className={classes.adContentWrapper}>
        <AdBusinessSelector
          selectedWebsite={selectedWebsite}
          ad={ad}
          onBusinessSelected={(business) => handleAdChanged({ ...ad, business })}
        />
        <AdDetails />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdContent);
