import { Editor, Point, Range, Transforms } from "slate";
import { ReactEditor, RenderElementProps, useFocused, useSelected, useSlateStatic } from "slate-react";
import * as React from "react";
import { I18n } from "react-redux-i18n";
import theme from "../../../themes/theme";
import { Widget } from "../widgets/WithWidgets";

export const DividerElement = ({
  attributes,
  children,
  element,
}: RenderElementProps & { element: { type: "divider" } }) => {
  const focused = useFocused();
  const selected = useSelected();
  const editor = useSlateStatic();

  const dividerCss = element.data?.dividerCss || {};

  return (
    <Widget
      title={I18n.t("rich_text_editor.divider.name")}
      element={element}
      editable
      handleDeleteClick={() => {
        const path = ReactEditor.findPath(editor, element);
        Transforms.removeNodes(editor, { at: path });
      }}
    >
      <div
        style={{
          width: "100%",
          height: 1,
          marginTop: 30,
          marginBottom: 30,
          backgroundColor: theme.palette.divider,
          marginLeft: element.alignment === "left" ? "unset" : "auto",
          marginRight: element.alignment === "right" ? "unset" : "auto",
          ...dividerCss,
        }}
        {...attributes}
      >
        {children}
      </div>
    </Widget>
  );
};

export function withDivider(editor: Editor): Editor {
  const { deleteBackward } = editor;

  editor.deleteBackward = (unit) => {
    if (editor.selection && Range.isCollapsed(editor.selection) && editor.selection.anchor.offset === 0) {
      const [aboveNode, abovePath] = Editor.above(editor, {
        match: (node) => node.type === "divider" && node.forcedLayout,
      }) || [editor, []];
      if (
        aboveNode.type === "divider" &&
        aboveNode.forcedLayout &&
        Point.equals(Editor.start(editor, abovePath), editor.selection.anchor)
      ) {
        return;
      }
    }
    deleteBackward(unit);
  };

  return editor;
}
