import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import MobileIcon from "@material-ui/icons/PhoneIphone";
import DesktopIcon from "@material-ui/icons/DesktopMac";
import { Paper, Theme } from "@material-ui/core";
import ClassNames from "classnames";
import { isEqual, omit } from "lodash";

import { IWebsite, IWidget, eWidgetSubtype, eWidgetType } from "../../../../../../reducers/constants/objectTypes";
import MDangerouslySetHtmlContent from "../../../../../../components/MDangerouslySetHtmlContent";
import MIFrameWrapper from "../../../../../../components/MIFrameWrapper";
import widgetsAgent from "../../../../../../widgetsAgent";
import useDebounce from "../../../../../../hooks/useDebounce";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.common.white,
    height: "calc(100vh - 131px)",
    width: "100%",
    overflowY: "auto",
    padding: 20,
    display: "flex",
  },
  paper: () => ({
    width: "100%",
    maxWidth: 790,
    height: "100%",
    margin: "auto",
    boxSizing: "content-box",
    background: "transparent",
    border: "none",
    // transform: "translate3d(-50%,0,0)",
    // transitionProperty: "width",
    // transitionDuration: "0.2s",
    // transitionTimingFunction: "ease",
  }),
  paperMobile: () => ({
    maxWidth: 425,
  }),
  iframeWrapper: {
    display: "block",
    width: "100%",
    height: "100%",
    border: "none",
    background: "transparent",
  },
  iframeWrapperMobile: {
    width: "100%",
    borderRadius: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
  responsive: {
    position: "absolute",
    right: 0,
    top: 100,
    padding: 5,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 3,
  },
  carouselWrapper: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 3,
    boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  widget: IWidget;
  selectedItem: number;
  // selectedWidgetType: string;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  widget: state.widgetBuilder.widget,
  selectedItem: state.widgetBuilder.selectedItem,
});

const mapDispatchToProps = (dispatch) => ({});

const WidgetEditorPreview = (props: PropTypes) => {
  const { widget, selectedItem, selectedWebsite } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [html, setHtml] = React.useState(null);
  const [mobile, setMobile] = React.useState(false);
  const paperRef = React.useRef(null);
  const theme = useTheme<Theme>();

  const widgetSelectedItem = widget?.items?.[selectedItem];

  const classes = useStyles();

  const prevWidget = React.useRef<IWidget>(null);
  const debouncedWidget = useDebounce(widget, 500);

  React.useEffect(() => {
    if (!debouncedWidget) {
      return;
    }

    if (isEqual(omit(prevWidget.current, ["name"]), omit(debouncedWidget, ["name"]))) {
      return;
    }

    widgetsAgent.Widgets.builderPreview(debouncedWidget, selectedWebsite.host)
      .then((res) => {
        setHtml(res.html);
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
        console.log(e, e.message);
      })
      .finally(() => {
        prevWidget.current = debouncedWidget;
      });
  }, [debouncedWidget, enqueueSnackbar]);

  React.useEffect(() => {
    if (!widgetSelectedItem || widget.items.length === 1) return;

    const iframeDocument = paperRef.current?.querySelector("iframe")?.contentWindow?.document;

    if (!iframeDocument) return;

    try {
      iframeDocument
        .querySelector(`[widget-item-id="${widgetSelectedItem.itemId}"]`)
        ?.style?.setProperty("border", `1px solid ${theme.palette.primary.main}`);
    } catch (e) {}

    return () => {
      if (iframeDocument) {
        try {
          iframeDocument
            .querySelector(`[widget-item-id="${widgetSelectedItem.itemId}"]`)
            ?.style.removeProperty("border");
        } catch (e) {}
      }
    };
  }, [widgetSelectedItem]);

  const onObserverChange = () => {
    const iframeDocument = paperRef.current?.querySelector("iframe")?.contentWindow?.document;

    if (!iframeDocument) return;

    iframeDocument.querySelector("body").style.display = "flex";
    iframeDocument.querySelector("body").style.justifyContent = "center";
    iframeDocument.querySelector("body").style.alignItems = "center";

    if (iframeDocument.querySelector("body")?.children?.[0]) {
      iframeDocument.querySelector("body").children[0].style.width = "100%";
    }
  };

  return (
    <div className={classes.container}>
      <Paper elevation={0} className={ClassNames(classes.paper, mobile && classes.paperMobile)} ref={paperRef}>
        {html && (
          <MIFrameWrapper
            allow={"autoplay; fullscreen"}
            className={ClassNames(
              classes.iframeWrapper,
              mobile && classes.iframeWrapperMobile,
              widget.subtype === eWidgetSubtype.PRODUCTS_CAROUSEL && classes.carouselWrapper
            )}
            onObserverChange={onObserverChange}
          >
            <MDangerouslySetHtmlContent html={html} />
          </MIFrameWrapper>
        )}
        <IconButton
          className={classes.responsive}
          disableRipple
          disableTouchRipple
          disableFocusRipple
          onClick={(e) => setMobile(!mobile)}
        >
          {!mobile ? <MobileIcon /> : <DesktopIcon />}
        </IconButton>
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetEditorPreview);
