import React, { useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { InfoOutlined } from "@material-ui/icons";

import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Button, Dialog, Typography } from "@material-ui/core";
import { IProduct, IWebsite } from "../../../reducers/constants/objectTypes";
import ModalsCloseButton from "../components/ModalsCloseButton";
import GeneralView from "./components/GeneralView";
import { isEqual } from "lodash";
import ReviewsView from "./components/ReviewsView";
import PricingView from "./components/PricingView";
import ImagesView from "./components/ImagesView/ImagesView";
import agent from "../../../agent";
import useDebounce from "../../../hooks/useDebounce";
import MarketingGoalView from "./components/MarketingGoalView";
import URLsView from "./components/URLsView";
import TagsView from "./components/TagsView";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    position: "absolute",
    top: 80,
    maxHeight: "calc(100% - 120px)",
    margin: 0,
  },
  header: {
    height: 60,
    minHeight: 60,
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeIcon: {
    width: 30,
    height: 30,
    color: theme.palette.text.primary,
  },
  menu: {
    width: 270,
    height: "100%",
    backgroundColor: "#DDEAFF",
    padding: "25px 20px",
    display: "flex",
    flexDirection: "column",
  },
  menuItem: {
    padding: 15,
    "& p": {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightRegular as any,
      lineHeight: "normal",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#BAD5FF66",
    },
    "&:last-child": {
      borderTop: "1px solid #BAD5FF",
    },
  },
  menuItemActive: {
    backgroundColor: "#BAD5FF",
    "&:hover": {
      backgroundColor: "#BAD5FF",
    },
  },
  dialogContent: {
    display: "flex",
    height: 520,
  },
  rightSide: {
    width: 620,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  selectedView: {
    height: "100%",
    padding: 24,
    display: "flex",
    flexDirection: "column",
    gap: 2,
    overflowY: "auto",
  },
  viewDescriptionWrapper: {
    display: "flex",
    alignItems: "flex-start",
    gap: 7,
    marginBottom: 25,
    "& svg": {
      fontSize: 17,
    },
    "& p": {
      fontSize: 14,
      lineHeight: "normal",
      fontWeight: theme.typography.fontWeightRegular as any,
    },
  },
  mainViewWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  description: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: theme.typography.fontWeightLight as any,
  },
  nameLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: "normal",
    marginBottom: 8,
  },
  nameContainer: {
    width: "100%",
    padding: 15,
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#EEF2F9",
    borderRadius: 3,
  },
  nameTextField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
    "& input": {
      padding: 0,
    },
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.divider,
    margin: "20px 0",
  },
  buttonWrapper: {
    padding: "12px 24px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: `${theme.palette.divider} solid 1px`,
  },
  button: {
    width: "fit-content",
    padding: "0px 20px",
    height: 36,
    borderRadius: 20,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    transition: "none",
    textTransform: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      color: "white",
      backgroundColor: theme.palette.primary.dark,
    },
  },
  progressBar: {
    "& *": {
      color: theme.palette.common.white,
    },
  },
}));

const views = [
  {
    label: "General",
    value: "general_view",
  },
  {
    label: "URLs",
    value: "urls_view",
  },
  {
    label: "Images",
    value: "images_view",
  },
  {
    label: "Reviews",
    value: "reviews_view",
  },
  {
    label: "Pricing",
    value: "pricing_view",
  },
  {
    label: "Tags",
    value: "tags_view",
  },
  {
    label: "Marketing goal",
    value: "marketing_goal_view",
  },
];

type PropTypes = {
  selectedWebsite: IWebsite;
  product?: IProduct;
  onClose: () => void;
  onProductUpdated?: (product: IProduct) => void;
};

const ProductDialog = (props: PropTypes) => {
  const classes = useStyles();
  const theme: any = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedWebsite, onClose, onProductUpdated } = props;
  const savedProduct = React.useRef((props.product || {}) as IProduct);
  const [draftProduct, setDraftProduct] = React.useState((props.product || {}) as IProduct);
  const [productName, setProductName] = React.useState(props.product?.name);
  const [selectedView, setSelectedView] = React.useState(views[0]);
  const [loading, setLoading] = React.useState(false);

  const debouncedProductName = useDebounce(productName, 500);

  const updateProduct = async (product: IProduct) => {
    if (loading) {
      return;
    }
    setLoading(true);

    agent.Product.update(product)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(I18n.t("snackbar.update_error", { msg: res.message }));
          setLoading(false);
          return;
        }
        savedProduct.current = res.product;
        setDraftProduct(res.product);
        if (onProductUpdated) {
          onProductUpdated(res.product);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: err.msg || err.message }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (debouncedProductName && debouncedProductName !== savedProduct.current.name) {
      updateProduct({ ...draftProduct, name: debouncedProductName });
    }
  }, [debouncedProductName]);

  const getSelectedViewComponent = () => {
    switch (selectedView.label) {
      case "General":
        return <GeneralView product={draftProduct} onChange={setDraftProduct} />;
      case "URLs":
        return <URLsView product={draftProduct} onChange={setDraftProduct} />;
      case "Reviews":
        return <ReviewsView product={draftProduct} onChange={setDraftProduct} />;
      case "Pricing":
        return <PricingView product={draftProduct} onChange={setDraftProduct} />;
      case "Tags":
        return <TagsView product={draftProduct} onChange={setDraftProduct} />;
      case "Images":
        return <ImagesView product={draftProduct} onChange={setDraftProduct} website={selectedWebsite} />;
      case "Marketing goal":
        return (
          <MarketingGoalView
            product={draftProduct}
            website={selectedWebsite}
            updateProduct={(product: IProduct) => {
              setDraftProduct(product);
              savedProduct.current = product;
              if (onProductUpdated) {
                onProductUpdated(product);
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open
      fullWidth={false}
      maxWidth={"lg"}
      onClose={onClose}
      disableEnforceFocus
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.header}>
        <Typography color={"textPrimary"} variant={"h6"}>
          {I18n.t("product_dialog.title")}
        </Typography>
        <ModalsCloseButton onClick={onClose} customClass={classes.closeIcon} />
      </div>
      <div className={classes.dialogContent}>
        <div className={classes.menu}>
          {views.map((view, key) => (
            <div
              key={key}
              className={classNames(classes.menuItem, selectedView.value === view.value && classes.menuItemActive)}
              onClick={() => {
                setSelectedView(view);
              }}
            >
              <Typography variant={"body1"}>{view.label}</Typography>
            </div>
          ))}
        </div>

        {selectedView.label === "Marketing goal" ? (
          getSelectedViewComponent()
        ) : (
          <div className={classes.rightSide}>
            <div className={classes.selectedView}>
              <div className={classes.viewDescriptionWrapper}>
                <InfoOutlined />
                <Typography variant={"body1"}>
                  {I18n.t(`product_dialog.${selectedView.value}.view_description`)}
                </Typography>
              </div>
              {getSelectedViewComponent()}
            </div>
            <div className={classes.buttonWrapper}>
              <Typography variant={"body1"} className={classes.description}>
                {I18n.t("product_dialog.description")}
              </Typography>
              <Button
                disableRipple
                disableElevation
                disabled={isEqual(savedProduct.current, draftProduct) || loading}
                className={classes.button}
                onClick={() => updateProduct(draftProduct)}
              >
                {I18n.t("product_dialog.apply_changes")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ProductDialog;
