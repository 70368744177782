import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import debounce from "lodash/debounce";
import FunnelStepFilter from "../../FunnelCreator/components/FunnelSidebar/components/inputs/FunnelStepFilter";
import { EVENTS, FUNNELS } from "../../../../reducers/constants/actionTypes";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import FunnelSideBarTextInput from "../../FunnelCreator/components/FunnelSidebar/components/inputs/FunnelSideBarTextInput";
import FunnelBaseSelect from "../../FunnelCreator/components/FunnelBaseSelect";
import EventStepFilter from "./EventStepFilter";

const useStyles = makeStyles((theme: Theme) => ({
  stepWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  stepEventWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
  },
  stepTitleWrapper: {
    display: "flex",
    justifyContent: "start",
    gap: 10,
    alignItems: "center",
  },
  stepTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: "#141414",
    whiteSpace: "nowrap",
  },
}));

const mapStateToProps = (state) => ({
  config: state.events.filter.config,
  eventNames: state.events.eventNames,
  selectedWebsite: state.home.selectedWebsite,
  dynamicColumns: state.funnels.dynamicColumns,
});

const mapDispatchToProps = (dispatch) => ({
  updateConfig(payload) {
    dispatch({ type: EVENTS.EVENTS_FILTER_CHANGED, payload });
  },
  setDynamicColumns(payload) {
    dispatch({ type: EVENTS.EVENTS_LOAD_DYNAMIC_COLUMNS, payload });
  },
});

type Props = {
  index: number;
  config: any;
  eventNames: any[];
  updateConfig: (payload) => void;
  selectedWebsite: IWebsite;
  setDynamicColumns: (payload) => void;
  dynamicColumns: any[];
  event: any;
  removeEvent: (index: number) => void;
};

const EventsToolbarSelectStep = (props: Props) => {
  const classes = useStyles();

  const {
    index,
    config,
    selectedWebsite,
    removeEvent,
    eventNames,
    updateConfig,
    dynamicColumns,
    setDynamicColumns,
    event,
  } = props;

  console.log({ config });

  const [stepDynamicColumns, setStepDynamicColumns] = useState([]);

  useEffect(() => {
    if (event?.value) {
      const eventName = event.value;

      if (dynamicColumns[eventName]) {
        setStepDynamicColumns(dynamicColumns[eventName]);
      } else if (eventName) {
        agent.Funnels.getDynamicColumns(selectedWebsite, eventName)
          .then((res) => {
            setStepDynamicColumns(res.data);
            setDynamicColumns({ eventName, data: res.data });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [event]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <div className={classes.stepEventWrapper}>
      <FunnelBaseSelect
        optionLabel={"label"}
        optionValue={"value"}
        placeholder={"Select event..."}
        onClear={() => {
          removeEvent(index);
        }}
        onFilterClick={() => {
          setMenuIsOpen(false);
          const newConfig = { ...config };
          if (
            newConfig.events[index].filters.length === 0 ||
            newConfig.events[index].filters[newConfig.events[index].filters.length - 1]?.field != null
          ) {
            newConfig.events[index].filters.push({
              column: "",
              condition: "is",
              value: "",
            });
            updateConfig({ config: newConfig });
          }
        }}
        options={eventNames}
        value={eventNames.find((e) => e.value === event.value)}
        handleOnChange={(t) => {
          const newConfig = { ...config };
          if (!newConfig.events?.[index]) {
            const events = newConfig.events || [];
            events.push({
              condition: "",
              value: t.value,
              filters: [],
            });
            newConfig.events = [...events];
          } else {
            newConfig.events[index].value = t.value;
          }
          updateConfig({ config: newConfig });
        }}
      />
      {event.filters.map((filter, filterIndex) => (
        <EventStepFilter
          dynamicColumns={stepDynamicColumns}
          updateConfig={(newConfig) => {
            updateConfig({ config: newConfig });
          }}
          eventIndex={index}
          config={config}
          selectedWebsite={selectedWebsite}
          filter={filter}
          filterIndex={filterIndex}
          key={filterIndex}
        />
      ))}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsToolbarSelectStep);
