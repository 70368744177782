import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 8,
    height: 16,
    fill: "transparent",
    stroke: "#333333",
  },
}));

type PropTypes = {
  className?: string;
};

const EditorLinkIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 8 16"}>
      <path
        d="M7.5 6.55012L7.5 4.24699C7.5 3.38683 7.14337 2.55433 6.48941 1.94383C5.83546 1.33334 4.95865 0.986493 4.02231 1.0004C3.08598 0.986456 2.20905 1.33344 1.5552 1.94383C0.886439 2.56815 0.529713 3.37287 0.529699 4.23307L0.529699 6.5362M0.499999 9.44988L0.499999 11.753C0.499999 12.6132 0.856633 13.4457 1.51058 14.0562C2.16454 14.6667 3.04135 15.0135 3.97769 14.9996C4.89908 14.9996 5.77593 14.6527 6.42989 14.0422C7.08384 13.4318 7.45537 12.6132 7.45538 11.753L7.45538 9.44988M4.03251 4.53824L4.03251 11.4476"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default EditorLinkIcon;
