import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import JobManagementHeader from "./JobManagementHeader";
import JobRunsList from "./JobRunsList";
import JobList from "./JobList";
import MTableWrapper from "../../../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarBaseActionButton from "../../../../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "20px 20px 20px 20px",
    background: theme.palette.background.default,
    height: "auto",
    width: "100%",
  },
  content: {
    flexGrow: 1,
  },
  divider: {
    marginRight: 30,
    marginLeft: 30,
  },
  dagWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "40px 30px 40px 30px",
  },
}));
type PropTypes = {
  selectedWebsite: IWebsite;
  setRefetch?: (value: boolean) => void;
  refetch: boolean;
  activeJob: string;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  activeJob: state.strategySettings.activeJob,
});

const JobManagement = (props: PropTypes) => {
  const classes = useStyles();

  const { activeJob, selectedWebsite } = props;

  const [refetch, setRefetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const setRefetchValue = (value: boolean) => setRefetch(value);
  const jobBtnText = {
    keywords_groups: I18n.t("strategy.settings.kw_group"),
    strategy_builder: I18n.t("strategy.settings.trigger_job"),
    outline_generator: I18n.t("strategy.settings.outline_generation"),
    video_briefs_generator: I18n.t("strategy.settings.video_briefs_generator"),
  };

  function triggerDagRun() {
    setLoading(true);
    agent.Airflow.triggerKeywordGroupDag(selectedWebsite._id, activeJob)
      .then(() => {
        setRefetch(!refetch);
      })
      .catch((e) => {
        enqueueSnackbar(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div className={classes.pageWrapper}>
        <JobList />
      </div>
      <MTableWrapper>
        <MTableToolbar
          actions={[
            <MTableToolbarBaseActionButton
              text={jobBtnText[activeJob]}
              handleClick={triggerDagRun}
              loading={loading}
            />,
            <IconButton onClick={() => setRefetch(!refetch)}>
              <RefreshIcon />
            </IconButton>,
          ]}
        ></MTableToolbar>
        <Divider />
        <JobRunsList refetch={refetch} />
      </MTableWrapper>
    </>
  );
};

export default connect(mapStateToProps)(JobManagement);
