import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import { IWebsite, IWebsiteCommonComponents, IWebsiteThemeProps } from "../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import ColorTool from "../../../components/ColorTool";
import MRouterPrompt from "../../../components/MRouterPrompt";

const useStyles = makeStyles((theme: Theme) => ({
  colorRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  colorsRow: {
    display: "flex",
    gap: 20,
  },
  colors: {
    // width: 400,
  },
  rectangle: {
    width: "100%",
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
  },
  example: {
    marginTop: 100,
    marginBottom: 50,
    color: theme.palette.text.primary,
  },
  link: {
    color: (props: PropTypes) => props.page.poweredBy.color || theme.palette.text.primary,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: (props: PropTypes) => props.page.poweredBy.colorOnHover || theme.palette.primary.main,
    },
  },
  backToDefault: {
    fontSize: 16,
    marginLeft: 5,
    marginTop: 8,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  page: state.websitePages.commonComponents,
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (commonComponents) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "commonComponents",
      value: commonComponents,
    }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  page: IWebsiteCommonComponents;
  theme: IWebsiteThemeProps;
  onPropertyChanged: (commonComponents) => void;
};

const PoweredBy = (props: PropTypes) => {
  const { page, selectedWebsite, theme, onPropertyChanged } = props;
  const classes = useStyles(props);
  return (
    <PageSection
      sectionTitle={I18n.t("website.common_components.powered_by.name")}
      checked={page.poweredBy.enabled}
      onCheckedChange={(value) =>
        onPropertyChanged({
          ...page,
          poweredBy: { ...page.poweredBy, enabled: value },
        })
      }
    >
      <MRouterPrompt />
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={page.poweredBy?.text}
        placeholder={""}
        title={I18n.t("website.common_components.powered_by.text")}
        maxLength={300}
        height={37}
        borderRadius={4}
        marginBottom={30}
        handleValueChange={(value) => onPropertyChanged({ ...page, poweredBy: { ...page.poweredBy, text: value } })}
      />
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={page.poweredBy?.link}
        placeholder={""}
        title={I18n.t("website.common_components.powered_by.link")}
        maxLength={300}
        height={37}
        borderRadius={4}
        marginBottom={30}
        handleValueChange={(value) => onPropertyChanged({ ...page, poweredBy: { ...page.poweredBy, link: value } })}
      />
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={page.poweredBy?.linkName}
        placeholder={""}
        title={I18n.t("website.common_components.powered_by.link_name")}
        maxLength={300}
        height={37}
        borderRadius={4}
        marginBottom={30}
        handleValueChange={(value) => onPropertyChanged({ ...page, poweredBy: { ...page.poweredBy, linkName: value } })}
      />
      <div className={classes.colorsRow}>
        <div className={classes.colorRow}>
          <ColorTool
            flexDirection={"column"}
            elementTitle={I18n.t("website.common_components.powered_by.text_color")}
            color={page.poweredBy.color || theme.palette.text.primary}
            updatePage={(value) => onPropertyChanged({ ...page, poweredBy: { ...page.poweredBy, color: value } })}
          />
          {page.poweredBy.color && page.poweredBy.color !== theme.palette.text.primary && (
            <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
              <CloseIcon
                className={classes.backToDefault}
                onClick={() =>
                  onPropertyChanged({
                    ...page,
                    poweredBy: { ...page.poweredBy, color: theme.palette.text.primary },
                  })
                }
              />
            </Tooltip>
          )}
        </div>

        <div className={classes.colorRow}>
          <ColorTool
            flexDirection={"column"}
            elementTitle={I18n.t("website.common_components.powered_by.text_color_on_hover")}
            color={page.poweredBy.colorOnHover || theme.palette.primary.main}
            updatePage={(value) =>
              onPropertyChanged({ ...page, poweredBy: { ...page.poweredBy, colorOnHover: value } })
            }
          />
          {page.poweredBy.colorOnHover && page.poweredBy.colorOnHover !== theme.palette.primary.main && (
            <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
              <CloseIcon
                className={classes.backToDefault}
                onClick={() =>
                  onPropertyChanged({
                    ...page,
                    poweredBy: { ...page.poweredBy, colorOnHover: theme.palette.primary.main },
                  })
                }
              />
            </Tooltip>
          )}
        </div>

        <div className={classes.colorRow}>
          <ColorTool
            flexDirection={"column"}
            elementTitle={I18n.t("website.common_components.powered_by.background_color")}
            color={page.poweredBy.backgroundColor || theme.palette.background.default}
            updatePage={(value) =>
              onPropertyChanged({
                ...page,
                poweredBy: { ...page.poweredBy, backgroundColor: value },
              })
            }
          />
          {page.poweredBy.backgroundColor && page.poweredBy.backgroundColor !== theme.palette.background.default && (
            <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
              <CloseIcon
                className={classes.backToDefault}
                onClick={() =>
                  onPropertyChanged({
                    ...page,
                    poweredBy: {
                      ...page.poweredBy,
                      backgroundColor: theme.palette.background.default,
                    },
                  })
                }
              />
            </Tooltip>
          )}
        </div>
      </div>

      <Typography variant={"body2"} className={classes.example}>
        {I18n.t("website.example")}
      </Typography>
      <div
        className={classes.rectangle}
        style={{
          color: page.poweredBy.color || theme.palette.text.primary,
          backgroundColor: page.poweredBy.backgroundColor || theme.palette.background.default,
        }}
      >
        <span>{page.poweredBy.text}</span>&nbsp;
        <a href={page.poweredBy.link} className={classes.link}>
          <strong>{page.poweredBy.linkName}</strong>
        </a>
      </div>
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PoweredBy);
