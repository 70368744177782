import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { I18n } from "react-redux-i18n";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { sumBy } from "lodash";
import { ILinksSplitTest, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import agent from "../../../../../../agent";
import EditLinksSplitTestFields from "./components/EditLinksSplitTestFields";
import EditLinksSplitTestGroupsTable from "./components/EditLinksSplitTestGroupsTable";
import EditLinksSplitTestDialogTitle from "./components/EditLinksSplitTestDialogTitle";
import CfProgressBar from "../../../../../../components/CfProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderWidth: 0,
    backgroundColor: "#F9FBFD",
  },

  dialogContent: {
    display: "flex",
    flexDirection: "column",
    minHeight: 220,
    padding: 40,
    overflowY: "visible",
  },
  dialogActions: {
    padding: 40,
  },
  closeButton: {
    position: "absolute",
    right: 10,
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(36),
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  open: boolean;
  testId: string;

  handleCloseClick: () => void;
  handleTestChanged: (test: ILinksSplitTest, newTest: boolean) => void;
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const newTest = {
  state: "Draft",
  name: "",
  description: "",
  groups: [],
};

const EditLinksSplitTestDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, testId, selectedWebsite, handleCloseClick, handleTestChanged } = props;
  const [loading, setLoading] = React.useState(true);
  const [test, setTest] = React.useState<ILinksSplitTest>(null);
  const { enqueueSnackbar } = useSnackbar();

  const onOpen = () => {
    console.log("onOpen");
  };

  React.useEffect(() => {
    if (testId === "new-test") {
      setLoading(false);
      setTest(newTest as ILinksSplitTest);
      return;
    }

    setLoading(true);
    agent.LinksSplitTest.getTest(selectedWebsite._id, testId)
      .then((res) => {
        setTest(res.test);
      })
      .catch((e) => {
        console.log("e", e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [testId]);

  const close = () => {
    setLoading(false);
    setTest(null);
    handleCloseClick();
  };

  if (test === null) {
    return null;
  }

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      disableBackdropClick
      disableEscapeKeyDown
      onEntered={(e) => onOpen()}
      onClose={(event) => close()}
      // fullWidth
      fullScreen
      // maxWidth={"md"}
      TransitionComponent={Transition}
    >
      <EditLinksSplitTestDialogTitle
        selectedWebsite={selectedWebsite}
        test={test}
        handleTestChanged={(updatedTest) => {
          setTest(updatedTest);
          handleTestChanged(updatedTest, testId === "new-test");
        }}
        closeDialog={close}
      />
      {!loading && (
        <DialogContent className={classes.dialogContent}>
          <EditLinksSplitTestFields selectedWebsite={selectedWebsite} test={test} handleTestChange={setTest} />
          {(!test?.products || test?.products?.length === 0) && !test.allProducts && (
            <Typography color={"textPrimary"} variant={"body1"}>
              {I18n.t("links_split_test.editor.please_select_product")}
            </Typography>
          )}
          {(test.products?.length > 0 || test.allProducts) && (
            <EditLinksSplitTestGroupsTable test={test} handleTestChanged={setTest} />
          )}
        </DialogContent>
      )}
      {loading && (
        <DialogContent className={classes.dialogContent}>
          <CfProgressBar />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default EditLinksSplitTestDialog;
