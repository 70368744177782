import React, { useCallback, useEffect, useState } from "react";
import isHotkey from "is-hotkey";
import ClassNames from "classnames";
import { createEditor, Editor, Transforms, Range } from "slate";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { I18n } from "react-redux-i18n";
import { Droppable } from "react-beautiful-dnd";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { HistoryEditor } from "slate-history/dist/history-editor";
import Portal from "@material-ui/core/Portal";
import _ from "lodash";
import { withTables } from "./elements/table/tables";
import comment from "./elements/comments/comment";
import commentV2 from "./elements/commentsv2/commentV2";
import link from "./elements/link/link";
import image from "./elements/image/image";
import inlineHtml from "./elements/inlineHTML/inlineHtml";
import richTextEditorHelper from "./helper/richTextEditorHelper";
import EditorToolbar from "./elements/toolbar/EditorToolbar";
import embed from "./elements/embed/embed";
import SNReadOnlyEditor from "./ReadOnlyEditor";
import RenderElement from "./elements/RenderElement";
import { withWidgets } from "./elements/widgets/WithWidgets";
import { insertLayout, withForcedLayout, withLayouts, withTypeValidation } from "./elements/layouts/layouts";
import RenderLeaf from "./elements/RenderLeaf";
import { insertTextBox } from "./elements/textBox/TextBoxElement";
import TextAreaWidgetBaseElement from "./elements/widgets/baseElements/TextAreaWidgetBaseElement";
import { IPage, IFile, IProduct, IWebsite, ICoverVideo } from "../reducers/constants/objectTypes";
import useDragMonitor from "../hooks/useDragMonitor";
import { insertProsConsTable } from "./elements/prosConsTable/prosConsTable";
import { editorWidgetList } from "../containers/PageEditor/editorActions/PageEditorElements";
import PageEditorElementStyle from "../containers/PageEditor/editorActions/designComponents/PageEditorElementStyle";
import { DEV } from "../reducers/constants/consts";
import { withBulletList } from "./elements/bulletedList/BulletedListElement";
import { withDivider } from "./elements/divider/DividerElement";
import { insertEntailWidget, withEntailWidgets } from "./elements/entailWidgets/entailWidgets";
import { ForcedLayout } from "./types/editor.Types";
import PageEditorCoverImage from "../containers/PageEditor/info/PageEditorCoverImage";
import HoveringToolbar from "./elements/toolbar/hoveringToolbar";
import PageEditorAssistant from "../containers/PageEditor/editorActions/assistant/PageEditorAssistant";
import { useEvent } from "../hooks/useEvents";
import TitleAssistantDropdown from "./elements/aiAssitant/TitleAssistantDropdown";
import agent from "../agent";
import PageEditorComments from "../containers/PageEditor/editorActions/PageEditorComments";
import pastingHelper from "./helper/pastingHelper";
import tableOfContents from "../containers/PageEditor/components/TableOfContents";
import TableOfContents from "../containers/PageEditor/components/TableOfContents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    feedWrapper: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    editable: {
      fontSize: (props: PropTypes) => theme.typography.pxToRem(props.fontSize || 18),
      color: theme.palette.text.primary,
      lineHeight: 1.55,
      paddingRight: 25,
      paddingLeft: 25,
      paddingTop: 20,
      paddingBottom: 20,
      minHeight: "200px !important",
      fontWeight: theme.typography.fontWeightLight as any,
      overflowY: "hidden",
    },
    button: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    image: {},
    quotes: {
      borderLeft: "14px solid #9fb5d0",
      margin: "1.5em 10px",
      padding: "0.5em 10px",
      quotes: '"\\201C""\\201D""\\2018""\\2019"',
      "&:before": {
        color: "#ccc",
        content: "open-quote",
        fontSize: "4em",
        lineHeight: "0.1em",
        marginRight: "0.25em",
        verticalAlign: "-0.4em",
      },
      "& p": {
        display: "inline",
      },
    },
    h2Text: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightBold as any,
      lineHeight: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(15),
      "&:not(:first-child)": {
        marginTop: theme.typography.pxToRem(35),
      },
    },
    h3Text: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold as any,
      lineHeight: theme.typography.pxToRem(26),
      marginBottom: theme.typography.pxToRem(10),
      "&:not(:first-child)": {
        marginTop: theme.typography.pxToRem(25),
      },
    },
    paragraph: {
      fontWeight: theme.typography.fontWeightLight as any,
      fontSize: (props: PropTypes) => theme.typography.pxToRem(props.fontSize || 16),
      lineHeight: theme.typography.pxToRem(26),
      marginBottom: theme.typography.pxToRem(0),
      marginTop: 10,
      wordBreak: "break-word",
    },
    titleTextField: {
      fontSize: theme.typography.pxToRem(30),
      lineHeight: 1.33,
      fontWeight: theme.typography.fontWeightBold as any,
      // minHeight: 80,
      paddingLeft: 25,
      paddingRight: 25,
      margin: "10px 0px 0px 0px",
    },
    titleTypography: {
      fontSize: theme.typography.pxToRem(30),
      lineHeight: 1.33,
      fontWeight: theme.typography.fontWeightBold as any,
      paddingLeft: 25,
      paddingTop: 25,
      paddingRight: 25,
    },
    subtitleTextField: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.33,
      fontWeight: theme.typography.fontWeightBold as any,
      color: theme.palette.text.secondary,
      paddingLeft: 25,
      paddingRight: 25,
      marginBottom: 16,
      marginTop: 16,
    },
    subtitleTypography: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.33,
      fontWeight: theme.typography.fontWeightBold as any,
      color: theme.palette.text.secondary,
      paddingLeft: 25,
      paddingRight: 25,
      marginBottom: 16,
    },
    imageDetailsWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    imageSizeWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    sizeField: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold as any,
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    tableHead: {
      backgroundColor: "#F5F6F8",
      border: "1px double black",
      height: "50px",
      fontSize: 14,
      padding: "25px 0 25px 20px",
    },
    tableCell: {
      border: "1px solid black",
      height: "50px",
      fontSize: 14,
      padding: "25px 0 25px 20px",
    },
  })
);

type PropTypes = {
  droppableId: string;
  website: IWebsite;
  products?: IProduct[];
  initialValue: any;

  editorMode?: boolean;

  showDesignPanel?: boolean;

  editableTitle?: boolean;
  editTitleAlert?: boolean;
  title?: string;
  brief?: string;
  titleDesiredCount?: number;
  enforceTitleDesiredCount?: boolean;
  titlePlaceHolder?: string;
  subtitlePlaceHolder?: string;
  editorPlaceHolder?: string;
  imageSizeLimit?: number;

  toolbarCustomClass?: string;
  titleCustomClass?: string;
  subtitleCustomClass?: string;
  feedWrapperCustomClass?: string;
  editableCustomClass?: string;
  fontSize?: number;
  enableComments?: boolean;
  enableEditableVoids?: boolean;
  withCustomElements?: boolean;
  enableTables?: boolean;
  enableLayouts?: boolean;
  enableDivider?: boolean;
  enableTextBoxes?: boolean;
  enableCoverMedia?: boolean;
  children?: JSX.Element;
  forcedElements?: ForcedLayout[];
  coverImage?: IFile;
  coverVideo?: ICoverVideo;
  page?: IPage;
  disableAi?: boolean;
  withAiAssistant?: boolean;
  withTOC?: boolean;
  handlePageChanged?: (page: IPage) => void;

  onValueChanged: (value, raw) => void;
  onTitleChanged?: (value) => void;
  onSubtitleChanged?: (value) => void;
  handleProductsChange?: (products: IProduct[]) => void;
  handleEditorChange?: (editor: ReactEditor) => void;
};

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
const HOTKEYS_BLOCK = {
  "mod+k": "link",
};

const emptyParagraph = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const RichTextEditor = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    droppableId,
    website,
    products = [],
    imageSizeLimit = null,
    editTitleAlert = false,
    editableTitle = true,
    editorMode = false,
    withCustomElements = false,
    title,
    brief,
    titlePlaceHolder = null,
    subtitlePlaceHolder = null,
    editorPlaceHolder = null,
    titleDesiredCount = null,
    enforceTitleDesiredCount = false,
    enableComments = false,
    enableTables = false,
    enableCoverMedia = false,
    fontSize,
    initialValue = null,
    toolbarCustomClass,
    feedWrapperCustomClass,
    editableCustomClass,
    titleCustomClass,
    children,
    page,
    disableAi = true,
    withAiAssistant = false,
    forcedElements = [],
    coverImage,
    coverVideo,
    handlePageChanged,
    handleProductsChange,
    handleEditorChange,
    withTOC = false,
  } = props;

  const [isDragging, setIsDragging] = React.useState(false);
  useDragMonitor(droppableId, {
    onDragStart(item) {
      setIsDragging(true);
    },
    onDragEnd(item) {
      setIsDragging(false);
      handleDragEnd(item);
    },
  });

  const [previewMode, setPreviewMode] = useState(false);
  const { onTitleChanged, onSubtitleChanged, onValueChanged } = props;
  const [editTitleConfirmed, setEditTitleConfirmed] = useState(false);
  // https://github.com/ianstormtaylor/slate/issues/3332
  const [value] = React.useState(JSON.parse(initialValue || null) || emptyParagraph);
  const [container, setContainer] = useState(null);
  const [assistantContainer, setAssistantContainer] = useState(null);
  const [tocContainer, setTocContainer] = useState(null);
  const [commentsContainer, setCommentsContainer] = React.useState(null);

  const [commentUpdate, setCommentUpdate] = useState(false);

  React.useEffect(() => {
    setContainer(document.getElementById("component_design_wrapper"));
    setAssistantContainer(document.getElementById("editor_assistant_wrapper"));
    setCommentsContainer(document.getElementById("page_editor_comments_wrapper"));
    setTocContainer(document.getElementById("page_editor_toc"));
  }, []);
  const [lastActiveSelection, setLastActiveSelection] = useState<Range>();

  const renderElement = useCallback(
    (props) => (
      <RenderElement
        isDragging={isDragging}
        imageSizeLimit={imageSizeLimit}
        onCommentUpdate={() => setCommentUpdate(true)}
        page={page}
        products={products}
        fontSize={fontSize}
        disableAi={disableAi}
        editorMode={editorMode}
        website={website}
        {...props}
      />
    ),
    [products, isDragging]
  );

  const renderLeaf = useCallback((props) => <RenderLeaf fontSize={fontSize} {...props} />, []);
  const [editor]: ReactEditor = useState(
    () =>
      withEntailWidgets(
        withForcedLayout(
          withTypeValidation(
            pastingHelper.withHtml(
              withWidgets(
                withLayouts(
                  inlineHtml.withInlineHtml(
                    commentV2.withComments(
                      comment.withComments(
                        withTables(
                          withDivider(
                            withBulletList(
                              link.withLinks(image.withImages(embed.withEmbeds(withHistory(withReact(createEditor())))))
                            )
                          )
                        )
                      )
                    )
                  )
                )
              ),
              website
            )
          ),
          forcedElements
        )
      ) as ReactEditor,
    []
  );

  const decorate = useCallback(
    ([node, path]) => {
      if (lastActiveSelection != null) {
        if (!richTextEditorHelper.isSelected(editor, true)) {
          setLastActiveSelection(null);
        }
        const intersection = Range.intersection(editor.selection, Editor.range(editor, path));
        if (intersection == null) {
          return [];
        }
        const range = {
          highlighted: true,
          ...intersection,
        };
        return [range];
      }
      return [];
    },
    [lastActiveSelection]
  );

  useEvent(
    "show-ai-assistant",
    (data) => {
      if (richTextEditorHelper.isSelected(editor, true)) {
        setLastActiveSelection(editor.selection);
      }
    },
    []
  );
  const updateRichText = (richText) => {
    if (page) {
      agent.Pages.updateRichText(page._id, richText);
    }
  };

  React.useEffect(() => {
    handleEditorChange?.(editor);
  }, [editor]);

  // Save comments even if the user didn't click on the save button
  React.useEffect(() => {
    if (!commentUpdate) {
      return;
    }
    console.log("Comment update");
    setCommentUpdate(false);
    updateRichText(JSON.stringify(editor.children));
  }, [commentUpdate]);

  const debouncedValueChanged = React.useMemo(
    () =>
      _.debounce((newValue) => {
        onValueChanged(JSON.stringify(newValue), richTextEditorHelper.serialize(newValue));
      }, 1000),
    [onValueChanged]
  );

  const handleChange = (newValue: any) => {
    const isAstChange = editor.operations.some((op) => op.type !== "set_selection");

    if (isAstChange) {
      if (DEV) {
        console.log("handleValueChange", newValue);
        const { undos, redos } = (editor as HistoryEditor).history;
        console.log("undos", undos);
        console.log("redos", redos);
        console.log("editor.operations", editor.operations);
      }
      debouncedValueChanged(newValue);
    }
  };

  const handleTitleChanged = (text: string) => {
    if (!editableTitle) {
      return;
    }
    if (editTitleAlert && !editTitleConfirmed) {
      if (
        confirm("Changing the title of an already published post will affect SEO. Are you sure you want to change it?")
      ) {
        setEditTitleConfirmed(true);
      } else {
        return;
      }
    }
    onTitleChanged(text);
  };

  const onKeyDown = (event) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        richTextEditorHelper.toggleMark(editor, mark);
      }
    }
    for (const hotkey in HOTKEYS_BLOCK) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        // console.log('link block');
        const block = HOTKEYS[hotkey];
        richTextEditorHelper.toggleBlock(editor, block);
      }
    }

    if (event.key === "Enter" && event.shiftKey === true) {
      event.preventDefault();
      editor.insertText("\n");
    }
    if (event.key === "Tab") {
      event.preventDefault();
      editor.insertText("\t");
    }
  };

  const handleDragEnd = React.useCallback((result) => {
    const { destination, source } = result;
    console.log("onDragEnd, source", source);
    console.log("onDragEnd destination", destination);
    if (!destination || destination.droppableId !== droppableId) {
      return;
    }
    // Insert from widgets drawer
    if (source.droppableId === "widgets-drawer-list") {
      const widget = editorWidgetList[source.index];
      console.log("adding widget", widget);
      if (!widget) {
        return;
      }
      // if (widget.type === "check-list") {
      //   insertCheckList(editor, [destination.index]);
      //   return;
      // }
      setIsDragging(true);
      if (widget.type === "pros-cons-table") {
        insertProsConsTable(editor, [destination.index]);
        return;
      }
      if (widget.type === "layouts") {
        insertLayout(editor, [1, 1], [destination.index]);
        return;
      }
      if (widget.type === "entail-widget") {
        insertEntailWidget(editor, null, [destination.index]);
        return;
      }
      if (widget.type === "text-box") {
        insertTextBox(editor, [destination.index]);
        return;
      }
      const text = { text: "" };
      const voidNode = {
        type: widget.type,
        data: { uuid: richTextEditorHelper.getUuid() },
        children: [text],
      };
      richTextEditorHelper.insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading(editor, voidNode, [
        destination.index,
      ]);
      const widgetEntry = Editor.above(editor, { match: (x) => x.type === widget.type });
      console.log("select widget.type", widgetEntry);
      if (widgetEntry) {
        Transforms.select(editor, [...widgetEntry[1], 0]);
      } else {
        Transforms.select(editor, [destination.index]);
      }

      return;
    }
    // handle inside editor drag&drop
    if (destination.index === source.index) {
      return;
    }
    const oldState = Array.from(value);
    const childNode = oldState.splice(source.index, 1);
    oldState.splice(destination.index, 0, childNode[0]);
    handleChange(oldState);
    Transforms.moveNodes(editor, { at: [source.index], to: [destination.index] });
  }, []);

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <HoveringToolbar disableAi={disableAi} />
      <EditorToolbar
        website={website}
        imageSizeLimit={imageSizeLimit}
        withComments={enableComments}
        withTables={enableTables}
        toolbarCustomClass={toolbarCustomClass}
        previewMode={previewMode}
        handlePreviewModeChange={(pm) => setPreviewMode(pm)}
      />

      {children}
      <div className={ClassNames(classes.feedWrapper, feedWrapperCustomClass)}>
        {previewMode && (
          <SNReadOnlyEditor
            products={products}
            website={website}
            value={JSON.stringify(editor?.children || value)}
            title={title}
            brief={brief}
          />
        )}
        {!previewMode && (
          <>
            {onTitleChanged && (
              <TitleAssistantDropdown
                selectedWebsite={website}
                page={page}
                iconTop={10}
                iconRight={20}
                margin={"0px 40px"}
                text={title}
                onReplace={(text) => handleTitleChanged(text)}
              >
                <TextAreaWidgetBaseElement
                  className={ClassNames(classes.titleTextField, titleCustomClass)}
                  text={title}
                  placeholder={titlePlaceHolder || I18n.t("rich_text_editor.title_placeholder")}
                  onChange={(text) => handleTitleChanged(text)}
                />
              </TitleAssistantDropdown>
            )}
            {!onTitleChanged && title && (
              <Typography className={classes.titleTypography} variant={"h3"}>
                {title}
              </Typography>
            )}

            {onSubtitleChanged && (
              <TextAreaWidgetBaseElement
                className={ClassNames(classes.subtitleTextField, titleCustomClass)}
                text={brief}
                placeholder={subtitlePlaceHolder || I18n.t("rich_text_editor.subtitle_placeholder")}
                onChange={(text) => onSubtitleChanged(text)}
              />
            )}
            {!onSubtitleChanged && brief && (
              <Typography className={classes.subtitleTypography} variant={"subtitle1"}>
                {brief}
              </Typography>
            )}
            {enableCoverMedia && (
              <PageEditorCoverImage
                imageSizeLimit={800 * 1000}
                selectedWebsite={website}
                previewImage={coverImage}
                previewVideo={coverVideo}
                onVideoSelected={(val) =>
                  handlePageChanged({
                    ...page,
                    previewImage: val.image,
                    previewVideo: val.video,
                  })
                }
                onImageSelected={(file) => handlePageChanged({ ...page, previewImage: file, previewVideo: null })}
              />
            )}
            <Droppable droppableId={droppableId}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Editable
                    id="slate-editor"
                    className={ClassNames(classes.editable, editableCustomClass)}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    decorate={decorate}
                    // placeholder={editorPlaceHolder || I18n.t('rich_text_editor.body_placeholder')}
                    spellCheck
                    autoFocus={false}
                    onKeyDown={onKeyDown}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </>
        )}
      </div>
      {withCustomElements && !previewMode && (
        <Portal container={container}>
          <PageEditorElementStyle
            editorId={droppableId}
            editor={editor as ReactEditor}
            products={products}
            handleProductsChange={handleProductsChange}
          />
        </Portal>
      )}
      {enableComments && !previewMode && (
        <Portal container={commentsContainer}>
          <PageEditorComments />
        </Portal>
      )}

      {withAiAssistant && (
        <Portal container={assistantContainer}>
          <PageEditorAssistant page={page} selectedWebsite={website} />
        </Portal>
      )}
      {withTOC && (
        <Portal container={tocContainer}>
          <TableOfContents selectedWebsite={website} />
        </Portal>
      )}
    </Slate>
  );
};

export default React.memo(RichTextEditor);
