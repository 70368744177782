import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { web } from "../../../../helpers/urlHelper";
import Widgets from "./Widgets/Widgets";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";
import WidgetEditor from "./WidgetEditor";

const WidgetsBuilder = () => (
  <>
    <MHeaderMetaTags title={I18n.t(`meta_tags.cro.widgets.title`)} />
    <Switch>
      <Route exact={false} path={web.widgetsEditor(":widgetId")} component={() => <WidgetEditor />} />
      <Route exact={false} path={web.widgets()} component={() => <Widgets />} />
      <Redirect exact from={"*"} to={web.widgets()} />
    </Switch>
  </>
);

export default WidgetsBuilder;
