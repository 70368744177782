import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function UnderlineIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 28 32"}>
      <path d="M15.317,23.914 C20.367,23.246 24.000,18.583 24.000,13.354 L24.000,2.143 C24.000,0.960 23.067,0.000 21.917,0.000 C20.767,0.000 19.833,0.960 19.833,2.143 L19.833,13.543 C19.833,16.406 17.950,19.011 15.217,19.577 C11.467,20.383 8.167,17.434 8.167,13.714 L8.167,2.143 C8.167,0.960 7.233,0.000 6.083,0.000 C4.933,0.000 4.000,0.960 4.000,2.143 L4.000,13.714 C4.000,19.834 9.217,24.720 15.317,23.914 ZM0.000,30.000 C0.000,31.100 0.900,32.000 2.000,32.000 L26.000,32.000 C27.100,32.000 28.000,31.100 28.000,30.000 C28.000,28.900 27.100,28.000 26.000,28.000 L2.000,28.000 C0.900,28.000 0.000,28.900 0.000,30.000 Z" />
    </SvgIcon>
  );
}

export default UnderlineIcon;
