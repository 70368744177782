import * as React from "react";
import DimensionsBaseComponent from "../BaseComponents/DimensionsBaseComponent";
import { I18n } from "react-redux-i18n";
import StrokeAllSidesIcon from "../../../icons/StrokeAllSidesIcon";
import StrokeTopIcon from "../../../icons/StrokeTopIcon";
import StrokeRightIcon from "../../../icons/StrokeRightIcon";
import StrokeLeftIcon from "../../../icons/StrokeLeftIcon";
import StrokeBottomIcon from "../../../icons/StrokeBottomIcon";

type PropTypes = {
  title?: string;
  value: string;
  defaultValue?: string;
  handleChange: (borderWidth: string) => void;
};

const BorderDimensionsCSS = (props: PropTypes) => {
  const { title = I18n.t("rich_text_editor.button.border_width"), value, defaultValue, handleChange } = props;
  return (
    <DimensionsBaseComponent
      icons={[<StrokeAllSidesIcon />, <StrokeTopIcon />, <StrokeRightIcon />, <StrokeBottomIcon />, <StrokeLeftIcon />]}
      rowTitle={title}
      value={value}
      defaultValue={defaultValue || "1px"}
      handleChange={(val) => handleChange(val)}
    />
  );
};

export default BorderDimensionsCSS;
