import React from "react";
import { PopperPlacementType, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import DownChevronIcon from "../icons/DownChevronIcon";
import CfProgressBar from "./CfProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative" as const,
  },
  contained: {
    visibility: (props: PropTypes) => (props.loading ? "hidden" : "unset"),
  },

  progressBar: {
    // visibility: (props: PropTypes) => props.loading ? 'unset' : 'hidden',
    position: "absolute",
    display: (props: PropTypes) => (props.loading ? "flex" : "none"),
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },

  buttonOutlined: (props: PropTypes) => ({
    textTransform: "capitalize" as const,
    color: props.gray ? theme.palette.text.secondary : theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold as any,
    display: "flex",
    flexDirection: "column" as const,
    minHeight: 40,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    paddingRight: 30,
    paddingLeft: 30,
    backgroundColor: "transparent",
    borderColor: props.gray ? theme.palette.divider : theme.palette.primary.main,
    borderStyle: "solid" as const,
    borderWidth: 1,
    borderRadius: 5,

    // transition: 'all 1s cubic-bezier(.175,.885,.32,1)',
    // webkitTransition: 'all 1s cubic-bezier(.175,.885,.32,1)',

    "&:hover": {
      backgroundColor: props.gray ? "transparent" : theme.palette.primary.main,
      color: props.gray ? theme.palette.primary.main : "white",
    },
    "&:disabled": {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    },
  }),

  button: (props: PropTypes) => ({
    textTransform: props.textTransform || "capitalize",
    color: "white",
    display: "flex",
    flexDirection: "column" as const,
    minHeight: 40,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    paddingRight: 20,
    paddingLeft: 20,
    fontWeight: theme.typography.fontWeightBold as any,
    backgroundColor: theme.palette.primary.main,
    transition: "none",

    // transition: 'all 1s cubic-bezier(.175,.885,.32,1)',
    // webkitTransition: 'all 1s cubic-bezier(.175,.885,.32,1)',

    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      color: "white",
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  fullWidth: {
    width: "100%",
  },
  progressBarWrapper: {
    minWidth: 40,
    position: "relative",
    paddingTop: 5,
    paddingBottom: 5,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    marginTop: -15,
    marginLeft: -15,
  },
  dividerWrapper: {
    minWidth: 1,
    height: "inherit",
    backgroundColor: theme.palette.primary.main,
    borderWidth: "0px !important",
  },
  divider: {
    width: 1,
    height: "calc(100% - 16px)",
    marginTop: 8,
    marginBottom: 8,
    opacity: 0.6,
    backgroundColor: "white",
  },

  textButton: {
    borderRightWidth: "0px !important",
  },
  chevronButton: {},
  buttonGroup: {
    borderRadius: 30,
    boxShadow: "none",
    display: "inline-flex",
  },
  buttonGroupText: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
  },
  buttonGroupChevron: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
  },
}));

type PropTypes = {
  text: string;

  outlined?: boolean;
  gray?: boolean;
  loading?: boolean;
  onClick?: Function;
  onMenuItemClick?: Function;
  disabled?: boolean;
  customClass?: any;
  wrapperCustomClass?: any;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  options: {
    key: string;
    label: string;
  }[];
  selectedOptionIndex?: number;
  hasDivider?: boolean;
  zIndex?: number;
  wholePress?: boolean;
  textTransform?: any;
  stopPropagation?: boolean;
};

const MButtonGroup = (props: PropTypes) => {
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const {
    disabled,
    onClick,
    options,
    selectedOptionIndex,
    onMenuItemClick,
    text,
    loading = false,
    customClass,
    wrapperCustomClass,
    outlined = false,
    fullWidth = false,
    startIcon,
    hasDivider = true,
    zIndex = 1000,
    wholePress = false,
  } = props;
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = (event) => {
    if (props.stopPropagation) {
      event.stopPropagation();
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, key: string) => {
    if (props.stopPropagation) event.stopPropagation();
    onMenuItemClick(key);
    setOpen(false);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={ClassNames(classes.wrapper, fullWidth && classes.fullWidth, wrapperCustomClass)}>
      <div className={classes.progressBar}>
        <CfProgressBar size={30} />
      </div>
      <ButtonGroup
        disableElevation
        disableRipple
        className={classes.buttonGroup}
        variant="contained"
        color="primary"
        classes={{
          contained: classes.contained,
        }}
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          disableRipple
          disableElevation
          className={ClassNames(
            classes.textButton,
            classes.buttonGroupText,
            outlined ? classes.buttonOutlined : classes.button,
            fullWidth && classes.fullWidth,
            customClass || null
          )}
          disabled={disabled || loading}
          onClick={(event) => {
            onClick(event);
            if (wholePress) {
              handleToggle(event);
            }
          }}
          startIcon={startIcon}
        >
          {text}
        </Button>
        {hasDivider && (
          <div className={classes.dividerWrapper}>
            <div className={classes.divider} />
          </div>
        )}
        <Button
          disableRipple
          disableElevation
          disabled={disabled || loading}
          className={ClassNames(
            classes.chevronButton,
            classes.buttonGroupChevron,
            outlined ? classes.buttonOutlined : classes.button,
            fullWidth && classes.fullWidth,
            customClass || null
          )}
          onClick={handleToggle}
        >
          <DownChevronIcon />
        </Button>
      </ButtonGroup>
      <Popper style={{ zIndex }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.key}
                      disabled={false}
                      selected={index === selectedOptionIndex}
                      onClick={(event) => handleMenuItemClick(event, option.key)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default MButtonGroup;
