import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import NormalHoverLabelElement from "./NormalHoverLabelElement";
import SwitchBaseComponent from "../BaseComponents/SwitchBaseComponent";
import FontSizeCSS from "../CssComponents/FontSizeCSS";
import FontWeightCSS from "../CssComponents/FontWeightElement";
import BorderStyleCSS from "../CssComponents/BorderStyleCSS";
import BorderWidthCSS from "../CssComponents/BorderWidthCSS";
import BorderRadiusCSS from "../CssComponents/BorderRadiusCSS";
import FontColorCSS from "../CssComponents/FontColorCSS";
import BackgroundColorCSS from "../CssComponents/BackgroundColorCSS";
import BorderColorCSS from "../CssComponents/BorderColorCSS";
import PaddingCSS from "../CssComponents/PaddingCSS";
import MarginCSS from "../CssComponents/MarginCSS";
import StylePanelSubSection from "../components/StylePanelSubSection";
import { buttonDefaultCss } from "../../../containers/WebsiteConfigurations/WebsiteEditor/consts/CssConsts";
import TextFieldElement from "./TextFieldElement";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: 32,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginTop: 30,
    marginBottom: 10,
  },
}));

type PropTypes = {
  divider?: boolean;
  sectionTitle: string;
  editableText?: boolean;
  editableUrl?: boolean;
  alwaysVisible?: boolean;
  button: {
    show?: boolean;
    css: any;
    url?: string;
    text?: string;
    id?: string;
    showArrow?: boolean;
  };
  elementDefaultCss?: object;
  defaultText?: string;
  defaultUrl?: string;
  theme: IWebsiteThemeProps;

  handleChange: any;
};

const ButtonStyleElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    divider = false,
    alwaysVisible = false,
    sectionTitle,
    button,
    editableText = false,
    editableUrl = false,
    defaultText,
    defaultUrl,
    theme,
    handleChange,
    elementDefaultCss = {},
  } = props;

  const defaultCss = React.useMemo(() => (theme ? buttonDefaultCss(theme, elementDefaultCss) : {}), [theme]);

  const handleShowChange = (show) => {
    handleChange({ ...button, show });
  };
  return (
    <StylePanelSubSection
      divider={divider}
      sectionTitle={sectionTitle}
      show={alwaysVisible ? null : button.show}
      handleShowChange={alwaysVisible ? null : handleShowChange}
    >
      <SwitchBaseComponent
        defaultValue={true}
        value={button?.showArrow || false}
        title={I18n.t("rich_text_editor.button.button_show_arrow")}
        handleChange={(showArrow) => handleChange({ ...button, showArrow })}
      />

      <TextFieldElement
        title={I18n.t("rich_text_editor.button.button_text")}
        placeholder={I18n.t("rich_text_editor.button.enter_button_text_ph")}
        value={button?.text || defaultText}
        handleChange={(text) => editableText && handleChange({ ...button, text })}
      />
      <TextFieldElement
        title={I18n.t("rich_text_editor.button.button_url")}
        placeholder={I18n.t("rich_text_editor.button.enter_button_url_ph")}
        value={button?.url || defaultUrl}
        handleChange={(url) => editableUrl && handleChange({ ...button, url })}
      />
      <TextFieldElement
        title={I18n.t("rich_text_editor.button.button_id")}
        placeholder={I18n.t("rich_text_editor.button.enter_button_id_ph")}
        value={button?.id || ""}
        handleChange={(id) => editableUrl && handleChange({ ...button, id })}
      />

      <Typography className={classes.title}>{I18n.t("rich_text_editor.button.button_typography")}</Typography>
      <FontSizeCSS
        value={button.css?.fontSize || defaultCss.fontSize}
        defaultValue={defaultCss.fontSize}
        handleChange={(fontSize) => handleChange({ ...button, css: { ...button.css, fontSize } })}
      />
      <FontWeightCSS
        value={button.css?.fontWeight || defaultCss.fontWeight}
        defaultValue={defaultCss.fontWeight}
        handleChange={(fontWeight) => handleChange({ ...button, css: { ...button.css, fontWeight } })}
      />
      <Typography className={classes.title}>{I18n.t("rich_text_editor.button.button_border")}</Typography>
      <BorderStyleCSS
        value={button.css?.borderStyle || defaultCss.borderStyle}
        defaultValue={defaultCss.borderStyle}
        handleChange={(borderStyle) => handleChange({ ...button, css: { ...button.css, borderStyle } })}
      />
      <BorderWidthCSS
        value={button.css?.borderWidth || defaultCss.borderWidth}
        defaultValue={defaultCss.borderWidth}
        handleChange={(borderWidth) => handleChange({ ...button, css: { ...button.css, borderWidth } })}
      />
      <BorderRadiusCSS
        value={button.css?.borderRadius || defaultCss.borderRadius}
        defaultValue={defaultCss.borderRadius}
        handleChange={(borderRadius) => handleChange({ ...button, css: { ...button.css, borderRadius } })}
      />

      <Typography className={classes.title}>{I18n.t("rich_text_editor.button.button_colors")}</Typography>
      <NormalHoverLabelElement />
      <FontColorCSS
        value={button.css?.color || defaultCss.color}
        defaultValue={defaultCss.color}
        isValueChanged={false}
        handleChange={(color) => handleChange({ ...button, css: { ...button.css, color } })}
        hover
        hoverValue={button.css["&:hover"]?.color || defaultCss["&:hover"]?.color}
        defaultHoverValue={defaultCss["&:hover"]?.color}
        handleHoverChange={(color) =>
          handleChange({
            ...button,
            css: {
              ...button.css,
              "&:hover": { ...button.css["&:hover"], color },
            },
          })
        }
      />
      <BackgroundColorCSS
        value={button.css?.backgroundColor || defaultCss.backgroundColor}
        defaultValue={defaultCss.backgroundColor}
        isValueChanged={false}
        handleChange={(backgroundColor) => handleChange({ ...button, css: { ...button.css, backgroundColor } })}
        hover
        hoverValue={button.css["&:hover"]?.backgroundColor || defaultCss["&:hover"].backgroundColor}
        defaultHoverValue={defaultCss["&:hover"].backgroundColor}
        handleHoverChange={(backgroundColor) =>
          handleChange({
            ...button,
            css: {
              ...button.css,
              "&:hover": { ...button.css["&:hover"], backgroundColor },
            },
          })
        }
      />
      <BorderColorCSS
        title={I18n.t("rich_text_editor.border_color")}
        value={button.css?.borderColor || defaultCss.borderColor}
        defaultValue={defaultCss.borderColor}
        isValueChanged={false}
        handleChange={(borderColor) => handleChange({ ...button, css: { ...button.css, borderColor } })}
        hover
        hoverValue={button.css["&:hover"]?.borderColor || defaultCss["&:hover"].borderColor}
        defaultHoverValue={defaultCss["&:hover"].borderColor}
        handleHoverChange={(borderColor) =>
          handleChange({
            ...button,
            css: {
              ...button.css,
              "&:hover": { ...button.css["&:hover"], borderColor },
            },
          })
        }
      />
      <Typography className={classes.title}>{I18n.t("rich_text_editor.button.other_button_properties")}</Typography>
      {/* {alignment && ( */}
      {/*  <AlignmentElement */}
      {/*    value={alignment || "center"} */}
      {/*    defaultValue={"center"} */}
      {/*    handleChange={(alignment) => handleChange({ alignment })} */}
      {/*  /> */}
      {/* )} */}
      <PaddingCSS
        value={button.css?.padding || defaultCss?.padding}
        defaultValue={defaultCss?.padding}
        handleChange={(padding) => handleChange({ ...button, css: { ...button.css, padding } })}
      />
      <MarginCSS
        value={button.css?.margin || defaultCss?.margin}
        defaultValue={defaultCss?.margin}
        handleChange={(margin) => handleChange({ ...button, css: { ...button.css, margin } })}
      />
    </StylePanelSubSection>
  );
};

export default ButtonStyleElement;
