import * as React from "react";
import { I18n } from "react-redux-i18n";
import ListBaseComponent from "../BaseComponents/ListBaseComponent";

type PropTypes = {
  value: string;
  handleChange: any;
  defaultValue?: string;
};

const FontWeightCSS = (props: PropTypes) => {
  const weightOptions = [100, 200, 300, 400, 500, 600, 700];
  const { value, handleChange, defaultValue } = props;
  return (
    <ListBaseComponent
      defaultValue={defaultValue}
      title={I18n.t("rich_text_editor.font_weight")}
      value={value}
      selectOptions={weightOptions}
      isValueChanged={value != defaultValue}
      handleChange={(fontWeight) => handleChange(fontWeight)}
    />
  );
};

export default FontWeightCSS;
