<svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none"></svg>;

import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#808080",
    stroke: "#808080",
  },
}));

type PropTypes = {
  className?: string;
};

const NoteIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} width="13" height="14" viewBox="0 0 13 14">
      <path
        d="M3.69303 8.135C3.77686 8.23425 3.88591 8.28747 4.01434 8.28747H6.15625C6.28448 8.28747 6.3935 8.23462 6.4774 8.1358C6.56066 8.03775 6.6 7.91745 6.6 7.78183C6.6 7.6462 6.56066 7.52581 6.47757 7.42744C6.39373 7.32819 6.28468 7.27497 6.15625 7.27497H4.01434C3.88611 7.27497 3.7771 7.32782 3.69319 7.42663C3.60994 7.52469 3.57059 7.64499 3.57059 7.78061C3.57059 7.91631 3.6099 8.03672 3.69303 8.135ZM3.69303 8.135L3.76942 8.07047L3.69308 8.13506C3.69307 8.13504 3.69305 8.13502 3.69303 8.135ZM7.775 9.2813V12.5875H2.11066C2.01648 12.5875 1.94303 12.5535 1.88273 12.4823C1.82149 12.4098 1.7875 12.3142 1.7875 12.1874V1.81259C1.7875 1.68575 1.82152 1.59015 1.88282 1.5176L1.80644 1.45306L1.88273 1.51771C1.94303 1.44655 2.01648 1.4125 2.11066 1.4125H10.8893C10.9835 1.4125 11.0569 1.44651 11.1172 1.5176C11.1785 1.59015 11.2125 1.68575 11.2125 1.81259V8.525H8.43033C8.24203 8.525 8.08275 8.60207 7.95833 8.74911C7.83464 8.89529 7.775 9.07495 7.775 9.2813ZM1.24988 13.1791C1.48417 13.4559 1.77253 13.6 2.11066 13.6H7.61186C7.78012 13.6 7.94066 13.5607 8.09226 13.4827C8.24164 13.4059 8.37071 13.3034 8.47882 13.1756L11.7472 9.31298C11.8552 9.18535 11.9405 9.03464 12.004 8.86227C12.0681 8.68804 12.1 8.50507 12.1 8.31402V1.81259C12.1 1.42977 11.984 1.09737 11.7501 0.820937C11.5158 0.54405 11.2275 0.4 10.8893 0.4H2.11066C1.77253 0.4 1.48417 0.54405 1.24988 0.820937C1.01597 1.09737 0.9 1.42977 0.9 1.81259V12.1874C0.9 12.5702 1.01597 12.9026 1.24988 13.1791ZM4.01434 5.06875H8.98566C9.11389 5.06875 9.2229 5.0159 9.30681 4.91709C9.39007 4.81903 9.42941 4.69873 9.42941 4.56311C9.42941 4.42748 9.39007 4.30709 9.30697 4.20872C9.22314 4.10947 9.11409 4.05625 8.98566 4.05625H4.01434C3.88611 4.05625 3.7771 4.1091 3.69319 4.20791C3.60994 4.30597 3.57059 4.42627 3.57059 4.56189C3.57059 4.69752 3.60993 4.81791 3.69303 4.91628C3.77686 5.01553 3.88591 5.06875 4.01434 5.06875Z"
        stroke-width="0.2"
      />
    </SvgIcon>
  );
};

export default NoteIcon;
