import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import ClassNames from "classnames";
import remarkGfm from "remark-gfm";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    "& >p": {
      margin: 0,
      marginBottom: 10,
    },

    "& >h1": {
      margin: 0,
      marginBottom: 10,
    },
    "& >h2": {
      margin: 0,
      marginBottom: 10,
    },
    "& >h3": {
      margin: 0,
      marginBottom: 10,
    },
    "& >h4": {
      margin: 0,
      marginBottom: 10,
    },
    "& >h5": {
      margin: 0,
      marginBottom: 10,
    },
    "& >h6": {
      margin: 0,
      marginBottom: 10,
    },
    "& >ol": {
      margin: 0,
      marginBottom: 10,
    },
    "& >table >thead >tr >th": {
      background: theme.palette.primary.main,
      color: "#fff",
      border: "1px double  #fff",
      padding: "15px 5px",
    },
    "& >table ": {
      borderCollapse: "collapse",
    },
    "& >table >tbody >tr >td": {
      padding: "10px 5px",
      border: "solid 1px #fff",
    },
  },
  link: {
    color: "#609EFF !important",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type PropTypes = {
  markdown: string;
  customClass: string;
};

const MarkDownPreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { markdown, customClass } = props;

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      skipHtml
      className={ClassNames(classes.wrapper, customClass)}
      components={{
        a(link) {
          return (
            <a className={classes.link} href={link.href} target={"_blank"}>
              {link.children}
            </a>
          );
        },
      }}
    >
      {markdown}
    </ReactMarkdown>
  );
};

export default MarkDownPreview;
