import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import numeral from "numeral";
import MTableHeader from "../../../../../components/Table/Table/MTableHeader";
import { I18n } from "react-redux-i18n";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import MTableRow from "../../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../../components/Table/Cells/MTableCellFirst";
import theme from "../../../../../themes/theme";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";
import MTable from "../../../../../components/Table/Table/MTable";
import { eActionIcon, IWidget, IWidgetAnalytics } from "../../../../../reducers/constants/objectTypes";
import { sumBy } from "lodash";
import { copyStringToClipboard } from "../../../../../helpers/fbScraperHelper";
import { useSnackbar } from "notistack";
import agent from "../../../../../agent";
import CfProgressBar from "../../../../../components/CfProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    width: "100%",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    minHeight: 220,
    padding: 20,
  },
  table: {
    width: 750,
  },
  firstCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  header: {
    padding: "10px 10px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  row: {
    padding: "10px 10px",
    border: "none",
    height: "auto",
    "&:hover": {
      padding: "10px 10px !important",
      border: "none",
    },
  },
  actions: {
    marginLeft: 5,
    position: "absolute",
    right: 0,
    zIndex: 1,
    padding: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}));

// const queries = null;
// const queries: string[] = ['First Query', 'Second Query', 'Third Query'];

type PropTypes = {
  open: boolean;
  widget: IWidget;
  startDate: Date;
  firstColumnWidth?: string | number;

  handleClose?: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WidgetAnalyticsDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const [loading, setLoading] = React.useState(false);
  const [analytics, setAnalytics] = React.useState<IWidgetAnalytics[]>([]);
  const { open, widget, startDate, firstColumnWidth = "60%", handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();

  if (!open) {
    return null;
  }

  React.useEffect(() => {
    if (!widget) {
      return;
    }
    console.log("WidgetAnalyticsDialog widget", widget);
    setLoading(true);
    agent.Widgets.getWidgetAnalytics(
      widget._id,
      widget.website?._id || (widget.website as any),
      startDate.toISOString()
    )
      .then((res) => {
        setAnalytics(res.analytics);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: "Something went wrong!" }));
        handleClose();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [widget, startDate]);

  const widgetAnalytics = React.useMemo(() => {
    const mid = analytics.reduce(
      (acc, item) => {
        return {
          totalLoads: acc.totalLoads + item.totalLoads,
          totalWidgetViews: acc.totalWidgetViews + item.totalWidgetViews,
          totalClicks: acc.totalClicks + item.totalClicks,
          totalInteractions: acc.totalInteractions + item.totalInteractions,
        };
      },
      {
        totalLoads: 0,
        totalWidgetViews: 0,
        totalClicks: 0,
        totalInteractions: 0,
        loadsByPage: {},
        widgetViewsByPage: {},
        clicksByPage: {},
        interactionsByPage: {},
      }
    );

    mid["loadsByPage"] = analytics.reduce(
      (a, obj) =>
        Object.entries(obj.loadsByPage).reduce((a, [key, val]) => {
          a[key] = (a?.[key] || 0) + val;
          return a;
        }, a),
      {}
    );

    mid["widgetViewsByPage"] = analytics.reduce(
      (a, obj) =>
        Object.entries(obj.widgetViewsByPage).reduce((a, [key, val]) => {
          a[key] = (a?.[key] || 0) + val;
          return a;
        }, a),
      {}
    );

    mid["clicksByPage"] = analytics.reduce(
      (a, obj) =>
        Object.entries(obj.clicksByPage).reduce((a, [key, val]) => {
          a[key] = (a?.[key] || 0) + sumBy(val, "count");
          return a;
        }, a),
      {}
    );

    mid["interactionsByPage"] = analytics.reduce(
      (a, obj) =>
        Object.entries(obj.interactionsByPage).reduce((a, [key, val]) => {
          a[key] = (a?.[key] || 0) + sumBy(val, "count");
          return a;
        }, a),
      {}
    );

    console.log("analytics", analytics);
    console.log("mid", mid);

    return mid;
  }, [analytics]);

  const table = React.useMemo(() => {
    const table = {};
    console.log("widgetAnalytics", widgetAnalytics);

    if (!widgetAnalytics) {
      return table;
    }

    Object.keys(widgetAnalytics?.loadsByPage || {}).map((key, index) => {
      table[key] = {
        ...(table[key] || {}),
        pageViews: widgetAnalytics.loadsByPage[key],
      };
    });

    Object.keys(widgetAnalytics?.widgetViewsByPage || {}).map((key, index) => {
      table[key] = {
        ...(table[key] || {}),
        widgetViews: widgetAnalytics.widgetViewsByPage[key],
      };
    });

    Object.keys(widgetAnalytics?.clicksByPage || {}).map((key, index) => {
      table[key] = {
        ...(table[key] || {}),
        clicks: widgetAnalytics.clicksByPage[key],
      };
    });

    Object.keys(widgetAnalytics?.interactionsByPage || {}).map((key, index) => {
      table[key] = {
        ...(table[key] || {}),
        interactions: widgetAnalytics.interactionsByPage[key],
      };
    });
    return table;
  }, [widgetAnalytics]);

  const tableActions = React.useMemo(() => {
    return [
      {
        text: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
        tooltip: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
        onClick: (event, row) => {
          window.open(row[0], "_blank");
        },
        iconName: eActionIcon.openInNew,
      },
      // {
      //   text: I18n.t(`learning_center_content.tooltip.copy_title`),
      //   tooltip: I18n.t(`learning_center_content.tooltip.copy_title`),
      //   onClick: (event, row) =>
      //     copyStringToClipboard(row[0], () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
      //   iconName: eActionIcon.copyName,
      // },
      {
        text: I18n.t(`learning_center_content.tooltip.copy_url`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_url`),
        onClick: (event, row) =>
          copyStringToClipboard(row[0], () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
        iconName: eActionIcon.copyToClipboard,
      },
    ];
  }, []);

  return (
    <Dialog
      className={classes.dialog}
      maxWidth={"lg"}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose()}
    >
      <DialogContent className={classes.dialogContent}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CfProgressBar />
          </div>
        ) : (
          <MTable>
            <MTableHeader top={0} className={classes.header}>
              <MTableHeaderLabel columnWidth={firstColumnWidth}>{I18n.t("widgets_main.list.pages")}</MTableHeaderLabel>
              <MTableHeaderLabel>{I18n.t("widgets_main.list.page_views")}</MTableHeaderLabel>
              <MTableHeaderLabel>{I18n.t("widgets_main.list.widget_views")}</MTableHeaderLabel>
              <MTableHeaderLabel>{I18n.t("widgets_main.list.clicks")}</MTableHeaderLabel>
              <MTableHeaderLabel>{I18n.t("widgets_main.list.cpm")}</MTableHeaderLabel>
            </MTableHeader>
            {Object.keys(table).map((key, rowIndex) => (
              <MTableRow
                key={`row_${rowIndex}`}
                handleRowClick={(e) => e.stopPropagation()}
                customClassName={classes.row}
                showBorder={false}
              >
                <MTableCellFirst
                  columnWidth={firstColumnWidth}
                  wrapperClassName={classes.firstCell}
                  fontSize={theme.typography.pxToRem(14)}
                  cellText={key}
                  actionsObject={[key]}
                  actions={tableActions}
                  actionClassName={classes.actions}
                />
                <MTableCellText text={numeral(table[key].pageViews).format("0,0[.]")} />
                <MTableCellText text={numeral(table[key].widgetViews).format("0,0[.]")} />
                <MTableCellText text={numeral(table[key].clicks).format("0,0[.]")} />
                <MTableCellText
                  text={numeral(((table[key].clicks || 0) * 1000) / (table[key].widgetViews || 1)).format("0,0[.]")}
                />
              </MTableRow>
            ))}
          </MTable>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default WidgetAnalyticsDialog;
