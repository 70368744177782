import { makeStyles } from "@material-ui/styles";

import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IWidgetField } from "../../../../../../../reducers/constants/objectTypes";
import FormWidgetItem from "./FormWidgetItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

type PropTypes = {
  onItemsReorder: (items: any[]) => void;
  fields: IWidgetField[];
  onCopyClick: (field: IWidgetField) => void;
  onDeleteClick: (field: IWidgetField, index: number) => void;
  onClick: (field: IWidgetField, index: number) => void;
};

const FormWidgetItems = (props: PropTypes) => {
  const { onItemsReorder, onClick, onCopyClick, onDeleteClick, fields } = props;
  const classes = useStyles(props);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const items = Array.from(fields);
    const [newOrder] = items.splice(source.index, 1);
    items.splice(destination.index, 0, newOrder);
    onItemsReorder(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div className={classes.root} {...provided.droppableProps} ref={provided.innerRef}>
            {fields?.map((field, index) => (
              <Draggable key={`${field.type}_${index}`} draggableId={`${field.type}_${index}`} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <FormWidgetItem
                      onClick={() => {
                        onClick(field, index);
                      }}
                      onCopyClick={(e) => {
                        e.stopPropagation();
                        onCopyClick(field);
                      }}
                      onDeleteClick={(e) => {
                        e.stopPropagation();
                        onDeleteClick(field, index);
                      }}
                      field={field}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FormWidgetItems;
