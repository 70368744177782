import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { find } from "lodash";
import Typography from "@material-ui/core/Typography";
import { Divider, Paper } from "@material-ui/core";
import { IEditorialPolicy, IWebsite, IWebsiteSectionProps } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import CfProgressBar from "../../../../../components/CfProgressBar";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import MRichTextField from "../../../../../components/MRichTextField";
import WebsitesSectionsSwitch from "../../../../WebsiteConfigurations/components/WebsitesSectionsSwitch";
import { EDITORIAL_POLICY, WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import ImagePicker from "../../../../WebsiteConfigurations/components/ImagePicker";
import MRouterPrompt from "../../../../WebsiteConfigurations/components/MRouterPrompt";

const useStyles = makeStyles((theme: Theme) => ({
  topicDetailsWrapper: {
    width: "100%",
    overflowY: "scroll",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  wrapper: {
    borderRadius: 0,
    border: "none",
    marginTop: 10,
    padding: 30,
  },
  topicDetailsInnerWrapper: {
    // height: '100%',
    width: "100%",
    padding: 20,
    maxWidth: 1280,
    marginLeft: "auto",
    marginRight: "auto",
  },
  topicNameWrapper: {
    marginBottom: 10,
    padding: 20,
  },
  appBar: {
    zIndex: 4,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
  },
  editable: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  editorToolbar: {
    height: 55,
    paddingLeft: 20,
    width: "100%",
    // paddingRight: 30,
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: 20,
    marginBottom: 30,
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  fieldTitleWrapper: {
    justifyContent: "flex-start",
    paddingTop: 20,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: 140,
    marginRight: 15,
  },
  policyText: {
    marginTop: 20,
  },
  divider1: {
    margin: "0px -30px 25px -30px",
  },
  divider: {
    margin: "25px -30px",
  },
  imageRow: {
    display: "flex",
    flexDirection: "column",
    marginRight: 15,
    "&:last-child": {
      marginRight: 0,
    },
  },
  imageFieldTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(14),
  },
  imageFieldDimensions: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
  },
  imageFieldWrapper: {
    display: "flex",
    marginBottom: 15,
    gap: 10,
    marginLeft: 20,
  },
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  policy: IEditorialPolicy;
  policies: IEditorialPolicy[];
  sections: IWebsiteSectionProps;
  onPropertyChanged: (sections) => void;
  onPolicyChanged: (policy) => void;
  onPolicyLoaded: (policy, type) => void;
  onPoliciesLoaded: (policy) => void;
  cleanUp: Function;
  loading: boolean;
  type: "EDITORIAL_PROCESS" | "COMMUNITY_GUIDELINES" | "EDITORIAL_TEAM" | "CONTENT_PRINCIPLES" | "FACT_CHECKED";
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  policy: state.editorial.policy,
  loading: state.editorial.loading,
  sections: state.websitePages.sections,
  policies: state.editorial.policies,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (sections) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "sections",
      value: sections,
    }),
  onPoliciesLoaded: (policies) =>
    dispatch({
      type: EDITORIAL_POLICY.ON_POLICIES_LOADED,
      policies,
    }),
  onPolicyChanged: (policy) =>
    dispatch({
      type: EDITORIAL_POLICY.ON_POLICY_CHANGED,
      policy,
    }),
  cleanUp: () =>
    dispatch({
      type: EDITORIAL_POLICY.CLEANUP,
    }),
  onPolicyLoaded: (policy, type) =>
    dispatch({
      type: EDITORIAL_POLICY.ON_POLICY_LOADED,
      policy,
      policyType: type,
    }),
});

const EditorialPolicyDetails = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    type,
    onPropertyChanged,
    sections,
    onPolicyChanged,
    onPolicyLoaded,
    onPoliciesLoaded,
    cleanUp,
    loading,
    policy,
  } = props;
  const [error, setError] = React.useState(false);
  const [emptyFields, setEmptyFields] = React.useState([]);
  React.useEffect(() => {
    if (emptyFields.length <= 0 && !error) {
      return;
    }
    textFieldsValidated();
  }, [policy]);
  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    agent.EditorialPolicy.get(selectedWebsite._id)
      .then((res) => {
        onPoliciesLoaded(res.policies);
        const selectedPolicy = find(res.policies, { type });
        if (selectedPolicy) {
          onPolicyLoaded(selectedPolicy, type);
        } else {
          onPolicyLoaded(null, type);
        }
      })
      .catch((e) => {
        onPolicyLoaded(null, type);
      });
    return () => {
      setError(false);
      setEmptyFields([]);
      cleanUp();
    };
  }, [type, selectedWebsite]);

  const getPolicyName = () => {
    switch (type) {
      case "EDITORIAL_TEAM":
        return "Team";
      case "COMMUNITY_GUIDELINES":
        return "Community guidelines";
      case "EDITORIAL_PROCESS":
        return "Editorial Policy";
      case "CONTENT_PRINCIPLES":
        return "Content Principles";
      case "FACT_CHECKED":
        return "Fact Check Tooltip";
      default:
        return "";
    }
  };

  const getPolicyTitle = () => {
    switch (type) {
      case "FACT_CHECKED":
      case "CONTENT_PRINCIPLES":
      case "EDITORIAL_TEAM":
        return `editorial_policy.content`;
      case "COMMUNITY_GUIDELINES":
      case "EDITORIAL_PROCESS":
      default:
        return `editorial_policy.policy`;
    }
  };

  const getPolicySection = () => {
    switch (type) {
      case "EDITORIAL_TEAM":
        return sections.editorialTeam;
      case "COMMUNITY_GUIDELINES":
        return sections.communityGuidelines;
      case "EDITORIAL_PROCESS":
        return sections.editorialPolicy;
      case "CONTENT_PRINCIPLES":
        return sections.contentPrinciples;
      case "FACT_CHECKED":
        return sections.factChecked;
      default:
        return false;
    }
  };
  const getPolicySectionName = () => {
    switch (type) {
      case "EDITORIAL_TEAM":
        return "editorialTeam";
      case "COMMUNITY_GUIDELINES":
        return "communityGuidelines";
      case "EDITORIAL_PROCESS":
        return "editorialPolicy";
      case "CONTENT_PRINCIPLES":
        return "contentPrinciples";
      case "FACT_CHECKED":
        return "factChecked";
      default:
        return "";
    }
  };

  const textFieldsValidated = () => {
    setEmptyFields([]);
    const emptyFields = [];
    let valid = true;
    if (!policy?.metaDescription && type !== "CONTENT_PRINCIPLES" && type !== "FACT_CHECKED") {
      valid = false;
      emptyFields.push(I18n.t(`manager.writers_bio.meta_description`));
    }
    if (!policy?.title && type !== "CONTENT_PRINCIPLES" && type !== "FACT_CHECKED") {
      valid = false;
      emptyFields.push(
        I18n.t(`editorial_policy.${type === "EDITORIAL_TEAM" ? "content_title_ph" : "policy_title_ph"}`, {
          name: getPolicyName(),
        })
      );
    }
    if (!policy?.richText) {
      emptyFields.push(I18n.t("rich_text_editor.body_placeholder"));
      valid = false;
    }
    setEmptyFields(emptyFields);
    return valid;
  };

  return (
    <>
      <MRouterPrompt />
      <WebsitesSectionsSwitch
        inSection
        enabled={getPolicySection()}
        title={I18n.t(`website.${type}`)}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && getPolicySection()) {
              onPropertyChanged({
                ...sections,
                [getPolicySectionName()]: enabled,
              });
            }
            setError(true);
          } else {
            setError(false);
            onPropertyChanged({
              ...sections,
              [getPolicySectionName()]: enabled,
            });
          }
        }}
      />
      {error && (
        <Typography variant={"body1"} className={classes.error}>
          {emptyFields.length > 0 && I18n.t("website.error_message_fields", { fields: emptyFields.join(", ") })}
        </Typography>
      )}

      {!loading && (
        <Paper elevation={0} className={classes.wrapper}>
          {type === "EDITORIAL_TEAM" && (
            <>
              <div className={classes.imageRow}>
                <div className={classes.imageFieldWrapper}>
                  <Typography variant={"body1"} className={classes.imageFieldTitle}>
                    {I18n.t("editorial_policy.app_logo")}
                  </Typography>
                  <Typography variant={"body1"} className={classes.imageFieldDimensions}>
                    {I18n.t("website.login_dimensions")}
                  </Typography>
                </div>
                <ImagePicker
                  key={`login-image`}
                  selectedWebsite={selectedWebsite}
                  image={policy?.logo}
                  objectFit={"contain"}
                  imageHeight={"152px"}
                  imageWidth={"152px"}
                  onImageChanged={(image) => {
                    onPolicyChanged({ ...policy, logo: image });
                  }}
                />
              </div>
              <div className={classes.fieldTitleWrapper}>
                <Typography className={classes.fieldTitle} variant={"subtitle2"}>
                  {I18n.t("manager.writers_bio.socials")}
                </Typography>
              </div>
              <div className={classes.rowWrapper}>
                <TextFieldMaxLength
                  key={`facebook`}
                  titleAndLengthLocation={"top"}
                  value={policy?.socials?.facebook}
                  placeholder={I18n.t("manager.writers_bio.url_ph")}
                  title={I18n.t("manager.writers_bio.facebook")}
                  height={37}
                  borderRadius={4}
                  handleValueChange={(facebook) => {
                    onPolicyChanged({ ...policy, socials: { ...policy?.socials, facebook } });
                  }}
                />
                <TextFieldMaxLength
                  key={`twitter`}
                  titleAndLengthLocation={"top"}
                  value={policy?.socials?.twitter}
                  placeholder={I18n.t("manager.writers_bio.url_ph")}
                  title={I18n.t("manager.writers_bio.twitter")}
                  height={37}
                  borderRadius={4}
                  handleValueChange={(twitter) => {
                    onPolicyChanged({ ...policy, socials: { ...policy?.socials, twitter } });
                  }}
                />
              </div>
              <div className={classes.rowWrapper}>
                <TextFieldMaxLength
                  key={`youtube`}
                  titleAndLengthLocation={"top"}
                  value={policy?.socials?.youTube}
                  placeholder={I18n.t("manager.writers_bio.url_ph")}
                  title={I18n.t("manager.writers_bio.youtube")}
                  height={37}
                  borderRadius={4}
                  handleValueChange={(youTube) => {
                    onPolicyChanged({ ...policy, socials: { ...policy?.socials, youTube } });
                  }}
                />
                <TextFieldMaxLength
                  key={`linkedin`}
                  titleAndLengthLocation={"top"}
                  value={policy?.socials?.linkedin}
                  placeholder={I18n.t("manager.writers_bio.url_ph")}
                  title={I18n.t("manager.writers_bio.linkedin")}
                  height={37}
                  borderRadius={4}
                  handleValueChange={(linkedin) => {
                    onPolicyChanged({ ...policy, socials: { ...policy?.socials, linkedin } });
                  }}
                />
              </div>
              <Divider className={classes.divider} />
            </>
          )}
          {type !== "CONTENT_PRINCIPLES" && type !== "FACT_CHECKED" && (
            <>
              <TextFieldMaxLength
                error={emptyFields.includes(I18n.t(`manager.writers_bio.meta_description`))}
                outlined={false}
                value={policy?.metaDescription}
                placeholder={I18n.t("manager.writers_bio.meta_description_ph")}
                title={I18n.t("manager.writers_bio.meta_description")}
                maxLength={160}
                titleAndLengthLocation={"top"}
                // height={160}
                multiLine
                borderRadius={0}
                marginTop={0}
                handleValueChange={(metaDescription) => onPolicyChanged({ ...policy, metaDescription })}
              />
              <Divider className={classes.divider} />
            </>
          )}
          {(type === "CONTENT_PRINCIPLES" || type === "FACT_CHECKED") && <Divider className={classes.divider1} />}
          <MRichTextField
            wrapperClassName={classes.policyText}
            key={`${type}-${policy?._id}`}
            fontSize={16}
            title={I18n.t(getPolicyTitle())}
            editorTitle={policy?.title}
            handleEditorTitleChange={(title) => onPolicyChanged({ ...policy, title })}
            editorTitlePlaceholder={I18n.t(
              `editorial_policy.${type === "EDITORIAL_TEAM" ? "content_title_ph" : "policy_title_ph"}`,
              { name: getPolicyName() }
            )}
            value={policy?.richText}
            website={selectedWebsite}
            handleValueChange={(value) => onPolicyChanged({ ...policy, richText: value })}
          />
        </Paper>
      )}
      {loading && <CfProgressBar />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorialPolicyDetails);
