import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: 0,
    "& >:last-child": {
      marginBottom: 0,
    },
    "& >:first-child": {
      marginTop: 0,
    },
  },
  textBoxStyle: (props: PropTypes) => ({
    ...props.textBoxStyle,
    "&:before": {
      ...props.textBoxStyle?.["&:before"],
    },
    "&:after": {
      ...props.textBoxStyle?.["&:after"],
    },
  }),
}));

type PropTypes = {
  textBoxStyle: any;
  attributes: any;
  children: any;
};

const TextBox = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children } = props;

  return (
    <div className={classNames(classes.wrapper, classes.textBoxStyle)} {...attributes}>
      {children}
    </div>
  );
};

export default TextBox;
