import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: theme.palette.primary.main,
    height: 26,
    width: 26,
  },
}));

type PropTypes = {
  className?: string;
};

const EntailLogo = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 26 26"}>
      <rect width="26" height="26" className={className} rx={"5"} ry={"5"} />
      <path
        d="M6.39355 19.3934V7.03271H13.5437V8.8515H8.6596V12.2418H13.0126V14.0077H8.6596V17.5746H21.0985V19.3934H6.39355Z"
        fill="white"
      />
      <path
        d="M20.2152 14.3635V14.4918H19.4305L19.2366 14.0413H18.3624L18.1685 14.4918H17.3838V14.3635H17.7269L18.5742 12.4062H19.0248L19.8691 14.3635H20.2152ZM18.801 13.0268L18.5384 13.6325H19.0606L18.801 13.0268Z"
        fill="white"
      />
      <path
        d="M20.8899 14.3635V14.4918H19.9113V14.3635H20.1678V12.6006L20.6333 12.8325V14.3635H20.8899Z"
        fill="white"
      />
      <path d="M20.1611 11.9344L20.63 12.1476V12.6164L20.1611 12.4033V11.9344Z" fill="white" />
    </SvgIcon>
  );
};

export default EntailLogo;
