import * as React from "react";
import { I18n } from "react-redux-i18n";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import { find } from "lodash";
import { IPage, IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import ButtonStyleElement from "../../../elementsStyles/ElementComponents/ButtonStyleElement";
import ProductPickerElement from "../../../elementsStyles/ElementComponents/ProductPickerElement";
import { ProductReview } from "../../../types/editor.Types";
import TypographyStyleElement from "../../../elementsStyles/ElementComponents/TypographyStyleElement";
import StylePanelSubSection from "../../../elementsStyles/components/StylePanelSubSection";
import theme from "../../../../themes/theme";

type PropTypes = {
  editor: Editor;
  websiteTheme: IWebsiteThemeProps;
  element: ProductReview;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
  selectedWebsite: IWebsite;
};

const ProductReviewWidgetStyle = (props: PropTypes) => {
  const { editor, websiteTheme, element, products, handleProductsChange, selectedWebsite } = props;
  const handleElementDataChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  return (
    <StylePanelSection sectionTitle={""} editor={editor}>
      <ProductPickerElement
        isClearable={false}
        fullWidth
        selectedWebsite={selectedWebsite}
        products={products}
        handleProductsChange={handleProductsChange}
        product={product}
        onProductChange={(prod: IProduct) => {
          if (!prod) {
            handleElementDataChange({ ...element.data, product: prod });
          } else {
            handleElementDataChange({
              ...element.data,
              product: {
                itemNumber: prod.itemNumber,
              },
            });
          }
        }}
      />
      <TypographyStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.product_num")}
        typography={element.data?.productNumber}
        elementDefaultCss={{
          fontSize: 40,
          fontWeight: theme.typography.fontWeightBold as any,
          margin: 0,
        }}
        variant={"subtitle1"}
        handleChange={(data) => handleElementDataChange({ ...element.data, productNumber: data })}
        theme={websiteTheme}
      />
      <StylePanelSubSection
        divider
        sectionTitle={I18n.t("rich_text_editor.logo")}
        show={element.data?.logo.show}
        handleShowChange={(value) =>
          handleElementDataChange({ ...element.data, logo: { ...element.data.logo, show: value } })
        }
      >
        {}
      </StylePanelSubSection>
      <StylePanelSubSection
        divider
        sectionTitle={I18n.t("rich_text_editor.product_review.reviews")}
        show={element.data?.reviews.show}
        handleShowChange={(value) =>
          handleElementDataChange({ ...element.data, reviews: { ...element.data.reviews, show: value } })
        }
      >
        {}
      </StylePanelSubSection>
      <ButtonStyleElement
        divider
        alwaysVisible
        sectionTitle={I18n.t("rich_text_editor.button.button")}
        button={element.data.button}
        editableText
        editableUrl
        defaultText={element.data?.button?.text || product?.callToAction}
        defaultUrl={element.data?.button?.url || product?.callToActionURL || ""}
        theme={websiteTheme}
        elementDefaultCss={{
          padding: "15px 50px",
        }}
        handleChange={(button) => {
          handleElementDataChange({ ...element.data, button });
        }}
      />
    </StylePanelSection>
  );
};

export default ProductReviewWidgetStyle;
