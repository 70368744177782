import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import ArrowLeftIcon from "../../../../icons/ArrowLeftIcon";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: 55,
    paddingRight: 17,
    paddingLeft: 17,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    width: 300,
    outline: "0.1px solid #9e9e9e",
    padding: 3,
    marginTop: 7,
    marginLeft: 10,
    height: "calc(100% - 30px)",
    borderRadius: "5px",
    boxSizing: "border-box",
  },
  label: {
    padding: "17px 20px 14px 20px",
    borderRadius: "5px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    userSelect: "none",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  addWidgetMode: boolean;
  handleBackClick: () => void;
};

const mapStateToProps = (state) => ({
  addWidgetMode: state.widgetPicker.addWidgetMode,
});

const mapDispatchToProps = (dispatch) => ({});

const WidgetPickerHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { addWidgetMode, handleBackClick } = props;

  return (
    <div className={classes.toolbar}>
      <div className={classes.wrapper}>
        <div className={classes.toolbarDetails}>
          <ArrowLeftIcon className={classes.arrowIcon} onClick={handleBackClick} />
        </div>
        <Typography className={classes.label}>
          {I18n.t(`widget_picker.header.${addWidgetMode ? "create_widget" : "insert_widget"}`)}
        </Typography>
      </div>
      <div className={classes.toolbarDetails}></div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetPickerHeader);
