import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { replace } from "connected-react-router";
import agent from "../../agent";
import { HOME } from "../../reducers/constants/actionTypes";
import { useSnackbar } from "notistack";
import EntailNavbarLogo from "../../icons/EntailNavbarLogo";
import sideImage from "../../img/signup_side.png";
import { eEntailProducts, eMpEvent, IAdminUser } from "../../reducers/constants/objectTypes";
import Typography from "@material-ui/core/Typography";
import FlowTextField from "./OnboardingFlow/components/FlowTextField";
import MButton from "../../components/MButton";
import { ArrowForward, CheckBox } from "@material-ui/icons";
import PasswordTextField from "./OnboardingFlow/components/PasswordTextField";
import validator from "validator";
import { MIXPANEL_PAGE_VIEW } from "../../reducers/middlewares/mixpanelMiddleware";
import CheckIcon from "../../icons/CheckIcon";
import ClassNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "70px 0px",
    width: 661,
  },
  topWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  imageWrapper: {
    background: "linear-gradient(90deg, #574AC1 0%, #2C2093 100%)",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  entailNavbarLogo: {},
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 30,
    lineHeight: "29px",
    margin: "30px 0",
    color: "#000",
    marginTop: 70,
  },
  inputColumn: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 15,
    marginBottom: 31,
  },
  inputTitle: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  navigation: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
  },
  backIcon: {
    marginRight: 10,
  },
  nextIcon: {
    marginLeft: 10,
  },
  button: {
    padding: "10px 30px",
    borderRadius: 40,
    height: 43,
  },
  backButton: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  rolesWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 35,
  },
  roles: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: 15,
    columnGap: 7,
  },
  jointText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "20px",
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  joinWrapper: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    gap: 7,
    marginTop: "-5px",
  },
  checkIcon: {
    color: theme.palette.primary.main,
    fontSize: 17,
  },
}));

type PropTypes = {
  pageLoaded: Function;
  mpEvent: (event: string, props: object) => void;
  onRegister: (to, email, name, orgName) => void;
  user: IAdminUser;
  email: string;
  onAdminUserLoaded: (result) => void;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
});

const mapDispatchToProps = (dispatch) => ({
  pageLoaded: () =>
    dispatch({
      type: HOME.PAGE_LOADED,
      meta: {
        mixpanel: { event: MIXPANEL_PAGE_VIEW, props: { "page-name": "signup details" } },
      },
    }),
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
  onRegister: (to, email, name, orgName) => {
    dispatch({
      type: HOME.ADMIN_USER_SIGNED_UP,
      meta: { mixpanel: { event: "signup", props: { email, name, organization: orgName } } },
    });
    dispatch(replace(to));
  },
  onAdminUserLoaded: (result) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: result.user }),
});

const SignupDetails = (props: PropTypes) => {
  const classes = useStyles(props);
  const [name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [orgName, setOrgName] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [emailJoin, setEmailJoin] = React.useState(false);

  const { pageLoaded, mpEvent, onRegister, onAdminUserLoaded, email } = props;
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    pageLoaded();
  }, []);

  const handleLoginClick = () => {
    const validPassword = validator.isStrongPassword(password, {
      minLength: 8,
      minUppercase: 1,
      minSymbols: 1,
      minNumbers: 1,
      minLowercase: 0,
    });
    if (name.length === 0 || !validPassword || orgName.length === 0) {
      setPasswordError(!validPassword);
      return;
    }

    mpEvent(eMpEvent.onboardingAccountButtonClick, { email, name, organization: orgName });
    setLoading(true);

    agent.Auth.register(email, password, name, orgName, emailJoin)
      .then((res) => {
        onAdminUserLoaded(res);
        onRegister(res.to || "/", email, name, orgName);
        mpEvent(eMpEvent.onboardingAccountSuccess, { email, name, organization: orgName });
      })
      .catch((e) => {
        mpEvent(eMpEvent.onboardingAccountError, { error: e.response.body.message });
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      })
      .finally(() => setLoading(false));
  };

  const organizationDomain = React.useMemo(() => {
    const commonEmailProviders = ["gmail.com", "yahoo.com", "outlook.com", "hotmail.com", "icloud.com"];

    const domain = email.split("@")[1];
    if (commonEmailProviders.includes(domain)) return null;
    return domain;
  }, [email]);

  return (
    <section className={classes.pageWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.topWrapper}>
          <EntailNavbarLogo className={classes.entailNavbarLogo} />
          <Typography className={classes.title}>{I18n.t("signup.create_account")}</Typography>
          <div className={classes.inputColumn}>
            <Typography className={classes.inputTitle}>{I18n.t("signup.name")}</Typography>
            <FlowTextField placeholder={I18n.t("signup.name_ph")} value={name} onChange={(v) => setName(v)} />
          </div>
          <div className={classes.inputColumn}>
            <Typography className={classes.inputTitle}>{I18n.t("signup.password")}</Typography>
            <PasswordTextField
              error={passwordError}
              placeholder={I18n.t("signup.password_ph")}
              value={password}
              onChange={(v) => {
                setPassword(v);
                if (passwordError) setPasswordError(false);
              }}
            />
          </div>
          <div className={classes.inputColumn}>
            <Typography className={classes.inputTitle}>{I18n.t("signup.org_name")}</Typography>
            <FlowTextField placeholder={I18n.t("signup.org_name_ph")} value={orgName} onChange={(v) => setOrgName(v)} />
            {organizationDomain && (
              <div
                className={classes.joinWrapper}
                onClick={() => {
                  setEmailJoin(!emailJoin);
                }}
              >
                {emailJoin ? (
                  <CheckCircleIcon className={classes.checkIcon} fontSize={"inherit"} />
                ) : (
                  <RadioButtonUncheckedIcon className={classes.checkIcon} fontSize={"inherit"} />
                )}
                <Typography className={classes.jointText}>
                  {I18n.t("signup.can_join_org", { domain: organizationDomain })}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className={classes.navigation}>
          <MButton loading={loading} customClassNames={classes.button} onClick={() => handleLoginClick()}>
            <span>{I18n.t("signup.navigation.continue")}</span>
            <ArrowForward className={classes.nextIcon} />
          </MButton>
        </div>
      </div>
      <div className={classes.imageWrapper}>
        <img alt={"sign up"} src={sideImage} />
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupDetails);
