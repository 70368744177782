import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useState } from "react";
import moment from "moment";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import MetricsGraph from "./components/MetricsGraph";
import PositionsMetrics from "./components/PositionsMetrics";
import PositionsTable from "./components/PositionsTable";

const useStyles = makeStyles((theme: Theme) => ({
  positions: {
    height: "100%",
    display: "flex",
    flex: 1,
    // overflow: 'hidden',
  },
  positionsContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // height: 'calc(100vh - 93px)',
    // overflow: 'auto',
  },
  graphWrapper: {
    margin: 10,
    backgroundColor: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};

const labels = {
  positions: ["0-3", "4-10", "11-20", "21-50", "51-100"],
  impressions: ["10k+", "5k-10k", "1k-5k", "100-1k", "10-100", "1-10"],
  clicks: ["50+", "20-50", "10-20", "5-10", "1-5", "0"],
};

const Positions = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalView, setTotalView] = React.useState(false);
  const [hiddenAreas, setHiddenAreas] = React.useState(new Set());
  const [interval, setInterval] = useState<"day" | "week" | "month" | "quarter">("month");
  const [startDate, setStartDate] = useState<string>(moment().subtract(6, "month").format("YYYY-MM-DD"));
  const [dataSet, setDataSet] = useState<"impressions" | "clicks" | "positions">("clicks");
  const [queriesView, setQueriesView] = useState(true);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    setLoading(true);
    agent.Analysis.metrics(
      selectedWebsite._id,
      queriesView,
      interval,
      moment(startDate, "YYYY-MM-DD"),
      moment(),
      queriesView ? totalView : false
    )
      .then((res) => {
        setMetrics(res.metrics);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
    return () => {
      setMetrics([]);
    };
  }, [selectedWebsite, interval, startDate, totalView, queriesView]);

  return (
    <div className={classes.positions}>
      <div className={classes.positionsContent}>
        <PositionsMetrics
          queriesView={queriesView}
          handleQueriesViewChange={(qv) => setQueriesView(qv)}
          interval={interval}
          handleIntervalChange={(selectedInterval) => setInterval(selectedInterval)}
          startDate={startDate}
          handleStartDateChange={(selectedStartDate) => setStartDate(selectedStartDate)}
          total={totalView}
          handleTotalChange={(total) => setTotalView(total)}
          dataSet={dataSet}
          handleDataSetChange={(ds) => setDataSet(ds)}
        />
        <div className={classes.graphWrapper}>
          <MetricsGraph
            labels={labels[dataSet]}
            dataSet={dataSet}
            metrics={metrics}
            hiddenAreas={hiddenAreas}
            loading={loading}
            interval={interval}
          />
          {metrics.length && (
            <PositionsTable
              queriesView={queriesView}
              labels={labels[dataSet]}
              dataSet={dataSet}
              metrics={metrics}
              hiddenAreas={hiddenAreas}
              handleHiddenAreasChange={setHiddenAreas}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Positions;
