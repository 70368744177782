import { POSTS_IN_PROGRESS } from "./constants/actionTypes";

const initialState = {
  itemsLoaded: false,
  refreshItemList: false,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POSTS_IN_PROGRESS.ITEMS_LOADED:
      return {
        ...state,
        itemsLoaded: true,
        refreshItemList: false,
        items: action.payload.items,
      };
    case POSTS_IN_PROGRESS.REFRESH_ITEM_LIST:
      return {
        ...state,
        refreshItemList: true,
      };
    default:
      return state;
  }
};
