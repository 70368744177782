import { isEqual, pick } from "lodash";
import { useSnackbar } from "notistack";
import { useReducer, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Node } from "slate";
import { I18n } from "react-redux-i18n";
import agent from "../../../../../agent";
import richTextEditorHelper from "../../../../../editor/helper/richTextEditorHelper";
import { getAvailablePageTypes } from "../../../../../helpers/websiteHelper";
import { IWebsite, ISourceFile } from "../../../../../reducers/constants/objectTypes";

const initialState = {
  sourceFile: null as ISourceFile,
  current: {
    transcriptionRichText: "",
    rephrasedTranscriptionRichText: "",
    rephrasedTranscriptionTitle: "",
  },
  // needed to force re-render on RichTextEditor component
  key: {
    transcription: 0,
    rephrasedTranscription: 1,
  },
  selectedHistory: null as ISourceFile["transcriptionHistory"][number],
  loading: false,
  submitLoading: false,
};

type DialogState = typeof initialState;
type DialogAction =
  | { type: "SET_SOURCE_FILE"; payload: DialogState["sourceFile"] }
  | { type: "EDIT_CURRENT"; payload: Partial<DialogState["current"]> }
  | { type: "EDIT_SELECTED_HISTORY"; payload: Partial<DialogState["selectedHistory"]> }
  | { type: "INCREMENT_KEY" }
  | { type: "SET_SELECTED_HISTORY"; payload: DialogState["selectedHistory"] }
  | { type: "SET_LOADING"; payload: DialogState["loading"] }
  | { type: "SET_SUBMIT_LOADING"; payload: DialogState["submitLoading"] };

const reducer = (state: DialogState, action: DialogAction) => {
  switch (action.type) {
    case "SET_SOURCE_FILE":
      return { ...state, sourceFile: action.payload };
    case "EDIT_CURRENT":
      return { ...state, current: { ...state.current, ...action.payload } };
    case "EDIT_SELECTED_HISTORY":
      return { ...state, selectedHistory: { ...state.selectedHistory, ...action.payload } };
    case "INCREMENT_KEY":
      return {
        ...state,
        key: {
          ...state.key,
          transcription: state.key.transcription + 1,
          rephrasedTranscription: state.key.rephrasedTranscription + 1,
        },
      };
    case "SET_SUBMIT_LOADING":
      return { ...state, submitLoading: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_SELECTED_HISTORY":
      return { ...state, selectedHistory: action.payload };
    default:
      return state;
  }
};

type PropTypes = {
  selectedWebsite: IWebsite;
  sourceFileId: string;
  handleSourceFileChanged: (sourceFile: ISourceFile) => void;
  openPost: (pageId: string, pathname: string) => void;
};

const useRephraseDialog = (props: PropTypes) => {
  const { selectedWebsite, sourceFileId, handleSourceFileChanged, openPost } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const { sourceFile, current, key, selectedHistory, loading, submitLoading } = state;

  const { transcriptionRichText, rephrasedTranscriptionRichText, rephrasedTranscriptionTitle } = useMemo(
    () => selectedHistory || current,
    [selectedHistory, current]
  );

  const getSourceFile = useCallback(async () => {
    dispatch({ type: "SET_LOADING", payload: true });

    await agent.SourceFiles.get(selectedWebsite, sourceFileId)
      .then((res) => {
        dispatch({ type: "SET_SOURCE_FILE", payload: res.sourceFile });
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });

    dispatch({ type: "SET_LOADING", payload: false });
  }, [selectedWebsite, sourceFileId, enqueueSnackbar, dispatch]);

  useEffect(() => {
    getSourceFile();
  }, [sourceFileId, selectedWebsite, getSourceFile]);

  const selectHistory = (payload: DialogState["selectedHistory"]) => {
    if (payload?._id === selectedHistory?._id) return;
    dispatch({ type: "SET_SELECTED_HISTORY", payload });
    dispatch({
      type: "INCREMENT_KEY",
    });
  };

  useEffect(() => {
    if (!sourceFile) return;

    dispatch({
      type: "EDIT_CURRENT",
      payload: {
        transcriptionRichText: sourceFile.transcriptionRichText || "",
        rephrasedTranscriptionRichText: sourceFile.rephrasedTranscriptionRichText || "",
        rephrasedTranscriptionTitle: sourceFile.rephrasedTranscriptionTitle || "",
      },
    });

    dispatch({
      type: "INCREMENT_KEY",
    });
  }, [sourceFile]);

  const pageActions = useMemo(() => {
    const pageTypes = getAvailablePageTypes(selectedWebsite);
    return pageTypes.map((pageType) => ({
      action: pageType.key,
      label: `Create a ${pageType.label} Article`,
      subAction: "",
    }));
  }, [selectedWebsite]);

  const hasText = (richText: string) => {
    try {
      const text = richTextEditorHelper.serialize(JSON.parse(richText));
      return text && text.split(/\s+/)?.length > 0;
    } catch (e) {
      return false;
    }
  };

  const isActionButtonEnabled = useMemo(() => {
    if (!rephrasedTranscriptionRichText || submitLoading) return false;
    return hasText(rephrasedTranscriptionRichText);
  }, [rephrasedTranscriptionRichText, submitLoading]);

  const isRephrasedButtonEnabled = useMemo(() => {
    if (!transcriptionRichText || submitLoading) return false;
    return hasText(transcriptionRichText);
  }, [transcriptionRichText, submitLoading]);

  const isSaveButtonEnabled = useMemo(() => {
    if (!sourceFile) return false;
    const properties = ["transcriptionRichText", "rephrasedTranscriptionRichText", "rephrasedTranscriptionTitle"];

    if (selectedHistory) {
      return (
        !isEqual(
          pick(selectedHistory, properties),
          pick(
            sourceFile.transcriptionHistory.find((item) => item._id === selectedHistory._id),
            properties
          )
        ) &&
        (isActionButtonEnabled || isRephrasedButtonEnabled)
      );
    }

    return !isEqual(current, pick(sourceFile, properties)) && (isActionButtonEnabled || isRephrasedButtonEnabled);
  }, [current, isActionButtonEnabled, isRephrasedButtonEnabled, selectedHistory, sourceFile]);

  const onChange = (newValue: Partial<DialogState["current"]>) => {
    if (!selectedHistory) {
      dispatch({ type: "EDIT_CURRENT", payload: newValue });
    } else {
      dispatch({ type: "EDIT_SELECTED_HISTORY", payload: newValue });
    }
  };

  const createNewPage = async (pageType: string) => {
    dispatch({ type: "SET_SUBMIT_LOADING", payload: true });

    try {
      await agent.SourceFiles.editSourceFile(selectedWebsite, sourceFile._id, selectedHistory?._id, {
        rephrasedTranscriptionRichText,
        rephrasedTranscriptionTitle,
        transcriptionRichText,
      });

      await agent.Pages.createPage(
        "",
        selectedWebsite.configurations.locale.defaultLocale,
        selectedWebsite._id,
        rephrasedTranscriptionTitle,
        "default",
        pageType,
        rephrasedTranscriptionRichText,
        sourceFile._id
      ).then((res) => {
        openPost(res.page._id, location.pathname);
      });
    } catch (e) {
      if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
    }

    dispatch({ type: "SET_SUBMIT_LOADING", payload: false });
  };

  const rephraseTranscript = async () => {
    dispatch({ type: "SET_SUBMIT_LOADING", payload: true });

    const transcriptionMarkdown = JSON.parse(transcriptionRichText)
      .map((n) => Node.string(n))
      .join("\n");

    await agent.SourceFiles.rephrase(selectedWebsite, sourceFile._id, {
      transcriptionMarkdown,
      transcriptionRichText,
    })
      .then((res) => {
        handleSourceFileChanged(res.sourceFile);
        dispatch({ type: "SET_SOURCE_FILE", payload: res.sourceFile });
        selectHistory(null);
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });

    dispatch({ type: "SET_SUBMIT_LOADING", payload: false });
  };

  const editSourceFile = async ({
    fields,
    withSuccessMessage = true,
  }: {
    fields: Partial<ISourceFile> | ISourceFile["transcriptionHistory"][number];
    withSuccessMessage?: boolean;
  }) => {
    await agent.SourceFiles.editSourceFile(selectedWebsite, sourceFile._id, selectedHistory?._id, fields)
      .then((res) => {
        handleSourceFileChanged(res.sourceFile);
        dispatch({ type: "SET_SOURCE_FILE", payload: res.sourceFile });
        if (withSuccessMessage) enqueueSnackbar(I18n.t("dialogs.rephrase.saved_success"));
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  const deleteHistory = async (historyItem: ISourceFile["transcriptionHistory"][number]) => {
    await agent.SourceFiles.deleteHistory(selectedWebsite, sourceFile._id, historyItem._id)
      .then((res) => {
        handleSourceFileChanged(res.sourceFile);
        if (selectedHistory && !res.sourceFile.transcriptionHistory.find((item) => item._id === selectedHistory._id)) {
          selectHistory(null);
        }

        dispatch({ type: "SET_SOURCE_FILE", payload: res.sourceFile });
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  return {
    sourceFile,
    transcriptionRichText,
    rephrasedTranscriptionRichText,
    rephrasedTranscriptionTitle,
    key,
    selectedHistory,
    loading,
    submitLoading,
    pageActions,
    isSaveButtonEnabled,
    isActionButtonEnabled,
    isRephrasedButtonEnabled,
    selectHistory,
    onChange,
    createNewPage,
    rephraseTranscript,
    editSourceFile,
    deleteHistory,
  };
};

export default useRephraseDialog;
