import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { isNumber } from "lodash";
import ClassNames from "classnames";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import { eActionIcon, IWebsite } from "../../../../reducers/constants/objectTypes";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import { getSearchConsoleUrl, getWebsiteRelativeUrl, isUrl, web } from "../../../../helpers/urlHelper";
import FunnelTableCell from "./FunnelTableCell";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Search } from "@material-ui/icons";
import { copyStringToClipboard } from "../../../../helpers/fbScraperHelper";
import CopyNameIcon from "../../../../icons/CopyNameIcon";
import CopyToClipboardIcon from "../../../../icons/CopyToClipboardIcon";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import AnalyticsIcon from "../../../../icons/AnalyticsIcon";

const useStyles = makeStyles((theme: Theme) => ({
  firstColumn: {
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    cursor: "pointer",
    transition: "all 0.3s",
  },
  avatar: {
    backgroundColor: "#B780FE!important",
    color: `${theme.palette.common.white}!important`,
  },
  firstCellUrl: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  itemWrapper: {
    "&:hover": {
      "& $actions": {
        display: "flex",
      },
    },
  },
  actions: {},
}));

type PropTypes = {
  funnelMap: any;
  item: any;
  selectedWebsite: IWebsite;
  hasBreakdown?: boolean;
  hasStrategyValues?: boolean;
  openReportInExpandView: (reportType: string, query?: string, currentUrl?: string) => void;
  showValue: boolean;
  showTraffic: boolean;
  cvr: {
    enabled: boolean;
    step1: string;
    step2: string;
  };
  priority: {
    enabled: boolean;
    step: string;
    column: string;
  };
};

const FunnelsItemRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const theme: any = useTheme();
  const {
    funnelMap,
    item,
    selectedWebsite,
    hasBreakdown,
    hasStrategyValues,
    cvr,
    priority,
    showValue,
    showTraffic,
    openReportInExpandView,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  /// conversion rate calculation between two steps
  const cvrValue = React.useMemo(() => {
    if (cvr.enabled && cvr.step1 && cvr.step2) {
      if (item[cvr.step1] === 0 && item[cvr.step2] === 0) return 0;
      if (item[cvr.step1] === 0) return 1;
      return item[cvr.step2] / item[cvr.step1];
    }
    return null;
  }, [cvr.enabled, cvr.step1, cvr.step2, item]);

  const priorityValue = React.useMemo(() => {
    if (priority.enabled && priority.column && priority.step) {
      if (item[priority.column] === 0 && item[priority.step] === 0) return "Low";
      const value = item[priority.column] / item[priority.step];
      if (value > 7) return "High";
      if (value > 4) return "Medium";
      return "Low";
    }
    return "-";
  }, [priority.enabled, priority.column, priority.step, item]);

  const actions = React.useMemo(() => {
    const values = [];
    if (isUrl(item.breakdown_field)) {
      values.push(
        ...[
          {
            text: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
            tooltip: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
            onClick: () => window.open(item.breakdown_field, "_blank"),
            iconName: eActionIcon.openInNew,
          },
          {
            text: I18n.t(`learning_center_content.tooltip.google_search_console`),
            tooltip: I18n.t(`learning_center_content.tooltip.google_search_console`),
            onClick: () => window.open(getSearchConsoleUrl(item.breakdown_field), "_blank"),
            iconName: eActionIcon.search,
          },
          {
            text: I18n.t(`learning_center_content.tooltip.page_report`),
            tooltip: I18n.t(`learning_center_content.tooltip.page_report`),
            onClick: () => {
              window.open(web.croReports("page-events", item.breakdown_field, location.pathname), "_blank");
            },
            iconName: eActionIcon.analytics,
          },
        ]
      );
    }
    if (!isUrl(item.breakdown_field)) {
      values.push({
        text: I18n.t(`learning_center_content.tooltip.copy_title`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_title`),
        onClick: () =>
          copyStringToClipboard(item.breakdown_field, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
        iconName: eActionIcon.copyName,
      });
    }

    if (isUrl(item.breakdown_field)) {
      values.push({
        text: I18n.t(`learning_center_content.tooltip.copy_url`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_url`),
        onClick: () =>
          copyStringToClipboard(item.breakdown_field, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
        iconName: eActionIcon.copyToClipboard,
      });
    }
    return values;
  }, [hasBreakdown, item.breakdown_field]);

  return (
    <MTableRow handleRowClick={() => console.log("click")} customClassName={classes.itemWrapper}>
      {hasBreakdown && (
        <MTableCellFirst
          actionClassName={classes.actions}
          avatarClassName={classes.avatar}
          toolTipProps={{ enterDelay: 300 }}
          columnWidth={"33%"}
          onClick={() => {
            if (isUrl(item.breakdown_field)) window.open(item.breakdown_field, "_blank");
          }}
          wrapperClassName={ClassNames(classes.firstColumn, isUrl(item.breakdown_field) && classes.firstCellUrl)}
          fontSize={theme.typography.pxToRem(14)}
          avatarType={funnelMap.breakdown_field}
          cellText={getWebsiteRelativeUrl(item.breakdown_field, selectedWebsite, true) || "(not set)"}
          actions={actions}
        />
      )}
      {hasBreakdown && hasStrategyValues && <MTableCellText text={""} />}
      {hasStrategyValues && showValue && (
        <FunnelTableCell
          value={item.value}
          columnWidth={"70px"}
          type={"currency"}
          tooltipTexts={[
            {
              name: "Topics",
              value: item.topics,
            },
            {
              name: "Volume",
              value: item.volume,
            },
            {
              name: "Funnel stage",
              value: item.conversion_potential,
            },
          ]}
        />
      )}
      {hasStrategyValues && showTraffic && (
        <FunnelTableCell
          columnWidth={"70px"}
          value={item.clicks}
          tooltipTexts={[
            {
              name: "Impressions",
              value: item.impressions,
            },
            {
              name: "Position",
              value: item.position,
            },
            {
              name: "CTR",
              value: item.ctr,
              format: "percentage",
            },
          ]}
        />
      )}
      {hasStrategyValues && <MTableCellText text={""} />}

      {Object.keys(funnelMap)
        .filter(
          (key) =>
            ![
              "breakdown_field",
              "topics",
              "volume",
              "conversion_potential",
              "position",
              "impressions",
              "ctr",
              "value",
              "clicks",
            ].includes(key)
        )
        .map((key) => {
          if (isNumber(item[key])) {
            return <MTableCellNumber type={"bigNumber"} key={key} value={item[key]} fontSize={14} />;
          }
          return <MTableCellText key={key} text={item[key]?.toString() || "-"} fontSize={14} />;
        })}
      {cvr.enabled && (
        <>
          <MTableCellText text={""} />
          <MTableCellNumber type={"percentage"} placeHolder={"-"} value={cvrValue} fontSize={14} />
        </>
      )}
      {hasStrategyValues && priority.enabled && (
        <>
          <MTableCellText text={""} />
          <MTableCellText text={priorityValue} fontSize={14} />
        </>
      )}
    </MTableRow>
  );
};

export default FunnelsItemRow;
