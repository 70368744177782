import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { uniqBy } from "lodash";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import MSelectMultiple from "../../../../../components/MSelectMultiple";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    width: "100%",
  },
  wrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 10,
    whiteSpace: "pre",
  },
}));

type PropTypes = {
  selectedWebsites: IWebsite[];
  websites: IWebsite[];

  handleWebsitesChange: (websites: IWebsite[]) => void;
};

const mapStateToProps = (state) => {
  return {
    websites: state.home.websites,
  };
};

const ExpertWebsites = (props: PropTypes) => {
  const classes = useStyles(props);
  const { websites, selectedWebsites = [], handleWebsitesChange } = props;

  return (
    <div className={classes.itemWrapper}>
      <Typography className={ClassNames(classes.title)} variant={"subtitle2"}>
        {I18n.t("experts.profile.websites")}
      </Typography>
      <MSelectMultiple
        wrapperClassName={classes.wrapper}
        options={websites}
        selectedOptions={selectedWebsites}
        optionLabel={"name"}
        optionValue={"_id"}
        borderWidth={1}
        height={38}
        fullWidth
        placeholder={I18n.t("experts.profile.select_websites")}
        handleOptionSelected={(website: IWebsite) => {
          handleWebsitesChange(uniqBy([...(selectedWebsites || []), website], "_id"));
        }}
        handleOptionRemoved={(website: IWebsite) => {
          const filteredWebsites = (selectedWebsites || []).filter((t) => t._id !== website._id);
          handleWebsitesChange(filteredWebsites);
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps, null)(ExpertWebsites);
