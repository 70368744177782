import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";

import { eUserRoles, eWidgetAction, eWidgetStatus, IWidget } from "../../../../../../reducers/constants/objectTypes";
import MCopyToClipboardButton from "../../../../../../components/MCopyToClipboardButton";
import agent from "../../../../../../agent";
import { push } from "connected-react-router";
import { web } from "../../../../../../helpers/urlHelper";
import { WIDGET_BUILDER } from "../../../../../../reducers/constants/actionTypes";
import ability from "../../../../../../casl/ability";
import MPageActionsButtonGroup from "../../../../../../components/MPageActionsButtonGroup";
import MInputsDialog from "../../../../../../components/MInputsDialog";

const useStyles = makeStyles(() => ({
  widgetActionWrapper: {
    background: "#FFFFFF",
    height: 77,
    padding: "0px 20px",
    gap: "20px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: "1px solid #E5E5E5",
  },
  button: {
    textTransform: "none",
  },
}));

type PropTypes = {
  widget: IWidget;
  extraButtonActions?: {
    action: string;
    label: string;
    onClick: () => void;
  }[];
  handleWidgetLoaded: (payload: { widget: IWidget }) => void;
  goToWidget: (id: string) => void;
  handleClose: () => void;
  cleanUp: () => void;
};

const mapStateToProps = (state) => ({
  widget: state.widgetBuilder.widget,
});

const mapDispatchToProps = (dispatch) => ({
  goToWidget: (id) => dispatch(push(web.widgetsEditor(id))),
  handleWidgetLoaded: (payload) => dispatch({ type: WIDGET_BUILDER.WIDGET_LOADED, payload }),
  cleanUp: () => dispatch({ type: WIDGET_BUILDER.WIDGET_CLEANUP }),
});

const WidgetEditorBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { widget, extraButtonActions = [] } = props;
  const { handleClose, goToWidget, cleanUp, handleWidgetLoaded } = props;
  const [templateDialog, setTemplateDialog] = React.useState({
    open: false,
    loading: false,
    inputs: [
      {
        name: "name",
        value: "",
        label: I18n.t("widgets_builder.header.template_dialog.label"),
        type: "text",
        placeholder: I18n.t("widgets_builder.header.template_dialog.placeholder"),
      },
    ],
  });
  const [loading, setLoading] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleAction = async (action?: eWidgetAction) => {
    if (
      // (action === eWidgetAction.publish || action === eWidgetAction.update) &&
      action === eWidgetAction.publish &&
      (widget.name === "" ||
        !widget.items ||
        widget.items.length === 0 ||
        (!widget.items[0].steps && widget.items[0].steps.length === 0) ||
        (!widget.items[0].steps[0].fields && widget.items[0].steps[0].fields.length === 0))
    ) {
      enqueueSnackbar(I18n.t("widgets_builder.actions.widget_is_not_completed"));
      return;
    }

    setLoading(true);

    await agent.Widgets.updateWidget(widget, action)
      .then((res) => {
        if (res.error) return;

        if (res.message) {
          enqueueSnackbar(res.message);
          handleClose();
        }

        if (res.widget) {
          if (action === eWidgetAction.duplicate) {
            enqueueSnackbar(I18n.t("widgets_builder.actions.widget_duplicate_successfully"));
            if (window.location.pathname.includes("campaigns/widgets/builder/")) {
              goToWidget(res.widget._id);
            } else {
              handleWidgetLoaded({ widget: res.widget });
            }
            return;
          }

          if (res.remoteResponse?.error) {
            enqueueSnackbar(I18n.t("widgets_builder.actions.widget_publish_fail"));
            enqueueSnackbar(I18n.t("widgets_builder.actions.changes_saved"));
          } else {
            enqueueSnackbar(I18n.t("widgets_builder.actions.widget_updated_successfully"));
          }
          handleWidgetLoaded({ widget: res.widget });
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.response?.body?.message || e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteWidget = () => {
    agent.Widgets.deleteWidget(widget._id)
      .then((res) => {
        if (res.error) return;
        enqueueSnackbar(res.message ?? I18n.t("widgets_main.list.widget_deleted"));
        handleWidgetLoaded({ widget: res.widget });
        handleClose();
      })
      .catch((e) => {
        enqueueSnackbar(e.response?.body?.message || e.message);
      });
  };

  const exportTemplate = () => {
    setTemplateDialog((prev) => ({ ...prev, loading: true }));
    agent.Widgets.exportTemplate(widget, templateDialog.inputs[0].value)
      .then((res) => {
        console.log("exportTemplate", res);
        if (res.error) return;
        enqueueSnackbar(res.message ?? I18n.t("widgets_main.list.template_saved"));
      })
      .catch((e) => {
        enqueueSnackbar(e.response?.body?.message || e.message);
      })
      .finally(() => {
        setTemplateDialog((prev) => ({
          ...prev,
          loading: false,
          open: false,
          inputs: [{ ...prev.inputs[0], value: "" }],
        }));
      });
  };

  const handleActionClick = async (action: string) => {
    const extraButtonAction = extraButtonActions && extraButtonActions.find((a) => a.action === action);
    if (extraButtonAction) {
      if (action === "Insert") {
        if (widget.status === eWidgetStatus.Active && widget.uploadedId) {
          await handleAction(eWidgetAction.update);
        } else {
          await handleAction(eWidgetAction.publish);
        }
      }
      extraButtonAction.onClick();
      return;
    }

    switch (action) {
      case eWidgetAction.publish:
        handleAction(eWidgetAction.publish);
        break;
      case eWidgetAction.update:
        handleAction(eWidgetAction.update);
        break;
      case eWidgetAction.delete:
      case eWidgetAction.delete_permanently:
        deleteWidget();
        break;
      case eWidgetAction.export_template:
        setTemplateDialog((prev) => ({ ...prev, open: true }));
        break;
      case eWidgetAction.duplicate:
        handleAction(eWidgetAction.duplicate);
        break;
      case eWidgetAction.restore:
        handleAction(eWidgetAction.publish);
        break;
      default:
        throw new Error(`Unknown type: ${action}`);
    }
  };

  const actions: {
    action: string;
    label: string;
    onClick?: () => void;
  }[] = [...extraButtonActions];

  switch (widget.status) {
    case eWidgetStatus.Active:
      actions.push(
        { action: eWidgetAction.update, label: I18n.t("widgets_builder.actions.update") },
        { action: eWidgetAction.delete, label: I18n.t("widgets_builder.actions.delete") },
        { action: eWidgetAction.duplicate, label: I18n.t("widgets_builder.actions.duplicate") }
      );
      break;
    case eWidgetStatus.Deleted:
      actions.push(
        { action: eWidgetAction.restore, label: I18n.t("widgets_builder.actions.restore") },
        { action: eWidgetAction.delete_permanently, label: I18n.t("widgets_builder.actions.delete_permanently") }
      );
      break;
    case eWidgetStatus.Draft:
      actions.push(
        { action: eWidgetAction.publish, label: I18n.t("widgets_builder.actions.publish") },
        { action: eWidgetAction.duplicate, label: I18n.t("widgets_builder.actions.duplicate") },
        { action: eWidgetAction.delete, label: I18n.t("widgets_builder.actions.delete") }
      );
      break;
    default:
      throw new Error(`Unhandled status code - ${widget.status}`);
  }

  if (ability.can(eUserRoles.ADMIN, "role")) {
    actions.push({ action: eWidgetAction.export_template, label: I18n.t("widgets_builder.actions.export_template") });
  }

  const templateActions = [];

  if (widget.isTemplate) {
    if (widget.status === eWidgetStatus.Deleted) {
      templateActions.push({
        action: eWidgetAction.restore,
        label: I18n.t("widgets_builder.template_actions.restore"),
      });
    } else {
      templateActions.push({ action: eWidgetAction.update, label: I18n.t("widgets_builder.template_actions.update") });
      templateActions.push({
        action: eWidgetAction.duplicate,
        label: I18n.t("widgets_builder.template_actions.duplicate"),
      });
      templateActions.push({ action: eWidgetAction.delete, label: I18n.t("widgets_builder.template_actions.delete") });
    }
  }

  return (
    <>
      <div className={classes.widgetActionWrapper}>
        <div>
          {widget?.uploadedId && widget.status === eWidgetStatus.Active && (
            <MCopyToClipboardButton
              value={`<div class="entail-ai-widget-${widget?.uploadedId}" entail-widget-id="${widget?.uploadedId}"></div>`}
              label={I18n.t("widgets_builder.bottom_bar.copy_code_snippet")}
            />
          )}
        </div>
        <MPageActionsButtonGroup
          loading={loading}
          customClass={classes.button}
          mainAction={widget.isTemplate ? templateActions[0] : actions[0]}
          handleActionOnClick={(option) => {
            handleActionClick(option.action);
          }}
          actions={widget.isTemplate ? templateActions : actions}
        />
      </div>
      {templateDialog.open && (
        <MInputsDialog
          open={templateDialog.open}
          title={I18n.t("widgets_builder.header.template_dialog.title")}
          inputs={templateDialog.inputs}
          buttonProps={{
            text: I18n.t("widgets_builder.header.template_dialog.export"),
            loading: templateDialog.loading,
            disabled: templateDialog.inputs[0].value === "",
          }}
          handleChange={(input) => {
            setTemplateDialog((prev) => ({
              ...prev,
              inputs: prev.inputs.map((i) => (i.name === input.name ? { ...i, value: input.value } : i)),
            }));
          }}
          handleClose={() => {
            setTemplateDialog((prev) => ({ ...prev, open: false }));
          }}
          handleSubmit={exportTemplate}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetEditorBottomBar);
