import * as React from "react";
import _ from "lodash";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { IProduct, IWebsite } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";
import agent from "../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  productWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 3,
  },
  title: {
    minWidth: 80,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 7,
  },
  selectCustomClass: {
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  product: IProduct;

  onProductChange: (product: IProduct) => void;
};

const PageEditorProduct = (props: PropTypes) => {
  const classes = useStyles(props);
  const { product, selectedWebsite, onProductChange } = props;
  const [items, setItems] = React.useState<IProduct[]>([]);
  const theme = useTheme<Theme>();
  const allItems = product && product._id ? [product, ...items] : items;

  return (
    <div className={classes.productWrapper}>
      <MSelect
        customClass={classes.selectCustomClass}
        isClearable={false}
        options={allItems}
        value={product ? _.find(allItems, { _id: product?._id || null }) : null}
        searchable
        placeholder={I18n.t("edit_post.review_item_ph")}
        height={31}
        borderWidth={"0px"}
        isBold={false}
        menuPlacement={"bottom"}
        singleValueStyles={{ fontSize: theme.typography.pxToRem(12) }}
        handleInputChange={(search) => {
          if (search === "") {
            setItems([]);
            return;
          }
          agent.Product.autoComplete(selectedWebsite._id, search)
            .then((results) => {
              setItems(results.items);
            })
            .catch((e) => {
              console.log(e);
            });
        }}
        handleChange={(selectedItem) => {
          onProductChange(selectedItem);
        }}
        optionLabel={"name"}
        optionValue={"_id"}
      />
    </div>
  );
};

export default PageEditorProduct;
