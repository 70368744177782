import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 17,
    height: 15,
    fill: "transparent",
  },
}));

type PropTypes = {
  className?: string;
};

const PaddingLeftIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 17 15"}>
      <path
        d="M1.3999 1.19995V16.4M11.1999 5.59995L8.5999 5.59995C7.27442 5.59995 6.1999 6.67447 6.1999 7.99995V10.6C6.1999 11.9254 7.27442 13 8.5999 13L11.1999 13C12.5254 13 13.5999 11.9254 13.5999 10.6V7.99995C13.5999 6.67447 12.5254 5.59995 11.1999 5.59995Z"
        stroke="#333333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default PaddingLeftIcon;
