import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import {
  eActionIcon,
  ePageType,
  IKeywordStrategyWithTopics,
  IWebsite,
} from "../../../../reducers/constants/objectTypes";
import { PAGE_TYPE_COLORS } from "../../../../reducers/constants/consts";
import ClassNames from "classnames";
import { List } from "@material-ui/core";

import { ListItem } from "@material-ui/core";
import OutlinePageListItem from "./OutlinePageListItem";
import { connect } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $actions": {
        display: "flex",
      },
      "& $actionsWrapper": {
        display: "flex",
      },
    },
    display: "block",
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
  },
  listItem: {
    display: "block",
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
  },
  directory: {
    "&:after": {
      position: "absolute",
      top: 39,
      bottom: 18,
      left: 32,
      width: 1,
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[ePageType.Blog],
      zIndex: 1,
    },
  },
  actionsWrapper: {
    display: "none",
    justifyContent: "flex-end",
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createButton: {
    height: 36,
    marginBottom: "5px",
  },
  hideBtn: {
    visibility: "hidden",
  },
  actions: {},
}));

type PropTypes = {
  item: IKeywordStrategyWithTopics;
  approve: (outline: any, postType: string) => void;
  deleteOutline: (outline: any) => void;
  onClick: (outline: any, pageTab: "keywords" | "broadKeywords") => void;
  redirect: (url: string) => void;
  setSelect(selected: boolean): void;
  selected: boolean;
  isChild?: boolean;
  main?: boolean;
  selectedWebsite: IWebsite;
  openInternalNoteDialog?: (strategyKeyword: any) => void;
};

const OutlinePageListItemCluster = (props: PropTypes) => {
  const classes = useStyles(props);

  const { item } = props;

  return (
    <ListItem className={ClassNames(classes.listItem, classes.directory)}>
      <>
        <OutlinePageListItem main {...props} />
        {item.topicGroup && item.topicGroup.length > 0 && (
          <List className={classes.itemWrapper}>
            {item.topicGroup.map((topic, index) => {
              return (
                <ListItem className={classes.listItem}>
                  <OutlinePageListItem key={index} isChild main={false} {...props} item={topic} />
                </ListItem>
              );
            })}
          </List>
        )}
      </>
    </ListItem>
  );
};

export default OutlinePageListItemCluster;
