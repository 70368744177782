"use strict";
import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
import { useSlate } from "slate-react";
import { ComponentType } from "react";
import helper from "../../../helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  activeIcon: {
    fill: theme.palette.primary.main,
  },
}));

type propTypes = {
  tooltip: string;
  format: string;
  Icon: ComponentType<any>;
};

function BlockButton(props: propTypes) {
  const classes = useStyles(props);
  const editor = useSlate();
  const { Icon, format, tooltip } = props;

  return (
    <Tooltip enterDelay={2000} title={tooltip}>
      <IconButton
        className={classes.button}
        disableRipple
        onMouseDown={(event) => {
          event.preventDefault();
          helper.toggleBlock(editor, format);
        }}
        aria-label={format}
      >
        <Icon className={ClassNames(classes.icon, helper.isBlockActive(editor, format) && classes.activeIcon)} />
      </IconButton>
    </Tooltip>
  );
}

export default BlockButton;
