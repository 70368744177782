import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ClassNames from "classnames";
import _ from "lodash";
import Divider from "@material-ui/core/Divider";
import { ePostType, ICategory, ITopic, IWebsite } from "../../../../../reducers/constants/objectTypes";
import MSelect from "../../../../../components/MSelect";
import { MANAGE_WEBSITES } from "../../../../../reducers/constants/actionTypes";
import agent from "../../../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  groupWrapper: {
    height: "100%",
    // padding: 20,
    backgroundColor: theme.palette.common.white,
    maxWidth: 280,
    width: "100%",
    borderRight: `${theme.palette.divider} solid 1px`,
    zIndex: 1,
  },
  typeSelector: {
    padding: 20,
  },
  listContainer: {
    display: "inline",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  groupList: {
    position: "relative",
    overflow: "auto",
    height: "calc(100% - 40px)",
  },
  listItem: {
    cursor: "pointer",
    paddingLeft: 30,
  },
  itemPrimaryText: {
    color: theme.palette.text.secondary,
  },
  itemSecondaryText: {
    color: theme.palette.text.disabled,
    marginTop: 5,
  },
  itemSelected: {
    color: theme.palette.primary.main,
  },
  missingDescription: {
    position: "absolute",
    content: " ",
    right: 10,
    backgroundColor: "#FF0000",
    borderRadius: "50%",
    opacity: 0.5,
    width: 10,
    height: 10,
    pointerEvents: "none",
  },
  topDivider: {
    marginBottom: 20,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  categoriesLoaded: boolean;
  categories: ICategory[];
  selectedCategory: ICategory;

  handleCategoryClick: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  // topicsFilter: state.manageWebsites.topicsFilter,
  // topicsLoaded: state.manageWebsites.topicsLoaded,
  categoriesLoaded: state.category.categoriesLoaded,
  categories: state.category.categories,
  selectedCategory: state.manageWebsites.selectedCategory,
});

const mapDispatchToProps = (dispatch) => ({
  // handleTopicsLoaded: (payload) =>
  //   dispatch({type: MANAGE_WEBSITES.ON_TOPIC_LOADED, payload}),
  // handleTopicsFilterChanged: (filter) =>
  //   dispatch({type: MANAGE_WEBSITES.FILTER_TOPIC_LIST_CHANGED, filter}),
  handleCategoryClick: (category) => dispatch({ type: MANAGE_WEBSITES.ON_CATEGORY_SELECTED, category }),
});

const TopicList = (props: PropTypes) => {
  const classes = useStyles(props);
  const { categoriesLoaded, selectedCategory, categories, handleCategoryClick } = props;

  React.useEffect(() => {
    if (!categoriesLoaded) {
      return;
    }
    handleCategoryClick(categories.length > 0 ? categories[0] : null);
  }, [categoriesLoaded]);

  const getCategoryLocales = (category) => {
    const locales = Object.keys(category.translations).join(", ");
    if (locales) {
      return `- ${locales}`;
    }
    return "";
  };

  return (
    <div className={classes.groupWrapper}>
      <div className={classes.typeSelector} />
      <Divider className={classes.topDivider} />
      <div className={classes.listContainer}>
        <List component="nav" className={classes.groupList}>
          {categories.length > 0 &&
            categories.map((category) => (
              <ListItem
                // button
                key={category._id}
                className={classes.listItem}
                onClick={(event) => handleCategoryClick(category)}
              >
                <ListItemText
                  primary={`${category.name}`}
                  primaryTypographyProps={{
                    variant: "subtitle2",
                    className: ClassNames(
                      classes.itemPrimaryText,
                      category._id === selectedCategory?._id && classes.itemSelected
                    ),
                  }}
                  secondary={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {category.pagesCount > 0 && (
                        <span>
                          {I18n.t("upload_post.groups_side_bar.posts", {
                            count: category.pagesCount,
                            locales: getCategoryLocales(category),
                          })}
                        </span>
                      )}
                      {category.questionsCount > 0 && (
                        <span>
                          {I18n.t("upload_post.groups_side_bar.questions", {
                            count: category.questionsCount,
                            locales: getCategoryLocales(category),
                          })}
                        </span>
                      )}
                    </div>
                  }
                  secondaryTypographyProps={{
                    variant: "body2",
                    className: ClassNames(
                      classes.itemSecondaryText,
                      category._id === selectedCategory?._id && classes.itemSelected
                    ),
                  }}
                />
                <div
                  className={ClassNames(
                    (!category.description || category.description.length === 0) && classes.missingDescription
                  )}
                />
              </ListItem>
            ))}
          {!categoriesLoaded && (
            <ListItem button>
              <ListItemText primary="Loading..." />
            </ListItem>
          )}
        </List>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicList);
