import * as React from "react";
import _, { uniqBy } from "lodash";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { IProduct, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import agent from "../../../../../../../agent";
import MSelect from "../../../../../../../components/MSelect";
import MTopic from "../../../../../../../components/MTopic";
import filter from "lodash/filter";

const useStyles = makeStyles((theme: Theme) => ({
  writersWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  writersWrapperFullWidth: {
    width: "100%",
  },
  writerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // whiteSpace: 'pre',
  },
  writerWrapperFullWidth: {
    flexDirection: "column",
    alignItems: "start",
    gap: 7,
  },
  writerTitle: {
    maxWidth: 65,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    whiteSpace: "pre",
  },
  writerTitleFullWidth: {
    maxWidth: "unset",
  },
  clearIcon: {
    fill: "#808080",
    marginLeft: 5,
    cursor: "pointer",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  select: {
    backgroundColor: "#EEF2F9",
    borderRadius: 5,
  },
  productsWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: 7,
    gap: 5,
  },
  tagCustomClass: {
    minHeight: 24,
    backgroundColor: theme.palette.divider,
  },
  tagIconCustomClass: {
    color: theme.palette.text.primary,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  allProductsSelected: boolean;
  selectedProducts: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
  handleAllProductsSelected: (allProductsSelected: boolean) => void;
};

const EditLinkSplitTestProductPicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedProducts = [],
    selectedWebsite,
    allProductsSelected = false,
    handleProductsChange,
    handleAllProductsSelected,
  } = props;
  const [items, setItems] = React.useState<IProduct[]>([]);
  const getProducts = React.useCallback(
    (search = "") => {
      agent.Product.autoComplete(selectedWebsite._id, search)
        .then((results) => {
          setItems(results.items);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [selectedWebsite]
  );

  React.useEffect(() => {
    getProducts();
  }, [getProducts]);

  console.log("selectedProducts", selectedProducts);
  const allItems = uniqBy([{ name: "All Products", _id: "ALL_PRODUCTS" }, ...selectedProducts, ...items], (p) => p._id);

  const onProductSelected = (p) => {
    if (p._id === "ALL_PRODUCTS") {
      handleAllProductsSelected(!allProductsSelected);
      return;
    }
    if (allProductsSelected) {
      return;
    }
    handleProductsChange(uniqBy([...selectedProducts, p], "_id"));
  };

  const onProductRemoved = (product) => {
    handleProductsChange(filter(selectedProducts, (pProduct) => pProduct._id !== product._id));
  };

  return (
    <div className={classes.wrapper}>
      <MSelect
        options={allItems}
        value={null}
        customClass={classes.select}
        optionValue={"_id"}
        optionLabel={"name"}
        borderWidth={"0px"}
        getDisplayLabel={(opt) => opt.name}
        height={37}
        placeholder={I18n.t("edit_post.select_products_ph")}
        searchable
        handleInputChange={(search) => {
          getProducts(search);
        }}
        handleChange={(tag) => {
          onProductSelected(tag);
        }}
      />
      {
        <div className={classes.productsWrapper}>
          {selectedProducts.length > 0 &&
            selectedProducts.map((product) => (
              <MTopic
                fontSize={12}
                key={product._id}
                tag={product.name}
                onRemoveClicked={(e) => onProductRemoved(product)}
                tagCustomClass={classes.tagCustomClass}
                tagIconCustomClass={classes.tagIconCustomClass}
              />
            ))}
          {allProductsSelected && (
            <MTopic
              fontSize={12}
              tag={"All Products"}
              onRemoveClicked={(e) => handleAllProductsSelected(false)}
              tagCustomClass={classes.tagCustomClass}
              tagIconCustomClass={classes.tagIconCustomClass}
            />
          )}
        </div>
      }
    </div>
  );
};

export default EditLinkSplitTestProductPicker;
