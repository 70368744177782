import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import { IQuestion, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import QuestionModeratorPanel from "./CommunityModeratorPanel";
import CommunityUserInformation from "./CommunityUserInformation";
import RichTextEditor from "../../../../../../editor/RichTextEditor";
import MPostedTimePicker from "../../../../../../components/MPostedTimePicker";
import moment from "moment/moment";
import { AbilityContext } from "../../../../../../casl/can";
import { useParams } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
  questionWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    opacity: (props: PropTypes) => (props.isAnswerEditor ? "0.7" : "1"),
    pointerEvents: (props: PropTypes) => (props.isAnswerEditor ? "none" : "initial"),
  },
  questionFbId: {
    display: "flex",
    justifyContent: "center",
    marginLeft: 20,
    marginBottom: 0,
    marginTop: 0,
    minWidth: 80,
    width: 80,
  },
  fbId: {
    color: theme.palette.text.hint,
  },
  questionSubject: {
    fontSize: theme.typography.pxToRem(30),
    lineHeight: theme.typography.pxToRem(36),
    fontWeight: theme.typography.fontWeightBold as any,
    minHeight: 50,
    margin: "10px 0px 20px 0px",
  },
  subject: {
    fontSize: theme.typography.pxToRem(30),
    lineHeight: theme.typography.pxToRem(40),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  questionTextField: {
    marginTop: 0,
  },
  question: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.3,
  },
  questionOriginalTextField: {
    marginTop: 20,
    width: "100%",
  },
  questionOriginalText: {},
  actions: {
    display: "flex",
    alignSelf: "flex-end",
  },
  collapsedOriginalText: {
    width: "100%",
  },
  richTextViewer: {
    padding: 0,
  },
  titleCustomClass: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
    fontSize: theme.typography.pxToRem(30),
    lineHeight: "36px",
  },
  editorToolbar: {
    overflowX: "auto",
    padding: "0 20px",
    justifyContent: "space-between",
  },
  editable: {
    fontSize: theme.typography.pxToRem(16),
    paddingTop: 5,
    minHeight: "142px !important",
    paddingLeft: 20,
    paddingRight: 20,
  },
  datePicker: {
    width: 200,
    alignSelf: "flex-end",
  },
  questionTopBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "5px 10px -20px 0",
    fontSize: theme.typography.pxToRem(12),
  },
}));

type PropTypes = {
  selectedQuestion: IQuestion;
  selectedWebsite: IWebsite;
  isAnswerEditor: Boolean;
  handleQuestionChange: Function;
  handleQuestionDateChanged: Function;
};

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,
    selectedQuestion: state.communityEditor.question,
    isAnswerEditor: state.communityEditor.isAnswerEditor,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleQuestionChange: (question) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_QUESTION_CHANGED, question }),
  handleQuestionDateChanged: (question) =>
    dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_QUESTION_POSTED_TIME_CHANGED, question }),
});

const CommunityEditorQuestion = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedQuestion, selectedWebsite, handleQuestionChange, handleQuestionDateChanged } = props;
  const ability = React.useContext<any>(AbilityContext);
  const { type } = useParams();
  const convertTextToRichText = (initialText) => {
    return JSON.stringify([
      {
        type: "paragraph",
        children: [{ text: initialText || "" }],
      },
    ]);
  };

  return (
    <Paper elevation={0} className={classes.questionWrapper}>
      <RichTextEditor
        fontSize={16}
        droppableId={`question_editor_answer_${selectedQuestion._id}`}
        key={selectedQuestion._id}
        editableCustomClass={classes.editable}
        toolbarCustomClass={classes.editorToolbar}
        titleCustomClass={classes.titleCustomClass}
        website={selectedWebsite}
        title={selectedQuestion.title}
        withCustomElements={true}
        products={selectedQuestion.products}
        handleProductsChange={(products) => handleQuestionChange({ ...selectedQuestion, products })}
        onTitleChanged={(title) => {
          handleQuestionChange({ ...selectedQuestion, title });
        }}
        initialValue={selectedQuestion.richText || convertTextToRichText(selectedQuestion.text)}
        onValueChanged={(value, raw) => handleQuestionChange({ ...selectedQuestion, text: raw, richText: value })}
      >
        <div className={classes.questionTopBar}>
          <MPostedTimePicker
            date={moment(selectedQuestion.postedTime)}
            onDateChanged={(date) =>
              handleQuestionDateChanged({ ...selectedQuestion, postedTime: moment(date).toISOString() })
            }
          />
        </div>
      </RichTextEditor>
      {type === "moderator" && ability.can("view", "editor_tab") && selectedQuestion.moderatorState === "NEW" && (
        <QuestionModeratorPanel />
      )}
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorQuestion);
