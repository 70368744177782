import React from "react";
import { IconButton, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";

import LaunchIcon from "../../../../../../../../icons/LaunchIcon";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: 5,
    "&:hover": {
      "& $icon": {
        fill: theme.palette.primary.light,
      },
      "& $text": {
        color: theme.palette.primary.light,
      },
    },
  },
  text: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium as any,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  iconButton: {
    padding: 0,
    borderRadius: 0,
  },
  icon: {
    fontSize: 15,
    fill: theme.palette.text.primary,
  },
}));

type PropTypes = {
  href: string;
};

const EditProductHref = ({ href }: PropTypes) => {
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={() => window.open(href, "_blank")}>
      <Typography className={classes.text} variant="h6">
        {I18n.t("widgets_builder.widget_editor.fields.edit_product")}
      </Typography>
      <IconButton target={"_blank"} href={href} className={classes.iconButton} disableRipple>
        <LaunchIcon className={classes.icon} />
      </IconButton>
    </div>
  );
};

export default EditProductHref;
