import React from "react";
import { I18n } from "react-redux-i18n";
import { Button } from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { IProduct } from "../../../../reducers/constants/objectTypes";
import ModalInputField from "../../components/ModalInputField";
import MTextFieldV2 from "../../../../components/MTextFieldV2";
import ModalDropdownField from "../../components/ModalDropdownField";
import CurrencyPickerElement from "../../../../editor/elementsStyles/ElementComponents/CurrencyPickerElement";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    borderRadius: 3,
    backgroundColor: "#EEF2F9",
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectIndicator: {
    marginRight: 7,
    width: 12,
    color: theme.palette.text.primary,
  },
}));

type PropTypes = {
  product: IProduct;
  onChange: (product: IProduct) => void;
};

const PricingView = (props: PropTypes) => {
  const classes = useStyles();
  const { product, onChange } = props;
  const theme: any = useTheme();

  const paymentOptions = [
    {
      label: "Free",
      value: "free",
    },
    {
      label: "Recurring",
      value: "recurring",
    },
    {
      label: "One time",
      value: "one_time",
    },
  ];

  const freeTrialOptions = [
    {
      label: "Not available",
      value: false,
    },
    {
      label: "Available",
      value: true,
    },
  ];

  return (
    <>
      <ModalInputField title={I18n.t("product_dialog.pricing_view.plans_type")}>
        <ModalDropdownField
          options={paymentOptions}
          value={paymentOptions.find((option) => option.value === product.pricing?.payment)}
          handleOnChange={(option) => onChange({ ...product, pricing: { ...product.pricing, payment: option.value } })}
          placeholder={I18n.t("product_dialog.pricing_view.plans_type_ph")}
          optionLabel="label"
          optionValue="value"
        />
      </ModalInputField>
      <ModalInputField title={I18n.t("product_dialog.pricing_view.currency")}>
        <CurrencyPickerElement
          value={product.pricing?.currency}
          onChange={(currency) => onChange({ ...product, pricing: { ...product.pricing, currency } })}
          height={50}
          placeholder={I18n.t("product_dialog.common.select_ph")}
        />
      </ModalInputField>
      <ModalInputField title={I18n.t("product_dialog.pricing_view.starting_price")}>
        <MTextFieldV2
          value={product.pricing?.startingPrice}
          placeholder={I18n.t("product_dialog.pricing_view.starting_price_ph")}
          onValueChange={(startingPrice) => onChange({ ...product, pricing: { ...product.pricing, startingPrice } })}
          type="number"
        />
      </ModalInputField>
      <ModalInputField title={I18n.t("product_dialog.pricing_view.free_trial")}>
        <ModalDropdownField
          options={freeTrialOptions}
          value={freeTrialOptions.find((option) => option.value == !!product.pricing?.freeTrial)}
          handleOnChange={(option) =>
            onChange({ ...product, pricing: { ...product.pricing, freeTrial: option.value } })
          }
          placeholder={I18n.t("product_dialog.pricing_view.free_trial")}
          optionLabel="label"
          optionValue="value"
        />
      </ModalInputField>
      {product.pricing?.freeTrial && (
        <ModalInputField title={I18n.t("product_dialog.pricing_view.free_trial_days")}>
          <MTextFieldV2
            type="number"
            value={product.pricing?.freeTrialDays || 0}
            onNumberChange={(freeTrialDays) => onChange({ ...product, pricing: { ...product.pricing, freeTrialDays } })}
          />
        </ModalInputField>
      )}
    </>
  );
};

export default PricingView;
