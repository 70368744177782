import { WORKFLOW_ALL_STATES } from "./constants/actionTypes";

const initialState = {
  loaded: false,
  refreshItemList: false,
  pagesLoaded: false,
  items: [],
  paginationPagesCount: 0,
  pagesCount: 0,
  filter: {
    searchText: "",
    postType: [],
    postStatus: [],
    sortField: {
      direction: "desc",
      field: "updatedAt",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WORKFLOW_ALL_STATES.LOADED:
      return {
        ...state,
        loaded: true,
        refreshItemList: false,
        items: action.payload.pageClusters,
        paginationPagesCount: action.payload.paginationPagesCount,
        pagesCount: action.payload.pagesCount,
        pagesLoaded: true,
      };
    case WORKFLOW_ALL_STATES.UPDATE_PAGE:
      return {
        ...state,
        items: action.payload.clusters,
      };
    case WORKFLOW_ALL_STATES.REFRESH:
      return {
        ...state,
        refreshItemList: true,
      };
    case WORKFLOW_ALL_STATES.WORKFLOW_ALL_STATES_ON_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
