import * as React from "react";
import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { Property } from "csstype";

const breakpoints = createBreakpoints({});

const FontWeightLight = 400;
const FontWeightRegular = 500;
const FontWeightMedium = 600;
const FontWeightBold = 600;

// https://material-ui.com/customization/default-theme/?expend-path=$.palette.primary
export default createTheme({
  shape: {
    borderRadius: 5,
  },
  direction: "ltr",
  typography: {
    fontFamily: ['"Montserrat"', "sans-serif"].join(","),
    fontWeightLight: FontWeightLight as Property.FontWeight,
    fontWeightRegular: FontWeightRegular as Property.FontWeight,
    fontWeightMedium: FontWeightMedium as Property.FontWeight,
    fontWeightBold: FontWeightBold as Property.FontWeight,
    h1: {
      fontSize: "3.125rem",
      fontWeight: FontWeightBold,
      lineHeight: "3.55rem",
      marginBottom: ".875rem",
      [breakpoints.down("sm")]: {
        fontSize: "2.25rem",
        fontWeight: "normal",
        lineHeight: "2.55rem",
      },
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: FontWeightBold,
      lineHeight: "2.8rem",
      marginBottom: "1.75rem",
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: "1.8rem",
      marginBottom: "1.75rem",
    },
    h4: {
      fontSize: "1.375rem",
      fontWeight: FontWeightBold,
      lineHeight: "1.75rem",
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: FontWeightBold,
      lineHeight: "1.35rem",
    },
    h6: {
      fontSize: "1.125rem",
      lineHeight: "1.35rem",
    },
    subtitle1: {
      fontSize: "1.125rem",
      fontWeight: FontWeightBold,
      lineHeight: "1.45rem",
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: FontWeightBold,
      lineHeight: "1.3rem",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.3rem",
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: "1.1375rem",
      color: "#9e9e9e",
    },
  },
  palette: {
    primary: {
      main: "#4353FF",
      light: "#3C49DA",
      dark: "#9e9e9e",
    },
    secondary: {
      main: "#F2F4FC",
      // main: '#f5f6f8',

      light: "#535869",
      dark: "#edf0f5",
    },
    background: {
      paper: "#ffffff",
      default: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#808080",
      disabled: "#9e9e9e",
      hint: "#9e9e9e",
    },
    divider: "#e5e5e5",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          height: "100%",
        },
        body: {
          color: "#333333",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "#f5f6f8",
        },
      },
    },
    MuiInput: {
      root: {
        fontSize: 14,
        color: "#4a4a4a",
      },
      fullWidth: {
        // color: 'red',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#333333",
        boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)",
        fontSize: 14,
      },
    },
    MuiPaper: {
      root: {
        border: "#e5e5e5 solid 1px",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "unset",
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0,0,0,0.8)",
      },
    },
  },
});
