import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { Divider } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { ePageAction, IPage, IPageCluster, IWebsite } from "../../reducers/constants/objectTypes";
import PageEditorLocaleDropDown from "./PageEditorLocaleDropDown";
import PageEditorPageStatesDropDown from "./PageEditorPageStatesDropDown";
import agent from "../../agent";
import { web } from "../../helpers/urlHelper";
import ArrowLeftIcon from "../../icons/ArrowLeftIcon";
import PageEditorUpdateButton from "./PageEditorUpdateButton";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 55,
    paddingRight: 25,
    paddingLeft: 25,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
    marginRight: 40,
  },
  rightSide: {},
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  postDetails: {
    display: "flex",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    marginRight: 20,
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  headerTitle: {
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightBold as any,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  divider: {
    height: 26,
    margin: "0 7px 0 15px",
  },
  middleDivider: {
    height: 26,
    margin: "0 15px 0 7px",
  },
  headerType: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  marginLeft: {
    marginLeft: 10,
  },
  marginRight: {
    marginRight: 10,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  pageLoaded: boolean;
  pageCluster: IPageCluster;
  page: IPage;

  exitScreen: () => void;
  returnToPreviousScreen: () => void;
};

const PageEditorHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, pageLoaded, page, pageCluster, exitScreen, returnToPreviousScreen } = props;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  // TODO: rewrite responses with locales
  const handleCreatePage = (locale, url) => {
    agent.Pages.createPage(page.cluster?._id || page.cluster, locale, page.website, page.title, page.variant, page.type)
      .then((res) => {
        history.push(web.editPage(res.page._id, url));
      })
      .catch((e) => enqueueSnackbar(e?.response?.body?.message || "Something went wrong"));
  };
  return (
    <>
      {pageLoaded && (
        <div className={classes.toolbar}>
          <div className={classes.leftSide}>
            <div>
              <ArrowLeftIcon className={classes.arrowIcon} onClick={exitScreen} />
            </div>
            <div className={classes.toolbarDetails}>
              <div className={classes.postDetails}>
                <Typography className={classes.headerTitle} color={"textPrimary"} variant={"body2"}>
                  {selectedWebsite?.name}
                </Typography>
                <Divider orientation={"vertical"} className={classes.divider} />
                <PageEditorLocaleDropDown handleCreatePage={handleCreatePage} />
                <Divider orientation={"vertical"} className={classes.middleDivider} />
                <Typography className={classes.headerType} color={"textPrimary"} variant={"body2"}>
                  {page.type.replace("_main_page", " vertical page")}
                </Typography>
                <Divider orientation={"vertical"} className={classes.divider} />
                {page.optimize && (
                  <>
                    <Typography className={classes.headerType} color={"error"} variant={"body2"}>
                      {I18n.t("edit_post.optimization")}
                    </Typography>
                    <Divider orientation={"vertical"} className={classes.divider} />
                  </>
                )}
                <PageEditorPageStatesDropDown />
              </div>
            </div>
          </div>
          <PageEditorUpdateButton
            publishAction={page.optimize ? ePageAction.update : ePageAction.publish}
            returnToPreviousScreen={returnToPreviousScreen}
          />
        </div>
      )}
    </>
  );
};

export default PageEditorHeader;
