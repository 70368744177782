import * as React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import WidgetsList from "./WidgetList";
import agent from "../../../../../agent";
import { IWebsite, eWidgetStatus, eWidgetSubtype, eWidgetType } from "../../../../../reducers/constants/objectTypes";
import { web } from "../../../../../helpers/urlHelper";
import { WIDGETS } from "../../../../../reducers/constants/actionTypes";
import MTableWrapper from "../../../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../../../components/Table/Toolbar/MTableToolbar";
import MTablePagination from "../../../../../components/Table/Pagination/MTablePagination";
import MTableToolbarBaseActionButton from "../../../../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import MTableToolbarBaseSelect from "../../../../../components/Table/Toolbar/MTableToolbarBaseSelect";
import WidgetPicker from "../../../../Modals/WidgetPicker/WidgetPicker";
import TimePeriodFilter, {
  options as timePeriodOptions,
  sixMonthOptions,
} from "../../../../Analytics/Reports/components/filters/TimePeriodFilter";
import moment from "moment/moment";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  searchText: state.widgets.searchText,
  page: state.widgets.page,
  pageSize: state.widgets.pageSize,
  totalPages: state.widgets.totalPages,
  sortField: state.widgets.sortField,
  types: state.widgets.types,
  timePeriod: state.widgets.timePeriod,
  subtypes: state.widgets.subtypes,
  statuses: state.widgets.statuses,
});

const mapDispatchToProps = (dispatch) => ({
  goToWidget: (id) => dispatch(push(web.widgetsEditor(id))),
  handleWidgetsLoaded: (payload) => {
    dispatch({ type: WIDGETS.WIDGETS_LOADED, payload });
  },
  handleValueChanged: (key, value) => {
    dispatch({ type: WIDGETS.ON_VALUE_CHANGED, key, value });
  },
});

type PropTypes = {
  selectedWebsite: IWebsite;
  page: number;
  pageSize: number;
  searchText: string;
  totalPages: number;
  types: string[];
  subtypes: string[];
  statuses: eWidgetStatus[];
  timePeriod: any;
  sortField: { direction: "desc" | "asc" | undefined; field: string };

  handleValueChanged: (key: string, value: any) => void;
  handleWidgetsLoaded: (payload) => void;
  goToWidget?: (id: string) => void;
};

const Widgets = (props: PropTypes) => {
  const {
    selectedWebsite,
    page,
    pageSize,
    searchText,
    types,
    subtypes,
    totalPages,
    statuses,
    sortField,
    timePeriod,
    handleWidgetsLoaded,
    handleValueChanged,
    goToWidget,
  } = props;
  const [widgetPickerOpen, setWidgetPickerOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const loadWidgets = (canceled = false) => {
    const typesFilter = types.length > 0 ? types.join(",") : null;
    const subtypesFilter = subtypes.length > 0 ? subtypes.join(",") : null;
    const statusesFilter = statuses.length > 0 ? statuses.join(",") : null;

    handleValueChanged("loading", true);

    agent.Widgets.getWidgets(
      selectedWebsite._id,
      page,
      pageSize,
      statusesFilter,
      typesFilter,
      subtypesFilter,
      searchText,
      sortField.field,
      sortField.direction
    )
      .then((res) => {
        if (canceled) return;
        handleWidgetsLoaded(res);
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  React.useEffect(() => {
    let canceled = false;

    loadWidgets(canceled);

    return () => {
      canceled = true;
    };
  }, [page, pageSize, searchText, types, statuses, sortField, timePeriod]);

  const typeAndSubtypeOptions = [
    { value: eWidgetType.FORM, name: "Form", field: "type" },
    { value: eWidgetType.DIALOG, name: "Popup", field: "type" },
    { value: eWidgetSubtype.PRODUCT_REVIEW, name: "Product Review", field: "subtype" },
    { value: eWidgetSubtype.PRODUCT_CTA_BANNER, name: "CTA Banner", field: "subtype" },
    { value: eWidgetSubtype.PRODUCT_CTA, name: "Product CTA", field: "subtype" },
    { value: eWidgetSubtype.CHARTICLE_TOP_PRODUCTS, name: "Top Products", field: "subtype" },
    { value: eWidgetSubtype.PRODUCT_RATING, name: "Product Rating", field: "subtype" },
    { value: eWidgetSubtype.PRODUCTS_CAROUSEL, name: "Products Carousel", field: "subtype" },
    { value: eWidgetSubtype.RELATED_CONTENT, name: "Related content", field: "subtype" },
    { value: eWidgetSubtype.TEXT_CTA, name: "Text CTA", field: "subtype" },
  ];

  const statusOptions = [
    { value: eWidgetStatus.Draft, name: eWidgetStatus.Draft },
    { value: eWidgetStatus.Active, name: eWidgetStatus.Active },
    { value: eWidgetStatus.Deleted, name: eWidgetStatus.Deleted },
  ];

  return (
    <MTableWrapper>
      <MTableToolbar
        searchText={searchText}
        searchPlaceHolder={I18n.t("widgets_main.header.search_widgets")}
        handleSearchTextChanged={(value) => handleValueChanged("searchText", value)}
        actions={[
          <TimePeriodFilter
            value={timePeriod}
            selectedOptions={sixMonthOptions}
            handleOnChange={(tp) => handleValueChanged("timePeriod", tp)}
          />,
          <MTableToolbarBaseActionButton
            handleClick={() => {
              setWidgetPickerOpen(true);
            }}
            text={I18n.t("widgets_main.header.create_widget")}
          />,
        ]}
      >
        <MTableToolbarBaseSelect
          optionLabel="name"
          optionValue="value"
          options={typeAndSubtypeOptions}
          selectedOptions={typeAndSubtypeOptions.filter(
            (type) => types.includes(type.value) || subtypes.includes(type.value)
          )}
          multipleOptions
          title={I18n.t("workflow.writer.types")}
          placeholder={I18n.t("widgets_main.header.select_types")}
          handleChange={(values) => {
            handleValueChanged(
              "types",
              (values || []).filter((v) => v.field === "type").map((value) => value.value) || []
            );
            handleValueChanged(
              "subtypes",
              (values || []).filter((v) => v.field === "subtype").map((value) => value.value) || []
            );
          }}
        />
        <MTableToolbarBaseSelect
          optionLabel="name"
          optionValue="value"
          multipleOptions
          options={statusOptions}
          selectedOptions={statusOptions.filter((status) => statuses.includes(status.value))}
          placeholder={I18n.t("widgets_main.header.select_status")}
          title={I18n.t("workflow.writer.status")}
          handleChange={(values) => handleValueChanged("statuses", values?.map((value) => value.value) || [])}
        />
      </MTableToolbar>
      <WidgetsList widgetsChanged={loadWidgets} />
      {totalPages > 0 && (
        <MTablePagination
          paginationLimit={pageSize}
          page={page}
          paginationPagesCount={totalPages}
          setPaginationLimit={(value) => handleValueChanged("pageSize", value)}
          setPage={(value) => handleValueChanged("page", value)}
        />
      )}

      {widgetPickerOpen && (
        <WidgetPicker
          addWidgetMode
          includeDialogs
          handleClose={() => {
            setWidgetPickerOpen(false);
          }}
          handleWidgetCreate={() => loadWidgets()}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Widgets);
