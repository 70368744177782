import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { AbilityContext } from "../../../casl/can";
import CfButton from "../../../components/CfButton";
import CreateNewArticleDialog from "./CreateNewArticleDialog";
import { HOME } from "../../../reducers/constants/actionTypes";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import NewWebsiteDialog from "../../WebsiteConfigurations/components/NewWebsiteDialog";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  button: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: 5,
    marginLeft: "auto",
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightBold as any,
    borderRadius: 20,
    height: 36,
    minHeight: 36,
  },
}));

type PropTypes = {
  handleWebsiteCreated: (website: IWebsite) => void;
};

const mapDispatchToProps = (dispatch) => ({
  handleWebsiteCreated: (website) => {
    dispatch({ type: HOME.WEBSITE_CREATED, website });
  },
});

const CreateNewArticle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { handleWebsiteCreated } = props;
  const ability = React.useContext<any>(AbilityContext);
  const [openNewArticleDialog, setOpenNewArticleDialog] = React.useState(false);
  const [openNewWebsiteDialog, setOpenNewWebsiteDialog] = React.useState(false);

  if (!ability.can("editor", "role") && !ability.can("customer", "role") && !ability.can("hr", "role")) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <CfButton
        customClass={classes.button}
        text={I18n.t("main.new_post.create_post")}
        onClick={() => setOpenNewArticleDialog(true)}
      />
      {openNewArticleDialog && (
        <CreateNewArticleDialog
          open
          handleNewWebsiteClick={() => setOpenNewWebsiteDialog(true)}
          handleCloseClick={() => {
            setOpenNewArticleDialog(false);
          }}
        />
      )}
      {openNewWebsiteDialog && (
        <NewWebsiteDialog
          open
          handleCloseClick={() => setOpenNewWebsiteDialog(false)}
          handleWebsiteCreated={(_website) => {
            handleWebsiteCreated(_website);
          }}
        />
      )}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(CreateNewArticle);
