import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import { eTOCType, IWebsite, IWebsiteCommonComponents } from "../../../../../reducers/constants/objectTypes";
import MRouterPrompt from "../../../components/MRouterPrompt";
import SelectRow from "../../../components/SelectRow";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  columnWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 13,
    width: "100%",
  },
  switchWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));
const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  page: state.websitePages.commonComponents,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (commonComponents) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "commonComponents",
      value: commonComponents,
    }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  page: IWebsiteCommonComponents;

  onPropertyChanged: (commonComponents) => void;
};

const TableOfContentsSettings = (props: PropTypes) => {
  const { page, onPropertyChanged } = props;
  const classes = useStyles(props);

  return (
    <PageSection sectionTitle={I18n.t("website.common_components.toc.name")}>
      <div className={classes.inputRowWrapper}>
        <TextFieldMaxLength
          titleAndLengthLocation={"top"}
          value={page.addons.toc.name}
          placeholder={I18n.t("website.name_ph")}
          title={I18n.t("website.name")}
          height={37}
          borderRadius={4}
          marginBottom={30}
          handleValueChange={(value) =>
            onPropertyChanged({
              ...page,
              addons: {
                ...page.addons,
                toc: {
                  ...page.addons.toc,
                  name: value,
                },
              },
            })
          }
        />
        <SelectRow
          bold
          flexDirection={"column"}
          rowTitle={I18n.t("website.common_components.toc.show_h3s")}
          value={page.addons?.toc?.showH3s ? "Yes" : "No"}
          selectOptions={["Yes", "No"]}
          handleChange={(value) => {
            onPropertyChanged({
              ...page,
              addons: {
                ...page.addons,
                toc: {
                  ...page.addons.toc,
                  showH3s: value === "Yes",
                },
              },
            });
          }}
        />
      </div>
      <div className={classes.inputRowWrapper}>
        <div className={classes.columnWrapper}>
          <Typography className={classes.title} variant={"body2"} color={"textPrimary"}>
            {I18n.t("website.common_components.toc.default_state")}
          </Typography>
          <div className={classes.switchWrapper}>
            <Typography variant={"body2"} color={"textPrimary"}>
              {I18n.t(`website.common_components.toc.states.${page.addons.toc.defaultState ? "on" : "off"}`)}
            </Typography>
            <Switch
              checked={page.addons.toc.defaultState}
              onChange={(e, checked) =>
                onPropertyChanged({
                  ...page,
                  addons: {
                    ...page.addons,
                    toc: {
                      ...page.addons.toc,
                      defaultState: checked,
                    },
                  },
                })
              }
              color="primary"
            />
          </div>
        </div>
        <SelectRow
          bold
          flexDirection={"column"}
          menuTextTransform={"none"}
          rowTitle={I18n.t("website.common_components.toc.position")}
          value={page.addons.toc.type}
          getLabel={(option) => I18n.t(`website.common_components.toc.types.${option}`)}
          selectOptions={[eTOCType.default, eTOCType.sideTOC]}
          handleChange={(value) => {
            onPropertyChanged({
              ...page,
              addons: {
                ...page.addons,
                toc: {
                  ...page.addons.toc,
                  type: value,
                },
              },
            });
          }}
        />
      </div>
      <MRouterPrompt />
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TableOfContentsSettings);
