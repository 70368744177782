export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";

export const MODALS = {
  OPEN: "MODALS/OPEN",
  CLOSE: "MODALS/CLOSE",
  PROPS_CHANGED: "MODALS/PROPS_CHANGED",
  CLEAR_PROPS: "MODALS/CLEAR_PROPS",
  ON_ERROR: "MODALS/ON_ERROR",
  CLOSE_ERROR: "MODALS/CLOSE_ERROR",
};

export const FUNNELS = {
  FUNNEL_COLUMNS_LOADED: "FUNNELS/FUNNEL_COLUMNS_LOADED",
  FUNNEL_CONFIG_LOADED: "FUNNELS/FUNNEL_CONFIG_LOADED",
  FUNNEL_CONFIG_UPDATED: "FUNNELS/FUNNEL_CONFIG_UPDATED",
  FUNNEL_CVR_UPDATED: "FUNNELS/FUNNEL_CVR_UPDATED",
  FUNNEL_PRIORITY_UPDATED: "FUNNELS/FUNNEL_PRIORITY_UPDATED",
  FUNNEL_FUNNEL_NAME_CHANGED: "FUNNELS/FUNNEL_FUNNEL_NAME_CHANGED",
  FUNNEL_TOGGLE_FIELD: "FUNNELS/FUNNEL_FUNNEL_TOGGLE_FIELD",
  FUNNEL_EVENT_NAMES_LOADED: "FUNNELS/FUNNEL_EVENT_NAMES_LOADED",
  FUNNEL_DETAILS_LOADED: "FUNNELS/FUNNEL_DETAILS_LOADED",
  FUNNELS_LOADED: "FUNNELS/FUNNELS_LOADED",
  FUNNELS_DYNAMIC_COLUMNS_LOADED: "FUNNELS/FUNNELS_DYNAMIC_COLUMNS_LOADED",
  FUNNELS_DYNAMIC_COLUMNS_CLEANUP: "FUNNELS/FUNNELS_DYNAMIC_COLUMNS_CLEANUP",
  FUNNELS_CLEAR: "FUNNELS/FUNNELS_CLEAR",
};

export const EVENTS = {
  EVENTS_LOADED: "EVENTS/EVENTS_LOADED",
  EVENTS_FILTER_CHANGED: "EVENTS/EVENTS_FILTER_CHANGED",
  EVENTS_LOADED_FAILED: "EVENTS/EVENTS_LOADED_FAILED",
  EVENTS_NAMES_LOADED: "EVENTS/EVENTS_NAMES_LOADED",
  EVENTS_LOAD_DYNAMIC_COLUMNS: "EVENTS/EVENTS_LOAD_DYNAMIC_COLUMNS",
};
export const FORUM = {
  NEW_QUESTION_CREATED: "FORUM/NEW_QUESTION_CREATED",

  CHANGE_QUESTIONS_FILTER: "FORUM/CHANGE_QUESTIONS_FILTER",
  QUESTIONS_LOADED: "FORUM/QUESTIONS_LOADED",
  QUESTION_SELECTED: "FORUM/QUESTION_SELECTED",
  QUESTION_CHANGED: "FORUM/QUESTION_CHANGED",
  QUESTION_POSTED_TIME_CHANGED: "FORUM/QUESTION_POSTED_TIME_CHANGED",
  QUESTION_LOADED: "FORUM/QUESTION_LOADED",
  QUESTION_SAVED: "FORUM/QUESTION_SAVED",

  WRITERS_LOADED: "FORUM/WRITERS_LOADED",

  ADD_NEW_ANSWER: "FORUM/ADD_NEW_ANSWER",
  ANSWER_CHANGED: "FORUM/ANSWER_CHANGED",
  ANSWER_POSTED_TIME_CHANGED: "FORUM/ANSWER_POSTED_TIME_CHANGED",
  ANSWER_DELETED: "FORUM/ANSWER_DELETED",

  ON_TAGS_LOADED: "FORUM/ON_TAGS_LOADED",
  ON_TAGS_SEARCH_FIELD_CHANGED: "FORUM/ON_TAGS_SEARCH_FIELD_CHANGED",
  ON_TAG_SELECTED: "FORUM/ON_TAG_SELECTED",
  ON_TAG_REMOVED: "FORUM/ON_TAG_REMOVED",
  ON_NEW_TAG_CREATED: "FORUM/ON_NEW_TAG_CREATED",

  ON_TOPICS_LOADED: "FORUM/ON_TOPICS_LOADED",
  ON_TOPIC_SELECTED: "FORUM/ON_TOPIC_SELECTED",
  ON_TOPICS_SEARCH_FIELD_CHANGED: "FORUM/ON_TOPICS_SEARCH_FIELD_CHANGED",
  ON_NEW_TOPIC_CREATED: "FORUM/ON_NEW_TOPIC_CREATED",

  ON_CATEGORIES_LOADED: "FORUM/ON_CATEGORIES_LOADED",
  ON_CATEGORY_SELECTED: "FORUM/ON_CATEGORY_SELECTED",

  OPEN_RICH_TEXT_EDITOR_DIALOG: "FORUM/OPEN_RICH_TEXT_EDITOR_DIALOG",
  CLOSE_RICH_TEXT_EDITOR_DIALOG: "FORUM/CLOSE_RICH_TEXT_EDITOR_DIALOG",

  OPEN_DIFF_VIEW_DIALOG: "FORUM/OPEN_DIFF_VIEW_DIALOG",
  CLOSE_DIFF_VIEW_DIALOG: "FORUM/CLOSE_DIFF_VIEW_DIALOG",

  ON_SOURCE_URL_ADDED: "FORUM/ON_SOURCE_URL_ADDED",
  ON_SOURCE_URL_REMOVED: "FORUM/ON_SOURCE_URL_REMOVED",
};

export const BLOG = {
  NEW_POST_CREATED: "BLOG/NEW_POST_CREATED",
  POST_SAVED: "BLOG/POST_SAVED",
  TOGGLE_AUTO_SAVE: "BLOG/TOGGLE_AUTO_SAVE",
  POST_AUTO_SAVED: "BLOG/POST_AUTO_SAVED",
  POSTS_LOADED: "BLOG/POSTS_LOADED",
  BLOG_POST_CHANGED: "BLOG/BLOG_POST_CHANGED",
  POST_SELECTED: "BLOG/POST_SELECTED",
  ON_TOPICS_LOADED: "BLOG/ON_TOPICS_LOADED",
  ON_CATEGORY_SELECTED: "BLOG/ON_CATEGORY_SELECTED",
  ON_TOPICS_SEARCH_FIELD_CHANGED: "BLOG/ON_TOPICS_SEARCH_FIELD_CHANGED",
  ON_NEW_TOPIC_CREATED: "BLOG/ON_NEW_TOPIC_CREATED",
  ON_TAGS_LOADED: "BLOG/ON_TAGS_LOADED",
  ON_TAGS_SEARCH_FIELD_CHANGED: "BLOG/ON_TAGS_SEARCH_FIELD_CHANGED",
  ON_TAG_SELECTED: "BLOG/ON_TAG_SELECTED",
  ON_TAG_REMOVED: "BLOG/ON_TAG_REMOVED",
  ON_NEW_TAG_CREATED: "BLOG/ON_NEW_TAG_CREATED",
  AUTHORS_LOADED: "BLOG/AUTHORS_LOADED",
  AUTHOR_CHANGED: "BLOG/AUTHOR_CHANGED",
  PREVIEW_IMAGE_SELECTED: "BLOG/PREVIEW_IMAGE_SELECTED",
  ON_NEW_AUTHOR_CREATED: "BLOG/ON_NEW_AUTHOR_CREATED",
  ON_SOURCE_URL_ADDED: "BLOG/ON_SOURCE_URL_ADDED",
  ON_SOURCE_URL_REMOVED: "BLOG/ON_SOURCE_URL_REMOVED",
};

export const INSIGHTS = {
  ON_REPORT_LOADED: "INSIGHTS/ON_REPORT_LOADED",
  ON_VALUE_CHANGED: "INSIGHTS/ON_VALUE_CHANGED",
  ON_URLS_LOADED: "INSIGHTS/ON_URLS_LOADED",
  ON_EVENT_NAMES_LOADED: "INSIGHTS/ON_EVENT_NAMES_LOADED",
  IS_LOADING: "INSIGHTS/IS_LOADING",
};

export const MANAGE_WEBSITES = {
  ON_CATEGORY_SELECTED: "MANAGE_WEBSITES/CATEGORIES/ON_CATEGORY_SELECTED",
  ON_CATEGORY_CHANGED: "MANAGE_WEBSITES/CATEGORIES/ON_CATEGORY_CHANGED",
  ON_NEW_CATEGORY_CREATED: "MANAGE_WEBSITES/CATEGORIES/ON_NEW_CATEGORY_CREATED",
  ON_CATEGORY_DELETED: "MANAGE_WEBSITES/CATEGORIES/ON_CATEGORY_DELETED",
  ON_CATEGORY_UPDATED: "MANAGE_WEBSITES/CATEGORIES/ON_CATEGORY_UPDATED",
  ON_CATEGORIES_LOCALE_CHANGED: "MANAGE_WEBSITES/CATEGORIES/ON_CATEGORIES_LOCALE_CHANGED",

  ON_TOPIC_LOADED: "MANAGE_WEBSITES/TOPICS/ON_TOPIC_LOADED",
  ON_TOPIC_SELECTED: "MANAGE_WEBSITES/TOPICS/ON_TOPIC_SELECTED",
  ON_TOPIC_CHANGED: "MANAGE_WEBSITES/TOPICS/ON_TOPIC_CHANGED",
  ON_NEW_TOPIC_CREATED: "MANAGE_WEBSITES/TOPICS/ON_NEW_TOPIC_CREATED",
  ON_TOPIC_DELETED: "MANAGE_WEBSITES/TOPICS/ON_TOPIC_DELETED",
  ON_TOPIC_UPDATED: "MANAGE_WEBSITES/TOPICS/ON_TOPIC_UPDATED",
  FILTER_TOPIC_LIST_CHANGED: "MANAGE_WEBSITES/TOPICS/FILTER_TOPIC_LIST_CHANGED",

  ON_TAGS_LOADED: "MANAGE_WEBSITES/TAGS/ON_TAGS_LOADED",
  ON_TAG_SELECTED: "MANAGE_WEBSITES/TAGS/ON_TAG_SELECTED",
  ON_TAG_UPDATED: "MANAGE_WEBSITES/TAGS/ON_TAG_UPDATED",
};

export const PAGES = {
  PAGES_LOADED: "PAGES/PAGES_LOADED",
  PAGE_SELECTED: "PAGES/PAGE_SELECTED",
  NEW_PAGE_CREATED: "PAGES/NEW_PAGE_CREATED",
  PAGE_CHANGED: "PAGES/PAGE_CHANGED",
  PAGE_UPDATED: "PAGES/PAGE_UPDATED",
  PAGE_SAVED: "PAGES/PAGE_SAVED",
  TEMPLATES_LOADED: "PAGES/TEMPLATES_LOADED",
};

export const MANAGE_CONTENT = {
  ON_TAGS_LOADED: "MANAGE_CONTENT/TAGS/ON_TAGS_LOADED",
  ON_TAG_SELECTED: "MANAGE_CONTENT/TAGS/ON_TAG_SELECTED",
  ON_TAG_UPDATED: "MANAGE_CONTENT/TAGS/ON_TAG_UPDATED",
  ON_TAG_DELETED: "MANAGE_CONTENT/TAGS/ON_TAG_DELETED",
  ON_TAG_CHANGED: "MANAGE_CONTENT/TAGS/ON_TAG_CHANGED",
  ON_TAG_CREATED: "MANAGE_CONTENT/TAGS/ON_TAG_CREATED",
  ON_TAGS_LOCALE_CHANGED: "MANAGE_CONTENT/TAGS/ON_TAGS_LOCALE_CHANGED",

  ON_PRODUCTS_LOADED: "MANAGE_CONTENT/PRODUCTS/ON_PRODUCTS_LOADED",
  ON_PRODUCT_CREATED: "MANAGE_CONTENT/PRODUCTS/ON_PRODUCT_CREATED",
  ON_PRODUCT_SELECTED: "MANAGE_CONTENT/PRODUCTS/ON_PRODUCT_SELECTED",
  ON_PRODUCT_CHANGED: "MANAGE_CONTENT/PRODUCTS/ON_PRODUCT_CHANGED",
  ON_PRODUCT_UPDATED: "MANAGE_CONTENT/PRODUCTS/ON_PRODUCT_UPDATED",
  ON_PRODUCT_DELETED: "MANAGE_CONTENT/PRODUCTS/ON_PRODUCT_DELETED",
  ON_PRODUCT_CLEANUP: "MANAGE_CONTENT/PRODUCTS/ON_PRODUCT_CLEANUP",
};

export const CATEGORIZE = {
  FILTER_CHANGED: "CATEGORIZE/FILTER_CHANGED",
  RESET_PAGE: "CATEGORIZE/RESET_PAGE",
  NEW_POST_CREATED: "CATEGORIZE/NEW_POST_CREATED",
  // SET_PERMALINK: 'CATEGORIZE/SET_PERMALINK',
  LOAD_NEW_POST: "CATEGORIZE/LOAD_NEW_POST",
  FB_GROUPS_LOADED: "CATEGORIZE/FB_GROUPS_LOADED",
  FB_GROUP_SELECTED: "CATEGORIZE/FB_GROUP_SELECTED",
  FB_GROUP_POSTS_LOADED: "CATEGORIZE/FB_GROUP_POSTS_LOADED",
  FB_POST_SELECTED: "CATEGORIZE/FB_POST_SELECTED",
  FB_POST_LOADED: "CATEGORIZE/FB_POST_LOADED",
  RESET_FB_POST: "CATEGORIZE/RESET_FB_POST",
  SKIP_POST: "CATEGORIZE/SKIP_POST",
  KEYWORDS_LOADED: "CATEGORIZE/KEYWORDS_LOADED",
  KEYWORD_SELECTED: "CATEGORIZE/KEYWORD_SELECTED",
  KEYWORD_POSTS_LOADED: "CATEGORIZE/KEYWORD_POSTS_LOADED",
  CONVERT_ANSWER_TO_QUESTION: "CATEGORIZE/CONVERT_ANSWER_TO_QUESTION",
  DELETE_POST: "CATEGORIZE/DELETE_POST",
  NEW_QUESTION: "CATEGORIZE/NEW_QUESTION",
  ON_POST_CHANGED: "CATEGORIZE/ON_POST_CHANGED",
  ON_ANSWER_VALUE_CHANGED: "CATEGORIZE/ON_ANSWER_VALUE_CHANGED",
  ON_RICH_TEXT_CHANGED: "CATEGORIZE/ON_RICH_TEXT_CHANGED",
  ON_QUESTION_CHANGED: "CATEGORIZE/ON_QUESTION_CHANGED",
  ON_QUESTION_POSTED_TIME_CHANGED: "CATEGORIZE/ON_QUESTION_POSTED_TIME_CHANGED",
  // SET_QUESTION_SUBJECT: 'CATEGORIZE/SET_QUESTION_SUBJECT',
  // SET_QUESTION: 'CATEGORIZE/SET_QUESTION',
  // SET_QUESTION_ORIGINAL_TEXT: 'CATEGORIZE/SET_QUESTION_ORIGINAL_TEXT',
  // ON_USERS_LOADED: 'CATEGORIZE/ON_USERS_LOADED',
  // ON_USER_SELECTED: 'CATEGORIZE/ON_USER_SELECTED',
  // ON_POSTED_TIME_CHANGED: 'CATEGORIZE/ON_POSTED_TIME_CHANGED',
  ADD_NEW_ANSWER: "CATEGORIZE/ADD_NEW_ANSWER",
  REMOVE_NEW_ANSWER: "CATEGORIZE/REMOVE_NEW_ANSWER",
  ON_REPLY_CHANGED: "CATEGORIZE/ON_REPLY_CHANGED",
  ON_COMMENT_CHANGED: "CATEGORIZE/ON_COMMENT_CHANGED",
  ON_TOPICS_LOADED: "CATEGORIZE/ON_TOPICS_LOADED",
  ON_TOPIC_SELECTED: "CATEGORIZE/ON_TOPIC_SELECTED",
  ON_TOPICS_SEARCH_FIELD_CHANGED: "CATEGORIZE/ON_TOPICS_SEARCH_FIELD_CHANGED",

  ON_CATEGORIES_LOADED: "CATEGORIZE/ON_CATEGORIES_LOADED",
  ON_CATEGORY_SELECTED: "CATEGORIZE/ON_CATEGORY_SELECTED",

  ON_NEW_TOPIC_CREATED: "CATEGORIZE/ON_NEW_TOPIC_CREATED",
  ON_TAGS_LOADED: "CATEGORIZE/ON_TAGS_LOADED",
  ON_TAGS_SEARCH_FIELD_CHANGED: "CATEGORIZE/ON_TAGS_SEARCH_FIELD_CHANGED",
  ON_TAG_SELECTED: "CATEGORIZE/ON_TAG_SELECTED",
  ON_TAG_REMOVED: "CATEGORIZE/ON_TAG_REMOVED",
  ON_NEW_TAG_CREATED: "CATEGORIZE/ON_NEW_TAG_CREATED",
  ON_SUITABLE_FOR_CHANGED: "CATEGORIZE/ON_SUITABLE_FOR_CHANGED",

  OPEN_RICH_TEXT_EDITOR_DIALOG: "CATEGORIZE/OPEN_RICH_TEXT_EDITOR_DIALOG",
  CLOSE_RICH_TEXT_EDITOR_DIALOG: "CATEGORIZE/CLOSE_RICH_TEXT_EDITOR_DIALOG",

  ON_SOURCE_URL_ADDED: "CATEGORIZE/ON_SOURCE_URL_ADDED",
  ON_SOURCE_URL_REMOVED: "CATEGORIZE/ON_SOURCE_URL_REMOVED",
};

export const FB_GROUPS = {
  FB_GROUPS_LOADED: "FB_GROUPS/FB_GROUPS_LOADED",
  FB_GROUPS_WEBSITES_UPDATED: "FB_GROUPS/FB_GROUPS_WEBSITES_UPDATED",
};

export const ANALYTICS = {
  TRACK_EVENT: "ANALYTICS/TRACK_EVENT",
  PIXEL_TRACK_EVENT: "ANALYTICS/PIXEL_TRACK_EVENT",
};

export const HOME = {
  WEBSITES_LOADED: "HOME/WEBSITES_LOADED",
  WEBSITE_CREATED: "HOME/WEBSITE_CREATED",
  PAGE_LOADED: "HOME/PAGE_LOADED",
  MIXPANEL_EVENT: "HOME/MIXPANEL_EVENT",
  ON_WEBSITE_UPDATED: "HOME/ON_WEBSITE_UPDATED",
  ON_WEBSITES_SELECTED: "HOME/ON_WEBSITES_SELECTED",
  ADMIN_USER_LOGGED_IN: "HOME/ADMIN_USER_LOGGED_IN",
  ADMIN_USER_SIGNED_UP: "HOME/ADMIN_USER_SIGNED_UP",
  ADMIN_USER_LOADED: "HOME/ADMIN_USER_LOADED",
  ADMIN_USER_LOADED_FAILED: "HOME/ADMIN_USER_LOADED_FAILED",
  ORGANIZATIONS_LOADED: "HOME/ORGANIZATIONS_LOADED",
  ON_ORGANIZATION_SELECTED: "HOME/ON_ORGANIZATION_SELECTED",
  ONBOARDING_DATA_UPDATED: "HOME/ONBOARDING_DATA_UPDATED",
  ONBOARDING_COMPLETED: "HOME/ONBOARDING_COMPLETED",
  ON_HOME_PROPERTY_CHANGED: "HOME/ON_HOME_PROPERTY_CHANGED",

  SUB_ADMIN_USER_LOADED: "HOME/SUB_ADMIN_USER_LOADED",
  SUB_ADMIN_USER_LOAD_FAILED: "HOME/SUB_ADMIN_USER_LOAD_FAILED",

  SUB_ADMIN_USER_REMOVE: "HOME/SUB_ADMIN_USER_REMOVE",

  POPULAR_TAGS_LOADED: "HOME/POPULAR_TAGS_LOADED",
  SIDE_QUESTIONS_LOADED: "HOME/SIDE_QUESTIONS_LOADED",
  CATEGORIES_LOADED: "HOME/CATEGORIES_LOADED",
};

export const MANAGE_CONTENT_UPLOAD = {
  DOMAINS_LOADED: "MANAGE_CONTENT_UPLOAD/DOMAINS_LOADED",
  DOMAINS_CHANGED: "MANAGE_CONTENT_UPLOAD/DOMAINS_CHANGED",
  SET_URL: "MANAGE_CONTENT_UPLOAD/SET_URL",
  SET_CONTENT: "MANAGE_CONTENT_UPLOAD/SET_CONTENT",
  CONTENT_SAVED: "MANAGE_CONTENT_UPLOAD/CONTENT_SAVED",
};

export const CATEGORY = {
  CATEGORIES_LOADED: "CATEGORY/CATEGORIES_LOADED",
  NEW_CATEGORY: "CATEGORY/NEW_CATEGORY",
  CATEGORY_CHANGED: "CATEGORY/CATEGORY_CHANGED",
  CATEGORY_DELETED: "CATEGORY/CATEGORY_DELETED",
};

export const CONTENT_SOURCE_PICKER = {
  OPEN_DIALOG: "CONTENT_SOURCE_PICKER/OPEN_DIALOG",
  SET_SELECTED_POST: "CONTENT_SOURCE_PICKER/SET_SELECTED_POST",
  FILTER_FACEBOOK_POSTS_CHANGED: "CONTENT_SOURCE_PICKER/FILTER_FACEBOOK_POSTS_CHANGED",
  FILTER_MANUAL_POSTS_CHANGED: "CONTENT_SOURCE_PICKER/FILTER_MANUAL_POSTS_CHANGED",
  CONTENT_LOADED: "CONTENT_SOURCE_PICKER/CONTENT_LOADED",
  TAGS_LOADED: "CONTENT_SOURCE_PICKER/TAGS_LOADED",
  TOPICS_LOADED: "CONTENT_SOURCE_PICKER/TOPICS_LOADED",
  DOMAINS_LOADED: "CONTENT_SOURCE_PICKER/DOMAINS_LOADED",
  DOMAINS_SELECTED: "CONTENT_SOURCE_PICKER/DOMAINS_SELECTED",
  FB_POST_MARK_AS_USED: "CONTENT_SOURCE_PICKER/FB_POST_MARK_AS_USED",
  MANUAL_POST_MARK_AS_USED: "CONTENT_SOURCE_PICKER/MANUAL_POST_MARK_AS_USED",
};

export const TAG = {
  TAG_LOADED: "TAG/TAG_LOADED",
  TAG_QUESTIONS_LOADED: "TAG/TAG_QUESTIONS_LOADED",
};

export const LEARN = {
  TOPICS_LOADED: "LEARN/TOPICS_LOADED",
  TOPICS_POSITION_UPDATED: "LEARN/TOPICS_POSITION_UPDATED",
  TOPIC_SELECTED: "LEARN/TOPIC_SELECTED",
  TOPIC_CREATED: "LEARN/TOPIC_CREATED",
  ON_TOPIC_KEYWORD_SELECTED: "LEARN/ON_TOPIC_KEYWORD_SELECTED",
  ON_TOPIC_KEYWORD_REMOVED: "LEARN/ON_TOPIC_KEYWORD_REMOVED",
  SUBTOPIC_SELECTED: "LEARN/SUBTOPIC_SELECTED",
  SUBTOPIC_CREATED: "LEARN/SUBTOPIC_CREATED",
  SUBTOPICS_POSITION_UPDATED: "LEARN/SUBTOPICS_POSITION_UPDATED",
  TOPIC_CHANGED: "LEARN/TOPIC_CHANGED",
  TOPIC_SAVED: "LEARN/TOPIC_SAVED",
  POSTS_LOADED: "LEARN/POSTS_LOADED",
  POST_CREATED: "LEARN/POST_CREATED",
  POST_SELECTED: "LEARN/POST_SELECTED",
  POST_CHANGED: "LEARN/POST_CHANGED",
  POST_SAVED: "LEARN/POST_SAVED",
  POSTS_POSITION_UPDATED: "LEARN/POSTS_POSITION_UPDATED",

  ON_TAGS_LOADED: "LEARN/ON_TAGS_LOADED",
  ON_TAGS_SEARCH_FIELD_CHANGED: "LEARN/ON_TAGS_SEARCH_FIELD_CHANGED",
  ON_TAG_SELECTED: "LEARN/ON_TAG_SELECTED",
  ON_TAG_REMOVED: "LEARN/ON_TAG_REMOVED",
  ON_NEW_TAG_CREATED: "LEARN/ON_NEW_TAG_CREATED",

  ON_KEYWORDS_LOADED: "LEARN/ON_KEYWORDS_LOADED",
  ON_KEYWORDS_SEARCH_FIELD_CHANGED: "LEARN/ON_KEYWORDS_SEARCH_FIELD_CHANGED",
  ON_KEYWORD_SELECTED: "LEARN/ON_KEYWORD_SELECTED",
  ON_KEYWORD_REMOVED: "LEARN/ON_KEYWORD_REMOVED",
  ON_NEW_KEYWORD_CREATED: "LEARN/ON_NEW_KEYWORD_CREATED",

  SET_CONTENT_SOURCE_PICKER_DIALOG_OPEN: "LEARN/SET_CONTENT_SOURCE_PICKER_DIALOG_OPEN",

  ON_SOURCE_URL_ADDED: "LEARN/ON_SOURCE_URL_ADDED",
  ON_SOURCE_URL_REMOVED: "LEARN/ON_SOURCE_URL_REMOVED",
};

export const INDUSTRY_EXPERTS = {
  POSTS_LOADED: "INDUSTRY_EXPERTS/POSTS_LOADED",
  SET_SELECTED_EXPERT: "INDUSTRY_EXPERTS/SET_SELECTED_EXPERT",
  EXPERTS_LOADED: "INDUSTRY_EXPERTS/EXPERTS_LOADED",
  EXPERT_CHANGED: "INDUSTRY_EXPERTS/EXPERT_CHANGED",
  EXPERT_DELETED: "INDUSTRY_EXPERTS/EXPERT_DELETED",
  EXPERT_POST_CHANGED: "INDUSTRY_EXPERTS/EXPERT_POST_CHANGED",
  EXPERT_CREATED: "INDUSTRY_EXPERTS/EXPERT_CREATED",
  SEARCH_TEXT_CHANGED: "INDUSTRY_EXPERTS/SEARCH_TEXT_CHANGED",
  FILTER_CHANGED: "INDUSTRY_EXPERTS/FILTER_CHANGED",
};

export const TARGETED_POSTS = {
  POSTS_LOADED: "TARGETED_POSTS/POSTS_LOADED",
  PENDING_NEW_POSTS_LOADED: "TARGETED_POSTS/PENDING_NEW_POSTS_LOADED",
  SET_SELECTED_POST_ID: "TARGETED_POSTS/SET_SELECTED_POST_ID",
  POST_LOADED: "TARGETED_POSTS/POST_LOADED",
  POST_CHANGED: "TARGETED_POSTS/POST_CHANGED",
  REMOVE_SELECTED_POST: "TARGETED_POSTS/REMOVE_SELECTED_POST",
  TAB_CHANGED: "TARGETED_POSTS/TAB_CHANGED",
  NEW_POST_CREATED: "TARGETED_POSTS/NEW_POST_CREATED",
  NEW_POST_SAVED: "TARGETED_POSTS/NEW_POST_SAVED",

  SEARCH_TEXT_CHANGED: "TARGETED_POSTS/SEARCH_TEXT_CHANGED",

  ON_TAGS_LOADED: "TARGETED_POSTS/ON_TAGS_LOADED",
  ON_TAGS_SEARCH_FIELD_CHANGED: "TARGETED_POSTS/ON_TAGS_SEARCH_FIELD_CHANGED",
  ON_TAG_SELECTED: "TARGETED_POSTS/ON_TAG_SELECTED",
  ON_TAG_REMOVED: "TARGETED_POSTS/ON_TAG_REMOVED",
  ON_NEW_TAG_CREATED: "TARGETED_POSTS/ON_NEW_TAG_CREATED",

  ON_KEYWORDS_LOADED: "TARGETED_POSTS/ON_KEYWORDS_LOADED",
  ON_KEYWORDS_SEARCH_FIELD_CHANGED: "TARGETED_POSTS/ON_KEYWORDS_SEARCH_FIELD_CHANGED",
  ON_KEYWORD_SELECTED: "TARGETED_POSTS/ON_KEYWORD_SELECTED",
  ON_KEYWORD_REMOVED: "TARGETED_POSTS/ON_KEYWORD_REMOVED",
  ON_NEW_KEYWORD_CREATED: "TARGETED_POSTS/ON_NEW_KEYWORD_CREATED",

  ON_TOPICS_LOADED: "TARGETED_POSTS/ON_TOPICS_LOADED",
  ON_CATEGORY_SELECTED: "TARGETED_POSTS/ON_CATEGORY_SELECTED",
  ON_TOPIC_SELECTED: "TARGETED_POSTS/ON_TOPIC_SELECTED",
  ON_TOPICS_SEARCH_FIELD_CHANGED: "TARGETED_POSTS/ON_TOPICS_SEARCH_FIELD_CHANGED",
  ON_NEW_TOPIC_CREATED: "TARGETED_POSTS/ON_NEW_TOPIC_CREATED",

  ON_SOURCE_URL_ADDED: "TARGETED_POSTS/ON_SOURCE_URL_ADDED",
  ON_SOURCE_URL_REMOVED: "TARGETED_POSTS/ON_SOURCE_URL_REMOVED",
};

export const VIDEO_EDITOR = {
  PROJECT_LOADED: "VIDEO_EDITOR/PROJECT_LOADED",
  PROJECT_UNLOADED: "VIDEO_EDITOR/PROJECT_UNLOADED",
  EDIT_PROJECT: "VIDEO_EDITOR/EDIT_PROJECT",
  SET_PENDING_UPLOADS: "VIDEO_EDITOR/SET_PENDING_UPLOADS",
  PROPERTY_CHANGE: "VIDEO_EDITOR/PROPERTY_CHANGE",
  ROW_ACTION_SELECTED: "VIDEO_EDITOR/ROW_ACTION_SELECTED",
  ROWS_CHANGED: "VIDEO_EDITOR/ROWS_CHANGED",
  TIMELINE_STATE_PROPERTY_CHANGED: "VIDEO_EDITOR/TIMELINE_STATE_PROPERTY_CHANGED",
  SET_SELECTED_ACTION_ID: "VIDEO_EDITOR/SET_SELECTED_ACTION_ID",
  SET_SELECTED_TIMELINE: "VIDEO_EDITOR/SET_SELECTED_TIMELINE",
};
export const ANSWERS = {
  POSTS_LOADED: "ANSWERS/POSTS_LOADED",
  REFRESH_POST_LIST: "ANSWERS/REFRESH_POST_LIST",
};

export const WRITE_ANSWER = {
  ANSWER_LOADED: "WRITE_ANSWER/ANSWER_LOADED",
  ANSWER_CHANGED: "WRITE_ANSWER/ANSWER_CHANGED",
  TAB_CHANGED: "WRITE_ANSWER/TAB_CHANGED",

  TOPICS_LOADED: "WRITE_ANSWER/TOPICS_LOADED",
  ON_TOPIC_SELECTED: "WRITE_ANSWER/ON_TOPIC_SELECTED",
  ON_NEW_TOPIC_CREATED: "WRITE_ANSWER/ON_NEW_TOPIC_CREATED",
  ON_TOPICS_SEARCH_FIELD_CHANGED: "WRITE_ANSWER/ON_TOPICS_SEARCH_FIELD_CHANGED",

  TAGS_LOADED: "WRITE_ANSWER/TAGS_LOADED",
  ON_TAG_SELECTED: "WRITE_ANSWER/ON_TAG_SELECTED",
  ON_TAG_REMOVED: "WRITE_ANSWER/ON_TAG_REMOVED",
  ON_TAGS_SEARCH_FIELD_CHANGED: "WRITE_ANSWER/ON_TAGS_SEARCH_FIELD_CHANGED",
  ON_NEW_TAG_CREATED: "WRITE_ANSWER/ON_NEW_TAG_CREATED",

  ON_SOURCE_URL_ADDED: "WRITE_ANSWER/ON_SOURCE_URL_ADDED",
  ON_SOURCE_URL_REMOVED: "WRITE_ANSWER/ON_SOURCE_URL_REMOVED",

  ON_KEYWORDS_LOADED: "WRITE_ANSWER/ON_KEYWORDS_LOADED",
  ON_KEYWORDS_SEARCH_FIELD_CHANGED: "WRITE_ANSWER/ON_KEYWORDS_SEARCH_FIELD_CHANGED",
  ON_KEYWORD_SELECTED: "WRITE_ANSWER/ON_KEYWORD_SELECTED",
  ON_KEYWORD_REMOVED: "WRITE_ANSWER/ON_KEYWORD_REMOVED",
  ON_NEW_KEYWORD_CREATED: "WRITE_ANSWER/ON_NEW_KEYWORD_CREATED",
};

export const REVIEW = {
  POSTS_LOADED: "REVIEW/POSTS_LOADED",
  REFRESH_POST_LIST: "REVIEW/REFRESH_POST_LIST",
};

export const SCRAPING = {
  WORKERS_LOADED: "SCRAPING/WORKERS_LOADED",
  WORKERS_FILTER_CHANGED: "SCRAPING/WORKERS_FILTER_CHANGED",
  WORKERS_SEARCH_TEXT_CHANGED: "SCRAPING/WORKERS_SEARCH_TEXT_CHANGED",
  WORKERS_WORKER_SELECTED: "SCRAPING/WORKERS_WORKER_SELECTED",
  WORKERS_WORKER_UPDATED: "SCRAPING/WORKERS_WORKER_UPDATED",

  KEYWORDS_LOADED: "SCRAPING/KEYWORDS_LOADED",
  KEYWORDS_CREATED: "SCRAPING/KEYWORDS_CREATED",
  KEYWORDS_KEYWORD_DELETED: "SCRAPING/KEYWORD_DELETED",
  KEYWORDS_KEYWORD_UPDATED: "SCRAPING/KEYWORDS_KEYWORD_UPDATED",
  KEYWORDS_SEARCH_TEXT_CHANGED: "SCRAPING/KEYWORDS_SEARCH_TEXT_CHANGED",
};

export const REVIEW_ANSWER = {
  ANSWER_LOADED: "REVIEW_ANSWER/ANSWER_LOADED",
  ANSWER_CHANGED: "REVIEW_ANSWER/ANSWER_CHANGED",
  TAB_CHANGED: "REVIEW_ANSWER/TAB_CHANGED",

  TOPICS_LOADED: "REVIEW_ANSWER/TOPICS_LOADED",
  ON_TOPIC_SELECTED: "REVIEW_ANSWER/ON_TOPIC_SELECTED",
  ON_NEW_TOPIC_CREATED: "REVIEW_ANSWER/ON_NEW_TOPIC_CREATED",
  ON_TOPICS_SEARCH_FIELD_CHANGED: "REVIEW_ANSWER/ON_TOPICS_SEARCH_FIELD_CHANGED",

  TAGS_LOADED: "REVIEW_ANSWER/TAGS_LOADED",
  ON_TAG_SELECTED: "REVIEW_ANSWER/ON_TAG_SELECTED",
  ON_TAG_REMOVED: "REVIEW_ANSWER/ON_TAG_REMOVED",
  ON_TAGS_SEARCH_FIELD_CHANGED: "REVIEW_ANSWER/ON_TAGS_SEARCH_FIELD_CHANGED",
  ON_NEW_TAG_CREATED: "REVIEW_ANSWER/ON_NEW_TAG_CREATED",

  ON_SOURCE_URL_ADDED: "REVIEW_ANSWER/ON_SOURCE_URL_ADDED",
  ON_SOURCE_URL_REMOVED: "REVIEW_ANSWER/ON_SOURCE_URL_REMOVED",

  ON_KEYWORDS_LOADED: "REVIEW_ANSWER/ON_KEYWORDS_LOADED",
  ON_KEYWORDS_SEARCH_FIELD_CHANGED: "REVIEW_ANSWER/ON_KEYWORDS_SEARCH_FIELD_CHANGED",
  ON_KEYWORD_SELECTED: "REVIEW_ANSWER/ON_KEYWORD_SELECTED",
  ON_KEYWORD_REMOVED: "REVIEW_ANSWER/ON_KEYWORD_REMOVED",
  ON_NEW_KEYWORD_CREATED: "REVIEW_ANSWER/ON_NEW_KEYWORD_CREATED",
};

export const LEARNING_CENTER_CONTENT = {
  DOWNLOAD_TOPICS: "LEARNING_CENTER_CONTENT/DOWNLOAD_TOPICS",
  TOPICS_LOADED: "LEARNING_CENTER_CONTENT/TOPICS_LOADED",
  TOPICS_POSITION_UPDATED: "LEARNING_CENTER_CONTENT/TOPICS_POSITION_UPDATED",
  SUBTOPICS_POSITION_UPDATED: "LEARNING_CENTER_CONTENT/SUBTOPICS_POSITION_UPDATED",
  COLLAPSE_ALL: "LEARNING_CENTER_CONTENT/COLLAPSE_ALL",
  EXPAND_ALL: "LEARNING_CENTER_CONTENT/EXPAND_ALL",
  SUBTOPIC_POSTS_LOADED: "LEARNING_CENTER_CONTENT/SUBTOPIC_POSTS_LOADED",
  EXPAND_TOPIC: "LEARNING_CENTER_CONTENT/EXPAND_TOPIC",
  COLLAPSE_TOPIC: "LEARNING_CENTER_CONTENT/COLLAPSE_TOPIC",
};

export const EDIT_BLOG_POST = {
  CLEANUP: "EDIT_BLOG_POST/CLEANUP",
  POST_LOADED: "EDIT_BLOG_POST/POST_LOADED",
  POST_TRANSLATION_LOADED: "EDIT_BLOG_POST/POST_TRANSLATION_LOADED",
  POST_CHANGED: "EDIT_BLOG_POST/POST_CHANGED",
  TRANSLATION_CHANGED: "EDIT_BLOG_POST/TRANSLATION_CHANGED",
  TAB_CHANGED: "EDIT_BLOG_POST/TAB_CHANGED",
  TEXT_CHANGED: "EDIT_BLOG_POST/TEXT_CHANGED",
  TRANSLATION_TEXT_CHANGED: "EDIT_BLOG_POST/TRANSLATION_TEXT_CHANGED",
  LOCALE_CHANGED: "EDIT_BLOG_POST/LOCALE_CHANGED",
};

export const EDIT_TARGETED_POST = {
  CLEANUP: "EDIT_TARGETED_POST/CLEANUP",
  POST_LOADED: "EDIT_TARGETED_POST/POST_LOADED",
  POST_TRANSLATION_LOADED: "EDIT_TARGETED_POST/POST_TRANSLATION_LOADED",
  POST_CHANGED: "EDIT_TARGETED_POST/POST_CHANGED",
  TRANSLATION_CHANGED: "EDIT_TARGETED_POST/TRANSLATION_CHANGED",
  TAB_CHANGED: "EDIT_TARGETED_POST/TAB_CHANGED",
  LOCALE_CHANGED: "EDIT_TARGETED_POST/LOCALE_CHANGED",
};

export const EDIT_CUSTOM_POST = {
  CLEANUP: "EDIT_CUSTOM_POST/CLEANUP",
  POST_LOADED: "EDIT_CUSTOM_POST/POST_LOADED",
  POST_CHANGED: "EDIT_CUSTOM_POST/POST_CHANGED",
  TAB_CHANGED: "EDIT_CUSTOM_POST/TAB_CHANGED",
};

export const EDIT_ARTICLE = {
  CLEANUP: "EDIT_ARTICLE/CLEANUP",
  ARTICLE_LOADED: "EDIT_ARTICLE/ARTICLE_LOADED",
  ARTICLE_CHANGED: "EDIT_ARTICLE/ARTICLE_CHANGED",
  ARTICLE_TEXT_CHANGED: "EDIT_ARTICLE/ARTICLE_TEXT_CHANGED",
};

export const PAGE_EDITOR = {
  CLEANUP: "PAGE_EDITOR/CLEANUP",
  PAGE_LOADED: "PAGE_EDITOR/PAGE_LOADED",
  EDITOR_CHANGED: "PAGE_EDITOR/EDITOR_CHANGED",
  PAGE_CHANGED: "PAGE_EDITOR/PAGE_CHANGED",
  PAGE_UPDATED: "PAGE_EDITOR/PAGE_UPDATED",
  CLUSTER_UPDATED: "PAGE_EDITOR/CLUSTER_UPDATED",
  PAGE_TEXT_CHANGED: "PAGE_EDITOR/PAGE_TEXT_CHANGED",
  SAVE_IN_PROGRESS: "PAGE_EDITOR/SAVE_IN_PROGRESS",
  AUTO_SAVE_ON: "PAGE_EDITOR/AUTO_SAVE_ON",
};
export const EDIT_TOPIC = {
  CLEANUP: "EDIT_TOPIC/CLEANUP",
  TOPIC_LOADED: "EDIT_TOPIC/TOPIC_LOADED",
  TOPIC_CHANGED: "EDIT_TOPIC/TOPIC_CHANGED",
  TAB_CHANGED: "EDIT_TOPIC/TAB_CHANGED",

  ON_KEYWORDS_LOADED: "EDIT_TOPIC/ON_KEYWORDS_LOADED",
  ON_KEYWORDS_SEARCH_FIELD_CHANGED: "EDIT_TOPIC/ON_KEYWORDS_SEARCH_FIELD_CHANGED",
  ON_KEYWORD_SELECTED: "EDIT_TOPIC/ON_KEYWORD_SELECTED",
  ON_KEYWORD_REMOVED: "EDIT_TOPIC/ON_KEYWORD_REMOVED",
  ON_NEW_KEYWORD_CREATED: "EDIT_TOPIC/ON_NEW_KEYWORD_CREATED",
};

export const PUBLISHED = {
  ITEMS_LOADED: "PUBLISHED/ITEMS_LOADED",
  REFRESH_ITEM_LIST: "PUBLISHED/REFRESH_ITEM_LIST",
  SET_LOADING: "PUBLISHED/SET_LOADING",
  WRITER_SELECTED: "PUBLISHED/WRITER_SELECTED",
  POST_TYPE_SELECTED: "PUBLISHED/POST_TYPE_SELECTED",
};

export const CLUSTERS = {
  CLUSTERS_LOADED: "CLUSTERS/CLUSTERS_LOADED",
  REFRESH_CLUSTERS_LIST: "CLUSTERS/REFRESH_CLUSTERS_LIST",
  CLUSTER_CREATED: "CLUSTERS/CLUSTER_CREATED",
  CLUSTER_CHANGED: "CLUSTERS/CLUSTER_CHANGED",
  CLUSTER_DELETED: "CLUSTERS/CLUSTER_DELETED",
  CLUSTER_SEARCH_TEXT_CHANGED: "CLUSTERS/CLUSTER_SEARCH_TEXT_CHANGED",

  CLUSTER_TOPIC_LOADED: "CLUSTERS/CLUSTER_TOPIC_LOADED",
  CLUSTER_TOPIC_CLEANUP: "CLUSTERS/CLUSTER_TOPIC_CLEANUP",
  CLUSTER_TOPIC_SEARCH_CONSOLE_INTERVAL_CHANGED: "CLUSTERS/CLUSTER_TOPIC_SEARCH_CONSOLE_INTERVAL_CHANGED",
  CLUSTER_TOPIC_POSTS_LOADED: "CLUSTERS/CLUSTER_TOPIC_POSTS_LOADED",
};

export const ADS = {
  ADS_LIST_LOADED: "ADS/ADS_LIST_LOADED",
  ADS_LIST_SEARCH_TEXT_CHANGED: "ADS/ADS_LIST_SEARCH_TEXT_CHANGED",
  ADS_LIST_SEARCH_CONSOLE_INTERVAL_CHANGED: "ADS/ADS_LIST_SEARCH_CONSOLE_INTERVAL_CHANGED",
  ADS_LIST_AD_CREATED: "ADS/ADS_LIST_AD_CREATED",
  ADS_LIST_AD_CHANGED: "ADS/ADS_LIST_AD_CHANGED",
  ADS_LIST_AD_DELETED: "ADS/ADS_LIST_AD_DELETED",

  EDIT_AD_LOADED: "ADS/EDIT_AD_LOADED",
  EDIT_AD_CHANGED: "ADS/EDIT_AD_CHANGED",
  EDIT_AD_PLACEMENT_CHANGED: "ADS/EDIT_AD_PLACEMENT_CHANGED",
  EDIT_AD_PLACEMENT_SELECTED: "ADS/EDIT_AD_PLACEMENT_SELECTED",
  EDIT_AD_CLEANUP: "ADS/EDIT_AD_CLEANUP",
};

export const GSC_QUERIES = {
  START_LOADING: "GSC_QUERIES/START_LOADING",
  QUERIES_LOADED: "GSC_QUERIES/QUERIES_LOADED",
  REFRESH_QUERIES_LIST: "GSC_QUERIES/REFRESH_QUERIES_LIST",
  QUERY_GROUP_CREATED: "GSC_QUERIES/QUERY_GROUP_CREATED",
  QUERY_CHANGED: "GSC_QUERIES/QUERY_CHANGED",
  QUERY_DELETED: "GSC_QUERIES/QUERY_DELETED",
  QUERY_SEARCH_TEXT_CHANGED: "GSC_QUERIES/QUERY_SEARCH_TEXT_CHANGED",

  QUERY_GROUP_LOADED: "GSC_QUERIES/QUERY_GROUP_LOADED",
  QUERY_GROUP_CLEANUP: "GSC_QUERIES/QUERY_GROUP_CLEANUP",
  QUERY_GROUP_SEARCH_CONSOLE_INTERVAL_CHANGED: "GSC_QUERIES/QUERY_GROUP_SEARCH_CONSOLE_INTERVAL_CHANGED",
  QUERY_GROUP_LOADING_QUERIES: "GSC_QUERIES/QUERY_GROUP_LOADING_QUERIES",
  QUERY_GROUP_QUERIES_LOADED: "GSC_QUERIES/QUERY_GROUP_QUERIES_LOADED",
  QUERY_GROUP_LIMIT_CHANGED: "QUERY_GROUP_LIMIT_CHANGED",
  // QUERY_GROUP_QUERIES_CLEANUP: 'GSC_QUERIES/QUERY_GROUP_QUERIES_CLEANUP',
};

export const QUERY_GROUPS_CONTAINERS = {
  CONTAINERS_LOADED: "QUERY_GROUPS_MAPPING/CONTAINERS_LOADED",
  ROOT_CONTAINER_LOADED: "QUERY_GROUPS_MAPPING/ROOT_CONTAINER_LOADED",
  CHILD_CONTAINER_LOADED: "QUERY_GROUPS_MAPPING/CHILD_CONTAINER_LOADED",
  HANDLE_CONTAINER_UPDATED: "QUERY_GROUPS_MAPPING/HANDLE_CONTAINER_UPDATED",
  HANDLE_CONTAINER_LIST_BACK_CLICK: "QUERY_GROUPS_MAPPING/HANDLE_CONTAINER_LIST_BACK_CLICK",
  REFRESH_GROUPS_LIST: "QUERY_GROUPS_MAPPING/REFRESH_GROUPS_LIST",
  SELECT_CONTAINER: "QUERY_GROUPS_MAPPING/SELECT_CONTAINER",
  START_LOADING_QUERY_GROUPS: "QUERY_GROUPS_MAPPING/START_LOADING_QUERY_GROUPS",
  QUERY_GROUPS_START_LOADING: "QUERY_GROUPS_MAPPING/QUERY_GROUPS_START_LOADING",
  QUERY_GROUPS_LOADED: "QUERY_GROUPS_MAPPING/QUERY_GROUPS_LOADED",
  QUERY_GROUPS_SEARCH_TEXT_CHANGED: "QUERY_GROUPS_MAPPING/QUERY_GROUPS_SEARCH_TEXT_CHANGED",
};

export const POSTS_IN_PROGRESS = {
  ITEMS_LOADED: "POSTS_IN_PROGRESS/ITEMS_LOADED",
  REFRESH_ITEM_LIST: "POSTS_IN_PROGRESS/REFRESH_ITEM_LIST",
};

export const WORKFLOW_PUBLISHED = {
  LOADED: "WORKFLOW/WORKFLOW_PUBLISHED/LOADED",
  REFRESH: "WORKFLOW/WORKFLOW_PUBLISHED/REFRESH",
};
export const WORKFLOW_PUBLISHED_CLUSTER = {
  LOADED: "WORKFLOW/WORKFLOW_PUBLISHED_CLUSTER/LOADED",
  REFRESH: "WORKFLOW/WORKFLOW_PUBLISHED_CLUSTER/REFRESH",
  WORKFLOW_PUBLISHED_CLUSTER_ON__FILTER_CHANGED:
    "WORKFLOW/WORKFLOW_PUBLISHED_CLUSTER/WORKFLOW_PUBLISHED_CLUSTER_ON__FILTER_CHANGED",
};

export const WORKFLOW_ALL_STATES = {
  LOADED: "WORKFLOW/ALL_STATES/LOADED",
  REFRESH: "WORKFLOW/ALL_STATES/REFRESH",
  UPDATE_PAGE: "WORKFLOW/ALL_STATES/UPDATE_PAGE",
  WORKFLOW_ALL_STATES_ON_FILTER_CHANGED: "WORKFLOW_ALL_STATES/WORKFLOW_ALL_STATES_ON_FILTER_CHANGED",
};

export const AUDIENCES = {
  ON_AUDIENCE_SELECTED: "AUDIENCES/ON_AUDIENCE_SELECTED",
  ON_AUDIENCE_CREATED: "AUDIENCES/ON_AUDIENCE_CREATED",
  ON_AUDIENCES_LOADED: "AUDIENCES/ON_AUDIENCES_LOADED",
  ON_AUDIENCE_CLEANUP: "AUDIENCES/ON_AUDIENCE_CLEANUP",
  ON_AUDIENCE_CHANGED: "AUDIENCES/ON_AUDIENCE_CHANGED",
  ON_AUDIENCE_PROPERTY_CHANGED: "AUDIENCES/ON_AUDIENCE_PROPERTY_CHANGED",
  ON_AUDIENCE_UPDATED: "AUDIENCES/ON_AUDIENCE_UPDATED",

  ON_MAIN_CATEGORIES_LOADED: "AUDIENCES/ON_MAIN_CATEGORIES_LOADED",
  ON_MAIN_CATEGORY_CREATED: "AUDIENCES/ON_MAIN_CATEGORY_CREATED",
  ON_CATEGORIES_LOADED: "AUDIENCES/ON_CATEGORIES_LOADED",
  ON_CATEGORY_CREATED: "AUDIENCES/ON_CATEGORY_CREATED",
  ON_TOPICS_LOADED: "AUDIENCES/ON_TOPICS_LOADED",
  ON_TOPIC_CREATED: "AUDIENCES/ON_TOPIC_CREATED",
  /*  ON_PRODUCT_UPDATED: 'MANAGE_CONTENT/PRODUCTS/ON_PRODUCT_UPDATED',
  ON_PRODUCT_DELETED: 'MANAGE_CONTENT/PRODUCTS/ON_PRODUCT_DELETED', */
};

export const WRITER_BIO = {
  ON_WRITERS_LOADED: "WRITER_BIO/ON_WRITERS_LOADED",
  ON_WRITER_CREATED: "WRITER_BIO/ON_WRITER_CREATED",
  ON_BIO_LOADED: "WRITER_BIO/ON_BIO_LOADED",
  ON_PROPERTY_CHANGED: "WRITER_BIO/ON_PROPERTY_CHANGED",
  ON_BIO_UPDATED: "WRITER_BIO/ON_BIO_UPDATED",
  CLEANUP: "WRITER_BIO/CLEANUP",
};

export const WEBSITE_PAGES = {
  ON_WEBSITE_PAGE_SELECTED: "WEBSITE_PAGES/ON_WEBSITE_PAGE_SELECTED",
  ON_WEBSITE_CONFIGURATIONS_LOADED: "WEBSITE_PAGES/ON_WEBSITE_CONFIGURATIONS_LOADED",
  ON_WEBSITE_CONFIGURATIONS_LOADING: "WEBSITE_PAGES/ON_WEBSITE_CONFIGURATIONS_LOADING",
  ON_WEBSITE_PAGES_PROPERTY_CHANGED: "WEBSITE_PAGES/ON_WEBSITE_PAGES_PROPERTY_CHANGED",
  ON_WEBSITE_PAGES_DYNAMIC_SECTION_INIT: "WEBSITE_PAGES/ON_WEBSITE_PAGES_DYNAMIC_SECTION_INIT",
  ON_WEBSITE_TOGGLE_ACTIVE: "WEBSITE_PAGES/ON_WEBSITE_TOGGLE_ACTIVE",
};
export const EDITORIAL_POLICY = {
  ON_POLICY_LOADED: "EDITORIAL_POLICY/ON_POLICY_LOADED",
  ON_POLICY_CHANGED: "EDITORIAL_POLICY/ON_POLICY_CHANGED",
  CLEANUP: "EDITORIAL_POLICY/CLEANUP",
  ON_POLICIES_LOADED: "EDITORIAL_POLICY/ON_POLICIES_LOADED",
};

export const WEBSITE_PAGE_ACTIVE = {
  ON_ACTIVE_STATUS_CHANGED: "WEBSITE_PAGE_ACTIVE/ON_ACTIVE_STATUS_CHANGED",
  ON_ACTIVE_STATUS_LOADED: "WEBSITE_PAGE_ACTIVE/ON_ACTIVE_STATUS_LOADED",
};

export const WEBSITE_PAGE_GENERAL = {
  ON_IMAGES_LOADED: "WEBSITE_PAGE_GENERAL/ON_IMAGES_LOADED",
};

export const OUTLINE = {
  ON_OUTLINE_PAGES_LOADED: "OUTLINE/ON_OUTLINE_PAGES_LOADED",
  ON_OUTLINE_PAGE_LOADED: "OUTLINE/ON_OUTLINE_PAGE_LOADED",
  ON_OUTLINE_PAGE_CHANGED: "OUTLINE/ON_OUTLINE_PAGE_CHANGED",
  ON_OUTLINE_PAGE_CREATED: "OUTLINE/ON_OUTLINE_PAGE_CREATED",
  ON_OUTLINE_PAGE_REMOVED: "OUTLINE/ON_OUTLINE_PAGE_REMOVED",
  ON_OUTLINE_PAGES_SEARCH_TEXT_CHANGED: "OUTLINE/ON_OUTLINE_PAGES_SEARCH_TEXT_CHANGED",
  ON_OUTLINE_PROPERTY_CHANGED: "OUTLINE/ON_OUTLINE_PROPERTY_CHANGED",
  ON_OUTLINE_CATEGORIES_LOADED: "OUTLINE/ON_OUTLINE_CATEGORIES_LOADED",
  ON_OUTLINE_KEYWORD_GROUPS_LOADED: "OUTLINE/ON_OUTLINE_KEYWORD_GROUPS_LOADED",
  ON_OUTLINE_TAGS_LOADED: "OUTLINE/ON_OUTLINE_TAGS_LOADED",
  OUTLINE_PAGE_CLEANUP: "OUTLINE/OUTLINE_PAGE_CLEANUP",
};

export const OUTLINE_PAGES = {
  ON_OUTLINE_PAGES_LOADED: "OUTLINE_PAGES/ON_OUTLINE_PAGES_LOADED",
  ON_OUTLINE_PAGES_UPDATED: "OUTLINE_PAGES/ON_OUTLINE_PAGES_UPDATED",
  ON_OUTLINE_PAGES_START_LOADING: "OUTLINE_PAGES/ON_OUTLINE_PAGES_START_LOADING",
  ON_OUTLINE_PAGE_SELECTED: "OUTLINE_PAGES/ON_OUTLINE_PAGE_SELECTED",
  ON_OUTLINE_PAGINATION_CHANGE: "OUTLINE_PAGES/ON_OUTLINE_PAGINATION_CHANGE",
  ON_OUTLINE_PAGES_SEARCH_TEXT_CHANGED: "OUTLINE_PAGES/ON_OUTLINE_PAGES_SEARCH_TEXT_CHANGED",
  ON_OUTLINE_PAGES_KEYWORDS_LOADED: "OUTLINE_PAGES/ON_OUTLINE_PAGES_KEYWORDS_LOADED",
  ON_OUTLINE_PAGES_KEYWORDS_EMPTY: "OUTLINE_PAGES/ON_OUTLINE_PAGES_KEYWORDS_EMPTY",
  ON_OUTLINE_PAGES_OUTLINE_UPDATED: "OUTLINE_PAGES/ON_OUTLINE_PAGES_OUTLINE_UPDATED",
  ON_OUTLINE_PAGES_SERPS_LOADED: "OUTLINE_PAGES/ON_OUTLINE_PAGES_SERPS_LOADED",
  ON_OUTLINE_PAGES_SERPS_LOADING: "OUTLINE_PAGES/ON_OUTLINE_PAGES_SERPS_LOADING",

  ON_AUTO_VIDEO_INTERVIEW_LOADED: "OUTLINE_PAGES/ON_AUTO_VIDEO_INTERVIEW_LOADED",

  OUTLINE_PAGES_ON_FILTER_CHANGED: "OUTLINE_PAGES/OUTLINE_PAGES_ON_FILTER_CHANGED",
};

export const KEYWORD_STRATEGY = {
  ON_KEYWORD_STRATEGY_DATA_LOADED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_DATA_LOADED",
  ON_KEYWORD_STRATEGY_DATA_LOADING: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_DATA_LOADING",
  ON_KEYWORD_STRATEGY_SELECTED_DATA_ITEM_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_DATA_ITEM_SELECTED",
  ON_KEYWORD_STRATEGY_DATA_SELECTED_ITEMS_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_DATA_SELECTED_ITEMS_CHANGED",
  ON_KEYWORD_STRATEGY_APPROVED_SELECTED_ITEMS_CHANGED:
    "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_APPROVED_SELECTED_ITEMS_CHANGED",
  ON_KEYWORD_STRATEGY_STATUS_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_STATUS_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_EXCLUDE_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_EXCLUDE_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_INCLUDE_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_INCLUDE_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_START_TIME_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_START_TIME_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_SELECTED_COLUMNS_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_SELECTED_COLUMNS_CHANGED",
  ON_KEYWORD_STRATEGY_POST_TYPE_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_POST_TYPE_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_PAGE_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_PAGE_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_CLICKS_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_CLICKS_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_POSITION_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_POSITION_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_VOLUME_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_VOLUME_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_SEARCH_TEXT_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_SEARCH_TEXT_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_IS_DIALOG_OPEN_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_IS_DIALOG_OPEN_CHANGED",
  ON_KEYWORD_STRATEGY_ACTIVE_DIALOG_TAB_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_ACTIVE_DIALOG_TAB_CHANGED",
  ON_KEYWORD_STRATEGY_SORT_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_SORT_CHANGED",
  ON_KEYWORD_STRATEGY_PAGE_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_PAGE_CHANGED",
  ON_KEYWORD_STRATEGY_LIMIT_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_LIMIT_CHANGED",
  ON_KEYWORD_STRATEGY_REFETCH_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_REFETCH_CHANGED",
  ON_KEYWORD_STRATEGY_BROAD_KEYWORDS_LOADED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_BROAD_KEYWORDS_LOADED",
  ON_KEYWORD_STRATEGY_SERPS_LOADED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_SERPS_LOADED",
  ON_KEYWORD_STRATEGY_SERPS_LOADING: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_SERPS_LOADING",
  ON_KEYWORD_STRATEGY_FILTER_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_FILTER_CHANGED",
  ON_KEYWORD_STRATEGY_SELECTED_STATUS_CHANGED: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_SELECTED_STATUS_CHANGED",
  ON_KEYWORD_STRATEGY_UPDATE_ITEM: "KEYWORD_STRATEGY/ON_KEYWORD_STRATEGY_UPDATE_ITEM",
};

export const CONTENT_GAPS = {
  ON_CONTENT_GAPS_DATA_LOADED: "CONTENT_GAPS/ON_CONTENT_GAPS_DATA_LOADED",
  ON_CONTENT_GAPS_DATA_LOADING: "CONTENT_GAPS/ON_CONTENT_GAPS_DATA_LOADING",
  ON_CONTENT_GAPS_SELECTED_DATA_ITEM_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_DATA_ITEM_SELECTED",
  ON_CONTENT_GAPS_DATA_SELECTED_ITEMS_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_DATA_SELECTED_ITEMS_CHANGED",
  ON_CONTENT_GAPS_APPROVED_SELECTED_ITEMS_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_APPROVED_SELECTED_ITEMS_CHANGED",
  ON_CONTENT_GAPS_STATUS_FILTER_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_STATUS_FILTER_CHANGED",
  ON_CONTENT_GAPS_EXCLUDE_FILTER_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_EXCLUDE_FILTER_CHANGED",
  ON_CONTENT_GAPS_INCLUDE_FILTER_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_INCLUDE_FILTER_CHANGED",
  ON_CONTENT_GAPS_IMPRESSIONS_FILTER_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_IMPRESSIONS_FILTER_CHANGED",
  ON_CONTENT_GAPS_SELECTED_COLUMNS_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_SnELECTED_COLUMNS_CHANGED",
  ON_CONTENT_GAPS_FUNNEL_STAGE_FILTER_CHANGE: "CONTENT_GAPS/ON_CONTENT_GAPS_FUNNEL_STAGE_FILTER_CHANGE",
  ON_CONTENT_GAPS_PAGE_FILTER_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_PAGE_FILTER_CHANGED",
  ON_CONTENT_GAPS_KEYWORDS_FILTER_CHANGE: "CONTENT_GAPS/ON_CONTENT_GAPS_KEYWORDS_FILTER_CHANGE",
  ON_CONTENT_GAPS_CLICKS_FILTER_CHANGE: "CONTENT_GAPS/ON_CONTENT_GAPS_CLICKS_FILTER_CHANGE",
  ON_CONTENT_GAPS_POSITION_FILTER_CHANGE: "CONTENT_GAPS/ON_CONTENT_GAPS_POSITION_FILTER_CHANGE",
  ON_CONTENT_GAPS_FILTER_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_FILTER_CHANGED",

  ON_CONTENT_GAPS_CLICKS_FILTER_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_CLICKS_FILTER_CHANGED",
  ON_CONTENT_GAPS_VOLUME_FILTER_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_VOLUME_FILTER_CHANGED",
  ON_CONTENT_GAPS_SEARCH_TEXT_FILTER_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_SEARCH_TEXT_FILTER_CHANGED",
  ON_CONTENT_GAPS_IS_DIALOG_OPEN_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_IS_DIALOG_OPEN_CHANGED",
  ON_CONTENT_GAPS_ACTIVE_DIALOG_TAB_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_ACTIVE_DIALOG_TAB_CHANGED",
  ON_CONTENT_GAPS_SORT_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_SORT_CHANGED",
  ON_CONTENT_GAPS_PAGE_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_PAGE_CHANGED",
  ON_CONTENT_GAPS_LIMIT_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_LIMIT_CHANGED",
  ON_CONTENT_GAPS_REFETCH_CHANGED: "CONTENT_GAPS/ON_CONTENT_GAPS_REFETCH_CHANGED",
  ON_CONTENT_GAPS_BROAD_KEYWORDS_LOADED: "CONTENT_GAPS/ON_CONTENT_GAPS_BROAD_KEYWORDS_LOADED",

  ON_CONTENT_GAPS_SERPS_LOADED: "CONTENT_GAPS/ON_CONTENT_GAPS_SERPS_LOADED",
};

export const KEYWORD_GROUP_PAGES = {
  ON_KEYWORD_GROUP_PAGES_DATA_LOADED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_DATA_LOADED",
  ON_KEYWORD_GROUP_PAGES_DATA_LOADING: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_DATA_LOADING",
  ON_KEYWORD_GROUP_PAGES_SELECTED_DATA_ITEM_CHANGED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_DATA_ITEM_SELECTED",
  ON_KEYWORD_GROUP_PAGES_DATA_SELECTED_ITEMS_CHANGED:
    "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_DATA_SELECTED_ITEMS_CHANGED",
  ON_KEYWORD_GROUP_PAGES_APPROVED_SELECTED_ITEMS_CHANGED:
    "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_APPROVED_SELECTED_ITEMS_CHANGED",
  ON_KEYWORD_GROUP_PAGES_STATUS_FILTER_CHANGED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_STATUS_FILTER_CHANGED",
  ON_KEYWORD_GROUP_PAGES_POST_TYPE_FILTER_CHANGED:
    "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_POST_TYPE_FILTER_CHANGED",
  ON_KEYWORD_GROUP_PAGES_PAGE_FILTER_CHANGED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_PAGE_FILTER_CHANGED",
  ON_KEYWORD_GROUP_PAGES_CLICKS_FILTER_CHANGED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_CLICKS_FILTER_CHANGED",
  ON_KEYWORD_GROUP_PAGES_VOLUME_FILTER_CHANGED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_VOLUME_FILTER_CHANGED",
  ON_KEYWORD_GROUP_PAGES_FILTER_CHANGED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_FILTER_CHANGED",

  ON_KEYWORD_GROUP_PAGES_IS_DIALOG_OPEN_CHANGED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_IS_DIALOG_OPEN_CHANGED",
  ON_KEYWORD_GROUP_PAGES_ACTIVE_DIALOG_TAB_CHANGED:
    "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_ACTIVE_DIALOG_TAB_CHANGED",
  ON_KEYWORD_GROUP_PAGES_PAGE_CHANGED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_PAGE_CHANGED",
  ON_KEYWORD_GROUP_PAGES_LIMIT_CHANGED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_LIMIT_CHANGED",
  ON_KEYWORD_GROUP_PAGES_REFETCH_CHANGED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_REFETCH_CHANGED",
  ON_KEYWORD_GROUP_PAGES_BROAD_KEYWORDS_LOADED: "KEYWORD_GROUP_PAGES/ON_KEYWORD_GROUP_PAGES_BROAD_KEYWORDS_LOADED",
};

export const INTERNAL_LINKING = {
  ON_INTERNAL_LINKING_DATA_LOADED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_DATA_LOADED",
  ON_INTERNAL_LINKING_DATA_LOADING: "INTERNAL_LINKING/ON_INTERNAL_LINKING_DATA_LOADING",
  ON_INTERNAL_LINKING_SELECTED_DATA_ITEM_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_DATA_ITEM_SELECTED",
  ON_INTERNAL_LINKING_DATA_SELECTED_ITEMS_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_DATA_SELECTED_ITEMS_CHANGED",
  ON_INTERNAL_LINKING_RELATED_KEYWORDS_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_RELATED_KEYWORDS_CHANGED",
  ON_INTERNAL_LINKING_APPROVED_SELECTED_ITEMS_CHANGED:
    "INTERNAL_LINKING/ON_INTERNAL_LINKING_APPROVED_SELECTED_ITEMS_CHANGED",
  ON_INTERNAL_LINKING_STATUS_FILTER_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_STATUS_FILTER_CHANGED",
  ON_INTERNAL_LINKING_SEARCH_TEXT_FILTER_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_SEARCH_TEXT_FILTER_CHANGED",
  ON_INTERNAL_LINKING_FUNNEL_STAGE_FILTER_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_FUNNEL_STAGE_FILTER_CHANGED",
  ON_INTERNAL_LINKING_IS_DIALOG_OPEN_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_IS_DIALOG_OPEN_CHANGED",
  ON_INTERNAL_LINKING_ACTIVE_DIALOG_TAB_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_ACTIVE_DIALOG_TAB_CHANGED",
  ON_INTERNAL_LINKING_SORT_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_SORT_CHANGED",
  ON_INTERNAL_LINKING_PAGE_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_PAGE_CHANGED",
  ON_INTERNAL_LINKING_LIMIT_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_LIMIT_CHANGED",
  ON_INTERNAL_LINKING_REFETCH_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_REFETCH_CHANGED",
  ON_INTERNAL_LINKING_RELATED_PAGES_LOADED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_RELATED_PAGES_LOADED",
  ON_INTERNAL_LINKING_FILTER_CHANGED: "INTERNAL_LINKING/ON_INTERNAL_LINKING_FILTER_CHANGED",
};

export const STRATEGY = {
  ON_STRATEGY_LOADED: "STRATEGY/ON_STRATEGY_LOADED",
  ON_STRATEGY_CHANGED: "STRATEGY/ON_STRATEGY_CHANGED",
  ON_NEW_AUDIENCE_CREATED: "STRATEGY/ON_NEW_AUDIENCE_CREATED",
  ON_NEW_AUDIENCE_LOADED: "STRATEGY/ON_NEW_AUDIENCE_LOADED",
};

export const FETCH_KEYWORDS = {
  ON_SEEDS_LIST_LOADED: "FETCH_KEYWORDS/ON_SEEDS_LIST_LOADED",
  ON_SEEDS_LIST_ADDED: "FETCH_KEYWORDS/ON_SEEDS_LIST_ADDED",
  ON_SEARCH_TEXT_CHANGED: "FETCH_KEYWORDS/ON_SEARCH_TEXT_CHANGED",
  ON_SELECTED_SITE_CHANGED: "FETCH_KEYWORDS/ON_SELECTED_SITE_CHANGED",
  ON_CHECKED_BOX_CHANGED: "FETCH_KEYWORDS/ON_CHECKED_BOX_CHANGED",
  ON_SEED_CHANGED: "FETCH_KEYWORDS/ON_SEED_CHANGED",
  ON_SEEDS_REMOVED: "FETCH_KEYWORDS/ON_SEEDS_REMOVED",
  CLEAR_CHECKED_SEEDS: "FETCH_KEYWORDS/CLEAR_CHECKED_SEEDS",
  SET_API_BALANCE: "FETCH_KEYWORDS/SET_API_BALANCE",
  ON_FETCH_KEYWORDS_FILTER_CHANGED: "FETCH_KEYWORDS/ON_FETCH_KEYWORDS_FILTER_CHANGED",
};

export const EXCLUDE_KEYWORDS = {
  ON_KEYWORDS_LIST_LOADED: "EXCLUDE_KEYWORDS/ON_KEYWORDS_LIST_LOADED",
  ON_SEARCH_TEXT_CHANGED: "EXCLUDE_KEYWORDS/ON_SEARCH_TEXT_CHANGED",
  ON_SELECTED_SITE_CHANGED: "EXCLUDE_KEYWORDS/ON_SELECTED_SITE_CHANGED",
  ON_CHECKED_BOX_CHANGED: "EXCLUDE_KEYWORDS/ON_CHECKED_BOX_CHANGED",
  ON_KEYWORD_CHANGED: "EXCLUDE_KEYWORDS/ON_KEYWORD_CHANGED",
  ON_KEYWORDS_REMOVED: "EXCLUDE_KEYWORDS/ON_KEYWORDS_REMOVED",
  ON_EXCLUDE_KEYWORD_ADDED: "EXCLUDE_KEYWORDS/ON_EXCLUDE_KEYWORD_ADDED",
  ON_EXCLUDE_KEYWORD_FILTER_CHANGED: "EXCLUDE_KEYWORDS/ON_EXCLUDE_KEYWORD_FILTER_CHANGED",
};

export const KEYWORD_FREQUENCY = {
  ON_KEYWORDS_LIST_LOADED: "KEYWORD_FREQUENCY/ON_KEYWORDS_LIST_LOADED",
  ON_SEARCH_TEXT_CHANGED: "KEYWORD_FREQUENCY/ON_SEARCH_TEXT_CHANGED",
  ON_SELECTED_SITE_CHANGED: "KEYWORD_FREQUENCY/ON_SELECTED_SITE_CHANGED",
  ON_CHECKED_BOX_CHANGED: "KEYWORD_FREQUENCY/ON_CHECKED_BOX_CHANGED",
  ON_KEYWORD_CHANGED: "KEYWORD_FREQUENCY/ON_KEYWORD_CHANGED",
  ON_KEYWORDS_REMOVED: "KEYWORD_FREQUENCY/ON_KEYWORDS_REMOVED",
};

export const OPPORTUNITIES = {
  OPPORTUNITIES_LOADED: "OPPORTUNITIES/OPPORTUNITIES_LOADED",
  OPPORTUNITIES_START_LOADING: "OPPORTUNITIES/OPPORTUNITIES_START_LOADING",

  OPPORTUNITY_LOADED: "OPPORTUNITIES/OPPORTUNITY_LOADED",
  OPPORTUNITY_START_LOADING: "OPPORTUNITIES/OPPORTUNITY_START_LOADING",
  OPPORTUNITY_PAGES_START_LOADING: "OPPORTUNITIES/OPPORTUNITY_PAGES_START_LOADING",
  OPPORTUNITY_PAGES_LOADED: "OPPORTUNITIES/OPPORTUNITY_PAGES_LOADED",
  ON_CHECKED_BOX_CHANGED: "OPPORTUNITIES/ON_CHECKED_BOX_CHANGED",
};

export const WRITE_PAGES = {
  WRITE_PAGES_LOADED: "WRITE_PAGES/WRITE_PAGES_LOADED",
  WRITE_PAGES_LOAD_FAILED: "WRITE_PAGES/WRITE_PAGES_LOAD_FAILED",
  WRITE_PAGES_LOADING: "WRITE_PAGES/WRITE_PAGES_LOADING",
  WRITE_PAGES_REFRESH: "WRITE_PAGES/WRITE_PAGES_REFRESH",
  WRITE_PAGES_ON_FILTER_CHANGED: "WRITE_PAGES/WRITE_PAGES_ON_FILTER_CHANGED",
  WRITE_PAGES_UPDATE_PAGE: "WRITER_PAGES/WRITE_PAGES_UPDATE_PAGE",
};

export const EDIT_PAGES = {
  PAGES_LOADED: "EDIT_PAGES/REVIEW_PAGES_LOADED",
  PAGES_LOADING: "EDIT_PAGES/REVIEW_PAGES_LOADING",
  PAGES_REFRESH: "EDIT_PAGES/REVIEW_PAGES_REFRESH",
  PAGES_LOAD_FAILED: "EDIT_PAGES/REVIEW_PAGES_LOAD_FAILED",
  PAGES_UPDATE_PAGE: "EDIT_PAGES/EDIT_PAGES_UPDATE_PAGE",
  EDIT_PAGES_ON_POST_TYPE_FILTER_CHANGED: "EDIT_PAGES/EDIT_PAGES_ON_POST_TYPE_FILTER_CHANGED",
  EDIT_PAGES_ON_FILTER_CHANGED: "EDIT_PAGES/EDIT_PAGES_ON_FILTER_CHANGED",
};

export const SECOND_EDIT_PAGES = {
  PAGES_LOADED: "SECOND_EDIT_PAGES/REVIEW_PAGES_LOADED",
  PAGES_LOADING: "SECOND_EDIT_PAGES/REVIEW_PAGES_LOADING",
  PAGES_REFRESH: "SECOND_EDIT_PAGES/REVIEW_PAGES_REFRESH",
  PAGES_LOAD_FAILED: "SECOND_EDIT_PAGES/REVIEW_PAGES_LOAD_FAILED",
  PAGES_UPDATE_PAGE: "SECOND_EDIT_PAGES/REVIEW_PAGES_UPDATE_PAGE",
  PAGES_ON_FILTER_CHANGED: "SECOND_EDIT_PAGES/PAGES_ON_FILTER_CHANGED",
};

export const FACT_CHECK_PAGES = {
  FACT_CHECK_PAGES_LOADED: "FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOADED",
  FACT_CHECK_PAGES_LOADING: "FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOADING",
  FACT_CHECK_PAGES_REFRESH: "FACT_CHECK_PAGES/FACT_CHECK_PAGES_REFRESH",
  FACT_CHECK_PAGES_LOAD_FAILED: "FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOAD_FAILED",
  FACT_CHECK_PAGES_UPDATE_PAGE: "FACT_CHECK_PAGES/FACT_CHECK_PAGES_UPDATE_PAGE",
  PAGES_ON_FILTER_CHANGED: "FACT_CHECK_PAGES/PAGES_ON_FILTER_CHANGED",
  PAGES_ON_FILTER_CLEANUP: "FACT_CHECK_PAGES/PAGES_ON_FILTER_CLEANUP",
};

export const SECOND_FACT_CHECK_PAGES = {
  SECOND_FACT_CHECK_PAGES_LOADED: "SECOND_FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOADED",
  SECOND_FACT_CHECK_PAGES_LOADING: "SECOND_FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOADING",
  SECOND_FACT_CHECK_PAGES_REFRESH: "SECOND_FACT_CHECK_PAGES/FACT_CHECK_PAGES_REFRESH",
  SECOND_FACT_CHECK_PAGES_LOAD_FAILED: "SECOND_FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOAD_FAILED",
};

export const THIRD_FACT_CHECK_PAGES = {
  THIRD_FACT_CHECK_PAGES_LOADED: "THIRD_FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOADED",
  THIRD_FACT_CHECK_PAGES_LOADING: "THIRD_FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOADING",
  THIRD_FACT_CHECK_PAGES_REFRESH: "THIRD_FACT_CHECK_PAGES/FACT_CHECK_PAGES_REFRESH",
  THIRD_FACT_CHECK_PAGES_LOAD_FAILED: "THIRD_FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOAD_FAILED",
};

export const FOURTH_FACT_CHECK_PAGES = {
  FOURTH_FACT_CHECK_PAGES_LOADED: "FOURTH_FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOADED",
  FOURTH_FACT_CHECK_PAGES_LOADING: "FOURTH_FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOADING",
  FOURTH_FACT_CHECK_PAGES_REFRESH: "FOURTH_FACT_CHECK_PAGES/FACT_CHECK_PAGES_REFRESH",
  FOURTH_FACT_CHECK_PAGES_LOAD_FAILED: "FOURTH_FACT_CHECK_PAGES/FACT_CHECK_PAGES_LOAD_FAILED",
};

export const PUBLISHED_PAGES = {
  PUBLISHED_PAGES_LOADED: "PUBLISHED_PAGES/PUBLISHED_PAGES_LOADED",
  PUBLISHED_PAGES_LOADING: "PUBLISHED_PAGES/PUBLISHED_PAGES_LOADING",
  PUBLISHED_PAGES_REFRESH: "PUBLISHED_PAGES/PUBLISHED_PAGES_REFRESH",
  PUBLISHED_PAGES_LOAD_FAILED: "PUBLISHED_PAGES/PUBLISHED_PAGES_LOAD_FAILED",
  PUBLISHED_PAGES_UPDATE_PAGE: "PUBLISHED_PAGES/PUBLISHED_PAGES_UPDATE_PAGE",
  PUBLISHED_PAGES_ON_FILTER_CHANGED: "PUBLISHED_PAGES/PUBLISHED_PAGES_ON_FILTER_CHANGED",
};

export const COMMUNITY = {
  MODERATOR_QUESTIONS_LOADED: "COMMUNITY/MODERATOR_QUESTIONS_LOADED",
  MODERATOR_QUESTIONS_ON_FILTER_CHANGED: "COMMUNITY/MODERATOR_QUESTIONS_ON_FILTER_CHANGED",
  NEW_QUESTIONS_LOADED: "COMMUNITY/NEW_QUESTIONS_LOADED",
  DRAFT_QUESTIONS_LOADED: "COMMUNITY/DRAFT_QUESTIONS_LOADED",
  DRAFT_QUESTION_ON_FILTER_CHANGED: "COMMUNITY/DRAFT_QUESTION_ON_FILTER_CHANGED",
  PUBLISHED_QUESTIONS_LOADED: "COMMUNITY/PUBLISHED_QUESTIONS_LOADED",
  PUBLISHED_ON_FILTER_CHANGED: " ANSWERS_COMMUNITY/PUBLISHED_ON_FILTER_CHANGED",
  DELETED_QUESTIONS_LOADED: "COMMUNITY/DELETED_QUESTIONS_LOADED",
  DELETED_QUESTIONS_ON_FILTER_CHANGED: "COMMUNITY/DELETED_QUESTIONS_ON_FILTER_CHANGED",

  QUESTIONS_LOADING: "COMMUNITY/QUESTIONS_LOADING",

  ANSWERS_LOADED: "COMMUNITY/ANSWERS_LOADED",
  ANSWERS_LOADING: "COMMUNITY/ANSWERS_LOADING",
  ANSWERS_REFRESH: "COMMUNITY/ANSWERS_REFRESH",
  ANSWERS_LOAD_FAILED: "COMMUNITY/ANSWERS_LOAD_FAILED",
  ANSWERS_ON_FILTER_CHANGED: " ANSWERS_COMMUNITY/ANSWERS_ON_FILTER_CHANGED",

  PUBLISHED_ANSWERS_LOADED: "COMMUNITY/PUBLISHED_ANSWERS_LOADED",
  PUBLISHED_ANSWERS_LOADING: "COMMUNITY/PUBLISHED_ANSWERS_LOADING",
  PUBLISHED_ANSWERS_REFRESH: "COMMUNITY/PUBLISHED_ANSWERS_REFRESH",
  PUBLISHED_ANSWERS_LOAD_FAILED: "COMMUNITY/PUBLISHED_ANSWERS_LOAD_FAILED",
  PUBLISHED_ANSWERS_ON_FILTER_CHANGED: "COMMUNITY/PUBLISHED_ANSWERS_ON_FILTER_CHANGED",

  NEW_QUESTION_CREATED: "COMMUNITY/NEW_QUESTION_CREATED",
  NEW_QUESTION_ON_FILTER_CHANGED: "COMMUNITY/NEW_QUESTION_ON_FILTER_CHANGED",

  CHANGE_QUESTIONS_FILTER: "COMMUNITY/CHANGE_QUESTIONS_FILTER",
  QUESTIONS_LOADED: "COMMUNITY/QUESTIONS_LOADED",
  QUESTION_SELECTED: "COMMUNITY/QUESTION_SELECTED",
  QUESTION_CHANGED: "COMMUNITY/QUESTION_CHANGED",
  QUESTION_POSTED_TIME_CHANGED: "COMMUNITY/QUESTION_POSTED_TIME_CHANGED",
  QUESTION_SAVED: "COMMUNITY/QUESTION_SAVED",

  SET_FOCUSED_EDITOR_ID: "COMMUNITY/SET_FOCUSED_EDITOR_ID",
};

export const COMMUNITY_EDITOR = {
  COMMUNITY_EDITOR_WRITERS_LOADED: "COMMUNITY_EDITOR/COMMUNITY_EDITOR_WRITERS_LOADED",
  // get question and get communityWriterAnswer or both
  COMMUNITY_EDITOR_DATA_LOADED: "COMMUNITY_EDITOR/COMMUNITY_EDITOR_DATA_LOADED",
  COMMUNITY_EDITOR_CLEANUP: "COMMUNITY_EDITOR/COMMUNITY_EDITOR_CLEANUP",
  COMMUNITY_QUESTION_SAVED: "COMMUNITY_EDITOR/COMMUNITY_QUESTION_SAVED",
  COMMUNITY_QUESTION_CHANGED: "COMMUNITY_EDITOR/COMMUNITY_QUESTION_CHANGED",

  COMMUNITY_ADD_NEW_ANSWER: "COMMUNITY_EDITOR/COMMUNITY_ADD_NEW_ANSWER",
  COMMUNITY_ANSWER_DELETED: "COMMUNITY_EDITOR/COMMUNITY_ANSWER_DELETED",
  COMMUNITY_ANSWER_CHANGED: "COMMUNITY_EDITOR/COMMUNITY_ANSWER_CHANGED",
  COMMUNITY_ANSWER_POSTED_TIME_CHANGED: "COMMUNITY_EDITOR/COMMUNITY_ANSWER_POSTED_TIME_CHANGED",

  COMMUNITY_WRITER_ANSWER_CHANGED: "COMMUNITY_EDITOR/COMMUNITY_WRITER_ANSWER_CHANGED",
  COMMUNITY_WRITER_ANSWER_POSTED_TIME_CHANGED: "COMMUNITY_EDITOR/COMMUNITY_WRITER_ANSWER_POSTED_TIME_CHANGED",

  // ?
  // WRITER_ANSWER_SAVED: "COMMUNITY_EDITOR/WRITER_ANSWER_SAVED",
  COMMUNITY_QUESTION_POSTED_TIME_CHANGED: "COMMUNITY_EDITOR/COMMUNITY_QUESTION_POSTED_TIME_CHANGED",

  ON_TAGS_LOADED: "COMMUNITY_EDITOR/ON_TAGS_LOADED",
  ON_TAG_SELECTED: "COMMUNITY_EDITOR/ON_TAG_SELECTED",
  ON_TAG_REMOVED: "COMMUNITY_EDITOR/ON_TAG_REMOVED",
  ON_NEW_TAG_CREATED: "COMMUNITY_EDITOR/ON_NEW_TAG_CREATED",
};

export const WIDGET_BUILDER = {
  WIDGET_LOADED: "WIDGET_BUILDER/WIDGET_LOADED",
  WIDGET_CLEANUP: "WIDGET_BUILDER/WIDGET_CLEANUP",

  SET_SELECTED_ITEM: "WIDGET_BUILDER/SET_SELECTED_ITEM",
  SET_SELECTED_STEP: "WIDGET_BUILDER/SET_SELECTED_STEP",
  SET_SELECTED_FIELD: "WIDGET_BUILDER/SET_SELECTED_FIELD",

  EDIT_WIDGET_PROPERTY: "WIDGET_BUILDER/EDIT_WIDGET_PROPERTY",
  ITEM_CHANGED: "WIDGET_BUILDER/ITEM_CHANGED",
  ADD_STEP: "WIDGET_BUILDER/ADD_STEP",
  DELETE_STEP: "WIDGET_BUILDER/DELETE_STEP",
  BUTTON_CHANGED: "WIDGET_BUILDER/BUTTON_CHANGED",
  ADD_NEW_FIELD: "WIDGET_BUILDER/ADD_NEW_FIELD",
  EDIT_FIELD: "WIDGET_BUILDER/EDIT_FIELD",
  DELETE_FIELD: "WIDGET_BUILDER/DELETE_FIELD",

  DESIGN: "WIDGET_BUILDER/DESIGN",
  DESIGN_FIELDS_DESIGN: "WIDGET_BUILDER/DESIGN_FIELDS_DESIGN",

  DESIGN_APPEARANCE_EDIT: "WIDGET_BUILDER/DESIGN_APPEARANCE_EDIT",
  DESIGN_LAYOUT_EDIT: "WIDGET_BUILDER/DESIGN_LAYOUT_EDIT",
  DESIGN_HEADER_EDIT: "WIDGET_BUILDER/DESIGN_HEADER_EDIT",
  DESIGN_INPUT_FIELDS_EDIT: "WIDGET_BUILDER/DESIGN_INPUT_FIELDS_EDIT",
  DESIGN_BUTTON_DESIGN_EDIT: "WIDGET_BUILDER/DESIGN_BUTTON_DESIGN_EDIT",
  DESIGN_BACKGROUND_EDIT: "WIDGET_BUILDER/DESIGN_BACKGROUND_EDIT",

  ADD_NEW_ITEM: "WIDGET/BUILDER/ADD_NEW_ITEM",

  EDIT_SETTINGS: "WIDGET/BUILDER/EDIT_SETTINGS",
  EDIT_BACKGROUND: "WIDGET/BUILDER/EDIT_BACKGROUND",
  DELETE_ITEM: "WIDGET/BUILDER/DELETE_ITEM",
};

export const WIDGETS = {
  WIDGETS_LOADED: "WIDGETS/WIDGETS_LOADED",
  ON_VALUE_CHANGED: "WIDGETS/ON_VALUE_CHANGED",
};

export const GOAL_COMPLETION_REPORTS = {
  GOAL_COMPLETION_BY_PAGE_CHANGED: "GOAL_COMPLETION_REPORTS/GOAL_COMPLETION_BY_PAGE_CHANGED",
  GOAL_COMPLETION_BY_PAGE_LOADED: "GOAL_COMPLETION_REPORTS/GOAL_COMPLETION_BY_PAGE_LOADED",
};

export const WIDGETS_DISPLAY_RULES = {
  RULES_LOADED: "WIDGETS_DISPLAY_RULES/RULES_LOADED",
  PROPERTY_CHANGE: "WIDGETS_DISPLAY_RULES/PROPERTY_CHANGE",
};

export const WIDGET_RULE_EDITOR = {
  RULE_LOADED: "WIDGETS_DISPLAY_RULES/RULE_LOADED",
  PROPERTY_CHANGE: "WIDGETS_DISPLAY_RULES/PROPERTY_CHANGE",
  PREVIEW_PROPERTY_CHANGE: "WIDGETS_DISPLAY_RULES/PREVIEW_PROPERTY_CHANGE",
  UNMOUNT: "WIDGETS_DISPLAY_RULES/UNMOUNT",
  FROM_ON_BOARDING: "WIDGETS_DISPLAY_RULES/FROM_ON_BOARDING",
};

export const WIDGET_PICKER = {
  MOUNT: "WIDGET_PICKER/MOUNT",
  PROPERTY_CHANGE: "WIDGET_PICKER/PROPERTY_CHANGE",
  UNMOUNT: "WIDGET_PICKER/UNMOUNT",
};

export const KEYWORDAGGREGATION = {
  KEYWORDAGGREGATION_LOADED: "KEYWORDAGGREGATION/KEYWORDAGGREGATION_LOADED",
  ON_COLUMNS_CHANGED: "KEYWORDAGGREGATION/ON_COLUMNS_CHANGED",
  ON_SEARCHTEXT_CHANGED: "KEYWORDAGGREGATION/ON_SEARCHTEXT_CHANGED",
  ON_PAGE_CHANGED: "KEYWORDAGGREGATION/ON_PAGE_CHANGED",
  ON_PAGESIZE_CHANGED: "KEYWORDAGGREGATION/ON_PAGESIZE_CHANGED",
  ON_ROWSELECTED_CHANGED: "KEYWORDAGGREGATION/ON_ROWSELECTED_CHANGED",
  ON_SORT_CHANGED: "KEYWORDAGGREGATION/ON_SORT_CHANGED",
  ON_FILTER_CHANGED: "KEYWORDAGGREGATION/ON_FILTER_CHANGED",
  IS_LOADING: "KEYWORDAGGREGATION/IS_LOADING",
};

export const KEYWORDTYPES = {
  KEYWORDTYPES_LOADED: "KEYWORDTYPES/KEYWORDTYPES_LOADED",
  ON_PAGE_CHANGED: "KEYWORDTYPES/ON_PAGE_CHANGED",
  ON_PAGESIZE_CHANGED: "KEYWORDTYPES/ON_PAGESIZE_CHANGED",
  ON_SEARCHTEXT_CHANGED: "KEYWORDTYPES/ON_SEARCHTEXT_CHANGED",
  ON_ROWSELECTED_CHANGED: "KEYWORDTYPES/ON_ROWSELECTED_CHANGED",
  LOADING: "KEYWORDTYPES/LOADING",
};

export const KEYWORD_GROUPS = {
  KEYWORD_GROUPS_LOADED: "KEYWORD_GROUPS/KEYWORD_GROUPS_LOADED",
  KEYWORD_GROUPS_START_LOADING: "KEYWORD_GROUPS/KEYWORD_GROUPS_START_LOADING",

  KEYWORD_GROUP_LOADED: "KEYWORD_GROUPS/KEYWORD_GROUP_LOADED",
  KEYWORD_GROUP_START_LOADING: "KEYWORD_GROUPS/KEYWORD_GROUP_START_LOADING",
  ON_CHECKED_BOX_CHANGED: "KEYWORD_GROUPS/ON_CHECKED_BOX_CHANGED",
  KEYWORD_GROUP_ON_FILTER_CHANGED: "KEYWORD_GROUPS/KEYWORD_GROUP_ON_FILTER_CHANGED",
};

export const KEYWORDCLASSIFICATION = {
  KEYWORDS_CLASSIFICATION_LOADED: "KEYWORDCLASSIFICATION/KEYWORDCLASSIFICATION_LOADED",
  LOADING: "KEYWORDCLASSIFICATION/LOADING",
  CHANGE_SELECTED_KEYWORDS_CLASSIFICATION_LIST: "KEYWORDCLASSIFICATION/CHANGE_SELECTED_KEYWORDS_CLASSIFICATION_LIST",
  CHANGE_PAGE: "KEYWORDCLASSIFICATION/CHANGE_PAGE",
  CHANGE_PAGINATION_NUMBER: "KEYWORDCLASSIFICATION/CHANGE_PAGINATION_NUMBER",
  CHANGE_SEARCH_TEXT: "KEYWORDCLASSIFICATION/CHANGE_SEARCH_TEXT",
  CHANGE_SELECTED_TYPE: "KEYWORDCLASSIFICATION/CHANGE_SELECTED_TYPE",
  CHANGE_SELECTED_LEVEL: "KEYWORDCLASSIFICATION/CHANGE_SELECTED_LEVEL",
  CHANGE_SELECTED_TOPIC: "KEYWORDCLASSIFICATION/CHANGE_SELECTED_TOPIC",
  CHANGE_SELECTED_WORD_COUNT: "KEYWORDCLASSIFICATION/CHANGE_SELECTED_WORD_COUNT",
  CHANGE_TYPES: "KEYWORDCLASSIFICATION/CHANGE_TYPES",
  CHANGE_WORD_COUNTS: "KEYWORDCLASSIFICATION/CHANGE_WORD_COUNTS",
  CHANGE_LEVELS: "KEYWORDCLASSIFICATION/CHANGE_LEVELS",
  CHANGE_TOPICS: "KEYWORDCLASSIFICATION/CHANGE_TOPICS",
  CHANGE_TAGS: "KEYWORDCLASSIFICATION/CHNAGE_CHANGE_TAGS",
  CHANGE_TYPE_SEARCH_TEXT: "KEYWORDCLASSIFICATION/CHANGE_TYPE_SEARCH_TEXT",
  CHANGE_NGRAM_DIALOG_OPEN: "KEYWORDCLASSIFICATION/CHANGE_NGRAM_DIALOG_OPEN",
  CHANGE_ADD_KEYWORDS_DIALOG_OPEN: "KEYWORDCLASSIFICATION/CHANGE_ADD_KEYWORDS_DIALOG_OPEN",
  CHANGE_SORT_CONFIG: "KEYWORDCLASSIFICATION/CHANGE_SORT_CONFIG",
  CHANGE_FILTERS: "KEYWORDCLASSIFICATION/CHANGE_FILTERS",
};

export const HEADING_CLUSTER = {
  HEADING_CLUSTER_LOADED: "HEADING_CLUSTER/HEADING_CLUSTER_LOADED",
  HEADING_CLUSTER_START_LOADING: "HEADING_CLUSTER/HEADING_CLUSTER_START_LOADING",

  HEADING_CLUSTER_SEARCH_TEXT_CHANGED: "HEADING_CLUSTER/HEADING_CLUSTER_SEARCH_TEXT_CHANGED",
  HEADING_CLUSTER_TOPIC_COUNT_CHANGED: "HEADING_CLUSTER/HEADING_CLUSTER_TOPIC_COUNT_CHANGED",
  HEADING_CLUSTER_PAGE_CHANGED: "HEADING_CLUSTER/HEADING_CLUSTER_PAGE_CHANGED",
  HEADING_CLUSTER_LIMIT_CHANGED: "HEADING_CLUSTER/HEADING_CLUSTER_LIMIT_CHANGED",
  HEADING_CLUSTER_TOTAL_CHANGED: "HEADING_CLUSTER/HEADING_CLUSTER_TOTAL_CHANGED",
};

export const LANDING_PAGES = {
  LANDING_PAGES_LOADED: "LANDING_PAGES/LANDING_PAGES_LOADED",
  LANDING_PAGE_CREATED: "LANDING_PAGES/LANDING_PAGE_CREATED",
  LANDING_PAGE_CHANGED: "LANDING_PAGES/LANDING_PAGE_CHANGED",
  LANDING_PAGE_LOADED: "LANDING_PAGES/LANDING_PAGE_LOADED",
  ERRORS_CHANGED: "LANDING_PAGES/ERRORS_CHANGED",
};

export const STRATEGY_SETTINGS = {
  STRATEGY_SETTINGS_JOB_CHANGE: "STRATEGY_SETTINGS/STRATEGY_SETTINGS_JOB_CHANGE",
};

export const VIDEO_PROJECT = {
  PROJECTS_LOADED: "VIDEO_PROJECT/PROJECTS_LOADED",
  PROJECTS_LOADING: "VIDEO_PROJECT/PROJECTS_LOADING",
  PROJECTS_REFRESH: "VIDEO_PROJECT/PROJECTS_REFRESH",
  PROJECTS_LOAD_FAILED: "VIDEO_PROJECT/PROJECTS_LOAD_FAILED",
  UPDATE_FIELD: "VIDEO_PROJECT/UPDATE_FIELD",
};
