import React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import {
  IWidget,
  IWidgetCallToActionAndReviewProps,
  IWidgetFieldProperties,
} from "../../../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../../../components/TextFieldMaxLength";
import SwitchProperty from "./itemProperties/components/SwitchProperty";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    gap: 10,
  },
  input: {
    border: `1px solid ${palette.grey["100"]}`,
    width: "100%",
    overflow: "hidden",
    padding: "10px 15px",
    color: palette.text.primary,
    backgroundColor: palette.secondary.dark,
    fontWeight: typography.fontWeightLight as any,
    fontSize: 14,
    borderRadius: 3,
    height: 29,
  },
  textarea: {
    minHeight: 70,
  },
  length: {
    color: palette.text.secondary,
  },
}));

type PropTypes = {
  widget: IWidget;
  properties: IWidgetFieldProperties;
  withoutButton?: boolean;
  onChange: (properties: IWidgetCallToActionAndReviewProps) => void;
};

const CallToActionAndReviewWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const properties = (
    Object.keys(props.properties).length === 0
      ? { tagline: "", reviewText: "", buttonText: "", reviewUrl: "", buttonUrl: "" }
      : props.properties
  ) as IWidgetCallToActionAndReviewProps;

  const { widget, withoutButton = false, onChange } = props;

  const { tagline, reviewText, buttonText, reviewUrl, buttonUrl, openInNewTab, includeQueryParams } =
    properties as IWidgetCallToActionAndReviewProps;

  return (
    <div className={classes.root}>
      <TextFieldMaxLength
        title={I18n.t("widgets_builder.widget_editor.fields.labels.tagline")}
        maxLength={50}
        handleValueChange={(value: string) => onChange({ ...properties, tagline: value })}
        value={tagline}
        placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
        multiLine={true}
        titleAndLengthLocation="top"
        inputClassName={ClassNames(classes.input, classes.textarea)}
        lengthClassName={classes.length}
        alignTitle="end"
        justifyTitle="space-between"
      />

      <div>
        <TextFieldMaxLength
          title={I18n.t("widgets_builder.widget_editor.fields.labels.review_text")}
          handleValueChange={(value: string) => onChange({ ...properties, reviewText: value })}
          value={reviewText}
          placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
          inputClassName={classes.input}
          titleMinWidth={85}
          alignTitle="start"
          borderRadius={3}
          borderColor="0"
          marginBottom={2}
          outlined={false}
        />

        <TextFieldMaxLength
          title={I18n.t("widgets_builder.widget_editor.fields.labels.review_url")}
          handleValueChange={(value: string) => onChange({ ...properties, reviewUrl: value })}
          value={reviewUrl}
          placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
          inputClassName={classes.input}
          titleMinWidth={85}
          alignTitle="start"
          borderRadius={3}
          borderColor="0"
          marginBottom={2}
          outlined={false}
          disabled={!widget.isTemplate}
        />

        {!withoutButton && (
          <>
            <TextFieldMaxLength
              title={I18n.t("widgets_builder.widget_editor.fields.labels.button_text")}
              handleValueChange={(value: string) => onChange({ ...properties, buttonText: value })}
              value={buttonText}
              placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
              inputClassName={classes.input}
              titleMinWidth={85}
              alignTitle="start"
              borderRadius={3}
              borderColor="0"
              marginTop={8}
              marginBottom={2}
              outlined={false}
            />
            <TextFieldMaxLength
              title={I18n.t("widgets_builder.widget_editor.fields.labels.button_url")}
              handleValueChange={(value: string) => onChange({ ...properties, buttonUrl: value })}
              value={buttonUrl}
              placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
              inputClassName={classes.input}
              titleMinWidth={85}
              alignTitle="start"
              borderRadius={3}
              borderColor="0"
              marginBottom={2}
              outlined={false}
              disabled={!widget.isTemplate}
            />
          </>
        )}
      </div>

      <div>
        <SwitchProperty
          checked={openInNewTab}
          onChange={(checked) => onChange({ ...properties, openInNewTab: checked })}
          label={I18n.t(`widgets_builder.widget_editor.fields.labels.open_in_new_tab`)}
        />
        <SwitchProperty
          checked={includeQueryParams}
          onChange={(checked) => onChange({ ...properties, includeQueryParams: checked })}
          label={I18n.t(`widgets_builder.widget_editor.fields.labels.include_query_params`)}
        />
      </div>
    </div>
  );
};

export default CallToActionAndReviewWidgetItem;
