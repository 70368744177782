import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import theme from "../../../../../themes/theme";
import green_frame from "../../../../../img/green_frame.svg";
import PageSection from "./PageSection";
import MTextField from "../../../../../components/MTextField";

const useStyles = makeStyles(() => ({
  headersRow: {
    display: "flex",
    padding: 10,
  },
  row: {
    display: "flex",
    alignItems: "center",
    padding: 12,
    gap: 10,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  nameField: {
    margin: 0,
  },
  field: {
    width: "10%",
    margin: 0,
  },
  inputName: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  proposal: any;
  handleChange: (value) => void;
};

const ContentLevels = (props: PropTypes) => {
  const classes = useStyles(props);
  const { proposal, handleChange } = props;

  return (
    <PageSection
      title={I18n.t("account.setup.content_level.title")}
      subtitle={I18n.t("account.setup.content_level.subtitle")}
    >
      <Typography variant={"subtitle2"} className={classes.headersRow}>
        {I18n.t("account.setup.content_level.header")}
      </Typography>
      {proposal?.contentLevels.map((level, i) => (
        <div key={level._id} className={classes.row}>
          <img src={green_frame} alt={"green-frame"} />
          <MTextField
            borderRadius={3}
            borderColor={theme.palette.divider}
            height={27}
            outlined
            customClass={classes.nameField}
            inputClassName={classes.inputName}
            value={level.name}
            onChange={(value) => {
              const newLevels = [...proposal.contentLevels];
              newLevels[i].name = value;
              handleChange({ ...proposal, contentLevels: newLevels });
            }}
          />
        </div>
      ))}
    </PageSection>
  );
};

export default ContentLevels;
