import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { useState } from "react";
import { find } from "lodash";
import PageSection from "../../../components/PageSection";
import {
  eBlogTemplate,
  ePageType,
  IWebsite,
  IWebsiteDynamicSectionProps,
} from "../../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import WebsitesSectionsSwitch from "../../../components/WebsitesSectionsSwitch";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import MRouterPrompt from "../../../components/MRouterPrompt";
import SelectRow from "../../../components/SelectRow";
import LearnTemplate from "./templates/LearnTemplate";
import BlogTemplate from "./templates/blog/BlogTemplate";
import PageLocale from "../../../components/PageLocale";
import CustomUrlPrefixTextField from "../../../components/CustomUrlPrefixTextField";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

const mapStateToProps = (state) => ({
  dynamicSection: state.websitePages.dynamicSection,
  dynamicSectionLoaded: state.websitePages.dynamicSectionLoaded,
  websiteConfigurationsLoaded: state.websitePages.websiteConfigurationsLoaded,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (dynamicSection) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "dynamicSection",
      value: dynamicSection,
    }),
  onDynamicPageInit: (dynamicSection) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_DYNAMIC_SECTION_INIT,
      value: dynamicSection,
    }),
});

type PropTypes = {
  dynamicSection: IWebsiteDynamicSectionProps;
  dynamicSectionLoaded: boolean;
  websiteConfigurationsLoaded: boolean;
  onPropertyChanged?: (dynamicSection) => void;
  _id?: string;
  onDynamicPageInit?: (dynamicSection) => void;
  website: IWebsite;
};

const NewSectionPage = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    dynamicSection,
    dynamicSectionLoaded,
    _id,
    onPropertyChanged,
    onDynamicPageInit,
    website,
    websiteConfigurationsLoaded,
  } = props;
  const [error, setError] = React.useState(false);
  const [emptyFields, setEmptyFields] = React.useState([]);
  const [locale, setLocale] = useState(website?.configurations.locale.defaultLocale || null);

  const { defaultLocale } = website?.configurations.locale;
  React.useEffect(() => {
    if (emptyFields.length <= 0) {
      return;
    }
    textFieldsValidated();
  }, [dynamicSection]);

  React.useEffect(() => {
    onDynamicPageInit(_id);
  }, [_id]);

  const templates = ["Blog", "Targeted"];
  const sitemapOptions = ["Yes", "No"];
  const accessList = ["Full", "API only", "Web only", "None"];
  const blogTemplates = [eBlogTemplate.default, eBlogTemplate.mayple, eBlogTemplate.safebooks, eBlogTemplate.jit];

  const textFieldsValidated = () => {
    let valid = true;
    setEmptyFields([]);
    const emptyFields = [];
    if (!dynamicSection.name) {
      valid = false;
      emptyFields.push(I18n.t(`website.name`));
    }
    if (!dynamicSection.mainPage.slug) {
      valid = false;
      emptyFields.push(I18n.t(`website.slug_title`));
    }
    if (dynamicSection.template === "Blog") {
      if (!dynamicSection.postPage.metaTitle) {
        valid = false;
        emptyFields.push(I18n.t(`website.blog.post_page_meta_title`));
      }
      if (!dynamicSection.postPage.metaDescription) {
        valid = false;
        emptyFields.push(I18n.t(`website.blog.post_page_meta_description`));
      }
    }
    if (dynamicSection.template === "Targeted") {
      if (!dynamicSection.postPage.metaTitle) {
        valid = false;
        emptyFields.push(I18n.t(`website.learn.post_page_meta_title`));
      }
      if (!dynamicSection.postPage.metaDescription) {
        valid = false;
        emptyFields.push(I18n.t(`website.learn.post_page_meta_description`));
      }
    }
    setEmptyFields(emptyFields);
    return valid;
  };
  if (!dynamicSectionLoaded || !websiteConfigurationsLoaded || _id !== dynamicSection._id) {
    return null;
  }

  const categorySlug = `${
    locale === defaultLocale ? dynamicSection.mainPage.slug : dynamicSection.translations[locale]?.mainPage?.slug || ""
  }${
    dynamicSection.template === ePageType.Blog
      ? locale === defaultLocale
        ? dynamicSection.categoryPage.slug
        : dynamicSection.translations[locale]?.categoryPage?.slug || ""
      : ""
  }/category-slug`;

  return (
    <>
      <MRouterPrompt />
      {/* <PageAccess */}
      {/*  access={dynamicSection.access} */}
      {/*  handleAccessChange={(access) => onPropertyChanged({ ...dynamicSection, access })} */}
      {/* /> */}
      <PageLocale website={website} locale={locale} handleLocaleChange={setLocale} />
      <WebsitesSectionsSwitch
        inSection
        enabled={dynamicSection.enabled}
        title={dynamicSection.name || I18n.t(`website.page_types.newContentPage`).replace("+", "")}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && dynamicSection.enabled) {
              onPropertyChanged({ ...dynamicSection, enabled: false });
            }
            setError(true);
            return;
          }
          setError(false);
          onPropertyChanged({ ...dynamicSection, enabled });
        }}
      >
        <CustomUrlPrefixTextField
          locale={locale}
          customUrlPrefix={dynamicSection.urlPrefix}
          alternativeUrlPrefix={dynamicSection.translations?.[locale]?.urlPrefix}
          previewUrls={[
            {
              key: "website.new_content_page.main_page_preview_url",
              value:
                locale === defaultLocale
                  ? dynamicSection.mainPage.slug
                  : dynamicSection.translations[locale]?.mainPage?.slug || "",
              valid: dynamicSection.enabled,
            },
            {
              key: "website.new_content_page.category_page_preview_url",
              value: categorySlug,
            },
            {
              key: "website.new_content_page.post_page_preview_url",
              value: `${
                locale === defaultLocale
                  ? dynamicSection.mainPage.slug
                  : dynamicSection.translations[locale]?.mainPage?.slug || ""
              }${dynamicSection.template === ePageType.Targeted ? "/category-slug" : ""}/post-slug`,
            },
          ]}
          handleValueChange={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...dynamicSection, urlPrefix: value });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              urlPrefix: value,
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
      </WebsitesSectionsSwitch>
      {error && (
        <Typography variant={"body1"} className={classNames(classes.error)}>
          {emptyFields.length > 0 && I18n.t("website.error_message")}
        </Typography>
      )}
      <PageSection sectionTitle={I18n.t("website.new_content_page.settings")}>
        <div className={classes.inputRowWrapper}>
          <SelectRow
            bold
            flexDirection={"column"}
            // width={}
            rowTitle={I18n.t("website.new_content_page.template")}
            value={dynamicSection.template}
            selectOptions={templates}
            handleChange={(template) => {
              onPropertyChanged({ ...dynamicSection, template });
            }}
          />
          {dynamicSection.template === "Targeted" && (
            <SelectRow
              bold
              flexDirection={"column"}
              rowTitle={I18n.t("website.learn.custom_top_section")}
              value={dynamicSection.mainPage.customTopSection ? "Yes" : "No"}
              selectOptions={["Yes", "No"]}
              handleChange={(value) => {
                onPropertyChanged({
                  ...dynamicSection,
                  mainPage: { ...dynamicSection.mainPage, customTopSection: value === "Yes" },
                });
              }}
            />
          )}{" "}
          {dynamicSection.template === "Blog" && (
            <SelectRow
              bold
              flexDirection={"column"}
              rowTitle={I18n.t("website.blog.template")}
              value={dynamicSection.blogTemplate}
              selectOptions={blogTemplates}
              handleChange={(value) => {
                onPropertyChanged({
                  ...dynamicSection,
                  blogTemplate: value,
                });
              }}
            />
          )}
        </div>
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            error={emptyFields.includes(I18n.t(`website.name`))}
            titleAndLengthLocation={"top"}
            value={locale === defaultLocale ? dynamicSection.name : dynamicSection.translations[locale]?.name || ""}
            placeholder={I18n.t("website.new_content_page.name_ph")}
            title={I18n.t("website.name")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...dynamicSection, name: value });
                return;
              }
              dynamicSection.translations[locale] = {
                ...(dynamicSection.translations[locale] || {}),
                name: value,
              };
              onPropertyChanged({ ...dynamicSection });
            }}
          />
          <TextFieldMaxLength
            error={emptyFields.includes(I18n.t(`website.slug_title`))}
            titleAndLengthLocation={"top"}
            value={
              locale === defaultLocale
                ? dynamicSection.mainPage.slug
                : dynamicSection.translations[locale]?.mainPage?.slug || ""
            }
            placeholder={I18n.t("website.slug_title_ph")}
            title={I18n.t("website.slug_title")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...dynamicSection, mainPage: { ...dynamicSection.mainPage, slug: value } });
                return;
              }
              dynamicSection.translations[locale] = {
                ...(dynamicSection.translations[locale] || {}),
                mainPage: { ...(dynamicSection.mainPage || {}), slug: value },
              };
              onPropertyChanged({ ...dynamicSection });
            }}
          />
        </div>
        <div className={classes.inputRowWrapper}>
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.new_content_page.add_to_sitemap")}
            value={dynamicSection.sitemap ? "Yes" : "No"}
            selectOptions={sitemapOptions}
            handleChange={(sitemapOption) => {
              onPropertyChanged({ ...dynamicSection, sitemap: sitemapOption === "Yes" });
            }}
          />
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.new_content_page.access")}
            value={dynamicSection.access || "Web only"}
            selectOptions={accessList}
            handleChange={(access) => {
              onPropertyChanged({ ...dynamicSection, access });
            }}
          />
        </div>
      </PageSection>
      {dynamicSection.template === "Blog" && (
        <BlogTemplate
          website={website}
          emptyFields={emptyFields}
          dynamicSection={dynamicSection}
          locale={locale}
          defaultLocale={defaultLocale}
        />
      )}

      {dynamicSection.template === "Targeted" && (
        <LearnTemplate
          emptyFields={emptyFields}
          dynamicSection={dynamicSection}
          locale={locale}
          defaultLocale={defaultLocale}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSectionPage);
