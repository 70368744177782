import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { HOME } from "../../../reducers/constants/actionTypes";
import { useSnackbar } from "notistack";
import EntailNavbarLogo from "../../../icons/EntailNavbarLogo";
import sideImage from "../../../img/signup_side.png";
import { eEntailProducts, eMpEvent, IAdminUser, IWebsite } from "../../../reducers/constants/objectTypes";
import Typography from "@material-ui/core/Typography";
import MButton from "../../../components/MButton";
import { ArrowForward } from "@material-ui/icons";
import ClassNames from "classnames";
import FlowTextButton from "./components/FlowTextButton";
import { replace } from "connected-react-router";
import { getDomainFromUrl, web } from "../../../helpers/urlHelper";
import agent from "../../../agent";
import { MIXPANEL_PAGE_VIEW } from "../../../reducers/middlewares/mixpanelMiddleware";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    height: "100vh",
    overflowY: "auto",
    padding: "70px 0px",
    flex: 1,
  },
  contentInnerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: "auto",
    marginRight: "auto",
    height: "100vh",
    width: 661,
  },
  topWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  imageWrapper: {
    background: "linear-gradient(90deg, #574AC1 0%, #2C2093 100%)",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  entailNavbarLogo: {},
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 30,
    lineHeight: "29px",
    color: "#000",
    marginTop: 70,
    marginBottom: "-5px",
  },
  inputColumn: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 15,
  },
  inputTitle: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 35,
  },
  backIcon: {
    marginRight: 10,
  },
  nextIcon: {
    marginLeft: 10,
  },
  button: {
    padding: "10px 30px",
    borderRadius: 40,
    height: 43,
  },
  backButton: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  rolesWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 35,
  },
  roles: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: 15,
    columnGap: 7,
  },
}));

type PropTypes = {
  user: IAdminUser;
  website: IWebsite;
  onBoardingData: any;
  onAdminUserLoaded: (payload: any) => void;
  onWebsiteSelected: (website: IWebsite) => void;
  onBoardingDataUpdated: (payload: any) => void;
  redirectTo: (url: string) => void;
  continueToNextStep: () => void;
  pageLoaded: Function;
  mpEvent: (event: string, props: object) => void;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
  onBoardingData: state.home.onBoardingData,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onAdminUserLoaded: (result) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: result.user }),
  onBoardingDataUpdated: (data) => dispatch({ type: HOME.ONBOARDING_DATA_UPDATED, data }),
  redirectTo: (url) => dispatch(replace(url)),
  onWebsiteSelected: (selectedWebsite) => dispatch({ type: HOME.ON_WEBSITES_SELECTED, selectedWebsite }),
  continueToNextStep: () => dispatch(replace(web.selectPage())),
  pageLoaded: () =>
    dispatch({
      type: HOME.PAGE_LOADED,
      meta: {
        mixpanel: {
          event: MIXPANEL_PAGE_VIEW,
          props: { "page-name": "Onboarding optimization", product: eEntailProducts.cro },
        },
      },
    }),
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
});

const WebsiteInfo = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    user,
    onWebsiteSelected,
    onAdminUserLoaded,
    website,
    onBoardingDataUpdated,
    onBoardingData,
    redirectTo,
    continueToNextStep,
    pageLoaded,
    mpEvent,
  } = props;
  const [selectedTargetAudience, setSelectedTargetAudience] = React.useState<any>(onBoardingData.audience?.target);
  const [selectedType, setSelectedType] = React.useState(onBoardingData.audience?.type);
  const [selectedTargetGoal, setSelectedTargetGoal] = React.useState(onBoardingData.audience?.goal);

  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const targetAudience = [
    {
      _id: "consumer",
      name: I18n.t("signup.website_info.target_audience.list.consumer"),
      items: [
        {
          _id: "e_commerce",
          name: I18n.t("signup.website_info.target_audience.consumer.e_commerce"),
        },
        {
          _id: "software",
          name: I18n.t("signup.website_info.target_audience.consumer.software"),
        },
        {
          _id: "mobile_app",
          name: I18n.t("signup.website_info.target_audience.consumer.mobile_app"),
        },
        {
          _id: "affiliate",
          name: I18n.t("signup.website_info.target_audience.consumer.affiliate"),
        },
        {
          _id: "services",
          name: I18n.t("signup.website_info.target_audience.consumer.services"),
        },
        {
          _id: "entertainment",
          name: I18n.t("signup.website_info.target_audience.consumer.entertainment"),
        },
        {
          _id: "news",
          name: I18n.t("signup.website_info.target_audience.consumer.news"),
        },
        {
          _id: "other",
          name: I18n.t("signup.website_info.target_audience.consumer.other"),
        },
      ],
      goals: [
        {
          _id: "subscriptions",
          name: I18n.t("signup.website_info.target_audience.goal.subscriptions"),
        },
        {
          _id: "registrations",
          name: I18n.t("signup.website_info.target_audience.goal.registrations"),
        },
        {
          _id: "downloads",
          name: I18n.t("signup.website_info.target_audience.goal.downloads"),
        },
        {
          _id: "other",
          name: I18n.t("signup.website_info.target_audience.goal.other"),
        },
      ],
    },
    {
      _id: "business",
      name: I18n.t("signup.website_info.target_audience.list.business"),
      items: [
        {
          _id: "saas",
          name: I18n.t("signup.website_info.target_audience.business.saas"),
        },
        {
          _id: "service_provider",
          name: I18n.t("signup.website_info.target_audience.business.service_provider"),
        },
        {
          _id: "supplier",
          name: I18n.t("signup.website_info.target_audience.business.supplier"),
        },
        {
          _id: "affiliate",
          name: I18n.t("signup.website_info.target_audience.business.affiliate"),
        },
        {
          _id: "construction",
          name: I18n.t("signup.website_info.target_audience.business.construction"),
        },
        {
          _id: "logistics",
          name: I18n.t("signup.website_info.target_audience.business.logistics"),
        },
        {
          _id: "transportation",
          name: I18n.t("signup.website_info.target_audience.business.transportation"),
        },
        {
          _id: "other",
          name: I18n.t("signup.website_info.target_audience.business.other"),
        },
      ],
      goals: [
        {
          _id: "demo_registrations",
          name: I18n.t("signup.website_info.target_audience.goal.demo_registrations"),
        },
        {
          _id: "sign_ups",
          name: I18n.t("signup.website_info.target_audience.goal.sign_ups"),
        },
        {
          _id: "contact_requests",
          name: I18n.t("signup.website_info.target_audience.goal.contact_requests"),
        },
        {
          _id: "sales",
          name: I18n.t("signup.website_info.target_audience.goal.sales"),
        },
        {
          _id: "leads",
          name: I18n.t("signup.website_info.target_audience.goal.leads"),
        },
        {
          _id: "subscriptions",
          name: I18n.t("signup.website_info.target_audience.goal.subscriptions"),
        },
        {
          _id: "other",
          name: I18n.t("signup.website_info.target_audience.goal.other"),
        },
      ],
    },
    {
      _id: "consumer_business",
      name: I18n.t("signup.website_info.target_audience.list.consumer_business"),
      items: [
        {
          _id: "saas",
          name: I18n.t("signup.website_info.target_audience.consumer_business.saas"),
        },
        {
          _id: "e_commerce",
          name: I18n.t("signup.website_info.target_audience.consumer_business.e_commerce"),
        },
        {
          _id: "marketplace",
          name: I18n.t("signup.website_info.target_audience.consumer_business.marketplace"),
        },
        {
          _id: "service_provider",
          name: I18n.t("signup.website_info.target_audience.consumer_business.service_provider"),
        },
        {
          _id: "supplier",
          name: I18n.t("signup.website_info.target_audience.consumer_business.supplier"),
        },
        {
          _id: "affiliate",
          name: I18n.t("signup.website_info.target_audience.consumer_business.affiliate"),
        },
        {
          _id: "construction",
          name: I18n.t("signup.website_info.target_audience.consumer_business.construction"),
        },
        {
          _id: "logistics",
          name: I18n.t("signup.website_info.target_audience.consumer_business.logistics"),
        },
        {
          _id: "transportation",
          name: I18n.t("signup.website_info.target_audience.consumer_business.transportation"),
        },
        {
          _id: "other",
          name: I18n.t("signup.website_info.target_audience.consumer_business.other"),
        },
      ],
      goals: [
        {
          _id: "demo_registrations",
          name: I18n.t("signup.website_info.target_audience.goal.demo_registrations"),
        },
        {
          _id: "sign_ups",
          name: I18n.t("signup.website_info.target_audience.goal.sign_ups"),
        },
        {
          _id: "contact_requests",
          name: I18n.t("signup.website_info.target_audience.goal.contact_requests"),
        },
        {
          _id: "sales",
          name: I18n.t("signup.website_info.target_audience.goal.sales"),
        },
        {
          _id: "leads",
          name: I18n.t("signup.website_info.target_audience.goal.leads"),
        },
        {
          _id: "subscriptions",
          name: I18n.t("signup.website_info.target_audience.goal.subscriptions"),
        },
        {
          _id: "other",
          name: I18n.t("signup.website_info.target_audience.goal.other"),
        },
      ],
    },
  ];

  const handleContinueClick = () => {
    if (selectedType && selectedTargetAudience && selectedTargetGoal && website) {
      const eventProps: any = {
        text: I18n.t("signup.navigation.continue"),
        target_audience: selectedTargetAudience.name,
        website_type: selectedType.name,
        goal: selectedTargetGoal.name,
        website_url: website.url,
      };
      const websiteTargetAudience = website.configurations?.siteDetails?.targetAudience;

      // if the selected target audience is the same as the website's target audience, continue to the next step
      if (
        selectedType._id === websiteTargetAudience?.type?._id &&
        selectedTargetAudience._id === websiteTargetAudience?.target?._id &&
        selectedTargetGoal._id === websiteTargetAudience?.goal?._id
      ) {
        mpEvent(eMpEvent.onboardingOptimizeButtonClick, eventProps);
        continueToNextStep();
        return;
      }

      setLoading(true);

      const updateAudience = {
        ...onBoardingData.audience,
        target: selectedTargetAudience,
        type: selectedType,
        goal: selectedTargetGoal,
      };
      onBoardingDataUpdated({ audience: updateAudience });
      agent.Websites.croUpdateWebsite(
        {
          siteDetails: {
            ...website.configurations.siteDetails,
            targetAudience: {
              ...onBoardingData.audience,
              target: { _id: onBoardingData.audience.target._id, name: onBoardingData.audience.target.name },
            },
          },
        },
        website
      )
        .then((res) => {
          if (res.error) {
            enqueueSnackbar(res.message, { variant: "error" });
            eventProps.error_message = res.message;
            return;
          }
          onWebsiteSelected(res.website);
          onAdminUserLoaded(res);
          continueToNextStep();
        })
        .catch((e) => {
          eventProps.error_message = e.response.body.message;
          enqueueSnackbar(e.response.body.message, { variant: "error" });
        })
        .finally(() => {
          mpEvent(eMpEvent.onboardingOptimizeButtonClick, eventProps);
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (!website) {
      return;
    }
    const websiteTargetAudience = website.configurations?.siteDetails?.targetAudience;
    if (websiteTargetAudience && !selectedTargetAudience && !selectedType && !selectedTargetGoal) {
      setSelectedTargetAudience(targetAudience.find((audience) => audience._id === websiteTargetAudience.target._id));
      setSelectedType(websiteTargetAudience.type);
      setSelectedTargetGoal(websiteTargetAudience.goal);
    }
  }, [website]);

  React.useEffect(() => {
    pageLoaded();
  }, []);

  return (
    <section className={classes.pageWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.contentInnerWrapper}>
          <div className={classes.topWrapper}>
            <EntailNavbarLogo className={classes.entailNavbarLogo} />
            <Typography className={classes.title}>
              {I18n.t("signup.website_info.title", {
                website: getDomainFromUrl(onBoardingData.websiteUrl || website.url),
              })}
            </Typography>
            <div className={classes.rolesWrapper}>
              <div className={classes.inputColumn}>
                <Typography className={classes.inputTitle}>
                  {I18n.t("signup.website_info.target_audience.title")}
                </Typography>
                <div className={classes.roles}>
                  {targetAudience.map((role) => (
                    <FlowTextButton
                      onChange={(value) => {
                        const updateAudience = {
                          ...onBoardingData.audience,
                          target: value,
                        };
                        onBoardingDataUpdated({ audience: updateAudience });
                        setSelectedTargetAudience(value);
                      }}
                      value={role}
                      selected={role._id === selectedTargetAudience?._id}
                    />
                  ))}
                </div>
              </div>
            </div>
            {selectedTargetAudience && (
              <div className={classes.rolesWrapper}>
                <div className={classes.inputColumn}>
                  <Typography className={classes.inputTitle}>
                    {I18n.t("signup.website_info.target_audience.type")}
                  </Typography>
                  <div className={classes.roles}>
                    {selectedTargetAudience.items.map((type) => (
                      <FlowTextButton
                        onChange={(value) => {
                          const updateAudience = {
                            ...onBoardingData.audience,
                            type: value,
                          };
                          onBoardingDataUpdated({ audience: updateAudience });
                          setSelectedType(value);
                        }}
                        value={type}
                        selected={type._id === selectedType?._id}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {selectedTargetAudience && selectedType && (
              <div className={classes.rolesWrapper}>
                <div className={classes.inputColumn}>
                  <Typography className={classes.inputTitle}>
                    {I18n.t("signup.website_info.target_audience.goal_title")}
                  </Typography>
                  <div className={classes.roles}>
                    {selectedTargetAudience.goals.map((goal) => (
                      <FlowTextButton
                        onChange={(value) => {
                          const updateAudience = {
                            ...onBoardingData.audience,
                            goal: value,
                          };
                          onBoardingDataUpdated({ audience: updateAudience });
                          setSelectedTargetGoal(value);
                        }}
                        value={goal}
                        selected={goal._id === selectedTargetGoal?._id}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={classes.navigation}>
            <MButton
              customClassNames={ClassNames(classes.button, classes.backButton)}
              onClick={() => {
                mpEvent(eMpEvent.onboardingOptimizeButtonClick, {
                  text: I18n.t("signup.navigation.back"),
                  website_url: website?.url,
                });
                redirectTo(web.personalize());
              }}
            >
              {I18n.t("signup.navigation.back")}
            </MButton>
            <MButton loading={loading} customClassNames={classes.button} onClick={() => handleContinueClick()}>
              <span>{I18n.t("signup.navigation.continue")}</span>
              <ArrowForward className={classes.nextIcon} />
            </MButton>
          </div>
        </div>
      </div>
      <div className={classes.imageWrapper}>
        <img alt={"sign up"} src={sideImage} />
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteInfo);
