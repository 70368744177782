import _ from "lodash";
import filter from "lodash/filter";
import { WRITE_ANSWER } from "./constants/actionTypes";

const initialState = {
  answerLoaded: false,
  answer: null,

  tags: [],
  tagsSearchField: "",
  topics: [],
  topicsSearchField: "",
  keywords: [],
  keywordsSearchField: "",

  selectedTab: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WRITE_ANSWER.ANSWER_LOADED:
      return {
        ...state,
        answerLoaded: true,
        answer: action.payload.post,
      };
    case WRITE_ANSWER.ANSWER_CHANGED:
      return {
        ...state,
        answer: action.answer,
      };
    case WRITE_ANSWER.TAB_CHANGED:
      return {
        ...state,
        selectedTab: action.tab,
      };

    case WRITE_ANSWER.TOPICS_LOADED:
      return {
        ...state,
        topics: action.payload.topics,
      };
    case WRITE_ANSWER.ON_TOPIC_SELECTED:
      return {
        ...state,
        answer: {
          ...state.answer,
          topic: action.topic,
        },
      };
    case WRITE_ANSWER.ON_TOPICS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        topicsSearchField: action.input,
      };
    case WRITE_ANSWER.ON_NEW_TOPIC_CREATED:
      return {
        ...state,
        topics: _.uniqBy([...state.topics, action.payload.topic], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        topicsSearchField: "",
        answer: {
          ...state.answer,
          topic: action.payload.topic,
        },
      };

    case WRITE_ANSWER.TAGS_LOADED:
      return {
        ...state,
        tags: action.payload.tags,
      };
    case WRITE_ANSWER.ON_TAG_SELECTED:
      return {
        ...state,
        answer: {
          ...state.answer,
          tags: [...state.answer.tags, action.tag],
        },
      };
    case WRITE_ANSWER.ON_TAG_REMOVED:
      return {
        ...state,
        answer: {
          ...state.answer,
          tags: filter(state.answer.tags, (tag) => tag.name !== action.tag.name),
        },
      };
    case WRITE_ANSWER.ON_TAGS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        tagsSearchField: action.input,
      };
    case WRITE_ANSWER.ON_NEW_TAG_CREATED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        tags: _.uniqBy([...state.tags, action.payload.tag], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        tagsSearchField: "",
        answer: {
          ...state.answer,
          tags: _.uniqBy([...state.answer.tags, action.payload.tag], "_id"),
        },
      };
    case WRITE_ANSWER.ON_SOURCE_URL_ADDED:
      return {
        ...state,
        answer: {
          ...state.answer,
          sourceURLs: [...(state.answer.sourceURLs || []), action.url],
        },
      };
    case WRITE_ANSWER.ON_SOURCE_URL_REMOVED:
      return {
        ...state,
        answer: {
          ...state.answer,
          sourceURLs: filter(state.answer.sourceURLs, (url) => url !== action.url),
        },
      };

    case WRITE_ANSWER.ON_KEYWORDS_LOADED:
      return {
        ...state,
        keywords: action.payload.keywords,
      };
    case WRITE_ANSWER.ON_KEYWORD_SELECTED:
      return {
        ...state,
        answer: {
          ...state.answer,
          keywords: [...state.answer.keywords, action.keyword],
        },
      };
    case WRITE_ANSWER.ON_KEYWORD_REMOVED:
      return {
        ...state,
        answer: {
          ...state.answer,
          keywords: filter(state.answer.keywords, (keyword) => keyword.name !== action.keyword.name),
        },
      };
    case WRITE_ANSWER.ON_KEYWORDS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        keywordsSearchField: action.input,
      };
    case WRITE_ANSWER.ON_NEW_KEYWORD_CREATED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        keywords: _.uniqBy([...state.keywords, action.payload.keyword], "_id").sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        keywordsSearchField: "",
        answer: {
          ...state.answer,
          keywords: _.uniqBy([...state.answer.keywords, action.payload.keyword], "_id"),
        },
      };
    default:
      return state;
  }
};
