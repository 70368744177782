import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import CustomTooltip from "./utils/CustomTooltip";
import theme from "../../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  legendTitle: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
    marginBottom: 15,
  },
}));

type Props = {
  data: any[];
  dateFormat: (item: any) => string;
  numberFormat: (item: any) => string;
  fullDateFormat: (item: any) => string;
  categories: any[];
  width?: string;
  height?: number;
};

const DelivrablesChart = (props: Props) => {
  const { data, numberFormat, dateFormat, fullDateFormat, categories, width, height } = props;
  const classes = useStyles();

  const renderLegend = (args) => {
    const { payload } = args;
    return (
      <ul style={{ paddingLeft: 15, fontSize: 12 }}>
        <Typography className={classes.legendTitle}>{I18n.t("reports.dashboard.legend_category_title")}</Typography>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ display: "flex", alignItems: "center", gap: 5, marginBottom: 5, listStyle: "none" }}
          >
            <div style={{ width: 15, height: 3, borderRadius: 3, backgroundColor: entry.color }}></div>
            <span style={{ textTransform: "capitalize" }}>{entry.value.replace("_", " ")}</span>
          </li>
        ))}
      </ul>
    );
  };

  const dataColors = [
    "#FF5FA7",
    "#8B2FE8",
    "#00BF8F",
    "#37679F",
    "#FFAE03",
    "#CC6D6D",
    "#a000c8",
    "#0298C8",
    "#028A0F",
    "#DFBE7D",
    "#FC8EAC",
    "#00563B",
  ];

  return (
    <ResponsiveContainer width={width || "98%"} height={height || 400}>
      <BarChart data={data}>
        <CartesianGrid stroke="#E5E5E5" vertical={false} strokeDasharray={1} />
        <XAxis
          dataKey="date"
          tickFormatter={dateFormat}
          axisLine={false}
          tickLine={false}
          tickMargin={16}
          tick={{ fontSize: 12 }}
          interval={"preserveStartEnd"}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          tick={{ fontSize: 12 }}
          width={40}
          type="number"
          domain={[0, "dataMax"]}
          tickFormatter={numberFormat}
        />
        <Tooltip
          cursor={false}
          content={<CustomTooltip getTotal={true} />}
          labelFormatter={fullDateFormat}
          labelStyle={{ marginBottom: 5, fontWeight: theme.typography.fontWeightBold }}
        />
        <Legend layout={"vertical"} verticalAlign={"middle"} align={"right"} content={renderLegend} />
        {categories.map((category, i) => (
          <Bar
            key={`${category}_${i}`}
            onMouseEnter={(barData) => {
              console.log({ barData });
            }}
            dataKey={category}
            maxBarSize={80}
            stackId="a"
            fill={dataColors[i] || "black"}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DelivrablesChart;
