import filter from "lodash/filter";
import { CATEGORY } from "./constants/actionTypes";

const initialState = {
  categoriesLoaded: false,
  categories: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY.CATEGORIES_LOADED:
      return {
        ...state,
        categoriesLoaded: true,
        categories: action.payload.categories,
      };
    case CATEGORY.NEW_CATEGORY:
      return {
        ...state,
        categories: [action.category, ...(state.categories || [])],
      };
    case CATEGORY.CATEGORY_CHANGED:
      return {
        ...state,
        categories: state.categories.map((t) => {
          if (t._id === action.category._id) {
            return action.category;
          }
          return t;
        }),
      };
    case CATEGORY.CATEGORY_DELETED: {
      const categories = filter(state.categories, (t) => t._id !== action.category._id);
      return {
        ...state,
        categories,
      };
    }
    default:
      return state;
  }
};
