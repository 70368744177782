import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import CfCheckBox from "../../../../../components/CfCheckBox";

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    width: 120,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    borderRight: `solid 1px ${theme.palette.divider}`,
    paddingTop: 20,
    "&:hover": {
      backgroundColor: "#FFFBE8",
    },
  },
  date: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    marginBottom: 20,
    textTransform: "capitalize",
  },
  titles: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
    marginBottom: 20,
    visibility: "hidden",
  },
  range: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  rootCheckBox: {
    padding: 0,
  },
}));

type PropTypes = {
  queriesView: boolean;
  labels: any[];
  dataSet: "impressions" | "clicks" | "positions";
  hiddenAreas: any;
  handleHiddenAreasChange: Function;
};

const colors = ["#fafaff", "#F0F1FF", "#C9CCF5", "#A4AAEB", "#828AE0", "#636DD6"];

const PositionsTableHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { queriesView, labels, dataSet, hiddenAreas, handleHiddenAreasChange } = props;

  return (
    <div className={classes.headerWrapper}>
      <Typography variant={"subtitle2"} className={classes.date}>
        {dataSet}
      </Typography>
      <Grid container>
        <Grid item container className={classes.titles}>
          <Grid item xs={6}>
            {queriesView ? "Queries" : "Pages"}
          </Grid>
          <Grid item xs={6}>
            {"Change"}
          </Grid>
        </Grid>
        {labels
          .map((label, index) => ({ range: label, color: colors[index] }))
          .map((range) => {
            return (
              <Grid key={range.range} item container className={classes.range}>
                <Grid item xs={6}>
                  <CfCheckBox
                    rootClassName={classes.rootCheckBox}
                    checked={!hiddenAreas.has(`${dataSet}.${range.range}`)}
                    value={range.range}
                    iconColor={range.color}
                    handleChange={(checked) => {
                      const newHiddenAreas = new Set(hiddenAreas);
                      const key = `${dataSet}.${range.range}`;
                      if (newHiddenAreas.has(key)) {
                        newHiddenAreas.delete(key);
                      } else {
                        newHiddenAreas.add(key);
                      }
                      handleHiddenAreasChange(newHiddenAreas);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {range.range}
                </Grid>
              </Grid>
            );
          })}
        <Grid item container className={classes.range}>
          <Grid item xs={6}>
            <CfCheckBox rootClassName={classes.rootCheckBox} checked iconColor={"#131955"} value={"total"} />
          </Grid>
          <Grid item xs={6}>
            {"Total"}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PositionsTableHeader;
