import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { AbilityContext } from "../../../../casl/can";
import CreateNewArticle from "../../../../containers/Main/components/CreateNewArticle";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  divider: {
    height: 30,
    color: theme.palette.divider,
    marginLeft: 4,
    marginRight: 14,
  },
}));

type PropTypes = {
  divider?: boolean;
};

const MTableCMSCreateButton = (props: PropTypes) => {
  const classes = useStyles(props);
  const { divider = false } = props;
  const ability = React.useContext<any>(AbilityContext);

  if (!ability.can("editor", "role") && !ability.can("customer", "role") && !ability.can("hr", "role")) {
    return null;
  }
  return (
    <div className={classes.wrapper}>
      {divider && <Divider orientation={"vertical"} className={classes.divider} />}
      <CreateNewArticle />
    </div>
  );
};

export default MTableCMSCreateButton;
