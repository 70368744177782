import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import RequiredFieldProperty from "./itemProperties/components/RequiredFieldProperty";
import WidthProperty from "./itemProperties/components/WidthProperty";
import TextareaProperty from "./itemProperties/components/TextareaProperty";
import { eWidgetFieldWidth, IWidgetConsentFieldProps } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({
      display: "flex",
      flexDirection: "column",
      gap: 10,
    }),
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
  };
});

type PropTypes = {
  properties: IWidgetConsentFieldProps;
  onPropertyChange(properties: IWidgetConsentFieldProps): void;
};

const ConsentWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <TextareaProperty onChange={(text) => onPropertyChange({ ...properties, text })} value={properties.text} />
      </div>
      <RequiredFieldProperty
        required={properties.required}
        onChange={(required) => onPropertyChange({ ...properties, required })}
      />
      <WidthProperty
        value={properties.width}
        onChange={(width: eWidgetFieldWidth) => onPropertyChange({ ...properties, width })}
      />
    </div>
  );
};

export default ConsentWidgetItem;
