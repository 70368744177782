import { EDIT_CUSTOM_POST } from "./constants/actionTypes";

const initialState = {
  postLoaded: false,
  post: null,

  selectedTab: "resources",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CUSTOM_POST.CLEANUP:
      return initialState;
    case EDIT_CUSTOM_POST.POST_LOADED:
      return {
        ...state,
        postLoaded: true,
        post: action.post,
      };
    case EDIT_CUSTOM_POST.POST_CHANGED: {
      return {
        ...state,
        post: action.post,
      };
    }
    case EDIT_CUSTOM_POST.TAB_CHANGED:
      return {
        ...state,
        selectedTab: action.tab,
      };

    default:
      return state;
  }
};
