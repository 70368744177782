import * as React from "react";
import { I18n } from "react-redux-i18n";
import LinksSplitTestItem from "./LinksSplitTestItem";
import { ILinksSplitTest } from "../../../../../reducers/constants/objectTypes";
import MTable from "../../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../../components/Table/Rows/MTableRowEmptyRow";

type PropTypes = {
  searchText: string;
  loaded: boolean;
  tests: ILinksSplitTest[];
  openTestEditor: (test: ILinksSplitTest) => void;
  deleteTest: (test: ILinksSplitTest) => void;
  refreshList: () => void;
};

const LinksSplitTestsList = (props: PropTypes) => {
  const { searchText, openTestEditor, loaded, tests, deleteTest, refreshList } = props;

  let filteredTests = loaded ? tests : [];
  if (searchText !== "") {
    filteredTests = filteredTests.filter(
      (c) =>
        (c?.name || "").toLowerCase().includes(searchText) ||
        (c?.description || "").toLowerCase().includes(searchText) ||
        (c?._id || "").toLowerCase().includes(searchText)
    );
  }

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabel> {I18n.t("links_split_test.table.name")}</MTableHeaderLabel>
        <MTableHeaderLabel columnWidth={"20%"}> {I18n.t("links_split_test.table.description")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("links_split_test.table.countries")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("links_split_test.table.products")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("links_split_test.table.state")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("links_split_test.table.groups")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("links_split_test.table.clicks")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("links_split_test.table.last_click")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("links_split_test.table.published")}</MTableHeaderLabel>
        <MTableHeaderLabel columnWidth={"30px"}> {""}</MTableHeaderLabel>
      </MTableHeader>
      {!loaded && <MTableRowProgressBar />}
      {loaded && filteredTests.length === 0 && <MTableRowEmptyRow />}
      {loaded &&
        filteredTests.length > 0 &&
        filteredTests.map((test, index) => (
          <LinksSplitTestItem
            key={test._id}
            test={test}
            handleTestClick={() => openTestEditor(test)}
            handleDeleteClick={() => deleteTest(test)}
          />
        ))}
    </MTable>
  );
};

export default LinksSplitTestsList;
