import classNames from "classnames";
import React from "react";
import { I18n } from "react-redux-i18n";
import { ListSubheader } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import numeral from "numeral";
import _ from "lodash";
import ListMetricColumn from "./components/ListMetricColumn";

const useStyles = makeStyles((theme: Theme) => ({
  dialogListSubHeader: {
    display: "flex",
    flexDirection: "row",
    top: -10,
    alignItems: "center",
    padding: "0 10px",
    borderBottom: `2px solid #c4c4c4`,
    backgroundColor: theme.palette.background.default,
    position: "sticky",
  },
  dialogTabs: {
    display: "flex",
    marginBottom: -2,
    width: "60%",
    marginLeft: -10,
    flexDirection: "row",
  },
  tab: {
    padding: "2px 40px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  activeTab: {
    color: theme.palette.text.primary,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    transition: "border .3s",
  },
}));

type PropTypes = {
  ordering: {
    column: string;
    order: "asc" | "desc";
  }[];
  keywordCount: number;
  pageCount: number;
  pageTab: boolean;
  setPageTab: (page: boolean) => void;
  setOrdering: (
    order: {
      column: string;
      order: "asc" | "desc";
    }[]
  ) => void;
};

const KeywordGroupDialogTableHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { keywordCount, pageCount, pageTab, setPageTab, ordering, setOrdering } = props;

  const pageMetrics = React.useMemo(
    () => [
      {
        value: "Clicks",
        col_name: "clicks",
        tooltip: I18n.t("queries.groups.tooltips.clicks"),
      },
      {
        value: "Imp",
        col_name: "impressions",
        tooltip: I18n.t("queries.groups.tooltips.imp"),
      },
      {
        value: "CTR",
        col_name: "ctr",
        tooltip: I18n.t("queries.groups.tooltips.ctr"),
      },
      {
        value: "Pos",
        col_name: "position",
        tooltip: I18n.t("queries.groups.tooltips.pos"),
      },
    ],
    []
  );
  const keywordMetrics: { value: string; last?: boolean; col_name: string; tooltip: string }[] = React.useMemo(
    () => [
      {
        value: "Int",
        col_name: "int",
        tooltip: I18n.t("queries.groups.tooltips.int"),
      },
      {
        value: "Com",
        col_name: "com",
        tooltip: I18n.t("queries.groups.tooltips.com"),
      },
      {
        value: "Vol",
        last: true,
        col_name: "volume",
        tooltip: I18n.t("queries.groups.tooltips.vol"),
      },
      ...pageMetrics,
    ],
    []
  );

  const handleMetricClick = (column: string) => {
    const index = _.findIndex(ordering, (val) => val.column === column);

    const newOrdering = Array.from(ordering);

    if (index === -1) {
      newOrdering.push({ column, order: "asc" });

      setOrdering(newOrdering);
      return;
    }

    if (newOrdering[index].order === "asc") newOrdering[index].order = "desc";
    else newOrdering.splice(index, 1);

    setOrdering(newOrdering);
  };

  return (
    <ListSubheader className={classes.dialogListSubHeader}>
      <div className={classes.dialogTabs}>
        <div
          className={classNames(!pageTab && classes.activeTab, classes.tab)}
          onClick={() => {
            setPageTab(false);
          }}
        >
          {numeral(keywordCount || 0).format("0a")} Queries
        </div>
        <div
          className={classNames(pageTab && classes.activeTab, classes.tab)}
          onClick={() => {
            setPageTab(true);
          }}
        >
          {numeral(pageCount || 0).format("0a")} Pages
        </div>
      </div>

      {pageTab ? (
        <>
          {pageMetrics.map((metric, index) => (
            <ListMetricColumn index={index} key={index} metric={metric} pageTab />
          ))}
        </>
      ) : (
        <>
          {keywordMetrics.map((metric, index) => (
            <ListMetricColumn
              index={index}
              key={index}
              metric={metric}
              onClick={() => handleMetricClick(metric.col_name)}
              collection={ordering}
              pageTab={false}
            />
          ))}
        </>
      )}
    </ListSubheader>
  );
};

export default KeywordGroupDialogTableHeader;
