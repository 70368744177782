import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) => ({
  divider: { height: 23, color: theme.palette.divider, marginLeft: 0, marginRight: 5 },
}));

const MTableToolbarDivider = () => {
  const classes = useStyles();

  return <Divider className={classes.divider} orientation={"vertical"} />;
};

export default MTableToolbarDivider;
