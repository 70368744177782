"use strict";
import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({}));

type propTypes = {
  className?: string;
};

function ImagePlaceHolder(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={className}>
      <path d="M16 14.7786V2.33417C16 1.3564 15.2 0.556396 14.2222 0.556396H1.77778C0.8 0.556396 0 1.3564 0 2.33417V14.7786C0 15.7564 0.8 16.5564 1.77778 16.5564H14.2222C15.2 16.5564 16 15.7564 16 14.7786ZM5.24444 10.3164L7.11111 12.5653L9.86667 9.01862C10.0444 8.78751 10.4 8.78751 10.5778 9.02751L13.6978 13.1875C13.92 13.4808 13.7067 13.8986 13.3422 13.8986H2.68444C2.31111 13.8986 2.10667 13.472 2.33778 13.1786L4.55111 10.3342C4.72 10.1031 5.05778 10.0942 5.24444 10.3164Z" />
    </SvgIcon>
  );
}

export default ImagePlaceHolder;
