import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function BigTitleIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 24 28"}>
      <path d="M-0.000,2.800 C-0.000,4.349 1.237,5.600 2.769,5.600 L9.231,5.600 L9.231,25.200 C9.231,26.749 10.468,28.000 12.000,28.000 C13.532,28.000 14.769,26.749 14.769,25.200 L14.769,5.600 L21.231,5.600 C22.763,5.600 24.000,4.349 24.000,2.800 C24.000,1.251 22.763,0.000 21.231,0.000 L2.769,0.000 C1.237,0.000 -0.000,1.251 -0.000,2.800 Z" />
    </SvgIcon>
  );
}

export default BigTitleIcon;
