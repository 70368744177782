import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import { HTMLAttributeAnchorTarget } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    flexGrow: 1,
    flexBasis: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: 10,
  },
}));

type PropTypes = {
  text: string | number;
  href?: string;
  target?: HTMLAttributeAnchorTarget | undefined;
  fontSize?: number | string;
  fontWeight?: number | string;
  handleOnClick?: (e) => void;
};

const MTableCellLink = (props: PropTypes) => {
  const classes = useStyles(props);
  const { text = "-", href, target = "_blank", fontSize = 12, fontWeight = 600, handleOnClick } = props;

  return (
    <div className={classes.column}>
      <Tooltip enterDelay={2000} title={text}>
        <span style={{ fontWeight, fontSize }}>
          {href || handleOnClick ? (
            <Link
              target={target}
              rel="noopener"
              href={href}
              onClick={(e) => {
                e.stopPropagation();
                if (handleOnClick) handleOnClick(e);
              }}
            >
              {text}
            </Link>
          ) : (
            text
          )}
        </span>
      </Tooltip>
    </div>
  );
};

export default MTableCellLink;
