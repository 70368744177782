import * as React from "react";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { IPage } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";

type PropTypes = {
  post: IPage;
  onCancel: Function;
  onUpdate: Function;
  field: string;
  state: string;
};

const FieldDateDialog = (props: PropTypes) => {
  const { post, onCancel, onUpdate, field, state } = props;
  const updateDueDate = (date) => {
    agent.Pages.updateFields(post._id, state, { [field]: date })
      .then((res) => {
        onUpdate(res);
      })
      .catch((e) => {
        onCancel();
      });
  };
  return (
    <DatePicker
      value={moment(post[field]) || moment()}
      open
      okLabel={<span className={"MuiButton-label"}>{"update"}</span>}
      views={["month", "year"]}
      onChange={(date) => updateDueDate(date.toDate())}
      onClose={() => onCancel()}
      minDate={moment(post[field] || Date.now()).subtract(1, "year")}
      maxDate={moment().add(1, "year")}
      format={"MM/YYYY"}
      TextFieldComponent={() => null}
    />
  );
};

export default FieldDateDialog;
