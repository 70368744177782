import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { Divider } from "@material-ui/core";
import set from "lodash/set";
import TextInputMentions from "../../../../components/TextInputMentions";
import {
  ePageType,
  IFile,
  IWebsite,
  IWebsiteBlogProps,
  SNWebsiteAutoCompleteMetaTag,
} from "../../../../../../reducers/constants/objectTypes";
import PageSection from "../../../../components/PageSection";
import TextInputToolAndDesign from "../../../../components/TextInputToolAndDesign";
import { LENGTHS } from "../../../../../../reducers/constants/consts";
import TextFieldMaxLength from "../../../../../../components/TextFieldMaxLength";
import TextDesignToolWithPreview from "../../../../components/TextDesignToolWithPreview";
import Typography from "@material-ui/core/Typography";
import ImagePicker from "../../../../components/ImagePicker";
import MTextField from "../../../../../../components/MTextField";
import ClassNames from "classnames";
import MButton from "../../../../../../components/MButton";
import agent from "../../../../../../agent";
import { web } from "../../../../../../helpers/urlHelper";
import { useLocation } from "react-router";
import ClearIcon from "@material-ui/icons/Clear";
import ButtonTextAndUrlAndDesign from "../../../../components/ButtonTextAndUrlAndDesign";
import page from "../../../../../GroupSerpDialogUI/page1";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  rowTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 14,
  },
  inputColumn: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  articlesWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  articleWrapper: {
    display: "flex",
    gap: 15,
  },
  articleCounter: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    marginTop: 10,
  },
  inputsWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 8,
  },
  inputWrapper: {
    padding: 0,
    margin: 0,
  },
  input: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    padding: "10px 15px",
  },
  editButton: {
    borderRadius: 20,
    height: 36,
    padding: "10px 20px",
    textTransform: "none",
  },
  image: {
    backgroundColor: theme.palette.divider,
  },
  backgroundImage: {
    backgroundColor: (props: PropTypes) =>
      props.selectedWebsite.configurations?.theme?.palette?.secondary?.main || theme.palette.primary.main,
    objectFit: "cover !important" as any,
  },
  clearIcon: {
    cursor: "pointer",
    fontSize: 18,
    marginTop: 10,
    fill: theme.palette.text.primary,
  },
  hideClearIcon: {
    opacity: 0,
  },
}));

type PropTypes = {
  onPropertyChanged: (template) => void;
  onTranslationChanged: (template) => void;
  locale: string;
  defaultLocale: string;
  emptyFields: string[];
  selectedWebsite: IWebsite;
  jitTemplate: IWebsiteBlogProps["jitTemplate"];
  jitTemplateTranslation: any;
  pageType: string;
};

const JitPageBlog = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    jitTemplate,
    jitTemplateTranslation,
    onPropertyChanged,
    onTranslationChanged,
    locale,
    defaultLocale,
    selectedWebsite,
    pageType,
  } = props;
  const location = useLocation();
  const { theme } = selectedWebsite.configurations;
  const [loadArticle, setLoadArticle] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const articles =
    locale === defaultLocale ? jitTemplate.mainPage?.articles || [] : jitTemplateTranslation?.mainPage?.articles || [];

  const getArticle = () => {
    if (!selectedWebsite) return;
    if (!pageType) {
      enqueueSnackbar(I18n.t("website.blog.jit_template.page_type_error"), { variant: "error" });
      return;
    }
    setLoadArticle(true);
    agent.Pages.getVerticalPage(selectedWebsite, `${pageType}_main_page`, locale)
      .then((res) => {
        setLoadArticle(false);
        if (res.error) {
          enqueueSnackbar(res.message, { variant: "error" });
          return;
        }
        window.open(web.editPage(res.page._id, location.pathName), "_blank");
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(e.response?.body?.message || e.message);
        setLoadArticle(false);
      });
  };

  return (
    <PageSection sectionTitle={I18n.t("website.blog.main_page")}>
      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.element_design", { element: I18n.t("website.title") })}
        topTitle
        exampleText={I18n.t("website.blog.jit_template.example_title")}
        css={jitTemplate.mainPage?.title?.css}
        handleCssChanged={(newCss) => {
          onPropertyChanged({
            jitTemplate: {
              ...jitTemplate,
              mainPage: { ...jitTemplate.mainPage, title: { ...jitTemplate.mainPage?.title, css: newCss } },
            },
          });
        }}
        variant={"h1"}
      />
      <Divider className={classes.divider} />
      <TextDesignToolWithPreview
        elementTitle={I18n.t("website.element_design", { element: I18n.t("website.description") })}
        topTitle
        exampleText={I18n.t("website.blog.jit_template.example_description")}
        css={jitTemplate.mainPage?.description?.css}
        handleCssChanged={(newCss) => {
          onPropertyChanged({
            jitTemplate: {
              ...jitTemplate,
              mainPage: { ...jitTemplate.mainPage, description: { ...jitTemplate.mainPage?.description, css: newCss } },
            },
          });
        }}
        variant={"h2"}
      />

      <Divider className={classes.divider} />
      <ButtonTextAndUrlAndDesign
        textFieldTitle={I18n.t("website.blog.jit_template.button_text")}
        urlFieldTitle={I18n.t("website.blog.jit_template.button_url")}
        button={
          locale === defaultLocale
            ? jitTemplate.mainPage?.button || { text: "", url: "", css: {} }
            : {
                ...(jitTemplateTranslation?.mainPage?.button || { text: "", url: "" }),
                css: jitTemplate.mainPage?.button?.css || {},
              } || { text: "", url: "", css: {} }
        }
        defaultButtonCss={theme?.buttons?.css}
        handleButtonChanged={(value) => {
          if (locale === defaultLocale) {
            onPropertyChanged({
              jitTemplate: { ...jitTemplate, mainPage: { ...jitTemplate.mainPage, button: value } },
            });
            return;
          }
          set(jitTemplateTranslation, "mainPage.button", value);
          onTranslationChanged(jitTemplateTranslation);
        }}
      />
      <Divider className={classes.divider} />
      <div className={classes.inputRowWrapper}>
        <div className={classes.inputColumn}>
          <Typography variant={"subtitle2"} className={classes.rowTitle}>
            {I18n.t("website.blog.jit_template.main_image")}
          </Typography>
          <ImagePicker
            imageClassName={classes.image}
            selectedWebsite={selectedWebsite}
            image={jitTemplate.mainPage?.mainImage as IFile}
            objectFit={"contain"}
            imageHeight={"152px"}
            imageWidth={"152px"}
            onImageChanged={(value) => {
              onPropertyChanged({
                jitTemplate: { ...jitTemplate, mainPage: { ...jitTemplate.mainPage, mainImage: value } },
              });
            }}
          />
        </div>
        <div className={classes.inputColumn}>
          <Typography variant={"subtitle2"} className={classes.rowTitle}>
            {I18n.t("website.blog.jit_template.background_image")}
          </Typography>
          <ImagePicker
            imageClassName={ClassNames(
              classes.image,
              jitTemplate.mainPage?.backgroundImage?.url && classes.backgroundImage
            )}
            selectedWebsite={selectedWebsite}
            image={jitTemplate.mainPage?.backgroundImage as IFile}
            objectFit={"contain"}
            imageHeight={"152px"}
            imageWidth={"152px"}
            onImageChanged={(value) => {
              onPropertyChanged({
                jitTemplate: { ...jitTemplate, mainPage: { ...jitTemplate.mainPage, backgroundImage: value } },
              });
            }}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.inputColumn}>
        <Typography variant={"subtitle2"} className={classes.rowTitle}>
          {I18n.t("website.blog.jit_template.related_articles")}
        </Typography>
        <div className={classes.articlesWrapper}></div>
        {[...articles, ...(articles.length < 5 ? [{ url: "", title: "" }] : [])].map((article, index) => (
          <div className={classes.articleWrapper}>
            <Typography className={classes.articleCounter}>{index + 1}</Typography>
            <div className={classes.inputsWrapper}>
              <MTextField
                outlined
                height={37}
                borderRadius={4}
                customClass={classes.inputWrapper}
                inputClassName={ClassNames(classes.input)}
                onChange={(url: string) => {
                  if (articles.length < index + 1) {
                    articles.push({ url, title: "" });
                  } else {
                    articles[index].url = url;
                  }
                  if (locale === defaultLocale) {
                    onPropertyChanged({
                      jitTemplate: { ...jitTemplate, mainPage: { ...jitTemplate.mainPage, articles } },
                    });
                    return;
                  }
                  set(jitTemplateTranslation, "mainPage.articles", articles);
                  onTranslationChanged(jitTemplateTranslation);
                }}
                value={article.url}
                placeholder={I18n.t("website.blog.jit_template.article_url")}
              />
              <MTextField
                outlined
                height={37}
                borderRadius={4}
                customClass={classes.inputWrapper}
                inputClassName={ClassNames(classes.input)}
                onChange={(title: string) => {
                  if (articles.length < index + 1) {
                    articles.push({ title, url: "" });
                  } else {
                    articles[index].title = title;
                  }
                  if (locale === defaultLocale) {
                    onPropertyChanged({
                      jitTemplate: { ...jitTemplate, mainPage: { ...jitTemplate.mainPage, articles } },
                    });
                    return;
                  }
                  set(jitTemplateTranslation, "mainPage.articles", articles);
                  onTranslationChanged(jitTemplateTranslation);
                }}
                value={article.title}
                placeholder={I18n.t("website.blog.jit_template.article_title")}
              />
            </div>
            <ClearIcon
              onClick={() => {
                if (index !== article.length) {
                  articles.splice(index, 1);
                  if (locale === defaultLocale) {
                    onPropertyChanged({
                      jitTemplate: { ...jitTemplate, mainPage: { ...jitTemplate.mainPage, articles } },
                    });
                    return;
                  }
                  set(jitTemplateTranslation, "mainPage.articles", articles);
                  onTranslationChanged(jitTemplateTranslation);
                }
              }}
              className={ClassNames(classes.clearIcon, index === articles.length && classes.hideClearIcon)}
            />
          </div>
        ))}
      </div>
      <Divider className={classes.divider} />
      <MButton loading={loadArticle} customClassNames={classes.editButton} onClick={getArticle}>
        {I18n.t("website.blog.jit_template.edit_article")}
      </MButton>
    </PageSection>
  );
};

export default JitPageBlog;
