import * as React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  image: {
    height: 80,
    width: 60,
  },
}));

type PropTypes = {
  className?: string;
};

function LayoutModalDefaultIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 82 63"}>
      <svg width="82" height="63" viewBox="0 0 82 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="81" height="63" rx="8" fill="#AEAEAE" />
        <path
          d="M4.5 11H77.5V54C77.5 55.6569 76.1569 57 74.5 57H7.5C5.84315 57 4.5 55.6569 4.5 54V11Z"
          fill="#404142"
        />
        <circle cx="10.5" cy="6" r="2" fill="#AEAEAE" />
        <circle cx="16.5" cy="6" r="2" fill="#AEAEAE" />
        <circle cx="22.5" cy="6" r="2" fill="#AEAEAE" />
        <path
          d="M25 23C25 21.3431 26.3431 20 28 20H53C54.6569 20 56 21.3431 56 23V44C56 45.6569 54.6569 47 53 47H28C26.3431 47 25 45.6569 25 44V23Z"
          fill="#AEAEAE"
        />
      </svg>
    </SvgIcon>
  );
}

export default LayoutModalDefaultIcon;
