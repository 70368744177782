import * as React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { push } from "connected-react-router";
import { I18n } from "react-redux-i18n";
import { useEffect } from "react";
import moment from "moment/moment";
import { makeStyles, Theme } from "@material-ui/core/styles";
import EventsList from "./components/EventsList";
import { IAnalyticsEventRow, IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import { web } from "../../../helpers/urlHelper";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import { EVENTS } from "../../../reducers/constants/actionTypes";
import EventsToolbarSelectStep from "./components/EventsToolbarSelectStep";
import FunnelBaseSelect from "../FunnelCreator/components/FunnelBaseSelect";
import MTableToolbarSearchInput from "../../../components/Table/Toolbar/MTableToolbarSearchInput";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: "0 -10px",
    marginTop: "-8px",
    padding: "0 30px",
    gap: 20,
    paddingBottom: "12px",
  },

  headerTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    width: "100%",
    gap: 10,
  },
  headerBottom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
    rowGap: 10,
    columnGap: 20,
    flexWrap: "wrap",
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginLeft: "auto",
    alignItems: "center",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  events: state.events.events,
  config: state.events.filter.config,
});

const mapDispatchToProps = (dispatch) => ({
  openUserProfile: (distinctId: string) => dispatch(push(web.croUserEvents(distinctId))),
  loadEvents: (payload: IAnalyticsEventRow[]) => dispatch({ type: EVENTS.EVENTS_LOADED, payload }),
  loadFailed: () => dispatch({ type: EVENTS.EVENTS_LOADED_FAILED }),
  loadEventsNames: (payload: string[]) => dispatch({ type: EVENTS.EVENTS_NAMES_LOADED, payload }),
  updateConfig(payload) {
    dispatch({ type: EVENTS.EVENTS_FILTER_CHANGED, payload });
  },
});

type PropTypes = {
  selectedWebsite: IWebsite;

  openUserProfile: (distinctId: string) => void;
  events: IAnalyticsEventRow[];
  loadEvents: (payload: IAnalyticsEventRow[]) => void;
  loadFailed: () => void;
  loadEventsNames: (payload: string[]) => void;
  config: any;
  updateConfig: (payload) => void;
};

const Events = (props: PropTypes) => {
  const { selectedWebsite, openUserProfile, loadEvents, loadFailed, events, loadEventsNames, config, updateConfig } =
    props;

  const classes = useStyles(props);

  const [searchText, setSearchText] = React.useState("");
  const [loaded, setLoaded] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(100);
  const [totalPages, setTotalPages] = React.useState(0);
  const [dataFilters, setDataFilters] = React.useState({
    month: {
      selected: { value: 1, label: "Last month" },
      options: [
        { value: 1, label: "Last month" },
        { value: 3, label: "Last 3 months" },
        { value: 6, label: "Last 6 months" },
        { value: 12, label: "Last 12 months" },
      ],
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    setLoaded(false);
    loadEvents([]);
    setTotalPages(0);
    const startDate = moment().subtract(dataFilters.month.selected.value, "month").format("YYYY-MM-DD HH:MM");
    const endDate = moment().format("YYYY-MM-DD HH:MM");
    agent.Analytics.getEvents(selectedWebsite._id, { ...config, startDate, endDate }, page, limit)
      .then((res) => {
        setLoaded(true);
        loadEvents(res.events);
        setTotalPages(res.totalPages);
      })
      .catch((e) => {
        loadFailed();
        setTotalPages(0);
        setLoaded(true);
        enqueueSnackbar("Failed to load events", { variant: "error" });
        console.log("e", e);
      });
  }, [selectedWebsite, page, limit, config, dataFilters, searchText]);

  useEffect(() => {
    agent.Funnels.getColumnValues(selectedWebsite, "events", "name", null, 1, 500)
      .then((res) => {
        loadEventsNames(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const removeEvent = (index: number) => {
    const newConfig = { ...config };
    if (newConfig.events.length > 0) {
      newConfig.events.splice(index, 1);
      updateConfig({ config: newConfig });
    }
  };

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.cro.events.title`)} />
      <div className={classes.headerWrapper}>
        <div className={classes.headerTop}>
          <MTableToolbarSearchInput
            placeHolder={I18n.t("analytics.events.search")}
            searchText={searchText}
            handleSearchTextChanged={(text) => setSearchText(text)}
          />
          <FunnelBaseSelect
            actionsMarginLeft={"-5px"}
            dynamicTextWidth
            search={false}
            flexDirection={"row"}
            optionValue={"value"}
            optionLabel={"label"}
            border={"none"}
            value={dataFilters.month.selected}
            options={dataFilters.month.options}
            handleOnChange={(v) => {
              setDataFilters({
                ...dataFilters,
                month: {
                  ...dataFilters.month,
                  selected: v,
                },
              });
            }}
            placeholder={""}
          />
        </div>
        <div className={classes.headerBottom}>
          {[
            ...config.events,
            {
              condition: "",
              value: "",
              filters: [],
            },
          ].map((e, index) => (
            // <>
            //   {index !== 0 && (
            //     <div style={{ marginLeft: 10, marginRight: 5 }}>
            //       <MTableToolbarDivider />
            //     </div>
            //   )}
            <EventsToolbarSelectStep
              event={e}
              key={`event_step_${index + 1}`}
              index={index}
              removeEvent={removeEvent}
            />
            //   </>
          ))}
        </div>
      </div>
      <EventsList handleUserClick={openUserProfile} loaded={loaded} events={events} searchText={searchText} />
      {totalPages > 0 && (
        <MTablePagination
          paginationLimit={limit}
          page={page}
          paginationPagesCount={totalPages}
          setPaginationLimit={setLimit}
          setPage={setPage}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
