import { Transforms, Editor } from "slate";
import { editorType } from "../../types/editor.Types";
import richTextEditorHelper from "../../helper/richTextEditorHelper";

const withEmbeds = (editor: Editor): Editor => {
  const { isVoid } = editor;

  editor.isVoid = (element) => (element.type === editorType.embed ? true : isVoid(element));
  return editor;
};

const insertEmbed = (editor, url, width, height, subtype, videoId, structuredData) => {
  const text = { text: "" };
  const embed = { type: editorType.embed, url, width, height, structuredData, children: [text], subtype, videoId };
  richTextEditorHelper.insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading(editor, embed);
};

export default {
  withEmbeds,
  insertEmbed,
};
