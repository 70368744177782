import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import agent from "../../../agent";
import { COMMUNITY } from "../../../reducers/constants/actionTypes";
import { IAdminUser, ICategory, IQuestion, IWebsite } from "../../../reducers/constants/objectTypes";
import QuestionsPublishedItemList from "./components/QuestionsPublishedItemList";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import { api } from "../../../helpers/urlHelper";
import { AbilityContext } from "../../../casl/can";
import MTableToolbarCategoriesFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarCategoriesFilter";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  admin: state.home.admin,
  categories: state.category.categories,
  refreshQuestions: state.community.refresh,
  totalPages: state.community.publishedTotalPages,
  questions: state.community.publishedQuestions,
  questionsLoaded: state.community.publishedQuestionsLoaded,
  category: state.community.publishedFilter.category,
  searchText: state.community.publishedFilter.searchText,
  sortField: state.community.publishedFilter.sortField,
});

const mapDispatchToProps = (dispatch) => ({
  loadQuestions: (payload) => dispatch({ type: COMMUNITY.PUBLISHED_QUESTIONS_LOADED, payload }),
  onFilterChanged: (payload) => dispatch({ type: COMMUNITY.PUBLISHED_ON_FILTER_CHANGED, payload }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  admin: IAdminUser;
  loadQuestions: (payload) => void;
  refreshQuestions: boolean;
  categories: ICategory[];
  totalPages: number;
  questions: IQuestion[];
  category: ICategory[];
  searchText: string;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  questionsLoaded: boolean;
  onFilterChanged: (payload) => void;
};

const Published = (props: PropTypes) => {
  const {
    selectedWebsite,
    admin,
    loadQuestions,
    refreshQuestions,
    totalPages,
    categories,
    questionsLoaded,
    questions,
    searchText,
    category,
    sortField,
    onFilterChanged,
  } = props;
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);

  const ability = React.useContext<any>(AbilityContext);

  useEffect(() => {
    const selectedCategories = category.map((cat) => cat._id);
    loadQuestions(
      agent.Community.getAll(
        selectedWebsite._id,
        selectedCategories,
        searchText,
        "published",
        (page - 1).toString(),
        pageLimit,
        sortField.field,
        sortField.direction
      )
    );
  }, [selectedWebsite, admin, category, searchText, refreshQuestions, page, pageLimit, sortField]);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.community.published.title`)} />
      <MTableToolbar
        csvURL={ability.can("editor", "role") ? `${api.api()}/questions/${selectedWebsite._id}/published/csv` : null}
        searchText={searchText}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
      >
        <MTableToolbarCategoriesFilter
          handleCategoriesSelected={(value) => onFilterChanged({ category: value })}
          selectedCategories={category}
          categories={categories}
        />
      </MTableToolbar>

      <QuestionsPublishedItemList
        sortField={sortField}
        setSortField={(value) => onFilterChanged({ sortField: value })}
        questions={questions}
      />

      {questionsLoaded && questions.length > 0 && (
        <MTablePagination
          paginationLimit={pageLimit}
          page={page}
          paginationPagesCount={totalPages}
          setPaginationLimit={setPageLimit}
          setPage={setPage}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Published);
