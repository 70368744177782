import * as React from "react";
import { I18n } from "react-redux-i18n";
import ButtonGroupBaseComponent from "../BaseComponents/ButtonGroupBaseComponent";

type PropTypes = {
  value: "left" | "center" | "right";
  defaultValue?: "left" | "center" | "right";
  handleChange: (alignment: string) => void;
  moreOptions?: [string];
};

const HorizontalAlignmentCSS = (props: PropTypes) => {
  const horizontalAlignOptions = ["left", "center", "right"];
  const { value, defaultValue = "center", moreOptions = [], handleChange } = props;
  return (
    <ButtonGroupBaseComponent
      defaultValue={defaultValue}
      rowTitle={I18n.t("rich_text_editor.button.horizontal_alignment")}
      value={value}
      selectOptions={[...horizontalAlignOptions, ...moreOptions]}
      isValueChanged={value !== defaultValue}
      handleChange={(val) => handleChange(val)}
    />
  );
};

export default HorizontalAlignmentCSS;
