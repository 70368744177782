import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { ePageAction } from "../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  actionText: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    fontSize: 14,
    color: theme.palette.text.primary,
    "&:before": {
      content: '""',
      width: 7,
      height: 7,
      borderRadius: "50%",
      backgroundColor: "#71D0B8"
    }
  },
  green: {
    "&:before": {
      backgroundColor: "#71D0B8",
    }
  },
  pink: {
    "&:before": {
      backgroundColor: "#FB6FDC",
    }
  },
  blue: {
    "&:before": {
      backgroundColor: "#4353ff",
    }
  },
  purple: {
    "&:before": {
      backgroundColor: "purple",
    }
  },
}));

type PropTypes = {
  action: ePageAction;
};

const MPostActionTypography = (props: PropTypes) => {
  const classes = useStyles(props);
  const { action } = props;

  const getClass = () => {
    switch (action) {
      case ePageAction.create:
      case ePageAction.save:
        return classes.blue;

      case ePageAction.update:
      case ePageAction.publish:
        return classes.green;

      case ePageAction.submitForEdit:
      case ePageAction.submitForReview:
      case ePageAction.submitForFactCheck:
      case ePageAction.submitForSecondEdit:
        return classes.purple;

      case ePageAction.returnToWriter:
      case ePageAction.returnToEdit:
      case ePageAction.returnToFactCheck:
      case ePageAction.returnToReview:
      case ePageAction.returnToSecondEdit:
        return classes.pink;

      default:
        return null;
    }
  };
  return (
    <Typography className={ClassNames(classes.actionText, getClass())}>{action}</Typography>
  );
};

export default React.memo(MPostActionTypography);
