import { Button, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldProperty from "../itemProperties/components/TextFieldProperty";
import DraggableList from "../itemProperties/components/DraggableList";
import AddButton from "../../../../components/AddButton";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  list: {
    marginTop: 20,
    marginBottom: 20,
  },
  buttonAdd: () => {
    const backgroundColor = palette.secondary.main;
    return {
      backgroundColor,
      marginTop: -2,
    };
  },
  buttonWrapper: {
    width: "100%",
    marginTop: "2px",
  },
  addOrUpdate: {
    height: "100%",
    width: "96%",
    position: "absolute",
    background: "#202020",
    top: 0,
    left: 0,
    marginTop: 48,
    zIndex: 3,
    padding: "31px",
    marginLeft: "-1px",
  },
  addOrUpdateWrapper: {
    backgroundColor: palette.secondary.main,
    borderRadius: "5px",
    padding: "12px 16px 15px 15px",
    border: "1px solid rgb(82, 82, 82)",
  },
  headerItems: {
    fontSize: "16px",
    verticalAlign: "middle",
    display: "flex",
    padding: "0px 0px 10px 7px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowBack: {
    display: "flex",
    marginLeft: "-8px",
  },
  backIcon: {
    height: "22px",
    color: palette.common.white,
  },
  head: {
    userSelect: "none",
    color: palette.common.white,
    fontSize: "16px",
    lineHeight: "21px",
  },
  button: {
    background: palette.primary.light,
    justifySelf: "flex-end",
    height: "27px",
    width: "54px",
    fontSize: "14px",
    fontWeight: typography.fontWeightLight,
    textTransform: "none",
  },
  input: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: `1px solid ${palette.grey["100"]}`,
    width: "100%",
    borderRadius: 3,
    overflow: "hidden",
    backgroundColor: palette.primary.dark,
  },
}));

type PropTypes = {
  options: string[];
  handleOptionsChanges(options: string[]): void;
};

const MultipleOptionsFieldProp = (props: PropTypes) => {
  const { options, handleOptionsChanges } = props;
  const classes = useStyles(props);
  const [selectedOptionIndex, setSelectedOptionIndex] = React.useState(null);
  const [updatedValue, setUpdatedValue] = React.useState(null);

  const deleteOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    handleOptionsChanges(newOptions);
  };

  const handleFieldClick = (field, index) => {
    console.log("field", field);
    console.log("index", index);
    setSelectedOptionIndex(index);
  };

  const handleCopyClick = (field) => {
    handleOptionsChanges([...options, field]);
  };

  if (selectedOptionIndex !== null) {
    return (
      <div className={classes.addOrUpdate}>
        <div className={classes.addOrUpdateWrapper}>
          <div className={classes.headerItems}>
            <div className={classes.arrowBack}>
              <Typography variant={"h6"} className={classes.head}>
                {I18n.t("widgets_builder.design.options")}
              </Typography>
            </div>
            <Button
              className={classes.button}
              onClick={() => {
                const newOptions = [...options];
                newOptions[selectedOptionIndex] = updatedValue || options[selectedOptionIndex];
                handleOptionsChanges(newOptions);
                setSelectedOptionIndex(null);
                setUpdatedValue(null);
              }}
            >
              {I18n.t("widgets_builder.design.done")}
            </Button>
          </div>
          <div className={classes.input}>
            <TextFieldProperty
              placeholder={`${I18n.t("widgets_builder.design.done")}...`}
              label={I18n.t("widgets_builder.design.option_label")}
              onChange={setUpdatedValue}
              value={updatedValue ?? options[selectedOptionIndex] ?? ""}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.list}>
      <DraggableList
        items={options}
        onCopyClick={handleCopyClick}
        onDeleteClick={deleteOption}
        onClick={handleFieldClick}
      />
      <div className={classes.buttonWrapper}>
        <AddButton
          className={classes.buttonAdd}
          onClick={() => {
            const newOptions = [...options, ""];
            handleOptionsChanges(newOptions);
            setSelectedOptionIndex(newOptions.length - 1);
          }}
          text={I18n.t("widgets_builder.widget_editor.Form.fields.properties.add_option")}
        />
      </div>
    </div>
  );
};

export default MultipleOptionsFieldProp;
