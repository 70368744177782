import { find } from "lodash";
import slugify from "slugify";
import {
  eClusterPageType,
  eComparisonPostTemplate,
  ePageState,
  ePageType,
  ePostState,
  ePostType,
  IBlogPost,
  ICategory,
  IComparisonPost,
  ILearnPost,
  ILearnTopic,
  IPage,
  IProduct,
  IProductAlternative,
  IProductComparison,
  IProductPage,
  IProductReview,
  IQuestion,
  ITag,
  ITargetedPost,
  IWebsite,
  IWidget,
} from "../reducers/constants/objectTypes";
import { DEV } from "../reducers/constants/consts";

const apiHost = DEV ? `http://admin.localhost:3001` : "";
const api = {
  api: () => `${apiHost}/api`,
};

let webHost = "";

const getWebsiteName = () => {
  return webHost;
};

const setWebsiteName = (websiteName) => {
  webHost = `/${websiteName}`;
};

const getWebsiteUrl = (website, locale = "en") => {
  const localeUrl = find(website.localesUrls, (lUrl) => lUrl.locale === locale);
  if (localeUrl) {
    return localeUrl.url;
  }
  return website.url;
};

const getSearchConsoleUrl = (url: string, months = 6, breakdown: "page" | "query" = "query") => {
  let scDomain = "sc-domain:";
  scDomain += new URL(url).hostname;

  return `https://search.google.com/search-console/performance/search-analytics?resource_id=${scDomain}&breakdown=${breakdown}&page=!${url}&num_of_months=${months}&metrics=CLICKS,IMPRESSIONS,CTR,POSITION`;
};

const getPageSearchConsoleUrl = (domain: string, page: string) =>
  `https://search.google.com/search-console/performance/search-analytics?resource_id=${domain}&page=!${page}`;

const getQuerySearchConsoleUrl = (domain: string, query: string) =>
  `https://search.google.com/search-console/performance/search-analytics?resource_id=${domain}&query=!${query}`;

const web = {
  homePage: () => `${webHost}`,

  loginPage: () => `${webHost}/login`,
  customer: () => `${webHost}/view`,
  customerPosts: () => `${webHost}/view/published`,
  customerReview: () => `${webHost}/view/review`,
  community: (
    filter: "writer" | "published" | "new" | "draft" | "deleted" | ":filter" | "answers" | "moderator" | null = null
  ) => `${webHost}/community${filter ? `/${filter}` : ""}`,

  signUp: () => `/signup`,
  onBoarding: () => `/onboarding`,
  personalize: () => `/onboarding/personalize`,
  selectPage: () => `/onboarding/select-page`,
  websiteInfo: () => `/onboarding/website-info`,
  websitePagePreview: () => `/onboarding/page-preview`,

  communityQuestionEditor: (questionId = null, type) =>
    `${webHost}/community/question${questionId ? `/${questionId}` : ""}${type ? `/${type}` : ""}`,
  communityAnswerEditor: (writerCommunityAnswer = null) =>
    `${webHost}/community/answer${writerCommunityAnswer ? `/${writerCommunityAnswer}` : ""}`,
  workflow: () => `${webHost}/cms`,
  workflowMapping: () => `${webHost}/cms/strategy`,
  workflowBriefs: () => `${webHost}/cms/briefs`,
  workflowOutlines: (outlineId = null) => `${webHost}/cms/outlines${outlineId ? `/${outlineId}` : ""}`,
  workflowWrite: () => `${webHost}/cms/write`,
  workflowEdit: () => `${webHost}/cms/edit`,
  workflowSecondEdit: () => `${webHost}/cms/second-edit`,
  workflowReviewStep: (step: string) => `${webHost}/cms/review/${step}`,
  workflowFactCheck: () => `${webHost}/cms/fact-check`,
  workflowPublished: () => `${webHost}/cms/published`,
  workflowAllStates: () => `${webHost}/cms/all-states`,
  workflowSiteStats: () => `${webHost}/cms/site/stats`,
  workflowSettings: () => `${webHost}/cms/settings`,

  legacy: () => `${webHost}/legacy`,
  legacySourceFiles: () => `${webHost}/legacy/source-files`,
  legacyLoginAs: () => `${webHost}/legacy/loginAs`,
  legacyAccountSetup: () => `${webHost}/legacy/account-setup`,
  legacyAccountPricing: () => `${webHost}/legacy/account-pricing`,
  legacyWriterStats: () => `${webHost}/legacy/site-stats`,
  legacyInsights: (report: "Website" | "AllPages" | "Page" | "Event" | "Bots" | ":report" = "AllPages") =>
    `${webHost}/legacy/insights${report ? `/${report}` : ""}`,

  videoWorkflow: (state = null, currentUrl = null) =>
    `${webHost}/video${state ? `/${state}` : ""}${currentUrl ? `?returnTo=${currentUrl}` : ""}`,
  videoWorkflowAllStates: () => `${webHost}/video/all-states`,
  videoWorkflowEditVideo: (projectId = null, currentUrl = null) =>
    `${webHost}/edit/video${projectId ? `/${projectId}` : ""}${currentUrl ? `?returnTo=${currentUrl}` : ""}`,

  writer: () => `${webHost}/writer`,
  writerWrite: () => `${webHost}/writer/write`,
  writerPublished: () => `${webHost}/writer/published`,
  editor: () => `${webHost}/editor`,
  editorCommunity: (filter: "published" | "new" | "draft" | "deleted" | ":filter" = "published", questionId = null) =>
    `${webHost}/editor/community/${filter}${questionId ? `/${questionId}` : ""}`,
  editorInProgress: () => `${webHost}/editor/inProgress`,
  editorReview: () => `${webHost}/editor/review`,
  editorPublished: () => `${webHost}/editor/published`,

  // Article Editors
  editPage: (pageId, currentUrl = null) =>
    `${webHost}/edit/page/${pageId}${currentUrl ? `?returnTo=${currentUrl}` : ""}`,
  // Post Editors
  editorPostPrefix: () => `${webHost}/post`,
  editorTopicPrefix: () => `${webHost}/topic`,
  editPost: (
    locale = "en",
    type: ePostType | ":type",
    postId,
    action: "view" | "review" | "write" | "create" | "translate" | ":action",
    currentUrl = null
  ) => `${webHost}/post/${type}/${locale}/${postId}/${action}${currentUrl ? `?returnTo=${currentUrl}` : ""}`,
  editTopic: (
    type: ePostType | ":type",
    topicId,
    action: "view" | "review" | "write" | "create" | ":action",
    currentUrl = null
  ) => `${webHost}/topic/${type}/${topicId}/${action}${currentUrl ? `?returnTo=${currentUrl}` : ""}`,

  manager: () => `${webHost}/manager`,
  managerLearn: () => {
    return `${webHost}/manager/learn`;
  },

  managerLandingPage: (type, landingPageId) =>
    `${webHost}/manager/landing-page${type ? `/${type}` : ""}${landingPageId ? `/${landingPageId}` : ""}`,
  managerClusters: (clusterId = null) => `${webHost}/manager/clusters${clusterId ? `/${clusterId}` : ""}`,
  websiteConfigurations: (section?: string) => `${webHost}/manager/configurations${section ? `/${section}` : ""}`,

  pageBuilder: () => `${webHost}/page-builder`,
  theme: (section?: string) => `${webHost}/page-builder/theme${section ? `/${section}` : ""}`,
  contentPages: (section?: string) => `${webHost}/page-builder/content-pages${section ? `/${section}` : ""}`,
  editorialPages: (section?: string) => `${webHost}/page-builder/editorial-pages${section ? `/${section}` : ""}`,
  landingPages: (section?: string) => `${webHost}/page-builder/landing-pages${section ? `/${section}` : ""}`,
  components: (section?: string) => `${webHost}/page-builder/components${section ? `/${section}` : ""}`,
  developerTools: (section?: string) => `${webHost}/page-builder/developer-tools${section ? `/${section}` : ""}`,
  headerAndFooter: (section?: string) => `${webHost}/page-builder/header-and-footer${section ? `/${section}` : ""}`,

  // settings routes
  settingsTopics: () => `${webHost}/cms/settings/topics`,
  settingsBios: (writerId = null) => `${webHost}/cms/settings/editorial/writers-bio${writerId ? `/${writerId}` : ""}`,
  settingsCategories: () => `${webHost}/cms/settings/categories`,
  settingsRolesAndPermissions: () => `${webHost}/cms/settings/roles-and-permissions`,

  analysis: () => `${webHost}/analysis`,
  analysisQueryGroups: () => `${webHost}/analysis/queryGroups`,
  analysisContent: () => `${webHost}/analysis/content`,
  analysisSinglePage: () => `${webHost}/analysis/single-page`,
  analysisMetrics: () => `${webHost}/analysis/metrics`,
  strategy: () => `${webHost}/strategy`,
  seo: () => `${webHost}/page-builder/seo`,
  seoIndexingApi: () => `${webHost}/page-builder/seo/indexing-api`,
  seoRedirects: () => `${webHost}/page-builder/seo/redirects`,
  fetchKeywords: () => `${webHost}/strategy/fetch-keywords`,
  keywordsClassification: () => `${webHost}/strategy/keywords-classification`,
  excludeKeywords: () => `${webHost}/strategy/exclude-keywords`,
  keywordFrequency: () => `${webHost}/strategy/keyword-frequency`,
  dataGroups: () => `${webHost}/strategy/groups`,
  keywordHeadingCluster: () => `${webHost}/strategy/heading-cluster`,

  dataTitles: () => `${webHost}/strategy/data-titles`,
  dataPages: () => `${webHost}/strategy/pages`,
  dataSettings: (section?: string) => `${webHost}/strategy/settings${section ? `/${section}` : ""}`,
  outlines: (outlineId = null) => `${webHost}/strategy/outlines${outlineId ? `/${outlineId}` : ""}`,
  keywordGroupPage: () => `${webHost}/strategy/pages`,
  outlinePages: (outlineId = null) => `${webHost}/strategy/mapping${outlineId ? `/${outlineId}` : ""}`,
  internalLinking: () => `${webHost}/strategy/internal-linking`,
  contentGaps: () => `${webHost}/strategy/content-gaps`,
  strategyInfo: (section?: string) => `${webHost}/strategy/info${section ? `/${section}` : ""}`,
  serp: () => `${webHost}/strategy/serp`,
  old: () => `${webHost}/old`,
  admin: () => `${webHost}/admin`,
  adminSiteStat: () => `${webHost}/admin/sitesStats`,
  adminPipelineStats: () => `${webHost}/admin/pipeline`,
  adminWritersStat: () => `${webHost}/admin/writersStats`,
  adminWordCounter: () => `${webHost}/admin/wordCounter`,
  adminOrders: () => `${webHost}/admin/orders`,

  campaigns: () => `${webHost}/campaigns`,
  widgets: () => `${webHost}/campaigns/widgets`,
  widgetsEditor: (widgetId: string) => `${webHost}/campaigns/widgets/builder/${widgetId}`,
  croLinksSplitTest: (testId?: string) => `${webHost}/campaigns/experiments${testId ? `/${testId}` : ""}`,
  croProducts: (productId = null) => `${webHost}/campaigns/products${productId ? `/${productId}` : ""}`,
  croCampaigns: () => `${webHost}/campaigns/campaigns`,
  croCampaignEditor: (ruleId = null) => `${webHost}/campaigns/campaigns/edit${ruleId ? `/${ruleId}` : ""}`,
  adsList: (adId = null) => `${webHost}/legacy/ads${adId ? `/${adId}` : ""}`,

  analytics: () => `${webHost}/cro`,
  croDashboard: (report?: string) => `${webHost}/cro/dashboard/${report ? `#${report}` : ""}`,
  croUserEvents: (distinctId: string) => `${webHost}/cro/events/user/${distinctId}`,
  croEvents: () => `${webHost}/cro/events`,
  croFunnels: () => `${webHost}/cro/funnels`,
  croGoalEvents: () => `${webHost}/cro/goal-events`,
  croGoalCompletion: (report?: string) => `${webHost}/cro/goal-completion${report ? `/${report}` : ""}`,
  croSettings: () => `${webHost}/cro/settings`,
  croOrders: () => `${webHost}/cro/orders`,
  croReportsAll: () => `${webHost}/cro/reports/`,
  croReports: (report?: string, pageTraffic?: string, currentUrl = null) =>
    `${webHost}/cro/reports/${report || ""}${pageTraffic ? `?traffic=${pageTraffic}` : ""}${
      currentUrl ? `${pageTraffic ? "&" : "?"}returnTo=${currentUrl}` : ""
    }`,

  hr: () => `${webHost}/hr`,
  hrRolesAndPermissions: () => `${webHost}/hr/rolesAndPermissions`,
  owner: () => `${webHost}/owner`,
  entail: () => `${webHost}/marketing`,
  entailAudiences: () => `${webHost}/entail/audiences`,
  entailExperts: (expertId = null) => `${webHost}/legacy/experts${expertId ? `/${expertId}` : ""}`,
  article: () => `${webHost}/website/article`,

  analyze: () => `${webHost}/analyze`,
  write: () => `${webHost}/write`,
  learningCenter: (topic = null, subtopic = null, post = null) =>
    `${webHost}/write/learningCenter${topic ? `/${topic.name}` : ""}${subtopic ? `/${subtopic.name}` : ""}${
      post ? `/${post._id}` : ""
    }`,
  review: () => `${webHost}/review`,
  account: () => `${webHost}/account`,
};

const clients = {
  getByPostType: (postType: ePostType, website: IWebsite, post: any, locale = "en") => {
    if (post.state && post.state !== ePostState.PUBLISHED) {
      return null;
    }
    switch (postType) {
      case ePostType.LEARNING_CENTER:
        return clients.learningCenterPost(website, post);
      case ePostType.BLOG:
        return clients.blogPage(website, post, locale);
      case ePostType.COMMUNITY:
        return clients.questionsPage(website, post);
      case ePostType.TARGETED:
        return clients.targetedPost(website, post, locale);
      case ePostType.TOPIC:
        return clients.topicPage(website, post);
      case ePostType.COMPARISON:
        return clients.comparisonPage(website, post);
      case ePostType.PRODUCT_PAGE:
        return clients.productPage(website, post);
      case ePostType.PRODUCT_REVIEW:
        return clients.productReview(website, post);
      case ePostType.PRODUCT_ALTERNATIVE:
        return clients.productAlternative(website, post);
      case ePostType.PRODUCT_COMPARISON:
        return clients.productComparison(website, post);
      default:
        return null;
    }
  },
  getByClusterPageType: (pageType: eClusterPageType, website: IWebsite, post: any) => {
    switch (pageType) {
      case eClusterPageType.LEARN_TOPIC:
      case eClusterPageType.LEARN_SUB_TOPIC:
        return clients.learningCenterTopic(website, post);
      case eClusterPageType.LEARN_POST:
        return clients.learningCenterPost(website, post);
      case eClusterPageType.BLOG_POST:
        return clients.blogPage(website, post);
      case eClusterPageType.COMMUNITY_POST:
        return clients.questionsPage(website, post);
      case eClusterPageType.TARGETED_POST:
        return clients.targetedPost(website, post);
      case eClusterPageType.LANDING_PAGE:
        return clients.landingPage(website, post);
      case eClusterPageType.TOPIC_PAGE:
        return clients.topicPage(website, post);
      case eClusterPageType.NONE:
      default:
        return null;
    }
  },
  questionsPage: (website: IWebsite, question: IQuestion) => {
    if (question.product) {
      return `${clientPages.getWebsiteUrl(website)}${question.questionUrl}${clientPages.getTrailingSlash(website)}`;
    }
    return `${clientPages.getWebsiteUrl(website, null, website.configurations.forum.urlPrefix)}${
      website.configurations.forum.mainPage.slug
    }${question.questionUrl}${clientPages.getTrailingSlash(website)}`;
  },
  blogPage: (website: IWebsite, blogPost: IBlogPost, locale = "en") => {
    const baseUrl = getWebsiteUrl(website, locale);
    if (blogPost.product) {
      return `${baseUrl}${blogPost.uploadedPostUrl}`;
    }
    return `${baseUrl}${website.configurations.siteDetails.urlPrefix}${website.configurations.blog.mainPage.slug}${
      blogPost.uploadedPostUrl
    }${website.configurations.siteDetails.trailingSlash ? "/" : ""}`;
  },
  targetedPost: (website: IWebsite, post: ITargetedPost, locale = "en") => {
    const baseUrl = getWebsiteUrl(website, locale);
    return `${baseUrl}${website.configurations.siteDetails.urlPrefix}${website.configurations.learn.mainPage.slug}${post.uploadedPostUrl}`;
  },
  learningCenterPost: (website: IWebsite, post: ILearnPost) =>
    `${website.url}${website.learningCenterUrlPrefix}${post.uploadedPostUrl}`,
  learningCenterTopic: (website: IWebsite, topic: ILearnTopic) =>
    `${website.url}${website.learningCenterUrlPrefix}${topic.uploadedUrl}`,
  landingPage: (website: IWebsite, post: IWebsitePage) => `${website.url}${website.pagesUrlPrefix}${post.slug}`,
  topicPage: (website: IWebsite, tag: ITag, locale = "en") => {
    const baseUrl = getWebsiteUrl(website, locale);
    return `${baseUrl}${website.configurations.siteDetails.urlPrefix}${website.configurations.topic.slug}${tag.uploadedUrl}`;
  },
  comparisonPage: (website: IWebsite, post: IComparisonPost) => {
    switch (post.template) {
      case eComparisonPostTemplate.TOP_10:
      case eComparisonPostTemplate.TOP_SEO:
        return `${website.url}${website.configurations.siteDetails.urlPrefix}${website.configurations.comparisonTop10.mainPage.slug}${post.uploadedPostUrl}`;
      case eComparisonPostTemplate.TOP_ALTERNATIVES:
        return `${website.url}${website.configurations.siteDetails.urlPrefix}${website.configurations.comparisonTopAlternative.mainPage.slug}${post.uploadedPostUrl}`;
      default:
        return "";
    }
  },
  categoryPage: (website: IWebsite, category: ICategory) =>
    `${website.url}${website.configurations.siteDetails.urlPrefix}${website.configurations.category.slug}${category.slug}`,
  productPage: (website: IWebsite, post: IProductPage) =>
    `${website.url}${website.configurations.siteDetails.urlPrefix}${website.configurations.productPage.mainPage.slug}${post.uploadedPostUrl}`,
  productReview: (website: IWebsite, post: IProductReview) => {
    if (website.configurations.productReview.mainPage.slug) {
      return `${website.url}${website.configurations.siteDetails.urlPrefix}${website.configurations.productReview.mainPage.slug}${post.uploadedPostUrl}`;
    }
    return `${website.url}/${post.product.type}/${post.product.itemNumber}/${post.product.slug}/reviews/professional-review`;
  },
  productAlternative: (website: IWebsite, post: IProductAlternative) =>
    `${website.url}/${post.product.type}/${post.product.itemNumber}/${post.product.slug}/alternatives/${post.competitorProduct.slug}/`,
  productComparison: (website: IWebsite, post: IProductComparison) => `${website.url}${post.uploadedPostUrl}`,
  dynamicPost: (website: IWebsite, post: IProduct) => {
    const dynamicPost = find(website.configurations.dynamicSections, { type: post.type });
    if (dynamicPost) {
      return `${website.url}/${dynamicPost.name}/${dynamicPost.postPage.slug}`;
    }
    return null;
  },
  withCacheInvalidation: (website: IWebsite, url: string) => {
    if (website?.configurations.siteDetails.platform === "WordPress") {
      return `${url}?_nocache=1`;
    }
    return url;
  },
};

const clientPages = {
  getTrailingSlash: (website) => (website.configurations.siteDetails.trailingSlash ? "/" : ""),
  getWebsiteUrl: (website, locale = "en", customUrlPrefix = undefined, alternativeUrlPrefix = undefined) => {
    let { urlPrefix } = website.configurations.siteDetails;
    if (customUrlPrefix != null) {
      urlPrefix = customUrlPrefix;
    }
    const alternateLocale = find(website.configurations.locale.alternateLocales, (al) => al.locale === locale);
    if (alternateLocale) {
      urlPrefix = alternateLocale.urlPrefix;
      if (alternativeUrlPrefix != null) {
        urlPrefix = alternativeUrlPrefix;
      }
    }

    return `${DEV ? "http://localhost:3000" : website.url}${urlPrefix}`;
  },
  // TODO fix change switch method to use ePageType
  getPageUrl: (page: IPage, website: IWebsite, ignoreState = false) => {
    if (!ignoreState) {
      if (page.currentState && page.currentState.state !== ePageState.published) {
        return null;
      }
    }

    if (page.url) {
      return page.url;
    }
    if (page.type.includes("_main_page")) {
      return clientPages.getMainPageUrl(website, page);
    }

    switch (page.type) {
      // case ePostType.COMMUNITY:
      //   return clientPages.questionsPage(website, page as IQuestion);
      case ePageType.Blog:
        return clientPages.blogPage(website, page as IPage);
      case ePageType.Targeted:
        return clientPages.targetedPage(website, page as IPage);
      // case ePageType.Topic:
      //   return clientPages.topicPage(website, page as ITag);
      case ePageType.ComparisonTop10:
        return clientPages.comparisonTopPage(website, page as IPage);
      case ePageType.ComparisonTopAlternatives:
        return clientPages.comparisonTopAlternativePage(website, page as IPage);
      case ePageType.ProductPage:
        return clientPages.productPage(website, page as IPage);
      case ePageType.ProductReview:
        return clientPages.productReview(website, page as IPage);
      case ePageType.ProductAlternative:
        return clientPages.productAlternative(website, page as IPage);
      case ePageType.ProductComparison:
        return clientPages.productComparison(website, page as IPage);
      case ePageType.PpcTop10:
        return clientPages.ppcTop10(website, page as IPage);
      default:
        return clientPages.dynamicPage(website, page as IPage);
    }
  },
  getMainPageUrl: (website: IWebsite, page: IPage) => {
    switch (page.type.replace("_main_page", "")) {
      case ePageType.Blog:
        return clientPages.blogMainPage(website, page as IPage);
      default:
        return clientPages.dynamicMainPage(website, page as IPage);
    }
  },
  questionsPage: (website: IWebsite, question: IQuestion) => {
    if (question.product) {
      return `${website.url}${question.questionUrl}${clientPages.getTrailingSlash(website)}`;
    }
    return `${website.url}${website.configurations.siteDetails.urlPrefix}${website.configurations.forum.mainPage.slug}${
      question.questionUrl
    }${clientPages.getTrailingSlash(website)}`;
  },
  topicPage: (website: IWebsite, tag: ITag, locale = "en") => {
    const baseUrl = clientPages.getWebsiteUrl(website, locale);
    return `${baseUrl}${website.configurations.topic.slug}${tag.uploadedUrl}${clientPages.getTrailingSlash(website)}`;
  },
  categoryPage: (website: IWebsite, category: ICategory, locale = "en") => {
    const baseUrl = clientPages.getWebsiteUrl(website, locale, website.configurations.category.urlPrefix);
    return `${baseUrl}${website.configurations.category.slug}${category.slug}${clientPages.getTrailingSlash(website)}`;
  },
  blogPage: (website: IWebsite, page: IPage) => {
    const baseUrl = clientPages.getWebsiteUrl(
      website,
      page.locale,
      website.configurations.blog.urlPrefix,
      website.configurations.blog.translations[page.locale]?.urlPrefix
    );
    // todo: only in keepshoppers
    // if (page.product) {
    // 	return `${baseUrl}${blogPost.uploadedPostUrl}`;
    // }
    const mainPageSlug =
      website.configurations.blog.translations[page.locale]?.mainPage?.slug ||
      website.configurations.blog.mainPage.slug;
    return `${baseUrl}${mainPageSlug}/${page.slug}${clientPages.getTrailingSlash(website)}`;
  },
  blogMainPage: (website: IWebsite, page: IPage) => {
    const baseUrl = clientPages.getWebsiteUrl(
      website,
      page.locale,
      website.configurations.blog.urlPrefix,
      website.configurations.blog.translations[page.locale]?.urlPrefix
    );
    const mainPageSlug =
      website.configurations.blog.translations[page.locale]?.mainPage?.slug ||
      website.configurations.blog.mainPage.slug;
    return `${baseUrl}${mainPageSlug}${clientPages.getTrailingSlash(website)}`;
  },
  targetedPage: (website: IWebsite, page: IPage) => {
    const baseUrl = clientPages.getWebsiteUrl(
      website,
      page.locale,
      website.configurations.learn.urlPrefix,
      website.configurations.learn.translations[page.locale]?.urlPrefix
    );
    const mainPageSlug =
      website.configurations.learn.translations[page.locale]?.mainPage?.slug ||
      website.configurations.learn.mainPage.slug;
    return `${baseUrl}${mainPageSlug}/${slugify(page.categories[0]?.name || "", {
      locale: "en",
      strict: true,
      lower: true,
      trim: false,
      remove: /[*+~.()'"!:@]/g,
    })}/${page.slug}${clientPages.getTrailingSlash(website)}`;
  },
  comparisonTopPage: (website: IWebsite, page: IPage) => {
    const baseUrl = clientPages.getWebsiteUrl(website, page.locale, website.configurations.comparisonTop10.urlPrefix);
    return `${baseUrl}${website.configurations.comparisonTop10.mainPage.slug}/${
      page.slug
    }${clientPages.getTrailingSlash(website)}`;
  },
  comparisonTopAlternativePage: (website: IWebsite, page: IPage) => {
    const baseUrl = clientPages.getWebsiteUrl(
      website,
      page.locale,
      website.configurations.comparisonTopAlternative.urlPrefix
    );
    return `${baseUrl}${website.configurations.comparisonTopAlternative.mainPage.slug}/${
      page.slug
    }${clientPages.getTrailingSlash(website)}`;
  },
  productPage: (website: IWebsite, page: IPage) => {
    const baseUrl = clientPages.getWebsiteUrl(website, page.locale, website.configurations.productPage.urlPrefix);
    return `${baseUrl}${website.configurations.productPage.mainPage.slug}/${page.slug}${clientPages.getTrailingSlash(
      website
    )}`;
  },
  productReview: (website: IWebsite, page: IPage) => {
    const baseUrl = clientPages.getWebsiteUrl(website, page.locale, website.configurations.productReview.urlPrefix);
    return `${baseUrl}${website.configurations.productReview.mainPage.slug}/${page.slug}${clientPages.getTrailingSlash(
      website
    )}`;
  },
  productAlternative: (website: IWebsite, page: IPage) => {
    const baseUrl = clientPages.getWebsiteUrl(
      website,
      page.locale,
      website.configurations.productAlternative.urlPrefix
    );
    return `${baseUrl}${website.configurations.productAlternative.mainPage.slug}/${
      page.slug
    }${clientPages.getTrailingSlash(website)}`;
  },

  productComparison: (website: IWebsite, page: IPage) => {
    const baseUrl = clientPages.getWebsiteUrl(website, page.locale, website.configurations.productComparison.urlPrefix);
    return `${baseUrl}${website.configurations.productComparison.mainPage.slug}/${
      page.slug
    }${clientPages.getTrailingSlash(website)}`;
  },
  ppcTop10: (website: IWebsite, page: IPage) => {
    const baseUrl = clientPages.getWebsiteUrl(
      website,
      page.locale,
      website.configurations.ppcTop10?.urlPrefix,
      website.configurations.ppcTop10.translations[page.locale]?.urlPrefix
    );
    const postPageSlug =
      website.configurations.ppcTop10.translations[page.locale]?.postPage?.slug ||
      website.configurations.ppcTop10.postPage.slug;
    return `${baseUrl}${postPageSlug}/${page.slug}${clientPages.getTrailingSlash(website)}`;
  },
  dynamicPage: (website: IWebsite, page: IPage) => {
    const dynamicSectionProps = find(website.configurations.dynamicSections, { type: page.type });
    if (dynamicSectionProps) {
      const baseUrl = clientPages.getWebsiteUrl(
        website,
        page.locale,
        dynamicSectionProps.urlPrefix,
        dynamicSectionProps.translations[page.locale]?.urlPrefix
      );
      const mainPageSlug =
        dynamicSectionProps.translations[page.locale]?.mainPage?.slug || dynamicSectionProps.mainPage.slug;
      return `${baseUrl}${mainPageSlug}/${page.slug}${clientPages.getTrailingSlash(website)}`;
    }
    return null;
  },
  dynamicMainPage: (website: IWebsite, page: IPage) => {
    const dynamicSectionProps = find(website.configurations.dynamicSections, {
      type: page.type.replace("_main_page", ""),
    });
    if (dynamicSectionProps) {
      const baseUrl = clientPages.getWebsiteUrl(
        website,
        page.locale,
        dynamicSectionProps.urlPrefix,
        dynamicSectionProps.translations[page.locale]?.urlPrefix
      );
      const mainPageSlug =
        dynamicSectionProps.translations[page.locale]?.mainPage?.slug || dynamicSectionProps.mainPage.slug;
      return `${baseUrl}${mainPageSlug}${clientPages.getTrailingSlash(website)}`;
    }
    return null;
  },
  withCacheInvalidation: (website: IWebsite, url: string) => {
    if (website?.configurations.siteDetails.platform === "WordPress") {
      return `${url}?_nocache=1`;
    }
    return url;
  },
};

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

const isUrl = (string) => {
  if (typeof string !== "string") {
    return false;
  }

  const match = string.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
};

const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
const withHttps = (url) => (!/^https?:\/\//i.test(url) ? `https://${url}` : url);

const formatUrl = (url) => {
  // Check if the URL starts with 'http://' or 'https://'
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }

  // Check if the URL starts with 'www.' after the protocol
  if (!/^https?:\/\/www\./i.test(url)) {
    url = url.replace(/^https?:\/\//i, "$&www.");
  }

  /// remove trailing slash
  if (url.endsWith("/")) {
    url = url.slice(0, -1);
  }
  return url;
};

const getImageCDNUrl = (url: string | undefined, width = null, height = null) => {
  if (!url || typeof url !== "string" || url.includes(".gif")) {
    return url;
  }
  let returnUrl = url
    .replace("assets.entail.ai", "entail-assets.com")
    .replace("sun-assets.s3.amazonaws.com", "entail-assets.com")
    .replace("sun-assets.s3.eu-west-1.amazonaws.com", "entail-assets.com");

  if (width || height) {
    returnUrl = returnUrl.replace(
      /entail-assets\.com\/(.*)\//i,
      `entail-assets.com/$1/fit-in/${width || height}x${height || width}/`
    );
  }

  return returnUrl;
};
const getHostnameFromUrl = (url) => {
  const { hostname } = new URL(url);
  const parts = hostname.split(".");
  if (parts.length > 2) {
    const newParts = parts.slice(parts.length - 2);
    return newParts.join(".");
  }
  return hostname;
};

const getFullHostnameFromUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    console.error("Invalid URL:", error);
    return url;
  }
};

const getDomainFromUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    let domainName = parsedUrl.hostname;

    // Remove 'www.' if it is present
    if (domainName.startsWith("www.")) {
      domainName = domainName.slice(4);
    }

    return domainName;
  } catch (error) {
    console.error("Invalid URL:", error);
    return url;
  }
};

const getWebsiteRelativeUrl = (url, website: IWebsite, includeSearchParams = false) => {
  if (!url?.includes(website?.url)) return url;
  try {
    const { pathname, search } = new URL(url);
    if (!pathname) return url;

    if (includeSearchParams) {
      return `${pathname}${search}`;
    }

    return pathname;
  } catch (e) {
    return url;
  }
};

const getOpportunityPageUrl = (url, includeHash?: boolean) => {
  try {
    const urlObj = new URL(url);

    const subDomain = urlObj.hostname.split(".");

    if (subDomain.length >= 3 && subDomain[0] !== "www") return url;

    if (includeHash) {
      if (urlObj.hash) return urlObj.pathname + urlObj.hash;
    }

    return urlObj.pathname;
  } catch (e) {
    return url;
  }
};

const getWordPressManuallyExportedPage = (website, page) => {
  if (DEV) {
    return `${api.api()}/wordpress/${website._id}/page/${page._id}/export`;
  }
  return `https://app.entail.ai/api/wordpress/${website._id}/page/${page._id}/export`;
};

export {
  api,
  web,
  clients,
  clientPages,
  getWebsiteName,
  setWebsiteName,
  isUrl,
  withHttp,
  withHttps,
  getImageCDNUrl,
  getSearchConsoleUrl,
  getHostnameFromUrl,
  getFullHostnameFromUrl,
  getOpportunityPageUrl,
  getQuerySearchConsoleUrl,
  getPageSearchConsoleUrl,
  getWebsiteRelativeUrl,
  getWordPressManuallyExportedPage,
  getDomainFromUrl,
  formatUrl,
};
