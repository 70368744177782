import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { I18n } from "react-redux-i18n";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import find from "lodash/find";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  sectionWrapper: {
    borderRadius: 0,
    border: "none",
    marginTop: 10,
  },
  innerWrapper: {
    padding: 30,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 20,
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    textTransform: "capitalize",
  },
}));

type PropTypes = {
  website?: IWebsite;
  locale?: string;
  handleLocaleChange?: (locale) => void;
};

const PageLocale = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website = null, locale = null, handleLocaleChange = null } = props;

  // const defaultLocale = {locale:website?.configurations.locale.defaultLocale};
  //
  // const locales = React.useMemo(
  // 	() => {
  // 		return [defaultLocale, ...website?.configurations.locale.alternateLocales.map((locale) => ({ locale })) || []]
  // 	},
  // 	[website]
  // );

  const locales = React.useMemo(
    () => [
      { locale: website?.configurations.locale.defaultLocale },
      ...(website?.configurations.locale.alternateLocales.map(({ locale }) => ({ locale })) || []),
    ],
    [website]
  );

  console.log("locales", locales, website);

  if (!handleLocaleChange || !website || website.locales.length === 0) {
    return null;
  }

  return (
    <Paper elevation={0} className={classes.sectionWrapper}>
      <div className={classes.titleWrapper}>
        <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
          {I18n.t("website.language")}
        </Typography>
        <MSelect
          options={locales}
          value={find(locales, (l) => l.locale === locale)}
          optionValue={"locale"}
          optionLabel={"locale"}
          borderColor={"#e6e6e6"}
          borderRadius={0}
          borderWidth={0}
          height={30}
          searchable
          handleChange={(l) => {
            handleLocaleChange(l.locale);
          }}
        />
      </div>
    </Paper>
  );
};

export default PageLocale;
