import { WIDGETS } from "./constants/actionTypes";

const initialState = {
  widgets: [],
  analytics: [],
  loading: false,
  page: 1,
  pageSize: 20,
  total: 0,
  sortField: { direction: "desc", field: "analytics.last_month.totalClicks" },
  timePeriod: { value: 1, period: "month", key: "last_month", label: "Last month" },
  totalPages: null,
  searchText: "",
  types: [],
  subtypes: [],
  statuses: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case WIDGETS.WIDGETS_LOADED:
      return {
        ...state,
        widgets: action.payload.widgets,
        analytics: action.payload.analytics,
        loading: false,
        total: action.payload.total,
        totalPages: action.payload.totalPages,
        page: action.payload.page,
        pageSize: action.payload.limit,
      };

    case WIDGETS.ON_VALUE_CHANGED:
      return {
        ...state,
        [action.key]: action.value,
      };

    default:
      return state;
  }
};
