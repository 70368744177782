import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";
import MappingList from "./MappingList";
import { KEYWORD_STRATEGY, OUTLINE_PAGES } from "../../reducers/constants/actionTypes";
import { IWebsite } from "../../reducers/constants/objectTypes";
import agent from "../../agent";
import OutlinePageDialog from "../Data/pages/OutlinePages/OutlineDialog/OutlinePageDialog";
import MTableWrapper from "../../components/Table/MTableWrapper";
import MTableToolbar from "../../components/Table/Toolbar/MTableToolbar";
import { api } from "../../helpers/urlHelper";
import MTableToolbarKeywordStrategyTypeFilter from "../../components/Table/Toolbar/Filters/MTableToolbarKeywordStrategyTypeFilter";
import MTableToolbarKeywordStrategyStatusFilter from "../../components/Table/Toolbar/Filters/MTableToolbarKeywordStrategyStatusFilter";
import MTableToolbarKeywordStrategyStartTimeFilter from "../../components/Table/Toolbar/Filters/MTableToolbarKeywordStrategyStartTimeFilter";
import MTablePagination from "../../components/Table/Pagination/MTablePagination";
import MTableToolBarMappingEditColumnsFilter from "../../components/Table/Toolbar/Filters/MTableToolBarMappingEditColumnsFilter";
import MHeaderMetaTags from "../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  isDialogOpen: state.keywordStrategy.isDialogOpen,
  activeDialogTab: state.keywordStrategy.activeDialogTab,
  searchTextFilter: state.keywordStrategy.filter.search,
  searchText: state.keywordStrategy.filter.search,
  postTypesFilter: state.keywordStrategy.filter.post_types,
  startTime: state.keywordStrategy.filter.startTime,
  statusFilter: state.keywordStrategy.filter.status,
  sortField: state.keywordStrategy.sort.field,
  sortDirection: state.keywordStrategy.sort.direction,
  total: state.keywordStrategy.total,
  limit: state.keywordStrategy.limit,
  page: state.keywordStrategy.page,
  refetch: state.keywordStrategy.refetch,
  selectedDataItemsIndexes: state.keywordStrategy.selectedDataItemsIndexes,
  dataList: state.keywordStrategy.dataList,
  selectedStatus: state.keywordStrategy.selectedStatus,
});

const mapDispatchToProps = (dispatch) => ({
  loading() {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_LOADING, payload: true });
  },
  changeLoadedData(payload) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_LOADED, payload });
  },
  changeSort(sort: { field: string; direction: "desc" | "asc" }) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SORT_CHANGED, payload: sort });
  },
  changeIsDialogOpen(open: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_IS_DIALOG_OPEN_CHANGED, payload: open });
  },
  changeActiveDialogTab(tab: "outlines" | "keywords" | "broadKeywords" | "pages") {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_ACTIVE_DIALOG_TAB_CHANGED, payload: tab });
  },

  changePage(page: number) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_PAGE_CHANGED, payload: page });
  },
  changeLimit(limit: number) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_LIMIT_CHANGED, payload: limit });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
  changePostTypeFilter: (postType) =>
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_POST_TYPE_FILTER_CHANGED, payload: postType }),
  changeSearchTextFilter: (searchText) =>
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SEARCH_TEXT_FILTER_CHANGED, payload: searchText }),
  emptyKeywords: () => dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGES_KEYWORDS_EMPTY }),
  onFilterChanged: (payload) => dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_FILTER_CHANGED, payload }),
  changeSelectedColumns: (columns) =>
    dispatch({
      type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SELECTED_COLUMNS_CHANGED,
      payload: columns,
    }),
  changeLoading(loading: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_LOADING, payload: loading });
  },
  changeRefetch(refresh: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_REFETCH_CHANGED, payload: refresh });
  },
  changeSerpsLoaded(payload) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SERPS_LOADED, payload });
  },
  changeSelectedStatus(status: string) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SELECTED_STATUS_CHANGED, payload: status });
  },
});

type PropTypes = {
  changePostTypeFilter: (postTypes: string[]) => void;
  changeSearchTextFilter: (searchText: string) => void;
  selectedWebsite: IWebsite;
  searchTextFilter: string | null;
  total: number;
  limit: number;
  changeSerpsLoaded: (payload: any) => void;
  page: number;
  isDialogOpen: boolean;
  activeDialogTab: "outlines" | "keywords" | "pages";
  postTypesFilter: string[];
  statusFilter: string[];
  sortDirection: "desc" | "asc";
  sortField: string;
  refetch: boolean;
  startTime: string;
  loading: () => void;
  changeLoadedData: (payload: any) => void;
  changeIsDialogOpen: (open: boolean) => void;
  changeActiveDialogTab: (tab: "outlines" | "keywords" | "pages") => void;
  changePage: (page: number) => void;
  changeLimit: (limit: number) => void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  changeSort: (sort: { field: string; direction: "desc" | "asc" }) => void;
  searchText: string;
  emptyKeywords: () => void;
  onFilterChanged: (payload) => void;
  dataList: any;
  selectedDataItemsIndexes: number[];
  selectedStatus: string;
  changeSelectedColumns: (columns: any) => void;
  changeLoading(loading: boolean): void;
  changeRefetch: (refresh: boolean) => void;
  changeSelectedStatus: (status: string) => void;
};

const Mapping = (props: PropTypes) => {
  const {
    changeIsDialogOpen,
    changeActiveDialogTab,
    changeLoadedData,
    changeLimit,
    changePage,
    loading,
    changeSearchTextFilter,
    changeSelectedDataItemsIndexes,
    changeSort,
    searchTextFilter,
    changeSerpsLoaded,
    isDialogOpen,
    activeDialogTab,
    limit,
    total,
    page,
    postTypesFilter,
    sortDirection,
    sortField,
    refetch,
    selectedWebsite,
    statusFilter,
    onFilterChanged,
    startTime,
    searchText,
    selectedDataItemsIndexes,
    dataList,
    changeSelectedColumns,
    changeLoading,
    changeRefetch,
    changeSelectedStatus,
    selectedStatus,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    const sort = JSON.stringify({
      [sortField]: sortDirection === "asc" ? "1" : "-1",
    });
    const filter = postTypesFilter.length > 0 ? JSON.stringify({ conversion_potential: postTypesFilter }) : undefined;

    const cleanedStatusFilter =
      statusFilter.length === 0 ? JSON.stringify(["approved", "pending", "rejected"]) : JSON.stringify(statusFilter);

    return agent.KeywordStrategy.getKeywordStrategies(
      selectedWebsite._id,
      filter,
      limit,
      page,
      sort,
      searchText,
      cleanedStatusFilter,
      startTime,
      "true"
    );
  };

  useEffect(
    () => () => {
      changeLimit(200);
      changePage(1);
      changeSearchTextFilter("");
      onFilterChanged({ status: [] });
      changeSelectedDataItemsIndexes([]);
      changeSort({ field: "submitted_at", direction: "desc" });
      changeSelectedStatus(null);
    },
    []
  );

  useEffect(() => {
    loading();

    changeLoadedData(fetchData());
  }, [
    selectedWebsite,
    searchTextFilter,
    statusFilter,
    postTypesFilter,
    sortDirection,
    sortField,
    limit,
    page,
    refetch,
    startTime,
  ]);
  const exportToCSV = () => {
    const API_ROOT = api.api();
    const pendingItems = dataList?.filter((o) => o.status === "pending");
    const keywords = selectedDataItemsIndexes.map((i) => pendingItems[i]?.keyword).filter((o) => o);

    let endpoint = `/keywordStrategy/${selectedWebsite._id}/pending/csv`;

    if (keywords.length > 0) {
      endpoint += `?keywords=${keywords.join(",")}`;
    }
    return `${API_ROOT}${endpoint}`;
  };

  const rejectAll = async () => {
    changeLoading(true);
    const pendingItems = dataList?.filter((o) => o.status === "pending");
    const keywords = selectedDataItemsIndexes.map((i) => pendingItems[i].keyword);
    try {
      await agent.KeywordStrategy.updateAllStatus(selectedWebsite._id, JSON.stringify(keywords), "rejected");
      await agent.OutlinePages.updateStatusBulk(selectedWebsite._id, keywords, "rejected");
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.reject_sucecss"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }

    changeLoading(false);
    changeRefetch(!refetch);
    changeSelectedDataItemsIndexes([]);
    changeSelectedStatus(null);
  };

  const approveAll = async () => {
    changeLoading(true);
    const pendingItems = dataList?.filter((o) => o.status === "pending");
    const keywords = selectedDataItemsIndexes.map((i) => pendingItems[i].keyword);
    try {
      await agent.KeywordStrategy.updateAllStatus(selectedWebsite._id, JSON.stringify(keywords), "approved");
      await agent.OutlinePages.updateStatusBulk(selectedWebsite._id, keywords, "approved");
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.approved"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    changeLoading(false);
    changeRefetch(!refetch);
    changeSelectedDataItemsIndexes([]);
    changeSelectedStatus(null);
  };

  const returnToPendingAll = async () => {
    changeLoading(true);
    const approvedItems = dataList?.filter((o) => o.status === "approved");
    const keywords = selectedDataItemsIndexes.map((i) => approvedItems[i].keyword);
    try {
      await agent.KeywordStrategy.updateAllStatus(selectedWebsite._id, JSON.stringify(keywords), "new");
      await agent.OutlinePages.updateStatusBulk(selectedWebsite._id, keywords, "new");
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.returned_to_mapping"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    changeLoading(false);
    changeRefetch(!refetch);
    changeSelectedDataItemsIndexes([]);
    changeSelectedStatus(null);
  };

  const theme = useTheme();

  const selectedStatusOptions = React.useMemo(() => {
    if (selectedStatus === "approved") {
      return [
        {
          text: I18n.t("strategy.strategyKeywords.return_to_pending"),
          onClick: returnToPendingAll,
          color: theme.palette.text.primary,
        },
      ];
    }
    if (selectedStatus === "pending") {
      return [
        {
          text: I18n.t("strategy.strategyKeywords.approve"),
          onClick: approveAll,
          color: "primary",
        },
        { text: I18n.t("strategy.strategyKeywords.reject"), onClick: rejectAll, color: theme.palette.error.main },
      ];
    }
    return [];
  }, [selectedStatus, selectedDataItemsIndexes]);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.article_cms.strategy.title`)} />
      <MTableToolbar
        cmsCreateButton
        csvURL={exportToCSV()}
        searchText={searchText}
        onClearSelectedRows={() => {
          changeSelectedDataItemsIndexes([]);
          changeSelectedStatus(null);
        }}
        selectedRows={selectedDataItemsIndexes.length}
        handleSearchTextChanged={(value) => onFilterChanged({ search: value })}
        selectedRowActions={selectedStatusOptions}
        actions={[
          <MTableToolBarMappingEditColumnsFilter
            columns={[]}
            handleColumnsSelected={(columns) => changeSelectedColumns(columns?.map((col) => col._id))}
          />,
        ]}
      >
        <MTableToolbarKeywordStrategyTypeFilter
          postTypes={postTypesFilter}
          handlePostTypesSelected={(value) => onFilterChanged({ post_types: value })}
        />
        <MTableToolbarKeywordStrategyStatusFilter
          status={statusFilter}
          handlePostStatusSelected={(value) => onFilterChanged({ status: value })}
        />
        <MTableToolbarKeywordStrategyStartTimeFilter
          startTime={startTime}
          handlePostStartTimeSelected={(value) => onFilterChanged({ startTime: value })}
        />
      </MTableToolbar>
      <MappingList />
      {dataList && dataList.length > 0 && (
        <MTablePagination
          paginationLimit={limit}
          page={page}
          paginationPagesCount={Math.ceil(total / limit)}
          setPage={changePage}
          setPaginationLimit={changeLimit}
        />
      )}
      <OutlinePageDialog
        open={isDialogOpen}
        close={() => {
          changeIsDialogOpen(false);
          changeActiveDialogTab("outlines");
          changeSerpsLoaded({ data: [] });
        }}
        cms={true}
        pageTab={activeDialogTab}
        setPageTab={changeActiveDialogTab}
      />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapping);
