import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { IExcludedKeywordSeed, IWebsite } from "../../../reducers/constants/objectTypes";
import { EXCLUDE_KEYWORDS } from "../../../reducers/constants/actionTypes";
import ExcludeKeywordItem from "./ExcludeKeywordItem";
import MTable from "../../../components/Table/Table/MTable";
import MTableHeader from "../../../components/Table/Table/MTableHeader";
import MTableHeaderLabelWithSelectAll from "../../../components/Table/Table/MTableHeaderLabelWithSelectAll";
import MTableHeaderLabel from "../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../components/Table/Rows/MTableRowEmptyRow";

type PropTypes = {
  keywordsListLoaded: IExcludedKeywordSeed;
  keywordsList: any[];
  searchText: string;
  selectedWebsite: IWebsite;
  totalKeywordsCount: number;
  checkedKeywordsList: IExcludedKeywordSeed[];

  handleKeywordsListLoaded: (payload) => void;
  handleSearchTextChanged: (searchText) => void;
  handleCheckedBoxChanged: (checkedKeywordsList) => void;
  handleExcludedKeywordSeedAdded: (payload) => void;
  loading: boolean;
};

const mapStateToProps = (state) => ({
  keywordsListLoaded: state.excludeKeywords.keywordsListLoaded,
  keywordsList: state.excludeKeywords.keywordsList,
  searchText: state.excludeKeywords.searchText,
  checkedKeywordsList: state.excludeKeywords.checkedKeywordsList,
  selectedWebsite: state.home.selectedWebsite,
  totalKeywordsCount: state.excludeKeywords.totalKeywordsCount,
});

const mapDispatchToProps = (dispatch) => ({
  handleKeywordsListLoaded: (payload) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_KEYWORDS_LIST_LOADED, payload });
  },
  handleSearchTextChanged: (searchText) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_SEARCH_TEXT_CHANGED, searchText });
  },
  handleExcludedKeywordSeedAdded: (excludedKeywords) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_EXCLUDE_KEYWORD_ADDED, excludedKeywords });
  },
  handleCheckedBoxChanged: (checkedKeywordsList) => {
    dispatch({ type: EXCLUDE_KEYWORDS.ON_CHECKED_BOX_CHANGED, checkedKeywordsList });
  },
});

const ExcludeKeywordsList = (props: PropTypes) => {
  const [allChecked, setAllChecked] = React.useState(false);
  const { keywordsListLoaded, keywordsList, handleCheckedBoxChanged, checkedKeywordsList, loading } = props;

  const formatTypeOptions = (type) => {
    switch (type) {
      case "exact":
        return "Exact Match";
      case "containing":
        return "Words Containing";
      case "phrase":
        return "Phrases Containing";
      default:
        return type;
    }
  };

  const handleAllChecked = () => {
    if (allChecked) {
      handleCheckedBoxChanged([]);
    } else {
      handleCheckedBoxChanged(keywordsList);
    }
    setAllChecked(!allChecked);
  };

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabelWithSelectAll
          selected={checkedKeywordsList?.length > 0}
          columnWidth={"33%"}
          onSelectAll={handleAllChecked}
          onClearAll={() => {
            handleCheckedBoxChanged([]);
          }}
        >
          {I18n.t("exclude_keywords.keywords")}
        </MTableHeaderLabelWithSelectAll>
        <MTableHeaderLabel> {I18n.t("exclude_keywords.type")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("exclude_keywords.excluded_kws")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("exclude_keywords.excluded_on")}</MTableHeaderLabel>
        <MTableHeaderLabel> {""}</MTableHeaderLabel>
      </MTableHeader>

      {loading && <MTableRowProgressBar />}
      {keywordsListLoaded && keywordsList?.length === 0 && <MTableRowEmptyRow />}

      {keywordsListLoaded &&
        keywordsList?.length > 0 &&
        keywordsList.map((keyword, index) => (
          <ExcludeKeywordItem
            isFirstRow={index === 0}
            isLastRow={index === keywordsList.length - 1}
            key={keyword._id}
            keyword={keyword}
            formatTypeOptions={formatTypeOptions}
          />
        ))}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExcludeKeywordsList);
