import React from "react";
import ClassNames from "classnames";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { IWebsite } from "../reducers/constants/objectTypes";
import RichTextEditor from "../editor/RichTextEditor";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    //	maxWidth: (props: PropTypes) => props.width || 'unset',
    // minHeight: (props: PropTypes) => props.height || 'unset',
    marginTop: (props: PropTypes) => props.marginTop || 0,
    marginBottom: (props: PropTypes) => props.marginBottom || 0,
    marginLeft: (props: PropTypes) => props.marginLeft || 0,
    flexDirection: (props: PropTypes) => (!props.labelsPosition || props.labelsPosition === "left" ? "row" : "column"),
    width: "100%",
    display: "flex",
  },
  titleAndLengthWrapper: {
    display: "flex",
    flexDirection: (props: PropTypes) => (!props.labelsPosition || props.labelsPosition === "left" ? "column" : "row"),
    justifyContent: (props: PropTypes) =>
      (!props.labelsPosition || props.labelsPosition === "left") && !true ? "center" : "flex-start",
    alignItems: (props: PropTypes) =>
      !props.labelsPosition || props.labelsPosition === "left" ? "flex-end" : "flex-start",
    minWidth: 140,
    marginRight: 15,
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    color: (props: PropTypes) =>
      !props.labelsPosition || props.labelsPosition === "left"
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
  },
  length: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: (props: PropTypes) => (!props.labelsPosition || props.labelsPosition === "left" ? 5 : 0),
    marginLeft: (props: PropTypes) => (!props.labelsPosition || props.labelsPosition === "left" ? 0 : 10),
  },
  textFieldWrapper: {
    width: "100%",
    marginLeft: "-30px",
    marginTop: "-20px",
    // border: `1px solid ${theme.palette.divider}`,
    // borderRadius: (props: PropTypes) => props.borderRadius || 5,
  },
  error: {
    color: "red",
  },
  editable: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  editorToolbar: {
    height: 55,
    width: 840,
    //	paddingLeft: 20,
    // paddingRight: 30,
  },
}));

type PropTypes = {
  value: string;
  height?: number;
  labelsPosition?: "left" | "top";
  placeholder?: string;
  borderRadius?: number;
  fullWidth?: boolean;
  error?: boolean;
  autoFocus?: boolean;
  maxLength?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  width?: number | string;
  wrapperClassName?: string;

  fontSize?: number;
  key: string;
  title?: string;
  editorTitle?: string;
  editorTitlePlaceholder?: string;
  website: IWebsite;

  handleEditorTitleChange?: (value) => void;
  handleValueChange: (value) => void;
};

const MRichTextField = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    key,
    value,
    title = null,
    editorTitle = null,
    editorTitlePlaceholder = null,
    handleEditorTitleChange = null,
    website,
    fontSize,
    handleValueChange,
    placeholder = null,
    maxLength = null,
    wrapperClassName = null,
  } = props;

  const valueLength = value?.length || 0;
  return (
    <div className={ClassNames(classes.wrapper, wrapperClassName)}>
      <div className={classes.textFieldWrapper}>
        <RichTextEditor
          key={key}
          droppableId={`text_field_editor_${key}`}
          fontSize={fontSize || 16}
          title={editorTitle}
          onTitleChanged={handleEditorTitleChange}
          titlePlaceHolder={editorTitlePlaceholder}
          enableComments={false}
          editorPlaceHolder={placeholder}
          editableCustomClass={classes.editable}
          toolbarCustomClass={classes.editorToolbar}
          website={website}
          initialValue={value}
          onValueChanged={handleValueChange}
        />
      </div>
    </div>
  );
};

export default MRichTextField;
