import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { IWidgetFieldProperties, IWidgetTextCTAFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import LiteRichTextEditor from "../../../../../../../editor/elements/liteEditor/LiteRichTextEditor";
import { editorType } from "../../../../../../../editor/types/editor.Types";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  editableCustomClass: {
    borderBottomLeftRadius: 3,
    borderBottomRightRadiusRadius: 3,
  },
}));

type PropTypes = {
  properties: IWidgetFieldProperties;
  onChange: (properties: IWidgetTextCTAFieldProps) => void;
};

const TextCTAWidgetItem = (props: PropTypes) => {
  const { onChange, properties } = props;
  const { value = "" } = properties as IWidgetTextCTAFieldProps;

  const classes = useStyles();

  return (
    <LiteRichTextEditor
      editorToolbarPostion="top"
      onValueChanged={(content) => onChange({ ...properties, value: content })}
      initialValue={value}
      editableCustomClass={classes.editableCustomClass}
      elements={[editorType.headingOne, editorType.headingTwo]}
    />
  );
};

export default TextCTAWidgetItem;
