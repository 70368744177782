import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { ReactEditor, useSelected, useSlateStatic } from "slate-react";
import { Editor, Node, Path, Transforms } from "slate";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PublicIcon from "@material-ui/icons/Public";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography/Typography";
import _ from "lodash";
import link from "./link";
import LinkPreviewer from "./LinkPreviewer";
import CopyToClipboardIcon from "../../../icons/CopyToClipboardIcon";
import { copyStringToClipboard } from "../../../helpers/fbScraperHelper";
import LinkEditor from "./LinkEditor";
import { withHttps } from "../../../helpers/urlHelper";
import MLink from "../../../components/MLink";
import { editorType } from "../../types/editor.Types";

const useStyles = makeStyles((theme: Theme) => ({
  linkTooltip: {
    margin: 0,
    padding: 0,
    backgroundColor: "white",
    maxWidth: "unset",
  },
  paper: {
    width: 400,
  },
  popper: {
    position: "absolute",
    zIndex: 1,
    opacity: 0,
    pointerEvents: "none",
    left: -2000,
  },
  popper1: {
    zIndex: 1,
    pointerEvents: "none",
    left: -2000,
  },
  linkTooltipWrapper: {
    padding: "2px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  urlWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tooltipLinkText: {
    color: theme.palette.primary.main,
    maxWidth: 230,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "pre",
  },
  globeIcon: {
    color: theme.palette.text.secondary,
    marginRight: 5,
  },
  actionsWrapper: {
    marginLeft: 10,
  },
  actionButton: {
    padding: 8,
    background: "none",
    border: "none",
  },
  actionIcon: {
    fontSize: 18,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  actionIconActive: {
    color: theme.palette.primary.main,
  },
  linePreviewWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
};

const LinkElement = React.memo(
  (props: PropTypes) => {
    const classes = useStyles(props);
    const { attributes, children, element } = props;
    const editor = useSlateStatic();
    const selected = useSelected();
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [metaData, setMetaData] = useState(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const popperRef = React.useRef<HTMLDivElement | null>(null);
    const [deletedEmptyLink, setDeletedEmptyLink] = useState(false);
    React.useEffect(
      () =>
        // hack to refresh tooltip position when failing to get preview
        () =>
          setMetaData(undefined),
      [metaData]
    );

    React.useEffect(() => {
      const el = popperRef.current;
      const { selection } = editor;

      if (!el) {
        return;
      }

      if (selection !== null && selection.anchor !== null && selected) {
        const parentPath = Path.parent(selection.anchor.path);
        const lastElementInPath = Node.get(editor, parentPath);
        const endOffset = Editor.end(editor, selection.anchor.path);

        if (
          lastElementInPath?.type === "link" &&
          lastElementInPath?.url === "" &&
          !lastElementInPath.product &&
          lastElementInPath.linkType !== "button"
        ) {
          setOpen(true);
          setEditMode(true);
          setDeletedEmptyLink(false);
          return;
        }

        if (
          lastElementInPath?.type === "link" &&
          selection.anchor.offset !== 0 && // the beginning of the <a />
          selection.anchor.offset !== endOffset?.offset // the end offset of <a />
        ) {
          setDeletedEmptyLink(false);
          setOpen(true);
          return;
        }
      }
      if (!deletedEmptyLink && !element.product && element?.url === "") {
        setDeletedEmptyLink(true);
        try {
          const elementPath = ReactEditor.findPath(editor as ReactEditor, element);
          Transforms.unwrapNodes(editor, { at: elementPath, match: (n) => n.type === editorType.link });
        } catch (e) {
          console.log("error:::", e);
        }
      }
      setEditMode(false);
      setOpen(false);
    });
    const text = element.children
      .map((child) => (child.type ? child.children.map((subChild) => subChild.text || "") : child.text))
      .join("");

    return (
      <Tooltip
        open={open}
        ref={popperRef}
        classes={{
          tooltip: classes.linkTooltip,
        }}
        PopperProps={
          {
            // keepMounted: true,
            // disablePortal: true,
          }
        }
        placement={"bottom"}
        interactive
        title={
          <>
            {editMode && (
              <LinkEditor
                initialProduct={element.product}
                initialType={element.linkType || "url"}
                disableClickAway={true}
                initialNewTab={element.newTab}
                initialReal={element.rel}
                initialCssId={element.cssId}
                // initialTextToDisplay={text}
                initialUrl={element.url}
                closePopover={() => {
                  console.log("closePopover");
                  setEditMode(false);
                  if (element.url === "" && element.linkType !== "button") {
                    link.removeLink(editor);
                    setOpen(false);
                  }
                }}
                onInsertLinkClicked={(
                  url: string,
                  newTab: boolean,
                  rel: string | undefined,
                  product,
                  linkType,
                  cssId: string
                ) => {
                  link.linkChanged(editor, {
                    url: !url ? "" : withHttps(url),
                    newTab,
                    rel,
                    linkType,
                    product,
                    cssId,
                  });
                  setEditMode(false);
                  setDeletedEmptyLink(true);
                }}
              />
            )}
            {!editMode && open && (
              <div className={classes.paper} contentEditable={false}>
                <div className={classes.linkTooltipWrapper}>
                  <div className={classes.urlWrapper}>
                    <PublicIcon className={classes.globeIcon} />
                    {element.linkType === "button" ? (
                      <div>
                        <Typography variant={"body1"} className={classes.tooltipLinkText}>
                          {I18n.t("rich_text_editor.insert_link.button_link")}
                        </Typography>
                      </div>
                    ) : (
                      <Tooltip enterDelay={1000} title={element.url}>
                        <MLink typographyClassName={classes.tooltipLinkText} href={element.url} newTab>
                          {element.url}
                        </MLink>
                      </Tooltip>
                    )}
                  </div>
                  <div className={classes.actionsWrapper}>
                    <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.insert_link.copy_link")}>
                      <IconButton
                        className={classes.actionButton}
                        onClick={(event) => {
                          event.stopPropagation();
                          copyStringToClipboard(element.url, () =>
                            enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))
                          );
                        }}
                      >
                        <CopyToClipboardIcon className={classes.actionIcon} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.insert_link.edit_link")}>
                      <IconButton className={classes.actionButton} onClick={() => setEditMode(true)}>
                        <EditOutlinedIcon className={classes.actionIcon} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.insert_link.remove_link")}>
                      <IconButton className={classes.actionButton} onClick={() => link.removeLink(editor)}>
                        <LinkOffIcon className={classes.actionIcon} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                {element.linkType !== "button" && (
                  <div className={classes.linePreviewWrapper}>
                    <LinkPreviewer
                      url={element.url}
                      width={"390px"}
                      onSuccess={(metadata) => {
                        console.log("onSuccess", metadata);
                        setMetaData(metadata);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        }
      >
        <a
          {...attributes}
          href={element.linkType === "button" ? "javascript:void(0)" : element.url}
          target={element.linkType === "button" ? undefined : element.newTab ? "_blank" : "_self"}
          rel={element.linkType === "button" ? undefined : element.rel}
          data-product-id={element.linkType === "button" ? undefined : element.product?.itemNumber}
        >
          {children}
        </a>
      </Tooltip>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps?.element?.children?.length > 1) {
      return _.isEqual(prevProps.children, nextProps.children);
    }
    return _.isEqual(prevProps.element, nextProps.element);
  }
);

export default LinkElement;
