import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import numeral from "numeral";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import { I18n } from "react-redux-i18n";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import moment from "moment";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import PageviewIcon from "@material-ui/icons/Pageview";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import { DATE_FORMAT } from "../../../../reducers/constants/consts";
import { IAnalyticsSession } from "../../../../reducers/constants/objectTypes";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) => ({
  profile: {
    margin: "0px 10px 20px 0",
    minHeight: 250,
  },
  root: {
    paddingTop: 0,
  },
  subHeader: {
    height: 60,
    display: "flex",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  url: {
    wordBreak: "break-word",
  },
}));

type PropTypes = {
  loaded: boolean;
  sessions: IAnalyticsSession[];
};

const UserEventsSessions = (props: PropTypes) => {
  const classes = useStyles(props);
  const { loaded, sessions } = props;

  return (
    <Paper className={classes.profile}>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        <ListSubheader className={classes.subHeader}>
          <Typography variant={"subtitle2"}>{I18n.t("analytics.user_events.user_sessions")}</Typography>
        </ListSubheader>
        {loaded &&
          sessions.map((session) => (
            <React.Fragment key={session.id}>
              <ListSubheader className={classes.subHeader}>
                <Typography variant={"subtitle2"}>{moment(session.created_at).format(DATE_FORMAT.COMPLETE)}</Typography>
              </ListSubheader>
              {session.entry_page && (
                <ListItem>
                  <ListItemIcon>
                    <FlightLandIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link
                        className={classes.url}
                        href={session.entry_page}
                        onClick={(e) => e.stopPropagation()}
                        target={"_blank"}
                        // className={classes.topicName}
                        variant="subtitle2"
                      >
                        {session.entry_page}
                      </Link>
                    }
                  />
                </ListItem>
              )}
              {session.exit_page && (
                <ListItem>
                  <ListItemIcon>
                    <FlightTakeoffIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link
                        className={classes.url}
                        href={session.exit_page}
                        onClick={(e) => e.preventDefault() && e.stopPropagation()}
                        target={"_blank"}
                        // className={classes.topicName}
                        variant="subtitle2"
                      >
                        {session.exit_page}
                      </Link>
                    }
                  />
                </ListItem>
              )}
              {session.duration && (
                <ListItem>
                  <ListItemIcon>
                    <AvTimerIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={I18n.t("analytics.user_events.duration", {
                      duration: numeral(session.duration / 60).format("0,0"),
                    })}
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemIcon>
                  <PageviewIcon />
                </ListItemIcon>
                <ListItemText primary={session.page_views} />
              </ListItem>
            </React.Fragment>
          ))}
      </List>
    </Paper>
  );
};

export default UserEventsSessions;
