import React from "react";
import { I18n } from "react-redux-i18n";
import SwitchProperty from "./SwitchProperty";

type PropTypes = {
  checked?: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
};

const ShowParagraphProperty = (props: PropTypes) => (
  <SwitchProperty
    {...props}
    label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.show_paragraph_label")}
  />
);

export default ShowParagraphProperty;
