import React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Theme, Divider, Typography } from "@material-ui/core";
import { nanoid } from "nanoid";
import { makeStyles } from "@material-ui/styles";
import { ListItems } from "../../../components/ListItems";
import { IWidget, IWidgetField } from "../../../../../../../reducers/constants/objectTypes";
import SwitchProperty from "../items/itemProperties/components/SwitchProperty";
import AddButton from "../../../components/AddButton";
import TrashCanIcon from "../../../../../../../icons/TrashCanIcon";
import ArrowRightRoundedIcon from "../../../../../../../icons/ArrowRightRoundedIcon";
import { FormWidgetPage } from "../items/FormWidgetPage";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  divider: {
    border: `.5px solid ${palette.grey["200"]}`,
  },
  addPageButton: {
    border: "none",
    borderRadius: 0,
  },
  classItem: {
    backgroundColor: palette.grey["100"],
    cursor: "pointer",
    display: "flex",
    color: "white",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 55,
    padding: 15,
    "&:hover $lefticon": {
      visibility: "visible",
    },
  },
  leftIcon: {
    visibility: "hidden",
  },
  icon: {
    marginRight: -15,
  },
  item: { display: "flex", flex: 0.2, justifyContent: "space-between" },
}));

type PropTypes = {
  widget: IWidget;
  selectedItem: number;
  selectedStep?: number;

  insertField: (field: IWidgetField) => void;
  removeField: (index: number) => void;
  reorderItems: (fields: IWidgetField[]) => void;
  onChange: (multistep: boolean) => void;
  onAddPage(): void;
  onAddNewItemMode(mode: boolean): void;
  deletePage: (index: any) => void;
  setSelectedPage: (pageIndex: number) => void;
  onClick: (field: IWidgetField, index: number) => void;
};

const mapStateToProps = (state) => ({
  widget: state.widgetBuilder.widget,
  selectedItem: state.widgetBuilder.selectedItem,
  selectedStep: state.widgetBuilder.selectedStep,
});

const mapDispatchToProps = (dispatch) => ({
  insertField: (field) => dispatch({ type: WIDGET_BUILDER.FIELD_ADDED, field }),
  removeField: (index) => dispatch({ type: WIDGET_BUILDER.FIELD_REMOVED, index }),
  reorderItems: (fields) => dispatch({ type: WIDGET_BUILDER.FIELD_REORDER, fields }),
  deletePage: (index) => dispatch({ type: WIDGET_BUILDER.DELETE_PAGE, index }),
  setSelectedPage: (selectedPage: number) => dispatch({ type: WIDGET_BUILDER.SET_SELECTED_PAGE, selectedPage }),
});

const MultiStepForm = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedItem, selectedStep, widget } = props;
  const {
    onChange,
    insertField,
    onAddPage,
    onAddNewItemMode,
    deletePage,
    setSelectedPage,
    reorderItems,
    removeField,
    onClick,
  } = props;

  function insertNewItem(item) {
    insertField({ ...item, fieldId: nanoid(10) });
  }

  function onItemSelect(item) {
    onAddNewItemMode(false);
    insertNewItem(item);
  }

  if (selectedItem !== null) {
    return (
      <FormWidgetPage
        label={I18n.t("label", { page: selectedPage })}
        onClose={() => {
          setSelectedPage(null);
        }}
        onDone={(e) => {
          setSelectedPage(null);
        }}
        onItemsReorder={(fields) => {
          reorderItems(fields);
        }}
        onDeleteClick={(field, index) => {
          removeField(index);
        }}
        onClick={onClick}
        onCopyClick={insertNewItem}
        onAddClick={() => {
          onAddNewItemMode(true);
        }}
        fields={widget.items[0].steps[selectedPage || 0]?.fields}
      />
    );
  }

  return (
    <ListItems>
      <SwitchProperty
        checked={widget.items[0].multistep}
        onChange={onChange}
        label={I18n.t("widgets_builder.build.multistep_form_label")}
      />
      {widget.items[0].steps.map((step, index) => (
        <div key={index + 2}>
          <Divider key={index + 1} className={classes.divider} />
          <div
            className={classes.classItem}
            onClick={() => {
              setSelectedPage(index);
            }}
          >
            <Typography>{`${I18n.t("widgets_builder.build.step")} ${index + 1}`}</Typography>
            <div className={classes.item}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  deletePage(index);
                }}
              >
                <TrashCanIcon className={classes.leftIcon} />
              </div>
              <ArrowRightRoundedIcon className={classes.icon} />
            </div>
          </div>
        </div>
      ))}
      <Divider className={classes.divider} />
      <AddButton
        onClick={() => {
          onAddPage();
        }}
        className={classes.addPageButton}
        text={I18n.t("widgets_builder.build.add_page_button")}
      />
    </ListItems>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiStepForm);
