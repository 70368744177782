import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fill: theme.palette.text.secondary,
    fontSize: 14,
  },
}));

type PropTypes = {
  className?: string;
};

function TableIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 18 18"}>
      <path d="M16.1147 18H1.88527C1.37643 18 0.935093 17.8103 0.561271 17.4308C0.18709 17.0517 0 16.6168 0 16.126V1.87402C0 1.36821 0.18709 0.929512 0.561271 0.557921C0.935093 0.185974 1.37643 0 1.88527 0H16.1147C16.6232 0 17.0645 0.185974 17.4387 0.557921C17.8129 0.929512 18 1.36821 18 1.87402V16.126C18 16.6168 17.8129 17.0517 17.4387 17.4308C17.0645 17.8103 16.6232 18 16.1147 18ZM1.88527 4.70753H16.1147V1.87402H1.88527V4.70753ZM4.93779 6.58154H1.88527V16.126H4.93779V6.58154ZM13.0622 6.58154V16.126H16.1147V6.58154H13.0622ZM11.1769 6.58154H6.82305V16.126H11.1769V6.58154Z" />
    </SvgIcon>
  );
}

export default TableIcon;
