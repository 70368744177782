import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ClassNames from "classnames";
import moment from "moment";
import MSelectMultiple from "../../../components/MSelectMultiple";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";
import { OUTLINE } from "../../../reducers/constants/actionTypes";
import { ICategory, IOutline, ITag, IWebsite } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";
import { getAvailablePageTypes } from "../../../helpers/websiteHelper";
import MDatePicker from "../../../components/MDatePicker";
import localeHelper from "../../../helpers/localeHelper";

const useStyles = makeStyles((theme: Theme) => ({
  leftSideWrapper: {
    backgroundColor: theme.palette.common.white,
    maxWidth: 350,
    minWidth: 280,
    width: "100%",
    border: `${theme.palette.divider} solid 1px`,
    borderRadius: 5,
    zIndex: 1,
    padding: 20,
    overflow: "auto",
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  selectMultipleWrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  fieldTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 10,
  },
  columnWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  listItemIcon: {
    cursor: "pointer",
    marginLeft: 10,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(24),
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  selectColumn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  selectTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 10,
  },
  hidden: {
    visibility: "hidden",
  },
  select: {
    textTransform: "capitalize",
  },
  datePickerWrapper: {
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 4,
    height: 37,
    padding: "10px 14px",
  },
}));

type PropTypes = {
  outlineLoaded: boolean;
  outline: IOutline;
  //
  outlineCategoriesOptions: ICategory[];
  outlineTagsOptions: ITag[];

  website: IWebsite;
  // postType: ePostType,
  // wordCount: number,
  // category: ICategory,
  // tags: ITag[],
  // links: string[],

  onOutlinePropertyChanged: (name, value) => void;
};

const mapStateToProps = (state) => ({
  outlineTagsOptions: state.outline.outlineTagsOptions,
  outlineCategoriesOptions: state.outline.outlineCategoriesOptions,

  outlineLoaded: state.outline.outlineLoaded,
  outline: state.outline.outline,

  website: state.home.selectedWebsite,

  // postType: state.outline.postType,
  // wordCount: state.outline.wordCount,
  // category: state.outline.category,
  // tags: state.outline.tags,
  // links: state.outline.links,
});

const mapDispatchToProps = (dispatch) => ({
  onOutlinePropertyChanged: (name, value) => dispatch({ type: OUTLINE.ON_OUTLINE_PROPERTY_CHANGED, name, value }),
});

const LeftSideScreen = (props: PropTypes) => {
  const classes = useStyles(props);
  const { outlineCategoriesOptions, outlineTagsOptions, outline, outlineLoaded, onOutlinePropertyChanged, website } =
    props;

  const selectOptions = getAvailablePageTypes(website);
  const locales = React.useMemo(
    () => [
      {
        locale: website?.configurations.locale.defaultLocale,
        name: localeHelper.getLanguageFromLocaleCode(website?.configurations.locale.defaultLocale),
      },
      ...(website?.configurations.locale.alternateLocales.map(({ locale }) => ({
        locale,
        name: localeHelper.getLanguageFromLocaleCode(locale),
      })) || []),
    ],
    [website]
  );

  return (
    <div className={classes.leftSideWrapper}>
      <div className={classes.row}>
        <div className={classes.selectColumn}>
          <Typography className={classes.selectTitle} variant={"subtitle2"}>
            {I18n.t("outline.post_type")}
          </Typography>
          <MSelect
            customClass={classes.select}
            options={selectOptions}
            value={selectOptions.find((option) => option.key === outline.postType)}
            optionValue={"key"}
            optionLabel={"label"}
            height={37}
            placeholder={I18n.t("outline.post_type_ph")}
            borderWidth={1}
            borderRadius={4}
            isBold={false}
            textColor={"#333333"}
            handleChange={(type) => {
              onOutlinePropertyChanged("postType", type.key);
            }}
          />
        </div>
        <TextFieldMaxLength
          value={outline.wordCount?.toString()}
          titleAndLengthLocation={"top"}
          title={I18n.t("outline.word_count")}
          placeholder={I18n.t("outline.enter_ph")}
          borderRadius={4}
          height={37}
          marginLeft={10}
          handleValueChange={(value) => onOutlinePropertyChanged("wordCount", value)}
        />
      </div>

      <div className={classes.row}>
        <div className={classes.columnWrapper}>
          <Typography className={classes.fieldTitle} variant={"subtitle2"}>
            {I18n.t("outline.language")}
          </Typography>
          <MSelect
            optionLabel={"name"}
            optionValue={"locale"}
            options={locales}
            value={locales.find(
              (locale) => locale.locale === (outline.locale || website?.configurations.locale.defaultLocale)
            )}
            width={"calc(50% - 10px)"}
            height={37}
            borderWidth={1}
            borderRadius={4}
            placeholder={I18n.t("outline.locale_ph")}
            handleChange={(locale) => {
              onOutlinePropertyChanged("locale", locale.locale);
            }}
          />
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.columnWrapper}>
          <Typography className={classes.fieldTitle} variant={"subtitle2"}>
            {I18n.t("outline.category")}
          </Typography>
          <MSelect
            optionLabel={"name"}
            optionValue={"_id"}
            isClearable
            options={outlineCategoriesOptions}
            value={outline.category}
            height={37}
            borderWidth={1}
            borderRadius={4}
            placeholder={I18n.t("outline.category_ph")}
            handleChange={(selectedCategory) => {
              onOutlinePropertyChanged("category", selectedCategory);
            }}
          />
        </div>
      </div>

      <div className={classes.columnWrapper}>
        <Typography className={classes.fieldTitle} variant={"subtitle2"}>
          {I18n.t("outline.topics")}
        </Typography>

        <MSelectMultiple
          wrapperClassName={classes.selectMultipleWrapper}
          options={outlineTagsOptions}
          selectedOptions={outline.tags}
          optionLabel={"name"}
          optionValue={"_id"}
          borderWidth={1}
          height={37}
          placeholder={I18n.t("outline.select_topics_ph")}
          handleOptionSelected={(tag) => {
            if (!outline.tags?.includes(tag)) {
              const selectedTags = [...outline.tags, tag];
              onOutlinePropertyChanged("tags", selectedTags);
            }
          }}
          handleOptionRemoved={(tag) =>
            onOutlinePropertyChanged(
              "tags",
              [...outline.tags].filter((t) => t !== tag)
            )
          }
          handleCreateOption={() => {}}
        />
      </div>

      <div className={classes.columnWrapper}>
        <Typography className={classes.fieldTitle} variant={"subtitle2"}>
          {I18n.t("outline.links_from_other_pages")}
        </Typography>

        <div>
          {[...outline.links, ""].map((link, index) => (
            <div className={classes.row}>
              <TextFieldMaxLength
                value={link}
                placeholder={I18n.t("outline.select_page")}
                borderRadius={4}
                height={37}
                handleValueChange={(value) => {
                  const updatedLinks = [...outline.links];
                  if (outline.links.length === index) {
                    onOutlinePropertyChanged("links", [...outline.links, value]);
                    return;
                  }
                  updatedLinks[index] = value;
                  onOutlinePropertyChanged("links", updatedLinks);
                }}
              />
              <CloseIcon
                className={ClassNames(classes.listItemIcon, outline.links.length === index && classes.hidden)}
                onClick={() => {
                  onOutlinePropertyChanged("links", [...outline.links.filter((l, i) => index !== i)]);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.columnWrapper}>
          <Typography className={classes.fieldTitle} variant={"subtitle2"}>
            {I18n.t("outline.due_date")}
          </Typography>
          <MDatePicker
            views={["month", "year"]}
            customClass={classes.datePickerWrapper}
            disableDateIcon
            dateFormat={"MM/YYYY"}
            minDate={moment().subtract(1, "year")}
            maxDate={moment().add(1, "year")}
            placeholder={I18n.t("outline.due_date_ph")}
            disableFuture={false}
            date={outline.dueDate ? moment(outline.dueDate) : null}
            onDateChanged={(date) => onOutlinePropertyChanged("dueDate", moment(date).toISOString())}
          ></MDatePicker>
        </div>
        <div className={classes.columnWrapper} style={{ marginLeft: 10 }}>
          <Typography className={classes.fieldTitle} variant={"subtitle2"}>
            {I18n.t("outline.quota_date")}
          </Typography>
          <MDatePicker
            views={["month", "year"]}
            customClass={classes.datePickerWrapper}
            disableDateIcon
            dateFormat={"MM/YYYY"}
            minDate={moment(outline.quotaDate || Date.now()).subtract(1, "year")}
            maxDate={moment().add(1, "year")}
            placeholder={I18n.t("outline.quota_date_ph")}
            disableFuture={false}
            date={outline.quotaDate ? moment(outline.quotaDate) : null}
            onDateChanged={(date) => onOutlinePropertyChanged("quotaDate", moment(date).toISOString())}
          ></MDatePicker>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideScreen);
