import * as React from "react";
import DimensionsBaseComponent from "../BaseComponents/DimensionsBaseComponent";
import { I18n } from "react-redux-i18n";
import BorderAllSidesIcon from "../../../icons/BorderAllSidesIcon";
import BorderTopLeftIcon from "../../../icons/BorderTopLeftIcon";
import BorderTopRightIcon from "../../../icons/BorderTopRightIcon";
import BorderBottomRightIcon from "../../../icons/BorderBottomRightIcon";
import BorderBottomLeftIcon from "../../../icons/BorderBottomLeftIcon";

type PropTypes = {
  title?: string;
  value: string;
  defaultValue?: string;
  handleChange: (borderRadius) => void;
};

const BorderRadiusCSS = (props: PropTypes) => {
  const { title = I18n.t("rich_text_editor.button.border_radius"), value, defaultValue, handleChange } = props;
  return (
    <DimensionsBaseComponent
      icons={[
        <BorderAllSidesIcon />,
        <BorderTopLeftIcon />,
        <BorderTopRightIcon />,
        <BorderBottomRightIcon />,
        <BorderBottomLeftIcon />,
      ]}
      rowTitle={title}
      value={value}
      defaultValue={defaultValue || "30px"}
      handleChange={(val) => handleChange(val)}
    />
  );
};

export default BorderRadiusCSS;
