import { unified } from "unified";
import markdown from "remark-parse";
import remarkGfm from "remark-gfm";
import serialize from "./slateMarkdown/serialize";
import toSlate from "./slateMarkdown/deserialize";

const slateTopMarkdown = (nodes) => nodes.map((v) => serialize(v)).join("");

const markDownToSlate = (text) =>
  new Promise((resolve, reject) => {
    unified()
      .use(markdown)
      .use(remarkGfm)
      .use(toSlate)
      .process(text, (err, file) => {
        if (err) {
          reject(err);
        }
        resolve(file?.result);
      });
  });

///TODO: implement custom markdown syntax
// function tablePlugin() {
//   return (tree) => {
//     tree.children.map((child) => {
//         if(child.type==="table"){
//
//         }
//     });
//     return tree;
//   };
// }

export default {
  slateTopMarkdown,
  markDownToSlate,
};
