import * as React from "react";
import Switch from "@material-ui/core/Switch";
import { I18n } from "react-redux-i18n";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  sectionNameAndSwitchWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "13px 20px",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  innerWrapper: {
    padding: 30,
  },
  sectionWrapper: {
    borderRadius: 0,
    border: "none",
    marginTop: 10,
    // paddingBottom: 30,
    "&:first-child": {
      marginTop: 0,
      paddingBottom: 0,
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    textTransform: "capitalize",
  },
}));

type PropTypes = {
  title?: string;
  inSection?: boolean;
  enabled: boolean;
  children?: React.ReactElement | React.ReactElement[];
  handleEnabledChange: (active) => void;
  className?: string;
};

const WebsitesSectionsSwitch = (props: PropTypes) => {
  const { title = null, enabled, inSection = false, handleEnabledChange, children, className } = props;
  const classes = useStyles(props);

  if (inSection) {
    return (
      <Paper elevation={0} className={classes.sectionWrapper}>
        <div className={classes.sectionNameAndSwitchWrapper}>
          <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
            {title}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={enabled}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleEnabledChange(!enabled);
                  }}
                  color="primary"
                />
              }
              labelPlacement="start"
              label={I18n.t(`website.${enabled ? "enabled" : "disabled"}`)}
            />
          </FormGroup>
        </div>
        {children && <div className={ClassNames(classes.innerWrapper, className)}> {children}</div>}
      </Paper>
    );
  }

  return (
    <>
      <FormGroup>
        <FormControlLabel
          label={""}
          control={
            <Switch
              checked={enabled}
              onChange={(e) => {
                e.stopPropagation();
                handleEnabledChange(!enabled);
              }}
              color="primary"
            />
          }
          labelPlacement="start"
        />
      </FormGroup>
    </>
  );
};

export default WebsitesSectionsSwitch;
