import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import { IWebsiteThemeProps } from "../../../../../reducers/constants/objectTypes";
import MRouterPrompt from "../../../components/MRouterPrompt";
import ColorPickerRowToolTip from "../../../components/ColorPickerRowToolTip";
import { DEV } from "../../../../../reducers/constants/consts";

const useStyles = makeStyles((theme: Theme) => ({
  paletteColorWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  elementWrap: {
    width: "100%",
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colorsTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    "&:first-child": {
      marginTop: 0,
    },
  },
  colorsRow: {
    display: "flex",
    alignItems: "center",
  },
  colorsToolTipText: {
    cursor: "pointer",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.primary.dark,
    marginLeft: 4,
  },
}));

const mapStateToProps = (state) => ({
  initialTheme: state.websitePages.initialTheme,
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (theme) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "theme",
      value: theme,
    }),
});

type PropTypes = {
  initialTheme: IWebsiteThemeProps;
  theme: IWebsiteThemeProps;
  onPropertyChanged: (theme) => void;
};

const ColorThemePalette = (props: PropTypes) => {
  const classes = useStyles(props);
  const { theme, onPropertyChanged } = props;

  return (
    <PageSection sectionTitle={I18n.t("website.page_types.theme")}>
      <MRouterPrompt />
      <div className={classes.paletteColorWrapper}>
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.primary_main_tp")}
          rowTitle={`${I18n.t("website.primary_main")}`}
          value={theme.palette.primary.main}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.primary.main = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.primary_light_tp")}
          rowTitle={`${I18n.t("website.primary_light")}`}
          value={theme.palette.primary.light}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.primary.light = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.primary_dark_tp")}
          rowTitle={`${I18n.t("website.primary_dark")}`}
          value={theme.palette.primary.dark}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.primary.dark = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        {/*Primary Colors*/}

        {/*Secondary Colors*/}
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.secondary_light_tp")}
          rowTitle={`${I18n.t("website.secondary_light")}`}
          value={theme.palette.secondary.light}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.secondary.light = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.secondary_dark_tp")}
          rowTitle={`${I18n.t("website.secondary_dark")}`}
          value={theme.palette.secondary.dark}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.secondary.dark = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        {/*Secondary Colors*/}

        {/*Texts*/}
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.text_primary_tp")}
          rowTitle={`${I18n.t("website.text_primary")}`}
          value={theme.palette.text.primary}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.text.primary = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.text_secondary_tp")}
          rowTitle={`${I18n.t("website.text_secondary")}`}
          value={theme.palette.text.secondary}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.text.secondary = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.text_disabled_tp")}
          rowTitle={`${I18n.t("website.text_disabled")}`}
          value={theme.palette.text.disabled}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.text.disabled = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        {/*Texts*/}

        {/*background*/}
        {/*<ColorPickerRowToolTip*/}
        {/*  toolTip={I18n.t("website.background_paper_tp")}*/}
        {/*  rowTitle={`${I18n.t("website.background_paper")}`}*/}
        {/*  value={theme.palette.background.paper}*/}
        {/*  handleChange={(color) => {*/}
        {/*    const newPalette = { ...theme.palette };*/}
        {/*    newPalette.background.paper = color;*/}
        {/*    onPropertyChanged({ ...theme, palette: newPalette });*/}
        {/*  }}*/}
        {/*/>*/}
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.background_default_tp")}
          rowTitle={`${I18n.t("website.background_default")}`}
          value={theme.palette.background.default}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.background.default = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.secondary_main_tp")}
          rowTitle={`${I18n.t("website.secondary_main")}`}
          value={theme.palette.secondary.main}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.secondary.main = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        <ColorPickerRowToolTip
          toolTip={I18n.t("website.divider_tp")}
          rowTitle={`${I18n.t("website.divider_txt")}`}
          value={theme.palette.divider}
          handleChange={(color) => {
            const newPalette = { ...theme.palette };
            newPalette.divider = color;
            onPropertyChanged({ ...theme, palette: newPalette });
          }}
        />
        {/*background*/}
      </div>
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorThemePalette);
