import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function HyperLinkIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 41 20"}>
      <path d="M4.434,8.760 C4.994,5.820 7.751,3.800 10.748,3.800 L16.603,3.800 C17.642,3.800 18.501,2.940 18.501,1.900 C18.501,0.860 17.642,0.000 16.603,0.000 L10.948,0.000 C5.733,0.000 1.077,3.820 0.578,9.020 C-0.002,14.980 4.674,20.000 10.509,20.000 L16.603,20.000 C17.642,20.000 18.501,19.140 18.501,18.100 C18.501,17.060 17.642,16.200 16.603,16.200 L10.509,16.200 C6.692,16.200 3.675,12.720 4.434,8.760 ZM14.505,12.000 L26.494,12.000 C27.593,12.000 28.492,11.100 28.492,10.000 C28.492,8.900 27.593,8.000 26.494,8.000 L14.505,8.000 C13.406,8.000 12.507,8.900 12.507,10.000 C12.507,11.100 13.406,12.000 14.505,12.000 ZM30.050,0.000 L24.396,0.000 C23.357,0.000 22.497,0.860 22.497,1.900 C22.497,2.940 23.357,3.800 24.396,3.800 L30.250,3.800 C33.247,3.800 36.005,5.820 36.564,8.760 C37.324,12.720 34.306,16.200 30.490,16.200 L24.396,16.200 C23.357,16.200 22.497,17.060 22.497,18.100 C22.497,19.140 23.357,20.000 24.396,20.000 L30.490,20.000 C36.324,20.000 41.000,14.980 40.441,9.020 C39.941,3.820 35.265,0.000 30.050,0.000 Z" />
    </SvgIcon>
  );
}

export default HyperLinkIcon;
