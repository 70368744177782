import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { I18n } from "react-redux-i18n";
import { useSlate, useSlateStatic } from "slate-react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Editor } from "slate";
import MButton from "../../../../../components/MButton";
import AssistantChats from "./AssistantChats";
import AssistantPrompts from "./AssistantPrompts";
import agent from "../../../../../agent";
import slateMarkdownHelper from "../../../../../helpers/slateMarkdownHelper";
import { IPage, IWebsite } from "../../../../../reducers/constants/objectTypes";
import { useEvent } from "../../../../../hooks/useEvents";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tasksWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    padding: "20px 0 0 0",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
  },
  taskBar: {
    marginBottom: 10,
    padding: 0,
    borderRadius: "3px 3px 0px 0px ",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  text: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  icon: {
    fontSize: 16,
    fill: theme.palette.text.primary,
  },
  barButton: {
    height: 27,
    padding: "5px 10px",
    fontSize: 14,
    borderRadius: 3,
    fontWeight: theme.typography.fontWeightLight as any,
  },
}));

type PropTypes = {
  navigateBack: Function;
  selectedWebsite: IWebsite;
  page: IPage;
};

const NewTaskAssistant = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, page } = props;
  const [chats, setChats] = React.useState([]);
  const [chatLoader, setChatLoader] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const editor = useSlateStatic();
  const [selectedText, setSelectedText] = React.useState({ text: "", selection: null });
  const admin = useSelector((state: any) => state.home.admin);
  const { enqueueSnackbar } = useSnackbar();
  const [cancelRequest, setCancelRequest] = React.useState(false);
  const messagesEndRef = React.useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEvent(
    "show-ai-assistant",
    (data) => {
      setSelectedText({ text: Editor.string(editor, editor.selection), selection: editor.selection });
      setChats([]);
      setChatLoader(null);
    },
    []
  );

  React.useEffect(() => {
    if (chatLoader != null) {
      scrollToBottom();
    }
  }, [chatLoader]);

  const getResults = async (instruction) => {
    const currentNodes = chats[chats.length - 1]?.nodes || editor.getFragment();

    if (currentNodes.length === 0) {
      enqueueSnackbar(I18n.t("snackbar.select_text"), {
        variant: "error",
      });
      return;
    }
    if (chats.length === 0) {
      setTitle(instruction);
    }
    setChatLoader({ question: instruction, userName: admin.name, nodes: [], selection: null });

    agent.EditorAssistant.assistant(selectedWebsite, {
      message: slateMarkdownHelper.slateTopMarkdown(currentNodes),
      parentMessageId: chats[chats.length - 1]?._id,
      instruction,
      data: { page: page._id },
    })
      .then((res) => {
        if (cancelRequest) {
          setCancelRequest(false);
        } else {
          slateMarkdownHelper
            .markDownToSlate(res.result.text)
            .then((nodes) => {
              setChats([
                ...chats,
                {
                  question: instruction,
                  userMessage: slateMarkdownHelper.slateTopMarkdown(currentNodes),
                  _id: res.result.id,
                  answer: res.result.text,
                  userName: admin.name,
                  nodes,
                  selection: editor.selection,
                },
              ]);
              setChatLoader(null);
            })
            .catch((e) => {
              setChatLoader({
                question: instruction,
                userName: admin.name,
                nodes: [],
                selection: null,
                error: e.message,
              });
            });
        }
      })
      .catch((e) => {
        setChatLoader({
          question: instruction,
          userName: admin.name,
          nodes: [],
          selection: null,
          error: e.response?.body?.message,
        });
      });
  };
  const clearTask = () => {
    setChats([]);
    setSelectedText({ text: "", selection: null });
    setTitle(null);
    setChatLoader(null);
  };

  return (
    <div className={classes.wrapper}>
      <List className={classes.tasksWrapper} component={"div"}>
        <AssistantChats
          onStop={() => {
            setChatLoader(null);
            setCancelRequest(true);
          }}
          selectedText={selectedText}
          onRetry={(value) => getResults(value)}
          chats={chats}
          chatLoader={chatLoader}
        />
        <div ref={messagesEndRef} />
      </List>

      <AssistantPrompts onRequest={(value) => getResults(value)} />
    </div>
  );
};

export default NewTaskAssistant;
