import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import CfButton from "../../components/CfButton";
import AuthNavbar from "./components/AuthNavbar";
import AuthTextField from "./components/AuthTextField";
import AuthCard from "./components/AuthCard";
import { useEffect, useState } from "react";
import agent from "../../agent";
import useQuery from "../../hooks/useQuery";
import { useSnackbar } from "notistack";
import { replace } from "connected-react-router";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
  },
  title: {
    margin: "24px 0",
  },
  description: {
    width: "282px",
    height: 38,
    color: "#808080",
    fontSize: 16,
    lineHeight: "19px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    marginBottom: 24,
  },
  email: {
    color: "black",
  },
  divider: {
    color: "black",
    height: 1,
    width: 121,
    backgroundColor: "#E5E5E5",
  },
  credentialsWrapper: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    marginTop: 10,
    marginBottom: 20,
    minWidth: 200,
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  terms: {
    width: 290,
    height: 60,
    marginTop: 20,
    textAlign: "center",
  },
}));

type PropTypes = {
  onSubmit: (to) => void;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (redirect) => dispatch(replace(redirect)),
});

const ResetPassword = (props: PropTypes) => {
  const classes = useStyles(props);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [preFlightCheckCompleted, setPreFlightCheckCompleted] = useState(false);

  const query = useQuery();

  const { onSubmit } = props;

  useEffect(() => {
    // preflight check
    agent.Auth.passwordResetConfirm(query.get("token"), null)
      .then((res) => {})
      .catch((e) => {
        if (e.response && e.response.body && e.response.body.type === "token") {
          onSubmit("/link-expired");
        }
      })
      .finally(() => setPreFlightCheckCompleted(true));
  }, []);

  const handleLoginClick = () => {
    if (!password || !confirmPassword) return null;

    if (password !== confirmPassword) {
      enqueueSnackbar(I18n.t("login.passwords_not_match"));

      return null;
    }

    agent.Auth.passwordResetConfirm(query.get("token"), password)
      .then((res) => {
        enqueueSnackbar(res.message);
        onSubmit("/login");
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  return preFlightCheckCompleted ? (
    <section className={classes.pageWrapper}>
      <AuthNavbar />
      <div className={classes.contentWrapper}>
        <AuthCard customClass={classes.title} title={I18n.t("login.reset_password")}>
          <Typography variant={"body2"} className={classes.description}>
            <div>
              {I18n.t("login.enter_new_password")}
              <span className={classes.email}>name@entail.ai.</span>
            </div>
          </Typography>

          <div className={classes.credentialsWrapper}>
            <AuthTextField
              label={I18n.t("login.new_password")}
              type={"password"}
              value={password}
              onChange={setPassword}
            />
            <AuthTextField
              label={I18n.t("login.confirm_password")}
              type={"password"}
              value={confirmPassword}
              onChange={setConfirmPassword}
            />
          </div>

          <CfButton
            fullWidth
            customClass={classes.button}
            text={I18n.t("login.reset_my_password")}
            onClick={(e) => handleLoginClick()}
          />
        </AuthCard>
      </div>
    </section>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
