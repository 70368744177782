import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { I18n } from "react-redux-i18n";
import { Transforms } from "slate";
import _, { find } from "lodash";
import ProductCTADefaultLayout from "./layouts/ProductCTADefaultLayout/ProductCTADefaultLayout";
import { Widget } from "../WithWidgets";
import { ProductCTA, ProductCTAData } from "../../../types/editor.Types";
import { IProduct, IWebsite } from "../../../../reducers/constants/objectTypes";
import { allCurrencies } from "../../../../helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: (props: PropTypes) => props.element.data?.backgroundColor || null,

    [theme.breakpoints.down("sm")]: {
      alignItems: "start",
    },
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: ProductCTA;
  website: IWebsite;
  products: IProduct[];
  readMode?: boolean;
};

const ProductCTAWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, website, products, readMode = false } = props;
  const editor = useSlateStatic();
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  const websiteTheme = website.configurations.theme;

  const handleChange = (data) => {
    console.log("handleChange", data, ReactEditor.findPath(editor as ReactEditor, element));
    // @ts-ignore
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const defaultData: ProductCTAData = {
    productNumber: {
      show: true,
      number: "1",
      css: {
        // fontSize: 70,
        // fontWeight: theme.typography.fontWeightBold as any,
        // margin: "0px 0px 15px 0px",
      },
    },
    title: {
      show: true,
      title: "",
      css: {
        // fontSize: 21,
        // fontWeight: theme.typography.fontWeightBold as any,
        // color: websiteTheme.palette.primary.main,
      },
    },
    productName: {
      show: true,
      css: {
        // fontSize: 14,
      },
    },
    startPrice: {
      show: false,
      priceCurrency: _.find(allCurrencies, { code: "USD" }),
      css: {
        // fontSize: 14,
      },
    },
    price: {
      show: true,
      priceCurrency: _.find(allCurrencies, { code: "USD" }),
      css: {
        // fontSize: 14,
        // fontWeight: theme.typography.fontWeightBold as any,
      },
    },
    button: {
      show: true,
      showArrow: false,
      css: {
        // padding: "15px 90px",
      },
    },
    rating: {
      show: true,
    },
    maxImages: 6,
    product: {
      itemNumber: null,
    },
    backgroundColor: "rgba(255,255,255,0)",
    uuid: null,
  };

  console.log("default data", defaultData);

  React.useEffect(() => {
    handleChange({ ...defaultData, ...element.data });
  }, []);

  console.log("element", element);
  return (
    <div {...attributes}>
      <Widget title={I18n.t("rich_text_editor.custom_components.product_cta")} element={element} editable>
        <div className={classes.wrapper}>
          <ProductCTADefaultLayout
            readMode={readMode}
            element={element}
            product={product}
            websiteTheme={websiteTheme}
            handleChange={handleChange}
          />
        </div>
        {children}
      </Widget>
    </div>
  );
};

export default ProductCTAWidget;
