import * as React from "react";
import DimensionsBaseComponent from "../BaseComponents/DimensionsBaseComponent";
import { I18n } from "react-redux-i18n";
import PaddingAllSidesIcon from "../../../icons/PaddingAllSidesIcon";
import PaddingTopIcon from "../../../icons/PaddingTopIcon";
import PaddingRightIcon from "../../../icons/PaddingRightIcon";
import PaddingBottomIcon from "../../../icons/PaddingBottomIcon";
import PaddingLeftIcon from "../../../icons/PaddingLeftIcon";

type PropTypes = {
  title?: string;
  value: string;
  defaultValue: string;
  handleChange: any;
};

const MarginCSS = (props: PropTypes) => {
  const { title = I18n.t("rich_text_editor.button.margin"), value, defaultValue, handleChange } = props;
  return (
    <DimensionsBaseComponent
      icons={[
        <PaddingAllSidesIcon />,
        <PaddingTopIcon />,
        <PaddingRightIcon />,
        <PaddingBottomIcon />,
        <PaddingLeftIcon />,
      ]}
      rowTitle={title}
      value={value || "0px"}
      defaultValue={defaultValue || "0px"}
      handleChange={(val) => handleChange(val)}
    />
  );
};

export default MarginCSS;
