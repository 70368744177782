import React from "react";
import { connect } from "react-redux";
import InsightsBarChart from "./InsightsBarChart";
import InsightsChart from "./InsightsChart";
import CfProgressBar from "../../../../../components/CfProgressBar";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 15,
  },
}));
const mapStateToProps = (state) => ({
  reportType: state.insights.reportType,
  isLoading: state.insights.isLoading,
});

const mapDispatchToProps = {};

type PropTypes = {
  reportType: "website" | "page" | "allPages" | "event";
  chartHeight: number;

  isLoading: boolean;
};

const InsightsGraph = (props: PropTypes) => {
  const { reportType, chartHeight, isLoading } = props;
  const classes = useStyles(props);

  return isLoading ? (
    <div className={classes.loaderWrapper}>
      <CfProgressBar />
    </div>
  ) : reportType === "event" ? (
    <InsightsBarChart height={chartHeight} />
  ) : (
    <InsightsChart height={chartHeight} />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsGraph);
