import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import { useState } from "react";
import { find } from "lodash";
import PageSection from "../../../components/PageSection";
import {
  eBlogTemplate,
  ePageType,
  IWebsite,
  IWebsiteBlogProps,
  IWebsiteSectionProps,
  IWebsiteThemeProps,
  SNWebsiteAutoCompleteMetaTag,
} from "../../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import WebsitesSectionsSwitch from "../../../components/WebsitesSectionsSwitch";
import TextInputMentions from "../../../components/TextInputMentions";
import { LENGTHS } from "../../../../../reducers/constants/consts";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import TextDesignToolWithPreview from "../../../components/TextDesignToolWithPreview";
import MRouterPrompt from "../../../components/MRouterPrompt";
import PageLocale from "../../../components/PageLocale";
import CustomUrlPrefixTextField from "../../../components/CustomUrlPrefixTextField";
import MSelect from "../../../../../components/MSelect";
import DefaultPageBlog from "./templates/DefaultPageBlog";
import MayplePageBlog from "./templates/MayplePageBlog";
import SafeBooksPageBlog from "./templates/SafeBooksPageBlog";
import JitPageBlog from "./templates/JitPageBlog";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  selectTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 5,
  },
  select: {
    textTransform: "capitalize",
  },
}));

const mapStateToProps = (state) => ({
  theme: state.websitePages.theme,
  sections: state.websitePages.sections,
  blog: state.websitePages.blog,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (blog) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED, name: "blog", value: blog }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  website: IWebsite;
  blog: IWebsiteBlogProps;
  theme: IWebsiteThemeProps;
  sections: IWebsiteSectionProps;
  onPropertyChanged: (blog) => void;
  handleToggleActive: (section, enabled) => void;
};

const PageBlog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website, sections, blog, theme, handleToggleActive, onPropertyChanged } = props;
  const [error, setError] = React.useState(false);
  const [emptyFields, setEmptyFields] = React.useState([]);

  const [locale, setLocale] = useState(website?.configurations.locale.defaultLocale || null);
  const { defaultLocale } = website?.configurations.locale;
  const defaultBlogTextFieldsValidated = (isValid = true, emptyFields = []) => {
    let valid = isValid;
    setEmptyFields(emptyFields);
    if (!blog.mainPage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_title`));
    }
    if (!blog.mainPage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_description`));
    }
    if (!blog.mainPage.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.title`));
    }
    if (!blog.mainPage.description.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.description`));
    }
    if (!blog.categoryPage.slug) {
      valid = false;
      emptyFields.push(I18n.t(`website.blog.category_page_slug`));
    }
    if (!blog.categoryPage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.blog.category_page_meta_title`));
    }
    if (!blog.categoryPage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.blog.category_page_meta_description`));
    }
    if (!blog.postPage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.blog.post_page_meta_title`));
    }
    if (!blog.postPage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.blog.post_page_meta_description`));
    }
    setEmptyFields(emptyFields);
    return valid;
  };
  const textFieldsValidated = () => {
    let valid = true;
    setEmptyFields([]);
    const emptyFields = [];
    if (!blog.name) {
      valid = false;
      emptyFields.push(I18n.t(`website.name`));
    }
    if (!blog.mainPage.slug) {
      valid = false;
      emptyFields.push(I18n.t(`website.slug_title`));
    }
    if (blog.template === "default") {
      valid = defaultBlogTextFieldsValidated(valid, emptyFields);
    }
    setEmptyFields(emptyFields);
    return valid;
  };

  React.useEffect(() => {
    if (emptyFields.length <= 0) {
      return;
    }
    textFieldsValidated();
  }, [blog]);

  const templateOptions = [
    { _id: 1, name: eBlogTemplate.default },
    { _id: 2, name: eBlogTemplate.mayple },
    { _id: 3, name: eBlogTemplate.safebooks },
    { _id: 4, name: eBlogTemplate.jit },
  ];

  return (
    <>
      <MRouterPrompt />
      <PageLocale website={website} locale={locale} handleLocaleChange={setLocale} />
      <WebsitesSectionsSwitch
        inSection
        enabled={sections.blog}
        title={I18n.t(`website.page_types.blog`)}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.blog) {
              handleToggleActive("blog", enabled);
            }
            setError(true);
          } else {
            setError(false);
            handleToggleActive("blog", enabled);
          }
        }}
      >
        <CustomUrlPrefixTextField
          previewUrls={[
            {
              key: "website.blog.main_page_preview_url",
              value: locale === defaultLocale ? blog.mainPage.slug : blog.translations[locale]?.mainPage?.slug || "",
              valid: sections.blog,
            },
            {
              key: "website.blog.category_page_preview_url",
              value: `${
                locale === defaultLocale ? blog.mainPage.slug : blog.translations[locale]?.mainPage?.slug || ""
              }${
                locale === defaultLocale ? blog.categoryPage.slug : blog.translations[locale]?.categoryPage?.slug || ""
              }/category-slug`,
            },
            {
              key: "website.blog.post_page_preview_url",
              value: `${
                locale === defaultLocale ? blog.mainPage.slug : blog.translations[locale]?.mainPage?.slug || ""
              }/post-slug`,
            },
          ]}
          handleValueChange={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...blog, urlPrefix: value });
              return;
            }
            blog.translations[locale] = {
              ...(blog.translations[locale] || {}),
              urlPrefix: value,
            };
            onPropertyChanged({ ...blog });
          }}
          customUrlPrefix={blog.urlPrefix}
          alternativeUrlPrefix={blog.translations?.[locale]?.urlPrefix}
          locale={locale}
        />
      </WebsitesSectionsSwitch>
      {error && (
        <Typography variant={"body1"} className={classNames(classes.error)}>
          {emptyFields.length > 0 && I18n.t("website.error_message")}
        </Typography>
      )}
      <PageSection sectionTitle={I18n.t("website.blog.general")}>
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            error={emptyFields.includes(I18n.t(`website.name`))}
            titleAndLengthLocation={"top"}
            value={locale === defaultLocale ? blog.name : blog.translations[locale]?.name || ""}
            placeholder={I18n.t("website.name_ph")}
            title={I18n.t("website.name")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...blog, name: value });
                return;
              }
              blog.translations[locale] = {
                ...(blog.translations[locale] || {}),
                name: value,
              };
              onPropertyChanged({ ...blog });
            }}
          />
          <TextFieldMaxLength
            error={emptyFields.includes(I18n.t(`website.slug_title`))}
            titleAndLengthLocation={"top"}
            value={locale === defaultLocale ? blog.mainPage.slug : blog.translations[locale]?.mainPage?.slug || ""}
            placeholder={I18n.t("website.slug_title_ph")}
            title={I18n.t("website.slug_title")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...blog, mainPage: { ...blog.mainPage, slug: value } });
                return;
              }
              blog.translations[locale] = {
                ...(blog.translations[locale] || {}),
                mainPage: { ...(blog.translations[locale]?.mainPage || {}), slug: value },
              };
              onPropertyChanged({ ...blog });
            }}
          />
        </div>
        <div>
          <Typography variant={"subtitle2"} className={classes.selectTitle}>
            {I18n.t("website.blog.template")}
          </Typography>
          <MSelect
            customClass={classes.select}
            singleValueStyles={{ textTransform: "capitalize" }}
            options={templateOptions}
            value={find(templateOptions, { name: blog.template })}
            optionValue={"_id"}
            optionLabel={"name"}
            width={"calc(50% - 10px)"}
            borderRadius={0}
            borderWidth={"1px"}
            height={37}
            handleChange={(value) => onPropertyChanged({ ...blog, template: value.name })}
          />
        </div>
      </PageSection>
      {blog.template === eBlogTemplate.default && (
        <DefaultPageBlog
          blog={blog}
          onPropertyChanged={onPropertyChanged}
          emptyFields={emptyFields}
          locale={locale}
          defaultLocale={defaultLocale}
        />
      )}
      {blog.template === eBlogTemplate.mayple && (
        <MayplePageBlog
          website={website}
          blog={blog}
          onPropertyChanged={onPropertyChanged}
          emptyFields={emptyFields}
          locale={locale}
          defaultLocale={defaultLocale}
        />
      )}
      {blog.template === eBlogTemplate.safebooks && (
        <SafeBooksPageBlog
          website={website}
          blog={blog}
          onPropertyChanged={onPropertyChanged}
          emptyFields={emptyFields}
          locale={locale}
          defaultLocale={defaultLocale}
        />
      )}
      {blog.template === eBlogTemplate.jit && (
        <JitPageBlog
          pageType={ePageType.Blog}
          jitTemplate={blog.jitTemplate}
          jitTemplateTranslation={blog.translations[locale]?.jitTemplate || {}}
          onPropertyChanged={(value) => {
            onPropertyChanged({ ...blog, ...value });
          }}
          onTranslationChanged={(value) => {
            blog.translations[locale] = {
              ...(blog.translations[locale] || {}),
              jitTemplate: value,
            };
            onPropertyChanged({ ...blog });
          }}
          locale={locale}
          defaultLocale={defaultLocale}
          emptyFields={emptyFields}
          selectedWebsite={website}
        />
      )}
      {blog.template !== eBlogTemplate.jit && (
        <PageSection sectionTitle={I18n.t("website.blog.category_page")}>
          <TextFieldMaxLength
            prefix={locale === defaultLocale ? blog.mainPage.slug : blog.translations[locale]?.mainPage?.slug || ""}
            error={emptyFields.includes(I18n.t(`website.blog.category_page_slug`))}
            titleAndLengthLocation={"top"}
            value={
              locale === defaultLocale ? blog.categoryPage.slug : blog.translations[locale]?.categoryPage?.slug || ""
            }
            placeholder={I18n.t("website.slug_title_ph")}
            title={I18n.t("website.slug_title")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...blog, categoryPage: { ...blog.categoryPage, slug: value } });
                return;
              }
              blog.translations[locale] = {
                ...(blog.translations[locale] || {}),
                categoryPage: { ...(blog.translations[locale]?.categoryPage || {}), slug: value },
              };
              onPropertyChanged({ ...blog });
            }}
          />

          <TextInputMentions
            key={`blog_category_meta_title_${locale}`}
            tags={[SNWebsiteAutoCompleteMetaTag.categoryName]}
            placeholder={I18n.t("website.meta_title_ph")}
            title={I18n.t("website.meta_title")}
            maxLength={LENGTHS.META_TITLE}
            error={emptyFields.includes(I18n.t(`website.blog.category_page_meta_title`))}
            titleAndLengthLocation={"top"}
            text={
              locale === defaultLocale
                ? blog.categoryPage.metaTitle
                : blog.translations[locale]?.categoryPage?.metaTitle || ""
            }
            onTextChanged={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({
                  ...blog,
                  categoryPage: { ...blog.categoryPage, metaTitle: value },
                });
                return;
              }
              blog.translations[locale] = {
                ...(blog.translations[locale] || {}),
                "categoryPage.metaTitle": value,
              };
              onPropertyChanged({ ...blog });
            }}
          />

          <TextInputMentions
            key={`blog_category_meta_desc_${locale}`}
            error={emptyFields.includes(I18n.t(`website.blog.category_page_meta_description`))}
            tags={[SNWebsiteAutoCompleteMetaTag.categoryName, SNWebsiteAutoCompleteMetaTag.categoryDescription]}
            placeholder={I18n.t("website.meta_description_ph")}
            title={I18n.t("website.meta_description")}
            maxLength={LENGTHS.META_DESCRIPTION}
            titleAndLengthLocation={"top"}
            text={
              locale === defaultLocale
                ? blog.categoryPage.metaDescription
                : blog.translations[locale]?.categoryPage?.metaDescription || ""
            }
            onTextChanged={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({
                  ...blog,
                  categoryPage: { ...blog.categoryPage, metaDescription: value },
                });
                return;
              }
              blog.translations[locale] = {
                ...(blog.translations[locale] || {}),
                "categoryPage.metaDescription": value,
              };
              onPropertyChanged({ ...blog });
            }}
          />
        </PageSection>
      )}
      <PageSection sectionTitle={I18n.t("website.blog.post_page")}>
        <TextDesignToolWithPreview
          topTitle
          disableHover
          marginLeft={"-30px"}
          marginRight={"-30px"}
          elementTitle={I18n.t("website.element_design", { element: I18n.t("website.title") })}
          css={blog.postPage.title?.css}
          variant={"h1"}
          // website.glossary.example_title_text
          exampleText={I18n.t("website.blog.example_title_text")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...blog,
              postPage: { ...blog.postPage, title: { ...blog.postPage.title, css: newCss } },
            });
          }}
        />
        <TextInputMentions
          key={`blog_post_meta_title_${locale}`}
          error={emptyFields.includes(I18n.t(`website.blog.post_page_meta_title`))}
          tags={[SNWebsiteAutoCompleteMetaTag.postTitle, SNWebsiteAutoCompleteMetaTag.year]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale ? blog.postPage.metaTitle : blog.translations[locale]?.postPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...blog, postPage: { ...blog.postPage, metaTitle: value } });
              return;
            }
            blog.translations[locale] = {
              ...(blog.translations[locale] || {}),
              "postPage.metaTitle": value,
            };
            onPropertyChanged({ ...blog });
          }}
        />

        <TextInputMentions
          key={`blog_post_meta_desc_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.postTitle, SNWebsiteAutoCompleteMetaTag.postMetaDescription]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          error={emptyFields.includes(I18n.t(`website.blog.post_page_meta_description`))}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? blog.postPage.metaDescription
              : blog.translations[locale]?.postPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...blog,
                postPage: { ...blog.postPage, metaDescription: value },
              });
              return;
            }
            blog.translations[locale] = {
              ...(blog.translations[locale] || {}),
              "postPage.metaDescription": value,
            };
            onPropertyChanged({ ...blog });
          }}
        />
        {blog.template === eBlogTemplate.safebooks && (
          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            value={
              locale === defaultLocale
                ? blog.safebooksTemplate?.bottomWidgetId
                : blog.translations[locale]?.safebooksTemplate?.bottomWidgetId || ""
            }
            placeholder={I18n.t("website.blog.bottom_widget_id_ph")}
            title={I18n.t("website.blog.bottom_widget_id")}
            height={37}
            borderRadius={4}
            marginBottom={30}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({
                  ...blog,
                  safebooksTemplate: {
                    ...blog.safebooksTemplate,
                    bottomWidgetId: value,
                  },
                });
                return;
              }
              blog.translations[locale] = {
                ...(blog.translations[locale] || {}),
                safebooksTemplate: {
                  ...(blog.translations[locale]?.safebooksTemplate || {}),
                  bottomWidgetId: value,
                },
              };
              onPropertyChanged({ ...blog });
            }}
          />
        )}
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageBlog);
