import React, { useMemo } from "react";

import Canvas from "./Canvas";
import VideoPlayer from "./VideoPlayer";
import { useVideoEditorContext } from "../VideoEditorContext";

const VideoView = () => {
  const { canvas, selectedTimeline } = useVideoEditorContext();

  const hasVideos = useMemo(
    () => selectedTimeline.rows.find((row) => row.type === "video").actions.length > 0,
    [selectedTimeline]
  );

  return (
    <div
      id="canvasbg"
      style={{
        // to fix aspectRatio and remove width 100%
        // aspectRatio: `${canvas.width}/${canvas.height}`,
        width: "100%",
        height: "100%",
        // maxHeight: "100%",
        // maxWidth: "100%",
        minWidth: 0,
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        margin: "auto",
      }}
    >
      {hasVideos && <VideoPlayer />}
      {/* <Canvas /> */}
    </div>
  );
};

export default VideoView;
