import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import { Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";

import { IFile, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import InsertImageIcon from "../../../../../../icons/InsertImageIcon";
import InsertImageDialog from "../../../../InsertImageDialog/InsertImageDialog";
import MUndoSnackbarContent from "../../../../../../components/MUndoSnackbarContent";

const useStyles = makeStyles((theme: Theme) => ({
  imageWrapper: {
    width: "100%",
    height: 180,
    backgroundColor: "#EEF2F9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: 10,
    position: "relative",
    "&:hover": {
      border: `2px solid ${theme.palette.primary.main}`,
      padding: 8,
      "& img": {
        opacity: 0.5,
      },
    },
  },
  hoverOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    zIndex: 1,
    cursor: "default",
    "& p": {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightRegular as any,
      color: theme.palette.common.white,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    "& svg": {
      color: theme.palette.common.white,
      fontSize: 6,
    },
  },
  insertImage: {
    "&:hover": {
      opacity: 1,
      "& svg": {
        fill: theme.palette.primary.main,
      },
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

type PropTypes = {
  website: IWebsite;
  image?: IFile;
  onChange: (image: IFile) => void;
  onRemove: () => void;
  onUndo?: () => void;
};

const ImageItem = (props: PropTypes) => {
  const classes = useStyles();
  const { image, website, onChange, onRemove, onUndo } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const url = image?.cdnUrl || image?.url;

  return (
    <>
      <div
        className={classNames(classes.imageWrapper, !url && classes.insertImage)}
        onClick={() => setDialogOpen(true)}
        onMouseEnter={() => url && setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover && url && (
          <div className={classes.hoverOverlay}>
            <Typography
              variant="body1"
              onClick={(e) => {
                e.stopPropagation();
                setDialogOpen(true);
              }}
            >
              {I18n.t("product_dialog.images_view.replace")}
            </Typography>
            <FiberManualRecordIcon />
            <Typography
              variant="body1"
              onClick={(e) => {
                e.stopPropagation();
                if (onUndo) {
                  enqueueSnackbar("", {
                    content: (key) => (
                      <MUndoSnackbarContent
                        id={key}
                        message={I18n.t("product_dialog.images_view.item_deleted")}
                        onUndo={onUndo}
                      />
                    ),
                  });
                }
                onRemove();
              }}
            >
              {I18n.t("product_dialog.images_view.delete")}
            </Typography>
          </div>
        )}
        {url ? <img src={url} alt={image.alt} className={classes.image} /> : <InsertImageIcon />}
      </div>
      {dialogOpen && (
        <InsertImageDialog
          website={website}
          handleCloseClick={() => setDialogOpen(false)}
          handleInsertClick={(file) => {
            onChange(file);
            setDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ImageItem;
