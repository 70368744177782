import React, { useCallback, useState } from "react";
import { Divider } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import isHotkey from "is-hotkey";
import ClassNames from "classnames";
import { createEditor } from "slate";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { HistoryEditor } from "slate-history/dist/history-editor";

import link from "../link/link";
import { DEV } from "../../../reducers/constants/consts";
import { withBulletList } from "../bulletedList/BulletedListElement";
import LiteEditorToolbar from "./LiteEditorToolbar";
import RichTextEditorHelper from "../../helper/richTextEditorHelper";
import LiteRenderElement from "./LiteRenderElement";
import LiteRenderLeaf from "./LiteRenderLeaf";
import { editorType } from "../../types/editor.Types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editable: {
      fontSize: (props: PropTypes) => theme.typography.pxToRem(props.fontSize || 18),
      color: theme.palette.text.primary,
      lineHeight: 1.55,
      padding: (props: PropTypes) => props.padding || "0px 15px 15px 15px",
      minHeight: "200px !important",
      fontWeight: theme.typography.fontWeightLight as any,
      overflowY: "hidden",
      backgroundColor: theme.palette.secondary.dark,
    },
    button: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    image: {},
    quotes: {
      borderLeft: "14px solid #9fb5d0",
      margin: "1.5em 10px",
      padding: "0.5em 10px",
      quotes: '"\\201C""\\201D""\\2018""\\2019"',
      "&:before": {
        color: "#ccc",
        content: "open-quote",
        fontSize: "4em",
        lineHeight: "0.1em",
        marginRight: "0.25em",
        verticalAlign: "-0.4em",
      },
      "& p": {
        display: "inline",
      },
    },
    h2Text: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightBold as any,
      lineHeight: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(15),
      "&:not(:first-child)": {
        marginTop: theme.typography.pxToRem(35),
      },
    },
    h3Text: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold as any,
      lineHeight: theme.typography.pxToRem(26),
      marginBottom: theme.typography.pxToRem(10),
      "&:not(:first-child)": {
        marginTop: theme.typography.pxToRem(25),
      },
    },
    paragraph: {
      fontWeight: theme.typography.fontWeightLight as any,
      fontSize: (props: PropTypes) => theme.typography.pxToRem(props.fontSize || 16),
      lineHeight: theme.typography.pxToRem(26),
      marginBottom: theme.typography.pxToRem(0),
      marginTop: 10,
      wordBreak: "break-word",
    },
    titleTextField: {
      fontSize: theme.typography.pxToRem(30),
      lineHeight: theme.typography.pxToRem(36),
      fontWeight: theme.typography.fontWeightBold as any,
      // minHeight: 80,
      paddingLeft: 25,
      paddingRight: 25,
      margin: "10px 0px 0px 0px",
    },
    titleTypography: {
      fontSize: theme.typography.pxToRem(30),
      lineHeight: theme.typography.pxToRem(36),
      fontWeight: theme.typography.fontWeightBold as any,
      paddingLeft: 25,
      paddingTop: 25,
      paddingRight: 25,
    },
    imageDetailsWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    imageSizeWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    sizeField: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold as any,
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
    tableHead: {
      backgroundColor: "#F5F6F8",
      border: "1px double black",
      height: "50px",
      fontSize: 14,
      padding: "25px 0 25px 20px",
    },
    tableCell: {
      border: "1px solid black",
      height: "50px",
      fontSize: 14,
      padding: "25px 0 25px 20px",
    },
  })
);

type PropTypes = {
  initialValue: any;
  editorPlaceHolder?: string;
  editorToolbarPostion?: "top" | "bottom" | "none";
  toolbarCustomClass?: string;
  editableCustomClass?: string;
  fontSize?: number;
  padding?: string;
  children?: React.ReactChild;
  elements?: editorType[];
  onValueChanged: (value) => void;
};

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
const HOTKEYS_BLOCK = {
  "mod+k": "link",
};

const emptyParagraph = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const LiteRichTextEditor = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    fontSize,
    initialValue = null,
    toolbarCustomClass,
    editorPlaceHolder,
    editableCustomClass,
    editorToolbarPostion = "bottom",
    children = null,
    elements,
    onValueChanged,
  } = props;

  const [value, setValue] = React.useState(JSON.parse(initialValue || null) || emptyParagraph);

  const renderElement = useCallback(
    (props) => <LiteRenderElement products={[]} placeholder={editorPlaceHolder} fontSize={fontSize} {...props} />,
    []
  );

  const renderLeaf = useCallback((props) => <LiteRenderLeaf fontSize={fontSize} {...props} />, []);
  const [editor]: ReactEditor = useState(
    () => withBulletList(link.withLinks(withHistory(withReact(createEditor())))) as ReactEditor,
    []
  );

  const handleChange = (newValue: any) => {
    setValue(newValue);
    const isAstChange = editor.operations.some((op) => op.type !== "set_selection");

    if (isAstChange) {
      console.log("handleValueChange", newValue);
      if (DEV) {
        const { undos, redos } = (editor as HistoryEditor).history;
        console.log("undos", undos);
        console.log("redos", redos);
        console.log("editor.operations", editor.operations);
      }
      onValueChanged(JSON.stringify(newValue));
    }
  };

  const onKeyDown = (event) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        RichTextEditorHelper.toggleMark(editor, mark);
      }
    }
    for (const hotkey in HOTKEYS_BLOCK) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        // console.log('link block');
        const block = HOTKEYS[hotkey];
        RichTextEditorHelper.toggleBlock(editor, block);
      }
    }

    if (event.key === "Enter" && event.shiftKey === true) {
      event.preventDefault();
      editor.insertText("\n");
    }
    if (event.key === "Tab") {
      event.preventDefault();
      editor.insertText("\t");
    }
  };

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      {editorToolbarPostion === "top" && (
        <>
          <LiteEditorToolbar toolbarCustomClass={toolbarCustomClass} elements={elements} />
          <Divider />
        </>
      )}
      {children}
      <Editable
        id="slate-editor"
        className={ClassNames(classes.editable, editableCustomClass)}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        // placeholder={editorPlaceHolder || I18n.t('rich_text_editor.body_placeholder')}
        spellCheck
        autoFocus={false}
        onKeyDown={onKeyDown}
      />
      {editorToolbarPostion === "bottom" && (
        <>
          <Divider />
          <LiteEditorToolbar toolbarCustomClass={toolbarCustomClass} elements={elements} />
        </>
      )}
    </Slate>
  );
};

export default React.memo(LiteRichTextEditor);
