import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import { useSnackbar } from "notistack";
import CfButton from "../../../components/CfButton";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";
import { useState } from "react";
import agent from "../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderWidth: 0,
    padding: 30,
    maxHeight: 400,
    height: "100%",
  },
  titleWrapper: {
    height: 114,
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
  },
  titleAndCloseIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    wordBreak: "break-word",
    maxWidth: "80%",
  },
  error: {
    color: "red",
    fontSize: theme.typography.pxToRem(12),
  },
  dialogContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  column: {
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginRight: 20,
    "&:last-child": {
      marginRight: 0,
    },
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: "30px 0 0 0",
  },
  countersAndButton: {
    display: "flex",
    alignItems: "center",
  },
  counter: {
    marginRight: 10,
  },
  counterText: {
    marginLeft: 5,
  },
  closeButton: {
    padding: 0,
  },
  columnTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 5,
  },
  columnTitleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  columnText: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: 10,
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(26),
    color: theme.palette.text.secondary,
  },
  textField: {
    height: "100%",
  },
  inputTextField: {
    padding: 10,
  },
  button: {
    marginLeft: 5,
  },
  hidden: {
    visibility: "hidden",
  },
  closableRow: {
    width: "100%",
    display: "flex",
  },
  row: {
    width: "100%",
    display: "flex",
    marginBottom: 10,
  },
  queryTextField: {
    width: "40%",
  },
  select: {
    width: "20%",
    textTransform: "capitalize",
    marginRight: 10,
  },
  queryCloseIcon: {
    width: "3%",
    cursor: "pointer",
    marginTop: 5,
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  title: {
    width: 260,
    fontSize: theme.typography.pxToRem(14),
    marginRight: 15,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  headersRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  firstHeader: {
    width: "40%",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  lastHeader: {
    width: "3%",
  },
  header: {
    width: "20%",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  open: boolean;
  selectedWebsite: IWebsite;
  handleCloseClick: () => void;
  handleLoadKeywords?: () => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const NgramGeneratorDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, selectedWebsite, handleLoadKeywords, handleCloseClick } = props;
  const { enqueueSnackbar } = useSnackbar();

  const ngramValues = [
    {
      name: "Unigram (1)",
      value: 1,
    },
    {
      name: "Bigram (2)",
      value: 2,
    },
    {
      name: "Trigram (3)",
      value: 3,
    },
    {
      name: "N-gram (4)",
      value: 4,
    },
  ];

  const [ngram, setNgram] = useState(ngramValues[0]);
  const generateNgrams = () => {
    const ngramValue = ngram.value;

    agent.KeywordsClassification.generateNgrams(selectedWebsite._id, ngramValue)
      .then(() => {
        handleLoadKeywords();
        enqueueSnackbar("Generated ngrams for website");
      })
      .catch((e) => {
        console.log(e);
      });

    enqueueSnackbar(`Started the job to generate ${ngram.name}`);
    setNgram(ngramValues[0]);
    handleCloseClick();
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      onClose={handleCloseClick}
      fullWidth
      maxWidth={"lg"}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.titleWrapper}>
        <div className={classes.titleAndCloseIcon}>
          <Typography variant={"subtitle1"}>{I18n.t("classify_keywords.ngram_dialog.title")}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseClick}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <Typography className={classes.subtitle} variant={"body1"}>
          {I18n.t("classify_keywords.ngram_dialog.description")}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.headersRow}>
          <MSelect
            customClass={classes.select}
            options={ngramValues}
            value={ngram}
            optionValue={"value"}
            optionLabel={"name"}
            height={37}
            placeholder={I18n.t("classify_keywords.dialog.select_ph")}
            borderWidth={1}
            borderRadius={4}
            isBold={false}
            textColor={"#333333"}
            handleChange={(value) => {
              setNgram(value);
            }}
          />
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <CfButton
          height={40}
          text={I18n.t("classify_keywords.ngram_dialog.generate")}
          customClass={classes.button}
          onClick={(e) => {
            e.preventDefault();
            generateNgrams();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
export default connect(mapStateToProps, null)(NgramGeneratorDialog);
