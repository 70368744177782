import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#fff",
    fontSize: 16,
  },
}));

type PropTypes = {
  className?: string;
};

const ItalicIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 12 13"}>
      <path d="M1.94585 13C1.62873 13 1.36447 12.8867 1.15305 12.6602C0.941643 12.4342 0.835938 12.1635 0.835938 11.8481C0.835938 11.519 0.941643 11.2447 1.15305 11.0253C1.36447 10.8059 1.62873 10.6962 1.94585 10.6962H3.51161L6.74224 2.3038H5.17648C4.85936 2.3038 4.5951 2.19053 4.38369 1.96399C4.17227 1.738 4.06657 1.4673 4.06657 1.1519C4.06657 0.822785 4.17227 0.548523 4.38369 0.329114C4.5951 0.109705 4.85936 0 5.17648 0H10.726C11.0299 0 11.291 0.112996 11.5093 0.338987C11.7271 0.565528 11.8359 0.836498 11.8359 1.1519C11.8359 1.4673 11.7271 1.738 11.5093 1.96399C11.291 2.19053 11.0299 2.3038 10.726 2.3038H9.16026L5.90981 10.6962H7.47558C7.77948 10.6962 8.04057 10.8095 8.25886 11.036C8.47661 11.262 8.58549 11.5327 8.58549 11.8481C8.58549 12.1635 8.47661 12.4342 8.25886 12.6602C8.04057 12.8867 7.77948 13 7.47558 13H1.94585Z" />
    </SvgIcon>
  );
};

export default ItalicIcon;
