import * as React from "react";
import _ from "lodash";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import { I18n } from "react-redux-i18n";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Tooltip from "@material-ui/core/Tooltip";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import agent from "../../../agent";
import { ePageType, IAdminUser, IWebsite } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";
import { web } from "../../../helpers/urlHelper";
import PageEditorMissingBioTooltip from "./PageEditorMissingBioTooltip";

const useStyles = makeStyles((theme: Theme) => ({
  writersWrapper: {
    width: "100%",
  },
  writerWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 3,
  },
  writerTitle: {
    minWidth: 80,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 7,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  writer: IAdminUser;
  postType: ePageType | string;
  locale?: string;
  creditedWriter?: boolean;
  canEdit?: boolean;

  handleWriterSelect: (writer) => void;
};

const PageEditorWriter = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    writer,
    postType,
    selectedWebsite,
    canEdit = true,
    locale = "en",
    creditedWriter = false,
    handleWriterSelect,
  } = props;

  const [selectedWriter, setSelectedWriter] = useState(null);
  const [writers, setWriters] = useState([]);
  const theme = useTheme<Theme>();

  React.useEffect(() => {
    setSelectedWriter(writer);
  }, [writer]);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    agent.Writers.getWritersV2(selectedWebsite, postType, locale, creditedWriter)
      .then((res) => {
        setWriters(res.writers);
      })
      .catch((e) => {
        // show error message
      });
  }, [selectedWebsite, postType, locale, creditedWriter]);

  const selectableWriters = creditedWriter
    ? writer
      ? _.uniqBy([...writers, writer], "_id")
      : writers
    : [{ email: "All", name: "Any Writer" }, ...writers];
  const [error, setError] = useState(false);
  return (
    <div className={classes.writerWrapper}>
      <Typography variant={"body1"} className={classes.writerTitle}>
        {I18n.t(`edit_post.${creditedWriter ? "credited_writer" : "writer"}`)}
      </Typography>
      <div className={classes.writersWrapper}>
        <MSelect
          disabled={!canEdit}
          customClass={classes.selectCustomClass}
          options={selectableWriters}
          value={_.find(
            selectableWriters,
            creditedWriter ? { email: writer?.email || null } : { email: writer?.email || "All" }
          )}
          optionLabel={"name"}
          optionValue={"_id"}
          height={31}
          menuPlacement={"bottom"}
          isBold={false}
          borderWidth={error ? "1px" : "0px"}
          borderColor={error ? "#F04444" : null}
          textColor={error ? "#F04444" : theme.palette.text.primary}
          singleValueStyles={{
            fontSize: theme.typography.pxToRem(12),
            color: error ? "#F04444" : theme.palette.text.primary,
          }}
          fullWidth
          searchable
          getDisplayLabel={(obj: IAdminUser) => `${obj.name}`}
          handleChange={(selected: IAdminUser) => {
            if (selected.email === "All") {
              setSelectedWriter(null);
              handleWriterSelect(null);
              return;
            }
            setSelectedWriter(selected);
            handleWriterSelect(selected);
          }}
        />
      </div>
      <PageEditorMissingBioTooltip selectedWebsite={selectedWebsite} user={selectedWriter} onBioMissing={setError} />
    </div>
  );
};

export default PageEditorWriter;
