import filter from "lodash/filter";
import _ from "lodash";
import { EDIT_TOPIC } from "./constants/actionTypes";

const initialState = {
  topicLoaded: false,
  topic: null,
  topicType: null,

  keywords: [],
  keywordsSearchField: "",

  selectedTab: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_TOPIC.CLEANUP:
      return initialState;
    case EDIT_TOPIC.TOPIC_LOADED:
      return {
        ...state,
        topicLoaded: true,
        topic: action.topic,
        topicType: action.topicType,
      };
    case EDIT_TOPIC.TOPIC_CHANGED:
      return {
        ...state,
        topic: action.topic,
      };
    case EDIT_TOPIC.TAB_CHANGED:
      return {
        ...state,
        selectedTab: action.tab,
      };

    case EDIT_TOPIC.ON_KEYWORDS_LOADED:
      return {
        ...state,
        keywords: action.payload.keywords,
      };
    case EDIT_TOPIC.ON_KEYWORD_SELECTED:
      return {
        ...state,
        topic: {
          ...state.topic,
          keywords: [...state.topic.keywords, action.keyword],
        },
      };
    case EDIT_TOPIC.ON_KEYWORD_REMOVED:
      return {
        ...state,
        topic: {
          ...state.topic,
          keywords: filter(state.topic.keywords, (keyword) => keyword.name !== action.keyword.name),
        },
      };
    case EDIT_TOPIC.ON_KEYWORDS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        keywordsSearchField: action.input,
      };
    case EDIT_TOPIC.ON_NEW_KEYWORD_CREATED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        keywords: _.uniqBy([...state.keywords, action.payload.keyword], "_id").sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        keywordsSearchField: "",
        topic: {
          ...state.topic,
          keywords: _.uniqBy([...state.topic.keywords, action.payload.keyword], "_id"),
        },
      };

    default:
      return state;
  }
};
