import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 18,
    height: 19,
    fill: "none",
  },
}));

type PropTypes = {
  className?: string;
};

const BorderAllSidesIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 18 19"}>
      <path
        d="M17.5 10.2727L17.5 14C17.5 16.2091 15.7091 18 13.5 18L9.7727 18"
        stroke="#4353FF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.500022 8.72729L0.500022 5.00003C0.500022 2.79089 2.29088 1.00002 4.50002 1.00002L8.2273 1.00002"
        stroke="#4353FF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.77271 1.00002L13.5 1.00002C15.7091 1.00002 17.5 2.79088 17.5 5.00002V8.72729"
        stroke="#4353FF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.22729 18L4.50002 18C2.29088 18 0.500022 16.2091 0.500022 14L0.500023 10.2727"
        stroke="#4353FF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default BorderAllSidesIcon;
