import * as React from "react";
import { I18n } from "react-redux-i18n";
import FontSizeCSS from "../CssComponents/FontSizeCSS";
import FontColorCSS from "../CssComponents/FontColorCSS";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import StylePanelSubSection from "../components/StylePanelSubSection";

type PropTypes = {
  title?: string;
  handleChange: any;
  element: any;
  theme: IWebsiteThemeProps;
};

const TitleElement = (props: PropTypes) => {
  const { title = I18n.t("rich_text_editor.title"), handleChange, theme, element } = props;
  return (
    <StylePanelSubSection sectionTitle={title}>
      <FontSizeCSS
        value={element.data?.titleCss?.fontSize || undefined}
        defaultValue={theme.typography.subtitle1.css.fontSize}
        handleChange={(fontSize) => {
          handleChange({ ...element.data, titleCss: { ...element.data?.titleCss, fontSize } });
        }}
      />
      <FontColorCSS
        title={I18n.t("rich_text_editor.product_embed.text_color")}
        value={element.data?.titleCss?.color || theme.palette.text.primary}
        defaultValue={theme.palette.text.primary}
        isValueChanged={false}
        handleChange={(color) => {
          handleChange({ ...element.data, titleCss: { ...element.data?.titleCss, color } });
        }}
      />
    </StylePanelSubSection>
  );
};

export default TitleElement;
