import { Editor, Range, Transforms, Path, Element, Node } from "slate";
import { ReactEditor } from "slate-react";
import { editorType } from "../../types/editor.Types";

const withLinks = (editor: Editor): Editor => {
  const { insertData, insertText, isInline, normalizeNode } = editor;

  editor.isInline = (element) => (element.type === editorType.link ? true : isInline(element));

  // editor.insertText = text => {
  //   console.log('withLinks insertText', text);
  //   if (text && isUrl(text)) {
  //     wrapLink(editor, { text, url: text, newTab: true });
  //   } else {
  //     insertText(text);
  //   }
  // };
  //
  // editor.insertData = data => {
  //   const text = data.getData('text/plain');
  //   console.log('withLinks insertData', text);
  //
  //   if (text && isUrl(text)) {
  //     wrapLink(editor, { text, url: text, newTab: true });
  //   } else {
  //     insertData(data);
  //   }
  // };

  // https://stackoverflow.com/questions/68511348/unable-to-delete-a-link-clearly-in-slate-js-editor-using-the-official-example
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && node.type) {
      const children = Array.from(Node.children(editor, path));
      for (const [child, childPath] of children) {
        // remove link nodes whose text value is empty string.
        // empty text links happen when you move from link to next line or delete link line.
        if (
          Element.isElement(child) &&
          child.type === editorType.link &&
          child.children?.filter((c) => c.text !== "")?.length === 0
        ) {
          if (children.length === 1) {
            Transforms.removeNodes(editor, { at: path });
            Transforms.insertNodes(editor, {
              type: editorType.paragraph,
              children: [{ text: "" }],
            });
          } else {
            Transforms.removeNodes(editor, { at: childPath });
          }
          return;
        }
      }
    }
    normalizeNode(entry);
  };

  return editor;
};

const insertLink = (editor, urlObj) => {
  console.log("insertLink", urlObj);
  // if (editor.selection) {
  //   console.log('wrap link');
  wrapLink(editor, urlObj);
  // }
};

const removeLink = (editor) => {
  console.log("removeLink");
  unwrapLink(editor);
};

const linkChanged = (editor, urlObj) => {
  Transforms.setNodes(editor, urlObj, {
    match: (n) => n.type === editorType.link,
  });
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === editorType.link });
  return !!link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === editorType.link });
};

const wrapLink = (editor, urlObj) => {
  // if (isLinkActive(editor)) {
  //   console.log('wrapLink: unwrapLink');
  //   unwrapLink(editor);
  // }

  const { selection } = editor;
  const link = {
    type: "link",
    url: urlObj.url,
    newTab: urlObj.newTab,
    linkType: urlObj.linkType,
    children: [{ text: urlObj.text || "New Link" }],
    rel: urlObj.rel,
  };
  console.log("link", link);
  ReactEditor.focus(editor);
  if (selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path);

    // Remove the Link node if we're inserting a new link node inside of another
    // link.
    if (parentNode.type === editorType.link) {
      console.log("wrapLink", "remove");
      removeLink(editor);
    }

    console.log("wrapLink: selection=", selection);

    if (editor.isVoid(parentNode)) {
      console.log("wrapLink: void");
      // Insert the new link after the void node
      Transforms.insertNodes(
        editor,
        { type: editorType.paragraph, children: [link] },
        {
          at: Path.next(parentPath),
          select: true,
        }
      );
    } else if (Range.isCollapsed(selection)) {
      console.log("wrapLink: collapsed");
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      console.log("wrapLink: else");
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  } else {
    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    console.log("wrapLink: no selection");
    Transforms.insertNodes(editor, { type: editorType.paragraph, children: [link] });
  }
};

export default {
  withLinks,
  insertLink,
  removeLink,
  linkChanged,
  isLinkActive,
};
