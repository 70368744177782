import { useEffect } from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Editor, Transforms } from "slate";
import richTextEditorHelper from "../../../helper/richTextEditorHelper";
import { emitEvent } from "../../../../hooks/useEvents";

type PropTypes = {
  element: any;
};

const useFaqWidgetElement = (props: PropTypes) => {
  const { element } = props;
  const editor = useSlateStatic();

  const forceStyleEditorLoad = () => {
    const elementPath = ReactEditor.findPath(editor as ReactEditor, element);
    ReactEditor.focus(editor as ReactEditor);
    Transforms.select(editor, Editor.start(editor, elementPath));
    emitEvent("edit-design", { element, selected: true, open: true });
  };

  const handleChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const getNewFaqObject = (faq) => {
    const isRichText = (text) => {
      try {
        const parsedRichText = JSON.parse(text);
        return parsedRichText[0] && parsedRichText[0].type === "paragraph";
      } catch (e) {
        return false;
      }
    };

    if (isRichText(faq.answer)) return { question: faq.question, answer: faq.answer };

    return { question: faq.question, answer: JSON.stringify(richTextEditorHelper.convertToRich(faq.answer || "")) };
  };

  const convertOldFaqListFormat = () => {
    if (element.data.formatted) return;

    const isNewElement = !element.data?.list && !element.data?.faqList;

    const data = {
      ...element.data,
      list: (element.data?.list || []).map(getNewFaqObject),
      title:
        (typeof element.data.title === "object" ? element.data.title?.title : element.data?.title) ||
        element.data?.faqTitle,
      googleSchemaMarkup: isNewElement,
    };

    if (data?.faqList) {
      data.list = [...data.list, ...data.faqList.map(getNewFaqObject)];
    }

    handleChange({ ...data, formatted: true });
    if (isNewElement) forceStyleEditorLoad();
  };

  useEffect(() => {
    convertOldFaqListFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default useFaqWidgetElement;
