import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import {
  IWidgetFieldProperties,
  IWidgetProductScoreFieldProps,
} from "../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "./components/GenericValueItemProperty";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

type PropTypes = {
  properties: IWidgetFieldProperties;
  fieldType: string;
  onChange: (properties: IWidgetProductScoreFieldProps) => void;
};

const ProductScoreWidgetItem = (props: PropTypes) => {
  const { onChange } = props;
  const properties = props.properties as IWidgetProductScoreFieldProps;
  const { value = "", labelText = "" } = properties;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <GenericValueItemProperty
        onChange={({ value: newValue }) => onChange({ ...properties, value: newValue })}
        value={value}
        label={I18n.t(`widgets_builder.widget_item_selector.set_product_score`)}
        labelOutsideInput
        numbersOnly
        max={10}
        step={0.1}
      />

      <GenericValueItemProperty
        onChange={({ value: newValue }) => onChange({ ...properties, labelText: newValue })}
        value={labelText}
        label={I18n.t(`widgets_builder.widget_item_selector.score_text`)}
        labelOutsideInput
      />
    </div>
  );
};

export default ProductScoreWidgetItem;
