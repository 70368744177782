import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { WIDGET_BUILDER } from "../../../../../../../../../reducers/constants/actionTypes";
import { IWidgetSettings } from "../../../../../../../../../reducers/constants/objectTypes";
import Layout from "./Layout";
import useDebounce from "../../../../../../../../../hooks/useDebounce";
import Content from "./Content";
import SwitchOptions from "./SwitchOptions";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
  },
}));

type PropTypes = {
  settings: IWidgetSettings;
  editSettings: (settings: IWidgetSettings) => void;
};

const mapStateToProps = (state) => ({
  settings: state.widgetBuilder.widget.settings,
});

const mapDispatchToProps = (dispatch) => ({
  editSettings: (payload: IWidgetSettings) => dispatch({ type: WIDGET_BUILDER.EDIT_SETTINGS, payload }),
});

const defaultLayoutValues: IWidgetSettings["layout"] = {
  type: "modal",
  position: "center",
  width: 700,
  verticalOffset: 0,
  horizontalOffset: 0,
  alignment: "center",
  padding: 10,
  spacing: 10,
  showCloseButton: true,
  showBackdrop: true,
  shadowRoot: true,
};

const LayoutSettings = (props: PropTypes) => {
  const { settings } = props;
  const { editSettings } = props;
  const layoutProp = settings.layout ?? {};

  const [layout, setLayout] = useState({ ...defaultLayoutValues, ...layoutProp });

  const debouncedLayoutValue = useDebounce(layout, 500);

  React.useEffect(() => {
    if (!isEqual(settings.layout, debouncedLayoutValue)) {
      editSettings({ ...settings, layout: debouncedLayoutValue });
    }
  }, [debouncedLayoutValue, editSettings, settings]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Layout layout={layout} onChange={(field) => setLayout({ ...layout, ...field })} />
      <Content layout={layout} onChange={(field) => setLayout({ ...layout, ...field })} />
      <SwitchOptions layout={layout} onChange={(field) => setLayout({ ...layout, ...field })} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSettings);
