import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import CfButton from "../../components/CfButton";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import CroGoogleSignup from "./components/CroGoogleSignup";
import sideImage from "../../img/signup_side.png";
import EntailNavbarLogo from "../../icons/EntailNavbarLogo";
import FlowTextField from "./OnboardingFlow/components/FlowTextField";
import SignupDetails from "./SignupDetail";
import validator from "validator";
import agent from "../../agent";
import { replace } from "connected-react-router";
import { HOME } from "../../reducers/constants/actionTypes";
import { connect } from "react-redux";
import { MIXPANEL_PAGE_VIEW } from "../../reducers/middlewares/mixpanelMiddleware";
import { eMpEvent } from "../../reducers/constants/objectTypes";
import { useMediaQuery } from "@material-ui/core";
import theme from "../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      margin: 0,
      height: "unset",
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    padding: 70,
    [theme.breakpoints.down("md")]: {
      padding: "70px 30px",
      margin: 0,
      width: "100%",
      height: "unset",
      justifyContent: "start",
    },
  },
  imageWrapper: {
    background: "linear-gradient(90deg, #574AC1 0%, #2C2093 100%)",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  dividerWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100%",
    margin: "30px 0",
    [theme.breakpoints.down("md")]: {
      gap: 10,
      margin: "20px 0",
    },
  },
  divider: {
    color: "black",
    height: 1,
    width: 170,
    backgroundColor: "#BCBCBC",
    [theme.breakpoints.down("md")]: {
      width: "unset",
      flex: 1,
    },
  },
  dividerText: {
    fontSize: 18,
    lineHeight: "20px",
    color: theme.palette.text.primary,
  },
  credentialsWrapper: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginBottom: 20,
  },
  button: {
    minWidth: 200,
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: theme.typography.fontWeightBold as any,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: 20,
      lineHeight: "normal",
      padding: "10px 40px",
    },
  },

  signUpSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
  },
  noAccountText: {
    fontWeight: theme.typography.fontWeightMedium as any,
    fontSize: 14,
    lineHeight: "20px",
    color: "#747474",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular as any,
      lineHeight: "23px",
    },
  },
  routerLink: {
    textDecoration: "none",
  },
  loginText: {
    fontWeight: theme.typography.fontWeightMedium as any,
    fontSize: 14,
    marginLeft: 10,
    lineHeight: "24px",
    color: theme.palette.primary.main,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular as any,
      lineHeight: "23px",
    },
  },
  terms: {
    marginTop: 30,
    textAlign: "center",
    maxWidth: 450,
    width: 365,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: 10,
      maxWidth: "100%",
    },
  },
  termsText: {
    fontWeight: theme.typography.fontWeightMedium as any,
    fontSize: 14,
    lineHeight: "20px",
    color: "#747474",
    padding: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      lineHeight: "23px",
      fontWeight: theme.typography.fontWeightRegular as any,
    },
  },
  termsLink: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    margin: "0 5px",
    textDecoration: "underline",
  },
  entailNavbarLogo: {
    position: "absolute",
    left: 70,
    top: 70,
    bottom: 50,
    [theme.breakpoints.down("md")]: {
      position: "unset",
    },
  },
  centeredContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 110,
    [theme.breakpoints.down("md")]: {
      marginTop: 70,
      textAlign: "center",
    },
  },
  welcomeText: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 30,
    color: theme.palette.common.black,
    lineHeight: "normal",
    marginBottom: 15,
    [theme.breakpoints.down("md")]: {
      marginBottom: 20,
      fontSize: 28,
    },
  },
  startFree: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.common.black,
    lineHeight: "20px",
    marginBottom: 50,
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      lineHeight: "normal",
    },
  },
  buttonTermsWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      gap: 20,
    },
  },
}));

type PropTypes = {
  pageLoaded: Function;
  mpEvent: (event: string, props: object) => void;
  onLogin: Function;
  onAdminUserLoaded: (payload) => void;
  adminUserLoadFailed: () => void;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  pageLoaded: () =>
    dispatch({
      type: HOME.PAGE_LOADED,
      meta: { mixpanel: { event: MIXPANEL_PAGE_VIEW, props: { "page-name": "signup" } } },
    }),
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
  onLogin: (redirect) => dispatch(replace(redirect)),
  onAdminUserLoaded: (payload) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: payload.user }),
  adminUserLoadFailed: () => dispatch({ type: HOME.ADMIN_USER_LOADED_FAILED }),
});

const SignUp = (props: PropTypes) => {
  const classes = useStyles(props);
  const { mpEvent, pageLoaded, onLogin, adminUserLoadFailed } = props;
  const [email, setEmail] = React.useState("");
  const [isContinue, setIsContinue] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    pageLoaded();
    agent.Auth.getUser()
      .then((res) => {
        onLogin("/");
      })
      .catch((e) => {
        // adminUserLoadFailed();
        console.log(e);
      });
  }, []);

  const handleContinueClick = () => {
    mpEvent(eMpEvent.websiteButtonClick, { email, type: "email", text: I18n.t("signup.continue") });
    if (!validator.isEmail(email)) {
      mpEvent(eMpEvent.signupError, { email, error: "invalid email" });
      setEmailError(true);
      return;
    }
    mpEvent(eMpEvent.signupSuccess, { email, type: "email", text: I18n.t("signup.continue") });

    setIsContinue(true);
  };

  if (isContinue) return <SignupDetails email={email} />;

  return (
    <section className={classes.pageWrapper}>
      <div className={classes.contentWrapper}>
        <EntailNavbarLogo className={classes.entailNavbarLogo} />
        <div className={classes.centeredContent}>
          <Typography className={classes.welcomeText} variant={"h2"}>
            {I18n.t("signup.welcome")}
          </Typography>
          <Typography className={classes.startFree} variant={"subtitle1"}>
            {I18n.t("signup.welcome_sub_header")}
          </Typography>
          <CroGoogleSignup isMobile={isMobile} />
          <div className={classes.dividerWrapper}>
            <Divider className={classes.divider} orientation={"horizontal"} />
            <Typography variant={"body2"} className={classes.dividerText}>
              {I18n.t("login.or")}
            </Typography>
            <Divider className={classes.divider} orientation={"horizontal"} />
          </div>
          <div className={classes.credentialsWrapper}>
            <FlowTextField
              type={"email"}
              error={emailError}
              placeholder={I18n.t("login.email_address")}
              errorMessage={I18n.t("signup.email_address_error")}
              value={email}
              onChange={(value) => {
                setEmail(value);
                if (emailError) setEmailError(false);
              }}
            />
          </div>
          <div className={classes.buttonTermsWrapper}>
            <CfButton
              fullWidth
              customClass={classes.button}
              text={I18n.t("signup.continue")}
              onClick={(e) => handleContinueClick()}
            />
            <div className={classes.terms}>
              <Typography variant={"body2"} className={classes.termsText}>
                {I18n.t("signup.terms_first_part")}
                <a
                  onClick={() =>
                    mpEvent(eMpEvent.websiteLinkClick, {
                      page: I18n.t("signup.terms_first_part"),
                      href: "https://entail.ai/app-terms-of-service",
                    })
                  }
                  href={"https://entail.ai/app-terms-of-service"}
                  target={"_blank"}
                  className={classes.termsLink}
                >
                  {I18n.t("signup.terms")}
                </a>
                {I18n.t("login.and")}
                <a
                  onClick={() =>
                    mpEvent(eMpEvent.websiteLinkClick, {
                      page: I18n.t("login.privacy_policy"),
                      href: "https://entail.ai/privacy-policy",
                    })
                  }
                  href={"https://entail.ai/privacy-policy"}
                  target={"_blank"}
                  className={classes.termsLink}
                >
                  {I18n.t("login.privacy_policy")}
                </a>
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.signUpSection}>
          <Typography variant={"body2"} className={classes.noAccountText}>
            {I18n.t("login.have_account")}
          </Typography>
          <Link
            onClick={() => mpEvent(eMpEvent.websiteLinkClick, { page: I18n.t("login.login"), href: "/login" })}
            to="/login"
            className={classes.routerLink}
          >
            <Typography variant={"body2"} className={classes.loginText}>
              {I18n.t("login.login")}
            </Typography>
          </Link>
        </div>
      </div>
      <div className={classes.imageWrapper}>
        <img alt={"sign up"} src={sideImage} />
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
