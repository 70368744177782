import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function AlphabetAIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 448 512"}>
      <path d="M253.5 51.7C248.6 39.8 236.9 32 224 32s-24.6 7.8-29.5 19.7l-120 288-40 96c-6.8 16.3 .9 35 17.2 41.8s35-.9 41.8-17.2L125.3 384H322.7l31.8 76.3c6.8 16.3 25.5 24 41.8 17.2s24-25.5 17.2-41.8l-40-96-120-288zM296 320H152l72-172.8L296 320z" />
    </SvgIcon>
  );
}

export default AlphabetAIcon;
