import * as React from "react";
import { useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";

import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";

type PropTypes = {
  showStatusField?: boolean;
  totalItems: number;
  topHeader: boolean;
  sortField?: any;
  setSortField?: (input: any) => void;
};

const FootageItemListSubHeader = (props: PropTypes) => {
  const { totalItems, topHeader, sortField, showStatusField, setSortField } = props;
  const theme: any = useTheme();

  return (
    <MTableHeader>
      <MTableHeaderSortLabel
        onClick={(result) => setSortField(result)}
        columnWidth={"50%"}
        fontSize={theme.typography.pxToRem(21)}
        sortField={sortField}
        field={"title"}
      >
        {I18n.t("video_workflow.footage.headers.videos", { count: totalItems })}
      </MTableHeaderSortLabel>

      {topHeader && (
        <>
          {showStatusField && <MTableHeaderLabel> {I18n.t("in_progress.header.status")}</MTableHeaderLabel>}
          <MTableHeaderLabel>{I18n.t("video_workflow.footage.headers.comments")}</MTableHeaderLabel>
          <MTableHeaderLabel>{""}</MTableHeaderLabel>

          {/* <MSortLabel
            onClick={(result) => setSortField(result)}
            className={classes.tableHead}
            sortField={sortField}
            field={"locale"}
            text={I18n.t("video_workflow.footage.headers.locale")}
            variant={"subtitle2"}
          />
          <MSortLabel
            onClick={(result) => setSortField(result)}
            className={classes.tableHead}
            sortField={sortField}
            field={"type"}
            text={I18n.t("video_workflow.footage.headers.type")}
            variant={"subtitle2"}
          />
          <MSortLabel
            onClick={(result) => setSortField(result)}
            className={classes.tableHead}
            sortField={sortField}
            field={"category"}
            text={I18n.t("video_workflow.footage.headers.category")}
            variant={"subtitle2"}
          />
          <MSortLabel
            onClick={(result) => setSortField(result)}
            className={classes.tableHead}
            sortField={sortField}
            field={"topics"}
            text={I18n.t("video_workflow.footage.headers.topics")}
            variant={"subtitle2"}
          />
          <MSortLabel
            onClick={(result) => setSortField(result)}
            className={classes.tableHead}
            sortField={sortField}
            field={"time"}
            text={I18n.t("video_workflow.footage.headers.time")}
            variant={"subtitle2"}
          /> */}
          {/* <MSortLabel
            onClick={(result) => setSortField(result)}
            className={classes.tableHead}
            sortField={sortField}
            field={"presenter"}
            text={I18n.t("video_workflow.footage.headers.presenter")}
            variant={"subtitle2"}
          />

          <MSortLabel
            onClick={(result) => setSortField(result)}
            className={classes.tableHead}
            sortField={sortField}
            field={"interviewer"}
            text={I18n.t("video_workflow.footage.headers.interviewer")}
            variant={"subtitle2"}
          />
          <MSortLabel
            onClick={(result) => setSortField(result)}
            className={classes.tableHead}
            sortField={sortField}
            field={"vol"}
            text={I18n.t("video_workflow.footage.headers.vol")}
            variant={"subtitle2"}
          /> */}
        </>
      )}
    </MTableHeader>
  );
};

export default FootageItemListSubHeader;
