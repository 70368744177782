import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { EDIT_TOPIC } from "../../../../../reducers/constants/actionTypes";
import {
  ePostState,
  ePostType,
  ILearnTopic,
  ITag,
  ITargetedPost,
  ITopic,
  IWebsite,
} from "../../../../../reducers/constants/objectTypes";
import EditTopicInfoTabSEOKeywords from "./EditTopicInfoTabSEOKeywords";
import EditTopicInfoTabWriter from "./EditTopicInfoTabWriter";
import { AbilityContext } from "../../../../../casl/can";
import EditTopicInfoTabTopicUrl from "./EditTopicInfoTabTopicUrl";
import EditTopicInfoTabIndustryExpert from "./EditTopicInfoTabIndustryExpert";

const useStyles = makeStyles((theme: Theme) => ({
  resourcesTab: {
    paddingRight: 50,
    paddingLeft: 50,
    overflowY: "auto",
    height: "calc(100vh - 105px)",
  },
  writerWrapper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "pre",
  },
  writerTitle: {
    marginRight: 20,
  },
  writerName: {
    paddingLeft: 20,
  },
  keywordsWrapper: {
    marginTop: 50,
  },
  tagsWrapper: {
    marginTop: 50,
  },
  topicWrapper: {
    marginTop: 20,
  },
  blogPreviewImageWrapper: {
    marginTop: 20,
  },
  sourceWrapper: {
    marginTop: 50,
  },
  SEOKeywordsWrapper: {
    marginTop: 50,
  },
}));

type PropTypes = {
  topic: ILearnTopic;
  topicType: ePostType;
  selectedWebsite: IWebsite;

  handleTopicChanged: Function;
};

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,
    topic: state.editTopic.topic,
    topicType: state.editTopic.topicType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleTopicChanged: (topic) => dispatch({ type: EDIT_TOPIC.TOPIC_CHANGED, topic }),
});

const EditTopicInfoTab = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, topic, topicType } = props;
  const ability = React.useContext<any>(AbilityContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className={classes.resourcesTab}>
      {topic.state === ePostState.PUBLISHED && (
        <EditTopicInfoTabTopicUrl topic={topic} topicType={topicType} selectedWebsite={selectedWebsite} />
      )}
      {ability.can("review", `${ePostType.LEARNING_CENTER}_topic`) && (
        <div className={classes.writerWrapper}>
          <Typography variant={"body1"} className={classes.writerTitle} color={"textSecondary"}>
            {I18n.t("edit_post.writer")}
          </Typography>
          <EditTopicInfoTabWriter />
        </div>
      )}

      {/*{ability.can('editor', 'role') && (*/}
      {/*  <div className={classes.writerWrapper}>*/}
      {/*    <Typography*/}
      {/*      variant={'body1'}*/}
      {/*      className={classes.writerTitle}*/}
      {/*      color={'textSecondary'}*/}
      {/*    >*/}
      {/*      {I18n.t('edit_post.expert')}*/}
      {/*    </Typography>*/}
      {/*    <EditTopicInfoTabIndustryExpert />*/}
      {/*  </div>*/}
      {/*)}*/}

      <div className={classes.SEOKeywordsWrapper}>
        <EditTopicInfoTabSEOKeywords />
      </div>

      {/* <div className={classes.keywordsWrapper}> */}
      {/*  <MPanelSuggestedKeywords */}
      {/*    horizontal */}
      {/*    selectedWebsite={selectedWebsite} */}
      {/*    handleKeywordSelected={(keyword) => copyStringToClipboard(keyword.keyword, () => enqueueSnackbar(I18n.t('snackbar.copied_to_clipboard')))} */}
      {/*  /> */}
      {/* </div> */}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTopicInfoTab);
