import { EVENTS } from "./constants/actionTypes";

const initialState = {
  filter: {
    searchText: "",
    config: {
      events: [],
    },
  },
  events: [],
  eventNames: [],
  dynamicColumns: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EVENTS.EVENTS_LOADED:
      return {
        ...state,
        events: action.payload,
      };
    case EVENTS.EVENTS_LOADED_FAILED:
      return {
        ...state,
        events: [],
      };
    case EVENTS.EVENTS_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case EVENTS.EVENTS_NAMES_LOADED:
      return {
        ...state,
        eventNames: action.payload,
      };
    case EVENTS.EVENTS_LOAD_DYNAMIC_COLUMNS:
      return {
        ...state,
        dynamicColumns: {
          ...state.dynamicColumns,
          [action.payload.eventName]: action.payload.data,
        },
      };

    default:
      return state;
  }
};
