import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import React from "react";
import { I18n } from "react-redux-i18n";
import CustomTooltip from "./utils/CustomTooltip";
import theme from "../../../../../themes/theme";

type Props = {
  data: any[];
  timeFormat: (item: any) => string;
  numberFormat: (item: any) => string;
  fullDateFormat: (item: any) => string;
  titlesFormat: (value: any, name: any) => any[];
  width?: string;
  height?: number;
};

const ImpressionsChart = (props: Props) => {
  const { data, timeFormat, numberFormat, titlesFormat, width, fullDateFormat, height } = props;

  return (
    <ResponsiveContainer width={width || "98%"} height={height || 400}>
      <ComposedChart data={data}>
        <XAxis
          dataKey={"date"}
          tickFormatter={timeFormat}
          axisLine={false}
          tickLine={false}
          tickMargin={16}
          tick={{ fontSize: 12 }}
          interval={"preserveStartEnd"}
        />
        <YAxis
          dataKey={"total_impressions"}
          yAxisId="left"
          axisLine={false}
          tickLine={false}
          width={40}
          tickMargin={10}
          tick={{ fontSize: 12 }}
          type="number"
          tickFormatter={numberFormat}
        />
        <YAxis
          dataKey={"avg_daily_total_impressions"}
          yAxisId="right"
          orientation="right"
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          tick={{ fontSize: 12 }}
          type="number"
          tickFormatter={numberFormat}
        />
        <Tooltip
          cursor={false}
          content={
            <CustomTooltip
              projectionColumn={"projectedImpressions"}
              totalColumn={"total_impressions"}
              getTotal={false}
              translations={{
                avg_daily_total_impressions: I18n.t("reports.dashboard.avg_daily_total_impressions"),
                total_impressions: I18n.t("reports.dashboard.total_impressions"),
                projectedImpressions: I18n.t("reports.dashboard.projected_clicks"),
              }}
            />
          }
          labelFormatter={fullDateFormat}
          formatter={titlesFormat}
          labelStyle={{ marginBottom: 5, fontWeight: theme.typography.fontWeightBold }}
        />
        <CartesianGrid stroke="#E5E5E5" vertical={false} strokeDasharray={1} />
        <Bar
          yAxisId={"left"}
          fill="#4353FF"
          maxBarSize={80}
          stackId={"impressions-stack"}
          dataKey="total_impressions"
        />
        <Bar
          yAxisId={"left"}
          fill="#4353FF80"
          maxBarSize={80}
          stackId={"impressions-stack"}
          dataKey="projectedImpressions"
        />

        <Line yAxisId={"right"} dataKey="avg_daily_total_impressions" stroke="#8B2FE8" strokeWidth={2} dot={false} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ImpressionsChart;
