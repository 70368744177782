import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import CampaignEditor from "./CampaignEditor/CampaignEditor";
import CampaignsList from "./CampaignList/CampaignsList";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";
import { web } from "../../../../helpers/urlHelper";

const Campaigns = () => (
  <>
    <MHeaderMetaTags title={I18n.t(`meta_tags.cro.widget_rules.title`)} />
    <Switch>
      <Route exact={false} path={web.croCampaignEditor(":ruleId")} component={() => <CampaignEditor />} />
      <Route exact={false} path={web.croCampaigns()} component={() => <CampaignsList />} />
      <Redirect exact from={"*"} to={web.croCampaigns()} />
    </Switch>
  </>
);

export default Campaigns;
