import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import MSelect from "../../../../../../components/MSelect";

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
}));

type PropTypes = {
  selected: boolean;
  handleSelectedChange: (selected: boolean) => void;
};

const options = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const YesNoSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selected, handleSelectedChange } = props;

  return (
    <MSelect
      customClass={classes.wrapper}
      fullWidth
      options={options}
      value={selected ? options[0] : options[1]}
      optionLabel={"label"}
      optionValue={"value"}
      borderWidth={1}
      borderRadius={4}
      height={37}
      placeholder={"Select..."}
      handleChange={(item) => {
        handleSelectedChange(item.value === "Yes");
      }}
    />
  );
};

export default YesNoSelect;
