"use strict";
import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
import PreviewIcon from "../../../icons/PreviewIcon";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 19,
    height: 14,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  activeIcon: {
    fill: theme.palette.primary.main,
  },
}));

type propTypes = {
  previewMode: boolean;
  handlePreviewModeChange: (previewMode: boolean) => void;
};

function PreviewButton(props: propTypes) {
  const classes = useStyles(props);
  const { previewMode, handlePreviewModeChange } = props;

  return (
    <Tooltip enterDelay={2000} title={I18n.t("rich_text_editor.tooltips.preview")}>
      <IconButton
        className={classes.button}
        disableRipple
        onMouseDown={(event) => {
          event.preventDefault();
          handlePreviewModeChange(!previewMode);
        }}
      >
        <PreviewIcon className={ClassNames(classes.icon, previewMode && classes.activeIcon)} />
      </IconButton>
    </Tooltip>
  );
}

export default PreviewButton;
