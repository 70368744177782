import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { colors } from "../../../helpers/constants";
import { Editor, Transforms } from "slate";
import { emitEvent } from "../../../hooks/useEvents";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: "none",
    backgroundColor: colors.pageEditorBackgroundColor,
  },
  innerWrapper: {
    padding: "30px 0",
  },
  tittleSection: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 14,
  },
  leftIcon: {
    marginLeft: -8,
  },
  elementNameRow: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      "& $leftIcon": {
        fill: theme.palette.primary.main,
      },
      "& $tittleSection": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

type PropTypes = {
  editor: Editor;
  className?: string;
  sectionTitle: string;
  children: React.ReactElement | React.ReactElement[];
};

const StylePanelSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, sectionTitle, className = null, children } = props;

  const close = () => {
    Transforms.deselect(editor);
    emitEvent("edit-design", { selected: false, open: false });
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.elementNameRow} onClick={close}>
        <KeyboardArrowLeftRounded className={classes.leftIcon} />
        <Typography className={classes.tittleSection} variant={"body1"}>
          {sectionTitle}
        </Typography>
      </div>
      <div className={ClassNames(classes.innerWrapper, className)}>{children}</div>
    </Paper>
  );
};

export default StylePanelSection;
