import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import MSelect from "../../../../components/MSelect";

const useStyles = makeStyles(() => ({
  filterWrapper: {
    display: "flex",
    marginBottom: "20px",
  },
  select: {
    textTransform: "capitalize",
    cursor: "pointer",
  },
}));

type PropTypes = {
  setPeriod: (date: {
    label: string;
    value: {
      startDate: string | null;
      endDate: string | null;
    };
  }) => void;

  currentPeriod: {
    label: string;
    value: {
      startDate: string | null;
      endDate: string | null;
    };
  };
};

const StatisticsFilter = (props: PropTypes) => {
  const classes = useStyles();

  const { currentPeriod, setPeriod } = props;

  const dateOptions = [
    {
      label: "All time",
      value: {
        startDate: null,
        endDate: null,
      },
      key: 0,
    },
    {
      label: "This Month",
      value: {
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
      },
      key: 1,
    },
    {
      label: "Past 90 days",
      value: {
        startDate: moment().subtract(90, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      key: 2,
    },
  ];

  return (
    <div className={classes.filterWrapper}>
      <MSelect
        customClass={classes.select}
        options={dateOptions}
        value={currentPeriod}
        optionLabel={"label"}
        optionValue={"value"}
        height={40}
        borderRadius={0}
        borderWidth={"0"}
        menuPlacement={"bottom"}
        placeholder={I18n.t("workflow.site_stats.time_period")}
        searchable
        handleChange={(type) => setPeriod(type)}
      />
    </div>
  );
};

export default StatisticsFilter;
