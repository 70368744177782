import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import { Theme, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";
import heading from "../../../../../img/heading.png";
import MoreOptionsButton from "../../../../WorkFlow/PageClusterList/components/MoreOptionsButton";
import { ISourceFile, IWebsite } from "../../../../../reducers/constants/objectTypes";
import MTableRow from "../../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../../components/Table/Cells/MTableCellFirst";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";
import MTableCell from "../../../../../components/Table/Cells/MTableCell";
import MTableCellDate from "../../../../../components/Table/Cells/MTableCellDate";
import MTableCellNumber from "../../../../../components/Table/Cells/MTableCellNumber";

const useStyles = makeStyles((theme: Theme) => ({
  iconColumn: {
    width: 40,
  },

  topicName: {
    fontSize: "16px",
    cursor: "pointer",
    width: "fit-content",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginLeft: 15,
  },

  playColumn: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  imgRow: { height: 25, width: 25, borderRadius: 100 },
  helpSpan: {
    backgroundColor: theme.palette.secondary.main,
    padding: 3,
    borderRadius: "50%",
    marginLeft: 10,
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 900,
    cursor: "help",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  sourceFile: ISourceFile;
  handleClick(video: ISourceFile): void;
  handleRephraseClick(video: ISourceFile): void;
  deleteSourceFile(): void;
  handleSourceFileChanged(sourceFile: ISourceFile): void;
};

const SourceFileListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { sourceFile, handleRephraseClick, handleClick, deleteSourceFile } = props;

  const { enqueueSnackbar } = useSnackbar();

  const transcribeStatus = React.useMemo(() => {
    if (sourceFile.transcribedEnd) {
      return "Done";
    }
    if (sourceFile.transcribedStart) {
      return "In Progress";
    }
    if (sourceFile.transcribeError) {
      return `Failed: ${sourceFile.transcribeError}`;
    }
    return "No";
  }, [sourceFile]);

  const onSourceFileClick = () => {
    if (transcribeStatus !== "Done") {
      return enqueueSnackbar(I18n.t("videos.list.transcribe_not_finished"));
    }
    return handleRephraseClick(sourceFile);
  };

  const getRephraseStatus = React.useCallback(
    (sf) => {
      if (sf.chatGptRephraseEnd) {
        return "Done";
      }
      if (sf.chatGptRephraseStart) {
        return "In Progress";
      }
      if (sf.chatGptRephraseError) {
        return `Failed: ${sourceFile.chatGptRephraseError}`;
      }
      return "No";
    },
    [sourceFile]
  );
  const theme = useTheme();
  return (
    <MTableRow handleRowClick={onSourceFileClick}>
      <MTableCellFirst
        columnWidth={"35%"}
        prefix={<img alt={"form image"} src={heading} className={classes.imgRow} />}
        fontSize={theme.typography.pxToRem(16)}
      >
        <Tooltip enterDelay={900} title={sourceFile?.title || sourceFile.fileName} arrow>
          <Typography className={classes.topicName} variant="subtitle2">
            {sourceFile?.title || sourceFile.fileName}
          </Typography>
        </Tooltip>
        <Tooltip enterDelay={250} title={sourceFile.description} arrow>
          <span className={classes.helpSpan}>{"?"}</span>
        </Tooltip>
      </MTableCellFirst>
      <MTableCellText text={sourceFile.type} />
      <MTableCell
        className={classes.playColumn}
        onClick={() => {
          handleClick(sourceFile);
        }}
      >
        {I18n.t("videos.list.play")}
      </MTableCell>
      <MTableCellDate date={sourceFile.createdAt} />
      <MTableCellText text={transcribeStatus} />
      <MTableCellText text={sourceFile.languageCode} />
      <MTableCellNumber value={sourceFile.speakers} />
      <MTableCellText text={getRephraseStatus(sourceFile)} />
      <MTableCellText text={"-"} />
      <MTableCellText text={"-"} />
      <div className={classes.iconColumn}>
        <MoreOptionsButton
          handleDeletePermanentelyClick={() => {
            deleteSourceFile();
          }}
        />
      </div>
    </MTableRow>
  );
};

export default SourceFileListItem;
