import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: 10,
    "&:hover": {
      cursor: (props: PropTypes) => (props?.onClick ? "pointer" : "default"),
      color: (props: PropTypes) => (props?.onClick ? theme.palette.primary.main : null),
    },
  },
  tooltipStyle: {
    whiteSpace: "pre-wrap",
  },
}));

type PropTypes = {
  children: string | React.ReactNode;
  columnWidth?: string;
  onClick?: any;
  tooltip?: string;
  className?: string;
};

const MTableCell = (props: PropTypes) => {
  const classes = useStyles(props);
  const { children, onClick, tooltip, className, columnWidth } = props;

  return (
    <div
      className={ClassNames(classes.column, className)}
      style={{
        width: columnWidth || "auto",
        flex: columnWidth ? undefined : 1,
        flexBasis: columnWidth ? undefined : 0,
      }}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    >
      {!tooltip && children}
      {tooltip && (
        <Tooltip enterDelay={300} title={tooltip} classes={{ tooltip: classes.tooltipStyle }}>
          <span>{children}</span>
        </Tooltip>
      )}
    </div>
  );
};

export default MTableCell;
