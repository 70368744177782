import * as React from "react";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabelWithSelectAll from "../../../../components/Table/Table/MTableHeaderLabelWithSelectAll";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";

type PropTypes = {
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  setSortField?: (input: { field: string; direction: "asc" | "desc" }) => void;
  selectedNr: number;
  clearSelected(): void;
  selectAll(): void;
  total: number;
};

const InternalLinkingColumns = (props: PropTypes) => {
  const { sortField, setSortField, total, selectedNr, clearSelected, selectAll } = props;

  return (
    <MTableHeader>
      <MTableHeaderLabelWithSelectAll
        selected={selectedNr > 0}
        onSelectAll={selectAll}
        onClearAll={clearSelected}
        columnWidth={"40%"}
      >
        {numeral(total).format("0,0a")}{" "}
        {total !== 1 ? I18n.t("strategy.internalLinking.pages") : I18n.t("strategy.internalLinking.page")}
      </MTableHeaderLabelWithSelectAll>
      <MTableHeaderSortLabel
        field={"related_pages_count"}
        onClick={(result) => setSortField(result)}
        sortField={sortField}
      >
        {I18n.t("strategy.strategyKeywords.internalLinkingColumns.related_pages")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        field={"conversion_potential"}
        onClick={(result) => setSortField(result)}
        sortField={sortField}
      >
        {I18n.t("strategy.strategyKeywords.internalLinkingColumns.funnel_stage")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel field={"topics"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.strategyKeywords.internalLinkingColumns.topics")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel field={"kws"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.strategyKeywords.internalLinkingColumns.kws")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel field={"position"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.strategyKeywords.internalLinkingColumns.position")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel field={"clicks"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.strategyKeywords.internalLinkingColumns.traffic")}
      </MTableHeaderSortLabel>
    </MTableHeader>
  );
};

export default InternalLinkingColumns;
