import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 14,
    width: 18,
  },
}));

type PropTypes = {
  className?: string;
  onClick?: () => void;
};

const ArrowLeftIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className, onClick } = props;

  return (
    <SvgIcon onClick={onClick} className={ClassNames(classes.icon, className)} viewBox={"0 0 20 20"}>
      <path d="M11.707 5.293a1 1 0 0 1 0 1.414L7.414 11H19a1 1 0 1 1 0 2H7.414l4.293 4.293a1 1 0 0 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0z" />
    </SvgIcon>
  );
};

export default ArrowLeftIcon;
