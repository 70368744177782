import { OPPORTUNITIES } from "./constants/actionTypes";

const initialState = {
  // list
  opportunitiesLoaded: false,
  opportunities: [],

  // single
  opportunity: null,
  opportunityLoaded: false,
  opportunityPages: [],
  opportunityPagesLoaded: false,
  checkedOpportunitiesList: [],

  // limit
  opportunitiesLimit: 100,
  opportunitiesTotal: 0,

  // filter
  opportunitiesSearch: "",
  opportunitiesTask: "",
  opportunitiesVolume: 0,
  opportunitiesIntent: "",
  opportunitiesCompetition: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPPORTUNITIES.OPPORTUNITY_PAGES_START_LOADING:
      return {
        ...state,
        opportunityPagesLoaded: false,
      };
    case OPPORTUNITIES.OPPORTUNITY_PAGES_LOADED:
      return {
        ...state,
        opportunityPagesLoaded: true,
        opportunityPages: action.payload.keywordPages,
      };
    case OPPORTUNITIES.OPPORTUNITIES_LOADED:
      return {
        ...state,
        opportunitiesLoaded: true,
        opportunities: action.payload.opportunities,
        opportunitiesTotal: action.payload.total,
      };
    case OPPORTUNITIES.OPPORTUNITIES_START_LOADING:
      return {
        ...state,
        opportunitiesLoaded: false,
      };
    case OPPORTUNITIES.OPPORTUNITY_LOADED:
      return {
        ...state,
        opportunity: action.opportunity,
        opportunityLoaded: true,
      };
    case OPPORTUNITIES.OPPORTUNITY_START_LOADING:
      return {
        ...state,
        opportunityLoaded: false,
      };
    case OPPORTUNITIES.ON_CHECKED_BOX_CHANGED:
      return {
        ...state,
        checkedOpportunitiesList: action.checkedOpportunitiesList,
      };
    default:
      return state;
  }
};
