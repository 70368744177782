import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useState } from "react";
import { Allotment } from "allotment";
import { I18n } from "react-redux-i18n";
import InsightsTable from "./Components/table/InsightsTable";
import InsightsHeader from "./Components/InsightsHeader";
import "allotment/dist/style.css";
import InsightsGraph from "./Components/chart/InsightsGraph";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const useStyles = makeStyles((theme: Theme) => ({
  insights: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    display: "flex",
    overflowY: "auto",
    flex: 1,
    flexDirection: "column",
    padding: 20,
  },
}));

type PropTypes = {};

const Insights = (props: PropTypes) => {
  const classes = useStyles(props);

  const [chartHeight, setChartHeight] = useState(250);

  return (
    <div className={classes.insights}>
      <MHeaderMetaTags title={I18n.t(`meta_tags.legacy.insights.title`)} />
      <InsightsHeader />
      <Allotment
        vertical
        onChange={([chart, table]) => {
          console.log("onChange", chart, table);
          setChartHeight(chart);
        }}
      >
        <Allotment.Pane>
          <InsightsGraph chartHeight={chartHeight} />
        </Allotment.Pane>
        <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
          <Allotment.Pane>
            <>
              {/* todo add later */}
              {/* <InsightsSearch/> */}
              <InsightsTable />
            </>
          </Allotment.Pane>
        </div>
      </Allotment>
    </div>
  );
};

export default Insights;
