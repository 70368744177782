import { unionBy } from "lodash";
import { WRITER_BIO } from "./constants/actionTypes";

const initialState = {
  writersLoaded: false,
  writers: [],

  selectedBio: null,
  selectedBioLoaded: false,

  // START audience object
  active: false,
  alternativeId: null,

  user: null,
  categories: [],

  general: {
    active: false,
    profileImage: null,
    name: null,
    displayName: null,
    role: "writer",
    expertiseList: [],
    educationList: [],
    socials: [
      {
        facebook: null,
        twitter: null,
        youTube: null,
        linkedin: null,
      },
    ],
  },

  preview: {
    active: false,
    text: null,
  },

  highlights: {
    active: false,
    option1: null,
    option2: null,
    option3: null,
    option4: null,
  },

  expertise: {
    active: false,
    title: null,
    text: null,
  },

  quote: {
    active: false,
    name: null,
    text: null,
  },

  metaDescription: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WRITER_BIO.ON_WRITERS_LOADED:
      return {
        ...state,
        writersLoaded: true,
        writers: action.payload.writers,
      };
    case WRITER_BIO.CLEANUP:
      return {
        ...initialState,
      };
    case WRITER_BIO.ON_BIO_LOADED:
      if (!action.payload.bio) {
        return {
          ...state,
          selectedBio: action.payload.bio,
          selectedBioLoaded: true,
          active: initialState.active,
          alternativeId: initialState.alternativeId,
          user: initialState.user,
          categories: initialState.categories,
          general: initialState.general,
          preview: initialState.preview,
          highlights: initialState.highlights,
          expertise: initialState.expertise,
          quote: initialState.quote,
          metaDescription: initialState.metaDescription,
        };
      }

      return {
        ...state,

        selectedBio: action.payload.bio,
        selectedBioLoaded: true,
        active: action.payload.bio.active,
        alternativeId: action.payload.bio.alternativeId,
        user: action.payload.bio.user,
        categories: action.payload.bio.categories,
        general: action.payload.bio.general,
        preview: action.payload.bio.preview,
        highlights: action.payload.bio.highlights,
        expertise: action.payload.bio.expertise,
        quote: action.payload.bio.quote,
        metaDescription: action.payload.bio.metaDescription,
      };
    case WRITER_BIO.ON_PROPERTY_CHANGED:
      return {
        ...state,
        [action.name]: action.value,
      };
    case WRITER_BIO.ON_BIO_UPDATED: {
      return {
        ...state,
        writers: state.writers.map((w) => {
          if (w._id === action.bio.user._id) {
            return {
              ...w,
              name: action.bio.general?.displayName || action.bio.general?.name || w.name,
              bios: unionBy(w.bios, [action.bio], "_id"),
            };
          }
          return w;
        }),
        selectedBio: action.bio,
        selectedBioLoaded: true,
        active: action.bio.active,
        alternativeId: action.bio.alternativeId,
        user: action.bio.user,
        general: action.bio.general,
        preview: action.bio.preview,
        highlights: action.bio.highlights,
        expertise: action.bio.expertise,
        quote: action.bio.quote,
        metaDescription: action.bio.metaDescription,
      };
    }
    case WRITER_BIO.ON_WRITER_CREATED:
      return {
        ...state,
        writers: [action.payload.writer, ...state.writers],
        selectedBio: action.payload.bio,
        selectedBioLoaded: true,
        active: action.payload.bio.active,
        alternativeId: action.payload.bio.alternativeId,
        user: action.payload.bio.user,
        categories: action.payload.bio.categories,
        general: action.payload.bio.general,
        preview: action.payload.bio.preview,
        highlights: action.payload.bio.highlights,
        expertise: action.payload.bio.expertise,
        quote: action.payload.bio.quote,
        metaDescription: action.payload.bio.metaDescription,
      };
    default:
      return state;
  }
};
