import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { eAcfFieldType } from "../../../../../reducers/constants/objectTypes";
import ACFTextInput from "./ACFTextInput";
import _ from "lodash";
import ACFRichTextInput from "./ACFRichTextInput";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    width: "100%",
    height: 37,
    padding: "0px 10px",
    margin: 0,
    justifyContent: "center",
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    "&:focus": {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
  },
  textInput: {
    padding: 0,
  },
  listTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    margin: "10px 0px",
  },
  listWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  itemLabel: {
    marginRight: 10,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  itemListWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 5,
    width: "100%",
  },
  icon: {
    height: "22px",
    cursor: "pointer",
  },
  addButton: {
    textTransform: "none",
    display: "flex",
    alignSelf: "flex-end",
  },
  startIcon: {
    marginTop: -2,
    marginRight: 2,
  },
}));

type PropTypes = {
  label: string;
  values: any[];
  items: any[];
  handleOnChange: (val: any) => void;
};

const ACFObjectList = (props: PropTypes) => {
  const classes = useStyles(props);
  const { label, values = [], handleOnChange, items = [] } = props;

  const getAcfFieldComponent = (item, field, index) => {
    switch (field.type) {
      case eAcfFieldType.Text:
        return (
          <ACFTextInput
            inputWidth={field.width}
            key={field.key}
            label={field.label}
            clearable={field.clearable}
            handleClear={() => {
              if (values.length !== index) {
                const newValues = [...values];
                newValues.splice(index, 1);
                handleOnChange(newValues);
              }
            }}
            value={_.get(item, field.key)}
            handleOnChange={(value) => {
              const newItem = { ...item };
              _.set(newItem, field.key, value);
              values[index] = newItem;
              handleOnChange([...values]);
            }}
          />
        );
      case eAcfFieldType.richText:
        return (
          <ACFRichTextInput
            key={field.key}
            label={field.label}
            value={_.get(item, field.key)}
            handleOnChange={(value) => {
              const newItem = { ...item };
              _.set(newItem, field.key, value);
              values[index] = newItem;
              handleOnChange([...values]);
            }}
          />
        );
      default:
        return null;
    }
  };

  const getDefaultFieldValue = (item) => {
    switch (item.type) {
      case eAcfFieldType.Text:
      case eAcfFieldType.richText:
        return "";
      case eAcfFieldType.Switch:
        return false;
    }
  };

  const addNewItem = () => {
    const newItem = items.reduce((acc, item) => {
      return {
        ...acc,
        [item.key]: getDefaultFieldValue(item),
      };
    });
    handleOnChange([...values, newItem]);
  };

  useEffect(() => {
    if (values.length == 0) {
      addNewItem();
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      {label && <Typography className={classes.listTitle}>{label}</Typography>}
      <div className={classes.listWrapper}>
        {[...(values?.length ? values : [])].map((value, index) => (
          <div className={classes.itemListWrapper} key={`${label}_${index + 1}`}>
            {items.map((item, j) => {
              return (
                <div key={item.key} className={classes.itemWrapper}>
                  {getAcfFieldComponent(value, item, index)}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <Button
        className={classes.addButton}
        classes={{ startIcon: classes.startIcon }}
        disableElevation
        startIcon={<AddIcon className={classes.icon} />}
        onClick={addNewItem}
      >
        {I18n.t("widgets_builder.widget_editor.fields.add")}
      </Button>
    </div>
  );
};

export default ACFObjectList;
