import { PUBLISHED } from "./constants/actionTypes";

const initialState = {
  itemsLoaded: false,
  refreshItemList: false,
  items: [],
  writer: { _id: "ALL", name: "All Writers" },
  postTypes: ["All"],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PUBLISHED.ITEMS_LOADED:
      return {
        ...state,
        itemsLoaded: true,
        refreshItemList: false,
        items: action.payload.items,
      };
    case PUBLISHED.SET_LOADING:
      return {
        ...state,
        itemsLoaded: false,
      };
    case PUBLISHED.REFRESH_ITEM_LIST:
      return {
        ...state,
        refreshItemList: true,
      };
    case PUBLISHED.WRITER_SELECTED:
      return {
        ...state,
        writer: action.writer,
      };
    case PUBLISHED.POST_TYPE_SELECTED: {
      let postTypes;
      if (action.postTypes.length === 0) {
        postTypes = ["All"];
      } else if (action.postTypes.length > 1) {
        postTypes = action.postTypes.filter((a) => a !== "All");
      } else {
        postTypes = action.postTypes;
      }
      return {
        ...state,
        postTypes,
      };
    }
    default:
      return state;
  }
};
