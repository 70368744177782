// https://github.com/Dhaiwat10/react-link-preview/tree/master/src/components/LinkPreview
import React from "react";
import { Theme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  skeletonContainer: {
    // borderRadius: 7,
    // border: '1px solid #eee',
  },

  skeletonLowerContainer: {
    padding: 10,
  },
}));

interface SkeletonProps {
  width?: string | number;
  imageHeight?: string | number;
  margin?: string | number;
}

const LinkPreviewSkeleton: React.FC<SkeletonProps> = (props: SkeletonProps) => {
  const { width = "100%", imageHeight = "30vh", margin } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.skeletonContainer} style={{ width, margin }}>
      <Skeleton variant={"rect"} width={width} height={imageHeight} />
      <div className={classes.skeletonLowerContainer}>
        <Skeleton variant={"text"} />
        <Skeleton variant={"text"} />
        <Skeleton variant={"text"} />
      </div>
    </div>
  );
};

export default LinkPreviewSkeleton;
