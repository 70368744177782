import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { ILinksSplitTest, IProduct, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import MTextField from "../../../../../../../components/MTextField";
import EditLinkSplitTestProductPicker from "./EditLinkSplitTestProductPicker";
import EditLinkSplitTestCountriesPicker from "./EditLinkSplitTestCountriesPicker";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: 10,
    marginBottom: 15,
  },
  columnHeader: {
    width: 85,
    textAlign: "end",
  },
  column: {
    // flex: 1,
    width: "50%",
    gap: 7,
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    margin: 0,
  },
  textFieldInput: {},
  stateSelect: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    height: 37,
    paddingLeft: 10,
  },
  dropDownIcon: {
    marginRight: 10,
    fill: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    "&:hover": {
      fill: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  test: ILinksSplitTest;
  handleTestChange: (test: ILinksSplitTest) => void;
};

const EditLinksSplitTestFields = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, test, handleTestChange } = props;

  return (
    <>
      <div className={classes.row}>
        <div className={classes.columnHeader}>
          <Typography color={"textPrimary"} variant={"subtitle2"}>
            {I18n.t("links_split_test.editor.name")}
          </Typography>
        </div>
        <div className={classes.column}>
          <MTextField
            autoFocus
            customClass={classes.textField}
            inputClassName={classes.textFieldInput}
            borderRadius={5}
            fullWidth
            outlined
            backgroundColor={"#EEF2F9"}
            borderColor={"#EEF2F9"}
            height={37}
            value={test.name}
            placeholder={I18n.t("links_split_test.editor.name_ph")}
            onChange={(name) => {
              handleTestChange({ ...test, name });
            }}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.columnHeader}>
          <Typography color={"textPrimary"} variant={"subtitle2"}>
            {I18n.t("links_split_test.editor.description")}
          </Typography>
        </div>
        <div className={classes.column}>
          <MTextField
            fullWidth
            outlined
            backgroundColor={"#EEF2F9"}
            borderColor={"#EEF2F9"}
            customClass={classes.textField}
            inputClassName={classes.textFieldInput}
            borderRadius={5}
            height={100}
            multiline
            rows={3}
            value={test.description}
            placeholder={I18n.t("links_split_test.editor.description_ph")}
            onChange={(description) => {
              handleTestChange({ ...test, description });
            }}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.columnHeader}>
          <Typography color={"textPrimary"} variant={"subtitle2"}>
            {I18n.t("links_split_test.editor.select_countries")}
          </Typography>
        </div>
        <div className={classes.column}>
          <EditLinkSplitTestCountriesPicker
            selectedCountries={test.countries}
            type={test.countriesSelector}
            handleCountriesChange={(countriesSelector, countries) =>
              handleTestChange({ ...test, countriesSelector, countries })
            }
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.columnHeader}>
          <Typography color={"textPrimary"} variant={"subtitle2"}>
            {I18n.t("links_split_test.editor.select_products")}
          </Typography>
        </div>
        <div className={classes.column}>
          <EditLinkSplitTestProductPicker
            selectedWebsite={selectedWebsite}
            allProductsSelected={test.allProducts}
            handleAllProductsSelected={(allProductsSelected: boolean) => {
              handleTestChange({
                ...test,
                allProducts: allProductsSelected,
                products: [],
                productNames: [],
                productItemNumbers: [],
              });
            }}
            selectedProducts={test.products}
            handleProductsChange={(prods: IProduct[]) => {
              console.log("onProductChange", prods);
              // const groups = [...test.groups];
              // if (prod.callToActionURL) {
              //   if (groups.length === 0) {
              //     groups.push({
              //       variant: "A",
              //       description: "Default",
              //       url: prod.callToActionURL,
              //       weight: 100,
              //       visits: 0,
              //     });
              //   }
              //   if (groups[0].url !== prod.callToActionURL) {
              //     groups[0].url = prod.callToActionURL;
              //   }
              // }
              handleTestChange({
                ...test,
                products: prods,
                productNames: prods.map((p) => p.name || p.displayName),
                productItemNumbers: prods.map((p) => p.itemNumber),
                // groups,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EditLinksSplitTestFields;
