import React from "react";
import { Rating } from "@material-ui/lab";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Badge, Theme, Tooltip, Typography } from "@material-ui/core";

import { IExpert } from "../../../../../reducers/constants/objectTypes";
import ExpertCollapsibleCard from "./ExpertCollapsibleCard";

type EvaluationKey = keyof IExpert["evaluation"];

type PropTypes = {
  type: EvaluationKey;
  options: IExpert["evaluation"][EvaluationKey];
  title: string;
  onChange: (options: IExpert["evaluation"][EvaluationKey]) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    gap: 20,
  },
  item: {
    display: "flex",
    gap: 20,
  },
  titleWrapper: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    gap: 30,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  badge: {
    top: 5,
    right: -5,
  },
  questionMark: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.grey[500],
    cursor: "pointer",
  },
  tooltip: {
    marginBottom: 0,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[500],
    fontSize: 12,
    maxWidth: 350,
  },
  tooltipPopper: {
    backgroundColor: "transparent",
  },
  ratingWrapper: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  ratingLabel: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
    color: "#4353FF",
  },
}));

const EvaluationCard = (props: PropTypes) => {
  const { type, options, title } = props;
  const { onChange } = props;

  const classes = useStyles();

  const scorePercentage = Math.floor(
    (Object.values(options).reduce((acc, cur) => acc + cur, 0) * 100) / (6 * Object.keys(options).length)
  );

  return (
    <ExpertCollapsibleCard title={title} className={classes.root} scorePercentage={scorePercentage}>
      {Object.keys(options).map((key, index) => (
        <div key={key} className={classes.item}>
          <div className={classes.titleWrapper}>
            <Typography variant="subtitle1" className={classes.title}>
              {`${index + 1}.`}
            </Typography>
            <Badge
              badgeContent={
                <Tooltip
                  title={I18n.t(`experts.profile.evaluation.${type}.options.${key}.tooltip`)}
                  placement="top"
                  enterDelay={500}
                  classes={{ tooltip: classes.tooltip, popper: classes.tooltipPopper }}
                >
                  <Typography variant="caption" className={classes.questionMark}>
                    ?
                  </Typography>
                </Tooltip>
              }
              classes={{ anchorOriginTopRightRectangle: classes.badge }}
            >
              <Typography variant="subtitle1" className={classes.title}>
                {I18n.t(`experts.profile.evaluation.${type}.options.${key}.title`)}
              </Typography>
            </Badge>
          </div>
          <div className={classes.ratingWrapper}>
            <Rating
              name={key}
              size="large"
              value={options[key] || 0}
              max={6}
              onChange={(_, newValue) => onChange({ ...options, [key]: newValue })}
            />
            <Typography variant="subtitle1" className={classes.ratingLabel}>
              {`${options[key] || 0}/6`}
            </Typography>
          </div>
        </div>
      ))}
    </ExpertCollapsibleCard>
  );
};

export default EvaluationCard;
