import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    flexGrow: 1,
    flexBasis: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: 10,
  },
}));

type PropTypes = {
  value1: number;
  value2: number;
  tooltipText?: string;
};

const MTableCellNumberProportion = (props: PropTypes) => {
  const classes = useStyles(props);
  const { value1, value2, tooltipText } = props;

  return (
    <div className={classes.column}>
      <Tooltip
        enterDelay={2000}
        title={tooltipText || `${numeral(value1 || 0).format("0,0")}/${numeral(value2 || 0).format("0,0")}`}
      >
        <span>
          {numeral(value1 || 0).format("0,0")}/{numeral(value2 || 0).format("0,0")}
        </span>
      </Tooltip>
    </div>
  );
};

export default MTableCellNumberProportion;
