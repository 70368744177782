"use strict";

import omit from "lodash/omit";
import { STAGING } from "../constants/consts";

export const MIXPANEL_PAGE_VIEW = "page-view";
function mixpanelMiddleware(mixpanel, prod) {
  if (!mixpanel || !mixpanel.track) {
    throw new TypeError("You must provide a mixpanel client instance.");
  }

  return function (store) {
    return function (next) {
      return function (action) {
        if (!action.meta || !action.meta.mixpanel || !action.meta.mixpanel.event) {
          return next(action);
        }

        console.log("MP: Middleware", JSON.stringify(action));

        try {
          const state = store.getState();
          const user = state.home.admin || action.meta.mixpanel.user || null;
          const website = state.home.selectedWebsite || action.meta.mixpanel.website || null;

          const _action$meta$mixpanel = action.meta.mixpanel;
          const event = _action$meta$mixpanel.event;
          const props = omit(_action$meta$mixpanel.props, ["user"]);

          if (user) {
            props.distinct_id = user._id;
          }
          console.log(`%c MP: Track: ${event}, ${JSON.stringify(props)}`, "background: #222; color: #bada55");
          if (!prod || STAGING) {
            console.log(
              `%c MP: Identify ${user ? `${user.email}-${user._id}` : "guest"}`,
              "background: #222; color: #bada55"
            );
            console.log(
              `%c MP: People: set ${user ? `${user.email}-${user._id}` : "guest"}`,
              "background: #222; color: #bada55"
            );
          } else {
            if (user) {
              mixpanel.identify(user._id);
              mixpanel.people.set({
                $name: user.name,
                $email: user.email,
                user_id: user._id,
                website_id: website?._id,
                website_name: website?.name,
              });
            }
            if (event === MIXPANEL_PAGE_VIEW) {
              mixpanel.track_pageview({ ...props, host: "app" });
            } else {
              mixpanel.track(event, props);
            }
          }
        } catch (error) {}
        return next(action);
      };
    };
  };
}

export { mixpanelMiddleware };
