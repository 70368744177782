import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import RuleItem from "./RuleItem";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import NewRedirectRuleDialog from "./NewRedirectRuleDialog";
import MTable from "../../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../../components/Table/Rows/MTableRowEmptyRow";

type PropTypes = {
  searchText: string;
  loaded: boolean;
  rules: any[];
  selectedWebsite: IWebsite;

  deleteRule: Function;
  refreshList: Function;
};

const RedirectsList = (props: PropTypes) => {
  const { searchText, selectedWebsite, loaded, rules, deleteRule, refreshList } = props;
  const [editRule, setEditRule] = React.useState(null);

  const removeDomain = (url) => {
    if (!url) {
      return url;
    }
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?((?:(?!www\.|\.).)+\.[a-zA-Z0-9.]+)/gim, "");
  };

  let filteredRules = loaded ? rules : [];
  if (searchText !== "") {
    const noDomainSearchText = removeDomain(searchText).toLowerCase();
    filteredRules = filteredRules.filter(
      (c) =>
        (c?.from || "").toLowerCase().includes(noDomainSearchText) ||
        (c?.to || "").toLowerCase().includes(noDomainSearchText) ||
        (c?.modelName || "").toLowerCase().includes(noDomainSearchText)
    );
  }
  const theme = useTheme();
  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabel columnWidth={"20%"} fontSize={theme.typography.pxToRem(21)}>
          {I18n.t("redirect_rules.table.from")}
        </MTableHeaderLabel>
        <MTableHeaderLabel columnWidth={"20%"} fontSize={theme.typography.pxToRem(21)}>
          {I18n.t("redirect_rules.table.to")}
        </MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("redirect_rules.table.locale")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("redirect_rules.table.type")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("redirect_rules.table.path")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("redirect_rules.table.last_redirect")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("redirect_rules.table.added")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("redirect_rules.table.bot_counter")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("redirect_rules.table.user_counter")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("redirect_rules.table.automatic")}</MTableHeaderLabel>
        <MTableHeaderLabel columnWidth={"30px"}>{""}</MTableHeaderLabel>
      </MTableHeader>
      {!loaded && <MTableRowProgressBar />}
      {loaded && filteredRules.length === 0 && <MTableRowEmptyRow />}
      {loaded &&
        filteredRules.length > 0 &&
        filteredRules.map((rule, index) => (
          <RuleItem
            key={rule._id}
            rule={rule}
            selectedWebsite={selectedWebsite}
            handleEditClick={() => setEditRule(rule)}
            handleDeleteClick={() => deleteRule(rule)}
          />
        ))}
      <NewRedirectRuleDialog
        selectedWebsite={selectedWebsite}
        open={editRule !== null}
        editedRule={editRule}
        handleCloseClick={(refresh) => {
          setEditRule(null);
          if (refresh) {
            refreshList();
          }
        }}
        handleRuleCreated={() => {
          refreshList();
        }}
      />
    </MTable>
  );
};

export default RedirectsList;
