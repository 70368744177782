import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import {
  IEditorialPolicy,
  IWebsite,
  IWebsiteConfigurationsProps,
  IWebsiteSectionProps,
} from "../../../../../reducers/constants/objectTypes";
import MSavedXMinutesAgo from "../../../../../components/MSavedXMinutesAgo";
import MButtonGroup from "../../../../../components/MButtonGroup";
import agent from "../../../../../agent";
import MAlertDialog from "../../../../../components/MAlertDialog";
import { EDITORIAL_POLICY, HOME, WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import { connect } from "react-redux";
import { find } from "lodash";
import richTextEditorHelper from "../../../../../editor/helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  postBottomBarWrapper: {
    zIndex: 100,
    height: 65,
    minHeight: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
    borderTop: `${theme.palette.divider} solid 1px`,
    position: "sticky",
    bottom: 0,
  },
  postBottomBarInnerWrapper: {
    // width: 'calc(100% - 2*280px)',
    width: "100%",
    paddingRight: 30,
    paddingLeft: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    minHeight: 40,
  },
  discardButton: {
    marginRight: 20,
  },
  postTypesWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textCounter: {
    marginRight: 20,
  },
  lastSaved: {
    marginRight: 20,
  },
}));
type PropTypes = {
  selectedWebsite: IWebsite;

  policyType: "EDITORIAL_PROCESS" | "COMMUNITY_GUIDELINES" | "EDITORIAL_TEAM" | "CONTENT_PRINCIPLES" | "FACT_CHECKED";
  policy: IEditorialPolicy;
  onPolicyChanged: (policy) => void;
  onPoliciesLoaded: (policy) => void;
  updatedSections: IWebsiteConfigurationsProps;
  onWebsiteUpdated: (website) => void;
  onPolicyLoaded: (policy, type) => void;
  name: string;
  url: string;
  host: string;
  sections: IWebsiteSectionProps;
  onPropertyChanged: (sections) => void;
  changesLostWarning: boolean;
};
const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  policyType: state.editorial.policyType,
  policy: state.editorial.policy,
  name: state.websitePages.name,
  url: state.websitePages.url,
  sections: state.websitePages.sections,
  host: state.websitePages.host,
  changesLostWarning: state.websitePages.changesLostWarning,
  updatedSections: state.websitePages.updatedSections,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (sections) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "sections",
      value: sections,
    }),
  onWebsiteUpdated: (website) => {
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_CONFIGURATIONS_LOADED, website });
    dispatch({ type: HOME.ON_WEBSITE_UPDATED, website });
  },
  onPolicyLoaded: (policy, type) =>
    dispatch({
      type: EDITORIAL_POLICY.ON_POLICY_LOADED,
      policy,
      policyType: type,
    }),
  onPoliciesLoaded: (policies) =>
    dispatch({
      type: EDITORIAL_POLICY.ON_POLICIES_LOADED,
      policies,
    }),
  onPolicyChanged: (policy) =>
    dispatch({
      type: EDITORIAL_POLICY.ON_POLICY_CHANGED,
      policy,
    }),
  handleWebsiteConfigurationsLoaded: (payload) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_CONFIGURATIONS_LOADED, payload }),
});
const EditorialPolicyBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    policyType,
    policy,
    onPolicyChanged,
    onPoliciesLoaded,
    onPolicyLoaded,
    changesLostWarning,
    onPropertyChanged,
    url,
    host,
    name,
    sections,
    onWebsiteUpdated,
    updatedSections,
  } = props;

  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const actions = [];

  actions.push({
    key: "update",
    label: I18n.t("edit_post.update"),
  });
  if (policy && policy._id) {
    actions.push({
      key: "delete",
      label: I18n.t("edit_post.delete"),
    });
  }
  const getPolicySectionName = () => {
    switch (policyType) {
      case "EDITORIAL_TEAM":
        return "editorialTeam";
      case "COMMUNITY_GUIDELINES":
        return "communityGuidelines";
      case "EDITORIAL_PROCESS":
        return "editorialPolicy";
      case "CONTENT_PRINCIPLES":
        return "contentPrinciples";
      case "FACT_CHECKED":
        return "factChecked";
      default:
        return "";
    }
  };

  const getPolicies = () => {
    if (!selectedWebsite) {
      return;
    }
    agent.EditorialPolicy.get(selectedWebsite._id).then((res) => {
      onPoliciesLoaded(res.policies);
      const selectedPolicy = find(res.policies, { type: policyType });
      if (selectedPolicy) {
        onPolicyLoaded(selectedPolicy, policyType);
      } else {
        onPolicyLoaded(null, policyType);
      }
    });
  };
  const handeWebsiteUpdate = (update?: any) => {
    const configurations = update || updatedSections;
    agent.Websites.updateConfigurations({
      _id: selectedWebsite._id,
      url,
      host,
      name,
      configurations,
    })
      .then((res) => {
        onWebsiteUpdated(res.website);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };
  const handleUpdatePolicyClick = () => {
    console.log("handleUpdatePolicyClick", policy);
    if (changesLostWarning) {
      handeWebsiteUpdate();
    }
    if (!policy.richText) {
      alert("Text can't be empty");
      return;
    }
    policy.text = richTextEditorHelper.serialize(JSON.parse(policy.richText));
    setSaveInProgress(true);
    agent.EditorialPolicy.update(selectedWebsite._id, policyType, policy)
      .then((res) => {
        onPolicyChanged(res.policy);
        setSaveInProgress(false);
        getPolicies();
        enqueueSnackbar(I18n.t("snackbar.general_update_success", { name: policyType.toLowerCase() }));
      })
      .catch((e) => {
        enqueueSnackbar(
          I18n.t("snackbar.general_update_error", {
            name: policyType.toLowerCase(),
            msg: e.message,
          })
        );
        setSaveInProgress(false);
      });
  };
  const handleDeletePolicyClick = () => {
    setSaveInProgress(true);
    if (!policy || !policy._id) {
      return;
    }
    if (sections[getPolicySectionName()]) {
    }
    agent.EditorialPolicy.remove(policy._id)
      .then((res) => {
        setSaveInProgress(false);
        enqueueSnackbar(I18n.t("snackbar.general_update_success", { name: policyType.toLowerCase() }));
        handeWebsiteUpdate({
          sections: {
            ...sections,
            [getPolicySectionName()]: false,
          },
          fields: ["sections"],
        });
        getPolicies();
      })
      .catch((e) => {
        enqueueSnackbar(
          I18n.t("snackbar.general_update_error", {
            name: policyType.toLowerCase(),
            msg: e.message,
          })
        );
        getPolicies();
        setSaveInProgress(false);
      });
  };
  const handleActionClick = (type) => {
    console.log("handleActionClick", type);
    switch (type) {
      case "update":
        handleUpdatePolicyClick();
        break;
      case "delete":
        setDeleteDialogOpen(true);
        break;
      default:
        break;
    }
  };
  return (
    <div className={classes.postBottomBarWrapper}>
      <div className={classes.postBottomBarInnerWrapper}>
        <div className={classes.flexWrapper} />
        <div className={classes.flexWrapper}>
          <div className={classes.lastSaved}>
            <MSavedXMinutesAgo lastSaveTime={policy?.updatedAt || null} />
          </div>
          {actions[0] && (
            <MButtonGroup
              loading={saveInProgress}
              text={actions[0].label}
              onClick={() => handleActionClick(actions[0].key)}
              options={actions}
              onMenuItemClick={(key: string) => handleActionClick(key)}
            />
          )}
        </div>
      </div>
      {deleteDialogOpen && (
        <MAlertDialog
          open={deleteDialogOpen}
          title={I18n.t("edit_post.delete_dialog.title")}
          description={I18n.t("edit_post.delete_dialog.description")}
          agreeText={I18n.t("edit_post.delete_dialog.agree")}
          disagreeText={I18n.t("edit_post.delete_dialog.cancel")}
          handleClose={(agree) => {
            setDeleteDialogOpen(false);
            if (agree) {
              handleDeletePolicyClick();
            }
          }}
        />
      )}
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(EditorialPolicyBottomBar);
