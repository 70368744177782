import * as React from "react";
import { connect } from "react-redux";
import { useMemo } from "react";
import { find } from "lodash";
import AddWidgetFieldsTab from "../components/AddWidgetFields/AddWidgetFieldsTab";
import {
  IWebsite,
  IWidget,
  IWidgetButtonProps,
  IWidgetField,
  IWidgetItem,
} from "../../../../../../../reducers/constants/objectTypes";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import ItemProperties from "../items/itemProperties/ItemProperties";
import MultiStepForm from "./MultiStepForm";
import OnePageForm from "./OnePageForm";
import ButtonWidgetItem from "../items/ButtonWidgetItem";
import { getWidgetAddableFields } from "../../../helpers/widgets";

type PropTypes = {
  selectedWebsite: IWebsite;

  widget: IWidget;
  selectedItem: number;
  selectedStep?: number;
  selectedFieldId?: string;
  button?: IWidgetButtonProps;
  removeField?: (field: IWidgetField) => void;
  reorderItems?: (fields: IWidgetField[]) => void;
  addPage?: () => void;
  setSelectedStep: (selectedStep: number) => void;
  setSelectedFieldId: (selectedField: string) => void;
  handleItemChange: (item: IWidgetItem) => void;
  editButton: (submitButton: IWidgetButtonProps) => void;
  addNewField: (field: IWidgetField) => void;
  editBackground: (background: IWidget["background"]) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  widget: state.widgetBuilder.widget,
  selectedItem: state.widgetBuilder.selectedItem,
  selectedStep: state.widgetBuilder.selectedStep,
  selectedFieldId: state.widgetBuilder.selectedFieldId,
  button: state.widgetBuilder.widget.button,
});

const mapDispatchToProps = (dispatch) => ({
  handleItemChange: (item: IWidgetItem) => dispatch({ type: WIDGET_BUILDER.ITEM_CHANGED, item }),
  setSelectedStep: (selectedStep: number) => dispatch({ type: WIDGET_BUILDER.SET_SELECTED_STEP, selectedStep }),
  setSelectedFieldId: (selectedFieldId: string) =>
    dispatch({ type: WIDGET_BUILDER.SET_SELECTED_FIELD, selectedFieldId }),
  addNewField: (field: IWidgetField) => dispatch({ type: WIDGET_BUILDER.ADD_NEW_FIELD, field }),
  removeField: (field: IWidgetField) => dispatch({ type: WIDGET_BUILDER.DELETE_FIELD, field }),
  editButton: (button) => dispatch({ type: WIDGET_BUILDER.BUTTON_CHANGED, button }),
  editBackground: (background) => dispatch({ type: WIDGET_BUILDER.EDIT_BACKGROUND, background }),
});

const FormWidgetBuildTab = (props: PropTypes) => {
  const { selectedWebsite, widget, selectedItem, selectedStep, selectedFieldId, button } = props;
  const {
    addNewField,
    removeField,
    addPage,
    setSelectedStep,
    handleItemChange,
    setSelectedFieldId,
    editButton,
    editBackground,
  } = props;

  const [addNewItemMode, setAddNewItemMode] = React.useState(false);
  const [selectedSubmitButton, setSelectedSubmitButton] = React.useState(null);

  const item = useMemo(() => widget.items[selectedItem], [widget, selectedItem]);

  function handleFieldEdit(field) {
    handleItemChange({
      ...item,
      steps: item.steps.map((step, i) => {
        if ((selectedStep || 0) === i) {
          return {
            ...step,
            fields: step.fields.map((f, fi) => {
              if (f.fieldId === field.fieldId) {
                return field;
              }
              return f;
            }),
          };
        }
        return step;
      }),
    });
  }

  if (selectedFieldId) {
    console.log("selectedFieldId", selectedFieldId, selectedWebsite);
    return (
      <ItemProperties
        field={find(widget.items[selectedItem].steps[selectedStep || 0].fields, (f) => f.fieldId === selectedFieldId)}
        onCancel={() => setSelectedFieldId(null)}
        selectedWebsite={selectedWebsite}
        onDone={(field) => {
          setSelectedFieldId(null);
          handleFieldEdit(field);
        }}
      />
    );
  }

  if (selectedSubmitButton) {
    console.log("selectedSubmitButton", selectedSubmitButton);
    return (
      <ButtonWidgetItem
        button={button}
        editButton={editButton}
        onClose={() => {
          setSelectedFieldId(null);
          setSelectedSubmitButton(null);
        }}
      />
    );
  }

  if (addNewItemMode) {
    console.log("addNewItemMode", addNewItemMode);
    return (
      <AddWidgetFieldsTab
        onClose={() => setAddNewItemMode(false)}
        addNewField={addNewField}
        addableFields={getWidgetAddableFields(widget)}
      />
    );
  }

  if (!item.multistep) {
    console.log("OnePageForm");
    return (
      <OnePageForm
        setAddNewItemMode={setAddNewItemMode}
        onSubmitButtonClick={() => {
          setSelectedSubmitButton(true);
        }}
        widget={widget}
        selectedItem={selectedItem}
        selectedStep={selectedStep}
        handleItemChange={handleItemChange}
        removeField={removeField}
        setSelectedStep={setSelectedStep}
        setSelectedFieldId={setSelectedFieldId}
        addNewField={addNewField}
        editBackground={editBackground}
      />
    );
  }

  console.log("MultiStepForm");
  return (
    <MultiStepForm
      onChange={(multistep) => {
        setSelectedStep(null);
        handleItemChange({ ...item, multistep });
      }}
      onClick={(field) => {
        setSelectedFieldId(field.fieldId);
      }}
      onAddNewItemMode={setAddNewItemMode}
      onAddPage={addPage}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWidgetBuildTab);
