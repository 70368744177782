import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { web } from "../../helpers/urlHelper";
import { AbilityContext } from "../../casl/can";
import VideoWorkFlowState from "./VideoWorkFlowState/VideoWorkFlowState";
import { eVideoProjectState, IWebsite } from "../../reducers/constants/objectTypes";
import { videoProjectStates } from "../../helpers/constants";

const useStyles = makeStyles(() =>
  createStyles({
    pageWrapper: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flexGrow: 1,
      display: "flex",
    },
  })
);
type PropTypes = {
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});
const VideoWorkFlow = (props: PropTypes) => {
  const classes = useStyles();
  const { selectedWebsite } = props;
  const ability = React.useContext<any>(AbilityContext);

  const routes = React.useMemo(() => {
    const allRoutes = [];
    videoProjectStates.forEach((state, index) => {
      if (ability.can("view", `video_workflow_${state}_tab`)) {
        allRoutes.push({
          url: web.videoWorkflow(state),
          route: (
            <Route
              key={selectedWebsite._id + index}
              exact={false}
              path={web.videoWorkflow(state)}
              component={() => <VideoWorkFlowState state={state} />}
            />
          ),
        });
      }
    });
    if (ability.can("view", `video_workflow_all_states_tab`)) {
      allRoutes.push({
        url: web.videoWorkflowAllStates(),
        route: (
          <Route
            key={"all_states_tab"}
            exact={false}
            path={web.videoWorkflowAllStates()}
            component={() => <VideoWorkFlowState />}
          />
        ),
      });
    }
    return allRoutes;
  }, [selectedWebsite, ability]);

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.content}>
        <Switch>
          {routes.map((route) => route.route)}
          <Redirect exact from={"*"} to={routes[0]?.url} />
        </Switch>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(VideoWorkFlow);
