import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import { IExpert } from "../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import ExpertCollapsibleCard from "./ExpertCollapsibleCard";
import useDebounce from "../../../../../hooks/useDebounce";

const useStyles = makeStyles((theme: Theme) => ({
  textFieldTitle: {
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    "&:first-child": {
      marginTop: 0,
    },
  },
  rowGaps: {
    gap: 20,
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  fieldTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    minWidth: 140,
    marginRight: 15,
  },
  profilePicWrapper: {
    marginRight: 40,
  },
  divider: {
    marginLeft: -30,
    marginRight: -30,
    marginTop: 20,
    marginBottom: 20,
  },
}));

type PropTypes = {
  expert: IExpert;

  handleExpertChange: (expert: IExpert) => void;
};

const ContactInfo = (props: PropTypes) => {
  const classes = useStyles(props);
  const { expert, handleExpertChange } = props;
  const [localData, setLocalData] = React.useState({ phoneNumber: expert.phoneNumber, email: expert.email });
  const debouncedLocalData = useDebounce(localData, 500);

  React.useEffect(() => {
    handleExpertChange({ ...expert, ...debouncedLocalData });
  }, [debouncedLocalData]);

  return (
    <ExpertCollapsibleCard title={I18n.t("experts.profile.contact_info")}>
      <div className={ClassNames(classes.rowWrapper, classes.rowGaps)}>
        <TextFieldMaxLength
          titleClassName={classes.textFieldTitle}
          key={`expert_phone_number`}
          titleAndLengthLocation={"top"}
          value={localData.phoneNumber}
          placeholder={I18n.t("experts.profile.write_ph")}
          title={I18n.t("experts.profile.phone_number")}
          borderRadius={4}
          handleValueChange={(phoneNumber) => {
            setLocalData({ ...localData, phoneNumber });
          }}
        />
        <TextFieldMaxLength
          titleClassName={classes.textFieldTitle}
          key={`expert_email`}
          titleAndLengthLocation={"top"}
          value={localData.email}
          placeholder={I18n.t("experts.profile.write_ph")}
          title={I18n.t("experts.profile.email")}
          borderRadius={4}
          handleValueChange={(email) => {
            setLocalData({ ...localData, email });
          }}
        />
      </div>
    </ExpertCollapsibleCard>
  );
};

export default ContactInfo;
