import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import * as React from "react";
import moment from "moment/moment";
import { Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import { DATE_FORMAT } from "../../../../../reducers/constants/consts";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";
import { IFunnelBreakdownColumn, IGoal, IWebsite } from "../../../../../reducers/constants/objectTypes";
import MTableCellLink from "../../../../../components/Table/Cells/MTableCellLink";
import { web } from "../../../../../helpers/urlHelper";
import CfProgressBar from "../../../../../components/CfProgressBar";
import localeHelper from "../../../../../helpers/localeHelper";
import Typography from "@material-ui/core/Typography";
import agent from "../../../../../agent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: 15,
    minWidth: 560,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
  },
  headerText: {
    fontSize: 14,
  },
  headerWrapper: {
    // backgroundColor: "white",
    border: "none",
    padding: "10px 20px 10px 0px",
    // width: 80,
  },
  tableContainer: {
    // width: "calc(100vw - 40px)",
    // overflowX: "unset",
    width: "100%",
    overflowY: "auto",
    maxHeight: 300,
    height: "100%",
  },
  table: {
    position: "relative",
    borderSpacing: "20px 0",
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: "auto",
    height: "100%",
    maxHeight: "calc(100vh - 225px)",
  },
  tableRow: {
    height: 47,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: "pointer",
    borderBottom: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      // borderRadius: 30,
      transition: "border-radius 500ms",
      clipPath: "xywh(0 0 100% 100% round 3.5em)",
    },
  },
  rowCell: {
    width: 80,
    padding: "0px 20px 0px 0px",
    cursor: "pointer",
    borderBottom: "none",
  },
  firstCell: {
    paddingLeft: 20,
  },
  tableHeader: {
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    zIndex: 3,
    top: 0,
    boxShadow: `0 1px 0px 0px ${theme.palette.divider}`,
  },
  closeButton: {
    position: "absolute",
    right: 10,
  },
  closeIcon: {
    fontSize: 24,
  },
}));

type Props = {
  open: boolean;
  website: IWebsite;
  dateTrunc: string;
  pageUrl: string;
  timeFrame: string;
  breakdownFilters: {
    filter: IFunnelBreakdownColumn;
    value: any;
  }[];
  onClose: () => void;
};

const GoalCompletionByPageExpandedDialog = (props: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [results, setResults] = React.useState<any[]>([]);
  const [goals, setGoals] = React.useState<IGoal[]>([]);
  const { open, website, dateTrunc, timeFrame, pageUrl, breakdownFilters, onClose } = props;

  React.useEffect(() => {
    if (!pageUrl || !timeFrame || !dateTrunc) {
      return;
    }
    setLoading(true);
    const urlFilter = breakdownFilters.find((bf) => bf.filter.label === "URL");
    agent.GoalCompletionReports.getGoalCompletionsByPageExpanded(
      website,
      pageUrl,
      timeFrame,
      dateTrunc,
      urlFilter?.filter?.value?.table || null,
      urlFilter?.filter?.value?.field || null,
      urlFilter?.value?.value || null
    )
      .then((res) => {
        setResults(res.results);
        setGoals(res.goals);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [website, dateTrunc, timeFrame, pageUrl]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"lg"}
      onClose={onClose}
      classes={{
        paper: classes.wrapper,
      }}
    >
      <div className={classes.header}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <Typography className={classes.headerText} variant={"subtitle2"}>{`${moment(dateTrunc).format(
          timeFrame === "month" ? DATE_FORMAT.READABLE_MONTH_YEAR : DATE_FORMAT.READABLE
        )} - `}</Typography>
        <Typography className={classes.headerText} variant={"body1"}>
          {pageUrl}
        </Typography>
      </div>
      {loading ? (
        <div style={{ display: "flex", padding: 30 }}>
          <div style={{ margin: "auto" }}>
            <CfProgressBar />
          </div>
        </div>
      ) : (
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={ClassNames(classes.headerWrapper, classes.firstCell)}>
                  <MTableHeaderLabel fontSize={14}>
                    {I18n.t("goal_completion_reports.column_names.goals", { count: results?.length || 0 })}
                  </MTableHeaderLabel>
                </TableCell>
                <TableCell className={classes.headerWrapper}>
                  <MTableHeaderLabel fontSize={14}>
                    {I18n.t("goal_completion_reports.column_names.type")}
                  </MTableHeaderLabel>
                </TableCell>
                <TableCell className={classes.headerWrapper}>
                  <MTableHeaderLabel fontSize={14}>
                    {I18n.t("goal_completion_reports.column_names.contribution")}
                  </MTableHeaderLabel>
                </TableCell>
                <TableCell className={classes.headerWrapper}>
                  <MTableHeaderLabel fontSize={14}>
                    {I18n.t("goal_completion_reports.column_names.journey")}
                  </MTableHeaderLabel>
                </TableCell>
                <TableCell className={classes.headerWrapper}>
                  <MTableHeaderLabel fontSize={14}>
                    {I18n.t("goal_completion_reports.column_names.date")}
                  </MTableHeaderLabel>
                </TableCell>
                <TableCell className={classes.headerWrapper}>
                  <MTableHeaderLabel fontSize={14}>
                    {I18n.t("goal_completion_reports.column_names.value")}
                  </MTableHeaderLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/*Totals row*/}
              {results.map((row, rowIndex) => (
                <>
                  <TableRow key={rowIndex} className={classes.tableRow}>
                    <TableCell className={ClassNames(classes.rowCell, classes.firstCell)}>
                      <MTableCellText
                        fontSize={14}
                        fontWeight={400}
                        textColor={"inherit"}
                        text={goals.find((g) => g._id === row.goal_id)?.name || "-"}
                      />
                    </TableCell>
                    <TableCell className={classes.rowCell}>
                      <MTableCellText
                        fontSize={14}
                        fontWeight={400}
                        textColor={"inherit"}
                        text={goals.find((g) => g._id === row.goal_id)?.goalType?.name || "-"}
                      />
                    </TableCell>
                    <TableCell className={classes.rowCell}>
                      <MTableCellText fontSize={14} fontWeight={400} textColor={"inherit"} text={row.contribution} />
                    </TableCell>
                    <TableCell className={classes.rowCell}>
                      <MTableCellLink
                        target={"_blank"}
                        href={web.croUserEvents(row.device_id)}
                        fontSize={14}
                        fontWeight={400}
                        text={`${row.device_id.slice(0, 15)}...`}
                      />
                    </TableCell>
                    <TableCell className={classes.rowCell}>
                      <MTableCellText
                        fontSize={14}
                        fontWeight={400}
                        textColor={"inherit"}
                        text={moment(row.goal_timestamp).format(DATE_FORMAT.READABLE)}
                      />
                    </TableCell>
                    <TableCell className={classes.rowCell}>
                      <MTableCellText
                        fontSize={14}
                        fontWeight={400}
                        textColor={"inherit"}
                        text={`${localeHelper.getCurrencyText(
                          parseFloat(row.partial_value || "0"),
                          "USD"
                        )}/${localeHelper.getCurrencyText(
                          parseFloat(goals.find((g) => g._id === row.goal_id)?.value?.value || "0"),
                          "USD"
                        )}`}
                      />
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Dialog>
  );
};

export default GoalCompletionByPageExpandedDialog;
