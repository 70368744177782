import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CfButton from "./CfButton";
import { I18n } from "react-redux-i18n";
import Button from "@material-ui/core/Button";

type PropTypes = {
  title: string;
  subTitle?: string;
  value: string[];
  onValueChange: (value: string[]) => void;
  buttonTitle?: string;
  onSubmit?: () => void;
  placeHolder?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  inputWrapper: {
    backgroundColor: theme.palette.common.white,

    display: "flex",
    padding: "10px 20px",
    flexDirection: "column",
    rowGap: "20px",
    borderRadius: "5px",
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25);",
    minWidth: "230px",
  },
  clearBtn: {
    fontSize: "14px",
    textTransform: "none",
    paddingLeft: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  clearBtnLabel: {
    justifyContent: "normal",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightLight as any,
  },
  valuesWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "7px",
  },
  valueWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  valueText: {
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  removeBtn: {
    padding: 0,
  },
  submitBtn: {
    padding: "7px 20px",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const MMultipleTextFieldInput = (props: PropTypes) => {
  const classes = useStyles();

  const { title, subTitle, buttonTitle, placeHolder, onSubmit, value } = props;

  const [currentValue, setCurrentValue] = useState("");

  return (
    <div className={classes.inputWrapper}>
      <div className={classes.titleWrapper}>
        <Typography className={classes.title}> {title} </Typography>
        <Typography className={classes.subTitle}> {subTitle} </Typography>
      </div>
      <div className={classes.valuesWrapper}>
        {value.map((item, index) => (
          <div className={classes.valueWrapper} key={index}>
            <Typography variant={"caption"} className={classes.valueText}>
              {item}
            </Typography>

            <IconButton
              className={classes.removeBtn}
              onClick={() => {
                const newValue = [...value];
                newValue.splice(index, 1);
                props.onValueChange(newValue);
              }}
            >
              <CloseIcon color={"error"} />
            </IconButton>
          </div>
        ))}
      </div>
      <TextField
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            if (!currentValue) return;
            props.onValueChange([...value, currentValue]);
            setCurrentValue("");
          }
        }}
        value={currentValue}
        onChange={(event) => {
          setCurrentValue(event.target.value);
        }}
        placeholder={placeHolder || I18n.t("strategy.multipleInput.defaultPlaceholder")}
      />

      <div className={classes.btnWrapper}>
        <Button
          onClick={() => {
            props.onValueChange([]);

            if (onSubmit) onSubmit();
          }}
          className={classes.clearBtn}
          color={"primary"}
          classes={{
            label: classes.clearBtnLabel,
          }}
        >
          {I18n.t("strategy.multipleInput.clear")}
        </Button>
        <CfButton
          onClick={() => {
            if (currentValue) {
              props.onValueChange([...value, currentValue]);
              setCurrentValue("");
            }
            if (onSubmit) onSubmit();
          }}
          text={buttonTitle || I18n.t("strategy.multipleInput.apply")}
          customClass={classes.submitBtn}
          height={35}
        />
      </div>
    </div>
  );
};

export default MMultipleTextFieldInput;
