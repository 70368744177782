import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import moment from "moment/moment";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "@material-ui/core";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import { IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import { AbilityContext } from "../../../casl/can";
import agent from "../../../agent";
import { clientPages } from "../../../helpers/urlHelper";
import PageEditorExpandableSideSection from "./PageEditorExpandableSideSection";
import richTextEditorHelper from "../../../editor/helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  resources: {
    height: "100%",
  },
  wrapper: {
    height: "100%",
    padding: 0,
  },
  section: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 0",
    borderTop: `solid 1px ${theme.palette.divider}`,
    "&:first-child": {
      borderTop: "none",
    },
    "&:last-child": {
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    width: "100%",
  },
  date: {
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: "pre",
  },
  url: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: 10,
  },
  statusRow: {
    flexDirection: "column",
    // justifyContent: "space-between",
    marginBottom: 15,
    alignItems: "center",
  },
}));

type PropTypes = {
  editor: ReactEditor;
  selectedWebsite: IWebsite;
  pageLoaded: boolean;
  page: IPage;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  editor: state.pageEditor.editor,
  pageLoaded: state.pageEditor.pageLoaded,
  page: state.pageEditor.page,
});

const mapDispatchToProps = (dispatch) => ({});

const PageEditorLinks = (props: PropTypes) => {
  const classes = useStyles(props);
  const { pageLoaded, page, selectedWebsite, editor } = props;
  const ability = React.useContext<any>(AbilityContext);

  const [linkingPagesLoaded, setLinkingPagesLoaded] = React.useState<boolean>(false);
  const [linkingPages, setLinkingPages] = React.useState<IPage[]>([]);
  const [linkedPages, setLinkedPages] = React.useState<IPage[]>([]);

  React.useEffect(() => {
    if (!pageLoaded || !editor || linkingPagesLoaded) {
      return;
    }
    setLinkedPages(richTextEditorHelper.getLinksItems(editor.children) || []);
    agent.Pages.getLinkingPages(selectedWebsite._id, page._id)
      .then((res) => {
        setLinkingPages(res.pages);
      })
      .catch((e) => {
        console.log(e, "Error getting linking pages");
      })
      .finally(() => {
        setLinkingPagesLoaded(true);
      });
  }, [pageLoaded, page, linkingPagesLoaded, selectedWebsite]);

  const scrollToLink = (link) => {
    try {
      console.log(editor.children);
      const [match] = Editor.nodes(editor, {
        match: (n) => n.type === "image",
      });
      console.log("match", match, Editor.isEditor(editor));
      const elementPath = ReactEditor.findPath(editor as ReactEditor, link);
      ReactEditor.focus(editor as ReactEditor);
      Transforms.select(editor, Editor.start(editor, elementPath));
    } catch (e) {
      console.log("can't select path:::", e);
    }
  };

  if (!pageLoaded || (!ability.can("writer", "role") && !ability.can("customer", "role"))) {
    return <div className={classes.resources} />;
  }

  console.log("linkedPages", linkedPages);
  return (
    <div className={classes.wrapper}>
      <PageEditorExpandableSideSection
        openByDefault
        sectionTitle={I18n.t("rich_text_editor.links.inbound_links", { count: linkingPages?.length })}
      >
        <>
          {linkingPages.map((linkingPage: IPage, index) => (
            <div key={`upload_${index}`} className={classes.statusRow}>
              <Typography className={classes.date} variant={"body1"}>
                {moment(linkingPage.lastPublishDate).format("MMM D, YY")}
              </Typography>
              <Tooltip title={linkingPage.title}>
                <Link
                  href={clientPages.getPageUrl(linkingPage, selectedWebsite)}
                  className={classes.url}
                  variant={"body1"}
                  target={"_blank"}
                >
                  {linkingPage.title}
                </Link>
              </Tooltip>
            </div>
          ))}
        </>
      </PageEditorExpandableSideSection>

      <PageEditorExpandableSideSection
        sectionTitle={I18n.t("rich_text_editor.links.outbound_links", { count: linkedPages?.length })}
      >
        <>
          {linkedPages.map((linkedPage: any, index) => (
            <div key={`upload_${index}`} className={classes.statusRow}>
              <Tooltip title={linkedPage.url}>
                <Typography
                  onClick={() => {
                    scrollToLink(linkedPage);
                  }}
                  // href={clientPages.getPageUrl(linkingPage, selectedWebsite)}
                  // href={linkedPage.url}
                  className={classes.url}
                  variant={"body1"}
                  // target={"_blank"}
                >
                  {linkedPage.url}
                </Typography>
              </Tooltip>
            </div>
          ))}
        </>
      </PageEditorExpandableSideSection>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorLinks);
