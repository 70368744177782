import { Transforms, Editor } from "slate";
import RichTextEditorHelper from "../../helper/richTextEditorHelper";

const inlineHtml = (editor: Editor): Editor => {
  const { insertText, insertData, isVoid } = editor;

  editor.isVoid = (element) => (element.type === "inline-html" ? true : isVoid(element));
  editor.insertText = (text) => {
    console.log("withLinks insertText", text);

    insertText(text);
  };

  editor.insertData = (data) => {
    console.log("withLinks insertData", data);
    const text = data.getData("text/plain");
    console.log("withLinks insertData", text);

    insertData(data);
  };
  return editor;
};

const isInlineHtmlActive = (editor) => {
  const [inlineHtml] = Editor.nodes(editor, { match: (n) => n.type === "inline-html" });
  return !!inlineHtml;
};

const deleteInlineHtml = (editor, path) => {
  Transforms.removeNodes(editor, { at: path });
};

const insertInlineHtml = (editor) => {
  const text = { text: "" };
  const voidNode: InlineHtmlElement = {
    type: "inline-html",
    html: "",
    uuid: RichTextEditorHelper.getUuid(),
    data: { style: { display: "flex", width: "100%" } },
    children: [text],
  };
  RichTextEditorHelper.insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading(editor, voidNode);
};

const updateInlineHtml = (editor, uuid: string, html: string) => {
  Transforms.setNodes(
    editor,
    {
      html,
    },
    {
      match: (n: InlineHtmlElement) => n.type === "inline-html" && n.uuid === uuid,
    }
  );
};

export type InlineHtmlElement = {
  type: "inline-html";
  html: string;
  uuid: string;
  data: any;
  children: { text: string }[];
};

export default {
  withInlineHtml: inlineHtml,
  deleteInlineHtml,
  insertInlineHtml,
  isInlineHtmlActive,
  updateInlineHtml,
};
