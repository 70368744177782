import { Collapse, makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import TextareaAutosize from "react-textarea-autosize";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import AiIcon from "../toolbar/icons/hover/AiIcon";
import MButtonGroup from "../../../components/MButtonGroup";
import MButton from "../../../components/MButton";
import MTextButton from "../../../components/MTextButton";
import { copyStringToClipboard } from "../../../helpers/fbScraperHelper";
import { editorType } from "../../types/editor.Types";
import agent from "../../../agent";
import slateMarkdownHelper from "../../../helpers/slateMarkdownHelper";
import { IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import MTextCounter from "../../../components/MTextCounter";

const useStyles = makeStyles((theme: Theme) => ({
  aiWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: "0px",
    marginLeft: 0,
  },
  textField: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    width: "100%",
    resize: "vertical",
    minHeight: 39,
    padding: "10px",
    margin: 0,
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    "&:focus": {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
  },
  row: {
    display: "flex",
    minHeight: 37,
    alignItems: "center",
    margin: 0,
    gap: 10,
  },
  labelCounterWrap: {
    minWidth: 80,
  },
  label: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  textInput: {
    fontFamily: theme.typography.fontFamily,
    resize: "none",
    border: "none",
    outline: "none",
    width: "100%",
    margin: (props: PropTypes) => props.margin || 0,
    fontSize: (props: PropTypes) => props.fontSize || 16,
    padding: (props: PropTypes) => props.padding || 0,
    lineHeight: 1.3,
    backgroundColor: "initial",
  },
  icon: {
    padding: 0,
  },
  innerWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "10px 15px",
    gap: 20,
    margin: (props: PropTypes) => props.margin || 0,
    borderRadius: 5,
    backgroundColor: "#fff",
    boxShadow: "2px 2px 15px 0px rgba(0, 0, 0, 0.25)",
    marginBottom: 20,
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: theme.typography.fontWeightLight as any,
    lineHeight: "21px",
  },
  actionsWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },

  actionButtonWrapper: {
    borderRadius: 3,
    border: `solid 1px ${theme.palette.divider}`,
    display: "flex",
    maxHeight: 31,
  },
  actionButton: {
    padding: "7px 10px",
    minHeight: 0,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    minWidth: 0,
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  generateButton: {
    maxWidth: 88,
    padding: "7px 15px",
    borderRadius: 3,
    maxHeight: 31,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  titleListWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingInlineStart: 0,
  },
  listTitle: {
    display: "flex",
    flexDirection: "row",
    color: theme.palette.text.primary,
    padding: 5,
    borderRadius: 3,
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
    "&:hover": {
      backgroundColor: "#EEF2F9",
      "& > $listActions": {
        display: "flex",
        alignItems: "center",
      },
    },
  },
  listActions: {
    display: "none",
    flexDirection: "row",
    margin: "0px 15px",
    gap: 10,
  },
  listAction: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  listItem: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight as any,
    lineHeight: "29px",
    flex: 1,
  },
  listActionSeparator: {
    width: 3,
    height: 3,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
  empty: {
    width: 10,
  },
}));

type PropTypes = {
  minRows?: number;
  maxRows?: number;
  text: string;
  margin?: string | number;
  padding?: string | number;
  fontSize?: string | number;
  placeholder?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange: (text: string) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  page?: IPage;
  selectedWebsite?: IWebsite;
  field: string;
  desiredCount: number;
  label: string;
};

const styleActions = [
  { label: "Generic", key: "generic" },
  { label: "Magazine", key: "magazine" },
  { label: "Short ", key: "short" },
  { label: "Academic", key: "academic" },
  { label: "Clickbait", key: "clickbait" },
  { label: "Social", key: "social" },
  { label: "Subject", key: "subject" },
  { label: "News", key: "news" },
];

const toneActions = [
  { label: "Informal", key: "informal" },
  { label: "Formal", key: "formal" },
  { label: "Friendly", key: "friendly" },
];
const types = [
  { label: "Rephrase existing", key: "rephrase" },
  { label: "Generate new", key: "generate_new" },
];
const MetaAssistantTextArea = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    text,
    field,
    minRows = 1,
    maxRows = undefined,
    onChange,
    onKeyDown = null,
    onBlur = null,
    onFocus = null,
    page,
    selectedWebsite,
    placeholder = I18n.t("rich_text_editor.insert_comments.comment_ph"),
    label,
    desiredCount,
  } = props;
  const [cursor, setCursor] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedStyleAction, setSelectedStyleAction] = React.useState(styleActions[0]);
  const [selectedToneAction, setSelectedToneAction] = React.useState(toneActions[0]);
  const [selectedType, setSelectedType] = React.useState(types[!text ? 1 : 0]);
  const [titles, setTitles] = React.useState([]);
  const [loadTitles, setLoadTitles] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ref = React.useRef(null);

  // https://stackoverflow.com/questions/46000544/react-controlled-input-cursor-jumps
  React.useEffect(() => {
    const input = ref.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref, cursor, text]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e.target.value);
  };
  React.useEffect(() => {
    onChange(text);
  }, []);

  const getMessage = () => {
    if (selectedType.key === "rephrase" && text) {
      const currentNodes = [{ type: editorType.headingOne, children: [{ text }] }];
      return {
        user: slateMarkdownHelper.slateTopMarkdown(currentNodes),
        system: `can you generate five ${selectedStyleAction.label}  Rephrased meta ${field}s ${
          selectedToneAction ? ` in a ${selectedToneAction.label} tone` : ""
        }. The result should be strictly a json format for example {"result":["title1","title2","more..."]}  and the json key should be "result"`,
      };
    }
    return {
      user: slateMarkdownHelper.slateTopMarkdown(JSON.parse(page.richText)),
      system: `can you generate five ${selectedStyleAction.label} meta ${field} ${
        selectedToneAction ? ` in a ${selectedToneAction.label} tone` : ""
      }. Use the text provided by the user as context. The result should be strictly a json format for example {"result":["title1","title2","more..."]} and the json key should be "result"`,
    };
  };

  const getTitles = async () => {
    setTitles([]);
    setLoadTitles(true);
    const message = getMessage();
    agent.EditorAssistant.assistant(selectedWebsite, {
      message: message.user,
      extraInstruction: false,
      instruction: message.system,
      data: { page: page?._id },
    })
      .then((res) => {
        const result = JSON.parse(res.result.text)?.result;
        setLoadTitles(false);
        if (result) {
          setTitles(result);
        } else {
          setLoadTitles(false);
          enqueueSnackbar(I18n.t("ai_assistant.bad_formatting_error"), {
            variant: "error",
          });
          setTitles([]);
        }
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.create_error", { msg: e.response.body.message }), {
          variant: "error",
        });
        setLoadTitles(false);
        setTitles([]);
      });
  };
  const handleElementDataChange = (title) => {
    onChange && onChange(title);
  };
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classes.aiWrapper}>
        <div className={classes.row}>
          <div className={classes.labelCounterWrap}>
            <div className={classes.label}>{label}</div>
            <MTextCounter text={text} desiredCount={desiredCount} />
          </div>
          <div className={ClassNames(classes.textField)}>
            <IconButton className={classes.icon} onClick={() => setOpen(!open)}>
              <AiIcon />
            </IconButton>
            <TextareaAutosize
              ref={ref}
              onBlur={onBlur}
              onFocus={onFocus}
              value={text}
              minRows={minRows}
              maxRows={maxRows}
              onChange={handleChange}
              onKeyDown={(e) => onKeyDown && onKeyDown(e)}
              placeholder={placeholder}
              onPaste={(e) => e.stopPropagation()}
              className={classes.textInput}
            />
          </div>
        </div>

        <Collapse in={open}>
          <div className={classes.row}>
            <div className={classes.labelCounterWrap} />
            <div className={classes.innerWrapper} contentEditable={false}>
              <Typography className={classes.title} variant={"subtitle2"}>
                {I18n.t("ai_assistant.generate_meta", { name: field })}
              </Typography>
              <div className={classes.actionsWrapper}>
                <MButtonGroup
                  wholePress
                  onMenuItemClick={(key) => setSelectedType(types.find((opt) => opt.key === key))}
                  onClick={() => {}}
                  wrapperCustomClass={classes.actionButtonWrapper}
                  customClass={classes.actionButton}
                  hasDivider={false}
                  options={types}
                  text={selectedType.label}
                />
                <MButtonGroup
                  wholePress
                  onMenuItemClick={(key) => setSelectedStyleAction(styleActions.find((opt) => opt.key === key))}
                  onClick={() => {}}
                  wrapperCustomClass={classes.actionButtonWrapper}
                  customClass={classes.actionButton}
                  hasDivider={false}
                  options={styleActions}
                  text={
                    selectedStyleAction?.label
                      ? `${selectedStyleAction.label} style`
                      : I18n.t("ai_assistant.select_style")
                  }
                />
                <MButtonGroup
                  wrapperCustomClass={classes.actionButtonWrapper}
                  wholePress
                  customClass={classes.actionButton}
                  onMenuItemClick={(key) => setSelectedToneAction(toneActions.find((opt) => opt.key === key))}
                  onClick={() => {}}
                  hasDivider={false}
                  options={toneActions}
                  text={
                    selectedToneAction?.label ? `${selectedToneAction.label} tone` : I18n.t("ai_assistant.select_tone")
                  }
                />
                <MButton
                  barSize={20}
                  disabled={!selectedStyleAction}
                  loading={loadTitles}
                  customClassNames={classes.generateButton}
                  onClick={() => {
                    if (selectedStyleAction) {
                      getTitles();
                    }
                  }}
                >
                  {I18n.t("ai_assistant.generate")}
                </MButton>
              </div>

              {titles && (
                <ol className={classes.titleListWrapper}>
                  {titles.map((title, i) => (
                    <div className={classes.listTitle} key={`title_${i + 1}`}>
                      <div className={classes.empty} />
                      <li className={classes.listItem}>{title}</li>
                      <div className={classes.listActions}>
                        <MTextButton
                          customClass={ClassNames(classes.listAction)}
                          text={I18n.t(`ai_assistant.insert`)}
                          onClick={() => {
                            handleElementDataChange(title);
                          }}
                        />
                        <div className={classes.listActionSeparator} />
                        <MTextButton
                          customClass={ClassNames(classes.listAction)}
                          text={I18n.t(`ai_assistant.actions.copy`)}
                          onClick={() => {
                            copyStringToClipboard(title, () =>
                              enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"), {
                                variant: "success",
                              })
                            );
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </ol>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </ClickAwayListener>
  );
};

export default MetaAssistantTextArea;
