import * as React from "react";
import { I18n } from "react-redux-i18n";
import TextInputMentions from "../../../../components/TextInputMentions";
import { IWebsite, IWebsiteBlogProps } from "../../../../../../reducers/constants/objectTypes";
import PageSection from "../../../../components/PageSection";
import { LENGTHS } from "../../../../../../reducers/constants/consts";

type PropTypes = {
  blog: IWebsiteBlogProps;
  website: IWebsite;
  onPropertyChanged: (blog) => void;
  locale: string;
  defaultLocale: string;
  emptyFields: string[];
};

const SafeBooksPageBlog = (props: PropTypes) => {
  const { blog, onPropertyChanged, emptyFields, locale, defaultLocale, website } = props;
  const { safebooksTemplate } = blog;

  return (
    <>
      <PageSection sectionTitle={I18n.t("website.blog.main_page")}>
        <TextInputMentions
          key={`blog_main_page_meta_title_${locale}`}
          tags={[]}
          error={emptyFields.includes(I18n.t(`website.meta_title`))}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? safebooksTemplate.mainPage.metaTitle
              : blog.translations[locale]?.safebooksTemplate.mainPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...blog,
                safebooksTemplate: { ...blog.safebooksTemplate, "mainPage.metaTitle": value },
              });
              return;
            }
            blog.translations[locale] = {
              ...(blog.translations[locale] || {}),
              safebooksTemplate: {
                ...(blog.translations[locale]?.safebooksTemplate || {}),
                "mainPage.metaTitle": value,
              },
            };
            onPropertyChanged({ ...blog });
          }}
        />
        <TextInputMentions
          key={`blog_main_page_meta_desc_${locale}`}
          error={emptyFields.includes(I18n.t(`website.meta_description`))}
          tags={[]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? safebooksTemplate.mainPage.metaDescription
              : blog.translations[locale]?.safebooksTemplate?.mainPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...blog,
                safebooksTemplate: { ...blog.safebooksTemplate, "mainPage.metaDescription": value },
              });
              return;
            }
            blog.translations[locale] = {
              ...(blog.translations[locale] || {}),
              safebooksTemplate: {
                ...(blog.translations[locale]?.safebooksTemplate || {}),
                "mainPage.metaDescription": value,
              },
            };
            onPropertyChanged({ ...blog });
          }}
        />
      </PageSection>
    </>
  );
};

export default SafeBooksPageBlog;
