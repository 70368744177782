import React from "react";
import { Slide, Dialog, Paper, ClickAwayListener, Typography, Theme, makeStyles } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { I18n } from "react-redux-i18n";

import CfButton from "./CfButton";
import MTextButton from "./MTextButton";
import MTextField from "./MTextField";

const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: 999,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  activeIcon: {
    fill: theme.palette.primary.main,
  },
  paper: {
    padding: 20,
    width: 440,
    border: "none",
  },
  fieldsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  textFieldWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    margin: 0,
  },
  fieldLabel: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(12),
    marginBottom: 5,
  },
  bottomWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttonWrapper: { display: "flex", flexDirection: "row", alignItems: "center" },
}));

type PropTypes = {
  open: boolean;
  title?: string;
  inputs: {
    name: string;
    type?: string;
    value: any;
    label?: string;
    placeholder?: string;
    required?: boolean;
  }[];
  buttonProps: {
    text: string;
    loading?: boolean;
    disabled?: boolean;
  };
  handleChange: ({ name, value }: { name: string; value: any }) => void;
  handleClose: (e?: any) => void;
  handleSubmit: (e?: any) => void;
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const MInputsDialog = (props: PropTypes) => {
  const { open, title, inputs, buttonProps, handleChange, handleClose, handleSubmit } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={handleClose}>
      <Paper className={classes.paper} contentEditable={false}>
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.container}>
            {title && <Typography variant={"h6"}>{title}</Typography>}
            <div className={classes.fieldsWrapper}>
              {inputs.map((input, index) => (
                <div className={classes.textFieldWrapper} key={index}>
                  {input.label && (
                    <Typography color={"textSecondary"} className={classes.fieldLabel} variant={"body2"}>
                      {input.label}
                    </Typography>
                  )}
                  <MTextField
                    fullWidth
                    outlined
                    autoFocus
                    borderRadius={3}
                    type={input.type || "text"}
                    value={input.value}
                    customClass={classes.textField}
                    onChange={(value) => handleChange({ name: input.name, value })}
                    placeholder={input.placeholder}
                  />
                </div>
              ))}
            </div>

            <div className={classes.bottomWrapper}>
              <div className={classes.buttonWrapper}>
                <MTextButton text={I18n.t("common.cancel")} onClick={handleClose} />
                <CfButton
                  loading={buttonProps.loading}
                  height={40}
                  disabled={buttonProps.disabled}
                  text={buttonProps.text || I18n.t("common.save")}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Paper>
    </Dialog>
  );
};

export default MInputsDialog;
