import React from "react";
import { I18n } from "react-redux-i18n";
import {
  IWidgetLinkDialogRedirectToURLProps,
  IWidgetFieldProperties,
  IWidgetLinkDialogCloseDialogProps,
} from "../../../../../../../reducers/constants/objectTypes";
import GenericClickActionWidgetItem from "./components/GenericClickActionWidgetItem";

type PropTypes = {
  fieldType: string;
  properties: IWidgetFieldProperties;
  onChange: (properties: IWidgetLinkDialogRedirectToURLProps | IWidgetLinkDialogCloseDialogProps) => void;
};

const DialogLinkWidgetItem = (props: PropTypes) => (
  <GenericClickActionWidgetItem {...props} settingsLabel={I18n.t(`widgets_builder.widget_editor.fields.labels.link`)} />
);

export default DialogLinkWidgetItem;
