import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  textFields: {
    width: 320,
    marginBottom: 10,
    border: "none",
    borderRadius: 55,
    maxHeight: 45,
    "& > *": {
      height: 45,
      borderRadius: 55,
      fontSize: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "13px 25px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(25px, 14px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
}));

type PropTypes = {
  type?: string;
  label: string;
  value: string;
  onChange: (string) => void;
};

const AuthTextField = (props: PropTypes) => {
  const classes = useStyles(props);
  const { type = "text", value, label, onChange } = props;

  return (
    <TextField
      id="outlined-required"
      label={label}
      value={value}
      type={type}
      className={classes.textFields}
      onChange={(event) => onChange(event.target.value)}
      variant="outlined"
    />
  );
};

export default AuthTextField;
