import React from "react";
import { I18n } from "react-redux-i18n";
import HoverLabelBaseComponent from "../BaseComponents/HoverLabelBaseComponent";
type PropTypes = {};

const NormalHoverLabelElement = (props: PropTypes) => {
  return (
    <HoverLabelBaseComponent
      labels={[I18n.t("rich_text_editor.button.button_normal"), I18n.t("rich_text_editor.button.button_hover")]}
    />
  );
};
export default NormalHoverLabelElement;
