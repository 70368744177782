import { VIDEO_EDITOR } from "./constants/actionTypes";

export const initialState = {
  videoProjectLoaded: false,
  videoProject: null,
  pendingUploads: {
    videos: [],
    audios: [],
    images: [],
  },
  videoElement: null,
  videoList: [],
  canvas: { width: 0, height: 0, color: "black" },
  time: 0,
  timelineEffects: {
    "comment-effect": {
      id: "comment-effect",
      name: "Comment Effect",
    },
    "video-effect": {
      id: "video-effect",
      name: "Video Effect",
    },
    "audio-effect": {
      id: "audio-effect",
      name: "Audio Effect",
    },
    "image-effect": {
      id: "image-effect",
      name: "Image Effect",
    },
    "text-effect": {
      id: "text-effect",
      name: "Text Effect",
    },
  },
  timelineState: {
    time: 0,
    isPlaying: false,
    zoom: 5,
    zoomWidth: 160,
    scale: 1,
    volume: 0.5,
    isBuffering: false,
    playAfterBuffering: false,
  },
  selectedRow: null,
  selectedActionId: null,
  selectedTimelineId: null,
  selectedTool: null,
  selectedTab: "comments",

  // not used
  selectedAction: null,
  stackedVideoList: [],
  textList: [],
  imageList: [],
  audioList: [],
  play: false,
  edit: "upload",
  element: 0,
  scale: 1,
  startLeft: 20,
  zoom: 10,
  zoomWidth: 160,
  zoomIn: 0,
  zoomOut: 0,
  scroll: 0,
  subtitleDefaultX: 0,
  subtitleDefaultY: 0,
  subtitleDefaultSize: 20,
  subtitleDefaultColor: "#000000",
  cursorUpdated: "0",
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_EDITOR.PROJECT_LOADED:
      return {
        ...state,
        videoProjectLoaded: true,
        videoProject: action.payload.videoProject,
      };
    case VIDEO_EDITOR.PROJECT_UNLOADED:
      return initialState;
    case VIDEO_EDITOR.EDIT_PROJECT:
      return {
        ...state,
        videoProject: action.payload.videoProject,
      };
    case VIDEO_EDITOR.SET_PENDING_UPLOADS:
      console.log("action.payload", action.payload);
      return {
        ...state,
        pendingUploads: action.payload.pendingUploads,
      };
    case VIDEO_EDITOR.PROPERTY_CHANGE:
      return {
        ...state,
        ...action.property,
      };
    case VIDEO_EDITOR.ROW_ACTION_SELECTED:
      return {
        ...state,
        selectedRow: action.row,
        selectedAction: action.action,
      };
    case VIDEO_EDITOR.ROWS_CHANGED:
      return {
        ...state,
        videoProject: {
          ...state.videoProject,
          timelines: state.videoProject.timelines.map((timeline) => {
            if (timeline.selected) {
              return {
                ...timeline,
                rows: action.timelineRows,
              };
            }
            return timeline;
          }),
        },
      };
    case VIDEO_EDITOR.TIMELINE_STATE_PROPERTY_CHANGED:
      return {
        ...state,
        timelineState: {
          ...state.timelineState,
          ...action.property,
        },
      };
    case VIDEO_EDITOR.SET_SELECTED_ACTION_ID:
      return {
        ...state,
        selectedActionId: action.payload.selectedActionId,
      };
    case VIDEO_EDITOR.SET_SELECTED_TIMELINE:
      return {
        ...state,
        timelineState: {
          ...state.timelineState,
          time: 0,
          isPlaying: false,
        },
        videoProject: {
          ...state.videoProject,
          timelines: state.videoProject.timelines.map((t, index) => {
            if (index === action.payload.fileIndex) {
              return {
                ...t,
                selected: true,
              };
            }
            return {
              ...t,
              selected: false,
            };
          }),
        },
      };
    default:
      return state;
  }
};
