import React, { useEffect, useRef } from "react";
import { I18n } from "react-redux-i18n";
import { Typography } from "@material-ui/core";
import { nanoid } from "nanoid";

import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { IFile, IProduct, IWebsite } from "../../../../../reducers/constants/objectTypes";
import ImageItem from "./components/ImageItem";
import useDebounce from "../../../../../hooks/useDebounce";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
  },
  imagesWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 10,
  },
  imageCard: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  imageWrapper: {
    width: "100%",
    height: 180,
    backgroundColor: "#EEF2F9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  insertImage: {
    cursor: "pointer",
    "&:hover": {
      "& svg": {
        fill: theme.palette.primary.main,
      },
    },
  },
  image: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
  title: {
    fontSize: 14,
    lineHeight: "normal",
    fontWeight: theme.typography.fontWeightBold as any,
    textTransform: "capitalize",
  },
}));

type PropTypes = {
  website: IWebsite;
  product: IProduct;
  onChange: (product: IProduct) => void;
};

const ImagesView = (props: PropTypes) => {
  const classes = useStyles();
  const { product, website, onChange } = props;

  const previousProduct = useRef(product);
  const debouncedProduct = useDebounce(product, 5050);

  useEffect(() => {
    if (previousProduct.current !== debouncedProduct) {
      previousProduct.current = debouncedProduct;
    }
  }, [debouncedProduct]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.imagesWrapper}>
        {["iconFile", "logoFile", "avatarFile"].map((field) => (
          <div key={field} className={classes.imageCard}>
            <Typography className={classes.title}>{I18n.t(`product_dialog.images_view.${field}`)}</Typography>
            <ImageItem
              image={product[field]}
              website={website}
              onChange={(image) => onChange({ ...product, [field]: image })}
              onRemove={() => {
                onChange({ ...product, [field]: null as IFile });
              }}
              onUndo={() => {
                onChange({ ...product, [field]: product[field] });
              }}
            />
          </div>
        ))}
      </div>
      <div className={classes.imagesWrapper}>
        {[...product.imageFiles, {} as IFile].map((image, index) => (
          <div key={`${image?._id}-item-${index}`} className={classes.imageCard}>
            <Typography className={classes.title}>{`${I18n.t("product_dialog.images_view.image")} ${
              index + 1
            }`}</Typography>
            <ImageItem
              image={image}
              website={website}
              onChange={(image) => {
                const imageFiles = [...product.imageFiles];
                imageFiles[index] = image;
                onChange({ ...product, imageFiles });
              }}
              onRemove={() => {
                const imageFiles = [...product.imageFiles];
                imageFiles.splice(index, 1);
                onChange({ ...product, imageFiles });
              }}
              onUndo={() => {
                console.log("onUndo", image, index, product.imageFiles);
                const imageFiles = [...product.imageFiles];
                imageFiles[index] = image;
                onChange({ ...product, imageFiles });
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImagesView;
