import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import ListItem from "@material-ui/core/ListItem";
import ClassNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import { Theme, Typography } from "@material-ui/core";
import moment from "moment/moment";
import Chip from "@material-ui/core/Chip";
import heading from "../../../../../img/heading.png";
import { IExpert, IWebsite } from "../../../../../reducers/constants/objectTypes";
import { DATE_FORMAT } from "../../../../../reducers/constants/consts";
import ExpertMoreOptionsButton from "./ExpertMoreOptionsButton";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
  },
  itemWrapper: {
    display: "flex",
    padding: "0 20px",
    height: 57,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.divider,
      borderRadius: 30,
      transition: "border-radius 500ms",
    },
  },
  listItem: {
    display: "block",
    padding: 0,
    fontSize: "12px",
  },
  firstColumn: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    width: "40%",
    position: "relative",
    paddingRight: 35,
  },
  column: {
    width: "8%",
    paddingRight: 10,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
  },
  columnWebsites: {
    width: "20%",
  },
  columnEmail: {
    width: "16%",
    textTransform: "none",
  },
  iconColumn: {
    width: 40,
  },
  topicNameWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 20,
  },
  topicName: {
    fontSize: "16px",
    cursor: "pointer",
    width: "fit-content",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginLeft: 25,
  },
  moreOptionsColumn: {
    width: 20,
  },
  imgRow: { height: 25, width: 25, borderRadius: 100 },
  helpSpan: {
    backgroundColor: theme.palette.secondary.main,
    padding: 3,
    borderRadius: "50%",
    marginLeft: 10,
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 900,
    cursor: "help",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  expert: IExpert;
  handleClick(expert: IExpert): void;
  deleteExpert(): void;
  handleExpertChange(expert: IExpert): void;
};

const ExpertsListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, expert, handleClick, deleteExpert, handleExpertChange } = props;

  return (
    <ListItem
      className={ClassNames(classes.itemWrapper)}
      onClick={() => {
        handleClick(expert);
      }}
    >
      <div className={classes.firstColumn}>
        <img alt={"form image"} src={heading} className={classes.imgRow} />
        <div className={classes.topicNameWrapper}>
          <Tooltip enterDelay={900} title={`${expert.firstName} ${expert.lastName}`} arrow>
            <Typography className={classes.topicName} variant="subtitle2">
              {`${expert.firstName} ${expert.lastName}`}
            </Typography>
          </Tooltip>
        </div>
      </div>
      <div className={classes.column}>{expert.active ? "Yes" : "No"}</div>
      <div className={classes.column}>{moment(expert.createdDate).format(DATE_FORMAT.READABLE_CHART)}</div>
      <div className={ClassNames(classes.column, classes.columnEmail)}>{expert.email}</div>
      <div className={ClassNames(classes.column, classes.columnWebsites)}>
        {expert.user?.websites?.map((w) => (
          <Chip size={"small"} label={w.name} />
        ))}
      </div>
      <div className={classes.column}>
        {expert.user?.locales?.map((locale) => (
          <Chip size={"small"} label={locale} />
        ))}
      </div>
      <div className={classes.iconColumn}>
        <ExpertMoreOptionsButton
          handleDeletePermanentelyClick={() => {
            deleteExpert();
          }}
        />
      </div>
    </ListItem>
  );
};

export default ExpertsListItem;
