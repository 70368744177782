import * as React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { find } from "lodash";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";

import MSelect from "../../../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  title: {
    color: (props: PropTypes) => props.titleColor || theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 10,
    whiteSpace: "pre",
  },
  experienceWrapper: {
    width: ({ fullWidth }: PropTypes) => (fullWidth ? "100%" : 200),
    minWidth: 105,
  },
}));

const monthsArr = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

type PropTypes = {
  title?: string;
  titleColor?: string;
  value?: number;
  fullWidth?: boolean;
  placeholder?: string;
  className?: string;
  disabled?: boolean;

  onChange: (month: number) => void;
};

const ExperienceMonthSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const { value, title, placeholder, className, disabled } = props;
  const { onChange } = props;

  const months = React.useMemo(
    () =>
      monthsArr.map((month, i) => ({
        value: i + 1,
        label: month,
      })),
    []
  );

  return (
    <div className={classes.experienceWrapper}>
      {title && (
        <Typography className={ClassNames(classes.title)} variant={"subtitle2"}>
          {title}
        </Typography>
      )}
      <MSelect
        customClass={ClassNames(classes.wrapper, className)}
        fullWidth
        options={months}
        disabled={disabled}
        value={value !== null ? find(months, { value }) : null}
        optionLabel={"label"}
        optionValue={"value"}
        borderWidth={1}
        height={38}
        placeholder={placeholder || "Select"}
        isBold={false}
        menuPosition={"fixed"}
        handleChange={(item) => {
          onChange(item.value);
        }}
      />
    </div>
  );
};

export default ExperienceMonthSelect;
