import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import ColumnValueFilter from "./components/ColumnValueFilter";
import { IFunnelBreakdownColumn, IWebsite } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

type PropTypes = {
  filter: IFunnelBreakdownColumn;
  placeholder?: string;
  website: IWebsite;
  columnValue: string;
  handleChange: (filter: IFunnelBreakdownColumn, columnValue: any) => void;
};

const MTableToolbarDbBreakdownFilter = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website, filter, columnValue, placeholder, handleChange } = props;

  return (
    <div className={classes.wrapper}>
      <ColumnValueFilter
        filter={filter}
        placeholder={placeholder}
        value={columnValue}
        handleChange={(v) => {
          handleChange(filter, v);
        }}
        selectedWebsite={website}
      />
    </div>
  );
};

export default MTableToolbarDbBreakdownFilter;
