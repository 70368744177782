import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import GlobeIcon from "@material-ui/icons/Language";
import EditIcon from "@material-ui/icons/Edit";
import ClassNames from "classnames";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import agent from "../../../../agent";
import CfButton from "../../../../components/CfButton";
import MTextField from "../../../../components/MTextField";
import MHeaderMetaTags from "../../../../components/MHeaderMetaTags";

const tableHeader = "transparent";
const tableHeaderBorder = "transparent";
const tableBorder = "transparent";
const rowBg = "transparent";

const useStyles = makeStyles((theme: Theme) => ({
  statisticsWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "white",
    height: "calc(100vh - 93px)",
    overflowY: "auto",
  },
  tableContainer: {
    height: "fit-content",
    overflow: "initial",
    display: "block",
    margin: "2em auto",
    width: "90%",
    maxWidth: 1200,
    minWidth: 950,
    boxSizing: "border-box",
    "& div": {
      boxSizing: "border-box",
    },
  },
  flexTable: {
    display: "flex",
    flexFlow: "row",
    borderLeft: `solid 1px ${tableBorder}`,
    transition: "0.5s",
    // '&:first-of-type': {
    "&$header": {
      borderTop: `solid 1px ${tableHeaderBorder}`,
      borderLeft: `solid 1px ${tableHeaderBorder}`,
      "& $flexRow": {
        background: tableHeader,
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold as any,
        borderColor: tableHeaderBorder,
        // textAlign: 'center',
        alignItems: "flex-end",
      },
    },
    "&$row:nth-child(odd)": {
      "& $flexRow": {
        background: rowBg,
      },
    },
    "&:hover": {
      background: theme.palette.secondary.main,
      transition: "500ms",
      borderRadius: 50,
      "&$topHeader": {
        backgroundColor: "white",
      },
      "&$header": {
        backgroundColor: "white",
      },
      "& $editIcon": {
        visibility: "unset",
        width: "unset",
        marginRight: 28,
      },
      "& $websiteIcon": {
        visibility: "hidden",
        width: 0,
        margin: 0,
      },
    },
  },

  flexRow: {
    width: "calc(100% / 8)",
    textAlign: "left",
    alignItems: "center",
    display: "flex",
    // padding: '0em 1em',
    paddingLeft: 10,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    height: 75,
    borderRight: `solid 1px ${tableBorder}`,
    borderBottom: `solid 1px ${tableBorder}`,
    "& $first": {
      width: "100%",
      borderBottom: `solid 1px ${tableBorder}`,
    },
  },
  rowspan: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "flex-start",
    justifyContent: "center",
  },

  column: {
    display: "flex",
    flexFlow: "column wrap",
    width: "75%",
    padding: 0,
    "& $flexRow": {
      display: "flex",
      flexFlow: "row wrap",
      width: "100%",
      padding: 0,
      border: 0,
      borderBottom: `solid 1px ${tableBorder}`,
      "&:hover": {
        background: "#F5F5F5",
        transition: "500ms",
      },
    },
  },
  flexCell: {
    width: "calc(100% / 3)", // 1px = border right
    textAlign: "center",
    padding: "0.5em 0.5em",
    borderRight: `solid 1px ${tableBorder}`,
    // flex: 1 1 33.3%;
    "&:last-child": {
      // border-right: 0;
    },
  },
  first: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  row: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  topHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    "& $flexRow": {
      paddingLeft: 100,
      height: 30,
      color: theme.palette.text.hint,
      textAlign: "center",
      fontSize: theme.typography.pxToRem(12),
    },
  },
  header: {
    position: "sticky",
    top: 28,
    backgroundColor: "white",
    "& $flexRow": {
      borderBottom: "solid 1px",
    },
  },
  spacer: { width: "35%" },
  total: {
    width: "33%",
  },
  monthly: { width: "32%" },

  website: {
    width: "15%",
    justifyContent: "space-between",
  },
  websiteName: {
    display: "flex",
    alignItems: "center",
  },
  planName: { width: "10%" },
  startDate: { width: "10%" },
  totalForumPosts: { width: "6%" },
  totalBlogPosts: { width: "6%" },
  totalTargetedPosts: { width: "6%" },
  totalLearningCenterPosts: { width: "6%" },
  totalTopics: { width: "6%" },
  inReviewPosts: { width: "9%" },
  dividerColumn: { width: "3%" },
  monthlyForumPosts: { width: "6%" },
  monthlyBlogPosts: { width: "6%" },
  monthlyTargetedPosts: { width: "6%" },
  monthlyLearningCenterPosts: { width: "6%" },
  monthlyTopics: { width: "6%" },
  websiteIcon: {
    marginRight: 20,
    fill: theme.palette.primary.main,
  },
  editIcon: {
    fontSize: theme.typography.pxToRem(16),
    cursor: "pointer",
    marginRight: 0,
    visibility: "hidden",
    width: 0,
    "&:hover": {
      fill: theme.palette.primary.main,
      transition: "500ms",
    },
  },
  saveButton: {
    marginRight: 10,
    paddingRight: 10,
    paddingLeft: 10,
    minWidth: 0,
    minHeight: 30,
  },
  textField: {
    margin: 0,
  },
  textFieldInput: {},
  red: {
    color: "red",
  },
  tooltip: {
    whiteSpace: "pre-wrap",
  },
}));

type PropTypes = {};

const Statistics = (props: PropTypes) => {
  const classes = useStyles(props);
  const [statistics, setStatistics] = React.useState(null);
  const [editedWebsite, setEditedWebsite] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    agent.Statistics.websites()
      .then((res) => {
        setStatistics(res.statistics);
      })
      .catch((e) => {});
  }, []);

  const onUpdateWebsiteClicked = () => {
    agent.Websites.update(editedWebsite)
      .then((res) => {
        setStatistics(
          statistics.map((websiteStatistics) => {
            if (websiteStatistics.website._id === res.website._id) {
              return {
                ...websiteStatistics,
                website: res.website,
              };
            }
            return websiteStatistics;
          })
        );
        setEditedWebsite(null);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  return (
    <div className={classes.statisticsWrapper}>
      <MHeaderMetaTags title={I18n.t(`meta_tags.legacy.creator_stats.title`)} />
      <div className={classes.tableContainer} role="table" aria-label="Destinations">
        <div className={ClassNames(classes.flexTable, classes.topHeader)} role="rowgroup">
          <div className={ClassNames(classes.flexRow, classes.spacer)} role="columnheader" />
          <div className={ClassNames(classes.flexRow, classes.total)} role="columnheader">
            {I18n.t("websites_statistics.existing_content")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.monthly)} role="columnheader">
            {I18n.t("websites_statistics.this_month")}
          </div>
        </div>
        <div className={ClassNames(classes.flexTable, classes.header)} role="rowgroup">
          <div className={ClassNames(classes.flexRow, classes.website)} role="columnheader">
            {I18n.t("websites_statistics.website")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.startDate)} role="columnheader">
            {I18n.t("websites_statistics.start_date")}
          </div>
          {/* <div className={ClassNames(classes.flexRow, classes.planName)} role="columnheader">{I18n.t('websites_statistics.plan')}</div> */}
          <div className={ClassNames(classes.flexRow, classes.totalForumPosts)} role="columnheader">
            {I18n.t("websites_statistics.forum_questions")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.totalForumPosts)} role="columnheader">
            {I18n.t("websites_statistics.forum_answers")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.totalTargetedPosts)} role="columnheader">
            {I18n.t("websites_statistics.targeted_posts")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.totalBlogPosts)} role="columnheader">
            {I18n.t("websites_statistics.blog")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.totalLearningCenterPosts)} role="columnheader">
            {I18n.t("websites_statistics.learning_center")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.totalTopics)} role="columnheader">
            {I18n.t("websites_statistics.topics")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.inReviewPosts)} role="columnheader">
            {I18n.t("websites_statistics.in_review")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.dividerColumn)} role="columnheader" />
          <div className={ClassNames(classes.flexRow, classes.monthlyForumPosts)} role="columnheader">
            {I18n.t("websites_statistics.forum_questions")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.monthlyForumPosts)} role="columnheader">
            {I18n.t("websites_statistics.forum_answers")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.monthlyTargetedPosts)} role="columnheader">
            {I18n.t("websites_statistics.targeted_posts")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.monthlyBlogPosts)} role="columnheader">
            {I18n.t("websites_statistics.blog")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.monthlyLearningCenterPosts)} role="columnheader">
            {I18n.t("websites_statistics.learning_center")}
          </div>
          <div className={ClassNames(classes.flexRow, classes.monthlyTopics)} role="columnheader">
            {I18n.t("websites_statistics.topics")}
          </div>
        </div>
        {statistics &&
          statistics.map((websiteStatistics, index) => (
            <div key={websiteStatistics.website.name}>
              {editedWebsite !== null && websiteStatistics.website._id === editedWebsite._id ? (
                <div className={ClassNames(classes.flexTable, classes.row)} role="rowgroup">
                  <div className={ClassNames(classes.flexRow, classes.website, classes.first)} role="cell">
                    <div className={classes.websiteName}>
                      <CfButton
                        customClass={classes.saveButton}
                        onClick={(e) => onUpdateWebsiteClicked()}
                        text={I18n.t("websites_statistics.save")}
                      />
                      {websiteStatistics.website.name}
                    </div>
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.startDate)} role="cell">
                    <MTextField
                      customClass={classes.textField}
                      inputClassName={classes.textFieldInput}
                      type={"date"}
                      onChange={(text) => setEditedWebsite({ ...editedWebsite, startDate: text })}
                      value={moment(editedWebsite.startDate).format("YYYY-MM-DD")}
                      placeholder={I18n.t("websites_statistics.start_date_ph")}
                    />
                  </div>
                  {/* <div className={ClassNames(classes.flexRow, classes.planName)} role="cell"> */}
                  {/*  <MTextField */}
                  {/*    customClass={classes.textField} */}
                  {/*    inputClassName={classes.textFieldInput} */}
                  {/*    onChange={text => setEditedWebsite({...editedWebsite, planName: text})} */}
                  {/*    value={editedWebsite.planName} */}
                  {/*    placeholder={I18n.t('websites_statistics.plan_ph')} */}
                  {/*  /> */}
                  {/* </div> */}
                  <div className={ClassNames(classes.flexRow, classes.totalForumPosts)} role="cell">
                    {websiteStatistics.total.forum}
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.totalForumPosts)} role="cell">
                    {websiteStatistics.total.forumAnswers}
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.totalTargetedPosts)} role="cell">
                    {websiteStatistics.total.targetedPosts}
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.totalBlogPosts)} role="cell">
                    {websiteStatistics.total.blog}
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.totalLearningCenterPosts)} role="cell">
                    {websiteStatistics.total.learningCenter}
                  </div>

                  <div className={ClassNames(classes.flexRow, classes.totalTopics)} role="cell">
                    {websiteStatistics.total.topics}
                  </div>

                  <div className={ClassNames(classes.flexRow, classes.inReviewPosts)} role="cell">
                    {websiteStatistics.review.forum +
                      websiteStatistics.review.forumAnswers +
                      websiteStatistics.review.targetedPosts +
                      websiteStatistics.review.blog +
                      websiteStatistics.review.learningCenter +
                      websiteStatistics.review.topics}
                  </div>

                  <div className={ClassNames(classes.flexRow, classes.dividerColumn)} role="cell" />

                  <div className={ClassNames(classes.flexRow, classes.monthlyForumPosts)} role="cell">
                    {`${websiteStatistics.monthly.forum}/`}
                    <MTextField
                      type={"number"}
                      customClass={classes.textField}
                      inputClassName={classes.textFieldInput}
                      onChange={(text) => setEditedWebsite({ ...editedWebsite, monthlyForumPosts: text })}
                      value={editedWebsite.monthlyForumPosts}
                    />
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.monthlyForumPosts)} role="cell">
                    {websiteStatistics.monthly.forumAnswers}
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.monthlyTargetedPosts)} role="cell">
                    {`${websiteStatistics.monthly.targetedPosts}/`}
                    <MTextField
                      type={"number"}
                      customClass={classes.textField}
                      inputClassName={classes.textFieldInput}
                      onChange={(text) => setEditedWebsite({ ...editedWebsite, monthlyTargetedPosts: text })}
                      value={editedWebsite.monthlyTargetedPosts}
                    />
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.monthlyBlogPosts)} role="cell">
                    {`${websiteStatistics.monthly.blog}/`}
                    <MTextField
                      type={"number"}
                      customClass={classes.textField}
                      inputClassName={classes.textFieldInput}
                      onChange={(text) => setEditedWebsite({ ...editedWebsite, monthlyBlogPosts: text })}
                      value={editedWebsite.monthlyBlogPosts}
                    />
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.monthlyLearningCenterPosts)} role="cell">
                    {`${websiteStatistics.monthly.learningCenter}/`}
                    <MTextField
                      type={"number"}
                      customClass={classes.textField}
                      inputClassName={classes.textFieldInput}
                      onChange={(text) => setEditedWebsite({ ...editedWebsite, monthlyLearningCenterPosts: text })}
                      value={editedWebsite.monthlyLearningCenterPosts}
                    />
                  </div>

                  <div className={ClassNames(classes.flexRow, classes.monthlyTopics)} role="cell">
                    {websiteStatistics.monthly.topics}
                  </div>
                </div>
              ) : (
                <div
                  key={websiteStatistics.website.name}
                  className={ClassNames(classes.flexTable, classes.row)}
                  role="rowgroup"
                >
                  <div className={ClassNames(classes.flexRow, classes.website, classes.first)} role="cell">
                    <div className={classes.websiteName}>
                      <GlobeIcon className={classes.websiteIcon} />
                      <EditIcon
                        className={classes.editIcon}
                        onClick={(e) => setEditedWebsite(websiteStatistics.website)}
                      />
                      {websiteStatistics.website.name}
                    </div>
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.startDate)} role="cell">
                    {websiteStatistics.website.startDate
                      ? moment(websiteStatistics.website.startDate).format("MM-DD-YYYY")
                      : ""}
                  </div>
                  {/* <div className={ClassNames(classes.flexRow, classes.planName)} role="cell"> */}
                  {/*  {websiteStatistics.website.planName} */}
                  {/* </div> */}
                  <div className={ClassNames(classes.flexRow, classes.totalForumPosts)} role="cell">
                    {websiteStatistics.total.forum}
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.totalForumPosts)} role="cell">
                    {websiteStatistics.total.forumAnswers}
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.totalTargetedPosts)} role="cell">
                    {websiteStatistics.total.targetedPosts}
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.totalBlogPosts)} role="cell">
                    {websiteStatistics.total.blog}
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.totalLearningCenterPosts)} role="cell">
                    {websiteStatistics.total.learningCenter}
                  </div>
                  <div className={ClassNames(classes.flexRow, classes.totalTopics)} role="cell">
                    {websiteStatistics.total.topics}
                  </div>

                  <div className={ClassNames(classes.flexRow, classes.inReviewPosts)} role="cell">
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      enterDelay={500}
                      title={`Forum Questions: ${websiteStatistics.review.forum}\nForum Answers: ${websiteStatistics.review.forumAnswers}\nAnswers: ${websiteStatistics.review.targetedPosts}\nBlog: ${websiteStatistics.review.blog}\nLearning Center: ${websiteStatistics.review.learningCenter}`}
                      arrow
                    >
                      <Typography variant={"body2"}>
                        {websiteStatistics.review.forum +
                          websiteStatistics.review.forumAnswers +
                          websiteStatistics.review.targetedPosts +
                          websiteStatistics.review.blog +
                          websiteStatistics.review.learningCenter +
                          websiteStatistics.review.topics}
                      </Typography>
                    </Tooltip>
                  </div>

                  <div className={ClassNames(classes.flexRow, classes.dividerColumn)} role="cell" />

                  <div
                    className={ClassNames(
                      classes.flexRow,
                      classes.monthlyForumPosts,
                      websiteStatistics.monthly.forumAnswers < websiteStatistics.website.monthlyForumPosts &&
                        classes.red
                    )}
                    role="cell"
                  >
                    {`${websiteStatistics.monthly.forum}`}
                  </div>
                  <div
                    className={ClassNames(
                      classes.flexRow,
                      classes.monthlyForumPosts,
                      websiteStatistics.monthly.forumAnswers < websiteStatistics.website.monthlyForumPosts &&
                        classes.red
                    )}
                    role="cell"
                  >
                    {`${websiteStatistics.monthly.forumAnswers}/${websiteStatistics.website.monthlyForumPosts}`}
                  </div>
                  <div
                    className={ClassNames(
                      classes.flexRow,
                      classes.monthlyTargetedPosts,
                      websiteStatistics.monthly.targetedPosts < websiteStatistics.website.monthlyTargetedPosts &&
                        classes.red
                    )}
                    role="cell"
                  >
                    {`${websiteStatistics.monthly.targetedPosts}/${websiteStatistics.website.monthlyTargetedPosts}`}
                  </div>
                  <div
                    className={ClassNames(
                      classes.flexRow,
                      classes.monthlyBlogPosts,
                      websiteStatistics.monthly.blog < websiteStatistics.website.monthlyBlogPosts && classes.red
                    )}
                    role="cell"
                  >
                    {`${websiteStatistics.monthly.blog}/${websiteStatistics.website.monthlyBlogPosts}`}
                  </div>
                  <div
                    className={ClassNames(
                      classes.flexRow,
                      classes.monthlyLearningCenterPosts,
                      websiteStatistics.monthly.learningCenterPosts <
                        websiteStatistics.website.monthlyLearningCenterPosts && classes.red
                    )}
                    role="cell"
                  >
                    {`${websiteStatistics.monthly.learningCenter}/${websiteStatistics.website.monthlyLearningCenterPosts}`}
                  </div>
                  <div
                    className={ClassNames(
                      classes.flexRow,
                      classes.monthlyLearningCenterPosts,
                      websiteStatistics.monthly.learningCenterPosts <
                        websiteStatistics.website.monthlyLearningCenterPosts && classes.red
                    )}
                    role="cell"
                  >
                    {`${websiteStatistics.monthly.topics}`}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Statistics;
