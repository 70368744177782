import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { debounce } from "lodash";
import { connect } from "react-redux";
import agent from "../../../../agent";
import { FUNNELS } from "../../../../reducers/constants/actionTypes";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import CfButton from "../../../../components/CfButton";

const useStyles = makeStyles((theme: Theme) => ({
  sidebarActions: {
    display: "flex",
    gap: 8,
    justifyContent: "flex-end",
    padding: 20,
    width: "100%",
  },
  submitBtn: {
    padding: "6px 15px",
    fontSize: 14,
    color: theme.palette.common.white,
  },
  cancelBtn: {
    fontSize: 14,
    textTransform: "none",
    width: "fit-content",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  funnel: state.funnels,
  funnels: state.funnels.funnels,
});

const mapDispatchToProps = (dispatch) => ({
  updateConfig(payload) {
    dispatch({ type: FUNNELS.FUNNEL_CONFIG_UPDATED, payload });
  },
  updateFunnelName(payload) {
    dispatch({ type: FUNNELS.FUNNEL_FUNNEL_NAME_CHANGED, payload });
  },
  updateFunnelDetails(payload) {
    dispatch({ type: FUNNELS.FUNNEL_DETAILS_LOADED, payload });
  },
  funnelsLoaded(payload) {
    dispatch({ type: FUNNELS.FUNNELS_LOADED, payload });
  },
});

type PropTypes = {
  selectedWebsite: IWebsite;
  funnel: any;
  funnels: any[];
  updateConfig: (payload) => void;
  updateFunnelName: (payload) => void;
  updateFunnelDetails: (payload) => void;
  onCancel: () => void;
  funnelsLoaded: (payload) => void;
};

const FunnelSidebarActions = (props: PropTypes) => {
  const classes = useStyles();

  const {
    funnel,
    funnels,
    updateFunnelName,
    selectedWebsite,
    updateConfig,
    updateFunnelDetails,
    onCancel,
    funnelsLoaded,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const createFunnel = () => {
    if (!funnel.funnelId) {
      agent.Funnels.saveFunnel(selectedWebsite, {
        funnelName: funnel.funnelName,
        config: funnel.config,
        cvr: funnel.cvr,
        priority: funnel.priority,
        showValue: funnel.showValue,
        showTraffic: funnel.showTraffic,
      })
        .then((res) => {
          if (res.data) {
            funnelsLoaded([...funnels, res.data]);
            updateFunnelDetails({ ...res.data });
          }

          enqueueSnackbar(I18n.t("analytics.funnels.popup_messages.funnel_saved"));
          onCancel();
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.body.message || "Bad request", { variant: "error" });
        });
    } else {
      agent.Funnels.updateFunnel(funnel.funnelId, {
        funnelName: funnel.funnelName,
        config: funnel.config,
        cvr: funnel.cvr,
        priority: funnel.priority,
        showValue: funnel.showValue,
        showTraffic: funnel.showTraffic,
      })
        .then(() => {
          enqueueSnackbar(I18n.t("analytics.funnels.popup_messages.funnel_updated"));
          onCancel();
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.body.message || "Bad request", { variant: "error" });
        });
    }
  };

  const createFunnelDebounce = debounce(createFunnel);

  return (
    <div className={classes.sidebarActions}>
      <Button
        variant={"text"}
        className={classes.cancelBtn}
        onClick={() => {
          const currentFunnel = funnel.funnels.find((f) => f._id === funnel.funnelId);

          if (currentFunnel) {
            updateFunnelDetails({ ...currentFunnel });
          } else {
            updateFunnelDetails({
              _id: null,
              funnelName: "",
              config: {
                steps: [],
              },
            });
          }

          onCancel();
        }}
      >
        Cancel
      </Button>
      <CfButton
        text={funnel?.funnelId ? "Update" : "Create Funnel"}
        customClass={classes.submitBtn}
        height={35}
        onClick={createFunnelDebounce}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FunnelSidebarActions);
