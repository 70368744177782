import * as React from "react";
import { uniqBy } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import agent from "../../../agent";
import { IProduct, IWebsite } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import theme from "../../../themes/theme";
import listHelper from "../../../helpers/listHelper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragIcon from "../../../icons/DragIcon";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Editor, Node, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { useSnackbar } from "notistack";
import richTextEditorHelper from "../../helper/richTextEditorHelper";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

const useStyles = makeStyles((theme: Theme) => ({
  writersWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  writersWrapperFullWidth: {
    width: "100%",
  },
  writerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // whiteSpace: 'pre',
    marginBottom: 10,
  },
  writerWrapperFullWidth: {
    flexDirection: "column",
    alignItems: "start",
    gap: 7,
  },
  writerTitle: {
    maxWidth: 65,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    whiteSpace: "pre",
  },
  writerTitleFullWidth: {
    maxWidth: "unset",
  },
  clearIcon: {
    fill: "#808080",
    marginLeft: 5,
    cursor: "pointer",
  },
  list: {},
  listItem: {
    "&:hover": {
      "& $secondaryAction": {
        opacity: 1,
      },
      "& $dragIcon": {
        opacity: 1,
      },
    },
  },
  dragIcon: {
    opacity: 0,
  },
  listAvatar: {
    minWidth: 36,
  },
  avatar: {
    fontSize: 12,
    width: 20,
    height: 20,
  },
  secondaryAction: {
    opacity: 0,
  },
  deleteIcon: {
    fontSize: 14,
  },
  visibilityOffIcon: {
    fontSize: 14,
  },
  blue: {
    fill: "blue",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  rowTitle?: string;
  isClearable?: boolean;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
  fullWidth?: boolean;
  editor: Editor;
  onProductsChange: (products: IProduct[]) => void;
};

const MultipleProductsPickerElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    onProductsChange,
    rowTitle,
    isClearable = false,
    products = [],
    editor,
    fullWidth = false,
    handleProductsChange,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [items, setItems] = React.useState<IProduct[]>([]);
  const [hiddenSelectedProducts, setHiddenSelectedProducts] = React.useState<IProduct[]>([]);
  const selectedProducts = products
    ? uniqBy([...products, ...hiddenSelectedProducts], (p) => p._id)
    : hiddenSelectedProducts;

  const getProducts = (search = "") => {
    agent.Product.autoComplete(selectedWebsite._id, search, true)
      .then((results) => {
        setItems(results.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    getProducts();
  }, []);

  const allItems = products ? uniqBy([...products, ...items], (p) => p._id) : items;

  const onProductsChanged = (uProducts: IProduct[]) => {
    handleProductsChange(uProducts);
    onProductsChange(uProducts);
  };

  const handleRemoveProduct = (index) => {
    // validating no other element is using the product
    const allProducts = [...products];
    const [elementWithProd] = Editor.nodes(editor, {
      match: (n) => n.data?.product?.itemNumber === allProducts[index].itemNumber,
      at: [], //lookup in all doc
    });
    console.log("elementWithProd", elementWithProd);
    if (!elementWithProd) {
      products.splice(index, 1);
      onProductsChanged(products);
    } else {
      enqueueSnackbar(I18n.t(`snackbar.product_in_use`));
      const elementPath = ReactEditor.findPath(editor as ReactEditor, elementWithProd[0]);
      ReactEditor.focus(editor as ReactEditor);
      Transforms.select(editor, Editor.start(editor, elementPath));
      richTextEditorHelper.scrollToSelection(editor.selection);
    }
  };
  const handleProductVision = (index: number) => {
    if (!hiddenSelectedProducts.includes(selectedProducts[index])) {
      setHiddenSelectedProducts([...hiddenSelectedProducts, selectedProducts[index]]);
      handleRemoveProduct(index);
    } else {
      setHiddenSelectedProducts(hiddenSelectedProducts.filter((p) => p !== selectedProducts[index]));
      onProductsChanged([...(products || []), selectedProducts[index]]);
    }
  };
  const onDragEnd = ({ destination, source }: DropResult) => {
    console.log("onDragEnd", destination, source);
    // dropped outside the list
    if (!destination) return;
    const newItems = listHelper.reorder(products, source.index, destination.index);
    console.log(newItems);
    onProductsChanged(newItems);
    // onItemsReordered(newItems);
  };

  return (
    <div className={ClassNames(classes.writerWrapper, fullWidth && classes.writerWrapperFullWidth)}>
      <Typography
        variant={"body1"}
        className={ClassNames(classes.writerTitle, fullWidth && classes.writerTitleFullWidth)}
        color={"textSecondary"}
      >
        {rowTitle || I18n.t("rich_text_editor.select_products")}
      </Typography>
      <div className={ClassNames(classes.writersWrapper, fullWidth && classes.writersWrapperFullWidth)}>
        <MSelect
          isClearable={isClearable}
          // textColor={theme.palette.text.primary}
          textColor={theme.palette.common.white}
          textColorHover={theme.palette.common.white}
          optionBackgroundColorHover={theme.palette.secondary.main}
          options={allItems}
          width={fullWidth ? "unset" : 180}
          fullWidth={fullWidth}
          value={null}
          searchable
          placeholder={I18n.t("edit_post.products_input_ph")}
          borderColor={theme.palette.divider}
          borderWidth={1}
          borderRadius={2}
          isBold={false}
          height={32}
          menuPlacement={"bottom"}
          handleInputChange={(search) => {
            getProducts(search);
          }}
          handleChange={(selectedItem) => {
            if (selectedItem) {
              if (!products?.includes(selectedItem)) {
                onProductsChanged([...(products || []), selectedItem]);
              }
            }
          }}
          optionLabel={"name"}
          optionValue={"_id"}
        />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="products-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={{ width: "100%" }}>
              <List dense className={classes.list}>
                {selectedProducts?.map((product, index) => (
                  <Draggable draggableId={product._id} index={index} key={product._id}>
                    {(provided, snapshot) => (
                      <ListItem
                        classes={{
                          container: classes.listItem,
                        }}
                        disableGutters
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={snapshot.isDragging ? classes.draggingListItem : ""}
                      >
                        <DragIcon className={classes.dragIcon} />
                        <ListItemAvatar className={classes.listAvatar}>
                          <Avatar className={classes.avatar}>{index + 1}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={product?.displayName || product.name} />
                        <ListItemSecondaryAction className={classes.secondaryAction}>
                          <IconButton edge="end" aria-label="visibility-off" onClick={() => handleProductVision(index)}>
                            <VisibilityOffOutlinedIcon
                              className={ClassNames(
                                classes.visibilityOffIcon,
                                hiddenSelectedProducts.includes(product) && classes.blue
                              )}
                            />
                          </IconButton>
                          <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveProduct(index)}>
                            <CloseIcon className={classes.deleteIcon} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default MultipleProductsPickerElement;
