import moment from "moment";

const formatDate = (date: Date) => {
  const d = new Date(date);
  if (moment(d).isSame(new Date(), "week")) {
    return moment(date).startOf("second").fromNow();
  }
  return moment(date).format("h:mm a, MMM Do YY");
};

const millisecondsToTime = (milliseconds: number) => {
  let remainingMilliseconds = milliseconds;

  const ms = remainingMilliseconds % 1000;
  remainingMilliseconds = (remainingMilliseconds - ms) / 1000;

  const secs = remainingMilliseconds % 60;
  remainingMilliseconds = (remainingMilliseconds - secs) / 60;

  const mins = remainingMilliseconds % 60;
  const hrs = (remainingMilliseconds - mins) / 60;

  return `${hrs.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};

export { formatDate, millisecondsToTime };
