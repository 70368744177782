import React, { useMemo } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Classnames from "classnames";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import _ from "lodash";
import { TableSortLabel } from "@material-ui/core";
import { push } from "connected-react-router";
import classNames from "classnames";
import { web } from "../../../../../../helpers/urlHelper";
import useSort from "../../../../../../hooks/useSort";
import PagesDialogListItem from "./PagesDialogListItem";
import { IKeywordHeadingCluster, IOutlinePages } from "../../../../../../reducers/constants/objectTypes";
import numeral from "numeral";

const useStyles = makeStyles((theme: Theme) => ({
  dialogListSubHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0",
    backgroundColor: theme.palette.background.default,
    margin: "15px 0px",
    top: 2,
  },
  navDialogTabs: {
    display: "flex",
    width: "55%",
    flexDirection: "row",
  },
  dialogTabs: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    width: "10%",
  },
  firstDialogTab: {
    width: "55%",
  },
  tab: {
    padding: "2px 40px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    flex: "0 0 auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "pointer",
    borderBottom: `1px solid #C4C4C4`,
  },
  activeTab: {
    color: theme.palette.text.primary,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    transition: "border .3s",
  },
  headerIcon: {
    width: 24,
    height: 24,
  },
  sortIcon: { color: `${theme.palette.text.primary} !important` },
}));

const mapStateToProps = (state) => ({
  serps: state.keywordStrategy.serps,

  pages: state.keywordStrategy.pages,
  broadKeywords: state.keywordStrategy.broadKeywords,
});

type PropTypes = {
  pages: IOutlinePages[];
  openPost: (pageId: string, currentUrl?: string) => void;
  pageTab: "keywords" | "pages" | "serps";
  serps: any[];
  setPageTab: (tab: "keywords" | "pages" | "serps") => void;
  broadKeywords: IKeywordHeadingCluster[];
  cms?: boolean;
};

const mapDispatchToProps = (dispatch) => ({
  openPost: (pageId, currentUrl) => dispatch(push(web.editPage(pageId, currentUrl))),
});
const PagesDialogList = (props: PropTypes) => {
  const classes = useStyles(props);

  const { pages, serps, openPost, setPageTab, pageTab, cms, broadKeywords } = props;
  const [lastSortField, setLastSortField] = React.useState<string>("clicks");

  const { sortFields, toggleSort } = useSort({
    initialSortFields: {
      clicks: "desc",
      impressions: "desc",
    },
  });

  const columns = useMemo(() => ["page", "clicks", "impressions", "ctr", "position"], []);

  const outlineColumns = React.useMemo(
    () => ({
      page: {
        isSortable: false,
        label: "",
      },
      clicks: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_pages.clicks"),
      },
      impressions: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_pages.impressions"),
      },
      ctr: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_pages.ctr"),
      },
      position: {
        isSortable: true,
        label: I18n.t("strategy.outlinePages.dialog_pages.position"),
      },
    }),
    []
  );

  const sortedData = useMemo(() => {
    if (pages) {
      const fields: string[] = [];
      const directions: any[] = [];

      Object.entries(sortFields).forEach(([field, direction]) => {
        if (field === lastSortField) {
          fields.unshift(field);
          directions.unshift(direction);
          return;
        }
        fields.push(field);
        directions.push(direction);
      });

      return _.orderBy(pages, fields, directions);
    }
    return [];
  }, [sortFields, pages]);

  return (
    <div>
      <ListSubheader className={classes.dialogListSubHeader}>
        {columns?.map((col, index) => {
          const columnLabel = outlineColumns[col].label;

          const { isSortable } = outlineColumns[col];
          return (
            <div
              className={index === 0 ? Classnames(classes.dialogTabs, classes.firstDialogTab) : classes.dialogTabs}
              key={index}
            >
              {isSortable ? (
                <TableSortLabel
                  classes={{ icon: classes.sortIcon }}
                  active={!!sortFields[col]}
                  direction={sortFields[col] || undefined}
                  onClick={() => {
                    setLastSortField(col);
                    toggleSort(col);
                  }}
                >
                  {columnLabel}
                </TableSortLabel>
              ) : index === 0 ? (
                <div className={classes.navDialogTabs}>
                  <div
                    className={classNames(pageTab === "keywords" && classes.activeTab, classes.tab)}
                    onClick={() => {
                      setPageTab("keywords");
                    }}
                  >
                    {numeral(broadKeywords?.length || 0).format("0,0a")}{" "}
                    {broadKeywords?.length === 1
                      ? I18n.t("strategy.outlinePages.dialog_header.keyword")
                      : I18n.t("strategy.outlinePages.dialog_header.keywords")}
                  </div>
                  <div
                    className={classNames(pageTab === "pages" && classes.activeTab, classes.tab)}
                    onClick={() => {
                      setPageTab("pages");
                    }}
                  >
                    {numeral(pages?.length || 0).format("0,0a")}{" "}
                    {pages?.length === 1
                      ? I18n.t("strategy.outlinePages.dialog_header.page")
                      : I18n.t("strategy.outlinePages.dialog_header.pages")}
                  </div>
                  <div
                    className={Classnames(pageTab === "serps" && classes.activeTab, classes.tab)}
                    onClick={() => {
                      setPageTab("serps");
                    }}
                  >
                    {numeral(serps?.length || 0).format("0,0a")}{" "}
                    {serps?.length === 1
                      ? I18n.t("strategy.outlinePages.dialog_header.serp")
                      : I18n.t("strategy.outlinePages.dialog_header.serps")}
                  </div>
                </div>
              ) : (
                columnLabel
              )}
            </div>
          );
        })}
      </ListSubheader>
      {sortedData?.map((page, index) => (
        <PagesDialogListItem cms={cms} key={index} page={page} openPost={openPost} />
      ))}
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(PagesDialogList);
