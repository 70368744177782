import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import { I18n } from "react-redux-i18n";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import moment from "moment";
import ActivityFeedItem from "./ActivityFeedItem";
import { IAnalyticsEvent, IAnalyticsSession } from "../../../../reducers/constants/objectTypes";
import CfProgressBar from "../../../../components/CfProgressBar";
import { Paper } from "@material-ui/core";
import ActivityFeedDateItem from "./ActivityFeedDateItem";

const useStyles = makeStyles((theme: Theme) => ({
  contentList: {},
  root: {
    paddingTop: 0,
  },
  subHeader: {
    top: 62,
    position: "sticky",
    padding: "30px 20px",
    marginRight: 5,
    display: "flex",
    zIndex: 3,
    backgroundColor: "white",
  },
  tableHiddenHead: {
    width: 20,
  },
  tableWideHead: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(21),
    width: "20%",
  },
  tableHead: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    flexGrow: 1,
    flexBasis: 0,
  },
  tableMoreOptionsHead: {
    width: 30,
  },
}));

type PropTypes = {
  loaded: boolean;
  events: IAnalyticsEvent[];
  sessions: IAnalyticsSession[];
};

const UserEventsActivityFeed = (props: PropTypes) => {
  const classes = useStyles(props);
  const { loaded, events, sessions } = props;

  return (
    <Paper className={classes.contentList}>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        <ListSubheader className={classes.subHeader}>
          <Typography variant={"subtitle2"}>{I18n.t("analytics.user_events.activity_feed")}</Typography>
        </ListSubheader>
        {!loaded && (
          <ListItem
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CfProgressBar />
          </ListItem>
        )}
        {loaded && events.length === 0 && (
          <ListItem button>
            <ListItemText primary="No content to show" />
          </ListItem>
        )}
        {loaded &&
          events.length > 0 &&
          events.map((event, index) => {
            if (index === 0 || event.session_id !== events[index - 1].session_id) {
              return (
                <React.Fragment key={event.id}>
                  <ActivityFeedDateItem date={event.created_at} />
                  <ActivityFeedItem event={event} />
                </React.Fragment>
              );
            }
            return <ActivityFeedItem key={event.id} event={event} />;
          })}
      </List>
    </Paper>
  );
};

export default UserEventsActivityFeed;
