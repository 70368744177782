import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: 72,
    height: 84,
    fill: "#5649C0",
    // transform: "rotate(-78.753deg)",
  },
}));

type PropTypes = {
  className?: string;
  onClick?: Function;
};

function PreviewEmbedIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className, onClick = null } = props;
  return (
    <SvgIcon
      onClick={(e) => onClick && onClick(e)}
      className={ClassNames(classes.svg, className)}
      viewBox={"0 0 72 84"}
    >
      <path
        d="M71.2695 71.3685C71.2668 71.318 71.2799 71.2675 71.27 71.2174C71.2642 71.1883 71.2449 71.1651 71.236 71.1367C71.2275 71.0988 71.2336 71.0611 71.22 71.0243C71.1981 70.962 71.1542 70.9155 71.1212 70.8607C71.1167 70.8543 71.1145 70.8485 71.1103 70.8431C70.9895 70.6484 70.8204 70.5019 70.6132 70.4234L60.0304 63.4346C59.5566 63.1217 58.9167 63.2489 58.5987 63.7193C58.2807 64.1897 58.4066 64.8227 58.8803 65.1356L67.86 71.0656C52.5443 75.8188 38.2292 72.6043 28.339 62.0636C27.9515 61.6502 27.2987 61.628 26.8806 62.0131C26.4625 62.3982 26.4373 63.0446 26.8246 63.457C36.1279 73.3751 49.0906 77.1886 63.1808 74.3868C64.7047 74.0837 66.243 73.6945 67.7896 73.236L62.1498 81.5775C61.8318 82.0478 61.9577 82.6809 62.4315 82.9938C62.6678 83.1498 62.9465 83.1965 63.2057 83.1449C63.4649 83.0934 63.7046 82.9437 63.8632 82.7091L71.1133 71.9857C71.2199 71.8282 71.2605 71.6462 71.2688 71.4602C71.2721 71.4294 71.2694 71.3997 71.2695 71.3685Z"
        fill="#5649C0"
      />
      <path
        d="M4.00248 34.6911C8.25997 44.7952 16.4667 52.8937 24.9138 55.3248C26.9143 55.8943 29.1533 55.9218 31.4517 55.4648C35.0603 54.7472 38.8155 52.8343 42.0224 49.9433C45.4851 46.8245 47.9031 42.9617 48.8327 39.0669C49.7949 35.0344 49.0606 31.4372 46.7635 28.9368C42.1275 23.8893 32.4529 25.0988 25.2022 31.6323C25.1967 31.6365 25.195 31.6441 25.1906 31.6481C22.0893 34.4462 19.7813 37.9196 18.6918 41.4286C18.5244 41.968 18.8295 42.5393 19.3746 42.7027C19.5426 42.7536 19.7153 42.7589 19.8762 42.7269C20.2367 42.6552 20.549 42.3942 20.6643 42.0214C21.6489 38.8529 23.7533 35.7001 26.5909 33.1429C26.5943 33.1391 26.5956 33.1347 26.599 33.1309C33.0076 27.3646 41.3619 26.1 45.2353 30.3132C47.061 32.3013 47.6254 35.2451 46.8238 38.6032C45.9893 42.098 43.7923 45.5899 40.6369 48.4331C35.9071 52.6962 29.9627 54.6301 25.4946 53.3601C17.6236 51.0945 9.93546 43.4551 5.90836 33.8956C1.43855 23.2901 1.90993 12.0572 7.23594 2.26518C7.50649 1.76782 7.31924 1.14907 6.8178 0.882263C6.31655 0.61646 5.69061 0.803404 5.41926 1.30197C-0.207227 11.6473 -0.711155 23.5052 4.00248 34.6911Z"
        fill="#5649C0"
      />
    </SvgIcon>
  );
}

export default PreviewEmbedIcon;
