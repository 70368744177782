import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles";
import { Paper, Popover } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state/index";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { Variant } from "@material-ui/core/styles/createTypography";
import edit from "../../../img/edit.png";
import SelectRow from "./SelectRow";
import ColorPickerRow from "./ColorPickerRow";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import InputRow from "./InputRow";
import DimensionsInputRow from "./DimensionsInputRow";
import {
  fontOptions,
  fontStyleOptions,
  letterCaseOptions,
  textDecorationOptions,
  weightOptions,
} from "../WebsiteEditor/consts/CssConsts";

const useStyles = makeStyles((theme: Theme) => ({
  designText: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  editImage: {
    marginLeft: 15,
    cursor: "pointer",
  },
  designPropsRow: {
    display: "flex",
    flexDirection: (props: PropTypes) => (props.topTitle ? "column" : "row"),
    minHeight: 59,
    padding: (props: PropTypes) => (props.disableHover ? "0px 30px" : "10px 30px"),
    marginLeft: (props: PropTypes) => props.marginLeft || 0,
    alignItems: "center",
    marginRight: (props: PropTypes) => props.marginRight || 0,
    marginBottom: (props: PropTypes) => (props.disableHover ? 20 : 0),
    "&:hover": {
      backgroundColor: (props: PropTypes) => (props.disableHover ? "transparent" : theme.palette.secondary.main),
      "& $editWrapper": {
        display: "flex",
      },
    },
  },
  designProps: {
    display: "flex",
    color: theme.palette.text.primary,
  },
  popoverPaper: {
    borderRadius: 0,
    boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.15)",
    border: "none",
    marginLeft: 10,
  },
  wrapper: {
    height: "100%",
    width: 416,
    border: "none",
    borderRadius: 0,
  },
  innerWrapper: {
    padding: "15px 20px",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 15,
  },
  title: {
    color: (props: PropTypes) => props.titleColor || theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
  },
  editWrapper: {
    display: (props: PropTypes) => (props.topTitle ? "flex" : "none"),
    gap: 10,
    alignItems: "center",
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  colorSquare: {
    width: 16,
    height: 16,
    backgroundColor: "black",
    marginLeft: 7,
  },
  titleRowWrapper: {
    display: "flex",
    marginBottom: 15,
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    cursor: "pointer",
  },
  designWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    cursor: "pointer",
    justifyContent: "space-between",
  },
}));

type PropTypes = {
  theme: IWebsiteThemeProps;
  disableHover?: boolean;
  elementTitle?: string;
  marginLeft?: number | string;
  marginRight?: number | string;
  topTitle?: boolean;
  titleColor?: string;
  css: any;
  defaultCss?: any;
  exampleText?: string;
  variant: Variant;
  background?: any;
  handleCssChanged: (css) => void;
};

const mapStateToProps = (state) => ({
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({});

const TextDesignToolWithPreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    theme,
    topTitle = false,
    variant,
    defaultCss = null,
    elementTitle = "",
    exampleText = null,
    css = {},
    background = null,
    handleCssChanged,
  } = props;

  const finalDefaultCss =
    { color: theme.palette.text.primary, fontFamily: theme.typography.fontFamily, ...defaultCss } ||
    theme.typography[variant].css;

  const onCssChanged = (cssName, cssValue) => {
    if (cssValue === null || cssValue === "" || cssValue === finalDefaultCss[variant]?.css[cssName]) {
      delete css[cssName];
      handleCssChanged(css);
      return;
    }
    handleCssChanged({ ...css, [cssName]: cssValue });
  };

  // console.log(elementTitle);
  // console.log('css', css);
  // console.log('defaultCss', defaultCss);
  // console.log('finalDefaultCss', finalDefaultCss);
  // console.log('isequal', css?.textTransform && css?.textTransform !== finalDefaultCss?.textTransform);
  // console.log('css?.textTransform', css?.textTransform);
  // console.log('finalDefaultCss?.textTransform', finalDefaultCss?.textTransform);

  return (
    <div className={classes.designPropsRow}>
      <PopupState variant="popover" popupId="popover">
        {(popupState) => (
          <div style={{ display: "contents" }}>
            {topTitle && (
              <div className={classes.titleRowWrapper} {...bindTrigger(popupState)}>
                <Typography className={classes.title} variant={"subtitle2"}>
                  {elementTitle || ""}
                </Typography>
                <div className={classes.editWrapper}>
                  <img width={12} height={12} src={edit} alt={"edit_image"} className={classes.editImage} />
                  <Typography variant={"body2"} className={classes.designText}>
                    {I18n.t("website.edit")}
                  </Typography>
                </div>
              </div>
            )}
            <div className={classes.designWrapper} {...bindTrigger(popupState)}>
              <Typography variant={variant} style={{ ...finalDefaultCss, ...css, margin: 0, padding: 0, background }}>
                {exampleText || elementTitle}
              </Typography>
              {!topTitle && (
                <div className={classes.editWrapper}>
                  <img width={12} height={12} src={edit} alt={"edit_image"} className={classes.editImage} />
                  <Typography variant={"body2"} className={classes.designText}>
                    {I18n.t("website.edit")}
                  </Typography>
                </div>
              )}
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              elevation={0}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <Paper className={classes.wrapper}>
                <div className={classes.titleWrapper}>
                  <Typography color={"textPrimary"} variant={"subtitle1"}>
                    {`${elementTitle} Typography`}
                  </Typography>
                  <CloseIcon className={classes.closeIcon} onClick={popupState.close} />
                </div>
                <div className={classes.innerWrapper}>
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.font")}
                    value={css?.fontFamily}
                    defaultValue={finalDefaultCss?.fontFamily}
                    isValueChanged={css?.fontFamily && css?.fontFamily !== finalDefaultCss?.fontFamily}
                    selectOptions={fontOptions}
                    handleChange={(font) => {
                      onCssChanged("fontFamily", font);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.size")}
                    value={css?.fontSize}
                    defaultValue={finalDefaultCss?.fontSize}
                    isValueChanged={css?.fontSize && css?.fontSize !== finalDefaultCss?.fontSize}
                    handleChange={(size) => {
                      onCssChanged("fontSize", size);
                    }}
                  />
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.weight")}
                    value={css?.fontWeight}
                    defaultValue={finalDefaultCss?.fontWeight}
                    isValueChanged={css?.fontWeight && css?.fontWeight !== finalDefaultCss?.fontWeight}
                    selectOptions={weightOptions}
                    handleChange={(weight) => {
                      onCssChanged("fontWeight", weight);
                    }}
                  />
                  <ColorPickerRow
                    rowTitle={I18n.t("website.design_tool.text_color")}
                    value={css?.color}
                    defaultValue={finalDefaultCss?.color}
                    isValueChanged={css?.color && css?.color !== finalDefaultCss?.color}
                    handleChange={(color) => {
                      onCssChanged("color", color);
                    }}
                  />
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.font_style")}
                    value={css?.fontStyle}
                    defaultValue={finalDefaultCss?.fontStyle}
                    isValueChanged={css?.fontStyle && css?.fontStyle !== finalDefaultCss?.fontStyle}
                    selectOptions={fontStyleOptions}
                    handleChange={(fontStyle) => {
                      onCssChanged("fontStyle", fontStyle);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.line_height")}
                    value={css?.lineHeight}
                    stepSize={"0.1"}
                    defaultValue={finalDefaultCss?.lineHeight}
                    isValueChanged={css?.lineHeight && css?.lineHeight !== finalDefaultCss?.lineHeight}
                    handleChange={(lineHeight) => {
                      onCssChanged("lineHeight", lineHeight);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.letter_spacing")}
                    value={css?.letterSpacing}
                    defaultValue={finalDefaultCss?.letterSpacing}
                    isValueChanged={css?.letterSpacing && css?.letterSpacing !== finalDefaultCss?.letterSpacing}
                    handleChange={(letterSpacing) => {
                      onCssChanged("letterSpacing", letterSpacing);
                    }}
                  />
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.letter_case")}
                    value={css?.textTransform}
                    defaultValue={finalDefaultCss?.textTransform}
                    isValueChanged={css?.textTransform && css?.textTransform !== finalDefaultCss?.textTransform}
                    selectOptions={letterCaseOptions}
                    handleChange={(letterCase) => {
                      onCssChanged("textTransform", letterCase);
                    }}
                  />

                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.text_decoration")}
                    value={css?.textDecoration}
                    defaultValue={finalDefaultCss?.textDecoration}
                    isValueChanged={css?.textDecoration && css?.textDecoration !== finalDefaultCss?.textDecoration}
                    selectOptions={textDecorationOptions}
                    handleChange={(textDecoration) => {
                      onCssChanged("textDecoration", textDecoration);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.padding")}
                    value={css?.padding || finalDefaultCss?.padding}
                    defaultValue={finalDefaultCss?.padding}
                    isValueChanged={css?.padding && css?.padding !== finalDefaultCss?.padding}
                    handleChange={(padding) => {
                      onCssChanged("padding", padding);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.margin")}
                    value={css?.margin || finalDefaultCss?.margin}
                    defaultValue={finalDefaultCss?.margin}
                    isValueChanged={css?.margin && css?.margin !== finalDefaultCss?.margin}
                    handleChange={(margin) => {
                      onCssChanged("margin", margin);
                    }}
                  />
                </div>
              </Paper>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TextDesignToolWithPreview);
