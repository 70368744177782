import React from "react";
import { I18n } from "react-redux-i18n";
import { IWidgetFieldProperties, IWidgetTagFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "./components/GenericValueItemProperty";

type PropTypes = {
  properties: IWidgetFieldProperties;
  fieldType: string;
  onChange: (properties: IWidgetTagFieldProps) => void;
};

const TagWidgetItem = (props: PropTypes) => {
  const { onChange, properties, fieldType } = props;
  const { value = "" } = properties as IWidgetTagFieldProps;

  return (
    <GenericValueItemProperty
      onChange={onChange}
      value={value}
      label={I18n.t(`widgets_builder.widget_item_selector.${fieldType}`)}
    />
  );
};

export default TagWidgetItem;
