import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 16,
    height: 16,
  },
}));

type PropTypes = {
  className?: string;
};

const BorderTopLeftIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 16 16"}>
      <path
        fill="none"
        d="M1 15L1 5C1 2.79086 2.79086 1 5 1L15 1"
        stroke="#333333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default BorderTopLeftIcon;
