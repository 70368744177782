import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: 5,
    alignItems: "center",
  },
  labelWrapper: {
    display: "flex",
    flexDirection: "row",
    width: 180,
    justifyContent: "space-between",
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    width: 73,
  },
}));

type PropTypes = {
  labels: string[];
};

const HoverLabelBaseComponent = (props: PropTypes) => {
  const classes = useStyles(props);
  const { labels } = props;

  return (
    <div className={classes.row}>
      <div className={classes.labelWrapper}>
        {labels.map((x) => (
          <Typography className={classes.label}>{x}</Typography>
        ))}
      </div>
    </div>
  );
};
export default HoverLabelBaseComponent;
