import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import * as React from "react";
import { useMemo } from "react";
import moment from "moment/moment";
import ClassNames from "classnames";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import MTableCellFirst from "../../../../../components/Table/Cells/MTableCellFirst";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../../../../components/Table/Table/MTableHeaderSortLabel";
import { DATE_FORMAT } from "../../../../../reducers/constants/consts";
import localeHelper from "../../../../../helpers/localeHelper";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";
import GlobAvatar from "../../../../../icons/GlobAvatar";
import GoalCompletionByPageExpandedDialog from "./GoalCompletionByPageExpandedDialog";
import { IFunnelBreakdownColumn, IWebsite } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    // backgroundColor: "white",
    padding: "10px 20px 10px 0px",
  },
  expandedTooltip: {
    maxWidth: "unset",
  },
  avatarWrapper: {
    backgroundColor: "#8B2FE8",
    height: 25,
    width: 25,
    borderRadius: 100,
    marginRight: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  firstColumn: {
    backgroundColor: theme.palette.common.white,
  },
  headerFirstColumn: {
    zIndex: 100,
    backgroundColor: theme.palette.common.white,
  },
  pageEventsAvatar: {
    backgroundColor: "#B780FE !important",
  },
  tableContainer: {
    width: "calc(100vw - 40px)",
    overflowX: "unset",
  },
  table: {
    position: "relative",
    borderSpacing: "20px 0",
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: "auto",
    height: "100%",
    maxHeight: "calc(100vh - 225px)",
  },
  tableRow: {
    height: 52,
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      clipPath: "xywh(0 0 100% 100% round 3.5em)",
      transition: "border-radius 500ms",
      "& $firstColumn": {
        backgroundColor: theme.palette.secondary.main,
      },
      "& $headerFirstColumn": {
        backgroundColor: theme.palette.secondary.main,
      },
      "& $thFirst": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  thFirst: {
    position: "sticky",
    left: 0,
    width: "50%",
    maxWidth: "48vw",
    paddingLeft: 30,
    backgroundColor: theme.palette.common.white,
  },
  rowCell: {
    width: 80,
    padding: "0px 20px 0px 0px",
    cursor: "pointer",
  },
  rowCellClickable: {
    "&:hover": {
      // transform: "scale(1.1)",
      // transition: "transform .3s",
      textDecoration: "underline",
    },
  },
  tableHeader: {
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    zIndex: 3,
    top: 0,
    boxShadow: `0 1px 0px 0px ${theme.palette.divider}`,
    // borderBottom: `30px solid ${theme.palette.common.white}`,
  },
  tableFooter: {
    width: "100%",
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    bottom: 0,
  },
  totalTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 16,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    textAlign: "right",
    padding: "10px 30px 10px 0px",
    height: 50,
    width: "30%",
  },
  firstRow: {
    padding: "0px 20px 0px 20px",
    // paddingTop: 15,
    // paddingBottom: 15,
  },
}));

type Props = {
  website: IWebsite;
  results: { pageUrl: string; totalCount: number; totalValue: number; data: object }[];
  totalItems: number;
  timeFrame: any;
  startDate: any;
  momentTimeFrame: any;
  totalsRow: any;
  breakdownFilters: {
    filter: IFunnelBreakdownColumn;
    value: any;
  }[];
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  setSortField: (sortField: { direction: "desc" | "asc" | undefined; field: string }) => void;
};

const GoalCompletionByPageTable = (props: Props) => {
  const classes = useStyles();
  const [dialogProps, setDialogProps] = React.useState<{ pageUrl: string; date: string }>(null);
  const {
    website,
    results,
    totalItems,
    timeFrame,
    startDate,
    momentTimeFrame,
    totalsRow,
    setSortField,
    sortField,
    breakdownFilters,
  } = props;

  const newDataRanges = useMemo(() => {
    const dates = [];

    const start = moment(startDate).startOf(momentTimeFrame);
    const end = moment().endOf(momentTimeFrame);

    while (start.isBefore(end)) {
      dates.push(start.format("YYYY-MM-DD"));
      start.add(1, momentTimeFrame as any);
    }

    return dates;
  }, [startDate]);

  console.log("newDataRanges", newDataRanges);

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={ClassNames(classes.thFirst, classes.headerWrapper)}>
                <MTableHeaderSortLabel
                  className={classes.headerFirstColumn}
                  field={"page_url"}
                  onClick={(value) => setSortField(value)}
                  sortField={sortField}
                >
                  {I18n.t("goal_completion_reports.column_names.pages")}
                </MTableHeaderSortLabel>
              </TableCell>
              {newDataRanges.map((date, dateIndex) => (
                <TableCell key={dateIndex} className={classes.headerWrapper}>
                  <MTableHeaderLabel fontSize={14}>
                    {moment(date).format(
                      timeFrame.value === "month" ? DATE_FORMAT.READABLE_MONTH_YEAR : DATE_FORMAT.READABLE
                    )}
                  </MTableHeaderLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/*Totals row*/}
            {totalsRow && (
              <TableRow className={classes.tableRow}>
                <TableCell className={ClassNames(classes.thFirst, classes.firstRow)}>
                  <MTableCellFirst
                    wrapperClassName={classes.firstColumn}
                    avatarClassName={classes.pageEventsAvatar}
                    cellText={I18n.t("goal_completion_reports.column_names.total_pages", { count: totalItems })}
                    prefix={
                      <div className={classes.avatarWrapper}>
                        <GlobAvatar />
                      </div>
                    }
                    toolTipProps={{ enterDelay: 300, placement: "top-start" }}
                    actions={[]}
                    fontSize={14}
                    fontWeight={600}
                  />
                </TableCell>
                {newDataRanges.map((date, dateIndex) => (
                  <TableCell key={`totals_row_${dateIndex}`} className={classes.rowCell}>
                    <MTableCellText
                      fontSize={14}
                      fontWeight={600}
                      textColor={"inherit"}
                      text={localeHelper.getCurrencyText(parseFloat(totalsRow?.data?.[date]?.value || "0"), "USD")}
                      toolTipProps={{
                        title: `${totalsRow?.data?.[date]?.count} times`,
                        enterDelay: 300,
                        placement: "top-start",
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            )}

            {results.map((row, rowIndex) => (
              <>
                <TableRow key={rowIndex} className={classes.tableRow}>
                  <TableCell className={ClassNames(classes.thFirst, classes.firstRow)}>
                    <MTableCellFirst
                      type={"link"}
                      wrapperClassName={classes.firstColumn}
                      avatarClassName={classes.pageEventsAvatar}
                      href={row.pageUrl}
                      cellText={row.pageUrl}
                      prefix={
                        <div className={classes.avatarWrapper}>
                          <GlobAvatar />
                        </div>
                      }
                      toolTipProps={{ enterDelay: 300, placement: "top-start" }}
                      actions={[]}
                      fontSize={14}
                      fontWeight={500}
                    />
                  </TableCell>
                  {newDataRanges.map((date, dateIndex) => (
                    <TableCell
                      key={`${rowIndex}_${dateIndex}`}
                      className={ClassNames(classes.rowCell, classes.rowCellClickable)}
                      onClick={() => setDialogProps({ pageUrl: row.pageUrl, date })}
                    >
                      <MTableCellText
                        fontSize={14}
                        fontWeight={400}
                        textColor={"inherit"}
                        text={localeHelper.getCurrencyText(parseFloat(row?.data?.[date]?.value || "0"), "USD")}
                        // toolTipProps={{
                        //   title: "Click to expand view",
                        //   enterDelay: 300,
                        //   placement: "bottom",
                        // }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <GoalCompletionByPageExpandedDialog
        open={dialogProps !== null}
        onClose={() => setDialogProps(null)}
        dateTrunc={dialogProps?.date}
        pageUrl={dialogProps?.pageUrl}
        timeFrame={timeFrame.value}
        breakdownFilters={breakdownFilters}
        website={website}
      />
    </>
  );
};

export default GoalCompletionByPageTable;
