import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import { CheckBox, CheckBoxOutlineBlank, CheckBoxSharp, RadioButtonChecked } from "@material-ui/icons";
import CheckIcon from "../icons/CheckIcon";

const useStyles = makeStyles((theme: Theme) => ({
  radioButtonRoot: {
    padding: "5px 7px 5px 0",
    color: "#dadada",
    "&$radioButtonChecked": {
      color: theme.palette.primary.main,
    },
  },
  label: {
    fontSize: (props: CfCheckBoxProps) => theme.typography.pxToRem(props.labelFontSize || 14),
  },
  icon: {
    fontSize: (props: CfCheckBoxProps) => theme.typography.pxToRem(props.iconSize || 15),
    fill: (props: CfCheckBoxProps) => props.iconColor || theme.palette.primary.main,
  },
  radioButtonChecked: {},
}));

type CfCheckBoxProps = {
  checked: boolean;
  indeterminate?: boolean;
  value: string;
  label?: string;
  labelFontSize?: number;
  labelClassName?: string;
  iconSize?: number;
  iconColor?: string;
  customClassName?: string;
  customIconClassName?: string;
  rootClassName?: string;
  handleChange?: Function;
  muiltiple?: boolean;
  checkedIcon?: React.ReactNode;
};

const CfCheckBox = (props: CfCheckBoxProps) => {
  const classes = useStyles(props);
  const {
    checked,
    indeterminate,
    value,
    label = null,
    labelClassName,
    rootClassName = null,
    customClassName,
    handleChange,
    muiltiple = true,
    customIconClassName,
    checkedIcon,
  } = props;
  return (
    <FormControlLabel
      className={customClassName}
      onClick={(e) => e.stopPropagation()}
      classes={{
        label: ClassNames(classes.label, labelClassName),
      }}
      control={
        <Checkbox
          checked={checked}
          classes={{
            root: ClassNames(classes.radioButtonRoot, rootClassName),
            checked: classes.radioButtonChecked,
          }}
          icon={
            <RadioButtonUncheckedIcon className={ClassNames(classes.icon, customIconClassName)} fontSize={"inherit"} />
          }
          checkedIcon={
            muiltiple ? (
              <CheckCircleIcon className={ClassNames(classes.icon, customIconClassName)} fontSize={"inherit"} />
            ) : (
              <RadioButtonChecked className={ClassNames(classes.icon, customIconClassName)} fontSize={"inherit"} />
            )
          }
          indeterminateIcon={
            <RemoveCircleOutlineRoundedIcon
              className={ClassNames(classes.icon, customIconClassName)}
              fontSize={"inherit"}
            />
          }
          onChange={(e) => {
            // e.stopPropagation();
            if (handleChange) {
              handleChange(e.target.checked);
            }
          }}
          value={value}
          name={value}
          aria-label={value}
        />
      }
      label={label}
    />
  );
};

export default CfCheckBox;
