import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import { IWebsiteDetailsProps } from "../../../../../reducers/constants/objectTypes";
import MRouterPrompt from "../../../components/MRouterPrompt";

const useStyles = makeStyles((theme: Theme) => ({
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  selects: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  selectWrap: {
    width: 300,
  },
  imagesWrapper: {
    display: "flex",
  },
  imageRow: {
    display: "flex",
    flexDirection: "column",
    marginRight: 80,
    "&:last-child": {
      marginRight: 0,
    },
  },
  imageFieldTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 15,
  },
  shopifyDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
}));

const mapStateToProps = (state) => ({
  siteDetails: state.websitePages.siteDetails,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (name, value) => dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED, name, value }),
});

type PropTypes = {
  siteDetails: IWebsiteDetailsProps;

  onPropertyChanged: (name, value) => void;
};

const SiteLinks = (props: PropTypes) => {
  const classes = useStyles(props);
  const { siteDetails, onPropertyChanged } = props;

  return (
    <>
      <PageSection sectionTitle={I18n.t("website.site_links")}>
        <MRouterPrompt />
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            key={"privacy-policy-url"}
            titleAndLengthLocation={"top"}
            value={siteDetails?.privacyPolicyURL}
            placeholder={""}
            title={I18n.t("website.privacy_policy_url")}
            nonBlocking
            height={37}
            multiLine
            borderRadius={4}
            handleValueChange={(newURL) => {
              onPropertyChanged("siteDetails", { ...siteDetails, privacyPolicyURL: newURL });
            }}
            marginBottom={30}
          />
          <TextFieldMaxLength
            key={"terms-and-conditions-url"}
            titleAndLengthLocation={"top"}
            value={siteDetails?.termsAndConditionsURL}
            placeholder={""}
            title={I18n.t("website.terms_and_conditions_url")}
            nonBlocking
            height={37}
            multiLine
            borderRadius={4}
            handleValueChange={(newURL) => {
              onPropertyChanged("siteDetails", { ...siteDetails, termsAndConditionsURL: newURL });
            }}
            marginBottom={30}
          />
        </div>
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteLinks);
