import React from "react";
import { I18n } from "react-redux-i18n";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";
import { ePageState, ePageType } from "../../../../reducers/constants/objectTypes";

type PropTypes = {
  pageState: { _id: number; name: ePageState }[];
  handlePageStateSelected: (pageState: { _id: ePageType; name: string }[]) => void;
};

const MTableToolbarPageStateFilter = (props: PropTypes) => {
  const { pageState, handlePageStateSelected } = props;
  const pageStates = Object.values(ePageState).map((value, index) => ({ _id: index, name: value }));

  return (
    <MTableToolbarBaseSelect
      multipleOptions
      options={pageStates}
      selectedOptions={pageState}
      placeholder={I18n.t("workflow.writer.filter_status")}
      title={I18n.t("workflow.writer.statuses")}
      handleChange={handlePageStateSelected}
    />
  );
};

export default MTableToolbarPageStateFilter;
