import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import CommunityEditorMainScreen from "./components/mainScreen/CommunityEditorMainScreen";
import CommunityEditorSidePanel from "./components/sidePanel/CommunityEditorSidePanel";
import CommunityEditorSideList from "./components/sideList/CommunityEditorSideList";
import agent from "../../../agent";
import { ePostType, IQuestion, IWebsite } from "../../../reducers/constants/objectTypes";
import { COMMUNITY_EDITOR } from "../../../reducers/constants/actionTypes";
import { useParams } from "react-router";
import { web } from "../../../helpers/urlHelper";
import { replace } from "connected-react-router";
import CommunityEditorHeader from "./components/CommunityEditorHeader";

const useStyles = makeStyles((theme: Theme) => ({
  answersWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: 50,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  answersInnerWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 50px)",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedQuestion: IQuestion;
  questionLoaded: boolean;
  returnTo?: string;
  returnToPrevious: (returnTo: string) => void;
  handleWritersLoaded: (payload) => void;
  handleDataLoaded: (payload, isAnswerEditor: Boolean) => void;
  cleanUp: () => void;
};

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,
    questionLoaded: state.communityEditor.questionLoaded,
    selectedQuestion: state.communityEditor.question,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleWritersLoaded: (payload) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_EDITOR_WRITERS_LOADED, payload }),
  handleDataLoaded: (payload, isAnswerEditor) =>
    dispatch({
      type: COMMUNITY_EDITOR.COMMUNITY_EDITOR_DATA_LOADED,
      payload,
      isAnswerEditor,
    }),
  cleanUp: () => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_EDITOR_CLEANUP }),
  returnToPrevious: (returnTo) => dispatch(replace(returnTo)),
});

const CommunityEditor = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, questionLoaded, selectedQuestion } = props;
  const { handleWritersLoaded, returnToPrevious, handleDataLoaded, cleanUp } = props;
  const { questionId, answerId, type } = useParams();

  const returnToPreviousPage = () => {
    if (answerId) {
      returnToPrevious(web.community("writer"));
      return;
    }

    if (type) {
      returnToPrevious(web.community(type));
      return;
    }

    returnToPrevious(web.community("new"));
  };

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    handleWritersLoaded(agent.Writers.getWriters(selectedWebsite, ePostType.COMMUNITY));
  }, [selectedWebsite]);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }

    if (questionId) {
      handleDataLoaded(agent.Questions.get(questionId), false);
    } else {
      agent.Community.getWriterAnswer(answerId)
        .then((res) => {
          handleDataLoaded(res.post, true);
        })
        .catch((e) => {
          console.error("Error", e);
          returnToPreviousPage();
        });
    }

    return () => {
      cleanUp();
    };
  }, [questionId, answerId]);

  return (
    <div className={classes.answersWrapper}>
      <div className={classes.toolbar}>
        <CommunityEditorHeader
          question={selectedQuestion}
          returnToPrevious={returnToPreviousPage}
          pageLoaded={questionLoaded}
          selectedWebsite={selectedWebsite}
        />
      </div>
      <div className={classes.answersInnerWrapper}>
        <CommunityEditorSideList />
        <CommunityEditorMainScreen />
        <CommunityEditorSidePanel />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditor);
