import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
import { Transforms } from "slate";
import { I18n } from "react-redux-i18n";
import { ReactEditor, useSlate } from "slate-react";
import { ComponentType } from "react";
import CommentIcon from "../toolbar/icons/CommentIcon";
import comment from "./commentV2";
import richTextEditorHelper from "../../helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
    disabledIcon: {
      "&:hover": {
        fill: theme.palette.text.disabled,
      },
    },
    activeIcon: {
      fill: theme.palette.primary.main,
    },
  })
);

type PropTypes = {
  Icon?: ComponentType<any>;
  onClick?: Function;
};

const CommentButtonV2 = (props: PropTypes) => {
  const classes = useStyles(props);
  const editor = useSlate();
  const { Icon = CommentIcon, onClick } = props;

  return (
    <div style={{ display: "contents" }}>
      <Tooltip
        enterDelay={2000}
        title={I18n.t(
          `rich_text_editor.tooltips.comment${richTextEditorHelper.isMultiNodeSelection(editor) ? "_disabled" : ""}`
        )}
      >
        <IconButton
          id={"insert-commentV2-button"}
          className={classes.button}
          disableRipple
          onClick={(event) => {
            event.preventDefault();
            if (comment.isCommentActive(editor) || richTextEditorHelper.isMultiNodeSelection(editor)) {
              console.log("comment already exist");
              return;
            }
            if (onClick) {
              onClick();
            }
            comment.insertComment(editor, []);
            ReactEditor.focus(editor as ReactEditor);
            Transforms.select(editor, { ...editor.selection });
          }}
        >
          <Icon
            className={ClassNames(
              classes.icon,
              comment.isCommentActive(editor) && classes.activeIcon,
              richTextEditorHelper.isMultiNodeSelection(editor) && classes.disabledIcon
            )}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CommentButtonV2;
