import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import MSelect from "../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 3,
  },
  title: {
    minWidth: 80,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(12),
    marginRight: 7,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
  },
}));

type PropTypes = {
  featuredPost: boolean;

  handleFeaturedChange: (featured) => void;
};

const PageEditorFeatured = (props: PropTypes) => {
  const classes = useStyles(props);
  const { featuredPost, handleFeaturedChange } = props;
  const theme = useTheme<Theme>();
  const options = React.useMemo(
    () => [
      {
        label: I18n.t("edit_post.yes"),
        value: true,
      },
      {
        label: I18n.t("edit_post.no"),
        value: false,
      },
    ],
    []
  );

  return (
    <div className={classes.wrapper}>
      <Typography variant={"body1"} className={classes.title}>
        {I18n.t(`edit_post.featured`)}
      </Typography>
      <MSelect
        customClass={classes.selectCustomClass}
        options={options}
        value={featuredPost ? options[0] : options[1]}
        optionLabel={"label"}
        optionValue={"value"}
        height={31}
        isBold={false}
        borderWidth={"0px"}
        textColor={theme.palette.text.primary}
        menuPlacement={"bottom"}
        fullWidth
        searchable
        getDisplayLabel={(vertical) => `${vertical.label}`}
        singleValueStyles={{ fontSize: theme.typography.pxToRem(12) }}
        handleChange={(option) => {
          handleFeaturedChange(option.value);
        }}
      />
    </div>
  );
};

export default PageEditorFeatured;
