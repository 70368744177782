import { List, Theme, makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";

type PropTypes = {
    children: ReactNode;
}


const useStyles = makeStyles((theme: Theme) => {
    const { palette } = theme;
    const isDark = palette.type === "dark";

    return {
        root: {
            padding: "30px 30px 0px 30px",
        },
        items: () => {
            const border = isDark ? `1px solid ${palette.grey["200"]}`
                : `1px solid ${palette.secondary.light}`;
            return {
                borderRadius: 3,
                display: "flex",
                flexDirection: "column",
                border,
                padding: 0,
                backgroundColor: palette.grey["100"],
            }
        },
        itemContainer: {
            overflow: "hidden",
        },
        item: {
            height: 55,
            padding: 15,
        },
        divider: (props: PropTypes) => {
            const border = isDark ? `px solid ${palette.grey["200"]}`
                : `1px solid ${palette.secondary.light}`;
            return {
                border,
            }
        },
    }
});

const ListItems = (props: PropTypes) => {
    const classes = useStyles(props);
    const { children } = props;
    return (
        <div className={classes.root}>
            <List className={classes.items}>
                {children}
            </List>
        </div>
    );
}

export { ListItems }