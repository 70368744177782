import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import set from "lodash/set";
import { IWebsiteDynamicSectionProps } from "../../../../../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../../../../../reducers/constants/actionTypes";
import PageSection from "../../../../../../components/PageSection";
import TextInputToolAndDesign from "../../../../../../components/TextInputToolAndDesign";
import TextInputMentions from "../../../../../../components/TextInputMentions";
import { LENGTHS } from "../../../../../../../../reducers/constants/consts";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

type PropTypes = {
  dynamicSection: IWebsiteDynamicSectionProps;
  emptyFields: string[];
  onPropertyChanged: (dynamicSection) => void;
  locale: string;
  defaultLocale: string;
};

const DefaultBlogTemplate = (props: PropTypes) => {
  const classes = useStyles(props);
  const { dynamicSection, onPropertyChanged, emptyFields, locale, defaultLocale } = props;
  const [mainPageEmptyFields, setMainPageEmptyFields] = React.useState([]);

  const mainPageValidated = () => {
    let valid = true;
    const requiredFields = [];
    setMainPageEmptyFields(requiredFields);
    if (!dynamicSection.mainPage.title?.text) {
      valid = false;
      requiredFields.push(I18n.t(`website.title`));
    }
    if (!dynamicSection.mainPage.description?.text) {
      valid = false;
      requiredFields.push(I18n.t(`website.description`));
    }
    if (!dynamicSection.mainPage.metaTitle) {
      valid = false;
      requiredFields.push(I18n.t(`website.meta_title`));
    }
    if (!dynamicSection.mainPage.metaDescription) {
      valid = false;
      requiredFields.push(I18n.t(`website.meta_description`));
    }
    setMainPageEmptyFields(requiredFields);
    return valid;
  };

  return (
    <>
      <PageSection
        sectionTitle={I18n.t("website.new_content_page.main_page")}
        checked={dynamicSection.mainPage.enabled}
        onCheckedChange={(checked) => {
          if (!mainPageValidated()) {
            if (!checked && dynamicSection.mainPage.enabled) {
              onPropertyChanged({
                ...dynamicSection,
                mainPage: { ...dynamicSection.mainPage, enabled: checked },
              });
            }
            return;
          }
          onPropertyChanged({
            ...dynamicSection,
            mainPage: { ...dynamicSection.mainPage, enabled: checked },
          });
        }}
      >
        {mainPageEmptyFields.length > 0 && (
          <div className={classes.errorWrapper}>
            <Typography variant={"body1"} className={classes.error}>
              {I18n.t("website.error_message")}
            </Typography>
          </div>
        )}
        <TextInputToolAndDesign
          error={mainPageEmptyFields.includes(I18n.t(`website.title`))}
          textFieldTitle={I18n.t("website.title")}
          text={
            locale === defaultLocale
              ? dynamicSection.mainPage?.title
              : {
                  text: dynamicSection.translations[locale]?.mainPage?.title || "",
                  css: dynamicSection.mainPage.title.css,
                }
          }
          handleTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...dynamicSection, mainPage: { ...dynamicSection.mainPage, title: value } });
              return;
            }
            set(dynamicSection.translations[locale], "mainPage.title", value.text);
            onPropertyChanged({ ...dynamicSection });
          }}
          variant={"h1"}
        />

        <TextInputMentions
          tags={[]}
          key={`dynamic_section_main_page_meta_title_${locale}`}
          error={mainPageEmptyFields.includes(I18n.t(`website.meta_title`))}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? dynamicSection.mainPage.metaTitle
              : dynamicSection.translations[locale]?.mainPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...dynamicSection, mainPage: { ...dynamicSection.mainPage, metaTitle: value } });
              return;
            }
            set(dynamicSection.translations[locale], "mainPage.metaTitle", value);
            onPropertyChanged({ ...dynamicSection });
          }}
        />
        <Divider className={classes.divider} />
        <TextInputToolAndDesign
          error={mainPageEmptyFields.includes(I18n.t(`website.description`))}
          textFieldTitle={I18n.t("website.description")}
          variant={"h2"}
          text={
            locale === defaultLocale
              ? dynamicSection.mainPage.description
              : {
                  text: dynamicSection.translations[locale]?.mainPage?.description || "",
                  css: dynamicSection.mainPage.description.css,
                }
          }
          handleTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...dynamicSection, mainPage: { ...dynamicSection.mainPage, description: value } });
              return;
            }
            set(dynamicSection.translations[locale], "mainPage.description", value.text);
            onPropertyChanged({ ...dynamicSection });
          }}
        />
        <TextInputMentions
          error={mainPageEmptyFields.includes(I18n.t(`website.meta_description`))}
          key={`dynamic_section_main_page_meta_description_${locale}`}
          tags={[]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? dynamicSection.mainPage.metaDescription
              : dynamicSection.translations[locale]?.mainPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                mainPage: { ...dynamicSection.mainPage, metaDescription: value },
              });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              "mainPage.metaDescription": value,
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
      </PageSection>
    </>
  );
};

export default DefaultBlogTemplate;
