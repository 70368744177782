import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 17,
    height: 14,
    fill: "transparent",
  },
}));

type PropTypes = {
  className?: string;
};

const PaddingTopIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 17 14"}>
      <path
        d="M16.1001 0.899902L0.900099 0.899903M11.7001 10.6999L11.7001 8.0999C11.7001 6.77442 10.6256 5.6999 9.3001 5.6999L6.7001 5.6999C5.37461 5.6999 4.3001 6.77442 4.3001 8.0999L4.3001 10.6999C4.3001 12.0254 5.37461 13.0999 6.7001 13.0999L9.3001 13.0999C10.6256 13.0999 11.7001 12.0254 11.7001 10.6999Z"
        stroke="#333333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default PaddingTopIcon;
