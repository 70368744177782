import * as React from "react";
import { I18n } from "react-redux-i18n";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../../elementsStyles/components/StylePanelSubSection";
import TextFieldElement from "../../../elementsStyles/ElementComponents/TextFieldElement";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "grid",
    gap: 30,
  },
  customTitleClass: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  customNameClass: {
    minHeight: 38,
  },
  customDescriptionClass: {
    minHeight: 418,
  },
}));

type PropTypes = {
  editor: Editor;
  element: any;
};

const EmbedWidgetStyle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { editor, element } = props;

  const handleElementDataChange = (el) => {
    Transforms.setNodes(editor, el, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  console.log("element", element);

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.embed.name")} editor={editor}>
      <div className={classes.container}>
        <TextFieldElement
          customTitleClass={classes.customTitleClass}
          customTextClass={classes.customNameClass}
          title={I18n.t("rich_text_editor.embed.sd_name")}
          placeholder={I18n.t("rich_text_editor.embed.sd_name_ph")}
          value={element.structuredData?.name || ""}
          direction={"column"}
          handleChange={(name) =>
            handleElementDataChange({ ...element, structuredData: { ...(element.structuredData || {}), name } })
          }
        />

        <TextFieldElement
          customTitleClass={classes.customTitleClass}
          customTextClass={classes.customDescriptionClass}
          title={I18n.t("rich_text_editor.embed.sd_description")}
          placeholder={I18n.t("rich_text_editor.embed.sd_description_ph")}
          value={element.structuredData?.description || ""}
          direction={"column"}
          handleChange={(description) =>
            handleElementDataChange({ ...element, structuredData: { ...(element.structuredData || {}), description } })
          }
        />
      </div>
    </StylePanelSection>
  );
};

export default EmbedWidgetStyle;
