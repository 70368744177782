import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function QuoteIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 32 20"}>
      <path d="M6.340,20.000 C7.360,20.000 8.300,19.420 8.740,18.520 L11.580,12.840 C11.860,12.280 12.000,11.680 12.000,11.060 L12.000,2.000 C12.000,0.900 11.100,0.000 10.000,0.000 L2.000,0.000 C0.900,0.000 -0.000,0.900 -0.000,2.000 L-0.000,10.000 C-0.000,11.100 0.900,12.000 2.000,12.000 L6.000,12.000 L3.940,16.120 C3.040,17.900 4.340,20.000 6.340,20.000 ZM26.340,20.000 C27.360,20.000 28.300,19.420 28.740,18.520 L31.580,12.840 C31.860,12.280 32.000,11.680 32.000,11.060 L32.000,2.000 C32.000,0.900 31.100,0.000 30.000,0.000 L22.000,0.000 C20.900,0.000 20.000,0.900 20.000,2.000 L20.000,10.000 C20.000,11.100 20.900,12.000 22.000,12.000 L26.000,12.000 L23.940,16.120 C23.040,17.900 24.340,20.000 26.340,20.000 Z" />
    </SvgIcon>
  );
}

export default QuoteIcon;
