import React from "react";
import { I18n } from "react-redux-i18n";
import MDropdown from "../../../MDropdown";

type PropTypes = {
  value: any;
  handleOnChange: (value) => void;
};

export const options = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

const MTableToolbarTimeFrameFilter = (props: PropTypes) => {
  const { value, handleOnChange } = props;

  return (
    <MDropdown
      options={options}
      value={value}
      handleOnChange={handleOnChange}
      placeholder={I18n.t("reports.dashboard.select_ph")}
      optionLabel="label"
      optionValue="value"
    />
  );
};

export default MTableToolbarTimeFrameFilter;
