import React from "react";
import ClassNames from "classnames";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MTextField from "./MTextField";
import theme from "../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  rowWrapper: {
    maxWidth: (props: PropTypes) => props.maxWidth || "unset",
    minWidth: (props: PropTypes) => props.minWidth || "unset",
    marginTop: (props: PropTypes) => props.marginTop || 0,
    marginBottom: (props: PropTypes) => props.marginBottom || 0,
    marginLeft: (props: PropTypes) => props.marginLeft || 0,
    marginRight: (props: PropTypes) => props.marginRight || 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  inputWithPrefix: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    width: "100%",
  },
  prefix: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  columnWrapper: {
    maxWidth: (props: PropTypes) => props.maxWidth || "unset",
    minWidth: (props: PropTypes) => props.minWidth || "unset",
    marginTop: (props: PropTypes) => props.marginTop || 0,
    marginBottom: (props: PropTypes) => props.marginBottom || 0,
    marginLeft: (props: PropTypes) => props.marginLeft || 0,
    marginRight: (props: PropTypes) => props.marginRight || 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  titleAndLengthColumnWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: (props: PropTypes) => props.alignTitle || "end",
    minWidth: (props: PropTypes) => props.titleMinWidth || 140,
    marginRight: 15,
  },
  titleAndLengthRowWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: (props: PropTypes) => props.justifyTitle || "start",
    marginBottom: 10,
  },
  title: {
    color: (props: PropTypes) => props.titleColor || theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 5,
    opacity: (props: PropTypes) => (props.disabled ? 0.7 : 1),
    cursor: "not-allowed",
  },
  length: {
    fontSize: theme.typography.pxToRem(14),
  },
  inputWrapper: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
  input: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    cursor: (props: PropTypes) => (props.disabled ? "not-allowed" : "unset"),
  },
  lengthError: {
    color: "#f44336",
  },
  error: {
    fontSize: 12,
    lineHeight: 1,
    color: "#f44336",
    marginLeft: 14,
    marginTop: 5,
  },
  titleOnTop: {
    marginRight: 10,
    marginBottom: 0,
  },
  multilineInput: {
    cursor: (props: PropTypes) => (props.disabled ? "not-allowed" : "unset"),
    width: "100%",
    minHeight: (props: PropTypes) => props.height,
    fontFamily: theme.typography.fontFamily,
    resize: "none",
    border: "none",
    outline: "none",
    margin: 0,
    fontSize: 14,
    padding: "8px 10px",
    backgroundColor: "initial",
    borderRadius: (props: PropTypes) => props.borderRadius,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: (props: PropTypes) => props.borderColor || theme.palette.divider,
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: theme.palette.text.hint,
    },
  },
}));

type PropTypes = {
  title?: string;
  titleColor?: string;
  borderColor?: string;
  outlined?: boolean;
  value: string;
  disabled?: boolean;
  height?: number;
  placeholder: string;
  borderRadius?: number;
  fullWidth?: boolean;
  titleMinWidth?: number | string;
  alignTitle?: "start" | "end";
  justifyTitle?: "space-between" | "start";
  error?: boolean;
  autoFocus?: boolean;
  multiLine?: boolean;
  maxLength?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  maxWidth?: number | string;
  minWidth?: number | string;
  titleClassName?: string;
  lengthClassName?: string;
  wrapperClassName?: string;
  inputClassName?: string;
  nonBlocking?: boolean;
  titleAndLengthLocation?: "left" | "top";
  prefix?: string;
  onEnterKey?: Function;
  handleValueChange: Function;
  onBlur?: (e: any) => void;
};

const TextFieldMaxLength = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    value,
    disabled = false,
    nonBlocking = false,
    handleValueChange,
    title = null,
    outlined = true,
    borderColor = null,
    error = false,
    autoFocus = false,
    multiLine = false,
    placeholder,
    maxLength = null,
    titleClassName = null,
    lengthClassName = null,
    wrapperClassName = null,
    inputClassName = null,
    borderRadius,
    fullWidth = false,
    height,
    titleAndLengthLocation = "left",
    prefix = null,
    onEnterKey,
    onBlur,
  } = props;

  const valueLength = value?.length || 0;
  return (
    <div
      className={ClassNames(
        titleAndLengthLocation === "left" && classes.rowWrapper,
        titleAndLengthLocation === "top" && classes.columnWrapper,
        wrapperClassName
      )}
    >
      {(title || maxLength) && (
        <div
          className={ClassNames(
            titleAndLengthLocation === "left" && classes.titleAndLengthColumnWrapper,
            titleAndLengthLocation === "top" && classes.titleAndLengthRowWrapper
          )}
        >
          <Typography
            className={ClassNames(
              classes.title,
              titleAndLengthLocation === "top" && classes.titleOnTop,
              titleClassName
            )}
            variant={"subtitle2"}
          >
            {title || ""}
          </Typography>

          {maxLength && (
            <Typography
              className={ClassNames(classes.length, valueLength >= maxLength && classes.lengthError, lengthClassName)}
              variant={"body2"}
            >
              {I18n.t("entail.text_length", { length: valueLength, maxLength })}
            </Typography>
          )}
        </div>
      )}
      <div className={classes.inputWithPrefix}>
        {prefix && <Typography className={classes.prefix}>{prefix}</Typography>}
        {multiLine ? (
          <div style={{ width: "100%", display: "flex" }}>
            <TextareaAutosize
              prefix={prefix}
              onBlur={onBlur}
              disabled={disabled}
              // onPaste={handlePaste}
              onKeyDown={(key) => {
                if (key.code === "Enter") {
                  if (onEnterKey) {
                    onEnterKey(key.target.value);
                    key.preventDefault();
                  }
                }
              }}
              value={value}
              placeholder={placeholder}
              className={ClassNames(classes.multilineInput, inputClassName)}
              onChange={(e) => {
                handleValueChange(e.target.value);
              }}
            />
            {error && <Typography className={classes.error}>{`${title} is required`}</Typography>}
          </div>
        ) : (
          <MTextField
            helperText={title}
            error={error}
            outlined={outlined}
            borderColor={borderColor}
            height={height}
            borderRadius={borderRadius}
            customClass={classes.inputWrapper}
            inputClassName={ClassNames(classes.input, inputClassName)}
            onChange={(text: string) => {
              handleValueChange(text);
            }}
            onBlur={onBlur}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            value={value || ""}
            disabled={disabled}
            placeholder={placeholder}
            handleEnterClick={onEnterKey}
          />
        )}
      </div>
    </div>
  );
};

export default TextFieldMaxLength;
