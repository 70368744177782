import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import _ from "lodash";
import KeywordGroupDialogHeader from "./components/KeywordGroupDialogHeader";
import { IKeywordGroupWithMetrics } from "../../../../reducers/constants/objectTypes";
import KeywordTabTotalItem from "./components/KeywordTabTotalItem";
import KeywordTabListItem from "./components/KeywordTabListItem";
import PageTabListItem from "./components/PageTabListItem";
import CfProgressBar from "../../../../components/CfProgressBar";
import DialogActions from "@material-ui/core/DialogActions";
import KeywordGroupDialogTableHeader from "./KeywordGroupDialogTableHeader";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    padding: "30px 30px 10px 30px",
  },
  dialogContent: {
    padding: "0",
  },
  list: {
    overflowY: "auto",
    height: 500,
  },
  listItems: {},
  footer: {
    borderTop: `${theme.palette.divider} solid 1px`,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const mapStateToProps = (state) => ({
  keywordGroup: state.keywordGroups.keywordGroup,
});

const mapDispatch = (dispatch) => ({});

type PropTypes = {
  keywordGroup: IKeywordGroupWithMetrics;
  open: boolean;
  pageTab: boolean;
  setPageTab: (page: boolean) => void;
  close: () => void;
};

const KeywordGroupDialog = (props: PropTypes) => {
  const classes = useStyles(props);

  const [ordering, setOrdering] = useState<
    {
      column: string;
      order: "asc" | "desc";
    }[]
  >([]);

  const { keywordGroup, close, open, setPageTab, pageTab } = props;

  const sortedKeywords = useMemo(() => {
    const columns = ordering.map((row) => row.column);
    const order = ordering.map((row) => row.order);

    if (keywordGroup) return _.orderBy(keywordGroup.keywords, columns, order);

    return [];
  }, [ordering, keywordGroup]);

  return (
    <Dialog open={open} onClose={(event) => close()} fullWidth maxWidth={"lg"} classes={{ paper: classes.dialogPaper }}>
      <KeywordGroupDialogHeader keywordGroup={keywordGroup} onClick={close} />
      <DialogContent className={classes.dialogContent}>
        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
          <KeywordGroupDialogTableHeader
            ordering={ordering}
            keywordCount={sortedKeywords.length}
            pageCount={keywordGroup?.pages?.length || 0}
            pageTab={pageTab}
            setPageTab={setPageTab}
            setOrdering={setOrdering}
          />

          <div className={classes.listItems}>
            {pageTab && keywordGroup?.pages?.map((page, index) => <PageTabListItem key={index} page={page} />)}

            {!pageTab &&
              sortedKeywords.map((keyword, index) => (
                <KeywordTabListItem key={index} keyword={keyword} group={keywordGroup} />
              ))}
          </div>
        </List>
      </DialogContent>
      {!pageTab && (
        <DialogActions className={classes.footer}>
          <KeywordTabTotalItem keywordGroup={keywordGroup} />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatch)(KeywordGroupDialog);
