import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    paddingRight: 0,
    paddingLeft: 0,
    maxWidth: "calc(100vw - 60px)",
  },
  tableRoot: {
    paddingTop: 0,
  },
}));

type PropTypes = {
  children: string | React.ReactNode;
  className?: string;
};

const MTable = (props: PropTypes) => {
  const classes = useStyles(props);
  const { children, className } = props;

  return (
    <div className={ClassNames(classes.table, className)}>
      <List component="nav" className={classes.tableRoot}>
        {children}
      </List>
    </div>
  );
};

export default MTable;
