import React from "react";
import { Node } from "slate";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import LinkElement from "../link/LinkElement";
import BulletedListElement from "../bulletedList/BulletedListElement";
import NumberedListElement from "../numberedList/NumberedListElement";
import { editorType } from "../../types/editor.Types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editable: {
      fontSize: (props: PropTypes) => theme.typography.pxToRem(props.fontSize || 16),
      color: theme.palette.text.primary,
      lineHeight: 1.55,
      paddingRight: 25,
      paddingLeft: 25,
      paddingTop: 20,
      paddingBottom: 20,
      minHeight: "200px !important",
      fontWeight: theme.typography.fontWeightLight as any,
      overflowY: "hidden",
    },
    paragraph: {
      fontWeight: theme.typography.fontWeightLight as any,
      fontSize: (props: PropTypes) => theme.typography.pxToRem(props.fontSize || 16),
      lineHeight: theme.typography.pxToRem(26),
      marginBottom: theme.typography.pxToRem(0),
      marginTop: 10,
      wordBreak: "break-word",
      // '&:before': {
      // 	content: 'attr(data-placeholder)',
      // },
      // '&:hover': {
      // 	'&:before': {
      // 		content: 'unset',
      // 	},
      // },
      // '&::empty': {
      // 	'&:before': {
      // 		content: 'attr(data-placeholder)',
      // 	},
      // },
    },
    placeholder: {
      "&:before": {
        content: "attr(data-placeholder)",
        display: "block",
        position: "absolute",
        color: theme.palette.text.hint,
        pointerEvents: "none",
      },
    },
    h2: {
      "&&": {
        fontSize: 30,
        fontWeight: theme.typography.fontWeightBold as any,
        lineHeight: "38px",
        marginBottom: 15,
        "&:not(:first-child)": {
          marginTop: 50,
          marginBottom: 15,
        },
      },
    },
    h3: {
      "&&": {
        fontSize: 26,
        fontWeight: theme.typography.fontWeightRegular as any,
        lineHeight: "34px",
        marginBottom: 10,
        "&:not(:first-child)": {
          marginTop: 40,
          marginBottom: 10,
        },
      },
    },
  })
);

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  placeholder?: string;
};

const LiteRenderElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, placeholder } = props;

  const alignment = element.alignment || "left";
  switch (element.type) {
    case editorType.listItem:
      return <li {...attributes}>{children}</li>;
    case editorType.numberedList:
      return (
        <NumberedListElement attributes={attributes} element={element} disableWrapperBorder>
          {children}
        </NumberedListElement>
      );
    case editorType.bulletedList:
      return (
        <BulletedListElement attributes={attributes} element={element} disableWrapperBorder>
          {children}
        </BulletedListElement>
      );
    case editorType.link:
      return <LinkElement {...props}>{children}</LinkElement>;
    case editorType.headingOne:
      return (
        <h2 className={classes.h2} color={"inherit"} {...attributes} style={{ textAlign: alignment }}>
          {children}
        </h2>
      );
    case editorType.headingTwo:
      return (
        <h3 className={classes.h3} color={"inherit"} {...attributes} style={{ textAlign: alignment }}>
          {children}
        </h3>
      );
    default: {
      return (
        <p
          className={ClassNames(classes.paragraph, Node.string(element).length === 0 && classes.placeholder)}
          data-placeholder={placeholder || "Type a paragraph"}
          style={{ textAlign: alignment }}
          {...attributes}
        >
          {children}
        </p>
      );
    }
  }
};

export default LiteRenderElement;
