import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import EntailNavbarLogo from "../../../icons/EntailNavbarLogo";
import sideImage from "../../../img/signup_side.png";
import { eEntailProducts, eMpEvent, IAdminUser, IWebsite } from "../../../reducers/constants/objectTypes";
import Typography from "@material-ui/core/Typography";
import MButton from "../../../components/MButton";
import { ArrowForward } from "@material-ui/icons";
import ClassNames from "classnames";
import FlowTextButton from "./components/FlowTextButton";
import { formatUrl, getFullHostnameFromUrl, isUrl, web } from "../../../helpers/urlHelper";
import { HOME } from "../../../reducers/constants/actionTypes";
import { replace } from "connected-react-router";
import agent from "../../../agent";
import psl from "psl";
import WebsiteUrlTextField from "./components/WebsiteUrlTextField";
import { MIXPANEL_PAGE_VIEW } from "../../../reducers/middlewares/mixpanelMiddleware";
const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    height: "100vh",
    marginRight: "auto",
    marginLeft: "auto",
    padding: "70px 0px",
    width: 661,
  },
  topWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  imageWrapper: {
    background: "linear-gradient(90deg, #574AC1 0%, #2C2093 100%)",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  entailNavbarLogo: {},
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 30,
    lineHeight: "29px",
    margin: "30px 0",
    color: "#000",
    marginTop: 70,
  },
  inputColumn: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 15,
  },
  inputTitle: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: theme.typography.fontWeightMedium as any,
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  backIcon: {
    marginRight: 10,
  },
  nextIcon: {
    marginLeft: 10,
  },
  button: {
    padding: "10px 30px",
    borderRadius: 40,
    height: 43,
  },
  backButton: {
    visibility: "hidden",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  backButtonVisible: {
    visibility: "visible",
  },
  rolesWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 35,
  },
  roles: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: 15,
    columnGap: 7,
    maxWidth: 503,
  },
}));

type PropTypes = {
  user: IAdminUser;
  website: IWebsite;
  onAdminUserLoaded: (payload: any) => void;
  onWebsiteSelected: (website: IWebsite) => void;
  onBoardingDataUpdated: (payload: any) => void;
  continueToNextStep: () => void;
  pageLoaded: Function;
  mpEvent: (event: string, props: object) => void;
  onBoardingData: any;
  redirectTo: (url: string) => void;
  onBoardingCompleted: () => void;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
  website: state.home.selectedWebsite,
  onBoardingData: state.home.onBoardingData,
});

const mapDispatchToProps = (dispatch) => ({
  onAdminUserLoaded: (result) => dispatch({ type: HOME.ADMIN_USER_LOADED, user: result.user }),
  onWebsiteSelected: (selectedWebsite) => dispatch({ type: HOME.ON_WEBSITES_SELECTED, selectedWebsite }),
  onBoardingDataUpdated: (data) => dispatch({ type: HOME.ONBOARDING_DATA_UPDATED, data }),
  continueToNextStep: () => dispatch(replace(web.websiteInfo())),
  pageLoaded: () =>
    dispatch({
      type: HOME.PAGE_LOADED,
      meta: {
        mixpanel: {
          event: MIXPANEL_PAGE_VIEW,
          props: { "page-name": "Onboarding personalize", product: eEntailProducts.cro },
        },
      },
    }),
  mpEvent: (event, props = {}) =>
    dispatch({
      type: HOME.MIXPANEL_EVENT,
      meta: { mixpanel: { event, props } },
    }),
  onBoardingCompleted: () => dispatch({ type: HOME.ONBOARDING_COMPLETED }),
  redirectTo: (url: string) => dispatch(replace(url)),
});

const Personalize = (props: PropTypes) => {
  const classes = useStyles(props);

  const {
    user,
    onBoardingData,
    website,
    onBoardingDataUpdated,
    onWebsiteSelected,
    onAdminUserLoaded,
    continueToNextStep,
    redirectTo,
    onBoardingCompleted,
    pageLoaded,
    mpEvent,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [lastStep, setLastStep] = React.useState(onBoardingData.lastStep);
  const [selectedRole, setSelectedRole] = React.useState(onBoardingData.userRole);
  const [websiteUrl, setWebsiteUrl] = React.useState(onBoardingData?.websiteUrl || "");
  const [urlError, setUrlError] = React.useState(false);
  const [currentRole, setCurrentRole] = React.useState(null);
  const [existingWebsite, setExistingWebsite] = React.useState(false);
  const [existingWebsiteName, setExistingWebsiteName] = React.useState("");

  const roles = [
    {
      _id: "owner",
      name: I18n.t("signup.personalize.roles.owner"),
    },
    {
      _id: "marketer",
      name: I18n.t("signup.personalize.roles.marketer"),
    },
    {
      _id: "seo",
      name: I18n.t("signup.personalize.roles.seo"),
    },
    {
      _id: "vp",
      name: I18n.t("signup.personalize.roles.vp"),
    },
    {
      _id: "c_level",
      name: I18n.t("signup.personalize.roles.c_level"),
    },
    {
      _id: "freelancer",
      name: I18n.t("signup.personalize.roles.freelancer"),
    },
    {
      _id: "agency",
      name: I18n.t("signup.personalize.roles.agency"),
    },
    {
      _id: "other",
      name: I18n.t("signup.personalize.roles.other"),
    },
  ];
  const { enqueueSnackbar } = useSnackbar();

  const getWebsiteDomainDetails = (url) => {
    const fullUrl = formatUrl(url);
    const hostName = getFullHostnameFromUrl(url);
    const parsedUrl = psl.parse(hostName.replace("www.", ""));
    let domain = parsedUrl.domain;
    if (parsedUrl.subdomain) {
      domain = parsedUrl.subdomain + "." + domain;
    }
    const name = parsedUrl?.sld || domain?.replace(".", "-");
    return { domain, name, url: fullUrl };
  };

  const updateWebsite = (data) => {
    setExistingWebsite(false);
    setExistingWebsiteName("");
    const eventProps: any = { text: I18n.t("signup.navigation.continue") };
    if (data.websiteUrl) {
      eventProps.website_url = data.websiteUrl;
    }
    if (data.role) {
      eventProps.role = selectedRole.name;
    }
    agent.Websites.croUpdateWebsite(data, website)
      .then((res) => {
        if (res.error) {
          if (res.existingWebsite) {
            setExistingWebsite(true);
            setExistingWebsiteName(res.websiteName);
            eventProps.error_message = I18n.t("signup.personalize.website_exist", { name: res.websiteName });
            eventProps.website_name = res.websiteName;
            return;
          }
          eventProps.error_message = res.message;
          enqueueSnackbar(res.message, { variant: "error" });
          return;
        }

        onWebsiteSelected(res.website);
        onAdminUserLoaded(res);
        if (res.to) {
          onBoardingCompleted();
          redirectTo(res.to);
          return;
        }
        continueToNextStep();
      })
      .catch((e) => {
        if (e.response.body.existingWebsite) {
          setExistingWebsite(true);
          setExistingWebsiteName(e.response.body.websiteName);
          eventProps.error_message = I18n.t("signup.personalize.website_exist", { name: e.response.body.websiteName });
          eventProps.website_name = e.response.body.websiteName;
          return;
        }
        eventProps.error_message = e.response.body.message;
        enqueueSnackbar(e.response.body.message, { variant: "error" });
      })
      .finally(() => {
        mpEvent(eMpEvent.onboardingPersonaliseButtonClick, eventProps);
        setLoading(false);
      });
  };

  const handleContinueClick = () => {
    if (lastStep && selectedRole) {
      const eventProps: any = { text: I18n.t("signup.navigation.continue") };
      const { domain, name, url } = getWebsiteDomainDetails(websiteUrl);
      if (selectedRole._id === currentRole && website && website?.url === url) {
        onBoardingDataUpdated({ userRole: selectedRole });
        continueToNextStep();
        return;
      }
      onBoardingDataUpdated({ userRole: selectedRole });
      /// update website url if  changed
      const websiteDetails: any = {};
      if (website && website?.url !== url) {
        if (isUrl(websiteUrl) || psl.isValid(websiteUrl)) {
          websiteDetails.websiteUrl = url;
          websiteDetails.s3BucketFolder = name;
          websiteDetails.dbName = name;
          websiteDetails.websiteHost = domain;
        } else {
          setUrlError(true);
          mpEvent(eMpEvent.onboardingPersonaliseButtonClick, {
            text: I18n.t("signup.navigation.continue"),
            website_url: websiteUrl,
            error_message: I18n.t("signup.personalize.website_url_error"),
          });
          return;
        }
      }

      setLoading(true);

      updateWebsite({
        role: selectedRole._id,
        ...websiteDetails,
        siteDetails: {
          ...website.configurations.siteDetails,
        },
      });
      return;
    }

    if (isUrl(websiteUrl) || psl.isValid(websiteUrl)) {
      setUrlError(false);
      setExistingWebsite(false);
      setExistingWebsiteName("");
      const { domain, name, url } = getWebsiteDomainDetails(websiteUrl);
      const eventProps: any = { website_url: url, text: I18n.t("signup.navigation.continue") };
      /// if the website is already created with the same url then just move to the next step
      if (website?.url === url) {
        setLastStep(true);
        onBoardingDataUpdated({ lastStep: true });
        mpEvent(eMpEvent.onboardingPersonaliseButtonClick, eventProps);
        return;
      }

      if (website && website?.url !== url) {
        updateWebsite({
          websiteUrl: url,
          websiteHost: domain,
          s3BucketFolder: name,
          dbName: name,
          siteDetails: {
            ...website.configurations.siteDetails,
          },
        });
        return;
      }

      setLoading(true);

      onBoardingDataUpdated({ websiteUrl: url, websiteHost: domain, s3BucketFolder: name, dbName: name });
      agent.Websites.croNewWebsite({ websiteUrl: url, websiteHost: domain, s3BucketFolder: name, dbName: name })
        .then((res) => {
          if (res.error) {
            if (res.existingWebsite) {
              setExistingWebsite(true);
              setExistingWebsiteName(res.websiteName);
              eventProps.error_message = I18n.t("signup.personalize.website_exist", { name: res.websiteName });
              eventProps.website_name = res.websiteName;
              return;
            }
            eventProps.error_message = res.message;
            enqueueSnackbar(res.message, { variant: "error" });
            return;
          }

          setLastStep(true);
          onBoardingDataUpdated({ lastStep: true });
          onWebsiteSelected(res.website);
          onAdminUserLoaded(res);
          if (res.to) {
            onBoardingCompleted();
            redirectTo(res.to);
            return;
          }
          setLastStep(true);
          onBoardingDataUpdated({ lastStep: true });
        })
        .catch((e) => {
          if (e.response.body.existingWebsite) {
            setExistingWebsite(true);
            setExistingWebsiteName(e.response.body.websiteName);
            eventProps.error_message = I18n.t("signup.personalize.website_exist", {
              name: e.response.body.websiteName,
            });
            eventProps.website_name = e.response.body.websiteName;
            return;
          }
          eventProps.error_message = e.response.body.message;
          enqueueSnackbar(e.response.body.message, { variant: "error" });
        })
        .finally(() => {
          mpEvent(eMpEvent.onboardingPersonaliseButtonClick, eventProps);
          setLoading(false);
        });
    } else {
      mpEvent(eMpEvent.onboardingPersonaliseButtonClick, {
        text: I18n.t("signup.navigation.continue"),
        website_url: websiteUrl,
        error_message: I18n.t("signup.personalize.website_url_error"),
      });
      setUrlError(true);
    }
  };

  React.useEffect(() => {
    if (websiteUrl) {
      return;
    }
    if (website) {
      setWebsiteUrl(website.url);
      const currentRole = user?.websiteRoles?.find(
        (wr) => wr.website.toString() === website?._id || wr.website._id === website?._id
      )?.role;
      setSelectedRole(roles.find((r) => r._id === currentRole));
      setLastStep(true);
      onBoardingDataUpdated({ lastStep: true });
    }
  }, [website]);

  // handle user website role
  React.useEffect(() => {
    if (!user || !website) {
      return;
    }
    const role = user?.websiteRoles?.find((wr) => wr.website.toString() === website?._id)?.role;
    setCurrentRole(role);
    if (role && !selectedRole) {
      setSelectedRole(roles.find((r) => r._id === role));
    }
  }, [user, website]);

  React.useEffect(() => {
    pageLoaded();
  }, []);

  return (
    <section className={classes.pageWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.topWrapper}>
          <EntailNavbarLogo className={classes.entailNavbarLogo} />
          <Typography className={classes.title}>{I18n.t("signup.personalize.title")}</Typography>
          <div className={classes.inputColumn}>
            <Typography className={classes.inputTitle}>{I18n.t("signup.personalize.website_url")}</Typography>
            <WebsiteUrlTextField
              onContactUsClick={() =>
                mpEvent(eMpEvent.onboardingPersonaliseButtonClick, {
                  text: I18n.t("signup.personalize.website_exist_contact_message_link"),
                  website_url: websiteUrl,
                })
              }
              existingWebsite={existingWebsite}
              websiteName={existingWebsiteName}
              invalidUrl={urlError}
              value={websiteUrl}
              onChange={(v) => {
                onBoardingDataUpdated({ websiteUrl: v });
                setWebsiteUrl(v);
              }}
            />
          </div>
          {lastStep && (
            <div className={classes.rolesWrapper}>
              <div className={classes.inputColumn}>
                <Typography className={classes.inputTitle}>{I18n.t("signup.personalize.roles.title")}</Typography>
                <div className={classes.roles}>
                  {roles.map((role) => (
                    <FlowTextButton
                      onChange={(value) => {
                        onBoardingDataUpdated({ role: value });
                        setSelectedRole(value);
                      }}
                      value={role}
                      selected={role._id === selectedRole?._id}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={classes.navigation}>
          {
            <MButton
              customClassNames={ClassNames(classes.button, classes.backButton, lastStep && classes.backButtonVisible)}
              onClick={() => {
                mpEvent(eMpEvent.onboardingPersonaliseButtonClick, {
                  text: I18n.t("signup.navigation.back"),
                  website_url: websiteUrl,
                });
                setLastStep(false);
                onBoardingDataUpdated({ lastStep: false });
              }}
            >
              {I18n.t("signup.navigation.back")}
            </MButton>
          }
          <MButton loading={loading} customClassNames={classes.button} onClick={() => handleContinueClick()}>
            <span>{I18n.t("signup.navigation.continue")}</span>
            <ArrowForward className={classes.nextIcon} />
          </MButton>
        </div>
      </div>
      <div className={classes.imageWrapper}>
        <img alt={"sign up"} src={sideImage} />
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Personalize);
