import filter from "lodash/filter";
import _ from "lodash";
import moment from "moment";
import { CATEGORIZE } from "./constants/actionTypes";
import { eEditedPostTextType } from "./constants/objectTypes";

const initialState = {
  filter: "facebook_groups",
  fbGroups: [],
  fbGroupsPosts: [],
  selectedFbGroup: null,
  selectedFBPostId: null,
  suitableFor: [],
  fbPost: null,
  originalFbPost: null,
  suggestedTags: [],

  keywords: [],
  selectedKeyword: null,
  keywordPosts: [],

  topics: [],
  topicsSearchField: "",

  categories: [],

  tags: [],
  tagsSearchField: "",

  // rich text dialog
  richTextDialogOpen: false,
  richTextDialogInitialValue: null,
  richTextDialogItemFbId: null,
  richTextDialogRichText: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CATEGORIZE.FILTER_CHANGED:
      return {
        ...state,
        filter: action.filter,
      };
    case CATEGORIZE.RESET_PAGE: {
      return {
        ...state,
        selectedFBPostId: null,
        fbPost: null,

        selectedFbGroup: null,
        fbGroupsPosts: [],

        selectedKeyword: null,
        keywordPosts: [],
      };
    }
    case CATEGORIZE.LOAD_NEW_POST: {
      if (state.filter === "facebook_groups") {
        const postIndex = _.findIndex(state.fbGroupsPosts, { _id: state.selectedFBPostId });
        const fbGroupsPosts = state.fbGroupsPosts.filter((p) => p._id !== state.selectedFBPostId);
        const selectedFBPostId =
          fbGroupsPosts.length > 0 ? fbGroupsPosts[Math.min(fbGroupsPosts.length - 1, postIndex)]._id : null;
        const selectedFbGroupIndex = _.findIndex(state.fbGroups, { _id: state.selectedFbGroup._id });
        const selectedFbGroup =
          selectedFBPostId === null
            ? state.fbGroups[Math.min(state.fbGroups.length - 1, selectedFbGroupIndex + 1)]
            : state.selectedFbGroup;
        return {
          ...state,
          fbPost: null,
          fbGroupsPosts,
          selectedFBPostId,
          selectedFbGroup,
        };
      }
      const postIndex = _.findIndex(state.keywordPosts, { _id: state.selectedFBPostId });
      const keywordPosts = state.keywordPosts.filter((p) => p._id !== state.selectedFBPostId);
      const selectedFBPostId =
        keywordPosts.length > 0 ? keywordPosts[Math.min(keywordPosts.length - 1, postIndex)]._id : null;
      const selectedKeywordIndex = _.findIndex(state.keywords, { _id: state.selectedKeyword._id });
      const selectedKeyword =
        selectedFBPostId === null
          ? state.keywords[Math.min(state.keywords.length - 1, selectedKeywordIndex + 1)]
          : state.selectedKeyword;
      return {
        ...state,
        fbPost: null,
        keywordPosts,
        selectedFBPostId,
        selectedKeyword,
      };
    }
    case CATEGORIZE.FB_GROUPS_LOADED:
      return {
        ...state,
        fbGroups: action.payload.groups,
        // selectedFbGroup: state.selectedFbGroup ? state.selectedFbGroup : action.payload.groups[0],
      };
    case CATEGORIZE.FB_GROUP_POSTS_LOADED:
      return {
        ...state,
        fbGroupsPosts: action.payload.posts,
        selectedFBPostId: action.payload.posts.length > 0 ? action.payload.posts[0]._id : null,
      };
    case CATEGORIZE.FB_GROUP_SELECTED:
      return {
        ...state,
        selectedFbGroup: action.fbGroup,
      };
    case CATEGORIZE.KEYWORDS_LOADED:
      return {
        ...state,
        keywords: action.payload.keywords,
      };
    case CATEGORIZE.KEYWORD_SELECTED:
      return {
        ...state,
        selectedKeyword: action.keyword,
      };
    case CATEGORIZE.KEYWORD_POSTS_LOADED:
      return {
        ...state,
        keywordPosts: action.payload.posts,
        selectedFBPostId: action.payload.posts.length > 0 ? action.payload.posts[0]._id : null,
      };
    case CATEGORIZE.FB_POST_SELECTED:
      return {
        ...state,
        selectedFBPostId: action.fbPostId,
      };
    case CATEGORIZE.FB_POST_LOADED:
      return {
        ...state,
        fbPost: action.payload.post,
        originalFbPost: action.payload.post,
        suggestedTags: action.payload.suggestedTags,
      };
    case CATEGORIZE.RESET_FB_POST:
      return {
        ...state,
        fbPost: state.originalFbPost,
      };
    case CATEGORIZE.SKIP_POST:
      return {
        ...state,
        // loadNewPost: true,
      };
    case CATEGORIZE.CONVERT_ANSWER_TO_QUESTION:
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          type:
            action.fbId === state.fbPost.fbId
              ? eEditedPostTextType.QUESTION
              : state.fbPost.type === eEditedPostTextType.QUESTION
              ? eEditedPostTextType.ANSWER
              : state.fbPost.type,
          comments: state.fbPost.comments.map((comment) => {
            return {
              ...comment,
              type:
                action.fbId === comment.fbId
                  ? eEditedPostTextType.QUESTION
                  : comment.type === eEditedPostTextType.QUESTION
                  ? eEditedPostTextType.ANSWER
                  : comment.type,
              replies: comment.replies
                ? comment.replies.map((reply) => {
                    return {
                      ...reply,
                      type:
                        action.fbId === reply.fbId
                          ? eEditedPostTextType.QUESTION
                          : reply.type === eEditedPostTextType.QUESTION
                          ? eEditedPostTextType.ANSWER
                          : reply.type,
                    };
                  })
                : null,
            };
          }),
        },
      };
    case CATEGORIZE.DELETE_POST: {
      if (state.filter === "facebook_groups") {
        const postIndex = _.findIndex(state.fbGroupsPosts, { _id: action.payload.post._id });
        const fbGroupsPosts = state.fbGroupsPosts.filter((p) => p._id !== action.payload.post._id);
        return {
          ...state,
          fbGroupsPosts,
          selectedFBPostId: fbGroupsPosts[Math.min(fbGroupsPosts.length - 1, postIndex)]._id,
        };
      }
      const postIndex = _.findIndex(state.keywordPosts, { _id: action.payload.post._id });
      const keywordPosts = state.keywordPosts.filter((p) => p._id !== action.payload.post._id);
      return {
        ...state,
        keywordPosts,
        selectedFBPostId: keywordPosts[Math.min(keywordPosts.length - 1, postIndex)]._id,
      };
    }
    case CATEGORIZE.NEW_QUESTION:
      return {
        ...initialState,
        topics: state.topics,
        tags: state.tags,
      };
    case CATEGORIZE.ON_POST_CHANGED:
      return {
        ...state,
        fbPost: action.fbPost,
      };
    case CATEGORIZE.ON_RICH_TEXT_CHANGED: {
      if (action.fbId === state.fbPost.fbId) {
        return {
          ...state,
          fbPost: {
            ...state.fbPost,
            text: action.text,
            richText: action.richText,
          },
        };
      }

      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          comments: state.fbPost.comments.map((comment) => {
            if (comment.fbId === action.fbId) {
              return {
                ...comment,
                text: action.text,
                richText: action.richText,
              };
            }
            return {
              ...comment,
              replies: comment.replies.map((reply) => {
                if (reply.fbId === action.fbId) {
                  return {
                    ...reply,
                    text: action.text,
                    richText: action.richText,
                  };
                }
                return reply;
              }),
            };
          }),
        },
      };
    }
    case CATEGORIZE.ON_ANSWER_VALUE_CHANGED:
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          [action.key]: action.fbId === state.fbPost.fbId ? action.value : state.fbPost[action.key],
          comments: state.fbPost.comments.map((comment) => {
            if (comment.fbId === action.fbId) {
              return { ...comment, [action.key]: action.value };
            }
            return {
              ...comment,
              replies: comment.replies.map((reply) => {
                if (reply.fbId === action.fbId) {
                  return {
                    ...reply,
                    [action.key]: action.value,
                  };
                }
                return reply;
              }),
            };
          }),
        },
      };
    case CATEGORIZE.ON_COMMENT_CHANGED:
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          comments: state.fbPost.comments.map((comment) => {
            if (comment.fbId === action.comment.fbId) {
              return action.comment;
            }
            return comment;
          }),
        },
      };
    case CATEGORIZE.ON_REPLY_CHANGED:
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          comments: state.fbPost.comments.map((comment) => {
            return {
              ...comment,
              replies: comment.replies.map((reply) => {
                if (reply.fbId === action.reply.fbId) {
                  return action.reply;
                }
                return reply;
              }),
            };
          }),
        },
      };
    case CATEGORIZE.ON_QUESTION_CHANGED:
      return {
        ...state,
        question: action.question,
      };
    case CATEGORIZE.ON_QUESTION_POSTED_TIME_CHANGED: {
      let lastDate = moment(action.postedTime).add(1 + Math.floor(Math.random() * 5), "day");
      const fbPost = {
        ...state.fbPost,
        postedTime: state.fbPost.type === eEditedPostTextType.QUESTION ? moment(action.postedTime) : lastDate.toDate(),
        comments: state.fbPost.comments.map((comment) => {
          lastDate = moment(lastDate).add(1 + Math.floor(Math.random() * 5), "day");
          return {
            ...comment,
            postedTime: comment.type === eEditedPostTextType.QUESTION ? moment(action.postedTime) : lastDate.toDate(),
            replies: comment.replies.map((reply) => {
              lastDate = moment(lastDate).add(1 + Math.floor(Math.random() * 5), "day");
              return {
                ...reply,
                postedTime: reply.type === eEditedPostTextType.QUESTION ? moment(action.postedTime) : lastDate.toDate(),
              };
            }),
          };
        }),
      };
      return {
        ...state,
        fbPost,
      };
    }
    case CATEGORIZE.ADD_NEW_ANSWER: {
      // fbId = 0 -> in the beginning
      // fbId = -1 -> in the end
      const newAnswer = {
        user: null,
        type: eEditedPostTextType.ANSWER,
        fbId: `manual_${Date.now()}`,
        replies: [],
      };

      if (action.fbId === "start" || action.fbId === "end") {
        console.log("action.fbId", action.fbId);
        return {
          ...state,
          fbPost: {
            ...state.fbPost,
            comments:
              action.fbId === "start"
                ? [{ ...newAnswer, postedTime: moment(state.fbPost).add(1, "days").toDate() }, ...state.fbPost.comments]
                : [
                    ...state.fbPost.comments,
                    {
                      ...newAnswer,
                      postedTime: moment(
                        state.fbPost.comments
                          ? state.fbPost.comments[state.fbPost.comments.length - 1].postedTime
                          : state.fbPost.postedTime
                      )
                        .add(1, "days")
                        .toDate(),
                    },
                  ],
          },
        };
      }

      if (action.isComment) {
        const index = _.findIndex(state.fbPost.comments, { fbId: action.fbId });

        if (state.fbPost.comments[index].replies && state.fbPost.comments[index].replies.length > 0) {
          const newComments = state.fbPost.comments.slice();
          newComments[index].replies = [
            { ...newAnswer, postedTime: moment(newComments[index].postedTime).add(1, "days").toDate() },
            ...newComments[index].replies,
          ];
          return {
            ...state,
            fbPost: {
              ...state.fbPost,
              comments: newComments,
            },
          };
        }

        const newComments = state.fbPost.comments.slice();
        newComments.splice(index + 1, 0, {
          ...newAnswer,
          postedTime: moment(state.fbPost.comments[Math.max(index, 0)].postedTime).toDate(),
        });
        console.log("comment splice", newComments);
        return {
          ...state,
          fbPost: {
            ...state.fbPost,
            comments: newComments,
          },
        };
      }

      console.log("replies");
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          comments: state.fbPost.comments.map((comment) => {
            if (comment.replies && comment.replies.length > 0) {
              const index = _.findIndex(comment.replies, { fbId: action.fbId });
              const newReplies = comment.replies.slice();
              newReplies.splice(index + 1, 0, {
                ...newAnswer,
                postedTime: moment(comment.replies[Math.max(index, 0)].postedTime).toDate(),
              });
              console.log("replies index", index);
              if (index > -1) {
                return {
                  ...comment,
                  replies: newReplies,
                };
              }
            }
            return comment;
          }),
        },
      };
    }
    case CATEGORIZE.REMOVE_NEW_ANSWER:
      // const filterAnswers = filter(state.answers, answer => answer.index !== action.answer.index);
      return {
        ...state,
        // answers: filterAnswers.map((answer, index) => ({...answer, index})),
      };
    case CATEGORIZE.ON_TAGS_LOADED:
      return {
        ...state,
        tags: action.payload.tags,
      };
    case CATEGORIZE.ON_TAG_SELECTED:
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          tags: [...state.fbPost.tags, action.tag],
        },
      };
    case CATEGORIZE.ON_TAG_REMOVED:
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          tags: filter(state.fbPost.tags, (tag) => tag.name !== action.tag.name),
        },
      };
    case CATEGORIZE.ON_TAGS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        tagsSearchField: action.input,
      };
    case CATEGORIZE.ON_NEW_TAG_CREATED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        tags: _.uniqBy([...state.tags, action.payload.tag], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        tagsSearchField: "",
        fbPost: {
          ...state.fbPost,
          tags: _.uniqBy([...state.fbPost.tags, action.payload.tag], "_id"),
        },
        suggestedTags: _.filter(state.suggestedTags, (t) => t !== action.payload.tag.name),
      };
    case CATEGORIZE.ON_TOPICS_LOADED:
      return {
        ...state,
        topics: action.payload.topics,
        // fbPost: {
        //   ...state.fbPost,
        // topic: action.payload.topics[0],
        // },
      };
    case CATEGORIZE.ON_TOPIC_SELECTED:
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          topic: action.topic,
        },
      };
    case CATEGORIZE.ON_TOPICS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        topicsSearchField: action.input,
      };
    case CATEGORIZE.ON_NEW_TOPIC_CREATED:
      return {
        ...state,
        topics: _.uniqBy([...state.topics, action.payload.topic], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        topicsSearchField: "",
        fbPost: {
          ...state.fbPost,
          topic: action.payload.topic,
        },
      };
    case CATEGORIZE.ON_CATEGORIES_LOADED:
      return {
        ...state,
        categories: action.payload.categories,
      };
    case CATEGORIZE.ON_CATEGORY_SELECTED:
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          category: action.category,
        },
      };
    case CATEGORIZE.OPEN_RICH_TEXT_EDITOR_DIALOG:
      return {
        ...state,
        richTextDialogOpen: true,
        richTextDialogInitialValue: action.initialText || null,
        richTextDialogRichText: action.richText || null,
        richTextDialogItemFbId: action.fbId,
      };
    case CATEGORIZE.CLOSE_RICH_TEXT_EDITOR_DIALOG:
      return {
        ...state,
        richTextDialogOpen: false,
        richTextDialogInitialValue: null,
        richTextDialogItemFbId: null,
        richTextDialogRichText: null,
      };
    case CATEGORIZE.ON_SUITABLE_FOR_CHANGED: {
      // let { suitableFor } = state;
      // if (suitableFor.includes(action.category)) {
      //   suitableFor = filter(suitableFor, category => category !== action.category);
      // } else {
      //   suitableFor = [...suitableFor, action.category];
      // }
      return {
        ...state,
        suitableFor: [action.category],
      };
    }
    case CATEGORIZE.ON_SOURCE_URL_ADDED:
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          sourceURLs: [...(state.fbPost.sourceURLs || []), action.url],
        },
      };
    case CATEGORIZE.ON_SOURCE_URL_REMOVED:
      return {
        ...state,
        fbPost: {
          ...state.fbPost,
          sourceURLs: filter(state.fbPost.sourceURLs, (url) => url !== action.url),
        },
      };
    default:
      return state;
  }
};
