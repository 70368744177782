import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Divider, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { I18n } from "react-redux-i18n";

import VideoEditorUpdateButton from "./VideoEditorUpdateButton";
import ArrowLeftIcon from "../../../icons/ArrowLeftIcon";
import { useVideoEditorContext } from "../VideoEditorContext";
import agent from "../../../agent";
import { useSnackbar } from "notistack";
import ability from "../../../casl/ability";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 55,
    paddingRight: 25,
    paddingLeft: 25,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
  },
  rightSide: {},
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  postDetails: {
    display: "flex",
    alignItems: "center",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    marginRight: 20,
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  headerTitle: {
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  divider: {
    height: 26,
    margin: "0 15px",
  },
  middleDivider: {
    height: 26,
    margin: "0 15px 0 7px",
  },
  title: {
    maxWidth: 500,
    fontWeight: theme.typography.fontWeightBold as any,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  iconEdit: { height: 16, width: 16, marginLeft: 5, cursor: "pointer" },
  editTitleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  inputWrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    width: 300,
    height: "100%",
    boxSizing: "border-box",
  },
  inputLabel: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  editBtn: {
    height: 35,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    textTransform: "none",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const VideoEditorHeader = () => {
  const classes = useStyles();
  const { selectedWebsite, videoProject, editVideoProject, returnToPreviousScreen } = useVideoEditorContext();
  const [isEditing, setIsEditing] = React.useState(false);
  const [projectTitle, setProjectTitle] = React.useState(videoProject.title);
  const { enqueueSnackbar } = useSnackbar();

  const updateTitle = () => {
    if (projectTitle.trim() === "") {
      return;
    }

    if (projectTitle.trim() === videoProject.title) {
      setIsEditing(false);
      return;
    }

    agent.VideoProjects.updateTitle(selectedWebsite, videoProject, projectTitle)
      .then(() => {
        enqueueSnackbar(I18n.t("video_workflow.title_updated"));
        setIsEditing(false);
        editVideoProject({ ...videoProject, title: projectTitle });
      })
      .catch((e) => enqueueSnackbar(e.message));
  };

  return (
    <div className={classes.toolbar}>
      <div className={classes.leftSide}>
        <div className={classes.toolbarDetails}>
          <ArrowLeftIcon className={classes.arrowIcon} onClick={returnToPreviousScreen} />
        </div>
        <div className={classes.toolbarDetails}>
          <div className={classes.postDetails}>
            <Typography className={classes.headerTitle} color={"textPrimary"} variant={"body2"}>
              {selectedWebsite?.name}
            </Typography>
            <Divider orientation={"vertical"} className={classes.divider} />
            {!isEditing && (
              <>
                <Typography className={classes.title} color={"textPrimary"} variant={"body2"}>
                  {projectTitle}
                </Typography>
                {ability.can("edit", "video_project_title") && (
                  <EditIcon
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    className={classes.iconEdit}
                  />
                )}
              </>
            )}
            {isEditing && (
              <div className={classes.editTitleWrapper}>
                <div className={classes.inputWrapper}>
                  <TextField
                    className={classes.input}
                    onChange={(event) => setProjectTitle(event.target.value)}
                    value={projectTitle}
                    InputProps={{
                      classes: {
                        input: classes.inputLabel,
                      },
                    }}
                  />
                </div>
                <Button className={classes.editBtn} onClick={updateTitle}>
                  {I18n.t("video_workflow.save")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <VideoEditorUpdateButton />
    </div>
  );
};

export default VideoEditorHeader;
