import * as React from "react";
import { useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import _ from "lodash";
import MTableHeader from "../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../components/Table/Table/MTableHeaderSortLabel";

type PropTypes = {
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  setSortField?: (input: { field: string; direction: "asc" | "desc" }) => void;
  total?: number;
};
const MappingListColumns = (props: PropTypes) => {
  const { total } = props;
  const theme: any = useTheme();
  return (
    <MTableHeader>
      <MTableHeaderLabel columnWidth={"33%"} fontSize={theme.typography.pxToRem(21)}>
        {numeral(total || 0).format("0,0a")}{" "}
        {total !== 1 ? I18n.t("strategy.outlinePages.titles") : _.lowerCase(I18n.t("strategy.outlinePages.title"))}
      </MTableHeaderLabel>
      <MTableHeaderLabel> {I18n.t("strategy.outlinePages.feedback")}</MTableHeaderLabel>
      <MTableHeaderLabel>{I18n.t("strategy.outlinePages.added")}</MTableHeaderLabel>
      <MTableHeaderLabel> {I18n.t("strategy.outlinePages.type")}</MTableHeaderLabel>
      <MTableHeaderLabel>{I18n.t("strategy.outlinePages.funnelStage")}</MTableHeaderLabel>
      <MTableHeaderLabel>{I18n.t("strategy.outlinePages.ranking_pages")}</MTableHeaderLabel>
    </MTableHeader>
  );
};

export default MappingListColumns;
