import * as React from "react";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabelWithSelectAll from "../../../../components/Table/Table/MTableHeaderLabelWithSelectAll";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";

type PropTypes = {
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  setSortField?: (input: { field: string; direction: "asc" | "desc" }) => void;
  selectedNr: number;
  clearSelected(): void;
  selectAll(): void;
  total: number;
};

const ContentGapsColumns = (props: PropTypes) => {
  const { sortField, setSortField, total, selectedNr, clearSelected, selectAll } = props;

  return (
    <MTableHeader>
      <MTableHeaderLabelWithSelectAll
        selected={selectedNr > 0}
        onSelectAll={selectAll}
        onClearAll={clearSelected}
        columnWidth={"40%"}
      >
        {numeral(total).format("0,0a")}{" "}
        {total !== 1 ? I18n.t("strategy.contentGaps.topics") : I18n.t("strategy.contentGaps.topic")}
      </MTableHeaderLabelWithSelectAll>
      <MTableHeaderLabel> {I18n.t("strategy.contentGaps.columns.pages")}</MTableHeaderLabel>
      <MTableHeaderSortLabel
        field={"conversion_potential"}
        onClick={(result) => setSortField(result)}
        sortField={sortField}
      >
        {I18n.t("strategy.contentGaps.columns.funnel_stage")}
      </MTableHeaderSortLabel>
      <MTableHeaderLabel> {I18n.t("strategy.contentGaps.columns.kws")}</MTableHeaderLabel>
      <MTableHeaderSortLabel field={"volume"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.contentGaps.columns.volume")}
      </MTableHeaderSortLabel>{" "}
      <MTableHeaderSortLabel field={"impressions"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.contentGaps.columns.impressions")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel field={"clicks"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.contentGaps.columns.clicks")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel field={"position"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.contentGaps.columns.position")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel field={"potential_value"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.contentGaps.columns.potential_value")}
      </MTableHeaderSortLabel>{" "}
      <MTableHeaderSortLabel field={"value"} onClick={(result) => setSortField(result)} sortField={sortField}>
        {I18n.t("strategy.contentGaps.columns.value")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        field={"value_difference"}
        onClick={(result) => setSortField(result)}
        sortField={sortField}
      >
        {I18n.t("strategy.contentGaps.columns.value_difference")}
      </MTableHeaderSortLabel>
    </MTableHeader>
  );
};

export default ContentGapsColumns;
