import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import numeral from "numeral";
import { web } from "../../../../../helpers/urlHelper";
import WidgetsListItem from "./WidgetListItem";
import { WIDGETS } from "../../../../../reducers/constants/actionTypes";
import agent from "../../../../../agent";
import { IWebsite, IWidget, eWidgetAction } from "../../../../../reducers/constants/objectTypes";
import MTable from "../../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import { ListItem } from "@material-ui/core";
import CfProgressBar from "../../../../../components/CfProgressBar";
import MTableHeaderSortLabel from "../../../../../components/Table/Table/MTableHeaderSortLabel";
import WidgetAnalyticsDialog from "./WidgetAnalyticsDialog";
import moment from "moment";

type PropTypes = {
  loading: boolean;
  widgets: IWidget[];
  selectedWebsite: IWebsite;
  page: number;
  totalPages: number;
  pageSize: number;
  totalWidgets: number;
  timePeriod: any;
  sortField: { direction: "desc" | "asc" | undefined; field: string };

  goToWidget(id: string): void;
  widgetsChanged(): void;
  handleValueChanged: (name: string, value: any) => void;
};

const mapStateToProps = (state) => ({
  loading: state.widgets.loading,
  widgets: state.widgets.widgets,
  selectedWebsite: state.home.selectedWebsite,
  totalWidgets: state.widgets.total,
  timePeriod: state.widgets.timePeriod,

  page: state.widgets.page,
  totalPages: state.widgets.totalPages,
  pageSize: state.widgets.pageSize,
  sortField: state.widgets.sortField,
});

const mapDispatchToProps = (dispatch) => ({
  handleValueChanged: (key, value) => dispatch({ type: WIDGETS.ON_VALUE_CHANGED, key, value }),
  goToWidget: (id) => dispatch(push(web.widgetsEditor(id))),
});

const WidgetsList = (props: PropTypes) => {
  const { loading, widgets, selectedWebsite, totalWidgets, timePeriod } = props;
  const [widgetAnalytics, setWidgetAnalytics] = React.useState<IWidget | null>(null);
  const { goToWidget, widgetsChanged, handleValueChanged } = props;

  const activeWidgets = React.useMemo(() => widgets.filter((item) => item.status !== "Deleted"), [widgets]);
  const deletedWidgets = React.useMemo(() => widgets.filter((item) => item.status === "Deleted"), [widgets]);

  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteWidget = (id: string) => {
    agent.Widgets.deleteWidget(id)
      .then((res) => {
        if (res.error) return;
        enqueueSnackbar(res.message ?? I18n.t("widgets_main.list.widget_deleted"));
        widgetsChanged();
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  const restoreWidget = (widget: IWidget) => {
    agent.Widgets.updateWidget(widget, eWidgetAction.publish)
      .then((res) => {
        if (res.error) return;
        enqueueSnackbar(I18n.t("widgets_main.list.widget_restored"));
        widgetsChanged();
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  const duplicateWidget = (widget: IWidget) => {
    agent.Widgets.updateWidget(widget, eWidgetAction.duplicate)
      .then((res) => {
        if (res.error) return;
        enqueueSnackbar(I18n.t("widgets_main.list.widget_duplicated"));
        widgetsChanged();
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  const theme = useTheme();

  return (
    <>
      <MTable>
        <MTableHeader>
          <MTableHeaderLabel columnWidth={"30%"} fontSize={theme.typography.pxToRem(20)}>
            {I18n.t("widgets_main.list.widgets", { count: numeral(totalWidgets || 0).format("0,0a") })}
          </MTableHeaderLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged("sortField", result)}
            sortField={props.sortField}
            field={"updatedAt"}
          >
            {I18n.t("widgets_main.list.updated")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel> {I18n.t("widgets_main.list.status")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("widgets_main.list.type")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("widgets_main.list.campaigns")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("widgets_main.list.articles")}</MTableHeaderLabel>
          <MTableHeaderLabel> {I18n.t("widgets_main.list.products")}</MTableHeaderLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged("sortField", result)}
            sortField={props.sortField}
            field={`analytics.${timePeriod.key}.totalLoads`}
          >
            {I18n.t("widgets_main.list.widget_loads")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged("sortField", result)}
            sortField={props.sortField}
            field={`analytics.${timePeriod.key}.totalWidgetViews`}
          >
            {I18n.t("widgets_main.list.widget_views")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged("sortField", result)}
            sortField={props.sortField}
            field={`analytics.${timePeriod.key}.totalClicks`}
          >
            {I18n.t("widgets_main.list.clicks")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => handleValueChanged("sortField", result)}
            sortField={props.sortField}
            field={`analytics.${timePeriod.key}.ctr`}
          >
            {I18n.t("widgets_main.list.cpm")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel> {""}</MTableHeaderLabel>
        </MTableHeader>

        {loading && (
          <ListItem
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CfProgressBar />
          </ListItem>
        )}

        {!loading &&
          activeWidgets.map((item) => (
            <WidgetsListItem
              key={item._id}
              widget={item}
              timePeriod={timePeriod}
              selectedWebsite={selectedWebsite}
              handleClick={goToWidget}
              duplicateWidget={duplicateWidget}
              deleteWidget={handleDeleteWidget}
              restoreWidget={restoreWidget}
              openWidgetAnalyticsDialog={() => setWidgetAnalytics(item)}
            />
          ))}

        {!loading &&
          deletedWidgets.map((item) => (
            <WidgetsListItem
              key={item._id}
              widget={item}
              timePeriod={timePeriod}
              selectedWebsite={selectedWebsite}
              handleClick={goToWidget}
              deleteWidget={handleDeleteWidget}
              restoreWidget={restoreWidget}
              openWidgetAnalyticsDialog={() => setWidgetAnalytics(item)}
            />
          ))}
      </MTable>
      {widgetAnalytics !== null && (
        <WidgetAnalyticsDialog
          open={true}
          widget={widgetAnalytics}
          startDate={moment().subtract(timePeriod.value, "months").startOf("day").toDate()}
          handleClose={() => setWidgetAnalytics(null)}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsList);
