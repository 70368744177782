import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 21,
    height: 21,
    fill: theme.palette.text.primary,
  },
}));

type PropTypes = {
  className?: string;
  onClick?: () => void;
};

const LocateIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className, onClick } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 21 21"} onClick={onClick}>
      <path d="M10.5511 18.925C6.20908 18.925 2.67676 15.3927 2.67676 11.0506C2.67676 6.70859 6.20908 3.17627 10.5511 3.17627C14.8932 3.17627 18.4255 6.70859 18.4255 11.0506C18.4255 15.3927 14.8932 18.925 10.5511 18.925ZM10.5511 4.75115C7.07764 4.75115 4.25163 7.57716 4.25163 11.0506C4.25163 14.5241 7.07764 17.3502 10.5511 17.3502C14.0246 17.3502 16.8506 14.5241 16.8506 11.0506C16.8506 7.57716 14.0246 4.75115 10.5511 4.75115Z" />
      <path d="M10.5529 12.3123C11.2487 12.3123 11.8128 11.7482 11.8128 11.0524C11.8128 10.3566 11.2487 9.79248 10.5529 9.79248C9.85705 9.79248 9.29297 10.3566 9.29297 11.0524C9.29297 11.7482 9.85705 12.3123 10.5529 12.3123Z" />
      <path d="M10.5511 6.79902C10.1163 6.79902 9.76367 6.55014 9.76367 6.24319V1.05534C9.76367 0.748387 10.1163 0.499512 10.5511 0.499512C10.986 0.499512 11.3385 0.748387 11.3385 1.05534V6.24319C11.3385 6.55014 10.986 6.79902 10.5511 6.79902Z" />
      <path d="M10.5511 21.5002C10.1163 21.5002 9.76367 21.2513 9.76367 20.9444V15.7565C9.76367 15.4496 10.1163 15.2007 10.5511 15.2007C10.986 15.2007 11.3385 15.4496 11.3385 15.7565V20.9444C11.3385 21.2513 10.986 21.5002 10.5511 21.5002Z" />
      <path d="M5.74367 11.8381H0.555831C0.248875 11.8381 0 11.4855 0 11.0506C0 10.6158 0.248875 10.2632 0.555831 10.2632H5.74367C6.05063 10.2632 6.2995 10.6158 6.2995 11.0506C6.2995 11.4855 6.05063 11.8381 5.74367 11.8381Z" />
      <path d="M20.4448 11.8376H15.257C14.95 11.8376 14.7012 11.485 14.7012 11.0501C14.7012 10.6153 14.95 10.2627 15.257 10.2627H20.4448C20.7518 10.2627 21.0007 10.6153 21.0007 11.0501C21.0007 11.485 20.7518 11.8376 20.4448 11.8376Z" />
    </SvgIcon>
  );
};

export default LocateIcon;
