import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Button from "@material-ui/core/Button";
import { IAd, IAdPlacement } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: 50,
    display: "flex",
    flexDirection: "row",
  },
  paper: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 700,
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    padding: 25,
  },
  businessImage: {
    backgroundSize: "contain",
    borderRadius: "50%",
  },
  businessDetails: {
    marginLeft: 10,
  },
  businessName: {},
  sponsored: {},
  adText: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  adImage: {
    marginTop: 15,
    backgroundSize: "contain",
    display: "block",
  },
  footer: {
    backgroundColor: "#f8faff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 25,
    paddingRight: 25,
    height: 60,
  },
  adTitle: {},
  callToAction: {
    backgroundColor: "#e6edf7",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(16),
    textTransform: "capitalize",
  },
}));

type PropTypes = {
  ad: IAd;
  placement: IAdPlacement;
};

const ForumPostImagePreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { ad, placement } = props;

  return (
    <div className={classes.wrapper}>
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.topBar}>
          <img
            width={40}
            height={40}
            src={ad?.business?.profileImageFile?.cdnUrl || ad.business.profileImageFile?.url}
            className={classes.businessImage}
            alt={ad.business.profileImageFile?.alt}
          />
          <div className={classes.businessDetails}>
            <Typography className={classes.businessName} variant={"subtitle2"}>
              {I18n.t("ads.edit_ad.content.business_name")}
            </Typography>
            <Typography className={classes.sponsored} variant={"body2"}>
              {I18n.t("ads.edit_ad.preview.sponsored")}
            </Typography>
          </div>
        </div>
        <Typography className={classes.adText} variant={"body1"}>
          {ad.text}
        </Typography>
        <img
          width={Math.min(700, placement.imageFile?.width || 700)}
          height={Math.min(200, placement.imageFile?.height || 200)}
          src={placement.imageFile?.cdnUrl || placement.imageFile?.url}
          className={classes.adImage}
          alt={placement.imageFile?.alt}
        />
        <div className={classes.footer}>
          <Typography className={classes.adTitle} variant={"subtitle2"}>
            {ad.title}
          </Typography>
          {/* <CfButton */}
          {/*  text={ad.callToAction} */}
          {/*  customClass={classes.callToAction} */}
          {/* /> */}
          <Button className={classes.callToAction} onClick={(e) => console.log("")}>
            {ad.callToAction}
            <KeyboardArrowRight />
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default ForumPostImagePreview;
