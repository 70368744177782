import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import { AbilityContext } from "../../../../../casl/can";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    paddingRight: 10,
  },
  button: {
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
}));

type PropTypes = {
  handleDeleteClick: Function;
};

const RuleMoreOptionsButton = (props: PropTypes) => {
  const classes = useStyles(props);
  const { handleDeleteClick } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const ability = React.useContext<any>(AbilityContext);
  const menuItems = [];

  if (ability.can("editor", "role")) {
    menuItems.push({
      key: "DELETE",
      label: I18n.t("redirect_rules.table.delete"),
    });
  }

  if (menuItems.length === 0) {
    return null;
  }

  const handleToggle = (e) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>, selectedItem = null) => {
    event.stopPropagation();
    console.log("handleClose");
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    if (!selectedItem) {
      setOpen(false);
      return;
    }

    switch (selectedItem.key) {
      case "DELETE":
        handleDeleteClick();
        break;
      default:
        break;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.wrapper}>
      <IconButton
        disableRipple
        className={classes.button}
        disableTouchRipple
        disableFocusRipple
        aria-haspopup="true"
        ref={anchorRef}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ zIndex: 1, transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {menuItems.map((item) => (
                    <MenuItem key={item.key} onClick={(e) => handleClose(e, item)}>
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default RuleMoreOptionsButton;
