import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import numeral from "numeral";
import { IKeywordGroupMember, IKeywordHeadingClusterWithStrategy } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  metric: {
    fontSize: "14px",
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: "100%",
    width: "10%",
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "20px 30px",
    justifyContent: "space-between",
  },
  listItemFirstColumn: {
    width: "55%",
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  keyword: IKeywordGroupMember;
};

const KeywordDialogListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { keyword } = props;

  return (
    <ListItem className={classNames(classes.listRow)}>
      <div className={classes.listItemFirstColumn}>
        <span
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "pre",
          }}
        >
          {keyword.keyword}
        </span>
      </div>

      <div className={classes.metric}>
        <Typography>{numeral(keyword.clicks).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(keyword.impressions).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(keyword.volume).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(keyword.ctr).format("0.0%")}</Typography>
      </div>
    </ListItem>
  );
};

export default KeywordDialogListItem;
