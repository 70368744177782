import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  className?: string;
};

function EmbedIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 48 48"}>
      <path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h26q1.2 0 2.1.9.9.9.9 2.1v10.75l6.7-6.7q.35-.35.825-.175t.475.675v16.9q0 .5-.475.675-.475.175-.825-.175l-6.7-6.7V37q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h26V11H7v26Zm0 0V11v26Z" />
    </SvgIcon>
  );
}

export default EmbedIcon;
