import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import { ePostType, IWriterCommunityAnswer } from "../../../../reducers/constants/objectTypes";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import MTableCellDate from "../../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";

type PropTypes = {
  answer: IWriterCommunityAnswer;
};

const WriterPublishedCommunityPostItemRow = (props: PropTypes) => {
  const { answer } = props;
  const theme: any = useTheme();
  return (
    <MTableRow handleRowClick={() => {}}>
      <MTableCellFirst
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(14)}
        avatarType={ePostType.COMMUNITY_ANSWER as ePostType}
        cellText={answer.question.title}
      />
      <MTableCellDate date={answer.createdAt} />
      <MTableCellDate date={answer.publishedDate} />
      <MTableCellText text={answer.question.category?.name} />
    </MTableRow>
  );
};

export default WriterPublishedCommunityPostItemRow;
