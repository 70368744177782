import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import ColorPicker from "./ColorPicker";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
  },
  valueWrap: {
    display: "flex",
    alignItems: "center",
  },
  backToDefault: {
    color: theme.palette.text.hint,
    fontSize: 16,
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  rowTitle: string;
  value: string;
  defaultValue?: string;
  isValueChanged: boolean;

  handleChange: any;
  hideOpacityInput?: boolean;
  classes?: {
    colorPickerRoot?: string;
  };
};

const ColorPickerRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    rowTitle,
    value = null,
    defaultValue = "",
    isValueChanged,
    handleChange,
    classes: propClasses = {},
    hideOpacityInput,
  } = props;

  const handleBackToDefault = () => {
    handleChange(defaultValue);
  };

  return (
    <div className={classes.row}>
      <Typography variant={"body2"} className={ClassNames(classes.text, isValueChanged && classes.boldText)}>
        {rowTitle}
      </Typography>
      <div className={classes.valueWrap}>
        {isValueChanged && (
          <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
            <CloseIcon className={classes.backToDefault} onClick={handleBackToDefault} />
          </Tooltip>
        )}
        <ColorPicker
          borderRadius={4}
          color={value || defaultValue}
          handleChange={handleChange}
          top={"0px"}
          left={"-65px"}
          className={propClasses.colorPickerRoot}
          hideOpacityInput={hideOpacityInput}
        />
      </div>
    </div>
  );
};
export default ColorPickerRow;
