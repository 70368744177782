import React from "react";
import { makeStyles } from "@material-ui/styles";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import {
  IWidgetItem,
  IWidget,
  IWidgetField,
  IWidgetFieldActionPayload,
} from "../../../../../../../reducers/constants/objectTypes";
import WidgetField from "./WidgetField";

type PropTypes = {
  selectedWidgetItem: IWidgetItem;
  widget: IWidget;
  fields: IWidgetField[];
  selectField: (selectedField: string) => void;
  editField: (payload: IWidgetFieldActionPayload) => void;
  deleteField: (payload: IWidgetFieldActionPayload) => void;
  onItemFieldsReorder: (fields: IWidgetField[]) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

const WidgetItemFieldList = (props: PropTypes) => {
  const { fields, widget } = props;
  const { selectField, deleteField, editField, onItemFieldsReorder } = props;
  const classes = useStyles(props);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // If the item was not dropped in a valid droppable area, return early
    if (!destination) {
      return;
    }

    // Reorder the fields array based on the drag and drop result
    const newFields = Array.from(fields);
    const movedItem = newFields.splice(source.index, 1)[0];
    newFields.splice(destination.index, 0, movedItem);

    // Update the forcedPosition values of the fields
    newFields.forEach((field, index) => {
      if (field.settings.forcedPosition !== null && field.settings.forcedPosition !== index) {
        const initialIndex = field.settings.forcedPosition;
        const initialField = newFields[initialIndex];
        newFields[initialIndex] = field;
        newFields[index] = initialField;
      }
    });

    onItemFieldsReorder(newFields);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="widget-fields-list">
        {(provided) => (
          <div className={classes.root} {...provided.droppableProps} ref={provided.innerRef}>
            {fields?.map((field, index) => (
              <Draggable
                draggableId={`widget-field-${field._id ?? field.fieldId}`}
                key={field._id ?? field.fieldId}
                index={index}
                isDragDisabled={!field.settings.draggable || field.settings.forcedPosition === index}
              >
                {(draggableProvided, snapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    <WidgetField
                      field={field}
                      widget={widget}
                      key={field._id ?? field.fieldId}
                      isDraggable={field.settings.draggable}
                      isDragging={snapshot.isDragging}
                      onClick={() => selectField(field.fieldId)}
                      deleteField={deleteField}
                      editField={editField}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default WidgetItemFieldList;
