import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { ChromePicker } from "react-color";
import { ClickAwayListener, Tooltip } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import MTextField from "../../../components/MTextField";

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    height: 20,
    alignSelf: "unset",
    marginRight: (props: PropTypes) => (props.maximize ? 5 : 0),
    marginLeft: (props: PropTypes) => (props.maximize ? 5 : 0),
    backgroundColor: theme.palette.text.secondary,
  },
  colorWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: 32,
    padding: (props: PropTypes) => (props.maximize ? "0 5px" : 0),
    width: (props: PropTypes) => (props.maximize ? 180 : 73),
    //border: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: (props: PropTypes) => props.borderRadius || 3,
  },
  colorRectangle: {
    width: 16,
    height: 16,
    cursor: "pointer",
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: "solid",
  },
  icon: {
    fontSize: 21,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  defaultIcon: {
    color: theme.palette.primary.main,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
    maxWidth: 36,
  },
  hex: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
    marginLeft: 10,
    maxWidth: 60,
  },
  buttonAndColorPickerWrap: {
    position: "relative",
    border: `${theme.palette.divider} 1px `,
    borderRadius: 2,
  },
  colorPickerWrap: {
    top: (props: PropTypes) => props.top || 0,
    left: (props: PropTypes) => props.left || 0,
    zIndex: 1,
    position: "absolute",
  },
}));

type PropTypes = {
  color: string;
  defaultColor: string;
  top?: string;
  left?: string;
  borderRadius?: number | string;
  maximize?: boolean;
  handleChange: Function;
};

const ColorPickerBaseComponent = (props: PropTypes) => {
  const classes = useStyles(props);
  const { color, handleChange, defaultColor, maximize = false } = props;
  const [alpha, setAlpha] = React.useState<string>("100%");
  const [open, setOpen] = React.useState(false);
  const [hex, setHex] = React.useState<string>("");
  const rgbaToHex = (rgbaColor: string): string => {
    if (/^rgb/.test(rgbaColor)) {
      const rgba = rgbaColor.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

      // rgb to hex

      let hexa = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
        .toString(16)
        .slice(1)}`;
      if (rgba[3]) {
        setAlpha(`${Math.floor(rgba[3] * 100)}%`);
      }
      // added alpha param if exists
      if (rgba[4]) {
        const calculatedAlpha = Math.round(0o1 * 255);
        const hexAlpha = (calculatedAlpha + 0x10000).toString(16).substr(-2).toUpperCase();
        hexa += hexAlpha;
      }

      return hexa;
    }
    return rgbaColor;
  };
  React.useEffect(() => {
    setHex(rgbaToHex(color));
  }, [color]);

  const handleClickAway = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  const hex2rgba = (hexa: string, opacity = 1): string => {
    if (!hexa.includes("#")) {
      hexa = `#${hexa}`;
    }
    if (opacity > 1) {
      opacity /= 100;
    }
    if (opacity > 1) {
      opacity = 1;
    }
    let c: string[] = hexa.substring(1).split("");

    if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hexa)) {
      return undefined;
    }

    switch (c.length) {
      case 3:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], "ff"];
        break;
      case 4:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
        break;
      case 6:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], "ff"];
        break;
      case 8:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
        break;
      default:
        break;
    }

    c = c.map((char) => parseInt(char, 16).toString());
    c[3] = opacity.toString();
    return c[3] === "1" ? `rgb( ${c[0]}, ${c[1]}, ${c[2]})` : `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`;
  };
  const getAlpha = (v: string) => {
    const alphaValue = +alpha.replace("%", "");
    return !alphaValue || alphaValue < 1 || alphaValue > 100 ? 100 : alphaValue;
  };
  const handleColorInput = () => {
    const colorStr = hex2rgba(hex, +`${alpha.replace("%", "")}`);
    setHex(rgbaToHex(colorStr || color));
    setAlpha(`${Math.floor(getAlpha(alpha))}%`);
    handleChange(colorStr || color);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.buttonAndColorPickerWrap}>
        <div className={classes.colorWrapper}>
          <Tooltip title={I18n.t("rich_text_editor.reset_default_color")}>
            <LanguageIcon
              onClick={() => {
                if (defaultColor !== color) {
                  handleChange(defaultColor);
                }
              }}
              className={ClassNames(classes.icon, defaultColor && defaultColor === color && classes.defaultIcon)}
            />
          </Tooltip>
          <Divider className={classes.divider} orientation={"vertical"} />
          {/* <Button  onClick={handleClick}> */}
          <div onClick={handleClick} className={classes.colorRectangle} style={{ backgroundColor: color }} />
          {/* </Button> */}
          {maximize && (
            <MTextField
              onBlur={() => handleColorInput()}
              onChange={(v) => setHex(v)}
              customClass={classes.hex}
              value={hex}
            />
          )}
          {maximize && <Divider className={classes.divider} orientation={"vertical"} />}
          {maximize && (
            <MTextField
              onBlur={() => handleColorInput()}
              onChange={(v) => setAlpha(v)}
              customClass={classes.text}
              value={alpha}
            />
          )}
        </div>

        {open ? (
          <div className={classes.colorPickerWrap}>
            <ChromePicker
              color={color}
              onChangeComplete={(col) => {
                let colorStr = col.hex;
                if (col.rgb.a !== 1) {
                  colorStr = `rgba(${col.rgb.r}, ${col.rgb.g}, ${col.rgb.b}, ${col.rgb.a})`;
                }
                setAlpha(`${Math.floor(col.rgb.a * 100)}%`);
                handleChange(colorStr);
              }}
            />
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default ColorPickerBaseComponent;
