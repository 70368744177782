import { EDIT_ARTICLE } from "./constants/actionTypes";

const initialState = {
  postLoaded: false,
  post: null,
  postRichText: null,
  postType: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_ARTICLE.CLEANUP:
      return initialState;
    case EDIT_ARTICLE.ARTICLE_LOADED:
      return {
        ...state,
        postLoaded: true,
        post: action.post,
        postRichText: action.post.richText,
        postType: action.postType,
      };
    case EDIT_ARTICLE.ARTICLE_CHANGED:
      return {
        ...state,
        post: action.post,
      };
    case EDIT_ARTICLE.ARTICLE_TEXT_CHANGED:
      return {
        ...state,
        postRichText: action.richText,
      };
    default:
      return state;
  }
};
