import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import ClearIcon from "@material-ui/icons/Clear";
import numeral from "numeral";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import PipeSeparatorIcon from "../../../icons/PipeSeparatorIcon";
import CfProgressBar from "../../CfProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: "0 -10px",
    marginTop: "-8px",
    padding: "0 30px",
    paddingBottom: "12px",
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginLeft: "auto",
  },
  selectedWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    height: 40,
  },
  selected: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    cursor: "pointer",
  },
  selectedText: {
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: theme.typography.pxToRem(14),
  },
  clearIcon: {
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  pipeIcon: {
    alignSelf: "center",
    fill: theme.palette.divider,
    width: 3,
    height: 30,
  },
  selectActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  selectAction: {
    fontWeight: theme.typography.fontWeightMedium as any,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  circleDivider: {
    margin: "0px 10px",
    width: 4,
    height: 4,
    borderRadius: "50%",
    backgroundColor: theme.palette.divider,
  },
}));

type PropTypes = {
  selectedRows: number;
  onClearSelectedRows: () => void;
  selectedRowActions: React.ReactNode[] | { onClick: Function; color?: string; text: string; tooltip?: string }[];
};

const MTableToolbarSelectedRowsActions = (props: PropTypes) => {
  const classes = useStyles();
  const { selectedRows = -1, selectedRowActions = [], onClearSelectedRows } = props;
  const theme = useTheme();
  return (
    <div className={classes.selectedWrapper}>
      <div
        className={classes.selected}
        onClick={(e) => {
          e.stopPropagation();
          onClearSelectedRows();
        }}
      >
        <Typography variant={"body1"} className={classes.selectedText} color={"textPrimary"}>
          {I18n.t("common.selected", { count: numeral(selectedRows).format("0,0a") })}
        </Typography>
        <ClearIcon className={classes.clearIcon} />
      </div>
      {selectedRowActions?.length > 0 && <PipeSeparatorIcon className={classes.pipeIcon} />}
      {selectedRowActions?.length > 0 && (
        <div className={classes.selectActions}>
          {selectedRowActions?.map((action: any, i) => {
            if (React.isValidElement(action)) {
              return action;
            }
            return (
              <div key={`tool_bar_selected_action${i + 1}`} className={classes.selectAction}>
                {i !== 0 && <div className={classes.circleDivider} />}
                <Tooltip title={action.tooltip || action.text}>
                  <Link
                    style={{
                      color: action.color || theme.palette.text.primary,
                      textDecoration: "none",
                      cursor: "pointer",
                      fontWeight: theme.typography.fontWeightRegular as any,
                      fontSize: theme.typography.pxToRem(14),
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      action.onClick();
                    }}
                  >
                    {action.text}
                  </Link>
                </Tooltip>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MTableToolbarSelectedRowsActions;
