import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";
import FontSizeCSS from "../CssComponents/FontSizeCSS";
import FontWeightCSS from "../CssComponents/FontWeightElement";
import FontColorCSS from "../CssComponents/FontColorCSS";
import PaddingCSS from "../CssComponents/PaddingCSS";
import MarginCSS from "../CssComponents/MarginCSS";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import StylePanelSubSection from "../components/StylePanelSubSection";
import { typographyDefaultCss } from "../../../containers/WebsiteConfigurations/WebsiteEditor/consts/CssConsts";
import IconPickerElement from "./IconPickerElement";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginTop: 30,
    marginBottom: 10,
  },
}));

type PropTypes = {
  divider?: boolean;
  sectionTitle: string;
  variant: Variant;
  elementDefaultCss?: any;
  list: {
    show: boolean | null;
    css: any;
    icon?: {
      key: string;
      color: string;
    };
  };
  websiteTheme: IWebsiteThemeProps;

  handleChange: any;
};

const ListStyleElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { divider = false, sectionTitle, websiteTheme, variant, elementDefaultCss = {}, list, handleChange } = props;

  const defaultCss = React.useMemo(
    () => (websiteTheme ? typographyDefaultCss(websiteTheme, variant, elementDefaultCss) : {}),
    [websiteTheme, variant, elementDefaultCss]
  );

  const handleShowChange = (show: boolean) => {
    handleChange({ ...list, show });
  };

  return (
    <StylePanelSubSection
      divider={divider}
      sectionTitle={sectionTitle}
      show={list.show}
      handleShowChange={list.show !== null ? handleShowChange : null}
    >
      <Typography className={classes.title}>
        {I18n.t("rich_text_editor.product_cta.bullets_list_properties")}
      </Typography>
      <PaddingCSS
        value={list.css?.listPadding || defaultCss?.listPadding}
        defaultValue={defaultCss?.listPadding}
        handleChange={(listPadding) => handleChange({ ...list, css: { ...list.css, listPadding } })}
      />
      <MarginCSS
        value={list.css?.listMargin || defaultCss?.listMargin}
        defaultValue={defaultCss?.listMargin}
        handleChange={(listMargin) => handleChange({ ...list, css: { ...list.css, listMargin } })}
      />
      <Typography className={classes.title}>{I18n.t("rich_text_editor.product_cta.bullets_properties")}</Typography>
      <FontSizeCSS
        value={list.css?.fontSize || defaultCss.fontSize}
        defaultValue={defaultCss.fontSize}
        handleChange={(fontSize) => handleChange({ ...list, css: { ...list.css, fontSize } })}
      />
      <FontWeightCSS
        value={list.css?.fontWeight || defaultCss.fontWeight}
        defaultValue={defaultCss.fontWeight}
        handleChange={(fontWeight) => handleChange({ ...list, css: { ...list.css, fontWeight } })}
      />
      <FontColorCSS
        title={I18n.t("rich_text_editor.text_color")}
        value={list.css?.color || defaultCss.color || websiteTheme.palette.text.primary}
        defaultValue={defaultCss.color}
        isValueChanged={list.css?.color && list.css?.color !== defaultCss.color}
        handleChange={(color) => handleChange({ ...list, css: { ...list.css, color } })}
      />
      <PaddingCSS
        value={list.css?.padding || defaultCss?.padding}
        defaultValue={defaultCss?.padding}
        handleChange={(padding) => handleChange({ ...list, css: { ...list.css, padding } })}
      />
      <MarginCSS
        value={list.css?.margin || defaultCss?.margin}
        defaultValue={defaultCss?.margin}
        handleChange={(margin) => handleChange({ ...list, css: { ...list.css, margin } })}
      />

      <Typography className={classes.title}>{I18n.t("rich_text_editor.product_cta.bullets_icon")}</Typography>
      <IconPickerElement
        icon={list.icon?.key}
        color={list.icon.color || websiteTheme.palette.primary.main}
        onIconChange={(icon) => {
          handleChange({ ...list, icon: { ...list.icon, key: icon.key } });
        }}
      />
      <FontColorCSS
        title={I18n.t("rich_text_editor.product_cta.select_icon_color")}
        value={list.icon.color || websiteTheme.palette.primary.main}
        defaultValue={websiteTheme.palette.primary.main}
        isValueChanged={false}
        handleChange={(color) => {
          handleChange({ ...list, icon: { ...list.icon, color } });
        }}
      />
    </StylePanelSubSection>
  );
};

export default ListStyleElement;
