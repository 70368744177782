import { filter } from "lodash";
import { MANAGE_CONTENT, MANAGE_WEBSITES } from "./constants/actionTypes";

const initialState = {
  // Tags
  tagsLoaded: false,
  tags: [],
  selectedTag: null,
  tagsSelectedLocale: null,

  //  Products
  productsLoaded: false,
  products: [],
  selectedProductId: null,
  selectedProduct: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_CONTENT.ON_TAGS_LOADED:
      return {
        ...state,
        tagsLoaded: true,
        tags: action.payload.tags,
        selectedTag: action.payload.tags.length > 0 ? action.payload.tags[0] : null,
      };
    case MANAGE_CONTENT.ON_TAG_CREATED:
      return {
        ...state,
        selectedTag: action.payload.tag,
        tags: [action.payload.tag, ...(state.tags || [])],
      };
    case MANAGE_CONTENT.ON_TAG_SELECTED:
      return {
        ...state,
        selectedTag: action.tag,
      };
    case MANAGE_CONTENT.ON_TAG_CHANGED:
      return {
        ...state,
        selectedTag: action.tag,
      };
    case MANAGE_CONTENT.ON_TAG_UPDATED:
      return {
        ...state,
        selectedTag: action.tag,
        tags: state.tags.map((tag) => {
          if (tag._id === action.tag._id) {
            return { ...tag, ...action.tag };
          }
          return tag;
        }),
      };
    case MANAGE_CONTENT.ON_TAGS_LOCALE_CHANGED:
      return {
        ...state,
        tagsSelectedLocale: action.locale,
      };
    case MANAGE_CONTENT.ON_TAG_DELETED: {
      const tags = filter(state.tags, (t) => t._id !== action.tag._id);
      return {
        ...state,
        tags,
        selectedTag: tags.length > 0 ? tags[0] : null,
      };
    }
    case MANAGE_CONTENT.ON_PRODUCTS_LOADED:
      return {
        ...state,
        productsLoaded: true,
        products: action.payload.items,
        selectedProductId: state.selectedProductId
          ? state.selectedProductId
          : action.payload.items.length > 0
          ? action.payload.items[0]._id
          : null,
      };
    case MANAGE_CONTENT.ON_PRODUCT_CREATED:
      return {
        ...state,
        products: [...state.products, action.product].sort((a, b) => a.name.localeCompare(b.name)),
        selectedProductId: action.product._id,
      };
    case MANAGE_CONTENT.ON_PRODUCT_SELECTED:
      return {
        ...state,
        selectedProductId: action.product._id,
      };
    case MANAGE_CONTENT.ON_PRODUCT_CHANGED:
      return {
        ...state,
        selectedProduct: action.product,
      };
    case MANAGE_CONTENT.ON_PRODUCT_UPDATED:
      return {
        ...state,
        selectedProduct: action.product,
        products: state.products.map((product) => {
          if (product._id === action.product._id) {
            return { ...product, ...action.product };
          }
          return product;
        }),
      };
    case MANAGE_CONTENT.ON_PRODUCT_DELETED: {
      const products = filter(state.products, (t) => t._id !== action.product._id);
      return {
        ...state,
        products,
        selectedProduct: products.length > 0 ? products[0] : null,
      };
    }
    case MANAGE_CONTENT.ON_PRODUCT_CLEANUP:
      return {
        ...state,
        productsLoaded: false,
        products: [],
        selectedProductId: null,
        selectedProduct: null,
      };
    default:
      return state;
  }
};
