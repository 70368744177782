import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 16,
    height: 16,
  },
}));

type PropTypes = {
  className?: string;
  onClick?: () => void;
};

function LaunchIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className, onClick } = props;

  return (
    <SvgIcon className={className} viewBox={"0 0 19 25"} onClick={onClick}>
      <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
    </SvgIcon>
  );
}

export default LaunchIcon;
