import heading from "../img/heading.png";
import like from "../img/widgets/icons/like.png";
import smiley from "../img/widgets/icons/smiley.png";
import numbers from "../img/widgets/icons/numbers.png";
import star_scale from "../img/widgets/icons/star_scale.png";
import consent from "../img/widgets/icons/consent.png";
import dropdown from "../img/widgets/icons/dropdown.png";
import checkbox from "../img/widgets/icons/checkbox.png";
import label from "../img/widgets/icons/label.png";
import paragraph from "../img/widgets/icons/paragraph.png";
import url from "../img/widgets/icons/url.png";
import hidden from "../img/widgets/icons/hidden.png";
import file from "../img/widgets/icons/file.png";
import date from "../img/widgets/icons/date.png";
import phone from "../img/widgets/icons/phone.png";
import email from "../img/widgets/icons/email.png";
import short_text from "../img/widgets/icons/short_text.png";
import long_text from "../img/widgets/icons/long_text.png";
import action from "../img/widgets/icons/action.png";
import cons from "../img/widgets/icons/cons.png";
import button from "../img/widgets/icons/button.png";
import image from "../img/widgets/icons/image.png";
import coupon from "../img/widgets/icons/coupon.png";
import link from "../img/widgets/icons/link.png";
import form from "../img/widgets/icons/form.png";
import inline_html from "../img/widgets/icons/inline_html.png";
import { eVideoProjectState, IFunnelBreakdownColumn } from "../reducers/constants/objectTypes";

export const REPORT_CACHE_KEY_NAME = "cacheReport";

export const ANALYTICS_DASHBOARD_METRICS_OPTIONS = [
  {
    value: "all",
    label: "All metrics",
  },
  {
    value: "impressions",
    label: "Impressions",
  },
  {
    label: "Clicks",
    value: "clicks",
  },
  {
    label: "CTR",
    value: "ctr",
  },
  {
    label: "Position",
    value: "position",
  },
];
export const ACTION_REPORTS_OPTIONS = [
  {
    value: "cta-clicks",
    label: "CTA Clicks",
  },
  {
    value: "internal-link-click",
    label: "Int. link clicks",
  },
  {
    value: "outbound-link-click",
    label: "Ext. link clicks",
  },
];

export const ANALYTICS_DISPLAY_MODES = [
  {
    value: "graph",
    label: "Graph",
  },
  {
    value: "table",
    label: "Table",
  },
];
export const FUNNEL_FILTER_CONDITIONS = [
  {
    value: "is",
    label: "Is",
  },
  {
    value: "isNot",
    label: "Is not",
  },
  {
    value: "contains",
    label: "Contains",
  },
  {
    value: "doesNotContain",
    label: "Does not contain",
  },
  {
    value: "isSet",
    label: "Is set",
  },
  {
    value: "isNotSet",
    label: "Is not set",
  },
];

export const FUNNEL_BREAK_DOWN_OPTIONS: IFunnelBreakdownColumn[] = [
  {
    label: "Event name",
    value: {
      field: "name",
      table: "events",
    },
  },
  {
    label: "Current URL",
    value: {
      field: "url",
      table: "events",
    },
  },
  {
    label: "Referrer",
    value: {
      field: "referrer",
      table: "events",
    },
  },
  {
    label: "Initial Referrer",
    value: {
      field: "referrer",
      table: "devices",
    },
  },
  {
    label: "Event Destination",
    value: {
      field: "destination",
      table: "events",
    },
  },
  {
    label: "Browser",
    value: {
      field: "browser",
      table: "sessions",
    },
  },
  {
    label: "Country",
    value: {
      field: "country",
      table: "sessions",
    },
  },
  {
    label: "City",
    value: {
      field: "city",
      table: "sessions",
    },
  },
  {
    label: "Device Type",
    value: {
      field: "device_type",
      table: "devices",
    },
  },
  {
    label: "User Agent",
    value: {
      field: "user_agent",
      table: "devices",
    },
  },
  {
    label: "OS",
    value: {
      field: "operating_system",
      table: "devices",
    },
  },
  {
    label: "Manufacturer",
    value: {
      field: "manufacturer",
      table: "devices",
    },
  },
  {
    label: "Landing Page",
    value: {
      field: "first_page",
      table: "devices",
    },
  },
].sort((a, b) => a.label.localeCompare(b.label));

export const FUNNEL_FILTER_COLUMNS: IFunnelBreakdownColumn[] = [
  {
    label: "Device Type",
    value: {
      field: "device_type",
      table: "devices",
      eventTable: "d",
    },
  },
  {
    label: "User Agent",
    value: {
      field: "user_agent",
      table: "devices",
      eventTable: "d",
    },
  },
  {
    label: "Manufacturer",
    value: {
      field: "manufacturer",
      table: "devices",
      eventTable: "d",
    },
  },
  {
    label: "Operating System",
    value: {
      field: "operating_system",
      table: "devices",
      eventTable: "d",
    },
  },
  {
    label: "Landing Page",
    value: {
      field: "first_page",
      table: "devices",
      eventTable: "d",
    },
  },
  {
    label: "Browser",
    value: {
      field: "browser",
      table: "sessions",
      eventTable: "s",
    },
  },
  {
    label: "Browser Language",
    value: {
      field: "browser_language",
      table: "sessions",
      eventTable: "s",
    },
  },
  {
    label: "Exit page",
    value: {
      field: "exit_page",
      table: "sessions",
      eventTable: "s",
    },
  },
  {
    label: "Referrer",
    value: {
      field: "referrer",
      table: "sessions",
      eventTable: "s",
    },
  },
  {
    label: "Initial Referrer",
    value: {
      field: "referrer",
      table: "devices",
      eventTable: "d",
    },
  },
  {
    value: {
      field: "country",
      table: "sessions",
      eventTable: "s",
    },
    label: "Country",
  },
  {
    value: {
      field: "city",
      table: "sessions",
      eventTable: "s",
    },
    label: "City",
  },
  {
    label: "URL",
    value: {
      field: "url",
      table: "events",
      eventTable: "e",
    },
  },
  {
    label: "Destination",
    value: {
      field: "destination",
      eventTable: "e",
      table: "events",
    },
  },
].sort((a, b) => a.label.localeCompare(b.label));

export const DEFAULT_FUNNEL_FILTER_COLUMN: IFunnelBreakdownColumn = FUNNEL_FILTER_COLUMNS.find(
  (f) => f.label === "URL"
);

export const EVENT_FILTER_COLUMNS: IFunnelBreakdownColumn[] = [
  {
    label: "Device Type",
    value: {
      field: "device_type",
      table: "d",
    },
  },
  {
    label: "User Agent",
    value: {
      field: "user_agent",
      table: "d",
    },
  },
  {
    label: "Manufacturer",
    value: {
      field: "manufacturer",
      table: "d",
    },
  },
  {
    label: "Operating System",
    value: {
      field: "operating_system",
      table: "d",
    },
  },
  {
    label: "Landing Page",
    value: {
      field: "first_page",
      table: "d",
    },
  },
  {
    label: "Browser",
    value: {
      field: "browser",
      table: "s",
    },
  },
  {
    label: "Browser Language",
    value: {
      field: "browser_language",
      table: "s",
    },
  },
  {
    label: "Exit page",
    value: {
      field: "exit_page",
      table: "s",
    },
  },
  {
    label: "Referrer",
    value: {
      field: "referrer",
      table: "s",
    },
  },
  {
    value: {
      field: "country",
      table: "s",
    },
    label: "Country",
  },
  {
    value: {
      field: "city",
      table: "s",
    },
    label: "City",
  },
  {
    label: "URL",
    value: {
      field: "url",
      table: "e",
    },
  },
  {
    label: "Destination",
    value: {
      field: "destination",
      table: "e",
    },
  },
];

export const allCurrencies = [
  {
    symbol: "Lek",
    name: "Lek",
    code: "ALL",
  },
  {
    symbol: "؋",
    name: "Afghani",
    code: "AFN",
  },
  {
    symbol: "$",
    name: "Peso",
    code: "ARS",
  },
  {
    symbol: "ƒ",
    name: "Guilder",
    code: "AWG",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "AUD",
  },
  {
    symbol: "ман",
    name: "Manat",
    code: "AZN",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "BSD",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "BBD",
  },
  {
    symbol: "p.",
    name: "Ruble",
    code: "BYR",
  },
  {
    symbol: "BZ$",
    name: "Dollar",
    code: "BZD",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "BMD",
  },
  {
    symbol: "$b",
    name: "Boliviano",
    code: "BOB",
  },
  {
    symbol: "KM",
    name: "Convertible Marka",
    code: "BAM",
  },
  {
    symbol: "P",
    name: "Pula",
    code: "BWP",
  },
  {
    symbol: "лв",
    name: "Lev",
    code: "BGN",
  },
  {
    symbol: "R$",
    name: "Real",
    code: "BRL",
  },
  {
    symbol: "$",
    name: "Darussalam Dollar",
    code: "BND",
  },
  {
    symbol: "៛",
    name: "Riel",
    code: "KHR",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "CAD",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "KYD",
  },
  {
    symbol: "$",
    name: "Peso",
    code: "CLP",
  },
  {
    symbol: "¥",
    name: "Yuan Renminbi",
    code: "CNY",
  },
  {
    symbol: "$",
    name: "Peso",
    code: "COP",
  },
  {
    symbol: "₡",
    name: "Colon",
    code: "CRC",
  },
  {
    symbol: "kn",
    name: "Kuna",
    code: "HRK",
  },
  {
    symbol: "₱",
    name: "Peso",
    code: "CUP",
  },
  {
    symbol: "Kč",
    name: "Koruna",
    code: "CZK",
  },
  {
    symbol: "kr",
    name: "Krone",
    code: "DKK",
  },
  {
    symbol: "RD$",
    name: "Peso",
    code: "DOP",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "XCD",
  },
  {
    symbol: "£",
    name: "Pound",
    code: "EGP",
  },
  {
    symbol: "$",
    name: "Colon",
    code: "SVC",
  },
  {
    symbol: "kr",
    name: "Kroon",
    code: "EEK",
  },
  {
    symbol: "€",
    name: "Euro",
    code: "EUR",
  },
  {
    symbol: "£",
    name: "Pound",
    code: "FKP",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "FJD",
  },
  {
    symbol: "¢",
    name: "Cedis",
    code: "GHC",
  },
  {
    symbol: "£",
    name: "Pound",
    code: "GIP",
  },
  {
    symbol: "Q",
    name: "Quetzal",
    code: "GTQ",
  },
  {
    symbol: "£",
    name: "Pound",
    code: "GGP",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "GYD",
  },
  {
    symbol: "L",
    name: "Lempira",
    code: "HNL",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "HKD",
  },
  {
    symbol: "Ft",
    name: "Forint",
    code: "HUF",
  },
  {
    symbol: "kr",
    name: "Krona",
    code: "ISK",
  },
  {
    symbol: "₹",
    name: "Rupee",
    code: "INR",
  },
  {
    symbol: "Rp",
    name: "Rupiah",
    code: "IDR",
  },
  {
    symbol: "﷼",
    name: "Rial",
    code: "IRR",
  },
  {
    symbol: "£",
    name: "Pound",
    code: "IMP",
  },
  {
    symbol: "₪",
    name: "Shekel",
    code: "ILS",
  },
  {
    symbol: "J$",
    name: "Dollar",
    code: "JMD",
  },
  {
    symbol: "¥",
    name: "Yen",
    code: "JPY",
  },
  {
    symbol: "£",
    name: "Pound",
    code: "JEP",
  },
  {
    symbol: "лв",
    name: "Tenge",
    code: "KZT",
  },
  {
    symbol: "₩",
    name: "Won",
    code: "KPW",
  },
  {
    symbol: "₩",
    name: "Won",
    code: "KRW",
  },
  {
    symbol: "лв",
    name: "Som",
    code: "KGS",
  },
  {
    symbol: "₭",
    name: "Kip",
    code: "LAK",
  },
  {
    symbol: "Ls",
    name: "Lat",
    code: "LVL",
  },
  {
    symbol: "£",
    name: "Pound",
    code: "LBP",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "LRD",
  },
  {
    symbol: "Lt",
    name: "Litas",
    code: "LTL",
  },
  {
    symbol: "ден",
    name: "Denar",
    code: "MKD",
  },
  {
    symbol: "RM",
    name: "Ringgit",
    code: "MYR",
  },
  {
    symbol: "₨",
    name: "Rupee",
    code: "MUR",
  },
  {
    symbol: "$",
    name: "Peso",
    code: "MXN",
  },
  {
    symbol: "₮",
    name: "Tughrik",
    code: "MNT",
  },
  {
    symbol: "MT",
    name: "Metical",
    code: "MZN",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "NAD",
  },
  {
    symbol: "₨",
    name: "Rupee",
    code: "NPR",
  },
  {
    symbol: "ƒ",
    name: "Antilles Guilder",
    code: "ANG",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "NZD",
  },
  {
    symbol: "C$",
    name: "Cordoba",
    code: "NIO",
  },
  {
    symbol: "₦",
    name: "Naira",
    code: "NGN",
  },
  {
    symbol: "kr",
    name: "Krone",
    code: "NOK",
  },
  {
    symbol: "﷼",
    name: "Rial",
    code: "OMR",
  },
  {
    symbol: "₨",
    name: "Rupee",
    code: "PKR",
  },
  {
    symbol: "B/.",
    name: "Balboa",
    code: "PAB",
  },
  {
    symbol: "Gs",
    name: "Guarani",
    code: "PYG",
  },
  {
    symbol: "S/.",
    name: "Nuevo Sol",
    code: "PEN",
  },
  {
    symbol: "₱",
    name: "Peso",
    code: "PHP",
  },
  {
    symbol: "zł",
    name: "Zloty",
    code: "PLN",
  },
  {
    symbol: "﷼",
    name: "Riyal",
    code: "QAR",
  },
  {
    symbol: "lei",
    name: "New Leu",
    code: "RON",
  },
  {
    symbol: "руб",
    name: "Ruble",
    code: "RUB",
  },
  {
    symbol: "£",
    name: "Pound",
    code: "SHP",
  },
  {
    symbol: "﷼",
    name: "Riyal",
    code: "SAR",
  },
  {
    symbol: "Дин.",
    name: "Dinar",
    code: "RSD",
  },
  {
    symbol: "₨",
    name: "Rupee",
    code: "SCR",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "SGD",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "SBD",
  },
  {
    symbol: "S",
    name: "Shilling",
    code: "SOS",
  },
  {
    symbol: "S",
    name: "Rand",
    code: "ZAR",
  },
  {
    symbol: "₨",
    name: "Rupee",
    code: "LKR",
  },
  {
    symbol: "kr",
    name: "Krona",
    code: "SEK",
  },
  {
    symbol: "CHF",
    name: "Franc",
    code: "CHF",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "SRD",
  },
  {
    symbol: "£",
    name: "Pound",
    code: "SYP",
  },
  {
    symbol: "NT$",
    name: "New Dollar",
    code: "TWD",
  },
  {
    symbol: "฿",
    name: "Baht",
    code: "THB",
  },
  {
    symbol: "TT$",
    name: "Dollar",
    code: "TTD",
  },
  {
    symbol: "₤",
    name: "Lira",
    code: "TRL",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "TVD",
  },
  {
    symbol: "₴",
    name: "Hryvna",
    code: "UAH",
  },
  {
    symbol: "£",
    name: "Pound",
    code: "GBP",
  },
  {
    symbol: "$",
    name: "Dollar",
    code: "USD",
  },
  {
    symbol: "$U",
    name: "Peso",
    code: "UYU",
  },
  {
    symbol: "лв",
    name: "Som",
    code: "UZS",
  },
  {
    symbol: "Bs",
    name: "Bolivar Fuerte",
    code: "VEF",
  },
  {
    symbol: "₫",
    name: "Dong",
    code: "VND",
  },
  {
    symbol: "﷼",
    name: "Rial",
    code: "YER",
  },
  {
    symbol: "Z$",
    name: "Dollar",
    code: "ZWD",
  },
];

export const colors = {
  pageEditorBackgroundColor: "#F9FBFD",
  promptBackgroundColor: "rgba(67, 83, 255, 0.20)",
  disabledSendIconColor: "#D4DAFD",
  widgetOnHoverBackgroundColor: "#D7E2F7",
};

// WIDGETS CONSTANTS
export const widgetFieldIcons = {
  short_text: short_text,
  long_text: long_text,
  email: email,
  url: url,
  phone: phone,
  date: date,
  file: file,
  hidden: hidden,
  heading: heading,
  paragraph: paragraph,
  label: label,
  checkbox: checkbox,
  dropdown: dropdown,
  consent: consent,
  star_scale: star_scale,
  numbers: numbers,
  smiley: smiley,
  like: like,
  score: numbers,
  medias: email,
  call_to_action: action,
  expandable_text_section: dropdown,
  pros_cons: cons,
  button: button,
  bulleted_list: cons,
  title: short_text,
  product_number: numbers,
  product_name: short_text,
  rating: star_scale,
  product_number_of_reviews: numbers,
  logo: email,
  icon: email,
  short_description: short_text,
  text: short_text,
  image: image,
  coupon: coupon,
  link: link,
  form: form,
  price: numbers,
  inline_html: inline_html,
  iframe: short_text,
  related_articles: cons,
  hubspot_form: inline_html,
};

export const widgetAddableFields = ["score", "medias", "call_to_action", "expandable_text_section", "pros_cons"];

export const KEYWORD_CLASS_LEVELS = ["1st", "2nd", "3rd", "amplifier", "informational"];

export const AIRFLOW_DAG_IDS = {
  keywords_groups: "Keyword Grouping",
  strategy_builder: "Keyword Strategy",
  outline_generator: "Outline Generator",
  video_briefs_generator: "Video Interview Generator",
};

export const CONVERSION_POTENTIAL_FUNNEL_STAGE = {
  bottom: "Bottom",
  "near bottom": "Near bottom",
  lower: "Lower",
  middle: "Middle",
  upper: "Upper",
  top: "Top",
  None: "None",
};

export const STRATEGY_POST_TYPES = {
  targeted: "Targeted",
  community: "Community",
  article: "Article",
  top10: "Top 10",
  review: "Review",
  comparison: "Comparison",
  list_article: "List Article",
  short_video: "Short Video",
  long_video: "Long Video",
};
export const AUTOOUTLINE_STATES = ["NEW", "DRAFT", "APPROVED", "REJECTED"];

export const CUSTOM_GRAPH_TYPES = [
  {
    value: "table",
    label: "Table",
  },
  {
    value: "pivot",
    label: "Pivot",
  },
];

export const videoProjectStates = [
  eVideoProjectState.trim,
  eVideoProjectState.brief,
  eVideoProjectState.edit,
  eVideoProjectState.firstCheck,
  eVideoProjectState.cards,
  eVideoProjectState.captions,
  eVideoProjectState.music,
  eVideoProjectState.secondCheck,
  eVideoProjectState.review,
  eVideoProjectState.done,
  eVideoProjectState.deleted,
];
