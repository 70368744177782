import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ArrowRightRoundedIcon from "../../../../../../../icons/ArrowRightRoundedIcon";
import GenericOptionContainer from "../GenericOptionContainer";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const color = palette.text.primary;
  return {
    leftContainer: {
      display: "flex",
      alignItems: "center",
      width: "80%",
    },
    title: {
      fontSize: 14,
      fontWeight: typography.fontWeightMedium as any,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color,
    },
    rightContainer: {
      display: "flex",
      justifySelf: "flex-end",
      justifyContent: "flex-end",
      alignItems: "center",
      marginRight: -12,
      width: "20%",
      textAlign: "right",
    },
    arrowIcon: {
      color,
      opacity: 1,
    },
  };
});

type PropTypes = {
  label: string;
  onClick(): void;
};

const SettingsOption = (props: PropTypes) => {
  const { label } = props;
  const { onClick } = props;
  const classes = useStyles(props);

  return (
    <GenericOptionContainer onClick={onClick}>
      <>
        <div className={classes.leftContainer}>
          <Typography className={classes.title}>{label}</Typography>
        </div>

        <div className={classes.rightContainer}>
          <ArrowRightRoundedIcon className={classes.arrowIcon} />
        </div>
      </>
    </GenericOptionContainer>
  );
};

export default SettingsOption;
