import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import agent from "../../../agent";
import { ePageType, IAdminUser, ICategory, IPage, IWebsite } from "../../../reducers/constants/objectTypes";
import ReviewItemList from "./components/SecondEditItemList";
import { SECOND_EDIT_PAGES } from "../../../reducers/constants/actionTypes";
import useDebounce from "../../../hooks/useDebounce";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarPostTypeFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarPostTypeFilter";
import MTableToolbarCategoriesFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarCategoriesFilter";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  admin: state.home.admin,
  refreshPages: state.workFlowSecondEdit.refreshPages,
  paginationPagesCount: state.workFlowSecondEdit.paginationPagesCount,
  pagesLoaded: state.workFlowSecondEdit.pagesLoaded,
  categories: state.category.categories,
  rejected: state.workFlowSecondEdit.rejected,
  inProgress: state.workFlowSecondEdit.myReviews,
  publicPages: state.workFlowSecondEdit.pendingReviews,
  takenByOthers: state.workFlowSecondEdit.takenByOthers,
  postType: state.workFlowSecondEdit.filter.postType,
  category: state.workFlowSecondEdit.filter.category,
  searchText: state.workFlowSecondEdit.filter.searchText,
  sortField: state.workFlowSecondEdit.filter.sortField,
});

const mapDispatchToProps = (dispatch) => ({
  loadPages: (payload) => dispatch({ type: SECOND_EDIT_PAGES.PAGES_LOADED, payload }),
  startLoading: () => dispatch({ type: SECOND_EDIT_PAGES.PAGES_LOADING }),
  loadFailed: () => dispatch({ type: SECOND_EDIT_PAGES.PAGES_LOAD_FAILED }),
  onFilterChanged: (payload) => dispatch({ type: SECOND_EDIT_PAGES.PAGES_ON_FILTER_CHANGED, payload }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  admin: IAdminUser;
  refreshPages: boolean;
  pagesLoaded: boolean;
  paginationPagesCount: number;
  categories: ICategory[];

  loadPages: (payload) => void;
  loadFailed: () => void;
  startLoading: () => void;

  inProgress: IPage[];
  rejected: IPage[];
  publicPages: IPage[];
  takenByOthers: IPage[];

  postType: { _id: ePageType; name: string }[];
  category: ICategory[];
  searchText: string;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  onFilterChanged: (payload) => void;
};

const SecondEdit = (props: PropTypes) => {
  const {
    selectedWebsite,
    admin,
    loadPages,
    startLoading,
    refreshPages,
    categories,
    pagesLoaded,
    loadFailed,
    rejected,
    inProgress,
    publicPages,
    takenByOthers,
    paginationPagesCount,
    searchText,
    category,
    postType,
    sortField,
    onFilterChanged,
  } = props;

  const [page, setPage] = useState<number>(1);
  const [paginationLimit, setPaginationLimit] = useState(100);

  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    if (selectedWebsite && admin) {
      const cleanPostTypes = postType.map((p) => p._id);
      const postCategories = category.map((cat) => cat._id);

      startLoading();

      loadPages(
        agent.Pages.getSecondEditItems(
          selectedWebsite,
          admin,
          page,
          paginationLimit,
          sortField.field,
          sortField.direction,
          cleanPostTypes,
          postCategories,
          searchText
        )
          .then((res) => res)
          .catch((e) => loadFailed())
      );
    }
  }, [selectedWebsite, admin, postType, refreshPages, category, page, paginationLimit, debouncedSearchText, sortField]);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.article_cms.second_edit.title`)} />
      <MTableToolbar
        searchText={searchText}
        cmsCreateButton
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
      >
        <MTableToolbarPostTypeFilter
          postTypes={postType}
          selectedWebsite={selectedWebsite}
          handlePostTypesSelected={(value) => onFilterChanged({ postType: value })}
        />
        <MTableToolbarCategoriesFilter
          handleCategoriesSelected={(value) => onFilterChanged({ category: value })}
          selectedCategories={category}
          categories={categories}
        />
      </MTableToolbar>
      <ReviewItemList
        sortField={sortField}
        setSortField={(value) => onFilterChanged({ sortField: value })}
        takenByOthers={takenByOthers}
        inProgress={inProgress}
        rejected={rejected}
        publicPages={publicPages}
      />
      {pagesLoaded &&
        (takenByOthers.length > 0 || rejected.length > 0 || inProgress.length > 0 || publicPages.length > 0) && (
          <MTablePagination
            paginationLimit={paginationLimit}
            page={page}
            paginationPagesCount={paginationPagesCount}
            setPaginationLimit={setPaginationLimit}
            setPage={setPage}
          />
        )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondEdit);
