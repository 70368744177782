import * as React from "react";
import _, { uniqBy } from "lodash";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import agent from "../../../agent";
import { IProduct, IWebsite } from "../../../reducers/constants/objectTypes";
import MSelect from "../../../components/MSelect";
import theme from "../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  writersWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  writersWrapperFullWidth: {
    width: "100%",
  },
  writerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // whiteSpace: 'pre',
    marginBottom: 10,
  },
  writerWrapperFullWidth: {
    flexDirection: "column",
    alignItems: "start",
    gap: 7,
  },
  writerTitle: {
    maxWidth: 65,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    whiteSpace: "pre",
  },
  writerTitleFullWidth: {
    maxWidth: "unset",
  },
  clearIcon: {
    fill: "#808080",
    marginLeft: 5,
    cursor: "pointer",
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
  },
  arrowIcon: {
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  product: IProduct;
  title?: string;
  isClearable?: boolean;
  fullWidth?: boolean;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
  onProductChange: (product: IProduct) => void;
};

const ProductPickerElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    product,
    selectedWebsite,
    title = I18n.t("edit_post.select_product"),
    isClearable = false,
    products,
    handleProductsChange,
    onProductChange,
    fullWidth = false,
  } = props;
  const [items, setItems] = React.useState<IProduct[]>([]);
  const theme = useTheme<Theme>();
  const getProducts = (search = "") => {
    agent.Product.autoComplete(selectedWebsite._id, search, true)
      .then((results) => {
        setItems(results.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    getProducts();
  }, []);

  const allItems = products
    ? uniqBy([...products, ...items], (p) => p._id)
    : product && product._id
    ? [product, ...items]
    : items;

  return (
    <div className={ClassNames(classes.writerWrapper, fullWidth && classes.writerWrapperFullWidth)}>
      <Typography
        variant={"body1"}
        className={ClassNames(classes.writerTitle, fullWidth && classes.writerTitleFullWidth)}
        color={"textSecondary"}
      >
        {title}
      </Typography>
      <div className={ClassNames(classes.writersWrapper, fullWidth && classes.writersWrapperFullWidth)}>
        <MSelect
          customClass={classes.selectCustomClass}
          customDropdownIndicatorClasses={classes.arrowIcon}
          isClearable={isClearable}
          // textColor={theme.palette.text.primary}
          textColor={theme.palette.common.white}
          textColorHover={theme.palette.common.white}
          optionBackgroundColorHover={theme.palette.secondary.main}
          options={allItems}
          width={fullWidth ? "unset" : 180}
          fullWidth={fullWidth}
          value={product ? _.find(allItems, { _id: product?._id || null }) : null}
          searchable
          placeholder={I18n.t("edit_post.products_input_ph")}
          borderColor={theme.palette.divider}
          borderWidth={"0px"}
          borderRadius={2}
          isBold={false}
          height={32}
          menuPlacement={"bottom"}
          handleInputChange={(search) => {
            // if (search === "") {
            //   setItems([]);
            //   return;
            // }
            getProducts(search);
          }}
          handleChange={(selectedItem) => {
            if (selectedItem) {
              if (!products?.includes(selectedItem)) {
                handleProductsChange([...(products || []), selectedItem]);
                // handlePageChange({ ...page, products: [...(page?.products || []), selectedItem] });
              }
            }
            onProductChange(selectedItem);
          }}
          optionLabel={"name"}
          optionValue={"_id"}
        />
      </div>
    </div>
  );
};

export default ProductPickerElement;
