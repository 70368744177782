import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { useState } from "react";
import { find } from "lodash";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import PageLocale from "../../components/PageLocale";
import WebsitesSectionsSwitch from "../../components/WebsitesSectionsSwitch";
import {
  IWebsite,
  IWebsitePPCTop10Props,
  IWebsiteSectionProps,
  SNWebsiteAutoCompleteMetaTag,
} from "../../../../reducers/constants/objectTypes";
import TextInputMentions from "../../components/TextInputMentions";
import { LENGTHS } from "../../../../reducers/constants/consts";
import TextDesignToolWithPreview from "../../components/TextDesignToolWithPreview";
import MRouterPrompt from "../../components/MRouterPrompt";
import PageSection from "../../components/PageSection";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import PageAccess from "../../components/PageAccess";
import CustomUrlPrefixTextField from "../../components/CustomUrlPrefixTextField";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

const mapStateToProps = (state) => ({
  theme: state.websitePages.theme,
  sections: state.websitePages.sections,
  ppcTop10: state.websitePages.ppcTop10,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (ppcTop10) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED, name: "ppcTop10", value: ppcTop10 }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  website: IWebsite;
  ppcTop10: IWebsitePPCTop10Props;
  sections: IWebsiteSectionProps;
  onPropertyChanged: (ppcTop10) => void;
  handleToggleActive: (section, enabled) => void;
};

const PPCTop10Page = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website, sections, ppcTop10, handleToggleActive, onPropertyChanged } = props;
  const [error, setError] = React.useState(false);
  const [emptyFields, setEmptyFields] = React.useState([]);

  const [locale, setLocale] = useState(website?.configurations.locale.defaultLocale || null);
  const { defaultLocale } = website?.configurations.locale;

  React.useEffect(() => {
    if (emptyFields.length <= 0) {
      return;
    }
    textFieldsValidated();
  }, [ppcTop10]);

  const textFieldsValidated = () => {
    let valid = true;
    setEmptyFields([]);
    const emptyFields = [];
    if (!ppcTop10.name) {
      valid = false;
      emptyFields.push(I18n.t(`website.name`));
    }
    if (!ppcTop10.postPage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.ppcTop10.post_page_meta_title`));
    }
    if (!ppcTop10.postPage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.ppcTop10.post_page_meta_description`));
    }
    setEmptyFields(emptyFields);
    return valid;
  };
  return (
    <>
      <MRouterPrompt />
      <PageLocale website={website} locale={locale} handleLocaleChange={setLocale} />
      <WebsitesSectionsSwitch
        inSection
        enabled={sections.ppcTop10}
        title={I18n.t(`website.page_types.ppcTop10`)}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.ppcTop10) {
              handleToggleActive("ppcTop10", enabled);
            }
            setError(true);
          } else {
            setError(false);
            handleToggleActive("ppcTop10", enabled);
          }
        }}
      >
        <CustomUrlPrefixTextField
          locale={locale}
          customUrlPrefix={ppcTop10.urlPrefix}
          alternativeUrlPrefix={ppcTop10.translations?.[locale]?.urlPrefix}
          handleValueChange={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...ppcTop10, urlPrefix: value });
              return;
            }
            ppcTop10.translations[locale] = {
              ...(ppcTop10.translations[locale] || {}),
              urlPrefix: value,
            };
            onPropertyChanged({ ...ppcTop10 });
          }}
          previewUrls={[
            {
              key: "website.ppcTop10.post_page_preview_url",
              value: `${ppcTop10.postPage.slug}/post-slug`,
            },
          ]}
        />
      </WebsitesSectionsSwitch>
      {error && (
        <Typography variant={"body1"} className={classNames(classes.error)}>
          {emptyFields.length > 0 && I18n.t("website.error_message")}
        </Typography>
      )}

      <PageSection sectionTitle={I18n.t("website.ppcTop10.post_page")}>
        <TextFieldMaxLength
          titleAndLengthLocation={"top"}
          error={emptyFields.includes(I18n.t(`website.slug_title`))}
          value={
            locale === defaultLocale ? ppcTop10.postPage.slug : ppcTop10.translations[locale]?.postPage?.slug || ""
          }
          placeholder={I18n.t("website.slug_title_ph")}
          title={I18n.t("website.slug_title")}
          height={37}
          borderRadius={4}
          marginBottom={30}
          handleValueChange={(value) => {
            onPropertyChanged({ ...ppcTop10, postPage: { ...ppcTop10.postPage, slug: value } });
            if (locale === defaultLocale) {
              onPropertyChanged({ ...ppcTop10, postPage: { ...ppcTop10.postPage, slug: value } });
              return;
            }
            ppcTop10.translations[locale] = {
              ...(ppcTop10.translations[locale] || {}),
              postPage: { ...(ppcTop10.translations[locale]?.postPage || {}), slug: value },
            };
            onPropertyChanged({ ...ppcTop10 });
          }}
        />
        <TextDesignToolWithPreview
          topTitle
          disableHover
          marginLeft={"-30px"}
          marginRight={"-30px"}
          elementTitle={I18n.t("website.element_design", { element: I18n.t("website.title") })}
          css={ppcTop10.postPage.title?.css}
          variant={"h1"}
          exampleText={I18n.t("website.ppcTop10.example_title_text")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...ppcTop10,
              postPage: { ...ppcTop10.postPage, title: { ...ppcTop10.postPage.title, css: newCss } },
            });
          }}
        />
        <TextInputMentions
          key={`blog_post_meta_title_${locale}`}
          error={emptyFields.includes(I18n.t(`website.ppcTop10.post_page_meta_title`))}
          tags={[SNWebsiteAutoCompleteMetaTag.postTitle, SNWebsiteAutoCompleteMetaTag.year]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? ppcTop10.postPage.metaTitle
              : ppcTop10.translations[locale]?.postPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...ppcTop10, postPage: { ...ppcTop10.postPage, metaTitle: value } });
              return;
            }
            ppcTop10.translations[locale] = {
              ...(ppcTop10.translations[locale] || {}),
              "postPage.metaTitle": value,
            };
            onPropertyChanged({ ...ppcTop10 });
          }}
        />

        <TextInputMentions
          key={`blog_post_meta_desc_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.postTitle, SNWebsiteAutoCompleteMetaTag.postMetaDescription]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          error={emptyFields.includes(I18n.t(`website.ppcTop10.post_page_meta_description`))}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? ppcTop10.postPage.metaDescription
              : ppcTop10.translations[locale]?.postPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...ppcTop10,
                postPage: { ...ppcTop10.postPage, metaDescription: value },
              });
              return;
            }
            ppcTop10.translations[locale] = {
              ...(ppcTop10.translations[locale] || {}),
              "postPage.metaDescription": value,
            };
            onPropertyChanged({ ...ppcTop10 });
          }}
        />
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PPCTop10Page);
