import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import ClassNames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { IWriterBio } from "../../../../../../reducers/constants/objectTypes";
import { useState } from "react";
import { findIndex } from "lodash";
import agent from "../../../../../../agent";
import { WRITER_BIO } from "../../../../../../reducers/constants/actionTypes";
import { connect } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    writerWrapper: {
      width: 180,
      display: "flex",
      flexDirection: "column",
      whiteSpace: "pre",
      marginBottom: 20,
      marginTop: 25,
    },
    writersWrapper: {
      width: "100%",
    },
    writerTitle: {
      minWidth: 130,
      fontWeight: theme.typography.fontWeightLight as any,
      color: theme.palette.text.primary,
      fontSize: 14,
      marginBottom: 10,
    },
    titleTextField: {
      margin: 0,
      padding: "2px 0",
    },
    titleTextFieldInput: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
    },
    statusRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 10,
      alignItems: "center",
    },
    status: {
      display: "flex",
    },
    green: {
      color: "green",
    },
    red: {
      color: "red",
    },
    flexOne: {
      flex: 1,
      fontSize: theme.typography.pxToRem(14),
    },
    showWrapper: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold as any,
      display: "flex",
      cursor: "pointer",
    },
    showMore: {
      color: "inherit",
    },
    showMoreIcon: {
      fontSize: theme.typography.pxToRem(18),
    },
  })
);

const mapDispatchToProps = (dispatch) => ({
  handleBioUpdated: (bio) => dispatch({ type: WRITER_BIO.ON_BIO_UPDATED, bio }),
});

type PropTypes = {
  bio: IWriterBio;

  handleBioUpdated: (bio: IWriterBio) => void;
};

const BioUploadStatus = (props: PropTypes) => {
  const classes = useStyles(props);
  const { bio, handleBioUpdated } = props;
  const [showMore, setShowMore] = React.useState(true);

  const [tries, setTries] = useState(0);

  React.useEffect(() => {
    if (!bio || tries > 3) {
      console.log("no bio, or too many tries", tries);
      return;
    }
    const index = findIndex(bio.publishHistory, (ph) => ph.publishStatus === "PENDING");
    if (index < 0) {
      console.log("no pending");
      return;
    }
    setTries(tries + 1);
    setTimeout(() => {
      console.log("getAuthorStatus");
      agent.NI.getAuthorStatus(bio)
        .then((res) => {
          console.log(res);
          handleBioUpdated(res.bio);
        })
        .catch((e) => {
          console.log(e);
        });
    }, 5000);
    // agent.NI.getAuthorStatus(bio)
    //   .then((res) => {
    //     console.log(res);
    //     handleBioUpdated(res.bio);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }, [bio]);

  if (!bio || bio.publishHistory.length === 0) {
    return null;
  }

  const histories = bio.publishHistory.sort((x, y) => (moment(x.date).isSameOrAfter(moment(y.date)) ? -1 : 1));

  return (
    <div className={classes.writerWrapper}>
      <Typography variant={"subtitle2"} className={classes.writerTitle} color={"textSecondary"}>
        {I18n.t("custom_post.publish_status")}
      </Typography>
      <div className={classes.writersWrapper}>
        <div className={classes.statusRow}>
          <Typography className={classes.flexOne} variant={"subtitle2"}>
            {"Date"}
          </Typography>
          <Typography className={classes.flexOne} variant={"subtitle2"}>
            {"Time"}
          </Typography>
          <Typography className={classes.flexOne} variant={"subtitle2"}>
            {"Status"}
          </Typography>
        </div>

        {[...(showMore ? histories.slice(0, 3) : histories)].map((publishHistory, index) => (
          <div key={`upload_${index}`} className={classes.statusRow}>
            <Typography className={classes.flexOne} variant={"body1"} color={"textSecondary"}>
              {moment(publishHistory.date).format("MMM D")}
            </Typography>
            <Typography className={classes.flexOne} variant={"body1"} color={"textSecondary"}>
              {moment(publishHistory.date).format("HH:mm")}
            </Typography>
            <Tooltip
              disableHoverListener={publishHistory?.errorReason === null}
              title={JSON.stringify(publishHistory?.errorReason) || "-"}
            >
              <Typography
                variant={"body1"}
                color={"textSecondary"}
                className={ClassNames(
                  classes.flexOne,
                  classes.status,
                  publishHistory?.publishStatus === "OK" ? classes.green : classes.red
                )}
              >
                {publishHistory?.publishStatus}
                {publishHistory?.errorReason && <InfoIcon fontSize={"small"} />}
              </Typography>
            </Tooltip>
          </div>
        ))}
        {bio.publishHistory.length > 3 && (
          <div className={classes.showWrapper} onClick={() => setShowMore(!showMore)}>
            <Typography className={classes.showMore} variant={"body2"}>
              {I18n.t(`custom_post.${showMore ? "show_more" : "show_less"}`)}
            </Typography>
            {showMore ? (
              <ExpandMoreIcon className={classes.showMoreIcon} />
            ) : (
              <ExpandLessIcon className={classes.showMoreIcon} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(BioUploadStatus);
