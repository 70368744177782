import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { eActionIcon, ePostType, IAdminUser, IQuestion } from "../../../../reducers/constants/objectTypes";
import { COMMUNITY } from "../../../../reducers/constants/actionTypes";
import { web } from "../../../../helpers/urlHelper";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import { copyStringToClipboard } from "../../../../helpers/fbScraperHelper";
import CopyNameIcon from "../../../../icons/CopyNameIcon";
import MTableCellDate from "../../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $actions": {
        display: "flex",
      },
      "& $takenByText": {
        display: "none",
      },
      "& $takeOverButton": {
        display: "flex",
      },
    },
  },
  actions: {},
}));

type PropTypes = {
  user: IAdminUser;

  question: IQuestion;
  communityQuestionEditor: (questionId: string) => void;
  refreshAnswerList: () => void;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
});

const mapDispatchToProps = (dispatch) => ({
  communityQuestionEditor: (questionId) =>
    dispatch(push(web.communityQuestionEditor(questionId, "draft"), { publicPost: true })),
  refreshAnswerList: () => dispatch({ type: COMMUNITY.ANSWERS_REFRESH }),
});

const QuestionsDraftItemRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { question, communityQuestionEditor } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const actions = React.useMemo(
    () => [
      {
        text: I18n.t(`learning_center_content.tooltip.copy_title`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_title`),
        onClick: () => {
          copyStringToClipboard(question.title, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard")));
        },
        iconName: eActionIcon.copyName,
      },
    ],
    [question.title]
  );

  return (
    <MTableRow handleRowClick={() => communityQuestionEditor(question._id)} customClassName={classes.itemWrapper}>
      <MTableCellFirst
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(14)}
        avatarType={ePostType.COMMUNITY_ANSWER as ePostType}
        cellText={question.title || I18n.t("community.new_question")}
        actions={actions}
        actionClassName={classes.actions}
      />
      <MTableCellDate date={question.createdAt} />
      <MTableCellText text={question.category?.name} />
      <MTableCellNumber value={question.answers?.length} />

      {/* <div className={ClassNames(classes.lastColumn)}> */}
      {/*  <PageActions customClass={classes.actions} page={post} selectedWebsite={selectedWebsite} /> */}
      {/*  {!publicRow && !rejected && post?.writer?._id === user._id && ( */}
      {/*    <MTextButton customClass={classes.removeButton} text={"Remove"} onClick={(e) => discardPost(e)} /> */}
      {/*  )} */}

      {/*  {taken && post?.writer?.name && <Typography className={classes.writerName}>{post.writer.name}</Typography>} */}
      {/* </div> */}
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsDraftItemRow);
