import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import { IWebsite, IWebsiteDetailsProps } from "../../../../../reducers/constants/objectTypes";
import ImagePicker from "../../../components/ImagePicker";
import MRouterPrompt from "../../../components/MRouterPrompt";

const useStyles = makeStyles((theme: Theme) => ({
  imageRowWrapper: {
    display: "flex",
  },
  divider: {
    margin: "25px -30px",
  },
  imageRow: {
    display: "flex",
    flexDirection: "column",
    marginRight: 15,
    "&:last-child": {
      marginRight: 0,
    },
  },
  imageFieldTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(14),
  },
  imageFieldDimensions: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
  },
  imageFieldWrapper: {
    display: "flex",
    marginBottom: 15,
    gap: 10,
  },
}));

const mapStateToProps = (state) => ({
  siteDetails: state.websitePages.siteDetails,
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (name, value) => dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED, name, value }),
});

type PropTypes = {
  siteDetails: IWebsiteDetailsProps;
  onPropertyChanged: (name, value) => void;
  selectedWebsite: IWebsite;
};

const ImageDesign = (props: PropTypes) => {
  const classes = useStyles(props);
  const { onPropertyChanged, siteDetails, selectedWebsite } = props;
  return (
    <PageSection sectionTitle={I18n.t("website.images")}>
      <MRouterPrompt />
      <div className={classes.imageRowWrapper}>
        <div className={classes.imageRow}>
          <div className={classes.imageFieldWrapper}>
            <Typography variant={"body1"} className={classes.imageFieldTitle}>
              {I18n.t("website.logo")}
            </Typography>
            <Typography variant={"body1"} className={classes.imageFieldDimensions}>
              {I18n.t("website.logo_dimensions")}
            </Typography>
          </div>

          <ImagePicker
            key={`logo-image`}
            selectedWebsite={selectedWebsite}
            image={siteDetails?.logo}
            objectFit={"contain"}
            imageHeight={"180px"}
            imageWidth={"180px"}
            onImageChanged={(image) => {
              onPropertyChanged("siteDetails", { ...siteDetails, logo: image });
            }}
          />
        </div>

        <div className={classes.imageRow}>
          <div className={classes.imageFieldWrapper}>
            <Typography variant={"body1"} className={classes.imageFieldTitle}>
              {I18n.t("website.favicon")}
            </Typography>
            <Typography variant={"body1"} className={classes.imageFieldDimensions}>
              {I18n.t("website.favicon_dimensions")}
            </Typography>
          </div>

          <ImagePicker
            key={`favicon`}
            selectedWebsite={selectedWebsite}
            image={siteDetails?.favicon}
            objectFit={"contain"}
            imageWidth={"41px"}
            imageHeight={"41px"}
            onImageChanged={(image) => {
              onPropertyChanged("siteDetails", { ...siteDetails, favicon: image });
            }}
          />
        </div>
      </div>
      <Divider className={classes.divider} />

      <div className={classes.imageRow}>
        <div className={classes.imageFieldWrapper}>
          <Typography variant={"body1"} className={classes.imageFieldTitle}>
            {I18n.t("website.share_preview")}
          </Typography>
          <Typography variant={"body1"} className={classes.imageFieldDimensions}>
            {I18n.t("website.share_preview_dimensions")}
          </Typography>
        </div>
        <ImagePicker
          key={`share-preview`}
          selectedWebsite={selectedWebsite}
          image={siteDetails?.defaultPreviewImage}
          objectFit={"contain"}
          imageHeight={"273px"}
          imageWidth={"468px"}
          onImageChanged={(image) => {
            onPropertyChanged("siteDetails", { ...siteDetails, defaultPreviewImage: image });
          }}
        />
      </div>
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageDesign);
