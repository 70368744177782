import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import { IProduct, IQuestion, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import MSelect from "../../../../../../components/MSelect";
import MTag from "../../../../../../components/MTag";
import filter from "lodash/filter";
import { uniqBy } from "lodash";
import agent from "../../../../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  pageTopSection: {
    width: "100%",
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 7,
    color: theme.palette.text.primary,
  },
  selector: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E5E5E5",
    borderRadius: 4,
    height: 37,
  },
  tagsWrapper: {
    width: "100%",
    border: ` 1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    backgroundColor: "white",
  },
  postProductsWrapper: {
    width: "100%",
  },
  productsWrapper: {
    borderTop: `solid 1px ${theme.palette.divider}`,
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: 10,
    "& > *": {
      marginTop: 10,
    },
  },
}));

type PropTypes = {
  selectedQuestion: IQuestion;
  selectedWebsite: IWebsite;

  handleQuestionChange: Function;
};

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.home.selectedWebsite,
    selectedQuestion: state.communityEditor.question,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleQuestionChange: (question) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_QUESTION_CHANGED, question }),
});

const CommunityEditorProducts = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedQuestion, selectedWebsite } = props;
  const { handleQuestionChange } = props;
  const [items, setItems] = React.useState<IProduct[]>([]);
  const allItems =
    selectedQuestion.products && selectedQuestion.products.length > 0
      ? [...selectedQuestion.products, ...items]
      : items;

  return (
    <div className={classes.pageTopSection}>
      <Typography className={classes.title} variant={"body2"}>
        {I18n.t("edit_post.products")}
      </Typography>
      <div className={classes.tagsWrapper}>
        <div className={classes.postProductsWrapper}>
          <MSelect
            options={allItems}
            value={null}
            optionValue={"_id"}
            optionLabel={"name"}
            borderColor={"#e6e6e6"}
            borderRadius={0}
            borderWidth={"0px"}
            height={37}
            placeholder={I18n.t("edit_post.products_input_ph")}
            searchable
            handleInputChange={(search) => {
              agent.Product.autoComplete(selectedWebsite._id, search, true)
                .then((results) => {
                  setItems(results.items);
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
            handleChange={(product) => {
              handleQuestionChange({
                ...selectedQuestion,
                products: uniqBy([...selectedQuestion.products, product], "_id"),
              });
            }}
          />
          {selectedQuestion.products.length > 0 && (
            <div className={classes.productsWrapper}>
              {selectedQuestion.products.map((product) => (
                <MTag
                  key={product._id}
                  tag={product.name}
                  onRemoveClicked={() => {
                    handleQuestionChange({
                      ...selectedQuestion,
                      products: filter(selectedQuestion.products, (pProduct) => pProduct._id !== product._id),
                    });
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorProducts);
