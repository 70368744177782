import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { I18n } from "react-redux-i18n";
import FieldItem from "./FieldItem";
import { IWidget, IWidgetField } from "../../../../../../../../reducers/constants/objectTypes";
import { colors } from "../../../../../../../../helpers/constants";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  container: {
    marginBottom: "10px",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 6,
    width: "100%",
    backgroundColor: colors.pageEditorBackgroundColor,
  },
  itemsWrapper: {
    borderRadius: "5px",
    margin: 20,
    height: "calc(100vh - 90px)",
    overflowY: "scroll",
  },
  headerWrapper: {
    display: "flex",
    marginLeft: "-8px",
    cursor: "pointer",
    alignItems: "center",
    marginBottom: 20,
  },
  backIcon: {
    height: 22,
  },
  fieldsWrapper: {
    paddingInline: 16,
  },
  mainItems: {
    borderRadius: "5px",
    display: "grid",
    gridGap: "2px",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  item: {
    width: "33%",
  },
  selectTitle: {
    fontSize: "14px",
    userSelect: "none",
    fontWeight: typography.fontWeightRegular as any,
  },
  categorizedWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  categoryTitle: {
    marginBottom: 8,
    fontSize: "14px",
    userSelect: "none",
    fontWeight: typography.fontWeightRegular as any,
  },
  button: {
    marginRight: "-5px",
    background: palette.primary.main,
    justifySelf: "flex-end",
    height: "27px",
    width: "87px",
    fontSize: "14px",
    fontWeight: typography.fontWeightLight as any,
    textTransform: "none",
  },
}));

type PropTypes = {
  addableFields: IWidgetField[];
  addNewField: (field: IWidgetField) => void;
  onClose: () => void;
};

const AddWidgetFieldsTab = (props: PropTypes) => {
  const classes = useStyles(props);

  const { addableFields } = props;
  const { addNewField, onClose } = props;

  const fieldsCategories = React.useMemo(
    () => [...new Set(addableFields.map((item) => item.category))].filter(Boolean),
    [addableFields]
  );

  const onItemSelect = (item: IWidgetField) => {
    addNewField(item);
    onClose();
  };

  const CategorizedFieldsWrapper = () => (
    <div className={classes.categorizedWrapper}>
      {fieldsCategories.map((category, i) => (
        <div key={i}>
          <Typography variant={"h6"} className={classes.categoryTitle}>
            {I18n.t(`widgets_builder.widget_field_categories.${category}`)}
          </Typography>
          <div className={classes.mainItems}>
            {addableFields
              .filter((x) => x.category === category)
              .map((item) => (
                <FieldItem key={item.type} item={item} onClick={() => onItemSelect(item)} />
              ))}
          </div>
        </div>
      ))}
    </div>
  );

  const NonCategorizedFieldsWrapper = () => (
    <div className={classes.mainItems}>
      {addableFields.map((item, i) => (
        <FieldItem key={i} item={item} onClick={() => onItemSelect(item)} />
      ))}
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.itemsWrapper}>
        <div className={classes.headerWrapper} onClick={() => onClose()}>
          <KeyboardArrowLeftRounded className={classes.backIcon} />
          <Typography variant={"h6"} className={classes.selectTitle}>
            {I18n.t("widgets_builder.widget_item_selector.select_item")}
          </Typography>
        </div>
        <div className={classes.fieldsWrapper}>
          {fieldsCategories.length > 0 ? <CategorizedFieldsWrapper /> : <NonCategorizedFieldsWrapper />}
        </div>
      </div>
    </div>
  );
};

export default AddWidgetFieldsTab;
