import React from "react";
import Typography from "@material-ui/core/Typography";
import { Input, Tooltip } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    maxWidth: 60,
    whiteSpace: "pre",
  },
  inputRoot: (props: PropTypes) => ({
    width: props.width || 180,
    minHeight: props.minHeight || "unset",
    padding: "0 5px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
  }),
  postFix: {
    color: `${theme.palette.text.primary} !important`,
  },
  valueWrap: {
    display: "flex",
    alignItems: "center",
  },
  backToDefault: {
    color: theme.palette.text.hint,
    fontSize: 16,
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

type PropTypes = {
  rowTitle: string;
  value: number;
  defaultValue: number;
  postFix?: string;
  maxValue?: number;
  width?: number | string;
  minHeight?: string | number;
  handleChange: (value) => void;
};

const NumberInputBaseComponent = (props: PropTypes) => {
  const classes = useStyles(props);
  const { value, rowTitle, defaultValue, postFix, maxValue, handleChange } = props;
  return (
    <div className={classes.row}>
      <Typography variant={"body2"} className={classes.text}>
        {rowTitle}
      </Typography>
      <div className={classes.valueWrap}>
        {value && value !== defaultValue && (
          <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
            <CloseIcon className={classes.backToDefault} onClick={() => handleChange(defaultValue)} />
          </Tooltip>
        )}
        <Input
          value={value || defaultValue || 0}
          type="number"
          disableUnderline
          endAdornment={
            postFix ? (
              <InputAdornment position="start" disableTypography>
                {postFix}
              </InputAdornment>
            ) : (
              ""
            )
          }
          inputProps={{ min: 1, max: maxValue || "" }}
          classes={{ root: classes.inputRoot }}
          onChange={(event) => handleChange(event.target.value)}
        />
      </div>
    </div>
  );
};

export default NumberInputBaseComponent;
