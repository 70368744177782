import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 24,
    height: 24,
  },
}));

type PropTypes = {
  className?: string;
  onClick?: (e: any) => void;
};

const SendIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className, onClick } = props;

  return (
    <SvgIcon onClick={onClick} className={ClassNames(classes.icon, className)} viewBox="0 0 30 30">
      <path fillRule="evenodd" clipRule="evenodd" d="M29.4424 15.8967C30.1859 15.53 30.1859 14.4698 29.4424 14.1031L2.04583 0.588821C1.38125 0.260998 0.603439 0.744616 0.603439 1.48565L0.603439 13.0488L13.0886 14.5034C13.6781 14.5721 13.6781 15.428 13.0886 15.4966L0.603439 16.9513L0.603438 28.5142C0.603438 29.2552 1.38125 29.7388 2.04583 29.411L29.4424 15.8967Z" />
    </SvgIcon>
  );
};

export default SendIcon;
