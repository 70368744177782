import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import EditorialPolicyRightSidebar from "./components/EditorialPolicyRightSidebar";
import EditorialPolicyLeftList from "./components/EditorialPolicyLeftList";
import EditorialPolicyDetails from "./components/EditorialPolicyDetails";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  type: "EDITORIAL_PROCESS" | "COMMUNITY_GUIDELINES" | "EDITORIAL_TEAM" | "CONTENT_PRINCIPLES" | "FACT_CHECKED";
};

const EditorialPolicy = (props: PropTypes) => {
  const classes = useStyles(props);
  const { type } = props;

  return <EditorialPolicyDetails type={type} />;
};

export default EditorialPolicy;
