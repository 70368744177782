import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { IProduct } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  featureComparisons: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid transparent",
    borderRadius: 3,
    padding: 5,
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  prosAndCons: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid transparent",
    borderRadius: 3,
    padding: 5,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  tableTopRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tableTitleColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  allProductsText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightLight as any,
    lineHeight: "19px",
    marginBottom: 2,
  },
  tableTitle: (props: PropTypes) => ({
    fontSize: 22,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.3,
    marginBottom: 5,
    ...props.element.data?.titleCss,
  }),
  tableDescription: (props: PropTypes) => ({
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: 1.3,
    ...props.element.data?.descriptionCss,
  }),
  columnTitleWrapper: (props: PropTypes) => ({
    display: "flex",
    flexDirection: "row",
    padding: 20,
    justifyContent: "space-between",
    alignItems: "center",
    ...props.element.data?.oddCss,
  }),
  columnTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.3,
  },
  productsWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  productsWrapperHideLabel: {
    display: "flex",
    flexDirection: "row",
    gap: 30,
    alignItems: "center",
  },
  productWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 100,
    gap: 10,
  },
  productImagePh: {
    height: 100,
    width: 100,
    borderRadius: 15,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  productImage: {
    height: 100,
    width: 100,
    objectFit: "cover",
    borderRadius: 15,
  },
  productTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: "16.8px",
    whiteSpace: "break-spaces",
    overflow: "hidden",
    textAlign: "center",
  },
  productComparisonsWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },

  comparisonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    padding: "15px 0px 15px 20px",
  },
  comparisonRowWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  featureTitle: {
    lineHeight: "19.2px",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 3,
  },
  featureDescription: {
    lineHeight: "16.8px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    color: "#808080",
  },
  productSelectionWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  productSelect: {
    width: 100,
    display: "flex",
    justifyContent: "center",
  },
  productSelectCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  productSwitchWrapper: {
    width: 70,
    display: "flex",
    flexDirection: "column",
  },
  priceText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "14.4px",
    textAlign: "center",
  },
  checkIcon: (props: PropTypes) => ({
    fill: "#35c376",
    marginRight: 10,
    ...props.element.data.prosCss,
  }),
  clearIcon: (props: PropTypes) => ({
    fill: "red",
    marginRight: 10,
    ...props.element.data.consCss,
  }),
  imagePlaceHolder: {
    fontWeight: "bolder",
    fontSize: 30,
    color: "white",
  },
}));
type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  products: IProduct[];
};
const ComparisonTableWidgetReadOnly = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, products = [] } = props;
  const comparisonProducts = [
    ...element.data.products
      .map((product) => {
        return products.find((p) => p.itemNumber === product.itemNumber);
      })
      .filter((product) => product !== undefined),
  ];
  console.log("products::::", element);
  const getComparisonsProducts = (products) => {
    let newProducts = [...products];
    console.log(newProducts);
    if (element.data.products) {
      let allProducts = [
        ...element.data.products.map((product) => ({
          itemNumber: product.itemNumber,
          selected: false,
        })),
      ];
      newProducts = allProducts.map((product) => ({
        ...product,
        ...newProducts.find((p) => p.itemNumber === product?.itemNumber),
      }));
    }
    return newProducts;
  };
  return (
    <div {...attributes}>
      <div className={classes.featureComparisons}>
        <div className={classes.tableTopRow}>
          <div className={classes.tableTitleColumn}>
            {comparisonProducts?.length > 0 && (
              <Typography className={classes.allProductsText}>
                {comparisonProducts.map((product) => product?.name).join(" vs ")}
              </Typography>
            )}
            <Typography className={classes.tableTitle}>
              {element.data.title || I18n.t("rich_text_editor.feature_comparison_table.title")}
            </Typography>
            <Typography className={classes.tableDescription}>{element.data.text || ""}</Typography>
          </div>
          <div className={classes.productsWrapper}>
            {[...(comparisonProducts || [])].map((product, index) => {
              return (
                <div className={classes.productWrapper} key={`${product.name}_${index}`}>
                  {element.data.showImage && (
                    <>
                      {product?.iconFile?.url || product?.iconFile?.url || product.logo || product.logoFile?.url ? (
                        <img
                          className={classes.productImage}
                          alt={product?.iconFile?.alt || product.logoFile?.alt || `Product ${index}`}
                          src={product?.iconFile?.url || product.logo || product.logoFile?.url}
                        />
                      ) : (
                        <div className={classes.productImagePh}>
                          <div className={classes.productImagePh}>
                            <Typography className={classes.imagePlaceHolder}>{product.name[0] || "P"}</Typography>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <Typography className={classes.productTitle}>{product.name}</Typography>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.productComparisonsWrapper}>
          {[...(element.data.comparisons || [])].map((comp, compIndex) => {
            return (
              <div key={`con_${compIndex}`} className={classes.comparisonRowWrapper}>
                <div
                  className={classes.comparisonWrapper}
                  style={{ ...(compIndex % 2 === 0 ? element.data.evenCss : element.data.oddCss) }}
                >
                  <div className={classes.tableTitleColumn}>
                    <Typography className={classes.featureTitle}>{comp.title}</Typography>
                    <Typography className={classes.featureDescription}>{comp.description}</Typography>
                  </div>
                  <div className={classes.productSelectionWrapper}>
                    {[...getComparisonsProducts(comp?.products || [])].map((product, i) => {
                      console.log(product);
                      return (
                        <div key={`${product._id}_${i}_${compIndex}`} className={classes.productSelect}>
                          {!product.selected ? (
                            <ClearIcon className={classes.clearIcon} />
                          ) : (
                            <CheckIcon className={classes.checkIcon} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {children}
    </div>
  );
};

export default ComparisonTableWidgetReadOnly;
