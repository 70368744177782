import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { IAdminUser, IOutline } from "../../../reducers/constants/objectTypes";
import MTableRow from "../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../components/Table/Cells/MTableCellFirst";
import MTableCellDate from "../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../components/Table/Cells/MTableCellText";
import MTableCell from "../../../components/Table/Cells/MTableCell";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    textTransform: "capitalize",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.light,
    },
  },
  takenBy: {
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: "nowrap",
  },
}));

type PropTypes = {
  view: "DATA_VIEW" | "EDITOR_VIEW";
  outlinePage: IOutline;
  user: IAdminUser;

  handleButtonClick: () => void;
  openOutlinePage: (outlinePage, view) => void;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
});

const mapDispatchToProps = (dispatch) => ({});

const OutlinePageItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { user, view, outlinePage, handleButtonClick, openOutlinePage, isChild } = props;
  const theme = useTheme();
  const handleToggle = (e) => {
    e.stopPropagation();
    handleButtonClick();
  };

  return (
    <MTableRow handleRowClick={() => openOutlinePage(outlinePage, view)}>
      <MTableCellFirst
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(14)}
        avatarType={outlinePage?.postType || "O"}
        cellText={outlinePage.postTitle.title || I18n.t("outline.new_outline")}
      />
      <MTableCellDate date={outlinePage.createdAt} />
      <MTableCellText text={undefined} />
      <MTableCellText text={undefined} />
      <MTableCellText text={undefined} />
      <MTableCellText text={undefined} />
      <MTableCellText text={undefined} />
      <MTableCellText text={undefined} />

      {view === "EDITOR_VIEW" && <MTableCellText text={outlinePage.analyst?.name || ""} />}
      <MTableCell>
        {(view === "DATA_VIEW" &&
          (!outlinePage.analyst || (outlinePage.analyst && outlinePage.analyst._id !== user._id))) ||
        (view === "EDITOR_VIEW" && !outlinePage.editor) ? (
          <></>
        ) : (
          <>
            {view === "EDITOR_VIEW" && outlinePage.editor && outlinePage.editor._id !== user._id ? (
              <Typography variant={"body2"} className={classes.takenBy}>
                {I18n.t("outline.taken_by", { name: outlinePage.editor.name })}
              </Typography>
            ) : (
              <Button className={classes.button} onClick={handleToggle}>
                {I18n.t("outline.remove")}
              </Button>
            )}
          </>
        )}
      </MTableCell>
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlinePageItem);
