import React, { useEffect, useMemo, useState } from "react";
import { Dialog, DialogContent, FormControlLabel, IconButton, Radio, RadioGroup, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import { I18n } from "react-redux-i18n";
import XIcon from "../../icons/XIcon";
import { IKeywordStrategy, IWebsite } from "../../reducers/constants/objectTypes";
import { connect } from "react-redux";
import MTextField from "../../components/MTextField";
import MButton from "../../components/MButton";
import Button from "@material-ui/core/Button";
import ClassNames from "classnames";
import CfRadioButton from "../../components/CfRadioButton";
import MTextArea from "../../components/MTextArea";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    padding: 0,
    borderRadius: "10px",
    width: "50%",
    "& .MuiDialogContent-root": {
      padding: 30,
    },
  },
  dialogTitle: {
    fontSize: "25px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "6px",
  },
  closeButton: {
    padding: 0,
    marginRight: 0,
    alignSelf: "flex-start",
  },
  headerIcon: {
    width: 20,
    height: 20,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  description: {
    fontSize: "16px",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  charCounter: {
    display: "flex",
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.disabled,
  },
  inputLabel: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    marginTop: "30px",
  },
  textInput: {
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    color: theme.palette.text.primary,
    padding: 10,
    fontSize: "16px",
    fontWeight: theme.typography.fontWeightLight as any,
  },
  buttonWrapper: {
    marginTop: "25px",
    marginLeft: "auto",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "10px",
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: "25px",
    padding: "10px 30px",
  },
  submitBtn: {
    borderRadius: "25px",
    padding: "10px 30px",
    textTransform: "capitalize",
  },
  rejectBtn: {
    color: theme.palette.error.main,
    borderRadius: "25px",
    padding: "10px 30px",
    textTransform: "capitalize",
    border: `1px solid ${theme.palette.error.main}`,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      transition: "color .2s ease-in-out",
    },
  },
  radioInputsWrapper: {
    marginTop: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    marginBottom: "10px",
  },
  rejectText: {
    fontSize: "16px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  radioInputs: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  radioButtonRoot: {
    color: "#dadada",
    "&$radioButtonChecked": {
      color: theme.palette.primary.main,
    },
  },
  radioButtonChecked: {},
  radioLabel: {
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.primary,
  },
  textAreaWrapper: {},
  textArea: {
    padding: "15px",
    backgroundColor: "#EEF2F9",
    borderRadius: "5px",
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightLight as any,
  },
}));

type PropTypes = {
  open: boolean;
  closeDialog: () => void;
  reject: (item, feedback?: string) => void;
  changeFeedback: (item, feedback?: string) => void;
  feedbackModify: boolean;
  keywordStrategy: IKeywordStrategy;
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  keywordStrategy: state.keywordStrategy.selectedDataItem,
});

const MappingFeedbackDialog = (props: PropTypes) => {
  const { open, closeDialog, keywordStrategy, changeFeedback, reject, feedbackModify } = props;
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [currentFeedback, setCurrentFeedback] = useState(keywordStrategy?.feedback || "");

  const radioOptions = useMemo(
    () => [
      { value: "Not relevant to our target audience" },
      { value: "The site already ranks for this" },
      { value: "We’re planning to create similar content" },
      { value: "The topic is too broad" },
      { value: "Not enough volume" },
      { value: "We need to avoid this topic" },
    ],
    []
  );

  useEffect(() => {
    setCurrentFeedback(keywordStrategy?.feedback || "");

    return () => {
      setCurrentFeedback("");
    };
  }, [keywordStrategy]);

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={closeDialog}
      fullWidth
      maxWidth={"xl"}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={classes.contentWrapper}>
        <div className={classes.titleWrapper}>
          <Typography variant={"subtitle1"} className={classes.dialogTitle}>
            {I18n.t("workflow.mapping.feedback")}
          </Typography>
          <IconButton className={classes.closeButton} onClick={closeDialog}>
            <XIcon className={classes.headerIcon} />
          </IconButton>
        </div>
        <div className={classes.inputLabel}>
          <Typography variant={"subtitle2"} className={classes.description}>
            {I18n.t("workflow.mapping.feedback_description")}
          </Typography>
        </div>
        <div className={classes.textAreaWrapper}>
          <MTextArea
            autoFocus
            text={currentFeedback}
            onChange={(text) => {
              setCurrentFeedback(text);
            }}
            placeholder={I18n.t("workflow.mapping.feedback_placeholder")}
            className={classes.textArea}
            minRows={5}
          />
        </div>
        <div className={classes.buttonWrapper}>
          {feedbackModify ? (
            <MButton
              customClassNames={ClassNames(classes.submitBtn)}
              onClick={() => {
                changeFeedback(keywordStrategy, currentFeedback);
                closeDialog();
              }}
            >
              <Typography variant={"subtitle2"}>{I18n.t("workflow.mapping.save")}</Typography>
            </MButton>
          ) : (
            <Button
              className={ClassNames(classes.rejectBtn)}
              variant={"outlined"}
              onClick={() => {
                reject(keywordStrategy, currentFeedback);
                closeDialog();
              }}
            >
              <Typography variant={"subtitle2"}>{I18n.t("workflow.mapping.reject")}</Typography>
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, {})(MappingFeedbackDialog);
