import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import MTextButton from "../../../../../../components/MTextButton";
import { COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import { IAdminUser, IQuestion, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import MPostedTimePicker from "../../../../../../components/MPostedTimePicker";
import CfCheckBox from "../../../../../../components/CfCheckBox";
import { AbilityContext } from "../../../../../../casl/can";
import CommunityEditorAnswerUserSelector from "./CommunityEditorAnswerUserSelector";
import MSelect from "../../../../../../components/MSelect";
import CommunityEditorAnswerModeratorPanel from "./CommunityEditorAnswerModeratorPanel";
import RichTextEditor from "../../../../../../editor/RichTextEditor";
import CheckIcon from "../../../../../../icons/CheckIcon";
import { useParams } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
  answerWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
    opacity: (props: PropTypes) => (!props.isAnswerEditor ? "1" : "0.7"),
    pointerEvents: (props: PropTypes) => (!props.isAnswerEditor ? "initial" : "none"),
  },
  answerTopBar: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 20px",
    borderBottom: "1px solid #E5E5E5",
    fontSize: theme.typography.pxToRem(12),
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: `1px solid ${theme.palette.divider}`,
    height: 46,
    padding: "0 10px",
  },
  checkboxWrapper: {
    display: "flex",
    marginLeft: 10,
  },
  actions: {
    display: "flex",
    alignSelf: "flex-end",
  },
  actionButton: {
    paddingRight: 0,
  },
  commentWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 5,
  },
  repliesWrapper: {
    marginLeft: 10,
  },
  statusWrapper: {
    display: "flex",
    alignItems: "center",
  },
  checkIcon: {
    marginRight: 7,
    marginLeft: 10,
  },
  editorToolbar: {
    overflowX: "auto",
    padding: "0 20px",
    justifyContent: "space-between",
  },
  editable: {
    fontSize: theme.typography.pxToRem(16),
    minHeight: 100,
    paddingTop: 5,
  },
  answerUser: {
    height: 45,
    flexDirection: "row",
    alignItems: "center",
  },
  realUserWrapper: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "center",
    marginLeft: 20,
  },
  realUserPicker: {
    marginLeft: -3,
    fontSize: `${theme.typography.pxToRem(12)} !important`,
    "& .MuiSvgIcon-root": {
      fill: "#333333",
      fontSize: `${theme.typography.pxToRem(12)} !important`,
    },
    '& [class$="singleValue"]': {
      fontSize: `${theme.typography.pxToRem(12)} !important`,
    },
    cursor: "pointer",
  },
  bottomUserPicker: {
    marginLeft: -15,
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(12),
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedQuestion: IQuestion;
  index: number;
  canEditPost?: boolean;
  writers: IAdminUser[];
  admin: IAdminUser;
  isAnswerEditor?: boolean;
  handleDeleteAnswer: Function;
  handleAnswerChange: Function;
  handleAnswerPostedTimeChange: Function;
  handleQuestionChange: (question: IQuestion) => void;
};

const mapStateToProps = (state) => ({
  admin: state.home.admin,
  selectedWebsite: state.home.selectedWebsite,

  selectedQuestion: state.communityEditor.question,
  writers: state.communityEditor.questionEditorWriters,
  richTextDialogItemQuestion: state.communityEditor.richTextDialogItemQuestion,
  richTextDialogIsAnswerIndex: state.communityEditor.richTextDialogIsAnswerIndex,
  isAnswerEditor: state.communityEditor.isAnswerEditor,
});

const mapDispatchToProps = (dispatch) => ({
  handleQuestionChange: (question) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_QUESTION_CHANGED, question }),
  handleDeleteAnswer: (index) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_ANSWER_DELETED, index }),
  handleAnswerChange: (index, answer) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_ANSWER_CHANGED, index, answer }),
  handleAnswerPostedTimeChange: (index, postedTime) =>
    dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_ANSWER_POSTED_TIME_CHANGED, index, postedTime }),
});

const CommunityEditorAnswer = (props: PropTypes) => {
  const { selectedWebsite, selectedQuestion, index, writers, isAnswerEditor, handleQuestionChange } = props;
  const answer = selectedQuestion.answers[index];

  const classes = useStyles({ ...props, isAnswerEditor });
  const { handleAnswerChange, handleAnswerPostedTimeChange, handleDeleteAnswer } = props;
  const ability = React.useContext<any>(AbilityContext);
  const { type } = useParams();

  const convertTextToRichText = (initialText) => {
    return JSON.stringify([
      {
        type: "paragraph",
        children: [{ text: initialText || "" }],
      },
    ]);
  };
  if (isAnswerEditor && !answer.published) {
    return null;
  }

  return (
    <div className={classes.answerWrapper}>
      <Paper elevation={0} className={classes.commentWrapper}>
        <RichTextEditor
          fontSize={16}
          droppableId={`question_editor_answer_${answer._id}`}
          key={answer._id}
          products={selectedQuestion.products}
          handleProductsChange={(products) => handleQuestionChange({ ...selectedQuestion, products })}
          withCustomElements={true}
          editableCustomClass={classes.editable}
          toolbarCustomClass={classes.editorToolbar}
          website={selectedWebsite}
          initialValue={answer.richText || convertTextToRichText(answer.text)}
          onValueChanged={(value, raw) => handleAnswerChange(index, { ...answer, text: raw, richText: value })}
        >
          <div className={classes.answerTopBar}>
            <div style={{ display: "flex" }} className={answer.moderatorState !== "NEW" ? classes.answerUser : ""}>
              {/*<div className={classes.userInfo}>*/}
              {/*    <CommunityUserInformation user={selectedQuestion.user} />*/}
              {/*</div>*/}
              <CommunityEditorAnswerUserSelector
                user={answer.user}
                creditedWriter={answer.creditedWriter}
                selectedWebsite={selectedWebsite}
                onUserSelected={(user) => handleAnswerChange(index, { ...answer, user, creditedWriter: null })}
                onCreditedWriterSelected={(writer) =>
                  handleAnswerChange(index, { ...answer, creditedWriter: writer, user: null })
                }
              />
              {!answer.publish && (
                <div className={classes.realUserWrapper}>
                  <Typography color={"textSecondary"} variant={"inherit"}>
                    {I18n.t("edit_post.writer")}:
                  </Typography>
                  <MSelect
                    options={writers}
                    value={_.find(writers, { email: answer?.writer?.email || "All" })}
                    optionLabel={"name"}
                    customClass={classes.realUserPicker}
                    optionValue={"_id"}
                    borderWidth={"0 0 0 0"}
                    height={45}
                    textColor={"#333333"}
                    isBold={false}
                    borderRadius={0}
                    menuPlacement={"bottom"}
                    // fullWidth
                    searchable
                    getDisplayLabel={(writer) => `${writer.name}`}
                    handleChange={(writer) =>
                      handleAnswerChange(index, { ...answer, writer: writer.email === "All" ? null : writer })
                    }
                  />
                </div>
              )}
            </div>
            <div style={{ display: "flex" }}>
              <MPostedTimePicker
                date={moment(answer.postedTime)}
                onDateChanged={(date) => handleAnswerPostedTimeChange(index, date)}
              />
            </div>
          </div>
        </RichTextEditor>

        {answer.moderatorState !== "NEW" && (
          <div className={classes.actionsWrapper}>
            {ability.can("view", "community_v2") && !answer.published && (
              <div className={classes.checkboxWrapper}>
                <CfCheckBox
                  checked={answer.publish !== null && answer.publish}
                  value={`${answer._id}_publish`}
                  label={I18n.t("upload_post.publish_answer")}
                  handleChange={(checked) => handleAnswerChange(index, { ...answer, publish: true })}
                />
                <CfCheckBox
                  checked={answer.publish === false}
                  value={`${answer._id}_submit`}
                  label={I18n.t("upload_post.submit_to")}
                  handleChange={(checked) => handleAnswerChange(index, { ...answer, publish: false })}
                />
                {!answer.publish && (
                  <MSelect
                    options={writers}
                    value={_.find(writers, { email: answer?.writer?.email || "All" })}
                    optionLabel={"name"}
                    optionValue={"_id"}
                    borderWidth={"0 0 0 0"}
                    height={45}
                    isBold={false}
                    borderRadius={0}
                    menuPlacement={"bottom"}
                    customClass={classes.bottomUserPicker}
                    // fullWidth
                    searchable
                    getDisplayLabel={(writer) => `${writer.name}`}
                    handleChange={(writer) =>
                      handleAnswerChange(index, { ...answer, writer: writer.email === "All" ? null : writer })
                    }
                  />
                )}
              </div>
            )}
            {answer.published && (
              <div className={classes.statusWrapper}>
                <CheckIcon className={classes.checkIcon} />
                <Typography color={"textPrimary"} variant={"body2"}>
                  Published
                </Typography>
              </div>
            )}
            <div className={classes.actions}>
              <MTextButton
                customClass={classes.actionButton}
                text={I18n.t("upload_post.delete")}
                onClick={(e) => handleDeleteAnswer(index)}
              />
            </div>
          </div>
        )}
        {answer.moderatorState === "NEW" && <CommunityEditorAnswerModeratorPanel answer={answer} />}
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorAnswer);
