import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import { IQueryGroup } from "../../../reducers/constants/objectTypes";
import CopyToClipboardIcon from "../../../icons/CopyToClipboardIcon";
import { copyStringToClipboard } from "../../../helpers/fbScraperHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold as any,
      color: theme.palette.text.primary,
    },
    innerIdentSection: {
      marginLeft: 10,
      marginTop: 5,
      fontSize: theme.typography.pxToRem(14),
    },
    targetKeywordWrapper: {
      marginBottom: 20,
      display: "flex",
      flexDirection: "column",
    },
    targetKeyword: {
      paddingLeft: 3,
      marginBottom: 5,
      fontSize: theme.typography.pxToRem(14),
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        "& $copyToClipboardButton": {
          display: "block",
        },
      },
    },
    copyToClipboardButton: {
      padding: "0px 5px 0px 5px",
      display: "none",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    copyToClipboardIcon: {
      width: 20,
      height: 20,
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
  })
);

type PropTypes = {
  h3: string[];
};

const EditorOutlineH3 = (props: PropTypes) => {
  const classes = useStyles(props);
  const { h3 = [] } = props;
  const { enqueueSnackbar } = useSnackbar();

  if (h3 && h3.length === 0) {
    return null;
  }

  return (
    <div className={classes.targetKeywordWrapper}>
      <Typography className={classes.innerTitle}>{I18n.t("outline.h3_title")}</Typography>
      <div className={classes.innerIdentSection}>
        {h3?.map((title) => (
          <Typography className={classes.targetKeyword}>
            {title}
            <IconButton
              disableRipple
              className={classes.copyToClipboardButton}
              onClick={(event) => {
                event.stopPropagation();
                copyStringToClipboard(title, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard")));
              }}
            >
              <CopyToClipboardIcon className={classes.copyToClipboardIcon} />
            </IconButton>
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default EditorOutlineH3;
