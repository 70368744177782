import React from "react";
import AppBar from "@material-ui/core/AppBar";
import EntailNavbarLogo from "../../../icons/EntailNavbarLogo";
import Button from "@material-ui/core/Button";
import ArrowRightIcon from "../../../icons/ArrowRightIcon";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: 4,
    height: 70,
    padding: "0 50px",
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 1px 25px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  entailNavbarLogo: {},
  navbarActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  getStartedButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    height: 45,
    borderRadius: 40,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
    padding: "12px 25px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  contactSales: {
    color: theme.palette.text.primary,
    lineHeight: "22px",
    whiteSpace: "nowrap",
    fontSize: 16,
    marginRight: 15,
    fontWeight: theme.typography.fontWeightBold as any,
    height: 45,
    borderRadius: 40,
    padding: "12px 25px",
    textTransform: "none",
  },
  arrowRightIcon: {
    marginLeft: 10,
    "&:hover": {
      color: "white",
    },
  },
}));

type PropTypes = {};

const AuthNavbar = (props: PropTypes) => {
  const classes = useStyles(props);

  return (
    <AppBar elevation={0} position="sticky" className={classes.appBar}>
      <EntailNavbarLogo className={classes.entailNavbarLogo} />
      <div className={classes.navbarActions}>
        <Button
          href={"https://entail.ai/contact-us"}
          target={"_blank"}
          onClick={() => {}}
          variant="text"
          className={classes.contactSales}
        >
          {I18n.t("login.contact_sales")}
        </Button>
        <Button href={"/signup"} onClick={() => {}} variant="outlined" className={classes.getStartedButton}>
          {I18n.t("login.get_started")}
          <ArrowRightIcon className={classes.arrowRightIcon} />
        </Button>
      </div>
    </AppBar>
  );
};

export default AuthNavbar;
