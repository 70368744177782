import React, { useMemo } from "react";
import { I18n } from "react-redux-i18n";
import moment from "moment/moment";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";

type PropTypes = {
  startTime: string;
  handlePostStartTimeSelected: (_id: string) => void;
};

const MTableToolbarKeywordStrategyStartTimeFilter = (props: PropTypes) => {
  const { startTime, handlePostStartTimeSelected } = props;

  const startTimeOptions = useMemo(
    () => [
      {
        name: I18n.t("cro.date_range.last_7_days"),
        _id: moment().subtract(7, "day").format("YYYY-MM-DD"),
      },
      {
        name: I18n.t("cro.date_range.last_28_days"),
        _id: moment().subtract(28, "day").format("YYYY-MM-DD"),
      },
      {
        name: I18n.t("cro.date_range.last_3_months"),
        _id: moment().subtract(3, "month").format("YYYY-MM-DD"),
      },
      {
        name: I18n.t("cro.date_range.last_6_months"),
        _id: moment().subtract(6, "month").format("YYYY-MM-DD"),
      },
      {
        name: I18n.t("cro.date_range.last_12_months"),
        _id: moment().subtract(12, "month").format("YYYY-MM-DD"),
      },
    ],
    []
  );
  return (
    <MTableToolbarBaseSelect
      options={startTimeOptions}
      selectedOptions={startTime ? [startTimeOptions.find((time) => startTime === time?._id)] : []}
      placeholder={I18n.t("strategy.outlinePages.reviewed_at")}
      handleChange={(v) => {
        handlePostStartTimeSelected(v?.[0]?._id || null);
      }}
    />
  );
};

export default MTableToolbarKeywordStrategyStartTimeFilter;
