import { FETCH_KEYWORDS } from "./constants/actionTypes";

const initialState = {
  seedsListLoaded: false,
  seedsList: [],
  searchText: "",
  selectedSite: null,
  checkedSeedsList: [],
  seed: null,
  totalKeywordsCount: 0,
  balance: 0,
  filter: {
    searchText: "",
    selectedType: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KEYWORDS.ON_SEEDS_LIST_ADDED:
      return {
        ...state,
        seedsList: [...state.seedsList, ...action.seeds],
      };
    case FETCH_KEYWORDS.ON_SEEDS_LIST_LOADED:
      return {
        ...state,
        seedsListLoaded: true,
        seedsList: action.payload.seeds,
        totalKeywordsCount: action.payload.totalKeywordsCount,
      };
    case FETCH_KEYWORDS.ON_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        searchText: action.searchText,
      };
    case FETCH_KEYWORDS.ON_SELECTED_SITE_CHANGED:
      return {
        ...state,
        selectedSite: action.selectedSite,
      };
    case FETCH_KEYWORDS.ON_CHECKED_BOX_CHANGED:
      return {
        ...state,
        checkedSeedsList: action.checkedSeedsList,
      };
    case FETCH_KEYWORDS.ON_SEED_CHANGED:
      return {
        ...state,
        seed: action.seed,
        seedsList: state.seedsList.map((seed) => {
          if (seed._id === action.seed._id) {
            return action.seed;
          }
          return seed;
        }),
      };
    case FETCH_KEYWORDS.ON_SEEDS_REMOVED:
      return {
        ...state,
        seedsList: state.seedsList.filter((seed) => !action.seeds.includes(seed)),
      };
    case FETCH_KEYWORDS.CLEAR_CHECKED_SEEDS:
      return {
        ...state,
        checkedSeedsList: [],
      };
    case FETCH_KEYWORDS.SET_API_BALANCE:
      return {
        ...state,
        balance: action.balance,
      };
    case FETCH_KEYWORDS.ON_FETCH_KEYWORDS_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
