import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Switch, Route, Redirect } from "react-router-dom";
import theme from "../../themes/theme";
import { web } from "../../helpers/urlHelper";
import Audiences from "./Audiences/Audiences";
import Experts from "./Experts/Experts";

const useStyles = makeStyles(() => ({
  pageWrapper: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    // flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  appBar: {
    zIndex: 3,
    display: "flex",
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    top: 52,
  },
  websitePickerWrapper: {
    width: "18%",
  },
  tabsWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "64%",
    marginLeft: 0,
  },
  tabsRoot: {
    minHeight: 40,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular as any,
  },
}));

type PropTypes = {};

const Admin = (props: PropTypes) => {
  const classes = useStyles(props);

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.content}>
        <Switch>
          {/*<Route exact={false} path={web.entailAudiences()} component={Audiences} />*/}
          <Route exact={false} path={web.entailExperts()} component={Experts} />
          <Redirect exact from={"*"} to={web.entailExperts()} />
        </Switch>
      </div>
    </div>
  );
};

export default Admin;
