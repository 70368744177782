import React from "react";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import ModalCloseIcon from "../../../icons/ModalCloseIcon";

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute" as "absolute",
    top: 15,
    right: 15,
    padding: 10,
    "&:hover": {
      "& $closeIcon": {
        fill: theme.palette.primary.main,
      },
    },
  },
  closeIcon: {},
}));

type propTypes = {
  customClass?: any;
  onClick: Function;
};

const ModalsCloseButton = (props: propTypes) => {
  const classes = useStyles(props);
  const { customClass, onClick } = props;

  return (
    <IconButton
      disableRipple
      onClick={(event) => onClick(event)}
      className={ClassNames(classes.closeButton, customClass)}
    >
      <ModalCloseIcon className={classes.closeIcon} />
    </IconButton>
  );
};

export default ModalsCloseButton;
