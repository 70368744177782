import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { IWebsiteHomepageProps, IWebsiteThemeProps } from "../../../../../reducers/constants/objectTypes";
import TextInputToolAndDesign from "../../../components/TextInputToolAndDesign";
import PageSection from "../../../components/PageSection";
import TextInputMentions from "../../../components/TextInputMentions";
import { LENGTHS } from "../../../../../reducers/constants/consts";
import BannersInput from "./BannersInput";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  productsTitle: {
    fontSize: 14,
    marginBottom: 20,
  },
}));

type PropTypes = {
  homepage: IWebsiteHomepageProps;
  theme: IWebsiteThemeProps;
  onPropertyChanged: (value) => void;
};

const TopSectionKeepshoppers = (props: PropTypes) => {
  const classes = useStyles(props);
  const { homepage, theme, onPropertyChanged } = props;
  const [topSectionKeepshoppersError, setTopSectionKeepshoppersError] = React.useState([]);

  const topSectionKeepshoppersValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!homepage?.metaTitleKeepshoppers) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.templates.keepshoppers.top_section.meta_title`));
    }
    if (!homepage?.metaDescriptionKeepshoppers) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.templates.keepshoppers.top_section.meta_description`));
    }
    if (!homepage?.topSectionKeepshoppers?.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.templates.keepshoppers.top_section.title`));
    }
    if (!homepage?.topSectionKeepshoppers?.description.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.templates.keepshoppers.top_section.description`));
    }
    if (!homepage?.topSectionKeepshoppers?.banners || homepage?.topSectionKeepshoppers?.banners.length === 0) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.templates.keepshoppers.top_section.banners`));
    }
    setTopSectionKeepshoppersError(emptyFields);

    return valid;
  };

  React.useEffect(() => {
    if (topSectionKeepshoppersError.length <= 0) {
      return;
    }
    topSectionKeepshoppersValidated();
  }, [homepage?.topSectionKeepshoppers, homepage?.metaTitleKeepshoppers, homepage?.metaDescriptionKeepshoppers]);

  return (
    <PageSection
      sectionTitle={I18n.t("website.homepage.templates.keepshoppers.top_section.top_section")}
      checked={homepage?.topSectionKeepshoppers?.enabled || false}
      onCheckedChange={(checked) => {
        if (!topSectionKeepshoppersValidated()) {
          if (!checked && homepage?.topSectionKeepshoppers?.enabled) {
            onPropertyChanged({
              ...homepage,
              topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, enabled: checked },
            });
          }
          return;
        }
        onPropertyChanged({
          ...homepage,
          topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, enabled: checked },
        });
      }}
    >
      {topSectionKeepshoppersError.length > 0 && (
        <div className={classes.errorWrapper}>
          <Typography variant={"body1"} className={classes.error}>
            {topSectionKeepshoppersError.length > 0 && I18n.t("website.error_message")}
          </Typography>
        </div>
      )}
      <TextInputToolAndDesign
        error={topSectionKeepshoppersError.includes(
          I18n.t(`website.homepage.templates.keepshoppers.top_section.title`)
        )}
        textFieldTitle={I18n.t("website.homepage.title")}
        text={homepage?.topSectionKeepshoppers?.title}
        handleTextChanged={(text) =>
          onPropertyChanged({
            ...homepage,
            topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, title: text },
          })
        }
        variant={"h1"}
      />
      <TextInputMentions
        error={topSectionKeepshoppersError.includes(I18n.t(`website.meta_title`))}
        tags={[]}
        placeholder={I18n.t("website.meta_title_ph")}
        title={I18n.t("website.meta_title")}
        maxLength={LENGTHS.META_TITLE}
        titleAndLengthLocation={"top"}
        text={homepage?.metaTitleKeepshoppers}
        onTextChanged={(value) => onPropertyChanged({ ...homepage, metaTitleKeepshoppers: value })}
      />
      <Divider className={classes.divider} />
      <TextInputToolAndDesign
        error={topSectionKeepshoppersError.includes(
          I18n.t(`website.homepage.templates.keepshoppers.top_section.description`)
        )}
        textFieldTitle={I18n.t("website.homepage.description")}
        text={homepage?.topSectionKeepshoppers?.description}
        handleTextChanged={(text) =>
          onPropertyChanged({
            ...homepage,
            topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, description: text },
          })
        }
        variant={"h2"}
      />
      <TextInputMentions
        error={topSectionKeepshoppersError.includes(I18n.t(`website.meta_description`))}
        tags={[]}
        placeholder={I18n.t("website.meta_description_ph")}
        title={I18n.t("website.meta_description")}
        maxLength={LENGTHS.META_DESCRIPTION}
        titleAndLengthLocation={"top"}
        text={homepage?.metaDescriptionKeepshoppers}
        onTextChanged={(value) => onPropertyChanged({ ...homepage, metaDescriptionKeepshoppers: value })}
      />
      <Divider className={classes.divider} />
      <BannersInput
        error={topSectionKeepshoppersError.includes(
          I18n.t(`website.homepage.templates.keepshoppers.top_section.banners`)
        )}
        homepage={homepage}
        theme={theme}
        onPropertyChanged={onPropertyChanged}
      />
    </PageSection>
  );
};

export default TopSectionKeepshoppers;
