import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { union } from "lodash";
import { useState } from "react";
import Chip from "@material-ui/core/Chip";
import { Theme } from "@material-ui/core";
import MTextField from "./MTextField";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: 10,
    width: "100%",
  },
  inputRoot: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
  input: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    padding: 5,
  },
  chipsWrapper: {
    marginTop: 5,
    "& > *": {
      marginTop: 5,
      marginRight: 5,
    },
  },
}));

type PropTypes = {
  chips: string[];
  onChipsChanged: (chips) => void;
};

const MChipsTextField = (props: PropTypes) => {
  const classes = useStyles(props);
  const { chips = [], onChipsChanged } = props;

  const [value, setValue] = useState("");
  return (
    <div className={classes.wrapper}>
      <MTextField
        outlined
        customClass={classes.inputRoot}
        inputClassName={classes.input}
        onChange={(text) => setValue(text)}
        value={value}
        height={45}
        fullWidth
        borderRadius={4}
        handleEnterClick={() => {
          onChipsChanged(union(chips, [value]));
          setValue("");
        }}
        placeholder={"Write and click Enter to add value..."}
      />
      <div className={classes.chipsWrapper}>
        {chips.map((chip, index) => (
          <Chip
            key={chip}
            label={chip}
            onDelete={() => {
              const newChips = [...chips];
              newChips.splice(index, 1);
              onChipsChanged(newChips);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default MChipsTextField;
