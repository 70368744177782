import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import {
  IWebsite,
  IWebsiteResourcePageProps,
  IWebsiteSectionProps,
  IWebsiteThemeProps,
} from "../../../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../../../reducers/constants/actionTypes";
import PageSection from "../../../../components/PageSection";
import PostSearchInput from "./PostSearchInput";
import TextInputToolAndDesign from "../../../../components/TextInputToolAndDesign";
import GradientBackgroundColorCSS from "../../../../../../editor/elementsStyles/CssComponents/GradientBackgroundColorCSS";
import ButtonTextAndUrlAndDesign from "../../../../components/ButtonTextAndUrlAndDesign";
import TextDesignToolWithPreview from "../../../../components/TextDesignToolWithPreview";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
    width: "100%",
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  idsColumns: {
    display: "flex",
    justifyContent: "space-between",
  },
  ids: {
    width: 300,
    marginBottom: 40,
  },
  selectTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 5,
  },
  select: {
    textTransform: "capitalize",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const mapStateToProps = (state) => ({
  resourcePage: state.websitePages.resourcePage,
  theme: state.websitePages.theme,
  sections: state.websitePages.sections,
  urlPrefix: state.websitePages.siteDetails.urlPrefix,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (resourcePage) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "resourcePage",
      value: resourcePage,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  theme: IWebsiteThemeProps;
  website: IWebsite;
  resourcePage: IWebsiteResourcePageProps;
  onPropertyChanged: (value) => void;
  handleToggleActive: (section, enabled) => void;
};

const FeaturedBanner = (props: PropTypes) => {
  const classes = useStyles(props);
  const { resourcePage, theme, website, handleToggleActive, onPropertyChanged } = props;
  const [error, setError] = React.useState([]);
  const { featuredBanner } = resourcePage;
  const textFieldsValidated = () => {
    const valid = true;
    const emptyFields = [];
    setError(emptyFields);
    return valid;
  };

  React.useEffect(() => {
    if (error.length <= 0) {
      return;
    }
    textFieldsValidated();
  }, []);

  return (
    <PageSection
      sectionTitle={I18n.t("website.resourcePage.featured_banner.name")}
      checked={featuredBanner.enabled}
      onCheckedChange={(checked) => {
        if (!textFieldsValidated()) {
          if (!checked && featuredBanner.enabled) {
            onPropertyChanged({
              ...resourcePage,
              featuredBanner: { ...featuredBanner, enabled: checked },
            });
          }
          return;
        }
        onPropertyChanged({
          ...resourcePage,
          featuredBanner: { ...featuredBanner, enabled: checked },
        });
      }}
    >
      {error.length > 0 && (
        <div className={classes.errorWrapper}>
          <Typography variant={"body1"} className={classes.error}>
            {I18n.t("website.error_message")}
          </Typography>
        </div>
      )}
      <PostSearchInput
        placeholder={I18n.t("website.resourcePage.featured_banner.post_ph")}
        title={I18n.t("website.resourcePage.featured_banner.post")}
        selectedWebsite={website}
        maxPages={1}
        marginBottom={30}
        pages={featuredBanner?.post?.slug ? [featuredBanner?.post] : []}
        handlePagesChange={(post) => {
          onPropertyChanged({
            ...resourcePage,
            featuredBanner: { ...featuredBanner, post },
          });
        }}
      />
      <div className={classes.container}>
        <TextInputToolAndDesign
          background={featuredBanner.background?.style}
          textFieldTitle={I18n.t("website.resourcePage.featured_banner.title")}
          text={featuredBanner.title}
          handleTextChanged={(value) =>
            onPropertyChanged({
              ...resourcePage,
              featuredBanner: { ...featuredBanner, title: value },
            })
          }
          variant={"h4"}
        />
        <GradientBackgroundColorCSS
          value={featuredBanner.background}
          title={I18n.t("website.resourcePage.featured_banner.background")}
          defaultValue={null}
          isValueChanged={featuredBanner.background !== null}
          handleChange={(value) =>
            onPropertyChanged({
              ...resourcePage,
              featuredBanner: { ...featuredBanner, background: value },
            })
          }
        />
        <TextDesignToolWithPreview
          background={featuredBanner.background?.style}
          topTitle
          disableHover
          marginLeft={"-30px"}
          marginRight={"-30px"}
          elementTitle={I18n.t("website.element_design", {
            element: I18n.t("website.resourcePage.featured_banner.post_title"),
          })}
          css={featuredBanner.postPage?.titleCss}
          variant={"h2"}
          exampleText={I18n.t("website.resourcePage.featured_banner.post_title")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...resourcePage,
              featuredBanner: {
                ...featuredBanner,
                postPage: { ...featuredBanner.postPage, titleCss: newCss },
              },
            });
          }}
        />
        <TextDesignToolWithPreview
          background={featuredBanner.background?.style}
          topTitle
          disableHover
          marginLeft={"-30px"}
          marginRight={"-30px"}
          elementTitle={I18n.t("website.element_design", {
            element: I18n.t("website.resourcePage.featured_banner.post_brief"),
          })}
          css={featuredBanner.postPage?.briefCSS}
          variant={"h4"}
          exampleText={I18n.t("website.resourcePage.featured_banner.post_brief")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({
              ...resourcePage,
              featuredBanner: {
                ...featuredBanner,
                postPage: { ...featuredBanner.postPage, briefCSS: newCss },
              },
            });
          }}
        />
        <ButtonTextAndUrlAndDesign
          toggleArrow
          background={featuredBanner.background?.style}
          textFieldTitle={I18n.t("website.resourcePage.featured_banner.button_text")}
          urlFieldTitle={I18n.t("website.resourcePage.featured_banner.button_url")}
          button={featuredBanner.button || { text: "", url: "", css: {}, arrow: false }}
          defaultButtonCss={theme?.buttons?.css}
          handleButtonChanged={(value) =>
            onPropertyChanged({
              ...resourcePage,
              featuredBanner: { ...featuredBanner, button: value },
            })
          }
        />
      </div>
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedBanner);
