import React, { useState } from "react";
import { makeStyles, Typography, Theme } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { I18n } from "react-redux-i18n";

import { WIDGET_BUILDER } from "../../../../../../../../reducers/constants/actionTypes";
import { IWidgetSettings } from "../../../../../../../../reducers/constants/objectTypes";
import useDebounce from "../../../../../../../../hooks/useDebounce";
import SelectProperty from "../../../build/items/itemProperties/components/SelectProperty";
import { colors } from "../../../../../../../../helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  fieldContainer: {
    backgroundColor: theme.palette.secondary.dark,
    padding: 15,
    borderRadius: 3,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.widgetOnHoverBackgroundColor,
      cursor: "pointer",
    },
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
  selectProperty: {
    width: "50%",
  },
}));

type PropTypes = {
  settings: IWidgetSettings;
  editSettings: (settings: IWidgetSettings) => void;
};

const mapStateToProps = (state) => ({
  settings: state.widgetBuilder.widget.settings,
});

const mapDispatchToProps = (dispatch) => ({
  editSettings: (payload: IWidgetSettings) => dispatch({ type: WIDGET_BUILDER.EDIT_SETTINGS, payload }),
});

const defaultLayoutValues: IWidgetSettings["layout"] = {
  alignment: "center",
};

const LayoutSettings = (props: PropTypes) => {
  const { settings } = props;
  const { editSettings } = props;
  const layoutProp = settings.layout ?? {};

  const [layout, setLayout] = useState({ ...defaultLayoutValues, ...layoutProp });

  const debouncedLayoutValue = useDebounce(layout, 500);

  React.useEffect(() => {
    if (!isEqual(settings.layout, debouncedLayoutValue)) {
      editSettings({ ...settings, layout: debouncedLayoutValue });
    }
  }, [debouncedLayoutValue, editSettings, settings]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.fieldContainer}>
        <Typography>{I18n.t("widgets_builder.widget_editor.Inline.settings.layout.alignment")}</Typography>
        <div className={classes.selectProperty}>
          <SelectProperty
            options={["left", "center", "right"]}
            onChange={(option: IWidgetSettings["layout"]["alignment"]) => setLayout({ ...layout, alignment: option })}
            value={layout.alignment}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSettings);
