import { Input, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { I18n } from "react-redux-i18n";
import { IProduct, IWebsite } from "../../../../reducers/constants/objectTypes";
import RatingStarIcon from "../../../../icons/RatingStarIcon";
import numeral from "numeral";
import ButtonWidgetBaseElement from "../baseElements/ButtonWidgetBaseElement";
import { find } from "lodash";
import VIcon from "../../../../icons/VIcon";
import { ProductCTAHorizontal, ProductCTAHorizontalData } from "../../../types/editor.Types";
import { Widget } from "../WithWidgets";
import ProductLogo from "../../../../components/ProductLogo";
import theme from "../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    borderRadius: 5,
    backgroundColor: (props: PropTypes) => props.website.configurations.theme.palette.secondary.main,
  },
  productName: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(16),
  },
  logoAndRatingWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  ratingWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  rating: {
    display: "flex",
    alignItems: "center",
  },
  ratingStarIcon: {
    marginRight: 6,
  },
  ratingInputRoot: {
    width: 50,
    marginRight: 10,
  },
  reviewsInputRoot: {
    width: 65,
    marginRight: 10,
  },
  numOfReviewsText: {
    fontSize: theme.typography.pxToRem(14),
  },
  ratingText: {
    fontSize: "16px",
    fontWeight: theme.typography.fontWeightRegular as any,
    justifyContent: "center",
  },
  shortDescriptionText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular as any,
    marginTop: 5,
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: ProductCTAHorizontal;
  website: IWebsite;
  products: IProduct[];

  readMode: boolean;
};

export const getRawText = (n: Node) => {
  return "";
};

const ProductCTAHorizontalWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, website, products = [], readMode = false } = props;
  const editor = useSlateStatic();
  const websiteTheme = website.configurations.theme;
  const handleChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  const defaultData: ProductCTAHorizontalData = {
    uuid: null,
    logo: {
      show: true,
    },
    productName: {
      show: true,
      css: {
        margin: "0px",
      },
    },
    rating: {
      show: true,
    },
    shortDescription: {
      show: false,
      css: {},
    },
    bullets: {
      show: false,
      css: {},
    },
    button: {
      show: true,
      css: {},
      showArrow: true,
    },
    product: {
      itemNumber: null,
    },
    element: {
      css: {},
    },
  };

  React.useEffect(() => {
    handleChange({ ...defaultData, ...element.data });
  }, []);

  console.log("ProductCTAHorizontalElement", element);
  return (
    <div {...attributes}>
      <Widget
        readMode={readMode}
        title={I18n.t("rich_text_editor.custom_components.product_cta_horizontal")}
        element={element}
        editable
      >
        <div className={classes.wrap} style={element.data?.element?.css}>
          <div style={{ width: "70%" }}>
            <div className={classes.logoAndRatingWrapper}>
              {element.data.logo?.show && (
                <ProductLogo marginRight={16} showBorder={false} width={50} borderRadius={25} product={product} />
              )}
              <div className={classes.ratingWrapper}>
                {element.data.productName?.show && (
                  <Typography className={classes.productName} variant={"h3"} style={element.data?.productName?.css}>
                    {product?.displayName || product?.name || ""}
                  </Typography>
                )}
                {element.data.rating?.show && (
                  <div className={classes.rating}>
                    <RatingStarIcon active className={classes.ratingStarIcon} />
                    {/*<Rating value={parseInt(product?.rating || "0")} precision={0.5} readOnly={true} />*/}
                    <Typography className={classes.ratingText} variant={"body2"}>
                      <span>{`${numeral(product?.rating || 0).format("0.0")}/5`}</span>
                      <span style={{ marginLeft: 5 }}>{`(${I18n.t("rich_text_editor.call_to_action.reviews", {
                        count: product?.numOfReviews,
                      })})`}</span>
                    </Typography>
                  </div>
                )}
                {element.data.shortDescription?.show && product?.shortDescription && (
                  <Typography
                    className={classes.shortDescriptionText}
                    style={element.data.shortDescription.css}
                    variant={"body2"}
                  >
                    {product.shortDescription}
                  </Typography>
                )}
              </div>
            </div>
            {element.data.bullets?.show && product?.bullets?.length > 0 && (
              <div style={{ marginLeft: element.data.logo.show ? 66 : 0 }}>
                {product?.bullets.map((bullet) => (
                  <div
                    key={bullet}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                      margin: "5px 0px 0px 0px",
                      fontSize: 14,
                      fontWeight: theme.typography.fontWeightRegular as any,
                      ...element.data.bullets.css,
                    }}
                  >
                    <VIcon style={{ height: 8, width: 11, fill: websiteTheme.palette.primary.main, marginRight: 4 }} />
                    <span>{bullet}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          {element.data.button?.show && (
            <ButtonWidgetBaseElement
              text={
                product?.callToAction || I18n.t("rich_text_editor.choose_product_to_add", { field: "Call To Action" })
              }
              url={product?.callToActionURL || I18n.t("rich_text_editor.choose_product_to_add", { field: "URL" })}
              css={element.data.button.css}
              hoverCss={element.data.button.css?.["&:hover"] || {}}
              showArrow={element.data?.button.showArrow || false}
              readMode={false}
              websiteTheme={websiteTheme}
            />
          )}
        </div>
        <div style={{ display: "none" }}>{children}</div>
        {/*{children}*/}
      </Widget>
    </div>
  );
};

export default ProductCTAHorizontalWidget;
