import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { find } from "lodash";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { Theme } from "@material-ui/core";

import MSelect from "../../../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  title: {
    color: (props: PropTypes) => props.titleColor || theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 10,
    whiteSpace: "pre",
  },
  experienceWrapper: {
    width: 200,
  },
}));

type PropTypes = {
  title?: string;
  titleColor?: string;
  experience: number;

  handleExperienceChange: (experience: number) => void;
};

const ExpertExperience = (props: PropTypes) => {
  const classes = useStyles(props);
  const { experience, title } = props;
  const { handleExperienceChange } = props;

  const experienceInYears = React.useMemo(
    () =>
      Array.from(Array(30).keys()).map((key) => ({
        value: key,
        key: I18n.t("experts.profile.years_of_experience", { count: key }),
      })),
    []
  );

  return (
    <div className={classes.experienceWrapper}>
      {title && (
        <Typography className={ClassNames(classes.title)} variant={"subtitle2"}>
          {title}
        </Typography>
      )}
      <MSelect
        customClass={classes.wrapper}
        fullWidth
        options={experienceInYears}
        value={experience !== null ? find(experienceInYears, { value: experience }) : null}
        optionLabel={"key"}
        optionValue={"key"}
        getDisplayLabel={(volume) =>
          I18n.t(`entail.audiences_details.appearance_section.search_volumes.${volume.key.toLowerCase()}`)
        }
        borderWidth={1}
        height={38}
        isBold={false}
        placeholder={"Select"}
        handleChange={(item) => {
          handleExperienceChange(item.value);
        }}
      />
    </div>
  );
};

export default ExpertExperience;
