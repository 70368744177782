import * as React from "react";
import { Path, Node } from "slate";
import { connect } from "react-redux";
import { ReactEditor, useFocused, useSelected, useSlate } from "slate-react";
import { useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { IPage, IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { COMMUNITY } from "../../../../reducers/constants/actionTypes";
import { DEV } from "../../../../reducers/constants/consts";
import ProductEmbedWidgetStyle from "../../../../editor/elements/widgets/productEmbed/ProductEmbedWidgetStyle";
import { editorType } from "../../../../editor/types/editor.Types";
import ProductCTAWidgetStyle from "../../../../editor/elements/widgets/productCTAElement/ProductCTAWidgetStyle";
import ProductCTAHorizontalWidgetStyle from "../../../../editor/elements/widgets/productCTAHorizontal/ProductCTAHorizontalWidgetStyle";
import CharticleTopProductsWidgetStyle from "../../../../editor/elements/widgets/charticleTopProductsList/CharticleTopProductsWidgetStyle";
import ProductDetailsWidgetStyle from "../../../../editor/elements/widgets/productDetails/ProductDetailsWidgetStyle";
import ButtonWidgetStyle from "../../../../editor/elements/widgets/button/ButtonWidgetStyle";
import DividerStyle from "../../../../editor/elements/divider/DividerStyle";
import TextBoxElementStyle from "../../../../editor/elements/textBox/TextBoxElementStyle";
import BulletedListStyle from "../../../../editor/elements/bulletedList/BulletedListStyle";
import ImageStyle from "../../../../editor/elements/image/ImageStyle";
import LayoutStyle from "../../../../editor/elements/layouts/LayoutStyle";
import DocumentStyle from "../../../../editor/elements/document/DocumentStyle";
import ComparisonTableWidgetStyle from "../../../../editor/elements/widgets/comparisonTable/ComparisonTableWidgetStyle";
import PricingTableWidgetStyle from "../../../../editor/elements/widgets/pricingTable/PricingTableWidgetStyle";
import CharticleTopProductWidgetStyle from "../../../../editor/elements/widgets/charticleTopProduct/CharticleTopProductWidgetStyle";
import ProductReviewWidgetStyle from "../../../../editor/elements/widgets/productReview/ProductReviewWidgetStyle";
import EntailWidgetStyle from "../../../../editor/elements/entailWidgets/EntailWidgetStyle";
import EmbedWidgetStyle from "../../../../editor/elements/widgets/embed/EmbedWidgetStyle";
import InlineHtmlElementStyle from "../../../../editor/elements/inlineHTML/InlineHtmlElementStyle";
import ProductReviewWidgetStyleEditor from "../../../../editor/elements/widgets/productReviewWidget/ProductReviewWidgetStyleEditor";
import FAQWidgetStyle from "../../../../editor/elements/widgets/faqWidget/FAQWidgetStyle";
import BlockQuoteElementStyle from "../../../../editor/elements/blockQuote/BlockQuoteElementStyle";
import { emitEvent } from "../../../../hooks/useEvents";

type PropTypes = {
  selectedWebsite: IWebsite;
  editor: ReactEditor;
  editorId: string;
  focusedEditorId: string;
  page?: IPage;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
  setFocusedEditorId: (focusedEditorId: string) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  page: state.pageEditor.page,
  focusedEditorId: state.community.focusedEditorId,
});

const mapDispatchToProps = (dispatch) => ({
  setFocusedEditorId: (focusedEditorId) => dispatch({ type: COMMUNITY.SET_FOCUSED_EDITOR_ID, focusedEditorId }),
});

const getDesignableNode = (editor, path) => {
  const pathClone = Path.parent(path);
  const node = Node.get(editor, pathClone);
  if (Node.isNode(node) && !["paragraph", "list-item", "layout-area"].includes(node.type)) {
    return node;
  }
  return getDesignableNode(editor, pathClone);
};

const PageEditorElementStyle = (props: PropTypes) => {
  const { selectedWebsite, editorId, focusedEditorId, setFocusedEditorId, page } = props;
  const { products, handleProductsChange } = props;
  const editor = useSlate();
  const { selection } = editor;
  const selected = useSelected();
  const focused = useFocused();
  const websiteTheme: IWebsiteThemeProps = selectedWebsite.configurations.theme;
  const theme = useTheme<Theme>();
  // Support multiple editors in one screen like in the community.
  React.useEffect(() => {
    if (focused) {
      setFocusedEditorId(editorId);
    }
  }, [focused]);

  if (focusedEditorId !== editorId) {
    return null;
  }
  //

  if (!selection) {
    return <DocumentStyle selectedWebsite={selectedWebsite} editor={editor} page={page} />;
  }

  const element = getDesignableNode(editor, selection.anchor.path);

  const renderElementDesign = () => {
    switch (element.type) {
      case editorType.productEmbed:
        return (
          <ProductEmbedWidgetStyle
            products={products}
            handleProductsChange={handleProductsChange}
            editor={editor}
            websiteTheme={websiteTheme}
            selectedWebsite={selectedWebsite}
            element={element}
          />
        );
      case editorType.productCta:
        return (
          <ProductCTAWidgetStyle
            products={products}
            handleProductsChange={handleProductsChange}
            editor={editor}
            websiteTheme={websiteTheme}
            selectedWebsite={selectedWebsite}
            element={element}
          />
        );
      case editorType.productCtaHorizontal:
        return (
          <ProductCTAHorizontalWidgetStyle
            products={products}
            handleProductsChange={handleProductsChange}
            editor={editor}
            websiteTheme={websiteTheme}
            selectedWebsite={selectedWebsite}
            element={element}
          />
        );
      case editorType.charticleTopProduct:
        return (
          <CharticleTopProductWidgetStyle
            products={products}
            handleProductsChange={handleProductsChange}
            editor={editor}
            selectedWebsite={selectedWebsite}
            websiteTheme={websiteTheme}
            element={element}
          />
        );
      case editorType.charticleTopProducts:
        return (
          <CharticleTopProductsWidgetStyle
            products={products}
            handleProductsChange={handleProductsChange}
            editor={editor}
            selectedWebsite={selectedWebsite}
            websiteTheme={websiteTheme}
            element={element}
          />
        );
      case editorType.callToAction:
        return <div>{"Deprecated: Please replace with product cta - horizontal"}</div>;
      case editorType.productDetails:
        return (
          <ProductDetailsWidgetStyle
            products={products}
            handleProductsChange={handleProductsChange}
            editor={editor}
            websiteTheme={websiteTheme}
            selectedWebsite={selectedWebsite}
            element={element}
          />
        );
      case editorType.inlineHtml:
        return <InlineHtmlElementStyle element={element} editor={editor} />;
      case editorType.button:
        return (
          <ButtonWidgetStyle
            selectedWebsite={selectedWebsite}
            products={products}
            handleProductsChange={handleProductsChange}
            editor={editor}
            websiteTheme={websiteTheme}
            element={element}
          />
        );
      case editorType.embed:
        return <EmbedWidgetStyle editor={editor} element={element} />;
      case editorType.faq:
        // return <FAQWidgetStyle editor={editor} websiteTheme={websiteTheme} element={element} />;
        return <FAQWidgetStyle editor={editor} element={element} />;
      case editorType.comparisonTable:
        return (
          <ComparisonTableWidgetStyle
            products={products}
            handleProductsChange={handleProductsChange}
            editor={editor}
            websiteTheme={websiteTheme}
            selectedWebsite={selectedWebsite}
            element={element}
          />
        );
      case editorType.pricingTable:
        return (
          <PricingTableWidgetStyle
            products={products}
            handleProductsChange={handleProductsChange}
            editor={editor}
            websiteTheme={websiteTheme}
            selectedWebsite={selectedWebsite}
            element={element}
          />
        );
      case editorType.image:
        return <ImageStyle editor={editor} websiteTheme={websiteTheme} element={element} />;
      case editorType.layout:
        return <LayoutStyle editor={editor} selectedWebsite={selectedWebsite} element={element} />;
      case editorType.bulletedList:
        return <BulletedListStyle editor={editor} websiteTheme={websiteTheme} element={element} />;
      case editorType.textBox:
        return <TextBoxElementStyle editor={editor} websiteTheme={websiteTheme} element={element} />;
      case editorType.divider:
        return <DividerStyle editor={editor} element={element} websiteTheme={websiteTheme} />;
      case editorType.entailWidget:
        return <EntailWidgetStyle editor={editor} selectedWebsite={selectedWebsite} element={element} />;
      case editorType.productReview:
        return (
          <ProductReviewWidgetStyle
            element={element}
            products={products}
            handleProductsChange={handleProductsChange}
            selectedWebsite={selectedWebsite}
            editor={editor}
            websiteTheme={websiteTheme}
          />
        );
      case editorType.blockQuote:
        return <BlockQuoteElementStyle editor={editor} element={element} />;
      default:
        return <DocumentStyle selectedWebsite={selectedWebsite} editor={editor} page={page} />;
    }
  };

  return (
    <>
      {renderElementDesign()}
      {DEV && (
        <div
          style={{
            whiteSpace: "pre-line",
            overflowWrap: "break-word",
            margin: "20px 0px",
            color: theme.palette.text.primary,
          }}
        >
          {JSON.stringify(selection.anchor)}
          <br />
          {JSON.stringify(editor.children[selection.anchor.path[0]])}
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorElementStyle);
