import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import classNames from "classnames";
import numeral from "numeral";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: 120,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    borderRight: `solid 1px ${theme.palette.divider}`,
    paddingTop: 20,
    "&:hover": {
      backgroundColor: "#FFFBE8",
    },
  },
  date: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    marginBottom: 20,
  },
  titles: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  range: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  red: {
    color: "#BE1D1D",
  },
  green: {
    color: "#009E10",
  },
}));

type PropTypes = {
  queriesView: boolean;
  data: any;
  labels: any[];
  dataSet: "impressions" | "clicks" | "positions";
  previousData: any;
  index: number;
};

const PositionsTableRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const { queriesView, dataSet, labels, data, previousData, index } = props;

  let total = 0;
  let totalPrevious = 0;
  labels.map((label) => {
    total += data[dataSet][label] || 0;
    totalPrevious += previousData[dataSet][label] || 0;
  });

  // const total = data[dataSet]['0-3'] + data[dataSet]['4-10'] + data[dataSet]['11-20'] + data[dataSet]['21-50'] + data[dataSet]['51-100'];
  // const totalPrevious = previousData[dataSet]['0-3'] + previousData[dataSet]['4-10'] + previousData[dataSet]['11-20'] + previousData[dataSet]['21-50'] + previousData[dataSet]['51-100'];
  const totalTrend = total / totalPrevious - 1;
  return (
    <div className={classes.wrapper}>
      <Typography variant={"subtitle2"} className={classes.date}>
        {moment(data.date, "YYYY-MM-DD").format("MMM YY")}
      </Typography>
      <Grid container>
        <Grid item container className={classes.titles}>
          <Grid item xs={6}>
            {queriesView ? "Queries" : "Pages"}
          </Grid>
          <Grid item xs={6}>
            {"Change"}
          </Grid>
        </Grid>
        {labels.map((range) => {
          let trend = 0;
          if (index === 0) {
            trend = 0;
          } else if (data[dataSet][range] && !previousData[dataSet][range]) {
            trend = data[dataSet][range];
          } else if (!data[dataSet][range] && previousData[dataSet][range]) {
            trend = -previousData[dataSet][range];
          } else if (data[dataSet][range] && previousData[dataSet][range]) {
            trend = (data[dataSet][range] / previousData[dataSet][range] || 0) - 1;
          }
          return (
            <Grid key={range} item container className={classes.range}>
              <Grid item xs={6}>
                {numeral(data[dataSet][range]).format("0,0[.]")}
              </Grid>
              <Grid item xs={6} className={classNames(trend > 0 && classes.green, trend < 0 && classes.red)}>
                {numeral(trend).format("0 %")}
              </Grid>
            </Grid>
          );
        })}
        <Grid item container className={classes.range}>
          <Grid item xs={6}>
            {numeral(total).format("0,0[.]")}
          </Grid>
          <Grid item xs={6} className={classNames(totalTrend > 0 && classes.green, totalTrend < 0 && classes.red)}>
            {numeral(totalTrend).format("0 %")}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PositionsTableRow;
