import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import MTable from "../../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import MTableRow from "../../../../../components/Table/Rows/MTableRow";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";
import MTableCellDate from "../../../../../components/Table/Cells/MTableCellDate";
import MTableCellFirst from "../../../../../components/Table/Cells/MTableCellFirst";
import MTableCellStatus from "../../../../../components/Table/Cells/MTableCellStatus";

type PropTypes = {
  selectedWebsite: IWebsite;
  refetch: boolean;
  activeJob: string;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  activeJob: state.strategySettings.activeJob,
});

const JobRunsList = (props: PropTypes) => {
  const { selectedWebsite, refetch, activeJob } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [jobRuns, setJobRuns] = useState([]);

  useEffect(() => {
    agent.Airflow.getKeywordGroupDagRuns(selectedWebsite._id, activeJob)
      .then((res) => {
        setJobRuns(res.data.dag_runs);
      })
      .catch((e) => {
        enqueueSnackbar(e.response?.body?.message || "");
      });
  }, [refetch, activeJob]);

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabel columnWidth={"33%"}>{I18n.t("strategy.settings.job_id")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("strategy.settings.start_time")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("strategy.settings.end_time")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("strategy.settings.duration")}</MTableHeaderLabel>
        <MTableHeaderLabel>{I18n.t("strategy.settings.status")}</MTableHeaderLabel>
      </MTableHeader>

      {jobRuns.map((item, index) => {
        let diff = null;
        if (item.start_date && item.end_date) {
          const startTime = moment(item.start_date);
          diff = Math.abs(startTime.diff(moment(item.end_date), "minutes", true)).toFixed(2);
        }
        return (
          <MTableRow key={`${item.dag_id}${index + 1}`} handleRowClick={() => {}} fullBorderWidth>
            <MTableCellFirst cellText={item.dag_id} columnWidth={"33%"} />
            <MTableCellDate date={item.start_date} dateFormat={"DD.MM.YYYY HH:mm"} />
            <MTableCellDate date={item.end_date} dateFormat={"DD.MM.YYYY HH:mm"} />
            <MTableCellText text={`${diff ? `${diff} minutes` : "-"}`} />
            <MTableCellStatus status={item.state} />
          </MTableRow>
        );
      })}
    </MTable>
  );
};

export default connect(mapStateToProps, null)(JobRunsList);
