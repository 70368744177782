import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/styles";
import { ReactEditor, useSlateStatic } from "slate-react";
import { I18n } from "react-redux-i18n";
import { Transforms } from "slate";
import inlineHtml from "./inlineHtml";
import MTextArea from "../../../components/MTextArea";
import { Widget } from "../widgets/WithWidgets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {},
    focused: {
      boxShadow: "0 0 0 3px #B4D5FF",
    },
    imageDetailsWrapper: {
      display: "flex",
      flexDirection: "row",
    },
    imageSizeWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    sizeField: {},
    bold: {
      fontWeight: theme.typography.fontWeightBold as any,
    },
    imageWrapper: {
      display: "flex",
      position: "relative",
    },
    floatingBoard: {
      position: "absolute",
      right: 0,
      left: 0,
      background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%);",
    },
    textAreaWrapper: {
      display: "flex",
      flexDirection: "column",
      marginTop: 10,
    },
    textArea: {
      width: "100%",
      resize: "none",
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 5,
      padding: 10,
      fontFamily: "monospace",
    },
    previewDiv: {
      width: "100%",
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 5,
      padding: 10,
    },
    buttonsWrapper: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 8,
    },
    button: {
      marginRight: 20,
      fill: theme.palette.text.secondary,
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
    icon: {
      fontSize: 20,
      fill: theme.palette.text.secondary,
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
    activeIcon: {
      fill: theme.palette.primary.main,
    },
    deleteIcon: {
      fontSize: 16,
    },
  })
);

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
};

const InlineHtmlElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element } = props;
  const editor = useSlateStatic();

  return (
    <div {...attributes}>
      <Widget
        title={I18n.t("rich_text_editor.tooltips.inline_html")}
        element={element}
        contentEditable={false}
        editable
        handleDeleteClick={() => {
          const path = ReactEditor.findPath(editor as ReactEditor, element);
          Transforms.removeNodes(editor, { at: path });
        }}
      >
        <div style={{ display: "none" }}>{children}</div>
        <div className={classes.textAreaWrapper}>
          <div style={{ width: "100%" }}>
            <MTextArea
              minRows={5}
              className={classes.textArea}
              text={element.html}
              placeholder={"Enter HTML..."}
              onChange={(value) => inlineHtml.updateInlineHtml(editor, element.uuid, value)}
            />
          </div>
        </div>
      </Widget>
    </div>
  );
};

export default InlineHtmlElement;
