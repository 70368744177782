import React from "react";
import { Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClassNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative" as "relative",
  },

  button: {
    textTransform: "capitalize" as "capitalize",
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "column" as "column",
    minHeight: 45,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(12),
    paddingRight: 20,
    paddingLeft: 20,
    fontWeight: theme.typography.fontWeightBold as any,
    backgroundColor: "transparent",

    transition: "all .2s ease-in-out",
    webkitTransition: "all .2s ease-in-out",

    "&:hover": {
      backgroundColor: "transparent",
      // transform: 'scale(1.1)',
      textDecoration: "underline",
    },
    "&:disabled": {
      color: theme.palette.text.disabled,
      backgroundColor: "transparent",
    },
  },
  fullWidth: {
    width: "100%",
  },
  progressBarWrapper: {
    minWidth: 40,
    position: "relative",
    paddingTop: 5,
    paddingBottom: 5,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    marginTop: -15,
    marginLeft: -15,
  },
}));

type PropTypes = {
  text: string;

  gray?: boolean;
  loading?: boolean;
  onClick?: Function;
  disabled?: boolean;
  navLink?: boolean;
  href?: string;
  target?: string;
  customClass?: any;
  component?: any;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
};

const MTextButton = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    disabled,
    onClick,
    component,
    href,
    target = "_self",
    text,
    loading = false,
    navLink = false,
    customClass,
    fullWidth = false,
    startIcon,
  } = props;

  const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  return (
    <div className={ClassNames(classes.wrapper, fullWidth && classes.fullWidth)}>
      {!loading && (
        <Button
          disableRipple
          className={ClassNames(classes.button, fullWidth && classes.fullWidth, customClass || null)}
          disabled={disabled || loading}
          onClick={(event) => onClick(event)}
          component={navLink ? Link : "button"}
          target={target}
          href={href}
          to={href}
          startIcon={startIcon}
        >
          {text}
        </Button>
      )}
      {loading && (
        <div className={ClassNames(classes.progressBarWrapper, customClass)}>
          <CircularProgress size={30} className={classes.buttonProgress} />
        </div>
      )}
    </div>
  );
};

export default MTextButton;
