import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  flexRender,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { List, ListSubheader, TableSortLabel } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import { getWebsiteRelativeUrl } from "../../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowX: "auto",
    height: "100%",
  },

  subHeader: {
    display: "flex",
    top: 0,
    position: "sticky",
    backgroundColor: theme.palette.common.white,
    padding: "0px 0 10px 0",
    alignItems: "center",
  },
  root: {
    paddingTop: 0,
  },
  firstColumn: {
    color: theme.palette.text.primary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginRight: 15,
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightLight as any,
    width: (tableProps: AnalyticsTableProps<any>) => tableProps.style?.firstColumnWidth || "50%",
    flex: "0 0 auto",
  },
  firstHeaderColumn: {
    alignItems: "center",
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
    marginRight: 15,
    width: (tableProps: AnalyticsTableProps<any>) => tableProps.style?.firstColumnWidth || "50%",
    flex: "0 0 auto",
  },
  tableRow: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0 10px 0",
  },
  secondColumn: {
    color: theme.palette.text.primary,
    width: (tableProps: AnalyticsTableProps<any>) => tableProps.style?.secondColumnWidth || "12%",

    fontSize: "14px",
    fontWeight: theme.typography.fontWeightLight as any,
    flexGrow: 1,
    whiteSpace: "nowrap",
    flex: "0 0 auto",

    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  secondHeaderColumn: {
    color: theme.palette.text.primary,
    width: (tableProps: AnalyticsTableProps<any>) => tableProps.style?.secondColumnWidth || "12%",
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: "0 0 auto",
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.main,
    },
  },
}));

interface AnalyticsTableProps<T> {
  selectedWebsite: IWebsite;
  columns: ColumnDef<T>[];
  data: T[];
  pagination: {
    pageSize: number;
    pageIndex: number;
  };
  style?: {
    firstColumnWidth?: string;
    secondColumnWidth?: string;
  };
}

function AnalyticsTable<T>(props: AnalyticsTableProps<T>) {
  const classes = useStyles(props);

  const { columns, data, pagination } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable<T>({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return (
    <div className={classes.tableWrapper}>
      <List aria-labelledby="nested-list-subheader" className={classes.root}>
        <ListSubheader className={classes.subHeader}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header, index) => (
              <TableSortLabel
                key={`${header.id}_${index + 1}`}
                disabled={!header.column.getCanSort()}
                className={index === 0 ? classes.firstHeaderColumn : classes.secondHeaderColumn}
                active={Boolean(header.column.getIsSorted())}
                direction={
                  (header.column.getIsSorted() ? String(header.column.getIsSorted()) : "desc") as "asc" | "desc"
                }
                onClick={header.column.getToggleSortingHandler()}
              >
                {header.isPlaceholder ? null : (
                  <div>{flexRender(header.column.columnDef.header, header.getContext())} </div>
                )}
              </TableSortLabel>
            ))
          )}
        </ListSubheader>
        {table.getRowModel().rows.map((row, i) => (
          <ListItem key={`${row.id}_${i + 1}`} className={classes.tableRow}>
            {row.getVisibleCells().map((cell, index) => (
              <div
                key={`${cell.id}_${index + 1}_${i + 1}`}
                className={index === 0 ? classes.firstColumn : classes.secondColumn}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            ))}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export const AnalyticsTableCellLink = (props: any) => {
  const classes = useStyles(props);
  if (!props.url) return null;
  return (
    <Tooltip title={props.url} placement={"bottom-start"}>
      <a className={classes.link} href={props.url} target={"_blank"}>
        {getWebsiteRelativeUrl(props.url, props.selectedWebsite)}
      </a>
    </Tooltip>
  );
};

export default AnalyticsTable;
