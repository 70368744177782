import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#fff",
    fontSize: 16,
  },
}));

type PropTypes = {
  className?: string;
};

const LinkIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 18 9"}>
      <path d="M13.5859 0H11.0359C10.5684 0 10.1859 0.405 10.1859 0.9C10.1859 1.395 10.5684 1.8 11.0359 1.8H13.5859C14.9884 1.8 16.1359 3.015 16.1359 4.5C16.1359 5.985 14.9884 7.2 13.5859 7.2H11.0359C10.5684 7.2 10.1859 7.605 10.1859 8.1C10.1859 8.595 10.5684 9 11.0359 9H13.5859C15.9319 9 17.8359 6.984 17.8359 4.5C17.8359 2.016 15.9319 0 13.5859 0ZM5.93594 4.5C5.93594 4.995 6.31844 5.4 6.78594 5.4H11.8859C12.3534 5.4 12.7359 4.995 12.7359 4.5C12.7359 4.005 12.3534 3.6 11.8859 3.6H6.78594C6.31844 3.6 5.93594 4.005 5.93594 4.5ZM7.63594 7.2H5.08594C3.68344 7.2 2.53594 5.985 2.53594 4.5C2.53594 3.015 3.68344 1.8 5.08594 1.8H7.63594C8.10344 1.8 8.48594 1.395 8.48594 0.9C8.48594 0.405 8.10344 0 7.63594 0H5.08594C2.73994 0 0.835938 2.016 0.835938 4.5C0.835938 6.984 2.73994 9 5.08594 9H7.63594C8.10344 9 8.48594 8.595 8.48594 8.1C8.48594 7.605 8.10344 7.2 7.63594 7.2Z" />
    </SvgIcon>
  );
};

export default LinkIcon;
