import React from "react";
import { eModeratorAction, IQuestion } from "../../../../../../reducers/constants/objectTypes";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { makeStyles, Theme, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { COMMUNITY, COMMUNITY_EDITOR } from "../../../../../../reducers/constants/actionTypes";
import agent from "../../../../../../agent";
import { useSnackbar } from "notistack";
import MButton from "../../../../../../components/MButton";
import classNames from "classnames";
import { goBack, replace } from "connected-react-router";
import { useParams } from "react-router";
import { web } from "../../../../../../helpers/urlHelper";

type PropTypes = {
  handleQuestionChange: (question) => void;
  selectedQuestion: IQuestion;
  returnToPrevious: Function;
};

const mapStateToProps = (state) => ({
  selectedQuestion: state.communityEditor.question,
});

const mapDispatchToProps = (dispatch) => ({
  handleQuestionChange: (question) => dispatch({ type: COMMUNITY_EDITOR.COMMUNITY_QUESTION_CHANGED, question }),
  returnToPrevious: (returnTo) => dispatch(replace(returnTo)),
});

const useStyles = makeStyles((theme: Theme) => ({
  moderatorWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // height: 46,
    padding: "10px 20px",
    borderTop: "1px solid #E5E5E5",
  },
  button: {
    width: "calc(50% - 10px)",
    borderRadius: 75,
    height: 40,
  },
  publishButton: {
    backgroundColor: "#4353FF",
  },
  declineButton: {
    backgroundColor: "#808080",
    "&:hover": {
      backgroundColor: "#6e6d6d",
    },
  },
}));

const CommunityModeratorPanel = (props: PropTypes) => {
  const classes = useStyles(props);

  const { selectedQuestion, handleQuestionChange, returnToPrevious } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { type } = useParams();
  const moderateQuestion = (action: eModeratorAction) => {
    agent.Community.moderateQuestion(selectedQuestion, action)
      .then((res) => {
        handleQuestionChange(res.question);
        const translateKey = action === eModeratorAction.APPROVE ? "question_is_approved" : "question_is_declined";
        enqueueSnackbar(I18n.t(`community.moderator.${translateKey}`));
        returnToPrevious(web.community(type));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className={classes.moderatorWrapper}>
      <MButton
        customClassNames={classNames(classes.button, classes.publishButton)}
        onClick={() => moderateQuestion(eModeratorAction.APPROVE)}
      >
        <Typography>{I18n.t("community.moderator.publish")}</Typography>
      </MButton>

      <MButton
        customClassNames={classNames(classes.button, classes.declineButton)}
        onClick={() => moderateQuestion(eModeratorAction.DECLINE)}
      >
        <Typography>{I18n.t("community.moderator.delete")}</Typography>
      </MButton>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityModeratorPanel);
