import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import numeral from "numeral";
import Tooltip from "@material-ui/core/Tooltip";
import { I18n } from "react-redux-i18n";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Search from "@material-ui/icons/Search";
import { getOpportunityPageUrl, getPageSearchConsoleUrl } from "../../../../../../helpers/urlHelper";
import CopyNameIcon from "../../../../../../icons/CopyNameIcon";
import CopyToClipboardIcon from "../../../../../../icons/CopyToClipboardIcon";
import OpenInEditor from "../../../../../../icons/OpenInEditor";

const useStyles = makeStyles((theme: Theme) => ({
  group: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      transition: "border-radius 500ms",
      "& $actionsWrapper": {
        display: "flex",
      },
      position: "relative",
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actionsWrapper: {
    gap: 20,
    alignItems: "center",
    display: "none",
    position: "absolute",
    zIndex: 2,
    left: "40%",
    width: "fit-content",
    backgroundColor: theme.palette.secondary.main,
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionIcon: {
    height: 18,
    fill: theme.palette.text.secondary,
  },
  iconBtn: {
    padding: 0,
  },
  metric: {
    fontSize: "14px",
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: "100%",
    width: "10%",
  },
  trafficMetric: {
    marginRight: "5px",
    fontSize: "14px",
  },
  trafficMetricLeft: {
    marginLeft: "5px",
    fontSize: "14px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  number: {
    width: 20,
    height: 20,
    backgroundColor: "lightblue",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    fontWeight: theme.typography.fontWeightBold as any,
    fontsize: 14,
    lineHeight: "16.8px",
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    padding: "20px 30px",
    justifyContent: "space-between",
    fontSize: "14px",
  },
  listItemFirstColumn: {
    width: "55%",
  },
  firstColumn: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: "14px",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "color .2s ease-in-out",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  postTitleField: {
    color: theme.palette.text.secondary,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: "1.5rem",
    height: "1.5rem",
    fontFamily: theme.typography.body2.fontFamily,
    cursor: "initial",
  },
}));

type PropTypes = {
  page: any;
  openPost: (pageId: string, currentUrl?: string) => void;
};

const PagesDialogListItem = (props: PropTypes) => {
  const { page, openPost } = props;
  const classes = useStyles(props);

  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return (type) => {
      if (page.page) {
        navigator.clipboard.writeText(type === "page" ? page.page : page.metadata?.title);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();

  return (
    <div className={classes.group}>
      <ListItem className={classNames(classes.listRow)}>
        <div
          className={classNames(classes.metric, classes.listItemFirstColumn)}
          onClick={() => {
            window.open(page.page, "_blank");
          }}
        >
          <Tooltip title={I18n.t("strategy.outlinePages.open_in_tab")} placement={"bottom-start"}>
            <Typography className={classes.firstColumn}>
              {page.metadata?.title ? page.metadata?.title : getOpportunityPageUrl(page.page, true)}
            </Typography>
          </Tooltip>
        </div>
        <div className={classes.metric}>
          <Typography>{numeral(page.clicks || 0).format("0,0a")}</Typography>
        </div>
        <div className={classes.metric}>
          <Typography>{numeral(page.impressions || 0).format("0,0a")}</Typography>
        </div>
        <div className={classes.metric}>
          <Typography>{numeral(page.ctr || 0).format("0.0%")}</Typography>
        </div>
        <div className={classes.metric}>
          <Typography>{numeral(page.position || 0).format("0.0")}</Typography>
        </div>
        <div className={classNames(classes.actionsWrapper)}>
          {page.metadata?.title && (
            <Tooltip
              arrow
              title={showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title")}
              className={classes.actionIconWrapper}
            >
              <IconButton
                className={classes.iconBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTitleCopy("title");
                }}
              >
                <CopyNameIcon className={classNames(classes.actionIcon)} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip
            arrow
            title={showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_url")}
            className={classes.actionIconWrapper}
          >
            <div>
              <CopyToClipboardIcon
                className={classNames(classes.actionIcon)}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTitleCopy("page");
                }}
              />
            </div>
          </Tooltip>
          <Tooltip
            className={classes.actionIconWrapper}
            arrow
            onClick={(e) => {
              e.stopPropagation();
              window.open(page.page, "_blank");
            }}
            title={I18n.t("outline.icons.open_in_new_tab")}
          >
            <div>
              <OpenInNewIcon className={classes.actionIcon} />
            </div>
          </Tooltip>
          {page.metadata?.entail_page_id && (
            <Tooltip arrow title={I18n.t("outline.icons.open_in_editor")} className={classes.actionIconWrapper}>
              <IconButton
                className={classes.iconBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  openPost(page.metadata?.entail_page_id);
                }}
              >
                <OpenInEditor className={classNames(classes.actionIcon)} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip
            enterDelay={500}
            title={I18n.t(`learning_center_content.tooltip.google_search_console`)}
            className={classes.actionIconWrapper}
          >
            <IconButton
              disableRipple
              className={classes.iconBtn}
              onClick={(event) => {
                event.stopPropagation();
                window.open(getPageSearchConsoleUrl(page.domain, page.page));
              }}
            >
              <Search className={classNames(classes.actionIcon)} />
            </IconButton>
          </Tooltip>
        </div>
      </ListItem>
    </div>
  );
};

export default PagesDialogListItem;
