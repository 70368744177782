import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 14,
    height: 14,
  },
}));

type PropTypes = {
  className?: string;
};
const StrokeBottomIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 14 14"}>
      <rect
        x="13.5"
        y="13.5"
        width="13"
        height="13"
        rx="0.5"
        transform="rotate(180 13.5 13.5)"
        fill="#F5F6F8"
        stroke="#C4C4C4"
      />
      <mask id="path-2-inside-1_3797_326" fill="white">
        <path d="M14 13C14 13.5523 13.5523 14 13 14L1 14C0.447716 14 -3.91405e-08 13.5523 -8.74228e-08 13L-1.1365e-06 1C-1.18478e-06 0.447717 0.447715 1.18478e-06 0.999999 1.1365e-06L13 8.74228e-08C13.5523 3.91405e-08 14 0.447716 14 1L14 13Z" />
      </mask>
      <path
        d="M14 13C14 14.3807 12.8807 15.5 11.5 15.5L2.5 15.5C1.11929 15.5 3.32829e-08 14.3807 -8.74228e-08 13L-1.31134e-07 12.5C-1.31134e-07 12.5 0.447715 12.5 1 12.5L13 12.5C13.5523 12.5 14 12.5 14 12.5L14 13ZM-1.22392e-06 1.22392e-06L14 0L-1.22392e-06 1.22392e-06ZM14 0L14 14L14 0ZM0 14L-1.22392e-06 1.22392e-06L0 14Z"
        fill="#4353FF"
        mask="url(#path-2-inside-1_3797_326)"
      />
    </SvgIcon>
  );
};

export default StrokeBottomIcon;
