import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { IAd, IAdPlacement } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: 50,
    display: "flex",
    flexDirection: "row",
  },
  paper: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1000,
    padding: 30,
  },
  adImage: {
    objectFit: "cover",
  },
}));

type PropTypes = {
  ad: IAd;
  placement: IAdPlacement;
};

const BlogFeedImagePreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { ad, placement } = props;

  return (
    <div className={classes.wrapper}>
      <Paper elevation={0} className={classes.paper}>
        <img
          width={Math.min(900, placement.imageFile?.width || 900)}
          height={Math.min(500, placement.imageFile?.height || 500)}
          src={placement.imageFile?.cdnUrl || placement.imageFile?.url}
          className={classes.adImage}
          alt={placement.imageFile?.alt}
        />
      </Paper>
    </div>
  );
};

export default BlogFeedImagePreview;
