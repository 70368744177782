import React, { useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import moment from "moment/moment";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import numeral from "numeral";

const useStyles = makeStyles((theme: Theme) => ({
  reportCard: {
    "& .recharts-legend-wrapper": {
      top: "0 !important",
      right: "0 !important",
      maxHeight: 270,
      display: "flex",
      overflow: "auto",
    },
  },
  legendTitle: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
    marginBottom: 15,
  },

  customTooltip: {
    background: theme.palette.common.white,
    border: "#e5e5e5 solid 1px",
    boxShadow: " cubic-bezier(0.4, 0, 0.2, 1) ",
    borderRadius: "10px",
    minWidth: "150px",
  },
  toolTipLabel: {
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightLight as any,
    marginBottom: 10,
    color: theme.palette.text.primary,
  },
  toolTipValues: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  tooltipText: {
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightLight as any,
  },
  tooltipTextTitle: {
    color: theme.palette.text.primary,
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightLight as any,
  },
  tooltipTotalText: {
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  tooltipTotal: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    gap: 5,
  },
}));

const CustomTooltip = ({
  active,
  payload,
  label,
  getTotal,
  translations,
  projectionColumn,
  totalColumn,
  customFormat,
}) => {
  const classes = useStyles();

  const calculateTotal = useMemo(() => {
    let total = 0;
    if (getTotal) {
      if (payload && payload.length > 0) {
        payload.forEach((item) => {
          total += item.value;
        });
      }
    }
    return numeral(total).format("0a");
  }, [payload]);

  const totalColumnValue = useMemo(() => {
    const value = payload?.find((item) => item.name === totalColumn)?.value;

    return value || 0;
  }, [payload]);

  return (
    <div className={classes.customTooltip}>
      {active && payload && payload.length > 0 && (
        <div style={{ padding: 10 }}>
          <Typography variant={"subtitle1"} className={classes.toolTipLabel}>
            {moment(label).format("MMM , YYYY")}
          </Typography>
          {payload.map((item, index) => (
            <div key={index} className={classes.toolTipValues} style={{ color: item.color }}>
              <Typography variant={"subtitle2"} className={classes.tooltipTextTitle}>
                {translations ? translations[item.name] : item.name}:
              </Typography>
              <Typography variant={"subtitle2"} className={classes.tooltipText}>
                {item.name === projectionColumn
                  ? numeral(item.value + totalColumnValue).format("0,00")
                  : numeral(item.value).format(customFormat?.[item.name] || "0,00")}
              </Typography>
            </div>
          ))}

          {getTotal && (
            <div className={classes.tooltipTotal}>
              <Typography variant={"subtitle2"} className={classes.tooltipTotalText}>
                Total:
              </Typography>
              <Typography variant={"subtitle2"} className={classes.tooltipTotalText}>
                {calculateTotal}
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
