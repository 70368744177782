import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import TextareaAutosize from "react-textarea-autosize";

const useStyles = makeStyles((theme: Theme) => ({
  textInput: {
    fontFamily: theme.typography.fontFamily,
    resize: "none",
    border: "none",
    outline: "none",
    width: "100%",
    margin: (props: PropTypes) => props.margin || 0,
    fontSize: (props: PropTypes) => props.fontSize || 16,
    padding: (props: PropTypes) => props.padding || 0,
    backgroundColor: "initial",
  },
}));

type PropTypes = {
  minRows?: number;
  maxRows?: number;
  text: string;
  fontSize?: string | number;
  margin?: string | number;
  padding?: string | number;
  className?: string;
  placeholder?: string;
  onBlur?: () => void;
  onChange: (text: string) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
};

const TextAreaBaseComponent = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    text,
    minRows = 1,
    maxRows = undefined,
    className = undefined,
    onChange,
    onKeyDown = null,
    onBlur = null,
    placeholder = I18n.t("rich_text_editor.insert_comments.comment_ph"),
  } = props;

  return (
    <TextareaAutosize
      onBlur={onBlur}
      value={text}
      minRows={minRows}
      maxRows={maxRows}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      placeholder={placeholder}
      className={ClassNames(classes.textInput, className)}
    />
  );
};

export default TextAreaBaseComponent;
