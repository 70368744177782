import React from "react";
import { I18n } from "react-redux-i18n";
import SwitchProperty from "./SwitchProperty";

type PropTypes = {
  required?: boolean;
  className?: string;
  onChange?(required: boolean): unknown;
};
const RequiredFieldProperty = (props: PropTypes) => {
  const { required, onChange } = props;

  return (
    <SwitchProperty
      {...props}
      checked={required}
      onChange={onChange}
      label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.required_field_label")}
    />
  );
};

export default RequiredFieldProperty;
