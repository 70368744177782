import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { OUTLINE_PAGES } from "../../reducers/constants/actionTypes";
import { I18n } from "react-redux-i18n";
import { IWebsite, IAutoOutlineWithStrategy, IKeywordStrategy } from "../../reducers/constants/objectTypes";
import MappingListColumns from "./BriefsListColumns";
import BriefsListItem from "./BriefsListItem";
import MTable from "../../components/Table/Table/MTable";
import MTableRowProgressBar from "../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../components/Table/Rows/MTableRowEmptyRow";
import agent from "../../agent";
import BriefsFeedbackDialog from "./BriefsFeedbackDialog";
import { useState } from "react";
import { useSnackbar } from "notistack";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  total: state.outlinePages.total,
  limit: state.outlinePages.limit,
  page: state.outlinePages.page,
});

const mapDispatchToProps = (dispatch) => ({
  handleOutlinesLoaded: (payload) => dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGES_LOADED, payload }),
  onFilterChanged: (payload) => dispatch({ type: OUTLINE_PAGES.OUTLINE_PAGES_ON_FILTER_CHANGED, payload }),
  redirect: (url) => dispatch(push(url)),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  outlines: IAutoOutlineWithStrategy[];
  redirect: (url: string) => void;
  outlinesLoaded: boolean;
  openOutlineDialog: (outline: IAutoOutlineWithStrategy, pageTab: "outlines" | "keywords" | "broadKeywords") => void;
  handleOutlinesLoaded: (payload: any) => void;
  setRefetch: (refetch: boolean) => void;
  refetch: boolean;
  limit: number;
  onFilterChanged: (payload: { searchText: string }) => void;
  page: number;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  setSortField: (sortField: { direction: "desc" | "asc" | undefined; field: string }) => void;
  total: number;
  updateAutoOutline: (outline: IAutoOutlineWithStrategy) => void;
  deleteAutoOutline: (id: string) => void;
};

const BriefsList = (props: PropTypes) => {
  const {
    outlinesLoaded,
    selectedWebsite,
    outlines,
    updateAutoOutline,
    openOutlineDialog,
    sortField,
    setSortField,
    total,
    refetch,
    setRefetch,
    deleteAutoOutline,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [feedBackDialogOpen, setFeedBackDialogOpen] = useState(false);
  const [selectedKeywordStrategy, setSelectedKeywordStrategy] = useState<IKeywordStrategy | null>(null);

  const changeFeedback = async (item, feedback) => {
    try {
      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, {
        ...item,
        feedback,
      });
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.feedback_saved"));
      setRefetch(!refetch);
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    setFeedBackDialogOpen(false);
    setSelectedKeywordStrategy(null);
  };

  return (
    <MTable>
      <MappingListColumns sortField={sortField} setSortField={setSortField} total={total} />
      {!outlinesLoaded && <MTableRowProgressBar />}
      {outlinesLoaded && outlines?.length === 0 && <MTableRowEmptyRow />}

      {outlinesLoaded &&
        outlines?.length > 0 &&
        outlines.map((o, index) => (
          <BriefsListItem
            openFeedbackDialog={(item) => {
              setSelectedKeywordStrategy(item.strategy);
              setFeedBackDialogOpen(true);
            }}
            updateAutoOutline={updateAutoOutline}
            deleteAutoOutline={deleteAutoOutline}
            isLastRow={index === outlines.length - 1}
            isFirstRow={index === 0}
            selectedWebsite={selectedWebsite}
            outline={o}
            key={index}
            openOutlineDialog={openOutlineDialog}
          />
        ))}
      <BriefsFeedbackDialog
        changeFeedback={changeFeedback}
        open={feedBackDialogOpen}
        closeDialog={() => {
          setFeedBackDialogOpen(false);
        }}
        keywordStrategy={selectedKeywordStrategy}
      />
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BriefsList);
