import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: theme.palette.text.primary,
  },
}));

type PropTypes = {
  active?: boolean;
  className?: string;
  onClick?: () => void;
};

const SendIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className, onClick } = props;
  return (
    <SvgIcon
      className={ClassNames(classes.icon, className)}
      onClick={onClick}
      viewBox={"0 0 25 26"}
      width={25}
      height={26}
    >
      <rect width="25" height="25" rx="12.5" transform="matrix(1 0 0 -1 0 25.5)" />
      <path
        d="M8.55469 17.9281V8.07194C8.55469 7.74703 8.66185 7.48714 8.87617 7.29229C9.09049 7.09743 9.34064 7 9.62663 7C9.72018 7 9.81496 7.01239 9.91095 7.03716C10.0072 7.06171 10.102 7.09864 10.1956 7.14797L17.9487 12.1016C18.1139 12.2173 18.2377 12.3492 18.3202 12.4974C18.4027 12.6453 18.444 12.8129 18.444 13C18.444 13.1871 18.4027 13.3547 18.3202 13.5026C18.2377 13.6508 18.1139 13.7827 17.9487 13.8984L10.1956 18.852C10.102 18.9014 10.0072 18.9383 9.91095 18.9628C9.81496 18.9876 9.72018 19 9.62663 19C9.34064 19 9.09049 18.9026 8.87617 18.7077C8.66185 18.5129 8.55469 18.253 8.55469 17.9281ZM9.88175 17.4457L16.8999 13L13.8571 15.4706L9.88175 17.4457Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default SendIcon;
