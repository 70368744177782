import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import RuleMoreOptionsButton from "./RuleMoreOptionsButton";
import RuleUrlActions from "./RuleUrlActions";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import MTableRow from "../../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../../components/Table/Cells/MTableCellFirst";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";
import MTableCellDate from "../../../../../components/Table/Cells/MTableCellDate";
import MTableCellNumber from "../../../../../components/Table/Cells/MTableCellNumber";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $ruleAction": {
        display: "flex",
      },
      "& $actions": {
        display: "flex",
      },
    },
  },

  moreOptionsColumn: {
    width: 30,
  },
  ruleAction: {},
}));

type PropTypes = {
  rule: any;
  selectedWebsite: IWebsite;

  handleEditClick: Function;
  handleDeleteClick: Function;
};

const RuleItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, rule, handleDeleteClick, handleEditClick } = props;

  return (
    <MTableRow handleRowClick={() => handleEditClick()} customClassName={classes.itemWrapper}>
      <MTableCellFirst cellText={rule.from} columnWidth={"20%"}>
        <RuleUrlActions customClass={classes.ruleAction} url={`${selectedWebsite.url}${rule.from}`} />
      </MTableCellFirst>
      <MTableCellFirst cellText={rule.to} columnWidth={"20%"}>
        <RuleUrlActions customClass={classes.ruleAction} url={`${selectedWebsite.url}${rule.to}`} />
      </MTableCellFirst>
      <MTableCellText text={rule.locale} />
      <MTableCellText text={rule.modelName} />
      <MTableCellText text={rule.path} />
      <MTableCellDate date={rule.lastRedirect} />
      <MTableCellDate date={rule.createdAt} />
      <MTableCellNumber value={rule.botCounter} />
      <MTableCellNumber value={rule.userCounter} />
      <MTableCellText text={rule.autoRule ? "✔" : "✘"} />
      <div className={classes.moreOptionsColumn}>
        <RuleMoreOptionsButton handleDeleteClick={handleDeleteClick} />
      </div>
    </MTableRow>
  );
};

export default RuleItem;
