import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import React from "react";
import { I18n } from "react-redux-i18n";
import CustomTooltip from "./utils/CustomTooltip";
import theme from "../../../../../themes/theme";

type Props = {
  data: any[];
  timeFormat: (item: any) => string;
  numberFormat: (item: any) => string;
  fullDateFormat: (item: any) => string;
  titlesFormat: (value: any, name: any) => any[];
  width?: string;
  height?: number;
};

const OrganicTrafficChart = (props: Props) => {
  const { timeFormat, data, numberFormat, fullDateFormat, titlesFormat, width, height } = props;

  return (
    <ResponsiveContainer width={width || "98%"} height={height || 400}>
      <ComposedChart data={data}>
        <XAxis
          dataKey={"date"}
          tickFormatter={timeFormat}
          axisLine={false}
          tickLine={false}
          tickMargin={16}
          tick={{ fontSize: 12 }}
          interval={"preserveStartEnd"}
        />

        <YAxis
          dataKey={"total_clicks"}
          yAxisId="left"
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          tick={{ fontSize: 12 }}
          type="number"
          width={40}
          tickFormatter={numberFormat}
        />
        <YAxis
          dataKey={"avg_daily_total_clicks"}
          yAxisId="right"
          orientation="right"
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          tick={{ fontSize: 12 }}
          type="number"
          tickFormatter={numberFormat}
        />

        <Tooltip
          cursor={false}
          content={
            <CustomTooltip
              projectionColumn={"projectedClicks"}
              totalColumn={"total_clicks"}
              getTotal={false}
              translations={{
                avg_daily_total_clicks: I18n.t("reports.dashboard.avg_daily_total_clicks"),
                total_clicks: I18n.t("reports.dashboard.total_clicks"),
                projectedClicks: I18n.t("reports.dashboard.projected_clicks"),
              }}
            />
          }
          labelFormatter={fullDateFormat}
          formatter={titlesFormat}
          labelStyle={{ marginBottom: 5, fontWeight: theme.typography.fontWeightBold }}
        />
        <CartesianGrid stroke="#E5E5E5" vertical={false} strokeDasharray={1} />
        <Bar yAxisId={"left"} fill="#4353FF" maxBarSize={80} stackId={"clicks-stack"} dataKey="total_clicks" />
        <Bar yAxisId={"left"} fill="#4353FF80" maxBarSize={80} stackId={"clicks-stack"} dataKey="projectedClicks" />

        <Line yAxisId={"right"} dataKey="avg_daily_total_clicks" stroke="#8B2FE8" strokeWidth={2} z={2} dot={false} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default OrganicTrafficChart;
