import { CLUSTERS } from "./constants/actionTypes";
import { eClusterPageType } from "./constants/objectTypes";

const initialState = {
  clustersLoaded: false,
  refreshItemList: false,
  clusters: [],
  clustersSearchText: "",

  clusterLoaded: false,
  cluster: null,
  clusterPostsIds: [],
  searchConsoleInterval: {
    value: "last3Months",
    interval: 3,
    intervalType: "month",
  },
  posts: [],
  postsLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLUSTERS.CLUSTERS_LOADED:
      return {
        ...state,
        clustersLoaded: true,
        refreshItemList: false,
        clusters: action.clusters,
      };
    case CLUSTERS.CLUSTER_CREATED:
      return {
        ...state,
        clusters: [action.cluster, ...state.clusters],
      };
    case CLUSTERS.CLUSTER_CHANGED:
      return {
        ...state,
        clusters: state.clusters.map((cluster) => {
          if (cluster._id === action.cluster._id) {
            return action.cluster;
          }
          return cluster;
        }),
      };
    case CLUSTERS.CLUSTER_DELETED:
      return {
        ...state,
        clusters: state.clusters.filter((c) => c._id !== action.cluster._id),
      };
    case CLUSTERS.REFRESH_CLUSTERS_LIST:
      return {
        ...state,
        refreshItemList: true,
      };
    case CLUSTERS.CLUSTER_SEARCH_TEXT_CHANGED:
      return {
        ...state,
        clustersSearchText: action.searchText,
      };

    case CLUSTERS.CLUSTER_TOPIC_LOADED: {
      const { cluster } = action;
      const clusterPostsIds = [];
      if (cluster.page.pageType !== eClusterPageType.NONE) {
        clusterPostsIds.push(
          cluster.page.blogPost?._id ||
            cluster.page.learnPost?._id ||
            cluster.page.communityPost?._id ||
            cluster.page.targetedPost?._id ||
            cluster.page.landingPage?._id ||
            cluster.page.learnTopic?._id ||
            cluster.page.topicPage?._id
        );
      }
      cluster.linkingPages.map((p) => {
        clusterPostsIds.push(
          p.blogPost?._id ||
            p.learnPost?._id ||
            p.communityPost?._id ||
            p.targetedPost?._id ||
            p.landingPage?._id ||
            p.learnTopic?._id ||
            p.topicPage?._id
        );
      });
      return {
        ...state,
        clusterLoaded: true,
        cluster,
        clusterPostsIds,
      };
    }
    case CLUSTERS.CLUSTER_TOPIC_CLEANUP:
      return {
        ...state,
        clusterLoaded: false,
        cluster: null,
        clusterPostsIds: [],
      };
    case CLUSTERS.CLUSTER_TOPIC_SEARCH_CONSOLE_INTERVAL_CHANGED:
      return {
        ...state,
        searchConsoleInterval: action.interval,
      };
    case CLUSTERS.CLUSTER_TOPIC_POSTS_LOADED:
      return {
        ...state,
        posts: action.payload.items,
        postsLoaded: true,
      };
    default:
      return state;
  }
};
