import { connect } from "react-redux";
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Allotment } from "allotment";
import { Dialog } from "@material-ui/core";
import { useSnackbar } from "notistack";
import "allotment/dist/style.css";

import WidgetEditorHeader from "./header/WidgetEditorHeader";
import WidgetEditorActions from "./tabs/WidgetEditorActions";
import WidgetEditorPreview from "./preview/WidgetEditorPreview";
import { IWidget, eUserRoles } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import { WIDGET_BUILDER } from "../../../../../reducers/constants/actionTypes";
import WidgetEditorBottomBar from "./components/WidgetEditorBottomBar";
import CfProgressBar from "../../../../../components/CfProgressBar";
import { colors } from "../../../../../helpers/constants";
import ability from "../../../../../casl/ability";

const useStyles = makeStyles(() => ({
  editorWrapper: {
    backgroundColor: colors.pageEditorBackgroundColor,
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  componentsWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 50px)",
  },
  emptyPageWrapper: {
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "#fff",
    height: "100%",
    flexDirection: "column",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

type PropTypes = {
  widgetId: string;
  widgetLoaded: boolean;
  widget: IWidget;
  latestSavedWidget: IWidget;
  extraButtonActions?: {
    action: string;
    label: string;
    onClick: () => void;
  }[];
  handleClose: (widget?: IWidget) => void;
  handleWidgetLoaded: (payload) => void;
  cleanUp: () => void;
};

const mapStateToProps = (state) => ({
  widgetLoaded: state.widgetBuilder.widgetLoaded,
  widget: state.widgetBuilder.widget,
  latestSavedWidget: state.widgetBuilder.latestSavedWidget,
});

const mapDispatchToProps = (dispatch) => ({
  handleWidgetLoaded: (payload) => dispatch({ type: WIDGET_BUILDER.WIDGET_LOADED, payload }),
  cleanUp: () => dispatch({ type: WIDGET_BUILDER.WIDGET_CLEANUP }),
});

const FormWidgetEditor = (props: PropTypes) => {
  const classes = useStyles(props);
  const { widget, widgetLoaded, widgetId, latestSavedWidget, extraButtonActions } = props;
  const { handleClose, handleWidgetLoaded, cleanUp } = props;
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!widgetId) {
      return;
    }

    agent.Widgets.getWidget(widgetId)
      .then((payload) => {
        if (payload.widget.isTemplate && !ability.can(eUserRoles.ADMIN, "role")) {
          handleClose();
          return;
        }
        handleWidgetLoaded(payload);
      })
      .catch((e) => {
        enqueueSnackbar(e.response?.body?.message || e.message);
      });
    // eslint-disable-next-line consistent-return
    return () => {
      cleanUp();
    };
  }, [widgetId]);

  if (!widgetLoaded) {
    return (
      <Dialog fullScreen open={true} transitionDuration={0}>
        <div className={classes.emptyPageWrapper}>
          <CfProgressBar />
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog fullScreen open={true} transitionDuration={0} disableEnforceFocus>
      <div className={classes.editorWrapper}>
        <WidgetEditorHeader handleClose={() => handleClose(latestSavedWidget)} />
        <div className={classes.componentsWrapper}>
          <Allotment>
            <Allotment.Pane preferredSize={460} minSize={460}>
              <WidgetEditorActions widget={widget} />
            </Allotment.Pane>
            <Allotment.Pane>
              <div>
                <WidgetEditorPreview />
                <WidgetEditorBottomBar handleClose={handleClose} extraButtonActions={extraButtonActions} />
              </div>
            </Allotment.Pane>
          </Allotment>
        </div>
      </div>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWidgetEditor);
