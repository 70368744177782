import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { find } from "lodash";
import MSelect from "../../MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  postBottomBarInnerWrapper: {
    paddingRight: 10,
    paddingLeft: 20,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    borderRadius: 20,
  },
  selector: {},
}));

type PropTypes = {
  limit: number;

  menuPlacement?: "auto" | "bottom" | "top";
  options: {
    value: number;
    label: string;
  }[];

  handleLimitChange?: (limit: number) => void;
};

const MTablePaginationLimitSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const { limit, handleLimitChange = null, menuPlacement = "bottom", options } = props;

  return (
    <div className={classes.postBottomBarInnerWrapper}>
      <MSelect
        optionValue={"value"}
        optionLabel={"label"}
        options={options}
        borderColor={"transparent"}
        placeholder={I18n.t("pages.display_limit")}
        customClass={classes.selector}
        menuPlacement={menuPlacement}
        handleChange={(newInterval) => {
          console.log("newInterval", newInterval.value);
          if (handleLimitChange) handleLimitChange(parseInt(newInterval.value, 10));
        }}
        value={find(options, { value: limit })}
        height={40}
      />
    </div>
  );
};

export default MTablePaginationLimitSelect;
