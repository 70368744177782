<svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none"></svg>;

import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  className?: string;
};

const AnalyticsIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 13 14"}>
      <path d="M0.8077 14C0.583334 14 0.392625 13.9215 0.235575 13.7644C0.0785251 13.6074 0 13.4167 0 13.1923V10.8077C0 10.5833 0.0785251 10.3926 0.235575 10.2356C0.392625 10.0785 0.583334 10 0.8077 10C1.03205 10 1.22275 10.0785 1.3798 10.2356C1.53685 10.3926 1.61537 10.5833 1.61537 10.8077V13.1923C1.61537 13.4167 1.53685 13.6074 1.3798 13.7644C1.22275 13.9215 1.03205 14 0.8077 14ZM6.61538 14C6.39103 14 6.20033 13.9215 6.04327 13.7644C5.88621 13.6074 5.80768 13.4167 5.80768 13.1923V5.8077C5.80768 5.58333 5.88621 5.39262 6.04327 5.23557C6.20033 5.07852 6.39103 5 6.61538 5C6.83974 5 7.03045 5.07852 7.1875 5.23557C7.34455 5.39262 7.42307 5.58333 7.42307 5.8077V13.1923C7.42307 13.4167 7.34455 13.6074 7.1875 13.7644C7.03045 13.9215 6.83974 14 6.61538 14ZM12.1923 14C11.968 14 11.7772 13.9215 11.6202 13.7644C11.4632 13.6074 11.3846 13.4167 11.3846 13.1923V0.8077C11.3846 0.583333 11.4632 0.392624 11.6202 0.235574C11.7772 0.0785243 11.968 0 12.1923 0C12.4167 0 12.6074 0.0785243 12.7644 0.235574C12.9215 0.392624 13 0.583333 13 0.8077V13.1923C13 13.4167 12.9215 13.6074 12.7644 13.7644C12.6074 13.9215 12.4167 14 12.1923 14Z" />
    </SvgIcon>
  );
};

export default AnalyticsIcon;
