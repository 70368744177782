import { IWidget, eWidgetSubtype, eWidgetType } from "../../../../reducers/constants/objectTypes";
import DesignBackgroundModel from "./FormWidgetEditor/design/models/DesignBackgroundModel";
import DesignButtonModel from "./FormWidgetEditor/design/models/DesignButtonModel";
import DesignInputFieldsModel from "./FormWidgetEditor/design/models/DesignInputFieldsModel";

export const widgetDesignDefaultValues: {
  buttonDesign: DesignButtonModel;
  background: DesignBackgroundModel;
  inputFields: DesignInputFieldsModel;
} = {
  buttonDesign: {
    alignment: "Left",
    fontSize: "10px",
    textColor: "white",
    backgroundColor: "black",
    borderRadius: "10px 20px 10px 20px",
    padding: "5px",
  },
  background: {
    background: { color: "#F8F1FC" },
    borderRadius: "10px",
    backgroundOption: "Color",
  },
  inputFields: {
    labelPosition: "Inside",
    labelColor: "#FFFFFF",
    textColor: "#FFFFFF",
    fieldColor: "#FFFFFF",
    borderRadius: "0px 0px 0px 0px",
    buttonColor: "",
  },
};

export const WidgetTypeAlias = {
  [eWidgetType.FORM]: "Forms",
  [eWidgetType.CUSTOM_WIDGET]: "Products",
  [eWidgetType.DIALOG]: "Popups",
  [eWidgetType.INLINE]: "Banners",
};

export const getWidgetType = (widget: IWidget) => {
  switch (widget.type) {
    case eWidgetType.FORM:
      return "Form";
    case eWidgetType.DIALOG:
      return "Popup";
    case eWidgetType.CUSTOM_WIDGET:
      switch (widget.subtype) {
        case eWidgetSubtype.PRODUCT_REVIEW:
          return "Product Review";
        case eWidgetSubtype.PRODUCT_CTA_BANNER:
          return "CTA Banner";
        case eWidgetSubtype.PRODUCT_CTA:
          return "Product CTA";
        case eWidgetSubtype.CHARTICLE_TOP_PRODUCTS:
          return "Top Products";
        case eWidgetSubtype.PRODUCT_RATING:
          return "Product Rating";
        case eWidgetSubtype.PRODUCTS_CAROUSEL:
          return "Products Carousel";
        default:
          return "Product";
      }
    case eWidgetType.INLINE:
      switch (widget.subtype) {
        case eWidgetSubtype.RELATED_CONTENT:
          return "Related content";
        case eWidgetSubtype.TEXT_CTA:
          return "Text CTA";
        default:
          return "Banner";
      }
  }
};
