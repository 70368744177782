import * as React from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { I18n } from "react-redux-i18n";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles, createStyles } from "@material-ui/styles";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import CfButton from "../../../components/CfButton";
import VideoUploaderHelper from "../../../helpers/VideoUploaderHelper";
import ClassNames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UploadSourceFileRow from "./components/UploadSourceFileRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: "100%",
      maxWidth: 1220,
      display: "flex",
      flexDirection: "column",
      borderWidth: 0,
      borderRadius: 0,
      padding: 24,
      gap: 24,
    },
    dialogTitleWrap: {
      padding: 0,
    },
    dialogTitle: {
      marginBottom: 0,
      fontWeight: theme.typography.fontWeightBold as any,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.2,
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      whiteSpace: "pre",
      padding: 0,
      gap: 10,
    },
    dialogActions: {
      padding: 0,
    },
    headersRow: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      fontWeight: theme.typography.fontWeightBold as any,
      fontSize: theme.typography.pxToRem(14),
    },
    xsHeader: {
      width: "2%",
    },
    smHeader: {
      width: "10%",
    },
    lgHeader: {
      width: "15%",
    },
    xlHeader: {
      width: "100%",
      maxWidth: 280,
    },
    button: {
      width: 110,
      padding: "10px 20px",
      borderRadius: 5,
      minHeight: "unset",
      fontWeight: theme.typography.fontWeightLight as any,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
);

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

type PropTypes = {
  open: boolean;
  selectedWebsite: IWebsite;
  handleExitClick: () => void;
};
const UploadSourceFileDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, selectedWebsite, handleExitClick } = props;

  const close = () => {
    handleExitClick();
  };

  const handleClose = (event, reason) => {
    console.log("handleClose", reason);
    if (reason && reason === "backdropClick") return;
    close();
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      keepMounted={false}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.dialogTitleWrap}>
        <Typography variant={"h2"} className={classes.dialogTitle}>
          {I18n.t("source_file_uploader_dialog.title")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.headersRow}>
          <span className={classes.xsHeader}>{I18n.t("source_file_uploader_dialog.index")}</span>
          <span className={classes.xlHeader}>{I18n.t("source_file_uploader_dialog.file")}</span>
          <span className={classes.lgHeader}>{I18n.t("source_file_uploader_dialog.title")}</span>
          <span className={classes.lgHeader}>{I18n.t("source_file_uploader_dialog.description")}</span>
          <span className={classes.smHeader}>{I18n.t("source_file_uploader_dialog.type")}</span>
          <span className={classes.smHeader}>{I18n.t("source_file_uploader_dialog.language")}</span>
          <span className={classes.smHeader}>{I18n.t("source_file_uploader_dialog.speakers")}</span>
          <span className={classes.smHeader}></span>
        </div>
        {[...Array(4)].map((item, i) => (
          <UploadSourceFileRow key={`${item}_${i}`} index={i + 1} selectedWebsite={selectedWebsite} />
        ))}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CfButton
          customClass={classes.button}
          text={I18n.t("source_file_uploader_dialog.done")}
          onClick={(e) => close()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UploadSourceFileDialog;
