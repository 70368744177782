import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  quotes: (props: PropTypes) => ({
    ...props.finalDefaultCss,
    ...props.css,
    "&:before": {
      ...(props.finalDefaultCss["&:before"] || {}),
      ...(props.css["&:before"] || {}),
    },
  }),
}));

type PropTypes = {
  finalDefaultCss: any;
  css: any;

  exampleText?: string;
};

const QuotePreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { exampleText = null } = props;

  return (
    <blockquote className={classes.quotes}>
      <span style={{ textDecoration: "inherit", fontStyle: "inherit" }}>
        {exampleText || I18n.t("website.quotes_example")}
      </span>
    </blockquote>
  );
};

export default QuotePreview;
