import { hot } from "react-hot-loader/root";
import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Switch, Route } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";
import Main from "./containers/Main/Main";
import AppTheme from "./themes/theme";
import store, { history } from "./store";

import Login from "./containers/Authentication/Login";
import ForgotPassword from "./containers/Authentication/ForgotPassword";
import ResetPassword from "./containers/Authentication/ResetPassword";
import IdleChecker from "./containers/Main/components/IdleChecker";
import { AbilityContext } from "./casl/can";
import ability from "./casl/ability";
import LinkExpired from "./containers/Authentication/LinkExpired";
import ChangeInitialPassword from "./containers/Authentication/ChangeInitialPassword";
import SignUp from "./containers/Authentication/SignUp";
import { web } from "./helpers/urlHelper";
import OnboardingFlow from "./containers/Authentication/OnboardingFlow/OnboardingFlow";

function App() {
  console.log("render App");

  const notistackRef = React.useRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current ? notistackRef.current.closeSnackbar(key) : undefined;
  };
  const helmetContext = {};
  return (
    <Provider store={store}>
      <HelmetProvider context={helmetContext}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={AppTheme}>
            <CssBaseline />
            <AbilityContext.Provider value={ability}>
              <SnackbarProvider
                ref={notistackRef}
                maxSnack={4}
                action={(key) => (
                  <Button onClick={onClickDismiss(key)} style={{ color: AppTheme.palette.primary.main }}>
                    {"Dismiss"}
                  </Button>
                )}
              >
                <Switch>
                  <Route path={web.signUp()} component={SignUp} />
                  <Route path={web.onBoarding()} component={OnboardingFlow} />
                  <Route path="/login" component={Login} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route path="/link-expired" component={LinkExpired} />
                  <Route path="/reset-initial-password" component={ChangeInitialPassword} />
                  <Route exact={false} path="/" component={Main} />
                </Switch>
              </SnackbarProvider>
              <IdleChecker />
            </AbilityContext.Provider>
          </ThemeProvider>
        </ConnectedRouter>
      </HelmetProvider>
    </Provider>
  );
}

export default hot(App);
