import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { find } from "lodash";
import ClassNames from "classnames";
import { ePageState, IPage, IPageCluster, IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import SNReadOnlyEditor from "../../../editor/ReadOnlyEditor";
import CfProgressBar from "../../../components/CfProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  progressBarWrapper: {
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  progressBar: {
    justifyContent: "center",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  pageLoaded: boolean;
  page: IPage;
  cluster: IPageCluster;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  pageLoaded: state.pageEditor.pageLoaded,
  page: state.pageEditor.page,
  cluster: state.pageEditor.pageCluster,
});

const mapDispatchToProps = (dispatch) => ({});

const PageEditorDefaultLocale = (props: PropTypes) => {
  const classes = useStyles(props);
  const { pageLoaded, page, cluster, selectedWebsite } = props;

  const [defaultPage, setDefaultPage] = React.useState(null);
  const [defaultLoaded, setDefaultLoading] = React.useState(false);

  if (!pageLoaded) {
    return null;
  }

  const pageId = React.useMemo(() => {
    const locale = selectedWebsite.configurations?.locale?.defaultLocale || "en";
    const published = find(cluster.publishedPages[page.variant], (p) => p.locale === locale);
    const inProgress = find(
      cluster.pages,
      (p) => p.locale === locale && ![ePageState.published, ePageState.archived].includes(p.currentState.state)
    );
    return published?.page?._id || published?.page || inProgress?._id || null;
  }, [selectedWebsite, cluster, page]);

  React.useEffect(() => {
    if (pageId) {
      agent.Pages.getPage(selectedWebsite, pageId)
        .then((res) => {
          setDefaultPage(res.page);
          setDefaultLoading(true);
        })
        .catch((e) => {
          console.error("Error", e);
          setDefaultLoading(true);
        });
    }
  }, [pageId, selectedWebsite]);
  if (!defaultPage && defaultLoaded) {
    return null;
  }

  return (
    <>
      {!defaultLoaded ? (
        <div className={ClassNames(classes.progressBarWrapper)}>
          <CfProgressBar customClass={classes.progressBar} size={40} />
        </div>
      ) : (
        <SNReadOnlyEditor
          products={defaultPage.products}
          website={selectedWebsite}
          value={defaultPage.richText ?? "[]"}
          title={defaultPage.title}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorDefaultLocale);
