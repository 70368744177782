import React from "react";
import { TextareaAutosize, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { I18n } from "react-redux-i18n";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import { ITimelineComment } from "../../../../../reducers/constants/objectTypes";
import { formatDate, millisecondsToTime } from "../../../utils";
import MActionsDropdown from "../../../../../components/MActionsDropdown";
import { addAnchorTags } from "../../../../../helpers/textHelper";
import MDangerouslySetHtmlContent from "../../../../../components/MDangerouslySetHtmlContent";

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: "flex",
    padding: 15,
    alignItems: "flex-start",
    gap: 20,
    alignSelf: "stretch",
    cursor: "pointer",
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: "#EEF2F9",
    "&:hover": {
      backgroundColor: "#E1EAF7",
      "& $item": {
        backgroundColor: "#E1EAF7",
      },
    },
    "&.highlighted": {
      backgroundColor: "#E1EAF7",
    },
  },
  replyItem: {
    paddingRight: 0,
    "&:first-child": {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    "&:last-child": {
      borderBottom: `none`,
    },
  },
  itemLeftSide: {
    display: "flex",
    minWidth: 70,
    maxWidth: 70,
    overflow: "hidden",
  },
  itemRightSide: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    flexGrow: 1,
  },
  commentTopWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  nameAndDateWrapper: {
    display: "flex",
    gap: 7,
    alignItems: "center",
  },
  fiberIcon: {
    color: theme.palette.grey[500],
    width: 4,
    height: 4,
  },
  timeText: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: "14px",
  },
  nameText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    lineHeight: "100%",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  commentText: {
    lineHeight: "21px",
    fontSize: 14,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    wordBreak: "break-word",
  },
  dateText: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  repliesText: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  repliesTextActive: {
    color: theme.palette.primary.main,
  },
  repliesWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  commentInputWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    minHeight: 40,
    borderRadius: 3,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
  },
  addIcon: {
    cursor: "pointer",
    margin: "auto",
    color: "white",
    borderRadius: "100%",
    fontSize: 20,
    backgroundColor: "rgba(0,0,0,0.3)",
    marginRight: 8,
  },
  addIconActive: {
    backgroundColor: theme.palette.primary.main,
  },
  textInput: {
    width: "100%",
    fontFamily: theme.typography.fontFamily,
    resize: "none",
    border: "none",
    outline: "none",
    fontSize: 14,
    padding: "10px 15px",
    backgroundColor: "initial",
  },
}));

type IComment = {
  comment: ITimelineComment;
  disabled?: boolean;
  currentExpandedCommentId: string;
  onClick?: () => void;
  onReplyClick: (id: string) => void;
  addReply?: (text: string) => void;
} & (
  | {
      time?: never;
      onDeleteCommentClick?: never;
      onDeleteReplyClick: (id: string) => void;
    }
  | {
      time: number;
      onDeleteCommentClick: () => void;
      onDeleteReplyClick: (id: string) => void;
    }
);

const Comment = (props: IComment) => {
  const classes = useStyles();
  const { comment, time, disabled, currentExpandedCommentId } = props;
  const { onClick, addReply, onReplyClick, onDeleteCommentClick, onDeleteReplyClick } = props;
  const [replyText, setReplyText] = React.useState("");

  const handleReply = () => {
    if (replyText.trim().length === 0) {
      return;
    }
    if (addReply) {
      addReply(replyText);
    }
    setReplyText("");
  };

  const isComment = time !== undefined;

  const showReplyInput =
    isComment &&
    (currentExpandedCommentId === comment._id || comment.replies.find((r) => r._id === currentExpandedCommentId));

  return (
    <div
      className={classNames(classes.item, !isComment && classes.replyItem)}
      onClick={onClick}
      data-comment-id={comment._id}
    >
      {isComment && (
        <div className={classes.itemLeftSide}>
          <Typography variant="body1" className={classes.timeText}>
            {millisecondsToTime(time * 1000) || "00:00:00"}
          </Typography>
        </div>
      )}
      <div className={classes.itemRightSide}>
        <div className={classes.commentTopWrapper}>
          <div className={classes.nameAndDateWrapper}>
            <Typography variant="body1" className={classes.nameText}>
              {comment.user.name}
            </Typography>
            <FiberManualRecord className={classes.fiberIcon} />
            <Typography variant="body1" className={classes.dateText}>
              {formatDate(comment.updatedAt || comment.createdAt)}
            </Typography>
            <FiberManualRecord className={classes.fiberIcon} />
            {isComment && (
              <Typography variant="body1" className={classes.dateText}>
                {I18n.t(`video_workflow.video_tabs.comments.visibility.${comment.visibility}`)}
              </Typography>
            )}
          </div>
          <MActionsDropdown
            options={[
              {
                label: I18n.t("video_workflow.video_tabs.comments.delete"),
                value: "delete",
                onClick: () => {
                  if (isComment) {
                    onDeleteCommentClick();
                  } else {
                    onDeleteReplyClick(comment._id);
                  }
                },
              },
              // {
              //   label: I18n.t("video_workflow.video_tabs.comments.mark_as_read"),
              //   value: "mark_as_read",
              // },
            ]}
            disabled={disabled}
          />
        </div>
        <MDangerouslySetHtmlContent className={classes.commentText} html={addAnchorTags(comment.text)} />
        <Typography
          variant="body1"
          className={classNames(
            classes.repliesText,
            currentExpandedCommentId === comment._id && classes.repliesTextActive
          )}
          onClick={(e) => {
            e.stopPropagation();
            onReplyClick(comment._id);
          }}
        >
          {currentExpandedCommentId !== comment._id
            ? I18n.t(`video_workflow.video_tabs.comments.reply`)
            : I18n.t("video_workflow.video_tabs.comments.cancel")}
        </Typography>
        {isComment && (
          <>
            <div className={classes.repliesWrapper} onClick={(e) => e.stopPropagation()}>
              {comment.replies.map((reply, index) => (
                <Comment
                  comment={reply}
                  key={reply._id || `reply_${index}`}
                  onReplyClick={onReplyClick}
                  currentExpandedCommentId={currentExpandedCommentId}
                  onDeleteReplyClick={onDeleteReplyClick}
                />
              ))}
              {showReplyInput && (
                <div className={classes.commentInputWrapper}>
                  <TextareaAutosize
                    disabled={disabled}
                    autoFocus
                    value={replyText}
                    minRows={1}
                    maxRows={5}
                    onChange={(e) => setReplyText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (e.shiftKey) {
                          return;
                        }
                        e.preventDefault();
                        handleReply();
                      }
                    }}
                    placeholder={I18n.t("video_workflow.video_tabs.comments.reply_ph")}
                    className={classes.textInput}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Comment;
