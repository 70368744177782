import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import ProductCTADefaultThumbnailImages from "./ProductCTADefaultThumbnailImages";
import { IFile, IProduct, IWebsiteThemeProps } from "../../../../../../../../reducers/constants/objectTypes";
import { Theme } from "@material-ui/core/styles";
import { getImageCDNUrl } from "../../../../../../../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  imgWrapper: {
    display: "flex",
    flexDirection: "column",

    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    gap: "5px",
  },
  mainImg: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    border: (props: PropTypes) => `1px solid ${props.websiteTheme.palette.divider}`,
  },
  mainImgWrapper: {
    width: "100%",
    marginBottom: "-5px",
  },
}));

type PropTypes = {
  product?: IProduct;
  maxImages?: number;
  websiteTheme: IWebsiteThemeProps;
};
const ProductCTADefaultImageLayout = (props: PropTypes) => {
  const { product, maxImages = 6, websiteTheme } = props;
  const classes = useStyles(props);

  const [mainImg, setMainImage] = useState<IFile | null>(null);

  useEffect(() => {
    setMainImage(product?.imageFiles?.[0] || null);
  }, [product]);

  return (
    <div className={classes.imgWrapper}>
      <div className={classes.mainImgWrapper}>
        {mainImg?.url && (
          <img
            className={classes.mainImg}
            src={getImageCDNUrl(mainImg?.url)}
            alt={mainImg?.alt}
            title={mainImg?.title}
          />
        )}
      </div>

      {product?.imageFiles.length > 1 && maxImages > 1 && (
        <ProductCTADefaultThumbnailImages
          websiteTheme={websiteTheme}
          images={product?.imageFiles.slice(0, maxImages)}
          setMainImage={(image) => setMainImage(image)}
        />
      )}
    </div>
  );
};

export default ProductCTADefaultImageLayout;
