import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { IAd, IAdPlacement } from "../../../../../../reducers/constants/objectTypes";
import AdTallSidePreview from "./components/AdTallSidePreview";
import ForumPostImagePreview from "./components/ForumPostImagePreview";
import ForumPostTextOnlyPreview from "./components/ForumPostTextOnlyPreview";
import MobileFeedCenterPreview from "./components/MobileFeedCenterPreview";
import PostFormPreview from "./components/PostFormPreview";
import BlogFeedImagePreview from "./components/BlogFeedImagePreview";
import AdIframe from "../adContent/components/AdIframe";
import AdIframePreview from "./components/AdIframePreview";
import AdEmbeddedHtmlPreview from "./components/AdEmbeddedHtmlPreview";

const useStyles = makeStyles((theme: Theme) => ({
  previewWrapper: {
    height: "100%",
    width: "100%",
    backgroundColor: "#f5f6f8",
    overflow: "auto",
  },
}));

type PropTypes = {
  ad: IAd;
  selectedPlacement: IAdPlacement;
};

const mapStateToProps = (state) => ({
  ad: state.ads.ad,
  selectedPlacement: state.ads.selectedPlacement,
});

const mapDispatchToProps = (dispatch) => ({});

const AdPreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { ad, selectedPlacement } = props;

  if (!selectedPlacement) {
    return null;
  }

  console.log("selectedPlacement", selectedPlacement);

  return (
    <div className={classes.previewWrapper}>
      {(selectedPlacement.type === "TALL_SIDE" ||
        selectedPlacement.type === "FORUM_POST_IMAGE" ||
        selectedPlacement.type === "MOBILE_FEED_CENTER" ||
        selectedPlacement.type === "BLOG_MOBILE_FEED_IMAGE" ||
        selectedPlacement.type === "BLOG_FEED_IMAGE") && (
        <>
          <AdIframe key={selectedPlacement.type} />
          {selectedPlacement.iframeURL && (
            <AdIframePreview
              iframeUrl={selectedPlacement.iframeURL}
              width={selectedPlacement.iframeWidth}
              height={selectedPlacement.iframeHeight}
            />
          )}
          {selectedPlacement.embedHtml && <AdEmbeddedHtmlPreview html={selectedPlacement.embedHtml} />}
        </>
      )}
      {!selectedPlacement.iframeURL && !selectedPlacement.embedHtml && (
        <>
          {selectedPlacement.type === "TALL_SIDE" && <AdTallSidePreview ad={ad} placement={selectedPlacement} />}
          {selectedPlacement.type === "FORUM_POST_IMAGE" && (
            <ForumPostImagePreview ad={ad} placement={selectedPlacement} />
          )}
          {selectedPlacement.type === "FORUM_POST_TEXT_ONLY" && (
            <ForumPostTextOnlyPreview ad={ad} placement={selectedPlacement} />
          )}
          {selectedPlacement.type === "MOBILE_FEED_CENTER" && (
            <MobileFeedCenterPreview ad={ad} placement={selectedPlacement} />
          )}
          {selectedPlacement.type === "BLOG_MOBILE_FEED_IMAGE" && (
            <BlogFeedImagePreview ad={ad} placement={selectedPlacement} />
          )}
          {selectedPlacement.type === "BLOG_FEED_IMAGE" && (
            <BlogFeedImagePreview ad={ad} placement={selectedPlacement} />
          )}
          {selectedPlacement.type === "POST_FORM" && <PostFormPreview ad={ad} placement={selectedPlacement} />}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdPreview);
