import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import ColorPicker from "./ColorPicker";

const useStyles = makeStyles((theme) => ({
  elementWrap: {
    width: "100%",
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  colorsTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    "&:first-child": {
      marginTop: 0,
    },
  },
  colorsRow: {
    display: "flex",
    alignItems: "center",
  },
  colorsToolTipText: {
    cursor: "pointer",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.primary.dark,
    marginLeft: 4,
  },
}));

type PropTypes = {
  rowTitle: string;
  value: string;
  toolTip?: string;
  handleChange: any;
};

const ColorPickerRowToolTip = (props: PropTypes) => {
  const classes = useStyles(props);
  const { rowTitle, value = "", handleChange, toolTip } = props;

  return (
    <div className={classes.elementWrap}>
      <div className={classes.colorsRow}>
        <Typography variant={"body2"} className={classes.colorsTitle}>
          {rowTitle}
        </Typography>
        <Tooltip title={toolTip}>
          <Typography variant={"body2"} className={classes.colorsToolTipText}>
            ?
          </Typography>
        </Tooltip>
      </div>

      <ColorPicker borderRadius={2} color={value} handleChange={handleChange} top={"0px"} left={"150px"} />
    </div>
  );
};
export default ColorPickerRowToolTip;
