import React, { useEffect, useMemo, useState } from "react";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import useReport from "./useReport";
import ReportCard from "./ReportCard";
import { REPORT_CACHE_KEY_NAME } from "../../../../helpers/constants";
import DelivrablesChart from "./charts/DelivrablesChart";

const useStyles = makeStyles((theme: Theme) => ({
  reportCard: {
    "& .recharts-legend-wrapper": {
      top: "0 !important",
      right: "0 !important",
      maxHeight: 270,
      display: "flex",
      overflow: "auto",
    },
  },
  legendTitle: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
    marginBottom: 15,
  },

  customTooltip: {
    background: theme.palette.common.white,
    border: "#e5e5e5 solid 1px",
    boxShadow: " cubic-bezier(0.4, 0, 0.2, 1) ",
    borderRadius: "10px",
  },
  toolTipLabel: {
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightLight as any,
    marginBottom: 10,
    color: theme.palette.text.primary,
  },
  toolTipValues: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  tooltipText: {
    fontSize: "12px",
    fontWeight: theme.typography.fontWeightLight as any,
  },
  tooltipTotalText: {
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold as any,
  },
  tooltipTotal: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    gap: 5,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  startDate: Date;
};

const WordsCountReport = (props: PropTypes) => {
  const classes = useStyles();
  const { selectedWebsite, startDate } = props;
  const [categories, setCategories] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [rawData, setRawData] = React.useState([]);

  const [cacheData, setCacheData] = useState(undefined);

  const { loading, fetchResponse, triggerRefresh, setTriggerRefresh, fullDateFormat, numberFormat, monthFormat } =
    useReport({
      selectedWebsite,
      startDate,
      reportType: "words-count",
    });

  const getCategoriesNames = (results) =>
    _.uniq(results.reduce((accum, currentVal) => [...accum, currentVal.category.name], []));

  const getData = (results, categoriesNames) => {
    const groupedByDateArray = Object.entries(_.groupBy(results, (obj) => obj.date));
    return groupedByDateArray.map((item) => {
      let dayInfo = { date: item[0] };
      categoriesNames.map((name) => {
        let total = 0;
        item[1].map((subItem) => {
          if (name === subItem.category.name) {
            total = subItem.total_words;
          }
          return null;
        });
        dayInfo = { ...dayInfo, [`${name}`]: total };
        return null;
      });
      return dayInfo;
    });
  };

  useEffect(() => {
    if (!fetchResponse) {
      return;
    }
    const categoriesNames = getCategoriesNames(fetchResponse.categoriesWordCount);
    setCategories(categoriesNames);
    setRawData(fetchResponse.categoriesWordCount);
    setData(getData(fetchResponse.categoriesWordCount, categoriesNames));
    setCacheData({
      cache: fetchResponse.cache,
      lastFetch: fetchResponse.fetchedTime,
    });
  }, [fetchResponse]);

  const periodTotalCount = useMemo(() => {
    let total = 0;

    if (rawData && Array.isArray(rawData))
      rawData.forEach((item) => {
        total += item.total_words;
      });

    return total;
  }, [rawData]);

  return (
    <ReportCard
      loading={loading}
      subTitle={I18n.t("reports.dashboard.words_count")}
      reportTypeName={"words-count"}
      setRefreshCache={() => {
        sessionStorage.setItem(REPORT_CACHE_KEY_NAME, "true");
        setTriggerRefresh(!triggerRefresh);
      }}
      cacheData={cacheData}
      containsData={data.length > 0}
      periodTotalCount={periodTotalCount}
      className={classes.reportCard}
    >
      <DelivrablesChart
        data={data}
        dateFormat={monthFormat}
        numberFormat={numberFormat}
        fullDateFormat={fullDateFormat}
        categories={categories}
      />
    </ReportCard>
  );
};

export default WordsCountReport;
