import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";

import { IWidgetContainerPosition } from "../../../../../../../../../../reducers/constants/objectTypes";
import MTextFieldV2 from "../../../../../../../../../../components/MTextFieldV2";
import MSelect from "../../../../../../../../../../components/MSelect";
import MMarginPicker from "../../../../../../../../../../components/MMarginPicker";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import MDynamicListItem from "../../../../../../../../../../components/MDynamicListItem";

const useStyles = makeStyles((theme: Theme) => ({
  positionWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
  },
  groupedItems: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 3,
  },
  selectorWrapper: {
    padding: "0 15px",
    borderRadius: 3,
    borderWidth: 1,
    backgroundColor: theme.palette.secondary.dark,
    "& $selectIndicator": {
      marginLeft: 5,
    },
    "& $selectCustomClass": {
      marginLeft: -5,
    },
  },
  selectIndicator: {
    marginRight: 5,
    width: 12,
  },
  noPaddingIndicator: {
    marginRight: -10,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: theme.palette.divider,
  },
  textField: {
    padding: 0,
    "& .MuiInputBase-multiline": {
      padding: "15px 15px 15px 0",
    },
  },
  advancedText: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  marginPickerWrapper: {
    backgroundColor: "#EEF2F9",
    padding: "0 10px",
    borderRadius: 3,
    height: 55,
    display: "flex",
  },
  marginPicker: {
    width: "100%",
    "& > p": {
      fontWeight: theme.typography.fontWeightRegular as any,
    },
  },
}));

type PropTypes = {
  position: IWidgetContainerPosition;
  onChange: (position: IWidgetContainerPosition) => void;
  onReselectPositionClick: () => void;
};

const I18nRoute = "widgets_display_rules_editor.builder.container_settings_view.position_view";

const WidgetContainerDevicePosition = (props: PropTypes) => {
  const { position, onChange, onReselectPositionClick } = props;
  const [advancedExpanded, setAdvancedExpanded] = React.useState(false);
  const classes = useStyles(props);
  const theme = useTheme<Theme>();

  const positionOptions = [
    { label: I18n.t(`${I18nRoute}.labels.position_above`), value: "above" },
    { label: I18n.t(`${I18nRoute}.labels.position_below`), value: "below" },
  ];

  const selectorOptions = [
    {
      label: I18n.t(`${I18nRoute}.labels.css_selector`),
      value: "css",
    },
    {
      label: I18n.t(`${I18nRoute}.labels.xpath_selector`),
      value: "XPath",
    },
  ];

  return (
    <div className={classes.positionWrapper}>
      <div className={classes.groupedItems}>
        <MDynamicListItem
          title={I18n.t(`${I18nRoute}.labels.position`)}
          noArrow
          fontWeight={theme.typography.fontWeightRegular}
          rightIconsComponent={
            <MSelect
              customClass={classes.selectCustomClass}
              customDropdownIndicatorClasses={classNames(classes.selectIndicator, classes.noPaddingIndicator)}
              valuePadding={"0"}
              placeholder={I18n.t(`${I18nRoute}.placeholders.position`)}
              options={positionOptions}
              value={positionOptions.find((option) => option.value === position.position)}
              optionLabel={"label"}
              optionValue={"value"}
              height={50}
              menuPosition="fixed"
              borderWidth={"0px"}
              isBold={false}
              textColor={theme.palette.text.primary}
              menuPlacement={"bottom"}
              singleValueStyles={{ fontSize: theme.typography.pxToRem(14), marginRight: 5 }}
              fullWidth
              getDisplayLabel={(selected) => `${selected.label}`}
              handleChange={(option) => onChange({ ...position, position: option.value })}
            />
          }
        />
        <div className={classes.marginPickerWrapper}>
          <MMarginPicker
            className={classes.marginPicker}
            margin={position.margin}
            onChange={(margin) => onChange({ ...position, margin })}
          />
        </div>
        <MDynamicListItem
          title={I18n.t(`${I18nRoute}.labels.reselect_position`)}
          fontWeight={theme.typography.fontWeightRegular}
          onClick={onReselectPositionClick}
        />
      </div>
      <div className={classes.groupedItems}>
        <Typography className={classes.advancedText} onClick={() => setAdvancedExpanded((prev) => !prev)}>
          {I18n.t(`${I18nRoute}.labels.advanced_options`)}
          {advancedExpanded ? <ExpandLess /> : <ExpandMore />}
        </Typography>
        {advancedExpanded && (
          <div className={classes.selectorWrapper}>
            <MSelect
              customClass={classes.selectCustomClass}
              customDropdownIndicatorClasses={classes.selectIndicator}
              valuePadding={"0px"}
              options={selectorOptions}
              value={selectorOptions.find((option) => option.value === position.selector.type)}
              optionLabel={"label"}
              optionValue={"value"}
              height={50}
              borderWidth={"0px"}
              isBold={false}
              textColor={theme.palette.text.primary}
              menuPlacement={"bottom"}
              singleValueStyles={{ fontSize: theme.typography.pxToRem(14) }}
              getDisplayLabel={(vertical) => `${vertical.label}`}
              handleChange={(option) =>
                onChange({
                  ...position,
                  selector: {
                    ...position.selector,
                    type: option.value,
                  },
                })
              }
              width={"fit-content"}
            />
            <div className={classes.divider} />
            <MTextFieldV2
              className={classes.textField}
              value={position.selector.value}
              onValueChange={(value) => onChange({ ...position, selector: { ...position.selector, value } })}
              placeholder={`Paste ${
                position.selector.type === "css"
                  ? I18n.t(`${I18nRoute}.placeholders.css`)
                  : I18n.t(`${I18nRoute}.labels.xpath`)
              }...`}
              multiline
              minRows={2}
              maxRows={4}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WidgetContainerDevicePosition;
