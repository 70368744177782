import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  learningCenter: {
    height: "100%",
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  learningCenterContent: {
    backgroundColor: "white",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 93px)",
    overflow: "auto",
  },
  learningCenterInnerContent: {
    width: "100%",
    maxWidth: 1350,
    marginRight: "auto",
    marginLeft: "auto",
  },
}));

type PropTypes = {};

const QueryGroups = (props: PropTypes) => {
  const classes = useStyles(props);

  return (
    <div className={classes.learningCenter}>
      <div className={classes.learningCenterContent}>
        {/* <QueryGroupsSearchBar /> */}
        {/* <div className={classes.learningCenterInnerContent}> */}
        {/*  <QueryGroupsList /> */}
        {/* </div> */}
        {/* <QueryGroupsFab /> */}
      </div>
    </div>
  );
};

export default QueryGroups;
