import React, { useState, useEffect, useRef } from "react";
import throttle from "lodash/throttle";
import { round } from "lodash";

export type UseScrollSpyTabsProps = {
  sections: { label: string; ref: React.RefObject<HTMLDivElement> }[];
  scrollingElementRef?: React.RefObject<HTMLElement>;
  scrollOffsetTop?: number;
  scrollMargin?: number;
  scrollTimeout?: number;
  throttleMs?: number;
};

const useScrollSpyTabs = (props: UseScrollSpyTabsProps) => {
  const { sections, scrollingElementRef, scrollOffsetTop = 0, scrollMargin = 0, throttleMs = 100 } = props;
  const [activeTab, setActiveTab] = useState(0);
  const isScrollingByClick = useRef(false);

  const handleScroll = throttle(() => {
    if (isScrollingByClick.current || !sections.length) {
      return;
    }

    const scrollY = scrollingElementRef.current.scrollTop;
    const clientHeight = scrollingElementRef.current.clientHeight;
    const scrollHeight = scrollingElementRef.current.scrollHeight;

    if (round(scrollY + clientHeight, -1) === round(scrollHeight, -1) && scrollHeight > clientHeight) {
      // Scrolled to the bottom
      setActiveTab(sections.length - 1);
      return;
    }

    const visibleSections = sections.filter(({ ref }) => {
      if (!ref || !ref.current) return false;
      const { top, bottom } = ref.current.getBoundingClientRect();
      return top - scrollOffsetTop < clientHeight - scrollMargin && bottom - scrollOffsetTop > scrollMargin;
    });

    const activeSectionIndex =
      visibleSections.length > 0 ? sections.findIndex((section) => section === visibleSections[0]) : null;
    setActiveTab(activeSectionIndex);
  }, throttleMs);

  useEffect(() => {
    const scrollingElement = scrollingElementRef?.current ?? window;

    if (scrollingElement) {
      scrollingElementRef.current.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
    }
    return () => scrollingElement.removeEventListener("scroll", handleScroll);
  }, [scrollingElementRef, sections, scrollOffsetTop, scrollMargin]);

  const handleTabClick = (index: number) => {
    const sectionElement = sections[index]?.ref?.current;
    if (sectionElement) {
      const scrollingElement = scrollingElementRef?.current ?? window;

      isScrollingByClick.current = true;
      setActiveTab(index);
      const calculatedStartPlus20Px = sectionElement.offsetTop - scrollOffsetTop - 20;

      scrollingElement.scrollTo({
        top: calculatedStartPlus20Px,
        behavior: "smooth",
      });

      setTimeout(() => {
        isScrollingByClick.current = false;
      }, 1000);
    }
  };

  return {
    activeTab,
    handleTabClick,
  };
};

export default useScrollSpyTabs;
