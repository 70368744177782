import * as React from "react";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import useInterval from "../hooks/useInterval";

type PropTypes = {
  lastSaveTime: Date;
  action?: string;
};

const MSavedXMinutesAgo = (props: PropTypes) => {
  const { lastSaveTime, action = "Saved" } = props;
  const [diff, setDiff] = React.useState(null);

  React.useEffect(() => {
    setDiff(moment().diff(moment(lastSaveTime), "minutes"));
  }, [lastSaveTime]);

  useInterval(() => {
    setDiff(moment().diff(moment(lastSaveTime), "minutes"));
  }, 60 * 1000);

  if (lastSaveTime === null || diff === null) {
    return null;
  }

  return (
    <Typography color={"textSecondary"} variant={"body2"}>
      {I18n.t("common.saved_duration_ago", {
        action,
        duration: moment.duration(-diff, "minutes").humanize(true),
      })}
    </Typography>
  );
};

export default React.memo(MSavedXMinutesAgo);
