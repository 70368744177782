import React from "react";
import { DragDropContext } from "react-beautiful-dnd";

export const MyDragDropContext = React.createContext({});

export const DragAndDropContext = ({ children }) => {
  const [eventsMap] = React.useState(() => new Map());

  const addEvent = (event, callback) => {
    // console.log('DragAndDropContext', 'addEvent', event);
    eventsMap.set(event, callback);
  };

  const emitEvent = (event, ...rest) => {
    // console.log('DragAndDropContext', 'emitEvent', event);
    if (!eventsMap.has(event)) return;
    // console.log('DragAndDropContext', 'emitEvent', 'run');
    eventsMap.get(event)(...rest);
  };

  const removeEvent = (event) => {
    // console.log('DragAndDropContext', 'removeEvent', event);
    eventsMap.delete(event);
  };

  const handleDragEnd = (item) => {
    // console.log('DragAndDropContext', 'handleDragEnd', item);
    if (!item.destination) return;
    emitEvent(item.destination.droppableId, { action: "DRAG_END", item });
  };

  const handleDragStart = (item) => {
    // console.log('DragAndDropContext', 'handleDragStart', item);
    if (!item.source) return;
    emitEvent(item.source.droppableId, { action: "DRAG_START", item });
  };

  return (
    <MyDragDropContext.Provider value={{ addEvent, removeEvent }}>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        {children}
      </DragDropContext>
    </MyDragDropContext.Provider>
  );
};
