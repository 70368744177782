import * as React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { push } from "connected-react-router";
import { useState } from "react";
import agent from "../../agent";
import { KEYWORD_STRATEGY } from "../../reducers/constants/actionTypes";
import { IWebsite } from "../../reducers/constants/objectTypes";
import MappingListColumns from "./MappingListColumns";
import MappingListItem from "./MappingListItem";
import MappingTitleDialog from "./MappingTitleDialog";
import MappingFeedbackDialog from "./MappingFeedbackDialog";
import MappingPostTypeDialog from "./MappingPostTypeDialog";
import MTable from "../../components/Table/Table/MTable";
import MTableRowEmptyRow from "../../components/Table/Rows/MTableRowEmptyRow";
import MTableRowProgressBar from "../../components/Table/Rows/MTableRowProgressBar";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  loading: state.keywordStrategy.dataLoading,
  data: state.keywordStrategy.dataList,
  sortField: state.keywordStrategy.sort.field,
  sortDirection: state.keywordStrategy.sort.direction,
  selectedDataItemsIndexes: state.keywordStrategy.selectedDataItemsIndexes,
  refetch: state.keywordStrategy.refetch,
  selectedColumns: state.keywordStrategy.selectedColumns,
  statusFilter: state.keywordStrategy.filter.status,
  selectedStatus: state.keywordStrategy.selectedStatus,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoading(loading: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_LOADING, payload: loading });
  },
  changeIsDialogOpen(open: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_IS_DIALOG_OPEN_CHANGED, payload: open });
  },

  changeActiveDialogTab(tab: "outlines" | "keywords" | "broadKeywords" | "pages") {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_ACTIVE_DIALOG_TAB_CHANGED, payload: tab });
  },
  changeSort(sort: { field: string; direction: "desc" | "asc" }) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SORT_CHANGED, payload: sort });
  },
  changeRefetch(refresh: boolean) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_REFETCH_CHANGED, payload: refresh });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
  changeSelectedItem(item: any) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SELECTED_DATA_ITEM_CHANGED, payload: item });
  },
  updateItem(item: any) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_UPDATE_ITEM, payload: item });
  },
  changeBroadKeywordsLoaded(payload: any) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_BROAD_KEYWORDS_LOADED, payload });
  },
  changeSerpsLoaded(payload) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SERPS_LOADED, payload });
  },
  changeSerpsLoading() {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SERPS_LOADING });
  },
  changeSelectedStatus(status: string) {
    dispatch({ type: KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SELECTED_STATUS_CHANGED, payload: status });
  },
  // old
  redirect: (url) => dispatch(push(url)),
});

type PropTypes = {
  loading: boolean;
  data: any[];
  sortDirection: "desc" | "asc";
  sortField: string;
  selectedDataItemsIndexes: number[];
  refetch: boolean;
  selectedWebsite: IWebsite;
  selectedColumns: string[];
  statusFilter: string[];

  changeSerpsLoading(): void;
  changeLoading(loading: boolean): void;
  changeBroadKeywordsLoaded(payload: any): void;
  changeSerpsLoaded(payload: any): void;
  changeSelectedItem(item: any): void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  changeSort(sort: { field: string; direction: "desc" | "asc" }): void;
  changeIsDialogOpen: (open: boolean) => void;
  changeActiveDialogTab: (tab: "outlines" | "keywords" | "pages") => void;
  changeRefetch: (refresh: boolean) => void;
  changeSelectedStatus: (status: string) => void;
  updateItem: (item: any) => void;
  redirect: (url: string) => void;
  selectedStatus: string;
};

const MappingList = (props: PropTypes) => {
  const { enqueueSnackbar } = useSnackbar();

  const [titleDialogOpen, setTitleDialogOpen] = useState(false);

  const [postTypeDialogOpen, setPostTypeDialogOpen] = useState(false);

  const [feedBackDialogOpen, setFeedBackDialogOpen] = useState(false);
  const [feedbackModify, setFeedbackModify] = useState(false);

  const {
    changeLoading,
    changeSerpsLoaded,
    selectedWebsite,
    changeSort,
    changeActiveDialogTab,
    changeIsDialogOpen,
    changeSelectedDataItemsIndexes,
    loading,
    selectedDataItemsIndexes,
    data,
    refetch,
    changeRefetch,
    sortField,
    sortDirection,
    changeSelectedItem,
    selectedColumns,
    changeBroadKeywordsLoaded,
    changeSerpsLoading,
    statusFilter,
    changeSelectedStatus,
    selectedStatus,
    updateItem,
  } = props;

  const pendingItems = data?.filter((o) => o.status === "pending");
  const approvedItems = data?.filter((o) => o.status === "approved" || o.status === "rejected");

  const openDialog = async (strategyKeywords: any, tab: "outlines" | "keywords" | "pages") => {
    changeSelectedItem(strategyKeywords);
    changeBroadKeywordsLoaded(
      await agent.KeywordStrategy.getBroadKeywords(selectedWebsite._id, strategyKeywords.keyword)
    );
    changeSerpsLoading();
    agent.KeywordStrategy.getSerps(selectedWebsite._id, strategyKeywords.keyword)
      .then((serps) => {
        changeSerpsLoaded(serps);
      })
      .catch((e) => {
        console.log(e);
      });

    changeActiveDialogTab(tab);
    changeIsDialogOpen(true);
  };

  const openFeedbackDialog = (strategyKeyword: any) => {
    changeSelectedItem(strategyKeyword);
    setFeedBackDialogOpen(true);
  };

  const openTitleDialog = (strategyKeyword: any) => {
    changeSelectedItem(strategyKeyword);

    setTitleDialogOpen(true);
  };

  const closeTitleDialog = () => {
    setTitleDialogOpen(false);
    changeSelectedItem(null);
  };

  const openPostTypeDialog = (strategyKeyword: any) => {
    changeSelectedItem(strategyKeyword);

    setPostTypeDialogOpen(true);
  };

  const closePostTypeDialog = () => {
    setPostTypeDialogOpen(false);
    changeSelectedItem(null);
  };

  const changeFeedback = async (item, feedback) => {
    try {
      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, {
        ...item,
        feedback,
      });
      updateItem({ ...item, feedback });
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.feedback_saved"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    setTitleDialogOpen(false);
    changeSelectedDataItemsIndexes([]);
  };
  const changeSuggestedTitle = async (item, suggestedTitle) => {
    try {
      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, {
        ...item,
        suggested_title: suggestedTitle,
      });
      updateItem({ ...item, suggested_title: suggestedTitle });
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.suggested_title_updated"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    setTitleDialogOpen(false);
    changeSelectedDataItemsIndexes([]);
  };

  const changePostType = async (item, postType) => {
    try {
      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, {
        ...item,
        post_type: postType,
      });
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.post_type_updated"));
      updateItem({ ...item, post_type: postType });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    setPostTypeDialogOpen(false);
    changeSelectedDataItemsIndexes([]);
  };
  const approve = async (item, feedback?: string) => {
    try {
      const updateData = {
        ...item,
        status: "approved",
        reviewed_at: new Date(),
      };

      if (feedback) {
        updateData.feedback = feedback;
      }

      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, updateData);
      await agent.OutlinePages.updateStatusBulk(selectedWebsite._id, [item.keyword], "approved");
      updateItem(updateData);
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.approved"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }

    changeSelectedDataItemsIndexes([]);
  };

  const returnToPending = async (item) => {
    try {
      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, {
        ...item,
        status: "pending",
      });
      await agent.OutlinePages.updateStatusBulk(selectedWebsite._id, [item.keyword], "pending");
      updateItem({ ...item, status: "pending" });
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.topic_returned_to_pending"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    changeSelectedDataItemsIndexes([]);
  };

  const returnToMapping = async (item) => {
    try {
      const updateData = {
        ...item,
        status: "new",
      };

      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, updateData);
      await agent.OutlinePages.updateStatusBulk(selectedWebsite._id, [item.keyword], "new");
      updateItem(updateData);
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.returned_to_mapping"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    changeSelectedDataItemsIndexes([]);
  };
  const reject = async (item, feedback?: string) => {
    try {
      const updateData = {
        ...item,
        status: "rejected",
        reviewed_at: new Date(),
      };

      if (feedback) {
        updateData.feedback = feedback;
      }
      await agent.KeywordStrategy.updateKeywordStrategy(selectedWebsite._id, item.keyword, updateData);
      await agent.OutlinePages.updateStatusBulk(selectedWebsite._id, [item.keyword], "rejected");
      updateItem(updateData);
      enqueueSnackbar(I18n.t("strategy.strategyKeywords.reject_sucecss"));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
    changeSelectedDataItemsIndexes([]);
  };

  const selectAll = (pending = true) => {
    const status = pending ? "pending" : "approved";
    if (!selectedStatus || status === selectedStatus) {
      changeSelectedStatus(status);
      changeSelectedDataItemsIndexes([...(pending ? pendingItems : approvedItems)].map((o, index) => index));
    }
  };

  return (
    <MTable>
      <MappingListColumns
        pendingTotal={pendingItems?.length || 0}
        reviewTotal={approvedItems?.length || 0}
        selectedColumns={selectedColumns}
        clearSelected={() => {
          changeSelectedDataItemsIndexes([]);
          changeSelectedStatus(null);
        }}
        selectAll={selectAll}
        selectedNr={selectedStatus == "pending" ? selectedDataItemsIndexes?.length : 0}
        sortField={{
          direction: sortDirection,
          field: sortField,
        }}
        setSortField={changeSort}
      />

      {!loading && pendingItems?.length < 1 && (
        <MTableRowEmptyRow
          message={
            statusFilter.length > 0 && !statusFilter.includes("pending")
              ? undefined
              : I18n.t("strategy.outlinePages.no_pending_topics")
          }
        />
      )}

      {loading && <MTableRowProgressBar />}

      {!loading &&
        pendingItems?.length > 0 &&
        pendingItems.map((item, index) => (
          <>
            {
              <MappingListItem
                returnToMapping={returnToMapping}
                openFeedbackDialog={openFeedbackDialog}
                changeFeedbackModify={(val) => setFeedbackModify(val)}
                openTitleDialog={openTitleDialog}
                openPostTypeDialog={openPostTypeDialog}
                reject={reject}
                approve={approve}
                selected={selectedDataItemsIndexes.includes(index) && (!selectedStatus || selectedStatus === "pending")}
                setSelect={(selected) => {
                  if (selectedStatus === "approved") {
                    return;
                  }
                  changeSelectedStatus("pending");
                  if (selected) {
                    changeSelectedDataItemsIndexes([...selectedDataItemsIndexes, index]);
                  } else {
                    changeSelectedDataItemsIndexes(selectedDataItemsIndexes.filter((i) => i !== index));
                    if (selectedDataItemsIndexes.filter((i) => i !== index).length === 0) {
                      changeSelectedStatus(null);
                    }
                  }
                }}
                item={item}
                key={index}
                onClick={() => {
                  openDialog(item, "pages");
                }}
              />
            }
          </>
        ))}

      {/* APPROVED */}

      {!loading && approvedItems?.length > 0 && (
        <MappingListColumns
          isApproveSection
          pendingTotal={pendingItems?.length || 0}
          reviewTotal={approvedItems?.length || 0}
          onlyFirstColumn
          clearSelected={() => {
            changeSelectedDataItemsIndexes([]);
            changeSelectedStatus(null);
          }}
          selectAll={selectAll}
          selectedNr={selectedStatus == "approved" ? selectedDataItemsIndexes?.length : 0}
          sortField={{
            direction: sortDirection,
            field: sortField,
          }}
          setSortField={changeSort}
        />
      )}

      {loading && <MTableRowProgressBar />}

      {!loading &&
        approvedItems?.length > 0 &&
        approvedItems.map((o, index) => (
          <MappingListItem
            returnToMapping={returnToMapping}
            noSelectable
            openFeedbackDialog={openFeedbackDialog}
            openPostTypeDialog={openPostTypeDialog}
            changeFeedbackModify={(val) => setFeedbackModify(val)}
            openTitleDialog={openTitleDialog}
            selected={selectedDataItemsIndexes.includes(index) && (!selectedStatus || selectedStatus === "approved")}
            approve={approve}
            item={o}
            key={index}
            returnToPending={returnToPending}
            setSelect={(selected) => {
              if (selectedStatus === "pending") {
                return;
              }
              changeSelectedStatus("approved");
              if (selected) {
                changeSelectedDataItemsIndexes([...selectedDataItemsIndexes, index]);
              } else {
                changeSelectedDataItemsIndexes(selectedDataItemsIndexes.filter((i) => i !== index));
                if (selectedDataItemsIndexes.filter((i) => i !== index).length === 0) {
                  changeSelectedStatus(null);
                }
              }
            }}
            onClick={() => {
              openDialog(o, "pages");
            }}
          />
        ))}
      <MappingTitleDialog
        open={titleDialogOpen}
        closeDialog={closeTitleDialog}
        saveSuggestedTitle={changeSuggestedTitle}
      />
      <MappingPostTypeDialog
        open={postTypeDialogOpen}
        closeDialog={closePostTypeDialog}
        savePostType={changePostType}
      />
      <MappingFeedbackDialog
        feedbackModify={feedbackModify}
        open={feedBackDialogOpen}
        closeDialog={() => {
          setFeedBackDialogOpen(false);
        }}
        reject={reject}
        changeFeedback={changeFeedback}
      />
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MappingList);
