import React, { useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import CfProgressBar from "../../../../../components/CfProgressBar";
import agent from "../../../../../agent";
import _ from "lodash";
import useDebounce from "../../../../../hooks/useDebounce";
import { IFunnelBreakdownColumn, IWebsite } from "../../../../../reducers/constants/objectTypes";
import DownChevronIcon from "../../../../../icons/DownChevronIcon";
import { getWebsiteRelativeUrl, isUrl } from "../../../../../helpers/urlHelper";
import PublicIcon from "@material-ui/icons/Public";
import MLink from "../../../../../components/MLink";
import IconButton from "@material-ui/core/IconButton";
import { copyStringToClipboard } from "../../../../../helpers/fbScraperHelper";
import CopyToClipboardIcon from "../../../../../icons/CopyToClipboardIcon";
import LinkPreviewer from "../../../../../editor/elements/link/LinkPreviewer";
import { useSnackbar } from "notistack";
import OpenInNewIcon from "../../../../../icons/OpenInNewIcon";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    alignItems: "center",
  },
  titleWrapper: {
    cursor: "pointer",
    color: theme.palette.text.disabled,
    display: "flex",
    alignItems: "center",
    columnGap: 5,
    margin: "0 10px",
    maxWidth: 343,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 10,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
  },
  popper: {
    zIndex: 100,
  },

  searchField: {
    width: "100%",
    color: "#000",
    height: 33,
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette.divider,
    },
    "& .MuiInput-root": {
      height: "100%",
    },
    "& .MuiInputAdornment-positionStart": {
      margin: 0,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },
  searchIcon: {
    color: theme.palette.primary.dark,
  },
  searchWrap: {
    padding: "0 10px",
  },
  searchInput: {
    padding: 0,
    color: "#000",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
    gap: 3,
    maxHeight: 162,
    width: 290,
    minHeight: 100,
    overflowY: "auto",
  },
  selectionResult: {
    cursor: "pointer",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    // textTransform: "lowercase",
    overflowX: "hidden",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // "&:first-letter": {
    //   textTransform: "uppercase",
    // },
  },
  selectedResults: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  checkbox: {
    margin: 0,
    cursor: "pointer",
    padding: "7px 10px",
    "&:hover": {
      fill: theme.palette.primary.main,
      backgroundColor: "#E6EEFF",
    },
  },
  checkboxLabel: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight as any,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    // textTransform: "lowercase",
    // "&:first-letter": {
    //   textTransform: "uppercase",
    // },
  },
  selectedLabel: {
    fill: theme.palette.primary.main,
    backgroundColor: "#E6EEFF",
    "& $checkboxLabel": {
      color: theme.palette.primary.main,
    },
  },
  loading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 30,
  },
  active: {
    color: theme.palette.primary.main,
  },
  noResults: {
    padding: "10px 10px",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(14),
  },
  dropdownIcon: {
    fill: theme.palette.text.primary,
  },
  previewPaper: {
    width: 400,
  },
  linkTooltipWrapper: {
    padding: "2px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  urlWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  globeIcon: {
    color: theme.palette.text.secondary,
    marginRight: 5,
  },
  tooltipLinkText: {
    color: theme.palette.primary.main,
    overflow: "hidden",
    maxWidth: 290,
    textOverflow: "ellipsis",
    whiteSpace: "pre",
  },
  actionsWrapper: {
    marginLeft: 5,
  },
  actionButton: {
    padding: "8px 5px",
    background: "none",
    border: "none",
  },
  actionIcon: {
    fontSize: 18,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  linePreviewWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  linkTooltip: {
    margin: 0,
    padding: 0,
    backgroundColor: "white",
    maxWidth: "unset",
  },
  linkTooltipPopper: {
    zIndex: 1000,
  },
}));

type PropTypes = {
  value: any;
  placeholder?: string;
  optionLabel?: string;
  optionValue?: string;
  handleChange: (value: any) => void;
  titleClassName?: string;
  selectedWebsite: IWebsite;
  filter: IFunnelBreakdownColumn;
};

const ColumnValueFilter = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    optionLabel = "label",
    optionValue = "value",
    filter,
    placeholder,
    value,
    handleChange,
    selectedWebsite,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(value);
  const [searchText, setSearchText] = React.useState("");
  const [filteredOptions, setFilteredOptions] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const debounceColumnTerm = useDebounce(searchText, 500);

  useEffect(() => {
    setLoading(true);
    agent.Funnels.getColumnValues(
      selectedWebsite,
      filter.autocomplete?.table || filter.value.table,
      filter.autocomplete?.field || filter.value.field,
      debounceColumnTerm,
      currentPage
    )
      .then((res) => {
        let values = debounceColumnTerm ? [{ value: debounceColumnTerm, label: debounceColumnTerm }] : [];
        const data = res.data.map((item) => {
          console.log("item", item, filter);
          return {
            value: item.value,
            label: filter?.value?.getLabel?.(item.value) || item.label,
          };
        });
        console.log("set setFilteredOptions");
        setFilteredOptions(values.length > 0 ? _.uniqBy([...values, ...data], "value") : data);
      })
      .catch((err) => {
        console.log(`Error fetching col values `, err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounceColumnTerm, filter, value]);

  const urlPreview = React.useMemo(() => {
    return isUrl(value?.[optionLabel] || selected?.[optionLabel]);
  }, [value, selected]);

  const previewTitle = (value: string) => {
    if (!urlPreview) {
      return value;
    }
    return (
      <div className={classes.previewPaper} contentEditable={false}>
        <div className={classes.linkTooltipWrapper}>
          <div className={classes.urlWrapper}>
            <PublicIcon className={classes.globeIcon} />
            <Tooltip enterDelay={1000} title={value}>
              <MLink typographyClassName={classes.tooltipLinkText} href={value} newTab>
                {value}
              </MLink>
            </Tooltip>
          </div>
          <div className={classes.actionsWrapper}>
            <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.insert_link.copy_link")}>
              <IconButton
                className={classes.actionButton}
                onClick={(event) => {
                  event.stopPropagation();
                  copyStringToClipboard(value, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard")));
                }}
              >
                <CopyToClipboardIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.insert_link.new_tab")}>
              <IconButton className={classes.actionButton} onClick={() => window.open(value, "_blank")}>
                <OpenInNewIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className={classes.linePreviewWrapper}>
          <LinkPreviewer url={value} width={"390px"} />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.wrapper}>
      <Tooltip
        classes={
          urlPreview
            ? {
                tooltip: classes.linkTooltip,
                popper: classes.linkTooltipPopper,
              }
            : {}
        }
        interactive={urlPreview}
        title={anchorEl ? "" : previewTitle(value?.[optionLabel] || selected?.[optionLabel])}
        placement="bottom"
      >
        <div className={classes.titleWrapper} onClick={(event) => handleClick(event)}>
          <Typography
            variant={"body1"}
            className={ClassNames(
              classes.selectionResult,
              value?.[optionValue] && classes.selectedResults,
              anchorEl && classes.active
            )}
            color={"textPrimary"}
          >
            {getWebsiteRelativeUrl(value?.[optionLabel] || selected?.[optionLabel], selectedWebsite) ||
              placeholder ||
              I18n.t("common.filter_value")}
          </Typography>
          <DownChevronIcon className={classes.dropdownIcon} />
        </div>
      </Tooltip>
      <Popper open={open} anchorEl={anchorEl} transition placement={"bottom-start"} className={classes.popper}>
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
            setSelected(value);
          }}
        >
          <div className={classes.paper}>
            <div className={classes.searchWrap}>
              <TextField
                type="search"
                variant={"standard"}
                autoFocus
                value={searchText}
                className={classes.searchField}
                placeholder={placeholder || "Search value.."}
                onKeyDown={(event) => {
                  // console.log("onKeyDown", searchText, debounceColumnTerm, event.target, event?.nativeEvent?.inputType);
                  if (event.key === "Enter" && searchText) {
                    handleChange({ [optionValue]: searchText, [optionLabel]: searchText });
                    setAnchorEl(null);
                  }
                }}
                onChange={(event) => {
                  // hack to determine when someone is clicking on the clear icon in the input
                  if (!event.target.value && event?.nativeEvent?.inputType !== "insertText") {
                    handleChange({ [optionValue]: event.target.value, [optionLabel]: event.target.value });
                  }
                  setSearchText(event.target.value);
                }}
                InputProps={{
                  classes: { input: classes.searchInput },
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.content}>
              {!loading &&
                filteredOptions &&
                filteredOptions.length > 0 &&
                filteredOptions.map((option, index) => (
                  <div
                    key={`${option[optionValue]}-${index}`}
                    className={ClassNames(
                      classes.checkbox,
                      value?.[optionValue] === option[optionValue] && classes.selectedLabel
                    )}
                    onClick={() => {
                      handleChange(option);
                      setSearchText(option[optionValue]);
                      setAnchorEl(null);
                    }}
                  >
                    <Tooltip title={option[optionLabel]} placement="bottom" style={{ zIndex: 1000 }}>
                      <Typography className={classes.checkboxLabel}>{option[optionLabel]}</Typography>
                    </Tooltip>
                  </div>
                ))}
              {loading && (
                <div className={classes.loading}>
                  <CfProgressBar size={20} />
                </div>
              )}
              {!loading && filteredOptions && filteredOptions.length === 0 && searchText && (
                <Typography className={classes.noResults} color={"textPrimary"}>
                  {I18n.t("common.no_results")}
                </Typography>
              )}
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default ColumnValueFilter;
