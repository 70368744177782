import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { I18n } from "react-redux-i18n";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import PageLocale from "./PageLocale";

const useStyles = makeStyles((theme: Theme) => ({
  sectionWrapper: {
    borderRadius: 0,
    border: "none",
    marginTop: 10,
  },
  innerWrapper: {
    padding: 30,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 20,
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
  },
}));

type PropTypes = {
  website?: IWebsite;
  locale?: string;
  className?: string;
  sectionTitle: string;
  children: React.ReactElement | React.ReactElement[];

  checked?: boolean;
  onCheckedChange?: (checked) => void;
  handleLocaleChange?: (locale) => void;
};

const pageSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    website = null,
    locale = null,
    sectionTitle,
    checked = false,
    onCheckedChange,
    className = null,
    children,
    handleLocaleChange = null,
  } = props;

  return (
    <>
      <PageLocale website={website} locale={locale} handleLocaleChange={handleLocaleChange} />
      <Paper elevation={0} className={classes.sectionWrapper}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
            {sectionTitle}
          </Typography>
          {onCheckedChange && (
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={checked} onChange={() => onCheckedChange(!checked)} color="primary" />}
                labelPlacement="start"
                label={I18n.t(`website.${checked ? "enabled" : "disabled"}`)}
              />
            </FormGroup>
          )}
        </div>
        <div className={ClassNames(classes.innerWrapper, className)}>{children}</div>
      </Paper>
    </>
  );
};

export default pageSection;
