import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state/index";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { ePageType, ePostType, IAdminUser } from "../../../../../reducers/constants/objectTypes";
import MTextField from "../../../../../components/MTextField";
import theme from "../../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  selectorWrapper: {
    display: "flex",
  },
  dropDownWrapper: {},
  newItemButton: {
    width: "100%",
    height: 60,
    borderRadius: "0px 0px 5px 5px",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
    textTransform: "capitalize" as const,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  newItemButtonLocked: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.secondary,
    },
  },
  searchWrapper: {
    height: 80,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchTextField: {
    margin: "0px 10px 0px 10px",
  },
  searchTextFieldLabel: {
    "&$searchTextFieldFocused": {
      color: theme.palette.text.secondary,
    },
  },
  searchTextFieldFocused: {},
  searchTextFieldUnderline: {
    "&:after": {
      borderBottomColor: theme.palette.text.secondary,
    },
  },
  searchTextFieldOutlinedInput: {
    height: 40,
    paddingRight: 0,
    "&$searchTextFieldNotchedOutline": {
      borderColor: "yellow",
      borderWidth: 1,
    },
    "&$searchTextFieldFocused $searchTextFieldNotchedOutline": {
      borderColor: theme.palette.divider,
      borderWidth: 1,
    },
  },
  searchTextFieldNotchedOutline: {
    borderColor: `${theme.palette.divider} !important`,
    // borderWidth: 1,
    borderWidth: "0px 0px 1px 0px !important",
    borderRadius: 0,
  },
  searchIcon: {
    color: theme.palette.primary.dark,
  },
  itemsList: {
    overflowY: "auto" as const,
    maxHeight: 240,
    minHeight: 40,
  },
  itemsListItem: {
    cursor: "pointer",
    height: 38,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& $itemsListItemText": {
        color: theme.palette.primary.main,
      },
      "& $listItemIcon": {
        visibility: "unset",
      },
    },
  },
  selectWebsites: {
    cursor: "pointer",
    whiteSpace: "pre-wrap",
  },
  itemsListItemText: {
    fontWeight: theme.typography.fontWeightMedium as any,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
  },
  itemsListSelected: {
    backgroundColor: theme.palette.secondary.main,
    "& $itemsListItemText": {
      color: theme.palette.primary.main,
    },
  },
  listItemIcon: {
    minWidth: "unset",
  },
  popoverPaper: {},
  popoverOfflineIcon: {
    fill: "#627cfb",
    backgroundColor: theme.palette.primary.light,
    borderRadius: "50%",
    fontSize: 16,
    marginLeft: 10,
    "&:hover": {
      fill: "#627cfb",
    },
  },
  inputLabel: {
    fontWeight: theme.typography.fontWeightMedium as any,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    marginBottom: "4px",
  },
  textField: {
    flex: 1,
    margin: "0px 10px 0px 10px",
  },
  textFieldInput: {},
  fieldRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

type PropTypes = {
  user: IAdminUser;
  ppw?: boolean;

  buttonWrapperClass?: any;
  width?: number;

  transformHorizontal?: "left" | "center" | "right" | number;

  handleUserChanged: Function;
};

const UserPPWPicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const { ppw = true, user, handleUserChanged } = props;
  const { buttonWrapperClass, transformHorizontal = "right", width = null } = props;
  const priceType = ppw ? "ppwByType" : "pptByType";

  const getPrefix = (name: string) => {
    let prefix = "";
    for (let i = 0; i < name.length; i++) {
      if (name[i] === name[i].toUpperCase()) prefix += name[i];
    }

    if (prefix.length === 0) prefix = name[0].toUpperCase();
    return prefix;
  };

  const getLabel = (type) => {
    const splitUpString = type.replace(/([A-Z])/g, " $1").trim();
    if (splitUpString.length === 0) return splitUpString;
    return `${splitUpString[0].toUpperCase() + splitUpString.substring(1)} - ¢`;
  };

  const getPrices = (priceType: "pptByType" | "ppwByType") => {
    const dynamicPageTypes = [];
    if (user.websites) {
      user.websites.forEach((website) => {
        website.configurations.dynamicSections.forEach((section) => {
          dynamicPageTypes.push(section.type);
        });
      });
    }

    const staticPageTypes = Object.values(ePageType);

    const prices = [];
    [...staticPageTypes, ...dynamicPageTypes, ePostType.COMMUNITY].forEach((pageType) => {
      if (!user[priceType]) user[priceType] = {};
      prices.push({
        prefix: getPrefix(pageType),
        label: getLabel(pageType),
        value: user[priceType][pageType] || 0,
        key: pageType,
      });
    });
    return prices;
  };

  return (
    <div className={classes.selectorWrapper}>
      <PopupState variant="popover" popupId="posts-type-popover">
        {(popupState) => (
          <div className={buttonWrapperClass || null}>
            <div className={classes.selectWebsites} role="cell" {...bindTrigger(popupState)}>
              {getPrices(priceType)
                .map((x) => `${x.prefix} - ${x.value}`)
                .join(", ")}
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: transformHorizontal,
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: transformHorizontal,
              }}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <div style={{ width: width || "unset", padding: 20 }}>
                {[...getPrices(priceType)].map((field) => (
                  <div key={field.label} className={classes.fieldRow}>
                    <Typography color={"textSecondary"} variant={"subtitle2"}>
                      {field.label}
                    </Typography>
                    <MTextField
                      customClass={classes.textField}
                      inputClassName={classes.textFieldInput}
                      type={"number"}
                      onChange={(amount) =>
                        handleUserChanged({
                          ...user,
                          [priceType]: { ...(user[priceType] || {}), [field.key]: parseInt(amount, 10) },
                        })
                      }
                      value={field.value}
                      placeholder={"Amount in cents"}
                    />
                  </div>
                ))}
              </div>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default UserPPWPicker;
