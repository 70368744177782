import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { IWebsite, IWebsiteHomepageProps } from "../../../../../reducers/constants/objectTypes";
import TextInputToolAndDesign from "../../../components/TextInputToolAndDesign";
import ProductPickerNoRepetitions from "../commonComponents/ProductPickerNoRepetitions";
import PageSection from "../../../components/PageSection";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import { find } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  productsTitle: {
    fontSize: 14,
    marginBottom: 20,
  },
  products: {
    display: "flex",
    flexDirection: "column",
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    gap: 10,
  },

  closeIcon: {
    fontSize: 17,
    cursor: "pointer",
    marginLeft: 7,
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  hidden: {
    visibility: "hidden",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  homepage: IWebsiteHomepageProps;
  onPropertyChanged: (value) => void;
};

const TopProductsKeepshoppers = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, homepage, onPropertyChanged } = props;
  const [topProductsKeepshoppersError, setTopProductsKeepshoppersError] = React.useState([]);

  const topProductsSectionKeepshoppersValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!homepage?.topProductsSectionKeepshoppers?.title.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.templates.keepshoppers.top_products_section.title`));
    }
    if (!homepage?.topProductsSectionKeepshoppers?.description.text) {
      valid = false;
      emptyFields.push(I18n.t(`website.homepage.templates.keepshoppers.top_products_section.description`));
    }
    setTopProductsKeepshoppersError(emptyFields);
    return valid;
  };

  React.useEffect(() => {
    if (topProductsKeepshoppersError.length <= 0) {
      return;
    }
    topProductsSectionKeepshoppersValidated();
  }, [homepage?.topProductsSectionKeepshoppers]);

  return (
    <PageSection
      sectionTitle={I18n.t("website.homepage.templates.keepshoppers.top_products_section.top_products_section")}
      checked={homepage?.topProductsSectionKeepshoppers?.enabled || false}
      onCheckedChange={(checked) => {
        if (!topProductsSectionKeepshoppersValidated()) {
          if (!checked && homepage?.topProductsSectionKeepshoppers?.enabled) {
            onPropertyChanged({
              ...homepage,
              topProductsSectionKeepshoppers: { ...homepage?.topProductsSectionKeepshoppers, enabled: checked },
            });
          }
          return;
        }
        onPropertyChanged({
          ...homepage,
          topProductsSectionKeepshoppers: { ...homepage?.topProductsSectionKeepshoppers, enabled: checked },
        });
      }}
    >
      {topProductsKeepshoppersError.length > 0 && (
        <div className={classes.errorWrapper}>
          <Typography variant={"body1"} className={classes.error}>
            {topProductsKeepshoppersError.length > 0 && I18n.t("website.error_message")}
          </Typography>
        </div>
      )}
      <TextInputToolAndDesign
        error={topProductsKeepshoppersError.includes(
          I18n.t(`website.homepage.templates.keepshoppers.top_products_section.title`)
        )}
        textFieldTitle={I18n.t("website.homepage.templates.keepshoppers.top_products_section.title")}
        text={homepage?.topProductsSectionKeepshoppers?.title}
        handleTextChanged={(text) =>
          onPropertyChanged({
            ...homepage,
            topProductsSectionKeepshoppers: { ...homepage?.topProductsSectionKeepshoppers, title: text },
          })
        }
        variant={"h4"}
      />
      <TextInputToolAndDesign
        error={topProductsKeepshoppersError.includes(
          I18n.t(`website.homepage.templates.keepshoppers.top_products_section.description`)
        )}
        textFieldTitle={I18n.t("website.homepage.templates.keepshoppers.top_products_section.description")}
        text={homepage?.topProductsSectionKeepshoppers?.description}
        handleTextChanged={(text) =>
          onPropertyChanged({
            ...homepage,
            topProductsSectionKeepshoppers: { ...homepage?.topProductsSectionKeepshoppers, description: text },
          })
        }
        variant={"h6"}
      />
      <Divider className={classes.divider} />
      <div>
        <Typography variant={"subtitle2"} className={classes.productsTitle}>
          {I18n.t("website.homepage.templates.keepshoppers.top_products_section.products")}
        </Typography>
        <div className={classes.products}>
          {[...(homepage?.topProductsSectionKeepshoppers?.products || []), null].map((product, index) => {
            //  const p = find(homepage?.products||[],(p)=>product?.itemNumber===p.itemNumber);
            return (
              <div key={`${product?.itemNumber || ""}_${index}`} className={classes.rowWrapper}>
                <div className={classes.row}>
                  <ProductPickerNoRepetitions
                    titleAndLengthLocation={"top"}
                    title={product?.displayName || `Product ${index + 1}`}
                    height={37}
                    fullWidth
                    borderRadius={4}
                    selectedWebsite={selectedWebsite}
                    product={product}
                    selectedProducts={homepage?.topProductsSectionKeepshoppers?.products}
                    handleChange={(selectedProd) => {
                      if ((homepage?.topProductsSectionKeepshoppers?.products || []).length === index) {
                        const updatedProducts = [
                          ...(homepage?.topProductsSectionKeepshoppers?.products || []),
                          {
                            itemNumber: selectedProd.itemNumber,
                            name: selectedProd.name,
                            displayName: selectedProd.displayName,
                            reviewLink: selectedProd.reviewLink,
                          },
                        ];
                        onPropertyChanged({
                          ...homepage,
                          topProductsSectionKeepshoppers: {
                            ...homepage?.topProductsSectionKeepshoppers,
                            products: updatedProducts,
                          },
                        });
                        return;
                      }
                      const updatedProducts = [...(homepage?.topProductsSectionKeepshoppers?.products || [])];
                      updatedProducts[index].itemNumber = selectedProd.itemNumber;
                      updatedProducts[index].name = selectedProd.name;
                      updatedProducts[index].displayName = selectedProd.displayName;
                      updatedProducts[index].reviewLink = selectedProd.reviewLink;
                      onPropertyChanged({
                        ...homepage,
                        topProductsSectionKeepshoppers: {
                          ...homepage?.topProductsSectionKeepshoppers,
                          products: updatedProducts,
                        },
                      });
                    }}
                  />
                  <TextFieldMaxLength
                    titleAndLengthLocation={"top"}
                    value={product?.url || product?.reviewLink || ""}
                    placeholder={""}
                    title={I18n.t("website.homepage.templates.keepshoppers.top_products_section.product_url")}
                    maxLength={200}
                    height={37}
                    borderRadius={4}
                    handleValueChange={(value) => {
                      if ((homepage?.topProductsSectionKeepshoppers?.products || []).length === index) {
                        const updatedProducts = [
                          ...(homepage?.topProductsSectionKeepshoppers?.products || []),
                          { url: value },
                        ];
                        onPropertyChanged({
                          ...homepage,
                          topProductsSectionKeepshoppers: {
                            ...homepage?.topProductsSectionKeepshoppers,
                            products: updatedProducts,
                          },
                        });
                        return;
                      }
                      const updatedProducts = [...(homepage?.topProductsSectionKeepshoppers?.products || [])];
                      updatedProducts[index].url = value;
                      onPropertyChanged({
                        ...homepage,
                        topProductsSectionKeepshoppers: {
                          ...homepage?.topProductsSectionKeepshoppers,
                          products: updatedProducts,
                        },
                      });
                    }}
                    marginBottom={30}
                  />
                </div>
                <CloseIcon
                  className={ClassNames(
                    classes.closeIcon,
                    homepage.topProductsSectionKeepshoppers.products.length === index && classes.hidden
                  )}
                  onClick={() => {
                    const updatedProducts = [...homepage.topProductsSectionKeepshoppers.products].filter(
                      (x, i) => index !== i
                    );
                    onPropertyChanged({
                      ...homepage,
                      topProductsSectionKeepshoppers: {
                        ...homepage?.topProductsSectionKeepshoppers,
                        products: updatedProducts,
                      },
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </PageSection>
  );
};

export default TopProductsKeepshoppers;
