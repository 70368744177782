import React from "react";
import { I18n } from "react-redux-i18n";
import MTableToolbarBaseSelect from "../MTableToolbarBaseSelect";

type PropTypes = {
  status: string[];
  options?: any[];
  handlePostStatusSelected: (postTypes: string[]) => void;
};

const MTableToolbarKeywordStrategyStatusFilter = (props: PropTypes) => {
  const { status, handlePostStatusSelected, options } = props;

  const topicStatus = options || [
    {
      _id: "rejected",
      name: "Rejected",
    },
    {
      _id: "approved",
      name: "Approved",
    },
    {
      _id: "pending",
      name: "Pending",
    },
  ];
  const statusValues = topicStatus
    .map((pt) => {
      if (status.includes(pt._id)) {
        return {
          _id: pt._id,
          name: pt.name,
        };
      }
    })
    .filter(Boolean);
  return (
    <MTableToolbarBaseSelect
      multipleOptions
      options={topicStatus}
      selectedOptions={statusValues}
      placeholder={I18n.t("strategy.outlinePages.status_filter")}
      title={I18n.t("strategy.outlinePages.states")}
      handleChange={(v) => {
        if (v.find((value) => value._id === "all")) {
          handlePostStatusSelected([]);
          return;
        }
        handlePostStatusSelected(v.map((value) => value._id));
      }}
    />
  );
};

export default MTableToolbarKeywordStrategyStatusFilter;
