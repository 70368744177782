import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import GenericValueItemProperty from "../build/items/components/GenericValueItemProperty";
import TrashIcon from "../../../../../../icons/TrashIcon";
import theme from "../../../../../../themes/theme";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  icon: {
    fontSize: theme.typography.pxToRem(15),
    opacity: 0.7,
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  },
  button: {
    backgroundColor: palette.primary.dark,
    border: 0,
  },
  error: {
    fontSize: 14,
  },
  textFieldWrapper: {
    height: 40,
    backgroundColor: "white",
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 4,
    padding: 10,
  },
}));

type PropTypes = {
  items: string[];
  placeholder?: string;
  error?: {
    message: string;
    hasError: (value: string) => boolean;
  };
  onChange: (elements: string[]) => void;
};

const InputList = (props: PropTypes) => {
  const { items, placeholder, error } = props;
  const { onChange } = props;

  const classes = useStyles(props);

  const onDelete = (index: number) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    onChange(updatedItems);
  };

  const onValueChange = (value: string, index: number) => {
    let updatedItems = [...items];

    if (!value) {
      updatedItems = items.filter((_, arrIndex) => arrIndex !== index);
    } else if (index === items.length) {
      updatedItems.push(value);
    } else {
      updatedItems = items.map((rowValue, arrIndex) => (arrIndex === index ? value : rowValue));
    }

    onChange(updatedItems);
  };

  return (
    <div className={classes.root}>
      {[...items, ""].map((value, i, array) => (
        <div key={i}>
          <GenericValueItemProperty
            key={i}
            onChange={({ value: newValue }) => onValueChange(newValue, i)}
            value={value}
            icon={
              i !== array.length - 1 ? <TrashIcon className={classes.icon} onClick={() => onDelete(i)} /> : undefined
            }
            placeholder={placeholder}
            classes={{ textFieldWrapper: classes.textFieldWrapper }}
          />
          {error?.hasError(value) && (
            <Typography color="error" className={classes.error}>
              {error.message}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};

export default InputList;
