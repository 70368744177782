import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import { IWebsiteThemeProps } from "../../../../../reducers/constants/objectTypes";
import ButtonDesignToolWithPreview from "../../../components/ButtonDesignToolWithPreview";
import TagDesignToolWithPreview from "../../../components/TagDesignToolWithPreview";
import TextInputToolWithPreview from "../../../components/TextInputToolWithPreview";
import TableDesignToolWithPreview from "../../../components/TableDesignToolWithPreview";
import MRouterPrompt from "../../../components/MRouterPrompt";

const useStyles = makeStyles((theme: Theme) => ({
  paletteColorWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  elementWrap: {
    width: "100%",
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
  },
  colorsTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 15,
    marginTop: 50,
    "&:first-child": {
      marginTop: 0,
    },
  },
  colorsRow: {
    display: "flex",
  },
  innerWrapper: {
    marginRight: "-30px",
    marginLeft: "-30px",
    marginTop: "-30px",
  },
}));

const mapStateToProps = (state) => ({
  initialTheme: state.websitePages.initialTheme,
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (theme) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "theme",
      value: theme,
    }),
});

type PropTypes = {
  initialTheme: IWebsiteThemeProps;
  theme: IWebsiteThemeProps;

  onPropertyChanged: (theme) => void;
};

const ElementDesign = (props: PropTypes) => {
  const classes = useStyles(props);
  const { theme, onPropertyChanged, initialTheme } = props;

  return (
    <PageSection className={classes.innerWrapper} sectionTitle={I18n.t("website.elements_design")}>
      <MRouterPrompt />
      <ButtonDesignToolWithPreview
        elementCss={theme.buttons.css}
        defaultElementCss={initialTheme.buttons.css}
        elementTitle={I18n.t("website.buttons")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({ ...theme, buttons: { css: newCss } });
        }}
      />
      <TagDesignToolWithPreview
        elementCss={theme.tags.css}
        defaultElementCss={initialTheme.tags.css}
        elementTitle={I18n.t("website.tags")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({ ...theme, tags: { css: newCss } });
        }}
      />

      <TagDesignToolWithPreview
        elementCss={theme.inPostTags?.css}
        defaultElementCss={initialTheme.tags.css}
        elementTitle={I18n.t("website.in_post_tags")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({ ...theme, inPostTags: { css: newCss } });
        }}
      />
      <TextInputToolWithPreview
        elementCss={theme.textFields?.css}
        defaultElementCss={initialTheme.textFields.css}
        elementTitle={I18n.t("website.text_input")}
        handleCssChanged={(newCss) => {
          onPropertyChanged({ ...theme, textFields: { css: newCss } });
        }}
      />
      <TableDesignToolWithPreview
        elementCss={theme.table}
        defaultElementCss={initialTheme.table}
        elementTitle={I18n.t("website.table")}
        handleCssChanged={(table) => {
          onPropertyChanged({
            ...theme,
            table: { css: table.css, headerCss: table.headerCss, bodyCss: table.bodyCss },
          });
        }}
      />
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ElementDesign);
