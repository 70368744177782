import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ClassNames from "classnames";
import _ from "lodash";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";
import MSelectMultiple from "../../../components/MSelectMultiple";
import { IOutlineParagraphData, IQueryGroup } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    width: "100%",
    display: "flex",
    marginBottom: 10,
    alignItems: "center",
  },
  listItemIcon: {
    cursor: "pointer",
    marginLeft: 10,
    marginTop: -10,
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  closableRow: {
    display: "flex",
    alignItems: "center",
  },
  selectMultipleWrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  keywordsFieldTitle: {
    minWidth: 170,
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    marginRight: 15,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
  },
  h3Wrapper: {},
  linksWrapper: {
    // marginTop: 30,
    // marginBottom: 10,
  },
  urlTextFieldInput: {
    minWidth: 100,
  },
  hidden: {
    visibility: "hidden",
  },
  bodyWrapper: {
    padding: 20,
  },
}));

//
type PropTypes = {
  paragraph: {
    type: "PARAGRAPH" | "FAQ";
    title: string;
    data: IOutlineParagraphData;
  };
  index: number;
  keywordGroupsOptions: IQueryGroup[];
  handleParagraphChange: (paragrahp, index) => void;
};

const ParagraphSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { paragraph, keywordGroupsOptions, handleParagraphChange, index } = props;

  const [selectedKeywords, setSelectedKeywords] = React.useState(
    paragraph?.data.targetKeywordsGroups?.map((t) => ({
      name: t,
      id: _.uniqueId(),
    }))
  );

  const keywordsOptions = React.useMemo(
    () => keywordGroupsOptions?.map((t) => ({ name: t.clicks_main_keyword, id: _.uniqueId() })),
    [keywordGroupsOptions]
  );
  const initialH3s = [
    ...(paragraph?.data?.h3?.map((t) => ({ name: t, id: _.uniqueId() })) ?? [{ name: "", id: _.uniqueId() }]),
    { name: "", id: _.uniqueId() },
  ];
  const [h3s = initialH3s, setH3s] = React.useState();

  const initialLinks = [
    ...(paragraph?.data?.links?.map((t) => ({ url: t.url, text: t.text, id: _.uniqueId() })) ?? [
      { text: "", url: "", id: _.uniqueId() },
    ]),
    { text: "", url: "", id: _.uniqueId() },
  ];
  const [links = initialLinks, setLinks] = React.useState();

  const updateH3s = (updatedH3s) => {
    handleParagraphChange(
      {
        ...paragraph,
        data: {
          ...paragraph.data,
          h3: updatedH3s?.map((t) => t.name).filter(Boolean),
        },
      },
      index
    );
  };

  const handleRemovedH3 = (kwGroup, i) => {
    if (i === h3s.length - 1 && h3s[i].name === "") {
      return;
    }
    const updatedH3s = h3s.filter((t) => t.id !== kwGroup.id);
    setH3s(updatedH3s);
    updateH3s(updatedH3s);
  };

  const handleH3sChange = (value, h3Index) => {
    const updatedH3s = [...h3s];
    if (updatedH3s.length - 1 === h3Index && updatedH3s[h3Index].name !== "") {
      updatedH3s.push({ name: "", id: _.uniqueId() });
    }
    updatedH3s[h3Index].name = value;
    setH3s(updatedH3s);
    updateH3s(updatedH3s);
  };

  const updateKeywords = (updatedKeywords) => {
    handleParagraphChange(
      {
        ...paragraph,
        data: {
          ...paragraph.data,
          targetKeywordsGroups: updatedKeywords?.map((t) => t.name),
        },
      },
      index
    );
  };

  const handleAddedToTargetKeywordsGroups = (kwGroup) => {
    const { name } = kwGroup;
    const updatedKeywords = [...selectedKeywords, { name, id: _.uniqueId() }];
    setSelectedKeywords(updatedKeywords);
    updateKeywords(updatedKeywords);
  };
  const handleRemovedFromTargetKeywordsGroups = (kwGroup) => {
    const updatedKeywords = selectedKeywords.filter((t) => t.id !== kwGroup.id);
    setSelectedKeywords(updatedKeywords);
    updateKeywords(updatedKeywords);
  };

  const updateLinks = (updatedLinks) => {
    handleParagraphChange(
      {
        ...paragraph,
        data: {
          ...paragraph.data,
          links: updatedLinks?.map((t) => ({ url: t.url, text: t.text })).filter((t) => t.text && t.url),
        },
      },
      index
    );
  };

  const handleRemoveLink = (kwGroup, i) => {
    if (i === links.length - 1 && links[i].text === "" && links[i].url === "") {
      return;
    }
    const updatedLinks = links.filter((t) => t.id !== kwGroup.id);
    setLinks(updatedLinks);
    updateLinks(updatedLinks);
  };

  const handleLinkTextChange = (value, linkIndex) => {
    const updatedLinks = [...links];
    if (updatedLinks.length - 1 === linkIndex && updatedLinks[linkIndex].text !== "") {
      updatedLinks.push({ text: "", url: "", id: _.uniqueId() });
    }
    updatedLinks[linkIndex].text = value;
    setLinks(updatedLinks);
    updateLinks(updatedLinks);
  };

  const handleLinkUrlChange = (value, linkIndex) => {
    const updatedLinks = [...links];
    if (updatedLinks.length - 1 === linkIndex && updatedLinks[linkIndex].url) {
      updatedLinks.push({ text: "", url: "", id: _.uniqueId() });
    }
    updatedLinks[linkIndex].url = value;
    setLinks(updatedLinks);
    updateLinks(updatedLinks);
  };

  return (
    <div className={classes.bodyWrapper}>
      <div className={classes.row}>
        <Typography className={classes.keywordsFieldTitle} variant={"subtitle2"}>
          {I18n.t("outline.target_keyword_groups")}
        </Typography>

        <MSelectMultiple
          creatable={false}
          wrapperClassName={classes.selectMultipleWrapper}
          options={keywordsOptions}
          selectedOptions={selectedKeywords}
          optionLabel={"name"}
          optionValue={"id"}
          borderWidth={1}
          height={37}
          placeholder={I18n.t("outline.target_keyword_groups_ph")}
          handleOptionSelected={handleAddedToTargetKeywordsGroups}
          handleOptionRemoved={handleRemovedFromTargetKeywordsGroups}
        />
      </div>

      <div className={classes.h3Wrapper}>
        {h3s?.map((h3, i) => (
          <div className={classes.closableRow}>
            <div className={classes.row}>
              <TextFieldMaxLength
                value={h3.name}
                titleAndLengthLocation={"left"}
                title={I18n.t("outline.h3", { number: i + 1 })}
                placeholder={I18n.t("outline.select_page")}
                borderRadius={4}
                titleMinWidth={170}
                height={37}
                marginRight={0}
                handleValueChange={(value) => {
                  handleH3sChange(value, i);
                }}
              />
            </div>
            <CloseIcon
              className={ClassNames(classes.listItemIcon, paragraph?.data?.links?.length === index && classes.hidden)}
              onClick={() => {
                handleRemovedH3(h3, i);
              }}
            />
          </div>
        ))}
      </div>

      <div className={classes.linksWrapper}>
        {links?.map((link, i) => (
          <div className={classes.closableRow}>
            <div className={classes.row}>
              <TextFieldMaxLength
                value={link.url}
                titleAndLengthLocation={"left"}
                title={I18n.t("outline.link", { number: i + 1 })}
                placeholder={I18n.t("outline.select_page")}
                borderRadius={4}
                titleMinWidth={170}
                height={37}
                marginRight={10}
                inputClassName={classes.urlTextFieldInput}
                handleValueChange={(value) => {
                  handleLinkUrlChange(value, i);
                }}
              />
              <TextFieldMaxLength
                value={link.text}
                placeholder={I18n.t("outline.anchor_text")}
                borderRadius={4}
                titleMinWidth={170}
                height={37}
                handleValueChange={(value) => {
                  handleLinkTextChange(value, i);
                }}
              />
            </div>
            <CloseIcon
              className={ClassNames(classes.listItemIcon)}
              onClick={() => {
                handleRemoveLink(link, i);
              }}
            />
          </div>
        ))}
      </div>

      <div className={classes.row}>
        <TextFieldMaxLength
          value={paragraph?.data?.notes || ""}
          titleAndLengthLocation={"left"}
          title={I18n.t("outline.notes")}
          placeholder={I18n.t("outline.notes_ph")}
          borderRadius={4}
          titleMinWidth={170}
          height={37}
          multiLine
          handleValueChange={(notes) =>
            handleParagraphChange(
              {
                ...paragraph,
                data: {
                  ...paragraph.data,
                  notes,
                },
              },
              index
            )
          }
        />
      </div>
    </div>
  );
};

export default ParagraphSection;
