import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import GradientColorPickerBaseComponent from "../BaseComponents/GradientColorPickerBaseComponent";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
  },
  valueWrap: {
    display: "flex",
    alignItems: "center",
    width: 180,
    justifyContent: "space-between",
  },
  backToDefault: {
    color: theme.palette.text.hint,
    fontSize: 16,
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    maxWidth: 60,
    whiteSpace: "pre",
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type PropTypes = {
  title?: string;
  value: any;
  defaultValue?: string;
  isValueChanged: boolean;
  handleChange: any;
};

const GradientBackgroundColorCSS = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    title = I18n.t("rich_text_editor.background"),
    value = null,
    defaultValue = "",
    isValueChanged,
    handleChange,
  } = props;

  return (
    <div className={classes.row}>
      <Typography variant={"body2"} className={ClassNames(classes.text, isValueChanged && classes.boldText)}>
        {title}
      </Typography>
      <div className={classes.valueWrap}>
        <GradientColorPickerBaseComponent
          defaultColor={defaultValue}
          borderRadius={2}
          gradient={value}
          handleChange={handleChange}
          top={"0"}
          left={"-100px"}
        />
      </div>
    </div>
  );
};
export default GradientBackgroundColorCSS;
