import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import _ from "lodash";
import debounce from "lodash/debounce";
import TitleSection from "./TitleSection";
import ParagraphSection from "./ParagraphSection";
import FAQSection from "./FAQSection";
import ParagraphActions from "./ParagraphActions";
import { IOutline, IQueryGroup } from "../../../reducers/constants/objectTypes";
import { OUTLINE } from "../../../reducers/constants/actionTypes";

import agent from "../../../agent";
import useDebounce from "../../../hooks/useDebounce";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
  },
  mainScreenWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: 1050,
    overflowY: "auto",
    paddingRight: 20,
  },
  addNewParagraphButton: {
    margin: 20,
    cursor: "pointer",
    width: "fit-content",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  outline: IOutline;
  outlineKeywordGroupsOptions: IQueryGroup[];
  onOutlineLoaded: (outline: IOutline) => void;
  onOutlinePropertyChanged: (name: string, value: any) => void;
};

const mapStateToProps = (state) => ({
  outline: state.outline.outline,
  outlineKeywordGroupsOptions: state.outline.outlineKeywordGroupsOptions,
});

const mapDispatchToProps = (dispatch) => ({
  onOutlinePropertyChanged: (name: string, value: any) =>
    dispatch({ type: OUTLINE.ON_OUTLINE_PROPERTY_CHANGED, name, value }),
  onOutlineLoaded: (outline: IOutline) => dispatch({ type: OUTLINE.ON_OUTLINE_PAGE_LOADED, outline }),
});

const MainScreen = (props: PropTypes) => {
  const classes = useStyles(props);
  const { outline, outlineKeywordGroupsOptions, onOutlinePropertyChanged, onOutlineLoaded } = props;

  const handleTitleChange = (postTitle) => {
    onOutlineLoaded({ ...outline, postTitle });
    console.log("MainScreen handleTitleChange updateOutline", { ...outline, postTitle });
    // agent.Outlines.update({ ...outline, postTitle });
  };

  const handleParagraphChange = (paragraphs, index) => {
    const updatedParagraphs = [...outline.paragraphs];
    updatedParagraphs[index] = paragraphs;
    onOutlineLoaded({ ...outline, paragraphs: updatedParagraphs });
    console.log("MainScreen handleParagraphChange updateOutline", { ...outline, paragraphs: updatedParagraphs });
    // agent.Outlines.update({ ...outline, paragraphs: updatedParagraphs });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainScreenWrapper}>
        <TitleSection
          postTitle={outline.postTitle}
          keywordGroupsOptions={outlineKeywordGroupsOptions}
          handleTitleChange={handleTitleChange}
        />
        {outline.paragraphs?.map((paragraph, index) => (
          <ParagraphActions key={`paragraph_${index}`} paragraphIndex={index}>
            {paragraph.type === "PARAGRAPH" && (
              <ParagraphSection
                key={`${paragraph.title}_${index + 1}`}
                index={index}
                paragraph={paragraph as any}
                keywordGroupsOptions={outlineKeywordGroupsOptions}
                handleParagraphChange={handleParagraphChange}
              />
            )}
            {paragraph.type === "FAQ" && (
              <FAQSection
                paragraph={paragraph}
                key={`${paragraph.title}_${index + 1}`}
                keywordGroupsOptions={outlineKeywordGroupsOptions}
                handleParagraphChange={(updatedParagraph) => {
                  handleParagraphChange(updatedParagraph, index);
                }}
              />
            )}
          </ParagraphActions>
        ))}
        <div
          className={classes.addNewParagraphButton}
          onClick={() => {
            onOutlinePropertyChanged("paragraphs", [
              ...outline.paragraphs,
              {
                type: "PARAGRAPH",
                title: "",
                data: {
                  targetKeywordsGroups: [],
                  links: [],
                  notes: "",
                },
              },
            ]);
          }}
        >
          {"+ Add New Section"}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
