import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core/styles";
import { Paper, Popover } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state/index";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import edit from "../../../img/edit.png";
import SelectRow from "./SelectRow";
import ColorPickerRow from "./ColorPickerRow";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import InputRow from "./InputRow";
import DimensionsInputRow from "./DimensionsInputRow";
import {
  fontOptions,
  fontStyleOptions,
  letterCaseOptions,
  textDecorationOptions,
  weightOptions,
} from "../WebsiteEditor/consts/CssConsts";
import QuotePreview from "./QuotePreview";
import agent from "../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  designTitle: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    // marginBottom: 20,
    width: "100%",
  },
  editImage: {
    marginLeft: 15,
    cursor: "pointer",
  },
  designPropsRow: {
    display: "flex",
    padding: "0px 30px",
    minHeight: 59,
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& $editWrapper": {
        display: "flex",
      },
    },
  },
  designProps: {
    display: "flex",
    color: theme.palette.text.primary,
  },
  popoverPaper: {
    borderRadius: 0,
    boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.15)",
    border: "none",
    marginLeft: 10,
  },
  wrapper: {
    height: "100%",
    width: 416,
    border: "none",
    borderRadius: 0,
  },
  innerWrapper: {
    padding: "15px 20px",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 15,
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  colorSquare: {
    width: 16,
    height: 16,
    backgroundColor: "black",
    marginLeft: 7,
  },
  designWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    cursor: "pointer",
    justifyContent: "space-between",
  },
  editWrapper: {
    display: "none",
    gap: 10,
    alignItems: "center",
  },
  designText: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
}));
type PropTypes = {
  theme: IWebsiteThemeProps;
  elementTitle?: string;
  css: any;
  defaultCss?: any;
  exampleText?: string;

  handleCssChanged: (css) => void;
};

const mapStateToProps = (state) => ({
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({});

const QuoteDesignToolWithPreview = (props: PropTypes) => {
  const classes = useStyles(props);
  const { theme, defaultCss = null, elementTitle = "", exampleText = null, css = {}, handleCssChanged } = props;

  // backward compatibility
  css.borderWidth = 0;
  css.quotes = null;
  css["&:before"] = css["&:before"] || {};
  css["&:before"].fontSize = 40;
  css["&:before"].content = '"\\201C"';

  const finalDefaultCss = {
    ...defaultCss,
    fontFamily: theme.typography.fontFamily,
    "&:before": {
      ...defaultCss["&:before"],
      color: theme.palette.primary.main,
    },
  };

  const onCssChanged = (cssName, cssValue) => {
    if (cssValue === null || cssValue === "" || cssValue === finalDefaultCss[cssName]) {
      delete css[cssName];
      handleCssChanged(css);
      return;
    }
    handleCssChanged({ ...css, [cssName]: cssValue });
  };

  const onCssBeforeChanged = (cssName, cssValue) => {
    if (cssValue === null || cssValue === "" || cssValue === finalDefaultCss["&:before"][cssName]) {
      delete css["&:before"][cssName];
      handleCssChanged(css);
      return;
    }
    handleCssChanged({ ...css, "&:before": { ...css["&:before"], [cssName]: cssValue } });
  };

  return (
    <div className={classes.designPropsRow}>
      <PopupState variant="popover" popupId="popover">
        {(popupState) => (
          <div style={{ display: "contents" }}>
            <div className={classes.designWrapper} {...bindTrigger(popupState)}>
              <QuotePreview finalDefaultCss={finalDefaultCss} css={css} exampleText={exampleText} />
              <div className={classes.editWrapper}>
                <img width={12} height={12} src={edit} alt={"edit_image"} className={classes.editImage} />
                <Typography variant={"body2"} className={classes.designText}>
                  {I18n.t("website.edit")}
                </Typography>
              </div>
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              elevation={0}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <Paper className={classes.wrapper}>
                <div className={classes.titleWrapper}>
                  <Typography color={"textPrimary"} variant={"subtitle1"}>
                    {`${elementTitle} Typography`}
                  </Typography>
                  <CloseIcon className={classes.closeIcon} onClick={popupState.close} />
                </div>
                <div className={classes.innerWrapper}>
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.font")}
                    value={css?.fontFamily}
                    defaultValue={finalDefaultCss?.fontFamily}
                    isValueChanged={css?.fontFamily && css?.fontFamily !== finalDefaultCss?.fontFamily}
                    selectOptions={fontOptions}
                    handleChange={(font) => {
                      onCssChanged("fontFamily", font);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.size")}
                    value={css?.fontSize}
                    defaultValue={finalDefaultCss?.fontSize}
                    isValueChanged={css?.fontSize && css?.fontSize !== finalDefaultCss?.fontSize}
                    handleChange={(size) => {
                      onCssChanged("fontSize", size);
                    }}
                  />
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.weight")}
                    value={css?.fontWeight}
                    defaultValue={finalDefaultCss?.fontWeight}
                    isValueChanged={css?.fontWeight && css?.fontWeight !== finalDefaultCss?.fontWeight}
                    selectOptions={weightOptions}
                    handleChange={(weight) => {
                      onCssChanged("fontWeight", weight);
                    }}
                  />
                  <ColorPickerRow
                    rowTitle={I18n.t("website.design_tool.text_color")}
                    value={css?.color}
                    defaultValue={finalDefaultCss?.color}
                    isValueChanged={css?.color && css?.color !== finalDefaultCss?.color}
                    handleChange={(color) => {
                      onCssChanged("color", color);
                    }}
                  />
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.font_style")}
                    value={css?.fontStyle}
                    defaultValue={finalDefaultCss?.fontStyle}
                    isValueChanged={css?.fontStyle && css?.fontStyle !== finalDefaultCss?.fontStyle}
                    selectOptions={fontStyleOptions}
                    handleChange={(fontStyle) => {
                      onCssChanged("fontStyle", fontStyle);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.line_height")}
                    value={css?.lineHeight}
                    stepSize={"0.1"}
                    defaultValue={finalDefaultCss?.lineHeight}
                    isValueChanged={css?.lineHeight && css?.lineHeight !== finalDefaultCss?.lineHeight}
                    handleChange={(lineHeight) => {
                      onCssChanged("lineHeight", lineHeight);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.letter_spacing")}
                    value={css?.letterSpacing}
                    defaultValue={finalDefaultCss?.letterSpacing}
                    isValueChanged={css?.letterSpacing && css?.letterSpacing !== finalDefaultCss?.letterSpacing}
                    handleChange={(letterSpacing) => {
                      onCssChanged("letterSpacing", letterSpacing);
                    }}
                  />
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.letter_case")}
                    value={css?.textTransform}
                    defaultValue={finalDefaultCss?.textTransform}
                    isValueChanged={css?.textTransform && css?.textTransform !== finalDefaultCss?.textTransform}
                    selectOptions={letterCaseOptions}
                    handleChange={(letterCase) => {
                      onCssChanged("textTransform", letterCase);
                    }}
                  />

                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.text_decoration")}
                    value={css?.textDecoration}
                    defaultValue={finalDefaultCss?.textDecoration}
                    isValueChanged={css?.textDecoration && css?.textDecoration !== finalDefaultCss?.textDecoration}
                    selectOptions={textDecorationOptions}
                    handleChange={(textDecoration) => {
                      onCssChanged("textDecoration", textDecoration);
                    }}
                  />

                  {/* <QuoteBorderLeftWidthInputRow
                    rowTitle={I18n.t("website.design_tool.quotes_divider_width")}
                    value={css?.borderWidth || finalDefaultCss?.borderWidth}
                    defaultValue={finalDefaultCss?.borderWidth}
                    isValueChanged={css?.borderWidth && css?.borderWidth !== finalDefaultCss?.borderWidth}
                    handleChange={(borderWidth) => {
                      onCssChanged("borderWidth", borderWidth);
                    }}
                  /> */}

                  <ColorPickerRow
                    rowTitle={I18n.t("website.design_tool.quotes_icon_color")}
                    value={css["&:before"].color || finalDefaultCss["&:before"].color}
                    defaultValue={defaultCss["&:before"].color}
                    isValueChanged={css["&:before"].color && css["&:before"].color !== defaultCss["&:before"].color}
                    handleChange={(color) => {
                      onCssBeforeChanged("color", color);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.padding")}
                    value={css?.padding || finalDefaultCss?.padding}
                    defaultValue={finalDefaultCss?.padding}
                    isValueChanged={css?.padding && css?.padding !== finalDefaultCss?.padding}
                    handleChange={(padding) => {
                      onCssChanged("padding", padding);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.margin")}
                    value={css?.margin}
                    defaultValue={defaultCss?.margin}
                    isValueChanged={css?.margin && css?.margin !== finalDefaultCss?.margin}
                    handleChange={(margin) => {
                      onCssChanged("margin", margin);
                    }}
                  />
                </div>
              </Paper>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteDesignToolWithPreview);
