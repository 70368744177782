import React from "react";
import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, Theme, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import { WIDGET_BUILDER } from "../../../../../../../../reducers/constants/actionTypes";
import { IWidgetSettings } from "../../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  container: {
    width: "100%",
    backgroundColor: palette.secondary.dark,
    borderRadius: 3,
    border: `1px solid ${palette.grey["100"]}`,
    padding: "10px 15px",
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  radioLabel: {},
  radio: {
    "&$checked": {
      color: palette.primary.light,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 18,
    },
  },
  checked: {},
  label: {
    fontSize: 12,
    opacity: 0.5,
  },
}));

type PropTypes = {
  settings: IWidgetSettings;
  editSettings: (settings: IWidgetSettings) => void;
};

const mapStateToProps = (state) => ({
  settings: state.widgetBuilder.widget.settings,
});

const mapDispatchToProps = (dispatch) => ({
  editSettings: (payload: IWidgetSettings) => dispatch({ type: WIDGET_BUILDER.EDIT_SETTINGS, payload }),
});

const FrequencySettings = (props: PropTypes) => {
  const { settings } = props;
  const { editSettings } = props;
  const { frequency } = settings;

  const classes = useStyles();

  const fields = React.useMemo(() => ["everytime", "until_close", "once", "everyday", "everyweek"], []);

  return (
    <div>
      <div className={classes.container}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={frequency}
            onChange={(e) => editSettings({ ...settings, frequency: e.target.value as IWidgetSettings["frequency"] })}
          >
            {fields.map((field) => (
              <FormControlLabel
                value={field}
                key={field}
                control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                label={I18n.t(`widgets_builder.widget_editor.Dialog.settings.frequency.${field}`)}
                className={classes.radioLabel}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      <Typography className={classes.label}>
        {I18n.t(`widgets_builder.widget_editor.Dialog.settings.frequency.label`)}
      </Typography>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FrequencySettings);
