import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import set from "lodash/set";
import { Divider } from "@material-ui/core";
import PageSection from "../../../../../../components/PageSection";
import { IWebsite, IWebsiteDynamicSectionProps } from "../../../../../../../../reducers/constants/objectTypes";
import TextInputMentions from "../../../../../../components/TextInputMentions";
import { LENGTHS } from "../../../../../../../../reducers/constants/consts";
import TextInputToolAndDesign from "../../../../../../components/TextInputToolAndDesign";
import BlogMultipleCategorySelector from "../../../../commonComponents/BlogMultipleCategorySelector";
import PostSearchInput from "../../../../ResourcePage/components/PostSearchInput";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

type PropTypes = {
  dynamicSection: IWebsiteDynamicSectionProps;
  website: IWebsite;
  onPropertyChanged: (dynmaicSeciton) => void;
  locale: string;
  defaultLocale: string;
  emptyFields: string[];
};

const MaypleBlogTemplate = (props: PropTypes) => {
  const classes = useStyles(props);
  const { dynamicSection, onPropertyChanged, emptyFields, locale, defaultLocale, website } = props;
  const { maypleTemplate } = dynamicSection;

  const [mainPageEmptyFields, setMainPageEmptyFields] = React.useState([]);

  const mainPageValidated = () => {
    let valid = true;
    const requiredFields = [];
    setMainPageEmptyFields(requiredFields);
    if (!maypleTemplate.mainPage.metaTitle) {
      valid = false;
      requiredFields.push(I18n.t(`website.meta_title`));
    }
    if (!maypleTemplate.mainPage.metaDescription) {
      valid = false;
      requiredFields.push(I18n.t(`website.meta_description`));
    }
    setMainPageEmptyFields(requiredFields);
    return valid;
  };
  return (
    <>
      <PageSection
        sectionTitle={I18n.t("website.new_content_page.main_page")}
        checked={maypleTemplate.mainPage.enabled}
        onCheckedChange={(checked) => {
          if (!mainPageValidated()) {
            if (!checked && maypleTemplate.mainPage.enabled) {
              onPropertyChanged({
                ...dynamicSection,
                maypleTemplate: { ...maypleTemplate, mainPage: { ...maypleTemplate.mainPage, enabled: checked } },
              });
            }
            return;
          }
          onPropertyChanged({
            ...dynamicSection,
            maypleTemplate: { ...maypleTemplate, mainPage: { ...maypleTemplate.mainPage, enabled: checked } },
          });
        }}
      >
        <TextInputMentions
          key={`blog_main_page_meta_title_${locale}`}
          tags={[]}
          error={mainPageEmptyFields.includes(I18n.t(`website.meta_title`))}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? maypleTemplate.mainPage.metaTitle
              : dynamicSection.translations[locale]?.maypleTemplate.mainPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                maypleTemplate: { ...dynamicSection.maypleTemplate, "mainPage.metaTitle": value },
              });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              maypleTemplate: {
                ...(dynamicSection.translations[locale]?.maypleTemplate || {}),
                "mainPage.metaTitle": value,
              },
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
        <TextInputMentions
          key={`blog_main_page_meta_desc_${locale}`}
          error={mainPageEmptyFields.includes(I18n.t(`website.meta_description`))}
          tags={[]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? maypleTemplate.mainPage.metaDescription
              : dynamicSection.translations[locale]?.maypleTemplate?.mainPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                maypleTemplate: { ...dynamicSection.maypleTemplate, "mainPage.metaDescription": value },
              });
              return;
            }
            dynamicSection.translations[locale] = {
              ...(dynamicSection.translations[locale] || {}),
              maypleTemplate: {
                ...(dynamicSection.translations[locale]?.maypleTemplate || {}),
                "mainPage.metaDescription": value,
              },
            };
            onPropertyChanged({ ...dynamicSection });
          }}
        />
        <PostSearchInput
          pageType={dynamicSection.type}
          placeholder={I18n.t("website.blog.mayple_template.featured_post_ph")}
          title={I18n.t("website.blog.mayple_template.featured_post")}
          selectedWebsite={website}
          maxPages={1}
          marginBottom={30}
          pages={maypleTemplate.featuredPost?.slug ? [maypleTemplate.featuredPost] : []}
          handlePagesChange={(page) => {
            onPropertyChanged({
              ...dynamicSection,
              maypleTemplate: { ...maypleTemplate, featuredPost: page },
            });
          }}
        />
        <Divider className={classes.divider} />
        <TextInputToolAndDesign
          error={emptyFields.includes(I18n.t(`website.blog.mayple_template.top_articles_title`))}
          textFieldTitle={I18n.t("website.blog.mayple_template.top_articles_title")}
          text={
            locale === defaultLocale
              ? maypleTemplate.topArticles?.title
              : {
                  text: dynamicSection.translations[locale]?.maypleTemplate?.topArticles?.title || "",
                  css: maypleTemplate.topArticles?.title?.css,
                }
          }
          handleTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...dynamicSection,
                maypleTemplate: { ...maypleTemplate, topArticles: { ...maypleTemplate.topArticles, title: value } },
              });
              return;
            }
            set(dynamicSection.translations[locale], "maypleTemplate.topArticles.title", value.text);
            onPropertyChanged({ ...dynamicSection });
          }}
          variant={"h1"}
        />
        <PostSearchInput
          placeholder={I18n.t("website.blog.mayple_template.top_articles_ph", {
            count: maypleTemplate.topArticles?.posts?.length,
          })}
          title={I18n.t("website.resourcePage.top_section.top_articles")}
          selectedWebsite={website}
          pageType={dynamicSection.type}
          marginBottom={30}
          pages={maypleTemplate.topArticles?.posts || []}
          handlePagesChange={(posts) => {
            onPropertyChanged({
              ...dynamicSection,
              maypleTemplate: { ...maypleTemplate, topArticles: { ...maypleTemplate.topArticles, posts } },
            });
          }}
        />
        <Divider className={classes.divider} />
        <BlogMultipleCategorySelector
          rowTitle={I18n.t("website.blog.mayple_template.select_categories")}
          handleCategoriesChange={(categories) => {
            onPropertyChanged({
              ...dynamicSection,
              maypleTemplate: { ...maypleTemplate, categories },
            });
          }}
          selectedCategories={maypleTemplate.categories || []}
        />
      </PageSection>
    </>
  );
};

export default MaypleBlogTemplate;
