import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import ClassNames from "classnames";
import { DATE_FORMAT } from "../../../reducers/constants/consts";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    flexGrow: 1,
    flexBasis: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: 10,
  },
  underline: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type PropTypes = {
  date: Date;
  dateFormat?: string;
  handleDateClick?: Function;
  underlineOnHover?: boolean;
};

const MTableCellDate = (props: PropTypes) => {
  const classes = useStyles(props);
  const { date, dateFormat = DATE_FORMAT.READABLE, underlineOnHover = false, handleDateClick } = props;
  return (
    <div
      className={ClassNames(classes.column, underlineOnHover && classes.underline)}
      onClick={(e) => {
        if (handleDateClick) {
          e.stopPropagation();
          handleDateClick(e);
        }
      }}
    >
      <Tooltip enterDelay={2000} title={date ? moment(date).format(dateFormat) : "-"}>
        <span>{date ? moment(date).format(dateFormat) : "-"}</span>
      </Tooltip>
    </div>
  );
};

export default MTableCellDate;
