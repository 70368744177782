import React, { useRef, useState } from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";

import { IWidgetSettings } from "../../../../../../../../../reducers/constants/objectTypes";
import CustomPopover from "../../../CustomPopover";
import InputSlider from "../../../InputSlider";
import SelectProperty from "../../../../build/items/itemProperties/components/SelectProperty";
import LayoutPaneSelectedIcon from "../../../../../../../../../icons/LayoutPaneSelectedIcon";
import LayoutPaneUnSelectedIcon from "../../../../../../../../../icons/LayoutPaneUnSelectedIcon";
import LayoutModalDefaultIcon from "../../../../../../../../../icons/LayoutModalDefaultIcon";
import LayoutModalSelectedIcon from "../../../../../../../../../icons/LayoutModalSelectedIcon";
import LayoutSlideInDefaultIcon from "../../../../../../../../../icons/LayoutSlideInDefaultIcon";
import LayoutSlideInSelectedIcon from "../../../../../../../../../icons/LayoutSlideInSelectedIcon";
import { colors } from "../../../../../../../../../helpers/constants";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const hover = {
    backgroundColor: colors.widgetOnHoverBackgroundColor,
    cursor: "pointer",
  };

  return {
    root: {
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },
    fieldContainer: {
      backgroundColor: palette.secondary.dark,
      padding: 15,
      display: "flex",
      borderRadius: 3,
      "& .MuiTypography-root": {
        fontSize: 14,
      },
    },
    positionField: {
      display: "flex",
      justifyContent: "space-between",
      "&:hover": hover,
    },
    positionOption: {
      flexGrow: 1,
      flexBasis: 0,
      minWidth: 0,
      minHeight: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "&:hover": hover,
      borderRadius: 3,
    },
    positionLabel: {
      fontSize: 14,
      opacity: 0.7,
      paddingBottom: 10,
    },
    selected: {
      color: palette.primary.main,
      fontWeight: typography.fontWeightMedium as any,
    },
    toolbar: {
      display: "flex",
      flexDirection: "column",
      gap: 15,
    },
  };
});

type PropTypes = {
  layout: IWidgetSettings["layout"];
  onChange: (field: Partial<IWidgetSettings["layout"]>) => void;
};

const Layout = (props: PropTypes) => {
  const [showPositionToolbar, setShowPositionToolbar] = useState(false);
  const toolbarRef = useRef(null);
  const classes = useStyles();

  const { onChange } = props;
  const { layout } = props;
  const { type, position, width, verticalOffset, horizontalOffset } = layout;

  return (
    <div className={classes.root}>
      <div className={classes.fieldContainer}>
        <div className={classes.positionOption} onClick={() => onChange({ type: "modal" })}>
          {type === "modal" ? <LayoutModalSelectedIcon /> : <LayoutModalDefaultIcon />}
          <Typography className={ClassNames(classes.positionLabel, type === "modal" ? classes.selected : undefined)}>
            {I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.modal")}
          </Typography>
        </div>
        <div className={classes.positionOption} onClick={() => onChange({ type: "slide_in" })}>
          {type === "slide_in" ? <LayoutSlideInSelectedIcon /> : <LayoutSlideInDefaultIcon />}
          <Typography className={ClassNames(classes.positionLabel, type === "slide_in" ? classes.selected : undefined)}>
            {I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.slide_in")}
          </Typography>
        </div>
        <div
          className={classes.positionOption}
          onClick={() => onChange({ type: "pane", position: layout.position !== "center" ? layout.position : null })}
        >
          {type === "pane" ? <LayoutPaneSelectedIcon /> : <LayoutPaneUnSelectedIcon />}
          <Typography className={ClassNames(classes.positionLabel, type === "pane" ? classes.selected : undefined)}>
            {I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.pane")}
          </Typography>
        </div>
      </div>
      {type !== "modal" && (
        <div>
          <div
            className={ClassNames(classes.fieldContainer, classes.positionField)}
            onClick={() => setShowPositionToolbar(true)}
            ref={toolbarRef}
          >
            <Typography> {I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.position")}</Typography>
            <Typography>
              {I18n.t(
                position
                  ? `widgets_builder.widget_editor.Dialog.settings.layout.alignment.${position}`
                  : "widgets_builder.widget_editor.fields.labels.select"
              )}
            </Typography>
          </div>
          <CustomPopover
            anchorEl={toolbarRef.current}
            onClose={() => setShowPositionToolbar(false)}
            open={showPositionToolbar}
            label={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.position")}
          >
            <div className={classes.toolbar}>
              <SelectProperty
                options={["left", ...(type === "slide_in" ? ["center"] : []), "right"]}
                value={position}
                onChange={(option) => onChange({ position: option as IWidgetSettings["layout"]["position"] })}
              />

              {type === "slide_in" ? (
                <>
                  <InputSlider
                    label={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.verticalOffset")}
                    max={80}
                    value={verticalOffset}
                    adornmentText="px"
                    onChange={(value) => onChange({ verticalOffset: value })}
                  />
                  <InputSlider
                    label={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.horizontalOffset")}
                    max={80}
                    value={horizontalOffset}
                    adornmentText="px"
                    onChange={(value) => onChange({ horizontalOffset: value })}
                  />
                </>
              ) : null}
            </div>
          </CustomPopover>
        </div>
      )}
      <InputSlider
        label={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.width")}
        min={200}
        max={1200}
        value={width}
        adornmentText="px"
        customClass={classes.fieldContainer}
        onChange={(value) => onChange({ width: value })}
      />
    </div>
  );
};

export default Layout;
