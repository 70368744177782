import {LANDING_PAGES} from "./constants/actionTypes";

const initialState = {
  landingPages: [],
  landingPagesLoaded: false,
  currentLandingPage: null,
  errors: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LANDING_PAGES.LANDING_PAGES_LOADED:
      return {
        ...state,
        landingPagesLoaded: true,
        landingPages: action.payload.landingPages,
      };
    case LANDING_PAGES.LANDING_PAGE_CREATED:
      return {
        ...state,
        errors: initialState.errors,
        currentLandingPage: initialState.currentLandingPage,
        landingPages: [action.payload.landingPage, ...state.landingPages],
      };
    case LANDING_PAGES.LANDING_PAGE_LOADED:
      return {
        ...state,
        errors: initialState.errors,
        currentLandingPage: action.payload.landingPage,
        landingPageLoaded: true,
      };
    case LANDING_PAGES.LANDING_PAGE_CHANGED:
      return {
        ...state,
        errors: initialState.errors,
        currentLandingPage: action.payload,
      };
    case LANDING_PAGES.ERRORS_CHANGED:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};
