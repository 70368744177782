"use strict";
import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import { useSlate } from "slate-react";
import { SketchPicker } from "react-color";
import { ClickAwayListener } from "@material-ui/core";
import ClassNames from "classnames";
import { Editor } from "slate";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import theme from "../../../themes/theme";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import AlphabetAIcon from "../toolbar/icons/AlphabetAIcon";
import RichTextEditorHelper from "../../helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: 38,
    height: 38,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  wrapper: {
    flexDirection: "column",
    display: "flex",
  },
  boxWrapper: {
    height: 3,
    marginTop: 2,
    width: "100%",
  },
  icon: {
    fontWeight: theme.typography.fontWeightBold as any,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  popper: {
    zIndex: 999,
  },

  paper: {
    padding: 20,
    // width: 440,
  },
  colorPickerWrap: {
    top: 0,
    left: 0,
    zIndex: 1,
    position: "absolute",
  },
}));

type propTypes = {
  themeProps: IWebsiteThemeProps;
};

function TextColorButton(props: propTypes) {
  const classes = useStyles(props);
  const { themeProps } = props;
  const editor = useSlate();
  const [color, setColor] = React.useState(themeProps.palette.text.primary);
  const [open, setOpen] = React.useState(false);
  const modalRef = React.useRef<HTMLButtonElement>(null);
  const defaultColors = [
    ...new Set([
      theme.palette.text.primary,
      theme.palette.text.secondary,
      theme.palette.text.disabled,
      theme.palette.primary.main,
      theme.palette.primary.light,
      theme.palette.primary.dark,
      theme.palette.secondary.main,
      theme.palette.secondary.light,
      theme.palette.secondary.dark,
    ]),
  ];
  const handleClickAway = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen(true);
  };
  return (
    <IconButton
      className={classes.button}
      ref={modalRef}
      disableRipple
      onClick={() => {
        setColor(RichTextEditorHelper.getSelectedTextColor(editor) || themeProps.palette.text.primary);
        handleClick();
      }}
      aria-label={"color"}
    >
      <div className={classes.wrapper}>
        <AlphabetAIcon className={ClassNames(classes.icon)} />
        <div
          style={{
            backgroundColor: RichTextEditorHelper.getSelectedTextColor(editor) || themeProps.palette.text.primary,
          }}
          className={classes.boxWrapper}
        />
      </div>

      <Popper
        open={open}
        anchorEl={modalRef.current}
        role={undefined}
        className={classes.popper}
        transition
        // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className={classes.colorPickerWrap}>
                  <SketchPicker
                    presetColors={defaultColors}
                    color={color}
                    onChangeComplete={(col) => {
                      let colorStr = col.hex;
                      if (col.rgb.a !== 1) {
                        colorStr = `rgba(${col.rgb.r}, ${col.rgb.g}, ${col.rgb.b}, ${col.rgb.a})`;
                      }
                      setColor(colorStr);
                      Editor.addMark(editor, "color", colorStr);
                    }}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </IconButton>
  );
}

export default TextColorButton;
