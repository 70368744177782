import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { IPage, IPageCluster } from "../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  pageLoaded: boolean;
  pageCluster: IPageCluster;
  page: IPage;
};

const PageEditorAddons = (props: PropTypes) => {
  const classes = useStyles(props);
  const { pageLoaded, page } = props;

  if (!pageLoaded) {
    return null;
  }

  if (page.type === "niProductReview") {
    return null;
  }

  return null;
};

export default PageEditorAddons;
