import CustomReportSettingsRow from "./CustomReportSettingsRow";
import MButton from "../../../../components/MButton";
import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { IReportsSettings } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  ctaListWrapper: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: 30,
  },
  saveButton: {
    marginTop: "20px",
    height: "40px",
    borderRadius: "75px",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

type CustomReportsTabProps = {
  reportsSettings: IReportsSettings;
  setReportsSettings: (value: IReportsSettings) => void;
  handleChange: (value: IReportsSettings) => void;
};
const CustomReportsTab = (props: CustomReportsTabProps) => {
  const classes = useStyles(props);

  const { setReportsSettings, reportsSettings, handleChange } = props;

  return (
    <div className={classes.ctaListWrapper}>
      {[...reportsSettings.customReports, { id: "", graphType: "", name: "" }].map((report, index) => (
        <CustomReportSettingsRow
          row={report}
          key={index}
          index={index}
          handleNameChange={(value, currentIndex) => {
            const newCustomReports = [...reportsSettings.customReports];
            newCustomReports[currentIndex] = { ...newCustomReports[currentIndex], name: value };
            setReportsSettings({
              ...reportsSettings,
              customReports: newCustomReports,
            });
          }}
          handleTextChange={(value, currentIndex) => {
            const newCustomReports = [...reportsSettings.customReports];
            newCustomReports[currentIndex] = { ...newCustomReports[currentIndex], id: value };
            setReportsSettings({
              ...reportsSettings,
              customReports: newCustomReports,
            });
          }}
          handleSelectChange={(value, currentIndex) => {
            const newCustomReports = [...reportsSettings.customReports];
            newCustomReports[currentIndex] = { ...newCustomReports[currentIndex], graphType: value };
            setReportsSettings({
              ...reportsSettings,
              customReports: newCustomReports,
            });
          }}
          handleDeleteRow={(currentIndex) => {
            const newCustomReports = [...reportsSettings.customReports];
            newCustomReports.splice(currentIndex, 1);
            setReportsSettings({
              ...reportsSettings,
              customReports: newCustomReports,
            });
          }}
        />
      ))}

      <div className={classes.btnWrapper}>
        <MButton
          customClassNames={classes.saveButton}
          onClick={() => {
            handleChange(reportsSettings);
          }}
        >
          {I18n.t(`reports.settings.save`)}
        </MButton>
      </div>
    </div>
  );
};

export default CustomReportsTab;
