import * as React from "react";
import { Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import CfProgressBar from "./CfProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  searchTextField: {
    // width: '100%',
    width: "100vw",
    height: "100%",
    justifyContent: "center",
    margin: 0,
  },
  searchTextFieldLabel: {
    "&$searchTextFieldFocused": {
      color: theme.palette.text.secondary,
    },
  },
  searchTextFieldFocused: {},
  searchTextFieldUnderline: {
    "&:after": {
      borderBottomColor: theme.palette.text.secondary,
    },
  },
  searchTextFieldOutlinedInput: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    borderWidth: 1,
    height: 45,
    borderColor: "transparent",
    "&$searchTextFieldNotchedOutline": {
      borderColor: "transparent",
      borderWidth: 1,
    },
    "&$searchTextFieldFocused $searchTextFieldNotchedOutline": {
      borderColor: theme.palette.divider,
      borderWidth: 1,
    },
  },
  searchTextFieldNotchedOutline: (props: PropTypes) => ({
    borderColor: `${props.borderColor || theme.palette.divider} !important`,
    borderWidth: 1,
  }),
  searchIcon: {
    color: theme.palette.primary.dark,
    cursor: "pointer",
  },
  input: (props: PropTypes) => ({
    color: props.color || theme.palette.text.primary,
  }),
}));

type PropTypes = {
  searchText?: string;
  placeholder: string;
  showSearchIcon?: boolean;
  customClass?: any;
  customInputClass?: any;
  inputClassName?: any;
  customSearchIconClass?: any;
  loading?: boolean;
  borderColor?: string;
  notchedOutlineCustomClass?: string;
  color?: string;
  variant?: "filled" | "outlined" | "standard";
  autoFocus?: boolean;
  handleSearchTextChange: Function;
  autoSearch?: boolean;
  handleOnEnter?: Function;
};

function CfSearchInput(props: PropTypes) {
  const classes = useStyles(props);
  const {
    searchText,
    loading = false,
    variant = "outlined",
    showSearchIcon = true,
    customClass,
    customInputClass,
    customSearchIconClass,
    placeholder,
    handleSearchTextChange,
    autoFocus = false,
    autoSearch = false,
    notchedOutlineCustomClass = undefined,
    inputClassName,
    handleOnEnter,
  } = props;

  const [text, setText] = React.useState(searchText);
  const debounceHandler = React.useMemo(() => _.debounce((value) => handleSearchTextChange(value), 300), []);

  React.useEffect(() => {
    if (!searchText && text) {
      setText("");
    }
  }, [searchText]);

  return (
    <TextField
      id="outlined-search"
      placeholder={placeholder}
      type="search"
      value={text}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleSearchTextChange(text);
          if (handleOnEnter) {
            handleOnEnter();
          }
        }
      }}
      onChange={(event) => {
        if (!_.isEmpty(text) && _.isEmpty(event.target.value)) {
          handleSearchTextChange("");
        }
        setText(event.target.value);
        if (autoSearch) {
          debounceHandler(event.target.value);
        }
      }}
      className={ClassNames(classes.searchTextField, customClass)}
      margin="normal"
      variant={variant}
      autoFocus={autoFocus}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.searchTextFieldLabel,
          focused: classes.searchTextFieldFocused,
        },
      }}
      InputProps={{
        classes: {
          input: ClassNames(classes.input, inputClassName),
          root: ClassNames(classes.searchTextFieldOutlinedInput, customInputClass),
          focused: classes.searchTextFieldFocused,
          notchedOutline: notchedOutlineCustomClass,
        },
        endAdornment: showSearchIcon ? (
          <InputAdornment position="end">
            {!loading && (
              <Search
                onClick={() => handleSearchTextChange(text)}
                className={ClassNames(classes.searchIcon, customSearchIconClass)}
              />
            )}
            {loading && <CfProgressBar size={20} />}
          </InputAdornment>
        ) : (
          <></>
        ),
      }}
    />
  );
}

export default CfSearchInput;
