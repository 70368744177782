import * as React from "react";
import { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import classNames from "classnames";
import { eActionIcon, ePageType, ePostType, IWebsite } from "../../../../reducers/constants/objectTypes";
import { CONVERSION_POTENTIAL_FUNNEL_STAGE } from "../../../../helpers/constants";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirstWithSelect from "../../../../components/Table/Cells/MTableCellFirstWithSelect";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";
import TrashCanIcon from "../../../../icons/TrashCanIcon";
import MTableCellStatus from "../../../../components/Table/Cells/MTableCellStatus";
import MActionsDropdown from "../../../../components/MActionsDropdown";
import InfoIcon from "../../../../icons/InfoIcon";
import NoteIcon from "../../../../icons/NoteIcon";
import CopyToClipboardIcon from "../../../../icons/CopyToClipboardIcon";
import MTableCell from "../../../../components/Table/Cells/MTableCell";
import { PAGE_TYPE_COLORS } from "../../../../reducers/constants/consts";
import ClassNames from "classnames";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";

const useStyles = makeStyles((theme: Theme) => ({
  actionsWrapper: {
    display: "none",
    justifyContent: "flex-end",
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createButton: {
    height: 36,
    marginBottom: "5px",
  },
  hideBtn: {
    visibility: "hidden",
  },
  actions: {},
  itemWrapper: {
    "&:hover": {
      "& $actionsWrapper": {
        display: "flex",
      },
      "& $topicWrapper": {
        paddingTop: 1,
        borderBottom: "1px solid transparent",
      },
      "& $iconColumn": {
        paddingTop: 1,
      },
      "& $actions": {
        display: "flex",
      },
    },
  },

  childPage: {
    "&:before": {
      position: "absolute",
      top: 35,
      left: 32,
      width: 15,
      height: 1,
      margin: "auto",
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[ePageType.Blog],
    },
    "&:after": {
      position: "absolute",
      top: -9,
      bottom: 0,
      left: 32,
      width: 1,
      height: 25,
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[ePageType.Blog],
      zIndex: 1,
    },
  },
  subDirectory: {
    height: 34,
    "&:before": {
      position: "absolute",
      marginTop: "-1px",
      left: 32,
      width: 10,
      height: 1,
      content: "''",
      backgroundColor: PAGE_TYPE_COLORS[ePageType.Blog],
    },
  },
  iconColumnPh: {
    width: 40,
  },
}));

type PropTypes = {
  item: any;
  approve: (outline: any, postType: string) => void;
  deleteOutline: (outline: any) => void;
  onClick: (outline: any, pageTab: "keywords" | "broadKeywords") => void;
  redirect: (url: string) => void;
  setSelect(selected: boolean): void;
  selected: boolean;
  isChild?: boolean;
  main?: boolean;
  selectedWebsite: IWebsite;
  openInternalNoteDialog?: (strategyKeyword: any) => void;
};

const OutlinePageListRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const theme = useTheme();

  const {
    item,
    onClick,
    approve: approveOutline,
    deleteOutline,
    setSelect,
    selected,
    openInternalNoteDialog,
    isChild,
    main,
  } = props;

  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return () => {
      if (rowData.keyword) {
        navigator.clipboard.writeText(rowData.keyword);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();

  const buttonOptions = useMemo(
    () => [
      { label: I18n.t("strategy.strategyKeywords.btnOptions.targeted"), value: "targeted" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.community"), value: "community" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.article"), value: "article" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.top10"), value: "top10" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.review"), value: "review" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.comparison"), value: "comparison" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.list_article"), value: "list_article" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.product"), value: "product" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.short_video"), value: "short_video" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.long_video"), value: "long_video" },
    ],
    []
  );

  const rowData = useMemo(() => {
    if (isChild) {
      return item.keywordStrategy;
    }
    return item;
  }, [isChild, item]);

  const actions = React.useMemo(() => {
    return [
      {
        text: showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title"),
        tooltip: showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title"),
        onClick: () => handleTitleCopy(),
        iconName: eActionIcon.copyToClipboard,
      },
      {
        text: I18n.t("outline.icons.internal_note"),
        tooltip: I18n.t("outline.icons.internal_note"),
        onClick: () => openInternalNoteDialog(rowData),
        iconName: eActionIcon.note,
      },
      {
        text: I18n.t("outline.icons.delete"),
        tooltip: I18n.t("outline.icons.delete"),
        onClick: () => deleteOutline(rowData),
        iconName: eActionIcon.trashCan,
      },
      {
        text: I18n.t("outline.icons.search"),
        tooltip: I18n.t("outline.icons.search"),
        onClick: () => {
          window.open(`https://www.google.com/search?q=${rowData.keyword}`, "_blank");
        },
        iconName: eActionIcon.search,
      },
    ];
  }, [item, showTitleCopyTooltip, rowData]);

  return (
    <MTableRow
      selected={selected}
      handleRowClick={() => {
        onClick(rowData, "keywords");
      }}
      customClassName={ClassNames(
        classes.itemWrapper,
        isChild && classes.childPage,
        !isChild && !main && classes.subDirectory
      )}
    >
      {item?.topicGroup?.length > 0 ? (
        <MTableCellFirst
          fontSize={theme.typography.pxToRem(14)}
          selected={selected}
          onSelect={setSelect}
          prefix={!main ? <div className={classes.iconColumnPh} /> : null}
          avatarType={main ? (rowData.mergedAt ? "M" : "A") : null}
          cellText={rowData.keyword || "Missing Title !"}
          actions={actions}
          actionClassName={classes.actions}
          columnWidth={"33%"}
        />
      ) : (
        <MTableCellFirstWithSelect
          fontSize={theme.typography.pxToRem(14)}
          selected={selected}
          onSelect={setSelect}
          prefix={!main ? <div className={classes.iconColumnPh} /> : null}
          avatarType={main ? (rowData.mergedAt ? "M" : "A") : null}
          cellText={rowData.keyword || "Missing Title !"}
          actions={actions}
          actionClassName={classes.actions}
          columnWidth={"33%"}
        />
      )}

      <MTableCellStatus status={_.capitalize(rowData.status)} />
      <MTableCellText text={_.capitalize(CONVERSION_POTENTIAL_FUNNEL_STAGE[rowData.conversion_potential] || "None")} />
      <MTableCellNumber type={"bigNumber"} value={rowData.pages} placeHolder={"-"} />
      <MTableCellNumber type={"bigNumber"} value={rowData.clicks} />
      <MTableCellNumber type={"bigNumber"} value={rowData.position} placeHolder={"-"} />
      <MTableCellNumber type={"currency"} value={rowData.potential_value} />
      <MTableCellNumber type={"bigNumber"} value={rowData.variations} placeHolder={"-"} />
      <MTableCellNumber type={"bigNumber"} value={rowData.volume || rowData.strategy?.volume} placeHolder={"-"} />
      <MTableCellText
        text={rowData.goals || "-"}
        toolTipProps={{
          title: rowData.goals || "-",
          enterDelay: 500,
        }}
      />

      <MTableCell>
        <div className={classNames(classes.actionsWrapper)}>
          {buttonOptions.length > 0 && (
            <MActionsDropdown
              placement={"bottom-end"}
              title={I18n.t("strategy.strategyKeywords.submit_as")}
              onClick={(option) => {
                approveOutline(rowData, option.value as string);
              }}
              options={buttonOptions}
            />
          )}
        </div>
      </MTableCell>
    </MTableRow>
  );
};

export default OutlinePageListRow;
