import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

type PropTypes = {
  text: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(18),
    // marginTop: 42,
    // marginBottom: 20,
  },
}));
const MTableRowTitle = (props: PropTypes) => {
  const classes = useStyles(props);
  const { text } = props;
  return (
    <ListItem>
      <ListItemText primary={text} primaryTypographyProps={{ className: classes.title, variant: "subtitle2" }} />
    </ListItem>
  );
};

export default MTableRowTitle;
