import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { IProduct, IWebsiteThemeProps } from "../../../../../../../reducers/constants/objectTypes";
import ProductCTADefaultImageLayout from "./components/ProductCTADefaultImageLayout";
import ProductCTAText from "../../components/ProductCTAText";
import { ProductCTA } from "../../../../../editor";

const useStyles = makeStyles((theme: Theme) => ({
  productCTAWrapper: {
    width: "100%",
    padding: "16px 0",
    display: "flex",
    gap: "30px",
    // maxWidth: (props: PropTypes) => props.element.data?.productWrapperWidth || null,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

type PropTypes = {
  element: ProductCTA;
  product: IProduct;
  readMode?: boolean;
  websiteTheme: IWebsiteThemeProps;

  handleChange?: (value: any) => void;
};
const ProductCTADefaultLayout = (props: PropTypes) => {
  const classes = useStyles(props);
  const { element, product, readMode, websiteTheme, handleChange } = props;

  return (
    <div className={classes.productCTAWrapper}>
      <ProductCTADefaultImageLayout websiteTheme={websiteTheme} maxImages={element.data.maxImages} product={product} />
      <ProductCTAText
        websiteTheme={websiteTheme}
        product={product}
        element={element}
        readMode={readMode}
        handleChange={(value) => handleChange && handleChange(value)}
      />
    </div>
  );
};

export default ProductCTADefaultLayout;
