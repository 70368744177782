import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { uniqBy } from "lodash";
import MSelectMultiple from "../../../components/MSelectMultiple";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";
import { IOutlineParagraphFAQData, IQueryGroup } from "../../../reducers/constants/objectTypes";
import FAQLinks from "./FAQLinks";
import FAQTargetKeywordGroups from "./FAQTargetKeywordGroups";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    width: "100%",
    display: "flex",
    marginBottom: 10,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  selectMultipleWrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  keywordsFieldTitle: {
    minWidth: 170,
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    marginRight: 15,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
  },
  bodyWrapper: {},
  closableRow: {
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    borderBottom: `solid 1px ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  questionWrapper: {
    width: "100%",
    paddingTop: 30,
    paddingBottom: 30,
    "&:last-child": {
      marginBottom: 50,
    },
  },
  listItemIcon: {
    cursor: "pointer",
    marginLeft: 10,
    marginTop: -10,
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  hidden: {
    visibility: "hidden",
  },
  select: {
    textTransform: "capitalize",
  },
  linksWrapper: {},
}));

type PropTypes = {
  paragraph: {
    type: "FAQ";
    title: string;
    data: IOutlineParagraphFAQData;
  };
  keywordGroupsOptions: IQueryGroup[];
  handleParagraphChange: (value) => void;
};

const newQuestionObject = { question: "", targetKeywordsGroups: [], links: [], notes: "" };
const FAQSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { paragraph, keywordGroupsOptions, handleParagraphChange } = props;

  return (
    <div className={classes.bodyWrapper}>
      {[...paragraph.data.questions, newQuestionObject].map((question, index) => (
        <div className={classes.closableRow} key={`faq_question_${index}`}>
          <div className={classes.questionWrapper}>
            <div className={classes.row}>
              <TextFieldMaxLength
                value={question.question}
                titleAndLengthLocation={"left"}
                title={I18n.t("outline.question", { number: index + 1 })}
                placeholder={I18n.t("outline.question_ph")}
                borderRadius={4}
                titleMinWidth={170}
                height={37}
                handleValueChange={(value) => {
                  if (paragraph.data.questions.length === index) {
                    handleParagraphChange({
                      ...paragraph,
                      data: {
                        ...paragraph.data,
                        questions: [
                          ...paragraph.data.questions,
                          {
                            ...(paragraph.data.questions?.[index] || {}),
                            question: value,
                          },
                        ],
                      },
                    });
                    return;
                  }

                  const updatedParagraph = { ...paragraph };
                  updatedParagraph.data.questions[index].question = value;
                  handleParagraphChange(updatedParagraph);
                }}
              />
            </div>

            <div className={classes.row}>
              <FAQTargetKeywordGroups
                keywordGroups={question.targetKeywordsGroups}
                keywordGroupsOptions={keywordGroupsOptions}
                handleKeywordGroupsChange={(targetKeywordsGroups) => {
                  if (paragraph.data.questions.length === index) {
                    handleParagraphChange({
                      ...paragraph,
                      data: {
                        ...paragraph.data,
                        questions: [
                          ...paragraph.data.questions,
                          {
                            ...(paragraph.data.questions?.[index] || {}),
                            targetKeywordsGroups,
                          },
                        ],
                      },
                    });
                    return;
                  }

                  const updatedParagraph = { ...paragraph };
                  updatedParagraph.data.questions[index].targetKeywordsGroups = targetKeywordsGroups;
                  handleParagraphChange(updatedParagraph);
                }}
              />
            </div>
            {/* <div className={classes.row}> */}
            {/*	<Typography className={classes.keywordsFieldTitle} variant={'subtitle2'}> */}
            {/*		{I18n.t('outline.target_keyword_groups')} */}
            {/*	</Typography> */}

            {/*	<MSelectMultiple */}
            {/*		creatable={false} */}
            {/*		wrapperClassName={classes.selectMultipleWrapper} */}
            {/*		options={keywordGroupsOptions} */}
            {/*		selectedOptions={question.targetKeywordsGroups} */}
            {/*		optionLabel={'name'} */}
            {/*		optionValue={'id'} */}
            {/*		borderWidth={1} */}
            {/*		height={37} */}
            {/*		placeholder={I18n.t('outline.target_keyword_groups_ph')} */}
            {/*		handleOptionSelected={(value) => { */}
            {/*			if (paragraph.data.questions.length === index) { */}
            {/*				handleParagraphChange({ */}
            {/*					...paragraph, */}
            {/*					data: { */}
            {/*						...paragraph.data, */}
            {/*						questions: [...paragraph.data.questions, { */}
            {/*							...(paragraph.data.questions?.[index] || {}), */}
            {/*							targetKeywordsGroups: uniqBy([...paragraph.data.questions[index].targetKeywordsGroups, value], 'id'), */}
            {/*						}], */}
            {/*					}, */}
            {/*				}); */}
            {/*				return; */}
            {/*			} */}

            {/*			const updatedParagraph = { ...paragraph }; */}
            {/*			updatedParagraph.data.questions[index].targetKeywordsGroups = uniqBy([...paragraph.data.questions[index].targetKeywordsGroups, value], 'id'); */}
            {/*			handleParagraphChange(updatedParagraph); */}
            {/*		}} */}
            {/*		handleOptionRemoved={(value) => { */}
            {/*			handleParagraphChange({ */}
            {/*				...paragraph, */}
            {/*				data: { */}
            {/*					...paragraph.data, */}
            {/*					targetKeywordsGroups: [...paragraph.data.questions[index].targetKeywordsGroups].filter(t => (t.id !== value._id)), */}
            {/*				}, */}
            {/*			}); */}
            {/*		}} */}
            {/*	/> */}
            {/* </div> */}

            <div className={classes.linksWrapper}>
              <FAQLinks
                links={question.links}
                handleLinksChange={(links) => {
                  if (paragraph.data.questions.length === index) {
                    handleParagraphChange({
                      ...paragraph,
                      data: {
                        ...paragraph.data,
                        questions: [
                          ...paragraph.data.questions,
                          {
                            ...(paragraph.data.questions?.[index] || {}),
                            links,
                          },
                        ],
                      },
                    });
                    return;
                  }

                  const updatedParagraph = { ...paragraph };
                  updatedParagraph.data.questions[index].links = links;
                  handleParagraphChange(updatedParagraph);
                }}
              />
            </div>

            <div className={classes.row}>
              <TextFieldMaxLength
                value={question.notes}
                titleAndLengthLocation={"left"}
                title={I18n.t("outline.notes")}
                placeholder={I18n.t("outline.notes_ph")}
                borderRadius={4}
                titleMinWidth={170}
                height={37}
                multiLine
                handleValueChange={(value) => {
                  if (paragraph.data.questions.length === index) {
                    handleParagraphChange({
                      ...paragraph,
                      data: {
                        ...paragraph.data,
                        questions: [
                          ...paragraph.data.questions,
                          {
                            ...(paragraph.data.questions?.[index] || {}),
                            notes: value,
                          },
                        ],
                      },
                    });
                    return;
                  }

                  const updatedParagraph = { ...paragraph };
                  updatedParagraph.data.questions[index].notes = value;
                  handleParagraphChange(updatedParagraph);
                }}
              />
            </div>
          </div>
          <CloseIcon
            className={ClassNames(classes.listItemIcon, paragraph.data.questions.length === index && classes.hidden)}
            onClick={() => {
              const updatedQuestions = [...paragraph.data.questions];
              updatedQuestions.splice(index, 1);
              handleParagraphChange({
                ...paragraph,
                data: {
                  ...paragraph.data,
                  questions: updatedQuestions,
                },
              });
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
