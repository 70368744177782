import * as React from "react";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Variant } from "@material-ui/core/styles/createTypography";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { Paper, Popover } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import edit from "../../../img/edit.png";
import SelectRow from "./SelectRow";
import {
  fontOptions,
  fontStyleOptions,
  letterCaseOptions,
  textDecorationOptions,
  weightOptions,
} from "../WebsiteEditor/consts/CssConsts";
import InputRow from "./InputRow";
import ColorPickerRow from "./ColorPickerRow";
import DimensionsInputRow from "./DimensionsInputRow";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import MDesignTextField from "../../../components/MDesignTextField";

const useStyles = makeStyles((theme: Theme) => ({
  titleAndLengthRowWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: 10,
    gap: 10,
  },
  editorWrapper: {
    height: "100%",
    width: 416,
    border: "none",
    borderRadius: 0,
  },
  wrapper: {
    marginBottom: 30,
  },
  error: {
    color: "red",
  },
  length: {
    fontSize: theme.typography.pxToRem(14),
  },
  title: {
    color: (props: PropTypes) => props.titleColor || theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 5,
  },
  editWrapper: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  designText: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  editImage: {
    marginLeft: 15,
    cursor: "pointer",
  },
  designWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    cursor: "pointer",
    justifyContent: "space-between",
  },
  popoverPaper: {
    borderRadius: 0,
    boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.15)",
    border: "none",
    marginLeft: 10,
  },
  innerWrapper: {
    padding: "15px 20px",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 15,
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  inputWrapper: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
  input: (props: PropTypes) => ({
    width: "100%",
    fontSize: theme.typography[`${props.variant}`].fontSize,
    fontWeight: theme.typography[`${props.variant}`].fontWeight,
    color: theme.palette.text.primary,
    lineHeight: 1.5,
  }),
}));

type PropTypes = {
  textFieldTitle: string;
  titleColor?: string;
  maxLength?: number;
  fontSize?: number;
  variant: Variant;
  theme: IWebsiteThemeProps;
  multiLine?: boolean;
  elementTitle?: string;
  defaultCss?: any;
  exampleText?: string;
  nonBlocking?: boolean;
  inputClassName?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  text: {
    text: string;
    css: any;
  };
  background?: any;

  handleTextChanged: (text) => void;
};

const mapStateToProps = (state) => ({
  theme: state.websitePages.theme,
});

const mapDispatchToProps = (dispatch) => ({});
const TextInputToolAndDesign = (props: PropTypes) => {
  const classes = useStyles(props);
  const { textFieldTitle, maxLength = 400, background } = props;
  const { text, handleTextChanged } = props;
  const {
    theme,
    error = false,
    variant,
    defaultCss = null,
    disabled = false,
    elementTitle = "",
    multiLine = true,

    autoFocus = false,
    fullWidth = false,
    nonBlocking = false,
  } = props;
  const value = text?.text || "";
  const valueLength = value?.length || 0;
  const finalDefaultCss =
    {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      ...defaultCss,
    } || theme.typography[variant].css;
  const css = text?.css || {};

  const onCssChanged = (cssName, cssValue) => {
    if (cssValue === null || cssValue === "" || cssValue === finalDefaultCss[variant]?.css[cssName]) {
      delete css[cssName];
      handleTextChanged({ text: value, css });
    }
    handleTextChanged({ text: value, css: { ...css, [cssName]: cssValue } });
  };
  return (
    <div className={classes.wrapper}>
      <PopupState variant="popover" popupId="popover">
        {(popupState) => (
          <div style={{ display: "contents" }}>
            <div className={classes.designWrapper} {...bindTrigger(popupState)}>
              <div className={classes.titleAndLengthRowWrapper}>
                <Typography className={classes.title} variant={"subtitle2"}>
                  {textFieldTitle || ""}
                </Typography>
                {maxLength && (
                  <Typography
                    className={ClassNames(classes.length, valueLength >= maxLength && classes.error)}
                    variant={"body2"}
                  >
                    {I18n.t("entail.text_length", { length: valueLength, maxLength })}
                  </Typography>
                )}
              </div>
              <div className={classes.editWrapper}>
                <img width={12} height={12} src={edit} alt={"edit_image"} className={classes.editImage} />
                <Typography variant={"body2"} className={classes.designText}>
                  {I18n.t("website.edit")}
                </Typography>
              </div>
            </div>
            <div style={{ background }}>
              <MDesignTextField
                helperText={textFieldTitle}
                error={error}
                finalDefaultCss={finalDefaultCss}
                css={{
                  ...css,
                }}
                customClass={classes.inputWrapper}
                inputClassName={classes.input}
                onChange={(txt: string) => {
                  if (nonBlocking || !maxLength || txt.length <= maxLength) {
                    handleTextChanged({ text: txt, css });
                  }
                }}
                autoFocus={autoFocus}
                fullWidth={fullWidth}
                value={value}
                disabled={disabled}
                multiline={multiLine}
                placeholder={I18n.t("website.element_design", { element: textFieldTitle })}
              />
            </div>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              elevation={0}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              <Paper className={classes.editorWrapper}>
                <div className={classes.titleWrapper}>
                  <Typography color={"textPrimary"} variant={"subtitle1"}>
                    {`${textFieldTitle} Typography`}
                  </Typography>
                  <CloseIcon className={classes.closeIcon} onClick={popupState.close} />
                </div>
                <div className={classes.innerWrapper}>
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.font")}
                    value={css?.fontFamily}
                    defaultValue={finalDefaultCss?.fontFamily}
                    isValueChanged={css?.fontFamily && css?.fontFamily !== finalDefaultCss?.fontFamily}
                    selectOptions={fontOptions}
                    handleChange={(font) => {
                      onCssChanged("fontFamily", font);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.size")}
                    value={css?.fontSize}
                    defaultValue={finalDefaultCss?.fontSize}
                    isValueChanged={css?.fontSize && css?.fontSize !== finalDefaultCss?.fontSize}
                    handleChange={(size) => {
                      onCssChanged("fontSize", size);
                    }}
                  />
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.weight")}
                    value={css?.fontWeight}
                    defaultValue={finalDefaultCss?.fontWeight}
                    isValueChanged={css?.fontWeight && css?.fontWeight !== finalDefaultCss?.fontWeight}
                    selectOptions={weightOptions}
                    handleChange={(weight) => {
                      onCssChanged("fontWeight", weight);
                    }}
                  />
                  <ColorPickerRow
                    rowTitle={I18n.t("website.design_tool.text_color")}
                    value={css?.color}
                    defaultValue={finalDefaultCss?.color}
                    isValueChanged={css?.color && css?.color !== finalDefaultCss?.color}
                    handleChange={(color) => {
                      onCssChanged("color", color);
                    }}
                  />
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.font_style")}
                    value={css?.fontStyle}
                    defaultValue={finalDefaultCss?.fontStyle}
                    isValueChanged={css?.fontStyle && css?.fontStyle !== finalDefaultCss?.fontStyle}
                    selectOptions={fontStyleOptions}
                    handleChange={(fontStyle) => {
                      onCssChanged("fontStyle", fontStyle);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.line_height")}
                    value={css?.lineHeight}
                    stepSize={"0.1"}
                    defaultValue={finalDefaultCss?.lineHeight}
                    isValueChanged={css?.lineHeight && css?.lineHeight !== finalDefaultCss?.lineHeight}
                    handleChange={(lineHeight) => {
                      onCssChanged("lineHeight", lineHeight);
                    }}
                  />
                  <InputRow
                    rowTitle={I18n.t("website.design_tool.letter_spacing")}
                    value={css?.letterSpacing}
                    defaultValue={finalDefaultCss?.letterSpacing}
                    isValueChanged={css?.letterSpacing && css?.letterSpacing !== finalDefaultCss?.letterSpacing}
                    handleChange={(letterSpacing) => {
                      onCssChanged("letterSpacing", letterSpacing);
                    }}
                  />
                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.letter_case")}
                    value={css?.textTransform}
                    defaultValue={finalDefaultCss?.textTransform}
                    isValueChanged={css?.textTransform && css?.textTransform !== finalDefaultCss?.textTransform}
                    selectOptions={letterCaseOptions}
                    handleChange={(letterCase) => {
                      onCssChanged("textTransform", letterCase);
                    }}
                  />

                  <SelectRow
                    rowTitle={I18n.t("website.design_tool.text_decoration")}
                    value={css?.textDecoration}
                    defaultValue={finalDefaultCss?.textDecoration}
                    isValueChanged={css?.textDecoration && css?.textDecoration !== finalDefaultCss?.textDecoration}
                    selectOptions={textDecorationOptions}
                    handleChange={(textDecoration) => {
                      onCssChanged("textDecoration", textDecoration);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.padding")}
                    value={css?.padding || finalDefaultCss?.padding}
                    defaultValue={finalDefaultCss?.padding}
                    isValueChanged={css?.padding && css?.padding !== finalDefaultCss?.padding}
                    handleChange={(padding) => {
                      onCssChanged("padding", padding);
                    }}
                  />

                  <DimensionsInputRow
                    rowTitle={I18n.t("website.design_tool.margin")}
                    value={css?.margin || finalDefaultCss?.margin}
                    defaultValue={finalDefaultCss?.margin}
                    isValueChanged={css?.margin && css?.margin !== finalDefaultCss?.margin}
                    handleChange={(margin) => {
                      onCssChanged("margin", margin);
                    }}
                  />
                </div>
              </Paper>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TextInputToolAndDesign);
