import filter from "lodash/filter";
import _ from "lodash";
import { BLOG, CATEGORIZE, PAGES } from "./constants/actionTypes";

const initialState = {
  pagesLoaded: false,
  pages: [],
  currentPage: null,
  templatesLoaded: false,
  templates: [],

  autoSaveOn: false,
  saveInProgress: false,
  lastSaveTime: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAGES.NEW_PAGE_CREATED:
      return {
        ...state,
        pages: [action.payload.page, ...state.pages],
        currentPage: action.payload.page,
      };
    case PAGES.PAGES_LOADED:
      return {
        ...state,
        pagesLoaded: true,
        pages: action.payload.pages,
        currentPage: action.payload.pages.length > 0 ? action.payload.pages[0] : initialState.currentPage,
      };
    case PAGES.TEMPLATES_LOADED:
      return {
        ...state,
        templatesLoaded: true,
        templates: action.payload.templates,
      };
    // case BLOG.TOGGLE_AUTO_SAVE:
    //   return {
    //     ...state,
    //     autoSaveOn: ! state.autoSaveOn,
    //   };
    // case BLOG.POST_SAVED:
    //   return {
    //     ...state,
    //     blogPost: action.blogPost,
    //     posts: state.posts.map(p => {
    //       if (p._id === action.blogPost._id || (! p._id && action.newPost)) {
    //         return action.blogPost;
    //       }
    //       return p;
    //     }),
    //   };
    case PAGES.PAGE_SAVED:
      return {
        ...state,
        saveInProgress: false,
        lastSaveTime: Date.now(),
        currentPage: action.page,
        pages: state.pages.map((p) => {
          if (p._id === action.page._id) {
            return action.page;
          }
          return p;
        }),
      };
    case PAGES.PAGE_UPDATED:
      return {
        ...state,
        saveInProgress: false,
        lastSaveTime: Date.now(),
        currentPage: action.payload.page,
        pages: state.pages.map((p) => {
          if (p._id === action.payload.page._id) {
            return action.payload.page;
          }
          return p;
        }),
      };
    case PAGES.PAGE_CHANGED:
      return {
        ...state,
        currentPage: action.page,
      };
    case PAGES.PAGE_SELECTED:
      return {
        ...state,
        currentPage: action.page,
      };
    // // case BLOG.AUTHORS_LOADED:
    // //   return {
    // //     ...state,
    // //     authors: action.payload.authors,
    // //   };
    // case BLOG.AUTHOR_CHANGED:
    //   return {
    //     ...state,
    //     blogPost: {
    //       ...state.blogPost,
    //       author: action.author,
    //     },
    //   };
    // case BLOG.ON_NEW_AUTHOR_CREATED:
    //   return {
    //     ...state,
    //     blogPost: {
    //       ...state.blogPost,
    //       author: action.payload.author,
    //     },
    //   };
    // case BLOG.PREVIEW_IMAGE_SELECTED:
    //   return {
    //     ...state,
    //     blogPost: {
    //       ...state.blogPost,
    //       previewImage: action.file,
    //     },
    //   };
    // case BLOG.ON_TAGS_LOADED:
    //   return {
    //     ...state,
    //     tags: action.payload.tags,
    //   };
    // case BLOG.ON_TAG_SELECTED:
    //   return {
    //     ...state,
    //     blogPost: {
    //       ...state.blogPost,
    //       tags: [...state.blogPost.tags, action.tag],
    //     },
    //   };
    // case BLOG.ON_TAG_REMOVED:
    //   return {
    //     ...state,
    //     blogPost: {
    //       ...state.blogPost,
    //       tags: filter(state.blogPost.tags, tag => tag.name !== action.tag.name),
    //     },
    //   };
    // case BLOG.ON_TAGS_SEARCH_FIELD_CHANGED:
    //   return {
    //     ...state,
    //     tagsSearchField: action.input,
    //   };
    // case BLOG.ON_NEW_TAG_CREATED:
    //   if (action.payload.error) {
    //     return {
    //       ...state,
    //     };
    //   }
    //   return {
    //     ...state,
    //     tags: _.uniqBy([...state.tags, action.payload.tag], '_id').sort((a, b) => a.name.localeCompare(b.name)),
    //     tagsSearchField: '',
    //     blogPost: {
    //       ...state.blogPost,
    //       tags: _.uniqBy([...state.blogPost.tags, action.payload.tag], '_id'),
    //     },
    //   };
    // case BLOG.ON_TOPICS_LOADED:
    //   return {
    //     ...state,
    //     topics: action.payload.topics,
    //     blogPost: {
    //       ...state.blogPost,
    //       topic: action.payload.topics[0],
    //     },
    //   };
    // case BLOG.ON_TOPIC_SELECTED:
    //   return {
    //     ...state,
    //     blogPost: {
    //       ...state.blogPost,
    //       topic: action.topic,
    //     },
    //   };
    // case BLOG.ON_TOPICS_SEARCH_FIELD_CHANGED:
    //   return {
    //     ...state,
    //     topicsSearchField: action.input,
    //   };
    // case BLOG.ON_NEW_TOPIC_CREATED:
    //   return {
    //     ...state,
    //     topics: _.uniqBy([...state.topics, action.payload.topic], '_id').sort((a, b) => a.name.localeCompare(b.name)),
    //     topicsSearchField: '',
    //     blogPost: {
    //       ...state.blogPost,
    //       topic: action.payload.topic,
    //     },
    //   };
    // case BLOG.ON_SOURCE_URL_ADDED:
    //   return {
    //     ...state,
    //     blogPost: {
    //       ...state.blogPost,
    //       sourceURLs: [...(state.blogPost.sourceURLs || []), action.url],
    //     }
    //   };
    // case BLOG.ON_SOURCE_URL_REMOVED:
    //   return {
    //     ...state,
    //     blogPost: {
    //       ...state.blogPost,
    //       sourceURLs: filter(state.blogPost.sourceURLs, url => url !== action.url),
    //     }
    //   };
    default:
      return state;
  }
};
