const splitTextWithDots = (text: string, maxChars = 100) =>
  text.match(new RegExp(`.{1,${maxChars}}`, "g")).map((chunk) => (chunk.length > maxChars ? `${chunk}...` : chunk));

const addAnchorTags = (text: string): string => {
  // Regular expression to find URLs
  const urlRegex = /(https?:\/\/\S+?(?=[,.]?(\s|$)))/g;

  // Replace each URL with HTML anchor tags
  const replacedText = text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);

  return replacedText;
};

export { splitTextWithDots, addAnchorTags };
