import * as React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core";

import { WIDGETS_DISPLAY_RULES } from "../../../../../reducers/constants/actionTypes";
import { ICampaign, IWebsite, eCampaignStatus } from "../../../../../reducers/constants/objectTypes";
import { web } from "../../../../../helpers/urlHelper";
import MTableWrapper from "../../../../../components/Table/MTableWrapper";
import MTableToolbarBaseActionButton from "../../../../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import MTableToolbar from "../../../../../components/Table/Toolbar/MTableToolbar";
import MTablePagination from "../../../../../components/Table/Pagination/MTablePagination";
import agent from "../../../../../agent";
import CampaignsTable from "./components/CampaignsTable";
import MTableToolbarBaseSelect from "../../../../../components/Table/Toolbar/MTableToolbarBaseSelect";
import TimePeriodFilter, { sixMonthOptions } from "../../../../Analytics/Reports/components/filters/TimePeriodFilter";
import NewCampaign from "./NewCampaign";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  searchText: state.widgetDisplayRules.searchText,
  page: state.widgetDisplayRules.page,
  pageSize: state.widgetDisplayRules.pageSize,
  totalPages: state.widgetDisplayRules.totalPages,
  sortField: state.widgetDisplayRules.sortField,
  timePeriod: state.widgetDisplayRules.timePeriod,
  statuses: state.widgetDisplayRules.statuses,
  checkedRules: state.widgetDisplayRules.checkedRules,
});

const mapDispatchToProps = (dispatch) => ({
  openRule: (id) => dispatch(push(web.croCampaignEditor(id))),
  handleRulesLoaded: (payload) => {
    dispatch({ type: WIDGETS_DISPLAY_RULES.RULES_LOADED, payload });
  },
  handleValueChanged: (property) => {
    dispatch({ type: WIDGETS_DISPLAY_RULES.PROPERTY_CHANGE, property });
  },
});

type PropTypes = {
  selectedWebsite: IWebsite;
  page: number;
  pageSize: number;
  searchText: string;
  totalPages: number;
  statuses: eCampaignStatus[];
  timePeriod: any;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  checkedRules: ICampaign[];

  handleValueChanged: (property: Record<string, any>) => void;
  handleRulesLoaded: (payload) => void;
  openRule: (id: string) => void;
};

const CampaignsList = (props: PropTypes) => {
  const { selectedWebsite, page, pageSize, searchText, totalPages, sortField, timePeriod, statuses, checkedRules } =
    props;
  const { handleRulesLoaded, handleValueChanged, openRule } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [showNewCampaignDialog, setShowNewCampaignDialog] = React.useState(false);
  const [bulkPublishLoading, setBulkPublishLoading] = React.useState(false);
  const theme = useTheme();

  const loadCampaigns = (canceled = false) => {
    handleValueChanged({ loading: true });
    const statusesFilter = statuses.length > 0 ? statuses.join(",") : null;

    agent.Campaigns.getCampaigns(
      selectedWebsite._id,
      page,
      pageSize,
      sortField.field,
      sortField.direction,
      searchText,
      statusesFilter
    )
      .then((res) => {
        if (canceled) return;
        handleRulesLoaded(res);
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  const handleBulkPublish = () => {
    const rulesToPublish = checkedRules.filter((rule) => rule.status === eCampaignStatus.DRAFT).map((rule) => rule._id);

    if (rulesToPublish.length === 0) {
      handleValueChanged({ checkedRules: [] });
      return;
    }

    setBulkPublishLoading(true);
    agent.Campaigns.bulkPublishCampaigns(selectedWebsite._id, rulesToPublish)
      .then((res) => {
        if (!res.results.find((result) => result.error)) {
          enqueueSnackbar(I18n.t("widgets_display_rules_workflow.header.bulk_publish_success"));
        } else {
          res.results.map((result) => {
            const rule = checkedRules.find((rule) => rule._id === result.rule);
            if (!result.error) {
              enqueueSnackbar(
                I18n.t("widgets_display_rules_workflow.header.rule_publish_success", { name: rule.name })
              );
            } else {
              enqueueSnackbar(
                I18n.t("widgets_display_rules_workflow.header.rule_publish_fail", {
                  name: rule.name,
                  error: result.message,
                })
              );
            }
          });
        }
        handleValueChanged({ checkedRules: [] });
        loadCampaigns();
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("widgets_display_rules_workflow.header.bulk_publish_fail"));
      })
      .finally(() => {
        setBulkPublishLoading(false);
      });
  };

  React.useEffect(() => {
    loadCampaigns();
  }, [page, pageSize, searchText, statuses, sortField, timePeriod]);

  const statusOptions = [
    { value: eCampaignStatus.DRAFT, name: eCampaignStatus.DRAFT },
    { value: eCampaignStatus.ACTIVE, name: eCampaignStatus.ACTIVE },
  ];

  if (showNewCampaignDialog) {
    return (
      <NewCampaign
        onCreateCampaign={() => {
          setShowNewCampaignDialog(false);
          loadCampaigns();
        }}
      />
    );
  }

  return (
    <MTableWrapper>
      <MTableToolbar
        searchText={searchText}
        searchPlaceHolder={I18n.t("widgets_display_rules_workflow.header.search_campaigns")}
        handleSearchTextChanged={(searchText) => handleValueChanged({ searchText })}
        actions={[
          <TimePeriodFilter
            selectedOptions={sixMonthOptions}
            value={timePeriod}
            handleOnChange={(tp) => handleValueChanged({ timePeriod: tp })}
          />,
          <MTableToolbarBaseActionButton
            handleClick={() => setShowNewCampaignDialog(true)}
            text={I18n.t("widgets_display_rules_workflow.header.create_new_campaign")}
          />,
        ]}
        selectedRows={checkedRules.length}
        onClearSelectedRows={() => {
          handleValueChanged({ checkedRules: [] });
        }}
        actionsLoading={bulkPublishLoading}
        selectedRowActions={[
          {
            text: I18n.t("widgets_display_rules_workflow.header.publish"),
            color: theme.palette.primary.main,
            onClick: handleBulkPublish,
          },
        ]}
      >
        <MTableToolbarBaseSelect
          optionLabel="name"
          optionValue="value"
          multipleOptions
          options={statusOptions}
          selectedOptions={statusOptions.filter((status) => statuses.includes(status.value))}
          placeholder={I18n.t("widgets_main.header.select_status")}
          title={I18n.t("workflow.writer.status")}
          handleChange={(values) => handleValueChanged({ statuses: values?.map((value) => value.value) || [] })}
        />
      </MTableToolbar>

      <CampaignsTable campaignChanged={loadCampaigns} />
      {totalPages > 0 && (
        <MTablePagination
          paginationLimit={pageSize}
          page={page}
          paginationPagesCount={totalPages}
          setPaginationLimit={(pageSize) => handleValueChanged({ pageSize })}
          setPage={(page) => handleValueChanged({ page })}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsList);
