import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { I18n } from "react-redux-i18n";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import CalendarIcon from "../icons/CalendarIcon";

const useStyles = makeStyles((theme: Theme) => ({
  postedTimeWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  datesWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 5,
    marginTop: 2,
    cursor: "pointer",
    "& .MuiInput-input": {
      color: "#333333",
      fontSize: theme.typography.pxToRem(12),
    },
  },
  root: {
    width: 80,
  },
  originalDate: {
    padding: 0,
    color: "#333333",
  },
  dateIcon: {
    marginLeft: 10,
    fontSize: theme.typography.pxToRem(18),
  },
  dateIconReadOnly: {
    color: theme.palette.text.disabled,
  },
}));

type PropTypes = {
  customClass?: string;
  inputClassName?: string;
  disabled?: boolean;

  date: moment.Moment;
  originalDate?: moment.Moment;

  onDateChanged: Function;
};

const MPostedTimePicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const { customClass = null, disabled = false, inputClassName = null, date, originalDate = null } = props;
  const { onDateChanged } = props;

  return (
    <div className={ClassNames(classes.postedTimeWrapper)}>
      <CalendarIcon className={ClassNames(classes.dateIcon, disabled && classes.dateIconReadOnly)} />
      <div className={classes.datesWrapper}>
        <DatePicker
          disabled={disabled}
          // readOnly={disabled}
          // value={moment.max(moment('2020-08-01T10:00:00'), date)}
          value={date}
          placeholder="MM-DD-YYYY"
          disableFuture
          onChange={(cDate) => {
            onDateChanged(cDate.toDate());
          }}
          // minDate={'08-01-2020'}
          maxDate={moment().add(1, "day")}
          format="MMM DD, YYYY"
          InputProps={{
            classes: {
              root: classes.root,
            },
            disableUnderline: true,
          }}
        />
        {originalDate && (
          <Typography className={classes.originalDate} variant="body2">
            {I18n.t("upload_post.original_date", { date: originalDate.format("MM-DD-YYYY") })}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default MPostedTimePicker;
