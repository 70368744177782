import React from "react";
import EntailSquareLogo from "../../../icons/EntailSquareLogo";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  paperWrapper: {
    display: "flex",
    width: 380,
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
    padding: 30,
  },
  welcomeText: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 24,
    lineHeight: "29px",
    margin: "30px 0",
  },
  entailCardLogo: {
    marginTop: 20,
  },
}));

type PropTypes = {
  children?: JSX.Element[];
  title?: string;
  customClass?: string;
};

const AuthCard = (props: PropTypes) => {
  const classes = useStyles(props);
  const { children, title, customClass } = props;

  return (
    <Paper className={classes.paperWrapper}>
      <EntailSquareLogo className={classes.entailCardLogo} />
      <Typography className={classNames(classes.welcomeText, customClass)} variant={"h2"}>
        {title || I18n.t("login.welcome")}
      </Typography>
      {children}
    </Paper>
  );
};

export default AuthCard;
