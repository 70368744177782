import { Editor, Transforms, Text, Element, Node } from "slate";
import { jsx } from "slate-hyperscript";
import { editorType } from "../types/editor.Types";
import { IWebsite } from "../../reducers/constants/objectTypes";
import agent from "../../agent";

const EmptyDocument: Array<Node> = [
  {
    type: editorType.paragraph,
    children: [
      {
        text: "",
      },
    ],
  },
];

class HtmlToSlateObject {
  website: IWebsite;

  html: HTMLElement;

  ELEMENT_TAGS = {
    A: (el) => {
      const url = el.getAttribute("href");
      const newTab = !url?.includes(this.website.url);
      const rel = el.getAttribute("rel") || newTab ? "nofollow" : "follow";
      return {
        type: editorType.link,
        url,
        newTab,
        rel,
      };
    },
    BLOCKQUOTE: () => ({ type: editorType.blockQuote }),
    H1: () => ({ type: editorType.headingOne }),
    H2: () => ({ type: editorType.headingOne }),
    H3: () => ({ type: editorType.headingTwo }),
    H4: () => ({ type: editorType.headingTwo }),
    H5: () => ({ type: editorType.headingTwo }),
    H6: () => ({ type: editorType.headingTwo }),
    // IMG: async (el) => {
    //   const file = await agent.Files.uploadFileFromUrl(this.website, {
    //     alt: el.getAttribute("alt"),
    //     title: el.getAttribute("title"),
    //     caption: el.getAttribute("caption"),
    //     url: el.getAttribute("src"),
    //   });
    //   return { type: editorType.image, file: null };
    // },
    LI: () => ({ type: editorType.listItem }),
    OL: () => ({ type: editorType.numberedList }),
    P: () => ({ type: editorType.paragraph }),
    PRE: () => ({ code: true, type: editorType.paragraph }),
    UL: () => ({ type: editorType.bulletedList }),
    // TABLE: () => ({ type: editorType.table }),
    // TBODY: () => ({ type: editorType.tableBody }),
    // TH: () => ({ type: editorType.tableHeader }),
    // TR: () => ({ type: editorType.tableRow }),
    // TD: () => ({ type: editorType.tableCell }),
  };

  // COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
  TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    CITE: () => ({ bold: true }),
    U: () => ({ underline: true }),
  };

  deserialize = (el) => {
    console.log("el:::", el.nodeType, el.nodeName, el);
    if (el.nodeType === 3) {
      return el.textContent;
    }
    if (el.nodeType !== 1) {
      return null;
    }
    if (el.nodeName === "BR") {
      console.log("return NEW LINE");
      return "\n";
    }

    const { nodeName } = el;
    let parent = el;

    if (nodeName === "PRE" && el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
      console.log("parent", "CODE OR PRE");
      parent = el.childNodes[0];
    }

    if (nodeName === "U" && el.childNodes.length === 1 && el.childNodes[0] && el.childNodes[0].nodeName === "A") {
      console.log("parent", "U AND A");
      parent = el.childNodes[0];
    }

    let children = Array.from(parent.childNodes)
      .map((child) => this.deserialize(child))
      .flat();
    if ((nodeName === "OL" || nodeName === "UL") && parent.childNodes.length > 0) {
      console.log("parent", "LIST", parent.childNodes);
      children = Array.from(Array.from(parent.childNodes).filter((child) => child.nodeName === "LI"))
        .map((child) => this.deserialize(child))
        .flat();
    }

    console.log("parent", nodeName, parent, parent.childNodes, children);

    if (children.length === 0) {
      children = [{ text: "" }];
    }

    if (nodeName === "BODY") {
      return jsx("fragment", {}, children);
    }

    if (this.ELEMENT_TAGS[nodeName]) {
      const attrs = this.ELEMENT_TAGS[nodeName](el);
      console.log("ELEMENT_TAGS attrs", nodeName, attrs);
      return jsx("element", attrs, children);
    }

    if (this.TEXT_TAGS[nodeName]) {
      const attrs = this.TEXT_TAGS[nodeName](el);
      console.log("TEXT_TAGS attrs", nodeName, attrs);
      return children.map((child) => jsx("text", attrs, child));
    }

    console.log("return default", nodeName, children);
    return children;
  };

  private normalize = (node: Node) => {
    if (Text.isText(node)) {
      return;
    }

    // Ensure that block and inline nodes have at least one text child.
    if (Element.isElement(node) && node.children.length === 0) {
      node.children.push({ text: "" });
    }
  };

  deserializeFromHtml = (): Array<Node> => {
    if (!this.website || !this.html) {
      return EmptyDocument;
    }

    // deserialize DOM document into an array of nodes
    const nodes: Array<Node> = this.deserialize(this.html);

    // normalize nodes to Slate compatible format
    nodes.forEach((node) => this.normalize(node));

    return nodes;
  };

  constructor(website: IWebsite, html: HTMLElement) {
    this.website = website;
    this.html = html;
  }
}

/**
 * If input is undefined, null or blank, an empty document will be returned
 */
export const deserializeFromHtml = (website: IWebsite, html: string | undefined | null): Array<Node> => {
  if (!html || html.trim().length === 0) {
    return EmptyDocument;
  }

  // parse html into a DOM document
  const document = new DOMParser().parseFromString(html, "text/html");
  const htmlToSlateObject = new HtmlToSlateObject(website, document.body);
  // deserialize DOM document into an array of nodes
  return htmlToSlateObject.deserializeFromHtml();
};

const withHtml = (editor: Editor, website: IWebsite): Editor => {
  const { insertData, isVoid, isInline } = editor;

  editor.isInline = (element) =>
    [editorType.link, "mention", editorType.comment, editorType.commentV2].includes(element.type)
      ? true
      : isInline(element);

  editor.isVoid = (element) =>
    [
      "comparison-table",
      "image",
      "pricing-table",
      "inline-html",
      "product-embed",
      "product-cta",
      "product-cta-horizontal",
      "charticle-top-product",
      "charticle-top-products",
      "faq",
      "product-details",
      "call-to-action",
      "divider",
      "button",
    ].includes(element.type)
      ? true
      : isVoid(element);

  editor.insertData = (data) => {
    const html = data.getData("text/html");
    // if it's a slate copy & paste, supported out of the box
    const slateFragment = data.getData("application/x-slate-fragment");
    if (html && !slateFragment) {
      try {
        const fragment = deserializeFromHtml(website, html);
        Transforms.insertFragment(editor, fragment);
        return;
      } catch (e) {
        console.log("desirialize error:::", e);
      }
    }
    insertData(data);
  };
  return editor;
};

export default {
  withHtml,
};
