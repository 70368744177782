import React from "react";
import Typography from "@material-ui/core/Typography";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import MQueryGroupLimitPicker from "./MQueryGroupLimitPicker";
import theme from "../themes/theme";

const useStyles = makeStyles(() => ({
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: 20,
  },
  recordsLimitField: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  recordsLimitTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: 10,
  },
}));

type PropTypes = {
  paginationLimit: number;
  page: number;
  paginationPagesCount: number;

  setPaginationLimit: (limit: number) => void;
  setPage: (page: number) => void;
};

const MPagination = (props: PropTypes) => {
  const classes = useStyles();
  const { paginationLimit, page, paginationPagesCount, setPaginationLimit, setPage } = props;
  const paginationLimitOptions = [
    {
      value: 20,
      label: "20",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 100,
      label: "100",
    },
    {
      value: 200,
      label: "200",
    },
  ];

  return (
    <div className={classes.paginationWrapper}>
      <div className={classes.recordsLimitField}>
        <Typography className={classes.recordsLimitTitle} variant={"body1"}>
          {I18n.t("queries.groups.limit")}
        </Typography>
        <MQueryGroupLimitPicker
          limit={paginationLimit}
          menuPlacement={"top"}
          handleLimitChange={(limit) => {
            setPage(1);
            setPaginationLimit(limit);
          }}
          options={paginationLimitOptions}
        />
      </div>
      <Pagination page={page} count={paginationPagesCount || 1} color={"primary"} onChange={(e, val) => setPage(val)} />
    </div>
  );
};

export default MPagination;
