import * as React from "react";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import MTableCellNumberProportion from "./MTableCellNumberProportion";

type PropTypes = {
  open: number;
  resolved: number;
};

const MTableCellComments = (props: PropTypes) => {
  const { open, resolved } = props;

  return (
    <MTableCellNumberProportion
      value1={open}
      value2={resolved}
      tooltipText={I18n.t("workflow.writer.listHeader.open_resolved_comments", {
        open: numeral(open || 0).format("0,0"),
        resolved: numeral(resolved || 0).format("0,0"),
      })}
    />
  );
};

export default MTableCellComments;
