import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import { ePostType } from "../../../reducers/constants/objectTypes";
import MTableCellText from "./MTableCellText";
import MPageTypeAvatar from "../../MPageTypeAvatar";
import CheckIcon from "../../../icons/CheckIcon";
import MTableCellActions from "./MTableCellActions";

const useStyles = makeStyles((theme: Theme) => ({
  firstColumn: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    overflow: "hidden",
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 12,
    width: "auto",
    position: "relative",
    paddingRight: 35,
    "&:hover": {
      "& $avatar": {
        marginBottom: -1,
        display: "none",
      },
      "& $hoverIconWrapper": {
        display: "flex",
        marginBottom: -1,
      },
    },
  },
  avatar: {},
  iconColumn: {
    minWidth: 40,
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    height: 25,
    minWidth: 25,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
  },
  hoverIconWrapper: {
    display: "none",
    backgroundColor: "#B780FE",
  },

  selectedIcon: {
    fill: theme.palette.primary.contrastText,
  },
  selectIcon: {
    height: 20,
    minWidth: 20,
    padding: 0,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "50%",
  },
}));

type PropTypes = {
  columnWidth?: number | string;
  fontSize?: number | string;
  avatarType?: ePostType | string;
  cellText?: string;
  actionClassName?: string;
  selected: boolean;
  onSelect: (selected: boolean) => void;
  prefix?: React.ReactNode;
  actions?: {
    text: string;
    tooltip: string;
    onClick: Function;
    icon: React.ReactNode;
  }[];
};

const MTableCellFirstWithSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    avatarType = null,
    cellText = null,
    selected,
    onSelect,
    prefix,
    fontSize = 12,
    columnWidth = "auto",
    actions = [],
    actionClassName,
  } = props;
  return (
    <div
      className={ClassNames(classes.firstColumn)}
      style={{
        width: columnWidth,
      }}
    >
      <div
        className={classes.iconColumn}
        onClick={(e) => {
          e.stopPropagation();
          onSelect(!selected);
        }}
      >
        {!selected && avatarType && (
          <>
            <div className={ClassNames(classes.iconWrapper, classes.hoverIconWrapper)}>
              <CheckIcon className={classes.selectedIcon} />
            </div>
            <MPageTypeAvatar pageType={avatarType} size={25} className={classes.avatar} />
          </>
        )}
        {!selected && !avatarType && prefix != null && prefix}
        {!selected && !avatarType && prefix == null && <div className={classes.selectIcon} />}
        {selected && (
          <div className={classes.iconWrapper}>
            <CheckIcon className={classes.selectedIcon} />
          </div>
        )}
      </div>
      {cellText && <MTableCellText text={cellText} fontSize={fontSize} />}
      <MTableCellActions actions={actions} customClass={actionClassName} />
    </div>
  );
};

export default MTableCellFirstWithSelect;
