import * as React from "react";
import _, { uniqBy } from "lodash";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { useCountries } from "use-react-countries";

import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { IProduct, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import agent from "../../../../../../../agent";
import MSelect from "../../../../../../../components/MSelect";
import MTopic from "../../../../../../../components/MTopic";
import filter from "lodash/filter";
import DownChevronIcon from "../../../../../../../icons/DownChevronIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme: Theme) => ({
  writersWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  innerWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  writersWrapperFullWidth: {
    width: "100%",
  },
  writerWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // whiteSpace: 'pre',
  },
  writerWrapperFullWidth: {
    flexDirection: "column",
    alignItems: "start",
    gap: 7,
  },
  writerTitle: {
    maxWidth: 65,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    whiteSpace: "pre",
  },
  writerTitleFullWidth: {
    maxWidth: "unset",
  },
  clearIcon: {
    fill: "#808080",
    marginLeft: 5,
    cursor: "pointer",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  select: {
    width: "100%",
    marginLeft: 5,
    backgroundColor: "#EEF2F9",
    borderRadius: 5,
  },
  productsWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: 7,
    gap: 5,
  },
  tagCustomClass: {
    minHeight: 24,
    backgroundColor: theme.palette.divider,
  },
  tagIconCustomClass: {
    color: theme.palette.text.primary,
  },
  tableSelect: {
    width: 200,
    border: `none`,
    backgroundColor: "#EEF2F9",
    paddingLeft: 10,
    borderRadius: 5,
    height: 37,
  },
  dropDownIcon: {
    marginRight: 10,
    fill: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    "&:hover": {
      fill: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    },
  },
}));

type PropTypes = {
  type: "AllCountries" | "IncludedCountries" | "ExcludedCountries";
  selectedCountries: string[];
  handleCountriesChange: (
    type: "AllCountries" | "IncludedCountries" | "ExcludedCountries",
    countries: string[]
  ) => void;
};

const EditLinkSplitTestCountriesPicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const { countries } = useCountries();

  const { type = "AllCountries", selectedCountries = [], handleCountriesChange } = props;

  // const allCountries = uniqBy([...selectedCountries, ...countries], (p) => p);
  const allCountries = React.useMemo(() => {
    return countries.sort((a, b) => a.name.localeCompare(b.name));
  }, [countries]);

  const onCountrySelected = (country) => {
    handleCountriesChange(
      type,
      uniqBy([...selectedCountries, country], (p) => p)
    );
  };

  const onProductRemoved = (country) => {
    handleCountriesChange(
      type,
      filter(selectedCountries, (pCountry) => pCountry !== country)
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <Select
          className={classes.tableSelect}
          value={type}
          onChange={(e) => {
            handleCountriesChange(e.target.value as any, e.target.value === "AllCountries" ? [] : selectedCountries);
          }}
          disableUnderline
          label="state"
          IconComponent={() => <DownChevronIcon className={classes.dropDownIcon} />}
        >
          <MenuItem value={"AllCountries"}>{I18n.t("links_split_test.editor.all_countries")}</MenuItem>
          <MenuItem value={"IncludedCountries"}>{I18n.t("links_split_test.editor.included_countries")}</MenuItem>
          <MenuItem value={"ExcludedCountries"}>{I18n.t("links_split_test.editor.excluded_countries")}</MenuItem>
        </Select>

        {type !== "AllCountries" && (
          <MSelect
            options={allCountries}
            value={null}
            customClass={classes.select}
            optionValue={"name"}
            optionLabel={"name"}
            borderWidth={"0px"}
            getDisplayLabel={(opt) => `${opt.emoji || "🏁"} ${opt.name}`}
            height={37}
            placeholder={I18n.t("links_split_test.editor.select_countries_ph")}
            searchable
            handleChange={(country) => {
              onCountrySelected(country.name);
            }}
          />
        )}
      </div>
      {type !== "AllCountries" && (
        <div className={classes.productsWrapper}>
          {selectedCountries.length > 0 &&
            selectedCountries.map((selectedCountry) => (
              <MTopic
                fontSize={12}
                key={selectedCountry}
                tag={`${allCountries.find((ac) => ac.name === selectedCountry)?.emoji || "🏁"} ${selectedCountry}`}
                onRemoveClicked={(e) => onProductRemoved(selectedCountry)}
                tagCustomClass={classes.tagCustomClass}
                tagIconCustomClass={classes.tagIconCustomClass}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default EditLinkSplitTestCountriesPicker;
