import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { IQuestion, IWebsite } from "../../../../reducers/constants/objectTypes";
import SlashIcon from "../../../../icons/SlashIcon";
import { I18n } from "react-redux-i18n";
import CloseIcon from "../../../../icons/PageEditorCloseIcon";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: 49,
    paddingRight: 20,
    paddingLeft: 20,
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  postDetails: {
    display: "flex",
    alignItems: "baseline",
  },
  closeIcon: {
    cursor: "pointer",
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  headerTitle: {
    marginLeft: 20,
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "19px",
  },
  headerType: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "19px",
  },
  marginX: {
    margin: "0 10px",
  },
  backIcon: {
    cursor: "pointer",
  },
  questionType: {
    color: "#808080",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "19px",
    marginRight: 7,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  question: IQuestion;
  pageLoaded: boolean;
  returnToPrevious: () => void;
};

const CommunityEditorHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, pageLoaded, question, returnToPrevious } = props;

  return (
    <div className={classes.toolbar}>
      <div className={classes.toolbarDetails}>
        {pageLoaded && (
          <div className={classes.postDetails}>
            <Typography className={classes.headerTitle} color={"textPrimary"} variant={"body2"}>
              {selectedWebsite?.name}
              <span className={classes.marginX}>
                <SlashIcon />
              </span>
            </Typography>
            <Typography className={classes.headerType} color={"textPrimary"} variant={"body2"}>
              {question?.locale.toUpperCase() || "EN"}
            </Typography>
            <Typography className={classes.headerType} color={"textPrimary"} variant={"body2"}>
              <span className={classes.marginX}>
                <SlashIcon />
              </span>
              {I18n.t("main.tabs.community")}
              <span className={classes.marginX}>
                <SlashIcon />
              </span>
            </Typography>
            <Typography className={classes.questionType} color={"textPrimary"} variant={"body2"}>
              {question?.state.charAt(0).toUpperCase() + question.state.slice(1).toLowerCase()}:
            </Typography>
            <Typography className={classes.headerType} color={"textPrimary"} variant={"body2"}>
              {question?.title}
            </Typography>
          </div>
        )}
      </div>
      {pageLoaded && <CloseIcon className={classes.closeIcon} exitModal={returnToPrevious} />}
    </div>
  );
};

export default CommunityEditorHeader;
