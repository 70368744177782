import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import { IWebsite, IWebsiteCommonComponents } from "../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import MRouterPrompt from "../../../components/MRouterPrompt";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  page: state.websitePages.commonComponents,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (commonComponents) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "commonComponents",
      value: commonComponents,
    }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  page: IWebsiteCommonComponents;

  onPropertyChanged: (commonComponents) => void;
};

const EmailSubscription = (props: PropTypes) => {
  const { page, onPropertyChanged } = props;
  return (
    <PageSection
      sectionTitle={I18n.t("website.common_components.side_subscribe.name")}
      checked={page.sideSubscribe.enabled}
      onCheckedChange={(value) =>
        onPropertyChanged({ ...page, sideSubscribe: { ...page.sideSubscribe, enabled: value } })
      }
    >
      <MRouterPrompt />
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={page.sideSubscribe?.title}
        placeholder={""}
        title={I18n.t("website.common_components.side_subscribe.title")}
        maxLength={100}
        height={37}
        borderRadius={4}
        marginBottom={30}
        handleValueChange={(value) =>
          onPropertyChanged({ ...page, sideSubscribe: { ...page.sideSubscribe, title: value } })
        }
      />
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={page.sideSubscribe?.text}
        placeholder={""}
        title={I18n.t("website.common_components.side_subscribe.text")}
        maxLength={300}
        height={37}
        multiLine
        borderRadius={4}
        marginBottom={30}
        handleValueChange={(value) =>
          onPropertyChanged({ ...page, sideSubscribe: { ...page.sideSubscribe, text: value } })
        }
      />
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={page.sideSubscribe?.terms}
        placeholder={""}
        title={I18n.t("website.common_components.side_subscribe.terms")}
        maxLength={300}
        height={37}
        multiLine
        borderRadius={4}
        marginBottom={30}
        handleValueChange={(value) =>
          onPropertyChanged({ ...page, sideSubscribe: { ...page.sideSubscribe, terms: value } })
        }
      />
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={page.sideSubscribe?.customElementCode}
        placeholder={""}
        title={I18n.t("website.common_components.side_subscribe.custom_element_code")}
        height={200}
        multiLine
        borderRadius={4}
        marginBottom={30}
        handleValueChange={(value) =>
          onPropertyChanged({
            ...page,
            sideSubscribe: { ...page.sideSubscribe, customElementCode: value },
          })
        }
      />
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscription);
