import { Divider, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldProperty from "./itemProperties/components/TextFieldProperty";
import RequiredFieldProperty from "./itemProperties/components/RequiredFieldProperty";
import WidthProperty from "./itemProperties/components/WidthProperty";
import TextareaProperty from "./itemProperties/components/TextareaProperty";
import { SelectedOptionProperty } from "./itemProperties/components/SelectedOptionProperty";
import MultipleOptionsFieldProp from "./components/MultipleOptionsFieldProp";
import { eWidgetFieldWidth, IWidgetDropDownFieldProps } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({}),
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
  };
});

type PropTypes = {
  properties: IWidgetDropDownFieldProps;
  onPropertyChange(properties: IWidgetDropDownFieldProps): void;
};

const DropdownWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;
  const classes = useStyles(props);

  const onHintChange = (event: any) => {
    const hint = event;
    onPropertyChange({ ...properties, hint });
  };

  const onRequiredChange = (required: boolean) => {
    onPropertyChange({ ...properties, required });
  };

  const onWidthChange = (width: eWidgetFieldWidth) => {
    onPropertyChange({ ...properties, width });
  };

  const onSelectedOptionChange = (event: any) => {
    const selectedOption = event.target.value;
    onPropertyChange({ ...properties, selectedOption });
  };

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.label")}
          placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.label_ph")}
          onChange={(label) => onPropertyChange({ ...properties, label })}
          value={properties?.label}
        />
        <Divider className={classes.divider} />
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.placeholder")}
          onChange={(placeholder) => onPropertyChange({ ...properties, placeholder })}
          placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.placeholder_ph")}
          value={properties?.placeholder}
        />
        <Divider className={classes.divider} />
        <TextareaProperty onChange={onHintChange} value={properties?.hint} />
      </div>

      <MultipleOptionsFieldProp
        options={properties.options}
        handleOptionsChanges={(options) => onPropertyChange({ ...properties, options })}
      />

      <SelectedOptionProperty
        items={properties.options}
        selectedOption={properties.selectedOption}
        onChange={onSelectedOptionChange}
      />

      <RequiredFieldProperty required={properties.required} onChange={onRequiredChange} />
      <WidthProperty value={properties.width} onChange={onWidthChange} />
    </div>
  );
};

export default DropdownWidgetItem;
