import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    height: 80,
    width: 60,
  },
}));

type PropTypes = {
  className?: string;
};

function LayoutPaneSelectedIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 82 63"}>
      <rect x="0.5" width="81" height="63" rx="8" fill="#609EFF" />
      <path d="M4.5 11H77.5V54C77.5 55.6569 76.1569 57 74.5 57H7.5C5.84315 57 4.5 55.6569 4.5 54V11Z" fill="#404142" />
      <circle cx="10.5" cy="6" r="2" fill="#404142" />
      <circle cx="16.5" cy="6" r="2" fill="#404142" />
      <circle cx="22.5" cy="6" r="2" fill="#404142" />
      <path
        d="M57.5 13H75.5V52C75.5 53.6569 74.1569 55 72.5 55H60.5C58.8431 55 57.5 53.6569 57.5 52V13Z"
        fill="#609EFF"
      />
    </SvgIcon>
  );
}

export default LayoutPaneSelectedIcon;
