import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import { omit } from "lodash";

import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    border: (props: PropTypes) => (props.withBorder ? `1px solid ${theme.palette.divider}` : "none"),
    borderRadius: 3,
    "&:hover": {
      cursor: "pointer",
      "& $dropDownIcon": {
        color: theme.palette.primary.main,
      },
      "& $placeholder": {
        color: theme.palette.primary.main,
      },
    },
  },
  disabled: {
    cursor: "default",
    opacity: 0.5,
    "&:hover": {
      cursor: "default",
      "& $dropDownIcon": {
        color: theme.palette.text.primary,
      },
      "& $placeholder": {
        cursor: "default",
        color: theme.palette.text.primary,
      },
    },
  },
  expanded: {
    "& $dropDownIcon": {
      color: theme.palette.primary.main,
    },
    "& $placeholder": {
      color: theme.palette.primary.main,
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
  },
  popper: {
    zIndex: 1000,
  },
  dropDownIcon: {
    width: 20,
    marginLeft: -5,
  },
  optionsWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
    gap: 3,
    overflowY: "auto",
  },
  optionLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: "4px 20px",
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E6EEFF",
      color: theme.palette.primary.main,
      borderRadius: "0px 3px 3px 0px",
    },
  },
  placeholder: {
    fontSize: 14,
    lineHeight: "normal",
    fontWeight: theme.typography.fontWeightLight as any,
  },
  selectedOption: {
    backgroundColor: "#E6EEFF",
    color: theme.palette.primary.main,
    borderRadius: "0px 3px 3px 0px",
  },
}));

type IOption = Record<string, any>;

type PropTypes = {
  placeholder?: string;
  disabled?: boolean;
  optionLabel?: string;
  optionValue?: string;
  options: IOption[];
  value: IOption;
  className?: string;
  classes?: {
    root?: string;
    placeholder?: string;
    placeholderSelected?: string;
    dropDownIcon?: string;
    paper?: string;
  };

  zIndex?: number;
  withBorder?: boolean;
  dynamicWidth?: boolean;
  handleOnChange: (value: IOption) => void;
};

export type IMDropdown = PropTypes;

const MDropdown = (props: PropTypes) => {
  const classes = useStyles(omit(props, "classes"));
  const {
    placeholder,
    optionLabel = "name",
    optionValue = "_id",
    options,
    value,
    className,
    disabled,
    zIndex,
    dynamicWidth,
  } = props;
  const { handleOnChange } = props;
  const propClasses = props.classes || {};
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) {
      return;
    }
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <div
        className={ClassNames(
          className,
          propClasses.root,
          classes.container,
          open && classes.expanded,
          disabled && classes.disabled
        )}
        onClick={handleToggle}
        ref={anchorRef}
      >
        <Typography
          className={ClassNames(
            propClasses.placeholder,
            value?.[optionLabel] && propClasses.placeholderSelected,
            classes.placeholder
          )}
        >
          {value?.[optionLabel] || placeholder || I18n.t("common.search_placeholder")}
        </Typography>
        {open ? (
          <KeyboardArrowUp className={ClassNames(propClasses.dropDownIcon, classes.dropDownIcon)} />
        ) : (
          <KeyboardArrowDown className={ClassNames(propClasses.dropDownIcon, classes.dropDownIcon)} />
        )}
      </div>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement={"bottom-start"}
        className={classes.popper}
        style={{
          zIndex: zIndex || 1000,
          ...(dynamicWidth ? { width: anchorRef.current?.clientWidth } : {}),
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <div className={ClassNames(propClasses.paper, classes.paper)}>
            <div className={classes.optionsWrapper}>
              {options &&
                options.length > 0 &&
                options.map((option, index) => (
                  <Typography
                    key={`${option[optionValue]}-${index}`}
                    className={ClassNames(
                      classes.optionLabel,
                      value?.[optionValue] === option[optionValue] && classes.selectedOption
                    )}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOnChange(option);
                      setOpen(false);
                    }}
                  >
                    {option[optionLabel]}
                  </Typography>
                ))}
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default MDropdown;
