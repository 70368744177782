import React, { useMemo } from "react";
import { Slider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useVideoEditorContext } from "../VideoEditorContext";

const useStyles = makeStyles(() => ({
  slider: {
    padding: 0,
    height: 5,
    "& .MuiSlider-track": {
      height: 5,
    },
    "& .MuiSlider-rail": {
      height: 5,
    },
  },
  thumb: {
    display: "none",
    "&:hover, &.Mui-active": {
      display: "none",
    },
  },
}));

const TimelineSlider = () => {
  const classes = useStyles();
  const { timelineState, selectedTimeline, jumpToTime } = useVideoEditorContext();

  const totalVideoLength = useMemo(
    () => selectedTimeline.rows.find((row) => row.type === "video").actions.slice(-1)[0]?.end || 0,
    [selectedTimeline.rows]
  );

  return (
    <Slider
      value={timelineState.time}
      onChange={(_, value) => jumpToTime(value as number)}
      min={0}
      max={totalVideoLength}
      step={0.1}
      style={{ width: "100%" }}
      classes={{
        root: classes.slider,
        thumb: classes.thumb,
      }}
    />
  );
};

export default TimelineSlider;
