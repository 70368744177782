import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 11,
    width: 11,
    fill: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  className?: string;
};

function FunnelIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={classNames(classes.root, className)} viewBox={"0 0 11 11"}>
      <path d="M5.08919 11C4.9201 11 4.77861 10.9379 4.66472 10.8138C4.55084 10.6897 4.4939 10.5355 4.4939 10.3513V6.1064L0.726794 0.891279C0.597389 0.703239 0.578626 0.507683 0.670503 0.30461C0.762381 0.101537 0.917453 0 1.13572 0H9.87084C10.0891 0 10.2442 0.101537 10.3361 0.30461C10.4279 0.507683 10.4092 0.703239 10.2798 0.891279L6.51266 6.1064V10.3513C6.51266 10.5355 6.45572 10.6897 6.34184 10.8138C6.22796 10.9379 6.08647 11 5.91737 11H5.08919ZM5.50328 5.72L8.83429 1.09999H2.17227L5.50328 5.72Z" />
    </SvgIcon>
  );
}

export default FunnelIcon;
