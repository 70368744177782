import { WORKFLOW_PUBLISHED } from "./constants/actionTypes";

const initialState = {
  loaded: false,
  refreshItemList: false,
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WORKFLOW_PUBLISHED.LOADED:
      return {
        ...state,
        loaded: true,
        refreshItemList: false,
        items: action.payload.pages,
      };
    case WORKFLOW_PUBLISHED.REFRESH:
      return {
        ...state,
        refreshItemList: true,
      };

    default:
      return state;
  }
};
