import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { MANAGE_CONTENT } from "../../../../../reducers/constants/actionTypes";
import { ePostType, ITag, IWebsite } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import MSelect from "../../../../../components/MSelect";
import { AbilityContext } from "../../../../../casl/can";

const useStyles = makeStyles((theme: Theme) => ({
  writerWrapper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "pre",
  },
  writerTitle: {
    marginRight: 20,
  },
  writersWrapper: {
    width: "100%",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  tag: ITag;

  onTagChanged: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  tag: state.manageContent.selectedTag,
});

const mapDispatchToProps = (dispatch) => ({
  onTagChanged: (tag) => dispatch({ type: MANAGE_CONTENT.ON_TAG_UPDATED, tag }),
});

const TagWriterSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const { tag, selectedWebsite, onTagChanged } = props;
  const [writers, setWriters] = useState([]);
  const ability = React.useContext<any>(AbilityContext);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    agent.Writers.getWriters(selectedWebsite, ePostType.TOPIC)
      .then((res) => {
        setWriters(res.writers);
      })
      .catch((e) => {
        // show error message
      });
  }, [selectedWebsite]);

  const selectableWriters = [{ email: "All", name: "Any writer" }, ...writers];

  if (!ability.can("assign_writer", ePostType.TOPIC)) {
    return null;
  }

  return (
    <div className={classes.writerWrapper}>
      <Typography variant={"body1"} className={classes.writerTitle} color={"textSecondary"}>
        {I18n.t("edit_post.writer")}
      </Typography>
      <div className={classes.writersWrapper}>
        <MSelect
          options={selectableWriters}
          value={_.find(selectableWriters, { email: tag?.writer?.email || "All" })}
          optionLabel={"name"}
          optionValue={"_id"}
          height={40}
          borderRadius={0}
          menuPlacement={"bottom"}
          fullWidth
          searchable
          getDisplayLabel={(writer) => `${writer.name}`}
          handleChange={(writer) => {
            onTagChanged({ ...tag, writer: writer.name === "All" ? null : writer });
          }}
        />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TagWriterSelect);
