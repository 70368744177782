import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { find } from "lodash";
import Popover from "@material-ui/core/Popover";
import WebsiteAvatar from "./drawer/WebsiteAvatar";
import DownChevronIcon from "../../../icons/DownChevronIcon";
import { web } from "../../../helpers/urlHelper";
import popup_strategy_img from "../../../img/popup_strategy_img.png";
import popup_video_img from "../../../img/popup_video_img.png";
import popup_cms_img from "../../../img/popup_cms_img.svg";
import popup_page_builder_img from "../../../img/popup_page_builder_img.png";
import popup_cro_img from "../../../img/popup_cro_img.svg";
import popup_marketplace_img from "../../../img/popup_marketplace_img.png";
import popup_seo_img from "../../../img/popup_seo_img.png";
import popup_community_img from "../../../img/popup_community_img.svg";
import goal_attribution_img from "../../../img/goal_attribution.png";
import BlockedProductsPopup from "../../BlockedProductsPopup/BlockedProductsPopup";
import ability from "../../../casl/ability";
import { eUserRoles, IWebsite } from "../../../reducers/constants/objectTypes";
import ClassNames from "classnames";
import { ArrowForward } from "@material-ui/icons";
import { getLegacyTabs } from "../../../helpers/tabHelper";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  menuWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    marginLeft: 14,
    cursor: "pointer",
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  icon: {
    color: theme.palette.text.primary,
  },
  root: {
    top: 49,
    zIndex: 1000,
    left: 0,
  },
  paper: {
    borderRadius: 5,
    border: `solid 1px ${theme.palette.divider}`,
    boxShadow: "4px 4px 22px 0px rgba(0, 0, 0, 0.25)",
    width: 661,
    display: "flex",
    left: "40px !important",
    top: "50px !important",
  },
  leftSide: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    padding: "15.9px 0 18.7px",
    background: "#DDEAFF",
  },
  middleWrapper: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "25px 30px 30px",
    background: theme.palette.background.paper,
  },
  rightSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "20px 30px",
    background: "#F5F6F8",
  },
  tabsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  selectedParentWrapper: {
    backgroundColor: "#BAD5FF",
  },
  parentWrapper: {
    cursor: "pointer",
    padding: "11.2px 18.7px",
    "& > $label": {
      fontWeight: theme.typography.fontWeightRegular as any,
    },
    "&:hover": {
      backgroundColor: "#BAD5FF",
    },
  },

  tabWrapper: {
    padding: 10,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    gap: 5,
    "&:hover": {
      "& > $text": {
        color: theme.palette.primary.main,
      },
      "& > $label": {
        color: theme.palette.primary.main,
        "& >  $arrowIcon": {
          visibility: "visible",
        },
      },
    },
  },
  selectedTabWrapper: {
    "& > $text": {
      color: theme.palette.primary.main,
    },
    "& > $label": {
      color: theme.palette.primary.main,
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    paddingBottom: 10,
    margin: "0 10px",
    color: theme.palette.text.primary,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  label: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    display: "flex",
    alignItems: "center",
    lineHeight: "normal",
    gap: 10,
  },
  arrowIcon: {
    visibility: "hidden",
    fontSize: 15,
  },
  text: {
    color: theme.palette.text.secondary,
    lineHeight: "normal",
    fontWeight: theme.typography.fontWeightLight as any,
  },
  chevronRightIcon: {
    fontSize: 20,
  },
  logo: {
    width: 26,
    borderRadius: 0,
    height: 26,
  },
}));

type PropTypes = {
  essentials: {
    url: string;
    label: string;
    mainLabel?: string;
    description?: string;
    canView?: boolean;
    key?: string;
    backgroundColor?: string;
    parent: string;
  }[];
};
const productsMap = {
  strategy: {
    image: popup_strategy_img,
    productName: I18n.t("blocked_products_popups.strategy.productName"),
    title: I18n.t("blocked_products_popups.strategy.title"),
    subtitle: I18n.t("blocked_products_popups.strategy.subtitle"),
    bullets: [
      I18n.t("blocked_products_popups.strategy.bullets.bullet1"),
      I18n.t("blocked_products_popups.strategy.bullets.bullet2"),
      I18n.t("blocked_products_popups.strategy.bullets.bullet3"),
    ],
    learnMoreUrl: "https://entail.ai/products/content-strategy-software",
  },
  video: {
    image: popup_video_img,
    productName: I18n.t("blocked_products_popups.video.productName"),
    title: I18n.t("blocked_products_popups.video.title"),
    subtitle: I18n.t("blocked_products_popups.video.subtitle"),
    bullets: [
      I18n.t("blocked_products_popups.video.bullets.bullet1"),
      I18n.t("blocked_products_popups.video.bullets.bullet2"),
      I18n.t("blocked_products_popups.video.bullets.bullet3"),
    ],
  },
  cms: {
    image: popup_cms_img,
    productName: I18n.t("blocked_products_popups.cms.productName"),
    title: I18n.t("blocked_products_popups.cms.title"),
    subtitle: I18n.t("blocked_products_popups.cms.subtitle"),
    bullets: [
      I18n.t("blocked_products_popups.cms.bullets.bullet1"),
      I18n.t("blocked_products_popups.cms.bullets.bullet2"),
      I18n.t("blocked_products_popups.cms.bullets.bullet3"),
    ],
    learnMoreUrl: "https://entail.ai/products/content-management-system",
  },
  page_builder: {
    image: popup_page_builder_img,
    productName: I18n.t("blocked_products_popups.page_builder.productName"),
    title: I18n.t("blocked_products_popups.page_builder.title"),
    subtitle: I18n.t("blocked_products_popups.page_builder.subtitle"),
    bullets: [
      I18n.t("blocked_products_popups.page_builder.bullets.bullet1"),
      I18n.t("blocked_products_popups.page_builder.bullets.bullet2"),
      I18n.t("blocked_products_popups.page_builder.bullets.bullet3"),
    ],
    learnMoreUrl: "https://entail.ai/products/page-builder",
  },
  cro: {
    image: popup_cro_img,
    productName: I18n.t("blocked_products_popups.campaigns.productName"),
    title: I18n.t("blocked_products_popups.campaigns.title"),
    subtitle: I18n.t("blocked_products_popups.campaigns.subtitle"),
    bullets: [
      I18n.t("blocked_products_popups.campaigns.bullets.bullet1"),
      I18n.t("blocked_products_popups.campaigns.bullets.bullet2"),
      I18n.t("blocked_products_popups.campaigns.bullets.bullet3"),
    ],
    learnMoreUrl: "https://entail.ai/products/conversion-rate-optimization-software",
  },
  cro_analytics: {
    image: goal_attribution_img,
    productName: I18n.t("blocked_products_popups.cro.productName"),
    title: I18n.t("blocked_products_popups.cro.title"),
    subtitle: I18n.t("blocked_products_popups.cro.subtitle"),
    bullets: [
      I18n.t("blocked_products_popups.cro.bullets.bullet1"),
      I18n.t("blocked_products_popups.cro.bullets.bullet2"),
      I18n.t("blocked_products_popups.cro.bullets.bullet3"),
    ],
    learnMoreUrl: "https://entail.ai/products/conversion-rate-optimization-software",
  },
  marketplace: {
    image: popup_marketplace_img,
    productName: I18n.t("blocked_products_popups.marketplace.productName"),
    title: I18n.t("blocked_products_popups.marketplace.title"),
    subtitle: I18n.t("blocked_products_popups.marketplace.subtitle"),
    bullets: [
      I18n.t("blocked_products_popups.marketplace.bullets.bullet1"),
      I18n.t("blocked_products_popups.marketplace.bullets.bullet2"),
      I18n.t("blocked_products_popups.marketplace.bullets.bullet3"),
    ],
    learnMoreUrl: "https://entail.ai/experts",
  },
  seo: {
    image: popup_seo_img,
    productName: I18n.t("blocked_products_popups.seo.productName"),
    title: I18n.t("blocked_products_popups.seo.title"),
    subtitle: I18n.t("blocked_products_popups.seo.subtitle"),
    bullets: [
      I18n.t("blocked_products_popups.seo.bullets.bullet1"),
      I18n.t("blocked_products_popups.seo.bullets.bullet2"),
      I18n.t("blocked_products_popups.seo.bullets.bullet3"),
    ],
  },
  community: {
    image: popup_community_img,
    productName: I18n.t("blocked_products_popups.community.productName"),
    title: I18n.t("blocked_products_popups.community.title"),
    subtitle: I18n.t("blocked_products_popups.community.subtitle"),
    bullets: [
      I18n.t("blocked_products_popups.community.bullets.bullet1"),
      I18n.t("blocked_products_popups.community.bullets.bullet2"),
      I18n.t("blocked_products_popups.community.bullets.bullet3"),
    ],
  },
};

const DropdownTabs = (props: PropTypes) => {
  const classes = useStyles(props);
  const { essentials } = props;
  const history = useHistory();
  const match = useRouteMatch(`${web.homePage()}/:page`);
  const [selectedTab, setSelectedTab] = React.useState(find(essentials, { url: match?.url || "" }));
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [productKey, setProductKey] = React.useState(null);

  const { selectedWebsite } = useSelector((state) => ({
    selectedWebsite: state.home.selectedWebsite,
  }));

  const productParents = React.useMemo(() => {
    return [
      {
        name: I18n.t("main.products.cms.name"),
        key: "entail_cms",
        description: I18n.t("main.products.cms.description"),
        view: true,
      },
      {
        name: I18n.t("main.products.cro.name"),
        description: I18n.t("main.products.cro.description"),
        key: "entail_cro",
        view: true,
      },
      {
        name: I18n.t("main.products.legacy.name"),
        description: I18n.t("main.products.legacy.description"),
        key: "entail_legacy",
        tab: true,
        view: ability.can("view", "legacy_tab") || getLegacyTabs(ability, selectedWebsite)?.length > 0,
      },
    ];
  }, [selectedWebsite, ability]);

  const [productParent, setProductParent] = React.useState(
    selectedTab?.parent ? productParents.find((pp) => pp.key === selectedTab.parent) : productParents[0]
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (tab) => {
    if (tab.canView) {
      history.push(tab.url);
      handleClose();
      setSelectedTab(tab);
    } else {
      handleClose();
      setProductKey(tab.key);
    }
  };

  React.useEffect(() => {
    if (selectedTab && selectedTab?.url === match?.url) {
      return;
    }
    if (match?.url) {
      const tab = find(essentials, { url: match?.url || "" });
      setSelectedTab({ ...tab });
      if (tab?.parent) {
        setProductParent(productParents.find((pp) => pp.key === tab.parent));
      }
    }
  }, [match?.url]);

  return (
    <div className={classes.wrapper}>
      <WebsiteAvatar
        color={selectedTab?.backgroundColor}
        defaultLogo
        website={selectedWebsite}
        selected
        className={classes.logo}
      />
      <div className={classes.menuWrapper} onClick={(e) => handleClick(e)}>
        <Typography color={"textPrimary"} className={classes.name}>
          {selectedTab?.label}
        </Typography>
        {<DownChevronIcon className={classes.icon} />}
      </div>
      <Popover
        classes={{ paper: classes.paper, root: classes.root }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={classes.leftSide}>
          {productParents
            .filter((pp) => pp.view)
            .map((pp) => (
              <div
                key={`product_parent_${pp.key}`}
                className={ClassNames(
                  classes.parentWrapper,
                  productParent.key === pp.key && classes.selectedParentWrapper
                )}
                onClick={() => {
                  if (pp.tab) {
                    handleChange(essentials.find((es) => es.parent === pp.key));
                    return;
                  }
                  setProductParent(pp);
                }}
              >
                <Typography variant={"body1"} className={classes.label}>
                  {pp.name}
                </Typography>
              </div>
            ))}
        </div>
        <div className={classes.middleWrapper}>
          {!productParent.tab && (
            <Typography variant={"body2"} className={classes.title}>
              {productParent.description}
            </Typography>
          )}
          <div className={classes.tabsWrapper}>
            {essentials
              .filter((es) => es.parent === productParent.key && !productParent.tab)
              .map((tab, i) => (
                <div
                  key={tab.label}
                  className={ClassNames(classes.tabWrapper, selectedTab?.key === tab.key && classes.selectedTabWrapper)}
                  onClick={() => handleChange(tab)}
                >
                  <Typography variant={"body1"} className={classes.label}>
                    <span> {tab.mainLabel || tab.label}</span>
                    <ArrowForward className={classes.arrowIcon} />
                  </Typography>
                  <Typography variant={"body2"} className={classes.text}>
                    {tab.description}
                  </Typography>
                </div>
              ))}
          </div>
        </div>
      </Popover>
      <BlockedProductsPopup
        open={productsMap[productKey]?.title !== undefined}
        image={productsMap[productKey]?.image}
        productName={productsMap[productKey]?.productName}
        title={productsMap[productKey]?.title}
        subtitle={productsMap[productKey]?.subtitle}
        bullets={productsMap[productKey]?.bullets || []}
        learnMoreUrl={productsMap[productKey]?.learnMoreUrl}
        handleCloseClick={() => setProductKey(null)}
      />
    </div>
  );
};

export default DropdownTabs;
