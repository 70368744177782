import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MTextField from "../../../../../components/MTextField";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    width: "100%",
    height: 37,
    padding: "0px 10px",
    margin: 0,
    justifyContent: "center",
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    "&:focus": {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
  },
  textInput: {
    padding: 0,
  },
  listTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    margin: "10px 0px",
  },
  itemWrapper: {
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  itemLabel: {
    marginRight: 10,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    color: theme.palette.text.primary,
  },
  clearIcon: {
    cursor: "pointer",
    fontSize: 18,
    marginLeft: 10,
  },
}));

type PropTypes = {
  label: string;
  values: any[];
  item: any;
  handleOnChange: (val: any) => void;
};

const ACFTextList = (props: PropTypes) => {
  const classes = useStyles(props);
  const { label, values = [], handleOnChange, item } = props;

  return (
    <div className={classes.wrapper}>
      {label && <Typography className={classes.listTitle}>{label}</Typography>}
      {[...values, item.key ? { [item.key]: "" } : ""].map((value, index) => (
        <div className={classes.itemWrapper} key={`${label}_${index + 1}`}>
          <Typography className={classes.itemLabel}>{`${index + 1}`}</Typography>
          <MTextField
            inputClassName={classes.textInput}
            customClass={classes.textField}
            onChange={(value) => {
              if (values.length === index) {
                const val = item.key ? { [item.key]: value } : value;
                handleOnChange([...values, val]);
                return;
              }
              const newValues = [...values];
              newValues[index] = item.key ? { [item.key]: value } : value;
              handleOnChange(newValues);
            }}
            value={item.key ? value[item.key] : value}
            placeholder={""}
          />
          <ClearIcon
            style={{ visibility: values.length !== index ? "visible" : "hidden" }}
            className={classes.clearIcon}
            onClick={() => {
              if (values.length !== index) {
                const newValues = [...values];
                newValues.splice(index, 1);
                handleOnChange(newValues);
              }
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default ACFTextList;
