import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { useLocation, useRouteMatch } from "react-router-dom";
import { web } from "../../../helpers/urlHelper";
import theme from "../../../themes/theme";
import MTabs from "../../../components/MTabs";
import { IWebsite, eVideoProjectState } from "../../../reducers/constants/objectTypes";
import { AbilityContext } from "../../../casl/can";
import {
  getCommunityTabs,
  getCampaignsTabs,
  getLegacyTabs,
  getPageBuilderTabs,
  getSeoTabs,
  getStrategyTabs,
  getWorkFlowTabs,
  getVideoTabs,
  getAnalyticsTabs,
} from "../../../helpers/tabHelper";

const useStyles = makeStyles((theme: Theme) => ({
  tabsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginLeft: 0,
  },
  tabsRoot: {
    minHeight: 40,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight as any,
    paddingRight: 0,
    paddingLeft: 0,
    "& p": {
      textTransform: "lowercase",
      "&:first-letter": {
        textTransform: "uppercase",
      },
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};

const PagesTabs = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const match = useRouteMatch(`${web.homePage()}/:page`);
  const ability = React.useContext<any>(AbilityContext);

  const tabs = React.useMemo(() => {
    const workFlowTabs = getWorkFlowTabs(ability, selectedWebsite);
    // console.log('PagesTabs', match);
    if (!match?.params?.page) {
      return [];
    }
    console.log("match.params.page", match.params.page);
    switch (match.params.page) {
      case "testVideoEditor": {
        return [];
      }
      case "view": {
        // customer
        const tabs = [
          {
            url: web.customerPosts(),
            label: I18n.t("customer.tabs.published"),
          },
        ];

        if (selectedWebsite.reviewProcess === "Fact-Check") {
          tabs.unshift({
            url: web.customerReview(),
            label: I18n.t("customer.tabs.review"),
          });
        }
        return tabs;
      }
      case "cms":
        return workFlowTabs;
      case "hr":
        return [
          {
            url: web.hrRolesAndPermissions(),
            label: I18n.t("owner.tabs.roles_and_permission"),
          },
        ];
      case "community":
        return getCommunityTabs(ability);
      case "writer":
        return [
          {
            url: web.writerWrite(),
            label: I18n.t("writer.tabs.write"),
          },
          {
            url: web.writerPublished(),
            label: I18n.t("writer.tabs.published"),
          },
        ];
      case "editor":
        return [
          {
            url: web.workflowOutlines(),
            label: I18n.t("editor.tabs.outline_review"),
          },
          {
            url: web.editorReview(),
            label: I18n.t("editor.tabs.review"),
          },
          {
            url: web.editorCommunity(),
            label: I18n.t("editor.tabs.community"),
          },
          {
            url: web.editorInProgress(),
            label: I18n.t("editor.tabs.in_progress"),
          },
          {
            url: web.editorPublished(),
            label: I18n.t("editor.tabs.published"),
          },
        ];
      case "seo":
        return getSeoTabs(ability);
      case "video":
        return getVideoTabs(ability);
      case "strategy":
        return getStrategyTabs(ability);
      case "campaigns":
        return getCampaignsTabs(ability);
      case "cro":
        return getAnalyticsTabs(ability);

      // case "widgets-builder":
      //   return [
      //     {
      //       url: web.widgetsBuilder("widgets"),
      //       label: I18n.t("widgets_builder.tabs.widgets"),
      //     },
      //   ];
      case "marketing":
        return [
          // {
          //   url: web.entailAudiences(),
          //   label: I18n.t("entail.tabs.audiences"),
          // },
          {
            url: web.entailExperts(),
            label: I18n.t("entail.tabs.experts"),
          },
        ];
      case "page-builder":
        return getPageBuilderTabs(ability);
      case "post":
      case "edit":
      case "video-test":
        return [];
      case "legacy":
        return getLegacyTabs(ability, selectedWebsite);
      default:
        console.log(`Unhandled inner page ${match.params.page}`);
        return [];
      // throw new Error(`Unhandled inner page ${match.params.page}`);
    }
  }, [match]);

  return (
    <MTabs
      tabsWrapperClass={classes.tabsWrapper}
      activeColor={theme.palette.primary.main}
      tabsRootClass={classes.tabsRoot}
      tabs={tabs}
    />
  );
};

export default PagesTabs;
