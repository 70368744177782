//https://stackoverflow.com/questions/69357804/intil-displaynames-undefined-in-safari-version-14-1
let languageNames = null;

try {
  languageNames = new Intl.DisplayNames(["en"], { type: "language" });
} catch (e) {}

let regionNames = null;
try {
  regionNames = new Intl.DisplayNames(["en"], { type: "region", fallback: "none" });
} catch (e) {}

const getLanguageFromLocaleCode = (locale) => {
  try {
    return languageNames.of(locale);
  } catch (e) {
    return locale;
  }
};

const getCountryFromCountryCode = (countryCode) => {
  try {
    return regionNames.of(countryCode);
  } catch (e) {
    return countryCode;
  }
};

const getCurrencyText = (value: number, currency: string) => {
  return new Intl.NumberFormat("en", {
    style: "currency",
    currency,
    maximumSignificantDigits: Math.trunc(Math.abs(value)).toFixed().length,
  }).format(value);
};

export default {
  getLanguageFromLocaleCode,
  getCountryFromCountryCode,
  getCurrencyText,
};
