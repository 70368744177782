import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import { KEYWORD_GROUPS } from "../../reducers/constants/actionTypes";
import { IKeywordGroup, IWebsite } from "../../reducers/constants/objectTypes";
import agent from "../../agent";
import KeywordGroupDialog from "./components/KeywordGroupDialog/KeywordGroupDialog";
import MTableWrapper from "../../components/Table/MTableWrapper";
import MTableToolbar from "../../components/Table/Toolbar/MTableToolbar";
import KeywordGroupItemList from "./components/KeywordGroupItemList";
import MTablePagination from "../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  websites: state.home.websites,
  websitesLoaded: state.home.websitesLoaded,
  selectedWebsite: state.home.selectedWebsite,

  keywordGroupsLoaded: state.keywordGroups.keywordGroupsLoaded,
  keywordGroups: state.keywordGroups.keywordGroups,

  keywordGroupsLimit: state.keywordGroups.keywordGroupsLimit,
  keywordGroupsTotal: state.keywordGroups.keywordGroupsTotal,
  searchText: state.keywordGroups.filter.searchText,
  sortField: state.keywordGroups.filter.sortField,
});

const mapDispatchToProps = (dispatch) => ({
  keywordGroupsStartLoading: () => dispatch({ type: KEYWORD_GROUPS.KEYWORD_GROUPS_START_LOADING }),
  handleKeywordGroupsLoaded: (payload) => dispatch({ type: KEYWORD_GROUPS.KEYWORD_GROUPS_LOADED, payload }),
  loadKeywordGroup: (keywordGroup) => dispatch({ type: KEYWORD_GROUPS.KEYWORD_GROUP_LOADED, keywordGroup }),
  handleCheckedBoxChanged: (checkedKeywordGroupsList) =>
    dispatch({ type: KEYWORD_GROUPS.ON_CHECKED_BOX_CHANGED, checkedKeywordGroupsList }),
  onFilterChanged: (payload) => dispatch({ type: KEYWORD_GROUPS.KEYWORD_GROUP_ON_FILTER_CHANGED, payload }),
});

type PropTypes = {
  websites: IWebsite[];
  selectedWebsite: IWebsite;

  keywordGroupsLoaded: boolean;
  keywordGroups: IKeywordGroup[];

  keywordGroupsLimit: number;
  keywordGroupsTotal: number;
  searchText: string;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  handleKeywordGroupsLoaded: (payload) => void;
  loadKeywordGroup: (keywordGroup) => void;
  handleCheckedBoxChanged: (checkedKeywordGroupsList) => void;
  keywordGroupsStartLoading: () => void;
  onFilterChanged: (payload) => void;
};

const KeywordGroups = (props: PropTypes) => {
  const [openDialog, setOpen] = useState<{ open: boolean; pageTab: boolean }>({
    open: false,
    pageTab: true,
  });
  const { enqueueSnackbar } = useSnackbar();

  const {
    keywordGroupsStartLoading,
    handleKeywordGroupsLoaded,
    keywordGroups,
    keywordGroupsTotal,
    keywordGroupsLoaded,
    searchText,
    sortField,
    loadKeywordGroup,
    selectedWebsite,
    onFilterChanged,
  } = props;
  const [paginationNumber, setPaginationNumber] = React.useState<number>(100);
  const [page, setPage] = React.useState(1);

  const setPageTab = (pageTab: boolean) => {
    setOpen({
      open: true,
      pageTab,
    });
  };

  useEffect(() => {
    if (selectedWebsite) {
      keywordGroupsStartLoading();
      /// check if sort field is ={} or not

      const sort = sortField?.field ? `${sortField.field}.${sortField.direction || "asc"}` : null;

      agent.KeywordGroups.getKeywordGroups(selectedWebsite._id, paginationNumber, searchText, page, sort)
        .then((res) => {
          handleKeywordGroupsLoaded(res);
        })
        .catch((error) => {
          enqueueSnackbar(error.response?.body?.message || "Bad Request:");
        });
    }
  }, [paginationNumber, page, searchText, selectedWebsite, sortField]);

  const handlePaginationNumberChanged = (number) => {
    setPaginationNumber(number);
  };

  const openKeywordDialog = (kg: IKeywordGroup, pageTab) => {
    agent.KeywordGroups.getKeywordGroup(selectedWebsite._id, kg.clicks_main_keyword).then((res) => {
      loadKeywordGroup(res.data);

      setOpen({
        pageTab,
        open: true,
      });
    });
  };

  const closeOpportunityDialog = () => {
    loadKeywordGroup(null);

    setOpen({
      pageTab: true,
      open: false,
    });
  };

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t("meta_tags.strategy.groups.title")} />
      <MTableToolbar
        searchText={searchText}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
      />
      <KeywordGroupItemList
        sortField={sortField}
        setSortField={(value) => onFilterChanged({ sortField: value })}
        openKeywordDialog={openKeywordDialog}
      />

      {keywordGroupsLoaded && keywordGroups?.length > 0 && (
        <MTablePagination
          paginationLimit={paginationNumber}
          page={page}
          paginationPagesCount={Math.ceil(keywordGroupsTotal / paginationNumber)}
          setPaginationLimit={handlePaginationNumberChanged}
          setPage={setPage}
        />
      )}
      <KeywordGroupDialog
        open={openDialog.open}
        close={closeOpportunityDialog}
        pageTab={openDialog.pageTab}
        setPageTab={setPageTab}
      />
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordGroups);
