import React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import {
  IWidgetFieldProperties,
  IWidgetHubspotFormFieldProps,
} from "../../../../../../../reducers/constants/objectTypes";
import GenericValueItemProperty from "./components/GenericValueItemProperty";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

type PropTypes = {
  properties: IWidgetFieldProperties;
  fieldType: string;
  onChange: (properties: IWidgetHubspotFormFieldProps) => void;
};

const HubspotFormWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { onChange } = props;
  const properties = props.properties as IWidgetHubspotFormFieldProps;
  const { formId, portalId, region } = properties;

  return (
    <div className={classes.root}>
      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, region: value })}
        value={region}
        label={I18n.t("widgets_builder.widget_editor.fields.labels.region")}
      />

      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, portalId: value })}
        value={portalId}
        label={I18n.t("widgets_builder.widget_editor.fields.labels.portalId")}
      />

      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, formId: value })}
        value={formId}
        label={I18n.t("widgets_builder.widget_editor.fields.labels.formId")}
      />
    </div>
  );
};

export default HubspotFormWidgetItem;
