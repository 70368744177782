import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: 10,
    height: 11,
    fill: "#333333",
  },
}));

type PropTypes = {
  className?: string;
  onClick?: Function;
};

function AddIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className, onClick = null } = props;
  return (
    <SvgIcon
      onClick={(e) => onClick && onClick(e)}
      className={ClassNames(classes.svg, className)}
      viewBox={"0 0 10 11"}
    >
      <rect y="6.5" width="2" height="10" rx="1" transform="rotate(-90 0 6.5)" />
      <rect x="6" y="10.5" width="2" height="10" rx="1" transform="rotate(180 6 10.5)" />
    </SvgIcon>
  );
}

export default AddIcon;
