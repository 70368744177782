import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import agent from "../../../agent";
import { COMMUNITY } from "../../../reducers/constants/actionTypes";
import { IAdminUser, ICategory, IQuestion, IWebsite } from "../../../reducers/constants/objectTypes";
import QuestionsModeratorItemList from "./components/QuestionsModeratorItemList";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarCategoriesFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarCategoriesFilter";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  admin: state.home.admin,
  categories: state.category.categories,
  refreshQuestions: state.community.refresh,
  totalPages: state.community.moderatorPages,
  questions: state.community.moderatorQuestions,
  answers: state.community.moderatorAnswers,
  questionsLoaded: state.community.moderatorQuestionsLoaded,
  category: state.community.moderatorQuestionsFilter.category,
  searchText: state.community.moderatorQuestionsFilter.searchText,
  sortField: state.community.moderatorQuestionsFilter.sortField,
});

const mapDispatchToProps = (dispatch) => ({
  loadQuestions: (payload) => dispatch({ type: COMMUNITY.MODERATOR_QUESTIONS_LOADED, payload }),
  onFilterChanged: (payload) => dispatch({ type: COMMUNITY.MODERATOR_QUESTIONS_ON_FILTER_CHANGED, payload }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  admin: IAdminUser;
  loadQuestions: (payload) => void;
  refreshQuestions: boolean;
  categories: ICategory[];
  totalPages: number;
  questions: IQuestion[];
  answers: IQuestion[];
  category: ICategory[];
  searchText: string;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  questionsLoaded: boolean;

  onFilterChanged: (payload) => void;
};

const Moderator = (props: PropTypes) => {
  const {
    selectedWebsite,
    admin,
    loadQuestions,
    refreshQuestions,
    totalPages,
    categories,
    questionsLoaded,
    questions,
    answers,
    searchText,
    sortField,
    category,
    onFilterChanged,
  } = props;
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);

  useEffect(() => {
    const filterCategories = category.map((c) => c._id).join(",");
    loadQuestions(
      agent.Community.getModeratorQuestions(
        selectedWebsite._id,
        (page - 1).toString(),
        pageLimit,
        filterCategories,
        searchText,
        sortField.field,
        sortField.direction
      )
        .then((res) => res)
        .catch((e) => {})
    );
  }, [selectedWebsite, admin, category, searchText, refreshQuestions, page, pageLimit, sortField]);
  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.community.moderator.title`)} />
      <MTableToolbar
        searchText={searchText}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
      >
        <MTableToolbarCategoriesFilter
          handleCategoriesSelected={(value) => onFilterChanged({ category: value })}
          selectedCategories={category}
          categories={categories}
        />
      </MTableToolbar>
      <QuestionsModeratorItemList
        sortField={sortField}
        setSortField={(value) => onFilterChanged({ sortField: value })}
        answers={answers}
        questions={questions}
      />

      {questionsLoaded && (questions.length > 0 || answers.length > 0) && (
        <MTablePagination
          paginationLimit={pageLimit}
          page={page}
          paginationPagesCount={totalPages}
          setPaginationLimit={setPageLimit}
          setPage={setPage}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Moderator);
