import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { Close } from "@material-ui/icons";
import ClassNames from "classnames";

import {
  IWidgetFieldProperties,
  IWidgetKeyDecisionFactorsFieldProps,
} from "../../../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../../../components/TextFieldMaxLength";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  item: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    gap: 15,
  },
  itemNo: {
    marginTop: 12,
    fontSize: 14,
    fontWeight: typography.fontWeightMedium as any,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: palette.text.primary,
    minWidth: 8,
  },
  inputsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    width: "100%",
  },
  input: {
    border: "none",
    width: "100%",
    overflow: "hidden",
    padding: 15,
    color: palette.text.primary,
    backgroundColor: palette.secondary.dark,
    fontWeight: typography.fontWeightRegular as any,
    fontSize: 14,
    borderRadius: 3,
  },
  removeIcon: {
    marginTop: 15,
    height: 20,
    width: 20,
    color: palette.text.primary,
    visibility: "hidden",
    "&:hover": {
      cursor: "pointer",
    },
  },
  showRemoveIcon: {
    visibility: "visible",
  },
}));

type PropTypes = {
  properties?: IWidgetFieldProperties;
  onChange: (properties: IWidgetKeyDecisionFactorsFieldProps) => void;
};

const KeyDecisionFactorsWidgetItem = (props: PropTypes) => {
  const classes = useStyles();

  const { onChange } = props;
  const { list = [] } = props.properties as IWidgetKeyDecisionFactorsFieldProps;

  const handleChange = ({
    item,
    index,
  }: {
    item?: IWidgetKeyDecisionFactorsFieldProps["list"][number];
    index: number;
  }) => {
    let updatedValues = [...list];

    if (!item?.description && !item?.title) {
      updatedValues = updatedValues.filter((_, arrIndex: number) => arrIndex !== index);
    } else {
      updatedValues =
        index === updatedValues.length
          ? [...updatedValues, item]
          : updatedValues.map((arrEl, arrIndex: number) => (arrIndex === index ? item : arrEl));
    }

    onChange({ ...props.properties, list: updatedValues });
  };

  return (
    <div className={classes.root}>
      {[...list, { title: "", description: "" }].map(({ title, description }, index, array) => (
        <div className={classes.item} key={index}>
          <Typography className={classes.itemNo} variant="body1">
            {index + 1}
          </Typography>
          <div className={classes.inputsWrapper}>
            <TextFieldMaxLength
              handleValueChange={(newValue: string) => handleChange({ item: { title: newValue, description }, index })}
              value={title}
              placeholder={I18n.t("widgets_builder.widget_editor.fields.placeholders.write_title")}
              inputClassName={classes.input}
              outlined={false}
            />
            <TextFieldMaxLength
              handleValueChange={(newValue: string) => handleChange({ item: { title, description: newValue }, index })}
              value={description}
              placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
              inputClassName={classes.input}
              outlined={false}
            />
          </div>
          <Close
            className={ClassNames(
              classes.removeIcon,
              array.length > 1 && index !== array.length - 1 && classes.showRemoveIcon
            )}
            onClick={() => handleChange({ item: null, index })}
          />
        </div>
      ))}
    </div>
  );
};

export default KeyDecisionFactorsWidgetItem;
