import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { ClickAwayListener, Tooltip } from "@material-ui/core";
import { ColorPicker } from "react-color-gradient-picker";
import "react-color-gradient-picker/dist/index.css";
import LanguageIcon from "@material-ui/icons/Language";
import Divider from "@material-ui/core/Divider";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
const useStyles = makeStyles((theme: Theme) => ({
  colorWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: 32,
    padding: "0 0 0 5px",
    width: 180,
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: (props: PropTypes) => props.borderRadius || 2,
  },
  backgroundWrapper: {
    flex: 1,
    height: 32,
  },
  divider: {
    height: 20,
    alignSelf: "unset",
    marginRight: 5,
    marginLeft: 5,
  },
  icon: {
    fontSize: 21,
    color: theme.palette.divider,
    cursor: "pointer",
  },
  defaultIcon: {
    color: theme.palette.primary.main,
  },
  buttonAndColorPickerWrap: {
    position: "relative",
    border: `${theme.palette.divider} 1px `,
    borderRadius: (props: PropTypes) => props.borderRadius || 2,
  },
  colorPickerWrap: {
    top: (props: PropTypes) => props.top || 0,
    left: (props: PropTypes) => props.left || 0,
    zIndex: 1,
    position: "absolute",
  },
}));

type PropTypes = {
  gradient: any;
  top?: string;
  left?: string;
  borderRadius?: number | string;
  defaultColor: string;
  handleChange: Function;
};

const GradientColorPickerBaseComponent = (props: PropTypes) => {
  const classes = useStyles(props);
  const { gradient, handleChange, defaultColor } = props;
  const [open, setOpen] = React.useState(false);
  const hex2rgba = (hexa: string, opacity = 1): string[] => {
    if (!hexa?.includes("#")) {
      hexa = `#${hexa}`;
    }
    if (opacity > 1) {
      opacity /= 100;
    }
    if (opacity > 1) {
      opacity = 1;
    }
    let c: string[] = hexa.substring(1).split("");

    if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hexa)) {
      return undefined;
    }

    switch (c.length) {
      case 3:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], "ff"];
        break;
      case 4:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
        break;
      case 6:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], "ff"];
        break;
      case 8:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
        break;
      default:
        break;
    }

    c = c.map((char) => parseInt(char, 16).toString());
    c[3] = opacity.toString();
    return c;
  };
  const rgb = hex2rgba(defaultColor);
  const defaultGradient = {
    points: [
      {
        left: 0,
        red: rgb?.[0] || 0,
        green: rgb?.[1] || 0,
        blue: rgb?.[2] || 0,
        alpha: rgb?.[3] || 1,
      },
      {
        left: 100,
        red: rgb?.[0] || 255,
        green: rgb?.[1] || 0,
        blue: rgb?.[2] || 0,
        alpha: rgb?.[3] || 1,
      },
    ],
    degree: 0,
    type: "linear",
    style: defaultColor,
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const [gradientAttrs, setGradientAttrs] = React.useState(gradient || defaultGradient);
  const onChange = (gradientAtt) => {
    setGradientAttrs(gradientAtt);
    handleChange(gradientAtt);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.buttonAndColorPickerWrap}>
        <div className={classes.colorWrapper}>
          <Tooltip title={I18n.t("rich_text_editor.reset_default_color")}>
            <LanguageIcon
              onClick={() => {
                if (defaultGradient.style !== gradient?.style) {
                  onChange(defaultGradient);
                }
              }}
              className={ClassNames(classes.icon, defaultGradient.style === gradient?.style && classes.defaultIcon)}
            />
          </Tooltip>
          <Divider className={classes.divider} orientation={"vertical"} />
          <div
            className={classes.backgroundWrapper}
            onClick={handleClick}
            style={{ background: gradientAttrs.style }}
          ></div>
        </div>

        {open ? (
          <div className={classes.colorPickerWrap}>
            <ColorPicker
              gradient={gradientAttrs}
              isGradient
              onStartChange={onChange}
              onChange={onChange}
              onEndChange={onChange}
            />
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default GradientColorPickerBaseComponent;
