import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { WIDGET_RULE_EDITOR } from "../../../../../../../reducers/constants/actionTypes";
import RuleBuilderMain from "./components/RuleBuilderMain";
import RuleBuilderPageSelect from "./components/RuleBuilderPageSelect";
import RuleBuilderWidgetContainerSettings from "./components/RuleBuilderWidgetContainerSettings/RuleBuilderWidgetContainerSettings";
import {
  WidgetRuleEditorRootState,
  WidgetRuleEditorSelectedView,
} from "../../../../../../../reducers/widgetRuleEditorReducer";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
    flex: 1,
    overflowY: "auto !important" as any,
    padding: 20,
  },
}));

const mapStateToProps = (state) => ({
  selectedView: state.widgetRuleEditor.selectedView,
  selectedWidgetContainerId: state.widgetRuleEditor.selectedWidgetContainerId,
});

const mapDispatchToProps = (dispatch) => ({
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PROPERTY_CHANGE, property });
  },
});

type PropTypes = {
  selectedView: WidgetRuleEditorRootState["selectedView"];
  selectedWidgetContainerId: WidgetRuleEditorRootState["selectedWidgetContainerId"];
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => void;
};

const CampaignBuilder = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedView, selectedWidgetContainerId } = props;

  return (
    <div className={classes.wrapper}>
      {selectedView?.startsWith(WidgetRuleEditorSelectedView.WIDGET_CONTAINER_SETTINGS) && selectedWidgetContainerId ? (
        <RuleBuilderWidgetContainerSettings />
      ) : selectedView === WidgetRuleEditorSelectedView.PAGE_SELECT ? (
        <RuleBuilderPageSelect />
      ) : (
        <RuleBuilderMain />
      )}
      <div></div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignBuilder);
