import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import Typography from "@material-ui/core/Typography";
import { Collapse } from "@material-ui/core";
import { find } from "lodash";
import { useMemo } from "react";
import {
  ePageState,
  ICategory,
  IPage,
  IPageCluster,
  IProduct,
  IWebsite,
} from "../../../reducers/constants/objectTypes";
import { PAGE_EDITOR } from "../../../reducers/constants/actionTypes";
import { AbilityContext } from "../../../casl/can";
import PageEditorPublishedPostUrl from "./PageEditorPublishedPostUrl";
import PageEditorWriter from "./PageEditorWriter";
import PageEditorReviewer from "./PageEditorReviewer";
import PageEditorTags from "./PageEditorTags";
import PageEditorFeatured from "./PageEditorFeatured";
import PageEditorUploadStatus from "./PageEditorUploadStatus";
import PageEditorCategory from "./PageEditorCategory";
import PageEditorProducts from "./PageEditorProducts";
import PageEditorFollow from "./PageEditorFollow";
import PageEditorIndex from "./PageEditorIndex";
import PageEditorNIVertical from "./PageEditorNIVertical";
import PageEditorCustomReviewStatus from "./PageEditorCustomReviewStatus";
import PageEditorBest from "./PageEditorBest";
import PageEditorProduct from "./PageEditorProduct";
import PageEditorAutoSaveSwitch from "./PageEditorAutoSaveSwitch";
import PageEditorShowCoverImage from "./PageEditorShowCoverImage";

const useStyles = makeStyles((theme: Theme) => ({
  resources: {
    height: "100%",
  },
  wrapper: {
    height: "100%",
    flex: 1,
    padding: "0 20px",
  },
  resourcesTab: {
    padding: 20,
    paddingTop: 10,
  },
  writerWrapper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "pre",
  },
  writerTitle: {
    marginRight: 20,
  },
  writerName: {
    paddingLeft: 20,
  },
  tagsWrapper: {
    marginTop: 50,
  },
  topicWrapper: {
    marginTop: 20,
  },
  section: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 0",
    borderTop: `solid 1px ${theme.palette.divider}`,
    "&:first-child": {
      borderTop: "none",
    },
    "&:last-child": {
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    width: "100%",
  },
  expandIcon: {
    marginRight: 7,
    fontSize: theme.typography.pxToRem(20),
  },
  sectionNameWrap: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  sectionName: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  subTabWrapper: {
    "& .MuiCollapse-wrapper": {
      padding: "10px 15px",
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  pageLoaded: boolean;
  page: IPage;
  cluster: IPageCluster;

  handlePageChange: (page: IPage) => void;
  handleClusterChange: (cluster: IPageCluster) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  pageLoaded: state.pageEditor.pageLoaded,
  page: state.pageEditor.page,
  cluster: state.pageEditor.pageCluster,
});

const mapDispatchToProps = (dispatch) => ({
  handlePageChange: (page) => dispatch({ type: PAGE_EDITOR.PAGE_CHANGED, page }),
  handleClusterChange: (cluster) => dispatch({ type: PAGE_EDITOR.CLUSTER_UPDATED, cluster }),
});

const PageEditorInfo = (props: PropTypes) => {
  const classes = useStyles(props);
  const { pageLoaded, page, cluster, selectedWebsite } = props;
  const { handlePageChange, handleClusterChange } = props;
  const ability = React.useContext<any>(AbilityContext);
  const [statusOpen, setStatusOpen] = React.useState<boolean>(false);
  const [configurationsOpen, setConfigurationsOpen] = React.useState<boolean>(false);
  const [contributorsOpen, setContributorsOpen] = React.useState<boolean>(false);
  const [categoryOpen, setCategoryOpen] = React.useState<boolean>(false);
  const [productsOpen, setProductsOpen] = React.useState<boolean>(false);
  const [topicsOpen, setTopicsOpen] = React.useState<boolean>(false);

  const publishedPage = useMemo(() => {
    if (page?.optimize && cluster?.publishedPages?.[page.variant]?.length > 0) {
      return find(cluster.publishedPages[page.variant], { locale: page.locale })?.page;
    }
    return undefined;
  }, [page, cluster]);

  if (!pageLoaded || (!ability.can("writer", "role") && !ability.can("customer", "role"))) {
    return <div className={classes.resources} />;
  }

  return (
    <div className={classes.wrapper}>
      {page?.currentState.state !== ePageState.published && <PageEditorAutoSaveSwitch />}
      <PageEditorPublishedPostUrl page={publishedPage || page} selectedWebsite={selectedWebsite} />

      {page?.currentState.state === ePageState.published ||
      cluster?.publishHistory.length > 0 ||
      selectedWebsite.reviewProcess === "Custom" ? (
        <>
          <section className={classes.section}>
            <div className={classes.sectionNameWrap} onClick={() => setStatusOpen(!statusOpen)}>
              {statusOpen ? (
                <ExpandMoreRoundedIcon className={classes.expandIcon} />
              ) : (
                <NavigateNextRoundedIcon className={classes.expandIcon} />
              )}
              <Typography className={classes.sectionName} variant="body1">
                {I18n.t("rich_text_editor.sub_tabs.status")}
              </Typography>
            </div>
            <Collapse in={statusOpen}>
              <PageEditorUploadStatus cluster={cluster} page={page} />
              <PageEditorCustomReviewStatus website={selectedWebsite} page={page} />
            </Collapse>
          </section>
        </>
      ) : null}
      <section className={classes.section}>
        <div className={classes.sectionNameWrap} onClick={() => setConfigurationsOpen(!configurationsOpen)}>
          {configurationsOpen ? (
            <ExpandMoreRoundedIcon className={classes.expandIcon} />
          ) : (
            <NavigateNextRoundedIcon className={classes.expandIcon} />
          )}
          <Typography className={classes.sectionName} variant="body1">
            {I18n.t("rich_text_editor.sub_tabs.configurations")}
          </Typography>
        </div>
        <Collapse in={configurationsOpen} className={classes.subTabWrapper}>
          {/* todo: hack to refresh page publish status */}
          {["niArticle", "niProductReview"].includes(page.type) && (
            <PageEditorNIVertical
              showLabel
              cluster={cluster}
              selectedContext={page.customFields?.context || ""}
              handleClusterChange={handleClusterChange}
              onContextSelected={(context) =>
                handlePageChange({ ...page, customFields: { ...page.customFields, context } })
              }
            />
          )}

          {!["niArticle", "wordpress", "niProductReview"].includes(page.type) && (
            <>
              <PageEditorFeatured
                featuredPost={page.featured}
                handleFeaturedChange={(featured) => handlePageChange({ ...page, featured })}
              />
              <PageEditorBest bestPost={page.best} handleBestChange={(best) => handlePageChange({ ...page, best })} />
              <PageEditorShowCoverImage
                showCoverImage={page.showCoverImage}
                handleShowCoverImageChange={(showCoverImage) => handlePageChange({ ...page, showCoverImage })}
              />
            </>
          )}
          <PageEditorIndex index={page.index} onIndexSelected={(index) => handlePageChange({ ...page, index })} />
          {/*<PageEditorFollow follow={page.follow} onFollowSelected={(follow) => handlePageChange({ ...page, follow })} />*/}
        </Collapse>
      </section>
      <section className={classes.section}>
        <div className={classes.sectionNameWrap} onClick={() => setContributorsOpen(!contributorsOpen)}>
          {contributorsOpen ? (
            <ExpandMoreRoundedIcon className={classes.expandIcon} />
          ) : (
            <NavigateNextRoundedIcon className={classes.expandIcon} />
          )}
          <Typography className={classes.sectionName} variant="body1">
            {I18n.t("rich_text_editor.sub_tabs.contributors")}
          </Typography>
        </div>
        <Collapse in={contributorsOpen} className={classes.subTabWrapper}>
          {(ability.can("editor", "role") ||
            (selectedWebsite.reviewProcess === "Fact-Check" && ability.can("user_role", "customer"))) && (
            <PageEditorWriter
              canEdit={ability.can("editor", "role")}
              selectedWebsite={selectedWebsite}
              writer={page.writer}
              postType={page.type}
              locale={page.locale || page.website.defaultLocale}
              handleWriterSelect={(writer) => {
                handlePageChange({ ...page, writer });
              }}
            />
          )}

          {(ability.can("editor", "role") ||
            (selectedWebsite.reviewProcess === "Fact-Check" && ability.can("user_role", "customer"))) && (
            <PageEditorWriter
              canEdit={ability.can("editor", "role")}
              creditedWriter
              selectedWebsite={selectedWebsite}
              writer={page.creditedWriter || page.writer}
              postType={page.type}
              locale={page.locale || page.website.defaultLocale}
              handleWriterSelect={(writer) => {
                handlePageChange({ ...page, creditedWriter: writer });
              }}
            />
          )}

          {ability.can("content_manager", "role") && (
            <PageEditorReviewer
              selectedWebsite={selectedWebsite}
              editor={page.editor}
              postType={page.type}
              locale={page.locale || page.website.defaultLocale}
              handleEditorSelect={(editor) => {
                handlePageChange({ ...page, editor });
              }}
            />
          )}

          {ability.can("content_manager", "role") && selectedWebsite.reviewProcess === "Fact-Check" && (
            <PageEditorReviewer
              selectedWebsite={selectedWebsite}
              editor={page.factChecker}
              factChecker
              postType={page.type}
              locale={page.locale || page.website.defaultLocale}
              handleEditorSelect={(factChecker) => {
                handlePageChange({ ...page, factChecker });
              }}
            />
          )}
        </Collapse>
      </section>

      <section className={classes.section}>
        <div className={classes.sectionNameWrap} onClick={() => setCategoryOpen(!categoryOpen)}>
          {categoryOpen ? (
            <ExpandMoreRoundedIcon className={classes.expandIcon} />
          ) : (
            <NavigateNextRoundedIcon className={classes.expandIcon} />
          )}
          <Typography className={classes.sectionName} variant="body1">
            {I18n.t("rich_text_editor.sub_tabs.category")}
          </Typography>
        </div>
        <Collapse in={categoryOpen} className={classes.subTabWrapper}>
          <PageEditorCategory
            showLabel
            selectedCategory={page.categories[0] || null}
            onCategorySelected={(category: ICategory) => handlePageChange({ ...page, categories: [category] })}
          />
        </Collapse>
      </section>

      <section className={classes.section}>
        <div className={classes.sectionNameWrap} onClick={() => setProductsOpen(!productsOpen)}>
          {productsOpen ? (
            <ExpandMoreRoundedIcon className={classes.expandIcon} />
          ) : (
            <NavigateNextRoundedIcon className={classes.expandIcon} />
          )}
          <Typography className={classes.sectionName} variant="body1">
            {I18n.t("rich_text_editor.sub_tabs.products")}
          </Typography>
        </div>
        <Collapse in={productsOpen} className={classes.subTabWrapper}>
          {["niProductReview"].includes(page.type) && (
            <PageEditorProduct
              selectedWebsite={selectedWebsite}
              product={page?.products[0] || null}
              onProductChange={(product: IProduct) => handlePageChange({ ...page, products: [product] })}
            />
          )}
          {!["niArticle", "niProductReview"].includes(page.type) && (
            <PageEditorProducts
              selectedWebsite={selectedWebsite}
              products={page.products || []}
              handleProductsChange={(products) => handlePageChange({ ...page, products })}
            />
          )}
        </Collapse>
      </section>

      <section className={classes.section}>
        <div className={classes.sectionNameWrap} onClick={() => setTopicsOpen(!topicsOpen)}>
          {topicsOpen ? (
            <ExpandMoreRoundedIcon className={classes.expandIcon} />
          ) : (
            <NavigateNextRoundedIcon className={classes.expandIcon} />
          )}
          <Typography className={classes.sectionName} variant="body1">
            {I18n.t("rich_text_editor.sub_tabs.topics")}
          </Typography>
        </div>
        <Collapse in={topicsOpen} className={classes.subTabWrapper}>
          <PageEditorTags
            selectedWebsite={selectedWebsite}
            postTags={page.tags}
            handleTagChange={(tags) => handlePageChange({ ...page, tags })}
          />
        </Collapse>
      </section>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageEditorInfo);
