import React, { useRef, useState } from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { IWidgetSettings } from "../../../../../../../../../reducers/constants/objectTypes";
import InputSlider from "../../../InputSlider";
import SelectProperty from "../../../../build/items/itemProperties/components/SelectProperty";
import CustomPopover from "../../../CustomPopover";
import { colors } from "../../../../../../../../../helpers/constants";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const hover = {
    backgroundColor: colors.widgetOnHoverBackgroundColor,
    cursor: "pointer",
  };

  return {
    root: {
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },
    title: {
      fontSize: 16,
      fontWeight: typography.fontWeightMedium as any,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    fieldContainer: {
      backgroundColor: palette.secondary.dark,
      padding: 15,
      display: "flex",
      borderRadius: 3,
      "& .MuiTypography-root": {
        fontSize: 14,
      },
    },
    positionField: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&:hover": hover,
    },
    positionOption: {
      flexGrow: 1,
      flexBasis: 0,
      minWidth: 0,
      minHeight: 30,
      "&:hover": hover,
    },
    alignmentField: {
      display: "flex",
      justifyContent: "space-between",
    },
    selectProperty: {
      width: "50%",
    },
    alignmentToolbar: {
      display: "grid",
      gridTemplateColumns: `repeat(3, 1fr)`,
      gap: 40,
    },
    positionItem: {
      width: "100%",
      height: 25,
      borderRadius: 3,
      backgroundColor: palette.primary.dark,
      "&:hover": hover,
    },
    selectedPosition: {
      backgroundColor: palette.primary.light,
    },
  };
});

type PropTypes = {
  layout: IWidgetSettings["layout"];
  onChange: (field: Partial<IWidgetSettings["layout"]>) => void;
};

const Content = (props: PropTypes) => {
  const [showAlignmentToolbar, setShowAlignmentToolbar] = useState(false);
  const toolbarRef = useRef(null);
  const classes = useStyles();

  const { onChange } = props;
  const { layout } = props;
  const { type, alignment, padding, spacing } = layout;

  const allAlignmentPositions: IWidgetSettings["layout"]["alignment"][] = [
    "top_left",
    "top",
    "top_right",
    "left",
    "center",
    "right",
    "bottom_left",
    "bottom",
    "bottom_right",
  ];

  return (
    <div className={classes.root}>
      <div
        className={ClassNames(classes.fieldContainer, classes.positionField)}
        onClick={() => type === "pane" && setShowAlignmentToolbar(true)}
        ref={toolbarRef}
      >
        <Typography>Alignment</Typography>

        {type !== "pane" ? (
          <div className={classes.selectProperty}>
            <SelectProperty
              options={["left", "center", "right"]}
              onChange={(option: IWidgetSettings["layout"]["alignment"]) => onChange({ alignment: option })}
              value={alignment}
            />
          </div>
        ) : (
          <Typography>
            {I18n.t(`widgets_builder.widget_editor.Dialog.settings.layout.alignment.${alignment}`)}
          </Typography>
        )}
      </div>
      <CustomPopover
        open={showAlignmentToolbar}
        onClose={() => setShowAlignmentToolbar(false)}
        anchorEl={toolbarRef.current}
        label={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.alignmentLabel")}
      >
        <div className={classes.alignmentToolbar}>
          {allAlignmentPositions.map((position) => (
            <div
              key={position}
              className={ClassNames(
                classes.positionItem,
                position === alignment ? classes.selectedPosition : undefined
              )}
              onClick={() => onChange({ alignment: position })}
              title={I18n.t(`widgets_builder.widget_editor.Dialog.settings.layout.alignment.${position}`)}
            ></div>
          ))}
        </div>
      </CustomPopover>
      <InputSlider
        label={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.padding")}
        max={80}
        value={padding}
        adornmentText="px"
        customClass={classes.fieldContainer}
        onChange={(value) => onChange({ padding: value })}
      />
      <InputSlider
        label={I18n.t("widgets_builder.widget_editor.Dialog.settings.layout.spacing")}
        max={80}
        value={spacing}
        adornmentText="px"
        customClass={classes.fieldContainer}
        onChange={(value) => onChange({ spacing: value })}
      />
    </div>
  );
};

export default Content;
