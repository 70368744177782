import { WEBSITE_PAGES } from "./constants/actionTypes";
import { ePageType, IWebsite } from "./constants/objectTypes";

const initialState = {
  websiteConfigurationsLoaded: false,

  changesLostWarning: false,
  // object
  name: null,
  url: null,
  host: null,
  publisherName: null,
  websiteActive: null,
  websiteUploadContent: null,
  secondEditStep: null,
  reviewProcess: null,
  reviews: [],
  id: null,
  dynamicSectionId: undefined,
  siteDetails: {},
  updatedSections: {},
  locale: {},
  analytics: {},
  sections: null,
  theme: {},
  initialTheme: {}, // used for saving the theme before changes
  initialArticle: {},
  article: {},
  category: {},
  topic: {},
  blog: {},
  forum: {},
  ppcTop10: {},
  homepage: {},
  resourcePage: {},
  learn: {},
  comparisonTopAlternative: {},
  comparisonTop10: {},
  productComparison: {},
  productPage: {},
  productReview: {},
  productAlternative: {},
  glossary: {},
  fields: [],
  customCode: {},
  commonComponents: {},
  dynamicSection: {
    _id: undefined,
    template: ePageType.Blog,
    mainPage: {},
    categoryPage: {},
    postPage: {},
    maypleTemplate: {
      mainPage: {},
    },
    safebooksTemplate: {
      mainPage: {},
    },
    jitTemplate: {
      mainPage: {},
    },
    blogTemplate: "default",
    enabled: false,
  },
  dynamicSectionLoaded: false,
  dynamicSections: [],
  staticPages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WEBSITE_PAGES.ON_WEBSITE_CONFIGURATIONS_LOADING:
      return {
        ...state,
        websiteConfigurationsLoaded: !action.value,
        dynamicSectionLoaded: false,
      };
    case WEBSITE_PAGES.ON_WEBSITE_CONFIGURATIONS_LOADED: {
      const website: IWebsite = action?.payload?.website || action.website;
      return {
        ...state,
        updatedSections: {},
        dynamicSections: website.configurations.dynamicSections,
        dynamicSectionLoaded: true,
        dynamicSection:
          state.dynamicSections.find((section) => section._id === state.dynamicSectionId) ||
          initialState.dynamicSection,
        id: website._id,
        fields: [],
        changesLostWarning: false,
        websiteConfigurationsLoaded: true,
        name: website.name,
        url: website.url,
        publisherName: website.publisherName,
        host: website.host,
        websiteActive: website.active,
        websiteUploadContent: website.uploadContent,
        secondEditStep: website.secondEditStep,
        reviewProcess: website.reviewProcess,
        reviews: website.reviews,
        siteDetails: website.configurations.siteDetails,
        locale: website.configurations.locale,
        analytics: website.configurations.analytics,
        sections: website.configurations.sections,
        theme: website.configurations.theme,
        initialTheme: website.configurations.theme,
        initialArticle: website.configurations.article,
        article: website.configurations.article,
        blog: website.configurations.blog,
        category: website.configurations.category,
        topic: website.configurations.topic,
        forum: website.configurations.forum,
        homepage: website.configurations.homepage,
        learn: website.configurations.learn,
        comparisonTop10: website.configurations.comparisonTop10,
        comparisonTopAlternative: website.configurations.comparisonTopAlternative,
        productComparison: website.configurations.productComparison,
        productPage: website.configurations.productPage,
        productReview: website.configurations.productReview,
        productAlternative: website.configurations.productAlternative,
        glossary: website.configurations.glossary,
        customCode: website.configurations.customCode,
        commonComponents: website.configurations.commonComponents,
        ppcTop10: website.configurations.ppcTop10,
        staticPages: website.configurations.staticPages,
        resourcePage: website.configurations.resourcePage,
        gsc: website.gsc,
      };
    }
    case WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE:
      if (!state.fields.includes("sections")) {
        state.fields.push("sections");
      }

      return {
        ...state,
        changesLostWarning: true,
        fields: state.fields,
        sections: {
          ...state.sections,
          [action.section]: action.enabled,
        },
        updatedSections: {
          ...state.updatedSections,
          fields: state.fields,
          sections: { ...state.sections, [action.section]: action.enabled },
        },
      };
    case WEBSITE_PAGES.ON_WEBSITE_PAGES_DYNAMIC_SECTION_INIT:
      return {
        ...state,
        dynamicSectionId: action.value,
        dynamicSectionLoaded: true,
        dynamicSection:
          state.dynamicSections.find((section) => section._id === action.value) || initialState.dynamicSection,
      };
    case WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED:
      if (!state.fields.includes(action.name)) {
        if (action.name === "dynamicSection") {
          state.fields.push("dynamicSections");
        } else {
          state.fields.push(action.name);
        }
      }
      return {
        ...state,
        fields: state.fields,
        changesLostWarning: true,
        updatedSections: {
          ...state.updatedSections,
          [action.name]: action.value,
          fields: state.fields,
        },
        [action.name]: action.value,
      };

    default:
      return state;
  }
};
