import * as React from "react";
import { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import numeral from "numeral";
import { findLast, startCase, camelCase } from "lodash";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import {
  eActionIcon,
  ePostType,
  eUserRoles,
  IAdminUser,
  IPage,
  IWebsite,
} from "../../../../reducers/constants/objectTypes";

import { clientPages, getSearchConsoleUrl, web } from "../../../../helpers/urlHelper";
import { PUBLISHED_PAGES } from "../../../../reducers/constants/actionTypes";
import { DATE_FORMAT } from "../../../../reducers/constants/consts";
import localeHelper from "../../../../helpers/localeHelper";
import ability from "../../../../casl/ability";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellDate from "../../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MTableCellStatus from "../../../../components/Table/Cells/MTableCellStatus";
import MTableCellNumberProportion from "../../../../components/Table/Cells/MTableCellNumberProportion";
import { copyStringToClipboard } from "../../../../helpers/fbScraperHelper";
import MTableCell from "../../../../components/Table/Cells/MTableCell";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $iconColumn": {
        paddingTop: 1,
      },
      "& $topicWrapper": {
        paddingTop: 1,
        borderBottom: "1px solid transparent",
      },
      "& $actions": {
        display: "flex",
      },
      "& $takenByText": {
        display: "none",
      },
      "& $takeOverButton": {
        display: "flex",
      },
    },
  },
  actions: {},
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  user: IAdminUser;

  post: IPage;
  taken?: boolean;
  rejected?: boolean;
  publicRow?: boolean;
  editPage: (id: string, currentUrl: string) => void;
  refreshPostList: () => void;
  onDueDateUpdate: Function;
  onPostTypeUpdate: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  user: state.home.admin,
});

const mapDispatchToProps = (dispatch) => ({
  editPage: (id: string, currentUrl: string) => dispatch(push(web.editPage(id, currentUrl))),
  refreshPostList: () => dispatch({ type: PUBLISHED_PAGES.PUBLISHED_PAGES_REFRESH }),
});

const PublishedPostItemRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { user, post, editPage, taken = false, selectedWebsite, onDueDateUpdate, onPostTypeUpdate } = props;
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const isEditor = ability.can(eUserRoles.EDITOR, "role");
  const selectPost = () => {
    editPage(post._id, location.pathname);
  };
  const status = useMemo(
    () =>
      (
        findLast(post.cluster.publishHistory, (ph) => ph.locale === post.locale && ph.variant === post.variant)
          ?.publishStatus || "UNKNOWN"
      ).toLowerCase(),
    [post]
  );
  const url = clientPages.getPageUrl(post, selectedWebsite);
  const actions = React.useMemo(() => {
    const values = [];
    if (url) {
      values.push(
        ...[
          {
            text: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
            tooltip: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
            onClick: () => window.open(url, "_blank"),
            iconName: eActionIcon.openInNew,
          },
          {
            text: I18n.t(`learning_center_content.tooltip.google_search_console`),
            tooltip: I18n.t(`learning_center_content.tooltip.google_search_console`),
            onClick: () => window.open(getSearchConsoleUrl(url), "_blank"),
            iconName: eActionIcon.search,
          },
        ]
      );
    }
    values.push({
      text: I18n.t(`learning_center_content.tooltip.copy_title`),
      tooltip: I18n.t(`learning_center_content.tooltip.copy_title`),
      onClick: () => copyStringToClipboard(post.title, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
      iconName: eActionIcon.copyName,
    });
    if (url) {
      values.push({
        text: I18n.t(`learning_center_content.tooltip.copy_url`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_url`),
        onClick: () => copyStringToClipboard(url, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
        iconName: eActionIcon.copyToClipboard,
      });
    }
    return values;
  }, [post.title]);

  return (
    <MTableRow
      handleRowClick={(e) => {
        if (taken) {
          return;
        }
        if (["writer", "credited_writer", "limited_writer"].includes(user.role)) {
          return;
        }
        selectPost();
      }}
      customClassName={classes.itemWrapper}
    >
      <MTableCellFirst
        columnWidth={"33%"}
        fontSize={theme.typography.pxToRem(14)}
        avatarType={post.type as ePostType}
        cellText={post.title}
        actions={actions}
        actionClassName={classes.actions}
      />

      <MTableCellDate date={post.firstPublishDate} />
      <MTableCellDate date={post.publishedDate} />
      <MTableCellDate
        date={post.quotaDate}
        dateFormat={DATE_FORMAT.MONTH_YEAR}
        underlineOnHover={isEditor}
        handleDateClick={(e) => {
          if (isEditor) {
            onDueDateUpdate("quotaDate");
          }
        }}
      />
      <MTableCellText text={localeHelper.getLanguageFromLocaleCode(post.locale)} />
      <MTableCellNumberProportion value1={post.textWordsCount} value2={post.outline?.wordCount || 1000} />
      <MTableCell tooltip={startCase(camelCase(post.type))} onClick={() => onPostTypeUpdate()}>
        {startCase(camelCase(post.type))}
      </MTableCell>
      <MTableCellText text={post.categories[0]?.name} />
      <MTableCellText text={numeral(post.linksCount).format("0,0") || 0} />
      <MTableCellText text={post.imported ? "✓" : "✘"} />
      <MTableCellText text={post.writer?.name} />
      <MTableCellStatus status={status} />
      <MTableCellText text={post.publishedBy?.name} />
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishedPostItemRow);
