import React from "react";
import {
  IWebsite,
  IWidgetFieldProperties,
  IWidgetLogoFieldProps,
} from "../../../../../../../reducers/constants/objectTypes";
import GenericMediaItem from "./components/GenericMediaItem";

type PropTypes = {
  properties: IWidgetFieldProperties;
  selectedWebsite: IWebsite;
  onChange: (properties: IWidgetLogoFieldProps) => void;
};

const LogoWidgetItem = (props: PropTypes) => {
  const { onChange, selectedWebsite } = props;
  const properties = props.properties as IWidgetLogoFieldProps;

  const handleDelete = () => {
    onChange(null as IWidgetLogoFieldProps);
  };

  return (
    <GenericMediaItem
      type="image"
      handleChange={(value: IWidgetLogoFieldProps) => onChange(value)}
      media={properties}
      selectedWebsite={selectedWebsite}
      handleDelete={handleDelete}
    />
  );
};

export default LogoWidgetItem;
