import { Collapse, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from "react";
import { I18n } from "react-redux-i18n";
import { find } from "lodash";
import ClassNames from "classnames";
import { IFile, IProduct, IWebsite } from "../../../../reducers/constants/objectTypes";
import { ProductReview } from "../../../types/editor.Types";
import PipeSeparatorIcon from "../../../../icons/PipeSeparatorIcon";
import ArrowRightIcon from "../../../../icons/ArrowRightIcon";
import ButtonWidgetBaseElement from "../baseElements/ButtonWidgetBaseElement";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme: Theme) => ({
  topWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rightWrapper: {},
  ctaNumberDefault: {
    fontSize: 40,
    fontWeight: theme.typography.fontWeightBold as any,
    maxWidth: 100,
    color: (props) => props.website.configurations.theme.palette.primary.main,
  },
  ctaNumber: (props: PropTypes) => ({
    ...(props.element.data.productNumber?.css || {}),
  }),
  separatorIcon: {
    stroke: (props) => props.website.configurations.theme.palette.divider,
    margin: "0px 15px",
    height: 41,
  },
  image: {
    width: 101,
    height: 41,
  },
  scoreRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  scoreText: {
    color: "#00B890",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular as any,
    marginRight: 4,
  },
  scoreValue: {
    color: "white",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
  },
  scoreBackground: {
    width: 40,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00B890",
    borderRadius: 8,
  },
  imagesWrapper: {
    display: "flex",
    flexDirection: "row",
    height: 310,
    marginTop: 16,
  },
  leftImagesWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  rightImageWrapper: {},
  leftImage: {
    width: 150,
    minHeight: 97,
    marginRight: 10,
    cursor: "pointer",
    borderRadius: 3,
  },
  rightImage: {
    width: "100%",
    height: "100%",
    borderRadius: 3,
  },
  bottomWrapper: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
  },
  textButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  description: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: theme.typography.fontWeightLight as any,
    color: (props: PropTypes) => props.website.configurations.theme.palette.text.secondary,
  },
  reviewLinkWrapper: {
    display: "flex",
    flexDirection: "row",
    color: (props: PropTypes) => props.website.configurations.theme.palette.primary.main,
    gap: 8,
    alignItems: "center",
    cursor: "pointer",
  },
  reviewText: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: theme.typography.fontWeightBold as any,
    textDecoration: "underline",
    color: (props: PropTypes) => props.website.configurations.theme.palette.primary.main,
  },
  buttonDefault: {
    marginLeft: 40,
    minWidth: 133,
    minHeight: 40,
    borderRadius: 34,
  },
  reviewsListWrapper: {
    marginTop: 14,
    display: "flex",
    flexDirection: "column",
  },
  reviewRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  reviewWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    cursor: "pointer",
    padding: "16px 0px",
    borderTop: (props: PropTypes) => `solid 1px ${props.website.configurations.theme.palette.divider}`,
  },

  row: {
    display: "flex",
    flexDirection: "row",
  },
  fieldTitle: {
    width: 100,
    marginRight: 10,
    whiteSpace: "nowrap",
    height: 30,
  },
  title: {},
  clearIcon: {
    cursor: "pointer",
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  hidden: {
    visibility: "hidden",
  },
  reviewsTitle: {
    marginTop: 10,
  },
  reviewTextWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  icon: {
    cursor: "pointer",
    fill: (props: PropTypes) => props.website.configurations.theme.palette.text.primary,
    marginRight: 16,
  },
  reviewTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "20.8px",
    color: (props: PropTypes) => props.website.configurations.theme.palette.text.primary,
  },
  reviewDescription: {
    fontSize: 14,
    paddingTop: 16,
    marginRight: 60,
    fontWeight: theme.typography.fontWeightLight as any,
    color: (props: PropTypes) => props.website.configurations.theme.palette.text.primary,
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: ProductReview;

  products: IProduct[];
  website: IWebsite;
};

const ProductReviewWidgetReadOnly = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, products, website } = props;

  const scoreTexts = {
    GOOD: "Good",
    VERY_GOOD: "Very Good",
    EXCELLENT: "Excellent",
  };

  const getScoreText = (sc) => {
    let scoreText;
    const score = parseFloat(sc);
    if (score >= 6 && score < 8) {
      scoreText = scoreTexts.GOOD;
    } else if (score >= 8 && score < 9) {
      scoreText = scoreTexts.VERY_GOOD;
    } else {
      scoreText = scoreTexts.EXCELLENT;
    }
    return scoreText;
  };

  const [rightImage, setRightImage] = React.useState<{ image: IFile; index: number }>({ image: null, index: null });

  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);
  React.useEffect(() => {
    setRightImage({ image: product?.imageFiles?.[0], index: 0 });
  }, []);

  const [reviews, setReviews] = React.useState<any>([...(element.data.reviews.list || [])]);
  const openReview = (index: number) => {
    reviews[index] = { ...reviews[index], show: !reviews[index].show };
    setReviews([...reviews]);
  };
  return (
    <div {...attributes}>
      <div className={classes.topWrapper}>
        <div className={classes.leftWrapper}>
          {element?.data?.productNumber?.show && (
            <Typography className={ClassNames(classes.ctaNumberDefault, classes.ctaNumber)}>
              {element.data.productNumber?.number || ""}
            </Typography>
          )}
          {element?.data?.productNumber?.show && element?.data?.logo?.show && (
            <PipeSeparatorIcon className={classes.separatorIcon} />
          )}
          {element?.data?.logo?.show && (
            <img className={classes.image} src={product?.logoFile?.url || product?.logo} alt={product?.logoFile?.alt} />
          )}
        </div>
        <div className={classes.rightWrapper}>
          <div className={classes.scoreRow}>
            <span className={classes.scoreText}>{getScoreText(product?.score || "6")}</span>
            <div className={classes.scoreBackground}>
              <span className={classes.scoreValue}>{product?.score || "6"}</span>
            </div>
          </div>
        </div>
      </div>
      {product?.imageFiles?.length > 0 && (
        <div className={classes.imagesWrapper}>
          <div className={classes.leftImagesWrapper}>
            {product.imageFiles.slice(0, element.data.maxImages).map(
              (image, index) =>
                index !== rightImage.index && (
                  <img
                    onClick={() => {
                      setRightImage({ image, index });
                    }}
                    key={`${index}_${image._id}`}
                    src={image.url}
                    alt={image.alt}
                    className={classes.leftImage}
                  />
                )
            )}
          </div>
          <div className={classes.rightImageWrapper}>
            <img
              key={`${rightImage?.image?._id}`}
              src={rightImage?.image?.url}
              alt={rightImage?.image?.alt}
              className={classes.rightImage}
            />
          </div>
        </div>
      )}
      <div className={classes.bottomWrapper}>
        <div className={classes.textButtonWrapper}>
          <div className={classes.textWrapper}>
            <Typography className={classes.description}>{product?.shortDescription}</Typography>
            <a className={classes.reviewText}>
              <div className={classes.reviewLinkWrapper}>
                <Typography>
                  {I18n.t("rich_text_editor.product_review.review_link", { name: product?.name || "This" })}
                </Typography>
                <ArrowRightIcon />
              </div>
            </a>
          </div>
          <ButtonWidgetBaseElement
            className={classes.buttonDefault}
            text={
              product?.callToAction || I18n.t("rich_text_editor.choose_product_to_add", { field: "Call To Action" })
            }
            url={product?.callToActionURL || I18n.t("rich_text_editor.choose_product_to_add", { field: "URL" })}
            css={element.data.button.css}
            hoverCss={element.data.button.css?.["&:hover"] || {}}
            showArrow={element.data?.button.showArrow || false}
            readMode={true}
            websiteTheme={website.configurations.theme}
          />
        </div>
        {element?.data?.reviews?.show && (
          <div className={classes.reviewsListWrapper}>
            {reviews.map((review, index) => (
              <div onClick={() => openReview(index)} key={`${review.title}_${index}`} className={classes.reviewWrapper}>
                <div className={classes.reviewTextWrapper}>
                  <Typography className={classes.reviewTitle}>{review.title}</Typography>
                  {review.show ? (
                    <ExpandLessIcon className={classes.icon} />
                  ) : (
                    <ExpandMoreIcon className={classes.icon} />
                  )}
                </div>
                <Collapse in={review.show}>
                  <Typography className={classes.reviewDescription}>{review.description}</Typography>
                </Collapse>
              </div>
            ))}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default ProductReviewWidgetReadOnly;
