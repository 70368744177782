import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import { IAd, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import { ADS } from "../../../../../../../reducers/constants/actionTypes";
import MTextField from "../../../../../../../components/MTextField";
import AdFormDetails from "./AdFormDetails";
import AdTags from "./AdTags";
import RichTextEditor from "../../../../../../../editor/RichTextEditor";
import richTextEditorHelper from "../../../../../../../editor/helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  userSelectorWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  selectWrapper: {
    minWidth: 250,
    marginLeft: 10,
  },
  fieldWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  textFieldInput: {
    minHeight: 29,
  },
  textFieldMultilineInput: {
    minHeight: 134,
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  editorToolbar: {
    overflowX: "scroll",
    position: "relative",
    zIndex: 0,
  },
  formWrapper: {
    width: "100%",
    marginTop: 25,
    borderTop: `solid 1px ${theme.palette.divider}`,
    paddingTop: 25,
  },
  formFieldWrapper: {
    display: "flex",
    marginTop: 10,
    gap: "10px",
  },
  removeIcon: {
    color: theme.palette.text.hint,
    cursor: "pointer",
    height: 10,
    width: 10,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  ad: IAd;

  handleAdChanged: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  adLoaded: state.ads.adLoaded,
  ad: state.ads.ad,
});

const mapDispatchToProps = (dispatch) => ({
  handleAdChanged: (ad) => {
    dispatch({ type: ADS.EDIT_AD_CHANGED, ad });
  },
});

const AdBusinessSelector = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, ad, handleAdChanged } = props;
  const { enqueueSnackbar } = useSnackbar();
  return (
    <div className={classes.userSelectorWrapper}>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.ad_title")}
        </Typography>
        <MTextField
          fullWidth
          outlined
          inputClassName={classes.textFieldInput}
          value={ad.title}
          onChange={(title) => handleAdChanged({ ...ad, title })}
          placeholder={I18n.t("ads.edit_ad.content.ad_title_ph")}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.ad_text")}
        </Typography>
        <RichTextEditor
          droppableId={"ad_details_editor"}
          toolbarCustomClass={classes.editorToolbar}
          website={selectedWebsite}
          initialValue={
            ad.richText ||
            JSON.stringify([
              {
                type: "paragraph",
                children: [{ text: ad.text || "" }],
              },
            ])
          }
          onValueChanged={(value) => {
            handleAdChanged({
              ...ad,
              richText: value,
              text: richTextEditorHelper.serialize(JSON.parse(value)),
            });
          }}
        />
        {/* <MTextField */}
        {/*  fullWidth */}
        {/*  outlined */}
        {/*  multiline */}
        {/*  inputClassName={classes.textFieldMultilineInput} */}
        {/*  value={ad.text} */}
        {/*  onChange={text => handleAdChanged({...ad, text})} */}
        {/*  placeholder={I18n.t('ads.edit_ad.content.ad_text_ph')} */}
        {/* /> */}
      </div>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.ad_link")}
        </Typography>
        <MTextField
          fullWidth
          outlined
          inputClassName={classes.textFieldInput}
          value={ad.link}
          onChange={(link) => handleAdChanged({ ...ad, link })}
          placeholder={I18n.t("ads.edit_ad.content.ad_link_ph")}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle} variant={"body1"}>
          {I18n.t("ads.edit_ad.content.ad_call_to_action")}
        </Typography>
        <MTextField
          fullWidth
          outlined
          inputClassName={classes.textFieldInput}
          value={ad.callToAction}
          onChange={(callToAction) => handleAdChanged({ ...ad, callToAction })}
          placeholder={I18n.t("ads.edit_ad.content.ad_call_to_action_ph")}
        />
      </div>

      <AdTags selectedWebsite={selectedWebsite} ad={ad} handleAdChanged={handleAdChanged} />

      <AdFormDetails selectedWebsite={selectedWebsite} ad={ad} handleAdChanged={handleAdChanged} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdBusinessSelector);
