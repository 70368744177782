import React from "react";
import { I18n } from "react-redux-i18n";
import MDropdown from "../../../../../components/MDropdown";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  dropdownWrapper: {
    padding: "3px 10px",
  },
}));

type PropTypes = {
  value: any;
  handleOnChange: (value) => void;
};

export const options = [
  {
    value: "goal-completion-by-page",
    label: "Goal Completion By Page",
  },
];

const GoalCompletionReportTypeFilter = (props: PropTypes) => {
  const classes = useStyles(props);
  const { value, handleOnChange } = props;

  return (
    <MDropdown
      classes={{ root: classes.dropdownWrapper }}
      withBorder
      options={options}
      value={options.find((o) => o.value === value)}
      handleOnChange={handleOnChange}
      placeholder={I18n.t("reports.dashboard.select_ph")}
      optionLabel="label"
      optionValue="value"
    />
  );
};

export default GoalCompletionReportTypeFilter;
