import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { AUDIENCES, WRITER_BIO } from "../../../../../reducers/constants/actionTypes";
import { IAdminUser, ICategory, IFile, IWebsite, IWriterBio } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import MButtonGroup from "../../../../../components/MButtonGroup";
import MAutoSave from "../../../../../components/MAutoSave";
import { useParams } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
  bottomBar: {
    minHeight: 60,
    paddingRight: 40,
    paddingLeft: 40,
    marginRight: 30,
    marginLeft: 30,
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderTop: `${theme.palette.divider} solid 1px`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    bottom: 0,
    position: "sticky",
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(18),
    textTransform: "capitalize",
    padding: "12px 40px",
    borderRadius: 75,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedBioLoaded: boolean;
  selectedBio: IWriterBio;

  user: IAdminUser;
  categories: ICategory[];

  active: boolean;

  general: {
    active: boolean;
    profileImage: IFile;
    name: string;
    displayName: string;
    role: string;
    expertiseList: [
      {
        title: string;
        text: string;
      }
    ];
    educationList: [
      {
        title: string;
        text: string;
      }
    ];
    socials: {
      facebook: string;
      twitter: string;
      youTube: string;
      linkedin: string;
    };
  };

  preview: {
    active: boolean;
    text: string;
  };

  highlights: {
    active: boolean;
    option1: string;
    option2: string;
    option3: string;
    option4: string;
  };

  expertise: {
    active: boolean;
    title: string;
    text: string;
  };

  quote: {
    active: boolean;
    name: string;
    text: string;
  };

  metaDescription: string;

  handleBioUpdated: (bio) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  selectedBioLoaded: state.writerBio.selectedBioLoaded,
  selectedBio: state.writerBio.selectedBio,

  user: state.writerBio.user,
  active: state.writerBio.active,
  general: state.writerBio.general,
  preview: state.writerBio.preview,
  highlights: state.writerBio.highlights,
  expertise: state.writerBio.expertise,
  quote: state.writerBio.quote,
  metaDescription: state.writerBio.metaDescription,
  categories: state.writerBio.categories,
});

const mapDispatchToProps = (dispatch) => ({
  handleBioUpdated: (bio) => dispatch({ type: WRITER_BIO.ON_BIO_UPDATED, bio }),
});

const WriterBioBottomBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, selectedBioLoaded, categories, selectedBio, handleBioUpdated } = props;
  const { user, active, general, preview, highlights, expertise, quote, metaDescription } = props;
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const { writerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const validBio = () => {
    if (!active) {
      return true;
    }
    // if (!audienceMainCategory) {
    // 	enqueueSnackbar(I18n.t('snackbar.invalid.missing_field', { field: 'Main Category' }));
    // 	return false;
    // }
    //
    // if (!audienceCategories || audienceCategories.length === 0) {
    // 	enqueueSnackbar(I18n.t('snackbar.invalid.missing_field', { field: 'Categories' }));
    // 	return false;
    // }
    //
    // if (!name) {
    // 	enqueueSnackbar(I18n.t('snackbar.invalid.missing_field', { field: 'Name' }));
    // 	return false;
    // }

    return true;
  };

  const update = () => {
    if (!validBio()) {
      return;
    }

    agent.Bios.updateBio(selectedWebsite._id, writerId, {
      ...selectedBio,
      user: user || writerId,
      website: selectedWebsite._id,
      categories,
      active,
      general,
      preview,
      highlights,
      expertise,
      quote,
      metaDescription,
    })
      .then((res) => {
        handleBioUpdated(res.bio);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  if (!selectedBioLoaded) {
    return null;
  }

  return (
    <div className={classes.bottomBar}>
      {/*<MAutoSave autoSaveOn interval={300} lastSaveTime={selectedBio?.updatedAt || null} handleSavePost={() => update()} />*/}
      <MButtonGroup
        loading={saveInProgress}
        text={I18n.t("entail.save_changes")}
        onClick={(e) => update()}
        options={[
          {
            key: "save",
            label: I18n.t("entail.save_changes"),
          },
          {
            key: "delete",
            label: I18n.t("upload_post.delete"),
          },
        ]}
        onMenuItemClick={(key: string) => {
          switch (key) {
            case "save":
              return update();
            case "delete":
            default:
              return null;
          }
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WriterBioBottomBar);
