import React from "react";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
  Theme,
} from "@material-ui/core";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { ChevronLeft } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    fontSize: 14,
    lineHeight: "normal",
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: "#E6EEFF",
      color: theme.palette.primary.light,
    },
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: 35,
    height: 35,
    "&:hover": {
      backgroundColor: "#D7E2F7",
      "& $icon": {
        color: theme.palette.primary.main,
      },
    },
  },
  icon: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  iconSelected: {
    backgroundColor: "#D7E2F7",
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  disabledIcon: {
    color: theme.palette.text.disabled,
    "&:hover": {
      cursor: "not-allowed",
      backgroundColor: "transparent",
      color: theme.palette.text.disabled,
    },
  },
  chevronLeft: {
    marginLeft: -10,
    marginRight: 5,
    width: 22,
  },
  menuItemExtraSpace: {
    paddingLeft: 32,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.text.secondary,
    padding: "0px 10px",
  },
}));

type IOption = {
  label: string;
  value?: string | number;
  onClick?: () => void;
};

type IOptionProps = {
  label: string;
} & (
  | {
      nested: true;
      placement?: PopperPlacementType;
      options: {
        label: string;
        onClick?: () => void;
        value?: string | number;
      }[];
    }
  | {
      nested?: never | false;
      onClick?: () => void;
      value?: string | number;
    }
);

type PropTypes = {
  options: IOptionProps[];

  disabled?: boolean;
  stopPropagationOnOpen?: boolean;
  iconVertical?: boolean;
  onClick?: (option: { label: string; value?: string | number }) => void;
  title?: string;
  placement?: PopperPlacementType;
};

const MActionsDropdown = (props: PropTypes) => {
  const classes = useStyles(props);
  const [open, setOpen] = React.useState(false);
  const [openNestedIndex, setOpenNestedIndex] = React.useState(null);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const nestedAnchorRef = React.useRef<any>(null);

  const { options, disabled, stopPropagationOnOpen = true, iconVertical = false, title, placement } = props;

  const handleToggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) {
      return;
    }
    if (stopPropagationOnOpen) {
      event.stopPropagation();
    }

    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, option: IOption) => {
    event.stopPropagation();
    if (option.onClick) {
      option.onClick();
    }
    if (props.onClick) {
      props.onClick(option);
    }

    setOpen(false);
    setOpenNestedIndex(null);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
    setOpenNestedIndex(null);
  };

  return (
    <>
      <div
        ref={anchorRef}
        onClick={handleToggle}
        className={classNames(classes.iconWrapper, open && classes.iconSelected)}
      >
        <MoreHoriz
          style={{ transform: iconVertical ? "rotate(90deg)" : "none" }}
          className={classNames(classes.icon, disabled && classes.disabledIcon)}
        />
      </div>
      <Popper
        style={{ zIndex: 1000 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement={placement}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {title && <Typography className={classes.title}>{title}</Typography>}
                  {options.map((option, index) => {
                    if (!option.nested) {
                      return (
                        <MenuItem
                          key={index}
                          onClick={(event) => handleMenuItemClick(event, option)}
                          className={classNames(
                            classes.menuItem,
                            options.find((o) => o.nested && o.options?.length > 0) && classes.menuItemExtraSpace
                          )}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    }

                    // eslint-disable-next-line no-lone-blocks
                    {
                      return option.nested && option.options?.length > 0 ? (
                        <div key={index}>
                          <MenuItem
                            className={classes.menuItem}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (openNestedIndex !== index) {
                                nestedAnchorRef.current = e.currentTarget;
                                setOpenNestedIndex(index);
                              } else {
                                setOpenNestedIndex(null);
                              }
                            }}
                          >
                            <ChevronLeft className={classes.chevronLeft} /> {option.label}
                          </MenuItem>
                          {openNestedIndex === index && nestedAnchorRef.current && (
                            <Popper
                              open={openNestedIndex === index}
                              placement={option.placement || "left-start"}
                              anchorEl={nestedAnchorRef.current}
                              role={undefined}
                              transition
                              disablePortal
                              style={{ zIndex: 1001 }}
                            >
                              {({ TransitionProps: NTransitionProps }) => (
                                <Grow {...NTransitionProps}>
                                  <Paper>
                                    <MenuList id="split-button-menu">
                                      {option.options.map((nOption, nIndex) => (
                                        <MenuItem
                                          key={`${nOption.label}-${nIndex}`}
                                          onClick={(event) => handleMenuItemClick(event, nOption)}
                                          className={classes.menuItem}
                                        >
                                          {nOption.label}
                                        </MenuItem>
                                      ))}
                                    </MenuList>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          )}
                        </div>
                      ) : null;
                    }
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default MActionsDropdown;
