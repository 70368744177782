import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import {
  IWebsite,
  IWidgetMediaFieldProps,
  IWidgetImageProps,
  IWidgetVideoProps,
  IWidgetFieldProperties,
} from "../../../../../../../reducers/constants/objectTypes";
import GenericMediaItem from "./components/GenericMediaItem";
import MSelect from "../../../../../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 3,
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectIndicator: {
    marginRight: 5,
    width: 12,
  },
}));

type PropTypes = {
  properties: IWidgetFieldProperties;
  selectedWebsite: IWebsite;
  onChange: (properties: IWidgetMediaFieldProps) => void;
};

const MediaWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const theme = useTheme<Theme>();

  const { onChange, selectedWebsite } = props;
  const properties = props.properties as IWidgetMediaFieldProps;
  const { type, image, video } = properties;

  const mediaTypeOptions = [
    { value: "image", label: "Image" },
    { value: "video", label: "Video" },
  ];

  return (
    <div className={classes.root}>
      <MSelect
        customClass={classes.selectCustomClass}
        customDropdownIndicatorClasses={classes.selectIndicator}
        valuePadding={"2px 10px 2px 6px"}
        options={mediaTypeOptions}
        value={mediaTypeOptions.find((option) => option.value === type)}
        optionLabel={"label"}
        optionValue={"value"}
        height={50}
        borderWidth={"0px"}
        isBold={false}
        textColor={theme.palette.text.primary}
        menuPlacement={"bottom"}
        singleValueStyles={{
          fontSize: theme.typography.pxToRem(14),
          fontWeight: theme.typography.fontWeightMedium as any,
        }}
        fullWidth
        getDisplayLabel={(vertical) => `${vertical.label}`}
        handleChange={(option) => onChange({ ...properties, type: option.value })}
      />
      {type === "image" ? (
        <GenericMediaItem
          type={"image"}
          handleChange={(image: IWidgetImageProps) => onChange({ ...properties, image })}
          media={image}
          selectedWebsite={selectedWebsite}
          handleDelete={() => onChange({ ...properties, image: null })}
        />
      ) : (
        <GenericMediaItem
          type={"video"}
          handleChange={(video: IWidgetVideoProps) => onChange({ ...properties, video })}
          media={video}
          selectedWebsite={selectedWebsite}
          handleDelete={() => onChange({ ...properties, video: null })}
        />
      )}
    </div>
  );
};

export default MediaWidgetItem;
