import { Collapse, makeStyles, Theme, Tooltip } from "@material-ui/core";
import * as React from "react";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import TextareaAutosize from "react-textarea-autosize";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import agent from "../../../../agent";
import MButton from "../../../../components/MButton";
import MButtonGroup from "../../../../components/MButtonGroup";
import MTextButton from "../../../../components/MTextButton";
import { editorType } from "../../../../editor/types/editor.Types";
import { copyStringToClipboard } from "../../../../helpers/fbScraperHelper";
import slateMarkdownHelper from "../../../../helpers/slateMarkdownHelper";
import { IPage, IWebsite } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  aiWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
    minHeight: 400,
  },
  textField: {
    gap: 10,
    width: "100%",
    resize: "vertical",
    minHeight: 37,
    border: "1px solid #E5E5E5",
    padding: "10px",
    margin: 0,
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    display: "flex",
    alignItems: "start",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    "&:focus": {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
  },
  textInput: {
    fontFamily: theme.typography.fontFamily,
    resize: "none",
    border: "none",
    outline: "none",
    width: "100%",
    margin: (props: PropTypes) => props.margin || 0,
    fontSize: (props: PropTypes) => props.fontSize || 16,
    padding: (props: PropTypes) => props.padding || 0,
    lineHeight: 1.3,
    backgroundColor: "initial",
  },
  icon: {
    padding: 0,
  },
  innerWrapper: {
    flex: 1,
    width: 680,
    display: "flex",
    flexDirection: "column",
    padding: 15,
    overflow: "auto",
    gap: 10,
    margin: (props: PropTypes) => props.margin || 0,
    borderRadius: 5,
    marginBottom: 20,
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  actionsWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },

  actionButtonWrapper: {
    borderRadius: 2,
    border: "solid 1px #E5E5E5",
    display: "flex",
    maxHeight: 31,
  },
  actionButton: {
    padding: "7px 10px",
    minHeight: 0,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    minWidth: 0,
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  generateButton: {
    maxWidth: 88,
    minWidth: 88,
    padding: "7px 15px",
    borderRadius: 2,
    maxHeight: 31,
  },
  titleListWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 5,
    paddingInlineStart: 0,
  },
  listTitle: {
    display: "flex",
    flexDirection: "row",
    color: theme.palette.text.primary,
    padding: 5,
    borderRadius: 3,
    alignItems: "center",
    gap: 10,
    "&:hover": {
      "& > $listActions": {
        display: "flex",
      },
    },
  },
  listActions: {
    display: "none",
    flexDirection: "row",
    margin: "0px 15px",
    position: "absolute",
    right: 10,
    zIndex: 2,
    gap: 10,
    backgroundColor: theme.palette.common.white,
  },
  listAction: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  listItem: {
    fontSize: 16,
    flex: 1,
    cursor: "pointer",
    maxWidth: "80%",
  },
  empty: {
    width: 10,
  },
  listItemText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 16,
  },
}));

type PropTypes = {
  minRows?: number;
  maxRows?: number;
  text: string;
  margin?: string | number;
  padding?: string | number;
  placeholder?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange: (text: string) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  page?: IPage;
  selectedWebsite?: IWebsite;
  field: string;
};

const styleActions = [
  { label: "Generic", key: "generic" },
  { label: "Magazine", key: "magazine" },
  { label: "Short ", key: "short" },
  { label: "Academic", key: "academic" },
  { label: "Clickbait", key: "clickbait" },
  { label: "Social", key: "social" },
  { label: "Subject", key: "subject" },
  { label: "News", key: "news" },
];

const toneActions = [
  { label: "Informal", key: "informal" },
  { label: "Formal", key: "formal" },
  { label: "Friendly", key: "friendly" },
];
const types = [
  { label: "Rephrase existing", key: "rephrase" },
  { label: "Generate new", key: "generate_new" },
];
const AITitleGenerator = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    text,
    field,
    minRows = 1,
    maxRows = undefined,
    onChange,
    onKeyDown = null,
    onBlur = null,
    onFocus = null,
    page,
    selectedWebsite,
    placeholder = I18n.t("rich_text_editor.insert_comments.comment_ph"),
  } = props;
  const [cursor, setCursor] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedStyleAction, setSelectedStyleAction] = React.useState(styleActions[0]);
  const [selectedToneAction, setSelectedToneAction] = React.useState(toneActions[0]);
  const [selectedType, setSelectedType] = React.useState(types[!text ? 1 : 0]);
  const [titles, setTitles] = React.useState([]);
  const [loadTitles, setLoadTitles] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const ref = React.useRef(null);

  React.useEffect(() => {
    const input = ref.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref, cursor, text]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e.target.value);
  };
  React.useEffect(() => {
    onChange(text);
  }, []);

  const getMessage = () => {
    if (selectedType.key === "rephrase" && text) {
      const currentNodes = [{ type: editorType.headingOne, children: [{ text }] }];
      return {
        user: slateMarkdownHelper.slateTopMarkdown(currentNodes),
        system: `can you generate five ${selectedStyleAction.label}  Rephrased meta ${field}s ${
          selectedToneAction ? ` in a ${selectedToneAction.label} tone` : ""
        }. The result should be strictly a json format for example {"result":["title1","title2","more..."]}  and the json key should be "result"`,
      };
    }
    return {
      user: slateMarkdownHelper.slateTopMarkdown(JSON.parse(page.richText)),
      system: `can you generate five ${selectedStyleAction.label} meta ${field} ${
        selectedToneAction ? ` in a ${selectedToneAction.label} tone` : ""
      }. Use the text provided by the user as context. The result should be strictly a json format for example {"result":["title1","title2","more..."]} and the json key should be "result"`,
    };
  };

  const getTitles = async () => {
    setTitles([]);
    setLoadTitles(true);
    const message = getMessage();
    agent.EditorAssistant.assistant(selectedWebsite, {
      message: message.user,
      extraInstruction: false,
      instruction: message.system,
      data: { page: page?._id },
    })
      .then((res) => {
        const result = JSON.parse(res.result.text)?.result;
        setLoadTitles(false);
        if (result) {
          setTitles(result);
        } else {
          setLoadTitles(false);
          enqueueSnackbar(I18n.t("ai_assistant.bad_formatting_error"), {
            variant: "error",
          });
          setTitles([]);
        }
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.create_error", { msg: e.response.body.message }), {
          variant: "error",
        });
        setLoadTitles(false);
        setTitles([]);
      });
  };
  const handleElementDataChange = (title) => {
    onChange && onChange(title);
  };
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classes.aiWrapper}>
        <div className={classes.innerWrapper} contentEditable={false}>
          <Typography className={classes.title} variant={"subtitle2"}>
            {I18n.t("strategy.outlinePages.dialog_outlines.ai_generator_title")}
          </Typography>
          <div className={classes.actionsWrapper}>
            <MButtonGroup
              wholePress
              onMenuItemClick={(key) => setSelectedType(types.find((opt) => opt.key === key))}
              onClick={() => {}}
              wrapperCustomClass={classes.actionButtonWrapper}
              customClass={classes.actionButton}
              hasDivider={false}
              options={types}
              text={selectedType.label}
            />
            <MButtonGroup
              wholePress
              onMenuItemClick={(key) => setSelectedStyleAction(styleActions.find((opt) => opt.key === key))}
              onClick={() => {}}
              wrapperCustomClass={classes.actionButtonWrapper}
              customClass={classes.actionButton}
              hasDivider={false}
              options={styleActions}
              text={
                selectedStyleAction?.label ? `${selectedStyleAction.label} style` : I18n.t("ai_assistant.select_style")
              }
            />
            <MButtonGroup
              wrapperCustomClass={classes.actionButtonWrapper}
              wholePress
              customClass={classes.actionButton}
              onMenuItemClick={(key) => setSelectedToneAction(toneActions.find((opt) => opt.key === key))}
              onClick={() => {}}
              hasDivider={false}
              options={toneActions}
              text={selectedToneAction?.label ? `${selectedToneAction.label} tone` : I18n.t("ai_assistant.select_tone")}
            />
            <MButton
              barSize={20}
              disabled={!selectedStyleAction}
              loading={loadTitles}
              customClassNames={classes.generateButton}
              onClick={() => {
                if (selectedStyleAction) {
                  getTitles();
                }
              }}
            >
              {I18n.t("ai_assistant.generate")}
            </MButton>
          </div>

          {titles && (
            <ol className={classes.titleListWrapper}>
              {titles.map((title, i) => (
                <div className={classes.listTitle} key={`title_${i + 1}`}>
                  <div className={classes.empty} />
                  <Tooltip title={title}>
                    <li className={classes.listItem}>
                      <Typography className={classes.listItemText}>{title}</Typography>
                    </li>
                  </Tooltip>
                  <div className={classes.listActions}>
                    <MTextButton
                      customClass={ClassNames(classes.listAction)}
                      text={I18n.t(`ai_assistant.insert`)}
                      onClick={() => {
                        handleElementDataChange(title);
                      }}
                    />
                    <MTextButton
                      customClass={ClassNames(classes.listAction)}
                      text={I18n.t(`ai_assistant.actions.copy`)}
                      onClick={() => {
                        copyStringToClipboard(title, () =>
                          enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"), {
                            variant: "success",
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              ))}
            </ol>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default AITitleGenerator;
