import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { goBack } from "connected-react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { filter } from "lodash";
import { ADS } from "../../../../reducers/constants/actionTypes";
import { IAd, IWebsite } from "../../../../reducers/constants/objectTypes";
import AdContent from "./components/adContent/AdContent";
import agent from "../../../../agent";
import MTextField from "../../../../components/MTextField";
import AdPlacements from "./components/adPlacements/AdPlacements";
import AdPreview from "./components/adPeview/AdPreview";
import MButtonGroup from "../../../../components/MButtonGroup";

const useStyles = makeStyles((theme: Theme) => ({
  answersWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    minHeight: 60,
    paddingRight: 40,
    paddingLeft: 40,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  toolbarRightSection: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  keywordsCounter: {
    marginRight: 20,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  backIcon: {
    cursor: "pointer",
    marginRight: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  answersInnerWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 135px)",
  },
  editor: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
    borderRight: `solid 1px ${theme.palette.divider}`,
  },
  resources: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
  },
  clusterName: {
    display: "flex",
    flexDirection: "row",
  },
  editIcon: {
    cursor: "pointer",
    fontSize: 16,
    marginLeft: 6,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  clusterNameTextField: {
    width: 800,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end",
    minHeight: 75,
    paddingRight: 40,
    paddingLeft: 40,
    borderTop: `solid 1px ${theme.palette.divider}`,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;

  ad: IAd;
  adLoaded: boolean;

  handleAdChanged: Function;
  cleanUp: Function;
  returnToPreviousScreen: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  adLoaded: state.ads.adLoaded,
  ad: state.ads.ad,
});

const mapDispatchToProps = (dispatch) => ({
  cleanUp: () => dispatch({ type: ADS.EDIT_AD_CLEANUP }),
  handleAdChanged: (ad) => {
    dispatch({ type: ADS.EDIT_AD_LOADED, ad });
  },
  returnToPreviousScreen: () => dispatch(goBack()),
});

const EditAd = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, adLoaded, ad } = props;
  const { handleAdChanged, cleanUp, returnToPreviousScreen } = props;
  const { adId } = useParams();
  const [editedAdName, setEditedAdName] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const actions = [
    {
      key: "publish",
      label: I18n.t("ads.edit_ad.publish"),
    },
    {
      key: "save_draft",
      label: I18n.t("ads.edit_ad.save_draft"),
    },
  ];

  const updateAd = (publish = false) => {
    console.log("updateAd", publish);
    agent.Ads.updateAd({ ...ad, published: publish ? true : ad.published })
      .then((res) => {
        handleAdChanged(res.ad);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        console.error("Error", e);
        enqueueSnackbar(e?.response?.body?.message || "Something went wrong");
        // returnToPreviousScreen();
      });
  };

  const validAd = () => {
    if (!ad.business) {
      alert("Ad business is missing");
      return false;
    }
    if (!ad.business.name) {
      alert("Ad business name is missing");
      return false;
    }
    if (!ad.business.profileImageFile) {
      alert("Ad business image is missing");
      return false;
    }
    if (!ad.title) {
      alert("Ad title is missing");
      return false;
    }
    if (!ad.text) {
      alert("Ad text is missing");
      return false;
    }
    if (!ad.callToAction) {
      alert("Ad call to action is missing");
      return false;
    }
    if (!ad.placements || ad.placements.length === 0) {
      alert("Ad placements are missing");
      return false;
    }
    const activePlacements = filter(ad.placements, { active: true });
    if (!activePlacements || activePlacements.length === 0) {
      alert("Ad must contain at least one active placement");
      return false;
    }
    // for (let i = 0; i < activePlacements.length; i++) {
    //   if (activePlacements[i].)
    // }
    return true;
  };

  const handleButtonClick = (key: string) => {
    switch (key) {
      case "publish":
        if (validAd()) {
          updateAd(true);
        }
        break;
      case "save_draft":
        updateAd();
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    console.log("adId", adId);
    agent.Ads.getAdById(adId)
      .then((res) => {
        handleAdChanged(res.ad);
      })
      .catch((e) => {
        console.error("Error", e);
        enqueueSnackbar(e?.response?.body?.message || "Something went wrong");
        returnToPreviousScreen();
      });
    return () => {
      cleanUp();
    };
  }, [returnToPreviousScreen, handleAdChanged, adId]);

  const updateAdName = () => {
    agent.Ads.updateAd({ ...ad, name: editedAdName })
      .then((res) => {
        handleAdChanged(res.ad);
        setEditedAdName(null);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  return (
    <div className={classes.answersWrapper}>
      <div className={classes.toolbar}>
        <div className={classes.toolbarDetails}>
          <ArrowBackIcon className={classes.backIcon} onClick={() => returnToPreviousScreen()} />
          {editedAdName ? (
            <MTextField
              outlined
              autoFocus
              customClass={classes.clusterNameTextField}
              value={editedAdName}
              onBlur={() => updateAdName()}
              placeholder={I18n.t("clusters.new_topic_dialog.cluster_name_ph")}
              onChange={(userInput) => {
                setEditedAdName(userInput);
              }}
            />
          ) : (
            <Typography className={classes.clusterName} color={"textPrimary"} variant={"subtitle2"}>
              {!ad ? "" : ad.name}
              {adLoaded && <EditIcon className={classes.editIcon} onClick={() => setEditedAdName(ad.name)} />}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.answersInnerWrapper}>
        <AdContent />
        <AdPlacements />
        <AdPreview />
      </div>
      <div className={classes.footer}>
        <div className={classes.toolbarDetails}>
          <MButtonGroup
            text={actions[0].label}
            onClick={() => handleButtonClick(actions[0].key)}
            options={actions}
            onMenuItemClick={(key: string) => handleButtonClick(key)}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAd);
