import { ListItem, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { ReactNode } from "react";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";

import CopyToClipboardIcon from "../../../../../../../icons/CopyToClipboardIcon";
import TrashCanIcon from "../../../../../../../icons/TrashCanIcon";
import ArrowRightRoundedIcon from "../../../../../../../icons/ArrowRightRoundedIcon";
import { colors, widgetFieldIcons } from "../../../../../../../helpers/constants";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    padding: 15,
    height: 55,
    marginBottom: 2,
    backgroundColor: "#EEF2F9",
    "&:hover": {
      backgroundColor: colors.widgetOnHoverBackgroundColor,
    },
  },
  header: {
    flex: 1,
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    "&:hover $actionIcon": {
      visibility: "visible",
    },
  },
  leftContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: 300,
    flex: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: typography.fontWeightMedium as any,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 150,
    color: palette.text.primary,
    marginLeft: 10,
  },
  rightContainer: {
    display: "flex",
    flex: 1,
    justifySelf: "flex-end",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: -20,
    gap: 10,
  },
  actionIcon: {
    visibility: "hidden",
    boxSizing: "content-box",
    color: palette.text.primary,
    opacity: 0.4,
    "&:hover": {
      opacity: 1,
    },
  },
  img: {
    height: 30,
    width: 30,
    userSelect: "none",
  },
  copyIcon: {
    fontSize: 19,
    color: palette.text.primary,
  },
  trashIcon: {
    fontSize: 18,
    color: palette.text.primary,
    marginRight: 0,
  },
  arrowIcon: {
    fontSize: 19,
    color: palette.text.primary,
    opacity: 1,
    padding: 5,
    boxSizing: "content-box",
  },
}));

type PropTypes = {
  field: {
    type: string;
    properties: {
      label: string;
      placeholder: string;
    };
  };
  onClick?(event: any): unknown;
  onCopyClick?(event: any): unknown;
  onDeleteClick?(event: any): unknown;
};

const FormWidgetItem = (props: PropTypes) => {
  const { field, onClick, onCopyClick, onDeleteClick } = props;
  const classes = useStyles(props);

  return (
    <ListItem className={classes.root} component={"div"} onClick={onClick}>
      <div className={classes.header}>
        <div className={classes.leftContainer}>
          <img src={widgetFieldIcons[field?.type]} className={classes.img} />
          <Typography className={classes.title}>
            {field?.properties?.label ||
              field?.properties?.placeholder ||
              I18n.t(`widgets_builder.widget_item_selector.${field?.type}`)}
          </Typography>
        </div>

        <div className={classes.rightContainer}>
          <CopyToClipboardIcon onClick={onCopyClick} className={ClassNames(classes.actionIcon, classes.copyIcon)} />
          <TrashCanIcon onClick={onDeleteClick} className={ClassNames(classes.actionIcon, classes.trashIcon)} />
          <ArrowRightRoundedIcon className={classes.arrowIcon} />
        </div>
      </div>
    </ListItem>
  );
};

export default FormWidgetItem;
