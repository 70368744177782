import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Switch from "@material-ui/core/Switch";
import AdIconDraft from "../../../../../icons/AdIconDraft.svg";
import AdIconOff from "../../../../../icons/AdIconOff.svg";
import AdIconPublished from "../../../../../icons/AdIconPublished.svg";
import { IAd } from "../../../../../reducers/constants/objectTypes";
import MTableRow from "../../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../../components/Table/Cells/MTableCellFirst";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";
import MTableCellDate from "../../../../../components/Table/Cells/MTableCellDate";
import MTableCellNumber from "../../../../../components/Table/Cells/MTableCellNumber";
import MActionsDropdown from "../../../../../components/MActionsDropdown";
import ability from "../../../../../casl/ability";

const useStyles = makeStyles((theme: Theme) => ({
  moreOptionsColumn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 100,
  },
}));

type PropTypes = {
  ad: IAd;
  searchConsoleInterval:
    | "today"
    | "lastWeek"
    | "last2Weeks"
    | "lastMonth"
    | "last2Months"
    | "last3Months"
    | "last6Months"
    | "lastYear";

  openAdPage: Function;
  handleDeleteClick: Function;
  handleAdChanged: Function;
};

const AdItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { ad, searchConsoleInterval, openAdPage, handleDeleteClick, handleAdChanged } = props;
  // const [impressions, setImpressions] = React.useState(0);
  // const [clicks, setClicks] = React.useState(0);

  // const totalLinks = cluster.linkingPages.reduce((acc, page) => {
  //   const post = page.learnTopic || page.learnPost || page.blogPost || page.communityPost || page.targetedPost || page.landingPage || page.topicPage;
  //   return acc + (post && post.richText ? richTextEditorHelper.countLinks(post.richText) : 0);
  // }, 0);

  const getIcon = () => {
    switch (ad.status) {
      case "DRAFT":
        return AdIconDraft;
      case "OFF":
      case "PUBLISH":
        return AdIconOff;
      case "PUBLISHED":
        return AdIconPublished;
      default:
        return null;
    }
  };

  let clicks = null;
  let impressions = null;

  ad.placements.map((placement) => {
    clicks = (clicks || 0) + placement.clicks;
    impressions = (impressions || 0) + placement.impressions;
    return true;
  });
  const theme = useTheme();

  const menuItems = React.useMemo(() => {
    const options = [];
    if (ability.can("editor", "role")) {
      options.push({
        value: "DELETE",
        onClick: handleDeleteClick,
        label: I18n.t("redirect_rules.table.delete"),
      });
    }
    return options;
  }, [ad, ability]);

  return (
    <MTableRow handleRowClick={() => openAdPage(ad)}>
      <MTableCellFirst
        cellText={ad.name}
        columnWidth={"40%"}
        fontSize={theme.typography.pxToRem(14)}
        prefix={<img style={{ marginRight: 25 }} height={25} width={25} alt={"ad icon"} src={getIcon()} />}
      />
      <MTableCellText
        text={
          ad.published
            ? I18n.t(`ads.ads_list.status.published`)
            : I18n.t(`ads.ads_list.status.${ad.status.toLowerCase()}`)
        }
      />
      <MTableCellDate date={ad.updatedAt} />
      <MTableCellText text={ad.business?.name} />
      <MTableCellNumber value={ad.placements.length} />
      <MTableCellDate date={ad.createdAt} />
      <MTableCellNumber value={impressions} placeHolder={"-"} />
      <MTableCellNumber value={clicks} placeHolder={"-"} />
      <div className={classes.moreOptionsColumn} onClick={(e) => e.stopPropagation()}>
        <Switch
          checked={ad.active}
          onChange={(e) => {
            e.stopPropagation();
            handleAdChanged({ ...ad, active: !ad.active });
          }}
          color="primary"
        />
        <MActionsDropdown options={menuItems} />
      </div>
    </MTableRow>
  );
};

export default AdItem;
