import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import VideoView from "./VideoView";
import VideoControlBar from "./VideoControlBar";
import CommentsPanel from "./CommentsPanel";
import TimelineSlider from "./TimelineSlider";
import { useVideoEditorContext } from "../VideoEditorContext";

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "#000",
    display: "grid",
    gridTemplateRows: "1fr auto",
    alignItems: "center",
    "&:focus": {
      outline: "none",
    },
  },
  videoViewWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  videoControlBarWrapper: {
    alignSelf: "end",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const VideoWithControls = () => {
  const classes = useStyles();
  const { timelineRef } = useVideoEditorContext();
  const videoPlayerContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const playVideoOnSpace = (e: KeyboardEvent) => {
      if (!videoPlayerContainerRef.current?.contains(document.activeElement)) {
        return;
      }

      if (e.key === " ") {
        e.preventDefault();
        if (timelineRef.current.isPlaying) {
          timelineRef.current.pause();
        } else {
          timelineRef.current.play({ autoEnd: true });
        }
      }
    };
    window.addEventListener("keydown", playVideoOnSpace);

    return () => {
      window.removeEventListener("keydown", playVideoOnSpace);
    };
  }, [timelineRef]);

  return (
    <div className={classes.container} ref={videoPlayerContainerRef} tabIndex={0}>
      <div className={classes.videoViewWrapper}>
        <VideoView />
      </div>
      <div className={classes.videoControlBarWrapper}>
        <TimelineSlider />
        <CommentsPanel />
        <VideoControlBar />
      </div>
    </div>
  );
};

export default VideoWithControls;
