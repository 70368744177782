import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import { MANAGE_CONTENT } from "../../../../../reducers/constants/actionTypes";
import { ITag, IWebsite } from "../../../../../reducers/constants/objectTypes";
import CfProgressBar from "../../../../../components/CfProgressBar";
import MTextBox from "../../../../../components/MTextBox";
import TagPageBottomBar from "./TagPageBottomBar";
import MTextField from "../../../../../components/MTextField";
import LocaleTabs from "../../../../../components/LocaleTabs";
import { clients } from "../../../../../helpers/urlHelper";
import RichTextEditor from "../../../../../editor/RichTextEditor";
import richTextEditorHelper from "../../../../../editor/helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    // margin: 20,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  tagMainSection: {
    paddingBottom: 50,
    height: "calc(100vh - 128px)",
    overflow: "auto",
  },
  paper: {},
  tagDetailsWrapper: {
    padding: 20,
  },
  metaDescriptionWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    marginTop: 10,
  },
  topicNameWrapper: {
    marginBottom: 10,
    padding: 20,
  },
  topicName: {},
  nameField: {
    marginTop: 10,
    marginBottom: 0,
    paddingLeft: 0,
  },
  appBar: {
    zIndex: 4,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "row",
  },
}));

type PropTypes = {
  tagsSelectedLocale: string;
  selectedWebsite: IWebsite;
  selectedTag: ITag;
  tagsLoaded: boolean;

  onTagUpdated: Function;
  handleLocaleChanged: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  selectedTag: state.manageContent.selectedTag,
  tagsSelectedLocale: state.manageContent.tagsSelectedLocale,
  tagsLoaded: state.manageContent.tagsLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  onTagUpdated: (tag) => dispatch({ type: MANAGE_CONTENT.ON_TAG_CHANGED, tag }),
  handleLocaleChanged: (locale) => {
    dispatch({ type: MANAGE_CONTENT.ON_TAGS_LOCALE_CHANGED, locale });
  },
});

const TagDetails = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, tagsLoaded, selectedTag, tagsSelectedLocale, handleLocaleChanged } = props;
  const { onTagUpdated } = props;

  const selectedLocale = tagsSelectedLocale || selectedWebsite.defaultLocale;
  const defaultLocale = selectedLocale === selectedWebsite.defaultLocale;
  return (
    <div className={classes.wrapper}>
      {!tagsLoaded && <CfProgressBar />}
      {tagsLoaded && selectedTag && (
        <div className={classes.tagMainSection}>
          <AppBar elevation={0} position="sticky" className={classes.appBar}>
            <LocaleTabs
              selectedLocale={selectedLocale}
              selectedWebsite={selectedWebsite}
              handleLocaleChange={(l) => handleLocaleChanged(l)}
              getTabName={(loc) => {
                if (loc === "en" || selectedTag.translations[loc]) {
                  return "Edit";
                }
                return "Add";
              }}
              getTabURL={(locale) => {
                if ((selectedTag.state === "PUBLISHED" && locale === "en") || selectedTag.translations[locale]) {
                  return clients.topicPage(selectedWebsite, selectedTag, locale);
                }
                return null;
              }}
            />
          </AppBar>
          <div className={classes.tagDetailsWrapper}>
            <div className={classes.metaDescriptionWrapper}>
              <MTextBox
                text={
                  defaultLocale
                    ? selectedTag.metaDescription
                    : selectedTag.translations[selectedLocale]?.metaDescription
                }
                handleTextChanged={(metaDescription) => {
                  if (defaultLocale) {
                    onTagUpdated({ ...selectedTag, metaDescription });
                    return;
                  }
                  onTagUpdated({
                    ...selectedTag,
                    translations: {
                      ...selectedTag.translations,
                      [selectedLocale]: {
                        ...(selectedTag.translations[selectedLocale] || {}),
                        metaDescription,
                      },
                    },
                  });
                }}
              />
            </div>
            <Paper elevation={0} className={classes.topicNameWrapper}>
              <Typography className={classes.topicName} color={"textPrimary"} variant={"subtitle1"}>
                {"Topic Name" || I18n.t("writers.answers.new_topic")}
              </Typography>
              <MTextField
                fullWidth
                disableUnderline={false}
                customClass={classes.nameField}
                value={defaultLocale ? selectedTag.name : selectedTag.translations[selectedLocale]?.name}
                placeholder={I18n.t("manage_content.tag_pages.name_ph")}
                onChange={(name) => {
                  if (defaultLocale) {
                    onTagUpdated({ ...selectedTag, name });
                    return;
                  }
                  onTagUpdated({
                    ...selectedTag,
                    translations: {
                      ...selectedTag.translations,
                      [selectedLocale]: {
                        ...(selectedTag.translations[selectedLocale] || {}),
                        name,
                      },
                    },
                  });
                }}
              />
            </Paper>
            <Paper elevation={0} className={classes.paper}>
              <RichTextEditor
                droppableId={"tag_page_editor"}
                key={`${selectedTag._id || selectedTag.title}_${selectedLocale}`}
                title={
                  defaultLocale
                    ? selectedTag.title || selectedTag.name
                    : selectedTag.translations[selectedLocale]?.title || selectedTag.translations[selectedLocale]?.name
                }
                titlePlaceHolder={I18n.t("manage_content.tag_pages.title_ph")}
                onTitleChanged={(title) => {
                  if (defaultLocale) {
                    onTagUpdated({ ...selectedTag, title });
                    return;
                  }
                  onTagUpdated({
                    ...selectedTag,
                    translations: {
                      ...selectedTag.translations,
                      [selectedLocale]: {
                        ...(selectedTag.translations[selectedLocale] || {}),
                        title,
                      },
                    },
                  });
                }}
                website={selectedWebsite}
                initialValue={
                  defaultLocale ? selectedTag.richText || "" : selectedTag.translations[selectedLocale]?.richText || ""
                }
                onValueChanged={(value, raw) => {
                  if (defaultLocale) {
                    onTagUpdated({
                      ...selectedTag,
                      richText: value,
                      text: richTextEditorHelper.serialize(JSON.parse(value)),
                    });
                    return;
                  }
                  onTagUpdated({
                    ...selectedTag,
                    translations: {
                      ...selectedTag.translations,
                      [selectedLocale]: {
                        ...(selectedTag.translations[selectedLocale] || {}),
                        richText: value,
                        text: richTextEditorHelper.serialize(JSON.parse(value)),
                      },
                    },
                  });
                }}
              />
            </Paper>
          </div>
        </div>
      )}
      {selectedTag && <TagPageBottomBar />}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TagDetails);
