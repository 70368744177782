import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldMaxLength from "../../../../../../../components/TextFieldMaxLength";
import {
  IWidgetFieldProperties,
  IWidgetInlineHTMLFieldProps,
} from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  input: {
    border: `1px solid ${palette.grey["100"]}`,
    width: "100%",
    overflow: "hidden",
    padding: "10px 15px",
    color: palette.text.primary,
    backgroundColor: palette.secondary.dark,
    fontWeight: typography.fontWeightLight,
    fontSize: 14,
    borderRadius: 3,
    minHeight: 70,
  },
  length: {
    color: palette.text.secondary,
  },
}));

type PropTypes = {
  properties: IWidgetFieldProperties;
  fieldType: string;
  onChange: (properties: IWidgetInlineHTMLFieldProps) => void;
};

const InlineHTMLWidgetItem = (props: PropTypes) => {
  const { onChange, properties, fieldType } = props;
  const { value = "" } = properties as IWidgetInlineHTMLFieldProps;

  const classes = useStyles();

  return (
    <TextFieldMaxLength
      title={I18n.t(`widgets_builder.widget_item_selector.${fieldType}`)}
      handleValueChange={(newValue: string) => onChange({ ...properties, value: newValue })}
      value={value}
      placeholder={I18n.t("widgets_builder.widget_editor.fields.default_input_placeholder")}
      multiLine={true}
      titleAndLengthLocation="top"
      inputClassName={classes.input}
      lengthClassName={classes.length}
      alignTitle="end"
      justifyTitle="space-between"
    />
  );
};

export default InlineHTMLWidgetItem;
