import React from "react";
import { Theme } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import agent from "../../../agent";
import DownChevronIcon from "../../../icons/DownChevronIcon";
import { IAdminUser } from "../../../reducers/constants/objectTypes";
import WebsitesDropdown from "./WebsitesDrodown";
import MAddToSiteButton from "../../../components/MAddToSiteButton";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    padding: 0,
  },
  dropdown: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#333333",
  },
  name: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: 12,
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    padding: 5,
  },
  dropDownName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  websiteName: {
    marginRight: 10,
    fontWeight: theme.typography.fontWeightRegular as any,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  dropdownRole: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightLight as any,
  },
  linksWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  link: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    padding: 5,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  menu: {
    width: 320,
    // maxHeight: 580,
    fontSize: 14,
    lineHeight: 17,
    fontWeight: theme.typography.fontWeightRegular as any,
    borderRadius: 5,
    border: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "4px 4px 22px 0px rgba(0, 0, 0, 0.25)",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    left: "-30px !important",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  organizationSection: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 15px",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  middleSection: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 15px",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  bottomSection: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 15px",
  },
}));

type PropTypes = {
  admin: IAdminUser;

  subLogin?: boolean;
  removeSubUser: () => void;
};

const UserDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { admin, subLogin, removeSubUser } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const history = useHistory();

  const { selectedWebsite } = useSelector((state) => ({
    selectedWebsite: state.home.selectedWebsite,
  }));

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = () => {
    if (subLogin) {
      agent.Auth.logoutSubUser()
        .then((res) => {
          removeSubUser();
          document.location.reload();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      agent.Auth.logout()
        .then(() => {
          history.push("/login");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleClose = (event: React.MouseEvent<EventTarget>, selectedItem = null) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    if (selectedItem) {
      // onItemSelected(selectedItem.key);
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div
        className={classes.dropdown}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        ref={anchorRef}
      >
        <div>
          <Typography variant={"subtitle2"} className={classes.name}>
            {admin.name}
          </Typography>
          <Typography variant={"subtitle2"} className={classes.websiteName}>
            {selectedWebsite.name}
          </Typography>
        </div>

        <DownChevronIcon />
      </div>

      <Popper
        open={open}
        className={classes.menu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <div className={classes.section}>
              <Typography variant={"subtitle2"} className={classes.dropDownName}>
                {admin.name}
              </Typography>
              <Typography variant={"subtitle2"} className={classes.dropdownRole}>
                {I18n.t(`snackbar.user.role.${admin.role}`)}
              </Typography>
            </div>
            <WebsitesDropdown handleClose={() => setOpen(false)} />
            {/* hide for now */}

            {/* <div className={classes.organizationSection}> */}
            {/*  <Typography variant={"subtitle2"} className={classes.sectionTitle}> */}
            {/*    {I18n.t("main.user_dropdown.organization")} */}
            {/*  </Typography> */}
            {/*  <div className={classes.linksWrapper}> */}
            {/*    <Typography variant={"body1"} className={classes.link}> */}
            {/*      {I18n.t("main.user_dropdown.settings")} */}
            {/*    </Typography> */}
            {/*    <Typography variant={"body1"} className={classes.link}> */}
            {/*      {I18n.t("main.user_dropdown.invite_users")} */}
            {/*    </Typography> */}
            {/*  </div> */}
            {/* </div> */}
            <div className={classes.middleSection}>
              <MAddToSiteButton outlined className={classes.link} showAlways handleOnClick={() => setOpen(false)} />
            </div>

            <div className={classes.bottomSection}>
              <Typography
                variant={"body1"}
                className={classes.link}
                onKeyDown={handleListKeyDown}
                onClick={handleLogout}
              >
                {subLogin ? "Logout from user" : "Logout"}
              </Typography>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default UserDialog;
