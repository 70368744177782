import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import SelectRow from "../../components/SelectRow";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import { IWebsiteStaticPageProps } from "../../../../reducers/constants/objectTypes";
import MRouterPrompt from "../../components/MRouterPrompt";
import PageSection from "../../components/PageSection";
import CustomUrlPrefixTextField from "../../components/CustomUrlPrefixTextField";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
    width: "100%",
  },
  inputColumnWrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 30,
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    marginTop: 40,
    "&:hover": {
      cursor: "pointer",
    },
  },
  empty: {
    width: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

const mapStateToProps = (state) => ({
  staticPages: state.websitePages.staticPages,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (staticPages) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "staticPages",
      value: staticPages,
    }),
});

type PropTypes = {
  staticPages: IWebsiteStaticPageProps[];
  onPropertyChanged?: (staticPages) => void;
};

const StaticPages = (props: PropTypes) => {
  const classes = useStyles(props);
  const { staticPages = [], onPropertyChanged } = props;

  const sitemapOptions = ["Yes", "No"];

  return (
    <>
      <MRouterPrompt />

      <PageSection sectionTitle={I18n.t(`website.page_types.staticPages`)}>
        {[...(staticPages || []), { name: "", sitemap: false, url: "", type: "", path: "", urlPrefix: "" }].map(
          (sp, index) => (
            <div key={`static_page_${index}`} className={classes.rowWrapper}>
              <div className={classes.inputColumnWrapper}>
                <div className={classes.inputRowWrapper}>
                  <TextFieldMaxLength
                    titleAndLengthLocation={"top"}
                    value={sp.name}
                    placeholder={I18n.t("website.static_page.name_ph")}
                    title={I18n.t("website.static_page.name")}
                    height={37}
                    borderRadius={4}
                    marginBottom={10}
                    handleValueChange={(name) => {
                      const nStaticPages = [...staticPages];
                      if (index < nStaticPages.length) {
                        nStaticPages[index] = { ...sp, name };
                        onPropertyChanged(nStaticPages);
                        return;
                      }
                      nStaticPages.push({ ...sp, name });
                      onPropertyChanged(nStaticPages);
                    }}
                  />
                  <TextFieldMaxLength
                    titleAndLengthLocation={"top"}
                    value={sp.url}
                    placeholder={I18n.t("website.static_page.url_title_ph")}
                    title={I18n.t("website.static_page.url_title")}
                    height={37}
                    borderRadius={4}
                    marginBottom={10}
                    handleValueChange={(url) => {
                      const nStaticPages = [...staticPages];
                      if (index < nStaticPages.length) {
                        nStaticPages[index] = { ...sp, url };
                        onPropertyChanged(nStaticPages);
                        return;
                      }
                      nStaticPages.push({ ...sp, url });
                      onPropertyChanged(nStaticPages);
                    }}
                  />
                  <TextFieldMaxLength
                    titleAndLengthLocation={"top"}
                    value={sp.path}
                    placeholder={I18n.t("website.static_page.path_ph")}
                    title={I18n.t("website.static_page.path")}
                    height={37}
                    borderRadius={4}
                    marginBottom={10}
                    handleValueChange={(path) => {
                      const nStaticPages = [...staticPages];
                      if (index < nStaticPages.length) {
                        nStaticPages[index] = { ...sp, path };
                        onPropertyChanged(nStaticPages);
                        return;
                      }
                      nStaticPages.push({ ...sp, path });
                      onPropertyChanged(nStaticPages);
                    }}
                  />
                  <SelectRow
                    bold
                    flexDirection={"column"}
                    rowTitle={I18n.t("website.static_page.add_to_sitemap")}
                    value={sp.sitemap ? "Yes" : "No"}
                    selectOptions={sitemapOptions}
                    handleChange={(sitemapOption) => {
                      const nStaticPages = [...staticPages];
                      if (index < nStaticPages.length) {
                        nStaticPages[index] = { ...sp, sitemap: sitemapOption === "Yes" };
                        onPropertyChanged(nStaticPages);
                        return;
                      }
                      nStaticPages.push({ ...sp, sitemap: sitemapOption === "Yes" });
                      onPropertyChanged(nStaticPages);
                    }}
                  />
                </div>
                <CustomUrlPrefixTextField
                  customUrlPrefix={sp.urlPrefix}
                  handleValueChange={(value) => {
                    const nStaticPages = [...staticPages];
                    if (index < nStaticPages.length) {
                      nStaticPages[index] = { ...sp, urlPrefix: value };
                      onPropertyChanged(nStaticPages);
                      return;
                    }
                    nStaticPages.push({ ...sp, urlPrefix: value });
                    onPropertyChanged(nStaticPages);
                  }}
                />
              </div>

              {index < staticPages.length ? (
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => {
                    const nStaticPages = [...staticPages];
                    nStaticPages.splice(index, 1);
                    onPropertyChanged([...nStaticPages]);
                  }}
                />
              ) : (
                <div className={classes.empty} />
              )}
            </div>
          )
        )}
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticPages);
