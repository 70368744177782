import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { ILandingPage, IWebsite } from "../../reducers/constants/objectTypes";
import LandingPagesList from "./components/LandingPagesList/LandingPagesList";
import { LANDING_PAGES } from "../../reducers/constants/actionTypes";
import agent from "../../agent";
import MTableWrapper from "../../components/Table/MTableWrapper";
import MTableToolbar from "../../components/Table/Toolbar/MTableToolbar";
import CreateLandingPageDialog from "./components/LandingPage/CreateLandingPageDialog";
import MTableToolbarBaseActionButton from "../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import MPagination from "../../components/MPagination";
import MHeaderMetaTags from "../../components/MHeaderMetaTags";

type PropTypes = {
  selectedWebsite: IWebsite;
  landingPages: ILandingPage[];
  landingPagesLoaded: boolean;

  onLandingPagesLoaded: (landingPages: ILandingPage[]) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  landingPages: state.landingPages.landingPages,
  landingPagesLoaded: state.landingPages.landingPagesLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  onLandingPagesLoaded: (payload) => {
    dispatch({ type: LANDING_PAGES.LANDING_PAGES_LOADED, payload });
  },
});

const LandingPages = (props: PropTypes) => {
  const { selectedWebsite, landingPages, landingPagesLoaded, onLandingPagesLoaded } = props;
  const [searchText, setSearchText] = useState(null);
  const [pagination, setPagination] = useState({ page: 1, limit: 20 });
  const [totalPages, setTotalPages] = useState(1);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

  async function loadLandingPages() {
    try {
      const res = await agent.LandingPages.getAll(selectedWebsite._id, pagination.page, pagination.limit, searchText);
      onLandingPagesLoaded(res);
      setTotalPages(res.totalLandingPages);
    } catch (e) {
      console.log("error", e);
    }
  }

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    loadLandingPages();
  }, [selectedWebsite, pagination, searchText]);

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.page_builder.landing_pages.title`)} />
      <MTableToolbar
        searchText={searchText}
        handleSearchTextChanged={(text) => setSearchText(text)}
        actions={[
          <MTableToolbarBaseActionButton
            handleClick={() => {
              setCreateDialogOpen(true);
            }}
            text={I18n.t("landing_pages.dialog.create")}
          />,
        ]}
      />
      <LandingPagesList
        landingPages={landingPages}
        handleRefreshList={() => loadLandingPages()}
        landingPagesLoaded={landingPagesLoaded}
      />
      {totalPages > 0 && (
        <MPagination
          paginationLimit={pagination.limit}
          page={pagination.page}
          paginationPagesCount={totalPages}
          setPaginationLimit={(limit) => setPagination({ ...pagination, limit })}
          setPage={(page) => setPagination({ ...pagination, page })}
        />
      )}
      {createDialogOpen && (
        <CreateLandingPageDialog
          open
          selectedWebsite={selectedWebsite}
          handleExitClick={() => {
            setCreateDialogOpen(false);
            loadLandingPages();
          }}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPages);
