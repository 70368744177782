import * as React from "react";
import { Editor, Path, Transforms } from "slate";
import { ReactEditor, RenderElementProps, useSlateStatic } from "slate-react";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import LaunchIcon from "@material-ui/icons/Launch";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import { Theme } from "@material-ui/core";

import richTextEditorHelper from "../../helper/richTextEditorHelper";
import { Widget } from "../widgets/WithWidgets";
import MIFrameWrapper from "../../../components/MIFrameWrapper";
import MDangerouslySetHtmlContent from "../../../components/MDangerouslySetHtmlContent";
import { editorType, EntailWidget } from "../../types/editor.Types";
import { DEV } from "../../../reducers/constants/consts";
import { web } from "../../../helpers/urlHelper";
import CfProgressBar from "../../../components/CfProgressBar";
import widgetsAgent from "../../../widgetsAgent";
import { IWebsite } from "../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  container: (props: { height: number }) => ({
    background: "white",
    width: "100%",
    height: props.height || 200,
  }),
  iframeWrapper: {
    display: "block",
    width: "100%",
    height: "100%",
    border: "none",
    background: "transparent",
  },
  iconButton: {
    padding: 9.5,
    borderRadius: 0,
  },
  icon: {
    fontSize: 15,
    fill: theme.palette.primary.main,
    "&:hover": {
      fill: theme.palette.primary.light,
    },
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    justifySelf: "center",
    position: "relative",
    top: "50%",
  },
}));

// UI Components
export const EntailWidgetPreview = ({
  attributes,
  children,
  element,
  website,
}: RenderElementProps & { element: EntailWidget; website: IWebsite }) => {
  const [html, setHtml] = React.useState(null);
  const editor = useSlateStatic();
  const [refresh, setRefresh] = React.useState(false);
  const [iframeHeight, setIframeHeight] = React.useState(100);
  const classes = useStyles({ height: iframeHeight });

  const loadWidget = React.useCallback(
    () =>
      widgetsAgent.Widgets.editorPreview(element?.data.widget.uploadedId, website.host)
        .then((newHtml) => setHtml(newHtml))
        .catch((e) => console.log(e, e.message)),
    [element?.data.widget?.uploadedId, website]
  );

  const refreshWidget = () => {
    if (!refresh) {
      setRefresh(true);
      loadWidget()
        .then((value) => setRefresh(false))
        .catch(() => setRefresh(false));
    }
  };

  React.useEffect(() => {
    console.log("updated", element?.data?.widget?.uploadedId);
    if (!element?.data?.widget?.uploadedId) {
      return;
    }
    loadWidget();
  }, [element?.data]);

  return (
    <Widget
      title={I18n.t(`rich_text_editor.entail_widget`)}
      element={element}
      customButtons={
        element?.data?.widget?.id && (
          <>
            <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.tooltips.refresh")}>
              <IconButton className={classes.iconButton} disableRipple onClick={refreshWidget}>
                <RefreshIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip enterDelay={1000} title={I18n.t("rich_text_editor.tooltips.launch")}>
              <IconButton
                target={"_blank"}
                href={web.widgetsEditor(element?.data?.widget?.id)}
                className={classes.iconButton}
                disableRipple
              >
                <LaunchIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </>
        )
      }
      contentEditable
      editable
      handleDeleteClick={() => {
        const path = ReactEditor.findPath(editor, element);
        Transforms.removeNodes(editor, { at: path });
      }}
    >
      <div className={classes.container} {...attributes}>
        {!refresh && html && (
          <MIFrameWrapper
            allow={"autoplay; fullscreen"}
            className={classes.iframeWrapper}
            onHeightChange={(height) => height && setIframeHeight(height)}
          >
            <MDangerouslySetHtmlContent html={html} />
          </MIFrameWrapper>
        )}
        {refresh && (
          <div className={classes.loader}>
            <CfProgressBar />
          </div>
        )}
        {children}
      </div>
    </Widget>
  );
};

export const insertEntailWidget = (editor: Editor, widgetId: string, at: Path) => {
  richTextEditorHelper.insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading(
    editor,
    [
      {
        type: editorType.entailWidget,
        data: { uuid: richTextEditorHelper.getUuid(), widget: null },
        children: [{ text: "" }],
      },
    ],
    at
  );
  const layoutEntry = Editor.above(editor, { match: (x) => x.type === "entail-widget" });
  if (layoutEntry) {
    Transforms.select(editor, [...layoutEntry[1], 0]);
  } else {
    Transforms.select(editor, at);
  }
  ReactEditor.focus(editor as ReactEditor);
};

// Plugin
export function withEntailWidgets(editor: Editor): Editor {
  const { isVoid } = editor;

  editor.isVoid = (element) => (element.type === "entail-widget" ? true : isVoid(element));

  return editor;
}
