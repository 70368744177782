import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { IWebsite, IWebsiteHomepageProps, IWebsiteThemeProps } from "../../../../../reducers/constants/objectTypes";
import ButtonTextAndUrlAndDesign from "../../../components/ButtonTextAndUrlAndDesign";
import TextInputToolAndDesign from "../../../components/TextInputToolAndDesign";
import ImagePicker from "../../../components/ImagePicker";
import GradientBackgroundColorCSS from "../../../../../editor/elementsStyles/CssComponents/GradientBackgroundColorCSS";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    fontSize: theme.typography.pxToRem(12),
    color: "red",
    marginBottom: 10,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  idsColumns: {
    display: "flex",
    justifyContent: "space-between",
  },
  ids: {
    width: 300,
    marginBottom: 40,
  },
  bannerWrap: {
    border: `solid 1px ${theme.palette.divider}`,
    padding: 10,
    marginBottom: 10,
  },
  closableRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  bannersTitle: {
    marginBottom: 20,
  },
  bannerTitle: {},
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  closeIcon: {
    cursor: "pointer",
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  hidden: {
    visibility: "hidden",
  },
  imageTitle: {
    marginBottom: 15,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

type PropTypes = {
  error: boolean;
  selectedWebsite: IWebsite;
  homepage: IWebsiteHomepageProps;
  theme: IWebsiteThemeProps;
  onPropertyChanged: (value) => void;
};

const BannersInput = (props: PropTypes) => {
  const classes = useStyles(props);
  const { error, selectedWebsite, homepage, theme, onPropertyChanged } = props;

  return (
    <>
      <Typography variant={"h4"} className={classes.bannersTitle}>
        {I18n.t("website.homepage.templates.keepshoppers.top_section.banners")}
      </Typography>

      {error && (
        <Typography paragraph className={classes.error}>
          {I18n.t("website.homepage.templates.keepshoppers.top_section.banners_error")}
        </Typography>
      )}

      <div>
        {[
          ...(homepage?.topSectionKeepshoppers?.banners || []),
          {
            title: { text: "", css: {} },
            description: { text: "", css: {} },
            background: null,
            button: { text: "", url: "", css: {} },
            image: null,
          },
        ].map((item, index) => (
          <div key={`${item.title}_${index}`} className={classes.bannerWrap}>
            <div className={classes.closableRow}>
              <Typography className={classes.bannerTitle}>
                {I18n.t("website.homepage.templates.keepshoppers.top_section.banner", { num: index + 1 })}
              </Typography>
              <CloseIcon
                className={ClassNames(
                  classes.closeIcon,
                  (homepage?.topSectionKeepshoppers?.banners || []).length === index && classes.hidden
                )}
                onClick={() => {
                  const updatedBanners = [...homepage.topSectionKeepshoppers.banners].filter((x, i) => index !== i);
                  onPropertyChanged({
                    ...homepage,
                    topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                  });
                }}
              />
            </div>
            <div className={classes.container}>
              <TextInputToolAndDesign
                background={item.background?.style}
                textFieldTitle={I18n.t("website.homepage.templates.keepshoppers.top_section.banner_title")}
                text={item.title}
                handleTextChanged={(value) => {
                  if ((homepage?.topSectionKeepshoppers?.banners || []).length === index) {
                    const updatedBanners = [
                      ...(homepage?.topSectionKeepshoppers?.banners || []),
                      {
                        title: value,
                        description: item.description,
                        background: item.background,
                        button: item.button,
                        image: item.image,
                      },
                    ];
                    onPropertyChanged({
                      ...homepage,
                      topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                    });
                    return;
                  }
                  const updatedBanners = [...(homepage?.topSectionKeepshoppers?.banners || [])];
                  updatedBanners[index].title = value;
                  onPropertyChanged({
                    ...homepage,
                    topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                  });
                }}
                variant={"h4"}
              />

              <TextInputToolAndDesign
                textFieldTitle={I18n.t("website.homepage.templates.keepshoppers.top_section.banner_description")}
                text={item.description}
                background={item.background?.style}
                handleTextChanged={(value) => {
                  if ((homepage?.topSectionKeepshoppers?.banners || []).length === index) {
                    const updatedBanners = [
                      ...(homepage?.topSectionKeepshoppers?.banners || []),
                      {
                        title: item.title,
                        description: value,
                        background: item.background,
                        button: item.button,
                        image: item.image,
                      },
                    ];
                    onPropertyChanged({
                      ...homepage,
                      topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                    });
                    return;
                  }
                  const updatedBanners = [...(homepage?.topSectionKeepshoppers?.banners || [])];
                  updatedBanners[index].description = value;
                  onPropertyChanged({
                    ...homepage,
                    topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                  });
                }}
                variant={"h4"}
              />
            </div>

            <GradientBackgroundColorCSS
              value={item.background}
              title={"Banner Background"}
              defaultValue={null}
              isValueChanged={item.background !== null}
              handleChange={(value) => {
                if ((homepage?.topSectionKeepshoppers?.banners || []).length === index) {
                  const updatedBanners = [
                    ...(homepage?.topSectionKeepshoppers?.banners || []),
                    {
                      title: item.title,
                      description: item.description,
                      background: value,
                      button: item.button,
                      image: item.image,
                    },
                  ];
                  onPropertyChanged({
                    ...homepage,
                    topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                  });
                  return;
                }
                const updatedBanners = [...(homepage?.topSectionKeepshoppers?.banners || [])];
                updatedBanners[index].background = value;
                onPropertyChanged({
                  ...homepage,
                  topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                });
              }}
            />

            <ButtonTextAndUrlAndDesign
              background={item.background?.style}
              textFieldTitle={I18n.t("website.homepage.templates.keepshoppers.top_section.banner_button_text")}
              urlFieldTitle={I18n.t("website.homepage.templates.keepshoppers.top_section.banner_button_url")}
              button={item.button || { text: "", url: "", css: {} }}
              defaultButtonCss={theme?.buttons?.css}
              handleButtonChanged={(value) => {
                if ((homepage?.topSectionKeepshoppers?.banners || []).length === index) {
                  const updatedBanners = [
                    ...(homepage?.topSectionKeepshoppers?.banners || []),
                    {
                      title: item.title,
                      description: item.description,
                      background: item.background,
                      button: value,
                      image: item.image,
                    },
                  ];
                  onPropertyChanged({
                    ...homepage,
                    topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                  });
                  return;
                }
                const updatedBanners = [...(homepage?.topSectionKeepshoppers?.banners || [])];
                updatedBanners[index].button = value;
                onPropertyChanged({
                  ...homepage,
                  topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                });
              }}
            />

            <Typography variant={"subtitle2"} className={classes.imageTitle}>
              {I18n.t("website.homepage.templates.keepshoppers.top_section.banner_image")}
            </Typography>
            <ImagePicker
              selectedWebsite={selectedWebsite}
              image={item.image}
              objectFit={"contain"}
              imageHeight={"100px"}
              imageWidth={"180px"}
              onImageChanged={(value) => {
                if ((homepage?.topSectionKeepshoppers?.banners || []).length === index) {
                  const updatedBanners = [
                    ...(homepage?.topSectionKeepshoppers?.banners || []),
                    {
                      title: item.title,
                      description: item.description,
                      background: item.background,
                      button: item.button,
                      image: value,
                    },
                  ];
                  onPropertyChanged({
                    ...homepage,
                    topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                  });
                  return;
                }
                const updatedBanners = [...(homepage?.topSectionKeepshoppers?.banners || [])];
                updatedBanners[index].image = value;
                onPropertyChanged({
                  ...homepage,
                  topSectionKeepshoppers: { ...homepage?.topSectionKeepshoppers, banners: updatedBanners },
                });
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BannersInput);
