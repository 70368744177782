import { KEYWORD_STRATEGY } from "./constants/actionTypes";

const initialState = {
  dataList: [],
  dataLoading: false,
  selectedDataItem: null,
  selectedDataItemsIndexes: [],
  selectedStatus: null,
  rankingPages: [],
  broadKeywords: [],
  serps: [],
  serpsLoading: false,
  total: 0,
  sort: {
    direction: "desc",
    field: "submitted_at",
  },
  filter: {
    searchText: "",
    post_types: [],
    status: [],
    pages: [],
    clicks: [],
    volume: [],
    position: [],
    exclude: [],
    include: [],
    startTime: null,
    sortField: {
      direction: "desc",
      field: "potential_value",
    },
  },
  refresh: false,
  limit: 200,
  page: 1,
  isDialogOpen: false,
  activeDialogTab: "",
  selectedColumns: [
    "suggested_title",
    "feedback",
    "status",
    "conversion_potential",
    "post_type",
    "pages",
    "clicks",
    "volume",
    "variations",
    "reviewed_at",
    "added_at",
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_LOADED:
      return {
        ...state,
        dataList: action.payload?.data,
        total: action.payload?.pagination?.totalCount,
        dataLoading: false,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_LOADING:
      return {
        ...state,
        dataLoading: true,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_POST_TYPE_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          post_types: action.payload,
        },
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_STATUS_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.payload,
        },
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_EXCLUDE_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          exclude: action.payload,
        },
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_INCLUDE_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          include: action.payload,
        },
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_START_TIME_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          startTime: action.payload,
        },
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SELECTED_COLUMNS_CHANGED:
      return {
        ...state,
        selectedColumns: action.payload,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_PAGE_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          pages: action.payload,
        },
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_POSITION_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          position: action.payload,
        },
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_CLICKS_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          clicks: action.payload,
        },
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_VOLUME_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          volume: action.payload,
        },
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SEARCH_TEXT_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          search: action.payload,
        },
        page: 1,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SERPS_LOADING:
      return {
        ...state,
        serpsLoading: true,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SERPS_LOADED:
      return {
        ...state,
        serps: action.payload.data,
        serpsLoading: false,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_IS_DIALOG_OPEN_CHANGED:
      return {
        ...state,
        isDialogOpen: action.payload,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_ACTIVE_DIALOG_TAB_CHANGED:
      return {
        ...state,
        activeDialogTab: action.payload,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SORT_CHANGED:
      return {
        ...state,
        sort: action.payload,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SELECTED_DATA_ITEM_CHANGED:
      return {
        ...state,
        selectedDataItem: action.payload,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_DATA_SELECTED_ITEMS_CHANGED:
      return {
        ...state,
        selectedDataItemsIndexes: action.payload,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_REFETCH_CHANGED:
      return {
        ...state,
        refetch: action.payload,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_PAGE_CHANGED:
      return {
        ...state,
        page: action.payload,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_LIMIT_CHANGED:
      return {
        ...state,
        limit: action.payload,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_BROAD_KEYWORDS_LOADED:
      return {
        ...state,
        broadKeywords: action.payload.data.keywords,
        pages: action.payload.data.pages,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_SELECTED_STATUS_CHANGED:
      return {
        ...state,
        selectedStatus: action.payload,
      };
    case KEYWORD_STRATEGY.ON_KEYWORD_STRATEGY_UPDATE_ITEM:
      return {
        ...state,
        dataList: state.dataList.map((item) =>
          item.keyword === action.payload.keyword ? { ...item, ...action.payload } : item
        ),
      };
    default:
      return state;
  }
};
