import { CONTENT_GAPS } from "./constants/actionTypes";

const initialState = {
  dataList: [],
  dataLoading: false,
  selectedDataItemsIndexes: [],
  selectedDataItem: null,
  total: 0,
  sort: {
    direction: "desc",
    field: "value_difference",
  },
  filter: {
    search: "",
    funnelStage: [],
    pages: [],
    status: [],
    keywords: [],
    impressions: [],
    volume: [],
    exclude: [],
    include: [],
    clicks: [],
    position: [],
    sortField: {
      direction: "desc",
      field: "value_difference",
    },
  },
  refresh: false,
  refetch: false,
  isDialogOpen: false,
  activeDialogTab: "",
  limit: 100,
  page: 1,
  selectedColumns: [],
  pages: [],
  broadKeywords: [],
  serps: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTENT_GAPS.ON_CONTENT_GAPS_BROAD_KEYWORDS_LOADED:
      return {
        ...state,
        broadKeywords: action.payload.data.keywords,
        pages: action.payload.data.pages,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_DATA_LOADED:
      return {
        ...state,
        dataList: action.payload?.data,
        total: action.payload?.pagination?.totalCount,
        dataLoading: false,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_SERPS_LOADED:
      return {
        ...state,
        serps: action.payload.data,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_DATA_LOADING:
      return {
        ...state,
        dataLoading: true,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_IS_DIALOG_OPEN_CHANGED:
      return {
        ...state,
        isDialogOpen: action.payload,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_ACTIVE_DIALOG_TAB_CHANGED:
      return {
        ...state,
        activeDialogTab: action.payload,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_FUNNEL_STAGE_FILTER_CHANGE:
      return {
        ...state,
        filter: {
          ...state.filter,
          funnelStage: action.payload,
        },
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_STATUS_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.payload,
        },
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_EXCLUDE_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          exclude: action.payload,
        },
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_INCLUDE_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          include: action.payload,
        },
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_IMPRESSIONS_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          impressions: action.payload,
        },
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_SELECTED_COLUMNS_CHANGED:
      return {
        ...state,
        selectedColumns: action.payload,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_PAGE_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          pages: action.payload,
        },
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_CLICKS_FILTER_CHANGE:
      return {
        ...state,
        filter: {
          ...state.filter,
          clicks: action.payload,
        },
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_POSITION_FILTER_CHANGE:
      return {
        ...state,
        filter: {
          ...state.filter,
          position: action.payload,
        },
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_KEYWORDS_FILTER_CHANGE:
      return {
        ...state,
        filter: {
          ...state.filter,
          keywords: action.payload,
        },
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_VOLUME_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          volume: action.payload,
        },
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_SEARCH_TEXT_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          search: action.payload,
        },
        page: 1,
      };

    case CONTENT_GAPS.ON_CONTENT_GAPS_SORT_CHANGED:
      return {
        ...state,
        sort: action.payload,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_SELECTED_DATA_ITEM_CHANGED:
      return {
        ...state,
        selectedDataItem: action.payload,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_DATA_SELECTED_ITEMS_CHANGED:
      return {
        ...state,
        selectedDataItemsIndexes: action.payload,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_REFETCH_CHANGED:
      return {
        ...state,
        refetch: action.payload,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_PAGE_CHANGED:
      return {
        ...state,
        page: action.payload,
      };
    case CONTENT_GAPS.ON_CONTENT_GAPS_LIMIT_CHANGED:
      return {
        ...state,
        limit: action.payload,
      };

    case CONTENT_GAPS.ON_CONTENT_GAPS_FILTER_CHANGED:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
