import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 14,
    width: 15,
  },
}));

type PropTypes = {
  className?: string;
};

const ExpandIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 14 15"}>
      <path
        d="M0.850685 14.5C0.609664 14.5 0.407632 14.4185 0.244589 14.2554C0.0815294 14.0924 0 13.8903 0 13.6493V9.14704C0 8.94705 0.0676786 8.77942 0.203036 8.64412C0.338393 8.50883 0.506111 8.44118 0.70619 8.44118C0.906285 8.44118 1.0739 8.50883 1.20904 8.64412C1.34418 8.77942 1.41175 8.94705 1.41175 9.14704V12.1055L11.6055 1.91175H8.64704C8.44705 1.91175 8.27942 1.84407 8.14412 1.70871C8.00883 1.57337 7.94118 1.40565 7.94118 1.20556C7.94118 1.00548 8.00883 0.837868 8.14412 0.702731C8.27942 0.567577 8.44705 0.5 8.64704 0.5H13.1493C13.3903 0.5 13.5924 0.58153 13.7554 0.744589C13.9185 0.907633 14 1.10967 14 1.35069V5.85296C14 6.05294 13.9323 6.22058 13.797 6.35588C13.6616 6.49117 13.4939 6.55882 13.2938 6.55882C13.0937 6.55882 12.9261 6.49117 12.791 6.35588C12.6558 6.22058 12.5883 6.05294 12.5883 5.85296V2.89453L2.39452 13.0883H5.35296C5.55294 13.0883 5.72058 13.1559 5.85588 13.2913C5.99117 13.4266 6.05882 13.5944 6.05882 13.7944C6.05882 13.9945 5.99117 14.1621 5.85588 14.2973C5.72058 14.4324 5.55294 14.5 5.35296 14.5H0.850685Z"
        fill="#333333"
      />
    </SvgIcon>
  );
};

export default ExpandIcon;
