import * as React from "react";
import { I18n } from "react-redux-i18n";
import numeral from "numeral";
import MTableHeader from "../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../../components/Table/Table/MTableHeaderSortLabel";
import MTableHeaderLabelWithSelectAll from "../../../components/Table/Table/MTableHeaderLabelWithSelectAll";

type PropTypes = {
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  setSortField?: (input: { field: string; direction: "asc" | "desc" }) => void;
  selectedNr: number;
  clearSelected(): void;
  selectAll(): void;
  total: number;
};

const KeywordGroupPageColumns = (props: PropTypes) => {
  const { total, selectedNr, clearSelected, selectAll } = props;
  return (
    <MTableHeader>
      <MTableHeaderLabelWithSelectAll
        selected={selectedNr > 0}
        onSelectAll={selectAll}
        onClearAll={clearSelected}
        columnWidth={"40%"}
      >
        {numeral(total).format("0,0a")}{" "}
        {total !== 1 ? I18n.t("strategy.keywordGroupPages.pages") : I18n.t("strategy.keywordGroupPages.page")}
      </MTableHeaderLabelWithSelectAll>
      <MTableHeaderLabel> {I18n.t("strategy.strategyKeywords.pageColumns.ranking")}</MTableHeaderLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"impressions"}
      >
        {I18n.t("strategy.strategyKeywords.pageColumns.impressions")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"clicks"}
      >
        {I18n.t("strategy.strategyKeywords.pageColumns.traffic")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel onClick={(result) => props.setSortField(result)} sortField={props.sortField} field={"ctr"}>
        {I18n.t("strategy.strategyKeywords.pageColumns.ctr")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"position"}
      >
        {I18n.t("strategy.strategyKeywords.pageColumns.position")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"volume"}
      >
        {I18n.t("strategy.strategyKeywords.pageColumns.volume")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"potential_value"}
      >
        {I18n.t("strategy.strategyKeywords.pageColumns.potential_value")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"value"}
      >
        {I18n.t("strategy.strategyKeywords.pageColumns.value")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"value_delta"}
      >
        {I18n.t("strategy.strategyKeywords.pageColumns.value_diff")}
      </MTableHeaderSortLabel>
      <MTableHeaderSortLabel
        onClick={(result) => props.setSortField(result)}
        sortField={props.sortField}
        field={"conversion_potential"}
      >
        {I18n.t("strategy.strategyKeywords.pageColumns.funnel_stage")}
      </MTableHeaderSortLabel>
    </MTableHeader>
  );
};

export default KeywordGroupPageColumns;
