import React from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { push } from "connected-react-router";
import { I18n } from "react-redux-i18n";
import OutlineDialogHeader from "./OutlineDialogHeader";
import KeywordDialogList from "./KeywordsDialog/KeywordDialogList";
import OutlinesDialogList from "./OutlinesDialog/OutlinesDialogList";
import PagesDialogList from "./PagesDialog/PagesDialogList";
import MButtonGroup from "../../../components/MButtonGroup";
import { OUTLINE_PAGES } from "../../../reducers/constants/actionTypes";
import { IAutoOutlineWithStrategy, IWebsite, ePageType } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import { getAvailablePageTypes } from "../../../helpers/websiteHelper";
import SerpDialogList from "../../Data/OutlinePages/OutlineDialog/SerpDialog/SerpsDialogList";
import BriefSerpsDialogList from "../BriefSerpDialog/BriefSerpsDialogList";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    padding: 0,
    "& .MuiDialogContent-root": {
      padding: 30,
    },
  },
  dialogContent: {
    overflowY: "hidden",
  },
  scrollPaper: {
    maxHeight: "100%",
  },
  list: {
    padding: "0",
  },
  listItems: {
    overflowY: "auto",
    height: "calc(100vh - 246px)",
  },
  footer: {
    borderTop: `${theme.palette.divider} solid 1px`,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    marginLeft: "auto",
  },
}));

const mapStateToProps = (state) => ({
  outline: state.outlinePages.outline,
  selectedWebsite: state.home.selectedWebsite,
  total: state.outlinePages.total,
  limit: state.outlinePages.limit,
  page: state.outlinePages.page,
});

const mapDispatch = (dispatch) => ({
  redirect: (url) => dispatch(push(url)),
  handleOutlinesLoaded: (payload) => dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGES_LOADED, payload }),
  handleOutlineSelected: (outline: IAutoOutlineWithStrategy) =>
    dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGE_SELECTED, outline }),
});

type PropTypes = {
  isStrategy?: boolean;
  disableActions?: boolean;
  outline: IAutoOutlineWithStrategy;
  total: number;
  limit: number;
  page: number;
  open: boolean;
  redirect: (url: string) => void;
  selectedWebsite: IWebsite;
  pageTab: "outlines" | "keywords" | "broadKeywords" | "pages" | "serps";
  setPageTab: (page: "outlines" | "keywords" | "broadKeywords") => void;
  close: () => void;
  handleOutlineSelected: (outline: IAutoOutlineWithStrategy) => any;
  handleOutlinesLoaded: (payload: any) => any;
  setRefetch: (refetch: boolean) => void;
  refetch: boolean;
};

const OutlinePageDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();

  const [hideCreateBtn, setHideCreateBtn] = React.useState(false);
  const {
    outline,
    close,
    open,
    setPageTab,
    pageTab,
    handleOutlineSelected,
    selectedWebsite,
    redirect,
    refetch,
    setRefetch,
  } = props;

  const onOutlineDataChange = async (outline) => {
    agent.OutlinePages.updateOutline(outline).then(() => {
      setRefetch(!refetch);
      handleOutlineSelected(outline);
    });
  };

  const approve = async (pageType: ePageType) => {
    close();
    const { _id, ...rest } = outline;
    await agent.OutlinePages.transferAutoOutline(rest, pageType);
    enqueueSnackbar(I18n.t("strategy.outlinePages.transfer.transfered"));
    setRefetch(!refetch);
  };

  const actionOptions = getAvailablePageTypes(selectedWebsite).map((row) => ({
    ...row,
    label: `Create ${row.label}`,
  }));

  console.log({ hideCreateBtn });
  const createButton = () => {
    if (
      !hideCreateBtn &&
      (outline?.page === null || outline?.page?.currentState.state === "Deleted") &&
      actionOptions.length > 0
    ) {
      return (
        <MButtonGroup
          wrapperCustomClass={classes.button}
          onClick={async () => {
            if (actionOptions.length > 0) {
              await approve(actionOptions[0].key);
            }
          }}
          onMenuItemClick={async (key) => {
            await approve(key);
          }}
          text={(() => actionOptions?.[0]?.label || "")()}
          options={actionOptions}
        />
      );
    }
    return null;
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={(event) => close()}
      fullWidth
      maxWidth={"xl"}
      classes={{ paper: classes.dialogPaper, paperScrollPaper: classes.scrollPaper }}
    >
      <DialogContent className={classes.dialogContent}>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
            <OutlineDialogHeader
              title={outline?.keywordGroup || ""}
              setPageTab={setPageTab}
              pageTab={pageTab}
              close={close}
            />

            <div className={classes.listItems}>
              {pageTab === "keywords" && <KeywordDialogList broadKeyword={false} />}
              {pageTab === "pages" && <PagesDialogList />}

              {pageTab === "outlines" && (
                <OutlinesDialogList
                  setHideCreateButton={(val) => {
                    setHideCreateBtn(val);
                  }}
                  selectedWebsite={selectedWebsite}
                  onOutlineDataChange={onOutlineDataChange}
                  outline={outline}
                />
              )}
              {pageTab === "serps" && <BriefSerpsDialogList pageTab={pageTab} setPageTab={setPageTab} />}
            </div>
          </List>

          {createButton()}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatch)(OutlinePageDialog);
