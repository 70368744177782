import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 16,
    height: 21,
  },
}));

type PropTypes = {
  className?: string;
  onClick?: (e: any) => void;
};

function CopyNameIcon(props: PropTypes) {
  const { className, onClick } = props;
  return (
    <SvgIcon onClick={onClick} className={className} viewBox={"0 0 10 14"}>
      <path d="M7.70342 4.32778C7.70342 4.60699 7.47707 4.83333 7.19786 4.83333H2.85269C2.57348 4.83333 2.34714 4.60699 2.34714 4.32778C2.34714 4.04857 2.57348 3.82222 2.85269 3.82222H7.19786C7.47707 3.82222 7.70342 4.04857 7.70342 4.32778ZM8.45932 13.5H1.54068C0.693308 13.5 0 12.85 0 12.0556V1.94444C0 1.15 0.693308 0.499999 1.54068 0.499999H8.45932C9.30669 0.499999 10 1.15 10 1.94444V12.0556C10 12.85 9.30669 13.5 8.45932 13.5ZM8.86032 2.42857C8.86032 1.87629 8.41261 1.42857 7.86032 1.42857H2.04782C1.49554 1.42857 1.04782 1.87628 1.04782 2.42857V11.5714C1.04782 12.1237 1.49554 12.5714 2.04782 12.5714H7.86032C8.41261 12.5714 8.86032 12.1237 8.86032 11.5714V2.42857Z" />
    </SvgIcon>
  );
}

export default CopyNameIcon;
