import * as React from "react";
import { I18n } from "react-redux-i18n";
import NumberInputBaseComponent from "../BaseComponents/NumberInputBaseComponent";

type PropTypes = {
  title?: string;
  value: number;
  handleChange: (width: number) => void;
  defaultValue?: number;
};

const WidthCSS = (props: PropTypes) => {
  const {
    value,
    title = I18n.t("rich_text_editor.product_embed.product_wrapper_width"),
    defaultValue,
    handleChange,
  } = props;
  return (
    <NumberInputBaseComponent
      defaultValue={defaultValue}
      rowTitle={title}
      value={value}
      handleChange={(width) => handleChange(parseFloat(width))}
    />
  );
};

export default WidthCSS;
