import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";
import { web } from "../../../../helpers/urlHelper";
import { IProposal, IWebsite } from "../../../../reducers/constants/objectTypes";
import Setup from "./components/Setup";
import agent from "../../../../agent";
import TotalCost from "./components/TotalCost";
import Pricing from "./components/Pricing";

const useStyles = makeStyles((theme: Theme) => ({
  topicsWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    height: "calc(100vh - 59px)",
    padding: "0 40px",
    maxWidth: 1350,
    margin: "auto",
  },
  content: {
    width: "100%",
    marginRight: 66,
  },
  details: {
    width: "100%",
    maxWidth: 1000,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    marginRight: 66,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

const Account = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [proposal, setProposal] = React.useState<IProposal>(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    setLoading(true);
    agent.Proposals.getProposal(selectedWebsite)
      .then((res) => {
        setProposal(res.proposal);
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.create_error", { msg: e.message }));
        setLoading(false);
      });
  }, [selectedWebsite]);

  console.log("propsal", proposal);

  return (
    <div className={classes.topicsWrapper}>
      {loading && (
        <Backdrop
          style={{
            zIndex: 10,
            color: "#fff",
            display: "flex",
            alignItems: "start",
            paddingTop: 150,
            position: "absolute",
            backgroundColor: "rgba(255,255,255,0.7)",
          }}
          open
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      {!loading && proposal && (
        <>
          <div className={classes.details}>
            <Switch>
              <Route
                exact={false}
                path={web.legacyAccountSetup()}
                render={() => <Setup proposal={proposal} handleChange={setProposal} />}
              />
              <Route
                exact={false}
                path={web.legacyAccountPricing()}
                render={() => <Pricing proposal={proposal} handleChange={setProposal} />}
              />
              <Redirect exact from={"*"} to={web.legacyAccountSetup()} />
            </Switch>
          </div>
          <TotalCost selectedWebsite={selectedWebsite} proposal={proposal} handleChange={setProposal} />
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
