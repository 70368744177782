import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, TypographyVariant } from "@material-ui/core/styles";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import { IVideoProject, eVideoProjectState } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  status: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: theme.typography.pxToRem(14),
  },
  green: {
    color: "#00cb72",
  },
  red: {
    color: "#ff0576",
  },
  blue: {
    color: "#4353ff",
  },
  violet: {
    color: "darkviolet",
  },
  purple: {
    color: "purple",
  },
}));

type PropTypes = {
  currentState: IVideoProject["currentState"];
  variant?: TypographyVariant;
};

const MVideoStatusTypography = (props: PropTypes) => {
  const classes = useStyles(props);
  const { currentState, variant = "body2" } = props;

  const getClass = () => {
    switch (currentState?.state) {
      case eVideoProjectState.trim:
        return classes.purple;
      case eVideoProjectState.brief:
      case eVideoProjectState.edit:
        return classes.blue;
      case eVideoProjectState.review:
      case eVideoProjectState.firstCheck:
      case eVideoProjectState.secondCheck:
        return classes.violet;
      case eVideoProjectState.cards:
      case eVideoProjectState.captions:
      case eVideoProjectState.music:
        return classes.purple;
      case eVideoProjectState.done:
        return classes.green;
      case eVideoProjectState.deleted:
        return classes.red;
      default:
        return classes.purple;
    }
  };

  return (
    <Typography className={ClassNames(classes.status, getClass())} variant={variant}>
      {I18n.t(`video_workflow.tabs.${currentState.state}`)}
    </Typography>
  );
};

export default React.memo(MVideoStatusTypography);
