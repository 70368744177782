import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { find } from "lodash";
import MRouterPrompt from "../../../components/MRouterPrompt";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import {
  IWebsite,
  IWebsiteResourcePageProps,
  IWebsiteSectionProps,
  IWebsiteThemeProps,
} from "../../../../../reducers/constants/objectTypes";
import WebsitesSectionsSwitch from "../../../components/WebsitesSectionsSwitch";
import PageSection from "../../../components/PageSection";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import TextInputMentions from "../../../components/TextInputMentions";
import { LENGTHS } from "../../../../../reducers/constants/consts";
import ResourcePageTopSection from "./components/TopSection";
import FeaturedBanner from "./components/FeaturedBanner";
import DynamicBanner from "./components/DynamicBanner";
import MSelect from "../../../../../components/MSelect";
import CustomUrlPrefixTextField from "../../../components/CustomUrlPrefixTextField";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  errorWrapper: {
    backgroundColor: "#f5f6f8",
    display: "flex",
    marginRight: "-30px",
    marginTop: "-30px",
    marginBottom: 10,
    paddingLeft: 10,
    marginLeft: "-30px",
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
    width: "100%",
  },
  select: {
    textTransform: "capitalize",
  },
  selectTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 10,
  },
  columnWrapper: {
    width: "calc(50% - 20px)",
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
  },
}));

const mapStateToProps = (state) => ({
  resourcePage: state.websitePages.resourcePage,
  theme: state.websitePages.theme,
  sections: state.websitePages.sections,
  urlPrefix: state.websitePages.siteDetails.urlPrefix,
  website: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (resourcePage) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "resourcePage",
      value: resourcePage,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  theme: IWebsiteThemeProps;
  website: IWebsite;
  resourcePage: IWebsiteResourcePageProps;
  sections: IWebsiteSectionProps;
  onPropertyChanged: (value) => void;
  handleToggleActive: (section, enabled) => void;
};

const PageResourcePage = (props: PropTypes) => {
  const classes = useStyles(props);
  const { sections, resourcePage, theme, website, handleToggleActive, onPropertyChanged } = props;
  const [error, setError] = React.useState([]);

  const textFieldsValidated = () => {
    let valid = true;
    const emptyFields = [];
    if (!resourcePage.name) {
      valid = false;
      emptyFields.push(I18n.t(`website.name`));
    }
    if (!resourcePage.metaTitle) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_title`));
    }
    if (!resourcePage.metaDescription) {
      valid = false;
      emptyFields.push(I18n.t(`website.meta_description`));
    }
    setError(emptyFields);
    return valid;
  };

  React.useEffect(() => {
    if (error.length <= 0) {
      return;
    }
    textFieldsValidated();
  }, [resourcePage]);

  const templateOptions = [
    { _id: 1, name: "default" },
    { _id: 2, name: "safebooks" },
  ];

  return (
    <>
      <MRouterPrompt />
      <WebsitesSectionsSwitch
        inSection
        enabled={sections.resourcePage}
        title={I18n.t(`website.page_types.resourcePage`)}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.resourcePage) {
              handleToggleActive("resourcePage", enabled);
            }
            return;
          }
          setError([]);
          handleToggleActive("resourcePage", enabled);
        }}
      >
        <CustomUrlPrefixTextField
          handleValueChange={(value) => onPropertyChanged({ ...resourcePage, urlPrefix: value })}
          customUrlPrefix={resourcePage.urlPrefix}
          previewUrls={[
            {
              key: "website.forum.main_page_preview_url",
              value: resourcePage.slug || "",
              valid: sections.resourcePage,
            },
          ]}
        />
      </WebsitesSectionsSwitch>

      {error.length > 0 && (
        <Typography variant={"body1"} className={classes.error}>
          {error.length > 0 && I18n.t("website.error_message")}
        </Typography>
      )}

      <PageSection sectionTitle={I18n.t("website.resourcePage.general")}>
        <div>
          <div className={classes.inputRowWrapper}>
            <TextFieldMaxLength
              titleAndLengthLocation={"top"}
              error={error.includes(I18n.t(`website.name`))}
              value={resourcePage.name}
              placeholder={I18n.t("website.name_ph")}
              title={I18n.t("website.name")}
              height={37}
              borderRadius={4}
              marginBottom={30}
              handleValueChange={(value) => onPropertyChanged({ ...resourcePage, name: value })}
            />
            <TextFieldMaxLength
              titleAndLengthLocation={"top"}
              value={resourcePage.slug}
              placeholder={I18n.t("website.slug_title_ph")}
              title={I18n.t("website.slug_title")}
              height={37}
              borderRadius={4}
              handleValueChange={(value: any) =>
                onPropertyChanged({
                  ...resourcePage,
                  slug: value,
                })
              }
            />
          </div>
          <div className={classes.columnWrapper}>
            <Typography variant={"subtitle2"} className={classes.selectTitle}>
              {I18n.t("website.blog.template")}
            </Typography>
            <MSelect
              customClass={classes.select}
              options={templateOptions}
              singleValueStyles={{ textTransform: "capitalize" }}
              value={find(templateOptions, { name: resourcePage.template })}
              optionValue={"_id"}
              optionLabel={"name"}
              borderRadius={0}
              borderWidth={"1px"}
              height={37}
              handleChange={(value) => onPropertyChanged({ ...resourcePage, template: value.name })}
            />
          </div>

          <TextInputMentions
            error={error.includes(I18n.t(`website.meta_title`))}
            tags={[]}
            placeholder={I18n.t("website.meta_title_ph")}
            title={I18n.t("website.meta_title")}
            maxLength={LENGTHS.META_TITLE}
            titleAndLengthLocation={"top"}
            text={resourcePage.metaTitle}
            onTextChanged={(value) => onPropertyChanged({ ...resourcePage, metaTitle: value })}
          />
          <TextInputMentions
            error={error.includes(I18n.t(`website.meta_description`))}
            tags={[]}
            placeholder={I18n.t("website.meta_description_ph")}
            title={I18n.t("website.meta_description")}
            maxLength={LENGTHS.META_DESCRIPTION}
            titleAndLengthLocation={"top"}
            text={resourcePage.metaDescription}
            onTextChanged={(value) => onPropertyChanged({ ...resourcePage, metaDescription: value })}
          />
        </div>
      </PageSection>
      <ResourcePageTopSection />
      {resourcePage.template !== "safebooks" && <FeaturedBanner />}
      {resourcePage.template !== "safebooks" && <DynamicBanner />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageResourcePage);
