import * as React from "react";
import { eWidgetType, IWidget } from "../../../../../../reducers/constants/objectTypes";
import FormWidgetBuildTab from "../build/formWidget/FormWidgetBuildTab";
import CustomWidgetBuildTab from "../build/customWidget/CustomWidgetBuildTab";
import DialogWidgetBuildTab from "../build/dialogWidget/DialogWidgetBuildTab";
import InlineWidgetBuildTab from "../build/inlineWidget/InlineWidgetBuildTab";

type PropTypes = {
  widget: IWidget;
};

const BuildTab = {
  [eWidgetType.FORM]: <FormWidgetBuildTab />,
  [eWidgetType.CUSTOM_WIDGET]: <CustomWidgetBuildTab />,
  [eWidgetType.DIALOG]: <DialogWidgetBuildTab />,
  [eWidgetType.INLINE]: <InlineWidgetBuildTab />,
};

const WidgetBuildTab = (props: PropTypes) => {
  const { widget } = props;

  return BuildTab[widget.type];
};

export default WidgetBuildTab;
