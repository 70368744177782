import * as React from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { IWebsite, IWriterCommunityAnswer } from "../../../../reducers/constants/objectTypes";
import WriterPublishedCommunityPostItemRow from "./WriterPublishedCommunityPostItemRow";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";
import MTable from "../../../../components/Table/Table/MTable";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";

type PropTypes = {
  selectedWebsite: IWebsite;
  answersLoaded: boolean;

  answers: IWriterCommunityAnswer[];

  sortField: any;
  setSortField: (input: any) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  answersLoaded: state.community.writePublishedAnswersLoaded,
  answers: state.community.writePublished,
});

const mapDispatchToProps = (dispatch) => ({});

const WriterCommunityItemListSubHeader = (props: {
  title: string;
  topHeader: boolean;
  sortField: any;
  setSortField: (input: any) => void;
}) => {
  const theme: any = useTheme();
  return (
    <MTableHeader>
      <MTableHeaderLabel columnWidth={"33%"} fontSize={theme.typography.pxToRem(21)}>
        {props.title}
      </MTableHeaderLabel>
      {props.topHeader && (
        <>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"createdAt"}
          >
            {I18n.t("workflow.writer.listHeader.created_at")}
          </MTableHeaderSortLabel>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"publishedDate"}
          >
            {I18n.t("workflow.writer.listHeader.published_at")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel>{I18n.t("workflow.writer.listHeader.category")}</MTableHeaderLabel>
        </>
      )}
    </MTableHeader>
  );
};
const WriterPublishedCommunityItemList = (props: PropTypes) => {
  const { answersLoaded, answers, sortField, setSortField } = props;

  return (
    <MTable>
      {!answersLoaded && <MTableRowProgressBar />}

      {answersLoaded && answers.length === 0 && <MTableRowEmptyRow />}

      {answersLoaded && answers.length > 0 && (
        <WriterCommunityItemListSubHeader
          setSortField={setSortField}
          sortField={sortField}
          title={I18n.t("writers.answers.your_answers")}
          topHeader
        />
      )}

      {answersLoaded &&
        answers.length > 0 &&
        answers.map((item, index) => <WriterPublishedCommunityPostItemRow key={item._id} answer={item} />)}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WriterPublishedCommunityItemList);
