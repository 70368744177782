import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import ClassNames from "classnames";
import { Editor, Path, Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";
import TextAreaWidgetBaseElement from "../baseElements/TextAreaWidgetBaseElement";
import { Widget } from "../WithWidgets";
import { editorType, Faq, FaqData } from "../../../types/editor.Types";
import richTextEditorHelper from "../../../helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    display: "flex",
    flexDirection: "column",
  },
  questionAndAnswerRow: {
    display: "flex",
    alignItems: "center",
  },
  questionAndAnswer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  row: {
    display: "flex",
  },
  fieldTitle: {
    width: 100,
    marginRight: 10,
    whiteSpace: "nowrap",
    height: 30,
  },
  faqTitle: (props: PropTypes) => ({
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    paddingBottom: 0,
    textTransform: "capitalize",
    ...props.element.data.title?.css,
  }),
  text: {},
  clearIcon: {
    cursor: "pointer",
    fill: theme.palette.text.secondary,
    "&:hover": {
      fill: theme.palette.text.primary,
    },
  },
  hidden: {
    visibility: "hidden",
  },
}));
type PropTypes = {
  attributes: any;
  children: any;
  element: Faq;
};

export const getRawText = (n: Node) => {
  if (!n.data) {
    return "";
  }
  return (
    [n.data.title?.title || "", n.data.faqList?.map((qa) => `${qa.question}\n${qa.answer}`)?.join("\n")]?.join("\n") ||
    ""
  );
};

export const insertFAQs = (editor: Editor, at: Path, forcedLayout = false) => {
  console.log("insertFAQs");
  richTextEditorHelper.insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading(
    editor,
    [
      {
        type: editorType.faq,
        forcedLayout,
        data: {
          uuid: richTextEditorHelper.getUuid(),
          title: {
            show: true,
            css: {},
            title: "FAQ's",
          },
          question: {
            show: false,
            css: {},
            title: null,
          },
          answer: {
            show: false,
            css: {},
            title: null,
          },
          list: [],

          card: {
            css: {},
          },
        },
        children: [
          {
            text: "",
          },
        ],
      },
    ],
    at
  );

  const layoutEntry = Editor.above(editor, { match: (x) => x.type === "layout" && x.layoutType === "pros-cons-table" });
  if (layoutEntry) {
    Transforms.select(editor, [...layoutEntry[1], 0]);
  } else {
    Transforms.select(editor, at);
  }
};

const FAQWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element } = props;
  const editor = useSlateStatic();

  const handleChange = (data) => {
    console.log("handleChange", data, ReactEditor.findPath(editor as ReactEditor, element));
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
    console.log("handleChange done");
  };

  const defaultData: FaqData = {
    uuid: null,
    title: {
      show: null,
      title: "",
      css: {},
    },
    question: {
      show: null,
      title: "",
      css: {},
    },
    answer: {
      show: null,
      title: "",
      css: {},
    },
    list: [],
    card: {
      css: {},
    },
  };

  React.useEffect(() => {
    const data = { ...element.data };
    if ((!data?.list || data?.list.length === 0) && data?.faqList) {
      data.list = [...data.faqList];
      data.title = { ...data?.title, title: data.faqTitle || data.title.title };
    }
    handleChange({ ...defaultData, ...data });
  }, []);

  return (
    <div {...attributes}>
      <Widget title={I18n.t("rich_text_editor.custom_components.faq")} element={element} editable>
        <div className={classes.wrap} style={element.data.card?.css}>
          <TextAreaWidgetBaseElement
            className={classes.faqTitle}
            style={element.data.title?.css}
            text={element.data.title?.title}
            placeholder={I18n.t("rich_text_editor.faq.faq_title_ph")}
            onChange={(title) => handleChange({ ...element.data, title: { ...element.data.title, title } })}
          />
          {[...(element.data.list || []), { question: "", answer: "" }].map((faq, faqIndex) => (
            <div className={classes.questionAndAnswerRow}>
              <div key={`faq_${faqIndex}`} className={classes.questionAndAnswer}>
                <div className={classes.row}>
                  <Typography variant={"body1"} className={classes.fieldTitle}>
                    {I18n.t("rich_text_editor.faq.question", { number: faqIndex + 1 })}
                  </Typography>
                  <TextAreaWidgetBaseElement
                    className={classes.text}
                    text={faq.question}
                    style={element.data.question?.css}
                    placeholder={I18n.t("rich_text_editor.faq.question_ph")}
                    onChange={(value) => {
                      const newFaqList = [...(element.data.list || [])];
                      if (faqIndex >= newFaqList.length) {
                        newFaqList.push({ question: value, answer: faq.answer });
                      } else {
                        newFaqList[faqIndex] = { question: value, answer: faq.answer };
                      }
                      handleChange({ ...element.data, list: newFaqList });
                    }}
                  />
                </div>
                <div className={classes.row}>
                  <Typography variant={"body1"} className={classes.fieldTitle}>
                    {I18n.t("rich_text_editor.faq.answer", { number: faqIndex + 1 })}
                  </Typography>
                  <TextAreaWidgetBaseElement
                    className={classes.text}
                    text={faq.answer}
                    style={element.data.answer?.css}
                    placeholder={I18n.t("rich_text_editor.faq.answer_ph")}
                    onChange={(value) => {
                      const newFaqList = [...(element.data.list || [])];
                      if (faqIndex >= newFaqList.length) {
                        newFaqList.push({ question: faq.question, answer: value });
                      } else {
                        newFaqList[faqIndex] = { question: faq.question, answer: value };
                      }
                      handleChange({ ...element.data, list: newFaqList });
                    }}
                  />
                </div>
              </div>
              <ClearIcon
                className={ClassNames(
                  classes.clearIcon,
                  [...(element.data.list || [])].length === faqIndex && classes.hidden
                )}
                onClick={() => {
                  const newFaqList = [...element.data.list].filter((x, i) => faqIndex !== i);
                  handleChange({ ...element.data, list: newFaqList });
                }}
              />
            </div>
          ))}
        </div>
        {children}
      </Widget>
    </div>
  );
};

export default FAQWidget;
