import * as React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import { Widget } from "../widgets/WithWidgets";
import { BlockQuote } from "../../types/editor.Types";
import QuotesIcon from "../../../icons/QuotesIcon";
import OpenQuoteIcon from "../../../icons/OpenQouteIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    openQuoteWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      marginLeft: 50,
    },
    quotes: {
      borderWidth: 0,
      padding: "20px 0px",
      "&:before": {},
      "&:after": {},
      "& p": {
        display: "inline",
      },
    },
    textWrapper: {
      marginLeft: 90,
      marginRight: 90,
      fontSize: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightMedium as any,
      lineHeight: "150%",
    },
    detailsWrapper: (props: PropTypes) => ({
      display: "flex",
      justifyContent: props.element.showDetails ? "space-between" : "flex-end",
      marginTop: props.element.showDetails ? 5 : "-10px",
      marginRight: 50,
      marginLeft: 90,
    }),
    details: (props: PropTypes) => ({
      fontSize: theme.typography.pxToRem(18),
      color: props.website.configurations.theme.palette.text.secondary,
      lineHeight: "150%",
    }),
    icon: (props: PropTypes) => ({
      height: 30,
      width: 35,
      fill: props.website.configurations.theme.palette.primary.main,
    }),
  })
);

type PropTypes = {
  children: any;
  element: BlockQuote;
  attributes: any;
  website: IWebsite;
  readOnly?: boolean;
};

const BlockQuoteElement = React.memo(
  (props: PropTypes) => {
    const { children, attributes, element, readOnly = false } = props;
    const classes = useStyles(props);
    return (
      <Widget
        readMode={readOnly}
        title={I18n.t("rich_text_editor.quote_element.name")}
        contentEditable
        editable
        element={element}
      >
        <div className={classes.quotes} {...attributes}>
          <div className={classes.openQuoteWrapper} contentEditable={false}>
            <OpenQuoteIcon className={classes.icon} />
          </div>
          <div className={classes.textWrapper}>{children}</div>

          <div className={classes.detailsWrapper} contentEditable={false}>
            {element.showDetails && (
              <Typography className={classes.details}>
                {`${element.name || ""} ${element?.name && element.jobTitle ? ", " : ""} ${element.jobTitle || ""}`}
              </Typography>
            )}
            <QuotesIcon className={classes.icon} />
          </div>
        </div>
      </Widget>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps?.element?.children?.length > 1) {
      return _.isEqual(prevProps.children, nextProps.children);
    }
    return _.isEqual(prevProps.element, nextProps.element);
  }
);

export default BlockQuoteElement;
