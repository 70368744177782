import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import InputList from "../../../CRO/pages/WidgetsBuilder/FormWidgetEditor/components/InputList";
import { I18n } from "react-redux-i18n";
import MButton from "../../../../components/MButton";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { IReportsSettings } from "../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  ctaListWrapper: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: 30,
  },
  saveButton: {
    marginTop: "20px",
    height: "40px",
    borderRadius: "75px",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  row: {
    display: "flex",
    alignItems: "center",
    gap: 30,
  },
  reportTitle: {
    minWidth: 250,
    fontSize: theme.typography.pxToRem(14),
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

type CTASettingsTabProps = {
  reportsSettings: IReportsSettings;
  setReportsSettings: (settings: IReportsSettings) => void;
  handleChange: (settings: IReportsSettings) => void;
};
const CTASettingsTab = (props: CTASettingsTabProps) => {
  const classes = useStyles(props);

  const { reportsSettings, setReportsSettings, handleChange } = props;

  return (
    <div className={classes.ctaListWrapper}>
      <div className={classes.section}>
        <div className={classes.row}>
          <Typography variant={"body1"} className={classes.reportTitle}>
            {I18n.t(`reports.settings.count_internal_link_clicks`)}
          </Typography>
          <Switch
            color={"primary"}
            checked={reportsSettings.ctaSettings.includeInternalLinkClick}
            onChange={() => {
              handleChange({
                ...reportsSettings,
                ctaSettings: {
                  ...reportsSettings.ctaSettings,
                  includeInternalLinkClick: !reportsSettings.ctaSettings.includeInternalLinkClick,
                },
              });
            }}
          />
        </div>
        <Typography variant={"body1"} className={classes.text}>
          {I18n.t(`reports.settings.include_links_containing`)}
        </Typography>
        <InputList
          items={reportsSettings.ctaSettings.internalLinksLike}
          placeholder={I18n.t(`reports.settings.destination_prefix`)}
          onChange={(values) => {
            setReportsSettings({
              ...reportsSettings,
              ctaSettings: {
                ...reportsSettings.ctaSettings,
                internalLinksLike: values,
              },
            });
          }}
        />
      </div>

      <div className={classes.section}>
        <div className={classes.row}>
          <Typography variant={"body1"} className={classes.reportTitle}>
            {I18n.t(`reports.settings.count_outbound_link_clicks`)}
          </Typography>
          <Switch
            color={"primary"}
            checked={reportsSettings.ctaSettings.includeOutboundLinkClick}
            onChange={() => {
              handleChange({
                ...reportsSettings,
                ctaSettings: {
                  ...reportsSettings.ctaSettings,
                  includeOutboundLinkClick: !reportsSettings.ctaSettings.includeOutboundLinkClick,
                },
              });
            }}
          />
        </div>
        <Typography variant={"body1"} className={classes.text}>
          {I18n.t(`reports.settings.include_links_containing`)}
        </Typography>
        <InputList
          items={reportsSettings.ctaSettings.destinationLike}
          placeholder={I18n.t(`reports.settings.destination_prefix`)}
          onChange={(values) => {
            setReportsSettings({
              ...reportsSettings,
              ctaSettings: {
                ...reportsSettings.ctaSettings,
                destinationLike: values,
              },
            });
          }}
        />
      </div>
      <div className={classes.btnWrapper}>
        <MButton
          customClassNames={classes.saveButton}
          onClick={() => {
            handleChange(reportsSettings);
          }}
        >
          {I18n.t(`reports.settings.save`)}
        </MButton>
      </div>
    </div>
  );
};

export default CTASettingsTab;
