import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { IQuestion } from "../../../../../../reducers/constants/objectTypes";
import Typography from "@material-ui/core/Typography";
import moment from "moment/moment";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  flexOne: {
    flex: 1,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  statusRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    alignItems: "center",
  },
  status: {
    display: "flex",
  },
  green: {
    color: "green",
  },
  writerTitle: {
    minWidth: 130,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "17px",
    marginBottom: 10,
  },
}));

type PropTypes = {
  selectedQuestion: IQuestion;
};

const mapStateToProps = (state) => {
  return {
    selectedQuestion: state.communityEditor.question,
  };
};

const mapDispatchToProps = (dispatch) => ({});

const CommunityEditorPublishStatus = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedQuestion } = props;

  return (
    <div>
      <Typography variant={"subtitle2"} className={classes.writerTitle} color={"textSecondary"}>
        {I18n.t("custom_post.publish_status")}
      </Typography>

      <div className={classes.statusRow}>
        <Typography className={classes.flexOne} variant={"subtitle2"}>
          {"Date"}
        </Typography>
        <Typography className={classes.flexOne} variant={"subtitle2"}>
          {"Time"}
        </Typography>
        <Typography className={classes.flexOne} variant={"subtitle2"}>
          {"Status"}
        </Typography>
      </div>
      <div className={classes.statusRow}>
        <Typography className={classes.flexOne} variant={"body1"} color={"textSecondary"}>
          {moment(selectedQuestion.publishedDate).format("MMM D")}
        </Typography>
        <Typography className={classes.flexOne} variant={"body1"} color={"textSecondary"}>
          {moment(selectedQuestion.publishedDate).format("HH:mm")}
        </Typography>
        <Tooltip title={"-"}>
          <Typography
            variant={"body1"}
            color={"textSecondary"}
            className={ClassNames(classes.flexOne, classes.status, classes.green)}
          >
            OK
          </Typography>
        </Tooltip>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityEditorPublishStatus);
