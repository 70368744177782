import { isPlainObject } from "lodash";
import { jsx } from "slate-hyperscript";
import escapeHtml from "escape-html";
import * as Bluebird from "bluebird";
import { Element } from "slate";

const isText = (value) => isPlainObject(value) && typeof value.text === "string";

const ELEMENT_TAGS = {
  A: (el) => ({
    type: "link",
    url: el.getAttribute("href"),
    newTab: el.getAttribute("target") === "_blank",
    rel: el.getAttribute("rel"),
  }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H1: () => ({ type: "heading-one" }),
  H2: () => ({ type: "heading-one" }),
  H3: () => ({ type: "heading-two" }),
  H4: () => ({ type: "heading-two" }),
  H5: () => ({ type: "heading-two" }),
  H6: () => ({ type: "heading-two" }),
  LI: () => ({ type: "list-item" }),
  OL: () => ({ type: "numbered-list" }),
  P: () => ({ type: "paragraph" }),
  PRE: () => ({ type: "code" }),
  UL: () => ({ type: "bulleted-list" }),
  FIGURE: () => ({ type: "figure" }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

export const HTMLtoJSON = async (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }

  const { nodeName } = el;
  let parent = el;

  if (nodeName === "PRE" && el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
    parent = el.childNodes[0];
  }
  let children = await Bluebird.map(Array.from(parent.childNodes), async (c) => await HTMLtoJSON(c), {
    concurrency: 1,
  });
  children = children.flat();
  /// should remove empty text nodes and null nodes
  children = children.filter((child) => {
    if (child === null) {
      return false;
    }
    if (typeof child === "string") {
      return child.trim().length > 0;
    }
    return child;
  });
  if (children.length === 0) {
    children = [{ text: "" }];
  }
  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }
  if (ELEMENT_TAGS[nodeName]) {
    const attrs = await ELEMENT_TAGS[nodeName](el);

    // ignore tablepress links and its children
    if (attrs.type === "table") {
      children = children.filter((child) => !(child.type === "link" && child.url?.includes("page=tablepress")));
    }

    /// add rowIndex to table rows
    if (attrs.type === "table-body") {
      children = children.map((child, rowIndex) => ({ ...child, rowIndex: rowIndex + 1 }));
    }

    if (attrs.fromImage) {
      delete attrs.fromImage;
      return jsx("element", attrs, attrs.children);
    }

    return jsx("element", attrs, children);
  }
  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => {
      if (Element.isElement(child)) {
        return jsx("element", child, child.children);
      }
      return jsx("text", attrs, child);
    });
  }
  return children;
};

const nodeToHTML = (node) => {
  const html = [];

  if (isText(node)) {
    let string = escapeHtml(node.text);

    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    html.push(string);
    return html;
  }

  const children = node.children.map((n) => nodeToHTML(n)).join("");

  switch (node.type) {
    case "block-quote":
      html.push(`<blockquote>${children}</blockquote>`);
      break;
    case "bulleted-list":
      html.push(`<ul>${children}</ul>`);
      break;
    case "heading-one":
      html.push(`<h2>${children}</h2>`);
      break;
    case "heading-two":
      html.push(`<h3>${children}</h3>`);
      break;
    case "list-item":
      html.push(`<li>${children}</li>`);
      break;
    case "inline-html":
      html.push(node.html);
      break;
    case "numbered-list":
      html.push(`<ol>${children}</ol>`);
      break;
    case "link":
      html.push(`<a href="${node.url}" target="${node.newTab ? "_blank" : "_self"}">${children}</a>`);
      break;
    default:
      html.push(`<p>${children}</p>`);
  }

  return html;
};

export const jsonToHTML = async (json) => {
  const nodes = JSON.parse(json);
  const html = await Bluebird.map(nodes, (node) => nodeToHTML(node));
  return html.flat(1);
};
