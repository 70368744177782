import { makeStyles } from "@material-ui/styles";
import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableItem from "./DraggableItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

type PropTypes = {
  onItemsReorder?: (items: string[]) => void;
  items: string[];
  onCopyClick: (item: string) => void;
  onDeleteClick: (index: number) => void;
  onClick: (item: string, index: number) => void;
};

const DraggableList = (props: PropTypes) => {
  const { onItemsReorder, onClick, onCopyClick, onDeleteClick, items } = props;
  const classes = useStyles(props);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const [newOrder] = items.splice(source.index, 1);
    items.splice(destination.index, 0, newOrder);

    onItemsReorder && onItemsReorder(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div className={classes.root} {...provided.droppableProps} ref={provided.innerRef}>
            {items?.map((field, index) => (
              <Draggable key={index} draggableId={index + "entail"} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <DraggableItem
                      onClick={() => {
                        onClick(field, index);
                      }}
                      key={index + ""}
                      onCopyClick={(e) => {
                        e.stopPropagation();
                        onCopyClick(field);
                      }}
                      onDeleteClick={(e) => {
                        console.log("onDeleteClick:DraggableList");
                        e.stopPropagation();
                        onDeleteClick(index);
                      }}
                      item={field}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
