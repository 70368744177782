import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerTitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold as any,
      color: theme.palette.text.primary,
    },
    notesInnerIdentSection: {
      marginTop: 5,
      fontSize: theme.typography.pxToRem(14),
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
    },
    notes: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

type PropTypes = {
  notes?: string;
};

const EditorOutlineNotes = (props: PropTypes) => {
  const classes = useStyles(props);
  const { notes } = props;

  if (!notes) {
    return null;
  }

  return (
    <div className={classes.notes}>
      <Typography className={classes.innerTitle}>{I18n.t("outline.notes")}</Typography>
      <Typography className={classes.notesInnerIdentSection}>{notes}</Typography>
    </div>
  );
};

export default EditorOutlineNotes;
