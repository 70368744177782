import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClassNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import Search from "@material-ui/icons/Search";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import DownChevronIcon from "../../../../icons/DownChevronIcon";
import FunnelIcon from "../../../../icons/FunnelIcon";

const useStyles = makeStyles((theme: Theme) => ({
  inputWrapper: {
    display: "flex",
    flexDirection: (props: PropTypes) => props.flexDirection || "column",
    rowGap: 8,
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: "#141414",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
  },
  popper: {
    zIndex: 9999,
  },
  titleWrapper: {
    cursor: "pointer",
    color: theme.palette.text.disabled,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: (props: PropTypes) => props.border || `1px solid ${theme.palette.divider}`,
    borderRadius: (props: PropTypes) => props.borderRadius || 3,
    height: 37,
    padding: (props: PropTypes) => props.padding || "0px 10px",
  },
  selectionResult: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary,
  },
  selectedResults: {
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  dropDownIcon: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  searchField: {
    width: "100%",
    color: "#000",
    height: 33,
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette.divider,
    },
    "& .MuiInput-root": {
      height: "100%",
    },
    "& .MuiInputAdornment-positionStart": {
      margin: 0,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },
  searchInput: {
    padding: 0,
    color: "#000",
  },
  searchIcon: {
    color: theme.palette.primary.dark,
  },
  searchWrap: {
    padding: "0 10px",
    paddingTop: 10,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 0px",
    gap: 7,
    maxHeight: 230,
    // width: 320,
    overflowY: "auto",
    paddingBottom: 10,
  },
  optionWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    padding: "0px 10px",
    gap: 20,
    "&:hover": {
      backgroundColor: "#E6EEFF",
      color: theme.palette.primary.main,
      borderRadius: 3,
    },
  },

  optionLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: 3,
    flex: 1,
    cursor: "pointer",
  },
  selectedOption: {
    backgroundColor: "#E6EEFF",
    color: theme.palette.primary.main,
    borderRadius: 3,
  },

  editText: {
    fontSize: 14,
    padding: 3,
    fontWeight: theme.typography.fontWeightLight as any,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E6EEFF",
      color: theme.palette.primary.main,
      borderRadius: 3,
    },
  },
  deleteText: {
    fontSize: 14,
    padding: 3,
    fontWeight: theme.typography.fontWeightLight as any,
    color: theme.palette.error.main,
    cursor: "pointer",
  },
  clearIcon: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  funnelIcon: {
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  iconButton: {
    margin: 0,
    padding: "0px 5px",
  },
  active: {
    color: theme.palette.primary.main,
  },
  actions: {
    marginLeft: (props: PropTypes) => props.actionsMarginLeft || 10,
    display: "flex",
    alignItems: "center",
    gap: 0,
  },
}));

type PropTypes = {
  placeholder?: string;
  optionLabel?: string;
  optionValue?: string;
  options: any[];
  value: any;
  handleOnChange: (value) => void;
  label?: string;
  fieldId?: string;
  onClear?: () => void;
  onFilterClick?: () => void;
  border?: string | number;
  showDropdownIcon?: boolean;
  children?: React.ReactNode | string;
  flexDirection?: "row" | "column";
  dynamicTextWidth?: boolean;
  search?: boolean;
  actionsMarginLeft?: number | string;
  padding?: string | number;
  wrapperClassName?: string;
  titleWrapperClassName?: string;
  borderRadius?: number | string;
  alwaysShowClearIcon?: boolean;
  iconColor?: string;
};

const FunnelBaseSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const {
    placeholder,
    optionLabel = "name",
    optionValue = "_id",
    fieldId,
    label,
    options,
    value,
    handleOnChange,
    search = true,
    onClear,
    onFilterClick,
    showDropdownIcon = true,
    children,
    dynamicTextWidth = false,
    wrapperClassName,
    titleWrapperClassName,
    alwaysShowClearIcon = false,
    iconColor = theme.palette.text.secondary,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [selected, setSelected] = React.useState(value);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleChange = (val) => {
    setSelected(val);
    setAnchorEl(null);
    handleOnChange(val);
  };
  const open = Boolean(anchorEl);

  let filteredOptions;
  if (searchText !== "") {
    filteredOptions = options.filter((option) => option[optionLabel].toLowerCase().includes(searchText.toLowerCase()));
  } else {
    filteredOptions = options;
  }

  const inputRef = React.useRef(null);
  const [inputWidth, setInputWidth] = React.useState("auto");

  React.useEffect(() => {
    // Calculate and update input width based on its content
    if (!dynamicTextWidth) return;
    const updateInputWidth = () => {
      const context = document.createElement("canvas").getContext("2d");
      context.font = "14px Montserrat";
      const textWidth = context.measureText(value?.[optionLabel]).width;
      console.log(textWidth, value?.[optionValue]);
      const padding = 10; // Adjust padding as needed
      setInputWidth(`${textWidth + padding}px`);
    };

    updateInputWidth();
  }, [value?.[optionLabel]]);
  return (
    <div className={ClassNames(classes.inputWrapper, wrapperClassName)}>
      {label && (
        <label htmlFor={fieldId} className={classes.inputLabel}>
          {label}
        </label>
      )}

      <div className={ClassNames(classes.titleWrapper, titleWrapperClassName)} onClick={(event) => handleClick(event)}>
        <Typography
          ref={inputRef}
          variant={"body1"}
          style={{ width: inputWidth }}
          className={ClassNames(
            classes.selectionResult,
            value && classes.selectedResults,
            anchorEl && value && classes.active
          )}
          color={"textPrimary"}
        >
          {value?.[optionLabel] || selected?.[optionLabel] || placeholder}
        </Typography>
        <div className={classes.actions}>
          {onFilterClick && value && (
            <IconButton
              className={classes.iconButton}
              onClick={(e) => {
                setAnchorEl(null);
                e.stopPropagation();
                onFilterClick();
              }}
            >
              <FunnelIcon className={classes.funnelIcon} />
            </IconButton>
          )}
          {onClear && (value || alwaysShowClearIcon) && (
            <IconButton
              className={classes.iconButton}
              onClick={(e) => {
                setSelected(null);
                e.stopPropagation();
                onClear();
              }}
            >
              <ClearIcon style={{ fill: iconColor }} className={classes.clearIcon} />
            </IconButton>
          )}
          {showDropdownIcon && (
            <IconButton className={classes.iconButton}>
              <DownChevronIcon className={classes.dropDownIcon} />
            </IconButton>
          )}
        </div>
      </div>
      {children}
      <Popper open={open} anchorEl={anchorEl} transition placement={"bottom-start"} className={classes.popper}>
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
          }}
        >
          <div className={classes.paper}>
            {search && (
              <div className={classes.searchWrap}>
                <TextField
                  type="search"
                  variant={"standard"}
                  autoFocus
                  value={searchText}
                  className={classes.searchField}
                  placeholder={"Search..."}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                  InputProps={{
                    classes: { input: classes.searchInput },
                    endAdornment: (
                      <InputAdornment position="start">
                        <Search className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
            <div className={classes.content}>
              {filteredOptions &&
                filteredOptions.length > 0 &&
                filteredOptions.map((option, index) => {
                  if (option[optionValue] === "properties_divider") {
                    return <Divider key={index} />;
                  }
                  return (
                    <div
                      key={`${option[optionValue]}-${index}`}
                      onClick={() => {
                        handleChange(option);
                      }}
                      className={ClassNames(
                        classes.optionWrapper,
                        value?.[optionValue] === option[optionValue] && classes.selectedOption
                      )}
                    >
                      <Typography className={classes.optionLabel}>{option[optionLabel]}</Typography>
                    </div>
                  );
                })}
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};
export default FunnelBaseSelect;
