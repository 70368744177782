import filter from "lodash/filter";
import _ from "lodash";
import moment from "moment";
import { FORUM } from "./constants/actionTypes";
import { eQuestionState } from "./constants/objectTypes";

const initialState = {
  questionsLoaded: false,
  questions: [],
  selectedQuestionId: null,
  selectedQuestion: null,

  questionsFilter: null,

  writers: [],

  tags: [],
  tagsSearchField: "",
  topics: [],
  topicsSearchField: "",

  categories: [],

  richTextDialogOpen: false,
  richTextDialogItemQuestion: null,
  richTextDialogIsAnswerIndex: null,

  diffViewDialogOpen: false,
  diffViewDialogOldText: null,
  diffViewDialogNewText: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FORUM.WRITERS_LOADED:
      return {
        writers: [{ email: "All", name: "Any writer" }, ...(action.payload.writers || [])],
      };
    case FORUM.NEW_QUESTION_CREATED:
      return {
        ...state,
        questionsFilter: "new",
        selectedQuestionId: action.payload.question._id,
        selectedQuestion: action.payload.question,
        questions: [action.payload.question, ...state.questions],
      };
    case FORUM.CHANGE_QUESTIONS_FILTER:
      return {
        ...state,
        questionsFilter: action.filter,
        questionsLoaded: false,
        questions: null,
        selectedQuestion: null,
        selectedQuestionId: null,
      };
    case FORUM.QUESTIONS_LOADED:
      return {
        ...state,
        questions: action.questions,
        questionsLoaded: true,
        // selectedQuestionId: state.selectedQuestionId !== null ? state.selectedQuestionId : action.payload.questions.length > 0 ? action.payload.questions[0]._id : initialState.selectedQuestionId,
      };
    case FORUM.QUESTION_SAVED: {
      const { question } = action;
      let remove = false;
      switch (state.questionsFilter) {
        case "new":
          remove = question.state !== eQuestionState.NEW;
          break;
        case "published":
          remove = question.state !== eQuestionState.PUBLISHED;
          break;
        case "drafted":
          remove = question.state !== eQuestionState.DRAFT;
          break;
        case "deleted":
          remove = question.state !== eQuestionState.DELETED;
          break;
        default:
          break;
      }
      let questions;
      let selectedQuestionId;
      if (remove) {
        questions = state.questions.filter((q) => q._id !== question._id);
        selectedQuestionId = questions.length > 0 ? questions[0]._id : initialState.selectedQuestionId;
      } else {
        questions = state.questions.map((q) => {
          if (question._id === q._id) {
            return question;
          }
          return q;
        });
        selectedQuestionId = state.selectedQuestionId;
      }
      return {
        ...state,
        selectedQuestionId,
        selectedQuestion: _.find(questions, { _id: selectedQuestionId }),
        questions,
      };
    }

    case FORUM.QUESTION_SELECTED:
      return {
        ...state,
        selectedQuestionId: action.questionId,
        questionsFilter: action.filter,
      };
    case FORUM.QUESTION_CHANGED:
      return {
        ...state,
        selectedQuestion: action.question,
      };
    case FORUM.QUESTION_POSTED_TIME_CHANGED: {
      let lastDate = moment(action.postedTime);
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          postedTime: moment(action.postedTime),
          answers: state.selectedQuestion.answers.map((answer) => {
            lastDate = moment(lastDate).add(1 + Math.floor(Math.random() * 5), "day");
            return {
              ...answer,
              postedTime: lastDate.toDate(),
            };
          }),
        },
      };
    }
    case FORUM.QUESTION_LOADED:
      return {
        ...state,
        selectedQuestion: action.payload.question,
      };
    case FORUM.ADD_NEW_ANSWER: {
      if (!state.selectedQuestion || state.selectedQuestion.answers.length === 0) {
        return {
          ...state,
          selectedQuestion: {
            ...state.selectedQuestion,
            answers: [{ text: "", postedTime: Date.now(), publish: false }],
          },
        };
      }
      const newAnswers = state.selectedQuestion.answers.slice();
      newAnswers.splice(action.index, 0, {
        text: "",
        postedTime: moment(
          state.selectedQuestion.answers[Math.min(action.index, state.selectedQuestion.answers.length - 1)].postedTime
        ).toDate(),
        publish: false,
      });
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          answers: newAnswers,
        },
      };
    }
    case FORUM.ANSWER_CHANGED: {
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          answers: state.selectedQuestion.answers.map((answer, index) =>
            action.index === index ? action.answer : answer
          ),
        },
      };
    }
    case FORUM.ANSWER_POSTED_TIME_CHANGED: {
      let lastDate = moment(action.postedTime);
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          answers: state.selectedQuestion.answers.map((answer, index) => {
            if (index === action.index) {
              return { ...answer, postedTime: action.postedTime };
            }
            if (index > action.index) {
              lastDate = moment(lastDate).add(1 + Math.floor(Math.random() * 5), "day");
              return { ...answer, postedTime: lastDate };
            }
            return answer;
          }),
        },
      };
    }
    case FORUM.ANSWER_DELETED:
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          answers: state.selectedQuestion.answers.filter((a, index) => index !== action.index),
        },
      };
    case FORUM.ON_TAGS_LOADED:
      return {
        ...state,
        tags: action.payload.tags,
      };
    case FORUM.ON_TAG_SELECTED:
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          tags: [...state.selectedQuestion.tags, action.tag],
        },
      };
    case FORUM.ON_TAG_REMOVED:
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          tags: filter(state.selectedQuestion.tags, (tag) => tag.name !== action.tag.name),
        },
      };
    case FORUM.ON_TAGS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        tagsSearchField: action.input,
      };
    case FORUM.ON_NEW_TAG_CREATED:
      return {
        ...state,
        tags: _.uniqBy([...state.tags, action.tag], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        tagsSearchField: "",
        selectedQuestion: {
          ...state.selectedQuestion,
          tags: _.uniqBy([...state.selectedQuestion.tags, action.tag], "_id"),
        },
      };
    case FORUM.ON_TOPICS_LOADED:
      return {
        ...state,
        topics: action.payload.topics,
      };
    case FORUM.ON_TOPIC_SELECTED:
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          topic: action.topic,
        },
      };
    case FORUM.ON_CATEGORIES_LOADED:
      return {
        ...state,
        topics: action.payload.categories,
      };
    case FORUM.ON_CATEGORY_SELECTED:
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          category: action.category,
        },
      };
    case FORUM.ON_TOPICS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        topicsSearchField: action.input,
      };
    case FORUM.ON_NEW_TOPIC_CREATED:
      return {
        ...state,
        topics: _.uniqBy([...state.topics, action.payload.topic], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        topicsSearchField: "",
        selectedQuestion: {
          ...state.selectedQuestion,
          topic: action.payload.topic,
        },
      };
    case FORUM.ON_SOURCE_URL_ADDED:
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          sourceURLs: [...(state.selectedQuestion.sourceURLs || []), action.url],
        },
      };
    case FORUM.ON_SOURCE_URL_REMOVED:
      return {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          sourceURLs: filter(state.selectedQuestion.sourceURLs, (url) => url !== action.url),
        },
      };
    case FORUM.OPEN_RICH_TEXT_EDITOR_DIALOG:
      return {
        ...state,
        richTextDialogOpen: true,
        richTextDialogItemQuestion: action.question,
        richTextDialogIsAnswerIndex: action.answerIndex,
      };
    case FORUM.CLOSE_RICH_TEXT_EDITOR_DIALOG:
      return {
        ...state,
        richTextDialogOpen: false,
        richTextDialogItemQuestion: null,
        richTextDialogIsAnswerIndex: null,
      };
    case FORUM.OPEN_DIFF_VIEW_DIALOG:
      return {
        ...state,
        diffViewDialogOpen: true,
        diffViewDialogOldText: action.oldText,
        diffViewDialogNewText: action.newText,
      };
    case FORUM.CLOSE_DIFF_VIEW_DIALOG:
      return {
        ...state,
        diffViewDialogOpen: false,
        diffViewDialogOldText: null,
        diffViewDialogNewText: null,
      };
    default:
      return state;
  }
};
