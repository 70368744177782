"use strict";
import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 20,
    height: 20,
  },
}));

type propTypes = {
  className?: string;
  onClick?: (e: any) => void;
};

function ModalCloseIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className, onClick } = props;
  return (
    <SvgIcon onClick={onClick} className={ClassNames(classes.icon, className)} viewBox={"0 0 21 20"}>
      <path d="M19.550,0.428 L19.550,0.428 C18.959,-0.164 18.000,-0.164 17.409,0.428 L9.991,7.845 L2.574,0.428 C1.983,-0.164 1.024,-0.164 0.433,0.428 L0.433,0.428 C-0.158,1.019 -0.158,1.977 0.433,2.569 L7.850,9.986 L0.433,17.404 C-0.158,17.995 -0.158,18.954 0.433,19.545 L0.433,19.545 C1.024,20.136 1.983,20.136 2.574,19.545 L9.991,12.127 L17.409,19.545 C18.000,20.136 18.959,20.136 19.550,19.545 L19.550,19.545 C20.141,18.954 20.141,17.995 19.550,17.404 L12.132,9.986 L19.550,2.569 C20.141,1.977 20.141,1.019 19.550,0.428 Z" />
    </SvgIcon>
  );
}

export default ModalCloseIcon;
