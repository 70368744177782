import React, { useMemo } from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import classNames from "classnames";

import { IReportsSettings, IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";
import ValueTab from "./components/ValueTab";
import CustomReportsTab from "./components/CustomReportsTab";
import CTASettingsTab from "./components/CTASettingsTab";

const useStyles = makeStyles((theme: Theme) => ({
  reportsSettingsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  saveButton: {
    marginTop: "20px",
    height: "40px",
    borderRadius: "75px",
  },
  ctaListWrapper: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: 30,
  },

  settingsHeaderWrapper: {
    backgroundColor: theme.palette.common.white,
    alignItems: "center",
    padding: "16px 25px",
    zIndex: 2,
    display: "flex",
    position: "sticky",
    top: 62,
    borderBottom: ` 1px solid ${theme.palette.divider}`,
  },
  settingsLinks: {
    display: "flex",
    gap: 20,
  },
  activeTab: {
    color: theme.palette.primary.main,
  },
  titleRow: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight as any,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    cursor: "pointer",
    transition: "color 0.2s ease-in-out",
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    margin: "25px 20px",
    padding: 40,
  },
  row: {
    display: "flex",
    alignItems: "center",
    gap: 30,
  },
  reportTitle: {
    minWidth: 250,
    fontSize: theme.typography.pxToRem(14),
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const Settings = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite } = props;

  const [currentTab, setCurrentTab] = React.useState<string>("reports");
  const [reportsSettings, setReportsSettings] = React.useState<IReportsSettings>(null);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    agent.ReportsSettings.get(selectedWebsite)
      .then((res) => {
        setReportsSettings(res.reportsSettings);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.create_error", { msg: e.message }));
      });
  }, [enqueueSnackbar, selectedWebsite]);

  const handleChange = (updatedReportsSettings) => {
    agent.ReportsSettings.update(selectedWebsite, updatedReportsSettings)
      .then((res) => {
        setReportsSettings(res.reportsSettings);
        enqueueSnackbar(I18n.t(`snackbar.update_success`));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  const CLIENT_REPORTS = useMemo(
    () =>
      reportsSettings
        ? [
            {
              key: "searchConsole",
              value: reportsSettings.reportsView.searchConsole,
              label: I18n.t("reports.dashboard.organic_traffic_report"),
            },
            {
              key: "searchConsoleEntail",
              value: reportsSettings.reportsView.searchConsoleEntail,
              label: I18n.t("reports.dashboard.organic_traffic_report_entail"),
            },
            {
              key: "impressions",
              value: reportsSettings.reportsView.impressions,
              label: I18n.t("reports.dashboard.impressions_report"),
            },
            {
              key: "impressionsEntail",
              value: reportsSettings.reportsView.impressionsEntail,
              label: I18n.t("reports.dashboard.impressions_report_entail"),
            },

            {
              key: "indexedQueries",
              value: reportsSettings.reportsView.indexedQueries,
              label: I18n.t("reports.dashboard.indexed_queries"),
            },
            {
              key: "indexedQueriesEntail",
              value: reportsSettings.reportsView.indexedQueriesEntail,
              label: I18n.t("reports.dashboard.indexed_queries_entail"),
            },
            {
              key: "wordsCount",
              value: reportsSettings.reportsView.wordsCount,
              label: I18n.t("reports.dashboard.words_count"),
            },
            {
              key: "articleCount",
              value: reportsSettings.reportsView.articleCount,
              label: I18n.t("reports.dashboard.article_count"),
            },
            {
              key: "topQueries",
              value: reportsSettings.reportsView.topQueries,
              label: I18n.t("reports.dashboard.top_queries_topics_pages"),
            },
            {
              key: "topQueriesEntail",
              value: reportsSettings.reportsView.topQueriesEntail,
              label: I18n.t("reports.dashboard.top_queries_topics_pages_entail"),
            },
            {
              key: "ctaClicks",
              value: reportsSettings.reportsView.ctaClicks,
              label: I18n.t("reports.dashboard.cta_clicks"),
            },
            {
              key: "ctaClicksEntail",
              value: reportsSettings.reportsView.ctaClicksEntail,
              label: I18n.t("reports.dashboard.cta_clicks_entail"),
            },
            {
              key: "ctaByUrl",
              value: reportsSettings.reportsView.ctaByUrl,
              label: I18n.t("reports.dashboard.cta_by_url"),
            },

            {
              key: "ctaByUrlEntail",
              value: reportsSettings.reportsView.ctaByUrlEntail,
              label: I18n.t("reports.dashboard.cta_by_url_entail"),
            },
            {
              key: "ctaByDestination",
              value: reportsSettings.reportsView.ctaByDestination,
              label: I18n.t("reports.dashboard.cta_by_destination"),
            },
            {
              key: "ctaByDestinationEntail",
              value: reportsSettings.reportsView.ctaByDestinationEntail,
              label: I18n.t("reports.dashboard.cta_by_destination_entail"),
            },
            {
              key: "ctaByLandingPage",
              value: reportsSettings.reportsView.ctaByLandingPage,
              label: I18n.t("reports.dashboard.cta_by_landing_page"),
            },
            {
              key: "ctaByLandingPageEntail",
              value: reportsSettings.reportsView.ctaByLandingPageEntail,
              label: I18n.t("reports.dashboard.cta_by_landing_page_entail"),
            },
          ]
        : [],
    [reportsSettings]
  );

  const TABS = useMemo(
    () => [
      {
        key: "reports",
        label: I18n.t("reports.settings.select_reports"),
      },
      {
        key: "cta_settings",
        label: I18n.t("reports.settings.cta_settings"),
      },
      {
        key: "custom_reports",
        label: I18n.t("reports.settings.custom_reports"),
      },
      {
        key: "value",
        label: I18n.t("reports.settings.value"),
      },
    ],
    []
  );

  return (
    reportsSettings && (
      <div className={classes.reportsSettingsWrapper}>
        <MHeaderMetaTags title={I18n.t(`meta_tags.cro.settings.title`)} />
        <div className={classes.settingsHeaderWrapper}>
          <div className={classes.settingsLinks}>
            {TABS.map((tab, index) => (
              <Typography
                variant={"subtitle2"}
                className={classNames(classes.titleRow, currentTab === tab.key ? classes.activeTab : undefined)}
                onClick={() => {
                  setCurrentTab(tab.key);
                }}
                key={index}
              >
                {tab.label}
              </Typography>
            ))}
          </div>
        </div>

        {currentTab === "reports" && (
          <div className={classes.container}>
            {CLIENT_REPORTS.map((report, index) => (
              <div className={classes.row} key={index}>
                <Typography variant={"body1"} className={classes.reportTitle}>
                  {report.label}
                </Typography>
                <Switch
                  color={"primary"}
                  checked={report.value}
                  onChange={() => {
                    handleChange({
                      ...reportsSettings,
                      reportsView: {
                        ...reportsSettings.reportsView,
                        [report.key]: !report.value,
                      },
                    });
                  }}
                />
              </div>
            ))}
          </div>
        )}

        {currentTab === "cta_settings" && (
          <div className={classes.container}>
            <CTASettingsTab
              reportsSettings={reportsSettings}
              setReportsSettings={setReportsSettings}
              handleChange={handleChange}
            />
          </div>
        )}

        {currentTab === "value" && (
          <ValueTab
            reportSettings={reportsSettings}
            setReportSettings={setReportsSettings}
            saveButtonClick={() => {
              handleChange(reportsSettings);
            }}
          />
        )}

        {currentTab === "custom_reports" && (
          <div className={classes.container}>
            <CustomReportsTab
              setReportsSettings={setReportsSettings}
              reportsSettings={reportsSettings}
              handleChange={handleChange}
            />
          </div>
        )}
      </div>
    )
  );
};

export default connect(mapStateToProps, {})(Settings);
