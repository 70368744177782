import * as React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import GoalEventsList from "./GoalEventsList";
import agent from "../../../agent";
import { useState } from "react";
import { IAnalyticsEventRow, IFunnelBreakdownColumn, IGoal, IWebsite } from "../../../reducers/constants/objectTypes";
import moment from "moment";
import GoalEventsHeader from "./GoalEventsHeader";
import localeHelper from "../../../helpers/localeHelper";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({});

type PropTypes = {
  selectedWebsite: IWebsite;
};

const GoalEvents = (props: PropTypes) => {
  const [loading, setLoading] = useState(true);
  const [goals, setGoals] = useState<IGoal[]>([]);
  const [events, setEvents] = useState<IAnalyticsEventRow[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
  const [searchText, setSearchText] = useState("");
  const [breakdownFilters, setBreakdownFilters] = useState<{ filter: IFunnelBreakdownColumn; value: any }[]>([
    {
      filter: {
        label: "Landing Page",
        value: {
          field: "first_page",
          table: "devices",
          like: true,
        },
      },
      value: null,
    },
    {
      filter: {
        label: "URL",
        value: {
          field: "url",
          table: "events",
          like: true,
        },
      },
      value: null,
    },
    // {
    //   filter: {
    //     value: {
    //       field: "country",
    //       table: "sessions",
    //       like: false,
    //       getLabel: (value) => {
    //         console.log("getLabel", value, localeHelper.getCountryFromCountryCode(value));
    //         return localeHelper.getCountryFromCountryCode(value);
    //       },
    //     },
    //     label: "Country",
    //   },
    //   value: null,
    // },
  ]);
  const [sortField, setSortField] = useState<{ direction: "desc" | "asc" | undefined; field: string }>({
    direction: "desc",
    field: "analytics.last_month.totalClicks",
  });
  const [timePeriod, setTimePeriod] = useState({ value: 1, period: "month", key: "last_month", label: "Last month" });
  const { selectedWebsite } = props;
  const { enqueueSnackbar } = useSnackbar();

  const loadGoalEvents = (canceled = false) => {
    setLoading(true);
    agent.Goals.getGoalEvents(
      selectedWebsite._id,
      moment()
        .subtract(timePeriod.value, timePeriod.period as any)
        .startOf("day")
        .toISOString(),
      searchText,
      breakdownFilters
    )
      .then((res) => {
        if (canceled) return;
        setEvents(res.events);
        setGoals(res.goals);
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    let canceled = false;
    loadGoalEvents(canceled);

    return () => {
      canceled = true;
    };
  }, [page, pageSize, searchText, sortField, timePeriod, breakdownFilters]);

  return (
    <MTableWrapper>
      <GoalEventsHeader
        selectedWebsite={selectedWebsite}
        timePeriod={timePeriod}
        handleTimePeriodChange={(tp) => setTimePeriod(tp)}
        searchText={searchText}
        handleSearchTextChange={(value) => setSearchText(value)}
        breakdownFilters={breakdownFilters}
        handleBreakdownFiltersChange={(breakdownFilters) => setBreakdownFilters(breakdownFilters)}
      />
      <GoalEventsList
        loading={loading}
        selectedWebsite={selectedWebsite}
        events={events}
        goals={goals}
        page={page}
        totalPages={totalPages}
        pageSize={pageSize}
        totalEvents={events.length}
        timePeriod={timePeriod}
        sortField={sortField}
        handleSortFieldChanged={(sortField) => setSortField(sortField)}
      />
      {totalPages > 0 && (
        <MTablePagination
          paginationLimit={pageSize}
          page={page}
          paginationPagesCount={totalPages}
          setPaginationLimit={(value) => setPageSize(value)}
          setPage={(value) => setPageSize(value)}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalEvents);
