import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 35,
    height: 36,
  },
}));

type PropTypes = {
  className?: string;
};

const DividerIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 36 35"} fill="none">
      <rect x="0.666626" width="35" height="35" rx="3" fill="#00BF8F" />
      <path
        d="M8.44446 28.0001V24.7839C8.44446 23.5997 9.41757 22.6397 10.618 22.6397L26.1043 22.6397C27.3047 22.6397 28.2778 23.5997 28.2778 24.7839V28.0001M8.44446 8.16675V11.383C8.44446 12.5671 9.41757 13.5271 10.618 13.5271H26.1043C27.3047 13.5271 28.2778 12.5671 28.2778 11.383V8.16675M8.44446 18.0835H8.51064M13.3201 18.0835H13.3862M18.328 18.0835H18.3942M23.2698 18.0835H23.336M28.2116 18.0835H28.2778"
        stroke="white"
        fill="none"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default DividerIcon;
