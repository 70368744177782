import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { I18n } from "react-redux-i18n";
import { TransitionProps } from "@material-ui/core/transitions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import { useSnackbar } from "notistack";
import DeleteIcon from "@material-ui/icons/Delete";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import MTextField from "../../../../../components/MTextField";
import CfButton from "../../../../../components/CfButton";
import agent from "../../../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    borderWidth: 0,
  },
  titleWrapper: {
    height: 85,
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    fontSize: theme.typography.pxToRem(24),
    color: "white",
    paddingLeft: 40,
  },
  title: {
    margin: 0,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    minHeight: 220,
    padding: 40,
  },
  dialogActions: {
    padding: 40,
  },
  closeButton: {
    position: "absolute",
    right: 20,
    // top: theme.spacing(1),
    color: "white",
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(36),
  },
  searchTextField: {
    marginRight: 10,
    flex: 1,
  },
  searchTextFieldInput: {
    height: 34,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
  },
  column: {
    flex: 1,
  },
  rowErrorWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  error: {
    color: "red",
  },
  success: {
    color: "green",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  open: boolean;
  editedRule?: any;

  handleCloseClick: Function;
  handleRuleCreated: Function;
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

const NewRedirectRuleDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, editedRule, selectedWebsite, handleCloseClick, handleRuleCreated } = props;
  const [loading, setLoading] = React.useState(false);
  const [rules, setRules] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [results, setResults] = React.useState(null);
  const [reloadOnCancel, setReloadOnCancel] = React.useState(false);

  const onOpen = () => {
    console.log("onOpen", editedRule);
    if (editedRule) {
      setRules([editedRule]);
    }
  };

  const close = () => {
    setLoading(false);
    setRules([]);
    setResults(null);
    handleCloseClick(reloadOnCancel);
    setReloadOnCancel(false);
  };

  const createRules = () => {
    setLoading(true);

    agent.Redirects.newRules(selectedWebsite._id, rules)
      .then((res) => {
        setResults(res.rules);
        // handleRuleCreated(res.rule);
        // close();
        setReloadOnCancel(true);
        setLoading(false);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
        setReloadOnCancel(true);
        setLoading(false);
      });
  };

  const removeDomain = (url) => {
    if (!url) {
      return url;
    }
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?((?:(?!www\.|\.).)+\.[a-zA-Z0-9.]+)/gim, "").trim();
  };

  const getModelName = (url) => {
    if (!url) {
      return null;
    }
    if (url.includes("/community") || url.includes("/forum")) {
      return "Community";
    }
    if (url.includes("/blog") || url.includes("/articles")) {
      return "Blog";
    }
    if (url.includes("/learning-center")) {
      return "Targeted";
    }
    if (url.includes("/learn") || url.includes("/answers")) {
      return "Targeted";
    }
    if (url.includes("/reviews")) {
      return "Reviews";
    }
    if (url.includes("/alternatives")) {
      return "Reviews";
    }
    if (url.includes("/compare")) {
      return "Compare";
    }
    if (url.includes("/best")) {
      return "Top 10";
    }
    if (url.includes("/category")) {
      return "Category";
    }
    if (url.includes("/topic")) {
      return "Topic";
    }
    // const modelNameRegex = /^(\/a)?(\/s)?\/([^\/]*)\//gmi
    // const match = modelNameRegex.exec(url);
    // if (match && match[3]) {
    //   return match[3];
    // }
    return null;
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      onEntered={(e) => onOpen()}
      onClose={(event) => close()}
      fullWidth
      maxWidth={"md"}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.titleWrapper}>
        <div className={classes.dialogTitle}>
          <Typography color={"inherit"} className={classes.title} variant={"h3"}>
            {I18n.t("redirect_rules.new_rule_dialog.title")}
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.row}>
          <Typography className={classes.column} color={"textPrimary"} variant={"body1"}>
            {I18n.t("redirect_rules.new_rule_dialog.from")}
          </Typography>
          <Typography className={classes.column} color={"textPrimary"} variant={"body1"}>
            {I18n.t("redirect_rules.new_rule_dialog.to")}
          </Typography>
          <Typography className={classes.column} color={"textPrimary"} variant={"body1"}>
            {I18n.t("redirect_rules.new_rule_dialog.type")}
          </Typography>
        </div>
        {rules.map((rule, index) => (
          <div key={`rule_${index}`} className={classes.rowErrorWrapper}>
            <div className={classes.row}>
              <MTextField
                fullWidth
                outlined
                autoFocus
                customClass={classes.searchTextField}
                inputClassName={classes.searchTextFieldInput}
                value={rule.from}
                placeholder={I18n.t("redirect_rules.new_rule_dialog.from_ph")}
                onChange={(userInput) => {
                  console.log("new rules", userInput);
                  const newRules = [...rules];
                  console.log("removeDomain(userInput)", removeDomain(userInput));
                  newRules[index].from = removeDomain(userInput);
                  if (!newRules[index].modelName) {
                    newRules[index].modelName = getModelName(newRules[index].from);
                  }
                  setRules(newRules);
                }}
              />
              <MTextField
                fullWidth
                outlined
                customClass={classes.searchTextField}
                inputClassName={classes.searchTextFieldInput}
                value={rule.to}
                placeholder={I18n.t("redirect_rules.new_rule_dialog.to_ph")}
                onChange={(userInput) => {
                  const newRules = [...rules];
                  newRules[index].to = removeDomain(userInput);
                  setRules(newRules);
                }}
              />
              <MTextField
                fullWidth
                outlined
                customClass={classes.searchTextField}
                inputClassName={classes.searchTextFieldInput}
                value={rule.modelName}
                placeholder={I18n.t("redirect_rules.new_rule_dialog.type_ph")}
                onChange={(userInput) => {
                  const newRules = [...rules];
                  newRules[index].modelName = userInput;
                  setRules(newRules);
                }}
              />
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => {
                  const newRules = [...rules];
                  newRules.splice(index, 1);
                  setRules(newRules);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            {(!rule.from || !rule.to || !rule.modelName) && (
              <Typography className={classes.error} color={"textPrimary"} variant={"body1"}>
                {"Missing Fields"}
              </Typography>
            )}
            {rule.from === rule.to && (
              <Typography className={classes.error} color={"textPrimary"} variant={"body1"}>
                {"From and To are the same"}
              </Typography>
            )}
            {results && (!results[index] || !results[index]._id) && (
              <Typography className={classes.error} color={"textPrimary"} variant={"body1"}>
                {`Error saving rule`}
              </Typography>
            )}
            {results && results[index] && results[index]._id && (
              <Typography className={classes.success} color={"textPrimary"} variant={"body1"}>
                {`Success`}
              </Typography>
            )}
          </div>
        ))}
        <div className={classes.row}>
          <MTextField
            fullWidth
            outlined
            autoFocus
            customClass={classes.searchTextField}
            inputClassName={classes.searchTextFieldInput}
            value={""}
            onPaste={(e) => {
              const text = e.clipboardData.getData("Text");
              const rows = text.split("\n");
              if (rows.length === 0) {
                return true;
              }

              if (rows.length === 1 && rows[0].split("\t").length < 2) {
                return true;
              }
              console.log("rows", rows);
              const newRules = [];
              rows.map((row) => {
                const col = row.split("\t");
                console.log("col", col);
                const from = removeDomain(col[0]);
                newRules.push({
                  from,
                  to: removeDomain(col[1]),
                  modelName: getModelName(from),
                });
              });
              console.log("texts", rows);
              console.log("newRules", newRules);
              setRules([...rules, ...newRules]);
              e.preventDefault();
              return false;
            }}
            placeholder={I18n.t("redirect_rules.new_rule_dialog.from_ph")}
            onChange={(userInput) => {
              console.log("onChange", userInput);
              setRules([...rules, { from: userInput, to: "", modelName: "" }]);
            }}
          />
          <MTextField
            fullWidth
            outlined
            customClass={classes.searchTextField}
            inputClassName={classes.searchTextFieldInput}
            value={""}
            placeholder={I18n.t("redirect_rules.new_rule_dialog.to_ph")}
            onChange={(userInput) => {
              setRules([...rules, { from: "", to: userInput, modelName: "" }]);
            }}
          />
          <MTextField
            fullWidth
            outlined
            customClass={classes.searchTextField}
            inputClassName={classes.searchTextFieldInput}
            value={""}
            placeholder={I18n.t("redirect_rules.new_rule_dialog.type_ph")}
            onChange={(userInput) => {
              setRules([...rules, { from: "", to: "", modelName: userInput }]);
            }}
          />
          <IconButton edge="end" aria-label="delete" style={{ visibility: "hidden" }}>
            <DeleteIcon />
          </IconButton>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CfButton
          height={40}
          text={
            results
              ? I18n.t(`redirect_rules.new_rule_dialog.done`)
              : I18n.t(`redirect_rules.new_rule_dialog.${editedRule ? "update" : "create"}`)
          }
          disabled={rules.length === 0}
          loading={loading}
          onClick={(e) => {
            if (results) {
              handleRuleCreated(results);
              close();
              return;
            }
            createRules();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default NewRedirectRuleDialog;
