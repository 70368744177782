import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { cloneDeep } from "lodash";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import {
  IProduct,
  IWebsite,
  IWidget,
  IWidgetItem,
  eWidgetFieldType,
  eWidgetSubtype,
} from "../../../../../../../reducers/constants/objectTypes";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import agent from "../../../../../../../agent";
import useDebounce from "../../../../../../../hooks/useDebounce";
import MSelect from "../../../../../../../components/MSelect";
import theme from "../../../../../../../themes/theme";

import TrashCanIcon from "../../../../../../../icons/TrashCanIcon";
import ProductDialog from "../../../../../../Modals/ProductDialog/ProductDialog";
import { excludeSameValueProperties, getProductDefaultValue } from "../../../helpers/widgets";
import { Clear } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
    marginBottom: 10,
  },
  selectCustomClass: {
    backgroundColor: "#EEF2F9",
    borderRadius: 3,
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectIndicator: {
    marginRight: 5,
    width: 12,
  },
  editIcon: {
    fontSize: 16,
    margin: "0 10px",
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  clearIcon: {
    fill: theme.palette.text.primary,
    fontSize: 18,
    "&:hover": {
      fill: theme.palette.error.main,
    },
  },
}));

type PropTypes = {
  widget: IWidget;
  selectedWebsite: IWebsite;
  selectedWidgetItem: IWidgetItem;
  editWidgetItem: (item: IWidgetItem) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  widget: state.widgetBuilder.widget,
});

const mapDispatchToProps = (dispatch) => ({
  editWidgetItem: (item: IWidgetItem) => dispatch({ type: WIDGET_BUILDER.ITEM_CHANGED, item }),
});

const WidgetItemProductPicker = (props: PropTypes) => {
  const classes = useStyles(props);
  const { widget, selectedWidgetItem, selectedWebsite } = props;

  if (!selectedWidgetItem) return null;

  const { product } = selectedWidgetItem;
  const { editWidgetItem } = props;
  const [searchText, setSearchText] = React.useState("");
  const [productOptions, setProductOptions] = React.useState([]);
  const [showProductDialog, setShowProductDialog] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);

  const changeProduct = (product: IProduct) => {
    const newWidgetItem = cloneDeep(selectedWidgetItem) as IWidgetItem;
    newWidgetItem.product = product;
    newWidgetItem.steps = newWidgetItem.steps.map((step) => {
      step.fields = step.fields.map((field) => {
        const productDefaultValue = getProductDefaultValue({
          widget: widget,
          field: field,
          product: newWidgetItem.product,
          defaultsOnly: true,
          website: selectedWebsite,
        });
        field.properties = excludeSameValueProperties(
          {
            ...field.properties,
            ...productDefaultValue,
          },
          productDefaultValue
        );

        if (
          [eWidgetFieldType.BUTTON, eWidgetFieldType.SECONDARY_BUTTON, eWidgetFieldType.PRIMARY_BUTTON].includes(
            field.type as eWidgetFieldType
          ) ||
          (widget.subtype === eWidgetSubtype.PRODUCTS_CAROUSEL && field.type === eWidgetFieldType.CALL_TO_ACTION)
        ) {
          field.properties = {
            ...field.properties,
            openAction: field.type === eWidgetFieldType.SECONDARY_BUTTON ? "product" : "call_to_action",
          };
        }

        return field;
      });
      return step;
    });

    editWidgetItem(newWidgetItem);
  };

  const removeProduct = () => {
    const newWidgetItem = cloneDeep(selectedWidgetItem) as IWidgetItem;
    newWidgetItem.product = null;

    newWidgetItem.steps = newWidgetItem.steps.map((step) => {
      step.fields = step.fields.map((field) => {
        if (
          [eWidgetFieldType.BUTTON, eWidgetFieldType.SECONDARY_BUTTON, eWidgetFieldType.PRIMARY_BUTTON].includes(
            field.type as eWidgetFieldType
          ) ||
          (widget.subtype === eWidgetSubtype.PRODUCTS_CAROUSEL && field.type === eWidgetFieldType.CALL_TO_ACTION)
        ) {
          field.properties = {
            ...field.properties,
            openAction: "custom",
          };
        }

        return field;
      });
      return step;
    });

    editWidgetItem(newWidgetItem);
  };

  const debouncedSearchText = useDebounce(searchText, 500);

  const searchProducts = React.useCallback(
    (keyword: string) => {
      agent.Product.autoComplete(selectedWebsite._id, keyword, true)
        .then((res) => {
          const productOptionsWithSelected =
            product && !keyword ? [product, ...res.items.filter((p) => p._id !== product?._id)] : res.items;
          setProductOptions(productOptionsWithSelected);
        })
        .catch((e) => console.log(e));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedWebsite._id]
  );

  React.useEffect(() => {
    searchProducts(debouncedSearchText);
  }, [debouncedSearchText, searchProducts]);

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.title}>
          {I18n.t(`widgets_builder.widget_editor.product_picker.title`)}
        </Typography>

        <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
          <MSelect
            searchable
            customClass={classes.selectCustomClass}
            customDropdownIndicatorClasses={classes.selectIndicator}
            valuePadding={"2px 10px 2px 6px"}
            optionLabel={"name"}
            optionValue={"_id"}
            options={productOptions}
            value={product || null}
            height={50}
            borderWidth={"0px"}
            placeholder={I18n.t(`widgets_builder.widget_editor.product_picker.select_product_placeholder`)}
            isBold={false}
            textColor={theme.palette.text.primary}
            menuPlacement={"bottom"}
            singleValueStyles={{ fontSize: theme.typography.pxToRem(14) }}
            fullWidth
            handleInputChange={(val) => {
              setSearchText(val);
            }}
            handleChange={(selectedItem) => {
              changeProduct(selectedItem);
            }}
            extraDropdownIcon={
              <>
                {isHover && product && (
                  <Clear
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeProduct();
                    }}
                    className={classes.clearIcon}
                  />
                )}
                {product && (
                  <EditOutlinedIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowProductDialog(true);
                    }}
                    className={classes.editIcon}
                  />
                )}
              </>
            }
          />
        </div>
      </div>
      {showProductDialog && product && (
        <ProductDialog
          onClose={() => setShowProductDialog(false)}
          product={product}
          selectedWebsite={selectedWebsite}
          onProductUpdated={(product) => {
            if (!product.active) {
              changeProduct(null);
              setProductOptions(productOptions.filter((p) => p._id !== product._id));
              setShowProductDialog(false);
            } else {
              changeProduct(product);
              setProductOptions([product, ...productOptions.filter((p) => p._id !== product._id)]);
            }
          }}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetItemProductPicker);
