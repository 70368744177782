import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import logoPlaceholder from "../img/product_logo_placeholder.png";
import { IProduct } from "../reducers/constants/objectTypes";
import { getImageCDNUrl } from "../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    borderRadius: (props: PropTypes) => props.borderRadius || "50%",
    border: `solid 1px ${theme.palette.divider}`,
    padding: 3,
    backgroundColor: "white",
    marginRight: (props: PropTypes) => props.marginRight || 0,
    position: "relative",
    "&:before": {
      content: '""',
      background: "none",
      border: "3px solid white",
      display: "block",
      position: "absolute",
      borderRadius: (props: PropTypes) => props.borderRadius || "50%",
      top: 1,
      left: 1,
      right: 1,
      bottom: 1,
      pointerEvents: "none",
    },
  },
  logoNoBorder: {
    borderRadius: (props: PropTypes) => props.borderRadius || "50%",
    marginRight: (props: PropTypes) => props.marginRight || 0,
  },
}));

type PropTypes = {
  width?: number;
  product: IProduct;
  className?: string;
  marginRight?: number;
  borderRadius?: number;
  showBorder?: boolean;
};

const ProductLogo = (props: PropTypes) => {
  const classes = useStyles(props);
  const { product, width = 90, showBorder = true, className = null } = props;
  return (
    <img
      width={width}
      height={width}
      src={
        getImageCDNUrl(
          product?.iconFile?.url ||
            product?.iconFile?.cdnUrl ||
            product?.logo ||
            product?.logoFile?.cdnUrl ||
            product?.logoFile?.url
        ) || logoPlaceholder
      }
      className={ClassNames(showBorder && classes.logo, !showBorder && classes.logoNoBorder, className)}
      alt={product?.logoFile?.alt || `${product?.name} logo`}
    />
  );
};

export default ProductLogo;
