import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Allotment } from "allotment";
import { useParams } from "react-router";
import { Theme } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { replace } from "connected-react-router";
import "allotment/dist/style.css";

import { HOME, WIDGET_RULE_EDITOR } from "../../../../../reducers/constants/actionTypes";
import { IWebsite, ICampaign, eMpEvent, eEntailProducts } from "../../../../../reducers/constants/objectTypes";
import agent from "../../../../../agent";
import { colors } from "../../../../../helpers/constants";
import CfProgressBar from "../../../../../components/CfProgressBar";
import CampaignBuilder from "./components/CampaignBuilder/CampaignBuilder";
import CampaignEditorHeader from "./components/CampaignEditorHeader";
import { web } from "../../../../../helpers/urlHelper";
import { WidgetRuleEditorRootState } from "../../../../../reducers/widgetRuleEditorReducer";
import classNames from "classnames";
import CampaignPreview from "./components/CampaignPreview/CampaignPreview";
import Joyride, { CallBackProps, Status, STATUS } from "react-joyride";
import { useTheme } from "@material-ui/core/styles";
import { MIXPANEL_PAGE_VIEW } from "../../../../../reducers/middlewares/mixpanelMiddleware";
import campaigns from "../Campaigns";

const useStyles = makeStyles((theme: Theme) => ({
  editorWrapper: {
    backgroundColor: colors.pageEditorBackgroundColor,
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  componentsWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 50px)",
  },
  allotmentMain: {
    backgroundColor: "#E4EAF5",
    paddingTop: 10,
  },
  allotmentLeft: {
    backgroundColor: "#F9FBFD",
  },
  allotmentRight: {
    // marginLeft: 10,
    border: 0,
    backgroundColor: "#fff",
    position: "relative",
    left: "470px !important",
    overflowX: "scroll",
    minWidth: 0,
    width: "calc(100% - 470px) !important",
    "&::before": {
      width: "0 !important",
    },
  },
  allotmentRightFullScreen: {
    backgroundColor: "white",
    margin: 0,
    width: "100% !important",
    left: "0 !important",
  },
  mainWrapper: {
    backgroundColor: "white",
    width: "100%",
    height: "calc(100vh)",
    flex: 1,
    position: "relative",
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  emptyPageWrapper: {
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "#fff",
    height: "100vh",
    flexDirection: "column",
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  addContainerMode: boolean;
  rule: ICampaign;
  preview: WidgetRuleEditorRootState["preview"];
  handleRuleLoaded: (payload) => void;
  unmount: () => void;
  returnToPreviousScreen: () => void;
  fromOnboarding: boolean;
  handleRuleOnboarding: (fromOnboarding: boolean) => void;
  pageLoaded: Function;
  mpEvent: (event: string, props: object) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  rule: state.widgetRuleEditor.rule,
  addContainerMode: state.widgetRuleEditor.addContainerMode,
  preview: state.widgetRuleEditor.preview,
  fromOnboarding: state.widgetRuleEditor.fromOnboarding,
});

const mapDispatchToProps = (dispatch) => ({
  handleRuleLoaded: (payload) => dispatch({ type: WIDGET_RULE_EDITOR.RULE_LOADED, payload }),
  returnToPreviousScreen: () => dispatch(replace(web.croCampaigns())),
  unmount: () => dispatch({ type: WIDGET_RULE_EDITOR.UNMOUNT }),
  handleRuleOnboarding: (fromOnboarding: boolean) =>
    dispatch({ type: WIDGET_RULE_EDITOR.FROM_ON_BOARDING, fromOnboarding }),
  pageLoaded: () =>
    dispatch({
      type: HOME.PAGE_LOADED,
      meta: {
        mixpanel: { event: MIXPANEL_PAGE_VIEW, props: { "page-name": "Campaigns", product: eEntailProducts.cro } },
      },
    }),
});

const CampaignEditor = (props: PropTypes) => {
  const classes = useStyles(props);
  const { rule, addContainerMode, selectedWebsite, preview, fromOnboarding, pageLoaded } = props;
  const { handleRuleLoaded, unmount, returnToPreviousScreen, handleRuleOnboarding } = props;
  const { ruleId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme<Theme>();
  const [run, setRun] = React.useState<boolean>(false);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: Status[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      handleRuleOnboarding(false);
    }
  };

  const onboardingTourSteps = [
    {
      target: "#scroll-to-widget",
      disableBeacon: true,
      content: I18n.t("onboarding.campaign_editor.scroll_to_widget"),
    },
    {
      target: "#new-widget",
      content: I18n.t("onboarding.campaign_editor.new_widget"),
    },
    // {
    //   target: "#update-theme",
    //   content: I18n.t("onboarding.campaign_editor.update_theme"),
    // },
    {
      target: "#publish-widgets",
      content: I18n.t("onboarding.campaign_editor.publish_widgets"),
    },
  ];

  React.useEffect(() => {
    // get the rule from the state
    if (fromOnboarding && rule) {
      setRun(true);
      return;
    }

    if (!ruleId) {
      return;
    }
    agent.Campaigns.getCampaign(selectedWebsite._id, ruleId)
      .then((res) => {
        handleRuleLoaded(res);
      })
      .catch((e) => {
        if (e.response && e.response.body) {
          enqueueSnackbar(e.response.body.message);
        }
        returnToPreviousScreen();
      });
  }, [ruleId, selectedWebsite]);

  React.useEffect(() => {
    pageLoaded();
    return () => {
      unmount();
    };
  }, []);

  if (!rule) {
    return (
      <div className={classes.emptyPageWrapper}>
        <CfProgressBar />
      </div>
    );
  }

  const fullScreen = preview.fullScreen || addContainerMode;

  return (
    <div className={classes.editorWrapper}>
      <Joyride
        steps={onboardingTourSteps}
        run={run}
        callback={handleJoyrideCallback}
        continuous
        scrollToFirstStep
        hideBackButton
        showProgress
        styles={{
          options: {
            primaryColor: theme.palette.primary.main,
            //  backgroundColor: theme.palette.primary.main,
            // textColor: theme.palette.common.white,
          },
        }}
      />

      <CampaignEditorHeader />
      <div className={classes.componentsWrapper}>
        <Allotment className={classes.allotmentMain}>
          <Allotment.Pane
            preferredSize={fullScreen ? 0 : 460}
            maxSize={460}
            visible={!fullScreen}
            className={classes.allotmentLeft}
            minSize={fullScreen ? 0 : 460}
          >
            <CampaignBuilder />
          </Allotment.Pane>
          <Allotment.Pane
            className={classNames(classes.allotmentRight, fullScreen && classes.allotmentRightFullScreen)}
          >
            <CampaignPreview />
          </Allotment.Pane>
        </Allotment>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignEditor);
