import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { find } from "lodash";
import MSelect from "../../../../../../components/MSelect";

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
}));

type PropTypes = {
  role: string;
  onRoleChanged: (role) => void;
};

const roles = [
  {
    label: "Writer",
    value: "writer",
  },
  {
    label: "Editor",
    value: "editor",
  },
];

const WriterRole = (props: PropTypes) => {
  const classes = useStyles(props);
  const { role, onRoleChanged } = props;

  return (
    <MSelect
      customClass={classes.wrapper}
      fullWidth
      options={roles}
      value={find(roles, (mc) => mc.value === role)}
      optionLabel={"label"}
      optionValue={"value"}
      borderWidth={1}
      borderRadius={4}
      height={37}
      placeholder={"Select..."}
      handleChange={(value) => {
        onRoleChanged(value);
      }}
    />
  );
};

export default WriterRole;
