import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";

import { IExpert } from "../../../../../reducers/constants/objectTypes";
import EvaluationCard from "./EvaluationCard";
import TabLayout from "./TabLayout";

type PropTypes = {
  expert: IExpert;
  rootElementRef: React.RefObject<HTMLElement>;

  handleExpertChange: (expert: IExpert) => void;
};

const useStyles = makeStyles(() => ({
  scrollSpyTabs: {
    marginTop: 2,
    top: 80,
  },
}));

const ExpertEvaluation = (props: PropTypes) => {
  const { expert, rootElementRef } = props;
  const { handleExpertChange } = props;

  const classes = useStyles();

  const sections = React.useMemo(
    () =>
      Object.keys(expert.evaluation).map((key) => ({
        key,
        label: I18n.t(`experts.profile.evaluation.${key}.title`),
        ref: React.createRef<HTMLDivElement>(),
      })),
    [expert.evaluation]
  );

  return (
    <TabLayout
      sections={sections}
      scrollMargin={150}
      rootElementRef={rootElementRef}
      classes={{
        scrollSpyTabs: classes.scrollSpyTabs,
      }}
    >
      {sections.map(({ key, label, ref }) => (
        <div key={key} ref={ref}>
          <EvaluationCard
            title={label}
            type={key as keyof IExpert["evaluation"]}
            options={expert.evaluation[key]}
            onChange={(options) =>
              handleExpertChange({ ...expert, evaluation: { ...expert.evaluation, [key]: options } })
            }
          />
        </div>
      ))}
    </TabLayout>
  );
};

export default ExpertEvaluation;
