import React from "react";
import { I18n } from "react-redux-i18n";
import { IWidgetHiddenFieldProps, IWidgetInputFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import GenericInputWidgetItem from "./components/GenericInputWidgetItem";

type PropTypes = {
  properties: IWidgetHiddenFieldProps;
  onPropertyChange(properties: IWidgetHiddenFieldProps): void;
};

const HiddenWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;

  return (
    <GenericInputWidgetItem
      hintDisabled
      widthDisabled
      requiredDisabled 
      properties={properties as IWidgetInputFieldProps}
      onPropertyChange={onPropertyChange}
    />
  );
};

export default HiddenWidgetItem;
