import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  className?: string;
};

function CommentResolvedIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={className} viewBox={"0 0 16 16"}>
      <path d="M6.75789 10.1053L4.61053 7.95789C4.52632 7.85965 4.41403 7.81053 4.27368 7.81053C4.13333 7.81053 4.00702 7.86667 3.89474 7.97895C3.79649 8.07719 3.74737 8.19649 3.74737 8.33684C3.74737 8.47719 3.79649 8.59649 3.89474 8.69474L6.33684 11.1158C6.46316 11.2421 6.60702 11.3053 6.76842 11.3053C6.92982 11.3053 7.06667 11.2421 7.17895 11.1158L12.0842 6.23158C12.1825 6.13333 12.2281 6.01404 12.2211 5.87368C12.214 5.73333 12.1614 5.61404 12.0632 5.51579C11.9649 5.41754 11.8456 5.36842 11.7053 5.36842C11.5649 5.36842 11.4386 5.41754 11.3263 5.51579L6.75789 10.1053ZM8 16C6.87719 16 5.82807 15.7965 4.85263 15.3895C3.87719 14.9825 3.02807 14.4175 2.30526 13.6947C1.58246 12.9719 1.01754 12.1228 0.610526 11.1474C0.203509 10.1719 0 9.12281 0 8C0 6.89123 0.203509 5.84912 0.610526 4.87368C1.01754 3.89825 1.58246 3.04912 2.30526 2.32632C3.02807 1.60351 3.87719 1.03509 4.85263 0.621053C5.82807 0.207017 6.87719 0 8 0C9.10877 0 10.1509 0.207017 11.1263 0.621053C12.1018 1.03509 12.9509 1.60351 13.6737 2.32632C14.3965 3.04912 14.9649 3.89474 15.3789 4.86316C15.793 5.83158 16 6.87719 16 8C16 9.12281 15.793 10.1719 15.3789 11.1474C14.9649 12.1228 14.3965 12.9719 13.6737 13.6947C12.9509 14.4175 12.1053 14.9825 11.1368 15.3895C10.1684 15.7965 9.12281 16 8 16ZM8 15.0526C9.99298 15.0526 11.6667 14.3754 13.0211 13.0211C14.3754 11.6667 15.0526 9.99298 15.0526 8C15.0526 6.00702 14.3754 4.33333 13.0211 2.97895C11.6667 1.62456 9.99298 0.947368 8 0.947368C6.00702 0.947368 4.33333 1.62456 2.97895 2.97895C1.62456 4.33333 0.947368 6.00702 0.947368 8C0.947368 9.99298 1.62456 11.6667 2.97895 13.0211C4.33333 14.3754 6.00702 15.0526 8 15.0526Z" />
    </SvgIcon>
  );
}

export default CommentResolvedIcon;
