import * as React from "react";
import { I18n } from "react-redux-i18n";
import { useState } from "react";
import { find } from "lodash";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";

import agent from "../../agent";
import { IWebsite } from "../../reducers/constants/objectTypes";
import MInputsDialog from "../../components/MInputsDialog";

const supportedTypes = [
  {
    type: "youtube",
    regex:
      /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/,
    embedUrl:
      "https://www.youtube.com/embed/<%= remote_id %>?autoplay=0&controls=0&rel=0&modestbranding=1&playsinline=1",
    // html: '<iframe style="width:100%;" height="320" frameborder="0" allowfullscreen></iframe>',
    height: 320,
    width: 580,
    id: ([id, params]) => {
      if (!params && id) {
        return id;
      }

      const paramsMap = {
        start: "start",
        end: "end",
        t: "start",

        time_continue: "start",
        list: "list",
      };

      params = params
        .slice(1)
        .split("&")
        .map((param) => {
          const [name, value] = param.split("=");

          if (!id && name === "v") {
            id = value;

            return null;
          }

          if (!paramsMap[name]) {
            return null;
          }

          if (name === "list") {
            return null;
          }

          return `${paramsMap[name]}=${value}`;
        })
        .filter((param) => !!param);

      console.log("params", params);
      return `${id}${params && params.length > 0 ? `?${params.join("&")}` : ""}`;
    },
  },
  {
    type: "youtube_shorts",
    regex:
      /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com\/)(?:v\/|u\/\w\/|embed\/|watch\?v=|shorts\/))([^#&?=\/]*)(?:\/|\?|&|$)/,
    embedUrl: "https://www.youtube.com/embed/<%= remote_id %>",
    // html: '<iframe style="width:100%;" height="320" frameborder="0" allowfullscreen></iframe>',
    height: 706,
    width: 397,
    id: ([id, params]) => {
      console.log("id", id, params);
      if (!params && id) {
        return id;
      }

      const paramsMap = {
        start: "start",
        end: "end",
        t: "start",

        time_continue: "start",
        list: "list",
      };

      params = params
        .slice(1)
        .split("&")
        .map((param) => {
          const [name, value] = param.split("=");

          if (!id && name === "v") {
            id = value;

            return null;
          }

          if (!paramsMap[name]) {
            return null;
          }

          if (name === "list") {
            return null;
          }

          return `${paramsMap[name]}=${value}`;
        })
        .filter((param) => !!param);

      return `${id}?${params.join("&")}`;
    },
  },
];

type PropTypes = {
  defaultUrl?: string;
  handleCloseClick: Function;
  handleInsertClick?: ({ video: IVideo, image: IFile }) => void;
  website: IWebsite;
};

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

function InsertVideoDialog(props: PropTypes) {
  const { handleCloseClick, website, defaultUrl, handleInsertClick } = props;

  const [url, setUrl] = useState(defaultUrl || "");
  const [loading, setLoading] = React.useState(false);

  const onInsertEmbedClicked = async () => {
    // test regex for "wistia.com"
    if (/wistia\.com/.test(url)) {
      handleInsertClick({
        video: {
          url: url,
          width: null,
          height: null,
          videoId: url,
          type: "wistia",
          structuredData: {
            name: url,
          },
        },
        image: null,
      });
      return;
    }

    if (!url || url.length === 0) {
      return;
    }
    let httpsUrl = url;
    if (!/^https?:\/\//i.test(httpsUrl)) {
      httpsUrl = `https://${url}`;
    }
    const service = find(supportedTypes, (st) => httpsUrl.match(st.regex));
    if (!service) {
      alert("currently supporting youtube videos only");
      return;
    }
    setLoading(true);
    const match = httpsUrl.match(service.regex);
    match.shift();
    const id = service.id instanceof Function ? service.id(match) : match[0];
    const embeddedUrl = service.embedUrl.replace("<%= remote_id %>", id);

    let response;
    try {
      response = await agent.Files.uploadThumbnail(website._id, id, httpsUrl, service.type);
    } catch (e) {
      setLoading(false);
      alert("video doesn't have thumbnails");
      console.log("Error getting video metadata: ", e);
      return;
    }
    setLoading(false);
    handleInsertClick({
      video: {
        url: embeddedUrl,
        width: service?.width,
        height: service?.height,
        videoId: id,
        type: service.type,
        structuredData: response?.results,
      },
      image: response?.file,
    });
  };

  const close = (e) => {
    e.stopPropagation();
    setUrl("");
    handleCloseClick();
  };

  return (
    <MInputsDialog
      open
      inputs={[
        {
          name: "url",
          type: "text",
          value: url,
          label: I18n.t("rich_text_editor.insert_embed.url"),
          placeholder: I18n.t("rich_text_editor.insert_embed.url_placeholder"),
          required: true,
        },
      ]}
      buttonProps={{
        text: I18n.t("rich_text_editor.insert_embed.insert"),
        loading: loading,
        disabled: !url || url.length === 0 || url === defaultUrl,
      }}
      handleChange={({ name, value }) => setUrl(value)}
      handleClose={close}
      handleSubmit={onInsertEmbedClicked}
    />
  );
}

export default InsertVideoDialog;
