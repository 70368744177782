import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme, useTheme } from "@material-ui/core/styles";
import CheckIcon from "../../../../icons/CheckIcon";
import classNames from "classnames";
import validator from "validator";

const useStyles = makeStyles((theme: Theme) => ({
  textFields: {
    maxWidth: 661,
    width: "100%",
    border: "none",
    borderRadius: 40,
    "& > *": {
      height: 42,
      borderRadius: 40,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium as any,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 20px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(25px, 14px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiFormHelperText-root": {
      height: "unset !important",
    },
  },
  validatorsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  validator: {
    display: "flex",
    alignItems: "center",
    "& span": {
      marginLeft: 10,
      fontSize: 14,
    },
  },
  checkIcon: {
    visibility: "hidden",
  },
  showCheckIcon: {
    visibility: "visible",
  },
}));

type PropTypes = {
  value: string;
  onChange: (string) => void;
  placeholder?: string;
  error?: boolean;
};

const PasswordTextField = (props: PropTypes) => {
  const classes = useStyles(props);
  const { value, placeholder, onChange, error = false } = props;
  const [focused, setFocused] = React.useState(false);
  const theme = useTheme();

  const validators = React.useMemo(() => {
    return [
      {
        valid: /[A-Z]/.test(value),
        message: I18n.t("signup.valid_password.uppercase"),
      },
      {
        valid: /[0-9]/.test(value),
        message: I18n.t("signup.valid_password.number"),
      },
      {
        valid: /[!@#$%^&*]/.test(value),
        message: I18n.t("signup.valid_password.special"),
      },
      {
        valid: value.length >= 8,
        message: I18n.t("signup.valid_password.length"),
      },
    ];
  }, [value]);

  return (
    <TextField
      id="outlined-required"
      value={value}
      type={"password"}
      onFocus={(v) => setFocused(true)}
      onBlur={(v) => setFocused(false)}
      className={classes.textFields}
      placeholder={placeholder}
      helperText={
        focused || error ? (
          <div className={classes.validatorsWrapper}>
            {validators.map((validator, index) => (
              <div key={`_validator_${index}`} className={classes.validator}>
                <CheckIcon className={classNames(classes.checkIcon, validator.valid && classes.showCheckIcon)} />
                <span
                  style={{ color: error && !validator.valid ? theme.palette.error.main : theme.palette.text.secondary }}
                >
                  {validator.message}
                </span>
              </div>
            ))}
          </div>
        ) : (
          ""
        )
      }
      onChange={(event) => onChange(event.target.value)}
      variant="outlined"
    />
  );
};

export default PasswordTextField;
