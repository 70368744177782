import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";
import ClassNames from "classnames";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "../../../icons/ErrorIcon";
import CheckCircleOutlineIcon from "../../../icons/CheckIcon";
import { web } from "../../../helpers/urlHelper";
import { IAdminUser, IWebsite } from "../../../reducers/constants/objectTypes";
import agent from "../../../agent";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  missingBioErrorWrapper: {
    position: "absolute",
    right: 5,
    display: "flex",
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: "green",
  },
  link: {
    display: "flex",
  },
  linkIcon: {
    color: "white",
    width: 20,
    height: 20,
    marginLeft: 10,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  refreshIcon: {
    cursor: "pointer",
    width: 20,
    height: 20,
    marginLeft: 10,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  user: IAdminUser;
  onBioMissing: funciton;
};

const PageEditorMissingBioTooltip = (props: PropTypes) => {
  const classes = useStyles(props);
  const { user, selectedWebsite, onBioMissing } = props;
  const [selectedUserBio, setSelectedUserBio] = useState(null);

  const loadBio = () => {
    agent.Writers.getWriterBio(selectedWebsite, user)
      .then((res) => {
        setSelectedUserBio(res.bio);
        if (onBioMissing != null) onBioMissing(!res.bio || !res.bio.active);
      })
      .catch((e) => {
        // show error message
      });
  };

  React.useEffect(() => {
    if (!selectedWebsite || !user) {
      return;
    }

    loadBio();
  }, [selectedWebsite, user]);

  if (!user) {
    return null;
  }

  if (!selectedUserBio || !selectedUserBio.active) {
    return (
      <div className={ClassNames(classes.missingBioErrorWrapper, classes.error)}>
        <Tooltip
          interactive
          title={
            <div className={classes.wrapper}>
              {I18n.t(`edit_post.missing_bio`)}
              <a className={classes.link} href={web.settingsBios(user?._id)} target={"_blank"}>
                <OpenInNewIcon className={classes.linkIcon} />
              </a>
              <RefreshIcon onClick={loadBio} className={classes.refreshIcon} />
            </div>
          }
        >
          <IconButton>
            <ErrorIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={ClassNames(classes.missingBioErrorWrapper, classes.success)}>
      <Tooltip
        interactive
        title={
          <div className={classes.wrapper}>
            {I18n.t(`edit_post.open_bio`)}
            <a className={classes.link} href={web.settingsBios(user?._id)} target={"_blank"}>
              <OpenInNewIcon className={classes.linkIcon} />
            </a>
          </div>
        }
      >
        <IconButton>
          <CheckCircleOutlineIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default PageEditorMissingBioTooltip;
