import React from "react";
import { I18n } from "react-redux-i18n";
import SelectPlan from "./SelectPlan";
import CustomizePlan from "./CustomizePlan";
import { IProposal } from "../../../../../reducers/constants/objectTypes";
import MHeaderMetaTags from "../../../../../components/MHeaderMetaTags";

type PropTypes = {
  proposal: IProposal;
  handleChange: (value) => void;
};
const Pricing = (props: PropTypes) => {
  const { proposal, handleChange } = props;

  return (
    <>
      <MHeaderMetaTags title={I18n.t(`meta_tags.legacy.account_pricing.title`)} />
      <SelectPlan proposal={proposal} />
      <CustomizePlan proposal={proposal} handleChange={handleChange} />
    </>
  );
};

export default Pricing;
