import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import { IWebsite, IWebsiteCommonComponents } from "../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import ImagePicker from "../../../components/ImagePicker";
import MRouterPrompt from "../../../components/MRouterPrompt";

const useStyles = makeStyles((theme: Theme) => ({
  imageRow: {
    display: "flex",
    flexDirection: "column",
    marginRight: 15,
    "&:last-child": {
      marginRight: 0,
    },
  },

  imageFieldTitle: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: theme.typography.pxToRem(14),
  },
  imageFieldDimensions: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
  },
  imageFieldWrapper: {
    display: "flex",
    marginBottom: 15,
    gap: 10,
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  page: state.websitePages.commonComponents,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (commonComponents) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "commonComponents",
      value: commonComponents,
    }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  page: IWebsiteCommonComponents;

  onPropertyChanged: (commonComponents) => void;
};

const LoginPopup = (props: PropTypes) => {
  const { page, selectedWebsite, onPropertyChanged } = props;
  const classes = useStyles(props);
  return (
    <PageSection sectionTitle={I18n.t("website.common_components.login_dialog.name")}>
      <MRouterPrompt />
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={page.loginDialog?.title}
        placeholder={I18n.t("website.common_components.login_dialog.title_ph")}
        title={I18n.t("website.common_components.login_dialog.title")}
        maxLength={100}
        height={37}
        borderRadius={4}
        marginBottom={30}
        handleValueChange={(value) =>
          onPropertyChanged({ ...page, loginDialog: { ...page.loginDialog, title: value } })
        }
      />
      <TextFieldMaxLength
        titleAndLengthLocation={"top"}
        value={page.loginDialog?.text}
        placeholder={I18n.t("website.common_components.login_dialog.text_ph")}
        title={I18n.t("website.common_components.login_dialog.text")}
        maxLength={300}
        height={37}
        multiLine
        borderRadius={4}
        marginBottom={30}
        handleValueChange={(value) => onPropertyChanged({ ...page, loginDialog: { ...page.loginDialog, text: value } })}
      />

      <div className={classes.imageRow}>
        <div className={classes.imageFieldWrapper}>
          <Typography variant={"body1"} className={classes.imageFieldTitle}>
            {I18n.t("website.login")}
          </Typography>
          <Typography variant={"body1"} className={classes.imageFieldDimensions}>
            {I18n.t("website.login_dimensions")}
          </Typography>
        </div>
        <ImagePicker
          key={`login-image`}
          selectedWebsite={selectedWebsite}
          image={page.loginDialog?.loginDialogImage}
          objectFit={"contain"}
          imageHeight={"152px"}
          imageWidth={"152px"}
          onImageChanged={(image) => {
            onPropertyChanged({
              ...page,
              loginDialog: { ...page.loginDialog, loginDialogImage: image },
            });
          }}
        />
      </div>
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPopup);
