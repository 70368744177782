import * as React from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { IWebsite, IWriterCommunityAnswer } from "../../../../reducers/constants/objectTypes";
import WriterCommunityPostItemRow from "./WriterCommunityPostItemRow";
import MTableHeader from "../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../components/Table/Table/MTableHeaderLabel";
import MTableHeaderSortLabel from "../../../../components/Table/Table/MTableHeaderSortLabel";
import MTable from "../../../../components/Table/Table/MTable";
import MTableRowProgressBar from "../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../components/Table/Rows/MTableRowEmptyRow";

type PropTypes = {
  selectedWebsite: IWebsite;
  answersLoaded: boolean;

  personal: IWriterCommunityAnswer[];
  rejected: IWriterCommunityAnswer[];
  publicAnswers: IWriterCommunityAnswer[];
  sortField: any;
  setSortField: (input: any) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,

  answersLoaded: state.community.writeAnswersLoaded,
  rejected: state.community.writeRejected,
  personal: state.community.writePersonal,
  publicAnswers: state.community.writePublic,
});

const mapDispatchToProps = (dispatch) => ({});

const WriterCommunityItemListSubHeader = (props: {
  title: string;
  topHeader: boolean;
  sortField: any;
  setSortField: (input: any) => void;
}) => {
  const theme: any = useTheme();
  return (
    <MTableHeader>
      <MTableHeaderLabel columnWidth={"33%"} fontSize={theme.typography.pxToRem(21)}>
        {props.title}
      </MTableHeaderLabel>
      {props.topHeader && (
        <>
          <MTableHeaderSortLabel
            onClick={(result) => props.setSortField(result)}
            sortField={props.sortField}
            field={"createdAt"}
          >
            {I18n.t("workflow.writer.listHeader.created_at")}
          </MTableHeaderSortLabel>
          <MTableHeaderLabel>{I18n.t("workflow.writer.listHeader.category")}</MTableHeaderLabel>
          <MTableHeaderLabel>{I18n.t("workflow.writer.listHeader.actions")}</MTableHeaderLabel>
        </>
      )}
    </MTableHeader>
  );
};
const WriterCommunityItemList = (props: PropTypes) => {
  const { answersLoaded, rejected, publicAnswers, personal, sortField, setSortField } = props;

  return (
    <MTable>
      {!answersLoaded && <MTableRowProgressBar />}

      {answersLoaded && personal.length === 0 && rejected.length === 0 && publicAnswers.length === 0 && (
        <MTableRowEmptyRow />
      )}

      {answersLoaded && personal.length > 0 && (
        <WriterCommunityItemListSubHeader
          setSortField={setSortField}
          sortField={sortField}
          title={I18n.t("writers.answers.your_answers")}
          topHeader
        />
      )}

      {answersLoaded &&
        personal.length > 0 &&
        personal.map((item, index) => <WriterCommunityPostItemRow key={item._id} answer={item} />)}

      {answersLoaded && rejected.length > 0 && (
        <WriterCommunityItemListSubHeader
          setSortField={setSortField}
          sortField={sortField}
          title={I18n.t("workflow.writer.rejected_post")}
          topHeader={personal.length === 0}
        />
      )}
      {answersLoaded &&
        rejected.length > 0 &&
        rejected.map((item, index) => <WriterCommunityPostItemRow key={item._id} answer={item} />)}

      {answersLoaded && publicAnswers.length > 0 && (
        <WriterCommunityItemListSubHeader
          sortField={setSortField}
          setSortField={setSortField}
          title={I18n.t("writers.answers.select_a_community_post")}
          topHeader={personal.length === 0 && rejected.length === 0}
        />
      )}
      {answersLoaded &&
        publicAnswers.length > 0 &&
        publicAnswers.map((item, index) => <WriterCommunityPostItemRow key={item._id} answer={item} />)}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WriterCommunityItemList);
