import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { Theme } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import ClassNames from "classnames";
import DownChevronIcon from "../../../icons/DownChevronIcon";
import MRangeInput from "../../MRangeInput";
import { displayRangeInputLabels } from "../../../helpers/strategyHelper";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 10,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    gap: 10,
  },
  popper: {
    zIndex: 3,
  },
  titleWrapper: {
    cursor: "pointer",
    color: theme.palette.text.disabled,
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    margin: "0 20px",
  },
  selectionResult: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    whiteSpace: "nowrap",
  },
  selectedResults: {
    color: "#000",
  },
  clearIcon: {
    fontSize: 14,
    marginLeft: "-6px",
    color: "#000",
  },
}));

type PropTypes = {
  placeholder?: string;
  title?: string;
  handleChange: (value: any) => void;
  rangValues: number[];
  clearable?: boolean;
};

const MTableToolbarBaseRangeSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    title,
    placeholder = I18n.t("common.select_placeholder"),
    rangValues = [undefined, undefined],
    clearable = true,
    handleChange,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const [values, setValues] = React.useState({ minValue: rangValues[0], maxValue: rangValues[1] });

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <div className={classes.titleWrapper} onClick={(event) => handleClick(event)}>
          <Typography
            variant={"body1"}
            className={ClassNames(
              classes.selectionResult,
              values.minValue && values.maxValue && classes.selectedResults
            )}
            color={"textPrimary"}
          >
            {displayRangeInputLabels(placeholder, placeholder, values.minValue, values.maxValue)}
          </Typography>
          {values.minValue && values.maxValue && clearable && (
            <ClearIcon
              className={classes.clearIcon}
              onClick={(e) => {
                e.stopPropagation();
                handleChange({ minValue: undefined, maxValue: undefined });
                setValues({ minValue: undefined, maxValue: undefined });
              }}
            />
          )}
          <DownChevronIcon />
        </div>
        <Popper open={open} anchorEl={anchorEl} transition placement={"bottom"} className={classes.popper}>
          <MRangeInput
            title={title}
            values={values}
            handleValueChange={(value) => {
              setValues(value);
              handleChange(value);
            }}
            handleMinValueChange={(value) => {
              setValues({ ...values, minValue: value });
            }}
            handleMaxValueChange={(value) => {
              setValues({ ...values, maxValue: value });
            }}
            handleSubmit={(pageCurrentValues?: any) => {
              handleChange(pageCurrentValues || [values.minValue, values.maxValue]);
              setAnchorEl(null);
            }}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default MTableToolbarBaseRangeSelect;
