import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { push } from "connected-react-router";
import { OUTLINE } from "../../../reducers/constants/actionTypes";
import { IOutline, IWebsite } from "../../../reducers/constants/objectTypes";
import OutlinePageItem from "./OutlinePageItem";
import agent from "../../../agent";
import { web } from "../../../helpers/urlHelper";
import MTableHeader from "../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../components/Table/Rows/MTableRowEmptyRow";
import MTable from "../../../components/Table/Table/MTable";
import MTableRowTitle from "../../../components/Table/Rows/MTableRowTitle";

type PropTypes = {
  view: "DATA_VIEW" | "EDITOR_VIEW";
  outlinesLoaded: boolean;
  outlines: IOutline[];
  inProgressOutlines: IOutline[];
  inProgressByOthersOutlines: IOutline[];
  refreshOutlines: boolean;

  website: IWebsite;

  searchTextFilter: string;

  handleOutlinesLoaded: (outlinePages) => void;
  handleOutlinePageRemoved: (outlinePage) => void;
  openOutlinePage: (outlinePage, view) => void;
  onOutlinePageUpdated: (outlinePage) => void;
};

const mapStateToProps = (state) => ({
  outlinesLoaded: state.outline.outlinesLoaded,
  outlines: state.outline.outlines,
  inProgressOutlines: state.outline.inProgressOutlines,
  inProgressByOthersOutlines: state.outline.inProgressByOthersOutlines,
  refreshOutlines: state.outline.refreshOutlines,

  searchTextFilter: state.outline.searchTextFilter,
});

const mapDispatchToProps = (dispatch) => ({
  handleOutlinesLoaded: (payload) => {
    dispatch({ type: OUTLINE.ON_OUTLINE_PAGES_LOADED, payload });
  },
  handleOutlinePageRemoved: (outline) => {
    dispatch({ type: OUTLINE.ON_OUTLINE_PAGE_REMOVED, outline });
  },
  onOutlinePageUpdated: (outline) => {
    dispatch({ type: OUTLINE.ON_OUTLINE_PAGE_CHANGED, outline });
  },
  openOutlinePage: (outlinePage, view) => {
    if (view === "DATA_VIEW") {
      dispatch(push(web.outlines(outlinePage._id)));
    } else if (view === "EDITOR_VIEW") {
      dispatch(push(web.workflowOutlines(outlinePage._id)));
    }
  },
});

const groupBy = function (array, key, subKey) {
  return array.reduce((storage, item) => {
    if (item[key]?.[subKey]) {
      (storage[item[key]?.[subKey]] = storage[item[key]?.[subKey]] || []).push(item);
    }
    return storage;
  }, {});
};

const OutlinePagesList = (props: PropTypes) => {
  const {
    website,
    view,
    searchTextFilter,
    refreshOutlines,
    outlinesLoaded,
    outlines,
    inProgressOutlines,
    inProgressByOthersOutlines,
  } = props;
  const { handleOutlinesLoaded, handleOutlinePageRemoved, openOutlinePage, onOutlinePageUpdated } = props;
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    console.log("Get Outline Pages", website);
    if (!website) {
      return;
    }
    handleOutlinesLoaded(agent.Outlines.getOutlineList(website._id, view));
  }, [website, view, handleOutlinesLoaded]);

  React.useEffect(() => {
    if (!refreshOutlines || !website) {
      return;
    }
    handleOutlinesLoaded(agent.Outlines.getOutlineList(website._id, view));
  }, [website, refreshOutlines, handleOutlinesLoaded]);

  const updateOutline = (outline) => {
    agent.Outlines.update(outline)
      .then((res) => {
        onOutlinePageUpdated(res.outline);
        enqueueSnackbar(I18n.t("snackbar.update_success"));
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });
  };

  const filteredOutlines =
    searchTextFilter && outlinesLoaded
      ? outlines.filter((outlinePage) =>
          outlinePage.postTitle?.title?.toLowerCase().includes(searchTextFilter.toLowerCase())
        )
      : outlines;

  const filteredInProgressOutlines =
    searchTextFilter && outlinesLoaded
      ? inProgressOutlines.filter((outlinePage) =>
          outlinePage.postTitle?.title?.toLowerCase().includes(searchTextFilter.toLowerCase())
        )
      : inProgressOutlines;

  const filteredInProgressByOthersOutlines =
    searchTextFilter && outlinesLoaded
      ? inProgressByOthersOutlines.filter((outlinePage) =>
          outlinePage.postTitle?.title?.toLowerCase().includes(searchTextFilter.toLowerCase())
        )
      : inProgressByOthersOutlines;

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabel columnWidth={"33%"}> {""} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("outline.header.date")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("outline.header.volume")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("outline.header.relevancy")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("outline.header.intent")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("outline.header.competition")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("outline.header.variations")} </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("outline.header.potential")} </MTableHeaderLabel>
        {view === "EDITOR_VIEW" && <MTableHeaderLabel>{I18n.t("outline.header.analyst")} </MTableHeaderLabel>}
        <MTableHeaderLabel>{""}</MTableHeaderLabel>
      </MTableHeader>

      {!outlinesLoaded && <MTableRowProgressBar />}

      {outlinesLoaded &&
        filteredOutlines.length === 0 &&
        filteredInProgressOutlines.length === 0 &&
        filteredInProgressByOthersOutlines.length === 0 && <MTableRowEmptyRow />}

      {outlinesLoaded && inProgressOutlines.length > 0 && (
        <>
          <MTableRowTitle
            text={
              inProgressOutlines.length > 0 && view === "EDITOR_VIEW"
                ? I18n.t("outline.header.approve_outlines")
                : I18n.t("outline.header.submit_your_outlines")
            }
          />

          {inProgressOutlines.map((outlinePage) => (
            <OutlinePageItem
              key={outlinePage._id}
              view={view}
              outlinePage={outlinePage}
              handleButtonClick={() => {
                updateOutline({
                  ...outlinePage,
                  [view === "DATA_VIEW" ? "analyst" : "editor"]: null,
                });
              }}
              openOutlinePage={() => openOutlinePage(outlinePage, view)}
            />
          ))}
        </>
      )}

      {outlinesLoaded && filteredOutlines.length > 0 && (
        <>
          <MTableRowTitle text={I18n.t("outline.header.pending_outlines")} />

          {filteredOutlines.map((outline: IOutline, index) => (
            <OutlinePageItem
              key={`pending_outline_${outline._id}`}
              view={view}
              outlinePage={outline}
              handleButtonClick={() => {}}
              openOutlinePage={() => openOutlinePage(outline, view)}
            />
          ))}
        </>
      )}

      {outlinesLoaded && filteredInProgressByOthersOutlines.length > 0 && (
        <>
          <MTableRowTitle text={I18n.t("outline.header.taken_outlines")} />
          {filteredInProgressByOthersOutlines.map((outline: IOutline, index) => (
            <OutlinePageItem
              key={`taken_outline_${outline._id}`}
              view={view}
              outlinePage={outline}
              handleButtonClick={() => {}}
              openOutlinePage={() => openOutlinePage(outline, view)}
            />
          ))}
        </>
      )}
    </MTable>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlinePagesList);
