import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { I18n } from "react-redux-i18n";
import GeneralAppearanceDesign from "./wrappers/GeneralAppearanceDesign";
import HeaderDesign from "./wrappers/HeaderDesign";
import InputFieldsDesign from "./wrappers/InputFieldsDesign";
import ButtonDesign from "./wrappers/ButtonDesign";
import BackgroundDesign from "./wrappers/BackgroundDesign";
import ArrowRightRoundedIcon from "../../../../../../icons/ArrowRightRoundedIcon";
import LayoutDesign from "./wrappers/LayoutDesign";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";

  return {
    root: (props: PropTypes) => {
      const backgroundColor = isDark ? "#343434" : palette.common.white;
      const backgroundColorOnHover = palette.action.hover;
      return {
        minHeight: "fit-content",
        padding: "18px 5px 18px 5px",
        marginBottom: -1,
        flex: 1,
        display: "flex",
        backgroundColor,
        "&:hover": {
          backgroundColor: backgroundColorOnHover,
        },
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        verticalAlign: "middle",
        textTransform: "none",
        cursor: "pointer",
        border: "0.2px solid #525252",
        borderRadius: "0px",
        "&:first-child": {
          borderRadius: "3px 3px 0px 0px",
        },
        "&:last-child": {
          borderRadius: "0px 0px 3px 3px",
        },
      };
    },
    title: (props: PropTypes) => {
      const color = palette.text.primary;
      return {
        fontSize: 16,
        fontWeight: typography.fontWeightMedium,
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: 200,
        color,
        marginLeft: "10px",
      };
    },
    arrow: (props: PropTypes) => ({
      color: "white",
      justifySelf: "flex-end",
    }),
    container: {
      height: "100%",
      width: "100%",
      padding: "30px",
    },
  };
});

type PropTypes = {};

const WidgetDesignTab = (props: PropTypes) => {
  const classes = useStyles(props);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const options = React.useMemo(
    () => [
      {
        key: "general_appearance",
        value: I18n.t("widgets_builder.design.general_appearance"),
      },
      {
        key: "layout",
        value: I18n.t("widgets_builder.design.layout"),
      },
      {
        key: "header",
        value: I18n.t("widgets_builder.design.header"),
      },
      {
        key: "input_fields",
        value: I18n.t("widgets_builder.design.input_fields"),
      },
      {
        key: "button_design",
        value: I18n.t("widgets_builder.design.button_design"),
      },

      {
        key: "background",
        value: I18n.t("widgets_builder.design.background"),
      },
    ],
    []
  );

  if (selectedItem) {
    switch (selectedItem.key) {
      case "general_appearance":
        return <GeneralAppearanceDesign goBack={setSelectedItem} />;
      case "layout":
        return <LayoutDesign goBack={setSelectedItem} />;
      case "header":
        return <HeaderDesign goBack={setSelectedItem} />;
      case "input_fields":
        return <InputFieldsDesign goBack={setSelectedItem} />;
      case "button_design":
        return <ButtonDesign goBack={setSelectedItem} />;
      case "background":
        return <BackgroundDesign goBack={setSelectedItem} />;
      default:
        throw new Error(`Unknown type: ${selectedItem.key}`);
    }
  }

  return (
    <div className={classes.container}>
      {options.map((item, i) => (
        <div key={item.key} className={classes.root} onClick={() => setSelectedItem(item)}>
          <Typography className={classes.title}>{item.value}</Typography>
          <ArrowRightRoundedIcon className={classes.arrow} />
        </div>
      ))}
    </div>
  );
};

export default WidgetDesignTab;
