import * as React from "react";
import { I18n } from "react-redux-i18n";
import filter from "lodash/filter";
import { uniqBy } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import MTopic from "../../../../../../components/MTopic";
import { IPage, IProduct, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import agent from "../../../../../../agent";
import MSelect from "../../../../../../components/MSelect";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: (props: PropTypes) => props.marginTop || 0,
    marginBottom: (props: PropTypes) => props.marginBottom || 0,
    marginLeft: (props: PropTypes) => props.marginLeft || 0,
    marginRight: (props: PropTypes) => props.marginRight || 0,
  },
  rowWrapper: {
    marginTop: (props: PropTypes) => props.marginTop || 0,
    marginLeft: (props: PropTypes) => props.marginLeft || 0,
    marginRight: (props: PropTypes) => props.marginRight || 0,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
  },
  length: {
    fontSize: theme.typography.pxToRem(14),
  },
  title: {
    color: (props: PropTypes) => props.titleColor || theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 10,
    opacity: (props: PropTypes) => (props.disabled ? 0.5 : 1),
  },
  tagsCustomClass: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  select: {
    height: 37,
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: 3,
  },
  productsWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: 7,
    gap: 5,
  },
  tagCustomClass: {
    minHeight: 24,
    backgroundColor: theme.palette.divider,
  },
  tagIconCustomClass: {
    color: theme.palette.text.primary,
  },
  titleOnTop: {
    marginRight: 10,
    marginBottom: 0,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  pages: { slug: string; title: string }[];
  placeholder?: string;
  handlePagesChange: Function;
  maxPages?: number;
  title?: string;
  titleAndLengthLocation?: "left" | "top";
  titleClassName?: string;
  disabled?: boolean;
  titleColor?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  error?: boolean;
  pageType?: string;
};

const PostSearchInput = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    pages = [],
    selectedWebsite,
    placeholder,
    handlePagesChange,
    maxPages = null,
    titleAndLengthLocation = "top",
    title,
    titleClassName,
    error = false,
    pageType = undefined,
  } = props;
  const [items, setItems] = React.useState<IPage[]>([]);

  const getProducts = (search = "") => {
    if (search) {
      agent.Pages.searchPublished(selectedWebsite._id, search, pageType)
        .then((results) => {
          setItems(results.pages);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const allItems = uniqBy([...pages, ...items], "slug");

  const onPageSelected = (page) => {
    if (maxPages === 1) {
      handlePagesChange(page);
      return;
    }
    let newPages = uniqBy([...page, ...pages], "slug");
    if (maxPages && newPages.length > maxPages) {
      newPages = newPages.slice(0, maxPages);
    }
    handlePagesChange(newPages);
  };

  const onProductRemoved = (page) => {
    handlePagesChange(maxPages === 1 ? null : filter(pages, (pPage) => pPage.slug !== page.slug));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.rowWrapper}>
        <Typography
          className={ClassNames(classes.title, titleAndLengthLocation === "top" && classes.titleOnTop, titleClassName)}
          variant={"subtitle2"}
        >
          {title || ""}
        </Typography>
        {maxPages !== 1 && (
          <Typography className={classes.length} variant={"body2"}>
            {I18n.t("entail.text_length", { length: pages.length, maxLength: maxPages || pages.length })}
          </Typography>
        )}
      </div>

      <MSelect
        options={allItems}
        value={maxPages === 1 ? pages?.[0] : null}
        customClass={classes.select}
        error={error}
        optionValue={"slug"}
        optionLabel={"title"}
        isMulti={maxPages !== 1}
        borderWidth={"0px"}
        getDisplayLabel={(opt) => opt.title}
        height={37}
        placeholder={placeholder || I18n.t("website.search_pages_ph")}
        searchable
        handleInputChange={(search) => {
          getProducts(search);
        }}
        handleChange={(tag) => {
          onPageSelected(tag);
        }}
      />
      {maxPages !== 1 && pages.length > 0 && (
        <div className={classes.productsWrapper}>
          {pages.map((page) => (
            <MTopic
              fontSize={12}
              key={page.slug}
              tag={page.title}
              onRemoveClicked={(e) => onProductRemoved(page)}
              tagCustomClass={classes.tagCustomClass}
              tagIconCustomClass={classes.tagIconCustomClass}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PostSearchInput;
