import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles, useTheme } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ClassNames from "classnames";
import DownChevronIcon from "../../../icons/DownChevronIcon";
import CfCheckBox from "../../CfCheckBox";
import CfButton from "../../CfButton";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 10,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
  },
  popper: {
    zIndex: 100,
  },
  searchField: {
    width: "100%",
    color: "#000",
    height: 33,
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette.divider,
    },
    "& .MuiInput-root": {
      height: "100%",
    },
    "& .MuiInputAdornment-positionStart": {
      margin: 0,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },
  searchIcon: {
    color: theme.palette.primary.dark,
  },
  searchWrap: {
    padding: "0 20px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 20px 15px 20px",
    gap: 15,
    maxHeight: 250,
    width: 290,
    overflowY: "auto",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    borderTop: `solid 1px ${theme.palette.divider}`,
  },
  clearButton: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: 0,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
  applyButton: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    padding: "7px 20px",
    borderRadius: 20,
  },
  titleWrapper: {
    cursor: "pointer",
    color: theme.palette.text.disabled,
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    margin: "0 20px",
  },
  selectionResult: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight as any,
    whiteSpace: "nowrap",
  },
  selectedResults: {
    color: "#000",
  },
  checkbox: {
    margin: 0,
    "&:hover": {
      color: "blue",
    },
  },
  checkboxLabel: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
  },
  clearIcon: {
    fontSize: 14,
    marginLeft: "-6px",
    color: "#000",
  },
  searchInput: {
    padding: 0,
    color: "#000",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
}));

type PropTypes = {
  placeholder?: string;
  multipleOptions?: boolean;
  title?: string;
  options: any[];
  selectedOptions: any[];
  optionLabel?: string;
  optionValue?: string;
  handleChange: (value: any) => void;
  clearable?: boolean;
  titleClassName?: string;
  dropdownClassName?: string;
  setExternalSearchText?: (value: string) => void;
  labelClassName?: string;
  selectionResultClass?: string;
  selectionResultSelectedClass?: string;
};

const MTableToolbarBaseSelect = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    title,
    options,
    selectedOptions = [],
    optionLabel = "name",
    optionValue = "_id",
    multipleOptions = false,
    placeholder = I18n.t("common.select_placeholder"),
    clearable = true,
    titleClassName,
    labelClassName,
    handleChange,
    setExternalSearchText,
    selectionResultClass,
    selectionResultSelectedClass,
    dropdownClassName,
  } = props;
  const theme = useTheme<Theme>();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [selected, setSelected] = React.useState(selectedOptions);
  const [selectionResult, setSelectionResult] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (selectedOptions.length === 0 && selected?.length > 0) {
      setSelected(selectedOptions);
      setSelectionResult(null);
    } else if (selectedOptions.length > 0) {
      setSelected(selectedOptions);
    }
  }, [selectedOptions]);

  let filteredOptions;
  if (searchText !== "" && !setExternalSearchText) {
    filteredOptions = options.filter((option) => option[optionLabel].toLowerCase().includes(searchText.toLowerCase()));
  } else {
    filteredOptions = options;
  }

  const onApply = () => {
    if (selected.length === 0) {
      setSelectionResult(null);
    } else if (selected.length === 1) {
      setSelectionResult(selected[0][optionLabel]);
    } else {
      setSelectionResult(`${selected.length} ${title || I18n.t("common.selected")}`);
    }
  };

  React.useEffect(() => {
    onApply();
  }, [selectedOptions]);

  return (
    <div>
      <div className={ClassNames(classes.titleWrapper, titleClassName)} onClick={(event) => handleClick(event)}>
        <Typography
          variant={"body1"}
          className={ClassNames(
            classes.selectionResult,
            selectionResultClass,
            selectionResult && (selectionResultSelectedClass || classes.selectedResults)
          )}
          color={"textPrimary"}
        >
          {selectionResult || placeholder}
        </Typography>
        <div className={classes.iconWrapper}>
          {selectionResult && clearable && (
            <ClearIcon
              className={classes.clearIcon}
              onClick={(e) => {
                e.stopPropagation();
                setSelectionResult(null);
                setSelected([]);
                handleChange([]);
              }}
            />
          )}
          <DownChevronIcon className={dropdownClassName} />
        </div>
      </div>
      <Popper open={open} anchorEl={anchorEl} transition placement={"bottom-start"} className={classes.popper}>
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
            setSelected(selectedOptions);
          }}
        >
          <div className={classes.paper}>
            <div className={classes.searchWrap}>
              <TextField
                autoFocus
                type="search"
                variant={"standard"}
                value={searchText}
                className={classes.searchField}
                placeholder={
                  title ? I18n.t("common.search_field", { field: title }) : I18n.t("common.search_placeholder")
                }
                onChange={(event) => {
                  if (setExternalSearchText) {
                    setExternalSearchText(event.target.value);
                  }
                  setSearchText(event.target.value);
                }}
                InputProps={{
                  classes: { input: classes.searchInput },
                  endAdornment: (
                    <InputAdornment position="start">
                      <Search className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.content}>
              {filteredOptions &&
                filteredOptions.length > 0 &&
                filteredOptions.map((option, index) => (
                  <CfCheckBox
                    muiltiple={multipleOptions}
                    key={option._id}
                    customClassName={classes.checkbox}
                    labelClassName={ClassNames(classes.checkboxLabel, labelClassName)}
                    iconColor={theme.palette.primary.main}
                    checked={Boolean(selected.find((opt) => opt[optionValue] === option[optionValue]))}
                    value={option[optionValue]}
                    label={option[optionLabel]}
                    handleChange={() => {
                      if (multipleOptions) {
                        if (!selected.find((opt) => opt[optionValue] === option[optionValue])) {
                          setSelected([option, ...selected]);
                        } else {
                          setSelected(selected.filter((opt) => opt[optionValue] !== option[optionValue]));
                        }
                      } else {
                        setSelected([option]);
                      }
                    }}
                  />
                ))}
            </div>
            <div className={classes.buttons}>
              <CfButton
                customClass={classes.clearButton}
                text={I18n.t("common.clear")}
                onClick={(e) => {
                  setSelected([]);
                }}
              />
              <CfButton
                height={30}
                customClass={classes.applyButton}
                text={I18n.t("common.apply")}
                onClick={() => {
                  onApply();
                  handleChange(selected);
                  setAnchorEl(null);
                }}
              />
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default MTableToolbarBaseSelect;
