import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";
import { connectRouter } from "connected-react-router";
import home from "./reducers/homeReducer";
import categorize from "./reducers/categorizeReducer";
import blog from "./reducers/blogReducer";
import manageWebsites from "./reducers/manageWebsitesReducer";
import category from "./reducers/categoryReducer";
import tag from "./reducers/tagReducer";
import forum from "./reducers/forumReducer";
import fbGroups from "./reducers/fbGroupsReducer";
import targetedPosts from "./reducers/targetedPostsReducer";
import industryExperts from "./reducers/industryExpertsReducer";
import ads from "./reducers/adsReducer";
import learn from "./reducers/learnReducer";
import published from "./reducers/publishedReducer";
import postsInProgress from "./reducers/postsInProgressReducer";
import learningCenterContent from "./reducers/learningCenterContentReducer";
import queryGroups from "./reducers/gscQueriesReducer";
import scraping from "./reducers/scrapingReducer";
import editBlogPost from "./reducers/editBlogPostReducer";
import editCustomPost from "./reducers/editCustomPostReducer";
import editArticle from "./reducers/editArticleReducer";
import editTargetedPost from "./reducers/editTargetedPostReducer";
import editTopic from "./reducers/editTopicReducer";
import contentSourcePicker from "./reducers/contentSourcePickerReducer";
import review from "./reducers/reviewReducer";
import reviewAnswer from "./reducers/reviewAnswersReducer";
import modals from "./reducers/modalsReducer";
import mcUpload from "./reducers/manageContentUploadReducer";
import clusters from "./reducers/clustersReducer";
import answers from "./reducers/answersReducer";
import writeAnswer from "./reducers/writeAnswersReducer";
import manageContent from "./reducers/manageContentReducer";
import audiences from "./reducers/audiencesReducer";
import pages from "./reducers/pagesReducer";
import landingPages from "./reducers/landingPagesReducer";
import writerBio from "./reducers/writerBioReducer";
import websitePages from "./reducers/websitePagesReducer";
import strategy from "./reducers/strategyReducer";
import queryGroupsContainers from "./reducers/queryGroupsMappingReducer";
import fetchKeywords from "./reducers/fetchKeywordsReducer";
import excludeKeywords from "./reducers/excludeKeywordsReducer";
import keywordFrequency from "./reducers/keywordFrequencyReducer";
import editorial from "./reducers/editorialReducer";
import outline from "./reducers/outlineReducer";
import opportunities from "./reducers/opportunitiesReducer";
import pageEditor from "./reducers/pageEditorReducer";
import workFlowPublished from "./reducers/workFlowPublishedReducer";
import workFlowAllStates from "./reducers/workFlowAllStatesReducer";
import workFlowPublishedCluster from "./reducers/workFlowPublishedClusterReducer";
import writePages from "./reducers/writePagesReducer";
import workFlowEdit from "./reducers/workFlowEditReducer";
import workFlowSecondEdit from "./reducers/workFlowSecondEditReducer";
import factCheckPages from "./reducers/factCheckPagesReducers";
import secondFactCheckPages from "./reducers/secondFactCheckPagesReducers";
import thirdFactCheckPages from "./reducers/thirdFactCheckPagesReducers";
import fourthFactCheckPages from "./reducers/fourthFactCheckPagesReducers";
import publishedPages from "./reducers/publishedPagesReducer";
import insights from "./reducers/insightsReducer";
import community from "./reducers/communityReducer";
import communityEditor from "./reducers/communityEditorReducer";
import widgetBuilder from "./reducers/widgetBuilderReducer";
import widgets from "./reducers/widgetsReducer";
import widgetDisplayRules from "./reducers/widgetDisplayRulesReducer";
import widgetRuleEditor from "./reducers/widgetRuleEditorReducer";
import keywordaggregation from "./reducers/keywordaggregationReducer";
import keywordTypes from "./reducers/keywordTypesReducer";
import keywordsClassification from "./reducers/keywordsClassificationReducer";
import keywordGroups from "./reducers/keywordGroupsReducer";
import headingCluster from "./reducers/headingClusterReducer";
import outlinePages from "./reducers/outlinePagesReducer";
import keywordStrategy from "./reducers/keywordStrategyReducer";
import keywordGroupPage from "./reducers/keywordGroupPageReducer";
import strategySettings from "./reducers/strategySettingsReducer";
import internalLinking from "./reducers/internalLinkingReducer";
import videoEditor from "./reducers/videoEditorReducer";
import videoWorkFlow from "./reducers/videoWorkFlowReducer";
import contentGaps from "./reducers/contentGapsReducer";
import funnels from "./reducers/funnelReducer";
import events from "./reducers/eventReducer";
import widgetPicker from "./reducers/widgetPickerReducer";
import goalCompletion from "./reducers/goalCompletionReducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    i18n: i18nReducer,
    home,
    category,
    tag,
    forum,
    fbGroups,
    categorize,
    blog,
    manageWebsites,
    targetedPosts,
    answers,
    writeAnswer,
    learn,
    mcUpload,
    // question,
    pages,
    review,
    reviewAnswer,
    contentSourcePicker,
    manageContent,
    queryGroups,
    queryGroupsContainers,
    editBlogPost,
    editArticle,
    editCustomPost,
    editTargetedPost,
    editTopic,
    scraping,
    ads,
    published,
    clusters,
    postsInProgress,
    industryExperts,
    learningCenterContent,
    modals,
    audiences,
    writerBio,
    websitePages,
    editorial,
    strategy,
    landingPages,
    outline,
    opportunities,
    fetchKeywords,
    excludeKeywords,
    keywordFrequency,
    pageEditor,
    workFlowAllStates,
    workFlowPublished,
    workFlowPublishedCluster,
    writePages,
    workFlowEdit,
    workFlowSecondEdit,
    factCheckPages,
    secondFactCheckPages,
    thirdFactCheckPages,
    fourthFactCheckPages,
    publishedPages,
    community,
    insights,
    communityEditor,
    widgetBuilder,
    widgets,
    widgetDisplayRules,
    widgetRuleEditor,
    keywordaggregation,
    keywordTypes,
    keywordsClassification,
    keywordGroups,
    headingCluster,
    outlinePages,
    strategySettings,
    keywordStrategy,
    keywordGroupPage,
    internalLinking,
    videoEditor,
    videoWorkFlow,
    contentGaps,
    funnels,
    events,
    widgetPicker,
    goalCompletion,
  });
