import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Carousel from "react-material-ui-carousel";
import ConditionalWrapper from "./ConditionalWrapper";
import { getImageCDNUrl } from "../helpers/urlHelper";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: "100%",
    objectFit: "contain",
  },
}));

type PropTypes = {
  imageHeight?: number | string;
  pictures: {
    src: string;
    alt?: string;
    title?: string;
    caption?: string;
    ctaUrl?: string;
  }[];
  editMode?: boolean;
  sponsored?: boolean;
};

const MPictureCarousel = (props: PropTypes) => {
  const classes = useStyles(props);
  const { pictures, sponsored = false, imageHeight = null, editMode = false } = props;

  return (
    <Carousel
      navButtonsAlwaysInvisible={pictures?.length === 1}
      animation={"slide"}
      indicators={pictures?.length > 1}
      swipe={pictures?.length > 1}
      autoPlay={false}
    >
      {pictures &&
        pictures.map((picture, i) => (
          <ConditionalWrapper
            key={picture.src}
            condition={!editMode && picture.ctaUrl}
            wrapper={(children) => (
              <a href={picture.ctaUrl} rel={`noopener${sponsored ? " sponsored" : ""}`} target={"_blank"}>
                {children}
              </a>
            )}
          >
            <picture>
              <img
                height={imageHeight}
                className={classes.image}
                src={getImageCDNUrl(picture.src)}
                alt={picture.alt}
                title={picture.title}
              />
            </picture>
          </ConditionalWrapper>
        ))}
    </Carousel>
  );
};

export default MPictureCarousel;
