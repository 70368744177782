import * as React from "react";
import { I18n } from "react-redux-i18n";
import NumberInputBaseComponent from "../BaseComponents/NumberInputBaseComponent";

type PropTypes = {
  value: string;
  handleChange: any;
  defaultValue?: string;
};

const FontSizeCSS = (props: PropTypes) => {
  const { value, handleChange, defaultValue } = props;
  return (
    <NumberInputBaseComponent
      defaultValue={parseInt(defaultValue, 10)}
      rowTitle={I18n.t("rich_text_editor.font_size")}
      postFix="px"
      width={180}
      value={parseInt(value, 10)}
      handleChange={(fontSize) => handleChange(`${fontSize.toString()}px`)}
    />
  );
};

export default FontSizeCSS;
