import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import addImage from "../../../../img/add_image_cropped.png";
import { IFile, IWebsite } from "../../../../reducers/constants/objectTypes";
import InsertImageDialog from "../../../../containers/Modals/InsertImageDialog/InsertImageDialog";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    whiteSpace: "pre",
    marginBottom: 20,
    overflow: "hidden",
  },
  title: {
    minWidth: 130,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 5,
  },
  collapsedContentWrapper: {
    width: "100%",
  },
  previewImage: {
    width: "100%",
    height: "auto",
    borderRadius: 5,
    cursor: "pointer",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  previewImage: IFile;
  imageSizeLimit?: number;

  onImageSelected: (file: IFile) => void;
};

const PPCHeaderImage = (props: PropTypes) => {
  const classes = useStyles(props);
  const { previewImage, selectedWebsite, imageSizeLimit, onImageSelected } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div className={classes.wrapper}>
      <Typography variant={"body1"} className={classes.title}>
        {I18n.t("rich_text_editor.ppc_top_10.header_image")}
      </Typography>
      <img
        height={previewImage?.height}
        width={previewImage?.width}
        className={classes.previewImage}
        src={previewImage ? previewImage.cdnUrl || previewImage.url : addImage}
        onClick={(e) => setOpen(true)}
      />
      {open && (
        <InsertImageDialog
          imageSizeLimit={imageSizeLimit}
          website={selectedWebsite}
          selectedImageFile={previewImage}
          handleInsertClick={(file: IFile) => {
            onImageSelected(file);
            setOpen(false);
          }}
          handleCloseClick={() => setOpen(false)}
        />
      )}
    </div>
  );
};

export default PPCHeaderImage;
