import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { connect, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Dialog, Divider, Theme, Typography } from "@material-ui/core";
import { debounce, isEqual, omit, uniq } from "lodash";
import { nanoid } from "nanoid";
import { I18n } from "react-redux-i18n";
import { load } from "cheerio";

import {
  IWebsite,
  IWidgetContainerPosition,
  ICampaign,
  IWidget,
  IDomElement,
} from "../../../../../../../reducers/constants/objectTypes";
import {
  WidgetRuleEditorRootState,
  WidgetRuleEditorSelectedView,
} from "../../../../../../../reducers/widgetRuleEditorReducer";
import { HOME, WIDGET_RULE_EDITOR } from "../../../../../../../reducers/constants/actionTypes";
import CfProgressBar from "../../../../../../../components/CfProgressBar";
import { loadHtml } from "../CampaignBuilder/apiHelper";
import ArrowLeftIcon from "../../../../../../../icons/ArrowLeftIcon";
import MDropdown from "../../../../../../../components/MDropdown";
import { replace } from "connected-react-router";
import { web } from "../../../../../../../helpers/urlHelper";
import Preview from "./Preview";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    top: 0,
    left: 0,
  },
  loaderWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    minHeight: 55,
    paddingRight: 17,
    paddingLeft: 17,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    position: "relative",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  toolbarDetails: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginLeft: 15,
    borderRadius: "5px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    userSelect: "none",
  },
  divider: {
    height: 26,
    margin: "0 15px",
  },
  arrowIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
    fill: theme.palette.text.primary,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  optionPlaceholder: {
    fontWeight: theme.typography.fontWeightRegular as any,
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  rule: state.widgetRuleEditor.rule,
  addContainerMode: state.widgetRuleEditor.addContainerMode,
  reselectPositionMode: state.widgetRuleEditor.reselectPositionMode,
  draftContainer: state.widgetRuleEditor.draftContainer,
  preview: state.widgetRuleEditor.preview,
  selectedPositionEdit: state.widgetRuleEditor.selectedPositionEdit,
  fromOnboarding: state.widgetRuleEditor.fromOnboarding,
  selectedWidgetContainerId: state.widgetRuleEditor.selectedWidgetContainerId,
});

const mapDispatchToProps = (dispatch) => ({
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PROPERTY_CHANGE, property });
  },
  handlePreviewPropertyChange: (property: Partial<WidgetRuleEditorRootState["preview"]>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PREVIEW_PROPERTY_CHANGE, property });
  },
  onWebsiteSelected: (selectedWebsite) => dispatch({ type: HOME.ON_WEBSITES_SELECTED, selectedWebsite }),
  redirectTo: (url: string) => dispatch(replace(url)),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  rule: ICampaign;
  addContainerMode: boolean;
  reselectPositionMode: boolean;
  draftContainer: WidgetRuleEditorRootState["draftContainer"];
  preview: WidgetRuleEditorRootState["preview"];
  selectedPositionEdit: WidgetRuleEditorRootState["selectedPositionEdit"];
  selectedWidgetContainerId: WidgetRuleEditorRootState["selectedWidgetContainerId"];
  onBoardingPagePreview?: boolean;
  fromOnboarding: boolean;
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => void;
  redirectTo: (url: string) => void;
};

const generateContainer = ({
  widget,
  position,
  xpath,
}: {
  widget: IWidget;
  position: IWidgetContainerPosition["position"];
  xpath: any;
}) =>
  ({
    containerId: nanoid(10),
    enabled: true,
    widgetFilter: { value: "specific", widget },
    position: {
      sameDesktopMobilePosition: true,
      desktop_mobile: {
        location: "feed",
        selector: {
          type: "XPath",
          value: xpath.fullXPath,
          shortestCssSelector: xpath.shortestCssSelector,
        },
        position,
        margin: { top: 0, bottom: 30 },
      },
      mobile: {
        enabled: true,
        location: "feed",
        selector: {
          type: "XPath",
          value: "",
          shortestCssSelector: "",
        },
        position: "below",
        margin: { top: 0, bottom: 30 },
      },
      desktop: {
        enabled: true,
        location: "feed",
        selector: {
          type: "XPath",
          value: "",
          shortestCssSelector: "",
        },
        position: "below",
        margin: { top: 0, bottom: 0 },
      },
    },
  } as ICampaign["widgetContainers"][number]);

const I18nRoute = "widgets_display_rules_editor.builder.container_settings_view.position_view.preview";

const CampaignPreview = (props: PropTypes) => {
  const {
    selectedWebsite,
    rule,
    addContainerMode,
    reselectPositionMode,
    draftContainer,
    preview,
    selectedPositionEdit,
    onBoardingPagePreview = false,
    fromOnboarding,
    selectedWidgetContainerId,
    redirectTo,
  } = props;

  const { handlePropertyChange } = props;
  const { previewBlobUrl, XPathCollections, loading, mobilePreview, plainHtml } = preview;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const previewCampaignController = React.useRef(null);

  const positionOptions = [
    { value: "below", label: I18n.t(`${I18nRoute}.insert_below`) },
    { value: "above", label: I18n.t(`${I18nRoute}.insert_above`) },
  ];

  const [selectedPosition, setSelectedPosition] = React.useState(positionOptions[0]);

  const isFirstRender = React.useRef(true);
  const prevState = React.useRef({ rule, mobilePreview });

  React.useEffect(() => {
    const hasChanges = () => {
      if (prevState.current.mobilePreview !== mobilePreview) {
        return true;
      }

      if (!isEqual(prevState.current.rule.rules, rule.rules)) {
        return true;
      }

      if (isEqual(prevState.current.rule, rule)) {
        return false;
      }

      // check if only the order of the containers has changed
      if (prevState.current.rule.widgetContainers.length === rule.widgetContainers.length) {
        let isTheSameOrder = true;
        rule.widgetContainers.forEach((container) => {
          const prevContainer = prevState.current.rule.widgetContainers.find(
            (c) => c.containerId === container.containerId
          );
          if (!prevContainer || !isEqual(prevContainer, container)) {
            isTheSameOrder = false;
          }
        });
        if (isTheSameOrder) {
          return false;
        }
      }

      return true;
      // TODO: check if the code below works as expected

      const prevContainers = prevState.current.rule.widgetContainers.map((container) => ({
        ...omit(container, ["name", "_id"]),
        position: {
          ...container.position,
          desktop_mobile: omit(container.position.desktop_mobile, ["location", "_id"]),
          desktop: omit(container.position.desktop, ["location", "_id"]),
          mobile: omit(container.position.mobile, ["location", "_id"]),
        },
      }));

      const newContainers = rule.widgetContainers.map((container) => ({
        ...omit(container, ["name", "_id"]),
        position: {
          ...container.position,
          desktop_mobile: omit(container.position.desktop_mobile, ["location", "_id"]),
          desktop: omit(container.position.desktop, ["location", "_id"]),
          mobile: omit(container.position.mobile, ["location", "_id"]),
        },
      }));

      const newAddedContainers = newContainers.filter(
        (container) => !prevContainers.find((prevContainer) => container.containerId === prevContainer.containerId)
      );

      const removedContainers = prevContainers.filter(
        (prevContainer) => !newContainers.find((container) => container.containerId === prevContainer.containerId)
      );

      console.log("rule check newAddedContainers", newAddedContainers);

      for (let i = 0; i < newAddedContainers.length; i++) {
        const container = newAddedContainers[i];
        const position = container.position;
        if (
          container.enabled &&
          (position.desktop_mobile.selector.value || position.desktop.selector.value || position.mobile.selector.value)
        ) {
          return true;
        }
      }

      for (let i = 0; i < removedContainers.length; i++) {
        const container = removedContainers[i];
        const position = container.position;
        if (
          container.enabled &&
          (position.desktop_mobile.selector.value || position.desktop.selector.value || position.mobile.selector.value)
        ) {
          return true;
        }
      }

      for (let i = 0; i < prevContainers.length; i++) {
        const containerWithChanges = newContainers.find((c) => c.containerId === prevContainers[i].containerId);
        console.log("rule check last", containerWithChanges, prevContainers[i]);
        if (containerWithChanges && !isEqual(prevContainers[i], containerWithChanges)) {
          return true;
        }
      }

      return false;
    };

    console.log("rule check", isFirstRender.current, hasChanges(), selectedPositionEdit);

    if (isFirstRender.current || (hasChanges() && !selectedPositionEdit)) {
      isFirstRender.current = false;
      prevState.current = { rule, mobilePreview };
      loadHtml(
        dispatch,
        enqueueSnackbar,
        { rule, selectedWebsite, preview, selectedWidgetContainerId },
        (controller) => {
          previewCampaignController.current = controller;
        }
      );
    }
  }, [selectedWebsite, rule, mobilePreview, selectedPositionEdit]);

  React.useEffect(() => {
    return () => {
      if (previewCampaignController.current) {
        previewCampaignController.current.abort();
      }
    };
  }, []);

  const addContainer = (widget: IWidget, xpath: any, position: IWidgetContainerPosition["position"]) => {
    const newContainer = generateContainer({ widget, position, xpath });
    handlePropertyChange({
      rule: {
        ...rule,
        widgetContainers: [...rule.widgetContainers, newContainer],
      },
      draftContainer: newContainer,
      selectedView: fromOnboarding
        ? WidgetRuleEditorSelectedView.MAIN
        : WidgetRuleEditorSelectedView.WIDGET_CONTAINER_SETTINGS,
      selectedWidgetContainerId: newContainer.containerId,
    });
  };

  // Helper function to check if the selector is unique
  const isUniqueSelector = ($, selector) => {
    const elements = $(selector);
    const results = elements.length === 1; // Check if only one element matches the selector
    console.log("isUniqueSelector", results);
    return results;
  };

  const getUniqueSelector = (options: IDomElement) => {
    console.log("getUniqueSelector");
    if (!plainHtml) {
      console.log("Error, HTML not found");
      return null;
    }
    console.log("selected Xpath:", options);
    const $ = load(plainHtml);

    let selector = "";

    // Add 'tagName' to the selector if provided (e.g., 'div', 'span')
    if (options.tagName) {
      selector += `${options.tagName}`;
    }

    // Add 'id' to the selector if provided
    if (options.id) {
      selector += `#${options.id}`;
      if (isUniqueSelector($, selector)) return $(selector);
    }

    // Add 'class' to the selector if provided (handles multiple classes)
    if (options.className) {
      selector += `.${options.className.split(" ").join(".")}`;
      if (isUniqueSelector($, selector)) return $(selector);
    }

    // // Add 'name' to the selector if provided
    // if (options.name) {
    //   selector += `[name='${options.name}']`;
    //   if (isUniqueSelector($, selector)) return $(selector);
    // }

    // Add 'data-*' attribute to the selector if provided
    if (Object.keys(options.attributes).length > 0) {
      for (let key in Object.keys(options.attributes)) {
        selector += `[${key}='${options.attributes[key]}']`;
      }
      if (isUniqueSelector($, selector)) return $(selector);
    }

    // // Add 'tag' to the selector if provided
    // if (options.tag) {
    //   selector = options.tag + selector; // Prepend tag name
    //   if (isUniqueSelector($, selector)) return $(selector);
    // }
    //
    // // Add 'type' attribute to the selector if provided (e.g., for input elements)
    // if (options.type) {
    //   selector += `[type='${options.type}']`;
    //   if (isUniqueSelector($, selector)) return $(selector);
    // }
    //
    // // Add any other attribute provided (as key-value pair in options.otherAttr)
    // if (options.otherAttr) {
    //   for (const [attr, value] of Object.entries(options.otherAttr)) {
    //     selector += `[${attr}='${value}']`;
    //     if (isUniqueSelector($, selector)) return $(selector);
    //   }
    // }

    // If no unique selector is found after all attributes are considered
    return $(selector);
  };

  const handleWidgetInsert = (widget: IWidget, xpath: IDomElement) => {
    console.log("handleWidgetInsert", getUniqueSelector(xpath));
    addContainer(widget, xpath, selectedPosition.value as IWidgetContainerPosition["position"]);
    handlePropertyChange({ addContainerMode: false });
    if (fromOnboarding) {
      redirectTo(web.croCampaignEditor(rule._id));
    }
  };

  const handleWidgetReselect = (xpath: IDomElement) => {
    console.log("handleWidgetReselect", getUniqueSelector(xpath));
    handlePropertyChange({
      draftContainer: {
        ...draftContainer,
        position: {
          ...draftContainer.position,
          [selectedPositionEdit]: {
            ...draftContainer.position[selectedPositionEdit],
            position: selectedPosition.value as IWidgetContainerPosition["position"],
            selector: {
              type: "XPath",
              value: xpath.fullXPath,
              shortestCssSelector: xpath.shortestCssSelector,
            },
          },
        },
      },
    });
    handlePropertyChange({ reselectPositionMode: false });
  };

  if (!previewBlobUrl && !loading) return null;

  console.log("XPathCollections", XPathCollections.sizePos?.length);

  if (loading) {
    return (
      <div className={classes.container}>
        <div className={classes.loaderWrapper}>
          <CfProgressBar />
        </div>
      </div>
    );
  }

  if ((addContainerMode || reselectPositionMode) && !onBoardingPagePreview) {
    return (
      <Dialog open={true} transitionDuration={0} fullScreen>
        <>
          <div className={classes.toolbar}>
            <div className={classes.wrapper}>
              <div>
                <ArrowLeftIcon
                  className={classes.arrowIcon}
                  onClick={() =>
                    handlePropertyChange({
                      addContainerMode: false,
                      reselectPositionMode: false,
                    })
                  }
                />
              </div>
              <div className={classes.toolbarDetails}>
                <Typography className={classes.label}>{I18n.t(`${I18nRoute}.add_widget`)}</Typography>
                <Divider orientation={"vertical"} className={classes.divider} />
                <MDropdown
                  optionLabel="label"
                  optionValue="value"
                  value={selectedPosition}
                  options={positionOptions}
                  handleOnChange={(option) => setSelectedPosition(option as any)}
                  zIndex={1300}
                  classes={{
                    placeholder: classes.optionPlaceholder,
                  }}
                />
              </div>
            </div>
          </div>
          <Preview
            mobilePreview={mobilePreview}
            handleWidgetInsert={handleWidgetInsert}
            handleWidgetReselect={handleWidgetReselect}
          />
        </>
      </Dialog>
    );
  }

  return (
    <Preview
      mobilePreview={mobilePreview}
      handleWidgetInsert={handleWidgetInsert}
      handleWidgetReselect={handleWidgetReselect}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignPreview);
