import React, { useMemo } from "react";
import { I18n } from "react-redux-i18n";

import {
  IWidget,
  IWidgetFieldProperties,
  IWebsite,
  IResetToProductDefaults,
  eWidgetFieldType,
  eWidgetType,
  eWidgetSubtype,
  IProduct,
} from "../../../../../../../reducers/constants/objectTypes";
import BulletedListWidgetItem from "../items/BulletedListWidgetItem";
import CallToActionAndReviewWidgetItem from "../items/CallToActionAndReviewWidgetItem";
import ResetToDefaults from "../items/components/ResetToDefaults";
import CouponWidgetItem from "../items/CouponWidgetItem";
import ProductButtonWidgetItem from "../items/ProductButtonWidgetItem";
import DialogButtonWidgetItem from "../items/DialogButtonWidgetItem";
import DialogFormWidgetItem from "../items/DialogFormWidgetItem";
import DialogLinkWidgetItem from "../items/DialogLinkWidgetItem";
import ExpandableTextSectionWidgetItem from "../items/ExpandableTextSectionWidgetItem";
import ImageWidgetItem from "../items/ImageWidgetItem";
import LogoWidgetItem from "../items/LogoWidgetItem";
import ImageMediasWidgetItem from "../items/ImagesWidgetItem";
import ProductPriceWidgetItem from "../items/ProductPriceWidgetItem";
import ProductNameWidgetItem from "../items/ProductNameWidgetItem";
import ProductNumberWidgetItem from "../items/ProductNumberWidgetItem";
import ProductRatingWidgetItem from "../items/ProductRatingWidgetItem";
import ProductReviewsNumberWidgetItem from "../items/ProductReviewsNumberWidgetItem";
import ProductScoreWidgetItem from "../items/ProductScoreWidgetItem";
import ProsAndConsWidgetItem from "../items/ProsAndConsWidgetItem";
import ShortDescriptionWidgetItem from "../items/ShortDescriptionWidgetItem";
import TextWidgetItem from "../items/TextWidgetItem";
import TitleWidgetItem from "../items/TitleWidgetItem";
import KeyDecisionFactorsWidgetItem from "../items/KeyDecisionFactorsWidgetItem";
import TagWidgetItem from "../items/TagWidgetItem";
import InlineHTMLWidgetItem from "../items/InlineHTMLWidgetItem";
import IframeWidgetItem from "../items/IframeWidgetItem";
import EditProductHref from "../items/components/EditProductHref";
import InlineButtonWidgetItem from "../items/InlineButtonWidgetItem";
import InlineLinkWidgetItem from "../items/InlineLinkWidgetItem";
import RelatedArticlesWidgetItem from "../items/RelatedArticlesWidgetItem/RelatedArticlesWidgetItem";
import IntroTextWidgetItem from "../items/IntroTextWidgetItem";
import TextCTAWidgetItem from "../items/TextCTAWidgetItem";
import MediaWidgetItem from "../items/MediaWidgetItem";
import TopicsWidgetItem from "../items/TopicsWidgetItem";
import HubspotFormWidgetItem from "../items/HubspotFormWidgetItem";
import CallToActionWidgetItem from "../items/CallToActionWidgetItem";

type SelectedWidgetFieldProps = {
  widget: IWidget;
  fieldType: string;
  form: IWidget;
  properties: IWidgetFieldProperties;
  selectedWebsite: IWebsite;
  resetToProductDefaults: IResetToProductDefaults;
  productHref?: string;
  product: IProduct;
  onChange: (properties: IWidgetFieldProperties) => void;
  onFormChange: (form: IWidget) => void;
  createFormWidgetField: () => void;
};

const SelectedWidgetField = (props: SelectedWidgetFieldProps) => {
  const { hasChanges } = props.resetToProductDefaults;

  const widgetFields = useMemo(() => {
    switch (props.widget.type) {
      case eWidgetType.CUSTOM_WIDGET: {
        switch (props.widget.subtype) {
          case eWidgetSubtype.PRODUCT_REVIEW:
            return {
              [eWidgetFieldType.TAG]: <TagWidgetItem {...props} />,
              [eWidgetFieldType.LOGO]: <LogoWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NUMBER]: <ProductNumberWidgetItem {...props} />,
              [eWidgetFieldType.SCORE]: <ProductScoreWidgetItem {...props} />,
              [eWidgetFieldType.AVATAR]: <LogoWidgetItem {...props} />,
              [eWidgetFieldType.MEDIAS]: <ImageMediasWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NAME]: <ProductNameWidgetItem {...props} />,
              [eWidgetFieldType.CALL_TO_ACTION]: <CallToActionAndReviewWidgetItem {...props} />,
              [eWidgetFieldType.KEY_DECISION_FACTORS]: <KeyDecisionFactorsWidgetItem {...props} />,
              [eWidgetFieldType.PROS_CONS]: <ProsAndConsWidgetItem {...props} />,
              [eWidgetFieldType.EXPANDABLE_TEXT_SECTION]: <ExpandableTextSectionWidgetItem {...props} />,
              [eWidgetFieldType.INTRO_TEXT]: <IntroTextWidgetItem {...props} />,
            };
          case eWidgetSubtype.CHARTICLE_TOP_PRODUCTS:
            return {
              [eWidgetFieldType.TAG]: <TagWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NUMBER]: <ProductNumberWidgetItem {...props} />,
              [eWidgetFieldType.AVATAR]: <LogoWidgetItem {...props} />,
              [eWidgetFieldType.LOGO]: <LogoWidgetItem {...props} />,
              [eWidgetFieldType.TITLE]: <TitleWidgetItem {...props} />,
              [eWidgetFieldType.SCORE]: <ProductScoreWidgetItem {...props} />,
              [eWidgetFieldType.RATING]: <ProductRatingWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS]: <ProductReviewsNumberWidgetItem {...props} />,
              [eWidgetFieldType.CALL_TO_ACTION]: <CallToActionAndReviewWidgetItem {...props} withoutButton={true} />,
              [eWidgetFieldType.SHORT_DESCRIPTION]: <ShortDescriptionWidgetItem {...props} />,
              [eWidgetFieldType.BUTTON]: <ProductButtonWidgetItem {...props} />,
              [eWidgetFieldType.BULLETED_LIST]: <BulletedListWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NAME]: <ProductNameWidgetItem {...props} />,
              [eWidgetFieldType.INTRO_TEXT]: <IntroTextWidgetItem {...props} />,
            };
          case eWidgetSubtype.PRODUCT_CTA:
            return {
              [eWidgetFieldType.TITLE]: <TitleWidgetItem {...props} />,
              [eWidgetFieldType.MEDIAS]: <ImageMediasWidgetItem {...props} />,
              [eWidgetFieldType.SCORE]: <ProductScoreWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NAME]: <ProductNameWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NUMBER]: <ProductNumberWidgetItem {...props} />,
              [eWidgetFieldType.BUTTON]: <ProductButtonWidgetItem {...props} />,
              [eWidgetFieldType.PRICE]: <ProductPriceWidgetItem {...props} />,
              [eWidgetFieldType.RATING]: <ProductRatingWidgetItem {...props} />,
            };
          case eWidgetSubtype.PRODUCT_CTA_BANNER:
            return {
              [eWidgetFieldType.ICON]: <LogoWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NAME]: <ProductNameWidgetItem {...props} />,
              [eWidgetFieldType.RATING]: <ProductRatingWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS]: <ProductReviewsNumberWidgetItem {...props} />,
              [eWidgetFieldType.SHORT_DESCRIPTION]: <ShortDescriptionWidgetItem {...props} />,
              [eWidgetFieldType.BULLETED_LIST]: <BulletedListWidgetItem {...props} />,
              [eWidgetFieldType.BUTTON]: <ProductButtonWidgetItem {...props} />,
            };
          case eWidgetSubtype.PRODUCT_RATING:
            return {
              [eWidgetFieldType.TAG]: <TagWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NUMBER]: <ProductNumberWidgetItem {...props} />,
              [eWidgetFieldType.LOGO]: <LogoWidgetItem {...props} />,
              [eWidgetFieldType.SCORE]: <ProductScoreWidgetItem {...props} />,
              [eWidgetFieldType.MEDIA]: <MediaWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NAME]: <ProductNameWidgetItem {...props} />,
              [eWidgetFieldType.SHORT_DESCRIPTION]: <ShortDescriptionWidgetItem {...props} />,
              [eWidgetFieldType.RATING]: <ProductRatingWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS]: <ProductReviewsNumberWidgetItem {...props} />,
              [eWidgetFieldType.TOPICS]: <TopicsWidgetItem {...props} />,
              [eWidgetFieldType.SECONDARY_BUTTON]: <ProductButtonWidgetItem {...props} />,
              [eWidgetFieldType.PRIMARY_BUTTON]: <ProductButtonWidgetItem {...props} />,
            };
          case eWidgetSubtype.PRODUCTS_CAROUSEL:
            return {
              [eWidgetFieldType.TAG]: <TagWidgetItem {...props} />,
              [eWidgetFieldType.SCORE]: <ProductScoreWidgetItem {...props} />,
              [eWidgetFieldType.MEDIA]: <MediaWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NAME]: <ProductNameWidgetItem {...props} />,
              [eWidgetFieldType.SHORT_DESCRIPTION]: <ShortDescriptionWidgetItem {...props} />,
              [eWidgetFieldType.RATING]: <ProductRatingWidgetItem {...props} />,
              [eWidgetFieldType.PRODUCT_NUMBER_OF_REVIEWS]: <ProductReviewsNumberWidgetItem {...props} />,
              [eWidgetFieldType.TOPICS]: <TopicsWidgetItem {...props} />,
              [eWidgetFieldType.CALL_TO_ACTION]: <CallToActionWidgetItem {...props} />,
            };
          default:
            return null;
        }
      }
      case eWidgetType.DIALOG:
        return {
          [eWidgetFieldType.TITLE]: <TitleWidgetItem {...props} />,
          [eWidgetFieldType.TEXT]: <TextWidgetItem {...props} />,
          [eWidgetFieldType.COUPON]: <CouponWidgetItem {...props} />,
          [eWidgetFieldType.BUTTON]: <DialogButtonWidgetItem {...props} />,
          [eWidgetFieldType.LINK]: <DialogLinkWidgetItem {...props} />,
          [eWidgetFieldType.IMAGE]: <ImageWidgetItem {...props} />,
          [eWidgetFieldType.FORM]: <DialogFormWidgetItem {...props} />,
          [eWidgetFieldType.INLINE_HTML]: <InlineHTMLWidgetItem {...props} />,
          [eWidgetFieldType.IFRAME]: <IframeWidgetItem {...props} />,
          [eWidgetFieldType.HUBSPOT_FORM]: <HubspotFormWidgetItem {...props} />,
        };
      case eWidgetType.INLINE:
        return {
          [eWidgetFieldType.TITLE]: <TitleWidgetItem {...props} />,
          [eWidgetFieldType.TEXT]: <TextWidgetItem {...props} />,
          [eWidgetFieldType.COUPON]: <CouponWidgetItem {...props} />,
          [eWidgetFieldType.BUTTON]: <InlineButtonWidgetItem {...props} />,
          [eWidgetFieldType.LINK]: <InlineLinkWidgetItem {...props} />,
          [eWidgetFieldType.IMAGE]: <ImageWidgetItem {...props} />,
          [eWidgetFieldType.FORM]: <DialogFormWidgetItem {...props} />,
          [eWidgetFieldType.INLINE_HTML]: <InlineHTMLWidgetItem {...props} />,
          [eWidgetFieldType.IFRAME]: <IframeWidgetItem {...props} />,
          [eWidgetFieldType.RELATED_ARTICLES]: <RelatedArticlesWidgetItem {...props} />,
          [eWidgetFieldType.TEXT_CTA]: <TextCTAWidgetItem {...props} />,
          [eWidgetFieldType.HUBSPOT_FORM]: <HubspotFormWidgetItem {...props} />,
        };
      default:
        return null;
    }
  }, [props]);

  const selectedWidgetField = useMemo(() => widgetFields[props.fieldType], [widgetFields, props.fieldType]);
  return selectedWidgetField ? (
    <>
      {selectedWidgetField}
      {props.productHref && <EditProductHref href={props.productHref} />}
      {hasChanges && (
        <ResetToDefaults
          onClick={props.resetToProductDefaults.onClick}
          label={I18n.t("widgets_builder.widget_editor.fields.reset_product_defaults")}
        />
      )}
    </>
  ) : null;
};

export default SelectedWidgetField;
