import * as React from "react";
import { useState } from "react";
import { I18n } from "react-redux-i18n";
import filter from "lodash/filter";
import { uniqBy } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import agent from "../../../agent";
import { ITag, IWebsite } from "../../../reducers/constants/objectTypes";
import MPanelTopics from "../../../components/MPanelTopics";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    minWidth: 130,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 5,
  },
  tagsCustomClass: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  selectCustomClass: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 3,
  },
  tagsWrapperCustomClass: {
    marginBottom: 0,
  },
  tagCustomClass: {
    minHeight: 24,
    backgroundColor: theme.palette.divider,
  },
  tagIconCustomClass: {
    color: theme.palette.text.primary,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  postTags: ITag[];

  handleTagChange: Function;
};

const PageEditorTags = (props: PropTypes) => {
  const classes = useStyles(props);
  const { postTags, selectedWebsite, handleTagChange } = props;
  const [tags, setTags] = useState<ITag[]>([]);

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }

    agent.Tags.all(selectedWebsite._id)
      .then((res) => {
        setTags(res.tags);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [selectedWebsite]);

  const onTagSelected = (tag) => {
    handleTagChange(uniqBy([...postTags, tag], "_id"));
  };

  const onTagRemoved = (tag) => {
    handleTagChange(filter(postTags, (pTag) => pTag.name !== tag.name));
  };

  const onTagCreated = (tag) => {
    setTags(uniqBy([...tags, tag], "_id").sort((a, b) => a.name.localeCompare(b.name)));
    handleTagChange(uniqBy([...postTags, tag], "_id"));
  };

  return (
    <div className={classes.wrapper}>
      <MPanelTopics
        selectedWebsite={selectedWebsite}
        postTags={postTags}
        tags={tags}
        height={31}
        onTagSelected={onTagSelected}
        onTagRemoved={onTagRemoved}
        onTagCreated={onTagCreated}
        customClass={classes.tagsCustomClass}
        selectCustomClass={classes.selectCustomClass}
        tagsWrapperCustomClass={classes.tagsWrapperCustomClass}
        tagCustomClass={classes.tagCustomClass}
        tagIconCustomClass={classes.tagIconCustomClass}
      />
    </div>
  );
};

export default PageEditorTags;
