import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ClassNames from "classnames";
import { useState } from "react";
import { filter, find } from "lodash";
import { Theme } from "@material-ui/core/styles";
import { ArrowForwardIos } from "@material-ui/icons";
import { useParams } from "react-router";
import { push } from "connected-react-router";
import CfSearchInput from "../../../../../components/CfSearchInput";
import agent from "../../../../../agent";
import { WRITER_BIO } from "../../../../../reducers/constants/actionTypes";
import { IWebsite, IAdminUser, IWriterBio } from "../../../../../reducers/constants/objectTypes";
import { web } from "../../../../../helpers/urlHelper";
import MTextButton from "../../../../../components/MTextButton";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) => ({
  groupWrapper: {
    height: "100%",
    backgroundColor: theme.palette.common.white,
    maxWidth: 400,
    width: "100%",
    borderRight: `${theme.palette.divider} solid 1px`,
    borderBottom: `${theme.palette.divider} solid 1px`,
    zIndex: 1,
  },
  listContainer: {
    display: "inline",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  groupList: {
    position: "relative",
    width: "100%",
    overflow: "auto",
    height: "calc(100% - 54px)",
    padding: 0,
  },
  itemPrimaryText: {
    color: theme.palette.text.primary,
  },
  itemSecondaryText: {
    color: theme.palette.text.disabled,
    fontSize: theme.typography.pxToRem(12),
    marginTop: 4,
  },
  itemSelected: {
    color: theme.palette.primary.main,
  },
  activeBio: {
    backgroundColor: "rgba(52, 149, 235,0.2)",
  },
  listItemSelected: {
    backgroundColor: theme.palette.secondary.main,
  },

  listItem: {
    cursor: "pointer",
    borderBottom: `${theme.palette.divider} solid 1px`,
    "&:hover": {
      transition: "500ms",
      backgroundColor: theme.palette.secondary.main,
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 20px 12px 4px",
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    padding: 10,
  },
  search: {
    // width: '-webkit-fill-available',
    height: "unset",
  },
  searchInput: {
    height: 40,
    borderRadius: 30,
    padding: "14px 15px 14px 8px",
    border: `${theme.palette.divider} solid 1px`,
    backgroundColor: "white",
  },
  searchIcon: {
    opacity: 0.2,
  },
  textButton: {
    marginLeft: 10,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  addButton: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  expandIcon: {
    fontSize: 15,
  },
  dot: {
    width: 10,
    height: 10,
    backgroundColor: "green",
    borderRadius: "50%",
    // marginRight: 10,
    // marginLeft: 10,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  writersLoaded: boolean;
  writers: IAdminUser[];

  handleWritersLoaded: (payload) => null;
  handleWriterClick: (writerId) => null;
  handleWriterCreated: (payload) => null;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  writersLoaded: state.writerBio.writersLoaded,
  writers: state.writerBio.writers,
});

const mapDispatchToProps = (dispatch) => ({
  handleWritersLoaded: (payload) => dispatch({ type: WRITER_BIO.ON_WRITERS_LOADED, payload }),
  handleWriterCreated: (payload) => dispatch({ type: WRITER_BIO.ON_WRITER_CREATED, payload }),
  handleWriterClick: (writerId) => dispatch(push(web.settingsBios(writerId))),
});

const WritersBioList = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    writersLoaded,
    selectedWebsite,
    handleWriterClick,
    handleWriterCreated,
    handleWritersLoaded,
    writers = [],
  } = props;
  const { writerId } = useParams();
  const [searchText, setSearchText] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    handleWritersLoaded(agent.Bios.all(selectedWebsite._id, 100));
  }, [selectedWebsite, handleWritersLoaded]);

  React.useEffect(() => {
    if (!writerId && writersLoaded) {
      handleWriterClick(writers.length > 0 ? writers[0]._id : null);
    }
  }, [writersLoaded, writerId, handleWriterClick]);

  const filteredWriters =
    writers.length === 0 || searchText === ""
      ? writers
      : filter(writers, (t) => t?.name.toLowerCase().includes(searchText.toLowerCase()));

  const createNewWriter = (name: string) => {
    agent.Bios.newWriter(selectedWebsite._id, name)
      .then((res) => {
        if (res.error) {
          enqueueSnackbar(I18n.t("snackbar.update_error", { msg: res.message }));
          return;
        }
        handleWriterCreated(res);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.response?.body?.message || e.message }));
      });
  };

  return (
    <div className={classes.groupWrapper}>
      <div className={classes.searchWrapper}>
        <CfSearchInput
          customClass={classes.search}
          customInputClass={classes.searchInput}
          customSearchIconClass={classes.searchIcon}
          borderColor={"transparent"}
          // color={'white'}
          searchText={searchText}
          placeholder={I18n.t("settings.bios.search_ph")}
          handleSearchTextChange={(text) => setSearchText(text)}
        />
        <MTextButton
          text={I18n.t("settings.bios.add")}
          disabled={!searchText || filteredWriters.length > 0}
          onClick={() => createNewWriter(searchText)}
        />
      </div>
      <div className={classes.listContainer}>
        <List component="nav" className={classes.groupList}>
          {writersLoaded &&
            filteredWriters.length > 0 &&
            filteredWriters.map((writer) => {
              const bio: IWriterBio = find(writer.bios, (b) => b.website === selectedWebsite._id) || undefined;
              return (
                <div
                  className={ClassNames(
                    classes.listItem,
                    bio?.active && classes.activeBio,
                    writer._id === writerId && classes.listItemSelected
                  )}
                >
                  <ListItem key={writer._id} onClick={(event) => handleWriterClick(writer._id)}>
                    <ListItemText
                      primary={`${writer.name}`}
                      primaryTypographyProps={{
                        variant: "subtitle2",
                        className: ClassNames(classes.itemPrimaryText, writer._id === writerId && classes.itemSelected),
                      }}
                      // secondary={`${bio?.alternativeId}`}
                      // secondaryTypographyProps={{
                      // 	variant: 'body2',
                      // 	className: ClassNames(
                      // 		classes.itemSecondaryText,
                      // 		writer._id === selectedWriterId && classes.itemSelected,
                      // 	),
                      // }}
                    />
                  </ListItem>
                  <div className={classes.rowWrapper}>
                    {/* {bio?.active && ( */}
                    {/* )} */}
                    <ArrowForwardIos className={classes.expandIcon} />
                    {/* <ListItemSecondaryAction */}
                    {/*	classes={{ */}
                    {/*		root: classes.dot, */}
                    {/*	}} */}
                    {/* /> */}
                  </div>
                </div>
              );
            })}
          {!writersLoaded && (
            <ListItem button>
              <ListItemText primary="Loading..." />
            </ListItem>
          )}

          {writersLoaded && filteredWriters.length === 0 && (
            <ListItem button>
              <ListItemText primary="Nothing to show..." />
            </ListItem>
          )}
        </List>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WritersBioList);
