import React, { useMemo, useState } from "react";
import { I18n } from "react-redux-i18n";
import { ResponsiveContainer } from "recharts";
import { makeStyles } from "@material-ui/styles";
import numeral from "numeral";
import { Theme, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import ReportCard from "./ReportCard";
import useReport from "./useReport";
import { ANALYTICS_DISPLAY_MODES, REPORT_CACHE_KEY_NAME } from "../../../../helpers/constants";
import AnalyticsTable, { AnalyticsTableCellLink } from "./charts/AnalyticsTable";
import MSelect from "../../../../components/MSelect";
import EngagementsTotalChart from "./charts/EngagementsTotalChart";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: 150,
    margin: 0,
  },
  chartContainer: {
    overflowY: "auto",
    zIndex: 1,
  },
  input: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.typography.pxToRem(14),
  },
  pageRowStyle: {
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    cursor: "pointer",
  },
  link: {
    color: theme.palette.primary.main,
  },

  rightSideHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },

  secondaryHeaderWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  startDate: Date;
};

type DisplayModes = "table" | "graph";

type ReportSecondaryHeaderProps = {
  reportType: any;
  displayMode: DisplayModes;
  setDisplayMode: (displayMode: DisplayModes) => void;
  setReportType: (reportType: string) => void;
  reportOptions: any[];
  eventType: any;
  setEventType: (eventType: string) => void;
  eventOptions: any[];
};

const ReportSecondaryHeader = (props: ReportSecondaryHeaderProps) => {
  const classes = useStyles();

  const theme = useTheme();

  const {
    reportType,
    setReportType,
    reportOptions,
    eventType,
    eventOptions,
    setEventType,
    displayMode,
    setDisplayMode,
  } = props;

  return (
    <div className={classes.secondaryHeaderWrapper}>
      <MSelect
        options={reportOptions}
        value={reportOptions.find((option) => option.value === reportType)}
        optionValue={"value"}
        optionLabel={"label"}
        valuePadding={"2px 0px 2px 0px"}
        optionBackgroundColorHover={"#E6EEFF"}
        textColor={theme.palette.primary.main}
        selectedTextColor={theme.palette.primary.main}
        changeColorOnMenuOpen={true}
        closedTextColor={theme.palette.text.primary}
        borderColor={"transparent"}
        borderRadius={0}
        borderWidth={0}
        height={32}
        isBold={false}
        placeholder={I18n.t("reports.dashboard.select_ph")}
        handleChange={(option) => {
          if (option) {
            setReportType(option.value);
          }
        }}
      />

      <div className={classes.rightSideHeaderWrapper}>
        <MSelect
          options={eventOptions}
          value={eventOptions.find((option) => option.value === eventType)}
          optionValue={"value"}
          optionLabel={"label"}
          valuePadding={"2px 0px 2px 0px"}
          optionBackgroundColorHover={"#E6EEFF"}
          borderColor={"transparent"}
          textColor={theme.palette.primary.main}
          selectedTextColor={theme.palette.primary.main}
          borderRadius={0}
          changeColorOnMenuOpen={true}
          closedTextColor={theme.palette.text.primary}
          borderWidth={0}
          height={32}
          isBold={false}
          placeholder={I18n.t("reports.dashboard.select_ph")}
          handleChange={(option) => {
            if (option) {
              setEventType(option.value);
            }
          }}
        />
        <MSelect
          options={ANALYTICS_DISPLAY_MODES}
          value={ANALYTICS_DISPLAY_MODES.find((option) => option.value === displayMode)}
          optionValue={"value"}
          optionLabel={"label"}
          valuePadding={"2px 0px 2px 0px"}
          optionBackgroundColorHover={"#E6EEFF"}
          changeColorOnMenuOpen={true}
          closedTextColor={theme.palette.text.primary}
          textColor={theme.palette.primary.main}
          selectedTextColor={theme.palette.primary.main}
          borderColor={"transparent"}
          borderRadius={0}
          borderWidth={0}
          height={32}
          isBold={false}
          placeholder={I18n.t("reports.dashboard.select_ph")}
          handleChange={(option) => {
            if (option) {
              setDisplayMode(option.value);
            }
          }}
        />
      </div>
    </div>
  );
};

const CTACombinedReport = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, startDate } = props;

  const REPORT_TYPES: { value: string; label: string }[] = useMemo(() => {
    return [
      {
        value: "cta-origin",
        label: I18n.t("reports.dashboard.cta_by_landing_page_title"),
      },
      {
        value: "cta-by-url",
        label: I18n.t("reports.dashboard.cta_by_url"),
      },
      {
        value: "cta-by-destination",
        label: I18n.t("reports.dashboard.cta_by_destination"),
      },
    ];
  }, []);

  const EVENT_TYPES: { value: string; label: string }[] = useMemo(() => {
    return [
      {
        value: "cta-clicks",
        label: I18n.t("reports.dashboard.cta_clicks"),
      },
      {
        value: "internal-link-click",
        label: I18n.t("reports.dashboard.internal_link_click"),
      },
      {
        value: "outbound-link-click",
        label: I18n.t("reports.dashboard.outbound_link_click"),
      },
    ];
  }, []);

  const [displayMode, setDisplayMode] = useState<DisplayModes>("table");
  const [reportType, setReportType] = useState<any>(REPORT_TYPES[0].value);
  const [eventType, setEventType] = useState<any>(EVENT_TYPES[0].value);
  const extraParams = React.useMemo(() => ({ reportType: eventType }), [selectedWebsite, eventType]);

  const {
    loading,
    fetchResponse,
    triggerRefresh,
    setTriggerRefresh,
    monthFormat,
    numberFormat,
    fullDateFormat,
    titlesFormat,
  } = useReport({
    selectedWebsite,
    startDate,
    reportType,
    extraParams,
  });

  const { data, totals, cacheData } = React.useMemo(() => {
    if (fetchResponse) {
      return {
        data: fetchResponse.results,
        totals: fetchResponse.totals,
        cacheData: {
          cache: fetchResponse.cache,
          lastFetch: fetchResponse.fetchedTime,
        },
      };
    }
    return {
      totals: [],
      data: [],
    };
  }, [fetchResponse]);

  const LIMIT_VALUES = useMemo(
    () => [
      {
        name: "5 rows",
        value: 5,
      },
      {
        name: "10 rows",
        value: 10,
      },
      {
        name: "20 rows",
        value: 20,
      },
      {
        name: "50 rows",
        value: 50,
      },
      {
        name: "100 rows",
        value: 100,
      },
    ],
    []
  );

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });

  const dateRange = useMemo(() => {
    const dates = [];

    const start = moment(startDate).startOf("month");
    const end = moment().endOf("month");

    while (start.isBefore(end)) {
      dates.push(start.format("YYYY-MM-DD"));
      start.add(1, "month");
    }

    return dates.map((date) => ({
      header: moment(date).format("MMM"),
      accessorKey: "date_data",
      enableSorting: false,
      cell: (cellProps) => (
        <>{cellProps.cell.getValue()?.[date] ? numeral(cellProps.cell.getValue()[date]).format("0a") : "-"}</>
      ),
    }));
  }, [startDate, fetchResponse]);

  const columns = useMemo(() => {
    switch (reportType) {
      case "cta-origin":
        return [
          {
            accessorKey: "origin",
            header: I18n.t("reports.column_names.landing_page"),
            enableSorting: true,
            cell: (cellProps) => (
              <AnalyticsTableCellLink selectedWebsite={selectedWebsite} url={cellProps.cell.getValue()} />
            ),
          },
          ...dateRange,
          {
            accessorKey: "total",
            header: I18n.t("reports.column_names.total"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0a")}</>,
          },
        ];
      case "cta-by-url":
        return [
          {
            accessorKey: "url",
            header: I18n.t("reports.column_names.origin"),
            enableSorting: true,
            cell: (cellProps) => (
              <AnalyticsTableCellLink selectedWebsite={selectedWebsite} url={cellProps.cell.getValue()} />
            ),
          },
          ...dateRange,
          {
            accessorKey: "total",
            header: I18n.t("reports.column_names.total"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0a")}</>,
          },
        ];
      case "cta-by-destination":
        return [
          {
            accessorKey: "destination",
            header: I18n.t("reports.column_names.destination"),
            enableSorting: true,
            cell: (cellProps) => (
              <AnalyticsTableCellLink selectedWebsite={selectedWebsite} url={cellProps.cell.getValue()} />
            ),
          },
          ...dateRange,
          {
            accessorKey: "total",
            header: I18n.t("reports.column_names.total"),
            cell: (cellProps) => <>{numeral(cellProps.cell.getValue()).format("0a")}</>,
          },
        ];
      default:
        return [];
    }
  }, [reportType, selectedWebsite, dateRange]);

  return (
    <ReportCard
      cacheData={cacheData}
      subTitle={I18n.t("reports.dashboard.all_pages")}
      setRefreshCache={() => {
        sessionStorage.setItem(REPORT_CACHE_KEY_NAME, "true");
        setTriggerRefresh(!triggerRefresh);
      }}
      containsData={data.length > 0}
      loading={loading}
      totalPageCount={Math.ceil(data.length / pagination.pageSize)}
      pagination={pagination}
      limitOptions={LIMIT_VALUES}
      reportTypeName={reportType}
      setPagination={setPagination}
      hidePagination={displayMode === "graph"}
      secondaryHeader={
        <ReportSecondaryHeader
          reportType={reportType}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          reportOptions={REPORT_TYPES}
          eventOptions={EVENT_TYPES}
          eventType={eventType}
          setEventType={setEventType}
          setReportType={setReportType}
        />
      }
    >
      {displayMode === "table" && (
        <ResponsiveContainer width={"98%"} height={400} className={classes.chartContainer}>
          <AnalyticsTable<any>
            selectedWebsite={selectedWebsite}
            pagination={pagination}
            style={{
              firstColumnWidth: "40%",
              secondColumnWidth: "7%",
            }}
            columns={columns}
            data={data}
          />
        </ResponsiveContainer>
      )}

      {displayMode === "graph" && (
        <EngagementsTotalChart
          data={totals}
          timeFormat={monthFormat}
          numberFormat={numberFormat}
          fullDateFormat={fullDateFormat}
          titlesFormat={titlesFormat}
        />
      )}
    </ReportCard>
  );
};

export default CTACombinedReport;
