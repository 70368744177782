import React, { createRef, useMemo } from "react";
import { makeStyles } from "@material-ui/core";

import { IExpert, IWebsite } from "../../../../../reducers/constants/objectTypes";
import ContactInfo from "./ContactInfo";
import ExpertAuthorization from "./ExpertAuthorization";
import ExpertCertifications from "./ExpertCertifications";
import ExpertEducation from "./ExpertEducation";
import ExpertStatusSection from "./ExpertStatusSection";
import TabLayout from "./TabLayout";
import PreviousExperience from "./PreviousExperience";
import ProfileInfo from "./ProfileInfo";
import SocialProfiles from "./SocialProfiles";

type Section = {
  label: string;
  Component: React.FC<Omit<PropTypes, "rootElementRef">>;
  ref: React.MutableRefObject<HTMLDivElement>;
};

type PropTypes = {
  expert: IExpert;
  selectedWebsite: IWebsite;
  rootElementRef: React.RefObject<HTMLElement>;
  handleExpertChange: (expert: IExpert) => void;
};

const useStyles = makeStyles(() => ({
  scrollSpyTabs: {
    top: 150,
    marginTop: 87,
  },
}));

const ExpertOnlineProfile = (props: PropTypes) => {
  const { expert, selectedWebsite, rootElementRef } = props;
  const { handleExpertChange } = props;

  const classes = useStyles();

  const sections: Section[] = React.useMemo(
    () => [
      {
        label: "Settings",
        Component: ExpertAuthorization,
        ref: createRef(),
      },
      {
        label: "Contact info",
        Component: ContactInfo,
        ref: createRef(),
      },
      {
        label: "Profile info",
        Component: ProfileInfo,
        ref: createRef(),
      },
      {
        label: "Professional experience",
        Component: PreviousExperience,
        ref: createRef(),
      },
      {
        label: "Education",
        Component: ExpertEducation,
        ref: createRef(),
      },
      {
        label: "Licenses & certifications",
        Component: ExpertCertifications,
        ref: createRef(),
      },
      {
        label: "Social profiles",
        Component: SocialProfiles,
        ref: createRef(),
      },
    ],
    []
  );

  const tabSections = useMemo(() => sections.map(({ label, ref }) => ({ label, ref })), [sections]);

  return (
    <TabLayout
      sections={tabSections}
      rootElementRef={rootElementRef}
      classes={{
        scrollSpyTabs: classes.scrollSpyTabs,
      }}
    >
      <ExpertStatusSection
        active={expert.active}
        slug={expert.slug}
        handleActiveChange={(active) => handleExpertChange({ ...expert, active })}
      />
      {sections.map(({ label, Component, ref }) => (
        <div key={label} ref={ref}>
          <Component expert={expert} selectedWebsite={selectedWebsite} handleExpertChange={handleExpertChange} />
        </div>
      ))}
    </TabLayout>
  );
};

export default ExpertOnlineProfile;
