// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import React from "react";
import { PlayArrow, Pause, VolumeOff, VolumeDown, VolumeUp, Replay } from "@material-ui/icons";
import { Theme, Button, Typography, Slider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { round } from "lodash";

import { useVideoEditorContext } from "../VideoEditorContext";
import { millisecondsToTime } from "../utils";

const useStyles = makeStyles((theme: Theme) => ({
  controlBarWrapper: {
    width: "100%",
    padding: "8px 20px",
    backgroundColor: "#20222B",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 20,
    borderBottom: "2px solid #000",
    position: "relative",
  },
  buttonsWrapper: {
    display: "flex",
    gap: 30,
    alignItems: "center",
  },
  timerWrapper: {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  button: {
    color: "#fff",
    fontSize: 20,
    padding: 0,
    minWidth: 0,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  timer: {
    fontSize: 14,
    color: "#fff",
    whiteSpace: "break-spaces",
  },
  timerLeft: {
    opacity: 0.8,
  },
  icon: {
    color: "#fff",
  },
  volumeWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  volumeSlider: {
    color: "#fff",
    padding: "5px 0px",
    width: 0,
    height: 5,
    transition: "width 0.2s cubic-bezier(.4,0,1,1), opacity 0.2s cubic-bezier(.4,0,1,1)",
    opacity: 0,
    "& .MuiSlider-rail": {
      opacity: 0.7,
      height: 5,
    },
    "& .MuiSlider-track": {
      height: 5,
    },
    "& .MuiSlider-thumb": {
      marginTop: -4,
    },
    "& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover": {
      boxShadow: "none",
      marginTop: -4,
    },
  },
  volumeSliderVisible: {
    width: 50,
    opacity: 1,
  },
}));

function VideoControlBar() {
  const classes = useStyles();

  const { timelineState, timelineRef, selectedTimeline, editVideoEditorTimelineProperty, jumpToTime } =
    useVideoEditorContext();
  const [showVolumeSlider, setShowVolumeSlider] = React.useState(false);

  const handlePlayOrPause = () => {
    if (!timelineRef.current) return;
    if (timelineRef.current.isPlaying) {
      timelineRef.current.pause();
    } else {
      timelineRef.current.play({ autoEnd: true });
    }
  };

  const videoEndTime = React.useMemo(
    () =>
      selectedTimeline.rows.reduce(
        (acc, row) => row.actions.reduce((acc2, action) => Math.max(acc2, action.end), acc),
        0
      ),
    [selectedTimeline.rows]
  );

  const muteVolume = () => {
    editVideoEditorTimelineProperty({ volume: 0 });
  };

  return (
    <div className={classes.controlBarWrapper}>
      <div className={classes.buttonsWrapper}>
        {videoEndTime > 0 && round(timelineState.time, 1) === round(videoEndTime, 1) ? (
          <Button
            className={classes.button}
            disableRipple
            onClick={() => {
              timelineRef.current?.setTime(0);
              timelineRef.current?.play({ autoEnd: true });
            }}
          >
            <Replay />
          </Button>
        ) : (
          <Button
            className={classes.button}
            disableRipple
            onClick={() => !timelineState.isBuffering && handlePlayOrPause()}
          >
            {timelineState?.isPlaying ? <Pause /> : <PlayArrow />}
          </Button>
        )}
        <div
          onMouseEnter={() => setShowVolumeSlider(true)}
          onMouseLeave={() => setShowVolumeSlider(false)}
          className={classes.volumeWrapper}
        >
          {timelineState.volume === 0 ? (
            <VolumeOff className={classes.icon} onClick={muteVolume} />
          ) : timelineState.volume < 0.5 ? (
            <VolumeDown className={classes.icon} onClick={muteVolume} />
          ) : (
            <VolumeUp className={classes.icon} onClick={muteVolume} />
          )}
          <Slider
            className={classNames(classes.volumeSlider, showVolumeSlider && classes.volumeSliderVisible)}
            value={timelineState.volume * 100}
            onChange={(_, value) => {
              editVideoEditorTimelineProperty({ volume: (value as number) / 100 });
            }}
          />
        </div>
      </div>
      <div className={classes.timerWrapper}>
        {videoEndTime ? (
          <>
            <Typography variant="body1" className={classes.timer}>
              {millisecondsToTime(timelineState.time * 1000)} /{" "}
            </Typography>
            <Typography variant="body1" className={classNames(classes.timer, classes.timerLeft)}>
              {millisecondsToTime(videoEndTime * 1000)}
            </Typography>
          </>
        ) : null}
      </div>
      <div className={classes.buttonsWrapper}></div>
    </div>
  );
}

export default VideoControlBar;
