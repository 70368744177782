import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

const useStyles = makeStyles<Theme, PropTypes>((theme) => ({
  row: (props: PropTypes) => ({
    display: "flex",
    justifyContent: props.flexDirection === "column" ? "start" : "space-between",
    flexDirection: props.flexDirection,
    alignItems: props.flexDirection === "column" ? "start" : "center",
    ...(props.flexDirection === "column" && { width: "100%" }),
    marginBottom: 15,
  }),
  valueWrap: (props: PropTypes) => ({
    display: "flex",
    alignItems: "center",
    ...(props.flexDirection === "column" && { width: "100%" }),
  }),
  backToDefault: {
    color: theme.palette.text.hint,
    fontSize: 16,
    marginRight: 5,
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectWrap: {
    width: (props) => (props.flexDirection === "column" ? props.width || "100%" : props.width || 160),
    height: 37,
    borderRadius: 4,
    padding: "10px 5px",
    border: `${theme.palette.divider} solid 1px`,
  },
  selectMenu: {
    paddingLeft: 5,
    textTransform: (props: PropTypes) => props.menuTextTransform || "capitalize",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    marginBottom: (props: PropTypes) => (props.flexDirection === "column" ? 13 : 0),
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold as any,
  },
  menuItem: {
    textTransform: (props: PropTypes) => props.menuTextTransform || "capitalize",
  },
}));

type PropTypes = {
  id?: string;
  rowTitle?: string;
  bold?: boolean;
  value: string;
  defaultValue?: string;
  isValueChanged?: boolean;
  flexDirection?: "column" | "row";
  selectOptions: any[];
  width?: number | string;
  handleChange: any;
  getLabel?: (option: string) => string;
  menuTextTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
};

const SelectRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    id,
    rowTitle,
    value = "",
    defaultValue = "",
    isValueChanged = false,
    flexDirection = "row",
    bold = false,
    selectOptions,
    handleChange,
    getLabel = null,
  } = props;

  const handleSelect = (event) => {
    handleChange(event.target.value);
  };

  const handleBackToDefault = () => {
    handleChange(defaultValue);
  };

  return (
    <div id={id} className={classes.row}>
      {rowTitle && (
        <Typography
          variant={"body2"}
          className={ClassNames(classes.text, (isValueChanged || bold) && classes.boldText)}
        >
          {rowTitle}
        </Typography>
      )}
      <div className={classes.valueWrap}>
        {isValueChanged && (
          <Tooltip enterDelay={1000} title={I18n.t("website.back_to_default_tooltip")}>
            <CloseIcon className={classes.backToDefault} onClick={handleBackToDefault} />
          </Tooltip>
        )}
        <Select
          value={value || defaultValue}
          IconComponent={KeyboardArrowDown}
          disableUnderline
          className={classes.selectWrap}
          classes={{ selectMenu: classes.selectMenu }}
          onChange={handleSelect}
        >
          {selectOptions.map((option, index) => (
            <MenuItem className={classes.menuItem} key={`${option}_${index}`} value={option}>
              {getLabel?.(option) || option}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};
export default SelectRow;
