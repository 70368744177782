import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative" as "relative",
    display: "flex",
  },
  top: {
    color: "transparent",
  },
  bottom: {
    color: theme.palette.primary.main,
    animationDuration: "550ms",
    position: "absolute" as "absolute",
    left: 0,
  },
}));

type PropTypes = {
  customClass?: string;
  size?: number;
};

const CfProgressBar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { size = 40, customClass } = props;

  return (
    <div className={ClassNames(classes.root, customClass)}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={size}
        thickness={4}
        {...props}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        size={size}
        thickness={4}
        {...props}
      />
    </div>
  );
};

export default CfProgressBar;
