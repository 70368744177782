import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Close } from "@material-ui/icons";
import { Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ marginBottom }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 15,
    marginBottom: marginBottom || 5,
    width: "100%",
  }),
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    flexShrink: 0,
    color: theme.palette.text.primary,
    minWidth: 8,
  },
  removeIcon: ({ showRemoveIcon }: PropTypes) => ({
    height: 20,
    width: 20,
    color: theme.palette.text.primary,
    "&:hover": {
      cursor: "pointer",
    },
    visibility: showRemoveIcon ? "visible" : "hidden",
  }),
}));

type PropTypes = {
  index: number;
  children: React.ReactNode;
  showRemoveIcon: boolean;
  showCounter?: boolean;
  marginBottom?: number;
  onRemove: () => void;
};

const MNumeratedItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { index, showCounter = true, children } = props;
  const { onRemove } = props;

  return (
    <div className={classes.root}>
      {showCounter && (
        <Typography className={classes.title} variant="body1">
          {index + 1}
        </Typography>
      )}
      {children}
      <Close className={classes.removeIcon} onClick={onRemove} />
    </div>
  );
};

export default MNumeratedItem;
