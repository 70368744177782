import React from "react";
import Tab, { TabProps } from "@material-ui/core/Tab";
import Tabs, { TabsProps } from "@material-ui/core/Tabs";
import useScrollSpyTabs, { UseScrollSpyTabsProps } from "../hooks/useScrollSpyTabs";

type PropTypes = {
  TabsProps?: TabsProps;
  TabProps?: TabProps;
} & UseScrollSpyTabsProps;

const MScrollSpyTabs = (props: PropTypes) => {
  const { activeTab, handleTabClick } = useScrollSpyTabs(props);

  const { sections } = props;

  return (
    <Tabs value={activeTab} indicatorColor="primary" textColor="primary" {...props.TabsProps}>
      {sections.map(({ label }, index) => (
        <Tab key={index} label={label} onClick={() => handleTabClick(index)} {...props.TabProps} />
      ))}
    </Tabs>
  );
};

export default MScrollSpyTabs;
