import * as React from "react";

import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import { eActionIcon, ePostType, eUserRoles, IAdminUser, IPage } from "../../../../reducers/constants/objectTypes";

import MTextButton from "../../../../components/MTextButton";
import agent from "../../../../agent";
import { AbilityContext } from "../../../../casl/can";
import { web } from "../../../../helpers/urlHelper";
import { FACT_CHECK_PAGES } from "../../../../reducers/constants/actionTypes";
import { DATE_FORMAT } from "../../../../reducers/constants/consts";
import localeHelper from "../../../../helpers/localeHelper";
import CfButton from "../../../../components/CfButton";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import MTableCellDate from "../../../../components/Table/Cells/MTableCellDate";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MTableCellNumberProportion from "../../../../components/Table/Cells/MTableCellNumberProportion";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";
import MTableCellComments from "../../../../components/Table/Cells/MTableCellComments";
import MTableCell from "../../../../components/Table/Cells/MTableCell";
import { copyStringToClipboard } from "../../../../helpers/fbScraperHelper";
import CopyNameIcon from "../../../../icons/CopyNameIcon";
import { camelCase, startCase } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $topicWrapper": {
        paddingTop: 1,
        borderBottom: "1px solid transparent",
      },
      "& $iconColumn": {
        paddingTop: 1,
      },
      "& $actions": {
        display: "flex",
      },
      "& $takenByText": {
        display: "none",
      },
      "& $takeOverButton": {
        display: "flex",
      },
      "& $writerNameHover": {
        display: "none",
      },
    },
  },
  removeButton: {
    color: theme.palette.primary.main,
    padding: 0,
    minWidth: "unset",
  },
  takenWrapper: {
    alignItems: "center",
    display: "flex",
  },
  takenByText: {
    display: "unset",
  },
  takeOverButton: {
    display: "none",
    backgroundColor: "#ff4a64",
  },
  writerName: {
    display: "flex",
    fontWeight: theme.typography.fontWeightLight as any,
    fontSize: "12px",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "left",
  },
  writerNameHover: {},
  actions: {},
}));

type PropTypes = {
  user: IAdminUser;

  taken?: boolean;
  rejected?: boolean;
  publicRow?: boolean;
  post: IPage;
  editPage: (id: string, currentUrl: string) => void;
  refreshPostList: () => void;
  onDateUpdate: Function;
  onPostTypeUpdate: Function;
};

const mapStateToProps = (state) => ({
  user: state.home.admin,
});

const mapDispatchToProps = (dispatch) => ({
  editPage: (id: string, currentUrl: string) => dispatch(push(web.editPage(id, currentUrl))),
  refreshPostList: () => dispatch({ type: FACT_CHECK_PAGES.FACT_CHECK_PAGES_REFRESH }),
});

const FactCheckPostItemRow = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    user,
    post,
    editPage,
    refreshPostList,
    rejected,
    publicRow,
    taken = false,
    onDateUpdate,
    onPostTypeUpdate,
  } = props;
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const ability = React.useContext<any>(AbilityContext);
  const isEditor = ability.can(eUserRoles.EDITOR, "role");
  const theme = useTheme();
  const discardPost = (e) => {
    e.stopPropagation();
    agent.Pages.discardFactChecker(post)
      .then(() => {
        refreshPostList();
        enqueueSnackbar(I18n.t(`snackbar.post.discard.${post.type}`));
      })
      .catch((event) => {
        enqueueSnackbar(I18n.t("snackbar.post.discard.error", { msg: event?.response?.body?.message }));
      });
  };

  const selectPost = () => {
    agent.Pages.selectFactChecker(post)
      .then((res) => {
        refreshPostList();
        editPage(post._id, location.pathname);
      })
      .catch((e) => {});
  };

  const factCheckTakeOver = (pageId: string) => {
    agent.Pages.factCheckerTakeOver(pageId).then((res) => {
      enqueueSnackbar(I18n.t("snackbar.update_success"));
      editPage(pageId, location.pathname);
    });
  };

  const actions = React.useMemo(
    () => [
      {
        text: I18n.t(`learning_center_content.tooltip.copy_title`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_title`),
        onClick: () => copyStringToClipboard(post.title, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))),
        iconName: eActionIcon.copyName,
      },
    ],
    [post.title]
  );

  return (
    <MTableRow
      handleRowClick={() => {
        if (taken) {
          return;
        }
        selectPost();
      }}
      customClassName={classes.itemWrapper}
    >
      <MTableCellFirst
        columnWidth={"30%"}
        fontSize={theme.typography.pxToRem(14)}
        avatarType={post.type as ePostType}
        cellText={post.title}
        actions={actions}
        actionClassName={classes.actions}
      />
      <MTableCellDate date={post.createdAt} />
      <MTableCellDate
        date={post.dueDate}
        dateFormat={DATE_FORMAT.MONTH_YEAR}
        underlineOnHover={isEditor}
        handleDateClick={(e) => {
          if (isEditor) {
            onDateUpdate("dueDate");
          }
        }}
      />
      <MTableCellDate
        date={post.quotaDate}
        dateFormat={DATE_FORMAT.MONTH_YEAR}
        underlineOnHover={isEditor}
        handleDateClick={(e) => {
          if (isEditor) {
            onDateUpdate("quotaDate");
          }
        }}
      />
      <MTableCellText text={localeHelper.getLanguageFromLocaleCode(post.locale)} />
      <MTableCellNumberProportion value1={post.textWordsCount} value2={post.outline?.wordCount || 1000} />
      <MTableCell tooltip={startCase(camelCase(post.type))} onClick={() => onPostTypeUpdate()}>
        {startCase(camelCase(post.type))}
      </MTableCell>
      <MTableCellText text={post.categories[0]?.name} />
      <MTableCellNumber value={post.linksCount} />
      <MTableCellComments open={post.openCommentsCount} resolved={post.resolvedCommentsCount} />
      <MTableCellText text={post.imported ? "✓" : "✘"} />
      <MTableCellText text={post.optimize ? "✓" : "✘"}></MTableCellText>
      <MTableCellText text={post.writer?.name} />

      <MTableCell columnWidth={"8%"}>
        {!publicRow && !rejected && post?.factChecker?._id === user._id && (
          <MTextButton customClass={classes.removeButton} text={"Remove"} onClick={(e) => discardPost(e)} />
        )}

        {taken && (
          <div className={classes.takenWrapper}>
            {post?.factChecker?.name && (
              <Typography
                className={ClassNames(classes.writerName, ability.can("takeOver", "page") && classes.writerNameHover)}
              >
                {post.factChecker?.name || ""}
              </Typography>
            )}

            {ability.can("takeOver", "page") && (
              <CfButton
                height={36}
                customClass={classes.takeOverButton}
                text={I18n.t("workflow.writer.take_over")}
                onClick={() => {
                  factCheckTakeOver(post._id);
                }}
              />
            )}
          </div>
        )}
      </MTableCell>
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FactCheckPostItemRow);
