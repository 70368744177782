import * as React from "react";
import { I18n } from "react-redux-i18n";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import { IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { find } from "lodash";
import BackgroundColorCSS from "../../../elementsStyles/CssComponents/BackgroundColorCSS";
import WidthCSS from "../../../elementsStyles/CssComponents/WidthCSS";
import TitleElement from "../../../elementsStyles/ElementComponents/TitleElement";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../../elementsStyles/components/StylePanelSubSection";
import FontSizeCSS from "../../../elementsStyles/CssComponents/FontSizeCSS";
import FontColorCSS from "../../../elementsStyles/CssComponents/FontColorCSS";
import ButtonOldElement from "../../../elementsStyles/ElementComponents/ButtonOldElement";
import ProductPickerElement from "../../../elementsStyles/ElementComponents/ProductPickerElement";

type PropTypes = {
  editor: Editor;
  selectedWebsite: IWebsite;
  websiteTheme: IWebsiteThemeProps;
  element: any;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
};

const ProductEmbedWidgetStyle = (props: PropTypes) => {
  const { editor, selectedWebsite, element, products, handleProductsChange, websiteTheme } = props;

  const handleElementDataChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.product_embed.name")} editor={editor}>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.product_embed.settings")}>
        <ProductPickerElement
          isClearable={false}
          fullWidth
          selectedWebsite={selectedWebsite}
          products={products}
          handleProductsChange={handleProductsChange}
          product={product}
          onProductChange={(prod: IProduct) => {
            if (!prod) {
              handleElementDataChange({ ...element.data, product: prod });
            } else {
              handleElementDataChange({
                ...element.data,
                product: {
                  itemNumber: prod.itemNumber,
                },
              });
            }
          }}
        />
        {/*<EditArticleStyleSelect height={32} rowTitle={I18n.t('rich_text_editor.product_embed.design_template')} value={'Desktop'} selectOptions={['Desktop', 'Mobile']} handleChange={(v) => {*/}
        {/*	console.log(v);*/}
        {/*}} />*/}

        <BackgroundColorCSS
          title={I18n.t("rich_text_editor.product_embed.background_color")}
          value={element.data?.backgroundColor || websiteTheme.palette.secondary.main}
          defaultValue={websiteTheme.palette.secondary.main}
          isValueChanged={false}
          handleChange={(color) => handleElementDataChange({ ...element.data, backgroundColor: color })}
        />
        <WidthCSS
          value={element.data?.productWrapperWidth || undefined}
          handleChange={(value) => handleElementDataChange({ ...element.data, productWrapperWidth: value })}
        />
      </StylePanelSubSection>

      <TitleElement theme={websiteTheme} element={element} handleChange={(data) => handleElementDataChange(data)} />
      <StylePanelSubSection
        sectionTitle={I18n.t("rich_text_editor.product_embed.price")}
        show={element.data?.showPrice}
        handleShowChange={(value) => handleElementDataChange({ ...element.data, showPrice: value })}
      >
        <FontSizeCSS
          value={element.data?.priceCss?.fontSize || undefined}
          defaultValue={websiteTheme.typography.body2.css.fontSize}
          handleChange={(fontSize) => {
            handleElementDataChange({ ...element.data, priceCss: { ...element.data?.priceCss, fontSize } });
          }}
        />
        <FontColorCSS
          value={element.data?.priceCss?.color || websiteTheme.palette.text.primary}
          defaultValue={websiteTheme.palette.text.primary}
          isValueChanged={false}
          handleChange={(color) => {
            handleElementDataChange({ ...element.data, priceCss: { ...element.data?.priceCss, color } });
          }}
        />
      </StylePanelSubSection>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.product_embed.button")}>
        <ButtonOldElement
          buttonText={element.data.buttonText || product?.callToAction || ""}
          buttonUrl={element.data.buttonUrl || product?.callToActionURL || ""}
          buttonCss={element.data?.buttonCss || {}}
          buttonDefaultCss={{
            color: "#fff",
            backgroundColor: websiteTheme.palette.primary.main,
            textTransform: "capitalize",
            lineHeight: 1,
            ...websiteTheme.buttons.css,
            "&:hover": {
              backgroundColor: websiteTheme.palette.primary.light,
              color: "#fff",
              textTransform: "capitalize",
              ...websiteTheme.buttons.css?.["&hover"],
            },
          }}
          handleChange={(buttonProps) => {
            handleElementDataChange({ ...element.data, ...buttonProps });
          }}
        />
      </StylePanelSubSection>
    </StylePanelSection>
  );
};

export default ProductEmbedWidgetStyle;
