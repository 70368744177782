import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    height: 80,
    width: 60,  
  },
}));

type PropTypes = {
  className?: string;
};

function LayoutEmbeddedUnSelectedIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 62 71"}>
     
      <rect x="14" y="13" width="34" height="46" rx="8"  fill="#AEAEAE" />
      <rect
        x="1.25"
        y="1.25"
        width="59.5"
        height="68.5"
        rx="1.75"
        fill="transparent"
        stroke="#AEAEAE"
        strokeWidth="2.5"
        strokeDasharray="10 10"
      />
    </SvgIcon>
  );
}

export default LayoutEmbeddedUnSelectedIcon; 