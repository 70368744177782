import * as React from "react";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Button, TextField, Theme, Typography } from "@material-ui/core";
import _ from "lodash";
import ClassNames from "classnames";
import BorderRadiusCSS from "../../../../../../../editor/elementsStyles/CssComponents/BorderRadiusCSS";
import SelectProperty from "../../build/items/itemProperties/components/SelectProperty";
import FontColorCSS from "../../../../../../../editor/elementsStyles/CssComponents/FontColorCSS";
import GradientColorPickerBaseComponent from "../../../../../../../editor/elementsStyles/BaseComponents/GradientColorPickerBaseComponent";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import DesignBackgroundModel from "../models/DesignBackgroundModel";
import InsertImageDialog from "../../../../../../Modals/InsertImageDialog/InsertImageDialog";
import { lightWidgetsTheme } from "../../../../../../../themes/widgetFormBuilderTheme";
import { eFormBackgroundOption, IFile, IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import { widgetDesignDefaultValues } from "../../../constants";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    padding: "30px",
  },
  wrapper: {
    backgroundColor: palette.secondary.main,
    borderRadius: "5px",
    padding: "25px 15px 15px 15px",
  },
  headerItems: {
    fontSize: "16px",
    verticalAlign: "middle",
    display: "flex",
    padding: "0px 0px 10px 0px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowBack: {
    display: "flex",
    marginLeft: "-8px",
    cursor: "pointer",
  },
  backIcon: {
    height: "22px",
    color: palette.common.white,
  },
  button: {
    background: palette.primary.light,
    justifySelf: "flex-end",
    height: "27px",
    width: "54px",
    fontSize: "14px",
    fontWeight: typography.fontWeightLight,
    textTransform: "none",
  },
  head: {
    userSelect: "none",
    color: palette.common.white,
    fontSize: "16px",
    lineHeight: "21px",
  },
  body: {
    width: "100%",
    borderRadius: "5px",
    marginTop: "10px",
  },
  marginTop: {
    marginTop: 20,
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 20,
  },
  video: {
    marginTop: 20,
    marginBottom: 10,
  },
  label: {
    color: "white",

    "& label": {
      "&.Mui-focused": {
        color: "white",
      },
      color: "white",
    },
  },
  image: {
    fontSize: "16px",
    verticalAlign: "middle",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 25,
    color: "white",
  },
  colorPicker: {
    top: "100%",
    right: 0,
    left: -70,
  },
  borderRadiusWrapper: {
    marginTop: 20,
  },
  textColor: { color: "white" },
  videoTextField: { color: "white", fontSize: "14px" },
  imagePicker: {},
}));

const mapStateToProps = (state) => ({
  website: state.home.selectedWebsite,
  backgroundDesign: state.widgetBuilder.widget.backgroundDesign,
});

const mapDispatchToProps = (dispatch) => ({
  editbackgroundDesign: (backgroundDesign) =>
    dispatch({ type: WIDGET_BUILDER.DESIGN, key: "backgroundDesign", value: backgroundDesign }),
});

type PropTypes = {
  website: IWebsite;
  backgroundDesign: DesignBackgroundModel;
  goBack: (any) => void;
  editbackgroundDesign: (backgroundDesign: DesignBackgroundModel) => void;
};
const BackgroundDesign = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website, goBack, editbackgroundDesign, backgroundDesign } = props;

  const [newBackgroundDesign, setNewBackgroundDesign] = React.useState(backgroundDesign);
  const remoteWebsiteTheme = React.useMemo(
    () =>
      ({
        backgroundOption: "Color" || widgetDesignDefaultValues.background.backgroundOption,
        borderRadius: false || widgetDesignDefaultValues.background.borderRadius,
        background: {
          color: "red" || widgetDesignDefaultValues.background.background.color,
        },
      } as DesignBackgroundModel),
    [website]
  );

  // const [backgroundOption, setBackgroundOption] = React.useState(backgroundDesign?.backgroundOption || "color");

  // const [borderRadius, setBorderRadius] = React.useState(backgroundDesign?.borderRadius || "0px 0px 0px 0px");
  // const [color, setColor] = React.useState(backgroundDesign?.background?.color || "white");
  // const [gradientColor, setGradientColor] = React.useState({
  //   points: [
  //     {
  //       left: 0,
  //       red: 178,
  //       green: 167,
  //       blue: 167,
  //       alpha: 100,
  //     },
  //     {
  //       left: 100,
  //       red: 255,
  //       green: 0,
  //       blue: 0,
  //       alpha: 1,
  //     },
  //   ],
  //   type: "linear",
  //   degree: 100,
  //   style: "linear-gradient(100deg,rgba(178, 167, 167, 100) 0%,rgba(255, 0, 0, 1) 100%)",
  // });
  // const [image, setImage] = React.useState("");
  const [openImagePicker, setOpenImagePicker] = React.useState(false);

  function handleClick() {
    editbackgroundDesign(newBackgroundDesign);
    goBack(null);
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.headerItems}>
          <div className={classes.arrowBack} onClick={() => goBack(null)}>
            <KeyboardArrowLeftRounded className={classes.backIcon} />
            <Typography variant={"h6"} className={classes.head}>
              {I18n.t("widgets_builder.design.background")}
            </Typography>
          </div>
          <Button className={classes.button} onClick={() => handleClick()}>
            {I18n.t("widgets_builder.design.done")}
          </Button>
        </div>
        <div className={classes.body}>
          <SelectProperty
            value={newBackgroundDesign?.backgroundOption || remoteWebsiteTheme?.backgroundOption}
            options={[eFormBackgroundOption.Color, eFormBackgroundOption.Gradient, eFormBackgroundOption.Image]} // eFormBackgroundOption.Video
            onChange={(backgroundOption) => {
              console.log("backgroundOption", backgroundOption);
              setNewBackgroundDesign({ ...newBackgroundDesign, backgroundOption });
            }}
          />

          {(newBackgroundDesign?.backgroundOption || remoteWebsiteTheme.backgroundOption) ===
            eFormBackgroundOption.Color && (
            <div className={classes.marginTop}>
              <FontColorCSS
                title={I18n.t("widgets_builder.design.main_color")}
                value={newBackgroundDesign?.background?.color || remoteWebsiteTheme.background.color}
                defaultValue={newBackgroundDesign?.background?.color || remoteWebsiteTheme.background.color}
                // defaultHoverValue={"red"}
                isValueChanged={true}
                handleChange={(color) => {
                  setNewBackgroundDesign({ ...newBackgroundDesign, background: { color } });
                }}
              />
            </div>
          )}

          {(newBackgroundDesign?.backgroundOption || remoteWebsiteTheme.backgroundOption) ===
            eFormBackgroundOption.Gradient && (
            <div className={classes.row}>
              <Typography variant={"body2"} className={classes.textColor}>
                {I18n.t("widgets_builder.design.gradient_colors")}
              </Typography>
              <GradientColorPickerBaseComponent
                top={"100%"}
                left={"-100px"}
                borderRadius={2}
                gradient={
                  newBackgroundDesign?.background?.gradientColor || remoteWebsiteTheme.background?.gradientColor
                }
                defaultColor={null}
                handleChange={(gradientColor) => {
                  setNewBackgroundDesign({ ...newBackgroundDesign, background: { gradientColor } });
                }}
              />
            </div>
          )}

          {(newBackgroundDesign?.backgroundOption || remoteWebsiteTheme.backgroundOption) ===
            eFormBackgroundOption.Image && (
            <>
              <div className={classes.image}>
                <Typography variant={"body2"} className={classes.textColor}>
                  {I18n.t("widgets_builder.design.image")}
                </Typography>
                <Button className={classes.button} onClick={() => setOpenImagePicker(true)}>
                  {I18n.t("widgets_builder.design.image_add")}
                </Button>
              </div>

              {openImagePicker && (
                <InsertImageDialog
                  website={website}
                  selectedImageFile={null}
                  customClass={classes.imagePicker}
                  handleInsertClick={(file: IFile) => {
                    setNewBackgroundDesign({ ...newBackgroundDesign, background: { image: file.cdnUrl } });
                    setOpenImagePicker(false);
                  }}
                  handleCloseClick={() => {
                    setOpenImagePicker(false);
                  }}
                />
              )}
            </>
          )}

          {/* {backgroundOption === eFormBackgroundOption.Video.toLowerCase() && (
            <div className={classes.video}>
              <TextField
                className={ClassNames(classes.label, classes.videoTextField)}
                label={I18n.t("widgets_builder.design.video_url")}
                placeholder={"www.pexel.com/techno_2334"}
                fullWidth
                margin="none"
                onChange={() => console.log("asd")}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          )} */}

          <div className={classes.borderRadiusWrapper}>
            <BorderRadiusCSS
              value={newBackgroundDesign?.borderRadius || remoteWebsiteTheme?.borderRadius}
              defaultValue={newBackgroundDesign?.borderRadius || remoteWebsiteTheme?.borderRadius}
              handleChange={(borderRadius) => {
                setNewBackgroundDesign({ ...newBackgroundDesign, borderRadius });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundDesign);
