import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";
import ClassNames from "classnames";
import PageSection from "./PageSection";
import { IWebsite, IWebsiteDetailsProps, IWebsiteLocale } from "../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../reducers/constants/actionTypes";
import { connect } from "react-redux";
import MRouterPrompt from "./MRouterPrompt";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import SelectRow from "./SelectRow";
import MAutoComplete from "../../../components/MAutoComplete";
import { allCurrencies } from "../../../helpers/constants";
import { find } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: 20,
  },
  alternateRow: {
    marginBottom: 30,
  },
  closeIcon: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    marginTop: 40,
    "&:hover": {
      cursor: "pointer",
    },
  },
  closeAlternateIcon: {
    marginLeft: "-40px",
  },
  replacementRow: {
    display: "flex",
    gap: 10,
    width: "100%",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
  },
}));

type AlternateReplacementsPropTypes = {
  locale: string;
  disabled?: boolean;
  alternateReplacements: [
    {
      replace: string;
      with: string;
    }
  ];
  onAlternateReplacementsChanged: (locale) => void;
};

const newAlternateReplacement = { replace: "", with: "" };
const AlternateReplacements = (props: AlternateReplacementsPropTypes) => {
  const classes = useStyles(props);
  const { locale, alternateReplacements = [], disabled = false, onAlternateReplacementsChanged } = props;

  return (
    <div className={classes.col}>
      {[...alternateReplacements, newAlternateReplacement].map((replacement, index) => (
        <div key={`alternate_replacememt_${locale}_${index}`} className={classes.row}>
          <TextFieldMaxLength
            minWidth={160}
            maxWidth={160}
            disabled={disabled}
            titleAndLengthLocation={"top"}
            value={replacement.replace}
            placeholder={I18n.t("website.alternate_locale_replace_ph")}
            title={I18n.t("website.alternate_locale_replace")}
            height={37}
            borderRadius={4}
            handleValueChange={(replace) => {
              if (!alternateReplacements[index]) {
                onAlternateReplacementsChanged([...alternateReplacements, { ...newAlternateReplacement, replace }]);
                return;
              }
              const replacements = [...alternateReplacements];
              replacements[index].replace = replace;
              onAlternateReplacementsChanged(replacements);
            }}
            marginBottom={0}
          />
          {/*<div className={classes.replacementRow}>*/}
          <TextFieldMaxLength
            minWidth={160}
            maxWidth={160}
            disabled={disabled}
            titleAndLengthLocation={"top"}
            value={replacement.with}
            placeholder={I18n.t("website.alternate_locale_with_ph")}
            title={I18n.t("website.alternate_locale_with")}
            height={37}
            borderRadius={4}
            handleValueChange={(replaceWith) => {
              if (!alternateReplacements[index]) {
                onAlternateReplacementsChanged([
                  ...alternateReplacements,
                  { ...newAlternateReplacement, with: replaceWith },
                ]);
                return;
              }
              const replacements = [...alternateReplacements];
              replacements[index].with = replaceWith;
              onAlternateReplacementsChanged(replacements);
            }}
            marginBottom={0}
          />
          {index !== 0 && (
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => {
                alternateReplacements.splice(index, 1);
                onAlternateReplacementsChanged([...alternateReplacements]);
                return;
              }}
            />
          )}
          {/*</div>*/}
        </div>
      ))}
    </div>
  );
};

type PropTypes = {
  selectedWebsite: IWebsite;
  locale: IWebsiteLocale;
  siteDetails: IWebsiteDetailsProps;
  onPropertyChanged: (name, value) => void;
};
const newAlternateLocale = { locale: "", inSitemap: true, replacements: [], currency: { code: "USD", symbol: "$" } };
const mapStateToProps = (state) => ({
  locale: state.websitePages.locale,
  siteDetails: state.websitePages.siteDetails,
});
const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (name, value) => dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED, name, value }),
});
const SiteDetailsLocale = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, locale, siteDetails, onPropertyChanged } = props;
  const [removed, onDelete] = React.useState(false);

  const hasChildren = (index) => {
    return locale.alternateLocales[index]?.replacements && locale.alternateLocales[index].replacements.length > 0;
  };
  return (
    <PageSection sectionTitle={I18n.t("website.locale")}>
      <MRouterPrompt />
      <div style={{ display: "flex", gap: 20 }}>
        <TextFieldMaxLength
          key={"default_locale"}
          titleAndLengthLocation={"top"}
          value={locale.defaultLocale}
          placeholder={""}
          title={I18n.t("website.default_locale")}
          maxLength={5}
          height={37}
          borderRadius={4}
          handleValueChange={(defaultLocale) => {
            onPropertyChanged("locale", { ...locale, defaultLocale });
          }}
          marginBottom={30}
        />
        <TextFieldMaxLength
          key={"site-url-prefix"}
          titleAndLengthLocation={"top"}
          value={siteDetails?.urlPrefix}
          placeholder={""}
          title={I18n.t("website.url_prefix")}
          maxLength={100}
          height={37}
          multiLine
          borderRadius={4}
          handleValueChange={(newURL) => {
            onPropertyChanged("siteDetails", { ...siteDetails, urlPrefix: newURL });
          }}
          marginBottom={30}
        />
        <SelectRow
          flexDirection={"column"}
          bold
          rowTitle={I18n.t("website.show_in_sitemap")}
          value={locale.inSitemap ? "Yes" : "No"}
          selectOptions={["Yes", "No"]}
          handleChange={(value) => {
            onPropertyChanged("locale", { ...locale, inSitemap: value === "Yes" });
          }}
        />
        <SelectRow
          flexDirection={"column"}
          bold
          rowTitle={I18n.t("website.currency")}
          value={(locale?.currency && `${locale?.currency.code} - ${locale?.currency.symbol}`) || `USD - $`}
          selectOptions={allCurrencies.map((currency) => `${currency.code} - ${currency.symbol}`)}
          handleChange={(currency) =>
            onPropertyChanged("locale", {
              ...locale,
              currency: find(allCurrencies, { code: currency.split(" - ")[0] }),
            })
          }
        />
      </div>
      {/*@ts-ignore*/}
      {[...locale?.alternateLocales, newAlternateLocale].map((alternate, index) => (
        <div key={`alternate_${index}`} className={ClassNames(classes.row, classes.alternateRow)}>
          <div style={{ width: "100%" }}>
            <div className={classes.row}>
              <TextFieldMaxLength
                titleAndLengthLocation={"top"}
                disabled={removed}
                value={alternate.locale}
                placeholder={I18n.t("website.alternate_locale_ph")}
                title={I18n.t("website.alternate_locale", { count: index + 1 })}
                maxLength={10}
                height={37}
                borderRadius={4}
                handleValueChange={(alternateLocale) => {
                  if (!locale.alternateLocales[index]) {
                    onPropertyChanged("locale", {
                      ...locale,
                      alternateLocales: [...(locale.alternateLocales || []), { locale: alternateLocale }],
                    });
                    return;
                  }
                  const alternateLocales = [...locale.alternateLocales];
                  alternateLocales[index].locale = alternateLocale;
                  onPropertyChanged("locale", { ...locale, alternateLocales });
                }}
                marginBottom={0}
              />

              <TextFieldMaxLength
                titleAndLengthLocation={"top"}
                disabled={removed}
                value={alternate.urlPrefix}
                placeholder={I18n.t("website.alternate_locale_prefix_ph")}
                title={I18n.t("website.alternate_locale_prefix", { count: index + 1 })}
                maxLength={15}
                height={37}
                borderRadius={4}
                handleValueChange={(prefix) => {
                  if (!locale.alternateLocales[index]) {
                    onPropertyChanged("locale", {
                      ...locale,
                      alternateLocales: [...(locale.alternateLocales || []), { urlPrefix: prefix }],
                    });
                    return;
                  }
                  const alternateLocales = [...locale.alternateLocales];
                  alternateLocales[index].urlPrefix = prefix;
                  onPropertyChanged("locale", { ...locale, alternateLocales });
                }}
                marginBottom={0}
              />

              <SelectRow
                flexDirection={"column"}
                bold
                rowTitle={I18n.t("website.show_in_sitemap")}
                value={alternate.inSitemap || alternate.inSitemap === null ? "Yes" : "No"}
                selectOptions={["Yes", "No"]}
                handleChange={(value) => {
                  if (!locale.alternateLocales[index]) {
                    onPropertyChanged("locale", {
                      ...locale,
                      alternateLocales: [...(locale.alternateLocales || []), { inSitemap: value === "Yes" }],
                    });
                    return;
                  }
                  const alternateLocales = [...locale.alternateLocales];
                  alternateLocales[index].inSitemap = value === "Yes";
                  onPropertyChanged("locale", { ...locale, alternateLocales });
                }}
              />
              <SelectRow
                flexDirection={"column"}
                bold
                rowTitle={I18n.t("website.currency")}
                value={
                  (alternate?.currency && `${alternate?.currency.code} - ${alternate?.currency.symbol}`) || `USD - $`
                }
                selectOptions={allCurrencies.map((currency) => `${currency.code} - ${currency.symbol}`)}
                handleChange={(currency) => {
                  if (!locale.alternateLocales[index]) {
                    onPropertyChanged("locale", {
                      ...locale,
                      alternateLocales: [
                        ...(locale.alternateLocales || []),
                        { currency: find(allCurrencies, { code: currency.split(" - ")[0] }) },
                      ],
                    });
                    return;
                  }
                  const alternateLocales = [...locale.alternateLocales];
                  alternateLocales[index].currency = find(allCurrencies, { code: currency.split(" - ")[0] });
                  onPropertyChanged("locale", { ...locale, alternateLocales });
                }}
              />
            </div>
            {/*  TODO: support in replacing to url to support different url structures in multiple locales - no one use it now so I removed it*/}
            {/*<AlternateReplacements*/}
            {/*  disabled={removed}*/}
            {/*  locale={alternate.locale}*/}
            {/*  alternateReplacements={alternate.replacements}*/}
            {/*  onAlternateReplacementsChanged={(replacements) => {*/}
            {/*    if (!locale.alternateLocales[index]) {*/}
            {/*      onPropertyChanged("locale", {*/}
            {/*        ...locale,*/}
            {/*        alternateLocales: [...(locale.alternateLocales || []), { replacements }],*/}
            {/*      });*/}
            {/*      return;*/}
            {/*    }*/}
            {/*    const alternateLocales = [...locale.alternateLocales];*/}
            {/*    alternateLocales[index].replacements = replacements;*/}
            {/*    onPropertyChanged("locale", { ...locale, alternateLocales });*/}
            {/*  }}*/}
            {/*/>*/}
          </div>
          {index !== 0 && (
            <CloseIcon
              className={ClassNames(classes.closeIcon, hasChildren(index) && classes.closeAlternateIcon)}
              onClick={() => {
                const alternateLocales = [...locale.alternateLocales];
                alternateLocales.splice(index, 1);
                onPropertyChanged("locale", { ...locale, alternateLocales });
              }}
            />
          )}
          {index == 0 && !removed && (
            <DeleteIcon
              className={classNames(classes.closeIcon, hasChildren(index) && classes.closeAlternateIcon)}
              onClick={() => {
                onDelete(true);
                onPropertyChanged("locale", { ...locale, alternateLocales: [] });
                return;
              }}
            />
          )}
        </div>
      ))}
    </PageSection>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetailsLocale);
