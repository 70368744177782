import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { I18n } from "react-redux-i18n";
import Link from "@material-ui/core/Link";
import { IAnalyticsEventRow } from "../../../../reducers/constants/objectTypes";
import EventCollapseItem from "./EventCollapseItem";
import { web } from "../../../../helpers/urlHelper";
import localeHelper from "../../../../helpers/localeHelper";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "0 20px",
    fontSize: theme.typography.pxToRem(12),
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      transition: "border-radius 500ms",
      marginTop: -1,
      paddingTop: 1,
    },
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    height: 53,
    flexDirection: "row",
    alignItems: "center",
    marginRight: 5,
    cursor: "pointer",
  },
  topicNameWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 20,
  },
  urlWrapper: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  topicName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular as any,
    cursor: "pointer",
    width: "fit-content",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  column: {
    flexGrow: 1,
    flexBasis: 0,
  },
  deviceTypeColumn: {
    textTransform: "lowercase",
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
  moreOptionsColumn: {
    width: 30,
  },
  distinctId: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  url: {
    fontSize: theme.typography.pxToRem(12),
    wordBreak: "break-word",
  },
}));

type PropTypes = {
  event: IAnalyticsEventRow;

  handleEventClick: () => void;
  handleUserClick: (distinctId: string) => void;
};

const EventItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { event, handleEventClick, handleUserClick } = props;
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <ListItem onClick={() => setCollapsed(!collapsed)} className={classes.itemWrapper}>
      <div className={classes.rowWrapper}>
        <div className={classes.column}>
          <div className={classes.topicNameWrapper}>
            <Tooltip enterDelay={1000} title={event.name} arrow>
              <div className={classes.urlWrapper}>
                <Typography className={classes.topicName} variant="subtitle2">
                  {I18n.t(`analytics.events.names.${event.name}`)}
                </Typography>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={classes.column}>{moment(event.created_at).fromNow()}</div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleUserClick(event.device_id);
          }}
          className={ClassNames(classes.column, classes.distinctId, classes.ellipsis)}
        >
          <Link target={"_blank"} href={web.croUserEvents(event.device_id)} onClick={(e) => e.stopPropagation()}>
            {event.device_id}
          </Link>
        </div>
        <div className={classes.column}>{event.city || "-"}</div>
        <div className={classes.column}>{localeHelper.getCountryFromCountryCode(event.country) || "-"}</div>
        <div className={ClassNames(classes.column, classes.deviceTypeColumn)}>{event.device_type}</div>
        <div className={ClassNames(classes.column, classes.url, classes.ellipsis)}>
          <Tooltip enterDelay={1000} title={event.first_page}>
            <Link
              className={classes.url}
              href={event.first_page}
              onClick={(e) => e.stopPropagation()}
              target={"_blank"}
              variant="subtitle2"
            >
              {event.first_page}
            </Link>
          </Tooltip>
        </div>
        <div className={classes.moreOptionsColumn}></div>
      </div>
      <EventCollapseItem event={event} collapsed={collapsed} />
    </ListItem>
  );
};

export default EventItem;
