import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import * as React from "react";
import { useMemo } from "react";
import { IFunnelBreakdownColumn, IWebsite } from "../../../../reducers/constants/objectTypes";
import moment from "moment/moment";
import MTablePagination from "../../../../components/Table/Pagination/MTablePagination";
import { options as timePeriodOptions } from "../../../../components/Table/Toolbar/Filters/MTableToolbarTimePeriodFilter";
import { options as timeFrameOptions } from "../../../../components/Table/Toolbar/Filters/MTableToolbarTimeFrameFilter";
import GoalCompletionByPageReportHeader from "./components/GoalCompletionByPageReportHeader";
import agent from "../../../../agent";
import CfProgressBar from "../../../../components/CfProgressBar";
import GoalCompletionByPageTable from "./components/GoalCompletionByPageTable";
import { connect } from "react-redux";
import { GOAL_COMPLETION_REPORTS } from "../../../../reducers/constants/actionTypes";
import { web } from "../../../../helpers/urlHelper";
import { matchPath, useLocation } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
  innerWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 65px)",
    width: "100%",
    marginBottom: 20,
  },
  reportsDashboardWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    margin: 20,
    position: "relative",
    height: "100%",
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableWrapper: {
    margin: 0,
    overflowX: "auto",
    height: "calc(100vh - 185px)",
    position: "relative",
  },
  pagination: {
    display: "flex",
    zIndex: 100,
    backgroundColor: theme.palette.common.white,
    width: "calc(100vw - 40px)",
    padding: "0px 20px",
    position: "fixed",
    bottom: 0,
    margin: 0,
  },
}));

type Props = {
  selectedWebsite: IWebsite;
  reportType: string;

  loading: boolean;
  data: any[];
  totalItems: number;
  totalPages: number;
  page: number;
  pageSize: number;
  sortField?: { direction: "desc" | "asc" | undefined; field: string };
  timePeriod: any;
  timeFrame: any;
  countFilter: any;
  totalsRow: any;
  breakdownFilters: {
    filter: IFunnelBreakdownColumn;
    value: any;
  }[];

  propChanged: (key: any, value: any) => void;
  goalCompletionLoaded: (payload: any) => void;
  setReport: (reportType: string) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  loading: state.goalCompletion.goalCompletionByPage.loading,
  data: state.goalCompletion.goalCompletionByPage.data,
  page: state.goalCompletion.goalCompletionByPage.page,
  pageSize: state.goalCompletion.goalCompletionByPage.pageSize,
  sortField: state.goalCompletion.goalCompletionByPage.sortField,
  timePeriod: state.goalCompletion.goalCompletionByPage.timePeriod,
  timeFrame: state.goalCompletion.goalCompletionByPage.timeFrame,
  countFilter: state.goalCompletion.goalCompletionByPage.countFilter,
  totalItems: state.goalCompletion.goalCompletionByPage.totalItems,
  totalPages: state.goalCompletion.goalCompletionByPage.totalPages,
  totalsRow: state.goalCompletion.goalCompletionByPage.totalsRow,
  breakdownFilters: state.goalCompletion.goalCompletionByPage.breakdownFilters,
});

const mapDispatchToProps = (dispatch) => ({
  propChanged: (key: any, value: any) =>
    dispatch({
      type: GOAL_COMPLETION_REPORTS.GOAL_COMPLETION_BY_PAGE_CHANGED,
      key,
      value,
    }),
  goalCompletionLoaded: (payload: any) =>
    dispatch({
      type: GOAL_COMPLETION_REPORTS.GOAL_COMPLETION_BY_PAGE_LOADED,
      payload,
    }),
});

const GoalCompletionByPageReport = (props: Props) => {
  const classes = useStyles();
  const {
    selectedWebsite,
    reportType,
    loading,
    countFilter,
    pageSize,
    page,
    sortField,
    timePeriod,
    timeFrame,
    data,
    totalPages,
    totalItems,
    propChanged,
    goalCompletionLoaded,
    setReport,
    totalsRow,
    breakdownFilters,
  } = props;

  const location = useLocation();
  const match = matchPath(location.pathname, {
    path: web.croGoalCompletion(":reportType"),
    exact: false,
  });

  const momentTimeFrame = useMemo(() => {
    switch (timeFrame?.value || timeFrameOptions[2].value) {
      case "day":
        return "day";
      case "week":
        return "isoWeek";
      case "month":
      default:
        return "month";
    }
  }, [timeFrame]);

  const startDate = React.useMemo(() => {
    return moment()
      .subtract(timePeriod?.value || timePeriodOptions[2].value, "month")
      .toDate();
  }, [timePeriod]);

  React.useEffect(() => {
    propChanged("loading", true);
    console.log("breakdownFilters", breakdownFilters);
    const urlFilter = breakdownFilters.find((bf) => bf.filter.label === "Page Url");
    agent.GoalCompletionReports.getGoalCompletionsByPage(
      selectedWebsite,
      moment(startDate).format("YYYY-MM-DD"),
      timeFrame?.value || timeFrameOptions[2].value,
      urlFilter?.filter?.value?.table || null,
      urlFilter?.filter?.value?.field || null,
      urlFilter?.value?.value || null,
      page,
      pageSize,
      countFilter?.value,
      sortField?.field,
      sortField?.direction
    )
      .then((res) => {
        console.log("res", res);
        goalCompletionLoaded({
          results: res.results,
          total: res.total,
          totals: res.totals,
          totalPages: res.totalPages,
          metadata: res.metadata,
          timeFrame: res.timeFrame,
          totalQueryResults: res.totalQueryResults,
        });
      })
      .catch((e) => {})
      .finally(() => {
        propChanged("loading", false);
      });
  }, [selectedWebsite, startDate, sortField, timeFrame, page, pageSize, countFilter, breakdownFilters]);

  return (
    <div className={classes.innerWrapper}>
      <div className={classes.reportsDashboardWrapper}>
        <GoalCompletionByPageReportHeader
          selectedWebsite={selectedWebsite}
          selectedReport={reportType}
          setSelectedReport={(reportType) => setReport(reportType)}
          breakdownFilters={breakdownFilters}
          handleBreakdownFiltersChange={(breakdownFilters) => propChanged("breakdownFilters", breakdownFilters)}
          selectedTimePeriod={timePeriod || timePeriodOptions[2]}
          setSelectedTimePeriod={(timePeriod) => propChanged("timePeriod", timePeriod)}
          selectedTimeFrame={timeFrame || timeFrameOptions[2]}
          setSelectedTimeFrame={(timeFrame) => propChanged("timeFrame", timeFrame)}
        />
        <div className={classes.tableWrapper}>
          {loading ? (
            <div className={classes.loader}>
              <CfProgressBar />
            </div>
          ) : (
            <GoalCompletionByPageTable
              website={selectedWebsite}
              results={data}
              startDate={startDate}
              momentTimeFrame={momentTimeFrame}
              timeFrame={timeFrame || timeFrameOptions[2]}
              totalItems={totalItems}
              sortField={sortField}
              setSortField={(sortField) => propChanged("sortField", sortField)}
              totalsRow={totalsRow}
              breakdownFilters={breakdownFilters}
            />
          )}
          {totalPages > 0 && (
            <MTablePagination
              className={classes.pagination}
              paginationLimit={pageSize}
              page={page}
              paginationPagesCount={totalPages}
              setPaginationLimit={(pageSize) => propChanged("pageSize", pageSize)}
              setPage={(page) => propChanged("page", page)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalCompletionByPageReport);
