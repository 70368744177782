import { Button, TextField, Theme, Typography } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useRef } from "react";
import classNames from "classnames";

import { colors } from "../../../../../../../../../helpers/constants";

const useStyles = makeStyles(({ palette, typography }: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 8,
    margin: ({ margin }: PropTypes) => margin || 0,
  },
  inlineRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 15,
    margin: ({ margin }: PropTypes) => margin || 0,
  },
  textFieldContainer: {
    width: "100%",
    display: "flex",
  },
  textFieldWrapper: {
    "& .MuiTextField-root": {
      width: "100%",
    },
    borderRadius: ({ borderRadius }: PropTypes) => borderRadius || 0,
    height: (props: PropTypes) => (props.labelOutsideInput ? 50 : "auto"),
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    zIndex: 1,
    padding: 15,
    fontSize: 14,
    backgroundColor: palette.secondary.dark,
  },
  input: {
    fontSize: 14,
    fontWeight: typography.fontWeightRegular as any,
    padding: 0,
    textAlign: ({ numbersOnly }: PropTypes) => (numbersOnly ? "center" : "left"),
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  textFieldRoot: {
    backgroundColor: "inherit",
  },
  textFieldLabel: {
    marginBottom: 3,
    "&$textFieldFocused": {
      color: palette.text.primary,
    },
  },
  textFieldFocused: {},
  label: {
    color: palette.text.primary,
    fontSize: typography.pxToRem(14),
    lineHeight: "normal",
    opacity: (props: PropTypes) => (props.disabled ? 0.5 : 1),
    minWidth: (props: PropTypes) => props.labelMinWidth || "fit-content",
  },
  iconWrapper: {
    padding: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.widgetOnHoverBackgroundColor,
    cursor: "pointer",
    transition: "background-color 0.2s",
    borderRadius: "3px 0 0 3px",
    "&:hover": {
      backgroundColor: colors.widgetOnHoverBackgroundColor,
    },
    "&:last-child": {
      borderRadius: "0 3px 3px 0",
    },
  },
  icon: {
    color: palette.primary.main,
    width: 20,
    height: 20,
  },
}));

type PropTypes = {
  value?: string;
  disabled?: boolean;
  label?: string;
  labelMinWidth?: number | string;
  labelOutsideInput?: boolean;
  inlineLabel?: boolean;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  className?: string;
  numbersOnly?: boolean;
  noDecimals?: boolean;
  icon?: JSX.Element;
  margin?: number | string;
  borderRadius?: number | string;
  classes?: {
    textFieldWrapper?: string;
  };
  multiLine?: boolean;
  onChange?(text: string): unknown;
};

export type TextFieldPropertyTypes = PropTypes;

const TextFieldProperty = (props: PropTypes) => {
  const {
    value,
    disabled,
    label = null,
    labelOutsideInput,
    inlineLabel,
    placeholder = null,
    numbersOnly = false,
    noDecimals = false,
    className,
    classes: classesProp = {},
    min = 0,
    max,
    step = 1,
    icon,
    multiLine,
    onChange,
  } = props;
  const classes = useStyles(props);

  const timer = useRef(null);
  const refValue = useRef(value);

  const timeoutClear = () => {
    clearInterval(timer.current);
  };

  const handleChange = (event) => {
    if (event.target.value === "") {
      onChange(null);
      refValue.current = null;
      return;
    }

    let inputValue = numbersOnly ? parseFloat(event.target.value) : event.target.value;

    if (typeof min === "number" && inputValue < min) {
      inputValue = min;
    }

    if (typeof max === "number" && inputValue > max) {
      inputValue = max;
    }

    const decimalCount = noDecimals ? 0 : step.toString().split(".")[1]?.length || 1;

    // eslint-disable-next-line no-nested-ternary
    const formattedValue = numbersOnly
      ? noDecimals
        ? parseInt(inputValue, 10).toString()
        : parseFloat(inputValue.toFixed(decimalCount)).toString()
      : inputValue;

    refValue.current = formattedValue;
    onChange(formattedValue);
  };

  const handleIncrementDecrement = (increment: number) => {
    const parsedValue = refValue.current ? parseFloat(refValue.current) : 0;
    let newValue = parsedValue + increment;

    if (typeof min === "number") {
      newValue = Math.max(newValue, min);
    }

    if (typeof max === "number") {
      newValue = Math.min(newValue, max);
    }

    if (!noDecimals) {
      const decimalCount = noDecimals ? 0 : step.toString().split(".")[1]?.length || 1;
      newValue = parseFloat(newValue.toFixed(decimalCount));
    }

    onChange(newValue.toString());
    refValue.current = newValue.toString();
  };

  const onLongPress = (increment: number) => {
    timer.current = setInterval(() => handleIncrementDecrement(increment), 500);
  };

  return (
    <div className={classNames(classes.root, inlineLabel && classes.inlineRoot)}>
      {(labelOutsideInput || numbersOnly) && label && (
        <Typography className={classes.label} variant={"subtitle2"}>
          {label || ""}
        </Typography>
      )}
      <div className={classes.textFieldContainer}>
        {numbersOnly && (
          <Button
            className={classes.iconWrapper}
            onMouseLeave={timeoutClear}
            onMouseUp={timeoutClear}
            onMouseDown={() => onLongPress(step * -1)}
            onClick={() => handleIncrementDecrement(step * -1)}
          >
            <Remove className={classes.icon} />
          </Button>
        )}
        <div className={classNames(classesProp.textFieldWrapper, classes.textFieldWrapper)}>
          <TextField
            multiline={multiLine}
            value={value ?? ""}
            label={!(labelOutsideInput || numbersOnly) && label}
            disabled={disabled}
            placeholder={placeholder}
            className={className}
            fullWidth
            margin="none"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.textFieldLabel,
                focused: classes.textFieldFocused,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.textFieldRoot,
                input: classes.input,
              },
              inputProps: {
                min: numbersOnly ? min : undefined,
                max: numbersOnly ? max : undefined,
                step: numbersOnly ? step : undefined,
              },
              endAdornment: icon || undefined,
            }}
            type={numbersOnly ? "number" : "text"}
          />
        </div>
        {numbersOnly && (
          <Button
            className={classes.iconWrapper}
            onMouseLeave={timeoutClear}
            onMouseUp={timeoutClear}
            onMouseDown={() => onLongPress(step)}
            onClick={() => handleIncrementDecrement(step)}
          >
            <Add className={classes.icon} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default TextFieldProperty;
