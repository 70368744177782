import classNames from "classnames";
import React from "react";
import { IconButton, ListSubheader, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Search from "@material-ui/icons/Search";
import { getOpportunityPageUrl, getPageSearchConsoleUrl } from "../../../../../helpers/urlHelper";
import CopyToClipboardIcon from "../../../../../icons/CopyToClipboardIcon";
import ArrowLeftIcon from "../../../../../icons/ArrowLeftIcon";
import XIcon from "../../../../../icons/XIcon";

const useStyles = makeStyles((theme: Theme) => ({
  dialogListSubHeader: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    backgroundColor: theme.palette.background.default,
    position: "sticky",
    top: 0,
  },
  actionsWrapper: {
    display: "none",
    alignItems: "center",
    justifyContent: "flex-end",
    columnGap: "13px",
  },
  dialogTitle: {
    fontSize: "20px",
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    "&:hover": {
      transition: "border-radius 500ms",
      "& $actionsWrapper": {
        display: "flex",
      },
    },
  },
  dialogTabs: {
    display: "flex",
    // marginBottom: -2,
    width: "60%",
    flexDirection: "row",
  },
  tab: {
    padding: "2px 40px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: 55,
    borderBottom: `2px solid #c4c4c4`,
  },
  activeTab: {
    color: theme.palette.text.primary,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    transition: "border .3s",
  },
  closeButton: {
    padding: 0,
    marginRight: 0,
    alignSelf: "flex-start",
  },
  headerIcon: {
    width: 20,
    height: 20,
  },
  countText: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    marginLeft: "10px",
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconButton: {
    padding: 0,
  },
  backIcon: {
    width: "20px",
    height: "18px",
    fill: theme.palette.common.black,
    marginRight: "10px",
  },
  actionIcon: {
    height: "18px",
    width: "18px",
    fill: theme.palette.text.secondary,
  },
  titleFirstPartWrapper: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
  iconBtn: {
    padding: 0,
  },
}));

const mapStateToProps = (state) => ({
  broadKeywords: state.outlinePages.broadKeywords,
  keywords: state.outlinePages.keywords,
});

type PropTypes = {
  close: () => void;
  title: string;
  dialogScreen: string;
  goBack: () => void;
  page: any;
};

const InternalLinkingDialogHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { close, title, goBack, dialogScreen, page } = props;

  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return () => {
      if (title) {
        navigator.clipboard.writeText(title);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();

  return (
    <ListSubheader className={classes.dialogListSubHeader}>
      <div className={classes.titleWrapper}>
        <div className={classes.titleFirstPartWrapper}>
          {dialogScreen === "relatedPage" && (
            <Tooltip arrow title={I18n.t("outline.icons.go_back")} className={classes.actionIconWrapper}>
              <div>
                <IconButton className={classes.iconButton} onClick={goBack}>
                  <ArrowLeftIcon className={classNames(classes.backIcon)} />
                </IconButton>
              </div>
            </Tooltip>
          )}
          <Typography className={classes.dialogTitle}>{getOpportunityPageUrl(title)}</Typography>
          <div className={classNames(classes.actionsWrapper)}>
            <Tooltip
              arrow
              title={showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_page_url")}
              className={classes.actionIconWrapper}
            >
              <div>
                <IconButton className={classes.iconButton}>
                  <CopyToClipboardIcon
                    className={classNames(classes.actionIcon)}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTitleCopy();
                    }}
                  />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip
              arrow
              title={I18n.t(`learning_center_content.tooltip.open_in_new_tab`)}
              className={classes.actionIconWrapper}
            >
              <IconButton
                disableRipple
                className={classes.iconButton}
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(title, "_blank");
                }}
              >
                <OpenInNewIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip
              enterDelay={500}
              title={I18n.t(`learning_center_content.tooltip.google_search_console`)}
              className={classes.actionIconWrapper}
            >
              <IconButton
                disableRipple
                className={classes.iconBtn}
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(getPageSearchConsoleUrl(page.domain, page.page));
                }}
              >
                <Search className={classNames(classes.actionIcon)} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <IconButton className={classes.closeButton} onClick={close}>
          <XIcon className={classes.headerIcon} />
        </IconButton>
      </div>
    </ListSubheader>
  );
};

export default connect(mapStateToProps, {})(InternalLinkingDialogHeader);
