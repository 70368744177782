import {Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import {Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  sectionWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    marginBottom: 40,
    padding: 30,
    gap: 10,
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
    "&:first-child": {
      marginTop: 40,
    }
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: 1.2,
    marginBottom: 0
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
  }
}));

type PropTypes = {
  title?: string;
  subtitle?: string;
  children: React.ReactElement | React.ReactElement[];
};

const pageSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const {title, subtitle, children} = props;

  return (
    <Paper elevation={0} className={classes.sectionWrapper}>
      {title &&
        <Typography className={classes.title} color={"textPrimary"} variant={"h2"}>
          {title}
        </Typography>
      }
      {subtitle &&
        <Typography className={classes.subtitle} color={"textPrimary"} variant={"body1"}>
          {subtitle}
        </Typography>
      }
      <div>{children}</div>
    </Paper>
  );
};

export default pageSection;
