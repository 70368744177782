import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: "#36CD3C",
    width: 13,
    height: 10,
  },
}));

type PropTypes = {
  className?: string;
};

const CheckIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 13 10"}>
      <path
        d="M5.17292 9.17684C4.70657 9.63407 3.9601 9.63407 3.49374 9.17684L0.781602 6.51777C0.352456 6.09702 0.352457 5.4059 0.781603 4.98515C1.19892 4.576 1.86691 4.576 2.28423 4.98515L4.33333 6.99416L10.7158 0.736613C11.1331 0.32746 11.8011 0.327459 12.2184 0.736612C12.6475 1.15736 12.6475 1.84848 12.2184 2.26923L5.17292 9.17684Z"
      />
    </SvgIcon>
  );
};

export default CheckIcon;
