import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import set from "lodash/set";
import { Divider } from "@material-ui/core";
import TextInputMentions from "../../../../components/TextInputMentions";
import { ePageType, IWebsite, IWebsiteBlogProps } from "../../../../../../reducers/constants/objectTypes";
import PageSection from "../../../../components/PageSection";
import { LENGTHS } from "../../../../../../reducers/constants/consts";
import TextInputToolAndDesign from "../../../../components/TextInputToolAndDesign";
import BlogMultipleCategorySelector from "../../commonComponents/BlogMultipleCategorySelector";
import PostSearchInput from "../../ResourcePage/components/PostSearchInput";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

type PropTypes = {
  blog: IWebsiteBlogProps;
  website: IWebsite;
  onPropertyChanged: (blog) => void;
  locale: string;
  defaultLocale: string;
  emptyFields: string[];
};

const MayplePageBlog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { blog, onPropertyChanged, emptyFields, locale, defaultLocale, website } = props;
  const { maypleTemplate } = blog;

  return (
    <>
      <PageSection sectionTitle={I18n.t("website.blog.main_page")}>
        <TextInputMentions
          key={`blog_main_page_meta_title_${locale}`}
          tags={[]}
          error={emptyFields.includes(I18n.t(`website.meta_title`))}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? maypleTemplate.mainPage.metaTitle
              : blog.translations[locale]?.maypleTemplate.mainPage?.metaTitle || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...blog, maypleTemplate: { ...blog.maypleTemplate, "mainPage.metaTitle": value } });
              return;
            }
            blog.translations[locale] = {
              ...(blog.translations[locale] || {}),
              maypleTemplate: { ...(blog.translations[locale]?.maypleTemplate || {}), "mainPage.metaTitle": value },
            };
            onPropertyChanged({ ...blog });
          }}
        />
        <TextInputMentions
          key={`blog_main_page_meta_desc_${locale}`}
          error={emptyFields.includes(I18n.t(`website.meta_description`))}
          tags={[]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={
            locale === defaultLocale
              ? maypleTemplate.mainPage.metaDescription
              : blog.translations[locale]?.maypleTemplate?.mainPage?.metaDescription || ""
          }
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...blog,
                maypleTemplate: { ...blog.maypleTemplate, "mainPage.metaDescription": value },
              });
              return;
            }
            blog.translations[locale] = {
              ...(blog.translations[locale] || {}),
              maypleTemplate: {
                ...(blog.translations[locale]?.maypleTemplate || {}),
                "mainPage.metaDescription": value,
              },
            };
            onPropertyChanged({ ...blog });
          }}
        />
        <PostSearchInput
          pageType={ePageType.Blog}
          placeholder={I18n.t("website.blog.mayple_template.featured_post_ph")}
          title={I18n.t("website.blog.mayple_template.featured_post")}
          selectedWebsite={website}
          maxPages={1}
          marginBottom={30}
          pages={maypleTemplate.featuredPost?.slug ? [maypleTemplate.featuredPost] : []}
          handlePagesChange={(page) => {
            onPropertyChanged({
              ...blog,
              maypleTemplate: { ...maypleTemplate, featuredPost: page },
            });
          }}
        />
        <Divider className={classes.divider} />
        <TextInputToolAndDesign
          error={emptyFields.includes(I18n.t(`website.blog.mayple_template.top_articles_title`))}
          textFieldTitle={I18n.t("website.blog.mayple_template.top_articles_title")}
          text={
            locale === defaultLocale
              ? maypleTemplate.topArticles?.title
              : {
                  text: blog.translations[locale]?.maypleTemplate?.topArticles?.title || "",
                  css: maypleTemplate.topArticles?.title?.css,
                }
          }
          handleTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({
                ...blog,
                maypleTemplate: { ...maypleTemplate, topArticles: { ...maypleTemplate.topArticles, title: value } },
              });
              return;
            }
            set(blog.translations[locale], "maypleTemplate.topArticles.title", value.text);
            onPropertyChanged({ ...blog });
          }}
          variant={"h1"}
        />
        <PostSearchInput
          placeholder={I18n.t("website.blog.mayple_template.top_articles_ph", {
            count: maypleTemplate.topArticles?.posts?.length,
          })}
          title={I18n.t("website.resourcePage.top_section.top_articles")}
          selectedWebsite={website}
          pageType={ePageType.Blog}
          marginBottom={30}
          pages={maypleTemplate.topArticles?.posts || []}
          handlePagesChange={(posts) => {
            onPropertyChanged({
              ...blog,
              maypleTemplate: {
                ...maypleTemplate,
                topArticles: { ...maypleTemplate.topArticles, posts },
                categories: [],
              },
            });
          }}
        />
        <Divider className={classes.divider} />
        <BlogMultipleCategorySelector
          rowTitle={I18n.t("website.blog.mayple_template.select_categories")}
          handleCategoriesChange={(categories) => {
            onPropertyChanged({
              ...blog,
              maypleTemplate: { ...maypleTemplate, categories },
            });
          }}
          selectedCategories={maypleTemplate.categories || []}
        />
      </PageSection>
    </>
  );
};

export default MayplePageBlog;
