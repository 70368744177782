import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import * as React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import numeral from "numeral";
import { I18n } from "react-redux-i18n";
import Tooltip from "@material-ui/core/Tooltip";
import agent from "../../../../../agent";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "20px 20px 20px 20px",
    background: theme.palette.background.default,
    height: "auto",
    width: "100%",
    padding: "40px 30px 40px 18px",
  },
  dataWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  dataHeading: {
    marginRight: "10px",
  },
  dataText: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: "17px",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

type PropTypes = {
  selectedWebsite: IWebsite;
};
const KeywordSERPStats = (props: PropTypes) => {
  const { selectedWebsite } = props;
  const classes = useStyles();

  const [keywordSerpStats, setKeywordSerpStats] = React.useState({
    serpCount: 0,
    keywordCount: 0,
  });

  useEffect(() => {
    agent.KeywordGroups.getKeywordSerpStats(selectedWebsite._id).then((data) => {
      setKeywordSerpStats(data);
    });
  }, [selectedWebsite]);

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.dataWrapper}>
        <Tooltip title={I18n.t("strategy.settings.keywords_describe")} placement={"bottom-start"}>
          <Typography className={classes.dataText}>
            <span className={classes.dataHeading}>{I18n.t("strategy.settings.keywords_count")}</span>{" "}
            {numeral(keywordSerpStats.keywordCount).format("0,0a")}{" "}
          </Typography>
        </Tooltip>

        <Tooltip title={I18n.t("strategy.settings.serp_describe")} placement={"bottom-start"}>
          <Typography className={classes.dataText}>
            <span className={classes.dataHeading}>{I18n.t("strategy.settings.serps_count")}</span>{" "}
            {numeral(keywordSerpStats.serpCount).format("0,0a")}{" "}
          </Typography>
        </Tooltip>
        <Typography className={classes.dataText}>
          <span className={classes.dataHeading}>{I18n.t("strategy.settings.kw_serp_ratio")}</span>
          {keywordSerpStats.serpCount && keywordSerpStats.keywordCount
            ? numeral(keywordSerpStats.serpCount / keywordSerpStats.keywordCount).format("0.0%")
            : "0%"}
        </Typography>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(KeywordSERPStats);
