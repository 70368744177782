import { ListItem, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import numeral from "numeral";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Search from "@material-ui/icons/Search";
import { I18n } from "react-redux-i18n";
import { getQuerySearchConsoleUrl } from "../../../../../../helpers/urlHelper";
import CopyToClipboardIcon from "../../../../../../icons/CopyToClipboardIcon";

const useStyles = makeStyles((theme: Theme) => ({
  metric: {
    fontSize: "14px",
    padding: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    height: "100%",
    width: "10%",
  },
  actionsWrapper: {
    gap: 20,
    alignItems: "center",
    display: "none",
    position: "absolute",
    left: "40%",
    zIndex: 2,
    width: "fit-content",
    backgroundColor: theme.palette.secondary.main,
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionIcon: {
    height: 18,
    fill: theme.palette.text.secondary,
  },
  iconBtn: {
    padding: 0,
  },
  clicksText: {
    color: theme.palette.primary.main,
  },
  impText: {
    color: `#712B9C`,
  },
  ctrText: {
    color: `#33776B`,
  },
  posText: {
    color: `#C8722D`,
  },
  listRow: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 30,
      transition: "border-radius 500ms",
      "& $actionsWrapper": {
        display: "flex",
      },
      position: "relative",
    },
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "20px 30px",
    justifyContent: "space-between",
  },
  listItemFirstColumn: {
    width: "55%",
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  keyword: any;
};

const KeywordDialogListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { keyword } = props;

  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return () => {
      if (keyword?.keyword) {
        navigator.clipboard.writeText(keyword.keyword);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();

  return (
    <ListItem className={classNames(classes.listRow)}>
      <div className={classes.listItemFirstColumn}>
        <span
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "pre",
          }}
        >
          {keyword.keyword}
        </span>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(keyword.clicks || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(keyword.impressions || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(keyword.volume || 0).format("0,0a")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(keyword.ctr || 0).format("0.0%")}</Typography>
      </div>
      <div className={classes.metric}>
        <Typography>{numeral(keyword.position || 0).format("0.0")}</Typography>
      </div>
      <div className={classNames(classes.actionsWrapper)}>
        <Tooltip
          arrow
          title={showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_keyword")}
          className={classes.actionIconWrapper}
        >
          <div>
            <CopyToClipboardIcon
              className={classNames(classes.actionIcon)}
              onClick={(e) => {
                e.stopPropagation();
                handleTitleCopy();
              }}
            />
          </div>
        </Tooltip>
        <Tooltip
          enterDelay={500}
          title={I18n.t(`learning_center_content.tooltip.google_search_console`)}
          className={classes.actionIconWrapper}
        >
          <IconButton
            disableRipple
            className={classes.iconBtn}
            onClick={(event) => {
              event.stopPropagation();
              window.open(getQuerySearchConsoleUrl(keyword.domain, keyword.keyword));
            }}
          >
            <Search className={classNames(classes.actionIcon)} />
          </IconButton>
        </Tooltip>
      </div>
    </ListItem>
  );
};

export default KeywordDialogListItem;
