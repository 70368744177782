import find from "lodash/find";
import { HOME } from "./constants/actionTypes";
import { setWebsiteName } from "../helpers/urlHelper";

const initialState = {
  websitesLoaded: false,
  websites: [],
  selectedWebsite: null,
  popularTags: [],
  sideQuestions: [],
  categories: [],
  websiteListSelected: null,
  organizations: [],
  selectedOrganization: null,
  adminLoaded: false,
  admin: null,

  subLogin: false,

  refreshWebsites: false,
  onBoardingData: {},

  showInstructionsDialog: false,
  siteDialog: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HOME.WEBSITES_LOADED: {
      const { websites, match } = action;
      // const lastWebsiteName = window.localStorage.getItem('lastWebsiteName') || 'KeepShoppers';
      const websiteName =
        match?.params?.websiteName || window.localStorage.getItem("lastWebsiteName") || "KeepShoppers";

      const website = find(websites, (w) => w.name === websiteName || find(w.children, { name: websiteName }));
      const childWebsite = website ? find(website.children, { name: websiteName }) : null;
      const selectedWebsite = childWebsite || website || websites[0] || null;

      setWebsiteName(selectedWebsite?.name);
      return {
        ...state,
        websitesLoaded: true,
        websites,
        selectedWebsite,
      };
    }
    case HOME.ON_WEBSITE_UPDATED: {
      setWebsiteName(action.website?.name);
      return {
        ...state,
        websites: state.websites.map((website) => {
          if (website._id === action.website._id) {
            return action.website;
          }
          return {
            ...website,
            children: website.children
              ? website.children.map((child) => {
                  if (child._id === action.website._id) {
                    return action.website;
                  }
                  return child;
                })
              : [],
          };
        }),
        selectedWebsite: action.website,
      };
    }
    case HOME.ON_WEBSITES_SELECTED: {
      window.localStorage.setItem("lastWebsiteName", action.selectedWebsite.name);
      setWebsiteName(action.selectedWebsite.name);
      return {
        ...state,
        selectedWebsite: action.selectedWebsite,
      };
    }
    case HOME.WEBSITE_CREATED: {
      window.localStorage.setItem("lastWebsiteName", action.website.name);
      // setWebsiteName(action.selectedWebsite.name);
      document.location.reload();
      return state;
      // {
      //   ...state,
      //   selectedWebsite: action.website,
      //   website: [...state.websites, action.website]
      // };
    }
    case HOME.ADMIN_USER_LOADED:
      return {
        ...state,
        adminLoaded: true,
        admin: action.user,
      };

    case HOME.ADMIN_USER_LOADED_FAILED: {
      return {
        ...state,
        adminLoaded: false,
        admin: null,
      };
    }

    case HOME.SUB_ADMIN_USER_LOADED:
      return {
        ...state,
        adminLoaded: true,
        subLogin: true,
        refreshWebsites: !state.refreshWebsites,
      };

    case HOME.SUB_ADMIN_USER_LOAD_FAILED:
      return {
        ...state,
        subLogin: false,
      };

    case HOME.SUB_ADMIN_USER_REMOVE:
      return {
        ...state,
        adminLoaded: true,
        subLogin: false,
        refreshWebsites: !state.refreshWebsites,
      };

    case HOME.POPULAR_TAGS_LOADED:
      return {
        ...state,
        popularTags: action.payload.tags,
      };
    case HOME.SIDE_QUESTIONS_LOADED:
      return {
        ...state,
        sideQuestions: action.payload.questions,
      };
    case HOME.CATEGORIES_LOADED:
      return {
        ...state,
        categories: action.payload.categories,
      };
    case HOME.ORGANIZATIONS_LOADED:
      return {
        ...state,
        organizations: action.organizations,
      };
    case HOME.ON_ORGANIZATION_SELECTED:
      return {
        ...state,
        selectedOrganization: action.organization,
      };
    case HOME.ONBOARDING_DATA_UPDATED:
      return {
        ...state,
        onBoardingData: {
          ...state.onBoardingData,
          ...action.data,
        },
      };
    case HOME.ONBOARDING_COMPLETED:
      return {
        ...state,
        onBoardingData: {},
      };
    case HOME.ON_HOME_PROPERTY_CHANGED: {
      return {
        ...state,
        ...action.property,
      };
    }
    default:
      return state;
  }
};
