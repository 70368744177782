import React from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { filter } from "lodash";
import {
  IWebsite,
  IWidgetFieldProperties,
  IWidgetImagesFieldProps,
  IWidgetImageProps,
} from "../../../../../../../reducers/constants/objectTypes";
import listHelper from "../../../../../../../helpers/listHelper";
import GenericMediaItem from "./components/GenericMediaItem";

type PropTypes = {
  properties: IWidgetFieldProperties;
  selectedWebsite: IWebsite;
  onChange: (properties: IWidgetImagesFieldProps) => void;
};

const ImagesWidgetItem = (props: PropTypes) => {
  const { selectedWebsite } = props;
  const properties = props.properties as IWidgetImagesFieldProps;
  const { medias = [] } = properties;

  const { onChange } = props;

  const handleChange = ({ value, index }) => {
    if (!medias[index]) return onChange({ ...properties, medias: [...medias, value] });

    return onChange({ ...properties, medias: medias.map((media, arrIndex) => (arrIndex === index ? value : media)) });
  };

  const handleDelete = (index: number) => {
    onChange({ ...properties, medias: filter(medias, (val, mediaIndex) => mediaIndex !== index) });
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const newItems = listHelper.reorder(medias, source.index, destination.index);
    onChange({ ...properties, medias: newItems });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {[...medias, null].map((media, index) => (
              <Draggable key={index} draggableId={`property-${index}`} index={index} isDragDisabled={media === null}>
                {(draggableProvided, snapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    <GenericMediaItem
                      selectedWebsite={selectedWebsite}
                      type="image"
                      media={media as IWidgetImageProps}
                      itemNumber={index + 1}
                      isDragging={snapshot.isDragging}
                      handleChange={(value) => handleChange({ value, index })}
                      handleDelete={() => handleDelete(index)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ImagesWidgetItem;
