import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) => ({
  text: { fontSize: theme.typography.pxToRem(14), textAlign: "center" },
  divider: { height: 30, color: theme.palette.divider, marginLeft: 20, marginRight: 20 },
}));

type PropTypes = {
  text: string;
  divider?: boolean;
};

const MTableToolbarActionDisplayText = (props: PropTypes) => {
  const classes = useStyles(props);
  const { text, divider } = props;

  return (
    <>
      <Typography className={classes.text}>
        <span>{text}</span>
      </Typography>
      {divider && <Divider orientation={"vertical"} className={classes.divider} />}
    </>
  );
};

export default MTableToolbarActionDisplayText;
