import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClassNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { find } from "lodash";
import ImagePlaceHolder from "../../../../icons/ImagePlaceHolder";
import { Widget } from "../WithWidgets";
import { IProduct, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import TextAreaWidgetBaseElement from "../baseElements/TextAreaWidgetBaseElement";

const useStyles = makeStyles((theme: Theme) => ({
  featureComparisons: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid transparent",
    borderRadius: 3,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  prosAndCons: {
    display: "flex",
    flexDirection: "row",
    border: "1px solid transparent",
    borderRadius: 3,
    padding: 5,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  tableTopRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 0px 15px 0px",
  },
  tableTitleColumn: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingRight: 100,
  },
  tableHeader: (props: PropTypes) => ({
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: 1.25,
    marginBottom: 15,
    ...props.element.data?.headerCss,
  }),
  tableTitle: (props: PropTypes) => ({
    fontSize: 22,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.3,
    marginBottom: 10,
    ...props.element.data?.titleCss,
  }),
  tableDescription: (props: PropTypes) => ({
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: 1.3,
    marginBottom: 10,
    ...props.element.data?.descriptionCss,
  }),
  columnTitleWrapper: (props: PropTypes) => ({
    display: "flex",
    flexDirection: "row",
    padding: 20,
    justifyContent: "space-between",
    alignItems: "center",
    ...props.element.data?.oddCss,
  }),
  columnTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: 1.3,
  },
  productsWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    alignItems: "end",
  },
  productsWrapperHideLabel: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    alignItems: "center",
  },
  productWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 10,
  },
  productImagePh: {
    height: 70,
    width: 70,
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  productImage: {
    height: 70,
    width: 70,
    objectFit: "contain",
  },
  productTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: "16.8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 70,
  },
  productComparisonsWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  productComparisonWrapperHideLabel: {
    marginTop: 0,
  },
  comparisonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    padding: "15px 0px 15px 0px",
  },
  comparisonRowWrapper: (props: PropTypes) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: 85,
    ...props.element.data?.dividerCss,
  }),
  comparisonHideLabelWrapper: {
    paddingRight: 40,
  },
  featureTitle: {
    lineHeight: "19.2px",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 3,
  },
  featureDescription: {
    lineHeight: "16.8px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  productSelectionWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  productSelect: {
    width: 70,
  },
  productSelectCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  productSwitchWrapper: {
    width: 70,
    display: "flex",
    flexDirection: "column",
  },
  priceText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular as any,
    lineHeight: "14.4px",
    textAlign: "center",
  },
  closeIconWrapper: {
    width: 60,
    fontSize: "18px !important",
  },
  uncheckIcon: {
    fill: "#C4C4C4",
    fontSize: 21,
  },
  checkIcon: {
    fill: "#4353FF",
    fontSize: 21,
  },
  clearIcon: {
    fill: "#808080",
    marginLeft: 20,
  },
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
  products?: IProduct[];
  websiteTheme: IWebsiteThemeProps;
};

const mapStateToProps = (state) => ({
  websiteTheme: state.home.selectedWebsite.configurations.theme,
});

export const getRawText = (n: Node) => {
  if (!n.data) {
    return "";
  }
  return (
    [
      n.data.header || "",
      n.data.title || "",
      n.data.text || "",
      n.data.comparisons?.map((c) => `${c.title}\n${c.description}`)?.join("\n"),
    ]?.join("\n") || ""
  );
};

const ComparisonTableWidget = (props: PropTypes) => {
  const classes = useStyles(props);
  const { attributes, children, element, websiteTheme, products = [] } = props;
  const editor = useSlateStatic();

  const defaultData = {
    showImage: true,
    titleCss: {
      fontSize: websiteTheme.typography.h4.css.fontSize,
    },
    descriptionCss: {
      fontSize: websiteTheme.typography.body2.css.fontSize,
    },
    headCss: {
      backgroundColor: websiteTheme.palette.secondary.main,
    },
    oddCss: {
      backgroundColor: websiteTheme.palette.background.default,
    },
    evenCss: {
      backgroundColor: websiteTheme.palette.background.default,
    },
    dividerCss: {
      borderBottomColor: websiteTheme.palette.divider,
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
    },
  };

  React.useEffect(() => {
    handleChange({ ...defaultData, ...element.data });
  }, [websiteTheme]);

  const handleChange = (data) => {
    console.log("handleChange", data, ReactEditor.findPath(editor as ReactEditor, element));
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
    console.log("handleChange done");
  };

  const getComparisonsProducts = (products) => {
    let newProducts = [...products];
    if (element.data.products) {
      const allProducts = [
        ...element.data.products.map((product) => ({
          itemNumber: product.itemNumber,
          selected: false,
        })),
      ];
      newProducts = allProducts.map((product) => ({
        ...product,
        ...newProducts.find((p) => p.itemNumber == product.itemNumber),
      }));
    }
    return newProducts;
  };

  const getCompleteProductObject = (product) => find(products, (p) => p.itemNumber === product?.itemNumber);

  return (
    <div {...attributes}>
      <Widget title={I18n.t("rich_text_editor.custom_components.comparison_table")} element={element} editable>
        <div className={classes.featureComparisons}>
          <div className={classes.tableTopRow} style={element.data.headCss}>
            <div className={classes.tableTitleColumn}>
              <TextAreaWidgetBaseElement
                className={classes.tableHeader}
                text={element.data.header || ""}
                placeholder={I18n.t("rich_text_editor.feature_comparison_table.header_ph")}
                onChange={(header) => handleChange({ ...element.data, header })}
              />
              <TextAreaWidgetBaseElement
                className={classes.tableTitle}
                text={element.data.title || ""}
                placeholder={I18n.t("rich_text_editor.feature_comparison_table.title_ph")}
                onChange={(title) => handleChange({ ...element.data, title })}
              />
              <TextAreaWidgetBaseElement
                className={classes.tableDescription}
                text={element.data.text || ""}
                placeholder={I18n.t("rich_text_editor.feature_comparison_table.description_ph")}
                onChange={(text) => handleChange({ ...element.data, text })}
              />
            </div>
            <div className={classes.productsWrapper}>
              {[...(element.data.products || [])].map((p, index) => {
                const product = getCompleteProductObject(p);
                if (!product) {
                  return null;
                }

                return (
                  <div className={classes.productWrapper} key={`${product?.name}_${index}`}>
                    {element.data.showImage && (
                      <>
                        {product && (product?.iconFile?.url || product.logoFile?.url || product.logo) ? (
                          <img
                            className={classes.productImage}
                            alt={product?.iconFile?.alt || product.logoFile?.alt || `Product ${index}`}
                            src={product?.iconFile?.url || product.logoFile?.url || product.logo}
                          />
                        ) : (
                          <div className={classes.productImagePh}>
                            <ImagePlaceHolder />
                          </div>
                        )}
                      </>
                    )}

                    <Typography className={classes.productTitle}>{product.displayName || product.name}</Typography>
                  </div>
                );
              })}
              <div className={classes.closeIconWrapper} />
            </div>
          </div>

          <div className={ClassNames(classes.productComparisonsWrapper)}>
            {[
              ...(element.data.comparisons || []),
              {
                title: "",
                description: "",
                products: [
                  ...(element.data.products?.map((product, index) => ({
                    itemNumber: product.itemNumber,
                    selected: false,
                  })) || []),
                ],
                selectType: "Checkbox",
              },
            ].map((comp, compIndex) => (
              <div
                key={`con_${compIndex}`}
                className={classes.comparisonRowWrapper}
                style={{ ...(compIndex % 2 === 0 ? element.data.evenCss : element.data.oddCss) }}
              >
                <div className={ClassNames(classes.comparisonWrapper)}>
                  <div className={classes.tableTitleColumn}>
                    <TextAreaWidgetBaseElement
                      className={ClassNames(classes.featureTitle)}
                      text={comp.title}
                      placeholder={I18n.t(`rich_text_editor.feature_comparison_table.feature.title_ph`)}
                      onChange={(title) => {
                        const comparisons = [...(element.data.comparisons || [])];
                        if (compIndex >= comparisons.length) {
                          comparisons.push({ title, description: "" });
                        } else {
                          comparisons[compIndex] = { ...comparisons[compIndex], title };
                        }
                        handleChange({ ...element.data, comparisons });
                      }}
                    />
                    <TextAreaWidgetBaseElement
                      className={classes.featureDescription}
                      text={comp.description}
                      placeholder={I18n.t("rich_text_editor.feature_comparison_table.feature.description_ph")}
                      onChange={(description) => {
                        const comparisons = [...(element.data.comparisons || [])];
                        if (compIndex >= comparisons.length) {
                          comparisons.push({
                            description,
                            title: "",
                            products: [],
                          });
                        } else {
                          comparisons[compIndex] = {
                            ...comparisons[compIndex],
                            description,
                          };
                        }
                        handleChange({ ...element.data, comparisons });
                      }}
                    />
                  </div>
                  <div className={classes.productSelectionWrapper}>
                    {[...getComparisonsProducts(comp?.products || [])].map((product, i) => (
                      <div
                        key={`${product.itemNumber}_${i}_${compIndex}`}
                        className={classes.productSelect}
                        onClick={() => {
                          const products = [...getComparisonsProducts(comp.products || [])];
                          products[i] = { ...product, selected: !product.selected };
                          const comparisons = [...(element.data.comparisons || [])];
                          if (compIndex >= comparisons.length) {
                            comparisons.push({
                              products,
                              description: "",
                              title: "",
                            });
                          } else {
                            comparisons[compIndex] = {
                              ...comparisons[compIndex],
                              products,
                            };
                          }
                          handleChange({ ...element.data, comparisons });
                        }}
                      >
                        {!product.selected ? (
                          <RadioButtonUncheckedIcon className={classes.uncheckIcon} />
                        ) : (
                          <CheckCircleIcon className={classes.checkIcon} />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={classes.closeIconWrapper}>
                  {element.data.comparisons &&
                    element.data.comparisons.length !== 0 &&
                    element.data.comparisons.length !== compIndex && (
                      <CloseIcon
                        className={classes.clearIcon}
                        onClick={() => {
                          const comparisons = [...element.data.comparisons];
                          comparisons.splice(compIndex, 1);
                          handleChange({ ...element.data, comparisons });
                        }}
                      />
                    )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {children}
      </Widget>
    </div>
  );
};

export default connect(mapStateToProps)(ComparisonTableWidget);
