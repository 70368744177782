import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import { useMemo } from "react";
import { I18n } from "react-redux-i18n";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import { CONVERSION_POTENTIAL_FUNNEL_STAGE } from "../../../../helpers/constants";
import CopyToClipboardIcon from "../../../../icons/CopyToClipboardIcon";
import MButtonGroup from "../../../../components/MButtonGroup";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirstWithSelect from "../../../../components/Table/Cells/MTableCellFirstWithSelect";
import MTableCellNumber from "../../../../components/Table/Cells/MTableCellNumber";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    "&:hover": {
      "& $actionsWrapper": {
        display: "flex",
      },
    },
  },
  actionsWrapper: {
    gap: 20,
    alignItems: "center",
    display: "none",
    position: "absolute",
    right: 20,
    zIndex: 2,
    width: "fit-content",
    backgroundColor: theme.palette.secondary.main,
  },
  actionIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionIcon: {
    // width: 18,
    height: 18,
    fill: theme.palette.text.secondary,
  },
  createButton: {
    height: 36,
    // maxWidth: 150,
  },
}));

type PropTypes = {
  item: any;
  redirect: (url: string) => void;
  setSelect(selected: boolean): void;
  selected: boolean;
  selectedWebsite: IWebsite;
  isFirstRow: boolean;
  approveOutline: (item: any, type: string) => void;
  isLastRow: boolean;
  onClick: () => void;
};

const ContentGapsListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { item, setSelect, selected, approveOutline, isFirstRow, isLastRow, onClick } = props;
  const buttonOptions = useMemo(
    () => [
      { label: I18n.t("strategy.strategyKeywords.btnOptions.targeted"), key: "targeted" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.community"), key: "community" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.article"), key: "article" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.top10"), key: "top10" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.review"), key: "review" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.comparison"), key: "comparison" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.list_article"), key: "list_article" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.short_video"), key: "short_video" },
      { label: I18n.t("strategy.strategyKeywords.btnOptions.long_video"), key: "long_video" },
    ],
    []
  );
  const [showTitleCopyTooltip, setShowTitleCopyTooltip] = React.useState(false);

  const handleTitleCopy = (() => {
    let timeoutId;
    return () => {
      if (item.keyword) {
        navigator.clipboard.writeText(item.keyword);
        setShowTitleCopyTooltip(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setShowTitleCopyTooltip(false);
        }, 1000);
      }
    };
  })();
  const theme = useTheme();

  return (
    <MTableRow
      handleRowClick={() => {
        onClick();
      }}
      customClassName={classes.itemWrapper}
    >
      <MTableCellFirstWithSelect
        selected={selected}
        onSelect={setSelect}
        columnWidth={"40%"}
        fontSize={theme.typography.pxToRem(14)}
        avatarType={"A"}
        cellText={item.keyword}
      />

      <MTableCellNumber value={item.pages} type={"bigNumber"} placeHolder={"-"} />
      <MTableCellText text={_.capitalize(CONVERSION_POTENTIAL_FUNNEL_STAGE[item.conversion_potential] || "None")} />
      <MTableCellNumber value={item.variations} type={"bigNumber"} placeHolder={"-"} />
      <MTableCellNumber value={item.volume} type={"bigNumber"} />
      <MTableCellNumber value={item.impressions} type={"bigNumber"} />
      <MTableCellNumber value={item.clicks} type={"bigNumber"} />
      <MTableCellNumber value={item.position} type={"bigNumber"} />
      <MTableCellNumber value={item.potential_value} type={"currency"} />
      <MTableCellNumber value={item.value} type={"currency"} />
      <MTableCellNumber value={item.value_difference} type={"currency"} />
      <div className={classNames(classes.actionsWrapper)}>
        <Tooltip
          arrow
          title={showTitleCopyTooltip ? "Copied" : I18n.t("outline.icons.copy_title")}
          className={classes.actionIconWrapper}
        >
          <div>
            <CopyToClipboardIcon
              className={classNames(classes.actionIcon)}
              onClick={(e) => {
                e.stopPropagation();
                handleTitleCopy();
              }}
            />
          </div>
        </Tooltip>
        <MButtonGroup
          wrapperCustomClass={classes.createButton}
          stopPropagation={true}
          hasDivider={false}
          customClass={classes.createButton}
          onClick={(e) => {
            e.stopPropagation();
            approveOutline(item, "article");
          }}
          onMenuItemClick={(key) => {
            approveOutline(item, key);
          }}
          text={I18n.t("strategy.strategyKeywords.btnOptions.article")}
          options={buttonOptions}
        />
      </div>
    </MTableRow>
  );
};

export default ContentGapsListItem;
