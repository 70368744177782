import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MTextField from "../../../../../components/MTextField";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textField: {
    width: "100%",
    height: 37,
    padding: "0px 10px",
    margin: 0,
    justifyContent: "center",
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    "&:focus": {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
  },
  textInput: {
    padding: 0,
  },
  label: {
    marginRight: 10,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular as any,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    minWidth: 80,
  },
  clearIcon: {
    cursor: "pointer",
    fontSize: 18,
    marginLeft: 10,
  },
}));

type PropTypes = {
  label: string;
  value: string;
  handleOnChange: (val: string) => void;
  clearable?: boolean;
  handleClear?: () => void;
  inputWidth?: string;
};

const ACFTextInput = (props: PropTypes) => {
  const classes = useStyles(props);
  const { label, value, handleOnChange, clearable, inputWidth = "100%" } = props;

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.label}>{label}</Typography>
      <MTextField
        inputClassName={classes.textInput}
        customStyle={{ width: inputWidth }}
        customClass={classes.textField}
        onChange={(value) => handleOnChange(value)}
        value={value}
        placeholder={""}
      />
      {clearable && (
        <ClearIcon
          className={classes.clearIcon}
          onClick={() => {
            props.handleClear && props.handleClear();
          }}
        />
      )}
    </div>
  );
};

export default ACFTextInput;
