import * as React from "react";
import { I18n } from "react-redux-i18n";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import { find } from "lodash";
import { IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import ButtonStyleElement from "../../../elementsStyles/ElementComponents/ButtonStyleElement";
import HorizontalAlignmentCSS from "../../../elementsStyles/CssComponents/HorizontalAlignmentCSS";
import ProductPickerElement from "../../../elementsStyles/ElementComponents/ProductPickerElement";
import { Button } from "../../../types/editor.Types";

type PropTypes = {
  editor: Editor;
  websiteTheme: IWebsiteThemeProps;
  element: Button;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
  selectedWebsite: IWebsite;
};

const ButtonWidgetStyle = (props: PropTypes) => {
  const { editor, websiteTheme, element, products, handleProductsChange, selectedWebsite } = props;

  const handleElementDataChange = (data) => {
    console.log("PageEditorButtonStyle: handleElementDataChange", data);
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.custom_components.button")} editor={editor}>
      <ProductPickerElement
        isClearable={false}
        fullWidth
        selectedWebsite={selectedWebsite}
        products={products}
        handleProductsChange={handleProductsChange}
        product={product}
        onProductChange={(prod: IProduct) => {
          if (!prod) {
            handleElementDataChange({ ...element.data, product: prod });
          } else {
            handleElementDataChange({
              ...element.data,
              product: {
                itemNumber: prod.itemNumber,
              },
            });
          }
        }}
      />
      <ButtonStyleElement
        alwaysVisible
        sectionTitle={I18n.t("rich_text_editor.button.button")}
        button={element.data.button}
        editableText
        editableUrl
        defaultText={element.data?.button?.text || product?.callToAction}
        defaultUrl={element.data?.button?.url || product?.callToActionURL || ""}
        theme={websiteTheme}
        elementDefaultCss={{
          padding: "15px 50px",
        }}
        handleChange={(button) => {
          handleElementDataChange({ ...element.data, button });
        }}
      />
      <HorizontalAlignmentCSS
        value={element.data?.button?.alignment || "center"}
        moreOptions={["full"]}
        handleChange={(alignment) =>
          handleElementDataChange({
            ...element.data,
            button: {
              ...element.data.button,
              alignment,
              css: { ...element.data.button?.css, width: alignment === "full" ? "100%" : undefined },
            },
          })
        }
      />
    </StylePanelSection>
  );
};

export default ButtonWidgetStyle;
