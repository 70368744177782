import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import ProductItem from "./ProductItem";
import { IProduct } from "../../../../../reducers/constants/objectTypes";
import MTable from "../../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";
import MTableRowProgressBar from "../../../../../components/Table/Rows/MTableRowProgressBar";
import MTableRowEmptyRow from "../../../../../components/Table/Rows/MTableRowEmptyRow";

type PropTypes = {
  loaded: boolean;
  products: IProduct[];

  openProductPage: (product: IProduct) => void;
  handleDeleteProductClick: (product: IProduct) => void;
};

const ProductsList = (props: PropTypes) => {
  const { loaded, products, handleDeleteProductClick, openProductPage } = props;
  const theme = useTheme();
  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabel columnWidth={"20%"} fontSize={theme.typography.pxToRem(21)}>
          {I18n.t("products.table.name")}
        </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("products.table.item_number")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("products.table.cta_url")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("products.table.published")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("products.table.active_test")}</MTableHeaderLabel>
        <MTableHeaderLabel columnWidth={"30px"}> {""}</MTableHeaderLabel>
      </MTableHeader>

      {!loaded && <MTableRowProgressBar />}
      {loaded && products.length === 0 && <MTableRowEmptyRow />}
      {loaded &&
        products.length > 0 &&
        products.map((product, index) => (
          <ProductItem
            key={product._id}
            product={product}
            handleProductClick={() => openProductPage(product)}
            handleDeleteClick={() => handleDeleteProductClick(product)}
          />
        ))}
    </MTable>
  );
};

export default ProductsList;
