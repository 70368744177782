import { Moment } from "moment";
import superagent from "superagent";
import { api } from "./helpers/urlHelper";

import {
  eCustomPostType,
  ePageAction,
  ePageState,
  ePageType,
  ePostState,
  ePostType,
  IAdminUser,
  IKeyword,
  ICustomPost,
  IPage,
  IQuery,
  IQueryGroup,
  IWebsite,
  IWorker,
  ISerpData,
  IPageCluster,
  IQuestion,
  IAnswer,
  IWebsiteGSC,
  ISourceFile,
  IFile,
  IExpert,
  ILandingPage,
  IWriterBio,
  eVideoProjectState,
  IVideoProject,
  ILinksSplitTest,
  eVideoProjectAction,
  IVideoProjectTimeline,
  ITimelineAction,
  ITimelineComment,
  eWidgetType,
  ICampaign,
  eCampaignAction,
  IWidget,
  eWidgetSubtype,
  IGoal,
} from "./reducers/constants/objectTypes";
import { IReports } from "./reducers/constants/apiAgentTypes";

const API_ROOT = api.api();

const unauthorizedRedirect = function (req) {
  req.on("response", (res) => {
    if (res.status === 401) {
      console.log("redirects", res.body.redirect);
      if (res.body.redirect) {
        window.location.replace(res.body.redirect);
      }
    }
  });
};

// const encode = encodeURIComponent;
// const responseBody = res => res.body;
const responseBody = (res) => res.body;

const corsPlugin = (req) => {
  // req.set('Access-Control-Allow-Origin', '*');
  req.set("Accept", "application/json");
  req.set("page-path", window.location.pathname);
};

const requests = {
  del: (url) => superagent.del(`${API_ROOT}${url}`).use(corsPlugin).withCredentials().then(responseBody),
  get: (url, query = {}, headers = {}) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .query(query)
      .use(corsPlugin)
      .set(headers)
      .use(unauthorizedRedirect)
      .withCredentials()
      .then(responseBody),
  put: (url, body) => superagent.put(`${API_ROOT}${url}`, body).use(corsPlugin).withCredentials().then(responseBody),
  post: (url, body, headers = {}) =>
    superagent.post(`${API_ROOT}${url}`, body).use(corsPlugin).set(headers).withCredentials().then(responseBody),
  postWithAbort: (url, body, headers = {}, signal) => {
    const req = superagent.post(`${API_ROOT}${url}`, body).use(corsPlugin).set(headers).withCredentials();
    signal.addEventListener("abort", () => {
      req?.xhr?.abort?.();
    });
    return req.then(responseBody);
  },
};

const Auth = {
  getUser: () => requests.get("/auth/user"),
  login: (userName, password) => requests.post("/auth/login", { email: userName, password }),
  register: (email, password, name, orgName = "", emailJoin = false) =>
    requests.post("/auth/signup", { email, password, name, orgName, emailJoin }),
  logout: () => requests.post("/auth/logout", {}),
  logoutSubUser: () => requests.post("/auth/logout/subUser", {}),
  passwordResetSend: (email) => requests.post("/auth/password_reset/send", { email }),
  passwordResetConfirm: (token, password) => requests.post(`/auth/password_reset/confirm/${token}`, { password }),
  googleLogin: (token) => requests.post("/auth/login/google", { token }),
  googleSignUp: (token) => requests.post("/auth/signup/google", { token }),
  changeInitialPassword: (password) => requests.put("/auth/user/initialPassword", { password }),
  upsertUser: (user) => requests.post("/auth/user/upsert", user),
  loginAs: (email) => requests.post(`/auth/login/as/${email}`, {}),
};

const Categories = {
  all: (websiteId) => requests.get(`/categories/${websiteId}/all`),
  upsert: (category) => requests.post("/categories/category", category),
  delete: (category) => requests.del(`/categories/category/${category._id}`),
};

const Posts = {
  getEditedPostByPostById: (postId) => requests.get(`/posts/${postId}`),
  groupPosts: (groupId) => requests.get(`/posts/group/${groupId}/posts`),
  delete: (postId) => requests.post("/posts/delete", { facebookPostId: postId }),
  newEditedPost: (editedPost) => requests.post("/posts/editedPost/new", editedPost),
  scrapingKeywords: (websiteId = null, withPostsOnly = false) =>
    requests.get(
      `/posts/scrapingKeywords/all?withPostsOnly=${withPostsOnly}${websiteId ? `&websiteId=${websiteId}` : ""}`
    ),
  scrapingKeywordPosts: (keywordId) => requests.get(`/posts/scrapingKeyword/${keywordId}/posts`),
};

const websitePages = {
  pages: (websiteId) => requests.get(`/websitePages/${websiteId}/pages`),
  update: (page) => requests.post("/websitePages/update", page),
  generatePreview: (page) => requests.post("/websitePages/generatePreview", page),
  templates: () => requests.get(`/websitePages/templates`),
  createNewPage: (websiteId) => requests.get(`/websitePages/${websiteId}/new`),
};

const Tags = {
  all: (websiteId, withCounters = false, onlyWithText = false) =>
    requests.get(
      `/tags/${websiteId}/all?${withCounters ? "&withCounters=true" : ""}${onlyWithText ? "&onlyWithText=true" : ""}`
    ),
  new: (websiteId, tagName) => requests.post(`/tags/${websiteId}/new/${tagName}`, {}),
  update: (tag) => requests.post("/tags/tag/update", tag),
  deleteTag: (tag, replacingTags: string) =>
    requests.del(`/tags/tag/${tag._id}${replacingTags ? `?replacingTags=${replacingTags}` : ""}`),
  addPhrase: (tagId, phrase) => requests.post(`/tags/${tagId}/add/phrase`, { phrase }),
  removePhrase: (tagId, phrase) => requests.post(`/tags/${tagId}/remove/phrase`, { phrase }),
  sync: (websiteId) => requests.get(`/tags/${websiteId}/sync`),
  getTag: (tagId) => requests.get(`/tags/tag/${tagId}`),
};

const ContactUserTopics = {
  all: () => requests.get("/contactUserTopics/all"),
  newTopic: (topicName) => requests.post(`/contactUserTopics/new/${topicName}`, {}),
};

const SEOKeywords = {
  all: () => requests.get("/keywords/all"),
  new: (name) => requests.post(`/keywords/new/${encodeURIComponent(name)}`, {}),
};

const Keywords = {
  all: (websiteId) => requests.get(`/keywords/${websiteId}/all`),
  new: (websiteId, keywords) => requests.post(`/keywords/${websiteId}/new`, { keywords }),
  delete: (keywordId) => requests.post(`/keywords/delete/${keywordId}`, {}),
};

const Content = {
  domains: () => requests.get(`/content/domains`),
  save: (content) => requests.post("/content/new", content),
};

const Files = {
  upload: (websiteId, file, body) =>
    requests.post(`/files/${websiteId}/upload?name=${file.name}&size=${file.size}`, body),
  updateDetails: (file: IFile) => requests.post(`/files/${file._id}/update`, file),
  uploadThumbnail: (websiteId, videoId: string, contentUrl: string, type: string) =>
    requests.post(`/files/${websiteId}/upload/thumbnail`, { videoId, contentUrl, type }),
  getList: (websiteId) => requests.get(`/files/${websiteId}/list`),
  getAltText: (file: IFile) => requests.get(`/files/alt/${file._id}`),
};

const Insights = {
  getEvents: (websiteId, startDate, endDate, limit, page, interval, url?, source?) =>
    requests.get(
      `/insights/${websiteId}/events?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}&interval=${interval}${
        url ? `&url=${url}` : ""
      }${source ? `&source=${source}` : ""}`
    ),
  getPages: (websiteId, startDate, endDate, limit, page, interval, eventName, searchText = null) =>
    requests.get(
      `/insights/${websiteId}/pages?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}&eventName=${eventName}&interval=${interval}${
        searchText ? `&searchText=${searchText}` : ""
      }`
    ),
  getPageEvents: (websiteId, startDate, endDate, limit, page, searchText = null, eventType = null) =>
    requests.get(
      `/insights/${websiteId}/pages/events?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}${
        searchText ? `&searchText=${searchText}` : ""
      }${eventType ? `&eventType=${eventType}` : ""}`
    ),
  getConversion: (websiteId, startDate, endDate, limit, page, interval, group, rule, url = null) =>
    requests.get(
      `/insights/${websiteId}/conversion?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}&interval=${interval}&group=${group}&rule=${rule}${
        url ? `&url=${url}` : ""
      }`
    ),

  getEventNames: (websiteId) => requests.get(`/insights/${websiteId}/eventNames`),
  getURLs: (websiteId) => requests.get(`/insights/${websiteId}/urls`),
  searchURLs: (websiteId, searchText) => requests.post(`/insights/${websiteId}/urls/search`, { searchText }),
};

const Widgets = {
  getTemplates: (
    website: IWebsite,
    search?: string,
    types?: eWidgetType[],
    subtypes?: (eWidgetSubtype | "empty")[],
    sortName = null,
    sortDate = null,
    limit = 100
  ) =>
    requests.get(
      `/widgets/${website._id}/templates?${search ? `search=${search}` : ""}${
        types ? `&types=${types.join(",")}` : ""
      }${subtypes ? `&subtypes=${subtypes.join(",")}` : ""}&limit=${limit}${sortName ? `&sortName=${sortName}` : ""}${
        sortDate ? `&sortDate=${sortDate}` : ""
      }`
    ),
  getWidgets: (
    websiteId,
    page,
    limit,
    statuses,
    types,
    subtypes,
    searchText,
    sort: string,
    sortDirection: string | number
  ) =>
    requests.get(
      `/widgets/${websiteId}/all?page=${page}&limit=${limit}${statuses ? `&statuses=${statuses}` : ""}${
        types ? `&types=${types}` : ""
      }${subtypes ? `&subtypes=${subtypes}` : ""}${
        searchText ? `&searchText=${searchText}` : ""
      }&sort=${sort}&sortDirection=${sortDirection}`
    ),
  getWidgetAnalytics: (widgetId: string, websiteId: string, startDate: string) =>
    requests.get(`/widgets/${websiteId}/widget/${widgetId}/analytics?startDate=${startDate}`),
  getWidget: (widgetId: string, onlyActive?: boolean) =>
    requests.get(`/widgets/widget/${widgetId}${onlyActive ? "?onlyActive=true" : ""}`),
  deleteWidget: (widgetId) => requests.del(`/widgets/widget/${widgetId}`),
  newWidgetFromTemplate: (websiteId, template) => requests.post(`/widgets/${websiteId}/template/${template._id}`, {}),
  newFormFieldWidget: (websiteId) => requests.post(`/widgets/${websiteId}/create-form-field-widget`, {}),
  updateWidget: (widget, action) => requests.put(`/widgets/widget/${widget._id}/${action}`, widget),
  updateWidgetName: (widgetId, name) => requests.put(`/widgets/widget/${widgetId}/name`, { name }),
  getAutoComplete: (
    website: IWebsite,
    search: string,
    types?: eWidgetType[],
    subtypes?: (eWidgetSubtype | "empty")[],
    sortName = null,
    sortDate = null,
    limit = 100,
    active = true
  ) =>
    requests.get(
      `/widgets/${website._id}/autoComplete?search=${search}&onlyActive=${active}&${
        types ? `types=${types.join(",")}` : ""
      }${subtypes ? `&subtypes=${subtypes.join(",")}` : ""}&limit=${limit}${sortName ? `&sortName=${sortName}` : ""}${
        sortDate ? `&sortDate=${sortDate}` : ""
      }`
    ),
  exportTemplate: (widget: IWidget, name: string) => requests.post("/widgets/export-template", { widget, name }),
};

const Campaigns = {
  getCampaigns: (websiteId, page, limit, sort, direction, searchText, statuses) =>
    requests.get(
      `/campaigns/${websiteId}/all?page=${page}&limit=${limit}&sort=${sort}&sortDirection=${direction}${
        searchText ? `&searchText=${searchText}` : ""
      }${statuses ? `&statuses=${statuses}` : ""}`
    ),
  getCampaignIdAnalytics: (campaignId: string, websiteId: string, startDate: string) =>
    requests.get(`/campaigns/${websiteId}/campaign/${campaignId}/analytics?startDate=${startDate}`),
  getCampaign: (websiteId: string, ruleId: string) => requests.get(`/campaigns/${websiteId}/${ruleId}`),
  createCampaign: (websiteId: string, data = {}) => requests.post(`/campaigns/${websiteId}`, data),
  previewCampaign: (website: IWebsite, rule: ICampaign, isMobile?: boolean, noCache?: boolean, signal?: any) =>
    requests.postWithAbort(
      `/campaigns_preview/${website._id}/preview-in-website?${isMobile ? "isMobile=true" : ""}${
        noCache ? "&noCache=true" : ""
      }`,
      rule,
      {},
      signal
    ),
  updateCampaign: (websiteId: string, rule: ICampaign, action: eCampaignAction) =>
    requests.put(`/campaigns/${websiteId}/${rule._id}/${action}`, rule),
  updateCampaignName: (websiteId: string, ruleId: string, name: string) =>
    requests.put(`/campaigns/${websiteId}/name/${ruleId}`, { name }),
  deleteCampaign: (websiteId: string, ruleId: string) => requests.del(`/campaigns/${websiteId}/${ruleId}`),
  bulkPublishCampaigns: (websiteId: string, ruleIds: string[]) =>
    requests.post(`/campaigns/${websiteId}/bulk/publish`, { ruleIds }),
};

const Organizations = {
  all: () => requests.get("/organizations/all"),
};
const Goals = {
  getGoalEvents: (websiteId: string, startDate: string, search: string, breakdowns: any) =>
    requests.post(
      `/goals/website/${websiteId}/goal/events?startDate=${startDate}${search ? `&search=${search}` : ""}`,
      breakdowns
    ),
};

const Websites = {
  all: () => requests.get("/websites/all"),
  getByID: (websiteId) => requests.get(`/websites/website/${websiteId}`),
  update: (website) => requests.post("/websites/update", website),
  updateConfigurations: (website) => requests.post("/websites/configurations/update", website),
  updateGoals: (websiteId, goals) => requests.post(`/websites/${websiteId}/goals/update`, goals),
  newWebsite: (website) => requests.post(`/websites/new`, website),
  croNewWebsite: (data) => requests.post(`/websites/cro/new/`, data),
  croUpdateWebsite: (data, website: IWebsite) => requests.post(`/websites/${website._id}/cro/update/`, data),

  updateGSC: (websiteId, gsc: IWebsiteGSC) => requests.post(`/websites/${websiteId}/gsc/update`, gsc),
  getStrategy: (websiteId) => requests.get(`/websites/${websiteId}/strategy/get`),
  updateStrategy: (websiteId, strategy) => requests.post(`/websites/${websiteId}/strategy/update`, strategy),
  allAudiences: (websiteId) => requests.get(`/websites/${websiteId}/audience/all`),
  newAudience: (websiteId, name) =>
    requests.post(`/websites/${websiteId}/audience/new/${encodeURIComponent(name)}`, {}),
  updateAudience: (websiteId, audience) => requests.post(`/websites/${websiteId}/audience/update`, audience),
};

const Outlines = {
  update: (outline) => requests.post("/outlines/update", outline),
  newOutline: (outline) => requests.post(`/outlines/new`, outline),
  takeover: (outlineId, outlineView = "DATA_VIEW") =>
    requests.get(`/outlines/takeover/${outlineId}?view=${outlineView}`),
  getOutlineList: (websiteId, view: "DATA_VIEW" | "EDITOR_VIEW") =>
    requests.get(`/outlines/website/${websiteId}/user/${view === "DATA_VIEW" ? "analyst" : "editor"}/all`),
  getOutlineClientsDetails: (view: "DATA_VIEW" | "EDITOR_VIEW") =>
    requests.get(`/outlines/user/${view === "DATA_VIEW" ? "analyst" : "editor"}/websites/details`),
  delete: (outlineId) => requests.del(`/outlines/${outlineId}`),
  createPage: (outlineId, writerId, type: ePageType) =>
    requests.post(`/outlines/${outlineId}/create/page/${type}`, { writerId }),
};

const AutoVideoInterview = {
  listAutoVideoInterviews: (websiteId, page = 1, limit = 100, postStates) =>
    requests.get(
      `/autoVideoInterview/website/${websiteId}?page=${page}&limit=${limit}${
        postStates && postStates?.length > 0 ? `&postStates=${postStates.join(",")}` : ""
      }`
    ),
  getAutoVideoInterview: (websiteId, autoVideoInterviewId) =>
    requests.get(`/autoVideoInterview/website/${websiteId}/${encodeURIComponent(autoVideoInterviewId)}`),
};

const OutlinePages = {
  getOutlines: (options: {
    websiteId;
    page;
    limit;
    searchText?: string;
    postTypes?: string[];
    postStates?: string[];
    sort?: string;
    sortDirection?: string;
    isWorkflowPage?: boolean;
  }) => {
    const { limit, page, websiteId, isWorkflowPage, postTypes, searchText, sort, sortDirection, postStates } = options;

    return requests.get(
      `/outlinePages/website/${websiteId}/user/analyst/all?page=${page}&limit=${limit}${
        searchText ? `&searchText=${searchText}` : ""
      }${postTypes && postTypes?.length > 0 ? `&postTypes=${postTypes.join(",")}` : ""}${
        postStates && postStates?.length > 0 ? `&postStates=${postStates.join(",")}` : ""
      }${sort ? `&sort=${sort}` : ""}${sortDirection ? `&sortDirection=${sortDirection}` : ""}${
        isWorkflowPage ? `&isWorkflowPage=${isWorkflowPage}` : ""
      }`
    );
  },
  updateStatusBulk: (websiteId: string, keywords: string[], status: string) =>
    requests.post(`/outlinePages/website/${websiteId}/update-status/bulk`, { keywords, status }),
  getOutlineKeywords: (websiteId, outlineId) =>
    requests.get(`/outlinePages/website/${websiteId}/outline/${outlineId}/keywords`),
  updateOutline: (outline) => requests.post(`/outlinePages/update/${outline._id}`, outline),
  transferAutoOutline: (outline, pageType) =>
    requests.post(`/outlinePages/transfer`, { autoOutline: outline, pageType }),
  deleteAutoOutline: (outlineId, cascade?: boolean) => requests.post(`/outlinePages/delete/${outlineId}`, { cascade }),
  transferAutoOutlines: (outlines) => requests.post(`/outlinePages/transfer-bulk`, outlines),
  updateState: (ids, state) =>
    requests.post(`/outlinePages/update-state`, {
      ids,
      state,
    }),
};

const QueryGroups = {
  getQueryGroups: (websiteId, page, limit, searchText?: string) =>
    requests.get(
      `/keywordGroups/${websiteId}?page=${page}&limit=${limit}${searchText ? `&searchText=${searchText}` : ""}
  `
    ),
};

const Statistics = {
  websites: () => requests.get("/statistics/websites"),
  websitesV2: (startDate: string | null, endDate: string | null) =>
    requests.get(
      `/statistics/websites/v2${startDate ? `?startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`
    ),

  pipeline: (selectedMonth: Moment) => requests.get(`/statistics/pipeline?selectedMonth=${selectedMonth}`),
  wordCounter: () => requests.get("/statistics/wordCounter"),
  contentWritingGetWriters: () => requests.get("/statistics/contentWriting/writers"),
  contentWritingUpdateWriters: (writers) => requests.post("/statistics/contentWriting/writers", writers),
  contentWriting: (startDate: Moment, endDate: Moment, selectedWebsites?: IWebsite[]) =>
    requests.get(
      `/statistics/contentWritingNew?startDate=${startDate}&endDate=${endDate}${
        selectedWebsites && selectedWebsites.length > 0
          ? `&websites=${selectedWebsites.map((w) => w._id).join(",")}`
          : ""
      }`
    ),

  contentWritingV2: (startDate: Moment, endDate: Moment, selectedWebsites?: IWebsite[]) =>
    requests.get(
      `/statistics/contentWritingNew/v2?startDate=${startDate}&endDate=${endDate}${
        selectedWebsites && selectedWebsites.length > 0
          ? `&websites=${selectedWebsites.map((w) => w._id).join(",")}`
          : ""
      }`
    ),
};

const User = {
  all: (websiteId) => requests.get(`/user/${websiteId}/all`),
  autoComplete: (websiteId, search) => requests.get(`/user/${websiteId}/autoComplete?search=${search}`),
  writers: (websiteId) => requests.get(`/user/${websiteId}/writers`),
};

const Admin = {
  getUsers: () => requests.get("/admin/all"),
  upsertUser: (user) => requests.post("/admin/upsert", user),
  deleteUser: (user) => requests.del(`/admin/${user._id}`),
  keepAlive: (tabVisible, URI) => requests.post("/admin/keepAlive", { tabVisible, URI }),
};

const Questions = {
  get: (id) => requests.get(`/questions/${id}`),
  update: (question) => requests.post("/questions/update", question),
};

const Community = {
  getWriterAnswer: (postId) => requests.get(`/community/writeranswer/post/${postId}`),
  discardAnswer: (translation = false, post, mode: "customerEditor" | "editor" | "writer") =>
    requests.post(`/community/writeranswer/${translation ? "translation" : "post"}/${mode}/discard`, post),
  saveAnswer: (post, locale = null) =>
    requests.post(`/community/writeranswer/post/save${locale ? `?locale=${locale}` : ""}`, post),
  pendingAnswers: (website, sort: string, direction: string) =>
    requests.get(`/community/${website._id}/answers/pending?sort=${sort}&sortDirection=${direction}`),
  publishedAnswers: (website, sort: string, direction: string) =>
    requests.get(`/community/${website._id}/answers/published?sort=${sort}&sortDirection=${direction}`),

  getAll: (
    websiteId,
    categories = [],
    searchText = null,
    filter = null,
    page = "0",
    limit = 10,
    sort = "createdAt",
    direction = "desc"
  ) =>
    requests.get(
      `/community/${websiteId}/all${filter ? `?filter=${filter}` : ""}${page ? `&page=${page}` : ""}${
        categories.length > 0 ? `&categories=${categories.join(",")}` : ""
      }${searchText ? `&searchText=${searchText}` : ""}${
        limit ? `&limit=${limit}` : ""
      }&sort=${sort}&sortDirection=${direction}`
    ),
  getModeratorQuestions: (
    websiteId,
    page = "0",
    limit = 50,
    category = null,
    searchText = null,
    sort = "createdAt",
    direction = "desc"
  ) =>
    requests.get(
      `/community/${websiteId}/moderator${page ? `?page=${page}` : ""}${limit ? `&limit=${limit}` : ""}${
        category ? `&category=${category}` : ""
      }${searchText ? `&searchText=${searchText}` : ""}&sort=${sort}&sortDirection=${direction}`
    ),

  getNewQuestion: (websiteId) => requests.get(`/community/${websiteId}/new`),
  get: (id) => requests.get(`/community/${id}`),
  update: (question) => requests.post("/community/update", question),
  updateV2: (question) => requests.post("/community/v2/update", question),
  moderateQuestion: (question: IQuestion, action) =>
    requests.post(`/community/moderator/moderate/${action}/question/${question._id}`, question),
  moderateAnswer: (answer: IAnswer, action) =>
    requests.post(`/community/moderator/moderate/${action}/answer/${answer._id}/`, {}),
};

const Learn = {
  getAll: (websiteId) => requests.get(`/learn/${websiteId}/topics/all`),
  getTopic: (topicId) => requests.get(`/learn/topics/${topicId}`),
  updateTopic: (topic) => requests.post("/learn/topics/update", topic),
  removeTopicWriter: (topic) => requests.post("/learn/topics/removeWriter", topic),
  upsertTopic: (topic) => requests.post("/learn/topics/upsert", topic),
  updateTopicPosition: (topicId, source, destination) =>
    requests.post("/learn/topics/position", { topicId, source, destination }),
  deleteTopic: (topicId) => requests.del(`/learn/topics/${topicId}`),
  getPosts: (topicId) => requests.get(`/learn/posts/${topicId}`),
  deletePost: (postId) => requests.del(`/learn/posts/${postId}`),
  getNewPost: (topicId) => requests.get(`/learn/posts/${topicId}/new`),
  getNewPostByWebsite: (websiteId) => requests.get(`/learn/website/${websiteId}/posts/new`),
  updatePost: (post) => requests.post(`/learn/posts/update`, post),
  updatePostsPosition: (postId, source, destination) =>
    requests.post("/learn/posts/position", { postId, source, destination }),
};

const Sources = {
  getPendingContent: (websiteId, facebookPosts, manualPosts, domains = []) =>
    requests.get(
      `/sources/${websiteId}/pendingContent?facebook=${facebookPosts}&manualPosts=${manualPosts}&domains=${domains.join(
        ","
      )}`
    ),
  markEditedPostsAsUsed: (editedPostId, learnPostId, add = true) =>
    requests.post(`/sources/editedPost/markAsUsed/learnPost?add=${add}`, {
      editedPostId,
      learnPostId,
    }),
  markManualPostsAsUsed: (manualPostId, learnPostId, add = true) =>
    requests.post(`/sources/manualPost/markAsUsed/learnPost?add=${add}`, {
      manualPostId,
      learnPostId,
    }),
};

const Writers = {
  getWriters: (website, type: ePostType, locale = "en", creditedUser = false, search = "") =>
    requests.get(
      `/writers/${website._id}/${type}/writers?locale=${locale}&creditedWriter=${creditedUser}&search=${search}`
    ),
  getEditors: (website, type: ePostType, locale = "en") =>
    requests.get(`/writers/${website._id}/${type}/editors?locale=${locale}`),

  getWritersV2: (website, type: ePageType | string, locale = "en", creditedUser = false, search = "") =>
    requests.get(
      `/writersV2/${website._id}/${type}/writers?locale=${locale}&creditedWriter=${creditedUser}&search=${search}`
    ),

  getWriterBio: (website, writer: IAdminUser) => requests.get(`/writersV2/${website._id}/bio/${writer._id}`),
  getEditorsV2: (website, type: ePageType | string, locale = "en") =>
    requests.get(`/writersV2/${website._id}/${type}/editors?locale=${locale}`),
  pendingAnswers: (website, includeCommunity = false) =>
    requests.get(`/writers/${website._id}/answers/pending?includeCommunity=${includeCommunity}`),
  selectTag: (website, tag) => requests.post(`/writers/${website._id}/answers/select/topic/${tag._id}`, {}),
  selectTopic: (website, topic, type: ePostType) =>
    requests.post(`/writers/${website._id}/answers/select/${type}/topic/${topic._id}`, {}),
  selectAnswer: (website, post, type: ePostType) =>
    requests.post(`/writers/${website._id}/answers/select/${type}/${post._id}`, {}),
  selectTranslation: (website, post, type: ePostType) =>
    requests.post(`/writers/${website._id}/translations/select/${type}/${post._id}`, {}),
};

const Review = {
  pendingPosts: (website, customerView = false) =>
    requests.get(`/review/${website._id}/pending?customerView=${customerView}`),
  customerSelectAnswer: (website, type: ePostType, post) =>
    requests.post(`/review/${website._id}/answers/select/customer/${type}/${post._id}`, {}),
  selectAnswer: (website, type: ePostType, post) =>
    requests.post(`/review/${website._id}/answers/select/${type}/${post._id}`, {}),
  selectTranslation: (website, type: ePostType, post) =>
    requests.post(`/review/${website._id}/translations/select/${type}/${post._id}`, {}),
  selectTopic: (website, type: ePostType, topic) =>
    requests.post(`/review/${website._id}/answers/select/${type}/topic/${topic._id}`, {}),
  summary: () => requests.get(`/review/summary`),
  summaryV2: (customer = false) => requests.get(`/review/summary/v2`),
};

const EditPost = {
  deletePost: (type: ePostType, postId, translation = false) =>
    requests.del(`/editPost/${type}/post/${postId}?translation=${translation}`),
  discardAnswer: (translation = false, postType: ePostType, post, mode: "customerEditor" | "editor" | "writer") =>
    requests.post(`/editPost/${postType}/${translation ? "translation" : "post"}/${mode}/discard`, post),
  takeOver: (postType: ePostType.CUSTOM, post, mode: "editor" | "customerEditor") =>
    requests.post(`/editPost/${postType}/post/${mode}/take-over`, post),
  removeWriter: (postType: ePostType, post) => requests.post(`/editPost/${postType}/post/removeWriter`, post),
};

const Items = {
  getItems: (website: IWebsite, page: number, limit: number, state: ePostState[], sort: string, postTypes = ["All"]) =>
    requests.get(
      `/items/${website._id}/all?page=${page}&limit=${limit}&state=${state.join(
        ","
      )}&sort=${sort}&postTypes=${postTypes.join(",")}`
    ),
  getWriterItems: (
    website: IWebsite,
    writer: IAdminUser,
    page: number,
    limit: number,
    state: ePostState[],
    sort: string,
    postTypes = ["All"]
  ) =>
    requests.get(
      `/items/${website._id}/writer/${writer._id}/all?page=${page}&limit=${limit}&state=${state.join(
        ","
      )}&sort=${sort}&postTypes=${postTypes.join(",")}`
    ),
};

const Counters = {
  manager: (websiteId) => requests.get(`/counters/${websiteId}/manager`),
};

const Workers = {
  getWorkers: (websiteId?: string) =>
    requests.get(`/workers/workers${websiteId && websiteId !== "all" ? `?websiteId=${websiteId}` : ""}`),
  updateWorker: (worker: IWorker) => requests.post(`/workers/worker/${worker._id}`, worker),
  getRules: () => requests.get(`/workers/rules`),
  updateRule: (rule) => requests.post(`/workers/rule`, rule),
  scrapeGroupNow: (group) => requests.post(`/workers/scrapeNow/group`, group),
  getKeywords: (website) => requests.get(`/workers/${website._id}/keywords`),
  createKeywords: (website, keywords) => requests.post(`/workers/${website._id}/keywords`, { keywords }),
  updateKeyword: (keyword) => requests.post(`/workers/keyword`, keyword),
  deleteKeyword: (keyword) => requests.del(`/workers/keyword/${keyword._id}`),
};

const Clusters = {
  getClusters: (websiteId, startDate: Moment, endDate: Moment) =>
    requests.get(
      `/clusters/${websiteId}/all?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}`
    ),
  getClusterById: (clusterId, startDate: Moment, endDate: Moment) =>
    requests.get(
      `/clusters/cluster/${clusterId}?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format(
        "YYYY-MM-DD"
      )}`
    ),
  deleteCluster: (clusterId) => requests.del(`/clusters/cluster/${clusterId}`),
  updateCluster: (cluster, startDate: Moment, endDate: Moment) =>
    requests.post(
      `/clusters/cluster?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}`,
      cluster
    ),
  newCluster: (websiteId, name) => requests.post(`/clusters/${websiteId}/cluster/${encodeURIComponent(name)}`, {}),
  addClusterPage: (clusterId, post, startDate: Moment, endDate: Moment) =>
    requests.post(
      `/clusters/cluster/${clusterId}/clusterPage?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format(
        "YYYY-MM-DD"
      )}`,
      post
    ),
  addLinkingPage: (clusterId, post, startDate: Moment, endDate: Moment) =>
    requests.post(
      `/clusters/cluster/${clusterId}/linkingPage?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format(
        "YYYY-MM-DD"
      )}`,
      post
    ),
  newPostAndLinkingPage: (clusterId, post) => requests.post(`/clusters/cluster/${clusterId}/newPost`, post),
  filterPosts: (
    websiteId,
    sortBy,
    filterPages,
    startDate: Moment,
    endDate: Moment,
    search = null,
    pageType = null,
    pageStatus = null,
    tags = null
  ) =>
    requests.get(
      `/clusters/search/${websiteId}/posts?sort=${sortBy}&filterPages=${filterPages}&startDate=${startDate.format(
        "YYYY-MM-DD"
      )}&endDate=${endDate.format("YYYY-MM-DD")}${search ? `&search=${encodeURIComponent(search)}` : ""}${
        pageType ? `&pageType=${encodeURIComponent(pageType)}` : ""
      }${pageStatus ? `&pageStatus=${encodeURIComponent(pageStatus)}` : ""}${
        tags ? `&tags=${encodeURIComponent(tags)}` : ""
      }`
    ),
  addScrapingKeywords: (cluster, keywords) => requests.post(`/clusters/${cluster._id}/scrapingKeywords`, { keywords }),
  removeScrapingKeywords: (cluster, keyword) =>
    requests.del(`/clusters/${cluster._id}/scrapingKeywords/${keyword._id}`),
};

const Redirects = {
  getRules: (websiteId) => requests.get(`/redirects/${websiteId}/rules`),
  newRule: (websiteId, rule) => requests.post(`/redirects/${websiteId}/rule`, rule),
  newRules: (websiteId, rules) => requests.post(`/redirects/${websiteId}/rules`, rules),
  deleteRule: (websiteId, rule) => requests.del(`/redirects/${websiteId}/rule/${rule._id}`),
};
const Orders = {
  getOrders: (websiteId, refresh = false) => requests.get(`/orders/${websiteId}/orders?refresh=${refresh}`),
};

const Ads = {
  getAds: (websiteId) => requests.get(`/pirsomot/${websiteId}/pirsomot/all`),
  getAdById: (adId) => requests.get(`/pirsomot/pirsomot/${adId}`),
  deleteAd: (adId) => requests.del(`/pirsomot/pirsomet/${adId}`),
  newAd: (websiteId, name) => requests.post(`/pirsomot/${websiteId}/pirsomet/${encodeURIComponent(name)}`, {}),
  updateAd: (ad) => requests.post(`/pirsomot/pirsomet`, ad),
  getBusinesses: (websiteId) => requests.get(`/pirsomot/${websiteId}/businesses/all`),
  newBusiness: (websiteId, name) => requests.post(`/pirsomot/${websiteId}/business/${encodeURIComponent(name)}`, {}),
  updateBusiness: (business) => requests.post(`/pirsomot/business`, business),
};

const Product = {
  all: (websiteId, page, limit = 100, search?: string) =>
    requests.get(`/products/${websiteId}/all?page=${page}&limit=${limit}${search ? `&search=${search}` : ""}`),
  get: (websiteId, id) => requests.get(`/products/product/${websiteId}/${id}`),
  update: (product) => requests.post(`/products/product`, product),
  remove: (product) => requests.post(`/products/remove`, product),
  autoComplete: (websiteId, search, onlyActive = false, limit = 100) =>
    requests.get(`/products/${websiteId}/autoComplete?search=${search}&onlyActive=${onlyActive}&limit=${limit}`),
};

const Goal = {
  autoComplete: (websiteId, search, onlyActive = false, limit = 100) =>
    requests.get(`/goals/${websiteId}/autoComplete?search=${search}&limit=${limit}`),
  getGoalTypes: () => requests.get(`/goals/goal-types`),
  create: (goal: IGoal, websiteId: string) => requests.post(`/goals/${websiteId}`, goal),
  update: (goal: IGoal, websiteId: string) => requests.put(`/goals/${websiteId}`, goal),
};

const Audiences = {
  all: (websiteId, limit = 100) => requests.get(`/audiences/${websiteId}/all?limit=${limit}`),
  get: (id) => requests.get(`/audiences/${id}`),
  newAudience: (websiteId, name) => requests.post(`/audiences/${websiteId}/${name}`, {}),
  updateAudience: (audience) => requests.post(`/audiences/audience`, audience),
  audiencesAutoComplete: (websiteId, search) =>
    requests.get(`/audiences/${websiteId}/audience/autoComplete?search=${search}`),
  categories: (websiteId, mainCategory?: boolean, search = null) =>
    requests.get(
      `/audiences/categories/${websiteId}/all${mainCategory ? `?mainCategory=${mainCategory}` : ""}${
        search ? `?search=${search}` : ""
      }`
    ),
  newCategory: (websiteId, name) => requests.post(`/audiences/categories/${websiteId}/${name}`, {}),
  topics: (websiteId, search = null) =>
    requests.get(`/audiences/topics/${websiteId}/all${search ? `?search=${search}` : ""}`),
  newTopic: (websiteId, name) => requests.post(`/audiences/topics/${websiteId}/${name}`, {}),
};

const Experts = {
  list: (websiteId: string, page, limit, searchText = null) =>
    requests.get(
      `/experts/${websiteId}/list?page=${page}&limit=${limit}${searchText ? `&searchText=${searchText}` : ""}`
    ),
  get: (id) => requests.get(`/experts/${id}`),
  update: (expert) => requests.post(`/experts/expert`, expert),
  delete: (website: IWebsite, expert: IExpert) => requests.del(`/experts/${website._id}/${expert._id}`),
  autoComplete: (websiteId, search) => requests.get(`/experts/${websiteId}/expert/autoComplete?search=${search}`),
};

const Analysis = {
  metrics: (websiteId, queries, interval = "day", startDate: Moment, endDate: Moment, totalView: boolean) =>
    requests.get(
      `/analysis/${websiteId}/${
        queries ? "queries" : "pages"
      }/metrics?interval=${interval}&startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format(
        "YYYY-MM-DD"
      )}&totalView=${totalView}`
    ),
  singlePage: (websiteId, interval = "day", startDate: Moment, endDate: Moment, totalView: boolean) =>
    requests.get(
      `/analysis/${websiteId}/singlePage/metrics?interval=${interval}&startDate=${startDate.format(
        "YYYY-MM-DD"
      )}&endDate=${endDate.format("YYYY-MM-DD")}&totalView=${totalView}`
    ),
  content: (websiteId, interval = "day", startDate: Moment, endDate: Moment, totalView: boolean) =>
    requests.get(
      `/analysis/${websiteId}/content?interval=${interval}&startDate=${startDate.format(
        "YYYY-MM-DD"
      )}&endDate=${endDate.format("YYYY-MM-DD")}&totalView=${totalView}`
    ),
};

const SlateEditor = {
  linkPreview: (url: string) => requests.get(`/slate/link/preview?url=${encodeURIComponent(url)}`),
};

const EditorialPolicy = {
  get: (websiteId) => requests.get(`/editorialPolicy/${websiteId}/policies`),
  update: (websiteId, type, policy: { text: string; richText: string }) =>
    requests.post(`/editorialPolicy/${websiteId}/${type}`, policy),
  remove: (policyId) => requests.del(`/editorialPolicy/policy/${policyId}`),
};

const Bios = {
  all: (websiteId, limit = 100) => requests.get(`/writersBio/${websiteId}/all?limit=${limit}`),
  getBio: (id) => requests.get(`/writersBio/${id}`),
  getByWebsiteAndWriter: (websiteId, writerId) => requests.get(`/writersBio/${websiteId}/writer/${writerId}`),
  updateBio: (websiteId, userId, bio) => requests.post(`/writersBio/${websiteId}/${userId}/bio`, bio),

  newWriter: (websiteId, name) => requests.post(`/writersV2/${websiteId}/${name}`, {}),

  audiencesAutoComplete: (websiteId, search) =>
    requests.get(`/audiences/${websiteId}/audience/autoComplete?search=${search}`),
  categories: (websiteId, mainCategory?: boolean, search = null) =>
    requests.get(
      `/audiences/categories/${websiteId}/all${mainCategory ? `?mainCategory=${mainCategory}` : ""}${
        search ? `?search=${search}` : ""
      }`
    ),
  newCategory: (websiteId, name) => requests.post(`/audiences/categories/${websiteId}/${name}`, {}),
  topics: (websiteId, search = null) =>
    requests.get(`/audiences/topics/${websiteId}/all${search ? `?search=${search}` : ""}`),
  newTopic: (websiteId, name) => requests.post(`/audiences/topics/${websiteId}/${name}`, {}),
};

const DataTitles = {
  getAll: (websiteId) => requests.get(`/dataTitles/website/${websiteId}/all`),
  update: (dataTitle) => requests.post("/dataTitles/update", dataTitle),
};

const Seeds = {
  getSeeds: (config: {
    website?: string;
    type?: string;
    source?: string;
    limit?: string;
    page?: string;
    skip?: number;
    search: string;
  }) => {
    const { website = null, type = null, source = null, limit = null, page = null, search = "", skip = null } = config;
    return requests.get(
      `/seed/all?
			${website ? `&website=${website}` : ""}
			${type ? `&type=${type}` : ""}
			${source ? `&source=${source}` : ""}
			${limit ? `&limit=${limit}` : ""}
			${page ? `&page=${page}` : ""}
			${search ? `&search=${search}` : ""}
			${skip ? `&skip=${skip}` : ""}`
    );
  },
  deleteSeeds: (ids: Array<string>) => requests.del(`/seed/${ids.join(",")}`),
  createSeeds: (data) => requests.post("/seed", data),
  fetchSeed: (id, fetchLimit) => requests.post(`/seed/fetch/${id}`, { fetchLimit }),
  fetchAll: (seeds, fetchLimit) => requests.post(`/seed/fetch/all`, { seeds, fetchLimit }),
  getBalance: () => requests.get(`/seed/balance`),
};

const KeywordGroups = {
  getKeywordGroups: (websiteId: string, limit = 20, searchText?: string, page?: number, sort?: string) =>
    requests.get(
      `/keywordGroups/${websiteId}/?limit=${limit}${searchText ? `&text=${searchText}` : ""}${
        page ? `&page=${page}` : ""
      }${sort ? `&sort=${sort}` : ""}`
    ),
  getKeywords: (websiteId: string, limit = 20, searchText?: string, page?: number, sort?: string) =>
    requests.get(
      `/keywordGroups/${websiteId}/keywords?limit=${limit}${searchText ? `&text=${searchText}` : ""}${
        page ? `&page=${page}` : ""
      }${sort ? `&sort=${sort}` : ""}`
    ),
  getKeywordGroup: (websiteId: string, groupHash: string) => requests.get(`/keywordGroups/${websiteId}/${groupHash}`),
  getKeywordSerpStats: (websiteId: string) => requests.get(`/keywordGroups/${websiteId}/serpStats`),
};

const ExcludedKeywordSeeds = {
  getExcludedKeywordSeeds: (config: {
    website?: string;
    search: string;
    type: string;
    limit?: string;
    page?: number;
  }) => {
    const { website = null, search = "", type = "", limit = null, page = null } = config;
    return requests.get(`/exclude/?
			${website ? `website=${website}` : ""}
			${search ? `&search=${search}` : ""}
			${type ? `&type=${type}` : ""}
			${limit ? `&limit=${limit}` : ""}
			${page ? `&page=${page}` : ""},`);
  },
  addExcludedKeywords: (data, websiteId) => requests.post(`/exclude/${websiteId}`, data),
  cancelExclusion: (id: string[]) => requests.del(`/exclude/${id.join(",")}`),
};

const KeywordsClassification = {
  getKeywords: (
    websiteId?: string,
    tag?: string,
    level?: string,
    type?: string,
    wordCount?: number,
    searchText?: string,
    limit?: string,
    page?: number,
    sortConfig?: Record<string, string>
  ) =>
    requests.get(`/keywordClassification/?
			${websiteId ? `websiteId=${websiteId}` : ""}
			${level ? `&level=${level}` : ""}
			${searchText ? `&searchText=${searchText}` : ""}
			${wordCount ? `&wordCount=${wordCount}` : ""}
			${type ? `&type=${type}` : ""}
			${tag ? `&tag=${tag}` : ""}
			${limit ? `&limit=${limit}` : ""}
			${page ? `&page=${page}` : ""}
      &${Object.keys(sortConfig)
        .map((key) => `sort=${key}`)
        .join("&")}&${Object.values(sortConfig)
      .map((key) => `sortDirection=${key}`)
      .join("&")}`),

  getKeywordsTypes: (websiteId?: string, search?: string, limit?: string, page?: string, selectType?: boolean) =>
    requests.get(
      `/keywordType/?${websiteId ? `websiteId=${websiteId}` : ""}${search ? `&search=${search}` : ""}${
        limit ? `&limit=${limit}` : ""
      }${page ? `&page=${page}` : ""}${selectType ? `&selectType=${selectType}` : ""}`
    ),
  updateKeyword: (id: string, updatedKeyword: object) => requests.put(`/keywordClassification/${id}`, updatedKeyword),
  deleteKeyword: (id: string) => requests.del(`/keywordClassification/${id}`),
  createKeyword: (keywords: any) => requests.post(`/keywordClassification`, keywords),
  generateNgrams: (websiteId: string, ngram: number) =>
    requests.post(`/keywordClassification/${websiteId}/`, { ngram }),
  deleteManyKeywords: (ids: string[]) => requests.post(`/keywordClassification/delete-many`, ids),
};

const Airflow = {
  triggerKeywordGroupDag: (websiteId, dagId) => requests.post(`/airflow/${websiteId}/trigger`, { dagId }),
  getKeywordGroupDagRuns: (websiteId, dagId) => requests.get(`/airflow/${websiteId}/${dagId}/dagRuns`),
};

const KeywordTypes = {
  getKeywordsTypes: (websiteId, search, limit, page) =>
    requests.get(
      `/keywordType?${websiteId ? `websiteId=${websiteId}` : ""}${limit ? `&limit=${limit}` : ""}${
        page ? `&page=${page}` : ""
      }${search ? `&search=${search}` : ""}`
    ),
  getKeywordTypeById: (id: string) => requests.get(`/keywordType/${id}`),
  createKeywordType: (keywordType) => requests.post(`/keywordType`, keywordType),
  updateKeywordType: (id, keywordType) => requests.put(`/keywordType/${id}`, keywordType),
  deleteKeywordType: (id) => requests.del(`/keywordType/${id}`),
  addWebsite: (ids, website) => requests.put(`/keywordType/${website}/add-website`, { ids }),
  removeWebsite: (ids, website) => requests.put(`/keywordType/${website}/remove-website`, { ids }),
  // addngramKeywordType : (websiteId, ngram) => requests.post(`/keywordType/${websiteId}/?ngram=${ngram}`)
};

const KeywordStrategy = {
  getKeywordStrategies: (
    websiteId,
    filter,
    limit,
    page,
    sort,
    text,
    status,
    startTime?: string,
    statusWeight?: string,
    excludeKeywords?: string,
    includeKeywords?: string,
    pagesMin?: number,
    pagesMax?: number,
    clicksMin?: number,
    clicksMax?: number,
    volumeMin?: number,
    volumeMax?: number,
    contentGap?: boolean,
    impressionsMin?: number,
    impressionsMax?: number,
    keywordsMin?: number,
    keywordsMax?: number,
    positionMin?: number,
    positionMax?: number
  ) =>
    requests.get(`/keywordStrategy/${websiteId}?
			${filter ? `filter=${filter}` : ""}
			${sort ? `&sort=${sort}` : ""}
			${page ? `&page=${page}` : ""}
			${limit ? `&limit=${limit}` : ""}
			${status ? `&status=${status}` : ""}
			${text ? `&text=${text}` : ""}
			${startTime ? `&startTime=${startTime}` : ""}
			${pagesMin || pagesMin === 0 ? `&pagesMin=${pagesMin}` : ""}
			${pagesMax || pagesMax === 0 ? `&pagesMax=${pagesMax}` : ""}
			${statusWeight ? `&statusWeight=${statusWeight}` : ""}
			${volumeMin || volumeMin === 0 ? `&volumeMin=${volumeMin}` : ""}
			${volumeMax || volumeMax === 0 ? `&volumeMax=${volumeMax}` : ""}
			${clicksMin || clicksMin === 0 ? `&clicksMin=${clicksMin}` : ""}
			${clicksMax || clicksMax === 0 ? `&clicksMax=${clicksMax}` : ""}
			${excludeKeywords ? `&excludeKeywords=${excludeKeywords}` : ""}
			${includeKeywords ? `&includeKeywords=${includeKeywords}` : ""}
			${contentGap ? `&contentGap=${contentGap}` : ""}
			${impressionsMin || impressionsMin === 0 ? `&impressionsMin=${impressionsMin}` : ""}
			${impressionsMax || impressionsMax === 0 ? `&impressionsMax=${impressionsMax}` : ""}
			${keywordsMin || keywordsMin === 0 ? `&keywordsMin=${keywordsMin}` : ""}
			${keywordsMax || keywordsMax === 0 ? `&keywordsMax=${keywordsMax}` : ""}
            ${positionMin || positionMin === 0 ? `&positionMin=${positionMin}` : ""}
            ${positionMax || positionMax === 0 ? `&positionMax=${positionMax}` : ""}
			`),
  getBroadKeywords: (websiteId, mainKeyword) => requests.get(`/keywordStrategy/${websiteId}/${mainKeyword}`),
  getSerps: (websiteId, keyword) => requests.get(`/keywordStrategy/${websiteId}/topic/${keyword}/serps`),
  updateKeywordStrategy: (websiteId, keyword, data, triggerQueue?: string) =>
    requests.put(
      `/keywordStrategy/${websiteId}/${keyword}${triggerQueue ? `?triggerQueue=${triggerQueue}` : ""}`,
      data
    ),
  deleteKeywordStrategy: (websiteId, keyword) => requests.del(`/keywordStrategy/${websiteId}/${keyword}`),
  updateAllStatus: (websiteId, keywords, status, triggerQueue?: string) =>
    requests.put(
      `/keywordStrategy/change/status/${websiteId}/?keywords=${keywords}${
        triggerQueue ? `&triggerQueue=${triggerQueue}` : ""
      }`,
      { status }
    ),
  mergeTopics: (websiteId, keywords) => requests.post(`/keywordStrategy/${websiteId}/merge/topics`, { keywords }),
};

const TopicGroup = {
  createTopicGroup: (websiteId, topics) => requests.post(`/topicGroup/${websiteId}`, { topics }),
};

const KeywordGroupPage = {
  getPages: (websiteId, page, limit, sort, text?: string, conversionPotential?: string) =>
    requests.get(
      `/keywordGroupPage/${websiteId}?page=${page}&limit=${limit}${sort ? `&sort=${sort}` : ""}${
        text ? `&text=${text}` : ""
      }${conversionPotential ? `&conversionPotential=${conversionPotential}` : ""}`
    ),
  getPageKeywords: (websiteId, page) => requests.get(`/keywordGroupPage/${websiteId}/${encodeURIComponent(page)}`),
  getRelatedPages: (websiteId, page) =>
    requests.get(`/keywordGroupPage/${websiteId}/${encodeURIComponent(page)}/related`),
  getRelatedKeywords: (websiteId, mainPage, relatedPage) =>
    requests.get(
      `/keywordGroupPage/${websiteId}/${encodeURIComponent(mainPage)}/${encodeURIComponent(relatedPage)}/keywords`
    ),
};

const DataPages = {
  getAll: (websiteId) => requests.get(`/dataPages/website/${websiteId}/all`),
  update: (dataPage) => requests.post("/dataPages/update", dataPage),
};

const VideoProjects = {
  getAll: (
    website: IWebsite,
    page: number,
    limit: number,
    sort: string,
    sortDirection: string | number,
    state: eVideoProjectState[],
    searchText?: string,
    category?: string
  ) =>
    requests.get(
      `/video-projects/${website._id}/all?page=${page}&limit=${limit}&state=${state.join(
        ","
      )}&sort=${sort}&sortDirection=${sortDirection}&searchText=${searchText}&category=${category || ""}`
    ),
  create: (website: IWebsite, state: eVideoProjectState) =>
    requests.post(`/video-projects/${website._id}/create/${state}`, {}),
  getProject: (website: IWebsite, videoProjectId: string) =>
    requests.get(`/video-projects/${website._id}/${videoProjectId}`),
  update: (website: IWebsite, videoProject: IVideoProject, action: eVideoProjectAction) =>
    requests.put(`/video-projects/${website._id}/${videoProject._id}/${action}`, videoProject),
  updateTitle: (website: IWebsite, videoProject: IVideoProject, title: string) =>
    requests.put(`/video-projects/${website._id}/${videoProject._id}/title`, { title }),
  createComment: (
    website: IWebsite,
    videoProject: IVideoProject,
    timeline: IVideoProjectTimeline,
    action: ITimelineAction
  ) => requests.post(`/video-projects/${website._id}/${videoProject._id}/${timeline._id}/comment`, action),
  createCommentReply: (
    website: IWebsite,
    videoProject: IVideoProject,
    timeline: IVideoProjectTimeline,
    action: ITimelineAction,
    text: string
  ) =>
    requests.post(`/video-projects/${website._id}/${videoProject._id}/${timeline._id}/${action._id}/reply`, { text }),
  deleteComment: (
    website: IWebsite,
    videoProject: IVideoProject,
    timeline: IVideoProjectTimeline,
    action: ITimelineAction
  ) => requests.del(`/video-projects/${website._id}/${videoProject._id}/${timeline._id}/${action._id}`),
  deleteCommentReply: (
    website: IWebsite,
    videoProject: IVideoProject,
    timeline: IVideoProjectTimeline,
    action: ITimelineAction,
    replyId: string
  ) =>
    requests.del(`/video-projects/${website._id}/${videoProject._id}/${timeline._id}/${action._id}/reply/${replyId}`),
};

const Pages = {
  getLinkingPages: (websiteId: string, pageId: string) =>
    requests.get(`/pages/${websiteId}/page/${pageId}/linking-pages`),
  getPageClusters: (
    website: IWebsite,
    page: number,
    limit: number,
    state: ePageState[],
    sort: string,
    sortDirection: string | number,
    postTypes?: string[],
    searchText?: string
  ) =>
    requests.get(
      `/pages/${website._id}/cluster/all?page=${page}&limit=${limit}&state=${state.join(
        ","
      )}&sort=${sort}&sortDirection=${sortDirection}&postTypes=${postTypes.join(",")}&searchText=${searchText}`
    ),
  getPages: (website: IWebsite, page: number, limit: number, state: ePageState[], sort: string, postTypes = ["All"]) =>
    requests.get(
      `/pages/${website._id}/page/all?page=${page}&limit=${limit}&state=${state.join(
        ","
      )}&sort=${sort}&postTypes=${postTypes.join(",")}`
    ),
  getPage: (website: IWebsite, id: string) => requests.get(`/pages/${website._id}/page/${id}`),
  getVerticalPage: (website: IWebsite, type: string, locale: string) =>
    requests.get(`/pages/${website._id}/vertical/post/${type}?locale=${locale}`),
  savePage: (website: IWebsite, page: IPage, action: ePageAction, subAction?: string) =>
    requests.post(`/pages/${website._id}/page/${action}${subAction ? `?subAction=${subAction}` : ""}`, page),
  invalidateCache: (website: IWebsite, url: string) =>
    requests.get(`/pages/${website._id}/page/invalidate/cache?url=${url}`),
  deleteForever: (pageId: string) => requests.del(`/pages/${pageId}/forever`),
  discardPage: (page: IPage) => requests.post(`/pages/${page._id}/writer/discard`, {}),
  selectPage: (page: IPage) => requests.post(`/pages/${page._id}/writer/select`, {}),
  getWriterItems: (
    website: IWebsite,
    writer: IAdminUser,
    page: number,
    limit: number,
    sort: string,
    sortDirection: number | string,
    postTypes?: ePageType[],
    postCategories?: string[],
    searchText?: string
  ) =>
    requests.get(
      `/pages/${website._id}/writer/${
        writer._id
      }/all?page=${page}&limit=${limit}&sort=${sort}&sortDirection=${sortDirection}${
        postTypes ? `&postTypes=${postTypes.join(",")}` : ""
      }${postCategories ? `&postCategories=${postCategories.join(",")}` : ""}${
        searchText ? `&searchText=${searchText}` : ""
      }`
    ),
  getEditItems: (
    website: IWebsite,
    writer: IAdminUser,
    page: number,
    limit: number,
    sort: string,
    sortDirection: string | number,
    postTypes?: ePageType[],
    postCategories?: string[],
    searchText?: string
  ) =>
    requests.get(
      `/pages/${website._id}/edit/all?page=${page}&limit=${limit}&sort=${sort}&sortDirection=${sortDirection}${
        postTypes ? `&postTypes=${postTypes.join(",")}` : ""
      }${postCategories ? `&postCategories=${postCategories.join(",")}` : ""}${
        searchText ? `&searchText=${searchText}` : ""
      } `
    ),
  selectEdit: (page: IPage) => requests.post(`/pages/${page._id}/editor/select`, {}),
  discardEdit: (page: IPage) => requests.post(`/pages/${page._id}/editor/discard`, {}),

  getSecondEditItems: (
    website: IWebsite,
    writer: IAdminUser,
    page: number,
    limit: number,
    sort: string,
    sortDirection: string | number,
    postTypes?: ePageType[],
    postCategories?: string[],
    searchText?: string
  ) =>
    requests.get(
      `/pages/${website._id}/secondEditor/all?page=${page}&limit=${limit}&sort=${sort}&sortDirection=${sortDirection}${
        postTypes ? `&postTypes=${postTypes.join(",")}` : ""
      }${postCategories ? `&postCategories=${postCategories.join(",")}` : ""}${
        searchText ? `&searchText=${searchText}` : ""
      } `
    ),
  selectSecondEdit: (page: IPage) => requests.post(`/pages/${page._id}/secondEditor/select`, {}),
  discardSecondEdit: (page: IPage) => requests.post(`/pages/${page._id}/secondEditor/discard`, {}),

  getReviewStepItems: (
    website: IWebsite,
    step: string,
    writer: IAdminUser,
    page: number,
    limit: number,
    sort: string,
    direction: string,
    postTypes?: ePageType[],
    postCategories?: string[],
    searchText?: string
  ) =>
    requests.get(
      `/pages/${website._id}/review/${step}/all?page=${page}&limit=${limit}&sort=${sort}&sortDirection=${direction}${
        postTypes ? `&postTypes=${postTypes.join(",")}` : ""
      }${postCategories ? `&postCategories=${postCategories.join(",")}` : ""}${
        searchText ? `&searchText=${searchText}` : ""
      }`
    ),
  selectReviewStepChecker: (page: IPage, step: string) => requests.post(`/pages/${page._id}/review/${step}/select`, {}),
  discardReviewStepChecker: (page: IPage, step: string) =>
    requests.post(`/pages/${page._id}/review/${step}/discard`, {}),
  reviewStepCheckerTakeOver: (pageId: string, step: string) =>
    requests.post(`/pages/${pageId}/review/${step}/takeOver`, {}),

  getFactCheckItems: (
    website: IWebsite,
    writer: IAdminUser,
    page: number,
    limit: number,
    sort: string,
    direction: string,
    postTypes?: ePageType[],
    postCategories?: string[],
    searchText?: string
  ) =>
    requests.get(
      `/pages/${
        website._id
      }/factChecker/review/all?page=${page}&limit=${limit}&sort=${sort}&sortDirection=${direction}${
        postTypes ? `&postTypes=${postTypes.join(",")}` : ""
      }${postCategories ? `&postCategories=${postCategories.join(",")}` : ""}${
        searchText ? `&searchText=${searchText}` : ""
      }`
    ),
  selectFactChecker: (page: IPage) => requests.post(`/pages/${page._id}/factChecker/select`, {}),
  discardFactChecker: (page: IPage) => requests.post(`/pages/${page._id}/factChecker/discard`, {}),
  factCheckerTakeOver: (pageId: string) => requests.post(`/pages/${pageId}/factChecker/takeOver`, {}),
  getPublishedClusters: (
    website: IWebsite,
    page: number,
    limit: number,
    state: ePageState[],
    sort: string,
    direction: string,
    postTypes?: string[],
    postCategories?: string[],
    publishStatus?: string,
    searchText?: string
  ) =>
    requests.get(
      `/pages/${website._id}/cluster/published/all?page=${page}&limit=${limit}&state=${state.join(",")}${
        postCategories ? `&postCategories=${postCategories.join(",")}` : ""
      }&sort=${sort}&sortDirection=${direction}&postTypes=${postTypes.join(",")}${
        publishStatus ? `&publishStatus=${publishStatus}` : ""
      }&searchText=${searchText}`
    ),

  getPublishedItems: (
    website: IWebsite,
    writer: IAdminUser,
    page: number,
    limit: number,
    sort: string,
    sortDirection: string | number,
    postTypes?: ePageType[],
    postCategories?: string[],
    searchText?: string,
    publishStatus?: string
  ) =>
    requests.get(
      `/pages/${website._id}/published/all?page=${page}&limit=${limit}&sort=${sort}&sortDirection=${sortDirection}${
        postTypes ? `&postTypes=${postTypes.join(",")}` : ""
      }${postCategories ? `&postCategories=${postCategories.join(",")}` : ""}${
        publishStatus ? `&publishStatus=${publishStatus}` : ""
      }&searchText=${searchText}`
    ),
  createPage: (
    clusterId: string,
    locale: string,
    website: string,
    title: string,
    variant: string,
    type: string,
    dueDate,
    quotaDate,
    richText: string = null,
    sourceFileId: string = null,
    categories = [],
    showTOC = false,
    topics = []
  ) =>
    requests.post("/pages", {
      clusterId,
      title,
      locale,
      variant,
      dueDate,
      quotaDate,
      type,
      website,
      richText,
      sourceFileId,
      categories,
      showTOC,
      topics,
    }),
  writerTakeOver: (pageId: string) => requests.post(`/pages/${pageId}/writer/takeOver`, {}),
  editorTakeOver: (pageId: string) => requests.post(`/pages/${pageId}/editor/takeOver`, {}),
  secondEditorTakeOver: (pageId: string) => requests.post(`/pages/${pageId}/secondEditor/takeOver`, {}),
  updateFields: (pageId, state, fields) => requests.post(`/pages/${pageId}/${state}/update/fields`, fields),
  updateRichText: (pageId, richText) => requests.post(`/pages/${pageId}/update/richText`, { richText }),
  updatePostType: (pageId, type) => requests.post(`/pages/${pageId}/vertical/${type}`, {}),
  searchPublished: (websiteId: string, searchTex: string, type = undefined) =>
    requests.get(`/pages/${websiteId}/page/published/search?searchText=${searchTex}${type ? `&type=${type}` : ""}`),
};
const LinksSplitTest = {
  getTests: (websiteId) => requests.get(`/linksSplitTest/${websiteId}/all`),
  getTest: (websiteId, testId: string) => requests.get(`/linksSplitTest/${websiteId}/${testId}`),
  upsert: (websiteId, test: ILinksSplitTest) => requests.post(`/linksSplitTest/${websiteId}/upsert`, test),
  deleteTest: (websiteId, test) => requests.del(`/linksSplitTest/${websiteId}/test/${test._id}`),
};

const Analytics = {
  getEventNames: (websiteId) => requests.get(`/analytics/${websiteId}/events/names`),
  getEvents: (websiteId, data, page = 1, limit = 100) =>
    requests.post(`/analytics/${websiteId}/events?page=${page}&limit=${limit}`, data),
  getUserEvents: (websiteId, distinctId) => requests.get(`/analytics/${websiteId}/user/events/${distinctId}`),
  getPageFunnelEvents: (websiteId, startDate) =>
    requests.get(`/reports/${websiteId}/conversion-funnel?startDate=${startDate}`),
};

const LandingPages = {
  getAll: (websiteId: string, page, limit, searchText?: string) =>
    requests.get(
      `/landingPages/${websiteId}/all?page=${page}&limit=${limit}&${searchText ? `searchText=${searchText}` : ""}`
    ),
  getLandingPage: (landingPageId: string) => requests.get(`/landingPages/landingPage/${landingPageId}`),
  createLandingPage: (name: string, type: string, websiteId: string) =>
    requests.post("/landingPages", { name, type, websiteId }),
  updateLandingPage: (websiteId: string, action: ePageAction, landingPage: any) =>
    requests.post(`/landingPages/${websiteId}/landingPage/${action}`, landingPage),
  deleteLandingPage: (websiteId: string, landingPageId: string) =>
    requests.del(`/landingPages/${websiteId}/${landingPageId}`),
};

const Serp = {
  getSerpData: (keywordGroupId: string) => requests.get(`/serp/?keywordGroupId=${keywordGroupId}`),
  create: (keywordGroupId: string) => requests.post("/serp/create", { keywordGroupId }),
  updateSerpData: (serpData: Array<ISerpData>) => requests.put("/serp/update", { serpData }),
};

const NI = {
  getPageClusterPublishStatus: (cluster: IPageCluster) => requests.get(`/ni/publish-status/${cluster._id}`),
  getAuthorStatus: (bio: IWriterBio) => requests.get(`/ni/publish-status/bio/${bio._id}`),
};

const Indexing = {
  quota: (website: IWebsite) => requests.get(`/indexing/${website._id}/quota`),
  indexUrls: (website: IWebsite, urls: string[]) => requests.post(`/indexing/${website._id}/urls`, { urls }),
};

const Cache = {
  cloudFrontInvalidation: (website: IWebsite, rules: string[]) =>
    requests.post(`/cache/${website._id}/cloudfront/invalidation`, rules),
  articleInvalidation: (website: IWebsite, page: IPage) =>
    requests.get(`/cache/${website._id}/invalidate/article/${page._id}`),
  questionInvalidation: (website: IWebsite, question: IQuestion) =>
    requests.get(`/cache/${website._id}/invalidate/question/${question._id}`),
};

const SourceFiles = {
  get: (website: IWebsite, sourceFileId: string) => requests.get(`/source-files/${website._id}/${sourceFileId}`),
  list: (website: IWebsite, page, limit, searchText) =>
    requests.get(
      `/source-files/${website._id}/list?page=${page}&limit=${limit}${searchText ? `&searchText=${searchText}` : ""}`
    ),
  getTranscription: (website: IWebsite, sourceFile: ISourceFile, json = false, maxWordsPerPart = null) =>
    requests.get(
      `/source-files/${website._id}/${sourceFile._id}/transcription?json=${json}${
        maxWordsPerPart ? `&maxWords=${maxWordsPerPart}` : ""
      }`
    ),
  deleteSourceFile: (website: IWebsite, sourceFile: ISourceFile) =>
    requests.del(`/source-files/${website._id}/${sourceFile._id}`),
  rephrase: (website: IWebsite, sourceFileId: string, body) =>
    requests.post(`/source-files/${website._id}/${sourceFileId}/rephrase/chatGPT`, body),
  editSourceFile: (website: IWebsite, sourceFileId: string, historyId?: string, body) =>
    requests.put(`/source-files/${website._id}/${sourceFileId}/${historyId || ""}`, body),
  deleteHistory: (website: IWebsite, sourceFileId: string, historyId) =>
    requests.del(`/source-files/${website._id}/${sourceFileId}/history/${historyId}`),
};

const SourceFileUploader = {
  createMultipart: (website: IWebsite, body) =>
    requests.post(`/source-file-uploader/${website._id}/create-multipart`, body),
  uploadPart: (website: IWebsite, body) => requests.post(`/source-file-uploader/${website._id}/upload-part`, body),
  completeMultipart: (website: IWebsite, body) =>
    requests.post(`/source-file-uploader/${website._id}/complete-multipart`, body),
  getPresignedUrl: (website: IWebsite, body) =>
    requests.post(`/source-file-uploader/${website._id}/get-presigned-url`, body),
  abortMultipart: (website: IWebsite, body) =>
    requests.post(`/source-file-uploader/${website._id}/abort-multipart`, body),
};

const VideoProjectFileUploader = {
  createMultipart: (videoProject: IVideoProject, website: IWebsite, body) =>
    requests.post(`/video-project-file-uploader/${website._id}/${videoProject._id}/create-multipart`, body),
  uploadPart: (website: IWebsite, body) =>
    requests.post(`/video-project-file-uploader/${website._id}/upload-part`, body),
  completeMultipart: (videoProject: IVideoProject, website: IWebsite, body) =>
    requests.post(`/video-project-file-uploader/${website._id}/${videoProject._id}/complete-multipart`, body),
  getPresignedUrl: (website: IWebsite, body) =>
    requests.post(`/video-project-file-uploader/${website._id}/get-presigned-url`, body),
  abortMultipart: (website: IWebsite, body) =>
    requests.post(`/video-project-file-uploader/${website._id}/abort-multipart`, body),
};

const Proposals = {
  getProposal: (website: IWebsite) => requests.get(`/proposal/${website._id}/proposal`),
  update: (website: IWebsite, proposal) => requests.post(`/proposal/${website._id}/update`, proposal),
};

const Reports = {
  getSearchConsoleData: ({ website, startDate, refreshCache, timeFrame, pageFilter }: IReports["search-console"]) =>
    requests.get(
      `/reports/${website._id}/search-console/?${startDate ? `startDate=${startDate}` : ""}${
        timeFrame ? `&timeFrame=${timeFrame}` : ""
      }${pageFilter ? `&pageFilter=${pageFilter}` : ""}`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),
  getIndexedQueriesData: ({ website, startDate, refreshCache, timeFrame, pageFilter }: IReports["indexed-queries"]) =>
    requests.get(
      `/reports/${website._id}/indexed-queries/?${startDate ? `startDate=${startDate}` : ""}${
        timeFrame ? `&timeFrame=${timeFrame}` : ""
      }${pageFilter ? `&pageFilter=${pageFilter}` : ""}`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),
  getArticleCount: ({ website, startDate, refreshCache }: IReports["article-count"]) =>
    requests.get(
      `/reports/${website._id}/article-count/?startDate=${startDate}`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),
  getWordsCountData: ({ website, startDate, refreshCache }: IReports["words-count"]) =>
    requests.get(
      `/reports/${website._id}/words-count/?startDate=${startDate}`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),
  getCtaOriginData: ({
    website,
    startDate,
    timeFrame,
    pageFilter,
    reportType = "cta-clicks",
    refreshCache,
  }: IReports["cta-origin"]) =>
    requests.get(
      `/reports/${website._id}/cta-origin/?${startDate ? `startDate=${startDate}` : ""}${
        timeFrame ? `&timeFrame=${timeFrame}` : ""
      }${pageFilter ? `&pageFilter=${pageFilter}` : ""}${reportType ? `&reportType=${reportType}` : ""}`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),

  actionsQueryBuilder:
    (reportType: "cta-clicks" | "internal-link-click" | "outbound-link-click") =>
    ({ website, startDate, refreshCache, timeFrame, pageFilter }: IReports["cta-clicks"]) =>
      requests.get(
        `/reports/${website._id}/actions/${reportType.toLowerCase()}/?${startDate ? `startDate=${startDate}` : ""}${
          timeFrame ? `&timeFrame=${timeFrame}` : ""
        }${pageFilter ? `&pageFilter=${pageFilter}` : ""}`,
        {},
        {
          "no-cache": refreshCache ? true : undefined,
        }
      ),

  getTrafficTrendData: ({ website, startDate, refreshCache, pageFilter }: IReports["traffic-trend"]) =>
    requests.get(
      `/reports/${website._id}/traffic-trend/?${startDate ? `startDate=${startDate}` : ""}${
        pageFilter ? `&pageFilter=${pageFilter}` : ""
      }`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),
  getTopPages: ({ website, startDate, refreshCache, pageFilter, metric }: IReports["top-pages"]) =>
    requests.get(
      `/reports/${website._id}/top-pages/?${startDate ? `startDate=${startDate}` : ""}${
        pageFilter ? `&pageFilter=${pageFilter}` : ""
      }${metric && metric !== "all" ? `&metric=${metric}` : ""}`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),
  getTopTopics: ({ website, startDate, refreshCache, pageFilter, metric }: IReports["top-pages"]) =>
    requests.get(
      `/reports/${website._id}/top-topics/?${startDate ? `startDate=${startDate}` : ""}${
        pageFilter ? `&pageFilter=${pageFilter}` : ""
      }${metric && metric !== "all" ? `&metric=${metric}` : ""}`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),
  getTopQueries: ({ website, startDate, refreshCache, pageFilter, metric }: IReports["top-queries"]) =>
    requests.get(
      `/reports/${website._id}/top-query/?${startDate ? `startDate=${startDate}` : ""}${
        pageFilter ? `&pageFilter=${pageFilter}` : ""
      }${metric && metric !== "all" ? `&metric=${metric}` : ""}`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),
  getCtaByUrl: ({
    website,
    startDate,
    refreshCache,
    reportType = "cta-clicks",
    pageFilter,
    timeFrame,
  }: IReports["cta-by-url"]) =>
    requests.get(
      `/reports/${website._id}/cta-url?${startDate ? `startDate=${startDate}` : ""}${
        pageFilter ? `&pageFilter=${pageFilter}` : ""
      }${timeFrame ? `&timeFrame=${timeFrame}` : ""}${reportType ? `&reportType=${reportType}` : ""}`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),
  getCtaByDestination: ({
    website,
    startDate,
    refreshCache,
    reportType = "cta-clicks",
    pageFilter,
    timeFrame,
  }: IReports["cta-by-destination"]) =>
    requests.get(
      `/reports/${website._id}/cta-destination?${startDate ? `startDate=${startDate}` : ""}${
        pageFilter ? `&pageFilter=${pageFilter}` : ""
      }${timeFrame ? `&timeFrame=${timeFrame}` : ""}${reportType ? `&reportType=${reportType}` : ""}`,
      {},
      {
        "no-cache": refreshCache ? true : undefined,
      }
    ),
  getCustomReport: ({ id, refreshCache }: IReports["custom"]) =>
    requests.get(
      `/reports/custom/report/${id}`,
      {},
      {
        "no-cache": true,
      }
    ),
  getPageEvents: ({
    website,
    startDate,
    breakdownValue,
    timeFrame,
    events,
    column,
    page,
    limit,
    table = "events",
    countFilter = "total",
  }: IReports["page-events"]) => {
    return requests.get(
      `/reports/${website._id}/page-events?${startDate ? `startDate=${startDate}` : ""}${
        breakdownValue ? `&breakdownValue=${breakdownValue}` : ""
      }${timeFrame ? `&timeFrame=${timeFrame}` : ""}${events ? `&events=${events}` : ""}${
        column ? `&column=${column}` : ""
      } ${page ? `&page=${page}` : ""}${limit ? `&limit=${limit}` : ""}&table=${table} &countFilter=${countFilter}`
    );
  },

  getPageEventBreakdown: ({
    website,
    startDate,
    breakdownValue,
    timeFrame,
    events,
    event,
    table = "e",
    column = "url",
    page,
    limit,
    countFilter = "total",
  }: any) => {
    return requests.get(
      `/reports/${website._id}/page-events/breakdown/event/${event}?${startDate ? `startDate=${startDate}` : ""}${
        breakdownValue ? `&breakdownValue=${breakdownValue}` : ""
      }${timeFrame ? `&timeFrame=${timeFrame}` : ""}${events ? `&events=${events}` : ""}${page ? `&page=${page}` : ""}${
        limit ? `&limit=${limit}` : ""
      }&table=${table}&column=${column}&countFilter=${countFilter}`
    );
  },
};

const GoalCompletionReports = {
  getGoalCompletionsByPage: (
    website,
    startDate,
    timeFrame,
    columnTable,
    columnName,
    columnValue,
    page,
    limit,
    countFilter = "total",
    sort = "total_value",
    sortDirection = "desc"
  ) => {
    return requests.get(
      `/goal-completion-reports/${website._id}/goal-completion-by-page?${startDate ? `startDate=${startDate}` : ""}${
        timeFrame ? `&timeFrame=${timeFrame}` : ""
      }${page ? `&page=${page}` : ""}${
        limit ? `&limit=${limit}` : ""
      }&countFilter=${countFilter}&sort=${sort}&sortDirection=${sortDirection}${
        columnTable ? `&columnTable=${columnTable}` : ""
      }${columnName ? `&columnName=${columnName}` : ""}${columnValue ? `&columnValue=${columnValue}` : ""}`
    );
  },
  getGoalCompletionsByPageExpanded: (website, pageUrl, timeFrame, dateTrunc, columnTable, columnName, columnValue) => {
    return requests.get(
      `/goal-completion-reports/${
        website._id
      }/goal-completion-by-page/expanded?timeFrame=${timeFrame}&pageUrl=${pageUrl}&dateTrunc=${dateTrunc}${
        columnTable ? `&columnTable=${columnTable}` : ""
      }${columnName ? `&columnName=${columnName}` : ""}${columnValue ? `&columnValue=${columnValue}` : ""}`
    );
  },
};

const ReportsSettings = {
  get: (website: IWebsite) => requests.get(`/reportsSettings/${website._id}/reports-settings/`),
  update: (website: IWebsite, reportsSettings) =>
    requests.post(`/reportsSettings/${website._id}/update`, reportsSettings),
  updateCustomReport: (website: IWebsite, reportId: string, customReport) =>
    requests.put(`/reportsSettings/${website._id}/customReport/${reportId}`, customReport),
};

const WebsiteGoals = {
  get: (website: IWebsite) => requests.get(`/websiteGoals/${website._id}`),
  update: (id: string, data) => requests.put(`/websiteGoals/${id}`, data),
  delete: (id: string) => requests.del(`/websiteGoals/${id}`),
};

const HeadingCluster = {
  get: (website: IWebsite, isRaw = true, searchText = null, topicCount = null, page = 1, limit = 20) =>
    requests.get(
      `/keywordHeadingCluster/${website._id}?page=${page}&limit=${limit}&isRaw=${isRaw}${
        searchText ? `&text=${searchText}` : ""
      }${topicCount ? `&topicCount=${topicCount}` : ""}`
    ),
};

const Funnels = {
  getColumns: (table) => requests.get(`/funnels/${table}/names`),
  getDynamicColumns: (selectedWebsite: IWebsite, eventName: string) =>
    requests.get(`/funnels/${selectedWebsite._id}/eventData/keys?eventName=${eventName}`),
  getColumnValues: (selectedWebsite: IWebsite, table, column, searchText, page, limit = 20) =>
    requests.get(
      `/funnels/${selectedWebsite._id}/${table}/${column}/values?page=${page}${
        searchText ? `&searchText=${searchText}` : ""
      }${limit ? `&limit=${limit}` : ""}`
    ),
  getFunnel: (selectedWebsite: IWebsite, data, page = 0, limit = 100, searchText = null) =>
    requests.post(
      `/funnels/${selectedWebsite._id}/funnel?page=${page}${limit ? `&limit=${limit}` : ""}${
        searchText ? `&searchText=${searchText}` : ""
      }`,
      data
    ),
  saveFunnel: (selectedWebsite: IWebsite, data) => requests.post(`/funnels/${selectedWebsite._id}/save/funnel`, data),
  getFunnels: (selectedWebsite: IWebsite, page, limit = 20) =>
    requests.get(`/funnels/${selectedWebsite._id}/all?page=${page}${limit ? `&limit=${limit}` : ""}`),
  fetchFunnel: (selectedWebsite: IWebsite, funnelId) =>
    requests.get(`/funnels/${selectedWebsite._id}/funnel/${funnelId}`),
  updateFunnel: (funnelId, data) => requests.put(`/funnels/funnel/${funnelId}`, data),
  deleteFunnel: (funnelId) => requests.del(`/funnels/funnel/${funnelId}`),
};

const EditorAssistant = {
  assistant: (website: IWebsite, data) => requests.post(`/editorAssistant/${website._id}/assistant`, data),
};

const Embeds = {
  getMetaData: (videoId: string, contentUrl: string, type: string) =>
    requests.post(`/embeds/meta-data`, { videoId, contentUrl, type }),
};

export default {
  Auth,
  Clusters,
  Ads,
  Tags,
  SEOKeywords,
  Keywords,
  Learn,
  Posts,
  Questions,
  Community,
  User,
  Content,
  Files,
  ContactUserTopics,
  Websites,
  Statistics,
  Sources,
  Insights,
  Admin,
  websitePages,
  Writers,
  Review,
  EditPost,
  Workers,
  Items,
  Counters,
  Categories,
  Product,
  Goal,
  Audiences,
  Analysis,
  Redirects,
  Orders,
  EditorialPolicy,
  Bios,
  SlateEditor,
  Outlines,
  TopicGroup,
  Pages,
  LandingPages,
  Seeds,
  Experts,
  ExcludedKeywordSeeds,
  KeywordsClassification,
  DataTitles,
  DataPages,
  Serp,
  NI,
  Indexing,
  Cache,
  Widgets,
  Campaigns,
  KeywordTypes,
  KeywordStrategy,
  KeywordGroupPage,
  KeywordGroups,
  Airflow,
  SourceFiles,
  SourceFileUploader,
  VideoProjectFileUploader,
  Proposals,
  Reports,
  ReportsSettings,
  WebsiteGoals,
  HeadingCluster,
  EditorAssistant,
  OutlinePages,
  QueryGroups,
  Embeds,
  AutoVideoInterview,
  LinksSplitTest,
  VideoProjects,
  Analytics,
  Funnels,
  Goals,
  Organizations,
  GoalCompletionReports,
};
