import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useParams } from "react-router";
import CfProgressBar from "../../../../../components/CfProgressBar";
import { IWebsite, IWriterBio } from "../../../../../reducers/constants/objectTypes";
import { WRITER_BIO } from "../../../../../reducers/constants/actionTypes";
import PageTopSection from "./components/PageTopSection";
import StatusSection from "./components/StatusSection";
import agent from "../../../../../agent";
import BioPreviewSection from "./components/BioPreviewSection";
import ExpertiseSection from "./components/ExpertiseSection";
import QuoteSection from "./components/QuoteSection";
import HighlightsSection from "./components/HighlightsSection";
import WriterBioBottomBar from "../components/WriterBioBottomBar";
import BioUploadStatus from "./components/BioUploadStatus";

const useStyles = makeStyles((theme: Theme) => ({
  mainBioWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    maxWidth: 1000,
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    overflowY: "auto",
  },
  pageLoader: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  detailsWrapper: {
    width: "100%",
    padding: "10px 30px 0 30px",
    maxWidth: 900,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  selectedBioLoaded: boolean;
  selectedBio: IWriterBio;

  handleBioLoaded: (payload) => void;
};

const mapStateToProps = (state) => ({
  audiences: state.audiences.audiences,
  selectedWebsite: state.home.selectedWebsite,
  selectedBioLoaded: state.writerBio.selectedBioLoaded,
  selectedBio: state.writerBio.selectedBio,
});

const mapDispatchToProps = (dispatch) => ({
  handleBioLoaded: (payload) => dispatch({ type: WRITER_BIO.ON_BIO_LOADED, payload }),
});

const BioDetails = (props: PropTypes) => {
  const classes = useStyles(props);
  const { writerId } = useParams();
  const { selectedWebsite, selectedBio, selectedBioLoaded } = props;
  const { handleBioLoaded } = props;

  React.useEffect(() => {
    if (!writerId || !selectedWebsite) {
      return;
    }

    handleBioLoaded(agent.Bios.getByWebsiteAndWriter(selectedWebsite._id, writerId));
  }, [handleBioLoaded, selectedWebsite, writerId]);

  return (
    <div key={selectedBio?._id} className={classes.mainBioWrapper}>
      <div className={classes.wrapper}>
        {!selectedBioLoaded && (
          <div className={classes.pageLoader}>
            <CfProgressBar />
          </div>
        )}
        {selectedBioLoaded && (
          <div className={classes.detailsWrapper}>
            <StatusSection />
            <PageTopSection />
            <BioPreviewSection />
            <HighlightsSection />
            <ExpertiseSection />
            <QuoteSection />
          </div>
        )}
        {selectedBioLoaded && <WriterBioBottomBar />}
      </div>
      <BioUploadStatus bio={selectedBio} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BioDetails);
