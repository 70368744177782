import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import ClassNames from "classnames";
import { IExpert, IWebsite } from "../../../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import ExpertImagePicker from "./ExpertImagePicker";
import ExpertExperience from "./ExpertExperience";
import ExpertExpertise from "./ExpertExpertise";
import ExpertCollapsibleCard from "./ExpertCollapsibleCard";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  textFieldTitle: {
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  rowWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: 20,
    alignItems: "flex-end",
    "&:first-child": {
      alignItems: "center",
    },
  },
  rowGaps: {
    gap: 10,
  },
  textFieldWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fieldTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  fieldTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    minWidth: 140,
    marginRight: 15,
  },
  profilePicWrapper: {
    marginRight: 40,
  },
  divider: {
    marginLeft: -30,
    marginRight: -30,
    marginTop: 20,
    marginBottom: 20,
  },
  noTransform: {
    textTransform: "none",
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  expert: IExpert;

  handleExpertChange: (expert: IExpert) => void;
};

const ProfileInfo = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, expert, handleExpertChange } = props;

  return (
    <ExpertCollapsibleCard title={I18n.t("experts.profile.profile_info")}>
      <div className={classes.root}>
        <div className={classes.rowWrapper}>
          <div className={classes.profilePicWrapper}>
            <ExpertImagePicker
              key={`profile_pic`}
              title={I18n.t("experts.profile.profile_pic")}
              showSizes
              selectedWebsite={selectedWebsite}
              image={expert?.profilePic}
              imageWidth={500}
              imageHeight={500}
              onImageChanged={(file) => {
                handleExpertChange({ ...expert, profilePic: file });
              }}
            />
          </div>
          <div className={classes.textFieldWrapper}>
            <TextFieldMaxLength
              titleAndLengthLocation={"top"}
              key={`expert_first_name`}
              titleClassName={classes.textFieldTitle}
              value={expert.firstName}
              placeholder={I18n.t("experts.profile.write_ph")}
              title={I18n.t("experts.profile.first_name")}
              borderRadius={4}
              handleValueChange={(firstName) => {
                handleExpertChange({ ...expert, firstName });
              }}
            />

            <TextFieldMaxLength
              titleClassName={classes.textFieldTitle}
              key={`expert_last_name`}
              titleAndLengthLocation={"top"}
              value={expert.lastName}
              marginTop={20}
              placeholder={I18n.t("experts.profile.write_ph")}
              title={I18n.t("experts.profile.last_name")}
              borderRadius={4}
              handleValueChange={(lastName) => {
                handleExpertChange({ ...expert, lastName });
              }}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <TextFieldMaxLength
            titleClassName={classes.noTransform}
            key={`expert_job_title`}
            titleAndLengthLocation={"top"}
            value={expert.jobTitle}
            placeholder={I18n.t("experts.profile.write_ph")}
            title={I18n.t("experts.profile.job_title_current")}
            borderRadius={4}
            marginTop={20}
            handleValueChange={(jobTitle) => {
              handleExpertChange({ ...expert, jobTitle });
            }}
          />
          <ExpertExperience
            title={I18n.t("experts.profile.experience")}
            experience={expert.experienceInYears}
            handleExperienceChange={(experienceInYears) => handleExpertChange({ ...expert, experienceInYears })}
          />
        </div>
        <div className={ClassNames(classes.rowWrapper)}>
          <TextFieldMaxLength
            titleClassName={classes.textFieldTitle}
            key={`expert_company_name`}
            titleAndLengthLocation={"top"}
            value={expert.companyName}
            marginTop={20}
            placeholder={I18n.t("experts.profile.write_ph")}
            title={I18n.t("experts.profile.company_name")}
            borderRadius={4}
            handleValueChange={(companyName) => {
              handleExpertChange({ ...expert, companyName });
            }}
          />
          <TextFieldMaxLength
            titleClassName={classes.textFieldTitle}
            key={`expert_company_url`}
            titleAndLengthLocation={"top"}
            value={expert.companyURL}
            marginTop={20}
            placeholder={I18n.t("experts.profile.write_ph")}
            title={I18n.t("experts.profile.company_url")}
            borderRadius={4}
            handleValueChange={(companyURL) => {
              handleExpertChange({ ...expert, companyURL });
            }}
          />
        </div>
        <div className={classes.rowWrapper}>
          <TextFieldMaxLength
            titleClassName={classes.textFieldTitle}
            key={`expert_location`}
            titleAndLengthLocation={"top"}
            value={expert.location}
            marginTop={20}
            placeholder={I18n.t("experts.profile.location_ph")}
            title={I18n.t("experts.profile.location")}
            borderRadius={4}
            handleValueChange={(location) => {
              handleExpertChange({ ...expert, location });
            }}
          />
        </div>
        <Divider className={classes.divider} />
        <div className={ClassNames(classes.rowWrapper)}>
          <TextFieldMaxLength
            key={`expert_short_description`}
            titleClassName={classes.textFieldTitle}
            titleAndLengthLocation={"top"}
            value={expert.shortDescription}
            multiLine
            height={120}
            placeholder={I18n.t("experts.profile.write_ph")}
            title={I18n.t("experts.profile.short_description")}
            borderRadius={4}
            maxLength={350}
            handleValueChange={(shortDescription) => {
              handleExpertChange({ ...expert, shortDescription });
            }}
          />
        </div>
        <Divider className={classes.divider} />
        <div className={ClassNames(classes.rowWrapper)}>
          <ExpertExpertise
            selectedWebsite={selectedWebsite}
            expertise={expert.expertise}
            handleExpertiseChange={(expertise) => handleExpertChange({ ...expert, expertise })}
          />
        </div>
      </div>
    </ExpertCollapsibleCard>
  );
};

export default ProfileInfo;
