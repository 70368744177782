import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useLocation, matchPath } from "react-router";
import { replace } from "connected-react-router";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import { web } from "../../../helpers/urlHelper";
import GoalCompletionByPageReport from "./GoalCompletionByPageReport/GoalCompletionByPageReport";

type PropTypes = {
  selectedWebsite: IWebsite;
  returnTo?: string;
  hideHeader?: boolean;
  setReport: (reportUrl: string) => void;
  returnToPreviousScreen: (url: string) => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  setReport: (report) => dispatch(replace(report)),
  returnToPreviousScreen: (returnTo) => dispatch(replace(returnTo)),
});
const GoalCompletion = (props: PropTypes) => {
  const { selectedWebsite, returnTo, setReport, returnToPreviousScreen, hideHeader = false } = props;
  const location = useLocation();
  const match = matchPath(location.pathname, {
    path: web.croGoalCompletion(":reportType"),
    exact: false,
  });

  const reportType = useMemo(() => {
    return match?.params?.reportType || null;
  }, match);

  useEffect(() => {
    if (!reportType) {
      setReport(web.croGoalCompletion("goal-completion-by-page"));
    }
  }, reportType);

  console.log("reportType", reportType);
  if (reportType === "goal-completion-by-page") {
    return (
      <GoalCompletionByPageReport
        reportType={reportType}
        setReport={(reportType) => setReport(web.croGoalCompletion(reportType))}
      />
    );
  }

  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalCompletion);
