import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { IWebsite } from "../reducers/constants/objectTypes";
import * as React from "react";
import { I18n } from "react-redux-i18n";
import { HOME } from "../reducers/constants/actionTypes";
import { connect } from "react-redux";
import ClassNames from "classnames";
import { MIXPANEL_PAGE_VIEW } from "../reducers/middlewares/mixpanelMiddleware";

const useStyles = makeStyles((theme: Theme) => ({
  filled: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: 5,
    marginLeft: "auto",
    fontSize: theme.typography.pxToRem(14),
    color: "#fff",
    backgroundColor: "#6B5ED1",
    fontWeight: theme.typography.fontWeightBold as any,
    borderRadius: 20,
    height: 36,
    minHeight: 36,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  outlined: {
    fontSize: 14,
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.light,
    },
  },
}));

type PropTypes = {
  showAlways?: boolean;
  selectedWebsite: IWebsite;
  handleOnClick?: () => void;
  marginRight?: number;
  className?: string;
  outlined?: boolean;
  handlePropertyChange: (property: any) => void;
};
const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  handlePropertyChange: (property: any) => {
    dispatch({ type: HOME.ON_HOME_PROPERTY_CHANGED, property });
  },
});

const MAddToSiteButton = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    className,
    showAlways,
    selectedWebsite,
    marginRight = 0,
    outlined,
    handleOnClick,
    handlePropertyChange,
  } = props;
  if (!showAlways && selectedWebsite.configurations?.analytics?.entailInstalled) return null;

  return (
    <>
      <Typography
        style={{
          marginRight,
        }}
        className={ClassNames(outlined ? classes.outlined : classes.filled, className)}
        onClick={() => {
          if (handleOnClick) handleOnClick();
          handlePropertyChange({ showInstructionsDialog: true });
        }}
      >
        {I18n.t("widgets_display_rules_workflow.actions.add_script")}
      </Typography>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MAddToSiteButton);
