import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
import { Range, Transforms } from "slate";
import { I18n } from "react-redux-i18n";
import { useSlate } from "slate-react";
import { useState } from "react";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@material-ui/core/Typography";
import { find } from "lodash";
import MTextField from "../../../components/MTextField";
import MTextButton from "../../../components/MTextButton";
import CfButton from "../../../components/CfButton";
import EmbedIcon from "../toolbar/icons/EmbedIcon";
import RichTextEditorHelper from "../../helper/richTextEditorHelper";
import embed from "./embed";
import agent from "../../../agent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 999,
    },
    button: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      "&:hover": {
        fill: theme.palette.primary.main,
      },
    },
    activeIcon: {
      fill: theme.palette.primary.main,
    },
    paper: {
      padding: 20,
      width: 440,
    },
    videoDetailsWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    fieldLabel: {
      marginTop: 10,
      fontWeight: theme.typography.fontWeightBold as any,
      fontSize: theme.typography.pxToRem(12),
    },
    bottomWrapper: {
      height: 80,
      marginBottom: -20,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  })
);

const supportedTypes = [
  {
    type: "vimeo",
    regex: /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
    embedUrl: "https://player.vimeo.com/video/<%= remote_id %>?title=0&byline=0",
    // html: '<iframe style="width:100%;" height="320" frameborder="0"></iframe>',
    height: 320,
    width: 580,
  },
  {
    type: "youtube",
    regex:
      /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/,
    embedUrl:
      "https://www.youtube.com/embed/<%= remote_id %>?autoplay=0&controls=0&rel=0&modestbranding=1&playsinline=1",
    // html: '<iframe style="width:100%;" height="320" frameborder="0" allowfullscreen></iframe>',
    height: 320,
    width: 580,
    id: ([id, params]) => {
      if (!params && id) {
        return id;
      }

      const paramsMap = {
        start: "start",
        end: "end",
        t: "start",

        time_continue: "start",
        list: "list",
      };

      params = params
        .slice(1)
        .split("&")
        .map((param) => {
          const [name, value] = param.split("=");

          if (!id && name === "v") {
            id = value;

            return null;
          }

          if (!paramsMap[name]) {
            return null;
          }

          if (name === "list") {
            return null;
          }

          return `${paramsMap[name]}=${value}`;
        })
        .filter((param) => !!param);

      console.log("params", params);
      return `${id}${params && params.length > 0 ? `?${params.join("&")}` : ""}`;
    },
  },
  {
    type: "youtube_shorts",
    regex:
      /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com\/)(?:v\/|u\/\w\/|embed\/|watch\?v=|shorts\/))([^#&?=\/]*)(\/|\?|&|$)/,

    embedUrl: "https://www.youtube.com/embed/<%= remote_id %>",
    // html: '<iframe style="width:100%;" height="320" frameborder="0" allowfullscreen></iframe>',
    height: 706,
    width: 397,
    id: ([id, params]) => {
      console.log("id", id, params);
      if (!params && id) {
        return id;
      }

      const paramsMap = {
        start: "start",
        end: "end",
        t: "start",

        time_continue: "start",
        list: "list",
      };

      params = params
        .slice(1)
        .split("&")
        .map((param) => {
          const [name, value] = param.split("=");

          if (!id && name === "v") {
            id = value;

            return null;
          }

          if (!paramsMap[name]) {
            return null;
          }

          if (name === "list") {
            return null;
          }

          return `${paramsMap[name]}=${value}`;
        })
        .filter((param) => !!param);

      return `${id}?${params.join("&")}`;
    },
  },
  {
    type: "coub",
    regex: /https?:\/\/coub\.com\/view\/([^\/\?\&]+)/,
    embedUrl: "https://coub.com/embed/<%= remote_id %>",
    // html: '<iframe style="width:100%;" height="320" frameborder="0" allowfullscreen></iframe>',
    height: 320,
    width: 580,
  },
  {
    type: "vine",
    regex: /https?:\/\/vine\.co\/v\/([^\/\?\&]+)/,
    embedUrl: "https://vine.co/v/<%= remote_id %>/embed/simple/",
    // html: '<iframe style="width:100%;" height="320" frameborder="0" allowfullscreen></iframe>',
    height: 320,
    width: 580,
  },
  {
    type: "imgur",
    regex: /https?:\/\/(?:i\.)?imgur\.com.*\/([a-zA-Z0-9]+)(?:\.gifv)?/,
    embedUrl: "http://imgur.com/<%= remote_id %>/embed",
    // html: '<iframe allowfullscreen="true" scrolling="no" id="imgur-embed-iframe-pub-<%= remote_id %>" class="imgur-embed-iframe-pub" style="height: 500px; width: 100%; border: 1px solid #000"></iframe>',
    height: 500,
    width: 540,
  },
  {
    type: "gfycat",
    regex: /https?:\/\/gfycat\.com(?:\/detail)?\/([a-zA-Z]+)/,
    embedUrl: "https://gfycat.com/ifr/<%= remote_id %>",
    html: "<iframe frameborder='0' scrolling='no' style=\"width:100%;\" height='436' allowfullscreen ></iframe>",
    height: 436,
    width: 580,
  },
  {
    type: "twitch-channel",
    regex: /https?:\/\/www\.twitch\.tv\/([^\/\?\&]*)\/?$/,
    embedUrl: "https://player.twitch.tv/?channel=<%= remote_id %>",
    // html: '<iframe frameborder="0" allowfullscreen="true" scrolling="no" height="366" style="width:100%;"></iframe>',
    height: 366,
    width: 600,
  },
  {
    type: "twitch-video",
    regex: /https?:\/\/www\.twitch\.tv\/(?:[^\/\?\&]*\/v|videos)\/([0-9]*)/,
    embedUrl: "https://player.twitch.tv/?video=v<%= remote_id %>",
    // html: '<iframe frameborder="0" allowfullscreen="true" scrolling="no" height="366" style="width:100%;"></iframe>',
    height: 366,
    width: 600,
  },
  {
    type: "yandex-music-album",
    regex: /https?:\/\/music\.yandex\.ru\/album\/([0-9]*)\/?$/,
    embedUrl: "https://music.yandex.ru/iframe/#album/<%= remote_id %>/",
    // html: '<iframe frameborder=\"0\" style=\"border:none;width:540px;height:400px;\" style=\"width:100%;\" height=\"400\"></iframe>',
    height: 400,
    width: 540,
  },
  {
    type: "yandex-music-track",
    regex: /https?:\/\/music\.yandex\.ru\/album\/([0-9]*)\/track\/([0-9]*)/,
    embedUrl: "https://music.yandex.ru/iframe/#track/<%= remote_id %>/",
    // html: '<iframe frameborder="0" style="border:none;width:540px;height:100px;" style="width:100%;" height="100"></iframe>',
    height: 100,
    width: 540,
    id: (ids) => ids.join("/"),
  },
  {
    type: "yandex-music-playlist",
    regex: /https?:\/\/music\.yandex\.ru\/users\/([^\/\?\&]*)\/playlists\/([0-9]*)/,
    embedUrl: "https://music.yandex.ru/iframe/#playlist/<%= remote_id %>/show/cover/description/",
    // html: '<iframe frameborder="0" style="border:none;width:540px;height:400px;" width="540" height="400"></iframe>',
    height: 400,
    width: 540,
    id: (ids) => ids.join("/"),
  },
  {
    type: "codepen",
    regex: /https?:\/\/codepen\.io\/([^\/\?\&]*)\/pen\/([^\/\?\&]*)/,
    embedUrl: "https://codepen.io/<%= remote_id %>?height=300&theme-id=0&default-tab=css,result&embed-version=2",
    // html: '<iframe height=\'300\' scrolling=\'no\' frameborder=\'no\' allowtransparency=\'true\' allowfullscreen=\'true\' style=\'width: 100%;\'></iframe>',
    height: 300,
    width: 600,
    id: (ids) => ids.join("/embed/"),
  },
  {
    type: "instagram",
    regex: /https?:\/\/www\.instagram\.com\/p\/([^\/\?\&]+)\/?/,
    embedUrl: "https://www.instagram.com/p/<%= remote_id %>/embed",
    // html: '<iframe width="400" height="505" style="margin: 0 auto;" frameborder="0" scrolling="no" allowtransparency="true"></iframe>',
    height: 505,
    width: 400,
  },
  {
    type: "twitter",
    regex: /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+?.*)?$/,
    embedUrl: "https://twitframe.com/show?url=https://twitter.com/<%= remote_id %>",
    // html: '<iframe width="600" height="600" style="margin: 0 auto;" frameborder="0" scrolling="no" allowtransparency="true"></iframe>',
    height: 300,
    width: 600,
    id: (ids) => ids.join("/status/"),
  },
  {
    type: "pinterest",
    regex: /https?:\/\/([^\/\?\&]*).pinterest.com\/pin\/([^\/\?\&]*)\/?$/,
    embedUrl: "https://assets.pinterest.com/ext/embed.html?id=<%= remote_id %>",
    width: "100%",
    // html: '<iframe scrolling=\'no\' frameborder=\'no\' allowtransparency=\'true\' allowfullscreen=\'true\' style=\'width: 100%; min-height: 400px; max-height: 1000px;\'></iframe>',
    id: (ids) => ids[1],
  },
  {
    type: "facebook",
    regex: /https?:\/\/www.facebook.com\/([^\/\?\&]*)\/(.*)/,
    embedUrl: "https://www.facebook.com/plugins/post.php?href=https://www.facebook.com/<%= remote_id %>&width=500",
    width: "100%",
    // html: '<iframe scrolling=\'no\' frameborder=\'no\' allowtransparency=\'true\' allowfullscreen=\'true\' style=\'width: 100%; min-height: 500px; max-height: 1000px;\'></iframe>',
    id: (ids) => ids.join("/"),
  },
  {
    type: "aparat",
    regex: /(?:http[s]?:\/\/)?(?:www.)?aparat\.com\/v\/([^\/\?\&]+)\/?/,
    embedUrl: "https://www.aparat.com/video/video/embed/videohash/<%= remote_id %>/vt/frame",
    // html: '<iframe width="600" height="300" style="margin: 0 auto;" frameborder="0" scrolling="no" allowtransparency="true"></iframe>',
    height: 300,
    width: 600,
  },
  {
    type: "miro",
    regex: /https:\/\/miro.com\/\S+(\S{12})\/(\S+)?/,
    embedUrl: "https://miro.com/app/live-embed/<%= remote_id %>",
    height: 500,
    width: 700,
    // html: '<iframe width="700" height="500" style="margin: 0 auto;" allowFullScreen frameBorder="0" scrolling="no"></iframe>',
  },
  {
    type: "entail_video",
    regex: /https:\/\/media.entail.ai\/(\S+)/,
    embedUrl: "https://media.entail.ai/<%= remote_id %>",
    height: 500,
    width: 700,
    // html: '<iframe width="700" height="500" style="margin: 0 auto;" allowFullScreen frameBorder="0" scrolling="no"></iframe>',
  },
];

type PropTypes = {};

function InsertEmbedButton(props: PropTypes) {
  const classes = useStyles(props);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [editorSelection, setEditorSelection] = useState<Range>(null);
  const editor = useSlate();
  const isActive = RichTextEditorHelper.isBlockActive(editor, "embed");

  if (open && !editorSelection && editor.selection) {
    setEditorSelection(editor.selection);
  }

  const closePopover = () => {
    setUrl("");
    setWidth(null);
    setHeight(null);
    setOpen(false);
    setEditorSelection(null);
  };

  const onInsertEmbedClicked = async () => {
    if (editorSelection === null) {
      return;
    }
    if (!url || url.length === 0) {
      return;
    }
    Transforms.select(editor, editorSelection);
    let httpsUrl = url;
    if (!/^https?:\/\//i.test(httpsUrl)) {
      httpsUrl = `https://${url}`;
    }
    const service = find(supportedTypes, (st) => httpsUrl.match(st.regex));

    const match = httpsUrl.match(service?.regex);
    match.shift();
    const id = service?.id instanceof Function ? service.id(match) : match[0];
    const embeddedUrl = service ? service.embedUrl.replace("<%= remote_id %>", id) : httpsUrl;

    let response;
    if (service) {
      try {
        response = await agent.Embeds.getMetaData(id, httpsUrl, service.type);
      } catch (e) {
        console.log("Error getting video metadata: ", e);
      }
    }

    embed.insertEmbed(
      editor,
      embeddedUrl,
      width || service?.width,
      height || service?.height,
      service?.type || "unknown",
      id,
      response?.results || null
    );
    closePopover();
  };

  return (
    <div style={{ display: "contents" }}>
      <Tooltip enterDelay={2000} title={"Embed Content"}>
        <IconButton
          id={"insert-embed-button"}
          className={classes.button}
          ref={anchorRef}
          disableRipple
          onClick={(event) => !isActive && setOpen(true)}
        >
          <EmbedIcon className={ClassNames(classes.icon, isActive && classes.activeIcon)} />
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        className={classes.popper}
        role={undefined}
        transition
        // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center bottom" : "center bottom" }}
          >
            <Paper className={classes.paper} contentEditable={false}>
              <ClickAwayListener onClickAway={(e) => closePopover()}>
                <div>
                  <div className={classes.videoDetailsWrapper}>
                    <Typography color={"textSecondary"} className={classes.fieldLabel} variant={"body2"}>
                      {I18n.t("rich_text_editor.insert_embed.url")}
                    </Typography>
                    <MTextField
                      fullWidth
                      outlined
                      autoFocus
                      borderRadius={3}
                      // handleEnterClick={() => onInsertVideoClicked()}
                      value={url}
                      onChange={(text) => setUrl(text)}
                      placeholder={I18n.t("rich_text_editor.insert_embed.url_placeholder")}
                    />
                    <Typography color={"textSecondary"} className={classes.fieldLabel} variant={"body2"}>
                      {I18n.t("rich_text_editor.insert_embed.width")}
                    </Typography>
                    <MTextField
                      fullWidth
                      outlined
                      borderRadius={3}
                      value={width || ""}
                      onChange={(text) => setWidth(text)}
                      placeholder={I18n.t("rich_text_editor.insert_embed.width_placeholder")}
                    />
                    <Typography color={"textSecondary"} className={classes.fieldLabel} variant={"body2"}>
                      {I18n.t("rich_text_editor.insert_embed.height")}
                    </Typography>
                    <MTextField
                      fullWidth
                      outlined
                      borderRadius={3}
                      value={height || ""}
                      onChange={(text) => setHeight(text)}
                      placeholder={I18n.t("rich_text_editor.insert_embed.height_placeholder")}
                    />
                  </div>
                  <div className={classes.bottomWrapper}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <MTextButton
                        text={I18n.t("rich_text_editor.insert_embed.cancel")}
                        onClick={(e) => {
                          closePopover();
                        }}
                      />
                      <CfButton
                        height={40}
                        disabled={!url || url.length === 0}
                        text={I18n.t("rich_text_editor.insert_embed.insert")}
                        onClick={(e) => onInsertEmbedClicked()}
                      />
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default InsertEmbedButton;
