import { Editor, Transforms, Path } from "slate";
import { ReactEditor, RenderElementProps, useFocused, useSelected, useSlateStatic } from "slate-react";
import * as React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import richTextEditorHelper from "../../helper/richTextEditorHelper";
import { Widget } from "../widgets/WithWidgets";
import { editorType } from "../../types/editor.Types";
import TextBox from "./TextBox";
import theme from "../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({}));

export const TextBoxElement = ({
  attributes,
  children,
  element,
}: RenderElementProps & { element: { type: "text-box" } }) => {
  const focused = useFocused();
  const selected = useSelected();
  const editor = useSlateStatic();
  const classes = useStyles({
    attributes,
    children,
    element,
  });
  const textBoxCss = {
    padding: "10px 20px",
    background: theme.palette.secondary.main,
    ...(element.data?.textBoxCss || {}),
  };
  return (
    <Widget
      title={I18n.t("rich_text_editor.text_box.name")}
      element={element}
      contentEditable
      editable
      handleDeleteClick={() => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        Transforms.removeNodes(editor, { at: path });
      }}
    >
      <TextBox textBoxStyle={textBoxCss} attributes={attributes}>
        {children}
      </TextBox>
    </Widget>
  );
};

export const insertTextBox = (
  editor: Editor,
  at: Path,
  children = [{ type: editorType.paragraph, children: [{ text: "" }] }],
  select = true
) => {
  richTextEditorHelper.insertNodesButReplaceIfSelectionIsAtEmptyParagraphOrHeading(
    editor,
    [
      {
        type: editorType.textBox,
        uuid: richTextEditorHelper.getUuid(),
        data: {},
        children,
      },
    ],
    at
  );
  const textBoxEntry = Editor.above(editor, { match: (x) => x.type === "text-box" });

  if (select) {
    console.log("select", textBoxEntry);
    if (textBoxEntry) {
      Transforms.select(editor, [...textBoxEntry[1], 0]);
    } else {
      Transforms.select(editor, at);
    }
  }
};
