import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import moment from "moment/moment";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactEditor, useSlate, useSlateStatic } from "slate-react";
import { Editor, Transforms } from "slate";
import MSavedXMinutesAgo from "../../../components/MSavedXMinutesAgo";
import { IPage } from "../../../reducers/constants/objectTypes";
import richTextEditorHelper from "../../../editor/helper/richTextEditorHelper";

const useStyles = makeStyles((theme: Theme) => ({
  listRoot: {
    padding: 0,
  },
  listItemRoot: {
    padding: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      "& $circle": {
        backgroundColor: theme.palette.primary.main,
      },
      "& $resolvedText": {
        color: theme.palette.primary.main,
      },
      "& $text": {
        color: theme.palette.text.primary,
      },
      "& $replies": {
        color: theme.palette.text.primary,
      },
    },
  },
  listItemTextRoot: {
    margin: 0,
    padding: "0 20px",
  },
  primaryTypography: {
    gap: 5,
    display: "flex",
    flexDirection: "column",
    borderBottom: `solid 1px ${theme.palette.divider}`,
    padding: "15px 0",
  },
  circle: {
    width: 20,
    height: 20,
    fontSize: 12,
    textAlign: "center",
    borderRadius: "50%",
    color: "white",
    backgroundColor: theme.palette.text.secondary,
  },
  firstRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    marginRight: 6,
  },
  text: {
    lineHeight: 1.2,
    color: theme.palette.text.secondary,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  resolvedText: {
    color: theme.palette.text.secondary,
  },
  openText: {
    color: theme.palette.primary.main,
  },
  replies: {
    lineHeight: 1.5,
    color: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  pageLoaded: boolean;
  page: IPage;
};

const mapStateToProps = (state) => ({
  pageLoaded: state.pageEditor.pageLoaded,
  page: state.pageEditor.page,
});

const mapDispatchToProps = (dispatch) => ({});

const PageEditorComments = (props: PropTypes) => {
  const classes = useStyles(props);
  const editor = useSlate();
  const { pageLoaded, page } = props;

  if (!pageLoaded) {
    return null;
  }

  const threads = richTextEditorHelper.getAllComments(editor.children, []);

  const scrollToComment = (comment) => {
    try {
      const elementPath = ReactEditor.findPath(editor as ReactEditor, comment);
      ReactEditor.focus(editor as ReactEditor);
      Transforms.select(editor, Editor.start(editor, elementPath));
    } catch (e) {
      console.log("can't select path:::", e);
    }
  };

  return (
    <List classes={{ root: classes.listRoot }}>
      {threads.map((thread, i) => (
        <ListItem
          key={`thread_${i}`}
          classes={{ root: classes.listItemRoot }}
          onClick={() => {
            scrollToComment(thread);
          }}
        >
          <ListItemText
            classes={{ root: classes.listItemTextRoot }}
            primaryTypographyProps={{ className: classes.primaryTypography }}
          >
            <div className={classes.firstRow}>
              <div className={classes.circle}>{i + 1}</div>
              <Typography variant={"body2"}>
                {thread.resolved ? (
                  <span className={classes.resolvedText}>{I18n.t("rich_text_editor.comments.resolved")}</span>
                ) : (
                  <span className={classes.openText}>{I18n.t("rich_text_editor.comments.open")}</span>
                )}
              </Typography>
            </div>
            <Tooltip
              enterDelay={1000}
              title={`${moment(thread.comments?.[0]?.date).format("DD-MMM-YY LT")}`}
              placement={"top"}
            >
              <div className={classes.row}>
                <Typography variant={"body2"} className={classes.name}>
                  {thread.comments?.[0]?.name}
                </Typography>
                <MSavedXMinutesAgo lastSaveTime={thread.comments?.[0]?.date} action={""} />
              </div>
            </Tooltip>
            <Typography variant={"body2"} className={classes.text}>
              {thread.comments?.[0]?.text}
            </Typography>
            <Typography variant={"body2"} className={classes.replies}>
              {thread.comments.length - 1} {I18n.t("rich_text_editor.comments.replies")}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(PageEditorComments);
