import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme, Typography } from "@material-ui/core";
import {
  IProduct,
  IWebsite,
  IWidgetCallToActionProps,
  IWidgetFieldProperties,
} from "../../../../../../../reducers/constants/objectTypes";
import MSelect from "../../../../../../../components/MSelect";
import GenericValueItemProperty from "./components/GenericValueItemProperty";
import DialogWidgetPicker from "./components/DialogWidgetPicker";
import MDynamicListItem from "../../../../../../../components/MDynamicListItem";
import MSwitch from "../../../../../../../components/MSwitch";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    gap: 20,
  },
  input: {
    border: `1px solid ${palette.grey["100"]}`,
    width: "100%",
    overflow: "hidden",
    padding: "10px 15px",
    color: palette.text.primary,
    backgroundColor: palette.secondary.dark,
    fontWeight: typography.fontWeightLight as any,
    fontSize: 14,
    borderRadius: 3,
    height: 29,
  },
  selectWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  selectCustomClass: {
    backgroundColor: palette.secondary.dark,
    borderRadius: 3,
  },
  label: {
    color: palette.text.primary,
    fontSize: typography.pxToRem(14),
    lineHeight: "normal",
    fontWeight: typography.fontWeightMedium as any,
  },
  groupedItems: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  switchItemsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
}));

type PropTypes = {
  product: IProduct;
  properties: IWidgetFieldProperties;
  selectedWebsite: IWebsite;
  onChange: (properties: IWidgetCallToActionProps) => void;
};

const CallToActionWidgetItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const properties = (props.properties || {}) as IWidgetCallToActionProps;

  const { product, selectedWebsite, onChange } = props;

  const {
    openAction = properties?.href || !product ? "custom" : "call_to_action",
    title,
    href,
    dialogId,
    openInNewTab,
    includeQueryParams,
  } = properties as IWidgetCallToActionProps;
  const theme = useTheme<Theme>();

  const handleOpenActionChange = ({ value: openAction }) => {
    const newProperties = { ...properties, openAction };
    if (openAction === "call_to_action") {
      newProperties.title = product.callToAction;
      newProperties.href = product.callToActionURL;
    }

    if (openAction === "product") {
      newProperties.title = product.linkText || product.callToAction;
      newProperties.href = product.link;
    }

    onChange(newProperties);
  };

  const buttonOpenActionOptions = [
    {
      label: I18n.t("widgets_builder.widget_editor.fields.button_open_options.primary_cta"),
      value: "call_to_action",
      disabled: !product,
    },
    {
      label: I18n.t("widgets_builder.widget_editor.fields.button_open_options.secondary_cta"),
      value: "product",
      disabled: !product,
    },
    { label: I18n.t("widgets_builder.widget_editor.fields.button_open_options.custom"), value: "custom" },
    { label: I18n.t("widgets_builder.widget_editor.fields.button_open_options.popup"), value: "dialog" },
  ];

  return (
    <div className={classes.root}>
      <GenericValueItemProperty
        onChange={({ value }) => onChange({ ...properties, title: value })}
        value={title}
        label={I18n.t("widgets_builder.widget_editor.fields.labels.text")}
        labelOutsideInput
      />
      <div className={classes.selectWrapper}>
        <Typography variant="body1" className={classes.label}>
          {I18n.t("widgets_builder.widget_editor.fields.labels.open_action_link")}
        </Typography>
        <MSelect
          customClass={classes.selectCustomClass}
          valuePadding={"2px 10px 2px 5px"}
          options={buttonOpenActionOptions}
          value={buttonOpenActionOptions.find((option) => option.value === openAction)}
          optionLabel={"label"}
          optionValue={"value"}
          height={50}
          borderWidth={"0px"}
          isBold={false}
          textColor={theme.palette.text.primary}
          menuPlacement={"bottom"}
          singleValueStyles={{ fontSize: theme.typography.pxToRem(14) }}
          fullWidth
          getDisplayLabel={(vertical) => `${vertical.label}`}
          handleChange={handleOpenActionChange}
        />
      </div>

      {openAction !== "dialog" ? (
        <GenericValueItemProperty
          onChange={({ value }) => onChange({ ...properties, href: value })}
          value={href}
          label={I18n.t("widgets_builder.widget_editor.fields.labels.url")}
          labelOutsideInput
          disabled={openAction === "product" || openAction === "call_to_action"}
        />
      ) : (
        <DialogWidgetPicker
          selectedWebsite={selectedWebsite}
          uploadedId={dialogId}
          handleChange={(dialogId) => onChange({ ...properties, dialogId })}
        />
      )}
      {openAction !== "dialog" && (
        <div className={classes.groupedItems}>
          <Typography variant="body1" className={classes.label}>
            {I18n.t("widgets_builder.widget_editor.fields.labels.behavior")}
          </Typography>

          <div className={classes.switchItemsWrapper}>
            <MDynamicListItem
              title={I18n.t(`widgets_builder.widget_editor.fields.labels.open_in_new_tab`)}
              noArrow
              onClick={() => onChange({ ...properties, openInNewTab: !openInNewTab })}
              rightIconsComponent={
                <MSwitch
                  checked={openInNewTab}
                  onChange={(_, e) => e.preventDefault()}
                  onClick={(e) => e.preventDefault()}
                />
              }
            />

            <MDynamicListItem
              title={I18n.t(`widgets_builder.widget_editor.fields.labels.include_query_params`)}
              noArrow
              onClick={() => onChange({ ...properties, includeQueryParams: !includeQueryParams })}
              rightIconsComponent={
                <MSwitch
                  checked={includeQueryParams}
                  onChange={(_, e) => e.preventDefault()}
                  onClick={(e) => e.preventDefault()}
                />
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CallToActionWidgetItem;
