import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";

import { IWidgetContainerPosition, ICampaign } from "../../../../../../../../../../reducers/constants/objectTypes";
import { colors } from "../../../../../../../../../../helpers/constants";
import RuleBuilderHeader from "../../RuleBuilderHeader";
import WidgetContainerDevicePosition from "./WidgetContainerDevicePosition";
import {
  WidgetRuleEditorRootState,
  WidgetRuleEditorSelectedView,
} from "../../../../../../../../../../reducers/widgetRuleEditorReducer";
import { WIDGET_RULE_EDITOR } from "../../../../../../../../../../reducers/constants/actionTypes";
import SwitchProperty from "../../../../../../../WidgetsBuilder/FormWidgetEditor/build/items/itemProperties/components/SwitchProperty";

const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  positionWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium as any,
    lineHeight: "normal",
  },
  deleteButton: {
    width: "100%",
    fontSize: 14,
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightRegular as any,
    padding: 15,
    height: 55,
    borderRadius: 3,
    borderWidth: 1,
    backgroundColor: theme.palette.secondary.dark,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: colors.widgetOnHoverBackgroundColor,
    },
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: theme.palette.divider,
  },
  textField: {
    padding: 0,
    "& .MuiInputBase-multiline": {
      padding: "15px 15px 15px 0",
    },
  },
}));

const I18nRoute = "widgets_display_rules_editor.builder.container_settings_view.position_view";

const mapStateToProps = (state) => ({
  rule: state.widgetRuleEditor.rule,
  draftContainer: state.widgetRuleEditor.draftContainer,
  selectedPositionEdit: state.widgetRuleEditor.selectedPositionEdit,
});

const mapDispatchToProps = (dispatch) => ({
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PROPERTY_CHANGE, property });
  },
  handlePreviewPropertyChange: (property: Partial<WidgetRuleEditorRootState["preview"]>) => {
    dispatch({ type: WIDGET_RULE_EDITOR.PREVIEW_PROPERTY_CHANGE, property });
  },
});

type PropTypes = {
  rule: ICampaign;
  draftContainer: ICampaign["widgetContainers"][number];
  selectedPositionEdit: "desktop" | "mobile";
  handlePropertyChange: (property: Partial<WidgetRuleEditorRootState>) => void;
  handlePreviewPropertyChange: (property: Partial<WidgetRuleEditorRootState["preview"]>) => void;
};

const WidgetContainerPositionView = (props: PropTypes) => {
  const classes = useStyles(props);

  const { draftContainer, selectedPositionEdit } = props;
  const { handlePropertyChange, handlePreviewPropertyChange } = props;
  const [draftPosition, setDraftPosition] = React.useState(draftContainer.position);

  useEffect(() => {
    if (!isEqual(draftPosition, draftContainer.position)) {
      setDraftPosition(draftContainer.position);
    }
  }, [draftContainer]);

  const saveButtonDisabled = isEqual(draftPosition, draftContainer.position);

  useEffect(() => {
    handlePreviewPropertyChange({
      mobilePreview: selectedPositionEdit === "mobile",
    });
    return () => {
      handlePreviewPropertyChange({
        mobilePreview: false,
      });
    };
  }, [selectedPositionEdit]);

  const saveChanges = () => {
    handlePropertyChange({
      draftContainer: { ...draftContainer, position: draftPosition },
      selectedView: WidgetRuleEditorSelectedView.WIDGET_CONTAINER_SETTINGS,
    });
  };

  return (
    <div className={classes.mainWrapper}>
      <RuleBuilderHeader
        title={I18n.t(`${I18nRoute}.title`, { device: selectedPositionEdit })}
        rightContent={
          <SwitchProperty
            checked={draftPosition[selectedPositionEdit].enabled}
            onChange={(enabled) =>
              setDraftPosition({
                ...draftPosition,
                [selectedPositionEdit]: {
                  ...draftPosition[selectedPositionEdit],
                  enabled,
                },
              })
            }
          />
        }
        onBackClick={() =>
          handlePropertyChange({ selectedView: WidgetRuleEditorSelectedView.WIDGET_CONTAINER_SETTINGS })
        }
        buttonProps={{
          disabled: saveButtonDisabled,
          onClick: saveChanges,
        }}
      />

      <>
        {selectedPositionEdit === "desktop" && (
          <WidgetContainerDevicePosition
            position={draftPosition["desktop"]}
            onChange={(updatedDevicePosition) =>
              setDraftPosition({
                ...draftPosition,
                ["desktop"]: updatedDevicePosition as IWidgetContainerPosition & { enabled: boolean },
              })
            }
            onReselectPositionClick={() => handlePropertyChange({ reselectPositionMode: true })}
          />
        )}
        {selectedPositionEdit === "mobile" && (
          <WidgetContainerDevicePosition
            position={draftPosition["mobile"]}
            onChange={(updatedDevicePosition) =>
              setDraftPosition({
                ...draftPosition,
                ["mobile"]: updatedDevicePosition as IWidgetContainerPosition & { enabled: boolean },
              })
            }
            onReselectPositionClick={() => handlePropertyChange({ reselectPositionMode: true })}
          />
        )}
      </>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetContainerPositionView);
