import { Editor, Element, Node, Range, Transforms } from "slate";
import RichTextEditorHelper from "../../helper/richTextEditorHelper";
import { editorType } from "../../types/editor.Types";

const isCommentActive = (editor) => {
  const [comment] = Editor.nodes(editor, { match: (n) => n.type === "commentV2" });
  return !!comment;
};

const unwrapComment = (editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === "commentV2" });
};

const wrapComment = (editor, comments) => {
  const { selection } = editor;
  if (isCommentActive(editor)) {
    console.log("wrapComment: update current comment");
    Transforms.setNodes(
      editor,
      {
        comments,
      },
      {
        match: (n) => n.type === editorType.commentV2,
      }
    );
    return;
  }

  const isCollapsed = selection && Range.isCollapsed(selection);
  if (isCollapsed) {
    return;
  }

  const comment = {
    type: editorType.commentV2,
    uuid: RichTextEditorHelper.getUuid(),
    comments,
    resolved: false,
    children: [],
  };

  Transforms.wrapNodes(editor, comment, { split: true });
};

const insertComment = (editor, comments) => {
  if (editor.selection) {
    wrapComment(editor, comments);
  }
};

const removeComment = (editor) => {
  console.log("removeComment");
  unwrapComment(editor);
};

const withComments = (editor: Editor) => {
  const { isInline, normalizeNode } = editor;

  editor.isInline = (element) => (element.type === editorType.commentV2 ? true : isInline(element));

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node) && node.type === editorType.paragraph) {
      const children = Array.from(Node.children(editor, path));
      for (const [child, childPath] of children) {
        // remove link nodes whose text value is empty string.
        // empty text links happen when you move from link to next line or delete link line.
        if (
          Element.isElement(child) &&
          child.type === editorType.commentV2 &&
          child.children?.filter((c) => c.text !== "")?.length === 0
        ) {
          if (children.length === 1) {
            Transforms.removeNodes(editor, { at: path });
            Transforms.insertNodes(editor, {
              type: editorType.paragraph,
              children: [{ text: "" }],
            });
          } else {
            Transforms.removeNodes(editor, { at: childPath });
          }
          return;
        }
      }
    }
    normalizeNode(entry);
  };

  return editor;
};

export default {
  withComments,
  insertComment,
  removeComment,
  isCommentActive,
};
