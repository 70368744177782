import filter from "lodash/filter";
import _ from "lodash";
import { BLOG, CATEGORIZE } from "./constants/actionTypes";

const initialState = {
  postsLoaded: false,
  posts: [],
  blogPost: {
    tags: [],
  },

  // authors: [],

  tags: [],
  topics: [],
  topicsSearchField: "",
  tagsSearchField: "",

  autoSaveOn: true,
  saveInProgress: false,
  lastSaveTime: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BLOG.NEW_POST_CREATED:
      return {
        ...state,
        posts: [action.payload.post, ...state.posts],
        blogPost: action.payload.post,
      };
    case BLOG.POSTS_LOADED:
      return {
        ...state,
        postsLoaded: true,
        posts: action.payload.posts,
        // blogPost: ! state.blogPost['_id'] && action.payload.posts.length > 0 ? action.payload.posts[0] : state.blogPost,
        blogPost: action.payload.posts.length > 0 ? action.payload.posts[0] : initialState.blogPost,
      };
    case BLOG.TOGGLE_AUTO_SAVE:
      return {
        ...state,
        autoSaveOn: !state.autoSaveOn,
      };
    case BLOG.POST_SAVED:
      return {
        ...state,
        blogPost: action.blogPost,
        posts: state.posts.map((p) => {
          if (p._id === action.blogPost._id || (!p._id && action.newPost)) {
            return action.blogPost;
          }
          return p;
        }),
      };
    case BLOG.POST_AUTO_SAVED:
      return {
        ...state,
        saveInProgress: false,
        lastSaveTime: Date.now(),
        blogPost: {
          ...state.blogPost,
          _id: action.payload.blogPost._id,
        },
        posts: state.posts.map((p) => {
          if (p._id === action.payload.blogPost._id || (!p._id && action.payload.newPost)) {
            return action.payload.blogPost;
          }
          return p;
        }),
      };
    case BLOG.BLOG_POST_CHANGED:
      return {
        ...state,
        blogPost: action.blogPost,
      };
    case BLOG.POST_SELECTED:
      return {
        ...state,
        blogPost: action.post,
      };
    // case BLOG.AUTHORS_LOADED:
    //   return {
    //     ...state,
    //     authors: action.payload.authors,
    //   };
    case BLOG.AUTHOR_CHANGED:
      return {
        ...state,
        blogPost: {
          ...state.blogPost,
          author: action.author,
        },
      };
    case BLOG.ON_NEW_AUTHOR_CREATED:
      return {
        ...state,
        blogPost: {
          ...state.blogPost,
          author: action.payload.author,
        },
      };
    case BLOG.PREVIEW_IMAGE_SELECTED:
      return {
        ...state,
        blogPost: {
          ...state.blogPost,
          previewImage: action.file,
        },
      };
    case BLOG.ON_TAGS_LOADED:
      return {
        ...state,
        tags: action.payload.tags,
      };
    case BLOG.ON_TAG_SELECTED:
      return {
        ...state,
        blogPost: {
          ...state.blogPost,
          tags: [...state.blogPost.tags, action.tag],
        },
      };
    case BLOG.ON_TAG_REMOVED:
      return {
        ...state,
        blogPost: {
          ...state.blogPost,
          tags: filter(state.blogPost.tags, (tag) => tag.name !== action.tag.name),
        },
      };
    case BLOG.ON_TAGS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        tagsSearchField: action.input,
      };
    case BLOG.ON_NEW_TAG_CREATED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        tags: _.uniqBy([...state.tags, action.payload.tag], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        tagsSearchField: "",
        blogPost: {
          ...state.blogPost,
          tags: _.uniqBy([...state.blogPost.tags, action.payload.tag], "_id"),
        },
      };
    case BLOG.ON_TOPICS_LOADED:
      return {
        ...state,
        topics: action.payload.topics,
        blogPost: {
          ...state.blogPost,
          topic: action.payload.topics[0],
        },
      };
    case BLOG.ON_CATEGORY_SELECTED:
      return {
        ...state,
        blogPost: {
          ...state.blogPost,
          category: action.category,
        },
      };
    case BLOG.ON_TOPICS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        topicsSearchField: action.input,
      };
    case BLOG.ON_NEW_TOPIC_CREATED:
      return {
        ...state,
        topics: _.uniqBy([...state.topics, action.payload.topic], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        topicsSearchField: "",
        blogPost: {
          ...state.blogPost,
          topic: action.payload.topic,
        },
      };
    case BLOG.ON_SOURCE_URL_ADDED:
      return {
        ...state,
        blogPost: {
          ...state.blogPost,
          sourceURLs: [...(state.blogPost.sourceURLs || []), action.url],
        },
      };
    case BLOG.ON_SOURCE_URL_REMOVED:
      return {
        ...state,
        blogPost: {
          ...state.blogPost,
          sourceURLs: filter(state.blogPost.sourceURLs, (url) => url !== action.url),
        },
      };
    default:
      return state;
  }
};
