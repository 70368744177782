import * as React from "react";
import { useParams } from "react-router";
import { replace } from "connected-react-router";
import { connect } from "react-redux";

import FormWidgetEditor from "./FormWidgetEditor/FormWidgetEditor";
import { web } from "../../../../helpers/urlHelper";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  handleClose: () => dispatch(replace(web.widgets())),
});

type PropTypes = {
  handleClose: () => void;
};

const WidgetEditor = (props: PropTypes) => {
  const { handleClose } = props;
  const { widgetId } = useParams();

  return <FormWidgetEditor widgetId={widgetId} handleClose={handleClose} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetEditor);
