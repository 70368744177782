import * as React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import { Theme } from "@material-ui/core/styles";
import { Collapse, Paper } from "@material-ui/core";
import DropDownIcon from "@material-ui/icons/ArrowDropDown";
import DropUpIcon from "@material-ui/icons/ArrowDropUp";
import { I18n } from "react-redux-i18n";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MTextField from "./MTextField";
import MTextCounter from "./MTextCounter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    metaDescriptionWrapper: {
      width: "100%",
      height: "100%",
    },
    paper: {
      height: "100%",
    },
    collapseContainer: {
      width: "100%",
    },
    collapseWrapper: {
      minHeight: (props) => props.minHeight || 100,
    },
    collapsedContentWrapper: {
      paddingRight: 20,
      paddingLeft: 20,
      paddingBottom: (props) => props.descriptionPaddingBottom || 20,
      width: "100%",
    },
    nonCollapsableWrapper: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    topSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    titleWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      minHeight: 40,
    },
    innerWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold as any,
    },
    arrowIcon: {
      padding: 8,
    },
    metaDescription: {
      marginTop: 0,
      marginBottom: 0,
    },
    counter: {
      width: "min-content",
    },
  })
);

type PropTypes = {
  customClass?: string;
  paperCustomClass?: string;
  text: string;
  title?: string;
  collapsable?: boolean;
  placeholder?: string;
  desiredCharsCount?: number;
  hasTitle?: boolean;
  hasCounter?: boolean;
  minHeight?: number;
  descriptionPaddingBottom?: number;
  enforceDesiredCharsCount?: boolean;
  handleTextChanged: (text) => void;
  children?: React.ReactNode;
};

const MTextBox = (props: PropTypes) => {
  const classes = useStyles(props);
  const [collapsed, setCollapsed] = React.useState(true);
  const {
    customClass,
    paperCustomClass,
    collapsable = true,
    text,
    hasTitle = true,
    title = I18n.t("targeted_posts.post.meta_description"),
    placeholder = I18n.t("targeted_posts.post.meta_description_ph"),
    desiredCharsCount = 160,
    enforceDesiredCharsCount = false,
    hasCounter = true,
    children,
    handleTextChanged,
  } = props;

  return (
    <div className={ClassNames(classes.metaDescriptionWrapper, customClass)}>
      <Paper elevation={0} className={ClassNames(classes.paper, paperCustomClass)}>
        {children}
        {hasTitle && (
          <div className={classes.topSection}>
            <div className={classes.titleWrapper}>
              {collapsable && (
                <IconButton
                  className={classes.arrowIcon}
                  disableRipple
                  disableTouchRipple
                  disableFocusRipple
                  onClick={(e) => setCollapsed(!collapsed)}
                >
                  {collapsed ? <DropDownIcon /> : <DropUpIcon />}
                </IconButton>
              )}
              <Typography className={classes.bold} color={"textPrimary"} variant={"body2"}>
                {title}
              </Typography>
            </div>
            <MTextCounter wrapperClass={classes.counter} text={text} desiredCount={desiredCharsCount} />
          </div>
        )}

        <Collapse
          in={collapsed}
          classes={{
            root: classes.collapseContainer,
            wrapper: classes.collapseWrapper,
          }}
        >
          <div className={ClassNames(classes.collapsedContentWrapper, !collapsable && classes.nonCollapsableWrapper)}>
            <div className={classes.innerWrapper}>
              <MTextField
                fullWidth
                multiline
                rows={6}
                customClass={classes.metaDescription}
                onChange={(t: string) => {
                  if (enforceDesiredCharsCount) {
                    handleTextChanged(t.substring(0, desiredCharsCount));
                    return;
                  }
                  handleTextChanged(t);
                }}
                value={text || ""}
                placeholder={placeholder}
              />
              {hasCounter && (
                <MTextCounter wrapperClass={classes.counter} text={text} desiredCount={desiredCharsCount} />
              )}
            </div>
          </div>
        </Collapse>
      </Paper>
    </div>
  );
};

export default MTextBox;
