import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import { TooltipProps } from "@material-ui/core";

import { ePostType } from "../../../reducers/constants/objectTypes";
import MTableCellText from "./MTableCellText";
import MTableCellActions from "./MTableCellActions";
import MPageTypeAvatar from "../../MPageTypeAvatar";
import MTableCellLink from "./MTableCellLink";

const useStyles = makeStyles((theme: Theme) => ({
  firstColumn: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    overflow: "hidden",
    fontWeight: theme.typography.fontWeightBold as any,
    width: "auto",
    position: "relative",
    paddingRight: 35,
    "&:hover $actions": {
      display: "flex",
    },
  },
  iconColumn: {
    minWidth: 40,
    display: "flex",
    alignItems: "center",
  },
  actions: {
    marginLeft: "auto",
    display: "none",
  },
}));

type PropTypes = {
  columnWidth?: number | string;
  fontSize?: number | string;
  fontWeight?: number | string;
  wrapperClassName?: string;
  onClick?: () => void;
  avatarType?: ePostType | string;
  cellText?: string;
  href?: string;
  actions?: {
    text: string;
    tooltip: any | string;
    tooltipDelay?: number;
    tooltipClasses?: any;
    onClick: (event, row) => void;
    icon?: React.ReactNode;
    iconName: string;
  }[];
  type?: "text" | "link";
  actionsObject?: any;
  prefix?: React.ReactNode;
  actionClassName?: string;
  children?: string | React.ReactNode;
  avatarClassName?: string;
  toolTipProps?: Partial<TooltipProps>;
};

const MTableCellFirst = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    avatarType = null,
    cellText = null,
    href = null,
    toolTipProps,
    actions = [],
    actionsObject = null,
    wrapperClassName,
    prefix,
    onClick,
    actionClassName,
    children,
    fontSize = 12,
    fontWeight = 600,
    columnWidth = "auto",
    type = "text",
    avatarClassName,
  } = props;

  return (
    <div
      className={ClassNames(classes.firstColumn, wrapperClassName)}
      style={{
        width: columnWidth,
      }}
      onClick={onClick}
    >
      {avatarType ? (
        <div className={classes.iconColumn}>
          <MPageTypeAvatar pageType={avatarType} className={avatarClassName} size={25} />
        </div>
      ) : (
        prefix
      )}
      {type === "text" && cellText && (
        <MTableCellText fontSize={fontSize} fontWeight={fontWeight} text={cellText} toolTipProps={toolTipProps} />
      )}
      {type === "link" && cellText && href && (
        <MTableCellLink text={cellText} fontSize={fontSize} fontWeight={fontWeight} href={href} />
      )}
      {children}
      <MTableCellActions
        actionsObject={actionsObject}
        actions={actions}
        className={classes.actions}
        customClass={actionClassName}
      />
    </div>
  );
};

export default MTableCellFirst;
