import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import { ILinksSplitTest, ILinksSplitTestGroup } from "../../../../../../../reducers/constants/objectTypes";
import MTextField from "../../../../../../../components/MTextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DownChevronIcon from "../../../../../../../icons/DownChevronIcon";

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    paddingLeft: 10,
  },
  searchTextField: {
    borderRadius: 5,
    // marginRight: 10,
  },
  searchTextFieldInput: {},
  searchTextFieldInputNumber: {
    marginRight: 10,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tableRowHead: {
    fontWeight: theme.typography.fontWeightBold as any,
    marginTop: 20,
  },
  tableColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    // flexGrow: 1,
    // flexBasis: 0,
    fontWeight: "inherit",
    marginLeft: 10,
  },
  tableColumnSmall: {
    maxWidth: 200,
    display: "flex",
    flexDirection: "column",
    fontWeight: "inherit",
  },
  tableColumnFirst: {
    width: 95,
    display: "flex",
    flexDirection: "column",
    fontWeight: "inherit",
    textAlign: "end",
    paddingRight: 10,
  },
  tableWeightColumn: {
    width: 80,
    marginRight: 10,
  },
  tableTypeColumn: {
    marginLeft: 0,
  },
  tableSelect: {
    width: 200,
    border: `none`,
    backgroundColor: "#EEF2F9",
    paddingLeft: 10,
    borderRadius: 5,
    marginTop: 5,
    height: 37,
  },
  dropDownIcon: {
    marginRight: 10,
    fill: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    "&:hover": {
      fill: theme.palette.primary.dark,
      color: theme.palette.primary.dark,
    },
  },
}));

type PropTypes = {
  test: ILinksSplitTest;
  handleTestChanged: (test: ILinksSplitTest) => void;
};

const EditLinksSplitTestGroupsTable = (props: PropTypes) => {
  const classes = useStyles(props);
  const { test, handleTestChanged } = props;

  const nextLetter = (s) =>
    s.replace(/([a-zA-Z])[^a-zA-Z]*$/, (a) => {
      let c = a.charCodeAt(0);
      switch (c) {
        case 90:
          return "A";
        case 122:
          return "a";
        default:
          return String.fromCharCode(++c);
      }
    });

  const nextGroup: ILinksSplitTestGroup = {
    variant: test.groups.length > 0 ? nextLetter(test.groups[test.groups.length - 1]?.variant) : "A",
    name: "",
    description: "",
    weight: 0,
    url: "",
    type: test.groups.length > 0 ? "custom" : "default",
    visits: 0,
  };

  return (
    <div>
      <div className={classNames(classes.tableRow, classes.tableRowHead)}>
        <Typography className={classes.tableColumnFirst} color={"textPrimary"} variant={"body1"}>
          {I18n.t("links_split_test.editor.variant")}
        </Typography>
        <Typography
          className={classNames(classes.tableColumnSmall, classes.tableWeightColumn)}
          color={"textPrimary"}
          variant={"body1"}
        >
          {I18n.t("links_split_test.editor.weight")}
        </Typography>
        <Typography
          className={classNames(classes.tableColumn, classes.tableColumnSmall, classes.tableTypeColumn)}
          color={"textPrimary"}
          variant={"body1"}
        >
          {I18n.t("links_split_test.editor.type")}
        </Typography>
        <Typography
          className={classNames(classes.tableColumn, classes.tableColumnSmall)}
          color={"textPrimary"}
          variant={"body1"}
        >
          {I18n.t("links_split_test.editor.url")}
        </Typography>
        <Typography
          className={classNames(classes.tableColumn, classes.tableColumnSmall)}
          color={"textPrimary"}
          variant={"body1"}
        >
          {I18n.t("links_split_test.editor.name")}
        </Typography>
        <Typography className={classes.tableColumn} color={"textPrimary"} variant={"body1"}>
          {I18n.t("links_split_test.editor.description")}
        </Typography>
        {/*<Typography*/}
        {/*  className={classNames(classes.tableColumn, classes.tableColumnSmall)}*/}
        {/*  color={"textPrimary"}*/}
        {/*  variant={"body1"}*/}
        {/*>*/}
        {/*  {I18n.t("links_split_test.editor.visits")}*/}
        {/*</Typography>*/}
        <div className={classNames(classes.tableColumn, classes.tableColumnSmall)} />
      </div>
      {[...test.groups, nextGroup].map((group, index) => (
        <div key={`group_${index}`} className={classes.tableRow}>
          <Typography
            className={classNames(classes.textField, classes.tableColumnFirst)}
            color={"textPrimary"}
            variant={"body1"}
          >
            {group.variant}
          </Typography>

          <MTextField
            fullWidth
            customClass={classNames(classes.searchTextField, classes.tableColumnSmall, classes.tableWeightColumn)}
            inputClassName={classNames(classes.searchTextFieldInput, classes.searchTextFieldInputNumber)}
            type={"number"}
            borderRadius={5}
            outlined
            backgroundColor={"#EEF2F9"}
            borderColor={"#EEF2F9"}
            height={37}
            value={group.weight.toString()}
            placeholder={I18n.t("links_split_test.editor.weight_ph")}
            onChange={(userInput) => {
              if (userInput < 0) {
                return;
              }
              const newGroups = index < test.groups.length ? [...test.groups] : [...test.groups, nextGroup];
              console.log("userInput", userInput, index, newGroups);
              const countBefore = [...newGroups].splice(0, index).reduce((acc, g) => acc + g.weight, 0);
              console.log("countBefore", countBefore);
              const currentCount = parseInt(userInput, 10);
              console.log("currentCount", currentCount);
              const countAfter = [...newGroups]?.splice(index + 1)?.reduce((acc, g) => acc + g.weight, 0) || 0;
              console.log("countAfter", countAfter);

              if (countBefore + currentCount + countAfter > 100) {
                return;
              }

              newGroups[index].weight = currentCount;
              if (newGroups[index].weight > 100 - countAfter) {
                newGroups[index].weight = 100 - countAfter;
              }
              if (newGroups[index].weight > 100 - countBefore) {
                newGroups[index].weight = 100 - countBefore;
              }
              handleTestChanged({ ...test, groups: newGroups });
            }}
          />

          <Select
            className={classes.tableSelect}
            value={index === 0 ? group.type : "custom"}
            disabled={index > 0}
            onChange={(e) => {
              const newGroups = index < test.groups.length ? [...test.groups] : [...test.groups, nextGroup];
              newGroups[index].type = e.target.value as any;
              handleTestChanged({ ...test, groups: newGroups });
            }}
            disableUnderline
            label="state"
            IconComponent={() => <DownChevronIcon className={classes.dropDownIcon} />}
          >
            <MenuItem value={"default"}>{I18n.t("links_split_test.editor.default_cta")}</MenuItem>
            <MenuItem value={"custom"}>{I18n.t("links_split_test.editor.custom_url")}</MenuItem>
          </Select>

          <MTextField
            fullWidth
            customClass={classNames(classes.searchTextField, classes.tableColumn, classes.tableColumnSmall)}
            inputClassName={classes.searchTextFieldInput}
            value={group.url}
            borderRadius={5}
            outlined
            disabled={group.type === "default"}
            disableUnderline
            backgroundColor={"#EEF2F9"}
            borderColor={"#EEF2F9"}
            height={37}
            placeholder={I18n.t(
              group.type === "default" ? "links_split_test.editor.default_cta" : "links_split_test.editor.url_ph"
            )}
            onChange={(userInput) => {
              const newGroups = index < test.groups.length ? [...test.groups] : [...test.groups, nextGroup];
              newGroups[index].url = userInput;
              handleTestChanged({ ...test, groups: newGroups });
            }}
          />

          <MTextField
            fullWidth
            customClass={classNames(classes.searchTextField, classes.tableColumn, classes.tableColumnSmall)}
            inputClassName={classes.searchTextFieldInput}
            value={group.name}
            borderRadius={5}
            outlined
            backgroundColor={"#EEF2F9"}
            borderColor={"#EEF2F9"}
            height={37}
            placeholder={I18n.t("links_split_test.editor.name_ph")}
            onChange={(userInput) => {
              const newGroups = index < test.groups.length ? [...test.groups] : [...test.groups, nextGroup];
              newGroups[index].name = userInput;
              handleTestChanged({ ...test, groups: newGroups });
            }}
          />

          <MTextField
            fullWidth
            customClass={classNames(classes.searchTextField, classes.tableColumn)}
            inputClassName={classes.searchTextFieldInput}
            value={group.description}
            borderRadius={5}
            outlined
            backgroundColor={"#EEF2F9"}
            borderColor={"#EEF2F9"}
            height={37}
            placeholder={I18n.t("links_split_test.editor.description_ph")}
            onChange={(userInput) => {
              const newGroups = index < test.groups.length ? [...test.groups] : [...test.groups, nextGroup];
              newGroups[index].description = userInput;
              handleTestChanged({ ...test, groups: newGroups });
            }}
          />

          {/*<Typography*/}
          {/*  className={classNames(classes.searchTextField, classes.tableColumn, classes.tableColumnSmall)}*/}
          {/*  color={"textPrimary"}*/}
          {/*  variant={"body1"}*/}
          {/*>*/}
          {/*  {numeral(find(test.analytics?.groups, (g) => g.variant === group.variant)?.visits || 0).format("0")}*/}
          {/*</Typography>*/}

          <div className={classNames(classes.tableColumn, classes.tableColumnSmall)}>
            {index > 0 && index < test.groups.length && (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => {
                  const newGroups = index < test.groups.length ? [...test.groups] : [...test.groups, nextGroup];
                  newGroups.splice(index, 1);
                  handleTestChanged({ ...test, groups: newGroups });
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EditLinksSplitTestGroupsTable;
