import { Theme, Typography, makeStyles, Select, MenuItem } from "@material-ui/core";
import React from "react";
import { I18n } from "react-redux-i18n";

type PropTypes = {
  onChange?(event: any): unknown;
  className?: string;
  items?: string[];
  selectedOption?: string;
};

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  return {
    root: (props: PropTypes) => ({
      display: "flex",
      alignItems: "center",
      height: 55,
      padding: 15,
      width: "100%",
    }),
    title: (props: PropTypes) => {
      const color = palette.text.primary;
      return {
        color,
        flex: 1,
      };
    },
    switch: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      marginRight: -12,
    },
    unitSelect: {
      marginLeft: 10,
      height: 32,
      borderRadius: 3,
      border: `${palette.divider} solid 1px`,
      paddingLeft: 12,
    },
    label: {
      color: palette.text.primary,
    },
  };
});

const SelectedOptionProperty = (props: PropTypes) => {
  const { onChange, className, items, selectedOption } = props;
  const classes = useStyles(props);
  const [value, setValue] = React.useState<string>(selectedOption ?? "None");

  let itemsOptions = [];

  itemsOptions = ["None", ...items];

  React.useEffect(() => {
    itemsOptions = ["None", ...items];
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        maxHeight: 55,
        width: "100%",
        justifyContent: "space-between",
        paddingBlock: 15,
      }}
    >
      <Typography className={classes.label}>
        {I18n.t("widgets_builder.widget_editor.Form.fields.properties.selected_option")}
      </Typography>
      <Select
        id="unit-select"
        className={classes.unitSelect}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e);
        }}
        disableUnderline
        label="StartDate"
      >
        {itemsOptions.map((item, i) => (
          <MenuItem value={item}>{item}</MenuItem>
        ))}
      </Select>
    </div>
  );
};

export { SelectedOptionProperty };
