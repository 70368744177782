import { I18n } from "react-redux-i18n";
import { web } from "./urlHelper";
import { IWebsiteReviewStep } from "../reducers/constants/objectTypes";
import { videoProjectStates } from "./constants";

export const getWorkFlowTabs = (ability, website) => {
  const workFlowTabs = [];

  if (ability.can("view", "cms_mapping_tab"))
    workFlowTabs.push({
      url: web.workflowMapping(),
      label: I18n.t("workflow.tabs.mapping"),
    });

  if (ability.can("view", "cms_briefs_tab"))
    workFlowTabs.push({
      url: web.workflowBriefs(),
      label: I18n.t("workflow.tabs.briefs"),
    });

  /*  if (ability.can("view", "cms_outline_review_tab"))
    workFlowTabs.push({
      url: web.workflowOutlines(),
      label: I18n.t("workflow.tabs.outline"),
    });*/

  if (ability.can("view", "cms_writer_tab"))
    workFlowTabs.push({
      url: web.workflowWrite(),
      label: I18n.t("workflow.tabs.write"),
    });

  if (ability.can("view", "cms_editor_tab"))
    workFlowTabs.push({
      url: web.workflowEdit(),
      label: I18n.t("workflow.tabs.edit"),
    });

  if (website.secondEditStep && ability.can("view", "cms_editor_tab"))
    workFlowTabs.push({
      url: web.workflowSecondEdit(),
      label: I18n.t("workflow.tabs.second_edit"),
    });

  if (ability.can("view", "cms_fact_check_tab") && website.reviewProcess === "Fact-Check")
    workFlowTabs.push({
      url: web.workflowFactCheck(),
      label: I18n.t("workflow.tabs.fact_check"),
    });
  if (website.reviewProcess === "Custom") {
    website.reviews.map((review: IWebsiteReviewStep) => {
      if (review.reviewType === "factCheck" && ability.can("view", "cms_fact_check_tab")) {
        workFlowTabs.push({
          url: web.workflowReviewStep(review.reviewStepName),
          label: review.reviewName,
        });
      }

      if (review.reviewType === "regulationCheck" && ability.can("view", "cms_fact_check_tab")) {
        workFlowTabs.push({
          url: web.workflowReviewStep(review.reviewStepName),
          label: review.reviewName,
        });
      }
    });
  }

  // if (website.customerReview && ability.can("view", "fact_check_tab"))
  //   workFlowTabs.push({
  //     url: web.workflowFactCheck(),
  //     label: website.customerReviewName || I18n.t("workflow.tabs.fact_check"),
  //   });
  //
  // if (website.secondCustomerReview && ability.can("view", "fact_check_tab"))
  //   workFlowTabs.push({
  //     url: web.workflowSecondFactCheck(),
  //     label: website.secondCustomerReviewName || I18n.t("workflow.tabs.second_fact_check"),
  //   });
  // if (website.thirdCustomerReview && ability.can("view", "fact_check_tab"))
  //   workFlowTabs.push({
  //     url: web.workflowThirdFactCheck(),
  //     label: website.thirdCustomerReviewName || I18n.t("workflow.tabs.third_fact_check"),
  //   });
  // if (website.fourthCustomerReview && ability.can("view", "fact_check_tab"))
  //   workFlowTabs.push({
  //     url: web.workflowFourthFactCheck(),
  //     label: website.fourthCustomerReviewName || I18n.t("workflow.tabs.fourth_fact_check"),
  //   });

  if (ability.can("view", "cms_published_tab"))
    workFlowTabs.push({
      url: web.workflowPublished(),
      label: I18n.t("workflow.tabs.published"),
    });

  if (ability.can("view", "cms_all_states_tab"))
    workFlowTabs.push({
      url: web.workflowAllStates(),
      label: I18n.t("workflow.tabs.all_states"),
    });
  return workFlowTabs;
};

export const getCommunityTabs = (ability) => {
  const communityTabs = [];

  if (ability.can("view", "community_writer_tab")) {
    communityTabs.push({
      url: web.community("writer"),
      label: I18n.t("workflow.tabs.write"),
    });
  }

  if (ability.can("view", "community_editor_tab")) {
    communityTabs.push({
      url: web.community("new"),
      label: I18n.t("workflow.tabs.new"),
    });
    communityTabs.push({
      url: web.community("draft"),
      label: I18n.t("workflow.tabs.draft"),
    });
  }
  if (ability.can("view", "community_published_tab")) {
    communityTabs.push({
      url: web.community("published"),
      label: I18n.t("workflow.tabs.published"),
    });
  }
  if (ability.can("view", "community_editor_tab")) {
    communityTabs.push({
      url: web.community("moderator"),
      label: I18n.t("workflow.tabs.moderator"),
    });

    communityTabs.push({
      url: web.community("deleted"),
      label: I18n.t("workflow.tabs.deleted"),
    });
  }
  if (ability.can("view", "community_writer_tab")) {
    communityTabs.push({
      url: web.community("answers"),
      label: I18n.t("workflow.tabs.write_published"),
    });
  }

  return communityTabs;
};

export const getCmsSettingsTabs = (ability) => {
  const settingsTabs = [];
  if (ability.can("view", "cms_settings_topics_tab")) {
    settingsTabs.push({
      url: web.settingsTopics(),
      label: I18n.t("settings.tabs.topics"),
    });
  }
  if (ability.can("view", "cms_settings_bios_tab")) {
    settingsTabs.push({
      url: web.settingsBios(),
      label: I18n.t("settings.tabs.bios"),
    });
  }
  if (ability.can("view", "cms_settings_categories_tab")) {
    settingsTabs.push({
      url: web.settingsCategories(),
      label: I18n.t("settings.tabs.categories"),
    });
  }
  if (ability.can("view", "settings_roles_and_permissions_tab")) {
    settingsTabs.push({
      url: web.settingsRolesAndPermissions(),
      label: I18n.t("settings.tabs.roles_and_permissions"),
    });
  }
  return settingsTabs;
};

export const getSeoTabs = (ability) => {
  const seoTabs = [];
  if (ability.can("view", "seo_redirects_tab")) {
    seoTabs.push({
      url: web.seoRedirects(),
      label: I18n.t("seo.tabs.redirects"),
    });
  }
  if (ability.can("view", "seo_indexing_api_tab")) {
    seoTabs.push({
      url: web.seoIndexingApi(),
      label: I18n.t("seo.tabs.indexing_api"),
    });
  }
  return seoTabs;
};

export const getStrategyTabs = (ability) => {
  const strategyTabs = [];
  if (ability.can("view", "strategy_mapping_tab")) {
    strategyTabs.push({
      url: web.outlinePages(),
      label: I18n.t("strategy.tabs.mapping"),
    });
  }
  if (ability.can("view", "strategy_fetch_tab")) {
    strategyTabs.push({
      url: web.fetchKeywords(),
      label: I18n.t("strategy.tabs.fetch"),
    });
  }
  if (ability.can("view", "strategy_exclude_keyword_tab")) {
    strategyTabs.push({
      url: web.excludeKeywords(),
      label: I18n.t("strategy.tabs.exclude"),
    });
  }
  if (ability.can("view", "strategy_keyword_classification_tab")) {
    strategyTabs.push({
      url: web.keywordsClassification(),
      label: I18n.t("strategy.tabs.classify"),
    });
  }
  if (ability.can("view", "strategy_keyword_groups_tab")) {
    strategyTabs.push({
      url: web.dataGroups(),
      label: I18n.t("strategy.tabs.groups"),
    });
  }

  // if (ability.can("view", "strategy_keyword_heading_tab")) {
  //   strategyTabs.push({
  //     url: web.keywordHeadingCluster(),
  //     label: I18n.t("strategy.tabs.heading_cluster"),
  //   });
  // }

  // if (ability.can("view", "strategy_page_keywords_tab")) {
  //   strategyTabs.push({
  //     url: web.pageKeywords(),
  //     label: I18n.t("strategy.tabs.page_keywords"),
  //   });
  // }
  if (ability.can("view", "strategy_keyword_group_page_tab")) {
    strategyTabs.push({
      url: web.keywordGroupPage(),
      label: I18n.t("strategy.tabs.pages"),
    });
  }
  if (ability.can("view", "strategy_outline_tab")) {
    strategyTabs.push({
      url: web.outlines(),
      label: I18n.t("strategy.tabs.outline"),
    });
  }
  if (ability.can("view", "strategy_content_gaps_tab")) {
    strategyTabs.push({
      url: web.contentGaps(),
      label: I18n.t("strategy.tabs.content_gaps"),
    });
  }

  if (ability.can("view", "strategy_internal_linking_tab")) {
    strategyTabs.push({
      url: web.internalLinking(),
      label: I18n.t("strategy.tabs.internal_linking"),
    });
  }

  return strategyTabs;
};

export const getCampaignsTabs = (ability) => {
  const croTabs = [];
  if (ability.can("view", "cro_widgets_display_rules_tab")) {
    croTabs.push({
      url: web.croCampaigns(),
      label: I18n.t("cro.tabs.campaigns"),
    });
  }
  if (ability.can("view", "cro_widgets_tab")) {
    croTabs.push({
      url: web.widgets(),
      label: I18n.t("cro.tabs.widgets"),
    });
  }
  if (ability.can("view", "cro_products_tab")) {
    croTabs.push({
      url: web.croProducts(),
      label: I18n.t("cro.tabs.products"),
    });
  }
  if (ability.can("view", "cro_experiments_tab")) {
    croTabs.push({
      url: web.croLinksSplitTest(),
      label: I18n.t("cro.tabs.links_split_test"),
    });
  }

  return croTabs;
};

export const getAnalyticsTabs = (ability) => {
  const croTabs = [];
  if (ability.can("view", "cro_dashboard_tab")) {
    croTabs.push({
      url: web.croDashboard(),
      label: I18n.t("cro.tabs.dashboard"),
    });
  }
  if (ability.can("view", "cro_dashboard_tab")) {
    croTabs.push({
      url: web.croGoalCompletion(),
      label: I18n.t("cro.tabs.goal_completion"),
    });
  }
  if (ability.can("view", "cro_reports_tab")) {
    croTabs.push({
      url: web.croReportsAll(),
      label: I18n.t("cro.tabs.reports"),
    });
  }
  if (ability.can("view", "cro_funnels_tab")) {
    croTabs.push({
      url: web.croFunnels(),
      label: I18n.t("cro.tabs.funnels"),
    });
  }
  if (ability.can("view", "cro_events_tab")) {
    croTabs.push({
      url: web.croEvents(),
      label: I18n.t("cro.tabs.events"),
    });
  }
  if (ability.can("view", "cro_goals_tab")) {
    croTabs.push({
      url: web.croGoalEvents(),
      label: I18n.t("cro.tabs.goal_events"),
    });
  }
  if (ability.can("view", "cro_orders_tab")) {
    croTabs.push({
      url: web.croOrders(),
      label: I18n.t("cro.tabs.orders"),
    });
  }
  return croTabs;
};

export const getVideoTabs = (ability) => {
  const videoTabs = [];

  videoProjectStates.forEach((state) => {
    if (ability.can("view", `video_workflow_${state}_tab`)) {
      videoTabs.push({
        url: web.videoWorkflow(state),
        label: I18n.t(`video_workflow.tabs.${state}`),
      });
    }
  });
  if (ability.can("view", `video_workflow_all_states_tab`)) {
    videoTabs.push({
      url: web.videoWorkflowAllStates(),
      label: I18n.t("video_workflow.tabs.all_videos"),
    });
  }
  console.log("videoTabs", videoTabs);
  return videoTabs;
};

export const getLegacyTabs = (ability, selectedWebsite) => {
  const legacyTabs = [];

  if (ability.can("view", "legacy_source_files_tab")) {
    legacyTabs.push({
      url: web.legacySourceFiles(),
      label: I18n.t("legacy.tabs.source_files"),
    });
  }
  if (ability.can("view", "legacy_login_as_tab")) {
    legacyTabs.push({
      url: web.legacyLoginAs(),
      label: I18n.t("legacy.tabs.login_as"),
    });
  }
  if (ability.can("view", "legacy_account_setup_tab")) {
    legacyTabs.push({
      url: web.legacyAccountSetup(),
      label: I18n.t("legacy.tabs.account_setup"),
    });
  }
  if (ability.can("view", "legacy_account_pricing_tab")) {
    legacyTabs.push({
      url: web.legacyAccountPricing(),
      label: I18n.t("legacy.tabs.account_pricing"),
    });
  }
  if (ability.can("view", "legacy_site_stats_tab")) {
    legacyTabs.push({
      url: web.legacyWriterStats(),
      label: I18n.t("legacy.tabs.site_stats"),
    });
  }
  if (ability.can("view", "legacy_insights_tab")) {
    legacyTabs.push({
      url: web.legacyInsights(),
      label: I18n.t("legacy.tabs.insights"),
    });
    if (ability.can("view", "cro_ads_tab")) {
      legacyTabs.push({
        url: web.adsList(),
        label: I18n.t("cro.tabs.ads"),
      });
    }
  }
  if (selectedWebsite?.name === "Entail") {
    legacyTabs.push({
      url: web.entailExperts(),
      label: I18n.t("entail.tabs.experts"),
    });
  }
  return legacyTabs;
};

export const getPageBuilderTabs = (ability) => {
  const pageBuilderTabs = [];
  if (ability.can("view", "page_builder_theme_tab")) {
    pageBuilderTabs.push({
      url: web.theme(),
      label: I18n.t("website.page_types.theme"),
    });
  }
  if (ability.can("view", "page_builder_content_pages_tab")) {
    pageBuilderTabs.push({
      url: web.contentPages(),
      label: I18n.t("website.page_types.verticals"),
    });
  }
  if (ability.can("view", "page_builder_editorial_pages_tab")) {
    pageBuilderTabs.push({
      url: web.editorialPages(),
      label: I18n.t("website.page_types.editorialPages"),
    });
  }
  if (ability.can("view", "page_builder_landing_pages_tab")) {
    pageBuilderTabs.push({
      url: web.landingPages(),
      label: I18n.t("website.page_types.landing_pages"),
    });
  }
  if (ability.can("view", "page_builder_components_tab")) {
    pageBuilderTabs.push({
      url: web.components(),
      label: I18n.t("website.page_types.components"),
    });
  }
  if (ability.can("view", "page_builder_developer_tools_tab")) {
    pageBuilderTabs.push({
      url: web.developerTools(),
      label: I18n.t("website.page_types.developerTools"),
    });
  }

  if (ability.can("view", "seo_tab")) {
    pageBuilderTabs.push({
      url: web.seo(),
      label: I18n.t("main.tabs.seo"),
    });
  }
  return pageBuilderTabs;
};
