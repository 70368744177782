import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { uniqBy } from "lodash";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import MSelectMultiple from "../../../../../components/MSelectMultiple";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import localeHelper from "../../../../../helpers/localeHelper";

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    width: "100%",
  },
  wrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    marginBottom: 10,
    whiteSpace: "pre",
  },
}));

type PropTypes = {
  selectedLocales: string[];
  websites: IWebsite[];

  handleLocalesChange: (locales: string[]) => void;
};

const mapStateToProps = (state) => ({
  websites: state.home.websites,
});

const ExpertLocales = (props: PropTypes) => {
  const classes = useStyles(props);
  const { websites, selectedLocales = [], handleLocalesChange } = props;

  const locales = React.useMemo(() => {
    const array = [];
    websites.map((website) => {
      array.push(website.configurations.locale.defaultLocale);
      website.configurations.locale.alternateLocales.map((locale) => {
        array.push(locale.locale);
      });
    });
    return [...new Set(array.filter((l) => l != null))].map((l) => ({
      key: l,
      label: localeHelper.getLanguageFromLocaleCode(l),
    }));
  }, [websites]);

  console.log("locales", locales);

  return (
    <div className={classes.itemWrapper}>
      <Typography className={ClassNames(classes.title)} variant={"subtitle2"}>
        {I18n.t("experts.profile.locales")}
      </Typography>
      <MSelectMultiple
        wrapperClassName={classes.wrapper}
        options={locales}
        selectedOptions={
          selectedLocales.map((l) => ({ key: l, label: localeHelper.getLanguageFromLocaleCode(l) })) || []
        }
        optionLabel={"label"}
        optionValue={"key"}
        borderWidth={1}
        height={38}
        fullWidth
        placeholder={I18n.t("experts.profile.select_locale")}
        handleOptionSelected={(locale: any) => {
          console.log("locale", locale);
          handleLocalesChange([...new Set([...(selectedLocales || []), locale.key])]);
        }}
        handleOptionRemoved={(locale: any) => {
          console.log("locale", locale);
          const filteredLocales = (selectedLocales || []).filter((l) => l !== locale.key);
          handleLocalesChange(filteredLocales);
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps, null)(ExpertLocales);
