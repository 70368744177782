import React, { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { connect } from "react-redux";
import { RowSelectionState } from "@tanstack/react-table";
import { DATE_FORMAT } from "../../../../../reducers/constants/consts";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function triggerTooltip(chart: ChartJS | null) {
  const tooltip = chart?.tooltip;

  if (!tooltip) {
    return;
  }

  if (tooltip.getActiveElements().length > 0) {
    tooltip.setActiveElements([], { x: 0, y: 0 });
  } else {
    const { chartArea } = chart;

    tooltip.setActiveElements(
      [
        {
          datasetIndex: 0,
          index: 2,
        },
        {
          datasetIndex: 1,
          index: 2,
        },
      ],
      {
        x: (chartArea.left + chartArea.right) / 2,
        y: (chartArea.top + chartArea.bottom) / 2,
      }
    );
  }

  chart.update();
}

type PropTypes = {
  height: number;
  reportLoaded: boolean;
  seriesHeaders: any[];
  colors: object;
  data: any;
  rowSelection: RowSelectionState;
};

const mapStateToProps = (state) => ({
  reportLoaded: state.insights.reportLoaded,
  seriesHeaders: state.insights.seriesHeaders,
  data: state.insights.data,
  colors: state.insights.colors,
  rowSelection: state.insights.rowSelection,
});

const mapDispatchToProps = (dispatch) => ({});

const InsightsChart = (props: PropTypes) => {
  const { height, reportLoaded, seriesHeaders, data, colors, rowSelection } = props;
  const chartRef = useRef<ChartJS>(null);
  const [labels, setLabels] = React.useState([]);
  const [datasets, setDatasets] = React.useState([]);

  React.useEffect(() => {
    if (!reportLoaded) {
      return;
    }
    setLabels(seriesHeaders.map((object) => moment(object).format(DATE_FORMAT.READABLE_CHART)));
    setDatasets(
      data.map((element, index) => ({
        label: element.name,
        borderColor: colors[element.name],
        borderWidth: 2,
        fill: false,
        data: seriesHeaders.map((label) => element.series?.[label] || 0),
        hidden: !rowSelection[index],
      }))
    );
  }, [reportLoaded, seriesHeaders, data, colors, rowSelection]);

  console.log("render chart", height, datasets);
  return (
    <div style={{ height, width: "calc(100vw - 140px)", paddingBottom: 30 }}>
      <Line
        ref={chartRef}
        data={{
          labels,
          datasets,
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,

              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(InsightsChart);
