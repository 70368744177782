import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { find } from "lodash";
import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import TextFieldMaxLength from "../../../components/TextFieldMaxLength";
import CfCheckBox from "../../../components/CfCheckBox";
import { clientPages, withHttps } from "../../../helpers/urlHelper";
import MLink from "../../../components/MLink";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: (props: PropTypes) => props.marginBottom || 0,
  },
  inputRowWrapper: {
    display: "flex",
    gap: 20,
    width: "100%",
  },
  checkBox: {
    width: "50%",
    marginTop: 30,
  },
  previewUrlsWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 15,
    gap: 10,
  },
  previewUrl: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  previewText: {
    fontSize: 12,
  },
  link: {
    color: "#0298C8",
    wordBreak: "break-word",
  },
  linkTypography: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: theme.typography.pxToRem(12),
  },
}));
const mapStateToProps = (state) => ({
  website: state.home.selectedWebsite,
});

type PropTypes = {
  website: IWebsite;
  customUrlPrefix: string;
  locale?: string;
  alternativeUrlPrefix?: string;
  handleValueChange: (value) => void;
  marginBottom?: number;
  previewUrls?: { key: string; value: string; valid?: boolean }[];
};

const CustomUrlPrefixTextField = (props: PropTypes) => {
  const classes = useStyles(props);

  const { website, locale, handleValueChange, customUrlPrefix, alternativeUrlPrefix, previewUrls = [] } = props;
  const isDefaultLocale = locale === undefined || locale === website?.configurations?.locale.defaultLocale;
  const [editUrlPrefixConfirmed, setEditUrlPrefixConfirmed] = useState(false);
  const [emptyPrefix, setEmptyPrefix] = useState(false);

  React.useEffect(() => {
    setEditUrlPrefixConfirmed(false);
  }, [locale]);
  React.useEffect(() => {
    setEmptyPrefix((isDefaultLocale && customUrlPrefix === "") || alternativeUrlPrefix === "");
  }, [customUrlPrefix, alternativeUrlPrefix]);

  const placeHolder = () => {
    if (emptyPrefix) return "";
    return isDefaultLocale
      ? website.configurations.siteDetails.urlPrefix
      : find(website.configurations.locale?.alternateLocales, { locale })?.urlPrefix;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputRowWrapper}>
        <TextFieldMaxLength
          titleAndLengthLocation={"top"}
          value={isDefaultLocale ? customUrlPrefix : alternativeUrlPrefix || ""}
          placeholder={placeHolder()}
          title={I18n.t("website.url_prefix")}
          height={37}
          borderRadius={4}
          marginBottom={0}
          handleValueChange={(value) => {
            if (!editUrlPrefixConfirmed) {
              if (confirm("Changing the url prefix  might break the website. Are you sure you want to change it?")) {
                setEditUrlPrefixConfirmed(true);
              } else {
                return;
              }
            }
            handleValueChange(value);
          }}
        />
        <CfCheckBox
          iconSize={18}
          customClassName={classes.checkBox}
          // customClassName={classes.check}
          label={I18n.t("website.empty_url_prefix")}
          checked={emptyPrefix}
          value={I18n.t("rich_text_editor.insert_link.new_tab")}
          handleChange={(checked) => {
            if (!editUrlPrefixConfirmed) {
              if (confirm("Changing the url prefix  might break the website. Are you sure you want to change it?")) {
                setEditUrlPrefixConfirmed(true);
              } else {
                return;
              }
            }
            handleValueChange(checked ? "" : null);
            setEmptyPrefix(checked);
          }}
        />
      </div>
      {previewUrls && previewUrls.length > 0 && (
        <div className={classes.previewUrlsWrapper}>
          {previewUrls.map((pUrl, i) => {
            const url = `${clientPages.getWebsiteUrl(
              website,
              locale || website?.configurations?.locale.defaultLocale,
              customUrlPrefix,
              alternativeUrlPrefix
            )}${pUrl.value}${website.configurations?.siteDetails?.trailingSlash ? "/" : ""}`;
            return (
              <div key={`${pUrl.key}_${i + 1}`} className={classes.previewUrl}>
                <Typography className={classes.previewText}>{I18n.t(pUrl.key)}</Typography>
                {pUrl.valid ? (
                  <MLink
                    linkClassName={classes.link}
                    typographyClassName={classes.linkTypography}
                    newTab
                    href={withHttps(url)}
                  >
                    {url}
                  </MLink>
                ) : (
                  <Typography className={classes.linkTypography}>{url}</Typography>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, null)(CustomUrlPrefixTextField);
