import React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 36,
    height: 35,
  },
}));

type PropTypes = {
  className?: string;
};

const ProductDetailsIcon = (props: PropTypes) => {
  const classes = useStyles(props);
  const { className } = props;

  return (
    <SvgIcon className={ClassNames(classes.icon, className)} viewBox={"0 0 36 35"} fill="none">
      <rect x="0.333252" width="35" height="35" rx="3" fill="#0298C8" />
      <path
        d="M17.6669 23.1193V18.7497M17.6669 18.7497L12.3625 15.7497M17.6669 18.7497L22.9713 15.7497M17.6667 6L28.1667 12V24L17.6667 30L7.16675 24V12L17.6667 6Z"
        stroke="white"
        fill="none"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};

export default ProductDetailsIcon;
