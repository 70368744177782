import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { kebabCase } from "lodash";
import classNames from "classnames";
import { ReactEditor, useSlate } from "slate-react";
import { Node, Transforms } from "slate";
import MTextField from "../../../components/MTextField";
import { IWebsite } from "../../../reducers/constants/objectTypes";
import TableOfContentsInput from "./TableOfContentsInput";

const useStyles = makeStyles((theme: Theme) => ({
  pLeft: {
    paddingLeft: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  counter: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold as any,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  headingOne: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: theme.typography.pxToRem(30),
  },
  headingTwo: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold as any,
    lineHeight: theme.typography.pxToRem(26),
  },
  wrapper: {
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
}));

type PropTypes = {
  customClass?: string;
  children?: React.ReactNode;
  selectedWebsite?: IWebsite;
};

const TableOfContents = (props: PropTypes) => {
  const classes = useStyles(props);
  const { customClass, children, selectedWebsite } = props;
  let headingOneCount = 0;
  let headingTwoCount = 0;
  const editor = useSlate();
  const contentAsJson = editor.children;
  const handleElementDataChange = (tocTitle, element) => {
    Transforms.setNodes(editor, { tocTitle }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };

  return (
    <div className={classNames(classes.wrapper, customClass)}>
      {contentAsJson?.length > 0 &&
        contentAsJson.map((element) => (
          <>
            {element.type === "heading-one" && (
              <div className={classes.itemWrapper}>
                <a className={classes.link} href={`#${kebabCase(Node.string(element))}`}>
                  <span className={classes.counter}>{`${(headingOneCount += 1)}`}</span>
                </a>
                <TableOfContentsInput
                  element={element}
                  handleChange={(value) => handleElementDataChange(value, element)}
                />
              </div>
            )}

            {element.type === "heading-two" && selectedWebsite.configurations?.commonComponents?.addons?.toc?.showH3s && (
              <div className={classes.pLeft}>
                <a className={classes.link} href={`#${kebabCase(Node.string(element))}`}>
                  <span className={classes.counter}>{`${headingOneCount}.${(headingTwoCount += 1)}`}</span>
                </a>
                <TableOfContentsInput
                  element={element}
                  handleChange={(value) => handleElementDataChange(value, element)}
                />
              </div>
            )}
          </>
        ))}

      {(!contentAsJson ||
        contentAsJson.filter((x) => ["heading-one", "heading-two"].includes(x.type)).length === 0) && (
        <h4>There are no headings or subheading!</h4>
      )}
    </div>
  );
};

export default TableOfContents;
