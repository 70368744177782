import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";

import theme from "../../../../themes/theme";
import { ICategory, IVideoProject, IWebsite, eVideoProjectState } from "../../../../reducers/constants/objectTypes";
import CfButton from "../../../../components/CfButton";
import agent from "../../../../agent";
import MTableToolbar from "../../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarCategoriesFilter from "../../../../components/Table/Toolbar/Filters/MTableToolbarCategoriesFilter";

const useStyles = makeStyles(() => ({
  button: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: 5,
    marginLeft: "auto",
    fontSize: theme.typography.pxToRem(14),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold as any,
    borderRadius: 20,
    height: 36,
    minHeight: 36,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#5967FF",
    },
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  category: ICategory[];
  categories: ICategory[];
  state?: eVideoProjectState;
  searchText: string;

  setSearchText: (text: string) => void;
  setCategory: (input: ICategory[]) => void;
  refreshList: () => void;
  handleVideoProjectCreated: (videoProject: IVideoProject) => void;
};

const ListHeader = (props: PropTypes) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { searchText, category, categories, selectedWebsite, state } = props;
  const { setSearchText, setCategory, refreshList, handleVideoProjectCreated } = props;

  const handleNewProjectClick = () =>
    agent.VideoProjects.create(selectedWebsite, state)
      .then((res) => {
        enqueueSnackbar(I18n.t("snackbar.update_success"));
        handleVideoProjectCreated(res.videoProject);
        refreshList();
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar(I18n.t("snackbar.update_error", { msg: e.message }));
      });

  return (
    <>
      <MTableToolbar
        searchText={searchText}
        searchPlaceHolder={I18n.t("video_workflow.list_header.search_projects")}
        handleSearchTextChanged={setSearchText}
        actions={[
          ...(state &&
          [
            eVideoProjectState.trim,
            eVideoProjectState.brief,
            eVideoProjectState.edit,
            eVideoProjectState.review,
            eVideoProjectState.done,
          ].includes(state)
            ? [
                <CfButton
                  height={36}
                  customClass={classes.button}
                  text={I18n.t("video_workflow.list_header.new_project")}
                  onClick={handleNewProjectClick}
                />,
              ]
            : []),
        ]}
      >
        <MTableToolbarCategoriesFilter
          handleCategoriesSelected={setCategory}
          selectedCategories={category}
          categories={categories}
        />
      </MTableToolbar>
    </>
  );
};

export default ListHeader;
