import React from "react";
import ClassNames from "classnames";
import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GenericOptionContainer from "./GenericOptionContainer";
import MSwitch from "../../../../../../components/MSwitch";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const color = palette.text.primary;

  return {
    root: {
      display: "flex",
      flexDirection: "column",
    },
    optionContainer: {
      padding: "0px 15px",
      marginBottom: 3,
    },
    leftContainer: {
      display: "flex",
      alignItems: "center",
      width: "80%",
    },
    title: {
      width: "100%",
      fontSize: 14,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color,
    },
    rightContainer: {
      display: "flex",
      justifySelf: "flex-end",
      justifyContent: "flex-end",
      alignItems: "end",
      width: "20%",
      textAlign: "right",
    },
    label: {
      marginTop: 2,
      fontSize: 12,
      color,
      opacity: 0.5,
    },
  };
});

type PropTypes = {
  title: string;
  checked?: boolean;
  label?: string;
  rightSide?: JSX.Element;
  children?: JSX.Element;
  customClass?: string;
  onChange?: (checked: boolean) => void;
};

const CustomSwitchOption = (props: PropTypes) => {
  const classes = useStyles(props);
  const { title, checked, label, rightSide, children, customClass } = props;
  const { onChange } = props;

  return (
    <div className={classes.root}>
      <GenericOptionContainer customClass={ClassNames(classes.optionContainer, customClass)}>
        <>
          <div className={classes.leftContainer}>
            <Typography className={classes.title}>{title}</Typography>
          </div>

          <div className={classes.rightContainer}>{rightSide || <MSwitch checked={checked} onChange={onChange} />}</div>
        </>
      </GenericOptionContainer>
      {children}
      {label && <Typography className={classes.label}>{label}</Typography>}
    </div>
  );
};

export default CustomSwitchOption;
