import * as React from "react";
import { I18n } from "react-redux-i18n";

import WidgetPickerElement from "./WidgetPickerElement";
import agent from "../../../../../../../../agent";
import { IWebsite, IWidget, eWidgetType } from "../../../../../../../../reducers/constants/objectTypes";

type PropTypes = {
  uploadedId: string;
  selectedWebsite: IWebsite;
  handleChange: (uploadedId: string) => void;
};

const DialogWidgetPicker = (props: PropTypes) => {
  const { uploadedId, selectedWebsite } = props;
  const { handleChange } = props;
  const [selectedWidget, setSelectedWidget] = React.useState<IWidget | null>(null);

  React.useEffect(() => {
    if (!uploadedId) {
      setSelectedWidget(null);
      return;
    }

    if (uploadedId !== selectedWidget?.uploadedId) {
      agent.Widgets.getWidget(uploadedId, true)
        .then((res) => {
          setSelectedWidget(res.widget);
        })
        .catch((e) => console.log(e));
    }
  }, [uploadedId, selectedWidget]);

  return (
    <WidgetPickerElement
      selectedWebsite={selectedWebsite}
      widget={selectedWidget}
      handleChange={(widget) => handleChange(widget?.uploadedId)}
      widgetTypes={[eWidgetType.DIALOG]}
      activeOnly
      title={I18n.t(`widgets_builder.widget_editor.widget_picker.popup`)}
      placeholder={I18n.t(`widgets_builder.widget_editor.widget_picker.select_popup`)}
    />
  );
};

export default DialogWidgetPicker;
