import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  className?: string;
};

function PreviewIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={className} viewBox={"0 0 19 14"}>
      <path d="M9.23684 10.6833C10.2466 10.6833 11.1034 10.3258 11.8074 9.61083C12.5113 8.89583 12.8633 8.02556 12.8633 7C12.8633 5.97444 12.5113 5.10417 11.8074 4.38917C11.1034 3.67417 10.2466 3.31667 9.23684 3.31667C8.22712 3.31667 7.37027 3.67417 6.66631 4.38917C5.96235 5.10417 5.61037 5.97444 5.61037 7C5.61037 8.02556 5.96235 8.89583 6.66631 9.61083C7.37027 10.3258 8.22712 10.6833 9.23684 10.6833ZM9.23684 9.42667C8.56843 9.42667 8.00313 9.19195 7.54093 8.7225C7.07873 8.25306 6.84763 7.67889 6.84763 7C6.84763 6.32111 7.07873 5.74694 7.54093 5.2775C8.00313 4.80806 8.56843 4.57333 9.23684 4.57333C9.90525 4.57333 10.4706 4.80806 10.9327 5.2775C11.3949 5.74694 11.626 6.32111 11.626 7C11.626 7.67889 11.3949 8.25306 10.9327 8.7225C10.4706 9.19195 9.90525 9.42667 9.23684 9.42667ZM9.23684 13.5C7.27428 13.5 5.48593 12.9583 3.87179 11.875C2.25766 10.7917 1.00972 9.36167 0.127993 7.585C0.0853289 7.51278 0.0533305 7.42611 0.0319983 7.325C0.0106661 7.22389 0 7.11556 0 7C0 6.88444 0.0106661 6.77611 0.0319983 6.675C0.0533305 6.57389 0.0853289 6.48722 0.127993 6.415C1.00972 4.63833 2.25766 3.20833 3.87179 2.125C5.48593 1.04167 7.27428 0.5 9.23684 0.5C11.1994 0.5 12.9878 1.04167 14.6019 2.125C16.216 3.20833 17.464 4.63833 18.3457 6.415C18.3884 6.48722 18.4204 6.57389 18.4417 6.675C18.463 6.77611 18.4737 6.88444 18.4737 7C18.4737 7.11556 18.463 7.22389 18.4417 7.325C18.4204 7.42611 18.3884 7.51278 18.3457 7.585C17.464 9.36167 16.216 10.7917 14.6019 11.875C12.9878 12.9583 11.1994 13.5 9.23684 13.5ZM9.23684 12.2C10.9576 12.2 12.5398 11.7269 13.9833 10.7808C15.4267 9.83472 16.5253 8.57444 17.2791 7C16.5253 5.42556 15.4267 4.16528 13.9833 3.21917C12.5398 2.27306 10.9576 1.8 9.23684 1.8C7.51604 1.8 5.93391 2.27306 4.49043 3.21917C3.04695 4.16528 1.94123 5.42556 1.17327 7C1.94123 8.57444 3.04695 9.83472 4.49043 10.7808C5.93391 11.7269 7.51604 12.2 9.23684 12.2Z" />
    </SvgIcon>
  );
}

export default PreviewIcon;
