import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import { IWebsiteProductPageProps, IWebsiteSectionProps } from "../../../../reducers/constants/objectTypes";
import PageSection from "../../components/PageSection";
import WebsitesSectionsSwitch from "../../components/WebsitesSectionsSwitch";
import WebsiteProductPagesMainPage from "../../components/WebsiteProductPagesMainPage";
import ProductPagesPostSection from "../../components/ProductPagesPostSection";
import MRouterPrompt from "../../components/MRouterPrompt";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import CustomUrlPrefixTextField from "../../components/CustomUrlPrefixTextField";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    color: "red",
    marginTop: 10,
    marginLeft: 20,
  },
}));

const mapStateToProps = (state) => ({
  productPage: state.websitePages.productPage,
  sections: state.websitePages.sections,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (productPage) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "productPage",
      value: productPage,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  productPage: IWebsiteProductPageProps;
  sections: IWebsiteSectionProps;
  onPropertyChanged: (productPage) => void;
  handleToggleActive: (section, enabled) => void;
};
const PageProductPage = (props: PropTypes) => {
  const classes = useStyles(props);
  const { productPage, onPropertyChanged, sections, handleToggleActive } = props;
  const [error, setError] = React.useState(false);
  const [emptyFields, setEmptyFields] = React.useState([]);

  React.useEffect(() => {
    if (emptyFields.length <= 0 && !error) {
      return;
    }
    textFieldsValidated();
  }, [productPage]);

  const textFieldsValidated = () => {
    setEmptyFields([]);
    let valid = true;
    const emptyFields = [];
    if (!productPage.name) {
      valid = false;
      emptyFields.push("name");
    }
    if (!productPage.mainPage.slug) {
      valid = false;
      emptyFields.push("mainPage.slug");
    }
    if (!productPage.mainPage.metaTitle) {
      valid = false;
      emptyFields.push("mainPage.metaTitle");
    }
    if (!productPage.mainPage.metaDescription) {
      valid = false;
      emptyFields.push("mainPage.metaDescription");
    }
    if (!productPage.mainPage.title.text) {
      valid = false;
      emptyFields.push("mainPage.title");
    }
    if (!productPage.mainPage.description.text) {
      valid = false;
      emptyFields.push("mainPage.description");
    }
    if (!productPage.mainPage.listTitle.text) {
      valid = false;
      emptyFields.push("mainPage.listTitle");
    }
    if (!productPage.postPage.metaTitle) {
      valid = false;
      emptyFields.push("postPage.metaTitle");
    }
    if (!productPage.postPage.metaDescription) {
      valid = false;
      emptyFields.push("postPage.metaDescription");
    }
    if (!productPage.postPage.disclaimer) {
      valid = false;
      emptyFields.push("postPage.disclaimer");
    }
    setEmptyFields(emptyFields);
    return valid;
  };

  return (
    <>
      <MRouterPrompt />
      <WebsitesSectionsSwitch
        inSection
        enabled={sections.productPage}
        title={I18n.t(`website.page_types.productPage`)}
        handleEnabledChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.productPage) {
              handleToggleActive("productPage", enabled);
            }
            setError(true);
          } else {
            setError(false);
            handleToggleActive("productPage", enabled);
          }
        }}
      >
        <CustomUrlPrefixTextField
          customUrlPrefix={productPage.urlPrefix}
          handleValueChange={(value) => onPropertyChanged({ ...productPage, urlPrefix: value })}
          previewUrls={[
            {
              key: "website.product_page.main_page_preview_url",
              value: productPage.mainPage.slug,
              valid: sections.productPage,
            },
            {
              key: "website.product_page.post_page_preview_url",
              value: `${productPage.mainPage.slug}/post-slug`,
            },
          ]}
        />
      </WebsitesSectionsSwitch>
      {error && (
        <Typography variant={"body1"} className={classes.error}>
          {emptyFields.length > 0 && I18n.t("website.error_message")}
        </Typography>
      )}

      <PageSection sectionTitle={I18n.t("website.product_page.main_page")}>
        <WebsiteProductPagesMainPage
          emptyFields={emptyFields}
          page={productPage}
          onPropertyChanged={onPropertyChanged}
        />
      </PageSection>

      <PageSection sectionTitle={I18n.t("website.product_page.post_page")}>
        <ProductPagesPostSection
          pageName={I18n.t("website.product_page.post_page").toLowerCase()}
          emptyFields={emptyFields}
          page={productPage}
          onPropertyChanged={onPropertyChanged}
        />
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageProductPage);
