import * as React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "react-redux-i18n";
import { Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { WRITER_BIO } from "../../../../../../reducers/constants/actionTypes";

const useStyles = makeStyles((theme: Theme) => ({
  sectionNameAndSwitchWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "13px 20px",
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  sectionWrapper: {
    borderRadius: 0,
    border: "none",
    marginTop: 10,
    paddingBottom: 30,
    "&:first-child": {
      marginTop: 0,
      paddingBottom: 0,
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    textTransform: "capitalize",
  },
}));

type PropTypes = {
  active: boolean;
  onPropertyChanged: (active) => null;
};

const mapStateToProps = (state) => ({
  active: state.writerBio.active,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (active) => dispatch({ type: WRITER_BIO.ON_PROPERTY_CHANGED, name: "active", value: active }),
});

const StatusSection = (props: PropTypes) => {
  const classes = useStyles(props);
  const { active } = props;
  const { onPropertyChanged } = props;

  return (
    <Paper elevation={0} className={classes.sectionWrapper}>
      <div className={classes.sectionNameAndSwitchWrapper}>
        <Typography className={classes.title} color={"textPrimary"} variant={"subtitle1"}>
          {I18n.t("manager.writers_bio.status")}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={() => {
                  onPropertyChanged(!active);
                }}
                color="primary"
              />
            }
            labelPlacement="start"
            label={I18n.t(`manager.writers_bio.${active ? "active" : "inactive"}`)}
          />
        </FormGroup>
      </div>
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusSection);
