import { WIDGETS_DISPLAY_RULES } from "./constants/actionTypes";

const initialState = {
  rule: null,
  rules: [],
  loading: false,
  page: 1,
  pageSize: 20,
  totalPages: null,
  searchText: "",
  statuses: [],
  checkedRules: [],
  sortField: { direction: "desc", field: "analytics.last_month.totalClicks" },
  timePeriod: { value: 1, period: "month", key: "last_month", label: "Last month" },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case WIDGETS_DISPLAY_RULES.RULES_LOADED:
      return {
        ...state,
        rules: action.payload.rules,
        loading: false,
        totalPages: action.payload.totalPages,
        page: action.payload.page,
        pageSize: action.payload.limit,
      };

    case WIDGETS_DISPLAY_RULES.PROPERTY_CHANGE:
      return {
        ...state,
        ...action.property,
      };

    default:
      return state;
  }
};
