import React, { useEffect } from "react";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { FaqElement } from "../../../types/editor.Types";
import useDebounce from "../../../../hooks/useDebounce";
import richTextEditorHelper from "../../../helper/richTextEditorHelper";

type PropTypes = {
  editor: Editor;
  element: FaqElement;
};

const emptyFaqRow = { question: "", answer: richTextEditorHelper.getEmptyRichText() };

const useFAQWidgetStyle = (props: PropTypes) => {
  const { editor, element } = props;
  const [localElement, setLocalElement] = React.useState(element);

  const debouncedLocalElement = useDebounce(localElement, 500);

  const handleElementDataChange = (data) => {
    try {
      Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
    } catch (e) {
      console.log("handleElementDataChange", e);
    }
  };

  const addNewItem = () => {
    setLocalElement({
      ...localElement,
      data: {
        ...localElement.data,
        list: [
          ...localElement.data.list,
          ...(element.data.list.length === 0 ? [emptyFaqRow, emptyFaqRow] : [emptyFaqRow]),
        ],
      },
    });
  };

  const deleteItem = (index) => {
    setLocalElement({
      ...localElement,
      data: {
        ...localElement.data,
        list: localElement.data.list.filter((val, arrIndex) => arrIndex !== index),
      },
    });
  };

  const handleItemChange = (faq: FaqElement["data"]["list"][number], index) => {
    const list = [...localElement.data.list];
    list[index] = faq;
    setLocalElement({ ...localElement, data: { ...localElement.data, list } });
  };

  useEffect(() => {
    handleElementDataChange(debouncedLocalElement.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLocalElement]);

  useEffect(() => {
    if (element.data.uuid !== localElement.data.uuid) {
      setLocalElement(element);
    }
  }, [element, localElement.data.uuid]);

  if (!element.data.formatted) {
    return null;
  }

  return {
    element: localElement,
    handleElementChange: setLocalElement,
    handleItemChange,
    addNewItem,
    deleteItem,
  };
};

export default useFAQWidgetStyle;
