import React, { useEffect, useMemo, useState } from "react";
import { I18n } from "react-redux-i18n";
import MDropdown from "../../../../../components/MDropdown";

type PropTypes = {
  value: any;
  selectedOptions?: any[];
  handleOnChange: (value) => void;
};

export const sixMonthOptions = [
  { value: 1, period: "day", key: "yesterday", label: "Yesterday" },
  { value: 1, period: "week", key: "last_week", label: "Last week" },
  { value: 2, period: "week", key: "last_2_weeks", label: "Last 2 weeks" },
  { value: 1, period: "month", key: "last_month", label: "Last month" },
  { value: 3, period: "month", key: "last_3_months", label: "Last 3 months" },
  { value: 6, period: "month", key: "last_6_months", label: "Last 6 months" },
];

export const options = [
  { value: 1, period: "month", key: "last_month", label: "Last month" },
  { value: 3, period: "month", key: "last_3_months", label: "Last 3 months" },
  { value: 6, period: "month", key: "last_6_months", label: "Last 6 months" },
  { value: 12, period: "month", key: "last_12_months", label: "Last 12 months" },
  { value: 24, period: "month", key: "last_2_years", label: "Last 2 years" },
];

const TimePeriodFilter = (props: PropTypes) => {
  const { value, selectedOptions = options, handleOnChange } = props;

  return (
    <MDropdown
      options={selectedOptions}
      value={value}
      handleOnChange={handleOnChange}
      placeholder={I18n.t("reports.dashboard.select_ph")}
      optionLabel="label"
      optionValue="key"
    />
  );
};

export default TimePeriodFilter;
