import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { push } from "connected-react-router";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import MoreOptionsButton from "../../../WorkFlow/PageList/components/MoreOptionsButton";
import { ILandingPage, IWebsite } from "../../../../reducers/constants/objectTypes";
import agent from "../../../../agent";
import { web } from "../../../../helpers/urlHelper";
import heading from "../../../../img/heading.png";
import MTableRow from "../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../components/Table/Cells/MTableCellFirst";
import MTableCellText from "../../../../components/Table/Cells/MTableCellText";
import MTableCellDate from "../../../../components/Table/Cells/MTableCellDate";

const useStyles = makeStyles((theme: Theme) => ({
  iconColumn: {
    width: 40,
  },
  imgRow: { height: 25, width: 25, borderRadius: 100, marginRight: 15 },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  landingPage: ILandingPage;
  editPage: (type: string, landingPageId: string) => void;
  handleRefreshList: () => void;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  editPage: (type: string, landingPageId: string) => dispatch(push(web.managerLandingPage(type, landingPageId))),
});

const LandingPageListItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const { selectedWebsite, landingPage, editPage, handleRefreshList } = props;
  const theme = useTheme();
  const selectLandingPage = () => {
    editPage(landingPage.type, landingPage._id);
  };

  const deleteLandingPage = () => {
    agent.LandingPages.deleteLandingPage(selectedWebsite._id, landingPage._id)
      .then((res) => {
        enqueueSnackbar(I18n.t("snackbar.general_delete_success", { name: landingPage.name }));
        handleRefreshList();
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.general_delete_error", { name: landingPage.name, msg: e.message }));
      });
  };

  return (
    <MTableRow
      handleRowClick={() => {
        selectLandingPage();
      }}
    >
      <MTableCellFirst
        columnWidth={"40%"}
        prefix={<img alt={"form image"} src={heading} className={classes.imgRow} />}
        fontSize={theme.typography.pxToRem(14)}
        cellText={landingPage?.name}
      />
      <MTableCellText text={landingPage.type} />
      <MTableCellText text={landingPage.status} />
      <MTableCellDate date={landingPage.createdAt} />
      <div className={classes.iconColumn}>
        <MoreOptionsButton handleDeleteClick={() => deleteLandingPage()} />
      </div>
    </MTableRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageListItem);
