import React, { useMemo } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import ClassNames from "classnames";
import theme from "../../../../../themes/theme";

const useStyles = makeStyles((theme: Theme) => ({
  radioButtonRoot: {
    padding: 5,
    color: "#dadada",
    "&$radioButtonChecked": {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    fontSize: 15,
  },
  radioButtonChecked: {},
}));

type PropTypes = {
  checked: boolean;
  indeterminate: boolean;
  value: string;
  stringToColor?: string;
  iconColor?: string;
  rootClassName?: string;
  handleChange?: (e) => void;
};

const getColorFromString = (string) => {
  if (!string) {
    return null;
  }
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
};

const InsightsTableCheckBox = (props: PropTypes) => {
  const classes = useStyles(props);
  const { checked, indeterminate, value, stringToColor = null, rootClassName = null, iconColor, handleChange } = props;
  const iconFillColor = useMemo(
    () => iconColor || getColorFromString(stringToColor) || theme.palette.primary.main,
    [iconColor, stringToColor]
  );
  return (
    <Checkbox
      checked={checked}
      indeterminate={indeterminate}
      classes={{
        root: ClassNames(classes.radioButtonRoot, rootClassName),
        checked: classes.radioButtonChecked,
      }}
      icon={<RadioButtonUncheckedIcon style={{ fill: iconFillColor }} className={classes.icon} fontSize={"inherit"} />}
      checkedIcon={<CheckCircleIcon style={{ fill: iconFillColor }} className={classes.icon} fontSize={"inherit"} />}
      indeterminateIcon={
        <RemoveCircleOutlineRoundedIcon style={{ fill: iconFillColor }} className={classes.icon} fontSize={"inherit"} />
      }
      onChange={handleChange}
      value={value}
      name={value}
      aria-label={value}
    />
  );
};

export default InsightsTableCheckBox;
