import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  className?: string;
};

function InLineHTMLIcon(props: PropTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={className} viewBox={"0 0 1000 1000"}>
      <g>
        <g>
          <path d="M654.7,680.5l77.4,77.4L990,500L732.1,242.1l-77.4,77.4L835.3,500L654.7,680.5z" />
          <path d="M345.3,319.5l-77.4-77.4L10,500l257.9,257.9l77.4-77.4L164.7,500L345.3,319.5z" />
          <path d="M549.4,208.7l56,15.3L450.7,791.3l-56-15.3L549.4,208.7L549.4,208.7z" />
        </g>
      </g>
    </SvgIcon>
  );
}

export default InLineHTMLIcon;
