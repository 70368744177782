import { I18n } from "react-redux-i18n";
import { eAcfFieldType, eAcfType, ePageType, IAdminUser, IWebsite } from "../reducers/constants/objectTypes";

export const getAvailablePageTypes = (selectedWebsite) => {
  const pageTypes = [];

  if (!selectedWebsite) {
    return pageTypes;
  }

  if (selectedWebsite.configurations.sections.blog) {
    pageTypes.push({
      key: ePageType.Blog,
      label: I18n.t("main.new_post.blog"),
    });
  }
  if (selectedWebsite.configurations.sections.learn) {
    pageTypes.push({
      key: ePageType.Targeted,
      label: I18n.t("main.new_post.targeted"),
    });
  }

  if (selectedWebsite.configurations.sections.comparisonTop10) {
    pageTypes.push({
      key: ePageType.ComparisonTop10,
      label: I18n.t("main.new_post.top_apps"),
    });
  }

  if (selectedWebsite.configurations.sections.comparisonTopAlternative) {
    pageTypes.push({
      key: ePageType.ComparisonTopAlternatives,
      label: I18n.t("main.new_post.top_alternatives"),
    });
  }
  if (selectedWebsite.configurations.sections.productAlternative) {
    pageTypes.push({
      key: ePageType.ProductAlternative,
      label: I18n.t("main.new_post.alternative"),
    });
  }
  if (selectedWebsite.configurations.sections.productReview) {
    pageTypes.push({
      key: ePageType.ProductReview,
      label: I18n.t("main.new_post.review"),
    });
  }
  if (selectedWebsite.configurations.sections.productComparison) {
    pageTypes.push({
      key: ePageType.ProductComparison,
      label: I18n.t("main.new_post.comparison"),
    });
  }
  if (selectedWebsite.configurations.sections.productPage) {
    pageTypes.push({
      key: ePageType.ProductPage,
      label: I18n.t("main.new_post.product_page"),
    });
  }

  if (selectedWebsite.configurations.sections.ppcTop10) {
    pageTypes.push({
      key: ePageType.PpcTop10,
      label: I18n.t("main.new_post.ppc_top_10"),
    });
  }

  selectedWebsite.configurations.dynamicSections.map((dynamicSection) => {
    if (dynamicSection.enabled && !pageTypes.includes(dynamicSection.type)) {
      pageTypes.push({
        key: dynamicSection.type,
        label: dynamicSection.name,
      });
    }
  });

  return pageTypes;
};

export const getWordpressAcfTabs = (selectedWebsite) => {
  switch (selectedWebsite.id) {
    case "66659862a87e12c534a73c84": //  papaya global dev
    case "66658879c3660b20b29611e8": // papaya global staging
    case "65914a434d1c73e01237a4fa": // papaya global
      return [
        {
          id: "acf_toc",
          title: I18n.t("custom_post.acf.toc.name"),
          fields: [
            {
              key: "block_post_navigation",
              type: eAcfType.TableOfContents,
              items: [
                {
                  key: "link_text",
                  type: eAcfFieldType.Text,
                  label: I18n.t("custom_post.acf.toc.link_text"),
                },
                {
                  key: "link_anchor",
                  isId: true,
                  type: eAcfFieldType.Text,
                  label: I18n.t("custom_post.acf.toc.link_anchor"),
                },
              ],
            },
          ],
        },
        {
          id: "acf_sidebar_cta",
          type: eAcfType.SidebarCTA,
          title: I18n.t("custom_post.acf.sidebar_cta.name"),
          fields: [
            {
              key: "use_own_scta",
              type: eAcfFieldType.Switch,
              label: I18n.t("custom_post.acf.sidebar_cta.use_own_cta"),
            },
            {
              key: "scta_title",
              type: eAcfFieldType.Text,
              label: I18n.t("custom_post.acf.sidebar_cta.title"),
              width: "80%",
            },
            {
              key: "scta_description",
              type: eAcfFieldType.Text,
              label: I18n.t("custom_post.acf.sidebar_cta.description"),
              width: "80%",
            },
            {
              key: "scta_btn.title",
              type: eAcfFieldType.Text,
              label: I18n.t("custom_post.acf.sidebar_cta.button_text"),
              width: "80%",
            },
            {
              key: "scta_btn.url",
              type: eAcfFieldType.Text,
              label: I18n.t("custom_post.acf.sidebar_cta.button_url"),
              width: "80%",
            },
            {
              key: "scta_btn.target",
              trueValue: "_blank",
              falseValue: "",
              checkBox: true,
              type: eAcfFieldType.Switch,
              label: I18n.t("custom_post.acf.sidebar_cta.button_target"),
            },
          ],
        },
        {
          title: I18n.t("custom_post.acf.key_list.name"),
          type: eAcfType.Repeater,
          id: "acf_key_list",
          fields: [
            {
              key: "kl_title",
              type: eAcfFieldType.Text,
              label: I18n.t("custom_post.acf.key_list.title"),
            },
            {
              key: "key_list",
              type: eAcfFieldType.List,
              label: I18n.t("custom_post.acf.key_list.list_title"),
              item: {
                key: "item",
                type: eAcfFieldType.Text,
                label: I18n.t("custom_post.acf.key_list.item_title"),
              },
            },
          ],
        },
        {
          id: "acf_faqs",
          title: I18n.t("custom_post.acf.faq.name"),
          type: eAcfType.Repeater,

          fields: [
            {
              key: "faqs_title",
              type: eAcfFieldType.Text,
              label: I18n.t("custom_post.acf.faq.title"),
            },
            {
              key: "faqs",
              type: eAcfFieldType.ObjectList,
              label: I18n.t("custom_post.acf.faq.list.title"),
              items: [
                {
                  key: "faq_title",
                  clearable: true,
                  type: eAcfFieldType.Text,
                  label: I18n.t("custom_post.acf.faq.list.question"),
                },
                {
                  key: "faq_description",
                  type: eAcfFieldType.richText,
                  label: I18n.t("custom_post.acf.faq.list.answer"),
                },
              ],
            },
          ],
        },
      ];
    default:
      return [];
  }
};

export const isOnboardingDone = (website: IWebsite, user: IAdminUser) => {
  if (!user) {
    return false;
  }
  if (!website) {
    return false;
  }
  if (!website.url) {
    return false;
  }

  const currentUserWebsiteRole = user.websiteRoles?.find(
    (role) => role.website?.toString() === website._id || role.website?._id === website._id
  );

  if (!currentUserWebsiteRole) {
    return false;
  }

  if (!website.configurations?.siteDetails?.targetAudience?.target?._id) {
    return false;
  }

  if (!website.configurations?.siteDetails?.targetAudience?.type?._id) {
    return false;
  }

  if (!website.configurations?.siteDetails?.targetAudience?.goal?._id) {
    return false;
  }

  return website.configurations?.siteDetails?.targetAudience?.pageUrl;
};
