import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { I18n } from "react-redux-i18n";
import { Button, Theme, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import SelectProperty from "../../build/items/itemProperties/components/SelectProperty";
import ListBaseComponent from "../../../../../../../editor/elementsStyles/BaseComponents/ListBaseComponent";
import FontColorCSS from "../../../../../../../editor/elementsStyles/CssComponents/FontColorCSS";
import BorderRadiusCSS from "../../../../../../../editor/elementsStyles/CssComponents/BorderRadiusCSS";
import PaddingCSS from "../../../../../../../editor/elementsStyles/CssComponents/PaddingCSS";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";
import DesignButtonModel from "../models/DesignButtonModel";
import { IWebsite } from "../../../../../../../reducers/constants/objectTypes";
import { widgetDesignDefaultValues } from "../../../constants";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    padding: "30px",
  },
  wrapper: {
    backgroundColor: palette.secondary.main,
    borderRadius: "5px",
    padding: "25px 15px 15px 15px",
  },
  headerItems: {
    fontSize: "16px",
    verticalAlign: "middle",
    display: "flex",
    padding: "0px 0px 10px 0px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowBack: {
    display: "flex",
    marginLeft: "-8px",
    cursor: "pointer",
  },
  backIcon: {
    height: "22px",
    color: palette.common.white,
  },
  button: {
    background: palette.primary.light,
    justifySelf: "flex-end",
    height: "27px",
    width: "54px",
    fontSize: "14px",
    fontWeight: typography.fontWeightLight,
    textTransform: "none",
  },
  head: {
    userSelect: "none",
    color: palette.common.white,
    fontSize: "16px",
    lineHeight: "21px",
  },
  body: {
    width: "100%",
    borderRadius: "5px",
    marginTop: "10px",
  },
  marginTop: {
    marginTop: 10,
  },
}));

const mapStateToProps = (state) => ({
  website: state.home.selectedWebsite,
  buttonDesign: state.widgetBuilder.widget.buttonDesign,
});

const mapDispatchToProps = (dispatch) => ({
  changeButtonDesign: (buttonDesign) =>
    dispatch({ type: WIDGET_BUILDER.DESIGN, key: "buttonDesign", value: buttonDesign }),
});

type PropTypes = {
  website: IWebsite;
  buttonDesign: DesignButtonModel;
  goBack: (any) => void;
  changeButtonDesign: (buttonDesign: DesignButtonModel) => void;
};
const ButtonDesign = (props: PropTypes) => {
  const classes = useStyles(props);
  const { goBack, buttonDesign, changeButtonDesign, website } = props;

  const [newButtonDesign, setNewButtonDesign] = React.useState(buttonDesign);
  console.log("buttonDesign", buttonDesign);
  const remoteWebsiteTheme = React.useMemo(
    () => ({
      alignment: "Left",
      fontSize: website.configurations.theme.buttons.css.fontSize || widgetDesignDefaultValues.buttonDesign.fontSize,
      textColor: website.configurations.theme.palette.text.primary,
      backgroundColor: website.configurations.theme.palette.primary.main,
      borderRadius:
        website.configurations.theme.buttons.css.borderRadius || widgetDesignDefaultValues.buttonDesign.borderRadius,
      padding: website.configurations.theme.buttons.css.padding || widgetDesignDefaultValues.buttonDesign.padding,
    }),
    [website]
  );

  // 8 - 90
  const fontSizeOptions = React.useMemo(() => [...Array(113).keys()].map((k) => `${k + 8}px`), []);

  function handleClick() {
    changeButtonDesign(newButtonDesign);
    goBack(null);
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.headerItems}>
          <div className={classes.arrowBack} onClick={() => goBack(null)}>
            <KeyboardArrowLeftRounded className={classes.backIcon} />
            <Typography variant={"h6"} className={classes.head}>
              {I18n.t("widgets_builder.design.button")}
            </Typography>
          </div>
          <Button className={classes.button} onClick={() => handleClick()}>
            {I18n.t("widgets_builder.design.done")}
          </Button>
        </div>
        <div className={classes.body}>
          <SelectProperty
            label={I18n.t("widgets_builder.design.alignment")}
            showLabel
            value={newButtonDesign?.alignment || remoteWebsiteTheme?.alignment}
            options={["Left", "Center", "Right"]}
            onChange={(option) => {
              setNewButtonDesign({ ...newButtonDesign, alignment: option });
            }}
          />
          <div className={classes.marginTop}>
            <ListBaseComponent
              title={I18n.t("widgets_builder.design.font_size")}
              value={newButtonDesign?.fontSize || remoteWebsiteTheme?.fontSize}
              selectOptions={fontSizeOptions}
              isValueChanged={false}
              borderColor="#525252"
              handleChange={(fontSize) => {
                setNewButtonDesign({ ...newButtonDesign, fontSize });
              }}
            />
          </div>
          <FontColorCSS
            title={I18n.t("widgets_builder.design.text_color")}
            value={newButtonDesign?.textColor || remoteWebsiteTheme?.textColor}
            defaultValue={"white"}
            isValueChanged={true}
            handleChange={(textColor) => {
              setNewButtonDesign({ ...newButtonDesign, textColor });
            }}
          />
          <FontColorCSS
            title={I18n.t("widgets_builder.design.background_color")}
            value={newButtonDesign?.backgroundColor || remoteWebsiteTheme?.backgroundColor}
            defaultValue={"white"}
            isValueChanged={true}
            handleChange={(backgroundColor) => {
              setNewButtonDesign({ ...newButtonDesign, backgroundColor });
            }}
          />
          <BorderRadiusCSS
            value={newButtonDesign?.borderRadius || remoteWebsiteTheme?.borderRadius}
            defaultValue={"0px 0px 0px 0px"}
            handleChange={(borderRadius) => {
              setNewButtonDesign({ ...newButtonDesign, borderRadius });
            }}
          />

          <PaddingCSS
            value={newButtonDesign?.padding || remoteWebsiteTheme?.padding}
            defaultValue={"0px 0px 0px 0px"}
            handleChange={(padding) => {
              setNewButtonDesign({ ...newButtonDesign, padding });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonDesign);
