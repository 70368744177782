import React from "react";
import { I18n } from "react-redux-i18n";
import { makeStyles, Theme } from "@material-ui/core/styles";
import numeral from "numeral";
import { eActionIcon } from "../../../reducers/constants/objectTypes";
import { getSearchConsoleUrl } from "../../../helpers/urlHelper";
import { copyStringToClipboard } from "../../../helpers/fbScraperHelper";
import { useSnackbar } from "notistack";
import theme from "../../../themes/theme";
import MTableRow from "../Rows/MTableRow";
import MTableCellFirst from "./MTableCellFirst";
import MTableCellText from "./MTableCellText";
import moment from "moment";
import { DATE_FORMAT } from "../../../reducers/constants/consts";
import Tooltip from "@material-ui/core/Tooltip";
import MTable from "../Table/MTable";
import MTableHeader from "../Table/MTableHeader";
import MTableHeaderLabel from "../Table/MTableHeaderLabel";

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    flexGrow: 1,
    flexBasis: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textDecoration: "underline",
    paddingRight: 10,
    fontSize: 12,
  },
  tooltip: {
    maxHeight: 300,
    maxWidth: 800,
    minWidth: (props: PropTypes) => props.tooltipMinWidth || 300,
    padding: 10,
    fontWeight: theme.typography.fontWeightRegular as any,
    background: "none",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: "1px 1px 7px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
    margin: 0,
    marginLeft: "-10px",
    marginTop: 3,
    overflow: "auto",
  },
  table: {
    width: 750,
  },
  firstCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: theme.typography.fontWeightRegular as any,
  },
  header: {
    padding: "10px 10px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  row: {
    padding: "10px 10px",
    border: "none",
    height: "auto",
    "&:hover": {
      padding: "10px 10px !important",
      border: "none",
    },
  },
  actions: {
    marginLeft: 5,
    position: "absolute",
    right: 0,
    zIndex: 1,
    padding: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}));

type PropTypes = {
  tooltipMinWidth?: number | string;
  columnTypes: ("text" | "number" | "date" | "hidden")[];
  firstColumnWidth?: string;
  titles: string[];
  rows: (string | number | any)[][];
  children?: any;
  getActionValue?: (type: eActionIcon, row) => any;
  actions?: eActionIcon[];
};

const MTableCellWithElementListTooltip = (props: PropTypes) => {
  const classes = useStyles(props);
  const { columnTypes, firstColumnWidth = "80%", titles, rows, children, actions = [], getActionValue = null } = props;
  const { enqueueSnackbar } = useSnackbar();

  const tableActions = React.useMemo(() => {
    const list = [];
    if (actions.includes(eActionIcon.openInNew)) {
      list.push({
        text: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
        tooltip: I18n.t(`learning_center_content.tooltip.open_in_new_tab`),
        onClick: (event, row) => {
          window.open(getActionValue ? getActionValue(eActionIcon.openInNew, row) : row[0], "_blank");
        },
        iconName: eActionIcon.openInNew,
      });
    }
    if (actions.includes(eActionIcon.search)) {
      list.push({
        text: I18n.t(`learning_center_content.tooltip.google_search_console`),
        tooltip: I18n.t(`learning_center_content.tooltip.google_search_console`),
        onClick: (event, row) =>
          window.open(getSearchConsoleUrl(getActionValue ? getActionValue(eActionIcon.search, row) : row[0]), "_blank"),
        iconName: eActionIcon.search,
      });
    }

    if (actions.includes(eActionIcon.copyName)) {
      list.push({
        text: I18n.t(`learning_center_content.tooltip.copy_title`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_title`),
        onClick: (event, row) =>
          copyStringToClipboard(getActionValue ? getActionValue(eActionIcon.copyName, row) : row[0], () =>
            enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))
          ),
        iconName: eActionIcon.copyName,
      });
    }

    if (actions.includes(eActionIcon.copyToClipboard)) {
      list.push({
        text: I18n.t(`learning_center_content.tooltip.copy_url`),
        tooltip: I18n.t(`learning_center_content.tooltip.copy_url`),
        onClick: (event, row) =>
          copyStringToClipboard(getActionValue ? getActionValue(eActionIcon.copyToClipboard, row) : row[0], () =>
            enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard"))
          ),
        iconName: eActionIcon.copyToClipboard,
      });
    }

    if (actions.includes(eActionIcon.openInEditor)) {
      list.push({
        text: I18n.t("outline.icons.open_in_editor"),
        tooltip: I18n.t("outline.icons.open_in_editor"),
        onClick: (event, row) => {
          window.open(getActionValue ? getActionValue(eActionIcon.openInEditor, row) : row[0], "_blank");
        },
        iconName: eActionIcon.openInEditor,
      });
    }
    return list;
  }, [actions]);

  const getCellData = (data, cellType) => {
    switch (cellType) {
      case "text":
        return data;
      case "number":
        return numeral(data).format("0,0[.]");
      case "date":
        return moment(data).format(DATE_FORMAT.READABLE_CHART);
    }
  };

  return (
    <Tooltip
      // open={rows.length > 0}
      interactive
      classes={{
        tooltip: classes.tooltip,
      }}
      disableHoverListener={rows?.length === 0}
      title={
        <MTable>
          <MTableHeader top={0} className={classes.header}>
            <MTableHeaderLabel columnWidth={firstColumnWidth}>{titles[0]}</MTableHeaderLabel>
            {titles
              .slice(1)
              .filter((title, index) => columnTypes[index + 1] !== "hidden")
              .map((title, index) => (
                <MTableHeaderLabel key={`title_${index}`}>{title}</MTableHeaderLabel>
              ))}
          </MTableHeader>
          {rows.map((row, rowIndex) => (
            <MTableRow
              key={`row_${rowIndex}`}
              handleRowClick={(e) => e.stopPropagation()}
              customClassName={classes.row}
              showBorder={false}
            >
              <MTableCellFirst
                columnWidth={firstColumnWidth}
                wrapperClassName={classes.firstCell}
                fontSize={theme.typography.pxToRem(14)}
                cellText={getCellData(row[0], columnTypes[0])}
                actionsObject={row}
                actions={tableActions}
                actionClassName={classes.actions}
              />
              {row
                .slice(1)
                .filter((title, index) => columnTypes[index + 1] !== "hidden")
                .map((cell, index) => (
                  <MTableCellText text={getCellData(cell, columnTypes[index])} key={`cell_${rowIndex}_${index}`} />
                ))}
            </MTableRow>
          ))}
        </MTable>
      }
    >
      <div className={classes.column}>{children}</div>
    </Tooltip>
  );
};

export default MTableCellWithElementListTooltip;
