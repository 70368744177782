import { Divider, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldProperty from "./itemProperties/components/TextFieldProperty";
import { eWidgetItemsAlignment, IWidgetHeadingFieldProps } from "../../../../../../../reducers/constants/objectTypes";
import SelectProperty from "./itemProperties/components/SelectProperty";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({}),
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
    selectProperty: { padding: "0px 15px 0px 15px", height: 55 },
  };
});

type PropTypes = {
  properties: IWidgetHeadingFieldProps;
  onPropertyChange(properties: IWidgetHeadingFieldProps): void;
};

const HeadingWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;
  const classes = useStyles(props);

  console.log('HeadingWidgetItem"HeadingWidgetItem', properties);

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.title")}
          placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.title_ph")}
          onChange={(title) => onPropertyChange({ ...properties, title })}
          value={properties?.title}
        />
        <Divider className={classes.divider} />
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.caption")}
          placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.caption_ph")}
          onChange={(caption) => onPropertyChange({ ...properties, caption })}
          value={properties?.caption}
        />
        <Divider className={classes.divider} />
        <SelectProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.alignment")}
          showLabel
          onChange={(alignment) => onPropertyChange({ ...properties, alignment })}
          options={[eWidgetItemsAlignment.LEFT, eWidgetItemsAlignment.CENTER, eWidgetItemsAlignment.RIGHT]}
          value={properties.alignment}
          className={classes.selectProperty}
        />
      </div>
    </div>
  );
};

export default HeadingWidgetItem;
