import filter from "lodash/filter";
import _ from "lodash";
import { BLOG, CATEGORIZE, INSIGHTS } from "./constants/actionTypes";
import moment from "moment";

const initialState = {
  startDate: moment().subtract(7, "day").format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
  reportType: "website",
  interval: "day",
  reportLoaded: false,
  page: 1,
  pageSize: 10,
  totalPages: null,
  colors: [],
  seriesHeaders: [],
  data: [],
  searchText: null,

  isLoading: false,

  rowSelection: {},

  eventNames: [],
  urls: [],
  urlSource: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INSIGHTS.ON_REPORT_LOADED:
      return {
        ...state,
        reportLoaded: true,
        isLoading: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        interval: action.payload.interval,

        page: action.payload.page,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,

        colors: action.payload.colors,
        seriesHeaders: action.payload.seriesHeaders,
        data: action.payload.data,
        rowSelection: Object.assign(
          {},
          ...(action.payload.data || []).slice(0, 10).map((row, index) => ({ [index]: true }))
        ),
      };
    case INSIGHTS.IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case INSIGHTS.ON_URLS_LOADED:
      return {
        ...state,
        urls: action.payload.urls,
        urlSource: action.payload.source,
      };
    case INSIGHTS.ON_EVENT_NAMES_LOADED:
      return {
        ...state,
        eventNames: action.payload.events,
      };
    case INSIGHTS.ON_VALUE_CHANGED:
      return {
        ...state,
        page: 1,
        [action.name]: action.value,
      };
    default:
      return state;
  }
};
