import * as React from "react";
import { useState } from "react";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";
import agent from "../../../../../agent";
import { ISourceFile, IWebsite } from "../../../../../reducers/constants/objectTypes";
import SourceFileListItem from "./SourceFileListItem";
import SourceFileRephraseDialog from "./SourceFileRephraseDialog";
import MTable from "../../../../../components/Table/Table/MTable";
import MTableHeader from "../../../../../components/Table/Table/MTableHeader";
import MTableHeaderLabel from "../../../../../components/Table/Table/MTableHeaderLabel";

type PropTypes = {
  sourceFiles: ISourceFile[];
  website: IWebsite;

  openSourceFilePage(sourceFile: ISourceFile): void;

  handleSourceFileChanged(sourceFile: ISourceFile): void;

  // handleValueChanged: (name: string, value: any) => void;
};

const SourceFilesList = (props: PropTypes) => {
  const { website, sourceFiles, openSourceFilePage, handleSourceFileChanged } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [rephraseSourceFileId, setRephraseSourceFileId] = useState(null);

  const handleDeleteSourceFile = (sourceFile: ISourceFile) => {
    agent.SourceFiles.deleteSourceFile(website, sourceFile)
      .then((res) => {
        if (res.error) return;
        enqueueSnackbar(res.message ?? I18n.t("videos.list.video_deleted"));
      })
      .catch((e) => {
        if (e.response && e.response.body) enqueueSnackbar(e.response.body.message);
      });
  };

  const handleRephraseClick = (sourceFile: ISourceFile) => {
    setRephraseSourceFileId(sourceFile._id);
  };
  const theme = useTheme();

  return (
    <MTable>
      <MTableHeader>
        <MTableHeaderLabel columnWidth={"35%"} fontSize={theme.typography.pxToRem(20)}>
          {I18n.t("videos.list.file_name")}
        </MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("videos.list.type")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("videos.list.play")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("videos.list.uploaded")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("videos.list.transcribed")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("videos.list.language")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("videos.list.speakers")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("videos.list.rephrase")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("videos.list.used_in")}</MTableHeaderLabel>
        <MTableHeaderLabel> {I18n.t("videos.list.topics")}</MTableHeaderLabel>
        <MTableHeaderLabel columnWidth={"40px"}> {""}</MTableHeaderLabel>
      </MTableHeader>

      {sourceFiles.map((item) => (
        <SourceFileListItem
          key={item._id}
          sourceFile={item}
          selectedWebsite={website}
          handleRephraseClick={handleRephraseClick}
          handleClick={openSourceFilePage}
          handleSourceFileChanged={handleSourceFileChanged}
          deleteSourceFile={() => {
            handleDeleteSourceFile(item);
          }}
        />
      ))}
      {rephraseSourceFileId && (
        <SourceFileRephraseDialog
          sourceFileId={rephraseSourceFileId}
          handleCloseClick={() => setRephraseSourceFileId(null)}
          handleSourceFileChanged={handleSourceFileChanged}
        />
      )}
    </MTable>
  );
};

export default SourceFilesList;
