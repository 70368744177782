import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import TextFieldMaxLength from "../../../../../components/TextFieldMaxLength";
import SelectRow from "../../../components/SelectRow";
import { WEBSITE_PAGES } from "../../../../../reducers/constants/actionTypes";
import PageSection from "../../../components/PageSection";
import { IWebsite, IWebsiteDetailsProps } from "../../../../../reducers/constants/objectTypes";
import MRouterPrompt from "../../../components/MRouterPrompt";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  shopifyInputWrapper: {
    display: "flex",
    marginLeft: "-10px",
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
  selects: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  selectWrap: {
    width: 300,
  },
  imagesWrapper: {
    display: "flex",
  },
  imageRow: {
    display: "flex",
    flexDirection: "column",
    marginRight: 80,
    "&:last-child": {
      marginRight: 0,
    },
  },
  imageFieldTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 15,
  },
  shopifyDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  name: state.websitePages.name,
  url: state.websitePages.url,
  host: state.websitePages.host,

  siteDetails: state.websitePages.siteDetails,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (name, value) => dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED, name, value }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  name: string;
  url: string;
  host: string;
  siteDetails: IWebsiteDetailsProps;

  onPropertyChanged: (name, value) => void;
};

const SiteDetails = (props: PropTypes) => {
  const classes = useStyles(props);
  const { selectedWebsite, name, url, host, siteDetails, onPropertyChanged } = props;
  const platformOptions = ["WordPress", "Shopify", "WebFlow", "Stand Alone"];
  const integrationTypeOptions = [
    "WordPress Plugin",
    "WordPress Manual Integration",
    "Shopify App Proxy",
    "CloudFlare Workers",
    "CloudFront",
    "Reverse Proxy",
    "WordPress API",
  ];

  return (
    <>
      <PageSection sectionTitle={I18n.t("website.site_details")}>
        <MRouterPrompt />
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            key={"site-name"}
            titleAndLengthLocation={"top"}
            value={name}
            placeholder={""}
            title={I18n.t("website.name")}
            maxLength={100}
            height={37}
            borderRadius={4}
            handleValueChange={(newName) => {
              onPropertyChanged("name", newName);
            }}
            marginBottom={30}
          />
          <TextFieldMaxLength
            key={"site-url"}
            titleAndLengthLocation={"top"}
            value={url}
            disabled
            placeholder={""}
            title={I18n.t("website.url")}
            height={37}
            multiLine
            borderRadius={4}
            handleValueChange={(newUrl) => {
              onPropertyChanged("url", newUrl);
            }}
            marginBottom={30}
          />
        </div>
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            key={"site-host"}
            titleAndLengthLocation={"top"}
            value={host}
            disabled
            placeholder={""}
            title={I18n.t("website.host")}
            height={37}
            multiLine
            borderRadius={4}
            handleValueChange={(newHost) => {
              onPropertyChanged("host", newHost);
            }}
            marginBottom={30}
          />
          <TextFieldMaxLength
            key={"site-url-prefix"}
            titleAndLengthLocation={"top"}
            value={siteDetails?.urlPrefix}
            placeholder={""}
            title={I18n.t("website.url_prefix")}
            maxLength={100}
            height={37}
            borderRadius={4}
            handleValueChange={(newURL) => {
              onPropertyChanged("siteDetails", { ...siteDetails, urlPrefix: newURL });
            }}
            marginBottom={30}
          />
        </div>
        <div className={classes.inputRowWrapper}>
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.url_trailing_slash")}
            value={siteDetails?.trailingSlash ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                trailingSlash: value === "Yes",
              });
            }}
          />
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.url_trailing_slash_auto_redirect")}
            value={siteDetails?.trailingSlashAutoRedirect ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                trailingSlashAutoRedirect: value === "Yes",
              });
            }}
          />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            key={"media_white_label"}
            titleAndLengthLocation={"top"}
            value={siteDetails?.mediaWhiteLabelUrlPrefix}
            placeholder={"https://entail-assets.com"}
            title={I18n.t("website.media_white_label")}
            height={37}
            maxWidth={"calc(50% - 10px)"}
            borderRadius={4}
            handleValueChange={(mediaWhiteLabelUrlPrefix) => {
              console.log("set", mediaWhiteLabelUrlPrefix);
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                mediaWhiteLabelUrlPrefix: mediaWhiteLabelUrlPrefix === "" ? null : mediaWhiteLabelUrlPrefix,
              });
            }}
            marginBottom={30}
          />
          <TextFieldMaxLength
            key={"static_sources_white_label"}
            titleAndLengthLocation={"top"}
            value={siteDetails?.staticSourcesWhiteLabelUrlPrefix}
            placeholder={"https://cdn.entail.ai/client"}
            title={I18n.t("website.static_sources_white_label")}
            height={37}
            maxWidth={"calc(50% - 10px)"}
            borderRadius={4}
            handleValueChange={(staticSourcesWhiteLabelUrlPrefix) => {
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                staticSourcesWhiteLabelUrlPrefix:
                  staticSourcesWhiteLabelUrlPrefix === "" ? null : staticSourcesWhiteLabelUrlPrefix,
              });
            }}
            marginBottom={30}
          />
        </div>
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            key={"api-url"}
            titleAndLengthLocation={"top"}
            value={siteDetails?.apiUrl}
            placeholder={"https://apps.entail.ai/api"}
            title={I18n.t("website.api_url")}
            maxLength={100}
            height={37}
            borderRadius={4}
            handleValueChange={(newURL) => {
              onPropertyChanged("siteDetails", { ...siteDetails, apiUrl: newURL });
            }}
            marginBottom={30}
          />
          <TextFieldMaxLength
            key={"distribution-id"}
            titleAndLengthLocation={"top"}
            value={siteDetails?.distributionId}
            placeholder={""}
            title={I18n.t("website.distribution_id")}
            maxLength={100}
            height={37}
            maxWidth={"calc(50% - 10px)"}
            borderRadius={4}
            handleValueChange={(distributionId) => {
              onPropertyChanged("siteDetails", { ...siteDetails, distributionId });
            }}
            marginBottom={30}
          />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.inputRowWrapper}>
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.custom_header_and_footer")}
            value={siteDetails?.customHeaderAndFooter ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                customHeaderAndFooter: value === "Yes",
              });
            }}
          />
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.platform")}
            value={siteDetails?.platform || ""}
            selectOptions={platformOptions}
            handleChange={(newPlatform) => {
              onPropertyChanged("siteDetails", { ...siteDetails, platform: newPlatform });
            }}
          />
        </div>

        <div className={classes.inputRowWrapper}>
          <SelectRow
            bold
            flexDirection={"column"}
            width={["Shopify", "WebFlow", "WordPress"].includes(siteDetails?.platform) ? "100%" : "calc(50% - 10px)"}
            rowTitle={I18n.t("website.integration_type")}
            value={siteDetails?.integrationType || ""}
            selectOptions={integrationTypeOptions}
            handleChange={(newIntegrationType) => {
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                integrationType: newIntegrationType,
              });
              if (newIntegrationType === "WordPress API") {
                onPropertyChanged("publisherName", "wordPressPublisher");
              } else if (newIntegrationType === "WordPress Manual Integration") {
                onPropertyChanged("publisherName", "wordPressManuallyExportPublisher");
              } else {
                onPropertyChanged("publisherName", "pagePublisher");
              }
            }}
          />

          {siteDetails?.platform === "WordPress" && (
            <SelectRow
              bold
              flexDirection={"column"}
              rowTitle={I18n.t("website.clear_wordpress_cache")}
              value={siteDetails?.clearWordpressCache ? "Yes" : "No"}
              selectOptions={["Yes", "No"]}
              handleChange={(value) => {
                onPropertyChanged("siteDetails", {
                  ...siteDetails,
                  clearWordpressCache: value === "Yes",
                });
              }}
            />
          )}

          {siteDetails?.platform === "Shopify" && (
            <TextFieldMaxLength
              key={"site-shop-url"}
              titleAndLengthLocation={"top"}
              value={siteDetails?.shopUrl}
              placeholder={""}
              title={I18n.t("website.shop_url")}
              maxLength={100}
              height={37}
              borderRadius={4}
              handleValueChange={(newShopUrl) => {
                onPropertyChanged("siteDetails", { ...siteDetails, shopUrl: newShopUrl });
              }}
              marginBottom={30}
            />
          )}
        </div>

        {siteDetails?.platform === "WebFlow" && (
          <>
            <Divider className={classes.divider} />
            <Typography style={{ marginBottom: 30 }} color={"textPrimary"} variant={"subtitle1"}>
              {I18n.t("website.webflow_api_usage")}
            </Typography>
            <div className={classes.shopifyInputWrapper}>
              <TextFieldMaxLength
                key={"webflow-token"}
                titleAndLengthLocation={"top"}
                value={siteDetails?.webflow?.token}
                placeholder={""}
                title={I18n.t("website.webflow_token")}
                height={37}
                marginLeft={10}
                borderRadius={4}
                handleValueChange={(newToken) => {
                  onPropertyChanged("siteDetails", {
                    ...siteDetails,
                    webflow: { ...(siteDetails.webflow || {}), token: newToken },
                  });
                }}
                marginBottom={30}
              />
            </div>
            <div className={classes.shopifyInputWrapper}>
              <TextFieldMaxLength
                key={"webflow-site-id"}
                titleAndLengthLocation={"top"}
                value={siteDetails?.webflow?.siteId}
                placeholder={""}
                marginLeft={10}
                marginRight={10}
                title={I18n.t("website.webflow_site_id")}
                height={37}
                borderRadius={4}
                handleValueChange={(newSiteId) => {
                  onPropertyChanged("siteDetails", {
                    ...siteDetails,
                    webflow: { ...(siteDetails.webflow || {}), siteId: newSiteId },
                  });
                }}
                marginBottom={30}
              />

              <TextFieldMaxLength
                key={"webflow-blog-collection-id"}
                titleAndLengthLocation={"top"}
                value={siteDetails?.webflow?.blogCollectionId}
                placeholder={""}
                title={I18n.t("website.webflow_blog_collection_id")}
                height={37}
                borderRadius={4}
                marginLeft={10}
                handleValueChange={(newBlogCollectionId) => {
                  onPropertyChanged("siteDetails", {
                    ...siteDetails,
                    webflow: { ...(siteDetails.webflow || {}), blogCollectionId: newBlogCollectionId },
                  });
                }}
                marginBottom={30}
              />
            </div>
            <div className={classes.shopifyInputWrapper}>
              <TextFieldMaxLength
                key={"webflow-authors-collection-id"}
                titleAndLengthLocation={"top"}
                value={siteDetails?.webflow?.authorsCollectionId}
                placeholder={""}
                title={I18n.t("website.webflow_authors_collection_id")}
                height={37}
                borderRadius={4}
                marginLeft={10}
                marginRight={10}
                handleValueChange={(newAuthorsCollectionId) => {
                  onPropertyChanged("siteDetails", {
                    ...siteDetails,
                    webflow: { ...(siteDetails.webflow || {}), authorsCollectionId: newAuthorsCollectionId },
                  });
                }}
                marginBottom={30}
              />
              <TextFieldMaxLength
                key={"webflow-categories-collection-id"}
                titleAndLengthLocation={"top"}
                value={siteDetails?.webflow?.categoriesCollectionId}
                placeholder={""}
                title={I18n.t("website.webflow_categories_collection_id")}
                height={37}
                borderRadius={4}
                marginLeft={10}
                handleValueChange={(newCategoriesCollectionId) => {
                  onPropertyChanged("siteDetails", {
                    ...siteDetails,
                    webflow: { ...(siteDetails.webflow || {}), categoriesCollectionId: newCategoriesCollectionId },
                  });
                }}
                marginBottom={30}
              />
            </div>
          </>
        )}

        {siteDetails?.platform === "Shopify" && (
          <div className={classes.shopifyInputWrapper}>
            <TextFieldMaxLength
              key={"site-shop-api-key"}
              titleAndLengthLocation={"top"}
              value={siteDetails?.shopApiKey}
              placeholder={""}
              title={I18n.t("website.shop_api_key")}
              marginLeft={10}
              marginRight={10}
              maxLength={100}
              height={37}
              multiLine
              borderRadius={4}
              handleValueChange={(newShopApiKey) => {
                onPropertyChanged("siteDetails", { ...siteDetails, shopApiKey: newShopApiKey });
              }}
              marginBottom={30}
            />
            <TextFieldMaxLength
              key={"site-shop-secret"}
              titleAndLengthLocation={"top"}
              value={siteDetails?.shopSecret}
              placeholder={""}
              title={I18n.t("website.shop_secret")}
              maxLength={100}
              height={37}
              multiLine
              borderRadius={4}
              handleValueChange={(newShopSecret) => {
                onPropertyChanged("siteDetails", { ...siteDetails, shopSecret: newShopSecret });
              }}
              marginBottom={30}
            />
          </div>
        )}
        <Divider className={classes.divider} />
        <div className={classes.inputRowWrapper}>
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.image_optimization")}
            value={siteDetails?.imageOptimization ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                imageOptimization: value === "Yes",
              });
            }}
          />
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.enable_products_split_tests")}
            value={siteDetails?.productsSplitTests ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              if (value === "Yes" && !siteDetails.apiUrl) {
                alert("Api Url is required to enable products split tests");
                return;
              }
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                productsSplitTests: value === "Yes",
              });
            }}
          />
        </div>
        <div className={classes.inputRowWrapper}>
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.lazy_load")}
            value={siteDetails?.lazyLoad ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                lazyLoad: value === "Yes",
              });
            }}
          />
          <SelectRow
            bold
            flexDirection={"column"}
            rowTitle={I18n.t("website.enable_watchdog")}
            value={siteDetails?.monitoring ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                monitoring: value === "Yes",
              });
            }}
          />
        </div>
        <div className={classes.inputRowWrapper}>
          <SelectRow
            bold
            flexDirection={"column"}
            width={"calc(50% - 10px)"}
            rowTitle={I18n.t("website.enable_video_cms")}
            value={siteDetails?.videoCmsAccess ? "Yes" : "No"}
            selectOptions={["Yes", "No"]}
            handleChange={(value) => {
              onPropertyChanged("siteDetails", {
                ...siteDetails,
                videoCmsAccess: value === "Yes",
              });
            }}
          />
        </div>
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetails);
