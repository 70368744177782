import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { I18n } from "react-redux-i18n";
import TextFieldProperty from "./itemProperties/components/TextFieldProperty";
import WidthProperty from "./itemProperties/components/WidthProperty";
import { eWidgetFieldWidth, IWidgetLabelFieldProps } from "../../../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const isDark = palette.type === "dark";
  return {
    root: (props: PropTypes) => ({
      display: "flex",
      flexDirection: "column",
      gap: 10,
    }),
    items: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: `1px solid ${palette.grey["100"]}`,
      width: "100%",
      borderRadius: 3,
      overflow: "hidden",
      backgroundColor: palette.secondary.dark,
    },
    divider: {
      border: `0.5px solid ${palette.grey["100"]}`,
    },
  };
});

type PropTypes = {
  properties: IWidgetLabelFieldProps;
  onPropertyChange(properties: IWidgetLabelFieldProps): void;
};

const LabelWidgetItem = (props: PropTypes) => {
  const { onPropertyChange, properties } = props;
  const classes = useStyles(props);

  const onWidthChange = (width: eWidgetFieldWidth) => {
    onPropertyChange({ ...properties, width });
  };

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        <TextFieldProperty
          label={I18n.t("widgets_builder.widget_editor.Form.fields.properties.text")}
          placeholder={I18n.t("widgets_builder.widget_editor.Form.fields.properties.text_ph")}
          onChange={(text) => onPropertyChange({ ...properties, text })}
          value={properties?.text}
        />
      </div>
      <WidthProperty value={properties.width} onChange={onWidthChange} />
    </div>
  );
};

export default LabelWidgetItem;
