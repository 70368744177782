import React, { useMemo } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Classnames from "classnames";
import OutlinesDialogListItem from "./OutlinesDialogListItem";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import MSelect from "../../../../components/MSelect";
import {
  IAutoOutlineWithStrategy,
  IWebsite,
  IAutoOutlineParagraphData,
  IAutoVideoInterview,
  IKeywordGroupMember,
} from "../../../../reducers/constants/objectTypes";
import AutoVideoDialogListItem from "./AutoVideoDialogListItem";

const useStyles = makeStyles((theme: Theme) => ({
  dialogListSubHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0",
    paddingRight: "30px",
    backgroundColor: theme.palette.background.default,
    top: -1,
  },
  autoVideoSubHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0",
    backgroundColor: theme.palette.background.default,
    top: -1,
  },
  autoDialogTab: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    width: "35%",
  },
  dialogTabs: {
    fontSize: "14px",
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold as any,
    width: "10%",
  },
  smallDialogTabs: {
    width: "7%",
  },
  kwsTab: {
    width: "30%",
  },
  firstDialogTab: {
    width: "30%",
  },
  tab: {
    padding: "2px 40px",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },
  activeTab: {
    color: theme.palette.text.primary,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    transition: "border .3s",
  },
  headerIcon: {
    width: 24,
    height: 24,
  },
  selectWrapper: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    padding: "0",
  },
  select: {
    maxWidth: "min-content",
    textTransform: "capitalize",
    cursor: "pointer",
    margin: "20px 0px",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 6,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.primary,
  },
  selectDropdownIndicator: {
    fill: theme.palette.text.primary,
  },
  selectRighText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold as any,
    color: theme.palette.text.secondary,
  },
}));

type PropTypes = {
  outline: IAutoOutlineWithStrategy;
  selectedWebsite: IWebsite;
  onOutlineDataChange: (outline: IAutoOutlineWithStrategy) => void;
  setPageTab?: (val) => void;
  autoVideoInterview: null | IAutoVideoInterview;
  keywords: IKeywordGroupMember[];
  setHideCreateButton: (val) => void;
};

const mapStateToProps = (state) => ({
  autoVideoInterview: state.outlinePages.autoVideoInterview,
  keywords: state.outlinePages.keywords,
});

const mapDispatch = (dispatch) => ({});

const OutlinesDialogList = (props: PropTypes) => {
  const classes = useStyles(props);

  const { outline, onOutlineDataChange, setHideCreateButton, selectedWebsite, keywords, autoVideoInterview } = props;
  const { enqueueSnackbar } = useSnackbar();

  const outlineColumns = React.useMemo(
    () => [
      I18n.t("strategy.outlinePages.dialog_outlines.items"),
      I18n.t("strategy.outlinePages.dialog_outlines.type"),
      I18n.t("strategy.outlinePages.dialog_outlines.notes"),
      I18n.t("strategy.outlinePages.dialog_outlines.kws"),
      I18n.t("strategy.outlinePages.dialog_outlines.volume"),
      I18n.t("strategy.outlinePages.dialog_outlines.competition"),
      I18n.t("strategy.outlinePages.dialog_outlines.stage"),
    ],
    []
  );

  const addTitleNotes = (notes) => {
    onOutlineDataChange({
      ...outline,
      postTitle: {
        ...outline.postTitle,
        notes,
      },
    });

    enqueueSnackbar(I18n.t("strategy.outlinePages.dialog_outlines.notes_added"));
  };

  const addParagraphNotes = (notes, index) => {
    const paragraphs = Array.from(outline.paragraphs);

    if (paragraphs[index]) {
      paragraphs[index].data = {
        ...paragraphs[index].data,
        notes,
      };
    }

    onOutlineDataChange({
      ...outline,
      paragraphs,
    });

    enqueueSnackbar(I18n.t("strategy.outlinePages.dialog_outlines.notes_added"));
  };

  const handleParagraphAdd = (
    newIndex: number,
    createData: {
      type: "PARAGRAPH";
      title: string;
      data: IAutoOutlineParagraphData;
    }
  ) => {
    const paragraphs = Array.from(outline.paragraphs);

    paragraphs.splice(newIndex, 0, createData);

    onOutlineDataChange({
      ...outline,
      paragraphs,
    });
  };

  const handleParagraphDelete = (index) => {
    onOutlineDataChange({
      ...outline,
      paragraphs: outline.paragraphs.filter((p, i) => i !== index),
    });
  };

  const handleH3Add = (index, newH3Index, h3) => {
    const newParagraphs = Array.from(outline.paragraphs);

    if (newParagraphs[index]) {
      if (newParagraphs[index].data?.h3) newParagraphs[index]?.data?.h3?.splice(newH3Index, 0, h3);
      else newParagraphs[index].data = { ...newParagraphs[index].data, h3: [h3] };
    }

    onOutlineDataChange({
      ...outline,
      paragraphs: newParagraphs,
    });
  };
  const handleH3Delete = (index, h3Index) => {
    const newParagraphs = Array.from(outline.paragraphs);

    if (newParagraphs[index]?.data?.h3) {
      newParagraphs[index]?.data?.h3?.splice(h3Index, 1);
    }

    onOutlineDataChange({
      ...outline,
      paragraphs: newParagraphs,
    });
  };

  const handlePostTypeChange = (postTitle: { title?: string; targetKeywordsGroups?: string[] }) => {
    const { title, targetKeywordsGroups } = postTitle;

    onOutlineDataChange({
      ...outline,
      postTitle: {
        ...outline.postTitle,
        title: title ? title : outline.postTitle.title,
        targetKeywordsGroups: targetKeywordsGroups ? targetKeywordsGroups : outline.postTitle.targetKeywordsGroups,
      },
    });
  };

  const handleParagrahpChange = (
    paragrahp: {
      title?: string;
      data?: {
        h3?: string[];
        notes?: string;
        targetKeywordsGroups: string[];
      };
    },
    index: number
  ) => {
    onOutlineDataChange({
      ...outline,
      paragraphs: outline.paragraphs.map((p, i) => {
        if (i === index) {
          return {
            ...p,
            title: paragrahp.title ? paragrahp.title : p.title,
            data: {
              ...p.data,
              h3: paragrahp.data?.h3 ? paragrahp.data?.h3 : p.data?.h3,
              notes: paragrahp.data?.notes ? paragrahp.data?.notes : p.data?.notes,
              targetKeywordsGroups: paragrahp.data?.targetKeywordsGroups
                ? paragrahp.data?.targetKeywordsGroups
                : p.data?.targetKeywordsGroups,
            },
          };
        }
        return p;
      }),
    } as any);
  };

  const handleDataH3Change = (title: string, index: number, h3Index: number) => {
    onOutlineDataChange({
      ...outline,
      paragraphs: outline.paragraphs.map((p, i) => {
        if (i === index) {
          const h3 = (p?.data as any)?.h3?.map((h, j) => {
            if (j === h3Index) {
              return title;
            }
            return h;
          });
          return { ...p, data: { ...p.data, h3 } };
        }
        return p;
      }),
    } as any);
  };

  const outlineHeadingLength = useMemo(() => {
    const h3Length = outline.paragraphs.reduce((acc, p) => {
      return acc + (p.data?.h3?.length || 0);
    }, 0);

    return h3Length + outline.paragraphs.length + 1;
  }, [outline]);

  const autoVideoColumns = React.useMemo(
    () => [
      I18n.t("strategy.outlinePages.auto_video_dialog.items"),
      I18n.t("strategy.outlinePages.dialog_outlines.kws"),
    ],
    []
  );

  const [value, setValue] = React.useState({ name: "Article", _id: "outlines" });

  return (
    <>
      <div className={classes.selectWrapper}>
        <Typography className={classes.selectRighText} variant="h6">
          {I18n.t("strategy.outlinePages.dialog_outlines.type")} {":"}
        </Typography>
        <MSelect
          customDropdownIndicatorClasses={classes.selectDropdownIndicator}
          width={"max-content"}
          customClass={classes.select}
          options={[
            { name: "Article", _id: "outlines" },
            { name: "Interview", _id: "autoVideoInterview" },
          ]}
          value={value}
          textColor="#000000"
          optionLabel={"name"}
          optionValue={"_id"}
          height={28}
          borderRadius={0}
          borderWidth={"0"}
          menuPlacement={"bottom"}
          placeholder={I18n.t("strategy.outlinePages.dialog_outlines.type")}
          searchable
          handleChange={(val) => {
            if (val._id === "autoVideoInterview") {
              setHideCreateButton(true);
            } else {
              setHideCreateButton(false);
            }
            setValue(val);
          }}
        />
      </div>

      {value._id === "outlines" ? (
        <>
          <ListSubheader className={classes.dialogListSubHeader}>
            {outlineColumns.map((col, index) => (
              <div
                className={(() => {
                  if (index == 0) {
                    return Classnames(classes.dialogTabs, classes.firstDialogTab);
                  } else if (index == 3) {
                    return Classnames(classes.dialogTabs, classes.kwsTab);
                  } else if (index > 3) {
                    return Classnames(classes.dialogTabs, classes.smallDialogTabs);
                  }
                  return classes.dialogTabs;
                })()}
                key={index}
              >
                {index === 0 ? `${outlineHeadingLength} ${col}` : col}
              </div>
            ))}
          </ListSubheader>
          <OutlinesDialogListItem
            keywords={keywords}
            selectedWebsite={selectedWebsite}
            addParagraphNotes={addParagraphNotes}
            addTitleNotes={addTitleNotes}
            handleH3Delete={handleH3Delete}
            handleH3Add={handleH3Add}
            handleParagraphAdd={handleParagraphAdd}
            handleParagraphDelete={handleParagraphDelete}
            paragraphChanged={handleParagrahpChange}
            dataH3Change={handleDataH3Change}
            postTitleChanged={handlePostTypeChange}
            outline={outline}
          />
        </>
      ) : (
        <>
          <ListSubheader className={classes.autoVideoSubHeader}>
            {autoVideoColumns.map((col, index) => (
              <div className={classes.autoDialogTab} key={index}>
                {col}
              </div>
            ))}
          </ListSubheader>
          <AutoVideoDialogListItem autoVideoInterview={autoVideoInterview} />
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatch)(OutlinesDialogList);
