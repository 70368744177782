import * as React from "react";
import { I18n } from "react-redux-i18n";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import { IWebsiteThemeProps } from "../../../reducers/constants/objectTypes";
import StylePanelSection from "../components/StylePanelSection";
import BackgroundColorCSS from "../CssComponents/BackgroundColorCSS";
import Typography from "@material-ui/core/Typography";
import BorderStyleCSS from "../CssComponents/BorderStyleCSS";
import BorderWidthCSS from "../CssComponents/BorderWidthCSS";
import BorderRadiusCSS from "../CssComponents/BorderRadiusCSS";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { cardDefaultCss } from "../../../containers/WebsiteConfigurations/WebsiteEditor/consts/CssConsts";
import BorderColorCSS from "../CssComponents/BorderColorCSS";
import PaddingCSS from "../CssComponents/PaddingCSS";
import StylePanelSubSection from "../components/StylePanelSubSection";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold as any,
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginTop: 30,
    marginBottom: 10,
  },
}));

type PropTypes = {
  sectionTitle: string;
  divider?: boolean;
  card: {
    css: any;
  };
  elementDefaultCss?: object;
  websiteTheme: IWebsiteThemeProps;
  handleChange: any;
};

const CardStyleElement = (props: PropTypes) => {
  const classes = useStyles(props);
  const { card, sectionTitle, divider = false, websiteTheme, elementDefaultCss = {}, handleChange } = props;

  const defaultCss = React.useMemo(
    () => (websiteTheme ? cardDefaultCss(websiteTheme, elementDefaultCss) : {}),
    [websiteTheme]
  );

  return (
    <StylePanelSubSection divider={divider} sectionTitle={sectionTitle}>
      <BackgroundColorCSS
        value={card?.css?.backgroundColor || defaultCss.backgroundColor}
        defaultValue={defaultCss.backgroundColor}
        isValueChanged={false}
        handleChange={(backgroundColor) => handleChange({ ...card, css: { ...card?.css, backgroundColor } })}
      />
      <BorderColorCSS
        title={I18n.t("rich_text_editor.border_color")}
        value={card?.css?.borderColor || defaultCss.borderColor}
        defaultValue={defaultCss.borderColor}
        isValueChanged={false}
        handleChange={(borderColor) => handleChange({ ...card, css: { ...card?.css, borderColor } })}
      />
      <BorderStyleCSS
        value={card?.css?.borderStyle || defaultCss.borderStyle}
        defaultValue={defaultCss.borderStyle}
        handleChange={(borderStyle) => handleChange({ ...card, css: { ...card?.css, borderStyle } })}
      />
      <BorderWidthCSS
        value={card?.css?.borderWidth || defaultCss.borderWidth}
        defaultValue={defaultCss.borderWidth}
        handleChange={(borderWidth) => handleChange({ ...card, css: { ...card?.css, borderWidth } })}
      />
      <BorderRadiusCSS
        value={card?.css?.borderRadius || defaultCss.borderRadius}
        defaultValue={defaultCss.borderRadius}
        handleChange={(borderRadius) => handleChange({ ...card, css: { ...card?.css, borderRadius } })}
      />
      <PaddingCSS
        value={card?.css?.padding || defaultCss?.padding}
        defaultValue={defaultCss?.padding}
        handleChange={(padding) => handleChange({ ...card, css: { ...card?.css, padding } })}
      />
    </StylePanelSubSection>
  );
};

export default CardStyleElement;
