import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { find } from "lodash";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import { IAd, IAdPlacement, IWebsite } from "../../../../../../reducers/constants/objectTypes";
import CfProgressBar from "../../../../../../components/CfProgressBar";
import { ADS } from "../../../../../../reducers/constants/actionTypes";
import AdPlacementListItem from "./components/AdPlacementListItem";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    height: "100%",
    width: 310,
    minWidth: 310,
    borderRight: `${theme.palette.divider} solid 1px`,
    overflowY: "auto",
  },
  title: {
    height: 60,
    paddingLeft: 30,
    display: "flex",
    alignItems: "center",
    borderBottom: `${theme.palette.divider} solid 1px`,
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 1,
  },
  adContentWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  placementsList: {
    // maxWidth: 'calc(100vw - 40px)',
  },
  root: {
    paddingTop: 0,
  },
}));

type PropTypes = {
  selectedWebsite: IWebsite;
  adLoaded: boolean;
  ad: IAd;
  selectedPlacement: IAdPlacement;

  handleAdChanged: Function;
  handleAdPlacementChanged: Function;
  handleAdPlacementSelected: Function;
};

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  adLoaded: state.ads.adLoaded,
  ad: state.ads.ad,
  selectedPlacement: state.ads.selectedPlacement,
});

const mapDispatchToProps = (dispatch) => ({
  handleAdChanged: (ad) => {
    dispatch({ type: ADS.EDIT_AD_CHANGED, ad });
  },
  handleAdPlacementChanged: (placement) => {
    dispatch({ type: ADS.EDIT_AD_PLACEMENT_CHANGED, placement });
  },
  handleAdPlacementSelected: (placement) => {
    dispatch({ type: ADS.EDIT_AD_PLACEMENT_SELECTED, placement });
  },
});

const placements: (
  | "BLOG_FEED_IMAGE"
  | "BLOG_MOBILE_FEED_IMAGE"
  | "TALL_SIDE"
  | "FORUM_POST_IMAGE"
  | "FORUM_POST_TEXT_ONLY"
  | "MOBILE_FEED_CENTER"
  | "POST_FORM"
)[] = [
  "TALL_SIDE",
  "FORUM_POST_IMAGE",
  "FORUM_POST_TEXT_ONLY",
  "MOBILE_FEED_CENTER",
  "POST_FORM",
  "BLOG_FEED_IMAGE",
  "BLOG_MOBILE_FEED_IMAGE",
];

const AdPlacements = (props: PropTypes) => {
  const classes = useStyles(props);
  const {
    selectedWebsite,
    adLoaded,
    ad,
    selectedPlacement,
    handleAdChanged,
    handleAdPlacementChanged,
    handleAdPlacementSelected,
  } = props;

  if (!adLoaded) {
    return (
      <div className={classes.content}>
        <CfProgressBar />
      </div>
    );
  }

  return (
    <div className={classes.content}>
      <Typography className={classes.title} variant={"subtitle2"}>
        {I18n.t("ads.edit_ad.placements.title")}
      </Typography>
      <div className={classes.adContentWrapper}>
        <div className={classes.placementsList}>
          <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
            {placements.map((placementType) => (
              <AdPlacementListItem
                key={placementType}
                selectedWebsite={selectedWebsite}
                placementType={placementType}
                selectedPlacement={selectedPlacement}
                placement={find(ad.placements, { type: placementType })}
                onPlacementSelected={(p) => handleAdPlacementSelected(p)}
                onPlacementChanged={(p) => handleAdPlacementChanged(p)}
              />
            ))}
          </List>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdPlacements);
