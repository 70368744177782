import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import agent from "../../../agent";
import { COMMUNITY } from "../../../reducers/constants/actionTypes";
import { IAdminUser, ICategory, IQuestion, IWebsite } from "../../../reducers/constants/objectTypes";
import QuestionsNewItemList from "./components/QuestionsNewItemList";
import MTableWrapper from "../../../components/Table/MTableWrapper";
import MTableToolbar from "../../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarCategoriesFilter from "../../../components/Table/Toolbar/Filters/MTableToolbarCategoriesFilter";
import MTableToolbarBaseActionButton from "../../../components/Table/Toolbar/Actions/MTableToolbarBaseActionButton";
import MTablePagination from "../../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  admin: state.home.admin,
  categories: state.category.categories,
  refreshQuestions: state.community.refresh,
  totalPages: state.community.newTotalPages,
  questions: state.community.newQuestions,
  questionsLoaded: state.community.newQuestionsLoaded,
  category: state.community.newQuestionsFilter.category,
  searchText: state.community.newQuestionsFilter.searchText,
  sortField: state.community.newQuestionsFilter.sortField,
});

const mapDispatchToProps = (dispatch) => ({
  loadQuestions: (payload) => dispatch({ type: COMMUNITY.NEW_QUESTIONS_LOADED, payload }),
  handleNewQuestion: (question: IQuestion) => dispatch({ type: COMMUNITY.NEW_QUESTION_CREATED, question }),
  onFilterChanged: (payload) => dispatch({ type: COMMUNITY.NEW_QUESTION_ON_FILTER_CHANGED, payload }),
});

type PropTypes = {
  selectedWebsite: IWebsite;
  admin: IAdminUser;
  refreshQuestions: boolean;
  categories: ICategory[];
  totalPages: number;
  questions: IQuestion[];
  questionsLoaded: boolean;
  category: ICategory[];
  searchText: string;
  sortField: { direction: "desc" | "asc" | undefined; field: string };

  loadQuestions: (payload) => void;
  handleNewQuestion: (question: IQuestion) => void;
  onFilterChanged: (payload) => void;
};

const New = (props: PropTypes) => {
  const {
    selectedWebsite,
    admin,
    loadQuestions,
    refreshQuestions,
    handleNewQuestion,
    categories,
    totalPages,
    questions,
    questionsLoaded,
    onFilterChanged,
    category,
    sortField,
    searchText,
  } = props;

  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const categoryIds = category.map((c) => c._id);
    loadQuestions(
      agent.Community.getAll(
        selectedWebsite._id,
        categoryIds,
        searchText,
        "new",
        (page - 1).toString(),
        pageLimit,
        sortField.field,
        sortField.direction
      )
        .then((res) => res)
        .catch((e) => {})
    );
  }, [selectedWebsite, admin, category, searchText, refreshQuestions, page, pageLimit, sortField]);

  const createQuestion = () => {
    agent.Community.getNewQuestion(selectedWebsite._id)
      .then((res) => {
        handleNewQuestion(res.question);
        enqueueSnackbar(I18n.t("community.status.created_question"));
      })
      .catch((e) => {});
  };

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.community.new.title`)} />
      <MTableToolbar
        searchText={searchText}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
        actions={[
          <MTableToolbarBaseActionButton
            handleClick={createQuestion}
            text={I18n.t("community.actions.new_question")}
          />,
        ]}
      >
        <MTableToolbarCategoriesFilter
          handleCategoriesSelected={(value) => onFilterChanged({ category: value })}
          selectedCategories={category}
          categories={categories}
        />
      </MTableToolbar>

      <QuestionsNewItemList
        sortField={sortField}
        setSortField={(value) => onFilterChanged({ sortField: value })}
        questions={questions}
      />

      {questionsLoaded && questions.length > 0 && (
        <MTablePagination
          paginationLimit={pageLimit}
          page={page}
          paginationPagesCount={totalPages}
          setPaginationLimit={setPageLimit}
          setPage={setPage}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(New);
