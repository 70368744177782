import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { IWidget, eWidgetType } from "../../../../../../reducers/constants/objectTypes";
import SettingsOption from "../components/WidgetSettings/SettingsOption";
import SelectedSettingsOption from "../components/WidgetSettings/SelectedSettingsOption";

const useStyles = makeStyles(() => ({
  root: {
    padding: 30,
  },
}));

type PropTypes = {
  widget: IWidget;
};

const mapStateToProps = (state) => ({
  widget: state.widgetBuilder.widget,
});

const settingsOptions = {
  [eWidgetType.DIALOG]: [
    {
      label: "Triggers",
      type: "triggers",
    },
    {
      label: "Layout",
      type: "layout",
    },
    {
      label: "Frequency",
      type: "frequency",
    },
    {
      label: "Pages",
      type: "pages",
    },
    {
      label: "Devices",
      type: "devices",
    },
    {
      label: "Custom Css",
      type: "custom_css",
    },
  ],
  [eWidgetType.INLINE]: [
    {
      label: "Layout",
      type: "layout",
    },
    {
      label: "Custom Css",
      type: "custom_css",
    },
  ],
};

const WidgetSettingsTab = (props: PropTypes) => {
  const { widget } = props;
  const [selectedOption, setSelectedOption] = React.useState(null);

  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {!selectedOption ? (
        [eWidgetType.DIALOG, eWidgetType.INLINE].includes(widget.type as eWidgetType) && (
          <>
            {settingsOptions[widget.type].map((option, i) => (
              <SettingsOption label={option.label} onClick={() => setSelectedOption(option)} key={i} />
            ))}
          </>
        )
      ) : (
        <SelectedSettingsOption widget={widget} selectedOption={selectedOption} back={() => setSelectedOption(null)} />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(WidgetSettingsTab);
