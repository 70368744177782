import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { I18n } from "react-redux-i18n";
import BriefsList from "./BriefsList";
import { OUTLINE_PAGES } from "../../reducers/constants/actionTypes";

import { IAutoOutlineWithStrategy, IWebsite, IKeywordGroupMember } from "../../reducers/constants/objectTypes";
import agent from "../../agent";
import OutlinePageDialog from "./OutlineDialog/OutlinePageDialog";
import MTableWrapper from "../../components/Table/MTableWrapper";
import MTableToolbar from "../../components/Table/Toolbar/MTableToolbar";
import MTableToolbarOutlineStateFilter from "../../components/Table/Toolbar/Filters/MTableToolbarOutlineStateFilter";
import MTablePagination from "../../components/Table/Pagination/MTablePagination";
import MHeaderMetaTags from "../../components/MHeaderMetaTags";

const mapStateToProps = (state) => ({
  websites: state.home.websites,
  websitesLoaded: state.home.websitesLoaded,
  selectedWebsite: state.home.selectedWebsite,

  outlines: state.outlinePages.outlines,
  outlinesLoaded: state.outlinePages.outlinesLoaded,
  searchTextFilter: state.outlinePages.searchTextFilter,
  total: state.outlinePages.total,
  limit: state.outlinePages.limit,
  page: state.outlinePages.page,
  postState: state.outlinePages.filter.postState,
  sortField: state.outlinePages.filter.sortField,
  searchText: state.outlinePages.filter.searchText,
  keywords: state.outlinePages.keywords,
});

const mapDispatchToProps = (dispatch) => ({
  outlineStartLoading: () => dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGES_START_LOADING }),
  handleOutlinesLoaded: (payload) => dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGES_LOADED, payload }),
  handleOutlineSelected: (outline: IAutoOutlineWithStrategy) =>
    dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGE_SELECTED, outline }),
  handleKeywordsLoaded: (payload) => dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGES_KEYWORDS_LOADED, payload }),
  handleAutoVideoInterviewLoaded: (payload) =>
    dispatch({ type: OUTLINE_PAGES.ON_AUTO_VIDEO_INTERVIEW_LOADED, payload }),
  handlePaginationChanged: (page, limit, total) =>
    dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGINATION_CHANGE, page, limit, total }),
  emptyKeywords: () => dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGES_KEYWORDS_EMPTY }),
  onFilterChanged: (payload) => dispatch({ type: OUTLINE_PAGES.OUTLINE_PAGES_ON_FILTER_CHANGED, payload }),
  changeSerpsLoaded(payload) {
    dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGES_SERPS_LOADED, payload });
  },
  changeSerpsLoading() {
    dispatch({ type: OUTLINE_PAGES.ON_OUTLINE_PAGES_SERPS_LOADING });
  },
});

type PropTypes = {
  websites: IWebsite[];
  selectedWebsite: IWebsite;

  outlines: IAutoOutlineWithStrategy[];
  outlinesLoaded: boolean;
  searchTextFilter: string | null;
  total: number;
  limit: number;
  page: number;
  outlineStartLoading: () => void;
  handleOutlinesLoaded: (payload: any) => void;
  handlePaginationChanged: (page: number, limit: number, total: number) => void;
  handleOutlineSelected: (outline: IAutoOutlineWithStrategy) => void;
  handleAutoVideoInterviewLoaded: (payload: any) => void;
  handleKeywordsLoaded: (payload: any) => void;
  emptyKeywords: () => void;
  keywords: IKeywordGroupMember[];
  searchText: string;
  sortField: { direction: "desc" | "asc" | undefined; field: string };
  postState: { _id: string; name: string }[];
  onFilterChanged: (payload) => void;
  changeSerpsLoaded: (payload) => void;
  changeSerpsLoading: () => void;
};

const Mapping = (props: PropTypes) => {
  const [openDialog, setOpen] = useState<{
    open: boolean;
    page: "outlines" | "keywords" | "broadKeywords" | "pages" | "serps";
  }>({
    open: false,
    page: "outlines",
  });

  const { enqueueSnackbar } = useSnackbar();

  const {
    outlinesLoaded,
    outlines,
    limit,
    total,
    page,
    selectedWebsite,
    handleKeywordsLoaded,
    handleOutlineSelected,
    postState,
    searchText,
    sortField,
    onFilterChanged,
  } = props;
  const {
    handleOutlinesLoaded,
    outlineStartLoading,
    handlePaginationChanged,
    emptyKeywords,
    handleAutoVideoInterviewLoaded,
    changeSerpsLoaded,
    changeSerpsLoading,
  } = props;

  const [refetch, setRefetch] = React.useState(false);

  const setPageTab = (page: "outlines" | "keywords" | "broadKeywords" | "pages" | "serps") => {
    setOpen({
      open: true,
      page,
    });
  };

  useEffect(() => {
    if (selectedWebsite) {
      outlineStartLoading();
      const cleanPostStates = postState.map((p) => p._id);
      handleOutlinesLoaded(
        agent.OutlinePages.getOutlines({
          websiteId: selectedWebsite._id,
          page,
          limit,
          searchText,
          postStates: cleanPostStates.length > 0 ? cleanPostStates : ["APPROVED"],
          sort: sortField.field,
          sortDirection: sortField.direction,
          isWorkflowPage: true,
        })
      );
    }
  }, [limit, page, searchText, selectedWebsite, refetch, sortField, postState]);

  const updateAutoOutline = (outline: IAutoOutlineWithStrategy) => {
    agent.OutlinePages.updateOutline(outline)
      .then(() => {
        setRefetch((val) => !val);
        enqueueSnackbar(I18n.t("strategy.strategyKeywords.topic_updated"));
      })
      .catch((err) => {
        console.log("error while updating", err);
      });
  };

  const deleteAutoOutline = (id: string) => {
    agent.OutlinePages.deleteAutoOutline(id, true)
      .then(() => {
        setRefetch((val) => !val);
        enqueueSnackbar(I18n.t("strategy.strategyKeywords.topic_deleted"));
      })
      .catch((err) => {
        console.log("error while deleting", err);
      });
  };

  const openOutlineDialog = (
    outline: IAutoOutlineWithStrategy,
    page: "outlines" | "keywords" | "broadKeywords" | "pages"
  ) => {
    handleKeywordsLoaded(agent.OutlinePages.getOutlineKeywords(selectedWebsite._id, outline._id));
    handleAutoVideoInterviewLoaded(
      agent.AutoVideoInterview.getAutoVideoInterview(selectedWebsite._id, outline.keywordGroup)
    );
    changeSerpsLoading();
    changeSerpsLoaded(agent.KeywordStrategy.getSerps(selectedWebsite._id, outline.strategy.keyword));
    handleOutlineSelected(outline);
    setOpen({
      page,
      open: true,
    });
  };

  const closeOpportunityDialog = () => {
    changeSerpsLoaded({ data: [] });
    emptyKeywords();
    setOpen({
      page: "outlines",
      open: false,
    });
  };

  return (
    <MTableWrapper>
      <MHeaderMetaTags title={I18n.t(`meta_tags.article_cms.briefs.title`)} />
      <MTableToolbar
        cmsCreateButton
        searchText={searchText}
        handleSearchTextChanged={(value) => onFilterChanged({ searchText: value })}
      >
        <MTableToolbarOutlineStateFilter
          state={postState}
          handleOutlineStateSelected={(value) => onFilterChanged({ postState: value })}
        />
      </MTableToolbar>
      <BriefsList
        updateAutoOutline={updateAutoOutline}
        deleteAutoOutline={deleteAutoOutline}
        outlinesLoaded={outlinesLoaded}
        outlines={outlines}
        openOutlineDialog={openOutlineDialog}
        refetch={refetch}
        setRefetch={(value) => setRefetch(value)}
        sortField={sortField}
        setSortField={(value) => onFilterChanged({ sortField: value })}
      />
      <OutlinePageDialog
        open={openDialog.open}
        close={closeOpportunityDialog}
        pageTab={openDialog.page}
        setPageTab={setPageTab}
        setRefetch={(value) => setRefetch(value)}
        refetch={refetch}
      />
      {outlinesLoaded && total > 0 && (
        <MTablePagination
          paginationLimit={limit}
          page={page}
          paginationPagesCount={Math.ceil(total / limit)}
          setPaginationLimit={(value) => handlePaginationChanged(page, value, total)}
          setPage={(value) => handlePaginationChanged(value, limit, total)}
        />
      )}
    </MTableWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapping);
