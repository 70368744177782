import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { useState } from "react";
import ClassNames from "classnames";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import { Theme, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles, createStyles } from "@material-ui/styles";
import DialogActions from "@material-ui/core/DialogActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { IWebsite } from "../../../../reducers/constants/objectTypes";
import CfButton from "../../../../components/CfButton";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import agent from "../../../../agent";
import { web } from "../../../../helpers/urlHelper";
import { LANDING_PAGES } from "../../../../reducers/constants/actionTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: "100%",
      maxWidth: 700,
      display: "flex",
      flexDirection: "column",
      borderWidth: 0,
      borderRadius: 0,
      padding: 24,
      gap: 24,
    },
    dialogTitleWrap: {
      padding: 0,
    },
    dialogTitle: {
      marginBottom: 0,
      fontWeight: theme.typography.fontWeightBold as any,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.2,
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      whiteSpace: "pre",
      padding: 0,
      gap: 30,
    },
    row: {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
    dialogActions: {
      padding: 0,
      gap: 10,
    },
    cancel: {
      fontSize: 14,
      color: theme.palette.text.secondary,
      cursor: "pointer",
    },
    button: {
      fontSize: 14,
      padding: "10px 20px",
      borderRadius: 5,
      minHeight: "unset",
      fontWeight: theme.typography.fontWeightLight as any,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    column: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    selectTitle: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold as any,
    },
    select: {
      textTransform: "capitalize",
    },
    root: {
      width: "100%",
      border: `solid 1px ${theme.palette.divider}`,
      textDecoration: "none",
      borderRadius: 3,
      paddingLeft: 10,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    menuItemText: {
      textTransform: "capitalize",
    },
  })
);

const iconStyles = {
  selectIcon: {
    color: "#9e9e9e",
    fontSize: 21,
    top: "unset",
    right: 4,
  },
};
const CustomExpandMore = withStyles(iconStyles)(({ className, classes, ...rest }) => {
  return <ExpandMoreIcon {...rest} className={ClassNames(className, classes.selectIcon)} />;
});
const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

type PropTypes = {
  open: boolean;
  selectedWebsite: IWebsite;
  handleExitClick: () => void;
  onLandingPageCreated: (landingPage) => void;
  editPage: (type: string, landingPageId: string) => void;
};

const types = ["gated-content", "type2", "type3"];

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  onLandingPageCreated: (payload) => {
    dispatch({ type: LANDING_PAGES.LANDING_PAGE_CREATED, payload });
  },
  editPage: (type: string, landingPageId: string) => dispatch(push(web.managerLandingPage(type, landingPageId))),
});

const CreateLandingPageDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const { open, selectedWebsite, handleExitClick, onLandingPageCreated, editPage } = props;
  const [name, setName] = useState<string>("");
  const [type, setType] = useState("");

  const close = () => {
    handleExitClick();
  };

  const createNewLandingPage = () => {
    agent.LandingPages.createLandingPage(name, type, selectedWebsite._id)
      .then((res) => {
        onLandingPageCreated(res);
        enqueueSnackbar(I18n.t(`snackbar.create_success`));
        editPage(res.landingPage.type, res.landingPage._id);
      })
      .catch((e) => {
        enqueueSnackbar(I18n.t("snackbar.create_error", { msg: e.message }));
      });
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      keepMounted={false}
      onClose={() => close()}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.dialogTitleWrap}>
        <Typography variant={"h2"} className={classes.dialogTitle}>
          {I18n.t("landing_pages.dialog.title")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextFieldMaxLength
          titleAndLengthLocation={"top"}
          value={name}
          placeholder={I18n.t("landing_pages.write_ph")}
          title={I18n.t("landing_pages.title")}
          height={37}
          borderRadius={4}
          handleValueChange={(value) => setName(value)}
        />
        <div className={classes.column}>
          <Typography className={classes.selectTitle}>{I18n.t("landing_pages.dialog.select_type")}</Typography>
          <Select
            disableUnderline
            placeholder={I18n.t("landing_pages.dialog.select_ph")}
            classes={{ root: classes.root }}
            className={classes.select}
            value={type}
            onChange={(event) => {
              console.log("event.target.value.toString()", event.target.value.toString());
              setType(event.target.value.toString());
            }}
            IconComponent={CustomExpandMore}
          >
            {types.map((t, i) => (
              <MenuItem key={`${t}_${i}`} value={t}>
                <span className={classes.menuItemText}>{t}</span>
              </MenuItem>
            ))}
          </Select>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Typography className={classes.cancel} onClick={(e) => close()}>
          {I18n.t("landing_pages.dialog.cancel")}
        </Typography>
        <CfButton
          customClass={classes.button}
          text={I18n.t("landing_pages.dialog.create")}
          onClick={(e) => {
            createNewLandingPage();
            close();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLandingPageDialog);
