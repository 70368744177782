import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ClassNames from "classnames";
import { Property } from "csstype";

import GenericOptionContainer from "../containers/CRO/pages/WidgetsBuilder/FormWidgetEditor/components/GenericOptionContainer";
import ArrowRightRoundedIcon from "../icons/ArrowRightRoundedIcon";
import DragIcon from "../icons/DragIcon";
import TrashCanIcon from "../icons/TrashCanIcon";

const useStyles = makeStyles(({ typography, palette }: Theme) => {
  const color = palette.text.primary;
  return {
    root: ({ isDraggable }: PropTypes) => ({
      width: "100%",
      position: "relative",
      "&:hover": {
        "& $dragIcon": {
          display: isDraggable ? "block" : "none",
        },
        "& $trashIcon": {
          visibility: "visible",
        },
      },
    }),
    leftContainer: ({ isDragging, isDraggable }: PropTypes) => ({
      display: "flex",
      alignItems: "center",
      paddingLeft: isDraggable && !isDragging ? 10 : 0,
      flex: 1,
      minWidth: 0,
    }),
    textWrapper: {
      overflow: "hidden",
    },
    title: ({ fontWeight }: PropTypes) => ({
      fontSize: 14,
      fontWeight: fontWeight || (typography.fontWeightMedium as any),
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color,
    }),
    rightContainer: ({ isDraggable, noArrow }: PropTypes) => ({
      display: "flex",
      justifySelf: "flex-end",
      justifyContent: "flex-end",
      alignItems: "center",
      marginRight: noArrow && !isDraggable ? 0 : isDraggable ? -5 : -15,
      textAlign: "right",
      gap: 13,
      marginLeft: 10,
    }),
    arrowIcon: {
      fontSize: 19,
      color,
      opacity: 1,
      padding: 5,
      paddingLeft: 0,
      boxSizing: "content-box",
    },
    trashIcon: () => ({
      fontSize: 18,
      color,
      marginRight: 0,
      visibility: "hidden",
      opacity: 0.4,
      "&:hover": {
        opacity: 1,
      },
    }),
    dragIcon: ({ isDragging }: PropTypes) => ({
      display: isDragging ? "block" : "none",
      position: "absolute",
      left: 0,
      fill: color,
      opacity: 0.2,
      width: 25,
      height: 25,
      cursor: "pointer",
    }),
    showDragIcon: {
      display: "block",
    },
  };
});

type PropTypes = {
  title: string;
  className?: string;
  rightIconsComponent?: React.ReactNode;
  fontWeight?: Property.FontWeight | number;
  noArrow?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
} & IDraggableProps;

export type IDraggableProps =
  | {
      isDraggable: boolean;
      isDragging: boolean;
    }
  | {
      isDraggable?: never;
      isDragging?: never;
    };

const MDynamicListItem = (props: PropTypes) => {
  const { title, rightIconsComponent, noArrow, className } = props;
  const { onClick, onDelete } = props;
  const classes = useStyles(props);

  return (
    <GenericOptionContainer customClass={ClassNames(classes.root, className)} onClick={onClick}>
      <>
        <div className={classes.leftContainer}>
          <DragIcon className={classes.dragIcon} />
          <div className={classes.textWrapper}>
            <Typography className={classes.title}>{title}</Typography>
          </div>
        </div>

        <div className={classes.rightContainer}>
          {rightIconsComponent && onDelete && (
            <TrashCanIcon
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              className={classes.trashIcon}
            />
          )}
          {rightIconsComponent}
          {!rightIconsComponent && onDelete && (
            <TrashCanIcon
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              className={classes.trashIcon}
            />
          )}
          {!noArrow && <ArrowRightRoundedIcon className={classes.arrowIcon} />}
        </div>
      </>
    </GenericOptionContainer>
  );
};

export default MDynamicListItem;
