import { AIRFLOW_DAG_IDS } from "../helpers/constants";
import { STRATEGY_SETTINGS } from "./constants/actionTypes";

const initialState = {
  activeJob: "keywords_groups",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STRATEGY_SETTINGS.STRATEGY_SETTINGS_JOB_CHANGE:
      return {
        ...state,
        activeJob: action.job,
      };

    default:
      return state;
  }
};
