import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) => ({}));

type PropTypes = {
  open: boolean;
  title: string;
  description?: string;
  agreeText: string;
  disagreeText: string;

  handleClose: Function;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MAlertDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { open, title, description = null, agreeText, disagreeText, handleClose } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleClose(false);
          }}
          color="primary"
        >
          {disagreeText}
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleClose(true);
          }}
          color="primary"
        >
          {agreeText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MAlertDialog;
