import React from "react";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClassNames from "classnames";
import AddIcon from "../../../../icons/AddIcon";
import DownChevronIcon from "../../../../icons/DownChevronIcon";
import MTableRowProgressBar from "../../Rows/MTableRowProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    whiteSpace: "nowrap",
    gap: 5,
    marginLeft: "auto",
    fontSize: theme.typography.pxToRem(14),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold as any,
    borderRadius: 20,
    height: 36,
    minHeight: 36,
    "&:hover": {
      backgroundColor: "#5967FF",
    },
  },
  icon: {
    fill: "#333",
  },
}));

type PropTypes = {
  handleClick: any;
  text: string;
  dropdown?: boolean;
  loading?: boolean;
  accessible?: boolean;
  showAddIcon?: boolean;
  className?: string;
};

const MTableToolbarBaseActionButton = (props: PropTypes) => {
  const classes = useStyles(props);
  const { handleClick, text, dropdown = false, loading, accessible = true, showAddIcon = false, className } = props;

  if (!accessible) return null;

  if (loading) return <MTableRowProgressBar size={25} />;
  return (
    <div className={ClassNames(classes.wrapper, className)} onClick={handleClick}>
      {!dropdown && showAddIcon && <AddIcon className={classes.icon} />}
      <span>{text}</span>
      {dropdown && <DownChevronIcon className={classes.icon} />}
    </div>
  );
};

export default MTableToolbarBaseActionButton;
