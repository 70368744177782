import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { push } from "connected-react-router";
import InternalLinkingDialogHeader from "./InternalLinkingDialogHeader";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import { INTERNAL_LINKING } from "../../../../../reducers/constants/actionTypes";
import RelatedPagesDialogList from "./RelatedPagesDialog/RelatedPagesDialogList";
import agent from "../../../../../agent";
import RelatedPagesKeywordDialogList from "./RelatedPagesKeywordDialog/RelatedPagesKeywordDialogList";

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    padding: 0,
    "& .MuiDialogContent-root": {
      padding: 30,
    },
  },
  dialogContent: {
    overflowY: "hidden",
  },
  scrollPaper: {
    maxHeight: "100%",
  },
  list: {
    padding: "0",
  },
  listItems: {
    overflowY: "auto",
    height: "calc(100vh - 246px)",
    marginTop: "30px",
  },
  footer: {
    borderTop: `${theme.palette.divider} solid 1px`,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    marginLeft: "auto",
  },
}));

const mapStateToProps = (state) => ({
  selectedWebsite: state.home.selectedWebsite,
  item: state.internalLinking.selectedDataItem,
  refetch: state.internalLinking.refetch,
});

const mapDispatch = (dispatch) => ({
  redirect: (url) => dispatch(push(url)),
  changeRefetch(refresh: boolean) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_REFETCH_CHANGED, payload: refresh });
  },
  changeSelectedDataItemsIndexes(indexes: number[]) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_DATA_SELECTED_ITEMS_CHANGED, payload: indexes });
  },
  changeRelatedKeywordDialogOpen(data: any) {
    dispatch({ type: INTERNAL_LINKING.ON_INTERNAL_LINKING_RELATED_KEYWORDS_CHANGED, payload: data });
  },
});

type PropTypes = {
  item: any;
  open: boolean;
  refetch: boolean;
  selectedWebsite: IWebsite;

  redirect: (url: string) => void;
  close: () => void;
  changeSelectedDataItemsIndexes: (indexes: number[]) => void;
  changeRefetch: (refresh: boolean) => void;
  changeRelatedKeywordDialogOpen: (data: any) => void;
};

const InternalLinkingDialog = (props: PropTypes) => {
  const classes = useStyles(props);
  const { item, close, open, changeRelatedKeywordDialogOpen, selectedWebsite } = props;

  const [dialogScreen, setDialogScreen] = React.useState("mainPage");
  const [selectedRelatedPage, setSelectedRelatedPage] = React.useState(null);

  useEffect(() => {
    if (selectedRelatedPage) {
      agent.KeywordGroupPage.getRelatedKeywords(
        selectedWebsite._id,
        selectedRelatedPage.page,
        selectedRelatedPage.related_page
      )
        .then((res) => {
          changeRelatedKeywordDialogOpen(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedRelatedPage]);

  useEffect(
    () => () => {
      changeRelatedKeywordDialogOpen([]);
      setDialogScreen("mainPage");
      setSelectedRelatedPage(null);
    },
    [close]
  );

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
      onClose={(event) => close()}
      fullWidth
      maxWidth={"xl"}
      classes={{ paper: classes.dialogPaper, paperScrollPaper: classes.scrollPaper }}
    >
      <DialogContent className={classes.dialogContent}>
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
            <InternalLinkingDialogHeader
              goBack={() => {
                setDialogScreen("mainPage");
                setSelectedRelatedPage(null);
              }}
              page={item}
              dialogScreen={dialogScreen}
              title={dialogScreen === "mainPage" ? item?.page || "" : selectedRelatedPage?.related_page || ""}
              close={close}
            />

            <div className={classes.listItems}>
              {dialogScreen === "mainPage" && (
                <RelatedPagesDialogList
                  setSelectedRelatedPage={(page) => {
                    setSelectedRelatedPage(page);
                    setDialogScreen("relatedPage");
                  }}
                  item={item}
                />
              )}
              {dialogScreen === "relatedPage" && <RelatedPagesKeywordDialogList relatedPage={selectedRelatedPage} />}
            </div>
          </List>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatch)(InternalLinkingDialog);
