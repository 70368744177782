import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import PageSection from "../../components/PageSection";
import {
  IWebsite,
  IWebsiteSectionProps,
  IWebsiteTopicProps,
  SNWebsiteAutoCompleteMetaTag,
} from "../../../../reducers/constants/objectTypes";
import { WEBSITE_PAGES } from "../../../../reducers/constants/actionTypes";
import TextFieldMaxLength from "../../../../components/TextFieldMaxLength";
import TextInputMentions from "../../components/TextInputMentions";
import { LENGTHS } from "../../../../reducers/constants/consts";
import TextDesignToolWithPreview from "../../components/TextDesignToolWithPreview";
import MRouterPrompt from "../../components/MRouterPrompt";

const useStyles = makeStyles((theme: Theme) => ({
  inputRowWrapper: {
    display: "flex",
    gap: 20,
  },
  divider: {
    marginLeft: "-30px",
    marginRight: "-30px",
    marginTop: 30,
    marginBottom: 30,
  },
}));

const mapStateToProps = (state) => ({
  website: state.home.selectedWebsite,
  topic: state.websitePages.topic,
  sections: state.websitePages.sections,
});

const mapDispatchToProps = (dispatch) => ({
  onPropertyChanged: (topic) =>
    dispatch({
      type: WEBSITE_PAGES.ON_WEBSITE_PAGES_PROPERTY_CHANGED,
      name: "topic",
      value: topic,
    }),
  handleToggleActive: (section, enabled) =>
    dispatch({ type: WEBSITE_PAGES.ON_WEBSITE_TOGGLE_ACTIVE, section, enabled }),
});

type PropTypes = {
  topic: IWebsiteTopicProps;
  website: IWebsite;
  sections: IWebsiteSectionProps;

  onPropertyChanged: (topic) => void;
  handleToggleActive: (section, enabled) => void;
};

const PageTopic = (props: PropTypes) => {
  const classes = useStyles(props);
  const { website, topic, sections, onPropertyChanged, handleToggleActive } = props;
  const [error, setError] = React.useState(false);
  const [emptyFields, setEmptyFields] = React.useState([]);
  const [locale, setLocale] = React.useState(website?.configurations.locale.defaultLocale || null);
  const { defaultLocale } = website?.configurations.locale;

  const textFieldsValidated = () => {
    const valid = true;
    // setEmptyFields([]);
    // const emptyFields = [];
    // if (!blog.name) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.name`));
    // }
    // if (!blog.mainPage.slug) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.slug_title`));
    // }
    // if (!blog.mainPage.metaTitle) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.meta_title`));
    // }
    // if (!blog.mainPage.metaDescription) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.meta_description`));
    // }
    // if (!blog.mainPage.title.text) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.title`));
    // }
    // if (!blog.mainPage.description.text) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.description`));
    // }
    // if (!blog.categoryPage.slug) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.blog.category_page_slug`));
    // }
    // if (!blog.categoryPage.metaTitle) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.blog.category_page_meta_title`));
    // }
    // if (!blog.categoryPage.metaDescription) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.blog.category_page_meta_description`));
    // }
    // if (!blog.postPage.metaTitle) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.blog.post_page_meta_title`));
    // }
    // if (!blog.postPage.metaDescription) {
    // 	valid = false;
    // 	emptyFields.push(I18n.t(`website.blog.post_page_meta_description`));
    // }
    // setEmptyFields(emptyFields);
    return valid;
  };

  return (
    <>
      <PageSection
        sectionTitle={I18n.t("website.topic.name")}
        website={website}
        locale={locale}
        handleLocaleChange={setLocale}
        checked={sections.topic}
        onCheckedChange={(enabled) => {
          if (!textFieldsValidated()) {
            if (!enabled && sections.topic) {
              handleToggleActive("topic", enabled);
            }
            setError(true);
          } else {
            setError(false);
            if (!enabled && sections.glossary) {
              handleToggleActive("glossary", false);
            }
            handleToggleActive("topic", enabled);
          }
        }}
      >
        <MRouterPrompt />
        <div className={classes.inputRowWrapper}>
          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            value={locale === defaultLocale ? topic.name : topic.translations[locale]?.name || ""}
            placeholder={I18n.t("website.name_ph")}
            title={I18n.t("website.name")}
            height={37}
            borderRadius={4}
            handleValueChange={(value) => {
              if (locale === defaultLocale) {
                onPropertyChanged({ ...topic, name: value });
                return;
              }
              topic.translations[locale] = {
                ...(topic.translations[locale] || {}),
                name: value,
              };
              onPropertyChanged({ ...topic });
            }}
          />

          <TextFieldMaxLength
            titleAndLengthLocation={"top"}
            value={topic.slug}
            placeholder={I18n.t("website.slug_title_ph")}
            title={I18n.t("website.slug_title")}
            height={37}
            borderRadius={4}
            handleValueChange={(value) => onPropertyChanged({ ...topic, slug: value })}
          />
        </div>
        <Divider className={classes.divider} />

        <TextDesignToolWithPreview
          topTitle
          disableHover
          marginRight={"-30px"}
          marginLeft={"-30px"}
          elementTitle={I18n.t("website.element_design", { element: I18n.t("website.title") })}
          css={topic.title?.css}
          variant={"h1"}
          exampleText={I18n.t("website.topic.example_title_text")}
          handleCssChanged={(newCss) => {
            onPropertyChanged({ ...topic, title: { ...topic.title, css: newCss } });
          }}
        />

        <TextInputMentions
          key={`topic_meta_title_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.topicName, SNWebsiteAutoCompleteMetaTag.topicTitle]}
          placeholder={I18n.t("website.meta_title_ph")}
          title={I18n.t("website.meta_title")}
          maxLength={LENGTHS.META_TITLE}
          titleAndLengthLocation={"top"}
          text={locale === defaultLocale ? topic.metaTitle : topic.translations[locale]?.metaTitle || ""}
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...topic, metaTitle: value });
              return;
            }
            topic.translations[locale] = {
              ...(topic.translations[locale] || {}),
              metaTitle: value,
            };
            onPropertyChanged({ ...topic });
          }}
        />

        <TextInputMentions
          key={`topic_meta_desc_${locale}`}
          tags={[SNWebsiteAutoCompleteMetaTag.topicName, SNWebsiteAutoCompleteMetaTag.topicMetaDescription]}
          placeholder={I18n.t("website.meta_description_ph")}
          title={I18n.t("website.meta_description")}
          maxLength={LENGTHS.META_DESCRIPTION}
          titleAndLengthLocation={"top"}
          text={locale === defaultLocale ? topic.metaDescription : topic.translations[locale]?.metaDescription || ""}
          onTextChanged={(value) => {
            if (locale === defaultLocale) {
              onPropertyChanged({ ...topic, metaDescription: value });
              return;
            }
            topic.translations[locale] = {
              ...(topic.translations[locale] || {}),
              metaDescription: value,
            };
            onPropertyChanged({ ...topic });
          }}
        />
      </PageSection>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTopic);
