import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IExpert, IWebsite } from "../../../../../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: 0,
    bottom: "auto",
    border: "none",
    backgroundColor: "white",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    justifyContent: "left",
    alignItems: "center",
    paddingLeft: 30,
    paddingRight: 30,
  },
  titleTypography: {
    display: "flex",
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold as any,
    marginBottom: 0,
  },
  pipe: {
    marginRight: 10,
    marginLeft: 10,
    color: theme.palette.divider,
  },
  backIcon: {
    color: theme.palette.text.primary,
    cursor: "pointer",
    marginRight: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  expert: IExpert;
  handleGoBackClick: () => void;
};

const ExpertHeader = (props: PropTypes) => {
  const classes = useStyles(props);
  const { expert, handleGoBackClick } = props;

  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar disableGutters className={classes.toolbar}>
        <ArrowBackIcon className={classes.backIcon} onClick={() => handleGoBackClick()} />
        <Typography className={classes.titleTypography} variant={"h3"}>
          {I18n.t("experts.profile.edit_profile")}
          <span className={classes.pipe}>|</span>
          {`${expert?.firstName || ""} ${expert?.lastName || ""}`}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default ExpertHeader;
