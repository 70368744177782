import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import ClassNames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DeleteIcon from "@material-ui/icons/Delete";
import Search from "@material-ui/icons/Search";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { I18n } from "react-redux-i18n";
import { useSnackbar } from "notistack";
import CopyToClipboardIcon from "../icons/CopyToClipboardIcon";
import { copyStringToClipboard } from "../helpers/fbScraperHelper";
import CopyNameIcon from "../icons/CopyNameIcon";
import { getSearchConsoleUrl, clients, clientPages } from "../helpers/urlHelper";
import { IPage, IWebsite } from "../reducers/constants/objectTypes";

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    flexDirection: "row",
    alignItems: "center",
    display: "none",
    position: "absolute",
    right: 10,
    top: 0,
    padding: "0 10px",
    backgroundColor: theme.palette.divider,
  },
  button: {
    padding: "0px 5px 0px 5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 20,
    height: 20,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
}));

type PropTypes = {
  customClass: string;
  url: string;
};

const EntailURLToolbar = (props: PropTypes) => {
  const classes = useStyles(props);
  const { customClass, url } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className={ClassNames(classes.actions, customClass)}>
      {url && (
        <Tooltip enterDelay={500} title={I18n.t(`learning_center_content.tooltip.open_in_new_tab`)}>
          <IconButton
            disableRipple
            className={classes.button}
            onClick={(event) => {
              event.stopPropagation();
              window.open(url, "_blank");
            }}
          >
            <OpenInNewIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
      {url && (
        <Tooltip enterDelay={500} title={I18n.t(`learning_center_content.tooltip.google_search_console`)}>
          <IconButton
            disableRipple
            className={classes.button}
            onClick={(event) => {
              event.stopPropagation();

              window.open(getSearchConsoleUrl(url), "_blank");
            }}
          >
            <Search className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}

      {url && (
        <Tooltip enterDelay={500} title={I18n.t(`learning_center_content.tooltip.copy_url`)}>
          <IconButton
            disableRipple
            className={classes.button}
            onClick={(event) => {
              event.stopPropagation();
              copyStringToClipboard(url, () => enqueueSnackbar(I18n.t("snackbar.copied_to_clipboard")));
            }}
          >
            <CopyToClipboardIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default EntailURLToolbar;
