import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function ItalicIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 24 28"}>
      <path d="M8.000,3.000 C8.000,4.660 9.340,6.000 11.000,6.000 L12.420,6.000 L5.580,22.000 L3.000,22.000 C1.340,22.000 -0.000,23.340 -0.000,25.000 C-0.000,26.660 1.340,28.000 3.000,28.000 L13.000,28.000 C14.660,28.000 16.000,26.660 16.000,25.000 C16.000,23.340 14.660,22.000 13.000,22.000 L11.580,22.000 L18.420,6.000 L21.000,6.000 C22.660,6.000 24.000,4.660 24.000,3.000 C24.000,1.340 22.660,0.000 21.000,0.000 L11.000,0.000 C9.340,0.000 8.000,1.340 8.000,3.000 Z" />
    </SvgIcon>
  );
}

export default ItalicIcon;
