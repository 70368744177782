import * as React from "react";
import { Theme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    fontSize: 14,
    fill: theme.palette.text.secondary,
  },
}));

type propTypes = {
  className?: string;
};

function AlignLeftIcon(props: propTypes) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SvgIcon className={ClassNames(className, classes.image)} viewBox={"0 0 32 32"}>
      <path d="M19.555,21.333 L1.778,21.333 C0.800,21.333 -0.000,22.133 -0.000,23.111 C-0.000,24.089 0.800,24.889 1.778,24.889 L19.555,24.889 C20.533,24.889 21.333,24.089 21.333,23.111 C21.333,22.133 20.533,21.333 19.555,21.333 ZM19.555,7.111 L1.778,7.111 C0.800,7.111 -0.000,7.911 -0.000,8.889 C-0.000,9.867 0.800,10.667 1.778,10.667 L19.555,10.667 C20.533,10.667 21.333,9.867 21.333,8.889 C21.333,7.911 20.533,7.111 19.555,7.111 ZM1.778,17.778 L30.222,17.778 C31.200,17.778 32.000,16.978 32.000,16.000 C32.000,15.022 31.200,14.222 30.222,14.222 L1.778,14.222 C0.800,14.222 -0.000,15.022 -0.000,16.000 C-0.000,16.978 0.800,17.778 1.778,17.778 ZM1.778,32.000 L30.222,32.000 C31.200,32.000 32.000,31.200 32.000,30.222 C32.000,29.244 31.200,28.444 30.222,28.444 L1.778,28.444 C0.800,28.444 -0.000,29.244 -0.000,30.222 C-0.000,31.200 0.800,32.000 1.778,32.000 ZM-0.000,1.778 C-0.000,2.756 0.800,3.556 1.778,3.556 L30.222,3.556 C31.200,3.556 32.000,2.756 32.000,1.778 C32.000,0.800 31.200,-0.000 30.222,-0.000 L1.778,-0.000 C0.800,-0.000 -0.000,0.800 -0.000,1.778 Z" />
    </SvgIcon>
  );
}

export default AlignLeftIcon;
