import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { KeyboardArrowLeftRounded } from "@material-ui/icons";
import { I18n } from "react-redux-i18n";
import { Button, TextareaAutosize, Theme, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import DesignHeaderModel from "../models/DesignHeaderModel";
import { WIDGET_BUILDER } from "../../../../../../../reducers/constants/actionTypes";

const useStyles = makeStyles(({ typography, palette }: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
    padding: "30px",
  },
  wrapper: {
    backgroundColor: palette.secondary.main,
    borderRadius: "5px",
    padding: "25px 15px 15px 15px",
  },
  headerItems: {
    fontSize: "16px",
    verticalAlign: "middle",
    display: "flex",
    padding: "0px 0px 10px 0px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowBack: {
    display: "flex",
    marginLeft: "-8px",
    cursor: "pointer",
  },
  backIcon: {
    height: "22px",
    color: palette.common.white,
  },
  button: {
    background: palette.primary.light,
    justifySelf: "flex-end",
    height: "27px",
    width: "54px",
    fontSize: "14px",
    fontWeight: typography.fontWeightLight,
    textTransform: "none",
  },
  head: {
    userSelect: "none",
    color: palette.common.white,
    fontSize: "16px",
    lineHeight: "21px",
  },
  body: {
    width: "100%",
    borderRadius: "5px",
    marginTop: "10px",
  },
  headerText: {
    background: palette.primary.dark,
    width: "100%",
    height: "126px",
  },
  textField: {
    background: palette.primary.dark,
    width: "100%",
    resize: "none !important" as "none",
    minHeight: 126,
    maxHeight: 126,
    border: "none",
    padding: "10px",
    lineHeight: "17px",
    borderRadius: 5,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    color: "white",
    "&:focus": {
      outline: "none",
    },
  },
}));

type PropTypes = {
  goBack: (any) => void;
  header: DesignHeaderModel;
  editHeader: (header: DesignHeaderModel) => void;
};

const mapStateToProps = (state) => ({
  header: state.widgetBuilder.widget.header,
});

const mapDispatchToProps = (dispatch) => ({
  editHeader: (header) => dispatch({ type: WIDGET_BUILDER.DESIGN, key: "header", value: header }),
});

const HeaderDesign = (props: PropTypes) => {
  const classes = useStyles(props);
  const { goBack, header, editHeader } = props;
  const [text, setText] = React.useState(header?.text || "");

  function handleClick() {
    const body = {
      text,
    };

    editHeader(body);

    goBack(null);
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.headerItems}>
          <div className={classes.arrowBack} onClick={() => goBack(null)}>
            <KeyboardArrowLeftRounded className={classes.backIcon} />
            <Typography variant={"h6"} className={classes.head}>
              {I18n.t("widgets_builder.design.header")}
            </Typography>
          </div>
          <Button className={classes.button} onClick={() => handleClick()}>
            {I18n.t("widgets_builder.design.done")}
          </Button>
        </div>
        <div className={classes.body}>
          <TextareaAutosize
            className={classes.textField}
            value={text}
            onChange={(event) => setText(event.target.value)}
            placeholder={I18n.t("widgets_builder.design.write_a_title")}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDesign);
