import _ from "lodash";
import filter from "lodash/filter";
import { TARGETED_POSTS } from "./constants/actionTypes";

const initialState = {
  postsLoaded: false,
  posts: null,
  selectedPostId: null,

  targetedPost: null,

  searchText: "",

  topics: [],
  topicsSearchField: "",

  tags: [],
  tagsSearchField: "",

  keywords: [],
  keywordsSearchField: "",

  showNewPostId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TARGETED_POSTS.POSTS_LOADED:
      return {
        ...state,
        postsLoaded: true,
        posts: action.payload.posts,
        selectedPostId: state.showNewPostId
          ? state.showNewPostId
          : action.payload.posts.length > 0
          ? action.payload.posts[0]._id
          : null,
        showNewPostId: null,
      };
    case TARGETED_POSTS.PENDING_NEW_POSTS_LOADED: {
      const posts = [
        ...action.payload.posts.map((post) => ({ ...post, postType: "TARGETED_POST" })),
        ...action.payload.fbPosts.map((fbPost) => ({ ...fbPost, postType: "FB" })),
        ...action.payload.uploadedContent.map((content) => ({ ...content, postType: "UPLOADED_CONTENT" })),
      ];
      return {
        ...state,
        postsLoaded: true,
        posts,
        selectedPostId: posts.length > 0 ? posts[0]._id : null,
      };
    }
    case TARGETED_POSTS.SET_SELECTED_POST_ID:
      return {
        ...state,
        selectedPostId: action.id,
      };
    case TARGETED_POSTS.POST_LOADED:
      return {
        ...state,
        targetedPost: action.payload.post,
      };
    case TARGETED_POSTS.POST_CHANGED:
      return {
        ...state,
        targetedPost: action.post,
      };
    case TARGETED_POSTS.NEW_POST_CREATED:
      return {
        ...state,
        posts: [action.post, ...state.posts],
        showNewPostId: action.post._id,
        selectedPostId: action.post._id,
      };
    case TARGETED_POSTS.NEW_POST_SAVED:
      return {
        ...state,
        selectedPostId: state.posts.length > 0 ? state.posts[0]._id : null,
      };

    case TARGETED_POSTS.SEARCH_TEXT_CHANGED:
      return {
        ...state,
        searchText: action.searchText,
      };
    case TARGETED_POSTS.REMOVE_SELECTED_POST: {
      const posts = state.posts.filter((p) => p._id !== state.selectedPostId);
      return {
        ...state,
        targetedPost: null,
        posts,
        selectedPostId: posts.length > 0 ? posts[0]._id : null,
      };
    }
    case TARGETED_POSTS.TAB_CHANGED: {
      return {
        ...state,
        posts: null,
        postsLoaded: false,
        targetedPost: null,
        selectedPostId: null,
      };
    }
    case TARGETED_POSTS.ON_TOPICS_LOADED:
      return {
        ...state,
        topics: action.payload.topics,
      };
    case TARGETED_POSTS.ON_TOPIC_SELECTED:
      return {
        ...state,
        targetedPost: {
          ...state.targetedPost,
          topic: action.topic,
        },
      };
    case TARGETED_POSTS.ON_CATEGORY_SELECTED:
      return {
        ...state,
        targetedPost: {
          ...state.targetedPost,
          category: action.category,
        },
      };
    case TARGETED_POSTS.ON_TOPICS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        topicsSearchField: action.input,
      };
    case TARGETED_POSTS.ON_NEW_TOPIC_CREATED:
      return {
        ...state,
        topics: _.uniqBy([...state.topics, action.payload.topic], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        topicsSearchField: "",
        targetedPost: {
          ...state.targetedPost,
          topic: action.payload.topic,
        },
      };
    case TARGETED_POSTS.ON_TAGS_LOADED:
      return {
        ...state,
        tags: action.payload.tags,
      };
    case TARGETED_POSTS.ON_TAG_SELECTED:
      return {
        ...state,
        targetedPost: {
          ...state.targetedPost,
          tags: [...state.targetedPost.tags, action.tag],
        },
      };
    case TARGETED_POSTS.ON_TAG_REMOVED:
      return {
        ...state,
        targetedPost: {
          ...state.targetedPost,
          tags: filter(state.targetedPost.tags, (tag) => tag.name !== action.tag.name),
        },
      };
    case TARGETED_POSTS.ON_TAGS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        tagsSearchField: action.input,
      };
    case TARGETED_POSTS.ON_NEW_TAG_CREATED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        tags: _.uniqBy([...state.tags, action.payload.tag], "_id").sort((a, b) => a.name.localeCompare(b.name)),
        tagsSearchField: "",
        targetedPost: {
          ...state.targetedPost,
          tags: _.uniqBy([...state.targetedPost.tags, action.payload.tag], "_id"),
        },
      };
    case TARGETED_POSTS.ON_KEYWORDS_LOADED:
      return {
        ...state,
        keywords: action.payload.keywords,
      };
    case TARGETED_POSTS.ON_KEYWORD_SELECTED:
      return {
        ...state,
        targetedPost: {
          ...state.targetedPost,
          keywords: [...state.targetedPost.keywords, action.keyword],
        },
      };
    case TARGETED_POSTS.ON_KEYWORD_REMOVED:
      return {
        ...state,
        targetedPost: {
          ...state.targetedPost,
          keywords: filter(state.targetedPost.keywords, (keyword) => keyword.name !== action.keyword.name),
        },
      };
    case TARGETED_POSTS.ON_KEYWORDS_SEARCH_FIELD_CHANGED:
      return {
        ...state,
        keywordsSearchField: action.input,
      };
    case TARGETED_POSTS.ON_NEW_KEYWORD_CREATED:
      if (action.payload.error) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        keywords: _.uniqBy([...state.keywords, action.payload.keyword], "_id").sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        keywordsSearchField: "",
        targetedPost: {
          ...state.targetedPost,
          keywords: _.uniqBy([...state.targetedPost.keywords, action.payload.keyword], "_id"),
        },
      };
    case TARGETED_POSTS.ON_SOURCE_URL_ADDED:
      return {
        ...state,
        targetedPost: {
          ...state.targetedPost,
          sourceURLs: [...(state.targetedPost.sourceURLs || []), action.url],
        },
      };
    case TARGETED_POSTS.ON_SOURCE_URL_REMOVED:
      return {
        ...state,
        targetedPost: {
          ...state.targetedPost,
          sourceURLs: filter(state.targetedPost.sourceURLs, (url) => url !== action.url),
        },
      };
    default:
      return state;
  }
};
