import React, { useEffect, useState } from "react";
import MTableToolbarBaseSelect from "../../../../../components/Table/Toolbar/MTableToolbarBaseSelect";
import agent from "../../../../../agent";
import { IWebsite } from "../../../../../reducers/constants/objectTypes";
import useDebounce from "../../../../../hooks/useDebounce";
import { I18n } from "react-redux-i18n";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import MDropdown from "../../../../../components/MDropdown";
import { options } from "./TimePeriodFilter";
import { find } from "lodash";
const useStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    margin: "0px 10px",
  },
  dropdownIcon: {
    fill: theme.palette.text.primary,
  },
}));

type Props = {
  selectedWebsite: IWebsite;
  handleChange: (event: any) => void;
  value?: any;
};

const EventFilter = (props: Props) => {
  const { selectedWebsite, value = undefined, handleChange } = props;
  const [events, setEvents] = useState<any[]>([]);
  const [search, setSearch] = useState<string>(null);
  const debounceColumnTerm = useDebounce(search, 500);
  const classes = useStyles(props);

  useEffect(() => {
    agent.Funnels.getColumnValues(selectedWebsite, "events", "name", debounceColumnTerm, 1, 500)
      .then((res) => {
        setEvents(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [debounceColumnTerm]);

  return (
    <MTableToolbarBaseSelect
      options={events}
      selectedOptions={value ? [find(events, (event) => event.value === value.value)] : []}
      titleClassName={classes.titleWrapper}
      dropdownClassName={classes.dropdownIcon}
      optionLabel={"label"}
      optionValue={"value"}
      setExternalSearchText={setSearch}
      title={I18n.t("reports.page_events.events")}
      placeholder={I18n.t("reports.page_events.all_events")}
      handleChange={(list) => handleChange(list?.[0] || null)}
    />
  );
};

export default EventFilter;
