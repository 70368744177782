import { WIDGET_RULE_EDITOR } from "./constants/actionTypes";
import { ICampaign } from "./constants/objectTypes";

const initialState = {
  rule: null,
  draftContainer: null,
  addContainerMode: false,
  reselectPositionMode: false,
  selectedView: "main",
  selectedWidgetContainerId: null,
  selectedPositionEdit: null,
  fromOnboarding: false,
  preview: {
    loading: false,
    plainHtml: null,
    htmlWithContainers: null,
    mobilePreview: false,
    fullScreen: false,
    XPathCollectionsRendered: false,
    XPathCollections: {
      sizePos: [],
      browserWidth: 0,
      pageHeight: 0,
    },
  },
};

export enum WidgetRuleEditorSelectedView {
  MAIN = "main",
  PAGE_SELECT = "page-select",
  WIDGET_CONTAINER_SETTINGS = "widget-container-settings",
  WIDGET_CONTAINER_SETTINGS_POSITION = "widget-container-settings/position",
}

export type WidgetRuleEditorRootState = {
  rule: ICampaign;
  draftContainer: ICampaign["widgetContainers"][0];
  addContainerMode?: boolean;
  reselectPositionMode?: boolean;
  selectedView?: WidgetRuleEditorSelectedView;
  selectedWidgetContainerId: string;
  selectedPositionEdit: "desktop" | "mobile" | "desktop_mobile";
  fromOnboarding: boolean;
  preview: {
    previewBlobUrl: string;
    plainHtml: string;
    htmlWithContainers: string;
    loading: boolean;
    mobilePreview: boolean;
    fullScreen: boolean;
    XPathCollectionsRendered: boolean;
    XPathCollections: {
      sizePos: any[];
      browserWidth: number;
      pageHeight: number;
    };
  };
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case WIDGET_RULE_EDITOR.RULE_LOADED:
      return {
        ...state,
        rule: action.payload.rule,
      };

    case WIDGET_RULE_EDITOR.PROPERTY_CHANGE:
      return {
        ...state,
        ...action.property,
      };

    case WIDGET_RULE_EDITOR.PREVIEW_PROPERTY_CHANGE:
      return {
        ...state,
        preview: {
          ...state.preview,
          ...action.property,
        },
      };
    case WIDGET_RULE_EDITOR.UNMOUNT:
      return initialState;
    case WIDGET_RULE_EDITOR.FROM_ON_BOARDING:
      return {
        ...state,
        fromOnboarding: action.fromOnboarding,
      };
    default:
      return state;
  }
};
