import * as React from "react";
import { I18n } from "react-redux-i18n";
import { ReactEditor } from "slate-react";
import { Editor, Transforms } from "slate";
import { IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { find } from "lodash";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../../elementsStyles/components/StylePanelSubSection";
import { ProductCTA } from "../../../types/editor.Types";
import ProductPickerElement from "../../../elementsStyles/ElementComponents/ProductPickerElement";
import MaxImagesCSS from "../../../elementsStyles/CssComponents/MaxImagesCSS";
import BackgroundColorCSS from "../../../elementsStyles/CssComponents/BackgroundColorCSS";
import TypographyStyleElement from "../../../elementsStyles/ElementComponents/TypographyStyleElement";
import ButtonStyleElement from "../../../elementsStyles/ElementComponents/ButtonStyleElement";
import theme from "../../../../themes/theme";

type PropTypes = {
  editor: Editor;
  selectedWebsite: IWebsite;
  websiteTheme: IWebsiteThemeProps;
  element: ProductCTA;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
};

const ProductCTAWidgetStyle = (props: PropTypes) => {
  const { editor, selectedWebsite, element, products, handleProductsChange, websiteTheme } = props;

  const handleElementDataChange = (data) => {
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.product_cta.name")} editor={editor}>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.product_cta.settings")}>
        <ProductPickerElement
          isClearable={false}
          fullWidth
          selectedWebsite={selectedWebsite}
          products={products}
          handleProductsChange={handleProductsChange}
          product={product}
          onProductChange={(prod: IProduct) => {
            if (!prod) {
              handleElementDataChange({ ...element.data, product: prod });
            } else {
              handleElementDataChange({
                ...element.data,
                product: {
                  itemNumber: prod.itemNumber,
                },
              });
            }
          }}
        />
        <MaxImagesCSS
          defaultValue={6}
          maxValue={6}
          value={element.data?.maxImages || 6}
          handleChange={(number) => {
            handleElementDataChange({ ...element.data, maxImages: number });
          }}
        />
        <BackgroundColorCSS
          value={element.data?.backgroundColor}
          defaultValue={"rgba(255,255,255,0)"}
          isValueChanged={false}
          handleChange={(color) => handleElementDataChange({ ...element.data, backgroundColor: color })}
        />
      </StylePanelSubSection>
      <TypographyStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.product_num")}
        typography={element.data?.productNumber}
        elementDefaultCss={{
          fontSize: 70,
          fontWeight: theme.typography.fontWeightBold as any,
          margin: "0px 0px 15px 0px",
        }}
        variant={"subtitle1"}
        handleChange={(data) => handleElementDataChange({ ...element.data, productNumber: data })}
        theme={websiteTheme}
      />
      <TypographyStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.title")}
        typography={element.data?.title}
        elementDefaultCss={{
          fontSize: 21,
          fontWeight: theme.typography.fontWeightBold as any,
          color: websiteTheme.palette.primary.main,
        }}
        variant={"h3"}
        handleChange={(data) => handleElementDataChange({ ...element.data, title: data })}
        theme={websiteTheme}
      />
      <TypographyStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.product_name")}
        typography={element.data?.productName}
        elementDefaultCss={{
          fontSize: 14,
        }}
        variant={"h4"}
        handleChange={(data) => handleElementDataChange({ ...element.data, productName: data })}
        theme={websiteTheme}
      />

      <StylePanelSubSection
        divider
        sectionTitle={I18n.t("rich_text_editor.rating")}
        show={element.data?.rating.show}
        handleShowChange={(value) =>
          handleElementDataChange({ ...element.data, rating: { ...element.data.rating, show: value } })
        }
      >
        {}
      </StylePanelSubSection>

      <TypographyStyleElement
        divider
        currency
        sectionTitle={I18n.t("rich_text_editor.product_cta.price")}
        typography={element.data?.price}
        elementDefaultCss={{
          fontSize: 14,
          fontWeight: theme.typography.fontWeightBold as any,
        }}
        variant={"h5"}
        handleChange={(data) => handleElementDataChange({ ...element.data, price: data })}
        theme={websiteTheme}
      />

      <ButtonStyleElement
        divider
        sectionTitle={I18n.t("rich_text_editor.product_cta.button")}
        button={element.data.button}
        defaultText={product?.callToAction || ""}
        defaultUrl={product?.callToActionURL || ""}
        elementDefaultCss={{
          padding: "15px 90px",
        }}
        theme={websiteTheme}
        handleChange={(button) => {
          handleElementDataChange({ ...element.data, button });
        }}
      />
    </StylePanelSection>
  );
};

export default ProductCTAWidgetStyle;
