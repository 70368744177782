import * as React from "react";
import { I18n } from "react-redux-i18n";
import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { IProduct, IWebsite, IWebsiteThemeProps } from "../../../../reducers/constants/objectTypes";
import { find } from "lodash";
import StylePanelSection from "../../../elementsStyles/components/StylePanelSection";
import StylePanelSubSection from "../../../elementsStyles/components/StylePanelSubSection";
import ProductPickerElement from "../../../elementsStyles/ElementComponents/ProductPickerElement";
import TextOldElement from "../../../elementsStyles/ElementComponents/TextOldElement";
import ButtonOldElement from "../../../elementsStyles/ElementComponents/ButtonOldElement";

type PropTypes = {
  editor: Editor;
  selectedWebsite: IWebsite;
  websiteTheme: IWebsiteThemeProps;
  element: any;
  products: IProduct[];
  handleProductsChange: (products: IProduct[]) => void;
};

const ProductDetailsWidgetStyle = (props: PropTypes) => {
  const { editor, selectedWebsite, element, products, handleProductsChange, websiteTheme } = props;

  const handleElementDataChange = (data) => {
    console.log("PageEditorProductDetailsStyle: handleElementDataChange", data);
    Transforms.setNodes(editor, { data }, { at: ReactEditor.findPath(editor as ReactEditor, element) });
  };
  const product = find(products, (p) => p.itemNumber === element.data.product?.itemNumber);

  return (
    <StylePanelSection sectionTitle={I18n.t("rich_text_editor.product_details.name")} editor={editor}>
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.product_details.settings")}>
        <ProductPickerElement
          isClearable={false}
          fullWidth
          selectedWebsite={selectedWebsite}
          products={products}
          handleProductsChange={handleProductsChange}
          product={product}
          onProductChange={(prod: IProduct) => {
            if (!prod) {
              handleElementDataChange({ ...element.data, product: prod });
            } else {
              handleElementDataChange({
                ...element.data,
                product: {
                  itemNumber: prod.itemNumber,
                },
              });
            }
          }}
        />
      </StylePanelSubSection>
      <TextOldElement
        sectionTitle={I18n.t("rich_text_editor.product_details.product_title")}
        cssKey={"productTitleCss"}
        defaultValue={websiteTheme.typography.h3.css.fontSize}
        handleChange={handleElementDataChange}
        element={element}
        theme={websiteTheme}
      />
      <TextOldElement
        sectionTitle={I18n.t("rich_text_editor.product_details.product_name")}
        cssKey={"productNameCss"}
        defaultValue={websiteTheme.typography.subtitle1.css.fontSize}
        handleChange={handleElementDataChange}
        element={element}
        theme={websiteTheme}
      />
      <StylePanelSubSection sectionTitle={I18n.t("rich_text_editor.button.button")}>
        <ButtonOldElement
          buttonText={element.data.buttonText || product?.callToAction || ""}
          buttonUrl={element.data.buttonUrl || product?.callToActionURL || ""}
          buttonCss={element.data?.buttonCss || {}}
          buttonDefaultCss={
            {
              fontSize: websiteTheme.typography.body1.css.fontSize,
              fontWeight: websiteTheme.typography.body1.css.fontWeight,
              margin: "0 0 0 10px",
              backgroundColor: websiteTheme.palette.primary.main,
              color: "white",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: websiteTheme.palette.primary.main,
              padding: "10px 30px",
              borderRadius: 30,
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: websiteTheme.palette.primary.light,
                borderColor: websiteTheme.palette.primary.light,
              },
            } || websiteTheme.buttons.css
          }
          handleChange={(buttonProps) => {
            handleElementDataChange({ ...element.data, ...buttonProps });
          }}
        />
      </StylePanelSubSection>
    </StylePanelSection>
  );
};

export default ProductDetailsWidgetStyle;
