import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { useSlateStatic } from "slate-react";

const bullets = {
  v_icon: (color: string) =>
    `url("data:image/svg+xml;utf8,<svg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10.801 0.701253C10.536 0.432916 10.1058 0.432916 9.8411 0.701253L3.77608 6.8408L1.15892 4.19135C0.893621 3.92278 0.463865 3.92278 0.198801 4.19135C-0.0662669 4.45969 -0.0662669 4.89499 0.198801 5.16308L3.29617 8.29866C3.42876 8.43289 3.60254 8.5 3.77635 8.5C3.95016 8.5 4.12382 8.43277 4.25654 8.29866L10.8013 1.67313C11.0663 1.40479 11.0663 0.969742 10.8011 0.701162L10.801 0.701253Z' fill='${encodeURIComponent(
      color
    )}'/></svg>")`,
  x_icon: (color: string) =>
    `url("data:image/svg+xml;utf8,<svg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.36229 4.5L8.81079 1.04767C9.05002 0.808182 9.05002 0.419047 8.81079 0.179614C8.57157 -0.0598715 8.18287 -0.0598715 7.9437 0.179614L4.49502 3.63195L1.04651 0.189592C0.807287 -0.0498936 0.418583 -0.0498936 0.179415 0.189592C-0.0598052 0.429078 -0.0598052 0.818214 0.179415 1.05765L3.62792 4.5L0.189382 7.95233C-0.0498384 8.19182 -0.0498384 8.58095 0.189382 8.82039C0.428603 9.05987 0.817308 9.05987 1.05648 8.82039L4.50498 5.36805L7.95349 8.82039C8.19271 9.05987 8.58142 9.05987 8.82058 8.82039C9.0598 8.5809 9.0598 8.19176 8.82058 7.95233L5.36229 4.5Z' fill='${encodeURIComponent(
      color
    )}'/></svg>")`,
  disc_icon: (color: string) =>
    `url("data:image/svg+xml;utf8,<svg width='5' height='6' viewBox='0 0 5 6' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='2.5' cy='3' r='2.5' fill='${encodeURIComponent(
      color
    )}'/></svg>")`,
};

const backgroundSizes = {
  v_icon: "11px 9px",
  x_icon: "9px 9px",
  disc_icon: "6px 6px",
};
const useStyles = makeStyles((theme: Theme) => ({
  list: (props: PropTypes) => ({
    listStyleImage: "none",
    marginBlockStart: "16px !important",
    marginBlockEnd: "16px! important",
    paddingInlineStart: `${
      props?.element?.data?.paddingLeft ||
      (!props.element?.data?.icon || props.element?.data?.icon === "disc_icon" ? 10 : 0)
    }px !important`,
    listStylePosition: "inside",
    "& li": {
      margin: "0px 0 10px 0",
      padding: "0 0 0 24px",
      listStyleType: "none",
    },
    "& > li::before": {
      content: '" "',
      backgroundImage: (bullets[props.element.data?.icon || "disc_icon"] || bullets["disc_icon"])(
        props?.element.data?.fill || theme.palette.text.primary
      ),
      backgroundSize: backgroundSizes[props.element.data?.icon] || backgroundSizes["disc_icon"],
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center left",
      width: 22,
      float: "left",
      display: "inline-block",
      marginLeft: -22,
    },
  }),
}));

type PropTypes = {
  attributes: any;
  children: any;
  element: any;
};

function BulletedListElementReadOnly(props: PropTypes) {
  const classes = useStyles(props);
  const { attributes, children, element } = props;
  const editor = useSlateStatic();

  return (
    <ul {...attributes} className={classes.list}>
      {children}
    </ul>
  );
}

export default BulletedListElementReadOnly;
