import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { I18n } from "react-redux-i18n";
import { IProduct } from "../../../../../reducers/constants/objectTypes";
import MTableRow from "../../../../../components/Table/Rows/MTableRow";
import MTableCellFirst from "../../../../../components/Table/Cells/MTableCellFirst";
import MTableCellText from "../../../../../components/Table/Cells/MTableCellText";
import MTableCellDate from "../../../../../components/Table/Cells/MTableCellDate";
import MActionsDropdown from "../../../../../components/MActionsDropdown";
import ability from "../../../../../casl/ability";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    "&:hover $moreOptionsColumn": {
      visibility: "visible",
    },
  },
  moreOptionsColumn: {
    width: 30,
    visibility: "hidden",
  },
}));

type PropTypes = {
  product: IProduct;

  handleProductClick: () => void;
  handleDeleteClick: () => void;
};

const ProductItem = (props: PropTypes) => {
  const classes = useStyles(props);
  const { product, handleDeleteClick, handleProductClick } = props;
  const menuItems = React.useMemo(() => {
    const options = [];
    if (ability.can("editor", "role") || ability.can("customer", "role")) {
      options.push({
        value: "DELETE",
        onClick: handleDeleteClick,
        label: I18n.t("redirect_rules.table.delete"),
      });
    }
    return options;
  }, [product, ability]);

  return (
    <MTableRow handleRowClick={() => handleProductClick()} fullBorderWidth customClassName={classes.wrapper}>
      <MTableCellFirst cellText={product.displayName || product.name} columnWidth={"20%"} />
      <MTableCellText text={product.itemNumber} />
      <MTableCellText text={product.callToActionURL ? "✓" : "✘"} />
      <MTableCellDate date={product.publishedDate} />
      <MTableCellText text={"✘"} />

      <div className={classes.moreOptionsColumn}>
        {menuItems.length > 0 && <MActionsDropdown options={menuItems} />}
      </div>
    </MTableRow>
  );
};

export default ProductItem;
